import React from 'react';
import _ from 'lodash';
import flags from 'containers/flags/service';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { FEATURE_NOTIFICATIONS } from 'common/constants/feature-notifications';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        background: 'transparent',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    image: {
        width: '100%',
        height: 500,
        objectFit: 'contain',
    },
    multiImagePage: {
        width: '100%',
        height: 200,
        objectFit: 'contain',
    },
}));

export function DottedStepper(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const filteredData = _.filter(props.data, value => value.id === props.id);

    const stepsLength = filteredData[0].steps.length;
    const lastStep = stepsLength - 1;

    return (
        <div>
            <DialogTitle id="simple-dialog-title">
                {filteredData[0].steps[activeStep].title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {filteredData[0].steps[activeStep].content}
                </DialogContentText>
                <img
                    className={
                        filteredData[0].steps[activeStep].subSteps &&
                        filteredData[0].steps[activeStep].subSteps.length > 0
                            ? classes.multiImagePage
                            : classes.image
                    }
                    src={filteredData[0].steps[activeStep].img}
                />
                {filteredData[0].steps[activeStep].subSteps &&
                    filteredData[0].steps[activeStep].subSteps.length > 0 && (
                        <React.Fragment>
                            {_.map(filteredData[0].steps[activeStep].subSteps, step => (
                                <React.Fragment>
                                    <DialogContentText id="alert-dialog-description">
                                        {step.content}
                                    </DialogContentText>
                                    <img className={classes.multiImagePage} src={step.img} />
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    )}
            </DialogContent>
            <DialogActions>
                <MobileStepper
                    variant="dots"
                    steps={stepsLength > 1 ? stepsLength : 0}
                    position="static"
                    activeStep={activeStep}
                    classes={{ root: classes.root }}
                    nextButton={
                        activeStep === lastStep ? (
                            <Button size="small" onClick={props.onClose}>
                                Finish
                            </Button>
                        ) : (
                            <Button size="small" onClick={handleNext}>
                                Next
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        )
                    }
                    backButton={
                        stepsLength > 1 && (
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                        )
                    }
                />
            </DialogActions>
        </div>
    );
}

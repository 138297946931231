import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useReportJobs } from '../hook';
import ReportJobList from '../report-job-list';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        bottom: 50,
        left: 10,
        width: 400,
        backgroundColor: '#fff',
        zIndex: 50000,
    },
    title: {
        flexGrow: 1,
    },
    emailOption: {
        marginLeft: 10,
    },
    collapseExpandButton: {
        color: '#fff',
    },
}));

export default function JobsProgressWindow() {
    const { pendingJobCount, isWindowExpanded, loadReportJobs } = useReportJobs();

    useEffect(() => {
        loadReportJobs();
    }, []);

    if (pendingJobCount > 0) {
        if (isWindowExpanded) {
            return <JobProgressPopup />;
        } else {
            return <JobsProgressWindowCollapsed />;
        }
    } else {
        return null;
    }
}

function JobProgressPopup() {
    const {
        pendingJobCount,
        shouldSendViaEmail,
        toggleSendReportMethod,
        expandWindow,
        collapseWindow,
        isWindowExpanded,
        reportJobs,
    } = useReportJobs();

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            Exporting {pendingJobCount} report(s)
                        </Typography>
                        {isWindowExpanded ? (
                            <IconButton
                                size="small"
                                className={classes.collapseExpandButton}
                                onClick={collapseWindow}
                            >
                                <KeyboardArrowUpIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                size="small"
                                className={classes.collapseExpandButton}
                                onClick={expandWindow}
                            >
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
                {isWindowExpanded && (
                    <div>
                        <FormControlLabel
                            className={classes.emailOption}
                            control={
                                <Checkbox
                                    checked={shouldSendViaEmail}
                                    onChange={toggleSendReportMethod}
                                    color="primary"
                                />
                            }
                            label="Send reports via e-mail once completed"
                        />
                        <ReportJobList reportJobs={reportJobs} />
                    </div>
                )}
            </Paper>
        </div>
    );
}

export function JobsProgressWindowCollapsed() {
    const { pendingJobCount, expandWindow } = useReportJobs();

    return (
        <Box m={1} textAlign="center">
            <Button
                variant="outlined"
                onClick={expandWindow}
                color="primary"
                endIcon={<OpenInNewIcon />}
            >
                Exporting {pendingJobCount} report(s)
            </Button>
        </Box>
    );
}

import React from 'react';
import classnames from 'classnames';

import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Tooltip from '@mui/material/Tooltip';

import { Caption } from 'widgets-v5/typography';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { RadioButton } from 'widgets-v5/radio-selector';
import { ComposableDataTable, Cell, Row } from 'widgets-v5/data-table';
import Spacer from 'widgets-v5/spacer';
import StandardInput from 'widgets-v5/standard-input';
import { CanSwitch, CanCase } from 'containers/can';
import { NeutralButton } from 'widgets-v5/buttons';
import { SimpleSelect } from 'widgets-v6/select';
import { MuiFormLabel } from 'widgets-v6/standard-input';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { PLATFORM_MAPPING } from 'common/constants/platforms';

import moment from 'moment-timezone';

import config from '../../../../../../config';

import { checkIsNewScheduledWeightedRotationMode } from 'states/resources/ads/business-logic';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import { getEnvironmentSettings } from 'services/environment';
import MultiPurposeCreativeTable from './multi-purporse-creative-table';
import { CreativeRotationByWeatherTable } from './creative-rotation-by-weather-table';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';
const PACING_LABELS = {
    impressions: 'Impressions',
    clicks: 'Clicks',
    billings: 'Revenue Budget',
    spend: 'Spend',
};

let MIXED_CREATIVE_ENABLED_DATE = moment('2018-04-15', 'YYYY-MM-DD');
if (config.DEV_SETTINGS_ENABLED) {
    MIXED_CREATIVE_ENABLED_DATE = moment('2018-04-15', 'YYYY-MM-DD');
}

function stopPropagation(e) {
    e.stopPropagation();
    e.preventDefault();
}

function formatPacing(pacingValue, pacingType) {
    switch (pacingType) {
        case 'impressions':
        case 'clicks':
            return formatNumber_whole(pacingValue);

        case 'billings':
        case 'spend':
            return formatNumber_currency(pacingValue);

        default:
            return pacingValue;
    }
}

class SingleCreativeTable extends React.Component {
    render() {
        let {
            creatives,
            selectSingleCreative,

            duplicateCreative,
            editCreative,
            disableEditingCreative,
            disableDuplicatingCreative,
            isCrossPlatformCampaign,
        } = this.props;

        let selectedCreatives = _(creatives)
            .filter(c => c.selected)
            .map(c => c.creativeId)
            .value();

        const selectedCreative = selectedCreatives[0];
        const disableCreativeRotationSelectorMenu =
            !!disableEditingCreative && !!disableDuplicatingCreative;

        return (
            <Box height="100%" maxHeight="400" overflow="auto">
                <ComposableDataTable>
                    <Row
                        key="header"
                        header={true}
                        className="ef3-creativeRotationSelector_creative"
                    >
                        <Cell />
                        <Cell className="ef3-creativeRotationSelector_name">Creative Name</Cell>
                        <Cell className="ef3-creativeRotationSelector_menu" />
                        <Cell className="ef3-creativeRotationSelector_preview">Preview</Cell>
                    </Row>
                    {_.map(creatives, creative => (
                        <Row
                            key={creative.creativeId}
                            disabled={creative.disableSelection}
                            className={classnames('ef3-creativeRotationSelector_creative', {
                                'ef3-creativeRotationSelector_creative--cpc-conflict':
                                    creative.hasCpcInsConflict,
                            })}
                        >
                            <Cell>
                                <RadioButton
                                    onClick={() => selectSingleCreative(creative.creativeId)}
                                    active={creative.creativeId === selectedCreative}
                                />
                            </Cell>
                            <Cell className="ef3-creativeRotationSelector_name">
                                <div>
                                    <Typography
                                        gutterBottom
                                        className="ef3-creativeRotationSelector_creative_name"
                                    >
                                        #{creative.id} {creative.name}
                                    </Typography>
                                    {isCrossPlatformCampaign && (
                                        <Grid container direction="row" spacing={1}>
                                            {_.map(creative.platforms, platform => (
                                                <Grid item key={platform}>
                                                    <Chip
                                                        size="small"
                                                        label={PLATFORM_MAPPING[platform].label}
                                                        variant="outlined"
                                                        color="primary"
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                    {creative.hasCpcInsConflict && (
                                        <InlineTooltip
                                            tooltip={
                                                <div>
                                                    <div>Warning: CPC Ads are paced by clicks</div>
                                                    <div>
                                                        and DoubleClick INS tags may not track
                                                        clicks properly!
                                                    </div>
                                                    <div>
                                                        This Ad will require frequent monitoring to
                                                        prevent overbuying
                                                    </div>
                                                </div>
                                            }
                                            position="right"
                                        >
                                            <span className="ef3-creativeRotationSelector__cpc-warning-icon">
                                                <i className="fa fa-fw fa-exclamation-triangle" />
                                            </span>
                                        </InlineTooltip>
                                    )}
                                    <div className="ef3-creativeRotationSelector_tags">
                                        <span className="ef3-creativeRotationSelector_tag">
                                            {creative.size}
                                        </span>
                                        <span className="ef3-creativeRotationSelector_tag">
                                            {creative.type}
                                        </span>
                                    </div>
                                </div>
                            </Cell>
                            {disableCreativeRotationSelectorMenu ? (
                                <Cell />
                            ) : (
                                <Cell className="ef3-creativeRotationSelector_menu">
                                    <div className="ef6-alignment__flex">
                                        <CanSwitch>
                                            <CanCase intent="campaign__view_and_edit">
                                                <InlineTooltip
                                                    tooltip="Edit"
                                                    top={25}
                                                    position="bottom"
                                                >
                                                    <NeutralButton
                                                        onClick={e => {
                                                            stopPropagation(e);
                                                            editCreative(creative.creativeId);
                                                        }}
                                                        icon={<i className="fa fa-fw fa-pencil" />}
                                                        style={{ marginRight: 5, padding: 8 }}
                                                    />
                                                </InlineTooltip>
                                            </CanCase>
                                        </CanSwitch>
                                        <CanSwitch>
                                            <CanCase intent="campaign__view_and_edit">
                                                <InlineTooltip
                                                    tooltip="Duplicate"
                                                    top={25}
                                                    position="bottom"
                                                >
                                                    <NeutralButton
                                                        onClick={e => {
                                                            stopPropagation(e);
                                                            duplicateCreative(creative.creativeId);
                                                        }}
                                                        icon={<i className="fa fa-fw fa-files-o" />}
                                                        style={{ marginRight: 5, padding: 8 }}
                                                    />
                                                </InlineTooltip>
                                            </CanCase>
                                        </CanSwitch>
                                    </div>
                                </Cell>
                            )}
                            <Cell className="ef3-creativeRotationSelector_preview">
                                <img
                                    className="ef3-creativeRotationSelector_previewImage"
                                    src={creative.previewImg}
                                />
                            </Cell>
                        </Row>
                    ))}
                </ComposableDataTable>
            </Box>
        );
    }
}

function CreativeControlButton({ creative, editCreative, duplicateCreative }) {
    return (
        <Cell className="ef3-creativeRotationSelector_menu">
            <div className="ef6-alignment__flex">
                <CanSwitch>
                    <CanCase intent="campaign__view_and_edit">
                        <InlineTooltip tooltip="Edit" top={25} position="bottom">
                            <NeutralButton
                                onClick={e => {
                                    stopPropagation(e);
                                    editCreative(creative.creativeId);
                                }}
                                icon={<i className="fa fa-fw fa-pencil" />}
                                style={{ marginRight: 5, padding: 8 }}
                            />
                        </InlineTooltip>
                    </CanCase>
                </CanSwitch>
                <CanSwitch>
                    <CanCase intent="campaign__view_and_edit">
                        <InlineTooltip tooltip="Duplicate" top={25} position="bottom">
                            <NeutralButton
                                onClick={e => {
                                    stopPropagation(e);
                                    duplicateCreative(creative.creativeId);
                                }}
                                icon={<i className="fa fa-fw fa-files-o" />}
                                style={{ marginRight: 5, padding: 8 }}
                            />
                        </InlineTooltip>
                    </CanCase>
                </CanSwitch>
            </div>
        </Cell>
    );
}

function getRotationMethodTip() {
    const environmentSettings = getEnvironmentSettings();
    return (
        <React.Fragment>
            <Typography variant="body1">
                <u>Single</u>
            </Typography>
            <Typography variant="body2">
                Choose a single creative to be delivered for the entire ad flight.
            </Typography>
            <br />
            <Typography variant="body1">
                <u>Even Rotation</u>
            </Typography>
            <Typography variant="body2">
                Choose multiple creatives to be delivered evenly with the same ratio.
            </Typography>
            <br />
            <Typography variant="body1">
                <u>Weighted Rotation</u>
            </Typography>
            <Typography variant="body2">
                Choose multiple creatives to be delivered and customize the ratio at which they
                appear.
            </Typography>
            <br />
            <Typography variant="body1">
                <u>Scheduled Rotation</u>
            </Typography>
            <Typography variant="body2">
                Setup a schedule to automatically switch between creatives throughout the ad flight.
            </Typography>
            {environmentSettings.canUseCreativeOptimization() && (
                <React.Fragment>
                    <br />
                    <Typography variant="body1">
                        <u>Optimize for Clicks</u>
                    </Typography>
                    <Typography variant="body2">
                        Prioritize serving creatives generating the best click performance.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        <u>Optimize for Conversions</u>
                    </Typography>
                    <Typography variant="body2">
                        At least one conversion is required to set up in this campaign.
                    </Typography>
                    <Typography variant="body2">
                        Prioritize serving creatives generating the best eCPA performance.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        <u>Weather Condition</u>
                    </Typography>
                    <Typography variant="body2">
                        Display different creatives based on the current weather condition of
                        targeted cities.
                    </Typography>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

class WeightedCreativeTable extends React.Component {
    handleCheck = (creative, firstSelectedCreativeFormat, campaign) => {
        if (shouldDisableCreativeSelection(creative, firstSelectedCreativeFormat, campaign)) {
            return;
        }

        const creativeId = creative.creativeId;
        const { creatives, rotationMode, selectEvenCreative, selectWeightedCreative } = this.props;
        let selectedCreatives = _(creatives)
            .filter(c => c.selected)
            .map(c => c.creativeId)
            .value();
        const onChange = _.includes(
            [CreativeRotationMethodMapping.OptimizedForClicks,
             CreativeRotationMethodMapping.Even,
             CreativeRotationMethodMapping.OptimizedForConversions,
             CreativeRotationMethodMapping.OptimizedForVideoCompletion],
            rotationMode
        )
            ? selectEvenCreative
            : selectWeightedCreative;

        if (_.includes(selectedCreatives, creativeId)) {
            const newValue = _.filter(selectedCreatives, item => item !== creativeId);
            onChange(newValue);
        } else {
            const newValue = [].concat(selectedCreatives, creativeId);
            onChange(newValue);
        }
    };

    render() {
        const { disableEditingCreative, disableDuplicatingCreative } = this.props;
        const disableCreativeRotationSelectorMenu =
            !!disableEditingCreative && !!disableDuplicatingCreative;
        let {
            rotationMode,
            assignedPacing,
            pacingColumnEnabled,
            primaryPacing,
            totalWeight,

            campaign,
            creatives,
            clearCreatives,

            changeWeight,
            shouldUpdateWeight,

            showWeightColumn,
            campaignBudgetAllocationMethod,
            isCrossPlatformCampaign,
            editCreative,
            duplicateCreative,
        } = this.props;

        const selectedCreatives = _.filter(creatives, creative => creative.selected);
        const selectedCreativeIds = _.map(selectedCreatives, creative => creative.creativeId);
        const firstSelectedCreativeWithMarkup = _.first(selectedCreatives);

        let firstSelectedCreativeFormat;
        if (firstSelectedCreativeWithMarkup) {
            firstSelectedCreativeFormat =
                firstSelectedCreativeWithMarkup && firstSelectedCreativeWithMarkup.type;
        }

        return (
            <Box height="100%" maxHeight="400" overflow="auto">
                <ComposableDataTable>
                    <Row key="headers" header>
                        <Cell />
                        <Cell className="ef3-creativeRotationSelector_name">Creative Name</Cell>
                        <Hidden lgDown>
                            <Cell />
                        </Hidden>
                        <Cell className="ef3-creativeRotationSelector_preview">Preview</Cell>
                        {showWeightColumn && (
                            <Cell className="ef3-creativeRotationSelector_weight">Weight</Cell>
                        )}
                        {pacingColumnEnabled && campaignBudgetAllocationMethod !== 'auto' && (
                            <Cell className="ef3-creativeRotationSelector_estimatedPacing">
                                {PACING_LABELS[primaryPacing]}
                            </Cell>
                        )}
                    </Row>
                    {moment(_.get(campaign, 'attr._created')).isAfter(
                        MIXED_CREATIVE_ENABLED_DATE
                    ) &&
                        !_.includes(
                            [CreativeRotationMethodMapping.OptimizedForClicks,
                             CreativeRotationMethodMapping.OptimizedForConversions,
                             CreativeRotationMethodMapping.OptimizedForVideoCompletion],
                            rotationMode
                        ) &&
                        this.props.showMessage && (
                            <Row className="ef3-creativeRotationSelector__row--warning">
                                <Cell>
                                    <i
                                        onClick={this.props.removeWarningMessage}
                                        className="ef3-creativeRotationSelector__row--warning-remove-icon fa fa-times-circle"
                                    />
                                </Cell>
                                <Cell colSpan={pacingColumnEnabled ? 5 : 4}>
                                    Selected Creatives must have the same format when using Creative
                                    Rotation
                                </Cell>
                            </Row>
                        )}
                    {_.map(creatives, (creative, index) => (
                        <Row
                            key={creative.creativeId}
                            disabled={shouldDisableCreativeSelection(
                                creative,
                                firstSelectedCreativeFormat,
                                campaign
                            )}
                            className={classnames({
                                'ef3-creativeRotationSelector_creative': true,
                                'ef3-creativeRotationSelector_creative--cpc-conflict':
                                    creative.hasCpcInsConflict,
                            })}
                        >
                            <Cell>
                                <MuiFormLabel className="ef4-checkbox-data-table__selector-click-area">
                                    <input
                                        type="checkbox"
                                        className="ef4-checkbox-data-table__selector"
                                        checked={_.includes(
                                            selectedCreativeIds,
                                            creative.creativeId
                                        )}
                                        onChange={() =>
                                            this.handleCheck(
                                                creative,
                                                firstSelectedCreativeFormat,
                                                campaign
                                            )
                                        }
                                    />
                                </MuiFormLabel>
                            </Cell>
                            <Cell className="ef3-creativeRotationSelector_name">
                                <div>
                                    <Typography
                                        gutterBottom
                                        className="ef3-creativeRotationSelector_creative_name"
                                    >
                                        #{creative.id} {creative.name}
                                    </Typography>
                                    {isCrossPlatformCampaign && (
                                        <Grid container direction="row" spacing={1}>
                                            {_.map(creative.platforms, platform => (
                                                <Grid item key={platform}>
                                                    <Chip
                                                        size="small"
                                                        label={PLATFORM_MAPPING[platform].label}
                                                        variant="outlined"
                                                        color="primary"
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                    {creative.hasCpcInsConflict && (
                                        <InlineTooltip
                                            tooltip={
                                                <div>
                                                    <div>Warning: CPC Ads are paced by clicks</div>
                                                    <div>
                                                        and DoubleClick INS tags may not track
                                                        clicks properly!
                                                    </div>
                                                    <div>
                                                        This Ad will require frequent monitoring to
                                                        prevent overbuying
                                                    </div>
                                                </div>
                                            }
                                            position="right"
                                        >
                                            <span className="ef3-creativeRotationSelector__cpc-warning-icon">
                                                <i className="fa fa-fw fa-exclamation-triangle" />
                                            </span>
                                        </InlineTooltip>
                                    )}
                                    <div className="ef3-creativeRotationSelector_tags">
                                        <span className="ef3-creativeRotationSelector_tag">
                                            {creative.size}
                                        </span>
                                        <span className="ef3-creativeRotationSelector_tag">
                                            {creative.type}
                                        </span>
                                    </div>
                                </div>
                            </Cell>
                            {disableCreativeRotationSelectorMenu ? (
                                <Hidden lgDown>
                                    <Cell />
                                </Hidden>
                            ) : (
                                <Hidden lgDown>
                                    <CreativeControlButton
                                        creative={creative}
                                        editCreative={editCreative}
                                        duplicateCreative={duplicateCreative}
                                    />
                                </Hidden>
                            )}
                            <Cell className="ef3-creativeRotationSelector_preview">
                                <img
                                    className="ef3-creativeRotationSelector_previewImage"
                                    src={creative.previewImg}
                                />
                                <Hidden lgUp>
                                    <CreativeControlButton
                                        creative={creative}
                                        editCreative={editCreative}
                                        duplicateCreative={duplicateCreative}
                                    />
                                </Hidden>
                            </Cell>
                            {showWeightColumn && (
                                <Cell className="ef3-creativeRotationSelector_weight">
                                    <div>
                                        <StandardInput
                                            type="text"
                                            id={`weightVal-${index}-${creative.creativeId}`}
                                            disabled={
                                                rotationMode === CreativeRotationMethodMapping.Even || !creative.selected
                                            }
                                            onChange={
                                                rotationMode === CreativeRotationMethodMapping.Even || !creative.selected
                                                    ? null
                                                    : newWeight => {
                                                          const trimmedWeight = newWeight.trim();

                                                          // The user has cleared the field?
                                                          if (trimmedWeight === '') {
                                                              return changeWeight(
                                                                  creative.creativeId,
                                                                  0
                                                              );
                                                          }

                                                          changeWeight(
                                                              creative.creativeId,
                                                              parseInt(trimmedWeight)
                                                          );
                                                      }
                                            }
                                            shouldAllowInput={shouldUpdateWeight(
                                                creative.creativeId
                                            )}
                                            value={creative.weight || ''}
                                        />{' '}
                                        <span>%</span>
                                    </div>
                                </Cell>
                            )}
                            {pacingColumnEnabled && campaignBudgetAllocationMethod !== 'auto' && (
                                <Cell className="ef3-creativeRotationSelector_estimatedPacing">
                                    {formatPacing(creative.estimatedPacing, primaryPacing)}
                                </Cell>
                            )}
                        </Row>
                    ))}
                    <Row key="final">
                        <Cell />
                        <Cell />
                        <Hidden lgDown>
                            <Cell />
                        </Hidden>
                        <Cell />
                        {showWeightColumn && <Cell>{totalWeight}%</Cell>}
                        {pacingColumnEnabled && campaignBudgetAllocationMethod !== 'auto' && (
                            <Cell>{formatPacing(assignedPacing, primaryPacing)}</Cell>
                        )}
                    </Row>
                    <Row>
                        <Cell colSpan={pacingColumnEnabled ? 6 : 5}>
                            <div
                                onClick={() => clearCreatives()}
                                className="ef3-creativeRotationSelector_clearSelection"
                            >
                                Clear Selection
                            </div>
                        </Cell>
                    </Row>
                </ComposableDataTable>
            </Box>
        );
    }
}

export default class CreativeRotationSelector extends React.Component {
    state = {
        showMessage: true,
    };

    removeWarningMessage = () => {
        this.setState({
            showMessage: false,
        });
    };

    hasRotationErrors = errors => {
        return !!_.find(_.keys(errors), field => field.indexOf('rotation_rules') === 0);
    };

    render() {
        let {
            rotationMode,
            duplicateCreative,
            editCreative,
            switchMode,
            rotationRules,
            creatives,
            weatherRotationSettings,
            // Weighted & single creative rotation props
            selectedCreatives,
            assignedPacing,
            pacingColumnEnabled,
            primaryPacing,
            totalWeight,
            changeWeight,
            shouldUpdateWeight,

            selectSingleCreative,
            selectEvenCreative,
            selectWeightedCreative,
            clearCreatives,

            // Scheduled creative rotation props
            scheduledCreatives,
            updateScheduleRow,
            addScheduleRow,
            removeScheduleRow,
            adTimezone,
            adEnd,
            scheduledRotationWarningEnabled,
            campaign,
            mode,
            disableScheduledRotation,
            disableEditingCreative,
            disableDuplicatingCreative,
            showWeightColumn,
            errors,
            showErrors,
            hasConversions,
            onCreativeRotationByWeatherChange,
            isCrossPlatformCampaign,
            creativeWarning,
        } = this.props;

        // Bug on master: when editing ad creatives from the overview page, campaign does not have attr
        const isCTVCampaign = [campaign?.attr?.type, campaign?.type].includes(CampaignTypeMapping.CTV);
        const isDOOHCampaign = [campaign?.attr?.type, campaign?.type].includes(CampaignTypeMapping.DOOH);

        let workspace;
        if (!creatives || !creatives.length) {
            workspace = <div className="ef3-creativeRotationSelector">No existing creatives</div>;
        } else if (rotationMode === CreativeRotationMethodMapping.Single) {
            workspace = (
                <SingleCreativeTable
                    creatives={creatives}
                    selectedCreatives={selectedCreatives}
                    selectSingleCreative={selectSingleCreative}
                    clearCreatives={clearCreatives}
                    duplicateCreative={duplicateCreative}
                    editCreative={editCreative}
                    disableEditingCreative={disableEditingCreative}
                    disableDuplicatingCreative={disableDuplicatingCreative}
                    isCrossPlatformCampaign={isCrossPlatformCampaign}
                />
            );
        } else if (
            rotationMode === CreativeRotationMethodMapping.Weighted ||
            rotationMode === CreativeRotationMethodMapping.Even ||
            rotationMode === CreativeRotationMethodMapping.OptimizedForClicks ||
            rotationMode === CreativeRotationMethodMapping.OptimizedForConversions ||
            rotationMode === CreativeRotationMethodMapping.OptimizedForVideoCompletion
        ) {
            workspace = (
                <WeightedCreativeTable
                    rotationMode={rotationMode}
                    showWeightColumn={showWeightColumn}
                    assignedPacing={assignedPacing}
                    pacingColumnEnabled={pacingColumnEnabled}
                    primaryPacing={primaryPacing}
                    totalWeight={totalWeight}
                    selectedCreatives={selectedCreatives}
                    campaign={campaign}
                    creatives={creatives}
                    selectEvenCreative={selectEvenCreative}
                    selectWeightedCreative={selectWeightedCreative}
                    clearCreatives={clearCreatives}
                    changeWeight={changeWeight}
                    shouldUpdateWeight={shouldUpdateWeight}
                    duplicateCreative={duplicateCreative}
                    editCreative={editCreative}
                    showMessage={this.state.showMessage}
                    removeWarningMessage={this.removeWarningMessage}
                    disableEditingCreative={disableEditingCreative}
                    disableDuplicatingCreative={disableDuplicatingCreative}
                    campaignBudgetAllocationMethod={campaign.budget_allocation_method}
                    isCrossPlatformCampaign={isCrossPlatformCampaign}
                />
            );
        } else if (rotationMode === CreativeRotationMethodMapping.Weather) {
            workspace = (
                <CreativeRotationByWeatherTable
                    errors={errors}
                    showErrors={showErrors}
                    campaign={campaign}
                    creatives={creatives}
                    weatherRotationSettings={weatherRotationSettings}
                    onChange={onCreativeRotationByWeatherChange}
                    rotationRules={rotationRules}
                    isCrossPlatformCampaign={isCrossPlatformCampaign}
                />
            );
        } else {
            const isNewScheduledRotation = checkIsNewScheduledWeightedRotationMode({
                scheduled: scheduledCreatives,
            });
            if (isNewScheduledRotation) {
                workspace = (
                    <MultiPurposeCreativeTable
                        switchMode={switchMode}
                        errors={errors}
                        showErrors={showErrors}
                        rotationErrors={showErrors && this.hasRotationErrors(errors)}
                        updateScheduleRow={updateScheduleRow}
                        addScheduleRow={addScheduleRow}
                        removeScheduleRow={removeScheduleRow}
                        clearCreatives={clearCreatives}
                        warningEnabled={scheduledRotationWarningEnabled}
                        campaign={campaign}
                        creatives={creatives}
                        scheduledCreatives={scheduledCreatives}
                        selectEvenCreative={selectEvenCreative}
                        selectWeightedCreative={selectWeightedCreative}
                        timezone={adTimezone}
                        adEnd={adEnd}
                        showMessage={this.state.showMessage}
                        addDateRange={this.props.addDateRange}
                        removeMultiCreatives={this.props.removeMultiCreatives}
                        removeScheduledWeightedCreative={this.props.removeScheduledWeightedCreative}
                        removeDateRange={this.props.removeDateRange}
                        changeScheduledWeightedCreativeEndDate={
                            this.props.changeScheduledWeightedCreativeEndDate
                        }
                        selectScheduledWeightedCreative={this.props.selectScheduledWeightedCreative}
                        changeScheduledWeightedCreativeWeight={
                            this.props.changeScheduledWeightedCreativeWeight
                        }
                        changeScheduledEvenWeighted={this.props.changeScheduledEvenWeighted}
                        addScheduledWeightedCreative={this.props.addScheduledWeightedCreative}
                        isCrossPlatformCampaign={isCrossPlatformCampaign}
                        creativeWarning={creativeWarning}
                        disableScheduledRotation={disableScheduledRotation}
                    />
                );
            } else {
                workspace = (
                    <MultiPurposeCreativeTable
                        switchMode={switchMode}
                        errors={errors}
                        showErrors={showErrors}
                        rotationErrors={showErrors && this.hasRotationErrors(errors)}
                        updateScheduleRow={updateScheduleRow}
                        addScheduleRow={addScheduleRow}
                        removeScheduleRow={removeScheduleRow}
                        clearCreatives={clearCreatives}
                        warningEnabled={scheduledRotationWarningEnabled}
                        campaign={campaign}
                        creatives={creatives}
                        scheduledCreatives={scheduledCreatives}
                        selectEvenCreative={selectEvenCreative}
                        selectWeightedCreative={selectWeightedCreative}
                        timezone={adTimezone}
                        adEnd={adEnd}
                        showMessage={this.state.showMessage}
                        addDateRange={this.props.addDateRange}
                        removeMultiCreatives={this.props.removeMultiCreatives}
                        removeScheduledWeightedCreative={this.props.removeScheduledWeightedCreative}
                        removeDateRange={this.props.removeDateRange}
                        changeScheduledWeightedCreativeEndDate={
                            this.props.changeScheduledWeightedCreativeEndDate
                        }
                        selectScheduledWeightedCreative={this.props.selectScheduledWeightedCreative}
                        changeScheduledWeightedCreativeWeight={
                            this.props.changeScheduledWeightedCreativeWeight
                        }
                        changeScheduledEvenWeighted={this.props.changeScheduledEvenWeighted}
                        addScheduledWeightedCreative={this.props.addScheduledWeightedCreative}
                        isCrossPlatformCampaign={isCrossPlatformCampaign}
                        disableScheduledRotation={disableScheduledRotation}
                        creativeWarning={creativeWarning}
                    />
                );
            }
        }

        const environmentSettings = getEnvironmentSettings();

        let items = [
            { label: 'Single Creative', value: CreativeRotationMethodMapping.Single },
            { label: 'Even Rotation', value: CreativeRotationMethodMapping.Even },
            { label: 'Weighted Rotation', value: CreativeRotationMethodMapping.Weighted },
        ];

        if (mode === 'new' || campaign.isUsingConsolidateCreativeOptions) {
            items = [];
        }

        items.push({ label: 'Custom Rotation', value: CreativeRotationMethodMapping.Scheduled });

        if (environmentSettings.canUseCreativeOptimization()) {
            if (!isCTVCampaign && !isDOOHCampaign) {
                items.push({ label: 'Optimize For Clicks', value: CreativeRotationMethodMapping.OptimizedForClicks });
                items.push({
                    label: 'Optimize For Conversions',
                    value: CreativeRotationMethodMapping.OptimizedForConversions,
                    disabled: !hasConversions,
                });
            }

            if (isCTVCampaign) {
                items.push({
                    label: 'Optimize For Video Completion',
                    value: CreativeRotationMethodMapping.OptimizedForVideoCompletion,
                });
            }
        }
        if (environmentSettings.canUseWeatherConditions()) {
            items.push({
                label: 'Weather Condition',
                value: CreativeRotationMethodMapping.Weather,
            });
        }

        return (
            <div>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={4}>
                        <SimpleSelect
                            label="Rotation Method"
                            options={items}
                            value={rotationMode}
                            margin="normal"
                            onChange={e => {
                                switchMode(e.target.value);
                            }}
                        />
                    </Grid>
                    <Hidden lgUp>
                        <Grid item xs={4}>
                            <div className="ef3-creativeRotationSelector_learnMore">
                                <Tooltip title={getRotationMethodTip()} placement="right">
                                    <i className="fa fa-question-circle" />
                                </Tooltip>
                            </div>
                        </Grid>
                    </Hidden>
                </Grid>
                <FormControl
                    error={showErrors && this.hasRotationErrors(errors)}
                    margin="normal"
                    fullWidth
                >
                    <MuiFormLabel required>Select creative(s)</MuiFormLabel>
                    {isCTVCampaign && (
                        <React.Fragment>
                            <Spacer type="small" />
                            <Caption type="info">
                                Only video creatives can be assigned to {PLATFORM_MAPPING[CampaignTypeMapping.CTV].label} campaigns.
                            </Caption>
                            <Spacer type="small" />
                        </React.Fragment>
                    )}
                    {workspace}
                </FormControl>
            </div>
        );
    }
}

function shouldDisableCreativeSelection(creative, firstSelectedCreativeFormat, campaign) {
    if (creative.disableSelection) {
        return true;
    }

    const campaignCreated = _.get(campaign, 'attr._created');
    if (moment(campaignCreated).isBefore(MIXED_CREATIVE_ENABLED_DATE)) {
        return false;
    }
    if (!firstSelectedCreativeFormat) {
        return false;
    }

    return creative.type !== firstSelectedCreativeFormat;
}

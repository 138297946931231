import { LEVEL_BY_NAME } from 'common/constants/forensiq-risk-levels';
import {
    CreativeRotationMethodMapping,
    BiddingStrategyMode,
    DeviceOSMapping,
} from 'states/resources/ads/business-logic';

export const payload_formData = {
    // --- Basics ---//
    platform: 'inapp',
    name: '',
    timezone: 'UTC',
    start: '',
    end: '',
    notes: '',
    custom_fields: [],

    // --- Creative ---//
    creative: [],
    rotation_rules: {
        mode: CreativeRotationMethodMapping.Single,
        weighted: [],
        scheduled: [],
        weather: [],
    },

    // --- Target ---//

    geo_targeting_settings: {
        categories: [],
        custom_layers: [],
        category_layers: [],
        radius: 500,
        retarget: true,
        target: true,
        days_to_retarget: 21,
    },

    // 'geotargets': [{ 'country': 'CA', 'include': [], 'exclude': [] }],
    geotargets: [],
    target_age_groups: [],
    target_genders: [],
    dayparts: [],
    dayparts_local: null,
    weekparts: [true, true, true, true, true, true, true],
    weekparts_local: null,
    target_device_language: [],
    target_app_store_cat: [],
    target_app_store_cat_exclude: [],
    target_app_categories: [],
    target_iab_categories: [],
    target_iab_categories_exclude: [],
    geoboxes: [],
    target_device_os: [DeviceOSMapping.IOS, DeviceOSMapping.Android],
    target_device_language_exclude: [],
    target_carriers: [],
    target_dooh_venues: [],
    target_dooh_venues_exclude: [],

    tactics_enabled: false,
    tactics_generators: [],

    // Deprecated field for frequency cap
    // NOTE: this field has been temporarily set to 5 as a safe default
    // until we iron out kinks in the deployment
    max_user_frequency: 5,

    // Replacement field for frequency cap
    fcaps: {
        imp: 5,
        interval_count: 1,
        interval_unit: 'days',
        start: Date.now(),
    },

    // --- Budget ---//
    max_cpm_rate_local: 0,

    max_total_impressions: 0,
    max_daily_impressions: 0,

    max_total_clicks: 0,
    max_daily_clicks: 0,

    max_total_spend_local: 0,
    max_daily_spend_local: 0,

    max_daily_billings: 0,
    max_total_billings: 0,

    non_billable_volume: 0,

    // --- Revenue Managemenet  ---//
    ef_billing_terms: 'CPM',
    billable_volume: 0,
    billing_rate: 0,
    billing_enabled: true,
    primary_pacing: 'impressions',
    use_front_load_pacing: false,
    use_deal_price_to_bid: false,
    ubx_audiences: [],
    audiences: [],
    audience_exclude: [],

    // --- Inventory Prediction ---//
    predicted_daily_impressions: null,
    predicted_daily_uniques: null,

    predicted_total_impressions_min: null,
    predicted_total_uniques_min: null,
    predicted_total_impressions_max: null,
    predicted_total_uniques_max: null,

    // Brand Safety
    forensiq_risk_cap: LEVEL_BY_NAME['allow_low_risk'].value,
    ias: {
        fraudPrevention: {
            risk: {
                value: 1,
                segId: -1,
            },
            unrateable: {
                value: 0,
                segId: -1,
            },
            ip: {
                value: 0,
                segId: -1,
            },
        },
        brandSafety: {
            adult: {
                value: 0,
                segId: -1,
            },
            alcohol: {
                value: 0,
                segId: -1,
            },
            gambling: {
                value: 0,
                segId: -1,
            },
            hateSpeech: {
                value: 0,
                segId: -1,
            },
            illegalDownloads: {
                value: 0,
                segId: -1,
            },
            illegalDrugs: {
                value: 0,
                segId: -1,
            },
            offensiveLanguage: {
                value: 0,
                segId: -1,
            },
            violence: {
                value: 0,
                segId: -1,
            },
            unrateable: {
                value: 0,
                segId: -1,
            },
        },
        viewability: {
            value: 0,
            segId: -1,
        },
        contextualTargeting: {
            value: [],
            segId: [],
        },
        groupmViewability: {
            value: 0,
            segId: -1,
        },
        publicisViewability: {
            value: 0,
            segId: -1,
        },
    },

    // First Party Fees
    fta_fee: 0,
    audience_fee: 0,
    fta: {
        enabled: null,
        partner_id: null,
        line_id: null,
        location_list: null,
    },
    thirdPartyFees: [],
    exchanges_blocked_ui: [],
    exchanges_blocked: [],
    applistSettings: {
        mode: 'blacklist',
        listIds: [],
    },
    unalteredDuplicate: false,
    isStartSet: true,
    isEndSet: true,
    deals: [],
    dealApplicationType: 'deal_augmented',
    automatic_bid_price: {
        max_ecpm_local: 0,
        apply_max_ecpm: true,
        optimizing_strategy: {
            mode: BiddingStrategyMode.SpendFullBudget,
            max_ecpc_local: 0,
            max_ecpcv_local: 0,
        },
    },
    bid_strategy_mode: 'fixed_bid_price',
    carriers_exclude: [],
    weatherConditions: {
        logicRelationship: 'or',
        conditions: [],
    },
    isIasPostBidVerificationEnabled: null,
    conversionsForCreativeOptimization: [],
    whitelistedAppsAndSites: [],
    blacklistedAppsAndSites: [],
    platforms: ['inapp', 'mweb', 'desktop', 'ctv'],
};

export const CTV_DEVICE_OS_OPTIONS = [
    { label: 'Android TV', value: DeviceOSMapping.AndroidTV },
    { label: 'Apple TV OS', value: DeviceOSMapping.AppleTV },
    { label: 'Chromecast', value: DeviceOSMapping.Chromecast },
    { label: 'Fire OS', value: DeviceOSMapping.Fire },
    { label: 'LG Web OS', value: DeviceOSMapping.LG },
    { label: 'Roku OS', value: DeviceOSMapping.Roku },
    { label: 'Samsung Tizen OS', value: DeviceOSMapping.Samsung },
    { label: 'Others', value: DeviceOSMapping.CTVOthers },
];

export const DESKTOP_DEVICE_OS_OPTIONS = [
    { value: DeviceOSMapping.Windows, label: 'Windows' },
    { value: DeviceOSMapping.Mac, label: 'Mac' },
    { value: DeviceOSMapping.Others, label: 'Others' },
];

export const INAPP_DEVICES_OS_OPTIONS = [
    { value: DeviceOSMapping.IOS, label: 'iOS' },
    { value: DeviceOSMapping.Android, label: 'Android' },
];

export const DOOH_DEVICES_OS_OPTIONS = [{ label: 'DOOH', value: DeviceOSMapping.DOOH }];

/**
 * All options above, consolidated into one variable, to be used on ad summary
 */
export const ALL_DEVICE_OPTIONS = [
    ...DESKTOP_DEVICE_OS_OPTIONS,
    ...INAPP_DEVICES_OS_OPTIONS,
    ...CTV_DEVICE_OS_OPTIONS,
    ...DOOH_DEVICES_OS_OPTIONS,
];

export const payload_nonFormData = {
    billing_type: 'fixed',
    paused: true,
    cpc_rate: '',
    cpm_rate: '',

    status: '',
    max_cpm_rate: 0,
    restrict_ips: [],
};

export const payload_updateOnly = {
    // update only (not present in resources)
    comment: '',
};

import _ from 'lodash';
import React, { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { makeStyles } from '@mui/styles';

import OwnOrganizationDashboard from './own-organization';
import ChildOrganizationDashboard from './child-organization';
import { can } from 'services/can';

const tabs = [
    {
        label: 'Manage Organization',
        value: 'own',
        intent: 'admin_org__view',
    },
    {
        label: 'Manage Sub-Organizations',
        value: 'children',
        intent: 'admin_subOrg__view',
    },
];

const adminStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    dashboardContainer: {
        padding: theme.spacing(3),
    },
}));

const Admin = () => {
    const [currentTab, setCurrentTab] = useState('own');
    const classes = adminStyles();
    const onChangeTab = (event, value) => {
        setCurrentTab(value);
    };
    return (
        <div>
            <AppBar position="static" color="inherit" elevation={1}>
                <Tabs
                    value={currentTab}
                    onChange={onChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {_.map(tabs, tab => {
                        return can(tab.intent) ? (
                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                        ) : null;
                    })}
                </Tabs>
            </AppBar>
            <div className={classes.dashboardContainer}>
                {currentTab === 'own' ? (
                    <OwnOrganizationDashboard />
                ) : (
                    <ChildOrganizationDashboard />
                )}
            </div>
        </div>
    );
};

export default Admin;

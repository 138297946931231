import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import UUID from 'uuid';

import { getEnvironmentSettings } from 'services/environment';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import actions from './actions';
import selector from './selector';

import BasicInformation from './modules/basic-information';
import CreativeSetup from './modules/creative-setup';
import Contacts from './modules/contacts';
import CustomFields from './modules/custom-fields';
import FeatureSets from './modules/feature-sets';
import Fees from './modules/fees';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import WarningIcon from '@mui/icons-material/Warning';

const LoadingContainer = withStyles(() => ({
    root: {
        height: '100%',
    },
}))(Grid);

const SubmitButtonLoadingContainer = withStyles(() => ({
    root: {
        width: '24px',
        height: '24px',
    },
}))(Grid);
const Loader = withStyles(() => ({
    root: {
        width: '24px !important',
        height: '24px !important',
    },
}))(CircularProgress);

const useStyles = makeStyles(theme => ({
    sectionErrorText: {
        color: 'red',
    },
    dialogContentRoot: {
        padding: 0,
    },
    sectionContainer: {
        height: 550,
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '20px 30px',
    },
    sidebar: {
        marginTop: '-10px',
        borderRight: `1px solid ${theme.palette.grey[200]}`,
        paddingRight: 0,
    },
    title: {
        padding: '10px 24px',
    },
}));

const orgTypeLabelMap = {
    admin: 'Admin',
    media_buyer: 'Media Buyer',
    advertiser: 'Advertiser',
    sales_rep: 'Sales Rep',
    partner: 'Partner',
};

const formSectionOptions = [
    {
        label: 'Basic',
        value: 'basic',
    },
    {
        label: 'Fees',
        value: 'fees',
    },
    {
        label: 'Creative',
        value: 'creative',
    },
    {
        label: 'Feature Sets',
        value: 'feature_sets',
    },
    {
        label: 'Auditors & Auditees',
        value: 'contacts',
    },
    {
        label: 'Custom Fields',
        value: 'custom_fields',
    },
];

const AdminOrgForm = ({
    dispatch,
    isFormOpen,
    isLoading,
    draft,
    isSubmitting,
    refreshOrganization,
    mode,
    relationship,
    sectionsWithErrors,
    showErrors,
    activeFormSection,
}) => {
    const classes = useStyles();
    const handleClose = () => dispatch(actions.closeForm());

    const updateDraft = (field, value) => {
        dispatch(actions.updateDraft(field, value));
    };

    const updateCreativeSetup = (creativeFormat, settingName, value) => {
        const { creatives } = draft;
        const newCreativeSettings = {
            ...creatives,
            [creativeFormat]: { ...creatives[creativeFormat], [settingName]: value },
        };
        updateDraft('creatives', newCreativeSettings);
    };
    const addCustomField = () => {
        const { custom_fields } = draft;
        const newCustomField = {
            value: '',
            required: false,
            name: '',
            key: Date.now().toString(),
        };
        const newCustomFields = _.concat(custom_fields, newCustomField);
        updateDraft('custom_fields', newCustomFields);
    };
    const removeCustomField = key => {
        const { custom_fields } = draft;
        const filteredCustomFields = _.filter(custom_fields, field => field.key !== key);
        updateDraft('custom_fields', filteredCustomFields);
    };
    const updateCustomField = (customFieldKey, fieldToUpdate, value) => {
        const { custom_fields } = draft;
        const newCustomFields = _.map(custom_fields, field => {
            if (field.key !== customFieldKey) {
                return field;
            }
            const updatedCustomField = { ...field, [fieldToUpdate]: value };
            if (fieldToUpdate === 'name') {
                updatedCustomField.key = UUID.v4();
            }
            return updatedCustomField;
        });
        updateDraft('custom_fields', newCustomFields);
    };

    const handleToggleSegment = (segment, checked) => {
        if (!checked) {
            const newRestrictedSegments = _.concat(draft.restrictedSegments, segment);
            updateDraft('restrictedSegments', newRestrictedSegments);
        } else {
            const newRestrictedSegments = _.filter(
                draft.restrictedSegments,
                restrictedSegment => restrictedSegment !== segment
            );
            updateDraft('restrictedSegments', newRestrictedSegments);
        }
    };

    const handleAudienceRateChange = (segment, value) => {
        const newAudienceSegmentMap = {
            ...draft.audienceRatesMap,
            [segment]: { ...draft.audienceRatesMap[segment], fee: value },
        };
        updateDraft('audienceRatesMap', newAudienceSegmentMap);
    };
    const handleSave = async () => {
        await dispatch(actions.save(refreshOrganization));
    };

    let showRestrictedFields = false;

    if (mode === 'edit') {
        const relationship_inDraft = relationship;
        if (_.includes(['child'], relationship_inDraft)) {
            const orgType_inDraft = draft.type;
            if (_.includes(['admin', 'media_buyer'], orgType_inDraft)) {
                showRestrictedFields = true;
            }
            if (_.includes(['co_managed_client', 'client'], orgType_inDraft)) {
                showRestrictedFields = false;
            }
        }
    } else if (mode === 'create') {
        // When creating a new org the newly created org is a child so subSection needs
        // to conform with child visibility rule.
        const orgType_inDraft = draft.type;
        if (_.includes(['admin', 'media_buyer'], orgType_inDraft)) {
            showRestrictedFields = true;
        }
        if (_.includes(['co_managed_client', 'client'], orgType_inDraft)) {
            showRestrictedFields = false;
        }
    }

    const header =
        mode === 'create'
            ? `New ${orgTypeLabelMap[draft.type === 'client' ? draft.client_type : draft.type]}`
            : 'Edit Organization';

    let availableFormSections = formSectionOptions;

    if (!showRestrictedFields) {
        availableFormSections = _.filter(
            availableFormSections,
            option => option.value !== 'creative' && option.value !== 'contacts'
        );
    }

    if (draft.type !== 'media_buyer' && draft.type !== 'admin') {
        availableFormSections = _.filter(
            availableFormSections,
            option => option.value !== 'custom_fields'
        );
    }

    const envSettings = getEnvironmentSettings();

    if (!showRestrictedFields || !envSettings.canUseAdminFeatureSets()) {
        availableFormSections = _.filter(
            availableFormSections,
            option => option.value !== 'feature_sets'
        );
    }

    if (draft.type !== 'media_buyer') {
        availableFormSections = _.filter(availableFormSections, option => option.value !== 'fees');
    }

    const handleAddBillingTier = type => {
        dispatch(actions.addBillingTier(type));
    };

    const handleDeleteBillingTier = ({ type, tierId }) => {
        dispatch(actions.deleteBillingTier({ type, tierId }));
    };

    const handleEditBillingTierMax = ({ type, tierId, value }) => {
        dispatch(actions.editBillingTierMax({ type, tierId, value }));
    };

    const handleEditBillingTierFee = ({ type, tierId, value }) => {
        dispatch(actions.editBillingTierFee({ type, tierId, value }));
    };

    return (
        <Dialog fullWidth maxWidth="md" open={isFormOpen} onClose={handleClose}>
            <DialogTitle className={classes.title}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{header}</Typography>
                    <IconButton onClick={handleClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContentRoot}>
                {isLoading ? (
                    <LoadingContainer container justify="center" alignItems="center">
                        <CircularProgress />
                    </LoadingContainer>
                ) : (
                    <Grid container direction="row">
                        <Grid item xs={3} className={classes.sidebar}>
                            <List>
                                {_.map(availableFormSections, section => (
                                    <ListItem
                                        key={section.value}
                                        selected={activeFormSection === section.value}
                                        button
                                        onClick={() =>
                                            dispatch(actions.changeFormSection(section.value))
                                        }
                                    >
                                        <ListItemText
                                            className={
                                                _.includes(sectionsWithErrors, section.value) &&
                                                showErrors
                                                    ? classes.sectionErrorText
                                                    : ''
                                            }
                                        >
                                            {section.label}
                                        </ListItemText>
                                        {_.includes(sectionsWithErrors, section.value) &&
                                            showErrors && (
                                                <WarningIcon fontSize="small" color="secondary" />
                                            )}
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={9} className={classes.sectionContainer}>
                            <div>
                                {(() => {
                                    switch (activeFormSection) {
                                        case 'basic':
                                            return (
                                                <BasicInformation
                                                    onChange={updateDraft}
                                                    canEditRestrictedFields={showRestrictedFields}
                                                    onAddBillingTier={handleAddBillingTier}
                                                    onDeleteBillingTier={handleDeleteBillingTier}
                                                    onEditBillingTierMax={handleEditBillingTierMax}
                                                    onEditBillingTierFee={handleEditBillingTierFee}
                                                />
                                            );
                                        case 'fees':
                                            return (
                                                <Fees
                                                    onChange={updateDraft}
                                                    canEditRestrictedFields={showRestrictedFields}
                                                    onAddBillingTier={handleAddBillingTier}
                                                    onDeleteBillingTier={handleDeleteBillingTier}
                                                    onEditBillingTierMax={handleEditBillingTierMax}
                                                    onEditBillingTierFee={handleEditBillingTierFee}
                                                />
                                            );
                                        case 'creative':
                                            return <CreativeSetup onChange={updateCreativeSetup} />;
                                        case 'feature_sets':
                                            return (
                                                <FeatureSets
                                                    onChange={updateDraft}
                                                    onToggleSegment={handleToggleSegment}
                                                    onAudienceRateChange={handleAudienceRateChange}
                                                />
                                            );
                                        case 'contacts':
                                            return <Contacts onChange={updateDraft} />;
                                        case 'custom_fields':
                                            return (
                                                <CustomFields
                                                    addCustomField={addCustomField}
                                                    removeCustomField={removeCustomField}
                                                    updateCustomField={updateCustomField}
                                                />
                                            );
                                    }
                                })()}
                            </div>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    {isSubmitting ? (
                        <SubmitButtonLoadingContainer
                            container
                            justify="center"
                            alignItems="center"
                        >
                            <Loader fontSize="small" color="inherit" />
                        </SubmitButtonLoadingContainer>
                    ) : (
                        'Save'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(selector)(AdminOrgForm);

import _ from 'lodash';
import c from 'common/constants/flux-events';

function getDateStamp() {
    const date = new Date();
    return date.toISOString();
}

const initialState = {
    isLoading: false,
    currentView: {
        entityType: 'implicitLayer',
        entityId: void 0,
    },
    eTag: getDateStamp(),
};

export const NAME = 'poiViewer';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.OVERVIEW_ADS__AD_DRAFT_EDIT__SHOW_GEO_ENTITY_IN_MAP: {
            const currentView = {
                entityType: action.payload.entityType,
                entityId: action.payload.entityId,
            };

            const o = {
                ...state,
                currentView,
            };
            return o;
        }

        case c.OVERVIEW_ADS__AD_DRAFT_EDIT__REFRESH_GEO_ENTITY_IN_MAP: {
            const o = {
                ...state,
                eTag: getDateStamp(),
            };
            return o;
        }

        default:
            return state;
    }
}

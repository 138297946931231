import React from 'react';
import moment from 'moment';
import map from 'lodash/map';
import get from 'lodash/get';
import includes from 'lodash/includes';
import filter from 'lodash/filter';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { isInternalUser } from 'states/profile/business-rules';

import { getStartAndEndDate } from '../../selector';
import { useBusinessReportEditor } from '../../v2';

export const LABELS = {
    date: 'Date Range',
    preset: 'Fixed Range',
    adEndDate: 'Ad End Date',
    dynamic: 'Dynamic Range',
    campaign: 'Campaign',
    include: 'Include',
    exclude: 'Exclude',
    organization: 'Organization',
};

const generateFiltersConfig = isInternalUser => [
    {
        filterName: 'dateRange',
        primaryText: LABELS.date,
        draftPath: 'type',
        showOn: true,
    },
    {
        filterName: 'adEndDate',
        primaryText: LABELS.adEndDate,
        draftPath: 'type',
        showOn: true,
    },
    {
        filterName: 'campaigns',
        primaryText: LABELS.campaigns,
        draftPath: 'mode',
        showOn: true,
    },
    {
        filterName: 'organizationFilter',
        primaryText: LABELS.organization,
        draftPath: 'mode',
        showOn: isInternalUser,
    },
];

const SummaryDetails = () => {
    const { campaignsSummary, draft, organizationForFilteringOptions } = useBusinessReportEditor({
        isInternalUser: isInternalUser(),
    });

    const filterConfig = generateFiltersConfig(isInternalUser());

    const getSecondaryContent = filterType => {
        const filterSelected = draft[filterType];
        if (filterType === 'dateRange' || filterType === 'adEndDate') {
            const { start, end } = getStartAndEndDate(filterSelected);
            const startPrettyString = moment
                .tz(start, draft.timezone)
                .format('MMMM D, YYYY HH:mm z');
            const endPrettyString = moment.tz(end, draft.timezone).format('MMMM D, YYYY HH:mm z');
            return (
                <React.Fragment>
                    {startPrettyString} - {endPrettyString}
                </React.Fragment>
            );
        } else if (filterType === 'campaigns') {
            const selectedCampaigns = filter(campaignsSummary, option => {
                return includes(filterSelected.ids, option.id);
            });
            if (selectedCampaigns.length === 0) {
                return 'All Campaigns';
            }

            return (
                <React.Fragment>
                    {map(selectedCampaigns, campaign => (
                        <div key={campaign.id}>
                            #{campaign.id} {campaign.name}
                        </div>
                    ))}
                </React.Fragment>
            );
        } else if (filterType === 'organizationFilter') {
            const selectedOrganizations = filter(organizationForFilteringOptions, option => {
                return includes(filterSelected.ids, option.value);
            });

            if (selectedOrganizations.length === 0) {
                return 'All Organizations';
            }

            return (
                <React.Fragment>
                    {map(selectedOrganizations, org => (
                        <React.Fragment key={org.value}>{org.label}, </React.Fragment>
                    ))}
                </React.Fragment>
            );
        }
    };

    return (
        <div>
            <Typography variant="body1" gutterBottom>
                Selected filters
            </Typography>
            <div className="ef6-alignment__flex">
                <List dense>
                    {filterConfig.map(config => {
                        return config.showOn &&
                            get(draft, [config.filterName, 'isEnabled'], false) ? (
                            <ListItemComponent
                                filterDescription={config.primaryText}
                                modeText={LABELS[get(draft, [config.filterName, config.draftPath])]}
                                summaryCategory={getSecondaryContent(config.filterName)}
                            />
                        ) : null;
                    })}
                </List>
            </div>
        </div>
    );
};

const ListItemComponent = ({ filterDescription, modeText, summaryCategory }) => {
    return (
        <React.Fragment>
            <ListItem>
                <ListItemText primary={filterDescription} />
            </ListItem>
            <Box ml={2}>
                <ListItem>
                    <ListItemText primary={modeText} secondary={summaryCategory} />
                </ListItem>
            </Box>
        </React.Fragment>
    );
};

export default SummaryDetails;

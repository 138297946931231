import React, { useState, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Map from '@mui/icons-material/Map';
import Tooltip from '@mui/material/Tooltip';

import { SearchInput } from 'widgets-v6/standard-input';
import { AudienceCard } from '../modules/audience-card';
import { AudienceTable } from '../modules/audience-table';
import { isInternalUser } from 'states/profile/business-rules';
import FlexSegmentsForm from 'forms/flex-segments-form';
import flags from 'containers/flags/service';
import { useOverallTableDashboardData } from '../overall-dashboard/v2/hook';

const categoryMap = {
    flex_standard_brand: {
        label: 'Brand',
        description: 'Users who frequent locations from select brands',
        color: '#FFB0C1',
    },
    flex_location_category: {
        label: 'Location Categories',
        description: 'Users who frequent top locations from select categories',
        color: '#FFCE50',
    },
};

const subCategoryColor = {
    'Location Categories': '#FFCE50',
    Brand: '#FFB0C1',
};

const tabValueMapping = {
    all: {
        value: ['flex_standard_brand', 'flex_location_category'],
        label: 'All',
    },
    location_categories: {
        value: ['flex_location_category'],
        label: 'Location Categories',
    },
    brand: {
        value: ['flex_standard_brand'],
        label: 'Brand',
    },
};

const tabValueMappingV2 = {
    all: {
        value: ['Brand', 'Location Categories'],
        label: 'All',
    },
    location_categories: {
        value: ['Location Categories'],
        label: 'Location Categories',
    },
    brand: {
        value: ['Brand'],
        label: 'Brand',
    },
};

const getTabs = ({ audiences }) => {
    const tabs = [
        {
            value: 'all',
            label: 'All',
            avatar: <Avatar>{audiences && audiences.length}</Avatar>,
        },
        {
            value: 'location_categories',
            label: 'Location Categories',
            tooltip:
                'Audiences composed of users that have been seen within mapped polygon locations, and are best used to target based on consumer interest, customer loyalty, or conquesting strategies.',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Location Categories';
                            } else {
                                return audience.source_type === 'flex_location_category';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'brand',
            label: 'Brand',
            tooltip:
                'Audiences composed of users that exhibited habitual visitation patterns, and are best used to target based on offline interests and frequent visitors to locations',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Brand';
                            } else {
                                return audience.source_type === 'flex_standard_brand';
                            }
                        }).length}
                </Avatar>
            ),
        },
    ];

    return tabs;
};

const sortingOptions = [
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
    {
        label: 'Sort by: Newest',
        value: 'id',
        direction: 'desc',
    },
];

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: 400,
        width: '100%',
    },
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: '3.5em',
        height: '3.5em',
        color: '#098679',
    },
}));

const Layout = ({ audienceSegments, openFormModal, onSave, onSearch, activeAudience, organizationId }) => {
    const classes = useStyles();
    const { canEditAudiences } = useOverallTableDashboardData();
    const [audiences, setAudiences] = useState(audienceSegments);
    const [activeTab, setActiveTab] = useState('all');
    const [activeSort, setActiveSort] = useState('name');

    useEffect(() => {
        setAudiences(audienceSegments);
    }, [audienceSegments]);

    const handleChangeTab = newValue => {
        setActiveTab(newValue);
        onSearch(null);
        setActiveSort('name');
    };

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const sortedAudiences = _.orderBy(
        audiences,
        [activeSort],
        [_.find(sortingOptions, option => activeSort === option.value).direction]
    );

    const filteredAudiences = _.filter(sortedAudiences, audience => {
        if (flags.isEnabled('en_2987_use_audience_category_and_subCategory_code')) {
            const activeSubCategories = tabValueMappingV2[activeTab].value;
            return _.includes(activeSubCategories, audience.subCategory);
        }
        const activeSourceTypes = tabValueMapping[activeTab].value;
        return _.includes(activeSourceTypes, audience.source_type);
    });

    const tabs = getTabs({ audiences: audienceSegments });

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'pelmorex' },
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Pelmorex Audiences
                </RouterLink>
                <Typography color="textPrimary">Flex Geolocation</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <Map className={classes.icon} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            Flex Geolocation
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            Segments combining bidstream data and virtual beacons — composed of
                            location radiuses and points of interest from Cleanlist.
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Box mt={3}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Total
                                    </Typography>
                                    <Typography variant="h5">
                                        {audienceSegments ? audienceSegments.length : 0}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {isInternalUser() && canEditAudiences && (
                            <Box mt={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => openFormModal()}
                                >
                                    <AddIcon /> New Audience
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Box mt={5} mb={1.5}>
                    <Grid container spacing={1}>
                        {_.map(tabs, option => (
                            <Grid item key={option.value}>
                                <Tooltip
                                    title={
                                        <Typography variant="body2">{option.tooltip}</Typography>
                                    }
                                    disableHoverListener={!option.tooltip}
                                >
                                    <Chip
                                        label={option.label}
                                        avatar={option.avatar}
                                        color={activeTab === option.value ? 'primary' : 'default'}
                                        onClick={() => handleChangeTab(option.value)}
                                    />
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box mb={2.5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={7}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <SearchInput
                                        placeholder="Filter Audiences"
                                        onChange={handleSearch}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*
                            Have to add a timestamp option for the order by newest to work properly
                            <Grid item xs={3}>
                                <SimpleSelect
                                    options={sortingOptions}
                                    value={activeSort}
                                    onChange={handleSortChange}
                                />
                            </Grid>
                        */}
                    </Grid>
                </Box>
                <AudienceTable
                    audiences={filteredAudiences}
                    cardSize="small"
                    activeAudience={activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            onClick={
                                isInternalUser() && segment.source_type === 'flex_standard_brand'
                                    ? () => openFormModal(segment.id)
                                    : null
                            }
                            segment={segment}
                            displayId={true}
                            active={activeAudience === segment.id}
                            categoryLabel={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? segment.subCategory
                                    : categoryMap[segment.source_type].label
                            }
                            categoryColor={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? subCategoryColor[segment.subCategory]
                                    : categoryMap[segment.source_type].color
                            }
                            organizationId={organizationId}
                        />
                    )}
                />
                <FlexSegmentsForm onSave={onSave} type="standard" />
            </Container>
        </Box>
    );
};

export default Layout;

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import FormField from 'widgets-v5/form-field';
import Draft from 'widgets-v5/draft';
import { Poi } from 'containers/ad-preset/modules/poi';

class PoisForm extends React.Component {
    static propTypes = {
        showErrors: PropTypes.bool,
        onDraftChange: PropTypes.func,

        geo_targeting_settings: PropTypes.object,
        geoResources: PropTypes.object,
        organizationId: PropTypes.string,
        isPoiRecategorized: PropTypes.bool,
    };

    static defaultProps = {
        geo_targeting_settings: {
            categories: [],
            custom_layers: [],
            category_layers: [],
            radius: 500,
            retarget: true,
            target: true,
            days_to_retarget: 21,
        },
    };

    state = {
        initialDraft: {
            geo_targeting_settings: this.props.geo_targeting_settings,
        },
        schema: {
            geo_targeting_settings(value) {
                if (value === undefined) {
                    return;
                }

                if (!_.isPlainObject(value)) {
                    return 'Invalid geo targetting settings data type';
                }
            },
            __require_selection(source) {
                const selections = [].concat(
                    source.geo_targeting_settings.categories,
                    source.geo_targeting_settings.custom_layers,
                    source.geo_targeting_settings.category_layers
                );

                if (selections.length === 0) {
                    return 'A POI must be specified for this tactic';
                }
            },
        },
    };

    render() {
        const {
            showErrors,
            onDraftChange,
            geoResources,
            organizationId,
            isPoiRecategorized,
        } = this.props;

        const { initialDraft, schema } = this.state;

        return (
            <Draft
                initialDraft={initialDraft}
                schema={schema}
                onDraftChange={onDraftChange}
                onInit={onDraftChange}
            >
                {({ draft, errors, updateDraft }) => (
                    <FormField errors={_.map(errors, e => e.message)} showErrors={showErrors}>
                        <Poi
                            geo_targeting_settings={draft.geo_targeting_settings}
                            geoResources={geoResources}
                            organizationId={organizationId}
                            onChange={val => updateDraft({ geo_targeting_settings: val })}
                            isPoiRecategorized={isPoiRecategorized}
                        />
                    </FormField>
                )}
            </Draft>
        );
    }
}

export default PoisForm;

import React, { useState, useEffect, useRef } from 'react';
import numeral from 'numeral';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIconMui from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInputMui from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import TextFieldFormatter from '../text-field-formatter';
import Box from '@mui/material/Box';

import { VariableSizeList } from 'react-window';

const SearchIcon = styled(SearchIconMui)({
    '&.MuiSvgIcon-root': {
        margin: '5px 10px 5px 0px',
    },
});

const OutlinedInput = styled(OutlinedInputMui)(props => ({
    '&.MuiOutlinedInput-input': {
        padding: props.size === 'small' ? 8 : 14,
    },
}));

export const MuiFormLabel = styled(FormLabel)(props => ({
    '&.MuiFormLabel-root': {
        fontSize: props.header ? 20 : 'inherit',
    },
}));

export function SearchInput(props) {
    return (
        <OutlinedInput
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            autoFocus={props.autoFocus}
            labelWidth={props.labelWidth}
            placeholder={props.placeholder}
            rowsMax={props.rowsMax}
            defaultValue={props.defaultValue}
            endAdornment={
                props.defaultValue !== undefined &&
                props.onReset && (
                    <InputAdornment position="end">
                        <IconButton aria-label="delete" onClick={props.onReset} size="medium">
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }
            startAdornment={<SearchIcon />}
            inputRef={props.inputRef}
            sx={{
                background: 'white',
                width: '100%',
                '.MuiInputBase-input.MuiOutlinedInput-input': {
                    padding: '14px 0px',
                },
            }}
        />
    );
}

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current !== null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 88;

    const getChildSize = () => {
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={index => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export function AutocompleteSearchInput(props) {
    return (
        <Autocomplete
            id="free-solo-demo"
            options={props.listItems || []}
            sx={{ width: 400 }}
            renderInput={params => (
                <Paper ref={params.InputProps.ref}>
                    <Box ml={1} width={'300px'} flex={1} p={1}>
                        <InputBase
                            {...params}
                            placeholder="Search"
                            type="text"
                            startAdornment={<SearchIcon />}
                            fullWidth
                        />
                    </Box>
                </Paper>
            )}
            ListboxComponent={ListboxComponent}
            renderOption={option => {
                return <ListItemText primary={option.name} secondary={option.description} />;
            }}
            getOptionLabel={option => option.name}
            filterOptions={createFilterOptions({
                stringify: option => {
                    if (option.id) {
                        return option.id + option.name;
                    } else {
                        return option.name;
                    }
                },
            })}
            onChange={props.onChange}
            noOptionsText={'No results found'}
        />
    );
}

export function TextField(props) {
    return (
        <FormControl
            margin={props.disableMargin ? 'none' : 'normal'}
            error={props.error}
            fullWidth={props.fullWidth}
        >
            {props.label && (
                <Box mb={1}>
                    <MuiFormLabel component="legend" required={props.required}>
                        {props.label}
                    </MuiFormLabel>
                </Box>
            )}
            <OutlinedInput
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                autoFocus={props.autoFocus}
                labelWidth={props.labelWidth}
                startAdornment={props.startAdornment}
                endAdornment={props.endAdornment}
                multiline={props.multiline}
                disabled={props.disabled}
                error={props.error}
                required={props.required}
                placeholder={props.placeholder}
                fullWidth={props.fullWidth}
                rowsMax={props.rowsMax}
                defaultValue={props.defaultValue}
                className={props.className}
                size={props.size}
                inputProps={{
                    maxLength: props.maxLength,
                }}
                inputRef={props.inputRef}
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            {props.errorText && <Typography color="error">{props.errorText}</Typography>}
        </FormControl>
    );
}

export function TextFieldInput(props) {
    const onInputChange = newValue => {
        props.onChange(newValue);
    };

    return (
        <FormControl
            margin={props.disableMargin ? 'none' : 'normal'}
            error={props.error}
            fullWidth={props.fullWidth}
        >
            {props.label && (
                <Box mb={1}>
                    <MuiFormLabel component="legend" required={props.required}>
                        {props.label}
                    </MuiFormLabel>
                </Box>
            )}
            <OutlinedInput
                value={props.value || ''}
                onChange={e => onInputChange(e.target.value)}
                onBlur={props.onBlur}
                autoFocus={props.autoFocus}
                labelWidth={props.labelWidth}
                startAdornment={props.startAdornment}
                endAdornment={props.endAdornment}
                multiline={props.multiline}
                disabled={props.disabled}
                error={props.error}
                required={props.required}
                placeholder={props.placeholder}
                fullWidth={props.fullWidth}
                rowsMax={props.rowsMax}
                defaultValue={props.defaultValue}
                className={props.className}
                type={props.type}
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
        </FormControl>
    );
}

export function TextFieldInStrOutInt(props) {
    const { onChange, value } = props;

    return (
        <FormControl
            margin={props.disableMargin ? 'none' : 'normal'}
            error={props.error}
            fullWidth={props.fullWidth}
        >
            {props.label && (
                <Box mb={1}>
                    <MuiFormLabel component="legend" required={props.required}>
                        {props.label}
                    </MuiFormLabel>
                </Box>
            )}
            <TextFieldFormatter
                initialValue={value}
                onChange={onChange}
                shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                formatIn={v => numeral(v).format('0,0')}
                formatOut={v => {
                    return v ? numeral(v).value() : 0;
                }}
                renderInput={({ value, onChange, onBlur }) => (
                    <OutlinedInput
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        onBlur={onBlur}
                        autoFocus={props.autoFocus}
                        labelWidth={props.labelWidth}
                        startAdornment={props.startAdornment}
                        endAdornment={props.endAdornment}
                        multiline={props.multiline}
                        disabled={props.disabled}
                        error={props.error}
                        required={props.required}
                        placeholder={props.placeholder}
                        fullWidth={props.fullWidth}
                        rowsMax={props.rowsMax}
                        defaultValue={props.defaultValue}
                        className={props.className}
                        inputProps={{
                            maxLength: props.maxLength,
                        }}
                    />
                )}
            />
            {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            {props.error && <Typography color="error">{props.error}</Typography>}
        </FormControl>
    );
}

export function PreFixSuffixInput(props) {
    return (
        <FormControl>
            <OutlinedInput
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                onBlur={props.onBlur}
                className={props.className}
                startAdornment={<InputAdornment position="start">{props.prefix}</InputAdornment>}
                endAdornment={<InputAdornment position="end">{props.suffix}</InputAdornment>}
            />
        </FormControl>
    );
}

export function PreFixSuffixInputInStrOutNum(props) {
    const [stringValue, setStringValue] = useState(props.formatIn(props.value));
    const inputRef = useRef(null)

    useEffect(() => {
        // First check if the click occurred outside the input field
        // Then attach the event listener when the component mounts
        // Finally clean up the event listener when the component unmounts
        const handleClickOutside = event => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setStringValue(props.formatIn(props.value));
            }
        };
        
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [props.value, inputRef]);

    const onInputChange = newValue => {
        setStringValue(newValue);

        const cachedValue = props.formatOut(newValue);
        props.onChange(cachedValue);
    };

    const value = /^[0-9,.]+$/.test(stringValue) ? stringValue || '' : '';

    return (
        <FormControl>
            <OutlinedInput
                value={value}
                onChange={e => onInputChange(e.target.value)}
                className={props.className}
                startAdornment={<InputAdornment position="start">{props.prefix}</InputAdornment>}
                endAdornment={<InputAdornment position="end">{props.suffix}</InputAdornment>}
                disabled={props.disabled}
                ref={inputRef}
                placeholder={'0.00'}
            />
        </FormControl>
    );
}

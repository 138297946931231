const actions = {
    changeIASFraudPreventionRisk(code) {
        return {
            type: 'AD_FORM__IAS_FRAUD_PREVENTION_RISK__CHANGE',
            payload: {
                code,
            },
        };
    },
    changeIASFraudPreventionUnrateable(code) {
        return {
            type: 'AD_FORM__IAS_FRAUD_PREVENTION_UNRATEABLE__CHANGE',
            payload: {
                code,
            },
        };
    },
    changeIASFraudPreventionIp(code) {
        return {
            type: 'AD_FORM__IAS_FRAUD_PREVENTION_IP__CHANGE',
            payload: {
                code,
            },
        };
    },
};

export default actions;

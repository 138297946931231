import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';

const Creatives = {
    getAllByCampaignIds(campaignIds) {
        return dispatch => {
            return http()
                .get(`creatives?campaigns=${campaignIds.join(',')}`)
                .then(creatives => {
                    dispatch({
                        type: c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS,
                        payload: {
                            campaignIds,
                            creatives,
                        },
                    });

                    return creatives;
                });
        };
    },

    getAll(campaignId) {
        return dispatch => {
            return http()
                .get(`campaigns/${campaignId}/creatives`)
                .then(
                    creatives => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                creatives,
                            },
                        });
                        return creatives;
                    },
                    err => {
                        console.error(`GET ALL creatives error`, err);

                        throw err;
                    }
                );
        };
    },

    get(campaignId, creativeId) {
        return dispatch => {
            return http()
                .get(`campaigns/${campaignId}/creatives/${creativeId}`)
                .then(
                    creative => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                creative,
                            },
                        });

                        return creative;
                    },
                    err => {
                        console.error(`GET ONE creatives error`, err);

                        throw err;
                    }
                );
        };
    },

    getHistory(campaignId, creativeId) {
        return dispatch => {
            return http()
                .get(`campaigns/${campaignId}/creatives/${creativeId}/history`)
                .then(
                    history => {
                        dispatch({
                            type: c.SYSTEM__CREATIVE_HISTORY__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                creativeId,
                                history,
                            },
                        });

                        return history;
                    },
                    err => {
                        console.error(`GET HISTORY creatives error`, err);

                        throw err;
                    }
                );
        };
    },

    create(campaignId, creative) {
        return dispatch => {
            return http()
                .post(`campaigns/${campaignId}/creatives`, creative)
                .then(
                    creative => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                creative,
                            },
                        });

                        return creative;
                    },
                    err => {
                        console.error(`CREATE creatives error`, err);

                        throw err;
                    }
                );
        };
    },

    update(campaignId, creativeId, creative) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.creatives.${creativeId}.attr._etag`);
            return http()
                .setIfMatch(etag)
                .patch(`campaigns/${campaignId}/creatives/${creativeId}`, creative)
                .then(creative => {
                    dispatch({
                        type: c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS,
                        payload: {
                            campaignId,
                            creative,
                        },
                    });

                    // Refetch ads because the ad status may have updated
                    http()
                        .get(`campaigns/${campaignId}/ads`)
                        .then(
                            ads => {
                                dispatch({
                                    type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                                    payload: { campaignId, ads },
                                });
                            },
                            err => {
                                console.error(`UPDATE creatives error`, err);

                                throw err;
                            }
                        );

                    return creative;
                });
        };
    },

    bulkEdit(campaignId, creatives) {
        return dispatch => {
            return http()
                .patch(`campaigns/${campaignId}/creatives`, creatives)
                .then(creatives => {
                    dispatch({
                        type: c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS,
                        payload: {
                            campaignId,
                            creatives,
                        },
                    });

                    // Refetch ads because the ad status may have updated
                    http()
                        .get(`campaigns/${campaignId}/ads`)
                        .then(
                            ads => {
                                dispatch({
                                    type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                                    payload: { campaignId, ads },
                                });
                            },
                            err => {
                                console.error(`UPDATE creatives error`, err);

                                throw err;
                            }
                        );

                    return creatives;
                });
        };
    },

    delete(campaignId, creativeId) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.creatives.${creativeId}.attr._etag`);
            return http()
                .setIfMatch(etag)
                .del(`campaigns/${campaignId}/creatives/${creativeId}`)
                .then(
                    creative => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                creative,
                            },
                        });

                        return creative;
                    },
                    err => {
                        console.error(`DELETE creatives error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default Creatives;

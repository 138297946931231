import React, { Component } from 'react';
import { Link as LinkRouter } from 'react-router';

import Card from 'widgets-v5/card';
import { BodyText } from 'widgets-v5/typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import topics from '../topics';

class HelpTopicList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { category } = this.props.params;

        const categories = _.filter(topics, topic => topic.url.indexOf(category) === 0);

        const publishedArticles = _.filter(categories, ['published', true]);

        return (
            <div className="help-topic-list">
                <Breadcrumbs aria-label="Breadcrumb">
                    <Link component={LinkRouter} color="inherit" to="/help">
                        Help
                    </Link>
                    <Link
                        component={LinkRouter}
                        color="textPrimary"
                        to={`/help/${category}`}
                        aria-current="page"
                    >
                        {categories[0].category}
                    </Link>
                </Breadcrumbs>
                <Card>
                    {_.map(publishedArticles, article => (
                        <HelpTopicListItem
                            key={article.url}
                            link={`/help/${article.url}`}
                            title={article.topic}
                        />
                    ))}
                </Card>
            </div>
        );
    }
}

function HelpTopicListItem(props) {
    return (
        <Link component={LinkRouter} to={props.link}>
            <BodyText type="link">{props.title}</BodyText>
        </Link>
    );
}

export default HelpTopicList;

import React, { Component } from 'react';
import _ from 'lodash';

export const conf = {
    test: 500,
};

export function highlight(WrappedComponent, shouldHighlight) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                mounted: false,
                highlight: false,
                timer: null,
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.state.mounted && shouldHighlight(this.props, nextProps)) {
                this.highlight();
            }
        }

        componentDidMount() {
            this.setState({
                mounted: true,
            });
        }

        componentWillUnmount() {
            clearTimeout(this.state.timer);

            this.setState({
                mounted: false,
                highlight: false,
            });
        }

        highlight = () => {
            let timer = setTimeout(() => {
                if (this.state.mounted) {
                    this.setState({
                        highlight: false,
                    });
                }
            }, 500);

            _.debounce(() => {
                if (timer) {
                    clearTimeout(timer);
                }
            }, 100);

            this.setState({
                highlight: true,
                timer,
            });
        };

        render() {
            return <WrappedComponent {...this.props} highlight={this.state.highlight} />;
        }
    };
}

export function hasChanged(prev, next) {
    const value_strgf_next = JSON.stringify(next);
    const value_strgf_prev = JSON.stringify(prev);

    const value_next =
        value_strgf_next === undefined || value_strgf_next === null || value_strgf_next === 'null'
            ? '""'
            : value_strgf_next;
    const value_prev =
        value_strgf_prev === undefined || value_strgf_prev === null || value_strgf_prev === 'null'
            ? '""'
            : value_strgf_prev;

    // console.log( 'zzz1: ', prev,      '|', next,       '|',  typeof prev, typeof next,  );
    // console.log( 'zzz2: ', value_prev,'|', value_next, '|',  typeof value_next, typeof value_next);
    return value_next !== value_prev;
}

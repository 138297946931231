import _ from 'lodash';
import Joi from '@hapi/joi';

import { LEVEL_BY_NAME } from 'common/constants/forensiq-risk-levels';
import weatherConditionSchema from './modules/weather-conditions/schema';
import creativeRotationByWeatherSchema from './modules/creative-rotation-selector/creative-rotation-by-weather/schema';
import {
    CreativeRotationMethodMapping,
    DeviceOSMapping,
} from 'states/resources/ads/business-logic';

const getCandianGeoTargeting = geotargets => {
    return _.find(geotargets, { country: 'CA' });
};

export const plainSchema = {
    name: Joi.string()
        .required()
        .default('')
        .label('Name'),

    timezone: Joi.string()
        .required()
        .default('UTC')
        .label('Timezone'),

    start: Joi.string()
        .required()
        .default('')
        .label('Start'),

    end: Joi.string()
        .required()
        .default('')
        .label('End'),

    notes: Joi.string()
        .required()
        .default('')
        .allow('')
        .label('Notes'),

    custom_fields: Joi.array()
        .items(
            Joi.object({
                key: Joi.string().required(),

                name: Joi.string().required(),

                required: Joi.bool().required(),

                value: Joi.string()
                    .required()
                    .allow(''),
            })
        )
        .required()
        .default([])
        .label('Custom Fields'),

    creative: Joi.array()
        .items(Joi.string())
        .required()
        .default([])
        .label('Creative'),

    rotation_rules: Joi.object({
        mode: Joi.string()
            .required()
            .default(CreativeRotationMethodMapping.Single)
            .label('Mode'),

        weighted: Joi.array()
            .items(
                Joi.object({
                    markup_id: Joi.string()
                        .required()
                        .label('Creative'),

                    weight: Joi.number(),
                })
            )
            .required()
            .default([]),
        weather: creativeRotationByWeatherSchema,
        scheduled: Joi.array()
            .items(
                Joi.object({
                    id: Joi.string(),
                    markup_id: Joi.string()
                        .allow('', null)
                        .label('Creative'),

                    start: Joi.string()
                        .allow('', null)
                        .label('Start'),

                    end: Joi.any()
                        .allow('', null)
                        .label('End'),

                    weighted: Joi.array()
                        .items(
                            Joi.object({
                                id: Joi.string(),
                                markup_id: Joi.string()
                                    .allow(null, '')
                                    .label('Creative')
                                    .custom((markup_id, helpers) => {
                                        const isEmptySchedule =
                                            _.size(
                                                helpers.prefs.context.draft.rotation_rules.schedule
                                            ) === 1 &&
                                            _.size(
                                                helpers.prefs.context.draft.rotation_rules
                                                    .schedule[0].weighted
                                            ) === 0;
                                        if (
                                            helpers.prefs.context.draft.rotation_rules.mode !==
                                                CreativeRotationMethodMapping.Scheduled ||
                                            isEmptySchedule
                                        ) {
                                            return markup_id;
                                        }
                                        if (!markup_id) {
                                            return helpers.message('Creative is required');
                                        }

                                        return markup_id;
                                    }),

                                weight: Joi.number(),
                            })
                        )
                        .default([])
                        .label('Weighted'),
                }).custom((schedule, helpers) => {
                    const isLegacyScheduledRotation = !!schedule.markup_id;
                    const rotationRules = helpers.prefs.context.draft.rotation_rules;
                    const isEmptySchedule =
                        _.size(rotationRules.scheduled) === 1 &&
                        _.size(rotationRules.scheduled[0].weighted) === 0;

                    if (isLegacyScheduledRotation || isEmptySchedule) {
                        return schedule;
                    }

                    let sum = 0;
                    _.each(schedule.weighted, weighted => (sum += weighted.weight));

                    if (sum !== 100) {
                        return helpers.message('Total weight must equal 100');
                    }

                    return schedule;
                })
            )
            .required()
            .default([]),
    }),

    geo_targeting_settings: Joi.object({
        categories: Joi.array(),
        custom_layers: Joi.array(),
        category_layers: Joi.array(),
        radius: Joi.number().default(500),
        retarget: Joi.bool(),
        target: Joi.bool(),
        days_to_retarget: Joi.number(),
    }),

    blacklistedAppsAndSites: Joi.array()
        .items(Joi.string())
        .custom((blacklists, helpers) => {
            const whiteLists = _.get(helpers, 'prefs.context.draft.whitelistedAppsAndSites');
            if (_.intersection(blacklists, whiteLists).length > 0) {
                return helpers.message(
                    'An Apps & Sites list cannot be selected in both Allowed and Blocked fields'
                );
            }
        }),

    whitelistedAppsAndSites: Joi.array()
        .items(Joi.string())
        .custom((whiteLists, helpers) => {
            const blacklists = _.get(helpers, 'prefs.context.draft.blacklistedAppsAndSites');
            if (_.intersection(blacklists, whiteLists).length > 0) {
                return helpers.message(
                    'An Apps & Sites list cannot be selected in both Allowed and Blocked fields'
                );
            }
        }),

    geotargets: Joi.array()
        .items(
            Joi.object({
                country: Joi.string(),
                include: Joi.array().items(
                    Joi.object({
                        city: Joi.string(),
                        region: Joi.string(),
                        label: Joi.string(),
                        fsa: Joi.string(),
                        value: Joi.string(),
                    })
                ),
                exclude: Joi.array().items(
                    Joi.object({
                        city: Joi.string(),
                        region: Joi.string(),
                        label: Joi.string(),
                        value: Joi.string(),
                    })
                ),
            })
        )
        .custom((geotargets, helpers) => {
            const canadianGeoTargeting = getCandianGeoTargeting(geotargets);
            // const spanishGeoTargeting = getSpanishGeoTargeting(geotargets);
            const weatherConditions = _.get(
                helpers,
                'prefs.context.draft.weatherConditions.conditions'
            );

            const creativeRotationMode = _.get(helpers, 'prefs.context.draft.rotation_rules.mode');

            // if (
            //     !canadianGeoTargeting &&
            //     !spanishGeoTargeting &&
            //     weatherConditions &&
            //     weatherConditions.length > 0
            // ) {
            //     return helpers.message('Country does not support weather condition targeting');
            // }

            // if (
            //     !canadianGeoTargeting &&
            //     !spanishGeoTargeting &&
            //     creativeRotationMode === CreativeRotationMethodMapping.Weather
            // ) {
            //     return helpers.message(
            //         'Country does not support weather condition creative rotation'
            //     );
            // }

            if (canadianGeoTargeting) {
                const includeContainsFSAcode =
                    _.filter(canadianGeoTargeting.include, target => target.fsa).length > 0;
                const excludeContainsFSAcode =
                    _.filter(canadianGeoTargeting.exclude, target => target.fsa).length > 0;

                const geotargetsContainsFSAcode = includeContainsFSAcode || excludeContainsFSAcode;

                if (
                    geotargetsContainsFSAcode &&
                    weatherConditions &&
                    weatherConditions.length > 0
                ) {
                    return helpers.message(
                        'FSA code is not supported when weather condition(s) are chosen'
                    );
                }

                if (
                    geotargetsContainsFSAcode &&
                    creativeRotationMode === CreativeRotationMethodMapping.Weather
                ) {
                    return helpers.message(
                        'FSA code is not supported when creative rotation by weather condition is chosen'
                    );
                }
            }
            return geotargets;
        }),

    target_age_groups: Joi.array()
        .items(Joi.number())
        .allow(1, 2, 3, 4, 5, 6, 7, 8, -1),

    target_genders: Joi.array()
        .items(Joi.string())
        .allow('M', 'F', 'D'),

    dayparts: Joi.array()
        .items(
            Joi.object({
                start: Joi.string(),
                end: Joi.string(),
            })
        )
        .allow(null),

    dayparts_local: Joi.array()
        .items(
            Joi.object({
                start: Joi.string(),
                end: Joi.string(),
            })
        )
        .allow(null),

    weekparts: Joi.array()
        .items(Joi.bool())
        .default([true, true, true, true, true, true, true])
        .allow(null),

    weekparts_local: Joi.array()
        .items(Joi.bool())
        .default(null)
        .allow(null),

    target_device_language: Joi.array()
        .items(Joi.string())
        .default([]),

    target_app_store_cat: Joi.array()
        .items(Joi.string())
        .default([]),

    target_app_store_cat_exclude: Joi.array()
        .items(Joi.string())
        .default([]),

    target_app_categories: Joi.array()
        .items(Joi.string())
        .default([]),

    target_iab_categories: Joi.array()
        .items(Joi.string())
        .default([]),

    target_iab_categories_exclude: Joi.array()
        .items(Joi.string())
        .default([]),

    geoboxes: Joi.array()
        .items(
            Joi.object({
                N: Joi.number(),
                E: Joi.number(),
                S: Joi.number(),
                W: Joi.number(),
                type: Joi.string(),
            })
        )
        .default([]),

    target_device_os: Joi.array()
        .items(Joi.string())
        .default([DeviceOSMapping.IOS, DeviceOSMapping.Android])
        .allow(DeviceOSMapping.IOS, DeviceOSMapping.Android),

    target_device_language_exclude: Joi.array()
        .items(Joi.string())
        .default([]),

    target_carriers: Joi.array()
        .items(Joi.string())
        .default([]),
    carriers_exclude: Joi.array()
        .items(Joi.string())
        .default([]),

    tactics_enabled: Joi.bool()
        .required()
        .default(false),

    tactics_generators: Joi.array()
        .default([])
        .custom((tactics, helpers) => {
            // We want to validate when the user has selected Contextual Targeting but
            // hasn't selected an app store category and also no IAS Contextual Targeting.
            for (const tactic of tactics) {
                if (tactic.targeting && tactic.targeting.length) {
                    for (const target of tactic.targeting) {
                        const isTargetModuleCategory = target.module === 'categories';
                        const isDraftEmpty = _.isEmpty(target.draft);

                        if (isTargetModuleCategory) {
                            if (isDraftEmpty) {
                                return helpers.message(
                                    'Please select at least one App Store Category or Contextual Targeting Category'
                                );
                            }

                            const isIASContextualTargetingEmpty =
                                !target.draft.ias ||
                                !target.draft.ias.contextualTargeting ||
                                !target.draft.ias.contextualTargeting.value ||
                                !target.draft.ias.contextualTargeting.value.length;
                            const isAppCategoryEmpty =
                                (!target.draft.target_app_store_cat ||
                                    !target.draft.target_app_store_cat.length) &&
                                (!target.draft.target_app_store_cat_exclude ||
                                    !target.draft.target_app_store_cat_exclude.length);

                            if (isIASContextualTargetingEmpty && isAppCategoryEmpty) {
                                return helpers.message(
                                    'Please select at least one App Store Category or Contextual Targeting Category'
                                );
                            }
                        }
                    }
                }
            }

            return tactics;
        }),

    // Deprecated field for frequency cap
    // NOTE: this field has been temporarily set to 5 as a safe default
    // until we iron out kinks in the deployment
    max_user_frequency: Joi.number().default(5),

    // Replacement field for frequency cap
    fcaps: Joi.object({
        imp: Joi.number()
            .default(5)
            .allow(null),
        interval_count: Joi.number()
            .default(1)
            .allow(null),
        interval_unit: Joi.string()
            .default('days')
            .allow(null),
        start: Joi.number().default(Date.now()),
    }).required(),

    // --- Budget ---//
    max_cpm_rate_local: Joi.number()
        .max(100)
        .default(0)
        .messages({
            'number.max': 'Total Bid Price must be $100 or below',
        }),

    use_deal_price_to_bid: Joi.bool().default(false),

    max_total_impressions: Joi.number().default(0),
    max_daily_impressions: Joi.number().default(0),

    max_total_clicks: Joi.number().default(0),
    max_daily_clicks: Joi.number().default(0),

    max_total_spend_local: Joi.number().default(0),
    max_daily_spend_local: Joi.number().default(0),

    max_daily_billings: Joi.number().default(0),
    max_total_billings: Joi.number().default(0),

    non_billable_volume: Joi.number().default(0),

    // --- Revenue Managemenet  ---//
    ef_billing_terms: Joi.string().default('CPM'),
    billable_volume: Joi.number().default(0),
    billing_rate: Joi.number().default(0),
    billing_enabled: Joi.bool().default(true),
    primary_pacing: Joi.string().default('impressions'),
    ubx_audiences: Joi.array().default([]),
    audiences: Joi.array().default([]),
    audience_exclude: Joi.array().default([]),

    // --- Inventory Prediction ---//
    predicted_daily_impressions: Joi.number()
        .default(null)
        .allow(null),
    predicted_daily_uniques: Joi.number()
        .default(null)
        .allow(null),

    predicted_total_impressions_min: Joi.number()
        .default(null)
        .allow(null),
    predicted_total_uniques_min: Joi.number()
        .default(null)
        .allow(null),
    predicted_total_impressions_max: Joi.number()
        .default(null)
        .allow(null),
    predicted_total_uniques_max: Joi.number()
        .default(null)
        .allow(null),

    // Brand Safety
    forensiq_risk_cap: Joi.number().default(LEVEL_BY_NAME['allow_low_risk'].value),

    ias: Joi.object({
        fraudPrevention: Joi.object({
            risk: Joi.object({
                value: Joi.number()
                    .default(1)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            ip: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1),
                segId: Joi.number().default(-1),
            }),
            unrateable: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1),
                segId: Joi.number().default(-1),
            }),
        }),
        brandSafety: Joi.object({
            adult: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            alcohol: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            gambling: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            hateSpeech: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            illegalDownloads: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            illegalDrugs: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            offensiveLanguage: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            violence: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1, 2),
                segId: Joi.number().default(-1),
            }),
            unrateable: Joi.object({
                value: Joi.number()
                    .default(0)
                    .allow(0, 1),
                segId: Joi.number().default(-1),
            }),
        }),
        viewability: Joi.object({
            value: Joi.number()
                .default(0)
                .allow(0, 1, 2, 3, 4, 5),
            segId: Joi.number().default(-1),
        }),
        contextualTargeting: Joi.object({
            value: Joi.array()
                .items(Joi.number().valid(..._.range(23)))
                .default([]),
            segId: Joi.array()
                .items(Joi.number())
                .default([]),
        }),
        groupmViewability: Joi.object({
            value: Joi.number()
                .default(0)
                .allow(0, 1, 2, 3, 4, 5),
            segId: Joi.number().default(-1),
        }),
        publicisViewability: Joi.object({
            value: Joi.number()
                .default(0)
                .allow(0, 1, 2, 3, 4, 5),
            segId: Joi.number().default(-1),
        }),
    }),

    // First Party Fees
    fta_fee: Joi.number().default(0),

    audience_fee: Joi.number().default(0),

    fta: Joi.object({
        enabled: Joi.bool()
            .default(null)
            .allow(null),
        partner_id: Joi.string()
            .default(null)
            .allow(null),
        line_id: Joi.string()
            .default(null)
            .allow(null, ''),
        location_list: Joi.string()
            .default(null)
            .allow(null),
    }),

    platform: Joi.string()
        .default('inapp')
        .allow('inapp', 'mweb', 'desktop'),

    applistSettings: Joi.object({
        mode: Joi.string()
            .default('blacklist')
            .allow(null),
        listIds: Joi.array()
            .items(Joi.string())
            .default([]),
    }),

    unalteredDuplicate: Joi.bool().default(false),
    isStartSet: Joi.bool().default(false),
    isEndSet: Joi.bool().default(false),
    deals: Joi.array()
        .items(Joi.string())
        .default([]),
    bid_strategy_mode: Joi.string(),
    automatic_bid_price: Joi.object({
        max_ecpm: Joi.number(),
        max_ecpm_local: Joi.number()
            .allow(null)
            .max(100)
            .messages({
                'number.max': 'Total Bid Price Limit must be $100 or below',
            }),
        apply_max_ecpm: Joi.boolean(),
        optimizing_strategy: Joi.object({
            mode: Joi.string(),
            max_ecpc_local: Joi.number(),
            max_ecpc: Joi.number(),
            max_ecpcv_local: Joi.number(),
            max_ecpcv: Joi.number(),
        }),
    }),
    weatherConditions: weatherConditionSchema,
    isIasPostBidVerificationEnabled: Joi.bool().allow(null),
    conversionsForCreativeOptimization: Joi.array().items(Joi.string()),
};

// const getSpanishGeoTargeting = geotargets => {
//     return _.find(geotargets, { country: 'ES' });
// };

export default Joi.object(plainSchema);

import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';
import flags from 'containers/flags/service';

const Organizations = {
    getAll() {
        return (dispatch, getState) => {
            return http()
                .get(`organizations`)
                .then(
                    organizations => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                organizations,
                            },
                        });

                        return organizations;
                    },
                    err => {
                        console.error(`GET ALL organizations error`, err);

                        throw err;
                    }
                );
        };
    },

    getAllSummaries() {
        return (dispatch, getState) => {
            return http()
                .get(`organizations/summary`)
                .then(
                    organizations => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                organizations,
                            },
                        });

                        return organizations;
                    },
                    err => {
                        console.error(`GET ALL SUMMARIES organizations error`, err);

                        throw err;
                    }
                );
        };
    },

    get(organizationId) {
        return (dispatch, getState) => {
            return http()
                .get(`organizations/${organizationId}`)
                .then(
                    organization => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                organization,
                            },
                        });
                        return organization;
                    },
                    err => {
                        console.error(`GET ONE organizations error`, err);

                        throw err;
                    }
                );
        };
    },

    getSelf() {
        return (dispatch, getState) => {
            return http()
                .get(`organization`)
                .then(
                    organization => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                organization,
                            },
                        });
                        return organization;
                    },
                    err => {
                        console.error(`GET SELF organizations error`, err);

                        throw err;
                    }
                );
        };
    },

    getSpend(organizationId) {
        return (dispatch, getState) => {
            return http()
                .get(`organizations/${organizationId}/spend`)
                .then(
                    spendStats => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                spendStats,
                            },
                        });

                        return spendStats;
                    },
                    err => {
                        console.error(`GET SPEND organizations error`, err);

                        throw err;
                    }
                );
        };
    },

    create(organization) {
        return (dispatch, getState) => {
            return http()
                .post(`organizations`, organization)
                .then(
                    organization => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                organization,
                            },
                        });
                        return organization;
                    },
                    err => {
                        console.error(`CREATE organizations error`, err);

                        throw err;
                    }
                );
        };
    },

    update(organizationId, organization) {
        return (dispatch, getState) => {
            return http()
                .patch(`organizations/${organizationId}`, organization)
                .then(
                    organization => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                organization,
                            },
                        });
                        return organization;
                    },
                    err => {
                        console.error(`UPDATE organizations error`, err);

                        throw err;
                    }
                );
        };
    },

    delete(organizationId) {
        return (dispatch, getState) => {
            return http()
                .delete(`organizations/${organizationId}`)
                .then(
                    organization => {
                        dispatch({
                            type: c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS,
                            payload: {
                                organization,
                            },
                        });

                        return organization;
                    },
                    err => {
                        console.error(`DELETE organizations error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default Organizations;

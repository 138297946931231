import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import createHttp from 'utils/http';
import flags from 'containers/flags/service';

const http = createHttp();

/*

    <Profile>
        {({profileState}) => userBelongsTo(profileState, 'Addictive Mobility') ? (

        ) : (

        )}
    </Profile>

*/

const Profile = connect(storeState => {
    const profileState = _.get(storeState, 'profile');

    return {
        profileState,
    };
})(
    class Profile extends React.Component {
        state = {
            ownOrganization: null,
        };
        componentDidMount() {
            this.fetchOwnOrganizationData();
        }
        fetchOwnOrganizationData = async () => {
            const { profileState } = this.props;
            const query = `
                query fetchOwnOrganizationDataForProfileState($id: String) {
                    organization(id: $id) {
                        id
                        fta_partner_id
                        type
                        audience_rates {
                            name
                            fee
                        }
                    }
                }
            `;
            const variables = {
                id: profileState.organizationId,
            };
            const { organization } = await http.graphql(query, variables);
            this.setState({ ownOrganization: organization });
        };
        render() {
            const { children, profileState } = this.props;

            if (!this.state.ownOrganization) {
                return null;
            }

            return children({
                profileState,
                ownOrganization: this.state.ownOrganization,
            });
        }
    }
);

export function useProfileState() {
    const profileState = useSelector(state => state.profile);
    return profileState;
}

export default Profile;

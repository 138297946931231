import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REDUCER_KEY, actions } from './reducer';
import {
    getCreativeArchiveMessage,
    getCreativeBulkArchiveMessage,
    getFilteredCreatives,
    getSearchQuery,
} from './selectors';

export function useCampaignOverviewCreatives() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);
    const creativeArchiveMessage = useSelector(getCreativeArchiveMessage);
    const creativeBulkArchiveMessage = useSelector(getCreativeBulkArchiveMessage);
    const creativesV2 = useSelector(getFilteredCreatives);
    const searchQuery2 = useSelector(getSearchQuery);

    return {
        ...state,
        ...boundActions,
        creativeArchiveMessage,
        creativeBulkArchiveMessage,
        creativesV2,
        searchQuery2,
    };
}

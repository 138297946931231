import _ from 'lodash';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import { isInternalUser } from 'states/profile/business-rules';

import { IAS_FRAUD_PREVENTION_RISK_OPTIONS } from 'common/constants/ias-fraud-prevention';
import { IAS_VIEWABILITY_OPTIONS } from 'common/constants/ias-viewability';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { getIASFraudIpWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

const IAS_VIEWABILITY_OPTIONS_MAPPING = {};
_.each(IAS_VIEWABILITY_OPTIONS, option => {
    IAS_VIEWABILITY_OPTIONS_MAPPING[option.value] = option;
});

const IASFraudPrevention = props => {
    const { ad, ads, campaign, onSave, draft, updateDraft, isCrossPlatformCampaign } = props;
    const handleChange = (e, updateDraft) => {
        const isUsingFrontLoadPacing = e.target.value === 'true';
        const updateObj = { use_front_load_pacing: isUsingFrontLoadPacing };
        updateDraft(updateObj);
    };

    const getChanges = (field, value) => {
        const changes = {
            ...draft.ias,
            fraudPrevention: {
                ...draft.ias.fraudPrevention,
                [field]: {
                    ...draft.ias.fraudPrevention[field],
                    value,
                },
            },
        };
        return changes;
    };

    const handleIASFraudPreventionRiskChange = e => {
        const mode = e.target.value;
        const changes = getChanges('risk', mode);
        updateDraft({ ias: changes });
    };

    const handleIASFraudPreventionUnrateableChange = e => {
        const checked = event.target.checked;
        const changes = getChanges('unrateable', checked ? 1 : 0);
        updateDraft({ ias: changes });
    };

    const handleIASFraudPreventionIpChange = e => {
        const checked = event.target.checked;
        const changes = getChanges('ip', checked ? 1 : 0);
        updateDraft({ ias: changes });
    };

    let iasFraudPreventionIpDisabled;
    if (ad) {
        const platform = _.get(ad, 'platform');
        const adPlatforms = _.get(ad, 'platforms');
        if (isCrossPlatformCampaign) {
            iasFraudPreventionIpDisabled = false;
        } else {
            iasFraudPreventionIpDisabled = platform === 'inapp';
        }
    } else if (ads && ads.length > 0) {
        if (isCrossPlatformCampaign) {
            const mwebDesktopAds = _.filter(
                ads,
                ad => _.includes(ad.platforms, 'mweb') || _.includes(ad.platforms, 'desktop')
            );
            iasFraudPreventionIpDisabled = mwebDesktopAds.length === 0;
        } else {
            iasFraudPreventionIpDisabled = _.some(ads, { platform: 'inapp' });
        }
    }
    const iasFraudPreventionRisk = _.get(draft, 'ias.fraudPrevention.risk.value');
    const iasFraudPreventionUnrateable = _.get(draft, 'ias.fraudPrevention.unrateable.value');
    const iasFraudPreventionIp = _.get(draft, 'ias.fraudPrevention.ip.value');

    let warningData = {};

    if (isCrossPlatformCampaign) {
        const platforms = ad
            ? _.get(ad, 'platforms')
            : _(ads)
                  .map(ad => ad.platforms)
                  .flatten()
                  .uniq()
                  .value();
        warningData = getIASFraudIpWarning({ platforms, ipValue: iasFraudPreventionIp });
    }

    return (
        <div>
            <Typography>Fraud Prevention</Typography>
            <div>
                <FormLabel component="legend">Content-based</FormLabel>
                <Select
                    value={iasFraudPreventionRisk}
                    onChange={handleIASFraudPreventionRiskChange}
                >
                    {_.map(IAS_FRAUD_PREVENTION_RISK_OPTIONS, option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={iasFraudPreventionUnrateable === 1}
                                onChange={handleIASFraudPreventionUnrateableChange}
                            />
                        }
                        label="Exclude unrated content"
                    />
                </FormGroup>
                <FormLabel component="legend">IP Address-based</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={iasFraudPreventionIpDisabled}
                                checked={iasFraudPreventionIp === 1}
                                onChange={handleIASFraudPreventionIpChange}
                            />
                        }
                        label="Exclude fraudulent IP addresses (desktop and mobile web only)"
                    />
                </FormGroup>
                {isCrossPlatformCampaign && warningData.hasWarning && (
                    <WarningText
                        message={warningData.message}
                        title={warningData.title}
                        severity={warningData.severity}
                    />
                )}
            </div>
        </div>
    );
};

export default IASFraudPrevention;

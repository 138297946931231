import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import every from 'lodash/every';

import { regEx } from 'utils/regular-expression-library';
const urlValidationRegExp = regEx.url;

export const nativeValidators = {
    main_image_asset() {
        // optional
    },
    main_image_url(value, source) {
        if (!value && !source.main_image_asset) {
            return 'Main Image URL is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Main Image URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    main_image_preview_url(value) {
        if (!value) {
            return;
        }

        if (!urlValidationRegExp.test(value)) {
            return `Main Image Preview URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    icon_image_url(value, source) {
        if (!value && !source.icon_asset) {
            return 'Icon Image URL is required';
        }
        if (!urlValidationRegExp.test(value)) {
            return `Icon Image URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    icon_image_preview_url(value) {
        if (!value) {
            return;
        }

        if (!urlValidationRegExp.test(value)) {
            return `Icon Image Preview URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    title(value) {
        if (!value) {
            return 'Title is required';
        }
    },
    text(value) {
        if (!value) {
            return 'Text is required';
        }
    },
    sponsoredBy(value) {
        if (!value) {
            return 'Sponsor is required';
        }
        if (value.length >= 25) {
            return 'Sponsor name should be 25 or fewer characters';
        }
    },
    call_to_action(value) {
        if (!value) {
            return;
        }
    },
    star_rating(value) {
        if (value === undefined || value === null) {
            return;
        }

        if (!isNumber(value)) {
            return 'Star Rating must be between 1 and 5';
        }
        if (value < 1 || value > 5) {
            return 'Star Rating must be between 1 and 5';
        }
    },
    third_party_pixels(value) {
        if (!value) {
            return;
        }

        if (!isArray(value)) {
            throw new Error('Expected third_party_pixels to be a list');
        }

        const allValid = every(value, pixel => urlValidationRegExp.test(pixel));

        if (!allValid) {
            return `Third Party Pixels should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_clickthrough(value) {
        if (!isBoolean(value)) {
            return 'Third Party Clickthrough is required';
        }
    },
    clickthrough_url(value, source) {
        if (!value && source.third_party_clickthrough === true) {
            return 'Clickthrough URL is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Clickthrough URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    landing_page(value, source) {
        if (!value) {
            return 'Landing Page is required';
        }

        if (value === source.clickthrough_url && source.third_party_clickthrough === true) {
            return 'Landing Page cannot be the same as Clickthrough URL';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Landing Page should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    creative_vendors(value) {
        if (!value.length) {
            return 'At least one creative vendor must be specified';
        }
    },
};

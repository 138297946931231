import _ from 'lodash';

// --------------
// Default values
// --------------
export { draftDefaultValues } from './default-values';

// -------------------------------
// Calculate Draft from API values
// -------------------------------
export { default as apiValueToDraft } from './api-values-to-draft';

// ----------
// Validation
// ----------
import _validationRules from './validation-rules';
import { default as validator } from 'utils/validate';

export function validateDraft(draft, meta) {
    const { orgId, relationship } = meta;

    let validationRules = _.cloneDeep(_validationRules);

    // Depends on the type of org or relationship, some field will not be send (hidden)
    // so can not be validate, so we have to remove these item from the validation rules.

    // tech_fee
    // --------
    if (_.includes(['client', 'co_managed_client', 'admin'], draft.type)) {
        // tech_fee only applies only to media_buyers
        delete validationRules.tech_fee;
    }

    if (orgId && _.includes(['child'], relationship)) {
        // Editing child organization
        // --------------------------
        switch (draft.type) {
            case 'client':
                validationRules = {
                    name: validationRules.name,
                    client_type: validationRules.client_type,
                    type: validationRules.type,
                };
                break;
            case 'co_managed_client':
                validationRules = {
                    name: validationRules.name,
                    type: validationRules.type,
                };
                break;
            case 'media_buyer':
                // send everything, including tech_fee
                break;
            case 'admin':
                delete validationRules.tech_fee; // tech_fee only applies only to media_buyers
                break;
            default:
                break;
        }
    }

    if (orgId && _.includes(['self'], relationship)) {
        // Editing self organization
        // --------------------------
        validationRules = {
            name: validationRules.name,
            type: validationRules.type,
        };
    }

    if (!orgId) {
        // Creating organization (always child)
        // ------------------------------------
        switch (draft.type) {
            case 'client':
            case 'co_managed_client':
                validationRules = {
                    name: validationRules.name,
                    type: validationRules.type,
                };
                break;
            case 'media_buyer':
                // all field need validationRules
                break;
            case 'admin':
                delete validationRules.tech_fee; // tech_fee only applies only to media_buyers
                break;
            default:
                break;
        }
    }

    const errors = validator(draft, validationRules);
    return errors;
}

// -----------------------------------------
// Data transformation before sending to api
// -----------------------------------------
export { default as serializedForApi } from './serialize-for-api';

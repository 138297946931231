import { replaceGDPRConsentMacro } from 'common/constants/macroMapping';
import { getEnvironmentSettings } from 'services/environment';

export const processMacrosForCustomHtml = ({ value }) => {
    const envSettings = getEnvironmentSettings();

    let html = value;
    const macroMapping = envSettings.getMacroMapping();
    _.each(macroMapping, (value, key) => {
        html = html.split(key).join(value);
    });

    if (envSettings.useGDPRConsentMacro()) {
        html = replaceGDPRConsentMacro(html);
    }
    return html;
};

export const processMacrosForThirdPartyPixels = pixels => {
    const envSettings = getEnvironmentSettings();
    const macroMapping = envSettings.getMacroMapping();

    let output = [];

    _.each(pixels, pixel => {
        let processedPixel = pixel;

        _.each(macroMapping, (value, key) => {
            processedPixel = processedPixel.split(key).join(value);
        });

        if (envSettings.useGDPRConsentMacro()) {
            output.push(replaceGDPRConsentMacro(processedPixel));
        }
    });

    return output;
};

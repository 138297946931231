import _ from 'lodash';

import { browserHistory } from 'react-router';
import toastr from 'toastr';
import c from 'common/constants/flux-events';
import { serializeForApi } from './services';
import ActivateActions from 'states/resources/activate/actions';

export const activateEdit = {
    open(activation_token) {
        return (dispatch, getState) => {
            dispatch({
                type: c.ACTIVATION__DRAFT__OPEN,
                payload: { activation_token },
            });

            dispatch(ActivateActions.get(activation_token))
                .then(
                    response => {
                        dispatch({
                            type: c.ACTIVATION__DRAFT__FETCH__SUCCESS,
                            payload: { activation_token, activateAttr: { ...response } },
                        });
                        return { activation_token, activateAttr: { ...response } };
                    },
                    error => {
                        dispatch({
                            type: c.ACTIVATION__DRAFT__FETCH__FAIL,
                            payload: {
                                activation_token,
                            },
                            error: error.body,
                        });
                        toastr.error('Invalid token');
                    }
                )
                .then(args => {
                    dispatch({
                        type: c.ACTIVATION__DRAFT__INIT,
                        payload: { ...args },
                    });
                });
        };
    },

    updateDraft(formData) {
        return {
            type: c.ACTIVATION__DRAFT__UPDATE,
            payload: { formData },
        };
    },

    showErrors() {
        return {
            type: c.ACTIVATION__DRAFT__SHOW_ERRORS,
            payload: {},
        };
    },

    submitDraft(history) {
        return (dispatch, getState) => {
            const store_activation = _.get(getState(), `activate`, void 0);
            const errors = _.get(store_activation, `errors`, []);
            if (errors.length !== 0) {
                // There are errors dont do ajax
                dispatch({
                    type: c.ACTIVATION__DRAFT__SHOW_ERRORS,
                    payload: {
                        payloadSerialized: null,
                        activation_token: null,
                    },
                });
                return;
            }

            // No errors, essemble payload and serialize
            const draft = _.get(store_activation, `draft`, {});
            const activation_token = _.get(store_activation, `activation_token`, void 0);
            const payload_beforeSerialized = {
                ...draft,
                activation_token: activation_token,
            };
            const payloadSerialized = serializeForApi(payload_beforeSerialized);
            // const payloadSerialized = payload_beforeSerialized;

            dispatch({
                type: c.ACTIVATION__DRAFT__SUBMIT,
                payload: { payloadSerialized },
            });

            dispatch(ActivateActions.update(payloadSerialized)).then(
                response => {
                    dispatch({
                        type: c.ACTIVATION__DRAFT__SUBMIT_SUCCESS,
                        payload: {
                            response,
                        },
                    });

                    toastr.success('Activation success.');

                    browserHistory.push(`/`);

                    dispatch({
                        type: c.ACTIVATION__DRAFT__CLOSE,
                    });
                },
                error => {
                    dispatch({
                        type: c.ACTIVATION__DRAFT__SUBMIT_FAIL,
                        payload: {},
                        error: error.body,
                    });

                    toastr.error('Error in activation');
                }
            );
        };
    },
};

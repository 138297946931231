import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { NeutralButton, PrimaryButton } from 'widgets-v5/buttons';

class OptionSelector extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        btnTextLeft: PropTypes.string.isRequired,
        btnTextRight: PropTypes.string.isRequired,
        tooltipLeft: PropTypes.string,
        tooltipRight: PropTypes.string,
        disableTooltips: PropTypes.bool,
        leftButton: PropTypes.node,
        rightButton: PropTypes.node,
        position: PropTypes.string,
    };

    state = {
        position: this.props.position,
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            position: newProps.position,
        });
    }

    getPosition = () => {
        return this.state.position;
    };

    updatePosition = position => {
        this.setState({
            position: position,
        });
        if (this.props.onClick) {
            this.props.onClick(position);
        }
    };

    render() {
        const isLeft = this.state.position === 'left';
        const isRight = !isLeft;

        if (this.props.disableTooltips) {
            return (
                <div className={classnames('ef5-optionSelector', this.props.className)}>
                    {isLeft ? (
                        <PrimaryButton
                            text={this.props.btnTextLeft.toUpperCase()}
                            onClick={() => this.updatePosition('left')}
                        />
                    ) : (
                        <NeutralButton
                            text={this.props.btnTextLeft.toUpperCase()}
                            onClick={() => this.updatePosition('left')}
                        />
                    )}
                    {isRight ? (
                        <PrimaryButton
                            text={this.props.btnTextRight.toUpperCase()}
                            onClick={() => this.updatePosition('right')}
                        />
                    ) : (
                        <NeutralButton
                            text={this.props.btnTextRight.toUpperCase()}
                            onClick={() => this.updatePosition('right')}
                        />
                    )}
                </div>
            );
        } else {
            return (
                <div className={classnames('ef5-optionSelector', this.props.className)}>
                    <InlineTooltip tooltip={this.props.tooltipLeft}>
                        {this.props.leftButton}
                    </InlineTooltip>
                    <InlineTooltip tooltip={this.props.tooltipRight}>
                        {this.props.rightButton}
                    </InlineTooltip>
                </div>
            );
        }
    }
}

export default OptionSelector;

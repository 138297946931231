import _ from 'lodash';

export default function selector(storeState, props) {
    const isOpen = _.get(storeState, `bulkUploadCreativesForm.isOpen`);

    const tempAssets = _.get(storeState, `bulkUploadCreativesForm.assets`);
    const failedAssets = _.get(storeState, `bulkUploadCreativesForm.failedAssets`);
    const loadingAssets = _.get(storeState, `bulkUploadCreativesForm.loadingAssets`);
    const creatives = _.get(storeState, `bulkUploadCreativesForm.creatives`);
    const format = _.get(storeState, `bulkUploadCreativesForm.format`);
    const creativeVendor = _.get(storeState, `bulkUploadCreativesForm.creativeVendor`);
    const isSubmitting = _.get(storeState, `bulkUploadCreativesForm.isSubmitting`);
    const specifiedThirdPartyVendors = _.get(
        storeState,
        `bulkUploadCreativesForm.specifiedThirdPartyVendors`
    );
    const showCreativeVendorError = _.get(
        storeState,
        `bulkUploadCreativesForm.showCreativeVendorError`
    );
    const showFileTypeError = _.get(storeState, `bulkUploadCreativesForm.showFileTypeError`);

    return {
        isOpen,
        creatives,
        tempAssets,
        failedAssets,
        loadingAssets,
        format,
        creativeVendor,
        isSubmitting,
        specifiedThirdPartyVendors,
        showCreativeVendorError,
        showFileTypeError,
    };
}

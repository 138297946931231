import get from 'lodash/get';

export default function selector(storeState, props) {
    const { campaignId } = props.params;
    const campaign = get(storeState, `resources.campaigns.${campaignId}.attr`);
    const isLoading = get(storeState, `campaignsOverview.isLoading`, true);
    const ownerName = get(storeState, `adsOverview.ownerName`);
    const campaignDataShowing = get(storeState, 'campaignsOverview.campaignDataShowing', false);

    if (!campaign) {
        return {
            campaignId,
            loading: isLoading,
            ownerName: ownerName,
        };
    }

    return {
        campaign,
        campaignDataShowing,
    };
}

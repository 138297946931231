import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import PublicIcon from '@mui/icons-material/Public';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import FormLabel from '@mui/material/FormLabel';

import { SortableChipRow } from 'widgets-v5/chip';
import { MultiSelect } from 'widgets-v6/select';
import { DialogTitle } from 'widgets-v6/modal';
import ExportReportButton from 'containers/export-report-button';
import InventoryReportLayout from '../shared/layout';
import InventoryReportPivotTable from '../shared/pivot-table';
import { APP_STORE_CAT_RELEASE_DATE } from 'common/constants/date-flags';
import metricConfig from 'common/metrics';
import dimensionConfig from 'common/dimensions';
import attributeConfig from 'common/attributes';
import flags from 'containers/flags/service';

import {
    openFilters,
    closeFilters,
    applyFilters,
    updateDimensions,
    updateAttributes,
    updateMetrics,
    updateLimit,
} from 'pages/apps-and-sites/apps-report/actions';

import selector from './selector';
import { useAppReport } from './hook';

const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    drawer: {
        width: 550,
        padding: theme.spacing(2),
    },
}));

const DIMENSION_OPTIONS = _.map(
    ['channel_name', 'channel_type', 'app_store_cat'],
    key => dimensionConfig[key]
);

const ATTRIBUTE_OPTIONS = _.map(['app_store_cat_attr'], key => attributeConfig[key]);

const METRIC_OPTIONS = _.map(['impressions', 'clicks', 'ctr'], key => metricConfig[key]);

class InventoryReport extends React.Component {
    state = {
        selectedMetrics: ['impressions', 'clicks', 'ctr'],
        selectedDimensions: ['channel_name'],
        selectedAttributes: ['app_store_cat_attr'],
        // This key is used to force render the Pivot Table when
        // criteria changes so that new data can be fetched
        pivotTableKey: null,
        request: null,
        hideZeroRows: false,
        limit: 100,
        sortColumn: 'impressions',
        sortDirection: 'desc',
        status: 'waiting',
        errors: {},
    };

    getState = () => {
        if (flags.isEnabled('EN_1960_app_report_mui_filters')) {
            return this.props;
        } else {
            return this.state;
        }
    };

    apply = () => {
        if (this.getState().status === 'error') {
            return;
        }

        // If the current sort column was removed, we need to fall back to another one
        const sortableColumns = [
            ...this.getState().selectedMetrics,
            'dimension',
            ...this.getState().selectedAttributes,
        ];
        let sortColumn = this.getState().sortColumn;
        if (!_.includes(sortableColumns, this.getState().sortColumn)) {
            sortColumn = _.first(sortableColumns);
        }

        setTimeout(() => {
            this.setState({
                pivotTableKey: Date.now(),
                request: this.createRequest(),
                sortColumn,
                status: 'waiting',
            });
        }, 10);
    };

    createRequest = () => {
        // By default we should show data only for the last 30 days, however
        // we need to exclude the data that was generated pre-release of the
        // app cat feature
        const thirtyDaysAgo = moment
            .utc()
            .subtract(30, 'days')
            .startOf('day');
        const start = thirtyDaysAgo.isAfter(APP_STORE_CAT_RELEASE_DATE)
            ? thirtyDaysAgo
            : APP_STORE_CAT_RELEASE_DATE;

        let request = {
            match: {
                camp_org: [this.props.profile.organizationId],
                channel_type: ['APP'],
            },
            split: this.getState().selectedDimensions,
            metrics: this.getState().selectedMetrics,
            attributes: this.getState().selectedAttributes,
            start: start.format().replace('Z', ''),
            end: moment
                .utc()
                .endOf('day')
                .format()
                .replace('Z', ''),
            timezone: 'UTC',
            top: {
                field: 'channel_id',
                limit: this.getState().limit,
            },
            source: 'channel',
            version: 'v3',
        };

        return request;
    };

    sort = column => {
        const sortColumn = column.value;

        let sortDirection = 'desc';
        if (this.state.sortColumn === sortColumn && this.state.sortDirection === 'desc') {
            sortDirection = 'asc';
        }

        this.setState({ sortDirection, sortColumn });
    };

    updateDimensions = dimensions => {
        this.setState({ selectedDimensions: dimensions, status: 'waiting' }, this.validate);
    };

    updateMetrics = metrics => {
        this.setState({ selectedMetrics: metrics, status: 'waiting' }, this.validate);
    };

    validate = () => {
        const errors = {};

        if (this.state.selectedMetrics.length === 0) {
            errors.metric = 'Select at least 1 metric';
        }

        if (this.state.selectedDimensions.length === 0) {
            errors.dimension = 'Select at least 1 dimension';
        }

        this.setState({
            errors,
            status: _.keys(errors).length > 0 ? 'error' : this.state.status,
        });
    };

    componentDidMount() {
        this.apply();
    }

    render() {
        const formattedOptions = _.map(DIMENSION_OPTIONS, item => {
            if (item.label === 'App Name') {
                return { ...item, label: 'Site Name' };
            } else {
                return { ...item };
            }
        });

        return flags.isEnabled('EN_1960_app_report_mui_filters') ? (
            <InventoryReportDrawer
                exportReportButton={
                    this.state.request && (
                        <ExportReportButton
                            request={{
                                ...this.state.request,
                                format: 'csv',
                                hideZeroRows: this.props.hideZeroRows,
                                sortColumn: this.state.sortColumn,
                                sortDirection: this.props.sortDirection,
                            }}
                            fileName={`${moment().format('YYYY_MM_DD')}.csv`}
                        />
                    )
                }
                pivotTable={
                    this.state.request && (
                        <InventoryReportPivotTable
                            key={this.state.pivotTableKey}
                            hideZeroRows={this.props.hideZeroRows}
                            channel_type={'APP'}
                            request={this.state.request}
                            sortColumn={this.state.sortColumn}
                            sortDirection={this.props.sortDirection}
                            onSort={this.sort}
                        />
                    )
                }
                selectedDimensions={this.props.selectedDimensions}
                selectedAttributes={this.props.selectedAttributes}
                selectedMetrics={this.props.selectedMetrics}
                channel_type={'APP'}
                formattedOptions={formattedOptions}
            >
                <FilterOptions
                    apply={this.apply}
                    status={this.state.status}
                    errors={this.props.errors}
                    channel_type={'APP'}
                    selectedDimensions={this.props.selectedDimensions}
                    dimensionOptions={DIMENSION_OPTIONS}
                    selectedAttributes={this.props.selectedAttributes}
                    attributeOptions={ATTRIBUTE_OPTIONS}
                    selectedMetrics={this.props.selectedMetrics}
                    metricOptions={METRIC_OPTIONS}
                    sortColumn={this.props.sortColumn}
                    formattedOptions={formattedOptions}
                />
            </InventoryReportDrawer>
        ) : (
            <InventoryReportLayout
                status={this.state.status}
                errors={this.state.errors}
                channel_type={'APP'}
                selectedDimensions={this.state.selectedDimensions}
                dimensionOptions={DIMENSION_OPTIONS}
                updateDimensions={this.updateDimensions}
                selectedAttributes={this.state.selectedAttributes}
                attributeOptions={ATTRIBUTE_OPTIONS}
                updateAttributes={attributes => {
                    this.setState({ selectedAttributes: attributes });
                }}
                selectedMetrics={this.state.selectedMetrics}
                metricOptions={METRIC_OPTIONS}
                updateMetrics={this.updateMetrics}
                limit={this.state.limit}
                showLimit={limit => {
                    this.setState({ limit });
                }}
                sortColumn={this.state.sortColumn}
                exportReportButton={
                    this.state.request && (
                        <ExportReportButton
                            request={{
                                ...this.state.request,
                                format: 'csv',
                                hideZeroRows: this.state.hideZeroRows,
                                sortColumn: this.state.sortColumn,
                                sortDirection: this.state.sortDirection,
                            }}
                            fileName={`${moment().format('YYYY_MM_DD')}.csv`}
                        />
                    )
                }
                applyButton={
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.apply}
                        startIcon={<CheckIcon />}
                    >
                        Apply
                    </Button>
                }
                pivotTable={
                    this.state.request && (
                        <InventoryReportPivotTable
                            key={this.state.pivotTableKey}
                            hideZeroRows={this.state.hideZeroRows}
                            channel_type={'APP'}
                            request={this.state.request}
                            sortColumn={this.state.sortColumn}
                            sortDirection={this.state.sortDirection}
                            onSort={this.sort}
                        />
                    )
                }
            />
        );
    }
}

const FILTERS_MAPPING = {
    channel_name: 'Site Name',
    channel_type: 'Type',
    app_store_cat: 'Category',
    app_store_cat_attr: 'Category',
    impressions: 'Impressions',
    clicks: 'Clicks',
    ctr: 'CTR',
};

function FilterSection(props) {
    return (
        <Grid container spacing={1} alignItems="center">
            <Typography color="textSecondary">Dimenstions:</Typography>
            {_.map(props.selectedDimensions, (value, i) => (
                <Grid item key={value}>
                    <Box display="flex" alignItems="center">
                        <Chip label={FILTERS_MAPPING[value]} />
                        {i + 1 !== props.selectedDimensions.length && (
                            <SubdirectoryArrowRightIcon />
                        )}
                    </Box>
                </Grid>
            ))}
            <Typography color="textSecondary">Attributes:</Typography>
            {_.map(props.selectedAttributes, value => (
                <Grid item key={value}>
                    <Chip label={FILTERS_MAPPING[value]} />
                </Grid>
            ))}
            <Typography color="textSecondary">Metrics:</Typography>
            {_.map(props.selectedMetrics, value => (
                <Grid item key={value}>
                    <Chip label={FILTERS_MAPPING[value]} />
                </Grid>
            ))}
        </Grid>
    );
}

export function InventoryReportDrawer(props) {
    const {
        exportReportButton,
        selectedDimensions,
        selectedAttributes,
        selectedMetrics,
        pivotTable,
        formattedOptions,
    } = props;

    const classes = useStyles();

    const dispatch = useDispatch();

    const { isFilterPopUpOpen: open } = useAppReport();

    const openDrawer = () => {
        dispatch(openFilters());
    };

    const closeDrawer = () => {
        dispatch(closeFilters());
    };

    return (
        <div>
            <Drawer
                open={open}
                anchor="right"
                onClose={closeDrawer}
                classes={{ paper: classes.drawer }}
            >
                <DialogTitle onClose={closeDrawer}>Settings</DialogTitle>
                {props.children}
            </Drawer>
            <TableContainer
                openDrawer={openDrawer}
                exportReportButton={exportReportButton}
                selectedDimensions={selectedDimensions}
                formattedOptions={formattedOptions}
                selectedAttributes={selectedAttributes}
                selectedMetrics={selectedMetrics}
            >
                {pivotTable}
            </TableContainer>
        </div>
    );
}

function TableContainer(props) {
    const {
        openDrawer,
        exportReportButton,
        selectedDimensions,
        selectedAttributes,
        selectedMetrics,
    } = props;

    return (
        <Container>
            <Box pb={1}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="h6">Last 30 Days' Stats</Typography>
                    </Grid>
                    <Grid item>
                        <Box display="flex">
                            <PublicIcon />
                            <Typography color="textSecondary">UTC</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Paper>
                <Box p={1}>
                    <Box display="flex" justifyContent="space-between">
                        <Button size="small" endIcon={<FilterListIcon />} onClick={openDrawer}>
                            Open settings
                        </Button>
                        <div
                            className="ef6-alignment__space-between"
                            style={{ alignItems: 'center', marginBottom: 10 }}
                        >
                            <div className="ef6-alignment__right">{exportReportButton}</div>
                        </div>
                    </Box>
                    <Box pb={1}>
                        <FilterSection
                            selectedAttributes={selectedAttributes}
                            selectedMetrics={selectedMetrics}
                            selectedDimensions={selectedDimensions}
                        />
                    </Box>
                    {props.children}
                </Box>
            </Paper>
        </Container>
    );
}

function FilterOptions(props) {
    const { formattedOptions, attributeOptions, metricOptions, channel_type, apply } = props;

    const dispatch = useDispatch();

    const {
        draft: { selectedDimensions, selectedAttributes, selectedMetrics, limit },
        errors,
        status,
        showErrors,
    } = useAppReport();

    const applyUpdatedFilters = () => {
        dispatch(applyFilters(status));

        if (status !== 'error') {
            apply();
        }
    };

    const updateDimension = dimensions => {
        dispatch(updateDimensions(dimensions));
    };

    const updateAttribute = attributes => {
        dispatch(updateAttributes(attributes));
    };

    const updateMetric = metrics => {
        dispatch(updateMetrics(metrics));
    };

    const handleLimitClick = limit => {
        dispatch(updateLimit(limit));
    };

    return (
        <React.Fragment>
            <Box p={1}>
                <FormLabel>Dimensions</FormLabel>
                <MultiSelect
                    placeholder="Dimensions"
                    value={selectedDimensions}
                    options={formattedOptions}
                    onChange={value => updateDimension(value)}
                    error={showErrors && status === 'error'}
                    errorMessage={showErrors && errors.dimension}
                />
            </Box>
            <SortableChipRow
                value={selectedDimensions}
                items={formattedOptions}
                onChange={value => updateDimension(value)}
            />
            <Box p={1}>
                <FormLabel>Attributes</FormLabel>
                <MultiSelect
                    placeholder="Attributes"
                    value={selectedAttributes}
                    options={attributeOptions}
                    onChange={value => updateAttribute(value)}
                />
            </Box>
            <Box p={1}>
                <FormLabel>Metrics</FormLabel>
                <MultiSelect
                    placeholder="Metrics"
                    value={selectedMetrics}
                    options={metricOptions}
                    onChange={value => updateMetric(value)}
                />
            </Box>
            <div style={{ marginTop: 10 }} className="ef6-alignment__space-between">
                <span className="ef6-alignment__flex">
                    <span>Show top: </span>
                    <span
                        className={cn('ef5-interactive-area__primary', {
                            'ef5-color-scheme_secondary': limit === 100,
                        })}
                        onClick={() => handleLimitClick(100)}
                    >
                        100
                    </span>
                    <span> / </span>
                    <span
                        className={cn('ef5-interactive-area__primary', {
                            'ef5-color-scheme_secondary': limit === 500,
                        })}
                        onClick={() => handleLimitClick(500)}
                    >
                        500
                    </span>
                    <span> / </span>
                    <span
                        className={cn('ef5-interactive-area__primary', {
                            'ef5-color-scheme_secondary': limit === -1,
                        })}
                        onClick={() => handleLimitClick(-1)}
                    >
                        {channel_type === 'APP' ? 'All Apps' : 'All Sites'}
                    </span>
                </span>
            </div>
            <div>
                <Button variant="contained" color="primary" onClick={() => applyUpdatedFilters()}>
                    Apply
                </Button>
            </div>
        </React.Fragment>
    );
}

export default connect(selector)(InventoryReport);

import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import { getDayPart } from 'containers/ad-summary';
import { ScheduleDayparts } from 'forms/ad-form/modules/schedule';

import shallowCompare from 'utils/shallow-compare';
import Typography from '@mui/material/Typography';

export const DaypartsForm = props => {
    const { ad, ads, campaign, onSave } = props;
    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Dayparts"
            fields={['dayparts', 'dayparts_local']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <React.Fragment>
                        <ScheduleDayparts
                            formData={draft}
                            onFieldChange={field => value => updateDraft({ [field]: value })}
                            errors={errors}
                            showErrors={shouldShowErrors}
                        />
                        <Typography color="textSecondary">{`Currently using ${
                            draft.timezone
                        } timezone`}</Typography>
                    </React.Fragment>
                );
            }}
        </BaseColumnForm>
    );
};

export class DaypartsSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;
        const daypartValue = {
            field: _.isArray(ad.dayparts) ? 'dayparts' : 'dayparts_local',
            value: _.isArray(ad.dayparts) ? ad.dayparts : ad.dayparts_local,
        };
        const dayparts = getDayPart(
            daypartValue,
            _.isArray(ad.dayparts_local) ? 'UTC' : ad.timezone,
            ad.start
        );

        return (
            <div>
                <Typography>{dayparts}</Typography>
            </div>
        );
    }
}

import React from 'react';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import AccordionItem from 'widgets-v5/expansion-panel-item';
import CreativeAssetSelector from '../creative-asset-selector';
import ClickthroughCard from '../click-through-card/click-through-card';
import ThirdPartyPixelsInput from '../third-party-pixels-input';
import {
    CreativeVendorSelector,
    TrackingVendorsSelector,
} from '../vendor-selector/vendor-selector';

import { creativeTrackingVendorsMapping, StyledLink } from '../../index';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';

const useStyles = makeStyles(theme => ({
    image: {
        width: 70,
    },
    thirdPartyVendors: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function CreativeFormVideoType(props) {
    const classes = useStyles();
    const { errors, draft, showErrors, updateVendors, onFieldChange, activeStep } = props;

    const errorsForVideoAsset = []
        .concat(
            errors.video_url,
            errors.video_preview_url,
            errors.image_preview_url,
            errors.video_duration,
            errors.video_bit_rate_kbps
        )
        .filter(x => x);

    const errorsForCompanionAsset = []
        .concat(errors.companion_asset, errors.companion_url, errors.companion_preview_url)
        .filter(x => x);

    const thirdPartyPixelsErrors = []
        .concat(errors.third_party_pixels, errors.third_party_quartile_pixels)
        .filter(x => x);
    
    const isDOOHCampaign = props.campaignType === CampaignTypeMapping.DOOH;

    if (activeStep === 1) {
        return (
            <div>
                <AccordionItem
                    onClick={props.expandPanel('video')}
                    expanded={props.expandedPanel === 'video'}
                    title="Video"
                    required
                    error={showErrors && errorsForVideoAsset.length > 0}
                    details={
                        <React.Fragment>
                            <CreativeAssetSelector
                                mode={props.mode}
                                uploadFile={props.uploadFile}
                                errors={props.errors}
                                assets={props.assets}
                                draft={props.draft}
                                onFieldChange={props.onFieldChange}
                                onMultiFieldChange={props.onMultiFieldChange}
                                inputUrlFieldsType="video"
                                showErrors={props.showErrors}
                                areAssetsLoading={props.areAssetsLoading}
                                onChangeAssetSelectionMethod={props.onChangeAssetSelectionMethod}
                                onSelectAssetWithWarnings={props.onSelectAssetWithWarnings}
                                uploadAssetsErrorMessage={props.uploadAssetsErrorMessage}
                                hideUploadAssetsErrorMessage={props.hideUploadAssetsErrorMessage}
                            />
                            {draft.assetSelectionMethod === 'inputByUrl' && (
                                <CreativeVendorSelector
                                    showErrors={showErrors}
                                    errors={errors}
                                    draft={draft}
                                    onFieldChange={onFieldChange}
                                    updateVendors={updateVendors}
                                    creativeTagsMatched={props.creativeTagsMatched}
                                    creativeType={'video'}
                                />
                            )}
                        </React.Fragment>
                    }
                    summary={
                        <div>
                            <div>
                                {draft.video_preview_url ? (
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <VideoPreview imageUrl={draft.image_preview_url} />
                                        </Grid>
                                        <Grid item>
                                            {props.creativeMapping[draft.video_preview_url]}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Typography color="textSecondary">Add video</Typography>
                                )}
                            </div>
                            <div>
                                {' '}
                                {draft.creative_vendors.length > 0 ? (
                                    <div className={classes.thirdPartyVendors}>
                                        {_.map(draft.creative_vendors, vendor => {
                                            return (
                                                <Chip
                                                    key={vendor}
                                                    label={
                                                        creativeTrackingVendorsMapping[vendor] ||
                                                        vendor
                                                    }
                                                    variant="outlined"
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add creative vendor
                                    </Typography>
                                )}{' '}
                            </div>
                        </div>
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('companionAd')}
                    expanded={props.expandedPanel === 'companionAd'}
                    title="Companion Ad"
                    error={showErrors && errorsForCompanionAsset.length > 0}
                    details={
                        <React.Fragment>
                            <CreativeAssetSelector
                                mode={props.mode}
                                uploadFile={props.uploadFile}
                                errors={props.errors}
                                assets={props.assets}
                                draft={props.draft}
                                onFieldChange={props.onFieldChange}
                                onMultiFieldChange={props.onMultiFieldChange}
                                inputUrlFieldsType="videoCompanion"
                                showErrors={props.showErrors}
                                areAssetsLoading={props.areAssetsLoading}
                                onChangeAssetSelectionMethod={props.onChangeAssetSelectionMethod}
                                uploadAssetsErrorMessage={props.uploadAssetsErrorMessage}
                                hideUploadAssetsErrorMessage={props.hideUploadAssetsErrorMessage}
                            />
                            <CreativeVendorSelector
                                showErrors={showErrors}
                                errors={errors}
                                draft={draft}
                                onFieldChange={onFieldChange}
                                updateVendors={updateVendors}
                                creativeTagsMatched={props.creativeTagsMatched}
                                creativeType={'video'}
                                required={false}
                            />
                        </React.Fragment>
                    }
                    summary={
                        <div>
                            <div>
                                {draft.companion_preview_url ? (
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <img
                                                className={classes.image}
                                                src={draft.companion_preview_url}
                                                alt=""
                                            />
                                        </Grid>
                                        <Grid item>
                                            {props.creativeMapping[draft.companion_preview_url]}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add companion image
                                    </Typography>
                                )}
                            </div>
                            <div>
                                {' '}
                                {draft.creative_vendors.length > 0 && draft.creative_vendors[0] ? (
                                    <div className={classes.thirdPartyVendors}>
                                        {_.map(draft.creative_vendors, vendor => {
                                            return (
                                                <Chip
                                                    key={vendor}
                                                    label={
                                                        creativeTrackingVendorsMapping[vendor] ||
                                                        vendor
                                                    }
                                                    variant="outlined"
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add creative vendor
                                    </Typography>
                                )}{' '}
                            </div>
                        </div>
                    }
                />
                {!isDOOHCampaign && <AccordionItem
                    onClick={props.expandPanel('thirdPartyClicktracker')}
                    expanded={props.expandedPanel === 'thirdPartyClicktracker'}
                    title="Clicktracking"
                    required
                    error={showErrors && errorsForCompanionAsset.length > 0}
                    details={
                        <React.Fragment>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={draft.third_party_clickthrough}
                                            color="primary"
                                            onChange={() => {
                                                props.onFieldChange('third_party_clickthrough')(
                                                    !draft.third_party_clickthrough
                                                );
                                            }}
                                            value={draft.third_party_clickthrough}
                                        />
                                    }
                                    label="Use Third-party Clicktracker"
                                />
                            </FormControl>
                            <Box mt={2}>
                                <Divider />
                            </Box>
                            <ClickthroughCard
                                draft={draft}
                                errors={errors}
                                onFieldChange={onFieldChange}
                                showErrors={showErrors}
                            />
                        </React.Fragment>
                    }
                    summary={
                        <div>
                            <Box mb={1}>
                                {draft.third_party_clickthrough
                                    ? 'Use Third-party Clicktracker'
                                    : "Don't use Third-party Clicktracker"}
                            </Box>
                            {draft.landing_page || draft.clickthrough_url ? (
                                draft.third_party_clickthrough ? (
                                    <div>
                                        {draft.clickthrough_url && (
                                            <div>
                                                <Typography color="textSecondary">
                                                    Third-Party Clicktracker URL
                                                </Typography>
                                                <StyledLink>{draft.clickthrough_url}</StyledLink>
                                            </div>
                                        )}
                                        {draft.landing_page && (
                                            <div>
                                                <Typography color="textSecondary">
                                                    Landing Page
                                                </Typography>
                                                <StyledLink>{draft.landing_page}</StyledLink>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    draft.landing_page && (
                                        <div>
                                            <Typography color="textSecondary">
                                                Landing Page
                                            </Typography>
                                            <StyledLink>{draft.landing_page}</StyledLink>
                                        </div>
                                    )
                                )
                            ) : (
                                <Typography color="textSecondary">
                                    {draft.third_party_clickthrough
                                        ? 'Add third-party clicktracker URL and landing page'
                                        : 'Add landing page'}
                                </Typography>
                            )}
                        </div>
                    }
                />}
                {!isDOOHCampaign && <AccordionItem
                    onClick={props.expandPanel('thirdPartyClicker')}
                    expanded={props.expandedPanel === 'thirdPartyClicker'}
                    title="Third Party Pixel"
                    error={showErrors && thirdPartyPixelsErrors.length > 0}
                    details={
                        <ThirdPartyPixelsInput
                            onChange={props.onFieldChange}
                            third_party_quartile_pixels={draft.third_party_quartile_pixels}
                            third_party_pixels={draft.third_party_pixels}
                            error={showErrors && thirdPartyPixelsErrors.length > 0}
                            canAddVastPixels={draft.format === 'video'}
                        />
                    }
                    summary={
                        draft.third_party_pixels.length > 0 ? (
                            <div>
                                {_.map(draft.third_party_pixels, pixel => (
                                    <StyledLink key={pixel}>{pixel}</StyledLink>
                                ))}
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add third party pixel</Typography>
                        )
                    }
                />}
                {!isDOOHCampaign && <AccordionItem
                    onClick={props.expandPanel('trackingVendors')}
                    expanded={props.expandedPanel === 'trackingVendors'}
                    title="Tracking Vendors"
                    error={props.showErrors && errors.tracking_vendors}
                    details={
                        <TrackingVendorsSelector
                            showErrors={showErrors}
                            errors={errors}
                            draft={draft}
                            onFieldChange={onFieldChange}
                            updateVendors={updateVendors}
                            trackingTagsMatched={props.trackingTagsMatched}
                            creativeType={'video'}
                        />
                    }
                    summary={
                        draft.tracking_vendors.length > 0 ? (
                            <div className={classes.thirdPartyVendors}>
                                {_.map(draft.tracking_vendors, vendor => {
                                    return (
                                        <Chip
                                            key={vendor}
                                            label={creativeTrackingVendorsMapping[vendor] || vendor}
                                            variant="outlined"
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add tracking vendors</Typography>
                        )
                    }
                />}
            </div>
        );
    }
}

function VideoPreview(props) {
    const classes = useStyles({ previewImage: props.imageUrl });
    return (
        <div className={classes.videoPreview}>
            <video className={classes.video} muted loop preload="none" src={props.imageUrl} />
        </div>
    );
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class LoadGroup extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        isLoading: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    };

    static defaultProps = {
        isLoading: false,
        size: 'medium',
    };

    render() {
        const { children, showLoadingIndicator = true } = this.props;
        return (
            <div className={classnames('ef4-load-group', this.props.className)}>
                <div
                    className={classnames('ef4-load-group__cover', {
                        'ef4-load-group__cover_is-loading': this.props.isLoading,
                    })}
                />
                <div
                    className={classnames('ef4-load-group__icon', {
                        'ef4-load-group__icon_is-loading': this.props.isLoading,
                    })}
                >
                    {showLoadingIndicator && (
                        <i
                            className={classnames('fa fa-circle-o-notch ef4-load-group__spin', {
                                fa: this.props.size === 'small',
                                'fa-2x': this.props.size === 'medium',
                                'fa-3x': this.props.size === 'large',
                            })}
                        />
                    )}
                    <div className="ef4-load-group__title">{this.props.title}</div>
                </div>
                <i
                    className={classnames(
                        /* Element exists to put a minimum size on the load group */
                        'ef4-load-group__size-placeholder',
                        'fa fa-circle-o-notch',
                        {
                            fa: this.props.size === 'small',
                            'fa-2x': this.props.size === 'medium',
                            'fa-3x': this.props.size === 'large',
                        }
                    )}
                />
                {_.isFunction(children) ? children() : children}
            </div>
        );
    }
}

export default LoadGroup;

export function InlineBlockLoadGroup(props) {
    return (
        <div className="ef4-inline-block-load-group">
            <LoadGroup {...props} />
        </div>
    );
}

export function BlockLoadGroup(props) {
    return (
        <div className="ef4-block-load-group">
            <LoadGroup {...props} />
        </div>
    );
}

import _ from 'lodash';

const initialState = {
    open: false,
    selectedFlexBrandId: '',
};

export const NAME = 'flexSegmentsForm';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'FLEX_SEGMENT_FORM__OPEN': {
            return {
                ...state,
                open: true,
                selectedFlexBrandId: action.payload.selectedFlexBrandId,
            };
        }

        case 'FLEX_SEGMENT_FORM__CLOSE': {
            return {
                ...state,
                open: false,
                selectedFlexBrandId: '',
            };
        }

        default: {
            return state;
        }
    }
}

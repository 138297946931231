import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
/** import mui components in the section above, keep the ascending order */

import { GroupedMultiSelectTabs, MultiSelect } from 'widgets-v6/select';
/** import widgets-v5 and widgets-v6 components in the section above, keep the ascending order */

import { DEAL_CATEGORY_OPTIONS, DealCategory } from 'common/constants/deals';
import actions from 'forms/media-plan-form/actions';
import BaseColumnForm from '../base-column-form';
import flags from 'containers/flags/service';
import selector, { formSelector } from './selector';
import shallowCompare from 'utils/shallow-compare';
/** import classes and others in the section above, keep the ascending order */

class DealsSummaryComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { dealsInAd } = this.props;
        const filteredDeals = dealsInAd.filter(deal => {
            if (flags.isEnabled('efb_35_curated_deals')) {
                return true;
            } else {
                return deal.category === DealCategory.Private;
            }
        });
        return (
            <div>
                <Typography>
                    {_.map(filteredDeals, deal => (
                        <div>{_.get(deal, 'app_name')}</div>
                    ))}
                </Typography>
            </div>
        );
    }
}
export const DealsSummary = connect(selector)(DealsSummaryComponent);

const DealsFormComponent = props => {
    const { ad, ads, campaign, onSave, dealsByOrganization, dispatch } = props;

    const filteredDeals = dealsByOrganization.filter(deal => {
        if (flags.isEnabled('efb_35_curated_deals')) {
            return true;
        } else {
            return deal.category === DealCategory.Private;
        }
    }); // Hide curated deals if flag is disabled

    const options = _.map(filteredDeals, deal => ({
        label: deal.app_name,
        value: deal.id,
        category: deal.category,
    }));

    const onSaveDeals = async () => {
        if (ads && ads.length > 0) {
            await Promise.all(
                _.map(ads, async ad => {
                    dispatch(actions.fetchAdDeals(ad.id));
                })
            );
        } else {
            await dispatch(actions.fetchAdDeals(ad.id));
        }
        onSave();
    };

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline={flags.isEnabled('efb_35_curated_deals') ? 'Deals' : 'Private Deals'}
            fields={['deals']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSaveDeals}
            onCancel={onSave}
            columnName={flags.isEnabled('efb_35_curated_deals') ? 'Deals' : 'Private Deals'}
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <FormControl fullWidth margin="normal">
                        <FormLabel>
                            {flags.isEnabled('efb_35_curated_deals') ? 'Deals' : 'Private Deals'}
                        </FormLabel>
                        {flags.isEnabled('efb_35_curated_deals') ? (
                            <GroupedMultiSelectTabs
                                value={draft.deals}
                                onChange={value => updateDraft({ deals: value })}
                                options={options}
                                placeholder={
                                    flags.isEnabled('efb_35_curated_deals')
                                        ? 'Select deals'
                                        : 'Select private deals'
                                }
                                tabs={
                                    flags.isEnabled('efb_35_curated_deals')
                                        ? DEAL_CATEGORY_OPTIONS
                                        : []
                                }
                                defaultTab={DealCategory.Private}
                                tabField="category"
                            />
                        ) : (
                            <MultiSelect
                                value={draft.deals}
                                onChange={value => updateDraft({ deals: value })}
                                options={options}
                                placeholder={
                                    flags.isEnabled('efb_35_curated_deals')
                                        ? 'Select deals'
                                        : 'Select private deals'
                                }
                            />
                        )}
                    </FormControl>
                );
            }}
        </BaseColumnForm>
    );
};

export const DealsForm = connect(formSelector)(DealsFormComponent);

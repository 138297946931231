import React, { Component } from 'react';
import { Link as LinkRouter } from 'react-router';

import { HelpTopicLayout } from '../modules/layout.js';
import { CenterLayout } from 'widgets-v5/layout';
import topics from '../topics';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

class HelpTopic extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { category, topic } = this.props.params;

        const article = _.find(topics, {
            url: `${category}/${topic}`,
        });

        console.log(article);

        return (
            <div className="help-topic">
                <Breadcrumbs aria-label="Breadcrumb">
                    <Link component={LinkRouter} color="inherit" to="/help">
                        Help
                    </Link>
                    <Link component={LinkRouter} color="inherit" to={`/help/${category}`}>
                        {article.category}
                    </Link>
                    <Link
                        component={LinkRouter}
                        color="textPrimary"
                        to={`/help/${category}/${topic}`}
                        aria-current="page"
                    >
                        {article.topic}
                    </Link>
                </Breadcrumbs>
                <CenterLayout>
                    <article.Component />
                </CenterLayout>
            </div>
        );
    }
}

export default HelpTopic;

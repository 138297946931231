import Joi from '@hapi/joi';

export default Joi.object({
    name: Joi.string()
        .required()
        .default('')
        .label('Name'),
    comments: Joi.string()
        .default('')
        .label('Comments')
        .allow(''),
    rawCsv: Joi.string()
        .default(false)
        .allow(null)
        .label('File'),
});

import toastr from 'toastr';
import $ from 'jquery';
import Verror from 'verror';

const SECOND = 1000;

var internalId,
    started = false,
    interval = 60 * SECOND;

const VersionPoller = {
    version: null,

    start() {
        if (started) {
            return;
        }

        VersionPoller._getVersion().then(version => {
            VersionPoller.version = version;

            internalId = setInterval(() => {
                VersionPoller._checkVersion();
            }, interval);

            started = true;
        });
    },

    stop() {
        clearInterval(internalId);
        started = false;
    },

    _getVersion() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/version_git_repo.json',
                dataType: 'text',
                headers: {
                    'Cache-Control': 'max-age=30',
                },
                success(data) {
                    resolve(data);
                },
                error(xhr, status, error) {
                    reject({
                        status,
                        message: `Couldn't poll for version with: ${error}`,
                        statusCode: xhr.status,
                    });
                },
            });
        });
    },

    _checkVersion() {
        VersionPoller._getVersion()
            .then(latestVersion => {
                if (VersionPoller.version !== latestVersion) {
                    toastNewUpdates();

                    VersionPoller.stop();
                }
            })
            .catch(error => {
                if (error.statusCode === 0) {
                    console.log('Version Poller lost connection', { message: error.message });
                } else {
                    throw new Verror(error, 'Version Poller failed unexpectedly');
                }
            });
    },
};

export function toastNewUpdates() {
    toastr.warning(
        "<p>We've just released an update which contains new features or bug fixes. Reload the page to get the latest version.</p>" +
            '<button onclick="window.location.reload();" class="btn btn-warning" style="background-color:#F89406">Update now (recommended) </button>',
        'A new version of EngageFront is available.',
        {
            tapToDismiss: false,
            showDuration: 1000,
            showMethod: 'slideDown',
            timeOut: 0,
            extendedTimeOut: 0,
            positionClass: 'toast-bottom-left',
        }
    );
}

export default VersionPoller;

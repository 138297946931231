import _ from 'lodash';

export default function selector(storeState, props) {
    const {
        isProcessingLocations,
        fileName,
        errors,
        locations,
        locationList,
        options,
        draft,
        showErrors,
        showFileTypeError,
        failedAssets,
        csvErrors,
        isSaving,
    } = _.get(storeState, 'ftaLocationListsEditor');

    return {
        isProcessingLocations,
        fileName,
        errors,
        locations,
        locationList,
        options,
        draft,
        showErrors,
        showFileTypeError,
        failedAssets,
        csvErrors,
        isSaving,
    };
}

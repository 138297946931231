import _ from 'lodash';

// --------------
// Default values
// --------------

// ----------
// Validation
// ----------
import { draftValidators_forPayload } from './validation-rules';
import { draftValidators_notForPayload } from './validation-rules';
import validate from 'utils/validate';

export const draftValidators = {
    ...draftValidators_forPayload,
    ...draftValidators_notForPayload,
};

export function validation(draft) {
    const _draft = {
        ...draft,
    };
    const validators = _.pick(draftValidators, Object.keys(_draft));
    return validate(_draft, validators);
}

// -----------------------------------------
// Data transformation before sending to api
// -----------------------------------------

import _ from 'lodash';
import React from 'react';

import FormField from 'widgets-v5/form-field';
import StandardInput from 'widgets-v5/standard-input';
import Toggle from 'widgets-v5/toggle';

import { NeutralButton, SecondaryButton } from 'widgets-v5/buttons';
import DataTable, { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import InfoIconTooltip from 'widgets-v5/info-icon';

class CustomFieldsSelector extends React.Component {
    static defaultProps = {
        customFields: [],
    };

    state = {
        customFields: this.props.customFields,
    };

    emitChange = () => {
        this.props.onChange(this.state.customFields);
    };

    addCustomField = () => {
        const customFields = this.state.customFields.concat({
            key: Date.now().toString(),
            name: '',
            required: false,
            value: '',
        });
        this.setState({ customFields }, () => {
            this.emitChange();
        });
    };

    updateCustomField = (customField, change) => {
        const customFields = _.map(this.state.customFields, f => {
            if (f !== customField) {
                return f;
            }

            const _change = { ...change };
            if (change.name) {
                _change.key = change.name
                    .split(' ')
                    .join('_')
                    .toLowerCase();
            }

            return {
                ...customField,
                ..._change,
            };
        });

        this.setState({ customFields }, () => {
            this.emitChange();
        });
    };

    removeCustomField = customField => {
        const customFields = this.state.customFields.filter(p => p !== customField);
        this.setState({ customFields }, () => {
            this.emitChange();
        });
    };

    render() {
        return (
            <div>
                <ComposableDataTable scrollable className="ef5-organization-setup__table">
                    <Row key="header" header>
                        <Cell header>
                            <div className="ef5-organization-setup__tooltip">
                                <div className="ef5-organization-setup__tooltip-title">
                                    Custom Fields
                                </div>
                                <InfoIconTooltip
                                    tooltipTitle="Custom fields can help you better categorize your ads and campaigns. If your campaigns use a third-party ad server, you may want to create a custom field to keep track of the third party's campaign ID. When you make a custom field required, Ad Groups will be automatically generated using on the value of the custom field in the ad."
                                    position="right"
                                />
                            </div>
                        </Cell>
                        <Cell header className="ef5-organization-setup__data-table-column-2">
                            Required?
                        </Cell>
                        <Cell header className="ef5-organization-setup__data-table-column-3">
                            <SecondaryButton
                                icon={<i className="fa fa-plus" />}
                                text="ADD"
                                onClick={this.addCustomField}
                            />
                        </Cell>
                    </Row>
                    {_.map(this.state.customFields, (customField, i) => (
                        <Row key={i}>
                            <Cell>
                                <FormField showErrors={false}>
                                    <StandardInput
                                        placeholder="Addictive ID"
                                        value={customField.name}
                                        onChange={name =>
                                            this.updateCustomField(customField, { name })
                                        }
                                    />
                                </FormField>
                            </Cell>
                            <Cell className="ef5-organization-setup__data-table-column-2">
                                <FormField showErrors={false}>
                                    <Toggle
                                        status="true"
                                        width={56}
                                        height={25}
                                        position={customField.required ? 'right' : 'left'}
                                        textLeft="no"
                                        textRight="yes"
                                        onClick={() =>
                                            this.updateCustomField(customField, {
                                                required: !customField.required,
                                            })
                                        }
                                    />
                                </FormField>
                            </Cell>
                            <Cell className="ef5-organization-setup__data-table-column-3">
                                <NeutralButton
                                    text="REMOVE"
                                    onClick={() => this.removeCustomField(customField)}
                                />
                            </Cell>
                        </Row>
                    ))}
                </ComposableDataTable>
            </div>
        );
    }
}

export default CustomFieldsSelector;

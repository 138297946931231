import _ from 'lodash';

export default function heatmapSelector(heatmaps, storeState, campaignId) {
    const component = heatmaps[0];
    const reportSession = _.get(storeState, `reportExplore`, {});
    const isLoading = _.get(
        storeState,
        `resources.stats.campaigns.report.explore.${campaignId}.${component.id}.isLoading`,
        false
    );
    const reportDictionary = _.get(storeState, `reportExplore.dictionary`);
    const statsMetricType = _.get(
        storeState,
        `reportExplore.metricSelector.currentSelectedMetricType`,
        'impressions'
    );
    const unitType = _.get(reportSession, `controls.unitType`, 'absolute');

    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`,
        []
    );
    const configDimensions = _.reduce(
        reportSession.dimensionFilter,
        (acc, value, key) => ({
            ...acc,
            [key]: {
                selected: value,
            },
        }),
        {}
    );

    const componentResource = _.get(
        storeState,
        `resources.stats.campaigns.report.explore.${campaignId}.${component.id}`
    );

    const rawData = {
        'hour|day_of_week': componentResource,
    };

    return {
        campaignId,
        component,
        configDimensions,
        rawData,
        statsMetricType,
        reportDictionary,
        isLoading,
        isRelativePercentage: unitType === 'percentage',
        metricComponentsConfig,
    };
}

import _ from 'lodash';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';

import { SortableChipRow } from 'widgets-v5/chip';

import { useReportExplore } from '../../redux';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    chipButton: {
        pointerEvents: 'auto !important',
        cursor: 'pointer !important',
    },
    tooltip: {
        fontSize: '12px',
    },
}));

function DimensionSelectorContainer() {
    const {
        allDimensionOptions,
        hasDimensionsAvailable,
        selectableDimensionOptions,
        selectedDimensions,
        addDimension,
        updateDimensions,
    } = useReportExplore();

    return (
        <Grid container alignItems="center">
            {selectedDimensions.length > 0 && <Box mr={1}>Split on:</Box>}
            <SortableChipRow
                items={allDimensionOptions}
                value={selectedDimensions}
                onChange={updateDimensions}
            />
            {hasDimensionsAvailable && (
                <AddSplitButton options={selectableDimensionOptions} onClick={addDimension} />
            )}
        </Grid>
    );
}

function AddSplitButton(props) {
    const { options, onClick } = props;

    const [popoverAnchor, setAnchorEl] = useState(null);
    const [openItems, setOpen] = useState({});

    const toggleOption = name => {
        setOpen({
            ...openItems,
            [name]: !openItems[name],
        });
    };

    const handleAddSplit = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOption = option => {
        handleClose();
        onClick(option);
    };

    const open = Boolean(popoverAnchor);
    return (
        <React.Fragment>
            <Button onClick={handleAddSplit}>Add Split</Button>
            <ButtonMenu
                anchor={popoverAnchor}
                onClickOption={handleClickOption}
                onClose={handleClose}
                open={open}
                openItems={openItems}
                options={options}
                toggleOption={toggleOption}
            />
        </React.Fragment>
    );
}

function ButtonMenu(props) {
    const { anchor, onClickOption, onClose, open, openItems, options, toggleOption } = props;

    const classes = useStyles();

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >
            <List>
                {_.map(options, option => (
                    <React.Fragment key={option.value}>
                        <Tooltip
                            title={
                                option.invalidBecauseOfDate ? (
                                    <Typography variant="body2">
                                        Data for this dimension is only available for campaigns
                                        starting after April 1, 2023
                                    </Typography>
                                ) : (
                                    ''
                                )
                            }
                            className={classes.chipButton}
                        >
                            <ListItem disableGutters>
                                <ListItemButton
                                    disabled={option.invalidBecauseOfDate}
                                    onClick={() => {
                                        if (
                                            !option.invalidBecauseOfDate &&
                                            !_.size(option.children)
                                        ) {
                                            onClickOption(option);
                                        } else if (!option.invalidBecauseOfDate) {
                                            toggleOption(option.value);
                                        }
                                    }}
                                >
                                    <ListItemText primary={option.label} />
                                    {_.size(option.children) ? (
                                        openItems[option.value] ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )
                                    ) : null}
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                        {_.size(option.children) > 0 && (
                            <Collapse in={openItems[option.value]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {_.map(option.children, child => (
                                        <ListItem key={child.value} disableGutters>
                                            <ListItemButton
                                                onClick={() => onClickOption(child)}
                                                className={classes.nested}
                                            >
                                                <ListItemText primary={child.label} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Popover>
    );
}
export default DimensionSelectorContainer;

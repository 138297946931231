import http from 'utils/http';

const Gis = {
    getPoisByEntity(entityType, entityId) {
        return () => {
            let request;
            switch (entityType) {
                case 'implicitLayer': {
                    request = http().get(`pois/geolayers/${entityId}`);
                    break;
                }
                case 'customLayer': {
                    request = http().get(`pois/geolayers/${entityId}`);
                    break;
                }
                case 'categoryLayer': {
                    request = http().get(`pois/geolayers/${entityId}`);
                    break;
                }
                case 'subCategory': {
                    request = http().get(`pois/geosubcategories/${entityId}`);
                    break;
                }
            }

            return request.then(
                response => {
                    return response;
                },
                err => {
                    console.error(`GET POIS (${entityType}) pois error`, err);

                    throw err;
                }
            );
        };
    },
};

export default Gis;

import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import constants from '../../../../../config';

import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import FormField from 'widgets-v5/form-field';
import { SecondaryButton, NeutralButton } from 'widgets-v5/buttons';
import Card from 'widgets-v5/card';
import CustomizableSelect from 'widgets/form-inputs/customizable-select';
import OptionSelector from 'widgets-v5/option-selector';
import StandardInput from 'widgets-v5/standard-input';
import DataTable from 'widgets-v5/data-table';
import { PanelsLayout, ScrollingSidebarPanel } from 'widgets-v5/panels-layout';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import OverflowLayout from 'widgets-v5/overflow-layout';
import OverviewTabs from 'pages/campaigns/campaigns-overview/overview-tabs';
import selector from './selector';
import { formatNumber_whole } from 'utils/formatting';
import {
    openNewBeaconsView,
    openManageBeaconsView,
    addBeacon,
    editBeacon,
    deleteBeacon,
    saveBeacons,
    cancelEdit,
    initializeBeaconDraft,
    promoteBeacon,
    demoteBeacon,
} from 'pages/campaigns/beacons-overview/actions';

import { canManageBeacons } from 'states/resources/beacons/business-logic';

const CampaignOverviewBeacons = createReactClass({
    displayName: 'CampaignOverviewBeacons',

    statics: {
        refresh(dispatch, nextState) {
            const { campaignId } = nextState.params;

            dispatch(initializeBeaconDraft(campaignId));
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        CampaignOverviewBeacons.refresh(dispatch, nextState, browserHistory.push);
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        if (this.props.params.campaignId !== prevProps.params.campaignId) {
            this.init();
        }
    },

    editBeacon(beaconId, fieldName, value) {
        const { dispatch } = this.props;
        const campaignId = _.get(this, 'props.params.campaignId');

        if (!campaignId) {
            return;
        }

        dispatch(editBeacon(campaignId, beaconId, fieldName, value));
    },

    openNewBeaconsView() {
        const { dispatch, campaignId } = this.props;

        dispatch(openNewBeaconsView(campaignId));
    },

    openManageBeaconsView() {
        const { dispatch, campaignId, draft } = this.props;

        dispatch(openManageBeaconsView(campaignId, draft));
    },

    deleteBeacon(beacon) {
        const {
            dispatch,
            params: { campaignId },
        } = this.props;

        dispatch(deleteBeacon(beacon.id, campaignId));
    },

    handleChange(beacon, fieldName, value) {
        this.editBeacon(beacon.id, fieldName, value);
    },

    handleHideChange(beacon, position) {
        const hideValue = position === 'right';
        this.handleChange(beacon, 'hide', hideValue);
    },

    getBeaconOptions(beacon) {
        const { availableBeaconOptions, rawBeaconOptions } = this.props;

        if (_.find(rawBeaconOptions, { value: beacon.name })) {
            return [{ value: beacon.name, label: beacon.name }].concat(availableBeaconOptions);
        }

        return availableBeaconOptions;
    },

    promote(priority, beacon) {
        const { dispatch } = this.props;

        dispatch(promoteBeacon(beacon, priority));
    },

    demote(priority, beacon) {
        const { dispatch } = this.props;

        dispatch(demoteBeacon(beacon, priority));
    },

    handleAddBeaconClick() {
        const {
            dispatch,
            params: { campaignId },
        } = this.props;

        dispatch(addBeacon(campaignId));
    },

    handleSaveClick() {
        const {
            dispatch,
            params: { campaignId },
        } = this.props;

        dispatch(saveBeacons(campaignId));
    },

    handleCancelClick() {
        const {
            dispatch,
            params: { campaignId },
        } = this.props;

        dispatch(cancelEdit(campaignId));
    },

    generateThirdPartyPixel(beacon) {
        const {
            params: { campaignId },
        } = this.props;

        const baseUri = constants.ENGAGEFRONT_ADSERVER_THIRDPARTY_PIXEL_BASEURI;

        const pixelData = {
            camp_id: campaignId,
            event_name: beacon.name,
        };

        return `${baseUri}${encodeURIComponent(JSON.stringify(pixelData))}`;
    },

    render() {
        const {
            params,
            draft,
            errors,
            showErrors,
            mode,
            isLoading,
            beaconsCount,
            campaign,
        } = this.props;

        const { campaignId } = params;

        const errorsMapped = _(errors)
            .groupBy(e => e.field)
            .reduce(
                (acc, value, key) => ({
                    ...acc,
                    [key]: _.map(value, 'message'),
                }),
                {}
            );

        let manageButton;
        let headers;
        let beaconsColumnClasses;

        const manageButtonEnabled = mode !== 'edit';

        if (canManageBeacons(campaign)) {
            manageButton = (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                        if (manageButtonEnabled) {
                            this.openManageBeaconsView();
                        }
                    }}
                    disabled={!manageButtonEnabled}
                >
                    Manage Engagements
                </Button>
            );
        } else {
            manageButton = null;
        }

        if (mode === 'edit') {
            headers = [
                'Priority',
                'Engagement Name',
                'Reporting Name',
                'Display on Report?',
                'Delete',
            ];
            beaconsColumnClasses = [
                'ef3-campaign-overview-beacons__edit-mode-column-1',
                'ef3-campaign-overview-beacons__edit-mode-column-2',
                'ef3-campaign-overview-beacons__edit-mode-column-3',
                'ef3-campaign-overview-beacons__edit-mode-column-4',
                'ef3-campaign-overview-beacons__edit-mode-column-5',
            ];
        } else {
            headers = [
                'Priority',
                'Engagement Name',
                'Reporting Name',
                'Display on Report?',
                'Copy Static Pixel',
                'Preview Count',
                'Total Count',
            ];
            beaconsColumnClasses = [
                'ef3-campaign-overview-beacons__view-mode-column-1',
                'ef3-campaign-overview-beacons__view-mode-column-2',
                'ef3-campaign-overview-beacons__view-mode-column-3',
                'ef3-campaign-overview-beacons__view-mode-column-4',
                'ef3-campaign-overview-beacons__view-mode-column-5',
                'ef3-campaign-overview-beacons__view-mode-column-6',
                'ef3-campaign-overview-beacons__view-mode-column-7',
            ];

            headers = _.filter(headers, header => header !== 'Copy Static Pixel');
            beaconsColumnClasses = _.slice(
                beaconsColumnClasses,
                0,
                beaconsColumnClasses.length - 1
            );
        }

        return (
            <div className="ef3-campaign-overview-beacons">
                <OverviewTabs tab="beacons" campaignId={campaignId} />
                <div className="ef3-standardToolbar ef3-campaign-overview-beacons__standard-toolbar">
                    {manageButton}
                </div>

                <PanelsLayout
                    className="ef3-campaign-overview-beacons__panels-layout"
                    direction="column"
                >
                    <ScrollingSidebarPanel className="ef3-campaign-overview-beacons__scrolling-side-bar-panel">
                        <BlockLoadGroup isLoading={isLoading}>
                            <Card margin="16" padding={0}>
                                <OverflowLayout responsive="mobile-tablet">
                                    <DataTable
                                        columnClassNames={beaconsColumnClasses}
                                        headers={headers}
                                        rows={_.map(draft, (beacon, index) => {
                                            let show;
                                            let priority = index;

                                            if (beacon.hide) {
                                                show = (
                                                    <span className="ef3-campaign-overview-beacons__hide-indicator">
                                                        <i className="fa fa-eye-slash" />
                                                        &nbsp;
                                                        <span className="showtext">Hide</span>
                                                    </span>
                                                );
                                            } else {
                                                show = (
                                                    <span className="ef3-campaign-overview-beacons__show-indicator">
                                                        <i className="fa fa-eye" />
                                                        &nbsp;
                                                        <span className="showtext">Show</span>
                                                    </span>
                                                );
                                            }

                                            if (mode === 'view') {
                                                return [
                                                    <span key={`view-priority-${index}`}>
                                                        {priority + 1}
                                                    </span>,
                                                    <div key={`view-name-${index}`}>
                                                        {beacon.name}
                                                    </div>,
                                                    <div key={`view-label-${index}`}>
                                                        {beacon.label}
                                                    </div>,
                                                    <div key={`view-show-${index}`}>{show}</div>,
                                                    <span key={`view-previewCount-${index}`}>
                                                        {formatNumber_whole(beacon.previewCount)}
                                                    </span>,
                                                    <span key={`view-totalCount-${index}`}>
                                                        {formatNumber_whole(beacon.totalCount)}
                                                    </span>,
                                                ];
                                            } else {
                                                return [
                                                    <div
                                                        key={`priority-${index}`}
                                                        className="ef3-campaign-overview-beacons__priority"
                                                    >
                                                        <span className="ef3-campaign-overview-beacons__priority-number">
                                                            {priority + 1}
                                                        </span>
                                                        <div>
                                                            <div className="ef3-campaign-overview-beacons__priority-arrow">
                                                                {priority > 0 && (
                                                                    <SecondaryButton
                                                                        onClick={() =>
                                                                            this.promote(
                                                                                priority,
                                                                                beacon
                                                                            )
                                                                        }
                                                                        icon={
                                                                            <i className="fa fa-chevron-up" />
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="ef3-campaign-overview-beacons__priority-arrow">
                                                                {(priority < beaconsCount - 1 ||
                                                                    priority === 0) && (
                                                                    <NeutralButton
                                                                        onClick={() =>
                                                                            this.demote(
                                                                                priority,
                                                                                beacon
                                                                            )
                                                                        }
                                                                        icon={
                                                                            <i className="fa fa-chevron-down" />
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>,
                                                    beacon.type === 'existing' ? (
                                                        beacon.name
                                                    ) : (
                                                        <FormField
                                                            errors={errorsMapped.name}
                                                            showErrors={showErrors}
                                                            isRequired={true}
                                                        >
                                                            <CustomizableSelect
                                                                autofocus={false}
                                                                disabled={false}
                                                                clearable={true}
                                                                searchable={true}
                                                                label="CustomizableSelect"
                                                                value={beacon.name}
                                                                onChange={value =>
                                                                    this.handleChange(
                                                                        beacon,
                                                                        'name',
                                                                        value
                                                                    )
                                                                }
                                                                placeholder="Enter a beacon name"
                                                                options={this.getBeaconOptions(
                                                                    beacon
                                                                )}
                                                                allowCustom={true}
                                                            />
                                                        </FormField>
                                                    ),
                                                    <FormField
                                                        key={errorsMapped.label + index}
                                                        errors={errorsMapped.label}
                                                        showErrors={showErrors}
                                                        isRequired={true}
                                                    >
                                                        <div className="ef3-campaign-overview-beacons__reporting-name-input">
                                                            <StandardInput
                                                                onChange={val =>
                                                                    this.handleChange(
                                                                        beacon,
                                                                        'label',
                                                                        val
                                                                    )
                                                                }
                                                                value={beacon.label}
                                                                placeholder="Reporting Name"
                                                            />
                                                        </div>
                                                    </FormField>,
                                                    <OptionSelector
                                                        key={`option-selector-${index}`}
                                                        className="ef3-campaign-overview-beacons__option-selector"
                                                        onClick={value =>
                                                            this.handleHideChange(beacon, value)
                                                        }
                                                        disableTooltips={true}
                                                        position={beacon.hide ? 'right' : 'left'}
                                                        btnTextLeft="Show" // Sets hide to `false`
                                                        btnTextRight="Hide" // Sets hide to `true`
                                                    />,
                                                    <NeutralButton
                                                        key={`neutral-button-${index}`}
                                                        onClick={() => this.deleteBeacon(beacon)}
                                                        text="DELETE"
                                                    />,
                                                ];
                                            }
                                        })}
                                    />
                                </OverflowLayout>
                            </Card>
                        </BlockLoadGroup>
                    </ScrollingSidebarPanel>
                    {mode === 'edit' && (
                        <Box mt={2}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Button
                                        onClick={this.handleAddBeaconClick}
                                        startIcon={<AddIcon />}
                                    >
                                        Add Beacon
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={this.handleSaveClick}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={this.handleCancelClick}>Cancel</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </PanelsLayout>
            </div>
        );
    },
});

export default connect(selector)(CampaignOverviewBeacons);

import { toastNewUpdates } from 'services/version-poller';
import { firebaseRemoteConfig } from 'services/firebase';

const mw = () => () => next => async action => {
    next(action);

    if (action.type === 'SERVER_PUSH:NEW_UPDATES_RELEASED') {
        firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 0;
        firebaseRemoteConfig.fetch();

        toastNewUpdates();
    }
};

export const makeMiddlewares = deps => {
    return [mw(deps)];
};
export const middlewares = makeMiddlewares({});

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import numeral from 'numeral';

import { getEnvironmentSettings } from 'services/environment';
import selector from './selector';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';

const useStyles = makeStyles(theme => ({
    red: {
        color: '#FF6584',
    },
    labelContainer: {
        textAlign: 'right',
    },
    subSectionContainer: {
        marginTop: theme.spacing(2),
    },
}));

const segments = [
    {
        label: 'Precise Geolocation: Brand',
        value: 'pds_brand',
    },
    {
        label: 'Precise Geolocation: Categories',
        value: 'pds_standard',
    },
    {
        label: 'Environics',
        value: 'pds_environics',
    },
    {
        label: 'Manifold',
        value: 'pds_manifold',
    },
    {
        label: 'Mastercard',
        value: 'pds_mastercard',
    },
    {
        label: 'Behavioural',
        value: 'pds_behaviour',
    },
    {
        label: 'Declared Age',
        value: 'pds_age',
    },
    {
        label: 'Intent',
        value: 'pds_intent',
    },
    {
        label: 'Custom',
        value: 'pds_custom',
    },
    {
        label: 'Retargeting',
        value: 'retargeting',
    },
    {
        label: 'Uploaded',
        value: 'upload',
    },
    {
        label: 'Adobe',
        value: 'adobe',
    },
    {
        label: 'Permutive',
        value: 'permutive',
    },
    {
        label: 'Flex Geolocation',
        value: 'flex_geolocation',
    },
];

const FeatureSets = ({
    draft,
    onToggleSegment,
    onAudienceRateChange,
    onChange,
    errors,
    showErrors,
}) => {
    const classes = useStyles();
    const envSettings = getEnvironmentSettings();

    return (
        <div>
            <Grid container direction="column" spacing={2}>
                {envSettings.canUseAdminFeatureSetsFTA() && (
                    <div>
                        <Typography gutterBottom variant="h6">
                            {' '}
                            Foot Traffic Attribution{' '}
                        </Typography>
                        <Grid item xs={12}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={4}>
                                    <FormLabel required classes={{ asterisk: classes.red }}>
                                        Foot Traffic Attribution
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={5}>
                                    <Switch
                                        color="primary"
                                        checked={draft.ftaEnabled}
                                        onChange={e => onChange('ftaEnabled', e.target.checked)}
                                    />
                                </Grid>
                            </Grid>
                            {draft.ftaEnabled && (
                                <React.Fragment>
                                    <Grid
                                        className={classes.subSectionContainer}
                                        container
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Grid item xs={4}>
                                            <FormLabel required classes={{ asterisk: classes.red }}>
                                                FTA Partner ID
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField
                                                required
                                                fullWidth
                                                value={draft.fta_partner_id}
                                                onChange={e =>
                                                    onChange('fta_partner_id', e.target.value)
                                                }
                                                helperText={showErrors && errors.fta_partner_id}
                                                error={showErrors && errors.fta_partner_id}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item xs={4}>
                                            <FormLabel required classes={{ asterisk: classes.red }}>
                                                FTA Data Cost
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextFieldFormatter
                                                initialValue={draft.fta_fee}
                                                onChange={value => onChange('fta_fee', value)}
                                                shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                                formatIn={v => {
                                                    return numeral(v).format('0,0.00');
                                                }}
                                                formatOut={v => {
                                                    return v ? numeral(v).value() : 0;
                                                }}
                                                renderInput={({ value, onChange }) => (
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        value={value}
                                                        onChange={onChange}
                                                        startAdornment={<div>$</div>}
                                                        endAdornment={<div>CPM</div>}
                                                        helperText={showErrors && errors.fta_fee}
                                                        error={showErrors && errors.fta_fee}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Grid>
                    </div>
                )}
                {envSettings.canUseAdminFeatureSetsIAS() && (
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h6">
                            IAS
                        </Typography>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={4}>
                                <FormLabel>IAS Post-bid Verification</FormLabel>
                            </Grid>
                            <Grid item xs={5}>
                                <Switch
                                    color="primary"
                                    checked={draft.isIasPostBidVerificationEnabled}
                                    onChange={e =>
                                        onChange(
                                            'isIasPostBidVerificationEnabled',
                                            e.target.checked
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                        {' '}
                        Audiences{' '}
                    </Typography>
                    <Grid container direction="row" className={classes.subSectionContainer}>
                        <Grid item xs={4}>
                            <Typography> Audience Name </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="center"> Enabled </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="right"> Data Cost </Typography>
                        </Grid>
                    </Grid>
                    {_.map(segments, segment => {
                        return !segment.showOn || segment.showOn() ? (
                            <Grid container direction="row" alignItems="center" key={segment.value}>
                                <Grid item xs={4}>
                                    <Typography> {segment.label} </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justifyContent="center">
                                        <Switch
                                            color="primary"
                                            checked={
                                                !_.includes(draft.restrictedSegments, segment.value)
                                            }
                                            onChange={e =>
                                                onToggleSegment(segment.value, e.target.checked)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFieldFormatter
                                        initialValue={_.get(
                                            draft.audienceRatesMap,
                                            `${segment.value}.fee`
                                        )}
                                        onChange={value =>
                                            onAudienceRateChange(segment.value, value)
                                        }
                                        shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                        formatIn={v => {
                                            return numeral(v).format('0,0.00');
                                        }}
                                        formatOut={v => {
                                            return v ? numeral(v).value() : 0;
                                        }}
                                        renderInput={({ value, onChange }) => (
                                            <TextField
                                                required
                                                fullWidth
                                                value={value}
                                                onChange={onChange}
                                                startAdornment={<div>$</div>}
                                                endAdornment={<div>CPM</div>}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        ) : null;
                    })}
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(selector)(FeatureSets);

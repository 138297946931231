import _ from 'lodash';
import c from 'common/constants/flux-events';
import CampaignActions from 'states/resources/campaigns/actions';
import BusinessReportActions from 'states/resources/business-reports/actions';
import UserActions from 'states/resources/users/actions';

export function addMediaPlanTab(name, path) {
    return {
        type: c.SIDEBAR__MEDIA_PLANS__ADD_TAB,
        payload: {
            name,
            path,
        },
    };
}

export const addCampaignTabToSidebar = campaignId => {
    return {
        type: c.SIDEBAR__CAMPAIGN_TAB__ADD,
        payload: { campaignId },
    };
};

export const removeCampaignTabFromSidebar = campaignId => {
    return {
        type: c.SIDEBAR__CAMPAIGN_TAB__REMOVE,
        payload: { campaignId },
    };
};

export const removeAllCampaignsTabFromSidebar = () => {
    return {
        type: c.SIDEBAR__CAMPAIGN_TAB__REMOVE_All,
        payload: {},
    };
};
export function closeMediaPlanTab(mediaPlanId) {
    return {
        type: c.MEDIA_PLANS_DASHBOARD__TAB_REMOVE,
        payload: {
            mediaPlanId,
        },
    };
}

export function removeBusinessReportTab(businessReportId) {
    return {
        type: 'SIDEBAR__BUSINESS_REPORT_TAB__REMOVE',
        payload: {
            businessReportId,
        },
    };
}

export const pinCampaignToggled = campaignId => {
    return {
        type: c.SIDEBAR__CAMPAIGN_TAB__PIN_TOGGLED,
        payload: { campaignId },
    };
};

export function logout() {
    return (dispatch, getState) => {
        dispatch({
            type: 'LOGOUT',
            payload: {
                isImpersonating: _.get(getState(), 'profile.isImpersonating'),
            },
        });
    };
}

export const slideToggleSidebar = () => {
    return {
        type: c.SIDEBAR__SLIDE_TOGGLE,
        payload: {},
    };
};

export function closeSidebar() {
    return {
        type: 'SIDEBAR__SLIDE_CLOSE',
        payload: {},
    };
}

export function openSidebar() {
    return {
        type: 'SIDEBAR__SLIDE_OPEN',
        payload: {},
    };
}

export function fetchSidebarCampaigns() {
    return (dispatch, getState) => {
        const selectedCampaignsTabs = _.get(getState(), 'sidebar.selectedCampaigns');

        _.each(selectedCampaignsTabs, tab => {
            dispatch(CampaignActions.get(tab.campaignId)).catch(err => {
                dispatch({
                    type: 'SIDEBAR__CAMPAIGN_FETCH_FAILURE',
                    payload: {
                        campaignId: tab.campaignId,
                    },
                });
            });
        });
    };
}

export function fetchSidebarBusinessReports() {
    return (dispatch, getState) => {
        const businessReportTabs = _.get(getState(), 'sidebar.businessReports');
        _.each(businessReportTabs, tab => {
            dispatch(BusinessReportActions.getOne(tab.businessReportId));
        });
    };
}

export function updateNotificationsLastSeen() {
    return async (dispatch, getState) => {
        const etag = _.get(getState(), `profile._etag`);
        const userId = _.get(getState(), `profile.userId`);
        const existingPreferences = _.get(getState(), `profile.preferences`);

        const payload = {
            preferences: {
                ...existingPreferences,
                notificationsLastSeen: Date.now(),
            },
        };

        const updatedUser = await dispatch(UserActions.update(userId, payload, etag));

        dispatch({
            type: 'PROFILE__USER_PREFERENCES_NOTIFICATIONS_LAST_SEEN__UPDATE',
            payload: {
                user: updatedUser,
            },
        });
    };
}

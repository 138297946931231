import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.pois';

const initialState = {
    pois: [],
    layers: [],
};

export default function pois(state = initialState, action) {
    switch (action.type) {
        case 'SYSTEM__POI_LAYERS__FETCH__SUCCESS': {
            const layers = {};

            _.forEach(
                action.payload.layers,
                layer => (layers[layer._id] = { id: layer._id, attr: { ...layer } })
            );

            return {
                ...state,
                layers,
            };
        }
        default:
            return state;
    }
}

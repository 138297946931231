import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import BaseColumnForm from '../base-column-form';

import { calculateAudienceFee, hasAnyPois } from 'states/resources/ads/business-logic';
import { IncludeAndExcludeSummary } from 'widgets-v5/include-and-exclude';
import Profile from 'states/profile';
import Overlay from 'widgets-v5/overlay';
import AudienceSegmentPicker from 'containers/audience-segment-picker';

import shallowCompare from 'utils/shallow-compare';

export class AudienceForm extends React.Component {
    state = {
        isMenuOpen: false,
        audienceSelectorKey: Date.now(),
    };

    handleOpen = () => {
        this.setState({ isMenuOpen: true });
    };

    handleClose = () => {
        this.setState({ isMenuOpen: false });
    };

    handleOnCopy = () => {
        this.reloadAudienceSelector();
    };

    reloadAudienceSelector = () => {
        this.setState({ audienceSelectorKey: Date.now() });
    };

    render() {
        const { ad, ads, campaign, onSave, isCTVCampaign } = this.props;

        const shouldShowAudienceOverlay = _.some([].concat(ad, ads), ad => hasAnyPois(ad));

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Audiences"
                fields={['audiences', 'audience_exclude', 'audience_fee']}
                shouldRefreshPredictionsOnSave={true}
                onSave={onSave}
                onCancel={onSave}
                onCopyAd={this.handleOnCopy}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <div
                        className={cn('media-plan-form__audience-selector', {
                            'media-plan-form__audience-selector--open': this.state.isMenuOpen,
                        })}
                    >
                        <Overlay
                            disabled
                            message="Audience Segments are not available when POI Segments are selected."
                            show={shouldShowAudienceOverlay}
                        >
                            <Profile>
                                {() => {
                                    return (
                                        <AudienceSegmentPicker
                                            onChange={({ includedItems, excludedItems }) => {
                                                const audienceFee = calculateAudienceFee({
                                                    includedAudiences: includedItems,
                                                    excludedAudiences: excludedItems,
                                                    audienceRates: campaign.audience_rates,
                                                });

                                                updateDraft({
                                                    audiences: _.map(
                                                        includedItems,
                                                        item => item.value
                                                    ),
                                                    audience_exclude: _.map(
                                                        excludedItems,
                                                        item => item.value
                                                    ),
                                                    audience_fee: audienceFee,
                                                });
                                            }}
                                            includedAudiences={_.map(draft.audiences, String)}
                                            excludedAudiences={_.map(
                                                draft.audience_exclude,
                                                String
                                            )}
                                            audienceRates={campaign.audience_rates}
                                            isCTVCampaign={isCTVCampaign}
                                        />
                                    );
                                }}
                            </Profile>
                        </Overlay>
                    </div>
                )}
            </BaseColumnForm>
        );
    }
}

export const AudienceSummary = connect(storeState => {
    const audienceMapping = _.get(storeState, 'mediaPlanForm.audienceMapping', {});

    return {
        audienceMapping,
    };
})(
    class extends React.Component {
        shouldComponentUpdate(nextProps, nextState) {
            const shouldUpdate =
                !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
            return shouldUpdate;
        }

        render() {
            const { ad, error } = this.props;

            return (
                <div>
                    {error && <div>{error}</div>}
                    {!error && audienceMapping && (
                        <IncludeAndExcludeSummary
                            includedItems={_.map(
                                ad.audiences,
                                value => audienceMapping[value]
                            ).filter(x => x)}
                            excludedItems={_.map(
                                ad.audience_exclude,
                                value => audienceMapping[value]
                            ).filter(x => x)}
                        />
                    )}
                </div>
            );
        }
    }
);

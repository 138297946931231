import _ from 'lodash';
import flags from 'containers/flags/service';

export function handleChangeIasGroupmViewability() {
    return {
        description: 'Handle change of IAS GroupM Viewability',
        target(state, action) {
            return 'AD_FORM__IAS_GROUPM_VIEWABILITY__CHANGE' === action.type;
        },
        reduce(state, action) {
            return updateIasGroupmViewability(state, action.payload.value);
        },
    };
}

function updateIasGroupmViewability(state, value) {
    return {
        ...state,
        draft: {
            ...state.draft,
            ias: {
                ...state.draft.ias,
                groupmViewability: {
                    ...state.draft.ias.groupmViewability,
                    value,
                },
            },
        },
    };
}

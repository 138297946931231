import _ from 'lodash';
import { NOTIFICATIONS_LIVE_DATE } from 'common/constants/feature-notifications';

export default function selector(storeState, props) {
    const selectedCampaignsSetting = _.get(storeState, 'sidebar.selectedCampaigns', {});

    const campaignsResource = _.get(storeState, 'resources.campaigns', {});

    const selectedCampaigns = _(selectedCampaignsSetting)
        .sortBy('timestamp')
        .map(campaignSelection => {
            const o = {
                id: campaignSelection.campaignId,
                name: _.get(
                    campaignsResource,
                    `${campaignSelection.campaignId}.attr.name`,
                    '...loading'
                ),
                path: campaignSelection.path,
                isPinned: _.get(
                    selectedCampaignsSetting,
                    `${campaignSelection.campaignId}.isPinned`,
                    void 0
                ),
            };
            return o;
        })
        .filter(x => x)
        .reverse()
        .value();

    const dashboardUrl = _.get(storeState, 'dashboard.filterUrl', '/dashboard');

    const profileState = _.get(storeState, 'profile');
    const impersonatorsOrg = _.get(storeState, 'profile.impersonatorsToken.organization.id');
    const canImpersonate = _.get(storeState, 'profile.canImpersonate', false);
    const isImpersonating = _.get(storeState, 'profile.isImpersonating', false);
    const userEmail = _.get(storeState, 'profile.userEmail');
    const ownOrgId = _.get(storeState, 'profile.organizationId', '');
    const userFirstName = _.get(storeState, 'profile.userFirstName');
    const userLastName = _.get(storeState, 'profile.userLastName');
    const ownOrgType = _.get(storeState, 'profile.ownOrganization.type', null);

    const mediaPlans = buildMediaPlanTabs(storeState, props);
    const businessReports = buildBusinessReportTabs(storeState, props);
    const currentBusinessReportId = _.get(storeState, 'sidebar.currentBusinessReportId');
    const notificationsLastSeen = _.get(storeState, 'profile.preferences.notificationsLastSeen');
    const notificationsSeen = notificationsLastSeen >= NOTIFICATIONS_LIVE_DATE;

    return {
        dashboardUrl,
        selectedCampaigns,
        isImpersonating,
        canImpersonate,
        userEmail,
        userFirstName,
        userLastName,
        mediaPlans,
        ownOrgId,
        businessReports,
        currentBusinessReportId,
        impersonatorsOrg,
        profileState,
        notificationsSeen,
        ownOrgType,
    };
}

function buildMediaPlanTabs(storeState, props) {
    const mediaPlanId = _.get(props, 'params.mediaPlanId');
    const path = _.get(storeState, 'routing.path');
    const mediaPlanIds = _.get(storeState, 'sidebar.mediaPlans', []);
    const mediaPlansResource = _.get(storeState, 'resources.mediaPlans');

    let mediaPlans = _(mediaPlanIds)
        .map(id => _.get(mediaPlansResource, `${id}.attr`))
        .filter(m => m)
        .map(m => ({
            ...m,
            active: m.id === mediaPlanId,
        }))
        .value();

    if (_.includes(mediaPlanIds, 'new')) {
        const newTab = {
            id: 'new',
            readable_id: '',
            name: '(New Media Plan)',
            active: path === '/media-plans/new',
        };
        mediaPlans = [].concat(newTab, mediaPlans);
    }

    return mediaPlans;
}

function buildBusinessReportTabs(storeState) {
    const businessReportsResource = _.get(storeState, 'resources.businessReports');
    const businessReportTabs = _.get(storeState, 'sidebar.businessReports');

    const businessReports = _.map(businessReportTabs, br => ({
        ...br,
        name: _.get(businessReportsResource, `${br.businessReportId}.attr.name`),
    }));

    return businessReports;
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import FlexSegmentsFormActions from 'forms/flex-segments-form/actions';

import actions from './actions';
import selector from './selector';

import FlexCustomLayout from './layout';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.fetchCustomBrands(keywords));
};

const FlexCustomDashboard = ({ dispatch, brands, loading, ...props }) => {
    useEffect(() => {
        dispatch(actions.fetchCustomBrands());
    }, []);

    const openFormModal = selectedFlexBrandId => {
        dispatch(FlexSegmentsFormActions.open(selectedFlexBrandId));
    };

    const onSave = () => {
        dispatch(actions.fetchCustomBrands());
    };

    const onSearch = value => {
        handleSearch(value, dispatch);
    };

    return (
        <FlexCustomLayout
            audienceSegments={brands}
            onSearch={onSearch}
            openFormModal={openFormModal}
            onSave={onSave}
            loading={loading}
            activeAudience={Number(props.params.audienceId)}
            organizationId={props.organizationId}
        />
    );
};

export default connect(selector)(FlexCustomDashboard);

import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from './actions';
import selector from './selector';
import { browserHistory } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadTextContents from 'widgets-v5/download-text-contents';

import { TextField, MuiFormLabel } from 'widgets-v6/standard-input';
import { TableWrapper } from 'widgets-v6/table';
import DragAndDropFileUpload from 'widgets-v5/drag-and-drop-file-upload';
import FileUpload from 'widgets-v5/file-upload';

import { TEMPLATE_CONTENTS, TEMPLATE_FILENAME } from './services/constants';

import { DialogTitle } from 'widgets-v6/modal';

const headers = [
    {
        id: 'location-name',
        name: 'Location Name',
        align: 'left',
    },
    {
        id: 'address',
        name: 'Address',
        align: 'left',
    },
    {
        id: 'city',
        name: 'City',
        align: 'left',
    },
    {
        id: 'postal-code',
        name: 'Postal Code',
        align: 'left',
    },
    {
        id: 'country',
        name: 'Country',
        align: 'left',
    },
    {
        id: 'lat',
        name: 'Lat',
        align: 'left',
    },
    {
        id: 'long',
        name: 'Long',
        align: 'left',
    },
];

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        minWidth: 650,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        padding: `${theme.spacing(2)} 0px`,
    },
    detailsRow: {
        display: 'flex',
        alignItems: 'end',
        '& > td': {
            padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
        },
    },
    detailsCell: {
        width: '200px',
        borderBottom: 'none',
    },
    detailsValue: {
        width: '65%',
        borderBottom: 'none',
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(1),
        '& > div > input': {
            padding: '12.5px 12px',
        },
    },
    helperText: {
        margin: '8px 0',
    },
    arrayHelperText: {
        marginTop: 0,
    },
    paper: {
        padding: theme.spacing(2),
    },
    uploadInput: {
        display: 'none',
    },
    uploadButton: {
        margin: 10,
    },
    dragAndDropText: {
        margin: 10,
    },
    downloadButton: {
        marginBottom: 10,
        marginTop: 5,
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50,
    },
    errors: {
        color: 'red',
    },
}));

const dialogStyles = theme => ({
    root: {
        width: '75%',
        height: '65%',
        minHeight: '400px',
    },
    contentRoot: {
        overflowY: 'hidden',
    },
    container: {
        height: '100%',
    },
    'grid-xs-3': {
        borderRight: `2px solid ${theme.palette.grey[300]}`,
    },
    'grid-xs-9': {
        height: '100%',
        overflowY: 'auto',
    },
});

const FTALocationListsEditor = ({
    dispatch,
    isProcessingLocations,
    locations,
    fileName,
    errors,
    params,
    locationList,
    isLoading,
    draft,
    showErrors,
    showFileTypeError,
    csvErrors,
    failedAssets,
    isSaving,
}) => {
    const classes = useStyles();

    useEffect(() => {
        if (params.id) {
            dispatch(actions.getFtaLocationList(params.id));
        }
    }, [1]);

    const handleOnClose = () => {
        dispatch(actions.closeEditor());
        browserHistory.push('/fta-location-lists');
    };

    const onFileChange = formData => {
        dispatch(actions.processUploadedLocations(formData));
    };

    const handleUpdateName = name => {
        dispatch(actions.updateName(name));
    };

    const handleUpdateComments = comments => {
        dispatch(actions.updateComments(comments));
    };

    const handleSave = () => {
        dispatch(actions.submitFtaLocationList());
    };

    const handleInvalidFileError = files => {
        dispatch(actions.showFileTypeError(files));
    };

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleOnClose}
                fullWidth
                maxWidth="lg"
                PaperProps={{ classes: { root: classes.root } }}
            >
                {!isLoading && !isProcessingLocations && (
                    <DialogTitle onClose={handleOnClose}>
                        {locationList ? 'View Location List' : 'Create Location List'}
                    </DialogTitle>
                )}
                <DialogContent classes={{ root: classes.dialogRoot }}>
                    {isProcessingLocations || isLoading ? (
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <React.Fragment>
                            {locationList ? (
                                <FTALocationListView
                                    locationList={locationList}
                                    isLoading={isLoading}
                                    isProcessingLocations={isProcessingLocations}
                                />
                            ) : (
                                <FTALocationListForm
                                    onFileChange={onFileChange}
                                    isProcessingLocations={isProcessingLocations}
                                    locations={locations}
                                    fileName={fileName}
                                    errors={errors}
                                    isLoading={isLoading}
                                    handleUpdateName={handleUpdateName}
                                    handleUpdateComments={handleUpdateComments}
                                    handleInvalidFileError={handleInvalidFileError}
                                    showFileTypeError={showFileTypeError}
                                    draft={draft}
                                    showErrors={showErrors}
                                    csvErrors={csvErrors}
                                    failedAssets={failedAssets}
                                />
                            )}
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    {!locationList && (
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            color="primary"
                            disabled={isSaving}
                        >
                            Save
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

const FTALocationListForm = ({
    onFileChange,
    isProcessingLocations,
    locations,
    fileName,
    errors,
    handleUpdateName,
    handleUpdateComments,
    handleInvalidFileError,
    draft,
    showErrors,
    failedAssets,
    csvErrors,
    showFileTypeError,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Grid container direction="column">
                        <TextField
                            label="Name"
                            value={draft.name}
                            onChange={e => handleUpdateName(e.target.value)}
                            error={showErrors && _.has(errors, 'name')}
                            required={true}
                        />
                        <TextField
                            label="Comments"
                            value={draft.comments}
                            multiline
                            onChange={e => handleUpdateComments(e.target.value)}
                            placeholder="Please enter details about the locations, campaign ID and campaign start time."
                        />
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid container direction="column">
                        <FormControl margin="normal">
                            <MuiFormLabel>Upload Locations</MuiFormLabel>
                            {!isProcessingLocations && (
                                <DragAndDropFileUpload
                                    accept=".csv, text/csv"
                                    onChange={onFileChange}
                                    onDropRejected={handleInvalidFileError}
                                    showFileTypeError={showFileTypeError}
                                    failedAssets={failedAssets}
                                >
                                    <div className={classes.dragAndDropText}>
                                        <Typography color="textSecondary">
                                            Drag and drop files here to upload
                                        </Typography>
                                        <Typography color="textSecondary">OR</Typography>
                                    </div>
                                    <FileUpload
                                        accept={'.csv'}
                                        onChange={onFileChange}
                                        disabled={isProcessingLocations}
                                        buttonText="Upload File"
                                    />
                                </DragAndDropFileUpload>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {fileName && <Typography>{`Filename: ${fileName}`}</Typography>}
                        </Grid>
                        <Grid item>
                            {csvErrors && (
                                <Typography color="error">
                                    Invalid CSV Format, please refer to template.
                                    {_.map(csvErrors, error => (
                                        <div key={error}>{error}</div>
                                    ))}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            <DownloadTextContents
                                filename={TEMPLATE_FILENAME}
                                contents={TEMPLATE_CONTENTS}
                            >
                                {onDownload => (
                                    <Button
                                        className={classes.downloadButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={onDownload}
                                    >
                                        Download Template
                                    </Button>
                                )}
                            </DownloadTextContents>
                        </Grid>
                    </Grid>
                    {locations.length > 0 && (
                        <TableList
                            headers={headers}
                            rows={locations}
                            isLoading={isProcessingLocations}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

const FTALocationListView = ({ locationList, isProcessingLocations, isLoading }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Grid container direction="column">
                        <TextField label="Name" value={locationList.name} disabled={true} />
                        <TextField
                            label="Comments"
                            value={locationList.comment}
                            multiline
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <TableList
                        headers={headers}
                        rows={locationList.locations}
                        isLoading={isLoading}
                        isProcessingLocations={isProcessingLocations}
                        handleClickDownload={() => {
                            window.location = locationList.raw_location_file_URL;
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};


function TableList({ rows, isLoading, isProcessingLocations, headers, handleClickDownload }) {
    const classes = useStyles();

    return (
        <React.Fragment>
            {rows && (
                <Typography variant="body2" gutterBottom color="textSecondary">{`${rows &&
                    rows.length} Records found`}</Typography>
            )}
            <TableWrapper
                title="All FTA Locations"
                actions={
                    <IconButton onClick={handleClickDownload} size="large">
                        <GetAppIcon />
                    </IconButton>
                }
            >
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            {_.map(headers, ({ id, name, align }) => (
                                <TableCell key={id} align={align}>
                                    {name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {!isLoading && (
                        <TableBody>
                            {_.map(
                                rows,
                                (
                                    { locationName, address, city, postalCode, country, lat, long },
                                    index
                                ) => (
                                    <TableRow key={`${index}_${address}`}>
                                        <TableCell component="th" scope="row">
                                            {locationName}
                                        </TableCell>
                                        <TableCell align="left">{address}</TableCell>
                                        <TableCell align="right">{city}</TableCell>
                                        <TableCell align="left">{postalCode}</TableCell>
                                        <TableCell align="right">{country}</TableCell>
                                        <TableCell align="right">{lat}</TableCell>
                                        <TableCell align="right">{long}</TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    )}
                </Table>
                {isProcessingLocations && (
                    <div className={classes.progress}>
                        <CircularProgress />
                    </div>
                )}
            </TableWrapper>
        </React.Fragment>
    );
}

export default withStyles(dialogStyles)(connect(selector)(FTALocationListsEditor));

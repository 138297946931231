import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import numeral from 'numeral';
import { getAsAbsolute, calcTotalStats } from 'services/resources/stats';
import IAB_CATEGORIES from 'common/constants/iab-categories';
import ProgressCalculator from 'common/progress-calculator';
import getData from '../ad-progress/progress-graph-selector/data-graph';

export default function selector(storeState, props) {
    const { campaignId, adId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const ad = _.get(storeState, `resources.ads.${adId}.attr`);
    const isAdComplete = _.get(storeState, `resources.ads.${adId}.isComplete`, false);
    const isLoading = _.get(storeState, `campaignProgress.isLoading`);
    const previouslyLoaded = _.get(storeState, `campaignProgress.previouslyLoaded`);
    const campaignMetadata = _.get(storeState, 'campaignProgress.campaignMetadata');
    const currentFlight = _.get(storeState, 'campaignProgress.currentFlight');
    const { viewBy } = _.get(storeState, 'campaignPageContainer');

    if (!campaign) {
        return { isLoading, previouslyLoaded };
    }

    const ads = getAdsForCampaign(storeState, campaign);
    const adsWithHealth = getAdsWithHealthAndTarget(storeState, ads, campaign);
    const currencyType = campaign.currency || 'N/A';
    let statsTableData;
    let summary;
    let creativesForAdSummary;
    let dataForOverallGraph;
    let progressData;
    const organizationTimezone = _.get(storeState, 'profile.organizationTimezone');
    const timezoneAtCampaignLevel = _.get(storeState, 'campaignProgress.timezone');

    if (ad) {
        const { start, end, stats } = getDataToInitStatsTable(ad, 'ad', storeState);
        progressData = getData({
            ad,
            stats,
            progressData: ad.metadata.progress ? ad.metadata.progress.progressData : {},
            timezone: timezoneAtCampaignLevel,
        }).progressData;
        const {
            rotation_rules: { scheduled },
        } = ad;

        const scheduledCreatives = _.map(scheduled, schedule => schedule.markup_id);
        if (timezoneAtCampaignLevel) {
            statsTableData = getStatsTableDataBasedOnTimezone(
                stats,
                'Ad Stats',
                currencyType,
                start,
                end,
                campaign,
                timezoneAtCampaignLevel,
                false
            );
        } else {
            statsTableData = getStatsTableData(
                stats,
                'Ad Stats',
                currencyType,
                start,
                end,
                campaign,
                false
            );
        }

        creativesForAdSummary = _.pick(
            storeState.resources.creatives,
            _.uniq([ad.creative].concat(scheduledCreatives))
        );

        const campaignData = getDataToInitStatsTable(campaign, 'campaign', storeState);

        if (timezoneAtCampaignLevel) {
            const { headerItems } = getStatsTableDataBasedOnTimezone(
                campaignData.stats,
                'Overall Stats',
                currencyType,
                campaignData.start,
                campaignData.end,
                campaign,
                timezoneAtCampaignLevel,
                true
            );
            dataForOverallGraph = headerItems;
        } else {
            const { headerItems } = getStatsTableData(
                campaignData.stats,
                'Overall Stats',
                currencyType,
                campaignData.start,
                campaignData.end,
                campaign,
                true
            );
            dataForOverallGraph = headerItems;
        }
    } else {
        const campaignData = getDataToInitStatsTable(campaign, 'campaign', storeState);
        if (timezoneAtCampaignLevel) {
            statsTableData = getStatsTableDataBasedOnTimezone(
                campaignData.stats,
                'Overall Stats',
                currencyType,
                campaignData.start,
                campaignData.end,
                campaign,
                timezoneAtCampaignLevel,
                true
            );
        } else {
            statsTableData = getStatsTableData(
                campaignData.stats,
                'Overall Stats',
                currencyType,
                campaignData.start,
                campaignData.end,
                campaign,
                true
            );
        }

        summary = getSummary(storeState, campaignId, campaign, 'campaign');
        dataForOverallGraph = statsTableData.headerItems;
    }

    let flight = {
        start: campaign.start,
        end: campaign.end,
    };

    if (viewBy && viewBy !== 'campaign' && currentFlight) {
        flight = {
            start: currentFlight.start,
            end: currentFlight.end,
        };
    }

    return {
        campaign,
        campaignId,
        isLoading,
        previouslyLoaded,
        statsTableData,
        dataForOverallGraph,
        summary,
        creativesForAdSummary,
        ads: ad ? (isAdComplete ? sortAdsById(adsWithHealth) : null) : sortAdsById(adsWithHealth),
        flight,
        organizationTimezone,
        timezoneAtCampaignLevel,
        progressData,
        campaignMetadata,
        currentFlight,
    };
}

function getDataToInitStatsTable(source, type, storeState) {
    const stats = _.get(storeState, `resources.stats.${type}.${source.id}.liveByHour`, []);
    return {
        start: source.start,
        end: source.end,
        stats,
    };
}

function getAdsForCampaign(storeState, campaign) {
    const adsResource = _.get(storeState, 'resources.ads', {});
    const ads = _(campaign.ads)
        .map(adId => adsResource[adId])
        .filter(x => x)
        .value();

    return ads;
}

function getAdsWithHealthAndTarget(storeState, ads, campaign) {
    return _.reduce(
        ads,
        (mapping, ad) => {
            const adStatsByHour = _.get(storeState, `resources.stats.ad.${ad.id}.liveByHour`, []);

            let health = _.get(ad, 'attr.metadata.progress.progressData.health');

            const totalStats = calcTotalStats(adStatsByHour);

            const progressCalculator = new ProgressCalculator(ad.attr);
            const goal = progressCalculator.getGoal();
            const maxTotalDelivery = _.get(
                storeState,
                `resources.ads.${ad.id}.attr.max_total_${
                    _.includes(goal, 'spend') ? `${goal}_local` : goal
                }`,
                0
            );
            const totalTarget = progressCalculator.getTotalTarget();
            const hasDailyCap = progressCalculator.hasDailyCap();
            const dailyCap = hasDailyCap ? progressCalculator.getDailyCap() : undefined;

            const isSpendGoalWithDataFees = goal === 'spend' && campaign.data_fees_enabled === true;

            const totalDelivered = isSpendGoalWithDataFees
                ? totalStats.owner_total_media_cost_local
                : totalStats[goal === 'billings' ? 'revenue' : goal];
            const dailyDelivered = getStatsForToday(adStatsByHour, goal, isSpendGoalWithDataFees);

            return {
                ...mapping,
                [ad.id]: {
                    ...ad.attr,
                    health,
                    goal,
                    maxTotalDelivery,
                    totalTarget,
                    dailyCap,
                    totalDelivered, // stats[goal]
                    dailyDelivered,
                },
            };
        },
        {}
    );
}

function getStatsForToday(rawStats, goal, isSpendGoalWithDataFees) {
    const today = moment().format('YYYY-MM-DD');
    return _.reduce(
        rawStats,
        (acc, statObj) => {
            if (today === statObj.date) {
                const value = isSpendGoalWithDataFees
                    ? statObj.owner_total_media_cost_local
                    : statObj[goal];
                return acc + value;
            }
            return acc;
        },
        0
    );
}

function sortAdsById(ads) {
    const adsFormatted = _.sortBy(ads, 'id');
    if (adsFormatted) {
        return adsFormatted.slice().reverse();
    }
    return adsFormatted;
}

function getStatsTableDataBasedOnTimezone(
    stats,
    title,
    currencyType,
    startDate,
    endDate,
    campaign,
    timezone
) {
    const statsBasedOnTimezone = stats.map(statsByHour => {
        const { date, hour, ...restHourlyStats } = statsByHour;
        let formattedHour = '00';
        if (hour !== undefined) {
            formattedHour = hour.toString().length === 1 ? '0' + hour : hour;
        }
        const utcTimestamp = date + 'T' + formattedHour + ':00:00.000Z';
        const newHour = moment(utcTimestamp)
            .tz(timezone)
            .format('k');
        const newDate = moment(utcTimestamp)
            .tz(timezone)
            .format('YYYY-MM-DD');

        return {
            ...restHourlyStats,
            date: newDate,
            hour: newHour,
        };
    });

    const totalStats = calcTotalStats(statsBasedOnTimezone);

    const newEndDate = moment(endDate)
        .tz(timezone)
        .format();
    const newStartDate = moment(startDate)
        .tz(timezone)
        .format('YYYY-MM-DD');

    const formattedEndDate = moment(newEndDate)
        .tz(timezone)
        .isBefore(moment().tz(timezone))
        ? newEndDate
        : moment()
              .tz(timezone)
              .format('YYYY-MM-DDTHH:mm:ss');

    const dateStatFillers = [];
    const range = moment.range(newStartDate, formattedEndDate).by('days');

    Array.from(range).forEach(moment => {
        dateStatFillers.push({
            date: moment.format('YYYY-MM-DD'),
            impressions: 0,
            clicks: 0,
            spend: 0,
            revenue: 0,
        });
    });

    const statsFormatted = _(statsBasedOnTimezone)
        .concat(dateStatFillers)
        .groupBy('date')
        .map((stats, date) => {
            return {
                ...calcTotalStats(stats),
                date,
            };
        })
        .sortBy('date')
        .reverse()
        .filter(statItem => {
            let isZeroRow = true;
            const statAsAbsolute = getAsAbsolute(statItem);

            if (
                statAsAbsolute.impressions > 0 &&
                statAsAbsolute.clicks > 0 &&
                statAsAbsolute.spend > 0
            ) {
                isZeroRow = false;
            }

            if (campaign.data_fees_enabled && statAsAbsolute.owner_total_media_cost_local > 0) {
                isZeroRow = false;
            }

            if (campaign.billing_enabled && statAsAbsolute.revenue > 0) {
                isZeroRow = false;
            }

            if (isZeroRow) {
                return false;
            }
            return true;
        })
        .map(statItem => {
            const statAsAbsolute = getAsAbsolute(statItem);

            const start = [
                { name: 'date', value: statItem.date },
                {
                    name: 'impressions',
                    value: statAsAbsolute.impressions
                        ? numeral(statAsAbsolute.impressions).format('0,0')
                        : '-',
                },
                {
                    name: 'clicks',
                    value: statAsAbsolute.clicks
                        ? numeral(statAsAbsolute.clicks).format('0,0')
                        : '-',
                },
                {
                    name: 'ctr',
                    value: statAsAbsolute.ctr ? numeral(statAsAbsolute.ctr).format('0.00%') : '-',
                },
                {
                    name: 'spend',
                    value: statAsAbsolute.spend
                        ? numeral(statAsAbsolute.spend).format('$0,0.00')
                        : '-',
                },
            ];

            var middle = [];
            if (campaign.data_fees_enabled) {
                middle = {
                    name: 'owner_total_media_cost_local',
                    value: numeral(statAsAbsolute.owner_total_media_cost_local).format('$0,0.00'),
                };
            }

            if (campaign.billing_enabled) {
                middle = {
                    name: 'revenue',
                    value: numeral(statAsAbsolute.revenue).format('$0,0.00'),
                };
            }

            return [].concat(start, middle);
        })
        .value();

    return {
        title,
        headerItems: getStatsTableHeaderItems(totalStats, currencyType, campaign),
        bodyItems: statsFormatted,
    };
}

function getStatsTableData(stats, title, currencyType, startDate, endDate, campaign) {
    const totalStats = calcTotalStats(stats);

    const formattedEndDate = moment.utc(endDate).isBefore(moment.utc())
        ? endDate
        : moment.utc().format('YYYY-MM-DD');

    const dateStatFillers = [];
    const range = moment
        .range(
            moment.utc(startDate).format('YYYY-MM-DD'),
            moment.utc(formattedEndDate).format('YYYY-MM-DD')
        )
        .by('days');

    Array.from(range).forEach(moment => {
        dateStatFillers.push({
            date: moment.format('YYYY-MM-DD'),
            impressions: 0,
            clicks: 0,
            spend: 0,
            revenue: 0,
        });
    });

    const statsFormatted = _(stats)
        .concat(dateStatFillers)
        .groupBy('date')
        .map((stats, date) => {
            return {
                ...calcTotalStats(stats),
                date,
            };
        })
        .sortBy('date')
        .reverse()
        .filter(statItem => {
            let isZeroRow = true;
            const statAsAbsolute = getAsAbsolute(statItem);

            if (
                statAsAbsolute.impressions > 0 &&
                statAsAbsolute.clicks > 0 &&
                statAsAbsolute.spend > 0
            ) {
                isZeroRow = false;
            }

            if (campaign.data_fees_enabled && statAsAbsolute.owner_total_media_cost_local > 0) {
                isZeroRow = false;
            }

            if (campaign.billing_enabled && statAsAbsolute.revenue > 0) {
                isZeroRow = false;
            }

            if (isZeroRow) {
                return false;
            }
            return true;
        })
        .map(statItem => {
            const statAsAbsolute = getAsAbsolute(statItem);

            const start = [
                { name: 'date', value: statItem.date },
                {
                    name: 'impressions',
                    value: statAsAbsolute.impressions
                        ? numeral(statAsAbsolute.impressions).format('0,0')
                        : '-',
                },
                {
                    name: 'clicks',
                    value: statAsAbsolute.clicks
                        ? numeral(statAsAbsolute.clicks).format('0,0')
                        : '-',
                },
                {
                    name: 'ctr',
                    value: statAsAbsolute.ctr ? numeral(statAsAbsolute.ctr).format('0.00%') : '-',
                },
                {
                    name: 'spend',
                    value: statAsAbsolute.spend
                        ? numeral(statAsAbsolute.spend).format('$0,0.00')
                        : '-',
                },
            ];

            var middle = [];
            if (campaign.data_fees_enabled) {
                middle = {
                    name: 'owner_total_media_cost_local',
                    value: numeral(statAsAbsolute.owner_total_media_cost_local).format('$0,0.00'),
                };
            }

            if (campaign.billing_enabled) {
                middle = {
                    name: 'revenue',
                    value: numeral(statAsAbsolute.revenue).format('$0,0.00'),
                };
            }

            return [].concat(start, middle);
        })
        .value();

    return {
        title,
        headerItems: getStatsTableHeaderItems(totalStats, currencyType, campaign),
        bodyItems: statsFormatted,
    };
}

function getStatsTableHeaderItems(totalStats = {}, currencyType, campaign) {
    const currencyLabel = currencyType || 'USD';

    let stats = totalStats;

    const start = [
        { name: 'Date', value: 'Total' },
        { name: 'Impressions', value: numeral(stats.impressions).format('0,0') },
        { name: 'Clicks', value: numeral(stats.clicks).format('0,0') },
        { name: 'CTR', value: stats.ctr ? numeral(stats.ctr).format('0.00%') : '-' },
        {
            name: `Billable Cost (${currencyLabel})`,
            value: numeral(stats.spend).format('$0,0.00'),
        },
    ];

    var middle = [];
    if (campaign.data_fees_enabled) {
        middle = {
            name: `${'Total Cost'} (${currencyLabel})`,
            value: numeral(stats.owner_total_media_cost_local).format('$0,0.00'),
        };
    }
    if (campaign.billing_enabled) {
        middle = {
            name: `Revenue (${currencyLabel})`,
            value: numeral(stats.revenue).format('$0,0.00'),
        };
    }

    const end = [];

    return [].concat(start, middle, end);
}

function getSummary(state, campaignId, item, type) {
    const summaryPopupSession = _.get(state, `campaignProgress.summaryPopup`, {});

    if (!item) {
        return {
            isOpen: false,
            details: {
                title: '',
                secondaryTitle: '',
                callToActions: [],
                groups: [],
            },
        };
    }

    const groups = getGroupsForSummary(item, type);
    const secondaryTitle = `${capitalizeString(type)} Summary`;

    return {
        isOpen: summaryPopupSession.isOpen,
        details: {
            title: item.name,
            secondaryTitle: secondaryTitle,
            callToActions: [],
            groups,
        },
    };
}

function getGroupsForSummary(item) {
    return [
        {
            title: 'Basics',
            items: [
                { name: 'Name', value: item.name },
                { name: 'Advertiser Domain', value: item.advertiser_domain },
                {
                    name: 'Advertiser Category',
                    value: _.map(item.iab_categories, cat => IAB_CATEGORIES[cat]),
                },
            ],
        },
        {
            title: 'Note',
            items: [{ name: 'Note', value: item.notes }],
        },
    ];
}

function capitalizeString(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

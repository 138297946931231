import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import numeral from 'numeral';
import classnames from 'classnames';

import Tabs from 'widgets-v5/tabs';

const MAX_NUM_OF_CHECKED = 12;
const MIN_NUM_OF_CHECKED = 3;

const componentFatory = function(nextProps, statsMetricsType_visibility, parentComponent) {
    const { currency } = nextProps ? nextProps : '';
    const { metricComponentsConfig } = nextProps;

    const lengthOfChecked = _.reduce(
        metricComponentsConfig,
        (acc, v) => {
            const match = statsMetricsType_visibility[v.metricType];
            return acc + match;
        },
        false
    );

    const generatedComponent = function(componentType) {
        let Component;
        let Children;
        let Child;
        switch (componentType) {
            case 'Components_metricType_visibilitySelector': {
                Child = class extends React.Component {
                    render() {
                        const { metricType, style, presentationName } = this.props.setting;
                        const checked = statsMetricsType_visibility[metricType];
                        let disabled = false;
                        if (lengthOfChecked > MAX_NUM_OF_CHECKED - 1 && !checked) {
                            disabled = true;
                        } //
                        if (lengthOfChecked === MIN_NUM_OF_CHECKED && checked) {
                            disabled = true;
                        }
                        return (
                            <label
                                htmlFor={metricType}
                                className={disabled ? style + ' disabled' : style}
                            >
                                <span>
                                    <input
                                        type="checkbox"
                                        id={metricType}
                                        name="visibilitySelectedFor"
                                        value={metricType}
                                        onChange={
                                            parentComponent.handle_metricVisibilitySelectionChange
                                        }
                                        checked={checked}
                                        disabled={disabled}
                                    />
                                </span>
                                <span>
                                    <i className="fa fa-square" />
                                </span>
                                <span>{presentationName} </span>
                            </label>
                        );
                    }
                };

                const standardMetrics = _.filter(
                    metricComponentsConfig,
                    m => m.category === 'basic'
                );
                const beaconMetrics = _.filter(
                    metricComponentsConfig,
                    m => m.category === 'beacon'
                );
                const hasNoBeacons = beaconMetrics.length === 0;
                const LeftMetrics = _.map(standardMetrics, setting => {
                    return React.createElement(Child, {
                        key: setting.metricType,
                        setting,
                    });
                });

                const RightMetrics = _.map(beaconMetrics, setting => {
                    return React.createElement(Child, {
                        key: setting.metricType,
                        setting,
                    });
                });

                Component = class extends React.Component {
                    render() {
                        const isShown = this.props.isShown ? 'isShown' : '';
                        return (
                            <div className={'components-metricType-visibilitySelector ' + isShown}>
                                <div className="components-metricType-visibilitySelector-inner">
                                    <h6>Choose up to {MAX_NUM_OF_CHECKED} Metrics</h6>
                                    <form>
                                        <div className="components-metricType-visibilitySelector-leftPanel">
                                            {LeftMetrics}
                                        </div>
                                        {hasNoBeacons ? null : (
                                            <div className="components-metricType-visibilitySelector-rightPanel">
                                                <h7>Beacons</h7>

                                                <div className="components-metricType-visibilitySelector-rightPanel-form">
                                                    {RightMetrics}
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        );
                    }
                };

                break;
            }

            case 'Components_metricType_selector': {
                Child = class extends React.Component {
                    render() {
                        const {
                            metricType,
                            style,
                            formatType,
                            presentationName,
                            isCurrencyType,
                        } = this.props.setting;
                        const currencyText = currency && isCurrencyType ? `(${currency})` : '';
                        const metricValue = parentComponent.props.totalFilteredStats[metricType];

                        var metricValueFormatted;

                        switch (formatType) {
                            case 'thousands':
                                metricValueFormatted = numeral(metricValue).format('0,0');
                                break;

                            case 'percentage':
                                metricValueFormatted = numeral(metricValue).format('0,0.00%');
                                break;

                            case 'dollar':
                                metricValueFormatted = numeral(metricValue).format('$0,0.00');
                                break;

                            default:
                                metricValueFormatted = metricValue;
                        }

                        const isActive =
                            metricType === parentComponent.props.metricType ? 'is-active' : '';

                        const classes = classnames(
                            'am-reports-metricSelector-btn ' + isActive,
                            `am-reports-metricSelector-btn-${style}`
                        );
                        return (
                            <li className={classes} data-stats-metrics-type={metricType}>
                                <div className="am-reports-metricSelector-btn-inner">
                                    <p className="am-reports-metricSelector-btn-key">
                                        <i className="fa fa-square" />
                                        {`${presentationName} ${currencyText}`}
                                    </p>
                                    <p className="am-reports-metricSelector-btn-value">
                                        {metricValueFormatted}
                                    </p>
                                </div>
                            </li>
                        );
                    }
                };

                const visibleMetrics = _.filter(
                    metricComponentsConfig,
                    m => statsMetricsType_visibility[m.metricType]
                );

                const rows = splitRowsBy6Columns(visibleMetrics);

                const rowComponents = _.map(rows, (rowConfig, j) => {
                    Children = _(rowConfig)
                        .map((setting, i) => {
                            const match = statsMetricsType_visibility[setting.metricType];
                            if (match) {
                                return React.createElement(Child, { key: i, setting });
                            } else {
                                return null;
                            }
                        })
                        .value();

                    return (
                        <ul
                            key={j}
                            className="component-reports-metric-typeSelector-list"
                            onClick={parentComponent.onClick_statsMetricsTypeBtn}
                        >
                            {Children}{' '}
                        </ul>
                    );
                });

                Component = class extends React.Component {
                    render() {
                        return (
                            <div className="component-reports-metric-typeSelector">
                                {rowComponents}
                            </div>
                        );
                    }
                };

                break;
            }
        }
        return Component;
    };
    return { generate: generatedComponent };
};

function splitRowsBy6Columns(list) {
    var rows = [];

    function _createRows(_list) {
        if (_list.length === 0) {
            return;
        }

        rows.push(_list.slice(0, 6));
        _createRows(_list.slice(6));
    }

    _createRows(list);

    return rows;
}

export default class extends React.Component {
    state = { isShown_visibilityControl: false };

    UNSAFE_componentWillMount() {
        const statsMetricsType_visibility = this.props.statsMetricsType_visibility;

        this.Components_metricType_visibilitySelector = componentFatory(
            this.props,
            statsMetricsType_visibility,
            this
        ).generate('Components_metricType_visibilitySelector');
        this.Components_metricType_selector = componentFatory(
            this.props,
            statsMetricsType_visibility,
            this
        ).generate('Components_metricType_selector');

        document.addEventListener('click', this.toggleVisibilityControl);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.toggleVisibilityControl);
    }

    toggleVisibilityControl = e => {
        const isVisibilityControlToggle = $(e.target).closest(
            '.am-report-metrics-visibility-activator-container'
        ).length
            ? true
            : false;
        const isVisibilityControl = $(e.target).closest('.components-metricType-visibilitySelector')
            .length
            ? true
            : false;
        if (isVisibilityControlToggle) {
            this.state.isShown_visibilityControl
                ? this.setState({ isShown_visibilityControl: false })
                : this.setState({ isShown_visibilityControl: true });
        } else if (isVisibilityControl) {
            return;
            // do nothing
        } else {
            this.setState({ isShown_visibilityControl: false });
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const next_statsMetricsType_visibility = nextProps.statsMetricsType_visibility;

        this.Components_metricType_visibilitySelector = componentFatory(
            nextProps,
            next_statsMetricsType_visibility,
            this
        ).generate('Components_metricType_visibilitySelector');
        // }
        this.Components_metricType_selector = componentFatory(
            nextProps,
            next_statsMetricsType_visibility,
            this
        ).generate('Components_metricType_selector');
    }

    handle_metricVisibilitySelectionChange = e => {
        const { metricComponentsConfig } = this.props;
        this.props.onClick_metricVisibilitySelection_haddleToggle(e.target.value);

        const currentStatsMetricsType = this.props.metricType;

        const statsMetricsType_visibility = this.props.statsMetricsType_visibility;

        if (currentStatsMetricsType === e.target.value && e.target.checked === false) {
            // User just un-selected the actived stats metric type, so
            // next (modular) available visible stats type will be the active stats metric type.

            const indexOf_currentStatsMetricsType = _.indexOf(
                metricComponentsConfig,
                _.find(metricComponentsConfig, { metricType: currentStatsMetricsType })
            );
            // console.log('yyy current : ', indexOf_currentStatsMetricsType);

            const metricType_length = metricComponentsConfig.length;
            let nextAvailableVisibleType;
            for (
                let i = (indexOf_currentStatsMetricsType % metricType_length) + 1; // i is the next posible index of item in metricComponentsConfig
                i <= 2 * metricType_length; // upper bound is x 2,  because we need to loop arround if starting from last item
                i++
            ) {
                const indexOf_nextAvailable = i % metricType_length;
                const metricType = metricComponentsConfig[indexOf_nextAvailable].metricType;

                // console.log('yyy: i', i, indexOf_nextAvailable,  metricType,  statsMetricsType_visibility[metricType]);
                if (statsMetricsType_visibility[metricType]) {
                    nextAvailableVisibleType = metricType;
                    break;
                }
            }
            // console.log('yyy next available: ', nextAvailableVisibleStats);

            this.props.onClickMetric(nextAvailableVisibleType);
        } // End if
    };

    onClick_statsMetricsTypeBtn = e => {
        var $target = $(e.target);
        var statsMetricsType = $target
            .closest('[data-stats-metrics-type]')
            .attr('data-stats-metrics-type');

        this.props.onClickMetric(statsMetricsType);
    };

    handleUnitClick = selectedUnit => {
        this.props.onClickUnit(selectedUnit);
    };

    // xxx start here
    render() {
        const { dimensionFilter, scopeFilter, isFilterBarVisible, tab } = this.props;

        return (
            <div className="am-reports-controls">
                {isFilterBarVisible ? (
                    <div className="am-reports-filterBar">
                        {dimensionFilter}
                        {scopeFilter}
                    </div>
                ) : null}
                <div className="am-component-reports-control-container">
                    <Tabs
                        activeItem={tab}
                        items={[
                            { label: 'Pivot Table', value: 'pivot' },
                            { label: 'Dimensions', value: 'dimension' },
                        ]}
                        onChange={v => this.props.onTabClick(v)}
                    />
                </div>
            </div>
        );
    }
}

import toastr from 'toastr';
import _ from 'lodash';

import { actions, REDUCER_KEY } from './redux';
import { request, graphqlRequest } from 'utils/http/redux';
import { getExportErrors, getReportJobPayload } from './selectors';

const toggleSendReportMethodMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.toggleSendReportMethod.type) {
        const reportJobs = _.get(getState(), `${REDUCER_KEY}.reportJobs`);
        const shouldSendViaEmail = _.get(getState(), `${REDUCER_KEY}.shouldSendViaEmail`);
        for (const reportJob of reportJobs) {
            await dispatch(
                updateReportJobRequest({
                    reportJobId: reportJob.id,
                    changes: {
                        shouldSendViaEmail,
                    },
                })
            );
        }
    }
};

const loadReportMw = () => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.loadReportJobs.type) {
        const res = await dispatch(loadReportJobsRequest());
        dispatch(actions.loadReportJobsSuccess(res.data.reportJobs));
    }

    if (action.type === actions.exportReportSuccess.type) {
        dispatch(actions.loadReportJobs());
    }
};

const exportReportMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.exportReport.type) {
        const exportErrors = getExportErrors(getState());

        if (!_.isEmpty(exportErrors)) {
            return;
        }
        const payload = getReportJobPayload(getState(), action.payload);

        await dispatch(createReportJobRequest(payload));
        dispatch(actions.exportReportSuccess());
    }
};

const cancelReportMw = () => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.cancelReport.type) {
        const reportJobId = action.payload.id;

        await dispatch(
            updateReportJobRequest({
                reportJobId,
                changes: {
                    status: 'cancelled',
                },
            })
        );
        dispatch(actions.loadReportJobs());
    }
};

const retryReportMw = () => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.retryReport.type) {
        const reportJobId = action.payload.id;

        await dispatch(
            updateReportJobRequest({
                reportJobId,
                changes: {
                    status: 'pending',
                },
            })
        );
        dispatch(actions.loadReportJobs());
    }
};

const downloadReportMw = () => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.downloadReport.type) {
        const reportJob = action.payload;
        if (reportJob.accessStatus === 'private') {
            const reportJobId = action.payload.id;
            await dispatch(
                updateReportJobRequest({
                    reportJobId,
                    changes: {
                        accessStatus: 'public',
                    },
                })
            );
        }
        const name = _.last(_.split(reportJob.downloadUrl, '/'));
        downloadURI(reportJob.downloadUrl, name);
    }
};

const reportJobEventMw = deps => ({ dispatch, getState }) => next => async action => {
    const { localStorage, downloadURI, loadReportJobs } = deps;
    next(action);

    if (action.type === 'REPORT_JOB:COMPLETED') {
        await loadReportJobs(dispatch);

        const profileUserId = _.get(getState(), 'profile.authToken.v3_impersonator.user');
        const { downloadUrl, userId, reportJobId } = action.payload;
        const downloadedReports = JSON.parse(
            localStorage.getItem('DOWNLOADED_REPORT_JOBS') || '{}'
        );

        const hasReportBeenDownloaded = downloadedReports[reportJobId] !== undefined;
        const didCurrentUserInitiateReportJob = userId === profileUserId;
        const shouldDownloadReport = didCurrentUserInitiateReportJob && !hasReportBeenDownloaded;

        if (shouldDownloadReport) {
            localStorage.setItem(
                'DOWNLOADED_REPORT_JOBS',
                JSON.stringify({
                    ...downloadedReports,
                    [reportJobId]: true,
                })
            );
            const name = _.last(_.split(downloadUrl, '/'));
            downloadURI(downloadUrl, name);
        }
    }
};

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const reportJobGenerateErrorMw = deps => ({ dispatch }) => next => async action => {
    const { loadReportJobs } = deps;
    next(action);

    if (action.type === 'REPORT_JOB:ERROR') {
        const { job } = action.payload;
        await loadReportJobs(dispatch);

        toastr.warning(
            '',
            `<p>Something went wrong with your report "${
                job.reportParameters.name
            }", please try again later. The EngageFront team has been notified</p>`,
            TOASTR_OPTIONS
        );
    }
};

function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = `${uri}?cachebust=${Date.now()}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function createReportJobRequest(reportJob) {
    return request({
        method: 'POST',
        url: '/report-jobs',
        data: reportJob,
    });
}

function updateReportJobRequest({ reportJobId, changes }) {
    return request({
        method: 'PATCH',
        url: `/report-jobs/${reportJobId}`,
        data: changes,
    });
}

function loadReportJobsRequest() {
    return graphqlRequest({
        query: `
            query getReportJobs {
                reportJobs {
                    id
                    status
                    _created
                    downloadUrl
                    isSavedReport
                    accessStatus
                    reportParameters {
                        name
                        timezone
                        dateRange {
                            isEnabled
                           start
                           end
                           last
                           lastFrame
                           type
                           from
                        }
                        campaigns {
                            isEnabled
                            ids
                            mode
                        }
                        adEndDate {
                            isEnabled
                           start
                           end
                           last
                           lastFrame
                           type
                           from
                        }
                        organizationFilter {
                            isEnabled
                            ids
                            mode
                        }
                        dimensions
                        attributes
                        metrics
                        conversions
                        engagements
                        ignore_filter_by_date_range
                        showSpendOverdeliveries
                        reportMode
                    }
                }
            }
        `,
    });
}

export const makeMiddlewares = deps => {
    return [
        loadReportMw(deps),
        exportReportMw(deps),
        cancelReportMw(deps),
        retryReportMw(deps),
        downloadReportMw(deps),
        reportJobEventMw(deps),
        toggleSendReportMethodMw(deps),
        reportJobGenerateErrorMw(deps),
    ];
};

export const middlewares = makeMiddlewares({
    localStorage: window.localStorage,
    downloadURI,
    loadReportJobs: dispatch => {
        return dispatch(actions.loadReportJobs());
    },
});

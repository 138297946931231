import _ from 'lodash';

import { getEnv } from 'services/environment';
import c from 'common/constants/flux-events';
import createPivotTableService from 'widgets/pivot-table/service';
import findCacheState from 'utils/find-cache-state';
import { getDimensions } from 'common/constants/dimensions';
// index by client ot server
function getDimensionsByClientName() {
    const DIMENSIONS_BY_CLIENT_NAME = {};
    _.each(getDimensions(), dimension => {
        DIMENSIONS_BY_CLIENT_NAME[dimension.name] = dimension;
    });

    return DIMENSIONS_BY_CLIENT_NAME;
}

const pt = createPivotTableService();

const initialState = {
    cache: {},
    campaignId: null,
    isInitializing: false,
    isInitialized: false,
    isLoading: true,
    hideZeros: true,
    dictionary: {},

    columns: []
        .concat(
            [
                { name: 'dimension', label: 'Dimensions', defaultValue: '', formatType: '' },

                { name: 'impressions' },
                { name: 'clicks' },
                { name: 'ctr' },
                // metrics for future use
                // { name: 'daily_uniq' },
                // { name: 'average_freq' },
                { name: 'spend' },
                { name: 'ecpm' },
                { name: 'ecpc' },
            ],
            [
                { name: 'billing_rate', label: 'Billing Rate', defaultValue: '', formatType: '' },

                { name: 'revenue' },
                { name: 'erpm' },
                { name: 'erpc' },
                { name: 'owner_total_media_cost_local', disabled: true },
            ]
        )
        .filter(x => x),
    // Pivot Table fields are always
    pivotTable: {
        splits: [{ label: 'Region', name: 'geo_country_region' }],
        dimensions: [
            { label: 'Date', name: 'date' },
            { label: 'Country', name: 'geo_country' },
            { label: 'Region', name: 'geo_country_region' },
            { label: 'City', name: 'geo_city' },
            { label: 'Flex Geolocation Audience', name: 'poi_tag_chosen' },
            { label: 'Ad', name: 'ad_id' },
            { label: 'Platform', name: 'platform' },
        ],
        sort: {
            column: 'dimension',
            order: 'asc',
        },
        tree: {},
    },
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            let _initialState = { ...initialState };
            if (getEnv() === 'production') {
                _initialState = {
                    ..._initialState,
                    columns: _.filter(initialState.columns, c => c.name !== 'billing_rate'),
                };
            }

            return findCacheState(campaignId, state, _initialState);
        }
        case c.REPORT_GEO__PIVOT_TABLE__INITIALIZE_START: {
            const { campaignId, campaign, isClient, isClientReportPage } = action.payload;

            let pivotTableState = { ...initialState.pivotTable };

            if (isClient || isClientReportPage) {
                pivotTableState = removeHiddenDimensions(pivotTableState, campaign);
            }

            if (campaign.isMergedSegmentsEnabled) {
                pivotTableState = removePoiDimension(pivotTableState);
            }

            // Toggle column options
            const columns = _(state.columns)
                // Remove billing fields when Campaign billing is off
                .filter(column => {
                    if (campaign.billing_enabled) {
                        return true;
                    }

                    if (_.includes(['erpm', 'erpc', 'revenue'], column.name)) {
                        return false;
                    } else {
                        return true;
                    }
                })
                .filter(column => {
                    // only apply sharing to clients
                    if (!(isClient || isClientReportPage)) {
                        return true;
                    }

                    const metricConfig = _.find(campaign.sharing_settings.metrics, metric => {
                        if (metric.name === column.name) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (metricConfig) {
                        return metricConfig.shared;
                    } else {
                        return true;
                    }
                })
                .map(column => {
                    switch (column.name) {
                        case 'owner_total_media_cost_local': {
                            const isDisabled =
                                !campaign.data_fees_enabled ||
                                campaign.third_party_fees.length === 0;
                            return {
                                ...column,
                                disabled: isDisabled,
                            };
                        }
                        default: {
                            return column;
                        }
                    }
                })
                .value();

            return {
                ...state,
                columns,
                campaignId,
                isLoading: true,
                isInitializing: true,
                pivotTable: pivotTableState,
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__INITIALIZE: {
            const { stats } = action.payload;

            return {
                ...state,
                pivotTable: pt.generatePivotTableState(state.pivotTable, stats),
                isLoading: false,
                isInitializing: false,
                isInitialized: true,
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__DATA_REFRESH: {
            return {
                ...state,
                pivotTable: pt.generatePivotTableState(state.pivotTable, action.payload.stats),
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__EXPAND_ROW: {
            return {
                ...state,
                pivotTable: pt.expandRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__COLLAPSE_ROW: {
            return {
                ...state,
                pivotTable: pt.collapseRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__ADD_SPLIT: {
            return {
                ...state,
                pivotTable: pt.addSplit(state.pivotTable, action.payload.dimension),
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__REMOVE_SPLIT: {
            return {
                ...state,
                pivotTable: pt.removeSplit(state.pivotTable, action.payload.dimension),
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__UPDATE_SPLITS: {
            return {
                ...state,
                pivotTable: pt.updateSplits(state.pivotTable, action.payload.splits),
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__SORT: {
            return {
                ...state,
                pivotTable: pt.sortColumn(state.pivotTable, action.payload.column),
            };
        }
        case c.REPORT_GEO__PIVOT_TABLE__HIDE_ZEROS_TOGGLE: {
            return {
                ...state,
                hideZeros: !state.hideZeros,
            };
        }
        default:
            return state;
    }
}

function removeHiddenDimensions(pivotTableState, campaign) {
    return {
        ...pivotTableState,
        dimensions: _.filter(pivotTableState.dimensions, dimension => {
            return _.find(campaign.sharing_settings.report_tabs.geo.dimensions, {
                name: getDimensionsByClientName()[dimension.name].serverName,
                shared: true,
            });
        }),
    };
}

function removePoiDimension(pivotTableState) {
    return {
        ...pivotTableState,
        dimensions: _.filter(pivotTableState.dimensions, dimension => {
            if (dimension.name === 'poi_tag_chosen') {
                return false;
            }

            return true;
        }),
    };
}

import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import every from 'lodash/every';
import filter from 'lodash/filter';

import React from 'react';
import { hasTopLevelFieldsBeenModified } from 'widgets/history-table';

import { VENDOR_TO_PRESENTATION_MAPPING } from 'common/constants/vendors';

const AUDIT_LABELS = {
    no_audit: 'Not submitted (Draft)',
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
};

export default function selector(storeState, props) {
    const { creativeId } = props.params;
    const historyData = get(storeState, `resources.history.creatives.${creativeId}`, []);

    const creative = get(storeState, `resources.creatives.${creativeId}.attr`, {});
    const resetCounter = get(storeState, `cretaiveHistory.resetCounter`);

    const organizationTimezone = get(storeState, 'profile.organizationTimezone');

    let fields;
    switch (creative.format) {
        case 'standard':
            fields = getStandardFields(storeState);
            break;
        case 'video':
            fields = getVideoFields(storeState);
            break;
        case 'mraid':
            fields = getMraidFields(storeState);
            break;
        case 'native':
            fields = getNativeFields(storeState);
            break;
        case 'custom_html':
            fields = getCustomHtmlFields(storeState);
            break;
        case 'third_party_vast':
            fields = getThirdPartyVastFields(storeState);
            break;
    }

    const filteredHistoryData = _(historyData)
        .filter((entry, index) => {
            if (index === 0) {
                return true;
            }

            const currenyHistoryValue = JSON.stringify(omit(entry.snapshot, ['_etag']));
            const prevHistoryValue = JSON.stringify(
                omit(historyData[index - 1].snapshot, ['_etag'])
            );

            return currenyHistoryValue !== prevHistoryValue;
        })
        .filter(entry => {
            const allChanges = map(fields, field => {
                return !!field.hasChange(entry);
            });

            const hasNoChanges = every(allChanges, change => change === false);

            return !hasNoChanges;
        })
        .value();

    return {
        historyTable: {
            title: 'Creative History',
            secondaryTitle: creativeId,
            fields: fields,
            historyEntries: filteredHistoryData,
            resetCounter,
        },
        organizationTimezone,
    };
}
function getStandardFields() {
    return [].concat([
        {
            label: 'Name',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
            },
            format(entry) {
                return entry.snapshot.name;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Size',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['size']);
            },
            format(entry) {
                return entry.snapshot.size;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Format',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['format']);
            },
            format(entry) {
                return entry.snapshot.format;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Image Asset',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_asset']);
            },
            format(entry) {
                return entry.snapshot.image_asset;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Image Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_url']);
            },
            format(entry) {
                return entry.snapshot.image_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Image Preview Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_preview_url']);
            },
            format(entry) {
                return entry.snapshot.image_preview_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Pixels',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_pixels']);
            },
            format(entry) {
                return entry.snapshot.third_party_pixels;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Javascript',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_javascript_urls']);
            },
            format(entry) {
                return entry.snapshot.third_party_javascript_urls;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Clickthrough',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_clickthrough']);
            },
            format(entry) {
                return entry.snapshot.third_party_clickthrough.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Clickthrough Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['clickthrough_url']);
            },
            format(entry) {
                return entry.snapshot.clickthrough_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Landing Page',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['landing_page']);
            },
            format(entry) {
                return entry.snapshot.landing_page;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Audit Status',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['audit_status']);
            },
            format(entry) {
                return AUDIT_LABELS[entry.snapshot.audit_status];
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Vendors',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_vendors']);
            },
            format(entry) {
                return map(
                    entry.snapshot.third_party_vendors,
                    v => VENDOR_TO_PRESENTATION_MAPPING[v]
                ).join('\n');
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Has screenshot',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['has_screenshot']);
            },
            format(entry) {
                return entry.snapshot.has_screenshot.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
    ]);
}

function getVideoFields() {
    return [].concat([
        {
            label: 'Name',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
            },
            format(entry) {
                return entry.snapshot.name;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Size',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['size']);
            },
            format(entry) {
                return entry.snapshot.size;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Format',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['format']);
            },
            format(entry) {
                return entry.snapshot.format;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Placements',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['placements']);
            },
            format(entry) {
                return entry.snapshot.placements.join(' | ');;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Positions',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['positions']);
            },
            format(entry) {
                return entry.snapshot.positions.join(' | ');;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Video Asset',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['video_asset']);
            },
            format(entry) {
                return entry.snapshot.video_asset;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Video Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['video_url']);
            },
            format(entry) {
                return entry.snapshot.video_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Video Preview Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['video_preview_url']);
            },
            format(entry) {
                return entry.snapshot.video_preview_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Video Duration',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['video_duration']);
            },
            format(entry) {
                return entry.snapshot.video_duration;
            },
            getClipboardText(entry) {
                return this.format(entry).toString();
            },
        },
        {
            label: 'Image Preview Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_preview_url']);
            },
            format(entry) {
                return entry.snapshot.image_preview_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Pixels',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_pixels']);
            },
            format(entry) {
                return entry.snapshot.third_party_pixels;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Javascript',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_javascript_urls']);
            },
            format(entry) {
                return entry.snapshot.third_party_javascript_urls;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Clickthrough',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_clickthrough']);
            },
            format(entry) {
                return entry.snapshot.third_party_clickthrough.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Clickthrough Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['clickthrough_url']);
            },
            format(entry) {
                return entry.snapshot.clickthrough_url;
            },
            getClipboardText(entry) {
                return this.format(entry).toString();
            },
        },
        {
            label: 'Landing Page',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['landing_page']);
            },
            format(entry) {
                return entry.snapshot.landing_page;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Audit Status',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['audit_status']);
            },
            format(entry) {
                return AUDIT_LABELS[entry.snapshot.audit_status];
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
    ]);
}

function getMraidFields() {
    return [].concat([
        {
            label: 'Name',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
            },
            format(entry) {
                return entry.snapshot.name;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Size',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['size']);
            },
            format(entry) {
                return entry.snapshot.size;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Format',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['format']);
            },
            format(entry) {
                return entry.snapshot.format;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Image Asset',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_asset']);
            },
            format(entry) {
                return entry.snapshot.image_asset;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Image Preview Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_preview_url']);
            },
            format(entry) {
                return entry.snapshot.image_preview_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Content HTML',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['content_html']);
            },
            format(entry) {
                return <div>{entry.snapshot.content_html}</div>;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Pixels',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_pixels']);
            },
            format(entry) {
                return entry.snapshot.third_party_pixels;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Javascript',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_javascript_urls']);
            },
            format(entry) {
                return entry.snapshot.third_party_javascript_urls;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Clickthrough',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_clickthrough']);
            },
            format(entry) {
                return entry.snapshot.third_party_clickthrough.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Clickthrough Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['clickthrough_url']);
            },
            format(entry) {
                return entry.snapshot.clickthrough_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Landing Page',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['landing_page']);
            },
            format(entry) {
                return entry.snapshot.landing_page;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Audit Status',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['audit_status']);
            },
            format(entry) {
                return AUDIT_LABELS[entry.snapshot.audit_status];
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Vendors',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_vendors']);
            },
            format(entry) {
                return map(
                    entry.snapshot.third_party_vendors,
                    v => VENDOR_TO_PRESENTATION_MAPPING[v]
                ).join('\n');
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Has screenshot',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['has_screenshot']);
            },
            format(entry) {
                return entry.snapshot.has_screenshot.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
    ]);
}

function getCustomHtmlFields() {
    return [].concat([
        {
            label: 'Name',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
            },
            format(entry) {
                return entry.snapshot.name;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Size',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['size']);
            },
            format(entry) {
                return entry.snapshot.size;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Format',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['format']);
            },
            format(entry) {
                return entry.snapshot.format;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Image Asset',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_asset']);
            },
            format(entry) {
                return entry.snapshot.image_asset;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Image Preview Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['image_preview_url']);
            },
            format(entry) {
                return entry.snapshot.image_preview_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Content HTML',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['content_html']);
            },
            format(entry) {
                return <div>{entry.snapshot.content_html}</div>;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Pixels',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_pixels']);
            },
            format(entry) {
                return entry.snapshot.third_party_pixels;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Javascript',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_javascript_urls']);
            },
            format(entry) {
                return entry.snapshot.third_party_javascript_urls;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Clickthrough',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_clickthrough']);
            },
            format(entry) {
                return entry.snapshot.third_party_clickthrough.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Clickthrough Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['clickthrough_url']);
            },
            format(entry) {
                return entry.snapshot.clickthrough_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Landing Page',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['landing_page']);
            },
            format(entry) {
                return entry.snapshot.landing_page;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Audit Status',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['audit_status']);
            },
            format(entry) {
                return AUDIT_LABELS[entry.snapshot.audit_status];
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Vendors',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_vendors']);
            },
            format(entry) {
                return map(
                    entry.snapshot.third_party_vendors,
                    v => VENDOR_TO_PRESENTATION_MAPPING[v]
                ).join('\n');
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Has screenshot',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['has_screenshot']);
            },
            format(entry) {
                return entry.snapshot.has_screenshot.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
    ]);
}

function getThirdPartyVastFields() {
    return [].concat([
        {
            label: 'Name',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
            },
            format(entry) {
                return entry.snapshot.name;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Size',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['size']);
            },
            format(entry) {
                return entry.snapshot.size;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Format',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['format']);
            },
            format(entry) {
                return entry.snapshot.format;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },        {
            label: 'Placements',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['placements']);
            },
            format(entry) {
                return entry.snapshot.placements.join(' | ');
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Positions',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['positions']);
            },
            format(entry) {
                return entry.snapshot.positions.join(' | ');
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Video Duration',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['video_duration']);
            },
            format(entry) {
                return entry.snapshot.video_duration;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Pixels',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_pixels']);
            },
            format(entry) {
                return entry.snapshot.third_party_pixels;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Javascript',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_javascript_urls']);
            },
            format(entry) {
                return entry.snapshot.third_party_javascript_urls;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Landing Page',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['landing_page']);
            },
            format(entry) {
                return entry.snapshot.landing_page;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Audit Status',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['audit_status']);
            },
            format(entry) {
                return AUDIT_LABELS[entry.snapshot.audit_status];
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Vendors',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_vendors']);
            },
            format(entry) {
                return map(
                    entry.snapshot.third_party_vendors,
                    v => VENDOR_TO_PRESENTATION_MAPPING[v]
                ).join('\n');
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Has screenshot',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['has_screenshot']);
            },
            format(entry) {
                return entry.snapshot.has_screenshot.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
    ]);
}

function getNativeFields() {
    const nativeFields = [].concat([
        {
            label: 'Name',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
            },
            format(entry) {
                return entry.snapshot.name;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Size',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['size']);
            },
            format(entry) {
                return entry.snapshot.size;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Format',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['format']);
            },
            format(entry) {
                return entry.snapshot.format;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Pixels',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_pixels']);
            },
            format(entry) {
                return entry.snapshot.third_party_pixels;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Javascript',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_javascript_urls']);
            },
            format(entry) {
                return entry.snapshot.third_party_javascript_urls;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Icon Image Asset',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['icon_image_asset']);
            },
            format(entry) {
                return entry.snapshot.icon_image_asset;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Icon Image Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['icon_image_url']);
            },
            format(entry) {
                return entry.snapshot.icon_image_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Icon Image Preview Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['icon_image_preview_url']);
            },
            format(entry) {
                return entry.snapshot.icon_image_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Main Image Asset',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['main_image_asset']);
            },
            format(entry) {
                return entry.snapshot.main_image_asset;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Main Image Preview Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['main_image_preview_url']);
            },
            format(entry) {
                return entry.snapshot.main_image_preview_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Title',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['title']);
            },
            format(entry) {
                return entry.snapshot.title;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Text',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['text']);
            },
            format(entry) {
                return entry.snapshot.text;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Sponsored By',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['sponsoredBy']);
            },
            format(entry) {
                return entry.snapshot.sponsoredBy;
            },
            getClipboardSponsoredBy(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Call To Action',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['call_to_action']);
            },
            format(entry) {
                return entry.snapshot.call_to_action;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Star Rating',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['star_rating']);
            },
            format(entry) {
                return entry.snapshot.star_rating;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Third Party Clickthrough',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_clickthrough']);
            },
            format(entry) {
                return entry.snapshot.third_party_clickthrough.toString();
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Clickthrough Url',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['clickthrough_url']);
            },
            format(entry) {
                return entry.snapshot.clickthrough_url;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Landing Page',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['landing_page']);
            },
            format(entry) {
                return entry.snapshot.landing_page;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
        {
            label: 'Audit Status',
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, ['audit_status']);
            },
            format(entry) {
                return AUDIT_LABELS[entry.snapshot.audit_status];
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        },
    ]);
    return filter(nativeFields, isEmpty);
}

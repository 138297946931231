import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    isInitialized: false,
    isInitializing: true,
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.CAMPAIGN_REPORT__INITIALIZE: {
            let nextState = { ...state };

            nextState = completeInitialization(nextState);

            return nextState;
        }
        default:
            return state;
    }
}

function completeInitialization(state) {
    return {
        ...state,
        isInitialized: true,
        isInitializing: false,
    };
}

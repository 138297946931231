import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { DescriptionText } from 'widgets-v5/typography';

class GeoFencingLayerFinder extends React.Component {
    static propTypes = {
        includedCustomLayers: PropTypes.array,
        includedCategories: PropTypes.array,
        includedCategoryLayers: PropTypes.array,
    };

    static defaultProps = {
        includedCustomLayers: [],
        includedCategories: [],
        includedCategoryLayers: [],
        categories: [],
    };

    state = {
        isCollapsed_customLayer: true,
        isHidden_customLayer: false,
    };

    handleToggle_isCollapsed_customLayer = () => {
        this.props.onGeoLayerFinder_folderStateChanged({ toggledNodeId: 'customLayers' });
    };

    includeLayer = id => {
        this.props.selectGeoLayer(id);
    };

    includeCategory = (id, tag) => {
        this.props.selectGeoCategory(id, tag);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const customLayers = _.get(nextProps, 'layers', []);

        if (customLayers.isOpen === true) {
            this.setState({ isCollapsed_customLayer: false });
        } else if (customLayers.isOpen === false) {
            this.setState({ isCollapsed_customLayer: true });
        }

        this.setState({ isHidden_customLayer: customLayers.isHidden });
    }

    createLayerDone = layerId => {
        this.setState({ isCollapsed_customLayer: false });
        this.props.selectGeoLayer(layerId);
    };

    render() {
        return (
            <div className="ef3-geoFencingLayerFinder">
                <div className="custom-layer">
                    {this.state.isHidden_customLayer ? null : (
                        <div>
                            <div
                                className={`
                                    tree-head
                                    ef3-geoFencingLayerFinder_fileTree_category_head
                                    ef3-geoFencingLayerFinder_fileTree_category_head__type-category
                                `}
                                onClick={this.handleToggle_isCollapsed_customLayer}
                            >
                                <span className="left">
                                    <span className="fileTreeCaret">
                                        {this.state.isCollapsed_customLayer ? (
                                            <i className="fa fa-fw fa-caret-right" />
                                        ) : (
                                            <i className="fa fa-fw fa-caret-down" />
                                        )}
                                    </span>
                                </span>
                                <span className="right">
                                    {this.state.isCollapsed_customLayer ? (
                                        <i className="fa fa-fw fa-folder" />
                                    ) : (
                                        <i className="fa fa-fw fa-folder-open" />
                                    )}
                                    <span className="name">Custom POI Segments</span>
                                </span>
                            </div>
                            <div className="tree">
                                {this.state.isCollapsed_customLayer ? null : (
                                    <FileTree
                                        editLayer={this.props.editLayer}
                                        allowEditLayers={!this.props.geoLayerSearchString}
                                        flat={true}
                                        includedCustomLayers={this.props.includedCustomLayers}
                                        includeLayer={this.includeLayer}
                                        categories={this.props.layers}
                                        onGeoLayerFinder_folderStateChanged={
                                            this.props.onGeoLayerFinder_folderStateChanged
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    <DescriptionText
                        type="info"
                        className={classnames('ef3-geoFencingLayerFinder_createLayer', {
                            'ef3-geoFencingLayerFinder_createLayer__finderIsFiltered': !!this.props
                                .geoLayerSearchString,
                        })}
                    >
                        You can now create Custom POI Segments using 'POI&nbsp;Segments' in the
                        sidebar
                    </DescriptionText>
                </div>
                <hr
                    className={classnames('ef3-geoFencingLayerFinder_sectionSeparator', {
                        'ef3-geoFencingLayerFinder_sectionSeparator__finderIsFiltered': !!this.props
                            .geoLayerSearchString,
                    })}
                />
                <div className="preset-layers">
                    <h4
                        className={classnames('ef3-geoFencingLayerFinder_sectionHeader', {
                            'ef3-geoFencingLayerFinder_sectionHeader__finderIsFiltered': !!this
                                .props.geoLayerSearchString,
                        })}
                    >
                        Standard POI Segments
                    </h4>
                    <FileTree
                        editLayer={this.props.editLayer}
                        includedCategories={this.props.includedCategories}
                        includedCategoryLayers={this.props.includedCategoryLayers}
                        includeLayer={this.includeCategory}
                        categories={this.props.categories}
                        onGeoLayerFinder_folderStateChanged={
                            this.props.onGeoLayerFinder_folderStateChanged
                        }
                    />
                </div>
            </div>
        );
    }
}

class FileTree extends React.Component {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        includeLayer: PropTypes.func.isRequired,
        includedCategories: PropTypes.array,
        includedCategoryLayers: PropTypes.array,
        includedCustomLayers: PropTypes.array,
    };

    static defaultProps = {
        includedCategories: [],
        includedCategoryLayers: [],
        includedCustomLayers: [],
    };

    constructor(props) {
        super(props);
        const { categories } = props;
        const mappedIdsAsNestedObject = createIdsAsNestedObject(categories);
        const subcategoryOrLayerToCategoryLookup = createSubcategoryOrLayerToCategoryLookup(
            categories
        );
        const openedFolders = createOpenFoldersLookup(categories);

        this.state = {
            openedFolders,
            mappedIdsAsNestedObject,
            subcategoryOrLayerToCategoryLookup,
        };
    }

    UNSAFE_componentWillMount() {
        this._cached = {};
    }

    componentDidMount() {
        this._cached = {
            openedFolders_pre: this.state.openedFolders,
        };
    }

    openFolderById = id => {
        const openedFolders_pre = _.get(
            this._cached,
            'openedFolders_pre',
            this.state.openedFolders
        );
        const openedFoldersWithHiddenCategory = {
            ...openedFolders_pre,
            [id]: false,
        };
        this._cached = {
            openedFolders_pre: openedFoldersWithHiddenCategory,
        };
    };

    closeFolderById = id => {
        const openedFolders_pre = _.get(
            this._cached,
            'openedFolders_pre',
            this.state.openedFolders
        );
        const openedFoldersWithHiddenCategory = {
            ...openedFolders_pre,
            [id]: true,
        };
        this._cached = {
            openedFolders_pre: openedFoldersWithHiddenCategory,
        };
    };

    actionOpenFolderBy = id => {
        // open foler action added but never use.
        // I wll not delete it, becasue it could be useful in the future
        this.props.onGeoLayerFinder_folderStateChanged({ openFolderId: id });
    };

    toggleFolder = id => {
        this.props.onGeoLayerFinder_folderStateChanged({ toggledNodeId: id });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const categories = nextProps.categories;

        const categories_without_implicit = _.filter(categories, itm => {
            return itm.name !== '___implicit';
        });

        const openedFolders = createOpenFoldersLookup(categories_without_implicit);
        const openedFolders_pre = _.get(this._cached, 'openedFolders_pre', {});
        const openedFolders_pre_filtered = _.pick(openedFolders_pre, Object.keys(openedFolders));
        this._cached.openedFolders_pre = openedFolders_pre_filtered;

        _.map(categories_without_implicit, category => {
            if (category.isOpen === true) {
                this.openFolderById(category.id);
            } else if (category.isOpen === void 0) {
                this.closeFolderById(category.id);
            }
            _.map(category.subcategories, subcat => {
                if (subcat.isOpen === true) {
                    this.openFolderById(subcat.id);
                } else if (subcat.isOpen === void 0) {
                    this.closeFolderById(subcat.id);
                }
            });
        });

        this.setState({ openedFolders: _.get(this._cached, 'openedFolders_pre', {}) });
    }

    render() {
        const {
            categories,
            includeLayer,
            includedCategories,
            includedCategoryLayers,
            includedCustomLayers,
            flat,
        } = this.props;

        const CommonDataProps = {
            includedCategories,
            includedCategoryLayers,
            mappedIdsAsNestedObject: this.state.mappedIdsAsNestedObject,
            subcategoryOrLayerToCategoryLookup: this.state.subcategoryOrLayerToCategoryLookup,
            openedFolders: this.state.openedFolders,
        };

        const categories_without_implicit = _.filter(categories, itm => {
            return itm.name !== '___implicit';
        }).reverse(); // date order descending

        return (
            <div className="ef3-geoFencingLayerFinder_fileTree">
                {_.map(categories_without_implicit, (category, index) => {
                    return (
                        <ol
                            key={'cat' + category.id + index}
                            className={classnames('ef3-geoFencingLayerFinder_fileTree_category', {
                                'is-closed': this.state.openedFolders[category.id],
                            })}
                        >
                            {flat ? (
                                <FileTreeLayer
                                    editLayer={this.props.editLayer}
                                    allowEditLayer={this.props.allowEditLayers}
                                    key={category.id}
                                    layer={category}
                                    categoryId={'categoryId'}
                                    subCategoryId={'not-subCategory'}
                                    includeLayer={() => includeLayer(category.id)}
                                    includedCustomLayers={includedCustomLayers}
                                    {...CommonDataProps}
                                />
                            ) : (
                                <FileTreeFolder
                                    id={category.id}
                                    name={category.name}
                                    type="category"
                                    includeLayer={() => {}}
                                    toggleFolder={() => this.toggleFolder(category.id)}
                                    actionOpenFolderBy={id => this.actionOpenFolderBy(id)}
                                    {...CommonDataProps}
                                />
                            )}
                            {this.state.openedFolders[category.id] ||
                                _.map(category.subcategories, (subcat, subcatIndex) => {
                                    const parentIsChecked =
                                        _.indexOf(includedCategories, category.id) !== -1;
                                    const isClosed = this.state.openedFolders[subcat.id];

                                    return (
                                        <ol
                                            key={'subcat' + subcat.id + subcatIndex}
                                            className={classnames(
                                                'ef3-geoFencingLayerFinder_fileTree_subCategory',
                                                {
                                                    'is-closed': isClosed,
                                                    'is-disabled': parentIsChecked,
                                                }
                                            )}
                                        >
                                            <FileTreeFolder
                                                id={subcat.id}
                                                name={subcat.name}
                                                type="sub"
                                                categoryId={category.id}
                                                includeLayer={() => {
                                                    if (parentIsChecked) return;
                                                    includeLayer(subcat.id);
                                                }}
                                                toggleFolder={() => this.toggleFolder(subcat.id)}
                                                actionOpenFolderBy={id =>
                                                    this.actionOpenFolderBy(id)
                                                }
                                                {...CommonDataProps}
                                            />

                                            {_.map(subcat.layers, layer => {
                                                return (
                                                    <FileTreeLayer
                                                        editLayer={this.props.editLayer}
                                                        key={layer.id}
                                                        layer={layer}
                                                        categoryId={category.id}
                                                        subCategoryId={subcat.id}
                                                        includeLayer={() =>
                                                            includeLayer(layer.id, 'sub')
                                                        }
                                                        {...CommonDataProps}
                                                    />
                                                );
                                            })}
                                        </ol>
                                    );
                                })}
                        </ol>
                    );
                })}
            </div>
        );
    }
}

class FileTreeCaret extends React.Component {
    static propTypes = {
        toggleFolder: PropTypes.func.isRequired,
        openedFolders: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
    };

    render() {
        const { toggleFolder, openedFolders, id } = this.props;
        return (
            <span
                className="fileTreeCaret"
                onClick={e => {
                    e.stopPropagation();
                    toggleFolder();
                }}
            >
                {openedFolders[id] ? (
                    <i className="fa fa-fw fa-caret-right" />
                ) : (
                    <i className="fa fa-fw fa-caret-down" />
                )}
            </span>
        );
    }
}

class FileTreeLayer extends React.Component {
    static propTypes = {
        layer: PropTypes.object.isRequired,
        includeLayer: PropTypes.func.isRequired,
        categoryId: PropTypes.string.isRequired,
        subCategoryId: PropTypes.string.isRequired,
        includedCategories: PropTypes.array.isRequired,
        includedCategoryLayers: PropTypes.array.isRequired,
        mappedIdsAsNestedObject: PropTypes.object.isRequired,
    };

    checkLayer = (parentCategoryIsChecked, parentSubCategoryIsChecked, id) => {
        if (this.props.categoryId === 'categoryId') {
            this.props.includeLayer(id, 'layer');
            return;
        }

        if (parentCategoryIsChecked || parentSubCategoryIsChecked) {
            return;
        }

        this.props.includeLayer(id, 'layer');
    };

    handle_editLayer = layerId => {
        this.props.editLayer(layerId);
    };

    render() {
        const {
            layer,
            categoryId,
            subCategoryId,
            includedCategories,
            includedCategoryLayers,
            includedCustomLayers,
            mappedIdsAsNestedObject,
        } = this.props;

        const { id, name } = layer;

        if (_.includes(name, '___implicit')) {
            return <span data-info="__implicit" />;
        }

        const parentCatIsChecked = _.includes(includedCategories, categoryId);
        const parentSubCatIsChecked = _.includes(includedCategories, subCategoryId);

        let idForCheckbox = id;
        if (parentCatIsChecked) {
            idForCheckbox = categoryId;
        } else {
            if (parentSubCatIsChecked) {
                idForCheckbox = subCategoryId;
            }
        }

        const isDisabled = parentCatIsChecked || parentSubCatIsChecked;

        return (
            <li
                className={classnames('ef3-geoFencingLayerFinder_fileTree_layer', {
                    'is-disabled': isDisabled,
                })}
                onClick={() => {
                    this.checkLayer(parentCatIsChecked, parentSubCatIsChecked, id);
                }}
            >
                <div className="layer" title={name}>
                    <FileCheckbox
                        id={idForCheckbox}
                        type="category"
                        mappedIdsAsNestedObject={mappedIdsAsNestedObject}
                        includedCategories={includedCategories}
                        includedCategoryLayers={includedCategoryLayers}
                        includedCustomLayers={includedCustomLayers}
                    />
                    <i className="fa fa-fw fa-clone" />
                    <span className="name">{name}</span>
                </div>
            </li>
        );
    }
}

class FileCheckbox extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['category', 'sub']).isRequired,
        mappedIdsAsNestedObject: PropTypes.object.isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        includedCategories: [],
        includedCategoryLayers: [],
        includedCustomLayers: [],
    };

    render() {
        const {
            includedCategories,
            includedCategoryLayers,
            includedCustomLayers,
            id,
            type,
            mappedIdsAsNestedObject,
            onClick,
        } = this.props;

        const isChecked =
            _.indexOf(
                [].concat(includedCategories, includedCategoryLayers, includedCustomLayers),
                id
            ) !== -1;
        if (type && isChecked) {
            return (
                <span className="checkbox" onClick={onClick}>
                    <i className={classnames('fa fa-fw', 'fa-check-square-o')} />
                </span>
            );
        }

        let hasCheckedChild = false;
        switch (type) {
            case 'sub':
                {
                    const layersInSubCategory = _.reduce(
                        mappedIdsAsNestedObject,
                        (acc, category) => {
                            return category[id] ? category[id] : acc;
                        },
                        []
                    );

                    if (hasCheckedLayer(layersInSubCategory, includedCategoryLayers)) {
                        hasCheckedChild = true;
                    }
                }
                break;
        }

        return (
            <span className="checkbox">
                {hasCheckedChild ? (
                    <i className={classnames('fa fa-fw', 'fa-square')} />
                ) : (
                    <i
                        className={classnames('fa fa-fw', {
                            'fa-square-o': !isChecked,
                            'fa-check-square-o': isChecked,
                        })}
                    />
                )}
            </span>
        );
    }
}

class FileTreeFolder extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['category', 'sub']).isRequired,
        categoryId: PropTypes.string,
        includeLayer: PropTypes.func.isRequired,
        mappedIdsAsNestedObject: PropTypes.object.isRequired,
        openedFolders: PropTypes.object.isRequired,
        toggleFolder: PropTypes.func.isRequired,
    };

    didClickInCheckbox = e => {
        return $(e.target).closest('.checkbox').length === 1;
    };

    render() {
        const {
            id,
            name,
            type,
            includedCategories,
            includedCategoryLayers,
            includeLayer,
            mappedIdsAsNestedObject,
            openedFolders,
            toggleFolder,
            subcategoryOrLayerToCategoryLookup,
        } = this.props;

        let idForCheckbox = id;

        if (type === 'sub') {
            const parentIsChecked = _.indexOf(includedCategories, this.props.categoryId) !== -1;
            idForCheckbox = parentIsChecked ? this.props.categoryId : id;
        }

        const subcategoryAndLayerSelections = [].concat(includedCategories, includedCategoryLayers);

        const highlightCategory =
            type === 'category'
                ? _.some(
                      subcategoryAndLayerSelections,
                      selection => subcategoryOrLayerToCategoryLookup[selection] === id
                  )
                : false;

        return (
            <li
                className={classnames(
                    'ef3-geoFencingLayerFinder_fileTree_category_head',
                    `ef3-geoFencingLayerFinder_fileTree_category_head__type-${type}`
                )}
                onClick={e => {
                    return this.didClickInCheckbox(e) ? includeLayer() : toggleFolder();
                }}
            >
                <span className="left">
                    <FileTreeCaret
                        toggleFolder={toggleFolder}
                        openedFolders={openedFolders}
                        id={id}
                    />
                </span>
                <span className="right" title={name}>
                    {// show a checkbox when not a major category
                    type !== 'category' ? (
                        <FileCheckbox
                            id={idForCheckbox}
                            type={type}
                            onClick={includeLayer}
                            mappedIdsAsNestedObject={mappedIdsAsNestedObject}
                            includedCategories={includedCategories}
                            includedCategoryLayers={includedCategoryLayers}
                        />
                    ) : null}
                    <span
                        className={classnames('ef3-geoFencingLayerFinder_fileTree_majorCategory', {
                            isActive: highlightCategory,
                        })}
                    >
                        <span className="ef3-geoFencingLayerFinder_itemTypeIcon">
                            {openedFolders[id] ? (
                                <i className="fa fa-fw fa-folder" />
                            ) : (
                                <i className="fa fa-fw fa-folder-open" />
                            )}
                        </span>
                        {name}
                    </span>
                </span>
            </li>
        );
    }
}

function hasCheckedLayer(layers, includedIds) {
    return _.reduce(
        layers,
        (result, layer) => {
            return _.indexOf(includedIds, layer) !== -1 ? true : result;
        },
        false
    );
}

function createSubcategoryOrLayerToCategoryLookup(categories) {
    const table = {};

    _.each(categories, category => {
        _.each(category.subcategories, subcategory => {
            _.each(subcategory.layers, layer => {
                table[layer.id] = category.id;
            });
            table[subcategory.id] = category.id;
        });
    });

    return table;
}

function createIdsAsNestedObject(categories) {
    return _.reduce(
        categories,
        (acc, category) => {
            const subcategoryMapping = _.reduce(
                category.subcategories,
                (mapping, sub) => {
                    return {
                        ...mapping,
                        [sub.id]: _.reduce(
                            sub.layers,
                            (arr, layer) => {
                                return [...arr, layer.id];
                            },
                            []
                        ),
                    };
                },
                {}
            );

            return {
                ...acc,
                [category.id]: subcategoryMapping,
            };
        },
        {}
    );
}

function createOpenFoldersLookup(categories) {
    const table = {};

    _.each(categories, category => {
        _.each(category.subcategories, subcategory => {
            table[subcategory.id] = true;
        });
        table[category.id] = true;
    });

    return table;
}

export default GeoFencingLayerFinder;

import _ from 'lodash';

export const NAME = 'adminV2.childOrganization';

const initialState = {
    currentOrganization: 'all',
    organizationsForList: [],
    filteredOrganizationsList: [],
    users: [],
    filteredUsers: [],
    isPageLoading: true,
    isSideBarLoading: false,
    isTableLoading: false,
    ownOrganizationType: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
        case 'ADMIN_V2__FETCH_CHILD_ORGANIZATION_START': {
            return {
                ...state,
                isTableLoading: true,
            };
        }
        case 'ADMIN_V2__FETCH_CHILD_ORGANIZATION_END': {
            return {
                ...state,
                isTableLoading: false,
                currentOrganization: action.payload.data.organization,
                users: action.payload.data.organization.users,
                filteredUsers: action.payload.data.organization.users,
            };
        }
        case 'ADMIN_V2__FITER_CHILD_USERS_START': {
            return {
                ...state,
                isTableLoading: true,
            };
        }
        case 'ADMIN_V2__FITER_CHILD_USERS_END': {
            return {
                ...state,
                isTableLoading: false,
                filteredUsers: action.payload.data,
            };
        }

        case 'ADMIN_V2__FETCH_CHILD_ORGS_BY_TYPE_START': {
            return {
                ...state,
                isTableLoading: true,
            };
        }

        case 'ADMIN_V2__FETCH_CHILD_ORGS_BY_TYPE_END': {
            return {
                ...state,
                isTableLoading: false,
                currentOrganization: 'all',
                users: action.payload.users,
                filteredUsers: action.payload.users,
                organizationsForList: action.payload.organizations,
                filteredOrganizationsList: action.payload.organizations,
                ownOrganizationType: action.payload.ownOrganizationType,
            };
        }

        case 'ADMIN_V2__FILTER_CHILD_ORGS_START': {
            return {
                ...state,
                isSideBarLoading: true,
            };
        }

        case 'ADMIN_V2__FILTER_CHILD_ORGS_END': {
            return {
                ...state,
                isSideBarLoading: false,
                filteredOrganizationsList: action.payload.data,
            };
        }

        case 'ADMIN_V2__CHANGE_ORG_FILTER_TYPE_START': {
            return {
                ...state,
                isSideBarLoading: true,
                isTableLoading: true,
            };
        }
        case 'ADMIN_V2__CHANGE_ORG_FILTER_TYPE_END': {
            return {
                ...state,
                isSideBarLoading: false,
                isTableLoading: false,
            };
        }

        case 'ADMIN_V2__CHILD_ORGANIZATION_DASH_INIT_START': {
            return {
                ...state,
                isPageLoading: true,
            };
        }

        case 'ADMIN_V2__CHILD_ORGANIZATION_DASH_INIT_END': {
            return {
                ...state,
                isPageLoading: false,
            };
        }
    }
}

import React from 'react';

import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { InfoBannerSimple } from 'widgets-v5/banners';

const useStyles = makeStyles({
    infoBannerContainer: {
        display: 'flex',
        margin: 0,
        justifyContent: 'center',
        padding: '10px 0',
    },
    infoBanner: {
        maxWidth: 300,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
    },
});

const CampaignInfoBanner = ({ content }) => {
    const styles = useStyles();
    return (
        <Box className={styles.infoBannerContainer}>
            <InfoBannerSimple className={styles.infoBanner} content={content} />
        </Box>
    );
};

export default CampaignInfoBanner;

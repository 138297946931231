import _ from 'lodash';
import React from 'react';
import { string, func, arrayOf, shape } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles(() => ({
    assetRow: {
        marginBottom: 20,
    },
}));

function AssetSelector(props) {
    const { selectedAsset, onChange, assets } = props;

    const classes = useStyles();

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Files</FormLabel>
            <RadioGroup
                aria-label="assets"
                name="assets"
                value={selectedAsset}
                onChange={(e, value) => onChange(value)}
            >
                {_.map(assets, asset => (
                    <FormControlLabel
                        key={asset.value}
                        value={asset.value}
                        control={<Radio />}
                        label={asset.label}
                        className={classes.assetRow}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

AssetSelector.propTypes = {
    selectedAsset: string,
    onChange: func,
    assets: arrayOf(
        shape({
            value: string,
            label: string,
        })
    ),
};

export default AssetSelector;

import _ from 'lodash';
import React, { useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import FormLabel from '@mui/material/FormLabel';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { SimpleSelect } from 'widgets-v6/select';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import { Label } from 'widgets-v5/typography';

import { isInternalUser } from 'states/profile/business-rules';
import LayoutV2 from './layout-v2';
import selector from './selector';
import actions from './actions';
import iabCategories from 'common/constants/iab-categories';
import { getDimensions } from 'common/constants/dimensions';
import METRICS from 'common/constants/metrics';
import CREATIVE_TYPES from 'common/constants/creative-types';
import { formatDimensions, formatMetrics } from 'widgets/pivot-table/service';
import { getIsZeroStat } from 'services/resources/stats';
import { formatNumber_percentage } from 'utils/formatting';
import {
    metrics,
    metricsWithSpendBreakout,
    getAvailableDimensions,
    dimensionCategoryMap,
    categories,
    getAvailableSnowflakeCategories,
    getAvailableMetricCategories,
} from './constants';
import { audienceDimensionIsHidden } from './services/business-logic';
import { getDayOfMonthOptions } from './services/helper';
import reportDashboardActions from 'pages/reports/dashboard/actions';
import { useBusinessReportEditor } from './v2/hook.js';
import { NEW_DIMENSIONS } from '../../campaigns/report/report-explore/v2/constants';
import { getEnvironmentSettings } from 'services/environment';

const METRIC_LOOKUP = {};
_.each(METRICS, metric => {
    METRIC_LOOKUP[metric.name] = metric;
});

function getDimensionLookup() {
    const DIMENSION_LOOKUP = {};
    _.each(getDimensions(), dimension => {
        DIMENSION_LOOKUP[dimension.name] = dimension;
    });

    return DIMENSION_LOOKUP;
}

let metricsToDisplay = metricsWithSpendBreakout;

const metricOptions = _.map(metricsToDisplay, metricName => {
    return {
        label: METRIC_LOOKUP[metricName].label,
        value: metricName,
    };
});

const iabCategoryOptions = _(iabCategories)
    .map((categoryName, categoryId) => ({
        value: categoryId,
        label: categoryName,
    }))
    .filter(category => category.value.indexOf('-') === -1)
    .value();

const dayOfMonthOptions = getDayOfMonthOptions();

const scheduledReportOptions = [
    {
        value: 'none',
        label: 'None',
    },
    {
        value: 'once',
        label: 'Once',
    },
    {
        value: 'hourly',
        label: 'Hourly',
    },
    {
        value: 'daily',
        label: 'Daily',
    },
    {
        value: 'weekly',
        label: 'Weekly',
    },
    {
        value: 'monthly',
        label: 'Monthly',
    },
];

function EditorWrapper(props) {
    const { init, reportInitialized } = useBusinessReportEditor({
        isInternalUser: isInternalUser(),
    });
    const { profile } = props;

    useEffect(() => {
        if (reportInitialized) {
            init();
        }
    }, [reportInitialized]);

    if (!profile.organizationId) {
        return null;
    }

    return <Editor {...props} />;
}

const DAYS_OF_WEEK = [
    { value: 'mon', label: 'Mon' },
    { value: 'tue', label: 'Tue' },
    { value: 'wed', label: 'Wed' },
    { value: 'thu', label: 'Thu' },
    { value: 'fri', label: 'Fri' },
    { value: 'sat', label: 'Sat' },
    { value: 'sun', label: 'Sun' },
];

const campaignStatusOptions = [
    {
        value: 'live',
        label: 'Live',
    },
    {
        value: 'paused',
        label: 'Paused',
    },
    {
        value: 'pending',
        label: 'Pending',
    },
    {
        value: 'ended',
        label: 'Ended',
    },
];

class Editor extends React.Component {
    state = {
        expandedPanel: '',
    };

    changeReportMode = mode => {
        this.props.dispatch(actions.changeReportMode(mode));
    };

    save = () => {
        const {
            editorState: { isSaving },
            dispatch,
            businessReportId,
        } = this.props;

        if (isSaving) {
            return;
        }

        dispatch(actions.save(businessReportId)).then(() => {
            this.onClose();
            dispatch(reportDashboardActions.init({}));
        });
    };

    saveAs = () => {
        const { dispatch } = this.props;
        if (this.props.editorState.isSaving) {
            return;
        }

        this.props.dispatch(actions.saveAs()).then(() => {
            this.onClose();
            dispatch(reportDashboardActions.init({}));
        });
    };

    openSaveAsModal = () => {
        this.props.dispatch(actions.openSaveAsModal());
    };

    closeSaveAsModal = () => {
        this.props.dispatch(actions.closeSaveAsModal());
    };

    openSaveModal = () => {
        this.props.dispatch(actions.openSaveModal());
    };

    closeSaveModal = () => {
        this.props.dispatch(actions.closeSaveModal());
    };

    cancel = () => {
        this.props.dispatch(actions.cancel());
    };

    updateDraft = changes => {
        const { dispatch, engagementOptions } = this.props;

        dispatch(actions.updateDraft(changes, engagementOptions));
    };

    updateField = field => {
        const { dispatch, engagementOptions } = this.props;

        return value => {
            const change = {
                [field]: value,
            };

            dispatch(actions.updateDraft(change, engagementOptions));
        };
    };

    updateCampaigns = input => {
        const {
            campaignOptions,
            editorState: {
                draft: { dateRange, dimensions, attributes },
            },
        } = this.props;
        const newCampaignsValue = {
            ids: input.values,
            mode: input.exclude ? 'exclude' : 'include',
        };

        this.updateField('campaigns')(newCampaignsValue);

        if (audienceDimensionIsHidden(campaignOptions, newCampaignsValue, dateRange)) {
            let filteredDimensions = _.filter(
                dimensions,
                dimension => dimension !== 'audience_segment'
            );
            let filteredAttributes = _.filter(
                attributes,
                attribute => attribute !== 'audience_segment_type_attr'
            );

            this.updateField('dimensions')(filteredDimensions);
            this.updateField('attributes')(filteredAttributes);
        }
    };

    updateDateRange = newDateRange => {
        const {
            campaignOptions,
            editorState: {
                draft: { campaigns, dimensions, attributes },
            },
        } = this.props;

        this.updateField('dateRange')(newDateRange);

        if (audienceDimensionIsHidden(campaignOptions, campaigns, newDateRange)) {
            let filteredDimensions = _.filter(
                dimensions,
                dimension => dimension !== 'audience_segment'
            );
            let filteredAttributes = _.filter(
                attributes,
                attribute => attribute !== 'audience_segment_type_attr'
            );

            this.updateField('dimensions')(filteredDimensions);
            this.updateField('attributes')(filteredAttributes);
        }
    };

    apply = () => {
        /*
        const {
            engagementOptions,
            attributeOptions,
            params: { businessReportId },
        } = this.props;

        const action = actions.apply(
            businessReportId,
            engagementOptions,
            attributeOptions
        );

        this.props.dispatch(action);
        */
    };

    onRenderFormatRow = (row, columns) => {
        let nextRow = { ...row };
        nextRow = this.formatMetrics(nextRow, columns);
        nextRow = this.formatDimensions(nextRow, columns);
        return nextRow;
    };

    onSerializeFormatRow = (row, columns) => {
        let nextRow = { ...row };
        nextRow = this.formatDimensions(nextRow, columns);
        return nextRow;
    };

    formatMetrics = (row, columns) => {
        let nextRow = { ...row };
        nextRow = formatMetrics(nextRow, columns);
        return nextRow;
    };

    formatDimensions = row => {
        const {
            editorState: {
                draft: { dimensions, timezone, attributes },
            },
            dictionary,
        } = this.props;

        const splits = _.map(dimensions, dimension => {
            return {
                label: getDimensionLookup()[dimension].label,
                name: dimension,
            };
        });

        let nextRow = { ...row };

        nextRow = formatDimensions(
            nextRow,
            splits,
            dictionary,
            null, // campaign,
            timezone
        );

        let columnData = { ...nextRow.columnData };

        if (nextRow.columnData.dimension !== 'Total') {
            const campaignsAttribute = _.get(nextRow, 'attributes.campaigns', []);
            const campaignNameAndId = _(campaignsAttribute)
                .filter(campaign => campaign)
                .map(campaign => `#${campaign.campaign_id} ${campaign.name}`)
                .join(', ');

            const adsAttribute = _.get(nextRow, 'attributes.ads', []);
            const adNameAndId = _(adsAttribute)
                .filter(ad => ad)
                .map(ad => `#${ad.ad_id} ${ad.name}`)
                .join(', ');

            const billingRateLabel = _(adsAttribute)
                .sortBy(['billing_enabled', 'billing_term', 'billing_rate'])
                .map(ad => {
                    if (!ad.billing_enabled) {
                        return 'Bonus';
                    }

                    const isCpmCpc = _.includes(['CPM', 'CPC'], ad.billing_term);

                    if (isCpmCpc) {
                        return `$${ad.billing_rate} ${ad.billing_term}`;
                    } else {
                        return `${formatNumber_percentage(ad.billing_rate)}`;
                    }
                })
                .uniq()
                .join(', ');

            const partnerLabel = _(campaignsAttribute)
                .filter(campaign => campaign)
                .map(campaign => campaign.clients)
                .flatten()
                .filter(client => client.type === 'partner')
                .map(client => client.name)
                .uniq()
                .sort()
                .join(', ');

            const advertiserLabel = _(campaignsAttribute)
                .filter(campaign => campaign)
                .map(campaign => campaign.clients)
                .flatten()
                .filter(client => client.type === 'advertiser')
                .map(client => client.name)
                .uniq()
                .sort()
                .join(', ');

            const salesRepLabel = _(campaignsAttribute)
                .filter(campaign => campaign)
                .map(campaign => campaign.clients)
                .flatten()
                .filter(client => client.type === 'sales_rep')
                .map(client => client.name)
                .uniq()
                .sort()
                .join(', ');

            switch (nextRow.dimension) {
                case 'markup_id': {
                    columnData = {
                        ...columnData,
                        dimension: `#${columnData.markup_id} ${nextRow.label}`,
                        markup_id: `#${columnData.markup_id} ${nextRow.label}`,
                    };
                    break;
                }
                case 'campaign_id': {
                    if (nextRow.attributes) {
                        columnData.dimension = campaignNameAndId;

                        const campaignsAttribute = _.get(nextRow, 'attributes.campaigns', []);
                        _.each(attributes, attribute => {
                            const attributeValue = _(campaignsAttribute)
                                .map(campaign => campaign[attribute])
                                .filter(a => a)
                                .join(', ');

                            if (attributeValue) {
                                columnData[attribute] = attributeValue;
                            }
                        });
                    }

                    break;
                }
                case 'ad_id': {
                    if (nextRow.attributes) {
                        columnData.dimension = adNameAndId;
                        const adsAttribute = _.get(nextRow, 'attributes.ads', []);
                        _.each(attributes, attribute => {
                            const attributeValue = _(adsAttribute)
                                .map(ad => ad[attribute])
                                .filter(a => a)
                                .join(', ');

                            if (attributeValue) {
                                columnData[attribute] = attributeValue;
                            }
                        });
                    }

                    break;
                }
            }

            columnData = {
                ...columnData,
                campaign_id: campaignNameAndId,
                ad_id: adNameAndId,
                billing_rate: billingRateLabel,
                partner: partnerLabel,
                advertiser: advertiserLabel,
                sales_rep: salesRepLabel,
            };
        }

        return {
            ...nextRow,
            columnData,
        };
    };

    filterRowHook = (filterRow, row, columns) => {
        const hideZeros = this.props.editorState.hideZeros;
        if (filterRow(row, columns) === false) {
            return false;
        }

        if (hideZeros) {
            return !getIsZeroStat(row.stats, metrics);
        }

        return true;
    };

    handlePivotTableSort = column => {
        const { businessReportId } = this.props.params;
        this.props.dispatch(actions.sortColumn(businessReportId, column));
    };

    handlePivotTableExpand = rowId => {
        const { businessReportId } = this.props.params;
        this.props.dispatch(actions.expandRow(businessReportId, rowId));
    };

    handlePivotTableCollapse = rowId => {
        const { businessReportId } = this.props.params;
        this.props.dispatch(actions.collapseRow(businessReportId, rowId));
    };

    handleHideZerosToggle = () => {
        const { businessReportId } = this.props.params;
        this.props.dispatch(actions.hideZerosToggle(businessReportId));
    };

    getDimensionOptions = () => {
        const {
            campaignOptions,
            editorState: {
                draft: { campaigns, dateRange },
            },
        } = this.props;

        let options = _.map(getAvailableDimensions(), dimensionName => {
            return {
                label: getDimensionLookup()[dimensionName]?.label || dimensionName,
                value: dimensionName,

                // Check if this dimension should be blocked due to the date range.
                // Certain dimensions are new and don't have reliable data before April 1st 2023.
                isBlocked: this.isBlockedDueToDateRange(dimensionName, dateRange),
            };
        });

        // Only allow this dimension when the campaigns contain good audience data, or
        // when the date range allows it
        if (audienceDimensionIsHidden(campaignOptions, campaigns, dateRange)) {
            options = _.filter(options, option => option.value !== 'audience_segment');
        }

        return options;
    };

    isBlockedDueToDateRange(dimensionName, dateRange) {
        return (
            dateRange.start &&
            moment(dateRange.start).isBefore(moment('2023-04-01')) &&
            NEW_DIMENSIONS.includes(dimensionName)
        );
    }

    getAttributeOptions = () => {
        const {
            attributeOptions,
            campaignOptions,
            editorState: {
                draft: { campaigns, dateRange },
            },
        } = this.props;

        let options = attributeOptions.slice();

        // Only allow this dimension when the campaigns contain good audience data, or
        // when the date range allows it
        if (audienceDimensionIsHidden(campaignOptions, campaigns, dateRange)) {
            options = _.filter(options, option => option.value !== 'audience_segment_type_attr');
        }

        return options;
    };

    getDimensionCategories = () => {
        const environmentSettings = getEnvironmentSettings();
        const canUseNewDimensions = environmentSettings.useNewReportingDimensions();

        const dimensions = this.getDimensionOptions();
        const attributes = this.getAttributeOptions();
        const categoriesBasedOnDB = canUseNewDimensions
            ? getAvailableSnowflakeCategories()
            : categories;
        const categoriesWithDimensions = _.map(Object.keys(categoriesBasedOnDB), categoryId => {
            return {
                label: categoriesBasedOnDB[categoryId].label,
                value: categoryId,
                dimensions: _.map(dimensions, dimension => {
                    if (_.includes(categoriesBasedOnDB[categoryId].dimensions, dimension.value)) {
                        return {
                            ...dimension,
                            attributes: _.map(attributes, attribute => {
                                if (
                                    _.includes(
                                        dimensionCategoryMap[dimension.value],
                                        attribute.value
                                    )
                                ) {
                                    return attribute;
                                }
                                if (attribute.category === 'custom') {
                                    const isCustomFieldIncludedInDimension = _.includes(
                                        dimensionCategoryMap[dimension.value],
                                        attribute.dimension
                                    );
                                    return isCustomFieldIncludedInDimension ? attribute : null;
                                }
                            }).filter(value => value),
                        };
                    }
                }).filter(dimension => dimension),
            };
        });

        return categoriesWithDimensions;
    };

    exportReport = download => {
        this.props.dispatch(actions.exportReport());
        // how should we get the errors

        if (_.keys(this.props.editorState.exportErrors).length > 0) {
            return;
        }

        download();
    };

    handleScheduleReportClick = () => {
        this.props.dispatch(actions.openSchedule());
    };

    handleScheduleDateChange = date => {
        this.props.dispatch(actions.changeDate(date));
        this.props.dispatch(actions.saveSchedule());
    };

    handleScheduleTimeChange = time => {
        this.props.dispatch(actions.changeTime(time));
        this.props.dispatch(actions.saveSchedule());
    };

    handleScheduleTimezoneChange = timezone => {
        this.props.dispatch(actions.changeTimezone(timezone));
    };

    handleScheduleFrequencyChange = e => {
        this.props.dispatch(actions.changeFrequency(e.target.value));
        this.props.dispatch(actions.saveSchedule());
    };

    handleScheduleDayOfWeekChange = dayOfWeek => () => {
        this.props.dispatch(actions.changeDayOfWeek(dayOfWeek));
        this.props.dispatch(actions.saveSchedule());
    };

    handleScheduleDayOfMonthChange = dayOfMonth => () => {
        this.props.dispatch(actions.changeDayOfMonth(dayOfMonth));
        this.props.dispatch(actions.saveSchedule());
    };

    handleScheduleToggleRecipient = e => {
        const email = e.target.value;
        this.props.dispatch(actions.toggleRecipient(email));
        this.props.dispatch(actions.saveSchedule());
    };

    handleChangeDateRangeType = type => {
        this.props.dispatch(actions.changeDateRangeType(type));
    };

    handleChangeDateRangeLast = last => {
        this.props.dispatch(actions.changeDateRangeLast(last));
    };

    handleChangeDateRangeLastFrame = lastFrame => {
        this.props.dispatch(actions.changeDateRangeLastFrame(lastFrame));
    };

    handleChangeDateRangeFrom = from => {
        this.props.dispatch(actions.changeDateRangeFrom(from));
    };

    expandPanel = panel => {
        return () => {
            this.state.expandedPanel === panel
                ? this.setState({
                      expandedPanel: '',
                  })
                : this.setState({
                      expandedPanel: panel,
                  });
        };
    };

    onClose = () => {
        this.props.dispatch(actions.closeEditor());
    };

    getMetricCategories = () => {
        const availableMetricCategories = getAvailableMetricCategories();
        const metrics = _.map(Object.keys(availableMetricCategories), categoryKey => {
            return {
                label: availableMetricCategories[categoryKey].label,
                value: categoryKey,
                metrics: _.map(availableMetricCategories[categoryKey].metrics, metric => ({
                    value: metric,
                    label: METRIC_LOOKUP[metric].label,
                })),
            };
        });
        return metrics;
    };

    render() {
        const { editorState } = this.props;
        const dimensionCategories = this.getDimensionCategories();
        const metricCategories = this.getMetricCategories();

        return (
            <Dialog
                open={this.props.isEditorOpen}
                onClose={this.onClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="lg"
            >
                <LayoutV2
                    schemaVersion={this.props.editorState.schemaVersion}
                    profile={this.props.profile}
                    businessReportId={this.props.businessReportId}
                    errors={this.props.editorState.errors}
                    organiztionForFilteringOptions={this.props.organiztionForFilteringOptions}
                    showErrors={this.props.editorState.showErrors}
                    isInitialized={this.props.editorState.reportInitialized}
                    isLoading={this.props.editorState.isLoading}
                    isReportCampaignsLoaderLoading={this.props.isReportCampaignsLoaderLoading}
                    isFetchingStats={this.props.editorState.isFetchingStats}
                    isSaving={this.props.editorState.isSaving}
                    iabCategoryOptions={iabCategoryOptions}
                    ownOrganization={this.props.ownOrganization}
                    isSaveAsModalOpen={this.props.editorState.isSaveAsModalOpen}
                    openSaveAsModal={this.openSaveAsModal}
                    closeSaveAsModal={this.closeSaveAsModal}
                    isSaveModalOpen={this.props.editorState.isSaveModalOpen}
                    openSaveModal={this.openSaveModal}
                    closeSaveModal={this.closeSaveModal}
                    changeReportMode={this.changeReportMode}
                    updateDraft={this.updateDraft}
                    updateAttributes={this.updateField('attributes')}
                    updateCampaignStatus={this.updateField('campaignStatus')}
                    updateCampaigns={this.updateCampaigns}
                    updateClient={this.updateField('client')}
                    updateCreativeType={this.updateField('creativeType')}
                    updateDateRange={this.updateDateRange}
                    updateDimensions={this.updateField('dimensions')}
                    updateEngagements={this.updateField('engagements')}
                    updateConversions={this.updateField('conversions')}
                    updateIabCategory={this.updateField('iabCategory')}
                    updateMetrics={this.updateField('metrics')}
                    updateName={this.updateField('name')}
                    updateSaveAsName={this.updateField('saveAsName')}
                    updateTimezone={this.updateField('timezone')}
                    draft={this.props.editorState.draft}
                    save={this.save}
                    saveAs={this.saveAs}
                    cancel={this.cancel}
                    apply={this.apply}
                    onSort={this.handlePivotTableSort}
                    onExpand={this.handlePivotTableExpand}
                    onCollapse={this.handlePivotTableCollapse}
                    pivotTable={this.props.editorState.pivotTable}
                    columns={this.props.editorState.columns}
                    hideZeros={this.props.editorState.hideZeros}
                    isPivotTableInitialized={this.props.editorState.isPivotTableInitialized}
                    isPivotTableLoading={this.props.editorState.isPivotTableLoading}
                    onSerializeFormatRow={this.onSerializeFormatRow}
                    onRenderFormatRow={this.onRenderFormatRow}
                    filterRowHook={this.filterRowHook}
                    dictionary={this.props.dictionary}
                    hideZerosToggle={this.handleHideZerosToggle}
                    exportMeta={this.props.exportMeta}
                    clientOptions={this.props.clientOptions}
                    campaignOptions={this.props.campaignOptions}
                    campaignStatusOptions={campaignStatusOptions}
                    creativeTypeOptions={CREATIVE_TYPES}
                    metricOptions={metricOptions}
                    metricCategories={metricCategories}
                    dimensionOptions={this.getDimensionOptions()}
                    attributeOptions={this.getAttributeOptions()}
                    engagementOptions={this.props.engagementOptions}
                    audienceDimensionIsHidden={audienceDimensionIsHidden(
                        this.props.campaignOptions,
                        this.props.editorState.draft.campaigns,
                        this.props.editorState.draft.dateRange
                    )}
                    exportReport={this.exportReport}
                    onChangeDateRangeType={this.handleChangeDateRangeType} // to be removed
                    filters={this.props.filters}
                    onChangeDateRangeLast={this.handleChangeDateRangeLast}
                    onChangeDateRangeLastFrame={this.handleChangeDateRangeLastFrame}
                    onChangeDateRangeFrom={this.handleChangeDateRangeFrom}
                    handleScheduleReportClick={this.handleScheduleReportClick}
                    scheduled={editorState.draft.schedule.frequency !== 'none'}
                    dimensionCategories={dimensionCategories}
                    scheduledReport={
                        <AccordionItem
                            onClick={this.expandPanel('schedule')}
                            expanded={this.state.expandedPanel === 'schedule'}
                            title="Schedule"
                            error={editorState.showErrors && editorState.scheduleDraftError}
                            details={
                                <div>
                                    <SimpleSelect
                                        value={editorState.draft.schedule.frequency}
                                        onChange={this.handleScheduleFrequencyChange}
                                        options={scheduledReportOptions}
                                        label="Frequency"
                                    />
                                    <FormControl fullWidth margin="normal">
                                        <FormGroup row>
                                            {_.includes(
                                                ['once'],
                                                editorState.draft.schedule.frequency
                                            ) && (
                                                <Box mr={2}>
                                                    <Box display="flex" mb={1}>
                                                        <FormLabel>Date Picker</FormLabel>
                                                    </Box>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterMoment}
                                                    >
                                                        <DatePicker
                                                            id="mui-pickers-date"
                                                            disableToolbar
                                                            variant="inline"
                                                            margin="normal"
                                                            format="YYYY-MM-DD"
                                                            value={moment(
                                                                editorState.draft.schedule.date
                                                            )}
                                                            onChange={this.handleScheduleDateChange}
                                                        />
                                                    </LocalizationProvider>
                                                </Box>
                                            )}
                                            {_.includes(
                                                ['once', 'daily', 'weekly', 'monthly'],
                                                editorState.draft.schedule.frequency
                                            ) && (
                                                <Box>
                                                    <Box display="flex" mb={1}>
                                                        <FormLabel>Time Picker</FormLabel>
                                                    </Box>
                                                    <TimePicker
                                                        margin="normal"
                                                        id="mui-pickers-time"
                                                        value={moment(
                                                            editorState.draft.schedule.time
                                                        )}
                                                        onChange={this.handleScheduleTimeChange}
                                                        ampm
                                                    />
                                                </Box>
                                            )}
                                        </FormGroup>
                                    </FormControl>
                                    {_.includes(
                                        ['weekly'],
                                        editorState.draft.schedule.frequency
                                    ) && (
                                        <FormControl fullWidth margin="dense">
                                            <FormLabel>Days of Week</FormLabel>
                                            <FormGroup row>
                                                {_.map(DAYS_OF_WEEK, dow => (
                                                    <FormControlLabel
                                                        key={dow.value}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    editorState.draft.schedule
                                                                        .day_of_week[dow.value]
                                                                }
                                                                onChange={this.handleScheduleDayOfWeekChange(
                                                                    dow.value
                                                                )}
                                                                value={dow.value}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={dow.label}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    )}
                                    {_.includes(
                                        ['monthly'],
                                        editorState.draft.schedule.frequency
                                    ) && (
                                        <FormControl fullWidth margin="dense">
                                            <FormGroup row>
                                                <SimpleSelect
                                                    value={
                                                        editorState.draft.schedule.dayOfMonth || 1
                                                    }
                                                    onChange={e =>
                                                        this.handleScheduleDayOfMonthChange(
                                                            e.target.value
                                                        )
                                                    }
                                                    options={dayOfMonthOptions}
                                                    placeholder="Select a day of month"
                                                    label="Day of Month"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    )}
                                    {!_.includes(
                                        ['none'],
                                        editorState.draft.schedule.frequency
                                    ) && (
                                        <FormControl component="fieldset" margin="normal" fullWidth>
                                            <FormLabel>Recipients</FormLabel>
                                            <RecipientsForm>
                                                {_.map(
                                                    editorState.ownOrg && editorState.ownOrg.users,
                                                    (user, idx) => (
                                                        <FormControlLabel
                                                            key={`${user.email}-${idx}`}
                                                            control={
                                                                <Checkbox
                                                                    checked={_.includes(
                                                                        editorState.draft.schedule
                                                                            .recipients,
                                                                        user.email
                                                                    )}
                                                                    onChange={
                                                                        this
                                                                            .handleScheduleToggleRecipient
                                                                    }
                                                                    value={user.email}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={user.email}
                                                        />
                                                    )
                                                )}
                                            </RecipientsForm>
                                        </FormControl>
                                    )}
                                    {editorState.showErrors && editorState.scheduleDraftError && (
                                        <FormHelperText error>
                                            {editorState.scheduleDraftError}
                                        </FormHelperText>
                                    )}
                                </div>
                            }
                            summary={
                                editorState.draft.schedule.frequency !== 'none' ? (
                                    <div>
                                        <Typography color="textSecondary">Frequency:</Typography>
                                        <Typography>
                                            {_.capitalize(editorState.draft.schedule.frequency)}
                                        </Typography>
                                        <br />
                                        <Typography color="textSecondary">Recipients: </Typography>
                                        <Box>
                                            {_.map(
                                                editorState.draft.schedule.recipients,
                                                recipient => (
                                                    <Typography>{recipient}</Typography>
                                                )
                                            )}
                                        </Box>
                                    </div>
                                ) : (
                                    <Label placeholder>Add a Schedule</Label>
                                )
                            }
                        />
                    }
                />
            </Dialog>
        );
    }
}

const styles = () => ({
    root: {
        maxHeight: 400,
        overflow: 'auto',
        flexWrap: 'nowrap',
    },
    label: {
        marginBottom: 10,
    },
    errorMessage: {
        color: 'red',
    },
});

const RecipientsForm = withStyles(styles)(props => {
    const { children, classes } = props;

    return <FormGroup className={classes.root}>{children}</FormGroup>;
});

export default withStyles(styles)(connect(selector)(EditorWrapper));

import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import Spacer from 'widgets-v5/spacer';
import actions from './actions';
import selector from './selector';
import { browserHistory } from 'react-router';
import moment from 'moment';

import flags from 'containers/flags/service';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { SearchInput } from 'widgets-v6/standard-input';
import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';

const useStyles = makeStyles(theme => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50,
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    header: {
        marginTop: '50px',
    },
    container: {
        marginBottom: 40,
    },
    table: {
        marginTop: 15,
    },
    toolbar: {
        margin: '10px 0px',
    },
}));

const FTALocationLists = ({ dispatch, ftaLocationLists, loading, workspace }) => {
    const [filterString, setFilterString] = useState('');
    const [filteredList, setFilteredList] = useState(ftaLocationLists);
    const classes = useStyles();

    useEffect(() => {
        dispatch(actions.getFtaLocationLists());
    }, [1]);

    useEffect(() => {
        if (filterString) {
            const filterStringLower = filterString.toLowerCase();
            setFilteredList(
                ftaLocationLists.filter(
                    ({ name }) => name.toLowerCase().indexOf(filterStringLower) > -1
                )
            );
        } else if (ftaLocationLists?.length > 0) {
            setFilteredList(ftaLocationLists);
        }
    }, [filterString, ftaLocationLists]);

    const handleCreateNewLocationList = () => {
        dispatch({
            type: 'FTA_LOCATION_LISTS_EDITOR__CREATE_NEW',
            payload: {},
        });

        browserHistory.push('/fta-location-lists/new');
    };

    const handleClickDownload = link => {
        window.location = link;
    };

    const handleViewLocationList = locationListId => {
        dispatch({
            type: 'FTA_LOCATION_LISTS_EDITOR__OPEN',
            payload: {},
        });

        browserHistory.push(`/fta-location-lists/view/${locationListId}`);
    };

    return (
        <React.Fragment>
            <Container maxWidth="lg" className={classes.container}>
                <Header
                    title="Foot Traffic Attribution"
                    imgUrl="/images/fta-location-lists/fta-location-lists.png"
                    description="Measures the effectiveness of your advertising campaigns at driving in-store traffic. Upload store locations to be tracked for campaigns leveraging Foot Traffic Attribution"
                />
                <Toolbar disableGutters className={classes.toolbar}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item xs={3}>
                            <SearchInput
                                placeholder="Search"
                                onChange={({ target: { value } }) => setFilterString(value)}
                            />
                        </Grid>
                        {can(PermissionsMapping.FTA__EDIT) && (
                            <Grid item>
                                <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    onClick={handleCreateNewLocationList}
                                    color="primary"
                                >
                                    New FTA List
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
                <TableList
                    rows={filteredList}
                    isLoading={loading}
                    handleViewLocationList={handleViewLocationList}
                    handleClickDownload={handleClickDownload}
                />
            </Container>
            {workspace}
        </React.Fragment>
    );
};

function Header({ title, description, imgUrl }) {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Typography variant="h4">{title}</Typography>
                    <Spacer />
                    <Typography variant="h5" color="textSecondary">
                        {description}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <HeaderImage imgUrl={imgUrl} />
                </Grid>
            </Grid>
        </div>
    );
}

function HeaderImage({ imgUrl }) {
    return <img className="audience-header__image" src={imgUrl} />;
}

const headers = [
    {
        id: 'name',
        label: 'Name',
        align: 'left',
    },
    {
        id: 'comment',
        label: 'Comment',
        align: 'left',
    },
    {
        id: 'status',
        label: 'Status',
        align: 'left',
    },
    {
        id: 'locations',
        label: 'Locations',
        align: 'right',
    },
    {
        id: 'created-by',
        label: 'Created By',
        align: 'left',
    },
    {
        id: 'created',
        label: 'Created',
        align: 'right',
    },
    {
        id: 'actions',
        label: 'Actions',
        align: 'center',
    },
];

const statusMapping = {
    GIS_PROCESSING: 'PROCESSING',
    RECEIVED: 'RECEIVED',
    READY: 'READY',
};

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function TableList({ rows, handleClickDownload, isLoading, handleViewLocationList }) {
    const classes = useStyles();

    const processedRows = rows
        ? rows.map(row => {
              return {
                  ...row,
                  customValues: isJson(row.custom_value) ? JSON.parse(row.custom_value) : {},
              };
          })
        : [];

    return (
        <React.Fragment>
            {rows && (
                <Typography variant="body2" gutterBottom color="textSecondary">{`${rows &&
                    rows.length} Records found`}</Typography>
            )}
            <Paper>
                <Table
                    aria-label="simple table"
                    size="small"
                    stickyHeader
                    className={classes.table}
                >
                    <TableHead>
                        <TableRow>
                            {flags.isEnabled(
                                'EN_3673_show_warning_when_ftaLocationList_status_is_processing'
                            )
                                ? map(headers, ({ id, label, align }) => (
                                      <TableCell key={id} align={align}>
                                          {label}
                                      </TableCell>
                                  ))
                                : filter(headers, header => header.id !== 'status').map(
                                      ({ id, label, align }) => (
                                          <TableCell key={id} align={align}>
                                              {label}
                                          </TableCell>
                                      )
                                  )}
                        </TableRow>
                    </TableHead>
                    {!isLoading && (
                        <TableBody>
                            {map(
                                processedRows,
                                ({
                                    id,
                                    comment,
                                    customValues,
                                    name,
                                    status,
                                    poi_count,
                                    requested_at,
                                    requested_by,
                                    raw_location_file_URL,
                                }) => (
                                    <TableRow key={id}>
                                        <TableCell component="th" scope="row">
                                            {name}
                                        </TableCell>
                                        <TableCell align="left">{comment}</TableCell>
                                        {flags.isEnabled(
                                            'EN_3673_show_warning_when_ftaLocationList_status_is_processing'
                                        ) && (
                                            <TableCell align="left">
                                                {statusMapping[status]}
                                            </TableCell>
                                        )}

                                        <TableCell align="right">
                                            {poi_count ? poi_count : 'N/A'}
                                        </TableCell>
                                        <TableCell align="left">
                                            {customValues && customValues.name
                                                ? customValues.name
                                                : requested_by}
                                        </TableCell>
                                        <TableCell align="right">
                                            {moment(requested_at).format('ll')}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() => {
                                                    handleClickDownload(raw_location_file_URL);
                                                }}
                                                size="large"
                                            >
                                                <GetAppIcon />
                                            </IconButton>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleViewLocationList(id)}
                                                color="primary"
                                            >
                                                VIEW
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    )}
                </Table>
                {isLoading && (
                    <div className={classes.progress}>
                        <CircularProgress />
                    </div>
                )}
            </Paper>
        </React.Fragment>
    );
}

export default connect(selector)(FTALocationLists);

import Joi from '@hapi/joi';
import {
    VALID_WEATHER_CONDITION_QUALIFIER,
    VALID_WEATHER_CONDITION_TIMEFRAME,
    VALID_WEATHER_CONDITION_TYPE,
} from 'common/constants/weather-condition-targeting';

const schema = Joi.object({
    logicRelationship: Joi.string()
        .valid('and', 'or')
        .optional(),
    conditions: Joi.array()
        .items(
            Joi.object({
                id: Joi.string().required(),
                type: Joi.string()
                    .valid(...VALID_WEATHER_CONDITION_TYPE)
                    .required()
                    .messages({
                        'string.empty': 'Not allowed to be empty',
                    }),
                qualifier: Joi.string()
                    .valid(...VALID_WEATHER_CONDITION_QUALIFIER)
                    .required()
                    .messages({
                        'string.empty': 'Not allowed to be empty',
                    }),
                amount: Joi.number()
                    .when('type', {
                        is: 'Temperature',
                        then: Joi.number()
                            .integer()
                            .required(),
                        otherwise: Joi.number().required(),
                    })
                    .when('type', {
                        is: 'FeelsLike',
                        then: Joi.number()
                            .integer()
                            .required(),
                        otherwise: Joi.number().required(),
                    })
                    .messages({
                        'any.only': 'Not allowed to be empty',
                    }),
                timeframe: Joi.string()
                    .valid(...VALID_WEATHER_CONDITION_TIMEFRAME)
                    .required()
                    .messages({
                        'string.empty': 'Not allowed to be empty',
                    }),
            })
        )
        .optional(),
});

export default schema;

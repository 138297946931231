import toastr from 'toastr';
import { createHttp } from 'utils/http';

const source_type = ['pds_standard'];

const actions = {
    getAudiences: keywords => {
        return async (dispatch) => {
            try {
                const http = createHttp();
                const query = `
                    query getAudiences ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                            id
                            name
                            lastRefreshed
                            dataCost
                            poiCount
                            uniques
                            status
                            match_rate
                            supports
                        }
                    }
                `;

                const variables = {
                    keywords,
                    source_type,
                    deprecated: false,
                };

                const data = await http.graphql(query, variables);

                dispatch({
                    type: 'RETAIL_DASHBOARD__FETCH_SEGMENTS_DATA__END',
                    payload: {
                        data: data.audienceSegments,
                    },
                });
            } catch (err) {
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import InlineTextboxForm from './modules/inline-textbox-form';
import makeStyles from '@mui/styles/makeStyles';

import { red } from '@mui/material/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';

import FileUpload from 'widgets-v5/file-upload';
import DragAndDropFileUpload from 'widgets-v5/drag-and-drop-file-upload';
import ClickToCopyButton from 'widgets/click-to-copy-button';
import Files from 'states/resources/files/actions';
import { SearchInput } from 'widgets-v6/standard-input';
import { TableWrapper } from 'widgets-v6/table';

import OverviewTabs from 'pages/campaigns/campaigns-overview/overview-tabs';

import { canEditFile } from 'states/resources/files/business-logic';

import {
    fileUploadSubmit,
    fileDeleteSubmit,
    fileRenameSubmit,
} from 'states/resources/campaigns/actions';
import { filterFileList, showFileTypeError } from 'pages/campaigns/files-overview/actions';
import { getEnvironmentSettings } from 'services/environment';

import selector from './selector';

const useStyles = makeStyles({
    asset: {
        maxWidth: '100px',
    },
});

function Asset(props) {
    const classes = useStyles();
    return <img className={classes.asset} src={props.src} />;
}

const getActions = ({ deleteFile, downloadFile, file, campaign }) => {
    const options = [
        {
            label: 'Download',
            link: null,
            canView: () => true,
            onClick: () =>
                downloadFile(
                    file && file.attr.type === 'video' ? file.attr.url : file.attr.preview_url
                ),
            icon: <GetAppIcon />,
        },
        {
            label: 'Delete',
            link: null,
            canView: canEditFile(campaign),
            onClick: () => deleteFile(file && file.id),
            icon: <DeleteIcon />,
        },
    ];

    return _.filter(options, option => option.canView);
};

const CampaignOverviewFiles = createReactClass({
    displayName: 'CampaignOverviewFiles',

    statics: {
        refresh(dispatch, nextState) {
            const campaignId = _.get(nextState, 'params.campaignId', void 0);
            const query = _.get(nextState, 'location.query', {});

            dispatch(Files.getAll(campaignId));

            if (query.search) {
                dispatch(filterFileList(campaignId, { filterType: 'search', value: query.search }));
            }
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        CampaignOverviewFiles.refresh(dispatch, nextState, browserHistory.push);
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        if (this.props.params.campaignId !== prevProps.params.campaignId) {
            this.init();
        }
    },

    uploadFile(formData, file) {
        const { dispatch, campaignId } = this.props;

        dispatch(fileUploadSubmit(formData, campaignId, file));
    },

    deleteFile(fileId) {
        const { dispatch, campaignId } = this.props;
        dispatch(fileDeleteSubmit(fileId, campaignId));
    },

    renameFile(updatedFileName, fileId) {
        const { dispatch, campaignId } = this.props;

        dispatch(fileRenameSubmit(fileId, updatedFileName, campaignId));
    },

    handleFilterChange(filterType, value) {
        const { dispatch, campaignId } = this.props;
        const filterOptions = { filterType, value };

        dispatch(filterFileList(campaignId, filterOptions));
    },

    handleInvalidFileError(files) {
        const { dispatch } = this.props;
        dispatch(showFileTypeError(files));
    },

    downloadFile(downloadUrl) {
        if (!downloadUrl) {
            return;
        }

        const element = document.createElement('a');
        element.setAttribute('href', downloadUrl);
        element.setAttribute('download', true);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    },

    render() {
        if (this.props.workspace) {
            return this.props.workspace;
        }

        const { campaign, campaignId, files, searchQuery, tempAssets, failedAssets } = this.props;

        const environmentSettings = getEnvironmentSettings();
        const maxFileUploadSizeMB = environmentSettings.getMaxFileUploadSizeMB();

        return (
            <div>
                <div className="ef3-campaign-overview-files__WorkspaceToolbar">
                    <OverviewTabs tab="files" campaignId={campaignId} />
                    <div className="ef3-standardToolbar">
                        <div className="ef3-campaign-overview-files__search">
                            <SearchInput
                                placeholder="Search"
                                onChange={e => {
                                    this.handleFilterChange('search', e.target.value);
                                }}
                                defaultValue={searchQuery}
                                autoFocus
                            />
                        </div>
                        <div className="ef3-campaign-overview-files__upload-assets-button">
                            {!_.isEmpty(this.props.uploadAssetsErrorMessage) && (
                                <Box textAlign='center' alignItems='right' padding='10px' color={red[500]} >
                                    <Typography>
                                        One or more files failed to upload, {this.props.uploadAssetsErrorMessage}
                                    </Typography>
                                </Box>
                            )}
                            {canEditFile(campaign) && (
                                <FileUpload
                                    isLoading={this.props.loadingAssets}
                                    onChange={formData => this.uploadFile(formData)}
                                    maxSizeInMB={maxFileUploadSizeMB}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {canEditFile(campaign) && (
                    <div className="ef3-campaign-overview-files__file-drop-zone">
                        <div className="ef3-campaign-overview-files__file-drop-zone-drag-and-drop">
                            <DragAndDropFileUpload
                                onChange={(formData, file) => this.uploadFile(formData, file)}
                                accept="video/mp4, image/*, application/pdf, .csv, text/csv, .xlsx, .xls"
                                onDropRejected={this.handleInvalidFileError}
                                tempAssets={tempAssets}
                                failedAssets={failedAssets}
                                maxSizeInMB={maxFileUploadSizeMB}
                            >
                                <div>
                                    <i className="fa fa-cloud-upload" />
                                    &nbsp;Drag and drop files here to upload
                                </div>
                            </DragAndDropFileUpload>
                        </div>

                        <div className="ef3-campaign-overview-files__file-drop-zone-info">
                            <h3>Uploads are limited to {maxFileUploadSizeMB} MB per file.</h3>
                            <p>Video: MP4</p>
                            <p>Image: JPG/JPEG, GIF, PNG</p>
                            <p>Document: PDF, TXT, DOC/DOCX, XSL/XSLX</p>
                        </div>
                    </div>
                )}
                <Box mx={2} mb={4}>
                    <TableWrapper title="Documents">
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(files.documents, doc => (
                                    <TableRow>
                                        <TableCell>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <InlineTextboxForm
                                                    key={doc.id}
                                                    filename={doc.attr.name}
                                                    editAction={
                                                        canEditFile(campaign)
                                                            ? filename =>
                                                                    this.renameFile(
                                                                        filename,
                                                                        doc.id
                                                                    )
                                                            : null
                                                    }
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell>{doc.attr.type}</TableCell>
                                        <TableCell>
                                            {_.map(
                                                getActions({
                                                    deleteFile: this.deleteFile,
                                                    renameFile: this.renameFile,
                                                    downloadFile: this.downloadFile,
                                                    file: doc,
                                                    campaign,
                                                }),
                                                action =>
                                                    action.canView && (
                                                        <IconButton
                                                            onClick={action.onClick}
                                                            size="large"
                                                        >
                                                            {action.icon}
                                                        </IconButton>
                                                    )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableWrapper>
                </Box>

                <Box mx={2}>
                    <TableWrapper title="Assets">
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {_.map(
                                        [
                                            'Name',
                                            'Preview',
                                            'Width',
                                            'Height',
                                            'Type',
                                            'Duration (Video)',
                                            'Actions',
                                        ],
                                        header => (
                                            <TableCell>{header}</TableCell>
                                        )
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(files.assets, file => (
                                    <TableRow key={file.id}>
                                        <TableCell>
                                            <InlineTextboxForm
                                                key={file.id}
                                                filename={file.attr.name}
                                                editAction={
                                                    canEditFile(campaign)
                                                        ? filename =>
                                                                this.renameFile(filename, file.id)
                                                        : null
                                                }
                                            />
                                        </TableCell>
                                        <TableCell key={file.attr.id}>
                                            {file.attr.type === 'video' ? (
                                                <video src={file.attr.url} controls width="75%">
                                                    Your browser does not support the HTML video
                                                    tag.
                                                </video>
                                            ) : (
                                                <Asset src={file.attr.preview_url} />
                                            )}
                                        </TableCell>
                                        <TableCell>{file.attr.width}</TableCell>
                                        <TableCell>{file.attr.height}</TableCell>
                                        <TableCell>{file.attr.type}</TableCell>
                                        <TableCell>{file.attr.duration || ''}</TableCell>
                                        <TableCell>
                                            {_.map(
                                                getActions({
                                                    deleteFile: this.deleteFile,
                                                    renameFile: this.renameFile,
                                                    downloadFile: this.downloadFile,
                                                    file,
                                                    campaign,
                                                }),
                                                action =>
                                                    action.canView && (
                                                        <IconButton
                                                            onClick={action.onClick}
                                                            size="large"
                                                        >
                                                            {action.icon}
                                                        </IconButton>
                                                    )
                                            )}
                                            <ClickToCopyButton
                                                textToCopy={file.attr.url}
                                                textToDisplay="Copy URL"
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableWrapper>
                </Box>
            </div>
        );
    },
});

export default connect(selector)(CampaignOverviewFiles);

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AppLists from 'states/resources/app-lists/actions';

const mapStateToProps = storeState => {
    const orgId = _.get(storeState, `profile.organizationId`, void 0);
    const appLists = _(_.get(storeState, `resources.appLists`, {}))
        .map(list => list.attr)
        .value();

    return {
        orgId,
        appLists,
    };
};

import ApplistItem from './app-list-item';

class ModuleRoot extends React.Component {
    constructor(props) {
        super(props);
        this.fetchDetails = _.throttle(this.fetchDetails.bind(this), 100);
        this.state = {
            isLoading: false,
        };
    }
    UNSAFE_componentWillMount() {
        this.fetchDetails(this.props.orgId);
    }

    fetchDetails(orgId) {
        const { dispatch } = this.props;
        this.setState({ isLoading: true });
        dispatch(AppLists.getAll(orgId)).then(
            () => {
                this.setState({
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    isLoading: false,
                });
            }
        );
    }

    render() {
        return (
            <div className="applist">
                <div className="add-to-block-list">
                    <div className="add-to-block-list_inner">
                        {this.props.appLists.map((applist, i) => {
                            return (
                                <ApplistItem
                                    appListId={applist.id}
                                    channel_id={this.props.channel_id}
                                    channel_type={this.props.channel_type}
                                    fetchDetails={() => this.fetchDetails(this.props.orgId)}
                                    key={i}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ModuleRoot);

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import BaseColumnForm from '../base-column-form';
import Tactics from 'forms/ad-form/modules/tactics';
import { TacticSummary } from 'containers/ad-summary';

import shallowCompare from 'utils/shallow-compare';

import { tacticsSummarySelector } from './selector';

export class TacticsForm extends React.Component {
    state = {
        errors: {},
        audienceMapping: {},
    };

    handleDraftUpdate = updateDraft => ({ draft, errors, errorKey }) => {
        updateDraft(draft);

        if (errors) {
            this.setState({
                errors: {
                    [errorKey]: errors,
                },
            });
        } else {
            this.setState({
                errors: {},
            });
        }
    };

    render() {
        const { ad, ads, campaign, onSave, platformFilter, isCTVCampaign } = this.props;

        const platform = platformFilter;

        const adPlatforms = ad
            ? _.get(ad, 'platforms')
            : _(ads)
                  .map(ad => ad.platforms)
                  .flatten()
                  .uniq()
                  .value();

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Tactics"
                fields={['tactics_generators', 'audience_fee']}
                shouldRefreshPredictionsOnSave={true}
                externalErrors={this.state.errors}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <Tactics
                        showErrors={shouldShowErrors}
                        updateModuleDraft={this.handleDraftUpdate(updateDraft)}
                        tactics_generators={draft.tactics_generators}
                        adId={ad && ad.id}
                        campaignId={campaign.id}
                        isAppStoreCatEnabled={campaign.isAppStoreCatEnabled}
                        audienceRates={campaign.audience_rates}
                        platform={platform}
                        platforms={adPlatforms}
                        isCrossPlatformCampaign={campaign.isCrossPlatformCampaign}
                        isCTVCampaign={isCTVCampaign}
                    />
                )}
            </BaseColumnForm>
        );
    }
}

class TacticsSummaryComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, error, geoTargetingSubCategories, geoLayers } = this.props;

        if (error) {
            return <div>{error}</div>;
        }

        return (
            <div>
                {_.map(ad.tactics_generators, tactic => (
                    <TacticSummary
                        key={tactic.id}
                        tactic={tactic}
                        geoTargetingSubCategories={geoTargetingSubCategories}
                        geoLayers={geoLayers}
                    />
                ))}
            </div>
        );
    }
}

export const TacticsSummary = connect(tacticsSummarySelector)(TacticsSummaryComponent);

import _ from 'lodash';
import React from 'react';
import shallowCompare from 'utils/shallow-compare';
import { Cell } from 'widgets-v5/data-table';
import { formatNumber_whole } from 'utils/formatting';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import flags from 'containers/flags/service';

export class EstimatedUniquesSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;
        const { isLoading, error } = this.props.inventoryPredictions || {};

        if (error) {
            return (
                <BlockLoadGroup isLoading={isLoading}>{_.get(error, ['body', 1])}</BlockLoadGroup>
            );
        }

        return (
            <BlockLoadGroup isLoading={isLoading}>
                <React.Fragment>
                    <div>Average CTR: {formatNumber_whole(ad.predicted_total_uniques_min)}</div>
                    <div>Low CTR: {formatNumber_whole(ad.predicted_total_uniques_max)}</div>
                </React.Fragment>
            </BlockLoadGroup>
        );
    }
}

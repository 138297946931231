import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';
import update from 'utils/update';
import { storageSelector } from 'utils/browser-storage';
import { detectImpersonation } from 'services/impersonation';

function getInitialState() {
    const impersonating = detectImpersonation();
    const storage = storageSelector(impersonating);

    const savedState = storage.getItem('RESOURCE_CAMPAIGNS');
    return savedState ? JSON.parse(savedState) : {};
}

// function saveToLocalStorage (state) {

//     const savable = _.reduce(state, (acc, campaign, key) => ({
//         ...acc,
//         [campaign.id]: {
//             ...campaign,
//             attr: {
//                 ...campaign.attr,
//                 ads: [],
//                 creatives: [],
//                 files: []
//             }
//         }
//     }), {});
//     console.log('saving', savable);
//     storage.setItem('RESOURCE_CAMPAIGNS', JSON.stringify(savable));
// }

export const NAME = 'resources.campaigns';

const initialState = getInitialState();

export default function campaigns_temp(state = initialState, action) {
    switch (action.type) {
        case c.OVERVIEW_CAMPAIGN__DUPLICATE__SUCCESS: {
            const { duplicateCampaign } = action.payload;
            const campaignsAsList = []
                .concat(duplicateCampaign)
                .filter(c => c)
                .map(c => ({
                    ...c,
                    ads: _.map(c.ads, a => a.id),
                    creatives: _.map(c.creatives, c => c.id),
                }));

            const nextState = mergeResource(state, campaignsAsList);

            return nextState;
        }

        case c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS: {
            const { campaign, campaigns } = action.payload;
            const campaignsAsList = []
                .concat(campaign)
                .concat(campaigns)
                .filter(c => c)
                .map(c => ({
                    ...c,
                    adTimezones: _(c.ads).map(a => a.timezone).uniq().value(),
                    ads: _.map(c.ads, a => a.id),
                    creatives: _.map(c.creatives, c => c.id),
                }));

            const nextState = mergeResource(state, campaignsAsList);

            return nextState;
        }

        case c.SYSTEM__CAMPAIGN_FILES__FETCH__SUCCESS: {
            const { campaignId, file, files } = action.payload;
            const campaign = state[campaignId];

            const filteredFiles = _(file)
                .concat(files)
                .filter(x => x)
                .value();

            const fileIdsAsStrings = _.map(filteredFiles, file => file.id);
            const allFiles = []
                .concat(_.get(campaign, 'attr.files', []), fileIdsAsStrings)
                .filter(x => x);

            const nextState = update(state, `${campaignId}.attr.files`, allFiles);

            return nextState;
        }

        case c.SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS: {
            const { campaignId, file } = action.payload;
            const fileIds = _.get(state, `${campaignId}.attr.files`, []);

            const newFileIds = [...fileIds, file.id].filter(x => x);
            const nextState = update(state, `${campaignId}.attr.files`, newFileIds);

            return nextState;
        }

        case c.SYSTEM__CAMPAIGN_ADS_GROUPINGS__FETCH__SUCCESS: {
            const { campaignId, adGrouping, adGroupings } = action.payload;
            const campaign = state[campaignId];
            const adGroupingIds = []
                .concat(adGrouping)
                .concat(adGroupings)
                .filter(x => x)
                .map(adGrouping => adGrouping.id);

            const nextState = update(state, `${campaignId}.attr.adGroupingIds`, adGroupingIds);
            return nextState;
        }

        default:
            return state;
    }
}

export const NAME = 'advertiserForm';

function getInitialState() {
    return {
        draft: {
            name: '',
        },
    };
}

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        default: {
            return state;
        }
    }
}

import _ from 'lodash';

import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.reportCampaigns';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SYSTEM__REPORT_CAMPAIGNS__FETCH__SUCCESS': {
            const { campaign, campaigns } = action.payload;
            const campaignsAsList = []
                .concat(campaign)
                .concat(campaigns)
                .filter(c => c);

            const nextState = mergeResource(state, campaignsAsList);

            return nextState;
        }
        default:
            return state;
    }
}

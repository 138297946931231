import _ from 'lodash';

const presetTabs = [
    {
        match: /presets\/pois/,
        value: 'pois',
    },
];

export function getCurrentTab() {
    let activeTab;

    _.forEach(presetTabs, tab => {
        if (tab.match.test(window.location.pathname)) {
            activeTab = tab.value;

            return false; // Break out early
        }
    });

    return activeTab;
}

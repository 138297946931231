import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const METRIC_CONFIG = [
    {
        metricType: 'impressions',
        style: 'impressions',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Impressions',
        isCurrencyType: false,
    },
    {
        metricType: 'clicks',
        style: 'clicks',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Clicks',
        isCurrencyType: false,
    },
    {
        metricType: 'ctr',
        style: 'ctr',
        category: 'basic',
        formatType: 'percentage',
        presentationName: 'CTR',
        isCurrencyType: false,
    },
    {
        metricType: 'spend',
        style: 'spend',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billable Cost',
        isCurrencyType: true,
    },
    {
        metricType: 'ecpc',
        style: 'ecpc',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billable Cost eCPC',
        isCurrencyType: true,
    },
    {
        metricType: 'ecpm',
        style: 'ecpm',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billable Cost eCPM',
        isCurrencyType: true,
    },
    {
        metricType: 'revenue',
        style: 'revenue',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billings',
        isCurrencyType: true,
    },
    {
        metricType: 'erpm',
        style: 'erpm',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billings eCPM',
        isCurrencyType: true,
    },
    {
        metricType: 'erpc',
        style: 'erpc',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billings eCPC',
        isCurrencyType: true,
    },
];

const initialState = {
    currentSelectedMetricType: 'impressions',
    isVisibleBeaconsAtLimit: false,
    metricComponentsConfig: [],
    isClientReportPage: false,
    selectedMetrics: ['dimension', 'impressions', 'clicks', 'ctr', 'revenue'],
};

const ownerTotalMediaCostMetric = {
    metricType: 'owner_total_media_cost_local',
    style: 'owner_total_media_cost_local',
    category: 'basic',
    formatType: 'dollar',
    presentationName: 'Total Cost',
    isCurrencyType: true,
};

const Transformer = {
    createBeaconComponents(beacons) {
        const filteredBeacons = _.filter(beacons, beacon => !beacon.hide);
        if (filteredBeacons.length < 2) {
            return _(filteredBeacons)
                .map(beacon => {
                    return {
                        metricType: 'event_' + beacon.name,
                        status: 'hidden',
                        category: 'beacon',
                        presentationName: beacon.label || beacon.name,
                        isCurrencyType: false,
                        formatType: 'thousands',
                        style: 'beacon0',
                    };
                })
                .value();
        }

        return _(filteredBeacons)
            .map(beacon => {
                return {
                    metricType: 'event_' + beacon.name,
                    status: 'hidden',
                    category: 'beacon',
                    presentationName: beacon.label || beacon.name,
                    isCurrencyType: false,
                    formatType: 'thousands',
                    style: 'beacon0',
                    defaultCellValue: 0,
                };
            })
            .reverse()
            .push({
                metricType: 'overall_engagements',
                status: 'hidden',
                category: 'beacon',
                presentationName: 'Overall Engagements',
                isCurrencyType: false,
                formatType: 'thousands',
                style: 'beacon0',
            })
            .reverse()
            .value();
    },

    updateStyles(metricComponentsConfig) {
        var beaconColourCode = 0;

        return _.map(metricComponentsConfig, component => {
            switch (component.category) {
                case 'beacon':
                    const usedAllColors = beaconColourCode === MAX_BEACON_COLORS;
                    const isHidden = component.status === 'hidden';

                    if (usedAllColors || isHidden) {
                        return {
                            ...component,
                            style: 'beacon0',
                        };
                    }

                    beaconColourCode += 1;
                    return {
                        ...component,
                        style: `beacon${beaconColourCode}`,
                    };

                case 'basic':
                default:
                    return component;
            }
        });
    },
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.CAMPAIGN_REPORT__INITIALIZE: {
            const { selectedMetrics } = state;
            if (state.initialized) {
                return state;
            }
            const { campaign, isClient, isClientReportPage } = action.payload;

            const sharingSettings = campaign.sharing_settings.metrics;

            // if data fees enabled and the campaign has third-party fees, insert owner_total_media_cost_local
            var ownerTotalMediaCost = [];
            const newSelectedMetrics = selectedMetrics.slice(0);
            if (campaign.data_fees_enabled && campaign.third_party_fees.length > 0) {
                ownerTotalMediaCost = ownerTotalMediaCostMetric;
                newSelectedMetrics.push('owner_total_media_cost_local');
            }

            const metricComponentsConfig = METRIC_CONFIG.concat(ownerTotalMediaCost)
                .map(renameBillingsToRevenueOrSpend(isClientReportPage))
                .filter(config => {
                    if (_.includes(['revenue', 'erpm', 'erpc'], config.metricType)) {
                        return campaign.billing_enabled;
                    }

                    return true;
                })
                .filter(config => {
                    // This is where we enforce campaign sharing settings on metrics.
                    // We do the same for dimensions in the dimension selector
                    const sharingConfig = _.find(sharingSettings, setting => {
                        return setting.name === config.metricType;
                    });

                    return isClient || isClientReportPage ? sharingConfig.shared : true;
                });

            return {
                ...initialState,
                metricComponentsConfig,
                isClientReportPage,
                selectedMetrics: newSelectedMetrics,
            };
        }

        case c.REPORT_GEO__METRIC_SELECTOR__TOGGLE_VISIBILITY: {
            const { selectedMetrics } = state;
            const { metric } = action.payload;

            const metricIndex = selectedMetrics.indexOf(metric.metricType);
            const isMetricSelected = metricIndex !== -1;

            let newSelectedMetrics = selectedMetrics.slice(0);
            if (isMetricSelected) {
                newSelectedMetrics.splice(metricIndex, 1)
            } else {
                newSelectedMetrics.push(metric.metricType)
            }

            if (newSelectedMetrics.length === 1) {
                // Don't do anything as this is the last selected metric.
                // We need at least one metric showing.
                return state;
            }

            return {
                ...state,
                selectedMetrics: newSelectedMetrics,
                currentSelectedMetricType: metric.metricType,
            }
        }

        default:
            return state;
    }
}

function renameBillingsToRevenueOrSpend(isClient) {
    return metricConfig => {
        const clientChanges = {
            revenue: {
                presentationName: 'Spend',
            },
            erpm: {
                presentationName: 'Spend eCPM',
            },
            erpc: {
                presentationName: 'Spend eCPC',
            },
        };

        const ownerChanges = {
            revenue: {
                presentationName: 'Revenue',
            },
            erpm: {
                presentationName: 'Revenue eCPM',
            },
            erpc: {
                presentationName: 'Revenue eCPC',
            },
        };

        // ignore non-billings metrics
        if (!clientChanges[metricConfig.metricType]) {
            return metricConfig;
        }

        // apply changes
        if (isClient) {
            return {
                ...metricConfig,
                ...clientChanges[metricConfig.metricType],
            };
        } else {
            return {
                ...metricConfig,
                ...ownerChanges[metricConfig.metricType],
            };
        }
    };
}

import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { browserHistory } from 'react-router';
import flags from 'containers/flags/service';

const DealsLanding = props => {
    if (!flags.isEnabled('efb_35_curated_deals')) {
        return props.workspace;
    }

    const [currentTab, setCurrentTab] = useState(0);
    const tabs = [
        {
            label: 'Private Deals',
            value: 'privateDeals',
            link: '/apps-and-sites/inventory',
        },
        {
            label: 'Curated Deals',
            value: 'curatedDeals',
            link: '/apps-and-sites/inventory/curated-deals',
        },
    ];

    useEffect(() => {
        let tab;
        switch (location.pathname) {
            case '/apps-and-sites/inventory':
                tab = 'privateDeals';
                break;
            case '/apps-and-sites/inventory/curated-deals':
                tab = 'curatedDeals';
                break;
        }
        setCurrentTab(tab);
    }, []);

    const onChange = (event, value) => {
        setCurrentTab(value);
        const tab = tabs.find(tab => tab.value === value);
        browserHistory.push(tab.link);
    };

    return (
        <div>
            <Tabs
                value={currentTab}
                onChange={onChange}
                indicatorColor="primary"
                textColor="primary"
            >
                {tabs.map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
            {props.workspace}
        </div>
    );
};

export default DealsLanding;

import _ from 'lodash';
import VError from 'verror';
import createHttp from 'utils/http';
import notify from 'utils/notify';
import c from 'common/constants/flux-events';
import Ads from 'states/resources/ads/actions';
import Campaigns from 'states/resources/campaigns/actions';
import { fetchReportStats } from 'services/resources/stats';
import { getEnvironmentSettings } from 'services/environment';

const http = createHttp();

const fetchAdMetaData = async adId => {
    try {
        const http = createHttp();
        const query = `
            query getAdMetaData ($adId: Int) {
                ad(id: $adId) {
                    id
                    revenueModel
                    metadata {
                        progress {
                            progressData {
                                idealDeliveryPlot
                                idealDelivered
                                currentDeliveryPlot
                                currentDelivered
                                currentPacing
                                projectedDeliveryPlot
                                projectedDelivery
                                projectedPacing
                                health
                                timeAxisData
                                totalTarget
                                goal
                                revenueAtRisk
                                goalAtRisk
                                variance
                            }
                            progressDataDaily {
                                idealDeliveryPlot
                                idealDelivered
                                currentDeliveryPlot
                                currentDelivered
                                currentPacing
                                projectedDeliveryPlot
                                projectedDelivery
                                projectedPacing
                                health
                                timeAxisData
                                totalTarget
                                goal
                                revenueAtRisk
                                goalAtRisk
                                variance
                            }
                        }
                    }
                }
             }
        `;

        const variables = {
            keywords: null,
            source_type: null,
            adId: parseInt(adId),
        };

        const data = await http.graphql(query, variables);
        const res = data.ad;
        return res;
    } catch (err) {
        console.error(err);
        notify({
            error: new VError(err, 'failed to fetch ad metadata'),
            metaData: {
                campaignId,
                location: 'campaigns/progress/campaign-progress/actions: _fetchData()',
                prevAction: 'SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS',
            },
        });

        return [];
    }
};

const _fetchData = (campaignId, adId, timezone) => async (dispatch) => {
    const options = {
        campaign_id: String(campaignId),
        match: {},
        split: ['ad_id'],
        timezone,
        include_beacons: false,
        datasource: getEnvironmentSettings().getReportingDatasource(),
    };

    await fetchReportStats(options).then(response => {
        const adStats = [];

        _.each(response.stats, stats => {
            adStats.push({
                id: _.get(stats, 'split.0.group'),
                stats,
            });
        });

        dispatch({
            type: 'SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS',
            payload: {
                adStats,
            },
        });
    });

    await dispatch(Campaigns.get(campaignId))
        .then(() => {
            return Promise.all([dispatch(Ads.get(campaignId, adId)), fetchAdMetaData(adId)]);
        })
        .then(async ([ad, adMetaData]) => {
            const adWithMetadata = {
                ...ad,
                revenueModel: adMetaData.revenueModel,
                metadata: {
                    progress: adMetaData.metadata.progress,
                    tactics: ad.metadata.tactics,
                },
            };

            dispatch({
                type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                payload: {
                    campaignId: parseInt(campaignId),
                    ad: adWithMetadata,
                },
            });

            const adStats = [];

            try {
                const stats = await http.get(
                    `campaigns/${campaignId}/ads/${
                        adWithMetadata.id
                    }/livestats?keys=hour,date&sort=date`
                );

                adStats.push({
                    id: ad.id,
                    stats,
                });
                dispatch({
                    type: 'SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH_ALL__SUCCESS',
                    payload: {
                        adStats,
                    },
                });
            } catch (err) {
                console.log(`CAMPAIGN PROGRESS failed to fetch livestats for AD ID ${ad.id}`, err);
                if (window.bugsnagClient) {
                    window.bugsnagClient.notify(
                        `CAMPAIGN PROGRESS failed to fetch livestats for AD ID${ad.id}`,
                        {
                            err: JSON.stringify(err),
                        }
                    );
                }
            }
        });
};

export const fetchAdDeliveryData = (campaignId, adId, timezone) => (dispatch, getState) => {
    dispatch({
        type: c.AD_DELIVERY__LOADING,
        payload: {
            adId,
        },
    });

    return _fetchData(campaignId, adId, timezone)(dispatch, getState);
};


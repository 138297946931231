import _ from 'lodash';

export default function selector(storeState) {
    const { audienceSegments } = _.get(storeState, 'pdsCustomDashboard');
    const organizationId = _.get(storeState, 'profile.organizationId');
    const userOrganizationName = _.get(storeState, 'profile.authToken.organization.name');
    const totalSegmentCount = _.get(storeState, 'pdsCustomDashboard.totalSegmentCount');
    const isLoading = _.get(storeState, 'pdsCustomDashboard.isLoading');
    const audienceBuilderVersion = _.get(storeState, 'pdsCustomDashboard.audienceBuilderVersion');
    const restrictedSegments = _.get(storeState, 'profile.restrictedSegments', []);

    return {
        hasEnvironics: !restrictedSegments.includes('pds_environics'),
        audienceSegments,
        organizationId,
        userOrganizationName,
        totalSegmentCount,
        isLoading,
        audienceBuilderVersion,
    };
}

import _ from 'lodash';
import { getHardSeverityTacticWarnings } from 'forms/ad-form/actions';

const TITLE_MAPPING = {
    exchange: 'Exchanges',
    creative: 'Creative',
    device_os: 'Device OS',
    carrier_isp: 'Carrier / ISPs',
};

export default function selector(storeState) {
    const adFormWarnings = _.get(storeState, 'adForm.form.warnings');
    const isOpen = _.get(storeState, 'adForm.form.isWarningModalOpen');

    const processedTacticWarnings = getHardSeverityTacticWarnings({
        tacticWarnings: adFormWarnings.tactics,
    });
    const targetingWarnings = _.omit(adFormWarnings, ['tactics']);
    const filteredTargetingWarnings = _(Object.keys(targetingWarnings))
        .map(warningKey => {
            const warningDetails = adFormWarnings[warningKey];
            if (warningDetails.hasWarning && warningDetails.severity === 'hard') {
                return {
                    ...adFormWarnings[warningKey],
                    title: TITLE_MAPPING[warningKey],
                };
            }
        })
        .filter(warning => warning)
        .value();

    const hardTacticWarning = {};
    if (adFormWarnings.tactics.hasWarning && adFormWarnings.tactics.severity === 'hard') {
        hardTacticWarning.message = adFormWarnings.tactics.message;
        hardTacticWarning.hasWarning = adFormWarnings.tactics.hasWarning;
    }

    return {
        hardTacticWarning,
        tacticWarnings: processedTacticWarnings,
        targetingWarnings: filteredTargetingWarnings,
        isOpen,
    };
}

import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router';
import cn from 'classnames';
import moment from 'moment';

import flags from 'containers/flags/service';

import { ButtonGroup, NeutralButton, PrimaryButton } from 'widgets-v5/buttons';
import { Pagination, PaginationRange } from 'widgets-v5/pagination';
import Card from 'widgets-v5/card';
import StandardInput from 'widgets-v5/standard-input';
import LoadGroup from 'widgets-v5/load-group';
import TopBarLayout from 'widgets/top-bar-layout';
import GlobalRefresh from 'containers/global-refresh';
import { formatNumber_percentage, formatNumber_whole } from 'utils/formatting';
import {
    getAudienceSource,
    isAudienceUsable,
    hasMatchInformation,
    computeMatchedUsers,
    isDownloadEnabled,
    isAudienceActive,
} from 'states/resources/audiences/business-logic';
import config from '../../../../../config';

import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import { SubheadingBold, BodyText, Title, Caption } from 'widgets-v5/typography';
import InlineTooltip from 'widgets-v5/inline-tooltip';

const AUDIENCE_UPLOAD_ENABLED = config.DEV_SETTINGS_ENABLED;

class DownloadButton extends React.Component {
    render() {
        if (this.props.isLoading) {
            return (
                <div>
                    <i className="audiences-dashboard__downloadLoadIndicator fa fa-circle-o-notch" />
                </div>
            );
        }

        return (
            <div>
                <InlineTooltip tooltip="Download audience" position="bottom">
                    <NeutralButton
                        onClick={this.props.onClick}
                        icon={<i className="fa fa-lg fa-download" />}
                    />
                </InlineTooltip>
            </div>
        );
    }
}

class Layout extends React.Component {
    render() {
        return (
            <div className="audiences-dashboard">
                <TopBarLayout
                    topBar={
                        <div>
                            <div className="audiences-dashboard__page-title">
                                <div className="ef6-alignment__flex">
                                    <Title>All Audience Segments</Title>
                                    <div className="audiences-dashboard__refresh-button">
                                        <GlobalRefresh heapEvent="audience-dashboard_refresh" />
                                    </div>
                                </div>

                                <ButtonGroup className="audiences-dashboard__button-group">
                                    <PrimaryButton
                                        className="audiences-dashboard__new-audience-segment-button"
                                        onClick={this.props.goToNew}
                                        text="+ New Custom Segment"
                                        heapEvent="audience-dashboard_new-audience_navigate"
                                    />
                                </ButtonGroup>
                            </div>
                            <div className="ef6-alignment__space-between ef6-alignment__flex-center">
                                <StandardInput
                                    className="audiences-dashboard__search-input"
                                    placeholder="Search..."
                                    heapEvent="audience-dashboard_seach"
                                    value={this.props.filters.search || ''}
                                    onChange={this.props.search}
                                />
                                <Caption>
                                    <a
                                        style={{ color: '#00897B', textDecoration: 'underline' }}
                                        target="_blank"
                                        href="https://bit.ly/ef-segments-matrix"
                                    >
                                        <i
                                            style={{ paddingRight: 5, verticalAlign: 'middle' }}
                                            className="fa fa-external-link"
                                        />
                                        For more info on Pelmorex Audience Segments click here
                                    </a>
                                </Caption>
                            </div>
                        </div>
                    }
                    workspace={
                        <div className="audiences-dashboard__wrapper">
                            <LoadGroup isLoading={this.props.isLoading}>
                                <Card padding={0} margin={'16px 26px'}>
                                    <div>
                                        <ComposableDataTable>
                                            <Row header>
                                                <Cell header>
                                                    <PaginationRange
                                                        itemsPerPage={this.props.itemsPerPage}
                                                        currentPage={this.props.currentPage}
                                                        maxResults={this.props.maxResults}
                                                    />
                                                </Cell>
                                                <Cell header>Source</Cell>
                                                <Cell header>Ready to use?</Cell>
                                                <Cell header align="right">
                                                    Matched Users
                                                </Cell>
                                                <Cell header align="right">
                                                    Last Refreshed
                                                </Cell>
                                                <Cell header align="center">
                                                    Download
                                                </Cell>
                                            </Row>
                                            {this.props.audiences.length === 0 ? (
                                                <Row key="noData">
                                                    <Cell colSpan={7} noData>
                                                        No Audiences Found
                                                    </Cell>
                                                </Row>
                                            ) : (
                                                _.map(this.props.audiences, audience => (
                                                    <Row key={audience.id}>
                                                        <Cell>
                                                            {// This condition controls who can edit a segment. PDS segments are never allowed to be edited, unless it's a 'pds_custom' segment, created for a specific client.
                                                            audience.source_type !== 'pds_custom' &&
                                                            audience.source_type.match(/pds_/) ? (
                                                                <BodyText>{audience.name}</BodyText>
                                                            ) : (
                                                                <Link
                                                                    to={`/audiences/${audience.id}`}
                                                                >
                                                                    <BodyText type="link">
                                                                        {audience.name}
                                                                    </BodyText>
                                                                </Link>
                                                            )}
                                                        </Cell>
                                                        <Cell>{getAudienceSource(audience)}</Cell>
                                                        {isAudienceUsable(audience) ? (
                                                            <Cell>
                                                                <i
                                                                    className={cn(
                                                                        'fa',
                                                                        'fa-fw',
                                                                        'fa-check-circle',
                                                                        'audiences-dashboard__reports-table-checkmark'
                                                                    )}
                                                                />{' '}
                                                                Ready
                                                            </Cell>
                                                        ) : (
                                                            <Cell>
                                                                <i
                                                                    className={cn(
                                                                        'fa',
                                                                        'fa-fw',
                                                                        'fa-times-circle'
                                                                    )}
                                                                />{' '}
                                                                Not Yet
                                                            </Cell>
                                                        )}

                                                        <Cell>
                                                            <div className="audiences-dashboard__reports-table-matched-users">
                                                                {hasMatchInformation(audience)
                                                                    ? formatNumber_whole(
                                                                          computeMatchedUsers(
                                                                              audience
                                                                          )
                                                                      )
                                                                    : 'N/A'}
                                                                {hasMatchInformation(audience) &&
                                                                isAudienceActive(audience) ? (
                                                                    <i className="fa fa-fw fa-refresh audiences-dashboard__reports-table-matched-users-active" />
                                                                ) : null}
                                                            </div>
                                                        </Cell>
                                                        <Cell align="right">
                                                            {audience.last_dmp_update === null ? (
                                                                <span>N/A</span>
                                                            ) : (
                                                                <InlineTooltip
                                                                    tooltip={moment(
                                                                        audience.last_dmp_update
                                                                    ).format('MMM Do YYYY')}
                                                                    position="bottom"
                                                                >
                                                                    {moment(
                                                                        audience.last_dmp_update
                                                                    ).fromNow()}
                                                                </InlineTooltip>
                                                            )}
                                                        </Cell>
                                                        <Cell align="center" style={{ padding: 0 }}>
                                                            {isDownloadEnabled(audience) ? (
                                                                <DownloadButton
                                                                    onClick={() =>
                                                                        this.props.downloadAudience(
                                                                            audience.id
                                                                        )
                                                                    }
                                                                    isLoading={
                                                                        this.props
                                                                            .audienceIdBeingDownloaded ===
                                                                        audience.id
                                                                    }
                                                                />
                                                            ) : null}
                                                        </Cell>
                                                    </Row>
                                                ))
                                            )}
                                        </ComposableDataTable>
                                        <Pagination
                                            heapEvent="audience-dashboard_pagination_navigate"
                                            url="/audiences"
                                            query={this.props.filters}
                                            padding={2}
                                            itemsPerPage={this.props.itemsPerPage}
                                            currentPage={this.props.currentPage}
                                            maxResults={this.props.maxResults}
                                        />
                                    </div>
                                </Card>
                            </LoadGroup>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Layout;

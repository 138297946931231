import React from 'react';

export default class extends React.Component {
    render() {
        return (
            <dd className="ef3-adSummaryView_description">
                {this.props.mainRegion}
                {this.props.excludedRegions.map((exclude, i) => (
                    <p key={exclude + i}>
                        <i className="excludedIcon fa fa-times-circle-o fa-fw" />
                        {exclude}
                    </p>
                ))}
            </dd>
        );
    }
}

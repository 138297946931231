export const rolePresentationDictionary = {
    revoked: '(Revoked)',
    analyst: 'Analyst',
    media_planner: 'Media Planner',
    campaign_manager: 'Campaign Manager',
    admin: 'Super Admin',
    account_admin: 'Account Admin',
    user_admin: 'User Admin',
    read_only_super_admin: 'Read-only Super Admin',
};

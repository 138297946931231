import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';

const AppLists = {
    getAll(orgId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.SYSTEM__APP_LISTS__FETCH,
                payload: {
                    orgId,
                },
            });
            return http()
                .get(`organizations/${orgId}/applists`)
                .then(
                    appLists => {
                        dispatch({
                            type: c.SYSTEM__APP_LISTS__FETCH__SUCCESS,
                            payload: {
                                orgId,
                                appLists,
                            },
                        });
                        return appLists;
                    },
                    err => {
                        console.error(`GET ALL applists error`, err);

                        throw err;
                    }
                );
        };
    },

    get(orgId, appListId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.SYSTEM__APP_LIST__FETCH,
                payload: {
                    orgId,
                    appListId,
                },
            });
            return http()
                .get(`organizations/${orgId}/applists/${appListId}`)
                .then(
                    appList => {
                        dispatch({
                            type: c.SYSTEM__APP_LIST__FETCH__SUCCESS,
                            payload: {
                                orgId,
                                appList,
                            },
                        });
                        return appList;
                    },
                    err => {
                        console.error(`GET applists error`, err);

                        throw err;
                    }
                );
        };
    },

    update(orgId, listId, payload) {
        return (dispatch, getState) => {
            return http()
                .patch(`organizations/${orgId}/applists/${listId}`, payload)
                .then(
                    appList => {
                        dispatch({
                            type: c.SYSTEM__APP_LIST__FETCH__SUCCESS,
                            payload: {
                                orgId,
                                appList,
                            },
                        });
                        return appList;
                    },
                    err => {
                        console.error(`UPDATE applists error`, err);

                        throw err;
                    }
                );
        };
    },

    patch(campaignId, applist_settings) {
        return (dispatch, getState) => {
            return http()
                .patch(`campaigns/${campaignId}/`, applist_settings)
                .then(
                    campaign => {
                        return campaign;
                    },
                    err => {
                        console.error(`PATCH applists error`, err);

                        throw err;
                    }
                );
        };
    },

    delete(orgId, appListId) {
        return (dispatch, getState) => {
            return (
                http()
                    // .setIfMatch(etag)
                    .del(`organizations/${orgId}/applists/${appListId}`)
                    .then(
                        campaign => {
                            return campaign;
                        },
                        err => {
                            console.error(`DELETE applists error`, err);

                            throw err;
                        }
                    )
            );
        };
    },
};

export default AppLists;

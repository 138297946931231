import _ from 'lodash';
import billingTierSchema from './billing-tiers-schema';
import UUID from 'uuid';

const initialState = {
    isFormOpen: false,
    isLoading: true,
    draft: {},
    users: [],
    relationship: '',
    errors: {},
    isSubmitting: false,
    showErrors: false,
    activeFormSection: 'basic',
};

const options = {
    allowUnknown: true,
    abortEarly: false,
};

const TIER_INCREMENT = 0.01;

const processErrors = errors => {
    if (!errors.error) {
        return {};
    }

    const result = {};

    _.each(errors.error.details, err => {
        const { path, message } = err;
        result[path.join('.')] = message;
    });
    return result;
};

const validateDraft = state => {
    const errors = billingTierSchema.validate(state.draft, options);
    const processedErrors = processErrors(errors);

    return {
        ...state,
        errors: processedErrors,
    };
};

export const NAME = 'adminOrgForm';

const handlers = {
    ADMIN_ORG_FORM__CHANGE_SECTION: (state, action) => {
        return {
            ...state,
            activeFormSection: action.payload.section,
        };
    },
    ADMIN_ORG_FORM__OPEN_FORM: state => {
        return {
            ...state,
            isFormOpen: true,
        };
    },
    ADMIN_ORG_FORM__CLOSE_FORM: () => {
        return {
            ...initialState,
        };
    },
    ADMIN_ORG_FORM__INIT_START: (state, action) => {
        return {
            ...state,
            isLoading: true,
            orgId: action.payload.orgId,
            mode: action.payload.mode,
        };
    },
    ADMIN_ORG_FORM__INIT_SUCCESS: (state, action) => {
        return {
            ...state,
            isLoading: false,
            draft: action.payload.draft,
            relationship: action.payload.relationship,
            users: action.payload.users,
        };
    },
    ADMIN_ORG_FORM__UPDATE_DRAFT: (state, action) => {
        const { field, value } = action.payload;
        return {
            ...state,
            draft: {
                ...state.draft,
                [field]: value,
            },
        };
    },
    ADMIN_ORG_FORM__SUBMIT_DRAFT_START: state => {
        return {
            ...state,
            isSubmitting: true,
        };
    },
    ADMIN_ORG_FORM__SHOW_ERRORS: state => {
        return {
            ...state,
            showErrors: true,
            isSubmitting: false,
        };
    },
    ADMIN_ORG_FORM__SUBMIT_DRAFT_SUCCESS: state => {
        return {
            ...state,
            isSubmitting: false,
        };
    },
    ADMIN_ORG_FORM__SUBMIT_DRAFT_FAIL: (state, action) => {
        const serverError = action.payload.error;
        const nameError = _.find(serverError.body, error => error.field === 'name');
        const newErrors = { ...state.errors };

        if (!_.isNil(nameError)) {
            newErrors.name = nameError.message;
        }
        return {
            ...state,
            isSubmitting: false,
            showErrors: true,
            errors: newErrors,
        };
    },
    ADMIN_ORG_FORM__ADD_BILLING_TIER: (state, action) => {
        const { type } = action.payload;
        const existingTiers = [...state.draft[type]];

        const existingLastTierEntry = _.last(existingTiers);

        const modifiedPrevMaxTier = {
            ...existingLastTierEntry,
            max: existingLastTierEntry.min + TIER_INCREMENT,
        };

        const tierToAdd = {
            techFee: null,
            min: modifiedPrevMaxTier.max + TIER_INCREMENT,
            max: -1,
            id: UUID.v4(),
        };

        const startingBillingTiers = _.filter(
            existingTiers,
            tier => tier.id !== existingLastTierEntry.id
        );
        const newBillingTiers = _.concat(startingBillingTiers, [modifiedPrevMaxTier, tierToAdd]);

        return {
            ...state,
            draft: {
                ...state.draft,
                [type]: newBillingTiers,
            },
        };
    },
    ADMIN_ORG_FORM__DELETE_BILLING_TIER: (state, action) => {
        const { type, tierId } = action.payload;

        const existingTiers = [...state.draft[type]];
        const filteredTiers = _.filter(existingTiers, tier => tier.id !== tierId);

        const newTiers = _.map(filteredTiers, (tier, index) => {
            const prevTier = filteredTiers[index - 1];
            const nextTier = filteredTiers[index + 1];

            const newMin = index === 0 ? 0 : prevTier.max + TIER_INCREMENT;
            const newMax = nextTier ? nextTier.min - TIER_INCREMENT : -1;

            return {
                ...tier,
                min: newMin,
                max: newMax,
            };
        });

        return {
            ...state,
            draft: {
                ...state.draft,
                [type]: newTiers,
            },
        };
    },
    ADMIN_ORG_FORM__EDIT_BILLING_TIER_MAX: (state, action) => {
        const { type, tierId, newMax } = action.payload;
        const existingTiers = [...state.draft[type]];
        const tierIndexToChange = _.findIndex(existingTiers, tier => tier.id === tierId);

        const tiersWithNewMax = _.map(existingTiers, tier => {
            if (tier.id === tierId) {
                return {
                    ...tier,
                    max: newMax,
                };
            }
            return tier;
        });

        const recreatedTiersWithNewMinMax = [];

        _.forEach(tiersWithNewMax, (currentTier, currentIndex) => {
            if (currentTier.id === tierId) {
                recreatedTiersWithNewMinMax.push(currentTier);
            } else if (currentIndex < tierIndexToChange) {
                recreatedTiersWithNewMinMax.push(currentTier);
            } else if (currentIndex > tierIndexToChange) {
                const prevTier = recreatedTiersWithNewMinMax[currentIndex - 1];
                const newMin = prevTier ? prevTier.max + TIER_INCREMENT : 0;
                const isMinGreaterThanMax = newMin > currentTier.max && currentTier.max !== -1;
                const newMax = isMinGreaterThanMax ? newMin + TIER_INCREMENT : currentTier.max;

                recreatedTiersWithNewMinMax.push({
                    ...currentTier,
                    min: newMin,
                    max: newMax,
                });
            }
        });

        return {
            ...state,
            draft: {
                ...state.draft,
                [type]: recreatedTiersWithNewMinMax,
            },
        };
    },
    ADMIN_ORG_FORM__EDIT_BILLING_TIER_FEE: (state, action) => {
        const { type, tierId, value } = action.payload;
        const tiers = [...state.draft[type]];

        const tiersWithNewTechFee = _.map(tiers, tier => {
            if (tier.id === tierId) {
                return {
                    ...tier,
                    techFee: value,
                };
            }
            return tier;
        });

        return {
            ...state,
            draft: {
                ...state.draft,
                [type]: tiersWithNewTechFee,
            },
        };
    },
};

export default function reducer(state = initialState, action) {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
        if (action.type !== 'ADMIN_ORG_FORM__SUBMIT_DRAFT_FAIL') {
            nextState = validateDraft(nextState);
        }
    }

    return nextState;
}

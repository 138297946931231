import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import $ from 'jquery';

class PopUp extends React.Component {
    static propTypes = {
        getContents: PropTypes.func,
        contents: PropTypes.node,
        children: PropTypes.node,
        position: PropTypes.oneOf(['left', 'right', 'bottom']),
    };

    static defaultProps = { position: 'left' };

    state = {
        visibility: 'hidden',
    };

    // NOTE, we have had to override the react event system and use the native
    // event api to close the pop up by clicking outside of the popup

    componentDidMount() {
        document.body.addEventListener('click', this.onBackgroundClick);
        this.refs.popUp.addEventListener('click', this.onForegroundClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.onBackgroundClick);
        this.refs.popUp.removeEventListener('click', this.onForegroundClick);
    }

    onForegroundClick = e => {
        e.isForegroundClick = true;
    };

    onBackgroundClick = e => {
        if (!e.isForegroundClick) {
            this.setState({ visibility: 'hidden' });
        }
    };

    onClose = e => {
        this.setState({ visibility: 'hidden' });
    };

    open = () => {
        // TODO, have the pop up fit to screen and the arrow move to match the
        // child button.

        this.$child = $(this.refs.child);
        this.$popUp = $(this.refs.popUp);

        let left, top;

        if (this.props.position === 'right') {
            left = this.$child.position().left + this.$child.width();
        } else if (this.props.position === 'bottom') {
            left = this.$child.position().left - this.$popUp.width() / 2;
            top = this.$child.position().top + this.$popUp.height() / 1.5;
        } else {
            left = this.$child.position().left - this.$popUp.width();
        }
        setTimeout(() => this.setState({ visibility: 'visible', left, top }));
    };

    render() {
        return (
            <div className="ef5-pop-up">
                <div onClick={this.open} ref="child" className="ef5-pop-up__child-element">
                    {this.props.children}
                </div>
                <div
                    ref="popUp"
                    className={classnames(
                        this.props.className,
                        'ef5-pop-up__content-section',
                        `ef5-pop-up--is-${this.props.position}-position`
                    )}
                    style={{
                        visibility: this.state.visibility,
                        left: this.state.left,
                        top: this.state.top,
                    }}
                >
                    <div className="ef5-pop-up__content">
                        {this.props.getContents
                            ? this.props.getContents(
                                  this.state.visibility === 'visible',
                                  this.onClose
                              )
                            : this.props.contents}
                    </div>
                </div>
            </div>
        );
    }
}

export default PopUp;

import { regEx } from '../utils/regular-expression-library';

const soloHtmlTag = regEx.soloHtmlTag;

export const clickMacroValidator = function(value) {
    /**
     * Currently recommended macros: %%CLICKTHROUGH%%, %%CLICKTHROUGH_ESC%%, %%CLICKTRACKER%%
     * Recently deprecated macros: %%REDIRECT%%, %%REDIRECT_ESC%%
     * Legacy macros: %%REDIRECT_URL%%, %%REDIRECT_URL_ESC%%, %%CLICK_URL%%, %%CLICK_URL_ESC%%
     */

    const recommendedMacrosRegex = /%%(CLICKTHROUGH|CLICKTHROUGH_ESC|CLICKTRACKER)%%/g;
    const deprecatedMacrosRegex = /%%(REDIRECT|REDIRECT_ESC)%%/g;
    const legacyMacros = /%%(REDIRECT_URL|REDIRECT_URL_ESC|CLICK_URL|CLICK_URL_ESC)%%/g;

    if (
        !recommendedMacrosRegex.test(value) &&
        !deprecatedMacrosRegex.test(value) &&
        !legacyMacros.test(value)
    ) {
        return `HTML Markup must contain the proper click macro. See Creative Macros for help.`;
    }
};

export const templateValidator = function(value) {
    /**
     * New custom Creatives use template tags like
     * <ef-creative> <ef-click>
     * And it doesn't have a <meta>
     * So, for now, if we detect the new template
     * so, the validations are different
     * */

    const clickMacroRegex = /clickMacro=['"]%%CLICKTHROUGH%%['"]/g;
    const creativeIdRegex = /creativeId=['"]%%CREATIVE_ID%%['"]/g;
    const rinfMacroRegex = /rinfMacro=['"]%%RINF%%['"]/g;

    if (!clickMacroRegex.test(value)) {
        return 'clickMacro attribute is missing';
    }

    if (!creativeIdRegex.test(value)) {
        return 'creativeId attribute is missing';
    }

    if (!rinfMacroRegex.test(value)) {
        return 'rinfMacro attribute is missing';
    }
};

export const oldTemplateValidator = function(value) {
    const doubleQuoteImpressionsRegex = /impressions="%%IMPRESSION_URLS%%"/g;
    const impressionsRegex = /impressions='%%IMPRESSION_URLS%%'/g;

    const doubleQuoteRinfRegex = /rinf="%%RINF%%"/g;
    const rinfRegex = /rinf='%%RINF%%'/g;

    // Base <Meta> tag validation
    if (soloHtmlTag('meta').test(value) === false) {
        return 'HTML Markup requires a valid meta tag';
    }

    // Impressions attribute validation
    if (doubleQuoteImpressionsRegex.test(value)) {
        return `HTML Markup's meta tag's "impressions" attribute must use single quotes`;
    }

    if (impressionsRegex.test(value) === false) {
        return `HTML Markup's meta tag requires a valid "impressions" attribute`;
    }

    // Required for a valid match. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex
    impressionsRegex.lastIndex = 0;

    let impressionsMatches = value.match(impressionsRegex);
    if (impressionsMatches.length > 1) {
        return `HTML Markup's meta tag must only have one "impressions" attribute`;
    }

    // Rinf attribute validation
    if (doubleQuoteRinfRegex.test(value)) {
        return `HTML Markup's meta tag's "rinf" attribute must use single quotes`;
    }

    if (rinfRegex.test(value) === false) {
        return `HTML Markup's meta tag requires an "rinf" attribute`;
    }

    // Required for a valid match. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex
    rinfRegex.lastIndex = 0;

    const rinfMatches = value.match(rinfRegex);
    if (rinfMatches.length > 1) {
        return `HTML Markup's meta tag must only have one "rinf" attribute`;
    }
};

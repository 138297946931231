import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { can } from 'services/can';
import constants from '../../../../../config';
import DeleteConversionWarning from './modules/delete-conversion-warning';
import { isCampaignCpaOptimized } from 'states/resources/campaigns/business-logic';

import {
    initializeConversionsDraft,
    openManageConversionsView,
    changeField,
    addNewConversion,
    cancelEdit,
    deleteConversion,
    saveConversions,
    toggleOptimization,
} from './actions';

import selector from './selector';

import CampaignOverviewConversionsLayout from './layout';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';
import { PermissionsMapping } from 'common/constants/role-intents';

const EF_PIXELS_KEY_IN_LOCAL_STORAGE = 'ENGAGE_FRONT_PIXELS_METADATA';

const storeConversionsInLocalStorage = ({ conversions, campaign }) => {
    const value = _.map(conversions, conversion => ({
        advertiserId: conversion.advertiser ? conversion.advertiser.id : 'None',
        campaignId: conversion.advertiser ? 'None' : campaign.id.toString(),
        reportingName: conversion.reporting_name,
        eventName: conversion.event_name,
        isTestModeOn: true,
    }));

    localStorage.setItem(EF_PIXELS_KEY_IN_LOCAL_STORAGE, JSON.stringify(value));
};

const removeConversionsInLocalStorage = () => {
    localStorage.removeItem(EF_PIXELS_KEY_IN_LOCAL_STORAGE);
};

const CampaignOverviewConversions = props => {
    const {
        campaign,
        dispatch,
        params: { campaignId },
        draft,
        isLoading,
        isSaving,
        errors,
        showErrors,
        mode,
        isDeleteConversionWarningOpen,
        deletedConversionWithAffectedAdLines,
        ownOrganization,
    } = props;

    useEffect(() => {
        dispatch(initializeConversionsDraft(parseInt(campaignId)));
    }, []);

    let headers = [];

    if (mode === 'edit') {
        if (isCampaignCpaOptimized(campaign)) {
            headers.push('Optimize for Budget Allocation');
        }
        headers.push('Pixel Name', 'Name', 'Attribution Model', 'Event Parameters', 'Delete');
    } else {
        if (isCampaignCpaOptimized(campaign)) {
            headers.push('Optimize for Budget Allocation');
        }
        headers.push(
            'Pixel Name',
            'Name',
            'Attribution Model',
            'Attribution Window',
            'Event Parameters',
            'Actions'
        );
    }

    headers = _.filter(headers, header => header !== 'Pixel Name');

    const attributionWindowOptions = [
        { label: '1', value: 1 },
        { label: '7', value: 7 },
        { label: '14', value: 14 },
        { label: '28', value: 28 },
        { label: '30', value: 30 },
    ];

    const handleOpenManageConversionsView = () => {
        dispatch(openManageConversionsView());
    };

    const conversionsColumnClasses = _.map(
        headers,
        (header, index) => `ef3-campaign-overview-conversions__${mode}-mode-column-${index + 1}`
    );
    const manageButtonEnabled = mode !== 'edit';

    const handleChange = fieldName => {
        return _.debounce((conversion, value) => {
            const id = conversion.id;
            dispatch(changeField({ id, fieldName, value }));
        }, 700);
    };

    const handleNonTextFieldChange = fieldName => (conversion, value) => {
        const id = conversion.id;
        dispatch(changeField({ id, fieldName, value }));

        if (fieldName === 'use_click_through' && !value) {
            dispatch(changeField({ id, fieldName: 'click_through_attribution_window', value: 14 }));
        }

        if (fieldName === 'use_view_through' && !value) {
            dispatch(changeField({ id, fieldName: 'view_through_attribution_window', value: 14 }));
        }
    };

    const handleDeleteConversion = conversion => {
        dispatch(deleteConversion(conversion.id));
    };

    const handleAddConversionClick = () => {
        dispatch(addNewConversion());
    };

    const handleSaveEdit = () => {
        dispatch(saveConversions(parseInt(campaignId)));
    };

    const handleCancelEdit = () => {
        dispatch(cancelEdit(parseInt(campaignId)));
    };

    const handleAdLineLevelConversionCheck = conversion => {
        if (campaign.ads && campaign.ads.length === 0) {
            handleDeleteConversion(conversion);
            return;
        }

        const affectedAds = _.filter(
            campaign.ads,
            ad =>
                ad.rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForConversions &&
                (ad.conversionsForCreativeOptimization.length === 0 ||
                    _.includes(ad.conversionsForCreativeOptimization, conversion.event_name))
        ).map(({ id }) => id);

        if (affectedAds.length === 0) {
            handleDeleteConversion(conversion);
            return;
        }

        const deletedConversionWithAffectedAdLines = {
            ...conversion,
            affectedAds,
        };

        dispatch({
            type: 'OVERVIEW_CONVERSIONS__SHOW_CONVERSIONS_DELETION_WARNING',
            payload: {
                deletedConversionWithAffectedAdLines,
            },
        });
    };

    const generateThirdPartyPixel = conversion => {
        const baseUri = constants.ENGAGEFRONT_ADSERVER_THIRDPARTY_PIXEL_BASEURI;

        const pixelData = {
            camp_id: campaignId,
            event_name: conversion.event_name,
        };

        return `${baseUri}${encodeURIComponent(JSON.stringify(pixelData))}`;
    };

    const handleToggleOptimization = (conversion, checked) => {
        dispatch(toggleOptimization(conversion, checked));
    };

    const handleConfirmConversionDeletion = () => {
        handleDeleteConversion(deletedConversionWithAffectedAdLines);
    };

    const handleCancelConversionDeletion = () => {
        handleCancelEdit();
    };

    const conversions = _.concat([], campaign.conversions, campaign.advertiserConversions).filter(
        conversion => conversion
    );

    const setTestModeOff = () => {
        removeConversionsInLocalStorage();
    };

    const setTestModeOn = () => {
        if (campaign && campaign.id && conversions && conversions.length > 0) {
            storeConversionsInLocalStorage({ conversions, campaign });
        }
    };

    const [isInTestMode, changeTestMode] = useState(false);

    const handleTestModeChange = () => {
        const originalValue = isInTestMode;

        changeTestMode(!originalValue);

        if (!originalValue) {
            setTestModeOn();
        }

        if (originalValue) {
            setTestModeOff();
        }
    };

    useEffect(() => {
        if (isInTestMode) {
            setTestModeOn();
        }

        return () => {
            setTestModeOff();
        };
    }, [campaign.conversions, campaign.advertiserConversions]);

    return (
        <React.Fragment>
            <CampaignOverviewConversionsLayout
                isInTestMode={isInTestMode}
                onTestModeChange={handleTestModeChange}
                hasConversions={conversions.length > 0}
                campaign={campaign}
                conversionsColumnClasses={conversionsColumnClasses}
                campaignId={campaignId}
                draft={draft}
                isLoading={isLoading}
                isSaving={isSaving}
                errors={errors}
                showErrors={showErrors}
                mode={mode}
                manageButtonEnabled={manageButtonEnabled}
                showManageButton={can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT)}
                onOpenManageConversionsView={handleOpenManageConversionsView}
                headers={headers}
                onToggleOptimization={handleToggleOptimization}
                onReportingNameChange={handleChange('reporting_name')}
                onDynamicDataSubeventNameChange={handleChange('dynamic_data_subevent_name')}
                onDynamicDataValueNameChange={handleChange('dynamic_data_value_name')}
                onEventNameChange={handleChange('event_name')}
                onDeleteConversion={handleAdLineLevelConversionCheck}
                onAddConversion={handleAddConversionClick}
                onSaveEdit={handleSaveEdit}
                onCancelEdit={handleCancelEdit}
                onGenerateThirdPartyPixel={generateThirdPartyPixel}
                attributionWindowOptions={attributionWindowOptions}
                onNonTextFieldChange={handleNonTextFieldChange}
                ownOrganization={ownOrganization}
            />
            <DeleteConversionWarning
                isOpen={isDeleteConversionWarningOpen}
                deletedConversionWithAffectedAdLines={deletedConversionWithAffectedAdLines}
                handleConfirm={handleConfirmConversionDeletion}
                handleCancel={handleCancelConversionDeletion}
            />
        </React.Fragment>
    );
};

export default withRouter(connect(selector)(CampaignOverviewConversions));

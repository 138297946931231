import _ from 'lodash';
import numeral from 'numeral';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import { getRangePositionToNow } from 'common/date-calculator';
import { mapFirst, flatMap } from 'utils/enum';
import { calcTotalStats, getAsAbsolute, getAsPercentage } from 'services/resources/stats';
import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
    formatNumber_wholeFixed,
} from 'utils/formatting';

export default function selector(storeState, props) {
    const isInitialized = _.get(storeState, 'reportChannel.page.isInitialized', false);

    if (!isInitialized) {
        return {
            isInitialized,
        };
    }

    const { campaignId } = props.params;

    const componentItems = _.get(storeState, 'reportChannel.components.items', []);

    if (componentItems.length === 0) {
        return {};
    }
    const pivotTableState = _.get(storeState, `reportChannel.pivotTable`);
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);

    const dateRange = _.get(storeState, `reportChannel.dateRange`);

    const components = _.get(storeState, `reportChannel.components.items`);
    const componentDict = _.reduce(
        components,
        (acc, component) => ({
            ...acc,
            [component.type]: []
                .concat(acc[component.type])
                .concat(component)
                .filter(x => x),
        }),
        {}
    );

    const nextPivotTableState = filterVisibleColumns(pivotTableState);

    const scopeComponents = scopeComponentsSelector(componentDict.scope, storeState, campaignId);

    const selectedScopeTab = _.get(storeState, `reportOverview.activeScopeTab`);
    const activeScopeTab = selectedScopeTab
        ? // If user manually set the active scope
          selectedScopeTab
        : // If user has not selected anything (report just initalized) and value is null
          scopeComponents && scopeComponents[0] && scopeComponents[0].grouping;

    return {
        isInitialized,
        campaignId,
        pivotTableState: nextPivotTableState,
        campaign,
        dictionary: createDictionary(storeState, props),
        dateRange,

        campaignRelationship: getRelationship(storeState, props),
        selectedMetric: getSelectedMetric(storeState, props),

        scopeFilter: scopeFilterSelector(storeState, campaignId),
        scopeComponents,
        activeScopeTab,

        organizationTimezone: storeState.profile.organizationTimezone,
    };
}

function filterVisibleColumns(pivotTableState) {
    /*
    if (!pivotTableState) {
        return pivotTableState;
    }

    const lastSplit = _.last(pivotTableState.pivotTable.splits);

    if (!lastSplit) {
        return pivotTableState;
    }

    // When the last split is 'date', do not show the 'type' column
    if (lastSplit.name === 'date') {
        return {
            ...pivotTableState,
            columns: pivotTableState.columns.filter(c => c.name !== 'type'),
        };
    }
    */

    return pivotTableState;
}

function getRelationship(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`, {});
    const userOrganization = _.get(storeState, 'profile.organizationId');

    const isClient = !!_.find(campaign.clients, { organization: userOrganization });
    const isOwner = campaign.owner === userOrganization;

    return {
        isOwner,
        isClient,
    };
}
function getSelectedMetric(storeState, props) {
    const { campaignId } = props.params;

    const metricComponentsConfig = _.get(
        storeState,
        `reportChannel.metricSelector.metricComponentsConfig`
    );
    const selectedMetric = _.find(metricComponentsConfig, { status: 'selected' });

    return selectedMetric;
}

function scopeComponentsSelector(componentsRaw, storeState, campaignId) {
    const components = adGroupSelector(componentsRaw, storeState, campaignId);

    return components;
}

function adGroupSelector(componentsRaw, storeState, campaignId) {
    const reportSession = _.get(storeState, `reportChannel`, {});
    const dictionary = _.get(storeState, `reportChannel.dictionary`);
    const metricComponentsConfig = _.get(
        storeState,
        `reportChannel.metricSelector.metricComponentsConfig`,
        []
    );

    return _.map(componentsRaw, function(component) {
        const grouping = component.groupings[0];

        const isLoading = _.get(
            storeState,
            `resources.stats.campaigns.report.channel.${campaignId}.${component.id}.isLoading`,
            false
        );
        const componentResource = _.get(
            storeState,
            `resources.stats.campaigns.report.channel.${campaignId}.${component.id}`
        );
        const componentSelection = _.get(reportSession, `${component.type}Filter.${grouping}`, []);
        const unitType = _.get(reportSession, `controls.unitType`, 'absolute');
        const metricType = _.get(
            storeState,
            `reportChannel.metricSelector.currentSelectedMetricType`,
            'impressions'
        );

        if (!componentResource) {
            return null;
        }

        const statsLookup = _.reduce(
            componentResource.stats,
            (acc, stat) => ({
                ...acc,
                [_.last(stat.split).group]: stat,
            }),
            {}
        );

        const statsTotal = calcTotalStats(componentResource.stats);

        // const distinctValues = {...componentResource.distinctValues};
        const distinctValues = _.reduce(
            componentResource.stats,
            (acc, stat) => {
                const dimension = stat.split[0].dimension;

                const dv = acc[dimension] ? acc[dimension] : [];

                return {
                    ...acc,
                    [dimension]: dv.concat(stat.split[0].group),
                };
            },
            {}
        );

        // Key of distinctValues, which is the grouping name ( Gender, age group, region etc).
        const distinctValues_key = Object.keys(distinctValues)[0];

        const distinctValues_value_temp = []; // use to store subsequence sort collection
        const distinctValues_value = _(distinctValues[distinctValues_key])
            .map(grouping_item => ({
                // Transforming [F, M, D] ---> [{}, {}, {}]
                grouping_item,
                groupingName: grouping,
                presentation: _.get(
                    dictionary,
                    `${grouping}.${grouping_item}.value`,
                    'Not Available'
                ),
                temp: '', // use to store temporary country name
            }))
            .sort((a, b) => a.presentation.localeCompare(b.presentation)) // Sort alphabatically
            .map(grouping_item => {
                // For geo_country we want to sort all the unknown by contry,
                // so we push all the Unknown into the temp array to be sorted again later
                if (
                    grouping_item.groupingName === 'geo_country_region' &&
                    grouping_item.presentation === 'Not Available'
                ) {
                    const countryCode = grouping_item.grouping_item.split('-')[0];
                    const countryName = _.get(
                        dictionary,
                        `geo_country.${countryCode}.value`,
                        'Not Available'
                    );
                    const grouping_item_new = { ...grouping_item, temp: countryName };
                    distinctValues_value_temp.push(grouping_item_new);
                    return void 0;
                } else {
                    return grouping_item;
                }
            })
            .filter(x => x) // removed undefined items
            .value();

        // Sort all the Unknown by country name.
        const distinctValues_value_tempSorted = distinctValues_value_temp
            .sort((a, b) => a.temp.localeCompare(b.temp))
            .map(grouping_item => ({
                ...grouping_item,
                presentation: 'Not Available (' + grouping_item.temp + ')',
            }));

        // Merge the first sort and second sort.
        const distinctValues_value_final = distinctValues_value.concat(
            distinctValues_value_tempSorted
        );

        const distinctValues_sorted = {
            ...componentResource.distinctValue,
            distinctValues_key: distinctValues_value_final,
        };

        const groups = mapFirst(distinctValues_sorted, []).map(item => {
            const dv = item.grouping_item;
            const presentationName = item.presentation;
            const stat = statsLookup[dv];

            if (!stat) {
                return {
                    id: dv,
                    visible: presentationName === 'Not Available' ? false : true,
                    grouping: grouping,
                    presentationName: presentationName,
                    selected: _.includes(componentSelection, dv),
                    statsMetrics: '-',
                    metricValue: 0,
                };
            }

            const statAsAbsolute = getAsAbsolute(stat);
            const metricValue = statAsAbsolute[metricType];
            const metricValueAsPercentage = getAsPercentage(statAsAbsolute, statsTotal)[metricType];

            const statsMetricsFormatted = _.reduce(
                metricComponentsConfig,
                (acc, metricConfig) => {
                    const value = statAsAbsolute[metricConfig.metricType];
                    return {
                        ...acc,
                        [metricConfig.metricType]: formatValue(value, metricConfig.formatType),
                    };
                },
                {}
            );

            const statsMetrics_presentation = statsMetricsFormatted[metricType];

            let visibility = true;
            // Hide 'Not Available' when metric value is 0
            if (presentationName === 'Not Available' && metricValue === 0) {
                visibility = false;
            }

            return {
                id: dv,
                visible: visibility,
                presentationName: presentationName,
                selected: _.includes(componentSelection, dv),
                statsMetrics: statsMetrics_presentation,
                metricValue,
            };
        });

        const totalMetricValue = _.reduce(groups, (sum, group) => sum + group.metricValue, 0);

        const groupsWithBarGraphLength = _.map(groups, group => ({
            ...group,
            barGraphLength:
                group.metricValue === 0 ? 0 : (group.metricValue / totalMetricValue) * 100,
        }));

        const title =
            component.title ||
            component.groupings
                .map(grouping => _.get(dictionary, `${component.type}.${grouping}.value`, grouping))
                .join(' x ');

        return {
            id: component.id,
            title,
            grouping,
            groups: groupsWithBarGraphLength,
            isLoading,
            component,
        };
    }).filter(x => x);
}

function formatValue(metricValue, formatType) {
    switch (formatType) {
        case 'thousands':
            return formatNumber_whole(metricValue);

        case 'percentage':
            return formatNumber_percentage(metricValue);

        case 'dollar':
            return formatNumber_currency(metricValue);

        case 'whole-fixed':
            return formatNumber_wholeFixed(metricValue, 2);

        default:
            return metricValue;
    }
}

function scopeFilterSelector(storeState, campaignId) {
    return filterSelector(storeState, campaignId, 'Ad Groups', 'scope');
}

function filterSelector(storeState, campaignId, title, componentType) {
    const groupings = _.get(storeState, `reportChannel.${componentType}Filter`);
    const dictionary = _.get(storeState, `reportChannel.dictionary`);

    const filterItems = _.map(groupings, (selectedGroups, grouping) => {
        const groups = _.map(selectedGroups, group => {
            return {
                id: group,
                name: group,
                title: _.get(dictionary, `${grouping}.${group}.value`, group),
            };
        });

        return {
            grouping,
            groupingPresentationName: _.get(
                dictionary,
                `${componentType}.${grouping}.value`,
                grouping
            ),
            groups,
        };
    });

    const isBarVisible = flatMap(filterItems, 'groups').length > 0;

    const shouldShowClearButton =
        _(filterItems)
            .map('groups')
            .filter(filterGroup => filterGroup.length > 0)
            .value().length > 1;

    return {
        title,
        filterItems,
        shouldShowClearButton,
        isBarVisible,
    };
}

// Merge the global dictionary with Geo's dictionary
function createDictionary(storeState, props) {
    const { campaignId } = props.params;
    const dictionary = _.get(storeState, `dictionary`);
    const reportChannelDictionary = _.get(storeState, `reportChannel.dictionary`, {});
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`,
        []
    );

    const metricKeys = {};
    _.each(metricComponentsConfig, (config, i) => {
        metricKeys[config.metricType] = {
            value: config.metricType,
            order: i,
        };
    });

    return {
        ...dictionary,
        ...reportChannelDictionary,
        metricKeys,
    };
}

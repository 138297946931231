import _ from 'lodash';
import React from 'react';
import FileSaver from 'utils/file-saver';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GetAppIcon from '@mui/icons-material/GetApp';

import { createHttp } from 'utils/http';

const http = createHttp();

const EXPORT_OPTIONS = [
    { label: 'CSV', name: 'csv' },
    { label: 'JSON', name: 'json' },
    { label: 'XLS', name: 'xls' },
];
class Exporter {
    buildTable(splits, columns, totals, rows) {
        // Add splits as columns
        let xlsColumns = _(splits)
            .map(split => ({
                ..._.pick(split, ['name', 'label']),
                defaultValue: '',
                formatType: '',
                type: 'dimension',
            }))
            // remove head, which is the dimension column.
            .concat(columns.slice(1))
            .value();

        const uniqueUserIndex = _.findIndex(xlsColumns, col => col.name === 'unique_users');

        // Remove virtual unique_users metric, add concrete uniques by campaign, ad, creative, ad-creative
        if (uniqueUserIndex > -1) {
            const uniqueUsers = xlsColumns[uniqueUserIndex];
            const before = xlsColumns.slice(0, uniqueUserIndex);
            const after = xlsColumns.slice(uniqueUserIndex + 1);
            const detailedUniques = [
                {
                    ...uniqueUsers,
                    name: 'unique_campaign',
                    label: 'Unique Users by Campaign',
                },
                {
                    ...uniqueUsers,
                    name: 'unique_ad',
                    label: 'Unique Users by Ad',
                },
                {
                    ...uniqueUsers,
                    name: 'unique_creative',
                    label: 'Unique Users by Creative',
                },
                {
                    ...uniqueUsers,
                    name: 'unique_ad_creative',
                    label: 'Unique Users by Ad-Creative',
                },
            ];

            xlsColumns = [].concat(before, detailedUniques, after);
        }

        // take only relevant columns
        const pickColumnFields = row => {
            const out = {};
            _.each(xlsColumns, col => {
                const rowValue = row[col.name];
                out[col.name] = rowValue !== undefined ? rowValue : col.defaultValue;
            });
            return out;
        };

        return {
            columns: xlsColumns,
            totals: pickColumnFields(totals),
            rows: _.map(rows, row => pickColumnFields(row)),
        };
    }
    exportStringAsFile(exportString, exportFormat, fileName) {
        const fileId = fileName || exportFormat;
        // Support IE9
        if (typeof Uint8Array === 'undefined') {
            // Adapated from http://stackoverflow.com/questions/4458119/display-save-as-dialog-and-save-contents-of-a-selected-text-inside-textarea-to/4458807#4458807
            const defaultFilename = `${fileId}.txt`;
            const exportIframe = document.createElement('iframe');
            exportIframe.onload = () => {
                const exportDocument = exportIframe.contentWindow.document;
                exportDocument.open('text/plain', 'replace');
                exportDocument.write(exportString);
                exportDocument.execCommand('SaveAs', true, defaultFilename);
                document.body.removeChild(exportIframe);
            };
            document.body.appendChild(exportIframe);

            // IE10+ and modern browsers
        } else {
            const mimeType = `text/${exportFormat};charset=utf-8`;
            const defaultFilename = `${fileId}.${exportFormat}`;
            const blob = new Blob([exportString], { type: mimeType });
            FileSaver.saveAs(blob, defaultFilename, true);
        }
    }
    addCampaign(campaign, columns, rows) {
        const columnsWithCampaign = [
            { label: 'Campaign ID', name: 'campaign_id' },
            { label: 'Campaign Name', name: 'campaign_name' },
        ].concat(columns);

        const rowsWithCampaign = _.map(rows, row => {
            return {
                ...row,
                campaign_id: campaign.id,
                campaign_name: campaign.name,
            };
        });

        return {
            columns: columnsWithCampaign,
            rows: rowsWithCampaign,
        };
    }
    exportForCSV(columns, rows, fileName) {
        const header =
            _.map(
                columns,
                column => `"${column.exportLabel ? column.exportLabel : column.label}"`
            ).join(',') + '\r\n';
        const body = _.map(rows, row => {
            return _.map(columns, c => {
                if (row[c.name] === undefined) {
                    return `""`;
                }

                let formattedValue;
                if (c.isCurrencyType) {
                    formattedValue = parseFloat(parseFloat(row[c.name]).toFixed(2));
                } else if (c.formatType === 'percentage') {
                    formattedValue = row[c.name] * 100;
                } else {
                    formattedValue = row[c.name];
                }

                return `"${formattedValue}"`;
            }).join(',');
        }).join('\r\n');

        const string = header + body;
        this.exportStringAsFile(string, 'csv', fileName);
    }
    exportForJSON(rows, dictionary, fileName) {
        const processedRows = _.map(rows, _row => {
            const row = {};
            _.each(_row, (val, key) => {
                const scopeKeyFormatted = _.get(dictionary, `scope.${key}.value`);
                if (scopeKeyFormatted) {
                    row[scopeKeyFormatted] = val;
                } else {
                    const keyFormatted = _.get(dictionary, `metricKeys.${key}.value`, key);
                    row[keyFormatted] = val;
                }
            });

            return row;
        });

        const string = JSON.stringify(processedRows);

        this.exportStringAsFile(string, 'json', fileName);
    }
    exportForXLS(endpoint, payload, fileName) {
        http.post(endpoint, payload).then(response => {
            FileSaver.saveAs(
                base64ToBlob(
                    response,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ),
                `${fileName}.xlsx`
            );
        });
    }
}
export class CampaignExportButton extends React.Component {
    generateXLSPayload = () => {
        const { campaign, dateRange } = this.props;
        const { columns, rows, totals } = this.getTableData();

        const progressNumber = Math.min(
            100,
            Math.round(
                ((moment().valueOf() - moment(campaign.start)) /
                    (moment(campaign.end).valueOf() - moment(campaign.start))) *
                    100
            )
        );

        const diff = Math.max(0, moment(campaign.end).diff(moment(), 'days'));

        const newTotal = {
            ...totals,
            [_.first(columns).name]: 'Total',
        };

        const elapsedLabel = diff === 0 ? '' : `elapsed (${diff} days remaining)`;

        const payload = {
            campaign: {
                name: campaign.name,
                status: _.capitalize(campaign.status),
                flight:
                    moment(campaign.start).format('MMMM Do YYYY') +
                    ' - ' +
                    moment(campaign.end).format('MMMM Do YYYY'),
                progress: `${progressNumber}% ${elapsedLabel}`,
                exportDate: 'Retrieved ' + moment().format('MMMM Do YYYY'),
                retrievedDate:
                    'From ' +
                    moment(dateRange.start).format('MMMM Do YYYY') +
                    ' to ' +
                    moment(dateRange.end).format('MMMM Do YYYY'),
            },
            columns: columns,
            stats: rows,
            totals: [newTotal],
        };

        return payload;
    };

    getTableData = () => {
        const pt = this.props.serializePivotTable();

        const exporter = new Exporter();
        const table = exporter.buildTable(pt.splits, pt.columns, pt.totals, pt.rows);

        return {
            columns: table.columns,
            rows: table.rows,
            totals: pt.totals,
        };
    };

    onExport = type => {
        const { campaign, dictionary } = this.props;
        const exporter = new Exporter();

        switch (type.name) {
            case 'xls': {
                const payload = this.generateXLSPayload();
                exporter.exportForXLS('report/excel', payload, campaign.name);
                break;
            }
            case 'csv': {
                const tableData = this.getTableData();
                const tableDataWithCampaign = exporter.addCampaign(
                    campaign,
                    tableData.columns,
                    tableData.rows
                );
                exporter.exportForCSV(
                    tableDataWithCampaign.columns,
                    tableDataWithCampaign.rows,
                    campaign.name
                );
                break;
            }
            case 'json': {
                const tableData = this.getTableData();
                const tableDataWithCampaign = exporter.addCampaign(
                    campaign,
                    tableData.columns,
                    tableData.rows
                );
                exporter.exportForJSON(tableDataWithCampaign.rows, dictionary, campaign.name);
                break;
            }
        }
    };

    render() {
        return <ExportDropDown options={this.props.options} onExport={this.onExport} />;
    }
}

function ExportDropDown(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { options } = props;
    let optionList = EXPORT_OPTIONS;
    if (options && _.isArray(options)) {
        optionList = _.filter(optionList, item => {
            return options.indexOf(item.name) > -1;
        });
    }

    const handleSelect = option => {
        props.onExport(option);
        handleClose();
    };

    return (
        <React.Fragment>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={<GetAppIcon />}
            >
                Export
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {_.map(optionList, option => (
                    <MenuItem key={option.name} onClick={() => handleSelect(option)}>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}

export class AccountExportButton extends React.Component {
    generateXLSPayload = (columns, rows, totals, meta) => {
        const newTotal = {
            ...totals,
            [_.first(columns).name]: 'Total',
        };

        const payload = {
            meta: {
                organization: meta.organization,
                date: meta.date,
                exportDate: meta.exportDate,
            },
            columns,
            stats: rows,
            totals: [newTotal],
        };

        return payload;
    };

    onExport = type => {
        const { meta, dictionary } = this.props;
        const { splits, columns, totals, rows } = this.props.serializePivotTable();

        const exporter = new Exporter();
        const table = exporter.buildTable(splits, columns, totals, rows);
        switch (type.name) {
            case 'xls': {
                const payload = this.generateXLSPayload(
                    table.columns,
                    table.rows,
                    table.totals,
                    meta
                );
                exporter.exportForXLS('report/excel', payload, meta.fileName);
                break;
            }
            case 'csv': {
                exporter.exportForCSV(table.columns, table.rows, meta.fileName);
                break;
            }
            case 'json': {
                exporter.exportForJSON(table.rows, dictionary, meta.fileName);
                break;
            }
        }
    };

    render() {
        return <ExportDropDown options={this.props.options} onExport={this.onExport} />;
    }
}
export class BusinessReportsExportButton extends React.Component {
    generateXLSPayload = (columns, rows, totals, meta) => {
        const newTotal = {
            ...totals,
            [_.first(columns).name]: 'Total',
        };

        const payload = {
            meta,
            columns,
            stats: rows,
            totals: [newTotal],
            type: 'business-report',
        };

        return payload;
    };

    onExport = type => {
        const { meta, dictionary } = this.props;
        const { splits, columns, totals, rows } = this.props.serializePivotTable();

        const exporter = new Exporter();
        const table = exporter.buildTable(splits, columns, totals, rows);
        switch (type.name) {
            case 'xls': {
                const payload = this.generateXLSPayload(
                    table.columns,
                    table.rows,
                    table.totals,
                    meta
                );
                exporter.exportForXLS('report/excel', payload, meta.fileName);
                break;
            }
            case 'csv': {
                exporter.exportForCSV(table.columns, table.rows, meta.fileName);
                break;
            }
            case 'json': {
                exporter.exportForJSON(table.rows, dictionary, meta.fileName);
                break;
            }
        }
    };

    render() {
        return <ExportDropDown options={this.props.options} onExport={this.onExport} />;
    }
}

function base64ToBlob(base64, _mimetype) {
    const mimetype = _mimetype || '';
    var bytechars = atob(base64);
    var bytearrays = [];
    for (var offset = 0; offset < bytechars.length; offset += 512) {
        var slice = bytechars.slice(offset, offset + 512);
        var bytenums = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            bytenums[i] = slice.charCodeAt(i);
        }
        var bytearray = new Uint8Array(bytenums);
        bytearrays[bytearrays.length] = bytearray;
    }
    return new Blob(bytearrays, { type: mimetype });
}

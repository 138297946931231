import _ from 'lodash';
import { getWeatherConditionOptions } from 'common/constants/weather-condition-targeting';
import { getEnvironmentSettings } from 'services/environment';

const environmentSettings = getEnvironmentSettings();

export function useWeatherConditions({ conditionsToUse }) {
    return {
        conditionOptions: getWeatherConditionOptions(conditionsToUse),
    };
}

export function getWeatherConditionQualifier(conditionOptions, type) {
    return _.find(conditionOptions, { value: type })?.qualifierOptions;
}

export function getWeatherConditionTimeframe(conditionOptions, type) {
    const timeFrames = _.find(conditionOptions, { value: type })?.timeframeOptions;
    return timeFrames;
}

export function getWeatherConditionValue(conditionOptions, type) {
    const values = _.find(conditionOptions, { value: type })?.valueOptions;
    if ((type === 'airquality' || type === 'flu') && environmentSettings.env !== 'spain') {
        const newValues = _.filter(values, value => value.alias !== 'veryHigh');
        return newValues;
    }
    return values;
}

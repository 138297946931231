import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import actions from './actions';
import selector from './selector';

import Layout from './layout';
import { getEnv } from 'services/environment';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getPdsCustomDashboardData(keywords));
};

const PDSCustomDashboard = ({
    dispatch,
    audienceSegments,
    userOrganizationName,
    organizationId,
    totalSegmentCount,
    isLoading,
    audienceBuilderVersion,
    hasEnvironics,
    ...props
}) => {
    useEffect(() => {
        dispatch(actions.init());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    const goToSegmentBuilder = segmentBuilderUrl => {
        window.open(segmentBuilderUrl, '_blank');
    };

    const orgName = window.encodeURIComponent(userOrganizationName);

    const segmentBuilderUrl = `http://segment${getEnv() === 'production' ? '' : '-dev'}.data.pelmorex.com/?eftoken=789&orgId=${organizationId}&orgName=${orgName}&environics=${hasEnvironics}`;

    return (
        <Layout
            audienceSegments={audienceSegments}
            onSearch={onChange}
            segmentCount={totalSegmentCount}
            isLoading={isLoading}
            goToSegmentBuilder={goToSegmentBuilder}
            segmentBuilderUrl={segmentBuilderUrl}
            activeAudience={props.params.audienceId}
            audienceBuilderVersion={audienceBuilderVersion}
            organizationId={organizationId}
        />
    );
};

export default connect(selector)(PDSCustomDashboard);

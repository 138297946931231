import _ from 'lodash';

import c from 'common/constants/flux-events';
import API from 'services/api';
import { serializeForApi } from 'services/resources/ad-preset';
import { browserHistory } from 'react-router';
import GeoCategories from 'states/resources/geo-categories/actions';
import Organizations from 'states/resources/organizations/actions';

import AdPresetActions from 'states/resources/ad-presets/actions';
import UserActions from 'states/resources/users/actions';

import CampaignsAPI from 'states/resources/campaigns/actions';
import flags from 'containers/flags/service';
// -----------------
// New Ad Preset Form
// -----------------
export const newAdPresetForm = {
    init() {
        return async (dispatch, getState) => {
            const orgId = _.get(getState(), 'profile.organizationId');
            await dispatch(AdPresetActions.getAll(orgId));
        };
    },
    open(campaignId) {
        return (dispatch, getState) => {
            // Open the form
            dispatch({
                type: c.NEW_AD_PRESET__DRAFT__OPEN,
                payload: {},
            });

            Promise.all([
                dispatch(UserActions.get()),
                dispatch(Organizations.getAll()),
                dispatch(GeoCategories.getCategories()),
                dispatch(GeoCategories.getAllLayers()),
            ]).then(([user, organizations]) => {
                const organization = _.find(organizations, { id: user.organization });

                const owner = user.organization;

                // Populate the draft
                dispatch({
                    type: c.NEW_AD_PRESET__DRAFT__INIT,
                    payload: {
                        owner,
                        organization,
                    },
                });
            });
        };
    },

    updateDraft(formData) {
        return {
            type: c.NEW_AD_PRESET__DRAFT__UPDATE,
            payload: { formData },
        };
    },

    showErrors(formData) {
        return {
            type: c.NEW_AD_PRESET__DRAFT__SHOW_ERRORS,
            payload: {},
        };
    },

    submitDraft(formData, query) {
        return async (dispatch, getState) => {
            const sessionNewAdPresetForm = _.get(getState(), `adPresetNew`, undefined);
            if (!sessionNewAdPresetForm) {
                throw new Error('Store error: adPresetNew does not exist');
            }

            const errors = _.get(sessionNewAdPresetForm, 'errors', []);
            if (errors.length !== 0) {
                // There are errors don't do ajax
                dispatch({
                    type: c.NEW_AD_PRESET__DRAFT__SHOW_ERRORS,
                    payload: {},
                });
                return;
            }
            //
            // // No errors, essemble payload and serialize
            const draft = _.get(sessionNewAdPresetForm, `draft`, {});
            const payload_beforeSerialized = { ...draft };
            const payloadSerialized = serializeForApi(payload_beforeSerialized, []);

            dispatch({
                type: c.NEW_AD_PRESET__DRAFT__SUBMIT,
            });

            const orgId = sessionNewAdPresetForm.organization.id;

            await dispatch(AdPresetActions.create(orgId, payloadSerialized)).then(
                response => {
                    const adpreset = response;

                    dispatch({
                        type: c.NEW_AD_PRESET__DRAFT__SUBMIT_SUCCESS,
                        payload: { adpreset },
                    });

                    const campaignId = _.get(query, 'campaign');

                    browserHistory.push(
                        campaignId ? `/campaigns/${campaignId}/setup` : `/ad-preset`
                    );
                    // Tear down
                    dispatch({
                        type: c.NEW_AD_PRESET__DRAFT__CLOSE,
                    });
                },
                error => {
                    // throw toaster error for server errors

                    dispatch({
                        type: c.NEW_AD_PRESET__DRAFT__SUBMIT_FAIL,
                        error: error.body,
                    });
                }
            );
        };
    },

    cancelDraft(query) {
        const campaignId = _.get(query, 'campaign');
        browserHistory.push(campaignId ? `/campaigns/${campaignId}/setup` : `/ad-preset`);

        return {
            type: c.NEW_AD_PRESET__DRAFT__CLOSE,
        };
    },
};

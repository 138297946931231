import _ from 'lodash';
import uuid from 'uuid';
import flags from 'containers/flags/service';

import schema from './schema';

export const NAME = 'conversionsDashboard';

const initialState = {
    isFormOpen: false,
    advertisers: [],
    conversions: [],
    advertiserIdSelected: null,
    draft: {
        id: null,
        event_name: '',
        reporting_name: '',
        advertiser: '',
        use_click_through: true,
        use_view_through: true,
        click_through_attribution_window: 14,
        view_through_attribution_window: 14,
        use_dynamic_data_subevent: false,
        use_dynamic_data_value: false,
        dynamic_data_subevent_name: '',
        dynamic_data_value_name: '',
        should_optimize_for_budget_allocation: false,
    },
    serverError: null,
    errors: {},
    showErrors: false,
    isSaving: false,
    isDeleting: false,
    conversionToEdit: null,
    isInitialized: false,
};

const handlers = {
    'CONVERSIONS_DASHBOARD:INIT:START': (state = initialState, action) => {
        return {
            ...initialState,
            isInitialized: false,
        };
    },
    'CONVERSIONS_DASHBOARD:INIT:SUCCESS': (state, action) => {
        const advertisers = _.filter(action.payload.data.organizations, org => {
            if (org.client_type === 'advertiser') {
                return true;
            }

            return false;
        });

        return {
            ...state,
            advertisers,
            conversions: _.get(action, 'payload.data.ownOrganization.conversions', []),
            isInitialized: true,
        };
    },
    'CONVERSIONS_DASHBOARD:INIT:ERROR': (state, action) => {
        return {
            ...state,
            error: action.error,
            isInitialized: true,
        };
    },
    'CONVERSIONS_DASHBOARD:FORM:OPEN': (state, action) => {
        const { conversion } = action.payload;
        const draftFields = _.keys(initialState.draft);

        let draft;
        if (conversion) {
            draft = {
                ..._.pick(conversion, draftFields),
                advertiser: conversion.advertiser.id,
            };
        } else {
            const defaultEventName = uuid.v4().replaceAll('-', '_');
            draft = {
                ...initialState.draft,
                event_name: defaultEventName,
                id: uuid.v4(),
            };
        }

        return {
            ...state,
            draft,
            showErrors: false,
            isFormOpen: true,
            conversionToEdit: conversion,
            serverError: null,
        };
    },
    'CONVERSIONS_DASHBOARD:FORM:CLOSE': (state, action) => {
        return {
            ...state,
            isFormOpen: false,
            draft: initialState.draft,
        };
    },
    'CONVERSIONS_DASHBOARD:VIEW_ADVERTISER': (state, action) => {
        return {
            ...state,
            advertiserIdSelected: action.payload.advertiser.id,
        };
    },
    'CONVERSIONS_DASHBOARD:VIEW_ALL_ADVERTISERS': (state, action) => {
        return {
            ...state,
            advertiserIdSelected: null,
        };
    },
    'CONVERSIONS_DASHBOARD:FORM:UPDATE_DRAFT': (state, action) => {
        return {
            ...state,
            draft: {
                ...state.draft,
                ...action.payload.changes,
            },
        };
    },
    'CONVERSIONS_DASHBOARD:FORM:SAVE:START': (state, action) => {
        return {
            ...state,
            showErrors: true,
            isSaving: true,
        };
    },
    'CONVERSIONS_DASHBOARD:FORM:SAVE:SUCCESS': (state, action) => {
        return {
            ...state,
            isSaving: false,
            isFormOpen: false,
            draft: initialState.draft,
        };
    },
    'CONVERSIONS_DASHBOARD:FORM:SAVE:ERROR': (state, action) => {
        return {
            ...state,
            serverError: action.error,
            isSaving: false,
        };
    },
    'CONVERSIONS_DASHBOARD:CONVERSION:DELETE:START': (state, action) => {
        return {
            ...state,
            isDeleting: true,
        };
    },
    'CONVERSIONS_DASHBOARD:CONVERSION:DELETE:SUCCESS': (state, action) => {
        return {
            ...state,
            isDeleting: false,
        };
    },
    'CONVERSIONS_DASHBOARD:CONVERSION:DELETE:ERROR': (state, action) => {
        return {
            ...state,
            isDeleting: false,
            deleteError: action.error,
        };
    },
};

function validateDraft(state) {
    const options = {
        allowUnknown: true,
        abortEarly: false,
        context: {
            draft: state.draft,
            conversions: state.conversions,
        },
    };

    const errors = {};
    const result = schema.validate(state.draft, options);
    if (!result.error) {
        return { ...state, errors };
    }

    _.each(result.error.details, detail => {
        let key;
        if (detail.path.length === 0) {
            key = '__root';
        } else {
            key = detail.path.join('.');
        }
        errors[key] = detail.message;
    });

    return { ...state, errors };
}

export default function reducer(state = initialState, action) {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
        nextState = validateDraft(nextState);
        return nextState;
    }

    return state;
}
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import StandardInput from 'widgets-v5/standard-input';

class PrefixSuffixInput extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        formatIn: PropTypes.func,
        formatOut: PropTypes.func,
        autocorrect: PropTypes.func,
        placeholder: PropTypes.string,
        shouldAllowInput: PropTypes.func,
    };

    static defaultProps = {
        className: '',
        onChange: () => {},
        value: '',
    };

    render() {
        return (
            <div className={`ef4-prefix-suffix-input ${this.props.className}`}>
                {this.props.prefix ? (
                    <div
                        className={classnames(
                            this.props.className,
                            'ef4-prefix-suffix-input__prefix'
                        )}
                    >
                        {this.props.prefix}
                    </div>
                ) : null}
                <StandardInput
                    {...this.props}
                    className={`ef4-prefix-suffix-input__field ${this.props.inputClassName}`}
                    type="text"
                />
                {this.props.suffix ? (
                    <div
                        className={classnames(
                            this.props.className,
                            'ef4-prefix-suffix-input__suffix'
                        )}
                    >
                        {this.props.suffix}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default PrefixSuffixInput;

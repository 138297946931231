import get from 'lodash/get';

export default function selector(storeState) {
    const { brands, loading } = get(storeState, 'flexCustomDashboard');
    const organizationId = get(storeState, 'profile.organizationId');

    return {
        brands,
        loading,
        organizationId,
    };
}

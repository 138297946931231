import _ from 'lodash';
import VError from 'verror';

import { actions } from './reducer';
import notify from 'utils/notify';
import UserActions from 'states/resources/users/actions';
import { graphqlRequest } from 'utils/http/redux';

const initMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.init.type) {
        const organizationIds = action?.payload?.organizationIds;
        const showAllSS = action?.payload?.showAllSS;

        try {
            let query;
            query = `
                query getOverviewAdData($organizationIds: [String], $showAllSS: Boolean) {
                    adsEndingSoon: ads (runTime: "live", hideArchived: true, organizationIds: $organizationIds, showAllSS: $showAllSS) {
                        id
                        name
                        assigneeId
                        start
                        end
                        timezone
                        fta {
                            enabled
                        }
                        notes
                        metadata {
                            adId
                            progress {
                              progressData {
                                  idealDelivered
                                  currentDelivered
                                  currentPacing
                                  currentStatus
                                  projectedDelivery
                                  projectedPacing
                                  projectedStatus
                                  deliveredToday
                                  deliveredYesterday
                                  deliveredTwoDaysAgo
                                  deliveredThreeDaysAgo
                                  health
                                  totalTarget
                                  goal
                                  revenueAtRisk
                                  goalAtRisk
                              }
                          }
                          metrics {
                            impressions
                            clicks
                            ctr
                            owner_total_media_cost_local_ecpm
                          }
                        }
                        creativeChannels
                        primary_pacing
                        billing_enabled
                        campaignName
                        campaignId
                        type
                        organization
                        assigneeName
                    }
                    upcomingAds: ads (runTime: "upcoming", hideArchived: true, organizationIds: $organizationIds, showAllSS: $showAllSS) {
                        id
                        name
                        assigneeId
                        start
                        end
                        timezone
                        primary_pacing
                        campaignName
                        campaignId
                        type
                        notes
                        fta {
                            enabled
                        }
                        notes
                        creativeChannels
                        metadata {
                            progress {
                              progressData {
                                  totalTarget
                                  goal
                              }
                          }
                        }
                        organization
                        assigneeName
                    }
                    recentlyEndedAds: ads (runTime: "ended", hideArchived: true, organizationIds: $organizationIds, showAllSS: $showAllSS) {
                        id
                        name
                        assigneeId
                        start
                        end
                        timezone
                        fta {
                            enabled
                        }
                        notes
                        metadata {
                            adId
                            progress {
                              progressData {
                                  idealDelivered
                                  currentDelivered
                                  currentPacing
                                  currentStatus
                                  projectedDelivery
                                  projectedPacing
                                  projectedStatus
                                  deliveredToday
                                  deliveredYesterday
                                  deliveredTwoDaysAgo
                                  deliveredThreeDaysAgo
                                  health
                                  totalTarget
                                  goal
                                  revenueAtRisk
                                  goalAtRisk
                                  result
                                  deliveredRatio
                                  variance
                              }
                          }
                          metrics {
                            impressions
                            clicks
                            ctr
                            owner_total_media_cost_local_ecpm
                          }
                        }
                        creativeChannels
                        primary_pacing
                        campaignName
                        campaignId
                        type
                        organization
                        assigneeName
                    }
                    campaignsEndingSoon: liveCampaigns(organizationIds: $organizationIds, showAllSS: $showAllSS) {
                        id
                        name
                        creatorId
                        start
                        end
                        budget_allocation_method
                        revenueModel
                        total_billings_local
                        campaign_max_total_spend
                        type
                        default_timezone
                        flightPacingStrategy
                        ftaEnabled
                        notes
                        creativeChannels
                        organization
                        assigneeName
                        metadata {
                          progressData {
                              idealDelivered
                              currentDelivered
                              currentStatus
                              currentPacing
                              projectedDelivery
                              projectedPacing
                              projectedStatus
                              deliveredToday
                              deliveredYesterday
                              deliveredTwoDaysAgo
                              deliveredThreeDaysAgo
                              goal
                              totalTarget
                              revenueAtRisk
                              spendAtRisk
                          }
                          metrics {
                            impressions
                            clicks
                            ctr
                            owner_total_media_cost_local_ecpm
                          }
                       }
                       flights (runTime: "live") {
                            id
                            name
                            start
                            end
                            metadata {
                                progressData {
                                    idealDelivered
                                    currentDelivered
                                    currentStatus
                                    currentPacing
                                    projectedDelivery
                                    projectedPacing
                                    projectedStatus
                                    deliveredToday
                                    deliveredYesterday
                                    deliveredTwoDaysAgo
                                    deliveredThreeDaysAgo
                                    goal
                                    totalTarget
                                    revenueAtRisk
                                    spendAtRisk
                                }
                                metrics {
                                    impressions
                                    clicks
                                    ctr
                                    owner_total_media_cost_local_ecpm
                                }
                            }
                       }
                    }
                    campaignsUpcoming: campaigns (runTime: "upcoming", organizationIds: $organizationIds, showAllSS: $showAllSS) {
                        id
                        name
                        creatorId
                        start
                        end
                        budget_allocation_method
                        revenueModel
                        total_billings_local
                        campaign_max_total_spend
                        type
                        default_timezone
                        flightPacingStrategy
                        ftaEnabled
                        notes
                        creativeChannels
                        organization
                        assigneeName
                        notes
                        metadata {
                          progressData {
                              totalTarget
                              goal
                          }
                        }
                        flights (runTime: "upcoming") {
                            id
                            name
                            start
                            end
                            metadata {
                                progressData {
                                    goal
                                    totalTarget
                                }
                            }
                        }
                    }
                    campaignsEnded: campaigns (runTime: "ended", organizationIds: $organizationIds, showAllSS: $showAllSS) {
                        id
                        name
                        creatorId
                        start
                        end
                        budget_allocation_method
                        revenueModel
                        total_billings_local
                        campaign_max_total_spend
                        type
                        default_timezone
                        flightPacingStrategy
                        ftaEnabled
                        notes
                        creativeChannels
                        organization
                        assigneeName
                        metadata {
                          progressData {
                              idealDelivered
                              currentDelivered
                              currentStatus
                              currentPacing
                              projectedDelivery
                              projectedPacing
                              projectedStatus
                              deliveredToday
                              deliveredYesterday
                              deliveredTwoDaysAgo
                              deliveredThreeDaysAgo
                              goal
                              totalTarget
                              revenueAtRisk
                              spendAtRisk
                              result
                              deliveredRatio
                              variance
                          }
                          metrics {
                            impressions
                            clicks
                            ctr
                            owner_total_media_cost_local_ecpm
                          }
                       }
                       flights(runTime: "ended") {
                            id
                            name
                            start
                            end
                            metadata {
                                progressData {
                                    idealDelivered
                                    currentDelivered
                                    currentStatus
                                    currentPacing
                                    projectedDelivery
                                    projectedPacing
                                    projectedStatus
                                    deliveredToday
                                    deliveredYesterday
                                    deliveredTwoDaysAgo
                                    deliveredThreeDaysAgo
                                    goal
                                    totalTarget
                                    revenueAtRisk
                                    spendAtRisk
                                    result
                                    deliveredRatio
                                    variance
                                }
                            }
                       }
                    }
                    alerts: alerts (filters: { showAllSS: $showAllSS }) {
                      id
                      campaignId
                      resourceId
                      severity
                      resourceType
                      alertType
                  }
                }
            `;

            const variables = {
                organizationIds,
                showAllSS,
            }

            const response = await dispatch(
                graphqlRequest({
                    query,
                    variables,
                })
            );

            const {
                data: {
                    adsEndingSoon,
                    upcomingAds,
                    recentlyEndedAds,
                    campaignsEndingSoon,
                    campaignsUpcoming,
                    campaignsEnded,
                    alerts,
                },
            } = response;

            dispatch(
                actions.initSuccess({
                    endingSoon: adsEndingSoon,
                    upcoming: upcomingAds,
                    recentlyEnded: recentlyEndedAds,
                    campaignsEndingSoon,
                    campaignsUpcoming,
                    campaignsEnded,
                    alerts,
                })
            );
        } catch (error) {
            const organization = _.get(getState(), 'profile.organizationId');
            const user = _.get(getState(), 'profile.userId');

            dispatch(actions.initError({ error }));

            notify({
                error: new VError(error, 'failed to fetch ads in dashboard'),
                metaData: {
                    organization,
                    user,
                },
            });
        }
    }

    if (action.type === actions.fetchSelfServeOrgList.type) {
        try {
            const query = `
                query getSelfServeOrgs {
                    selfServeOrganizations {
                        id
                        name
                    }
                }
            `;

            const {
                data: {
                    selfServeOrganizations,
                },
            } = await dispatch(
                graphqlRequest({
                    query,
                })
            );

            dispatch(
                actions.fetchSelfServeOrgListSuccess({
                    selfServeOrgList: selfServeOrganizations
                })
            );
        } catch (error) {
            const organization = _.get(getState(), 'profile.organizationId');
            const user = _.get(getState(), 'profile.userId');

            notify({
                error: new VError(error, 'failed to fetch self serve orgs'),
                metaData: {
                    organization,
                    user,
                },
            });
        }
    }
};

const userPreferenceMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.fetchUserPreference.type) {
        const id = _.get(getState(), `profile.userId`);

        try {
            const query = `
              query getUser ($id: String) {
                    user(id: $id) {
                        _etag
                        preferences{
                            columnPreferencesOnDashboardOverviewPage {
                                hiddenColumns {
                                    live
                                    upcoming
                                    ended
                                }
                                sortBy {
                                    live {
                                        id
                                        desc
                                    }
                                    upcoming {
                                        id
                                        desc
                                    }
                                    ended {
                                        id
                                        desc
                                    }
                                }
                                assigneeFilter {
                                    live
                                    upcoming
                                    ended
                                }
                                organizationFilter {
                                    live
                                    upcoming
                                    ended
                                }
                            }
                        }
                    }
                }
            `;
            const {
                data: {
                    user: {
                        preferences: {
                            columnPreferencesOnDashboardOverviewPage: {
                                hiddenColumns,
                                sortBy,
                                assigneeFilter,
                                organizationFilter,
                            },
                        },
                        _etag,
                    },
                },
            } = await dispatch(
                graphqlRequest({
                    query,
                    variables: { id },
                })
            );

            dispatch(
                actions.fetchUserPreferenceSuccess({
                    etag: _etag,
                    hiddenColumns,
                    sortBy,
                    assigneeFilter,
                    organizationFilter,
                })
            );
        } catch (error) {
            const organization = _.get(getState(), 'profile.organizationId');
            const user = _.get(getState(), 'profile.userId');

            dispatch(actions.initError({ error }));

            notify({
                error: new VError(error, 'failed to fetch user preferences'),
                metaData: {
                    organization,
                    user,
                },
            });
        }
    }

    if (
        action.type === actions.updateHiddenColumns.type ||
        action.type === actions.updateSortBy.type ||
        action.type === actions.updateAssigneeFilter.type ||
        action.type === actions.updateOrganizationFilter.type
    ) {
        const hiddenColumns = action.payload.hiddenColumns;
        const sortBy = action.payload.sortBy;
        const assigneeFilter = action.payload.assigneeFilter;
        const organizationFilter = action.payload.organizationFilter;

        const userId = _.get(getState(), `profile.userId`);
        const etag = _.get(getState(), `overviewDashboardv2.etag`);
        const currentHiddenColumns = _.get(getState(), `overviewDashboardv2.hiddenColumns`);
        const currentSortBy = _.get(getState(), `overviewDashboardv2.sortBy`);
        const currentAssigneeFilter = _.get(getState(), `overviewDashboardv2.assigneeFilter`);
        const currentOrganizationFilter = _.get(getState(), `overviewDashboardv2.organizationFilter`);

        const payload = {
            preferences: {
                columnPreferencesOnDashboardOverviewPage: {
                    hiddenColumns: {
                        ...currentHiddenColumns,
                        [action.payload.section]:
                            hiddenColumns || currentHiddenColumns[action.payload.section],
                    },
                    sortBy: {
                        ...currentSortBy,
                        [action.payload.section]: sortBy || currentSortBy[action.payload.section],
                    },
                    assigneeFilter: {
                        ...currentAssigneeFilter,
                        [action.payload.section]:
                            assigneeFilter || currentAssigneeFilter[action.payload.section],
                    },
                    organizationFilter: {
                        ...currentOrganizationFilter,
                        [action.payload.section]:
                            organizationFilter || currentOrganizationFilter[action.payload.section],
                    },
                },
            },
        };

        const impersonatorId = _.get(getState(), `profile.impersonatorsToken.user.id`);

        if (impersonatorId === userId) {
            dispatch(UserActions.update(userId, payload, etag));
        }
    }
};

const alertMw = ({ debounceFn }) => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === 'ALERT:CREATED' || action.type === 'ALERT:DEACTIVATED') {
        debounceFn(dispatch);
    }

    if (action.type === actions.fetchSidebarAlerts.type) {
        try {
            const showAllSS = action?.payload?.showAllSS;
            const query = `
                    query getOverviewAdData($resourceId: [String], $campaignId: [Int], $showAllSS: Boolean) {
                        alerts: alerts (filters: {
                            resourceId: $resourceId,
                            campaignId: $campaignId,
                            showAllSS: $showAllSS
                        }) {
                            id
                            campaignId
                            resourceName
                            resourceType
                            resourceId
                            severity
                            body
                            title
                            start
                            alertType
                        }
                    }
                `;

            const response = await dispatch(
                graphqlRequest({
                    query,
                    variables: {
                        resourceId: action.payload.resourceId,
                        campaignId: action.payload.campaignId,
                        showAllSS,
                    },
                })
            );

            dispatch(actions.fetchSidebarAlertsSuccess(response.data.alerts));
        } catch (error) {
            dispatch(actions.fetchSidebarAlertsError(error));
        }
    }
};

export const makeMiddlewares = deps => {
    return [initMw(deps), userPreferenceMw(deps), alertMw(deps)];
};

export const middlewares = makeMiddlewares({
    debounceFn: dispatch => _.debounce(() => dispatch(actions.init()), 15000),
});

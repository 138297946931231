import filter from 'lodash/filter';
import some from 'lodash/some';
import React from 'react';
import classnames from 'classnames';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { BlockLoadGroup } from 'widgets-v5/load-group';

export function BarGraphTile(props) {
    const handleChange = group => {
        const { component, grouping } = props;
        props.onCheckboxSelected(component, grouping, group);
    };

    const handleReset = () => {
        props.onReset(props.component);
    };

    const visibleGroups = filter(props.groups, { visible: true });
    const someSelected = some(visibleGroups, { selected: true });

    return (
        <section className={classnames('ef5-bar-graph-tile', props.className)}>
            <BlockLoadGroup isLoading={props.isLoading}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6">{props.title}</Typography>
                    {someSelected && (
                        <Button variant="contained" onClick={handleReset}>
                            Reset
                        </Button>
                    )}
                </Box>
                <div className="ef5-bar-graph-tile__main">
                    <div className="ef5-bar-graph-tile__table">
                        {visibleGroups.map((group, index) => (
                            <label className="ef5-bar-graph-tile__group" key={index}>
                                <span className="ef5-bar-graph-tile__group-name">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={group.selected}
                                                onChange={() => handleChange(group)}
                                                color="primary"
                                            />
                                        }
                                        label={group.presentationName}
                                    />
                                </span>
                                <figure className="ef5-bar-graph-tile__bar-graph">
                                    <div
                                        className="ef5-bar-graph-tile__bar-graph-bar"
                                        style={{ width: `${group.barGraphLength}%` }}
                                    />
                                </figure>
                                <span className="ef5-bar-graph-tile__stat">
                                    {group.statsMetrics}
                                </span>
                            </label>
                        ))}
                    </div>
                </div>
            </BlockLoadGroup>
        </section>
    );
}

import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';

const GeoCategories = {
    getCategories(options) {
        return (dispatch, getState) => {
            return http()
                .get('geocategories', options)
                .then(
                    categories => {
                        dispatch({
                            type: c.SYSTEM__GEO_CATEGORIES__FETCH__SUCCESS,
                            payload: {
                                categories,
                            },
                        });
                        return categories;
                    },
                    err => {
                        console.error(`GET ALL geocategories error`, err);

                        throw err;
                    }
                );
        };
    },
    getAllLayers() {
        return (dispatch, getState) => {
            return http()
                .get('geolayers')
                .then(
                    layers => {
                        dispatch({
                            type: c.SYSTEM__GEO_LAYERS__FETCH__SUCCESS,
                            payload: {
                                layers,
                            },
                        });
                        return layers;
                    },
                    err => {
                        console.error(`GET ALL geolayers error`, err);

                        throw err;
                    }
                );
        };
    },
    getLayer(layerId) {
        return (dispatch, getState) => {
            return http()
                .get(`geolayers/${layerId}`)
                .then(
                    layer => {
                        dispatch({
                            type: c.SYSTEM__GEO_LAYER_POINTS__FETCH__SUCCESS,
                            payload: {
                                layer,
                            },
                        });
                        return layer;
                    },
                    err => {
                        console.error(`GET geolayer error`, err);

                        throw err;
                    }
                );
        };
    },
    createLayer(newLayer) {
        return (dispatch, getState) => {
            return http()
                .post('geolayers', newLayer)
                .then(
                    layer => {
                        dispatch({
                            type: c.SYSTEM__GEO_LAYERS__POST__SUCCESS,
                            payload: {
                                layer,
                            },
                        });
                        return layer;
                    },
                    err => {
                        console.error(`CREATE geolayer error`, err);

                        throw err;
                    }
                );
        };
    },
    deleteLayer(layerId) {
        return (dispatch, getState) => {
            return http()
                .del(`geolayers/${layerId}`)
                .then(
                    response => {
                        dispatch({
                            type: c.SYSTEM__GEO_LAYERS__DELETE__SUCCESS,
                            payload: {
                                layerId,
                            },
                        });
                        return response;
                    },
                    err => {
                        console.error(`DELETE geolayer error`, err);

                        throw err;
                    }
                );
        };
    },
    updateLayer(layerId, newLayer) {
        return (dispatch, getState) => {
            return http()
                .patch(`geolayers/${layerId}`, newLayer)
                .then(
                    layer => {
                        dispatch({
                            type: c.SYSTEM__GEO_LAYERS__UPDATE__SUCCESS,
                            payload: {
                                layer,
                            },
                        });
                        return layer;
                    },
                    err => {
                        console.error(`UPDATE geolayer error`, err);

                        throw err;
                    }
                );
        };
    },
    getLayersByCampaign(campaignId) {
        return (dispatch, getState) => {
            return http()
                .get(`campaigns/${campaignId}/geolayers`)
                .then(
                    layers => {
                        dispatch({
                            type: c.SYSTEM__GEO_LAYERS__FETCH__SUCCESS,
                            payload: {
                                layers,
                            },
                        });
                        return layers;
                    },
                    err => {
                        console.error(`GET BY CAMPAIGN geolayers error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default GeoCategories;

import _ from 'lodash';

const querystring = {
    serialize(json) {
        let qs = '';
        _.each(json, (value, key) => {
            qs += `${key}=${value}&`;
        });

        // remove last &
        return qs.substr(0, qs.length - 1);
    },
    parse(qs) {
        const json = {};
        let _qs = qs;
        if (!_qs) {
            return json;
        }

        if (_qs[0] === '?') {
            _qs = _.tail(qs).join('');
        }

        const pairs = _qs.split('&');

        _.each(pairs, pair => {
            const split = pair.split('=');
            json[split[0]] = split[1];
        });

        return json;
    },
};

export default querystring;

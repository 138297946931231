import _ from 'lodash';
import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import config from '../../../../config';

import QuickDialog from 'widgets-v5/quick-dialog';
import { NegativeButton } from 'widgets-v5/buttons';
import RadioSelector from 'widgets-v5/radio-selector';
import Separator from 'widgets-v5/separator';
import Navigation from 'widgets-v5/navigation';

import select from './selector';
import { toggleAPImode, toggleEnvironment } from './actions';

class DevSettings extends React.Component {
    handleAPImodeToggle = () => {
        this.props.dispatch(toggleAPImode());
    };

    handleEnvironmentToggle = env => {
        this.props.dispatch(toggleEnvironment(env));
    };

    render() {
        // Forcibly disable this page in production. This check should be unnecessary, but will act as a
        // guard in case this component is used anywhere else.
        if (!config.DEV_SETTINGS_ENABLED) {
            return <div>Disabled</div>;
        }

        const {
            isOpen,
            isRemote,
            isLoading,

            apiMode,
            environment,

            apiSelectorPosition,
            environmentSelectorPosition,
        } = this.props;

        return (
            <QuickDialog
                className="ef5-dev-settings"
                button={open => (
                    <NegativeButton
                        className="ef5-dev-settings__button"
                        onClick={open}
                        text={`${environment.toUpperCase()}, ${
                            apiMode === 'local' ? 'localhost' : 'remote'
                        }`}
                    />
                )}
                dialog={close => (
                    <div className="ef5-dev-settings__dialog">
                        <Separator label="Environment" />
                        <RadioSelector
                            className="ef5-dev-settings__env-selector"
                            items={[
                                { label: 'Production', value: 'production' },
                                { label: 'Demo', value: 'demo' },
                                { label: 'Spain', value: 'spain' },
                            ]}
                            activeItem={environment}
                            onChange={this.handleEnvironmentToggle}
                        />
                        <Separator label="API" />
                        <RadioSelector
                            className="ef5-dev-settings__api-selector"
                            items={[
                                { label: 'Local', value: 'local' },
                                { label: 'Remote', value: 'remote' },
                            ]}
                            activeItem={apiMode}
                            onChange={this.handleAPImodeToggle}
                        />
                    </div>
                )}
            />
        );
    }
}

export default connect(select)(DevSettings);

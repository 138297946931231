import _ from 'lodash';

import validate from 'utils/validate';
import getValidators from './services/validation-rules';
import { defaultValues, defaultsByMode } from './services/constants';

export const NAME = 'audienceEditor';

export function getDefaultValues() {
    const flagVal = { ...defaultValues, advertiser_id: '', lookback: '' };
    return flagVal;
}

export function getInitialState() {
    const initialState = {
        isInitializing: false,
        isSaving: false,
        showErrors: false,
        isEditing: false,
        fileName: undefined,
        draft: getDefaultValues(),
        errors: {},
        serverError: undefined,
        invalidLines: [],
        isModalOpen: false,
    };
    return initialState;
}

const dashboardToModeMapping = {
    uploaded: {
        mode: 'upload',
        source_type: 'upload',
    },
    retargeting: {
        mode: 'retargeting',
    },
};

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case 'AUDIENCE_EDITOR__INIT_START': {
            return {
                ...getInitialState(),
                isInitializing: true,
                draft: {
                    ...getInitialState().draft,
                    ...(dashboardToModeMapping[action.payload.target] || {}),
                },
            };
        }
        case 'AUDIENCE_EDITOR__INIT_END': {
            return initializeDraft(state, action);
        }
        case 'AUDIENCE_EDITOR__INIT_ERROR': {
            return {
                ...state,
                isInitializing: false,
            };
        }
        case 'AUDIENCE_EDITOR__SAVE_START': {
            const errors = validateDraft(state.draft, getValidators(state.isEditing));

            return {
                ...state,
                isSaving: true,
                showErrors: true,
                errors,
            };
        }
        case 'AUDIENCE_EDITOR__SAVE_END': {
            return getInitialState();
        }
        case 'AUDIENCE_EDITOR__SAVE_CLIENT_ERROR': {
            return {
                ...state,
                isSaving: false,
            };
        }
        case 'AUDIENCE_EDITOR__SAVE_SERVER_ERROR': {
            return {
                ...state,
                isSaving: false,
                serverError: action.payload.error,
                invalidLines: action.payload.invalidLines || [],
            };
        }
        case 'AUDIENCE_EDITOR__UPDATE_DRAFT': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    ...action.payload.changes,
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'AUDIENCE_EDITOR__UPDATE_MODE': {
            const { mode } = action.payload;

            if (mode === state.draft.mode) {
                return state;
            }

            const modeToSourceType = {
                upload: 'upload',
                retargeting: 'campaign',
            };

            let defaultsForMode = defaultsByMode[mode];
            let nextState = {
                ...getInitialState(),
                draft: {
                    ...getInitialState().draft,
                    mode,
                    source_type: modeToSourceType[mode],
                    name: state.draft.name,
                    ...defaultsForMode,
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'AUDIENCE_EDITOR__UPDATE_FIELD': {
            let nextState = {
                ...state,
                ...action.payload,
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'AUDIENCE_EDITOR__UPDATE_RETARGETING_TYPE': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    ...applyRetargetingType(action.payload.type),
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'AUDIENCE_EDITOR__TOGGLE_INCLUDE_VIEW_THROUGH_ATTRIBUTION': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    include_view_through_attribution: !state.draft.include_view_through_attribution,
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'AUDIENCE_EDITOR__TOGGLE_INCLUDE_CLICK_THROUGH_ATTRIBUTION': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    include_click_through_attribution: !state.draft
                        .include_click_through_attribution,
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }

        case 'AUDIENCE_EDITOR__UPLOADED_DASHBOARD_OPEN_MODAL': {
            return {
                ...state,
                audienceId: action.payload.audienceId,
                isModalOpen: true,
            };
        }

        case 'AUDIENCE_EDITOR__UPLOADED_DASHBOARD_CLOSE_MODAL': {
            return {
                ...state,
                isModalOpen: false,
            };
        }

        default: {
            return state;
        }
    }
}

function initializeDraft(state, action) {
    const { audience, data } = action.payload;

    const nextState = {
        ...state,
        isInitializing: false,
        isEditing: true,
        ...data,
    };

    if (!audience) {
        return {
            ...nextState,
            isEditing: false,
        };
    }

    const { ...rest } = audience;

    return {
        ...nextState,
        draft: {
            ...state.draft,
            ...rest,
        },
    };
}

function validateDraft(draft, validators) {
    const draftErrors = validate(draft, validators);
    const errors = indexErrors(draftErrors);

    return errors;
}

function indexErrors(errorList) {
    const errors = {};
    _.each(errorList, error => {
        errors[error.field] = [error.message];
    });
    return errors;
}

// Reset event name when not retargeting is not conversion
function applyRetargetingType(retargetingType) {
    let changes = {
        retargeting_type: retargetingType,
    };

    if (retargetingType !== 'conversion') {
        changes.event_name = undefined;
    }

    return changes;
}

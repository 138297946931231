import get from 'lodash/get';

export default function selector(storeState) {
    const { audienceSegments } = get(storeState, 'manifoldDashboard');
    const organizationId = get(storeState, 'profile.organizationId');

    return {
        audienceSegments,
        organizationId,
    };
}

import _ from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';

import { DialogTitle } from 'widgets-v6/modal';
import { HealthChip } from 'widgets-v6/health-chip';
import { DarkInfoTooltip } from 'widgets-v6/tooltip';
import { SingleSelect } from 'widgets-v6/select';

import StatusToggle from 'widgets-v5/status-toggle';
import { formatNumber_currency } from 'utils/formatting';
import {
    canToggleStatus,
    canArchiveCampaign,
    canEditCampaign,
    canDuplicateCampaign,
} from 'states/resources/campaigns/business-logic';
import { Summary, useCampaignSummary } from '../campaign-summary';
import { archiveCampaign, openArchiveConfirmation, closeArchiveConfirmation } from '../../actions';

const useStyles = makeStyles(theme => ({
    cardWrapper: {
        padding: 16,
        minHeight: 160,
    },
    cardHeader: {
        position: 'relative',
        marginBottom: 5,
    },
    cardTitle: {
        marginTop: 5,
    },
    cardActions: {
        position: 'absolute',
        right: 0,
    },
    notes: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    drawer: {
        width: 550,
        padding: theme.spacing(2),
    },
}));

const healthStatus = {
    bad: {
        label: 'Critical',
        color: '#f36a7f',
    },
    good: {
        label: 'On Track',
        color: '#13baaa',
    },
    ok: {
        label: 'Behind',
        color: '#f8cf61',
    },
    pending: {
        label: 'Scheduled',
        color: '#9fbfff',
    },
};

const CampaignDetail = props => {
    const {
        campaign,
        openCampaignEditForm,
        duplicateCampaign,
        openCampaignHistory,
        toggleCampaignStatus,
        campaignCreator,
        campaignHealth,
    } = props;
    const togglePosition = campaign.paused ? 'left' : 'right';
    const isAgencyMargin = campaign.revenueModel === 'agencyMargin';
    const [isNotesOverflowed, setOverflow] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [duplicateStatus, setDuplicateStatus] = useState('pending');
    const [duplicateBehaviour, setDuplicateBehaviour] = useState(null);
    const isDuplicating = useRef(false);
    const notesRef = useRef();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { fetchArchivedAdsAndCreatives, archivedAdsAndCreatives } = useCampaignSummary();

    useEffect(() => {
        fetchArchivedAdsAndCreatives({ campaignId: campaign.id });
    }, [campaign.id]);

    const hasArchivedAdsOrCreatives =
        (archivedAdsAndCreatives.ads.length || archivedAdsAndCreatives.creatives.length) &&
        (archivedAdsAndCreatives.ads.some(({ isArchived }) => isArchived) ||
            archivedAdsAndCreatives.creatives.some(({ isArchived }) => isArchived));

    useEffect(() => {
        if (!notesRef.current) {
            return;
        }
        let resize = () => {
            setOverflow(notesRef.current.scrollWidth > notesRef.current.clientWidth);
        };
        setOverflow(notesRef.current.scrollWidth > notesRef.current.clientWidth);
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [notesRef.current]);

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDuplicateStatus('pending');
        setDialogOpen(false);
        setDuplicateBehaviour(null);
    };

    const handleClickArchive = () => {
        dispatch(openArchiveConfirmation());
    };

    return (
        <Card className={classes.cardWrapper}>
            <div className={classes.cardHeader}>
                <Grid container>
                    <Grid item xs={5} lg={6}>
                        <Grid container spacing={1} alignItems="center">
                            {canToggleStatus(campaign) && (
                                <Grid item>
                                    <StatusToggle
                                        status={campaign.status}
                                        onClick={toggleCampaignStatus}
                                        togglePosition={togglePosition}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    className={classes.cardTitle}
                                >
                                    Campaign Details
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} lg={6}>
                        <Summary campaignId={campaign.id} />
                        {canEditCampaign(campaign) && (
                            <Tooltip title={<Typography variant="body2">Edit</Typography>}>
                                <IconButton onClick={openCampaignEditForm} size="small">
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {canDuplicateCampaign(campaign) && (
                            <Tooltip title={<Typography variant="body2">Duplicate</Typography>}>
                                <IconButton onClick={handleClickOpen} size="small">
                                    <FileCopyOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={<Typography variant="body2">History</Typography>}>
                            <IconButton
                                onClick={openCampaignHistory}
                                size="small"
                                className={classes.actionButton}
                            >
                                <AccessTimeOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        {canArchiveCampaign(campaign) && (
                            <Tooltip title={<Typography variant="body2">Archive</Typography>}>
                                <IconButton onClick={handleClickArchive} size="small">
                                    <ArchiveIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </div>
            <div>
                {_.map(campaign.custom_fields, customField => (
                    <div key={customField.name}>
                        {customField.value === '' ? null : (
                            <Typography variant="caption" key={customField.name}>
                                <strong>{customField.name}:</strong> {customField.value}
                            </Typography>
                        )}
                    </div>
                ))}
                {!isAgencyMargin && campaign.campaign_max_total_spend_local === 0 ? null : (
                    <div>
                        <Typography variant="caption">
                            <strong>
                                {isAgencyMargin ? 'Revenue Budget' : 'Total Cost Budget'}:
                            </strong>{' '}
                            {formatNumber_currency(
                                isAgencyMargin
                                    ? campaign.total_billings_local
                                    : campaign.campaign_max_total_spend_local
                            )}
                        </Typography>
                    </div>
                )}
                {campaignCreator ? (
                    <div>
                        <Typography variant="caption">
                            <strong>Assignee:</strong> {campaignCreator}
                        </Typography>
                    </div>
                ) : null}
                {campaign.notes ? (
                    <Tooltip
                        title={<Typography variant="body2">{campaign.notes}</Typography>}
                        disableHoverListener={!isNotesOverflowed}
                    >
                        <div ref={notesRef} className={classes.notes}>
                            <Typography variant="caption">
                                <strong>Notes:</strong> {campaign.notes}
                            </Typography>
                        </div>
                    </Tooltip>
                ) : null}
                {campaign.budget_allocation_method === 'auto' && campaignHealth ? (
                    <div>
                        <Typography variant="caption">
                            <strong>Campaign Status </strong>
                            <DarkInfoTooltip
                                title={
                                    'Green, yellow, or red circles indicate whether the campaign is pacing within 90%, within 80%, or less than 80%.'
                                }
                                placement="bottom-start"
                            />
                            <strong> : </strong>
                        </Typography>
                        <HealthChip
                            label={healthStatus[campaignHealth].label}
                            customcolor={healthStatus[campaignHealth].color}
                            size="small"
                        />
                    </div>
                ) : null}
            </div>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle className={classes.title}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">Duplicating Campaign...</Typography>
                        <IconButton
                            onClick={handleClose}
                            disabled={duplicateStatus === 'loading'}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The new campaign will be paused and will require new start/end dates. Any
                        scheduled reports or shared reports will also be removed.
                    </DialogContentText>
                    {hasArchivedAdsOrCreatives ? (
                        <DialogContentText>
                            <SingleSelect
                                required
                                placeholder="Select a behaviour"
                                value={duplicateBehaviour}
                                onChange={setDuplicateBehaviour}
                                label="This campaign contains archived ads or creatives. Include archived items in duplicated campaign?"
                                options={[
                                    {
                                        label: 'Duplicate and unarchive items',
                                        value: 'duplicateUnarchive',
                                    },
                                    {
                                        label: 'Duplicate, but keeps items archived',
                                        value: 'duplicateKeep',
                                    },
                                    {
                                        label: 'Skip archived items',
                                        value: 'skipArchived',
                                    },
                                ]}
                            />
                        </DialogContentText>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={async () => {
                            if (!isDuplicating.current) {
                                setDuplicateStatus('loading');
                                isDuplicating.current = true;
                                await duplicateCampaign({ duplicateBehaviour });
                                handleClose();
                                setTimeout(() => {
                                    isDuplicating.current = false;
                                }, 1000);
                            }
                        }}
                        color="primary"
                        variant="contained"
                        disabled={
                            duplicateStatus === 'loading' ||
                            (hasArchivedAdsOrCreatives && !duplicateBehaviour)
                        }
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <ArchiveConfirmation campaign={campaign} />
        </Card>
    );
};

function ArchiveConfirmation(props) {
    const { campaign } = props;

    const [status, setStatus] = useState('pending');
    const dispatch = useDispatch();
    const { isArchiveConfirmationOpen } = useSelector(state => state.campaignsOverview);

    const handleCancel = () => {
        dispatch(closeArchiveConfirmation());
    };

    const handleArchive = async () => {
        try {
            if (status === 'loading') {
                return;
            }
            setStatus('loading');
            await dispatch(archiveCampaign(campaign.id));
            setStatus('pending');
            handleCancel();
        } catch (err) {
            setStatus('pending');
        }
    };

    if (!campaign) {
        return null;
    }

    return (
        <Dialog disableEscapeKeyDown maxWidth="sm" open={isArchiveConfirmationOpen}>
            <DialogTitle>
                #{campaign.id} {campaign.name}
            </DialogTitle>
            <DialogContent>
                This campaign will be permanently archived and can no longer be edited or set live.
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleArchive}
                    disabled={status === 'loading'}
                    variant="outlined"
                    color="primary"
                >
                    Archive
                </Button>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CampaignDetail;

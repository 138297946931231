import _ from 'lodash';

export default function selector(storeState, props) {
    const {
        currentOrganization,
        filteredUsers,
        isPageLoading,
        isSideBarLoading,
        isTableLoading,
        filteredOrganizationsList,
        ownOrganizationType,
    } = _.get(storeState, 'adminV2.childOrganization');
    const ownOrganizationId = _.get(storeState, 'profile.organizationId');
    return {
        currentOrganization,
        filteredUsers,
        isPageLoading,
        isSideBarLoading,
        isTableLoading,
        filteredOrganizationsList,
        ownOrganizationType,
        ownOrganizationId,
    };
}

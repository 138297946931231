import React, { Component } from 'react';

import { Cell, Row, ComposableDataTable } from 'widgets-v5/data-table';
import http from 'utils/http';

const fetcher = {
    fetch(request) {
        return http().post('report/stats', request);
    },
};

function findNextSplit({ dimension, splits }) {
    const statIndex = _.indexOf(splits, dimension);
    if (statIndex === -1) {
        return null;
    }

    const nextSplit = splits[statIndex + 1];

    if (!nextSplit) {
        return null;
    }

    return nextSplit;
}

export class PivotTableData {
    constructor() {
        this.tree = null;
        this.idSeq = 0;
        this.request = null;

        this._emitChange = _.debounce(() => {
            if (this.changeHandler) {
                this.changeHandler(this);
            }
        }, 0);

        this._emitError = _.debounce(() => {
            if (this.errorHandler) {
                this.errorHandler(this);
            }
        }, 0);
    }

    onChange(changeHandler) {
        this.changeHandler = changeHandler;
    }

    onError(errorHandler) {
        this.errorHandler = errorHandler;
    }

    async init({ request }) {
        try {
            const statRoot = await fetcher.fetch({
                ...request,
                split: [request.split[0]],
            });

            this.request = request;
            this.tree = this._generateTree({
                rootId: this._getId(),
                statRoot,
                path: [],
            });

            this._emitChange();
        } catch (err) {
            this._emitError();

            throw err;
        }
    }

    destroy() {
        this.changeHandler = null;
    }

    getNode(nodeId) {
        return this.tree[nodeId];
    }

    collapse(nodeId) {
        this.tree = {
            ...this.tree,
            [nodeId]: {
                ...this.tree[nodeId],
                isExpanded: false,
            },
        };

        this._emitChange();
    }

    async expand(nodeId) {
        const node = this.getNode(nodeId);

        this.tree = {
            ...this.tree,
            [nodeId]: {
                ...this.tree[nodeId],
                isExpanded: true,
            },
        };

        // Don't fetch data if the note was previously expanded
        if (node.hasExpanded) {
            this._emitChange();
            return;
        }

        const nextSplit = findNextSplit({
            dimension: node.dimension,
            splits: this.request.split,
        });

        if (!nextSplit) {
            return;
        }

        this.tree = {
            ...this.tree,
            [nodeId]: {
                ...this.tree[nodeId],
                isExpanding: true,
            },
        };

        this._emitChange();

        const match = {};
        _.each(node.path, split => {
            match[split.dimension] = [split.group];
        });

        try {
            const statRoot = await fetcher.fetch({
                ...this.request,
                match: {
                    ...this.request.match,
                    ...match,
                },
                split: [nextSplit],
            });

            this.tree = {
                ...this.tree,
                [nodeId]: {
                    ...this.tree[nodeId],
                    isExpanding: false,
                },
            };

            this._mergeTree(nodeId, statRoot);

            this._emitChange();
        } catch (err) {
            this._emitError();

            throw err;
        }
    }

    getTotalRow() {
        return this.tree[0];
    }

    getRows(opts = {}) {
        if (!this.tree) {
            return [];
        }

        const rows = [];

        const walk = node => {
            // Apply filter hook
            if (opts.filterRow && opts.filterRow(node) === false) {
                return;
            }
            const depth = node.depth;
            const splitLen = this.request.split.length;

            rows.push({
                ...node,
                isExpandable: node.depth < this.request.split.length,
            });

            if (!node.isExpanded) {
                return;
            }

            // Apply sort hook
            let childIds = node.children;
            if (opts.sortGroups) {
                const children = _.map(node.children, id => this.tree[id]);
                const sortedChildren = opts.sortGroups(children);

                childIds = _.map(sortedChildren, c => c.id);
            }

            _.each(childIds, childId => walk(this.tree[childId]));
        };

        // 0 is always root
        walk(this.tree[0]);

        // Ignore first row which is the root
        return rows.slice(1);
    }

    _mergeTree(nodeId, statRoot) {
        const node = this.getNode(nodeId);

        const tree = this._generateTree({
            rootId: nodeId,
            statRoot,
            path: node.path,
        });

        this.tree = {
            ...this.tree,
            // merge in new child tree
            ...tree,
            [nodeId]: {
                // keep existing properies of the original node
                ...node,
                // update ref to new children
                children: tree[nodeId].children,
                isExpanded: true,
                hasExpanded: true,
            },
        };
    }

    _getId() {
        return this.idSeq++;
    }

    _generateTree({ rootId, statRoot, path }) {
        const tree = {};

        const walk = ({ id, node, path }) => {
            const children = _.map(node.stats, stat => {
                const childId = this._getId();

                walk({
                    id: childId,
                    node: stat,
                    path: path.concat({
                        dimension: stat.dimension,
                        group: stat.group,
                        label: stat.label,
                    }),
                });

                return childId;
            });

            const record = {
                ..._.omit(node, 'stats'),
                _id: node.id,
                isExpanded: false,
                id,
                children,
                path,
                depth: path.length,
            };

            tree[id] = record;
        };

        walk({
            id: rootId,
            node: statRoot,
            path,
        });

        // The root should always be expanded initially
        tree[rootId] = {
            ...tree[rootId],
            isExpanded: true,
        };

        return tree;
    }
}
// export default PivotTable;

import _ from 'lodash';
import c from 'common/constants/flux-events';

export const NAME = 'campaign';

export default function(state = {}, action) {
    switch (action.type) {
        default: {
            return state;
        }
    }
}

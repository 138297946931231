import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    timeframe: 'campaign_to_date',
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.CAMPAIGN_REPORT__INITIALIZE: {
            const { campaign, organizationTimezone } = action.payload;

            let nextState = { ...state };

            nextState = applyDefaultDateTimeTimezone(state, campaign, organizationTimezone);

            return nextState;
        }
        case c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_FILTER: {
            const { dateRange } = action.payload;

            return {
                ...state,
                ...dateRange,
            };
        }
        case c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_TIMEZONE: {
            return {
                ...state,
                timezone: action.payload.timezone,
            };
        }
        default:
            return state;
    }
}

function applyDefaultDateTimeTimezone(state, campaign, organizationTimezone) {
    if (state.timezone) {
        return state;
    }

    return {
        ...state,
        start: campaign.start,
        end: campaign.end,
        timezone: organizationTimezone,
    };
}

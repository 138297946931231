import _ from 'lodash';

export default function selector(storeState) {
    const { audienceSegments, totalSegmentCount } = _.get(storeState, 'retargetingDashboard');
    const { isLoading } = _.get(storeState, 'retargetingDashboard');
    const organizationId = _.get(storeState, 'profile.organizationId');

    return {
        audienceSegments,
        isLoading,
        totalSegmentCount,
        organizationId,
    };
}

import _ from 'lodash';
import validate from 'utils/validate';
import { validateCreativeDraft } from 'forms/creative-form/services';

const initialState = {
    isCreativeWarningOpen: false,
};

export const NAME = 'creativeAuditWarningModal';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'CREATIVE_BULK_EDIT_FORM__OPEN_WARNING_MODAL': {
            return {
                ...state,
                isCreativeWarningOpen: true,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__CLOSE_WARNING_MODAL': {
            return {
                ...state,
                isCreativeWarningOpen: false,
            };
        }

        default: {
            return state;
        }
    }
}

import React, { useEffect } from 'react';
import createReactClass from 'create-react-class';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import { useCampaignPageContainer } from './v2';
import selector from './selector';
import CampaignOverviewLayoutHeaderToolbar from './modules/campaign-overview-layout-header-toolbar';

import { PanelsLayout, OverlaySidebarPanel, WorkspacePanel } from 'widgets-v5/panels-layout';

import { fetchOverviewPageData } from 'pages/campaigns/campaigns-overview/actions';

const Campaign = createReactClass({
    displayName: 'Campaign',

    statics: {
        refresh(dispatch, nextState) {
            dispatch(fetchOverviewPageData(nextState.params.campaignId));
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        Campaign.refresh(dispatch, nextState, browserHistory.push);
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        if (this.props.params.campaignId !== prevProps.params.campaignId) {
            this.init();
        }
    },

    render() {
        return (
            <PanelsLayout direction="column">
                {this.props.campaign && (
                    <OverlaySidebarPanel>
                        <CampaignOverviewLayoutHeaderToolbar campaign={this.props.campaign} />
                    </OverlaySidebarPanel>
                )}
                <WorkspacePanel id="creative-overview-workspace-scroll-container">
                    {this.props.workspace}
                </WorkspacePanel>
            </PanelsLayout>
        );
    },
});

function CampaignPageContainer(props) {
    const { init, cleanup } = useCampaignPageContainer();
    useEffect(() => {
        // Check if this user has committed a typo in the URL and redirect
        // him to the setup page if so.
        const {
            location: { pathname },
        } = props;

        const wrongCampaignPath_regex = new RegExp(/\/campaigns\/\d+$/);
        if (wrongCampaignPath_regex.test(pathname)) {
            browserHistory.replace(pathname + '/setup');
            return null;
        }

        init(props.params.campaignId);
        return () => {
            cleanup();
        };
    }, [props.params.campaignId]);

    return <Campaign {...props} />;
}

export default connect(selector)(CampaignPageContainer);

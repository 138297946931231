import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { fetchCreativeHistory } from 'pages/campaigns/creatives/creatives-history/actions';
import selector from './selector';
import { HistoryTableV3 } from 'widgets/history-table';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const CreativeHistory = createReactClass({
    displayName: 'CreativeHistory',

    statics: {
        refresh(dispatch, nextState) {
            const { creativeId, campaignId } = nextState.params;

            dispatch(fetchCreativeHistory(creativeId, campaignId));
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        CreativeHistory.refresh(dispatch, nextState, browserHistory.push);
    },

    componentDidMount() {
        this.init();
    },

    handleClose() {
        const { campaignId } = this.props.params;
        browserHistory.push(`/campaigns/${campaignId}/setup/creatives`);
    },

    render() {
        const { isLoading } = this.props;

        return (
            <Dialog fullWidth maxWidth={'lg'} open={true} onClose={this.handleClose}>
                <DialogContent>
                    <HistoryTableV3
                        title="Creative History"
                        secondaryTitle={'Creative ID: ' + this.props.historyTable.secondaryTitle}
                        historyEntries={this.props.historyTable.historyEntries}
                        fields={this.props.historyTable.fields}
                        organizationTimezone={this.props.organizationTimezone}
                        isLoading={isLoading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
});

export default connect(selector)(CreativeHistory);

import _ from 'lodash';
import { createSelector } from 'reselect';

import { REDUCER_KEY } from './reducer';
import { getEnvironmentSettings } from 'services/environment';
import { TORSTAR_ID } from '../constants';
import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';

const filtersByType = [
    {
        label: 'Pelmorex Audiences',
        value: 'pelmorex',
        categories: [
            {
                label: 'Precise Geolocation',
                value: 'precise_geolocation',
                name: 'Precise Geolocation',
                source: 'pds_standard',
                type: 'Pelmorex Audiences',
            },
            {
                label: 'Flex Geolocation (Pelmorex Audiences)',
                value: 'flex_geolocation_pelmorex',
                name: 'Flex Geolocation',
                source: 'flex_standard_brand',
                type: 'Pelmorex Audiences',
            },
            {
                label: 'Online Behavioural',
                value: 'online_behavioural',
                name: 'Online Behavioural',
                source: 'pds_behaviour',
                type: 'Pelmorex Audiences',
            },
            {
                label: 'CanaCode',
                value: 'canacode',
                name: 'CanaCode',
                source: 'pds_manifold',
                type: 'Pelmorex Audiences',
            },
            {
                label: 'Environics',
                value: 'environics',
                name: 'Environics',
                source: 'pds_environics',
                type: 'Pelmorex Audiences',
            },
            {
                label: 'Mastercard',
                value: 'mastercard',
                name: 'Mastercard',
                source: 'pds_mastercard',
                type: 'Pelmorex Audiences',
            },
            {
                label: 'Declared Age',
                value: 'declared_age',
                name: 'Declared Age',
                source: 'pds_age',
                type: 'Pelmorex Audiences',
            },
        ],
    },
    {
        label: 'Custom Audiences',
        value: 'custom',
        categories: [
            {
                label: 'Pelmorex Data',
                value: 'pelmorex_data',
                name: 'Pelmorex Data',
                source: 'pds_custom',
                type: 'Custom Audiences',
            },
            {
                label: 'Flex Geolocation (Custom Audiences)',
                value: 'flex_geolocation_custom',
                name: 'Flex Geolocation',
                source: 'flex_custom_brand',
                type: 'Custom Audiences',
            },
            {
                label: 'Retargeting',
                value: 'retargeting',
                name: 'Retargeting',
                source: 'retargeting',
                type: 'Custom Audiences',
            },
            {
                label: 'Uploaded',
                value: 'uploaded',
                name: 'Uploaded',
                source: 'upload',
                type: 'Custom Audiences',
            },
        ],
    },
    {
        label: 'DMP Integrations',
        value: 'dmp',
        categories: [
            {
                label: 'Adobe',
                value: 'adobe',
                name: 'Adobe',
                source: 'adobe',
                type: 'DMP Integrations',
            },
            {
                label: 'Permutive',
                value: 'permutive',
                name: 'Permutive',
                source: 'permutive',
                type: 'DMP Integrations',
                showOn: orgId => orgId === TORSTAR_ID,
            },
        ],
    },
];

const selectAudiences = state => _.get(state, `${REDUCER_KEY}.audiences`);
const selectFilters = state => _.get(state, `${REDUCER_KEY}.filters`);
export const selectRestrictedSegments = state => _.get(state, 'profile.restrictedSegments', []);
export const selectOrgId = state => _.get(state, 'profile.organizationId', void 0);

export const filterAudiences = createSelector(
    selectAudiences,
    selectFilters,
    selectRestrictedSegments,
    selectOrgId,
    (audiences, filters, restrictedSegments, orgId) => {
        const selectedFilters = _(filtersByType)
            .map(type => type.categories)
            .flatten()
            .filter(category => _.includes(filters, category.value))
            .filter(category => !_.includes(restrictedSegments, category.source))
            .filter(category => !category.showOn || category.showOn(orgId))
            .map(category => ({ type: category.type, category: category.name }))
            .value();

        if (selectedFilters.length > 0) {
            const filteredAudiences = _.filter(audiences, audience => {
                return _.find(
                    selectedFilters,
                    filter => filter.type === audience.type && filter.category === audience.category
                );
            });
            return filteredAudiences;
        }

        return audiences;
    }
);

export const filteredfiltersByType = createSelector(
    selectRestrictedSegments,
    selectOrgId,
    (restrictedSegments, orgId) => {
        let audienceFiltersByType = _.cloneDeep(filtersByType);
        const excludedAudiences = getEnvironmentSettings().excludedAudiences();

        _.forEach(filtersByType, (type, index) => {
            const categories = _(type.categories)
                .filter(category => category && !_.includes(restrictedSegments, category.source))
                .filter(category => !category.showOn || category.showOn(orgId))
                .filter(category => {
                    if (!category) {
                        return false;
                    }
                    const isRestrictedSegment = _.includes(restrictedSegments, category.source);
                    const excludedAudienceList = excludedAudiences[type.value] || [];
                    const isAudienceExcluded = _.includes(excludedAudienceList, category.source);

                    if (isRestrictedSegment || isAudienceExcluded) {
                        return false;
                    }
                    return true;
                })
                .value();

            audienceFiltersByType[index].categories = categories;
        });
        return audienceFiltersByType;
    }
);

export const editAudiences = createSelector(() => {
    return can(PermissionsMapping.AUDIENCE__EDIT);
});

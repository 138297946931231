import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const generateInitialState = (campaign = {}) => {
    let initialState = {
        filterOptions: {
            search: '',
            sort: 'byDateCreated', //'byAdEndDate',
        },
        isLoading: false,
        hiddenColumns: {},
        fetchingHiddenColumns: false,
        userEtag: null,
        isFinishedFetchingSegmentData: false,
        adsHealth: {},
        adsBeingProcessed: [],
        selectedAds: [],
        isArchiveConfirmationOpen: false,
        adToArchive: null,
        shouldShowArchivedAds: campaign.isArchived ? true : false,
    };

    return initialState;
};

const adsRegex = /^\/campaigns\/\d+\/setup\/ads\/?$/;
const parseRoute = path => adsRegex.test(path);

export const NAME = 'adsOverview';

export default function(state = generateInitialState(), action) {
    switch (action.type) {
        case 'OVERVIEW_ADS__USER_PREFERENCES_AND_ETAG': {
            return {
                ...state,
                fetchingHiddenColumns: true,
            };
        }
        case 'OVERVIEW_ADS__USER_PREFERENCES_AND_ETAG_FETCH__SUCCESS': {
            const { hiddenColumns, etag } = action.payload;
            const newHiddenColumns = _.chain(hiddenColumns)
                .map(field => ({
                    field,
                    value: true,
                }))
                .keyBy('field')
                .mapValues('value')
                .value();

            return {
                ...state,
                hiddenColumns: newHiddenColumns,
                userEtag: etag,
                fetchingHiddenColumns: false,
            };
        }
        case 'OVERVIEW_ADS__USER_PREFERENCES_AND_ETAG_FETCH__FAIL': {
            return {
                ...state,
                hiddenColumns: {
                    ad_start_date: true,
                    ad_end_date: true,
                    ad_bid_price: true,
                    ad_margin: true,
                    ad_win_rate: true,
                },
                fetchingHiddenColumns: false,
            };
        }
        case c.OVERVIEW_ADS__INIT: {
            const { campaignId, campaign } = action.payload;

            return {
                ...findCacheState(campaignId, state, generateInitialState(campaign)),
                adsHealth: state.adsHealth,
            };
        }

        case c.OVERVIEW_ADS__FETCH: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case c.OVERVIEW_ADS__FETCH__SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case c.OVERVIEW_ADS__FETCH__FAIL: {
            return {
                ...state,
                isLoading: false,
                ownerName: action.payload.ownerName,
            };
        }

        case '@@router/LOCATION_CHANGE': {
            const isOverviewAdsUrl = parseRoute(action.path);

            if (!isOverviewAdsUrl) {
                return state;
            }

            return {
                ...state,
                filterUrl: action.path,
            };
        }

        case c.OVERVIEW_ADS__AD_LIST__FILTER: {
            return {
                ...state,
                // isLoading: true,
                filterOptions: {
                    ...state.filterOptions,
                    [action.payload.filterType]: action.payload.value,
                },
            };
        }

        case 'OVERVIEW_ADS__TOGGLE_COLUMN': {
            const { column } = action.payload;
            return {
                ...state,
                hiddenColumns: {
                    ...state.hiddenColumns,
                    [column]: !state.hiddenColumns[column],
                },
            };
        }

        case 'OVERVIEW_ADS__ADS_HEALTH_FETCH__SUCCESS': {
            const { fetchedAds } = action.payload;

            const adsHealth = {};

            _.each(fetchedAds, ad => {
                adsHealth[ad.id] = _.get(ad, 'metadata.progress.progressData.health');
            });

            return {
                ...state,
                adsHealth,
            };
        }

        case 'OVERVIEW_ADS__AD__PAUSE':
        case 'OVERVIEW_ADS__AD__UNPAUSE': {
            const { adIds } = action.payload;

            const adsBeingProcessed = adIds;

            return {
                ...state,
                adsBeingProcessed,
            };
        }

        case 'OVERVIEW_ADS__AD__PAUSE_SUCCESS':
        case 'OVERVIEW_ADS__AD__UNPAUSE_SUCCESS': {
            const adsBeingProcessed = [];

            return {
                ...state,
                adsBeingProcessed,
                selectedAds: [],
            };
        }

        case 'OVERVIEW_ADS__SELECT_AD': {
            const { adId, checked } = action.payload;

            let newSelectedAds;

            if (checked) {
                newSelectedAds = _.concat(state.selectedAds, [adId]);
            } else {
                newSelectedAds = _.filter(state.selectedAds, row => row !== adId);
            }

            return {
                ...state,
                selectedAds: newSelectedAds,
            };
        }

        case 'OVERVIEW_ADS__SELECT_ALL': {
            const { checked, nonStaleAds } = action.payload;

            let newSelectedAds;

            if (checked) {
                newSelectedAds = _.map(nonStaleAds, ad => ad.id);
            } else {
                newSelectedAds = [];
            }

            return {
                ...state,
                selectedAds: newSelectedAds,
            };
        }
        case 'OVERVIEW_ADS__ARCHIVE_CONFIRMATION__OPEN': {
            const { campaignId, ad } = action.payload;
            return {
                ...state,
                isArchiveConfirmationOpen: true,
                adToArchive: ad,
                campaignId,
            };
        }
        case 'OVERVIEW_ADS__ARCHIVE_CONFIRMATION__CLOSE': {
            return {
                ...state,
                isArchiveConfirmationOpen: false,
                adToArchive: null,
                campaignId: null,
            };
        }
        case 'OVERVIEW_ADS__TOGGLE_ARCHIVE_AD_VISIBILITY': {
            return {
                ...state,
                shouldShowArchivedAds: !state.shouldShowArchivedAds,
            };
        }
        default:
            return state;
    }
}

import _ from 'lodash';

export default function selector(storeState, props) {
    const isOpen = _.get(storeState, 'deleteUserModal.isOpen');
    const isDeleting = _.get(storeState, 'deleteUserModal.isDeleting');
    return {
        isOpen,
        isDeleting,
    };
}

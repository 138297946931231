import _ from 'lodash';

import c from 'common/constants/flux-events';

import { calcTotalStats } from 'services/resources/stats';

/*
export default function ad (state = {}, action) {

    const adId = _.get(action, 'payload.adId');


    switch (action.type) {
        case c.SYSTEM__AD_STATS_TOTAL__SET_LOADING:
            return _.reduce(
                state,
                (acc, stats, adId) => ({
                    ...acc,
                    [adId]: {
                        ...stats,
                        standardTotalIsLoading: true
                    }
                }),
                state
            );
    }

    if (!adId) {
        return state;
    }

    return {
        ...state,
        [adId]: reducer(state[adId], action)
    };
}
*/

export const NAME = 'resources.stats.ad';

const initialState = {
    liveByDate: [],
    liveByDateIsLoading: false,

    liveByHour: [],
    liveByHourIsLoading: false,

    standardByDate: [],
    standardByDateIsLoading: false,

    standardByHour: [],
    standardByHourIsLoading: false,

    standardTotal: undefined,
    standardTotalIsLoading: false,
};

export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__AD_STATS_TOTAL__FETCH_ALL: {
            const { adIds } = action.payload;

            const newState = {};
            _.each(adIds, adId => {
                newState[adId] = {
                    ...state[adId],
                    standardTotalIsLoading: true,
                };
            });

            return {
                ...state,
                ...newState,
            };
        }
        case c.SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS: {
            const { adIds, statsResponse } = action.payload;

            // create a lookup table to access stats quickly
            const statsLookup = {};
            _.each(statsResponse.stats, stat => {
                const adId = _.get(stat, 'split.0.group');
                if (!adId) {
                    return;
                }

                statsLookup[adId] = stat;
            });

            // stop loading state and save stats
            const newState = {};
            _.each(adIds, adId => {
                newState[adId] = {
                    ...state[adId],
                    standardTotalIsLoading: false,
                    standardTotal: statsLookup[adId],
                };
            });

            return {
                ...state,
                ...newState,
            };
        }
        case c.SYSTEM__AD_STATS_TOTAL__SET_LOADING: {
            const nextState = {};

            _.each(state, (value, adId) => {
                nextState[adId] = {
                    ...state[adId],
                    standardTotalIsLoading: true,
                };
            });

            return nextState;
        }
        case c.SYSTEM__AD_STATS_BY_DATE__FETCH: {
            const { adId } = action.payload;
            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    standardByDateIsLoading: true,
                },
            };
        }

        case c.SYSTEM__AD_STATS_BY_DATE__FETCH__SUCCESS: {
            const { adId } = action.payload;

            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    standardByDate: action.payload.stats,
                    standardByDateIsLoading: false,
                },
            };
        }

        case c.SYSTEM__AD_STATS_BY_HOUR__FETCH: {
            const { adId } = action.payload;

            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    standardByHourIsLoading: true,
                },
            };
        }

        case c.SYSTEM__AD_STATS_BY_HOUR__FETCH__SUCCESS: {
            const { adId } = action.payload;

            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    standardByHour: action.payload.stats,
                    standardByHourIsLoading: false,
                },
            };
        }

        case c.SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH: {
            const { adId } = action.payload;

            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    liveByHourIsLoading: true,
                },
            };
        }

        case c.SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH__SUCCESS: {
            const { adId } = action.payload;

            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    liveByHour: action.payload.stats,
                    liveByHourIsLoading: false,
                },
            };
        }

        case 'SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH_ALL__SUCCESS': {
            const { adStats } = action.payload;

            let nextState = {
                ...state,
            };

            _.each(adStats, ({ id, stats }) => {
                nextState = {
                    ...nextState,
                    [id]: {
                        ...nextState[id],
                        liveByHour: stats,
                        liveByHourIsLoading: false,
                    },
                };
            });

            return nextState;
        }

        case c.SYSTEM__AD_STATS_TOTAL__FETCH: {
            const { adId } = action.payload;

            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    standardTotalIsLoading: true,
                },
            };
        }

        case c.SYSTEM__AD_STATS_TOTAL__FETCH__SUCCESS: {
            const { adId } = action.payload;

            return {
                ...state,
                [adId]: {
                    ...state[adId],
                    standardTotal: action.payload.stat,
                    standardTotalIsLoading: false,
                },
            };
        }

        case 'SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS': {
            const { adStats } = action.payload;

            let nextState = {
                ...state,
            };

            _.each(adStats, ({ id, stats }) => {
                nextState = {
                    ...nextState,
                    [id]: {
                        ...nextState[id],
                        standardTotal: stats,
                        standardTotalIsLoading: false,
                    },
                };
            });

            return nextState;
        }

        default:
            return state;
    }
}

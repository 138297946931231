import _ from 'lodash';

import { isCampaignCpaOptimized } from 'states/resources/campaigns/business-logic';

export default function selector(storeState) {
    const isLoading = _.get(storeState, `conversionsOverview.isLoading`, false);
    const mode = _.get(storeState, `conversionsOverview.mode`, 'view');
    const campaign = _.get(storeState, `conversionsOverview.campaign`, {});
    const advertiserConversionsDraft = _.get(
        storeState,
        `conversionsOverview.advertiserConversionsDraft`,
        []
    );

    return {
        isLoading,
        isCampaignCpaOptimized: isCampaignCpaOptimized(campaign),
        conversions: mode === 'view' ? campaign.advertiserConversions : advertiserConversionsDraft,
        mode,
        campaignId: campaign.id,
    };
}

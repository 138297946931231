import _ from 'lodash';

import c from 'common/constants/flux-events';
import http from 'utils/http';

const actions = {
    getOne(campaignId, scheduledReportId) {
        return dispatch => {
            const promise = http()
                .get(`campaigns/${campaignId}/scheduled-reports/${scheduledReportId}`)
                .then(
                    scheduledReport => {
                        dispatch({
                            type: c.SYSTEM__SCHEDULED_REPORT__FETCH__SUCCESS,
                            payload: { scheduledReport },
                        });

                        return scheduledReport;
                    },
                    err => {
                        console.error(`GET ONE scheduled report error`, err);

                        throw err;
                    }
                );

            return promise;
        };
    },
    create(campaignId, payload) {
        return dispatch => {
            return http()
                .post(`campaigns/${campaignId}/scheduled-reports`, payload)
                .then(
                    scheduledReport => {
                        dispatch({
                            type: c.SYSTEM__SCHEDULED_REPORT__FETCH__SUCCESS,
                            payload: { campaignId, scheduledReport },
                        });
                        return scheduledReport;
                    },
                    err => {
                        console.error(`CREATE scheduled report error`, err);

                        throw err;
                    }
                );
        };
    },
    update(scheduledReportId, campaignId, payload) {
        return (dispatch, getState) => {
            const etag = _.get(
                getState(),
                `resources.scheduledReports.${scheduledReportId}.attr._etag`
            );

            const promise = http()
                .setIfMatch(etag)
                .patch(`campaigns/${campaignId}/scheduled-reports/${scheduledReportId}`, payload)
                .then(
                    scheduledReport => {
                        dispatch({
                            type: c.SYSTEM__SCHEDULED_REPORT__FETCH__SUCCESS,
                            payload: { scheduledReport },
                        });
                        return scheduledReport;
                    },
                    err => {
                        console.error(`UPDATE scheduled report error`, err);

                        throw err;
                    }
                );

            return promise;
        };
    },
};

export default actions;

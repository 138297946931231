import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Select, { Creatable } from 'react-select';
import classnames from 'classnames';

class MultiSelect extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.array,
    };

    static defaultProps = {
        simpleValue: true,
    };

    handleSelectChange = value => {
        if (!value) {
            return this.props.onChange([]);
        }

        const valueAsArray = this.props.simpleValue ? value.split(',') : _.map(value, v => v.value);
        // console.log('valueAsArray', valueAsArray)
        this.props.onChange(valueAsArray);
    };

    render() {
        let value =
            this.props.value && this.props.simpleValue
                ? this.props.value.join(',')
                : this.props.value;

        return (
            <div className={classnames('ef3-reactMultiSelect', this.props.className)}>
                {// <Creatable> is the up-to-date interface for react-select
                this.props.allowCreate ? (
                    <Creatable
                        multi
                        simpleValue={this.props.simpleValue}
                        onBlur={this.props.onBlur}
                        onClose={this.props.onClose}
                        autofocus={this.props.autofocus}
                        disabled={this.props.disabled}
                        value={value}
                        placeholder={this.props.placeholder}
                        options={this.props.options}
                        optionRenderer={this.props.optionRenderer}
                        delimiter={this.props.delimiter}
                        noResultsText={this.props.noResultsText}
                        isValidNewOption={this.props.isValidNewOption}
                        resetValue={this.props.resetValue || null}
                        backspaceToRemoveMessage=""
                        onChange={this.handleSelectChange}
                    />
                ) : (
                    <Select
                        multi
                        simpleValue={this.props.simpleValue}
                        onBlur={this.props.onBlur}
                        onClose={this.props.onClose}
                        autofocus={this.props.autofocus}
                        disabled={this.props.disabled}
                        value={value}
                        placeholder={this.props.placeholder}
                        options={this.props.options}
                        optionRenderer={this.props.optionRenderer}
                        delimiter={this.props.delimiter}
                        noResultsText={this.props.noResultsText}
                        onChange={this.handleSelectChange}
                        isValidNewOption={this.props.isValidNewOption}
                        onInputChange={this.props.onInputChange}
                        filterOption={this.props.filterOption}
                    />
                )}
            </div>
        );
    }
}

export default MultiSelect;

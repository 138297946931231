import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import IABCategories from 'common/constants/iab-categories';

class IabCategorySelector extends React.Component {
    static propTypes = {
        checkedCategories: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const formattedCategories2 = _.reduce(
            IABCategories,
            (acc, value, key) => {
                if (key.indexOf('-') === -1) {
                    return {
                        ...acc,
                        [key]: [],
                    };
                } else {
                    const iabParentCategory = key.split('-')[0];
                    return {
                        ...acc,
                        [iabParentCategory]: acc[iabParentCategory].concat(key),
                    };
                }
            },
            {}
        );

        const majorCategories = _.keysIn(formattedCategories2);

        this.state = {
            categoriesExpanded: false,
            subCategoriesExpanded: false,
            subCategoriesList: [],
            checkedCategories: props.checkedCategories || [],
            majorCategories,
            formattedCategories2,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            checkedCategories: nextProps.checkedCategories || this.state.checkedCategories,
        });
    }

    getValue = () => {
        return this.state.checkedCategories;
    };

    toggleCategories = () => {
        const categoriesExpanded = this.state.categoriesExpanded ? false : true;
        this.setState({ categoriesExpanded });
    };

    toggleSubCategories = majorCategory => {
        const { formattedCategories2, majorCategories } = this.state;

        const subCategoriesExpanded = true;
        const subCategoriesList = formattedCategories2[majorCategories[majorCategory]];

        this.setState({ subCategoriesExpanded, subCategoriesList });
    };

    selectCategory = category => {
        if (_.includes(this.state.checkedCategories, category)) {
            this.removeCategory(category);
            return;
        }

        const checkedCategories = this.state.checkedCategories.concat(category);

        this.setState(
            {
                checkedCategories,
            },
            () => this.triggerChange()
        );
    };

    removeCategory = category => {
        const checkedCategories = _.filter(this.state.checkedCategories, n => n !== category);

        this.setState(
            {
                checkedCategories,
            },
            () => this.triggerChange()
        );
    };

    triggerChange = () => {
        setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange(this.state.checkedCategories);
            }
        });
    };

    // NOTE, we have had to override the react event system and use the native
    // event api to close the pop up by clicking outside of the checklist - Alex
    componentDidMount() {
        document.body.addEventListener('click', this.close);
        this.refs.expandedChecklist.addEventListener('click', this.cancelClose);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.close);
        this.refs.expandedChecklist.removeEventListener('click', this.cancelClose);
    }

    cancelClose = e => {
        e.doNotCloseChecklist = true;
    };

    close = e => {
        if (!e.doNotCloseChecklist) {
            this.setState({ categoriesExpanded: false });
        }
    };

    render() {
        const inputClasses = classnames(
            'ef4-standard-input',
            'ef3-iabCategorySelector_labelRow',
            'ef6-alignment__space-between'
        );
        return (
            <div className="ef3-iabCategorySelector">
                <div className={inputClasses} onClick={this.toggleCategories}>
                    <div>
                        {this.state.checkedCategories.length > 0 ? (
                            this.state.checkedCategories.map((category, index) => (
                                <div
                                    key={category + index}
                                    className="ef3-iabCategorySelector_label"
                                >
                                    <span
                                        onClick={() => this.removeCategory(category)}
                                        className="ef3-iabCategorySelector_label_icon"
                                    >
                                        x
                                    </span>
                                    <span className="ef3-iabCategorySelector_label_text">
                                        {IABCategories[category]}
                                    </span>
                                </div>
                            ))
                        ) : (
                            <span className="ef4-standard-selector__default">
                                Select categories
                            </span>
                        )}
                    </div>
                    <i className="fa fa-caret-down dropdown-toggle__dropdown-icon ef3-iabCategorySelector__dropdown-icon" />
                </div>
                <div
                    ref="expandedChecklist"
                    className={classnames(
                        'ef3-iabCategorySelector_categories',
                        {
                            'ef3-iabCategorySelector_categories--is-expanded': this.state
                                .categoriesExpanded,
                        },
                        this.props.className
                    )}
                >
                    <div className="ef3-iabCategorySelector_categoriesMajor">
                        <ol>
                            {this.state.majorCategories.map((key, index) => (
                                <li
                                    key={key}
                                    onClick={() => this.selectCategory(key)}
                                    onMouseOver={() => this.toggleSubCategories(index)}
                                >
                                    {_.includes(this.state.checkedCategories, key) ? (
                                        <i className="fa fa-check-square-o fa-fw" />
                                    ) : (
                                        <i className="fa fa-square-o fa-fw" />
                                    )}
                                    <span className="ef3-iabCategorySelector_catergoryItem">
                                        {IABCategories[key]}
                                    </span>
                                </li>
                            ))}
                        </ol>
                    </div>
                    <div
                        className={classnames('ef3-iabCategorySelector_categoriesMinor', {
                            'is-visible': this.state.subCategoriesExpanded,
                        })}
                    >
                        <ol>
                            {_.map(this.state.subCategoriesList, key => (
                                <li key={key} onClick={() => this.selectCategory(key)}>
                                    {_.includes(this.state.checkedCategories, key) ? (
                                        <i className="fa fa-check-square-o" />
                                    ) : (
                                        <i className="fa fa-square-o" />
                                    )}
                                    <span className="ef3-iabCategorySelector_catergoryItem">
                                        {IABCategories[key]}
                                    </span>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default IabCategorySelector;

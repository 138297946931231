import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import selector from './selector';

import CampaignForm from 'forms/campaign-form';
import { useCampaignForm } from 'forms/campaign-form/v2';

class NewCampaignForm extends React.Component {
    onClose = e => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        browserHistory.push('/');
    };

    onSubmit = campaign => {
        browserHistory.push(`/campaigns/${campaign.id}/setup`);
    };

    render() {
        return <CampaignForm type={'new'} onSubmit={this.onSubmit} onClose={this.onClose} />;
    }
}

const NewCampaignFormWrapper = props => {
    const campaignForm = useCampaignForm();

    useEffect(() => {
        campaignForm.open();
    }, []);

    return <NewCampaignForm {...props} />;
};

export default connect(selector)(NewCampaignFormWrapper);

import _ from 'lodash';
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import IncludeExclude from 'widgets-v5/include-exclude';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import Select from '@mui/material/Select';
import withStyles from '@mui/styles/withStyles';
import { MultiSelect, GroupedMultiSelect } from 'widgets-v6/select';

import { IncludeExcludeLayout } from 'widgets-v6/layout';

import { getEnvironmentSettings } from 'services/environment';

function TargetApplists({ type, onChange, exclude, values, applistOptions, placeholder }) {
    return (
        <IncludeExcludeLayout type={type}>
            <IncludeExclude
                label={placeholder}
                oneColumn
                useCheckboxes
                onApply={onChange}
                values={values}
                useExcludeButton={true}
                exclude={exclude}
                leftItems={applistOptions}
            />
        </IncludeExcludeLayout>
    );
}

export const ApplistSelector = props => {
    return (
        <IncludeExcludeLayout type={props.type} label={props.label}>
            <GroupedMultiSelect
                value={props.values}
                clearable={true}
                placeholder={props.values.length === 0 ? props.placeholder : ''}
                options={props.options}
                onChange={values => props.onChange(values)}
            />
        </IncludeExcludeLayout>
    );
};

export const ApplistSummary = props => {
    const environmentSettings = getEnvironmentSettings();
    return (
        <React.Fragment>
            <IncludeExcludeLayout type="Include" label="Allowed" marginBottom>
                {props.allowedList.length > 0
                    ? props.allowedList.join(', ')
                    : environmentSettings.getAppsAndSitesPlaceholder()}
            </IncludeExcludeLayout>
            <IncludeExcludeLayout type="Exclude" label="Blocked">
                {props.blockedList.length > 0 ? props.blockedList.join(', ') : 'None'}
            </IncludeExcludeLayout>
        </React.Fragment>
    );
};

export default TargetApplists;

import _ from 'lodash';

import c from 'common/constants/flux-events';
import { validateClientDraft } from 'forms/campaign-form/services';

const initialState = {
    isLoading: true,
    isFetching: false,
    audienceSegments: [],
    flexSegments: [],
};

export const NAME = 'audienceSegmentPicker';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'AUDIENCE_SEGMENT_PICKER__AUDIENCES__FETCH_START': {
            return {
                ...state,
                isFetching: true,
            };
        }

        case 'AUDIENCE_SEGMENT_PICKER__AUDIENCES__FETCH_END': {
            return {
                ...state,
                isLoading: false,
                isFetching: false,
                audienceSegments: _.get(action.payload, 'data.audiences'),
                flexSegments: _.get(action.payload, 'data.flexSegments'),
            };
        }

        default:
            return state;
    }
}

import _ from 'lodash';
import schema from './schema';

const initialState = {
    isFormOpen: false,
    isLoading: true,
    draft: {
        first_name: '',
        last_name: '',
        email: '',
        global_role: '',
        orgId: '',
    },
    etag: null,
    errors: {},
    showErrors: false,
    userId: '',
    isSubmitting: false,
    organizationType: '',
    mode: '',
};
const options = {
    allowUnknown: true,
    abortEarly: false,
};

const processErrors = errors => {
    if (!errors.error) {
        return {};
    }

    const result = {};

    _.each(errors.error.details, err => {
        const { path, message } = err;
        result[path.join('.')] = message;
    });
    return result;
};

const validateDraft = state => {
    const errors = schema.validate(state.draft, options);
    const processedErrors = processErrors(errors);

    return {
        ...state,
        errors: processedErrors,
    };
};

export const NAME = 'adminUsersFormV2';

const handlers = {
    ADMIN_USERS_FORM__OPEN_FORM: state => {
        return {
            ...state,
            isFormOpen: true,
        };
    },

    ADMIN_USERS_FORM__CLOSE_FORM: () => {
        return {
            ...initialState,
        };
    },

    ADMIN_USERS_FORM__DRAFT_INIT_START: (state, action) => {
        return {
            ...state,
            isLoading: true,
            mode: action.payload.userId ? 'edit' : 'create',
        };
    },
    ADMIN_USERS_FORM__DRAFT_INIT_END: (state, action) => {
        return {
            ...state,
            isLoading: false,
            draft: action.payload.draft,
            userId: action.payload.draft.id,
            etag: action.payload.etag,
        };
    },
    ADMIN_USERS_FORM__UPDATE_DRAFT: (state, action) => {
        const { field, value } = action.payload;
        const newDraft = {
            ..._.get(state, 'draft'),
            [field]: value,
        };
        return {
            ...state,
            draft: newDraft,
        };
    },
    ADMIN_USERS_FORM__SHOW_ERRORS: state => {
        return {
            ...state,
            showErrors: true,
            isSubmitting: false,
        };
    },
    ADMIN_USERS_FORM__DRAFT_SUBMIT_START: state => {
        return {
            ...state,
            isSubmitting: true,
        };
    },
    ADMIN_USERS_FORM__DRAFT_SUBMIT_SUCCESS: state => {
        return {
            ...state,
            isSubmitting: false,
        };
    },
    ADMIN_USERS_FORM__DRAFT_SUBMIT_FAIL: state => {
        return {
            ...state,
            isSubmitting: false,
        };
    },
    ADMIN_USERS_FORM__ORGANIZATION_FETCH_SUCCESS: (state, action) => {
        return {
            ...state,
            organizationType: action.payload.organizationType,
        };
    },
};

export default function(state = initialState, action) {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
        nextState = validateDraft(nextState);
    }

    return nextState;
}

import _ from 'lodash';

export function makeActions(args) {
    const { reducerKey, initialState, reducers, legacyReducers = {} } = args;

    const actions = {};
    const prefixedReducers = {
        ...legacyReducers,
    };
    _.each(reducers, (reducer, key) => {
        const prefixKey = `${reducerKey}:${key}`;
        actions[key] = (payload, meta) => ({ type: prefixKey, payload, meta });
        actions[key].type = prefixKey;
        prefixedReducers[prefixKey] = reducer;
    });

    const reducer = (state = initialState, action) => {
        const { type } = action;
        if (!prefixedReducers[type]) {
            return state;
        }
        return prefixedReducers[type](state, action.payload);
    };

    return { actions, reducer };
}

import { makeActions } from 'utils/redux-tools';
import { getCurrentFlight, getMostRecentFlight } from 'states/resources/campaigns/business-logic';

export const REDUCER_KEY = 'campaignPageContainer';

export const initialState = {
    campaign: null,
    viewBy: null, // can be 'campaign' or flightId
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        cleanup: () => initialState,
        init: () => initialState,
        initSucccess: (state, campaign) => {
            let viewBy = 'campaign';

            if (campaign.flightPacingStrategy === 'campaign') {
                // find an active flight
                const currentFlight = getCurrentFlight(campaign);

                // in cases when there is no active flight (campaign ended, gap period between flights)
                // we should show the most recently ended flight
                const mostRecentFlight = getMostRecentFlight(campaign);

                if (currentFlight) {
                    viewBy = currentFlight.id;
                } else if (mostRecentFlight) {
                    viewBy = mostRecentFlight.id;
                }
            }

            return {
                ...state,
                campaign,
                viewBy,
            };
        },
        initError: state => state,
        changeView: (state, view) => ({
            ...state,
            viewBy: view,
        }),
    },
});

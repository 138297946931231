import _ from 'lodash';

export const IAS_PUBLICIS_VIEWABILITY_OPTIONS = [
    { value: 0, label: 'Off' },
    { value: 1, label: 'Target up to 40% Publicis viewability' },
    { value: 2, label: 'Target 40% Publicis viewability or higher' },
    { value: 3, label: 'Target 50% Publicis viewability or higher' },
    { value: 4, label: 'Target 60% Publicis viewability or higher' },
    { value: 5, label: 'Target 70% Publicis viewability or higher' },
];

export const IAS_PUBLICIS_VIEWABILITY_MAPPING = {};

_.each(IAS_PUBLICIS_VIEWABILITY_OPTIONS, option => {
    IAS_PUBLICIS_VIEWABILITY_MAPPING[option.value] = option.label;
});

export const IAS_PUBLICIS_VIEWABILITY_VALUES = {
    OFF: 0,
    TARGET_UP_TO_40: 1,
    TARGET_40: 2,
    TARGET_50: 3,
    TARGET_60: 4,
    TARGET_70: 5,
};

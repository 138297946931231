import _ from 'lodash';
import Joi from '@hapi/joi';

const billingTierSchema = Joi.object({
    techFee: Joi.number()
        .min(0)
        .max(100)
        .required()
        .messages({
            'number.base': 'Platform fee not allowed to be empty',
        }),
    min: Joi.number()
        .min(0)
        .required(),
    max: Joi.number()
        .min(Joi.ref('min'))
        .allow(-1)
        .required()
        .messages({
            'number.min': 'Tier max has to be greater than tier min',
        }),
});

export default Joi.object({
    name: Joi.string()
        .required()
        .messages({
            'string.empty': 'Name not allowed to be empty',
        }),
    default_timezone: Joi.string()
        .trim()
        .required()
        .messages({
            'string.empty': 'Timezone not allowed to be empty',
        }),
    currency: Joi.string()
        .trim()
        .required()
        .messages({
            'string.empty': 'Currency not allowed to be empty',
        }),
    type: Joi.string(),
    revenueModel: Joi.when('type', {
        switch: [
            {
                is: 'media_buyer',
                then: Joi.string()
                    .trim()
                    .required(),
            },
            { is: 'admin', then: Joi.string().required() },
        ],
    }).messages({
        'string.base': 'Revenue model not allowed to be empty',
        'string.empty': 'Revenue model not allowed to be empty',
    }),
    privateDealTechFee: Joi.when('type', {
        switch: [
            {
                is: 'media_buyer',
                then: Joi.number()
                    .min(0)
                    .max(100)
                    .allow(null),
            },
        ],
    }).messages({
        'number.base': 'Platform fee not allowed to be empty',
        'number.max': 'Platform fee cannot be greater than 100',
        'number.min': 'Platform fee has to be greater than 0',
        'any.required': 'Platform fee not allowed to be empty',
    }),
    privateDealInfraCost: Joi.when('type', {
        switch: [
            {
                is: 'media_buyer',
                then: Joi.number()
                    .min(0)
                    .required(),
            },
        ],
    }).messages({
        'number.base': 'Infrastructure Cost not allowed to be empty',
        'number.max': 'Infrastructure Cost cannot be greater than 100',
        'number.min': 'Infrastructure Cost has to be greater than 0',
        'any.required': 'Infrastructure Cost not allowed to be empty',
    }),
    publicInventoryInfraCost: Joi.when('type', {
        switch: [
            {
                is: 'media_buyer',
                then: Joi.number()
                    .min(0)
                    .required(),
            },
        ],
    }).messages({
        'number.base': 'Infrastructure Cost not allowed to be empty',
        'number.max': 'Infrastructure Cost cannot be greater than 100',
        'number.min': 'Infrastructure Cost has to be greater than 0',
        'any.required': 'Infrastructure Cost not allowed to be empty',
    }),
    tech_fee: Joi.when('type', {
        switch: [
            {
                is: 'media_buyer',
                then: Joi.number()
                    .min(0)
                    .max(100)
                    .allow(null),
            },
        ],
    }).messages({
        'number.base': 'Platform fee not allowed to be empty',
        'number.max': 'Platform fee cannot be greater than 100',
        'number.min': 'Platform fee has to be greater than 0',
        'any.required': 'Platform fee not allowed to be empty',
    }),
    ftaEnabled: Joi.bool(),
    fta_fee: Joi.when('ftaEnabled', {
        switch: [
            {
                is: true,
                then: Joi.number()
                    .min(0)
                    .required(),
            },
        ],
    }).messages({
        'number.base': 'FTA fee not allowed to be empty',
        'number.min': 'FTA fee has to be greater than 0',
        'any.required': 'FTA fee not allowed to be empty',
    }),
    fta_partner_id: Joi.when('ftaEnabled', {
        switch: [
            {
                is: true,
                then: Joi.string()
                    .trim()
                    .required(),
            },
        ],
    }).messages({
        'string.base': 'FTA Partner ID not allowed to be empty',
        'string.empty': 'FTA Partner ID not allowed to be empty',
        'any.required': 'FTA FTA Partner ID not allowed to be empty',
    }),
    isIasPostBidVerificationEnabled: Joi.bool(),
    privateInventorySpendTiers: Joi.array().items(billingTierSchema),
    publicInventorySpendTiers: Joi.array().items(billingTierSchema),
});

function selector(storeState, props) {
    const isLoading = _.get(storeState, 'audienceSegmentPicker.isLoading');
    const audienceSegments = _.get(storeState, 'audienceSegmentPicker.audienceSegments', []);
    const flexSegments = _.get(storeState, 'audienceSegmentPicker.flexSegments', []);

    const mapping = {};
    const flexMapping = {};
    _.each(audienceSegments, option => (mapping[option.value] = option));
    _.each(flexSegments, option => (flexMapping[option.value] = option));

    return {
        mapping,
        flexMapping,
        isLoading,
        options: audienceSegments.concat(flexSegments),
        shouldHideFlexSegments: props.shouldHideFlexSegments,
    };
}

export default selector;

import _ from 'lodash';

import { AUDIENCE_SOURCE_TYPES } from 'states/resources/audiences/business-logic';

export default function selector(storeState, props) {
    const audienceSegmentOptions = _.get(storeState, 'audienceSegmentPicker.audienceSegments');
    const flexSegmentsOptions = _.get(storeState, 'audienceSegmentPicker.flexSegments');
    const ownOrganizationId = _.get(storeState, 'profile.organizationId');
    const isLoading = _.get(storeState, 'audienceSegmentPicker.isLoading');
    const campaign = _.get(storeState, `resources.campaigns.${props.campaignId}.attr`);

    return {
        audienceSegmentOptions,
        ownOrganizationId,
        isLoading,
        flexSegmentsOptions,
        shouldHideFlexSegments: _.isNil(campaign)
            ? false
            : _.get(campaign, 'shouldHideFlexSegments'),
        isCrossPlatformCampaign: _.isNil(campaign)
            ? true
            : _.get(campaign, 'isCrossPlatformCampaign'),
    };
}

export function getAudienceSegmentOptions(storeState) {
    const audienceResources = _.get(storeState, `resources.audiences`, {});

    const options = _(audienceResources)
        .filter(a => a)
        .map(audience => {
            const sourceTypeConfig = AUDIENCE_SOURCE_TYPES[audience.attr.source_type];

            let category = 'Custom';
            if (sourceTypeConfig && sourceTypeConfig.type === 'prebuilt') {
                category = 'Pelmorex Audience Segments';
            }
            return {
                label: audience.attr.name,
                description: audience.attr.description,
                source_type: audience.attr.source_type,
                mode: audience.attr.mode,
                category,
                value: String(audience.id),
                supports: audience.attr.supports,
                tier: audience.attr.tier,
                deprecated: audience.attr.deprecated,
                uniqueDevices: audience.attr.uniques,
                third_party_fees: audeince.attr.third_party_fees,
            };
        })
        .value();

    return options;
}

import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import selector from './selector';

import FormField from 'widgets-v5/form-field';
import StandardInput from 'widgets-v5/standard-input';
import { PrimaryButton, NeutralButton } from 'widgets-v5/buttons';
import Organizations from 'states/resources/organizations/actions';
import Spacer from 'widgets-v5/spacer';
import { SimpleSelect } from 'widgets-v6/select';
import { MuiFormLabel } from 'widgets-v6/standard-input';
import PrefixSuffixInput from 'widgets-v6/prefix-suffix-input';

import { payload_clientData as orgDefaults } from 'forms/campaign-form/services/default-values';
import validate from 'utils/validate';

const REVENUE_MODEL_OPTIONS = [
    { label: 'CPM / CPC', value: 'cpmcpc' },
    { label: 'Agency margin', value: 'agencyMargin' },
    // { label: 'Total cost markup', value: 'totalSpendMarkup' },
    { label: 'Disabled', value: 'disabled' },
];

const validators = {
    name(value) {
        if (!value) {
            return 'required field';
        }
    },
};

const AdvertiserForm = createReactClass({
    displayName: 'AdvertiserForm',

    getInitialState() {
        return {
            draft: {
                ...orgDefaults,
                revenueModel: this.props.ownOrganization.revenueModel,
                agencyMarginRate: this.props.ownOrganization.agencyMarginRate,
                client_type: 'advertiser',
            },
            errors: {},
            isLoading: false,
            showErrors: false,
            isFormOpen: false,
        };
    },

    toggleForm() {
        if (this.state.isFormOpen) {
            this.cancel();
        } else {
            this.openForm();
        }
    },

    openForm() {
        this.setState({ isFormOpen: true });
    },

    updateField(field) {
        return value => {
            const draft = {
                ...this.state.draft,
                [field]: value,
            };
            const errors = mapErrors(validate(draft, validators));
            this.setState({ draft, errors });
        };
    },

    updateRevenueModel(field, value) {
        const draft = {
            ...this.state.draft,
            [field]: value,
        };
        const errors = mapErrors(validate(draft, validators));
        this.setState({ draft, errors });
    },

    cancel() {
        this.setState(this.getInitialState());
    },

    submit() {
        this.setState({ showErrors: true });

        if (_.keys(this.state.errors).length > 0) {
            return;
        }

        this.setState({ isLoading: true });

        this.props.dispatch(Organizations.create(this.state.draft)).then(
            () => {
                this.props.dispatch({
                    type: 'ADVERTISER_FORM__CREATE__SUCCESS',
                });
                if (this.props.onSave) {
                    this.props.onSave();
                }
                this.setState(this.getInitialState());
            },
            err => {
                this.setState({
                    isLoading: false,
                    errors: mapErrors(err.body),
                });
            }
        );
    },

    render() {
        const { isFormOpen, draft, errors, showErrors } = this.state;

        return (
            <div className="advertiser-form">
                <NeutralButton
                    className="advertiser-form__new-advertiser-button"
                    text="+ New Advertiser"
                    onClick={this.toggleForm}
                />
                {isFormOpen && (
                    <div className="advertiser-form__dropdown">
                        <i
                            onClick={this.toggleForm}
                            className="fa fa-times advertiser-form__dropdown-toggle-close-icon"
                        />
                        <FormField
                            label="New Name"
                            errors={errors.name}
                            showErrors={showErrors}
                            isRequired={true}
                        >
                            <StandardInput value={draft.name} onChange={this.updateField('name')} />
                        </FormField>
                        <Spacer />
                        <FormField
                            errors={errors.revenueModel}
                            showErrors={showErrors}
                            isRequired={true}
                        >
                            <SimpleSelect
                                required
                                label="Default Revenue Model"
                                options={REVENUE_MODEL_OPTIONS}
                                value={draft.revenueModel}
                                onChange={e =>
                                    this.updateRevenueModel('revenueModel', e.target.value)
                                }
                                margin="normal"
                                helperText={showErrors && errors.revenueModel}
                                error={showErrors && errors.revenueModel}
                            />
                        </FormField>
                        <Spacer />
                        {draft.revenueModel === 'agencyMargin' && (
                            <div>
                                <MuiFormLabel component="legend">
                                    Default Agency Margin
                                </MuiFormLabel>
                                <FormField
                                    errors={errors.revenueModel}
                                    showErrors={showErrors}
                                    isRequired={true}
                                >
                                    {draft.revenueModel === 'agencyMargin' && (
                                        <PrefixSuffixInput
                                            label="Field Name"
                                            disabled={false}
                                            placeholder=""
                                            suffix={'%'}
                                            formatIn={formatMarginValueToString}
                                            formatOut={formatMarginStringToValue}
                                            shouldAllowInput={shouldAllowInputForBillingRate}
                                            onChange={e =>
                                                this.updateRevenueModel('agencyMarginRate', e)
                                            }
                                            value={draft.agencyMarginRate}
                                        />
                                    )}
                                </FormField>
                            </div>
                        )}
                        <Spacer />
                        <PrimaryButton
                            className="advertiser-form__dropdown-save-button"
                            text="SAVE"
                            onClick={this.submit}
                        />
                    </div>
                )}
            </div>
        );
    },
});

function mapErrors(errorList) {
    const errors = {};

    _.each(errorList, err => {
        errors[err.field] = [err.message];
    });

    return errors;
}


function shouldAllowInputForBillingRate(input) {
    // Allow empty input
    if (!input) {
        return true;
    }

    let parsedInput = parseInt(input);

    // Check allowable characters
    if (Number.isNaN(parsedInput)) {
        return false;
    }

    // Check character length
    return parsedInput < 100;
}

function formatMarginValueToString(value) {
    return value && value >= 0 ? `${(value * 100).toFixed(2)}` : '';
}

function formatMarginStringToValue(valueAsString) {
    return valueAsString === '' ? 0 : parseFloat(valueAsString) / 100;
}

export default connect(selector)(AdvertiserForm);

import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import { hasChanged } from '../summary-conf';
import { highlight } from '../summary-conf';
/** import classes and others in the section above, keep the ascending order */

export default highlight(
    class extends React.Component {
        render() {
            const style = this.props.strikethroughText
                ? {
                      textDecoration: 'line-through',
                      color: 'gray',
                  }
                : this.props.highlight
                ? { background: '#ffaaaa' }
                : null;

            return (
                <dl className="ef3-adSummaryView_panelBlock">
                    <div className="ef3-adSummaryView__item-group">
                        <dt className="ef3-adSummaryView_term">{this.props.keyName}</dt>
                        <dd
                            className={`ef3-adSummaryView_description ${
                                this.props.uncapitalize ? 'ef3-adSummaryView_notCapitalized' : ''
                            }`}
                            style={style}
                        >
                            <div className="TODO widget--summary-type-text-collection">
                                {this.props.value}
                            </div>
                        </dd>
                        {this.props.tooltip}
                    </div>
                </dl>
            );
        }
    },
    (currentProps, nextProps) => hasChanged(currentProps.value, nextProps.value)
);

export const CREATIVE_VENDORS = [
    {
        label: 'EyeReturn JavaScript Tag',
        value: 'eyereturn__tag__js',
    },
    {
        label: 'DCM - INS Tag',
        value: 'dcm__tag__ins',
    },
    {
        label: 'Other Vendors',
        value: 'generic',
    },
];

export const TEMPLATE_FILENAME = 'generic-creative-template.csv';
export const TEMPLATE_CONTENTS =
    'Name,Size,HTML Markup,Landing Page,Third-Party Clicktracker (optional),"Third Party Pixels (optional, comma-separated)","Third Party Javascript (optional, comma-separated)",Requires MRAID (Yes or No)\n,,,,,,';

const insTagExample =
    "<ins class='dcmads' style='display:inline-block;width:300px;height:250px'" +
    "data-dcm-placement='N7480.1664088DOUBLECLICK.NETTEST/B8299600.114131924'" +
    "data-dcm-rendering-mode='iframe'" +
    'data-dcm-https-only' +
    "data-dcm-resettable-device-id=''" +
    "data-dcm-app-id=''" +
    "data-dcm-keywords='[url_encoded_publisher_data]'>" +
    "<script src='https://www.googletagservices.com/dcm/dcmads.js'></script>" +
    '</ins>';

export const EXAMPLE_FILENAME = 'generic-creative-example.csv';
export const EXAMPLE_CONTENTS = `Name,Size,HTML Markup,Landing Page,Third-Party Clicktracker (optional),"Third Party Pixels (optional, comma-separated)","Third Party Javascript (optional, comma-separated)",Requires MRAID (Yes or No)\nExample 1,300x250,${insTagExample},https://brand.com,ttps://adserver.com?redirect=http%3A%2F%2Fbrand.com,"https://adserver1.com/pixel.gif,https://adserver2.com/tracker.gif","https://ad.com/file.js,https://ad2.com/file2.js"No`;

import _ from 'lodash';
export default function selector(storeState) {
    const organizationId = _.get(storeState, 'profile.organizationId');
    const userOrganizationName = _.get(storeState, 'profile.authToken.organization.name');
    const restrictedSegments = _.get(storeState, 'profile.restrictedSegments');
    return {
        organizationId,
        userOrganizationName,
        restrictedSegments,
    };
}

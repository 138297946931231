import http from 'utils/http';

const API = {
    generatePrediction: query => {
        return http()
            .post(`inventory-predictions`, query)
            .then(results => {
                return results;
            });
    },
};

export default API;

import React from 'react';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
    formatDate_dateWithYear,
    formatNumber_currency,
    formatNumber_whole,
} from 'utils/formatting';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { DarkTooltip } from 'widgets-v6/tooltip';

import { isDownloadEnabled } from 'states/resources/audiences/business-logic';
import { isInternalUser } from 'states/profile/business-rules';
import { PlatformMapping } from 'states/resources/ads/business-logic';
import {
    STANDARD_RETARGETING_TYPES,
    RETARGETING_TYPES,
} from 'states/resources/audiences/business-logic';
import flags from 'containers/flags/service';
import { getEnvironmentSettings } from 'services/environment';
import { getAudienceStatus } from '../../../../states/resources/audiences/business-logic';

const PLATFORM_ICON_MAPPING = {
    inapp: {
        component: (
            <DarkTooltip title="In-app">
                <PhoneIphoneIcon fontSize="small" />
            </DarkTooltip>
        ),
    },
    mweb: {
        component: (
            <DarkTooltip title="Mobile Web / Desktop">
                <PhonelinkIcon fontSize="small" />
            </DarkTooltip>
        ),
    },
    ctv: {
        component: (
            <DarkTooltip title="Connected TV">
                <ConnectedTvIcon fontSize="small" />
            </DarkTooltip>
        ),
    },
    dooh: {
        component: (
            <DarkTooltip title="Digital Out-of-Home">
                <HomeMaxIcon fontSize="small" />
            </DarkTooltip>
        ),
    },
};

const createAudieneCardStyles = makeStyles(theme => ({
    footerColor: props => ({
        backgroundColor: props.categoryColor || 'white',
        color: 'white',
    }),
    root: props => ({
        height: '100%',
        '&:hover': {
            cursor: props.onClick ? 'pointer' : 'default',
            transform: props.onClick ? 'translateY(-4px)' : 'none',
            boxShadow: props.onClick
                ? theme.shadows[3]
                : '0px 2px 1px -1px rgb(0 0 0 / 6%), 0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 7%)',
        },
        border: props.active && `2px solid ${theme.palette.primary.main}`,
        position: 'relative',
        transition: 'all 0.2s ease',
    }),
    cardContent: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    name: {
        fontSize: 16,
        display: '-webkit-box',
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    description: {
        display: '-webkit-box',
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    downloadIcon: {
        fill: 'white',
    },
}));

export const AudienceCard = ({ segment, ...props }) => {
    const classes = createAudieneCardStyles(props);

    const segmentIdLabel = `#${segment.id}`;

    const handleDownload = e => {
        e.stopPropagation();
        props.onDownload(segment);
    };

    const isRetargetingAudience = segment.mode === 'retargeting';
    const isFromStandardTypes = STANDARD_RETARGETING_TYPES.includes(segment.retargeting_type);
    const isCTVImpressionsRetargetingType =
        segment?.supports?.includes(PlatformMapping.CTV) &&
        segment.retargeting_type === RETARGETING_TYPES.Impression;
    const isCTVAttributionRetargetingType =
        segment?.supports?.includes(PlatformMapping.CTV) &&
        segment.retargeting_type === RETARGETING_TYPES.Attribution;
    const isFromPixelRetargetingType = segment.retargeting_type === RETARGETING_TYPES.Pixel;

    let parsedSupportedPlatforms = segment.supports
        ? segment?.supports?.filter(platform => platform)
        : [];

    if (!flags.isEnabled('en_4657_dooh_campaigns')) {
        parsedSupportedPlatforms = parsedSupportedPlatforms.filter(
            platform => platform !== PlatformMapping.DOOH
        );
    }

    if (isRetargetingAudience && (isFromStandardTypes || isFromPixelRetargetingType)) {
        // Retargeting audiences should support CTV even if source isn't a CTV campaign/ad.
        parsedSupportedPlatforms = parsedSupportedPlatforms
            ? sortBy(
                  uniq(parsedSupportedPlatforms.concat([PlatformMapping.CTV])),
                  platform => platform
              )
            : [];
    }

    if (
        isRetargetingAudience &&
        (isCTVImpressionsRetargetingType ||
            isCTVAttributionRetargetingType ||
            isFromPixelRetargetingType)
    ) {
        // Retargeting audiences should support CTV, INAPP, DESKTOP if source is a CTV campaign/ad.
        parsedSupportedPlatforms = parsedSupportedPlatforms
            ? sortBy(
                  uniq(
                      parsedSupportedPlatforms.concat([PlatformMapping.INAPP, PlatformMapping.MWEB])
                  ),
                  platform => platform
              )
            : [];
    }

    return (
        <Card classes={{ root: classes.root }} onClick={props.onClick}>
            <CardContent className={classes.cardContent}>
                <Box padding={2}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={9}>
                            <DarkTooltip title={segment.name}>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    className={classes.name}
                                >
                                    {`${props.displayId ? segmentIdLabel : ''} ${segment.name}`}
                                </Typography>
                            </DarkTooltip>
                        </Grid>
                        <Grid item>
                            {map(parsedSupportedPlatforms, platform => {
                                if (
                                    ((platform === PlatformMapping.CTV ||
                                        platform === PlatformMapping.DOOH) &&
                                        !getEnvironmentSettings().canUseCTVOrDOOH()) ||
                                    platform === null
                                ) {
                                    return null;
                                }

                                return (
                                    <React.Fragment key={`${segment.id}_${platform}`}>
                                        {PLATFORM_ICON_MAPPING[platform].component}
                                    </React.Fragment>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Typography variant="h5" component="h2">
                        {!isNil(segment.uniques) && segment.uniques > 0 ? (
                            <span>{formatNumber_whole(segment.uniques)}</span>
                        ) : (
                            <span>N/A</span>
                        )}
                    </Typography>
                    <Typography color="textSecondary" variant="body2" gutterBottom>
                        Uniques
                    </Typography>
                    {segment.status && (
                        <Typography color="textSecondary" gutterBottom>
                            Status: {getAudienceStatus(segment)}
                        </Typography>
                    )}
                    {segment.points > 0 && (
                        <Typography color="textSecondary" gutterBottom>
                            Points: {segment.points}
                        </Typography>
                    )}
                    {segment._created && (
                        <Typography color="textSecondary" gutterBottom>
                            Created: {formatDate_dateWithYear(segment._created)}
                        </Typography>
                    )}
                    {flags.isEnabled('en_4634_audience_suspension') &&
                        segment.expire_at &&
                        segment?.source_type === 'pds_custom' && (
                            <Typography color="textSecondary" gutterBottom>
                                Expiration: {formatDate_dateWithYear(segment.expire_at)}
                                &nbsp;
                                <Tooltip
                                    title={
                                        <Typography variant="body2">
                                            This audience will expire 90 days after last usage.
                                            <br />
                                            A suspending status will appear 30 days before the
                                            audience is moved to suspended.
                                            <br />
                                            To reactivate an audience you can either click reactive
                                            button under actions in the audience tab or apply it to
                                            any upcoming or active campaigns.
                                            <br />
                                        </Typography>
                                    }
                                    placement="right"
                                >
                                    <i className="fa fa-question-circle" />
                                </Tooltip>
                            </Typography>
                        )}
                    {props.description && (
                        <DarkTooltip title={props.description}>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                className={classes.description}
                            >
                                {props.description}
                            </Typography>
                        </DarkTooltip>
                    )}
                    {props.metaData}
                </Box>
                <div>
                    <Box padding={1}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                <Typography color="textSecondary">
                                    {formatNumber_currency(segment.dataCost)} CPM
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {segment.lastRefreshed && (
                                    <Typography color="textSecondary" align="right">
                                        Last updated:{' '}
                                        {formatDate_dateWithYear(segment.lastRefreshed)}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                    {(props.categoryLabel || isDownloadEnabled(segment)) && (
                        <Box className={classes.footerColor}>
                            <Box padding={1}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    {props.categoryLabel && (
                                        <Grid item>
                                            <Typography>{props.categoryLabel}</Typography>
                                        </Grid>
                                    )}
                                    {isInternalUser() && isDownloadEnabled(segment) && (
                                        <Grid item>
                                            <DarkTooltip title="Download segment">
                                                <IconButton
                                                    size="small"
                                                    onClick={handleDownload}
                                                    disabled={props.isSegmentDownloading}
                                                >
                                                    <GetAppOutlinedIcon
                                                        fontSize="small"
                                                        classes={{ root: classes.downloadIcon }}
                                                    />
                                                </IconButton>
                                            </DarkTooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

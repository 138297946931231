import _ from 'lodash';
import PoisActions from 'states/resources/pois/actions';

import { draftToPayloadPOIv2 } from './services/draft-to-payload';
import { validatePois, constructPois } from './services/csv-processing';
import { interpolatePoints } from './services/async-csv-processing';

import GeoCategories from 'states/resources/geo-categories/actions';

const actions = {
    setPointsError(poiErrors) {
        return {
            type: 'POIS_EDITOR__SET_POINTS_ERROR',
            payload: {
                poiErrors,
            },
        };
    },
    setFileTypeError(files) {
        return {
            type: 'POIS_EDITOR__SET_FILE_TYPE_ERROR',
            payload: {
                files,
            },
        };
    },
    initialize(poiId, type) {
        return (dispatch, getState) => {
            dispatch({
                type: 'POIS_EDITOR__INIT_START',
                payload: {
                    poiId,
                },
            });

            dispatch(GeoCategories.getCategories({ allowCategoriesWithoutLayers: true })).then(
                geoCategories => {
                    if (!poiId) {
                        const organizationId =
                            type === 'custom' ? _.get(getState(), `profile.organizationId`) : null;

                        dispatch(actions.setPointsError(['A file must be uploaded']));

                        dispatch({
                            type: 'POIS_EDITOR__INIT_END',
                            payload: {
                                poi: null,
                                organizationId,
                                geoCategories,
                            },
                        });
                        return;
                    }

                    // fetch existing POI and load into draft
                    dispatch(PoisActions.getOne(poiId)).then(({ points, ...rest }) => {
                        const organizationId = _.get(getState(), `profile.organizationId`);

                        dispatch({
                            type: 'POIS_EDITOR__INIT_END',
                            payload: {
                                poiId,
                                poi: rest,
                                points,
                                organizationId,
                                geoCategories,
                            },
                        });
                    });
                }
            );
        };
    },
    updateLayer(field, value) {
        return {
            type: 'POIS_EDITOR__UPDATE_LAYER',
            payload: {
                [field]: value,
            },
        };
    },
    updateDraft(changes) {
        return {
            type: 'POIS_EDITOR__UPDATE_DRAFT',
            payload: changes,
        };
    },
    removePoint(pointToRemove) {
        return {
            type: 'POIS_EDITOR__REMOVE_POINT',
            payload: { pointToRemove },
        };
    },
    processUploadedPoints(formData, options = {}) {
        return dispatch => {
            const file = formData.get('asset');
            const reader = new FileReader();

            const onLoadEnd = async (file, reader) => {
                let errors = validatePois({ data: reader.result, parseCsv: true });
                if (errors) {
                    dispatch({
                        type: 'POIS_EDITOR__PROCESS_POINTS__FAILURE',
                        payload: { errors },
                    });
                }

                let pointsFromCsv = constructPois(reader.result);

                try {
                    dispatch({
                        type: 'POIS_EDITOR__INTERPOLATE_POINTS__START',
                        payload: {},
                    });

                    pointsFromCsv = await interpolatePoints(pointsFromCsv);

                    dispatch({
                        type: 'POIS_EDITOR__INTERPOLATE_POINTS__END',
                        payload: {},
                    });
                } catch (error) {
                    dispatch({
                        type: 'POIS_EDITOR__PROCESS_POINTS__FAILURE',
                        payload: { errors: [error] },
                    });
                }

                const uniquePoints = _.uniqWith(
                    pointsFromCsv,
                    (pointA, pointB) =>
                        `${pointA.latitude},${pointA.longitude}` ===
                        `${pointB.latitude},${pointB.longitude}`
                );

                const hasDuplicates = pointsFromCsv.length !== uniquePoints.length;

                dispatch({
                    type: 'POIS_EDITOR__PROCESS_POINTS__SUCCESS',
                    payload: {
                        points: uniquePoints,
                        options,
                        errors,
                        hasDuplicates,
                    },
                });
            };

            dispatch({
                type: 'POIS_EDITOR__PROCESS_POINTS',
                payload: { fileName: file.name },
            });

            reader.addEventListener('loadend', () => onLoadEnd(file, reader));

            reader.readAsText(file);
        };
    },
    save() {
        return (dispatch, getState) => {
            dispatch({
                type: 'POIS_EDITOR__SAVE_START',
                payload: {},
            });

            const {
                poiErrors,
                metadataErrors,
                isEditing,
                poiId,
                draft,
                cleanlistErrors,
                csvErrors,
            } = _.get(getState(), 'poisEditor');

            if (draft.source !== 'cleanlist' && poiErrors.length > 0) {
                return Promise.reject(
                    new Error('There are errors in the form under tab UPLOAD POI LIST')
                );
            }
            if (draft.source !== 'cleanlist' && csvErrors && Object.keys(csvErrors).length > 0) {
                return Promise.reject(new Error('There are errors in the uploaded csv file'));
            }
            if (metadataErrors.length > 0) {
                return Promise.reject(new Error('There are metadata errors in the form'));
            }
            if (draft.source === 'cleanlist' && cleanlistErrors.length > 0) {
                return Promise.reject(
                    new Error('There are errors in the form under tab SEARCH FOR POIS')
                );
            }

            let payload = draftToPayloadPOIv2(draft);

            /**
             * We don´t want to save points with latitude and longitude 0
             * The users receive an alert saying that the points are invalid
             * and they can´t be saved
             */
            if (payload.source === 'uploaded') {
                payload.points = payload.points.filter(
                    point => point.latitude !== 0 && point.longitude !== 0
                );
            }

            let operation;

            if (isEditing) {
                operation = dispatch(PoisActions.updatePoi(payload, poiId));
            } else {
                operation = dispatch(PoisActions.createPoi(payload));
            }

            return operation
                .then(() => {
                    dispatch({
                        type: 'POIS_EDITOR__SAVE_END',
                        payload: {},
                    });
                })
                .catch(err => {
                    dispatch({
                        type: 'POIS_EDITOR__SAVE_SERVER_ERROR',
                        payload: {
                            err: JSON.parse(err.bodyText),
                        },
                    });
                    return Promise.reject(new Error('Server save failed'));
                });
        };
    },
};

export default actions;

import _ from 'lodash';
import moment from 'moment';
import { getEnv } from 'services/environment';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';
import { getDimensions } from 'common/constants/dimensions';
import createPivotTableService from 'widgets/pivot-table/service';

import { APP_STORE_CAT_ENABLED_DATE } from 'common/constants/date-flags';

// index by client ot server
function getDimensionsByClientName() {
    const DIMENSIONS_BY_CLIENT_NAME = {};
    _.each(getDimensions(), dimension => {
        DIMENSIONS_BY_CLIENT_NAME[dimension.name] = dimension;
    });

    return DIMENSIONS_BY_CLIENT_NAME;
}

const pt = createPivotTableService();

const initialState = {
    cache: {},
    campaignId: null,
    isInitializing: false,
    isInitialized: false,
    isLoading: true,
    limit: 50,

    columns: [
        { label: 'Dimensions', name: 'dimension', defaultValue: '', formatType: '' },
        { label: 'Type', name: 'type', defaultValue: '', formatType: '' },
        { label: 'Impressions', name: 'impressions', defaultValue: 0, formatType: 'thousands' },
        { label: 'Clicks', name: 'clicks', defaultValue: 0, formatType: 'thousands' },
        { label: 'CTR', name: 'ctr', defaultValue: 0, formatType: 'percentage' },
        { label: 'Billable Cost', name: 'spend', defaultValue: 0, formatType: 'dollar' },
        { label: 'Billable Cost eCPM', name: 'ecpm', defaultValue: 0, formatType: 'dollar' },
        { label: 'Billable Cost eCPC', name: 'ecpc', defaultValue: 0, formatType: 'dollar' },
        { label: 'Billing Rate', name: 'billing_rate', defaultValue: '', formatType: '' },
    ],
    pivotTable: {
        splits: [],
        dimensions: [
            { label: 'Date', name: 'date' },
            { label: 'Category', name: 'app_store_cat' },
            { label: 'Ad', name: 'ad_id' },
            { label: 'Platform', name: 'platform' },
        ],
        sort: {
            column: 'impressions',
            order: 'desc',
        },
        tree: {},
    },
};

const initialDimensions = [...initialState.pivotTable.dimensions];

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            let _initialState = {
                ...initialState,
                pivotTable: {
                    ...initialState.pivotTable,
                    splits: [{ label: 'App & Site', name: 'channel_id'}],
                    dimensions: initialDimensions.concat([{ label: 'App', name: 'channel_id' }]),
                }
            };
            if (getEnv() === 'production') {
                _initialState = {
                    ..._initialState,
                    columns: _.filter(initialState.columns, c => c.name !== 'billing_rate'),
                };
            }

            return findCacheState(campaignId, state, _initialState);
        }

        case c.REPORT_CHANNEL__PIVOT_TABLE__INITIALIZE_START: {
            const { campaign, campaignId, isClient } = action.payload;

            let pivotTableState = {
                ...initialState.pivotTable,
                splits: [{ label: 'App & Site', name: 'channel_id'}],
                dimensions: initialDimensions.concat([{ label: 'App', name: 'channel_id' }]),
            };
            pivotTableState = removeAppCatForLegacyCampaigns(pivotTableState, campaign);
            pivotTableState = removeHiddenDimensionsForAll(pivotTableState, campaign);
            if (isClient) {
                pivotTableState = removeHiddenDimensionsForClient(pivotTableState, campaign);
            }

            const columns = _(state.columns)
                .filter(column => {
                    // only apply sharing to clients
                    if (!isClient) {
                        return true;
                    }

                    const metricConfig = _.find(campaign.sharing_settings.metrics, metric => {
                        if (metric.name === column.name) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (metricConfig) {
                        return metricConfig.shared;
                    } else {
                        return true;
                    }
                })
                .map(column => {
                    switch (column.name) {
                        case 'owner_total_media_cost_local': {
                            const isDisabled =
                                !campaign.data_fees_enabled ||
                                campaign.third_party_fees.length === 0;
                            return {
                                ...column,
                                disabled: isDisabled,
                            };
                        }
                        default: {
                            return column;
                        }
                    }
                })
                .value();

            return {
                ...state,
                columns,
                campaignId,
                isLoading: true,
                isInitializing: true,
                pivotTable: pivotTableState,
            };
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__INITIALIZE: {
            const { stats } = action.payload;

            return {
                ...state,
                pivotTable: pt.generatePivotTableState(state.pivotTable, stats),
                isLoading: false,
                isInitializing: false,
                isInitialized: true,
            };
        }
        case 'REPORT_CHANNEL__PIVOT_TABLE__DATA_REFRESH_START': {
            return {
                ...state,
                isLoading: true,
            };
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__DATA_REFRESH: {
            return {
                ...state,
                isLoading: false,
                pivotTable: pt.generatePivotTableState(state.pivotTable, action.payload.stats),
            };
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__EXPAND_ROW: {
            return {
                ...state,
                pivotTable: pt.expandRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__COLLAPSE_ROW: {
            return {
                ...state,
                pivotTable: pt.collapseRow(state.pivotTable, action.payload.rowId),
            };
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__UPDATE_SPLITS: {
            return {
                ...state,
                pivotTable: pt.updateSplits(state.pivotTable, action.payload.splits),
            };
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__ADD_SPLIT: {
            let nextState = {
                ...state,
                pivotTable: pt.addSplit(state.pivotTable, action.payload.dimension),
            };

            // if split does not have channel, hide type column
            const hasChannel = _.find(nextState.pivotTable.splits, { name: 'channel_id' });
            const typeIndex = _.findIndex(nextState.columns, { name: 'type' });

            const columns = nextState.columns.slice();

            columns[typeIndex] = {
                ...columns[typeIndex],
                status: hasChannel ? 'visible' : 'hidden',
            };

            nextState = {
                ...nextState,
                columns,
            };

            return nextState;
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__REMOVE_SPLIT: {
            let nextState = {
                ...state,
                pivotTable: pt.removeSplit(state.pivotTable, action.payload.dimension),
            };

            // if split does not have channel, hide type column
            const hasChannel = _.find(nextState.pivotTable.splits, { name: 'channel_id' });
            const typeIndex = _.findIndex(nextState.columns, { name: 'type' });

            const columns = nextState.columns.slice();

            columns[typeIndex] = {
                ...columns[typeIndex],
                status: hasChannel ? 'visible' : 'hidden',
            };

            nextState = {
                ...nextState,
                columns,
            };

            return nextState;
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__SORT: {
            return {
                ...state,
                pivotTable: pt.sortColumn(state.pivotTable, action.payload.column),
            };
        }
        case c.REPORT_CHANNEL__PIVOT_TABLE__LIMIT_CHANNELS: {
            return {
                ...state,
                limit: action.payload.limit,
            };
        }
        default:
            return state;
    }
}

function removeAppCatForLegacyCampaigns(pivotTableState, campaign) {
    const dimensions = _(pivotTableState.dimensions)
        .filter(dimension => {
            if (dimension.name === 'app_store_cat' && campaign.isAppStoreCatEnabled === false) {
                return false;
            }

            const campaignCreated = moment.utc(campaign._created);
            if (
                dimension.name === 'app_store_cat' &&
                campaignCreated.isBefore(APP_STORE_CAT_ENABLED_DATE)
            ) {
                return false;
            }

            return true;
        })
        .value();

    return {
        ...pivotTableState,
        dimensions,
    };
}

function removeHiddenDimensionsForAll(pivotTableState) {
    const dimensions = _(pivotTableState.dimensions)
        .filter(dimension => {
            if (dimension.name === 'app_category') {
                return false;
            }

            return true;
        })
        .value();

    return {
        ...pivotTableState,
        dimensions,
    };
}
function removeHiddenDimensionsForClient(pivotTableState, campaign) {
    const dimensions = _(pivotTableState.dimensions)
        .filter(dimension => {
            return _.find(campaign.sharing_settings.report_tabs.channel.dimensions, {
                name: getDimensionsByClientName()[dimension.name].serverName,
                shared: true,
            });
        })
        .value();

    return {
        ...pivotTableState,
        dimensions,
    };
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import { formatNumber_percentage } from 'utils/formatting';

class CircularProgressBar extends React.Component {
    static propTypes = {
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        percentage: PropTypes.number,
        header: PropTypes.string,
    };

    static defaultProps = {
        size: 'medium',
        percentage: 0.25,
    };

    state = {};

    render() {
        const sqSizeArray = this.props.size;

        // Size, stroke width of the enclosing square
        let sqSize;
        let strokeWidth;
        let circleHeaderClassName;
        let circlePercentageClassName;
        switch (sqSizeArray) {
            case 'small':
                sqSize = 100;
                strokeWidth = 7;
                circleHeaderClassName = 'ef6-circular-progress-bar__circle-header-small';
                circlePercentageClassName = 'ef6-circular-progress-bar__circle-percentage-small';
                break;
            case 'medium':
                sqSize = 150;
                strokeWidth = 9;
                circleHeaderClassName = 'ef6-circular-progress-bar__circle-header-medium';
                circlePercentageClassName = 'ef6-circular-progress-bar__circle-percentage-medium';
                break;
            case 'large':
                sqSize = 180;
                strokeWidth = 13;
                circleHeaderClassName = 'ef6-circular-progress-bar__circle-header-large';
                circlePercentageClassName = 'ef6-circular-progress-bar__circle-percentage-large';
        }

        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (sqSize - strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - dashArray * this.props.percentage;

        return (
            <div className="ef6-circular-progress-bar">
                <svg width={sqSize} height={sqSize} viewBox={viewBox}>
                    <circle
                        className="ef6-circular-progress-bar__circle-background"
                        cx={sqSize / 2}
                        cy={sqSize / 2}
                        r={radius}
                        strokeWidth={`${strokeWidth}px`}
                    />
                    <circle
                        className="ef6-circular-progress-bar__circle-progress"
                        cx={sqSize / 2}
                        cy={sqSize / 2}
                        r={radius}
                        strokeWidth={`${strokeWidth}px`}
                        // Start progress marker at 12 O'Clock
                        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                        style={{
                            strokeDasharray: dashArray,
                            strokeDashoffset: dashOffset,
                        }}
                    />
                    <text
                        className={classnames(
                            'ef6-circular-progress-bar__circle-header',
                            circleHeaderClassName
                        )}
                        x="50%"
                        y="40%"
                        dy=".3em"
                        textAnchor="middle"
                    >
                        {this.props.header}
                    </text>
                    <text
                        className={classnames(
                            'ef6-circular-progress-bar__circle-percentage',
                            circlePercentageClassName
                        )}
                        x="50%"
                        y={this.props.header ? '55%' : '50%'}
                        dy=".3em"
                        textAnchor="middle"
                    >
                        {formatNumber_percentage(this.props.percentage)}
                    </text>
                </svg>
            </div>
        );
    }
}

export default CircularProgressBar;

import omit from 'lodash/omit';
import { makeActions } from 'utils/redux-tools';
import uuid from 'uuid';
import {
    RevenueModelMapping,
    CampaignTypeMapping,
    getFtaDisabledDefaults,
    getRevenueModelDefaults,
    CampaignAutoBudgetAllocationOptions,
} from '../../../states/resources/campaigns/business-logic';
import { validateRestrictedCategory } from './schema';

export const REDUCER_KEY = 'campaignForm';
export const initialState = {
    draft: null,
    showErrors: false,
    isLoading: true,
    isSubmitting: false,
    isDirty: false,
    isModalOpen: false,
    errors: null,
};

const updateDraft = (state, changes) => {
    return {
        ...state,
        draft: {
            ...state.draft,
            ...changes,
        },
    };
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        open: state => ({
            ...state,
            isModalOpen: true,
            isLoading: true,
            showErrors: false,
            isSubmitting: false,
        }),
        close: () => initialState,
        loadOrganizations: (state, { ownOrganization, organizations }) => ({
            ...state,
            ownOrganization,
            organizations,
        }),
        init: (state, payload) => {
            return {
                ...initialState,
                isModalOpen: true,
                isLoading: true,
                showErrors: false,
                isSubmitting: false,
                fields: payload.fields,
            };
        },
        initSuccess: (state, payload) => ({
            ...state,
            draft: payload.campaign,
            isLoading: false,
            originalCampaign: payload.campaign ? payload.campaign.id : payload.draft,
            ftaLocationLists: payload.ftaLocationLists,
            flightMetadata: payload.flights,
        }),
        submit: state => ({
            ...state,
            isSubmitting: true,
            showErrors: true,
        }),
        submitSuccess: state => ({
            ...state,
            isSubmitting: false,
            isModalOpen: false,
            showErrors: false,
        }),
        submitClientErrorsUnresolved: (state, clientErrors) => ({
            ...state,
            clientErrors,
            isLoading: false,
            isSubmitting: false,
            showErrors: true,
        }),
        submitServerErrors: (state, serverErrors) => ({
            ...state,
            serverErrors,
            isLoading: false,
            isSubmitting: false,
        }),
        campaignFormUpdate: (state, payload) => {
            const nextDraft = {
                ...state.draft,
                ...payload,
            };

            const useDisabledRevenueModelAndAutoBudgetAllocation =
                state.draft.revenueModel === 'disabled' &&
                state.draft.budget_allocation_method === 'auto';

            if (
                useDisabledRevenueModelAndAutoBudgetAllocation &&
                payload.campaign_max_total_spend_local
            ) {
                nextDraft.total_billings_local = nextDraft.campaign_max_total_spend_local;
            }

            return {
                ...state,
                draft: nextDraft,
                isDirty: true,
            };
        },
        changeName: (state, name) => updateDraft(state, { name }),
        changeCustomField: (state, { key, value }) => {
            const custom_fields = _.map(state.draft.custom_fields, cf => {
                if (cf.key !== key) {
                    return cf;
                }

                return { ...cf, value: _.trim(value) };
            });

            return updateDraft(state, { custom_fields });
        },
        changeDefaultTimezone: (state, default_timezone) =>
            updateDraft(state, { default_timezone }),
        changeType: (state, type) => {
            let defaults = { type, ...getFtaDisabledDefaults() };

            if (
                [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                    state.draft.revenueModel
                )
            ) {
                const revenueModelDefaults = getRevenueModelDefaults(null);
                defaults = { ...defaults, ...revenueModelDefaults };
            }

            // We should also update the automaticBudgetAllocationOptimizationStrategy field.
            // This is because the values for that field are dependant on the campaign type.
            if (
                state.draft.budget_allocation_method === 'auto' &&
                type !== CampaignTypeMapping.DOOH
            ) {
                let automaticBudgetAllocationOptimizationStrategy =
                    type === CampaignTypeMapping.CTV
                        ? CampaignAutoBudgetAllocationOptions.CPCV
                        : CampaignAutoBudgetAllocationOptions.CPC;

                return updateDraft(state, {
                    ...defaults,
                    automaticBudgetAllocationOptimizationStrategy,
                });
            }

            // DOOH campaign can't have auto budget allocation so we need to make sure that
            // the correct value is set for that field.
            if (type === CampaignTypeMapping.DOOH) {
                defaults.budget_allocation_method = 'manual';
            }

            return updateDraft(state, { ...defaults });
        },
        changeCreator: (state, creatorId) => updateDraft(state, { creatorId }),
        changeNotes: (state, notes) => updateDraft(state, { notes }),
        changeRestrictedCategory: (state, value) => {
            const newState = updateDraft(state, { restricted_category: value });
            const clearedErrors = { ...omit(state.clientErrors, ['restricted_category']) };
            const errors = validateRestrictedCategory({
                errors: clearedErrors,
                draft: newState.draft,
            });

            return {
                ...newState,
                showErrors: !!errors.restricted_category,
                clientErrors: {
                    ...state.clientErrors,
                    ...errors,
                },
            };
        },
        changeFlightsSelection: (state, flightPacingStrategy) => {
            if (flightPacingStrategy === 'campaign') {
                // Campaign level flights means the user can set flights here. We set a default
                // flight first to help the user.
                return updateDraft(state, {
                    flightPacingStrategy,
                    flights: [
                        {
                            _id: uuid.v4(),
                            name: 'Flight 1',
                            start: null,
                            end: null,
                            notes: '',
                            total_cost_budget: 0,
                            total_impressions: 0,
                            billings_local: 0,
                        },
                    ],
                });
            } else {
                // Ad-level flights, just remove the flights.
                return updateDraft(state, { flightPacingStrategy, flights: [] });
            }
        },
        updateFlight: (state, { id, value }) => {
            const splitID = id.split('.');
            const rowIndex = splitID[0];
            const columnName = splitID[1];

            const flight = {
                ...state.draft.flights[rowIndex],
                [columnName]: value,
            };
            const newFlights = [...state.draft.flights];
            newFlights[rowIndex] = flight;

            if (state.draft.revenueModel === RevenueModelMapping.AgencyMargin) {
                const revenueBudget = newFlights.reduce(
                    (acc, currentFlight) => acc + (currentFlight.billings_local || 0),
                    0
                );
                const campaignTotalBudget = newFlights.reduce(
                    (acc, flight) => acc + flight.total_cost_budget,
                    0
                );
                return updateDraft(state, {
                    flights: newFlights,
                    total_billings_local: revenueBudget,
                    campaign_max_total_spend_local: campaignTotalBudget,
                });
            }

            if (
                [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                    state.draft.revenueModel
                )
            ) {
                return updateDraft(state, {
                    flights: newFlights,
                    campaign_max_total_impressions: newFlights.reduce(
                        (acc, currentFlight) => acc + (currentFlight.total_impressions || 0),
                        0
                    ),
                });
            }

            return updateDraft(state, {
                flights: newFlights,
                campaign_max_total_spend_local: newFlights.reduce(
                    (acc, currentFlight) => acc + (currentFlight.total_cost_budget || 0),
                    0
                ),
            });
        },
        addNewFlight: state => {
            return updateDraft(state, {
                flights: [
                    ...state.draft.flights,
                    {
                        _id: uuid.v4(),
                        name: `Flight ${state.draft.flights.length + 1}`,
                        start: null,
                        end: null,
                        notes: '',
                        total_cost_budget: 0,
                        total_impressions: 0,
                        billings_local: 0,
                    },
                ],
            });
        },
        removeFlight: (state, index) => {
            const newFlights = [...state.draft.flights];
            newFlights.splice(index, 1);

            if (state.draft.revenueModel === RevenueModelMapping.AgencyMargin) {
                const revenueBudget = newFlights.reduce(
                    (acc, currentFlight) => acc + (currentFlight.billings_local || 0),
                    0
                );

                return updateDraft(state, {
                    flights: newFlights,
                    total_billings_local: revenueBudget,
                    campaign_max_total_spend_local:
                        revenueBudget - revenueBudget * state.draft.billing_rate,
                });
            }

            if (
                [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                    state.draft.revenueModel
                )
            ) {
                return updateDraft(state, {
                    flights: newFlights,
                    campaign_max_total_impressions: newFlights.reduce(
                        (acc, currentFlight) => acc + (currentFlight.total_impressions || 0),
                        0
                    ),
                });
            }

            return updateDraft(state, {
                flights: newFlights,
                campaign_max_total_spend_local: newFlights.reduce(
                    (acc, currentFlight) => acc + (currentFlight.total_cost_budget || 0),
                    0
                ),
            });
        },
        changeRevenueModel: (state, selection) => {
            const defaults = getRevenueModelDefaults(selection);

            const nextDraft = {
                ...state.draft,
                ...defaults,
            };

            return {
                ...state,
                draft: nextDraft,
                isDirty: true,
            };
        },
    },
});

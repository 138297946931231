import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import FileSaver from 'utils/file-saver';
import toastr from 'toastr';

import config from '../../../../config';
import logger from 'services/logger';
import { getNextBase } from 'utils/http';
import { NeutralButton } from 'widgets-v5/buttons';

import selector from './selector';

import { translateClientToServerMetrics } from 'services/resources/stats';
import flags from 'containers/flags/service';

const { DEV_SETTINGS_ENABLED } = config;
const { string, arrayOf, shape, object } = PropTypes;

class ExportReportButton extends React.Component {
    static propTypes = {
        fileName: string,
        request: shape({
            split: arrayOf(string),
            metrics: arrayOf(string),
            attributes: arrayOf(string),
            match: object,
            start: string,
            end: string,
            timezone: string,
        }),
    };
    state = {
        isLoading: false,
    };

    download = () => {
        if (this.props.onClick) {
            this.props.onClick(() => this._download());
        } else {
            this._download();
        }
    };

    _download = () => {
        if (this.state.isLoading) {
            return;
        }

        this.setState(
            {
                isLoading: true,
            },
            () => {
                this.props.dispatch((dispatch, getState) => {
                    const apiBranch = _.get(getState(), 'devSettings.currentBranch', 'develop');
                    const apiMode = _.get(getState(), 'devSettings.apiMode', 'remote');

                    var req = new XMLHttpRequest();
                    req.open('POST', `${getNextBase()}report/excel-download`, true);
                    req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                    req.setRequestHeader('Authorization', getState().profile.authToken.auth_token);

                    if (DEV_SETTINGS_ENABLED && apiMode === 'remote') {
                        req.setRequestHeader('X-Hipster-Branch', apiBranch);
                    }

                    req.setRequestHeader('X-Flags', flags.getEnabledFlagKeys().join(','));

                    req.responseType = 'blob';

                    req.onload = oEvent => {
                        this.setState({
                            isLoading: false,
                        });

                        if (req.status === 200) {
                            const blob = req.response;
                            FileSaver.saveAs(blob, this.props.fileName);
                        } else {
                            console.error(req.statusText);
                            toastr.error('Please try again later', 'Export Failed');

                            logger.notify(
                                new Error('Export to CSV Failed', {
                                    request: this.props.request,
                                    fileName: this.props.fileName,
                                })
                            );
                        }
                    };

                    // Remove properties that have value null or undefined
                    let request = _.omitBy(
                        this.props.request,
                        value => value === null || value === undefined
                    );

                    request = {
                        ...request,
                        metrics: translateClientToServerMetrics(request.metrics),
                    };

                    req.send(JSON.stringify(request));
                });
            }
        );
    };

    render() {
        return (
            <NeutralButton
                disabled={this.state.isLoading}
                icon={
                    this.state.isLoading ? (
                        <i className="fa fa-circle-o-notch ef4-load-group__spin" />
                    ) : (
                        <i className="fa fa-download" />
                    )
                }
                text="Export"
                onClick={this.download}
            />
        );
    }
}

export default connect(selector)(ExportReportButton);

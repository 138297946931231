import _ from 'lodash';
import http from 'utils/http';
import c from 'common/constants/flux-events';

import { can } from 'services/can';
import { getEnvironmentSettings } from 'services/environment';
import acceptedMIMETypes from 'common/constants/acceptedMIMETypes';
import Files from 'states/resources/files/actions';
import { PermissionsMapping } from 'common/constants/role-intents';

const Campaigns = {
    getAll(params) {
        return dispatch => {
            return http()
                .get(`campaigns`, params)
                .then(
                    response => {
                        const campaigns = _.get(response, '1', []);

                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaigns,
                            },
                        });

                        return response;
                    },
                    err => {
                        console.error(`GET ALL campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    getByIds(campaignIds) {
        return dispatch => {
            const campaignIdsCSV = [].concat(campaignIds).join(',');

            return http()
                .get(`campaigns?ids=${campaignIdsCSV}`)
                .then(
                    response => {
                        const campaigns = _.get(response, '1', []);

                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaigns,
                            },
                        });

                        return response;
                    },
                    err => {
                        console.error(`GET BY IDS campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    getAllSummary(params) {
        return dispatch => {
            return http()
                .get(`campaigns/summary`, params)
                .then(
                    response => {
                        const campaigns = _.get(response, '1', []);

                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaigns,
                            },
                        });

                        return response;
                    },
                    err => {
                        console.error(`GET ALL SUMMARY campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    get(campaignId) {
        return dispatch => {
            return http()
                .get(`campaigns/${campaignId}`)
                .then(
                    campaign => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaign,
                            },
                        });

                        return campaign;
                    },
                    err => {
                        console.error(`GET ONE campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    getOneSummary(campaignId) {
        return dispatch => {
            return http()
                .get(`campaigns/${campaignId}/summary`)
                .then(
                    campaign => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaign,
                            },
                        });

                        return campaign;
                    },
                    err => {
                        console.error(`GET ONE SUMMARY campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    create(campaign) {
        return dispatch => {
            return http()
                .post(`campaigns`, campaign)
                .then(
                    campaign => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaign,
                            },
                        });

                        return campaign;
                    },
                    err => {
                        console.error(`CREATE campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    update(campaignId, campaign) {
        return dispatch => {
            return http()
                .patch(`campaigns/${campaignId}`, campaign)
                .then(
                    campaign => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaign,
                            },
                        });

                        return campaign;
                    },
                    err => {
                        console.error(`UPDATE campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    delete(campaignId) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.campaigns.${campaignId}.attr._etag`);

            return http()
                .setIfMatch(etag)
                .del(`campaigns/${campaignId}`)
                .then(
                    campaign => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                            payload: {
                                campaign,
                            },
                        });

                        return campaign;
                    },
                    err => {
                        console.error(`DELETE campaigns error`, err);

                        throw err;
                    }
                );
        };
    },

    getHistory(campaignId) {
        return dispatch => {
            return http()
                .get(`campaigns/${campaignId}/history`)
                .then(
                    history => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_HISTORY__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                history,
                            },
                        });
                        return history;
                    },
                    err => {
                        console.error(`GET HISTORY campaigns error`, err);

                        throw err;
                    }
                );
        };
    },
    shareNotification(campaignId, userIds) {
        return () => {
            return http()
                .post(`campaigns/${campaignId}/notify-share`, {
                    users: userIds,
                })
                .then(
                    campaign => {
                        return campaign;
                    },
                    err => {
                        console.error(`SHARE NOTIFICATION campaigns error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default Campaigns;

export function fetchCampaign(campaignId) {
    if (can(PermissionsMapping.CAMPAIGN__VIEW)) {
        return dispatch => {
            return dispatch(Campaigns.get(campaignId));
        };
    } else if (can(PermissionsMapping.DASHBOARD__VIEW)) {
        return dispatch => {
            return dispatch(Campaigns.getOneSummary(campaignId));
        };
    }

    return dispatch => {
        return dispatch(Campaigns.get(campaignId));
    };
}

export function fileUploadSubmit(fileData, campaignId, file) {
    return (dispatch, getState) => {
        const environmentSettings = getEnvironmentSettings();
        const maxFileUploadSizeMB = environmentSettings.getMaxFileUploadSizeMB();
        const BITS_ONE_MEGABIT = 1048576; // 2^20
        /*
            File Upload button does not send @file because it does not need to track
            progress. In that case this conditional is fulfilled and simply
            calls the basic file create.
        */
        if (!file) {
            dispatch({
                type: c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT,
                payload: {
                    campaignId,
                },
            });

            return dispatch(Files.create(campaignId, fileData, onProgress, onComplete)).then(
                response => {
                    const file = response;
                    dispatch({
                        type: c.SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS,
                        payload: {
                            file,
                            campaignId,
                        },
                    });

                    fetchCampaign(campaignId)(dispatch, getState);
                    dispatch(Files.getAll(campaignId));
                },

                error => {
                    let reason = error?.message;
                    if (_.isArray(error?.body) && !_.isEmpty(error?.body[0])) {
                        reason = error.body[0].message || error?.body[0];
                    }

                    dispatch({
                        type: 'SYSTEM__CAMPAIGN_FILES__UPLOAD__ERROR',
                        payload: {
                            uploadAssetsErrorMessage: reason,
                        },
                    });
                }
            );
        }

        const { name, size, type } = file;

        if (size / BITS_ONE_MEGABIT > maxFileUploadSizeMB) {
            cancelFileUpload(dispatch, campaignId, name, `over ${maxFileUploadSizeMB} MB`);
            return false;
        }

        if (!_.includes(acceptedMIMETypes, type)) {
            cancelFileUpload(dispatch, campaignId, name, 'incorrect type');
            return false;
        }

        dispatch({
            type: c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT,
            payload: {
                campaignId,
            },
        });

        function onProgress(percent) {
            if (percent >= 0) {
                const percentAsMultipleOfTen = Math.round(percent / 10) * 10;

                dispatch({
                    type: c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT__PROGRESS,
                    payload: {
                        campaignId,
                        assets: {
                            [name]: {
                                progress: percentAsMultipleOfTen,
                            },
                        },
                    },
                });
            }
        }

        function onComplete() {
            dispatch({
                type: c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT__PROGRESS_COMPLETE,
                payload: {
                    campaignId,
                    assets: {
                        [name]: {
                            isComplete: true,
                            progress: 100,
                        },
                    },
                },
            });
        }

        return dispatch(Files.create(campaignId, fileData, onProgress, onComplete)).then(
            response => {
                const file = response;

                dispatch({
                    type: c.SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS,
                    payload: {
                        file,
                        campaignId,
                    },
                });

                setTimeout(() => {
                    dispatch({
                        type: c.OVERVIEW_FILES__FILE_UPLOAD__CLEAR_TEMP,
                        payload: {
                            campaignId,
                            asset: name,
                        },
                    });
                }, 5000);

                fetchCampaign(campaignId)(dispatch, getState);
                dispatch(Files.getAll(campaignId));
            },

            error => {
                let reason = error?.message;
                if (_.isArray(error?.body) && !_.isEmpty(error?.body[0])) {
                    reason = error.body[0].message || error?.body[0];
                }

                cancelFileUpload(dispatch, campaignId, name, reason);
            }
        );
    };
}

export function fileDeleteSubmit(fileId, campaignId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_FILES__FILE_DELETE,
            payload: {},
        });

        const file = _.get(getState(), `resources.files.${fileId}`, undefined);
        const confirmDelete = confirm(`Are you sure?\nYou are going to delete:\n${file.attr.name}`);

        if (!confirmDelete) {
            return dispatch({
                type: c.OVERVIEW_FILES__FILE_DELETE__FAIL,
                payload: {},
            });
        }

        return dispatch(Files.delete(campaignId, fileId)).then(
            () => {
                dispatch({
                    type: c.OVERVIEW_FILES__FILE_DELETE__SUCCESS,
                    payload: {
                        fileId,
                        campaignId,
                    },
                });

                fetchCampaign(campaignId)(dispatch, getState);
                dispatch(Files.getAll(campaignId));
            },

            () => {}
        );
    };
}

export function fileRenameSubmit(fileId, updatedFileName, campaignId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_FILES__FILE_EDIT,
            payload: {},
        });

        const payload = {
            name: updatedFileName,
        };

        return dispatch(Files.update(campaignId, fileId, payload)).then(
            response => {
                const file = response;

                dispatch({
                    type: c.OVERVIEW_FILES__FILE_EDIT__SUCCESS,
                    payload: {
                        file,
                        campaignId,
                        id: fileId,
                    },
                });

                fetchCampaign(campaignId)(dispatch, getState);
            },

            () => {}
        );
    };
}

function cancelFileUpload(dispatch, campaignId, name, reason) {
    setTimeout(() => {
        dispatch({
            type: c.OVERVIEW_FILES__FILE_UPLOAD__CLEAR_TEMP,
            payload: {
                campaignId,
                asset: name,
            },
        });
    }, 5000);

    return dispatch({
        type: c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT_FAIL,
        payload: {
            campaignId,
            failedAsset: {
                name,
                reason,
            },
        },
    });
}

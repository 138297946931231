import React from 'react';

import BaseColumnForm from '../base-column-form';
import { formatNumber_wholeFixed } from 'utils/formatting';
import ThirdPartyFees from 'forms/campaign-form/modules/third-party-fees';
import { getEnvironmentSettings } from 'services/environment';
import { calculateThirdPartyFees as calculateAdThirdPartyFees } from 'states/resources/ads/business-logic';
import { InfoTooltip } from 'widgets-v6/tooltip';
import Box from '@mui/material/Box';

export class ThirdPartyFeesForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Third Party Fees"
                fields={['thirdPartyFees']}
                shouldRefreshPredictionsOnSave={false}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => {
                    return (
                        <React.Fragment>
                            <Box display="flex">
                                <div>Third Party Fees</div>
                                <InfoTooltip
                                    placement="right"
                                    title="Plan your campaign budget holistically by entering any non-media CPM costs, such as third-party data fees or vendor fees. Third-party fees are automatically deducted from your total cost budget to make sure you don't go over-budget. Changes to this field are not applied retroactively and only take effect for new impressions moving forward."
                                />
                            </Box>
                            <ThirdPartyFees
                                errors={errors}
                                showErrors={shouldShowErrors}
                                currency={campaign.currency}
                                value={draft.thirdPartyFees}
                                onChange={value => updateDraft({ thirdPartyFees: value })}
                            />
                        </React.Fragment>
                    );
                }}
            </BaseColumnForm>
        );
    }
}

export const ThirdPartyFeesSummary = React.memo(props => {
    const { ad, campaign, error } = props;
    const environmentSettings = getEnvironmentSettings();
    const thirdPartyFees = calculateAdThirdPartyFees(ad);
    let currencyLabel = '';
    if (environmentSettings.showCurrencyLabel()) {
        currencyLabel = campaign.currency || 'USD';
    }
    if (error) {
        return <div>{error}</div>;
    }
    return (
        <div
            placeholder={thirdPartyFees <= 0}
        >{`${currencyLabel} ${environmentSettings.getCurrencySign()} ${formatNumber_wholeFixed(
            thirdPartyFees
        )} CPM`}</div>
    );
});

import _ from 'lodash';
import React, { useState } from 'react';
import createReactClass from 'create-react-class';
import { Link, browserHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import FilterListIcon from '@mui/icons-material/FilterList';

import DashboardCampaignList from './modules/dashboard-campaign-list';
import Pagination from 'widgets-v5/pagination';
import { DialogTitle } from 'widgets-v6/modal';
import { MultiSelect } from 'widgets-v6/select';
import DashboardLayout from './layout';

import selector, { iabCategoryOptions } from './selector';

import { canViewCampaignCreator } from '../../states/resources/campaigns/business-logic';

import {
    fetchDashboardCampaigns,
    filterCampaigns,
    fetchChildOrganizations,
    expandAdGrouping,
    collapseAdgrouping,
    toggleColumn,
    fetchUserPreferences,
    changePage,
    searchCampaigns,
    clearSearch,
    applyFilters,
    openFilters,
    closeFilters,
    deleteFilter,
} from 'pages/dashboard/actions';
import CampaignFormEdit from 'containers/campaign-form-edit/actions';

const useStyles = makeStyles(theme => ({
    select: {
        marginLeft: theme.spacing(2),
    },
    drawer: {
        width: 400,
        padding: theme.spacing(2),
    },
}));

const _getCampaignCreatorOptions = users => {
    const options = _(users)
        .filter(user => !user.suspended && user.global_role !== 'revoked' && !(_.isEmpty(user.first_name) && _.isEmpty(user.last_name)))
        .map(user => ({
            label: user.first_name + ' ' + user.last_name,
            value: user.id,
        }))
        .sortBy(user => user.label)
        .value();

    return options;
};

const STATUS_OPTIONS = [
    {
        value: 'live',
        label: 'Live',
    },
    {
        value: 'paused',
        label: 'Paused',
    },
    {
        value: 'pending',
        label: 'Pending',
    },
    {
        value: 'ended',
        label: 'Ended',
    },
];

const CREATIVE_OPTIONS_v2 = [
    {
        value: 'display_banner',
        label: 'Banner',
    },
    {
        value: 'display_box',
        label: 'Box',
    },
    {
        value: 'display_vertical',
        label: 'Vertical',
    },
    {
        value: 'display_interstitial',
        label: 'Interstitial',
    },
    {
        value: 'display_expandable',
        label: 'Expandable',
    },
    {
        value: 'video',
        label: 'Video',
    },
    {
        value: 'native',
        label: 'Native',
    },
];

const filterOptions = [
    {
        label: 'Status',
        component: ({ filters, updateFilter }) => (
            <MultiSelect
                placeholder="Campaign Status"
                value={filters.status}
                onChange={value => updateFilter({ status: value })}
                options={STATUS_OPTIONS}
            />
        ),
        isPermitted: () => true,
    },
    {
        label: 'Creative Type',
        component: ({ updateFilter, filters }) => (
            <MultiSelect
                onChange={value => updateFilter({ creative_type: value })}
                value={filters.creative_type}
                placeholder="Creative Type"
                options={CREATIVE_OPTIONS_v2}
            />
        ),
        isPermitted: () => true,
    },
    {
        label: 'IAB category',
        component: ({ updateFilter, filters }) => (
            <MultiSelect
                placeholder="IAB category"
                value={filters.category}
                onChange={value => updateFilter({ category: value })}
                options={iabCategoryOptions}
            />
        ),
        isPermitted: () => true,
    },
    {
        label: 'Partner / Advertiser / Sales Rep',
        component: ({ filters, updateFilter, organizations }) => (
            <MultiSelect
                placeholder="Partner / Advertiser / Sales Rep"
                value={filters.client}
                onChange={value => updateFilter({ client: value })}
                options={organizations}
            />
        ),
        isPermitted: ({ organizations }) => organizations.length > 0,
    },
    {
        label: 'Assignee',
        component: ({ filters, updateFilter, ownOrganization }) => (
            <MultiSelect
                placeholder="Assignee"
                value={filters.campaignCreatorId}
                onChange={value => updateFilter({ campaignCreatorId: value })}
                options={_getCampaignCreatorOptions(ownOrganization.users)}
            />
        ),
        isPermitted: ({ canViewCampaignCreator, ownOrganization }) =>
            canViewCampaignCreator() && ownOrganization && ownOrganization.users,
    },
];

const Dashboard = createReactClass({
    getInitialState() {
        return {
            searchInputRef: React.createRef(),
        };
    },

    displayName: 'Dashboard',

    statics: {
        refresh(dispatch, nextState) {
            const queryString = _.get(nextState, 'location.query', {});
            let queryArray = {};

            _.forEach(queryString, (query, key) => {
                if (_.includes(['page', 'sort', 'search'], key)) {
                    queryArray[key] = query;
                } else {
                    queryArray[key] = _.split(query, ',');
                }
            });

            const queryDefaults = { page: 1 };

            const query = {
                ...queryDefaults,
                ...queryArray,
            };

            dispatch(filterCampaigns(query));
            dispatch(fetchDashboardCampaigns({}));
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };
        dispatch(fetchUserPreferences());
        dispatch(fetchChildOrganizations());
        Dashboard.refresh(dispatch, nextState, browserHistory.push);
    },

    componentDidMount() {
        this.init();
    },

    UNSAFE_componentWillMount() {
        this.filterByText = this.createFilterHandler('search');
        this.filterByStatus = this.createFilterHandler('status');
        this.filterByCreativeType = this.createFilterHandler('creative_type');
        this.filterByIABCategory = this.createFilterHandler('category');
        this.filterByPage = this.createFilterHandler('page');
        this.filterByClient = this.createFilterHandler('client');
        this.filterByCampaignCreatorId = this.createFilterHandler('campaignCreatorId');
    },

    createFilterHandler(filter) {
        const { dispatch } = this.props;

        return value => {
            const nextFilter = {
                [filter]: value,
            };
            dispatch(filterCampaigns(nextFilter));
        };
    },

    goToNewCampaignForm() {
        browserHistory.push('/campaigns/new');
    },

    openCampaignHistory(campaignId) {
        browserHistory.push(`/campaigns/${campaignId}/history`);
    },

    toggleAdGrouping(primaryAdGroupingId, isExpanded) {
        const { dispatch } = this.props;

        if (isExpanded === true) {
            dispatch(collapseAdgrouping(primaryAdGroupingId));
            return;
        }

        dispatch(expandAdGrouping(primaryAdGroupingId));
    },

    openCampaignEditForm() {
        const { campaigns, dispatch } = this.props;

        const id = _.map(campaigns, campaign => {
            return campaign.id;
        });

        dispatch(CampaignFormEdit.open(id));
    },

    resetSearchValue() {
        this.state.searchInputRef.current.value = '';
        this.props.dispatch(clearSearch());
    },

    toggleColumn(column) {
        this.props.dispatch(toggleColumn(column));
    },

    searchCampaigns(text) {
        this.props.dispatch(searchCampaigns({ search: text }));
    },

    render() {
        const {
            pagination,
            organizations,
            organizationTimezone,
            ownOrganizationType,
            ownOrganization,
            canViewSetup,
            isDashboardLoading,
            maxResults,
            currentPage,
            hiddenColumns,
            fetchingHiddenColumns,
        } = this.props;

        const dashboardCampaignList = (
            <DashboardCampaignList
                campaigns={this.props.campaigns}
                ownOrganizationType={ownOrganizationType}
                openCampaignHistory={this.openCampaignHistory}
                paginationLocation={this.props.paginationLocation}
                campaignId={this.props.campaignId}
                toggleAdGrouping={this.toggleAdGrouping}
                canViewSetup={canViewSetup}
                openCampaignEditForm={this.openCampaignEditForm}
                organizationTimezone={organizationTimezone}
                isDashboardLoading={isDashboardLoading}
                paginator={
                    <Pagination
                        active={this.props.pagination.currentLinkIndex}
                        moveBack={
                            pagination.moveBack.hide ? null : (
                                <Link
                                    to={{ pathname: '/dashboard', ...pagination.moveBack }}
                                    onClick={() =>
                                        this.props.dispatch(
                                            changePage({ page: pagination.moveBack.id })
                                        )
                                    }
                                >
                                    <i className="fa fa-angle-left" />
                                </Link>
                            )
                        }
                        moveForward={
                            pagination.moveForward.hide ? null : (
                                <Link
                                    to={{ pathname: '/dashboard', ...pagination.moveForward }}
                                    onClick={() =>
                                        this.props.dispatch(
                                            changePage({ page: pagination.moveForward.id })
                                        )
                                    }
                                >
                                    <i className="fa fa-angle-right" />
                                </Link>
                            )
                        }
                        links={_.map(pagination.links, link => (
                            <Link
                                to={{ pathname: '/dashboard', ...link }}
                                key={link.id}
                                onClick={() => this.props.dispatch(changePage({ page: link.id }))}
                            >
                                {link.id}
                            </Link>
                        ))}
                    />
                }
                resetSearchValue={this.resetSearchValue}
                maxResults={maxResults}
                currentPage={currentPage}
                maxPage={pagination.maxPage}
                hiddenColumns={hiddenColumns}
                toggleColumn={this.toggleColumn}
                fetchingHiddenColumns={fetchingHiddenColumns}
                archiveVisibilityToggle={<ArchiveVisibilityToggle />}
                filterButton={
                    <TemporaryDrawer>
                        <FilterOptions />
                    </TemporaryDrawer>
                }
                filterChips={<FilterSection />}
            />
        );

        return (
            <DashboardLayout
                filterByText={this.filterByText}
                filterByStatus={this.filterByStatus}
                filterByCreativeType={this.filterByCreativeType}
                filterByIABCategory={this.filterByIABCategory}
                filterByClient={this.filterByClient}
                filterByCampaignCreatorId={this.filterByCampaignCreatorId}
                pagination={pagination}
                organizations={organizations}
                ownOrganization={ownOrganization}
                ownOrganizationType={ownOrganizationType}
                goToNewCampaignForm={this.goToNewCampaignForm}
                workspace={this.props.workspace}
                dashboardCampaignList={dashboardCampaignList}
                searchQueryString={this.props.location.query.search}
                searchInputRef={this.state.searchInputRef}
                resetSearchValue={this.resetSearchValue}
                hiddenColumns={hiddenColumns}
                archiveVisibilityToggle={<ArchiveVisibilityToggle />}
                searchCampaigns={this.searchCampaigns}
                creativeOptions={CREATIVE_OPTIONS_v2}
                getCampaignCreatorOptions={_getCampaignCreatorOptions}
            />
        );
    },
});

function ArchiveVisibilityToggle() {
    const dispatch = useDispatch();
    const archived = useSelector(state => _.get(state, 'dashboard.filters.archived'));

    const handleToggle = e => {
        let filter = {};
        if (e.target.checked) {
            filter.archived = '1';
        } else {
            filter.archived = '';
        }

        dispatch(filterCampaigns(filter));
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={archived === '1'}
                    onChange={handleToggle}
                    name="checkedB"
                    color="primary"
                />
            }
            label="Show Archived Campaigns"
        />
    );
}

function TemporaryDrawer(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const open = useSelector(state => {
        return _.get(state, 'dashboard.isFilterPopUpOpen');
    });

    const openDrawer = () => {
        dispatch(openFilters());
    };

    const closeDrawer = () => {
        dispatch(closeFilters());
    };

    return (
        <React.Fragment>
            <Button size="small" color="inherit" endIcon={<FilterListIcon />} onClick={openDrawer}>
                Open filters
            </Button>
            <Drawer
                anchor="right"
                open={open}
                classes={{ paper: classes.drawer }}
                onClose={closeDrawer}
            >
                <DialogTitle onClose={closeDrawer}>Filters</DialogTitle>
                {props.children}
            </Drawer>
        </React.Fragment>
    );
}

function FilterSection() {
    const { filters, organizations, ownOrganization } = useSelector(state => {
        const childOrganizations = _.get(state, 'dashboard.childOrganizations');
        const organizations = _(childOrganizations)
            .filter(x => x)
            .map(org => ({
                label: org.name,
                value: org.id,
            }))
            .sortBy('label')
            .value();

        return {
            ...state.dashboard,
            organizations,
        };
    });

    const filteredFilters = _.pickBy(filters, _.isArray);

    const allFilters = [];
    _.forEach(filteredFilters, (values, field) => {
        _.forEach(values, value => {
            allFilters.push({ field, value });
        });
    });

    const dispatch = useDispatch();

    const handleDelete = filter => {
        dispatch(deleteFilter(filter));
    };

    const filterMappings = _.concat(
        STATUS_OPTIONS,
        CREATIVE_OPTIONS_v2,
        iabCategoryOptions,
        organizations,
        _getCampaignCreatorOptions(ownOrganization.users)
    );

    const flattenedFilterMapping = {};

    _.forEach(filterMappings, mapping => {
        flattenedFilterMapping[mapping.value] = mapping.label;
    });

    return (
        <Grid container spacing={1}>
            {_.map(allFilters, filter => (
                <Grid item key={filter.value + filter.field}>
                    <Chip
                        label={flattenedFilterMapping[filter.value]}
                        onDelete={() => handleDelete(filter)}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

function FilterOptions() {
    const { filters, organizations, ownOrganization } = useSelector(state => {
        const childOrganizations = _.get(state, 'dashboard.childOrganizations');
        const organizations = _(childOrganizations)
            .filter(x => x)
            .map(org => ({
                label: org.name,
                value: org.id,
            }))
            .sortBy('label')
            .value();

        return {
            ...state.dashboard,
            organizations,
        };
    });

    const [appliedFilter, setApplyFilter] = useState({
        status: filters.status,
        category: filters.category,
        creative_type: filters.creative_type,
        client: filters.client,
        campaignCreatorId: filters.campaignCreatorId,
    });

    const dispatch = useDispatch();

    const updateFilter = nextFilter => {
        setApplyFilter({ ...appliedFilter, ...nextFilter });
    };

    const handleApply = () => {
        dispatch(applyFilters(appliedFilter));
    };

    let filteredFilterOptions = _(filterOptions)
        .filter(option =>
            option.isPermitted({ organizations, canViewCampaignCreator, ownOrganization })
        )
        .value();

    return (
        <React.Fragment>
            {_.map(filteredFilterOptions, (header, index) => (
                <FormControl margin="dense" key={index}>
                    <FormLabel component="legend">{header.label}</FormLabel>
                    {header.component({
                        filters: appliedFilter,
                        updateFilter,
                        organizations,
                        ownOrganization,
                    })}
                </FormControl>
            ))}
            <ArchiveVisibilityToggle />
            <Box mt={1}>
                <Button color="primary" variant="contained" onClick={handleApply}>
                    Apply
                </Button>
            </Box>
        </React.Fragment>
    );
}

export default connect(selector)(Dashboard);

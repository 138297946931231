import _ from 'lodash';
import React, { useEffect } from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import cn from 'classnames';

import selector from './selector';
import PivotTable from 'widgets/pivot-table';
import { CampaignExportButton } from 'widgets/pivot-table/export-button';
import actions from 'pages/campaigns/report/report-channel/actions';
import { ReportLayout } from 'pages/campaigns/report/report-overview/layout';
import { formatDimensions, formatMetrics } from 'widgets/pivot-table/service';
import { BlockLoadGroup } from 'widgets-v5/load-group';

import { formatNumber_percentage } from 'utils/formatting';
// import AppPopup  from 'containers/app-popup';
import Popup from 'containers/app-popup/popup';
import PopupContent from 'containers/app-popup/popup-content';
import DeactivateSelfWhenClickOutside from 'containers/app-popup/deactivate-self-when-click-outside';

import {
    adgroup_toggleGroup,
    adgroup_reset,
    filterByDateRange,
    inititalizeReport,
    updateTimezone,
    refreshReport,
} from 'pages/campaigns/report/report-channel/actions';
import { controls_setActiveScopeTab } from 'pages/campaigns/report/report-overview/actions';

import Card, { VerticalCardGroup } from 'widgets-v5/card';
import Tabs from 'widgets-v5/tabs';
import { BarGraphTile } from 'widgets-v5/bar-graph-tile';
import { useReportExplore } from '../report-explore/v2/hook';
import { TIMEOUT_IDS } from '../constants';

const Channel = createReactClass({
    displayName: 'Channel',

    statics: {
        refresh(dispatch, nextState) {
            dispatch(inititalizeReport(nextState.params.campaignId));
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        Channel.refresh(dispatch, nextState, browserHistory.push);
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        const {
            params: { campaignId },
        } = this.props;

        if (campaignId !== prevProps.params.campaignId) {
            this.init();
            return;
        }
    },

    handleSort(column) {
        const { campaignId, dispatch } = this.props;
        dispatch(actions.sortColumn(campaignId, column));
    },

    handleExpand(rowId) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.expandRow(campaignId, rowId));
    },

    handleCollapse(rowId) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.collapseRow(campaignId, rowId));
    },

    handleAddSplit(split) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.addSplit(campaignId, split));
    },

    handleRemoveSplit(split) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.removeSplit(campaignId, split));
    },

    handleUpdateSplits(splits) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.updateSplits(campaignId, splits));
    },

    topChannels(limit) {
        return () => {
            const { campaignId, dispatch } = this.props;
            dispatch(actions.limitChannelsBy(campaignId, limit));
        };
    },

    serializePivotTable() {
        return this.refs.pivotTable.serialize();
    },

    // copied

    controls_handleSetActiveScopeTab(scopeId) {
        const { campaignId } = this.props.params;
        this.props.dispatch(controls_setActiveScopeTab(campaignId, scopeId));
    },

    /* Bargraph handlers */
    adgroup_handleGroupToggle(component, grouping, group) {
        const { campaignId, dispatch } = this.props;

        dispatch(adgroup_toggleGroup(campaignId, component, grouping, group));
    },

    adgroup_handleReset(component) {
        const { campaignId, dispatch } = this.props;

        dispatch(adgroup_reset(campaignId, component));
    },

    filterByDateRange({ timeframe, start, end, selectedKey }) {
        const { campaignId, dispatch } = this.props;

        dispatch(filterByDateRange(campaignId, { timeframe, start, end, selectedKey }));
    },

    updateTimezone(timezone) {
        const { campaignId } = this.props.params;

        this.props.dispatch(updateTimezone(campaignId, timezone));
    },

    getTab() {
        const { routes } = this.props;
        const lastRoute = _.last(routes);

        return lastRoute.path;
    },

    getTimezone() {
        const { dateRange, organizationTimezone } = this.props;

        const defaultTimezone = organizationTimezone;

        return dateRange.timezone || defaultTimezone;
    },

    formatRow(row) {
        const { pivotTableState, dictionary, campaign } = this.props;

        let nextRow = { ...row };
        nextRow = formatDimensions(
            nextRow,
            pivotTableState.pivotTable.splits,
            dictionary,
            campaign
        );

        const channelTypes = _.get(nextRow, 'attributes.channel_type', []).filter(x => x);
        const channelType = _.map(channelTypes, _.capitalize).join(', ');

        const channelName =
            channelType === 'Site'
                ? _.get(nextRow, 'group', '')
                : _.get(nextRow, 'attributes.channel_name.0', '');

        // each row should derive channel_id from channel_name attribute
        let columnData = {
            ...nextRow.columnData,
            channel_id: channelName,
            type: channelType,
        };

        // update 'dimension' field when dimension is channel_id
        if (nextRow.dimension === 'channel_id') {
            columnData = {
                ...columnData,
                // group has a zero when bundle_id isn't provided
                dimension: nextRow.group === '' ? 'Not Available' : channelName,
                channel_id: nextRow.group === '' ? 'Not Available' : channelName,
            };
        }

        const adsAttribute = _.get(nextRow, 'attributes.ads', []);
        const billingRateLabel = _(adsAttribute)
            .sortBy(['billing_enabled', 'billing_term', 'billing_rate'])
            .map(ad => {
                if (!ad.billing_enabled) {
                    return 'Bonus';
                }

                const isCpmCpc = _.includes(['CPM', 'CPC'], ad.billing_term);

                if (isCpmCpc) {
                    return `$${ad.billing_rate} ${ad.billing_term}`;
                } else {
                    return `${formatNumber_percentage(ad.billing_rate)}`;
                }
            })
            .uniq()
            .join(', ');

        columnData = {
            ...columnData,
            billing_rate: billingRateLabel,
        };

        return {
            ...nextRow,
            columnData,
        };
    },

    refreshPageData() {
        const { campaignId } = this.props.params;

        this.props.dispatch(refreshReport(campaignId));
    },

    // copied end
    render() {
        const { isInitialized } = this.props;

        if (!isInitialized) {
            return (
                <div className="ef6-alignment__center" style={{ width: '100%', height: '100%' }}>
                    <BlockLoadGroup isLoading={true} />
                </div>
            );
        }

        const {
            pivotTableState,
            campaign,
            dictionary,
            dateRange,

            // copied
            scopeComponents,
            activeScopeTab,

            selectedMetric,
            campaignRelationship,
        } = this.props;

        const timezone = this.getTimezone();

        const activeScopeComponent = _.filter(
            scopeComponents,
            component => component.grouping === activeScopeTab
        ).map(component => {
            return (
                <BarGraphTile
                    {...component}
                    key={component.id}
                    onReset={this.adgroup_handleReset}
                    onCheckboxSelected={this.adgroup_handleGroupToggle}
                />
            );
        })[0];

        return (
            <ReportLayout
                refreshPageData={this.refreshPageData}
                campaignRelationship={campaignRelationship}
                selectedMetric={selectedMetric}
                campaign={campaign}
                updateTimezone={this.updateTimezone}
                timezone={timezone}
                existingTimezones={[this.props.organizationTimezone, campaign.default_timezone]}
                dateRange={dateRange}
                workspace={
                    <VerticalCardGroup>
                        <Card>
                            <div className="ef5-reports__scopes">
                                {scopeComponents && scopeComponents.length > 1 && (
                                    <Tabs
                                        activeItem={activeScopeTab}
                                        onChange={value => {
                                            this.controls_handleSetActiveScopeTab(value);
                                        }}
                                        items={scopeComponents.map(scope => ({
                                            label: scope.title,
                                            value: scope.grouping,
                                        }))}
                                    />
                                )}
                                <div
                                    className={cn('ef5-reports__scope-dimension', {
                                        'ef5-reports__scope-dimension_multiple-scopes':
                                            scopeComponents && scopeComponents.length > 1,
                                    })}
                                >
                                    {activeScopeComponent}
                                </div>
                            </div>
                        </Card>
                        <Card>
                            {pivotTableState ? (
                                <BlockLoadGroup isLoading={pivotTableState.isLoading}>
                                    <PivotTable
                                        ref="pivotTable"
                                        timezone={timezone}
                                        config={pivotTableState.pivotTable}
                                        dictionary={dictionary}
                                        columns={pivotTableState.columns}
                                        onSerializeFormatRow={(row, columns) => {
                                            return this.formatRow(row, columns);
                                        }}
                                        onRenderFormatRow={(row, columns) => {
                                            let nextRow = { ...row };
                                            nextRow = formatMetrics(nextRow, columns);
                                            nextRow = this.formatRow(nextRow, columns);

                                            return nextRow;
                                        }}
                                        sortByHook={(sorter, columnName, row, dictionary) => {
                                            switch (columnName) {
                                                case 'billing_rate': {
                                                    return row.columnData.billing_rate;
                                                }
                                                case 'dimension': {
                                                    if (
                                                        row.dimension === 'date' ||
                                                        row.dimension ===
                                                            'week_by_monday_to_sunday' ||
                                                        row.dimension ===
                                                            'week_by_campaign_start_day' ||
                                                        row.dimension === 'month' ||
                                                        row.dimension === 'hour'
                                                    ) {
                                                        return row.group;
                                                    }
                                                    if (row.dimension === 'channel_id') {
                                                        return row.columnData.channel_id.toLowerCase();
                                                    }
                                                }
                                                default:
                                                    return sorter(columnName, row, dictionary);
                                            }
                                        }}
                                        renderCell={(content, row, column) => {
                                            const dimension = _.get(row, 'dimension');

                                            // ** Note **
                                            // row.group is channel_id when row.dimension === 'channel_id'
                                            // row.attributes.channel_name[0] is channel name
                                            // row.attributes.channel_type[0] is channel type

                                            // // ** For dev **
                                            // //
                                            // if ( (column.name === 'dimension')) {
                                            //     const channel_id = 'com.whatsapplock';
                                            //     const channel_name = 'Name name name';
                                            //     const channel_type = 'type';
                                            //     return (
                                            //         <DeactivateSelfWhenClickOutside>
                                            //             <DimensionContent>
                                            //                 {content}
                                            //             </DimensionContent>
                                            //             <Popup >
                                            //                 <PopupContent
                                            //                     channel_id={channel_id}
                                            //                     channel_name={channel_name}
                                            //                     channel_type={channel_type}
                                            //                 />
                                            //             </Popup>
                                            //         </DeactivateSelfWhenClickOutside>
                                            //     );
                                            // }

                                            if (
                                                column.name === 'dimension' &&
                                                dimension === 'channel_id'
                                            ) {
                                                // ** For testing **
                                                const channel_id = _.get(row, `group`);
                                                const channel_name = _.get(
                                                    row,
                                                    `attributes.channel_name[0]`
                                                );
                                                const channel_type = _.get(
                                                    row,
                                                    `attributes.channel_type[0]`
                                                );
                                                // console.log('xxxx channel_name: ', channel_name);
                                                return (
                                                    <DeactivateSelfWhenClickOutside>
                                                        <div
                                                            style={{ cursor: 'pointer' }}
                                                            className="am-pivotTable-dimensionValueForChannels am-pivotTable-dimensionValueForChannels_channelName"
                                                        >
                                                            {content}
                                                        </div>
                                                        <Popup>
                                                            <PopupContent
                                                                channel_id={channel_id}
                                                                channel_name={channel_name}
                                                                channel_type={channel_type}
                                                            />
                                                        </Popup>
                                                    </DeactivateSelfWhenClickOutside>
                                                );
                                            } else {
                                                return (
                                                    <div className="am-pivotTable-dimensionValueForChannels">
                                                        {content}
                                                    </div>
                                                );
                                            }

                                            return content;
                                        }}
                                        topbarWorkspace={
                                            <span>
                                                <span className="am-pivotTable_topApps">
                                                    Show top
                                                    {_.map(
                                                        [
                                                            { label: '50', value: 50 },
                                                            { label: '100', value: 100 },
                                                            { label: '250', value: 250 },
                                                            { label: 'All', value: 0 },
                                                        ],
                                                        (option, i) => (
                                                            <span key={option.label}>
                                                                {i !== 0 && <span>/</span>}
                                                                <span
                                                                    className={cn(
                                                                        'am-pivotTable_limitTab',
                                                                        {
                                                                            'am-pivotTable_limitTab-activeLimit':
                                                                                pivotTableState.limit ===
                                                                                option.value,
                                                                        }
                                                                    )}
                                                                    onClick={this.topChannels(
                                                                        option.value
                                                                    )}
                                                                >
                                                                    {option.label}
                                                                </span>
                                                            </span>
                                                        )
                                                    )}
                                                    Apps & Sites
                                                </span>

                                                <CampaignExportButton
                                                    campaign={campaign}
                                                    dateRange={dateRange}
                                                    options={
                                                        pivotTableState.limit === 0
                                                            ? ['json', 'csv']
                                                            : ['json', 'csv', 'xls']
                                                    }
                                                    serializePivotTable={this.serializePivotTable}
                                                    dictionary={dictionary}
                                                />
                                            </span>
                                        }
                                        onSort={this.handleSort}
                                        onExpand={this.handleExpand}
                                        onCollapse={this.handleCollapse}
                                        onAddSplit={this.handleAddSplit}
                                        onRemoveSplit={this.handleRemoveSplit}
                                        onUpdateSplits={this.handleUpdateSplits}
                                    />
                                </BlockLoadGroup>
                            ) : (
                                <BlockLoadGroup isLoading={true} />
                            )}
                        </Card>
                    </VerticalCardGroup>
                }
                filterByDateRange={this.filterByDateRange}
                tab={this.getTab()}
            />
        );
    },
});

function ChannelWrapper(props) {
    const { removeTimeouts } = useReportExplore();
    useEffect(() => () => removeTimeouts(TIMEOUT_IDS), [])
    return <Channel {...props} />;
}

export default connect(selector)(ChannelWrapper);

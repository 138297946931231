import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import actions from './actions';
import selector from './selector';

import EnvironicsLayout from './layout';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const EnvironicsDashboard = ({ dispatch, audienceSegments, ...props }) => {
    useEffect(() => {
        dispatch(actions.getAudiences());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    return (
        <EnvironicsLayout
            audienceSegments={audienceSegments}
            onSearch={onChange}
            activeAudience={props.params.audienceId}
            organizationId={props.organizationId}
        />
    );
};

export default connect(selector)(EnvironicsDashboard);

import _ from 'lodash';
import React from 'react';

import { MultiSelect } from 'widgets-v6/select';
import { IncludeExcludeLayout } from 'widgets-v6/layout';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

const getOptionsExcludedFromList = (options, list) => {
    return _.filter(options, ({ value, label }) => !_.includes(list, value));
};

const MultiSelectWrapper = ({
    onChange,
    type,
    color,
    placeholder,
    fieldName,
    options,
    value,
    marginBottom,
}) => {
    return (
        <IncludeExcludeLayout type={type} marginBottom={marginBottom}>
            <MultiSelect
                value={value}
                onChange={value => onChange({ field: fieldName, value })}
                options={options}
                placeholder={placeholder} //"Select exchanges to exclude"
            />
        </IncludeExcludeLayout>
    );
};

export const TargetCarriersIsps = ({
    onFieldChange,
    options,
    carrierISPIncludeValue,
    carrierISPExcludeValue,
    platforms,
    updateSetupHardWarnings,
    carrierIspWarning,
    isCrossPlatformCampaign,
}) => {
    const handleChange = ({ field, value }) => {
        onFieldChange(field)(value);
    };

    return (
        <React.Fragment>
            <MultiSelectWrapper
                onChange={handleChange}
                type="Include"
                placeholder="Select carriers / ISPs to include"
                fieldName="target_carriers"
                options={getOptionsExcludedFromList(options, carrierISPExcludeValue)}
                value={carrierISPIncludeValue}
                marginBottom
            />
            <MultiSelectWrapper
                onChange={handleChange}
                type="Exclude"
                placeholder="Select carriers / ISPs to exclude"
                fieldName="carriers_exclude"
                options={getOptionsExcludedFromList(options, carrierISPIncludeValue)}
                value={carrierISPExcludeValue}
            />
            {carrierIspWarning.hasWarning && isCrossPlatformCampaign && (
                <React.Fragment>
                    <br />
                    <WarningText
                        severity={carrierIspWarning.severity}
                        message={carrierIspWarning.message}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export const TargetCarriersIspsSummary = ({ carrierISPIncludeValue, carrierISPExcludeValue }) => {
    return (
        <React.Fragment>
            <IncludeExcludeLayout type="Include" marginBottom>
                {carrierISPIncludeValue}
            </IncludeExcludeLayout>
            <IncludeExcludeLayout type="Exclude">{carrierISPExcludeValue}</IncludeExcludeLayout>
        </React.Fragment>
    );
};

import _ from 'lodash';
import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { SearchInput } from 'widgets-v6/standard-input';

import PLATFORMS from 'common/constants/platforms';

class AdMenu extends React.Component {
    constructor(props, context) {
        super(props, context);
        const selectedItems = this.updateSelected(_.map(props.ads, ad => ad.id), false);

        this.state = {
            selectedItems,
            lastCopiedAd: null,
            searchedText: '',
        };
    }

    handleSearch = _.debounce(text => {
        const { value } = text.target;
        this.setState({ searchedText: value });
    }, 200);

    selectAll = (isChecked, filteredAds) => {
        const ads = filteredAds;
        const selectedItems = this.updateSelected(_.map(ads, ad => ad.id), !isChecked);

        this.setState({ selectedItems }, () => {
            this.emitChange();
        });
    };

    toggleAd = (adId, isChecked) => {
        const selectedItems = this.updateSelected([adId], !isChecked);
        this.setState({ selectedItems }, () => {
            this.emitChange();
        });
    };

    updateSelected = (adIds, isChecked) => {
        const selectedItems = { ...(this.state ? this.state.selectedItems : {}) };

        _.each(adIds, adId => {
            selectedItems[adId] = isChecked;
        });

        return selectedItems;
    };

    emitChange = () => {
        const adIds = _(this.state.selectedItems)
            .map((isChecked, adId) => ({ isChecked, adId }))
            .filter(item => item.isChecked)
            .map(item => item.adId)
            .value();

        this.props.onChange(adIds);
    };

    copyToDraft = ad => {
        this.setState({ lastCopiedAd: ad.id });
        this.props.onCopyAd(ad);
    };

    handleReset = () => {
        this.setState({ lastCopiedAd: null });
        this.props.onResetForm();
    };

    render() {
        const { selectedItems } = this.state;
        const { activePlatformFilter } = this.props;
        const areAllSelected =
            _.keys(selectedItems).length > 0 && _.every(selectedItems, val => val === true);

        const checkboxLabel = activePlatformFilter
            ? `All ${PLATFORMS.find(platform => platform.value === activePlatformFilter).label} Ads`
            : 'All Ads';

        const filteredAds = _.filter(this.props.ads, ad =>
            _.includes(_.lowerCase(ad.name), _.lowerCase(this.state.searchedText))
        );

        return (
            <React.Fragment>
                <SearchInput
                    placeholder="Filter Ads"
                    onChange={this.handleSearch}
                    defaultValue=""
                />
                <List>
                    <ListItem dense onChange={() => this.selectAll(areAllSelected, filteredAds)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={areAllSelected}
                                tabIndex={-1}
                                disableRipple
                                color="primary"
                            />
                        </ListItemIcon>
                        <ListItemText primary={checkboxLabel} />
                    </ListItem>
                    {_.map(filteredAds, ad => (
                        <ListItem
                            key={ad.id}
                            dense
                            button
                            onClick={() => this.toggleAd(ad.id, selectedItems[ad.id])}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedItems[ad.id]}
                                    tabIndex={-1}
                                    disableRipple
                                    color="primary"
                                />
                            </ListItemIcon>
                            <ListItemText primary={`#${ad.id} ${ad.name}`} />
                        </ListItem>
                    ))}
                </List>
            </React.Fragment>
        );
    }
}

export default AdMenu;

import _ from 'lodash';

import c from 'common/constants/flux-events';
import { validateDraft } from 'services/resources/users';

const initialState = {
    draft: null,
    etag: null,
    isLoading: false,
    httpErrors: void 0,
    isOpen: false,
    showErrors: false,
    formErrors: [],
};

export const NAME = 'adminUsersForm';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.ADMIN_USERS_FORM__INIT_START: {
            return {
                ...state,
                isOpen: true,
                isLoading: true,
            };
        }

        case c.ADMIN_USERS_FORM__INIT_SUCCESS: {
            const draft_current = _.get(state, `draft`, null);
            let draft_next;

            if (draft_current === null) {
                draft_next = _.get(action, 'payload.draft');
            } else {
                draft_next = _.cloneDeep(draft_current);
            }

            const formErrors = validateDraft(draft_next);

            return {
                ...state,
                draft: _.get(action, 'payload.draft'),
                isLoading: false,
                formErrors,
                etag: action.payload.etag,
            };
        }

        case c.ADMIN_USERS_FORM__INIT_FAIL: {
            const httpError = _.get(action, 'payload.err', void 0);

            return {
                ...state,
                httpError,
                isLoading: false,
            };
        }

        case c.ADMIN_USERS_FORM__FIELD_CHANGED: {
            const error = _.get(action, 'payload.err', void 0);
            const data = _.get(action, 'payload.data', {});

            const draft_current = _.get(state, `draft`, null);
            const draft_next = {
                ..._.cloneDeep(draft_current),
                ...data,
            };

            const formErrors = validateDraft(draft_next);

            return {
                ...state,
                draft: draft_next,
                formErrors,
            };
        }

        case c.ADMIN_USERS_FORM__USER_SUBMIT: {
            const payload = _.get(action, 'payload', void 0);

            return {
                ...state,
                showErrors: true,
            };
        }

        case c.ADMIN_USERS_FORM__SUBMIT_START: {
            const payload = _.get(action, 'payload', void 0);

            return {
                ...state,
                isLoading: true,
            };
        }

        case c.ADMIN_USERS_FORM__SUBMIT_SUCESS: {
            const payload = _.get(action, 'payload', void 0);
            return {
                ...initialState,
            };
        }

        case c.ADMIN_USERS_FORM__SUBMIT_FAIL: {
            const payload = _.get(action, 'payload', void 0);
            const httpErrors = _.get(action, 'payload.httpErrors', void 0);
            return {
                ...state,
                httpErrors,
                formErrors: _.get(httpErrors, `body`, []),
                isLoading: false,
            };
        }

        case c.ADMIN_USERS_FORM__TEARDOWN: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
}

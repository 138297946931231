import _ from 'lodash';
import React from 'react';

import FormField from 'widgets-v5/form-field';
import FormCheckbox from 'widgets/form-inputs/form-checkbox';
import OptionSelector from 'widgets-v5/option-selector';
import { DeviceOSMapping } from 'states/resources/ads/business-logic';

const CB_VALUE_2_FORM_itemIsGroupName = (values, formCheckboxItems) => {
    // Takes values like: ['M', 'F', ...],  only the checked items present in collection

    if (values && values.length === 0) {
        // empthy array means everything is selected
        formCheckboxItems.forEach(item => {
            item.checked = true;
        });
    } else {
        // Reset all checkbox to unchecked first
        formCheckboxItems.forEach(item => {
            item.checked = false;
        });

        // Then set checkbox in accourding to new state
        _(formCheckboxItems).forEach(item => {
            _(values).forEach(value => {
                if (value === item.group) {
                    item.checked = true;
                }
            });
        });
    }

    // console.log('xx: CB_VALUE_2_FORM_itemIsGroupName: values ', values);
    // console.log('xx: CB_VALUE_2_FORM_itemIsGroupName: formCheckboxItem_toCheck ', formCheckboxItems);
    return formCheckboxItems;
};

const CB_VALUE_2_FORM_itemsAreBoolean = (values, formCheckboxItems) => {
    // takes values like: [true, true, false, true ... ]

    // const values2 = _([true, true, true, false, false, false, true]).map((value, index)=>{
    const values2 = _(values)
        .map((value, index) => {
            if (value === true) {
                return index + '';
            } else {
                return void 0;
            }
        })
        .filter(v => v)
        .value();

    // Reset all checkbox to unchecked
    formCheckboxItems.forEach(item => {
        item.checked = false;
    });

    _(formCheckboxItems).forEach(item => {
        _(values2).forEach(value => {
            if (value === item.group) {
                item.checked = true;
            }
        });
    });
    return formCheckboxItems;
};

const CB_FORM_2_VALUE_itemIsGroupName = formCheckboxItems => {
    //return  values like: ['M', 'F', ...],  only the checked items present in collection

    const values = _(formCheckboxItems)
        .map(v => {
            if (v.checked === true) {
                return v.group;
            } else {
                return void 0;
            }
        })
        .filter(v => v)
        .value();
    return values;
};

const CB_FORM_2_VALUE_itemsAreBoolean = formCheckboxItems => {
    // return values like: [true, true, false, true ... ]
    const values = _(formCheckboxItems)
        .map(v => {
            return v.checked;
        })
        .value();
    return values;
};

const CB_VALUE_2_FORM_itemIsGroupName_emptyIsNonSelected = (values, formCheckboxItems) => {
    // Takes values like: ['M', 'F', ...],  only the checked items present in collection

    // Reset check box to uncheck first
    formCheckboxItems.forEach(item => {
        item.checked = false;
    });

    // Then set checkbox in accourding to new state
    _(formCheckboxItems).forEach(item => {
        _(values).forEach(value => {
            if (value === item.group) {
                item.checked = true;
            }
        });
    });

    // console.log('xx: CB_VALUE_2_FORM_itemIsGroupName_emptyIsNonSelected: values ', values);
    // console.log('xx: CB_VALUE_2_FORM_itemIsGroupName_emptyIsNonSelected: formCheckboxItem_toCheck ', formCheckboxItems);
    return formCheckboxItems;
};
const CB_FORM_2_VALUE_itemIsGroupName_emptyIsNonSelected = formCheckboxItems => {
    //return  values like: ['M', 'F', ...],  only the checked items present in collection

    const values = _(formCheckboxItems)
        .map(v => {
            if (v.checked === true) {
                return v.group;
            } else {
                return void 0;
            }
        })
        .filter(v => v)
        .value();
    return values;
};

const ReactClassFactory = OPTS => {
    const { SCHEMA, MINIMUM_CHECKBOX_SELECT, LABEL, CB_VALUE_2_FORM, CB_FORM_2_VALUE } = OPTS;
    return class extends React.Component {
        static defaultProps = {
            label: LABEL,
        };

        UNSAFE_componentWillMount() {
            this._formCheckboxItems = CB_VALUE_2_FORM(this.props.value, [...SCHEMA]);
            this.value = this.getValue();
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            this._formCheckboxItems = CB_VALUE_2_FORM(nextProps.value, [...SCHEMA]);
        }

        onCheckboxChange = (index, checked) => {
            this._formCheckboxItems = [...this._formCheckboxItems].map((item, i) => ({
                ...item,
                checked: i === index ? checked : item.checked,
            }));

            this.value = this.getValue(); // value for parent to read
            this.props.onChange(this.value);
        };

        getValue = () => {
            return CB_FORM_2_VALUE(this._formCheckboxItems);
        };

        render() {
            const { errors } = this.props;
            const { buttonSW } = this.props;

            var buttonSwitcherComponent = null;

            if (buttonSW) {
                buttonSwitcherComponent = (
                    <OptionSelector
                        onClick={this.props.handleButtonSwitcher}
                        btnTextLeft={this.props.textLeft}
                        btnTextRight={this.props.textRight}
                        position={this.props.position}
                    />
                );
            }

            return (
                <div className="ef5-ad-form-checkbox-groups">
                    <FormField
                        label={this.props.label}
                        description=""
                        errors={errors}
                        showErrors={true}
                        isRequired={false}
                        marginBottom={this.props.marginBottom}
                    >
                        {buttonSwitcherComponent}
                        <FormCheckbox
                            handleChange={this.onCheckboxChange}
                            minSelected={MINIMUM_CHECKBOX_SELECT}
                            items={this._formCheckboxItems}
                        />
                    </FormField>
                </div>
            );
        }
    };
};

const OPTS_target_age_groups_EF_1418 = {
    SCHEMA: [
        { group: 1, label: '13 - 18', checked: false },
        { group: 2, label: '19 - 24', checked: false },
        { group: 3, label: '25 - 34', checked: false },
        // { group:4 , label: '35 +'   , checked: false, },
        { group: 5, label: '35 - 44', checked: false },
        { group: 6, label: '45 - 54', checked: false },
        { group: 7, label: '55 - 64', checked: false },
        { group: 8, label: '65 +', checked: false },
        { group: -1, label: 'Unknown', checked: false },
    ],
    MINIMUM_CHECKBOX_SELECT: 1,
    LABEL: 'Age',
    CB_FORM_2_VALUE: CB_FORM_2_VALUE_itemIsGroupName,
    CB_VALUE_2_FORM: CB_VALUE_2_FORM_itemIsGroupName,
};
export const TargetAgeGroups_EF_1418 = ReactClassFactory(OPTS_target_age_groups_EF_1418);

const OPTS_target_genders = {
    SCHEMA: [
        { group: 'M', label: 'Male', checked: false },
        { group: 'F', label: 'Female', checked: false },
        { group: 'D', label: 'Unknown', checked: false },
    ],
    MINIMUM_CHECKBOX_SELECT: 1,
    LABEL: 'Gender',
    CB_FORM_2_VALUE: CB_FORM_2_VALUE_itemIsGroupName,
    CB_VALUE_2_FORM: CB_VALUE_2_FORM_itemIsGroupName,
};
export const TargetGenders = ReactClassFactory(OPTS_target_genders);

const OPTS_weekparting = {
    SCHEMA: [
        { group: '0', label: 'Mon', checked: false },
        { group: '1', label: 'Tue', checked: false },
        { group: '2', label: 'Wed', checked: false },
        { group: '3', label: 'Thu', checked: false },
        { group: '4', label: 'Fri', checked: false },
        { group: '5', label: 'Sat', checked: false },
        { group: '6', label: 'Sun', checked: false },
    ],
    MINIMUM_CHECKBOX_SELECT: 1,
    LABEL: (
        <div>
            Weekparts
            <span>
                <i className="fa fa-globe ef5-ad-form-checkbox-groups__weekparts-utc-label" /> UTC
            </span>
        </div>
    ),
    CB_FORM_2_VALUE: CB_FORM_2_VALUE_itemsAreBoolean,
    CB_VALUE_2_FORM: CB_VALUE_2_FORM_itemsAreBoolean,
};
export const Weekparts = ReactClassFactory(OPTS_weekparting);

const OPTS_target_device_os = {
    SCHEMA: [
        { group: DeviceOSMapping.IOS, label: DeviceOSMapping.IOS, checked: true },
        { group: DeviceOSMapping.Android, label: DeviceOSMapping.Android, checked: true },
    ],
    MINIMUM_CHECKBOX_SELECT: 1,
    LABEL: 'Device OS',
    CB_FORM_2_VALUE: CB_FORM_2_VALUE_itemIsGroupName_emptyIsNonSelected,
    CB_VALUE_2_FORM: CB_VALUE_2_FORM_itemIsGroupName_emptyIsNonSelected,
};
export const TargetDeviceOs = ReactClassFactory(OPTS_target_device_os);

export class AdFormCheckboxList extends React.Component {
    handleChange = (index, checked, selectedOptions, selectedValues) => {
        this.props.onChange(selectedValues);
    };
    render() {
        const { options, values } = this.props;

        const checkedValueMapping = {};
        _.each(values, value => {
            checkedValueMapping[value] = true;
        });

        const areAllUnchecked = _.every(options, o => o.checked === false);

        const items = _.map(this.props.options, option => {
            let checked;

            // if none are selected, select all
            if (areAllUnchecked) {
                checked = true;
            } else {
                checked = !!checkedValueMapping[option.value];
            }

            return {
                ...option,
                checked,
            };
        });

        return (
            <div className="ef5-ad-form-checkbox-groups">
                <FormField
                    label={this.props.label}
                    description=""
                    errors={this.props.errors}
                    showErrors={true}
                    isRequired={false}
                >
                    <FormCheckbox
                        handleChange={this.handleChange}
                        minSelected={this.props.minSelected}
                        items={items}
                    />
                </FormField>
            </div>
        );
    }
}

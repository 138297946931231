import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Layout from './layout';
import selector from './selector';
import actions from './actions';

import { isInternalUser } from 'states/profile/business-rules';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { DialogTitle } from 'widgets-v6/modal';

const modeOptions = [
    { label: 'RETARGETING SEGMENT', value: 'retargeting' },
    { label: 'UPLOADED SEGMENT', value: 'upload' },
];

const sourceTypeOptions = [{ label: 'Campaign', value: 'campaign' }, { label: 'Ad', value: 'ad' }];
class AudienceEditorWrapper extends React.Component {
    render() {
        return <AudienceEditor {...this.props} />;
    }
}

class AudienceEditor extends React.Component {
    updateDraft = field => {
        return value => {
            this.props.dispatch(
                actions.updateDraft({
                    [field]: value,
                })
            );
        };
    };

    handleSave = () => {
        const { audienceId } = this.props;
        this.props.dispatch(actions.save(audienceId));
    };

    handleCancel = () => {
        this.props.dispatch(actions.closeModal());
    };

    updateMode = value => {
        this.props.dispatch(actions.updateMode(value));
    };

    handleFileChange = formData => {
        const { description } = this.props.audienceEditorState.draft;
        const file = formData.get('asset');

        this.props.dispatch(actions.updateDraft({ formData }));
        this.props.dispatch(actions.updateDraft({ description: description || file.name }));
        this.props.dispatch(actions.updateField('fileName', file.name));
    };

    updateRetargeting = type => {
        this.props.dispatch(actions.updateRetargetingType(type));
    };

    updateUploadOrigin = origin => {
        this.props.dispatch(actions.updateDraft({ upload_origin: origin }));
    };

    updateCustomUploadOrigin = value => {
        this.props.dispatch(actions.updateDraft({ customUploadOrigin: value }));
    };

    updateSource = source => {
        this.props.dispatch(
            actions.updateDraft({
                source_type: source,
                source_id: [],
            })
        );
    };

    render() {
        return (
            <UploadedAudiencesModal
                open={this.props.audienceEditorState.isModalOpen}
                onSave={this.handleSave}
                onCancel={this.handleCancel}
            >
                <Layout
                    isLoading={
                        this.props.audienceEditorState.isInitializing ||
                        this.props.audienceEditorState.isSaving
                    }
                    isCampaignsLoading={this.props.resourcesLoading}
                    showErrors={this.props.audienceEditorState.showErrors}
                    errors={this.props.audienceEditorState.errors}
                    serverError={this.props.audienceEditorState.serverError}
                    invalidLines={this.props.audienceEditorState.invalidLines}
                    draft={this.props.audienceEditorState.draft}
                    fileName={this.props.audienceEditorState.fileName}
                    isEditing={this.props.audienceEditorState.isEditing}
                    matchRate={this.props.audienceEditorState.matchRate}
                    deviceIds={this.props.audienceEditorState.deviceIds}
                    matchedUsers={this.props.audienceEditorState.matchedUsers}
                    modeOptions={modeOptions}
                    campaignOptions={this.props.campaignOptions}
                    adOptions={this.props.adOptions}
                    advertiserOptions={this.props.advertiserOptions}
                    uploadOriginOptions={this.props.uploadOriginOptions}
                    retargetingOptions={getRetargetingOptions()}
                    conversionOptions={this.props.conversionOptions}
                    eventOptions={this.props.eventOptions}
                    attributionOptions={this.props.attributionOptions}
                    sourceTypeOptions={sourceTypeOptions}
                    onSourceTypeChange={this.updateSource}
                    onNameChange={this.updateDraft('name')}
                    onSupportsChange={this.updateDraft('supports')}
                    onModeChange={this.updateMode}
                    onSourceChange={this.updateDraft('source_id')}
                    onAdvertiserChange={this.updateDraft('advertiser_id')}
                    onLookBackChange={this.updateDraft('lookback')}
                    onRetargetingChange={this.updateRetargeting}
                    onOriginChange={this.updateUploadOrigin}
                    onEventChange={this.updateDraft('event_name')}
                    onEventNameListChange={this.updateDraft('event_name_list')}
                    onFileChange={this.handleFileChange}
                    onDescriptionChange={this.updateDraft('description')}
                    onThirdPartyFeesChange={this.updateDraft('third_party_fees')}
                    onSave={this.handleSave}
                    onCancel={this.handleCancel}
                    updateCustomUploadOrigin={this.updateCustomUploadOrigin}
                />
            </UploadedAudiencesModal>
        )
    }
}

export function UploadedAudiencesModal(props) {
    return (
        <Dialog open={props.open}>
            <DialogTitle onClose={props.onCancel}>Uploaded Audiences</DialogTitle>
            {props.children}
            <DialogActions>
                <Button variant="contained" onClick={props.onSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function getRetargetingOptions() {
    let retargetingOptions = [
        { label: 'Impressions', value: 'impression' },
        { label: 'Clicks', value: 'click' },
        { label: 'Engagements', value: 'conversion' },
    ];
    if (!isInternalUser()) {
        retargetingOptions = _.filter(retargetingOptions, option => option.value !== 'conversion');
    }
    return retargetingOptions.concat({
        label: 'Campaign Pixel',
        value: 'attribution',
    });
}

export default connect(selector)(AudienceEditorWrapper);

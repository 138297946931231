const POIS_EDITOR = [
    'POI_EDITOR__OPEN',
    'POI_EDITOR__CLOSE',
    'POI_EDITOR__INITIALIZE__START',
    'POI_EDITOR__INITIALIZE__SUCCESS',
    'POI_EDITOR__INITIALIZE__FAIL',
    'POI_EDITOR__SAVE__START',
    'POI_EDITOR__SAVE__SUCCESS',
    'POI_EDITOR__SAVE__FAIL',
    'POI_EDITOR__DRAFT_UPDATE',
    'POI_EDITOR__DRAFT_ADD_POI',
    'POI_EDITOR__DRAFT_REMOVE_POI_BYID',
    'POI_EDITOR__SHOWERROR',
];

const ADMIN_ORGS = ['ADMIN_ORGS__INIT_START', 'ADMIN_ORGS__INIT_SUCCESS', 'ADMIN_ORGS__INIT_FAIL'];

const ADMIN_ORG = [
    'ADMIN_ORG_ITEM__INIT_START',
    'ADMIN_ORG_ITEM__INIT_SUCCESS',
    'ADMIN_ORG_ITEM__INIT_FAIL',
];

const ADMIN_USERS = [
    'ADMIN_USERS__INIT_START',
    'ADMIN_USERS__INIT_SUCCESS',
    'ADMIN_USERS__INIT_FAIL',
    'ADMIN_USERS__DELETE_START',
    'ADMIN_USERS__DELETE_SUCCESS',
    'ADMIN_USERS__DELETE_FAIL',
];

const ADMIN_USERS_FORM = [
    'ADMIN_USERS_FORM__INIT_START',
    'ADMIN_USERS_FORM__INIT_SUCCESS',
    'ADMIN_USERS_FORM__INIT_FAIL',
    'ADMIN_USERS_FORM__FIELD_CHANGED',
    'ADMIN_USERS_FORM__USER_SUBMIT',
    'ADMIN_USERS_FORM__SUBMIT_START',
    'ADMIN_USERS_FORM__SUBMIT_SUCESS',
    'ADMIN_USERS_FORM__SUBMIT_FAIL',
    'ADMIN_USERS_FORM__TEARDOWN',
];

const ADMIN_ORG_ITEM_FORM = [
    'ADMIN_ORG_ITEM_FORM__INIT_START',
    'ADMIN_ORG_ITEM_FORM__INIT_SUCCESS',
    'ADMIN_ORG_ITEM_FORM__INIT_FAIL',
    'ADMIN_ORG_ITEM_FORM__FIELD_CHANGED',
    'ADMIN_ORG_ITEM_FORM__USER_SUBMIT',
    'ADMIN_ORG_ITEM_FORM__SUBMIT_START',
    'ADMIN_ORG_ITEM_FORM__SUBMIT_SUCESS',
    'ADMIN_ORG_ITEM_FORM__SUBMIT_FAIL',
    'ADMIN_ORG_ITEM_FORM__TEARDOWN',
];

const MODULES = [
    ...POIS_EDITOR,
    ...ADMIN_ORGS,
    ...ADMIN_ORG,
    ...ADMIN_USERS,
    ...ADMIN_USERS_FORM,
    ...ADMIN_ORG_ITEM_FORM,
];

const symbols = [
    ...MODULES,

    // System
    'LOGOUT',
    'LOGIN',

    'SYSTEM__NAVIGATE',
    'SYSTEM__GLOBAL_REFRESH',

    // Development settings
    'DEV_SETTINGS__HIPSTER_TARGETS__FETCH',
    'DEV_SETTINGS__HIPSTER_TARGETS__FETCH__FAILURE',
    'DEV_SETTINGS__HIPSTER_TARGETS__FETCH__SUCCESS',
    'DEV_SETTINGS__TOGGLE_ENDPOINT_MODE',
    'DEV_SETTINGS__TOGGLE_ENVIRONMENT',
    'DEV_SETTINGS__SET_API_BRANCH',

    'CAMPAIGN__ACCESS__INIT',
    'CAMPAIGN__ACCESS__INIT_COMPLETE',

    // ----- Resources [ START ] -----

    'SYSTEM__AUDITS__FETCH__SUCCESS',
    'SYSTEM__USERS__FETCH__SUCCESS',
    'SYSTEM__ORGANIZATIONS__FETCH__SUCCESS',
    'SYSTEM__ACTIVATION_TOKEN__FETCH__SUCCESS',

    'SYSTEM__MEDIA_PLANS__FETCH__SUCCESS',
    'SYSTEM__SCHEDULED_REPORT__FETCH__SUCCESS',
    'SYSTEM__AD_PRESET__FETCH__SUCCESS',

    'SYSTEM__CAMPAIGNS__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_FILES__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS',
    'SYSTEM__CAMPAIGN_BEACONS__FETCH__SUCCESS',

    // Ad Groupings Resource
    'SYSTEM__CAMPAIGN_ADS_GROUPINGS__FETCH',
    'SYSTEM__CAMPAIGN_ADS_GROUPINGS__FETCH__SUCCESS',

    // App Lists Resource
    'SYSTEM__APP_LISTS__FETCH',
    'SYSTEM__APP_LISTS__FETCH__SUCCESS',
    'SYSTEM__APP_LIST__FETCH',
    'SYSTEM__APP_LIST__FETCH__SUCCESS',

    // App Resource
    'SYSTEM__APPS__FETCH',
    'SYSTEM__APPS__FETCH__SUCCESS',

    'SYSTEM__CAMPAIGN_STATS__FETCH_ALL_START',
    'SYSTEM__CAMPAIGN_STATS__FETCH_ALL_STOP',
    'SYSTEM__CAMPAIGN_STATS_TOTAL__FETCH',
    'SYSTEM__CAMPAIGN_STATS_TOTAL__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_STATS_BY_HOUR__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_STATS_BY_DATE__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_STATS_LIVE_BY_HOUR__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_STATS_LIVE_BY_DATE__FETCH__SUCCESS',

    'SYSTEM__AD_STATS_TOTAL__SET_LOADING',
    'SYSTEM__AD_STATS_TOTAL__FETCH',
    'SYSTEM__AD_STATS_TOTAL__FETCH__SUCCESS',
    'SYSTEM__AD_STATS_TOTAL__FETCH_ALL',
    'SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS',
    'SYSTEM__AD_STATS_BY_HOUR__FETCH',
    'SYSTEM__AD_STATS_BY_HOUR__FETCH__SUCCESS',
    'SYSTEM__AD_STATS_BY_DATE__FETCH',
    'SYSTEM__AD_STATS_BY_DATE__FETCH__SUCCESS',
    'SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH',
    'SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH__SUCCESS',
    'SYSTEM__AD_STATS_LIVE_BY_DATE__FETCH',
    'SYSTEM__AD_STATS_LIVE_BY_DATE__FETCH__SUCCESS',

    'SYSTEM__AD_HISTORY__FETCH__SUCCESS',
    'SYSTEM__CAMPAIGN_HISTORY__FETCH__SUCCESS',
    'SYSTEM__CREATIVE_HISTORY__FETCH__SUCCESS',

    'SYSTEM__GEO_CATEGORIES__FETCH__SUCCESS',
    'SYSTEM__GEO_LAYERS__FETCH__SUCCESS',
    'SYSTEM__GEO_LAYERS__POST',
    'SYSTEM__GEO_LAYERS__POST__SUCCESS',
    'SYSTEM__GEO_LAYERS__POST_FAIL',
    'SYSTEM__GEO_LAYER_POINTS__FETCH__SUCCESS',
    // 'SYSTEM__ADD_GEO_POINT',
    // 'SYSTEM__REMOVE_GEO_POINT',

    'SYSTEM__GEO_LAYERS__UPDATE__SUCCESS',
    'SYSTEM__GEO_LAYERS__DELETE__SUCCESS',

    // Profile
    'PROFILE__USER__FETCH__SUCCESS',

    // Account
    'ACCOUNT__INITIALIZE',
    'ACCOUNT__INITIALIZE__SUCCESS',
    'ACCOUNT__INITIALIZE__FAIL',
    'ACCOUNT__SUMMARY__DATA_REFRESH',
    'ACCOUNT__SUMMARY__DATA_REFRESH__SUCCESS',
    'ACCOUNT__DICTIONARY__INITIALIZE',
    'ACCOUNT__PIVOT_TABLE__SORT',
    'ACCOUNT__PIVOT_TABLE__EXPAND_ROW',
    'ACCOUNT__PIVOT_TABLE__COLLAPSE_ROW',
    'ACCOUNT__PIVOT_TABLE__ADD_SPLIT',
    'ACCOUNT__PIVOT_TABLE__REMOVE_SPLIT',
    'ACCOUNT__PIVOT_TABLE__UPDATE_SPLITS',
    'ACCOUNT__PIVOT_TABLE__DATA_REFRESH',
    'ACCOUNT__PIVOT_TABLE__DATA_REFRESH__SUCCESS',
    'ACCOUNT__FILTER__DATE_RANGE_FILTER',
    'ACCOUNT__METRIC_SELECTOR__TOGGLE_VISIBILITY',
    'ACCOUNT__METRIC_SELECTOR__TOGGLE_SELECTION',
    'ACCOUNT__METRIC_SELECTOR__INIT',
    'ACCOUNT__METRIC_SELECTOR__DATA_REFRESH__SUCCESS',
    'ACCOUNT__SEARCH__BY_CAMPAIGN',
    'ACCOUNT__SEARCH__BY_CLIENT',
    'ACCOUNT__SEARCH__BY_STATUS',
    'ACCOUNT__LINK_QUERY__INIT',
    'ACCOUNT__GENERATE_TABLE',

    // ----- Resources [ END ] -----

    // Files
    'OVERVIEW_FILES__INIT',
    'OVERVIEW_FILES__FILE_LIST__FILTER',
    'OVERVIEW_FILES__FILE_UPLOAD__SUBMIT',
    'OVERVIEW_FILES__FILE_UPLOAD__SUBMIT_SUCCESS',
    'OVERVIEW_FILES__FILE_UPLOAD__SUBMIT_FAIL',
    'OVERVIEW_FILES__FILE_UPLOAD__SUBMIT__PROGRESS',
    'OVERVIEW_FILES__FILE_UPLOAD__SUBMIT__PROGRESS_COMPLETE',
    'OVERVIEW_FILES__FILE_UPLOAD__CLEAR_TEMP',

    'OVERVIEW_FILES__FILE_EDIT',
    'OVERVIEW_FILES__FILE_EDIT__FAIL',
    'OVERVIEW_FILES__FILE_EDIT__SUCCESS',
    'OVERVIEW_FILES__FILE_DELETE',
    'OVERVIEW_FILES__FILE_DELETE__FAIL',
    'OVERVIEW_FILES__FILE_DELETE__SUCCESS',

    // Campaign Resource Control
    'OVERVIEW__CAMPAIGN__INIT',
    'OVERVIEW__CAMPAIGN__INIT_SUCCESS',
    'OVERVIEW__CAMPAIGN__PAUSE',
    'OVERVIEW__CAMPAIGN__PAUSE_SUCCESS',
    'OVERVIEW__CAMPAIGN__PAUSE_FAIL',
    'OVERVIEW__CAMPAIGN__UNPAUSE',
    'OVERVIEW__CAMPAIGN__UNPAUSE_SUCCESS',
    'OVERVIEW__CAMPAIGN__UNPAUSE_FAIL',
    'OVERVIEW_CAMPAIGN__DUPLICATE__SUCCESS',
    'OVERVIEW_CAMPAIGN__DUPLICATE__FAIL',

    // Ad Resource Control
    'OVERVIEW_ADS__AD__PAUSE',
    'OVERVIEW_ADS__AD__PAUSE_SUCCESS',
    'OVERVIEW_ADS__AD__PAUSE_FAIL',
    'OVERVIEW_ADS__AD__UNPAUSE',
    'OVERVIEW_ADS__AD__UNPAUSE_SUCCESS',
    'OVERVIEW_ADS__AD__UNPAUSE_FAIL',
    'OVERVIEW_ADS__INIT',
    'OVERVIEW_ADS__FETCH',
    'OVERVIEW_ADS__FETCH__SUCCESS',
    'OVERVIEW_ADS__FETCH__FAIL',

    // Sidebar
    'SIDEBAR__CAMPAIGN_TAB__ADD',
    'SIDEBAR__CAMPAIGN_TAB__REMOVE',
    'SIDEBAR__CAMPAIGN_TAB__REMOVE_All',
    'SIDEBAR__CAMPAIGN_TAB__PIN_TOGGLED',
    'SIDEBAR__USERS__FETCH__SUCCESS',
    'SIDEBAR__MEDIA_PLANS__ADD_TAB',
    'SIDEBAR__SLIDE_TOGGLE',

    // Campaign Dashboard
    'CAMPAIGN_DASHBOARD__FETCH',
    'CAMPAIGN_DASHBOARD__FETCH__SUCCESS',
    'CAMPAIGN_DASHBOARD__FETCH__ADGROUPINGS',
    'CAMPAIGN_DASHBOARD__FETCH__ADGROUPINGS__SUCCESS',
    'CAMPAIGN_DASHBOARD__CAMPAIGNS_STATS__FETCH__SUCCESS',
    'CAMPAIGN_DASHBOARD__SEARCH',
    'CAMPAIGN_DASHBOARD__ADD_FILTERS',
    'CAMPAIGN_DASHBOARD__AD_GROUPING_EXPAND',
    'CAMPAIGN_DASHBOARD__AD_GROUPING_COLLAPSE',

    // Campaign History
    'CAMPAIGN_DASHBOARD__HISTORY__OPEN',
    'CAMPAIGN_DASHBOARD__HISTORY__CLOSE',
    'CAMPAIGN_DASHBOARD__HISTORY__FETCH',
    'CAMPAIGN_DASHBOARD__HISTORY__FETCH__SUCCESS',
    'CAMPAIGN_DASHBOARD__HISTORY__FETCH__FAIL',

    // Campaign Progress
    'CAMPAIGN_PROGRESS__CAMPAIGNS__FETCH__SUCCESS',
    'CAMPAIGN_PROGRESS__CAMPAIGNS__FETCH__FAIL',

    // Campaign Delivery
    'CAMPAIGN_DELIVERY__LOADING',
    'CAMPAIGN_DELIVERY__INIT',

    // AD DELIVERY
    'AD_DELIVERY__LOADING',

    // Ad Preset New
    'NEW_AD_PRESET__DRAFT__OPEN',
    'NEW_AD_PRESET__DRAFT__INIT',
    'NEW_AD_PRESET__DRAFT__CLOSE',

    'NEW_AD_PRESET__DRAFT__UPDATE',
    'NEW_AD_PRESET__DRAFT__SHOW_ERRORS',
    'NEW_AD_PRESET__DRAFT__SUBMIT',
    'NEW_AD_PRESET__DRAFT__SUBMIT_SUCCESS',
    'NEW_AD_PRESET__DRAFT__SUBMIT_FAIL',

    // Ad Preset Edit
    'EDIT_AD_PRESET__DRAFT__OPEN',
    'EDIT_AD_PRESET__DRAFT__INIT',
    'EDIT_AD_PRESET__DRAFT__CLOSE',

    'EDIT_AD_PRESET__DRAFT__UPDATE',
    'EDIT_AD_PRESET__DRAFT__SHOW_ERRORS',
    'EDIT_AD_PRESET__DRAFT__FETCH_FAIL',
    'EDIT_AD_PRESET__DRAFT__SUBMIT',
    'EDIT_AD_PRESET__DRAFT__SUBMIT_SUCCESS',
    'EDIT_AD_PRESET__DRAFT__SUBMIT_FAIL',

    // Campaign Form
    'CAMPAIGN_FORM__OPEN',
    'CAMPAIGN_FORM__INIT',
    'CAMPAIGN_FORM__INIT_FAIL',
    'CAMPAIGN_FORM__CLOSE',

    'CAMPAIGN_FORM__UPDATE',
    'CAMPAIGN_FORM__SHOW_ERRORS',
    'CAMPAIGN_FORM__SUBMIT',
    'CAMPAIGN_FORM__SUBMIT_SUCCESS',
    'CAMPAIGN_FORM__SUBMIT_FAIL',
    'CAMPAIGN_FORM__CHANGE_BUDGET_OPTION',

    // Campaign New
    'NEW_CAMPAIGN__DRAFT__OPEN',
    'NEW_CAMPAIGN__DRAFT__INIT',
    'NEW_CAMPAIGN__DRAFT__CLOSE',

    'NEW_CAMPAIGN__DRAFT__UPDATE',
    'NEW_CAMPAIGN__DRAFT__SHOW_ERRORS',
    'NEW_CAMPAIGN__DRAFT__SUBMIT',
    'NEW_CAMPAIGN__DRAFT__SUBMIT_SUCCESS',
    'NEW_CAMPAIGN__DRAFT__SUBMIT_FAIL',
    'NEW_CAMPAIGN__DRAFT__INIT_FAIL',
    'NEW_CAMPAIGN__DRAFT__FETCH_ORGANIZATIONS_SUCCESS',

    // Client Organization New
    'NEW_CLIENT__DRAFT__OPEN',
    'NEW_CLIENT__DRAFT__INIT',
    'NEW_CLIENT__DRAFT__CLOSE',

    'NEW_CLIENT__DRAFT__UPDATE',
    'NEW_CLIENT__DRAFT__SHOW_ERRORS',
    'NEW_CLIENT__DRAFT__SUBMIT',
    'NEW_CLIENT__DRAFT__SUBMIT_SUCCESS',
    'NEW_CLIENT__DRAFT__SUBMIT_FAIL',

    // Client Organization New
    'CAMPAIGN_SHARING_CLIENT__DRAFT__OPEN',
    'CAMPAIGN_SHARING_CLIENT__DRAFT__INIT',
    'CAMPAIGN_SHARING_CLIENT__DRAFT__CLOSE',

    'CAMPAIGN_SHARING_CLIENT__DRAFT__UPDATE',
    'CAMPAIGN_SHARING_CLIENT__DRAFT__SHOW_ERRORS',
    'CAMPAIGN_SHARING_CLIENT__DRAFT__SUBMIT',
    'CAMPAIGN_SHARING_CLIENT__DRAFT__SUBMIT_SUCCESS',
    'CAMPAIGN_SHARING_CLIENT__DRAFT__SUBMIT_FAIL',

    // Campaign Edit
    'OVERVIEW__CAMPAIGN_DRAFT__OPEN',
    'OVERVIEW__CAMPAIGN_DRAFT__INIT',
    'OVERVIEW__CAMPAIGN_DRAFT__INIT_FAIL',
    'OVERVIEW__CAMPAIGN_DRAFT__CLOSE',
    'OVERVIEW__CAMPAIGN_DRAFT__FETCH_ORGANIZATIONS_SUCCESS',
    'OVERVIEW__CAMPAIGN_DRAFT__FETCH_ORGANIZATIONS_FAIL',

    'OVERVIEW__CAMPAIGN_DRAFT__UPDATE',
    'OVERVIEW__CAMPAIGN_DRAFT__SHOW_ERRORS',
    'OVERVIEW__CAMPAIGN_DRAFT__SUBMIT',
    'OVERVIEW__CAMPAIGN_DRAFT__SUBMIT_SUCCESS',
    'OVERVIEW__CAMPAIGN_DRAFT__SUBMIT_FAIL',

    // Ad Form
    'AD_FORM__INIT_STATE',
    'AD_FORM__OPEN',
    'AD_FORM__INIT',
    'AD_FORM__INIT_FAIL',
    'AD_FORM__CLOSE',

    'AD_FORM__SHOW_GEO_ENTITY_IN_MAP',
    'AD_FORM__REFRESH_GEO_ENTITY_IN_MAP',
    'AD_FORM__GEOLAYER_SEARCH_STRING_CHANGED',
    'AD_FORM__GEOLAYER_FINDER__REFRESH',
    'AD_FORM__GEOLAYER_FINDER__FOLDER_STATE_CHANGED',

    'AD_FORM__UPDATE',
    'AD_FORM__SHOW_ERRORS',
    'AD_FORM__SUBMIT',
    'AD_FORM__SUBMIT_SUCCESS',
    'AD_FORM__SUBMIT_FAIL',

    'AD_FORM_OPEN_AD_PRESET_MODAL',
    'AD_FORM_CLOSE_AD_PRESET_MODAL',
    'AD_FORM_SET_AD_PRESET',

    'AD_FORM__INIT_GEO_POINTS',
    'AD_FORM__SET_START_ASAP',

    'AD_FORM__ADD_GEO_POINT',
    'AD_FORM__REMOVE_GEO_POINT',

    'AD_FORM__CREATIVES__SWITCH_MODE',
    'AD_FORM__CREATIVES__SELECT_SINGLE_CREATIVE',
    'AD_FORM__CREATIVES__SELECT_WEIGHTED_CREATIVE',
    'AD_FORM__CREATIVES__SELECT_EVEN_CREATIVE',
    'AD_FORM__CREATIVES__CLEAR_CREATIVES',

    'AD_FORM__CREATIVES__CHANGE_WEIGHT',

    'AD_FORM__CREATIVES__ADD_CREATIVE_SCHEDULE_ROW',
    'AD_FORM__CREATIVES__UPDATE_CREATIVE_SCHEDULE_ROW',
    'AD_FORM__CREATIVES__REMOVE_CREATIVE_SCHEDULE_ROW',

    'AD_FORM__GENERATE_PREDICTION',
    'AD_FORM__GENERATE_PREDICTION__FAILED',
    'AD_FORM__GENERATE_PREDICTION__COMPLETE',

    // Ad Overview Form New
    'OVERVIEW_ADS__AD_DRAFT_NEW__INIT_STATE',
    'OVERVIEW_ADS__AD_DRAFT_NEW__OPEN',
    'OVERVIEW_ADS__AD_DRAFT_NEW__INIT',
    'OVERVIEW_ADS__AD_DRAFT_NEW__INIT_FAIL',
    'OVERVIEW_ADS__AD_DRAFT_NEW__CLOSE',

    'OVERVIEW_ADS__AD_DRAFT_NEW__SHOW_GEO_ENTITY_IN_MAP',
    'OVERVIEW_ADS__AD_DRAFT_NEW__REFRESH_GEO_ENTITY_IN_MAP',
    'OVERVIEW_ADS__AD_DRAFT_NEW__GEOLAYER_SEARCH_STRING_CHANGED',
    'OVERVIEW_ADS__AD_DRAFT_NEW__GEOLAYER_FINDER__REFRESH',
    'OVERVIEW_ADS__AD_DRAFT_NEW__GEOLAYER_FINDER__FOLDER_STATE_CHANGED',

    'OVERVIEW_ADS__AD_DRAFT_NEW__UPDATE',
    'OVERVIEW_ADS__AD_DRAFT_NEW__SHOW_ERRORS',
    'OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT',
    'OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT_SUCCESS',
    'OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT_FAIL',

    'OVERVIEW_ADS__AD_DRAFT_NEW_OPEN_AD_PRESET_MODAL',
    'OVERVIEW_ADS__AD_DRAFT_NEW_CLOSE_AD_PRESET_MODAL',
    'OVERVIEW_ADS__AD_DRAFT_NEW_SET_AD_PRESET',

    'OVERVIEW_ADS__AD_DRAFT_NEW__INIT_GEO_POINTS',
    'OVERVIEW_ADS__AD_DRAFT_NEW__SET_START_ASAP',

    'OVERVIEW_ADS__AD_DRAFT_NEW__ADD_GEO_POINT',
    'OVERVIEW_ADS__AD_DRAFT_NEW__REMOVE_GEO_POINT',

    // Ad Overview Form Edit
    'OVERVIEW_ADS__AD_DRAFT_EDIT__OPEN',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__INIT_STATE',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__INIT',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__INIT_FAIL',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__CLOSE',

    'OVERVIEW_ADS__AD_DRAFT_EDIT__INIT_GEO_POINTS',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__SHOW_GEO_ENTITY_IN_MAP',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__REFRESH_GEO_ENTITY_IN_MAP',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__GEOLAYER_SEARCH_STRING_CHANGED',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__GEOLAYER_FINDER__REFRESH',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__GEOLAYER_FINDER__FOLDER_STATE_CHANGED',

    'OVERVIEW_ADS__AD_DRAFT_EDIT__UPDATE',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__SHOW_ERRORS',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__SUBMIT',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__SUBMIT_SUCCESS',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__SUBMIT_FAIL',

    'OVERVIEW_ADS__AD_DRAFT_EDIT_OPEN_AD_PRESET_MODAL',
    'OVERVIEW_ADS__AD_DRAFT_EDIT_CLOSE_AD_PRESET_MODAL',
    'OVERVIEW_ADS__AD_DRAFT_EDIT_SET_AD_PRESET',

    'OVERVIEW_ADS__AD_DRAFT_EDIT__ADD_GEO_POINT',
    'OVERVIEW_ADS__AD_DRAFT_EDIT__REMOVE_GEO_POINT',

    // Ad Groupings Overview Form
    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE_CATELOG__FETCH',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE_CATELOG__FETCH__SUCCESS',

    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE__FETCH',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE__FETCH__SUCCESS',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE_SELECTION_CHANGED',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE_SELECTION_CHANGED_FAILED',

    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_GENERATE',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_GENERATE_APPLIED',

    'OVERVIEW_ADS_GROUPINGS__DRAFT__AD_IN_GROUP_SELECTION__TOGGLED',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__MOVE_ADS',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__ADD_GROUP',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__DELETE_GROUP',

    'OVERVIEW_ADS_GROUPINGS__INIT',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__OPEN',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__INIT',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__INIT_FAIL',

    'OVERVIEW_ADS_GROUPINGS__DRAFT__CLOSE',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__UPDATE',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__SUBMIT',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__SHOW_ERROR',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__SUBMIT_SUCCESS',
    'OVERVIEW_ADS_GROUPINGS__DRAFT__SUBMIT_FAIL',

    // App List Form
    'OVERVIEW_APP_LISTS__DRAFT__OPEN',
    'OVERVIEW_APP_LISTS__DRAFT__INIT',
    'OVERVIEW_APP_LISTS__DRAFT__INIT_FAIL',

    // ----- Overview List [ START ] -----

    // Adgrouping Overview List
    'OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_TOGGLE_VISIBILITY',
    'OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_TOGGLE_VISIBILITY__SUCCESS',
    'OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_TOGGLE_VISIBILITY__ERROR',
    'OVERVIEW_ADS_GROUPINGS__LIST__AD_TOGGLE_VISIBILITY',
    'OVERVIEW_ADS_GROUPINGS__LIST__AD_TOGGLE_VISIBILITY__SUCCESS',
    'OVERVIEW_ADS_GROUPINGS__LIST__AD_TOGGLE_VISIBILITY__ERROR',

    // Ad Overview List
    'OVERVIEW_ADS__AD_LIST__FILTER',
    'OVERVIEW_ADS__HISTORY__OPEN',
    'OVERVIEW_ADS__HISTORY__CLOSE',

    // Creative Overview List
    'OVERVIEW_CREATIVES__CREATIVE_LIST__FILTER',
    'OVERVIEW_CREATIVES__HISTORY__OPEN',
    'OVERVIEW_CREATIVES__HISTORY__CLOSE',

    // Beacons Overview List
    'OVERVIEW_BEACONS__INIT',
    'OVERVIEW_BEACONS__INIT_DRAFT',
    'OVERVIEW_BEACONS__OPEN_VIEW_NEW',
    'OVERVIEW_BEACONS__OPEN_VIEW_MANAGE',
    'OVERVIEW_BEACONS__ADD',
    'OVERVIEW_BEACONS__EDIT',
    'OVERVIEW_BEACONS__DELETE',
    'OVERVIEW_BEACONS__CANCEL',
    'OVERVIEW_BEACONS__SAVE',
    'OVERVIEW_BEACONS__SAVE_TRY',
    'OVERVIEW_BEACONS__SAVE_SUCCESS',
    'OVERVIEW_BEACONS__SAVE_FAIL',
    'OVERVIEW_BEACONS__PROMOTE_BEACON',
    'OVERVIEW_BEACONS__DEMOTE_BEACON',

    // Ad Groupings Overview List
    'OVERVIEW_ADS_GROUPINGS__LIST__FILTER',
    'OVERVIEW_ADS_GROUPINGS__LIST__OPEN',
    'OVERVIEW_ADS_GROUPINGS__LIST__CLOSE',
    'OVERVIEW_ADS_GROUPINGS__LIST__FETCH',
    'OVERVIEW_ADS_GROUPINGS__LIST__INIT',
    'OVERVIEW_ADS_GROUPINGS__LIST__INIT_FAIL',
    'OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_CHANGED',
    'OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_DELETE',
    'OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_DELETE__SUCCESS',
    'OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_DELETE__FAIL',

    // Overview Applists
    'OVERVIEW_APP_LISTS__WORKSPACE__INIT',
    'OVERVIEW_APP_LISTS__WORKSPACE__OPEN',

    'OVERVIEW_APP_LISTS__INIT',
    'OVERVIEW_APP_LISTS__INIT_FAIL',

    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__OPEN',
    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__CLOSE',

    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__DRAFT_INIT',
    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__DRAFT_INIT_FAIL',

    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__DRAFT_SUBMIT',
    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__DRAFT_SUBMIT_SUCCESS',
    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__DRAFT_SUBMIT_FAIL',
    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__DRAFT_ADD_NEW_APP_LIST',

    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__ADD_ITEM',
    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__REMOVE_ITEM',
    'OVERVIEW_APP_LISTS__MANAGE_ASSIGNMENT__CHANGE_LIST_MODE',

    // Overview Applist Editor
    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__NEW',
    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__EDIT',

    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__REMOVE',
    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__REMOVE_SUCCESS',
    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__REMOVE_FAIL',

    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__TEARDOWN',
    'OVERVIEW_APP_LISTS__EDITOR__CANCEL',
    'OVERVIEW_APP_LISTS__EDITOR__SHOW_ERRORS',

    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__EDIT__INIT',
    'OVERVIEW_APP_LISTS__EDITOR__APPLIST__EDIT__INIT_FAIL',

    'OVERVIEW_APP_LISTS__EDITOR__APPLIST_DRAFT__UPDATE',

    'OVERVIEW_APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC',
    'OVERVIEW_APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_BLOCKED',
    'OVERVIEW_APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_SUCCESS',
    'OVERVIEW_APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_FAIL',

    'OVERVIEW_APP_LISTS__EDITOR__BUNDLE_ID__REMOVE',

    'OVERVIEW_APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT',
    'OVERVIEW_APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT_FAIL',
    'OVERVIEW_APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT_SUCCESS',

    'OVERVIEW_APP_LISTS__APPLIST_VIEW__OPEN',
    'OVERVIEW_APP_LISTS__APPLIST_VIEW__INIT_SUCCESS',
    'OVERVIEW_APP_LISTS__APPLIST_VIEW__INIT_FAIL',
    'OVERVIEW_APP_LISTS__APPLIST_VIEW__CANCEL',

    // ----- Overview List [ END ] -----

    // Creative Audit
    'OVERVIEW__CREATIVE_AUDIT__UPDATE',
    'OVERVIEW__CREATIVE_AUDIT__UPDATE_SUCCESS',
    'OVERVIEW__CREATIVE_AUDIT__UPDATE_FAIL',

    // Creative Form
    'CREATIVE_FORM__OPEN',
    'CREATIVE_FORM__INIT_STATE',
    'CREATIVE_FORM__INIT',
    'CREATIVE_FORM__INIT_FAIL',
    'CREATIVE_FORM__CLOSE',

    'CREATIVE_FORM__UPDATE',
    'CREATIVE_FORM__SHOW_ERRORS',
    'CREATIVE_FORM__SUBMIT',
    'CREATIVE_FORM__SUBMIT_SUCCESS',
    'CREATIVE_FORM__SUBMIT_AND_AUDIT',
    'CREATIVE_FORM__SUBMIT_FAIL',
    'CREATIVE_FORM__SUBMIT_CANCEL',

    // Creative Form Edit
    'OVERVIEW__CREATIVE_DRAFT_EDIT__OPEN',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__INIT_STATE',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__INIT',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__INIT_FAIL',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__CLOSE',

    'OVERVIEW__CREATIVE_DRAFT_EDIT__UPDATE',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__SHOW_ERRORS',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_SUCCESS',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_AND_AUDIT',
    'OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_FAIL',

    // Creative Form New
    'OVERVIEW__CREATIVE_DRAFT_NEW__OPEN',
    'OVERVIEW__CREATIVE_DRAFT_NEW__INIT_STATE',
    'OVERVIEW__CREATIVE_DRAFT_NEW__INIT',
    'OVERVIEW__CREATIVE_DRAFT_NEW__CLOSE',

    'OVERVIEW__CREATIVE_DRAFT_NEW__UPDATE',
    'OVERVIEW__CREATIVE_DRAFT_NEW__SHOW_ERRORS',
    'OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT',
    'OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_SUCCESS',
    'OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_AND_AUDIT',
    'OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_FAIL',

    // Creative list
    'OVERVIEW_CREATIVES__INIT',
    'OVERVIEW_CREATIVES__CREATIVE_EXPAND',
    'OVERVIEW_CREATIVES__CREATIVE_COLLAPSE',
    'OVERVIEW_CREATIVES__CREATIVE_EXPAND_ALL',
    'OVERVIEW_CREATIVES__CREATIVE_COLLAPSE_ALL',

    'OVERVIEW_CREATIVES__CREATIVE_DELETE',
    'OVERVIEW_CREATIVES__CREATIVE_DELETE__FAIL',
    'OVERVIEW_CREATIVES__CREATIVE_DELETE__SUCCESS',

    // Ad list
    'OVERVIEW_ADS__AD_DELETE',
    'OVERVIEW_ADS__AD_DELETE__FAIL',
    'OVERVIEW_ADS__AD_DELETE__SUCCESS',

    // NOTE:  - Alex
    'OVERVIEW_CREATIVES__CREATIVE_DUPLICATE',
    // 'OVERVIEW_CREATIVES__CREATIVE_DUPLICATE__SUCCESS',
    // 'OVERVIEW_ADS__AD_DUPLICATE',
    // 'OVERVIEW_ADS__AD_DUPLICATE__SUCCESS',

    // Campaign Report
    'CAMPAIGN_REPORT__INIT_STATE',
    'CAMPAIGN_REPORT__INITIALIZE',
    'CAMPAIGN_REPORT__REFRESH',

    'CAMPAIGN_REPORT__CONTROLS__SET_UNIT_TYPE',
    'CAMPAIGN_REPORT__CONTROLS__SET_METRIC_TYPE',
    'CAMPAIGN_REPORT__CONTROLS__SET_TAB',
    'CAMPAIGN_REPORT__CONTROLS__SET_ACTIVE_SCOPE_TAB',
    'CAMPAIGN_REPORT__CONTROLS__SET_METRIC_VISIBILITY',

    'CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_VISIBILITY',
    'CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_SELECTION',
    'CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE',

    'CAMPAIGN_REPORT__ADGROUP__TOGGLE',
    'CAMPAIGN_REPORT__ADGROUP__RESET',
    'CAMPAIGN_REPORT__ADGROUP_STATS__FETCH__SUCCESS',
    'CAMPAIGN_REPORT__ADGROUP_STATS__FETCH',
    'CAMPAIGN_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS',

    'CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE_START',
    'CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE',
    'CAMPAIGN_REPORT__PIVOT_TABLE__SORT',
    'CAMPAIGN_REPORT__PIVOT_TABLE__EXPAND_ROW',
    'CAMPAIGN_REPORT__PIVOT_TABLE__COLLAPSE_ROW',
    'CAMPAIGN_REPORT__PIVOT_TABLE__ADD_SPLIT',
    'CAMPAIGN_REPORT__PIVOT_TABLE__REMOVE_SPLIT',
    'CAMPAIGN_REPORT__PIVOT_TABLE__UPDATE_SPLITS',
    'CAMPAIGN_REPORT__PIVOT_TABLE__HIDE_ZEROS_TOGGLE',
    'CAMPAIGN_REPORT__PIVOT_TABLE__DATA_REFRESH',

    'CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR',
    'CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR_ALL',
    'CAMPAIGN_REPORT__SCOPE_FILTER__CLEAR',
    'CAMPAIGN_REPORT__SCOPE_FILTER__CLEAR_ALL',

    'CAMPAIGN_REPORT__FILTER__DATE_RANGE_FILTER',
    'CAMPAIGN_REPORT__FILTER__DATE_RANGE_TIMEZONE',

    'CAMPAIGN_REPORT__EXPORT_EXCEL_SUCCESS',
    'CAMPAIGN_REPORT__AVAILABLE_CREATIVES__FETCH_SUCCESS',

    // Report Channel
    'REPORT_CHANNEL__INIT_STATE',
    'REPORT_CHANNEL__INITIALIZE',
    'REPORT_CHANNEL__REFRESH',

    'REPORT_CHANNEL__ADGROUP__TOGGLE',
    'REPORT_CHANNEL__ADGROUP__RESET',
    'REPORT_CHANNEL__ADGROUP_STATS__FETCH__SUCCESS',
    'REPORT_CHANNEL__ADGROUP_STATS__FETCH',
    'REPORT_CHANNEL__AVAILABLE_ADGROUPS__FETCH_SUCCESS',

    'REPORT_CHANNEL__SCOPE_FILTER__CLEAR',
    'REPORT_CHANNEL__SCOPE_FILTER__CLEAR_ALL',

    'REPORT_CHANNEL__FILTER__DATE_RANGE_FILTER',
    'REPORT_CHANNEL__FILTER__DATE_RANGE_TIMEZONE',

    // Report Channel
    'REPORT_GEO__INIT_STATE',
    'REPORT_GEO__DICTIONARY__INITIALIZE',
    'REPORT_GEO__INITIALIZE',
    'REPORT_GEO__REFRESH',

    'REPORT_GEO__METRIC_SELECTOR__TOGGLE_VISIBILITY',
    'REPORT_GEO__METRIC_SELECTOR__TOGGLE_SELECTION',
    'REPORT_GEO__METRIC_SELECTOR__TOGGLE',

    'REPORT_GEO__ADGROUP__TOGGLE',
    'REPORT_GEO__ADGROUP__RESET',
    'REPORT_GEO__ADGROUP_STATS__FETCH__SUCCESS',
    'REPORT_GEO__ADGROUP_STATS__FETCH',
    'REPORT_GEO__AVAILABLE_ADGROUPS__FETCH_SUCCESS',

    'REPORT_GEO__SCOPE_FILTER__CLEAR',
    'REPORT_GEO__SCOPE_FILTER__CLEAR_ALL',

    'REPORT_GEO__FILTER__DATE_RANGE_FILTER',
    'REPORT_GEO__FILTER__DATE_RANGE_TIMEZONE',

    // Metric Selector

    'METRIC_SELECTOR__INITIALIZE_START',
    'METRIC_SELECTOR__INITIALIZE',
    'METRIC_SELECTOR__TOGGLE_VISIBILITY',
    'METRIC_SELECTOR__TOGGLE_SELECTION',
    'METRIC_SELECTOR__DATA_REFRESH',

    // Activation
    'ACTIVATION__DRAFT__OPEN',
    'ACTIVATION__DRAFT__FETCH__SUCCESS',
    'ACTIVATION__DRAFT__FETCH__FAIL',
    'ACTIVATION__DRAFT__INIT',
    'ACTIVATION__DRAFT__CLOSE',

    'ACTIVATION__DRAFT__UPDATE',
    'ACTIVATION__DRAFT__SHOW_ERRORS',
    'ACTIVATION__DRAFT__SUBMIT',
    'ACTIVATION__DRAFT__SUBMIT_SUCCESS',
    'ACTIVATION__DRAFT__SUBMIT_FAIL',

    // Media Plan Dashboard
    'MEDIA_PLANS_DASHBOARD__MEDIA_PLANS__FETCH__SUCCESS',
    'MEDIA_PLANS_DASHBOARD__ADD_FILTERS',
    'MEDIA_PLANS_DASHBOARD__TAB_ADD',
    'MEDIA_PLANS_DASHBOARD__TAB_REMOVE',

    // Media Plans Form
    'MEDIA_PLANS__FORM__CLOSE',
    'MEDIA_PLANS__FORM__INIT_STATE',
    'MEDIA_PLANS__FORM__INIT',
    'MEDIA_PLANS__FORM__SUBMIT',
    'MEDIA_PLANS__FORM__SUBMIT_SUCCESS',
    'MEDIA_PLANS__FORM__SUBMIT_FAIL',
    'MEDIA_PLANS__FORM__CANCEL',
    'MEDIA_PLANS__FORM__SHOW_ERRORS',
    'MEDIA_PLANS__FORM__TARGETING_COLUMN_ADD',
    'MEDIA_PLANS__FORM__TARGETING_COLUMN_REMOVE',
    'MEDIA_PLANS__FORM__UPDATE_DRAFT',
    'MEDIA_PLANS__FORM__UPDATE_DRAFT_MEDIA_PLAN',
    'MEDIA_PLANS__FORM__UPDATE_DRAFT_LINE_ITEM',
    'MEDIA_PLANS__FORM__LINE_ITEM_ADD',
    'MEDIA_PLANS__FORM__LINE_ITEM_SPLIT',
    'MEDIA_PLANS__FORM__LINE_ITEM_DELETE',
    'MEDIA_PLANS__FORM__LINE_ITEM_EXPAND_OR_COLLAPSE',
    'MEDIA_PLANS__FORM__PREDICTION__FETCH__SUCCESS',
    'MEDIA_PLANS__FORM__RESET',

    'REPORT_CHANNEL__PIVOT_TABLE__INITIALIZE_START',
    'REPORT_CHANNEL__PIVOT_TABLE__INITIALIZE',
    'REPORT_CHANNEL__PIVOT_TABLE__EXPAND_ROW',
    'REPORT_CHANNEL__PIVOT_TABLE__COLLAPSE_ROW',
    'REPORT_CHANNEL__PIVOT_TABLE__ADD_SPLIT',
    'REPORT_CHANNEL__PIVOT_TABLE__REMOVE_SPLIT',
    'REPORT_CHANNEL__PIVOT_TABLE__UPDATE_SPLITS',
    'REPORT_CHANNEL__PIVOT_TABLE__SORT',
    'REPORT_CHANNEL__PIVOT_TABLE__DATA_REFRESH',
    'REPORT_CHANNEL__PIVOT_TABLE__LIMIT_CHANNELS',

    'REPORT_GEO__PIVOT_TABLE__INITIALIZE_START',
    'REPORT_GEO__PIVOT_TABLE__INITIALIZE',
    'REPORT_GEO__PIVOT_TABLE__EXPAND_ROW',
    'REPORT_GEO__PIVOT_TABLE__COLLAPSE_ROW',
    'REPORT_GEO__PIVOT_TABLE__ADD_SPLIT',
    'REPORT_GEO__PIVOT_TABLE__REMOVE_SPLIT',
    'REPORT_GEO__PIVOT_TABLE__UPDATE_SPLITS',
    'REPORT_GEO__PIVOT_TABLE__SORT',
    'REPORT_GEO__PIVOT_TABLE__HIDE_ZEROS_TOGGLE',
    'REPORT_GEO__PIVOT_TABLE__DATA_REFRESH',

    'REPORT_EXPLORE__SCHEDULED_REPORT__OPEN',
    'REPORT_EXPLORE__SCHEDULED_REPORT__CLOSE',
    'REPORT_EXPLORE__SCHEDULED_REPORT__INIT',
    'REPORT_EXPLORE__SCHEDULED_REPORT__UPDATE_DRAFT',
    'REPORT_EXPLORE__SCHEDULED_REPORT__SHOW_ERRORS',
    'REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_SUCCESS',
    'REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_USERS_FAIL',
    'REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_FAIL',
    'REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_DRAFT',
    'REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_DRAFT_SUCCESS',
    'REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_DRAFT_FAIL',
    'REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_PATCH_DRAFT',
    'REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_PATCH_DRAFT_SUCCESS',
    'REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_PATCH_DRAFT_FAIL',
    'REPORT_EXPLORE__SCHEDULED_REPORT__PREVIEW_FILE_SUCCESS',
    'REPORT_EXPLORE__SCHEDULED_REPORT__PREVIEW_DOWNLOAD_SUCCESS',
    'REPORT_EXPLORE__SCHEDULED_REPORT__TOGGLE_ZERO_ROWS',
    'REPORT_EXPLORE__PIVOT_TABLE__INITIALIZE',
    'REPORT_EXPLORE__PIVOT_TABLE__SPLIT_CHANGE',

    'CAMPAIGN_SHARING__INIT',
    'CAMPAIGN_SHARING__INIT_SUCCESS',
    'CAMPAIGN_SHARING__DESTROY',
    'CAMPAIGN_SHARING__OPEN',
    'CAMPAIGN_SHARING__CLOSE',
    'CAMPAIGN_SHARING__SHARE',
    'CAMPAIGN_SHARING__TOGGLE_EMAIL',
    'CAMPAIGN_SHARING__TOGGLE_SHARE',
    'CAMPAIGN_SHARING__TOGGLE_SHARE__SUCCESS',
    'CAMPAIGN_SHARING__TOGGLE_SHARE__FAIL',
    'CAMPAIGN_SHARING__UPDATE_METRICS',
    'CAMPAIGN_SHARING__UPDATE_DIMENSIONS',
    'CAMPAIGN_SHARING__UPDATE_CLIENTS',
    'CAMPAIGN_SHARING__UPDATE_REPORT_TAB',
    'CAMPAIGN_SHARING__UPDATE_CLIENTS__SUCCESS',
    'CAMPAIGN_SHARING__UPDATE_CLIENTS__FAIL',
    'CAMPAIGN_SHARING__EMAILS_SELECT_ALL',
    'CAMPAIGN_SHARING__EMAILS_SELECT_NONE',
    'CAMPAIGN_SHARING__EXPAND_COLLAPSE_TOGGLE',
    'CAMPAIGN_SHARING__SEND',
    'CAMPAIGN_SHARING__SEND__SUCCESS',
    'CAMPAIGN_SHARING__SEND__FAIL',

    // Profile
    'PROFILE_AD_PRESETS_FETCH',
    'PROFILE_AD_PRESETS_FETCH_FAIL',

    'IMPERSONATION_MODAL__INIT',
    'IMPERSONATION_MODAL__INIT_SUCCESS',
    'IMPERSONATION_MODAL__INIT_FAILURE',
    'IMPERSONATION_MODAL__SET_ORG',
    'IMPERSONATION_MODAL__OPEN',
    'IMPERSONATION_MODAL__CLOSE',
    'IMPERSONATION_MODAL__IMPERSONATION_START',
    'IMPERSONATION_MODAL__IMPERSONATION_SUCCESS',
    'IMPERSONATION_MODAL__UNIMPERSONATE',
    'IMPERSONATION_MODAL__REFRESH_USER',
    'IMPERSONATION_MODAL__REFRESH_USER_SUCCESS',
    'IMPERSONATION_MODAL__REFRESH_USER_FAILURE',
    'IMPERSONATION_MODAL__REFRESH_ORGANIZATION',
    'IMPERSONATION_MODAL__REFRESH_ORGANIZATION_SUCCESS',
    'IMPERSONATION_MODAL__REFRESH_ORGANIZATION_FAILURE',
].reduce(
    (acc, eventName) => ({
        ...acc,
        [eventName]: acc[eventName] ? duplicateEventNameError(eventName) : Symbol.for(eventName),
    }),
    {}
);

function duplicateEventNameError(eventName) {
    throw new Error(`Event ${eventName} already exists`);
}

export default symbols;

import _ from 'lodash';

export function tacticsSummarySelector(storeState, ownProps) {
    const { ad } = ownProps;
    const allGeoCategories = _.get(storeState, 'resources.geoCategories');

    const geoTargetingSubCategories = _(allGeoCategories.geoCategories)
        .map(category => category.subcategories)
        .flatten()
        .value();

    return {
        geoTargetingSubCategories,
        geoLayers: allGeoCategories.geoLayers,
    };
}

import _ from 'lodash';
import React, { useEffect } from 'react';
import { useCampaignSummary } from './hook';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { SummaryLayout, SummaryGroup } from 'widgets/summary-view/summary-layout';
import SummaryTypeText from 'widgets/summary-view/summary-type-text';
import SummaryTypeComponent from 'widgets/summary-view/summary-type-component';
import SummaryTypeTextCollection from 'widgets/summary-view/summary-type-text-collection';
import Spacer from 'widgets-v5/spacer';
import { Label } from 'widgets-v5/typography';
import { DialogTitle } from 'widgets-v6/modal';

import IABCategories from 'common/constants/iab-categories';

import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
} from 'utils/formatting';
import { canViewCampaignCreator } from 'states/resources/campaigns/business-logic';
import { isInternalUser } from 'states/profile/business-rules';
import { RevenueModelMapping } from '../../../../../states/resources/campaigns/business-logic';

export const SummaryContent = ({ campaignId, hasDialogTitle = false, subTitle, dialogClose }) => {
    const {
        init,
        unInit,
        ownOrganization,
        isDoneInitializing,
        campaign,
        userMapping,
        partnersMapping,
        salesRepsMapping,
        advertisersMapping,
        locationListsMapping,
        revenueModelMapping,
        budgetAllocationMapping,
        showFta,
        showFtaFullyManagedFields,
        clientGrouping,
        showBudgetOptimizationField,
        restrictedCategoryMapping,
    } = useCampaignSummary();

    useEffect(() => {
        init(campaignId);
        return () => {
            unInit();
        };
    }, []);

    return isDoneInitializing ? (
        <React.Fragment>
            {hasDialogTitle ? (
                <DialogTitle onClose={dialogClose}>{`${campaignId} ${campaign.name}`}</DialogTitle>
            ) : null}
            <SummaryLayout title={subTitle}>
                <SummaryGroup groupName="Basic">
                    <SummaryTypeText keyName="Name" value={campaign.name} />
                    {_.map(campaign.custom_fields, customField => (
                        <SummaryTypeText
                            key={customField.name}
                            keyName={customField.name}
                            value={customField.value}
                        />
                    ))}
                    <SummaryTypeText keyName="Default Timezone" value={campaign.default_timezone} />
                    {canViewCampaignCreator() && ownOrganization && campaign.creatorId && (
                        <SummaryTypeText
                            keyName="Assignee"
                            value={_.get(userMapping, `${campaign.creatorId}.fullname`)}
                        />
                    )}
                    {campaign.notes && <SummaryTypeText keyName="Notes" value={campaign.notes} />}
                </SummaryGroup>
                <SummaryGroup groupName="Categorization">
                    {_.includes(['admin'], _.get(ownOrganization, 'type')) &&
                        isInternalUser() &&
                        clientGrouping.partner && (
                            <SummaryTypeTextCollection
                                keyName="Partners"
                                values={_.map(campaign.clients, client => {
                                    return (
                                        partnersMapping[client.organization] &&
                                        partnersMapping[client.organization].label
                                    );
                                })}
                            />
                        )}
                    {clientGrouping.advertiser && (
                        <SummaryTypeTextCollection
                            keyName="Advertisers"
                            values={_.map(
                                clientGrouping.advertiser,
                                advertiser =>
                                    advertisersMapping[advertiser.organization] &&
                                    advertisersMapping[advertiser.organization].label
                            )}
                        />
                    )}
                    <SummaryTypeText
                        keyName="Advertiser Domain"
                        value={campaign.advertiser_domain}
                    />
                    <SummaryTypeComponent
                        keyName="Adveriser Category"
                        value={_.map(campaign.iab_categories, iabCategory => (
                            <div key={iabCategory}>{IABCategories[iabCategory]}</div>
                        ))}
                    />
                    {_.includes(['admin'], _.get(ownOrganization, 'type')) &&
                        isInternalUser() &&
                        clientGrouping.sales_rep && (
                            <SummaryTypeTextCollection
                                keyName="Sales Reps"
                                values={_.map(campaign.clients, client => {
                                    return (
                                        salesRepsMapping[client.organization] &&
                                        salesRepsMapping[client.organization].label
                                    );
                                })}
                            />
                        )}
                </SummaryGroup>
                <SummaryGroup groupName="Budget & Billing">
                    <SummaryTypeText keyName="Currency" value={campaign.currency} />
                    <SummaryTypeText
                        keyName="Revenue Model"
                        value={revenueModelMapping[campaign.revenueModel].label}
                    />
                    {campaign.revenueModel === 'agencyMargin' && (
                        <SummaryTypeComponent
                            keyName="Agency Margin"
                            value={
                                campaign.billing_rate ? (
                                    <div>{formatNumber_percentage(campaign.billing_rate)}</div>
                                ) : (
                                    <div>{formatNumber_percentage(0)}</div>
                                )
                            }
                        />
                    )}
                    {campaign.revenueModel === 'agencyMargin' && (
                        <SummaryTypeComponent
                            keyName="Revenue Margin"
                            value={
                                campaign.total_billings_local ? (
                                    <div>
                                        <div>{`${campaign.currency} ${formatNumber_currency(
                                            campaign.total_billings_local
                                        )}`}</div>
                                        <Spacer type="small" />
                                        <div>Total Cost Budget</div>
                                        <div>
                                            {`${campaign.currency} ${formatNumber_currency(
                                                campaign.campaign_max_total_spend_local
                                            )}`}
                                        </div>
                                    </div>
                                ) : (
                                    <div>{`${campaign.currency} ${formatNumber_currency(0)}`}</div>
                                )
                            }
                        />
                    )}
                    {campaign.revenueModel !== null && campaign.revenueModel !== 'agencyMargin' && (
                        <SummaryTypeComponent
                            keyName="Total Cost Budget"
                            value={
                                campaign.campaign_max_total_spend_local === 0 ? (
                                    <div>Unlimited</div>
                                ) : (
                                    <div>
                                        {`${campaign.currency} ${formatNumber_currency(
                                            campaign.campaign_max_total_spend_local
                                        )}`}
                                    </div>
                                )
                            }
                        />
                    )}
                    {showBudgetOptimizationField && (
                        <SummaryTypeComponent
                            keyName="Ad Budget Allocation"
                            value={
                                <AutomaticBudgetAllocationMethodSummary
                                    budgetAllocationMapping={budgetAllocationMapping}
                                    budgetAllocationMethod={campaign.budget_allocation_method}
                                    automaticBudgetAllocationOptimizationStrategy={campaign.automaticBudgetAllocationOptimizationStrategy}
                                />
                            }
                        />
                    )}
                    {campaign.budget_allocation_method === 'auto' && (
                        <SummaryTypeComponent
                            keyName="Pacing Strategy"
                            value={
                                <div>
                                    {campaign.use_front_load_pacing
                                        ? 'Front-loaded pacing'
                                        : 'Even Pacing'}
                                </div>
                            }
                        />
                    )}
                    {campaign.budget_allocation_method === 'auto' &&
                        [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                            campaign?.revenueModel
                        ) && (
                            <SummaryTypeComponent
                                keyName="Total Impressions"
                                value={
                                    <div>
                                        {formatNumber_whole(
                                            campaign.campaign_max_total_impressions
                                        )}
                                    </div>
                                }
                            />
                        )}
                    {campaign.third_party_fees.length > 0 && (
                        <SummaryTypeTextCollection
                            keyName="Default Third Party Fees"
                            values={_.map(
                                campaign.third_party_fees,
                                thirdPartyFee =>
                                    `${thirdPartyFee.description}: ${
                                        campaign.currency
                                    } ${formatNumber_currency(thirdPartyFee.fee)} CPM`
                            )}
                        />
                    )}
                </SummaryGroup>
                {showFta && (
                    <SummaryGroup groupName="Foot Traffic Attribution">
                        <SummaryTypeComponent
                            keyName="Foot Traffic Attribution"
                            value={
                                <div>
                                    {campaign.fta_enabled &&
                                        campaign.fta_management_level === 'ad' && (
                                            <div>Enabled per ad</div>
                                        )}
                                    {campaign.fta_enabled &&
                                        campaign.fta_management_level === 'campaign' &&
                                        campaign.fta_location_list && (
                                            <div>
                                                <div>Enabled for all ads</div>
                                                <Spacer type="small" />
                                                <div>Location List</div>
                                                <div>
                                                    {_.get(
                                                        locationListsMapping,
                                                        `${campaign.fta_location_list}.name`
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    {!campaign.fta_enabled && <div>Disabled</div>}
                                </div>
                            }
                        />
                        {showFtaFullyManagedFields && (
                            <React.Fragment>
                                <SummaryTypeText
                                    keyName="Partner ID"
                                    value={campaign.fta_partner_id}
                                />
                                <SummaryTypeComponent
                                    keyName="Lookback Window"
                                    value={
                                        campaign.fta_conversion_lookback_window.type ===
                                        'unlimited' ? (
                                            <div>Unlimited</div>
                                        ) : (
                                            campaign.fta_conversion_lookback_window.type ===
                                                'limited' &&
                                            campaign.fta_conversion_lookback_window
                                                .lookback_window_days > 0 && (
                                                <React.Fragment>
                                                    <div>Limited</div>
                                                    <div color="textSecondary">
                                                        {
                                                            campaign.fta_conversion_lookback_window
                                                                .lookback_window_days
                                                        }{' '}
                                                        days
                                                    </div>
                                                </React.Fragment>
                                            )
                                        )
                                    }
                                />
                                <SummaryTypeComponent
                                    keyName="Analysis Window"
                                    value={
                                        <div>
                                            <div>Pre-campaign Interval</div>
                                            <div>
                                                {campaign.fta_pre_campaign_interval_days} days
                                            </div>
                                            <Spacer type="small" />
                                            <div>Post-campaign Interval</div>
                                            <div>
                                                {campaign.fta_post_campaign_interval_days} days
                                            </div>
                                        </div>
                                    }
                                />
                                <SummaryTypeText
                                    keyName="Lift Version"
                                    value={
                                        campaign.fta_lift_version === 'standard'
                                            ? 'Standard'
                                            : 'Lookalike'
                                    }
                                />
                            </React.Fragment>
                        )}
                    </SummaryGroup>
                )}
                <SummaryGroup groupName="Restricted Content">
                    <SummaryTypeText
                        keyName="Restricted Category"
                        value={
                            campaign.restricted_category
                                ? restrictedCategoryMapping[campaign.restricted_category].label
                                : 'None'
                        }
                    />
                </SummaryGroup>
            </SummaryLayout>
        </React.Fragment>
    ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
        </Box>
    );
};

export const AutomaticBudgetAllocationMethodSummary = ({
    budgetAllocationMethod,
    automaticBudgetAllocationOptimizationStrategy,
    budgetAllocationMapping
}) => (
    <div>
        <Label>
            {
                budgetAllocationMapping[budgetAllocationMethod].label
            }
            {budgetAllocationMethod ===
                'auto' &&
                ` (Optimize for ${automaticBudgetAllocationOptimizationStrategy.toUpperCase()})`}
        </Label>
    </div>
)


import _ from 'lodash';

const tools = {};

tools.isLikeNumberComma = val => {
    const regEx = /^(\d+,?)*\d*\.?\d*\s*$/;
    const _isLikeNumberComma = regEx.test(val);
    return _isLikeNumberComma;
};

tools.isLikeNumber = val => {
    const regEx = /^\d*\.?\d*\s*$/;
    const _isLikeNumber = regEx.test(val);
    return _isLikeNumber;
};

tools.removeCommas = val => {
    return val.split(',').join('');
};

tools.numberCommaStringToFloat = val => {
    if (typeof val === 'string' && tools.isLikeNumberComma(val)) {
        return parseFloat(tools.removeCommas(val));
    } else if (typeof val === 'number') {
        return parseFloat(val);
    }
    return null;
};

tools.numberCommaStringToInteger = val => {
    if (typeof val === 'string' && tools.isLikeNumberComma(val)) {
        return parseFloat(tools.removeCommas(val));
    } else if (typeof val === 'number') {
        return parseInt(val, 10);
    }
    return null;
};
export default tools;

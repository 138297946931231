import { parse } from 'csv-parse/sync';
import validate from 'utils/validate';
import _ from 'lodash';

// Declared here because they have no use outside of this context yet
const validators = {
    latitude(value, source) {
        const isLatitudeRequired = !source.address;

        if (isLatitudeRequired) {
            if (!value) {
                return 'a latitude is required';
            }

            if (_.isNaN(Number(value))) {
                return 'a valid latitude is required';
            }
            const latitude = Number(value);
            if (latitude < -90 || latitude > 90) {
                return 'latitude must be between -90 and 90';
            }
        }
    },
    longitude(value, source) {
        const isLongitudeRequired = !source.address;

        if (isLongitudeRequired) {
            if (!value) {
                return 'a latitude is required';
            }

            if (_.isNaN(Number(value))) {
                return 'a valid logitude is required';
            }
            const longitude = Number(value);
            if (longitude < -180 || longitude > 180) {
                return 'latitude must be between -180 and 180';
            }
        }
    },
    address(value, source) {
        const isAddressRequired = !source.latitude || !source.longitude;

        if (isAddressRequired) {
            if (!value) {
                return 'an address is required';
            }
        }
    },
    city() {},
    postalCode() {},
    province(value) {
        if (!value) {
            return;
        }

        if (
            !_.includes(
                [
                    'AB',
                    'BC',
                    'MB',
                    'NB',
                    'NL',
                    'NS',
                    'NT',
                    'NU',
                    'ON',
                    'PE',
                    'QC',
                    'SK',
                    'YT',
                    'N/A',
                ],
                value
            )
        ) {
            return 'Invalid province abbreviation';
        }
    },
};

export function validatePois({ data, parseCsv }) {
    let parsedCsv;
    if (parseCsv) {
        try {
            parsedCsv = parse(data, { columns: true });
        } catch (e) {
            console.error('Error parsing CSV: ', e);
            return ['Invalid CSV submitted with error:', e.message];
        }
    } else {
        parsedCsv = data;
    }

    const errors = {};
    _.forEach(parsedCsv, (pointData, index) => {
        let lineErrors = validate(pointData, validators);
        const poiKey =
            pointData.poiKey || `${pointData.latitude}_${pointData.longitude}_${pointData.address}`;
        _.forEach(lineErrors, error => {
            const condensedError = `Line ${index + 1}: Invalid ${error.field}`;
            errors[poiKey] = { ...error, message: condensedError };
        });
    });

    return Object.keys(errors).length ? errors : {};
}

export function constructPois(csvText) {
    let parsedCsv = parse(csvText, { columns: true });

    return _.map(parsedCsv, record => ({
        latitude: isNaN(Number(record.latitude)) ? null : Number(record.latitude),
        longitude: isNaN(Number(record.longitude)) ? null : Number(record.longitude),
        region: record.province,
        city: record.city,
        postalCode: record.postalCode,
        address: record.address,
        poiKey: `${record.latitude}_${record.longitude}_${record.address}`,
    }));
}

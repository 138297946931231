import FormHelperText from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import numeral from 'numeral';
import React from 'react';

import flags from 'containers/flags/service';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import ThirdPartyFees from 'forms/campaign-form/modules/third-party-fees';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import FormField from 'widgets-v5/form-field';
import PrefixSuffixInput from 'widgets-v5/prefix-suffix-input';
import Separator from 'widgets-v5/separator';
import Spacer from 'widgets-v5/spacer';
import StandardInput from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import Toggle from 'widgets-v5/toggle';
import { BodyBold, Label } from 'widgets-v5/typography';
import { SingleSelect } from 'widgets-v6/select';
import {
    PreFixSuffixInputInStrOutNum,
    PreFixSuffixInput as PrefixSuffixInputV6,
    TextFieldInStrOutInt,
} from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';

import { Box } from '@mui/material';
import { getEnvironmentSettings } from 'services/environment';
import {
    formatBillableVolume,
    getBillableVolume,
    getEfBillingTermsOptions,
    getGoalTypeOptions,
} from 'states/resources/ads/business-logic';
import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
    formatNumber_wholeFixed,
} from 'utils/formatting';
import { RevenueModelMapping } from '../../../../states/resources/campaigns/business-logic';
import LockedMessage from '../locked-message';

const useStyles = makeStyles(() => ({
    arrayHelperText: {
        marginLeft: 10,
    },
}));

class Budget extends React.Component {
    handleBillingEnabledChange = () => {
        const checked = !this.props.formData.billing_enabled;
        this.props.onFieldChange('billing_enabled')(checked);
    };

    handleBillingTermChange = value => {
        this.props.onFieldChange('ef_billing_terms')(value);
    };

    getFormByRevenueModel = props => {
        switch (props.campaign.revenueModel) {
            case 'cpmcpc':
            case 'cpm':
                return <RevenueBudgetCPMCPC {...props} />;
            case 'agencyMargin':
                return <RevenueBudgetAgencyMargin {...props} />;
            case 'totalSpendMarkup':
                return <RevenueBudgetTotalSpendMarkup {...props} />;
            case 'disabled':
                return <RevenueBudgetDisabled {...props} />;
            default:
                return null;
        }
    };

    render() {
        const {
            campaign_budget_enabled: campaignBudgetEnabled,
            billing_enabled: campaignBillingEnabled,
            billing_term: campaignBillingTerm,
            billing_rate: campaignBillingRate,
            currency,
        } = this.props.campaign;

        const props = {
            ...this.props,
            campaignBudgetEnabled,
            campaignBillingEnabled,
            campaignBillingTerm,
            campaignBillingRate,
            currency,
        };

        return this.getFormByRevenueModel({
            ...props,
            handleBillingEnabledChange: this.handleBillingEnabledChange,
            handleBillingTermChange: this.handleBillingTermChange,
        });
    }
}

function BonusAdOption({ formData, handleBillingEnabledChange }) {
    return (
        <React.Fragment>
            <div className="ef5-ad-form-budget__row ef5-ad-form-budget__row-rightAlign">
                <span>Bonus Ad?</span>
                <Toggle
                    className="ef5-ad-form-budget__billingToggle"
                    justify="none"
                    width={70}
                    height={30}
                    position={formData.billing_enabled ? 'left' : 'right'}
                    textLeft="No"
                    textRight="Yes"
                    onClick={handleBillingEnabledChange}
                />
            </div>
            <div className="ef5-ad-form-budget__spacer" />
        </React.Fragment>
    );
}

class RevenueBudgetCPMCPC extends React.Component {
    render() {
        const {
            errors,
            errorsByField,
            showErrors,
            formData,
            handleBillingEnabledChange,
            handleBillingTermChange,
            onFieldChange,
            currency,
            hideThirdPartyFees,
            campaign,
        } = this.props;

        if (!_.includes(['CPM', 'CPC'], formData.ef_billing_terms)) {
            onFieldChange('ef_billing_terms')('CPM');
        }

        const billingEnabled = formData.billing_enabled;
        const billingDisabled = !billingEnabled;

        const environmentSettings = getEnvironmentSettings();

        return (
            <div className="ef5-ad-form-budget">
                <BonusAdOption
                    formData={formData}
                    handleBillingEnabledChange={handleBillingEnabledChange}
                />
                <Spacer type="small" />
                <Separator label="Revenue" />
                <Spacer />
                <FormField label="Revenue Model">
                    <StandardSelector
                        onChange={handleBillingTermChange}
                        value={formData.ef_billing_terms}
                        autofocus={false}
                        clearable={false}
                        searchable={false}
                        label="Revenue Model"
                        items={getEfBillingTermsOptions(campaign.revenueModel)}
                    />
                </FormField>
                <Spacer type="small" />
                <FormField
                    label="Billing Rate"
                    errors={errors.billing_rate}
                    showErrors={showErrors}
                    isRequired={true}
                >
                    <PreFixSuffixInputInStrOutNum
                        prefix={`${currency} ${environmentSettings.getCurrencySign()}`}
                        suffix={formData.ef_billing_terms}
                        formatIn={formatSpendValueToString(formData.ef_billing_terms)}
                        formatOut={formatSpendStringToValue(formData.ef_billing_terms)}
                        onChange={onFieldChange('billing_rate')}
                        value={billingDisabled ? 0 : formData.billing_rate}
                        disabled={billingDisabled}
                        isNaNValue={'0.00'}
                    />
                </FormField>
                <Spacer type="small" />
                {formData.ef_billing_terms === 'CPM' && (
                    <React.Fragment>
                        <TextFieldInStrOutInt
                            onChange={onFieldChange('billable_volume')}
                            value={billingDisabled ? 0 : formData.billable_volume}
                            error={showErrors && errors.billable_volume}
                            fullWidth
                        />
                        <Spacer type="small" />
                        <TextFieldInStrOutInt
                            onChange={onFieldChange('non_billable_volume')}
                            value={formData.non_billable_volume}
                            error={showErrors && errors.billable_volume}
                            fullWidth
                        />
                    </React.Fragment>
                )}
                {formData.ef_billing_terms === 'CPC' && (
                    <React.Fragment>
                        <FormField
                            label="Billable Clicks"
                            errors={errors.billable_volume}
                            showErrors={showErrors}
                            isRequired={billingEnabled}
                        >
                            <StandardInput
                                onChange={onFieldChange('billable_volume')}
                                formatIn={formatBillableVolumeToString(formData.ef_billing_terms)}
                                formatOut={formatBillableVolumeStringToValue(
                                    formData.ef_billing_terms
                                )}
                                disabled={billingDisabled}
                                value={billingDisabled ? 0 : formData.billable_volume}
                            />
                        </FormField>
                        <Spacer type="small" />
                        <FormField
                            label="Non-billable Clicks"
                            errors={errors.non_billable_volume}
                            showErrors={showErrors}
                            isRequired={billingDisabled}
                        >
                            <StandardInput
                                onChange={onFieldChange('non_billable_volume')}
                                formatIn={formatBillableVolumeToString(formData.ef_billing_terms)}
                                formatOut={formatBillableVolumeStringToValue(
                                    formData.ef_billing_terms
                                )}
                                value={formData.non_billable_volume}
                            />
                        </FormField>
                    </React.Fragment>
                )}
                <Spacer />
                <Separator label="Budget" />
                <Spacer />
                {formData.ef_billing_terms === 'CPM' && (
                    <React.Fragment>
                        <Spacer type="small" />
                        <FormField label="Total Impressions">
                            <BodyBold>
                                {formatNumber_whole(formData.max_total_impressions)}
                            </BodyBold>
                        </FormField>
                    </React.Fragment>
                )}
                {formData.ef_billing_terms === 'CPC' && (
                    <React.Fragment>
                        <Spacer type="small" />
                        <FormField label="Total Clicks">
                            <BodyBold>{formatNumber_whole(formData.max_total_clicks)}</BodyBold>
                        </FormField>
                    </React.Fragment>
                )}
                <Spacer type="small" />
                <FormField label={'Total Revenue'}>
                    <BodyBold>
                        {currency} {formatNumber_currency(formData.max_total_billings)}
                    </BodyBold>
                </FormField>
                {!hideThirdPartyFees && (
                    <React.Fragment>
                        <Spacer />
                        <Separator />
                        <Spacer />
                        <FormField
                            label="Third Party Fees"
                            showErrors={showErrors}
                            isRequired={false}
                            tooltip={{
                                tooltip: (
                                    <div>
                                        Plan your campaign budget holistically by entering
                                        <br />
                                        your non-media costs such data fees or vendor fees.
                                        <br />
                                        These third-party fees will be automatically deducted
                                        <br />
                                        from your total media spend to make sure you don’t go
                                        over-budget.
                                    </div>
                                ),
                                position: 'right',
                            }}
                        >
                            <ThirdPartyFees
                                errors={errorsByField}
                                showErrors={showErrors}
                                currency={currency}
                                value={formData.thirdPartyFees}
                                onChange={onFieldChange('thirdPartyFees')}
                            />
                        </FormField>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

class RevenueBudgetAgencyMargin extends React.Component {
    render() {
        const {
            errorsByField,
            errors,
            showErrors,
            formData,
            onFieldChange,
            currency,
            hideThirdPartyFees,
        } = this.props;

        const environmentSettings = getEnvironmentSettings();
        return (
            <div className="ef5-ad-form-budget">
                <Spacer />
                <Separator label="Revenue" />
                <Spacer />
                <FormField label="Revenue Model">
                    <StandardSelector
                        value="agencyMargin"
                        autofocus={false}
                        clearable={false}
                        searchable={false}
                        label="Revenue Model"
                        disabled={true}
                        items={[{ label: 'Agency Margin', value: 'agencyMargin' }]}
                    />
                </FormField>
                <Spacer type="small" />
                <FormField
                    label="Agency Margin"
                    errors={errors.billing_rate}
                    showErrors={showErrors}
                    isRequired={true}
                >
                    <PrefixSuffixInput
                        placeholder=""
                        suffix="%"
                        disabled={true}
                        formatIn={formatSpendValueToString(formData.ef_billing_terms)}
                        formatOut={formatSpendStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForSpend}
                        onChange={onFieldChange('billing_rate')}
                        value={formData.billing_rate}
                    />
                </FormField>
                <Spacer />
                <Separator label="Budget" />
                <Spacer />
                <FormField
                    label="Revenue Budget (Client Spend)"
                    errors={errors.billable_volume}
                    showErrors={showErrors}
                    isRequired={true}
                >
                    <PrefixSuffixInput
                        prefix={environmentSettings.getCurrencySign()}
                        formatIn={formatBillableVolumeToString(formData.ef_billing_terms)}
                        formatOut={formatBillableVolumeStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForBillableVolume(
                            formData.ef_billing_terms
                        )}
                        onChange={onFieldChange('billable_volume')}
                        value={formData.billable_volume}
                    />
                </FormField>
                <Spacer type="small" />
                <FormField label="Total Cost Budget" disabled={true}>
                    <BodyBold>
                        {currency} {formatNumber_currency(formData.max_total_spend_local)}
                    </BodyBold>
                </FormField>
                {!hideThirdPartyFees && (
                    <React.Fragment>
                        <Spacer />
                        <Separator />
                        <Spacer />
                        <FormField
                            label="Third Party Fees"
                            showErrors={showErrors}
                            isRequired={false}
                            tooltip={{
                                tooltip: (
                                    <div>
                                        Plan your campaign budget holistically by entering
                                        <br />
                                        your non-media costs such data fees or vendor fees.
                                        <br />
                                        These third-party fees will be automatically deducted
                                        <br />
                                        from your total media spend to make sure you don’t go
                                        over-budget.
                                    </div>
                                ),
                                position: 'right',
                            }}
                        >
                            <ThirdPartyFees
                                errors={errorsByField}
                                showErrors={showErrors}
                                currency={currency}
                                value={formData.thirdPartyFees}
                                onChange={onFieldChange('thirdPartyFees')}
                            />
                        </FormField>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

class RevenueBudgetTotalSpendMarkup extends React.Component {
    render() {
        const {
            errorsByField,
            errors,
            showErrors,
            formData,
            onFieldChange,
            currency,
            hideThirdPartyFees,
        } = this.props;

        return (
            <div className="ef5-ad-form-budget">
                <Spacer />
                <Separator label="Revenue" />
                <Spacer />
                <FormField label="Revenue Model">
                    <StandardSelector
                        value="totalSpendMarkup"
                        autofocus={false}
                        clearable={false}
                        searchable={false}
                        label="Revenue Model"
                        disabled={true}
                        items={[
                            {
                                label: 'Total Cost Markup',
                                value: 'totalSpendMarkup',
                            },
                        ]}
                    />
                </FormField>
                <Spacer type="small" />
                <FormField
                    label="Markup Rate"
                    errors={errors.billing_rate}
                    showErrors={showErrors}
                    isRequired={true}
                >
                    <PrefixSuffixInput
                        placeholder=""
                        suffix="%"
                        disabled={false}
                        formatIn={formatSpendValueToString(formData.ef_billing_terms)}
                        formatOut={formatSpendStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForSpend}
                        onChange={onFieldChange('billing_rate')}
                        value={formData.billing_rate}
                    />
                </FormField>
                <Spacer />
                <Separator label="Budget" />
                <Spacer />
                <FormField
                    label="Total Cost Budget"
                    errors={errors.billable_volume}
                    showErrors={showErrors}
                    isRequired={true}
                >
                    <PrefixSuffixInput
                        prefix="$"
                        formatIn={formatBillableVolumeToString(formData.ef_billing_terms)}
                        formatOut={formatBillableVolumeStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForBillableVolume(
                            formData.ef_billing_terms
                        )}
                        onChange={onFieldChange('billable_volume')}
                        value={formData.billable_volume}
                    />
                </FormField>
                <Spacer type="small" />
                <FormField label="Revenue Budget" disabled={true}>
                    <BodyBold>
                        {currency} {formatNumber_currency(formData.max_total_billings)}
                    </BodyBold>
                </FormField>
                {!hideThirdPartyFees && (
                    <React.Fragment>
                        <Spacer />
                        <Separator />
                        <Spacer />
                        <FormField
                            label="Third Party Fees"
                            showErrors={showErrors}
                            isRequired={false}
                            tooltip={{
                                tooltip: (
                                    <div>
                                        Plan your campaign budget holistically by entering
                                        <br />
                                        your non-media costs such data fees or vendor fees.
                                        <br />
                                        These third-party fees will be automatically deducted
                                        <br />
                                        from your total media spend to make sure you don’t go
                                        over-budget.
                                    </div>
                                ),
                                position: 'right',
                            }}
                        >
                            <ThirdPartyFees
                                errors={errorsByField}
                                showErrors={showErrors}
                                currency={currency}
                                value={formData.thirdPartyFees}
                                onChange={onFieldChange('thirdPartyFees')}
                            />
                        </FormField>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

class RevenueBudgetDisabled extends React.Component {
    getPrefix = () => {
        const environmentSettings = getEnvironmentSettings();
        if (this.props.formData.ef_billing_terms === 'billable_media_cost_markup') {
            return environmentSettings.getCurrencySign();
        }
        return '';
    };

    getVolumeLabel = () => {
        const volumeLabelMapping = {
            CPM: 'Total Impressions',
            CPC: 'Total Clicks',
            billable_media_cost_markup: 'Total Cost',
        };
        return volumeLabelMapping[this.props.formData.ef_billing_terms];
    };

    render() {
        const {
            errorsByField,
            errors,
            showErrors,
            formData,
            handleBillingTermChange,
            onFieldChange,
            currency,
            hideThirdPartyFees,
        } = this.props;

        return (
            <div className="ef5-ad-form-budget">
                <FormField label="Goal Type">
                    <StandardSelector
                        onChange={handleBillingTermChange}
                        value={formData.ef_billing_terms}
                        autofocus={false}
                        clearable={false}
                        searchable={false}
                        label=""
                        placeholder="Select billing terms..."
                        items={[
                            {
                                label: 'Impressions',
                                value: 'CPM',
                            },
                            {
                                label: 'Clicks',
                                value: 'CPC',
                            },
                            {
                                label: 'Total Cost',
                                value: 'billable_media_cost_markup',
                            },
                        ]}
                    />
                </FormField>
                <Spacer type="small" />
                <FormField
                    label={this.getVolumeLabel()}
                    errors={errors.non_billable_volume}
                    showErrors={showErrors}
                    isRequired={true}
                >
                    <PrefixSuffixInput
                        prefix={this.getPrefix()}
                        formatIn={formatBillableVolumeToString(formData.ef_billing_terms)}
                        formatOut={formatBillableVolumeStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForBillableVolume(
                            formData.ef_billing_terms
                        )}
                        onChange={onFieldChange('non_billable_volume')}
                        value={formData.non_billable_volume}
                    />
                </FormField>
                {!hideThirdPartyFees && (
                    <React.Fragment>
                        <Spacer />
                        <Separator />
                        <Spacer />
                        <FormField
                            label="Third Party Fees"
                            showErrors={showErrors}
                            isRequired={false}
                            tooltip={{
                                tooltip: (
                                    <div>
                                        Plan your campaign budget holistically by entering
                                        <br />
                                        your non-media costs such data fees or vendor fees.
                                        <br />
                                        These third-party fees will be automatically deducted
                                        <br />
                                        from your total media spend to make sure you don’t go
                                        over-budget.
                                    </div>
                                ),
                                position: 'right',
                            }}
                        >
                            <ThirdPartyFees
                                errors={errorsByField}
                                showErrors={showErrors}
                                currency={currency}
                                value={formData.thirdPartyFees}
                                onChange={onFieldChange('thirdPartyFees')}
                            />
                        </FormField>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

// ---------------
// billable_volume
// ---------------
function shouldAllowInputForBillableVolume(billingTerm) {
    return input => {
        if (input === '') {
            return true;
        }

        switch (billingTerm) {
            case 'billable_media_cost_markup':
            case 'billable_media_cost_margin':
                return /^\$?\d+(,\d{0,3})*(\.\d{0,2})?$/.test(input);
            default:
                return /^[\d,]*$/g.test(input);
        }
    };
}
function formatBillableVolumeToString(billingTerm) {
    return value => {
        return formatBillableVolume(value, billingTerm);
    };
}
// when user modify the value
// this function decides what is sent to redux store
function formatBillableVolumeStringToValue(billingTerm) {
    return string => {
        if (string === '') {
            return 0;
        }

        switch (billingTerm) {
            case 'billable_media_cost_markup':
            case 'billable_media_cost_margin':
                return parseFloat(string.replace(/,/g, ''));
            default:
                return parseInt(string.replace(/,/g, ''));
        }
        /*
        if (string === '') {
            return 0;
        } else {
            return parseFloat(string.replace(/,/g, ''));
        }
        */
    };
}

// ---------------------
// Spend (billling_rate)
// ---------------------
function shouldAllowInputForSpend(input) {
    // Allow empty input
    if (input === '') {
        return true;
    }
    // Check allowable characters
    if (!/^[\d,]*(\.\d{0,2})?$/g.test(input)) {
        return false;
    }
    // Check character length

    // it is supposed to prevent $ value
    const [dollars, cents] = input.split('.');
    if (!dollars.match(/\d/g)) {
        return true;
    }
    const numDollarsDigits = dollars.match(/\d/g).length;
    const numCentsDigits = cents ? cents.length : 0;
    return numDollarsDigits <= 9 && numCentsDigits <= 2;
}

function formatSpendValueToString(billingTerm) {
    return value => {
        switch (billingTerm) {
            case 'billable_media_cost_markup':
            case 'billable_media_cost_margin':
            case 'media_cost_markup':
                // if (value > 1) {
                //     return value;
                // }
                return formatNumber_wholeFixed(parseFloat(value) * 100);
            default:
                if (typeof value !== 'number') {
                    // throw new Error('Billing rate should be type number');
                    return '0.00';
                }
                const [dollars, cents] = value.toFixed(2).split('.');
                const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
                return `${formattedDollars}.${cents}`;
        }
    };
}

function formatSpendStringToValue(billingTerm) {
    return string => {
        if (string === '' || string === '.') {
            return 0;
        }

        switch (billingTerm) {
            case 'billable_media_cost_markup':
            case 'billable_media_cost_margin':
            case 'media_cost_markup':
                return parseFloat(string) / 100;
            default:
                return parseFloat(string.replace(/,/g, ''));
        }
    };
}

export function BonusAd(props) {
    const handleBillingEnabledChange = () => {
        const checked = !props.formData.billing_enabled;
        props.onFieldChange('billing_enabled')(checked);
    };

    return (
        <Toggle
            className="ef5-ad-form-budget__billingToggle"
            justify="none"
            width={70}
            height={30}
            position={props.formData.billing_enabled ? 'left' : 'right'}
            textLeft="No"
            textRight="Yes"
            onClick={handleBillingEnabledChange}
        />
    );
}

export function Revenue(props) {
    switch (props.campaign.revenueModel) {
        case 'cpmcpc':
        case 'cpm':
            return <CPMCPCRevenue {...props} />;
        case 'agencyMargin':
            return <AgencyMarginRevenue {...props} />;
        case 'totalSpendMarkup':
            return <MarkupRevenue {...props} />;
        default:
            return null;
    }
}

function CPMCPCRevenue(props) {
    const {
        expandPanel,
        expanded,
        formData,
        onFieldChange,
        showErrors,
        errors,
        billingEnabled,
        currency,
        billableVolume,
        campaign,
    } = props;

    const billingDisabled = billingEnabled === false;

    const handleBillingTermChange = value => {
        if (value === null) {
            return;
        }
        onFieldChange('ef_billing_terms')(value);
    };

    const environmentSettings = getEnvironmentSettings();
    const isCPMCPCCampaignWithAutoBudget =
        campaign.budget_allocation_method === 'auto' &&
        campaign.revenueModel === RevenueModelMapping.CPMCPC;
    return (
        <React.Fragment>
            <AccordionItem
                onClick={expandPanel('bonusAd')}
                expanded={expanded === 'bonusAd'}
                title="Bonus Ad"
                details={<BonusAd formData={formData} onFieldChange={onFieldChange} />}
                summary={formData.billing_enabled ? <Label>Off</Label> : <Label>On</Label>}
            />
            <AccordionItem
                onClick={expandPanel('revenueModel')}
                expanded={expanded === 'revenueModel'}
                title="Revenue Model"
                disabled={isCPMCPCCampaignWithAutoBudget}
                details={
                    <SingleSelect
                        required
                        value={formData.ef_billing_terms}
                        onChange={handleBillingTermChange}
                        options={getEfBillingTermsOptions(campaign.revenueModel)}
                        error={errors && showErrors}
                    />
                }
                summary={
                    isCPMCPCCampaignWithAutoBudget ? (
                        <LockedMessage
                            body={<></>}
                            title="When automatic budget allocation is used, all ads will use the CPM revenue model and will be paced by impressions"
                        />
                    ) : (
                        <Label>
                            {formData.ef_billing_terms === 'CPM' && 'CPM'}
                            {formData.ef_billing_terms === 'CPC' && 'CPC'}
                        </Label>
                    )
                }
                helper="Set how revenue is calculated in reporting and specify the delivery goal for this ad."
            />
            <AccordionItem
                onClick={expandPanel('billingRate')}
                expanded={expanded === 'billingRate'}
                title="Billing Rate"
                readOnly={formData.ef_billing_terms === 'billable_media_cost_margin'}
                incomplete={!showErrors && !formData.billing_rate}
                error={showErrors && errors.billing_rate}
                required={formData.billing_enabled}
                details={
                    <React.Fragment>
                        <TextFieldFormatter
                            initialValue={billingDisabled ? 0 : formData.billing_rate}
                            onChange={onFieldChange('billing_rate')}
                            shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                            formatIn={v => numeral(v).format('0,0.00')}
                            formatOut={v => {
                                return v ? numeral(v).value() : 0;
                            }}
                            renderInput={({ value, onChange, onBlur }) => (
                                <PrefixSuffixInputV6
                                    prefix={`${currency} ${environmentSettings.getCurrencySign()}`}
                                    suffix={formData.ef_billing_terms}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    disabled={billingDisabled}
                                />
                            )}
                        />
                        {flags.isEnabled(
                            'en_3224_warning_and_alert_when_CPM_rate_less_than_totalBidPrice'
                        ) &&
                            campaign.revenueModel === RevenueModelMapping.CPMCPC &&
                            (formData.max_cpm_rate_local > formData.billing_rate ||
                                formData.automatic_bid_price.max_ecpm_local >
                                    formData.billing_rate) && (
                                <Box marginTop={1}>
                                    {formData.bid_strategy_mode === 'automatic_bid_price' ? (
                                        <WarningText
                                            message={`Billing Rate is less than the Total Bid Price Limit of ${formatNumber_currency(
                                                formData.automatic_bid_price.max_ecpm_local
                                            )}. This may result in a negative margin.`}
                                        />
                                    ) : (
                                        <WarningText
                                            message={`Billing Rate is less than the Total Bid Price Limit of ${formatNumber_currency(
                                                formData.max_cpm_rate_local
                                            )}. This may result in a negative margin.`}
                                        />
                                    )}
                                </Box>
                            )}
                    </React.Fragment>
                }
                summary={
                    <React.Fragment>
                        {formData.ef_billing_terms === 'CPM' && billingEnabled && (
                            <Label placeholder={formData.billing_rate <= 0}>
                                {`${formatNumber_currency(formData.billing_rate)} CPM`}
                            </Label>
                        )}
                        {formData.ef_billing_terms === 'CPC' && billingEnabled && (
                            <Label placeholder={formData.billing_rate <= 0}>
                                {`${formatNumber_currency(formData.billing_rate)} CPC`}
                            </Label>
                        )}
                    </React.Fragment>
                }
                disabled={!billingEnabled}
            />
            <AccordionItem
                onClick={expandPanel('billableRate')}
                expanded={expanded === 'billableRate'}
                title={
                    formData.ef_billing_terms === 'CPM' ? 'Billable Impressions' : 'Billable Clicks'
                }
                required={formData.billing_enabled}
                incomplete={!showErrors && !formData.billable_volume}
                error={showErrors && errors.billable_volume}
                disabled={isCPMCPCCampaignWithAutoBudget || !billingEnabled}
                details={
                    <TextFieldInStrOutInt
                        onChange={onFieldChange('billable_volume')}
                        value={billingDisabled ? 0 : formData.billable_volume}
                        error={showErrors && errors.billable_volume}
                        fullWidth
                    />
                }
                summary={
                    isCPMCPCCampaignWithAutoBudget ? (
                        <LockedMessage body={<></>} />
                    ) : (
                        <React.Fragment>
                            {formData.ef_billing_terms === 'CPM' && billingEnabled && (
                                <Label placeholder={billableVolume <= 0}>
                                    {formatNumber_whole(billableVolume)}
                                </Label>
                            )}
                            {formData.ef_billing_terms === 'CPC' && billingEnabled && (
                                <Label placeholder={billableVolume <= 0}>
                                    {formatNumber_whole(getBillableVolume(formData))}
                                </Label>
                            )}
                        </React.Fragment>
                    )
                }
            />
            <AccordionItem
                onClick={props.expandPanel('nonBillableRate')}
                expanded={props.expanded === 'nonBillableRate'}
                title={
                    formData.ef_billing_terms === 'CPM'
                        ? 'Non-billable Impressions'
                        : 'Non-billable Clicks'
                }
                required={billingDisabled}
                incomplete={!showErrors && !formData.non_billable_volume}
                error={showErrors && errors.non_billable_volume}
                disabled={isCPMCPCCampaignWithAutoBudget}
                details={
                    <TextFieldInStrOutInt
                        onChange={onFieldChange('non_billable_volume')}
                        value={formData.non_billable_volume}
                        error={showErrors && errors.non_billable_volume}
                        fullWidth
                    />
                }
                summary={
                    isCPMCPCCampaignWithAutoBudget ? (
                        <LockedMessage body={<></>} />
                    ) : (
                        <Label placeholder={formData.non_billable_volume <= 0}>
                            {(formData.ef_billing_terms === 'CPM' || 'CPC') &&
                                formatNumber_whole(formData.non_billable_volume)}
                        </Label>
                    )
                }
            />
        </React.Fragment>
    );
}

function AgencyMarginRevenue(props) {
    const {
        expandPanel,
        expanded,
        formData,
        onFieldChange,
        showErrors,
        errors,
        billingEnabled,
    } = props;
    return (
        <React.Fragment>
            <AccordionItem
                onClick={expandPanel('revenueModel')}
                expanded={expanded === 'revenueModel'}
                title="Revenue Model"
                readOnly
                details={
                    <StandardSelector
                        value="agencyMargin"
                        autofocus={false}
                        clearable={false}
                        searchable={false}
                        label="Revenue Model"
                        disabled={true}
                        items={[{ label: 'Agency Margin', value: 'agencyMargin' }]}
                    />
                }
                summary={
                    <Label>
                        {formData.ef_billing_terms === 'billable_media_cost_margin' &&
                            billingEnabled &&
                            'Agency Margin'}
                    </Label>
                }
                helper="Set how revenue is calculated in reporting and specify the delivery goal for this ad."
            />
            <AccordionItem
                onClick={expandPanel('agencyMargin')}
                expanded={expanded === 'agencyMargin'}
                incomplete={!showErrors && !formData.billing_rate}
                error={showErrors && errors.billing_rate}
                title="Agency Margin"
                readOnly
                details={
                    <PrefixSuffixInput
                        placeholder=""
                        suffix="%"
                        disabled={true}
                        formatIn={formatSpendValueToString(formData.ef_billing_terms)}
                        formatOut={formatSpendStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForSpend}
                        onChange={onFieldChange('billing_rate')}
                        value={formData.billing_rate}
                    />
                }
                summary={<Label>{`${formatNumber_percentage(formData.billing_rate)}`}</Label>}
            />
        </React.Fragment>
    );
}

function MarkupRevenue(props) {
    const {
        expandPanel,
        expanded,
        formData,
        onFieldChange,
        showErrors,
        errors,
        billingEnabled,
    } = props;
    return (
        <React.Fragment>
            <AccordionItem
                onClick={expandPanel('revenueModel')}
                expanded={expanded === 'revenueModel'}
                title="Revenue Model"
                readOnly
                required
                details={
                    <StandardSelector
                        value="totalSpendMarkup"
                        autofocus={false}
                        clearable={false}
                        searchable={false}
                        label="Revenue Model"
                        disabled={true}
                        items={[
                            {
                                label: 'Total Cost Markup',
                                value: 'totalSpendMarkup',
                            },
                        ]}
                    />
                }
                summary={
                    <Label>
                        {formData.ef_billing_terms === 'billable_media_cost_markup' &&
                            billingEnabled &&
                            'Total Cost Markup'}
                    </Label>
                }
                helper="Set how revenue is calculated in reporting and specify the delivery goal for this ad."
            />
            <AccordionItem
                onClick={expandPanel('markupRate')}
                expanded={expanded === 'markupRate'}
                incomplete={!showErrors && !formData.billing_rate}
                error={showErrors && errors.billing_rate}
                title="Markup Rate"
                required
                details={
                    <PrefixSuffixInput
                        placeholder=""
                        suffix="%"
                        disabled={false}
                        formatIn={formatSpendValueToString(formData.ef_billing_terms)}
                        formatOut={formatSpendStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForSpend}
                        onChange={onFieldChange('billing_rate')}
                        value={formData.billing_rate}
                    />
                }
                summary={
                    <Label placeholder={formData.billing_rate <= 0}>
                        {`${formatNumber_percentage(formData.billing_rate)}`}
                    </Label>
                }
            />
        </React.Fragment>
    );
}

export function RevenueBudget(props) {
    switch (props.campaign.revenueModel) {
        case RevenueModelMapping.CPMCPC:
            return <CPMCPCBudget {...props} />;
        case RevenueModelMapping.AgencyMargin:
            return <AgencyMarginBudget {...props} />;
        case RevenueModelMapping.TotalSpendMarkup:
            return <MarkupBudget {...props} />;
        case RevenueModelMapping.Disabled:
            return <DisabledBudget {...props} />;
        default:
            return null;
    }
}

function CPMCPCBudget(props) {
    const { expandPanel, expanded, formData, campaign, billableVolume, currency } = props;

    const isCPMCPCCampaignWithAutoBudget =
        campaign.budget_allocation_method === 'auto' &&
        campaign.revenueModel === RevenueModelMapping.CPMCPC;
    return (
        <React.Fragment>
            <AccordionItem
                onClick={expandPanel('billingTerms')}
                expanded={expanded === 'billingTerms'}
                title={formData.ef_billing_terms === 'CPM' ? 'Total Impressions' : 'Total Clicks'}
                readOnly
                disabled={isCPMCPCCampaignWithAutoBudget}
                summary={
                    isCPMCPCCampaignWithAutoBudget ? (
                        <LockedMessage body={<></>} />
                    ) : (
                        <Label placeholder={billableVolume + formData.non_billable_volume <= 0}>
                            {campaign.revenueModel === RevenueModelMapping.CPMCPC &&
                                formatNumber_whole(billableVolume + formData.non_billable_volume)}
                        </Label>
                    )
                }
            />
            <AccordionItem
                onClick={expandPanel('totalRevenue')}
                expanded={expanded === 'totalRevenue'}
                title="Total Revenue"
                readOnly
                disabled={isCPMCPCCampaignWithAutoBudget}
                summary={
                    isCPMCPCCampaignWithAutoBudget ? (
                        <LockedMessage body={<></>} />
                    ) : (
                        <Label placeholder={formData.max_total_billings <= 0}>
                            {currency} {formatNumber_currency(formData.max_total_billings)}
                        </Label>
                    )
                }
            />
        </React.Fragment>
    );
}

function AgencyMarginBudget(props) {
    const {
        expandPanel,
        expanded,
        formData,
        campaign,
        currency,
        showErrors,
        errors,
        onFieldChange,
        adOverBudgetWarning,
    } = props;
    const classes = useStyles();
    const environmentSettings = getEnvironmentSettings();
    return (
        <React.Fragment>
            <AccordionItem
                onClick={expandPanel('clientBudget')}
                expanded={expanded === 'clientBudget'}
                title="Revenue Budget"
                incomplete={
                    !showErrors &&
                    !formData.billable_volume &&
                    campaign.budget_allocation_method !== 'auto'
                }
                error={
                    showErrors &&
                    errors.billable_volume &&
                    campaign.budget_allocation_method !== 'auto'
                }
                required={campaign.budget_allocation_method !== 'auto'}
                disabled={campaign.budget_allocation_method === 'auto'}
                details={
                    <React.Fragment>
                        <PrefixSuffixInput
                            prefix={environmentSettings.getCurrencySign()}
                            formatIn={formatBillableVolumeToString(formData.ef_billing_terms)}
                            formatOut={formatBillableVolumeStringToValue(formData.ef_billing_terms)}
                            shouldAllowInput={shouldAllowInputForBillableVolume(
                                formData.ef_billing_terms
                            )}
                            onChange={onFieldChange('billable_volume')}
                            value={formData.billable_volume}
                        />
                        {showErrors && errors.billable_volume && (
                            <FormHelperText error classes={{ root: classes.arrayHelperText }}>
                                {errors.billable_volume}
                            </FormHelperText>
                        )}
                    </React.Fragment>
                }
                summary={
                    campaign.budget_allocation_method === 'auto' ? (
                        <LockedMessage
                            autoBudgetAllocationMethod={
                                campaign.automaticBudgetAllocationOptimizationStrategy
                            }
                        />
                    ) : (
                        <Label placeholder={formData.billable_volume <= 0}>
                            {formatNumber_currency(formData.billable_volume)}
                        </Label>
                    )
                }
            />
            {campaign.budget_allocation_method !== 'auto' && (
                <AccordionItem
                    onClick={expandPanel('totalSpend')}
                    expanded={expanded === 'totalSpend'}
                    title="Total Cost Budget"
                    readOnly
                    summary={
                        <Label placeholder={formData.max_total_spend_local <= 0}>
                            {currency} {formatNumber_currency(formData.max_total_spend_local)}
                            {adOverBudgetWarning && (
                                <WarningText
                                    message={adOverBudgetWarning.message}
                                    severity={adOverBudgetWarning.severity}
                                />
                            )}
                        </Label>
                    }
                />
            )}
        </React.Fragment>
    );
}

function MarkupBudget(props) {
    const { expandPanel, expanded, formData, currency, showErrors, errors, onFieldChange } = props;

    const environmentSettings = getEnvironmentSettings();
    return (
        <React.Fragment>
            <AccordionItem
                onClick={expandPanel('clientBudget')}
                expanded={expanded === 'clientBudget'}
                title="Total Cost Budget"
                incomplete={!showErrors && !formData.billable_volume}
                error={showErrors && errors.billable_volume}
                required
                details={
                    <PrefixSuffixInput
                        prefix={environmentSettings.getCurrencySign()}
                        formatIn={formatBillableVolumeToString(formData.ef_billing_terms)}
                        formatOut={formatBillableVolumeStringToValue(formData.ef_billing_terms)}
                        shouldAllowInput={shouldAllowInputForBillableVolume(
                            formData.ef_billing_terms
                        )}
                        onChange={onFieldChange('billable_volume')}
                        value={formData.billable_volume}
                    />
                }
                summary={
                    <Label placeholder={getBillableVolume(formData) <= 0}>
                        {formatNumber_currency(getBillableVolume(formData))}
                    </Label>
                }
            />
            <AccordionItem
                onClick={expandPanel('totalRevenue')}
                expanded={expanded === 'totalRevenue'}
                title="Total Revenue"
                readOnly
                summary={
                    <Label placeholder={formData.max_total_billings <= 0}>
                        {currency} {formatNumber_currency(formData.max_total_billings)}
                    </Label>
                }
            />
        </React.Fragment>
    );
}

function DisabledBudget(props) {
    const {
        expandPanel,
        expanded,
        formData,
        onFieldChange,
        showErrors,
        errors,
        campaign,
        adOverBudgetWarning,
    } = props;

    const environmentSettings = getEnvironmentSettings();

    const handleBillingTermChange = value => {
        onFieldChange('ef_billing_terms')(value);
    };

    const getPrefix = () => {
        if (formData.ef_billing_terms === 'billable_media_cost_markup') {
            return environmentSettings.getCurrencySign();
        }
        return '';
    };

    const getVolumeLabel = () => {
        const volumeLabelMapping = {
            CPM: 'Total Impressions',
            CPC: 'Total Clicks',
            billable_media_cost_markup: 'Total Cost',
        };
        return volumeLabelMapping[formData.ef_billing_terms];
    };

    const getGoalType = () => {
        const mapping = {
            CPM: 'Impressions',
            CPC: 'Clicks',
            billable_media_cost_markup: 'Total Cost',
        };

        return mapping[formData.ef_billing_terms];
    };

    const getGoalValue = () => {
        const nonBillableVolume = Math.max(0, formData.non_billable_volume);

        switch (formData.ef_billing_terms) {
            case 'CPM':
                return formatNumber_whole(nonBillableVolume);
            case 'CPC':
                return formatNumber_whole(nonBillableVolume);
            case 'billable_media_cost_markup':
                return formatNumber_currency(nonBillableVolume);
        }
    };

    const useDisabledRevenueModelAndAutoBudgetAllocation =
        campaign.revenueModel === RevenueModelMapping.Disabled &&
        campaign.budget_allocation_method === 'auto';

    return (
        <React.Fragment>
            <AccordionItem
                onClick={expandPanel('revenueModel')}
                expanded={expanded === 'revenueModel'}
                title="Goal Type"
                required={!useDisabledRevenueModelAndAutoBudgetAllocation}
                disabled={useDisabledRevenueModelAndAutoBudgetAllocation}
                details={
                    <StandardSelector
                        onChange={handleBillingTermChange}
                        value={formData.ef_billing_terms}
                        autofocus={false}
                        clearable={false}
                        searchable={false}
                        label=""
                        placeholder="Select billing terms..."
                        items={getGoalTypeOptions(campaign)}
                    />
               }
                summary={
                    useDisabledRevenueModelAndAutoBudgetAllocation ? (
                        <LockedMessage
                            autoBudgetAllocationMethod={
                                campaign.automaticBudgetAllocationOptimizationStrategy
                            }
                        />
                    ) : (
                        <Label>{getGoalType()}</Label>
                    )
                }
                helper="Set how revenue is calculated in reporting and specify the delivery goal for this ad."
            />
            {!useDisabledRevenueModelAndAutoBudgetAllocation && (
                <AccordionItem
                    onClick={expandPanel('disabledTotal')}
                    expanded={expanded === 'disabledTotal'}
                    title={getVolumeLabel()}
                    incomplete={!showErrors && !formData.non_billable_volume}
                    error={showErrors && errors.non_billable_volume}
                    required
                    details={
                        <React.Fragment>
                            <PrefixSuffixInput
                                prefix={getPrefix()}
                                formatIn={formatBillableVolumeToString(
                                    formData.ef_billing_terms
                                )}
                                formatOut={formatBillableVolumeStringToValue(
                                    formData.ef_billing_terms
                                )}
                                shouldAllowInput={shouldAllowInputForBillableVolume(
                                    formData.ef_billing_terms
                                )}
                                onChange={onFieldChange('non_billable_volume')}
                                value={formData.non_billable_volume}
                            />
                            {adOverBudgetWarning && (
                                <WarningText
                                    message={adOverBudgetWarning.message}
                                    severity={adOverBudgetWarning.severity}
                                />
                            )}
                        </React.Fragment>
                    }
                    summary={
                        <Label placeholder={formData.non_billable_volume <= 0}>
                            {getGoalValue()}
                        </Label>
                    }
                />
            )}
        </React.Fragment>
    );
}

export function ThirdPartyFeesBudget(props) {
    const { showErrors, formData, onFieldChange, errorsByField, currency } = props;

    return (
        <ThirdPartyFees
            errors={errorsByField}
            showErrors={showErrors}
            currency={currency}
            value={formData.thirdPartyFees}
            onChange={onFieldChange('thirdPartyFees')}
        />
    );
}

export default Budget;

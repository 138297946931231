import { actions } from './reducer';
import { graphqlRequest } from 'utils/http/redux';

const initMw = () => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.init.type) {
        const adId = action.payload;
        const res = await dispatch(fetchAd(adId));
        dispatch(actions.initSuccess(res.data.ad));
    }
};

function fetchAd(adId) {
    return graphqlRequest({
        query: `
            query fetchAdSummaryData($adId: Int) {
                ad(id: $adId) {
                    id
                    name
                    notes
                    status
                    paused
                    start
                    end
                    timezone
                    primary_pacing
                    max_daily_clicks
                    max_daily_impressions
                    max_daily_spend_local
                    max_daily_billings
                    max_total_impressions
                    max_total_clicks
                    max_total_impressions
                    max_total_spend_local
                    max_bid_cpm_local
                    ef_billing_terms
                    use_front_load_pacing
                    unalteredDuplicate
                    isStartSet
                    isEndSet
                    creative
                    isArchived
                    target_carriers
                    target_device_os
                    target_dooh_venues
                    target_dooh_venues_exclude
                    platforms
                    billable_volume
                    non_billable_volume
                    target_carriers
                    carriers_exclude
                    weekparts
                    weekparts_local
                    dayparts {
                        start
                        end
                    }
                    dayparts_local {
                        start
                        end
                    }
                    billing_enabled
                    platform
                    platforms
                    billing_rate
                    isIasPostBidVerificationEnabled
                    geotargets {
                        country
                        include {
                            region
                            city
                            fsa
                        }
                        exclude {
                            region
                            city
                            fsa
                        }
                    }
                    geoboxes {
                        N
                        S
                        E
                        W
                    }
                    tactics_enabled
                    target_device_os
                    target_device_language
                    target_device_language_exclude
                    target_iab_categories
                    target_iab_categories_exclude
                    target_app_store_cat
                    target_app_store_cat_exclude
                    target_genders
                    target_age_groups
                    audiences
                    audience_exclude
                    dealApplicationType
                    exchanges_blocked_ui
                    exchanges_blocked
                    whitelistedAppsAndSites
                    blacklistedAppsAndSites
                    bid_strategy_mode
                    max_total_billings
                    max_cpm_rate_local
                    fta_fee
                    audience_fee
                    conversionsForCreativeOptimization
                    thirdPartyFees {
                        description
                        billing_model
                        fee
                        billable
                        disabled
                    }
                    fcaps {
                        imp
                        interval_count
                        interval_unit
                        start
                    }
                    automatic_bid_price {
                        apply_max_ecpm
                        max_ecpm_local
                        optimizing_strategy {
                            mode
                            max_ecpc_local
                            max_ecpcv_local
                        }
                    }
                    weatherConditions {
                        logicRelationship
                        conditions {
                            id
                            type
                            qualifier
                            amount
                            timeframe
                        }
                    }
                    geo_targeting_settings {
                        categories
                        category_layers
                        custom_layers
                    }
                    fta {
                        line_id
                        partner_id
                        enabled
                        location_list
                    }
                    custom_fields {
                        key
                        name
                        value
                        required
                    }

                    ias {
                        fraudPrevention {
                            risk {
                                value
                            }
                            unrateable {
                                value
                            }
                            ip {
                                value
                            }
                        }
                        brandSafety {
                            adult {
                                value
                            }
                            alcohol {
                                value
                            }
                            gambling {
                                value
                            }
                            hateSpeech {
                                value
                            }
                            illegalDownloads {
                                value
                            }
                            illegalDrugs {
                                value
                            }
                            offensiveLanguage {
                                value
                            }
                            violence {
                                value
                            }
                            unrateable {
                                value
                            }
                        }
                        viewability {
                            value
                        }
                        groupmViewability {
                            value
                        }
                        publicisViewability {
                            value
                        }
                    }
                    tactics_generators {
                        id
                        title
                        targeting {
                            ... on AudienceTargeting {
                                id
                                module
                                draft {
                                    audiences
                                    audience_exclude
                                    audience_fee
                                    geo_targeting_settings {
                                        categories
                                        category_layers
                                        custom_layers

                                    }
                                }
                            }
                            ... on CategoryTargeting {
                                id
                                module
                                draft {
                                    ias {
                                        contextualTargeting {
                                            value
                                        }
                                    }
                                    target_app_store_cat
                                    target_app_store_cat_exclude
                                }
                            }
                            ... on POITargeting {
                                id
                                module
                                draft {
                                    geo_targeting_settings {
                                        category_layers
                                        categories
                                        custom_layers
                                        target
                                        retarget
                                        days_to_retarget
                                    }
                                }
                            }
                            ... on AppCategoriesTargeting {
                                id
                                module
                                draft {
                                    target_app_store_cat
                                    target_app_store_cat_exclude
                                }
                            }
                            ... on IASContextualTargeting  {
                                id
                                module
                                draft {
                                    ias {
                                        contextualTargeting {
                                            value
                                        }
                                    }
                                }
                            }
                        }
                    }
                    rotation_rules {
                        mode
                        weighted {
                            id
                            markup_id
                            weight
                        }
                        weather {
                            id
                            markupId
                            isDefault
                            qualifier
                            type
                            amount
                            timeframe
                        }
                        scheduled {
                            id
                            markup_id
                            start
                            end
                            weighted {
                                id
                                markup_id
                                weight
                            }
                        }
                    }
                    metadata {
                        progress {
                            progressData {
                                health
                                goal
                            }
                        }
                        metrics {
                            impressions
                            clicks
                            ctr
                            win_rate
                            billings_local
                            owner_total_media_cost_local
                            conv_overall
                            conv_overall_imp
                            conv_overall_click
                            conv_overall_total_cost_ecpa
                            conv_overall_revenue_ecpa
                        }
                        metricsDaily {
                            owner_total_media_cost_local
                            win_rate
                        }
                        isStale
                        isDelivering
                    }
                }
            }
        `,
        variables: {
            adId: Number(adId),
        },
    });
}

export const makeMiddlewares = deps => {
    return [initMw(deps)];
};

export const middlewares = makeMiddlewares({});

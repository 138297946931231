import _ from 'lodash';
import moment from 'moment';
import * as DateCalculator from './date-calculator';

const PACING_GOAL_TO_PACING_METRIC = {
    impressions: 'impressions',
    clicks: 'clicks',
    spend: 'owner_total_media_cost_local', // Eventually this should be total_media_cost_local (new naming scheme)
    billings: 'billings_local',
};

export default class ProgressCalculator {
    constructor(properties, stats) {
        this.start = properties.start._isAMomentObject
            ? properties.start.toDate()
            : new Date(properties.start);

        this.end = properties.end._isAMomentObject
            ? properties.end.toDate()
            : new Date(properties.end);

        // NOTE: This is hacky because Progress Calculator uses UTC time for all caluculations
        //
        //       Weekparts Local and Dayparts Local does NOT behave like their UTC equivalents
        //       because when the Local targeting is set and the ad targets multiple timezones,
        //       the pacing does not completely stop during that time period
        //
        // TODO: More thought needs to be put into this; Progress Calculator may or may not
        //       need to be rewritten to support this properly
        //
        this.weekparts = properties.weekparts || properties.weekparts_local;
        this.dayparts = properties.dayparts || properties.dayparts_local;

        this.max_total_impressions = properties.max_total_impressions || 0;
        this.max_daily_impressions = properties.max_daily_impressions || 0;
        this.max_total_clicks = properties.max_total_clicks || 0;
        this.max_daily_clicks = properties.max_daily_clicks || 0;
        this.max_total_spend = properties.max_total_spend_local || 0;
        this.max_daily_spend = properties.max_daily_spend_local || 0;
        this.max_total_billings = properties.max_total_billings || 0;
        this.max_daily_billings = properties.max_daily_billings || 0;

        this.data_fees_enabled = properties.data_fees_enabled || false;
        this.primary_pacing = properties.primary_pacing;
        this.stats = stats || [];

        this._cache = {};
    }

    getGoal() {
        return this.primary_pacing;
    }

    getPacingMetric() {
        const goal = this.getGoal();
        return PACING_GOAL_TO_PACING_METRIC[goal];
    }

    getTotalTarget() {
        const goal = this.getGoal();
        return this[`max_total_${goal}`];
    }

    getDailyCap() {
        const goal = this.getGoal();
        return this[`max_daily_${goal}`];
    }

    hasTotalTarget() {
        return this.getTotalTarget() > 0;
    }

    hasDailyCap() {
        return this.getDailyCap() > 0;
    }

    // Returns array of 7 booleans representing whether the ad is active that day
    // Indexed by ISO day of week number
    // e.g. Weekdays only: [false, true, true, ture, true, true, false]
    _getIsoWeekparts() {
        if (!this.weekparts.length) {
            return [true, true, true, true, true, true, true];
        }
        const [monday, tuesday, wednesday, thursday, friday, saturday, sunday] = this.weekparts;
        return [sunday, monday, tuesday, wednesday, thursday, friday, saturday];
    }

    // Date information is dropped here
    // It is assumed that the start and end times are on the same date
    _getActiveSecondsOfTimeRangeInSameDay({ start, end }) {
        if (!this.dayparts.length) {
            return DateCalculator.getNumberOfSecondsInRange({ start, end });
        }

        // Use an abritrary date
        // The date is not important, we're just using to intersect the time
        const arbitraryDate = new Date();

        const rangeStart = new Date(arbitraryDate);
        rangeStart.setUTCHours(start.getUTCHours());
        rangeStart.setUTCMinutes(start.getUTCMinutes());
        rangeStart.setUTCSeconds(start.getUTCSeconds());
        rangeStart.setUTCMilliseconds(0);

        const rangeEnd = new Date(arbitraryDate);
        rangeEnd.setUTCHours(end.getUTCHours());
        rangeEnd.setUTCMinutes(end.getUTCMinutes());
        rangeEnd.setUTCSeconds(end.getUTCSeconds());
        rangeEnd.setUTCMilliseconds(0);

        // `start` and `end` are reusable date objects
        // to be used for intersection only
        const daypartStart = new Date(arbitraryDate);
        const daypartEnd = new Date(arbitraryDate);

        // Dayparting is an array of start/end pairs formatted as a string
        // e.g. for 10AM-12:30PM + 3:45PM-5:00PM
        // [
        //     { start: '10:00:00', end: '12:30:00' },
        //     { start: '15:45:00', end: '17:00:00' },
        // ]
        const activeSeconds = this.dayparts.reduce((accumulatedSeconds, daypart) => {
            const startHour = parseInt(daypart.start.slice(0, 2));
            const startMinute = parseInt(daypart.start.slice(3, 5));
            const startSecond = parseInt(daypart.start.slice(6, 8));
            daypartStart.setUTCHours(startHour);
            daypartStart.setUTCMinutes(startMinute);
            daypartStart.setUTCSeconds(startSecond);

            const endHour = parseInt(daypart.end.slice(0, 2));
            const endMinute = parseInt(daypart.end.slice(3, 5));
            const endSecond = parseInt(daypart.end.slice(6, 8));
            daypartEnd.setUTCHours(endHour);
            daypartEnd.setUTCMinutes(endMinute);
            daypartEnd.setUTCSeconds(endSecond);

            const activePart = DateCalculator.getIntersection(
                { start: rangeStart, end: rangeEnd },
                { start: daypartStart, end: daypartEnd }
            );
            if (!activePart) {
                return accumulatedSeconds;
            }
            const secondsInActivePart = DateCalculator.getNumberOfSecondsInRange(activePart);

            return accumulatedSeconds + secondsInActivePart;
        }, 0);

        return activeSeconds;
    }

    // Similar to ProgressCalculator#_getActiveSecondsOfTimeRangeInSameDay,
    // but assumes a "typical" day, where the range start and end are
    // 00:00:00.000 and 23:59:59.999, so some calculations can be done more efficiently
    _getActiveSecondsOfTypicalActiveDay() {
        if (this._cache.secondsOfTypicalActiveDay) {
            return this._cache.secondsOfTypicalActiveDay;
        }

        if (!this.dayparts.length) {
            return 24 * 60 * 60;
        }

        // `start` and `end` are reusable date objects
        // to be used for intersection only
        // Use an abritrary date
        // The date is not important, we're just using to intersect the time
        const start = new Date();
        const end = new Date();

        // Dayparting is an array of start/end pairs formatted as a string
        // e.g. for 10AM-12:30PM + 3:45PM-5:00PM
        // [
        //     { start: '10:00:00', end: '12:30:00' },
        //     { start: '15:45:00', end: '17:00:00' },
        // ]
        const activeSeconds = this.dayparts.reduce((accumulatedSeconds, daypart) => {
            const startHour = parseInt(daypart.start.slice(0, 2));
            const startMinute = parseInt(daypart.start.slice(3, 5));
            const startSecond = parseInt(daypart.start.slice(6, 8));
            start.setUTCHours(startHour);
            start.setUTCMinutes(startMinute);
            start.setUTCSeconds(startSecond);

            const endHour = parseInt(daypart.end.slice(0, 2));
            const endMinute = parseInt(daypart.end.slice(3, 5));
            const endSecond = parseInt(daypart.end.slice(6, 8));
            end.setUTCHours(endHour);
            end.setUTCMinutes(endMinute);
            end.setUTCSeconds(endSecond);

            const secondsInDaypart = DateCalculator.getNumberOfSecondsInRange({ start, end });
            return accumulatedSeconds + secondsInDaypart;
        }, 0);

        this._cache.secondsOfTypicalActiveDay = activeSeconds;

        return activeSeconds;
    }

    _getTotalActiveSecondsOfRange(range) {
        const { start: rangeStart, end: rangeEnd } = range;

        const weekparts = this._getIsoWeekparts();

        const typicalActiveSeconds = this._getActiveSecondsOfTypicalActiveDay();

        const flightDays = DateCalculator.getDayIterator(range);
        const lastFlightDayIndex = flightDays.length - 1;

        const totalActiveSeconds = flightDays.reduce((accumulator, flightDay, index) => {
            const dayOfWeek = flightDay.getUTCDay();
            const dayIsActive = weekparts[dayOfWeek];
            if (!dayIsActive) {
                return accumulator;
            }

            // Special case for first day and last day which are atypical because
            // they could be incomplete and therefore require additional intersection
            if (index === 0) {
                const firstDay = {
                    start: rangeStart,
                    end: DateCalculator.getUtcEndOfDay(rangeStart),
                };
                const projectablePartOfFirstDay = DateCalculator.getIntersection(range, firstDay);
                if (!projectablePartOfFirstDay) {
                    return accumulator;
                }
                return (
                    accumulator +
                    this._getActiveSecondsOfTimeRangeInSameDay(projectablePartOfFirstDay)
                );
            } else if (index === lastFlightDayIndex) {
                const lastDayRange = {
                    start: DateCalculator.getUtcStartOfDay(rangeEnd),
                    end: rangeEnd,
                };
                return accumulator + this._getActiveSecondsOfTimeRangeInSameDay(lastDayRange);

                // Case for typical days
                // Typical day = any day which is not the start or end date
            } else {
                return accumulator + typicalActiveSeconds;
            }
        }, 0);

        return totalActiveSeconds;
    }

    getIdealPacedFillForEndOfSecond(projectionDate) {
        if (!this.hasTotalTarget()) {
            return null;
        }

        let secondsTotal;
        if (this._cache.totalActiveSeconds) {
            secondsTotal = this._cache.totalActiveSeconds;
        } else {
            const flightRange = {
                start: this.start,
                end: this.end,
            };
            secondsTotal = this._getTotalActiveSecondsOfRange(flightRange);
            this._cache.totalActiveSeconds = secondsTotal;
        }

        const projectionRange = {
            start: this.start,
            end: projectionDate,
        };
        let secondsElapsed = this._getTotalActiveSecondsOfRange(projectionRange);
        secondsElapsed = Math.min(secondsElapsed, secondsTotal);

        const idealPacedFill = this.getTotalTarget() * (secondsElapsed / secondsTotal);
        return idealPacedFill;
    }

    getPacedFillHealthForEndOfSecond(projectionDate, projectionDateFill) {
        return this.healthAlgorithm_v2(projectionDate, projectionDateFill);
    }

    healthAlgorithm_v1(projectionDate, projectionDateFill) {
        if (!this.hasTotalTarget()) {
            return null;
        }
        if (projectionDate.valueOf() < this.start.valueOf()) {
            return 'pending';
        }

        const idealFilled = this.getIdealPacedFillForEndOfSecond(projectionDate);

        const idealRemaining = this.getTotalTarget() - idealFilled;

        const goodFillThreshold = idealFilled - idealRemaining * 0.1;
        const okFillThreshold = idealFilled - idealRemaining * 0.2;

        if (projectionDateFill >= goodFillThreshold) {
            return 'good';
        }

        if (projectionDateFill >= okFillThreshold) {
            return 'ok';
        }

        return 'bad';
    }

    healthAlgorithm_v2(projectionDate, projectionDateFill) {
        if (!this.hasTotalTarget()) {
            return null;
        }
        if (projectionDate.valueOf() < this.start.valueOf()) {
            return 'pending';
        }

        const idealFilled = this.getIdealPacedFillForEndOfSecond(projectionDate);

        // Should not have started yet
        if (idealFilled === 0) {
            return 'pending';
        }

        const numberOfSecondsInAd = DateCalculator.getNumberOfSecondsInRange({
            start: this.start,
            end: this.end,
        });
        const numberOfSecondsRemaining = DateCalculator.getNumberOfSecondsInRange({
            start: new Date(),
            end: this.end,
        });
        const percentageTimeRemaining = numberOfSecondsRemaining / numberOfSecondsInAd;

        const GOOD_FILL_THRESHOLD_PERCENT = 0.1;
        const OK_FILL_THRESHOLD_PERCENT = 0.2;

        const goodFillThreshold =
            idealFilled * (1 - GOOD_FILL_THRESHOLD_PERCENT * percentageTimeRemaining);
        const okFillThreshold =
            idealFilled * (1 - OK_FILL_THRESHOLD_PERCENT * percentageTimeRemaining);

        if (projectionDateFill < okFillThreshold) {
            return 'bad';
        } else if (projectionDateFill < goodFillThreshold) {
            return 'ok';
        } else {
            return 'good';
        }
    }

    getIdealUnpacedFillForEndOfSecond(projectionDate, yesterday, yesterdayFilled) {
        if (!this.hasDailyCap()) {
            return null;
        }

        const startOfYesterday = DateCalculator.getUtcStartOfDay(yesterday);
        const startOfToday = new Date(startOfYesterday.valueOf() + 24 * 60 * 60 * 1000);

        // If the ad has started, project from today onwards;
        // If the ad is scheduled/pending, project from start day onwards.
        const startOfProjectableRange = new Date(Math.max(this.start, startOfToday));

        const endOfEndDate = DateCalculator.getUtcEndOfDay(this.end);

        if (projectionDate.valueOf() < startOfProjectableRange.valueOf()) {
            return null;
        }

        const weekparts = this._getIsoWeekparts();

        const projectableDays = DateCalculator.getDayIterator({
            start: startOfProjectableRange,
            end: endOfEndDate,
        });
        const projectedUnpacedFill = projectableDays.reduce((accumulator, flightDay) => {
            if (flightDay.valueOf() > projectionDate.valueOf()) {
                return accumulator;
            }
            const dayOfWeek = flightDay.getUTCDay();
            const dayIsActive = weekparts[dayOfWeek];
            if (!dayIsActive) {
                return accumulator;
            }
            return accumulator + this.getDailyCap();
        }, yesterdayFilled);

        return projectedUnpacedFill;
    }

    getHourlyIdealPacedFillDataSeries() {
        if (!this.hasTotalTarget()) {
            return [];
        }

        const range = { start: this.start, end: this.end };
        const endOfHourPoints = DateCalculator.getEndOfHourIterator(range);

        const start = DateCalculator.clone(this.start);
        const end = DateCalculator.clone(this.end);

        const mapping = {
            [start.toISOString()]: 0,
            [end.toISOString()]: this.getTotalTarget(),
        };
        const dataSeries = [
            // Add start date (hardcoded value)
            {
                date: start,
                value: 0,
            },
            // Main data points
            ...endOfHourPoints.map(date => {
                const idealPacedFill = this.getIdealPacedFillForEndOfSecond(date);
                mapping[date.toISOString()] = idealPacedFill;
                return {
                    date: date,
                    value: idealPacedFill,
                };
            }),
            // Add end date (hardcoded value)
            {
                date: end,
                value: this.getTotalTarget(),
            },
        ];

        return {
            mapping,
            dataSeries,
        };
    }

    getHourlyIdealUnpacedFillDataSeries() {
        if (!this.hasDailyCap()) {
            return { mapping: null, dataSeries: [] };
        }

        const flightRange = { start: this.start, end: this.end };
        if (DateCalculator.getRangePositionToNow(flightRange) === 'past') {
            return { mapping: null, dataSeries: [] };
        }

        const today = new Date();
        const startOfToday = DateCalculator.getUtcStartOfDay(today);
        const yesterday = new Date(today.valueOf() - 24 * 60 * 60 * 1000);

        const projectionRange = {
            start: DateCalculator.max(startOfToday, this.start),
            end: this.end,
        };
        const dayPoints = DateCalculator.getDayIterator(projectionRange);

        // Everything except today's stats
        const pacingMetric = this.getPacingMetric();
        const todayDateString = DateCalculator.getIsoDateString(today);
        const yesterdayFilled = _(this.stats)
            .reject({ date: todayDateString })
            .reduce((filled, record) => filled + record[pacingMetric], 0);

        const mapping = {};
        const dataSeries = dayPoints.map((day, index) => {
            const endOfDay = DateCalculator.getUtcEndOfDay(day);
            const idealUnpacedFill = this.getIdealUnpacedFillForEndOfSecond(
                endOfDay,
                yesterday,
                yesterdayFilled
            );

            const startOfDay = DateCalculator.getUtcStartOfDay(day);
            const capStart =
                index === 0
                    ? // If first point, make sure it does not go beyond start date
                      DateCalculator.max(startOfDay, this.start)
                    : // Otherwise, so just use start of date
                      startOfDay;

            const capEnd =
                index === dayPoints.length - 1
                    ? // If last point, make sure it does not go beyond end date
                      DateCalculator.min(endOfDay, this.end)
                    : // Otherwise, so just use end of date
                      endOfDay;

            return [
                ...DateCalculator.getEndOfHourIterator(
                    {
                        start: capStart,
                        end: capEnd,
                    },
                    {
                        includeEndpoints: true,
                    }
                ).map(date => {
                    const dateString = date.toISOString();
                    mapping[dateString] = idealUnpacedFill;
                    return {
                        date,
                        value: idealUnpacedFill,
                    };
                }),
            ];
        });

        return {
            mapping,
            dataSeries,
        };
    }

    getHourlyFillDataSeries() {
        const now = new Date();

        // Data series is the plotted range, only include delivered range
        const dataSeriesRange = { start: this.start, end: DateCalculator.min(now, this.end) };

        // Mapping contains all data, for tooltip, etc
        const mappingRange = { start: this.start, end: this.end };

        // If flight is in the future, short circuit logic
        if (DateCalculator.getRangePositionToNow(mappingRange) === 'future') {
            return [];
        }

        // Map stats into { ISOString: delivered } for fast lookup
        const pacingMetric = this.getPacingMetric();
        const deliveryMapping = this.stats.reduce(
            (accumulator, item, index) => {
                const isoDate = item.date;
                const isoHour = (Number(item.hour) < 10 ? '0' : '') + item.hour;

                let isoString = `${isoDate}T${isoHour}:59:59.999Z`;

                accumulator[isoString] = item[pacingMetric];
                return accumulator;
            },
            {},
            this
        );

        // Used to accumulate fill (stops updating after `now` is reached)
        let fill = 0;

        const endOfHourIterator = DateCalculator
            // Return array of date objects, already sorted and ready to accumulate
            .getEndOfHourIterator(mappingRange, {
                includeEndpoints: true,
            });

        // Populate fill mapping
        const fillMapping = endOfHourIterator.reduce((accumulator, date, index) => {
            const dateString = date.toISOString();

            let delivery;
            if (index === endOfHourIterator.length - 1) {
                delivery = deliveryMapping[`${dateString.substring(0, 13)}:59:59.999Z`] || 0;
                fill += delivery;
            } else {
                delivery = deliveryMapping[dateString] || 0;
                fill += delivery;
            }
            accumulator[dateString] = { delivery, fill };
            return accumulator;
        }, {});

        // Populate data series from the fill mapping
        // Since dataSeries is a subset of fill mapping, almost every point
        // should exist in fill mapping. Exception is the last point since
        // dataSeriesRange only covers up to `now` which is probably not at
        // the end of the hour, so make special cases for first and last points.
        const dataSeries = [
            {
                date: this.start,
                value: 0,
            },
            ...DateCalculator.getEndOfHourIterator(dataSeriesRange).map(date => {
                return {
                    date,
                    value: fillMapping[date.toISOString()].fill,
                };
            }),
            {
                // If the ad has not ended, add an extra point for 'now' to represent the
                // total sum of all stats. If the ad has already ended, the end date will be used
                // to represent the total sum of stats.
                date: dataSeriesRange.end,
                value: fill,
            },
        ];

        return {
            dataSeries,
            mapping: fillMapping,
        };
    }

    getHourlyIntervals() {
        const range = { start: this.start, end: this.end };
        const dataPoints = DateCalculator.getEndOfHourIterator(range, { includeEndpoints: true });

        const dataSeries = [];
        const mapping = dataPoints.reduce((accumulator, date, index) => {
            const prevDate = dataPoints[index - 1];
            const nextDate = dataPoints[index + 1];

            const startBoundary = prevDate
                ? // If there's a previous point, start boundary
                  // is the midpoint of previous point and this point
                  DateCalculator.getMidpoint(prevDate, date)
                : // If there's no previous point, start boundary
                  // is this point
                  date;

            const endBoundary = nextDate
                ? // If there's a previous point, end boundary
                  // is the midpoint of previous point and this point
                  DateCalculator.getMidpoint(date, nextDate)
                : // If there's no previous point, end boundary
                  // is this point
                  date;

            accumulator[date.toISOString()] = {
                start: startBoundary,
                end: endBoundary,
            };

            dataSeries.push({
                date,
                start: startBoundary,
                end: endBoundary,
            });

            return accumulator;
        }, {});

        return {
            mapping,
            dataSeries,
        };
    }
}

// window.ProgressCalculator = ProgressCalculator;
// window.DateCalculator = DateCalculator;

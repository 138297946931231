import get from 'lodash/get';
import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
const http = createHttp();
import Campaigns from 'states/resources/campaigns/actions';

export function fetchCampaignData(campaignId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.CAMPAIGN__ACCESS__INIT,
            payload: { campaignId },
        });

        const campaign = get(getState(), `resources.campaigns.${campaignId}.attr`);
        const thisUsersOrg = get(getState(), `profile.organizationId`);

        // We already fetched the campaign? No need to refetch
        if (campaign) {
            // If the user's org owns the campaign we've seen enough.
            if (campaign.organization === thisUsersOrg) {
                return dispatch({
                    type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                    payload: {
                        isLoading: false,
                        campaignId,
                        accessGranted: true,
                    },
                });
            }

            // If not, we have to identify the relationship of the user to the campaign.
            return http.get(`organizations/${campaign.organization}/summary`).then(
                org => {
                    // Ancestor? Access denied
                    dispatch({
                        type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                        payload: {
                            isLoading: false,
                            ownerName: org.name,
                            campaignId,
                            accessGranted: false,
                        },
                    });
                },
                () => {
                    // This user has access to the campaign summary, but not to the
                    // owning org's summary making them a client. Access granted
                    dispatch({
                        type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                        payload: {
                            isLoading: false,
                            campaignId,
                            accessGranted: true,
                        },
                    });
                }
            );
        }

        return (
            dispatch(Campaigns.get(campaignId))
                // If the user has access to the full campaign, we've seen enough.
                .then(
                    () => {
                        dispatch({
                            type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                            payload: {
                                isLoading: false,
                                campaignId,
                                accessGranted: true,
                            },
                        });
                    },
                    () => {
                        // If the user has access to the campaign summary, we need to determine
                        // if they belong to an ancestor or a client org
                        return dispatch(Campaigns.getOneSummary(campaignId)).then(
                            summary => {
                                if (summary.organization === thisUsersOrg) {
                                    // Owner? Access granted
                                    return dispatch({
                                        type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                                        payload: {
                                            isLoading: false,
                                            campaignId,
                                            accessGranted: true,
                                        },
                                    });
                                }

                                http.get(`organizations/${summary.organization}/summary`).then(
                                    org => {
                                        // Ancestor? Access denied
                                        dispatch({
                                            type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                                            payload: {
                                                isLoading: false,
                                                ownerName: org.name,
                                                campaignId,
                                                accessGranted: false,
                                            },
                                        });
                                    },
                                    () => {
                                        // This user has access to the campaign summary, but not to the
                                        // owning org's summary making them a client. Access granted
                                        dispatch({
                                            type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                                            payload: {
                                                isLoading: false,
                                                campaignId,
                                                accessGranted: true,
                                            },
                                        });
                                    }
                                );
                            },
                            () => {
                                dispatch({
                                    type: c.CAMPAIGN__ACCESS__INIT_COMPLETE,
                                    payload: {
                                        isLoading: false,
                                        campaignId,
                                        accessGranted: false,
                                    },
                                });
                            }
                        );
                    }
                )
        );
    };
}

export function unInitCampaign() {
    return {
        type: 'CAMPAIGN__ACCESS__UNINIT',
    }
}

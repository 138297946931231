import c from 'common/constants/flux-events';

const defaultState = {
    isLoading: true,
    previouslyLoaded: false,
    currentFlightId: null,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'CAMPAIGN_DELIVERY__TIMEZONE_CHANGE': {
            return {
                ...state,
                timezone: action.payload.timezone,
            };
        }
        case c.CAMPAIGN_DELIVERY__LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case c.CAMPAIGN_DELIVERY__INIT: {
            return {
                ...state,
                isLoading: false,
                previouslyLoaded: true,
                campaignFromGraphql: action.payload.campaignFromGraphql,
                campaignMetadata: action.payload.campaignData,
                currentFlight: action.payload.currentFlight,
                liveFlight: action.payload.liveFlight,
                timezone: action.payload.defaultTimezone,
            };
        }
        case 'SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH_ALL__SUCCESS': {
            return {
                ...state,
            };
        }
        case 'CAMPAIGN_DELIVERY__RESET_STATE': {
            return defaultState;
        }
        case 'UPDATE_FLIGHT_ID': {
            return {
                ...state,
                currentFlightId: action.payload.flightId,
            };
        }
        default: {
            return state;
        }
    }

    return state;
}

export const NAME = 'campaignProgress';

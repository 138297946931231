import _ from 'lodash';

import c from 'common/constants/flux-events';
import flags from 'containers/flags/service';
import { getDefaults, validateAdPresetDraft } from 'services/resources/ad-preset';

const initialState = {
    draft: {},
    defaultValues: getDefaults(),
    searchString: '',
    organization: null,
    isDraftValid: false,
    errors: [],
    poiViewer: null,
    showErrors: false,
    isLoading: true,
    isSubmiting: false,
    isDirty: false,
    isFormOpen: false,
};

export const NAME = 'adPresetEdit';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.EDIT_AD_PRESET__DRAFT__OPEN: {
            return {
                ...state,
                isFormOpen: true,
                isLoading: true,
            };
        }

        case c.EDIT_AD_PRESET__DRAFT__INIT: {
            const sessionDraft_current = _.get(state, `draft`, {});
            let sessionDraft_next = sessionDraft_current;
            let isOldForm = false;
            let refactoredForm = [...getDefaults().ad_preset_properties];
            if (_.isEmpty(sessionDraft_current)) {
                if (
                    (_.filter(action, 'payload.adpreset.ad_preset_properties'),
                    item => {
                        item.groupName === 'Audience';
                    }).length > 0
                ) {
                    isOldForm = true;
                    const allProperties = _.flatten(
                        _.map(_.get(action, 'payload.adpreset.ad_preset_properties'), item => {
                            const allProps = [];
                            _.forEach(item.properties, prop => {
                                allProps.push(prop);
                            });
                            return allProps;
                        })
                    );
                    _.forEach(refactoredForm, item => {
                        var presetIndex = _.findIndex(refactoredForm, item);
                        _.forEach(item.properties, props => {
                            var propIndex = _.findIndex(
                                refactoredForm[presetIndex].properties,
                                props
                            );
                            _.forEach(allProperties, prop => {
                                if (prop.key === props.key) {
                                    refactoredForm[presetIndex].properties[propIndex] = prop;
                                }
                            });
                        });
                    });
                }

                const enabledAdPresetProperties = _.flatten(
                    _.map(_.get(action, 'payload.adpreset.ad_preset_properties'), item => {
                        const enabledProps = [];
                        _.forEach(item.properties, prop => {
                            if (!prop.checked) {
                                return;
                            }

                            if (prop.key === 'audiences') {
                                enabledProps.push('geo_targeting_settings');
                            }

                            if (prop.fields && prop.fields.length > 0) {
                                enabledProps.push(...prop.fields);
                            } else {
                                enabledProps.push(prop.key);
                            }
                        });
                        return enabledProps;
                    })
                );

                sessionDraft_next = _.pick(
                    isOldForm
                        ? {
                              ..._.get(action, 'payload.adpreset'),
                              ad_preset_properties: refactoredForm,
                          }
                        : _.get(action, 'payload.adpreset'),
                    _.filter(_.keys(state.defaultValues), ky => {
                        return (
                            enabledAdPresetProperties.indexOf(ky) > -1 ||
                            ['ad_preset_properties', 'ad_preset_name'].indexOf(ky) > -1
                        );
                    })
                );
            }

            const errors = validateAdPresetDraft({ ...sessionDraft_next });

            return {
                ...state,
                errors,
                organization: _.get(action, 'payload.organization'),
                draft: sessionDraft_next,
                isLoading: false,
            };
        }

        case c.EDIT_AD_PRESET__DRAFT__UPDATE: {
            const { formData } = action.payload;
            const nextDraft = {
                ...state.draft,
                ...formData,
            };
            const errors = validateAdPresetDraft(nextDraft);
            return {
                ...state,
                draft: nextDraft,
                isDirty: true,
                errors,
                isDraftValid: errors.length === 0,
            };
        }

        case c.EDIT_AD_PRESET__DRAFT__SHOW_ERRORS: {
            return {
                ...state,
                showErrors: true,
            };
        }

        case c.EDIT_AD_PRESET__DRAFT__SUBMIT: {
            return {
                ...state,
                isSubmitting: true,
            };
        }

        case c.EDIT_AD_PRESET__DRAFT__SUBMIT_FAIL: {
            const httpErrors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));

            return {
                ...state,
                errors: httpErrors,
                showErrors: true,
                isSubmiting: false,
                isDraftValid: false,
            };
        }

        case c.EDIT_AD_PRESET__DRAFT__SUBMIT_SUCCESS:
            return {
                ...state,
                isSubmiting: false,
                isLoading: false,
                isFormOpen: false,
            };

        case c.EDIT_AD_PRESET__DRAFT__CLOSE: {
            return {
                ...initialState,
                isLoading: false,
                isFormOpen: false,
            };
        }

        default:
            return state;
    }
}

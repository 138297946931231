import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';

class InteractiveItem extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.node.isRequired,
        value: PropTypes.string,
        href: PropTypes.string,
        link: PropTypes.string,
        hidden: PropTypes.bool,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        hidden: false,
    };

    render() {
        if (this.props.hidden) {
            return null;
        }

        if (this.props.href) {
            return (
                <a href={this.props.href} target="_blank" className={this.props.className}>
                    {this.props.label}
                </a>
            );
        }

        if (this.props.link) {
            return (
                <Link to={this.props.link} className={this.props.className}>
                    {this.props.label}
                </Link>
            );
        }

        if (this.props.onClick) {
            return (
                <button onClick={this.props.onClick} className={this.props.className}>
                    {this.props.label}
                </button>
            );
        }

        return <div>{this.props.label}</div>;
    }
}

export default InteractiveItem;

import React from 'react';
import map from 'lodash/map';

import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { HtmlTooltip } from 'widgets-v6/tooltip';
import MultilineInput from 'widgets-v5/multiline-input';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import CreativeAssetSelector from '../creative-asset-selector/index';
import ClickthroughCard from '../click-through-card/click-through-card';
import {
    CreativeVendorSelector,
    TrackingVendorsSelector,
} from '../vendor-selector/vendor-selector';
import { creativeTrackingVendorsMapping, StyledLink } from '../../index';
import { TextField, TextFieldInStrOutInt } from 'widgets-v6/standard-input';

const useStyles = makeStyles(theme => ({
    image: {
        width: 70,
    },
    thirdPartyVendors: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function CreativeFormNativeType(props) {
    const classes = useStyles();

    const { errors, draft, onFieldChange, activeStep, showErrors, updateVendors } = props;

    const textFieldRef = React.useRef(null);

    const errorsForIconImage = []
        .concat(errors.icon_image_url, errors.icon_image_preview_url)
        .filter(error => error);

    const errorsForMainImage = []
        .concat(errors.main_image_url, errors.main_image_preview_url)
        .filter(error => error);

    const errorsForContent = []
        .concat(
            errors.title,
            errors.text,
            errors.sponsoredBy,
            errors.call_to_action,
            errors.star_rating
        )
        .filter(error => error);

    const landingPageClickthroughErrors = [].concat(errors.clickthrough_url).filter(error => error);

    const landingPageErrors = [].concat(errors.landing_page).filter(error => error);

    if (activeStep === 1) {
        return (
            <div>
                <AccordionItem
                    onClick={props.expandPanel('iconImage')}
                    expanded={props.expandedPanel === 'iconImage'}
                    title="Icon Image"
                    required
                    error={showErrors && errorsForIconImage.length > 0}
                    details={
                        <React.Fragment>
                            <CreativeAssetSelector
                                mode={props.mode}
                                uploadFile={props.uploadFile}
                                errors={errors}
                                assets={props.assets}
                                draft={draft}
                                onFieldChange={onFieldChange}
                                onMultiFieldChange={props.onMultiFieldChange}
                                inputUrlFieldsType="nativeIconImage"
                                showErrors={props.showErrors}
                                areAssetsLoading={props.areAssetsLoading}
                                onChangeAssetSelectionMethod={props.onChangeAssetSelectionMethod}
                                uploadAssetsErrorMessage={props.uploadAssetsErrorMessage}
                                hideUploadAssetsErrorMessage={props.hideUploadAssetsErrorMessage}
                            />
                            {draft.assetSelectionMethod === 'inputByUrl' && (
                                <CreativeVendorSelector
                                    showErrors={showErrors}
                                    errors={errors}
                                    draft={draft}
                                    onFieldChange={onFieldChange}
                                    updateVendors={updateVendors}
                                    creativeTagsMatched={props.creativeTagsMatched}
                                    creativeType={'native'}
                                />
                            )}
                        </React.Fragment>
                    }
                    summary={
                        <div>
                            <div>
                                {' '}
                                {draft.icon_image_preview_url ? (
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <img
                                                className={classes.image}
                                                src={draft.icon_image_preview_url}
                                                alt=""
                                            />
                                        </Grid>
                                        <Grid item>
                                            {props.creativeMapping[draft.icon_image_preview_url]}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Typography color="textSecondary">Add icon image</Typography>
                                )}{' '}
                            </div>
                            <div>
                                {' '}
                                {draft.creative_vendors.length > 0 ? (
                                    <div className={classes.thirdPartyVendors}>
                                        {map(draft.creative_vendors, vendor => {
                                            return (
                                                <Chip
                                                    key={vendor}
                                                    label={
                                                        creativeTrackingVendorsMapping[vendor] ||
                                                        vendor
                                                    }
                                                    variant="outlined"
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add creative vendor
                                    </Typography>
                                )}{' '}
                            </div>
                        </div>
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('mainImage')}
                    expanded={props.expandedPanel === 'mainImage'}
                    title="Main Image"
                    required
                    error={showErrors && errorsForMainImage.length > 0}
                    details={
                        <React.Fragment>
                            <CreativeAssetSelector
                                mode={props.mode}
                                uploadFile={props.uploadFile}
                                errors={errors}
                                assets={props.assets}
                                draft={draft}
                                onFieldChange={onFieldChange}
                                onMultiFieldChange={props.onMultiFieldChange}
                                inputUrlFieldsType="nativeMainImage"
                                showErrors={props.showErrors}
                                areAssetsLoading={props.areAssetsLoading}
                                onChangeAssetSelectionMethod={props.onChangeAssetSelectionMethod}
                                uploadAssetsErrorMessage={props.uploadAssetsErrorMessage}
                                hideUploadAssetsErrorMessage={props.hideUploadAssetsErrorMessage}
                            />
                            {draft.assetSelectionMethod === 'inputByUrl' && (
                                <CreativeVendorSelector
                                    showErrors={showErrors}
                                    errors={errors}
                                    draft={draft}
                                    onFieldChange={onFieldChange}
                                    updateVendors={updateVendors}
                                    creativeTagsMatched={props.creativeTagsMatched}
                                    creativeType={'native'}
                                />
                            )}
                        </React.Fragment>
                    }
                    summary={
                        <div>
                            <div>
                                {draft.main_image_preview_url ? (
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <img
                                                className={classes.image}
                                                src={draft.main_image_preview_url}
                                                alt=""
                                            />
                                        </Grid>
                                        <Grid item>
                                            {props.creativeMapping[draft.main_image_preview_url]}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Typography color="textSecondary">Add main image</Typography>
                                )}
                            </div>
                            <div>
                                {' '}
                                {draft.creative_vendors.length > 0 && draft.creative_vendors[0] ? (
                                    <div className={classes.thirdPartyVendors}>
                                        {map(draft.creative_vendors, vendor => {
                                            return (
                                                <Chip
                                                    key={vendor}
                                                    label={
                                                        creativeTrackingVendorsMapping[vendor] ||
                                                        vendor
                                                    }
                                                    variant="outlined"
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add creative vendor
                                    </Typography>
                                )}{' '}
                            </div>
                        </div>
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('content')}
                    expanded={props.expandedPanel === 'content'}
                    title="Content"
                    required
                    error={showErrors && errorsForContent.length > 0}
                    details={
                        <Box display="flex" flexDirection="column">
                            <TextField
                                defaultValue={draft.title}
                                onChange={e => onFieldChange('title')(e.target.value)}
                                required
                                label="Ad Title"
                                error={showErrors && errors.title}
                                placeholder="Enter Ad Title"
                                maxLength={25}
                                helperText="25 characters (includes spaces)"
                            />
                            <TextField
                                defaultValue={draft.text}
                                onChange={e => onFieldChange('text')(e.target.value)}
                                required
                                label="Main Text"
                                error={showErrors && errors.text}
                                placeholder="Enter Main Text"
                                maxLength={100}
                                helperText="100 characters (includes spaces)"
                            />
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">
                                            NEW: "Sponsored By" Is Now Required
                                        </Typography>
                                        <em>
                                            {
                                                'The brand name will be displayed in the creative in order to help users identify the advertiser.'
                                            }
                                        </em>
                                    </React.Fragment>
                                }
                                open={true}
                                placement="left"
                                arrow
                            >
                                <div>
                                    <TextField
                                        defaultValue={draft.sponsoredBy}
                                        onChange={e =>
                                            onFieldChange('sponsoredBy')(e.target.value)
                                        }
                                        required
                                        label="Sponsored By"
                                        errorText={
                                            showErrors &&
                                            errors.sponsoredBy &&
                                            errors.sponsoredBy
                                        }
                                        error={showErrors && errors.text}
                                        placeholder="Enter Sponsor"
                                        maxLength={100}
                                        inputRef={textFieldRef}
                                        fullWidth={true}
                                    />
                                </div>
                            </HtmlTooltip>
                            <TextField
                                defaultValue={draft.call_to_action}
                                onChange={e => onFieldChange('call_to_action')(e.target.value)}
                                required
                                label="Call To Action"
                                error={showErrors && errors.call_to_action}
                                placeholder="Enter Call To Action"
                                maxLength={15}
                                helperText="15 characters (includes spaces)"
                            />
                            <TextFieldInStrOutInt
                                value={draft.star_rating}
                                onChange={onFieldChange('star_rating')}
                                required
                                label="Star Rating"
                                error={showErrors && errors.star_rating}
                                placeholder="Enter Star Rating"
                                maxLength={1}
                                formatIn={value => {
                                    if (!value) {
                                        return '';
                                    } else {
                                        return value.toString();
                                    }
                                }}
                                formatOut={string => {
                                    if (string === '') {
                                        return null;
                                    } else {
                                        return parseInt(string);
                                    }
                                }}
                                shouldAllowChange={input => {
                                    // Allow empty input
                                    if (!input) {
                                        return true;
                                    }
                                    // Check if all digits
                                    const isDigits = /^\d*$/.test(input);
                                    if (isDigits) return true;
                                    return false;
                                }}
                            />
                        </Box>
                    }
                    summary={
                        draft.title ||
                        draft.text ||
                        draft.sponsoredBy ||
                        draft.call_to_action ||
                        draft.star_rating ? (
                            <div>
                                {draft.title ? (
                                    <div>Title: {draft.title}</div>
                                ) : (
                                    <Typography color="textSecondary">Add title</Typography>
                                )}
                                {draft.text ? (
                                    <div>Main Text: {draft.text}</div>
                                ) : (
                                    <Typography color="textSecondary">Add main text</Typography>
                                )}
                                {draft.sponsoredBy ? (
                                    <div>Sponsored By: {draft.sponsoredBy}</div>
                                ) : (
                                    <Typography color="textSecondary">Add sponsor</Typography>
                                )}
                                {draft.call_to_action ? (
                                    <div>Call to Action: {draft.call_to_action}</div>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add call to action
                                    </Typography>
                                )}
                                {draft.star_rating ? (
                                    <div>Star Rating: {draft.star_rating}</div>
                                ) : (
                                    <Typography color="textSecondary">Add star rating</Typography>
                                )}
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add content</Typography>
                        )
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('thirdPartyClicktracker')}
                    expanded={props.expandedPanel === 'thirdPartyClicktracker'}
                    title="Clicktracking"
                    required
                    error={
                        showErrors &&
                        (landingPageClickthroughErrors.length > 0 || landingPageErrors.length > 0)
                    }
                    details={
                        <React.Fragment>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={draft.third_party_clickthrough}
                                            color="primary"
                                            onChange={() => {
                                                onFieldChange('third_party_clickthrough')(
                                                    !draft.third_party_clickthrough
                                                );
                                            }}
                                            value={draft.third_party_clickthrough}
                                        />
                                    }
                                    label="Use Third-party Clicktracker"
                                />
                            </FormControl>
                            <Divider />
                            <ClickthroughCard
                                draft={props.draft}
                                errors={props.errors}
                                onFieldChange={onFieldChange}
                                showErrors={props.showErrors}
                            />
                        </React.Fragment>
                    }
                    summary={
                        <div>
                            <Box mb={1}>
                                {draft.third_party_clickthrough
                                    ? 'Use Third-party Clicktracker'
                                    : "Don't use Third-party Clicktracker"}
                            </Box>
                            {draft.landing_page || draft.clickthrough_url ? (
                                draft.third_party_clickthrough ? (
                                    <div>
                                        {draft.clickthrough_url && (
                                            <div>
                                                <Typography color="textSecondary">
                                                    Third-Party Clicktracker URL
                                                </Typography>
                                                <div>{draft.clickthrough_url}</div>
                                            </div>
                                        )}
                                        {draft.landing_page && (
                                            <div>
                                                <Typography color="textSecondary">
                                                    Landing Page
                                                </Typography>
                                                <div>{draft.landing_page}</div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    draft.landing_page && (
                                        <div>
                                            <Typography color="textSecondary">
                                                Landing Page
                                            </Typography>
                                            <div>{draft.landing_page}</div>
                                        </div>
                                    )
                                )
                            ) : (
                                <Typography color="textSecondary">
                                    {draft.third_party_clickthrough
                                        ? 'Add third-party clicktracker URL and landing page'
                                        : 'Add landing page'}
                                </Typography>
                            )}
                        </div>
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('thirdPartyPixels')}
                    expanded={props.expandedPanel === 'thirdPartyPixels'}
                    title="Third Party Pixels"
                    error={showErrors && errors.third_party_pixels}
                    details={
                        <MultilineInput
                            onChange={onFieldChange('third_party_pixels')}
                            inputs={props.draft.third_party_pixels}
                            addText="Add Pixel"
                        />
                    }
                    summary={
                        draft.third_party_pixels.length > 0 ? (
                            <div>
                                {map(draft.third_party_pixels, pixel => (
                                    <StyledLink key={pixel}>{pixel}</StyledLink>
                                ))}
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add third party pixels</Typography>
                        )
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('trackingVendors')}
                    expanded={props.expandedPanel === 'trackingVendors'}
                    title="Tracking Vendors"
                    error={props.showErrors && errors.tracking_vendors}
                    details={
                        <TrackingVendorsSelector
                            showErrors={showErrors}
                            errors={errors}
                            draft={draft}
                            onFieldChange={onFieldChange}
                            updateVendors={updateVendors}
                            trackingTagsMatched={props.trackingTagsMatched}
                            creativeType={'native'}
                        />
                    }
                    summary={
                        draft.tracking_vendors.length > 0 ? (
                            <div className={classes.thirdPartyVendors}>
                                {map(draft.tracking_vendors, vendor => {
                                    return (
                                        <Chip
                                            key={vendor}
                                            label={creativeTrackingVendorsMapping[vendor] || vendor}
                                            variant="outlined"
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add tracking vendors</Typography>
                        )
                    }
                />
            </div>
        );
    }

    return null;
}

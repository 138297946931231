import _ from 'lodash';
import React from 'react';

import FormField from 'widgets-v5/form-field';
import IabCategorySelector from 'widgets/form-inputs/iab-category-selector';

class TargetIABCategories extends React.Component {
    render() {
        const { checkedCategories, validate, errors, showErrors, onChange, label } = this.props;

        return (
            <div className="ef5-ad-setup__app-categories">
                <FormField label={label} errors={errors} showErrors={showErrors} isRequired={false}>
                    <IabCategorySelector
                        checkedCategories={checkedCategories}
                        onChange={onChange}
                    />
                </FormField>
            </div>
        );
    }
}

export default TargetIABCategories;

import React, { useEffect, useRef } from 'react';

import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';

import { useReportExplore } from '../../hook';
import DayWeekPartsGraph from '../../../modules/day-week-part-graph';

export const Heatmap = ({
    isClient,
    isClientReportPage,
    onReset,
    onHoursClick,
    onDayOfWeekClick,
}) => {
    const {
        campaign,
        heatmapItemsWithValues,
        heatmapItems,
        initializeDimensions,
    } = useReportExplore();

    useEffect(() => {
        if (!heatmapItemsWithValues) {
            initializeDimensions({
                components: heatmapItems,
                campaignId: campaign.id,
            });
        }
    }, []);

    const sharingSettings = get(
        campaign,
        'sharing_settings.report_tabs.explore.dimensions',
        []
    );

    const parsedHeatmapComponents = isClient || isClientReportPage ? heatmapItemsWithValues.filter(item => {
        // This is where we enforce campaign sharing settings on dimensions.
        // We do the same for metrics in the metricSelector
        const sharingConfig = find(sharingSettings, setting => {
            return setting.name === item.component.serverName;
        });

        return isClient || isClientReportPage
            ? get(sharingConfig, 'shared', false)
            : true;
    }) : heatmapItemsWithValues;

    return map(parsedHeatmapComponents, heatmapConfig => (
        <div
            className="ef5-explore-report__dimension-tile ef5-explore-report__dimension-tile_day-week-parts"
            key={heatmapConfig.component.id}
        >
            <HeatmapComponent
                heatmapConfig={heatmapConfig}
                onReset={onReset}
                onHoursClick={onHoursClick}
                onDayOfWeekClick={onDayOfWeekClick}
            />
        </div>
    ));
}

const HeatmapComponent = ({
    heatmapConfig,
    onReset,
    onHoursClick,
    onDayOfWeekClick
}) => {
    const {
        fetchDimensionItemData,
        dateRange,
        dimensionMatch,
        scopeMatch,
        campaign,
    } = useReportExplore();

    const { component } = heatmapConfig;

    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            // First render, check if we have data already before fetching more.
            if (!heatmapConfig.rawData || !heatmapConfig.rawData['hour|day_of_week']) {
                fetchDimensionItemData({
                    component,
                    campaignId: campaign.id,
                });
            }
            mounted.current = true;
        } else {
            // Subsequent renders are ok to fetch since some of the filtering might have changed.
            fetchDimensionItemData({
                component,
                campaignId: campaign.id,
            });
        }
    }, [dateRange, dimensionMatch, scopeMatch]);

    return (
        <DayWeekPartsGraph
            {...heatmapConfig}
            onReset={onReset}
            onHoursClick={onHoursClick}
            onDayOfWeekClick={onDayOfWeekClick}
        />
    )
}

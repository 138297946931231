import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import classnames from 'classnames';

const { arrayOf, shape, string, any } = PropTypes;

class StandardSelect extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        searchable: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.string,
        clearable: PropTypes.bool,
        multi: PropTypes.bool,
        className: PropTypes.string,
        options: arrayOf(
            shape({
                label: string,
                value: any,
            })
        ),
    };

    static defaultProps = {
        clearable: false,
        multi: false,
    };

    componentDidMount() {
        this.value = this.props.value;
    }

    getValue = () => {
        return this.value;
    };

    updateValue = selectValue => {
        this.value = selectValue;
        this.props.onChange(selectValue);
    };

    render() {
        return (
            <div className={classnames('ef3-reactSelect', this.props.className)}>
                <Select
                    ref="stateSelect"
                    autofocus={this.props.autofocus}
                    options={this.props.options}
                    simpleValue
                    isLoading={this.props.isLoading}
                    placeholder={this.props.placeholder}
                    clearable={this.props.clearable}
                    multi={this.props.multi}
                    name={this.props.label}
                    disabled={this.props.disabled}
                    value={this.props.value}
                    valueRenderer={this.props.valueRenderer}
                    onChange={this.updateValue}
                    searchable={this.props.searchable}
                />
            </div>
        );
    }
}

export default StandardSelect;

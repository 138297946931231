import React from 'react';
import _ from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useCampaignOverviewCreatives } from '../../v2/hook';

const CreativeBulkArchiveConfirmModal = ({ campaignId, selectedRows }) => {
    const campaignOverviewCreatives = useCampaignOverviewCreatives();
    const {
        isBulkArchiveConfirmModalOpen,
        closeBulkArchiveConfirmModal,
        creativeBulkArchiveMessage,
        confirmBulkArchiveCreatives,
    } = campaignOverviewCreatives;
    const { creativesToBulkArchive, creativesWithConnectedAds, title } = creativeBulkArchiveMessage;

    const getMessages = () => {
        if (creativesWithConnectedAds.length === 0) {
            return (
                <div>
                    <Box mb={1}>
                        {_.map(creativesToBulkArchive, creative => {
                            return (
                                <Typography key={creative.id} variant="subtitle1">
                                    # {creative.id} {creative.name}
                                </Typography>
                            );
                        })}
                    </Box>

                    <Typography>
                        {' '}
                        The creatives will be permanently archived and can no longer be edited or
                        assigned to a live ad.
                    </Typography>
                </div>
            );
        } else {
            return (
                <div>
                    <Typography>
                        The following creatives must be unassigned from all associated ads before
                        bulk archiving:
                    </Typography>
                    <Box mb={1}>
                        {_.map(creativesWithConnectedAds, creative => {
                            return (
                                <Typography key={creative.id} variant="subtitle1">
                                    # {creative.id} {creative.name}
                                </Typography>
                            );
                        })}
                    </Box>
                </div>
            );
        }
    };

    const getButtons = () => {
        if (creativesWithConnectedAds.length === 0) {
            return (
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const actionPayload = {
                                campaignId,
                                selectedRows,
                            };
                            confirmBulkArchiveCreatives(actionPayload);
                        }}
                    >
                        Archive
                    </Button>
                </div>
            );
        } else {
            return (
                <Button variant="contained" color="primary" onClick={closeBulkArchiveConfirmModal}>
                    OK
                </Button>
            );
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isBulkArchiveConfirmModalOpen}
            onClose={closeBulkArchiveConfirmModal}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent> {getMessages()} </DialogContent>
            <DialogActions>{getButtons()}</DialogActions>
        </Dialog>
    );
};

export default CreativeBulkArchiveConfirmModal;

import toastr from 'toastr';

import store from 'core/store';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

function notify({ error, metaData = {} }) {
    if (window.bugsnagClient) {
        // https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/
        const state = store.getState();
        let _error = error;

        const hasErrorNameAndMessage = error.name && error.message;
        if (!hasErrorNameAndMessage) {
            _error = new Error(JSON.stringify(error));
        }

        window.bugsnagClient.notify(_error, {
            metaData,
            user: {
                id: state.profile.userId,
                name: `${state.profile.userFirstName} ${state.profile.userLastName}`,
                email: state.profile.userEmail,
            },
        });
    }

    console.error(error);

    toastr.warning(
        '',
        '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
        TOASTR_OPTIONS
    );
}

export default notify;

import { isInternalUser } from 'states/profile/business-rules';
import flags from 'containers/flags/service';
import { getEnvironmentSettings } from 'services/environment';

const dimensions = [
    {
        name: 'campaign_id',
        serverName: 'campaign_id',
        label: 'Campaign',
    },
    {
        name: 'ad_id',
        serverName: 'ad_id',
        label: 'Ad',
    },
    {
        name: 'age',
        serverName: 'age',
        label: 'Age',
    },
    {
        name: 'channel_id',
        serverName: 'channel',
        label: 'Channel',
    },
    {
        name: 'date',
        serverName: 'date',
        label: 'Date',
    },
    {
        name: 'day_of_week',
        serverName: 'time',
        label: 'Time',
    },
    {
        name: 'device_os',
        serverName: 'os',
        label: 'Device OS',
    },
    {
        name: 'gender',
        serverName: 'gender',
        label: 'Gender',
    },
    {
        name: 'geo_country',
        serverName: 'country',
        label: 'Country',
    },
    {
        name: 'geo_country_region',
        serverName: 'region',
        label: 'Region',
    },
    {
        name: 'hour',
        serverName: 'hour',
        label: 'Hour',
    },
    {
        name: 'month',
        serverName: 'month',
        label: 'Month',
    },
    {
        name: 'quarter',
        serverName: 'quarter',
        label: 'Quarter',
    },
    {
        name: 'markup_id',
        serverName: 'markup_id',
        label: 'Creative',
    },
    {
        name: 'audience_segment',
        serverName: 'audience_segment',
        label: 'Audience',
    },
    {
        name: 'poi_tag_chosen',
        serverName: 'poi',
        label: 'POI',
    },
    {
        name: 'week_by_campaign_start_day',
        serverName: 'week_by_campaign_start_day',
        label: 'Week by campaign start',
    },
    {
        name: 'week_by_monday_to_sunday',
        serverName: 'week_by_monday_to_sunday',
        label: 'Week by Monday to Sunday',
    },
    {
        name: 'tactic_id',
        serverName: 'tactic_id',
        label: 'Tactic',
    },
    {
        name: 'platform',
        serverName: 'platform',
        label: 'Platform',
    },
    {
        name: 'flight_id',
        serverName: 'flight_id',
        label: 'Flights',
    },
];

export function getDimensions() {
    let newDimensions = [...dimensions];
    const environmentSettings = getEnvironmentSettings();
    const canUseNewDimensions = environmentSettings.useNewReportingDimensions();

    if (isInternalUser()) {
        newDimensions.push({
            name: 'camp_org',
            serverName: 'camp_org',
            label: 'Organization',
        });
    }

    if (canUseNewDimensions) {
        newDimensions.push({
            name: 'geo_city',
            serverName: 'geo_city',
            label: 'City',
        });
        newDimensions.push({
            name: 'fsa',
            serverName: 'fsa',
            label: 'Postal Code',
        });
        newDimensions.push({
            name: 'device_type_name',
            serverName: 'device_type_name',
            label: 'Device Type',
        });
        newDimensions.push({
            name: 'device_language',
            serverName: 'device_language',
            label: 'Device Language',
        });
        newDimensions.push({
            name: 'device_carrier',
            serverName: 'device_carrier',
            label: 'Device Carrier',
        });
        newDimensions.push({
            name: 'exchange_id',
            serverName: 'exchange_id',
            label: 'Exchange',
        });
        newDimensions.push({
            name: 'inventory_type',
            serverName: 'inventory_type',
            label: 'Environment',
        });
        newDimensions.push({
            name: 'inventory_id',
            serverName: 'inventory_id',
            label: 'App / Site ID',
        });
        newDimensions.push({
            name: 'inventory_name',
            serverName: 'inventory_name',
            label: 'App / Site Name',
        });
        newDimensions.push({
            name: 'app_store_cat',
            serverName: 'app_store_cat',
            label: 'App / Site Category',
        });
        newDimensions.push({
            name: 'pmp_id',
            serverName: 'pmp_id',
            label: flags.isEnabled('efb_35_curated_deals') ? 'Deal ID' : 'Private Deal ID',
        });
        newDimensions.push({
            name: 'pmp_deal_name',
            serverName: 'pmp_deal_name',
            label: flags.isEnabled('efb_35_curated_deals') ? 'Deal Name' : 'Private Deal Name',
        });
        newDimensions.push({
            name: 'creative_size',
            serverName: 'creative_size',
            label: 'Creative Size',
        });
        newDimensions.push({
            name: 'creative_channel',
            serverName: 'creative_channel',
            label: 'Creative Channel',
        });
        newDimensions.push({
            name: 'video_length',
            serverName: 'video_length',
            label: 'Video Length',
        });
        newDimensions.push({
            name: 'video_placement_name',
            serverName: 'video_placement_name',
            label: 'Video Placement',
        });
        newDimensions.push({
            name: 'video_position_name',
            serverName: 'video_position_name',
            label: 'Video Position',
        });
        newDimensions.push({
            name: 'conversion_lag',
            serverName: 'conversion_lag',
            label: 'Conversion Lag',
        });
    }

    if (canUseNewDimensions && flags.isEnabled('efb_17_dooh_target_venue')) {
        newDimensions.push({
            name: 'dooh_venue_type_subclass',
            serverName: 'dooh_venue_type_subclass',
            label: 'Venue Type',
        });
    }

    if (canUseNewDimensions && flags.isEnabled('en_3511_snowflake_dimensions_lower_priority')) {
        newDimensions.push({
            name: 'device_model',
            serverName: 'device_model',
            label: 'Device Model',
        });
        newDimensions.push({
            name: 'pmp_deal_source_type',
            serverName: 'pmp_deal_source_type',
            label: 'Private Deal Source Type',
        });
        newDimensions.push({
            name: 'video_skippable',
            serverName: 'video_skippable',
            label: 'Video Skippable',
        });
        newDimensions.push({
            name: 'audience_category',
            serverName: 'audience_category',
            label: 'Audience Category',
        });
        newDimensions.push({
            name: 'audience_subcategory',
            serverName: 'audience_subcategory',
            label: 'Audience Subcategory',
        });
    }
    return newDimensions;
}

export default dimensions;

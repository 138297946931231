import _ from 'lodash';
import { formatNumber_whole, formatNumber_percentage } from 'utils/formatting';
import flags from 'containers/flags/service';

export const configs = [
    {
        value: 'impressions',
        label: 'Impressions',
        formatValue: stat => formatNumber_whole(stat.metrics.impressions),
        orderValue: stat => stat.metrics.impressions,
        isZeroRow: stat => stat.metrics.impressions === 0,
    },

    {
        value: 'clicks',
        label: 'Clicks',
        formatValue: stat => formatNumber_whole(stat.metrics.clicks),
        orderValue: stat => stat.metrics.clicks,
        isZeroRow: stat => stat.metrics.clicks === 0,
    },

    {
        value: 'ctr',
        label: 'CTR',
        formatValue: stat => formatNumber_percentage(stat.metrics.ctr),
        orderValue: stat => stat.metrics.ctr,
        isZeroRow: stat => stat.metrics.ctr === 0,
    },

    {
        value: 'placed_bid',
        label: 'Bids',
        formatValue: stat => formatNumber_whole(stat.metrics.placed_bid),
        orderValue: stat => stat.metrics.placed_bid,
        isZeroRow: stat => stat.metrics.placed_bid === 0,
    },

    {
        value: 'billings_local',
        label: 'Revenue',
        formatValue: stat => formatNumber_whole(stat.metrics.billings_local),
        orderValue: stat => stat.metrics.clicks,
        isZeroRow: stat => stat.metrics.clicks === 0,
    },
];

const metricMapping = {};
_.each(configs, config => (metricMapping[config.value] = config));

export default metricMapping;

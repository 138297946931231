import _ from 'lodash';

import store from 'core/store';
import config from '../../config';
import CURRENCY_OPTIONS from 'common/constants/currency-options.json';
import {
    CANADIAN_US_CARRIERS_ISPs,
    CANADIAN_ISPs,
    SPAIN_CARRIERS,
    SPAIN_ISPS,
} from 'common/constants/carrier-targeting-v2';

import { getDefaults } from 'forms/ad-form/services';
import { isInternalUser } from 'states/profile/business-rules';
import { macroMapping, excludeGDPRMacrosForSpain } from 'common/constants/macroMapping';
import flags from 'containers/flags/service';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';

const HAMAK_SELF_SERVE_ORG_ID = '61b35cec8ee952c001f2592f';
const AOD_SELF_SERVE_ORG_ID = '6595d531ff3513c1eb37f76e';
const MEDIATOMIC_SELF_SERVE_ORG_ID = '65fc788fb835a4df74121e15';

export function getEnv() {
    if (config.DEV_SETTINGS_ENABLED) {
        return _.get(store.getState(), 'devSettings.environment');
    }

    return config.ENV_NAME;
}

const environmentSettingsDefaults = {
    conversionDomain: 'https://dashboard.engagefront.com',
    pixelDomain: 'https://engagefront.theweathernetwork.com',
    canUseAdminFeatureSets: () => true,
    canUseAdminFeatureSetsFTA: () => true,
    canUseAdminFeatureSetsIAS: () => true,
    getDefaultCurrency: () => 'USD',
    getDealCurrencyOptions: () => _.filter(CURRENCY_OPTIONS, option => option.value !== 'EUR'),
    getTimezoneCountryCodes: () => ['CA', 'US', 'UTC'],
    getCurrencyOptions: () => _.filter(CURRENCY_OPTIONS, option => option.value !== 'EUR'),
    canUseAutoBudgetAllocation: () => true,
    canUsePixels: () => true,
    getDefaultPlatformTargets: () => {
        const { platform, platforms } = getDefaults('payload_formData');
        return { platform, platforms };
    },
    canUseFta: () => true,
    canUseCreativeOptimization: () => true,
    canUseWeatherConditions: () => true,
    getWeatherCondtionsToUse: () => {
        return [
            'Rain',
            'Sunny',
            'Temperature',
            'FeelsLike',
            'Snow',
            'humidity',
            'windspeed',
            'heat',
            'fog',
            'airquality',
            'tick',
            'flu',
            'uv',
            'migraine',
            'outdoor',
            'poolandbeach',
            'pollen',
            'mosquito',
        ];
    },
    canUseCarrierIsp: () => true,
    getCarriers: () => CANADIAN_US_CARRIERS_ISPs,
    getISPs: () => CANADIAN_ISPs,
    canUseGenderTargeting: () => true,
    canUseAgeTargeting: () => true,
    canUseTactics: () => true,
    canUseTacticsCategories: () => true,
    canUseGeoFencing: () => true,
    canUseExchanges: () => true,
    canEditBidStrategy: () => true,
    getAppsAndSitesLabel: () => 'Apps & Sites',
    getAppsAndSitesPlaceholder: () => 'All Apps & Sites',
    getCountryCodes: campaignType => {
        if (campaignType === CampaignTypeMapping.DOOH) {
            return ['CA'];
        }
        if (isInternalUser()) {
            return ['CA', 'US'];
        }
        if (store.getState()?.profile?.organizationId === HAMAK_SELF_SERVE_ORG_ID) {
            return ['CA', 'US'];
        }
        if (store.getState()?.profile?.organizationId === AOD_SELF_SERVE_ORG_ID) {
            return ['CA', 'US'];
        }
        if (store.getState()?.profile?.organizationId === MEDIATOMIC_SELF_SERVE_ORG_ID) {
            return ['CA', 'US'];
        }

        return ['CA'];
    },
    canSpecifyCityTargeting: () => true,
    canUseInventoryForecast: () => true,
    canUsePattisonForInventoryForecast: () => true,
    getInventoryForecastCountryCode: () => 'CA',
    getInventoryForecastCurrency: () => 'CAD',
    getInventoryForecastCreativeTypes: () => {
        return [
            'display_banner',
            'display_box',
            'display_vertical',
            'display_interstitial',
            'display_expandable',
            'video',
            'native',
        ];
    },
    canUseAudiences: () => true,
    canUseAdPresets: () => true,
    canUseIas: () => true,
    getDefaultIasFraudPreventionRiskValue: () => 1,
    canCreativesUseInapp: () => true,
    getCreativePlatformRestrictions: () => [],
    canUseMraid: () => true,
    canUseCustomDimensions: () => true,
    getCurrencySign: () => '$',
    showCurrencyLabel: () => true,
    getMacroMapping: () => macroMapping,
    useGDPRConsentMacro: () => true,
    canUseConsoleteamEmails: () => false,
    canUseRetargetingFrequencyOption: () => false,
    canUseAudienceBuilder: () => true,
    excludedAudiences: () => [],
    getViewabilityOptions: () => {
        return ['viewability']; // publicis and groupM options are removed as IAS does not support them at this moment [EN-3303]
    },
    getDataProviderOptions: () => {
        if (isInternalUser()) {
            return ['kochava', 'client', 'other'];
        }
        return ['client', 'other'];
    },
    canUploadMweb: () => {
        if (isInternalUser()) {
            return true;
        }
        return false;
    },
    getReportingDatasource: () => {
        return 'snowflake';
    },
    useNewReportingDimensions: () => {
        return true;
    },
    getMaxFileUploadSizeMB: () => 110,
    canUseCTVOrDOOH: () => true,
    useJobForBulkCreativeScreenshots: () => true,
};

const environments = [
    {
        ...environmentSettingsDefaults,
        conversionDomain: 'https://dashboard.engagefront.es',
        pixelDomain: 'https://engagefront.eltiempo.es',
        env: 'spain',
        canUseAdminFeatureSets: () => true,
        canUseAdminFeatureSetsFTA: () => false,
        canUseAdminFeatureSetsIAS: () => true,
        getDefaultCurrency: () => 'EUR',
        getDealCurrencyOptions: () => [{ label: 'USD', value: 'USD' }],
        getTimezoneCountryCodes: () => ['SP', 'UTC'],
        getCurrencyOptions: () => _.filter(CURRENCY_OPTIONS, option => option.value === 'EUR'),
        canUseAutoBudgetAllocation: () => {
            return flags.isEnabled('internal_test_campaign_level_pacing_for_spain');
        },
        canUsePixels: () => true,
        getDefaultPlatformTargets: () => {
            const { platform, platforms } = getDefaults('payload_formData');
            return { platform, platforms };
        },
        canUseFta: () => false,
        canUseCreativeOptimization: () => true,
        canUseWeatherConditions: () => true,
        getWeatherCondtionsToUse: () => {
            return [
                'Rain',
                'Sunny',
                'Temperature',
                'FeelsLike',
                'Snow',
                'humidity',
                'windspeed',
                'heat',
                'fog',
                'haze',
                'pollen',
                'airquality',
                'flu',
                'uv',
                'mosquito',
                'migraine',
                'outdoor',
                'pool',
                'beach',
            ];
        },
        canUseCarrierIsp: () => {
            return flags.isEnabled('en_3125_add_carriers_targeting_in_Spain');
        },
        getCarriers: () => SPAIN_CARRIERS,
        getISPs: () => SPAIN_ISPS,
        canUseGenderTargeting: () => false,
        canUseAgeTargeting: () => false,
        canUseTactics: () => true,
        canUseTacticsCategories: () => true,
        canUseGeoFencing: () => false,
        canUseExchanges: () => false,
        canEditBidStrategy: () => false,
        getAppsAndSitesLabel: () => 'Sites',
        getAppsAndSitesPlaceholder: () => 'All Sites',
        getCountryCodes: () => ['ES', 'GB'],
        canSpecifyCityTargeting: () => {
            return true;
        },
        canUseInventoryForecast: () => true,
        canUsePattisonForInventoryForecast: () => false,
        getInventoryForecastCountryCode: () => 'ES',
        getInventoryForecastCurrency: () => 'EUR',
        getInventoryForecastCreativeTypes: () => {
            return [
                'display_banner',
                'display_box',
                'display_vertical',
                'display_interstitial',
                'display_expandable',
                'video',
                'native',
            ];
        },
        canUseAudiences: () => true,
        canUseAdPresets: () => false,
        canUseIas: () => true,
        getDefaultIasFraudPreventionRiskValue: () => 1,
        canCreativesUseInapp: () => true,
        getCreativePlatformRestrictions: () => [],
        canUseMraid: () => false,
        canUseCustomDimensions: () => false,
        getCurrencySign: () => '€',
        showCurrencyLabel: () => false,
        getMacroMapping: () => excludeGDPRMacrosForSpain(),
        useGDPRConsentMacro: () => false,
        canUseConsoleteamEmails: () => false,
        canUseRetargetingFrequencyOption: () => true,
        canUseAudienceBuilder: () => false,
        excludedAudiences: () => {
            return {
                pelmorex: [
                    'pds_manifold',
                    'pds_environics',
                    'pds_mastercard',
                    'flex_standard_brand',
                ],
                custom: ['flex_custom_brand'],
            };
        },
        getViewabilityOptions: () => ['viewability'],
        getDataProviderOptions: () => {
            return ['client', 'other'];
        },
        canUploadMweb: () => false,
        getReportingDatasource: () => 'elasticsearch',
        useNewReportingDimensions: () => {
            return false;
        },
        getMaxFileUploadSizeMB: () => 20,
        canUseCTVOrDOOH: () => false,
        useJobForBulkCreativeScreenshots: () => false,
    },
    {
        ...environmentSettingsDefaults,
        env: 'cloud-dev',
        canUseConsoleteamEmails: () => true,
    },
];

export const getEnvironmentSettings = () => {
    const env = getEnv();

    if (_.get(store.getState(), 'profile.organizationId') === '5fff36b8a122ef9c6b8782c2') {
        return _.find(environments, { env: 'spain' });
    }

    return _.find(environments, { env }) || environmentSettingsDefaults;
};

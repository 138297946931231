import Joi from '@hapi/joi';

import { regEx } from 'utils/regular-expression-library';
import { getValidSizes } from 'forms/creative-form/constants';
const urlValidationRegExp = regEx.url;

const validSizes = getValidSizes();

export default Joi.object({
    name: Joi.string()
        .required()
        .messages({
            'string.empty': 'Name is required',
        }),
    format: Joi.string()
        .required()
        .messages({ 'string.empty': 'Format is required' }),
    size: Joi.string()
        .required()
        .valid(...validSizes)
        .messages({
            'any.required': 'Size is required',
            'any.valid': 'Image does not have valid dimensions',
            'any.empty': 'Image dimensions not supported. Valid size is required.',
            'any.base': 'Image dimensions not supported. Valid size is required.',
        }),
    platforms: Joi.array()
        .min(1)
        .required()
        .messages({ 'array.min': 'Platform is required', 'any.required': 'Platform is required' }),
    type: Joi.string()
        .required()
        .messages({ 'string.empty': 'Type is required', 'any.required': 'Type is required' }),
    third_party_clickthrough: Joi.boolean()
        .required()
        .messages({ 'any.empty': 'Third Party Clickthrough is required' }),
    clickthrough_url: Joi.when('third_party_clickthrough', {
        switch: [
            {
                is: true,
                then: Joi.string()
                    .trim()
                    .required()
                    .pattern(urlValidationRegExp, 'urlPatternTest'),
            },
        ],
    }).messages({
        'any.required': 'Clickthrough URL is required',
        'string.base': 'Clickthrough URL is required',
        'string.empty': 'Clickthrough URL is required',
        'string.pattern.name': `Clickthrough URL should start with 'http://', 'https://', or 'tel:`,
    }),
    landing_page: Joi.string()
        .trim()
        .pattern(urlValidationRegExp, 'urlPatternTest')
        .invalid(Joi.ref('clickthrough_url'))
        .required()
        .messages({
            'any.invalid': 'Landing Page cannot be the same as Clickthrough URL',
            'string.empty': 'Landing Page is required',
            'string.pattern.name': `Landing Page should start with 'http://', 'https://', or 'tel:`,
        }),
    third_party_pixels: Joi.array()
        .items(Joi.string().pattern(urlValidationRegExp, 'urlPatternTest'))
        .messages({
            'string.empty': 'Third Party Pixels cannot be empty',
            'string.base': 'Third Party Pixels must be a string',
            'string.pattern.name': `Third Party Pixels should start with 'http://', 'https://', or 'tel:'`,
        }),
    third_party_javascript_urls: Joi.array()
        .items(Joi.string().pattern(urlValidationRegExp, 'urlPatternTest'))
        .messages({
            'string.empty': 'Third Party Javascript cannot be empty',
            'string.base': 'Third Party Javascript must be a string',
            'string.pattern.name': `Third Party Javascript should start with 'http://', 'https://', or 'tel:'`,
        }),
    image_asset: Joi.string().allow(null, ''),
    image_url: Joi.string()
        .trim()
        .required()
        .pattern(urlValidationRegExp, 'urlPatternTest'),
    image_preview_url: Joi.string().pattern(urlValidationRegExp, 'urlPatternTest'),
});

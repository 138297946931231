import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import classnames from 'classnames';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';

import TimezonelessDateTimeRangePicker from 'widgets-v5/timezoneless-date-time-range-picker';

import { getRangePositionToNow } from 'common/date-calculator';
import { DialogTitle } from 'widgets-v6/modal';

function timezonelessFormat(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

export const Select = styled(MuiSelect)({
    backgroundColor: 'white',
});

export const HiddenMenuItem = styled(MenuItem)({
    display: 'none',
});

class TimezonelessDateRangePicker extends React.Component {
    static propTypes = {
        campaignFlight: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        }),
        dropDownOptions: PropTypes.array,
        onChange: PropTypes.func,
        disableCustomRange: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const {
            campaignFlight: { start, end },
        } = props;
        const index = _.findIndex(props.dropDownOptions, option => {
            if (!option.start || !option.end) {
                return false;
            }

            return (
                timezonelessFormat(start) === timezonelessFormat(option.start) &&
                timezonelessFormat(end) === timezonelessFormat(option.end)
            );
        });

        this.state = {
            activeDropDown: index > -1 ? index : props.dropDownOptions.length - 1,
            menuIsActive: false,
            popUpIsActive: false,
            startPreApply: timezonelessFormat(start),
            endPreApply: timezonelessFormat(end),
            customStartEnd: {
                start: timezonelessFormat(start),
                end: timezonelessFormat(end),
            },
            dateTimePickerKey: 0,
        };
    }

    selectFromList = index => {
        let activeItem;
        if (index === this.props.dropDownOptions.length - 1 && !this.props.disableCustomRange) {
            activeItem = {
                ...this.props.dropDownOptions[index],
                start: timezonelessFormat(this.state.customStartEnd.start),
                end: timezonelessFormat(this.state.customStartEnd.end),
            };
        } else {
            activeItem = this.props.dropDownOptions[index];
        }

        const response = this.props.onChange(activeItem);

        if (response === false) {
            return;
        }

        this.setState({
            activeDropDown: index,
            menuIsActive: false,
            popUpIsActive: false,
            startPreApply: this.state.customStartEnd.start,
            endPreApply: this.state.customStartEnd.end,
        });
    };

    toggleDropDownMenu = () => {
        this.setState({ menuIsActive: !this.state.menuIsActive });
    };

    toggleCustomPopUp = () => {
        this.setState({ popUpIsActive: !this.state.popUpIsActive });
    };

    cancel = () => {
        this.setState({
            popUpIsActive: false,
            dateTimePickerKey: this.state.dateTimePickerKey + 1,
            customStartEnd: {
                start: this.state.startPreApply,
                end: this.state.endPreApply,
            },
        });
    };

    setCustomDate = (start, end, callback = _.noop) => {
        this.setState({ customStartEnd: { start, end } }, callback);
    };

    applyCustomDate = () => {
        if (this.state.customStartEnd.start && this.state.customStartEnd.end) {
            this.selectFromList(this.props.dropDownOptions.length - 1);
        }
    };

    componentDidMount() {
        document.body.addEventListener('click', this.onBodyClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.onBodyClick);
    }

    onBodyClick = event => {
        const targetName = event.target.className;
        const { menuIsActive } = this.state;

        if (_.isString(targetName)) {
            const clickedOnBody = targetName.indexOf('dateRangePicker') === -1;
            if (clickedOnBody && menuIsActive) {
                this.toggleDropDownMenu();
            }
        } else {
            if (menuIsActive) {
                this.toggleDropDownMenu();
            }
        }
    };

    handleDateRangeChange = e => {
        const { dropDownOptions } = this.props;
        const { value } = e.target;
        if (value === 'Custom' && !this.props.disableCustomRange) {
            this.toggleCustomPopUp();
        } else {
            const optionIndex = _.findIndex(dropDownOptions, option => option.title === value);
            this.selectFromList(optionIndex);
        }
    };

    render() {
        if (!this.props.dropDownOptions) {
            return <div className="ef3-dateRangePicker">...loading</div>;
        }

        const { campaignFlight, dropDownOptions } = this.props;
        const { customStartEnd } = this.state;

        const campaignFlightInRelationToNow = getRangePositionToNow(campaignFlight);

        const activeDropDownIndex = this.state.activeDropDown;

        let customValue = null;
        if (activeDropDownIndex === dropDownOptions.length - 1 && !this.props.disableCustomRange) {
            customValue = `${moment(customStartEnd.start).format('MMM DD, YYYY')} - ${moment(
                customStartEnd.end
            ).format('MMM DD, YYYY')}`;
        }

        return (
            <div className="ef3-dateRangePicker">
                <FormControl variant="outlined" fullWidth>
                    <Box mb={1}>
                        <FormLabel component="legend">Date Range</FormLabel>
                    </Box>
                    <Select
                        id="demo-customized-select"
                        value={customValue || dropDownOptions[activeDropDownIndex].title}
                        onChange={this.handleDateRangeChange}
                    >
                        {_.map(this.props.dropDownOptions, (option, index) => (
                            <MenuItem key={index} value={option.title}>
                                {option.title}
                            </MenuItem>
                        ))}
                        <HiddenMenuItem value={customValue}>{customValue}</HiddenMenuItem>
                    </Select>
                </FormControl>
                <Dialog open={this.state.popUpIsActive} onClose={() => this.toggleCustomPopUp()}>
                    <DialogTitle onClose={this.cancel} />
                    <DialogContent>
                        <TimezonelessDateTimeRangePicker
                            key={this.state.dateTimePickerKey}
                            mode="inline"
                            value={{
                                start: this.state.customStartEnd.start,
                                end: this.state.customStartEnd.end,
                            }}
                            onChange={({ start, end }) => this.setCustomDate(start, end)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <FormHelperText error>{this.props.displayError}</FormHelperText>
                        <Button
                            onClick={this.applyCustomDate}
                            variant="contained"
                            color="primary"
                            text="Apply"
                            disabled={
                                !this.state.customStartEnd.start && !this.state.customStartEnd.end
                            }
                        >
                            Apply
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default TimezonelessDateRangePicker;

import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { MuiFormLabel } from 'widgets-v6/standard-input';
import Separator from 'widgets-v5/separator';

import InlineTooltip from 'widgets-v5/inline-tooltip';
import AssetSelector from '../asset-selector';
import { securePartnerDomainsAndReplaceUrlMacros } from 'widgets-v5/url-standard-input';
import { processMacrosForCustomHtml } from '../../../creative-bulk-upload-form/services/process-macros';
import { optionKeyValueMapping, getCreativeFormatTextFieldMapping2 } from '../../index';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        minWidth: 650,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        padding: `${theme.spacing(2)} 0px`,
    },
    detailsRow: {
        display: 'flex',
        '& > td': {
            padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
        },
    },
    detailsCell: {
        width: '200px',
        borderBottom: 'none',
    },
    detailsValue: {
        width: '65%',
        borderBottom: 'none',
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(1),
        '& > div > input': {
            padding: '12.5px 12px',
        },
    },
    helperText: {
        margin: '8px 0',
    },
    arrayHelperText: {
        marginTop: 0,
    },
    previewImage: {
        height: 80,
    },
    warning: {
        color: 'orange',
    },
    updated: {
        width: '10%',
        borderBottom: 'none',
    },
}));

const formatOptions = {
    standard: 'Standard',
    video: 'Video Interstitial',
    custom_html: 'Rich Media',
    mraid: 'MRAID Rich Media',
    native: 'Native',
};

const VirtualizedCreativeEditTableGenerator = ({
    selectedCreatives,
    fields,
    onChange,
    onArrayValueChange,
    errors,
    showErrors,
    files,
    updatedFields,
    isFileUploaded,
}) => {
    const classes = useStyles();
    let listRef = useRef();

    const getItemSize = i => {
        let itemSize = fields.length * 80;

        if (_.includes(fields, 'content_html')) {
            itemSize += 100;
        }

        if (_.includes(fields, 'third_party_clickthrough')) {
            itemSize += 100;

            if (selectedCreatives[i].third_party_clickthrough === true) {
                itemSize += 85;
            }
        }

        return itemSize;
    };

    const handleOnBlurFieldWithMacros = ({ value, creativeId, field }) => {
        let processedValue = value;

        if (field === 'content_html') {
            processedValue = processMacrosForCustomHtml({ value: processedValue });
        }

        if (field === 'clickthrough_url') {
            processedValue = securePartnerDomainsAndReplaceUrlMacros(processedValue);
        }

        onChange(creativeId, field, processedValue);
    };

    const handleOnBlurFieldWithMacrosInArray = ({ value, creative, field, index }) => {
        let processedValue = value;

        if (field === 'third_party_pixels') {
            processedValue = securePartnerDomainsAndReplaceUrlMacros(processedValue);
        }

        onArrayValueChange(creative, field, processedValue, index);
    };

    return (
        <Table
            className={classes.tableWrapper}
            component="div"
            style={{ width: '100%', height: '200%' }}
        >
            <TableBody component="div">
                <AutoSizer>
                    {({ height, width }) => (
                        <VariableSizeList
                            ref={listRef}
                            height={height}
                            width={width}
                            itemCount={selectedCreatives.length}
                            itemSize={getItemSize}
                        >
                            {({ index, style }) => {
                                if (listRef.current) {
                                    listRef.current.resetAfterIndex(index, false);
                                }

                                return (
                                    <div style={style}>
                                        <TableRow className={classes.detailsRow} component="div">
                                            <TableCell
                                                className={classes.detailsCell}
                                                component="div"
                                            >
                                                ID
                                            </TableCell>
                                            <TableCell
                                                className={classes.detailsCell}
                                                component="div"
                                            >
                                                #{selectedCreatives[index].id}
                                            </TableCell>
                                        </TableRow>
                                        {_.map(fields, field => {
                                            const creative = selectedCreatives[index];
                                            const value = creative[field];
                                            const label = optionKeyValueMapping[field];
                                            const creativeHasField = _.includes(
                                                getCreativeFormatTextFieldMapping2()[
                                                    creative.format
                                                ],
                                                field
                                            );

                                            const updated = updatedFields[creative.id];
                                            const updatedTag =
                                                isFileUploaded && _.includes(updated, field);
                                            if (!creativeHasField) {
                                                return (
                                                    <TableRow
                                                        key={field}
                                                        className={classes.detailsRow}
                                                        component="div"
                                                    >
                                                        <TableCell
                                                            className={classes.detailsCell}
                                                            component="div"
                                                        >
                                                            {label}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.detailsValue}
                                                            component="div"
                                                        >
                                                            <p>
                                                                This field is not the part of the{' '}
                                                                {formatOptions[creative.format]}{' '}
                                                                format
                                                            </p>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            if (field === 'image_url') {
                                                const filesByCreativeType = getFilesByCreativeType(
                                                    files,
                                                    creative.format
                                                );

                                                return (
                                                    <TableRow
                                                        key={field}
                                                        className={classes.detailsRow}
                                                        component="div"
                                                    >
                                                        <TableCell
                                                            className={classes.detailsCell}
                                                            component="div"
                                                        >
                                                            {label}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.detailsValue}
                                                            component="div"
                                                        >
                                                            <AssetSelector
                                                                assets={_.map(
                                                                    filesByCreativeType,
                                                                    file => ({
                                                                        value: file.id,
                                                                        label: (
                                                                            <React.Fragment>
                                                                                <React.Fragment>
                                                                                    {isIncorrectSize(
                                                                                        file,
                                                                                        creative
                                                                                    ) && (
                                                                                        <InlineTooltip
                                                                                            position="right"
                                                                                            tooltip={`Warning: asset does not match expected size (${
                                                                                                creative.size
                                                                                            })`}
                                                                                        >
                                                                                            <span
                                                                                                className={
                                                                                                    classes.warning
                                                                                                }
                                                                                            >
                                                                                                <i className="fa fa-exclamation-triangle" />
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        </InlineTooltip>
                                                                                    )}
                                                                                    {file.name}
                                                                                </React.Fragment>
                                                                                <img
                                                                                    className={
                                                                                        classes.previewImage
                                                                                    }
                                                                                    src={
                                                                                        file.preview_url
                                                                                    }
                                                                                />
                                                                            </React.Fragment>
                                                                        ),
                                                                    })
                                                                )}
                                                                selectedAsset={creative.image_asset}
                                                                onChange={value => {
                                                                    const file = _.find(
                                                                        filesByCreativeType,
                                                                        {
                                                                            id: value,
                                                                        }
                                                                    );

                                                                    onChange(
                                                                        creative.id,
                                                                        'image_asset',
                                                                        file.id
                                                                    );
                                                                    onChange(
                                                                        creative.id,
                                                                        'image_url',
                                                                        file.url
                                                                    );
                                                                    onChange(
                                                                        creative.id,
                                                                        'image_preview_url',
                                                                        file.preview_url
                                                                    );
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {updatedTag ? (
                                                            <TableCell
                                                                className={classes.updated}
                                                                component="div"
                                                            >
                                                                <Chip
                                                                    label="Updated"
                                                                    size="small"
                                                                />
                                                            </TableCell>
                                                        ) : null}
                                                    </TableRow>
                                                );
                                            }
                                            if (field === 'third_party_clickthrough') {
                                                return (
                                                    <ClickTrackingGroup
                                                        key={`${field}-${value}`}
                                                        clickTarackingValue={value}
                                                        creative={creative}
                                                        classes={classes}
                                                        label={label}
                                                        updatedTag={updatedTag}
                                                        updated={updated}
                                                        onChange={onChange}
                                                        showErrors={showErrors}
                                                        errors={errors}
                                                        handleOnBlurFieldWithMacros={
                                                            handleOnBlurFieldWithMacros
                                                        }
                                                    />
                                                );
                                            }
                                            if (
                                                typeof value === 'string' ||
                                                typeof value === 'number' ||
                                                (!value && creativeHasField)
                                            ) {
                                                return (
                                                    <TableRow
                                                        key={`${field}-${value}`}
                                                        className={classes.detailsRow}
                                                        component="div"
                                                    >
                                                        <TableCell
                                                            className={classes.detailsCell}
                                                            component="div"
                                                        >
                                                            {label}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.detailsValue}
                                                            component="div"
                                                        >
                                                            <TextField
                                                                classes={{
                                                                    root: classes.textField,
                                                                }}
                                                                variant="outlined"
                                                                defaultValue={value}
                                                                multiline={field === 'content_html'}
                                                                rows={
                                                                    field === 'content_html'
                                                                        ? 6
                                                                        : undefined
                                                                }
                                                                onBlur={event =>
                                                                    handleOnBlurFieldWithMacros({
                                                                        value: event.target.value,
                                                                        creativeId: creative.id,
                                                                        field,
                                                                    })
                                                                }
                                                                FormHelperTextProps={{
                                                                    classes: {
                                                                        root: classes.helperText,
                                                                    },
                                                                }}
                                                                error={
                                                                    showErrors &&
                                                                    errors[creative.id] &&
                                                                    errors[creative.id][field]
                                                                }
                                                                helperText={
                                                                    showErrors &&
                                                                    errors[creative.id] &&
                                                                    errors[creative.id][field]
                                                                }
                                                            />
                                                        </TableCell>
                                                        {updatedTag ? (
                                                            <TableCell
                                                                className={classes.updated}
                                                                component="div"
                                                            >
                                                                <Chip
                                                                    label="Updated"
                                                                    size="small"
                                                                />
                                                            </TableCell>
                                                        ) : null}
                                                    </TableRow>
                                                );
                                            } else {
                                                return (
                                                    <TableRow
                                                        key={field}
                                                        className={classes.detailsRow}
                                                        component="div"
                                                    >
                                                        <TableCell
                                                            className={classes.detailsCell}
                                                            component="div"
                                                        >
                                                            {label}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.detailsValue}
                                                            component="div"
                                                        >
                                                            <React.Fragment>
                                                                {/* if value is an empty array, it does not map/return empty array */}
                                                                {value.length > 0 ? (
                                                                    _.map(
                                                                        value,
                                                                        (itemInArray, index) => {
                                                                            return (
                                                                                <TextField
                                                                                    key={`${field}-${itemInArray}-${index}`}
                                                                                    classes={{
                                                                                        root:
                                                                                            classes.textField,
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    defaultValue={
                                                                                        itemInArray
                                                                                    }
                                                                                    onBlur={event =>
                                                                                        handleOnBlurFieldWithMacrosInArray(
                                                                                            {
                                                                                                creative,
                                                                                                field,
                                                                                                value:
                                                                                                    event
                                                                                                        .target
                                                                                                        .value,
                                                                                                index,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )
                                                                ) : (
                                                                    <TextField
                                                                        key={`${field}`}
                                                                        classes={{
                                                                            root: classes.textField,
                                                                        }}
                                                                        variant="outlined"
                                                                        onBlur={event =>
                                                                            handleOnBlurFieldWithMacrosInArray(
                                                                                {
                                                                                    creative,
                                                                                    field,
                                                                                    value:
                                                                                        event.target
                                                                                            .value,
                                                                                    index: 0,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                                {showErrors &&
                                                                    errors[creative.id] &&
                                                                    errors[creative.id][field] && (
                                                                        <FormControl error>
                                                                            <FormHelperText
                                                                                classes={{
                                                                                    root:
                                                                                        classes.arrayHelperText,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    errors[
                                                                                        creative.id
                                                                                    ][field]
                                                                                }
                                                                            </FormHelperText>
                                                                        </FormControl>
                                                                    )}
                                                            </React.Fragment>
                                                        </TableCell>
                                                        {updatedTag ? (
                                                            <TableCell
                                                                className={classes.updated}
                                                                component="div"
                                                            >
                                                                <Chip
                                                                    label="Updated"
                                                                    size="small"
                                                                />
                                                            </TableCell>
                                                        ) : null}
                                                    </TableRow>
                                                );
                                            }
                                        })}
                                    </div>
                                );
                            }}
                        </VariableSizeList>
                    )}
                </AutoSizer>
            </TableBody>
        </Table>
    );
};

function ClickTrackingGroup({
    clickTarackingValue,
    creative,
    classes,
    label,
    onChange,
    showErrors,
    errors,
    handleOnBlurFieldWithMacros,
    updatedTag,
    updated,
}) {
    const clickTrackingOptionChange = value => {
        onChange(creative.id, 'third_party_clickthrough', value);
    };

    const showClickTrackingOptions = () => {
        const format = creative.format;
        if (format === 'custom_html' || format === 'mraid') {
            return (
                <Box mb={1}>
                    <RadioGroup
                        aria-label="position"
                        name="position"
                        value={clickTarackingValue}
                        onChange={e => {
                            const value = e.target.value === 'true' ? true : false;
                            clickTrackingOptionChange(value);
                        }}
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            label="Use Clickthrough URL set by third-party ad server or tag (most common)"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label="Set or override Clickthrough URL"
                        />
                    </RadioGroup>
                    {updatedTag ? <Chip label="Updated" size="small" /> : null}
                </Box>
            );
        } else if (format === 'third_party_vast') {
            return null;
        } else {
            return (
                <div>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={clickTarackingValue}
                                    color="primary"
                                    onChange={() => {
                                        clickTrackingOptionChange(!clickTarackingValue);
                                    }}
                                    value={clickTarackingValue}
                                />
                            }
                            label="Use Third-party Clicktracker"
                        />
                    </FormControl>
                    {updatedTag ? <Chip label="Updated" size="small" /> : null}
                </div>
            );
        }
    };

    const showClickTrackingGroupField = () => {
        const landingPageField = (
            <Box key={creative.name} mb={1}>
                <MuiFormLabel component="legend">Landing Page URL</MuiFormLabel>
                <Box display="flex">
                    <Box mr={1} width="100%">
                        <TextField
                            key={'landing_page'}
                            classes={{
                                root: classes.textField,
                            }}
                            variant="outlined"
                            defaultValue={creative.landing_page}
                            onBlur={event =>
                                handleOnBlurFieldWithMacros({
                                    value: event.target.value,
                                    creativeId: creative.id,
                                    field: 'landing_page',
                                })
                            }
                            FormHelperTextProps={{
                                classes: {
                                    root: classes.helperText,
                                },
                            }}
                            error={
                                showErrors &&
                                errors[creative.id] &&
                                errors[creative.id].landing_page
                            }
                            helperText={
                                showErrors &&
                                errors[creative.id] &&
                                errors[creative.id].landing_page
                            }
                            fullWidth
                        />
                    </Box>
                    {_.includes(updated, 'landing_page') && <Chip label="Updated" size="small" />}
                </Box>
            </Box>
        );

        const clickthroughField = (
            <div key={creative.id}>
                <MuiFormLabel component="legend">Third-party Clicktracker URL</MuiFormLabel>
                <Box display="flex">
                    <Box mr={1} width="100%">
                        <TextField
                            key={'clickthrough_url'}
                            classes={{
                                root: classes.textField,
                            }}
                            variant="outlined"
                            defaultValue={creative.clickthrough_url}
                            onBlur={event =>
                                handleOnBlurFieldWithMacros({
                                    value: event.target.value,
                                    creativeId: creative.id,
                                    field: 'clickthrough_url',
                                })
                            }
                            FormHelperTextProps={{
                                classes: {
                                    root: classes.helperText,
                                },
                            }}
                            error={
                                showErrors &&
                                errors[creative.id] &&
                                errors[creative.id].clickthrough_url
                            }
                            helperText={
                                showErrors &&
                                errors[creative.id] &&
                                errors[creative.id].clickthrough_url
                            }
                            fullWidth
                        />
                    </Box>
                    {_.includes(updated, 'clickthrough_url') && (
                        <Chip label="Updated" size="small" />
                    )}
                </Box>
            </div>
        );
        if (creative.format === 'third_party_vast') {
            return landingPageField;
        }
        if (clickTarackingValue) {
            return [landingPageField, clickthroughField];
        }
        return landingPageField;
    };
    return (
        <TableRow key={'third_party_clickthrough'} className={classes.detailsRow} component="div">
            <TableCell key={label} className={classes.detailsCell} component="div">
                {label}
            </TableCell>
            <TableCell className={classes.detailsValue} component="div">
                {showClickTrackingOptions()}
                <Box ml={3}>{showClickTrackingGroupField()} </Box>
            </TableCell>
        </TableRow>
    );
}

function getFilesByCreativeType(files, creativeFormat) {
    const isVideoCreative = _.includes(['video', 'third_party_vast'], creativeFormat);
    if (isVideoCreative) {
        return _.filter(files, { type: 'video' });
    }

    return _.filter(files, { type: 'image' });
}

function isIncorrectSize(file, creative) {
    return creative.size !== `${file.width}x${file.height}`;
}

export default VirtualizedCreativeEditTableGenerator;

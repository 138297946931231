import _ from 'lodash';
import tools from 'utils/tools';
import validateV2 from './validate-v2';

/**
    Apply validation function to an object.

    For more examples visit: https://github.com/amoa/adsushi/wiki/v3-Validator-examples


    Example:

    validators = {
        someProperty: [
            {
                message (value) {
                    return `${value} must be a string`
                },
                validate (value, draft) {
                    return _.isString(value);
                },
                breakOnFail: true
            },
            {
                message: 'Must be at least 10 characters',
                validate (value, draft) {
                    return value.length >= 10;
                }
            }
        ]

        nestedProp: {
            name: [{

            }]
        }
    }

    errors = validate({ someProperty: 123}, validators);

        // errors === [{ message: 'must be a string', field: 'someProperty' }]

        // Notice only 1 error is returned because 'breakOnFail' stopped execution of subsequent validators



    errors = validate({ someProperty: 'foobar'}, validators);

        // errors === [{ message: 'Must be at least 10 characters', field: 'someProperty' }]

}

 */
export default function validate(source, validatorMapping, path = []) {
    const schemaType = getSchemaType(validatorMapping);

    if (schemaType === 'v2') {
        return validateV2(source, validatorMapping);
    }

    if (!_.isPlainObject(source)) {
        throw new Error('@source must be a plain object');
    }
    if (!_.isPlainObject(validatorMapping)) {
        throw new Error('@validatorMapping must be a plain object');
    }

    return _.reduce(
        validatorMapping,
        (errors, validators, fieldName) => {
            if (!validators) {
                return errors;
            }

            const valueToValidate = source[fieldName];

            // Validators have nested childre, run validate on them
            if (_.isPlainObject(validators)) {
                return errors.concat(validate(valueToValidate, validators, path.concat(fieldName)));
            }

            var breakOutOfPipeline = false;

            return _.reduce(
                validators,
                (fieldErrors, validator) => {
                    if (breakOutOfPipeline) {
                        return fieldErrors;
                    }

                    if (typeof validator.validate !== 'function') {
                        throw new Error('validator.validate must be a function');
                    }

                    const result = validator.validate(valueToValidate, source);
                    const isFailure = result === false || typeof result === 'string';

                    function getMessage() {
                        if (typeof result === 'string') {
                            return result;
                        }

                        // Allow override of error message result
                        if (validator.message) {
                            return typeof validator.message === 'string'
                                ? validator.message
                                : validator.message(valueToValidate, fieldName, source);
                        }
                    }

                    // Optional fields will use a breakOnSuccess
                    if (result === true && validator.breakOnSuccess === true) {
                        breakOutOfPipeline = true;
                        return fieldErrors;
                    }

                    var message = getMessage();

                    if (isFailure) {
                        breakOutOfPipeline = validator.breakOnFail;

                        // Message is not provided, ignore any errors
                        // Some validators do not have messages: breakOnSuccess, nested validators
                        if (!message) {
                            return fieldErrors;
                        }

                        return fieldErrors.concat({
                            field: path.concat(fieldName).join('.'),
                            value: valueToValidate,
                            message,
                        });
                    }

                    return fieldErrors;
                },
                errors
            );
        },
        []
    );
}

function getSchemaType(schema) {
    function isArrayOfFunctions(value) {
        return _.isArray(value) && _.every(value, _.isFunction);
    }

    switch (true) {
        case _.isFunction(schema):
        case _.every(schema, isArrayOfFunctions):
        case _.every(schema, _.isFunction):
            return 'v2';

        default:
            return 'v1';
    }
}

function isUndefined(value) {
    return typeof value === 'undefined';
}

function isDefined(value) {
    return !isUndefined(value);
}

export function isString(min, max) {
    return {
        validate(value, source) {
            if (!_.isString(value)) {
                return false;
            }
            if (isDefined(min) && isDefined(max)) {
                return min <= value.length && value.length <= max;
            }
            if (isDefined(min)) {
                return min <= value.length;
            }
            return true;
        },
        message(value) {
            if (!_.isString(value)) {
                return 'Must be a string';
            }
            if (isDefined(min) && isDefined(max)) {
                return `Must be between ${min} and ${max} characters`;
            }
            if (isDefined(min)) {
                return `Must be at least ${min} characters`;
            }
            return 'Must be a string';
        },
    };
}

export function isNumber(min, max) {
    return {
        validate(value, source) {
            if (!_.isNumber(value)) {
                return false;
            }

            if (isDefined(min) && isDefined(max)) {
                return min <= value && value <= max;
            }

            if (isDefined(min)) {
                return min <= value;
            }

            return true;
        },
        message(value) {
            if (!_.isNumber(value)) {
                return 'Must be a number';
            }

            if (isDefined(min) && isDefined(max)) {
                return `Must be between ${min} and ${max}`;
            }

            if (isDefined(min)) {
                return `Must be at least ${min}`;
            }

            return 'Must be a number';
        },
    };
}

export function isLikeNumberComma(min, max) {
    return {
        validate(value, source) {
            const valueAsFloat = parseFloat(value);
            if (
                tools.isLikeNumberComma(value) &&
                isNumber(min, max).validate(valueAsFloat, source)
            ) {
                return true;
            }
            return false;
        },
        message(value) {
            return 'String is not like number with comma';
        },
    };
}

export function isLikeNumber(min, max, message) {
    var _message = message,
        _max = max,
        _min = min;
    if (typeof _min === 'string') {
        _message = _min;
        _min = _max = undefined;
    } else if (typeof _max === 'string') {
        _message = _max;
        _max = undefined;
    }

    return {
        validate(value, source) {
            const valueAsFloat = parseFloat(value);
            if (tools.isLikeNumber(value) && isNumber(_min, _max).validate(valueAsFloat, source)) {
                return true;
            }
            return false;
        },
        message(value) {
            return _message || 'String is not like number';
        },
    };
}

export function isArray(min, max) {
    return {
        validate(value, source) {
            if (!_.isArray(value)) {
                return false;
            }

            if (isDefined(min) && isDefined(max)) {
                return min <= value.length && value.length <= max;
            }

            if (isDefined(min)) {
                return min <= value.length;
            }

            return true;
        },
        message(value) {
            if (!_.isArray(value)) {
                return 'Must be an array';
            }

            if (isDefined(min) && isDefined(max)) {
                return `Must be between ${min} and ${max} elements`;
            }

            if (isDefined(min)) {
                return `Must be at least ${min} element(s)`;
            }

            return 'Must be an array';
        },
    };
}

export function isDatetimeISOString() {
    return {
        validate(value, source) {
            if (!_.isString(value)) {
                return false;
            }
            //Ref http://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
            const regex_datetimeISOString = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
            const _isDatetimeISOString = regex_datetimeISOString.test(value);
            if (!_isDatetimeISOString) {
                return false;
            }

            return true;
        },
        message(value) {
            return 'Invalid date';
        },
    };
}

export function isRequired(msg) {
    return {
        validate(value, source) {
            return value !== undefined;
        },
        message(value) {
            return msg ? msg : `Field is required`;
        },
        breakOnFail: true,
    };
}

export function isOptional() {
    return {
        validate(value, source) {
            return value === undefined;
        },
        breakOnSuccess: true,
    };
}

export function isBoolean(min, max) {
    return {
        validate(value, source) {
            if (!_.isBoolean(value)) {
                return false;
            }
            return true;
        },
        message(value) {
            return 'Must be a boolean';
        },
    };
}

export function isUnique(valueSet) {
    return {
        validate(newValue) {
            if (valueSet.indexOf(newValue) !== -1) {
                return false;
            }
            return true;
        },
        message(value) {
            return `This must be unique`;
        },
    };
}

export function isAlphaNumericWithUnderscore() {
    return {
        validate(value) {
            if (value.match(/^[a-zA-Z0-9_]+$/g)) {
                return true;
            }
            return false;
        },
        message(value) {
            return `Cannot contain spaces, or symbols other than '_'`;
        },
    };
}

export function isAlphaNumericWithSpacesAndUnderscores() {
    return {
        validate(value) {
            if (value.match(/^[a-zA-Z0-9 _]+$/g)) {
                return true;
            }
            return false;
        },
        message(value) {
            return `Cannot contain symbols other than '_'`;
        },
    };
}

import _ from 'lodash';
import Joi from '@hapi/joi';
import { CampaignAutoBudgetAllocationOptions } from '../../../states/resources/campaigns/business-logic';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

export default Joi.array()
    .items(
        Joi.object({
            reporting_name: Joi.string()
                .required()
                .messages({
                    'string.empty': 'Required',
                }),
            event_name: Joi.string()
                .trim()
                .regex(/^[a-z0-9_]+$/)
                .required()
                .messages({
                    'string.empty': 'Required',
                    'string.pattern.base':
                        "Cannot contain uppercase letters, space or symbols other than '_'",
                })
                .custom((eventName, helpers) => {
                    const { draft } = helpers.prefs.context;

                    const hasDuplicateConversions =
                        _(draft)
                            .filter(conversion => conversion.event_name === eventName)
                            .value().length > 1;

                    if (hasDuplicateConversions) {
                        return helpers.message('Pixel Name already exists');
                    }

                    return eventName;
                }),
            use_click_through: Joi.boolean().required(),
            use_view_through: Joi.when('use_click_through', {
                is: false,
                then: Joi.boolean().valid(true),
            })
                .required()
                .messages({
                    'any.only': 'Enable at least 1 attribution model',
                }),
            click_through_attribution_window: Joi.number().integer(),
            view_through_attribution_window: Joi.number().integer(),
            use_dynamic_data_subevent: Joi.boolean().required(),
            use_dynamic_data_value: Joi.boolean().required(),
            dynamic_data_subevent_name: Joi.when('use_dynamic_data_subevent', {
                is: true,
                then: Joi.string()
                    .regex(/^[A-Za-z0-9\s\-.~][A-Za-z0-9\s_./&+-]*$/)
                    .required()
                    .messages({
                        'string.empty': 'GTM Variable cannot be empty',
                        'string.pattern.base':
                            "The name cannot start with '_' or contain a reserved character (!, @, #, $, <, etc)",
                    }),
            }),
            dynamic_data_value_name: Joi.when('use_dynamic_data_value', {
                is: true,
                then: Joi.string()
                    .regex(/^[A-Za-z0-9\s\-.~][A-Za-z0-9\s_./&+-]*$/)
                    .required()
                    .messages({
                        'string.empty': 'GTM Variable cannot be empty',
                        'string.pattern.base':
                            "The name cannot start with '_' or contain a reserved character (!, @, #, $, <, etc)",
                    }),
            }),
            should_optimize_for_budget_allocation: Joi.boolean(),
        }).allow(null)
    )
    .custom((conversions, helpers) => {
        const { campaign, advertiserConversionsDraft } = helpers.prefs.context;

        const allConversions = [...conversions, ...advertiserConversionsDraft];

        if (
            campaign &&
            campaign.automaticBudgetAllocationOptimizationStrategy ===
                CampaignAutoBudgetAllocationOptions.CPA
        ) {
            if (allConversions.length === 0) {
                return helpers.message(
                    'Create at least 1 pixel to track conversions and enable CPA Optimization features.'
                );
            }

            const allConversionOptimizationOff = _.every(
                allConversions,
                conversion => !conversion.should_optimize_for_budget_allocation
            );

            if (allConversionOptimizationOff) {
                return helpers.message(
                    'Create at least 1 pixel to track conversions and enable CPA Optimization features.'
                );
            }
        }

        const hasAtLeastOneAdWithCreativeRotationOptimizedForConversions =
            _.findIndex(
                campaign.ads,
                ad =>
                    ad.rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForConversions
            ) > -1;

        if (
            hasAtLeastOneAdWithCreativeRotationOptimizedForConversions &&
            allConversions.length === 0
        ) {
            return helpers.message(
                'At least one conversion event is required for any campaign with creative rotation "Optimize for Conversions" at ad line level'
            );
        }

        return conversions;
    });

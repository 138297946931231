import _ from 'lodash';
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GetAppIcon from '@mui/icons-material/GetApp';
import { isInternalUser } from 'states/profile/business-rules';

import { useReportExplore } from '../../redux';

const EXPORT_OPTIONS = [
    { label: 'CSV', name: 'csv' },
    { label: 'XLS', name: 'xls' },
];

function ExportButtonContainer() {
    const { exportTable } = useReportExplore();

    return <ExportButton exportTable={exportTable} />;
}

function ExportButton({ exportTable }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = option => {
        exportTable(option.name);
        handleClose();
    };

    let exportOptions = EXPORT_OPTIONS;
    if (isInternalUser()) {
        exportOptions = [...exportOptions, { label: 'JSON', name: 'json' }];
    }

    return (
        <React.Fragment>
            <Button onClick={handleClick} endIcon={<GetAppIcon />}>
                Export
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {_.map(exportOptions, option => (
                    <MenuItem key={option.name} onClick={() => handleSelect(option)}>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}

export default ExportButtonContainer;

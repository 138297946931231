import _ from 'lodash';

import { getEnvironmentSettings } from 'services/environment';

export const CANADIAN_US_CARRIERS_ISPs = [
    { value: 'AIRTEL', label: 'Airtel' },
    { value: 'ALIANT', label: 'Aliant' },
    { value: 'ALLTEL', label: 'Alltel' },
    { value: 'ALO MOBILE INC', label: 'ALO Mobile Inc' },
    { value: 'AT&T', label: 'AT&T' },
    { value: 'BELL', label: 'Bell' },
    { value: 'C SPIRE', label: 'C Spire' },
    { value: 'CELLCOM', label: 'Cellcom' },
    { value: 'CELLULAR ONE', label: 'Cellular One' },
    { value: 'CITYTEL MOBILITY', label: 'CityTel Mobility' },
    { value: 'COGECO', label: 'Cogeco Inc' },
    { value: 'EXECULINK', label: 'Execulink' },
    { value: 'FIDO', label: 'Fido' },
    { value: 'FIRST', label: 'FIRST' },
    { value: 'GLOBALSTAR', label: 'Globalstar' },
    { value: 'ICE WIRELESS', label: 'Ice Wireless' },
    { value: 'LYNX MOBILITY', label: 'Lynx Mobility' },
    { value: 'METRO PCS', label: 'Metro PCS' },
    { value: 'MTS', label: 'MTS' },
    { value: 'NEXTEL', label: 'Nextel' },
    { value: 'NTT DOCOMO', label: 'NTT DoCoMo' },
    { value: 'PINE CELLULAR', label: 'Pine Cellular' },
    { value: 'PUBLIC MOBILE IN', label: 'Public Mobile Inc' },
    { value: 'ROGERS', label: 'Rogers' },
    { value: 'SASKTEL', label: 'SaskTel' },
    { value: 'SHAW TELECOM G.P.', label: 'Shaw Telecom G.P.' },
    { value: 'SPRINT', label: 'Sprint' },
    { value: 'SSI CONNEXIONS', label: 'SSi Connexions' },
    { value: 'T-MOBILE', label: 'T-Mobile' },
    { value: 'TBAY MOBILITY', label: 'Tbay Mobility' },
    { value: 'TELUS', label: 'Telus' },
    { value: 'TERRESTAR SOLUTIONS', label: 'TerreStar Solutions' },
    { value: 'VERIZON', label: 'Verizon' },
    { value: 'VIDEOTRON', label: 'Videotron' },
    { value: 'VIRGIN MOBILE', label: 'Virgin Mobile' },
    { value: 'WIGHTMAN TELECOM', label: 'Wightman Telecom' },
];

export const CANADIAN_ISPs = [
    { value: 'ALIANT', label: 'Aliant' },
    { value: 'BELL', label: 'Bell' },
    { value: 'COGECO', label: 'Cogeco Inc' },
    { value: 'EXECULINK', label: 'Execulink' },
    { value: 'FIDO', label: 'Fido' },
    { value: 'ROGERS', label: 'Rogers' },
    { value: 'SASKTEL', label: 'SaskTel' },
    { value: 'SHAW TELECOM G.P.', label: 'Shaw Telecom G.P.' },
    { value: 'SSI CONNEXIONS', label: 'SSi Connexions' },
    { value: 'TELUS', label: 'Telus' },
    { value: 'VIRGIN MOBILE', label: 'Virgin Mobile' },
];

export const SPAIN_CARRIERS = [
    // { value: 'MOVISTAR', label: 'Movistar' },
    // { value: 'ORANGE', label: 'Orange' },
    // { value: 'VODAFONE', label: 'Vodafone' },
    // { value: 'YOIGO', label: 'Yoigo' },
    // { value: 'JAZZTEL', label: 'JazzTel' },
    // { value: 'MASMOVIL', label: 'MasMóvil' },
    // { value: 'DIGI', label: 'Digi' },
    { value: 'foreign', label: 'Foreign Carriers' },
];

export const SPAIN_ISPS = [
    // { value: 'ORANGE', label: 'Orange' },
    // { value: 'VODAFONE', label: 'Vodafone' },
    // { value: 'YOIGO', label: 'Yoigo' },
    // { value: 'JAZZTEL', label: 'JazzTel' },
    // { value: 'MASMOVIL', label: 'MasMóvil' },
    // { value: 'DIGI', label: 'Digi' },
    // { value: 'ONO', label: 'Ono' },
    // { value: 'TELEFONICA', label: 'Telefónica' },
    { value: 'foreign', label: 'Foreign ISPs' },
];

const getDescription = ({ option }) => {
    const environmentSettings = getEnvironmentSettings();

    const ispValues = _.map(environmentSettings.getISPs(), option => option.value);
    const carrierValues = _.map(environmentSettings.getCarriers(), option => option.value);
    if (_.includes(ispValues, option.value) && _.includes(carrierValues, option.value)) {
        return 'Carrier, ISP';
    }
    if (_.includes(ispValues, option.value) && !_.includes(carrierValues, option.value)) {
        return 'ISP';
    }
    if (!_.includes(ispValues, option.value) && _.includes(carrierValues, option.value)) {
        return 'Carrier';
    }
};

const getCarriersISPsMapping = () => {
    const environmentSettings = getEnvironmentSettings();

    const mapping = {};
    const allCarriersIsp = _.concat(
        environmentSettings.getCarriers(),
        environmentSettings.getISPs()
    );
    const uniqueCarriersAndIsps = _.uniqBy(allCarriersIsp, 'value');

    _.forEach(uniqueCarriersAndIsps, option => {
        mapping[option.value] = option.label;
    });

    return mapping;
};

export const getCarrierIspOptions = ({ platform, isCrossPlatformCampaign }) => {
    const environmentSettings = getEnvironmentSettings();

    if (isCrossPlatformCampaign) {
        const carrierIspOptions = _.concat(
            environmentSettings.getCarriers(),
            environmentSettings.getISPs()
        );
        const uniqueCarriers = _.uniqBy(carrierIspOptions, 'value');
        const carriersWithSupportedField = _.map(uniqueCarriers, carrier => ({
            ...carrier,
            description: getDescription({ option: carrier }),
        }));

        return carriersWithSupportedField;
    } else {
        if (platform === 'desktop') {
            return environmentSettings.getISPs();
        } else {
            return environmentSettings.getCarriers();
        }
    }
};

export const getTargetCarriersInclude = obj => {
    const carriersISPsMapping = getCarriersISPsMapping();

    if (_.isArray(obj) && obj.length === 0) {
        return 'All carriers / ISPs';
    } else if (_.isArray(obj)) {
        return _.map(obj, i => carriersISPsMapping[i]).join(', ');
    } else {
        return '';
    }
};

export const getTargetCarriersExclude = obj => {
    const carriersISPsMapping = getCarriersISPsMapping();

    if (_.isArray(obj) && obj.length === 0) {
        return 'None';
    } else if (_.isArray(obj)) {
        return _.map(obj, i => carriersISPsMapping[i]).join(', ');
    } else {
        return '';
    }
};

export const getMobileCarrierOptionValues = () => {
    const environmentSettings = getEnvironmentSettings();

    return _.map(environmentSettings.getCarriers(), option => option.value);
};

export const getDesktopIspOptionValues = () => {
    const environmentSettings = getEnvironmentSettings();

    return _.map(environmentSettings.getISPs(), option => option.value);
};

import _ from 'lodash';

export default function selector(storeState) {
    const draft = _.get(storeState, `bulkUploadCreativesForm.draft`);
    const creativeFormat = _.get(storeState, `bulkUploadCreativesForm.format`);
    const errors = _.get(storeState, `bulkUploadCreativesForm.errors`);
    const showErrors = _.get(storeState, `bulkUploadCreativesForm.showErrors`);
    const loadingCreativeAssets = _.get(
        storeState,
        `bulkUploadCreativesForm.loadingCreativeAssets`
    );
    return {
        draft,
        creativeFormat,
        errors,
        showErrors,
        loadingCreativeAssets,
    };
}

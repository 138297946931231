import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import React, { useState, useEffect } from 'react';
import { getEnv, getEnvironmentSettings } from 'services/environment';

import { getAdDateRules } from 'states/resources/ads/business-logic';
import FormField from 'widgets-v5/form-field';
import StandardSelector from 'widgets-v5/standard-selector';
import DateTimeRangePicker from 'widgets/date-time-range-picker';
import Spacer from 'widgets-v5/spacer';
import { SingleSelect } from 'widgets-v6/select';

import { Weekparts } from '../checkbox-groups';
import Dayparts from '../dayparts';

import dict_timezone from 'common/constants/timezones';
const _getTimezoneOptions = () => {
    const environmentSettings = getEnvironmentSettings();
    const countryCodes = environmentSettings.getTimezoneCountryCodes();

    const validTimezoneEntries = _.pickBy(dict_timezone, (value, countryCode) => {
        return _.includes(countryCodes, countryCode);
    });

    const timezones = _(validTimezoneEntries)
        .map(v => v)
        .flatten()
        .map(v => ({
            label: `${v[1]}${v[3] ? ', ' + v[3] : ''} - ${v[2]}`,
            value: `${v[0]}`,
        }))
        .value();

    return timezones;
};

class Schedule extends React.Component {
    state = {};
    UNSAFE_componentWillMount() {
        const { formData } = this.props;
        // to make sure one of weekparts or weekparts_local is null instead of empty array
        if (_.isArray(formData.weekparts) && _.isArray(formData.weekparts_local)) {
            if (formData.weekparts.length === 0) {
                this.props.onFieldChange('weekparts')(null);
            }

            if (formData.weekparts_local.length === 0) {
                this.props.onFieldChange('weekparts_local')(null);
            }
        }

        if (_.isArray(formData.dayparts) && _.isArray(formData.dayparts_local)) {
            if (formData.dayparts_local.length === 0) {
                this.props.onFieldChange('dayparts_local')(null);
            } else if (formData.dayparts.length === 0) {
                this.props.onFieldChange('dayparts')(null);
            }
        }
    }
    componentDidMount() {
        this.setState({
            originalWeekpartsLocal: this.props.formData.weekparts_local,
            originalDaypartsLocal: this.props.formData.dayparts_local,
        });
    }

    startAsSoonAsPossible = () => {
        // emit action which will set `startNow` property in session to true or false
        // That will cause the date time widget to emit the current date
        this.props.startAsSoonAsPossible();
    };

    handleWeekpartButtonSwitcher = (newPos, oldPos) => {
        switch (true) {
            case newPos === oldPos:
                return;
            // break;
            case newPos === 'right':
                this.props.onFieldChange('weekparts')(this.props.formData.weekparts_local);
                this.props.onFieldChange('weekparts_local')(null);
                break;
            case newPos === 'left':
                this.props.onFieldChange('weekparts_local')(this.props.formData.weekparts);
                this.props.onFieldChange('weekparts')(null);
        }
    };

    handleDaypartButtonSwitcher = (newPos, oldPos) => {
        switch (true) {
            case newPos === oldPos:
                return;
            // break;
            case newPos === 'right':
                this.props.onFieldChange('dayparts')(this.props.formData.dayparts_local);
                this.props.onFieldChange('dayparts_local')(null);
                break;
            case newPos === 'left':
                this.props.onFieldChange('dayparts_local')(this.props.formData.dayparts);
                this.props.onFieldChange('dayparts')(null);
        }
    };

    handleDateTimeRangePickerChange = ({ start, end }) => {
        if (start !== undefined) {
            this.props.onFieldChange('start')(start ? start.toISOString() : '');
        }
        if (end !== undefined) {
            this.props.onFieldChange('end')(end ? end.toISOString() : '');
        }
    };

    render() {
        const { formData, errors, showErrors, startNow, adId, isDelivering, isStale } = this.props;

        const dateRules = getAdDateRules({ isStale, isDelivering });
        const now = moment.utc();

        return (
            <div>
                <div className="ef5-ad-setup__row">
                    <div className="ef5-ad-setup__column">
                        <FormField
                            label="Timezone"
                            description="Search by nearest major city or name of timezone"
                            errors={errors.timezone}
                            showErrors={showErrors}
                            isRequired={true}
                            marginBottom={25}
                        >
                            <StandardSelector
                                virtual
                                searchable
                                value={formData.timezone}
                                disabled={false}
                                placeholder="Timezone"
                                items={_getTimezoneOptions()}
                                onChange={value => {
                                    if (value === null) {
                                        return;
                                    }
                                    this.props.onFieldChange('timezone')(value);
                                }}
                            />
                        </FormField>
                    </div>
                    <div className="ef5-ad-setup__column">
                        <FormField
                            label="Start / End"
                            errors={errors.start || errors.end || []}
                            showErrors={showErrors}
                            isRequired={true}
                            marginBottom={25}
                        >
                            <DateTimeRangePicker
                                disableStart={dateRules.start === 'locked'}
                                disableEnd={dateRules.end === 'locked'}
                                minEndDate={dateRules.end === 'future' ? now.toISOString() : null}
                                resetable={
                                    dateRules.start !== 'locked' || dateRules.end !== 'locked'
                                }
                                timezone={formData.timezone}
                                startNow={startNow}
                                value={{
                                    start: formData.start === '' ? null : new Date(formData.start),
                                    end: formData.end === '' ? null : new Date(formData.end),
                                }}
                                onChange={this.handleDateTimeRangePickerChange}
                                error={(errors.start || errors.end || []) && showErrors}
                            />
                        </FormField>
                        {adId ? null : (
                            <label>
                                <input
                                    type="checkbox"
                                    id="startASAP"
                                    onClick={this.startAsSoonAsPossible}
                                />
                                <span>Start as soon as possible</span>
                            </label>
                        )}
                    </div>
                </div>
                <Spacer />
                <div className="ef5-ad-setup__row">
                    <Dayparts
                        buttonSW={this.state.originalDaypartsLocal ? true : false}
                        handleButtonSwitcher={this.handleDaypartButtonSwitcher}
                        disabledLeft={getEnv() === 'production' ? true : false}
                        textLeft={'Local Time (Experimental)'}
                        textRight={'Selected Timezone: ' + moment.tz(formData.timezone).format('z')}
                        position={_.isArray(formData.dayparts) ? 'right' : 'left'}
                        isUTCTime={_.isArray(formData.dayparts_local)}
                        valueDayparts={
                            _.isArray(formData.dayparts)
                                ? formData.dayparts
                                : formData.dayparts_local
                        }
                        valueTimeZone={formData.timezone}
                        adStart={formData.start}
                        errors={errors.dayparts}
                        showErrors={showErrors}
                        onChange={this.props.onFieldChange(
                            _.isArray(formData.dayparts) ? 'dayparts' : 'dayparts_local'
                        )}
                    />
                </div>
                <Spacer />
                <div className="ef5-ad-setup__row">
                    <Weekparts
                        buttonSW={this.state.originalWeekpartsLocal ? true : false}
                        handleButtonSwitcher={this.handleWeekpartButtonSwitcher}
                        disabledLeft={getEnv() === 'production' ? true : false}
                        textLeft={'Local Time (Experimental)'}
                        textRight={'UTC'}
                        position={_.isArray(formData.weekparts) ? 'right' : 'left'}
                        value={
                            _.isArray(formData.weekparts)
                                ? formData.weekparts
                                : formData.weekparts_local
                        }
                        errors={
                            _.isArray(formData.weekparts)
                                ? errors.weekparts
                                : errors.weekparts_local
                        }
                        showErrors={showErrors}
                        onChange={this.props.onFieldChange(
                            _.isArray(formData.weekparts) ? 'weekparts' : 'weekparts_local'
                        )}
                    />
                </div>
            </div>
        );
    }
}

export function Timezone(props) {
    return (
        <SingleSelect
            required
            placeholder="Select Timezone"
            value={props.formData}
            onChange={value => {
                if (value === null) {
                    return;
                }
                props.onFieldChange('timezone')(value);
            }}
            options={_getTimezoneOptions()}
            error={props.errors && props.showErrors}
        />
    );
}

export function StartEnd(props) {
    const {
        isStale,
        isDelivering,
        startNow,
        errors,
        showErrors,
        formData,
        onFieldChange,
        shouldShowStartASAP,
    } = props;
    const dateRules = getAdDateRules({ isStale, isDelivering });
    const now = moment.utc();

    const handleDateTimeRangePickerChange = ({ start, end }) => {
        if (start !== undefined) {
            onFieldChange('start')(start ? start.toISOString() : '');
        }
        if (end !== undefined) {
            onFieldChange('end')(end ? end.toISOString() : '');
        }
    };

    const startAsSoonAsPossible = () => {
        // emit action which will set `startNow` property in session to true or false
        // That will cause the date time widget to emit the current date
        props.startAsSoonAsPossible();
    };

    return (
        <div className="ef5-ad-setup__column">
            <FormField
                errors={errors.start || errors.end || []}
                showErrors={showErrors}
                isRequired={true}
                marginBottom={25}
            >
                <DateTimeRangePicker
                    disableStart={dateRules.start === 'locked'}
                    disableEnd={false}
                    minEndDate={dateRules.end === 'future' ? now.toISOString() : null}
                    resetable={dateRules.start !== 'locked' || dateRules.end !== 'locked'}
                    timezone={formData.timezone}
                    startNow={startNow}
                    value={{
                        start: formData.start === '' ? null : new Date(formData.start),
                        end: formData.end === '' ? null : new Date(formData.end),
                    }}
                    onChange={handleDateTimeRangePickerChange}
                    error={(errors.start || errors.end || []) && showErrors}
                />
            </FormField>
            {shouldShowStartASAP && (
                <label>
                    <input type="checkbox" id="startASAP" onClick={startAsSoonAsPossible} />
                    <span>Start as soon as possible</span>
                </label>
            )}
        </div>
    );
}

export function ScheduleDayparts(props) {
    const { formData, errors, showErrors } = props;

    const [state, setState] = useState({});
    useEffect(() => {
        if (_.isArray(formData.dayparts) && _.isArray(formData.dayparts_local)) {
            if (formData.dayparts_local.length === 0) {
                props.onFieldChange('dayparts_local')(null);
            } else if (formData.dayparts.length === 0) {
                props.onFieldChange('dayparts')(null);
            }
        }
        setState({ originalDaypartsLocal: formData.dayparts_local });
    }, []);

    const handleDaypartButtonSwitcher = (newPos, oldPos) => {
        switch (true) {
            case newPos === oldPos:
                return;
            // break;
            case newPos === 'right':
                props.onFieldChange('dayparts')(props.formData.dayparts_local);
                props.onFieldChange('dayparts_local')(null);
                break;
            case newPos === 'left':
                props.onFieldChange('dayparts_local')(props.formData.dayparts);
                props.onFieldChange('dayparts')(null);
        }
    };

    return (
        <Dayparts
            buttonSW={state.originalDaypartsLocal ? true : false}
            handleButtonSwitcher={handleDaypartButtonSwitcher}
            disabledLeft={getEnv() === 'production' ? true : false}
            textLeft={'Local Time (Experimental)'}
            textRight={'Selected Timezone: ' + moment.tz(formData.timezone).format('z')}
            position={_.isArray(formData.dayparts) ? 'right' : 'left'}
            isUTCTime={_.isArray(formData.dayparts_local)}
            valueDayparts={
                _.isArray(formData.dayparts) ? formData.dayparts : formData.dayparts_local
            }
            valueTimeZone={formData.timezone}
            adStart={formData.start}
            errors={errors.dayparts}
            showErrors={showErrors}
            onChange={props.onFieldChange(
                _.isArray(formData.dayparts) ? 'dayparts' : 'dayparts_local'
            )}
        />
    );
}

export function ScheduleWeekparts(props) {
    const { formData, errors, showErrors } = props;

    const [state, setState] = useState({});
    useEffect(() => {
        if (_.isArray(formData.weekparts) && _.isArray(formData.weekparts_local)) {
            if (formData.weekparts.length === 0) {
                props.onFieldChange('weekparts')(null);
            }

            if (formData.weekparts_local.length === 0) {
                props.onFieldChange('weekparts_local')(null);
            }
        }
        setState({ originalWeekpartsLocal: formData.weekparts_local });
    }, []);

    const handleWeekpartButtonSwitcher = (newPos, oldPos) => {
        switch (true) {
            case newPos === oldPos:
                return;
            // break;
            case newPos === 'right':
                props.onFieldChange('weekparts')(props.formData.weekparts_local);
                props.onFieldChange('weekparts_local')(null);
                break;
            case newPos === 'left':
                props.onFieldChange('weekparts_local')(props.formData.weekparts);
                props.onFieldChange('weekparts')(null);
        }
    };

    return (
        <Weekparts
            buttonSW={state.originalWeekpartsLocal ? true : false}
            handleButtonSwitcher={handleWeekpartButtonSwitcher}
            disabledLeft={getEnv() === 'production' ? true : false}
            textLeft={'Local Time (Experimental)'}
            textRight={'UTC'}
            position={_.isArray(formData.weekparts) ? 'right' : 'left'}
            value={_.isArray(formData.weekparts) ? formData.weekparts : formData.weekparts_local}
            errors={_.isArray(formData.weekparts) ? errors.weekparts : errors.weekparts_local}
            showErrors={showErrors}
            onChange={props.onFieldChange(
                _.isArray(formData.weekparts) ? 'weekparts' : 'weekparts_local'
            )}
        />
    );
}

export default Schedule;

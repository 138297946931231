const actions = {};

export function openFilters() {
    return {
        type: 'APPS_REPORT__OPEN_FILTER_OPTIONS',
    };
}

export function closeFilters() {
    return {
        type: 'APPS_REPORT__CLOSE_FILTER_OPTIONS',
    };
}

export function applyFilters(status) {
    return dispatch => {
        dispatch({
            type: 'APPS_REPORT__APPLY_FILTERS_START',
        });

        if (status === 'error') {
            return;
        }

        dispatch(closeFilters());

        dispatch({
            type: 'APPS_REPORT__APPLY_FILTERS_SUCCESS',
        });
    };
}

export function updateDimensions(dimensions) {
    return {
        type: 'APPS_REPORT__UPDATE_DIMENSIONS',
        payload: {
            dimensions,
        },
    };
}

export function updateAttributes(attributes) {
    return {
        type: 'APPS_REPORT__UPDATE_ATTRIBUTES',
        payload: {
            attributes,
        },
    };
}

export function updateMetrics(metrics) {
    return {
        type: 'APPS_REPORT__UPDATE_METRICS',
        payload: {
            metrics,
        },
    };
}

export function deleteDimensions(dimension) {
    return {
        type: 'APPS_REPORT__DELETE_DIMENSION',
        payload: {
            dimension,
        },
    };
}

export function updateLimit(limit) {
    return {
        type: 'APPS_REPORT__UPDATE_LIMIT',
        payload: {
            limit,
        },
    };
}

export default actions;

import _ from 'lodash';
import c from 'common/constants/flux-events';
import createHttp from 'utils/http';
import Tracking from 'services/tracking';
import config from '../../../../config';
import flags from 'containers/flags/service';
import { can } from 'services/can';
import { setFireBaseUserProperties } from 'services/firebase';
import { getInitialState } from './reducer';
import { PermissionsMapping } from 'common/constants/role-intents';

// Given '/campaigns/4125/overview'
// return ["/campaigns/4125", "4125"]
const parseRoute = path => /^\/campaigns\/(\d+)/.exec(path);

const fetchBootstrapData = async () => {
    const http = createHttp();
    const query = `
        query getBootstrapData {
            ownUser {
                id
                email
                first_name
                last_name
                organization
                global_role
                _etag
                preferences {
                    hiddenColumnsOnCampaignAdsListPage
                    notificationsLastSeen
                }
                org {
                    name
                    default_timezone
                    restrictedSegments
                }
            }
            ownOrganization {
                id
                type
                name
                users {
                    id
                    first_name
                    last_name
                }
            }
        }
    `;
    try {
        const data = await http.graphql(query, {});
        return data;
    } catch (error) {
        if (_.get(error, 'response.status') === 401) {
            return;
        }
        throw error;
    }
};

export function bootstrap() {
    return async (dispatch, getState) => {
        const {
            isImpersonating,
            canImpersonate,
            impersonatorsToken,
            authToken,
            isLoggedIn,
        } = getInitialState(flags);
        dispatch({
            type: 'PROFILE__INIT_SUCCESS',
            payload: {
                isImpersonating,
                canImpersonate,
                impersonatorsToken,
                authToken,
                isLoggedIn,
            },
        });
        const { ownUser, ownOrganization } = await fetchBootstrapData();

        if (!ownUser) {
            return;
        }
        dispatch({
            type: c.PROFILE__USER__FETCH__SUCCESS,
            payload: {
                user: ownUser,
                ownOrganization,
            },
        });
        Tracking.setProfile();
        setFireBaseUserProperties();

        if (can(PermissionsMapping.CAMPAIGN__VIEW)) {
            Beamer.init();
        }

        // Prime our 3rd-party QA services with info on the current user, if applicable
        const impersonator = _.get(getState(), `profile.authToken.v3_impersonator`);
        const HEAP_ANALYTICS = _.get(config, 'API_KEYS.HEAP_ANALYTICS');

        // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
        // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
        // Call this function after users are authenticated in your app and your visitor and account id values are available
        // Please use Strings, Numbers, or Bools for value types.
        pendo.initialize({
            visitor: {
                id: ownUser.id, // Required if user is logged in, default creates anonymous ID
                email: ownUser.email, // Recommended if using Pendo Feedback, or NPS Email
                full_name: `${ownUser.first_name} ${ownUser.last_name}`, // Recommended if using Pendo Feedback
                role: ownUser.global_role, // Optional
                organization: ownUser.org.name,

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            // account: {
            //     id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            //     // name:         // Optional
            //     // is_paying:    // Recommended if using Pendo Feedback
            //     // monthly_value:// Recommended if using Pendo Feedback
            //     // planLevel:    // Optional
            //     // planPrice:    // Optional
            //     // creationDate: // Optional

            //     // You can add any additional account level key-values here,
            //     // as long as it's not one of the above reserved names.
            // }
        });

        let userProps;

        if (impersonator) {
            // Identify by impersonator
            userProps = {
                identified_by: 'impersonator',
                first_name: impersonator.first_name,
                last_name: impersonator.last_name,
                organization: impersonator.organization,
                organization_name: impersonator.organization_name,
            };
        } else {
            // Identify by logged in user
            userProps = {
                identified_by: 'logged_in_user',
                first_name: ownUser.first_name,
                last_name: ownUser.last_name,
                organization: ownUser.organization,
                organization_name: ownUser.org.name,
            };
        }

        if (window.heap) {
            try {
                window.heap.load(HEAP_ANALYTICS);
                window.heap.identify(impersonator.email);
                window.heap.addUserProperties(userProps);
            } catch (e) {
                console.error('Heap not loaded, with', e);
            }
        }

        dispatch({
            type: c.SIDEBAR__USERS__FETCH__SUCCESS,
        });

        const path = _.get(getState(), 'routing.path');

        const match = parseRoute(path);

        if (match) {
            dispatch({
                type: c.SIDEBAR__CAMPAIGN_TAB__ADD,
                payload: {
                    campaignId: match[1],
                    path,
                },
            });
        }
    };
}

// ------------
// Authentication
// ------------
export function logout() {
    return (dispatch, getState) => {
        dispatch({
            type: 'LOGOUT',
            payload: {
                isImpersonating: _.get(getState(), 'profile.isImpersonating'),
            },
        });
    };
}
export function login(profile) {
    return {
        type: 'LOGIN',
        payload: {
            ...profile,
        },
    };
}

// -----------
// Navigation
// -----------
export function navigate() {
    return { type: c.SYSTEM__NAVIGATE };
}

export function globalRefresh(nextState) {
    const { location, params, routes } = nextState;

    if (!location || !params || !routes) {
        throw new Error('globalRefresh: missing parameters');
    }

    dataFetchStream.push({
        nextState,
        replaceState: _.noop,
    });

    return {
        type: c.SYSTEM__GLOBAL_REFRESH,
        payload: {},
    };
}

import React, { useState, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import HomeWork from '@mui/icons-material/HomeWork';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';

import { formatNumber_whole, formatNumber_currency } from 'utils/formatting';

import { AudienceDetails } from '../modules/audience-details';
import { AudienceTable } from '../modules/audience-table';
import { AudienceCard } from '../modules/audience-card';

const sortingOptions = [
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
];

export const environicsMetaDataDescriptions = [
    {
        getValue: ({ segment }) =>
            formatNumber_whole(segment.environicsMetadata.householdCount) || 'N/A',
        label: 'Households',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'environicsMetadata.incomeLevel', 'N/A') || 'N/A',
        label: 'Income Level',
    },
    {
        getValue: ({ segment }) =>
            _.get(segment, 'environicsMetadata.officialLanguage', 'N/A') || 'N/A',
        label: 'Official Language',
    },
    {
        getValue: ({ segment }) =>
            _.get(segment, 'environicsMetadata.ageOfChildren', 'N/A') || 'N/A',
        label: 'Age of Children',
    },
    {
        getValue: ({ segment }) =>
            `${formatNumber_whole(segment.environicsMetadata.householdsWithChildren)}%` || 'N/A',
        label: 'Households with Children',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'environicsMetadata.jobType', 'N/A') || 'N/A',
        label: 'Job Type',
    },
    {
        getValue: ({ segment }) =>
            _.get(segment, 'environicsMetadata.dwellingType', 'N/A') || 'N/A',
        label: 'Dwelling Type',
    },
    {
        getValue: ({ segment }) => formatNumber_currency(segment.environicsMetadata.averageIncome),
        label: 'Average Income',
    },
    {
        getValue: ({ segment }) =>
            _.get(segment, 'environicsMetadata.familyStatus', 'N/A') || 'N/A',
        label: 'Family Status',
    },
    {
        getValue: ({ segment }) =>
            _.get(segment, 'environicsMetadata.culturalDiversityIndex', 'N/A') || 'N/A',
        label: 'Cultural Diversity Index',
    },
    {
        getValue: ({ segment }) =>
            _.get(segment, 'environicsMetadata.ageOfMaintainer', 'N/A') || 'N/A',
        label: 'Age of Maintainer',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'environicsMetadata.education', 'N/A') || 'N/A',
        label: 'Education',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'environicsMetadata.ownOrRent', 'N/A') || 'N/A',
        label: 'Own/Rent',
    },
];

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: 400,
        width: '100%',
    },
    card: {
        height: '100%',
    },
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: '3.5em',
        height: '3.5em',
        color: '#098679',
    },
}));

const EnvironicsLayout = ({ audienceSegments, onSearch, activeAudience, organizationId }) => {
    const classes = useStyles();
    const [audiences, setAudiences] = useState(audienceSegments);
    const [segmentInFocus, setSegmentInFocus] = useState(null);
    const [activeSort, setActiveSort] = useState({ value: 'name', direction: 'asc' });

    useEffect(() => {
        setAudiences(audienceSegments);
    }, [audienceSegments]);

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const handleSortChange = event => {
        const { value, direction } = _.find(sortingOptions, { value: event.target.value });
        setActiveSort({ value, direction });
    };

    const handleSegmentSelect = segment => {
        setSegmentInFocus(segment);
    };

    const handleClose = () => {
        setSegmentInFocus(null);
    };
    const sortedAudiences = _.orderBy(audiences, [activeSort.value], [activeSort.direction]);

    const goToNextAudience = () => {
        const currentAudienceIndex = _.findIndex(
            sortedAudiences,
            audience => audience.id === segmentInFocus.id
        );

        const nextSegment = sortedAudiences[currentAudienceIndex + 1];

        setSegmentInFocus(nextSegment);
    };

    const goToPrevAudience = () => {
        const currentAudienceIndex = _.findIndex(
            sortedAudiences,
            audience => audience.id === segmentInFocus.id
        );

        const nextSegment = sortedAudiences[currentAudienceIndex - 1];

        setSegmentInFocus(nextSegment);
    };

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'pelmorex' },
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Pelmorex Audiences
                </RouterLink>
                <Typography color="textPrimary">Environics</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <HomeWork className={classes.icon} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            Environics
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            Environics PRIZM5 categorizes Canadian neighbourhoods into one of 68
                            distinct lifestyle segments based on the characteristics of households.
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Box mt={3}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Total
                                    </Typography>
                                    <Typography variant="h5">
                                        {audienceSegments ? audienceSegments.length : 0}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mb={2.5} mt={5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <SearchInput placeholder="Filter Audiences" onChange={handleSearch} />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort.value}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <AudienceTable
                    audiences={sortedAudiences}
                    cardSize="small"
                    activeAudience={activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            segment={segment}
                            onClick={() => handleSegmentSelect(segment)}
                            active={segment.id === activeAudience}
                            organizationId={organizationId}
                        />
                    )}
                />

                {!_.isNil(segmentInFocus) && (
                    <AudienceDetails
                        isOpen={!_.isNil(segmentInFocus)}
                        segment={segmentInFocus}
                        onClose={handleClose}
                        onClickNextAudience={goToNextAudience}
                        onClickPrevAudience={goToPrevAudience}
                        isLastSegment={
                            _.findIndex(sortedAudiences, { id: segmentInFocus.id }) ===
                            sortedAudiences.length - 1
                        }
                        isFirstSegment={
                            _.findIndex(sortedAudiences, { id: segmentInFocus.id }) === 0
                        }
                        body={
                            <React.Fragment>
                                <Grid container spacing={2}>
                                    {_.map(environicsMetaDataDescriptions, description => (
                                        <Grid item xs={4} key={description.label}>
                                            <Card
                                                elevation={0}
                                                variant="outlined"
                                                className={classes.card}
                                            >
                                                <CardContent>
                                                    <Typography gutterBottom align="center">
                                                        {description.label}
                                                    </Typography>
                                                    <Typography align="center">
                                                        {description.getValue({
                                                            segment: segmentInFocus,
                                                        })}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </React.Fragment>
                        }
                    />
                )}
            </Container>
        </Box>
    );
};

export default EnvironicsLayout;

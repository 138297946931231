import _ from 'lodash';
import React from 'react';
import { styled } from '@mui/styles';
import { darken } from '@mui/system';
import Chip from '@mui/material/Chip';

export const HealthChip = styled(props => <Chip {...props} />)({
    backgroundColor: props => props.customcolor,
    color: 'white',
    '&:hover': {
        backgroundColor: props => darken(props.customcolor, 0.1),
    },
    '& > .healthAvatar': {
        backgroundColor: props => darken(props.customcolor, 0.15),
        color: 'white',
    },
});

import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.adGroupings';

export default function adGroupings(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__CAMPAIGN_ADS_GROUPINGS__FETCH__SUCCESS: {
            const { campaignId, adGrouping, adGroupings } = action.payload;
            return mergeResource(
                state,
                []
                    .concat(adGrouping)
                    .concat(adGroupings)
                    .filter(x => x)
            );
        }

        case c.OVERVIEW_ADS_GROUPINGS__LIST__GROUPING_TOGGLE_VISIBILITY__SUCCESS: {
            const { adGrouping, adGroupingId } = action.payload;
            return mergeResource(state, [].concat(adGrouping).filter(x => x));
        }
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

class CustomizableSelect extends React.Component {
    static displayName = 'StandardSelect';

    static propTypes = {
        label: PropTypes.string,
        searchable: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.string,
        clearable: PropTypes.bool,
        allowCustom: PropTypes.bool,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            })
        ),
    };

    static defaultProps = {
        clearable: false,
    };

    constructor(props) {
        super(props);

        const currentlySelectedValueIsCustom = !_.find(this.props.options, {
            value: this.props.value,
        });

        let options = this.props.options;
        if (currentlySelectedValueIsCustom) {
            // Append the custom value to the predefined options so that it shows up in the dropdown
            options = this.props.options.concat({
                value: this.props.value,
                label: this.props.value,
            });
        } else {
            options = this.props.options;
        }

        this.state = {
            options,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.value = nextProps.value;

        if (!this.state.options.find(this.findDuplicate(nextProps.value))) {
            this.setState({
                options: [
                    ...this.state.options,
                    {
                        value: nextProps.value,
                        label: nextProps.label,
                    },
                ],
            });
        }
    }

    componentDidMount() {
        this.value = this.props.value;
    }

    getValue = () => {
        return this.value;
    };

    findDuplicate = value => {
        return function(option) {
            if (option.value === value) {
                return true;
            }
        };
    };

    updateValue = selectValue => {
        this.value = selectValue;

        if (!this.state.options.find(this.findDuplicate(selectValue))) {
            // This is a dirty hack that updates the Select' object reference to
            // what's in its options list. Without this, there's no easy or obvious way
            // to display the value of dynamically added beacon name after it's been chosen
            this.state.options.splice(0, 0, { value: selectValue, label: selectValue });
        }

        this.props.onChange(selectValue);
    };

    includeCustomOption = (input, callback) => {
        if (input.length > 0 && !this.state.options.find(this.findDuplicate(input))) {
            return callback(null, {
                options: [...this.state.options, { value: input, label: input }],
            });
        }

        return callback(null, {
            options: this.state.options,
        });
    };

    render() {
        if (this.props.allowCustom) {
            return (
                <div className="ef3-reactSelect">
                    <Select.Async
                        autoFocus={this.props.autofocus}
                        loadOptions={this.includeCustomOption}
                        simpleValue
                        isLoading={this.props.isLoading}
                        placeholder={this.props.placeholder}
                        clearable={this.props.clearable}
                        name={this.props.label}
                        disabled={this.props.disabled}
                        value={this.props.value}
                        onChange={this.updateValue}
                        searchable={this.props.searchable}
                    />
                </div>
            );
        }
        return (
            <div className="ef3-reactSelect">
                <Select
                    autofocus={this.props.autofocus}
                    options={this.state.options}
                    simpleValue
                    isLoading={this.props.isLoading}
                    placeholder={this.props.placeholder}
                    clearable={this.props.clearable}
                    name={this.props.label}
                    disabled={this.props.disabled}
                    value={this.props.value}
                    onChange={this.updateValue}
                    searchable={this.props.searchable}
                />
            </div>
        );
    }
}

export default CustomizableSelect;

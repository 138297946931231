// --------------
// Default values
// --------------
export { default as draftDefaultValues } from './default-values';

// ----------
// Validation
// ----------
import validationRules from './validation-rules';
import { default as validator } from 'utils/validate';
export function validateDraft(draft) {
    const errors = validator(draft, validationRules);
    return errors;
}

// -----------------------------------------
// Data transformation before sending to api
// -----------------------------------------
export { default as serializedForApi } from './serialize-for-api';

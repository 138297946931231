import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { calculateAudienceFee } from 'states/resources/ads/business-logic';
import AudienceSegmentPicker from 'containers/audience-segment-picker';
import FormField from 'widgets-v5/form-field';
import Draft from 'widgets-v5/draft';

class AudiencesForm extends React.Component {
    static propTypes = {
        audiences: PropTypes.array,
        audience_exclude: PropTypes.array,

        audienceRates: PropTypes.array,
        showErrors: PropTypes.bool,
        onDraftChange: PropTypes.func,
    };

    static defaultProps = {
        audiences: [],
        audience_exclude: [],
        audience_fee: 0,
    };

    state = {
        initialDraft: {
            audiences: this.props.audiences,
            audience_exclude: this.props.audience_exclude,
            audience_fee: this.props.audience_fee,
            geo_targeting_settings: this.props.geo_targeting_settings,
        },
        schema: {
            audiences() {
                return value => {
                    if (!value) {
                        return 'invalid value';
                    }
                };
            },
            audience_exclude() {
                return value => {
                    if (!value) {
                        return 'invalid value';
                    }
                };
            },
            audience_fee(value) {
                if (!_.isNumber(value)) {
                    throw new Error('Expected Audience Fee to be a number');
                }
            },
            __require_selection(source) {
                const selections = [].concat(source.audiences, source.audience_exclude);

                if (selections.length === 0) {
                    return 'An Audience must be specified for this tactic';
                }
            },
        },
    };

    render() {
        const {
            showErrors,
            onDraftChange,
            audienceRates,
            action,
            onClickEditButton,
            platform,
            campaignId,
            shouldHideFlexSegments,
            platforms,
            isCTVCampaign,
            isDOOHCampaign,
            audienceWarning,
        } = this.props;

        const { initialDraft, schema } = this.state;

        return (
            <Draft initialDraft={initialDraft} schema={schema} onDraftChange={onDraftChange}>
                {({ draft, errors, updateDraft }) => {
                    return (
                        <FormField
                            description=""
                            showErrors={showErrors}
                            isRequired={false}
                            errors={_.map(errors, e => e.message)}
                        >
                            <AudienceSegmentPicker
                                platform={platform}
                                platforms={platforms}
                                onClickEditButton={onClickEditButton}
                                action={action}
                                onChange={({
                                    includedItems,
                                    excludedItems,
                                    geo_targeting_settings,
                                }) => {
                                    const audienceFee = calculateAudienceFee({
                                        includedAudiences: includedItems,
                                        excludedAudiences: excludedItems,
                                        audienceRates: audienceRates,
                                    });
                                    const draft = !shouldHideFlexSegments
                                        ? {
                                              audiences: _.map(includedItems, item => item.value),
                                              audience_exclude: _.map(
                                                  excludedItems,
                                                  item => item.value
                                              ),
                                              audience_fee: audienceFee,
                                              geo_targeting_settings,
                                          }
                                        : {
                                              audiences: _.map(includedItems, item => item.value),
                                              audience_exclude: _.map(
                                                  excludedItems,
                                                  item => item.value
                                              ),
                                              audience_fee: audienceFee,
                                          };
                                    updateDraft(draft);
                                }}
                                geo_targeting_settings={draft.geo_targeting_settings}
                                includedAudiences={_.map(draft.audiences, String)}
                                excludedAudiences={_.map(draft.audience_exclude, String)}
                                audienceRates={audienceRates}
                                campaignId={campaignId}
                                audienceWarning={audienceWarning}
                                isCTVCampaign={isCTVCampaign}
                                isDOOHCampaign={isDOOHCampaign}
                            />
                        </FormField>
                    );
                }}
            </Draft>
        );
    }
}

function mapState(storeState, ownProps) {
    let platform = _.get(storeState, 'adForm.form.draft.platform');
    if (ownProps.platform) {
        platform = ownProps.platform;
    }

    return {
        platform,
    };
}

export default connect(mapState)(AudiencesForm);

import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import makeStyles from '@mui/styles/makeStyles';

import { CreativeBulkEdit } from '../../actions';

const useStyles = makeStyles(theme => ({
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    alert: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
}));

const matchOptionsToSelect = [
    { value: 'name', label: 'Name' },
    { value: 'creativeId', label: 'Creative ID' },
];

const MatchUpload = ({ needMatch, fileData, selectedCreatives }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(needMatch);
    const [option, setOption] = useState('creativeId');

    const handleConfirm = () => {
        const { csvHeaders, parsedCsvRows } = fileData;
        dispatch(
            CreativeBulkEdit.readGenericTemplateData(
                csvHeaders,
                parsedCsvRows,
                selectedCreatives,
                option
            )
        );
        setIsOpen(false);
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={() => setIsOpen(false)}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                Match uploaded edits based on
            </DialogTitle>
            <DialogContent>
                <RadioGroup
                    aria-label="matchOption"
                    name="match_option"
                    value={option}
                    onChange={event => setOption(event.target.value)}
                >
                    {matchOptionsToSelect.map(({ value, label }) => (
                        <div key={value}>
                            <FormControlLabel
                                key={value}
                                value={value}
                                control={<Radio />}
                                label={label}
                            />
                        </div>
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleConfirm}>
                    Confirm
                </Button>
                <Button variant="outlined" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect()(MatchUpload);

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import InlineTooltip from 'widgets-v5/inline-tooltip';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import StandardSelector from 'widgets-v5/standard-selector';
import { Caption, Title, Subheading } from 'widgets-v5/typography';
import Spacer from 'widgets-v5/spacer';
import { InfoIcon, ExpandCollapseIcon } from 'widgets-v5/icons';
import {
    Flexbox,
    CenterLayout,
    VerticalCenterLayout,
    AlignRight,
    SpaceBetweenLayout,
    Flex,
} from 'widgets-v5/layout';
import { WarningBanner } from 'widgets-v5/banners';

import { formatNumber_whole } from 'utils/formatting';

function calculateBarWidth2(impressionGoal, predictedImpressions) {
    let rawPercentage = predictedImpressions / impressionGoal;
    let roundedPercentage = Math.round(rawPercentage * 100);

    // rawPercentage of 100% should display a bar of 50% width
    // rawPercentage of 200% should display a bar of 100% width
    // const halfedWidth = roundedPercentage / 2;

    // Cap width at 100
    return Math.min(100, roundedPercentage);
}

export class InventoryIndicatorWithDailyPrediction extends React.Component {
    static propTypes = {
        isOpenByDefault: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        error: PropTypes.string,

        impressionGoal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        predictedImpressions: PropTypes.number,
        uniqueUsers: PropTypes.number,
    };

    static defaultProps = {
        goal: true,
        dailyGoal: true,
    };

    state = {
        isToggled: this.props.isOpenByDefault || false,
        target: 'total',
    };

    toggleIndicator = () => {
        this.setState({ isToggled: !this.state.isToggled });
    };

    toggleTarget = () => {
        this.setState({
            target: this.state.target === 'total' ? 'daily' : 'total',
        });
    };

    getPredictedImpressionsMin = () => {
        if (this.state.target === 'daily') {
            return formatNumber_whole(this.props.predictedImpressionsMin / this.props.daysRunning);
        } else if (this.state.target === 'total') {
            return formatNumber_whole(this.props.predictedImpressionsMin);
        }
    };

    getPredictedImpressionsMax = () => {
        if (this.state.target === 'daily') {
            return formatNumber_whole(this.props.predictedImpressionsMax / this.props.daysRunning);
        } else if (this.state.target === 'total') {
            return formatNumber_whole(this.props.predictedImpressionsMax);
        }
    };

    render() {
        const {
            impressionGoal,
            daysRunning,
        } = this.props;

        let targetImpressionGoal;

        if (this.state.target === 'total') {
            targetImpressionGoal = impressionGoal;
        } else if (this.state.target === 'daily') {
            targetImpressionGoal = impressionGoal / daysRunning;
        }

        let predictedImpressionsMinBarWidth2 = calculateBarWidth2(
            impressionGoal,
            this.props.predictedImpressionsMin
        );

        return (
            <React.Fragment>
                <InventoryIndicatorLayout>
                    <VerticalCenterLayout>
                        <Subheading>Inventory Forecast</Subheading>
                    </VerticalCenterLayout>
                    <BlockLoadGroup isLoading={this.props.isLoading}>
                        {this.props.error ? (
                            <React.Fragment>
                                <Spacer type="small" />
                                <Flex>
                                    <WarningBanner content={this.props.error} />
                                </Flex>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {this.props.dailyGoal === false && <Spacer type="small" />}
                                <CenterLayout>
                                    <Flexbox flex={4}>
                                        <Caption>Estimated Impressions</Caption>
                                    </Flexbox>
                                    <Flexbox flex={1}>
                                        {(this.props.error === (null || '') ||
                                            this.props.dailyGoal) && (
                                            <StandardSelector
                                                onChange={this.toggleTarget}
                                                value={this.state.target}
                                                items={[
                                                    { label: 'Total', value: 'total' },
                                                    { label: 'Daily', value: 'daily' },
                                                ]}
                                                bordered={false}
                                            />
                                        )}
                                    </Flexbox>
                                </CenterLayout>
                                {this.props.dailyGoal === false && <Spacer type="small" />}
                                <Title>{this.getPredictedImpressionsMin()}</Title>
                                <Spacer type="small" />
                                {this.props.goal && (
                                    <InventoryIndicatorGraph
                                        percentageFilled={predictedImpressionsMinBarWidth2}
                                    />
                                )}
                                {this.props.goal && (
                                    <AlignRight>
                                        <Caption>
                                            Goal: {formatNumber_whole(targetImpressionGoal)}
                                        </Caption>
                                    </AlignRight>
                                )}
                                <Spacer type="small" />
                                <Separator />
                                <SpaceBetweenLayout>
                                    <Caption>Details</Caption>
                                    <Spacer type="small" />
                                    <ExpandCollapseIcon
                                        onClick={this.toggleIndicator}
                                        isToggled={this.state.isToggled}
                                    />
                                </SpaceBetweenLayout>
                                {this.state.isToggled && (
                                    <React.Fragment>
                                        <InventoryIndicatorDetails
                                            minImpressions={this.getPredictedImpressionsMin()}
                                            maxImpressions={this.getPredictedImpressionsMax()}
                                            minUniqueUsers={formatNumber_whole(
                                                this.props.predictedUniqueUsersMin
                                            )}
                                            maxUniqueUsers={formatNumber_whole(
                                                this.props.predictedUniqueUsersMax
                                            )}
                                        />
                                        <Spacer type="small" />
                                        <InlineTooltip
                                            position="top"
                                            top={-90}
                                            wrapText
                                            tooltip={
                                                <React.Fragment>
                                                    <div>Dayparting</div>
                                                    <div>Weekparting</div>
                                                    <div>Geofencing</div>
                                                    <div>Carrier Networks</div>
                                                    <div>User Language</div>
                                                </React.Fragment>
                                            }
                                        >
                                            <Caption type="info">
                                                Some parameters in this ad have not been considered
                                                in this forecast{' '}
                                                <InfoIcon style={{ paddingLeft: 2 }} />
                                            </Caption>
                                        </InlineTooltip>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </BlockLoadGroup>
                </InventoryIndicatorLayout>
            </React.Fragment>
        );
    }
}

function InventoryIndicatorLayout(props) {
    return <div className="inventory-indicator__layout">{props.children}</div>;
}

function Separator() {
    return <hr />;
}

function InventoryIndicatorGraph({ percentageFilled }) {
    return (
        <div className="inventory-indicator__graph-wrapper">
            <div
                className={classnames(
                    'inventory-indicator__graph',
                    `${
                        percentageFilled >= 100
                            ? 'inventory-indicator__graph--reached'
                            : 'inventory-indicator__graph--not-reached'
                    }`
                )}
                style={{ width: `${percentageFilled}%` }}
            >
                &nbsp;
            </div>
        </div>
    );
}

function InventoryIndicatorDetails({
    minImpressions,
    maxImpressions,
    minUniqueUsers,
    maxUniqueUsers,
}) {
    return (
        <table className="inventory-indicator__details-table">
            <tbody>
                <tr>
                    <td />
                    <td className="inventory-indicator__table-header-2">
                        Estimate
                        <InlineTooltip
                            position="top"
                            top={-80}
                            left={-22}
                            width={250}
                            wrapText
                            tooltip={
                                <div>
                                    The forecasted impressions and reach of an ad based on your
                                    targeting and default CTR optimization settings
                                </div>
                            }
                        >
                            <InfoIcon style={{ paddingLeft: 2 }} />
                        </InlineTooltip>
                    </td>
                    <td className="inventory-indicator__table-header-2">
                        Available
                        <InlineTooltip
                            position="top"
                            top={-80}
                            left={-114}
                            width={250}
                            wrapText
                            tooltip={
                                <div>
                                    The potentially available impressions and reach based on your
                                    targeting settings, without CTR optimization.
                                </div>
                            }
                        >
                            <InfoIcon style={{ paddingLeft: 2 }} />
                        </InlineTooltip>
                    </td>
                </tr>
                <tr>
                    <td className="inventory-indicator__table-sub-header">Impressions</td>
                    <td className="inventory-indicator__table-data-2">{minImpressions}</td>
                    <td className="inventory-indicator__table-data-2">{maxImpressions}</td>
                </tr>
                <tr>
                    <td className="inventory-indicator__table-sub-header">Unique Users</td>
                    <td className="inventory-indicator__table-data-2">{minUniqueUsers}</td>
                    <td className="inventory-indicator__table-data-2">{maxUniqueUsers}</td>
                </tr>
            </tbody>
        </table>
    );
}

import _ from 'lodash';
import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useReportExplore } from '../../redux';

function HideZeroRowsContainer() {
    const { shouldHideZeroRows, toggleHideZeroRows } = useReportExplore();
    return <HideZeroRows shouldHideZeroRows={shouldHideZeroRows} onClick={toggleHideZeroRows} />;
}

function HideZeroRows(props) {
    const { shouldHideZeroRows, onClick } = props;

    return (
        <FormControlLabel
            control={<Checkbox color="primary" checked={shouldHideZeroRows} onChange={onClick} />}
            label="Hide Zero Rows"
        />
    );
}

export default HideZeroRowsContainer;

import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import classnames from 'classnames';

import ProgressBar from 'widgets-v5/progress-bar';

import {
    formatTimeRangeInDaysHours,
    formatNumber_whole,
    formatNumber_currency,
} from 'utils/formatting';
import { getDateProgressRelativeToRange } from 'common/date-calculator';

class ElapsedTimeProgressBar extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['campaign', 'ad-row']),
        status: PropTypes.oneOf([
            'live',
            'paused',
            'scheduled',
            'pending',
            'ended',
            'incomplete',
            'archived',
            null,
        ]).isRequired,
        deliveryProgress: PropTypes.number,
        campaignFlight: PropTypes.object,
        startDate: PropTypes.object.isRequired, // example: 'MMM D, YYYY HH:mm z'
        endDate: PropTypes.object.isRequired, // example: 'MMM D, YYYY HH:mm z'
        deliveryHealth: PropTypes.oneOf(['good', 'ok', 'bad', 'pending', null]),
        labelLeft: PropTypes.string,
        labelRight: PropTypes.string,
        rangeLeft: PropTypes.string,
        rangeRight: PropTypes.string,
    };

    render() {
        const { startDate, endDate, type, labelLeft } = this.props;

        const remainingTime = createRemainingTimeObject(startDate, endDate);
        let elapsedTime;

        elapsedTime = createElapsedTimeObject(startDate, endDate);

        const hasStarted = elapsedTime.value.indexOf('-') !== -1;
        const hasEnded = remainingTime.value.indexOf('-') !== -1;

        const elapsedTimeString = hasStarted
            ? `Starts in ${elapsedTime.value.replace('-', '')}`
            : `${elapsedTime.value} elapsed`;

        const remainingTimeString = hasEnded
            ? `Ended ${remainingTime.value.replace('-', '')} ago`
            : `${remainingTime.value} remaining`;

        return (
            <div className="ef3-elapsedTimeProgressBar">
                <ProgressBar
                    {...this.props}
                    rangeLeft={elapsedTimeString}
                    rangeRight={remainingTimeString}
                    elapsedTime
                />
            </div>
        );
    }
}

function createElapsedTimeObject(
    relativeStart,
    relativeEnd,
    start = relativeStart,
    end = relativeEnd
) {
    let now = new Date();

    if (now > end) {
        now = end;
    }

    return {
        start:
            getDateProgressRelativeToRange({ start: relativeStart, end: relativeEnd }, start) * 100,
        end:
            100 -
            getDateProgressRelativeToRange({ start: relativeStart, end: relativeEnd }, end) * 100,
        value: formatTimeRangeInDaysHours({ start, end: now }),
    };
}

function createRemainingTimeObject(start, end) {
    return {
        start: getDateProgressRelativeToRange({ start, end }, new Date()) * 100,
        end: 0,
        value: formatTimeRangeInDaysHours({ start: new Date(), end }),
    };
}

export default ElapsedTimeProgressBar;

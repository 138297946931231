import _ from 'lodash';
import c from 'common/constants/flux-events';

import { createHttp } from 'utils/http';

const useGraphQLToFetchOrganizationDetails = async orgId => {
    const http = createHttp();
    const query = `
        query getOrganizationDataForAdminOrgItemDetails ($id: String) {
            organization(id: $id) {
                auditors
                auditees
                type
                name
                tech_fee
                credit_limit
                currency
                revenueModel
                relationshipToAuthOrganization {
                    _relationship
                }
                creatives {
                    standard {
                        allow_create
                        auto_approve
                    }
                    video {
                        allow_create
                        auto_approve
                    }
                    mraid {
                        allow_create
                        auto_approve
                    }
                    custom_html {
                        allow_create
                        auto_approve
                    }
                    native {
                        allow_create
                        auto_approve
                    }
                    third_party_vast {
                        allow_create
                        auto_approve
                    }
                }
            }
        }
    `;
    const variables = {
        id: orgId,
    };
    return await http.graphql(query, variables);
};

const useGraphQLToGetUsersByIds = userIds => {
    const http = createHttp();
    const query = `
        query getUsers ($userIds: [String]) {
            users(userIds: $userIds) {
                id
                first_name
                last_name
                email
            }
         }
    `;
    const variables = {
        userIds,
    };

    return http.graphql(query, variables);
};

const formatUsers = users => {
    return users
        .map(({ first_name, last_name, email }) => {
            if (first_name !== '') {
                return `${first_name} ${last_name} (${email})`;
            } else {
                return void 0;
            }
        })
        .filter(v => v);
};

export const adminOrgItem = {
    init(orgId) {
        return dispatch => {
            dispatch({
                type: c.ADMIN_ORG_ITEM__INIT_START,
                payload: { orgId },
            });

            if (orgId) {
                useGraphQLToFetchOrganizationDetails(orgId)
                    .then(({ organization }) => {
                        dispatch({
                            type: c.ADMIN_ORG_ITEM__INIT_SUCCESS,
                            payload: {
                                organization: {
                                    ...organization,
                                    _relationship:
                                        organization.relationshipToAuthOrganization._relationship,
                                },
                            },
                        });
                        const auditorsIds = _.get(organization, `auditors`, []);
                        const auditeesIds = _.get(organization, `auditees`, []);

                        if (auditorsIds.length > 0) {
                            new Promise(resolve => {
                                const auditors = useGraphQLToGetUsersByIds(auditorsIds);
                                resolve(auditors);
                            }).then(auditors => {
                                dispatch({
                                    type: 'ADMIN_AUDITORS__FETCH_SUCCESS',
                                    payload: { auditors: formatUsers(auditors.users) },
                                });
                            });
                        }

                        if (auditeesIds.length > 0) {
                            new Promise(resolve => {
                                const auditees = useGraphQLToGetUsersByIds(auditeesIds);
                                resolve(auditees);
                            }).then(auditees => {
                                dispatch({
                                    type: 'ADMIN_AUDITEES__FETCH_SUCCESS',
                                    payload: { auditees: formatUsers(auditees.users) },
                                });
                            });
                        }
                    })
                    .catch(err => {
                        dispatch({
                            type: c.ADMIN_ORG_ITEM__INIT_FAIL,
                            payload: { err },
                        });

                        return Promise.reject(new Error('Failed to fetch org item'));
                    });
            }

            return Promise.resolve();
        };
    },
};

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';

import { adminUsersForm as Action_adminUsersForm } from './actions';

import FormField from 'widgets-v5/form-field';
import StandardSelector from 'widgets-v5/standard-selector';
import StandardInput from 'widgets-v5/standard-input';
import Toggle from 'widgets-v5/toggle';
import Spacer from 'widgets-v5/spacer';

import rolePermissionsByOrganization from 'common/constants/role-permissions-by-organization';
import {
    rolePresentationDictionary,
} from 'common/constants/role-presentation-dict';
import { GlobalModal } from 'widgets-v5/modal';
import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';
import { BlockLoadGroup } from 'widgets-v5/load-group';

const mapStateToProps = (state, ownProps) => {
    const thisOrgId = _.get(ownProps, 'orgId', void 0);
    const draft = _.get(state, 'adminUsersForm.draft', void 0);

    // Constructing item in global_role dropdown
    const thisOrgType = ownProps.orgType;
    const thisRolePermissions = _.get(rolePermissionsByOrganization, `${thisOrgType}`, []);
    const role_dropdownItems = thisRolePermissions.map(roleId => ({
        value: roleId,
        label: _.get(rolePresentationDictionary, `${roleId}`, 'Unknown Role'),
    }));

    const showErrors = _.get(state, 'adminUsersForm.showErrors', void 0);
    const formErrorsArray = _.get(state, 'adminUsersForm.formErrors', void 0);
    const formErrorsByField = formErrorsArray.reduce((acc, v) => {
        acc[v.field] = v.message;
        return acc;
    }, {});

    const o = {
        orgId: thisOrgId,
        userIds: _.get(state, 'admin.userIds', []),
        isLoading: _.get(state, 'adminUsersForm.isLoading', void 0),
        isOpen: _.get(state, 'adminUsersForm.isOpen', void 0),
        draft,
        role_dropdownItems,
        showErrors,
        formErrors: formErrorsByField,
    };
    return o;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch__draftInit(userId, orgId) {
            dispatch(Action_adminUsersForm.draftInit(userId, orgId));
        },
        dispatch__draftTearDown(userId) {
            dispatch(Action_adminUsersForm.draftTearDown(userId));
        },
        dispatch_fieldChange(userId, orgId, data) {
            dispatch(Action_adminUsersForm.fieldChanged(userId, orgId, data));
        },
        dispatch__draftSubmit(userId, orgId) {
            return dispatch(Action_adminUsersForm.draftSubmit(userId, orgId));
        },
    };
};

class UserForm_Button extends React.Component {
    constructor(props) {
        super(props);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            isFormOpen: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const storeState_isOpen = nextProps.isOpen;
        const componentState_isOpen = this.state.isFormOpen;
        if (componentState_isOpen === true && storeState_isOpen === false) {
            this.setState({ isFormOpen: false });
        }
    }

    onFieldChange(fieldName) {
        return fieldValue => {
            this.props.dispatch_fieldChange(this.props.userId, this.props.orgId, {
                [fieldName]: fieldValue,
            });
        };
    }

    handleSubmit(e) {
        e.stopPropagation();
        this.props
            .dispatch__draftSubmit(this.props.userId, this.props.orgId)
            .then(() => {
                this.props.retrieveUsers();
            })
            .catch(() => {
                toastr.error(
                    'Failed to save the changes due to input errors on the form. Please refresh the page and try again.'
                );
            });
    }

    handleEdit(e) {
        e.stopPropagation();
        this.props.dispatch__draftInit(this.props.userId, this.props.orgId);
        this.setState({ isFormOpen: true });
    }

    handleCloseForm() {
        this.props.dispatch__draftTearDown(this.props.userId);
    }

    handleCancel(e) {
        e.stopPropagation();
        this.handleCloseForm();
    }

    render() {
        const isEdit = this.props.userId;
        const isNew = !isEdit;

        const isLoading = !(
            this.state.isFormOpen &&
            !this.props.isLoading &&
            this.props.draft !== null
        );

        return (
            <div className="ef5-user-setup admin_user_button">
                <div onClick={e => this.handleEdit.call(this, e)}>{this.props.Button}</div>
                <GlobalModal
                    title={isNew ? 'New User' : 'Edit User'}
                    isOpen={this.state.isFormOpen}
                    className="admin_user_button"
                    onClose={this.handleCloseForm.bind(this)}
                    bottomBar={
                        !isLoading && (
                            <ButtonGroup>
                                <PrimaryButton text="Save" onClick={this.handleSubmit} />
                                <NeutralButton text="Cancel" onClick={this.handleCancel} />
                            </ButtonGroup>
                        )
                    }
                >
                    {isLoading ? (
                        <div className="ef6-alignment__center" style={{ width: 500, height: 300 }}>
                            <BlockLoadGroup isLoading={true} />
                        </div>
                    ) : (
                        <div className="ef5-user-setup__form">
                            {_.get(this.props.draft, 'global_role', void 0) !== 'revoked' && (
                                <div className="role field">
                                    <FormField
                                        label="Role"
                                        errors={[_.get(this.props, `formErrors.global_role`, '')]}
                                        showErrors={this.props.showErrors}
                                        isRequired={true}
                                    >
                                        <StandardSelector
                                            autofocus={false}
                                            disabled={false}
                                            clearable={false}
                                            searchable={true}
                                            label="StandardSelector"
                                            value={_.get(this.props.draft, 'global_role', void 0)}
                                            onChange={this.onFieldChange('global_role')}
                                            placeholder="Select a role for user"
                                            items={this.props.role_dropdownItems}
                                        />
                                    </FormField>
                                    <Spacer type="small" />
                                </div>
                            )}

                            <div className="email field">
                                {isNew ? (
                                    <div>
                                        <FormField
                                            label="Email"
                                            errors={[_.get(this.props, `formErrors.email`, '')]}
                                            showErrors={this.props.showErrors}
                                            isRequired={true}
                                        >
                                            <StandardInput
                                                value={this.props.draft.email}
                                                onChange={this.onFieldChange('email')}
                                            />
                                        </FormField>
                                        <Spacer type="small" />
                                    </div>
                                ) : (
                                    <div>
                                        <FormField
                                            label="Email"
                                            errors={[]}
                                            showErrors={false}
                                            isRequired={false}
                                        >
                                            <div className="value">
                                                {_.get(this.props.draft, 'email', void 0)}
                                            </div>
                                        </FormField>
                                        <Spacer type="small" />
                                    </div>
                                )}
                            </div>

                            <div className="first-name field">
                                <FormField
                                    label="First Name"
                                    errors={[_.get(this.props, `formErrors.first_name`, '')]}
                                    showErrors={this.props.showErrors}
                                    isRequired={true}
                                >
                                    <StandardInput
                                        value={this.props.draft.first_name}
                                        onChange={this.onFieldChange('first_name')}
                                    />
                                </FormField>
                                <Spacer type="small" />
                            </div>

                            <div className="last-name field">
                                <FormField
                                    label="Last Name"
                                    errors={[_.get(this.props, `formErrors.last_name`, '')]}
                                    showErrors={this.props.showErrors}
                                    isRequired={true}
                                >
                                    <StandardInput
                                        value={this.props.draft.last_name}
                                        onChange={this.onFieldChange('last_name')}
                                    />
                                </FormField>
                                <Spacer type="small" />
                            </div>

                            <div className="suspended field">
                                <FormField
                                    label="Suspended"
                                    errors={[_.get(this.props, `formErrors.suspended`, '')]}
                                    showErrors={this.props.showErrors}
                                    isRequired={false}
                                >
                                    <Toggle
                                        status="true"
                                        width={56}
                                        height={25}
                                        position={this.props.draft.suspended ? 'right' : 'left'}
                                        textLeft="no"
                                        textRight="yes"
                                        onClick={() => {
                                            this.onFieldChange('suspended')(
                                                !this.props.draft.suspended
                                            );
                                        }}
                                    />
                                </FormField>
                                <Spacer type="small" />
                            </div>
                        </div>
                    )}
                </GlobalModal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserForm_Button);

import _ from 'lodash';

export const isNotNullOrUndefined = x => x !== null && x !== undefined;
export const isNullOrUndefined = x => !isNotNullOrUndefined(x);

// flatMap([ { f: [1,2,3] }, {f: [4,5,6] } ])
// output: [1,2,3,4,5,6]
export const flatMap = (list, field) =>
    _(list)
        .map(field)
        .flatten()
        .filter(isNotNullOrUndefined)
        .value();

export const filterMap = (list, mapper) =>
    _(list)
        .filter(isNotNullOrUndefined)
        .map(mapper)
        .value();

export const filterMapFirst = (list, mapper) => _.first(filterMap(list, mapper));

// input: { hello: 'world' }
// output: 'hello'
export const mapFirst = (dict, defaultValue) => {
    if (isNullOrUndefined(dict)) {
        return defaultValue;
    }

    return _.first(_.map(dict, x => x));
};

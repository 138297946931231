import React from 'react';
import { connect } from 'react-redux';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

import actions from '../../actions';
import { IAS_PUBLICIS_VIEWABILITY_OPTIONS } from 'common/constants/ias-publicis-viewability';

import { getIASPublicisGroupMWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { getCreativeFormat } from 'forms/ad-form/modules/ias-viewability/selector.js';

const useStyles = makeStyles({
    form: {
        margin: '10px 0px',
    },
    label: {
        fontSize: 14,
        margin: '10px 0px',
    },
    labelSmall: {
        fontSize: 17,
    },
});

const IASPublicisViewability = ({
    disabled,
    options,
    value,
    handleChange,
    helpMessage,
    warningData,
    isCrossPlatformCampaign,
}) => {
    const classes = useStyles();

    return (
        <div>
            <FormControl className={classes.form} disabled={disabled}>
                <FormLabel component="legend">Publicis Performance Matching</FormLabel>
                <Select value={value} onChange={handleChange}>
                    {_.map(options, option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {helpMessage && <FormHelperText>{helpMessage}</FormHelperText>}
                {isCrossPlatformCampaign &&
                    _.map(warningData, warning => {
                        if (warning.hasWarning) {
                            return (
                                <WarningText
                                    message={warning.message}
                                    title={warning.title}
                                    severity={warning.severity}
                                />
                            );
                        }
                    })}
            </FormControl>
        </div>
    );
};

function selector(storeState) {
    const platform = _.get(storeState, 'adForm.form.draft.platform');
    const platforms = _.get(storeState, 'adForm.form.draft.platforms');
    const value = _.get(storeState, 'adForm.form.draft.ias.publicisViewability.value');
    const selectedCreatives = _.get(storeState, 'adForm.form.draft.creative');
    const creatives = _.get(storeState, 'adForm.form.creatives');
    const rotationRules = _.get(storeState, 'adForm.form.draft.rotation_rules');
    const creativeFormats = getCreativeFormat(selectedCreatives, creatives, rotationRules);
    const isInAppPlatform = platform === 'inapp';
    const isVideoCreative = creativeFormat === 'video';

    const disabled = false; // isInAppPlatform || !isVideoCreative;
    const options = IAS_PUBLICIS_VIEWABILITY_OPTIONS;

    const warningData = getIASPublicisGroupMWarning({
        viewabilityValue: value,
        platforms,
        field: 'publicis',
        creativeFormats,
    });

    let helpMessage;
    if (isInAppPlatform) {
        helpMessage = 'Supports desktop and mobile web platforms only';
    } else if (!isVideoCreative) {
        helpMessage = 'Supports video format only';
    }

    return {
        disabled,
        options,
        value,
        helpMessage,
        warningData,
    };
}

function mapDispatch(dispatch) {
    return {
        handleChange: event => {
            const { value } = event.target;

            dispatch(actions.changePublicisViewability(value));
        },
    };
}

export default connect(
    selector,
    mapDispatch
)(IASPublicisViewability);

import _ from 'lodash';

export default function selector(storeState, props) {
    const adPresetId = _.get(props, `params.adPresetId`, 'new');
    const adPresetEdit = _.get(storeState, 'adPresetEdit', {});
    var isNewAdPreset = adPresetEdit.isFormOpen ? 'adPresetEdit' : 'adPresetNew';
    const adPreset = _.get(storeState, `${isNewAdPreset}`, {});

    const geoResources = {
        ..._.get(storeState, `resources.geoCategories`),
    };
    const organizationId = _.get(storeState, `profile.organizationId`);

    const errors = _.reduce(
        adPreset.errors,
        (result, err) => {
            result[err.field] = [err.message];
            return result;
        },
        {}
    );

    return {
        formData: adPreset.draft,
        showErrors: adPreset.showErrors,
        errors,
        isLoading: adPreset.isLoading,
        adPresetId,
        defaultValues: adPreset.defaultValues,
        geoResources,
        organizationId,
        isPoiRecategorized: false,
    };
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _ from 'lodash';

import FormField from 'widgets-v5/form-field';
import AutosizeTextarea from 'widgets-v5/autosize-textarea';
import Spacer from 'widgets-v5/spacer';
import { ButtonGroup, PrimaryButton } from 'widgets-v5/buttons';
import { BodyBold, BodyText } from 'widgets-v5/typography';

function validateTargets(targets, validators) {
    let validTargets = [],
        validationPool = [],
        invalidTargets = [];

    /*
        Validators run sequentially on a set of targets, ie: ['Toronto, ON', 'M1A', 'M2A', 'QC']
        After running through a validator, the validationPool will contain all the remaining
        invalid targets, which will then be checked by the next validator. This way, validators can
        be decoupled and written to only recognize/validate one type of input format (ie. city vs region vs fsa)
    */
    validationPool = targets;

    validators.forEach(validator => {
        const [validSpecification, invalidSpecification] = validator(validationPool);
        validTargets = _.union(validSpecification, validTargets);
        validationPool = invalidSpecification;
    });

    invalidTargets = validationPool;

    return [validTargets, invalidTargets];
}

function BatchImport({ onImportClick, validators, onClose }) {
    const [targets, setTargets] = useState('');
    const [showError, setShowError] = useState(false);

    const handleImportClick = () => {
        const splitTargets = _.compact(targets.split('\n'));
        const [validTargets, invalidTargets] = validateTargets(splitTargets, validators);

        const showError = !!invalidTargets.length;

        setTargets(invalidTargets.join('\n'));
        setShowError(showError);

        if (!showError) {
            onClose();
        }

        onImportClick(validTargets);
    };

    return (
        <div className="ef4-batch-import">
            <i onClick={onClose} className="ef4-batch-import__close-icon fa fa-times" />
            <FormField
                label="Bulk Import"
                errors={['Please check invalid lines for formatting.']}
                showErrors={showError}
            >
                <AutosizeTextarea
                    value={targets}
                    placeholder="Enter one city or postal code per line"
                    onChange={setTargets}
                />
            </FormField>
            <Spacer type="x-small" />
            <BodyBold>Accepted formats for cities</BodyBold>
            <BodyText type="info">
                Toronto, Ontario
                <br />
                Montreal, QC
            </BodyText>
            <Spacer type="x-small" />
            <BodyBold>Accepted formats for Canadian postal codes</BodyBold>
            <BodyText type="info">
                M5H
                <br />
                V5Y
            </BodyText>
            <ButtonGroup align="right">
                <PrimaryButton onClick={handleImportClick} text="Import" />
            </ButtonGroup>
        </div>
    );
}

BatchImport.propTypes = {
    onImportClick: PropTypes.func,
    onClose: PropTypes.func,
    validators: PropTypes.array,
};

export default BatchImport;

import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'campaignSummary';
export const initialState = {
    isDoneInitializing: false,
    organizations: [],
    ownOrganization: {},
    archivedAdsAndCreatives: {
        ads: [],
        creatives: [],
    },
};
export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        init: (state, payload) => {
            return state;
        },
        initSuccess: (state, data) => {
            return {
                ...state,
                ...data,
                isDoneInitializing: true,
            };
        },
        unInit: (state, payload) => {
            return initialState;
        },
        fetchArchivedAdsAndCreatives: (state, payload) => {
            return state;
        },
        fetchArchivedAdsAndCreativesSuccess: (state, data) => {
            return {
                ...state,
                archivedAdsAndCreatives: {
                    ...state.archivedAdsAndCreatives,
                    ...data,
                }
            }
        },
    },
});

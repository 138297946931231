import _ from 'lodash';

export const NAME = 'audiences';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import flags from 'containers/flags/service';

import selector from './selector';

const creatives = [
    {
        label: 'Standard',
        value: 'standard',
    },
    {
        label: 'Rich Media',
        value: 'custom_html',
    },
    {
        label: 'MRAID Rich Media',
        value: 'mraid',
    },
    {
        label: 'Video',
        value: 'video',
    },
    {
        label: 'Third-party VAST',
        value: 'third_party_vast',
    },
    {
        label: 'Native',
        value: 'native',
    },
];

const CreativeSetup = ({ draft, onChange, mode, relationship }) => {
    let showAutoApprove = false;

    if (mode === 'edit') {
        const relationship_inDraft = relationship;
        if (_.includes(['child'], relationship_inDraft)) {
            showAutoApprove = true; // only show creative auto_approve field if it is a child
        }
    } else if (mode === 'create') {
        showAutoApprove = true;
    }

    return (
        <div>
            <Grid container direction="column" spacing={3}>
                <Grid item xs={12}>
                    <Grid container direction="row">
                        <Grid item xs={4} />
                        <Grid item xs={4}>
                            <Typography align="center"> Allowed </Typography>
                        </Grid>
                    </Grid>
                    {_.map(creatives, creative => {
                        return (
                            <Grid
                                key={creative.value}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <Grid item xs={4}>
                                    <Typography> {creative.label} </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container justifyContent="center">
                                        <Switch
                                            checked={_.get(
                                                draft,
                                                `creatives.${creative.value}.allow_create`
                                            )}
                                            color="primary"
                                            onChange={e =>
                                                onChange(
                                                    creative.value,
                                                    'allow_create',
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(selector)(CreativeSetup);

import _ from 'lodash';

import notify from 'utils/notify';
import dealsInventoryActions from '../actions';
import dealAPIActions from '../../../../states/resources/deals/actions';

const actions = {
    openDeleteModal(dealId, appName) {
        return dispatch => {
            dispatch({
                type: 'DELETE_DEAL_MODAL__OPEN',
                payload: { dealId, appName },
            });
        };
    },
    closeDeleteModal() {
        return dispatch => {
            dispatch({
                type: 'DELETE_DEAL_MODAL__CLOSE',
            });
        };
    },
    delete() {
        return async (dispatch, getState) => {
            const dealId = _.get(getState(), 'deleteDealModal.dealId');
            dispatch({
                type: 'DELETE_DEAL_MODAL__DELETE_START',
            });
            try {
                await dispatch(dealAPIActions.delete(dealId));
                dispatch({
                    type: 'DELETE_DEAL_MODAL__DELETE_SUCCESS',
                });
                dispatch(dealsInventoryActions.fetchAllDeals());
            } catch (error) {
                dispatch({
                    type: 'DELETE_DEAL_MODAL__DELETE_ERROR',
                });
                notify({ error, metaData: dealId });
            }
        };
    },
};

export default actions;

import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.files';

export default function files(state = {}, action) {
    switch (action.type) {
        // case c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS:
        case c.SYSTEM__CAMPAIGN_FILES__FETCH__SUCCESS:
            // case c.SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS:

            const { file, files } = action.payload;

            return mergeResource(
                state,
                []
                    .concat(file)
                    .concat(files)
                    .filter(x => x)
            );

        case c.OVERVIEW_FILES__FILE_DELETE__SUCCESS:
            const { fileId } = action.payload;
            return _.omit(state, [fileId]);

        case c.OVERVIEW_FILES__FILE_EDIT__SUCCESS:
            const { id } = action.payload;
            const editedFileAttr = action.payload.file;

            return {
                ...state,
                [id]: {
                    id,
                    attr: editedFileAttr,
                },
            };

        case 'CREATIVE_FORM__DELETE_UNUSED_IMAGE':
            const { fileToBeDeleted } = action.payload;
            return _.omit(state, [fileToBeDeleted]);

        default:
            return state;
    }
}

import _ from 'lodash';
import flags from 'containers/flags/service';

const initialState = {
    isLoading: false,
    error: null,

    dailyUniqueUsers: null,
    dailyAvailableImpressions: null,
    totalUniqueUsersMin: null,
    totalAvailableImpressionsMin: null,
    totalUniqueUsersMax: null,
    totalAvailableImpressionsMax: null,
};

export const NAME = 'resources.inventoryPredictions';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION':
            return {
                ...state,
                isLoading: true,
                error: null,
                dailyUniqueUsers: 0,
                dailyAvailableImpressions: 0,
                totalUniqueUsersMin: 0,
                totalAvailableImpressionsMin: 0,
                totalUniqueUsersMax: 0,
                totalAvailableImpressionsMax: 0,
            };
        case 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION__COMPLETE':
            return {
                isLoading: false,

                dailyUniqueUsers: action.payload.dailyUniqueUsers,
                dailyAvailableImpressions: action.payload.dailyAvailableImpressions,
                totalUniqueUsersMin: action.payload.totalUniqueUsersMin,
                totalAvailableImpressionsMin: action.payload.totalAvailableImpressionsMin,
                totalUniqueUsersMax: action.payload.totalUniqueUsersMax,
                totalAvailableImpressionsMax: action.payload.totalAvailableImpressionsMax,

                error: null,
            };
        case 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION__FAILED':
            return {
                ...state,

                isLoading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
}

import _ from 'lodash';

export const NAME = 'poisDashboard';

const initialState = {
    isLoading: false,
    isInitialized: false,
    itemsPerPage: 25,
    maxResults: 0,
    pagination: {
        // 1: [1,2,3],
        // 2: [4,5,6],
        // 3: [7,8,9],
    },
    filters: {
        search: '',
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'POI_DASHBOARD__INIT__START': {
            return {
                ...state,
                isInitialized: false,
                filters: action.payload.filters || {},
            };
        }
        case 'POI_DASHBOARD__INIT__END': {
            return {
                ...state,
                isInitialized: true,
            };
        }
        case 'POI_DASHBOARD__INIT__ERROR': {
            return {
                ...state,
                isInitialized: true,
            };
        }
        case 'POI_DASHBOARD__FETCH_PAGE_DATA__START': {
            return {
                ...state,
                isLoading: true,
            };
        }
        case 'POI_DASHBOARD__FETCH_PAGE_DATA__END': {
            const { page, pois, maxResults } = action.payload;

            const pagination = indexPage(state.pagination, page, pois);

            return {
                ...state,
                pagination,
                maxResults,
                filters: {
                    ...state.filters,
                    page,
                },
                isLoading: false,
            };
        }
        case 'POI_DASHBOARD__FETCH_PAGE_DATA__ERROR': {
            return {
                ...state,
                isLoading: false,
                errors: action.payload.error,
            };
        }
        case 'POI_DASHBOARD__SEARCH': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    search: action.payload.text,
                },
            };
        }
        default:
            return state;
    }
}

function indexPage(pagination, page, pois) {
    return {
        ...pagination,
        [page]: _.map(pois, poi => poi._id),
    };
}

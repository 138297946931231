export function clone(date) {
    return new Date(date.valueOf());
}

export function getMidpoint(date1, date2) {
    const midpoint = (date1.valueOf() + date2.valueOf()) / 2;
    return new Date(midpoint);
}

// Always includes start day and end day
export function getDayIterator({ start, end }) {
    // Use start of start date and end of end date
    // in order to iterate through incomplete UTC days
    const startOfStartDate = getUtcStartOfDay(start);
    const endOfEndDate = getUtcEndOfDay(end);

    const days = [];
    let date = startOfStartDate;
    while (date.valueOf() < endOfEndDate.valueOf()) {
        days.push(date);
        date = new Date(date.valueOf() + 24 * 60 * 60 * 1000);
    }

    return days;
}

// Returns end of hour points between start and end,
// optionally including or excluding (default) endpoints
export function getEndOfHourIterator({ start, end }, { includeEndpoints = false } = {}) {
    const endOfHourOfStartDate = getEndOfHour(start);
    const endOfHourOfEndDate = getEndOfHour(end);

    const hours = [];
    let date = endOfHourOfStartDate;
    while (date.valueOf() < endOfHourOfEndDate.valueOf()) {
        hours.push(date);
        date = new Date(date.valueOf() + 60 * 60 * 1000);
    }

    // If the starting point is already the end of the hour,
    // then remove it since endpoints are excluded by default
    // If endpoints are to be included, then add it back in below
    // If there are no points between start and end because they are
    // clsoe together, then skip this step
    if (hours.length && hours[0].valueOf() === start.valueOf()) {
        hours.splice(0, 1);
    }
    if (includeEndpoints) {
        return [start, ...hours, end];
    }

    return hours;
}

export function getIntersection({ start: start1, end: end1 }, { start: start2, end: end2 }) {
    if (start1 > end1 || start2 > end2) {
        throw new Error('Ranges not correct');
    }
    if ((end1 < start2 && end1 < end2) || (end2 < start1 && end2 < end1)) {
        return null;
    }
    var maxStart = Math.max(start1.valueOf(), start2.valueOf());
    var minEnd = Math.min(end1.valueOf(), end2.valueOf());
    return {
        start: new Date(maxStart),
        end: new Date(minEnd),
    };
}

export function max(...dates) {
    const timestamps = dates.map(date => date.valueOf());
    const maxTimestamp = Math.max(...timestamps);
    return new Date(maxTimestamp);
}

export function min(...dates) {
    const timestamps = dates.map(date => date.valueOf());
    const minTimestamp = Math.min(...timestamps);
    return new Date(minTimestamp);
}

export function getRangePositionToNow({ start, end }) {
    const startTimestamp = start.valueOf();
    const endTimestamp = end.valueOf();
    const currentTimestamp = Date.now();

    if (currentTimestamp < startTimestamp) {
        return 'future';
    }
    if (endTimestamp < currentTimestamp) {
        return 'past';
    }
    return 'present';
}

export function getNumberOfSecondsInRange({ start, end }) {
    const rangeStartUnix = Math.floor(start.valueOf() / 1000);
    const rangeEndUnix = Math.floor(end.valueOf() / 1000);

    // If start is after end, don't bother with a calculation
    if (rangeStartUnix >= rangeEndUnix) {
        return 0;
    }

    return rangeEndUnix - rangeStartUnix + 1;
}

export function getUtcStartOfDay(date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();

    // year, month, day, hour, minute, second, millisecond
    return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
}

export function getUtcEndOfDay(date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();

    // year, month, day, hour, minute, second, millisecond
    return new Date(Date.UTC(year, month, day, 23, 59, 59, 999));
}

export function getStartOfHour(date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const hour = date.getUTCHours();

    // year, month, day, hour, minute, second, millisecond
    return new Date(Date.UTC(year, month, day, hour, 0, 0, 0));
}

export function getEndOfHour(date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const hour = date.getUTCHours();

    // year, month, day, hour, minute, second, millisecond
    return new Date(Date.UTC(year, month, day, hour, 59, 59, 999));
}

export function getIsoDateString(date) {
    const isoDateString = date.toISOString();
    return isoDateString.slice(0, 10);
}

const isValidDate = date => {
    if (date instanceof Date && !isNaN(date.valueOf())) {
        return true;
    }
    return false;
};

export function getDateProgressRelativeToRange({ start, end }, date) {
    const startDate = typeof start === 'string' ? new Date(start) : start;
    const endDate = typeof end === 'string' ? new Date(end) : end;
    const dateDate = typeof date === 'string' ? new Date(date) : date;

    let totalTime = 1;
    let elapsedTime = 0;
    if (isValidDate(startDate) && isValidDate(endDate)) {
        totalTime = endDate.valueOf() - startDate.valueOf();
    }
    if (isValidDate(startDate) && isValidDate(dateDate)) {
        elapsedTime = dateDate.valueOf() - startDate.valueOf();
    }

    if (totalTime <= 0) {
        return 0;
    }
    return elapsedTime / totalTime;
}



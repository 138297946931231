import { connect } from 'react-redux';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import selector from './selector';
import { Label } from 'widgets-v5/typography';

import { highlight } from '../summary-conf';
import { hasChanged } from '../summary-conf';

import { formatNumber_percentage, formatDate_long } from 'utils/formatting';
import SummaryTypeText from 'widgets/summary-view/summary-type-text';

import { checkIsNewScheduledWeightedRotationMode } from 'states/resources/ads/business-logic';
import Separator from 'widgets-v5/separator';
import { getConditionLabel } from 'common/constants/weather-condition-targeting';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

function _getCreativeObjs(creativesByCampaign, selectedIds) {
    const creatives = _(selectedIds)
        .filter(creativeId => creativesByCampaign[creativeId])
        .map(creativeId => {
            const creative = creativesByCampaign[creativeId];
            const { attr } = creative;

            let previewURL;
            switch (attr.format) {
                case 'video':
                    previewURL = attr.image_preview_url;
                    break;
                case 'mraid':
                    previewURL = attr.reference_image_preview_url;
                    break;
                default:
                    previewURL = attr.image_preview_url || attr.reference_image_asset_preview_url;
            }

            return {
                id: creative.id,
                name: attr.name,
                previewImg: previewURL,
            };
        })
        .value();

    return creatives;
}

function _getScheduledObjs(creativesByCampaign, scheduled, timezone) {
    return (
        _(scheduled)
            .filter(schedule => schedule.markup_id)
            .map(schedule => {
                const { markup_id: creativeId } = schedule;

                const creative = creativesByCampaign[creativeId];
                const { attr } = creative;

                let previewURL;
                switch (attr.format) {
                    case 'video':
                        previewURL = attr.image_preview_url;
                        break;
                    case 'mraid':
                        previewURL = attr.reference_image_preview_url;
                        break;
                    default:
                        previewURL =
                            attr.image_preview_url || attr.reference_image_asset_preview_url;
                }

                return {
                    id: creativeId,
                    name: attr.name,
                    previewImg: previewURL,
                    start: moment(schedule.start)
                        .tz(timezone)
                        .format('MMM DD, YYYY HH:mm z'),
                    end: moment(schedule.end)
                        .tz(timezone)
                        .format('MMM DD, YYYY HH:mm z'),
                };
            })
            .value() || []
    );
}

const getPreviewUrl = creative => {
    let previewURL;
    switch (creative.format) {
        case 'video':
            previewURL = creative.image_preview_url;
            break;
        case 'mraid':
        case 'custom_html':
            previewURL = creative.reference_image_preview_url;
            break;
        case 'native':
            previewURL = creative.main_image_preview_url;
            break;
        default:
            previewURL = creative.image_preview_url || creative.reference_image_asset_preview_url;
    }
    return previewURL;
};

const getWeightedObjs = (creatives, weightMap) => {
    return _.map(creatives, creative => {
        const previewURL = getPreviewUrl(creative);

        return {
            id: creative.id,
            name: creative.name,
            previewImg: previewURL,
            weight: weightMap[creative.id].weight,
        };
    });
};

const styles = makeStyles(theme => ({
    item: {
        marginBottom: theme.spacing(2),
    },
}));

const getCreativeRotationByWeatherSummary = ({ settings, creativesByCampaign }) => {
    const creativeIds = _.map(settings, setting => setting.markupId);
    const creatives = _getCreativeObjs(creativesByCampaign, creativeIds);

    const creativesMapping = {};
    _.each(creatives, creative => (creativesMapping[creative.id] = creative));

    return _.map(settings, setting => ({
        ...setting,
        timerame: setting.timeframe ? setting.timeframe : 'now',
        creative: creativesMapping[setting.markupId],
    }));
};

const getCreativeRotationSummary = mode => {
    switch (mode) {
        case CreativeRotationMethodMapping.Single:
            return 'Single creative';
        case CreativeRotationMethodMapping.Weighted:
            return 'Weighted rotation';
        case CreativeRotationMethodMapping.Even:
            return 'Even rotation';
        case CreativeRotationMethodMapping.Scheduled:
            return 'Scheduled rotation';
        case CreativeRotationMethodMapping.OptimizedForConversions:
            return 'Optimize for conversions';
        case CreativeRotationMethodMapping.OptimizedForClicks:
            return 'Optimize for clicks';
        case CreativeRotationMethodMapping.OptimizedForVideoCompletion:
            return 'Optimize for Video Completion';
    }
};

let CreativeSummary = ({
    orgTimezone,
    value,
    highlight,
    onLabelWeatherConditionQualifier,
    onLabelWeatherConditionValue,
    onLabelWeatherTimeFrame,
    ...props
}) => {
    const classes = styles();

    const {
        rotationRules: { mode, scheduled, weighted, weather },
        creativeField,
        creativesByCampaign,
        timezone,
    } = value;
    const style = highlight ? { background: '#ffaaaa' } : null;
    if (
        checkIsNewScheduledWeightedRotationMode(value.rotationRules) &&
        mode === CreativeRotationMethodMapping.Scheduled
    ) {
        const schedules = getScheduledWeightedRotationSummary({
            rotationRules: value.rotationRules,
            creativesMapping: creativesByCampaign,
        });

        return (
            <CreativesByScheduleWeightSummary
                schedules={schedules}
                classes={classes}
                highlight={highlight}
                creativeLink={props.creativeLink}
                timezone={orgTimezone}
            />
        );
    }

    if (mode === CreativeRotationMethodMapping.Weather) {
        const creativeRotationByWeatherSummary = getCreativeRotationByWeatherSummary({
            settings: weather,
            creativesByCampaign,
        });

        return creativeRotationByWeatherSummary.length > 0 ? (
            <div>
                <SummaryTypeText keyName="Rotation Method" value="Weather Condition" />
                <div className="ef3-adSummaryView__item-group">
                    <dt className="ef3-adSummaryView_term">Creatives</dt>
                    <dd className="ef3-adSummaryView_description">
                        <CreativeRotationByWeather
                            settings={creativeRotationByWeatherSummary}
                            onLabelWeatherConditionQualifier={onLabelWeatherConditionQualifier}
                            onLabelWeatherConditionValue={onLabelWeatherConditionValue}
                            onLabelWeatherTimeFrame={onLabelWeatherTimeFrame}
                        />
                    </dd>
                </div>
            </div>
        ) : (
            <div>None</div>
        );
    }

    const creativeIds = creativeField;
    let summaryData;
    if (mode === CreativeRotationMethodMapping.Scheduled) {
        summaryData = _getScheduledObjs(creativesByCampaign, scheduled, timezone);
    } else if (
        mode === CreativeRotationMethodMapping.Weighted ||
        mode === CreativeRotationMethodMapping.Even
    ) {
        const selectedCreatives = _.map(creativeIds, id => creativesByCampaign[id].attr);
        const weightMap = {};
        _.forEach(weighted, rule => (weightMap[rule.markup_id] = { weight: rule.weight }));
        summaryData = getWeightedObjs(selectedCreatives, weightMap);
    } else {
        summaryData = _getCreativeObjs(creativesByCampaign, creativeIds, scheduled, timezone);
    }

    const term = getCreativeRotationSummary(mode);

    return (
        <div>
            <SummaryTypeText keyName="Rotation Method" value={term} />
            <div className="ef3-adSummaryView__item-group">
                <dt className="ef3-adSummaryView_term">Creatives</dt>
                <dd className="ef3-adSummaryView_description" style={style}>
                    {_.map(summaryData, data => (
                        <div key={data.id} className={classes.item} style={style}>
                            <div>
                                {mode === CreativeRotationMethodMapping.Scheduled && (
                                    <div>
                                        <Typography variant="subtitle2">
                                            {`${data.start} - ${data.end}`}
                                        </Typography>
                                    </div>
                                )}
                                <Link to={props.creativeLink}>
                                    <Typography variant="body2">#{data.id}</Typography>
                                </Link>
                                <Typography variant="body2">{data.name}</Typography>
                                {(mode === CreativeRotationMethodMapping.Weighted ||
                                    mode === CreativeRotationMethodMapping.Even) && (
                                    <Typography variant="subtitle2">
                                        Weight: {formatNumber_percentage(data.weight)}
                                    </Typography>
                                )}
                            </div>
                            <div>
                                <img className="creativePreviewImg" src={data.previewImg} />
                            </div>
                        </div>
                    ))}
                </dd>
            </div>
        </div>
    );
};

CreativeSummary = connect(selector)(CreativeSummary);

export default highlight(CreativeSummary, (currentProps, nextProps) =>
    hasChanged(currentProps.value.creativeId, nextProps.value.creativeId)
);

const useStyles = makeStyles(theme => ({
    image: {
        width: '100%',
    },
    grid: {
        marginBottom: theme.spacing(2),
    },
    rotation: {
        marginBottom: theme.spacing(1),
    },
    creativeSummaryContainer: {
        marginBottom: 10,
    },
    imageName: {
        whiteSpace: 'pre-wrap',
    },
}));

let CreativesByScheduleWeight = ({ orgTimezone, schedules, classes }) => {
    const styles = useStyles();

    if (schedules.length === 1 && schedules[0].weighted.length === 0) {
        return <Label placeholder>Add a creative</Label>;
    }

    return schedules.length > -1 ? (
        <div>
            <div className={classes.rotation}>Rotation Type: {'Custom Rotation'}</div>
            {_.map(schedules, schedule => (
                <Grid container className={classes.grid} key={schedule.id}>
                    {schedule.start && schedule.end ? (
                        <Box width="100%">
                            <Typography variant="subtitle1">
                                {`${formatDate_long(
                                    schedule.start,
                                    orgTimezone
                                )} - ${formatDate_long(schedule.end, orgTimezone)}`}
                            </Typography>
                        </Box>
                    ) : (
                        <Box width="100%">
                            <Typography variant="subtitle1" color="error">
                                Invalid dates
                            </Typography>
                        </Box>
                    )}
                    {_.map(schedule.weighted, weighted => (
                        <React.Fragment key={weighted.id}>
                            <Grid container className={styles.creativeSummaryContainer} spacing={2}>
                                {weighted.previewImg ? (
                                    <Grid item xs={2}>
                                        <img className={styles.image} src={weighted.previewImg} />
                                    </Grid>
                                ) : (
                                    <Typography variant="body2" color="error">
                                        Creative: Incomplete
                                    </Typography>
                                )}
                                <Grid item xs={10}>
                                    {weighted.markup_id && (
                                        <Typography variant="body2">
                                            # {weighted.markup_id}
                                        </Typography>
                                    )}
                                    {weighted.name && (
                                        <Typography variant="body2" className={styles.imageName}>
                                            Name: {weighted.name}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="body2"
                                        color={!weighted.weight ? 'error' : 'initial'}
                                    >
                                        Weight:{' '}
                                        {weighted.weight
                                            ? `${weighted.weight}%`
                                            : 'should be more than 0'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Typography
                        variant="subtitle2"
                        color={_.sumBy(schedule.weighted, 'weight') === 100 ? 'initial' : 'error'}
                    >
                        {_.sumBy(schedule.weighted, 'weight') === 100
                            ? `Total weight: ${_.sumBy(schedule.weighted, 'weight')}%`
                            : 'Total weight must equal 100%'}
                    </Typography>
                </Grid>
            ))}
        </div>
    ) : (
        <Label placeholder>Add a creative</Label>
    );
};

CreativesByScheduleWeight = connect(selector)(CreativesByScheduleWeight);

export { CreativesByScheduleWeight };

const CreativeRotationByWeatherDefaultSummary = ({ setting, classes }) => (
    <Box key={setting.id} mb={2}>
        <Box mb={2}>
            <Separator
                label={
                    <Typography variant="subtitle1" gutterBottom>
                        Default Rule
                    </Typography>
                }
            />
        </Box>
        {setting.creative && setting.creative.id ? (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <img className={classes.image} src={setting.creative.previewImg} />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body2">#{setting.creative.id}</Typography>
                    <Typography variant="subtitle2">{setting.creative.name}</Typography>
                </Grid>
            </Grid>
        ) : (
            <Typography variant="subtitle2">None</Typography>
        )}
    </Box>
);

const CreativeRotationByWeatherNonDefaultSummary = ({
    setting,
    title,
    onLabelWeatherConditionQualifier,
    onLabelWeatherConditionValue,
    onLabelWeatherTimeFrame,
    classes,
}) => (
    <Box key={setting.id} mb={2}>
        <Box mb={2}>
            <Separator
                label={
                    <Typography variant="subtitle1" gutterBottom>
                        {title}
                    </Typography>
                }
            />
        </Box>
        {setting.creative && setting.creative.id ? (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <img className={classes.image} src={setting.creative.previewImg} />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body2">#{setting.creative.id}</Typography>
                    <Typography variant="subtitle2">{setting.creative.name}</Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="subtitle2">Condition</Typography>
                            <Typography variant="body2">
                                {setting.type ? getConditionLabel(setting.type) : ''}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">Qualifier</Typography>
                            <Typography variant="body2">
                                {onLabelWeatherConditionQualifier(setting.qualifier)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">Value</Typography>
                            <Typography variant="body2">
                                {onLabelWeatherConditionValue(setting.amount, setting.type)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">Time Frame</Typography>
                            <Typography variant="body2">
                                {onLabelWeatherTimeFrame(setting.timeframe)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        ) : (
            <Typography variant="subtitle2">None</Typography>
        )}
    </Box>
);

function CreativesByScheduleWeightSummary({
    timezone,
    schedules,
    classes,
    highlight,
    creativeLink,
}) {
    const style = highlight ? { background: '#ffaaaa' } : null;

    return (
        <div>
            <SummaryTypeText keyName="Rotation Method" value="Scheduled rotation" />
            <div className="ef3-adSummaryView__item-group">
                <dt className="ef3-adSummaryView_term">Creatives</dt>
                <dd className="ef3-adSummaryView_description" style={style}>
                    {_.map(schedules, schedule => (
                        <div key={schedule.id} className={classes.item} style={style}>
                            <Typography variant="subtitle2">
                                {`${formatDate_long(schedule.start, timezone)} - ${formatDate_long(
                                    schedule.end,
                                    timezone
                                )}`}
                            </Typography>
                            {_.map(schedule.weighted, weighted => (
                                <div key={weighted.id}>
                                    <Link to={creativeLink}>
                                        <Typography variant="body2">
                                            #{weighted.markup_id}
                                        </Typography>
                                    </Link>
                                    <Typography variant="body2">{weighted.name}</Typography>
                                    <Typography variant="body2">
                                        Weight: {formatNumber_percentage(weighted.weight / 100)}
                                    </Typography>
                                    <div>
                                        <img
                                            className="creativePreviewImg"
                                            src={weighted.previewImg}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </dd>
            </div>
        </div>
    );
}

export function getScheduledWeightedRotationSummary({ rotationRules, creativesMapping }) {
    const newSchedule = _.map(rotationRules.scheduled, schedule => ({
        ...schedule,
        weighted: _.map(schedule.weighted, weighted => ({
            ...weighted,
            name: _.get(creativesMapping, `${weighted.markup_id}.attr.name`),
            previewImg: getPreviewUrl(_.get(creativesMapping, `${weighted.markup_id}.attr`, {})),
        })),
    }));

    return newSchedule;
}

export function Creatives(props) {
    const classes = useStyles();
    const {
        rotationRules: { mode, scheduled, weather },
        creativeField,
        creativesByCampaign,
        timezone,
    } = props.value;

    const {
        onLabelWeatherConditionQualifier,
        onLabelWeatherConditionValue,
        onLabelWeatherTimeFrame,
    } = props;

    if (
        checkIsNewScheduledWeightedRotationMode(props.value.rotationRules) &&
        props.value &&
        props.value.rotationRules.mode === CreativeRotationMethodMapping.Scheduled
    ) {
        const schedules = getScheduledWeightedRotationSummary({
            rotationRules: props.value.rotationRules,
            creativesMapping: creativesByCampaign,
        });

        return <CreativesByScheduleWeight schedules={schedules} classes={classes} />;
    }

    if (props.value.rotationRules.mode === CreativeRotationMethodMapping.Weather) {
        const creativeRotationByWeatherSummary = getCreativeRotationByWeatherSummary({
            settings: weather,
            creativesByCampaign,
        });

        return creativeRotationByWeatherSummary.length > 0 ? (
            <div>
                <Box mb={2}>Rotation Method: Weather Condition</Box>
                <CreativeRotationByWeather
                    settings={creativeRotationByWeatherSummary}
                    onLabelWeatherConditionQualifier={onLabelWeatherConditionQualifier}
                    onLabelWeatherConditionValue={onLabelWeatherConditionValue}
                    onLabelWeatherTimeFrame={onLabelWeatherTimeFrame}
                />
            </div>
        ) : (
            <Label placeholder>Add a creative</Label>
        );
    }

    const creativeIds = creativeField;
    let summaryData;
    if (mode === CreativeRotationMethodMapping.Scheduled) {
        summaryData = _getScheduledObjs(creativesByCampaign, scheduled, timezone);
    } else {
        summaryData = _getCreativeObjs(creativesByCampaign, creativeIds, scheduled, timezone);
    }

    const term = getCreativeRotationSummary(mode);

    return summaryData.length > 0 ? (
        <div>
            <div className={classes.rotation}>{term}</div>
            <Grid container spacing={2} className={classes.grid}>
                {_.map(summaryData, summary => (
                    <React.Fragment key={summary.id}>
                        {mode === CreativeRotationMethodMapping.Scheduled && (
                            <div>
                                {summary.start} ~<br />
                                {summary.end}
                            </div>
                        )}
                        <Grid item xs={1}>
                            <img className={classes.image} src={summary.previewImg} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="body2"># {summary.id}</Typography>
                            <Typography variant="subtitle2">{summary.name}</Typography>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    ) : (
        <Label placeholder>Add a creative</Label>
    );
}

const CreativeRotationByWeather = ({
    settings,
    onLabelWeatherConditionQualifier,
    onLabelWeatherConditionValue,
    onLabelWeatherTimeFrame,
}) => {
    const classes = useStyles();

    return (
        <div>
            {_.filter(settings, setting => !setting.isDefault).map((setting, index) => (
                <CreativeRotationByWeatherNonDefaultSummary
                    setting={setting}
                    title={`Rule ${index + 1}`}
                    onLabelWeatherConditionQualifier={onLabelWeatherConditionQualifier}
                    onLabelWeatherConditionValue={onLabelWeatherConditionValue}
                    onLabelWeatherTimeFrame={onLabelWeatherTimeFrame}
                    key={setting.id}
                    classes={classes}
                />
            ))}

            {_.filter(settings, setting => setting.isDefault).map(setting => (
                <CreativeRotationByWeatherDefaultSummary
                    setting={setting}
                    title={`Rule ${settings.length}`}
                    onLabelWeatherConditionQualifier={onLabelWeatherConditionQualifier}
                    onLabelWeatherConditionValue={onLabelWeatherConditionValue}
                    onLabelWeatherTimeFrame={onLabelWeatherTimeFrame}
                    key={setting.id}
                    classes={classes}
                />
            ))}
        </div>
    );
};

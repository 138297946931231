import { getBaseUrl } from 'utils/http/redux';

let _after;
function listenForEvents({ eventHandler, getState, localStorage }) {
    const token = _.get(getState(), 'profile.authToken.auth_token');
    if (!token) {
        return;
    }

    const eventSource = new EventSource(
        `${getBaseUrl(getState)}events?authorization=${token}&after=${_after || ''}`
    );

    eventSource.onerror = () => {
        eventSource.close();
        setTimeout(() => listenForEvents({ eventHandler, getState, localStorage }), 2500);
    };

    eventSource.onmessage = event => {
        const parsedData = JSON.parse(event.data);

        eventHandler(parsedData);
        _after = parsedData._id;
    };

    return eventSource;
}

function makeBuffer(dispatch) {
    const queue = [];
    let isRunning = false;
    function run() {
        isRunning = true;
        if (queue.length === 0) {
            isRunning = false;
            return;
        }
        setTimeout(() => {
            const event = queue.shift();
            dispatch({ type: event.name, payload: event.meta });
            run();
        }, 100);
    }
    return event => {
        queue.push(event);
        if (!isRunning) {
            run();
        }
    };
}

const eventMw = ({ localStorage }) => ({ dispatch, getState }) => {
    const buffer = makeBuffer(dispatch);
    const eventHandler = event => {
        if (event.name) {
            buffer(event);
        }
    };
    let eventSource = listenForEvents({
        eventHandler,
        getState,
        localStorage,
    });

    return next => action => {
        next(action);
        if (action.type === 'LOGIN') {
            if (eventSource) {
                eventSource.close();
            }
            eventSource = listenForEvents({
                eventHandler,
                getState,
                localStorage,
            });
        }
        if (action.type === 'LOGOUT') {
            if (eventSource) {
                eventSource.close();
                eventSource = null;
            }
        }
    };
};

export const makeMiddlewares = deps => {
    return [eventMw(deps)];
};

export const middlewares = makeMiddlewares({
    localStorage: window.localStorage,
});

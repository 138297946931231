import _ from 'lodash';

export const commonValidators = {
    name(value) {
        if (!value) {
            return 'Name is required';
        }
    },
    format(value) {
        if (!value) {
            return 'Format is required';
        }
    },
    size(value) {
        if (!value) {
            return 'Size is required';
        }
    },
    platforms(platforms) {
        if (platforms.length === 0) {
            return 'Platform is required';
        }
    },
    type(type) {
        if (!type) {
            return 'Format is required';
        }
    },
    positions(positions, source) {
        // This field isn't required for DOOH creatives.
        if (source.platforms.includes('dooh')) {
            return;
        }

        const isUsingInStream = _.includes(source.placements, 'in_stream');

        if (!isUsingInStream) {
            return;
        }

        const hasPositions = positions.length > 0;
        if (!hasPositions) {
            return 'Position is required';
        }
    },
};

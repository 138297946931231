import React from 'react';
import classnames from 'classnames';

import { PrimaryButton } from 'widgets-v5/buttons';

const HELPFUL_SUGGESTIONS = [
    'Wrangle some jangles!',
    'Pray to the great god Google!',
    'Our team of helper puppies are on the way!',
    'Make a cup of calming tea!',
];

export default class CatchAll extends React.Component {
    state = { errorThrown: false };

    onClick = () => {
        window.location.reload();
    };

    constructor(props) {
        super(props);
    }

    componentDidCatch(error, info) {
        this.setState({
            errorThrown: true,
        });

        window.bugsnagClient &&
            window.bugsnagClient.notify(error, {
                metaData: {
                    type: 'Critical rendering error',
                    componentStack: info,
                },
                severity: 'error',
            });
    }

    render() {
        if (this.state.errorThrown) {
            return (
                <div className="ef5-catch-all">
                    <img src="/images/EngageFront-logo.png" />
                    <h1>
                        {
                            HELPFUL_SUGGESTIONS[
                                Math.floor(Math.random() * Math.floor(HELPFUL_SUGGESTIONS.length))
                            ]
                        }
                    </h1>
                    <br /> <br />
                    <em>
                        Something went wrong while rendering the page :( The development team has
                        been notified.{' '}
                    </em>
                    <PrimaryButton onClick={this.onClick} text="Click to refresh" />
                </div>
            );
        }

        return this.props.children;
    }
}

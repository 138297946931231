import _ from 'lodash';
import schema from './schema';

export const NAME = 'ftaLocationListsEditor';

const defaultDraft = {
    name: '',
    comments: '',
    rawCsv: '',
};

const initialState = {
    isProcessingLocations: false,
    isLoading: false,
    locations: [],
    locationList: null,
    fileName: null,
    draft: defaultDraft,
    showErrors: null,
    showFileTypeError: false,
    errors: null,
    csvErrors: null,
    failedAssets: [],
};

const handlers = {
    FTA_LOCATION_LISTS_EDITOR__FETCH_DATA__END: (state, action) => {
        return {
            ...state,
            locationList: action.payload.data,
            isLoading: false,
            isProcessingLocations: false,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__FETCH_DATA__START: (state, action) => {
        return {
            ...state,
            locations: [],
            isLoading: true,
            isProcessingLocations: true,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__FETCH_DATA__ERROR: (state, action) => {
        return {
            ...state,
            isLoading: false,
            isProcessingLocations: false,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__SUBMIT__START: (state, action) => {
        return {
            ...state,
            isLoading: true,
            isSaving: true,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__SUBMIT__END: (state, action) => {
        return {
            ...state,
            isLoading: false,
            showErrors: false,
            isSaving: false,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__SUBMIT__ERROR: (state, action) => {
        return {
            ...state,
            isLoading: false,
            showErrors: true,
            isSaving: false,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__CREATE_NEW: (state, action) => {
        return {
            ...state,
            locationList: null,
            locations: [],
            isLoading: false,
            draft: defaultDraft,
            fileName: null,
            csvErrors: null,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__OPEN: (state, action) => {
        return {
            ...state,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__ERROR: (state, action) => {
        return {
            ...state,
            isLoading: false,
            isProcessingLocations: false,
            showErrors: true,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__CHANGE_NAME: (state, action) => {
        return updateDraft(state, {
            name: action.payload.name,
        });
    },

    FTA_LOCATION_LISTS_EDITOR__CHANGE_COMMENTS: (state, action) => {
        return updateDraft(state, {
            comments: action.payload.comments,
        });
    },

    FTA_LOCATION_LISTS_EDITOR__PROCESS_LOCATIONS: (state, action) => {
        // NOTE: We need to replace spaces in the filename with _
        // so that the FTA system doesn't crash!
        return {
            ...state,
            isProcessingLocations: true,
            fileName: action.payload.fileName.replace(/\s/g, '_'),
        };
    },

    FTA_LOCATION_LISTS_EDITOR__CLOSE: (state, action) => {
        return {
            ...state,
            locationList: null,
            locations: [],
            isLoading: false,
            fileName: null,
            draft: defaultDraft,
            showErrors: false,
            csvErrors: null,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__PROCESS_LOCATIONS__FAILURE: (state, action) => {
        return {
            ...state,
            isProcessingLocations: false,
            showFileTypeError: false,
            csvErrors: action.payload.csvErrors,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__PROCESS_LOCATIONS__SUCCESS: (state, action) => {
        return {
            ...state,
            isProcessingLocations: false,
            locations: action.payload.locations,
            draft: {
                ...state.draft,
                rawCsv: action.payload.rawCsv,
            },
            options: action.payload.options,
            csvErrors: null,
            showFileTypeError: false,
        };
    },

    FTA_LOCATION_LISTS_EDITOR__SET_FILE_TYPE_ERROR: (state, action) => {
        const payloadFiles = action.payload.files;
        const failedAssets = _.map(payloadFiles, file => {
            return {
                name: file.name,
                reason: 'Invalid File Type',
            };
        });

        return {
            ...state,
            failedAssets,
            isProcessingLocations: false,
            csvErrors: null,
            fileName: null,
            showFileTypeError: true,
        };
    },
};

export default function reducer(state = initialState, action) {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
    }

    if (nextState === state) {
        return state;
    } else {
        return updateState(nextState);
    }
}

function validateSchema(draft) {
    const options = {
        allowUnknown: true,
        abortEarly: false,
    };

    const result = schema.validate(draft, options);

    if (!result.error) {
        return {};
    }

    const errors = {};

    _.each(result.error.details, detail => {
        errors[detail.path.join('.')] = detail.message;
    });

    return errors;
}

function validateBusinessLogic(draft) {
    const errors = {};
    return errors;
}

function updateState(state) {
    const schemaErrors = validateSchema(state.draft);
    const businessErrors = validateBusinessLogic(state.draft);

    return {
        ...state,
        errors: {
            ...schemaErrors,
            ...businessErrors,
        },
    };
}

function updateDraft(state, changes) {
    return {
        ...state,
        draft: {
            ...state.draft,
            ...changes,
        },
    };
}

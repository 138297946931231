function selector(storeState) {
    const ftaLocationLists = _.get(storeState, 'adForm.form.ftaLocationLists');
    const orgPartnerId = _.get(storeState, 'adForm.form.ownOrganization.fta_partner_id', null);
    const campaignPartnerId = _.get(storeState, 'adForm.form.campaign.fta_partner_id', null);
    const partnerId = _.isNil(campaignPartnerId) ? orgPartnerId : campaignPartnerId;

    const ftaFee = _.get(storeState, 'adForm.form.campaign.fta_fee_standard', 0);
    const campaignFtaEnabled = _.get(storeState, 'adForm.form.campaign.fta_enabled', false);
    const campaignFtaManagementLevel = _.get(
        storeState,
        'adForm.form.campaign.fta_management_level',
        'campaign'
    );
    const campaignFtaLocationList = _.get(storeState, 'adForm.form.campaign.fta_location_list', '');

    const ftaLocationListOptions = _.map(ftaLocationLists, locationList => ({
        value: locationList.id,
        label: locationList.name,
    }));

    return {
        partnerId,
        ftaFee,
        campaignFtaEnabled,
        campaignFtaManagementLevel,
        campaignFtaLocationList,
        ftaLocationListOptions,
    };
}

export default selector;

import _ from 'lodash';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { createSelector } from 'reselect';
import { REDUCER_KEY } from './reducer';

export const getPredictionEditorData = state => get(state, REDUCER_KEY);
export const getProfileToken = state => get(state, `profile.authToken`);
export const getOwnOrganization = state => get(state, 'predictionEditor.organization');
export const getPrediction = state => get(state, 'resources.inventoryPredictions');
export const getAllGeoCategories = state => get(state, 'resource.geoCategories');

export const getPredictionState = createSelector(
    getPrediction,
    (prediction) => {
        if (prediction) {
            const {
                isLoading: isLoadingPrediction,
                error: predictionError,

                dailyUniqueUsers,
                dailyAvailableImpressions,
        
                totalUniqueUsersMin,
                totalAvailableImpressionsMin,

                totalUniqueUsersMax,
                totalAvailableImpressionsMax,
            } = prediction;

            return {
                predictionError,
                isLoadingPrediction,

                dailyUniqueUsers,
                dailyAvailableImpressions,

                totalUniqueUsersMin,
                totalAvailableImpressionsMin,

                totalUniqueUsersMax,
                totalAvailableImpressionsMax,
            }
        }

        return {};
    }
)

export const getTargetingSubCategories = createSelector(
    getAllGeoCategories,
    (allGeoCategories) => {
        return allGeoCategories ? _(allGeoCategories.geoCategories)
            .map(category => category.subcategories)
            .flatten()
            .value() : {};
    }
)

export const getSelectedPoiCategories = createSelector(
    getTargetingSubCategories,
    (geoTargetingSubCategories) => {
        return filter(
            get(geoTargetingSubCategories, 'geoLayers', []),
            geoLayer => draft.geo_targeting_settings.category_layers.indexOf(geoLayer.id) !== -1
        )
    }
)

export const getSelectedPoiSegments = createSelector(
    getAllGeoCategories,
    (allGeoCategories) => {
        return filter(
            get(allGeoCategories, 'geoLayers', []),
            geoLayer => draft.geo_targeting_settings.category_layers.indexOf(geoLayer.id) !== -1
        )
    }
)

import get from 'lodash/get';

export default function selector(storeState) {
    const audienceSegments = get(storeState, 'intentDashboard.audienceSegments');
    const totalSegmentCount = get(storeState, 'intentDashboard.totalSegmentCount');
    const isLoading = get(storeState, 'intentDashboard.isLoading');
    const organizationId = get(storeState, 'profile.organizationId');

    return {
        audienceSegments,
        totalSegmentCount,
        isLoading,
        organizationId,
    };
}

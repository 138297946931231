import _ from 'lodash';

import { regEx } from 'utils/regular-expression-library';
const urlValidationRegExp = regEx.url;

export const standardValidators = {
    image_asset() {
        // optional
    },
    image_url(value) {
        if (!urlValidationRegExp.test(value)) {
            return `Banner URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    image_preview_url(value) {
        if (value === undefined) {
            return;
        }

        if (value === '') {
            return;
        }

        if (!urlValidationRegExp.test(value)) {
            return `Banner Preview URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_pixels(value) {
        if (value === undefined) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_pixels to be a list');
        }

        const allValid = _.every(value, pixel => urlValidationRegExp.test(pixel));

        if (!allValid) {
            return `Third Party Pixels should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_javascript_urls(value) {
        if (value === undefined) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_javascript_urls to be a list');
        }

        const allValid = _.every(value, pixel => urlValidationRegExp.test(pixel));

        if (!allValid) {
            return `Third Party JavaScript should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_clickthrough(value) {
        if (!_.isBoolean(value)) {
            return 'Third Party Clickthrough is required';
        }
    },
    clickthrough_url(value, source) {
        // This field isn't required for DOOH creatives.
        if (source.platforms.includes('dooh')) {
            return;
        }

        if (!value && source.third_party_clickthrough === true) {
            return `Clickthrough URL should start with 'http://', 'https://', or 'tel:'`;
        }

        if (!urlValidationRegExp.test(value)) {
            return `Clickthrough URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    landing_page(value, source) {
        // This field isn't required for DOOH creatives.
        if (source.platforms.includes('dooh')) {
            return;
        }

        if (value === undefined && source.third_party_clickthrough === false) {
            return;
        }

        if (!value) {
            return `Landing Page should start with 'http://', 'https://', or 'tel:'`;
        }

        if (value === source.clickthrough_url && source.third_party_clickthrough === true) {
            return 'Landing Page cannot be the same as Clickthrough URL';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Landing Page should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    creative_vendors(value, source) {
        if (source.assetSelectionMethod === 'inputByUrl') {
            if (!value.length) {
                return 'At least one creative vendor must be specified';
            }
        }
    },
};

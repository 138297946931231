import _ from 'lodash';
import React from 'react';

import Grid from '@mui/material/Grid';

import countryListForSelect2 from 'common/constants/countries';

import FormField from 'widgets-v5/form-field';
import GeotargetsSelector from 'widgets/geotargets-selector';
import { SimpleSelect } from 'widgets-v6/select';

import { getEnvironmentSettings } from 'services/environment';

// Format the list for StandardSelect
// TODO: If we are no longer using Select2, then we should
// update the canonical list to use a better format
const countryListForStandardSelect = countryListForSelect2.map(country => ({
    value: country.id,
    label: country.text,
}));

export default class Geotargets extends React.Component {
    onAddCountry(country) {
        country = typeof country === 'string' ? country : country.target.value;
        const newGeotargets = [...this.props.value, { country, include: [], exclude: [] }];
        this.props.onChange(newGeotargets);
    }

    render() {
        const selectedCountries = this.props.value.map(geotargets => geotargets.country);
        const environmentSettings = getEnvironmentSettings();
        const allowedCountryCodes = environmentSettings.getCountryCodes(this.props.campaignType);

        // Selectable countries are only include those which have not yet been selected
        const selectableCountries = _.filter(countryListForStandardSelect, country => {
            return (
                _.includes(allowedCountryCodes, country.value) &&
                !_.includes(selectedCountries, country.value)
            );
        });

        const { required = true } = this.props;

        return (
            <FormField
                showErrors={this.props.showErrors}
                errors={this.props.errors}
                isRequired={required}
            >
                <Grid container justifyContent="space-between" spacing={1}>
                    {!this.props.hideCountrySelector && (
                        <Grid item xs={12} md={3}>
                            <SimpleSelect
                                required
                                options={selectableCountries}
                                value={selectedCountries && ''}
                                margin="normal"
                                placeholder="Add a Country"
                                onChange={country => this.onAddCountry(country)}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={9}>
                        <GeotargetsSelector
                            profileToken={this.props.profileToken}
                            value={this.props.value}
                            onChange={geotargets => this.props.onChange(geotargets)}
                            removeCountryButton={this.props.removeCountryButton}
                        />
                    </Grid>
                </Grid>
            </FormField>
        );
    }
}

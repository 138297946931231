import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.apps';
export default function appLists(state = null, action) {
    switch (action.type) {
        case c.SYSTEM__APPS__FETCH__SUCCESS:
            const { apps } = action.payload;
            // @TODO need to remove the follow after server side make correction
            // const apps_temp = apps.map( (app)=> {
            //     app.id = app.bundle_id;
            //     return app;
            // } );
            // const o = mergeResource(state, apps_temp);
            const o = mergeResource(state, apps);
            return o;

        default:
            return state;
    }
}

import moment from 'moment';

export const defaultValues = {
    layerMetadata: {
        name: '',
        radius: 500,
        category_code: null,
        status: 'active',
        organization: null,
        time_constraint: {
            date_range: [
                {
                    start: null,
                    end: null,
                },
            ],
        },
        manual_activation_until: null,
        created_date: moment().toISOString(),
    },
    points: [],
    audience_type: null, // always-on / time-limited
    source: 'uploaded', // uploaded / cleanlist / legacy
    cleanlist_entries: [
        /*
        {
            brand: '', // String REQUIRED
            province: '', // String, or null
            city: '',      // String, or null
            poits: 0,     // String, or null
        }
        */
    ],
};

export const AUDIENCE_STATUS_MAPPING = {
    active: 'active',
    suspended: 'suspended',
    pending: 'pending',
    processed: 'processed',
    suspending: 'suspending',
    processing: 'processing',
};

export const RADIUS_OPTIONS = [
    { label: '100m', value: 100 },
    { label: '200m', value: 200 },
    { label: '300m', value: 300 },
    { label: '400m', value: 400 },
    { label: '500m', value: 500 },
    { label: '600m', value: 600 },
    { label: '700m', value: 700 },
    { label: '800m', value: 800 },
    { label: '900m', value: 900 },
    { label: '1km', value: 1000 },
    { label: '2km', value: 2000 },
    { label: '3km', value: 3000 },
    { label: '5km', value: 5000 },
    { label: '10km', value: 10000 },
];

export const TEMPLATE_FILENAME = 'upload-template.csv';

export const TEMPLATE_CONTENTS = 'latitude,longitude,address,city,postalCode,province\n,,,,,';

export const EXAMPLE_TEMPLATE_FILENAME = 'upload-example-template.csv';

export const EXAMPLE_TEMPLATE_CONTENTS =
    'latitude,longitude,address,city,postalCode,province\n49.274705,-122.790906,"3025 Lougheed Highway","Richmond","V3B 6S2","BC"';

export const EXAMPLE_TEMPLATE_CONTENTS_EN_1562 =
    'latitude,longitude,address,city,postalCode,province\n49.274705,-122.790906,3025 Lougheed Highway,Richmond,V3B 6S2,BC\n50.274705,-123.790906,,,,\n,,3026 Lougheed Highway,Richmond,V3B 6S2,BC\n,,5305 Murray St,Niagara Falls,,ON\n,,5200 Robinson St,,L2G 2A3,';

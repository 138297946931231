import _ from 'lodash';
import $ from 'jquery';
import toastr from 'toastr';
import VError from 'verror';

import config from '../../../config';
import store from 'core/store';
import { createServerErrorAsToastr } from 'common/server-errors-toastr';
import flagService from 'containers/flags/service';
import { GraphQLClient } from 'graphql-request';
import logger from 'services/logger';

const { API_BASES } = config;

const LOCAL_URL = 'http://localhost:8000/v1/';
const START_DELIM = '::::';
const END_DELIM = '$$$$';
const MAX_TRIES = 5000;

var remoteBases = API_BASES.slice();

function getAPIbranch() {
    return _.get(store.getState(), 'devSettings.currentBranch', 'develop');
}

function getAPImode() {
    return _.get(store.getState(), 'devSettings.apiMode', 'remote');
}

const defaultOptions = {
    redirectOnFail: true,
    forceRemote: false,
};

export function getNextBase(forceRemote) {
    if (config.ENV_NAME === 'development' && getAPImode() === 'remote') {
        return 'https://ef-dev.engagecore.com/v1/';
    } else if (config.ENV_NAME === 'development' && getAPImode() === 'local' && !forceRemote) {
        return LOCAL_URL;
    } else {
        const url = remoteBases.shift();
        remoteBases.push(url);

        return url;
    }
}

export function getAuthToken() {
    return _.get(store.getState(), 'profile.authToken.auth_token', '');
}

export function createHttp(options) {
    options = _.assign({}, defaultOptions, options);

    const handleError = (reject, requestMetadata, parentError) => (xhr, status) => {
        try {
            let error;
            if (parentError) {
                error = new VError(parentError, xhr.statusText);
            } else {
                error = new VError(xhr.statusText);
            }

            error.code = xhr.status;
            error.body = xhr.responseJSON;
            error.bodyText = xhr.responseText;

            if (xhr.status === 401 && options.redirectOnFail) {
                handle401();
            } else if (xhr.status === 401) {
                toastr.error('Unauthorized');
            } else if (xhr.status === 412) {
                // alert('Precondition failed');
                toastr.error(
                    'Resource was modified by another user. To prevent overwriting, your changes have not been saved. Please refresh the page and try again.'
                );
            } else if (xhr.status >= 500) {
                handle500(error);
            } else {
                console.error(xhr.responseText);
            }

            reject(error);
        } catch (err) {
            reject(err);
        }
    };

    const handleSuccess = resolve => (data, status, xhr) => {
        resolve(data);
    };

    return {
        setIfMatch(etag) {
            this.ifMatch = etag;
            return this;
        },

        _getHeaders() {
            var headers = {
                Authorization: getAuthToken(),
                'X-API-Client': process.env.VERSION,
                'X-Flags': flagService.getEnabledFlagKeys().join(','),
            };

            if (this.ifMatch) {
                headers = {
                    ...headers,
                    'If-Match': this.ifMatch,
                };
            }

            return headers;
        },

        graphql(query, variables) {
            const graphqlUrl = getNextBase() + 'graphql';
            const client = new GraphQLClient(graphqlUrl, {
                headers: {
                    ...this._getHeaders(),
                },
            });

            return client.request(query, variables).catch(err => {
                if (err.response.status === 401) {
                    handle401();
                }

                throw err;
            });
        },

        get(url, params = {}) {
            const fullUrl = getNextBase(options.forceRemote) + url;
            const error = new VError({
                name: `failed HTTP GET to ${fullUrl}`,
                info: params,
            });

            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'GET',
                    data: params,
                    url: fullUrl,
                    headers: this._getHeaders(),
                    success: handleSuccess(resolve),
                    error: handleError(reject, { url: fullUrl, method: 'GET' }, error),
                });
            });
        },

        post(url, payload, opts) {
            const fullUrl = getNextBase(options.forceRemote) + url;
            const error = new VError({
                name: `failed HTTP POST to ${fullUrl}`,
                info: {
                    payload,
                    opts,
                },
            });

            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: fullUrl,
                    data: JSON.stringify(payload),
                    headers: this._getHeaders(),
                    contentType: 'application/json',
                    success: handleSuccess(resolve),
                    error: handleError(reject, { url: fullUrl, method: 'POST' }, error),
                    ...opts,
                });
            });
        },

        postFile(url, payload, opts) {
            const fullUrl = getNextBase(options.forceRemote) + url;
            const error = new VError({
                name: `failed HTTP POST to ${fullUrl}`,
                info: {
                    payload,
                    opts,
                },
            });

            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: fullUrl,
                    data: payload,
                    headers: this._getHeaders(),
                    cache: false,
                    processData: false,
                    contentType: false,
                    success: handleSuccess(resolve),
                    error: handleError(reject, { url: fullUrl, method: 'POST' }, error),
                    ...opts,
                });
            });
        },

        put(url, payload) {
            const fullUrl = getNextBase(options.forceRemote) + url;
            const error = new VError({
                name: `failed HTTP PUT to ${fullUrl}`,
                info: {
                    payload,
                },
            });

            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'PUT',
                    url: fullUrl,
                    data: JSON.stringify(payload),
                    headers: this._getHeaders(),
                    contentType: 'application/json',
                    success: handleSuccess(resolve),
                    error: handleError(reject, { url: fullUrl, method: 'PUT' }, error),
                });
            });
        },

        patch(url, payload) {
            const fullUrl = getNextBase(options.forceRemote) + url;
            const error = new VError({
                name: `failed HTTP PATCH to ${fullUrl}`,
                info: {
                    payload,
                },
            });

            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'PATCH',
                    url: fullUrl,
                    data: JSON.stringify(payload),
                    headers: this._getHeaders(),
                    contentType: 'application/json',
                    success: handleSuccess(resolve),
                    error: handleError(reject, { url: fullUrl, method: 'PATCH' }, error),
                });
            });
        },

        del(url) {
            const fullUrl = getNextBase(options.forceRemote) + url;
            const error = new VError({
                name: `failed HTTP DELETE to ${fullUrl}`,
                info: {},
            });

            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'DELETE',
                    url: fullUrl,
                    headers: this._getHeaders(),
                    success: handleSuccess(resolve),
                    error: handleError(reject, { url: fullUrl, method: 'DELETE' }, error),
                });
            });
        },

        getHipsterTargets() {
            const fullUrl = getNextBase(options.forceRemote);
            const error = new VError({
                name: `failed HTTP GET to ${fullUrl}`,
                info: {},
            });

            return new Promise((resolve, reject) => {
                const headers = this._getHeaders();
                delete headers['x-hipster-branch'];

                headers['content-type'] = 'application/json';

                $.ajax({
                    type: 'GET',
                    url: fullUrl,
                    headers: headers,
                    success: handleSuccess(resolve),
                    error: handleError(reject, { url: fullUrl, method: 'GET' }),
                });
            });
        },
        stream(url) {
            const _http = this;
            return {
                eventHandlers: {},
                on(event, callback) {
                    this.eventHandlers[event] = callback;
                    return this;
                },
                start() {
                    const self = this;
                    $.ajax({
                        type: 'GET',
                        url: getNextBase(options.forceRemote) + url,
                        headers: _http._getHeaders(),
                        contentType: 'application/json',
                        xhr() {
                            const xhr = new XMLHttpRequest();
                            const streamParser = new StreamParser();

                            streamParser.onData(obj => {
                                if (self.eventHandlers.data) {
                                    self.eventHandlers.data(obj);
                                }
                            });

                            xhr.addEventListener(
                                'progress',
                                e => {
                                    const text = e.target.responseText;
                                    streamParser.parse(text);
                                },
                                false
                            );

                            return xhr;
                        },
                    })
                        .fail((xhr, status, error) => {
                            if (self.eventHandlers.error) {
                                self.eventHandlers.error(error);
                            }
                        })
                        .done(() => {
                            if (self.eventHandlers.success) {
                                self.eventHandlers.success();
                            }
                        });
                },
            };
        },
    };
}

class StreamParser {
    constructor(startDelim = '::::', endDelim = '$$$$') {
        this.START_DELIM = startDelim;
        this.END_DELIM = endDelim;
        this.MAX_TRIES = 5000;
        this.rangeStart = -1;
        this.eventHandlers = {};
        this.dataHandler = _.noop;
    }
    parse(stream) {
        let parseNext = true;
        let tries = 0;
        while (parseNext) {
            tries += 1;

            const start = stream.indexOf(this.START_DELIM, this.rangeStart);
            const end = stream.indexOf(this.END_DELIM, start);

            if (start > this.rangeStart && end > start) {
                const startIndex = start + this.START_DELIM.length;
                const line = stream.substring(startIndex, end);

                let docs = [];
                try {
                    docs = [].concat(JSON.parse(line));
                } catch (e) {
                    console.log(e, line);
                }

                _.each(docs, doc => {
                    this.dataHandler(doc);
                });
                this.rangeStart = startIndex;
            } else {
                parseNext = false;
            }

            // Failsafe
            if (tries >= this.MAX_TRIES) {
                parseNext = false;
            }
        }
    }
    onData(callback) {
        this.dataHandler = callback;
    }
}

function handle401() {
    store.dispatch({ type: 'UNAUTHORIZED' });
}

function handle500(error) {
    console.error(error);
}

export default createHttp;

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class extends React.Component {
    static propTypes = {
        geoTargetingSettings: PropTypes.object.isRequired,
        mapConf: PropTypes.object.isRequired,
        points: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                latitude: PropTypes.number,
                longitude: PropTypes.number,
            })
        ).isRequired,
        createPoint: PropTypes.func.isRequired,
        parent: PropTypes.instanceOf(React.Component).isRequired,
    };

    state = {
        allowedToPlacePoints: false,
    };

    UNSAFE_componentWillMount() {
        // window.n = this;
        this._cached = {};
        this._cached.markers = [];
        this._cached.circles = [];
        this._cached.pointRadius = void 0;
    }

    componentDidMount() {
        this.map = new google.maps.Map(this.refs.map, {
            center: { lat: 43.6525, lng: -79.381667 },
            zoom: 12,
            scrollwheel: false,
            mapTypeControl: false,
            streetViewControl: false,
            maxZoom: 18,
        });
        console.log('geofencing map editor mounted');

        // If shapes were drawn, ensure that they are within viewport
        this.fitBoundsToPoints(this.props.points);

        // Initialize Click events for points/markers
        this.initializeGoogleClickEvents();

        // Initialize searchbox
        this.initializeGoogleSearchBox();
    }

    componentWillUnmount() {
        // ATTENTION:
        // https://code.google.com/p/gmaps-api-issues/issues/detail?id=3803
        // There is no way official way to clean up a Google Map
        // Best you can do is remove the event listeners that you manually added
        //
        // TODO in the far off future:
        // Figure out how to re-initialize and re-use existing Map instances
        // google.maps.event.clearListeners(this.drawingManager);
        google.maps.event.clearListeners(this.searchBox);
        google.maps.event.clearListeners(this.map);

        this.refs.widget.removeEventListener('click', this.onWidgetClick);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const that = this;
        let shouldUpdate = false;
        let shouldUpdateRadius = nextProps.geoTargetingSettings.radius !== this._cached.pointRadius;

        this._cached.pointRadius = _.get(nextProps, `geoTargetingSettings.radius`, void 0);

        // points
        const points_nxt = _.get(nextProps, `points`, void 0);
        const points_prv = _.get(this.props, `points`, void 0);

        // ids
        const ids_nxt = _.map(points_nxt, point => {
            return point.id;
        });
        const ids_prv = _.map(points_prv, point => {
            return point.id;
        });

        if (shouldUpdateRadius) {
            shouldUpdate = true;

            // Clear the map and re-render with the new radius
            _.map(ids_nxt, id => {
                removeItemFromGoogleMap(that._cached.circles, 'circle_' + id);
                removeItemFromGoogleMap(that._cached.markers, id);

                _.remove(that._cached.circles, circle => circle.pointId === 'circle_' + id);
                _.remove(that._cached.markers, marker => marker.pointId === id);
            });

            _.map(points_nxt, point => {
                const { marker, circle } = that.createGoogleMapMarker(point);
                that._cached.markers.push(marker);
                that._cached.circles.push(circle);
            });
        } else {
            // add points
            const ids_new = _.difference(ids_nxt, ids_prv);
            if (ids_new.length !== 0) {
                shouldUpdate = true;

                const points_to_add = _.filter(points_nxt, point => _.includes(ids_new, point.id));
                _.map(points_to_add, point => {
                    const { marker, circle } = that.createGoogleMapMarker(point);
                    that._cached.markers.push(marker);
                    that._cached.circles.push(circle);
                });
            }

            // remove points
            const ids_remove = _.difference(ids_prv, ids_nxt);
            // const points_to_remove = _.filter( points_prv, point => _.includes(ids_remove, point.id) );
            if (ids_remove.length !== 0) {
                shouldUpdate = true;

                _.map(ids_remove, id => {
                    removeItemFromGoogleMap(that._cached.circles, 'circle_' + id);
                    removeItemFromGoogleMap(that._cached.markers, id);
                    //need to remove item from that._cached
                    _.remove(that._cached.circles, circle => circle.pointId === 'circle_' + id);
                    _.remove(that._cached.markers, marker => marker.pointId === id);
                });
            }
        }

        // setting change
        const searchWithinMapBounds_nxt = _.get(nextProps, `mapConf.searchWithinMapBounds`, void 0);
        const searchWithinMapBounds_prv = _.get(
            this.props,
            `mapConf.searchWithinMapBounds`,
            void 0
        );
        if (searchWithinMapBounds_nxt !== searchWithinMapBounds_prv) {
            shouldUpdate = true;
        }

        const allowedToPlacePoints_nxt = nextState.allowedToPlacePoints;
        const allowedToPlacePoints_prv = this.props.allowedToPlacePoints;
        if (allowedToPlacePoints_nxt !== allowedToPlacePoints_prv) {
            shouldUpdate = true;
        }

        return shouldUpdate;
    }

    initializeGoogleClickEvents = () => {
        google.maps.event.addListener(this.map, 'click', event => {
            if (!this.state.allowedToPlacePoints) {
                return;
            }

            const tempId = _.uniqueId('new_');
            const latitude = event.latLng.lat();
            const longitude = event.latLng.lng();

            new Promise((resolve, reject) => {
                const geocoder = new google.maps.Geocoder();

                geocoder.geocode(
                    {
                        location: {
                            lat: latitude,
                            lng: longitude,
                        },
                    },
                    (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                resolve(results[0].formatted_address);
                            }
                        } else {
                            reject(status);
                        }
                    }
                );
            })
                .then(result => {
                    this.props.createPoint({ latitude, longitude, name: result, pointId: tempId });
                })
                .catch(() => {
                    this.props.createPoint({
                        latitude,
                        longitude,
                        name: 'Unknown',
                        pointId: tempId,
                    });
                });
        });
    };

    initializeGoogleSearchBox = () => {
        const searchBoxInParent = _.get(this.props, `parent.refs.searchBox_v2`, void 0);
        if (searchBoxInParent) {
            this.searchBox = new google.maps.places.SearchBox(searchBoxInParent);

            const searchWithinMapBounds = _.get(
                this.props,
                `mapConf.searchWithinMapBounds`,
                void 0
            );

            if (searchWithinMapBounds !== void 0) {
                // weed out the case this property is undefined

                if (searchWithinMapBounds === true) {
                    // Bias the SearchBox results towards current map's viewport.

                    // @TODO problem here:
                    // getBounds() returns undefind on componentDidMount
                    this.searchBox.setBounds(this.map.getBounds());
                } else if (searchWithinMapBounds === true) {
                    this.searchBox.setBounds();
                }
            }

            google.maps.event.addListener(this.searchBox, 'places_changed', () => {
                // Clear search box
                searchBoxInParent.value = '';

                const places = this.searchBox.getPlaces();
                if (!places.length) {
                    return;
                }

                if (places.length >= 10) {
                    const confirmPlacement = confirm(
                        `Are you sure you want to add ${places.length} points?`
                    );
                    if (!confirmPlacement) {
                        return;
                    }
                }

                const placesWithLatLng = _.map(places, place => {
                    const latitude = place.geometry.location.lat();
                    const longitude = place.geometry.location.lng();

                    return {
                        ...place,
                        latitude,
                        longitude,
                    };
                });

                placesWithLatLng.forEach(place => {
                    this.props.createPoint({
                        latitude: place.latitude,
                        longitude: place.longitude,
                        name: `${place.name} - ${place.formatted_address}`,
                        pointId: _.uniqueId('new_'),
                    });
                });

                // delay map bounds reset to allow point creation
                setTimeout(this.fitBoundsToPoints, 1000);
            });
        }
    };

    createGoogleMapMarker = pointData => {
        const marker = new google.maps.Marker({
            position: {
                lat: pointData.latitude,
                lng: pointData.longitude,
            },
            map: this.map,
            pointId: pointData.id,
        });

        const circle = this.createNewGoogleMapCircle(marker, pointData.id);

        return { marker, circle };
    };

    createNewGoogleMapCircle = (marker, id) => {
        const markerCenter = marker.getPosition();
        const pointRadius = this._cached.pointRadius ? this._cached.pointRadius : 0;

        // Add circle overlay and bind to marker
        const circle = new google.maps.Circle({
            map: this.map,
            radius: parseInt(pointRadius, 10),
            fillColor: '#BBDDDD', // @ef5-color__deep-cyan-1
            strokeColor: '#318599', // @ef5-color__deep-cyan-3;
            strokeWeight: 1,
            strokeOpacity: 0.5,
            pointId: 'circle_' + id,
            center: markerCenter,
        });
        return circle;
    };

    fitBoundsToPoints = points => {
        let pointsForBoundary = points;

        // default to the props if no points passed in
        if (!points || !points.length) {
            pointsForBoundary = this.props.points;
        }

        // ignore if no points in props, otherwise you'll end up in the ocean
        if (!pointsForBoundary || !pointsForBoundary.length) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();

        pointsForBoundary.forEach(point => {
            bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));
        });

        this.map.fitBounds(bounds);

        // zoom out if there is only one point
        if (pointsForBoundary.length === 1) {
            this.map.setZoom(14);
        }
    };

    setAllowedToPlacePoints = state => {
        this.setState({ allowedToPlacePoints: state });
    };

    UNSAFE_componentWillUpdate(nextProps) {
        const searchWithinMapBounds = _.get(nextProps, `mapConf.searchWithinMapBounds`, void 0);
        if (searchWithinMapBounds) {
            this.searchBox.setBounds(this.map.getBounds());
        } else {
            this.searchBox.setBounds();
        }
    }

    render() {
        const { points } = this.props;

        return (
            <div
                ref="widget"
                className={classnames('ef5-geofencing__map-wrapper', {
                    allowToPlacePoint: this.state.allowedToPlacePoints,
                })}
            >
                <div className="ef5-geofencing__map-overlay">
                    <div
                        onClick={() => this.setAllowedToPlacePoints(false)}
                        className={classnames('ef5-geofencing__map-overlay-cursor', {
                            'ef5-geofencing__map-overlay-cursor--is-active': !this.state
                                .allowedToPlacePoints,
                        })}
                    >
                        <i className="fa fa-fw fa-hand-o-up" />
                    </div>
                    <div
                        onClick={() => this.setAllowedToPlacePoints(true)}
                        className={classnames('ef5-geofencing__map-overlay-marker', {
                            'ef5-geofencing__map-overlay-marker--is-active': this.state
                                .allowedToPlacePoints,
                        })}
                    >
                        <i className="fa fa-fw fa-map-marker" />
                    </div>
                </div>

                <div ref="map" className="ef5-geofencing__map" />

                <div className="ef5-geofencing__map-overlay-button">
                    <button
                        onClick={() => this.fitBoundsToPoints(points)}
                        className="btn btn-secondary btn-sm"
                    >
                        See All Points
                    </button>
                </div>
            </div>
        );
    }
}

function removeItemFromGoogleMap(list, id) {
    const newList = _.filter(list, item => {
        if (item.pointId === id) {
            item.setMap(null);
            return false;
        }
        return true;
    });
    return newList;
}

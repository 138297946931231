import _ from 'lodash';

import querystring from 'utils/querystring';

import { NAME } from './reducer';

export default function selector(storeState, props) {
    const poiDashboardState = _.get(storeState, NAME);
    const poiLayerResources = _.get(storeState, 'resources.pois.layers', {});

    const allGeoCategories = _.get(storeState, 'resources.geoCategories');
    const poiCategories = _(allGeoCategories.geoCategories)
        .map(category => category.subcategories)
        .flatten()
        .value();

    const filters = querystring.parse(window.location.href.split('?')[1]);
    const { pagination } = poiDashboardState;

    const pois = _(pagination[filters.page || 1])
        .map(id => poiLayerResources[id])
        .filter(a => a)
        .map(a => ({
            ...a.attr,
            points: _.get(a.attr, 'points', []).length,
            category: _.find(poiCategories, category => category.id === a.attr.category_code),
        }))
        .value();

    return {
        ...poiDashboardState,
        pois,
    };
}

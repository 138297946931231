import _ from 'lodash';

import flags from 'containers/flags/service';

export function handleIASViewabilityChange() {
    return {
        description: 'Handle IAS Viewability Change',
        target(state, action) {
            return 'AD_FORM__IAS_VIEWABILITY__CHANGE' === action.type;
        },
        reduce(state, action) {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    ias: {
                        ...state.draft.ias,
                        viewability: {
                            ...state.draft.ias.viewability,
                            value: action.payload.code,
                        },
                    },
                },
            };
        },
    };
}

import each from 'lodash/each';
import omit from 'lodash/omit';

export const EXCHANGE_OPTIONS = {
    standard: [
        'adx_inapp',
        'aerserv_inapp',
        'index_inapp',
        'mobfox_inapp',
        'mopub_inapp',
        'nexage_inapp',
        'omax_inapp',
        'pubmatic_inapp',
        'rubicon_inapp',
        'smaato_inapp',
    ],
    video: [
        'adx_inapp',
        'aerserv_inapp',
        'index_inapp',
        'mobfox_inapp',
        'mopub_inapp',
        'nexage_inapp',
        'omax_inapp',
        'smaato_inapp',
    ],
    mraid: [
        'adx_inapp',
        'aerserv_inapp',
        'index_inapp',
        'mopub_inapp',
        'nexage_inapp',
        'rubicon_inapp',
        'smaato_inapp',
    ],
    custom_html: [
        'adx_inapp',
        'aerserv_inapp',
        'index_inapp',
        'mopub_inapp',
        'nexage_inapp',
        'pubmatic_inapp',
        'rubicon_inapp',
    ],
    native: ['mopub_inapp'],
    third_party_vast: [
        'adx_inapp',
        'aerserv_inapp',
        'index_inapp',
        'mobfox_inapp',
        'mopub_inapp',
        'nexage_inapp',
        'omax_inapp',
        'smaato_inapp',
    ],
};

export const EXCHANGE_LABEL_MAPPING = {
    adx: 'Google Ad Manager',
    aerserv: 'AerServ',
    index: 'Index Exchange',
    mobfox: 'Mobfox',
    mopub: 'MoPub',
    nexage: 'ONE by AOL',
    omax: 'AdColony',
    pubmatic: 'PubMatic',
    rubicon: 'Rubicon Project',
    smaato: 'Smaato',
    sharethrough: 'Sharethrough',
    vistar: 'Vistar',
};

export const PLATFORMS_SUPPORTED = ['inapp', 'mweb', 'desktop'];

export const IAS_VIEWABILITY_OPTIONS = {
    none: {
        default: -1,
        options: [
            {
                label: 'Off',
                value: -1,
            },
        ],
    },
    all: {
        display: {
            default: 140,
            options: [
                {
                    label: 'Target 40% viewability or higher for display campaigns',
                    value: 140,
                },
                {
                    label: 'Target 50% viewability or higher for display campaigns',
                    value: 150,
                },
                {
                    label: 'Target 60% viewability or higher for display campaigns',
                    value: 160,
                },
                {
                    label: 'Target 70% viewability or higher for display campaigns',
                    value: 170,
                },
                {
                    label: 'Target up to 40% viewability for display campaigns',
                    value: 9140,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
        video: {
            default: 92640,
            options: [
                {
                    label: 'Target 40% viewability or higher for video campaigns',
                    value: 92640,
                },
                {
                    label: 'Target 50% viewability or higher for video campaigns',
                    value: 92650,
                },
                {
                    label: 'Target 60% viewability or higher for video campaigns',
                    value: 92660,
                },
                {
                    label: 'Target 70% viewability or higher for video campaigns',
                    value: 92670,
                },
                {
                    label: 'Target up to 40% viewability for video campaigns',
                    value: 92630,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
    },
    desktop: {
        display: {
            default: 940,
            options: [
                {
                    label: 'Target 40% viewability or higher for display campaigns',
                    value: 940,
                },
                {
                    label: 'Target 50% viewability or higher for display campaigns',
                    value: 950,
                },
                {
                    label: 'Target 60% viewability or higher for display campaigns',
                    value: 960,
                },
                {
                    label: 'Target 70% viewability or higher for display campaigns',
                    value: 970,
                },
                {
                    label: 'Target up to 40% viewability for display campaigns',
                    value: 930,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
        video: {
            default: 92040,
            options: [
                {
                    label: 'Target 40% viewability or higher for desktop video campaigns',
                    value: 92040,
                },
                {
                    label: 'Target 50% viewability or higher for desktop video campaigns',
                    value: 92050,
                },
                {
                    label: 'Target 60% viewability or higher for desktop video campaigns',
                    value: 92060,
                },
                {
                    label: 'Target 70% viewability or higher for desktop video campaigns',
                    value: 92070,
                },
                {
                    label: 'Target up to 40% viewability for desktop video campaigns',
                    value: 92030,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
    },
    mweb: {
        display: {
            default: 640,
            options: [
                {
                    label: 'Target 40% viewability or higher for display campaigns on mobile web',
                    value: 640,
                },
                {
                    label: 'Target 50% viewability or higher for display campaigns on mobile web',
                    value: 650,
                },
                {
                    label: 'Target 60% viewability or higher for display campaigns mobile web',
                    value: 660,
                },
                {
                    label: 'Target 70% viewability or higher for display campaigns mobile web',
                    value: 670,
                },
                {
                    label: 'Target up to 40% viewability for display campaigns on mobile web',
                    value: 639,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
        video: {
            default: 92340,
            options: [
                {
                    label: 'Target 40% viewability or higher for mobile web video campaigns',
                    value: 92340,
                },
                {
                    label: 'Target 50% viewability or higher for mobile web video campaigns',
                    value: 92350,
                },
                {
                    label: 'Target 60% viewability or higher for mobile web video campaigns',
                    value: 92360,
                },
                {
                    label: 'Target 70% viewability or higher for mobile web video campaigns',
                    value: 92370,
                },
                {
                    label: 'Target up to 40% viewability for mobile web video campaigns',
                    value: 92330,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
    },
    inapp: {
        display: {
            default: 4040,
            options: [
                {
                    label: 'Target 40% viewability or higher for mobile in-app display campaigns',
                    value: 4040,
                },
                {
                    label: 'Target 50% viewability or higher for mobile in-app display campaigns',
                    value: 4050,
                },
                {
                    label: 'Target 60% viewability or higher for mobile in-app display campaigns',
                    value: 4060,
                },
                {
                    label: 'Target 70% viewability or higher for mobile in-app display campaigns',
                    value: 4070,
                },
                {
                    label: 'Target up to 40% viewability for mobile-in-app display campaigns',
                    value: 4030,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
        video: {
            default: 8040,
            options: [
                {
                    label: 'Target 40% viewability or higher for mobile in-app video campaigns',
                    value: 8040,
                },
                {
                    label: 'Target 50% viewability or higher for mobile in-app video campaigns',
                    value: 8050,
                },
                {
                    label: 'Target 60% viewability or higher for mobile in-app video campaigns',
                    value: 8060,
                },
                {
                    label: 'Target 70% viewability or higher for mobile in-app video campaigns',
                    value: 8070,
                },
                {
                    label: 'Target up to 40% viewability for mobile-in-app video campaigns',
                    value: 8030,
                },
                {
                    label: 'Off',
                    value: -1,
                },
            ],
        },
    },
};

export const IAS_VIEWABILITY_OPTIONS_MAPPING = {};

each(omit(IAS_VIEWABILITY_OPTIONS, 'none'), platforms => {
    each(platforms, platform => {
        each(platform.options, option => {
            IAS_VIEWABILITY_OPTIONS_MAPPING[option.value] = option;
        });
    });
});

export const DECLARED_AGE_SEGMENTS = [
    'pds_fe6ef01a-756a-4ad2-8921-1ed577448584',
    'pds_fc8b928b-f757-4b8e-aaa9-40803d65b762',
    'pds_cb481470-8ce1-4739-9aa5-b95de19df874',
    'pds_360a77d4-10b9-4c6a-bbf1-388a1b62ce85',
    'pds_0ce2dfa7-80ea-4dd6-8300-94712b35230c',
    'pds_0444407-bccf-4d20-9d0e-4daf55955a46',
];

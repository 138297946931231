import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import actions from './actions';
import selector from './selector';

import ManifoldLayout from './layout';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const ManifoldDashboard = ({ dispatch, audienceSegments, params, organizationId }) => {
    useEffect(() => {
        dispatch(actions.getAudiences());
    }, [1]);

    const handleTextSearch = value => {
        handleSearch(value, dispatch);
    };

    return (
        <ManifoldLayout
            audienceSegments={audienceSegments}
            onSearch={handleTextSearch}
            activeAudience={params.audienceId}
            organizationId={organizationId}
        />
    );
};

export default connect(selector)(ManifoldDashboard);

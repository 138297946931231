import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import flags from 'containers/flags/service';

import actions from './actions';
import selector from './selector';

import Layout from './layout';

const categoryMap = {
    pds_brand: {
        label: 'Brand',
        description: 'Users who frequent locations from select brands',
    },
    pds_standard: {
        label: 'Location Categories',
        description: 'Users who frequent top locations from select categories',
    },
    pds_behaviour: {
        label: 'Behavioural',
        description:
            'Group consumers based on specific pattern they display, paired with frequently visited locations and first-party SDK location data',
    },
};

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const useStyles = makeStyles(theme => ({
    chip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    platformCell: {
        display: 'flex',
    },
    chipContainer: {
        width: 'fit-content',
        marginTop: theme.spacing(1),
    },
    tooltip: {
        fontSize: theme.tooltip.fontSize,
    },
}));

const BrandDashboard = ({ dispatch, audienceSegments, ...props }) => {
    useEffect(() => {
        dispatch(actions.getAudiences());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    return (
        <Layout
            audienceSegments={audienceSegments}
            onSearch={onChange}
            activeAudience={props.params.audienceId}
        />
    );
};

export default connect(selector)(BrandDashboard);

import _ from 'lodash';

import c from 'common/constants/flux-events';
import http from 'utils/http';

const actions = {
    get(activationToken) {
        return (dispatch, getState) => {
            return http()
                .get(`activation/${activationToken}`)
                .then(
                    token => {
                        dispatch({
                            type: c.SYSTEM__ACTIVATION_TOKEN__FETCH__SUCCESS,
                            payload: { token },
                        });

                        return token;
                    },
                    err => {
                        console.error(`GET activation error`, err);

                        throw err;
                    }
                );
        };
    },
    update(requestPayload) {
        return (dispatch, getState) => {
            return http()
                .post(`activation`, requestPayload)
                .then(
                    token => {
                        dispatch({
                            type: c.SYSTEM__ACTIVATION_TOKEN__FETCH__SUCCESS,
                            payload: { token },
                        });

                        return token;
                    },
                    err => {
                        console.error(`UPDATE activation error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default actions;

import PropTypes from 'prop-types';
import React from 'react';
import StandardSelector from 'widgets-v5/standard-selector';
import moment from 'moment';
import _ from 'lodash';

class TimeSelector extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        defaultValue: PropTypes.string,
        interval: PropTypes.number,
    };

    static defaultProps = {
        interval: 30,
    };

    render() {
        const { interval } = this.props;
        const options = _.sortBy(
            _.map(_.range(1, 49), number => {
                const val =
                    number % 2
                        ? moment(number / 2, 'HH')
                        : moment(number / 2, 'HH').subtract(interval, 'minutes');
                return {
                    label: val.format('LT'),
                    value: val.toISOString(),
                };
            }),
            'value'
        );

        const { onChange, defaultValue } = this.props;

        return (
            <div>
                <StandardSelector
                    searchable
                    value={defaultValue}
                    disabled={false}
                    placeholder="Time"
                    items={options}
                    onChange={onChange}
                />
            </div>
        );
    }
}

export default TimeSelector;

import Joi from '@hapi/joi';
import {
    VALID_WEATHER_CONDITION_TYPE,
    VALID_WEATHER_CONDITION_QUALIFIER,
} from 'common/constants/weather-condition-targeting';

const schema = Joi.array()
    .items(
        Joi.object({
            type: Joi.string().when('isDefault', {
                is: true,
                then: Joi.allow(''),
                otherwise: Joi.string()
                    .required()
                    .valid(...VALID_WEATHER_CONDITION_TYPE),
            }),
            qualifier: Joi.string().when('isDefault', {
                is: true,
                then: Joi.allow(''),
                otherwise: Joi.string()
                    .required()
                    .valid(...VALID_WEATHER_CONDITION_QUALIFIER),
            }),
            amount: Joi.number()
                .when('isDefault', {
                    is: true,
                    then: Joi.allow(-1),
                })
                .when('type', {
                    is: 'Temperature',
                    then: Joi.number()
                        .integer()
                        .required(),
                    otherwise: Joi.number().required(),
                })
                .when('type', {
                    is: 'FeelsLike',
                    then: Joi.number()
                        .integer()
                        .required(),
                    otherwise: Joi.number().required(),
                }),
            markupId: Joi.string().required(),
            id: Joi.string().required(),
            isDefault: Joi.boolean().required(),
        })
    )
    .optional();

export default schema;

import _ from 'lodash';

import METRICS from 'common/constants/metrics';
const METRIC_LOOKUP = {};
_.each(METRICS, m => {
    METRIC_LOOKUP[m.name] = m;
});

export default function selector(storeState, props) {
    const campaign = props.campaign;

    const rootDictionary = _.get(storeState, 'dictionary');
    const markupIdDict = _.get(storeState, `reportExplore.dictionary.markup_id`, {});
    const exploreDict = _.get(storeState, `reportExplore.dictionary`, {});

    const adgroups = _.pick(exploreDict, Object.keys(exploreDict.scope));

    const dictionary = {
        ...rootDictionary,
        markup_id: markupIdDict,
        ...adgroups,
    };

    const scheduledReport = _.get(storeState, 'scheduledReport', {});

    const errors = _.reduce(
        scheduledReport.errors,
        (result, err) => {
            return {
                ...result,
                [err.field.indexOf('.') > -1 ? _.first(err.field.split('.')) : err.field]: [
                    err.message,
                ],
            };
        },
        {}
    );

    let hasScheduleReport = _.get(
        storeState,
        `resources.scheduledReports.${campaign.scheduledReportId}`,
        false
    );

    if (hasScheduleReport) {
        const frequency = _.get(hasScheduleReport, 'attr.scheduleInfo.repeat', 'none');
        hasScheduleReport = !(frequency === 'none' || !frequency);
    }

    return {
        errors,
        recipients: scheduledReport.draft.recipients,
        metrics: scheduledReport.draft.metrics,
        hideZeroRows: scheduledReport.draft.hideZeroRows,
        hasScheduleReport,
        splits: scheduledReport.pivotTable.splits,
        pivotTable: scheduledReport.pivotTable,
        isModalOpen: scheduledReport.isModalOpen,
        showErrors: scheduledReport.showErrors,
        draft: scheduledReport.draft,
        dictionary,
        columns: getColumns(
            scheduledReport.columns,
            scheduledReport.draft.metrics,
            campaign.beacons,
            campaign.conversions
        ),
    };
}

function getColumns(columns, metrics, beacons, conversions) {
    const _METRIC_LOOKUP = {
        ...METRIC_LOOKUP,
    };
    _.each(beacons, beacon => {
        _METRIC_LOOKUP[beacon.name] = {
            name: `event_${beacon.name}`,
            label: beacon.label,
            formatType: 'thousands',
            defaultValue: 0,
        };
    });
    _.each(conversions, conversion => {
        _METRIC_LOOKUP[`conv_total_${conversion.event_name}`] = {
            name: `conv_total_${conversion.event_name}`,
            label: conversion.reporting_name,
            formatType: 'thousands',
            defaultValue: 0,
        };
    });
    const _metrics = _.filter(metrics.enabledMetrics, m => m.checked === true).map(
        m => _METRIC_LOOKUP[m.name]
    );

    return columns.concat(_metrics);
}

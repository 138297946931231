import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';

import { lighten } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';

const useStyles = makeStyles(theme => ({
    tacticHeader: {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
        display: 'flex',
        justifyContent: 'space-between',
    },
    tacticsFooter: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    targetingCard: {
        margin: 15,
    },
    targetingHeader: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[100],
        display: 'flex',
        justifyContent: 'space-between',
    },
    andButton: {
        display: 'flex',
        justifyContent: 'center',
    },
    orButton: {
        display: 'flex',
        justifyContent: 'center',
        margin: 10,
    },
    andOrButton: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

// OrCard
export function OrCard({ children }) {
    return <Paper>{children}</Paper>;
}

export function OrCardHeader({ children, onRemove }) {
    const classes = useStyles();

    return (
        <Toolbar className={classes.tacticHeader}>
            <span>{children}</span>
            <IconButton onClick={onRemove} size="large">
                <DeleteIcon />
            </IconButton>
        </Toolbar>
    );
}

export function OrCardBody({ children }) {
    return <div>{children}</div>;
}

export function OrCardFooter({ children }) {
    const classes = useStyles();
    return <CardActions className={classes.tacticsFooter}>{children}</CardActions>;
}

// AndCard
export function AndCard({ children }) {
    const classes = useStyles();
    return <Card className={classes.targetingCard}>{children}</Card>;
}

export function AndCardHeader({ children, onRemove }) {
    const classes = useStyles();
    return (
        <Toolbar className={classes.targetingHeader}>
            <span>{children}</span>
            <IconButton onClick={onRemove} size="large">
                <DeleteIcon />
            </IconButton>
        </Toolbar>
    );
}

export function AndCardBody({ children }) {
    return <CardContent>{children}</CardContent>;
}

export function AndCardFooter({ children }) {
    return <div>{children}</div>;
}

export function AndOrButton({ children }) {
    const classes = useStyles();
    return (
        <Card className={classes.andOrButton}>
            <CardContent>{children}</CardContent>
        </Card>
    );
}

export function AndDivider() {
    const classes = useStyles();
    return (
        <div className={classes.andButton}>
            <Chip label="AND" />
        </div>
    );
}

export function OrDivider() {
    const classes = useStyles();
    return (
        <div className={classes.orButton}>
            <Chip label="OR" />
        </div>
    );
}

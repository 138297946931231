import get from 'lodash/get';
import remove from 'lodash/remove';
import map from 'lodash/map';
import omit from 'lodash/omit';

import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
import Bacon from 'baconjs';
import moment from 'moment';
import UserActions from 'states/resources/users/actions';
import Organizations from 'states/resources/organizations/actions';
import { fetchReportStats } from 'services/resources/stats';
import { getEnvironmentSettings } from 'services/environment';

const http = createHttp();

const pivotTableStream = new Bacon.Bus();

const LOCAL_CURRENCY_IMPLEMENTATION_DATE = moment.utc('10-01-2016', 'MM-DD-YYYY').startOf('month');

const fetchOwnAndhChildOrganizationData = async userOrganizationId => {
    const query = `
        query getOwnAndChildOrganizationDataForAccount($filters: OrganizationFilters, $id: String) {
            organization(id: $id) {
                id
                name
                type
                _created
                default_timezone
                credit_limit
                currency
                custom_fields {
                    value
                    required
                    name
                    key
                }
            }
            organizations(filters: $filters) {
                id
                name
            }
        }
    `;
    const variables = {
        filters: {
            relationship: 'child',
            type: ['client', 'co_managed_client'],
            ids: [],
        },
        id: userOrganizationId,
    };
    const { organizations, organization } = await http.graphql(query, variables);
    const userOrganization = organization;
    const clientOrganizations = remove(
        organizations,
        organization => organization.id !== userOrganizationId
    );

    return { userOrganization, clientOrganizations };
};

pivotTableStream
    .flatMapLatest(({ campOrg, getState, dispatch, done }) => {
        const splits = get(getState(), `account.accountDetails.pivotTable.splits`, []);
        const split = map(splits, s => s.name);
        const dateRange = get(getState(), `account.accountDetails.dateRange`, {});

        let dateRangePayload = {
            ...omit(dateRange, ['cache', 'cacheKey', 'campaignStart', 'campaignEnd']),
            // Remove timezones/offsets from start/end
            start: moment(dateRange.start).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment(dateRange.end).format('YYYY-MM-DDTHH:mm:ss'),
        };

        const fetchData = () => {
            const options = {
                camp_org: campOrg,
                ...dateRangePayload,
                match: {},
                split,
                include_beacons: false,
                showSpendOverdeliveries: false,
                datasource: getEnvironmentSettings().getReportingDatasource(),
                queryTag: 'ef_account_page',
            };

            const promise = fetchReportStats(options, {
                mockResponseOnError: false,
                displayWarnStatsProblemRetryLater: false,
            })
                .then(stats => {
                    if (stats.statusCode === 404) {
                        // No stats found
                        return Promise.resolve({ stats: {}, done });
                    }
                    return {
                        stats,
                        done,
                    };
                })
                .catch(err => {
                    if (err.code === 503) {
                        dispatch({
                            type: 'ACCOUNT__FETCHING_REPORT_STATS__RESPONSE_TIMEOUT',
                            payload: {},
                        });
                        return Promise.resolve({ stats: {}, done, err });
                    }
                    return Promise.reject(err);
                });
            return Bacon.fromPromise(promise, true);
        };
        return Bacon.retry({
            source: fetchData,
            retries: 2,
            delay() {
                return 1000;
            },
        });
    })
    .onValue(({ campaigns, stats, done, err }) => {
        if (!err) {
            done(stats, campaigns);
        }
    });

export function initializeWithGraphqlFetch(query) {
    return async dispatch => {
        let summaryFetched = false;
        let pivotTableFetched = false;

        const pivotTableDone = (stats, campaigns) => {
            pivotTableFetched = true;

            summaryFetched = true;

            if (summaryFetched && pivotTableFetched) {
                dispatch({
                    type: c.ACCOUNT__INITIALIZE__SUCCESS,
                    payload: {
                        summaryStats: stats,
                        pivotTableStats: stats,
                        query,
                        campaigns,
                    },
                });
            }
        };

        dispatch({
            type: c.ACCOUNT__LINK_QUERY__INIT,
            payload: {
                query,
            },
        });

        dispatch({
            type: c.ACCOUNT__METRIC_SELECTOR__INIT,
            payload: {
                query,
            },
        });
        const user = await dispatch(UserActions.get());
        const { userOrganization, clientOrganizations } = await fetchOwnAndhChildOrganizationData(
            user.organization
        );

        dispatch({
            type: 'ACCOUNT__USER_ORGANIZATION_FETCH_SUCCESS',
            payload: { userOrganization },
        });
        // We use the creation date for the beginning of the date picker.
        const orgCreationDateMoment = moment.utc(userOrganization._created).startOf('month');
        const orgId = userOrganization.id;
        const timezone = userOrganization.default_timezone;

        const earliestStart = LOCAL_CURRENCY_IMPLEMENTATION_DATE.isAfter(orgCreationDateMoment)
            ? LOCAL_CURRENCY_IMPLEMENTATION_DATE.format('YYYY-MM-DDTHH:mm:ss')
            : orgCreationDateMoment.format('YYYY-MM-DDTHH:mm:ss');

        dispatch({
            type: c.ACCOUNT__INITIALIZE,
            payload: {
                earliestStart,
                timezone,
                organization: userOrganization,
                clientOrganizations,
            },
        });

        dispatch(fetchStatsForPivotTable(orgId, pivotTableDone));
    };
}

export function sortColumn(column) {
    return {
        type: c.ACCOUNT__PIVOT_TABLE__SORT,
        payload: {
            column,
        },
    };
}

export function expandRow(rowId) {
    return {
        type: c.ACCOUNT__PIVOT_TABLE__EXPAND_ROW,
        payload: {
            rowId,
        },
    };
}

export function collapseRow(rowId) {
    return {
        type: c.ACCOUNT__PIVOT_TABLE__COLLAPSE_ROW,
        payload: {
            rowId,
        },
    };
}

export function addSplit(dimension) {
    return {
        type: c.ACCOUNT__PIVOT_TABLE__ADD_SPLIT,
        payload: {
            dimension,
        },
    };
}

export function removeSplit(dimension) {
    return {
        type: c.ACCOUNT__PIVOT_TABLE__REMOVE_SPLIT,
        payload: {
            dimension,
        },
    };
}

export function updateSplits(splits) {
    return {
        type: c.ACCOUNT__PIVOT_TABLE__UPDATE_SPLITS,
        payload: {
            splits,
        },
    };
}

export function fetchStatsForPivotTable(campOrg, done) {
    return (dispatch, getState) => {
        pivotTableStream.push({ campOrg, getState, dispatch, done });
    };
}

export function refreshPivotTable() {
    return (dispatch, getState) => {
        dispatch({
            type: c.ACCOUNT__PIVOT_TABLE__DATA_REFRESH,
            payload: {},
        });

        const done = (stats, campaigns) => {
            dispatch({
                type: c.ACCOUNT__PIVOT_TABLE__DATA_REFRESH__SUCCESS,
                payload: {
                    stats,
                    campaigns,
                },
            });

            dispatch({
                type: c.ACCOUNT__METRIC_SELECTOR__DATA_REFRESH__SUCCESS,
                payload: {
                    stats,
                    campaigns,
                },
            });

            dispatch({
                type: c.ACCOUNT__SUMMARY__DATA_REFRESH__SUCCESS,
                payload: {
                    stats,
                },
            });
        };

        const profileOrganizationId = get(getState(), 'profile.organizationId');

        if (profileOrganizationId) {
            return dispatch(fetchStatsForPivotTable(profileOrganizationId, done));
        } else {
            return dispatch(UserActions.get())
                .then(user => {
                    return dispatch(Organizations.get(user.organization));
                })
                .then(
                    org => {
                        const orgId = org[0].id;

                        dispatch(fetchStatsForPivotTable(orgId, done));
                    },
                    error => {
                        console.error(error);
                    }
                );
        }
    };
}
export function updateTimezone(timezone) {
    return dispatch => {
        dispatch({
            type: 'ACCOUNT__FILTER__CHANGE_TIMEZONE',
            payload: {
                timezone,
            },
        });

        dispatch(refreshPivotTable());
    };
}
export function filterByDateRange(dateRange) {
    return dispatch => {
        dispatch({
            type: c.ACCOUNT__FILTER__DATE_RANGE_FILTER,
            payload: { dateRange },
        });

        dispatch(refreshPivotTable());
    };
}

export function metricSelector_toggleVisiblity(metric) {
    return {
        type: c.ACCOUNT__METRIC_SELECTOR__TOGGLE_VISIBILITY,
        payload: { metric },
    };
}

export function metricSelector_toggleSelection(metric) {
    return {
        type: c.ACCOUNT__METRIC_SELECTOR__TOGGLE_SELECTION,
        payload: { metric },
    };
}

export function onSearchCampaign(campaignSearch) {
    return {
        type: c.ACCOUNT__SEARCH__BY_CAMPAIGN,
        payload: { campaignSearch },
    };
}

export function onSelectClient(selectedClient) {
    return {
        type: c.ACCOUNT__SEARCH__BY_CLIENT,
        payload: { selectedClient },
    };
}

export function onFilterByStatus(campaignStatus) {
    return {
        type: c.ACCOUNT__SEARCH__BY_STATUS,
        payload: { campaignStatus },
    };
}

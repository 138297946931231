import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class extends React.Component {
    static displayName = 'Separator';

    render() {
        return (
            <div
                style={this.props.style}
                className={classnames('ef5-separator', this.props.className)}
            >
                {this.props.label && <div className="ef5-separator__label">{this.props.label}</div>}
                <div className="ef5-separator__border" />
            </div>
        );
    }
}

import _ from 'lodash';
import VError from 'verror';
import { parse } from 'csv-parse/sync';

import CreativesActions from 'states/resources/creatives/actions';
import { createHttp } from 'utils/http';
import notify from 'utils/notify';
import { TRACKING_VENDOR_TAGS, CREATIVES_VENDOR_TAGS } from '../../../common/constants/vendors';
import { getSuggestedVendors } from '../creative-form/reducer';

const http = createHttp();
const matchOptions = {
    creativeId: 'Creative ID',
    name: 'Name',
    externalId: 'External ID',
};

const headersMapping = {
    'Creative ID': 'id',
    Name: 'name',
    'Landing Page': 'landing_page',
    Clicktracking: 'third_party_clickthrough',
    'Clickthrough URL': 'clickthrough_url',
    'Third Party Pixels': 'third_party_pixels',
    'Third Party Javascript': 'third_party_javascript_urls',
    'Star Rating': 'star_rating',
    'Ad Title': 'title',
    'Main Text': 'text',
    'Call To Action': 'call_to_action',
    'Hosted VAST URL': 'hosted_vast_url',
    'Reference Screenshot': 'reference_screenshot',
    Duration: 'video_duration',
    'Bit Rate (Kbps)': 'video_bit_rate_kbps',
    'HTML Markup': 'content_html',
    'Creative Asset': 'image_url',
};

export const CreativeBulkEdit = {
    init(campaignId, selectedCreativeIds) {
        return async dispatch => {
            try {
                dispatch({
                    type: 'CREATIVE_BULK_EDIT_FORM__INIT_START',
                });

                const creatives = await dispatch(CreativesActions.getAll(campaignId));

                const query = `
                    query getBulkEditFormData ($campaignId: Int) {
                        campaign(id: $campaignId) {
                            files {
                                id
                                name
                                type
                                url
                                preview_url
                                height
                                width
                            }
                        }
                    }
                `;

                const variables = {
                    campaignId: Number(campaignId),
                };

                const data = await http.graphql(query, variables);

                dispatch({
                    type: 'CREATIVE_BULK_EDIT_FORM__INIT',
                    payload: {
                        creatives,
                        files: data.campaign.files,
                        selectedCreativeIds,
                    },
                });
            } catch (err) {
                notify(new VError(err, 'Failed to fetch data for Creative Bulk Edit Form'), {
                    campaignId,
                });
            }
        };
    },
    updateField(creativeId, fieldName, fieldValue) {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__UPDATE_FIELD',
                payload: {
                    creativeId,
                    fieldName,
                    fieldValue,
                },
            });
        };
    },
    showUpdatedTag(creativeId, fieldName) {
        return {
            type: 'CREATIVE_BULK_EDIT_FORM__SHOW_UPDATED_TAG',
            payload: {
                creativeId,
                fieldName,
            },
        };
    },
    removeField(fieldName) {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__REMOVE_FIELD',
                payload: {
                    fieldName,
                },
            });
        };
    },
    addField(fieldName) {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__ADD_FIELD',
                payload: {
                    fieldName,
                },
            });
        };
    },
    closeForm() {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__CLOSE_FORM',
            });
        };
    },
    openForm(campaignId, selectedCreativeIds) {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__OPEN_FORM',
            });
            dispatch(CreativeBulkEdit.init(campaignId, selectedCreativeIds));
        };
    },
    failedToSaveCreativesAndShowServerSideError(errors) {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__SHOW_SERVER_ERRORS',
                payload: {
                    errors,
                },
            });
        };
    },
    saveBulkEdit(campaignId, selectedCreativeIds, isSaveAudit = false) {
        return async (dispatch, getState) => {
            const selectedFields = _.get(getState(), `creativeBulkEditForm.selectedFields`);

            const errors = _.get(getState(), `creativeBulkEditForm.errors`);

            const selectedFieldsErrors = _.values(errors)
                .map(errorsOfOneCreative =>
                    _.filter(errorsOfOneCreative, error => _.includes(selectedFields, error.field))
                )
                .filter(errorArray => errorArray.length > 0);

            if (selectedFieldsErrors.length > 0) {
                dispatch({
                    type: 'CREATIVE_BULK_EDIT_FORM__SHOW_ERRORS',
                });

                return;
            }

            const draft = _.get(getState(), `creativeBulkEditForm.draft`).filter(creative =>
                _.includes(selectedCreativeIds, creative.id)
            );

            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__SUBMIT',
            });

            const errorsFromServer = {};

            for await (const creative of draft) {
                let _audit_status;
                if (!isSaveAudit) {
                    _audit_status = 'no_audit';
                } else {
                    _audit_status = 'pending';
                }

                const submittedFields = mapSelectedFieldsToPayloadFields(selectedFields);
                const submittedValues = _.pick(creative, submittedFields);

                if (
                    _.includes(selectedFields, 'landing_page') &&
                    !creative.third_party_clickthrough
                ) {
                    submittedValues['clickthrough_url'] = creative['clickthrough_url'];
                }

                const payloadSerialized = {
                    ...submittedValues,
                    audit_status: _audit_status,
                };
                try {
                    await dispatch(
                        CreativesActions.update(campaignId, creative.id, payloadSerialized)
                    );
                } catch (error) {
                    errorsFromServer[creative.id] = error;
                }
            }

            if (errorsFromServer && _.values(errorsFromServer).length === 0) {
                dispatch(CreativeBulkEdit.closeForm());
            } else {
                dispatch(
                    CreativeBulkEdit.failedToSaveCreativesAndShowServerSideError(errorsFromServer)
                );
            }
        };
    },

    saveBulkEdit2(campaignId, selectedCreativeIds, isSaveAudit = false) {
        return async (dispatch, getState) => {
            const selectedFields = _.get(getState(), `creativeBulkEditForm.selectedFields`);

            const errors = _.get(getState(), `creativeBulkEditForm.errors`);

            const selectedFieldsErrors = _.values(errors)
                .map(errorsOfOneCreative =>
                    _.filter(errorsOfOneCreative, error => _.includes(selectedFields, error.field))
                )
                .filter(errorArray => errorArray.length > 0);

            if (selectedFieldsErrors.length > 0) {
                dispatch({
                    type: 'CREATIVE_BULK_EDIT_FORM__SHOW_ERRORS',
                });

                return;
            }

            const draft = _.get(getState(), `creativeBulkEditForm.draft`).filter(creative =>
                _.includes(selectedCreativeIds, creative.id)
            );

            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__SUBMIT',
            });

            const errorsFromServer = {};
            const bulkEditCreatives = [];

            for (const creative of draft) {
                let _audit_status;
                if (!isSaveAudit) {
                    _audit_status = 'no_audit';
                } else {
                    _audit_status = 'pending';
                }
                const submittedFields = mapSelectedFieldsToPayloadFields(selectedFields);
                const submittedValues = _.pick(creative, submittedFields);

                const _etag = _.get(getState(), `resources.creatives.${creative.id}.attr._etag`);

                const trackingVendorTagsSuggested = getSuggestedVendors({
                    draft: creative,
                    selectedThirdPartyVendors: [], //creative.third_party_vendors
                    vendorTagsByType: TRACKING_VENDOR_TAGS,
                }).tagsSuggested;
                let tracking_vendors = [];
                if (trackingVendorTagsSuggested.length > 0) {
                    tracking_vendors = _.map(
                        trackingVendorTagsSuggested,
                        suggestion => suggestion.value
                    );
                }

                const creativeVendorTagsSuggested = getSuggestedVendors({
                    draft: creative,
                    selectedThirdPartyVendors: [], //creative.creative_vendors
                    vendorTagsByType: CREATIVES_VENDOR_TAGS,
                }).tagsSuggested;
                let creative_vendors = [];
                if (creativeVendorTagsSuggested.length > 0) {
                    creative_vendors = _.map(
                        creativeVendorTagsSuggested,
                        suggestion => suggestion.value
                    );
                }

                const third_party_vendors = creative_vendors.concat(tracking_vendors);
                const payloadSerialized = {
                    ...submittedValues,
                    audit_status: _audit_status,
                    creative_vendors,
                    tracking_vendors,
                    third_party_vendors,
                    _etag,
                    id: creative.id,
                };

                bulkEditCreatives.push(payloadSerialized);
            }

            await dispatch(CreativesActions.bulkEdit(campaignId, bulkEditCreatives));

            if (errorsFromServer && _.values(errorsFromServer).length === 0) {
                dispatch({
                    type: 'CREATIVE_BULK_EDIT_FORM__SUBMIT_SUCCESS',
                    payload: { campaignId },
                });
                dispatch(CreativeBulkEdit.closeForm());
            } else {
                dispatch(
                    CreativeBulkEdit.failedToSaveCreativesAndShowServerSideError(errorsFromServer)
                );
            }
        };
    },

    openMatchUploadModal() {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_EDIT_FORM__MATCH_UPLOAD__OPEN',
            });
        };
    },
    processUploadedTemplateToGetMatchOption(formData, selectedCreatives) {
        return dispatch => {
            const file = formData.get('asset');
            const reader = new FileReader();

            dispatch({
                type: 'CREATIVE_BULK_EDIT__SEARCH_MATCH_OPTIONS__START',
            });

            reader.addEventListener('loadend', async () => {
                const rawCsvData = reader.result;
                const csvHeaders = parse(rawCsvData, { relax_column_count: true })[0];
                const parsedCsvRows = parse(rawCsvData, {
                    columns: true,
                });

                dispatch({
                    type: 'CREATIVE_BULK_EDIT__PROCESS_GENERIC_TEMPLATE__START',
                    payload: {
                        fileData: { csvHeaders, parsedCsvRows },
                    },
                });

                const hasMatchOptions = ['Creative ID', 'Name'].every(option =>
                    csvHeaders.includes(option)
                );
                if (hasMatchOptions && csvHeaders.length > 2) {
                    dispatch(CreativeBulkEdit.openMatchUploadModal());
                } else {
                    dispatch(
                        CreativeBulkEdit.readGenericTemplateData(
                            csvHeaders,
                            parsedCsvRows,
                            selectedCreatives,
                            'creativeId'
                        )
                    );
                }
            });
            reader.readAsText(file);
        };
    },
    readGenericTemplateData(csvHeaders, parsedCsvRows, selectedCreatives, matchOption) {
        return dispatch => {
            const matchOptionHeader = matchOptions[matchOption];
            _.forEach(parsedCsvRows, row => {
                const creativeId = row['Creative ID'];
                const match = row[matchOptionHeader];
                let isSelected;
                _.forEach(selectedCreatives, creative => {
                    isSelected = _.includes(creative, match);
                    if (isSelected) {
                        _.forEach(csvHeaders, header => {
                            let fieldName = headersMapping[header];
                            let fieldValue = row[header];
                            if (_.includes(header, 'Third Party Pixels')) {
                                fieldName = headersMapping['Third Party Pixels'];
                                fieldValue = row[header].split(',');
                            } else if (header === 'Clicktracking') {
                                fieldValue = row[header] === 'TRUE';
                            }
                            if (fieldName !== 'id') {
                                dispatch(
                                    CreativeBulkEdit.updateField(creativeId, fieldName, fieldValue)
                                );
                                dispatch(CreativeBulkEdit.addField(fieldName));
                            }
                        });
                    }
                });
            });
            dispatch({
                type: 'CREATIVE_BULK_EDIT__PROCESS_TEMPLATE__SUCCESS',
            });
        };
    },

    readUploadedDCMTemplate(formData, selectedCreativeIds, dcmFileType) {
        return async dispatch => {
            const file = formData.get('asset');
            const reader = new FileReader();

            dispatch({
                type: 'CREATIVE_BULK_EDIT__READ_DCM_TEMPLATE__START',
            });

            reader.addEventListener('loadend', async () => {
                const { Sheets } = await http.postFile('excel', formData);

                dispatch({
                    type: 'CREATIVE_BULK_EDIT__READ_DCM_TEMPLATE__SUCCESS',
                    payload: { Sheets, selectedCreativeIds, dcmFileType },
                });
            });

            reader.readAsArrayBuffer(file);
        };
    },
};

function mapSelectedFieldsToPayloadFields(selectedFields) {
    const mapping = {
        image_url: ['image_asset', 'image_url', 'image_preview_url'],
        third_party_clickthrough: ['landing_page', 'clickthrough_url', 'third_party_clickthrough'],
    };

    return _(selectedFields)
        .map(field => {
            return mapping[field] ? mapping[field] : [field];
        })
        .flatten()
        .value();
}

import _ from 'lodash';

import { optionKeyValueMapping } from './index';

export default function selector(storeState, props) {
    const form = _.get(storeState, `creativeBulkEditForm`);
    const draft = _.get(storeState, `creativeBulkEditForm.draft`);
    const defaultCreatives = _.get(storeState, `creativeBulkEditForm.creativesDefaultValues`);
    const uploadError = _.get(storeState, `creativeBulkEditForm.uploadError`);

    const selectedFields = _.get(storeState, `creativeBulkEditForm.selectedFields`);
    const hasSelectedFields = selectedFields.length > 0;
    const selectedCreativeIds = _.get(props, `selectedCreativeIds`);

    const selectedCreatives = _.filter(draft, creative =>
        _.includes(selectedCreativeIds, creative.id)
    );

    const updatedFields = getUpdatedFields({ selectedCreativeIds, defaultCreatives, draft });

    const csvData = formatCsvData({ selectedFields, selectedCreatives });
    const csvContents = getCsvContents({ selectedFields, selectedCreatives });

    const formattedErrors = {};
    _.each(
        _.keys(form.errors),
        creativeId => (formattedErrors[creativeId] = reduceErrorList(form.errors[creativeId]))
    );

    return {
        ...form,
        errors: formattedErrors,
        hasSelectedFields,
        selectedCreatives,
        files: storeState.creativeBulkEditForm.files,
        csvData,
        updatedFields,
        uploadError,
        csvContents,
    };
}

function reduceErrorList(errorsAsList = []) {
    return _(errorsAsList)
        .groupBy(e => e.field)
        .reduce((acc, v, k) => {
            const o = {
                ...acc,
                [k]: v[0]['message'],
            };
            return o;
        }, {});
}

function formatCsvData({ selectedFields, selectedCreatives }) {
    const data = [];

    const csvHeaderFields = ['id', ...selectedFields];
    const headers = _.map(csvHeaderFields, field => optionKeyValueMapping[field]);
    data.push(headers);

    _.forEach(selectedCreatives, creative => {
        const row = _.map(csvHeaderFields, field => {
            const rowValue = creative[field];
            if (Array.isArray(rowValue)) {
                return rowValue.join('\n');
            }
            return rowValue;
        });
        data.push(row);
    });
    return data;
}

function getCsvContents({ selectedFields, selectedCreatives }) {
    const data = [];

    const csvHeaderFields = ['id', ...selectedFields];
    let headers = getHeaders(csvHeaderFields);
    data.push(headers.join(','));

    _.forEach(selectedCreatives, creative => {
        const row = getRows(creative, csvHeaderFields);
        data.push(row.join(','));
    });
    return data.join('\r\n');
}

function getUpdatedFields({ selectedCreativeIds, defaultCreatives, draft }) {
    let updatedFields = {};
    _.forEach(selectedCreativeIds, id => {
        updatedFields[id] = [];
    });
    _.forEach(defaultCreatives, (defaultCreative, index) => {
        const draftCreative = draft[index];
        _.forEach(draftCreative, (fieldValue, fieldName) => {
            const id = draftCreative.id;
            const isChanged = draftCreative[fieldName] !== defaultCreative[fieldName];
            if (isChanged) {
                updatedFields[id].push(fieldName);
            }
        });
    });
    return updatedFields;
}

function getHeaders(csvHeaderFields) {
    let headers = [];
    _.forEach(csvHeaderFields, field => {
        if (field === 'third_party_clickthrough') {
            headers.push('Clicktracking', 'Landing Page', 'Clickthrough URL');
        } else if (field === 'third_party_pixels') {
            headers.push(`Third Party Pixels_comma separated`);
        } else {
            headers.push(optionKeyValueMapping[field]);
        }
    });
    return headers;
}

function getRows(creative, csvHeaderFields) {
    let row = [];

    _.forEach(csvHeaderFields, field => {
        let fieldValue = creative[field];
        if (field === 'third_party_clickthrough') {
            const landingPageValue = creative.landing_page;
            const clickthroughValue = creative.clickthrough_url;
            const clickTracking = creative.third_party_clickthrough;
            row.push(clickTracking, landingPageValue, clickthroughValue);
        } else if (field === 'content_html') {
            const htmlValue = creative.content_html;
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            const data = htmlValue.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            const final = data.replace(/"/g, '""');
            // Push escaped string
            row.push('"' + final + '"');
        } else if (Array.isArray(fieldValue)) {
            row.push('"' + fieldValue.join(',') + '"');
        } else {
            row.push(fieldValue);
        }
    });
    return row;
}

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import MenuMaterial from '@mui/material/Menu';
import MenuItemMaterial from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import selector from './selector';
import TARGET_OPTIONS, { TARGET_OPTIONS_MAPPING } from './target-mapping';
import TacticForm from './modules/tactic/form';
import {
    OrCard,
    OrCardHeader,
    OrCardBody,
    OrCardFooter,
    AndCard,
    AndCardHeader,
    AndCardBody,
    AndDivider,
    OrDivider,
    AndOrButton,
} from 'widgets-v5/and-or-list';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

import { getAudienceFeeFromTactics } from 'states/resources/ads/business-logic';
import AudiencesForm from './modules/audiences/form';
import { formatNumber_currency } from 'utils/formatting';

const styles = theme => ({
    dialogContent: {
        height: 600,
    },
    dialogMaxWidth: {
        maxWidth: '1450px',
    },
    dialogTitle: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
});

class Tactics extends React.Component {
    static propTypes = {
        tactics_generators: PropTypes.array,
        showErrors: PropTypes.bool,
        isAppStoreCatEnabled: PropTypes.bool,
        adId: PropTypes.string,
        campaignId: PropTypes.string,
        shouldHideFlexSegments: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEditingTactic: {},
            isModalOpen: false,
            newAudienceSegmentTargetingDraft: {},
            tacticIndexToEdit: null,
        };
    }

    getTargetingOptions = () => {
        const {
            isAppStoreCatEnabled,
            platform,
            shouldHideFlexSegments,
            platforms,
            isCrossPlatformCampaign,
            isCTVCampaign,
            isDOOHCampaign,
        } = this.props;

        let options = TARGET_OPTIONS;

        options = _.filter(options, option =>
            option.shouldFilter({
                isAppStoreCatEnabled,
                shouldHideFlexSegments,
                platforms,
                isCrossPlatformCampaign,
                isCTVCampaign,
                isDOOHCampaign,
            })
        );

        if (isCrossPlatformCampaign) {
            options = _.filter(
                options,
                option => _.intersection(option.platforms, platforms).length > 0
            );
        } else {
            options = _.filter(options, option => _.includes(option.platforms, platform));
        }
        return _.map(options, option => option.key);
    };

    handleAddTacticClick = tactic => {
        if (tactic === 'audience_segments') {
            this.setState({ isModalOpen: true });
        } else {
            this.addTactic(tactic);
        }
    };

    handleAddTargetClick = ({ targetOption, tacticIndex }) => {
        if (targetOption === 'audience_segments') {
            this.setState({ isModalOpen: true, tacticIndexToEdit: tacticIndex });
        } else {
            this.addTarget({ targetOption, tacticIndex });
        }
    };

    updateTargetDraft = ({ tacticIndex, targetIndex, draft, errorKey, errors }) => {
        let tacticsGenerators = [...this.props.tactics_generators];
        const tactic = tacticsGenerators[tacticIndex];

        const targeting = [...tactic.targeting];
        const target = targeting[targetIndex];

        targeting[targetIndex] = {
            ...target,
            draft,
        };

        tacticsGenerators[tacticIndex] = {
            ...tactic,
            targeting,
        };

        const audienceFee = getAudienceFeeFromTactics(tacticsGenerators);

        tacticsGenerators = clearEmptyTactics(tacticsGenerators);

        this.props.updateModuleDraft({
            draft: {
                tactics_generators: tacticsGenerators,
                audience_fee: audienceFee,
            },
            errorKey,
            errors: errors.length > 0,
        });
    };

    clearDraftErrors = errorKey => {
        this.props.updateModuleDraft({
            draft: {},
            errorKey,
            errors: null,
        });
    };

    addTactic = targetOption => {
        const { tactics_generators } = this.props;

        const tactic = {
            id: _.uniqueId(Date.now()),
            title: `Tactic ${tactics_generators.length + 1}`,
            targeting: [this.createTarget(targetOption)],
        };

        const tacticsGenerators = [...tactics_generators, tactic];

        this.props.updateModuleDraft({
            draft: {
                tactics_generators: tacticsGenerators,
            },
            errorKey: tactic.id,
            errors: null,
        });
    };

    handleSegmentSave = () => {
        const { tactics_generators } = this.props;
        const { tacticIndexToEdit } = this.state;
        if (tacticIndexToEdit === null) {
            const tactic = {
                id: _.uniqueId(Date.now()),
                title: `Tactic ${tactics_generators.length + 1}`,
                targeting: [
                    {
                        module: 'audience_segments',
                        draft: this.state.newAudienceSegmentTargetingDraft,
                        id: _.uniqueId(Date.now()),
                    },
                ],
            };

            const tacticsGenerators = [...tactics_generators, tactic];
            const audienceFee = getAudienceFeeFromTactics(tacticsGenerators);

            this.props.updateModuleDraft({
                draft: {
                    tactics_generators: tacticsGenerators,
                    audience_fee: audienceFee,
                },
                errorKey: tactic.id,
                errors: null,
            });

            this.setState({ isModalOpen: false });
        } else {
            const { tactics_generators } = this.props;

            const tactic = tactics_generators[tacticIndexToEdit];

            const tacticsGenerators = [...tactics_generators];

            const target = {
                module: 'audience_segments',
                draft: this.state.newAudienceSegmentTargetingDraft,
                id: _.uniqueId(Date.now()),
            };

            tacticsGenerators[tacticIndexToEdit] = {
                ...tactic,
                targeting: tactic.targeting.concat(target),
            };

            const audienceFee = getAudienceFeeFromTactics(tacticsGenerators);

            this.props.updateModuleDraft({
                draft: {
                    tactics_generators: tacticsGenerators,
                    audience_fee: audienceFee,
                },
                errorKey: target.id,
                errors: null,
            });

            this.setState({ isModalOpen: false, tacticIndexToEdit: null });
        }
    };

    addTarget = ({ targetOption, tacticIndex }) => {
        const { tactics_generators } = this.props;

        const tactic = tactics_generators[tacticIndex];
        const tacticsGenerators = [...tactics_generators];

        const target = this.createTarget(targetOption);
        tacticsGenerators[tacticIndex] = {
            ...tactic,
            targeting: tactic.targeting.concat(target),
        };

        this.props.updateModuleDraft({
            draft: {
                tactics_generators: tacticsGenerators,
            },
            errorKey: target.id,
            errors: null,
        });
    };

    createTarget = targetOption => {
        return {
            module: targetOption,
            draft: {},
            id: _.uniqueId(Date.now()),
        };
    };

    removeTactic = ({ tacticIndex }) => {
        const { tactics_generators } = this.props;

        const tactic = tactics_generators[tacticIndex];
        let tacticsGenerators = _.filter(tactics_generators, (t, index) => index !== tacticIndex);

        const audienceFee = getAudienceFeeFromTactics(tacticsGenerators);

        tacticsGenerators = clearEmptyTactics(tacticsGenerators);

        this.props.updateModuleDraft({
            draft: {
                tactics_generators: tacticsGenerators,
                audience_fee: audienceFee,
            },
            errorKey: tactic.id,
            errors: null,
        });
    };

    removeTarget = ({ tacticIndex, targetIndex }) => {
        const { tactics_generators } = this.props;

        const tactic = tactics_generators[tacticIndex];

        // Remove the whole tactic if there will be no more targets left
        if (tactic.targeting.length === 1) {
            this.removeTactic({ tacticIndex });
            return;
        }

        let tacticsGenerators = [...tactics_generators];
        const target = tactic.targeting[targetIndex];

        tacticsGenerators[tacticIndex] = {
            ...tactic,
            targeting: _.filter(tactic.targeting, (t, index) => index !== targetIndex),
        };

        const audienceFee = getAudienceFeeFromTactics(tacticsGenerators);

        tacticsGenerators = clearEmptyTactics(tacticsGenerators);

        this.props.updateModuleDraft({
            draft: {
                tactics_generators: tacticsGenerators,
                audience_fee: audienceFee,
            },
            errorKey: target.id,
            errors: null,
        });
    };

    handleTacticCloseclick = ({ tacticIndex }) => () => {
        this.closeTacticEdit(tacticIndex);
    };

    handleTacticDraftChange = ({ tacticIndex }) => ({ draft, errors }) => {
        const { tactics_generators } = this.props;

        const tactic = tactics_generators[tacticIndex];
        const tacticsGenerators = [...tactics_generators];

        tacticsGenerators[tacticIndex] = {
            ...tactic,
            ...draft,
        };

        this.props.updateModuleDraft({
            draft: {
                tactics_generators: tacticsGenerators,
            },
            errorKey: tactic.id,
            errors: errors.length > 0,
        });
    };

    getMenuItems = ({ availableTargetOptions }) => {
        return _.map(availableTargetOptions, targetOption => {
            let disabled = false;
            disabled = TARGET_OPTIONS_MAPPING[targetOption].shouldDisable({
                availableTargetOptions,
            });

            return {
                key: targetOption,
                disabled,
                onClick: ({ onClose, tacticIndex }) => () => {
                    if (disabled) {
                        return;
                    }

                    this.handleAddTargetClick({ targetOption, tacticIndex });
                    onClose();
                },
                title: TARGET_OPTIONS_MAPPING[targetOption].title,
            };
        });
    };

    getMaxAudienceCost = tactic => {
        let audienceFee = 0;

        _.each(tactic.targeting, target => {
            if (target.module === 'audience_segments') {
                audienceFee = Math.max(_.get(target, 'draft.audience_fee', 0), audienceFee);
            }
        });

        return audienceFee;
    };

    render() {
        const targetingOptions = this.getTargetingOptions();

        const {
            tactics_generators,
            showErrors,
            adId,
            campaignId,
            platform,
            classes,
            shouldHideFlexSegments,
            platforms,
            isCrossPlatformCampaign,
            tacticWarnings,
            isCTVCampaign,
            isDOOHCampaign,
        } = this.props;
        const initialAudienceWarningForAudienceForm = {};

        return (
            <div>
                {_.map(tactics_generators, (tactic, tacticIndex) => {
                    // Remove options that have already been selected
                    const existingTargets = _.map(tactic.targeting, target => target.module);
                    const availableTargetOptions = _.filter(targetingOptions, targetingOption => {
                        if (targetingOption === 'pois' || targetingOption === 'audience_segments') {
                            return true;
                        }
                        return !_.includes(existingTargets, targetingOption);
                    });

                    const menuItems = this.getMenuItems({ availableTargetOptions });
                    return (
                        <React.Fragment key={tactic.id}>
                            <OrCard>
                                <OrCardHeader onRemove={() => this.removeTactic({ tacticIndex })}>
                                    <TacticForm
                                        title={tactic.title}
                                        onDraftChange={this.handleTacticDraftChange({
                                            tacticIndex,
                                        })}
                                        isCTVCampaign={isCTVCampaign}
                                    />
                                </OrCardHeader>

                                {_.map(tactic.targeting, (target, targetIndex) => {
                                    const {
                                        customSubheader,
                                        title,
                                        Target,
                                    } = TARGET_OPTIONS_MAPPING[target.module];
                                    return (
                                        <React.Fragment key={target.id}>
                                            <OrCardBody>
                                                <AndCard>
                                                    <AndCardHeader
                                                        className="ef6-alignment__space-between"
                                                        onRemove={() =>
                                                            this.removeTarget({
                                                                tacticIndex,
                                                                targetIndex,
                                                            })
                                                        }
                                                    >
                                                        {title}
                                                    </AndCardHeader>
                                                    <AndCardBody>
                                                        {customSubheader}
                                                        <Target
                                                            platform={platform}
                                                            platforms={platforms}
                                                            showErrors={showErrors}
                                                            targetDraft={target.draft}
                                                            onDraftChange={({ draft, errors }) => {
                                                                this.updateTargetDraft({
                                                                    tacticIndex,
                                                                    targetIndex,
                                                                    draft,
                                                                    errorKey: target.id,
                                                                    errors,
                                                                });
                                                            }}
                                                            onUnmount={() =>
                                                                this.clearDraftErrors(target.id)
                                                            }
                                                            campaignId={campaignId}
                                                            adId={adId}
                                                            audienceRates={this.props.audienceRates}
                                                            isAppStoreCatEnabled={
                                                                this.props.isAppStoreCatEnabled
                                                            }
                                                            shouldHideFlexSegments={
                                                                shouldHideFlexSegments
                                                            }
                                                            isCrossPlatformCampaign={
                                                                isCrossPlatformCampaign
                                                            }
                                                            audienceWarning={_.get(
                                                                tacticWarnings,
                                                                `${tactic.id}.${
                                                                    target.id
                                                                }.audience_segments`,
                                                                {}
                                                            )}
                                                            isCTVCampaign={isCTVCampaign}
                                                        />
                                                    </AndCardBody>
                                                </AndCard>
                                            </OrCardBody>

                                            {targetIndex !== tactic.targeting.length - 1 && (
                                                <AndDivider />
                                            )}
                                        </React.Fragment>
                                    );
                                })}

                                <OrCardFooter className="ef6-alignment__center">
                                    {availableTargetOptions.length > 0 && (
                                        <TargetingsMenu
                                            menuItems={menuItems}
                                            tacticIndex={tacticIndex}
                                            isCTVCampaign={isCTVCampaign}
                                        />
                                    )}
                                    <div>
                                        <Typography variant="h6">Audiences</Typography>
                                    </div>
                                    <div>
                                        Data Cost:{' '}
                                        {formatNumber_currency(this.getMaxAudienceCost(tactic))}
                                        CPM
                                    </div>
                                </OrCardFooter>
                            </OrCard>

                            {tactics_generators.length > 0 && (
                                <OrDivider className="ef6-alignment__center" />
                            )}
                        </React.Fragment>
                    );
                })}

                <AndOrButton>
                    <TacticsMenu menuItems={targetingOptions} onClick={this.handleAddTacticClick} />
                </AndOrButton>
                <Dialog
                    onClose={() => {
                        this.setState({ isModalOpen: false });
                    }}
                    open={this.state.isModalOpen}
                    maxWidth="lg"
                    scroll="body"
                    fullWidth
                    classes={{
                        paperWidthLg: classes.dialogMaxWidth,
                    }}
                >
                    <DialogTitle className={classes.dialogTitle}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <div>
                                <Typography variant="h6">Audiences</Typography>
                            </div>
                            <div>
                                <IconButton
                                    onClick={() => {
                                        this.setState({ isModalOpen: false });
                                    }}
                                    size="large"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <AudiencesForm
                            audiences={[]}
                            audience_exclude={[]}
                            geo_targeting_settings={{
                                category_layers: [],
                                categories: [],
                                custom_layers: [],
                                target: true,
                                retarget: true,
                                days_to_retarget: 21,
                                lookbackMap: {},
                                targetingSettings: {},
                            }}
                            campaignId={campaignId}
                            audience_fee={0}
                            audienceRates={this.props.audienceRates}
                            showErrors={false}
                            onDraftChange={({ draft }) => {
                                this.setState({
                                    newAudienceSegmentTargetingDraft: draft,
                                });
                            }}
                            shouldHideFlexSegments={shouldHideFlexSegments}
                            platforms={platforms}
                            platform={platform}
                            isCrossPlatformCampaign={isCrossPlatformCampaign}
                            audienceWarning={initialAudienceWarningForAudienceForm}
                            isCTVCampaign={isCTVCampaign}
                            isDOOHCampaign={isDOOHCampaign}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.handleSegmentSave()}
                            color="primary"
                            variant="contained"
                        >
                            Apply
                        </Button>
                    </DialogActions>
                </Dialog>

                {tacticWarnings && tacticWarnings.hasWarning > 0 && (
                    <Box marginTop={1} marginLeft={4}>
                        <WarningText
                            message={tacticWarnings.message}
                            severity={tacticWarnings.severity}
                        />
                    </Box>
                )}
            </div>
        );
    }
}

function TacticsMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button color="primary" onClick={handleClick}>
                ADD TACTIC
            </Button>
            <MenuMaterial
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                {_.map(props.menuItems, menuItem => (
                    <MenuItemMaterial
                        key={menuItem}
                        onClick={() => {
                            props.onClick(menuItem);
                            onClose();
                        }}
                    >
                        {TARGET_OPTIONS_MAPPING[menuItem].title}
                    </MenuItemMaterial>
                ))}
            </MenuMaterial>
        </div>
    );
}

function TargetingsMenu({ menuItems, tacticIndex }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button color="primary" onClick={handleClick}>
                ADD TARGETING
            </Button>
            <MenuMaterial
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={onClose}
            >
                {_.map(menuItems, menuItem => (
                    <MenuItemMaterial
                        key={menuItem.key}
                        onClick={menuItem.onClick({
                            onClose,
                            tacticIndex,
                        })}
                    >
                        {menuItem.title}
                    </MenuItemMaterial>
                ))}
            </MenuMaterial>
        </div>
    );
}

function clearEmptyTactics(tacticsGenerators) {
    return _(tacticsGenerators)
        .map(tactic => {
            const targeting = _.filter(tactic.targeting, targeting => {
                if (targeting.module === 'audience_segments') {
                    if (
                        _.concat(targeting.draft.audiences, targeting.draft.audience_exclude)
                            .length === 0 &&
                        (targeting.draft.geo_targeting_settings.categories.length === 0 &&
                            targeting.draft.geo_targeting_settings.category_layers.length === 0 &&
                            targeting.draft.geo_targeting_settings.custom_layers.length === 0)
                    ) {
                        return false;
                    }
                    return true;
                }

                return true;
            });
            return {
                ...tactic,
                targeting,
            };
        })
        .filter(tactic => {
            if (tactic.targeting.length === 0) {
                return false;
            }
            return true;
        })
        .value();
}

export default withStyles(styles)(connect(selector)(Tactics));

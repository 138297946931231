import React, { useState } from 'react';
import numeral from 'numeral';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import BlockIcon from '@mui/icons-material/Block';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Popover from '@mui/material/Popover';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';

import { formatNumber_currency } from 'utils/formatting';

const getTierIcon = tier => {
    let tierIcon;

    if (tier === 'Top Tier Spender' || tier === 'Top Spending Geography') {
        tierIcon = 'fa fa-trophy';
    } else if (tier === 'Frequent Transactor' || tier === 'Frequently Transacting Geography') {
        tierIcon = 'fa fa-credit-card';
    } else if (tier === 'In Market' || tier === 'In Market Geography') {
        tierIcon = 'fa fa-shopping-cart';
    } else if (tier === 'Big Ticket Shopper') {
        tierIcon = 'fa fa-ticket';
    }

    return tierIcon;
};

const sectionStyles = makeStyles(theme => ({
    paperContainer: {
        padding: theme.spacing(1.75),
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    paperHeader: {
        marginBottom: theme.spacing(1),
    },
    itemDetailsContent: {
        marginBottom: theme.spacing(2),
    },
    content: {
        position: 'relative',
        paddingLeft: theme.spacing(3.25),
        maxHeight: '35%',
    },
    orContainer: {
        position: 'relative',
        paddingLeft: theme.spacing(4),
        backgroundImage: `linear-gradient(${theme.palette.grey[400]} 45%, rgba(255,255,255,0) 0%)`,
        backgroundPosition: 'left',
        backgroundSize: '2px 15px',
        backgroundRepeat: 'repeat-y',
        height: '35%',
    },
    or: {
        width: '40px',
        borderRadius: '6px',
        position: 'absolute',
        left: '-20px',
        top: `50%`,
    },
    itemDetailsContainer: props => ({
        maxHeight: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            width: '7px',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0, 0, 0, .5)',
            '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
            display: props.items.length > 1 ? 'block' : 'none',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#F5F5F5',
        },
        '& > :last-child': {
            marginBottom: '0',
        },
    }),
    chip: {
        padding: theme.spacing(1),
        marginTop: '0.35em',
    },
}));

const Summary = ({
    includedItems,
    excludedItems,
    audienceRatesMap,
    restrictedSegments,
    geo_targeting_settings,
    removeFlexSegment,
    hideGeoTargetingEditor = false,
    ...props
}) => {
    return (
        <div>
            {includedItems.length > 0 && (
                <div>
                    <SummarySection
                        include={true}
                        items={includedItems}
                        audienceRatesMap={audienceRatesMap}
                        removeItem={props.removeIncludedItem}
                        removeAll={props.removeAllIncluded}
                        restrictedSegments={restrictedSegments}
                        geo_targeting_settings={geo_targeting_settings}
                        removeFlexSegment={removeFlexSegment}
                        changeLookbackWindow={props.changeLookbackWindow}
                        handleChangeTargetingSetting={props.handleChangeTargetingSetting}
                        hideGeoTargetingEditor={hideGeoTargetingEditor}
                    />
                </div>
            )}
            {excludedItems.length > 0 && (
                <div>
                    <SummarySection
                        items={excludedItems}
                        audienceRatesMap={audienceRatesMap}
                        removeItem={props.removeExcludedItem}
                        removeAll={props.removeAllExcluded}
                        restrictedSegments={restrictedSegments}
                        excluded
                        geo_targeting_settings={geo_targeting_settings}
                    />
                </div>
            )}
            {props.footerLabel && (
                <div>
                    <Typography>
                        {props.footerLabel({
                            includedItems: includedItems,
                            excludedItems: excludedItems,
                        })}
                    </Typography>
                    <Typography>
                        {props.thirdPartyFees({
                            includedItems: includedItems,
                            excludedItems: excludedItems,
                        })}
                    </Typography>
                </div>
            )}
        </div>
    );
};

const SummarySection = ({
    include,
    items,
    audienceRatesMap,
    removeItem,
    removeAll,
    restrictedSegments,
    excluded,
    geo_targeting_settings,
    removeFlexSegment,
    changeLookbackWindow,
    shouldHideFlexSegments,
    handleChangeTargetingSetting,
    hideGeoTargetingEditor,
}) => {
    const classes = sectionStyles({ items });
    const someItemsRestricted = _.some(items, item =>
        _.includes(restrictedSegments, item.source_type) || item.deprecated
    );

    const flexSourceTypes = ['flex_standard_brand', 'flex_location_category', 'flex_custom_brand'];
    let lookbackMap = {};
    let targetingSettings = {};
    if (!shouldHideFlexSegments) {
        targetingSettings = { ..._.get(geo_targeting_settings, 'targetingSettings', {}) };
        lookbackMap = { ..._.get(geo_targeting_settings, 'lookbackMap') };
        _.forEach(items, item => {
            if (item.hasTimeConstraint) {
                lookbackMap[item.id] = -1;
            }

            if (item.id && targetingSettings[item.id]) {
                targetingSettings[item.id].targetDisabled = false;
                targetingSettings[item.id].retargetDisabled = false;

                if (item.hasTimeConstraint) {
                    targetingSettings[item.id].target = false;
                    targetingSettings[item.id].targetDisabled = true;
                }
            }
        });
    }
    return (
        <Paper elevation={0} className={classes.paperContainer}>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.paperHeader}
            >
                <Grid item xs={4}>
                    <Grid container alignItems="center">
                        {include ? (
                            <React.Fragment>
                                <CheckIcon color="primary" />
                                <Typography className={classes.title}>
                                    {`Included (${items.length})`}
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <BlockIcon color="error" />
                                <Typography className={classes.title}>
                                    {`Excluded (${items.length})`}
                                </Typography>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    {someItemsRestricted ? (
                        <Tooltip
                            title={
                                <Typography variant="body2">
                                    Some segments will not be available after being removed
                                </Typography>
                            }
                        >
                            <IconButton onClick={removeAll} size="large">
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <IconButton onClick={removeAll} size="large">
                            <ClearIcon />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                className={!excluded && items.length > 1 ? classes.orContainer : classes.content}
            >
                {!excluded && items.length > 1 && (
                    <Chip label="OR" className={classes.or} size="small" />
                )}
                <Grid
                    container
                    justifyContent="space-between"
                    className={classes.itemDetailsContainer}
                >
                    {_.map(items, item => {
                        const targetingSetting = targetingSettings[item.id] || {
                            target: geo_targeting_settings.target,
                            retarget: geo_targeting_settings.retarget,
                        };
                        return (
                            <Grid
                                key={item.id}
                                container
                                className={classes.itemDetailsContent}
                                justifyContent="space-between"
                            >
                                <Grid item xs={8}>
                                    <Typography gutterBottom variant="subtitle1">
                                        {item.name}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        {item.source_type_category}
                                    </Typography>
                                    {_.includes(flexSourceTypes, item.source_type) && (
                                        <Grid container direction="row">
                                            <Grid item>
                                                <TargetingOptionEditor
                                                    onApply={({ newTargetingSetting }) =>
                                                        handleChangeTargetingSetting({
                                                            id: item.id,
                                                            newTargetingSetting,
                                                        })
                                                    }
                                                    targetingSetting={targetingSetting}
                                                    hideGeoTargetingEditor={hideGeoTargetingEditor}
                                                />
                                            </Grid>

                                            {!item.hasTimeConstraint && targetingSetting.retarget && (
                                                <Grid item>
                                                    <LookbackWindowEditor
                                                        value={lookbackMap[item.id]}
                                                        onSave={({ newLookbackValue }) =>
                                                            changeLookbackWindow({
                                                                id: item.id,
                                                                newLookbackValue,
                                                            })
                                                        }
                                                        hideGeoTargetingEditor={
                                                            hideGeoTargetingEditor
                                                        }
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                    {item.source_type === 'pds_mastercard' && (
                                        <Chip
                                            label={item.tier}
                                            size="small"
                                            className={classes.chip}
                                            icon={
                                                <Icon
                                                    fontSize="small"
                                                    className={getTierIcon(item.tier)}
                                                />
                                            }
                                            variant="outlined"
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>
                                        {formatNumber_currency(audienceRatesMap[item.source_type])}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    {_.includes(restrictedSegments, item.source_type) || item.deprecated ? (
                                        <Tooltip
                                            title={
                                                <Typography variant="body2">
                                                    Once you remove this segment you cannot add it
                                                    back
                                                </Typography>
                                            }
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    return _.includes(
                                                        flexSourceTypes,
                                                        item.source_type
                                                    )
                                                        ? removeFlexSegment({
                                                              id: item.id,
                                                              source_type: item.source_type,
                                                          })
                                                        : removeItem(item.value);
                                                }}
                                                size="large"
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <IconButton
                                            onClick={() => {
                                                return _.includes(flexSourceTypes, item.source_type)
                                                    ? removeFlexSegment({
                                                          id: item.id,
                                                          source_type: item.source_type,
                                                      })
                                                    : removeItem(item.value);
                                            }}
                                            size="large"
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textPrimary">
                                        Third-Party Fee $
                                        {formatSpendValueToString(item.third_party_fees)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Paper>
    );
};

const editorStyles = makeStyles(theme => ({
    paperRoot: {
        padding: theme.spacing(1.5),
    },
}));

const TargetingOptionEditor = ({ targetingSetting, onApply, hideGeoTargetingEditor }) => {
    const classes = editorStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [targetingSettingsDraft, setTargetingSettingsDraft] = useState(targetingSetting);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleChange = ({ checked, field }) => {
        setTargetingSettingsDraft({ ...targetingSettingsDraft, [field]: checked });
    };

    const handleApply = () => {
        onApply({ newTargetingSetting: targetingSettingsDraft });
        handleClose();
    };

    const targetingModeSummary = [];

    if (targetingSetting.target) {
        targetingModeSummary.push('Real-time Targeting');
    }

    if (targetingSetting.retarget) {
        targetingModeSummary.push('Retargeting');
    }

    return (
        <Grid container alignItems="center">
            <Grid item>
                <Typography color="textSecondary" variant="body2">{`Mode: ${_.join(
                    targetingModeSummary,
                    ', '
                )}`}</Typography>
            </Grid>
            {!hideGeoTargetingEditor && (
                <Grid item>
                    <IconButton onClick={handleClick} size="small">
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <Popover
                        classes={{ paper: classes.paperRoot }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <IconButton size="small" onClick={handleClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Box mb={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={targetingSettingsDraft.target}
                                            color="primary"
                                            onChange={e =>
                                                handleChange({
                                                    checked: e.target.checked,
                                                    field: 'target',
                                                })
                                            }
                                            disabled={
                                                targetingSettingsDraft.targetDisabled ||
                                                !targetingSettingsDraft.retarget
                                            }
                                        />
                                    }
                                    label="Real-time Targeting"
                                />
                                {targetingSettingsDraft.targetDisabled ? (
                                    <FormHelperText>
                                        Note: Real-time Targeting cannot be used when a Date Range
                                        is set on the Flex Geolocation audience
                                    </FormHelperText>
                                ) : null}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={targetingSettingsDraft.retarget}
                                            color="primary"
                                            onChange={e =>
                                                handleChange({
                                                    checked: e.target.checked,
                                                    field: 'retarget',
                                                })
                                            }
                                            disabled={
                                                targetingSettingsDraft.retargetDisabled ||
                                                !targetingSettingsDraft.target
                                            }
                                        />
                                    }
                                    label="Retargeting"
                                />
                            </FormGroup>
                        </Box>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button variant="outlined" color="primary" onClick={handleApply}>
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            )}
        </Grid>
    );
};

const LookbackWindowEditor = ({ value, onSave, hideGeoTargetingEditor }) => {
    const classes = editorStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [lookbackWindowDraft, setLookbackWindowDraft] = useState(value);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleChange = value => {
        setLookbackWindowDraft(value);
    };

    const handleSave = () => {
        onSave({ newLookbackValue: lookbackWindowDraft });
        handleClose();
    };
    return (
        <Grid container alignItems="center">
            <Grid item>
                <Typography
                    color="textSecondary"
                    variant="body2"
                >{`Lookback Window: ${value} days`}</Typography>
            </Grid>
            {!hideGeoTargetingEditor && (
                <Grid item>
                    <IconButton onClick={handleClick} size="small">
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <Popover
                        classes={{ paper: classes.paperRoot }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <IconButton size="small" onClick={handleClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <div>
                            <TextFieldFormatter
                                initialValue={lookbackWindowDraft}
                                onChange={handleChange}
                                shouldAllowChange={v => {
                                    return !/[a-zA-Z.]/.test(v) && v <= 365;
                                }}
                                formatIn={v => {
                                    if (v === 0) {
                                        return '';
                                    }
                                    return numeral(v).format('0,0');
                                }}
                                formatOut={v => {
                                    return v ? numeral(v).value() : 0;
                                }}
                                renderInput={({ value, onChange }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label="Lookback Window"
                                        fullWidth
                                        helperText="Max. 365"
                                        endAdornment="days"
                                    />
                                )}
                            />
                        </div>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button variant="outlined" color="primary" onClick={handleSave}>
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            )}
        </Grid>
    );
};

export default Summary;

export function formatSpendValueToString(value) {
    if (!value) {
        return '0.00';
    } else {
        const [dollars, cents] = value.toFixed(2).split('.');
        const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
        return `${formattedDollars}.${cents}`;
    }
}

import React from 'react';
import _ from 'lodash';

import 'moment-timezone';
import IAB_CATEGORIES from 'common/constants/iab-categories';
import { hasTopLevelFieldsBeenModified } from 'widgets/history-table';

import { formatNumber_percentage, formatNumber_currency } from 'utils/formatting';

import { formatCurrency } from 'common/fns';
import { isInternalUser } from 'states/profile/business-rules';
import { CampaignAutoBudgetAllocationOptions, RevenueModelMapping } from '../../../states/resources/campaigns/business-logic';

function getFields({ storeState, campaign, ftaLocationListsMapping }) {
    const fields = [].concat(
        [
            {
                label: 'Basic',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'name',
                        'default_timezone',
                        'notes',
                        'custom_fields',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Name',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['name']);
                },
                format(entry) {
                    return entry.snapshot.name;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Type',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['type']);
                },
                format(entry) {
                    return entry.snapshot.type;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Status',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['paused']);
                },
                format(entry) {
                    return entry.snapshot.paused ? 'Off' : 'On';
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
        ],
        _.map(campaign.custom_fields, (campaignCustomField, customFieldIndex) => {
            return {
                label: campaignCustomField.name,
                isCategory: false,
                hasChange(entry) {
                    let hasChange;
                    const isAdd = _.get(entry, 'patch.custom_fields.op') === 'add';
                    if (isAdd) {
                        hasChange = true;
                    } else {
                        const indexesOfChangedCustomFields = _(entry.patch)
                            .map((value, key) => key)
                            .filter(key => {
                                if (key.indexOf('custom_fields') > -1) {
                                    return true;
                                } else {
                                    return false;
                                }
                            })
                            .map(key => String(key.split('.')[1]))
                            .value();

                        hasChange = _.includes(
                            indexesOfChangedCustomFields,
                            String(customFieldIndex)
                        );
                    }
                    return hasChange;
                },
                format(entry) {
                    const customField = _.find(entry.snapshot.custom_fields, {
                        name: campaignCustomField.name,
                    });

                    return customField ? customField.value : '';
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            };
        }),
        [
            {
                label: 'Default Timezone',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['default_timezone']);
                },
                format(entry) {
                    return entry.snapshot.default_timezone;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Notes',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['notes']);
                },
                format(entry) {
                    return entry.snapshot.notes;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Categorization',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'clients',
                        'advertiser_domain',
                        'iab_categories',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Partners',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['clients']);
                },
                format(entry) {
                    const organizations = _.get(storeState, 'campaignHistory.organizations');
                    const clientsIds = _(entry.snapshot.clients)
                        .map(client => {
                            return {
                                id: client.organization,
                                type: client.type,
                            };
                        })
                        .value();
                    let clientNames;
                    clientNames = _(clientsIds).map(item =>
                        _.get(
                            _.find(
                                organizations,
                                organization =>
                                    organization.id === item.id && 'partner' === item.type
                            ),
                            'name'
                        )
                    );

                    return clientNames;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Advertisers',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['clients']);
                },
                format(entry) {
                    const organizations = _.get(storeState, 'campaignHistory.organizations');
                    const clientsIds = _(entry.snapshot.clients)
                        .map(client => {
                            return {
                                id: client.organization,
                                type: client.type,
                            };
                        })
                        .value();
                    let clientNames;
                    clientNames = _(clientsIds).map(item =>
                        _.get(
                            _.find(
                                organizations,
                                organization =>
                                    organization.id === item.id && 'advertiser' === item.type
                            ),
                            'name'
                        )
                    );

                    return clientNames;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Advertiser Domain',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['advertiser_domain']);
                },
                format(entry) {
                    return entry.snapshot.advertiser_domain;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Advertiser Category',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['iab_categories']);
                },
                format(entry) {
                    const iabCategories = _(entry.snapshot.iab_categories)
                        .map(category => IAB_CATEGORIES[category])
                        .join(', ');

                    return iabCategories;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Sales Reps',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['clients']);
                },
                format(entry) {
                    const organizations = _.get(storeState, 'campaignHistory.organizations');
                    const clientsIds = _(entry.snapshot.clients)
                        .map(client => {
                            return {
                                id: client.organization,
                                type: client.type,
                            };
                        })
                        .value();
                    let clientNames;
                    clientNames = _(clientsIds).map(item =>
                        _.get(
                            _.find(
                                organizations,
                                organization =>
                                    organization.id === item.id && 'sales_rep' === item.type
                            ),
                            'name'
                        )
                    );

                    return clientNames;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Budget & Billing',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'currency',
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                        'third_party_fees',
                        'budget_allocation_method',
                        'campaign_max_total_impressions',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Currency',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['currency']);
                },
                format(entry) {
                    return entry.snapshot.currency;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Revenue Model',
                isCategory: false,
                hasChange(entry) {
                    const intersection = _.intersection(_.keys(entry.patch), ['revenueModel']);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;
                    let value = [];
                    if (snapshot.revenueModel === RevenueModelMapping.Disabled) {
                        value = [{ label: 'Disabled' }];
                    } else if (snapshot.revenueModel === RevenueModelMapping.CPMCPC) {
                        value = [{ label: 'CPM / CPC' }];
                    } else if (snapshot.revenueModel === RevenueModelMapping.CPM) {
                        value = [{ label: 'CPM' }];
                    } else if (snapshot.revenueModel === RevenueModelMapping.AgencyMargin) {
                        value = [{ label: 'Agency margin' }];
                    } else if (snapshot.revenueModel === RevenueModelMapping.TotalSpendMarkup) {
                        value = [{ label: 'Total cost markup' }];
                    }

                    return (
                        <div>
                            {_.map(value, item => (
                                <div key={item.label}>
                                    <span>{item.label}</span>
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            {
                label: 'Total Cost Budget',
                isCategory: false,
                hasChange(entry) {
                    const intersection = _.intersection(_.keys(entry.patch), [
                        'billing_rate',
                        'billing_term',
                        'billing_enabled',
                        'campaign_budget_enabled',
                        'campaign_max_total_spend_local',
                    ]);

                    return intersection.length > 0;
                },
                format(entry) {
                    const { snapshot } = entry;
                    let value = [];
                    const usesMaxTotalSpendLocal = _.includes(
                        [
                            RevenueModelMapping.Disabled,
                            RevenueModelMapping.CPMCPC,
                            RevenueModelMapping.CPM,
                            RevenueModelMapping.TotalSpendMarkup,
                        ],
                        snapshot.revenueModel
                    );

                    if (usesMaxTotalSpendLocal) {
                        const totalCostBudget =
                            snapshot.campaign_max_total_spend_local === 0
                                ? 'Unlimited'
                                : formatNumber_currency(snapshot.campaign_max_total_spend_local);
                        value = [
                            {
                                label: `${totalCostBudget}`,
                            },
                        ];
                    } else if (snapshot.revenueModel === 'agencyMargin') {
                        value = [
                            {
                                label: `Agency Margin: ${formatNumber_percentage(
                                    snapshot.billing_rate
                                )}`,
                            },
                            {
                                label: `Revenue Budget: ${formatNumber_currency(
                                    snapshot.total_billings_local
                                )}`,
                            },
                        ];
                    }

                    return (
                        <div>
                            {_.map(value, item => (
                                <div key={item.label}>
                                    <span>{item.label}</span>
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            {
                label: 'Default Third Party Fees',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['third_party_fees']);
                },
                format(entry) {
                    return (
                        <div>
                            {_.map(entry.snapshot.third_party_fees, v => (
                                <div key={v.description}>
                                    {`${v.description}, ${formatCurrency(
                                        v.fee
                                    )} ${v.billing_model.toUpperCase()}`}
                                </div>
                            ))}
                        </div>
                    );
                },
                getClipboardText(entry) {
                    return _.map(
                        entry.snapshot.third_party_fees,
                        v =>
                            `${v.description}, ${formatCurrency(
                                v.fee
                            )} ${v.billing_model.toUpperCase()}`
                    ).join('\n');
                },
            },
            {
                label: 'Reference ID',
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['reference_id']);
                },
                format(entry) {
                    return entry.snapshot.reference_id;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
            {
                label: 'Restricted Content',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['restricted_category']);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Restricted Category',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, ['restricted_category']);
                },
                format(entry) {
                    if (entry.snapshot.restricted_category === 'cannabis') {
                        return 'Cannabis';
                    }

                    return entry.snapshot.restricted_category;
                },
                getClipboardText(entry) {
                    return this.format(entry);
                },
            },
        ]
    );
    if (campaign.revenueModel === 'agencyMargin' || campaign.revenueModel === 'disabled') {
        fields.push({
            label: 'Budget Allocation Method',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, [
                    'budget_allocation_method',
                    'automaticBudgetAllocationOptimizationStrategy',
                ]);
            },
            format(entry) {
                const { snapshot } = entry;

                if (snapshot.budget_allocation_method === 'manual') {
                    return 'Manual';
                }

                let autoStrategyLabel = 'Automatic';
                if (snapshot.automaticBudgetAllocationOptimizationStrategy === CampaignAutoBudgetAllocationOptions.CPC) {
                    autoStrategyLabel += '(Optimize for CPC)';
                } else if (snapshot.automaticBudgetAllocationOptimizationStrategy === CampaignAutoBudgetAllocationOptions.CPA) {
                    autoStrategyLabel += '(Optimize for CPA)';
                } else if (snapshot.automaticBudgetAllocationOptimizationStrategy === CampaignAutoBudgetAllocationOptions.CPCV) {
                    autoStrategyLabel += '(Optimize for CPCV)';
                }

                return autoStrategyLabel;
            },
            getClipboardText(entry) {
                this.format(entry);
            },
        });
    }
    if (
        [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(campaign.revenueModel) &&
        campaign.budget_allocation_method === 'auto'
    ) {
        fields.push({
            label: 'Total Impressions',
            isCategory: false,
            hasChange(entry) {
                return hasTopLevelFieldsBeenModified(entry.patch, [
                    'campaign_max_total_impressions',
                ]);
            },
            format(entry) {
                return entry.snapshot.campaign_max_total_impressions;
            },
            getClipboardText(entry) {
                return this.format(entry);
            },
        });
    }

    if (!isInternalUser()) {
        fields.push(
            {
                label: 'FTA',
                isCategory: true,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'fta_enabled',
                        'fta_management_level',
                        'fta_location_list',
                    ]);
                },
                format() {
                    return '';
                },
            },
            {
                label: 'Foot Traffic Attribution',
                isCategory: false,
                hasChange(entry) {
                    return hasTopLevelFieldsBeenModified(entry.patch, [
                        'fta_enabled',
                        'fta_management_level',
                        'fta_location_list',
                    ]);
                },
                format(entry) {
                    const { snapshot } = entry;
                    return (
                        <table>
                            <tbody>
                                <tr>
                                    <td>{snapshot.fta_enabled ? 'Enabled' : 'Disabled'}</td>
                                </tr>
                                {snapshot.fta_enabled && (
                                    <React.Fragment>
                                        <tr>
                                            <td>Level:</td>
                                            <td>
                                                {snapshot.fta_management_level === 'campaign'
                                                    ? 'Campaign'
                                                    : 'Ad'}
                                            </td>
                                        </tr>
                                        {snapshot.fta_management_level === 'campaign' && (
                                            <tr>
                                                <td>Location List:</td>
                                                <td>
                                                    {
                                                        ftaLocationListsMapping[
                                                            snapshot.fta_location_list
                                                        ]
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )}
                            </tbody>
                        </table>
                    );
                },
                getClipboardText(entry) {
                    return JSON.stringify(
                        _.pick(entry.snapshot, [
                            'fta_enabled',
                            'fta_management_level',
                            'fta_location_list',
                        ])
                    );
                },
            }
        );
    }

    return fields;
}

export default function selector(storeState, props) {
    const campaignId = props.params.campaignId;
    const historyData = _.get(storeState, `resources.history.campaigns.${campaignId}`, []);
    const campaignAttr = _.get(storeState, `resources.campaigns.${campaignId}.attr`, {});

    const ftaLocationLists = _.get(storeState, 'campaignHistory.ftaLocationLists');

    const ftaLocationListsMapping = {};
    _.each(ftaLocationLists, locationList => {
        ftaLocationListsMapping[locationList.id] = locationList.name;
    });

    // to prevent duplicated consecutive history fields(only for valid field values);

    const fields = getFields({
        storeState,
        campaign: campaignAttr,
        ftaLocationListsMapping,
    });

    const filteredHistoryData = _(historyData)
        .filter((entry, index) => {
            if (index === 0) {
                return true;
            }

            const currenyHistoryValue = JSON.stringify(_.omit(entry.snapshot, ['_etag']));
            const prevHistoryValue = JSON.stringify(
                _.omit(historyData[index - 1].snapshot, ['_etag'])
            );

            return currenyHistoryValue !== prevHistoryValue;
        })
        .filter(entry => {
            const allChanges = _.map(fields, field => {
                return !!field.hasChange(entry);
            });

            const hasNoChanges = _.every(allChanges, change => change === false);

            return !hasNoChanges;
        })
        .value();

    const out = {
        isLoading: _.get(storeState, `campaignHistory.isLoading`, true),
        campaign: campaignAttr,
        fields,
        historyEntries: filteredHistoryData,
        organizationTimezone: storeState.profile.organizationTimezone,
    };
    return out;
}

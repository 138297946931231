import flags from 'containers/flags/service';

export const defaultValues = {
    name: '',
    source_type: 'campaign',
    source_id: [],
    mode: 'retargeting',
    retargeting_type: 'impression',
    event_name: undefined,
    event_name_list: [],
    description: undefined,
    formData: undefined,
    upload_origin: undefined,
    supports: ['inapp'],
    frequency: 1,
};

export const defaultsByMode = {
    upload: {},
};

import _ from 'lodash';
import toastr from 'toastr';

import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
import Bacon from 'baconjs';
import { can } from 'services/can';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import AppLists from 'states/resources/app-lists/actions';
import { fetchReportStats } from 'services/resources/stats';
import { fetchOrganizationTimezone } from '../shared';
import { getEnvironmentSettings } from 'services/environment';
import { actions as ExploreActions } from '../report-explore/v2/redux';
import { TIMEOUT_NOTIF_CONFIG, TIMEOUT_IDS } from '../constants';
import { PermissionsMapping } from 'common/constants/role-intents';

const http = createHttp();
const stream = new Bacon.Bus();

stream
    // .debounce(100)
    .flatMapLatest(({ campaignId, getState, done }) => {
        // const campaignId = _.get(getState(), 'reportChannel.campaignId');

        // if (!campaignId) {
        //     return Promise.resolve();
        // }

        const limit = _.get(getState(), `reportChannel.pivotTable.limit`, 50);
        const splits = _.get(getState(), `reportChannel.pivotTable.pivotTable.splits`, []);
        const split = _.map(splits, s => s.name);
        const scopeFilter = _.get(getState(), `reportChannel.scopeFilter`, {});
        const dateRange = _.get(getState(), `reportChannel.dateRange`, {});

        let dateRangePayload = {
            ..._.omit(dateRange, ['cache', 'cacheKey', 'selectedKey']),
        };

        if (
            dateRange.timeframe === 'campaign_to_date' &&
            getEnvironmentSettings().getReportingDatasource() !== 'snowflake'
        ) {
            // omit start/end or remove Z offset
            dateRangePayload = _.omit(dateRangePayload, ['start', 'end']);
        }

        const fetchData = () => {
            let match = _.omit(scopeFilter, ['cache', 'cacheKey', 'campaignStart', 'campaignEnd']);

            const { viewBy } = getState().campaignPageContainer;
            if (viewBy && viewBy !== 'campaign') {
                match.flight_id = [viewBy];
            }

            const promise = fetchReportStats({
                campaign_id: campaignId,
                limit,
                ...dateRangePayload,
                match,
                split,
                attributes: ['channel_type', 'channel_name'],
                type: 'ads_app_site_bundle_domain_name',
                datasource: getEnvironmentSettings().getReportingDatasource(),
                isReportPage: true,
                queryTag: 'ef_report_channel',
            }).then(stats => {
                return { stats, done };
            });

            return Bacon.fromPromise(promise, true);
        };

        return Bacon.retry({
            source: fetchData,
            retries: 2,
            delay() {
                return 1000;
            },
        });
    })
    .onValue(({ stats, done }) => {
        done(stats);
    });

export const fetchReportData = campaignId => (dispatch, getState) => {
    fetchComponentData(campaignId, dispatch, getState);
};

const actions = {
    initialize(campaignId) {
        return (dispatch, getState) => {
            const orgId = _.get(getState(), 'profile.organizationId');

            if (orgId && can(PermissionsMapping.CAMPAIGN__VIEW)) {
                dispatch(AppLists.getAll(orgId));
            }

            fetchCampaign(campaignId)(dispatch, getState).then(campaign => {
                const userOrganization = _.get(getState(), 'profile.organizationId');
                const campaignOwner = campaign.owner;

                const isClient = userOrganization !== campaignOwner;

                dispatch({
                    type: c.REPORT_CHANNEL__PIVOT_TABLE__INITIALIZE_START,
                    payload: {
                        campaignId,
                        isClient,
                        campaign,
                    },
                });

                const done = stats => {
                    dispatch(ExploreActions.removeTimeouts(TIMEOUT_IDS));
                    toastr.clear();
                    dispatch({
                        type: c.REPORT_CHANNEL__PIVOT_TABLE__INITIALIZE,
                        payload: {
                            stats,
                            campaign,
                            campaignId,
                            isClient,
                        },
                    });
                };

                dispatch(this.fetchStats(campaignId, done));
            });
        };
    },
    sortColumn(campaignId, column) {
        return {
            type: c.REPORT_CHANNEL__PIVOT_TABLE__SORT,
            payload: {
                column,
                campaignId,
            },
        };
    },
    expandRow(campaignId, rowId) {
        return {
            type: c.REPORT_CHANNEL__PIVOT_TABLE__EXPAND_ROW,
            payload: {
                rowId,
                campaignId,
            },
        };
    },
    collapseRow(campaignId, rowId) {
        return {
            type: c.REPORT_CHANNEL__PIVOT_TABLE__COLLAPSE_ROW,
            payload: {
                rowId,
                campaignId,
            },
        };
    },
    addSplit(campaignId, dimension) {
        return dispatch => {
            dispatch({
                type: c.REPORT_CHANNEL__PIVOT_TABLE__ADD_SPLIT,
                payload: {
                    dimension,
                    campaignId,
                },
            });

            return dispatch(this.refreshStats(campaignId));
        };
    },
    removeSplit(campaignId, dimension) {
        return dispatch => {
            dispatch({
                type: c.REPORT_CHANNEL__PIVOT_TABLE__REMOVE_SPLIT,
                payload: {
                    dimension,
                    campaignId,
                },
            });

            return dispatch(this.refreshStats(campaignId));
        };
    },
    updateSplits(campaignId, splits) {
        return dispatch => {
            dispatch({
                type: c.REPORT_CHANNEL__PIVOT_TABLE__UPDATE_SPLITS,
                payload: {
                    splits,
                    campaignId,
                },
            });

            return dispatch(this.refreshStats(campaignId));
        };
    },

    limitChannelsBy(campaignId, limit) {
        return dispatch => {
            dispatch({
                type: c.REPORT_CHANNEL__PIVOT_TABLE__LIMIT_CHANNELS,
                payload: {
                    limit,
                    campaignId,
                },
            });

            return dispatch(this.refreshStats(campaignId));
        };
    },
    fetchStats(campaignId, done) {
        return (dispatch, getState) => {
            dispatch(ExploreActions.addTimeouts(TIMEOUT_NOTIF_CONFIG));
            stream.push({ campaignId, getState, done });
        };
    },
    refreshStats(campaignId) {
        return (dispatch, getState) => {
            const isInitializing = _.get(getState(), `reportChannel.pivotTable.isInitializing`);

            if (isInitializing) {
                return;
            }

            dispatch({
                type: 'REPORT_CHANNEL__PIVOT_TABLE__DATA_REFRESH_START',
                payload: {},
            });

            const done = stats => {
                dispatch(ExploreActions.removeTimeouts(TIMEOUT_IDS));
                toastr.clear();
                dispatch({
                    type: c.REPORT_CHANNEL__PIVOT_TABLE__DATA_REFRESH,
                    payload: {
                        stats,
                        campaignId,
                    },
                });
            };
            dispatch(this.fetchStats(campaignId, done));
        };
    },
};

/* Ad Group */
export const adgroup_toggleGroup = (campaignId, component, grouping, group) => (
    dispatch,
    getState
) => {
    [].concat(group).forEach(group => {
        dispatch({
            type: c.CAMPAIGN_REPORT__ADGROUP__TOGGLE,
            payload: {
                campaignId,
                component,
                grouping,
                group,
            },
        });
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const adgroup_reset = (campaignId, component) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__ADGROUP__RESET,
        payload: {
            campaignId,
            component,
        },
    });

    fetchReportData(campaignId)(dispatch, getState);
};
export const filterByDateRange = (campaignId, dateRange) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_FILTER,
        payload: { campaignId, dateRange },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export function updateTimezone(campaignId, timezone) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_TIMEZONE,
            payload: { campaignId, timezone },
        });
    };
}

/* General */
export function inititalizeReport(campaignId) {
    return async (dispatch, getState) => {
        dispatch({
            type: c.CAMPAIGN_REPORT__INIT_STATE,
            payload: {
                campaignId,
            },
        });

        const isInitialized = _.get(getState(), `reportChannel.pivotTable.isInitialized`, false);

        if (isInitialized) {
            fetchReportData(campaignId)(dispatch, getState);
            return;
        }

        const campaign = await dispatch(fetchCampaign(campaignId));
        const userOrganization = _.get(getState(), 'profile.organizationId');
        const organizationTimezone = await fetchOrganizationTimezone(
            _.get(getState(), 'profile.userId')
        );
        const campaignOwner = campaign.owner;

        const isClient = userOrganization !== campaignOwner;

        dispatch({
            type: c.CAMPAIGN_REPORT__INITIALIZE,
            payload: {
                context: campaignId,
                campaignId,
                isClient,
                beacons: campaign.beacons,
                campaign,
                organizationTimezone,
            },
        });

        dispatch(actions.initialize(campaignId));

        const response = await http.get(`campaigns/${campaignId}/report-scopes`);

        dispatch({
            type: c.REPORT_CHANNEL__AVAILABLE_ADGROUPS__FETCH_SUCCESS,
            payload: {
                campaignId,
                response,
                campaign,
            },
        });

        fetchComponentData(campaignId, dispatch, getState);

        dispatch(fetchCampaign(campaignId)).then(() => {
            fetchComponentData(campaignId, dispatch, getState);
        });
    };
}

export function refreshReport(campaignId) {
    return (dispatch, getState) => {
        let campaign;
        dispatch(fetchCampaign(campaignId))
            .then(_campaign => {
                campaign = _campaign;

                return http.get(`campaigns/${campaignId}/report-scopes`);
            })
            .then(response => {
                dispatch({
                    type: c.REPORT_CHANNEL__AVAILABLE_ADGROUPS__FETCH_SUCCESS,
                    payload: {
                        campaignId,
                        response,
                        campaign,
                    },
                });
            })
            .then(() => {
                fetchComponentData(campaignId, dispatch, getState);
            });
    };
}

const StreamManager = (function() {
    var streams = {};

    return {
        getStream({ componentId, dispatch, getState }) {
            if (!streams[componentId]) {
                streams[componentId] = new Bacon.Bus();

                streams[componentId]
                    .debounce(100)
                    .flatMapLatest(({ request, component }) => {
                        dispatch({
                            type: c.REPORT_CHANNEL__ADGROUP_STATS__FETCH,
                            payload: {
                                campaignId: request.match.campaign_id,
                                component,
                            },
                        });
                        switch (component.type) {
                            case 'pivot':
                            case 'dimension':
                            case 'overview':
                            case 'heatmap':
                            case 'scope': {
                                const fetchData = () => {
                                    let match = _.omit(request.match.scopes, ['cache', 'cacheKey']);

                                    const { viewBy } = getState().campaignPageContainer;
                                    if (viewBy && viewBy !== 'campaign') {
                                        match.flight_id = [viewBy];
                                    }

                                    const promise = fetchReportStats({
                                        campaign_id: request.match.campaign_id,
                                        start: request.start,
                                        end: request.end,
                                        timeframe: request.timeframe,
                                        timezone: request.timezone,
                                        match,
                                        split: request.groups.scope
                                            ? [request.groups.scope]
                                            : request.groups.dimensions,
                                        type: 'ads_app_site_bundle_domain_name',
                                        datasource: getEnvironmentSettings().getReportingDatasource(),
                                        isReportPage: true,
                                        queryTag: 'ef_report_channel_splits',
                                    }).then(response => ({
                                        response,
                                        request,
                                        component,
                                    }));

                                    return Bacon.fromPromise(promise, true);
                                };

                                return Bacon.retry({
                                    source: fetchData,
                                    retries: 2,
                                    delay() {
                                        return 1000;
                                    },
                                });
                            }

                            default: {
                                console.warn('Unsupported component type');
                            }
                        }
                    })
                    .onValue(function(result) {
                        dispatch({
                            type: c.REPORT_CHANNEL__ADGROUP_STATS__FETCH__SUCCESS,
                            payload: {
                                campaignId: result.request.match.campaign_id,
                                response: result.response,
                                component: result.component,
                            },
                        });
                    });
            }

            return streams[componentId];
        },
    };
})();

export function fetchComponentData(campaignId, dispatch, getState, filter = () => true) {
    const reportSession = _.get(getState(), `reportChannel`);
    const dateRange = _.get(getState(), `reportChannel.dateRange`);

    const components = _.get(reportSession, 'components.items', [])
        .filter(filter)
        .filter(c => c.type !== 'pivot');
    const dimensionMatch = _.get(reportSession, 'dimensionFilter', {});
    const scopeMatch = _.get(reportSession, 'scopeFilter', {});

    dispatch(actions.refreshStats(campaignId));

    components.forEach(component => {
        const request = createRequest(campaignId, dimensionMatch, scopeMatch, component, dateRange);

        StreamManager.getStream({
            componentId: component.id,
            dispatch,
            getState,
        }).push({ request, component });
    });
}

function createRequest(campaignId, dimensionMatch, scopeMatch, component, dateRange) {
    const isScope = component.type === 'scope';
    const groupType = isScope ? 'scope' : 'dimensions';
    let groupValue = isScope ? _.first([].concat(component.groupings)) : component.groupings;

    const defaultGroups = {
        dimensions: [],
        scope: null,
    };

    let dateRangePayload = {
        ..._.omit(dateRange, ['cache', 'cacheKey', 'selectedKey']),
    };

    if (dateRange.timeframe === 'campaign_to_date') {
        // omit start/end or remove Z offset
        dateRangePayload = _.omit(dateRangePayload, ['start', 'end']);
    }

    return {
        ...dateRangePayload,
        match: {
            campaign_id: campaignId,
            scopes: scopeMatch,
        },
        groups: {
            ...defaultGroups,
            [groupType]: groupValue,
        },
        type: component.type,
    };
}

export default actions;

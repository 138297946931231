import _ from 'lodash';

export const IAS_GROUPM_VIEWABILITY_OPTIONS = [
    {
        value: 0,
        label: 'Off',
    },
    {
        value: 1,
        label: 'Target up to 40% GroupM viewability',
    },
    {
        value: 2,
        label: 'Target 40% GroupM viewability or higher',
    },
    {
        value: 3,
        label: 'Target 50% GroupM viewability or higher',
    },
    {
        value: 4,
        label: 'Target 60% GroupM viewability or higher',
    },
    {
        value: 5,
        label: 'Target 70% GroupM viewability or higher',
    },
];

export const IAS_GROUPM_VIEWABILITY_BY_AD_SIZE_OPTIONS = [];

export const IAS_GROUPM_VIEWABILITY_MAPPING = {};
_.each(IAS_GROUPM_VIEWABILITY_OPTIONS, option => {
    IAS_GROUPM_VIEWABILITY_MAPPING[option.value] = option.label;
});

export const IAS_GROUPM_VIEWABILITY_BY_AD_SIZE_MAPPING = {};
_.each(IAS_GROUPM_VIEWABILITY_BY_AD_SIZE_OPTIONS, option => {
    IAS_GROUPM_VIEWABILITY_BY_AD_SIZE_MAPPING[option.value] = option.label;
});

export const IAS_GROUPM_VIEWABILITY_VALUES = {
    OFF: 0,
    TARGET_UP_TO_40: 1,
    TARGET_40: 2,
    TARGET_50: 3,
    TARGET_60: 4,
    TARGET_70: 5,
};

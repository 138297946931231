import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { orgItemFormForm as Action_adminOrgItemForm } from './actions';
import { isInternalUser } from 'states/profile/business-rules';

import CustomFieldsSelector from 'containers/admin-org-item-form/modules/custom-fields-selector';
import FormField from 'widgets-v5/form-field';
import StandardInput from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import PrefixSuffixInput from 'widgets-v5/prefix-suffix-input';
import Toggle from 'widgets-v5/toggle';
import TimezoneSelector from 'widgets-v5/timezone-selector';
import Spacer from 'widgets-v5/spacer';

import { formatNumber_percentage } from 'utils/formatting';
import { getAllowOrgCreationTypes } from 'common/constants/allow-org-creation-types-by-org';
import orgTypeDictionary from 'common/constants/organizationTypeMappings';
import REVENUE_MODELS from 'common/constants/revenue-models';

import { creativeTypes, creativeSettings } from 'services/resources/organization/setting';

import UsersByOrgSelector from 'containers/users-by-org-selector';

import { GlobalModal } from 'widgets-v5/modal';
import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';
import { BlockLoadGroup } from 'widgets-v5/load-group';

import { createHttp } from 'utils/http';
import toastr from 'toastr';

const http = createHttp();

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const revenueModelOptions = _.map(REVENUE_MODELS, (label, value) => ({
    label,
    value,
}));

const t = {
    standard: 'STANDARD',
    video: 'VIDEO',
    mraid: 'MRAID',
    custom_html: 'CUSTOM HTML',
    native: 'NATIVE',
    allow_create: 'Enabled',
    auto_approve: 'Auto Approve',
};

const CLIENT_TYPES = [
    { value: 'advertiser', label: 'Advertiser' },
    { value: 'sales_rep', label: 'Sales Rep' },
    { value: 'partner', label: 'Partner' },
];
const CLIENT_TYPE_MAPPING = {};
_.each(CLIENT_TYPES, type => {
    CLIENT_TYPE_MAPPING[type.value] = type.label;
});

const mapStateToProps = (state, ownProps) => {
    const OrgIdInForm = _.get(ownProps, 'orgId', void 0); // orgId being edit
    const draft = _.get(state, 'adminOrgItemForm.draft', void 0);
    const isSubmiting = _.get(state, 'adminOrgItemForm.isSubmiting', void 0);
    const showErrors = _.get(state, 'adminOrgItemForm.showErrors', void 0);
    const formErrorsArray = _.get(state, 'adminOrgItemForm.formErrors', void 0);

    // Populate drop down with allow able creation type of organization
    const loggedInAsOrgId = _.get(state, `profile.organizationId`, void 0);

    const formErrorsByField = formErrorsArray.reduce((acc, v) => {
        acc[v.field] = v.message;
        return acc;
    }, {});

    const o = {
        loggedInAsOrgId,
        orgId: OrgIdInForm,
        isOpen: _.get(state, 'adminOrgItemForm.isOpen', void 0),
        draft,

        showErrors,
        formErrors: formErrorsByField,
        isSubmiting,
        relationship: _.get(state, 'adminOrgItemForm.relationship'),
    };

    return o;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch__draftInit(orgId) {
            dispatch(Action_adminOrgItemForm.draftInitWithGraphql(orgId));
        },

        dispatch__draftTearDown() {
            dispatch(Action_adminOrgItemForm.draftTearDown());
        },

        dispatch_fieldChange(orgId, data) {
            dispatch(Action_adminOrgItemForm.fieldChanged(orgId, data));
        },

        dispatch__draftSubmit(orgId) {
            dispatch(Action_adminOrgItemForm.draftSumbitWithGraphqlFetch(orgId));
        },
    };
};

class CreativeTypeItemComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="creative-type-item">
                <div className="creative-head">{_.get(t, `${this.props.creativeType}`, '??')}</div>
                <div className="creative-body">
                    {this.props.creativeSettings
                        .filter(creativeSetting => {
                            if (creativeSetting && creativeSetting !== 'auto_approve') return true;
                            if (
                                creativeSetting &&
                                creativeSetting === 'auto_approve' &&
                                this.props.showAutoApprove
                            )
                                return true;
                        })
                        ///////////////////////////////////////////////////////////////////////
                        // Currently auto_approve is not implimented thus the filter bellow is added
                        // to only show allow_create.
                        .filter(creativeSetting => {
                            if (creativeSetting && creativeSetting !== 'auto_approve') return false;
                            if (creativeSetting && creativeSetting !== 'allow_create') return true;
                        })
                        // Remove the above filter when auto_approve is implimented
                        ///////////////////////////////////////////////////////////////////////
                        .map((creativeSetting, k) => {
                            const settingName =
                                'creatives-' + this.props.creativeType + '-' + creativeSetting;
                            const settingValue = _.get(this.props, `draft.${settingName}`, void 0);
                            return (
                                <div className={`creative-setting ${creativeSetting}`} key={k}>
                                    <div className="creative-setting-name">
                                        {_.get(t, `${creativeSetting}`, '??')}
                                    </div>
                                    <div className="creative-setting-value">
                                        <Toggle
                                            status="true"
                                            width={56}
                                            height={25}
                                            position={settingValue ? 'right' : 'left'}
                                            textLeft="no"
                                            textRight="yes"
                                            onClick={() =>
                                                this.props.onFieldChange(settingName)(!settingValue)
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

class CreativeTypeCollectionComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={`creative-type-collection`}>
                {creativeTypes.map((creativeType, k) => {
                    return (
                        <CreativeTypeItemComponent
                            key={k}
                            creativeType={creativeType}
                            creativeSettings={creativeSettings}
                            {...this.props}
                        />
                    );
                })}
            </div>
        );
    }
}

const OrgForm_ButtonContainer = ({ loggedInAsOrgId, ...props }) => {
    const [
        allowableOrgTypeCreation_dropdownItems,
        setallowableOrgTypeCreation_dropdownItems,
    ] = useState(null);
    
    const fetchOrganizationTypeData = async () => {
        if (!loggedInAsOrgId) {
            return;
        }
        const query = `
            query getOrganizationType ($id: String) {
                organization(id: $id) {
                    type
                }
            }
        `;
        const variables = {
            id: loggedInAsOrgId,
        };
        try {
            const { organization } = await http.graphql(query, variables);
            const dropwdownItems = _.map(
                getAllowOrgCreationTypes(organization.type),
                allowAbleOrgTypes => {
                    return {
                        value: allowAbleOrgTypes,
                        label: _.get(orgTypeDictionary, `${allowAbleOrgTypes}`, ''),
                    };
                }
            );
            setallowableOrgTypeCreation_dropdownItems(dropwdownItems);
        } catch (error) {
            toastr.warning(
                '',
                '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
                TOASTR_OPTIONS
            );
            if (window.bugsnagClient) {
                window.bugsnagClient.notify(
                    `Failed to fetch organization type data in OrgForm_Button`,
                    {
                        metaData: {
                            loggedInAsOrgId: `${loggedInAsOrgId}`,
                        },
                    }
                );
            }
        }
    };

    useEffect(() => {
        fetchOrganizationTypeData();
    }, [loggedInAsOrgId]);

    return (
        <OrgForm_Button
            {...props}
            allowableOrgTypeCreation_dropdownItems={allowableOrgTypeCreation_dropdownItems}
        />
    );
};

class OrgForm_Button extends React.Component {
    constructor(props) {
        super(props);

        this.onFieldChange = this.onFieldChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            isFormOpen: false,
        };
    }

    handleEditOrCreate(e) {
        e.stopPropagation();
        this.props.dispatch__draftInit(this.props.orgId);
        this.setState({ isFormOpen: true });
    }

    onFieldChange(fieldName) {
        return fieldValue => {
            this.props.dispatch_fieldChange(this.props.orgId, { [fieldName]: fieldValue });
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const storeState_isOpen = nextProps.isOpen;
        const componentState_isOpen = this.state.isFormOpen;
        if (componentState_isOpen === true && storeState_isOpen === false) {
            this.setState({ isFormOpen: false });
        }
    }

    handleSubmit(e) {
        e.stopPropagation();
        this.props.dispatch__draftSubmit(this.props.orgId);
    }

    handleCloseForm() {
        this.props.dispatch__draftTearDown();
        this.setState({ isFormOpen: false });
    }

    handleCancel(e) {
        e.stopPropagation();
        this.handleCloseForm();
    }

    getOrganizationTypeByKey = key => {
        switch (key) {
            case 'media_buyer':
                return 'Media Buyer';
            case 'admin':
                return 'Admin';
            default:
                return key;
        }
    };

    render() {
        const isEdit = this.props.orgId;
        const isNew = !isEdit;
        let showTechFee = true;
        let canEditTechFee = false;

        let showAutoApprove = false;
        let showSubsection = false;

        const hasDraft =
            this.state.isFormOpen && !this.props.isLoading && this.props.draft !== null;
        if (hasDraft) {
            // tech_fee visibility
            // -------------------
            if (
                _.includes(['client', 'co_managed_client', 'admin', void 0], this.props.draft.type)
            ) {
                // don't show platform fee if it is co-managed client,  client, admin
                showTechFee = false;
            }

            if (isEdit) {
                const relationship_inDraft = this.props.relationship;

                if (_.includes(['child'], relationship_inDraft)) {
                    canEditTechFee = true; // only allow to edit platform fee if it is a child

                    showAutoApprove = true; // only show creative auto_approve field if it is a child

                    const orgType_inDraft = this.props.draft.type;
                    if (_.includes(['admin', 'media_buyer'], orgType_inDraft)) {
                        showSubsection = true;
                    }
                    if (_.includes(['co_managed_client', 'client'], orgType_inDraft)) {
                        showSubsection = false;
                    }
                }
            } else if (isNew) {
                canEditTechFee = true;
                showAutoApprove = true;

                // When creating a new org the newly created org is a child so subSection needs
                // to conform with child visibility rule.
                const orgType_inDraft = this.props.draft.type;
                if (_.includes(['admin', 'media_buyer'], orgType_inDraft)) {
                    showSubsection = true;
                }
                if (_.includes(['co_managed_client', 'client'], orgType_inDraft)) {
                    showSubsection = false;
                }
            }
        }

        return (
            <div className="ef5-organization-setup admin_organization_button">
                <div
                    onClick={e => this.handleEditOrCreate.call(this, e)}
                    style={{ display: 'inline-block' }}
                >
                    {this.props.Button}
                </div>
                <GlobalModal
                    title="New Organization"
                    isOpen={this.state.isFormOpen}
                    onClose={() => this.handleCloseForm}
                    overflow
                    className="admin_organization_button"
                    bottomBar={
                        hasDraft ? (
                            <ButtonGroup>
                                <PrimaryButton text="Save" onClick={this.handleSubmit} />
                                <NeutralButton text="Cancel" onClick={this.handleCancel} />
                            </ButtonGroup>
                        ) : (
                            <div />
                        )
                    }
                >
                    {!hasDraft ? (
                        <div className="ef6-alignment__center" style={{ width: 500, height: 300 }}>
                            <BlockLoadGroup isLoading={true} />
                        </div>
                    ) : (
                        <BlockLoadGroup isLoading={this.props.isSubmiting}>
                            <div className="ef5-organization-setup__form">
                                <div className="top-section">
                                    <div>
                                        {/* ------------ Org Name ----------------*/}
                                        <div className="org-type field">
                                            <FormField
                                                label="Name"
                                                errors={[_.get(this.props, `formErrors.name`, '')]}
                                                showErrors={this.props.showErrors}
                                                isRequired={true}
                                            >
                                                <StandardInput
                                                    value={this.props.draft.name}
                                                    onChange={this.onFieldChange('name')}
                                                />
                                            </FormField>
                                            <Spacer type="small" />
                                        </div>
                                        {/* ------------ Org Name (END)-----------*/}

                                        {/* ------------ Org Timezone ----------------*/}
                                        <div className="org-type field">
                                            {
                                                <div>
                                                    <FormField
                                                        label="Default Timezone"
                                                        errors={[
                                                            _.get(
                                                                this.props,
                                                                `formErrors.default_timezone`,
                                                                ''
                                                            ),
                                                        ]}
                                                        showErrors={this.props.showErrors}
                                                        isRequired={true}
                                                    >
                                                        <TimezoneSelector
                                                            label="StandardSelector"
                                                            timezone={_.get(
                                                                this.props.draft,
                                                                'default_timezone',
                                                                void 0
                                                            )}
                                                            onChange={this.onFieldChange(
                                                                'default_timezone'
                                                            )}
                                                        />
                                                    </FormField>
                                                    <Spacer type="small" />
                                                </div>
                                            }
                                        </div>
                                        {/* ------------ Org Timezone (END)-----------*/}

                                        {/* ------------ Org Revenue Model ----------------*/}
                                        <div className="org-type field">
                                            <div>
                                                <FormField
                                                    label="Default Revenue Model"
                                                    errors={[
                                                        _.get(
                                                            this.props,
                                                            `formErrors.revenueModel`,
                                                            ''
                                                        ),
                                                    ]}
                                                    showErrors={this.props.showErrors}
                                                    isRequired={false}
                                                >
                                                    <StandardSelector
                                                        onChange={this.onFieldChange(
                                                            'revenueModel'
                                                        )}
                                                        value={this.props.draft.revenueModel}
                                                        items={revenueModelOptions}
                                                        clearable
                                                    />
                                                </FormField>
                                                <Spacer type="small" />
                                            </div>
                                        </div>
                                        {/* ------------ Revenue Model (END)-----------*/}

                                        {/* ------------ Org Type ----------------*/}
                                        <div className="org-type field">
                                            {isNew ? (
                                                <div>
                                                    <FormField
                                                        label="Type"
                                                        errors={[
                                                            _.get(
                                                                this.props,
                                                                `formErrors.type`,
                                                                ''
                                                            ),
                                                        ]}
                                                        showErrors={this.props.showErrors}
                                                        isRequired={true}
                                                    >
                                                        <StandardSelector
                                                            autofocus={false}
                                                            disabled={false}
                                                            clearable={false}
                                                            searchable={true}
                                                            label="StandardSelector"
                                                            value={_.get(
                                                                this.props.draft,
                                                                'type',
                                                                void 0
                                                            )}
                                                            onChange={this.onFieldChange('type')}
                                                            placeholder="Select a organization to create"
                                                            items={
                                                                this.props
                                                                    .allowableOrgTypeCreation_dropdownItems
                                                            }
                                                        />
                                                    </FormField>
                                                    <Spacer type="small" />
                                                </div>
                                            ) : (
                                                <div>
                                                    <FormField
                                                        label="Type"
                                                        errors={[]}
                                                        showErrors={false}
                                                        isRequired={false}
                                                    >
                                                        <div className="value">
                                                            {this.getOrganizationTypeByKey(
                                                                _.get(
                                                                    this.props.draft,
                                                                    'type',
                                                                    void 0
                                                                )
                                                            )}
                                                        </div>
                                                    </FormField>
                                                    <Spacer type="small" />
                                                </div>
                                            )}
                                        </div>
                                        {/* ------------ Org Type (END)-----------*/}

                                        {this.props.draft.type === 'client' &&
                                            (isNew ? (
                                                <div className="org-type field">
                                                    <FormField
                                                        label="Client Type"
                                                        isRequired={false}
                                                    >
                                                        <StandardSelector
                                                            autofocus={false}
                                                            disabled={false}
                                                            clearable={false}
                                                            searchable={true}
                                                            label="StandardSelector"
                                                            value={_.get(
                                                                this.props.draft,
                                                                'client_type'
                                                            )}
                                                            onChange={this.onFieldChange(
                                                                'client_type'
                                                            )}
                                                            placeholder="Select a Client Type"
                                                            items={CLIENT_TYPES}
                                                        />
                                                    </FormField>
                                                    <Spacer type="small" />
                                                </div>
                                            ) : (
                                                <div>
                                                    <FormField label="Client Type">
                                                        <div className="value">
                                                            {
                                                                CLIENT_TYPE_MAPPING[
                                                                    this.props.draft.client_type
                                                                ]
                                                            }
                                                        </div>
                                                    </FormField>
                                                    <Spacer type="small" />
                                                </div>
                                            ))}

                                        {/* ------------ Platform Fee ----------------*/}
                                        {showTechFee && canEditTechFee ? (
                                            <div className="tech-fee field">
                                                <FormField
                                                    label="Platform Fee"
                                                    errors={[
                                                        _.get(
                                                            this.props,
                                                            `formErrors.tech_fee`,
                                                            ''
                                                        ),
                                                    ]}
                                                    showErrors={this.props.showErrors}
                                                    isRequired={true}
                                                >
                                                    <PrefixSuffixInput
                                                        disabled={false}
                                                        placeholder=""
                                                        suffix={'%'}
                                                        formatIn={format_valueToString_techFee}
                                                        formatOut={format_stringToValue_techFee}
                                                        shouldAllowInput={shouldAllowInput_techFee}
                                                        value={this.props.draft.tech_fee}
                                                        onChange={this.onFieldChange('tech_fee')}
                                                    />
                                                </FormField>
                                                <Spacer type="small" />
                                            </div>
                                        ) : null}
                                        {showTechFee && !canEditTechFee ? (
                                            <div className="tech-fee field">
                                                <FormField
                                                    label="Platform Fee"
                                                    errors={[]}
                                                    showErrors={false}
                                                    isRequired={false}
                                                >
                                                    <div className="value-unit">
                                                        <div className="value">
                                                            {formatNumber_percentage(
                                                                this.props.draft.tech_fee
                                                            )}
                                                        </div>
                                                    </div>
                                                </FormField>
                                                <Spacer type="small" />
                                            </div>
                                        ) : null}
                                        {/* ------------ Platform Fee (END) ----------*/}

                                        {/* ------------ Credit Limit ------------*/}
                                        {showSubsection ? (
                                            <div className="credit-limit field">
                                                <FormField
                                                    label="Credit limit"
                                                    errors={[
                                                        _.get(
                                                            this.props,
                                                            `formErrors.credit_limit`,
                                                            ''
                                                        ),
                                                    ]}
                                                    showErrors={this.props.showErrors}
                                                    isRequired={false}
                                                >
                                                    <PrefixSuffixInput
                                                        prefix="$"
                                                        disabled={false}
                                                        placeholder="Unlimited"
                                                        formatIn={format_valueToString_creditLimit}
                                                        formatOut={format_stringToValue_creditLimit}
                                                        shouldAllowInput={
                                                            shouldAllowInput_creditLimit
                                                        }
                                                        value={this.props.draft.credit_limit}
                                                        onChange={this.onFieldChange(
                                                            'credit_limit'
                                                        )}
                                                    />
                                                </FormField>
                                                <Spacer type="small" />
                                            </div>
                                        ) : null}
                                        {/* ------------ Credit Limit (END)-------*/}

                                        {/* ------------ Currency ----------------*/}
                                        {showSubsection ? (
                                            <div className="currency field">
                                                <FormField
                                                    label="Currency"
                                                    errors={[
                                                        _.get(
                                                            this.props,
                                                            `formErrors.currency`,
                                                            ''
                                                        ),
                                                    ]}
                                                    showErrors={this.props.showErrors}
                                                    isRequired={false}
                                                >
                                                    <StandardSelector
                                                        autofocus={false}
                                                        disabled={false}
                                                        clearable={false}
                                                        searchable={true}
                                                        label="StandardSelector"
                                                        value={_.get(
                                                            this.props.draft,
                                                            'currency',
                                                            void 0
                                                        )}
                                                        onChange={this.onFieldChange('currency')}
                                                        placeholder="Select a currency type"
                                                        items={[
                                                            { value: 'USD', label: 'USD' },
                                                            { value: 'CAD', label: 'CAD' },
                                                        ]}
                                                    />
                                                </FormField>
                                                <Spacer type="small" />
                                            </div>
                                        ) : null}
                                        {/* ------------ Currency (END)  ---------*/}
                                    </div>
                                    <div>
                                        {/* ------------ Auditor and Auditee ----------------*/}
                                        {showSubsection ? (
                                            <div className="audit">
                                                <div className="auditor field">
                                                    <FormField
                                                        label="Auditors"
                                                        errors={[]}
                                                        showErrors={false}
                                                        isRequired={false}
                                                    >
                                                        <UsersByOrgSelector
                                                            orgId={this.props.orgId}
                                                            value={_.get(
                                                                this.props.draft,
                                                                'auditors',
                                                                []
                                                            )}
                                                            onChange={this.onFieldChange(
                                                                'auditors'
                                                            )}
                                                        />
                                                    </FormField>
                                                    <Spacer type="small" />
                                                </div>
                                                <div className="auditee field">
                                                    <FormField
                                                        label="Auditees"
                                                        errors={[]}
                                                        showErrors={false}
                                                        isRequired={false}
                                                    >
                                                        <UsersByOrgSelector
                                                            orgId={this.props.orgId}
                                                            value={_.get(
                                                                this.props.draft,
                                                                'auditees',
                                                                []
                                                            )}
                                                            onChange={this.onFieldChange(
                                                                'auditees'
                                                            )}
                                                        />
                                                    </FormField>
                                                    <Spacer type="small" />
                                                </div>
                                            </div>
                                        ) : null}
                                        {/* ------------ Auditor and Auditee (END)-----------*/}
                                    </div>
                                </div>

                                {/* ------------ Creatives ----------------*/}
                                <div className="bottom-section">
                                    {showSubsection ? (
                                        <div className="creatives-container">
                                            <div className="creatives field">
                                                <CreativeTypeCollectionComponent
                                                    {...this.props}
                                                    showAutoApprove={showAutoApprove}
                                                    onFieldChange={this.onFieldChange}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                {/* ------------ Creatives (END) -----------*/}
                                {isInternalUser() && (
                                    <div>
                                        <CustomFieldsSelector
                                            customFields={this.props.draft.custom_fields}
                                            onChange={this.onFieldChange('custom_fields')}
                                        />
                                    </div>
                                )}
                            </div>
                        </BlockLoadGroup>
                    )}
                </GlobalModal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgForm_ButtonContainer);

function shouldAllowInput_techFee(domString) {
    // Allow empty input, However validation will tell user they cannot leave this field empty.
    if (!domString) return true;

    // cannot parse to number
    if (!(+domString === +domString)) return false;

    // Only allow digit and period
    if (!/^[\d\.]*$/g.test(domString)) return false;

    // if there are period
    const hasPeriod = /\./.test(domString);
    if (hasPeriod) {
        // has period split them
        const after = domString.split('.')[1];
        // can't have 2 digits after peroid
        if (after.length > 2) return false;
    }
    return true;
}

function format_valueToString_techFee(value) {
    if (!value) return '';
    const out = value * 100; // fraction to percentage
    return out.toFixed(2);
}

function format_stringToValue_techFee(string) {
    let out = void 0;
    if (string === '') {
        out = void 0;
    } else {
        out = +string / 100; // percent to fraction
    }
    return out;
}

function shouldAllowInput_creditLimit(domString) {
    // Allow empty input, However validation will tell user they cannot leave this field empty.
    if (!domString) return true;

    // cannot parse to number
    if (!(+domString === +domString)) return false;

    // Only allow digit and period
    if (!/^[\d\.]*$/g.test(domString)) return false;

    // if there are period
    const hasPeriod = /\./.test(domString);
    if (hasPeriod) {
        // has period split them
        const after = domString.split('.')[1];
        // can't have 2 digits after peroid
        if (after.length > 2) return false;
    }
    return true;
}

function format_valueToString_creditLimit(value) {
    if (!value) return '';
    if (value === -1) return '';
    const out = value + '';
    return out;
}

function format_stringToValue_creditLimit(string) {
    if (string === '') return -1;
    const out = +string;
    return out;
}

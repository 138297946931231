import _ from 'lodash';
import Joi from '@hapi/joi';

const schema = Joi.object({
    id: Joi.string(),
    event_name: Joi.string()
        .trim()
        .regex(/^[a-z0-9_]+$/)
        .required()
        .messages({
            'string.empty': 'Not allowed to be empty',
            'string.pattern.base':
                'Cannot contain uppercase letters, space or symbols other than "_"',
        })
        .custom((value, helpers) => {
            const conversion = helpers.state.ancestors[0];
            const { conversions } = helpers.prefs.context;

            const hasExistingConversionWithSameEventName = _(conversions)
                .filter(_conversion => _conversion.advertiser.id === conversion.advertiser)
                .filter(_conversion => _conversion.id !== conversion.id)
                .filter(_conversion => _conversion.event_name === conversion.event_name)
                .some();

            if (hasExistingConversionWithSameEventName) {
                return helpers.message(`Event Name exists`);
            }

            return value;
        }),
    reporting_name: Joi.string()
        .required()
        .messages({
            'string.empty': 'Not allowed to be empty',
        }),
    advertiser: Joi.string()
        .required()
        .messages({
            'string.empty': 'Not allowed to be empty',
        }),
    use_click_through: Joi.boolean().required(),
    use_view_through: Joi.boolean().required(),
    click_through_attribution_window: Joi.number().integer(),
    view_through_attribution_window: Joi.number().integer(),
    use_dynamic_data_subevent: Joi.boolean().required(),
    use_dynamic_data_value: Joi.boolean().required(),
    dynamic_data_subevent_name: Joi.when('use_dynamic_data_subevent', {
        is: true,
        then: Joi.string()
            .regex(/^[A-Za-z0-9\s\-.~][A-Za-z0-9\s_./&+-]*$/)
            .required()
            .messages({
                'string.empty': 'GTM Variable cannot be empty',
                'string.pattern.base':
                    "The name cannot start with '_' or contain a reserved character (!, @, #, $, <, etc)",
            }),
    }),
    dynamic_data_value_name: Joi.when('use_dynamic_data_value', {
        is: true,
        then: Joi.string()
            .regex(/^[A-Za-z0-9\s\-.~][A-Za-z0-9\s_./&+-]*$/)
            .required()
            .messages({
                'string.empty': 'GTM Variable cannot be empty',
                'string.pattern.base':
                    "The name cannot start with '_' or contain a reserved character (!, @, #, $, <, etc)",
            }),
    }),
    should_optimize_for_budget_allocation: Joi.boolean().required(),
}).custom((conversion, helpers) => {
    const { use_click_through, use_view_through } = helpers.prefs.context.draft;
    if (!use_click_through && !use_view_through) {
        return helpers.message('Select an Attribution Type');
    }

    return conversion;
});

export default schema;

import toastr from 'toastr';
import _ from 'lodash';
import { createHttp } from 'utils/http';

const http = createHttp();

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const actions = {
    init: () => {
        return async dispatch => {
            dispatch({
                type: 'ADMIN_V2__CHILD_ORGANIZATION_DASH_INIT_START',
            });
            await dispatch(actions.fetchAllOrganizationsByType());
            dispatch({
                type: 'ADMIN_V2__CHILD_ORGANIZATION_DASH_INIT_END',
            });
        };
    },
    fetchOrganizationData: orgId => {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'ADMIN_V2__FETCH_CHILD_ORGANIZATION_START',
                    payload: {},
                });
                const query = `
                    query getOrganizationDataAndUsers ($id: String) {
                        organization(id: $id) {
                            id
                            name
                            type
                            default_timezone
                            revenueModel
                            users {
                                id
                                first_name
                                last_name
                                email
                                global_role
                                organization
                            }
                        }
                    }
                `;

                const variables = {
                    id: orgId,
                };

                const { organization } = await http.graphql(query, variables);

                dispatch({
                    type: 'ADMIN_V2__FETCH_CHILD_ORGANIZATION_END',
                    payload: {
                        data: { organization },
                    },
                });
            } catch (err) {
                toastr.error('', 'Failed to get users for organization', TOASTR_OPTIONS);
                if (window.bugsnagClient) {
                    window.bugsnagClient.notify(
                        `Failed to fetch organization user data in admin page`,
                        {
                            metaData: {
                                organizationId: _.get(getState(), 'profile.organizationId'),
                            },
                        }
                    );
                }
            }
        };
    },

    fetchAllOrganizationsByType: (type = 'advertiser') => {
        return async (dispatch, getState) => {
            dispatch({
                type: 'ADMIN_V2__FETCH_CHILD_ORGS_BY_TYPE_START',
            });
            const query = `
                query getOrganizationUsersByType($orgFilters: OrganizationFilters) {
                    organizations (filters: $orgFilters) {
                        id
                        name
                        type
                        client_type
                        users {
                            id
                            last_name
                            first_name
                            email
                            global_role
                            organization
                            org {
                                name
                            }
                        }
                    }
                }
            `;

            const typeQuery =
                type === 'media_buyer' || type === 'admin'
                    ? { type: [type] }
                    : { client_type: [type] };
            const variables = {
                orgFilters: { ...typeQuery, relationship: 'child' },
            };
            const { organizations: organizationsAndSelfOrg } = await http.graphql(query, variables);
            const ownOrganization = _.find(
                organizationsAndSelfOrg,
                org => org.id === _.get(getState(), 'profile.organizationId')
            );
            const organizations = _(organizationsAndSelfOrg)
                .filter(org => org.id !== _.get(getState(), 'profile.organizationId'))
                .sortBy(org => org.name)
                .value();

            const users = _(organizations)
                .filter(organization => organization.users.length > 0)
                .map(organization => organization.users)
                .flatten()
                .sortBy(user => `${user.first_name} ${user.last_name}`)
                .value();

            dispatch({
                type: 'ADMIN_V2__FETCH_CHILD_ORGS_BY_TYPE_END',
                payload: {
                    users,
                    organizations,
                    ownOrganizationType: _.get(ownOrganization, 'type'),
                },
            });
        };
    },

    changeOrgFilterType: value => {
        return async (dispatch, getState) => {
            dispatch({
                type: 'ADMIN_V2__CHANGE_ORG_FILTER_TYPE_START',
            });
            await dispatch(actions.fetchAllOrganizationsByType(value));
            dispatch({
                type: 'ADMIN_V2__CHANGE_ORG_FILTER_TYPE_END',
            });
        };
    },

    filterOrganizationsByText: keywords => {
        return (dispatch, getState) => {
            dispatch({
                type: 'ADMIN_V2__FILTER_CHILD_ORGS_START',
            });
            const orgs = _.get(getState(), 'adminV2.childOrganization.organizationsForList');
            const pattern = new RegExp(keywords, 'i');
            const filteredOrgs = _.filter(orgs, org => {
                return pattern.test(org.name);
            });
            dispatch({
                type: 'ADMIN_V2__FILTER_CHILD_ORGS_END',
                payload: { data: filteredOrgs },
            });
        };
    },

    filterUsers: keywords => {
        return (dispatch, getState) => {
            dispatch({
                type: 'ADMIN_V2__FITER_CHILD_USERS_START',
                payload: {},
            });
            const users = _.get(getState(), 'adminV2.childOrganization.users');
            const pattern = new RegExp(keywords, 'i');
            const filteredUsers = _.filter(users, user => {
                const userFullName = `${user.first_name} ${user.last_name}`;
                return pattern.test(userFullName);
            });
            dispatch({
                type: 'ADMIN_V2__FITER_CHILD_USERS_END',
                payload: { data: filteredUsers },
            });
        };
    },
};

export default actions;

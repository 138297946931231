import React from 'react';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

import actions from '../../actions';

import PLATFORM_OPTIONS from 'common/constants/platforms';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { getIASGamblingWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

import {
    IAS_BRAND_SAFETY_CATEGORIES,
    IAS_BRAND_SAFETY_OPTIONS,
    IAS_BRAND_SAFETY_UNRATEABLE_ON_LABEL,
} from 'common/constants/ias-brand-safety';

const PLATFORMS = {};
_.each(PLATFORM_OPTIONS, option => (PLATFORMS[option.value] = option));

const useStyles = makeStyles({
    form: {
        width: '30%',
        margin: '5px 0px',
    },
    label: {
        fontSize: 14,
        margin: '10px 0px',
    },
    labelSmall: {
        fontSize: 17,
    },
    select: {
        marginTop: '10px',
    },
});

const IASBrandSafety = ({
    handleChangeRateable,
    handleChangeUnrateable,
    isUnrateableChecked,
    categories,
    iasGamblingwarning,
    isCrossPlatformCampaign,
}) => {
    const classes = useStyles();

    return (
        <div>
            {_.map(categories, categoryOption => (
                <div key={categoryOption.id}>
                    <FormControl className={classes.form} disabled={categoryOption.disabled}>
                        <InputLabel className={classes.labelSmall}>
                            {categoryOption.label}
                        </InputLabel>
                        <Select
                            className={classes.select}
                            value={categoryOption.value}
                            onChange={handleChangeRateable(categoryOption.id)}
                        >
                            {_.map(IAS_BRAND_SAFETY_OPTIONS, option => (
                                <MenuItem value={option.value} key={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {categoryOption.helpText && (
                            <FormHelperText>{categoryOption.helpText}</FormHelperText>
                        )}
                        {categoryOption.id === 'gambling' &&
                            isCrossPlatformCampaign &&
                            iasGamblingwarning.hasWarning && (
                                <WarningText
                                    message={iasGamblingwarning.message}
                                    severity={iasGamblingwarning.severity}
                                />
                            )}
                    </FormControl>
                </div>
            ))}

            <FormControlLabel
                control={
                    <Checkbox checked={isUnrateableChecked} onChange={handleChangeUnrateable} />
                }
                label={IAS_BRAND_SAFETY_UNRATEABLE_ON_LABEL}
            />
        </div>
    );
};

function selector(storeState) {
    const platform = _.get(storeState, 'adForm.form.draft.platform');
    const adPlatforms = _.get(storeState, 'adForm.form.draft.platforms');
    const brandSafety = _.get(storeState, 'adForm.form.draft.ias.brandSafety');
    const isCrossPlatformCampaign = _.get(storeState, 'adForm.form.isCrossPlatformCampaign');
    const categories = _.map(IAS_BRAND_SAFETY_CATEGORIES, category => {
        const disabled = isCrossPlatformCampaign
            ? false
            : !_.includes(category.platforms, platform);
        const unsupportedPlatforms = _.filter(
            adPlatforms,
            adPlatform => !_.includes(category.platforms, adPlatform)
        );

        let helpText;

        if (disabled || unsupportedPlatforms.length > 0) {
            const platforms = _.map(category.platforms, platform => PLATFORMS[platform].label).join(
                ' and '
            );

            helpText = `Supports ${platforms} only`;
        }

        return {
            ...category,
            id: category.value,
            disabled,
            helpText,
            value: brandSafety[category.value].value,
        };
    });

    const iasGamblingwarning = getIASGamblingWarning({
        platforms: adPlatforms,
        gamblingValue: brandSafety.gambling.value,
    });

    return {
        categories,
        isUnrateableChecked: brandSafety.unrateable.value !== 0,
        iasGamblingwarning,
        isCrossPlatformCampaign,
    };
}

function mapDispatch(dispatch) {
    return {
        handleChangeRateable: brandSafetyField => event => {
            const mode = event.target.value;

            dispatch(actions.changeBrandSafety(brandSafetyField, mode));
        },

        handleChangeUnrateable: event => {
            const checked = event.target.checked;

            dispatch(actions.changeBrandSafety('unrateable', checked ? 1 : 0));
        },
    };
}

export default connect(
    selector,
    mapDispatch
)(IASBrandSafety);

import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Joi from '@hapi/joi';

import { createHttp } from 'utils/http';
import { calculateThirdPartyFees } from 'states/resources/campaigns/business-logic';
import { validateAdDraft } from 'forms/ad-form/services';
import AdActions from 'states/resources/ads/actions';
import selector from './selector';
import {
    serializeRotationRules,
    syncScheduleRotationWithFlight,
} from 'states/resources/creatives/business-logic';
import { plainSchema } from 'forms/ad-form/schema';
import toastr from 'toastr';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

const http = createHttp();

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const PartialAdFormContainer = props => {
    const [ownOrganization, setOwnOrganization] = useState(null);

    const fetchOrganizationFtaPartnerId = async () => {
        const query = `
            query getOrganizationFtaPartnerId($id: String) {
                organization(id: $id) {
                    id
                    fta_partner_id
                }
            }
        `;
        const variables = {
            id: props.ownOrgId,
        };
        try {
            const { organization } = await http.graphql(query, variables);
            setOwnOrganization(organization);
        } catch (error) {
            if (error.response.status === 401) {
                return;
            }
            toastr.warning(
                '',
                '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
                TOASTR_OPTIONS
            );
            if (window.bugsnagClient) {
                window.bugsnagClient.notify(
                    `Failed to fetch organization tech fee data in Partial Ad Form`,
                    {
                        metaData: {
                            orgId: `${props.ownOrgId}`,
                        },
                    }
                );
            }
        }
    };

    useEffect(() => {
        fetchOrganizationFtaPartnerId();
    }, []);

    return <PartialAdForm {...props} ownOrganization={ownOrganization} />;
};

const PartialAdForm = class extends React.Component {
    state = {
        draft: this.props.initialDraft,
        errors: {},
        shouldShowErrors: false,
    };

    updateDraft = changes => {
        this.setState(
            state => {
                if (_.isFunction(changes)) {
                    return {
                        ...state,
                        draft: changes(state.draft),
                    };
                }
                return {
                    ...state,
                    draft: { ...state.draft, ...changes },
                };
            },
            () => {
                this.validate();
            }
        );
    };

    mapErrors = errorsList => {
        const errors = {};

        _.each(errorsList, error => {
            errors[error.field] = [error.message];
        });

        return errors;
    };

    showErrors = () => {
        this.setState({ shouldShowErrors: true });
    };

    validate = () => {
        const { ad, campaign } = this.props;
        const draft = _.pick(this.state.draft, this.props.fields);
        const orgFtaPartnerId = _.get(this.props, 'ownOrganization.fta_partner_id');
        const errorsList = validateAdDraft({
            draft,
            originalAd: ad,
            billingEnabled: this.props.campaign.billing_enabled,
            thirdPartyFees: calculateThirdPartyFees(this.props.campaign),
            orgFtaPartnerId,
            revenueModel: this.props.campaign.revenueModel,
            validGeoLayers: this.props.validGeoLayers,
            campaignFtaVersion: campaign.fta_version,
            campaignType: campaign.type,
        });

        const errorsFromSchema = this.validateSchema(draft, this.props.fields);
        const errors = this.mapErrors(errorsList);

        this.setState({
            errors: { ...errors, ...errorsFromSchema },
        });

        if (errorsList.length > 0) {
            return false;
        }

        return true;
    };

    validateSchema = (draft, fields) => {
        const options = {
            allowUnknown: true,
            abortEarly: false,
            context: {
                draft: draft,
            },
        };
        const schema = Joi.object(_.pick(plainSchema, fields));
        const result = schema.validate(draft, options);

        if (!result.error) {
            return {};
        }

        const errorMapping = {};

        _.each(result.error.details, detail => {
            errorMapping[detail.path.join('.')] = detail.message;
        });

        return errorMapping;
    };

    save = adId => {
        const { dispatch, campaign, fields, ads, ad } = this.props;
        const cloneDraft = _.cloneDeep(this.state.draft);
        let draft = _.pick(cloneDraft, fields);
        if (draft.rotation_rules) {
            draft = {
                ...draft,
                rotation_rules: serializeRotationRules(draft.rotation_rules),
            };
        }

        if (draft.use_front_load_pacing) {
            draft.max_daily_impressions = 0;
            draft.max_daily_clicks = 0;
            draft.max_daily_billings = 0;
            draft.max_daily_spend_local = 0;
        }

        if (ads) {
            const adToEdit = _.find(ads, ad => ad.id === _.toNumber(adId));
            const isSingleEvenWeightedOptions =
                _.get(draft, 'rotation_rules.scheduled.length') === 1;
            const isScheduled = _.get(draft, 'rotation_rules.mode') === CreativeRotationMethodMapping.Scheduled;
            const isEditingStartColumn = _.includes(fields, 'start');
            const isEditingEndColumn = _.includes(fields, 'end');
            const isEditingCreativeColumn = _.includes(fields, 'rotation_rules');

            if (isScheduled && (isEditingStartColumn || isEditingEndColumn)) {
                draft.rotation_rules = syncScheduleRotationWithFlight({
                    ad: adToEdit,
                    changes: draft,
                });
            }

            if (isScheduled && isEditingCreativeColumn && isSingleEvenWeightedOptions) {
                draft.rotation_rules.scheduled[0].start = adToEdit.start;
                draft.rotation_rules.scheduled[0].end = adToEdit.end;
            }
        } else if (ad) {
            if (
                ad.rotation_rules.mode === CreativeRotationMethodMapping.Scheduled &&
                (_.includes(fields, 'start') || _.includes(fields, 'end'))
            ) {
                draft.rotation_rules = syncScheduleRotationWithFlight({ ad, changes: draft });
            }
        }

        this.setState({
            isSaving: true,
            shouldShowErrors: true,
        });

        return dispatch(AdActions.update(campaign.id, adId, draft)).then(
            ad => {
                this.setState({ isSaving: false });

                return ad;
            },
            err => {
                this.setState({ isSaving: false });

                throw err;
            }
        );
    };

    componentDidMount() {
        this.validate();
    }

    render() {
        return this.props.children({
            // behavior
            validate: this.validate,
            save: this.save,
            updateDraft: this.updateDraft,
            showErrors: this.showErrors,

            // local state
            draft: this.state.draft,
            errors: this.state.errors,
            shouldShowErrors: this.state.shouldShowErrors,
            isSaving: this.state.isSaving,
        });
    }
};

export default connect(selector)(PartialAdFormContainer);

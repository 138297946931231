import _ from 'lodash';

const genericMacros = {
    '[timestamp]': '%%TIMESTAMP%%',
    '[TIMESTAMP]': '%%TIMESTAMP%%',
    '%time%': '%%TIMESTAMP%%',
    '%time2%': '%%TIMESTAMP%%',
    Insert_Time_Date_Stamp_Here: '%%TIMESTAMP%%',
    INSERT_TIMESTAMP_HERE: '%%TIMESTAMP%%',
    '[INSERT_TIME_STAMP_/_RND_NUMBER_HERE]': '%%TIMESTAMP%%',
    '[INSERT_AD_SERVER_CLICK_REDIRECT_HERE]': '%%CLICKTHROUGH_ESC%%',
    __RANDOM_NUMBER__: '%%RANDOM%%',
    '%%CACHEBUSTER%%': '%%TIMESTAMP%%',
    '%%timestamp%%': '%%TIMESTAMP%%',
    '%%random%%': '%%RANDOM%%',
    '%%city%%': '%%CITY%%',
    '%%region%%': '%%REGION%%',
    '%%country%%': '%%COUNTRY%%',
    '%%carrier%%': '%%CARRIER%%',
    '%%latitude%%': '%%LATITUDE%%',
    '%%longitude%%': '%%LONGITUDE%%',
    '%%device_os%%': '%%DEVICE_OS%%',
    '%%device_model%%': '%%DEVICE_MODEL%%',
    '%%clickthrough%%': '%%CLICKTHROUGH%%',
    '%%clickthrough_esc%%': '%%CLICKTHROUGH_ESC%%',
    '%%redirect%%': '%%CLICKTHROUGH%%',
    '%%redirect_esc%%': '%%CLICKTHROUGH_ESC%%',
    '%%clicktracker%%': '%%CLICKTRACKER%%',
    '%%clicktracker_esc%%': '%%CLICKTRACKER_ESC%%',
    "a: 'DBM',": "a: 'Engagefront',",
};

const contoBoxMacros = {
    '${CAMPAIGN_ID}': '%%CAMPAIGN_ID%%',
    '${EXCHANGE_ID}': '%%EXCHANGE_ID%%',
    '${UNIVERSAL_SITE_ID}': '',
    '${INVENTORY_SOURCE_EXTERNAL_ID}': '',
    '${CREATIVE_ID}': '%%CREATIVE_ID%%',
    '${CLICK_URL_ESC}': '%%CLICKTHROUGH_ESC%%',
    '${SOURCE_URL}': '%%SITE_DOMAIN%%',
};

const iabTFCMacros = {
    '${GDPR}': '0',
    '${GDPR_PD}': '',
    '${ADDTL_CONSENT}': '',
};

const googleCampaignManagerMacros = {
    '%%CLICK_URL_ESC%%': '%%CLICKTHROUGH_ESC%%',
    '%ebuy!': '%%CAMPAIGN_ID%%',
    '%eaid!': '%%AD_ID%%',
    '%ecid!': '%%CREATIVE_ID%%',
    '%%ADVERTISING_IDENTIFIER_PLAIN%%': '%%DEVICE_ID%%',
    '%%SITE%%': '%%SITE_DOMAIN%%',
    '%%PATTERN:lt%%': '%%LATITUDE%%',
    '%%PATTERN:ln%%': '%%LONGITUDE%%',
};

const thirdyPartyDSPMacros = {
    //Sizmek tags (Spain)
    '%%CLICK_URL_UNESC%%': '%%CLICKTHROUGH%%',
};

const dcmMacros = {
    "data-dcm-resettable-device-id=''": `data-dcm-resettable-device-id='%%DEVICE_ID%%'
    data-dcm-click-tracker='%%CLICKTHROUGH%%'`,
};

const facebookMacros = {
    '{{ad.id}}': '%%AD_ID%% ',
    '{{adset.id}}': '%%AD_ID%% ',
    '{{campaign.id}}': '%%CAMPAIGN_ID%% ',
    '{{ad.name}}': '%%AD_ID%% ',
    '{{adset.name}}': '%%AD_ID%% ',
    '{{campaign.name}}': '%%CAMPAIGN_ID%% ',
    '{{site_source_name}}': '%%SITE_DOMAIN%% ',
};

export const macroMapping = {
    ...genericMacros,
    ...contoBoxMacros,
    ...iabTFCMacros,
    ...googleCampaignManagerMacros,
    ...thirdyPartyDSPMacros,
    ...dcmMacros,
    ...facebookMacros,
};

export const replaceGDPRConsentMacro = input => {
    return input ? input.replace(/\$\{GDPR_CONSENT_([0-9]*)\}/gi, '') : input;
};

export function excludeGDPRMacrosForSpain() {
    const keys = Object.keys(macroMapping);
    const spainMapping = { ...macroMapping };

    _.forEach(keys, key => {
        if (_.includes(key, 'GDPR') || _.includes(key, 'ADDTL')) {
            delete spainMapping[key];
        }
    });
    return spainMapping;
}

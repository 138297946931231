import _ from 'lodash';
import Bacon from 'baconjs';
import toastr from 'toastr';

import c from 'common/constants/flux-events';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import { serializeForApi } from 'forms/ad-form/services';
import { getDefaults } from 'forms/ad-form/services';
import {
    DESKTOP_DEVICE_OS_OPTIONS,
    INAPP_DEVICES_OS_OPTIONS,
    CTV_DEVICE_OS_OPTIONS,
    DOOH_DEVICES_OS_OPTIONS,
} from 'forms/ad-form/services/default-values';
import Ads from 'states/resources/ads/actions';
import Campaigns from 'states/resources/campaigns/actions';
import Creatives from 'states/resources/creatives/actions';
import Organizations from 'states/resources/organizations/actions';
import UserActions from 'states/resources/users/actions';
import { revenueModelToBillingTerm } from 'states/resources/campaigns/business-logic';
import { getDistributedWeight } from 'states/resources/creatives/business-logic';
import { createHttp } from 'utils/http';
import { getEnvironmentSettings } from 'services/environment';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { getAllowedPlatformOptions } from 'states/resources/ads/business-logic';
import { PLATFORM_MAPPING } from 'common/constants/platforms';

const http = createHttp();

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

export const getAllLayers = async (dispatch, layerId) => {
    try {
        const http = createHttp();
        const query = `
                query getGeolayers($layerId: String) {
                    geolayers(filters: { layerId: $layerId }) { 
                        id  
                        name    
                        organization       
                        totalPoints
                        radius 
                        category
                        deprecated
                    }                            
                }   
            `;
        const variables = {
            layerId,
        };
        const data = await http.graphql(query, variables);

        if (layerId) {
            dispatch({
                type: c.SYSTEM__GEO_LAYER_POINTS__FETCH__SUCCESS,
                payload: {
                    layer: data.geolayers[0],
                },
            });
            return data[0];
        } else {
            dispatch({
                type: c.SYSTEM__GEO_LAYERS__FETCH__SUCCESS,
                payload: {
                    layers: data.geolayers,
                },
            });
            return data;
        }
    } catch (err) {
        console.error(err);
        return [];
    }
};

const openStream = new Bacon.Bus();
// A stream is required so that slow requests do not continue to execute when
// a form has closedthen re-opened.
openStream
    .flatMapLatest(({ dispatch, getState, campaignId, adId, done }) => {
        const adNewDraft = _.get(getState(), `adForm.form.draft`, undefined);

        const promise = Promise.all([dispatch(UserActions.get())])
            .then(() => {
                const organizationId = _.get(getState(), `profile.organizationId`);
                return Promise.all([
                    dispatch(Campaigns.get(campaignId)),
                    adNewDraft ? getLayersAndImplicitLayerPoints() : getAllLayers(dispatch),
                    fetchOrganizationWithGraphQL(organizationId),
                    // Creatives are required for creative selection
                    dispatch(Creatives.getAll(campaignId)),
                    // Ads are required when duplicating
                    dispatch(Ads.getAll(campaignId)),
                    dispatch(Organizations.get(organizationId)),
                ]);
            })
            .then(([campaign, points, organization]) => {
                const isAdFormOpen = _.get(getState(), `adForm.form.isOpen`);

                return {
                    campaignId,
                    campaign,
                    adId,
                    points,
                    dispatch,
                    isAdFormOpen,
                    getState,
                    done,
                    organization,
                };
            })
            .catch(reason => {
                throw new Error(reason);
            });

        return Bacon.fromPromise(promise, true);

        function getLayersAndImplicitLayerPoints() {
            return Promise.all([getAllLayers(dispatch)]).then(([layers]) => {
                const { custom_layers } = adNewDraft.geo_targeting_settings;
                const allImplicitLayers = _.filter(layers, layer => layer.name === '___implicit');
                const implicitLayerForGeoMap = _.filter(allImplicitLayers, layer =>
                    _.includes(custom_layers, layer.id)
                )[0];

                if (!implicitLayerForGeoMap) {
                    return;
                }
                return getAllLayers(dispatch, implicitLayerForGeoMap.id).then(
                    layer => {
                        const { points } = layer;

                        return points;
                    },
                    () => {
                        /* not impliment at this point */
                    }
                );
            });
        }
    })
    .onValue(
        ({
            campaignId,
            campaign,
            adId,
            points,
            dispatch,
            isAdFormOpen,
            getState,
            done,
            organization,
        }) => {
            if (isAdFormOpen) {
                // poi module
                const geoResources = _.get(getState(), `resources.geoCategories`);
                const organizationId = _.get(getState(), `profile.organizationId`);
                const ownOrganization = organization;

                const geoCategories = {
                    ...geoResources,
                    geoLayersForThisOrg: _.filter(
                        geoResources.geoLayers,
                        layer => layer.organization === organizationId
                    ),
                };

                const { campaign_budget_enabled, billing_rate, billing_term } = campaign;

                if (adId) {
                    dispatch(generateAdForDuplication(campaignId, adId)).then(adAttr_form => {
                        done({
                            campaignId,
                            campaign,
                            ad: adAttr_form,
                            geoCategories: geoCategories,
                            points,
                            ownOrganization,
                            duplicate: true,
                        });
                    });
                } else {
                    const defaultsDraft = { ...getDefaults('payload_formData') };

                    const environmentSettings = getEnvironmentSettings();
                    const defaultPlatformTargeting = environmentSettings.getDefaultPlatformTargets();

                    defaultsDraft.platforms = getAllowedPlatformOptions(campaign.type);
                    defaultsDraft.platform = defaultPlatformTargeting.platform;

                    if (campaign.type === CampaignTypeMapping.CTV) {
                        defaultsDraft.platform = PLATFORM_MAPPING[campaign.type].value;
                        defaultsDraft.target_device_os = CTV_DEVICE_OS_OPTIONS.map(os => os.value);
                    } else if (campaign.type === CampaignTypeMapping.DOOH) {
                        defaultsDraft.target_device_os = DOOH_DEVICES_OS_OPTIONS.map(os => os.value);
                    } else if (campaign.isCrossPlatformCampaign) {
                        const desktopDeviceOsValues = DESKTOP_DEVICE_OS_OPTIONS.map(os => os.value);
                        const inappDeviceOsValues = INAPP_DEVICES_OS_OPTIONS.map(os => os.value);
                        defaultsDraft.target_device_os = _.concat(
                            inappDeviceOsValues,
                            desktopDeviceOsValues
                        );
                    }

                    const draft = _.cloneDeep(defaultsDraft);

                    // We enforce the campaign budget settings here
                    if (campaign_budget_enabled) {
                        draft.ef_billing_terms = revenueModelToBillingTerm(campaign.revenueModel);
                        draft.billing_rate = billing_rate;

                        switch (billing_term) {
                            case 'billable_media_cost_margin':
                                draft.primary_pacing = 'billings';
                                break;
                            case 'billable_media_cost_markup':
                                draft.primary_pacing = 'spend';
                                break;
                        }
                    }

                    let start = '';
                    let end = '';

                    if (campaign.flightPacingStrategy === 'campaign') {
                        start = campaign.flights[0].start;
                        end = campaign.flights[campaign.flights.length - 1].end;
                    }

                    let fcaps = { ...draft.fcaps };
                    if (campaign.type === CampaignTypeMapping.DOOH) {
                        fcaps = {
                            imp: null,
                            interval_count: null,
                            interval_unit: null,
                            start: Date.now(),
                        }
                    }

                    const ad = {
                        ...draft,
                        timezone: campaign.default_timezone,
                        custom_fields: _.map(campaign.custom_fields, c => ({ ...c, value: '' })),
                        thirdPartyFees: campaign.third_party_fees,
                        start,
                        end,
                        fcaps,
                    };

                    ad.ias = {
                        ...ad.ias,
                        fraudPrevention: {
                            ...ad.ias.fraudPrevention,
                            risk: {
                                ...ad.ias.fraudPrevention.risk,
                                value: environmentSettings.getDefaultIasFraudPreventionRiskValue(),
                            },
                        },
                    };

                    if (environmentSettings.canUseFta()) {
                        if (campaign.fta_version === 2) {
                            if (campaign.fta_management_level === 'campaign') {
                                ad.fta_fee = campaign.fta_fee_standard;
                                ad.fta = {
                                    enabled: true,
                                    line_id: null,
                                    partner_id: campaign.fta_partner_id,
                                    location_list: campaign.fta_location_list,
                                };
                            }

                            if (campaign.fta_management_level === 'ad') {
                                ad.fta_fee = campaign.fta_fee_standard;
                                ad.fta = {
                                    enabled: true,
                                    line_id: null,
                                    partner_id: ownOrganization.fta_partner_id,
                                    location_list: null,
                                };
                            }
                        }
                    } else {
                        ad.fta = {
                            enabled: false,
                            line_id: null,
                            partner_id: null,
                            location_list: null,
                        };
                    }

                    done({
                        campaignId,
                        campaign,
                        ad,
                        rawAd: null,
                        geoCategories: geoCategories,
                        points,
                        deliveredImpressions: 0,
                        ownOrganization,
                    });
                }
            }
        }
    );

async function fetchOrganizationWithGraphQL(id) {
    const query = `
        query getOrganizationDataForAdNewForm($id: String) {
            organization(id: $id) {
                name
                tech_fee
                blacklistedAppsAndSites
                whitelistedAppsAndSites
                fta_partner_id
                ftaEnabled
                audience_rates {
                    name
                    fee
                }
                isIasPostBidVerificationEnabled
            }
        }
    `;

    const variables = {
        id,
    };
    try {
        const { organization } = await http.graphql(query, variables);
        return organization;
    } catch (error) {
        if (error.response.status === 401) {
            return;
        }
        toastr.warning(
            '',
            '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
            TOASTR_OPTIONS
        );
        if (window.bugsnagClient) {
            window.bugsnagClient.notify(`Failed to fetch organization data in Ad New Form`, {
                metaData: {
                    organizationId: `${id}`,
                },
            });
        }
    }
}

function generateAdForDuplication(campaignId, adId) {
    return async dispatch => {
        const [layers, ad] = await Promise.all([
            getAllLayers(dispatch),
            dispatch(Ads.get(campaignId, adId)),
        ]);

        const defaultsDraft = getDefaults('payload_formData');
        const adAttr = _.omit(ad, ['id']);

        // adAtrr has all the data relevent to an ad, but we only want data for the form.
        const defaultsDraft_keys = Object.keys(defaultsDraft);
        const adAttr_pickedForDraft = _.pick(adAttr, defaultsDraft_keys);

        // We handle creative rotation weights as whole numbers on the front end in order to avoid
        // float precision errors. We leave the store's ad resource untouched however. These weights
        // are converted to decimals in the serialization step of the ad form submission
        const adAttr_pickedForDraft_rotationRulesProcessed = _.cloneDeep(adAttr_pickedForDraft);

        _.each(adAttr_pickedForDraft_rotationRulesProcessed.tactics_generators, tactic => {
            tactic.id = _.uniqueId(Date.now());
        });

        if (
            _.includes(
                [
                    CreativeRotationMethodMapping.OptimizedForClicks,
                    CreativeRotationMethodMapping.OptimizedForConversions,
                    CreativeRotationMethodMapping.OptimizedForVideoCompletion,
                ],
                adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.mode
            )
        ) {
            const numberOfCreatives =
                adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.weighted.length;

            adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.weighted = _.map(
                adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.weighted,
                (rotationSetting, index) => {
                    const weight = getDistributedWeight({ index, size: numberOfCreatives });
                    return {
                        ...rotationSetting,
                        weight,
                    };
                }
            );
        } else {
            adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.weighted = _.map(
                adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.weighted,
                rotationSetting => ({
                    ...rotationSetting,
                    weight: _.floor(rotationSetting.weight * 100),
                })
            );
        }

        // We also want need to make sure to separate the implicit geolayer from its points, if one exists for this ad.
        // Failure to do so will make the one implicit layer apply to the source and duplicate ads, which is bad news.
        const geo_targeting_settings =
            adAttr_pickedForDraft_rotationRulesProcessed.geo_targeting_settings;

        const allImplicitLayers = _.filter(layers, layer => layer.name === '___implicit');
        const implicitLayerForGeoMap = _.filter(allImplicitLayers, layer =>
            _.includes(
                adAttr_pickedForDraft_rotationRulesProcessed.geo_targeting_settings.custom_layers,
                layer.id
            )
        );

        if (!implicitLayerForGeoMap || implicitLayerForGeoMap.length < 1) {
            const adAttr_form = {
                ...defaultsDraft, // so that attr form does not has missing field
                ...adAttr_pickedForDraft_rotationRulesProcessed,
            };

            return adAttr_form;
        }

        const implicitLayerId = implicitLayerForGeoMap[0].id;

        const customLayersWithoutImplicitLayer = _.filter(
            adAttr_pickedForDraft.geo_targeting_settings.custom_layers,
            layerId => layerId !== implicitLayerId
        );

        // Reconstruct the draft to exclude the implicit layer from `geo_targeting_settings.custom_layers`
        const adAttr_form = {
            ...defaultsDraft, // so that attr form does not have missing fields
            ...adAttr_pickedForDraft,
            geo_targeting_settings: {
                ...geo_targeting_settings,
                custom_layers: customLayersWithoutImplicitLayer,
            },
        };

        return adAttr_form;
    };
}

const AdNew = {
    open(campaignId, adId) {
        return (dispatch, getState) => {
            return new Promise(resolve => {
                openStream.push({
                    dispatch,
                    getState,
                    campaignId,
                    adId,
                    done: resolve,
                });
            });
        };
    },
    submitDraft(draft, campaignId) {
        if (!campaignId) {
            throw new Error('New Ad data not found');
        }

        return (dispatch, getState) => {
            dispatch({
                type: c.OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT,
                payload: { campaignId },
            });

            const adForm = _.get(getState(), `adForm.form`);
            const isCrossPlatformCampaign = _.get(adForm, 'isCrossPlatformCampaign');

            const payloadSerialized = serializeForApi({ draft, isCrossPlatformCampaign });

            return dispatch(Ads.create(campaignId, payloadSerialized)).then(
                response => {
                    const ad = response;

                    dispatch({
                        type: c.OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT_SUCCESS,
                        payload: { ad, campaignId },
                    });

                    fetchCampaign(campaignId)(dispatch, getState);
                },
                error => {
                    dispatch({
                        type: c.OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT_FAIL,
                        payload: { campaignId },
                        error: error.body,
                    });

                    return Promise.reject(error.body);
                }
            );
        };
    },
};



export const getGeocategories = async dispatch => {
    try {
        const http = createHttp();
        const query = `
            query getGeocategories($type: String) { 
                geocategories(filters: { type: $type }) { 
                    id  
                    name    
                    subcategories   {
                    id
                    name
                    layers {
                        id
                        name
                        totalPoints
                        public
                        organization
                        deprecated
                        }
                    }                            
                }            
            }
            `;
        const variables = {
            type: 'allCategories',
        };
        const data = await http.graphql(query, variables);
        dispatch({
            type: c.SYSTEM__GEO_CATEGORIES__FETCH__SUCCESS,
            payload: {
                categories: data.geocategories,
            },
        });
        return data;
    } catch (err) {
        console.error(err);
        return [];
    }
};

export default AdNew;

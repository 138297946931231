import _ from 'lodash';

import c from 'common/constants/flux-events';
import { serializeForApi } from './services';

import { fetchReportStats } from 'services/resources/stats';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import Beacons from 'states/resources/beacons/actions';
import { getEnvironmentSettings } from 'services/environment';

export function initializeBeaconDraft(campaignId) {
    return (dispatch, getState) => {
        const defaultTimezone = _.get(getState(), `resources.campaigns.${campaignId}.attr.default_timezone`);
        dispatch({
            type: c.OVERVIEW_BEACONS__INIT,
            payload: {
                campaignId: campaignId,
            },
        });

        Promise.all([
            fetchCampaign(campaignId)(dispatch, getState),
            fetchReportStats({
                campaign_id: campaignId,
                match: {},
                split: [],
                timezone: defaultTimezone,
                datasource: getEnvironmentSettings().getReportingDatasource(),
            }),
        ]).then(([campaign, statRoot]) => {
            dispatch({
                type: c.OVERVIEW_BEACONS__INIT_DRAFT,
                payload: {
                    beacons: campaign.beacons,
                    campaignId: campaignId,
                    statRoot,
                    campaign,
                },
            });
        });
    };
}

export function addBeacon(campaignId) {
    return {
        type: c.OVERVIEW_BEACONS__ADD,
        payload: { campaignId },
    };
}

export function deleteBeacon(beaconId, campaignId) {
    return {
        type: c.OVERVIEW_BEACONS__DELETE,
        payload: { campaignId, beaconId },
    };
}

export function editBeacon(campaignId, beaconId, fieldName, value) {
    return {
        type: c.OVERVIEW_BEACONS__EDIT,
        payload: { campaignId, beaconId, fieldName, value },
    };
}

export function promoteBeacon(beacon, priority) {
    return {
        type: c.OVERVIEW_BEACONS__PROMOTE_BEACON,
        payload: {
            beacon,
            priority,
        },
    };
}

export function demoteBeacon(beacon, priority) {
    return {
        type: c.OVERVIEW_BEACONS__DEMOTE_BEACON,
        payload: {
            beacon,
            priority,
        },
    };
}

export function saveBeacons(campaignId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_BEACONS__SAVE_TRY,
            payload: {
                campaignId,
            },
        });

        const beaconsDraft = _.get(getState(), `beaconsOverview.draft`);
        const beaconErrors = _.get(getState(), `beaconsOverview.errors`, []);

        if (!beaconsDraft) {
            return;
        }

        if (
            _(beaconErrors)
                .flatten()
                .some()
        ) {
            return;
        }

        const payload = {
            beacons: serializeForApi(beaconsDraft),
        };

        dispatch({
            type: c.OVERVIEW_BEACONS__SAVE,
            payload: {
                campaignId,
            },
        });

        dispatch(Beacons.create(campaignId, payload)).then(
            () => {
                dispatch({
                    type: c.OVERVIEW_BEACONS__SAVE_SUCCESS,
                    payload: {
                        campaignId,
                    },
                });

                fetchCampaign(campaignId)(dispatch, getState);
            },

            error => {
                console.error(error);
                dispatch({
                    type: c.OVERVIEW_BEACONS__SAVE_FAIL,
                    payload: {
                        campaignId,
                    },
                    error,
                });
            }
        );
    };
}

export function cancelEdit(campaignId) {
    return (dispatch, getState) => {
        return initializeBeaconDraft(campaignId)(dispatch, getState);
    };
}

export function openNewBeaconsView(campaignId) {
    return {
        type: c.OVERVIEW_BEACONS__OPEN_VIEW_NEW,
        payload: { campaignId },
    };
}

export function openManageBeaconsView(campaignId, existingBeacons) {
    return {
        type: c.OVERVIEW_BEACONS__OPEN_VIEW_MANAGE,
        payload: { campaignId, existingBeacons },
    };
}

export function fetchCampaignBeacons(campaignId) {
    return (dispatch) => {
        return dispatch(Beacons.getAll(campaignId)).then(
            response => {
                dispatch({
                    type: c.SYSTEM__CAMPAIGN_BEACONS__FETCH__SUCCESS,
                    payload: {
                        campaignId,
                        beacons: response,
                    },
                });
            },
            err => {
                console.error(err);
            }
        );
    };
}

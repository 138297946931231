import PropTypes from 'prop-types';
import React from 'react';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { InfoIcon } from 'widgets-v5/icons';
import classnames from 'classnames';

class InfoIconTooltip extends React.Component {
    static propTypes = {
        tooltip: PropTypes.node,
        tooltipTitle: PropTypes.string,
        position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
    };

    render() {
        return (
            <div className="ef5-info-icon">
                <InlineTooltip
                    className={classnames(this.props.className, 'ef5-info-icon__tooltip')}
                    position={this.props.position}
                    tooltipTitle={this.props.tooltipTitle}
                    tooltip={this.props.tooltip}
                >
                    <InfoIcon />
                </InlineTooltip>
            </div>
        );
    }
}

export default InfoIconTooltip;

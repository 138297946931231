import _ from 'lodash';

export function getDayOfMonthOptions() {
    const output = [];
    const firstDayOfMonth = 1;
    const lastAllowedDayOfMonth = 28;

    _.each(_.range(firstDayOfMonth, lastAllowedDayOfMonth + 1), dayNumber => {
        output.push({
            value: dayNumber,
            label: `${getOrdinalSuffix(dayNumber)} day of Month`,
        });
    });

    return output;
}

export function getOrdinalSuffix(num) {
    const int = parseInt(num),
        digits = [int % 10, int % 100],
        ordinals = ['st', 'nd', 'rd', 'th'],
        oPattern = [1, 2, 3, 4],
        tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
        ? int + ordinals[digits[0] - 1]
        : int + ordinals[3];
}

import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import numeral from 'numeral';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_wholeFixed,
} from 'utils/formatting';

import Splits from 'widgets/pivot-table/splits';

const useStyles = makeStyles(theme => ({
    totalRow: {
        backgroundColor: theme.palette.grey[200],
    },
}));

function formatNumber_whole(number) {
    if (window.isNaN(number)) {
        return '0';
    }
    return numeral(number).format('0,0');
}

function formatValue(metricValue, formatType) {
    switch (formatType) {
        case 'thousands':
            return formatNumber_whole(metricValue);

        case 'percentage':
            return formatNumber_percentage(metricValue);

        case 'dollar':
            return formatNumber_currency(metricValue);

        case 'whole-fixed':
            return formatNumber_wholeFixed(metricValue, 2);

        default:
            return metricValue;
    }
}

const { arrayOf, shape, string, any, object, func } = PropTypes;

export default class extends React.Component {
    static propTypes = {
        // pivotTable config is always managed by the PT service
        config: shape({
            splits: arrayOf(
                shape({
                    label: string,
                    value: any,
                })
            ),
            dimensions: arrayOf(
                shape({
                    label: string,
                    value: any,
                })
            ),
            tree: object,
        }),

        // props below can be set by any source (metric selector, report dictionary, etc)
        dictionary: object,
        columns: arrayOf(
            shape({
                label: string,
                value: any,
                order: any,
            })
        ),
        topbarWorkspace: any,
        renderCell: func,

        filterRow: func,
        sortByHook: func,

        onSort: func,
        onExpand: func,
        onCollapse: func,
        onAddSplit: func,
        onRemoveSplit: func,
    };

    static defaultProps = {
        filterRowHook(filterRow, row, columns) {
            return filterRow(row, columns);
        },
        sortByHook: (sortBy, columnValue, row, dictionary) => sortBy(columnValue, row, dictionary),
        onRenderFormatRow(row) {
            return row;
        },
        onSerializeFormatRow(row) {
            return row;
        },
        renderCell(content) {
            return content;
        },
        timezone: 'UTC',
    };

    filterRow = columns => {
        return row => {
            const _filterRow = () => {
                return true;
            };

            return this.props.filterRowHook(_filterRow, row, columns);
        };
    };

    applySorters = (columnName, row) => {
        const { dictionary } = this.props;

        const _applySorters = (columnName, row, dictionary) => {
            const columnValue = row.stats[columnName];

            switch (columnName) {
                case 'dimension': {
                    const split = _.last(row.split);
                    if (!split) {
                        return columnValue;
                    }

                    // Sort 'Not Available' rows last
                    if (row.columnData.dimension.indexOf('Not Available') > -1) {
                        return 'zzzzzzzzzz';
                    }

                    // Sort 'Other' as second last row
                    if (row.columnData.dimension.indexOf('Other') > -1) {
                        return 'zzzzzzzz';
                    }

                    return _.get(
                        dictionary,
                        `${split.dimension}.${split.group}.order`,
                        row.columnData.dimension
                    );
                }
                default: {
                    return columnValue;
                }
            }
        };

        return this.props.sortByHook(_applySorters, columnName, row, dictionary);
    };

    getColumnSorters = () => {
        return {
            ...this.state.columnSorters,
            ..._.get(this.props, 'columnSorters', {}),
        };
    };

    getVisibleColumns = () => {
        return _.filter(this.props.columns, c => c.status !== 'hidden');
    };

    removeSplitsFromDimensions = () => {
        const {
            config: { splits, dimensions },
        } = this.props;
        const splitsIndex = {};
        _.each(splits, split => (splitsIndex[split.name] = true));

        const dimensionsWithoutSplits = _(dimensions)
            // Remove dimensions that have been selected
            .filter(dimension => !splitsIndex[dimension.name])
            .filter(dimension => {
                // Remove dimensions that have all children selected
                if (dimension.children) {
                    return _.filter(dimension.children, c => !splitsIndex[c.name]).length > 0;
                }

                return true;
            })
            .map(dimension => {
                return {
                    ...dimension,
                    // Remove children that have been selected
                    children: _.filter(dimension.children, c => !splitsIndex[c.name]),
                };
            })
            .value();

        return dimensionsWithoutSplits;
    };

    getRows = () => {
        const { onRenderFormatRow } = this.props;
        // always add the row
        const shouldAddRow = () => true;

        // do not add children if the row is collapsed
        const shouldIgnoreChildren = row => !row.isExpanded;

        const { totals, rows } = this.treeToRows(
            shouldAddRow,
            shouldIgnoreChildren,
            onRenderFormatRow
        );

        return {
            totals,
            // remove head which is the same as totals
            rows: rows.slice(1),
        };
    };

    serialize = () => {
        const {
            onSerializeFormatRow,
            config: { splits },
        } = this.props;
        const columns = this.getVisibleColumns();
        const lastSplit = _.last(splits);

        // only add leaf nodes
        const shouldAddRow = row => row.dimension === lastSplit.name;
        // never exit, go through all nodes
        const shouldIgnoreChildren = () => false;

        const { totals, rows } = this.treeToRows(
            shouldAddRow,
            shouldIgnoreChildren,
            onSerializeFormatRow
        );

        return {
            splits,
            columns,
            totals: _.omit(totals.columnData, ['stats']),
            rows: _.map(rows, row => row.columnData),
        };
    };

    treeToRows = (shouldAddRow, shouldIgnoreChildren, formatRow) => {
        const {
            config: { tree, sort },
        } = this.props;
        const columns = this.getVisibleColumns();

        const rows = [];
        const walk = row => {
            if (!row) {
                return;
            }

            if (shouldAddRow(row)) {
                rows.push(row);
            }

            if (shouldIgnoreChildren(row)) {
                return;
            }

            _(row.children)
                .map(id => tree[id])
                .map(row => formatRow(row, columns))
                .filter(this.filterRow(columns))
                .orderBy(row => this.applySorters(sort.column, row), sort.order)
                .each(child => walk(child));
        };

        walk(tree.root);

        // first element is total
        let totals = {};
        if (tree.root) {
            totals = formatRow(tree.root, columns);
        }

        return {
            totals,
            rows,
        };
    };

    render() {
        const {
            config: { splits, sort },
            hideSplits,
            renderCell,
            topbarWorkspace,

            onAddSplit,
            onRemoveSplit,
            onUpdateSplits,
            onSort,
            onExpand,
            onCollapse,
            hidden,
            subheaders,
            conversionNameMapping,
            selectedConversions,
            allConversions,
            showConversions,
        } = this.props;
        const columns = this.getVisibleColumns();

        const { totals, rows } = this.getRows();

        const dimensionsFiltered = this.removeSplitsFromDimensions();
        const hasDimensionsAvailable = dimensionsFiltered.length > 0;

        return (
            <div className="am-pivotTable" style={{ display: hidden ? 'none' : 'block' }}>
                <Paper>
                    <AppBar position="static" color="inherit" elevation={0}>
                        <Box my={1}>
                            <Toolbar>
                                <Grid container spacing={1} justifyContent="space-between">
                                    {!hideSplits && (
                                        <Grid>
                                            <Splits
                                                hasDimensionsAvailable={hasDimensionsAvailable}
                                                splits={splits}
                                                onRemoveSplit={onRemoveSplit}
                                                onAddSplit={onAddSplit}
                                                dimensionsFiltered={dimensionsFiltered}
                                                onUpdateSplits={onUpdateSplits}
                                            />
                                        </Grid>
                                    )}
                                    {typeof topbarWorkspace === 'function' ? (
                                        <Grid item>{topbarWorkspace(this.serialize)}</Grid>
                                    ) : (
                                        <Grid item>{topbarWorkspace}</Grid>
                                    )}
                                </Grid>
                            </Toolbar>
                        </Box>
                    </AppBar>
                    <Box width="100%" overflow="auto">
                        <Table stickyHeader className="am-pivotTable-table" size="small">
                            <TableHead>
                                {showConversions ? (
                                    <React.Fragment>
                                        <HeadersWithConversions
                                            columns={columns}
                                            subheaders={subheaders}
                                            onSort={onSort}
                                            sort={sort}
                                        />
                                        {subheaders.length > 0 && (
                                            <Subheaders columns={subheaders} />
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Headers columns={columns} onSort={onSort} sort={sort} />
                                )}
                            </TableHead>
                            <TableBody>
                                {this.props.message && (
                                    <TableRow className={this.props.messageClass}>
                                        <TableCell colSpan={columns.length}>
                                            {this.props.message}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {showConversions ? (
                                    <TotalRowWithConversions
                                        columns={columns}
                                        totals={totals}
                                        subheaders={subheaders}
                                        selectedConversions={selectedConversions}
                                        allConversions={allConversions}
                                    />
                                ) : (
                                    <TotalRow columns={columns} totals={totals} />
                                )}
                                {showConversions ? (
                                    <React.Fragment>
                                        {_.map(rows, row => (
                                            <BodyRowWithConversions
                                                row={row}
                                                columns={columns}
                                                key={row.id}
                                                onExpand={onExpand}
                                                onCollapse={onCollapse}
                                                subheaders={subheaders}
                                                conversionNameMapping={conversionNameMapping}
                                                selectedConversions={selectedConversions}
                                            />
                                        ))}
                                    </React.Fragment>
                                ) : (
                                    _.map(rows, row => (
                                        <BodyRow
                                            row={row}
                                            columns={columns}
                                            key={row.id}
                                            renderCell={renderCell}
                                            onExpand={onExpand}
                                            onCollapse={onCollapse}
                                        />
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            </div>
        );
    }
}

class Headers extends React.Component {
    getSortIcon = column => {
        const { sort } = this.props;

        // placeholder icon
        if (column.name !== sort.column) {
            return (
                <i style={{ visibility: 'hidden' }} className="fa fa-fw am-pivotTable__sort-icon" />
            );
        }

        switch (sort.order) {
            case 'desc':
                return <i className="fa fa-fw fa-caret-down am-pivotTable__sort-icon" />;
            case 'asc':
                return <i className="fa fa-fw fa-caret-up am-pivotTable__sort-icon" />;
        }
    };

    render() {
        const { onSort, columns } = this.props;
        return (
            <TableRow>
                {_.map(columns, column => (
                    <TableCell
                        className="am-pivotTable-header"
                        key={column.name}
                        onClick={() => onSort(column)}
                    >
                        {column.label}
                        {this.getSortIcon(column)}
                    </TableCell>
                ))}
            </TableRow>
        );
    }
}

const HeadersWithConversions = ({ sort, onSort, columns, subheaders }) => {
    const getSortIcon = column => {
        if (column.name === 'conversions') {
            return '';
        }

        if (column.name !== sort.column) {
            return (
                <i style={{ visibility: 'hidden' }} className="fa fa-fw am-pivotTable__sort-icon" />
            );
        }

        switch (sort.order) {
            case 'desc':
                return <i className="fa fa-fw fa-caret-down am-pivotTable__sort-icon" />;
            case 'asc':
                return <i className="fa fa-fw fa-caret-up am-pivotTable__sort-icon" />;
        }
    };

    let processedColumns = columns;

    const columnsHaveConversions =
        _.filter(_.map(columns, ({ name }) => name), name => _.includes(name, 'conv_')).length > 0;

    if (columnsHaveConversions) {
        const conversionColumnCount = _.filter(
            subheaders,
            ({ isConversionHeader }) => isConversionHeader
        ).length;
        processedColumns = _.filter(columns, column => !_.includes(column.name, 'conv_'));
        processedColumns = _.concat(processedColumns, {
            name: 'conversions',
            colSpan: conversionColumnCount,
            label: 'Conversions',
        });
    }

    return (
        <TableRow>
            {_.map(processedColumns, column => {
                let columnsAlignment;

                if (column.label === 'Conversions') {
                    columnsAlignment = 'center';
                } else if (column.label === 'Dimensions') {
                    columnsAlignment = 'left';
                } else {
                    columnsAlignment = 'right';
                }

                return (
                    <TableCell
                        key={column.name}
                        onClick={() => onSort(column)}
                        colSpan={column.colSpan}
                        align={columnsAlignment}
                    >
                        {column.label}
                        {getSortIcon(column)}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

const Subheaders = ({ columns }) => {
    return (
        <TableRow>
            {_.map(columns, ({ name, label, isEmpty }) => (
                <TableCell align="right" key={name}>
                    {isEmpty ? '' : label}
                </TableCell>
            ))}
        </TableRow>
    );
};

function TotalRow(props) {
    const classes = useStyles();
    const { columns, totals } = props;
    return (
        <TableRow className={classes.totalRow}>
            {_.map(columns, column => (
                <TableCell
                    align={column.label === 'Dimensions' ? 'left' : 'right'}
                    key={column.name}
                >
                    <div className="cell-content">
                        {_.get(totals, `columnData.${column.name}`, '')}
                    </div>
                </TableCell>
            ))}
        </TableRow>
    );
}

const TotalRowWithConversions = ({
    columns,
    totals,
    subheaders,
    allConversions,
}) => {
    const classes = useStyles();

    const processedColumns = _.concat(
        _.filter(columns, ({ name }) => !_.includes(name, 'conv_')),
        _.filter(subheaders, ({ name }) => _.includes(name, 'conv_'))
    );

    const getFilteredConversions = ({ conversions, target, variations }) => {
        return _.filter(conversions, conversion => {
            let hasVariation = false;

            _.each(variations, variation => {
                if (_.includes(conversion, variation)) {
                    hasVariation = true;
                    return false;
                }
            });

            return _.includes(conversion, target) && !hasVariation;
        });
    };

    const getColumnData = ({ name }) => {
        if (name === 'conv_header_events') {
            return '';
        }

        if (name === 'conv_header_total_cost_ecpa') {
            if (_.includes(allConversions, 'conv_overall_total_cost_ecpa')) {
                return formatValue(
                    numeral(_.get(totals, `columnData.conv_overall_total_cost_ecpa`, 0)).value(),
                    'dollar'
                );
            }

            let output = 0;
            _.each(allConversions, conversion => {
                if (_.includes(conversion, 'conv_total_cost_ecpa_')) {
                    output += numeral(_.get(totals, `columnData.${conversion}`, 0)).value();
                }
            });
            return formatValue(output, 'dollar');
        }

        if (name === 'conv_header_revenue_ecpa') {
            if (_.includes(allConversions, 'conv_overall_revenue_ecpa')) {
                return formatValue(
                    numeral(_.get(totals, `columnData.conv_overall_revenue_ecpa`, 0)).value(),
                    'dollar'
                );
            }

            let output = 0;
            _.each(allConversions, conversion => {
                if (_.includes(conversion, 'conv_revenue_ecpa_')) {
                    output += numeral(_.get(totals, `columnData.${conversion}`, 0)).value();
                }
            });
            return formatValue(output, 'dollar');
        }

        if (name === 'conv_header_click') {
            if (_.includes(allConversions, 'conv_overall_click')) {
                return _.get(totals, `columnData.conv_overall_click`, 0);
            }

            let output = 0;
            _.each(allConversions, conversion => {
                if (_.includes(conversion, 'conv_total_click_')) {
                    output += numeral(_.get(totals, `columnData.${conversion}`, 0)).value();
                }
            });
            return formatValue(output, 'thousands');
        }

        if (name === 'conv_header_imp') {
            if (_.includes(allConversions, 'conv_overall_imp')) {
                return _.get(totals, `columnData.conv_overall_imp`, 0);
            }

            let output = 0;
            _.each(allConversions, conversion => {
                if (_.includes(conversion, 'conv_total_imp_')) {
                    output += numeral(_.get(totals, `columnData.${conversion}`, 0)).value();
                }
            });
            return formatValue(output, 'thousands');
        }

        if (name === 'conv_header_total') {
            if (_.includes(allConversions, 'conv_overall')) {
                return _.get(totals, `columnData.conv_overall`, 0);
            }

            let output = 0;
            _.each(
                getFilteredConversions({
                    conversions: allConversions,
                    target: 'conv_total_',
                    variations: ['conv_total_click_', 'conv_total_imp_', 'conv_total_cost_ecpa'],
                }),
                conversion => {
                    output += numeral(_.get(totals, `columnData.${conversion}`, 0)).value();
                }
            );
            return formatValue(output, 'thousands');
        }

        return _.get(totals, `columnData.${name}`, '');
    };

    return (
        <TableRow className={classes.totalRow}>
            {_.map(processedColumns, column => (
                <TableCell
                    align={column.label === 'Dimensions' ? 'left' : 'right'}
                    key={column.name}
                >
                    <div className="cell-content">{getColumnData(column)}</div>
                </TableCell>
            ))}
        </TableRow>
    );
};

const BodyRowWithConversions = ({
    row,
    columns,
    onExpand,
    onCollapse,
    subheaders,
    conversionNameMapping,
    selectedConversions,
}) => {
    const expandOrCollapse = row => {
        switch (row.isExpanded) {
            case undefined:
                return;
            case true:
                return onCollapse(row.id);
            case false:
                return onExpand(row.id);
        }
    };

    const getExpandedIcon = (row, columnIndex) => {
        if (columnIndex > 0) {
            return;
        }

        switch (row.isExpanded) {
            case undefined:
                return <i className="fa fa-fw" />;
            case true:
                return <i className="fa fa-fw fa-minus-square-o" />;
            case false:
                return <i className="fa fa-fw fa-plus-square-o" />;
        }
    };

    const processedColumns = _.concat(
        _.filter(columns, ({ name }) => !_.includes(name, 'conv_')),
        _.filter(subheaders, ({ name }) => _.includes(name, 'conv_'))
    );

    const getColumnData = (row, column, conversion) => {
        if (conversion) {
            if (column.name === 'conv_header_events') {
                return conversionNameMapping[conversion];
            }

            if (conversion === 'conv_overall' && _.includes(column.name, 'conv_')) {
                return row.columnData[`${column.overallMapping}`];
            }

            if (_.includes(column.name, 'conv_')) {
                return row.columnData[`${column.eventPrefix}${conversion}`];
            }
        }

        return row.columnData[column.name];
    };

    const selectedConversionsWithRowHeaders = getSelectedConversionsWithRowHeaders(
        selectedConversions
    );
    return (
        <React.Fragment>
            {selectedConversionsWithRowHeaders.length > 0 ? (
                _.map(selectedConversionsWithRowHeaders, (conversion, rowIndex) => {
                    return (
                        <TableRow
                            className={cn({ 'is-expandable': row.isExpanded !== undefined })}
                            onClick={() => expandOrCollapse(row)}
                        >
                            {_.map(processedColumns, (column, columnIndex) => (
                                <TableCell
                                    align={column.align === 'Dimensions' ? 'left' : 'right'}
                                    className={cn({
                                        [`am-pivotTable-dimensionValue is-split-level-${
                                            row.depth
                                        }`]: true,
                                        'am-pivotTable-rowHeader':
                                            column.name === 'conv_header_events' &&
                                            _.includes(
                                                ['advertiser_row_header', 'conversion_row_header'],
                                                conversion
                                            ),
                                    })}
                                    key={column.name}
                                >
                                    {rowIndex > 0 && !_.includes(column.name, 'conv_') ? (
                                        ''
                                    ) : (
                                        <React.Fragment>
                                            {getExpandedIcon(row, columnIndex, conversion)}
                                            {getColumnData(row, column, conversion)}
                                        </React.Fragment>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })
            ) : (
                <TableRow
                    className={cn({ 'is-expandable': row.isExpanded !== undefined })}
                    onClick={() => expandOrCollapse(row)}
                >
                    {_.map(processedColumns, (column, columnIndex) => (
                        <TableCell
                            align={column.label === 'Dimensions' ? 'left' : 'right'}
                            className={`am-pivotTable-dimensionValue is-split-level-${row.depth}`}
                            key={column.name}
                        >
                            {getExpandedIcon(row, columnIndex)}
                            {getColumnData(row, column)}
                        </TableCell>
                    ))}
                </TableRow>
            )}
        </React.Fragment>
    );
};

class BodyRow extends React.Component {
    expandOrCollapse = row => {
        switch (row.isExpanded) {
            case undefined:
                return;
            case true:
                return this.props.onCollapse(row.id);
            case false:
                return this.props.onExpand(row.id);
        }
    };

    getExpandedIcon = (row, columnIndex) => {
        // Only allow icons for the first column
        if (columnIndex > 0) {
            return;
        }

        switch (row.isExpanded) {
            case undefined:
                return <i className="fa fa-fw" />;
            case true:
                return <i className="fa fa-fw fa-minus-square-o" />;
            case false:
                return <i className="fa fa-fw fa-plus-square-o" />;
        }
    };

    // shouldComponentUpdate (nextProps) {
    //     const { row, columns } = this.props;
    //     return (
    //         columns.length !== nextProps.columns.length ||
    //         row.id !== nextProps.row.id ||
    //         row.isExpanded !== nextProps.row.isExpanded
    //     );
    // },
    render() {
        const { row, columns, renderCell } = this.props;

        return (
            <TableRow
                className={cn({ 'is-expandable': row.isExpanded !== undefined })}
                onClick={() => this.expandOrCollapse(row)}
            >
                {_.map(columns, (column, columnIndex) => (
                    <TableCell
                        className={`am-pivotTable-dimensionValue is-split-level-${row.depth}`}
                        key={column.name}
                    >
                        {this.getExpandedIcon(row, columnIndex)}
                        {renderCell(row.columnData[column.name], row, column)}
                    </TableCell>
                ))}
            </TableRow>
        );
    }
}

function getSelectedConversionsWithRowHeaders(selectedConversions) {
    const overallConversions = _.filter(
        selectedConversions,
        conversionName => conversionName === 'conv_overall'
    );

    let advertiserConversions = _.filter(selectedConversions, conversionName =>
        _.includes(conversionName, 'adv_')
    );
    if (advertiserConversions.length > 0) {
        advertiserConversions = [...advertiserConversions];
    }

    let conversions = _.filter(
        selectedConversions,
        conversionName =>
            !_.includes(conversionName, 'adv_') && !_.includes(conversionName, 'conv_')
    );

    if (conversions.length > 0) {
        conversions = [...conversions];
    }

    const selectedConversionsWithHeaders = _.concat(
        overallConversions,
        advertiserConversions,
        conversions
    );

    return selectedConversionsWithHeaders;
}

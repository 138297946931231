import _ from 'lodash';
import 'moment';
import moment from 'moment-timezone';
import smartQuery from 'utils/smart-query';
import {
    isCpcAd,
    checkIsNewScheduledWeightedRotationMode,
    isArchivedAd,
    getTotalTarget,
} from 'states/resources/ads/business-logic';
import { hasInsTag } from 'states/resources/creatives/business-logic';
import {
    calculateThirdPartyFees as calculateAdThirdPartyFees,
    calculateFirstPartyDataFee,
    calculateBidPrice,
    calculatePlatformTechFee,
    calculateMediaCost,
} from 'states/resources/ads/business-logic';

import {
    getDeviceOsWarning,
    getCarrierISPWarning,
} from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

import { getTacticWarnings, processCreativeWarning } from 'forms/ad-form/reducer';
import { getHardSeverityTacticWarnings } from 'forms/ad-form/actions';
import { calculateConvertedDealPrice, shouldShowWarning } from 'forms/ad-form/v2/selectors';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

const comparator_decend = (a, b) => {
    if (a > b) {
        return -1;
    } else if (a < b) {
        return 1;
    } else {
        return 0;
    }
};

const comparator_accend = (a, b) => {
    if (a > b) {
        return 1;
    } else if (a < b) {
        return -1;
    } else {
        return 0;
    }
};

const getCreativeRotationByWeatherSummary = ({ settings, creativeMapping, campaignId }) => {
    return _.uniqBy(settings, 'markupId').map(setting => ({
        ...setting,
        creative: {
            markupId: setting.markupId,
            name: _.get(creativeMapping, `${setting.markupId}.name`),
            link: `/campaigns/${campaignId}/setup/creatives#${setting.markupId}`,
        },
    }));
};

/**
 * Get the unapproved creatives from an ad so that we can inform the user
 * that he needs to take action.
 */
const getUnapprovedCreatives = (creative, resource) => {
    let result = [];
    if (creative && resource) {
        _.each(creative, currentCreativeId => {
            if (!resource[currentCreativeId]) {
                return;
            }
            if (resource[currentCreativeId].isArchived) {
                return;
            }
            if (resource[currentCreativeId].audit_status !== 'approved') {
                result.push({ ...resource[currentCreativeId] });
            }
        });
    }

    return result;
};


const sortMechanism = {
    byDateCreated: function(ads) {
        let _out = ads;

        // Recent create has id with greater number value
        _out = ads.sort((a, b) => {
            return comparator_decend(+a.id, +b.id);
        });
        return _out;
    },
    byEndDate: function(ads) {
        let _out = ads;
        const nowIso = new Date().toISOString();

        // Sort into three bins
        const ads_future = [];
        const ads_past = [];
        const ads_now = [];

        ads.forEach(ad => {
            if (nowIso > ad.end) ads_past.push(ad);
            if (nowIso < ad.end) ads_future.push(ad);
            if (nowIso === ad.end) ads_now.push(ad);
        });

        // Sort again for future and past bin
        const ads_future_sorted = ads_future.sort((a, b) => {
            return comparator_accend(a.end, b.end);
        });
        const ads_past_sorted = ads_past.sort((a, b) => {
            return comparator_decend(a.end, b.end);
        });

        // Concat all bins together
        _out = [].concat(ads_future_sorted, ads_now, ads_past_sorted);

        return _out;
    },
};

export const doesHaveSetupWarnings = ({
    ad,
    creativeMapping,
    audienceMapping,
    flexSegmentMapping,
    deals,
    campaign,
}) => {
    const deviceOsWarning = getDeviceOsWarning({
        selectedDevices: ad.target_device_os,
        platforms: ad.platforms,
    });
    const carrierIspWarning = getCarrierISPWarning({
        includedCarriers: ad.target_carriers,
        platforms: ad.platforms,
        excludedCarriers: [],
    });
    const creativeWarning = processCreativeWarning({
        creativeIds: ad.creative,
        rotation_rules: ad.rotation_rules,
        creativeMapping,
        adPlatforms: ad.platforms,
    });
    const tacticWarnings = getTacticWarnings({
        tactics: ad.tactics_generators,
        audienceMapping,
        flexSegmentMapping,
        adPlatforms: ad.platforms,
    });

    const overrideDealPriceWarning = getOverrideDealPriceWarning({ ad, campaign, deals });
    const hardTacticWarnings = getHardSeverityTacticWarnings({ tacticWarnings });

    const hasTargetingWarnings = [deviceOsWarning, carrierIspWarning, creativeWarning].some(
        warning => warning.hasWarning && warning.severity === 'hard'
    );
    const hasTacticWarnings = !!hardTacticWarnings.length;

    return hasTargetingWarnings || hasTacticWarnings || overrideDealPriceWarning;
};

function getOverrideDealPriceWarning({ ad, campaign, deals }) {
    if (!campaign || !deals) {
        return false;
    }

    const techFee = campaign.tech_fee;
    const maxCpmRate =
        ad.bid_strategy_mode === 'fixed_bid_price'
            ? ad.max_cpm_rate_local
            : ad.automatic_bid_price.max_ecpm_local;
    const thirdPartyFees = calculateAdThirdPartyFees(ad);
    const firstPartyFees = calculateFirstPartyDataFee(ad);
    const mediaCost = calculateMediaCost({ maxCpmRate, thirdPartyFees, firstPartyFees });
    const platformTechFee = calculatePlatformTechFee({ mediaCost, techFee });
    const netBidPrice = calculateBidPrice({ mediaCost, platformTechFee });

    const dealsOfAd = _.filter(
        deals,
        deal =>
            _.includes(deal.targeting.target.ad_id, _.toNumber(ad.id)) ||
            _.includes(deal.targeting.deal_only.ad_id, _.toNumber(ad.id))
    );

    const campainCurrency = campaign.currency;
    const rate = campaign.currency_rate;
    const dealsWithConvertedPrice = calculateConvertedDealPrice(dealsOfAd, campainCurrency, rate);
    const showWarning = shouldShowWarning(dealsWithConvertedPrice, netBidPrice);

    return showWarning;
}

export default function selector(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, 'adsOverviewV2.campaign');
    const deals = _.get(storeState, 'adsOverviewV2.deals');
    const isInitialized = _.get(storeState, 'adsOverviewV2.isInitialized');
    const campaignCurrencyType = _.get(campaign, 'currency', 'USD');
    const orgTimezone = _.get(storeState, 'profile.organizationTimezone', 'UTC');

    if (!campaign) {
        return {
            ads: [],
        };
    }

    const creativeMapping = {};
    _.forEach(campaign.creatives, creative => {
        creativeMapping[creative.id] = creative;
    });

    const { isCrossPlatformCampaign } = campaign;
    const audiences = _.get(storeState, 'audienceSegmentPicker.audienceSegments', {});
    const flexSegments = _.get(storeState, 'audienceSegmentPicker.flexSegments', {});
    const hasAudienceSegmentsData = audiences.length > 0 && flexSegments.length > 0;

    const audienceMapping = {};
    const flexSegmentMapping = {};

    _.forEach(audiences, segment => {
        audienceMapping[segment.id] = segment;
    });

    _.forEach(flexSegments, segment => {
        flexSegmentMapping[segment.id] = segment;
    });

    const pagination = _.get(storeState, `adsOverview`);
    const hiddenColumns = _.get(storeState, `adsOverview.hiddenColumns`);
    const selectedAds = _.get(storeState, `adsOverview.selectedAds`, []);
    const adsBeingProcessed = _.get(storeState, `adsOverview.adsBeingProcessed`);
    const fetchingHiddenColumns = _.get(storeState, `adsOverview.fetchingHiddenColumns`);
    const shouldShowArchivedAds = _.get(storeState, `adsOverview.shouldShowArchivedAds`);
    const campaignDataShowing =
        _.get(storeState, 'campaignPageContainer.viewBy') === 'campaign' ? 'campaign' : 'flight';
    const ads = _.map(campaign.ads)
        .filter(ad => {
            if (shouldShowArchivedAds) {
                return true;
            }

            return !isArchivedAd(ad);
        })
        .map(ad => {
            const goal = ad.metadata.progress ? ad.metadata.progress.progressData.goal : '';

            const maxTotalDelivery = getTotalTarget(ad);

            const muted = campaign.paused || ad.status === 'ended';

            let creativeIds;
            if (ad.rotation_rules.mode === CreativeRotationMethodMapping.Scheduled) {
                creativeIds = _.map(ad.rotation_rules.scheduled, s => s.markup_id);
            } else {
                creativeIds = ad.creative;
            }

            const creatives = _.map(creativeIds, (id, creativeIndex) => {
                const creative = creativeMapping[id];
                if (!creative) {
                    return { id };
                }

                let scheduledRotation;
                if (ad.rotation_rules.mode === CreativeRotationMethodMapping.Scheduled) {
                    const rule = ad.rotation_rules.scheduled[creativeIndex];

                    const isActive = moment.utc().isBetween(rule.start, rule.end);

                    scheduledRotation = {
                        start: moment.tz(rule.start, ad.timezone).format('LLLL'),
                        end: moment.tz(rule.end, ad.timezone).format('LLLL'),
                        isEnded: moment.tz(rule.end, ad.timezone).isBefore(moment.tz(ad.timezone)),
                        isActive,
                    };
                }

                return {
                    ...creative,
                    hasCpcInsConflict: isCpcAd(ad) && hasInsTag(creative),
                    scheduledRotation,
                };
            });

            const hasCpcInsConflict = _.some(creatives, c => c.hasCpcInsConflict === true);

            let scheduledWeightedRotationSummary, creativeRotationByWeatherSummary;
            if (checkIsNewScheduledWeightedRotationMode(ad.rotation_rules)) {
                scheduledWeightedRotationSummary = getScheduledWeightedRotationSummary({
                    ad: ad,
                    creativeMapping,
                    campaignId,
                });
            }

            if (ad.rotation_rules.mode === CreativeRotationMethodMapping.Weather) {
                creativeRotationByWeatherSummary = getCreativeRotationByWeatherSummary({
                    settings: ad.rotation_rules.weather,
                    creativeMapping,
                    campaignId,
                });
            }

            let hasSetupWarnings = false;
            if (hasAudienceSegmentsData && isCrossPlatformCampaign) {
                hasSetupWarnings = doesHaveSetupWarnings({
                    ad: ad,
                    creativeMapping,
                    audienceMapping,
                    flexSegmentMapping,
                    deals,
                    campaign,
                });
            }

            const adMetrics =
                campaignDataShowing === 'campaign'
                    ? _.get(ad, ['metadata', 'metrics'], {})
                    : _.get(ad, ['flight', 'metadata', 'metrics'], {});
            const adMetricsDaily =
                campaignDataShowing === 'campaign'
                    ? _.get(ad, ['metadata', 'metricsDaily'], {})
                    : _.get(ad, ['flight', 'metadata', 'metricsDaily'], {});
            const adStart =
                campaignDataShowing === 'campaign' ? ad.start : _.get(ad, ['flight', 'start'], {});
            const adEnd =
                campaignDataShowing === 'campaign' ? ad.end : _.get(ad, ['flight', 'end'], {});
            return {
                ...ad,
                id: ad.id,
                name: ad.name,
                hasCpcInsConflict,
                creativeRotationByWeatherSummary,
                scheduledWeightedRotationSummary,
                creatives,
                unapprovedCreatives: getUnapprovedCreatives(ad.creative, creativeMapping),
                healthStatus: ad.metadata.progress ? ad.metadata.progress.progressData.health : '',
                status: ad.status,
                stats: {
                    ...adMetrics,
                    daily: {
                        ...adMetricsDaily,
                    },
                },
                start: adStart,
                end: adEnd,
                paused: ad.paused,
                goal,
                maxTotalDelivery,
                muted,
                maxDailyTotals: {
                    max_daily_clicks: ad.max_daily_clicks,
                    max_daily_impressions: ad.max_daily_impressions,
                    max_daily_spend_local: ad.max_daily_spend_local,
                },
                maxTotals: {
                    max_total_clicks: ad.max_total_clicks,
                    max_total_impressions: ad.max_total_impressions,
                    max_total_spend_local: ad.max_total_spend_local,
                },
                isStale: ad.isStale,
                isDelivering: ad.isDelivering,
                totalSpend: ad.max_bid_cpm_local,
                billingTerms: ad.ef_billing_terms,
                unalteredDuplicate: ad.unalteredDuplicate,
                isStartSet: ad.isStartSet,
                isEndSet: ad.isEndSet,
                isUsingFrontLoadedPacing: ad.use_front_load_pacing,
                hasSetupWarnings,
                hasStarted: moment().isAfter(adStart),
            };
        });

    const sortType = _.get(pagination, 'filterOptions.sort', void 0);
    const searchQuery = _.get(pagination, 'filterOptions.search', '');
    const searchQueryClean = searchQuery.toLowerCase();

    const ads_sorting = ads => {
        if (sortType && sortType in sortMechanism) {
            return sortMechanism[sortType](ads);
        } else {
            return ads;
        }
    };

    const adsLength_beforeFilter = ads.length;

    const ads_filtering = ads => {
        const _o = _(ads)
            .filter(ad => {
                const stringBeingSearch = _.values(_.pick(ad, ['id', 'name', 'notes'])).join(' ');
                const stringBeingSearch_lowercase = stringBeingSearch.toLowerCase();
                if (searchQueryClean === '') return true;
                if (smartQuery(searchQueryClean).test(stringBeingSearch_lowercase)) return true;
                return false;
            })
            .value();
        return _o;
    };

    const filteredAds = _.flow(
        ads_sorting,
        ads_filtering
    )(ads);

    return {
        ads: filteredAds,
        campaignCurrencyType,
        campaignStatus: campaign.paused, // maybe use status?
        sortType,
        isInitialized,
        searchQuery,
        adsLength_beforeFilter,
        campaign,
        hiddenColumns,
        fetchingHiddenColumns,
        orgTimezone,
        selectedAds,
        adsBeingProcessed,
    };
}

function getScheduledWeightedRotationSummary({ ad, creativeMapping, campaignId }) {
    return _.map(ad.rotation_rules.scheduled, schedule => ({
        ...schedule,
        isActive: moment.utc().isBetween(schedule.start, schedule.end),
        weighted: _.map(schedule.weighted, weighted => ({
            ...weighted,
            name: _.get(creativeMapping, `${weighted.markup_id}.name`),
            link: `/campaigns/${campaignId}/setup/creatives#${weighted.markup_id}`,
            hasCpcInsConflict:
                isCpcAd(ad) && hasInsTag(_.get(creativeMapping, `${weighted.markup_id}`)),
        })),
    }));
}


import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import flags from 'containers/flags/service';
import Tabs from 'widgets-v5/tabs';
import StandardInput from 'widgets-v5/standard-input';
import Dropdown from 'widgets-v5/dropdown';
import Menu, { VirtualMenu, MenuItem } from 'widgets-v5/menu';
import { DescriptionText, BodyBold } from 'widgets-v5/typography';

export default class extends React.Component {
    static displayName = 'IncludeAndExclude';

    static defaultProps = {
        options: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            excludedItems: props.excludedValues,
            includedItems: props.includedValues,
            activeCustomFilter: null,
            customFilterTest: null,
        };

        if (this.props.customFilters) {
            const initialFilter = props.customFilters[0];

            this.state.activeCustomFilter = initialFilter.value;
            this.state.customFilterTest = initialFilter.test;
        }
    }

    onFilterInput = value => {
        this.setState({ filter: value });
    };

    toggleInclude = item => {
        var isAlreadyIncluded = _.includes(this.state.includedItems, item.value);

        // if its already included, remove it
        if (isAlreadyIncluded) {
            const nextIncluded = _.filter(this.state.includedItems, value => value !== item.value);
            this.setState({
                includedItems: nextIncluded,
            });
            // otherwise add it to included, and remove it from excluded
        } else {
            const nextExcluded = _.filter(this.state.excludedItems, value => value !== item.value);
            const nextIncluded = this.state.includedItems.concat(item.value);

            this.setState({
                includedItems: nextIncluded,
                excludedItems: nextExcluded,
            });
        }
    };

    toggleExclude = item => {
        var isAlreadyExcluded = _.includes(this.state.excludedItems, item.value);

        // if its already excluded, remove it
        if (isAlreadyExcluded) {
            const nextExcluded = _.filter(this.state.excludedItems, value => value !== item.value);
            this.setState({
                excludedItems: nextExcluded,
            });
            // otherwise add it to excluded, and remove it from included
        } else {
            const nextIncluded = _.filter(this.state.includedItems, value => value !== item.value);
            const nextExcluded = this.state.excludedItems.concat(item.value);

            this.setState({
                includedItems: nextIncluded,
                excludedItems: nextExcluded,
            });
        }
    };

    removeIncludedItem = includedValue => {
        const nextIncluded = _.filter(this.state.includedItems, value => value !== includedValue);

        this.setState(
            {
                includedItems: nextIncluded,
            },
            () => {
                this.emitChange();
            }
        );
    };

    removeExcludedItem = excludedValue => {
        const nextExcluded = _.filter(this.state.excludedItems, value => value !== excludedValue);

        this.setState(
            {
                excludedItems: nextExcluded,
            },
            () => {
                this.emitChange();
            }
        );
    };

    handleClose = () => {
        this.emitChange();
    };

    emitChange = () => {
        const itemLookup = {};
        _.each(this.props.options, option => {
            itemLookup[option.value] = option;
        });

        this.props.onChange({
            includedItemValues: this.state.includedItems,
            excludedItemValues: this.state.excludedItems,

            includedItems: _.map(this.state.includedItems, value => itemLookup[value]),
            excludedItems: _.map(this.state.excludedItems, value => itemLookup[value]),
        });
    };

    switchCustomFilterTab = value => {
        const filter = _.find(this.props.customFilters, { value });

        this.setState({
            customFilterTest: filter.test,
            activeCustomFilter: value,
        });
    };

    render() {
        let buttonLabel;

        let onClear;
        if (this.props.clearable) {
            // onClear = this.props.values.length > 0 ? this.clearValues : null;
        }

        const optionMapping = {};
        _.each(this.props.options, o => (optionMapping[o.value] = o));

        const filteredOptions = _(this.props.options)
            .filter(option => {
                const labelMatch =
                    option.label.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0;

                let descriptionMatch;
                if (option.description) {
                    const description = option.description.toLowerCase();
                    descriptionMatch = description.indexOf(this.state.filter.toLowerCase()) > -1;
                }

                return labelMatch || descriptionMatch;
            })
            .filter(option => {
                if (this.state.customFilterTest) {
                    return this.state.customFilterTest(option);
                }

                return true;
            })
            .value();

        return (
            <div className="include-and-exclude">
                <Dropdown
                    className="include-and-exclude__dropdown"
                    isLoading={this.props.isLoading}
                    buttonLabel={'Filter selections'}
                    onClear={onClear}
                    onClose={this.handleClose}
                    dropUp={this.props.dropUp}
                >
                    {close => (
                        <div>
                            {this.props.customFilters && (
                                <Tabs
                                    activeItem={this.state.activeCustomFilter}
                                    onChange={this.switchCustomFilterTab}
                                    items={_.map(this.props.customFilters, filter => ({
                                        label: filter.label,
                                        value: filter.value,
                                        onClick: _.noop,
                                    }))}
                                />
                            )}
                            <div className="include-and-exclude__standard-input">
                                <StandardInput
                                    value={this.state.filter}
                                    placeholder="Filter"
                                    onChange={this.onFilterInput}
                                />
                            </div>

                            <VirtualMenu items={filteredOptions}>
                                {(item, style) => (
                                    <MenuItem
                                        key={item.value}
                                        className="include-and-exclude__menu-item-wrapper"
                                        style={style}
                                    >
                                        <div className="include-and-exclude__menu-item">
                                            <div className="include-and-exclude__menu-item-label-wrapper">
                                                <div className="include-and-exclude__menu-item-label">
                                                    {item.label}
                                                </div>
                                                {this.props.description && (
                                                    <span
                                                        className={classnames({
                                                            'include-and-exclude__menu-item-description':
                                                                item.description !== undefined,
                                                        })}
                                                    >
                                                        <DescriptionText type="info">
                                                            {item.description}
                                                        </DescriptionText>
                                                    </span>
                                                )}
                                            </div>
                                            {item.subtitle}
                                            <div className="include-and-exclude__buttons">
                                                {this.props.type && (
                                                    <div className="include-and-exclude__type">
                                                        city
                                                    </div>
                                                )}
                                                <i
                                                    onClick={() => this.toggleInclude(item)}
                                                    className={classnames({
                                                        'include-and-exclude__icon-include fa fa-check': true,
                                                        'include-and-exclude__icon-include--is-active': _.includes(
                                                            this.state.includedItems,
                                                            item.value
                                                        ),
                                                    })}
                                                />
                                                <i
                                                    onClick={() => this.toggleExclude(item)}
                                                    className={classnames({
                                                        'include-and-exclude__icon-exclude fa fa-ban': true,
                                                        'include-and-exclude__icon-exclude--is-active': _.includes(
                                                            this.state.excludedItems,
                                                            item.value
                                                        ),
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </MenuItem>
                                )}
                            </VirtualMenu>
                        </div>
                    )}
                </Dropdown>

                <IncludeAndExcludeSummary
                    includedItems={_.map(
                        this.state.includedItems,
                        value => optionMapping[value]
                    ).filter(x => x)}
                    excludedItems={_.map(
                        this.state.excludedItems,
                        value => optionMapping[value]
                    ).filter(x => x)}
                    removeIncludedItem={this.removeIncludedItem}
                    removeExcludedItem={this.removeExcludedItem}
                    category={this.props.category}
                    footerLabel={this.props.footerLabel}
                />
            </div>
        );
    }
}

export function IncludeAndExcludeSummary(props) {
    return (
        <div className={classnames({ 'include-and-exclude__summary': props.removeIncludedItem })}>
            <div className="include-and-exclude__summary-title">
                <i className="include-and-exclude__summary-icon-include fa fa-check" />
                Included ({props.includedItems.length})
            </div>
            {props.includedItems.length > 0 && (
                <div className="include-and-exclude__summary-selected-items">
                    {_.map(props.includedItems, item => (
                        <div
                            key={item.value}
                            className="include-and-exclude__summary-selected-item-wrapper"
                        >
                            <div className="include-and-exclude__summary-selected-item">
                                {item.label}
                                <span className="include-and-exclude__menu-item-description">
                                    <DescriptionText type="info">
                                        {item.description}
                                    </DescriptionText>
                                </span>
                                <span className="include-and-exclude__menu-item-description">
                                    <DescriptionText>{item.category}</DescriptionText>
                                </span>
                            </div>
                            {props.removeIncludedItem && (
                                <i
                                    onClick={() => props.removeIncludedItem(item.value)}
                                    className="fa fa-times include-and-exclude__icon-remove"
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
            <div className="include-and-exclude__summary-title">
                <i className="include-and-exclude__summary-icon-exclude fa fa-ban" />
                Excluded ({props.excludedItems.length})
            </div>
            {props.excludedItems.length > 0 && (
                <div className="include-and-exclude__summary-selected-items">
                    {_.map(props.excludedItems, item => (
                        <div
                            key={item.value}
                            className="include-and-exclude__summary-selected-item-wrapper"
                        >
                            <div className="include-and-exclude__summary-selected-item">
                                {item.label}
                                <span className="include-and-exclude__menu-item-description">
                                    <DescriptionText type="info">
                                        {item.description}
                                    </DescriptionText>
                                </span>
                                <span className="include-and-exclude__menu-item-description">
                                    <DescriptionText>{item.category}</DescriptionText>
                                </span>
                            </div>
                            {props.removeExcludedItem && (
                                <i
                                    onClick={() => props.removeExcludedItem(item.value)}
                                    className="fa fa-times include-and-exclude__icon-remove"
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
            {props.footerLabel && (
                <div className="include-and-exclude__footer-label">
                    <BodyBold>
                        {props.footerLabel({
                            includedItems: props.includedItems,
                            excludedItems: props.excludedItems,
                        })}
                    </BodyBold>
                </div>
            )}
        </div>
    );
}

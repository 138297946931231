import React from 'react';

import FormField from 'widgets-v5/form-field';
import OptionSelector from 'widgets-v5/option-selector';
import Dayparts from 'widgets-v5/dayparts';

class AdFormDayparts extends React.Component {
    handleFormChange = value => {
        this.props.onChange(value);
    };

    render() {
        const { buttonSW } = this.props;

        var buttonSwitcherComponent = null;

        if (buttonSW) {
            buttonSwitcherComponent = (
                <OptionSelector
                    onClick={this.props.handleButtonSwitcher}
                    btnTextLeft={this.props.textLeft}
                    // disabledLeft={this.props.disabledLeft}
                    // disabledRight={this.props.disabledRight}
                    btnTextRight={this.props.textRight}
                    position={this.props.position}
                />
            );
        }

        return (
            <div className="ef5-ad-form-dayparts">
                <FormField
                    label={false && 'Dayparts'}
                    description="Full day if left unspecified"
                    errors={this.props.errors}
                    showErrors={this.props.showErrors}
                    isRequired={false}
                    marginBottom={25}
                >
                    {buttonSwitcherComponent}
                    <Dayparts
                        date={this.props.adStart ? new Date(this.props.adStart) : new Date()}
                        timezone={this.props.isUTCTime ? 'local' : this.props.valueTimeZone}
                        value={this.props.valueDayparts}
                        onChange={this.handleFormChange}
                    />
                </FormField>
            </div>
        );
    }
}

export default AdFormDayparts;

import _ from 'lodash';

import http from 'utils/http';

const dealAPIActions = {
    create(dealPayload) {
        return (dispatch, getState) => {
            return http()
                .post(`deals`, dealPayload)
                .then(
                    deal => {
                        dispatch({
                            type: 'SYSTEM_INVENTORY_DEALS_CREATE_SUCCESS',
                        });
                    },
                    error => {
                        console.error(`CREATE/POST deal error`, error);
                        throw error;
                    }
                );
        };
    },
    update(dealId, dealPayload) {
        return (dispatch, getState) => {
            return http()
                .patch(`deals/${dealId}`, dealPayload)
                .then(
                    deal => {
                        dispatch({
                            type: 'SYSTEM_INVENTORY_DEALS_UPDATE_SUCCESS',
                        });
                    },
                    error => {
                        throw error;
                    }
                );
        };
    },
    delete(dealId) {
        return (dispatch, getState) => {
            return http()
                .del(`deals/${dealId}`)
                .then(
                    deal => {
                        dispatch({ type: 'SYSTEM_DEALS__DELETE__SUCCESS' });
                    },
                    error => {
                        throw error;
                    }
                );
        };
    },
};

export default dealAPIActions;

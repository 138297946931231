import _ from 'lodash';
import React from 'react';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

import APP_STORE_CATEGORIES from 'common/constants/app-store-categories';
import { MultiSelect } from 'widgets-v6/select';

const getAppCategories = isProspectMedia => {
    const appCategoryTargeting = [];
    _(APP_STORE_CATEGORIES).each(item => {
        if ((isProspectMedia && item.value !== 'Games') || !isProspectMedia) {
            appCategoryTargeting.push(item);
        }
    });

    return _(appCategoryTargeting)
        .sortBy('label')
        .value();
};

export default function TargetAppStoreCategories({
    includeValues,
    excludeValues,
    onChange,
    exclude,
    includeOnly,
    isProspectMedia,
}) {
    let values = [];

    if (includeValues.length > 0) {
        values = includeValues;
    } else {
        values = excludeValues;
    }

    const handleFormChange = value => {
        onChange({
            values: value,
            exclude,
        });
    };

    return (
        <React.Fragment>
            {!includeOnly && <RadioButtons exclude={exclude} onChange={onChange} values={values} />}
            <MultiSelect
                value={values}
                options={getAppCategories(isProspectMedia)}
                onChange={handleFormChange}
                placeholder={values.length === 0 ? 'Select App Categories' : ''}
            />
        </React.Fragment>
    );
}

export function RadioButtons({ onChange, exclude, values }) {
    const handleChange = event => {
        if (event.target.value === 'include') {
            onChange({
                exclude: false,
                values: values,
            });
        } else {
            onChange({
                exclude: true,
                values: values,
            });
        }
    };

    return (
        <RadioGroup row value={exclude ? 'exclude' : 'include'} onChange={handleChange}>
            <FormControlLabel value="include" control={<Radio />} label="Include" />
            <FormControlLabel value="exclude" control={<Radio />} label="Exclude" />
        </RadioGroup>
    );
}

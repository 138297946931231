import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import flags from 'containers/flags/service';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CenterLayout from 'packages/widgets-v5/center-layout';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { SimpleSelect, MultiSelect } from 'widgets-v6/select';
import { mergeVendorOptions } from 'forms/creative-form';
import DragAndDropFileUpload from 'widgets-v5/drag-and-drop-file-upload';
import FileUpload from 'widgets-v5/file-upload';
import DownloadTextContents from 'widgets-v5/download-text-contents';
import { CREATIVES_VENDOR_TAGS } from 'common/constants/vendors';

import CircularProgress from '@mui/material/CircularProgress';

import actions from './actions';
import selector from './selector';

import BulkUploadTable from './modules/upload-table';

import {
    CREATIVE_VENDORS,
    TEMPLATE_FILENAME,
    TEMPLATE_CONTENTS,
    EXAMPLE_FILENAME,
    EXAMPLE_CONTENTS,
} from './constants.js';

const thirdPartyVendorOptions = mergeVendorOptions({
    creativeType: 'mraid',
    vendorsFromDraft: [],
    tagsMatched: [],
    vendorType: CREATIVES_VENDOR_TAGS,
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dragAndDropText: {
        margin: 10,
    },
    dragAndDropFileUpload: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    templateButton: {
        marginTop: 20,
        marginRight: theme.spacing(1),
    },
    vendorContainer: {
        marginBottom: theme.spacing(1.5),
    },
}));

const Loading = withStyles(theme => ({
    root: {
        width: '24px',
        height: '24px',
    },
}))(Grid);

const LoadingSpinner = withStyles(theme => ({
    root: {
        width: '24px !important',
        height: '24px !important',
    },
}))(CircularProgress);

function CreativeBulkUploadForm({
    isOpen,
    dispatch,
    campaignId,
    tempAssets,
    failedAssets,
    loadingAssets,
    format,
    creativeVendor,
    specifiedThirdPartyVendors,
    showCreativeVendorError,
    campaignType,
    ...props
}) {
    const classes = useStyles();
    const handleClose = () => {
        dispatch(actions.closeForm());
    };

    const handleFileUpload = (formData, file) => {
        dispatch(actions.fileUploadSubmit(formData, campaignId, file));
    };

    const handleTemplateUpload = (formData, file) => {
        dispatch(actions.processUploadedTemplate(formData));
    };

    const handleSelectCreativeVendor = e => {
        dispatch(actions.changeCreativeVendor({ value: e.target.value }));
    };

    const handleSpecifyThirdPartyVendors = value => {
        dispatch(actions.changeSpecificThirdPartyVendor({ value }));
    };

    const handleInvalidFileError = files => {
        dispatch(actions.showFileTypeError(files));
    };

    const vendorOptions = CREATIVE_VENDORS;

    const acceptedFormats = creativeVendor === 'generic' ? '.csv' : '.xlsx, .xls';

    return (
        <Dialog fullScreen open={isOpen} maxWidth="lg" onClose={handleClose}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Bulk Upload Creatives
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                {format !== 'standard' && (
                    <React.Fragment>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            className={classes.vendorContainer}
                        >
                            <Grid item xs="2">
                                <SimpleSelect
                                    label="Creative Vendor"
                                    options={vendorOptions}
                                    onChange={handleSelectCreativeVendor}
                                    value={creativeVendor}
                                    placeholder="Select a creative vendor"
                                />
                            </Grid>
                            {creativeVendor === 'generic' && (
                                <Grid item>
                                    <DownloadTextContents
                                        filename={EXAMPLE_FILENAME}
                                        contents={EXAMPLE_CONTENTS}
                                    >
                                        {onDownload => (
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={onDownload}
                                                className={classes.templateButton}
                                            >
                                                Download Example
                                            </Button>
                                        )}
                                    </DownloadTextContents>
                                    <DownloadTextContents
                                        filename={TEMPLATE_FILENAME}
                                        contents={TEMPLATE_CONTENTS}
                                    >
                                        {onDownload => (
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={onDownload}
                                                className={classes.templateButton}
                                            >
                                                Download Template
                                            </Button>
                                        )}
                                    </DownloadTextContents>
                                </Grid>
                            )}
                        </Grid>
                        {creativeVendor === 'generic' && (
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography>Specify Vendor*</Typography>
                                    <MultiSelect
                                        options={thirdPartyVendorOptions}
                                        onChange={handleSpecifyThirdPartyVendors}
                                        value={specifiedThirdPartyVendors}
                                        placeholder="Select a creative vendor"
                                    />
                                    {showCreativeVendorError && (
                                        <FormHelperText error>Must Specify a Vendor</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {format === 'custom_html' || format === 'mraid' ? (
                    <React.Fragment>
                        {creativeVendor && (
                            <DragAndDropFileUpload
                                accept={acceptedFormats}
                                onChange={(formData, file) => handleTemplateUpload(formData, file)}
                                failedAssets={failedAssets}
                                onDropRejected={handleInvalidFileError}
                                className={classes.dragAndDropFileUpload}
                            >
                                <div className={classes.dragAndDropText}>
                                    <Typography color="textSecondary">
                                        Drag and drop file here to upload
                                    </Typography>
                                    <Typography color="textSecondary">OR</Typography>
                                </div>
                                <FileUpload
                                    accept={acceptedFormats}
                                    onChange={handleTemplateUpload}
                                    disabled={false}
                                    buttonText="Upload File"
                                />
                            </DragAndDropFileUpload>
                        )}
                        <BulkUploadTable campaignId={campaignId} campaignType={campaignType} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <DragAndDropFileUpload
                            accept="image/*"
                            onChange={(formData, file) => handleFileUpload(formData, file)}
                            onDropRejected={handleInvalidFileError}
                            failedAssets={failedAssets}
                            className={classes.dragAndDropFileUpload}
                            tempAssets={tempAssets}
                        >
                            <div className={classes.dragAndDropText}>
                                <Typography color="textSecondary">
                                    Drag and drop files here to upload
                                </Typography>
                                <Typography color="textSecondary">OR</Typography>
                            </div>
                            <FileUpload
                                accept="image/*"
                                isLoading={loadingAssets}
                                onChange={formData => handleFileUpload(formData)}
                                buttonText="Upload File"
                            />
                        </DragAndDropFileUpload>
                        <BulkUploadTable campaignId={campaignId} campaignType={campaignType} />
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(actions.submitDraft())}
                >
                    {props.isSubmitting ? (
                        <Loading>
                            <LoadingSpinner fontSize="small" color="inherit" />
                        </Loading>
                    ) : (
                        'Save and Submit'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default connect(selector)(CreativeBulkUploadForm);

import React from 'react';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FileUpload from 'widgets-v5/file-upload';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';

import HtmlEditor from 'widgets-v5/html-editor/lazy';
import StandardInput from 'widgets-v5/standard-input';
import LoadGroup from 'widgets-v5/load-group';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import MultilineInput from 'widgets-v5/multiline-input';
import CreativeAssetSelector from '../creative-asset-selector/index';
import ThirdPartyPixelsInput from '../third-party-pixels-input/index';
import ClickthroughCard from '../click-through-card/click-through-card';
import {
    CreativeVendorSelector,
    TrackingVendorsSelector,
} from '../vendor-selector/vendor-selector';
import { getExporter, isSupportedExporter } from '../../constants';
import { creativeTrackingVendorsMapping, StyledLink } from '../../index';
import { htmlExamples, htmlGenericExample } from './html-examples';

const useStyles = makeStyles(theme => ({
    image: {
        width: 70,
    },
    html: {
        backgroundColor: theme.palette.grey[200],
        padding: '15px',
        margin: '10px 0px',
    },
    thirdPartyVendors: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    expand: {
        cursor: 'pointer',
    },
}));

export default function CreativeFormMraidType(props) {
    const classes = useStyles();

    const {
        showErrors,
        errors,
        draft,
        onFieldChange,
        updateVendors,
        activeStep,
        isReferenceImageLoading,
        isZipUploadLoading,
        handleZipUpload,
        showHTMLblock,
    } = props;

    const onHtmlEditorChange = value => {
        onFieldChange('content_html')(value);
    };

    const suggestedExample = getSuggestedExample(draft.creative_vendors);

    const landingPageClickthroughErrors = [].concat(errors.clickthrough_url).filter(error => error);

    const landingPageErrors = [].concat(errors.landing_page).filter(error => error);

    const errorsForReferenceImage = []
        .concat(
            errors.reference_image_asset,
            errors.reference_image_url,
            errors.reference_image_preview_url
        )
        .filter(error => error);

    const thirdPartyPixelsErrors = []
        .concat(errors.third_party_pixels, errors.third_party_quartile_pixels)
        .filter(error => error);

    const htmlMarkupErrors = []
        .concat(errors.creative_vendors, errors.content_html)
        .filter(error => error);

    const expandIcon = props.expandedExamples ? <ExpandLessIcon /> : <ExpandMoreIcon />;
    const expandExample = props.expandedExamples ? (
        <div>
            {suggestedExample.instructions}
            {suggestedExample.example && (
                <pre className={classes.html}>
                    <code>{suggestedExample.example}</code>
                </pre>
            )}
        </div>
    ) : (
        ''
    );

    let isHTMLBlockShown;

    if (draft.exporter) {
        isHTMLBlockShown = showHTMLblock;
    } else {
        isHTMLBlockShown = true;
    }

    let isCreativeVendorsFieldShown;

    if (draft.exporter) {
        isCreativeVendorsFieldShown = false;
    } else {
        isCreativeVendorsFieldShown = true;
    }

    let HTMLMarkupTitle;

    if (draft.exporter) {
        HTMLMarkupTitle = `Upload ${getExporter(draft.exporter).title} Creative`;
    } else {
        HTMLMarkupTitle = `HTML Markup (${draft.size})`;
    }

    if (activeStep === 1) {
        return (
            <div>
                <AccordionItem
                    required
                    onClick={props.expandPanel('htmlMarkup')}
                    expanded={props.expandedPanel === 'htmlMarkup'}
                    title={HTMLMarkupTitle}
                    error={showErrors && htmlMarkupErrors}
                    helper={
                        draft.exporter === 'adobe' && (
                            <React.Fragment>
                                <Typography gutterBottom>
                                    Please note that we require that you upload your Adobe Animate
                                    Creative in a certain format. Before uploading your Adobe
                                    Animate Creative file, please view the following links:
                                </Typography>
                                <Box mt={1}>
                                    <Typography gutterBottom>
                                        <Link
                                            target="_blank"
                                            href="https://engagefront.notion.site/Preparing-your-Adobe-Animate-creative-79dacaba2c964a89a7f59314c6fd695c"
                                        >
                                            How to prepare your Adobe Animate Creative
                                        </Link>
                                    </Typography>
                                    <Typography>
                                        <Link
                                            target="_blank"
                                            href="https://engagefront.notion.site/Uploading-Adobe-Animate-creatives-to-EngageFront-97d3a5ed07314f569cd30e53d619ad94"
                                        >
                                            How to upload Adobe Animate Creatives to EnageFront
                                        </Link>
                                    </Typography>
                                </Box>
                            </React.Fragment>
                        )
                    }
                    details={
                        <Box display="flex" flexDirection="column">
                            {isSupportedExporter(draft.exporter) && (
                                <Box mb={1}>
                                    <FileUpload
                                        accept={'.zip'}
                                        onChange={handleZipUpload}
                                        disabled={false}
                                        buttonText={`Upload ${
                                            getExporter(draft.exporter).label
                                        } Zip File`}
                                    />
                                </Box>
                            )}
                            {isHTMLBlockShown && (
                                <LoadGroup
                                    isLoading={isReferenceImageLoading || isZipUploadLoading}
                                >
                                    <HtmlEditor
                                        generateReferenceImage={props.generateReferenceImage}
                                        value={draft.content_html}
                                        onChange={onHtmlEditorChange}
                                    />
                                </LoadGroup>
                            )}
                            {props.showErrors && errors.content_html && (
                                <FormHelperText error>
                                    <Typography variant="body2">{errors.content_html}</Typography>
                                </FormHelperText>
                            )}
                            {isCreativeVendorsFieldShown && (
                                <React.Fragment>
                                    <CreativeVendorSelector
                                        showErrors={showErrors}
                                        errors={errors}
                                        draft={draft}
                                        onFieldChange={onFieldChange}
                                        updateVendors={updateVendors}
                                        creativeTagsMatched={props.creativeTagsMatched}
                                        creativeType={'mraid'}
                                    />
                                    <Box m={2}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            onClick={props.expandExamples}
                                            className={classes.expand}
                                        >
                                            <div>Instructions for {suggestedExample.name}</div>
                                            <div>{expandIcon}</div>
                                        </Box>
                                        <Box>{expandExample}</Box>
                                    </Box>
                                </React.Fragment>
                            )}
                        </Box>
                    }
                    summary={
                        <div>
                            <LoadGroup isLoading={isReferenceImageLoading || isZipUploadLoading}>
                                {' '}
                                {draft.content_html ? (
                                    <HtmlEditor
                                        generateReferenceImage={() => {}}
                                        value={draft.content_html}
                                        onChange={() => {}}
                                        readOnly
                                    />
                                ) : !isCreativeVendorsFieldShown ? (
                                    <Typography color="textSecondary">Upload File</Typography>
                                ) : (
                                    <Typography color="textSecondary">Add HTML markup</Typography>
                                )}{' '}
                            </LoadGroup>
                            {isCreativeVendorsFieldShown && (
                                <Box mt={1}>
                                    {draft.creative_vendors.length > 0 &&
                                    draft.creative_vendors[0] ? (
                                        <div className={classes.thirdPartyVendors}>
                                            <Typography color="textSecondary">
                                                Creative Vendor
                                            </Typography>
                                            {_.map(draft.creative_vendors, vendor => {
                                                return (
                                                    <Chip
                                                        key={vendor}
                                                        label={
                                                            creativeTrackingVendorsMapping[
                                                                vendor
                                                            ] || vendor
                                                        }
                                                        variant="outlined"
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <Typography color="textSecondary">
                                            Add creative vendor
                                        </Typography>
                                    )}{' '}
                                </Box>
                            )}
                        </div>
                    }
                />

                <AccordionItem
                    onClick={props.expandPanel('thirdPartyClicktracker')}
                    expanded={props.expandedPanel === 'thirdPartyClicktracker'}
                    title="Clicktracking"
                    required
                    error={
                        props.showErrors &&
                        (landingPageClickthroughErrors.length > 0 || landingPageErrors.length > 0)
                    }
                    details={
                        <React.Fragment>
                            <ClickthroughCard
                                draft={props.draft}
                                errors={props.errors}
                                onFieldChange={props.onFieldChange}
                                showErrors={props.showErrors}
                            />
                        </React.Fragment>
                    }
                    summary={
                        <React.Fragment>
                            <div>
                                <Box mb={1}>
                                    {draft.third_party_clickthrough
                                        ? 'Set or override Clickthrough URL'
                                        : 'Use Clickthrough URL set by third-party ad server or tag  (most common)'}
                                </Box>
                                {draft.third_party_clickthrough &&
                                    (draft.clickthrough_url ? (
                                        <Box>
                                            <Typography color="textSecondary">
                                                Clickthrough URL
                                            </Typography>
                                            <StyledLink>{draft.clickthrough_url}</StyledLink>
                                        </Box>
                                    ) : (
                                        <Typography color="textSecondary">
                                            Add clickthrough URL
                                        </Typography>
                                    ))}
                            </div>
                        </React.Fragment>
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('landingPage')}
                    expanded={props.expandedPanel === 'landingPage'}
                    title="Landing Page URL"
                    helper="Used for reference only, by EngageFront and our exchange partners, to verify the Clickthrough URL."
                    required
                    error={props.showErrors && landingPageErrors.length > 0}
                    details={
                        <Box mt={1.5}>
                            <FormControl
                                error={props.showErrors && landingPageErrors.length > 0}
                                fullWidth
                                required
                            >
                                <StandardInput
                                    value={draft.landing_page}
                                    onChange={onFieldChange('landing_page')}
                                    placeholder="http://', 'https://', or 'tel:"
                                />
                                <FormHelperText>
                                    {props.showErrors &&
                                        landingPageErrors.length > 0 &&
                                        landingPageErrors}
                                </FormHelperText>
                                <FormHelperText>
                                    Enter the URL of the final landing page. Do not enter any
                                    third-party tracking URLs.
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    }
                    summary={
                        draft.landing_page ? (
                            <div>
                                <Typography color="textSecondary">Landing Page</Typography>
                                <StyledLink>{draft.landing_page}</StyledLink>
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add landing page</Typography>
                        )
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('thirdPartyPixels')}
                    expanded={props.expandedPanel === 'thirdPartyPixels'}
                    title="Third Party Pixels"
                    error={props.showErrors && thirdPartyPixelsErrors > 0}
                    details={
                        <div>
                            <ThirdPartyPixelsInput
                                onChange={props.onFieldChange}
                                third_party_quartile_pixels={draft.third_party_quartile_pixels}
                                third_party_pixels={draft.third_party_pixels}
                                error={props.showErrors && thirdPartyPixelsErrors > 0}
                                canAddVastPixels={draft.format === 'video'}
                            />
                            <FormHelperText>{thirdPartyPixelsErrors}</FormHelperText>
                        </div>
                    }
                    summary={
                        draft.third_party_pixels.length > 0 ? (
                            <div>
                                {_.map(draft.third_party_pixels, pixel => (
                                    <StyledLink key={pixel}>{pixel}</StyledLink>
                                ))}
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add third party pixels</Typography>
                        )
                    }
                />
                <AccordionItem
                    onClick={props.expandPanel('thirdPartyJavascriptURLs')}
                    expanded={props.expandedPanel === 'thirdPartyJavascriptURLs'}
                    title="Third Party Javascript"
                    error={props.showErrors && errors.third_party_javascript_urls > 0}
                    details={
                        <MultilineInput
                            onChange={props.onFieldChange('third_party_javascript_urls')}
                            inputs={props.draft.third_party_javascript_urls}
                            addText="Add Javascript URL"
                        />
                    }
                    summary={
                        draft.third_party_javascript_urls.length > 0 ? (
                            <div>
                                {_.map(draft.third_party_javascript_urls, url => (
                                    <StyledLink key={url}>{url}</StyledLink>
                                ))}
                            </div>
                        ) : (
                            <Typography color="textSecondary">
                                Add Third Party Javascript
                            </Typography>
                        )
                    }
                />

                <AccordionItem
                    onClick={props.expandPanel('trackingVendors')}
                    expanded={props.expandedPanel === 'trackingVendors'}
                    title="Tracking Vendors"
                    error={showErrors && errors.tracking_vendors}
                    details={
                        <TrackingVendorsSelector
                            showErrors={showErrors}
                            errors={errors}
                            draft={draft}
                            onFieldChange={onFieldChange}
                            updateVendors={updateVendors}
                            trackingTagsMatched={props.trackingTagsMatched}
                            creativeType={'mraid'}
                        />
                    }
                    summary={
                        draft.tracking_vendors.length > 0 ? (
                            <div className={classes.thirdPartyVendors}>
                                {_.map(draft.tracking_vendors, vendor => {
                                    return (
                                        <Chip
                                            key={vendor}
                                            label={creativeTrackingVendorsMapping[vendor] || vendor}
                                            variant="outlined"
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            <Typography color="textSecondary">Add tracking vendors</Typography>
                        )
                    }
                />

                <AccordionItem
                    onClick={props.expandPanel('referenceImage')}
                    expanded={props.expandedPanel === 'referenceImage'}
                    title={`Reference Image (${draft.size})`}
                    error={errorsForReferenceImage.length > 0 && props.showErrors}
                    details={
                        <CreativeAssetSelector
                            mode={props.mode}
                            uploadFile={props.uploadFile}
                            errors={props.errors}
                            assets={props.assets}
                            draft={props.draft}
                            onFieldChange={props.onFieldChange}
                            onMultiFieldChange={props.onMultiFieldChange}
                            inputUrlFieldsType="mraid"
                            showErrors={props.showErrors}
                            areAssetsLoading={props.areAssetsLoading}
                            isGenerateReferenceImageDisabled={draft.content_html.length === 0}
                            generateReferenceImage={props.generateReferenceImage}
                            renderDelay={props.renderDelay}
                            onChangeRenderDelay={props.onChangeRenderDelay}
                            onChangeAssetSelectionMethod={props.onChangeAssetSelectionMethod}
                            isReferenceImageLoading={isReferenceImageLoading || isZipUploadLoading}
                            uploadAssetsErrorMessage={props.uploadAssetsErrorMessage}
                            hideUploadAssetsErrorMessage={props.hideUploadAssetsErrorMessage}
                        />
                    }
                    summary={
                        draft.reference_image_preview_url ? (
                            <Grid container spacing={1}>
                                <Grid item>
                                    <img
                                        className={classes.image}
                                        src={draft.reference_image_preview_url}
                                        alt=""
                                    />
                                </Grid>
                                <Grid item>
                                    {props.creativeMapping[draft.reference_image_preview_url]}
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography color="textSecondary">Add reference image</Typography>
                        )
                    }
                />
            </div>
        );
    }

    return null;
}

function getSuggestedExample(selectedVendor) {
    let suggestedExample = _.filter(htmlExamples, example => {
        return _.includes(selectedVendor[0], example.value);
    })[0];

    if (!suggestedExample) {
        suggestedExample = htmlGenericExample;
    }

    return suggestedExample;
}

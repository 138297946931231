import _ from 'lodash';

export function tabLinksUriQuery(storeState, campaignId) {
    const queries = _.reduce(
        ['ads', 'creatives', 'files', 'adGroupings', 'customDimensions'],
        (acc, v) => {
            const hasQuery = [];

            const filterOptions = _.get(storeState, `${v}Overview.filterOptions`);

            const queryStr = _.reduce(
                filterOptions,
                (acc_query, query_v, query_k) => {
                    hasQuery.push(query_v ? true : false);

                    acc_query[query_k] = query_v;
                    return acc_query;
                },
                {}
            );

            acc[v] = _.some(hasQuery)
                ? '?' +
                  _(queryStr)
                      .map((queryStr_v, queryStr_k) => {
                          return queryStr_k + '=' + queryStr_v;
                      })
                      .value()
                      .join('&')
                : '';

            return acc;
        },
        {}
    );
    return queries;
}

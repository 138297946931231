import toastr from 'toastr';
import { createHttp } from 'utils/http';

const source_type = ['pds_custom'];

const actions = {
    init: () => {
        return async dispatch => {
            dispatch({
                type: 'PDS_CUSTOM_DASHBOARD__INIT_START',
            });
            const { totalSegmentCount, ownOrganization } = await dispatch(
                actions.getPdsCustomDashboardData()
            );

            dispatch({
                type: 'PDS_CUSTOM_DASHBOARD__INIT_END',
                payload: { totalSegmentCount, ownOrganization },
            });
        };
    },
    getPdsCustomDashboardData: keywords => {
        return async (dispatch) => {
            try {
                const http = createHttp();
                const query = `
                    query getPdsCustomDashboardData ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                            id
                            name
                            lastRefreshed
                            dataCost
                            _created
                            uniques
                            status
                            match_rate
                            supports
                            expire_at
                            source_type
                        }
                        ownOrganization {
                            audienceBuilderVersion
                        }
                    }
                `;

                const variables = {
                    keywords,
                    source_type,
                    deprecated: false,
                };

                const data = await http.graphql(query, variables);

                dispatch({
                    type: 'PDS_CUSTOM_DASHBOARD__FETCH_SEGMENTS_DATA__END',
                    payload: {
                        data: data.audienceSegments,
                    },
                });
                return {
                    totalSegmentCount: data.audienceSegments.length,
                    ownOrganization: data.ownOrganization,
                };
            } catch (err) {
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

import React from 'react';

import { DoohVenueTypeSummary, TargetDoohVenues } from 'forms/ad-form/modules/target-dooh-venues';
import { formatSelectedDOOHVenueTypes } from 'states/resources/ads/business-logic';
import {
    DOOH_VENUE_TYPES,
    doohChildToParent,
    doohVenueTranslate,
} from 'common/constants/dooh-venue-types';
import BaseColumnForm from '../base-column-form';
import shallowCompare from 'utils/shallow-compare';

export class DoohVenueTypeSummaryBulkEdit extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;

        return (
            <div>
                <DoohVenueTypeSummary
                    doohVenuesIncludeValue={doohVenueTranslate(ad.target_dooh_venues)}
                    doohVenuesExcludeValue={doohVenueTranslate(ad.target_dooh_venues_exclude)}
                />
            </div>
        );
    }
}

export class DoohVenueTypeFormBulkEdit extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        const fields = ['target_dooh_venues', 'target_dooh_venues_exclude'];

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Include or exclude DOOH Venue Type categories"
                fields={fields}
                shouldRefreshPredictionsOnSave={true}
                onSave={onSave}
                onCancel={onSave}
                columnName="DOOH Venue Type"
            >
                {(updateDraft, shouldShowErrors, errors, draft) => {
                    return (
                        <TargetDoohVenues
                            onFieldChange={(field, value) =>
                                updateDraft({ [field]: formatSelectedDOOHVenueTypes(value) })
                            }
                            options={DOOH_VENUE_TYPES}
                            doohVenuesIncludeValue={doohChildToParent(draft.target_dooh_venues)}
                            doohVenuesExcludeValue={doohChildToParent(
                                draft.target_dooh_venues_exclude
                            )}
                        />
                    );
                }}
            </BaseColumnForm>
        );
    }
}

import _ from 'lodash';
import notify from 'utils/notify';
import VError from 'verror';
import { createHttp } from 'utils/http';
import { can } from 'services/can';
import c from 'common/constants/flux-events';
import * as services from 'services/resources/organization';
import Organizations from 'states/resources/organizations/actions';
import { adminOrgs as Action_orgs } from 'containers/admin-orgs/actions';
import { getEnvironmentSettings } from 'services/environment';
import { PermissionsMapping } from 'common/constants/role-intents';

import UUID from 'uuid';

const http = createHttp();

const defaultAudienceRates = [
    { name: 'retargeting', fee: 0 },
    { name: 'upload', fee: 0 },
    { name: 'adobe', fee: 0 },
    { name: 'permutive', fee: 0 },
    { name: 'pds_age', fee: 1.5 },
    { name: 'pds_custom', fee: 1.5 },
    { name: 'pds_mastercard', fee: 2.5 },
    { name: 'pds_environics', fee: 1.5 },
    { name: 'pds_standard', fee: 1.5 },
    { name: 'pds_brand', fee: 1.5 },
    { name: 'pds_behaviour', fee: 1.5 },
    { name: 'pds_mweb', fee: 0 },
    { name: 'pds_intent', fee: 1.5 },
    { name: 'pds_manifold', fee: 1.5 },
    { name: 'environics', fee: 0 },
    { name: 'flex_geolocation', fee: 0 },
];

const formatDraft = draft => {
    const audience_rates = [];
    const restrictedSegments = [];

    _.forEach(Object.keys(draft.audienceRatesMap), rate => {
        if (rate === 'retargeting') {
            audience_rates.push({
                name: 'campaign',
                fee: _.get(draft.audienceRatesMap, `${rate}.fee`),
            });
            audience_rates.push({ name: 'ad', fee: _.get(draft.audienceRatesMap, `${rate}.fee`) });
            audience_rates.push({
                name: 'pixel',
                fee: _.get(draft.audienceRatesMap, `${rate}.fee`),
            });
        } else if (rate === 'flex_geolocation') {
            audience_rates.push({
                name: 'flex_standard_brand',
                fee: _.get(draft.audienceRatesMap, `${rate}.fee`),
            });
            audience_rates.push({
                name: 'flex_location_category',
                fee: _.get(draft.audienceRatesMap, `${rate}.fee`),
            });
            audience_rates.push({
                name: 'flex_custom_brand',
                fee: _.get(draft.audienceRatesMap, `${rate}.fee`),
            });
        } else {
            audience_rates.push({ name: rate, fee: _.get(draft.audienceRatesMap, `${rate}.fee`) });
        }
    });
    _.forEach(draft.restrictedSegments, segment => {
        if (segment === 'retargeting') {
            restrictedSegments.push('ad');
            restrictedSegments.push('campaign');
            restrictedSegments.push('pixel');
        } else {
            restrictedSegments.push(segment);
        }
    });

    return {
        ...draft,
        privateDealInfraCost: draft.privateDealInfraCost ? draft.privateDealInfraCost : 0,
        publicInventoryInfraCost: draft.publicInventoryInfraCost
            ? draft.publicInventoryInfraCost
            : 0,
        tech_fee: +draft.tech_fee / 100,
        infraCostPercentage: draft.infraCostPercentage,
        privateDealTechFee: +draft.privateDealTechFee / 100,
        fta_fee: draft.ftaEnabled ? draft.fta_fee : null,
        fta_partner_id: draft.ftaEnabled ? draft.fta_partner_id : null,
        audience_rates,
        restrictedSegments,
    };
};

const actions = {
    openForm(orgId, type) {
        return dispatch => {
            dispatch({
                type: 'ADMIN_ORG_FORM__OPEN_FORM',
            });
            dispatch(actions.init(orgId, type));
        };
    },
    changeFormSection(section) {
        return {
            type: 'ADMIN_ORG_FORM__CHANGE_SECTION',
            payload: { section },
        };
    },
    init(orgId, type) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'ADMIN_ORG_FORM__INIT_START',
                payload: { orgId, mode: orgId ? 'edit' : 'create' },
            });
            if (orgId) {
                try {
                    const query = `
                    query getOrganizationForAdminForm ($id: String) {
                        organization (id: $id) {
                            id
                            name
                            type
                            client_type
                            default_timezone
                            currency
                            revenueModel
                            agencyMarginRate
                            blacklistedAppsAndSites
                            whitelistedAppsAndSites
                            auditors
                            auditees
                            credit_limit
                            tech_fee
                            infraCostPercentage
                            audience_rates {
                                name
                                fee
                            }
                            ftaEnabled
                            fta_fee
                            fta_partner_id
                            restrictedSegments
                            privateDealInfraCost
                            privateDealTechFee
                            publicInventoryInfraCost
                            relationshipToAuthOrganization {
                                _relationship
                            }
                            custom_fields {
                                value
                                required
                                name
                                key
                            }

                            users {
                                id
                                first_name
                                last_name
                            }
                            creatives {
                                standard {
                                    allow_create
                                    auto_approve
                                }
                                video {
                                    allow_create
                                    auto_approve
                                }
                                mraid {
                                    allow_create
                                    auto_approve
                                }
                                custom_html {
                                    allow_create
                                    auto_approve
                                }
                                native {
                                    allow_create
                                    auto_approve
                                }
                                third_party_vast {
                                    allow_create
                                    auto_approve
                                }
                            }
                            isIasPostBidVerificationEnabled
                            privateInventorySpendTiers {
                                techFee
                                min
                                max
                                id
                            }
                            publicInventorySpendTiers {
                                techFee
                                min
                                max
                                id
                            }
                        }
                        applists(filters: {}) {
                            id
                            name
                        }
                    }
                `;
                    const variables = {
                        id: orgId,
                    };
                    const { organization, applists } = await http.graphql(query, variables);
                    const relationship = organization.relationshipToAuthOrganization._relationship;
                    const formattedTechFeeForDraft = (organization.tech_fee * 100).toFixed(2);
                    const formattedPrivateDealTechFeeForDraft = (
                        organization.privateDealTechFee * 100
                    ).toFixed(2);
                    const audienceRatesMap = {};
                    _.forEach(organization.audience_rates, audience => {
                        const audienceName =
                            audience.name === 'campaign' || audience.name === 'ad'
                                ? 'retargeting'
                                : audience.name;
                        audienceRatesMap[audienceName] = { ...audience, name: audienceName };
                    });

                    const draft = {
                        ...services.apiValueToDraft(organization),
                        id: organization.id,
                        default_timezone: organization.default_timezone,
                        currency: organization.currency,
                        relationship,
                        creatives: organization.creatives,
                        tech_fee: formattedTechFeeForDraft,
                        infraCostPercentage: organization.infraCostPercentage,
                        audienceRatesMap,
                        restrictedSegments: organization.restrictedSegments,
                        ftaEnabled: organization.ftaEnabled,
                        fta_fee: organization.fta_fee,
                        fta_partner_id: organization.fta_partner_id,
                        privateDealTechFee: formattedPrivateDealTechFeeForDraft,
                        privateDealInfraCost: organization.privateDealInfraCost,
                        publicInventoryInfraCost: organization.publicInventoryInfraCost,
                        isIasPostBidVerificationEnabled:
                            organization.isIasPostBidVerificationEnabled,
                        privateInventorySpendTiers: organization.privateInventorySpendTiers,
                        publicInventorySpendTiers: organization.publicInventorySpendTiers,
                        blacklistedAppsAndSites: organization.blacklistedAppsAndSites,
                        whitelistedAppsAndSites: organization.whitelistedAppsAndSites,
                        applists: applists,
                    };

                    const users = _.map(organization.users, user => ({
                        value: user.id,
                        label: `${user.first_name} ${user.last_name}`,
                    }));

                    dispatch({
                        type: 'ADMIN_ORG_FORM__INIT_SUCCESS',
                        payload: { draft, relationship, users },
                    });
                } catch (error) {
                    notify({
                        error: new VError(
                            error,
                            'failed to initialize admin org data for admin form'
                        ),
                        metaData: {
                            orgId,
                            location: 'admin-org-form/actions: init()',
                            prevAction: 'ADMIN_ORG_FORM__INIT_START',
                        },
                    });
                }
            } else {
                try {
                    const query = `
                        query getApplists {
                            applists(filters: {}) {
                                id
                                name
                            }
                        }
                        `;
                    const { applists } = await http.graphql(query, {});
                    const relationship = 'child';
                    const organizationType =
                        type === 'media_buyer' || type === 'admin' ? type : 'client';
                    const client_type = type === 'media_buyer' || type === 'admin' ? '' : type;
                    const audienceRatesMap = {};
                    _.forEach(defaultAudienceRates, audience => {
                        audienceRatesMap[audience.name] = audience;
                    });
                    const envSettings = getEnvironmentSettings();
                    const ownOrganization = _.get(
                        getState(),
                        'adminV2.ownOrganization.organization'
                    );

                    // prepare default draft
                    let defaultDraft = {
                        ..._.cloneDeep(services.draftDefaultValues),
                        organization: void 0,
                        type: organizationType,
                        client_type,
                        restrictedSegments: ['pds_environics', 'pds_mastercard'],
                        audienceRatesMap,
                        creatives: {
                            standard: {
                                allow_create: true,
                                auto_approve: false,
                            },
                            video: {
                                allow_create: true,
                                auto_approve: false,
                            },
                            mraid: {
                                allow_create: true,
                                auto_approve: false,
                            },
                            custom_html: {
                                allow_create: true,
                                auto_approve: false,
                            },
                            native: {
                                allow_create: false,
                                auto_approve: false,
                            },
                            third_party_vast: {
                                allow_create: true,
                                auto_approve: false,
                            },
                        },
                        ftaEnabled: false,
                        fta_fee: 0,
                        fta_partner_id: '',
                        privateDealTechFee: null,
                        tech_fee: null,
                        publicInventoryInfraCost: null,
                        privateDealInfraCost: null,
                        isIasPostBidVerificationEnabled: false,
                        currency: envSettings.getDefaultCurrency(),
                        whitelistedAppsAndSites: [],
                        blacklistedAppsAndSites: [],
                        applists: applists,
                    };

                    if (!defaultDraft.agencyMarginRate) {
                        defaultDraft = {
                            ...defaultDraft,
                            agencyMarginRate: ownOrganization.agencyMarginRate,
                        };
                    }

                    if (organizationType === 'media_buyer') {
                        defaultDraft.privateInventorySpendTiers = [
                            {
                                techFee: null,
                                min: 0,
                                max: -1,
                                id: UUID.v4(),
                            },
                        ];
                        defaultDraft.publicInventorySpendTiers = [
                            {
                                techFee: null,
                                min: 0,
                                max: -1,
                                id: UUID.v4(),
                            },
                        ];
                        defaultDraft.infraCostPercentage = 0.18;
                        defaultDraft.privateDealInfraCost = 0;
                        defaultDraft.publicInventoryInfraCost = 0;
                    }

                    dispatch({
                        type: 'ADMIN_ORG_FORM__INIT_SUCCESS',
                        payload: {
                            draft: defaultDraft,
                            relationship,
                        },
                    });
                } catch (error) {
                    notify({
                        error: new VError(
                            error,
                            'failed to initialize admin org data for admin form'
                        ),
                        metaData: {
                            orgId,
                            location: 'admin-org-form/actions: init()',
                            prevAction: 'ADMIN_ORG_FORM__INIT_START',
                        },
                    });
                }
            }
        };
    },
    updateDraft(field, value) {
        return {
            type: 'ADMIN_ORG_FORM__UPDATE_DRAFT',
            payload: { field, value },
        };
    },
    save(refreshOrganization) {
        return async (dispatch, getState) => {
            const draft = _.get(getState(), `adminOrgForm.draft`, null);
            const orgId = _.get(getState(), `adminOrgForm.orgId`, null);
            const mode = _.get(getState(), `adminOrgForm.mode`, null);
            const relationshipInDraft = draft.relationship;

            dispatch({
                type: 'ADMIN_ORG_FORM__SUBMIT_DRAFT_START',
                payload: {},
            });

            const errors = _.get(getState(), 'adminOrgForm.errors', null);
            if (Object.keys(errors).length !== 0) {
                // can not send because there are erorrs in form
                return dispatch({
                    type: 'ADMIN_ORG_FORM__SHOW_ERRORS',
                    payload: {},
                });
            }
            let relationship;
            if (orgId) {
                relationship = _.get(getState(), 'adminOrgForm.relationship');
            }
            const meta = { orgId, relationship };
            const formattedDraft = formatDraft(draft);
            let payload = {
                ..._.cloneDeep(services.serializedForApi(formattedDraft, meta)),
                creatives: formattedDraft.creatives,
                audience_rates: formattedDraft.audience_rates,
                restrictedSegments: formattedDraft.restrictedSegments,
                ftaEnabled: formattedDraft.ftaEnabled,
                fta_fee: formattedDraft.fta_fee,
                fta_partner_id: formattedDraft.fta_partner_id,
                privateDealTechFee: formattedDraft.privateDealTechFee,
                publicInventoryInfraCost: formattedDraft.publicInventoryInfraCost,
                privateDealInfraCost: formattedDraft.privateDealInfraCost,
                isIasPostBidVerificationEnabled: formattedDraft.isIasPostBidVerificationEnabled,
                revenueModel: formattedDraft.revenueModel,
                agencyMarginRate: formattedDraft.agencyMarginRate,
                privateInventorySpendTiers: formattedDraft.privateInventorySpendTiers,
                publicInventorySpendTiers: formattedDraft.publicInventorySpendTiers,
                blacklistedAppsAndSites: formattedDraft.blacklistedAppsAndSites,
                whitelistedAppsAndSites: formattedDraft.whitelistedAppsAndSites,
            };

            if (payload.type === 'media_buyer') {
                if (_.isNil(payload.privateDealTechFee)) {
                    payload.privateDealTechFee = payload.privateInventorySpendTiers[0].techFee;
                }
                if (_.isNil(payload.tech_fee)) {
                    payload.tech_fee = payload.publicInventorySpendTiers[0].techFee;
                }
            }

            if (mode === 'edit' && relationshipInDraft === 'self') {
                payload = _.omit(payload, [
                    'audience_rates',
                    'restrictedSegments',
                    'creatives',
                    'ftaEnabled',
                    'fta_fee',
                    'fta_partner_id',
                    'privateDealTechFee',
                    'publicInventoryInfraCost',
                    'tech_fee',
                    'privateDealInfraCost',
                    'privateInventorySpendTiers',
                    'publicInventorySpendTiers',
                ]);
            }

            if (payload.type !== 'media_buyer') {
                payload = _.omit(payload, [
                    'tech_fee',
                    'privateDealTechFee',
                    'privateDealInfraCost',
                    'publicInventoryInfraCost',
                    'privateInventorySpendTiers',
                    'publicInventorySpendTiers',
                ]);
            }

            if (orgId) {
                try {
                    const org = await dispatch(Organizations.update(orgId, payload));
                    dispatch({
                        type: 'ADMIN_ORG_FORM__SUBMIT_DRAFT_SUCCESS',
                        payload: { orgId, org },
                    });
                    if (can(PermissionsMapping.IMPERSONATE)) {
                        dispatch({
                            type: c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_SUCCESS,
                            payload: {
                                organization: org,
                            },
                        });
                    }
                    const profile = _.get(getState(), 'profile');
                    if (
                        orgId === profile.organizationId &&
                        org.default_timezone !== profile.organizationTimezone
                    ) {
                        dispatch({
                            type: 'PROFILE__TIMEZONE__CHANGE',
                            payload: {
                                organizationTimezone: org.default_timezone,
                            },
                        });
                    }
                    dispatch(actions.closeForm());
                    refreshOrganization();
                } catch (error) {
                    dispatch({
                        type: 'ADMIN_ORG_FORM__SUBMIT_DRAFT_FAIL',
                        payload: { error },
                    });
                    if (error.code !== 400) {
                        notify({
                            error: new VError(
                                error,
                                'failed to submit admin org data for admin form'
                            ),
                            metaData: { orgId, mode, relationshipInDraft },
                        });
                    }
                }
            } else if (!orgId) {
                try {
                    const newOrg = await dispatch(Organizations.create(payload));
                    dispatch(Action_orgs.init(orgId));
                    dispatch({
                        type: 'ADMIN_ORG_FORM__SUBMIT_DRAFT_SUCCESS',
                        payload: { orgId, org: newOrg },
                    });

                    if (can(PermissionsMapping.IMPERSONATE)) {
                        dispatch({
                            type: c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_SUCCESS,
                            payload: {
                                organization: newOrg,
                            },
                        });
                    }
                    dispatch(actions.closeForm());
                    refreshOrganization();
                } catch (error) {
                    dispatch({
                        type: 'ADMIN_ORG_FORM__SUBMIT_DRAFT_FAIL',
                        payload: { error },
                    });
                    if (error.code !== 400) {
                        notify({
                            error: new VError(
                                error,
                                'failed to submit admin org data for admin form'
                            ),
                            metaData: { orgId, mode, relationshipInDraft },
                        });
                    }
                }
            }
        };
    },
    closeForm() {
        return dispatch => {
            dispatch({
                type: 'ADMIN_ORG_FORM__CLOSE_FORM',
            });
        };
    },
    addBillingTier(type) {
        return {
            type: 'ADMIN_ORG_FORM__ADD_BILLING_TIER',
            payload: { type },
        };
    },
    deleteBillingTier({ type, tierId }) {
        return {
            type: 'ADMIN_ORG_FORM__DELETE_BILLING_TIER',
            payload: { type, tierId },
        };
    },
    editBillingTierMax({ type, tierId, value }) {
        return {
            type: 'ADMIN_ORG_FORM__EDIT_BILLING_TIER_MAX',
            payload: { type, tierId, newMax: value },
        };
    },
    editBillingTierFee({ type, tierId, value }) {
        return {
            type: 'ADMIN_ORG_FORM__EDIT_BILLING_TIER_FEE',
            payload: { type, tierId, value },
        };
    },
};

export default actions;

import _ from 'lodash';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { combineNestedReducers, staticReducers } from './root-reducer';
import createLogger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';

import reduxModules from './redux-modules';

const dynamicMiddlewares = [];
export function createMyStore(args) {
    const { middlewares = [], initialState = {} } = args;

    const createStoreWithMiddleware = compose(applyMiddleware(thunk, ...middlewares))(createStore);

    const store = createStoreWithMiddleware(combineNestedReducers(staticReducers), initialState);

    store.asyncReducers = {};

    store.addMiddleware = middlewares => {
        dynamicMiddlewares.push(...middlewares.map(mw => mw(store)));
    };

    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    return store;
}

function createReducer(asyncReducers) {
    return combineNestedReducers({
        ...staticReducers,
        ...asyncReducers,
    });
}

function createRealStore() {
    let allMiddlewares = [];
    if (process.env.NODE_ENV !== 'test') {
        const dynamicMw = () => next => action => {
            return compose(...dynamicMiddlewares)(next)(action);
        };

        const historyMiddleware = routerMiddleware(browserHistory);

        const breadcrumbMiddleware = () => next => action => {
            if (action.payload) {
                window.bugsnagClient &&
                    window.bugsnagClient.leaveBreadcrumb(`action fired`, {
                        action: action.type.toString(),
                    });
            }
            return next(action);
        };

        const errorMiddleware = () => next => action => {
            try {
                return next(action);
            } catch (e) {
                console.error('Uncaught error in a redux action!', action, e);
                window.bugsnagClient &&
                    window.bugsnagClient.notify(e, {
                        metaData: {
                            type: 'uncaughtReduxActionError',
                            action,
                        },
                    });
            }
        };

        allMiddlewares = [breadcrumbMiddleware, errorMiddleware, thunk, historyMiddleware];

        if (!_.includes(['production', 'test'], process.env.NODE_ENV)) {
            const logger = createLogger();
            allMiddlewares.push(logger);
        }

        _.each(reduxModules, ({ middlewares }) => {
            if (!middlewares) {
                return;
            }
            _.each(middlewares, mw => {
                allMiddlewares.push(mw);
            });
        });
        allMiddlewares.push(dynamicMw);
    }

    return createMyStore({
        initialState: {},
        middlewares: allMiddlewares,
    });
}

const store = createRealStore();
window.__store = store;

export default store;

import FileSaver from 'utils/file-saver';
import _ from 'lodash';
import http from 'utils/http';

import AudienceActions from 'states/resources/audiences/actions';

function base64ToBlob(base64, _mimetype) {
    const mimetype = _mimetype || '';
    var bytechars = atob(base64);
    var bytearrays = [];
    for (var offset = 0; offset < bytechars.length; offset += 512) {
        var slice = bytechars.slice(offset, offset + 512);
        var bytenums = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            bytenums[i] = slice.charCodeAt(i);
        }
        var bytearray = new Uint8Array(bytenums);
        bytearrays[bytearrays.length] = bytearray;
    }
    return new Blob(bytearrays, { type: mimetype });
}

const actions = {
    init(filters) {
        return (dispatch, getState) => {
            dispatch({
                type: 'AUDIENCE_DASHBOARD__INIT__START',
                payload: {
                    filters,
                },
            });

            return dispatch(actions.fetchPageData(filters)).then(
                () => {
                    dispatch({
                        type: 'AUDIENCE_DASHBOARD__INIT__END',
                        payload: {},
                    });
                },
                error => {
                    dispatch({
                        type: 'AUDIENCE_DASHBOARD__INIT__ERROR',
                        payload: {},
                        error,
                    });

                    throw error;
                }
            );
        };
    },
    fetchPageData(filters) {
        return (dispatch, getState) => {
            const limit = _.get(getState(), 'audienceDashboard.itemsPerPage', 25);

            const query = {
                limit,
                page: filters.page || 1,
                search: filters.search,
            };

            dispatch({
                type: 'AUDIENCE_DASHBOARD__FETCH_PAGE_DATA__START',
                payload: {},
            });

            return dispatch(AudienceActions.getAll(query)).then(
                response => {
                    dispatch({
                        type: 'AUDIENCE_DASHBOARD__FETCH_PAGE_DATA__END',
                        payload: {
                            audiences: response.payload,
                            maxResults: response.meta.max_results,
                            page: query.page,
                        },
                    });
                },
                error => {
                    dispatch({
                        type: 'AUDIENCE_DASHBOARD__FETCH_PAGE_DATA__START',
                        payload: {},
                        error,
                    });

                    throw error;
                }
            );
        };
    },
    downloadAudience(id) {
        return (dispatch, getState) => {
            dispatch({
                type: 'AUDIENCE_DASHBOARD__DOWNLOAD__START',
                payload: { audienceIdBeingDownloaded: id },
            });

            const audience = _.get(getState(), `resources.audiences.${id}.attr`);

            return http()
                .get(`audience-download/${id}`)
                .then(
                    response => {
                        FileSaver.saveAs(
                            new Blob([response], { type: `text/plain;charset=utf-8` }),
                            `${audience.name}.csv`
                        );

                        dispatch({
                            type: 'AUDIENCE_DASHBOARD__DOWNLOAD__END',
                            payload: {},
                        });
                    },
                    err => {
                        console.error(`DOWNLOAD audience error`, err);

                        dispatch({
                            type: 'AUDIENCE_DASHBOARD__DOWNLOAD__END',
                            payload: {},
                        });

                        throw err;
                    }
                );
        };
    },
};
export default actions;

import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';

import http from 'utils/http';

const mapStateToProps = (storeState, ownProps) => {
    const orgId = get(storeState, 'profile.organizationId');
    return {
        orgId,
        ownProps,
    };
};
class ModuleRoot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            channel: null,
        };
    }

    componentDidMount() {
        this.fetchApp(this.props.channel_id);
    }

    fetchApp(appBundle) {
        this.setState({
            isLoading: true,
        });
        http()
            .get(`applications/${appBundle}`)
            .then(response => {
                this.setState({
                    channel: response,
                    isLoading: false,
                });
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    render() {
        if (this.state.isLoading) {
            return <div className="channel-details is-loading">... Loading</div>;
        }

        const channel_id = get(this.props, 'channel_id');
        const channel_type = get(this.props, 'channel_type');
        const channel_type_upper = channel_type.toUpperCase();

        const channel_name = get(this.props, 'channel_name');

        if (channel_id === '') {
            return (
                <div className="channel-details">
                    <div className="row unknown">
                        <p>[Not Available]</p>
                    </div>
                </div>
            );
        }

        const cat = get(this.state, 'channel.harmonized_category');
        const url = get(this.state, 'channel.app_url');

        const maturityRating = get(this.state, 'channel.harmonized_maturity');
        const developerName = get(this.state, 'channel.developer_name');
        const performance = get(this.state, 'channel.performance');
        const firstReleasedDate = get(this.state, 'channel.first_released_date');
        const rating = get(this.state, 'channel.rating');
        const numOfRating = get(this.state, 'channel.num_of_rating');
        const appTitle = get(this.state, 'channel.app_title');

        const title = appTitle || channel_name;

        return (
            <div className="channel-details">
                {!!rating && <RowDetails label="Rating" value={rating.toFixed(1)} />}
                {!!numOfRating && <RowDetails label="Total Ratings" value={numOfRating} />}
                {title && channel_type_upper === 'APP' && (
                    <RowDetails label="App name" value={title} />
                )}
                {channel_id && channel_type_upper === 'APP' && (
                    <RowDetails label="Bundle ID" value={channel_id} />
                )}
                {channel_id && channel_type_upper === 'SITE' && (
                    <RowDetails label="Domain" value={channel_id} />
                )}
                {cat && <RowDetails label="Category" value={cat} />}
                {maturityRating && <RowDetails label="Maturity Rating" value={maturityRating} />}
                {developerName && <RowDetails label="Publisher Info" value={developerName} />}
                {performance && <RowDetails label="Installs" value={performance} />}
                {firstReleasedDate && (
                    <RowDetails
                        label="First Released Date"
                        value={moment(firstReleasedDate).format('YYYY-MM-DD')}
                    />
                )}
                {url && (
                    <RowDetails
                        label="Url"
                        value={
                            <a href={url} target="_blank">
                                {url}
                            </a>
                        }
                    />
                )}
            </div>
        );
    }
}

function RowDetails({ label, value }) {
    return (
        <div className="row">
            <span className="property-label">{label}:</span>
            <span className="property-value">{value}</span>
        </div>
    );
}
export default connect(mapStateToProps)(ModuleRoot);

import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetAppIcon from '@mui/icons-material/GetApp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCheckbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { SortableChipRow } from 'widgets-v5/chip';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import FormField from 'widgets-v5/form-field';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import StandardInput from 'widgets-v5/standard-input';
import TimezoneSelector from 'widgets-v5/timezone-selector';
import { Label } from 'widgets-v5/typography';
import { TextField } from 'widgets-v6/standard-input';

import { useReportJobs } from 'containers/report-jobs/redux';
import { isInternalUser } from 'states/profile/business-rules';
import FilterSelector from './modules/filters-selector';
import ShowOverdeliveries from './modules/show-overdeliveries';
import SummaryDetails from './modules/summary-details';
import { getStartAndEndDate } from './selector';
import { useBusinessReportEditor } from './v2';
import { MetricList } from 'widgets-v5/metric-selector-2';
import flags from 'containers/flags/service';

export const LABELS = {
    date: 'Date Range',
    preset: 'Fixed Range',
    adEndDate: 'Ad End Date',
    dynamic: 'Dynamic Range',
    campaign: 'Campaign',
    include: 'Include',
    exclude: 'Exclude',
    organization: 'Organization',
};

class LayoutV2 extends React.Component {
    state = {
        filter: '',
        selectAll: false,
        expandedPanel: '',
    };

    constructor(props) {
        super(props);
        this.updateNameDebounced = _.debounce(name => this.props.updateName(name), 600);
    }

    setReportMode = e => {
        const { value } = e.target;
        this.props.changeReportMode(value);

        this.setState({
            filter: '',
            selectAll: false,
        });
    };

    onFilterInput = filter => {
        this.setState({
            filter,
        });
    };

    handleItemToggle = item => {
        let selectedItems;
        if (_.includes(this.props.draft.campaigns.ids, item.value)) {
            selectedItems = _.filter(this.props.draft.campaigns.ids, val => val !== item.value);
        } else {
            selectedItems = this.props.draft.campaigns.ids.concat(item.value);
        }

        this.props.updateDraft({
            campaigns: {
                ...this.props.draft.campaigns,
                ids: selectedItems,
            },
        });
    };

    removeAllSelectedItems = () => {
        this.props.updateDraft({
            campaigns: {
                ...this.props.draft.campaigns,
                ids: [],
            },
        });
    };

    removeSelectedItem = itemValue => {
        const removedSelectedItems = _.filter(
            this.props.draft.campaigns.ids,
            selectedItemValue => selectedItemValue !== itemValue
        );

        this.props.updateDraft({
            campaigns: {
                ...this.props.draft.campaigns,
                ids: removedSelectedItems,
            },
        });
    };

    selectAll = availableItems => {
        const availableItemsList = _.map(availableItems, item => item.value);

        const selectAll = !this.state.selectAll;

        this.props.updateDraft({
            campaigns: {
                ...this.props.draft.campaigns,
                ids: selectAll ? this.props.draft.campaigns.ids.concat(availableItemsList) : [],
            },
        });

        this.setState({
            selectAll,
        });
    };

    toggleExclude = () => {
        this.props.updateDraft({
            campaigns: {
                ...this.props.draft.campaigns,
                mode: this.props.draft.campaigns.mode === 'exclude' ? 'include' : 'exclude',
            },
        });
    };

    excludeCampaigns = () => {
        this.props.updateDraft({
            campaigns: {
                ...this.props.draft.campaigns,
                mode: 'exclude',
            },
        });
    };

    includeCampaignMode = () => {
        this.props.updateDraft({
            campaigns: {
                ...this.props.draft.campaigns,
                mode: 'include',
                ids: [],
            },
        });
    };

    getStartFromDateRange = dateRange => {
        if (dateRange.type === 'dynamic') {
            return moment()
                .subtract(dateRange.last, dateRange.lastFrame)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
        }

        return dateRange.start;
    };

    getEndFromDateRange = dateRange => {
        if (dateRange.type === 'dynamic') {
            if (dateRange.from === 'day_before_report_generation_date') {
                return moment()
                    .subtract(1, 'day')
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss');
            } else {
                return moment()
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss');
            }
        }

        return dateRange.end;
    };

    expandPanel = panel => {
        return () => {
            this.state.expandedPanel === panel
                ? this.setState({
                      expandedPanel: '',
                  })
                : this.setState({
                      expandedPanel: panel,
                  });
        };
    };

    getDimensionsMapping = () => {
        const mapping = {};
        _.each(this.props.dimensionCategories, category =>
            _.each(category.dimensions, dimension => {
                mapping[dimension.value] = { label: dimension.label };
                mapping[dimension.value].attributes = {};
                _.each(
                    dimension.attributes,
                    attribute =>
                        (mapping[dimension.value].attributes[attribute.value] = attribute.label)
                );
            })
        );

        return mapping;
    };

    getSummaryDetails = (filterType, timezone, options) => {
        const filter = this.props.draft[filterType];
        if (filterType === 'dateRange' || filterType === 'adEndDate') {
            const { start, end } = getStartAndEndDate(filter);
            // const timezone = this.props.draft.timezone;
            const startPrettyString = moment.tz(start, timezone).format('MMMM D, YYYY HH:mm z');
            const endPrettyString = moment.tz(end, timezone).format('MMMM D, YYYY HH:mm z');
            return (
                <React.Fragment>
                    {startPrettyString} - {endPrettyString}
                </React.Fragment>
            );
        } else if (filterType === 'campaigns') {
            const selectedCampaigns = _.filter(options, option => {
                return _.includes(filter.ids, option.value);
            });
            if (selectedCampaigns.length === 0) {
                return 'All Campaigns';
            }

            return (
                <React.Fragment>
                    {_.map(selectedCampaigns, campaign => (
                        <React.Fragment key={campaign.value}>{campaign.label}</React.Fragment>
                    ))}
                </React.Fragment>
            );
        } else if (filterType === 'organizationFilter') {
            const selectedorganizations = _.filter(options, option => {
                return _.includes(filter.ids, option.value);
            });

            if (selectedorganizations.length === 0) {
                return 'All Organizations';
            }

            return (
                <React.Fragment>
                    {_.map(selectedorganizations, org => (
                        <React.Fragment key={org.value}>{org.label}, </React.Fragment>
                    ))}
                </React.Fragment>
            );
        }
    };

    render() {
        const { draft, schemaVersion } = this.props;

        if (this.props.isLoading) {
            return (
                <BlockLoadGroup isLoading={this.props.isLoading}>
                    <div style={{ height: '100%' }} />
                </BlockLoadGroup>
            );
        }

        let engagements = draft.engagements;
        if (_.includes(engagements, 'overall_engagements')) {
            engagements = _(this.props.engagementOptions)
                .filter(b => b.value !== 'overall_engagements')
                .map(b => b.value)
                .value();
        }

        const requestMatch = {};
        if (draft.campaigns.ids.length > 0) {
            if (draft.campaigns.mode === 'include') {
                requestMatch.campaign_id = draft.campaigns.ids;
            } else if (draft.campaigns.mode === 'exclude') {
                requestMatch.campaign_id = _(this.props.campaignOptions)
                    .filter(o => {
                        const out = !_.includes(draft.campaigns.ids, o.value);
                        return out;
                    })
                    .map(o => o.value)
                    .value();
            }
        }
        requestMatch.camp_org = [this.props.profile.organizationId];

        const selections = {};
        _.each(this.props.draft.campaigns.ids, itemValue => (selections[itemValue] = true));

        const reportModeOptions = [
            { label: 'By Date', value: 'date' },
            { label: 'By Campaign', value: 'campaign' },
        ];

        if (isInternalUser()) {
            reportModeOptions.push({
                label: 'By Ad End Date',
                value: 'adEndDate',
            });
        }

        const dimensionsMapping = this.getDimensionsMapping();

        return (
            <React.Fragment>
                <Grid container justifyContent="space-between" alignItems="center">
                    <DialogTitle>
                        {this.props.businessReportId ? draft.name : 'New Report'}
                    </DialogTitle>
                    <div>
                        <IconButton
                            onClick={this.props.cancel}
                            disabled={this.props.isSaving}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Grid>
                <DialogContent dividers>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={5}>
                            <Box mb={2}>
                                <TextField
                                    label="Name"
                                    showErrors={this.props.showErrors}
                                    error={this.props.errors.name && this.props.showErrors}
                                    defaultValue={draft.name}
                                    onChange={e => this.updateNameDebounced(e.target.value)}
                                    placeholder="Untitled Report"
                                    helperText={this.props.showErrors && this.props.errors.name}
                                    fullWidth
                                    disableMargin
                                    required
                                />
                            </Box>
                        </Grid>

                        {schemaVersion === '2' && (
                            <Grid item xs={12}>
                                <Grid container direction="column">
                                    <Grid item xs={4}>
                                        <Box mb={2} mt={2}>
                                            <FormField
                                                className="reports-editor__form"
                                                label="Timezone"
                                                showErrors={this.props.showErrors}
                                                isRequired
                                            >
                                                <TimezoneSelector
                                                    timezone={draft.timezone}
                                                    onChange={this.props.updateTimezone}
                                                />
                                            </FormField>
                                        </Box>
                                    </Grid>
                                    {isInternalUser() && (
                                        <ShowOverdeliveries
                                            expandPanel={this.expandPanel}
                                            expandedPanel={this.state.expandedPanel}
                                            showErrors={this.props.showErrors}
                                            showOverdeliveriesError={
                                                this.props.errors.showOverdeliveries
                                            }
                                            draft={this.props.draft}
                                        />
                                    )}
                                    <AccordionItem
                                        onClick={this.expandPanel('filters')}
                                        expanded={this.state.expandedPanel === 'filters'}
                                        title="Filters"
                                        required
                                        error={
                                            this.props.showErrors &&
                                            (this.props.errors.dateRange ||
                                                this.props.errors.iabCategory ||
                                                this.props.errors.client ||
                                                this.props.errors['campaigns.ids'])
                                        }
                                        details={
                                            <React.Fragment>
                                                <FilterSelector
                                                    draft={this.props.draft}
                                                    start={this.getStartFromDateRange(
                                                        draft.dateRange
                                                    )}
                                                    end={this.getEndFromDateRange(draft.dateRange)}
                                                    updateDateRange={this.props.updateDateRange}
                                                    schemaVersion={schemaVersion}
                                                    showErrors={this.props.showErrors}
                                                    errors={this.props.errors}
                                                    updateIabCategory={this.props.updateIabCategory}
                                                    iabCategoryOptions={
                                                        this.props.iabCategoryOptions
                                                    }
                                                    updateClient={this.props.updateClient}
                                                    clientOptions={this.props.clientOptions}
                                                    selectAll={this.state.selectAll}
                                                    campaignOptions={this.props.campaignOptions}
                                                    engagementsOptions={
                                                        this.props.engagementOptions
                                                    }
                                                    getSummaryDetails={this.getSummaryDetails}
                                                    filters={this.props.filters}
                                                />
                                            </React.Fragment>
                                        }
                                        summary={<SummaryDetails />}
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('dimensionsAttributes')}
                                        expanded={
                                            this.state.expandedPanel === 'dimensionsAttributes'
                                        }
                                        title="Dimensions & Attributes"
                                        required
                                        error={
                                            this.props.showErrors &&
                                            (this.props.errors.dimensions ||
                                                this.props.errors.attributes)
                                        }
                                        details={
                                            <React.Fragment>
                                                <DimensionsSelector
                                                    dimensionsOptions={this.props.dimensionOptions}
                                                    dimensionsValues={draft.dimensions}
                                                    dimensionsOnChange={this.props.updateDimensions}
                                                    dimensionsMapping={dimensionsMapping}
                                                    ownOrganization={this.props.ownOrganization}
                                                    attributesOptions={this.props.attributeOptions}
                                                    attributesValues={draft.attributes}
                                                    attributesOnChange={this.props.updateAttributes}
                                                    dimensionCategories={
                                                        this.props.dimensionCategories
                                                    }
                                                    errors={this.props.errors}
                                                    showErrors={this.props.showErrors}
                                                />
                                                <div className="reports-editor__chip-row-and-apply-button">
                                                    <SortableChipRow
                                                        className="reports-editor__chip-row"
                                                        items={this.props.dimensionOptions}
                                                        value={draft.dimensions}
                                                        onChange={this.props.updateDimensions}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        }
                                        summary={
                                            draft.dimensions.length > 0 ? (
                                                _.map(draft.dimensions, dimension => (
                                                    <div key={dimension}>
                                                        <Label>
                                                            {dimensionsMapping[dimension].label}
                                                        </Label>
                                                        <Typography variant="caption">
                                                            Dimension
                                                        </Typography>
                                                        <Box
                                                            ml={3.5}
                                                            maxHeight={250}
                                                            overflow="auto"
                                                        >
                                                            {_.map(draft.attributes, attribute => {
                                                                if (
                                                                    dimensionsMapping[dimension]
                                                                        .attributes[attribute]
                                                                ) {
                                                                    return (
                                                                        <div key={attribute.value}>
                                                                            <Label>
                                                                                {
                                                                                    dimensionsMapping[
                                                                                        dimension
                                                                                    ].attributes[
                                                                                        attribute
                                                                                    ]
                                                                                }
                                                                            </Label>
                                                                            <Typography variant="caption">
                                                                                Attribute
                                                                            </Typography>
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                        </Box>
                                                    </div>
                                                ))
                                            ) : (
                                                <Label placeholder>Add a Dimension/Attribute</Label>
                                            )
                                        }
                                    />
                                    <AccordionItem
                                        onClick={this.expandPanel('metrics')}
                                        expanded={this.state.expandedPanel === 'metrics'}
                                        title="Metrics"
                                        required
                                        error={
                                            this.props.showErrors &&
                                            (this.props.errors.metrics ||
                                                this.props.errors.engagements)
                                        }
                                        details={<MetricsTabsV2 />}
                                        summary={<MetricSummary />}
                                    />
                                    {this.props.scheduledReport}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ExportReportJobButton id={this.props.businessReportId} />
                    {this.props.businessReportId && schemaVersion === '2' ? (
                        <React.Fragment>
                            <SaveModal
                                label="Save As"
                                title="Save New Report"
                                description="Save a new report with the same settings"
                                open={this.props.isSaveAsModalOpen}
                                onClose={this.props.closeSaveAsModal}
                                onSave={this.props.saveAs}
                                disabled={this.props.isSaving}
                                onClick={this.props.openSaveAsModal}
                            >
                                <FormField
                                    label="Untitled Report"
                                    isRequired={true}
                                    showErrors={this.props.showErrors}
                                    errors={this.props.errors.saveAsName}
                                >
                                    <StandardInput
                                        value={draft.saveAsName}
                                        onChange={this.props.updateSaveAsName}
                                    />
                                </FormField>
                            </SaveModal>
                            <Box mr={0.5}>
                                <Button
                                    onClick={this.props.save}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <Button
                            onClick={this.props.save}
                            disabled={this.props.isSaving}
                            variant="outlined"
                            color="primary"
                        >
                            Save
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        );
    }
}

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(0.5),
    },
    root: {
        flexGrow: 1,
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 200,
    },
    subcategory: {
        paddingLeft: theme.spacing(4),
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabPanel: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    showMoreBtn: {
        fontSize: '10px',
    },
    metricSelectorItem: {
        overflow: 'hidden',
    },
    metricSelectorItemLabel: {
        maxWidth: '100%',
        '& > span': {
            wordWrap: 'break-word',
            maxWidth: '90%',
            marginLeft: '7px',
            marginTop: '7px',
            marginRight: '25px',
            fontSize: '11px',
        },
    },
    fullWidth: {
        width: '100%',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function DimensionsSelector(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleAttributions = value => {
        if (expanded === value) {
            setExpanded('');
        } else {
            setExpanded(value);
        }
    };

    const checkedValues = {};
    _.each(props.dimensionsValues, value => {
        checkedValues[value] = true;
    });

    const checkedAttributesValues = {};
    _.each(props.attributesValues, value => {
        checkedAttributesValues[value] = true;
    });

    const changeDimensions = value => {
        const existingDimension = props.dimensionsValues;
        const selectedAttributes = props.attributesValues;
        const allDimensions = _(props.dimensionCategories)
            .map(category => category.dimensions)
            .flatten()
            .value();
        const currentDimension = _.find(allDimensions, dimension => dimension.value === value);
        const attributesInDimension = _.map(
            currentDimension.attributes,
            attribute => attribute.value
        );

        if (_.includes(existingDimension, value)) {
            const newValues = _.filter(existingDimension, dimension => dimension !== value);
            const newAttributes = _.filter(
                selectedAttributes,
                attribute => !_.includes(attributesInDimension, attribute)
            );
            props.dimensionsOnChange(newValues);
            props.attributesOnChange(newAttributes);
        } else {
            const newValues = _.concat(existingDimension, value);
            props.dimensionsOnChange(newValues);
        }
    };

    const changeAttributions = value => {
        const existingAttribution = props.attributesValues;
        const existingDimensions = props.dimensionsValues;
        const { dimensionCategories } = props;

        let attributeDimension = '';
        _.forEach(dimensionCategories, category => {
            _.forEach(category.dimensions, dimension => {
                const attributesInDimension = _.map(
                    dimension.attributes,
                    attribute => attribute.value
                );
                if (_.includes(attributesInDimension, value)) {
                    attributeDimension = dimension.value;
                }
            });
        });

        const isDimensionSelected = _.includes(existingDimensions, attributeDimension);
        if (_.includes(existingAttribution, value)) {
            const newValues = _.filter(existingAttribution, attribution => attribution !== value);
            props.attributesOnChange(newValues);
        } else {
            const newValues = _.concat(existingAttribution, value);
            props.attributesOnChange(newValues);
            if (!isDimensionSelected) {
                changeDimensions(attributeDimension);
            }
        }
    };
    const { errors, showErrors, ownOrganization } = props;
    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <Grid container>
                    <Grid item xs={7} className={classes.tabPanel}>
                        <Box display="flex" height="100%">
                            <Tabs
                                orientation="vertical"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                className={classes.tabs}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {_.map(props.dimensionCategories, (dimensionCat, index) => (
                                    <Tab
                                        key={dimensionCat.value}
                                        label={dimensionCat.label}
                                        {...a11yProps(index)}
                                    />
                                ))}
                            </Tabs>
                            <Box overflow="auto" maxHeight="300" width="100%">
                                {_.map(props.dimensionCategories, (dimensionCat, index) => (
                                    <TabPanel key={dimensionCat.value} value={value} index={index}>
                                        <List className={classes.list}>
                                            {_.map(dimensionCat.dimensions, dimension => (
                                                <React.Fragment key={dimension.value}>
                                                    <Tooltip
                                                        title={
                                                            dimension.isBlocked ? (
                                                                <Typography variant="body2">
                                                                    Data for this dimension is only
                                                                    available after April 1, 2023
                                                                </Typography>
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                        className={classes.chipButton}
                                                    >
                                                        <ListItem
                                                            disabled={dimension.isBlocked}
                                                            role={undefined}
                                                            dense
                                                        >
                                                            <ListItemIcon>
                                                                <MuiCheckbox
                                                                    color="primary"
                                                                    edge="start"
                                                                    checked={
                                                                        !!checkedValues[
                                                                            dimension.value
                                                                        ]
                                                                    }
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            dimension.value,
                                                                    }}
                                                                    disabled={dimension.isBlocked}
                                                                    onChange={() =>
                                                                        changeDimensions(
                                                                            dimension.value
                                                                        )
                                                                    }
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                id={dimension.value}
                                                                primary={dimension.label}
                                                                secondary="Dimension"
                                                            />
                                                            {dimension.attributes.length > 0 && (
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="comments"
                                                                        onClick={() =>
                                                                            toggleAttributions(
                                                                                dimension.value
                                                                            )
                                                                        }
                                                                        className={cn(
                                                                            classes.expand,
                                                                            {
                                                                                [classes.expandOpen]:
                                                                                    expanded ===
                                                                                    dimension.value,
                                                                            }
                                                                        )}
                                                                    >
                                                                        <ExpandMoreIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            )}
                                                        </ListItem>
                                                    </Tooltip>
                                                    <Collapse
                                                        in={expanded === dimension.value}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <List>
                                                            {_.map(
                                                                dimension.attributes,
                                                                attribution => {
                                                                    if (
                                                                        attribution.value ===
                                                                            'partner_attr' ||
                                                                        attribution.value ===
                                                                            'sales_rep_attr'
                                                                    ) {
                                                                        return (
                                                                            _.includes(
                                                                                ['admin'],
                                                                                _.get(
                                                                                    ownOrganization,
                                                                                    'type'
                                                                                )
                                                                            ) &&
                                                                            isInternalUser() && (
                                                                                <DimensionsSelectorListItem
                                                                                    attribution={
                                                                                        attribution
                                                                                    }
                                                                                    dimension={
                                                                                        dimension
                                                                                    }
                                                                                    checkedAttributesValues={
                                                                                        checkedAttributesValues
                                                                                    }
                                                                                    changeAttributions={
                                                                                        changeAttributions
                                                                                    }
                                                                                />
                                                                            )
                                                                        );
                                                                    }
                                                                    return (
                                                                        <DimensionsSelectorListItem
                                                                            attribution={
                                                                                attribution
                                                                            }
                                                                            dimension={dimension}
                                                                            checkedAttributesValues={
                                                                                checkedAttributesValues
                                                                            }
                                                                            changeAttributions={
                                                                                changeAttributions
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </List>
                                                    </Collapse>
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </TabPanel>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box p={2}>
                            <Typography variant="body1" gutterBottom>
                                Selected
                            </Typography>
                            <Box ml={2} overflow="auto" height={250}>
                                <List dense>
                                    {_.map(props.dimensionsValues, dimension => (
                                        <React.Fragment key={dimension}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        props.dimensionsMapping[dimension].label
                                                    }
                                                    secondary="Dimension"
                                                />
                                            </ListItem>
                                            <Box ml={2}>
                                                {_.map(props.attributesValues, attribute => {
                                                    if (
                                                        props.dimensionsMapping[dimension]
                                                            .attributes[attribute]
                                                    ) {
                                                        return (
                                                            <ListItem key={attribute}>
                                                                <ListItemText
                                                                    primary={
                                                                        props.dimensionsMapping[
                                                                            dimension
                                                                        ].attributes[attribute]
                                                                    }
                                                                    secondary="Attribute"
                                                                />
                                                            </ListItem>
                                                        );
                                                    }
                                                })}
                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {showErrors && (
                <div>
                    <FormHelperText error>{errors.dimensions}</FormHelperText>
                    <FormHelperText error>{errors.attributes}</FormHelperText>
                </div>
            )}
        </React.Fragment>
    );
}

function DimensionsSelectorListItem(props) {
    const { attribution, dimension, checkedAttributesValues, changeAttributions } = props;
    const classes = useStyles();

    return (
        <ListItem
            disabled={dimension.isBlocked}
            key={attribution.value}
            className={classes.subcategory}
            role={undefined}
            dense
            button
            onClick={() => changeAttributions(attribution.value)}
        >
            <ListItemIcon>
                <MuiCheckbox
                    color="primary"
                    edge="start"
                    checked={!!checkedAttributesValues[attribution.value]}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                        'aria-labelledby': attribution.value,
                    }}
                />
            </ListItemIcon>
            <ListItemText
                id={attribution.value}
                primary={attribution.label}
                secondary={attribution.category === 'custom' ? 'Attribute - Custom' : 'Attribute'}
            />
        </ListItem>
    );
}

function MetricSummary() {
    const { selectedMetrics } = useBusinessReportEditor({
        isInternalUser: isInternalUser(),
    });

    if (_.size(selectedMetrics) === 0) {
        return <Label placeholder>Add a Metric</Label>;
    }

    return (
        <Box>
            {_.map(selectedMetrics, category => (
                <React.Fragment key={category.categoryLabel}>
                    <Typography color="textSecondary">{category.categoryLabel}:</Typography>
                    <Box mb={1} ml={1} maxHeight={250} overflow="auto">
                        {_.map(category.metrics, metric => (
                            <Typography key={metric.metricKey}>{metric.metricLabel}</Typography>
                        ))}
                    </Box>
                </React.Fragment>
            ))}
        </Box>
    );
}

function MetricsTabsV2() {
    const { draft, metricMenu, selectedMetrics, toggleMetric, toggleSubevent, selectedSubevents } = useBusinessReportEditor({
        isInternalUser: isInternalUser(),
    });

    const classes = useStyles();
    const [tab, setTab] = React.useState('General');
    const [showMoreMetrics, setShowMoreMetrics] = React.useState([]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const categorySelected = _.find(metricMenu, { categoryLabel: tab });
    const grouped = _.groupBy(categorySelected.metrics, 'conversionName');

    return (
        <Paper className={classes.root}>
            <Grid container>
                <Grid item xs={7} className={classes.tabPanel}>
                    <Box display="flex" height="100%">
                        <Tabs
                            orientation="vertical"
                            value={tab}
                            onChange={handleTabChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {_.map(metricMenu, category => (
                                <Tab
                                    key={category.categoryLabel}
                                    value={category.categoryLabel}
                                    label={category.categoryLabel}
                                />
                            ))}
                        </Tabs>
                        <Box overflow="auto" maxHeight="300" width="100%">
                            {categorySelected && (tab === 'Conversions' && flags.isEnabled('en_4598_new_report_conversion_columns')) && (
                                <MetricList
                                    groupedMetricList={grouped}
                                    metricSelector_toggleVisiblity={({metricKey}) => toggleMetric(metricKey)}
                                    classes={classes}
                                    metricSelector_toggleSubevent={toggleSubevent}
                                    selectedSubevents={selectedSubevents}
                                    selectedMetrics={_.concat(
                                        draft.metrics,
                                        draft.engagements,
                                        draft.conversions
                                    )}
                                    showMoreMetrics={showMoreMetrics}
                                    setShowMoreMetrics={setShowMoreMetrics}
                                    newUiFlag
                                />
                            )}
                            {categorySelected && (tab !== 'Conversions' || !flags.isEnabled('en_4598_new_report_conversion_columns')) && (
                                <List>
                                    {_.map(categorySelected.metrics, metric => {
                                        return (
                                            <ListItem
                                                key={metric.isHeader ? `header_${metric.metricKey}` : metric.metricKey}
                                                dense
                                                button
                                                onClick={() => toggleMetric(metric.metricKey)}
                                            >
                                                <ListItemIcon>
                                                    <MuiCheckbox
                                                        color="primary"
                                                        edge="start"
                                                        checked={_.includes(
                                                            _.concat(
                                                                draft.metrics,
                                                                draft.engagements,
                                                                draft.conversions
                                                            ),
                                                            metric.metricKey
                                                        )}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    id={metric.metricKey}
                                                    primary={metric.metricLabel}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Box p={2}>
                        <Typography variant="body1" gutterBottom>
                            Selected
                        </Typography>
                        <Box ml={2} overflow="auto" height={250}>
                            {_.map(selectedMetrics, category => (
                                <React.Fragment key={category.categoryLabel}>
                                    <Typography color="textSecondary">
                                        {category.categoryLabel}:
                                    </Typography>
                                    <Box mb={1} ml={1} maxHeight={250} overflow="auto">
                                        {_.map(category.metrics, (metric, index) => (
                                            <Box ml={metric.isSubCategory ? 2 : 0} key={`${metric.metricKey}${index}`}>
                                                <Typography>
                                                    {metric.metricLabel}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

function SaveModal(props) {
    const classes = useStyles();

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={props.onClick}
                disabled={props.disabled}
                className={classes.button}
            >
                {props.label}
            </Button>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.description}</DialogContentText>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={props.onSave}
                        color="primary"
                        disabled={props.disabled}
                        variant="contained"
                        className={classes.button}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={props.onClose}
                        disabled={props.disabled}
                        className={classes.button}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function ExportReportJobButton(props) {
    const { id } = props;
    const { exportReport, exportErrorMessage, showExportErrors } = useReportJobs();
    const { errors } = useBusinessReportEditor({
        isInternalUser: isInternalUser(),
    });
    return (
        <React.Fragment>
            {showExportErrors && exportErrorMessage && (
                <FormHelperText error>{exportErrorMessage}</FormHelperText>
            )}
            <Button
                onClick={() => exportReport(id)}
                color="primary"
                variant="contained"
                startIcon={<GetAppIcon />}
                disabled={errors && errors.dateRange}
            >
                Export
            </Button>
        </React.Fragment>
    );
}

export default LayoutV2;

import _ from 'lodash';
import { browserHistory } from 'react-router';
import VError from 'verror';
import qs from 'utils/querystring';

import c from 'common/constants/flux-events';
import Ads from 'states/resources/ads/actions';
import AdGroupings from 'states/resources/ad-groupings/actions';

import { fetchCampaign } from 'states/resources/campaigns/actions';
import { createHttp } from 'utils/http';

import UserActions from 'states/resources/users/actions';

import AudienceActions from 'containers/audience-segment-picker/actions';
import notify from 'utils/notify';

export function filterList(campaignId, filterOptions) {
    return (dispatch, getState) => {
        const store_filterOptions = _.get(getState(), `adsOverview.filterOptions`);
        const query = {
            ...store_filterOptions,
            [filterOptions.filterType]: filterOptions.value,
        };

        try {
            browserHistory.push(`/campaigns/${campaignId}/setup/ads?${qs.serialize(query)}`);
        } catch (e) {
            console.error(
                `Failed to change URIs: ${`/campaigns/${campaignId}/setup/ads?${qs.serialize(
                    query
                )}`}`,
                e
            );
            if (window.bugsnagClient) {
                bugsnagClient.notify(`Failed to change URIs`, {
                    metaData: {
                        URI: `/campaigns/${campaignId}/setup/ads?${qs.serialize(query)}`,
                    },
                });
            }
        }

        dispatch({
            type: c.OVERVIEW_ADS__AD_LIST__FILTER,
            payload: {
                campaignId,
                filterType: filterOptions.filterType,
                value: filterOptions.value,
            },
        });
    };
}

export const fetchAudiencesForWarnings = ({ shouldHideFlexSegments }) => {
    return async dispatch => {
        await dispatch(AudienceActions.fetchAudiencesWithGraphQl(shouldHideFlexSegments));
    };
};

export const fetchUserPreferences = () => {
    return async (dispatch, getState) => {
        const id = _.get(getState(), `profile.userId`);

        const http = createHttp();
        const query = `
            query getUser ($id: String) {
                user(id: $id) {
                    _etag
                    preferences{
                        hiddenColumnsOnCampaignAdsListPage
                    }
                }
             }
        `;
        const variables = {
            id,
        };

        try {
            dispatch({
                type: 'OVERVIEW_ADS__USER_PREFERENCES_AND_ETAG',
                payload: {},
            });

            const fetchedUser = await http.graphql(query, variables);
            const { hiddenColumnsOnCampaignAdsListPage } = fetchedUser.user.preferences;
            const etag = fetchedUser.user._etag;

            dispatch({
                type: 'OVERVIEW_ADS__USER_PREFERENCES_AND_ETAG_FETCH__SUCCESS',
                payload: {
                    hiddenColumns: hiddenColumnsOnCampaignAdsListPage,
                    etag,
                },
            });
        } catch (err) {
            if (window.bugsnagClient) {
                bugsnagClient.notify(
                    `Failed to fetch user preferences for campaign ads list column display`,
                    {
                        metaData: {
                            URI: window.location.href,
                            userId: id,
                        },
                    }
                );
            }
            dispatch({
                type: 'OVERVIEW_ADS__USER_PREFERENCES_AND_ETAG_FETCH__FAIL',
            });
        }
    };
};

export function pause(campaignId, selectedAdIds) {
    return async (dispatch, getState) => {
        try {
            const ads = getState().adsOverviewV2.campaign.ads;
            const operations = _(selectedAdIds)
                .map(adId => _.find(ads, ad => ad.id === adId))
                .filter(ad => ad)
                .filter(ad => ad.paused === false)
                .map(ad => ({
                    id: ad.id,
                    input: {
                        paused: true,
                    },
                }))
                .value();

            if (_.isEmpty(operations)) {
                return;
            }

            dispatch({
                type: 'OVERVIEW_ADS__AD__PAUSE',
                payload: {
                    campaignId,
                    adIds: selectedAdIds,
                },
            });

            await dispatch(Ads.updateMany(campaignId, operations));

            dispatch({
                type: 'OVERVIEW_ADS__AD__PAUSE_SUCCESS',
                payload: {
                    campaignId,
                    adIds: selectedAdIds,
                },
            });
        } catch (err) {
            dispatch({
                type: 'OVERVIEW_ADS__AD__PAUSE_FAIL',
                payload: {
                    campaignId,
                    adIds: selectedAdIds,
                },
            });
        }
    };
}

export function unpause(campaignId, selectedAdIds) {
    return async (dispatch, getState) => {
        try {
            const ads = getState().adsOverviewV2.campaign.ads;
            const operations = _(selectedAdIds)
                .map(adId => _.find(ads, ad => ad.id === adId))
                .filter(ad => ad)
                .filter(ad => ad.paused === true)
                .map(ad => ({
                    id: ad.id,
                    input: {
                        paused: false,
                    },
                }))
                .value();

            if (_.isEmpty(operations)) {
                return;
            }
            dispatch({
                type: 'OVERVIEW_ADS__AD__UNPAUSE',
                payload: {
                    campaignId,
                    adIds: selectedAdIds,
                },
            });

            await dispatch(Ads.updateMany(campaignId, operations));

            dispatch({
                type: 'OVERVIEW_ADS__AD__UNPAUSE_SUCCESS',
                payload: {
                    campaignId,
                    adIds: selectedAdIds,
                },
            });
        } catch (err) {
            dispatch({
                type: 'OVERVIEW_ADS__AD__UNPAUSE_FAIL',
                payload: {
                    campaignId,
                    adIds: selectedAdIds,
                },
            });
        }
    };
}

export function deleteAd(campaignId, adId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_ADS__AD_DELETE,
            payload: {},
        });

        const confirmDelete = confirm(`Are you sure?\nYou are going to delete ad:\n#${adId}`);
        if (!confirmDelete) {
            return dispatch({
                type: c.OVERVIEW_ADS__AD_DELETE__FAIL,
                payload: {},
            });
        }

        return dispatch(Ads.delete(campaignId, adId)).then(
            () => {
                dispatch({
                    type: c.OVERVIEW_ADS__AD_DELETE__SUCCESS,
                    payload: {
                        adId,
                        campaignId,
                    },
                });

                dispatch(AdGroupings.getAll(campaignId));

                fetchCampaign(campaignId)(dispatch, getState);
            },

            error => console.error(error)
        );
    };
}

export function toggleColumn(column) {
    return (dispatch, getState) => {
        dispatch({
            type: 'OVERVIEW_ADS__TOGGLE_COLUMN',
            payload: { column },
        });

        const hiddenColumnsOnCampaignAdsListPage = _.get(getState(), `adsOverview.hiddenColumns`);

        const userId = _.get(getState(), `profile.userId`);
        const etag = _.get(getState(), `adsOverview.userEtag`);

        const hiddenColumns = _.pickBy(hiddenColumnsOnCampaignAdsListPage, value => !!value);

        const payload = {
            preferences: {
                hiddenColumnsOnCampaignAdsListPage: _.keys(hiddenColumns),
            },
        };
        const impersonatorId = _.get(getState(), `profile.impersonatorsToken.user.id`);

        if (impersonatorId === userId) {
            dispatch(UserActions.update(userId, payload, etag));
        }
    };
}

export function fetchAdsHealth(campaignId) {
    return async dispatch => {
        dispatch({
            type: 'OVERVIEW_ADS__ADS_HEALTH_FETCH__START',
            payload: {},
        });

        const http = createHttp();
        const query = `
            query getAdsHealth ($campaignId: Int) {
                campaign(id: $campaignId) {
                    ads {
                        id
                        metadata {
                            progress {
                                progressData {
                                    health
                                }
                            }
                        }
                    }
                }
             }
        `;
        const variables = {
            campaignId,
        };

        try {
            const { campaign: fetchedCampaign } = await http.graphql(query, variables);
            const { ads: fetchedAds } = fetchedCampaign;

            dispatch({
                type: 'OVERVIEW_ADS__ADS_HEALTH_FETCH__SUCCESS',
                payload: {
                    fetchedAds,
                },
            });
        } catch (error) {
            notify({
                error,
                metaData: {
                    URI: window.location.href,
                    campaignId,
                },
            });
            dispatch({
                type: 'OVERVIEW_ADS__ADS_HEALTH_FETCH__FAIL',
            });
        }
    };
}

export function selectAll(checked) {
    return (dispatch, getState) => {
        const ads = _.get(getState(), `adsOverviewV2.campaign.ads`);

        const nonStaleAds = _.filter(ads, ad => !ad.isStale);

        dispatch({
            type: 'OVERVIEW_ADS__SELECT_ALL',
            payload: { nonStaleAds, checked },
        });
    };
}

export function selectAd(adId, checked) {
    return dispatch => {
        dispatch({
            type: 'OVERVIEW_ADS__SELECT_AD',
            payload: { adId, checked },
        });
    };
}

export function openArchiveConfirmation({ campaignId, ad }) {
    return {
        type: 'OVERVIEW_ADS__ARCHIVE_CONFIRMATION__OPEN',
        payload: { campaignId, ad },
    };
}

export function closeArchiveConfirmation() {
    return {
        type: 'OVERVIEW_ADS__ARCHIVE_CONFIRMATION__CLOSE',
        payload: {},
    };
}

export function archiveAd({ campaignId, adId }) {
    return async dispatch => {
        try {
            const payload = {
                isArchived: true,
            };

            await dispatch(Ads.update(campaignId, adId, payload));

            dispatch({
                type: 'OVERVIEW_ADS__AD__ARCHIVE__SUCCESS',
                payload: { campaignId },
            });

            await dispatch(fetchCampaign(campaignId));
        } catch (err) {
            const error = new VError(err, `failed to archive ad ${adId}`);

            notify({
                error,
                metaData: {
                    adId,
                    campaignId,
                },
            });

            throw error;
        }
    };
}

export function toggleArchivedAdVisibility() {
    return {
        type: 'OVERVIEW_ADS__TOGGLE_ARCHIVE_AD_VISIBILITY',
    };
}

import _ from 'lodash';

import { defaultForApi } from './default-values';
import { creativeTypes, creativeSettings } from './setting';

function getCreativeSettingForApi(draft) {
    const nested = {};
    _.forEach(creativeTypes, type => {
        nested[type] = {};
        _.forEach(creativeSettings, setting => {
            nested[type][setting] = _.get(draft, `creatives-${type}-${setting}`, void 0);
        });
    });
    return { creatives: nested };
}

function serializedForApi(draft, meta) {
    const { orgId, relationship } = meta;

    // Figure out relationship of organization in draft
    let relationship_inDraft;
    if (orgId) {
        relationship_inDraft = relationship;
    } else {
        relationship_inDraft = 'child';
    }

    const fields = [
        'name',
        'client_type',
        'type',
        'credit_limit',
        'tech_fee',
        'infraCostPercentage',
        'auditors',
        'auditees',
        'currency',
        'default_timezone',
        'custom_fields',
        'revenueModel',
        'agencyMarginRate',
        'blacklistedAppsAndSites',
        'whitelistedAppsAndSites',
    ];

    let payload = {
        ...defaultForApi,
        ..._.pick(draft, fields),
        ...getCreativeSettingForApi(draft),
    };

    // Final Type parsing
    // ------------------
    payload.tech_fee = +payload.tech_fee; // must be a number

    // tech_fee
    // --------
    if (_.includes(['client', 'co_managed_client', 'admin'], payload.type)) {
        // tech_fee only applies only to media_buyers
        delete payload.tech_fee;
    }

    // auto_approve
    // ------------
    let includeAuto_approve = false;
    if (_.includes(['child'], relationship_inDraft)) {
        // only include auto_approve in payload if it is a child relationship
        includeAuto_approve = true;
    }
    if (!includeAuto_approve) {
        _.forEach(payload.creatives, creative => {
            delete creative.auto_approve;
        });
    }

    // Editing child organization
    // --------------------------
    if (orgId && _.includes(['child'], relationship_inDraft)) {
        switch (payload.type) {
            case 'client':
            // tech_fee has been deleted above
            case 'co_managed_client':
                // When edit, if it is child, the *only* property in payload is name
                payload = _.pick(payload, ['name']);
                break;
            case 'media_buyer':
                // send everything, including tech_fee
                break;
            case 'admin':
                // tech_fee has been deleted above
                break;
            default:
                break;
        }
    }

    // Editing self organization
    // --------------------------
    if (orgId && _.includes(['self'], relationship_inDraft)) {
        // An organization cannot modify its own creatives field
        delete payload.creatives;

        switch (payload.type) {
            case 'client':
                // tech_fee has been deleted above
                break;
            case 'co_managed_client':
                // tech_fee has been deleted above
                break;
            case 'media_buyer':
                break;
            case 'admin':
                // tech_fee has been deleted above
                break;
            default:
                break;
        }
    }

    // Creating organization
    // ---------------------
    // /* eslint-disable */
    if (!orgId) {
        switch (payload.type) {
            case 'client':
                // tech_fee has been deleted above
                payload = {
                    name: payload.name,
                    client_type: payload.client_type,
                    type: payload.type,
                };
                break;
            case 'co_managed_client':
                payload = {
                    name: payload.name,
                    type: payload.type,
                };
                break;
            case 'media_buyer':
                // send everything, including tech_fee
                break;
            case 'admin':
                // tech_fee has been deleted above
                break;
            default:
                break;
        }
    }
    /* eslint-enable */

    // Will send organizatin type on patch, but API will ignore it.
    console.log('serialized payload: ', JSON.stringify(payload, null, 4));
    return payload;
}

export default serializedForApi;

import _ from 'lodash';
import React, { useEffect } from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import classnames from 'classnames';

import selector from './selector';
import PivotTable from 'widgets/pivot-table';
import { CampaignExportButton } from 'widgets/pivot-table/export-button';
import HizeZeros from 'widgets/pivot-table/hide-zeros';

import actions from 'pages/campaigns/report/report-geo/actions';
import { ReportLayout } from 'pages/campaigns/report/report-overview/layout';
import { getIsZeroStat } from 'services/resources/stats';
import { formatNumber_percentage } from 'utils/formatting';
import { formatDimensions, formatMetrics } from 'widgets/pivot-table/service';

import {
    adgroup_toggleGroup,
    adgroup_reset,
    filterByDateRange,
    inititalizeReport,
    metricSelector_toggleVisiblity,
    updateTimezone,
    refreshReport,
} from 'pages/campaigns/report/report-geo/actions';
import { controls_setActiveScopeTab } from 'pages/campaigns/report/report-overview/actions';

import Card, { VerticalCardGroup } from 'widgets-v5/card';
import Tabs from 'widgets-v5/tabs';
import { BarGraphTile } from 'widgets-v5/bar-graph-tile';
import MetricSelector2 from 'widgets-v5/metric-selector-2';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { useReportExplore } from '../report-explore/v2/hook';
import { TIMEOUT_IDS } from '../constants';

const Geo = createReactClass({
    displayName: 'Geo',

    statics: {
        refresh(dispatch, nextState, isClientReportPage) {
            dispatch(inititalizeReport(nextState.params.campaignId, isClientReportPage));
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        const isClientReportPage = _.find(routes, route => route.path === 'client-report');

        Geo.refresh(dispatch, nextState, isClientReportPage);
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        const {
            params: { campaignId },
        } = this.props;

        if (campaignId !== prevProps.params.campaignId) {
            this.init();
            return;
        }

        // if (scopeFilter !== prevProps.scopeFilter) {
        //     dispatch(actions.refreshStats());
        //     return;
        // }

        // if (dateRange !== prevProps.dateRange) {
        //     dispatch(actions.refreshStats());
        //     return;
        // }
    },

    handleSort(column) {
        const { campaignId, dispatch } = this.props;
        dispatch(actions.sortColumn(campaignId, column));
    },

    handleExpand(rowId) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.expandRow(campaignId, rowId));
    },

    handleCollapse(rowId) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.collapseRow(campaignId, rowId));
    },

    handleAddSplit(split) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.addSplit(campaignId, split));
    },

    handleRemoveSplit(split) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.removeSplit(campaignId, split));
    },

    handleUpdateSplits(splits) {
        const { campaignId, dispatch } = this.props;

        dispatch(actions.updateSplits(campaignId, splits));
    },

    handleHideZerosToggle() {
        const { campaignId, dispatch } = this.props;
        dispatch(actions.hideZerosToggle(campaignId));
    },

    // copied

    controls_handleSetActiveScopeTab(scopeId) {
        const { campaignId } = this.props.params;
        this.props.dispatch(controls_setActiveScopeTab(campaignId, scopeId));
    },

    /* Metric Selector */
    metricSelector_toggleVisiblity(metric) {
        const { campaignId, dispatch } = this.props;
        dispatch(metricSelector_toggleVisiblity(campaignId, metric));
    },

    /* Bargraph handlers */
    adgroup_handleGroupToggle(component, grouping, group) {
        const { campaignId, dispatch } = this.props;

        dispatch(adgroup_toggleGroup(campaignId, component, grouping, group));
    },

    adgroup_handleReset(component) {
        const { campaignId, dispatch } = this.props;

        dispatch(adgroup_reset(campaignId, component));
    },

    filterByDateRange({ timeframe, start, end, selectedKey }) {
        const { campaignId, dispatch } = this.props;

        dispatch(filterByDateRange(campaignId, { timeframe, start, end, selectedKey }));
    },

    updateTimezone(timezone) {
        const { campaignId } = this.props.params;

        this.props.dispatch(updateTimezone(campaignId, timezone));
    },

    getTab() {
        const { routes } = this.props;
        const lastRoute = _.last(routes);

        return lastRoute.path;
    },

    serializePivotTable() {
        return this.refs.pivotTable.serialize();
    },

    getTimezone() {
        const { dateRange, organizationTimezone } = this.props;

        const defaultTimezone = organizationTimezone;

        return dateRange.timezone || defaultTimezone;
    },

    formatRow(row) {
        const { pivotTableState, dictionary, campaign } = this.props;

        let nextRow = { ...row };
        nextRow = formatDimensions(
            nextRow,
            pivotTableState.pivotTable.splits,
            dictionary,
            campaign
        );

        const adsAttribute = _.get(nextRow, 'attributes.ads', []);
        const billingRateLabel = _(adsAttribute)
            .sortBy(['billing_enabled', 'billing_term', 'billing_rate'])
            .map(ad => {
                if (!ad.billing_enabled) {
                    return 'Bonus';
                }
                const isCpmCpc = _.includes(['CPM', 'CPC'], ad.billing_term);

                if (isCpmCpc) {
                    return `$${ad.billing_rate} ${ad.billing_term}`;
                } else {
                    return `${formatNumber_percentage(ad.billing_rate)}`;
                }
            })
            .uniq()
            .join(', ');

        nextRow = {
            ...nextRow,
            columnData: {
                ...nextRow.columnData,
                billing_rate: billingRateLabel,
            },
        };

        return nextRow;
    },

    refreshPageData() {
        const { campaignId } = this.props.params;

        this.props.dispatch(refreshReport(campaignId));
    },

    render() {
        const { isInitialized } = this.props;

        if (!isInitialized) {
            return (
                <div className="ef6-alignment__center" style={{ width: '100%', height: '100%' }}>
                    <BlockLoadGroup isLoading={true} />
                </div>
            );
        }

        const {
            pivotTableState,
            campaign,
            dictionary,
            dateRange,
            metricSelector,
            selectedMetrics,

            // copied
            scopeComponents,
            activeScopeTab,

            metrics,
            selectedMetric,
            columns,
            campaignRelationship,
        } = this.props;

        const timezone = this.getTimezone();

        const activeScopeComponent = _.filter(scopeComponents, component => {
            return component.grouping === activeScopeTab;
        }).map(component => {
            return (
                <BarGraphTile
                    {...component}
                    key={component.id}
                    onReset={this.adgroup_handleReset}
                    onCheckboxSelected={this.adgroup_handleGroupToggle}
                />
            );
        })[0];

        return (
            <ReportLayout
                refreshPageData={this.refreshPageData}
                campaignRelationship={campaignRelationship}
                selectedMetric={selectedMetric}
                campaign={campaign}
                updateTimezone={this.updateTimezone}
                timezone={timezone}
                existingTimezones={[this.props.organizationTimezone, campaign.default_timezone]}
                dateRange={dateRange}
                metricSelector={
                    metricSelector &&
                    campaign && (
                        <React.Fragment>
                            <MetricSelector2
                                campaign={campaign}
                                showSelectionIcon={false}
                                statsMetricsType_visibility={
                                    metricSelector.statsMetricsType_visibility
                                }
                                isVisibleBeaconsAtLimit={metricSelector.isVisibleBeaconsAtLimit}
                                metricType={metricSelector.metricType}
                                selectedMetric={metricSelector.selectedMetric}
                                totalFilteredStats={metricSelector.totalFilteredStats}
                                currency={metricSelector.currency}
                                campaignBillingEnabled={campaign.billing_enabled}
                                metricComponentsConfig={metricSelector.metricComponentsConfig}
                                metricSelector_toggleVisiblity={this.metricSelector_toggleVisiblity}
                                selectedMetrics={selectedMetrics}
                            />
                        </React.Fragment>
                    )
                }
                workspace={
                    <VerticalCardGroup>
                        <Card>
                            <div className="ef5-reports__scopes">
                                {scopeComponents && scopeComponents.length > 1 && (
                                    <Tabs
                                        activeItem={activeScopeTab}
                                        onChange={value => {
                                            this.controls_handleSetActiveScopeTab(value);
                                        }}
                                        items={scopeComponents.map(scope => ({
                                            label: scope.title,
                                            value: scope.grouping,
                                        }))}
                                    />
                                )}
                                <div
                                    className={classnames('ef5-reports__scope-dimension', {
                                        'ef5-reports__scope-dimension_multiple-scopes':
                                            scopeComponents && scopeComponents.length > 1,
                                    })}
                                >
                                    {activeScopeComponent}
                                </div>
                            </div>
                        </Card>
                        <Card>
                            {pivotTableState && (
                                <PivotTable
                                    ref="pivotTable"
                                    timezone={timezone}
                                    config={pivotTableState.pivotTable}
                                    dictionary={dictionary}
                                    columns={columns}
                                    onSerializeFormatRow={row => {
                                        let nextRow = { ...row };
                                        nextRow = this.formatRow(nextRow);

                                        return nextRow;
                                    }}
                                    onRenderFormatRow={(row, columns) => {
                                        let nextRow = { ...row };
                                        nextRow = formatMetrics(nextRow, columns);
                                        nextRow = this.formatRow(
                                            nextRow,
                                            pivotTableState.pivotTable.splits,
                                            dictionary,
                                            campaign
                                        );

                                        return nextRow;
                                    }}
                                    sortByHook={(sorter, columnName, row, dictionary) => {
                                        switch (columnName) {
                                            case 'billing_rate': {
                                                return row.columnData.billing_rate;
                                            }
                                            case 'dimension': {
                                                if (
                                                    row.dimension === 'date' ||
                                                    row.dimension === 'week_by_monday_to_sunday' ||
                                                    row.dimension ===
                                                        'week_by_campaign_start_day' ||
                                                    row.dimension === 'month' ||
                                                    row.dimension === 'hour'
                                                ) {
                                                    return row.group;
                                                }
                                            }
                                            default:
                                                return sorter(columnName, row, dictionary);
                                        }
                                    }}
                                    filterRowHook={(filterRow, row, columns) => {
                                        if (filterRow(row, columns) === false) {
                                            return false;
                                        }

                                        if (pivotTableState.hideZeros) {
                                            return !getIsZeroStat(row.stats, metrics);
                                        }

                                        return true;
                                    }}
                                    topbarWorkspace={
                                        <span>
                                            <HizeZeros
                                                onHideZeroRows={this.handleHideZerosToggle}
                                                shouldHideZeros={pivotTableState.hideZeros}
                                            />
                                            &nbsp;
                                            <CampaignExportButton
                                                campaign={campaign}
                                                dateRange={dateRange}
                                                serializePivotTable={this.serializePivotTable}
                                                dictionary={dictionary}
                                            />
                                        </span>
                                    }
                                    onSort={this.handleSort}
                                    onExpand={this.handleExpand}
                                    onCollapse={this.handleCollapse}
                                    onAddSplit={this.handleAddSplit}
                                    onRemoveSplit={this.handleRemoveSplit}
                                    onUpdateSplits={this.handleUpdateSplits}
                                />
                            )}
                        </Card>
                    </VerticalCardGroup>
                }
                filterByDateRange={this.filterByDateRange}
                tab={this.getTab()}
            />
        );
    },
});

function GeoWrapper(props) {
    const { removeTimeouts } = useReportExplore();
    useEffect(() => () => removeTimeouts(TIMEOUT_IDS), [])
    return <Geo {...props} />;
}

export default connect(selector)(GeoWrapper);

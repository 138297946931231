import _ from 'lodash';
import moment from 'moment-timezone';

const DEFAULT_DAYPARTS = {
    start: '00:00:00',
    end: '23:00:00',
};

const GOAL_MAPPING = {
    impressions: 'impressions',
    clicks: 'clicks',
    billings: 'billings_local',
    spend: 'owner_total_media_cost_local',
};

const STATUS_MAPPING = {
    PENDING: 'pending',
    BAD: 'bad',
    OK: 'ok',
    GOOD: 'good',
};

const PERCENTAGE_THRESHOLD_MAPPING = {
    GOOD: 0.1,
    OK: 0.2,
};

const getPrimaryPacingKey = ad => {
    if (_.includes(ad.primary_pacing, 'spend')) {
        return `max_total_${ad.primary_pacing}_local`;
    } else {
        return `max_total_${ad.primary_pacing}`;
    }
};


export const getTotalTarget = ad => {
    const totalTarget = ad[getPrimaryPacingKey(ad)];
    return totalTarget ? totalTarget : 0;
};

const getHourDifference = (earlierDateTime, laterDateTime) => {
    return laterDateTime.diff(earlierDateTime, 'hours');
};

export const getTimeAxisData = ({ start, end, timezone }) => {
    const output = [];
    const quantizedStart = moment.tz(start, timezone).startOf('hour');
    const quantizedEnd = moment.tz(end, timezone).startOf('hour');

    output.push(quantizedStart.format('MMM D, HH:mm:ss z'));

    let datePointer = quantizedStart;

    while (getHourDifference(datePointer, quantizedEnd) > 0) {
        datePointer.add(1, 'hours');
        output.push(datePointer.format('MMM D, HH:mm:ss z'));
    }

    if (moment(quantizedStart).isDST() !== moment(quantizedEnd).isDST()) {
        datePointer.add(1, 'hours');
        output.push(datePointer.format('MMM D, HH:mm:ss z'));
    }
    return output;
};

const getTimeParts = ({ dayparts, weekparts, timeAxisData }) => {
    let processedDayParts = dayparts;

    if (processedDayParts.length === 0) {
        processedDayParts.push(DEFAULT_DAYPARTS);
    }

    const validDayPartHours = _.uniq(
        _.flatten(
            _.map(processedDayParts, daypart => {
                const startHour = parseInt(daypart.start.split(':')[0]);

                const endHour = parseInt(daypart.end.split(':')[0]);
                const endMinute = parseInt(daypart.end.split(':')[1]);

                const endHourShiftedForward = endHour + 1;

                const validHourRange = _.range(
                    startHour,
                    (endMinute > 0 ? endHourShiftedForward : endHour) + 1
                );

                return validHourRange;
            })
        )
    );

    const validWeekpartDays = [];

    _.each(weekparts, (weekpart, index) => {
        if (weekpart) {
            validWeekpartDays.push(index + 1);
        }
    });

    const validTimeParts = [];

    _.each(timeAxisData, item => {
        const datetime = moment(item);
        if (
            _.includes(validDayPartHours, datetime.hour()) &&
            _.includes(validWeekpartDays, datetime.isoWeekday())
        ) {
            validTimeParts.push(item);
        }
    });

    return _.difference(timeAxisData, validTimeParts);
};

export const getRightPaddedTimeAxisData = (end, timeAxisData, timezone) => {
    const lastTimeAxisPoint = moment.tz(end, timezone).startOf('hour');
    const rightPaddedTimePoint = lastTimeAxisPoint.add(1, 'hours');

    return _.concat(timeAxisData, [rightPaddedTimePoint.format('MMM D, HH:mm:ss z')]);
};

const getDeliveredTargetDateTimeMapping = ({ stats, goal, timezone }) => {
    const mapping = {};
    _.each(stats, stat => {
        const utc_key = moment(stat.date)
            .hour(stat.hour)
            .format('MMM D, HH:mm:ss z');
        const key = moment.tz(utc_key, timezone).format('MMM D, HH:mm:ss z');
        const value = stat[GOAL_MAPPING[goal]];
        if (mapping[key]) {
            mapping[key] += value;
        } else {
            mapping[key] = value;
        }
    });

    return mapping;
};


export const getCurrentDeliveredTarget = ({ stats, rightPaddedTimeAxisData, goal, timezone }) => {
    let accumulator = 0;
    const mapping = getDeliveredTargetDateTimeMapping({ stats, goal, timezone });
    const plotBuffer = [];

    _.each(rightPaddedTimeAxisData, item => {
        if (mapping[item]) {
            accumulator += mapping[item];
        }
        plotBuffer.push({
            original: mapping[item] ? mapping[item] : 0,
            accumulated: accumulator,
        });
    });

    _.eachRight(plotBuffer, item => {
        if (item.original === 0) {
            item.accumulated = 0;
        } else {
            return false;
        }
    });

    const plot = _.map(plotBuffer, ({ accumulated }) => accumulated);
    return {
        plot,
    };
};

const getIdealFrontLoadedPacingLine = ({ totalTarget, timePoints, timeParts }) => {
    const difference = _.difference(timePoints, timeParts);
    let [firstTercile, secondTercile, thirdTercile, remainder] = _.chunk(
        difference,
        Math.floor(difference.length / 3)
    );

    if (remainder) {
        thirdTercile = _.concat(thirdTercile, remainder);
        if (remainder.length === 2) {
            const firstElementOfThirdTercile = _.take(thirdTercile);
            thirdTercile = _.difference(thirdTercile, firstElementOfThirdTercile);
            secondTercile = _.concat(secondTercile, firstElementOfThirdTercile);
        }
    }

    const yValueMapping = {};

    const firstTercileDelivery = totalTarget * 0.5;
    const secondTercileDelivery = totalTarget * 0.3;
    const thirdTercileDelivery = totalTarget * 0.2;

    _.each(firstTercile, (item, index) => {
        yValueMapping[item] = firstTercileDelivery * ((index + 1) / firstTercile.length);
    });

    _.each(secondTercile, (item, index) => {
        yValueMapping[item] =
            secondTercileDelivery * ((index + 1) / secondTercile.length) + firstTercileDelivery;
    });

    _.each(thirdTercile, (item, index) => {
        yValueMapping[item] =
            thirdTercileDelivery * ((index + 1) / thirdTercile.length) +
            firstTercileDelivery +
            secondTercileDelivery;
    });

    const plot = [0];
    let lastValue = 0;

    _.each(timePoints, item => {
        if (!_.includes(timeParts, item)) {
            lastValue = yValueMapping[item];
        }
        plot.push(lastValue);
    });

    const markArea = _.map(timeParts, item => {
        return [
            {
                name: item > 0 && timePoints.length < 40 ? item : '',
                xAxis: item - 1,
            },
            {
                xAxis: item,
            },
        ];
    });

    return {
        plot,
        markArea,
    };
};

const getLastThreeDaysTrend = ({ mapping, timeParts }) => {
    const hrs = _.map(mapping, (key, value) => ({ time: value, impressions: key })).filter(
        h => !_.includes(timeParts, h.time)
    );
    const hourSize = _.size(hrs);
    if (hourSize < 72) {
        return _.sumBy(hrs, hr => hr.impressions) / hourSize;
    }
    const lastFiftyHours = _.slice(hrs, hourSize - 72, hourSize);
    const lastImpressions = _.sumBy(lastFiftyHours, hr => hr.impressions);
    const ratio = lastImpressions / 72;
    return ratio;
};

const getProjectedDeliveredTarget = ({
    stats,
    rightPaddedTimeAxisData,
    goal,
    currentDeliveredTarget,
    timeParts,
    idealTargetDelivery,
    timezone,
}) => {
    const mapping = getDeliveredTargetDateTimeMapping({ stats, goal, timezone });
    const lastAverageImpressions = getLastThreeDaysTrend({ mapping, timeParts });

    let lastPlotPoint = {};
    let prevVal = 0;
    _.each(currentDeliveredTarget.plot, (item, index) => {
        if (prevVal > item) {
            lastPlotPoint = { lastIndex: index, lastVal: prevVal };
            return false;
        }
        prevVal = item;
    });

    const plotBuffer = [];
    const lastIndex = lastPlotPoint.lastIndex;
    let accumulator = lastPlotPoint.lastVal;
    // if current delivery above ideal, don't draw projection
    if (idealTargetDelivery.plot[lastIndex] < accumulator) {
        return plotBuffer;
    }

    _.each(rightPaddedTimeAxisData, (item, index) => {
        // Found Starting point of drawing projection line
        if (index > lastIndex) {
            // If projection is above ideal, use ideal data points
            plotBuffer.push(_.min([accumulator, idealTargetDelivery.plot[index]]));

            if (!_.includes(timeParts, item)) {
                accumulator += lastAverageImpressions;
            }
        } else {
            plotBuffer.push(0);
        }
    });
    return plotBuffer;
};

const getIdealEvenPacingLine = ({ totalTarget, timePoints, timeParts }) => {
    const difference = _.difference(timePoints, timeParts);

    const yValueMapping = {};

    _.each(difference, (item, index) => {
        yValueMapping[item] = totalTarget * ((index + 1) / difference.length);
    });

    const plot = [0];
    let lastValue = 0;

    _.each(timePoints, item => {
        if (!_.includes(timeParts, item)) {
            lastValue = yValueMapping[item];
        }
        plot.push(lastValue);
    });

    const markArea = _.map(timeParts, item => {
        return [
            {
                name: item > 0 && timePoints.length < 40 ? item : '',
                xAxis: item - 1,
            },
            {
                xAxis: item,
            },
        ];
    });

    return {
        plot,
        markArea,
    };
};


const getPercentageRemaining = ({ idealPlot, latestDeliveryIndex }) => {
    if (idealPlot.length) {
        return 1 - (latestDeliveryIndex + 1) / idealPlot.length;
    }
    return 0;
};

const getLatestDeliveryIndex = plot => {
    let latestDeliveryIndex = 0;
    let hasNotStartedYet = false;
    _.eachRight(plot, (item, index) => {
        if (item !== 0) {
            latestDeliveryIndex = index;
            return false;
        }
        if (index === 0) {
            hasNotStartedYet = true;
        }
    });
    return {
        latestDeliveryIndex,
        hasNotStartedYet,
    };
};

const getAdHealth = ({ idealTargetDelivery, currentDeliveredTarget }) => {
    const { plot: idealPlot } = idealTargetDelivery;
    const { plot: currentPlot } = currentDeliveredTarget;

    const { latestDeliveryIndex, hasNotStartedYet } = getLatestDeliveryIndex(currentPlot);

    if (hasNotStartedYet) {
        return STATUS_MAPPING.PENDING;
    }

    const idealDelivery = idealPlot[latestDeliveryIndex];
    const currentDelivery = currentPlot[latestDeliveryIndex];

    const percentageRemaining = getPercentageRemaining({ idealPlot, latestDeliveryIndex });

    const goodFillThreshold =
        idealDelivery * (1 - PERCENTAGE_THRESHOLD_MAPPING.GOOD * percentageRemaining);
    const okFillThreshold =
        idealDelivery * (1 - PERCENTAGE_THRESHOLD_MAPPING.OK * percentageRemaining);

    if (currentDelivery < okFillThreshold) {
        return STATUS_MAPPING.BAD;
    } else if (currentDelivery < goodFillThreshold) {
        return STATUS_MAPPING.OK;
    } else {
        return STATUS_MAPPING.GOOD;
    }
};

export const getProgressData = ({ ad, stats, timezone }) => {
    const {
        start,
        end,
        dayparts,
        weekparts,
        use_front_load_pacing: useFrontLoadedPacing,
        primary_pacing: goal,
    } = ad;

    const totalTarget = getTotalTarget(ad);

    const timeAxisData = getTimeAxisData({ start, end, timezone });
    const timeParts = getTimeParts({ dayparts, weekparts, timeAxisData });
    const rightPaddedTimeAxisData = getRightPaddedTimeAxisData(end, timeAxisData, timezone);

    let idealTargetDelivery;
    const currentDeliveredTarget = getCurrentDeliveredTarget({
        stats,
        rightPaddedTimeAxisData,
        goal,
        timezone,
    });

    if (useFrontLoadedPacing) {
        idealTargetDelivery = getIdealFrontLoadedPacingLine({
            totalTarget,
            timePoints: timeAxisData,
            timeParts,
        });
    } else {
        idealTargetDelivery = getIdealEvenPacingLine({
            totalTarget,
            timePoints: timeAxisData,
            timeParts,
        });
    }

    const projectedTarget = getProjectedDeliveredTarget({
        stats,
        rightPaddedTimeAxisData,
        goal,
        currentDeliveredTarget,
        timeParts,
        idealTargetDelivery,
        timezone,
    });

    const health = getAdHealth({
        idealTargetDelivery,
        currentDeliveredTarget,
    });

    return {
        idealTargetDelivery,
        currentDeliveredTarget,
        projectedTarget,
        health,
        timeAxisData: rightPaddedTimeAxisData,
        totalTarget,
        goal,
    };
};

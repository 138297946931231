import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class HealthIndicator extends React.Component {
    static propTypes = {
        status: PropTypes.oneOf(['good', 'ok', 'bad', 'pending', null, 'loading']),
        margin: PropTypes.string,
        badge: PropTypes.bool,
    };

    static defaultProps = {
        margin: '5',
        badge: false,
    };

    render() {
        return (
            <span
                style={{ margin: this.props.margin }}
                className={classnames(
                    this.props.className,
                    'ef3-healthIndicator',
                    `ef3-healthIndicator__is-${this.props.status}`,
                    { 'ef3-healthIndicator__badge': this.props.badge }
                )}
            >
                {this.props.status === 'loading' ? (
                    <i className="fa fa-circle-o ef3-healthIndicator__icon" />
                ) : (
                    <i className="fa fa-circle ef3-healthIndicator__icon" />
                )}
                {this.props.label}
            </span>
        );
    }
}

export default HealthIndicator;

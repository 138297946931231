import _ from 'lodash';

export const NAME = 'flexCategoriesDashboard';

const initialState = {
    loading: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;

        case 'FLEX_CATEGORIES_DASHBOARD__FETCH_CATEGORIES_DATA_START': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'FLEX_CATEGORIES_DASHBOARD__FETCH_CATEGORIES_DATA_END': {
            return {
                ...state,
                categories: action.payload.data,
                loading: false,
            };
        }
        case 'FLEX_CATEGORIES_DASHBOARD__FETCH_CATEGORIES_DATA_ERROR': {
            return {
                ...state,
                loading: false,
            };
        }
    }
}

import _ from 'lodash';
import React from 'react';
import { flatMap } from 'utils/enum';

class FilterSummaryItem extends React.Component {
    resetOptions = () => {
        if (this.props.onClearItem) {
            this.props.onClearItem(this.props.grouping);
        }
    };

    render() {
        return (
            <div className="am-reports-filterBar-filters-item" onClick={this.resetOptions}>
                <span className="am-reports-filterBar-key">
                    {this.props.groupingPresentationName}
                </span>
                {this.props.groups.map((group, index) => (
                    <div className="am-reports-filterBar-values" key={group.name}>
                        {index !== 0 ? ',' : null}
                        <span className="am-reports-filterBar-values-value" title={group.title}>
                            {group.title}
                        </span>
                    </div>
                ))}
                <i className="fa fa-close" />
            </div>
        );
    }
}

class FilterSummary extends React.Component {
    clearAllFilter = () => {
        if (this.props.onClearAllFilters) {
            this.props.onClearAllFilters();
        }
    };

    render() {
        const { isBarVisible, filterItems, shouldShowClearButton } = this.props;

        if (!isBarVisible) {
            return null;
        }

        return (
            <div className="am-reports-filterBar-row">
                <label>{this.props.title}</label>
                <div className="am-reports-filterBar-filters">
                    {filterItems.map(filterItem => {
                        return filterItem.groups.length > 0 ? (
                            <FilterSummaryItem
                                key={_.uniqueId()}
                                groups={filterItem.groups}
                                groupingPresentationName={filterItem.groupingPresentationName}
                                grouping={filterItem.grouping}
                                onClearItem={this.props.onClearItem}
                            />
                        ) : null;
                    })}

                    {shouldShowClearButton ? (
                        <span
                            className="am-reports-filterBar-clearAllBtn"
                            onClick={this.clearAllFilter}
                        >
                            <span>Clear All</span>
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default FilterSummary;

import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

class Checkbox extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        description: PropTypes.string,
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        checkType: PropTypes.string,
        justify: PropTypes.string,
        strikeThrough: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    render() {
        return (
            <span
                className={cn(this.props.className, {
                    checkbox: true,
                    'checkbox--justify-right': this.props.justify === 'right',
                    'checkbox--disabled': this.props.disabled,
                })}
                onClick={() => {
                    if (this.props.disabled) {
                        return;
                    }
                    this.props.onChange();
                }}
            >
                <input
                    type="checkbox"
                    className={cn({
                        checkbox__input: true,
                        'checkbox--cross': this.props.checkType === 'cross',
                    })}
                    onChange={_.noop}
                    checked={!!this.props.checked}
                />
                <span
                    onClick={this.props.onClick}
                    className={cn({
                        checkbox__label: true,
                        'checkbox__label--strike': this.props.strikeThrough,
                    })}
                >
                    {this.props.label}
                    {this.props.description && (
                        <span className="checkbox__description"> - {this.props.description}</span>
                    )}
                </span>
            </span>
        );
    }
}

export default Checkbox;

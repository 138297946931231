import toastr from 'toastr';
import { createHttp } from 'utils/http';
import {
    validateLocations,
    constructLocations,
    filterEmptyRawCsvLocations,
} from './services/csv-processing';
import FTALocationLists from '../actions';
import { browserHistory } from 'react-router';

const actions = {
    processUploadedLocations(formData, options = {}) {
        return (dispatch) => {
            const file = formData.get('asset');
            const reader = new FileReader();

            dispatch({
                type: 'FTA_LOCATION_LISTS_EDITOR__PROCESS_LOCATIONS',
                payload: { fileName: file.name },
            });

            reader.addEventListener('loadend', () => {
                let csvErrors = validateLocations(reader.result);
                if (csvErrors) {
                    return dispatch({
                        type: 'FTA_LOCATION_LISTS_EDITOR__PROCESS_LOCATIONS__FAILURE',
                        payload: { csvErrors },
                    });
                }

                let locations = constructLocations(reader.result);
                let rawCsv = filterEmptyRawCsvLocations(reader.result);

                dispatch({
                    type: 'FTA_LOCATION_LISTS_EDITOR__PROCESS_LOCATIONS__SUCCESS',
                    payload: {
                        locations,
                        rawCsv,
                        options,
                    },
                });
            });

            reader.readAsText(file);
        };
    },
    getFtaLocationList: locationListId => {
        return async (dispatch) => {
            try {
                dispatch({
                    type: 'FTA_LOCATION_LISTS_EDITOR__FETCH_DATA__START',
                    payload: {},
                });

                const http = createHttp();
                const query = `
                    query getFtaLocationLists($locationListId: String) {
                        ftaLocationList(locationListId: $locationListId) {
                            id
                            comment
                            custom_value
                            name
                            poi_count
                            requested_at
                            requested_by
                            raw_location_file_URL
                            raw_csv
                        }
                    }
                `;

                const variables = { locationListId };

                const { ftaLocationList } = await http.graphql(query, variables);

                dispatch({
                    type: 'FTA_LOCATION_LISTS_EDITOR__FETCH_DATA__END',
                    payload: {
                        data: {
                            ...ftaLocationList,
                            locations: constructLocations(ftaLocationList.raw_csv),
                        },
                    },
                });
            } catch (err) {
                dispatch({
                    type: 'FTA_LOCATION_LISTS_EDITOR__FETCH_DATA__ERROR',
                    payload: {
                        errors: { fetch: err.message },
                    },
                });

                toastr.error('FTA Location Lists failed to be fetched. Please try it again later.');

                browserHistory.push('/fta-location-lists');
            }
        };
    },
    updateName: name => {
        return (dispatch) => {
            dispatch({
                type: 'FTA_LOCATION_LISTS_EDITOR__CHANGE_NAME',
                payload: {
                    name,
                },
            });
        };
    },
    updateComments: comments => {
        return (dispatch) => {
            dispatch({
                type: 'FTA_LOCATION_LISTS_EDITOR__CHANGE_COMMENTS',
                payload: {
                    comments,
                },
            });
        };
    },
    closeEditor: () => {
        return (dispatch) => {
            dispatch({
                type: 'FTA_LOCATION_LISTS_EDITOR__CLOSE',
                payload: {},
            });
        };
    },
    showFileTypeError: files => {
        return (dispatch) => {
            dispatch({
                type: 'FTA_LOCATION_LISTS_EDITOR__SET_FILE_TYPE_ERROR',
                payload: {
                    files,
                },
            });
        };
    },
    submitFtaLocationList: () => {
        return async (dispatch, getState) => {
            const isSaving = _.get(getState(), `ftaLocationListsEditor.isSaving`);

            if (isSaving) {
                return;
            }

            dispatch({
                type: 'FTA_LOCATION_LISTS_EDITOR__SUBMIT__START',
                payload: {},
            });

            const errors = _.get(getState(), `ftaLocationListsEditor.errors`);
            const csvErrors = _.get(getState(), `ftaLocationListsEditor.csvErrors`);

            if (_.keys(errors).length > 0 || (csvErrors && csvErrors.length > 0)) {
                dispatch({
                    type: 'FTA_LOCATION_LISTS_EDITOR__SUBMIT__ERROR',
                    payload: {
                        errors,
                    },
                });
            } else {
                const name = _.get(getState(), `ftaLocationListsEditor.draft.name`);
                const firstName = _.get(getState(), `profile.userFirstName`);
                const lastName = _.get(getState(), `profile.userLastName`);

                const payload = {
                    name,
                    comment: _.get(getState(), `ftaLocationListsEditor.draft.comments`),
                    requested_by: _.get(getState(), `profile.userEmail`),
                    file_name: _.get(getState(), `ftaLocationListsEditor.fileName`),
                    custom_value: JSON.stringify({ name: `${firstName} ${lastName}` }),
                    raw_location: _.get(getState(), `ftaLocationListsEditor.draft.rawCsv`),
                };

                try {
                    const http = createHttp();

                    await http.post('fta', payload);

                    dispatch(FTALocationLists.getFtaLocationLists());

                    browserHistory.push('/fta-location-lists');

                    dispatch({
                        type: 'FTA_LOCATION_LISTS_EDITOR__SUBMIT__END',
                        payload: {},
                    });
                } catch (err) {
                    console.log(err);
                    toastr.error(
                        'FTA Location Lists creation request failed. Please try it again later.'
                    );
                }
            }
        };
    },
};

export default actions;

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArchiveIcon from '@mui/icons-material/Archive';

import { blue } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import { blueGrey } from '@mui/material/colors';

import { DarkTooltip } from 'widgets-v6/tooltip';

var FLIGHT_LABELS = {
    live: 'Live',
    paused: 'Paused',
    ended: 'Ended',
    scheduled: 'Scheduled',
    pending: 'Scheduled',
    incomplete: 'Incomplete',
    campaignIsOff: 'Campaign OFF',
    archived: 'Archived',
};

const getStatusIcons = props => {
    const FLIGHT_ICONS = {
        live: <PlayArrowIcon style={{ color: green[500] }} fontSize={props.fontSize} />,
        paused: <PauseIcon color="secondary" fontSize={props.fontSize} />,
        ended: (
            <AssignmentTurnedInIcon style={{ color: blueGrey[500] }} fontSize={props.fontSize} />
        ),
        scheduled: <ScheduleIcon fontSize={props.fontSize} />,
        pending: <ScheduleIcon style={{ color: blue[500] }} fontSize={props.fontSize} />,
        incomplete: <ReportProblemIcon style={{ color: orange[500] }} fontSize={props.fontSize} />,
        campaignIsOff: <ReportProblemIcon fontSize={props.fontSize} />,
        archived: <ArchiveIcon fontSize={props.fontSize} />,
    };

    return FLIGHT_ICONS;
};

export default function FlightIndicator(props) {
    const label = FLIGHT_LABELS[props.status];

    const icons = getStatusIcons(props);

    return <DarkTooltip title={label}>{icons[props.status]}</DarkTooltip>;
}

import _ from 'lodash';

export default function(state) {
    const currentUserId = _.get(state, 'profile.userId', undefined);
    const realPersonId = _.get(state, 'profile.impersonatorsToken.user.id', undefined);
    const realPersonOrganization = _.get(
        state,
        'profile.impersonatorsToken.v3_impersonator.organization',
        undefined
    );
    const isImpersonating = _.get(state, 'profile.isImpersonating', false);

    const users = _.get(state, `impersonate.users`, undefined);
    const organizations = _.get(state, 'impersonate.organizations', undefined);
    const isProfileLoaded = _.get(state, `impersonate.isProfileLoaded`, false);
    const isInitialized = _.get(state, `impersonate.isInitialized`, false);
    const isLoading = _.get(state, `impersonate.isLoading`, false);
    const isModalOpen = _.get(state, `impersonate.isModalOpen`, false);
    const selectedOrganization = _.get(state, `impersonate.selectedOrganization`);

    const userOrganizationId = _.get(state, 'profile.organizationId');
    const userFirstName = _.get(state, 'profile.userFirstName');

    // const userOrganization = _.find(organizations, org => org.id === userOrganizationId);
    // const userOrganizationName = _.get(userOrganization, 'name');
    const userOrganizationName = _.get(state, 'profile.ownOrganization.name');

    const organizationToUserMappings = _(organizations)
        .map(org => {
            const usersForOrg = _(users)
                .filter(
                    user =>
                        user.organization === org.id &&
                        // && user.id !== currentUserId
                        user.global_role !== 'revoked' &&
                        !user.suspended &&
                        !user.third_party
                )
                .map(user => {
                    const name =
                        user.first_name && user.last_name
                            ? `${user.first_name} ${user.last_name}`
                            : '(none)';

                    return {
                        name,
                        email: user.email,
                        role: user.global_role,
                        id: user.id,
                        isCurrentUser: isImpersonating
                            ? realPersonOrganization === user.organization &&
                              realPersonId === user.id
                            : userOrganizationId === user.organization && currentUserId === user.id,
                        isImpersonator:
                            isImpersonating &&
                            userOrganizationId === user.organization &&
                            currentUserId === user.id,
                    };
                })
                .sortBy(user => user.name.toLowerCase())
                .value();

            return {
                id: org.id,
                name: org.name,
                users: usersForOrg,
            };
        })
        .sortBy(user => user.name.toLowerCase())
        .value();

    // We also remove any orgs without users
    const sanitizedOrgToUserMappings = _.filter(
        organizationToUserMappings,
        mapping => mapping.users.length !== 0
    );

    return {
        isProfileLoaded,
        isInitialized,
        isLoading,
        isModalOpen,

        userFirstName,
        userOrganizationId,
        userOrganizationName,
        currentUserId,

        orgToUserMappings: sanitizedOrgToUserMappings,
        isImpersonating,
        selectedOrganization,
    };
}

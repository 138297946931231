import _ from 'lodash';
import countries from 'common/constants/regions';
import fsaRegions from 'common/constants/fsa-regions';
import { removeDiacritics } from './services';
import { regEx } from 'utils/regular-expression-library';

export const validateFsas = countryCode => splitTargets => {
    if (countryCode.toUpperCase() === 'CA') {
        const [validFsas, invalidFsas] = _.partition(
            splitTargets,
            target =>
                target.length === 3 &&
                regEx.letter.test(target.charAt(0)) &&
                regEx.digit.test(target.charAt(1)) &&
                regEx.letter.test(target.charAt(2)) &&
                (fsaRegions[target.toUpperCase()] || fsaRegions[target.charAt(0).toUpperCase()])
        );
        return [
            validFsas.map(value => {
                return {
                    value: value.toUpperCase(),
                    region:
                        value.charAt(0).toUpperCase() === 'X'
                            ? fsaRegions[value.toUpperCase()]
                            : fsaRegions[value.charAt(0).toUpperCase()],
                };
            }),
            invalidFsas,
        ];
    } else {
        return [[], splitTargets];
    }
};

export const validateCities = countryCode => splitTargets => {
    let validCities = [],
        validationPool = [],
        invalidCities = [];

    const normalizedCities = _.map(splitTargets, city => removeDiacritics(city));

    _.forEach(normalizedCities, city => {
        if (city.indexOf(',') > -1) {
            const splittedCity = city.split(',');
            const cityTrimmed = splittedCity.map(c => c.trim()).join(', ');
            if (splittedCity.length > 2) {
                invalidCities.push(cityTrimmed);
            } else {
                validationPool.push({
                    isValid: false,
                    value: cityTrimmed,
                    city: splittedCity[0].trim(),
                    region: splittedCity[1].trim().replace(/\s+/g, ''),
                    regionCode: null,
                });
            }
        } else {
            invalidCities.push(city);
        }
    });

    _.forEach(_.keys(countries[countryCode]), regionCode => {
        const regionCodeShort = regionCode.split('-')[1]; // etc ON, BC
        _.forEach(validationPool, poolItem => {
            if (
                regionCodeShort.replace(/\s+/g, '').toLowerCase() === poolItem.region.toLowerCase()
            ) {
                poolItem.regionCode = regionCodeShort;
                poolItem.value = `${poolItem.city}, ${poolItem.regionCode}`;
                poolItem.isValid = true;
            }
            if (
                countries[countryCode][regionCode].replace(/\s+/g, '').toLowerCase() ===
                poolItem.region.toLowerCase()
            ) {
                poolItem.regionCode = regionCodeShort;
                poolItem.region = countries[countryCode][regionCode];
                poolItem.value = `${poolItem.city}, ${poolItem.region}`;
                poolItem.isValid = true;
            }
        });
    });

    _.forEach(validationPool, poolItem => {
        if (poolItem.isValid) {
            validCities.push({
                value: poolItem.value,
                regionCode: poolItem.regionCode,
            });
        } else {
            invalidCities.push(poolItem.value);
        }
    });

    return [validCities, invalidCities];
};

import React from 'react';
import _ from 'lodash';
import flags from 'containers/flags/service';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

import { SearchInput } from 'widgets-v6/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import { CanSwitch, CanCase } from 'containers/can';
import FormField from 'widgets-v5/form-field';
import { Title } from 'widgets-v5/typography';

import GlobalRefresh from 'containers/global-refresh';
import { canViewCampaignCreator } from '../../states/resources/campaigns/business-logic';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    body: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
    },
}));

class DashboardLayoutHeader extends React.Component {
    constructor(props) {
        super(props);

        this.handleSearchTextDebounced = _.debounce(text => {
            this.props.searchCampaigns(text);
        }, 200);
    }

    UNSAFE_componentWillMount() {
        this.handleChangeSearchText = value => {
            this.props.filterByText(value);
        };
    }

    componentDidMount() {
        if (this.refs.dashboardSearch) {
            this.refs.dashboardSearch.focus();
            this.refs.dashboardSearch.select();
        }
    }

    selectAllSearchText = () => {
        if (this.refs.dashboardSearch) {
            this.refs.dashboardSearch.select();
        }
    };

    handleSearchText = e => {
        this.handleSearchTextDebounced(e.target.value);
    };

    render() {
        const {
            pagination,
            filterByStatus,
            filterByCreativeType,
            filterByIABCategory,
            filterByClient,
            filterByCampaignCreatorId,
            organizations,
            searchQueryString,
            searchInputRef,
            resetSearchValue,
            ownOrganization,
            creativeOptions,
            getCampaignCreatorOptions,
        } = this.props;

        return (
            <div>
                <Box display="flex" justifyContent="space-between" mt={2} alignItems="center">
                    <div className="ef6-alignment__flex">
                        <Typography variant="h4">All Campaigns</Typography>
                        <div style={{ marginLeft: 3 }}>
                            <GlobalRefresh isLoading={pagination.isLoading} />
                        </div>
                    </div>
                    <div className="ef6-dashboard__action-buttons">
                        <CanSwitch>
                            <CanCase intent="campaign__view_and_edit">
                                {this.props.callToAction}
                            </CanCase>
                        </CanSwitch>
                    </div>
                </Box>
                <Box mt={2}>
                    <Box maxWidth="300" mb={2}>
                        <SearchInput
                            placeholder="Search by Campaign or Netsuite ID"
                            onChange={this.handleSearchText}
                            defaultValue={searchQueryString}
                            inputRef={searchInputRef}
                            onReset={resetSearchValue}
                        />
                    </Box>
                </Box>
            </div>
        );
    }
}

export default function DashboardLayout(props) {
    const classes = useStyles();

    const {
        pagination,
        filterByText,
        filterByStatus,
        filterByCreativeType,
        filterByIABCategory,
        filterByClient,
        filterByCampaignCreatorId,
        organizations,
        ownOrganization,
        dashboardCampaignList,
        goToNewCampaignForm,
        searchQueryString,
        searchInputRef,
        resetSearchValue,
        searchCampaigns,
        creativeOptions,
        getCampaignCreatorOptions,
    } = props;

    return (
        <div className={classes.root}>
            <Container>
                <DashboardLayoutHeader
                    ownOrganization={ownOrganization}
                    resetSearchValue={resetSearchValue}
                    searchInputRef={searchInputRef}
                    searchQueryString={searchQueryString}
                    filterByStatus={filterByStatus}
                    filterByCreativeType={filterByCreativeType}
                    filterByIABCategory={filterByIABCategory}
                    filterByText={filterByText}
                    filterByClient={filterByClient}
                    filterByCampaignCreatorId={filterByCampaignCreatorId}
                    pagination={pagination}
                    organizations={organizations}
                    callToAction={
                        <Button
                            onClick={goToNewCampaignForm}
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            New Campaign
                        </Button>
                    }
                    classes={classes}
                    searchCampaigns={searchCampaigns}
                    creativeOptions={creativeOptions}
                    getCampaignCreatorOptions={getCampaignCreatorOptions}
                />
                <div className={classes.body}>{dashboardCampaignList}</div>
            </Container>
        </div>
    );
}

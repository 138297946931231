import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Grid from '@mui/material/Grid';

import { FixedSizeGrid } from 'react-window';

import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import AutoSizer from 'react-virtualized-auto-sizer';

const generateTableFromList = ({ items, columnCount }) => {
    const cells = [...items];

    const table = [];
    while (cells.length) {
        const row = cells.splice(0, columnCount);
        table.push(row);
    }

    return table;
};

const getColumnCount = ({ width }) => {
    const {
        breakpoints: { values },
    } = useTheme();
    if (width >= values.xl) {
        return 4;
    }

    if (width >= values.lg) {
        return 4;
    }

    if (width >= values.md) {
        return 4;
    }

    if (width > values.sm) {
        return 2;
    }

    if (width <= values.sm) {
        return 1;
    }
};

export const AudienceTable = ({
    audiences,
    renderContent,
    cardSize,
    activeAudience,
    customRowHeight,
}) => {
    return (
        <AutoSizer>
            {({ width, height }) => (
                <VirtualGrid
                    audiences={audiences}
                    width={width}
                    height={height}
                    renderContent={renderContent}
                    cardSize={cardSize}
                    activeAudience={activeAudience}
                    customRowHeight={customRowHeight}
                />
            )}
        </AutoSizer>
    );
};

const BASE_ROW_HEIGHT = 300;

const VirtualGrid = ({
    audiences,
    width,
    renderContent,
    cardSize,
    activeAudience,
    customRowHeight,
}) => {
    const [tableWidth, setWidth] = useState(width);
    const gridRef = React.createRef();

    useEffect(() => {
        setWidth(width);
    }, [width]);

    const columnCount = getColumnCount({ width: tableWidth });
    const columnWidth = tableWidth / columnCount;

    const table = generateTableFromList({ items: audiences, columnCount });

    useEffect(() => {
        if (gridRef.current) {
            const rowIndex = _.findIndex(table, row =>
                _.find(row, column => column.id === activeAudience)
            );
            const columnIndex = _.findIndex(
                table[rowIndex],
                column => column.id === activeAudience
            );

            gridRef.current.scrollToItem({
                align: 'center',
                columnIndex: columnIndex,
                rowIndex: rowIndex,
            });
        }
    }, [audiences, gridRef]);

    let rowHeight = BASE_ROW_HEIGHT;

    if (cardSize === 'small') {
        rowHeight = BASE_ROW_HEIGHT - 40;
    } else if (cardSize === 'large') {
        rowHeight = BASE_ROW_HEIGHT + 40;
    }

    return (
        <FixedSizeGrid
            key={tableWidth}
            ref={gridRef}
            columnCount={columnCount}
            columnWidth={columnWidth}
            height={600}
            rowCount={table.length}
            rowHeight={customRowHeight ? customRowHeight : rowHeight}
            width={tableWidth}
        >
            {props => {
                const row = table[props.rowIndex];
                const segment = row[props.columnIndex];

                if (!segment) {
                    return null;
                }
                return (
                    <div style={props.style}>
                        <SegmentContainer
                            segment={segment}
                            width={columnWidth}
                            columnCount={columnCount}
                            renderContent={renderContent}
                        />
                    </div>
                );
            }}
        </FixedSizeGrid>
    );
};

const useStyles = makeStyles(theme => ({
    cardContainer: props => ({
        width: props.columnCount > 1 ? props.width - theme.spacing(2) : props.width,
        height: '95%',
        paddingRight: theme.spacing(1.5),
    }),
}));

const SegmentContainer = ({ segment, width, columnCount, renderContent }) => {
    const classes = useStyles({ width, columnCount });
    return (
        <Grid container className={classes.cardContainer}>
            <Grid item xs={12}>
                {renderContent(segment)}
            </Grid>
        </Grid>
    );
};

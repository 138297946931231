import _ from 'lodash';
import { combineReducers } from 'redux';

import c from 'common/constants/flux-events';

import controls from './controls';
import dimensionFilter from './dimension-filter';
import scopeFilter from './scope-filter';
import pivotTable from './pivot-table';
import components from './components';
import dictionary from './dictionary';
import dateRange from './date-range';
import metricSelector from './metric-selector';
import page from './page';

export default combineReducers({
    components,
    dimensionFilter,
    scopeFilter,
    controls,
    pivotTable,
    dictionary,
    dateRange,
    metricSelector,
    page,
});

// export default function report (state = {}, action) {
// const campaignId = _.get(action, 'payload.campaignId');

// if (!campaignId) {
//     return state;
// }

// const report = state[campaignId] || {};

// const nextReportState = reportReducer(report, action);

// if (nextReportState === report && state[campaignId] !== undefined) {
//     return state;
// }

// return {
//     ...state,
//     [campaignId]: nextReportState
// };
// }

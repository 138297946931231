import React, { Component } from 'react';
import { Link as LinkRouter } from 'react-router';
import { Row, Col } from 'react-flexbox-grid';

import { Title, BodyText, Subheading } from 'widgets-v5/typography';
import { SecondaryButton } from 'widgets-v5/buttons';
import Spacer from 'widgets-v5/spacer';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import topics from './topics';

const categories = _.groupBy(topics, 'category');

const categoryIconMapping = {
    Reference: 'images/help/help-reference.png',
    Guides: 'images/help/help-guides.png',
    "What's New": 'images/help/help-whatsnew.png',
};

class Help extends Component {
    render() {
        return (
            <React.Fragment>
                <Row center="xs">
                    <Col xs>
                        <HelpCardHeader>
                            <Title>Help</Title>
                        </HelpCardHeader>
                    </Col>
                </Row>
                <Grid container justifyContent="center" spacing={4}>
                    {_.map(categories, (articles, categoryName) => {
                        const publishedArticles = _.filter(articles, ['published', true]);

                        return (
                            <Grid item xs={3} container>
                                <HelpCard
                                    key={categoryName}
                                    image={categoryIconMapping[categoryName]}
                                    title={categoryName}
                                    topics={_.map(publishedArticles, article => ({
                                        title: article.topic,
                                        link: `/help/${article.url}`,
                                    }))}
                                    link={`/help/${articles[0].url.split('/')[0]}`}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </React.Fragment>
        );
    }
}

function HelpCardHeader(props) {
    return <div className="help-card__header">{props.children}</div>;
}

function HelpCard(props) {
    return (
        <Card style={{ width: '100%' }}>
            <CardMedia
                component="img"
                style={{ padding: '25px 50px 0px' }}
                image={props.image}
                title={props.title}
            />
            <CardContent>
                <Typography align="center" gutterBottom variant="h5" component="h2">
                    {props.title}
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary" component="p">
                    {_.map(props.topics, (topic, index) => {
                        const { title, link } = topic;

                        return (
                            <React.Fragment>
                                <Link key={index} component={LinkRouter} to={link}>
                                    {title}
                                </Link>
                                <br />
                            </React.Fragment>
                        );
                    })}
                </Typography>
            </CardContent>
            <CardActions>
                <Link component={LinkRouter} to={props.link} underline="none">
                    <Button size="large">View All</Button>
                </Link>
            </CardActions>
        </Card>
    );
}

export default Help;

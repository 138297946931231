import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import cn from 'classnames';

export default class extends React.Component {
    render() {
        const active = parseInt(this.props.active, 10);

        return (
            <div className="ef3-pagination">
                {this.props.moveBack ? (
                    <div className="ef3-pagination_link">{this.props.moveBack}</div>
                ) : null}
                {this.props.links.map((link, index) =>
                    React.cloneElement(link, {
                        className: cn('ef3-pagination_link', { 'is-active': index === active }),
                        key: index,
                    })
                )}
                {this.props.moveForward ? (
                    <div className="ef3-pagination_link">{this.props.moveForward}</div>
                ) : null}
            </div>
        );
    }
}

export class PaginationRange extends React.Component {
    static propTypes = {
        itemsPerPage: PropTypes.number,
        currentPage: PropTypes.number,
        maxResults: PropTypes.number,
    };

    render() {
        const { itemsPerPage, currentPage, maxResults } = this.props;

        const softStart = (currentPage - 1) * itemsPerPage + 1;
        const start = softStart < maxResults ? softStart : maxResults;

        const softEnd = currentPage * itemsPerPage;
        const end = softEnd < maxResults ? softEnd : maxResults;

        return (
            <span>
                {start}-{end} of {maxResults}
            </span>
        );
    }
}

export class Pagination extends React.Component {
    static propTypes = {
        url: PropTypes.string,
        heapEvent: PropTypes.string,
        query: PropTypes.object,
        padding: PropTypes.number,
        itemsPerPage: PropTypes.number,
        currentPage: PropTypes.number,
        maxResults: PropTypes.number,
        space: PropTypes.number,
    };

    static defaultProps = {
        space: 20,
    };

    generate = () => {
        const { query, itemsPerPage, currentPage, maxResults, padding } = this.props;

        const buffer = padding;
        var activeIndex = buffer;

        const start = Math.max(currentPage - buffer, 1);
        const maxVisiblePages = buffer * 2 + 1;

        const maxPage = Math.ceil(maxResults / itemsPerPage);

        // when the current page is too close to the start or end, highlight
        // should not be centered
        const highlightCurrentPage = currentPage <= buffer || currentPage >= maxPage - buffer;

        const links = [];

        _.each(_.times(maxVisiblePages), tabIndex => {
            // merge
            var filter = {
                ...query,
                page: start + tabIndex,
            };

            // Only add pages which have results to show
            if (filter.page > maxPage) {
                return;
            }

            if (highlightCurrentPage && filter.page === currentPage) {
                activeIndex = tabIndex;
            }

            if (query.search) {
                filter.search = query.search;
            }

            // Filter out empty query values
            // This is to avoid urls like: ?status=&creative_type=&search=
            const querystring = {};
            _.each(filter, (value, key) => {
                if (value === '' || value === null || value === undefined) {
                    return;
                }
                querystring[key] = value;
            });

            links.push({
                id: start + tabIndex,
                query: querystring,
                active: currentPage === querystring.page,
            });
        });

        const currentLink = _.find(links, { id: currentPage });

        const pageForwardNumber = Math.min(currentPage + 1, maxPage);

        var moveForward = {
            id: pageForwardNumber,
            hide: currentPage === maxPage,
        };

        const pageBackNumber = Math.max(currentPage - 1, 1);

        var moveBack = {
            id: pageBackNumber,
            hide: currentPage === 1,
        };

        if (currentLink) {
            moveForward.query = {
                ...currentLink.query,
                page: moveForward.id,
            };

            moveBack.query = {
                ...currentLink.query,
                page: moveBack.id,
            };
        }

        return {
            query,
            links,
            moveForward,
            moveBack,
            currentLinkIndex: activeIndex,
        };
    };

    render() {
        const { url } = this.props;

        const setup = this.generate();

        return (
            <div
                style={{ padding: this.props.space }}
                className="ef3-pagination"
                data-heap-event={this.props.heapEvent}
            >
                {!setup.moveBack.hide && (
                    <div className="ef3-pagination_link">
                        <Link to={{ pathname: url, query: setup.moveBack.query }}>
                            <i className="fa fa-angle-left" />
                        </Link>
                    </div>
                )}
                {setup.links.map((link, index) => (
                    <Link
                        key={index}
                        to={{ pathname: url, query: link.query }}
                        className={cn({
                            'ef3-pagination_link': true,
                            'is-active': link.active,
                        })}
                    >
                        {link.id}
                    </Link>
                ))}
                {!setup.moveForward.hide && (
                    <div className="ef3-pagination_link">
                        <Link to={{ pathname: url, query: setup.moveForward.query }}>
                            <i className="fa fa-angle-right" />
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}

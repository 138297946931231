import forEach from 'lodash/forEach';

import flags from 'containers/flags/service';
import { getEnvironmentSettings } from '../../environment';

const regEx_missing = /^\s*$/g;
const regEx_appleBundleId = /^\d+$/;
const regEx_googleBundleId = /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)+$/;
const regEx_domain = /^(?!(www\.|http:\/\/|https:\/\/))[a-z0-9]([a-z0-9\-]{0,61}[a-z0-9]\.)+[a-z]{2,63}$/i;

export const validators_rules = {
    name(value) {
        const isMissing = regEx_missing.test(value);
        if (isMissing) {
            return 'List Name is required';
        }
    },
    __appsAndSites(value, source) {
        if (
            source.bundleIdInput === '' &&
            source.domainInput === '' &&
            source.connectedTVIdsInput === '' &&
            source.doohBoardIdsInput === ''
        ) {
            const platforms = ['mobile app'];

            if (getEnvironmentSettings().canUseCTVOrDOOH()) {
                platforms.push('connected TV app');
            }

            if (
                getEnvironmentSettings().canUseCTVOrDOOH() &&
                flags.isEnabled('en_4657_dooh_campaigns')
            ) {
                platforms.push('board id');
            }

            platforms.push('and/or site');

            return 'Please input at least one ' + platforms.join(', ');
        }
    },
    __bundleId(value, source) {
        const invalidBundleIds = [];
        forEach(source.allBundleIds, bundleId => {
            if (
                !(regEx_appleBundleId.test(bundleId) || regEx_googleBundleId.test(bundleId)) ||
                /\.(com|net)\s*$/.test(bundleId)
            ) {
                invalidBundleIds.push(bundleId);
            }
        });

        if (invalidBundleIds.length > 0) {
            const invalidBundleIdsString = invalidBundleIds.join(', ');
            return invalidBundleIdsString;
        }
    },
    __domain(value, source) {
        const invalidDomains = [];

        forEach(source.domains, domain => {
            if (!regEx_domain.test(domain)) {
                invalidDomains.push(domain);
            }
        });

        if (invalidDomains.length > 0) {
            const invalidDomainsString = invalidDomains.join(', ');
            return invalidDomainsString;
        }
    },
};

import _ from 'lodash';

import c from 'common/constants/flux-events';
import { validation } from './services';

const initialState = {
    geoEntity: null,
    adId: null,
    draft: null,
    isOpen: false,
    isLoading: false,
    showError: false,
    draftErrors: [],
    message: '',
};

export const NAME = 'poiEditor';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.POI_EDITOR__OPEN: {
            return {
                ...state,
                isOpen: true,
                campaignId: _.get(action, 'payload.campaignId'),
                adId: _.get(action, 'payload.adId'),
                geoEntity: _.get(action, 'payload.geoEntity'),
            };
        }

        case c.POI_EDITOR__CLOSE: {
            return {
                ...initialState,
                isOpen: false,
            };
        }

        case c.POI_EDITOR__INITIALIZE__START: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case c.POI_EDITOR__INITIALIZE__SUCCESS: {
            const draft_current = _.get(state, `draft`, null);
            let draft_next;
            if (draft_current === null) {
                draft_next = _.get(action, 'payload.draft');
            } else {
                draft_next = _.cloneDeep(draft_current);
            }

            const errors = validation(draft_next);
            return {
                ...state,
                draft: draft_next,
                isLoading: false,
                draftErrors: errors,
            };
        }

        case c.POI_EDITOR__INITIALIZE__FAIL: {
            const error = _.get(action, 'payload.error', void 0);

            return {
                ...state,
                error,
                isLoading: false,
            };
        }

        case c.POI_EDITOR__SAVE__START: {
            const payload = _.get(action, 'payload', void 0);

            return {
                ...state,
                isLoading: true,
            };
        }

        case c.POI_EDITOR__SAVE__SUCCESS: {
            const payload = _.get(action, 'payload', void 0);
            return {
                ...state,
                isLoading: false,
            };
        }

        case c.POI_EDITOR__SAVE__FAIL: {
            const payload = _.get(action, 'payload', void 0);
            const error = _.get(action, 'payload.error', void 0);
            return {
                ...state,
                error,
                isLoading: false,
            };
        }

        case c.POI_EDITOR__DRAFT_ADD_POI: {
            const poiToAdd = _.cloneDeep(_.get(action, `payload.poi`, null));
            poiToAdd.id = poiToAdd.id || poiToAdd.pointId;

            const draft_current = _.get(state, `draft`, null);
            const draft_next = _.cloneDeep(draft_current);

            const points_next = _.get(draft_next, `points`, []);
            points_next.push(poiToAdd);

            const errors = validation(draft_next);
            return {
                ...state,
                draft: draft_next,
                draftErrors: errors,
            };
        }

        case c.POI_EDITOR__DRAFT_REMOVE_POI_BYID: {
            const id_poiToRemove = _.cloneDeep(_.get(action, `payload.id`, null));

            const draft_current = _.get(state, `draft`, null);
            const draft_next = _.cloneDeep(draft_current);

            _.remove(draft_next.points, function(point) {
                return point.id === id_poiToRemove;
            });

            const errors = validation(draft_next);
            return {
                ...state,
                draft: draft_next,
                draftErrors: errors,
            };
        }

        case c.POI_EDITOR__DRAFT_UPDATE: {
            const draft_next = _.get(action, 'payload.nextDraft', void 0);
            const errors = validation(draft_next);

            return {
                ...state,
                draft: draft_next,
                draftErrors: errors,
            };
        }

        case c.POI_EDITOR__SHOWERROR: {
            const draftError = _.get(action, 'payload.draftError', void 0);
            return {
                ...state,
                showError: true,
            };
        }

        default:
            return state;
    }
}

import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { InfoBanner } from 'widgets-v5/banners';

import PLATFORMS, { PLATFORM_MAPPING } from 'common/constants/platforms';
import { PlatformMapping } from 'states/resources/ads/business-logic';
import flags from 'containers/flags/service';

const PLUSCO_SHOWROOM_ID = '6487683d04a1620f4c1af7f6';
const ROOT_ORG_ID = '53271100d26f9a2f917ed289';
const ADDICTIVE_ORG_ID = '53271100d26f9a2f917ed290';

const useStyles = makeStyles(theme => ({
    banner: {
        margin: theme.spacing(2),
    },
    compatabilityTableAvailableCell: {
        color: 'green',
    },
    compatabilityTableNotAvailableCell: {
        color: 'red',
    },
    informativeText: {
        color: 'grey',
        fontSize: 11,
    },
}));

/**
 * Current compatibility table (EN-4581)
 *
 * User targeted on|
 * platform        |        User converts on platform
 *                 |  Mobile In-App  Mobile Web  Desktop  CTV    DOOH
 * Mobile In-App   |        Y           Y          N       N      N
 * Mobile Web      |        N           Y          N       N      N
 * Desktop         |        N           N          Y       N      N
 * CTV             |        N           Y          Y       N      N
 * DOOH            |        N           N          N       N      Y
 *
 * For CTV to MWEB/Desktop it's only for View-through attribution.
 */

const CURRENT_PLATFORMS = PLATFORMS.map(({ value }) => value);

// The below matrix follows the above diagram.
const PLATFORM_COMPATIBILITY = [
    [1, 1, 0, 0, 0],
    [0, 1, 0, 0, 0],
    [0, 0, 1, 0, 0],
    [0, 1, 1, 0, 0],
    [0, 0, 0, 0, 1],
];

const AvailableCell = ({ informativeText }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.compatabilityTableAvailableCell} align="center">
            <div>
                <CheckIcon />
            </div>
            <div className={classes.informativeText}>{informativeText}</div>
        </TableCell>
    );
};

const NotAvailableCell = ({ informativeText }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.compatabilityTableNotAvailableCell} align="center">
            <div>
                <ClearIcon />
            </div>
            <div className={classes.informativeText}>{informativeText}</div>
        </TableCell>
    );
};

export const CompatiblityTable = ({ ownOrganization }) => {
    const classes = useStyles();

    const [compatabiiltyTableAnchor, setCompatabilityAnchor] = useState(null);

    function showCompatabilityTable(e) {
        setCompatabilityAnchor(e.currentTarget);
    }

    function closeCompatabilityTable() {
        setCompatabilityAnchor(null);
    }

    let parsedPlatforms = CURRENT_PLATFORMS.slice(0);
    if (!flags.isEnabled('en_4657_dooh_campaigns')) {
        // Remove DOOH platform
        parsedPlatforms = parsedPlatforms.filter(platform => platform !== 'dooh');
    }

    return (
        <div className={classes.banner}>
            <InfoBanner
                content={
                    <React.Fragment>
                        <Typography>
                            EngageFront pixels can track same-platform conversions and same-device
                            conversions.{' '}
                            <Link onClick={showCompatabilityTable}>
                                See full compatibility table.
                            </Link>
                        </Typography>
                        <Popover
                            open={!!compatabiiltyTableAnchor}
                            anchorEl={compatabiiltyTableAnchor}
                            onClose={closeCompatabilityTable}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell rowSpan={2}>User targeted on platform</TableCell>
                                        <TableCell colSpan={4} align="center">
                                            User converts on platform
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {parsedPlatforms.map(platform => (
                                            <TableCell align="center" key={platform}>
                                                {PLATFORM_MAPPING[platform].label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {parsedPlatforms.map((platform, ii) => {
                                        return (
                                            <TableRow key={ii}>
                                                <TableCell align="center">
                                                    {PLATFORM_MAPPING[platform].label}
                                                </TableCell>
                                                {parsedPlatforms.map((columnPlatform, jj) => {
                                                    const informativeText =
                                                        platform === PlatformMapping.CTV &&
                                                        (columnPlatform === PlatformMapping.MWEB ||
                                                            columnPlatform ===
                                                                PlatformMapping.Desktop)
                                                            ? 'View-through attribution only'
                                                            : null;
                                                    const isCompatible = !!PLATFORM_COMPATIBILITY[
                                                        ii
                                                    ][jj];
                                                    const InnerCell = isCompatible
                                                        ? AvailableCell
                                                        : NotAvailableCell;
                                                    return (
                                                        <InnerCell
                                                            key={jj}
                                                            informativeText={informativeText}
                                                        />
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Popover>
                    </React.Fragment>
                }
            />
        </div>
    );
};

import _ from 'lodash';
import c from 'common/constants/flux-events';

export const NAME = 'adminOrgItem';
export default function adminOrgItem(state = {}, action) {
    switch (action.type) {
        case c.ADMIN_ORG_ITEM__INIT_SUCCESS:
            const { organization } = action.payload;

            return {
                ...state,
                organization,
            };
        case 'ADMIN_AUDITORS__FETCH_SUCCESS':
            const { auditors } = action.payload;
            return {
                ...state,
                auditors,
            };
        case 'ADMIN_AUDITEES__FETCH_SUCCESS':
            const { auditees } = action.payload;
            return {
                ...state,
                auditees,
            };
        default:
            return state;
    }
}

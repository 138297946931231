import c from 'common/constants/flux-events';

const initialState = {
    isCampaignLoading: true,
    isAdsLoading: true,
    isArchiveConfirmationOpen: false,
    campaignDataShowing: "campaign",
};

export const NAME = 'campaignsOverview';

export default function(state = initialState, action) {
    switch (action.type) {
        case c.OVERVIEW__CAMPAIGN__INIT: {
            return {
                ...state,
                isCampaignLoading: true,
                isAdsLoading: true,
            };
        }
        case c.OVERVIEW__CAMPAIGN__INIT_SUCCESS: {
            return {
                ...state,
                isCampaignLoading: false,
                isAdsLoading: false,
            };
        }
        case c.OVERVIEW_ADS__FETCH__FAIL: {
            return {
                ...state,
                isAdsLoading: false,
            };
        }
        case 'OVERVIEW__CAMPAIGN__FETCH_DATA_SUCCESS': {
            return {
                ...state,
                campaign: action.payload.campaign,
            };
        }
        case 'OVERVIEW__CAMPAIGN__ARCHIVE_CONFIRMATION_OPEN': {
            return {
                ...state,
                isArchiveConfirmationOpen: true,
            };
        }
        case 'OVERVIEW__CAMPAIGN__ARCHIVE_CONFIRMATION_CLOSE': {
            return {
                ...state,
                isArchiveConfirmationOpen: false,
            };
        }
        case 'OVERVIEW__CAMPAIGN__CHANGE_DATA_SELECTION': {
            return {
                ...state,
                campaignDataShowing: action.payload,
            }
        }
        default: {
            return state;
        }
    }
}

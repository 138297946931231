import React from 'react';
import { connect } from 'react-redux';
import { can } from 'services/can';
import Applist from 'containers/app-popup/applists';
import ChannelDetails from './channel-details';
import cn from 'classnames';
import { PermissionsMapping } from 'common/constants/role-intents';

const mapStateToProps = (storeState, ownProps) => {
    return {
        ownProps,
    };
};

class ModuleRoot extends React.Component {
    constructor(props) {
        super(props);
        this.handle_toggleShowBlockList = this.handle_toggleShowBlockList.bind(this);
        this.state = {
            showBlockList: false,
        };
    }

    handle_toggleShowBlockList() {
        this.setState({
            showBlockList: !this.state.showBlockList,
        });
    }

    handle_ReportPage() {
        const current_url = window.location.pathname;
        return /^\/campaigns\/[0-9]*\/report\/channel$/.test(current_url);
    }

    render() {
        return (
            <div className="modules-app-popup-content">
                <div className="inner-wrap">
                    <ChannelDetails
                        channel_id={this.props.channel_id}
                        channel_name={this.props.channel_name}
                        channel_type={this.props.channel_type}
                    />
                    {can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT) &&
                        this.props.channel_type === 'APP' && (
                            <div>
                                {this.props.channel_id !== '' ? (
                                    <div
                                        className={cn({
                                            'add-to-block-list-button': true,
                                            'is-active': this.state.showBlockList,
                                        })}
                                        onClick={this.handle_toggleShowBlockList}
                                    >
                                        <span>Add to app list</span>
                                    </div>
                                ) : (
                                    <div className={`add-to-block-list-button can-not-click`}>
                                        <span>Add to app list</span>
                                    </div>
                                )}
                                {this.state.showBlockList ? (
                                    <Applist
                                        channel_id={this.props.channel_id}
                                        channel_type={this.props.channel_type}
                                    />
                                ) : null}
                            </div>
                        )}

                    {!this.handle_ReportPage() && (
                        <div>
                            {can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT) &&
                                this.props.channel_type === 'SITE' && (
                                    <div>
                                        {this.props.channel_id !== '' ? (
                                            <div
                                                className={cn({
                                                    'add-to-block-list-button': true,
                                                    'is-active': this.state.showBlockList,
                                                })}
                                                onClick={this.handle_toggleShowBlockList}
                                            >
                                                <span>Add to Site list</span>
                                            </div>
                                        ) : (
                                            <div
                                                className={`add-to-block-list-button can-not-click`}
                                            >
                                                <span>Add to Site list</span>
                                            </div>
                                        )}
                                        {this.state.showBlockList ? (
                                            <Applist
                                                channel_id={this.props.channel_id}
                                                channel_type={this.props.channel_type}
                                            />
                                        ) : null}
                                    </div>
                                )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ModuleRoot);

import _ from 'lodash';
import React from 'react';
import { Cell } from 'widgets-v5/data-table';
import flags from 'containers/flags/service';

import {
    isBonusAd,
    getBillableVolume,
    getTotalMediaCost,
} from 'states/resources/ads/business-logic';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';

import Typography from '@mui/material/Typography';

import shallowCompare from 'utils/shallow-compare';
export class BillingsSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign } = this.props;

        if (campaign.budget_allocation_method === 'auto') {
            return (
                <div>
                    <Typography> Auto-budget enabled at campaign level </Typography>
                </div>
            );
        }

        let summary;
        if (isBonusAd(ad, campaign)) {
            summary = <div>Bonus Ad</div>;
        } else if (campaign.billing_enabled) {
            switch (ad.primary_pacing) {
                case 'clicks':
                case 'impressions':
                    summary = (
                        <div>
                            <div className="ef5-text-size_hint">Total {ad.primary_pacing}</div>
                            <div className="media-plan-form__overview-table-goal-number">
                                {formatNumber_whole(ad.billable_volume + ad.non_billable_volume)}
                            </div>
                            <div className="ef5-text-size_hint">Revenue Budget</div>
                            <div className="media-plan-form__overview-table-goal-number">{`${
                                campaign.currency
                            } ${formatNumber_currency(ad.max_total_billings)}`}</div>
                        </div>
                    );
                    break;
                case 'billings':
                    summary = (
                        <div>
                            <div className="ef5-text-size_hint">Total Cost Budget</div>
                            <div className="media-plan-form__overview-table-goal-number">
                                {formatNumber_currency(getBillableVolume(ad))}
                            </div>
                        </div>
                    );
                    break;
                case 'spend':
                    summary = (
                        <div>
                            <div className="ef5-text-size_hint">Revenue Budget</div>
                            <div className="media-plan-form__overview-table-goal-number">
                                {formatNumber_currency(ad.max_total_billings)}
                            </div>
                        </div>
                    );
                    break;
            }
        } else {
            switch (ad.primary_pacing) {
                case 'clicks':
                    summary = <div>Clicks</div>;
                    break;
                case 'impressions':
                    summary = <div>Impressions</div>;
                    break;
                case 'billings':
                    summary = <div>Margin</div>;
                    break;
                case 'spend':
                    summary = <div>Total Cost</div>;
                    break;
            }
        }

        return summary;
    }
}

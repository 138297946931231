import _ from 'lodash';
import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

import { getEnvironmentSettings } from 'services/environment';

import FileUpload from 'widgets-v5/file-upload';
import StandardInput from 'widgets-v5/standard-input';
import MultipleSelector from 'widgets-v5/multiple-selector';
import FormField from 'widgets-v5/form-field';
import LoadGroup from 'widgets-v5/load-group';
import TopBarLayout from 'widgets/top-bar-layout';
import StandardSelector from 'widgets-v5/standard-selector';
import Card from 'widgets-v5/card';
import { TextField } from 'widgets-v6/standard-input';
import PreFixSuffixInput from 'widgets-v6/prefix-suffix-input';
import { SimpleSelect, MultiSelect, SingleSelect } from 'widgets-v6/select';

const useStyles = makeStyles({
    third_party_container: {
        margin: 0,
        paddingLeft: 0,
        paddingTop: 30,
        width: 400,
    },
    third_party_title: {
        paddingTop: 5,
        marginRight: -15,
    },
    tooltip: {
        maxWidth: 500,
        fontSize: 12,
    },
    icon: {
        fontSize: 15,
        padding: 1,
    },
    button: {
        padding: 1,
    },
});

const toolTipText = `To track campaign budgets holistically, input the data fee charged by the data provider.
    If set, this amount will be automatically deducted from campaign budgets to prevent overspending.`;

function Layout(props) {
    const classes = useStyles();
    const handleSourceChange = value => {
        props.onSourceTypeChange(value);
        if (value === 'pixel') {
            props.onSupportsChange(['mweb']);
            props.onRetargetingChange('pixel');
        } else {
            props.onSupportsChange(['inapp']);
            props.onRetargetingChange('');
            props.onAdvertiserChange('');
            props.onEventNameListChange([]);
        }
    };

    const handleAdvertiserChange = value => {
        props.onAdvertiserChange(value);
        props.onEventNameListChange([]);
    };

    return (
        <div className="audience-editor">
            <TopBarLayout
                topBar={
                    <div>
                        <FormField
                            label="Name"
                            isRequired={true}
                            errors={props.errors.name}
                            showErrors={props.showErrors}
                        >
                            <StandardInput
                                className="audience-editor__audience-segment-name-input"
                                value={props.draft.name}
                                onChange={props.onNameChange}
                                disabled={
                                    props.isEditing && props.draft.upload_origin === 'environics'
                                }
                            />
                        </FormField>
                    </div>
                }
                workspace={
                    <div className="audience-editor__wrapper">
                        <LoadGroup isLoading={props.isLoading}>
                            <Card className="audience-editor__selectors-card">
                                {props.draft.mode === 'retargeting' ? (
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Box mb={2}>
                                                <FormControl component="fieldset" required>
                                                    <FormLabel component="legend">Source</FormLabel>
                                                    <RadioGroup
                                                        aria-label="source"
                                                        name="source"
                                                        value={props.draft.source_type}
                                                        onChange={e =>
                                                            handleSourceChange(e.target.value)
                                                        }
                                                    >
                                                        {_.map(props.sourceTypeOptions, option => (
                                                            <FormControlLabel
                                                                disabled={props.isEditing}
                                                                value={option.value}
                                                                control={<Radio color="primary" />}
                                                                label={option.label}
                                                            />
                                                        ))}
                                                        <FormControlLabel
                                                            disabled={props.isEditing}
                                                            value="pixel"
                                                            control={<Radio color="primary" />}
                                                            label="Advertiser Pixel"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Box mb={2}>
                                                {props.draft.source_type === 'ad' && (
                                                    <FormField
                                                        label="Ads"
                                                        showErrors={props.showErrors}
                                                        errors={props.errors.source_id}
                                                    >
                                                        <MultipleSelector
                                                            placeholder="Select Ads"
                                                            searchable
                                                            clearable
                                                            values={props.draft.source_id}
                                                            options={props.adOptions}
                                                            onChange={props.onSourceChange}
                                                            disabled={props.isEditing}
                                                            isLoading={props.isCampaignsLoading}
                                                        />
                                                    </FormField>
                                                )}
                                                {props.draft.source_type === 'campaign' && (
                                                    <FormField
                                                        label="Campaigns"
                                                        showErrors={props.showErrors}
                                                        errors={props.errors.source_id}
                                                    >
                                                        <MultipleSelector
                                                            placeholder="Select Campaigns"
                                                            searchable
                                                            clearable
                                                            values={props.draft.source_id}
                                                            options={props.campaignOptions}
                                                            onChange={props.onSourceChange}
                                                            disabled={props.isEditing}
                                                            isLoading={props.isCampaignsLoading}
                                                        />
                                                    </FormField>
                                                )}
                                            </Box>
                                        </Grid>
                                        {props.draft.source_type === 'pixel' ? (
                                            <Grid item xs={2}>
                                                <Box mb={2}>
                                                    <SingleSelect
                                                        placeholder="Select Advertiser"
                                                        label="Advertiser"
                                                        value={props.draft.advertiser_id}
                                                        onChange={value => {
                                                            handleAdvertiserChange(value);
                                                        }}
                                                        options={props.advertiserOptions}
                                                        disabled={props.isEditing}
                                                    />
                                                </Box>
                                                <Box mb={2}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        error={
                                                            props.showErrors &&
                                                            props.errors.event_name_list
                                                        }
                                                    >
                                                        <FormLabel required component="legend">
                                                            {' '}
                                                            Target Pixels{' '}
                                                        </FormLabel>
                                                        <MultiSelect
                                                            placeholder={
                                                                props.draft.event_name_list
                                                                    .length === 0 ||
                                                                !props.draft.event_name_list
                                                                    ? 'Select target pixel'
                                                                    : ''
                                                            }
                                                            clearable
                                                            error={
                                                                props.showErrors &&
                                                                props.errors.event_name_list
                                                            }
                                                            value={props.draft.event_name_list}
                                                            options={props.conversionOptions}
                                                            onChange={props.onEventNameListChange}
                                                            disabled={props.isEditing}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={3}>
                                                <Box mb={2}>
                                                    <FormField
                                                        label="Target Event"
                                                        showErrors={props.showErrors}
                                                        errors={props.errors.retargeting_type}
                                                    >
                                                        <StandardSelector
                                                            disabled={props.isEditing}
                                                            onChange={props.onRetargetingChange}
                                                            value={props.draft.retargeting_type}
                                                            clearable={true}
                                                            items={props.retargetingOptions}
                                                        />
                                                    </FormField>
                                                </Box>
                                            </Grid>
                                        )}

                                        {props.draft.retargeting_type === 'conversion' && (
                                            <Grid item xs={5}>
                                                <Box mb={2}>
                                                    <FormField
                                                        label="Target Engagements"
                                                        showErrors={props.showErrors}
                                                        errors={props.errors.event_name_list}
                                                    >
                                                        <MultipleSelector
                                                            placeholder="Select Engagements"
                                                            searchable
                                                            clearable
                                                            values={props.draft.event_name_list}
                                                            options={props.eventOptions}
                                                            onChange={props.onEventNameListChange}
                                                            disabled={props.isEditing}
                                                        />
                                                    </FormField>
                                                </Box>
                                            </Grid>
                                        )}
                                        {props.draft.retargeting_type === 'attribution' && (
                                            <Grid item xs={5}>
                                                <FormField
                                                    label="Target Pixels"
                                                    showErrors={props.showErrors}
                                                    errors={props.errors.event_name_list}
                                                >
                                                    <Box mb={2}>
                                                        <MultipleSelector
                                                            placeholder="Select Attribution"
                                                            searchable
                                                            clearable
                                                            values={props.draft.event_name_list}
                                                            options={props.attributionOptions}
                                                            onChange={props.onEventNameListChange}
                                                            disabled={props.isEditing}
                                                        />
                                                    </Box>
                                                </FormField>
                                            </Grid>
                                        )}
                                        {props.draft.source_type === 'pixel' && (
                                            <Grid item xs={2}>
                                                <Box mb={2}>
                                                    <SimpleSelect
                                                        label="Lookback Window"
                                                        required
                                                        placeholder="Select Lookback window"
                                                        disabled={props.isEditing}
                                                        value={props.draft.lookback}
                                                        onChange={e =>
                                                            props.onLookBackChange(e.target.value)
                                                        }
                                                        error={
                                                            props.showErrors &&
                                                            props.errors.advertiser_id
                                                        }
                                                        options={[
                                                            { label: '1 day', value: 1 },
                                                            { label: '7 days', value: 7 },
                                                            { label: '14 days', value: 14 },
                                                            { label: '28 days', value: 28 },
                                                        ]}
                                                    />
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                ) : (
                                    <div className="audience-editor__file-upload-section">
                                        <UploadedType {...props} />
                                        {!props.isEditing && (
                                            <FormControl
                                                component="fieldset"
                                                fullWidth
                                                margin="normal"
                                            >
                                                <FormLabel component="legend">
                                                    Upload File
                                                </FormLabel>
                                                <div className="audience-editor__file-upload-button">
                                                    <FileUpload
                                                        buttonText="Upload Device ID List"
                                                        disabled={props.isEditing}
                                                        onChange={props.onFileChange}
                                                        accept=".txt"
                                                    />
                                                    <div className="audience-editor__file-upload-section-title">
                                                        {props.fileName ? (
                                                            <div className="audience-editor__file-title">
                                                                {props.fileName && (
                                                                    <div>
                                                                        <i className="fa fa-file-text-o" />{' '}
                                                                        {props.fileName}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div>No Attached File</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <FormHelperText>
                                                    <ul>
                                                        <li>
                                                            Files should be formatted with one
                                                            device ID per line. Device IDs must be a
                                                            valid Apple IDFA or Google Advertising
                                                            ID
                                                        </li>
                                                    </ul>
                                                </FormHelperText>
                                                <div>{props.errors.file}</div>
                                            </FormControl>
                                        )}
                                        <div className="audience-editor__audience-segment-upload-origin-input">
                                            <FormField
                                                style={{ maxWidth: 500 }}
                                                label="Data Provider"
                                                isRequired={true}
                                                errors={props.errors.upload_origin}
                                                showErrors={props.showErrors}
                                            >
                                                <StandardSelector
                                                    disabled={props.isEditing}
                                                    onChange={props.onOriginChange}
                                                    value={props.draft.upload_origin}
                                                    clearable={true}
                                                    items={props.uploadOriginOptions}
                                                />
                                                {props.draft.upload_origin === 'other' && (
                                                    <TextField
                                                        label={
                                                            props.isEditing
                                                                ? null
                                                                : 'Custom Data Provider'
                                                        }
                                                        required
                                                        placeholder="If provider is not listed, please specify"
                                                        disabled={props.isEditing}
                                                        error={
                                                            props.showErrors &&
                                                            props.errors.customUploadOrigin
                                                                ? true
                                                                : false
                                                        }
                                                        helperText={
                                                            props.showErrors &&
                                                            props.errors.uploadOriginUserInput
                                                                ? props.errors.customUploadOrigin[0]
                                                                : null
                                                        }
                                                        fullWidth
                                                        value={props.draft.customUploadOrigin}
                                                        onChange={e =>
                                                            props.updateCustomUploadOrigin(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            </FormField>
                                        </div>
                                        <Container
                                            maxWidth="sm"
                                            className={classes.third_party_container}
                                        >
                                            <Grid container justifyContent="flex-start">
                                                <Grid
                                                    item
                                                    xs={7}
                                                    className={classes.third_party_title}
                                                >
                                                    <FormField
                                                        label="Third Party Data Fees (Optional)"
                                                        isRequired={false}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    className={classes.third_party_title}
                                                >
                                                    <Tooltip
                                                        title={toolTipText}
                                                        placement="right"
                                                        classes={{ tooltip: classes.tooltip }}
                                                    >
                                                        <IconButton
                                                            size={'small'}
                                                            aria-label="information"
                                                            classes={{ root: classes.button }}
                                                        >
                                                            <InfoIcon
                                                                classes={{ root: classes.icon }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <PreFixSuffixInput
                                                        formatIn={formatSpendValueToString}
                                                        formatOut={formatSpendStringToValue}
                                                        shouldAllowInput={shouldAllowInputForSpend}
                                                        disabled={
                                                            props.draft.source_type !== 'upload'
                                                        }
                                                        prefix={`$`}
                                                        suffix="CPM"
                                                        onChange={props.onThirdPartyFeesChange}
                                                        value={props.draft.third_party_fees}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Container>
                                        <div className="audience-editor__audience-segment-description">
                                            {(props.fileName || props.draft.description) && (
                                                <FormField label="Description">
                                                    <StandardInput
                                                        disabled={props.draft.mode !== 'upload'}
                                                        value={props.draft.description}
                                                        onChange={props.onDescriptionChange}
                                                        placeholder="Leave a description"
                                                    />
                                                </FormField>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Card>
                            {props.invalidLines.length > 0 && (
                                <Card>
                                    <div className="audience-editor__server-error">
                                        Invalid lines:
                                    </div>
                                    {_.map(props.invalidLines, line => (
                                        <div className="audience-editor__server-error">{line}</div>
                                    ))}
                                </Card>
                            )}
                        </LoadGroup>
                    </div>
                }
            />
        </div>
    );
}

function UploadedType(props) {
    const environmentSettings = getEnvironmentSettings();

    return (
        <React.Fragment>
            <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">Type</FormLabel>
                <RadioGroup
                    aria-label="type"
                    name="type"
                    value={props.draft.supports[0]}
                    onChange={event => {
                        if (environmentSettings.canUploadMweb()) {
                            props.onSupportsChange([event.target.value]);
                        }
                    }}
                >
                    <FormGroup row>
                        <div>
                            <FormControlLabel
                                value="inapp"
                                control={<Radio />}
                                label="Mobile Advertising IDs (Device IDs)"
                            />
                            <FormHelperText>
                                Supported formats:
                                <ul>
                                    <li>Apple IDFA</li>
                                    <li>Android Ad ID</li>
                                </ul>
                            </FormHelperText>
                        </div>
                        {environmentSettings.canUploadMweb() && (
                            <div>
                                <FormControlLabel
                                    value="mweb"
                                    control={<Radio />}
                                    label="Mobile Web / Desktop Cookie IDs"
                                />
                                <FormHelperText>
                                    Supported formats:
                                    <ul>
                                        <li>AdX Cookie ID (Google User ID)</li>
                                    </ul>
                                </FormHelperText>
                            </div>
                        )}
                    </FormGroup>
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );
}

function formatSpendValueToString(value) {
    if (!value) {
        return '';
    } else {
        const [dollars, cents] = value.toFixed(2).split('.');
        const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
        return `${formattedDollars}.${cents}`;
    }
}

function formatSpendStringToValue(string) {
    if (string === '') {
        return 0;
    } else {
        return parseFloat(string.replace(/,/g, ''));
    }
}

function shouldAllowInputForSpend(input) {
    // Allow empty input
    if (!input) {
        return true;
    }
    // Check allowable characters
    if (!/^[\d,]*(\.\d{0,2})?$/g.test(input)) {
        return false;
    }
    // Check character length
    const [dollars, cents] = input.split('.');
    const numDollarsDigits = dollars.match(/\d/g).length;
    const numCentsDigits = cents ? cents.length : 0;
    return numDollarsDigits <= 9 && numCentsDigits <= 2;
}

export default Layout;

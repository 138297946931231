import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';

import selector from './selector';
import actions from './actions';
import { Can } from 'containers/can';
import { isBillingsManagedThroughEF } from 'states/resources/campaigns/business-logic';
import {
    getBillableVolume,
    formatBillingTerm,
    isCpmCpcAd,
} from 'states/resources/ads/business-logic';
import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
} from 'utils/formatting';
import CampaignBudgetOverview from './modules/campaign-budget-overview';
import { NameSummary, NameForm } from './modules/name-column';
import { AudienceForm, AudienceSummary } from './modules/audience-column';
import { BillingRateSummary } from './modules/billing-rate-column';
import { FrequencyCapForm, FrequencyCapSummary } from './modules/frequency-cap-column';
import { DeviceOsForm, DeviceOsSummary } from './modules/device-os-column';
import { CarrierForm, CarrierSummary } from './modules/carrier-column';
import { TargetCarriersIspsSummary, TargetCarriersIspsForm } from './modules/carrier-isp-column';
import { LanguageForm, LanguageSummary } from './modules/language-column';
import { AppCategoryForm, AppCategorySummary } from './modules/app-category-column';
import { IABCategoryForm, IABCategorySummary } from './modules/iab-category-column';
import { GenderForm, GenderSummary } from './modules/gender-column';
import { TacticsForm, TacticsSummary } from './modules/tactics-column';
import { AgeForm, AgeSummary } from './modules/age-column';
import { DaypartsForm, DaypartsSummary } from './modules/dayparts-column';
import { WeekpartsForm, WeekpartsSummary } from './modules/weekparts-column';
import { GeoForm, GeoSummary } from './modules/geo-column';
import { GeoTargetingForm, GeoTargetingSummary } from './modules/geo-targeting-column';
import { GeoFencingForm, GeoFencingSummary } from './modules/geo-fencing-column';
import { PacingStrategyForm, PacingStrategySummary } from './modules/pacing-strategy-column';
import {
    ThirdPartyVerificationForm,
    ThirdPartyVerificationSummary,
} from './modules/third-party-verification-column';
import CreativeSummary, { CreativeForm } from './modules/creative-column';
import { FlightSummary, FlightForm } from './modules/flight-column';
import { AppsAndSitesSummary, AppsAndSitesForm } from './modules/apps-and-sites-column';
import {
    DoohVenueTypeSummaryBulkEdit,
    DoohVenueTypeFormBulkEdit,
} from './modules/dooh-venue-type-column';
import { ExchangesSummary, ExchangesForm } from './modules/exchanges-column';
import { BillingsSummary } from './modules/billings-column';
import { GoalForm, GoalSummary } from './modules/goal-column';
import { ThirdPartyFeesSummary, ThirdPartyFeesForm } from './modules/third-party-fees-column';
import { EstimatedImpressionsSummary } from './modules/estimated-impressions-column';
import { EstimatedDailyImpressionsSummary } from './modules/estimated-daily-impressions-column';
import { EstimatedUniquesSummary } from './modules/estimated-uniques-column';
import { isPlatformDisabled } from './services/is-platform-disabled';
import {
    FootTrafficAttributionV1Form,
    FootTrafficAttributionV1Summary,
} from './modules/fta-v1-column';
import {
    FootTrafficAttributionV2Form,
    FootTrafficAttributionV2Summary,
} from './modules/fta-v2-column';
import { DealsSummary, DealsForm } from './modules/deals-column';
import { PublicInventoryForm, PublicInventorySummary } from './modules/public-inventory-column';
import { BidStrategyForm, BidStrategySummary } from './modules/bid-strategy-column';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import Card from 'widgets-v5/card';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import { getEnvironmentSettings } from 'services/environment';

import PLATFORMS from 'common/constants/platforms';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';

import flags from 'containers/flags/service';

const styles = () => ({
    columnContent: {
        maxHeight: 200,
        overflow: 'auto',
        marginRight: 10,
        padding: '0px 5px 5px 10px',
    },
    archivedWrapper: {
        marginTop: 20,
        padding: 50,
    },
});

class MediaPlanForm extends React.Component {
    componentDidMount() {
        this.props.dispatch(actions.init(this.props.campaignId));
    }

    componentWillUnmount() {
        this.props.dispatch(actions.cleanup());
    }

    getColumns = () => {
        return getColumns({
            campaign: this.props.campaign,
            ads: this.props.ads,
            hiddenColumns: this.props.hiddenColumns,
            organization: this.props.organization,
        });
    };

    getHeaders = () => {
        const columns = this.getColumns();

        return columns;
    };

    toggleColumn = column => {
        this.props.dispatch(actions.toggleColumn(column));
    };

    setPlatformFilter = ({ filter }) => {
        this.props.dispatch(actions.setPlatformFilter(filter));
    };

    render() {
        const columns = this.getColumns();
        const headers = this.getHeaders();

        const {
            isLoading,
            isInit,
            ads,
            campaign,
            inventoryPredictions,
            adMeta,
            classes,
            currentUser,
        } = this.props;

        const isCTVCampaign = campaign.type === CampaignTypeMapping.CTV;
        if (campaign.isArchived) {
            return (
                <Grid container justifyContent="center" alignItems="center">
                    <Paper className={classes.archivedWrapper}>
                        <Typography variant="h6">Campaign and Ads have been archived.</Typography>
                    </Paper>
                </Grid>
            );
        }

        const filteredColumns = _.filter(headers, header => header.name !== 'name');

        return (
            <React.Fragment>
                {isLoading && <LinearProgress />}
                {isInit && (
                    <React.Fragment>
                        <CampaignBudgetOverview
                            campaign={campaign}
                            columns={filteredColumns}
                            toggleColumn={this.toggleColumn}
                        />
                        <AdsTable
                            adMeta={adMeta}
                            ads={ads}
                            campaign={campaign}
                            inventoryPredictions={inventoryPredictions}
                            headers={headers}
                            columns={columns}
                            classes={classes}
                            setPlatformFilter={this.setPlatformFilter}
                            currentUser={currentUser}
                            isCTVCampaign={isCTVCampaign}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

class AdsTable extends React.Component {
    handleSetFilterAndOpenModal = ({ filter, openFormModal }) => {
        const { setPlatformFilter } = this.props;

        setPlatformFilter({ filter });
        openFormModal(filter);
    };

    render() {
        const {
            campaign,
            ads,
            adMeta,
            inventoryPredictions,
            headers,
            columns,
            classes,
            currentUser,
            isCTVCampaign,
        } = this.props;

        const visibleHeaders = _.filter(headers, col => !col.hide);

        const visibleColumns = _.filter(columns, col => !col.hide);

        return (
            <Card margin="0px 16px 16px 16px" padding={0}>
                <ComposableDataTable scrollable className="media-plan-form__overview-table content">
                    {({ onScroll, offScroll }) => [
                        <Row key="header" header>
                            {_.map(visibleHeaders, (col, cIndex) => {
                                const disabledPlatforms = campaign.isCrossPlatformCampaign
                                    ? []
                                    : _(PLATFORMS)
                                          .map(platform =>
                                              isPlatformDisabled({
                                                  platform: platform.value,
                                                  field: col.name,
                                                  ads,
                                              })
                                          )
                                          .filter(platform => platform)
                                          .value();

                                const platformRestrictedColumns = [
                                    'target_device_os',
                                    'creative',
                                    'tactics',
                                    'name',
                                ];
                                if (campaign.carriersISPsVersion === 2) {
                                    platformRestrictedColumns.push('target_carriers_isps');
                                }

                                const isMenuDisabled =
                                    !campaign.isCrossPlatformCampaign &&
                                    (disabledPlatforms.length > 0 ||
                                        platformRestrictedColumns.includes(col.name));

                                const allPlatforms = PLATFORMS.map(platform => {
                                    const isDisabled =
                                        !!disabledPlatforms.find(
                                            disabledPlatform => disabledPlatform === platform.value
                                        ) ||
                                        (campaign.isCrossPlatformCampaign
                                            ? _.filter(ads, ad =>
                                                  _.includes(ad.platforms, platform.value)
                                              ).length === 0
                                            : ads.filter(ad => ad.platform === platform.value)
                                                  .length === 0);

                                    return {
                                        ...platform,
                                        isDisabled,
                                    };
                                });

                                const enabledPlatforms = allPlatforms.filter(
                                    platform => !platform.isDisabled
                                );
                                const skipBulkdEditDropdownOptions = enabledPlatforms.length === 1;

                                return (
                                    <MediaPlanCell
                                        campaign={campaign}
                                        classes={classes}
                                        key={col.name}
                                        colSpan={col.span}
                                        name={col.name}
                                        fixedFirstColumn={cIndex === 0}
                                        onScroll={onScroll}
                                        offScroll={offScroll}
                                        tooltipText={'Bulk Edit'}
                                        canEdit={col.canEditBulk({ ads })}
                                        summary={
                                            <span>
                                                {col.label}
                                                {col.required && (
                                                    <sup className="ef4-form-field__required-label">
                                                        *
                                                    </sup>
                                                )}
                                            </span>
                                        }
                                        form={
                                            col.Form &&
                                            ((closeModal, platformFilter) => {
                                                let filteredAds = [];
                                                if (platformFilter) {
                                                    if (campaign.isCrossPlatformCampaign) {
                                                        filteredAds = _.filter(ads, ad =>
                                                            _.includes(ad.platforms, platformFilter)
                                                        );
                                                    } else {
                                                        filteredAds = ads.filter(
                                                            ad => ad.platform === platformFilter
                                                        );
                                                    }
                                                } else {
                                                    filteredAds = ads;
                                                }
                                                return (
                                                    <col.Form
                                                        columnName={col.name}
                                                        ads={filteredAds}
                                                        campaign={campaign}
                                                        onSave={closeModal}
                                                        onCancel={closeModal}
                                                        platformFilter={platformFilter}
                                                        isCTVCampaign={isCTVCampaign}
                                                    />
                                                );
                                            })
                                        }
                                        dropdown={(menuAnchor, onCloseMenu, openFormModal) => {
                                            return (
                                                <Menu
                                                    anchorEl={menuAnchor}
                                                    open={!!menuAnchor}
                                                    onClose={onCloseMenu}
                                                >
                                                    <MenuItem
                                                        disabled={isMenuDisabled}
                                                        onClick={() =>
                                                            this.handleSetFilterAndOpenModal({
                                                                openFormModal,
                                                            })
                                                        }
                                                    >
                                                        All Ads
                                                    </MenuItem>
                                                    {allPlatforms.map(platform => (
                                                        <MenuItem
                                                            key={platform.value}
                                                            disabled={platform.isDisabled}
                                                            onClick={() => {
                                                                this.handleSetFilterAndOpenModal({
                                                                    filter: platform.value,
                                                                    openFormModal,
                                                                });
                                                            }}
                                                        >
                                                            All {platform.label} Ads
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            );
                                        }}
                                        header={!skipBulkdEditDropdownOptions}
                                        onClickOverwrite={
                                            !skipBulkdEditDropdownOptions
                                                ? undefined
                                                : openFormModal => {
                                                      return this.handleSetFilterAndOpenModal({
                                                          filter: enabledPlatforms[0].value,
                                                          openFormModal,
                                                      });
                                                  }
                                        }
                                    />
                                );
                            })}
                        </Row>,
                        _.map(ads, ad => {
                            return (
                                <Row key={ad.id}>
                                    {_.map(visibleColumns, col => {
                                        const columnError = _.get(
                                            adMeta,
                                            `${ad.id}.errors.${col.name}`
                                        );

                                        return (
                                            <MediaPlanCell
                                                classes={classes}
                                                key={col.name}
                                                name={col.name}
                                                tooltipText="Edit"
                                                campaign={campaign}
                                                canEdit={col.canEditSingle(ad)}
                                                summary={
                                                    <col.Summary
                                                        ad={ad}
                                                        name={col.name}
                                                        campaign={campaign}
                                                        error={columnError}
                                                        isCTVCampaign={isCTVCampaign}
                                                        inventoryPredictions={
                                                            inventoryPredictions[ad.id]
                                                        }
                                                    />
                                                }
                                                form={
                                                    col.Form &&
                                                    (closeModal => (
                                                        <col.Form
                                                            columnName={col.name}
                                                            ad={ad}
                                                            campaign={campaign}
                                                            onSave={closeModal}
                                                            onCancel={closeModal}
                                                            isCTVCampaign={isCTVCampaign}
                                                            platformFilter={ad.platform}
                                                            currentUser={currentUser}
                                                        />
                                                    ))
                                                }
                                                adPlatform={ad.platform}
                                                fixedFirstColumn={col.name === 'name'}
                                                onScroll={onScroll}
                                                offScroll={offScroll}
                                            />
                                        );
                                    })}
                                </Row>
                            );
                        }),
                        ads.length === 0 ? (
                            <Row key="noData">
                                <Cell colSpan={columns.length} noData>
                                    No Data
                                </Cell>
                            </Row>
                        ) : (
                            <Row key="footer" totalRow>
                                {_.map(visibleColumns, (col, cIndex) => {
                                    let content = '';
                                    switch (col.name) {
                                        case 'name':
                                            content = 'Ads Total';
                                            break;
                                        case 'billing_rate':
                                            content = getBillingRateSummary(ads, campaign);
                                            break;
                                        case 'goal':
                                            content = getGoalSummary(ads, campaign);
                                            break;
                                        case 'billings':
                                            content = getBillingsSummary(ads, campaign);
                                            break;
                                    }

                                    return (
                                        <Cell
                                            className={`media-plan-form__overview-table-total-row-column-${
                                                col.name
                                            }`}
                                            key={col.name}
                                            fixedFirstColumn={cIndex === 0}
                                            onScroll={onScroll}
                                            offScroll={offScroll}
                                        >
                                            {content}
                                        </Cell>
                                    );
                                })}
                            </Row>
                        ),
                    ]}
                </ComposableDataTable>
            </Card>
        );
    }
}

function getColumns({ campaign, ads, hiddenColumns, organization }) {
    const environmentSettings = getEnvironmentSettings();
    const campaignHasNoTactics = _.every(ads, { tactics_enabled: false });
    const campaignHasAllTactics = _.every(ads, { tactics_enabled: true });
    const { fta_version, fta_management_level } = campaign;
    const columns = [];
    const isMultiFlightCampaign = campaign.flightPacingStrategy === 'campaign';
    const isCTVCampaign = campaign.type === CampaignTypeMapping.CTV;
    const isDOOHCampaign = campaign.type === CampaignTypeMapping.DOOH;

    columns.push({
        label: 'Name',
        name: 'name',
        Summary: NameSummary,
        Form: NameForm,
        canEditBulk: () => false,
        canEditSingle: () => true,
    });

    if (campaign.billing_enabled) {
        columns.push({
            label: 'Billing Rate',
            name: 'billing_rate',
            required: true,
            Summary: BillingRateSummary,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    columns.push({
        label: 'Goal',
        name: 'goal',
        required: true,
        Summary: GoalSummary,
        Form: campaign.budget_allocation_method !== 'auto' ? GoalForm : null,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    columns.push({
        label: 'Third Party Fees',
        name: 'thirdPartyFees',
        Summary: ThirdPartyFeesSummary,
        Form: ThirdPartyFeesForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    columns.push({
        label: 'Budget',
        name: 'billings',
        Summary: BillingsSummary,
        canEditBulk: () => false,
        canEditSingle: () => false,
    });

    columns.push({
        label: 'Bid Strategy',
        name: 'bid_strategy',
        Summary: BidStrategySummary,
        Form: BidStrategyForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    columns.push({
        label: 'Pacing Strategy',
        name: 'pacing_strategy',
        required: true,
        Summary: PacingStrategySummary,
        Form: campaign.budget_allocation_method !== 'auto' ? PacingStrategyForm : null,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    if (environmentSettings.canUseFta()) {
        if (fta_version === 1) {
            columns.push({
                label: 'Foot Traffic Attribution',
                name: 'foot_traffic_attribution',
                required: true,
                Summary: FootTrafficAttributionV1Summary,
                Form: FootTrafficAttributionV1Form,
                canEditBulk: () => true,
                canEditSingle: () => true,
            });
        } else if (fta_version === 2 && organization.fta_partner_id) {
            columns.push({
                label: 'Foot Traffic Attribution',
                name: 'foot_traffic_attribution',
                required: true,
                Summary: FootTrafficAttributionV2Summary,
                Form: fta_management_level === 'campaign' ? null : FootTrafficAttributionV2Form,
                canEditBulk: () => campaign.fta_enabled,
                canEditSingle: () => campaign.fta_enabled,
            });
        }
    }

    if (!isDOOHCampaign) {
        columns.push({
            label: 'Frequency Cap',
            name: 'max_user_frequency',
            Summary: FrequencyCapSummary,
            Form: FrequencyCapForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    const canEditSingleStartDate = ad =>
        !isMultiFlightCampaign && !ad.isDelivering && !ad.unalteredDuplicate;

    columns.push({
        label: 'Start',
        name: 'start_date',
        required: true,
        Summary: FlightSummary,
        Form: FlightForm,
        canEditBulk: ({ ads }) => {
            if (isMultiFlightCampaign) {
                return false;
            }

            const adsWithSingleEditAvailable = _.filter(ads, ad => canEditSingleStartDate(ad));
            return adsWithSingleEditAvailable.length > 0;
        },
        canEditSingle: canEditSingleStartDate,
    });
    columns.push({
        label: 'End',
        name: 'end_date',
        required: true,
        Summary: FlightSummary,
        Form: FlightForm,
        canEditBulk: () => !isMultiFlightCampaign,
        canEditSingle: () => !isMultiFlightCampaign,
    });

    columns.push({
        label: 'Dayparts',
        name: 'dayparts',
        Summary: DaypartsSummary,
        Form: DaypartsForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });
    columns.push({
        label: 'Weekparts',
        name: 'weekparts',
        Summary: WeekpartsSummary,
        Form: WeekpartsForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    columns.push({
        label: 'Creative',
        name: 'creative',
        Summary: CreativeSummary,
        Form: CreativeForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    if (campaignHasNoTactics) {
        columns.push({
            label: 'Geo',
            name: 'geo',
            required: true,
            Summary: GeoSummary,
            Form: GeoForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    } else {
        columns.push({
            label: 'Geotargeting',
            name: 'geo_targeting',
            required: true,
            Summary: GeoTargetingSummary,
            Form: GeoTargetingForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
        if (environmentSettings.canUseGeoFencing() && !isCTVCampaign) {
            columns.push({
                label: 'Geofencing',
                name: 'geo_fencing',
                Summary: GeoFencingSummary,
                Form: GeoFencingForm,
                canEditBulk: () => true,
                canEditSingle: () => true,
            });
        }
    }

    if (!isDOOHCampaign) {
        columns.push({
            label: 'Device OS',
            name: 'target_device_os',
            Summary: DeviceOsSummary,
            Form: DeviceOsForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    if (environmentSettings.canUseCarrierIsp() && !isDOOHCampaign) {
        if (campaign.carriersISPsVersion === 1) {
            columns.push({
                label: 'Carrier',
                name: 'target_carriers',
                Summary: CarrierSummary,
                Form: CarrierForm,
                canEditBulk: () => true,
                canEditSingle: () => true,
            });
        }

        if (campaign.carriersISPsVersion === 2) {
            columns.push({
                label: 'Carrier / ISP',
                name: 'target_carriers_isps',
                Summary: TargetCarriersIspsSummary,
                Form: TargetCarriersIspsForm,
                canEditBulk: () => true,
                canEditSingle: () => true,
            });
        }
    }

    if (!isCTVCampaign && !isDOOHCampaign) {
        columns.push({
            label: 'Language',
            name: 'target_device_language',
            Summary: LanguageSummary,
            Form: LanguageForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    if (campaign.isAppStoreCatEnabled) {
        if (campaignHasNoTactics) {
            columns.push({
                label: 'App Categories',
                name: 'app_categories',
                Summary: AppCategorySummary,
                Form: AppCategoryForm,
                canEditBulk: () => true,
                canEditSingle: () => true,
            });
        }
    } else {
        columns.push({
            label: 'IAB Categories',
            name: 'iab_categories',
            Summary: IABCategorySummary,
            Form: IABCategoryForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    if (environmentSettings.canUseGenderTargeting() && !isCTVCampaign && !isDOOHCampaign) {
        columns.push({
            label: 'Gender',
            name: 'target_genders',
            Summary: GenderSummary,
            Form: GenderForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    if (environmentSettings.canUseAgeTargeting() && !isCTVCampaign && !isDOOHCampaign) {
        columns.push({
            label: 'Age',
            name: 'target_age_groups',
            Summary: AgeSummary,
            Form: AgeForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    if (environmentSettings.canUseIas() && !isCTVCampaign && !isDOOHCampaign) {
        columns.push({
            label: 'Third Party Verification',
            name: 'third_party_vetification',
            Summary: ThirdPartyVerificationSummary,
            Form: ThirdPartyVerificationForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }
    if (campaignHasNoTactics) {
        columns.push({
            label: 'Audience Segments',
            name: 'audiences',
            Summary: AudienceSummary,
            Form: AudienceForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    if (campaignHasAllTactics && environmentSettings.canUseTactics()) {
        columns.push({
            label: 'Tactics',
            name: 'tactics',
            Summary: TacticsSummary,
            Form: TacticsForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    if (environmentSettings.canUseExchanges()) {
        columns.push({
            label: 'Exchanges',
            name: 'exchanges',
            Summary: ExchangesSummary,
            Form: ExchangesForm,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    columns.push({
        label: 'Public Inventory',
        name: 'public_inventory',
        Summary: PublicInventorySummary,
        Form: PublicInventoryForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    columns.push({
        label: flags.isEnabled('efb_35_curated_deals') ? 'Deals' : 'Private Deals',
        name: 'deals',
        Summary: DealsSummary,
        Form: DealsForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    columns.push({
        label: environmentSettings.getAppsAndSitesLabel(),
        name: 'apps_and_sites',
        Summary: AppsAndSitesSummary,
        Form: AppsAndSitesForm,
        canEditBulk: () => true,
        canEditSingle: () => true,
    });

    if (isDOOHCampaign && flags.isEnabled('efb_17_dooh_target_venue')) {
        columns.push({
            label: 'DOOH Venue Type',
            name: 'dooh_venue_types',
            Summary: DoohVenueTypeSummaryBulkEdit,
            Form: DoohVenueTypeFormBulkEdit,
            canEditBulk: () => true,
            canEditSingle: () => true,
        });
    }

    columns.push({
        label: 'Estimated Total Imprs.',
        name: 'estimated_total_impressions',
        Summary: EstimatedImpressionsSummary,
        canEditBulk: () => false,
        canEditSingle: () => false,
    });
    columns.push({
        label: 'Estimated Daily Imprs.',
        name: 'estimated_daily_impressions',
        Summary: EstimatedDailyImpressionsSummary,
        canEditBulk: () => false,
        canEditSingle: () => false,
    });
    columns.push({
        label: 'Estimated Uniques',
        name: 'estimated_uniques',
        Summary: EstimatedUniquesSummary,
        canEditBulk: () => false,
        canEditSingle: () => false,
    });

    return _(columns)
        .filter(x => x)
        .map(column => {
            return {
                ...column,
                hide: hiddenColumns[column.name],
            };
        })
        .value();
}

class MediaPlanCell extends React.Component {
    state = { isModalOpen: false, menuAnchor: null, platformFilter: null };

    openModal = platformFilter => {
        this.setState({ isModalOpen: true, menuAnchor: null, platformFilter });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    openMenu = e => {
        this.setState({ menuAnchor: e.currentTarget });
    };

    onCloseMenu = () => {
        this.setState({ menuAnchor: null });
    };

    render() {
        const { menuAnchor, platformFilter } = this.state;
        const {
            summary,
            form,
            name,
            header,
            dropdown,
            adPlatform,
            classes,
            campaign,
            canEdit = true,
            onClickOverwrite,
        } = this.props;
        const showDropdown = header;
        const restrictedColumns = campaign.carriersISPsVersion === 1 ? ['target_carriers'] : [];
        const restrictedPlatforms = ['mweb', 'desktop'];
        const isRestrictedPlatformInColumn =
            !header && restrictedColumns.includes(name) && restrictedPlatforms.includes(adPlatform);
        return (
            <Cell
                colSpan={this.props.colSpan}
                className={`media-plan-form__overview-table-column-${name}`}
                onScroll={this.props.onScroll}
                offScroll={this.props.offScroll}
                fixedFirstColumn={this.props.fixedFirstColumn}
                bordered={true}
            >
                <Grid container wrap="nowrap" justifyContent="space-between">
                    <Grid item className={classes.columnContent}>
                        {summary}
                    </Grid>
                    <Grid item>
                        <Can intent="campaign__view_and_edit">
                            {canDo => {
                                if (!canDo) {
                                    return null;
                                }
                                if (!form) {
                                    return null;
                                }

                                if (isRestrictedPlatformInColumn) {
                                    return null;
                                }

                                if (!canEdit) {
                                    return null;
                                }

                                return (
                                    <InlineTooltip
                                        position="bottom"
                                        tooltip={this.props.tooltipText}
                                        className="media-plan-form__overview-table-tooltip"
                                    >
                                        <div className="media-plan-form__overview-table-edit-icon">
                                            <IconButton
                                                aria-label="edit"
                                                onClick={
                                                    !!onClickOverwrite
                                                        ? () => onClickOverwrite(this.openModal)
                                                        : showDropdown
                                                        ? this.openMenu
                                                        : this.openModal
                                                }
                                                size="small"
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                        {showDropdown &&
                                            !!menuAnchor &&
                                            dropdown(menuAnchor, this.onCloseMenu, this.openModal)}
                                    </InlineTooltip>
                                );
                            }}
                        </Can>
                    </Grid>
                </Grid>
                <Can intent="campaign__view_and_edit">
                    {canDo => {
                        if (!canDo) {
                            return null;
                        }
                        if (!form) {
                            return null;
                        }

                        return (
                            <Dialog open={this.state.isModalOpen} fullWidth maxWidth="md">
                                {form(this.closeModal, platformFilter)}
                            </Dialog>
                        );
                    }}
                </Can>
            </Cell>
        );
    }
}

function areAllSame(list) {
    return _.every(list, item => item === list[0]);
}

function formatTotalTargetByPacing(value, pacing) {
    switch (pacing) {
        case 'impressions':
        case 'clicks':
            return formatNumber_whole(value);
        case 'billings':
        case 'spend':
            return formatNumber_currency(value);
    }
}
function sumByField(ads, field) {
    let sum = 0;

    _.each(ads, ad => {
        sum += ad[field];
    });

    return sum;
}
function getBillingRateSummary(ads, campaign) {
    const pacings = _.map(ads, ad => ad.primary_pacing);
    const firstAd = ads[0];
    if (!firstAd) {
        return '';
    }

    if (!areAllSame(pacings)) {
        return 'Mixed';
    }

    // Campaign Billings Off
    if (!isBillingsManagedThroughEF(campaign)) {
        // Billing Rate column is turned off when Campaign Billings is off, so dont display anything
        return null;
    }

    // Campaign Billings On
    return _(ads)
        .map(ad => {
            if (isCpmCpcAd(ad)) {
                return `${formatNumber_currency(ad.billing_rate)} ${formatBillingTerm(
                    ad.ef_billing_terms
                )}`;
            } else {
                return `${formatNumber_percentage(ad.billing_rate)} ${formatBillingTerm(
                    ad.ef_billing_terms
                )}`;
            }
        })
        .uniq()
        .sort()
        .join(', ');
}
function getGoalSummary(ads, campaign) {
    const pacings = _.map(ads, ad => ad.primary_pacing);
    const firstAd = ads[0];
    if (!firstAd) {
        return '';
    }

    if (!areAllSame(pacings)) {
        return 'Mixed';
    }

    // Campaign Billings Off
    if (!isBillingsManagedThroughEF(campaign)) {
        const totalNonBillableVolume = sumByField(ads, 'non_billable_volume');
        return formatTotalTargetByPacing(totalNonBillableVolume, pacings[0]);
    }

    // Campaign Billings On
    const totalBillableVolume = sumByField(ads, 'billable_volume');
    let formattedBillableVolume = formatTotalTargetByPacing(
        totalBillableVolume,
        firstAd.primary_pacing
    );
    const goalLabelMapping = {
        impressions: 'Billable Impressions',
        clicks: 'Billable Clicks',
        spend: 'Total Cost Budget',
        billings: 'Revenue Budget',
    };

    return (
        <div>
            <div className="ef5-text-size_hint">{goalLabelMapping[firstAd.primary_pacing]}</div>
            <div className="media-plan-form__overview-table-total-row-data">
                {formattedBillableVolume}
            </div>
        </div>
    );
}
function getBillingsSummary(ads, campaign) {
    const pacings = _.map(ads, ad => ad.primary_pacing);
    const firstAd = ads[0];
    if (!firstAd) {
        return '';
    }

    if (!areAllSame(pacings)) {
        return 'Mixed';
    }

    // Campaign Billings Off
    if (!isBillingsManagedThroughEF(campaign)) {
        return pacings[0];
    }

    // billings
    const isMarginTerm = firstAd.ef_billing_terms === 'billable_media_cost_margin';
    let totalBillings = 0;
    if (isMarginTerm) {
        _.each(ads, ad => (totalBillings += getBillableVolume(ad)));
    } else {
        totalBillings = sumByField(ads, 'max_total_billings');
    }

    const billingsLabelMapping = {
        impressions: 'Revenue Budget',
        clicks: 'Revenue Budget',
        spend: 'Revenue Budget',
        billings: 'Total Cost Budget',
    };

    return (
        <div>
            <div className="ef5-text-size_hint">{billingsLabelMapping[firstAd.primary_pacing]}</div>
            <div className="media-plan-form__overview-table-total-row-data">
                {formatNumber_currency(totalBillings)}
            </div>
        </div>
    );
}

export default withStyles(styles)(connect(selector)(MediaPlanForm));

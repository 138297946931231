import _ from 'lodash';
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MultiSelect } from 'widgets-v6/select';

export const ConversionsForCreativeOptimization = ({ onChange, options, value }) => {
    return (
        <React.Fragment>
            <Grid container alignItems="center">
                <Grid item xs={8}>
                    <MultiSelect
                        placeholder={
                            value && value.length === 0 ? 'Select optimized conversion events' : ''
                        }
                        value={value}
                        onChange={onChange}
                        options={options}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export const ConversionsForCreativeOptimizationSummary = ({ value }) => {
    return <Typography>{value}</Typography>;
};

import toastr from 'toastr';
import { createHttp } from 'utils/http';
import flags from 'containers/flags/service';

const actions = {
    getFtaLocationLists: () => {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'FTA_LOCATION_LISTS__FETCH_DATA__START',
                    payload: {},
                });

                const http = createHttp();
                const query = `
                    query getFtaLocationLists {
                        ftaLocationLists {
                            id
                            comment
                            custom_value
                            name
                            status
                            poi_count
                            requested_at
                            requested_by
                            raw_location_file_URL
                        }
                    }
                `;

                const { ftaLocationLists } = await http.graphql(query, {});

                dispatch({
                    type: 'FTA_LOCATION_LISTS__FETCH_DATA__END',
                    payload: {
                        data: ftaLocationLists,
                    },
                });
            } catch (err) {
                console.log(err);
                toastr.error('FTA Location Lists failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

import React, { Fragment } from 'react';
import { Link } from 'react-router';
import _ from 'lodash';

import {
    Section,
    SubSection,
    Image,
    List,
    ListItem,
    Layout,
    Paragraph,
} from '../modules/layout.js';
import { Subheading } from 'widgets-v5/typography';
import Spacer from 'widgets-v5/spacer';
import flags from 'containers/flags/service';

export default {
    category: "What's New",
    topic: "What's New",
    url: 'whats-new/whats-new',
    published: true,
    Component: class Article extends React.Component {
        constructor(props) {
            super(props);

            this._2019_06_17 = React.createRef();
            this._2019_06_11 = React.createRef();
            this._2019_06_03 = React.createRef();
        }

        render() {
            return (
                <Layout
                    title="What's New"
                    sidebarContent={_.compact([
                        {
                            title: '2019-06-17 / Reporting Delayed [U]',
                            link: this._2019_06_17,
                        },
                        {
                            title: '2019-06-11 / Track holistic campaign spend with Total Cost',
                            link: this._2019_06_11,
                        },
                        {
                            title: '2019-06-03 / Scheduled Maintenance Notice',
                            link: this._2019_06_03,
                        },
                    ])}
                >
                    <Section link={this._2019_06_17} title="2019-06-17 / Reporting Delayed [U]">
                        <Spacer type="medium" />
                        <Subheading>
                            [Update — 18 June 2019, 09:00 ET: Reports have been recovered and are
                            now updating as normal]
                        </Subheading>
                        <Spacer type="medium" />
                        <Subheading>
                            Delays in reporting from 16 June 2019, 00:00 UTC onwards
                        </Subheading>
                        <Spacer type="medium" />
                        <Paragraph>
                            Impressions, clicks, spend, and other metrics from 16 June 2019, 00:00
                            (UTC) onwards are currently under-reporting due to delays in report
                            processing. We are working to restore these reports as soon as possible.
                            We apologize for the inconvenience.
                        </Paragraph>
                        <Paragraph>
                            Where possible, please avoid using or exporting reports that include the
                            16-17 June period until this problem has been resolved.
                        </Paragraph>
                        <Paragraph>
                            Campaign delivery will continue as normal unless campaigns or ads are
                            intentionally paused. To see live progress of campaign delivery, you may
                            use the campaign's Real-Time Progress tab. Real-Time Progress is
                            unaffected by this delay and will continue to be up-to-date.
                        </Paragraph>
                    </Section>
                    <Spacer type="large" />
                    <Section
                        link={this._2019_06_11}
                        title="2019-06-11 / Track holistic campaign spend with Total Cost"
                    >
                        <Spacer type="medium" />
                        <Paragraph>
                            EngageFront is adding better support for the Total Cost metric, to help
                            you better track campaign spend holistically.
                        </Paragraph>
                        <Paragraph>
                            Across EngageFront, we are replacing the Billable Cost metric with Total
                            Spend. What's the difference? Total Cost will include any 3rd Party Fees
                            (fees added by third-party adservers, like DCM) that you choose to track
                            on EngageFront.
                        </Paragraph>
                        <Paragraph>
                            The example below illustrates the difference:
                            <Image url="/images/help/metrics-differences.png" />
                        </Paragraph>
                        <Paragraph>
                            Here are some ways to take advantage of the new Total Cost metric.
                        </Paragraph>
                        <Spacer type="medium" />
                        <Subheading>Reporting</Subheading>
                        <SubSection title="Breakdowns">
                            Billable Cost has been replaced by Total Cost across EngageFront as the
                            primary way to measure and track spend. Campaign Reports will now also
                            show the breakdown of Total Cost to help you understand where your spend
                            is going.
                            <Image url="/images/help/reporting-breakdown.png" />
                        </SubSection>
                        <Spacer type="medium" />
                        <Subheading>Adding 3rd Party Fees to campaigns and ads</Subheading>
                        <SubSection title="Adding 3rd Party Fees to Ads">
                            <Paragraph>
                                You can now add 3rd Party Fees to ads. 3rd Party Fees are now
                                tracked individually per ad so that each ad can have a different
                                fee.
                            </Paragraph>
                        </SubSection>
                        <Image url="/images/help/third-party-fees.png" />
                        <SubSection title="Adding Default 3rd Party Fees to Campaigns">
                            <Paragraph>
                                When 3rd Party Fees are added to a campaign, they become the default
                                fees for new ads being created. When you create a new ad, the
                                Default 3rd Party Fees will be copied over from the campaign to the
                                ad and can then be further adjusted at the ad level. Existing ads
                                will not automatically update when the campaign's Default 3rd Party
                                Fees is updated.
                            </Paragraph>
                        </SubSection>
                        <Spacer type="medium" />
                        <Subheading>Limiting Spend</Subheading>
                        <SubSection title="Limiting Campaign Total Cost">
                            <Paragraph>
                                <Image url="/images/help/limit-campaign-total-spend.png" />
                                At the campaign level, you can enter a holistic budget here to
                                prevent overspending. Total Cost is the metric used for limiting
                                spend, so that budgets for data costs and 3rd party fees do not have
                                to be separately calculated or reserved.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Limiting Ad Total Cost">
                            <Paragraph>
                                When a campaign's revenue model is set to "Agency Margin", "Total
                                Spend Markup" or is disabled, a Total Cost cap can be set at the ad
                                level. To learn more about revenue models, see{' '}
                                <Link href="/help/guides/budget-revenue-model">
                                    Budget & Revenue Model
                                </Link>
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Bid Optimization">
                            <Paragraph>
                                When setting up Bid Optimization for an ad, Total Cost is also used.
                                All data costs and 3rd party fees are deducted to automatically
                                calculate the final bid price.
                                <Image url="/images/help/bid-optimization-total-spend.png" />
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Spacer type="large" />
                    <Section
                        link={this._2019_06_03}
                        title="2019-06-03 / Scheduled Maintenance Notice"
                    >
                        <Spacer type="medium" />
                        <Subheading>
                            Expected delays in reporting from 15 June 2019, 11pm ET to 17 June 2019,
                            9am ET
                        </Subheading>
                        <Spacer type="medium" />
                        <Paragraph>
                            At 11pm on 15 June 2019 11pm, we will begin conducting preventative
                            maintenance to ensure future stability of our platform. The processing
                            of campaign reports will be temporarily affected. We expect EngageFront
                            to return to full functionality by 9am on 17 June 2019.
                        </Paragraph>
                        <Spacer type="medium" />
                        <SubSection title="From 15 June 2019, 11pm to 16 June 2019, 5am (approximate)">
                            <Paragraph>
                                The processing of campaign reports on EngageFront will be
                                temporarily suspended. Our ad servers will continue to record
                                impressions, clicks, media spend and other metrics on the backend,
                                but these metrics will not be updated on your EngageFront view.
                                Reports generated or exported during this time, including scheduled
                                reports, will display the last known numbers on 15 June 2019.
                            </Paragraph>
                            <Paragraph>
                                Backend campaign pacing is not affected and, to minimize disruption,
                                media buying will continue as normal unless ads are otherwise
                                paused. If necessary, you can use a campaign’s Real-time Progress
                                page to see live, unprocessed numbers. Real-time Progress displays
                                live numbers as recorded by our ad servers, but may not reflect the
                                final processed numbers displayed on other EngageFront pages.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="From 16 June 2019, 5am to 17 June 2019, 9am (approximate)">
                            <Paragraph>
                                EngageFront will begin processing reports again, but delays in
                                displaying recent campaign metrics are expected until 17 June 2019,
                                9am. Reports generated or exported during this time, including
                                scheduled reports, may not display the campaign’s final results. It
                                is advised to re-generate these reports after 17 June 2019, 9am.
                            </Paragraph>
                        </SubSection>
                        <Spacer type="medium" />
                        <Paragraph>
                            We apologize for any inconvenience. To minimize disruption, this
                            maintenance has been scheduled for off-peak hours and media buying will
                            continue as normal unless ads are otherwise paused.
                        </Paragraph>
                        <Paragraph>
                            EngageFront will remain accessible throughout this period. All posted
                            times are in Eastern Time (UTC-04:00).
                        </Paragraph>
                    </Section>
                </Layout>
            );
        }
    },
};

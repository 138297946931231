import { createHttp } from 'utils/http';
import toastr from 'toastr';

const http = createHttp();

const actions = {
    fetchAudiencesWithGraphQl(shouldHideFlexSegments) {
        return async (dispatch, getState) => {
            try {
                const isFetching = _.get(getState(), 'audienceSegmentPicker.isFetching');
                if (isFetching) {
                    return;
                }

                dispatch({ type: 'AUDIENCE_SEGMENT_PICKER__AUDIENCES__FETCH_START' });

                const query = `
                    query getAudiences ($keywords: String, $source_type: [String]) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type }) {
                            id
                            value: id
                            label: name
                            name
                            dataCost
                            uniques
                            mode
                            retargeting_type
                            source_type
                            source_type_category
                            description
                            supports
                            tier
                            uniqueDevices: uniques
                            uniqueCTVs
                            uniqueDOOHs
                            third_party_fees
                            deprecated
                            contact_ces
                            status
                        }
                        flexSegments(filters: { keywords: $keywords, source_type: ["flex_standard_brand", "flex_custom_brand", "flex_location_category"] }) {
                            id
                            value: id
                            label: name
                            name
                            source_type
                            status
                            source_type_category
                            points
                            supports
                            uniques
                            uniqueCTVs
                            uniqueDOOHs
                            hasTimeConstraint
                        }
                    }
                `;

                const variables = {
                    keywords: '',
                    source_type: [],
                };

                const data = await http.graphql(query, variables);

                const audienceSegments = _.get(
                    getState(),
                    'audienceSegmentPicker.audienceSegments',
                    []
                );
                const flexSegments = _.get(getState(), 'audienceSegmentPicker.flexSegments', []);

                const areSegmentsEqual =
                    _.isEqual(audienceSegments, data.audienceSegments) &&
                    _.isEqual(flexSegments, data.flexSegments);

                if (areSegmentsEqual) {
                    return;
                }
                if (!shouldHideFlexSegments) {
                    dispatch({
                        type: 'AUDIENCE_SEGMENT_PICKER__AUDIENCES__FETCH_END',
                        payload: {
                            data: {
                                audiences: data.audienceSegments,
                                flexSegments: data.flexSegments,
                            },
                        },
                    });
                } else {
                    dispatch({
                        type: 'AUDIENCE_SEGMENT_PICKER__AUDIENCES__FETCH_END',
                        payload: {
                            data: { audiences: data.audienceSegments },
                        },
                    });
                }
            } catch (err) {
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import Avatar from '@mui/material/Avatar';
import CreditCard from '@mui/icons-material/CreditCard';
import Tooltip from '@mui/material/Tooltip';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';
import { AudienceCard } from '../modules/audience-card';
import { AudienceTable } from '../modules/audience-table';
import flags from 'containers/flags/service';

const categoryMap = {
    standard: {
        label: 'Standard',
        description: 'Users who frequent locations from select brands',
        color: '#FFB0C1',
    },
    seasonal: {
        label: 'Seasonal',
        description: 'Users who frequent top locations from select categories',
        color: '#FFCE50',
    },
};
const subCategoryColor = {
    Standard: '#FFB0C1',
    Seasonal: '#FFCE50',
};
const tabValueMapping = {
    all: {
        value: ['standard', 'seasonal'],
        label: 'All',
    },
    standard: {
        value: ['standard'],
        label: 'Standard',
    },
    seasonal: {
        value: ['seasonal'],
        label: 'Seasonal',
    },
};
const tabValueMappingV2 = {
    all: {
        value: ['Standard', 'Seasonal'],
        label: 'All',
    },
    standard: {
        value: ['Standard'],
        label: 'Standard',
    },
    seasonal: {
        value: ['Seasonal'],
        label: 'Seasonal',
    },
};

const getTabs = ({ audiences }) => {
    const tabs = [
        {
            value: 'all',
            label: 'All',
            avatar: <Avatar>{audiences && audiences.length}</Avatar>,
        },
        {
            value: 'standard',
            label: 'Standard',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Standard';
                            } else {
                                return audience.subType === 'standard';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'seasonal',
            label: 'Seasonal',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Seasonal';
                            } else {
                                return audience.subType === 'seasonal';
                            }
                        }).length}
                </Avatar>
            ),
        },
    ];

    return tabs;
};

const sortingOptions = [
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
];

const getTierIcon = tier => {
    let tierIcon;

    if (tier === 'Top Tier Spender' || tier === 'Top Spending Geography') {
        tierIcon = 'fa fa-trophy';
    } else if (tier === 'Frequent Transactor' || tier === 'Frequently Transacting Geography') {
        tierIcon = 'fa fa-credit-card';
    } else if (tier === 'In Market' || tier === 'In Market Geography') {
        tierIcon = 'fa fa-shopping-cart';
    } else if (tier === 'Big Ticket Shopper') {
        tierIcon = 'fa fa-ticket';
    }

    return tierIcon;
};

const useStyles = makeStyles(() => ({
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: '3.5em',
        height: '3.5em',
        color: '#098679',
    },
}));

const Layout = ({ audienceSegments, onSearch, totalSegmentCount, activeAudience, organizationId }) => {
    const classes = useStyles();

    const [activeTab, setActiveTab] = useState('all');
    const [activeSort, setActiveSort] = useState({ value: 'name', direction: 'asc' });

    const handleChangeTab = newValue => {
        setActiveTab(newValue);
        onSearch(null);
        setActiveSort({ value: 'name', direction: 'asc' });
    };

    const handleSearch = event => {
        onSearch(event.target.value);
    };

    const handleSortChange = event => {
        const { value, direction } = _.find(sortingOptions, { value: event.target.value });
        setActiveSort({ value, direction });
    };

    const sortedAudiences = _.orderBy(audienceSegments, [activeSort.value], [activeSort.direction]);

    const filteredAudiences = _.filter(sortedAudiences, audience => {
        if (flags.isEnabled('en_2987_use_audience_category_and_subCategory_code')) {
            const activeSubCategories = tabValueMappingV2[activeTab].value;
            return _.includes(activeSubCategories, audience.subCategory);
        }
        const activeSourceTypes = tabValueMapping[activeTab].value;
        return _.includes(activeSourceTypes, audience.subType);
    });

    const tabs = getTabs({ audiences: audienceSegments });

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'pelmorex' },
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Pelmorex Audiences
                </RouterLink>
                <Typography color="textPrimary">Mastercard</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <CreditCard className={classes.icon} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            Mastercard
                        </Typography>
                        <Box mt={2}>
                            <Typography variant="h6" color="textSecondary" gutterBottom>
                                Target users based on aggregate consumer spending behaviour. Reach
                                more of the likeliest purchasers, with less waste in your online
                                spend.
                            </Typography>
                        </Box>
                        <Grid container>
                            <Grid item mt={3}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total
                                </Typography>
                                <Typography variant="h5">{totalSegmentCount}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={5} mb={1.5}>
                    <Grid container spacing={1}>
                        {_.map(tabs, option => (
                            <Grid item key={option.value}>
                                <Tooltip
                                    title={
                                        <Typography variant="body2">{option.tooltip}</Typography>
                                    }
                                    disableHoverListener={!option.tooltip}
                                >
                                    <Chip
                                        label={option.label}
                                        avatar={option.avatar}
                                        color={activeTab === option.value ? 'primary' : 'default'}
                                        onClick={() => handleChangeTab(option.value)}
                                    />
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box mb={2.5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <SearchInput
                                placeholder="Filter Audiences"
                                onChange={handleSearch}
                                key={`${activeTab}_search`}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort.value}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <AudienceTable
                    audiences={filteredAudiences}
                    cardSize="large"
                    activeAudience={activeAudience}
                    rowHeight={400}
                    renderContent={segment => (
                        <AudienceCard
                            segment={segment}
                            categoryLabel={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? segment.subCategory
                                    : categoryMap[segment.subType].label
                            }
                            categoryColor={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? subCategoryColor[segment.subCategory]
                                    : categoryMap[segment.subType].color
                            }
                            metaData={
                                segment.tier &&
                                segment.tier.length > 0 && (
                                    <Chip
                                        label={segment.tier}
                                        size="small"
                                        icon={
                                            <Icon
                                                fontSize="small"
                                                className={getTierIcon(segment.tier)}
                                            />
                                        }
                                        variant="outlined"
                                    />
                                )
                            }
                            description={segment.description}
                            dataCost={segment.dataCost}
                            active={segment.id === activeAudience}
                            organizationId={organizationId}
                        />
                    )}
                />
            </Container>
        </Box>
    );
};

export default Layout;

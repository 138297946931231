import _ from 'lodash';
import moment from 'moment';

import createPivotTableService from 'widgets/pivot-table/service';
import { getDimensions } from 'common/constants/dimensions';
import METRICS from 'common/constants/metrics';
import validate from 'utils/validate';
import { getValidators, getValidatorsV2 } from './services/validation-rules';
import { reducer as newReducer } from './v2/reducer';
import CONSOLE_TEAM_USERS from 'common/constants/console-team-users';
import { getEnvironmentSettings } from '../../../../services/environment';

export const NAME = 'businessReportEditor';
const METRIC_LOOKUP = {};
_.each(METRICS, metric => {
    METRIC_LOOKUP[metric.name] = metric;
});

function getDimensionLookup() {
    const DIMENSION_LOOKUP = {};
    _.each(getDimensions(), dimension => {
        DIMENSION_LOOKUP[dimension.name] = dimension;
    });

    return DIMENSION_LOOKUP;
}

const pt = createPivotTableService();

const initialState = {
    searchFilter: '',
    selectAll: false,
    selectedTab: 'dateRange',
    isLoading: false,
    isSaving: false,
    reportInitialized: false,
    showErrors: false,
    errors: {},
    exportErrors: {},
    isSaveModalOpen: false,
    isSaveAsModalOpen: false,
    schemaVersion: '2',
    organizationsForFiltering: [],
    campaigns: [],
    draft: {
        name: '',
        saveAsName: '',
        timezone: 'UTC',
        organizationFilter: {
            isEnabled: true,
            ids: [],
            mode: 'include',
        },
        dateRange: {
            isEnabled: true,
            start: moment()
                .startOf('month')
                .format('YYYY-MM-DDTHH:mm:ss'),
            end: moment()
                .endOf('month')
                .format('YYYY-MM-DDTHH:mm:ss'),
            last: null,
            lastFrame: null,
            type: 'preset', // automatic, dynamic, custom, preset
            from: 'day_before_report_generation_date', // report_generation_date
        },
        adEndDate: {
            isEnabled: false,
            start: moment()
                .startOf('month')
                .format('YYYY-MM-DDTHH:mm:ss'),
            end: moment()
                .endOf('month')
                .format('YYYY-MM-DDTHH:mm:ss'),
            last: null,
            lastFrame: null,
            type: 'preset', // automatic, dynamic, custom, preset
            from: 'day_before_report_generation_date', // report_generation_date
        },
        campaigns: {
            isEnabled: false,
            ids: [],
            mode: 'include',
        },
        campaignStatus: null,
        creativeType: null,
        iabCategory: null,
        dimensions: ['campaign_id'],
        metrics: ['impressions'],
        attributes: [],
        client: null,
        engagements: [],
        conversions: [],
        ignore_filter_by_date_range: false,
        showSpendOverdeliveries: false,
        reportMode: 'date',
        schedule: {
            frequency: 'none',
            date: new Date(),
            time: new Date(),
            day_of_week: {
                mon: false,
                tue: false,
                wed: false,
                thu: false,
                fri: false,
                sat: false,
                sun: false,
            },
            dayOfMonth: 1,
            recipients: [],
        },
    },
    scheduleDraft: {},
    scheduleDraftError: null,
    showScheduleDraftError: false,
    isScheduleReportOpen: false,
    isPivotTableInitialized: false,
    isPivotTableLoading: false,
    columns: [{ label: 'Dimensions', name: 'dimension', defaultValue: '', formatType: '' }],
    dictionary: {},
    hideZeros: false,
    pivotTable: {
        splits: [],
        hideSplits: true,
        dimensions: [],
        sort: {
            column: 'dimension',
            order: 'asc',
        },
        tree: {},
    },
    isEditorOpen: false,
};

function reducerOld(state = initialState, action) {
    switch (action.type) {
        case 'BUSINESS_REPORT_EDITOR__INIT_REPORT__START': {
            return {
                ...state,
                isLoading: true,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__INIT_REPORT__END': {
            return initializeDraft(state, action);
        }
        case 'BUSINESS_REPORT_EDITOR__SAVE__START': {
            let nextState = {
                ...state,
                isSaving: true,
                showErrors: true,
            };

            return validateDraft(nextState);
        }
        case 'BUSINESS_REPORT_EDITOR__SAVE__END': {
            return {
                ...initialState,
                campaigns: state.campaigns,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__SAVE__CLIENT_ERROR': {
            return {
                ...state,
                isSaving: false,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__SAVE__SERVER_ERROR': {
            const errors = {};
            _.each(action.payload.error, error => {
                errors[error.field] = [error.message];
            });

            return {
                ...state,
                isSaving: false,
                errors,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__APPLY': {
            return {
                ...validateDraft(state),
                showErrors: true,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__REPORT_CAMPAIGNS__FETCH_START': {
            return {
                ...state,
                isLoadingCampaigns: true,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__REPORT_CAMPAIGNS__FETCH_END': {
            return {
                ...state,
                isLoadingCampaigns: false,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__UPDATE_DRAFT': {
            return updateDraft(state, action);
        }
        case 'BUSINESS_REPORT_EDITOR__OPEN_SAVE_MODAL': {
            return openSaveModal(state, action.payload.name);
        }
        case 'BUSINESS_REPORT_EDITOR__CLOSE_SAVE_MODAL': {
            return closeSaveModal(state, action.payload.name);
        }
        case 'BUSINESS_REPORT_EDITOR__OPEN_SAVE_AS_MODAL': {
            return openSaveAsModal(state, action.payload.name);
        }
        case 'BUSINESS_REPORT_EDITOR__CLOSE_SAVE_AS_MODAL': {
            return closeSaveAsModal(state, action.payload.name);
        }
        case 'BUSINESS_REPORT_EDITOR__CANCEL': {
            return {
                ...initialState,
                campaigns: state.campaigns,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__INIT_START': {
            return initializePivotTable(state, action);
        }
        case 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__INIT_END': {
            const { stats } = action.payload;

            return {
                ...state,
                pivotTable: pt.generatePivotTableState(state.pivotTable, stats),
                isPivotTableInitialized: true,
                isLoading: false,
                isPivotTableLoading: false,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__EXPAND_ROW': {
            return {
                ...state,
                pivotTable: pt.expandRow(state.pivotTable, action.payload.rowId),
            };
        }
        case 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__COLLAPSE_ROW': {
            return {
                ...state,
                pivotTable: pt.collapseRow(state.pivotTable, action.payload.rowId),
            };
        }
        case 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__SORT': {
            return {
                ...state,
                pivotTable: pt.sortColumn(state.pivotTable, action.payload.column),
            };
        }
        case 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__HIDE_ZEROS_TOGGLE': {
            return {
                ...state,
                hideZeros: !state.hideZeros,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__CHANGE_REPORT_MODE': {
            const reportMode = action.payload.mode;

            const automaticDate = { start: null, end: null, type: 'automatic' };

            const isReportModeByDateOrAdEnd = reportMode === 'date' || reportMode === 'adEndDate';

            const dateRange = isReportModeByDateOrAdEnd
                ? initialState.draft.dateRange
                : automaticDate;

            const nextState = {
                ...state,
                draft: {
                    ...initialState.draft,
                    reportMode,
                    timezone: state.ownOrg ? state.ownOrg.default_timezone : 'UTC',
                    name: state.draft.name,
                    campaigns: {
                        ids: [],
                        mode: isReportModeByDateOrAdEnd ? 'exclude' : 'include',
                    },
                    dateRange,
                },
            };

            return validateDraft(nextState);
        }
        case 'BUSINESS_REPORT_EDITOR__EXPORT_REPORT': {
            return validateDraftForExportingPerformanceReport({
                ...state,
                showErrors: true,
            });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_DATE': {
            return updateSchedule(state, { date: action.payload.date });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_TIME': {
            return updateSchedule(state, { time: action.payload.time });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_TIMEZONE': {
            return updateSchedule(state, { timezone: action.payload.timezone });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_FREQUENCY': {
            return updateSchedule(state, {
                ...initialState.draft.schedule,
                frequency: action.payload.frequency,
                timezone: state.scheduleDraft.timezone,
            });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_DAY_OF_WEEK': {
            const { dayOfWeek } = action.payload;

            return updateSchedule(state, {
                day_of_week: {
                    ...state.scheduleDraft.day_of_week,
                    [dayOfWeek]: !state.scheduleDraft.day_of_week[dayOfWeek],
                },
            });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_DAY_OF_MONTH': {
            const { dayOfMonth } = action.payload;

            return updateSchedule(state, {
                dayOfMonth,
            });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__TOGGLE_RECIPIENT': {
            const { email } = action.payload;

            let recipients = _.get(state, 'scheduleDraft.recipients', []).slice();
            if (_.includes(recipients, email)) {
                recipients = _.filter(recipients, e => e !== email);
            } else {
                recipients.push(email);
            }

            return updateSchedule(state, {
                recipients,
            });
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__SAVE': {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    schedule: {
                        ...state.scheduleDraft,
                    },
                },
                showScheduleDraftError: state.scheduleDraftError ? true : false,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__CANCEL': {
            return {
                ...state,
                isScheduleReportOpen: false,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__SCHEDULE__OPEN': {
            return {
                ...state,
                isScheduleReportOpen: true,
                scheduleDraft: {
                    ...state.draft.schedule,
                    timezone: state.draft.timezone,
                },
            };
        }
        case 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_TYPE': {
            const { type } = action.payload;

            let change;

            if (type === 'dynamic') {
                change = {
                    last: 30,
                    lastFrame: 'days',
                    from: 'day_before_report_generation_date',
                    end: null,
                    start: null,
                    type,
                };
            } else {
                change = initialState.draft.dateRange;
            }

            return updateDateRange(state, change);
        }
        case 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_LAST': {
            return updateDateRange(state, {
                last: action.payload.last,
            });
        }
        case 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_LAST_FRAME': {
            return updateDateRange(state, {
                lastFrame: action.payload.lastFrame,
            });
        }
        case 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_FROM': {
            return updateDateRange(state, {
                from: action.payload.from,
            });
        }
        case 'BUSINESS_REPORT_EDITOR__OPEN_EDITOR': {
            return {
                ...state,
                isEditorOpen: true,
            };
        }
        case 'BUSINESS_REPORT_EDITOR__CLOSE_EDITOR': {
            return {
                ...state,
                isEditorOpen: false,
            };
        }
        default:
            return state;
    }
}

function updateDateRange(state, changes) {
    return {
        ...state,
        draft: {
            ...state.draft,
            dateRange: {
                ...state.draft.dateRange,
                ...changes,
            },
        },
    };
}

function updateSchedule(state, change) {
    let scheduleDraftError;

    // Apply change
    const nextState = {
        ...state,
        scheduleDraft: {
            ...state.scheduleDraft,
            ...change,
        },
    };

    // validate
    if (
        nextState.scheduleDraft.frequency !== 'none' &&
        nextState.scheduleDraft.recipients.length === 0
    ) {
        scheduleDraftError = 'Select recipients of the report';
    }

    return {
        ...nextState,
        scheduleDraftError,
    };
}

function initializeDraft(state, action) {
    const {
        businessReport,
        ownOrg,
        clientOrgs,
        reportJob,
        organizationsForFiltering,
    } = action.payload;

    const environmentSettings = getEnvironmentSettings();
    let ownOrgCopy = { ...ownOrg };

    if (environmentSettings.canUseConsoleteamEmails()) {
        ownOrgCopy = {
            ...ownOrgCopy,
            users: _.uniqBy(_.concat(ownOrgCopy.users, CONSOLE_TEAM_USERS), 'id'),
        };
    }
    if (!businessReport) {
        const nextState = {
            ...state,
            isLoading: false,
            reportInitialized: true,
            ownOrg: ownOrgCopy,
            clientOrgs,
            organizationsForFiltering,
            draft: {
                ...initialState.draft,
                timezone: ownOrg.default_timezone,
                ..._.get(reportJob, 'reportParameters', {}),
                organizationFilter: {
                    isEnabled: true,
                    ids: [ownOrg.id],
                    mode: 'include',
                },
                datasource: getEnvironmentSettings().getReportingDatasource(),
                queryTag: 'ef_report_editor',
            },
        };

        return validateDraft(nextState);
    }

    // Omit non-draft fields
    const rest = _.omit(businessReport, [
        'id',
        'organization',
        'downloadUrl',
        'dateRange',
        'schemaVersion',
        'schedule',
        '_created',
        '_etag',
        '_updated',
    ]);

    const { dateRange, adEndDate, schedule, schemaVersion } = businessReport;
    return {
        ...state,
        draft: {
            ...state.draft,
            ...rest,
            schedule,
            adEndDate: {
                ...adEndDate,
                start: convertUTCtoVague(adEndDate.start, rest.timezone),
                end: convertUTCtoVague(adEndDate.end, rest.timezone),
            },
            dateRange: {
                ...dateRange,
                start: convertUTCtoVague(dateRange.start),
                end: convertUTCtoVague(dateRange.end),
            },
        },
        scheduleDraft: {
            ...schedule,
        },
        schemaVersion,
        businessReportId: businessReport.id,
        isLoading: false,
        reportInitialized: true,
        ownOrg: ownOrgCopy,
        clientOrgs,
        organizationsForFiltering,
    };
}

function initializePivotTable(state, action) {
    const { engagementOptions, attributeOptions } = action.payload;

    const splits = _.map(state.draft.dimensions, d => {
        return {
            label: getDimensionLookup()[d].label,
            name: d,
        };
    });

    const ENGAGEMENT_LOOKUP = {};
    _.each(engagementOptions, option => {
        ENGAGEMENT_LOOKUP[option.value] = option;
    });

    const metricColumns = _.map(state.draft.metrics, metricName => {
        return {
            ...METRIC_LOOKUP[metricName],
            status: 'visible',
        };
    });
    const ATTR_LOOKUP = {};
    _.each(attributeOptions, attr => {
        ATTR_LOOKUP[attr.value] = attr;
    });
    const attrColumns = _.map(state.draft.attributes, attr => {
        return {
            label: ATTR_LOOKUP[attr].label,
            name: attr,
        };
    });
    const engagementColumns = _.map(state.draft.engagements, engagement => {
        return {
            label: ENGAGEMENT_LOOKUP[engagement].label,
            name: `event_${engagement}`,
            formatType: 'thousands',
        };
    });
    const headColumns = [
        { label: 'Dimensions', name: 'dimension', defaultValue: '', formatType: '' },
    ];

    const columns = [].concat(headColumns, attrColumns, metricColumns, engagementColumns);

    return {
        ...state,
        columns,
        pivotTable: {
            ...state.pivotTable,
            splits,
        },
        isPivotTableLoading: true,
    };
}

function openSaveModal(state) {
    return {
        ...state,
        isSaveModalOpen: true,
    };
}

function closeSaveModal(state) {
    return {
        ...state,
        isSaveModalOpen: false,
    };
}

function openSaveAsModal(state) {
    return {
        ...state,
        isSaveAsModalOpen: true,
    };
}

function closeSaveAsModal(state) {
    return {
        ...state,
        isSaveAsModalOpen: false,
    };
}

export function updateDraft(state, action) {
    const { changes, engagementOptions } = action.payload;
    let nextState = { ...state };

    // Merge changes into draft
    nextState = {
        ...nextState,
        draft: {
            ...nextState.draft,
            ...changes,
        },
    };

    if (engagementOptions) {
        // Ensure that engagements in the draft exist as Engagement Options
        const engagementOptionValues = _.map(engagementOptions, opt => opt.value);
        const engagements = _.intersection(nextState.draft.engagements, engagementOptionValues);

        nextState = {
            ...nextState,
            draft: {
                ...nextState.draft,
                engagements,
            },
        };
    }

    return validateDraft(nextState);
}

function validateDraft(state) {
    const draftErrors = validate(
        state.draft,
        getValidatorsV2({
            isSavingAs: state.isSaveAsModalOpen,
            currentErrors: state.errors,
        })
    );

    const errors = {};
    _.each(draftErrors, error => {
        errors[error.field] = [error.message];
    });

    return {
        ...state,
        errors,
    };
}

function validateDraftForExportingPerformanceReport(state) {
    const validators = _.omit(getValidators({ isSavingAs: state.isSaveAsModalOpen }), ['name']);

    const draftErrors = validate(state.draft, validators);

    const errors = {};
    _.each(draftErrors, error => {
        errors[error.field] = [error.message];
    });

    return {
        ...state,
        errors,
        exportErrors: errors,
    };
}

export default function reducer(state, action) {
    let nextState = reducerOld(state, action);
    nextState = newReducer(nextState, action);
    return nextState;
}

function convertUTCtoVague(date, timezone) {
    if (!date) {
        return;
    }

    if (timezone) {
        return moment
            .utc(date)
            .tz(timezone)
            .format('YYYY-MM-DDTHH:mm:ss');
    } else {
        return moment.utc(date).format('YYYY-MM-DDTHH:mm:ss');
    }
}

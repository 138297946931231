import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';

import toastr from 'toastr';
import API from 'services/api';
import selector from './selector';

import FormField from 'widgets-v5/form-field';
import PasswordActions from 'states/resources/password/actions';

import CenterLayout from 'widgets-v5/center-layout';
import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';
import Card from 'widgets-v5/card';

class ForgotPassword extends React.Component {
    state = { emailError: null };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ emailError: null });
        const email = this.refs.email.value && this.refs.email.value.trim();

        if (!email) {
            this.setState({ emailError: 'A valid e-mail address is required.' });
            return;
        }
        this.props.dispatch(PasswordActions.forgot(email)).then(
            response => {
                browserHistory.push(`/`);
                toastr.success(`An email containing the reset link has been sent to ${email}.`);
            },
            response => {
                if (response.code === 404) {
                    this.setState({
                        emailError:
                            'E-mail address not found. If you already have an account, please contact us at help@engagefront.com',
                    });
                    return;
                } else {
                    toastr.error('An error has occured, please try again.');
                }
            }
        );
    };

    render() {
        const emailError = this.state.emailError;
        return (
            <CenterLayout className="ef5-forgot-password">
                <div>
                    <img className="ef5-forgot-password__logo" src="/images/EngageFront-logo.png" />
                    <Card
                        className="ef5-forgot-password__card"
                        title="Forgot Your Password?"
                        footer={
                            <ButtonGroup>
                                <NeutralButton text="I Remember My Password!" link="/" />
                                <PrimaryButton text="Send E-mail" onClick={this.handleSubmit} />
                            </ButtonGroup>
                        }
                    >
                        <form ref="login-form" method="POST" onSubmit={this.handleSubmit}>
                            <p className="ef5-forgot-password__instructions">
                                Don't fret. Enter the e-mail address you use to sign in and we'll
                                send you a link containing instructions to reset your password.
                            </p>
                            <FormField
                                label="E-mail"
                                isRequired={true}
                                showErrors={!!emailError}
                                errors={[emailError]}
                            >
                                <input className="ef3-stdInput" ref="email" type="text" />
                            </FormField>
                        </form>
                    </Card>
                </div>
            </CenterLayout>
        );
    }
}

export default connect(selector)(ForgotPassword);

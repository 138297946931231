import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';

import Draft from 'widgets-v5/draft';
import { MultiSelect } from 'widgets-v6/select';
import { RadioButtons } from '../../../target-app-store-categories';

import {
    getIASContextualTargetingOptions,
} from 'common/constants/ias-contextual-targeting';

const useStyles = makeStyles({
    formControl: {
        minWidth: 220,
    },
});

function IasContextualTargetingTarget({ disabled, helperText, onDraftChange, targetDraft }) {
    const classes = useStyles();

    const initialDraft = {
        ias: {
            contextualTargeting: {
                exclude: _.get(targetDraft, 'ias.contextualTargeting.exclude', false),
                value: _.get(targetDraft, 'ias.contextualTargeting.value', []),
            },
        },
    };

    const schema = {};

    return (
        <Draft
            initialDraft={initialDraft}
            schema={schema}
            onDraftChange={onDraftChange}
            onInit={onDraftChange}
        >
            {({ draft, updateDraft }) => (
                <FormControl className={classes.formControl} disabled={disabled}>
                    <RadioButtons
                        exclude={draft.ias.contextualTargeting.exclude}
                        onChange={({ values, exclude }) => {
                            updateDraft({
                                ias: {
                                    contextualTargeting: {
                                        exclude: exclude ? exclude : false,
                                        value: values,
                                    },
                                },
                            });
                        }}
                        values={draft.ias.contextualTargeting.value}
                    />
                    <MultiSelect
                        value={draft.ias.contextualTargeting.value}
                        onChange={value => {
                            updateDraft({
                                ias: {
                                    contextualTargeting: {
                                        exclude: draft.ias.contextualTargeting.exclude,
                                        value: value,
                                    },
                                },
                            });
                        }}
                        placeholder="Contextual Targeting"
                        options={getIASContextualTargetingOptions()}
                        disabled={disabled}
                    />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            )}
        </Draft>
    );
}

function selector(storeState, { platform, platforms, isCrossPlatformCampaign }) {
    const disabled = isCrossPlatformCampaign
        ? _.difference(platforms, ['inapp']).length === 0
        : platform === 'inapp';

    let helperText;
    if (disabled) {
        helperText = 'Contextual Targeting only supports Mobile Web and Desktop platforms';
    }

    return {
        disabled,
        helperText,
    };
}

function mapDispatch() {
    return {};
}

export default connect(
    selector,
    mapDispatch
)(IasContextualTargetingTarget);

import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import $ from 'jquery';

class InlineTooltipDEPRECATED extends React.Component {
    static propTypes = {
        children: PropTypes.oneOf([
            PropTypes.element,
            PropTypes.array,
        ]).isRequired,
        tooltip: PropTypes.node,
        tooltipTitle: PropTypes.string,
        position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
        showHelpCursor: PropTypes.bool,
        isEnabled: PropTypes.bool,
        actionable: PropTypes.bool,
    };

    static defaultProps = {
        position: 'top',
        showHelpCursor: false,
        isEnabled: true,
        actionable: true,
        wrapText: false,
    };

    state = {
        marginLeft: 0,
        tooltipHeight: 0,
    };

    onMouseOver = e => {
        if (this.$tooltip) {
            return;
        }

        this.$tooltip = $(this.refs.tooltip);
        this.$children = $(this.refs.children);

        let marginLeft;
        switch (this.props.position) {
            case 'left':
                marginLeft = -(this.$tooltip.outerWidth() + 8);
                break;
            case 'right':
                marginLeft = this.$children.outerWidth() + 8;
                break;

            default:
                marginLeft = -(this.$tooltip.outerWidth() / 2);
        }
        this.setState({
            marginLeft,
            tooltipHeight: this.$tooltip.outerHeight(),
        });
    };

    render() {
        if (!this.props.isEnabled) {
            return this.props.children;
        }

        const title = this.props.tooltipTitle;
        const body = this.props.tooltip;

        const titleLayout = (
            <div className="ef5-inline-tooltip__titleLayout">
                <span className="ef5-inline-tooltip__title">{title}</span>
                <div className="ef5-inline-tooltip__body">{body}</div>
            </div>
        );

        const rootClassName = classnames('ef5-inline-tooltip', {
            'is-helpCursor': this.props.showHelpCursor,
        });

        return (
            <div
                className={classnames(rootClassName, this.props.className)}
                onMouseOver={this.onMouseOver}
            >
                <div
                    ref="tooltip"
                    className={classnames(
                        this.props.styleClass,
                        `ef5-inline-tooltip__tooltip ef5-inline-tooltip__tooltip--is-${
                            this.props.position
                        }-of-content`,
                        {
                            'ef5-inline-tooltip__tooltip--is-actionable': this.props.actionable,
                            'ef5-inline-tooltip__tooltip--unwrapped-text': !this.props.wrapText,
                        }
                    )}
                    style={{
                        top: `${this.props.top}`,
                        marginLeft: `${this.state.marginLeft}`,
                        left: `${this.props.left}`,
                        right: `${this.props.right}`,
                        width: `${this.props.width}`,
                    }}
                >
                    {title ? titleLayout : body}
                </div>
                <div className={this.props.className} ref="children">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const modalRoot = document.getElementById('modal-root');

export class InlineTooltip extends React.Component {
    static propTypes = {
        anchorEl: PropTypes.object,
        children: PropTypes.node,
    };

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    render() {
        if (!this.props.anchorEl) {
            return null;
        }

        const bbox = this.props.anchorEl.getBoundingClientRect();

        // Don't change these styles
        // If you need to adjust tooltip position, do it on the Content component
        const style = {
            position: 'fixed',
            top: bbox.top,
            left: bbox.left,
            zIndex: 9999,
        };

        return ReactDOM.createPortal(
            <div style={style} className={this.props.className}>
                {this.props.children}
            </div>,
            this.el
        );
    }
}

export function InlineTooltipContent(props) {
    return (
        <div
            style={props.style}
            className={classnames('ef6-inline-tooltip__tooltip', props.className)}
        >
            {props.children}
        </div>
    );
}

export function InlineTooltipBackdrop(props) {
    return (
        <div
            style={{
                position: 'fixed',
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                backgroundColor: 'transparent',
                zIndex: 100,
            }}
            {...props}
        />
    );
}

export default InlineTooltipDEPRECATED;

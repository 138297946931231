import c from 'common/constants/flux-events';
import Creatives from 'states/resources/creatives/actions';

export function openCreativeHistory(creativeId, campaignId) {
    return {
        type: c.OVERVIEW_CREATIVES__HISTORY__OPEN,
        payload: {
            creativeId,
            campaignId,
        },
    };
}

export function creativeHistorySuccess() {
    return {
        type: 'OVERVIEW_CREATIVES__HISTORY__SUCCESS',
    };
}

export function closeCreativeHistory(creativeId, campaignId) {
    return {
        type: c.OVERVIEW_CREATIVES__HISTORY__CLOSE,
        payload: {
            creativeId,
            campaignId,
        },
    };
}

export function fetchCreativeHistory(creativeId, campaignId) {
    return async (dispatch) => {
        await Promise.all([
            dispatch(Creatives.getHistory(campaignId, creativeId)),
        ]);

        dispatch(creativeHistorySuccess());
    };
}

import React from 'react';

import flagService from './service';

/*
 *  Usage:
 *
 *  import flagsService from 'containers/flags/service';
 *
 *  flagsService.isEnabled('some_feature_key');
 *
 *  ===== OR ======
 *
 *  import { withFlags } from 'containers/flags';
 *
 *  function SomeComponent (props) {
 *      if (props.flags.isEnabled('feature_a')) {
 *          return ...
 *      } else {
 *          return ...
 *      }
 *  }
 *
 *  withFlags(SomeComponent);
 *
 */

export function withFlags(Component) {
    return class FlagWrappedComponent extends React.Component {
        constructor(props) {
            super(props);

            flagService.onChange(this.handleChange);

            this.state = {
                flags: flagService,
            };
        }

        handleChange = fs => {
            this.setState({
                flags: fs,
            });
        };

        componentWillUnmount() {
            this.state.flags.offChange(this.handleChange);
        }

        render() {
            return <Component {...this.props} flags={this.state.flags} />;
        }
    };
}

import React from 'react';
import { connect } from 'react-redux';
import selector from './selector';
import { browserHistory } from 'react-router';
import { withRouter } from 'react-router';
import CreativeForm from 'forms/creative-form';
import { fileUploadSubmit } from 'states/resources/campaigns/actions';
import { CreativeNew as actions } from 'pages/campaigns/creatives/form/creative-new-form/actions';

class NewCreativeFormWrapper extends React.Component {
    // statics: {
    //     refresh (dispatch, nextState) {
    //         const campaignId = _.get(nextState, 'params.campaignId', void 0);
    //         const creativeId = _.get(nextState, 'params.creativeId', void 0);
    //         dispatch( actions.open(campaignId, creativeId) );
    //     }
    // },

    // init () {
    //     const { dispatch, location, params, routes } = this.props;
    //     const nextState = { location, params, routes };

    //     NewCreativeFormWrapper.refresh(dispatch, nextState, browserHistory.push);
    // },

    static defaultProps = {
        getReturnUrl(params) {
            return `/campaigns/${params.campaignId}/setup/creatives`;
        },
    };

    // componentDidMount () {
    //     this.init();
    // },

    // componentDidUpdate (prevProps) {
    //     if (this.props.params.campaignId !== prevProps.params.campaignId) {
    //         this.init();
    //     }
    // },

    submit = draft => {
        const { dispatch, campaignId, params, isSubmiting } = this.props;

        if (isSubmiting) {
            return;
        }

        const pathname = window.location.href;
        const isNewAdForm = /^.*\?tactics\=1$/.test(pathname);
        const returnURL = this.props.getReturnUrl(params);

        return dispatch(actions.submitDraft(draft, campaignId)).then(() => {
            browserHistory.push(isNewAdForm ? `${returnURL}?tactics=1` : returnURL);
        });
    };

    submitAndAudit = async draft => {
        const { dispatch, campaignId, params, isSubmiting } = this.props;

        if (isSubmiting) {
            return;
        }

        const pathname = window.location.href;
        const isNewAdForm = /^.*\?tactics\=1$/.test(pathname);
        const returnURL = this.props.getReturnUrl(params);

        return dispatch(actions.submitDraftAndAudit(draft, campaignId)).then(() => {
            browserHistory.push(isNewAdForm ? `${returnURL}?tactics=1` : returnURL);
        });
    };

    handleClose = () => {
        const { campaignId, dispatch, params, isSubmiting } = this.props;

        if (isSubmiting) {
            return;
        }
        const pathname = window.location.href;
        const isNewAdForm = /^.*\?tactics\=1$/.test(pathname);
        const returnURL = this.props.getReturnUrl(params);

        dispatch(actions.close(campaignId));
        browserHistory.push(isNewAdForm ? `${returnURL}?tactics=1` : returnURL);
    };

    getInitialDraft = () => {
        const { dispatch, campaignId, creativeId, location } = this.props;

        return dispatch(actions.getInitialDraft({ campaignId, creativeId, location }));
    };

    uploadFile = (formData, campaignId) => {
        const { dispatch } = this.props;

        return dispatch(fileUploadSubmit(formData, campaignId));
    };

    render() {
        return (
            <CreativeForm
                closeAction={this.handleClose}
                campaignId={this.props.campaignId}
                warnings={this.props.warnings}
                submit={this.submit}
                submitAndAudit={this.submitAndAudit}
                getInitialDraft={this.getInitialDraft}
                uploadFile={this.uploadFile}
            />
        );
    }
}

const CreativeFormConnected = withRouter(connect(selector)(NewCreativeFormWrapper));

export function provideCreativeNew(props) {
    class ProvideCreativeNew extends React.Component {
        render() {
            return <CreativeFormConnected {...this.props} {...props} />;
        }
    }

    return ProvideCreativeNew;
}

export default CreativeFormConnected;

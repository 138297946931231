// Use middleware to perform side-effects like data-fetching, accessing localStorage, etc
import { actions } from './reducer';

const initMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.changeMessage.type) {
        // do stuff when changeMessage is dispatched
    }
};

export const makeMiddlewares = deps => {
    return [initMw(deps)];
};

export const middlewares = makeMiddlewares({});

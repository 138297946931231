import _ from 'lodash';

import findCacheState from 'utils/find-cache-state';
import c from 'common/constants/flux-events';

import { createBeacon, validateDraft } from './services';

const initialState = {
    mode: 'view',
    draft: [],
    errors: [],
    showErrors: false,
    isLoading: true,
    previewCounts: {},
    totalCounts: {},
};

export const NAME = 'beaconsOverview';

export default function(state = {}, action) {
    switch (action.type) {
        case c.OVERVIEW_BEACONS__INIT: {
            return findCacheState(action.payload.campaignId, state, initialState);
        }

        case c.OVERVIEW_BEACONS__INIT_DRAFT:
            const { statRoot, campaign } = action.payload;

            const previewCounts = _.pickBy(statRoot, (v, k) => /preview_event_/.test(k));
            const totalCounts = _.pickBy(statRoot, (v, k) => /event_/.test(k));

            return {
                ...state,
                campaign,
                mode: 'view',
                isLoading: false,
                previewCounts,
                totalCounts,
                draft: action.payload.beacons.map(beacon => {
                    return {
                        ...beacon,
                        id: beacon.name,
                        previewCount: _.get(previewCounts, `preview_event_${beacon.name}`, 0),
                        totalCount: _.get(totalCounts, `event_${beacon.name}`, 0),
                    };
                }),
            };

        case c.OVERVIEW_BEACONS__OPEN_VIEW_NEW:
            return {
                ...state,
                mode: 'edit',
                draft: [createBeacon()],
            };

        case c.OVERVIEW_BEACONS__OPEN_VIEW_MANAGE:
            const { existingBeacons } = action.payload;

            const draft = existingBeacons.map(beacon => ({
                ...beacon,
                type: 'existing',
            }));

            return {
                ...state,
                mode: 'edit',
                draft,
            };

        case c.OVERVIEW_BEACONS__ADD:
            return {
                ...state,
                draft: state.draft.concat(createBeacon()),
            };

        case c.OVERVIEW_BEACONS__EDIT: {
            // const newBeacons = action.payload.beacons;
            const { beaconId, fieldName, value } = action.payload;
            const { previewCounts, totalCounts } = state;

            const newBeacons = mergeBeaconChange(
                state.draft,
                beaconId,
                fieldName,
                value,
                previewCounts,
                totalCounts
            );

            // First, pull out all beaconNames
            const errors = generateValidationErrors(newBeacons);

            return {
                ...state,
                draft: newBeacons,
                errors,
            };
        }

        case c.OVERVIEW_BEACONS__DELETE:
            const { beaconId } = action.payload;

            return {
                ...state,
                draft: state.draft.filter(beacon => beacon.id !== beaconId),
            };

        case c.OVERVIEW_BEACONS__CANCEL:
            return {
                ...initialState,
            };

        case c.OVERVIEW_BEACONS__SAVE_TRY: {
            const { draft } = state;
            const errors = generateValidationErrors(draft);

            return {
                ...state,
                draft,
                isLoading: false,
                errors,
                showErrors: true,
            };
        }

        case c.OVERVIEW_BEACONS__SAVE: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case c.OVERVIEW_BEACONS__SAVE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showErrors: false,
                mode: 'view',
            };

        case c.OVERVIEW_BEACONS__PROMOTE_BEACON: {
            const { priority } = action.payload;

            if (priority === 0) {
                return state;
            }

            const nextPriority = priority - 1;
            const nextBeacons = state.draft.slice();
            nextBeacons[nextPriority] = state.draft[priority];
            nextBeacons[priority] = state.draft[nextPriority];

            return {
                ...state,
                draft: nextBeacons,
            };
        }

        case c.OVERVIEW_BEACONS__DEMOTE_BEACON: {
            const { priority } = action.payload;

            if (priority === state.draft.length - 1) {
                return state;
            }

            const nextPriority = priority + 1;
            const nextBeacons = state.draft.slice();
            nextBeacons[nextPriority] = state.draft[priority];
            nextBeacons[priority] = state.draft[nextPriority];

            return {
                ...state,
                draft: nextBeacons,
            };
        }

        default:
            return state;
    }
}

function generateValidationErrors(beacons) {
    const beaconNames = beacons.map(beacon => beacon.name);

    return _(beacons)
        .map((beacon, index) => {
            // A simple _.filter would remove all duplicates too
            const namesWithoutThisBeacon = _.clone(beaconNames);
            namesWithoutThisBeacon.splice(index, 1);

            return validateDraft(beacon, namesWithoutThisBeacon);
        })
        .value();
}

function mergeBeaconChange(beacons, beaconId, fieldName, value, previewCounts, totalCounts) {
    return _.map(beacons, beacon => {
        if (beacon.id === beaconId) {
            return {
                ...beacon,
                [fieldName]: value,
                previewCount: _.get(previewCounts, `preview_event_${beacon.name}`, 0),
                totalCount: _.get(totalCounts, `event_${beacon.name}`, 0),
            };
        } else {
            return beacon;
        }
    });
}

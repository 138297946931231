import { actions } from './reducer';
import notify from 'utils/notify';
import { graphqlRequest } from 'utils/http/redux';
import VError from 'verror';
import get from 'lodash/get';

const initDashboardMw = () => ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === actions.init.type) {
        try {
            const keywords = get(action, 'payload.searchString', '');
            const { data, totalSegmentCount } = await fetchAudiences(dispatch, keywords);
            dispatch(actions.initSuccess({ data, totalSegmentCount }));
        } catch (err) {
            notify({
                error: new VError(err, 'Failed to fetch audience data for Permutive Dashboard'),
            });
        }
    }
};

export async function fetchAudiences(dispatch, keywords) {
    const query = `
            query getAudiences ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                id
                name
                lastRefreshed
                dataCost
                uniques
                _created
                supports
            }
        }
        `;

    const variables = {
        keywords,
        source_type: ['permutive'],
        deprecated: false,
    };
    const {
        data: { audienceSegments },
    } = await dispatch(
        graphqlRequest({
            query,
            variables,
        })
    );

    return {
        data: audienceSegments,
        totalSegmentCount: audienceSegments.length,
    };
}

export const makeMiddlewares = deps => {
    return [initDashboardMw(deps)];
};

export const middlewares = makeMiddlewares();

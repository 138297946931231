const inititalState = {
    errors: [],
    isValid: false,
};

export const NAME = 'test';

export default function reducer(state = inititalState, action) {
    switch (action.type) {
        case 'VALIDATE_TEST_FORM':
            var errors = [];

            if (action.payload.email !== 'brian') {
                errors.push({ message: 'Invalid email' });
            }

            if (action.payload.password !== 'password0') {
                errors.push({ message: 'Invalid password' });
            }

            return {
                ...state,
                errors,
            };

        default:
            return state;
    }
}

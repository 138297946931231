import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
} from 'utils/formatting';

const useStyles = makeStyles(theme => ({
    cardWrapper: {
        minHeight: 160,
    },
    metricBox: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        margin: '9px 0px',
        padding: '0px 10px',
    },
}));

export const CampaignMetrics = props => {
    const { campaignStats, campaignStatsIsLoading } = props;
    const classes = useStyles();
    return (
        <Card className={classes.cardWrapper}>
            <Grid container>
                <Grid item xs={3} sm={5} md={3} lg={3} xl={2} className={classes.metricBox}>
                    <Typography variant="subtitle2" gutterBottom>
                        Impressions
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {campaignStatsIsLoading || !campaignStats.impressions
                            ? '-'
                            : formatNumber_whole(campaignStats.impressions)}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={5} md={3} lg={3} xl={2} className={classes.metricBox}>
                    <Typography variant="subtitle2" gutterBottom>
                        Clicks
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {campaignStatsIsLoading || !campaignStats.clicks
                            ? '-'
                            : formatNumber_whole(campaignStats.clicks)}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={5} md={3} lg={3} xl={2} className={classes.metricBox}>
                    <Typography variant="subtitle2" gutterBottom>
                        CTR
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {campaignStatsIsLoading || !campaignStats.ctr
                            ? '-'
                            : formatNumber_percentage(campaignStats.ctr)}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={5} md={3} lg={3} xl={2} className={classes.metricBox}>
                    <Typography variant="subtitle2" gutterBottom>
                        Win Rate
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {campaignStatsIsLoading
                            ? '-'
                            : formatNumber_percentage(campaignStats.win_rate)}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={5} md={3} lg={3} xl={2} className={classes.metricBox}>
                    <Typography variant="subtitle2" gutterBottom>
                        Total Cost
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {campaignStatsIsLoading || !campaignStats.owner_total_media_cost_local
                            ? '-'
                            : formatNumber_currency(campaignStats.owner_total_media_cost_local)}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={5} md={3} lg={3} xl={2} className={classes.metricBox}>
                    <Typography variant="subtitle2" gutterBottom>
                        Total Cost eCPM
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {campaignStatsIsLoading || !campaignStats.owner_total_media_cost_local_ecpm
                            ? '-'
                            : formatNumber_currency(
                                  campaignStats.owner_total_media_cost_local_ecpm
                              )}
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={5} md={3} lg={3} xl={2} className={classes.metricBox}>
                    <Typography variant="subtitle2" gutterBottom>
                        Revenue
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        {campaignStatsIsLoading || !campaignStats.billings_local
                            ? '-'
                            : formatNumber_currency(campaignStats.billings_local)}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';

class FormCheckbox extends React.Component {
    static propTypes = {
        handleChange: PropTypes.func,
        maxSelected: PropTypes.number,
        minSelected: PropTypes.number,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                checked: PropTypes.bool,
            })
        ).isRequired,
    };

    handleSelect = (e, index) => {
        const checked = e.target.checked;
        const { items } = this.props;

        const currentItems = [...items];
        currentItems[index] = { ...items[index], checked };
        const currentlySelected = _.filter(currentItems, item => item.checked);
        const selectedValues = _.map(currentlySelected, item => item.value);

        this.props.handleChange(index, checked, currentlySelected, selectedValues);
    };

    render() {
        const selectedCount = this.props.items.reduce(
            (sum, item) => (item.checked ? 1 : 0) + sum,
            0
        );

        const disabled = this.props.maxSelected
            ? selectedCount >= this.props.maxSelected
            : selectedCount <= this.props.minSelected;

        return (
            <div className="ef3-formMultiCheckbox">
                {this.props.items.map((item, index) => (
                    <FormGroup key={item.label + index}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={'primary'}
                                    checked={item.checked}
                                    onChange={e => this.handleSelect(e, index, item)}
                                    disabled={
                                        this.props.maxSelected
                                            ? !item.checked && disabled
                                            : item.checked && disabled
                                    }
                                />
                            }
                            label={item.label}
                        />
                    </FormGroup>
                ))}
            </div>
        );
    }
}

export default FormCheckbox;

import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, withStyles } from '@mui/styles';

import { BlockLoadGroup } from 'widgets-v5/load-group';
import { TextField } from 'widgets-v6/standard-input';

import selector from './selector';
import actions from './actions';

const useStyles = makeStyles(theme => ({
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    table: {
        marginBottom: theme.spacing(1),
    },
}));

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const Loading = withStyles(() => ({
    root: {
        width: '24px',
        height: '24px',
    },
}))(Grid);

const LoadingSpinner = withStyles(() => ({
    root: {
        width: '24px !important',
        height: '24px !important',
    },
}))(CircularProgress);

const permissionTableRows = [
    {
        label: 'See campaign* reports',
        viewableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
        authorizedUserTypes: [
            'analyst',
            'media_planner',
            'campaign_manager',
            'account_admin',
            'admin',
            'read_only_super_admin',
        ],
    },
    {
        label: 'See campaign* settings',
        viewableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
        authorizedUserTypes: [
            'media_planner',
            'campaign_manager',
            'account_admin',
            'admin',
            'read_only_super_admin',
        ],
    },
    {
        label: 'See and create cross-campaign reports',
        viewableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
        authorizedUserTypes: [
            'media_planner',
            'campaign_manager',
            'account_admin',
            'admin',
            'read_only_super_admin',
        ],
    },
    {
        label: 'See and create inventory forecasts',
        viewableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
        authorizedUserTypes: [
            'media_planner',
            'campaign_manager',
            'account_admin',
            'admin',
            'read_only_super_admin',
        ],
    },
    {
        label: 'Create and manage campaign resources (audiences, pixels, app & site lists, etc)',
        viewableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
        authorizedUserTypes: [
            'media_planner',
            'campaign_manager',
            'account_admin',
            'admin',
            'read_only_super_admin',
        ],
    },
    {
        label: 'Create and manage campaigns',
        viewableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
        authorizedUserTypes: ['campaign_manager', 'account_admin', 'admin'],
    },
    {
        label: 'Create and manage users',
        viewableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
        authorizedUserTypes: ['account_admin', 'user_admin', 'admin'],
    },
    {
        label: 'Create and manage self-serve-users',
        viewableByOrgTypes: ['admin'],
        authorizedUserTypes: ['admin'],
    },
];

const userTypes = [
    {
        label: 'Analyst',
        value: 'analyst',
        createableByOrgTypes: ['client', 'co_managed_client', 'media_buyer', 'admin'],
    },
    {
        label: 'Media Planner',
        value: 'media_planner',
        createableByOrgTypes: ['co_managed_client', 'media_buyer', 'admin'],
    },
    {
        label: 'Campaign Manager',
        value: 'campaign_manager',
        createableByOrgTypes: ['media_buyer', 'admin'],
    },
    {
        label: 'Account Admin',
        value: 'account_admin',
        createableByOrgTypes: ['co_managed_client', 'media_buyer', 'admin'],
    },
    {
        label: 'User Admin',
        value: 'user_admin',
        createableByOrgTypes: ['co_managed_client', 'media_buyer', 'admin'],
    },
    {
        label: 'Read-only Super Admin',
        value: 'read_only_super_admin',
        createableByOrgTypes: ['admin'],
    },
    {
        label: 'Super Admin',
        value: 'admin',
        createableByOrgTypes: ['admin'],
    },
];
const AdminUserForm = ({
    dispatch,
    isFormOpen,
    draft,
    isLoading,
    errors,
    showErrors,
    refreshUsers,
    isSubmitting,
    organizationType,
    mode,
}) => {
    const classes = useStyles();
    const handleClose = () => dispatch(actions.closeForm());
    const handleSubmit = async () => {
        await dispatch(actions.submitDraft(refreshUsers));
    };

    const filteredUserTypes =
        organizationType === 'admin'
            ? userTypes
            : _.filter(
                  userTypes,
                  userType =>
                      userType.value !== 'admin' && userType.value !== 'read_only_super_admin'
              );
    const filteredPermissionTableRows = _.filter(permissionTableRows, row =>
        _.includes(row.viewableByOrgTypes, organizationType)
    );

    return (
        <Dialog fullWidth maxWidth="lg" open={isFormOpen} onClose={handleClose}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <Typography variant="h6">
                            {mode === 'create' ? 'New User' : 'Edit User'}
                        </Typography>
                    </div>
                    <div>
                        <IconButton onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <BlockLoadGroup isLoading={isLoading}>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <div>
                                <TextField
                                    label="First Name"
                                    onChange={e =>
                                        dispatch(actions.updateDraft('first_name', e.target.value))
                                    }
                                    value={draft.first_name}
                                    error={showErrors && errors.first_name}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Last Name"
                                    onChange={e =>
                                        dispatch(actions.updateDraft('last_name', e.target.value))
                                    }
                                    value={draft.last_name}
                                    error={showErrors && errors.last_name}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Email"
                                    onChange={e =>
                                        dispatch(actions.updateDraft('email', e.target.value))
                                    }
                                    value={draft.email}
                                    error={showErrors && errors.email}
                                    fullWidth
                                    required
                                />
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <div>
                                <Typography variant="body1">Permissions and Role</Typography>
                            </div>
                            <div>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            {_.map(filteredUserTypes, type => (
                                                <TableCell align="center" key={type.value}>
                                                    <FormControlLabel
                                                        value={type.value}
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                disabled={
                                                                    !_.includes(
                                                                        type.createableByOrgTypes,
                                                                        organizationType
                                                                    )
                                                                }
                                                                onChange={e =>
                                                                    dispatch(
                                                                        actions.updateDraft(
                                                                            'global_role',
                                                                            e.target.value
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={type.label}
                                                        labelPlacement="top"
                                                        checked={draft.global_role === type.value}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(filteredPermissionTableRows, row => (
                                            <StyledTableRow key={row.label}>
                                                <TableCell>{row.label}</TableCell>
                                                {_.map(filteredUserTypes, user => (
                                                    <TableCell key={user.value} align="center">
                                                        {_.includes(
                                                            row.authorizedUserTypes,
                                                            user.value
                                                        ) && <CheckIcon color="primary" />}
                                                    </TableCell>
                                                ))}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Typography>*campaigns shared with their organization</Typography>
                            </div>
                            {showErrors && errors.global_role && (
                                <Typography color="error">{errors.global_role}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </BlockLoadGroup>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {isSubmitting ? (
                        <Loading>
                            <LoadingSpinner fontSize="small" color="inherit" />
                        </Loading>
                    ) : (
                        'Save'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(selector)(AdminUserForm);

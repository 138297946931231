import _ from 'lodash';

import CampaignActions from 'states/resources/report-campaigns/actions';

const actions = {
    getAll() {
        return (dispatch, getState) => {
            return Promise.resolve().then(() => {
                dispatch({
                    type: 'REPORT_CAMPAIGNS_LOADER__FETCH_START',
                    payload: {},
                });
                dispatch(CampaignActions.getAll()).then(
                    () => {
                        dispatch({
                            type: 'REPORT_CAMPAIGNS_LOADER__FETCH_END',
                            payload: {},
                        });
                    },
                    err => {
                        dispatch({
                            type: 'REPORT_CAMPAIGNS_LOADER__FETCH_ERROR',
                            payload: {},
                            error: err,
                        });
                    }
                );
            });
        };
    },
};
export default actions;

import _ from 'lodash';
import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
import Bacon from 'baconjs';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import { fetchReportStats } from 'services/resources/stats';
import { getEnvironmentSettings } from 'services/environment';

const http = createHttp();

/* Metric Selector */
export function metricSelector_toggleVisiblity(campaignId, metric) {
    return {
        type: c.CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_VISIBILITY,
        payload: { campaignId, metric },
    };
}
export function metricSelector_toggleSelection(campaignId, metric) {
    return {
        type: c.CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_SELECTION,
        payload: { campaignId, metric },
    };
}

/* Controls */
export const controls_setTab = (campaignId, tab) => dispatch => {
    dispatch({
        type: c.CAMPAIGN_REPORT__CONTROLS__SET_TAB,
        payload: { campaignId, tab },
    });
};

export const controls_setActiveScopeTab = (campaignId, scopeId) => ({
    type: c.CAMPAIGN_REPORT__CONTROLS__SET_ACTIVE_SCOPE_TAB,
    payload: { campaignId, scopeId },
});

export const controls_setUnitType = (campaignId, unitType) => ({
    type: c.CAMPAIGN_REPORT__CONTROLS__SET_UNIT_TYPE,
    payload: { campaignId, unitType },
});

export const controls_setMetricType = (campaignId, metricType) => ({
    type: c.CAMPAIGN_REPORT__CONTROLS__SET_METRIC_TYPE,
    payload: { campaignId, metricType },
});

export const controls_setMetricVisibility = (campaignId, metricType) => ({
    type: c.CAMPAIGN_REPORT__CONTROLS__SET_METRIC_VISIBILITY,
    payload: { campaignId, metricType },
});

export const fetchReportData = campaignId => (dispatch, getState) => {
    fetchComponentData(campaignId, dispatch, getState);
};

/* Pivot Table */

const ptStream = new Bacon.Bus();

ptStream
    .flatMapLatest(({ campaignId, getState, done }) => {
        if (!campaignId) {
            console.warn('Campaign ID not found');
            return;
        }

        const splits = _.get(getState(), `reportExplore.pivotTable.pivotTable.splits`, []);
        const split = _.map(splits, s => s.name);
        const dimensionMatch = _.get(getState(), `reportExplore.dimensionFilter`, {});
        const scopeFilter = _.get(getState(), `reportExplore.scopeFilter`, {});
        const dateRange = _.get(getState(), `reportExplore.dateRange`, {});
        const timezone = _.get(
            getState(),
            `resources.campaigns.${campaignId}.attr.default_timezone`,
            'UTC'
        );

        const fetchData = () => {
            const promise = fetchReportStats({
                campaign_id: campaignId,
                dateRange: { ..._.omit(dateRange, ['campaignStart', 'campaignEnd']) },
                timezone,
                match: {
                    ..._.omit(dimensionMatch, ['campaign_id']),
                    ...scopeFilter,
                },
                split,
                datasource: getEnvironmentSettings().getReportingDatasource(),
                isReportPage: true,
                queryTag: 'ef_report_overview',
            }).then(stats => {
                return { stats, done };
            });

            return Bacon.fromPromise(promise, true);
        };

        return Bacon.retry({
            source: fetchData,
            retries: 2,
            delay() {
                return 1000;
            },
        });
    })
    .onValue(({ stats, done }) => {
        done(stats);
    });

export function pivot_sortColumn(campaignId, column) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__SORT,
        payload: {
            column,
            campaignId,
        },
    };
}
export function pivot_expandRow(campaignId, rowId) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__EXPAND_ROW,
        payload: {
            rowId,
            campaignId,
        },
    };
}
export function pivot_collapseRow(campaignId, rowId) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__COLLAPSE_ROW,
        payload: {
            rowId,
            campaignId,
        },
    };
}
export function pivot_addSplit(campaignId, dimension) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_REPORT__PIVOT_TABLE__ADD_SPLIT,
            payload: {
                dimension,
                campaignId,
            },
        });

        return dispatch(refreshPivotTableStats(campaignId));
    };
}
export function pivot_removeSplit(campaignId, dimension) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_REPORT__PIVOT_TABLE__REMOVE_SPLIT,
            payload: {
                dimension,
                campaignId,
            },
        });

        return dispatch(refreshPivotTableStats(campaignId));
    };
}
export function pivot_hideZerosToggle(campaignId) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__HIDE_ZEROS_TOGGLE,
        payload: {
            campaignId,
        },
    };
}

export function fetchPivotTableStats(campaignId, done) {
    return (dispatch, getState) => {
        ptStream.push({ campaignId, getState, done });
    };
}
export function refreshPivotTableStats(campaignId) {
    return (dispatch, getState) => {
        const isInitializing = _.get(getState(), `reportExplore.pivotTable.isInitializing`);

        if (isInitializing) {
            return;
        }

        const done = stats => {
            dispatch({
                type: c.CAMPAIGN_REPORT__PIVOT_TABLE__DATA_REFRESH,
                payload: {
                    stats,
                    campaignId,
                },
            });
        };
        dispatch(fetchPivotTableStats(campaignId, done));
    };
}

/* Ad Group */
export const adgroup_toggleGroup = (campaignId, component, grouping, group) => (
    dispatch,
    getState
) => {
    [].concat(group).forEach(group => {
        dispatch({
            type: c.CAMPAIGN_REPORT__ADGROUP__TOGGLE,
            payload: {
                campaignId,
                component,
                grouping,
                group,
            },
        });
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const adgroup_reset = (campaignId, component) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__ADGROUP__RESET,
        payload: {
            campaignId,
            component,
        },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

/* Filters */
export const dimensionFilter_groupingClear = (campaignId, grouping) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR,
        payload: { campaignId, grouping },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const dimensionFilter_groupingClearAll = campaignId => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR_ALL,
        payload: { campaignId },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const scopeFilter_groupingClear = (campaignId, grouping) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__SCOPE_FILTER__CLEAR,
        payload: { campaignId, grouping },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const scopeFilter_groupingClearAll = campaignId => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__SCOPE_FILTER__CLEAR_ALL,
        payload: { campaignId },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const filterByDateRange = (campaignId, dateRange) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_FILTER,
        payload: { campaignId, dateRange },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export function updateTimezone(campaignId, timezone) {
    return (dispatch, getState) => {
        dispatch({
            type: c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_TIMEZONE,
            payload: { campaignId, timezone },
        });

        fetchReportData(campaignId)(dispatch, getState);
    };
}

/* General */
export function inititalizeReport(campaignId) {
    return (dispatch, getState) => {
        fetchCampaign(campaignId)(dispatch, getState)
            .then(campaign => {
                const userOrganization = _.get(getState(), 'profile.organizationId');
                const campaignOwner = campaign.owner;

                const isClient = userOrganization !== campaignOwner;

                dispatch({
                    type: c.CAMPAIGN_REPORT__INIT_STATE,
                    payload: {
                        campaignId,
                    },
                });

                dispatch({
                    type: c.CAMPAIGN_REPORT__INITIALIZE,
                    payload: {
                        context: campaignId,
                        campaignId,
                        isClient,
                        beacons: campaign.beacons,
                        campaign,
                    },
                });

                dispatch({
                    type: c.CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE_START,
                    payload: {
                        campaignId,
                        campaign,
                        isClient,
                    },
                });

                const done = stats => {
                    dispatch({
                        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE,
                        payload: {
                            stats,
                            campaignId,
                            campaign,
                            isClient,
                        },
                    });
                };

                dispatch(fetchPivotTableStats(campaignId, done));

                return http.get(`campaigns/${campaignId}/report-scopes`);
            })
            .then(response => {
                dispatch({
                    type: c.CAMPAIGN_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS,
                    payload: {
                        campaignId,
                        response,
                    },
                });
            })
            .then(() => {
                fetchComponentData(campaignId, dispatch, getState);
            })
            .then(() => {
                return http.get(`campaigns/${campaignId}/creatives/creativeForStats`);
            })
            .then(response => {
                dispatch({
                    type: c.CAMPAIGN_REPORT__AVAILABLE_CREATIVES__FETCH_SUCCESS,
                    payload: {
                        campaignId,
                        response,
                    },
                });
            });
    };
}

export function refreshReport(campaignId) {
    return (dispatch, getState) => {
        fetchCampaign(campaignId)(dispatch, getState);

        http.get(`campaigns/${campaignId}/report-scopes`)
            .then(response => {
                dispatch({
                    type: c.CAMPAIGN_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS,
                    payload: {
                        campaignId,
                        response,
                    },
                });
            })
            .then(() => {
                fetchComponentData(campaignId, dispatch, getState);
            })
            .then(() => {
                return http.get(`campaigns/${campaignId}/creatives/creativeForStats`);
            })
            .then(response => {
                dispatch({
                    type: c.CAMPAIGN_REPORT__AVAILABLE_CREATIVES__FETCH_SUCCESS,
                    payload: {
                        campaignId,
                        response,
                    },
                });
            });
    };
}

const StreamManager = (function() {
    var streams = {};

    return {
        getStream(componentId, dispatch) {
            if (!streams[componentId]) {
                streams[componentId] = new Bacon.Bus();

                streams[componentId]
                    .debounce(100)
                    .flatMapLatest(({ request, component }) => {
                        dispatch({
                            type: c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH,
                            payload: {
                                campaignId: request.match.campaign_id,
                                component,
                            },
                        });
                        switch (component.type) {
                            case 'pivot':
                            case 'dimension':
                            case 'overview':
                            case 'heatmap':
                            case 'scope': {
                                const fetchData = () => {
                                    const promise = fetchReportStats({
                                        campaign_id: request.match.campaign_id,
                                        start: request.start,
                                        end: request.end,
                                        match: {
                                            ..._.omit(request.match.dimensions, ['campaign_id']),
                                            ...request.match.scopes,
                                        },
                                        split: request.groups.scope
                                            ? [request.groups.scope]
                                            : request.groups.dimensions,
                                            datasource: getEnvironmentSettings().getReportingDatasource(),
                                        queryTag: 'ef_report_overview',
                                        isReportPage: true,
                                    }).then(response => ({
                                        response,
                                        request,
                                        component,
                                    }));

                                    return Bacon.fromPromise(promise, true);
                                };

                                return Bacon.retry({
                                    source: fetchData,
                                    retries: 2,
                                    delay() {
                                        return 1000;
                                    },
                                });
                            }

                            default: {
                                console.warn('Unsupported component type');
                            }
                        }
                    })
                    .onValue(function(result) {
                        dispatch({
                            type: c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH__SUCCESS,
                            payload: {
                                campaignId: result.request.match.campaign_id,
                                response: result.response,
                                component: result.component,
                            },
                        });
                    });
            }

            return streams[componentId];
        },
    };
})();

function fetchComponentData(campaignId, dispatch, getState, filter = () => true) {
    const reportSession = _.get(getState(), `reportExplore`);
    const dateRange = _.get(getState(), `reportExplore.dateRange`);

    const components = _.get(reportSession, 'components', [])
        .filter(filter)
        .filter(c => c.type !== 'pivot');
    const dimensionMatch = _.get(reportSession, 'dimensionFilter', {});
    const scopeMatch = _.get(reportSession, 'scopeFilter', {});

    dispatch(refreshPivotTableStats(campaignId));

    components.forEach(component => {
        const request = createRequest(campaignId, dimensionMatch, scopeMatch, component, dateRange);

        StreamManager.getStream(component.id, dispatch, getState).push({ request, component });
    });
}

function createRequest(campaignId, dimensionMatch, scopeMatch, component, dateRange) {
    const isScope = component.type === 'scope';
    const groupType = isScope ? 'scope' : 'dimensions';
    const groupValue = isScope ? _.first([].concat(component.groupings)) : component.groupings;

    const defaultGroups = {
        dimensions: [],
        scope: null,
    };

    return {
        ...dateRange,
        match: {
            campaign_id: campaignId,
            dimensions: dimensionMatch,
            scopes: scopeMatch,
        },
        groups: {
            ...defaultGroups,
            [groupType]: groupValue,
        },
        type: component.type,
    };
}

import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'permutiveDashboard';
export const initialState = {
    isLoading: false,
    audienceSegments: [],
    totalSegmentCount: 0,
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        init: state => ({
            ...state,
            isLoading: true,
        }),
        initSuccess: (state, payload) => ({
            ...state,
            isLoading: false,
            audienceSegments: payload.data,
            totalSegmentCount: payload.totalSegmentCount,
        }),
    },
});

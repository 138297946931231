import _ from 'lodash';
import React, { useMemo } from 'react';

import FormField from 'widgets-v5/form-field';
import { TextField } from 'widgets-v6/standard-input';

export function Name({ value, errors, showErrors, onFieldChange }) {
    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            onFieldChange(fieldValue);
        }, 500);
    }, []);

    return (
        <TextField
            disableMargin
            error={showErrors ? !!errors : false}
            defaultValue={value}
            onChange={e => handleChange(e.target.value)}
            fullWidth
        />
    );
}

export function Currency(props) {
    return (
        <div className="ef5-ad-setup-form-basic__currency-column">
            <FormField showErrors={false} isRequired={false}>
                <div className="ef5-ad-setup-form-basic__currency-label">
                    {props.currency ? `${props.currency} $` : 'N/A'}
                </div>
            </FormField>
        </div>
    );
}

export function NetsuiteID(props) {
    const handleCustomFieldChange = (customField, value) => {
        const customFields = _.map(props.formData.custom_fields, c => {
            if (c.name !== customField.name) {
                return c;
            }

            return {
                ...customField,
                value,
            };
        });

        props.onFieldChange('custom_fields')(customFields);
    };

    return (
        <div className="ef5-ad-setup-form-basic__form-field-wrapper">
            <TextField
                onChange={e => handleCustomFieldChange(props.customField, e.target.value)}
                value={props.customField.value}
                error={props.showErrors && props.errors}
                placeholder={props.customField.name}
                fullWidth
            />
        </div>
    );
}

export function Notes(props) {
    const { formData, errors, showErrors, onFieldChange } = props;
    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            onFieldChange(fieldValue);
        }, 500);
    }, []);

    return (
        <TextField
            disableMargin
            error={showErrors && errors.notes}
            defaultValue={formData}
            onChange={e => handleChange(e.target.value)}
            fullWidth
            multiline
        />
    );
}

import _ from 'lodash';
import moment from 'moment';
import uuid from 'uuid';

import { can } from 'services/can';
import { CREATIVE_TABLE_VERSION_2 } from 'forms/creative-form/constants';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';
import { isCpcAd } from 'states/resources/ads/business-logic';
import { PermissionsMapping } from 'common/constants/role-intents';

export function hasInsTag(creative) {
    if (!creative) {
        return false;
    }
    if (!creative.third_party_vendors) {
        return false;
    }
    return creative.third_party_vendors.indexOf('dcm__tag__ins') > -1;
}

export const MAX_TOTAL_CREATIVE_ROTATION_WEIGHT = 100;

export function changeWeight(weighted, creativeId, newWeight) {
    const totalWeightForOtherCreatives = _(weighted)
        .filter(weighted => creativeId !== weighted.markup_id)
        .map(weighted => weighted.weight)
        .sum();

    const prevWeightForSelectedCreative = _.find(weighted, w => w.markup_id === creativeId).weight;

    const nextTotalWeight = totalWeightForOtherCreatives + newWeight;
    const previouslyUnspentWeight =
        MAX_TOTAL_CREATIVE_ROTATION_WEIGHT -
        (totalWeightForOtherCreatives + prevWeightForSelectedCreative);

    const safeNewWeight =
        nextTotalWeight <= MAX_TOTAL_CREATIVE_ROTATION_WEIGHT
            ? newWeight
            : newWeight -
              (nextTotalWeight + previouslyUnspentWeight - MAX_TOTAL_CREATIVE_ROTATION_WEIGHT);

    const nextWeighted = _.map(weighted, w => ({
        ...w,
        weight: w.markup_id === creativeId ? safeNewWeight : w.weight,
    }));

    return nextWeighted;
}

export function getRotationWeight(formData, creativeId) {
    let rotationWeight = _.find(
        formData.rotation_rules.weighted,
        weighting => weighting.markup_id === creativeId
    ).weight;

    return rotationWeight;
}

export function getEstimatedPacing(formData, rotationWeight) {
    let estimatedPacing;
    switch (formData.primary_pacing) {
        case 'impressions':
            estimatedPacing = _.floor(
                formData.max_total_impressions * parseFloat((rotationWeight / 100).toFixed(2))
            );
            break;
        case 'clicks':
            estimatedPacing = _.floor(
                formData.max_total_clicks * parseFloat((rotationWeight / 100).toFixed(2))
            );
            break;
        case 'spend':
            estimatedPacing = _.floor(
                formData.max_total_spend_local * parseFloat((rotationWeight / 100).toFixed(2))
            );
            break;
        case 'billings':
            estimatedPacing = _.floor(
                formData.max_total_billings * parseFloat((rotationWeight / 100).toFixed(2))
            );
            break;
    }

    return estimatedPacing;
}

export function handleCreativesModeSwitch(draft, newMode, selectedCreatives) {
    const { start, end, rotation_rules } = draft;
    const { mode: previousMode, weighted } = rotation_rules;

    let nextWeighted;
    let nextCreatives;
    let nextScheduled;
    let nextWeather = [];

    switch (newMode) {
        case CreativeRotationMethodMapping.Single:
            if (
                _.includes(
                    [
                        CreativeRotationMethodMapping.Scheduled,
                        CreativeRotationMethodMapping.Weather,
                    ],
                    previousMode
                )
            ) {
                nextWeighted = [];
                nextCreatives = [];
            } else {
                nextWeighted = [];
                nextCreatives = selectedCreatives.length
                    ? [
                          // Default to the first selected
                          selectedCreatives[0].creativeId,
                      ]
                    : [];
            }

            break;
        case CreativeRotationMethodMapping.Even:
        case CreativeRotationMethodMapping.OptimizedForConversions:
        case CreativeRotationMethodMapping.OptimizedForVideoCompletion:
        case CreativeRotationMethodMapping.OptimizedForClicks:
            if (
                _.includes(
                    [
                        CreativeRotationMethodMapping.Scheduled,
                        CreativeRotationMethodMapping.Weather,
                    ],
                    previousMode
                )
            ) {
                nextWeighted = [];
                nextCreatives = [];
            } else {
                if (weighted && weighted.length) {
                    nextWeighted = _(weighted)
                        .map(w => ({
                            ...w,
                            weight: Math.floor(
                                MAX_TOTAL_CREATIVE_ROTATION_WEIGHT / weighted.length
                            ),
                        }))
                        .value();
                } else {
                    nextWeighted = _.map(selectedCreatives, creative => ({
                        markup_id: creative.creativeId,
                        weight: Math.floor(
                            MAX_TOTAL_CREATIVE_ROTATION_WEIGHT / selectedCreatives.length
                        ),
                    }));
                }

                // This next step only matters if creatives are selected
                if (nextWeighted.length) {
                    // Slightly increase the weight of the last element when rounding caused the total
                    // to drop below 1
                    let totalWeight = _(nextWeighted)
                        .map(w => w.weight)
                        .sum();
                    if (totalWeight < MAX_TOTAL_CREATIVE_ROTATION_WEIGHT) {
                        nextWeighted[nextWeighted.length - 1].weight +=
                            MAX_TOTAL_CREATIVE_ROTATION_WEIGHT - totalWeight;

                        let leftoverWeight = MAX_TOTAL_CREATIVE_ROTATION_WEIGHT - totalWeight;
                        let creativeToIncrement = nextWeighted.length - 1;

                        while (leftoverWeight--) {
                            nextWeighted[creativeToIncrement--].weight += 1;
                        }
                    }
                }
            }

            break;
        case CreativeRotationMethodMapping.Weighted:
            if (
                _.includes(
                    [
                        CreativeRotationMethodMapping.Scheduled,
                        CreativeRotationMethodMapping.Weather,
                    ],
                    previousMode
                )
            ) {
                nextWeighted = [];
                nextCreatives = [];
            } else {
                if (weighted.length) {
                    nextWeighted = _(weighted)
                        .map(w => ({
                            ...w,
                        }))
                        .value();
                } else {
                    nextWeighted = _.map(selectedCreatives, creative => ({
                        markup_id: creative.creativeId,
                        weight: 0,
                    }));
                }
            }

            break;
        case CreativeRotationMethodMapping.Scheduled:
            nextWeighted = [];
            nextCreatives = [];
            nextScheduled = _.isEmpty(draft.rotation_rules.scheduled)
                ? [
                      {
                          id: uuid.v4(),
                          start,
                          end,
                          weighted: [
                              {
                                  id: uuid.v4(),
                                  markup_id: null,
                                  weight: 100,
                              },
                          ],
                          isManualWeight: false,
                      },
                  ]
                : draft.rotation_rules.scheduled;
            break;
        case CreativeRotationMethodMapping.Weather:
            nextWeighted = [];
            nextScheduled = [];
            nextCreatives = [];
            nextWeather = _.isEmpty(draft.rotation_rules.weather)
                ? [
                      {
                          id: uuid.v4(),
                          qualifier: '',
                          type: '',
                          amount: -1,
                          markupId: null,
                          isDefault: true,
                      },
                  ]
                : draft.rotation_rules.weather;
    }

    return {
        nextWeighted,
        nextCreatives,
        nextScheduled,
        nextWeather,
    };
}

export function getAssignedPacing(primaryPacing, formData, totalRotationWeight) {
    let assignedPacing;
    switch (primaryPacing) {
        case undefined:
            assignedPacing = null;
            break;
        case 'impressions':
            assignedPacing = _.floor(formData.max_total_impressions * (totalRotationWeight / 100));
            break;
        case 'clicks':
            assignedPacing = _.floor(
                (assignedPacing = formData.max_total_clicks * (totalRotationWeight / 100))
            );
            break;
        case 'spend':
            assignedPacing = _.floor(
                (assignedPacing = formData.max_total_spend_local * (totalRotationWeight / 100))
            );
            break;
        case 'billings':
            assignedPacing = _.floor(
                (assignedPacing = formData.max_total_billings * (totalRotationWeight / 100))
            );
            break;
    }
    return assignedPacing;
}

export function getTotalRotationWeight(formDataLoaded, formData) {
    let totalRotationWeight;
    if (formDataLoaded && formData.rotation_rules.weighted.length) {
        totalRotationWeight = _(formData.rotation_rules.weighted)
            .map(weighting => weighting.weight)
            .sum();
    } else {
        totalRotationWeight = 0;
    }
    return totalRotationWeight;
}

export function isPacingColumnEnabledForRotationSelector(formData) {
    if (!formData) {
        return false;
    }

    switch (formData.primary_pacing) {
        case 'impressions':
            return formData.max_total_impressions > 0;
        case 'clicks':
            return formData.max_total_clicks > 0;
        case 'spend':
            return formData.max_total_spend_local > 0;
        case 'billings':
            return formData.max_total_billings > 0;
    }
}

export function handleSelectCreative(formData, onCreativeChange, onRotationChange) {
    let { mode, weighted } = formData.rotation_rules;

    if (input === null) {
        if (mode === CreativeRotationMethodMapping.Scheduled) {
            onCreativeChange([]);
            onRotationChange({
                mode,
                weighted: [],
                scheduled: [
                    {
                        start: formData.start,
                        end: formData.end,
                    },
                ],
            });
        } else {
            onCreativeChange([]);
            onRotationChange({ mode, weighted: [], scheduled: [] });
        }

        return;
    }

    let nextCreatives;
    let nextWeighted;

    switch (mode) {
        // In this case, `input` is a string
        case CreativeRotationMethodMapping.Single:
            nextWeighted = [];
            nextCreatives = [input];

            break;

        // In these cases, `input` is an array
        case CreativeRotationMethodMapping.Even:
            nextCreatives = input;
            nextWeighted = _.map(input, (creativeId, index, collection) => ({
                markup_id: creativeId,
                weight: Math.floor(MAX_TOTAL_CREATIVE_ROTATION_WEIGHT / collection.length),
            }));

            // Slightly increase the weight of the last element when rounding caused the total
            // to drop below 1
            let totalWeight = _(nextWeighted)
                .map(w => w.weight)
                .sum();
            if (totalWeight && totalWeight < MAX_TOTAL_CREATIVE_ROTATION_WEIGHT) {
                let leftoverWeight = MAX_TOTAL_CREATIVE_ROTATION_WEIGHT - totalWeight;
                let creativeToIncrement = nextWeighted.length - 1;

                while (leftoverWeight--) {
                    nextWeighted[creativeToIncrement--].weight += 1;

                    if (creativeToIncrement === nextWeighted.length) {
                        creativeToIncrement = nextWeighted.length - 1;
                    }
                }
            }

            break;
        case CreativeRotationMethodMapping.Weighted:
            nextCreatives = input;

            let previouslyWeightedCreatives = _.map(weighted, w => w.markup_id);
            let newlySelectedCreative = _.difference(input, previouslyWeightedCreatives)[0];

            if (newlySelectedCreative) {
                nextWeighted = _(weighted)
                    .map(w => w)
                    .push({ markup_id: newlySelectedCreative, weight: 0 })
                    .value();
            } else {
                nextWeighted = _(weighted)
                    .map(w => w)
                    .filter(w => _.includes(nextCreatives, w.markup_id))
                    .value();
            }

            break;
    }

    onCreativeChange(nextCreatives);
    onRotationChange({ mode, weighted: nextWeighted });
}

export function handleSelectEvenCreative(nextCreatives) {
    let nextWeighted = _.map(nextCreatives, (creativeId, index, collection) => ({
        markup_id: creativeId,
        weight: Math.floor(MAX_TOTAL_CREATIVE_ROTATION_WEIGHT / collection.length),
    }));

    // Slightly increase the weight of the last element when rounding caused the total
    // to drop below 100
    let totalWeight = _(nextWeighted)
        .map(w => w.weight)
        .sum();
    if (totalWeight && totalWeight < MAX_TOTAL_CREATIVE_ROTATION_WEIGHT) {
        let leftoverWeight = MAX_TOTAL_CREATIVE_ROTATION_WEIGHT - totalWeight;
        let creativeToIncrement = nextWeighted.length - 1;

        while (leftoverWeight--) {
            nextWeighted[creativeToIncrement--].weight += 1;

            if (creativeToIncrement === nextWeighted.length) {
                creativeToIncrement = nextWeighted.length - 1;
            }
        }
    }

    return {
        nextWeighted,
    };
}

export function handleSelectWeightedCreative(nextCreatives, weighted) {
    const previouslyWeightedCreatives = _.map(weighted, w => w.markup_id);
    const newlySelectedCreative = _.difference(nextCreatives, previouslyWeightedCreatives)[0];

    let nextWeighted;
    if (newlySelectedCreative) {
        nextWeighted = _(weighted)
            .map(w => w)
            .push({ markup_id: newlySelectedCreative, weight: 0 })
            .value();
    } else {
        nextWeighted = _(weighted)
            .map(w => w)
            .filter(w => _.includes(nextCreatives, w.markup_id))
            .value();
    }

    return {
        nextWeighted,
    };
}

export function handleClearCreatives(draft) {
    const { rotation_rules } = draft;

    const { mode } = rotation_rules;

    let nextRotationRules;
    let nextCreatives;

    if (mode === CreativeRotationMethodMapping.Scheduled) {
        nextCreatives = [];
        nextRotationRules = {
            mode,
            weighted: [],
            scheduled: [
                {
                    start: draft.start,
                    end: draft.end,
                },
            ],
        };
    } else {
        nextCreatives = [];
        nextRotationRules = { mode, weighted: [], scheduled: [] };
    }

    return {
        nextRotationRules,
        nextCreatives,
    };
}

export function handleMultiClearCreatives({ draft, dateRangeIndex }) {
    const {
        rotation_rules: { scheduled },
    } = draft;

    const nextScheduled = _.cloneDeep(scheduled);
    nextScheduled[dateRangeIndex].weighted = [];
    return { nextScheduled };
}

export function handleUpdateCreativeScheduleRow(draft, rowId, data) {
    const {
        rotation_rules: { scheduled },
    } = draft;

    const nextScheduled = _.cloneDeep(scheduled);

    nextScheduled[rowId] = {
        ...nextScheduled[rowId],
        ...data,
    };

    if (data.end && nextScheduled[rowId + 1]) {
        nextScheduled[rowId + 1] = {
            ...nextScheduled[rowId + 1],
            start: moment(data.end)
                .add(1, 'minutes')
                .toISOString(),
        };
    }

    return { nextScheduled };
}

export function addScheduledWeightedCreativeDateRange({ draft }) {
    const {
        rotation_rules: { scheduled },
        end: adEnd,
    } = draft;

    const nextScheduled = _.cloneDeep(scheduled);

    if (_.size(nextScheduled) > 0) {
        nextScheduled[nextScheduled.length - 1].end = '';
    }

    nextScheduled.push({
        id: uuid.v4(),
        start: '',
        end: adEnd,
        weighted: [],
    });

    return { nextScheduled };
}

export function changeScheduledWeightedCreativeEndDate({ draft, dateRangeIndex, end }) {
    const {
        rotation_rules: { scheduled },
    } = draft;

    const nextScheduled = _.cloneDeep(scheduled);

    nextScheduled[dateRangeIndex].end = end;

    _.each(nextScheduled, (dateRange, index) => {
        if (nextScheduled[index + 1]) {
            nextScheduled[index + 1] = {
                ...nextScheduled[index + 1],
                start: moment(dateRange.end)
                    .add(1, 'minutes')
                    .toISOString(),
            };
        }
    });

    return { nextScheduled };
}

export function removeScheduledWeightedCreative({ draft, dateRangeIndex, creativeIndex }) {
    const {
        start,
        end,
        rotation_rules: { scheduled },
    } = draft;

    let nextScheduled = _.cloneDeep(scheduled);

    nextScheduled[dateRangeIndex].weighted = _.filter(
        nextScheduled[dateRangeIndex].weighted,
        (row, index) => index !== creativeIndex
    );

    if (nextScheduled[dateRangeIndex].weighted.length === 0) {
        nextScheduled = _.filter(nextScheduled, (dateRange, index) => index !== dateRangeIndex);
    }

    if (_.size(nextScheduled) < 1) {
        nextScheduled.push({
            id: uuid.v4(),
            start: start,
            end: end,
            weighted: [],
            isManualWeight: false,
        });
    }

    return {
        nextScheduled: syncStartEndAcrossSchedules({
            schedules: nextScheduled,
            adStart: start,
            adEnd: end,
        }),
    };
}

export function removeScheduledWeightedCreativeDateRange({ draft, dateRangeIndex }) {
    const {
        start,
        end,
        rotation_rules: { scheduled },
    } = draft;

    // We must alwawys have 1 remaining date range
    if (scheduled.length === 1) {
        return { nextScheduled: scheduled };
    }

    const nextScheduled = _.filter(scheduled, (dateRange, index) => index !== dateRangeIndex);

    return {
        nextScheduled: syncStartEndAcrossSchedules({
            schedules: nextScheduled,
            adStart: start,
            adEnd: end,
        }),
    };
}

function syncStartEndAcrossSchedules({ schedules, adStart, adEnd }) {
    if (schedules.length === 0) {
        return schedules;
    }

    let nextSchedules = [...schedules];

    nextSchedules[0] = {
        ...nextSchedules[0],
        start: adStart,
    };

    nextSchedules[nextSchedules.length - 1] = {
        ...nextSchedules[nextSchedules.length - 1],
        end: adEnd,
    };

    _.each(nextSchedules, (schedule, index) => {
        const nextSchedule = nextSchedules[index + 1];

        const shouldSetStartDateOfNextSchedule = schedule.end && nextSchedule;

        if (shouldSetStartDateOfNextSchedule) {
            nextSchedules[index + 1] = {
                ...nextSchedule,
                start: moment(schedule.end)
                    .add(1, 'minutes')
                    .toISOString(),
            };
        }
    });

    return nextSchedules;
}

export function addCreativeToScheduledWeightedCreative({ draft, dateRangeIndex }) {
    const {
        rotation_rules: { scheduled },
    } = draft;

    const nextScheduled = _.cloneDeep(scheduled);

    nextScheduled[dateRangeIndex].weighted.push({
        id: uuid.v4(),
        markup_id: null,
        weight: 0,
    });

    return { nextScheduled };
}

export function changeScheduledWeightedCreativeWeight({
    draft,
    dateRangeIndex,
    creativeIndex,
    weight,
}) {
    const {
        rotation_rules: { scheduled },
    } = draft;

    const nextScheduled = _.filter(_.cloneDeep(scheduled), schedule => schedule.markup_id !== null);

    nextScheduled[dateRangeIndex].weighted[creativeIndex].weight = weight;

    return { nextScheduled };
}

export function changeScheduledEvenWeighted({ draft, dateRangeIndex }) {
    const {
        rotation_rules: { scheduled },
    } = draft;

    let nextScheduled = _.cloneDeep(scheduled);
    if (!nextScheduled[dateRangeIndex]) {
        return { nextScheduled };
    }
    nextScheduled[dateRangeIndex].weighted = _.filter(
        nextScheduled[dateRangeIndex].weighted,
        weight => weight.markup_id !== null
    );

    const weightLength = _.size(nextScheduled[dateRangeIndex].weighted);
    _.map(nextScheduled[dateRangeIndex].weighted, creative => {
        if (creative.markup_id) {
            creative.weight = Math.floor(MAX_TOTAL_CREATIVE_ROTATION_WEIGHT / weightLength);
        }
    });

    let totalWeight = _(nextScheduled[dateRangeIndex].weighted)
        .map(w => w.weight)
        .sum();

    if (totalWeight && totalWeight < MAX_TOTAL_CREATIVE_ROTATION_WEIGHT) {
        let leftoverWeight = MAX_TOTAL_CREATIVE_ROTATION_WEIGHT - totalWeight;
        let creativeToIncrement = weightLength - 1;

        while (leftoverWeight--) {
            nextScheduled[dateRangeIndex].weighted[creativeToIncrement--].weight += 1;

            if (creativeToIncrement === weightLength) {
                creativeToIncrement = weightLength - 1;
            }
        }
    }

    return { nextScheduled };
}

export function changeScheduledWeightedCreativeSelection({ draft, dateRangeIndex, creativeId }) {
    const {
        rotation_rules: { scheduled },
    } = draft;
    const nextScheduled = _.cloneDeep(scheduled);
    const lastIndex = _.findLastIndex(nextScheduled[dateRangeIndex].weighted);
    nextScheduled[dateRangeIndex].weighted[lastIndex].markup_id = creativeId;

    return { nextScheduled };
}

export function handleAddCreativeScheduleRow(draft) {
    const { rotation_rules, end: adEnd } = draft;

    const { scheduled } = rotation_rules;

    const nextScheduled = _.cloneDeep(scheduled);
    nextScheduled[nextScheduled.length - 1].end = '';

    nextScheduled.push({
        start: '',
        end: adEnd,
    });

    return nextScheduled;
}

export function handleRemoveCreativeScheduleRow(rowId, draft) {
    const { rotation_rules, end: adEnd, start: adStart } = draft;

    const { scheduled } = rotation_rules;

    let nextScheduled = _.cloneDeep(scheduled);

    if (nextScheduled.length === 2) {
        nextScheduled = [
            {
                markup_id: nextScheduled[0].markup_id,
                start: adStart,
                end: adEnd,
            },
        ];
    } else if (rowId === nextScheduled.length - 1) {
        nextScheduled.splice(rowId, 1);
        nextScheduled[nextScheduled.length - 1].end = adEnd;
    } else {
        let startDateOfRowToRemove = nextScheduled[rowId].start;

        nextScheduled.splice(rowId, 1);
        nextScheduled[rowId].start = startDateOfRowToRemove;
    }

    return nextScheduled;
}

export function serializeRotationRules(rotation_rules) {
    return {
        ...rotation_rules,
        weighted: _.map(rotation_rules.weighted, w => ({
            ...w,
            weight: parseFloat(w.weight.toFixed(2)),
        })),
    };
}

export function processCreative(attr, formData, creativeId, campaign) {
    let previewURL;
    switch (attr.format) {
        case 'video':
            previewURL = attr.image_preview_url;
            break;
        case 'mraid':
        case 'custom_html':
            previewURL = attr.reference_image_preview_url;
            break;
        default:
            previewURL = attr.image_preview_url;
    }

    const processedCreative = {
        ...attr,
        creativeId,
        selected: formData.creative.indexOf(creativeId) !== -1 ? true : false,
        name: attr.name,
        size: attr.size,
        type: attr.format,
        previewImg: previewURL,
        hasCpcInsConflict: isCpcAd(formData) && hasInsTag(attr),
        platforms: attr.platforms,
        disableSelection: shouldDisableCreativeSelectionOnCampaignType(attr, campaign),
    };

    if (
        processedCreative.selected &&
        (formData.rotation_rules.mode === CreativeRotationMethodMapping.Weighted ||
            formData.rotation_rules.mode === CreativeRotationMethodMapping.Even)
    ) {
        let rotationWeight = getRotationWeight(formData, creativeId);
        let estimatedPacing = getEstimatedPacing(formData, rotationWeight);

        processedCreative.estimatedPacing = estimatedPacing;

        // Convert to a percentage to avoid decimal rounding issues going into and coming out of
        // the creative module
        processedCreative.weight = rotationWeight;
    }

    return processedCreative;
}

export const handleAddCreativeRotationByWeatherSetting = draft => {
    const { rotation_rules } = draft;

    const { weather } = rotation_rules;

    const nextWeather = _.cloneDeep(weather);

    nextWeather.push({
        id: uuid.v4(),
        qualifier: '',
        type: '',
        amount: -1,
        markupId: null,
        isDefault: false,
    });

    return nextWeather;
};

export const syncScheduleRotationWithFlight = ({ ad, changes }) => {
    const {
        rotation_rules: { scheduled },
    } = ad;

    let flightFieldName = changes.start ? 'start' : 'end';
    let flightFieldValue = changes[flightFieldName];

    let rowToBeChanged;
    let newSchedule;

    switch (flightFieldName) {
        case 'start':
            const newAdStart = moment(flightFieldValue);
            _.forEach(scheduled, (schedule, index) => {
                const scheduleEnd = moment(schedule.end);
                if (newAdStart.isBefore(scheduleEnd)) {
                    rowToBeChanged = index;
                    return false; // Break out early
                }
            });
            newSchedule = _.cloneDeep(scheduled);
            if (rowToBeChanged !== 0) {
                newSchedule = newSchedule.slice(rowToBeChanged);
                // If we ended up having to remove rows, reset all creatives for the schedule too
                newSchedule = _.map(newSchedule, schedule => ({
                    ...schedule,
                }));
            }
            if (_.size(newSchedule) < 1) {
                newSchedule.push({
                    id: uuid.v4(),
                    weighted: [],
                    isManualWeight: false,
                });
            }

            newSchedule[0].start = flightFieldValue;
            break;
        case 'end':
            const newAdEnd = moment(flightFieldValue);
            _.forEachRight(scheduled, (schedule, index) => {
                const scheduleStart = moment(schedule.start);
                if (newAdEnd.isAfter(scheduleStart)) {
                    rowToBeChanged = index;
                    return false; // Break out early
                }
            });

            newSchedule = _.cloneDeep(scheduled);
            if (rowToBeChanged !== scheduled.length - 1) {
                newSchedule = newSchedule.slice(0, rowToBeChanged + 1);
                // If we ended up having to remove rows, reset all creatives for the schedule too
                newSchedule = _.map(newSchedule, schedule => ({
                    ...schedule,
                }));
            }

            if (_.size(newSchedule) < 1) {
                newSchedule.push({
                    id: uuid.v4(),
                    weighted: [],
                    isManualWeight: false,
                });
            }

            newSchedule[newSchedule.length - 1].end = flightFieldValue;
    }

    const newRotationRules = {
        ...ad.rotation_rules,
        scheduled: newSchedule,
    };

    return newRotationRules;
};

export function canCreateCreative(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canEditCreative(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canDuplicateCreative(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canArchiveCreative(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function getDistributedWeight({ index, size }) {
    if (size === 0) {
        return 0;
    }

    const weights = _.fill(Array(size), 0);

    let i = 0;
    _.times(100, () => {
        weights[i] += 1;

        if (i >= size - 1) {
            i = 0;
        } else {
            i += 1;
        }
    });

    return weights[index];
}

const campaignTypeByPlatform = {};
_.forEach(CREATIVE_TABLE_VERSION_2, ({ value, allowedOnCampaignType }) => {
    campaignTypeByPlatform[value] = allowedOnCampaignType;
});

/**
 * A creative should be disabled if the campaign type does not accept any of the creative.platforms
 * @param {*} creative
 * @param {*} campaign
 * @returns boolean if this creative should be disabled for selection or not
 */
function shouldDisableCreativeSelectionOnCampaignType(creative, campaign) {
    for (const platform of creative.platforms) {
        const allowedOnCampaignType = campaignTypeByPlatform[platform];
        if (allowedOnCampaignType === campaign.type) {
            return false;
        }
    }

    return true;
}

import PropTypes from 'prop-types';
import React from 'react';

import DateTimeRangePicker from 'widgets/date-time-range-picker';

class HalfDateTimeRangePicker extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        mode: PropTypes.oneOf(['dropdown', 'inline']),
        placeholder: PropTypes.string,
        timezone: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        visibleHalf: PropTypes.string.isRequired,
        lastSelectableDate: PropTypes.string,
        value: PropTypes.shape({
            start: PropTypes.instanceOf(Date),
            end: PropTypes.instanceOf(Date),
        }).isRequired,
    };

    static defaultProps = {
        mode: 'dropdown',
    };

    render() {
        return <DateTimeRangePicker {...this.props} />;
    }
}

export default HalfDateTimeRangePicker;

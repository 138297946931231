import _ from 'lodash';
import http from 'utils/http';
import c from 'common/constants/flux-events';

const actions = {
    getOne(orgId, adPresetId) {
        return (dispatch, getState) => {
            return http()
                .get(`organizations/${orgId}/adpresets/${adPresetId}`)
                .then(
                    adpreset => {
                        dispatch({
                            type: c.SYSTEM__AD_PRESET__FETCH__SUCCESS,
                            payload: { orgId, adpreset },
                        });

                        return adpreset;
                    },
                    err => {
                        console.error(`GET ONE ad preset error`, err);

                        throw err;
                    }
                );
        };
    },
    getAll(orgId) {
        return (dispatch, getState) => {
            return http()
                .get(`organizations/${orgId}/adpresets`)
                .then(
                    adpresets => {
                        dispatch({
                            type: c.SYSTEM__AD_PRESET__FETCH__SUCCESS,
                            payload: { orgId, adpresets },
                        });

                        return adpresets;
                    },
                    err => {
                        console.error(`GET ALL ad preset error`, err);

                        throw err;
                    }
                );
        };
    },
    create(orgId, payload) {
        return (dispatch, getState) => {
            return http()
                .post(`organizations/${orgId}/adpresets`, payload)
                .then(
                    adpreset => {
                        dispatch({
                            type: c.SYSTEM__AD_PRESET__FETCH__SUCCESS,
                            payload: { orgId, adpreset },
                        });
                        return adpreset;
                    },
                    err => {
                        console.error(`CREATE ad preset error`, err);

                        throw err;
                    }
                );
        };
    },
    update(orgId, adPresetId, payload) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.adPresets.${adPresetId}.attr._etag`);

            const promise = http()
                .setIfMatch(etag)
                .patch(`organizations/${orgId}/adpresets/${adPresetId}`, payload)
                .then(
                    adpreset => {
                        dispatch({
                            type: c.SYSTEM__AD_PRESET__FETCH__SUCCESS,
                            payload: { orgId, adPresetId, adpreset },
                        });
                        return adpreset;
                    },
                    err => {
                        console.error(`UPDATE ad preset error`, err);

                        throw err;
                    }
                );

            return promise;
        };
    },
};

export default actions;

import _ from 'lodash';

import smartQuery from 'utils/smart-query';

export default function selector(storeState, props) {
    const { campaignId } = props.params;

    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);

    if (!campaign) {
        return {
            files: [],
        };
    }

    const filesResource = _.get(storeState, `resources.files`, {});
    const pagination = _.get(storeState, `filesOverview`);

    const tempAssets = _.get(storeState, `filesOverview.assets`);
    const failedAssets = _.get(storeState, `filesOverview.failedAssets`);
    const loadingAssets = _.get(storeState, `filesOverview.loadingAssets`);
    const uploadAssetsErrorMessage = _.get(storeState, `filesOverview.uploadAssetsErrorMessage`);

    const campaignFiles = _.reduce(
        campaign.files,
        (acc, fileId, index) => {
            const file = filesResource[fileId];

            if (!file) {
                return acc;
            }

            return {
                ...acc,
                [fileId]: file,
            };
        },
        {}
    );

    const assets = _.filter(campaignFiles, (value, key) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? value : null;
    });

    const documents = _.filter(campaignFiles, (value, key) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? null : value;
    });

    const searchQuery = _.get(pagination, 'filterOptions.search', '');
    const searchQueryClean = searchQuery.toLowerCase();

    const entity_filtering = entities => {
        const _o = _(entities)
            .filter(entity => {
                const entity_attr = 'attr' in entity ? entity.attr : {};
                const stringBeingSearch = _.values(_.pick(entity_attr, ['name'])).join(' ');
                const stringBeingSearch_lowercase = stringBeingSearch.toLowerCase();
                // console.log('xxxxxx: ', smartQuery(searchQueryClean).test(stringBeingSearch_lowercase),  searchQueryClean, stringBeingSearch_lowercase);
                if (searchQueryClean === '') return true;
                if (smartQuery(searchQueryClean).test(stringBeingSearch_lowercase)) return true;
                return false;
            })
            .value();
        return _o;
    };

    return {
        campaign,
        campaignId,
        files: {
            documents: entity_filtering(documents),
            assets: entity_filtering(assets),
        },
        tempAssets,
        failedAssets,
        loadingAssets,
        uploadAssetsErrorMessage,
        searchQuery,
    };
}

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import selector from './selector';
import actions from './actions';

import StatusToggle from 'widgets-v5/status-toggle';

class AdStatusToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            togglePosition: props.paused ? 'left' : 'right',
        };
    }

    toggleAdStatus = () => {
        const { disabled, adId, campaignId, dispatch } = this.props;
        const { togglePosition } = this.state;

        if (disabled) {
            return;
        }

        const paused = togglePosition === 'left';
        if (paused) {
            this.setState({ togglePosition: 'right' });
            dispatch(actions.unpause(campaignId, adId));
        } else {
            this.setState({ togglePosition: 'left' });
            dispatch(actions.pause(campaignId, adId));
        }
    };
    render() {
        const { status, disabled } = this.props;

        const muted = status === 'ended' || status === 'campaignIsOff';

        return (
            <StatusToggle
                disabled={disabled}
                status={status}
                onClick={this.toggleAdStatus}
                muted={muted}
                togglePosition={this.state.togglePosition}
            />
        );
    }
}

export default connect(selector)(AdStatusToggle);

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';

import AdPresets from 'containers/ad-preset';
import selector from './selector';
import { can } from 'services/can';
import { SearchInput } from 'widgets-v6/standard-input';
import { newAdPresetForm as actions } from './actions';
import { PermissionsMapping } from 'common/constants/role-intents';

const headers = [
    {
        label: 'Name',
        align: 'left',
    },
    {
        label: 'Actions',
        align: 'right',
    },
];

const useStyles = makeStyles(theme => ({
    table: {
        margin: theme.spacing(3),
    },
    container: {
        marginTop: theme.spacing(2),
    },
}));

function AdPresetNew(props) {
    const { adPresets, isPresetsLoading, location, params, routes, history, dispatch } = props;

    useEffect(() => {
        dispatch(actions.init());
    }, []);

    const classes = useStyles();
    const [values, setValues] = useState({ adPresetId: '', modalOpen: false, filter: '' });

    const editAdPreset = value => {
        setValues({ ...values, ['adPresetId']: value, ['modalOpen']: true });
        //this.setState({ modalOpen: true, adPresetId: adPresetId });
    };

    const onChange = e => {
        const { value } = e.target;
        //this.setState({ filter: value });
        setValues({ ...values, ['filter']: value });
    };

    const handleClose = e => {
        if (e) {
            e.preventDefault();
        }
        //this.setState({ modalOpen: false });
        setValues({ ...values, ['modalOpen']: false });
    };

    const handleOpen = e => {
        if (e) {
            e.preventDefault();
        }
        //this.setState({ modalOpen: true, adPresetId: 'new' });
        setValues({ ...values, ['adPresetId']: 'new', ['modalOpen']: true });
    };

    return (
        <div>
            <Container maxWidth="lg" className={classes.container}>
                <Toolbar>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom>
                                Ad Presets
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={3}>
                                <SearchInput onChange={onChange} placeholder="Search" />
                            </Grid>
                            <Grid item>
                                {can(PermissionsMapping.ADPRESET__EDIT) && (
                                    <Button
                                        startIcon={<AddIcon />}
                                        variant="contained"
                                        color="primary"
                                        onClick={handleOpen}
                                    >
                                        {' '}
                                        New Preset
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Paper className={classes.table}>
                    {isPresetsLoading ? <LinearProgress /> : null}
                    <Table aria-label="simple table" size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {_.map(headers, header => (
                                    <TableCell
                                        key={header.label}
                                        align={header.align}
                                        width={header.width}
                                        size={'medium'}
                                    >
                                        {header.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(
                                _.filter(
                                    adPresets,
                                    row =>
                                        row.ad_preset_name
                                            .toLowerCase()
                                            .indexOf(values.filter.toLowerCase()) > -1
                                ).reverse(),
                                row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.ad_preset_name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => {
                                                    editAdPreset(row.id);
                                                }}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </Paper>
                {values.modalOpen ? (
                    <Dialog fullWidth={true} maxWidth="md" open={values.modalOpen}>
                        <AdPresets
                            EN_1421_adPresetId={values.adPresetId}
                            handleClose={handleClose}
                            location={location}
                            params={params}
                            routes={routes}
                            history={history}
                        />
                    </Dialog>
                ) : null}
            </Container>
        </div>
    );
}

export default connect(selector)(AdPresetNew);

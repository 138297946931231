import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import validate from 'utils/validate';

class Draft extends React.Component {
    static propTypes = {
        schema: PropTypes.object,
        initialDraft: PropTypes.object,
        // use metadata when you want validation to run when external data changes
        metadata: PropTypes.object,

        onDraftChange: PropTypes.func,
        onInit: PropTypes.func,
    };

    static defaultProps = {
        metadata: {},
        onDraftChange: _.noop,
    };

    state = {
        draft: this.props.initialDraft,
        errors: {},
    };

    componentDidMount = () => {
        this.setState(
            {
                draft: this.state.draft,
                errors: this.validate(this.state.draft),
            },
            () => {
                if (this.props.onInit) {
                    this.props.onInit({
                        draft: this.state.draft,
                        errors: this.state.errors,
                    });
                }
            }
        );
    };

    UNSAFE_componentWillReceiveProps = nextProps => {
        // When metadata changes, we must validate again
        const hasMetadataChanged =
            shallowCompare(nextProps.metadata, this.props.metadata) === false;

        if (hasMetadataChanged) {
            this.setState(
                {
                    errors: this.validate(this.state.draft),
                },
                this.emitChange
            );
        }
    };

    emitChange = () => {
        if (this.props.onDraftChange) {
            this.props.onDraftChange({
                draft: this.state.draft,
                errors: this.state.errors,
            });
        }
    };

    validate = draft => {
        return validate(draft, this.props.schema);
    };

    updateDraft = draft => {
        const nextDraft = {
            ...this.state.draft,
            ...draft,
        };

        this.setState(
            {
                draft: nextDraft,
                errors: this.validate(nextDraft),
            },
            this.emitChange
        );
    };

    render() {
        return this.props.children({
            errors: this.state.errors,
            draft: this.state.draft,

            updateDraft: this.updateDraft,
        });
    }
}

function shallowCompare(a, b) {
    for (let key in a) {
        if (!(key in b) || a[key] !== b[key]) {
            return false;
        }
    }

    for (let key in b) {
        if (!(key in a) || a[key] !== b[key]) {
            return false;
        }
    }

    return true;
}

export default Draft;

import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles, withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { BlockLoadGroup } from 'widgets-v5/load-group';
import { TableWrapper } from 'widgets-v6/table';
import { SearchInput } from 'widgets-v6/standard-input';

import actions from './actions';
import selector from './selector';
import AdminUserForm from 'packages/forms/admin-users-form';
import { default as adminUserFormActions } from 'packages/forms/admin-users-form/actions';
import AdminOrgForm from 'packages/forms/admin-org-form';
import { default as adminOrgFormActions } from 'packages/forms/admin-org-form/actions';
import DeleteUserModal from 'packages/containers/delete-user-modal';
import { default as deleteModalActions } from 'packages/containers/delete-user-modal/actions';
import { rolePresentationDictionary } from 'common/constants/role-presentation-dict';
import { isInternalUser } from 'states/profile/business-rules';
import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';

const dashboardStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    sidebarContent: {
        padding: theme.spacing(1),
    },
    name: {
        marginRight: theme.spacing(0.5),
    },
    sidebarSection: {
        marginBottom: theme.spacing(1.5),
    },
    gridRoot: {
        height: '91%',
    },
    gridItem: {
        height: '100%',
    },
    tableContainer: {
        height: '87%',
    },
    searchContainer: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        height: '42px',
    },
    orgListItem: {
        '&:hover': {
            '& > $orgListItemAction': {
                display: 'block',
            },
        },
    },
    orgListItemAction: {
        display: 'none',
    },
    buttonLabel: {
        fontSize: '11px',
    },
}));

const LoadingContainer = withStyles(() => ({
    root: {
        height: '80%',
    },
}))(Grid);

const organizationTypes = [
    {
        label: 'Advertisers',
        value: 'advertiser',
    },
    {
        label: 'Partners',
        value: 'partner',
    },
    {
        label: 'Sales Reps',
        value: 'sales_rep',
    },
    {
        label: 'Media Buyers',
        value: 'media_buyer',
    },
    {
        label: 'Admins',
        value: 'admin',
    },
];

const orgTypeLabelMap = {
    admin: 'Admin',
    media_buyer: 'Media Buyer',
    advertiser: 'Advertiser',
    sales_rep: 'Sales Rep',
    partner: 'Partner',
};

const ADDICTIVE_ORG = '53271100d26f9a2f917ed290';

const ChildOrganizationDashboard = ({
    dispatch,
    isPageLoading,
    currentOrganization,
    filteredUsers,
    filteredOrganizationsList,
    isSideBarLoading,
    ownOrganizationType,
    isTableLoading,
    ownOrganizationId,
}) => {
    const classes = dashboardStyles();
    const [currentTab, setCurrentTab] = useState('advertiser');
    useEffect(() => {
        dispatch(actions.init());
    }, []);

    const onChangeUserTextFilterDebounced = useMemo(
        () => _.debounce(value => dispatch(actions.filterUsers(value)), 300),
        []
    );

    const onChangeOrganizationTextFilterDebounced = useMemo(
        () => _.debounce(value => dispatch(actions.filterOrganizationsByText(value)), 300),
        []
    );

    const onChangeUserTextFilter = e => {
        const { value } = e.target;
        onChangeUserTextFilterDebounced(value);
    };
    const onChangeOrganizationTextFilter = e => {
        const { value } = e.target;
        onChangeOrganizationTextFilterDebounced(value);
    };
    const onChangeOrganizationTypeFilter = (event, value) => {
        setCurrentTab(value);
        dispatch(actions.changeOrgFilterType(value));
    };
    const fetchUsers = value => {
        if (value === 'all') {
            return dispatch(actions.fetchAllOrganizationsByType(currentTab));
        }
        return dispatch(actions.fetchOrganizationData(value));
    };

    if (isPageLoading) {
        return (
            <LoadingContainer container justify="center" alignItems="center">
                <CircularProgress color="primary" />
            </LoadingContainer>
        );
    }

    let tabs = _.cloneDeep(organizationTypes);

    if (ownOrganizationType !== 'admin') {
        tabs = _.filter(tabs, tab => tab.value !== 'admin' && tab.value !== 'media_buyer');
    }

    if (!isInternalUser()) {
        tabs = _.filter(
            tabs,
            tab => tab.value !== 'admin' && tab.value !== 'partner' && tab.value !== 'sales_rep'
        );
    }

    if (ownOrganizationId === ADDICTIVE_ORG) {
        tabs = _.filter(tabs, tab => tab.value !== 'media_buyer');
    }

    const currentTabLabel = _.get(_.find(tabs, tab => tab.value === currentTab), 'label');

    return (
        <div>
            <Grid container spacing={1} className={classes.gridRoot}>
                <Grid item xs={4} className={classes.gridItem}>
                    {can(PermissionsMapping.ADMIN_ORG__VIEW) && (
                        <Paper>
                            <div className={classes.marginBottom}>
                                <Tabs
                                    value={currentTab}
                                    onChange={onChangeOrganizationTypeFilter}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {_.map(tabs, tab => (
                                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                                    ))}
                                </Tabs>
                            </div>
                            <div className={classes.sidebarContent}>
                                <BlockLoadGroup isLoading={isSideBarLoading}>
                                    <div className={classes.searchContainer}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <SearchInput
                                                    key={currentTab}
                                                    placeholder={`Search ${currentTabLabel}`}
                                                    onChange={onChangeOrganizationTextFilter}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                {can(PermissionsMapping.ADMIN_SUB_ORG__EDIT) && (
                                                    <Button
                                                        className={classes.button}
                                                        classes={{ label: classes.buttonLabel }}
                                                        color="primary"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        onClick={() =>
                                                            dispatch(
                                                                adminOrgFormActions.openForm(
                                                                    null,
                                                                    currentTab
                                                                )
                                                            )
                                                        }
                                                    >
                                                        {`New ${orgTypeLabelMap[currentTab]}`}
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        {filteredOrganizationsList.length === 0 ? (
                                            <Typography align="center">{`No ${currentTabLabel} Found`}</Typography>
                                        ) : (
                                            <Box height="68vh" overflow="auto">
                                                <List>
                                                    <ListItem
                                                        button
                                                        onClick={() => fetchUsers('all')}
                                                    >
                                                        <ListItemText>{`All ${currentTabLabel}`}</ListItemText>
                                                    </ListItem>
                                                    {_.map(filteredOrganizationsList, org => (
                                                        <ListItem
                                                            key={org.id}
                                                            classes={{
                                                                container: classes.orgListItem,
                                                            }}
                                                            selected={
                                                                currentOrganization.id === org.id
                                                            }
                                                            button
                                                            onClick={() => fetchUsers(org.id)}
                                                        >
                                                            <ListItemText>{org.name}</ListItemText>
                                                            <ListItemSecondaryAction
                                                                className={
                                                                    classes.orgListItemAction
                                                                }
                                                            >
                                                                <IconButton
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            adminOrgFormActions.openForm(
                                                                                org.id
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    {can('admin_subOrg__edit') && (
                                                                        <EditOutlinedIcon />
                                                                    )}
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        )}
                                    </div>
                                </BlockLoadGroup>
                            </div>
                        </Paper>
                    )}
                </Grid>
                <Grid item xs={8}>
                    <Grid container className={classes.marginBottom}>
                        <Grid item xs={6}>
                            {can(PermissionsMapping.ADMIN_USER__VIEW) && (
                                <SearchInput
                                    placeholder="Search User"
                                    onChange={onChangeUserTextFilter}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {can(PermissionsMapping.ADMIN_USER__VIEW) && (
                        <TableWrapper
                            title={
                                currentOrganization === 'all'
                                    ? `Users: All ${currentTabLabel}`
                                    : `Users: ${currentOrganization.name}`
                            }
                            actions={
                                currentOrganization !== 'all' &&
                                can(PermissionsMapping.ADMIN_SUB_ORG__EDIT) && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                            dispatch(
                                                adminUserFormActions.openForm(
                                                    null,
                                                    currentOrganization.id
                                                )
                                            )
                                        }
                                    >
                                        New User
                                    </Button>
                                )
                            }
                        >
                            <BlockLoadGroup isLoading={isTableLoading}>
                                <div className={classes.tableContainer}>
                                    <Table
                                        size="small"
                                        stickyHeader
                                        className={classes.marginBottom}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox" />
                                                <TableCell>Name</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Role</TableCell>
                                                {currentOrganization === 'all' && (
                                                    <TableCell>Organization</TableCell>
                                                )}
                                                <TableCell padding="checkbox" />
                                                <TableCell padding="checkbox" />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {_.map(filteredUsers, user => (
                                                <TableRow key={user.id}>
                                                    <TableCell />
                                                    <TableCell>{`${user.first_name} ${
                                                        user.last_name
                                                    }`}</TableCell>
                                                    <TableCell>{user.email}</TableCell>
                                                    <TableCell>
                                                        {rolePresentationDictionary[
                                                            user.global_role
                                                        ] || 'Unknown'}
                                                    </TableCell>
                                                    {currentOrganization === 'all' && (
                                                        <TableCell>{user.org.name}</TableCell>
                                                    )}
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                dispatch(
                                                                    adminUserFormActions.openForm(
                                                                        user.id,
                                                                        user.organization
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {can('admin_subOrg__edit') && (
                                                                <EditOutlinedIcon />
                                                            )}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                dispatch(
                                                                    deleteModalActions.open(user.id)
                                                                )
                                                            }
                                                        >
                                                            {can('admin_subOrg__edit') && (
                                                                <DeleteOutlineIcon />
                                                            )}
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {filteredUsers.length === 0 && (
                                        <Typography align="center">No Users Found</Typography>
                                    )}
                                </div>
                            </BlockLoadGroup>
                        </TableWrapper>
                    )}
                </Grid>
            </Grid>
            <DeleteUserModal
                refreshUsers={() => {
                    currentOrganization === 'all'
                        ? fetchUsers('all')
                        : fetchUsers(currentOrganization.id);
                }}
            />
            <AdminUserForm
                refreshUsers={() => {
                    currentOrganization === 'all'
                        ? fetchUsers('all')
                        : fetchUsers(currentOrganization.id);
                }}
            />
            <AdminOrgForm
                refreshOrganization={() =>
                    dispatch(actions.fetchAllOrganizationsByType(currentTab))
                }
            />
        </div>
    );
};

export default connect(selector)(ChildOrganizationDashboard);

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import TextType from './text-type';
import GeoIconType from './geo-icon-type';
import CreativePreviewType from './creative-preview-type';

import { SummaryLayout, SummaryGroup } from 'widgets/summary-view/summary-layout';

const renderDisplayType = item => {
    let displayType;

    // TODO: displayType is no longer a value. How do we decide?

    // switch (field.displayType) {
    //  case 'text':
    //      displayType = <TextType dataOut={field.value} />;
    //  break;
    //  case 'commaSeparated':
    //      displayType = <TextType dataOut={field.value.join(', ')} />;
    //  break;
    //  case 'geoIconType':
    //      displayType = <GeoIconType mainRegion={field.value.mainRegion} excludedRegions={field.value.exclude} />;
    //  break;
    //  case 'creativePreview':
    //      displayType = <CreativePreviewType dataOut={field.value} />;
    //  break;

    // default:
    displayType = <TextType dataOut={item.value} />;
    // }

    return displayType;
};

export default function CampaignSummaryView({ subtitle, groups }) {
    return (
        <SummaryLayout title={subtitle}>
            {groups.map((group, i) => (
                <SummaryGroup key={group.title + i} groupName={group.title}>
                    {group.items.map((item, j) => (
                        <div className="ef3-adSummaryView__item-group" key={item.name + i + j}>
                            <dt className="ef3-adSummaryView_term">{item.name}</dt>
                            {renderDisplayType(item)}
                        </div>
                    ))}
                </SummaryGroup>
            ))}
        </SummaryLayout>
    );
}

import _ from 'lodash';
import { browserHistory } from 'react-router';
import toastr from 'toastr';
import flags from 'containers/flags/service';
import { graphqlRequest } from 'utils/http/redux';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

import querystring from 'utils/querystring';
import BusinessReportActions from 'states/resources/business-reports/actions';

const actions = {
    init(filters) {
        return (dispatch, getState) => {
            dispatch({
                type: 'BUSINESS_REPORT_DASHBOARD__INIT__START',
                payload: {
                    filters,
                },
            });

            return dispatch(actions.fetchPageData(filters)).then(
                () => {
                    dispatch({
                        type: 'BUSINESS_REPORT_DASHBOARD__INIT__END',
                        payload: {},
                    });
                },
                error => {
                    dispatch({
                        type: 'BUSINESS_REPORT_DASHBOARD__INIT__ERROR',
                        payload: {},
                        error,
                    });

                    throw error;
                }
            );
        };
    },
    delete(businessReportId, filters) {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'BUSINESS_REPORT_DASHBOARD__DELETE__START',
                    payload: {
                        businessReportId,
                    },
                });

                const businessReport = await dispatch(
                    BusinessReportActions.delete(businessReportId)
                );

                if (businessReport) {
                    dispatch({
                        type: 'BUSINESS_REPORT_DASHBOARD__DELETE__SUCCESS',
                        payload: {
                            businessReport,
                        },
                    });
                } else {
                    toastr.error(
                        '',
                        `<p>There was an error deleting the business report.</p>`,
                        TOASTR_OPTIONS
                    );
                    dispatch({
                        type: 'BUSINESS_REPORT_DASHBOARD__DELETE__FAILED',
                        payload: {},
                    });
                }
            } catch (error) {
                toastr.error(
                    '',
                    `<p>There was an error deleting the business report.</p>`,
                    TOASTR_OPTIONS
                );
                dispatch({
                    type: 'BUSINESS_REPORT_DASHBOARD__DELETE__ERROR',
                    payload: {},
                    error,
                });
            }

            try {
                await dispatch(actions.fetchPageData(filters));
            } catch (error) {
                dispatch({
                    type: 'BUSINESS_REPORT_DASHBOARD__FETCH_PAGE_DATA__ERROR',
                    payload: {},
                    error,
                });
            }
        };
    },
    fetchPageData(filters) {
        return async (dispatch, getState) => {
            const limit = _.get(getState(), 'businessReportDashboard.itemsPerPage', 25);

            const query = {
                limit,
                page: filters.page || '1',
                search: filters.search,
                type: filters.type,
            };

            dispatch({
                type: 'BUSINESS_REPORT_DASHBOARD__FETCH_PAGE_DATA__START',
                payload: {},
            });

            try {
                const {
                    businessReports,
                    maxResults,
                    allBusinessReportCount,
                    myBusinessReportCount,
                } = await dispatch(actions.getBusinessReports(query));

                return dispatch({
                    type: 'BUSINESS_REPORT_DASHBOARD__FETCH_PAGE_DATA__END',
                    payload: {
                        businessReports,
                        maxResults,
                        page: query.page,
                        allBusinessReportCount,
                        myBusinessReportCount,
                    },
                });
            } catch (error) {
                dispatch({
                    type: 'BUSINESS_REPORT_DASHBOARD__FETCH_PAGE_DATA__START',
                    payload: {},
                    error,
                });

                throw error;
            }
        };
    },
    search(key, value, filters) {
        return (dispatch, getState) => {
            const query = {
                ...filters,
                [key]: value,
                page: 1,
            };
            browserHistory.push(`/reports?${querystring.serialize(query)}`);
        };
    },
    openDeleteConfirmationModal(businessReportId) {
        return (dispatch, getState) => {
            dispatch({
                type: 'BUSINESS_REPORT_DASHBOARD__OPEN_DELETE_CONFIRMATION_MODAL',
                payload: {
                    businessReportId,
                },
            });
        };
    },
    closeDeleteConfirmationModal() {
        return (dispatch, getState) => {
            dispatch({
                type: 'BUSINESS_REPORT_DASHBOARD__CLOSE_DELETE_CONFIRMATION_MODAL',
                payload: {},
            });
        };
    },
    reset() {
        return dispatch => {
            dispatch(actions.init({}));
            dispatch(actions.search('', {}));
        };
    },
    getBusinessReports(query) {
        return async dispatch => {
            try {
                const {
                    data: { businessReports, allBusinessReportCount, myBusinessReportCount },
                } = await dispatch(
                    graphqlRequest({
                        query: `
                        query getBusinessReports($businessReportId: String, $search: String, $type: String, $page: String, $limit: Int) {
                            myBusinessReportCount
                            allBusinessReportCount
                            businessReports(businessReportId: $businessReportId, search: $search, type: $type, page: $page, limit: $limit) {
                                maxResults
                                businessReports {
                                    id
                                    name
                                    organization
                                    timezone
                                    dateRange {
                                        start
                                        end
                                        last
                                        lastFrame
                                        type
                                        from
                                    }
                                    campaigns {
                                        ids
                                        mode
                                    }
                                    campaignStatus
                                    creativeType
                                    iabCategory
                                    client
                                    dimensions
                                    attributes
                                    metrics
                                    engagements
                                    conversions
                                    ignore_filter_by_date_range
                                    showSpendOverdeliveries
                                    reportMode
                                    schemaVersion
                                    schedule {
                                        frequency
                                        date
                                        time
                                        day_of_week {
                                            mon
                                            tue
                                            wed
                                            thu
                                            fri
                                            sat
                                            sun
                                        }
                                        dayOfMonth
                                        recipients
                                    }
                                    scheduledReportTaskId
                                    createdBy
                                    updatedBy
                                    downloadUrl
                                    _deleted
                                    _etag
                                    _created
                                    _updated
                                    createdByFullName
                                }
                            }
                        }
                    `,
                        variables: {
                            ...query,
                        },
                    })
                );

                dispatch({
                    type: 'SYSTEM__BUSINESS_REPORT__FETCH__SUCCESS',
                    payload: {
                        businessReports: businessReports.businessReports,
                    },
                });

                return {
                    businessReports: businessReports.businessReports,
                    maxResults: businessReports.maxResults,
                    allBusinessReportCount,
                    myBusinessReportCount,
                };
            } catch (err) {
                console.error(`GET ALL business reports error`, err);

                throw err;
            }
        };
    },
};

export default actions;

import _ from 'lodash';

export default function selector(storeState, props) {
    const { organization, users, loading } = _.get(storeState, 'adminV2.ownOrganization');

    return {
        organization,
        users,
        loading,
    };
}

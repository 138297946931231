import _ from 'lodash';

export default function indexBy(list, field) {
    return _.map(list)
        .filter(x => x)
        .reduce(
            (acc, item) => ({
                ...acc,
                [item[field]]: item,
            }),
            {}
        );
}

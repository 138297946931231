import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import flags from 'containers/flags/service';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
    getAdvertiserPixel,
    getJavaScriptTag,
    getHtmlTag,
    getPixelUrl,
} from 'states/resources/conversions/business-logic';
import constants from '../../../../../../../config';
import { PanelsLayout, ScrollingSidebarPanel, OverlaySidebarPanel } from 'widgets-v5/panels-layout';
import ClickToCopyButton from 'widgets/click-to-copy-button';
import { TableWrapper } from 'widgets-v6/table';

import selector from './selector';
import { toggleConversionBudgetOptimization } from '../../actions';

const useStyles = makeStyles(theme => ({
    title: {},
    copyButton: {},
    html: {
        whiteSpace: 'pre-wrap',
        backgroundColor: '#FAFAFA',
        fontFamily: "Consolas, Monaco, 'Andale Mono', 'Lucida Console', monospace",
        lineHeight: '1.6em',
        fontSize: 12,
        padding: '0.3em 0.5em 0.3em 0.5em',
        border: '1px solid #E0E0E0',
        borderRadius: 4,
        overflow: 'auto',
        maxHeight: 250,
        position: 'relative',
        lineBreak: 'anywhere',
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    pixelPopoverContainer: {
        padding: theme.spacing(3),
    },
}));

export function IntegratePixelPopover(props) {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState('javascript');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [isCopied, setIsCopied] = useState({
        javascriptTag: false,
        htmlTag: false,
        pixelUrl: false,
    });

    const { textToCopy, reportingName } = props;

    const tabs = [
        {
            label: 'JavaScript Tag',
            value: 'javascript',
        },
        {
            label: 'Other Formats',
            value: 'other',
        },
    ];

    const onChangeTab = (event, value) => {
        setCurrentTab(value);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const destroyCopy = () => {
        setTimeout(() => {
            setIsCopied({
                javascriptTag: false,
                htmlTag: false,
                pixelUrl: false,
            });
        }, 2000);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Button variant="outlined" onClick={handleClick}>
                Integrate Pixel
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box width="400">
                    <Box
                        my={1}
                        mx={2}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">{`Integrate Pixel: ${reportingName}`}</Typography>
                        <IconButton onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <AppBar position="static" color="inherit" elevation={1}>
                        <Tabs
                            variant="fullWidth"
                            value={currentTab}
                            onChange={onChangeTab}
                            indicatorColor="primary"
                            centered
                        >
                            {_.map(tabs, tab => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </Tabs>
                    </AppBar>
                    <div className={classes.pixelPopoverContainer}>
                        {currentTab === 'javascript' ? (
                            <React.Fragment>
                                <Typography gutterBottom>JavaScript Tag</Typography>
                                <pre className={classes.html}>
                                    <code>{textToCopy.javascriptTag}</code>
                                </pre>
                                <Box mt={1} mb={1} className={classes.button}>
                                    <CopyToClipboard
                                        text={textToCopy.javascriptTag}
                                        onCopy={() => {
                                            setIsCopied({
                                                javascriptTag: true,
                                            });
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                destroyCopy();
                                            }}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            {isCopied.javascriptTag
                                                ? 'Copied'
                                                : 'Copy JavaScript Tag'}
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    For Google Tag Manager, copy the JavaScript Tag above and follow{' '}
                                    <a
                                        href="https://support.google.com/tagmanager/answer/6107167?hl=en#CustomHTML"
                                        target="_blank"
                                    >
                                        Google's instructions
                                    </a>{' '}
                                    for creating new <b>Custom HTML Tags</b>
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography gutterBottom>HTML Tag</Typography>
                                <pre className={classes.html}>
                                    <code>{textToCopy.htmlTag}</code>
                                </pre>
                                <Box mt={1} mb={1} className={classes.button}>
                                    <CopyToClipboard
                                        text={textToCopy.htmlTag}
                                        onCopy={() => {
                                            setIsCopied({
                                                htmlTag: true,
                                            });
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                destroyCopy();
                                            }}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            {isCopied.htmlTag ? 'Copied' : 'Copy HTML Tag'}
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    For Google Tag Manager, copy the HTML Tag above and follow{' '}
                                    <a
                                        href="https://support.google.com/tagmanager/answer/6107167?hl=en#CustomHTML"
                                        target="_blank"
                                    >
                                        Google's instructions
                                    </a>{' '}
                                    for creating new <b>Custom HTML Tags</b>
                                </Typography>
                                <Box my={1.5}>
                                    <Divider />
                                </Box>
                                <Typography gutterBottom>Pixel URL</Typography>
                                <pre className={classes.html}>
                                    <code>{textToCopy.pixelUrl}</code>
                                </pre>
                                <Box mt={1} mb={1} className={classes.button}>
                                    <CopyToClipboard
                                        text={textToCopy.pixelUrl}
                                        onCopy={() => {
                                            setIsCopied({
                                                pixelUrl: true,
                                            });
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                destroyCopy();
                                            }}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            {isCopied.pixelUrl ? 'Copied' : 'Copy URL'}
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    For Google Tag Manager, copy the Pixel URL above and follow{' '}
                                    <a
                                        href="https://support.google.com/tagmanager/answer/6107167?hl=en#CustomImage"
                                        target="_blank"
                                    >
                                        Google's instructions
                                    </a>{' '}
                                    for creating new <b>Custom Image Tags</b>
                                </Typography>
                            </React.Fragment>
                        )}
                    </div>
                </Box>
            </Popover>
        </div>
    );
}

function getColumns({ isCampaignCpaOptimized, campaignId }) {
    const columns = [];

    if (isCampaignCpaOptimized) {
        columns.push({
            key: 'optimize_for_budget_allocation',
            getHeaderContent: () => 'Optimize for Budget Allocation',
            getBodyContent: ({ mode, conversion, onToggleConversionBudgetOptimization }) => {
                if (mode === 'view') {
                    return conversion.should_optimize_for_budget_allocation ? 'On' : 'Off';
                } else {
                    return (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={conversion.should_optimize_for_budget_allocation}
                                    onChange={() =>
                                        onToggleConversionBudgetOptimization(conversion)
                                    }
                                    color="primary"
                                />
                            }
                            label={conversion.should_optimize_for_budget_allocation ? 'On' : 'Off'}
                        />
                    );
                }
            },
        });
    }

    columns.push(
        {
            key: 'reporting_name',
            getHeaderContent: () => {
                return 'Name';
            },
            getBodyContent: ({ conversion }) => conversion.reporting_name,
        },
        {
            key: 'advertiser',
            getHeaderContent: () => 'Advertiser',
            getBodyContent: ({ conversion }) => conversion.advertiser.name,
        },
        {
            key: 'attribution_type',
            getHeaderContent: () => 'Attribution Model',
            getBodyContent: ({ conversion }) => (
                <React.Fragment>
                    {conversion.use_click_through && <div>Click-through attribution</div>}
                    {conversion.use_view_through && <div>View-through attribution</div>}
                </React.Fragment>
            ),
        },
        {
            key: 'attribution_window',
            getHeaderContent: () => 'Attribution Window',
            getBodyContent: ({ conversion }) => (
                <React.Fragment>
                    {conversion.use_click_through && (
                        <div>{conversion.click_through_attribution_window} days</div>
                    )}
                    {conversion.use_view_through && (
                        <div>{conversion.view_through_attribution_window} days</div>
                    )}
                </React.Fragment>
            ),
        },
        {
            key: 'dynamic_data',
            getHeaderContent: () => 'Event Parameter',
            getBodyContent: ({ conversion }) => (
                <React.Fragment>
                    <div>
                        {conversion.use_dynamic_data_subevent &&
                            `${conversion.dynamic_data_subevent_name} (parameter)`}
                    </div>
                    <div>
                        {conversion.use_dynamic_data_value &&
                            `${conversion.dynamic_data_value_name} (value)`}
                    </div>
                </React.Fragment>
            ),
        },
        {
            key: 'copy_pixel_url',
            getHeaderContent: () => 'Actions',
            getBodyContent: ({ conversion }) => (
                <React.Fragment>
                    <IntegratePixelPopover
                        textToCopy={{
                            javascriptTag: getJavaScriptTag({ conversion, campaignId }),
                            htmlTag: getHtmlTag({ conversion, campaignId }),
                            pixelUrl: getPixelUrl({ conversion, campaignId }),
                        }}
                        reportingName={conversion.reporting_name}
                    />
                </React.Fragment>
            ),
        }
    );

    return columns;
}

function AdvertiserConversions(props) {
    const {
        onToggleConversionBudgetOptimization,
        isCampaignCpaOptimized,
        conversions,
        mode,
        isLoading,
        campaignId,
    } = props;

    const columns = getColumns({
        isCampaignCpaOptimized,
        campaignId,
    });

    return (
        <React.Fragment>
            <PanelsLayout
                className="ef3-campaign-overview-conversions__panels-layout"
                direction="column"
            >
                <ScrollingSidebarPanel className="ef3-campaign-overview-conversions__scrolling-side-bar-panel">
                    <Box mx={2}>
                        <TableWrapper title="Advertiser Pixels">
                            {isLoading && <LinearProgress />}
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {_.map(columns, column => (
                                            <TableCell key={column.key}>
                                                {column.getHeaderContent()}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(conversions, conversion => (
                                        <TableRow key={conversion.id}>
                                            {_.map(columns, column => (
                                                <TableCell key={column.key}>
                                                    {column.getBodyContent({
                                                        mode,
                                                        conversion,
                                                        onToggleConversionBudgetOptimization,
                                                    })}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableWrapper>
                    </Box>
                </ScrollingSidebarPanel>
            </PanelsLayout>
        </React.Fragment>
    );
}

function mapDispatch(dispatch) {
    return {
        onToggleConversionBudgetOptimization: conversion => {
            return dispatch(toggleConversionBudgetOptimization(conversion));
        },
    };
}

export default connect(
    selector,
    mapDispatch
)(AdvertiserConversions);

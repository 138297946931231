import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.creatives';
export default function creatives(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__CAMPAIGN_CREATIVES__FETCH__SUCCESS: {
            const { creative, creatives } = action.payload;

            const incomingCreatives = []
                .concat(creative)
                .concat(creatives)
                .filter(x => x);
            const creativesResource = mergeResource(state, incomingCreatives);

            const incomingCreativeIds = incomingCreatives.filter(item => item).map(a => a.id);

            incomingCreativeIds.forEach(
                creativeId => (creativesResource[creativeId].isComplete = true)
            );

            return creativesResource;
        }

        case c.OVERVIEW_CREATIVES__CREATIVE_DELETE__SUCCESS:
            return _.omit(state, [action.payload.creativeId]);

        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import flags from 'containers/flags/service';

import { TotalRow } from 'widgets-v6/table';

export default class extends React.Component {
    static propTypes = {
        headerItems: PropTypes.array,
        bodyItems: PropTypes.array,
        isLoading: PropTypes.bool,
        isLiveStats: PropTypes.bool,
    };

    state = {};
    render() {
        return (
            <Paper>
                {this.props.isLoading ? <LinearProgress /> : null}
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {this.props.headerItems.map((item, i) => (
                                <TableCell key={item.name + i}>{item.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TotalRow>
                            {this.props.headerItems.map((item, i) => (
                                <TableCell key={item.value + i}>
                                    {item.name === 'Date' ? (
                                        <span>
                                            {this.props.isLiveStats ? (
                                                <span className="ef3-stats-summary-table__is-live">
                                                    Real-Time Total
                                                    <i className="ef3-stats-summary-table__is-live-icon fa fa-circle-o-notch fa-spin" />
                                                </span>
                                            ) : (
                                                <span> Total </span>
                                            )}
                                        </span>
                                    ) : (
                                        item.value
                                    )}
                                </TableCell>
                            ))}
                        </TotalRow>
                        {this.props.bodyItems.map((rowItem, i) => (
                            <TableRow key={`rowItem-${i}`}>
                                {rowItem.map((cellItem, j) => (
                                    <TableCell key={cellItem.name + i + j}>
                                        {cellItem.value}
                                        {i === 0 && j === 0 && this.props.isLiveStats ? (
                                            <span className="ef3-stats-summary-table__is-live">
                                                Real-Time
                                                <i className="ef3-stats-summary-table__is-live-icon fa fa-circle-o-notch fa-spin" />
                                            </span>
                                        ) : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

import filter from 'lodash/filter';

export function isPlatformDisabled({ platform, field, ads }) {
    let mwebOrDesktopRestrictions = ['target_carriers', 'app_categories', 'iab_categories'];

    const restrictions = {
        inapp: [],
        mweb: mwebOrDesktopRestrictions,
        desktop: mwebOrDesktopRestrictions,
        ctv: [],
    };

    const foundRestriction = restrictions[platform]?.find(restriction => restriction === field);
    if (field === 'start_date') {
        const editableAds = filter(
            ads,
            ad => ad.platform === platform && !ad.isStale && !ad.isDelivering
        );
        return editableAds.length === 0 ? platform : null;
    }
    if (field === 'end_date') {
        const editableAds = filter(ads, ad => ad.platform === platform && !ad.isStale);
        return editableAds.length === 0 ? platform : null;
    }
    return foundRestriction ? platform : null;
}

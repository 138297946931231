import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import CreativeAutoComplete from 'widgets-v5/creative-auto-complete';
import { NeutralButton, ButtonGroup } from 'widgets-v5/buttons';

class MultilineInput extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        inputs: PropTypes.array,
    };

    static defaultProps = {
        inputs: [''],
    };

    updateInput = (val, index) => {
        const renderedInputs = this.props.inputs ? this.props.inputs : [''];
        const { onChange } = this.props;

        if (val === renderedInputs[index] && val !== '') {
            return;
        }

        const newInputs = [...renderedInputs];
        newInputs.splice(index, 1, val);

        onChange(newInputs);
    };

    addInputRow = () => {
        const _inputs = this.props.inputs;
        const inputs = _inputs.length === 0 ? [''] : _inputs;

        this.props.onChange(inputs.concat(''));
    };

    removeInputRow = index => {
        const { inputs, onChange } = this.props;
        const filteredInputs = _.filter(inputs, (v, i) => i !== index);
        onChange(filteredInputs);
    };

    render() {
        const _inputs = this.props.inputs;
        const inputs = _inputs.length === 0 ? [''] : _inputs;

        const buttonLabel = inputs.length === 1 ? 'Clear' : 'Remove';

        return (
            <div className="ef3-multilineInput">
                <ButtonGroup align="right">
                    <NeutralButton
                        icon={<i className="fa fa-plus" />}
                        text={this.props.addText}
                        onClick={this.addInputRow}
                    />
                </ButtonGroup>

                <div className="ef3-thirdPartyPixelsInput_divider" />

                {_.map(inputs, (input, index) => {
                    return (
                        <div className="ef3-multilineInput_inputSection" key={index}>
                            <div className="ef3-multilineInput_inputSectionInput">
                                <CreativeAutoComplete
                                    value={input}
                                    onChange={val => this.updateInput(val, index)}
                                />
                            </div>

                            <NeutralButton
                                text={buttonLabel}
                                onClick={() => this.removeInputRow(index)}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default MultilineInput;

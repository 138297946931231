import _ from 'lodash';

import { validateAppsDraft } from 'services/resources/apps';

const defaultDraftValues = {
    name: '',
    bundle_ids: [],
    domains: [],
    bundleIdInput: '',
    domainInput: '',
    connected_tv_ids: [],
    connectedTVIdsInput: '',
    dooh_board_ids: [],
    doohBoardIdsInput: '',
    advertiserId: '',
};

const initialState = {
    draft: null,
    isLoading: undefined,
    isSyncing: false,
    isNew: undefined,
    viewState: undefined,
    syncError: '',
    errors: [],
    isDraftValid: false,
    showErrors: false,
    isEditorOpen: false,
    isBlacklisted: false,
    isAppListsLoading: true,
    isBlackListLoading: true,
    isPaginationLoading: false,
    advertiserOptions: [],
    filters: {
        advertiserIds: [],
    },
    ownOrganization: null,
};

export const NAME = 'appLists';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'APP_LISTS__FETCH_DATA__START': {
            return {
                ...state,
                appLists: action.payload.data,
                isAppListsLoading: true,
            };
        }
        case 'APP_LISTS__UPDATE_BLACKLISTS__START':
        case 'APP_LISTS__FETCH_BLACKLISTS__START': {
            return {
                ...state,
                loadingId: action.payload.loadingId,
                isBlackListLoading: true,
            };
        }

        case 'APP_LISTS__UPDATE_BLACKLISTS__END':
        case 'APP_LISTS__FETCH_BLACKLISTS__END': {
            return {
                ...state,
                blackList: action.payload.blackList,
                isBlackListLoading: false,
            };
        }

        case 'APP_LISTS__FETCH_DATA__END': {
            return {
                ...state,
                appLists: action.payload.data,
            };
        }

        case 'APP_LISTS__FETCH_APPLISTS__END': {
            return {
                ...state,
                appLists: action.payload.appLists,
                isAppListsLoading: false,
            };
        }

        case 'APP_LISTS__FETCH_APPLIST__END': {
            return {
                ...state,
                appList: action.payload.appList,
                draft: action.payload.draft,
                isLoading: false,
            };
        }

        case 'APP_LISTS__FETCH_ADVERTISERS_SUCESS': {
            const { advertiserOptions, ownOrganization } = action.payload;
            return {
                ...state,
                advertiserOptions,
                ownOrganization,
            };
        }

        case 'APP_LISTS__APPLY_FILTERS': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        case `APP_LISTS__EDITOR__APPLIST__NEW`: {
            const draft_prev = state.draft;

            const draft_next = {
                ...defaultDraftValues,
            };

            let out;
            if (draft_prev !== null) {
            
                out = {
                    ...state,
                    isLoading: false,
                    isBlacklisted: false,
                    viewState: 'inputing',
                    isEditorOpen: true,
                    isNew: true,
                    appListId: ''
                };
            } else {
                out = {
                    ...state,
                    isLoading: false,
                    isBlacklisted: false,
                    isNew: true,
                    viewState: 'inputing',
                    isEditorOpen: true,
                    draft: draft_next,
                };
            }
            return out;
        }

        case `APP_LISTS__EDITOR__APPLIST__EDIT`: {
            return {
                ...state,
                isLoading: true,
                isEditorOpen: true,
                viewState: 'editing',
                errors: [],
                appListId: action.payload.appListId,
                isBlacklisted: action.payload.blackList.includes(action.payload.appListId),
            };
        }

        case `APP_LISTS__EDITOR__APPLIST__TEARDOWN`: {
            return {
                ...state,
                viewState: null,
            };
        }

        case `APP_LISTS__EDITOR__APPLIST__CLOSE`:
        case `APP_LISTS__APPLIST_VIEW__CANCEL`:
        case `APP_LISTS__EDITOR__CANCEL`: {
            return {
                ...state,
                isLoading: false,
                isEditorOpen: false,
                viewState: null,
                appListId: null,
                draft: defaultDraftValues,
            };
        }

        case `APP_LISTS__EDITOR__SHOW_ERRORS`: {
            return {
                ...state,
                showErrors: true,
            };
        }

        case `APP_LISTS__EDITOR__APPLIST__EDIT__INIT`: {
            const draft_next = _.get(action, `payload.draft`, undefined);
            const draft_prev = state.draft;

            const errors = validateAppsDraft(draft_next);

            let out;
            if (draft_prev !== null) {
                out = {
                    ...state,
                    isLoading: false,
                    isNew: false,
                    isDraftValid: errors.length === 0,
                };
            } else {
                out = {
                    ...state,
                    isLoading: false,
                    isNew: false,
                    draft: draft_next,
                    isDraftValid: errors.length === 0,
                };
            }
            return out;
        }

        case `APP_LISTS__EDITOR__APPLIST_DRAFT__UPDATE`: {
            const draft_next = _.get(action, `payload.draft`, undefined);
            const errors = validateAppsDraft(draft_next);

            const out = {
                ...state,
                errors,
                draft: draft_next,
                isDraftValid: errors.length === 0,
            };
            return out;
        }

        case `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC`: {
            const draft_next = _.get(action, `payload.draft`, undefined);
            const errors = validateAppsDraft(draft_next);
            const o = {
                ...state,
                isLoading: true,
                isSyncing: true,
                draft: draft_next,
                errors,
                showErrors: true,
            };
            return o;
        }

        case `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_SUCCESS`: {
            let viewState_next;
            const viewState_prev = state.viewState;
            if (viewState_prev === 'editing') {
                viewState_next = 'inputing';
            }
            if (viewState_prev === 'inputing') {
                viewState_next = 'editing';
            }

            const draft_next = _.get(action, `payload.draft`, undefined);
            const errors = validateAppsDraft(draft_next);

            return {
                ...state,
                isLoading: false,
                isSyncing: false,
                draft: draft_next,
                viewState: viewState_next,
                syncError: '',
                errors,
                isDraftValid: errors.length === 0,
                appList: action.payload.appList,
            };
        }

        case `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_FAIL`: {
            const syncError = _.get(action, `payload.syncError`, undefined);
            return {
                ...state,
                isLoading: false,
                isSyncing: false,
                syncError,
            };
        }

        case `APP_LISTS__EDITOR__CHANGE_PAGE`: {
            return {
                ...state,
                isPaginationLoading: true,
            };
        }

        case `APP_LISTS__EDITOR__CHANGE_PAGE__SUCCESS`: {
            const draft = _.get(action, `payload.draft`, undefined);

            return {
                ...state,
                isPaginationLoading: false,
                draft,
                syncError: '',
                appList: action.payload.appList,
            };
        }

        case `APP_LISTS__EDITOR__CHANGE_PAGE__SYNC_FAIL`: {
            const syncError = _.get(action, `payload.syncError`, undefined);
            return {
                ...state,
                isPaginationLoading: false,
                syncError,
            };
        }

        case `APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT`: {
            return {
                ...state,
                isLoading: true,
                syncError: '',
            };
        }

        case `APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT_SUCCESS`: {
            return {
                ...state,
                isLoading: false,
                isEditorOpen: false,
                showErrors: false,
                appLists: action.payload.appLists,
                draft: defaultDraftValues,
            };
        }

        case `APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT_FAIL`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case `APP_LISTS__EDITOR__BUNDLE_ID__REMOVE`: {
            const draft_next = _.get(action, `payload.draft`, undefined);
            const errors = validateAppsDraft(draft_next);
            return {
                ...state,
                draft: draft_next,
                isDraftValid: errors.length === 0,
                errors,
            };
        }

        case `APP_LISTS__EDITOR__DOMAIN__REMOVE`: {
            const draft_next = _.get(action, `payload.draft`, undefined);
            const errors = validateAppsDraft(draft_next);

            return {
                ...state,
                draft: draft_next,
                isDraftValid: errors.length === 0,
                errors,
            };
        }

        case 'APP_LISTS__EDITOR__CONNECTED_TV__REMOVE':
        case 'APP_LISTS__EDITOR__DOOH_BOARD_ID__REMOVE': {
            const draft_next = _.get(action, `payload.draft`, undefined);
            const errors = validateAppsDraft(draft_next);

            return {
                ...state,
                draft: draft_next,
                isDraftValid: errors.length === 0,
                errors,
            };
        }

        case `APP_LISTS__EDITOR__APPLIST__REMOVE_SUCCESS`: {
            return {
                ...state,
                draft: null,
            };
        }

        case `APP_LISTS__EDITOR__BLACKLIST__CHECKED`: {
            const isBlacklisted_prev = _.get(action, `payload`, undefined);

            return {
                ...state,
                isBlacklisted: !isBlacklisted_prev.isBlacklisted,
            };
        }

        default: {
            return state;
        }
    }
}

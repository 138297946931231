import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import FormField from 'widgets-v5/form-field';
import StandardSelector from 'widgets-v5/standard-selector';
import DateTimePicker from 'widgets/date-time-picker';
import TimeSelector from 'widgets-v5/time-selector';

import dict_timezone from 'common/constants/timezones';

const FREQUENCY_OPTIONS = [
    {
        label: 'None',
        value: 'none',
    },
    {
        label: 'Once',
        value: 'once',
    },
    {
        label: 'Hourly',
        value: 'hourly',
    },
    {
        label: 'Daily',
        value: 'daily',
    },
    {
        label: 'Weekly',
        value: 'weekly',
    },
];

const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const _getTimezoneOptions = () => {
    const timezoneOptions = _(dict_timezone)
        .map(v => v)
        .flatten()
        .map(v => ({
            label: `${v[1]}${v[3] ? ', ' + v[3] : ''} - ${v[2]}`,
            value: `${v[0]}`,
        }))
        .value();
    return timezoneOptions;
};

class ScheduleConfigurator extends React.Component {
    handleFrequencyChange = value => {
        const { onChange, scheduleInfo } = this.props;
        onChange('scheduleInfo')({
            ...scheduleInfo,
            repeat: value,
        });
    };

    handleTimezoneChange = value => {
        const { onChange, scheduleInfo } = this.props;
        onChange('scheduleInfo')({
            ...scheduleInfo,
            timezone: value,
        });
    };

    handleTimeChange = value => {
        const { onChange, scheduleInfo } = this.props;
        onChange('scheduleInfo')({
            ...scheduleInfo,
            atTime: value,
        });
    };

    handleDateChange = value => {
        const { onChange, scheduleInfo } = this.props;
        onChange('scheduleInfo')({
            ...scheduleInfo,
            atDate: value,
        });
    };

    onClickWeekCB = index => {
        const { onChange, scheduleInfo } = this.props;
        onChange('scheduleInfo')({
            ...scheduleInfo,
            weekDays: _.map(scheduleInfo.weekDays, (val, ind) => {
                if (index === ind) {
                    return !val;
                }
                return val;
            }),
        });
    };

    render() {
        const { scheduleInfo } = this.props;

        let visibilities = {};

        switch (scheduleInfo.repeat) {
            case 'once':
                visibilities = {
                    date: true,
                    time: true,
                    week: false,
                    timezone: true,
                };
                break;

            case 'hourly':
                visibilities = {
                    date: false,
                    time: false,
                    week: false,
                    timezone: true,
                };
                break;

            case 'daily':
                visibilities = {
                    date: false,
                    time: true,
                    week: false,
                    timezone: true,
                };
                break;

            case 'weekly':
                visibilities = {
                    date: false,
                    time: true,
                    week: true,
                    timezone: true,
                };
                break;

            default:
                // none, hour or other else choices all false
                visibilities = {
                    date: false,
                    time: false,
                    week: false,
                    timezone: false,
                };
        }

        return (
            <div className="ef3-scheduledReport_scheduleConfigurator">
                <div className="ef3-scheduledReport_scheduleConfigurator_frequency">
                    <FormField label="Frequency">
                        <StandardSelector
                            clearable={false}
                            searchable={false}
                            items={FREQUENCY_OPTIONS}
                            value={scheduleInfo.repeat}
                            onChange={value => this.handleFrequencyChange(value)}
                        />
                    </FormField>
                </div>
                <div className="ef3-scheduledReport_scheduleConfigurator_timePanel">
                    {visibilities.week ? (
                        <div className="ef3-scheduledReport_scheduleConfigurator_timePanel_week">
                            <FormField label="On day(s) of week">
                                {_.map(WEEK_DAYS, (day, index) => {
                                    return (
                                        <label key={index + day}>
                                            <input
                                                onChange={() => this.onClickWeekCB(index)}
                                                type="checkbox"
                                                checked={scheduleInfo.weekDays[index]}
                                            />
                                            <span>{day}</span>
                                        </label>
                                    );
                                })}
                            </FormField>
                        </div>
                    ) : null}

                    <div className="ef3-scheduledReport_scheduleConfigurator_timePanel_dateBlock">
                        {visibilities.date ? (
                            <div className="ef3-scheduledReport_scheduleConfigurator_timePanel_dateBlock_date">
                                <FormField label="At Date">
                                    <DateTimePicker
                                        showToday={true}
                                        readonly={true}
                                        date={scheduleInfo.atDate}
                                        timezone={scheduleInfo.timezone}
                                        onChange={this.handleDateChange}
                                    />
                                </FormField>
                            </div>
                        ) : null}
                        {visibilities.time ? (
                            <div className="ef3-scheduledReport_scheduleConfigurator_timePanel_dateBlock_time">
                                <FormField label="At Time">
                                    <TimeSelector
                                        defaultValue={scheduleInfo.atTime}
                                        interval={30}
                                        onChange={this.handleTimeChange}
                                    />
                                </FormField>
                            </div>
                        ) : null}

                        {visibilities.timezone ? (
                            <div className="ef3-scheduledReport_scheduleConfigurator_timePanel_dateBlock_timezone">
                                <FormField label="Timezone">
                                    <StandardSelector
                                        searchable
                                        value={scheduleInfo.timezone}
                                        disabled={false}
                                        placeholder="Timezone"
                                        items={_getTimezoneOptions()}
                                        onChange={this.handleTimezoneChange}
                                    />
                                </FormField>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default ScheduleConfigurator;

import _ from 'lodash';
import VError from 'verror';

import c from 'common/constants/flux-events';
import Ads from 'states/resources/ads/actions';
import AppLists from 'states/resources/app-lists/actions';
import AudienceActions from 'states/resources/audiences/actions';
import createHttp from 'utils/http';
import notify from 'utils/notify';
const http = createHttp();

export function openAdHistory(adId, campaignId) {
    return {
        type: c.OVERVIEW_ADS__HISTORY__OPEN,
        payload: {
            adId,
            campaignId,
        },
    };
}

export function closeAdHistory() {
    return {
        type: c.OVERVIEW_ADS__HISTORY__CLOSE,
    };
}

export function fetchAdHistory(adId, campaignId) {
    return async (dispatch, getState) => {
        const ad = await dispatch(Ads.get(campaignId, adId));

        const audiences = _.get(ad, 'audiences', []);
        const orgId = _.get(getState(), 'profile.organizationId');

        let audiencesRequest;
        if (audiences.length > 0) {
            const filter = {
                ids: audiences.join(','),
            };
            audiencesRequest = dispatch(AudienceActions.getAll(filter));
        } else {
            audiencesRequest = Promise.resolve();
        }

        const data = await fetchAdHistoryData(orgId);

        dispatch({
            type: 'OVERVIEW_ADS__HISTORY__INIT',
            payload: {
                ...data,
            },
        });

        await Promise.all([
            dispatch(Ads.getHistory(campaignId, adId)),
            audiencesRequest,
            dispatch(AppLists.getAll(orgId)),
        ]);

        dispatch({
            type: 'OVERVIEW_ADS__HISTORY__END',
        });
    };
}

async function fetchAdHistoryData(organizationId) {
    const query = `
        query getAdHistoryData ($organizationId: String){
            organization(id: $organizationId) {
                tech_fee
                fta_partner_id
                isIasPostBidVerificationEnabled
            }
            ftaLocationLists {
                id
                name
            }
        }
    `;

    const variables = {
        organizationId,
    };

    try {
        const data = await http.graphql(query, variables);

        return data;
    } catch (err) {
        notify({
            err: new VError(err, 'Failed to fetch Ad History data'),
        });
    }
}

import _ from 'lodash';
import React, { useEffect } from 'react';
import d3 from 'd3';
import classnames from 'classnames';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { SecondaryButton } from 'widgets-v5/buttons';
import CenterLayout from 'widgets-v5/center-layout';

class DayWeekCell extends React.Component {
    render() {
        const presentation_stats = this.props.presentation_stats;

        const dayWeekIndex_to_dataIndex = function(index_hour, index_weekDay) {
            const _index_hour = +index_hour;
            const _index_weekDay = +index_weekDay;
            return _index_weekDay * 4 + _index_hour;
        };
        const dataIndex = dayWeekIndex_to_dataIndex(
            this.props.index_hour,
            this.props.index_weekDay
        );

        if (presentation_stats.length !== 0) {
            const stat = presentation_stats[dataIndex];

            const statsMetrics = stat.statsMetrics;
            const statsMetrics_numType = stat.statsMetrics_numType;
            const qScale = this.props.opts.qScale;

            const colorScale = qScale(statsMetrics_numType);
            const content = statsMetrics;
            // const className            = (stat.selected) ? ('is-quartile-' + colorScale) : 'is-inactive';
            const className = stat.selected
                ? 'ef4-heatmap-tile__tile ef4-heatmap-tile__tile_quartile-' + colorScale
                : 'ef4-heatmap-tile__tile_inactive';

            return (
                <td
                    className={className}
                    key={dataIndex}
                    data-id={'data-' + dataIndex}
                    data-toggle={content}
                />
            );
        } else {
            return (
                <td className={''} key={dataIndex} data-id={'data-' + dataIndex} data-toggle={''} />
            );
        }
    }
}

function DayWeekRow(props) {
    const presentation_group0 = props.presentation_group0;
    const presentation_group1 = props.presentation_group1;
    const presentation_stats = props.presentation_stats;

    const Component_tableCells = [];

    _.forEach(presentation_group1, item => {
        const index_weekDay = item.name;
        Component_tableCells.push(
            React.createElement(DayWeekCell, {
                key: index_weekDay,
                index_hour: props.index_hour,
                index_weekDay: index_weekDay,
                opts: props.opts,

                presentation_group0: presentation_group0,
                presentation_group1: presentation_group1,
                presentation_stats: presentation_stats,
            })
        );
    });

    return (
        <tr>
            <th className="ef4-heatmap-tile__row-header">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={presentation_group0[props.index_hour].selected}
                            onChange={() => props.handleClick_group0(props.index_hour)}
                            name={props.index_hour}
                            color="primary"
                        />
                    }
                    label={presentation_group0[props.index_hour].presentationName}
                />
            </th>
            {Component_tableCells}
        </tr>
    );
}

class DayWeekBody extends React.Component {
    render() {
        const presentation_group0 = this.props.presentation_group0;
        const presentation_group1 = this.props.presentation_group1;
        const presentation_stats = this.props.presentation_stats;

        const Component_tableRows = [];

        presentation_group0.forEach(function(hour) {
            const index_hour = hour.name;
            Component_tableRows.push(
                React.createElement(DayWeekRow, {
                    key: index_hour,
                    index_hour: index_hour,
                    handleClick_group0: this.props.handleClick_group0,
                    opts: this.props.opts,
                    presentation_group0: presentation_group0,
                    presentation_group1: presentation_group1,
                    presentation_stats: presentation_stats,
                })
            );
        }, this);

        return <tbody>{Component_tableRows}</tbody>;
    }
}

class DayWeekPartsGraph extends React.Component {
    UNSAFE_componentWillMount() {
        this._qScale;
    }

    handleClick_group0 = selected_g0 => {
        this.props.onCheckboxSelected_group0([selected_g0]);
    };

    handleClick_group1 = selected_g1 => {
        this.props.onCheckboxSelected_group1([selected_g1]);
    };

    resetOptions = () => {
        this.props.onReset();
    };

    render() {
        const presentation_group0 = this.props.presentation_group0; // interval
        const presentation_group1 = this.props.presentation_group1; // day_of_week
        const presentation_stats = this.props.presentation_stats;

        // console.log('heatmap props presentation_stats', presentation_stats);

        // Calculating quantile scale for color in heat map
        // We only want to calculate max min value base on the data point that has been selected
        const stats_heatmapSelected = _.filter(presentation_stats, stat_heatmap => {
            return stat_heatmap.selected;
        });
        const statsMinMax_heatmap = d3.extent(_.map(stats_heatmapSelected, 'statsMetrics_numType'));
        this._qScale = d3.scale
            .quantile()
            .domain(statsMinMax_heatmap)
            .range(['1', '2', '3', '4']);
        const opts = { qScale: this._qScale };

        // selection
        const someSelected_hour = _.some(this.props.presentation_group0, { selected: true });
        const someSelected_dayOfWeek = _.some(this.props.presentation_group1, { selected: true });
        const someSelected = someSelected_hour || someSelected_dayOfWeek;

        return (
            <section className={classnames('ef4-heatmap-tile', this.props.className)}>
                {this.props.isLoading && (
                    <div className="ef4-heatmap-tile__loadingIndicator">
                        <CenterLayout>
                            <i className="fa fa-spinner fa-spin fa-2x" />
                        </CenterLayout>
                    </div>
                )}
                <header className="ef4-heatmap-tile__header">
                    <h1 className="ef4-heatmap-tile__title">Day of Week &times; Time of Day</h1>
                    {someSelected && (
                        <SecondaryButton
                            className="ef4-heatmap-tile__reset"
                            text="Reset"
                            onClick={this.resetOptions}
                        />
                    )}
                </header>
                <table className="ef4-heatmap-tile__heatmap">
                    <thead>
                        <tr>
                            <td />
                            {_.map(presentation_group1, (item, index) => (
                                <th key={index} className="ef4-heatmap-tile__column-header">
                                    <label>
                                        <input
                                            className="ef4-heatmap-tile__column-checkbox"
                                            data-selection-group="group_1"
                                            type="checkbox"
                                            name={item.name}
                                            id={item.name}
                                            onChange={() => this.handleClick_group1(item.name)}
                                            checked={item.selected}
                                        />
                                        {item.presentationName}
                                    </label>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <DayWeekBody
                        handleClick={this.handleClick}
                        handleClick_group0={this.handleClick_group0}
                        presentation_group0={presentation_group0}
                        presentation_group1={presentation_group1}
                        presentation_stats={presentation_stats}
                        opts={opts}
                    />
                </table>
            </section>
        );
    }
}

export function DayWeekPartsGraphv2(props) {

    const handleClick_group0 = selected_g0 => {
        props.onCheckboxSelected_group0([selected_g0]);
    };

    const handleClick_group1 = selected_g1 => {
        props.onCheckboxSelected_group1([selected_g1]);
    };

    const resetOptions = () => {
        props.onReset();
    };

    const presentation_group0 = props.presentation_group0; // interval
    const presentation_group1 = props.presentation_group1; // day_of_week
    const presentation_stats = props.presentation_stats;

    // Calculating quantile scale for color in heat map
    // We only want to calculate max min value base on the data point that has been selected
    const stats_heatmapSelected = _.filter(presentation_stats, stat_heatmap => {
        return stat_heatmap.selected;
    });
    const statsMinMax_heatmap = d3.extent(_.map(stats_heatmapSelected, 'statsMetrics_numType'));
    let _qScale;

    useEffect(() => {
        _qScale;
    }, []);

    _qScale = d3.scale
        .quantile()
        .domain(statsMinMax_heatmap)
        .range(['1', '2', '3', '4']);
    const opts = { qScale: _qScale };

    // selection
    const someSelected_hour = _.some(props.presentation_group0, { selected: true });
    const someSelected_dayOfWeek = _.some(props.presentation_group1, { selected: true });
    const someSelected = someSelected_hour || someSelected_dayOfWeek;

    return (
        <div>
            {props.isLoading && (
                <div className="ef4-heatmap-tile__loadingIndicator">
                    <CenterLayout>
                        <i className="fa fa-spinner fa-spin fa-2x" />
                    </CenterLayout>
                </div>
            )}
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">Day of Week &times; Time of Day</Typography>
                {someSelected && (
                    <Button variant="contained" onClick={resetOptions}>
                        Reset
                    </Button>
                )}
            </Box>
            <table className="ef4-heatmap-tile__heatmap">
                <thead>
                    <tr>
                        <td />
                        {_.map(presentation_group1, (item, index) => (
                            <th key={index} className="ef4-heatmap-tile__column-header">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.selected}
                                            onChange={() => handleClick_group1(item.name)}
                                            name={item.name}
                                            color="primary"
                                        />
                                    }
                                    label={item.presentationName}
                                />
                            </th>
                        ))}
                    </tr>
                </thead>
                <DayWeekBody
                    handleClick_group0={handleClick_group0}
                    presentation_group0={presentation_group0}
                    presentation_group1={presentation_group1}
                    presentation_stats={presentation_stats}
                    opts={opts}
                />
            </table>
        </div>
    );
}

export default DayWeekPartsGraph;

import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router';
import { ButtonGroup, PrimaryButton } from 'widgets-v5/buttons';
import { Pagination, PaginationRange } from 'widgets-v5/pagination';
import Card from 'widgets-v5/card';
import StandardInput from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import LoadGroup from 'widgets-v5/load-group';
import TopBarLayout from 'widgets/top-bar-layout';
import GlobalRefresh from 'containers/global-refresh';
import { formatNumber_percentage, formatNumber_whole } from 'utils/formatting';

import flags from 'containers/flags/service';

import config from '../../../../../config';

import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import { SubheadingBold, BodyText } from 'widgets-v5/typography';

class Layout extends React.Component {
    getPoints(poi) {
        let points = 0;
        if (poi.source === 'cleanlist') {
            _.each(poi.cleanlist_entries, entry => {
                points += entry.points;
            });
        } else {
            points = poi.points;
        }
        return points.toLocaleString('en');
    }
    render() {
        return (
            <div className="pois-dashboard">
                <TopBarLayout
                    topBar={
                        <div>
                            <div className="pois-dashboard__page-title">
                                <ButtonGroup className="pois-dashboard__button-group">
                                    {
                                        // <div className="pois-dashboard__refresh-button">
                                        //     <GlobalRefresh
                                        //         heapEvent="poi-dashboard_refresh"
                                        //     />
                                        // </div>
                                    }
                                </ButtonGroup>
                            </div>
                            <div>
                                <StandardInput
                                    className="pois-dashboard__search-input"
                                    placeholder="Search by POI segment name or ID"
                                    heapEvent="poi-dashboard_seach"
                                    value={this.props.filters.search || ''}
                                    onChange={this.props.search}
                                />
                                <PrimaryButton
                                    className="pois-dashboard__new-poi-button"
                                    onClick={this.props.goToNew}
                                    text="+ New Custom POI Segment"
                                    heapEvent="poi-dashboard_new-audience_navigate"
                                />
                            </div>
                        </div>
                    }
                    workspace={
                        <div className="pois-dashboard__wrapper">
                            <LoadGroup isLoading={this.props.isLoading}>
                                <Card padding={0}>
                                    <div>
                                        <ComposableDataTable>
                                            <Row header>
                                                <Cell header>ID</Cell>
                                                <Cell header>
                                                    Name (
                                                    {
                                                        <PaginationRange
                                                            itemsPerPage={this.props.itemsPerPage}
                                                            currentPage={this.props.currentPage}
                                                            maxResults={this.props.maxResults}
                                                        />
                                                    }
                                                    )
                                                </Cell>
                                                <React.Fragment>
                                                    <Cell header> Category </Cell>
                                                    <Cell header align="right">
                                                        Points
                                                    </Cell>
                                                    <Cell header align="right">
                                                        Radius (m)
                                                    </Cell>
                                                </React.Fragment>
                                            </Row>
                                            {this.props.pois.length === 0 ? (
                                                <Row key="noData">
                                                    <Cell colSpan={7} noData>
                                                        No POI segments found
                                                    </Cell>
                                                </Row>
                                            ) : (
                                                _.map(this.props.pois, poi => (
                                                    <Row key={poi._id}>
                                                        <Cell>
                                                            <Link
                                                                className="pois-dashboard__reports-table-link"
                                                                to={`/presets/pois/${poi._id}`}
                                                            >
                                                                #{poi._id}
                                                            </Link>
                                                        </Cell>
                                                        <Cell>
                                                            <Link
                                                                className="pois-dashboard__reports-table-link"
                                                                to={`/presets/pois/${poi._id}`}
                                                            >
                                                                <BodyText type="link">
                                                                    {poi.name}
                                                                </BodyText>
                                                            </Link>
                                                        </Cell>
                                                        <React.Fragment>
                                                            <Cell>
                                                                {' '}
                                                                {_.get(
                                                                    poi,
                                                                    'category.name',
                                                                    ''
                                                                )}{' '}
                                                            </Cell>
                                                            <Cell align="right">
                                                                {this.getPoints(poi)}
                                                            </Cell>
                                                            <Cell align="right">
                                                                {' '}
                                                                {poi.radius.toLocaleString(
                                                                    'en'
                                                                )}{' '}
                                                            </Cell>
                                                        </React.Fragment>
                                                    </Row>
                                                ))
                                            )}
                                        </ComposableDataTable>
                                        <Pagination
                                            heapEvent="poi-dashboard_pagination_navigate"
                                            url="/presets/pois"
                                            query={this.props.filters}
                                            padding={2}
                                            itemsPerPage={this.props.itemsPerPage}
                                            currentPage={this.props.currentPage}
                                            maxResults={this.props.maxResults}
                                        />
                                    </div>
                                </Card>
                            </LoadGroup>
                        </div>
                    }
                />
            </div>
        );
    }
}

export default Layout;

import get from 'lodash/get';
import http from 'utils/http';
/** import third-party libraries in the section above, keep the ascending order */

import flags from 'containers/flags/service';
import { getEnvironmentSettings } from 'services/environment';
import { showEstimateData } from 'states/resources/inventory-predictions/business-logic';
/** import classes and others in the section above, keep the ascending order */

const queryInventoryPrediction = async query => {
    const BAD_REQUEST = 400;
    const MAX_RETRIES = 0;
    const URL_PATH = 'inventory-predictions';

    let lastError;

    for (let retryCount = 0; retryCount <= MAX_RETRIES; retryCount++) {
        try {
            const response = await http().post(URL_PATH, query);
            return response; // If successful, return the response immediately
        } catch (err) {
            if (err?.code === BAD_REQUEST) {
                throw err;
            }
            lastError = err;
        }
    }

    console.error('GET inventory prediction error', lastError);
    throw lastError;
};

const actions = {
    fetch(query) {
        return () => {
            return queryInventoryPrediction(query);
        };
    },
    validatePredictionForm(variables) {
        return async (dispatch, getState) => {
            const validations = {
                daysRunning: {
                    error: 'Set days running to see forecast (at least 1 day is required)',
                    isValid: daysRunning => parseInt(daysRunning) > 0,
                },
                'custom.creativeFormat': {
                    error: 'Select a creative format to see forecast',
                    isValid: () => variables.creativeFormat,
                },
                creativeSize: {
                    error: 'Select a creative size to see forecast',
                    isValid: creativeSize => creativeSize,
                },
                'custom.creativeType': {
                    error: 'Select a creative type to see forecast',
                    isValid: () => variables.creativeType && variables.creativeFormat,
                },
                geotargets: {
                    error: 'Select a country to see forecast',
                    isValid: geotargets => geotargets.length > 0,
                },
                'custom.audiences': {
                    error: 'Forecast not supported for custom segments',
                    isValid: () => {
                        const allAudiences = _.get(getState(), 'resources.audiences', {});

                        const isUsingNonPdsIncludeSegments = _(variables.audiences)
                            .map(
                                audienceId =>
                                    allAudiences[audienceId] && allAudiences[audienceId].attr
                            )
                            .some(audience => audience.source_type.indexOf('pds_') === -1);

                        const isUsingNonPdsExcludeSegments = _(variables.audience_exclude)
                            .map(
                                audienceId =>
                                    allAudiences[audienceId] && allAudiences[audienceId].attr
                            )
                            .some(audience => audience.source_type.indexOf('pds_') === -1);

                        return !isUsingNonPdsIncludeSegments && !isUsingNonPdsExcludeSegments;
                    },
                },
                max_cpm_rate_local: {
                    error: 'Set Total Bid Price to see forecast',
                    isValid: max_cpm_rate_local =>
                        max_cpm_rate_local && parseFloat(max_cpm_rate_local) > 0,
                },
                bidRate: {
                    error: 'Adjust the Net Bid Price, it should be greater than zero',
                    isValid: bidRate => bidRate && parseFloat(bidRate) > 0,
                },
                'fcaps.imp': {
                    error: 'Set Frequency cap to see forecast',
                    isValid: imp => imp,
                },
                'custom.fcaps.interval_unit_1': {
                    error: 'Forecast not supported for hourly frequency cap',
                    isValid: () => variables.fcaps.interval_unit !== 'hours',
                },
                'custom.fcaps.interval_unit_2': {
                    error: 'Forecast not supported for weekly frequency cap',
                    isValid: () => variables.fcaps.interval_unit !== 'weeks',
                },
                'custom.fcaps.interval_unit_3': {
                    error: 'Forecast not supported for frequency caps based on ad lifetime',
                    isValid: () => variables.fcaps.interval_unit !== 'ad',
                },
            };

            for (const [key, value] of Object.entries(validations)) {
                if (!value.isValid(get(variables, key))) {
                    dispatch({
                        type: `INVENTORY_PREDICTIONS__GENERATE_PREDICTION__FAILED`,
                        payload: { error: value.error },
                    });
                    return false;
                }
            }

            return true;
        };
    },
    updatePrediction(variables, daysRunning) {
        return async dispatch => {
            dispatch({
                type: 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION',
                payload: {},
            });
            const envSettings = getEnvironmentSettings();

            const requestPayload = {
                geo_cregion_include: _(variables.geotargets)
                    .map(target => target.include)
                    .flatten()
                    .filter(includedTarget => !includedTarget.city && !includedTarget.fsa)
                    .map(includedTarget => includedTarget.region)
                    .value(),
                geo_cregion_exclude: _(variables.geotargets)
                    .map(target => target.exclude)
                    .flatten()
                    .filter(includedTarget => !includedTarget.city && !includedTarget.fsa)
                    .map(includedTarget => includedTarget.region)
                    .value(),
                geo_city_include: _(variables.geotargets)
                    .map(target => target.include)
                    .flatten()
                    .filter(includedTarget => includedTarget.city)
                    .map(includedTarget => includedTarget.city)
                    .value(),
                geo_city_exclude: _(variables.geotargets)
                    .map(target => target.exclude)
                    .flatten()
                    .filter(includedTarget => includedTarget.city)
                    .map(includedTarget => includedTarget.city)
                    .value(),
                geo_zip_include: _(variables.geotargets)
                    .map(target => target.include)
                    .flatten()
                    .filter(includedTarget => includedTarget.fsa)
                    .map(includedTarget => includedTarget.fsa)
                    .value(),
                geo_zip_exclude: _(variables.geotargets)
                    .map(target => target.exclude)
                    .flatten()
                    .filter(includedTarget => includedTarget.fsa)
                    .map(includedTarget => includedTarget.fsa)
                    .value(),
                creative_size: variables.creativeSize,
                creative_format: variables.creativeFormat,
                creative_type: variables.creativeType,
                age_groups: variables.target_age_groups,
                genders: variables.target_genders,
                device_os: variables.target_device_os,
                frequency_cap: variables.fcaps.imp,
                bid_rate: variables.max_cpm_rate_local,
                ttype: variables.platforms,
                target_device_language: variables.target_device_language,
                target_device_language_exclude: variables.target_device_language_exclude,
                whitelistedAppsAndSites: variables.whitelistedAppsAndSites,
                blacklistedAppsAndSites: variables.blacklistedAppsAndSites,
                target_video_skippable: parseInt(variables.target_video_skippable),
                placements: variables.placements,
                positions: variables.positions,
                currency: envSettings.getInventoryForecastCurrency(),
            };

            // This is to maintain backwards compatibility.
            if (!flags.isEnabled('en_4869_inventory_predictor_v4')) {
                requestPayload['creative_format'] = variables.creativeType;
                requestPayload['creative_type'] = variables.creativeFormat;
            }

            requestPayload.bid_rate = variables.bidRate;
            requestPayload.pattison_billboards = variables.pattisonBillboards;

            requestPayload.tactics_generators = variables.tactics_generators;
            requestPayload.days_running = daysRunning;

            try {
                // request 1
                // ctr off will include all possible inventory
                const promiseMax = queryInventoryPrediction({
                    ...requestPayload,
                    ctr: [],
                });

                // request 2
                // ctr on will only include average pool of inventory
                // for video and native, we know it is going to return 0 and won't be used in the UI,
                // so we ignore and don't send a HTTP request to save resources
                const promiseMin = !showEstimateData(variables.creativeType)
                    ? Promise.resolve({ unique_users: 0, available_impressions: 0 })
                    : queryInventoryPrediction({
                          ...requestPayload,
                          ctr: [1],
                      });

                const [requestMax, requestMin] = await Promise.all([promiseMax, promiseMin]);

                if (flags.isEnabled('en_4869_inventory_predictor_v4')) {
                    dispatch({
                        type: 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION__COMPLETE',
                        payload: {
                            dailyUniqueUsers: requestMin.unique_users,
                            dailyAvailableImpressions: requestMin.available_impressions,

                            totalUniqueUsersMin: requestMin.unique_users,
                            totalAvailableImpressionsMin: requestMin.available_impressions,

                            totalUniqueUsersMax: requestMax.unique_users,
                            totalAvailableImpressionsMax: requestMax.available_impressions,
                        },
                    });
                    return;
                }

                dispatch({
                    type: 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION__COMPLETE',
                    payload: {
                        dailyUniqueUsers: requestMin.dailyUniqueUsers,
                        dailyAvailableImpressions:
                            requestMin.dailyAvailableImpressions * daysRunning,

                        totalUniqueUsersMin: requestMin.dailyUniqueUsers,
                        totalAvailableImpressionsMin:
                            requestMin.dailyAvailableImpressions * daysRunning,

                        totalUniqueUsersMax: requestMax.dailyUniqueUsers,
                        totalAvailableImpressionsMax:
                            requestMax.dailyAvailableImpressions * daysRunning,
                    },
                });
            } catch (err) {
                let userFacingError = 'An unknown error occured.';

                if (err?.body && err?.body[0] === 'SyntaxError') {
                    userFacingError = err.body[1];
                }

                if (err?.body && err?.body[0]?.field && err?.body[0]?.message) {
                    userFacingError = err.body[0].message;
                }

                dispatch({
                    type: 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION__FAILED',
                    payload: {
                        error: userFacingError,
                    },
                });
            }
        };
    },
};

export default actions;

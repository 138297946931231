import _ from 'lodash';
import toastr from 'toastr';

import c from 'common/constants/flux-events';
import { serializeForApi } from 'forms/creative-form/services';

import Campaigns from 'states/resources/campaigns/actions';
import Creatives from 'states/resources/creatives/actions';
import Files from 'states/resources/files/actions';

import { defaultSetting } from 'forms/creative-form/services';

export const CreativeEdit = {
    getInitialDraft(campaignId, creativeId) {
        return (dispatch, getState) => {
            return dispatch(Campaigns.get(campaignId))
                .then(() => {
                    return Promise.all([
                        dispatch(Files.getAll(campaignId)),
                        dispatch(Creatives.get(campaignId, creativeId)),
                    ]);
                })
                .then(() => {
                    const creative = _.get(getState(), `resources.creatives.${creativeId}.attr`);

                    return {
                        ...defaultSetting.defaultValues.initial,
                        ...defaultSetting.defaultValues[creative.format],
                        ...creative,
                    };
                });
        };
    },
    open(campaignId, creativeId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__INIT_STATE,
                payload: { campaignId, creativeId },
            });
            dispatch({
                type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__OPEN,
                payload: { campaignId, creativeId },
            });
            console.info('--- Begin fetching creative form data for: ', campaignId, creativeId);

            dispatch(Campaigns.get(campaignId))
                .then(() => {
                    return Promise.all([
                        dispatch(Files.getAll(campaignId)),
                        dispatch(Creatives.get(campaignId, creativeId)),
                    ]);
                })
                .then(
                    () => {
                        const resource = _.get(getState(), `resources.creatives.${creativeId}`);
                        const attr = _.get(resource, 'attr');
                        const creativeformat = attr.format;

                        // Different creative format has different field, so we set up the draft accordingly.
                        const manifest_creativeFormat = _.get(
                            defaultSetting,
                            `manifest.${creativeformat}`,
                            []
                        );
                        const manifest = [...manifest_creativeFormat];
                        const attrPicked_default = _.pick(defaultSetting.defaultValues, manifest);
                        const attrPicked_resource = _.pick(attr, manifest);

                        const creativeAttr_form = {
                            ...attrPicked_default,
                            ...attrPicked_resource,
                        };

                        dispatch({
                            type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__INIT,
                            payload: {
                                campaignId,
                                creativeId,
                                creative: creativeAttr_form,
                            },
                        });
                    },
                    error => {
                        dispatch({
                            type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__INIT_FAIL,
                            payload: { campaignId, creativeId },
                            error: error.body,
                        });
                    }
                )
                .catch(reason => {
                    throw new Error(reason);
                });
        };
    },

    submitDraft(draft, campaignId, creativeId) {
        return (dispatch) => {
            const payloadSerialized = serializeForApi(draft);

            dispatch({
                type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT,
                payload: { campaignId, payloadSerialized },
            });

            return dispatch(Creatives.update(campaignId, creativeId, payloadSerialized)).then(
                () => {
                    dispatch({
                        type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_SUCCESS,
                        payload: { creativeId, campaignId },
                    });

                    dispatch({
                        type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__CLOSE,
                        payload: { campaignId, creativeId },
                    });
                },
                error => {
                    // throw toaster error for server errors
                    dispatch({
                        type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_FAIL,
                        error: error.body,
                        payload: {
                            campaignId,
                            creativeId,
                        },
                    });

                    return Promise.reject(error.body);
                }
            );
        };
    },

    submitDraftAndAudit(draft, campaignId, creativeId) {
        return (dispatch, getState) => {
            const isSaveAudit = true;
            return CreativeEdit.submitDraft(draft, campaignId, creativeId, isSaveAudit)(
                dispatch,
                getState
            );
        };
    },

    //@TODO This should not belong here as it has nothing to do with draft. To move to its appropreate place
    submitFromCreativeOverview(campaignId, creativeId) {
        return (dispatch, getState) => {
            const payload = { audit_status: 'pending' };

            dispatch({
                type: c.OVERVIEW__CREATIVE_AUDIT__UPDATE,
                payload: { creativeId, campaignId, payload },
            });

            const creativeName = _.get(getState(), `resources.creatives.${creativeId}.attr.name`);

            return dispatch(Creatives.update(campaignId, creativeId, payload)).then(
                () => {
                    dispatch({
                        type: c.OVERVIEW__CREATIVE_AUDIT__UPDATE_SUCCESS,
                        payload: { creativeId, campaignId },
                    });
                },
                error => {
                    let message = '';
                    _.each(error.body, err => {
                        const field = err.field
                            .split('_')
                            .map(word => _.capitalize(word))
                            .join(' ');
                        message += `<div>${field} ${err.message}</div>`;
                    });
                    toastr.error(message, `#${creativeId} ${creativeName}`, {
                        closeButton: true,
                        timeOut: 15000,
                    });

                    dispatch({
                        type: c.OVERVIEW__CREATIVE_AUDIT__UPDATE_FAIL,
                        payload: error.body,
                    });
                }
            );
        };
    },

    close(campaignId, creativeId) {
        return {
            type: c.OVERVIEW__CREATIVE_DRAFT_EDIT__CLOSE,
            payload: { campaignId, creativeId },
        };
    },
};

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { can } from 'services/can';
import { initProfile, updateSubscriptionPreferences } from './actions';
import selector from './selector';
import { logout } from 'containers/sidebar/actions';
import { PermissionsMapping } from 'common/constants/role-intents';

import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
    checkbox: {
        padding: 0,
    },
}));

class Profile extends React.Component {
    UNSAFE_componentWillMount() {
        const { orgId, dispatch } = this.props;
        dispatch(initProfile(orgId));
    }

    handleLogout = e => {
        if (e) {
            e.preventDefault();
        }

        this.props.dispatch(logout());
    };

    handleSubscribeToEmailChange = e => {
        const { dispatch } = this.props;
        const shouldSubscribeToEmail = e.target.checked;

        dispatch(updateSubscriptionPreferences({ shouldSubscribeToEmail }));
    };

    render() {
        const {
            user,
            organizations,
            ownOrganization,
            isUpdatingSubscriptionPreferences,
        } = this.props;

        return (
            <ProfileLayout
                user={user}
                onLogout={this.handleLogout}
                organizations={organizations}
                ownOrganization={ownOrganization}
                workspace={this.props.workspace}
                onSubscribeToEmailChange={this.handleSubscribeToEmailChange}
                isUpdatingSubscriptionPreferences={isUpdatingSubscriptionPreferences}
            />
        );
    }
}

const ProfileLayout = props => {
    const classes = useStyles();
    if (props.workspace) {
        return props.workspace;
    }

    if (!props.user) {
        return <div>...loading</div>;
    }

    const userAttr = props.user.attr;
    const filteredUserAttr = _.pick(userAttr, ['email']);

    // note lodash get? and put this in the selector
    const userOrganization = props.ownOrganization;

    if (!_.get(userOrganization, 'attr')) {
        return <div>...loading</div>;
    }

    const organizationName = userOrganization.attr.name;
    const organizationId = userOrganization.attr.id;

    return (
        <div className="ef3-profile">
            <Typography variant="h4">User Profile</Typography>
            <div className="ef3-profile_table">
                {_.map(filteredUserAttr, (value, key) => (
                    <div key={key} className="ef3-profile_tableItem">
                        <div className="ef3-profile_tableItem_label">{key}</div>
                        <div className="ef3-profile_tableItem_value">
                            {value === false ? 'No' : value}
                        </div>
                    </div>
                ))}

                <div className="ef3-profile_tableItem">
                    <div className="ef3-profile_tableItem_label">Organization</div>
                    <div className="ef3-profile_tableItem_value">{organizationName}</div>
                </div>

                <div className="ef3-profile_tableItem">
                    <div className="ef3-profile_tableItem_label">Organization ID</div>
                    <div className="ef3-profile_tableItem_value">{organizationId}</div>
                </div>

                <div className="ef3-profile_tableItem">
                    <div className="ef3-profile_tableItem_label">Suspended</div>
                    <div className="ef3-profile_tableItem_value">
                        {userAttr.suspended ? 'Yes' : 'No'}
                    </div>
                </div>

                <div className="ef3-profile_tableItem">
                    <div className="ef3-profile_tableItem_label">Dashboard Version</div>
                    <div className="ef3-profile_tableItem_value">
                        <span className="ef3-profile_versionNumber">{process.env.VERSION}</span>
                    </div>
                </div>

                {can(PermissionsMapping.CAMPAIGN__VIEW) && (
                    <div className="ef3-profile_tableItem">
                        <div className="ef3-profile_tableItem_label">
                            Subscribe to notifications for underpacing ads
                        </div>
                        <div className="ef3-profile_tableItem_value">
                            <Checkbox
                                checked={userAttr.subscribedToEmailNotifications}
                                onChange={props.onSubscribeToEmailChange}
                                color="primary"
                                className={classes.checkbox}
                                disabled={props.isUpdatingSubscriptionPreferences}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Button onClick={props.onLogout} color="primary" variant="contained">
                Log Out
            </Button>
        </div>
    );
};

export default connect(selector)(Profile);

import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Toolbar from '@mui/material/Toolbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { formatNumber_currency, formatNumber_percentage } from 'utils/formatting';

import { RevenueModelMapping } from 'states/resources/campaigns/business-logic';
import shallowCompare from 'utils/shallow-compare';
import { ButtonGroup, NeutralButton } from 'widgets-v5/buttons';
import InlineTooltip from 'widgets-v5/inline-tooltip';

class CampaignBudgetOverview extends React.Component {
    static propTypes = {
        campaign: PropTypes.object.isRequired,
    };

    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    handleArrowClickLeft = () => {
        const wrapper = $('.media-plan-form__overview-table');
        wrapper.animate({
            scrollLeft: wrapper.scrollLeft() - 500,
        });
    };

    handleArrowClickRight = () => {
        const wrapper = $('.media-plan-form__overview-table');
        wrapper.animate({
            scrollLeft: wrapper.scrollLeft() + 500,
        });
    };

    render() {
        const { campaign, columns, toggleColumn } = this.props;

        const {
            revenueModel,
            currency,
            total_billings_local,
            billing_rate,
            campaign_max_total_spend_local,
        } = campaign;

        let label;

        if (revenueModel === RevenueModelMapping.Disabled) {
            label = (
                <div className="media-plan-form__campaign-overview-tab-label">
                    <span className="media-plan-form__campaign-overview-tab-bold-label">
                        Revenue Model:{' '}
                    </span>
                    Disabled
                </div>
            );
        } else if (revenueModel === RevenueModelMapping.CPMCPC) {
            label = (
                <div className="media-plan-form__campaign-overview-tab-label">
                    <span className="media-plan-form__campaign-overview-tab-bold-label">
                        Revenue Model:{' '}
                    </span>
                    CPM / CPC
                </div>
            );
        } else if (revenueModel === RevenueModelMapping.CPM) {
            label = (
                <div className="media-plan-form__campaign-overview-tab-label">
                    <span className="media-plan-form__campaign-overview-tab-bold-label">
                        Revenue Model:{' '}
                    </span>
                    CPM
                </div>
            );
        } else if (revenueModel === RevenueModelMapping.TotalSpendMarkup) {
            label = (
                <div className="media-plan-form__campaign-overview-tab-labels">
                    <div className="media-plan-form__campaign-overview-tab-label">
                        <span className="media-plan-form__campaign-overview-tab-bold-label">
                            Revenue Model:{' '}
                        </span>
                        Total Cost Markup
                    </div>
                    <div className="media-plan-form__campaign-overview-tab-label">
                        <span className="media-plan-form__campaign-overview-tab-bold-label">
                            Total Cost Budget:{' '}
                        </span>
                        ${currency} {formatNumber_currency(campaign_max_total_spend_local)}
                    </div>
                </div>
            );
        } else if (revenueModel === RevenueModelMapping.AgencyMargin) {
            label = (
                <div className="media-plan-form__campaign-overview-tab-labels">
                    <div className="media-plan-form__campaign-overview-tab-label">
                        <span className="media-plan-form__campaign-overview-tab-bold-label">
                            Revenue Model:{' '}
                        </span>
                        Agency Margin
                    </div>
                    <div className="media-plan-form__campaign-overview-tab-label">
                        <span className="media-plan-form__campaign-overview-tab-bold-label">
                            Agency Margin:{' '}
                        </span>
                        {formatNumber_percentage(billing_rate)}
                    </div>
                    <div className="media-plan-form__campaign-overview-tab-label">
                        <span className="media-plan-form__campaign-overview-tab-bold-label">
                            Revenue Budget:{' '}
                        </span>
                        {currency} {formatNumber_currency(total_billings_local)}
                    </div>
                </div>
            );
        }

        return (
            <OverviewToolBar
                leftSide={label}
                rightSide={
                    <React.Fragment>
                        <ScrollButtons
                            leftButtonClick={this.handleArrowClickLeft}
                            rightButtonClick={this.handleArrowClickRight}
                        />
                        <ColumnsDropdown columns={columns} onClick={toggleColumn} />
                    </React.Fragment>
                }
            />
        );
    }
}

const useStyles = makeStyles({
    menu: {
        top: 450,
    },
    columns: {
        height: 300,
        overflow: 'auto',
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    rightSide: {
        display: 'flex',
        alignItems: 'center',
    },
    dropdownLabel: {
        padding: 10,
    },
});

function OverviewToolBar(props) {
    const classes = useStyles();

    return (
        <Toolbar className={classes.toolbar}>
            <div>{props.leftSide}</div>
            <div className={classes.rightSide}>{props.rightSide}</div>
        </Toolbar>
    );
}

function ScrollButtons(props) {
    return (
        <ButtonGroup align="right">
            <InlineTooltip
                tooltip="Scroll Left"
                position="bottom"
                className="media-plan-form__campaign-overview-tab-scroll-button-tooltip"
            >
                <NeutralButton
                    className="media-plan-form__campaign-overview-tab-scroll-button"
                    onClick={props.leftButtonClick}
                    icon={<i className="fa fa-angle-left" />}
                />
            </InlineTooltip>
            <InlineTooltip
                tooltip="Scroll Right"
                position="bottom"
                className="media-plan-form__campaign-overview-tab-scroll-button-tooltip"
            >
                <NeutralButton
                    onClick={props.rightButtonClick}
                    icon={<i className="fa fa-angle-right" />}
                />
            </InlineTooltip>
        </ButtonGroup>
    );
}

function ColumnsDropdown(props) {
    const classes = useStyles();

    const [isMenuOpen, setOpenMenu] = React.useState(false);
    const open = Boolean(isMenuOpen);

    const openMenu = event => {
        setOpenMenu(event.currentTarget);
    };

    const handleClose = () => {
        setOpenMenu(false);
    };

    const columnsButtonIcon = isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;

    return (
        <div style={{ position: 'relative' }}>
            <Button size="small" color="inherit" endIcon={columnsButtonIcon} onClick={openMenu}>
                Columns
            </Button>
            <Menu
                anchorEl={isMenuOpen}
                id="long-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.menu }}
            >
                <div className={classes.dropdownLabel}>Show Columns</div>
                <div className={classes.columns}>
                    {props.columns.map(column => (
                        <MenuItem key={column.name} onClick={() => props.onClick(column.name)}>
                            <Checkbox checked={!column.hide} value="checkedA" color="primary" />
                            {column.label}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </div>
    );
}

export default CampaignBudgetOverview;

import _ from 'lodash';
import { combineReducers } from 'redux';

import c from 'common/constants/flux-events';

const initialState = {
    campaignId: undefined,
    isLoading: false,
    isOpen: false,
};

export const NAME = 'campaignHistory';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.CAMPAIGN_DASHBOARD__HISTORY__OPEN:
            const campaignId = action.payload.campaignId;
            return {
                ...initialState,
                isLoading: false,
                isOpen: true,
                campaignId,
            };

        case c.CAMPAIGN_DASHBOARD__HISTORY__CLOSE:
            return {
                ...initialState,
            };

        case c.CAMPAIGN_DASHBOARD__HISTORY__FETCH:
            return {
                ...state,
                isLoading: true,
            };

        case c.CAMPAIGN_DASHBOARD__HISTORY__FETCH__SUCCESS:
            const { organizations, ftaLocationLists } = action.payload;
            return {
                ...state,
                isLoading: false,
                organizations,
                ftaLocationLists,
            };

        case c.CAMPAIGN_DASHBOARD__HISTORY__FETCH__FAIL:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}

import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import { getWeekparting } from 'containers/ad-summary';
import { ScheduleWeekparts } from 'forms/ad-form/modules/schedule';

import shallowCompare from 'utils/shallow-compare';
import Typography from '@mui/material/Typography';

export const WeekpartsForm = props => {
    const { ad, ads, campaign, onSave } = props;
    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Weekparts"
            fields={['weekparts', 'weekparts_local']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <React.Fragment>
                        <ScheduleWeekparts
                            formData={draft}
                            onFieldChange={field => value => updateDraft({ [field]: value })}
                            errors={errors}
                            showErrors={shouldShowErrors}
                        />
                        <Typography color="textSecondary">
                            Weekparts can only be set in the UTC timezone.
                        </Typography>
                    </React.Fragment>
                );
            }}
        </BaseColumnForm>
    );
};

export class WeekpartsSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;

        const weekpartValue = {
            field: _.isArray(ad.weekparts) ? 'weekparts' : 'weekparts_local',
            value: _.isArray(ad.weekparts) ? ad.weekparts : ad.weekparts_local,
        };

        const weekparts = getWeekparting(weekpartValue);

        return (
            <div>
                {_.map(weekparts, weekpart => (
                    <Typography>{weekpart}</Typography>
                ))}
            </div>
        );
    }
}

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import FormField from 'widgets-v5/form-field';
import StandardInput from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import GoogleMapsApiLoader from 'containers/google-maps-api-loader';
import GeofenceEditor from './modules/geofencing-map-editor';
import { GlobalModal } from 'widgets-v5/modal';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';

import { poiEditor as Action_poiEditor } from 'containers/poi-editor/actions';

const mapStateToProps = (state, ownProps) => {
    const { adId, campaignId } = ownProps;
    const ad = _.get(state, `resources.ads.${adId}`);
    const geo_targeting_settings = _.get(state, `attr.geo_targeting_settings`, void 0);

    const geoResources = _.get(state, `resources.geoCategories`);

    const points = _.get(state, `poiEditor.draft.points`, []);
    const name = _.get(state, `poiEditor.draft.name`, void 0);
    const radius = _.get(state, `poiEditor.draft.radius`, '').toString();
    const isOpen = _.get(state, `poiEditor.isOpen`, []);

    const isLoading = _.get(state, `poiEditor.isLoading`, []);

    const o = {
        ...ownProps,
        isOpen,
        isLoading,
        campaignId,
        adId,
        ad,
        geoResources,
        geo_targeting_settings,
        pois: points,
        name,
        radius,
        draftErrors: _.get(state, `poiEditor.draftErrors`, void 0),
        showError: _.get(state, `poiEditor.showError`, void 0),
    };
    return o;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch__open: () => {
            dispatch(
                Action_poiEditor.open(
                    ownProps.campaignId,
                    ownProps.adId,
                    ownProps.geoEntity,
                    ownProps.geoLayerId
                )
            );
        },
        dispatch__close: () => {
            dispatch(Action_poiEditor.close());
        },
        dispatch__poi_add: poi => {
            dispatch(Action_poiEditor.addPoi(poi));
        },
        dispatch__poi_remove: id_poi => {
            dispatch(Action_poiEditor.removePoiById(id_poi));
        },

        dispatch__fieldUpdate: (fieldName, value) => {
            dispatch(Action_poiEditor.updateDraft(fieldName, value));
        },

        dispatch__save: () => {
            const done = layerId => {
                if (ownProps.createLayerDone) {
                    ownProps.createLayerDone(layerId);
                }
            };
            dispatch(Action_poiEditor.saveDraft(done));
        },
    };
};

// ========
//  Main
// ========
const PoisEditor = class PoisEditor extends React.Component {
    static refresh() {}

    constructor(props) {
        super(props);
        this.toggleSearchWithinMapBounds = this.toggleSearchWithinMapBounds.bind(this);
        this.handle_cancel = this.handle_cancel.bind(this);
        this.state = { searchWithinMapBounds: true };
    }

    init() {
        this.props.dispatch__open();
    }

    componentDidMount() {
        this.init();
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.isOpen === true && nextProps.isOpen === false) {
            this.props.closeModal();
        }
    }

    toggleSearchWithinMapBounds() {
        this.setState({ searchWithinMapBounds: !this.state.searchWithinMapBounds });
    }

    handle_cancel() {
        this.props.dispatch__close();
        this.props.closeModal();
    }

    render() {
        const { geoEntity, adId, isLoading, radius } = this.props;

        let implicitLayerName;
        if (adId) {
            implicitLayerName = `Custom Layer (Ad #${adId})`;
        } else {
            implicitLayerName = `Custom Layer (New Ad)`;
        }

        const geoLayerType = _.get(geoEntity, `type`, void 0);

        const mapConf = { searchWithinMapBounds: this.state.searchWithinMapBounds };
        const errorMsg_name = _.get(
            _.find(this.props.draftErrors, itm => itm.field === 'name'),
            'message',
            ''
        );
        const errorMsg_points = _.get(
            _.find(this.props.draftErrors, itm => itm.field === 'points'),
            'message',
            ''
        );

        return (
            <div className="ef5-poi-editor">
                <GlobalModal
                    isOpen={true}
                    onClose={this.handle_cancel}
                    title="POI Editor"
                    bottomBar={
                        <div className="ef5-poi-editor_foot">
                            <div className="errorMessage">
                                {this.props.showError ? <span>{errorMsg_points}</span> : null}
                            </div>
                            <ButtonGroup>
                                <PrimaryButton text="Save" onClick={this.props.dispatch__save} />
                                <NeutralButton
                                    text="Cancel"
                                    onClick={() => {
                                        this.handle_cancel();
                                    }}
                                />
                            </ButtonGroup>
                        </div>
                    }
                >
                    <div className="ef5-poi-editor__head">
                        {geoLayerType === 'custom' ? (
                            <FormField
                                className="ef5-poi-editor__form-name"
                                label="Name"
                                isRequired={true}
                                showErrors={this.props.showError}
                                errors={[errorMsg_name]}
                            >
                                <StandardInput
                                    onChange={value => {
                                        this.props.dispatch__fieldUpdate('name', value);
                                    }}
                                    value={this.props.name}
                                />
                            </FormField>
                        ) : (
                            <div className="layerName implicit">
                                <i className="fa fa-fw fa-cog" />
                                <span className="implicitLayerName">{implicitLayerName}</span>
                            </div>
                        )}
                        {geoLayerType === 'custom' ? (
                            <FormField
                                className="ef5-poi-editor__form-radius"
                                label="Layer Radius"
                                isRequired={false}
                                showErrors={this.props.showError}
                            >
                                <StandardSelector
                                    onChange={value => {
                                        this.props.dispatch__fieldUpdate('radius', parseInt(value));
                                    }}
                                    value={this.props.radius}
                                    items={[
                                        { value: '200', label: '200m' },
                                        { value: '500', label: '500m' },
                                        { value: '1000', label: '1km' },
                                        { value: '2000', label: '2km' },
                                    ]}
                                />
                            </FormField>
                        ) : (
                            <div className="layerName implicit">
                                <i className="fa fa-fw fa-cog" />
                                <span className="implicitLayerName">{implicitLayerName}</span>
                            </div>
                        )}
                    </div>
                    <div className="ef5-poi-editor__body">
                        <div className="ef5-poi-editor__poi-search">
                            <input
                                ref="searchBox_v2"
                                className="ef3-stdInput ef5-poi-editor__search-box"
                                placeholder="Search for POIs"
                            />
                            <div className="ef5-poi-editor__poi-list">
                                {_.map(this.props.pois, (point, key) => {
                                    return (
                                        <div
                                            key={key}
                                            onClick={() => {
                                                this.refs.poiEditor.fitBoundsToPoints([point]);
                                            }}
                                            className="ef5-poi-editor__poi-list-item"
                                        >
                                            <p
                                                className="ef5-poi-editor__poi-list-item-title"
                                                title={point.name}
                                            >
                                                <i className="ef5-poi-editor__poi-list-item-pointer-icon fa fa-fw fa-map-marker" />
                                                &nbsp;{point.name}
                                            </p>
                                            <i
                                                className="ef5-poi-editor__poi-list-item-close-icon fa fa-fw fa-close"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    this.props.dispatch__poi_remove(point.id);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <BlockLoadGroup isLoading={isLoading}>
                            <div className="ef5-poi-editor__map">
                                <GoogleMapsApiLoader>
                                    <GeofenceEditor
                                        ref="poiEditor"
                                        geoTargetingSettings={{
                                            ...this.props.geoTargetingSettings,
                                            radius: parseInt(radius),
                                        }}
                                        mapConf={mapConf}
                                        points={this.props.pois}
                                        createPoint={this.props.dispatch__poi_add}
                                        parent={this}
                                    />
                                </GoogleMapsApiLoader>
                            </div>
                        </BlockLoadGroup>
                    </div>
                </GlobalModal>
            </div>
        );
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PoisEditor);

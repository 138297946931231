import _ from 'lodash';
import React from 'react';

import { Cell } from 'widgets-v5/data-table';
import { formatNumber_currency, formatNumber_percentage } from 'utils/formatting';
import shallowCompare from 'utils/shallow-compare';

export class BillingRateSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign } = this.props;

        let rate;
        switch (ad.primary_pacing) {
            case 'clicks':
            case 'impressions':
                rate = formatNumber_currency(ad.billing_rate);
                break;
            case 'billings':
            case 'spend':
                rate = formatNumber_percentage(ad.billing_rate);
                break;
        }

        const TERM_MAPPING = {
            CPM: 'CPM',
            CPC: 'CPC',
            billable_media_cost_margin: 'Margin',
            billable_media_cost_markup: 'Markup',
        };
        const term = TERM_MAPPING[ad.ef_billing_terms];

        return (
            <div>
                {rate} {term}
            </div>
        );
    }
}

import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
} from 'utils/formatting';

export const metricConfig = {
    unique_users: {
        getValueFromRecord({ statRecord }) {
            return statRecord.unique_campaign || 0;
        },
        getFormattedValueFromRecord({ statRecord }) {
            return formatNumber_whole(this.getValueFromRecord({ statRecord }));
        },
        isZeroValue({ statRecord }) {
            return !this.getValueFromRecord({ statRecord });
        },
    },
    unique_campaign: {
        getValueFromRecord({ statRecord }) {
            return statRecord.unique_campaign || 0;
        },
        getFormattedValueFromRecord({ statRecord }) {
            return formatNumber_whole(this.getValueFromRecord({ statRecord }));
        },
        isZeroValue({ statRecord }) {
            return !this.getValueFromRecord({ statRecord });
        },
    },
    unique_ad: {
        getValueFromRecord({ statRecord }) {
            return statRecord.unique_ad || 0;
        },
        getFormattedValueFromRecord({ statRecord }) {
            return formatNumber_whole(this.getValueFromRecord({ statRecord }));
        },
        isZeroValue({ statRecord }) {
            return !this.getValueFromRecord({ statRecord });
        },
    },
    unique_creative: {
        getValueFromRecord({ statRecord }) {
            return statRecord.unique_creative || 0;
        },
        getFormattedValueFromRecord({ statRecord }) {
            return formatNumber_whole(this.getValueFromRecord({ statRecord }));
        },
        isZeroValue({ statRecord }) {
            return !this.getValueFromRecord({ statRecord });
        },
    },
    unique_ad_creative: {
        getValueFromRecord({ statRecord }) {
            return statRecord.unique_ad_creative || 0;
        },
        getFormattedValueFromRecord({ statRecord }) {
            return formatNumber_whole(this.getValueFromRecord({ statRecord }));
        },
        isZeroValue({ statRecord }) {
            return !this.getValueFromRecord({ statRecord });
        },
    },
};

export function createBeaconConfig({ campaign }) {
    const config = {};

    _.each(campaign.beacons, beacon => {
        config[`event_${beacon.name}`] = {
            getValueFromRecord({ statRecord }) {
                return statRecord.impressions;
            },
            getFormattedValueFromRecord({ statRecord }) {},
        };
    });

    return config;
}

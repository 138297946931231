const actions = {
    changeIASViewability(code) {
        return {
            type: 'AD_FORM__IAS_VIEWABILITY__CHANGE',
            payload: {
                code,
            },
        };
    },
};

export default actions;

import _ from 'lodash';
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import Alert from '@mui/material/Alert';

import { getEnvironmentSettings } from 'services/environment';
import { formatNumber_wholeFixed } from 'utils/formatting';
import StandardInput from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import FormField from 'widgets-v5/form-field';
import { VerticalRadioSelector } from 'widgets-v5/radio-selector';
import Spacer from 'widgets-v5/spacer';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import InfoIconTooltip from 'widgets-v5/info-icon';
import { InfoTooltip } from 'widgets-v6/tooltip';
import { SimpleSelect } from 'widgets-v6/select';
import { TextFieldInput } from 'widgets-v6/standard-input';

import {
    calculateBidPrice,
    calculatePlatformTechFee,
    calculateMediaCost,
    calculateFirstPartyDataFee,
    calculateBillableCost,
} from 'states/resources/ads/business-logic';
import { useAdForm } from '../../v2/hook';

const useStyles = makeStyles(() => ({
    totalSpendLabel: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 50,
    },
    totalSpendInput: {
        padding: 7,
        margin: '0 5px',
    },
    totalSpend: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        justifyContent: 'space-between',
        marginRight: 8,
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    listItemLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    listItemData: {
        display: 'flex',
        paddingLeft: 10,
    },
    bold: {
        fontWeight: 'bold',
    },
    subtractedFeeIcon: {
        paddingRight: 5,
        paddingLeft: 10,
    },
    frequencyCapRadioGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    frequencyCapMargin: {
        marginLeft: 5,
        marginRight: 5,
    },
}));

// this component is used in inventory forecast
class BudgetOptimization extends React.Component {
    static defaultProps = {
        ftaModeLocked: false,
    };

    state = {
        formMode: 'left',
        expandedBillableCost: true,
        expandedMediaCost: true,
    };

    toggleFormMode = () => {
        this.setState({
            formMode: this.state.formMode === 'left' ? 'right' : 'left',
        });
    };

    fillRange = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((item, index) => start + index);
    };

    getBillableCost = () => {
        return calculateBillableCost({
            mediaCost: this.getMediaCost(),
            firstPartyFees: calculateFirstPartyDataFee(this.props.formData),
        });
    };

    getMediaCost = () => {
        return calculateMediaCost({
            maxCpmRate: this.props.formData.max_cpm_rate_local,
            thirdPartyFees: this.props.thirdPartyFees,
            firstPartyFees: calculateFirstPartyDataFee(this.props.formData),
        });
    };

    getPlatformTechFee = () => {
        return calculatePlatformTechFee({
            mediaCost: this.getMediaCost(),
            techFee: this.props.techFee,
        });
    };

    getBidPrice = () => {
        return calculateBidPrice({
            mediaCost: this.getMediaCost(),
            platformTechFee: this.getPlatformTechFee(),
        });
    };

    collapseBillableCost = () => {
        this.setState({
            expandedBillableCost: !this.state.expandedBillableCost,
        });
    };

    collapseMediaCost = () => {
        this.setState({
            expandedMediaCost: !this.state.expandedMediaCost,
        });
    };

    render() {
        const { errors, showErrors, formData, currency } = this.props;

        const environmentSettings = getEnvironmentSettings();
        let currencyLabel = '';

        if (environmentSettings.showCurrencyLabel()) {
            currencyLabel = currency || 'USD';
        }
        const impressionsOptionsArray = this.fillRange(1, 99);

        const intervalCountOptionsArray = this.fillRange(1, 99);

        const impressionsOptions = impressionsOptionsArray.map(option => ({
            label: option.toString(),
            value: option,
        }));

        const intervalCountOptions = intervalCountOptionsArray.map(option => ({
            label: option.toString(),
            value: option,
        }));

        return (
            <div>
                <div>
                    {!this.props.ftaFeesDisabled && (
                        <OptimizationTreeLayout
                            techFee={this.props.techFee}
                            onFieldChange={this.props.onFieldChange}
                            formData={this.props.formData}
                            totalFirstPartyDataFee={this.props.totalFirstPartyDataFee}
                            thirdPartyFees={
                                <div className="ef5-ad-setup-optimization__third-party-fee">
                                    {formatNumber_wholeFixed(this.props.thirdPartyFees)}
                                </div>
                            }
                            errors={errors}
                            showErrors={showErrors}
                            currencyLabel={currencyLabel}
                            bidPrice={this.getBidPrice()}
                            platformTechFee={this.getPlatformTechFee()}
                            organization={this.props.organization}
                        />
                    )}

                    <Spacer type="large" />
                    <div>
                        <FormField
                            className="ef5-ad-setup__daily-frequency-cap-form"
                            label="Frequency Cap"
                            description=""
                            errors={errors.max_user_frequency}
                            showErrors={showErrors}
                            isRequired={false}
                        >
                            <div className="ef5-ad-setup__row">
                                <VerticalRadioSelector
                                    onChange={this.props.toggleFrequencyCapMode}
                                    activeItem={this.props.frequencyCapMode}
                                    items={[
                                        { label: 'Limit to', value: 'limited' },
                                        { label: 'No limit', value: 'unlimited' },
                                    ]}
                                />

                                <div className="ef6-alignment__flex ef5-ad-setup-optimization__limit">
                                    <div className="ef6-alignment__flex ef5-ad-setup-optimization__limit-impression">
                                        <StandardSelector
                                            disabled={this.props.frequencyCapMode === 'unlimited'}
                                            className="ef5-ad-setup-optimization__selector"
                                            onChange={this.props.updateFrequencyCap('imp')}
                                            value={formData.fcaps.imp}
                                            items={impressionsOptions}
                                        />
                                        <span className="ef5-ad-setup-optimization__limit-impression-label">
                                            impression(s) per
                                        </span>
                                    </div>
                                    <div className="ef5-ad-setup-optimization__limit-interval">
                                        <div className="ef6-alignment__flex">
                                            {formData.fcaps.interval_unit === 'ad' ? null : (
                                                <StandardSelector
                                                    virtual
                                                    disabled={
                                                        this.props.frequencyCapMode ===
                                                            'unlimited' || this.props.ftaModeLocked
                                                    }
                                                    className="ef5-ad-setup-optimization__selector"
                                                    onChange={this.props.updateFrequencyCap(
                                                        'interval_count'
                                                    )}
                                                    value={formData.fcaps.interval_count}
                                                    items={intervalCountOptions}
                                                />
                                            )}
                                            <StandardSelector
                                                disabled={
                                                    this.props.frequencyCapMode === 'unlimited' ||
                                                    this.props.ftaModeLocked
                                                }
                                                onChange={this.props.updateFrequencyCap(
                                                    'interval_unit'
                                                )}
                                                value={formData.fcaps.interval_unit}
                                                items={[
                                                    { label: 'hour(s)', value: 'hours' },
                                                    { label: 'day(s)', value: 'days' },
                                                    { label: 'week(s)', value: 'weeks' },
                                                    { label: 'lifetime of ad', value: 'ad' },
                                                ]}
                                            />
                                        </div>
                                        {this.props.showFrequencyCapWarning && (
                                            <div className="ef5-ad-setup-optimization__limit-interval-warning">
                                                <i className="fa fa-fw fa-exclamation-triangle ef5-color__warning" />
                                                <span className="ef5-ad-setup-optimization__limit-interval-warning-message">
                                                    Frequency cap will be reset for the current
                                                    period
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </FormField>
                    </div>
                </div>
            </div>
        );
    }
}

export function OptimizationTreeLayout(props) {
    const environmentSettings = getEnvironmentSettings();
    return (
        <div>
            <ComposableDataTable className="ef5-ad-setup-optimization__table">
                <Row borderless>
                    <Cell>
                        Total Bid Price
                        <span className="ef5-ad-setup-optimization__required">*</span>
                        <InfoIconTooltip
                            tooltipTitle="Total Bid Price"
                            tooltip="The total price you are willing to bid to reach a user, including data costs and third-party fees."
                            position="right"
                        />
                    </Cell>
                    <Cell>
                        <span className="ef5-ad-setup-optimization__currency ef5-ad-setup-optimization__rate-cell">
                            {props.currencyLabel} {environmentSettings.getCurrencySign()}
                        </span>
                    </Cell>
                    <Cell className="ef5-ad-setup-optimization__rate-cell ef5-ad-setup-optimization__input-cell">
                        <TextFieldInput
                            autoFocus
                            type="number"
                            errorText={props.errors.max_cpm_rate_local}
                            required={true}
                            error={props.showErrors}
                            onChange={props.onFieldChange('max_cpm_rate_local')}
                            value={props.formData.max_cpm_rate_local}
                            size="small"
                        />
                    </Cell>
                    <Cell>CPM</Cell>
                </Row>
                <Row borderless>
                    <Cell className="ef5-ad-setup-optimization__table-level-2">
                        Third Party Fees
                        <InfoIconTooltip
                            tooltipTitle="Third Party Fees"
                            tooltip="CPM fees charged by third parties (not payable to Pelmorex) that you want to include for the purpose of pacing and budgeting. Third party fees can be adjusted in campaign settings."
                            position="right"
                        />
                    </Cell>
                    <Cell>
                        <span className="ef5-ad-setup-optimization__currency">
                            {props.currencyLabel} {environmentSettings.getCurrencySign()}
                        </span>
                    </Cell>
                    <Cell className="ef5-ad-setup-optimization__rate-cell ef5-ad-setup-optimization__input-cell">
                        {props.thirdPartyFees}
                    </Cell>
                    <Cell>CPM</Cell>
                </Row>
                <Row borderless>
                    <Cell className="ef5-ad-setup-optimization__table-level-2">
                        Data Cost
                        <span className="ef5-ad-setup-optimization__required">*</span>
                        <InfoIconTooltip
                            tooltipTitle="Data Cost"
                            tooltip="Fees for using Pelmorex Audiences and Foot Traffic Attribution."
                            position="right"
                        />
                    </Cell>
                    <Cell>
                        <span className="ef5-ad-setup-optimization__currency">
                            {props.currencyLabel} {environmentSettings.getCurrencySign()}
                        </span>
                    </Cell>
                    <Cell className="ef5-ad-setup-optimization__rate-cell">
                        <span>{formatNumber_wholeFixed(props.totalFirstPartyDataFee)}</span>
                    </Cell>
                    <Cell>CPM</Cell>
                </Row>
                <Row borderless>
                    <Cell className="ef5-ad-setup-optimization__table-level-2">
                        Platform Fee
                        <InfoIconTooltip
                            tooltipTitle="EngageFront Platform Fee"
                            tooltip={`The fee for using EngageFront. The amount shown is an estimate based on your bid price; the actual fee will be a percentage of the winning price of the impression. The platform fee for ${
                                props.organization
                            } is ${props.techFee * 100}%.`}
                            position="right"
                        />
                    </Cell>
                    <Cell>
                        {props.currencyLabel} {environmentSettings.getCurrencySign()}
                    </Cell>
                    <Cell className="ef5-ad-setup-optimization__rate-cell">
                        {formatNumber_wholeFixed(props.platformTechFee)}
                    </Cell>
                    <Cell>CPM</Cell>
                </Row>
                <Row borderless>
                    <Cell className="ef5-ad-setup-optimization__table-level-2">
                        Net Bid Price
                        <InfoIconTooltip
                            tooltipTitle="Net Bid Price"
                            tooltip="The final bid price submited to exchange partners. The Net Bid Price is calculated by deducting the Platform Fee, Data Cost, and Third-party Fees from the Total Bid Price."
                            position="right"
                        />
                    </Cell>
                    <Cell>
                        {props.currencyLabel} {environmentSettings.getCurrencySign()}
                    </Cell>
                    <Cell className="ef5-ad-setup-optimization__rate-cell">
                        {formatNumber_wholeFixed(props.bidPrice)}
                    </Cell>
                    <Cell>CPM</Cell>
                </Row>
            </ComposableDataTable>
        </div>
    );
}

const fillRange = (start, end) => {
    return Array(end - start + 1)
        .fill()
        .map((item, index) => start + index);
};

export function FrequencyCap(props) {
    const classes = useStyles();
    const impressionsOptionsArray = fillRange(1, 99);

    const intervalCountOptionsArray = fillRange(1, 99);

    const impressionsOptions = impressionsOptionsArray.map(option => ({
        label: option.toString(),
        value: option,
    }));

    const intervalCountOptions = intervalCountOptionsArray.map(option => ({
        label: option.toString(),
        value: option,
    }));

    const {
        errors,
        showErrors,
        toggleFrequencyCapMode,
        frequencyCapMode,
        updateFrequencyCap,
        formData,
        ftaModeLocked,
        showFrequencyCapWarning,
        isDOOHCampaign,
    } = props;

    return (
        <div>
            <FormField
                className="ef5-ad-setup__daily-frequency-cap-form"
                description=""
                errors={errors.max_user_frequency}
                showErrors={showErrors}
                isRequired={false}
            >
                <div className="ef5-ad-setup__row">
                    <RadioGroup
                        aria-label="frequency_cap"
                        name="frequency_cap"
                        value={frequencyCapMode || ''}
                        onChange={toggleFrequencyCapMode}
                    >
                        <div className={classes.frequencyCapRadioGroup}>
                            <FormControlLabel
                                value={'limited'}
                                control={<Radio color="primary" />}
                                label={'Limit to'}
                                style={{ width: 150 }}
                                disabled={isDOOHCampaign}
                            />
                            {frequencyCapMode !== 'unlimited' && (
                                <div className="ef6-alignment__flex ef5-ad-setup-optimization__limit">
                                    <div className="ef6-alignment__flex ef5-ad-setup-optimization__limit-impression">
                                        <div className={classes.frequencyCapMargin}>
                                            <SimpleSelect
                                                value={formData.fcaps.imp}
                                                onChange={e => {
                                                    updateFrequencyCap('imp')(e.target.value);
                                                }}
                                                options={impressionsOptions}
                                                error={errors && showErrors}
                                            />
                                        </div>
                                        <span className="ef5-ad-setup-optimization__limit-impression-label">
                                            impression(s) per
                                        </span>
                                    </div>
                                    <div className="ef5-ad-setup-optimization__limit-interval">
                                        <div className="ef6-alignment__flex">
                                            {formData.fcaps.interval_unit === 'ad' ? null : (
                                                <div className={classes.frequencyCapMargin}>
                                                    <SimpleSelect
                                                        disabled={ftaModeLocked}
                                                        value={formData.fcaps.interval_count}
                                                        onChange={e => {
                                                            updateFrequencyCap('interval_count')(
                                                                e.target.value
                                                            );
                                                        }}
                                                        options={intervalCountOptions}
                                                        error={errors && showErrors}
                                                    />
                                                </div>
                                            )}
                                            <div className={classes.frequencyCapMargin}>
                                                <SimpleSelect
                                                    disabled={ftaModeLocked}
                                                    value={formData.fcaps.interval_unit}
                                                    onChange={e => {
                                                        updateFrequencyCap('interval_unit')(
                                                            e.target.value
                                                        );
                                                    }}
                                                    options={[
                                                        { label: 'hour(s)', value: 'hours' },
                                                        { label: 'day(s)', value: 'days' },
                                                        { label: 'week(s)', value: 'weeks' },
                                                        {
                                                            label: 'lifetime of ad',
                                                            value: 'ad',
                                                        },
                                                    ]}
                                                    error={errors && showErrors}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {showFrequencyCapWarning && (
                                        <div>
                                            <Alert severity="warning">
                                                Frequency cap will be reset for the current period
                                            </Alert>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <FormControlLabel
                            value={'unlimited'}
                            control={<Radio color="primary" />}
                            label={'No limit'}
                            disabled={isDOOHCampaign}
                        />
                    </RadioGroup>
                </div>
            </FormField>
            {showErrors &&
                errors[
                    'fcaps'
                ] && (
                    <Grid item>
                        <FormHelperText error>
                            {
                                errors[
                                    'fcaps'
                                ]
                            }
                        </FormHelperText>
                    </Grid>
                )}
        </div>
    );
}


export function BillingsList(props) {
    const classes = useStyles();
    const { netBidPrice, platformTechFee } = useAdForm();

    const headers = [
        {
            name: 'Third Party Fees',
            data: () => formatNumber_wholeFixed(props.thirdPartyFees),
            tooltip:
                'CPM fees charged by third parties (not payable to Pelmorex) that you want to include for the purpose of pacing and budgeting. Third party fees can be adjusted in campaign settings.',
        },
        {
            name: 'Data Cost',
            data: () => <span>{props.totalFirstPartyDataFee}</span>,
            tooltip: 'Fees for using Pelmorex Audiences and Foot Traffic Attribution.',
        },
        {
            name: 'Platform Fee',
            data: () => formatNumber_wholeFixed(platformTechFee),
            tooltip: `The fee for using EngageFront. The amount shown is an estimate based on your bid price; the actual fee will be a percentage of the winning price of the impression. The platform fee for ${
                props.organization
            } is ${props.techFee * 100}%.`,
        },
    ];

    const environmentSettings = getEnvironmentSettings();

    const currencyLabel = environmentSettings.showCurrencyLabel() ? props.currency : '';

    return (
        <List className={classes.billingsList}>
            <ListItem className={classes.listItem}>
                <Grid container justifyContent="space-between">
                    <div className={classes.listItemLabel}>Total Bid Price</div>
                    <div className={classes.listItemData}>
                        <div>
                            {currencyLabel} {environmentSettings.getCurrencySign()}
                        </div>
                        <div>
                            {props.formData.bid_strategy_mode === 'fixed_bid_price'
                                ? formatNumber_wholeFixed(props.formData.max_cpm_rate_local)
                                : formatNumber_wholeFixed(
                                      props.formData.automatic_bid_price.max_ecpm_local
                                  )}
                        </div>
                        CPM
                    </div>
                </Grid>
            </ListItem>
            {_.map(headers, ({ name, data, tooltip }, index) => (
                <ListItem key={index} className={classes.listItem}>
                    <Grid container>
                        <div className={classes.subtractedFeeIcon}>-</div>
                        <div className={classes.listItemLabel}>
                            <div>{name}</div>
                            <InfoTooltip title={tooltip} />
                        </div>
                    </Grid>
                    <div className={classes.listItemData}>
                        <div>
                            {currencyLabel} {environmentSettings.getCurrencySign()}
                        </div>
                        <div>{data()}</div>
                        CPM
                    </div>
                </ListItem>
            ))}
            <Divider />
            <ListItem className={classes.bold}>
                <Grid container justifyContent="space-between">
                    <div className={classes.listItemLabel}>
                        <div>Net Bid Price</div>
                        <InfoTooltip title="The final bid price submited to exchange partners. The Net Bid Price is calculated by deducting the Platform Fee, Data Cost, and Third-party Fees from the Total Bid Price." />
                    </div>
                    <div className={classes.listItemData}>
                        <div>
                            {currencyLabel} {environmentSettings.getCurrencySign()}
                        </div>
                        <div>{formatNumber_wholeFixed(netBidPrice)}</div>
                        CPM
                    </div>
                </Grid>
            </ListItem>
        </List>
    );
}

export function TotalSpend(props) {
    const classes = useStyles();
    return (
        <div className={classes.totalSpend}>
            {props.label && <div className={classes.totalSpendLabel}>Total Bid Price</div>}
            <div className={classes.totalSpend}>
                <div>{props.currency} $</div>
                <FormField
                    width="auto"
                    errors={props.errors.max_cpm_rate_local}
                    showErrors={props.showErrors}
                    isRequired={true}
                    marginBottom={0}
                >
                    <StandardInput
                        className={classes.totalSpendInput}
                        style={{ width: 100, textAlign: 'right' }}
                        formatIn={formatSpendValueToString}
                        formatOut={formatSpendStringToValue}
                        shouldAllowInput={shouldAllowInputForSpend}
                        onChange={props.onFieldChange('max_cpm_rate_local')}
                        value={props.formData.max_cpm_rate_local}
                        placeholder="0.00"
                    />
                </FormField>
                <div>CPM</div>
            </div>
        </div>
    );
}

function shouldAllowInputForSpend(input) {
    // Allow empty input
    if (!input) {
        return true;
    }
    // Check allowable characters
    if (!/^[\d,]*(\.\d{0,2})?$/g.test(input)) {
        return false;
    }
    // Check character length
    const [dollars, cents] = input.split('.');
    const numDollarsDigits = (dollars.match(/\d/g) || []).length;
    const numCentsDigits = cents ? cents.length : 0;
    return numDollarsDigits <= 9 && numCentsDigits <= 2;
}

function formatSpendValueToString(value) {
    if (!value) {
        return '';
    } else {
        const [dollars, cents] = value.toFixed(2).split('.');
        const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
        return `${formattedDollars}.${cents}`;
    }
}

function formatSpendStringToValue(string) {
    if (string === '') {
        return 0;
    } else {
        return parseFloat(string.replace(/,/g, ''));
    }
}

export default BudgetOptimization;

import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import flags from 'containers/flags/service';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

import actions from '../../actions';
import {
    IAS_GROUPM_VIEWABILITY_OPTIONS,
    IAS_GROUPM_VIEWABILITY_BY_AD_SIZE_OPTIONS,
} from 'common/constants/ias-groupm-viewability';

import { getIASPublicisGroupMWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

const useStyles = makeStyles({
    form: {
        margin: '10px 0px',
    },
    label: {
        fontSize: 14,
        margin: '10px 0px',
    },
    labelSmall: {
        fontSize: 17,
    },
});

const IASGroupmViewability = ({
    disabled,
    options,
    value,
    handleChange,
    helpMessage,
    warningData,
    isCrossPlatformCampaign,
}) => {
    const classes = useStyles();

    return (
        <div>
            <FormControl className={classes.form} disabled={disabled}>
                <FormLabel component="legend">GroupM Performance Matching</FormLabel>
                <Select value={value} onChange={handleChange}>
                    {_.map(options, option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {helpMessage && <FormHelperText>{helpMessage}</FormHelperText>}
                {isCrossPlatformCampaign && warningData.hasWarning && (
                    <WarningText
                        message={warningData.message}
                        title={warningData.title}
                        severity={warningData.severity}
                    />
                )}
            </FormControl>
        </div>
    );
};

function selector(storeState) {
    const platform = _.get(storeState, 'adForm.form.draft.platform');
    const platforms = _.get(storeState, 'adForm.form.draft.platforms');
    const value = _.get(storeState, 'adForm.form.draft.ias.groupmViewability.value');
    const disabled = false; // platform === 'inapp';
    const isInAppPlatform = platform === 'inapp';

    const warningData = getIASPublicisGroupMWarning({
        viewabilityValue: value,
        platforms,
        field: 'groupm',
    });

    const options = IAS_GROUPM_VIEWABILITY_OPTIONS;

    let helpMessage;
    if (isInAppPlatform) {
        helpMessage = 'Supports desktop and mobile web platforms only';
    }

    return {
        disabled,
        options,
        value,
        helpMessage,
        warningData,
    };
}

function mapDispatch(dispatch) {
    return {
        handleChange: event => {
            const { value } = event.target;

            dispatch(actions.changeGroupmViewability(value));
        },
    };
}

export default connect(
    selector,
    mapDispatch
)(IASGroupmViewability);

import PropTypes from 'prop-types';
import React from 'react';

import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;
import 'jquery-textcomplete';

import _ from 'lodash';
import { macroMapping } from 'common/constants/macroMapping';
import UrlStandardInput from 'widgets-v5/url-standard-input';

class CreativeAutoComplete extends React.Component {
    static propTypes = {
        value: PropTypes.any,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        shouldAllowInput: PropTypes.func,
        formatIn: PropTypes.func,
        formatOut: PropTypes.func,
        autocorrect: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {},
        placeholder: 'Enter a value, macros enabled',
        value: '',
    };

    componentDidMount() {
        const macros = _(macroMapping)
            .valuesIn()
            .uniq()
            .value();

        const matchingStrategies = [
            {
                /*  Required:
                match: regex
                search: func -> cb(matchingOption)
                replace: func -> String
            */
                match: /%%\w*$/g,
                index: 0, // leave at 0 to correctly use regex match
                search(rawTypedValue, callback) {
                    const term = rawTypedValue.toLowerCase();

                    const answer = _.chain(macros)
                        .map(macro => {
                            return _.includes(macro.toLowerCase(), term) ? macro : undefined;
                        })
                        .filter(result => result)
                        .value();

                    callback(answer);
                },
                replace(macro) {
                    return macro.toString();
                },
            },
        ];

        const options = {
            className: 'ef3-creativeAutoComplete_dropdown',
            noResultsMessage: 'No matching macros available.',
            zIndex: 1000, // To layer on top of modal @ 999
        };

        const { onChange } = this.props;

        $(this.el)
            .textcomplete(matchingStrategies, options)
            .on({
                'textComplete:select'(e) {
                    if (onChange) onChange(e.target.value);
                },
            });
    }

    // https://github.com/yuku-t/jquery-textcomplete/blob/master/doc/how_to_use.md
    render() {
        return (
            <div className="ef3-creativeAutoComplete">
                <UrlStandardInput {...this.props} innerRef={(currentRef) => this.el = currentRef} type="text" />
            </div>
        );
    }
}

export default CreativeAutoComplete;

import _ from 'lodash';

export default function selector(storeState) {
    const { audienceSegments } = _.get(storeState, 'environicsDashboard');
    const organizationId = _.get(storeState, 'profile.organizationId');

    return {
        audienceSegments,
        organizationId,
    };
}

import _ from 'lodash';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import $ from 'jquery';

import createHttp from 'utils/http';
import { deleteCookie } from 'utils/cookies';
import { logout, login } from 'states/profile/actions';

import FormField from 'widgets-v5/form-field';
import Spacer from 'widgets-v5/spacer';

import TextField from '@mui/material/TextField';
import CenterLayout from 'widgets-v5/center-layout';
import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';
import Card from 'widgets-v5/card';
import StandardInput from 'widgets-v5/standard-input';
import { BlockLoadGroup } from 'widgets-v5/load-group';

const http = createHttp({ redirectOnFail: false });

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.emailField = createRef();
        this.passwordField = createRef();
    }

    state = {
        message: '',
        noEmail: true,
        noPassword: true,
        showError: false,
        isLoading: false,
    };

    handleSubmit = e => {
        e.preventDefault();

        const email = this.emailField.current.value;
        const password = this.passwordField.current.value;

        if (!email || !password) {
            this.setState({
                showError: true,
                noEmail: !email,
                noPassword: !password,
            });
            return;
        }

        this.setState({
            isLoading: true,
            noEmail: false,
            noPassword: false,
        });

        this.login(email, password).then(
            data => {
                this.props.dispatch(login(data));
            },
            err => {
                this.setState({ isLoading: false });
                const code = _.get(err, `code`, void 0);
                if (code >= 400 && code <= 499) {
                    this.setState({
                        showError: true,
                        message: 'Email and password combination incorrect',
                    });
                } else if ((code >= 500 && code <= 599) || code === void 0) {
                    this.setState({
                        showError: true,
                        message: 'Server error, please try again later',
                    });
                }
            }
        );
    };

    login = (email, password) => {
        return http.post('auth?populate=user,role,organization', { email, password });
    };

    componentDidMount() {
        this.props.dispatch(logout());
    }

    render() {
        return (
            <CenterLayout className="ef5-login">
                <div>
                    <img className="ef5-login__logo" src="/images/EngageFront-logo.png" />
                    <BlockLoadGroup isLoading={this.state.isLoading} size="large">
                        <form ref="login-form" method="POST" onSubmit={this.handleSubmit}>
                            <Card
                                className="ef5-login__card"
                                title="Sign In"
                                footer={
                                    <ButtonGroup>
                                        <NeutralButton
                                            text="I Forgot My Password :("
                                            link="/forgot-password"
                                        />
                                        <PrimaryButton
                                            efEvent="LOGIN__SIGN_IN"
                                            text="Sign In"
                                            onClick={this.handleSubmit}
                                        />
                                    </ButtonGroup>
                                }
                            >
                                <FormField
                                    label="E-mail"
                                    isRequired={true}
                                    showErrors={this.state.showError}
                                    errors={this.state.noEmail ? ['E-mail is required'] : null}
                                >
                                    <TextField
                                        inputRef={this.emailField}
                                        fullWidth
                                        variant="outlined"
                                        type="email"
                                        autoComplete="username"
                                    />
                                </FormField>
                                <Spacer />
                                <FormField
                                    label="Password"
                                    isRequired={true}
                                    showErrors={this.state.showError}
                                    errors={this.state.noPassword ? ['Password is required'] : null}
                                >
                                    <TextField
                                        inputRef={this.passwordField}
                                        fullWidth
                                        variant="outlined"
                                        type="password"
                                        autoComplete="current-password"
                                    />
                                </FormField>
                                <FormField
                                    showErrors={this.state.showError}
                                    errors={[this.state.message]}
                                />
                            </Card>
                        </form>
                    </BlockLoadGroup>
                </div>
            </CenterLayout>
        );
    }
}

function select(state) {
    const o = _.get(state, 'profile', {});
    return o;
}

export default connect(select)(Login);

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

import BulkUploadTableRow from '../table-row';
import selector from './selector';
import { default as creativeBulkUploadActions } from '../../actions';
import { getPlatformsAvailableForCampaign } from 'forms/creative-form/constants';

import { getValidSizes } from 'forms/creative-form/constants';

import { getEnvironmentSettings } from 'services/environment';

const validSizes = getValidSizes();

const useStyles = makeStyles(theme => ({
    tooltip: {
        fontSize: theme.tooltip.fontSize,
        whiteSpace: 'pre-line',
    },
    icon: {
        marginLeft: '2px',
    },
}));

const SizeHeader = () => {
    const classes = useStyles();
    const validSizesText = _.join(validSizes, '\n');
    return (
        <Grid container direction="row" justifyContent="left">
            Size *
            <Tooltip
                title={`Valid Sizes: \n ${validSizesText}`}
                classes={{ tooltip: classes.tooltip }}
            >
                <InfoOutlinedIcon fontSize="small" className={classes.icon} />
            </Tooltip>
        </Grid>
    );
};

const PlatformsHeader = campaignType => {
    const classes = useStyles();
    const validPlatforms = getPlatformsAvailableForCampaign(campaignType).map(
        platform => platform.label
    );

    const validPlatformsText = _.join(validPlatforms, '\n');
    return (
        <Grid container direction="row" justifyContent="left">
            Platforms *
            <Tooltip
                title={`Valid Platforms: \n ${validPlatformsText}`}
                classes={{ tooltip: classes.tooltip }}
            >
                <InfoOutlinedIcon fontSize="small" className={classes.icon} />
            </Tooltip>
        </Grid>
    );
};

const standardHeaders = campaignType => [
    {
        key: 'name',
        label: 'Name *',
        shouldHide: () => false,
    },
    {
        key: 'size',
        label: <SizeHeader />,
        shouldHide: () => false,
    },
    {
        key: 'image',
        label: 'Image *',
        shouldHide: () => false,
    },
    {
        key: 'landing_page',
        label: 'Landing Page *',
        shouldHide: () => false,
    },
    {
        key: 'third_party_clicktracker',
        label: 'Third-Party Clicktracker',
        shouldHide: () => false,
    },
    {
        key: 'third_party_pixels',
        label: 'Third Party Pixels',
        shouldHide: () => false,
    },
    {
        key: 'third_party_javascript_urls',
        label: 'Third Party Javascript',
        shouldHide: () => false,
    },
    {
        key: 'platforms',
        label: PlatformsHeader(campaignType),
        shouldHide: () => false,
    },
    {
        key: 'actions',
        label: '',
        shouldHide: () => false,
    },
];

const richMediaHeaders = campaignType => [
    {
        key: 'name',
        label: 'Name *',
        shouldHide: () => false,
    },
    {
        key: 'size',
        label: <SizeHeader />,
        shouldHide: () => false,
    },
    {
        key: 'html',
        label: 'HTML Markup',
        shouldHide: () => false,
    },
    {
        key: 'reference_image',
        label: 'Reference Image *',
        shouldHide: () => getEnvironmentSettings().useJobForBulkCreativeScreenshots(),
    },
    {
        key: 'landing_page',
        label: 'Landing Page *',
        shouldHide: () => false,
    },
    {
        key: 'third_party_clicktracker',
        label: 'Third-Party Clicktracker',
        shouldHide: () => false,
    },
    {
        key: 'third_party_pixels',
        label: 'Third Party Pixels',
        shouldHide: () => false,
    },
    {
        key: 'third_party_javascript_urls',
        label: 'Third Party Javascript',
        shouldHide: () => false,
    },
    {
        key: 'mraid',
        label: 'Requires MRAID',
        shouldHide: ({ environmentSettings }) => !environmentSettings.canUseMraid(),
    },
    {
        key: 'platforms',
        label: PlatformsHeader(campaignType),
        shouldHide: () => false,
    },
    {
        key: 'actions',
        label: '',
        shouldHide: () => false,
    },
];

function BulkUploadTable({
    draft,
    creativeFormat,
    dispatch,
    errors,
    showErrors,
    campaignId,
    campaignType,
    loadingCreativeAssets,
}) {
    const handleFieldChange = ({ creativeKey, field, value }) => {
        dispatch(creativeBulkUploadActions.updateDraft({ creativeKey, field, value }));
    };
    const handleImageChange = (formData, creativeKey) => {
        dispatch(creativeBulkUploadActions.changeImage(formData, campaignId, creativeKey));
    };
    const availableHeaders =
        creativeFormat === 'standard'
            ? _.filter(standardHeaders(campaignType), header => !header.shouldHide())
            : _.filter(
                  richMediaHeaders(campaignType),
                  header => !header.shouldHide({ environmentSettings: getEnvironmentSettings() })
              );
    return (
        <Table stickyHeader size="small">
            <TableHead>
                <TableRow>
                    {_.map(availableHeaders, header => (
                        <TableCell>{header.label}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {_.map(Object.keys(draft), creativeKey => (
                    <BulkUploadTableRow
                        creativeFormat={creativeFormat}
                        creativeDraft={draft[creativeKey]}
                        creativeKey={creativeKey}
                        onChange={handleFieldChange}
                        errors={_.get(errors, creativeKey, {})}
                        showErrors={showErrors}
                        onImageChange={handleImageChange}
                        isAssetLoading={loadingCreativeAssets[creativeKey]}
                        campaignType={campaignType}
                    />
                ))}
            </TableBody>
        </Table>
    );
}

export default connect(selector)(BulkUploadTable);

import _ from 'lodash';
import c from 'common/constants/flux-events';
import createHttp from 'utils/http';

export function toggleEnvironment(env) {
    return {
        type: c.DEV_SETTINGS__TOGGLE_ENVIRONMENT,
        payload: {
            env,
        },
    };
}

export function toggleAPImode() {
    return {
        type: c.DEV_SETTINGS__TOGGLE_ENDPOINT_MODE,
        payload: {},
    };
}

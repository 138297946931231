import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Spacer extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
    };

    static defaultProps = {
        type: 'medium',
    };

    render() {
        return <div className={classnames(`spacer__${this.props.type}`)} />;
    }
}

export default Spacer;

import React from 'react';
import _ from 'lodash';

const TriangleLeft = () => {
    // const bgColor = 'white';
    // const style = {
    //     display: 'block',
    //     position: 'absolute',
    //     left: '25px',
    //     top: '-40px',
    //     width: '0',
    //     height: '0',
    //     borderRight : `20px solid ${bgColor}`,
    //     borderTop: '25px solid transparent',
    //     borderBottom: '25px solid transparent'
    // }
    //
    // const style2 = {
    //     display: 'block',
    //     position: 'absolute',
    //     left: '24px',
    //     top: '-41px',
    //     width: '0',
    //     height: '0',
    //     borderRight : `22px solid black`,
    //     borderTop: '27px solid transparent',
    //     borderBottom: '25px solid transparent'
    // }

    return (
        <div className="triangle-wrap">
            <div className="triangle-left outline" />
            <div className="triangle-left" />
        </div>
    );
};

class Popup extends React.Component {
    constructor(props) {
        super(props);

        this._yOffset = 50;
        // this._yOffset = 0;

        this.state = {
            top: `-${this._yOffset}px`,
        };
    }

    render() {
        const _className = `${this.props.isActive ? 'is-active' : ''} popup`;
        const _popupStyle = {
            top: this.state.top,
        };

        return (
            <div
                className={_className}
                style={_popupStyle}
                ref={thisNode => {
                    this._node = thisNode;
                    this._nodeId = _.uniqueId();
                }}
            >
                <div>{this.props.children}</div>
            </div>
        );
    }
}

class ModuleRoot extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const activeStyle = {
            display: 'block',
        };
        return (
            <div className="ef3-widget--pivot-table-popup">
                {this.props.isActive ? <TriangleLeft /> : null}
                {this.props.isActive ? <Popup {...this.props} parent={this} /> : null}
            </div>
        );
    }
}

export default ModuleRoot;

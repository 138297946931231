import _ from 'lodash';
function isFieldDisabled(source) {
    return source.mode === 'adobe' || source.mode === 'mweb';
}

function getValidators(isEditing) {
    return {
        name(value) {
            if (!value) {
                return 'required field';
            }
        },
        supports(value) {
            if (value.length === 0) {
                return 'required field';
            }
        },
        source_type(value, source) {
            if (isFieldDisabled(source)) {
                return;
            }

            if (source.mode === 'upload' && value !== 'upload') {
                return `must be 'upload' when mode is 'upload'`;
            }
            if (source.mode === 'environics' && value !== 'environics') {
                return `must be 'environics' when mode is 'environics'`;
            }
            if (source.mode === 'retargeting' && !_.includes(['campaign', 'ad', 'pixel'], value)) {
                return `must be 'campaign, ad or pixel' when mode is 'retargeting'`;
            }
        },
        source_id(value, draft) {
            if (draft.source_type === 'pixel') {
                return;
            }

            if (isFieldDisabled(draft)) {
                return;
            }

            if (_.includes(['upload', 'environics'], draft.mode)) {
                return;
            }
            if (!_.isArray(value)) {
                return 'expected a list';
            }
            if (value.length === 0) {
                return 'required field';
            }

            return value => {
                if (!_.isNumber(value)) {
                    return 'expected a number';
                }
            };
        },
        advertiser_id(value, draft) {
            if (draft.source_type === 'pixel' && value.length === 0) {
                return 'required field';
            }
        },
        mode(value) {
            if (!_.includes(['environics', 'retargeting', 'upload', 'adobe', 'mweb'], value)) {
                return `mode must be one of: Retargeting, or Upload`;
            }
        },
        retargeting_type(value, draft) {
            if (_.includes(['upload', 'environics', 'adobe', 'mweb'], draft.mode)) {
                return;
            }

            if (!_.includes(['impression', 'click', 'conversion', 'attribution', 'pixel'], value)) {
                return `mode must be one of: Impression, Click, Pixel or Conversion`;
            }
        },
        event_name() {},
        event_name_list(value, draft) {
            const isEventRetargeting =
                draft.retargeting_type === 'conversion' ||
                draft.retargeting_type === 'pixel' ||
                draft.retargeting_type === 'attribution';
            const hasEvents = value && value.length > 0;

            if (isEventRetargeting && !hasEvents) {
                return 'required field';
            }
        },
        description(value, draft) {
            if (_.includes(['retargeting', 'environics', 'adobe', 'mweb'], draft.mode)) {
                return;
            }

            if (!value) {
                return 'required field';
            }
        },
        upload_origin(value, draft) {
            if (draft.mode === 'upload' && !value) {
                return `"Data Provider" is a required field for uploaded audience segments`;
            }
        },
        customUploadOrigin(value, draft) {
            if (draft.upload_origin === 'other' && !value && !isEditing) {
                return 'Must specify upload type';
            }
        },
        lookback(value, draft) {
            if (draft.source_type === 'pixel' && value === '') {
                return 'required field';
            }
        },
    };
}

export default getValidators;

import _ from 'lodash';

export default function selector(storeState, props) {
    const { campaignId } = props;

    const dataFeesEnabled = _.get(
        storeState,
        `resources.campaigns.${campaignId}.attr.data_fees_enabled`,
        false
    );

    return {
        dataFeesEnabled,
    };
}

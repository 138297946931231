import React, { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Icon from '@mdi/react';
import { mdiGestureTapButton } from '@mdi/js';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';

import { AudienceCard } from '../modules/audience-card';
import { AudienceTable } from '../modules/audience-table';

const sortingOptions = [
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
];

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: 400,
        width: '100%',
    },
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#098679',
    },
}));

const IntentLayout = ({ audienceSegments, onSearch, segmentCount, organizationId, activeAudience }) => {
    const classes = useStyles();
    const [activeSort, setActiveSort] = useState({ value: 'name', direction: 'asc' });

    const handleSortChange = event => {
        const { value, direction } = _.find(sortingOptions, { value: event.target.value });
        setActiveSort({ value, direction });
    };

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const sortedAudiences = _.orderBy(audienceSegments, [activeSort.value], [activeSort.direction]);

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'pelmorex' },
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Pelmorex Audiences
                </RouterLink>
                <Typography color="textPrimary">Online Behavioural</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <Icon path={mdiGestureTapButton} size={3} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            Online Behavioural
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            Target users based on common keywords and URLs found in content that
                            people browse while researching a given product or service.
                        </Typography>
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            gutterBottom
                            style={{ marginTop: 25 }}
                        >
                            These audiences allow you to reach users who are at the start of their
                            consumer purchase journey, or exhibit behaviours difficult to determine
                            based on visitation patterns.
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Box mt={3}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Total
                                    </Typography>
                                    <Typography variant="h5">{segmentCount}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mb={2.5} mt={5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <SearchInput placeholder="Filter Audiences" onChange={handleSearch} />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort.value}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <AudienceTable
                    audiences={sortedAudiences}
                    cardSize="small"
                    activeAudience={activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            active={activeAudience === segment.id}
                            segment={segment}
                            organizationId={organizationId}
                        />
                    )}
                />
            </Container>
        </Box>
    );
};

export default IntentLayout;

import _ from 'lodash';
import moment from 'moment-timezone';
import c from 'common/constants/flux-events';
import createHttp from 'utils/http';
import Campaigns from 'states/resources/campaigns/actions';
import { fetchReportStats } from 'services/resources/stats';
import { getEnvironmentSettings } from 'services/environment';

import ScheduledReportActions from 'states/resources/scheduled-reports/actions';

const http = createHttp();
const actions = {
    openModal(campaign) {
        return dispatch => {
            dispatch({
                type: c.REPORT_EXPLORE__SCHEDULED_REPORT__OPEN,
                payload: {
                    isModalOpen: true,
                },
            });

            dispatch(this.init(campaign));
        };
    },
    closeModal() {
        return dispatch => {
            dispatch({
                type: c.REPORT_EXPLORE__SCHEDULED_REPORT__CLOSE,
                payload: {
                    isModalOpen: false,
                },
            });
        };
    },
    init(campaign) {
        return async dispatch => {
            let users = {};
            let organizations;

            try {
                let query = `
                    query getOrganizationNamesAndUsers ($relationship: String) {
                        organizations(filters: { relationship: $relationship }) {
                            name
                            id
                            users {
                                id
                                organization
                                global_role
                                email
                            }
                        }
                    }
                `;

                const variables = {
                    relationship: 'child',
                };

                const organizationsIncludesUsers = await http.graphql(query, variables);
                organizations = organizationsIncludesUsers.organizations;
                _.each(organizationsIncludesUsers.organizations, org => {
                    _.each(org.users, user => {
                        users[user.id] = { attr: user };
                    });
                });
            } catch (err) {
                dispatch({
                    type: 'REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_USERS_FAIL',
                    payload: { error: err },
                });
            }

            const { id, organization, scheduledReportId } = campaign;

            if (scheduledReportId === 'new' || !scheduledReportId) {
                dispatch({
                    type: c.REPORT_EXPLORE__SCHEDULED_REPORT__INIT,
                    payload: {
                        campaignId: id,
                        campaign,
                        organization,
                        organizations,
                        users,
                    },
                });
            } else {
                dispatch(ScheduledReportActions.getOne(id, scheduledReportId)).then(
                    response => {
                        const scheduledReport = response;

                        dispatch({
                            type: c.REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_SUCCESS,
                            payload: {
                                scheduledReport,
                                campaign,
                                users,
                                organizations,
                            },
                        });
                    },
                    error => {
                        dispatch({
                            type: c.REPORT_EXPLORE__SCHEDULED_REPORT__FETCH_FAIL,
                            error: error.body,
                        });
                    }
                );
            }
        };
    },
    updateSplits(splits) {
        return {
            type: c.REPORT_EXPLORE__PIVOT_TABLE__SPLIT_CHANGE,
            payload: {
                splits,
            },
        };
    },
    fetchStats(campaignId, done) {
        return (dispatch, getState) => {
            const campaign = _.get(getState(), `resources.campaigns.${campaignId}.attr`);
            const split = _.get(getState(), 'scheduledReport.pivotTable.splits', []);
            const excludeTodaysStat = _.get(
                getState(),
                'scheduledReport.draft.scheduleInfo.excludeTodaysStat'
            );
            const timezone = _.get(getState(), 'scheduledReport.draft.scheduleInfo.timezone');

            const start = campaign.start;
            const end = excludeTodaysStat
                ? moment()
                      .tz(timezone)
                      .subtract(1, 'days')
                      .endOf('day')
                      .toDate()
                : campaign.end;

            return fetchReportStats({
                campaign_id: campaignId,
                start,
                end,
                match: {},
                split: split.map(s => s.name),
                timezone: campaign.default_timezone || 'America/Toronto',
                datasource: getEnvironmentSettings().getReportingDatasource(),
            }).then(stats => {
                dispatch({
                    type: c.REPORT_EXPLORE__PIVOT_TABLE__INITIALIZE,
                    payload: {
                        stats,
                    },
                });

                done();
            });
        };
    },
    updateDraft(newDraft) {
        return dispatch => {
            dispatch({
                type: c.REPORT_EXPLORE__SCHEDULED_REPORT__UPDATE_DRAFT,
                payload: {
                    newDraft,
                },
            });
        };
    },
    toggleZeroRows() {
        return {
            type: c.REPORT_EXPLORE__SCHEDULED_REPORT__TOGGLE_ZERO_ROWS,
            payload: {},
        };
    },
    submitDraft() {
        return (dispatch, getState) => {
            const sessionScheduledReportForm = _.get(getState(), 'scheduledReport', undefined);

            if (!sessionScheduledReportForm) {
                throw new Error('scheduledReport does not exist');
            }

            dispatch({
                type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_DRAFT,
                payload: {},
            });

            const errors = _.get(sessionScheduledReportForm, 'errors', []);

            if (errors.length) {
                dispatch({
                    type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SHOW_ERRORS,
                    payload: {},
                });
                return;
            }

            const payload = _.get(sessionScheduledReportForm, 'draft', {});

            const campaignId = payload.campaignId;

            dispatch(ScheduledReportActions.create(campaignId, payload)).then(
                response => {
                    const scheduledReport = response;

                    dispatch({
                        type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_DRAFT_SUCCESS,
                        payload: { scheduledReport },
                    });

                    dispatch(this.closeModal());

                    // To update campaign's scheduledReportId
                    dispatch(Campaigns.get(campaignId))
                        .then(campaign => {
                            dispatch({
                                type: c.SYSTEM__CAMPAIGNS__FETCH__SUCCESS,
                                payload: { campaignId, campaign },
                            });
                            return campaign;
                        })
                        .catch(reason => {
                            throw new Error(reason);
                        });
                },
                error => {
                    dispatch({
                        type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_DRAFT_FAIL,
                        error: error.body,
                    });
                }
            );
        };
    },
    submitPatchDraft() {
        return (dispatch, getState) => {
            const sessionScheduledReportForm = _.get(getState(), 'scheduledReport', undefined);

            if (!sessionScheduledReportForm) {
                throw new Error('scheduledReport does not exist');
            }

            const errors = _.get(sessionScheduledReportForm, 'errors', []);

            if (errors.length) {
                dispatch({
                    type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SHOW_ERRORS,
                    payload: {},
                });
                return;
            }

            const payload = _.get(sessionScheduledReportForm, 'draft', {});

            dispatch({
                type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_PATCH_DRAFT,
                payload: {},
            });

            const campaignId = payload.campaignId;
            const scheduledReportId = sessionScheduledReportForm.id;
            dispatch(ScheduledReportActions.update(scheduledReportId, campaignId, payload)).then(
                response => {
                    const scheduledReport = response;

                    dispatch({
                        type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_PATCH_DRAFT_SUCCESS,
                        payload: { scheduledReport },
                    });

                    dispatch(this.closeModal());
                },
                error => {
                    dispatch({
                        type: c.REPORT_EXPLORE__SCHEDULED_REPORT__SUBMIT_PATCH_DRAFT_FAIL,
                        error: error.body,
                    });
                }
            );
        };
    },
};

export default actions;

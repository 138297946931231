import _ from 'lodash';

const rules = {
    admin: ['media_buyer', 'co_managed_client', 'client'],
    media_buyer: ['co_managed_client', 'client'],
};

const orgTypesWithCreationPermission = Object.keys(rules);

const getAllowOrgCreationTypes = function(org) {
    if (_.includes(orgTypesWithCreationPermission, org)) {
        const allowAbleCreationTypes = rules[org];
        return allowAbleCreationTypes;
    } else if (org === void 0) {
        return [];
    } else {
        throw new Error(`"${org}" has no permission to create organization}`);
    }
};

export { getAllowOrgCreationTypes };

import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import AppCategoryForm from './form';
import flags from 'containers/flags/service';

class AppCategoriesTarget extends React.Component {
    static propTypes = {
        isAppStoreCatEnabled: PropTypes.bool,
        targetDraft: PropTypes.object,
        showErrors: PropTypes.bool,
        onDraftChange: PropTypes.func,
        onUnmount: PropTypes.func,
    };

    componentWillUnmount() {
        this.props.onUnmount();
    }

    render() {
        const {
            targetDraft,
            showErrors,
            onDraftChange,
            isAppStoreCatEnabled,
            isProspectMedia,
        } = this.props;

        if (!isAppStoreCatEnabled) {
            return null;
        }

        let exclude = targetDraft.exclude;

        if (exclude === null || exclude === undefined) {
            if (targetDraft.target_app_store_cat && targetDraft.target_app_store_cat_exclude) {
                exclude = targetDraft.target_app_store_cat_exclude.length > 0;
            } else {
                exclude = false;
            }
        }

        return (
            <AppCategoryForm
                target_app_store_cat={targetDraft.target_app_store_cat}
                target_app_store_cat_exclude={targetDraft.target_app_store_cat_exclude}
                exclude={exclude}
                showErrors={showErrors}
                onDraftChange={onDraftChange}
                isProspectMedia={isProspectMedia}
            />
        );
    }
}

function selector(storeState, props) {
    const isAppStoreCatEnabled = _.get(
        storeState,
        `resources.campaigns.${props.campaignId}.attr.isAppStoreCatEnabled`,
        props.isAppStoreCatEnabled
    );

    const orgId = _.get(storeState, `profile.organizationId`);
    const isProspectMedia = orgId === '61ba37fb834aef8e532e0687';

    return {
        isAppStoreCatEnabled,
        isProspectMedia,
    };
}

export default connect(selector)(AppCategoriesTarget);

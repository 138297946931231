import VError from 'verror';
import _ from 'lodash';
import toastr from 'toastr';

import CreativesActions from 'states/resources/creatives/actions';
import { createHttp } from 'utils/http';
import notify from 'utils/notify';
import Files from 'states/resources/files/actions';
import acceptedMIMETypes from 'common/constants/acceptedMIMETypes';
import { TRACKING_VENDOR_TAGS } from '../../../common/constants/vendors';
import { getSuggestedVendors } from '../creative-form/reducer';

import { getValidImageSizeMapping, DEFAULT_RENDER_DELAY } from 'forms/creative-form/constants';
import {
    getTagsByCreativeVendor,
    parseEngageFrontTags,
} from './services/rich-media-template-parser';

import { getEnvironmentSettings } from 'services/environment';

const useJobForBulkCreativeScreenshots = getEnvironmentSettings().useJobForBulkCreativeScreenshots();

const ONE_MILLION = 1000000;
const TWENTY = 20;
const FIVE_SECONDS = 5000;

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const formatDraft = ({ draft, creativeVendor, specifiedThirdPartyVendors, trackingVendors }) => {
    let third_party_vendors =
        creativeVendor === 'generic' ? specifiedThirdPartyVendors : draft.third_party_vendors;

    if (trackingVendors.length > 0) {
        third_party_vendors = [...third_party_vendors, ...trackingVendors];
    }

    return {
        ...draft,
        audit_status: 'pending',
        third_party_vendors,
        tracking_vendors: trackingVendors,
        clickthrough_url: _.trim(draft.clickthrough_url),
        landing_page: _.trim(draft.landing_page),
        third_party_pixels: _.map(draft.third_party_pixels, pixel => _.trim(pixel)),
    };
};

const actions = {
    closeForm() {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_UPLOAD_FORM__CLOSE_FORM',
            });
        };
    },
    openForm({ campaignId, format, campaignType }) {
        return dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_UPLOAD_FORM__OPEN_FORM',
                payload: { campaignId, format, campaignType },
            });
        };
    },
    updateDraft({ creativeKey, field, value }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__UPDATE_DRAFT',
            payload: { field, value, creativeKey },
        };
    },
    changeCreativeVendor({ value }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__CHANGE_CREATIVE_VENDOR',
            payload: { value },
        };
    },
    changeSpecificThirdPartyVendor({ value }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__SPECIFY_THIRD_PARTY_VENDORS',
            payload: { value },
        };
    },
    changePlatforms({ creativeKey, checked, value }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__CHANGE_PLATFORM',
            payload: { creativeKey, value, checked },
        };
    },
    addThirdPartyPixelInput({ creativeKey }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__ADD_THIRD_PARTY_PIXEL',
            payload: { creativeKey },
        };
    },
    removeThirdPartyPixel({ creativeKey, index }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__REMOVE_THIRD_PARTY_PIXEL',
            payload: { creativeKey, index },
        };
    },
    changeThirdPartyPixel({ creativeKey, index, value }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__CHANGE_THIRD_PARTY_PIXEL',
            payload: { creativeKey, index, value },
        };
    },
    addThirdPartyJavascriptInput({ creativeKey }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__ADD_THIRD_PARTY_JS_PIXEL',
            payload: { creativeKey },
        };
    },
    removeThirdPartyJavascript({ creativeKey, index }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__REMOVE_THIRD_PARTY_JS_PIXEL',
            payload: { creativeKey, index },
        };
    },
    changeThirdPartyJavascript({ creativeKey, index, value }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__CHANGE_THIRD_PARTY_JS_PIXEL',
            payload: { creativeKey, index, value },
        };
    },
    changeClickThroughUrl({ creativeKey, value }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__CHANGE_CLICKTHROUGH_URL',
            payload: { creativeKey, value },
        };
    },
    toggleClickThroughUrl({ creativeKey, checked }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__TOGGLE_CLICKTHROUGH_URL',
            payload: { creativeKey, checked },
        };
    },
    changeSize({ creativeKey, size }) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'CREATIVE_BULK_UPLOAD__UPDATE_SIZE',
                payload: { size, creativeKey },
            });

            if (!useJobForBulkCreativeScreenshots) {
                const htmlContent = _.get(
                    getState(),
                    `bulkUploadCreativesForm.draft.${creativeKey}.content_html`
                );

                await dispatch(
                    actions.generateReferenceImage({
                        html: htmlContent,
                        creativeKey: creativeKey,
                    })
                );
            }
        };
    },
    changeImage(fileData, campaignId, creativeKey) {
        return async dispatch => {
            dispatch({
                type: 'CREATIVE_BULK_UPLOAD_FORM__CHANGE_IMAGE__START',
                payload: {
                    campaignId,
                    creativeKey,
                },
            });

            try {
                const uploadedFile = await dispatch(Files.create(campaignId, fileData));
                const isValidSize = getValidImageSizeMapping()[
                    `${uploadedFile.width}x${uploadedFile.height}`
                ];

                if (!isValidSize) {
                    let fileName = '';
                    for (var data of fileData.values()) {
                        fileName = data.name;
                    }
                    toastr.warning(
                        '',
                        `<p>Image ${fileName} was not a valid size</p>`,
                        TOASTR_OPTIONS
                    );
                    dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__CHANGE_IMAGE__ERROR',
                        payload: {
                            creativeKey,
                        },
                    });
                    cancelFileUpload(dispatch, campaignId, file.name, 'invalid size');
                    return;
                }
                dispatch({
                    type: 'SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS',
                    payload: {
                        file: uploadedFile,
                        campaignId,
                    },
                });

                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__CHANGE_IMAGE__SUCCESS',
                    payload: {
                        file: uploadedFile,
                        campaignId,
                        creativeKey,
                    },
                });
            } catch (error) {
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__CHANGE_IMAGE__ERROR',
                    payload: {
                        creativeKey,
                    },
                });
                notify({
                    error: new VError(error, 'failed to upload creative image for bulk upload'),
                    metaData: { campaignId },
                });
                dispatch({
                    type: 'SYSTEM__CAMPAIGN_FILES__UPLOAD__ERROR',
                    payload: {},
                });
            }

            return;
        };
    },
    fileUploadSubmit(fileData, campaignId, file) {
        return async dispatch => {
            const environmentSettings = getEnvironmentSettings();
            if (!file) {
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT',
                    payload: {
                        campaignId,
                    },
                });

                try {
                    const uploadedFile = await dispatch(
                        Files.create(campaignId, fileData, onProgress, onComplete)
                    );
                    const isValidSize = getValidImageSizeMapping()[
                        `${uploadedFile.width}x${uploadedFile.height}`
                    ];

                    if (!isValidSize) {
                        let fileName = '';
                        for (var data of fileData.values()) {
                            fileName = data.name;
                        }
                        toastr.warning(
                            '',
                            `<p>Image ${fileName} was not a valid size</p>`,
                            TOASTR_OPTIONS
                        );
                        cancelFileUpload(dispatch, campaignId, uploadedFile.name, 'invalid size');
                        return;
                    }
                    dispatch({
                        type: 'SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS',
                        payload: {
                            file: uploadedFile,
                            campaignId,
                        },
                    });
                    dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUCCESS',
                        payload: {
                            file: uploadedFile,
                            campaignId,
                            creativePlatformRestrictions: environmentSettings.getCreativePlatformRestrictions(),
                        },
                    });
                } catch (error) {
                    dispatch({
                        type: 'SYSTEM__CAMPAIGN_FILES__UPLOAD__ERROR',
                        payload: {},
                    });
                }

                return;
            }

            const { name, size, type } = file;

            if (size / ONE_MILLION > TWENTY) {
                cancelFileUpload(dispatch, campaignId, name, 'over 20mb');
                return false;
            }

            if (!_.includes(acceptedMIMETypes, type)) {
                cancelFileUpload(dispatch, campaignId, name, 'incorrect type');
                return false;
            }

            dispatch({
                type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT',
                payload: {
                    campaignId,
                },
            });

            function onProgress(percent) {
                if (percent >= 0) {
                    const percentAsMultipleOfTen = Math.round(percent / 10) * 10;

                    dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT__PROGRESS',
                        payload: {
                            campaignId,
                            assets: {
                                [name]: {
                                    progress: percentAsMultipleOfTen,
                                },
                            },
                        },
                    });
                }
            }

            function onComplete() {
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT__PROGRESS_COMPLETE',
                    payload: {
                        campaignId,
                        assets: {
                            [name]: {
                                isComplete: true,
                                progress: 100,
                            },
                        },
                    },
                });
            }

            try {
                const uploadedFile = await dispatch(
                    Files.create(campaignId, fileData, onProgress, onComplete)
                );
                const isValidSize = getValidImageSizeMapping()[
                    `${uploadedFile.width}x${uploadedFile.height}`
                ];

                if (!isValidSize) {
                    toastr.warning('', `<p>Image ${name} was not a valid size</p>`, TOASTR_OPTIONS);
                    cancelFileUpload(dispatch, campaignId, file.name, 'invalid size');
                    return;
                }
                dispatch({
                    type: 'SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS',
                    payload: {
                        file: uploadedFile,
                        campaignId,
                    },
                });
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUCCESS',
                    payload: {
                        file: uploadedFile,
                        campaignId,
                        creativePlatformRestrictions: environmentSettings.getCreativePlatformRestrictions(),
                    },
                });

                setTimeout(() => {
                    dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__CLEAR_TEMP',
                        payload: {
                            campaignId,
                            asset: name,
                        },
                    });
                }, FIVE_SECONDS);
            } catch (error) {
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT_FAIL',
                    payload: {
                        campaignId,
                        failedAsset: name,
                        assets: {
                            [name]: {
                                didFail: true,
                                progress: 0,
                            },
                        },
                    },
                });

                cancelFileUpload(dispatch, campaignId, name);
            }
        };
    },
    processUploadedTemplate(formData) {
        return async (dispatch, getState) => {
            const http = createHttp();
            const environmentSettings = getEnvironmentSettings();
            const file = formData.get('asset');
            const reader = new FileReader();

            const creativeVendor = _.get(getState(), `bulkUploadCreativesForm.creativeVendor`);
            const campaignId = _.get(getState(), `bulkUploadCreativesForm.campaignId`);

            dispatch({
                type: 'CREATIVE_BULK_UPLOAD_FORM__PROCESS_TEMPLATE',
                payload: { fileName: file.name },
            });

            if (creativeVendor === 'generic') {
                reader.addEventListener('loadend', async () => {
                    const { tags, error, lineErrors } = parseEngageFrontTags(reader.result);

                    if (error) {
                        toastr.warning(
                            `<p>${error.message}: ${_.join(error.headerDifference, ',')}</p>`,
                            TOASTR_OPTIONS
                        );
                        return dispatch({
                            type: 'CREATIVE_BULK_UPLOAD_FORM__PROCESS_TEMPLATE__FAILURE',
                            payload: { error },
                        });
                    }

                    const lineErrorKeys = Object.keys(lineErrors);
                    const validTags = _.filter(
                        tags,
                        tag => !_.includes(lineErrorKeys, tag.creativeKey)
                    );

                    if (lineErrorKeys.length > 0) {
                        const invalidLines = _.map(
                            lineErrorKeys,
                            errorKey => lineErrors[errorKey].line
                        );

                        toastr.warning(
                            '',
                            `<p>Invalid size on line: ${_.join(invalidLines, ',')}</p>`
                        );

                        dispatch({
                            type: 'CREATIVE_BULK_UPLOAD_FORM__SHOW_LINE_ERRORS',
                            payload: {
                                lineErrors,
                            },
                        });
                    }

                    dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__PROCESS_TEMPLATE__SUCCESS',
                        payload: {
                            tags,
                            creativePlatformRestrictions: environmentSettings.getCreativePlatformRestrictions(),
                        },
                    });

                    if (!useJobForBulkCreativeScreenshots) {
                        try {
                            for await (const tag of validTags) {
                                await dispatch(
                                    actions.generateReferenceImage({
                                        html: tag.content_html,
                                        creativeKey: tag.creativeKey,
                                    })
                                );
                            }
                        } catch (error) {
                            notify({
                                error: new VError(
                                    error,
                                    'failed to generate reference image in bulk upload'
                                ),
                                metaData: { campaignId },
                            });
                        }
                    }
                });

                reader.readAsText(file);
            } else {
                const { Sheets } = await http.postFile('excel', formData);

                const { tags, error } = getTagsByCreativeVendor({
                    rawSheets: Sheets,
                    creativeVendor,
                });

                const creativeKeyModifiedTags = _.map(tags, tag => {
                    return { ...tag, creativeKey: `${tag.creativeKey}_${tag.size}` };
                });

                if (error) {
                    toastr.warning(`<p>${error}</p>`, TOASTR_OPTIONS);

                    return dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__PROCESS_TEMPLATE__FAILURE',
                        payload: { error },
                    });
                }

                const invalidSizes = _.chain(tags)
                    .map('size')
                    .filter(size => !getValidImageSizeMapping()[size])
                    .uniq()
                    .value();

                if (!_.isEmpty(invalidSizes)) {
                    const error = `Invalid creative sizes: ${invalidSizes.join(', ')}`;
                    toastr.warning(error, TOASTR_OPTIONS);

                    return dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__PROCESS_TEMPLATE__FAILURE',
                        payload: { error },
                    });
                }

                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__PROCESS_TEMPLATE__SUCCESS',
                    payload: {
                        tags: creativeKeyModifiedTags,
                        creativePlatformRestrictions: environmentSettings.getCreativePlatformRestrictions(),
                    },
                });

                if (!useJobForBulkCreativeScreenshots) {
                    try {
                        for await (const tag of creativeKeyModifiedTags) {
                            await dispatch(
                                actions.generateReferenceImage({
                                    html: tag.content_html,
                                    creativeKey: tag.creativeKey,
                                })
                            );
                        }
                    } catch (error) {
                        notify({
                            error: new VError(
                                error,
                                'failed to generate reference image in bulk upload'
                            ),
                            metaData: { campaignId },
                        });
                    }
                }
            }
        };
    },
    deleteRow({ creativeKey }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__DELETE_CREATIVE',
            payload: { creativeKey },
        };
    },
    duplicateRow({ creativeKey }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__DUPLICATE_CREATIVE',
            payload: { creativeKey },
        };
    },
    toggleMraid({ creativeKey, checked }) {
        return {
            type: 'CREATIVE_BULK_UPLOAD_FORM__TOGGLE_MRAID',
            payload: { creativeKey, checked },
        };
    },
    showFileTypeError(files) {
        return {
            type: 'CREATIVE_BULK_UPLOAD__SET_FILE_TYPE_ERROR',
            payload: { files },
        };
    },
    generateReferenceImage({ html, creativeKey }) {
        return async (dispatch, getState) => {
            const http = createHttp();
            const campaignId = _.get(getState(), `bulkUploadCreativesForm.campaignId`);
            const versionBefore = _.get(getState(), `bulkUploadCreativesForm.version`);
            dispatch({
                type: 'CREATIVE_BULK_UPLOAD_FORM__GENERATE_IMAGE_START',
                payload: { creativeKey },
            });
            const creative = _.get(getState(), `bulkUploadCreativesForm.draft.${creativeKey}`);
            const [width, height] = _.get(creative, 'size').split('x');
            try {
                const file = await http.post(`campaigns/${campaignId}/files`, {
                    html,
                    campaignId,
                    dimensions: { width: Number(width), height: Number(height) },
                    renderDelay: DEFAULT_RENDER_DELAY,
                    shouldDeleteUnused: false,
                });
                const versionAfter = _.get(getState(), `bulkUploadCreativesForm.version`);
                if (versionBefore === versionAfter) {
                    dispatch({
                        type: 'CREATIVE_BULK_UPLOAD_FORM__GENERATE_IMAGE_SUCCESS',
                        payload: { file, creativeKey },
                    });
                    dispatch({
                        type: 'SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS',
                        payload: {
                            file,
                            campaignId,
                        },
                    });
                }
            } catch (error) {
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__GENERATE_IMAGE_ERROR',
                    payload: { creativeKey },
                });
                notify({
                    error: new VError(error, 'failed to generate reference image in bulk upload'),
                    metaData: { html, campaignId, dimensions: `${width}x${height}` },
                });
            }
        };
    },
    submitDraft() {
        return async (dispatch, getState) => {
            dispatch({
                type: 'CREATIVE_BULK_UPLOAD_FORM__SUBMIT_START',
            });

            const errorsInDraft = _.get(getState(), 'bulkUploadCreativesForm.errors');
            if (Object.keys(errorsInDraft).length > 0) {
                return dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__SHOW_ERRORS',
                });
            }
            const uploadedCreativesDraft = _.get(getState(), 'bulkUploadCreativesForm.draft');
            const campaignId = _.get(getState(), 'bulkUploadCreativesForm.campaignId');
            const creativeVendor = _.get(getState(), 'bulkUploadCreativesForm.creativeVendor');
            const specifiedThirdPartyVendors = _.get(
                getState(),
                'bulkUploadCreativesForm.specifiedThirdPartyVendors'
            );

            if (creativeVendor === 'generic' && specifiedThirdPartyVendors.length === 0) {
                return dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__SHOW_CREATIVE_VENDOR_ERROR',
                });
            }
            const creativeKeyList = _.keys(uploadedCreativesDraft);
            try {
                for await (const key of creativeKeyList) {
                    const draft = uploadedCreativesDraft[key];
                    const { tagsSuggested } = getSuggestedVendors({
                        draft,
                        selectedThirdPartyVendors: [],
                        vendorTagsByType: TRACKING_VENDOR_TAGS,
                    });
                    let suggestedTagValues = [];
                    if (tagsSuggested.length > 0) {
                        _.forEach(tagsSuggested, suggestion =>
                            suggestedTagValues.push(suggestion.value)
                        );
                    }

                    const payload = formatDraft({
                        draft,
                        creativeVendor,
                        specifiedThirdPartyVendors,
                        trackingVendors: suggestedTagValues,
                    });
                    await dispatch(CreativesActions.create(campaignId, payload));
                }
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__SUBMIT_SUCCESS',
                    payload: { campaignId },
                });
            } catch (error) {
                dispatch({
                    type: 'CREATIVE_BULK_UPLOAD_FORM__SUBMIT_FAIL',
                });
                notify({
                    error: new VError(error, 'failed to save creatives in bulk upload'),
                    metaData: { campaignId, uploadedCreativesDraft },
                });
            }

            dispatch(actions.closeForm());
        };
    },
};

function cancelFileUpload(dispatch, campaignId, name, reason) {
    setTimeout(() => {
        dispatch({
            type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__CLEAR_TEMP',
            payload: {
                campaignId,
                asset: name,
            },
        });
    }, FIVE_SECONDS);

    return dispatch({
        type: 'CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT_FAIL',
        payload: {
            campaignId,
            failedAsset: {
                name,
                reason,
            },
        },
    });
}

export default actions;

import _ from 'lodash';

import c from 'common/constants/flux-events';

export const NAME = 'resources.stats.campaigns.report.geo';
export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.REPORT_GEO__ADGROUP_STATS__FETCH: {
            const { campaignId, component } = action.payload;
            const report = _.get(state, campaignId, {});

            return {
                ...state,
                [campaignId]: {
                    ...report,
                    [component.id]: {
                        ...report[component.id],
                        isLoading: true,
                    },
                },
            };
        }
        case c.REPORT_GEO__ADGROUP_STATS__FETCH__SUCCESS: {
            const { campaignId, component, response } = action.payload;

            const report = _.get(state, campaignId, {});

            return {
                ...state,
                [campaignId]: {
                    ...report,
                    [component.id]: {
                        ...response,
                        isLoading: false,
                    },
                },
            };
        }
        default:
            return state;
    }
}

import _ from 'lodash';

export default function selector(storeState, props) {
    const { categories, loading } = _.get(storeState, 'flexCategoriesDashboard');

    return {
        categories,
        loading,
    };
}

import _ from 'lodash';

import {
    payload_formData as draftDefaults,
    DESKTOP_DEVICE_OS_OPTIONS,
} from '../../services/default-values';

export default function platformChangeHandler() {
    return {
        description: 'Handle platform change',
        target(state, action) {
            return 'AD_FORM__PLATFORM__CHANGE' === action.type;
        },
        reduce(state, action) {
            if (state.mode === 'edit') {
                return state;
            }

            const { platform } = action.payload;

            const target_device_os =
                platform === 'desktop'
                    ? _.map(DESKTOP_DEVICE_OS_OPTIONS, o => o.value)
                    : draftDefaults.target_device_os;

            const defaults = _.pick(draftDefaults, [
                'creative',
                'rotation_rules',
                'forensiq_risk_cap',
                'geoboxes',
                'geo_targeting_settings',
                'target_carriers',
                'target_device_language',
                'target_device_language_exclude',
                'target_app_store_cat',
                'target_app_store_cat_exclude',
                'target_age_groups',
                'target_genders',
                'audiences',
                'audience_exclude',
                'tactics_generators',
            ]);

            let ias = state.draft.ias;
            if (platform !== 'inapp') {
                ias = {
                    ...state.draft.ias,
                    brandSafety: {
                        ...state.draft.ias.brandSafety,
                        gambling: draftDefaults.ias.brandSafety.gambling,
                    },
                };
            }

            return {
                ...state,
                draft: {
                    ...state.draft,
                    ...defaults,
                    platform,
                    target_device_os,
                    ias,
                },
            };
        },
    };
}

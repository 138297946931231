import { actions } from './reducer';

const processCreativesMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    if (action.type === 'CREATIVE_BULK_EDIT:UPDATED') {
        const { updatedCreatives } = action.payload;
        dispatch(actions.updateBulkEditProgress(updatedCreatives));
    }

    if (action.type === 'CREATIVE_BULK_EDIT_FORM__CLOSE_FORM') {
        dispatch(actions.bulkEditSuccess());
    }
};

export const makeMiddlewares = deps => {
    return [processCreativesMw(deps)];
};
export const middlewares = makeMiddlewares({});

import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.geoCategories';

export default function geoCategories(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__GEO_CATEGORIES__FETCH__SUCCESS: {
            const { categories } = action.payload;
            return {
                ...state,
                geoCategories: categories,
            };
        }

        case c.SYSTEM__GEO_LAYERS__FETCH__SUCCESS: {
            const { layers } = action.payload;

            return {
                ...state,
                geoLayers: layers,
            };
        }

        case c.SYSTEM__GEO_LAYERS__DELETE__SUCCESS: {
            const layerIdToRemove = action.payload.layerId;
            const geoLayers_prev = _.get(state, `geoLayers`, []);
            const geoLayers_next = _.cloneDeep(geoLayers_prev);
            _.remove(geoLayers_next, itm => itm.id === layerIdToRemove);

            const o = {
                ...state,
                geoLayers: geoLayers_next,
            };
            return o;
        }

        case c.SYSTEM__GEO_LAYERS__UPDATE__SUCCESS: {
            const { layer } = action.payload;
            const id_targetedLayer = layer.id;

            const geoLayersInResource = _.get(state, `geoLayers`, []);
            const index_targetedGeoLayerInStore = _.findIndex(
                geoLayersInResource,
                itm => id_targetedLayer === itm.id
            );
            if (index_targetedGeoLayerInStore === -1) {
                throw new Error('server return geo-layer with id not found on client', layer);
            }

            geoLayersInResource[index_targetedGeoLayerInStore] = layer;
            const o = {
                ...state,
                geoLayers: geoLayersInResource,
            };
            return o;
        }

        case c.SYSTEM__GEO_LAYERS__POST__SUCCESS:
            const { layer } = action.payload;
            const geoLayers_prev = _.get(state, `geoLayers`, []);
            const geoLayers_next = _.cloneDeep(geoLayers_prev);
            geoLayers_next.push(layer);

            const o = {
                ...state,
                geoLayers: geoLayers_next,
            };
            return o;

        default:
            return state;
    }
}

import _ from 'lodash';
import c from 'common/constants/flux-events';
import indexBy from 'utils/indexBy';

const Transformer = {
    updateStyles(metricComponentsConfig) {
        return _.map(metricComponentsConfig, component => {
            switch (component.category) {
                case 'basic':
                default:
                    return component;
            }
        });
    },
};


const metricComponentsConfigWithSpendBreakdown = [
    {
        metricType: 'impressions',
        status: 'selected',
        style: 'impressions',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Impressions',
        isCurrencyType: false,
        serverName: 'impressions',
    },
    {
        metricType: 'clicks',
        status: 'visible',
        style: 'clicks',
        category: 'basic',
        formatType: 'thousands',
        presentationName: 'Clicks',
        isCurrencyType: false,
        serverName: 'clicks',
    },
    {
        metricType: 'owner_inventory_cost_local',
        status: 'visible',
        style: 'inventory',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Inventory Cost',
        isCurrencyType: true,
        serverName: 'inventory_cost',
    },
    {
        metricType: 'owner_media_cost_2_local',
        status: 'visible',
        style: 'inventory',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Media Cost',
        isCurrencyType: true,
        serverName: 'owner_media_cost_2_local',
    },
    {
        metricType: 'data_cost',
        status: 'visible',
        style: 'data_cost',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Data Cost',
        isCurrencyType: true,
        serverName: 'data_cost',
    },
    {
        metricType: 'spend',
        status: 'visible',
        style: 'spend',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Billable Cost',
        isCurrencyType: true,
        serverName: 'mediaCost',
    },
    {
        metricType: 'third_party_fees',
        status: 'visible',
        style: 'third_party_fees',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Third Party Fees',
        isCurrencyType: true,
        serverName: 'third_party_fees',
    },
    {
        metricType: 'owner_total_media_cost_local',
        status: 'visible',
        style: 'owner_total_media_cost_local',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Total Cost',
        isCurrencyType: true,
        serverName: 'owner_total_media_cost_local',
    },
    {
        metricType: 'billings_local',
        status: 'visible',
        style: 'revenue',
        category: 'basic',
        formatType: 'dollar',
        presentationName: 'Revenue',
        isCurrencyType: true,
        serverName: 'billings',
    },
];

const initialState = {
    stats: [],
    currentSelectedMetricType: 'impressions',
    isVisibleBeaconsAtLimit: false,
    metricComponentsConfig: [
        {
            metricType: 'impressions',
            status: 'selected',
            style: 'impressions',
            category: 'basic',
            formatType: 'thousands',
            presentationName: 'Impressions',
            isCurrencyType: false,
            serverName: 'impressions',
        },
        {
            metricType: 'clicks',
            status: 'visible',
            style: 'clicks',
            category: 'basic',
            formatType: 'thousands',
            presentationName: 'Clicks',
            isCurrencyType: false,
            serverName: 'clicks',
        },
        {
            metricType: 'owner_inventory_cost_local',
            status: 'visible',
            style: 'inventory',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Inventory Cost',
            isCurrencyType: true,
            serverName: 'inventory_cost',
        },
        {
            metricType: 'owner_media_cost_2_local',
            status: 'visible',
            style: 'inventory',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Media Cost',
            isCurrencyType: true,
            serverName: 'owner_media_cost_2_local',
        },
        {
            metricType: 'spend',
            status: 'visible',
            style: 'spend',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Billable Cost',
            isCurrencyType: true,
            serverName: 'mediaCost',
        },
        {
            metricType: 'billings_local',
            status: 'visible',
            style: 'revenue',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Revenue',
            isCurrencyType: true,
            serverName: 'billings',
        },
    ],
};

export default function(state = initialState, action) {
    switch (action.type) {
        case c.ACCOUNT__INITIALIZE__SUCCESS: {
            const metricComponentsConfig = metricComponentsConfigWithSpendBreakdown;

            return {
                ...state,
                stats: action.payload.pivotTableStats.stats,
                metricComponentsConfig,
            };
        }

        case c.ACCOUNT__METRIC_SELECTOR__DATA_REFRESH__SUCCESS: {
            return {
                ...state,
                stats: action.payload.stats.stats,
            };
        }

        case c.ACCOUNT__METRIC_SELECTOR__INIT: {
            const { query } = action.payload;
            const metrics_on = _.get(query, 'metrics_on', []);
            const metrics_off = _.get(query, 'metrics_off', []);

            const nextMetricComponentsConfig = _.map(state.metricComponentsConfig, metric => {
                return {
                    ...metric,
                    ...(metrics_on.length && {
                        status:
                            metrics_on.indexOf(metric.metricType) > -1 ? 'visible' : metric.status,
                    }),
                    ...(metrics_off.length && {
                        status:
                            metrics_off.indexOf(metric.metricType) > -1 ? 'hidden' : metric.status,
                    }),
                };
            });

            return {
                ...state,
                metricComponentsConfig: nextMetricComponentsConfig,
            };
        }

        case c.ACCOUNT__METRIC_SELECTOR__TOGGLE_VISIBILITY: {
            const { metricComponentsConfig } = state;
            const { metric } = action.payload;

            const targetMetric = _.find(metricComponentsConfig, { metricType: metric.metricType });
            const firstVisibleMetric = _.find(metricComponentsConfig, { status: 'visible' });
            const firstSelectedMetric = _.find(metricComponentsConfig, { status: 'selected' });

            // given current status, determine next status
            switch (metric.status) {
                case 'selected': {
                    const nextTargetMetricState = {
                        ...targetMetric,
                        status: 'hidden',
                    };
                    const nextSelectedMetricState = {
                        ...(firstVisibleMetric || firstSelectedMetric),
                        status: 'selected',
                    };

                    const nextMetricComponentsConfig = mergeMetrics(metricComponentsConfig, [
                        nextTargetMetricState,
                        nextSelectedMetricState,
                    ]);

                    const nextMetricComponentsConfigWithUpdatedStyles = Transformer.updateStyles(
                        nextMetricComponentsConfig
                    );

                    return {
                        ...state,
                        metricComponentsConfig: nextMetricComponentsConfigWithUpdatedStyles,
                        currentSelectedMetricType: nextSelectedMetricState.metricType,
                    };
                }
                case 'hidden':
                case 'visible': {
                    const nextStatus = metric.status === 'hidden' ? 'visible' : 'hidden';
                    const nextTargetMetricState = {
                        ...targetMetric,
                        status: nextStatus,
                    };

                    const nextMetricComponentsConfig = mergeMetrics(metricComponentsConfig, [
                        nextTargetMetricState,
                    ]);

                    const nextMetricComponentsConfigWithUpdatedStyles = Transformer.updateStyles(
                        nextMetricComponentsConfig
                    );

                    return {
                        ...state,
                        metricComponentsConfig: nextMetricComponentsConfigWithUpdatedStyles,
                    };
                }
                default:
                    return state;
            }
        }

        case c.ACCOUNT__METRIC_SELECTOR__TOGGLE_SELECTION: {
            const { metricComponentsConfig } = state;
            const { metric } = action.payload;

            const targetMetric = _.find(metricComponentsConfig, { metricType: metric.metricType });
            const currentSelectedMetric = _.find(metricComponentsConfig, { status: 'selected' });
            const firstVisibleMetric = _.find(metricComponentsConfig, { status: 'visible' });
            const firstSelectedMetric = _.find(metricComponentsConfig, { status: 'selected' });

            switch (metric.status) {
                case 'selected': {
                    const nextTargetMetricState = {
                        ...targetMetric,
                        status: 'visible',
                    };
                    const nextSelectedMetricState = {
                        ...(firstVisibleMetric || firstSelectedMetric),
                        status: 'selected',
                    };

                    const nextMetricComponentsConfig = mergeMetrics(metricComponentsConfig, [
                        nextTargetMetricState,
                        nextSelectedMetricState,
                    ]);

                    const nextMetricComponentsConfigWithUpdatedStyles = Transformer.updateStyles(
                        nextMetricComponentsConfig
                    );

                    return {
                        ...state,
                        metricComponentsConfig: nextMetricComponentsConfigWithUpdatedStyles,
                        currentSelectedMetricType: nextSelectedMetricState.metricType,
                    };
                }

                case 'hidden':
                case 'visible': {
                    const nextTargetMetricState = {
                        ...targetMetric,
                        status: 'selected',
                    };
                    const nextCurrentSelectedMetric = {
                        ...currentSelectedMetric,
                        status: 'visible',
                    };

                    const nextMetricComponentsConfig = mergeMetrics(metricComponentsConfig, [
                        nextTargetMetricState,
                        nextCurrentSelectedMetric,
                    ]);

                    const nextMetricComponentsConfigWithUpdatedStyles = Transformer.updateStyles(
                        nextMetricComponentsConfig
                    );

                    return {
                        ...state,
                        metricComponentsConfig: nextMetricComponentsConfigWithUpdatedStyles,
                        currentSelectedMetricType: nextTargetMetricState.metricType,
                    };
                }
                default:
                    return state;
            }
        }

        default:
            return state;
    }
}

function mergeMetrics(metricComponentsConfig, metrics) {
    const metricsIndexed = indexBy(metrics, 'metricType');

    return _.map(metricComponentsConfig, component => {
        if (metricsIndexed[component.metricType]) {
            return metricsIndexed[component.metricType];
        }

        return component;
    });
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Textarea from 'react-textarea-autosize';

class AutosizeTextarea extends PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        minRows: PropTypes.number,
        maxRows: PropTypes.number,
    };

    static defaultProps = {
        className: null,
        style: null,
        placeholder: '',
    };

    render() {
        return (
            <Textarea
                className={classnames('ef5-autosize-textarea', this.props.className)}
                value={this.props.value}
                placeholder={this.props.placeholder}
                minRows={this.props.minRows}
                maxRows={this.props.maxRows}
                onBlur={this.props.onBlur}
                onChange={e => {
                    this.props.onChange && this.props.onChange(e.target.value);
                }}
            />
        );
    }
}

export default AutosizeTextarea;

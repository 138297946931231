import _ from 'lodash';
import { browserHistory } from 'react-router';
import toastr from 'toastr';
import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import API from 'services/api';
import qs from 'utils/querystring';

import Creatives from 'states/resources/creatives/actions';
import { getConnectedAds } from './v2/selectors';

export function filterCreativeList(campaignId, filterOptions) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_CREATIVES__INIT,
            payload: {
                campaignId,
            },
        });
        const store_filterOptions = _.get(getState(), `creativesOverview.filterOptions`);
        const query = {
            ...store_filterOptions,
            [filterOptions.filterType]: filterOptions.value,
        };

        browserHistory.push(`/campaigns/${campaignId}/setup/creatives?${qs.serialize(query)}`);

        dispatch({
            type: c.OVERVIEW_CREATIVES__CREATIVE_LIST__FILTER,
            payload: {
                campaignId,
                filterType: filterOptions.filterType,
                value: filterOptions.value,
            },
        });
    };
}

export function openDeleteConfirmModal(campaignId, creativeId) {
    return (dispatch, getState) => {
        const ads = _.map(_.get(getState(), 'resources.ads'), adObj => adObj.attr);
        const connectedAds = getConnectedAds(ads, creativeId);

        dispatch({
            type: 'OVERVIEW_CREATIVES__OPEN_DELETE_CONFIRM_MODAL',
            payload: { creativeToDelete: creativeId, connectedAds },
        });
    };
}

export function closeDeleteConfirmModal() {
    return {
        type: 'OVERVIEW_CREATIVES__CLOSE_DELETE_CONFIRM_MODAL',
        payload: {},
    };
}

export function deleteCreative(campaignId, creativeId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_CREATIVES__CREATIVE_DELETE,
            payload: {},
        });

        return dispatch(Creatives.delete(campaignId, creativeId)).then(
            response => {
                dispatch({
                    type: c.OVERVIEW_CREATIVES__CREATIVE_DELETE__SUCCESS,
                    payload: {
                        creativeId,
                        campaignId,
                    },
                });

                fetchCampaign(campaignId)(dispatch, getState);
                dispatch(closeDeleteConfirmModal());
            },

            error => console.error(error)
        );
    };
}

import { defaultSetting } from 'forms/creative-form/services';
export function duplicateCreative(campaignId, creativeId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW__CREATIVE_DRAFT_NEW__OPEN,
            payload: { campaignId },
        });

        const creativeToDuplicate = _.get(getState(), `resources.creatives.${creativeId}`);
        const attrsToDuplicate = _.omit(creativeToDuplicate.attr, ['id', 'audit_status']); // <-- note that we don't want to copy id and audit status
        const creativeFormat = _.get(attrsToDuplicate, `format`, void 0);

        const manifest_creativeFormat = _.get(defaultSetting, `manifest.${creativeFormat}`, []);
        const manifest = [...manifest_creativeFormat];
        const attrPicked_default = _.pick(defaultSetting.defaultValues, manifest);
        const attrPicked_toDuplicate = _.pick(attrsToDuplicate, manifest);

        // Some field has value depedended on other field, and are set automatically
        //      Ideally the duplicated creative shouldn't have missing field, but just in case.
        if (attrPicked_toDuplicate.format === 'native') {
            attrPicked_toDuplicate.size = '0x0';
        }

        const newCreativeAttrs = {
            ...attrPicked_default,
            ...attrPicked_toDuplicate,
        };

        dispatch({
            type: c.OVERVIEW__CREATIVE_DRAFT_NEW__INIT,
            payload: { campaignId, creative: newCreativeAttrs },
        });
    };
}

export function expandCreative(campaignId, creativeId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_CREATIVES__CREATIVE_EXPAND,
            payload: { campaignId, creativeId },
        });
    };
}

export function collapseCreative(campaignId, creativeId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_CREATIVES__CREATIVE_COLLAPSE,
            payload: { campaignId, creativeId },
        });
    };
}

export function expandAllCreatives(campaignId, creatives) {
    return (dispatch, getState) => {
        const creativeIds = getCreativeIds(creatives);

        dispatch({
            type: c.OVERVIEW_CREATIVES__CREATIVE_EXPAND_ALL,
            payload: { campaignId, creativeIds },
        });
    };
}

export function collapseAllCreatives(campaignId, creatives) {
    return (dispatch, getState) => {
        const creativeIds = getCreativeIds(creatives);

        dispatch({
            type: c.OVERVIEW_CREATIVES__CREATIVE_COLLAPSE_ALL,
            payload: { campaignId, creativeIds },
        });
    };
}

export function submitFromCreativeOverview(campaignId, creativeId) {
    return (dispatch, getState) => {
        const payload = { audit_status: 'pending' };

        dispatch({
            type: c.OVERVIEW__CREATIVE_AUDIT__UPDATE,
            payload: { creativeId, campaignId, payload },
        });

        const creativeName = _.get(getState(), `resources.creatives.${creativeId}.attr.name`);

        return dispatch(Creatives.update(campaignId, creativeId, payload)).then(
            response => {
                dispatch({
                    type: c.OVERVIEW__CREATIVE_AUDIT__UPDATE_SUCCESS,
                    payload: { creativeId, campaignId },
                });
            },
            error => {
                let message = '';
                _.each(error.body, err => {
                    const field = err.field
                        .split('_')
                        .map(word => _.capitalize(word))
                        .join(' ');
                    message += `<div>${field} ${err.message}</div>`;
                });
                toastr.error(message, `#${creativeId} ${creativeName}`, {
                    closeButton: true,
                    timeOut: 15000,
                });

                dispatch({
                    type: c.OVERVIEW__CREATIVE_AUDIT__UPDATE_FAIL,
                    payload: error.body,
                });
            }
        );
    };
}

function getCreativeIds(creatives) {
    const creativeIds = _.reduce(
        creatives,
        (acc, value) => {
            return _.union(acc, [value.id]);
        },
        []
    );

    return creativeIds;
}

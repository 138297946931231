import _ from 'lodash';
import { createSelector } from 'reselect';
import {
    RevenueModelMapping,
} from 'states/resources/campaigns/business-logic';

import { schema, customValidator } from './schema';

const getDraft = state => _.get(state, 'campaignForm.draft');
const getOriginalCampaign = state => _.get(state, 'campaignForm.originalCampaign');
const getServerErrors = state => _.get(state, 'campaignForm.serverErrors');
const getClientErrors = state => _.get(state, 'campaignForm.clientErrors');
const getFormErrors = state => _.get(state, 'campaignForm.errors');
const getOrganizations = state => _.get(state, 'campaignForm.organizations');
const getOwnOrg = state => _.get(state, 'campaignForm.ownOrganization');
const getFlightMetadata = state => _.get(state, 'campaignForm.flightMetadata');
const getCampaignMetadata = state =>
    _.get(state, ['campaignsOverview', 'campaign', 'metadata'], null);

export const getClientMapping = createSelector(
    getDraft,
    (draft) => {
        let clientMapping = {};
        if (draft) {
            _.each(draft.clients, c => (clientMapping[c.organization] = c));
        }

        return clientMapping;
    }
)

export const getClientGrouping = createSelector(
    getDraft,
    (draft) => {
        if (draft) {
            return _.groupBy(draft.clients, 'type');
        }

        return {};
    }
)

export const getChildOrganizations = createSelector(
    getOrganizations,
    getOwnOrg,
    (organizations, ownOrganization) => {
        if (ownOrganization) {
            return _.omit(organizations, [ownOrganization.id]);
        }
    }
);

export const getSalesReps = createSelector(
    getChildOrganizations,
    (childOrganizations) => {
        return _(childOrganizations)
        .filter(x => x)
        .filter(org => org.attr.client_type === 'sales_rep')
        .map(org => ({
            label: org.attr.name,
            value: org.id,
        }))
        .sortBy('label')
        .value();
    }
);

export const getAdvertisers = createSelector(
    getChildOrganizations,
    (childOrganizations) => {
        return _(childOrganizations)
        .filter(x => x)
        .filter(org => org.attr.client_type === 'advertiser')
        .map(org => ({
            label: org.attr.name,
            revenueModel: org.attr.revenueModel,
            agencyMarginRate: org.attr.agencyMarginRate,
            value: org.id,
        }))
        .sortBy('label')
        .value();
    }
);

export const getPartners = createSelector(
    getChildOrganizations,
    (childOrganizations) => {
        return _(childOrganizations)
        .filter(x => x)
        .filter(org => org.attr.client_type === 'partner')
        .map(org => ({
            label: org.attr.name,
            value: org.id,
        }))
        .sortBy('label')
        .value();
    }
);

export const getThirdPartyFeeErrors = createSelector(
    getFormErrors,
    (errors) => {
        return _(errors)
        .filter(error => /^third_party_fees/.test(error.field))
        .map(error => error.message)
        .uniq()
        .value();
    }
);

export const formatFlightMetrics = createSelector(
    getFlightMetadata,
    getDraft,
    (flightMetadata, draft) => {
        if (!draft) {
            return;
        }
        return (
            _(flightMetadata)
                // NOTE: It's possible the flight is being deleted so we need to check
                // if we find the flight metadata before doing any manipulation.
                .filter(metadata => {
                    return _.find(draft.flights, f => f._id === metadata.flightId);
                })
                .map(metadata => {
                    const flight = _.find(draft.flights, f => f._id === metadata.flightId);

                    if (
                        [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                            draft?.revenueModel
                        )
                    ) {
                        let remaining = flight.total_impressions - metadata.impressions;

                        if (remaining < 0) {
                            remaining = 0;
                        }

                        return { ...metadata, remaining };
                    }

                    const totalCostBudget = flight.total_cost_budget;
                    const spent =
                        draft.revenueModel === RevenueModelMapping.AgencyMargin
                            ? metadata.billings_local
                            : metadata.owner_total_media_cost_local;
                    const revenue = flight.billings_local;

                    // We may have overspent so we need to make sure to only show 0 here in that case.
                    let remaining =
                        draft.revenueModel === RevenueModelMapping.AgencyMargin
                            ? revenue - spent
                            : totalCostBudget - spent;
                    if (remaining < 0) {
                        remaining = 0;
                    }

                    return { ...metadata, remaining };
                })
                .value()
        );
    }
);

export const getShowFta = createSelector(
    getDraft,
    getOwnOrg,
    (draft, ownOrg) => {
        if (!draft) {
            return false;
        }
        if (!ownOrg) {
            return false;
        }

        return (draft.fta_version === 2 && ownOrg.ftaEnabled) || draft.isUsingFtaFullyManagedFields;
    }
);

export const getShowFtaFullyManagedFields = createSelector(
    getDraft,
    draft => {
        if (!draft) {
            return false;
        }

        return draft.isUsingFtaFullyManagedFields && draft.fta_enabled;
    }
);

export const getShowFtaLimitedFields = createSelector(
    getDraft,
    draft => {
        if (!draft) {
            return false;
        }

        return draft.isUsingFtaLimitedFields && draft.fta_enabled;
    }
);

export const getUseDisabledRevenueModelAndAutoBudgetAllocation = createSelector(
    getDraft,
    draft => {
        if (!draft) {
            return false;
        }

        return (
            draft.revenueModel === RevenueModelMapping.Disabled &&
            draft.budget_allocation_method &&
            draft.budget_allocation_method === 'auto'
        );
    }
);

export const getSchemaErrors = createSelector(
    getDraft,
    getOriginalCampaign,
    formatFlightMetrics,
    getCampaignMetadata,
    (draft, originalCampaign, flightMetrics, campaignMetadata) => {
        if (!draft) {
            return {};
        }

        const options = {
            allowUnknown: true,
            abortEarly: false,
            context: {
                draft,
            },
        };

        const schemaErrors = {};
        const result = schema.validate(draft, options);

        if (result.error) {
            _.each(result.error.details, detail => {
                schemaErrors[detail.path.join('.')] = detail.message;
            });
        }

        const customErrors = customValidator({
            draft,
            originalCampaign,
            flightMetrics,
            campaignMetadata,
        });

        return {
            ...schemaErrors,
            ...customErrors,
        };
    }
);

export const getDraftErrors = createSelector(
    getSchemaErrors,
    schemaErrors => {
        return {
            ...schemaErrors,
        };
    }
);

export const getErrors = createSelector(
    getDraftErrors,
    getClientErrors,
    getServerErrors,
    (draftErrors, clientErrors, serverErrors) => {
        const allErrors = { ...draftErrors };

        _.each(clientErrors, error => {
            allErrors[error.field] = [error.message];
        });

        _.each(serverErrors, error => {
            allErrors[error.field] = [error.message];
        });

        return allErrors;
    }
);

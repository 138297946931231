import React from 'react';
import _ from 'lodash';

import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import FlagIcon from '@mui/icons-material/Flag';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Alert from '@mui/material/Alert';

import StandardInput from 'widgets-v5/standard-input';
import CreativeAutoComplete from 'widgets-v5/creative-auto-complete';
import { InfoTooltip } from 'widgets-v6/tooltip';

import Label from '../label/index';
import FormControlStyled from '../form-control-styled/index';
import { ENABLED_CLICKTRACKING_MESSAGE, DISABLED_CLICKTRACKING_MESSAGE } from '../../index';

import { useDispatch } from 'react-redux';
import creativeFormActions from '../../actions';

const useStyles = makeStyles(theme => ({
    timeline: {
        '&:before': {
            content: 'none',
        },
    },
    timelineDotMargin: {
        marginLeft: 10,
    },
}));

const getClickThroughTimelineContent = ({
    richMediaCreatives,
    isClickthrough,
    showErrors,
    landingPageClickthroughErrors,
    landingPageErrors,
    onFieldChange,
    draft,
}) => {
    const richMediaClickthroughSteps = [
        'click_event',
        'tag_clicktracker',
        'ef_clicktracker',
        'clickthrough_url',
    ];
    const richMediaLandingSteps = [
        'click_event',
        'tag_clicktracker',
        'ef_clicktracker',
        'ef_clicktracker_url',
    ];

    const standardClickthroughSteps = [
        'click_event',
        'ef_clicktracker_auto',
        'third_party_clicktracker_url',
        'landing_page_for_reference',
    ];
    const standardLandingSteps = ['click_event', 'ef_clicktracker_auto', 'landing_page'];

    const contents = [
        {
            item: (
                <Box mt={1.5}>
                    <Typography color="textSecondary"> Click Event </Typography>
                </Box>
            ),
            icon: <TouchAppIcon />,
            key: 'click_event',
        },
        {
            item: (
                <Box ml={1.4} mt={0.1}>
                    <Typography color="textSecondary">Third-Party Ad Server</Typography>
                </Box>
            ),
            key: 'tag_clicktracker',
        },
        {
            item: (
                <Box ml={1.4} mt={0.1}>
                    <Typography color="textSecondary">EngageFront Clicktracker</Typography>
                    <Clicktracking value={draft.third_party_clickthrough} onClick={onFieldChange} />
                </Box>
            ),
            key: 'ef_clicktracker',
        },
        {
            item: (
                <Box ml={1.4} mt={0.1}>
                    <Typography color="textSecondary">
                        EngageFront Clicktracker (automatic)
                    </Typography>
                </Box>
            ),
            key: 'ef_clicktracker_auto',
        },
        {
            item: (
                <Box mt={1.4}>
                    <Typography color="textSecondary">
                        Clickthrough URL set by the third-party ad server
                    </Typography>
                </Box>
            ),
            icon: <FlagIcon />,
            key: 'ef_clicktracker_url',
        },
        {
            item: (
                <Box mt={1.5}>
                    <FormControl
                        error={showErrors && landingPageClickthroughErrors.length > 0}
                        fullWidth
                        required
                    >
                        <Label component="legend">Clickthrough URL</Label>
                        <CreativeAutoComplete
                            value={draft.clickthrough_url}
                            placeholder="http://', 'https://', or 'tel:"
                            onChange={onFieldChange('clickthrough_url')}
                        />
                        <FormHelperText>
                            {showErrors &&
                                landingPageClickthroughErrors.length > 0 &&
                                landingPageClickthroughErrors}
                        </FormHelperText>
                    </FormControl>
                </Box>
            ),
            icon: <FlagIcon />,
            key: 'clickthrough_url',
        },
        {
            item: (
                <Box mt={1.5}>
                    <FormControl
                        error={showErrors && landingPageClickthroughErrors.length > 0}
                        fullWidth
                        required
                    >
                        <Label component="legend">Third-Party Clicktracker URL</Label>
                        <CreativeAutoComplete
                            value={draft.clickthrough_url}
                            placeholder="http://', 'https://', or 'tel:"
                            onChange={onFieldChange('clickthrough_url')}
                        />
                        <FormHelperText>
                            {showErrors &&
                                landingPageClickthroughErrors.length > 0 &&
                                landingPageClickthroughErrors}
                        </FormHelperText>
                    </FormControl>
                </Box>
            ),
            icon: <FlagIcon />,
            key: 'third_party_clicktracker_url',
        },
        {
            item: (
                <Box ml={1.4}>
                    <FormControl
                        error={showErrors && landingPageErrors.length > 0}
                        fullWidth
                        required
                    >
                        <Box display="flex" alignItems="center">
                            <Label component="legend">Landing Page URL (For reference only)</Label>
                            <Box mb="2" ml={0.5}>
                                <InfoTooltip
                                    placement="right"
                                    title="Used for reference only, by EngageFront and our exchange partners, to verify the Clickthrough URL."
                                />
                            </Box>
                        </Box>
                        <StandardInput
                            value={draft.landing_page}
                            onChange={onFieldChange('landing_page')}
                            placeholder="http://', 'https://', or 'tel:"
                        />
                        <FormHelperText>
                            {showErrors && landingPageErrors.length > 0 && landingPageErrors}
                        </FormHelperText>
                        <FormHelperText>
                            Enter the URL of the final landing page. Do not enter any third-party
                            tracking URLs.
                        </FormHelperText>
                    </FormControl>
                </Box>
            ),
            key: 'landing_page_for_reference',
        },
        {
            item: (
                <Box mt={1.5}>
                    <FormControl
                        error={showErrors && landingPageErrors.length > 0}
                        fullWidth
                        required
                    >
                        <Label component="legend">Landing Page URL</Label>
                        <StandardInput
                            value={draft.landing_page}
                            onChange={onFieldChange('landing_page')}
                            placeholder="http://', 'https://', or 'tel:"
                        />
                        <FormHelperText>
                            {showErrors && landingPageErrors.length > 0 && landingPageErrors}
                        </FormHelperText>
                    </FormControl>
                </Box>
            ),
            icon: <FlagIcon />,
            key: 'landing_page',
        },
    ];

    let stepList = [];
    if (richMediaCreatives) {
        if (isClickthrough) {
            stepList = richMediaClickthroughSteps;
        } else {
            stepList = richMediaLandingSteps;
        }
    } else {
        if (isClickthrough) {
            stepList = standardClickthroughSteps;
        } else {
            stepList = standardLandingSteps;
        }
    }

    return contents.filter(step => _.includes(stepList, step.key));
};

class ClickthroughCard extends React.Component {
    render() {
        const { errors, draft, onFieldChange, showErrors } = this.props;

        const landingPageClickthroughErrors = []
            .concat(errors.clickthrough_url)
            .filter(error => error);

        const landingPageErrors = [].concat(errors.landing_page).filter(error => error);

        const richMediaCreatives = draft.format === 'custom_html' || draft.format === 'mraid';
        const timelineContents = getClickThroughTimelineContent({
            richMediaCreatives,
            isClickthrough: draft.third_party_clickthrough,
            showErrors,
            landingPageClickthroughErrors,
            landingPageErrors,
            onFieldChange,
            draft,
        });

        return (
            <div>
                <FormControlStyled
                    error={
                        showErrors &&
                        (landingPageClickthroughErrors.length > 0 || landingPageErrors.length > 0)
                    }
                >
                    <ClickthroughTimeline contents={timelineContents} />
                </FormControlStyled>
            </div>
        );
    }
}

function ClickthroughTimeline(props) {
    const classes = useStyles();
    return (
        <Timeline>
            {_.map(props.contents, (content, index) => (
                <TimelineItem key={content + index} classes={{ root: classes.timeline }}>
                    <TimelineSeparator
                        classes={{ root: !content.icon && classes.timelineDotMargin }}
                    >
                        <TimelineDot>{content.icon}</TimelineDot>
                        {props.contents.length - 1 !== index && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>{content.item}</TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}

function Clicktracking(props) {
    const [value, setValue] = React.useState(props.value ? 'enabled' : 'disabled');

    const dispatch = useDispatch();

    const handleChange = e => {
        const value = e.target.value === 'enabled' ? true : false;
        setValue(event.target.value);
        dispatch(creativeFormActions.toggleClicktracker(event.target.value));
    };
    return (
        <FormControl component="fieldset">
            <RadioGroup value={value} onChange={handleChange}>
                <FormControlLabel
                    value={'disabled'}
                    control={<Radio color="primary" />}
                    label="Use Clickthrough URL set by third-party ad server or tag (most common)"
                />
                {value === 'disabled' && (
                    <Box ml={3.5}>
                        <Box mt={1} maxWidth="500">
                            <Typography color="textSecondary">
                                In most cases, the Clickthrough URL is already defined, either
                                directly within the creative tag or on a third-party ad server.
                                EngageFront will respect and redirect to any Clickthrough URLs
                                passed to it.
                            </Typography>
                        </Box>
                    </Box>
                )}
                <FormControlLabel
                    value={'enabled'}
                    control={<Radio color="primary" />}
                    label="Set or override Clickthrough URL"
                />
                {value === 'enabled' && (
                    <Box ml={3.5}>
                        <Box mt={1}>
                            <Typography color="textSecondary">
                                Always redirect to the Clickthrough URL set below.
                            </Typography>
                            <Box mt={1}>
                                <Typography color="textSecondary">
                                    This is sometimes useful if the Clickthrough URL was not set,
                                    was set incorrectly, or requires overriding — for example, in
                                    order to redirect to a fourth-party ad server.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </RadioGroup>
        </FormControl>
    );
}

export default ClickthroughCard;

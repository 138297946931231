import _ from 'lodash';
import React from 'react';
import cn from 'classnames';

class HizeZeros extends React.Component {
    render() {
        const { onHideZeroRows, shouldHideZeros } = this.props;
        return (
            <span>
                <input
                    id="hide-zero-toggle"
                    type="checkbox"
                    onChange={onHideZeroRows}
                    checked={!!shouldHideZeros}
                />
                &nbsp;
                <label htmlFor="hide-zero-toggle">Hide Zero Rows</label>
            </span>
        );
    }
}

export default HizeZeros;

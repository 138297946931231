import _ from 'lodash';

import c from 'common/constants/flux-events';
import flags from 'containers/flags/service';
import Organizations from 'states/resources/organizations/actions';

export const adminOrgs = {
    init() {
        return (dispatch, getState) => {
            dispatch({
                type: c.ADMIN_ORGS__INIT_START,
                payload: {},
            });
        };
    },
};

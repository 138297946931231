import React from 'react';

import { Headline, Title, Subheading } from 'widgets-v5/typography';
import { Row, Col } from 'react-flexbox-grid';
import Spacer from 'widgets-v5/spacer';
import classnames from 'classnames';

function AudienceHeader(props) {
    return (
        <Row middle="xs" className="audience-header">
            <Col md={6} lg={7}>
                <LeftSide>
                    <Headline>{props.title}</Headline>
                    <Spacer />
                    <Title type="info">{props.description}</Title>
                    <Spacer type="large" />
                    {props.category && (
                        <Category icon={props.categoryIcon}>{props.category}</Category>
                    )}
                    <Spacer type="small" />
                    <Subheading type="info">{props.categoryDescription}</Subheading>
                    {props.children}
                </LeftSide>
            </Col>
            <Col md={6} lg={4}>
                <HeaderImage imgUrl={props.imgUrl} />
            </Col>
        </Row>
    );
}

function LeftSide(props) {
    return <div className="audience-header__left-side">{props.children}</div>;
}

function Category(props) {
    return (
        <div className="audience-header__category">
            <div className="audience-header__category-icon">
                <i className={props.icon} />
            </div>
            <div className="audience-header__category-title">{props.children}</div>
        </div>
    );
}

function HeaderImage(props) {
    return <img className="audience-header__image" src={props.imgUrl} />;
}

export default AudienceHeader;

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const propTypes = {
    type: PropTypes.oneOf(['neutral', 'action', 'info', 'alert', 'error', 'link', 'emphasized']),
};

function getDefaultProps() {
    return {
        type: 'neutral',
    };
}

export function Headline(props) {
    return (
        <div
            className={classnames(
                props.className,
                'ef6-typography__headline',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function Caption(props) {
    return (
        <div
            style={props.style}
            className={classnames(
                props.className,
                'ef6-typography__caption',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function ButtonTitle(props) {
    return (
        <div
            className={classnames(
                props.className,
                'ef6-typography__button-title',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function BodyText(props) {
    return (
        <div
            style={props.style}
            className={classnames(
                props.className,
                'ef6-typography__body-text',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function BodyBold(props) {
    return (
        <div
            className={classnames(
                props.className,
                'ef6-typography__body-bold',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function Subheading(props) {
    return (
        <div
            style={props.style}
            className={classnames(
                props.className,
                'ef6-typography__subheading',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function SubheadingBold(props) {
    return (
        <div
            className={classnames(
                props.className,
                'ef6-typography__subheading-bold',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function Title(props) {
    return (
        <div
            id={props.id}
            className={classnames(
                props.className,
                'ef6-typography__title',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function DescriptionText(props) {
    return (
        <div
            className={classnames(
                props.className,
                'ef6-typography__description-text',
                `ef6-typography--${props.type}`
            )}
        >
            {props.children}
        </div>
    );
}

export function Label(props) {
    return (
        <Typography color={props.placeholder ? 'textSecondary' : 'inherit'}>
            {props.children}
        </Typography>
    );
}

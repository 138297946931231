import { connect } from 'react-redux';
import React from 'react';

import includes from 'lodash/includes';
import get from 'lodash/get';
import map from 'lodash/map';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import { Typography } from '@mui/material';

import actions from '../../actions';
import { getPlatformOptions } from 'states/resources/ads/business-logic';
import { PLATFORM_MAPPING } from 'common/constants/platforms';

export function Platform({
    platform,
    mode,
    changePlatform,
    disableOptions,
    warningText,
    platformOptions,
    ...props
}) {

    let parsedOptions;
    if (!platformOptions) {
        parsedOptions = props.campaign ? getPlatformOptions(props.campaign.type) : [PLATFORM_MAPPING.inapp];
    } else {
        parsedOptions = platformOptions.slice(0);
    }

    if (props.isCrossPlatformCampaign) {
        return (
            <FormGroup>
                {map(parsedOptions, platformOpt => (
                    <FormControlLabel
                        key={platformOpt.value}
                        control={
                            <Checkbox
                                checked={includes(props.platforms, platformOpt.value)}
                                onChange={e =>
                                    props.changePlatforms({
                                        value: platformOpt.value,
                                        checked: e.target.checked,
                                    })
                                }
                                color="primary"
                                disabled={
                                    (includes(props.platforms, platformOpt.value) &&
                                        props.platforms.length === 1) ||
                                    disableOptions
                                }
                            />
                        }
                        label={platformOpt.label}
                    />
                ))}
                {warningText ? (
                    <Typography variant="caption" gutterBottom>
                        {warningText}
                    </Typography>
                ) : null}
            </FormGroup>
        );
    }
    return (
        <div>
            <FormControl component="fieldset">
                <RadioGroup
                    value={platform}
                    aria-label="platform"
                    name="platform"
                    onChange={changePlatform}
                >
                    {map(parsedOptions, platform => (
                        <FormControlLabel
                            key={platform.value}
                            value={platform.value}
                            control={<Radio />}
                            label={platform.label}
                            disabled={mode === 'edit'}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
}

function mapState(state) {
    const campaign = get(state, 'adForm.form.campaign');
    const platform = get(state, 'adForm.form.draft.platform', 'inapp');
    const platforms = get(state, 'adForm.form.draft.platforms', 'inapp');
    const mode = get(state, 'adForm.form.mode');

    return {
        campaign,
        platform,
        mode,
        platforms,
    };
}

function mapDispatch(dispatch, ownProps) {
    return {
        changePlatform(event) {
            const platform = event.target.value;
            dispatch(actions.changePlatform(platform));

            ownProps.onChange();
        },
        changePlatforms(newPlatforms) {
            dispatch(actions.changePlatforms(newPlatforms));
            ownProps.onChange();
        },
    };
}

export default connect(
    mapState,
    mapDispatch
)(Platform);

import React from 'react';
import _ from 'lodash';

import { SimpleSelect } from 'widgets-v6/select';

class DimensionSelector extends React.Component {
    handleButton2Click = value => {
        const { metricComponentsConfig } = this.props;
        const metric = _.find(metricComponentsConfig, function(metricConfig) {
            return metricConfig.metricType === value;
        });

        this.props.metricSelector_toggleSelection(metric);
    };

    getPrimaryMetricOptions = () => {
        const { metricComponentsConfig } = this.props;

        return _.map(metricComponentsConfig, metricConfig => {
            return {
                value: metricConfig.metricType,
                label: metricConfig.presentationName,
            };
        });
    };

    render() {
        return (
            <SimpleSelect
                value={this.props.metricType}
                onChange={e => this.handleButton2Click(e.target.value)}
                options={this.getPrimaryMetricOptions()}
            />
        );
    }
}

export default DimensionSelector;

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions, REDUCER_KEY } from './redux';
import {
    selectHasDimensionsAvailable,
    selectPivotTableData,
    selectSelectableDimensionOptions,
    selectSelectedDimensions,
    selectAllDimensionOptionsFlat,
    selectPerformanceMetricOptions,
    selectBeaconMetricOptions,
    selectOverallConversionMetricOptions,
    selectAdvertiserConversionMetricOptions,
    selectCampaignConversionMetricOptions,
    selectSelectedMetrics,
    selectUserOrganization,
    selectCampaign,
    selectDateRange,
    selectDimensionMatch,
    selectScopeMatch,
    selectDimensionsWithValues,
    selectDimensionItems,
    selectHeatmapItems,
    selectHeatmapWithValues,
    selectSelectedSubevents,
} from './selectors';

export function useReportExplore() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);

    const hasDimensionsAvailable = useSelector(selectHasDimensionsAvailable);
    const pivotTableData = useSelector(selectPivotTableData);
    const selectableDimensionOptions = useSelector(selectSelectableDimensionOptions);
    const selectedDimensions = useSelector(selectSelectedDimensions);
    const allDimensionOptions = useSelector(selectAllDimensionOptionsFlat);
    const performanceMetricOptions = useSelector(selectPerformanceMetricOptions);
    const beaconMetricOptions = useSelector(selectBeaconMetricOptions);
    const overallConversionMetricOptions = useSelector(selectOverallConversionMetricOptions);
    const advertiserConversionMetricOptions = useSelector(selectAdvertiserConversionMetricOptions);
    const campaignConversionMetricOptions = useSelector(selectCampaignConversionMetricOptions);
    const selectedMetrics = useSelector(selectSelectedMetrics);
    const userOrganization = useSelector(selectUserOrganization);
    const campaign = useSelector(selectCampaign);
    const dateRange = useSelector(selectDateRange);
    const dimensionMatch = useSelector(selectDimensionMatch);
    const scopeMatch = useSelector(selectScopeMatch);
    const dimensionsWithValues = useSelector(selectDimensionsWithValues);
    const heatmapItemsWithValues = useSelector(selectHeatmapWithValues);
    const dimensionItems = useSelector(selectDimensionItems);
    const heatmapItems = useSelector(selectHeatmapItems);
    const selectedSubevents = useSelector(selectSelectedSubevents);

    return {
        ...state,
        ...boundActions,
        allDimensionOptions,
        hasDimensionsAvailable,
        pivotTableData,
        selectableDimensionOptions,
        selectedDimensions,
        performanceMetricOptions,
        beaconMetricOptions,
        overallConversionMetricOptions,
        advertiserConversionMetricOptions,
        campaignConversionMetricOptions,
        selectedMetrics,
        userOrganization,
        campaign,
        dateRange,
        dimensionMatch,
        scopeMatch,
        dimensionsWithValues,
        dimensionItems,
        heatmapItems,
        heatmapItemsWithValues,
        selectedSubevents,
    };
}

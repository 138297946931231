import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import audienceEditorActions from './modules/actions';

import actions from './actions';
import selector from './selector';

import RetargetingLayout from './layout';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const RetargetingDashboard = ({ dispatch, audienceSegments, totalSegmentCount, ...props }) => {
    useEffect(() => {
        dispatch(actions.init());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    const openModal = audienceId => {
        dispatch(audienceEditorActions.initialize(audienceId));
    };

    const { download, isSegmentDownloading } = useSegmentDownload(dispatch);

    return (
        <RetargetingLayout
            onSearch={onChange}
            openModal={openModal}
            audienceSegments={audienceSegments}
            download={download}
            isSegmentDownloading={isSegmentDownloading}
            totalSegmentCount={totalSegmentCount}
            activeAudience={props.params.audienceId}
            organizationId={props.organizationId}
        />
    );
};

function useSegmentDownload(dispatch) {
    const [segmentsDownloading, setSegmentsDownloading] = useState({});

    async function download(segment) {
        if (isSegmentDownloading(segment)) {
            return;
        }

        setSegmentsDownloading({
            ...segmentsDownloading,
            [segment.id]: true,
        });

        await dispatch(actions.downloadAudience(segment.id, segment.name)).catch(err => {
            console.error(err);
        });

        setSegmentsDownloading({
            ...segmentsDownloading,
            [segment.id]: false,
        });
    }

    function isSegmentDownloading(segment) {
        return !!segmentsDownloading[segment.id];
    }

    return {
        download,
        isSegmentDownloading,
    };
}

export default connect(selector)(RetargetingDashboard);

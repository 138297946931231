import React, { useState, useReducer } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import actions from '../custom-dimensions-overview/actions';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import { TextField } from 'widgets-v6/standard-input';

import { isMaxFta } from 'states/resources/custom-dimensions/business-logic';

const getDimensionConfirmationMessages = draft => {
    return {
        toggleShow: {
            title: draft.hidden
                ? 'Show for client reports on Analytics?'
                : 'Hide for client reports on Analytics?',
            description: draft.hidden
                ? 'This will show the dimension in client reports on Analytics'
                : 'This will hide the dimension in client reports on Analytics',
        },
        toggleSendToFta: {
            title: draft.sendToFta ? "Disable 'Sync with FTA'?" : "Enable 'Sync with FTA'?",
            description: 'Changes may require up to 24 hours to be synced.',
        },
        setFavourite: {
            title: 'Add to summarized Campaign reports?',
            description: 'This will add the dimension to summarized Campaign reports',
        },
        deleteDimension: {
            title: 'Delete Custom Dimension?',
            description: 'This dimension will be permanently deleted.',
        },
    };
};

const getGroupConfirmationMessages = draft => {
    return {
        deleteGroup: {
            title: 'Delete Group?',
            description: 'This group group will be permanently deleted.',
        },
    };
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    group: {
        margin: theme.spacing(1, 0),
    },
}));

function FormDialog({
    dispatch,
    open,
    mode,
    onCancel,
    onClose,
    onCreate,
    draft,
    campaignId,
    showErrors,
    errors,
    customDimensions,
}) {
    const [nameError, setNameError] = useState(null);
    const classes = useStyles();

    function handleChangeName(event) {
        setNameError(null);
        dispatch(actions.changeDimensionName(event.target.value));
    }

    function handleChangeContentType(event) {
        dispatch(actions.changeDimensionContentType(event.target.value));
    }

    function handleChangeSendToFta(event) {
        dispatch(actions.changeDimensionSendToFta(event.target.checked));
    }

    function handleChangeHidden(event) {
        dispatch(actions.changeDimensionHidden(event.target.checked));
    }

    function handleChangeIsPrimaryDimension(event) {
        dispatch(actions.changeDimensionIsPrimaryDimension(event.target.checked));
    }

    function handleCreateDimension() {
        dispatch(actions.create(campaignId)).then(() => {
            onCreate();
        });
    }

    function handleSaveDimension() {
        dispatch(actions.patch(campaignId, draft.id)).then(() => {
            onClose();
        });
    }
    function validateDimensionName() {
        const { name } = draft;
        if (_.find(customDimensions, { name })) {
            return setNameError('Custom Dimension name already exists');
        }
        if (mode === 'edit') {
            return handleSaveDimension();
        }
        return handleCreateDimension();
    }

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {mode === 'edit' ? 'Edit Custom Dimension' : 'New Custom Dimension'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>
                        Custom dimensions allow campaign reports to be queried and split in
                        customized ways. Ads or creatives can be grouped together according to how
                        you want them to appear in reports.
                    </p>
                </DialogContentText>
                <FormControl component="fieldset" className={classes.formControl} fullWidth>
                    <TextField
                        autoFocus
                        label="Dimension Name"
                        value={draft.name}
                        onChange={handleChangeName}
                        error={!!nameError}
                        helperText={nameError}
                    />
                </FormControl>
                {showErrors && <div>{errors.name}</div>}
                {mode !== 'edit' && (
                    <FormControl component="fieldset" className={classes.formControl} fullWidth>
                        <FormLabel component="legend">Dimension Type</FormLabel>
                        <RadioGroup
                            aria-label="dimensiontype"
                            name="dimensiontype"
                            className={classes.group}
                            value={draft.contentType}
                            onChange={handleChangeContentType}
                        >
                            <FormControlLabel value="ad" control={<Radio />} label="Ad" />
                            <FormControlLabel
                                value="creative"
                                control={<Radio />}
                                label="Creative"
                            />
                        </RadioGroup>
                    </FormControl>
                )}
                <FormControl component="fieldset" className={classes.formControl} fullWidth>
                    <FormLabel component="legend">Options</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={draft.sendToFta}
                                    onChange={handleChangeSendToFta}
                                    color="primary"
                                />
                            }
                            label="Sync with FTA"
                        />
                        Up to 5 dimensions can be synced with FTA.
                        {/*<FormControlLabel
                            control={
                                <Switch
                                    checked={!draft.hidden}
                                    onChange={handleChangeHidden}
                                    color="primary"
                                />
                            }
                            label="Show for client reports on Analytics"
                        />*/}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={draft.isPrimaryDimension}
                                    onChange={handleChangeIsPrimaryDimension}
                                    color="primary"
                                />
                            }
                            label="Add to summarized Campaign reports"
                        />
                    </FormGroup>
                </FormControl>
                {errors && errors.sendToFta && <div>{errors.sendToFta}</div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={validateDimensionName} color="primary">
                    {mode === 'edit' ? 'Save' : 'Create'}
                </Button>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function DimensionConfirmationDialogModal({
    dispatch,
    open,
    confirmationAction,
    onCancel,
    onClose,
    onDelete,
    draft,
    campaignId,
    customDimensions,
}) {
    function handleSaveDimension() {
        switch (confirmationAction) {
            case 'toggleShow':
                dispatch(actions.changeDimensionHidden(!draft.hidden));
                break;
            case 'toggleSendToFta':
                dispatch(actions.changeDimensionSendToFta(!draft.sendToFta));
                onClose();
                break;
            case 'setFavourite':
                dispatch(actions.changeDimensionIsPrimaryDimension(!draft.isPrimaryDimension));
                break;
            default:
                break;
        }

        if (confirmationAction === 'deleteDimension') {
            dispatch(actions.delete(campaignId)).then(() => {
                onDelete();
            });
        } else {
            dispatch(actions.patch(campaignId, draft.id)).then(() => {
                onClose();
            });
        }
    }

    const confirmationMessages = getDimensionConfirmationMessages(draft);

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            {confirmationAction === 'toggleSendToFta' &&
            draft.sendToFta === false &&
            isMaxFta(customDimensions) ? (
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">Cannot send metadata to FTA</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The maximum number of dimensions to send metadata to FTA for has been
                            reached.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <DialogTitle id="form-dialog-title">
                        {confirmationAction ? confirmationMessages[confirmationAction].title : ''}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {confirmationAction
                                ? confirmationMessages[confirmationAction].description
                                : ''}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSaveDimension} color="primary">
                            Confirm
                        </Button>
                        <Button onClick={onCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Dialog>
    );
}

function GroupConfirmationDialogModal({
    dispatch,
    open,
    confirmationAction,
    onCancel,
    onClose,
    draft,
    activeDimension,
    campaignId,
    selectedGroupId,
}) {
    function handleSaveDimension() {
        if (confirmationAction === 'deleteGroup') {
            dispatch(
                actions.updateDimensionDraft({
                    ...activeDimension,
                    groups: _.filter(activeDimension.groups, group => group.id !== selectedGroupId),
                })
            );

            dispatch(actions.patch(campaignId)).then(() => {
                onClose();
            });
        }
    }

    const confirmationMessages = getGroupConfirmationMessages(draft);

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {confirmationAction ? confirmationMessages[confirmationAction].title : ''}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {confirmationAction ? confirmationMessages[confirmationAction].description : ''}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveDimension} color="primary">
                    Confirm
                </Button>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const initialState = { newGroupName: '', errors: null };
const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'CREATE_GROUP_DIALOG_MODAL__SET_NEW_GROUP_NAME':
            return { newGroupName: payload.newGroupName, errors: null };
        case 'CREATE_GROUP_DIALOG_MODAL__DUPLICATE_NAME_ERROR':
            return { ...state, errors: payload.errors };
    }
};

function CreateGroupDialogModal({
    dispatch,
    openCreateGroup,
    onClose,
    isOpen,
    groups,
    currentGroup,
    selectedRows,
    campaignId,
    activeDimension,
}) {
    const [state, hookDispatch] = useReducer(reducer, initialState);

    function handleInput(event) {
        hookDispatch({
            type: 'CREATE_GROUP_DIALOG_MODAL__SET_NEW_GROUP_NAME',
            payload: { newGroupName: event.target.value },
        });
    }

    function handleCreateGroup(newGroupName) {
        if (_.find(groups, { name: newGroupName })) {
            return hookDispatch({
                type: 'CREATE_GROUP_DIALOG_MODAL__DUPLICATE_NAME_ERROR',
                payload: { errors: 'Group name already exists' },
            });
        }
        const updatedGroups = [...activeDimension.groups];

        _.forEach(updatedGroups, group => {
            group.keys = _.filter(group.keys, key => !_.includes(selectedRows, key));
        });

        updatedGroups.push({
            name: newGroupName,
            keys: selectedRows,
        });

        dispatch(
            actions.updateDimensionDraft({
                ...activeDimension,
                groups: _.filter(updatedGroups, group => group.keys.length > 0),
            })
        );

        dispatch(actions.patch(campaignId)).then(() => {
            onClose();
        });
    }

    function handleAssignGroup(groupId) {
        const updatedGroups = [...activeDimension.groups];

        _.forEach(updatedGroups, group => {
            if (group.id === groupId) {
                group.keys = _.union(group.keys, selectedRows);
            } else {
                group.keys = _.filter(group.keys, key => !_.includes(selectedRows, key));
            }
        });

        _.pull(updatedGroups, group => group.keys.length === 0);

        dispatch(
            actions.updateDimensionDraft({
                ...activeDimension,
                groups: _.filter(updatedGroups, group => group.keys.length > 0),
            })
        );

        dispatch(actions.patch(campaignId)).then(() => {
            onClose();
        });
    }

    function handleUnassignRows() {
        const updatedGroups = [...activeDimension.groups];

        _.forEach(updatedGroups, group => {
            group.keys = _.filter(group.keys, key => !_.includes(selectedRows, key));
        });

        _.pull(updatedGroups, group => group.keys.length === 0);

        dispatch(
            actions.updateDimensionDraft({
                ...activeDimension,
                groups: _.filter(updatedGroups, group => group.keys.length > 0),
            })
        );

        dispatch(actions.patch(campaignId)).then(() => {
            onClose();
        });
    }

    return (
        <React.Fragment>
            <GroupList
                assignGroup={handleAssignGroup}
                openCreateGroup={openCreateGroup}
                currentGroup={currentGroup}
                groups={groups}
                unassignRows={handleUnassignRows}
                selectedRows={selectedRows}
            />
            <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">New Group</DialogTitle>
                <DialogContent>
                    <DialogContentText>Create a new group for the selected rows.</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Group Name"
                        type="text"
                        fullWidth
                        value={state.newGroupName}
                        onChange={handleInput}
                        error={!!state.errors}
                        helperText={state.errors}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCreateGroup(state.newGroupName)} color="primary">
                        Create
                    </Button>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function GroupList({
    groups,
    openCreateGroup,
    currentGroup,
    assignGroup,
    unassignRows,
    selectedRows,
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleAssignGroup(groupId) {
        assignGroup(groupId);
        setAnchorEl(null);
    }

    function handleUnassignRows() {
        unassignRows();
        setAnchorEl(null);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const listGroups = _.filter(groups, listGroup => {
        return listGroup.id !== currentGroup.id && !listGroup.isUngrouped;
    });

    return (
        <React.Fragment>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                Move selected rows to...
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {_.map(listGroups, group => (
                    <MenuItem
                        key={group.id}
                        onClick={() => handleAssignGroup(group.id)}
                        onCancel={handleClose}
                    >
                        {group.name}
                    </MenuItem>
                ))}
                {listGroups.length > 0 && <Divider />}
                <MenuItem onClick={openCreateGroup}>New group</MenuItem>
                {selectedRows && !currentGroup.isUngrouped && (
                    <MenuItem onClick={() => handleUnassignRows()}>Ungrouped</MenuItem>
                )}
            </Menu>
        </React.Fragment>
    );
}

function selector(storeState) {
    return {
        draft: storeState.customDimensionsOverview.dimensionDraft,
        activeDimension: storeState.customDimensionsOverview.activeDimension,
    };
}

export const CreateGroupDialog = connect(selector)(CreateGroupDialogModal);
export const DimensionConfirmationDialog = connect(selector)(DimensionConfirmationDialogModal);
export const GroupConfirmationDialog = connect(selector)(GroupConfirmationDialogModal);

export default connect(selector)(FormDialog);

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REDUCER_KEY, actions } from './reducer';

export function useCreativeForm() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);

    return {
        ...state,
        ...boundActions,
    };
}

import _ from 'lodash';

export default function selector(storeState, props) {
    const {
        customDimensions,
        ads,
        creatives,
        loading,
        primaryDimension,
        activeDimension,
        errors,
        dimensionDraftError,
        showErrors,
        campaign,
    } = _.get(storeState, 'customDimensionsOverview');

    const adsMapping = {};
    _.each(ads, ad => (adsMapping[ad.id] = ad));

    const creativesMapping = {};
    _.each(creatives, creative => (creativesMapping[creative.id] = creative));

    return {
        campaign,
        customDimensions,
        adsMapping,
        creativesMapping,
        loading,
        primaryDimension,
        activeDimension,
        errors,
        dimensionDraftError,
        showErrors,
        campaignBudgetAllocationMethod: _.get(campaign, 'budget_allocation_method'),
    };
}

const metrics = [
    {
        name: 'impressions',
        label: 'Impressions',
        status: 'selected',
        style: 'impressions',
        category: 'basic',
        formatType: 'thousands',
        isCurrencyType: false,
    },
    {
        name: 'clicks',
        label: 'Clicks',
        status: 'visible',
        style: 'clicks',
        category: 'basic',
        formatType: 'thousands',
        isCurrencyType: false,
    },
    {
        name: 'ctr',
        label: 'CTR',
        status: 'visible',
        style: 'ctr',
        category: 'basic',
        formatType: 'percentage',
        isCurrencyType: false,
    },
    {
        name: 'vcr',
        label: 'VCR',
        status: 'visible',
        style: 'vcr',
        category: 'basic',
        formatType: 'percentage',
        isCurrencyType: false,
    },
    {
        name: 'placed_bid',
        label: 'Placed Bids',
        status: 'hidden',
        style: 'placed_bid',
        category: 'basic',
        formatType: 'thousands',
        isCurrencyType: false,
    },
    {
        name: 'unique_users',
        label: 'Unique Users',
        status: 'hidden',
        style: 'unique_users',
        category: 'basic',
        formatType: 'thousands',
        isCurrencyType: false,
    },
    {
        name: 'daily_uniq',
        label: 'Average Daily Uniques',
        status: 'hidden',
        style: 'daily_uniq',
        category: 'basic',
        formatType: 'thousands',
        isCurrencyType: false,
    },
    {
        name: 'average_freq',
        label: 'Average Daily Frequency',
        status: 'hidden',
        style: 'average_freq',
        category: 'basic',
        formatType: 'whole-fixed',
        isCurrencyType: false,
    },
    {
        name: 'win_rate',
        label: 'Win Rate',
        status: 'hidden',
        style: 'win_rate',
        category: 'basic',
        formatType: 'percentage',
        isCurrencyType: false,
    },
    {
        name: 'spend',
        label: 'Billable Cost',
        status: 'visible',
        style: 'spend',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'ecpc',
        label: 'Billable Cost eCPC',
        status: 'hidden',
        style: 'ecpc',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'ecpm',
        label: 'Billable Cost eCPM',
        status: 'hidden',
        style: 'ecpm',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'owner_total_media_cost_local',
        label: 'Total Cost',
        status: 'hidden',
        style: 'owner_total_media_cost_local',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'owner_total_media_cost_local_ecpm',
        label: 'Total Cost eCPM',
        status: 'hidden',
        style: 'owner_total_media_cost_local_ecpm',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'owner_total_media_cost_local_ecpc',
        label: 'Total Cost eCPC',
        status: 'hidden',
        style: 'owner_total_media_cost_local_ecpc',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'owner_total_media_cost_local_ecpcv',
        label: 'Total Cost eCPCV',
        status: 'hidden',
        style: 'owner_total_media_cost_local_ecpcv',
        category: 'basic',
        formatType: 'percentage',
        isCurrencyType: false,
    },
    {
        name: 'revenue',
        label: 'Spend',
        status: 'visible',
        style: 'revenue',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'erpm',
        label: 'Spend eCPM',
        status: 'hidden',
        style: 'erpm',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'erpc',
        label: 'Spend eCPC',
        status: 'hidden',
        style: 'erpc',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'revenue_ecpcv',
        label: 'Spend eCPCV',
        status: 'hidden',
        style: 'revenue_ecpcv',
        category: 'basic',
        formatType: 'percentage',
        isCurrencyType: false,
    },
    {
        name: 'owner_media_cost_2_local',
        label: 'Media Cost',
        status: 'hidden',
        style: 'owner_media_cost_2_local',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'data_cost',
        label: 'Data Cost',
        status: 'hidden',
        style: 'data_cost',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'third_party_fees',
        label: 'Third Party Fees',
        status: 'hidden',
        style: 'third_party_fees',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'owner_inventory_cost_local',
        label: 'Inventory Cost',
        status: 'hidden',
        style: 'owner_inventory_cost_local',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'margin',
        label: 'Margin',
        status: 'hidden',
        style: 'basic',
        category: 'basic',
        formatType: 'percentage',
        isCurrencyType: false,
    },
    {
        name: 'addictive_cost_local',
        label: 'Internal Cost (Campaign Currency)',
        status: 'hidden',
        style: 'addictive_cost_local',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'addictive_cost_cad',
        label: 'Internal Cost (CAD)',
        status: 'hidden',
        style: 'addictive_cost_cad',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'addictive_cost_usd',
        label: 'Internal Cost (USD)',
        status: 'hidden',
        style: 'addictive_cost_usd',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'addictive_revenue_cad',
        label: 'Internal Revenue (CAD)',
        status: 'hidden',
        style: 'addictive_revenue_cad',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
    {
        name: 'internal_margin_cad',
        label: 'Internal Margin (CAD)',
        status: 'hidden',
        style: 'internal_margin_cad',
        category: 'basic',
        formatType: 'dollar',
        isCurrencyType: true,
    },
];

export default metrics;

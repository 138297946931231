import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './reducer';
import {
    getOverviewAdData,
    getCampaignsFlightsAndAds,
    getParsedSidebarAlerts,
    getSidebarAlertsIsLoading,
} from './selectors';

export function useOverviewAdData() {
    const dispatch = useDispatch();
    const state = useSelector(getOverviewAdData);
    const boundActions = bindActionCreators(actions, dispatch);
    const campaignsFlightsAndAds = useSelector(getCampaignsFlightsAndAds);
    const sidebarAlerts = useSelector(getParsedSidebarAlerts);
    const sidebarIsLoading = useSelector(getSidebarAlertsIsLoading);

    return {
        ...state,
        ...boundActions,
        ...campaignsFlightsAndAds,
        sidebarAlerts,
        sidebarIsLoading,
    };
}

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import actions from './actions';
import selector from './selector';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles(theme => ({
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    alert: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
}));
const Loading = withStyles(theme => ({
    root: {
        width: '24px',
        height: '24px',
    },
}))(Grid);

const LoadingSpinner = withStyles(theme => ({
    root: {
        width: '24px !important',
        height: '24px !important',
    },
}))(CircularProgress);

const DeleteDealModal = ({ dispatch, isOpen, isDeleting, dealId, dealName }) => {
    const classes = useStyles();
    const handleClose = () => dispatch(actions.closeDeleteModal());
    const handleDelete = () => {
        dispatch(actions.delete());
    };

    const loadingSpinner = isDeleting => {
        return isDeleting ? (
            <Loading>
                <LoadingSpinner fontSize="small" color="inherit" />
            </Loading>
        ) : (
            'Delete'
        );
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>Delete Deal?</DialogTitle>
            <DialogContent>
                <Box mb={1}>
                    <Alert icon={false} severity="info" classes={{ root: classes.alert }}>
                        <Typography variant="subtitle1">{dealName}</Typography>
                        <Typography variant="subtitle1">({dealId})</Typography>
                    </Alert>
                </Box>
                <Typography>This deal will be removed from all associated ads.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                    {loadingSpinner(isDeleting)}
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(selector)(DeleteDealModal);

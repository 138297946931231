import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DialogTitle } from 'widgets-v6/modal';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import { SimpleSelect } from 'widgets-v6/select';

import selector from './selector';
import actions from '../../actions';

const useStyles = makeStyles(() => ({
    warningMessage: {
        color: 'orange',
    },
}));

const attributionWindowOptions = [
    { value: 1, label: '1' },
    { value: 7, label: '7' },
    { value: 14, label: '14' },
    { value: 28, label: '28' },
    { value: 30, label: '30' },
];

function ConversionForm(props) {
    const {
        draft,
        errors,
        advertiserOptions,
        isOpen,
        isSaving,
        onClose,
        onSave,
        updateDraft,
        serverErrorMessage,
        mode,
    } = props;

    const classes = useStyles();

    const handleChangeReportingName = _.debounce(value => {
        updateDraft({ reporting_name: value });
    }, 200);

    const handleChangeSubeventVariable = _.debounce(value => {
        updateDraft({ dynamic_data_subevent_name: value });
    }, 200);

    const handleChangeValueVariable = _.debounce(value => {
        updateDraft({ dynamic_data_value_name: value });
    }, 200);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown>
            <DialogTitle onClose={onClose}>
                {mode === 'new' ? 'Create New Advertiser Pixel' : 'Edit Advertiser Pixel'}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="column">
                    <TextField
                        defaultValue={draft.reporting_name}
                        error={!!errors.reporting_name}
                        helperText={errors.reporting_name}
                        onChange={e => handleChangeReportingName(e.target.value)}
                        label={'Name'}
                        disabled={isSaving}
                        disableMargin
                    />
                    <Box mt={1} mb={1}>
                        <SimpleSelect
                            label="Advertiser"
                            placeholder="Select an Advertiser"
                            value={draft.advertiser}
                            error={!!errors.advertiser}
                            options={advertiserOptions}
                            onChange={e => updateDraft({ advertiser: e.target.value })}
                            disabled={isSaving}
                        />
                    </Box>
                    <FormControl component="fieldset" margin="dense">
                        <FormLabel component="legend">Attribution Model</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={draft.use_click_through}
                                        onChange={e =>
                                            updateDraft({ use_click_through: e.target.checked })
                                        }
                                        name="clickthrough"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label="Click-through attribution"
                            />
                            {draft.use_click_through && (
                                <Box ml={2}>
                                    <SimpleSelect
                                        label="Attribution Window"
                                        value={draft.click_through_attribution_window}
                                        options={attributionWindowOptions}
                                        onChange={e =>
                                            updateDraft({
                                                click_through_attribution_window: e.target.value,
                                            })
                                        }
                                        disabled={isSaving}
                                    />
                                </Box>
                            )}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={draft.use_view_through}
                                        onChange={e =>
                                            updateDraft({ use_view_through: e.target.checked })
                                        }
                                        name="viewthrough"
                                        color="primary"
                                        disabled={isSaving}
                                    />
                                }
                                label="View-through attribution"
                            />
                            {draft.use_view_through && (
                                <Box ml={2}>
                                    <SimpleSelect
                                        label="Attribution Window"
                                        value={draft.view_through_attribution_window}
                                        options={attributionWindowOptions}
                                        onChange={e =>
                                            updateDraft({
                                                view_through_attribution_window: e.target.value,
                                            })
                                        }
                                        disabled={isSaving}
                                    />
                                </Box>
                            )}
                        </FormGroup>
                        <FormHelperText error={!!errors.__root}>
                            Enable at least 1 attribution model
                        </FormHelperText>
                    </FormControl>{' '}
                    <br />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Event Parameters</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={draft.use_dynamic_data_subevent}
                                        onChange={e =>
                                            updateDraft({
                                                use_dynamic_data_subevent: e.target.checked,
                                            })
                                        }
                                        name="use_dynamic_data_subevent"
                                        disabled={isSaving}
                                        color="primary"
                                    />
                                }
                                label="Track event parameter (text string) from GTM, e.g. Product SKU"
                            />
                            {draft.use_dynamic_data_subevent && (
                                <Box ml={2}>
                                    <TextFieldFormatter
                                        initialValue={draft.dynamic_data_subevent_name}
                                        onChange={value => handleChangeSubeventVariable(value)}
                                        renderInput={({ value, onChange }) => (
                                            <TextField
                                                error={!!errors.dynamic_data_subevent_name}
                                                value={value}
                                                onChange={onChange}
                                                label={'Google Tag Manager (GTM) Variable'}
                                                disabled={isSaving}
                                                disableMargin
                                            />
                                        )}
                                    />
                                    {!!errors.dynamic_data_subevent_name && (
                                        <FormHelperText className={classes.warningMessage}>
                                            {errors.dynamic_data_subevent_name}
                                        </FormHelperText>
                                    )}
                                    <Typography variant="body2" gutterBottom color="textSecondary">
                                        Enter the name of the Built-In Variable or User-Defined
                                        Variable to capture from GTM.
                                    </Typography>
                                </Box>
                            )}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={draft.use_dynamic_data_value}
                                        onChange={e =>
                                            updateDraft({
                                                use_dynamic_data_value: e.target.checked,
                                            })
                                        }
                                        name="use_dynamic_data_value"
                                        disabled={isSaving}
                                        color="primary"
                                    />
                                }
                                label="Track event metric (numeric) from GTM, e.g. Product Price"
                            />
                            {draft.use_dynamic_data_value && (
                                <Box ml={2}>
                                    <TextFieldFormatter
                                        initialValue={draft.dynamic_data_value_name}
                                        onChange={value => handleChangeValueVariable(value)}
                                        renderInput={({ value, onChange }) => (
                                            <TextField
                                                error={!!errors.dynamic_data_value_name}
                                                value={value}
                                                onChange={onChange}
                                                label={'Google Tag Manager (GTM) Variable'}
                                                disabled={isSaving}
                                                disableMargin
                                            />
                                        )}
                                    />
                                    {!!errors.dynamic_data_value_name && (
                                        <FormHelperText className={classes.warningMessage}>
                                            {errors.dynamic_data_value_name}
                                        </FormHelperText>
                                    )}
                                    <Typography variant="body2" gutterBottom color="textSecondary">
                                        Enter the name of the Built-In Variable or User-Defined
                                        Variable to capture from GTM.
                                    </Typography>
                                </Box>
                            )}
                        </FormGroup>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                {serverErrorMessage && <FormHelperText error>{serverErrorMessage}</FormHelperText>}
                <Button
                    onClick={isSaving ? _.noop : onSave}
                    color="primary"
                    variant="contained"
                    disabled={isSaving}
                >
                    <span>Save </span>
                    {isSaving && <CircularProgress size={20} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function mapDispatch(dispatch) {
    return {
        onSave: () => dispatch(actions.saveForm()),
        onClose: () => dispatch(actions.closeForm()),
        updateDraft: changes => dispatch(actions.updateDraft(changes)),
    };
}

export default connect(
    selector,
    mapDispatch
)(ConversionForm);

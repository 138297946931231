import _ from 'lodash';

import processor_dataGraph from './data-graph';
export default function adProgressGraphReducer(state, campaignId, adId, health) {
    const ad_attr = _.get(state, `resources.ads.${adId}.attr`);
    const stats = _.get(state, `resources.stats.ad.${adId}.liveByHour`);
    const campaignTimezone = _.get(
        state,
        'campaignProgress.timezone',
        state.campaignProgress.timezone
    );
    const adTimeZone = ad_attr.timezone;

    const timezone = !_.isNil(adTimeZone) ? adTimeZone : campaignTimezone;
    // const healthById = _.get(health, [adId, 'health'], null);

    // All the dependencies that this reducer depends on to generate UI state
    // const newDeps = {
    //     stats,
    //     adAttr: ad_attr,
    // };

    // Rerendering required if any new deps does not match the old dep
    // const rerenderingRequired = _.some(newDeps, (dep, key) => {
    //     return dep !== state.deps[key];
    // });
    // if (!rerenderingRequired) {
    //     return state;
    // }

    let adAttr = _(ad_attr)
        .pick(
            'max_daily_clicks',
            'max_daily_impressions',
            'max_daily_spend_local',
            'max_daily_billings',

            'max_total_clicks',
            'max_total_impressions',
            'max_total_spend_local',
            'max_total_billings',

            'end',
            'start',
            'timezone',

            'weekparts',
            'dayparts',
            'primary_pacing'
        )
        .value();

    adAttr = { ...adAttr, adId, health };

    let dataGraph;
    if (adAttr) {
        dataGraph = processor_dataGraph({ ad: ad_attr, stats, timezone });
        dataGraph.meta = {
            ...dataGraph.meta,
            adId,
            health,
            max_total_impressions: adAttr.max_total_impressions,
            max_total_clicks: adAttr.max_total_clicks,
            max_total_spend_local: adAttr.max_total_spend_local,
            max_total_billings: adAttr.max_total_billings,
            timezone,
        };
    }

    return {
        adAttr: adAttr,
        stats: stats,
        dataGraph: dataGraph,
    };
}

export const defaultValues = {
    adAttr: [],
    stats: [],
    dataGraph: [],
    dataGraph2: [],
};

// attr: Object
// _etag: "ae7dfe4c97acfff20dfe120f08f41026"
// billing_rate: 0
// ef_billing_terms: "PPM"
// billing_type: "fixed"
// creative: "JP3GEG"
// dayparts: Array[0]
// end: "2015-05-22T23:59:59.000Z"
// geoboxes: Array[0]
// geotargets: Array[1]
// id: 10072
// interest_target: Array[0]
// max_cpm_rate_local: 2.5
// max_daily_clicks: 0
// max_daily_impressions: 0
// max_daily_spend_local: 0
// max_total_clicks: 0
// max_total_impressions: 200
// max_total_spend_local: 0
// max_user_frequency: 4
// name: "Lipton Test"
// notes: ""
// paused: true
// cpc_rate: 0
// cpm_rate: 0
// restrict_ips: Array[0]
// start: "2015-05-19T00:00:00.000Z"
// status: "ended"
// target_age_groups: Array[0]
// target_app_categories: Array[0]
// target_carriers: Array[0]
// target_connection_types: Array[0]
// target_device_language: null
// target_device_os: Array[2]
// target_genders: Array[0]
// timezone: "UTC"
// weekparts: Array[7]

import _ from 'lodash';
import uuid from 'uuid';
import numeral from 'numeral';
import React, { useReducer, useEffect } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControl } from '@mui/material';

import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import { getEnvironmentSettings } from 'services/environment';
import {
    OrCard,
    OrCardHeader,
    OrCardBody,
    AndOrButton,
    OrDivider,
    AndDivider,
} from 'widgets-v5/and-or-list';
import { SimpleSelect, GroupedSingleSelect } from 'widgets-v6/select';
import { InfoTooltip } from 'widgets-v6/tooltip';

import schema from './schema';
import {
    VALID_WEATHER_CONDITION_MULTIPLE_OPTION_TYPE,
    hasWeatherConditions,
    maxNumberOfConditions,
    isWeatherTypeTemperature,
    inputAdornmentMap,
} from 'common/constants/weather-condition-targeting';
import Spacer from 'widgets-v5/spacer';

import {
    useWeatherConditions,
    getWeatherConditionQualifier,
    getWeatherConditionTimeframe,
    getWeatherConditionValue,
} from '../weather-conditions/hook';

const AddConditionButton = ({ onClick }) => {
    return (
        <div>
            <Button color="primary" onClick={onClick}>
                ADD CONDITION
            </Button>
        </div>
    );
};

const validateDraft = state => {
    const options = {
        allowUnknown: true,
        abortEarly: false,
    };

    const result = schema.validate(state.draft, options);

    if (!result.error) {
        return {
            ...state,
            errors: {},
        };
    }

    const errors = {};

    _.each(result.error.details, detail => {
        errors[`weatherConditions.${detail.path.join('.')}`] = detail.message;
    });

    return {
        ...state,
        errors,
    };
};

const handlers = {
    WEATHER_CONDITION__ADD_A_NEW_CONDITION: state => {
        return {
            ...state,
            draft: {
                ...state.draft,
                conditions: [
                    ...state.draft.conditions,
                    {
                        type: '',
                        qualifier: '',
                        amount: '',
                        timeframe: '',
                        id: uuid.v4(),
                    },
                ],
            },
        };
    },
    WEATHER_CONDITION__REMOVE_A_NEW_CONDITION: (state, action) => {
        return {
            ...state,
            draft: {
                ...state.draft,
                conditions: _.filter(
                    state.draft.conditions,
                    condition => condition.id !== action.payload.id
                ),
            },
        };
    },
    WEATHER_CONDITION__UPDATE_A_CONDITION: (state, action) => {
        const { id, field, value } = action.payload;

        return {
            ...state,
            draft: {
                ...state.draft,
                conditions: _.map(state.draft.conditions, condition => {
                    if (condition.id === id) {
                        const updatedCondition = {
                            ...condition,
                            [field]: value,
                        };

                        if (field === 'type') {
                            updatedCondition.amount = '';
                            updatedCondition.timeframe = '';
                            updatedCondition.qualifier = !isWeatherTypeTemperature(value)
                                ? 'equal'
                                : '';
                            updatedCondition.amount = '';
                        }

                        return updatedCondition;
                    }
                    return condition;
                }),
            },
        };
    },
    WEATHER_CONDITION__UPDATE_LOGIC_RELATIONSHIP: (state, action) => {
        const { newLogic } = action.payload;

        return {
            ...state,
            draft: {
                ...state.draft,
                logicRelationship: newLogic,
            },
        };
    },
};

const reducer = (state, action) => {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
        nextState = validateDraft(nextState);
        return nextState;
    }

    return state;
};

export const WeatherConditions = props => {
    const initialState = {
        draft: props.weatherConditions,
        errors: {},
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const { onChange, showErrors } = props;

    useEffect(() => {
        const nonWeatherConditionErrors = _.omitBy(props.errors, (value, key) =>
            key.startsWith('weatherCondition')
        );
        onChange({
            errors: {
                ...nonWeatherConditionErrors,
                ...state.errors,
            },
            draft: {
                weatherConditions: state.draft,
            },
        });
    }, [state]);

    const handleAddConditionClick = () => {
        dispatch({
            type: 'WEATHER_CONDITION__ADD_A_NEW_CONDITION',
        });
    };

    const handleConditionChange = id => ({ field, value }) => {
        dispatch({
            type: 'WEATHER_CONDITION__UPDATE_A_CONDITION',
            payload: {
                id,
                field,
                value,
            },
        });
    };

    const handleDeleteCondition = id => {
        dispatch({
            type: 'WEATHER_CONDITION__REMOVE_A_NEW_CONDITION',
            payload: {
                id,
            },
        });
    };

    const handleLogicRelationshipChange = (event, newLogic) => {
        if (!newLogic) {
            return;
        }
        dispatch({
            type: 'WEATHER_CONDITION__UPDATE_LOGIC_RELATIONSHIP',
            payload: {
                newLogic,
            },
        });
    };
    return (
        <div>
            {
                <FormControl>
                    <Box display="flex">
                        <Box mt={1.5}>
                            <FormLabel id="demo-radio-buttons-group-label">Logic Type</FormLabel>
                        </Box>
                        <Box ml={2}>
                            <RadioGroup
                                aria-label="gender"
                                name="logic-types"
                                value={state.draft.logicRelationship}
                                onChange={handleLogicRelationshipChange}
                            >
                                <div>
                                    <FormControlLabel
                                        value="or"
                                        control={<Radio color="primary" />}
                                        label="OR"
                                    />
                                    <Box ml={3.3}>
                                        <Typography variant="body2" gutterBottom>
                                            Activate ad if any of the weather conditions are met{' '}
                                        </Typography>
                                    </Box>
                                </div>
                                <div>
                                    <FormControlLabel
                                        value="and"
                                        control={<Radio color="primary" />}
                                        label="AND"
                                    />
                                    <Box ml={3.3} display={'flex'}>
                                        <Typography variant="body2" gutterBottom>
                                            Activate ad if all of the weather conditions are met{' '}
                                        </Typography>
                                        <Box ml={0.6}>
                                            <InfoTooltip
                                                title={
                                                    'The ad will activate as long as all conditions are met according to their respective timeframe settings, even if they are met during different times of the day or on different days.'
                                                }
                                            />
                                        </Box>
                                    </Box>
                                </div>
                            </RadioGroup>
                        </Box>
                    </Box>
                </FormControl>
            }
            <Spacer />
            {hasWeatherConditions(state.draft) &&
                _.map(state.draft.conditions, (condition, index) => (
                    <React.Fragment>
                        <WeatherConditionForm
                            condition={condition}
                            onChange={handleConditionChange(condition.id)}
                            onDelete={handleDeleteCondition}
                            index={index}
                            key={condition.id}
                            showErrors={showErrors}
                            errors={state.errors}
                        />
                        <Spacer type="small" />
                        {index < maxNumberOfConditions - 1 &&
                            state.draft.logicRelationship === 'and' && (
                                <AndDivider className="ef6-alignment__center" />
                            )}
                        {index < maxNumberOfConditions - 1 &&
                            state.draft.logicRelationship === 'or' && (
                                <OrDivider className="ef6-alignment__center" />
                            )}
                        <Spacer type="small" />
                    </React.Fragment>
                ))}
            {state.draft.conditions && state.draft.conditions.length < maxNumberOfConditions && (
                <AndOrButton>
                    <AddConditionButton onClick={handleAddConditionClick} />
                </AndOrButton>
            )}
        </div>
    );
};

const WeatherConditionForm = ({ condition, onChange, index, onDelete, showErrors, errors }) => {
    const environmentSettings = getEnvironmentSettings();

    const { conditionOptions } = useWeatherConditions({
        conditionsToUse: environmentSettings.getWeatherCondtionsToUse(),
    });

    return (
        <React.Fragment>
            <OrCard>
                <OrCardHeader onRemove={() => onDelete(condition.id)}>
                    <div className="ef6-alignment__flex">{`Condition ${index + 1}`}</div>
                </OrCardHeader>
                <OrCardBody>
                    <Box m={2}>
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item xs={3}>
                                <GroupedSingleSelect
                                    label="Condition"
                                    value={condition.type}
                                    options={conditionOptions}
                                    onChange={value => {
                                        onChange({ field: 'type', value });
                                    }}
                                    placeholder="Select condition"
                                    error={
                                        showErrors &&
                                        errors[`weatherConditions.conditions.${index}.type`]
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {!condition.type ||
                                _.includes(
                                    VALID_WEATHER_CONDITION_MULTIPLE_OPTION_TYPE,
                                    condition.type
                                ) ? (
                                    <SimpleSelect
                                        label="Qualifier"
                                        value={condition.qualifier}
                                        onChange={event => {
                                            onChange({
                                                field: 'qualifier',
                                                value: event.target.value,
                                            });
                                        }}
                                        disabled={!condition.type}
                                        options={getWeatherConditionQualifier(
                                            conditionOptions,
                                            condition.type
                                        )}
                                        placeholder="Select qualifier"
                                        error={
                                            showErrors &&
                                            errors[
                                                `weatherConditions.conditions.${index}.qualifier`
                                            ]
                                        }
                                    />
                                ) : (
                                    <React.Fragment>
                                        <InputLabel>Qualifier</InputLabel>
                                        <TextField fullWidth disabled={true} label="equals to" />
                                    </React.Fragment>
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                {isWeatherTypeTemperature(condition.type) ? (
                                    <TextFieldFormatter
                                        initialValue={condition.amount}
                                        onChange={v =>
                                            onChange({
                                                field: 'amount',
                                                value: v,
                                            })
                                        }
                                        shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                        formatIn={v => numeral(v).value()}
                                        formatOut={v => numeral(v).value()}
                                        renderInput={({ value, onChange, onBlur }) => (
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                variant="outlined"
                                                error={
                                                    showErrors &&
                                                    errors[
                                                        `weatherConditions.conditions.${index}.amount`
                                                    ]
                                                }
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {inputAdornmentMap[condition.type]}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                placeholder="Input value"
                                                style={{ marginTop: '24px' }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <SimpleSelect
                                        label="Value"
                                        value={condition.amount === -1 ? '' : condition.amount}
                                        onChange={event => {
                                            onChange({
                                                field: 'amount',
                                                value: event.target.value,
                                            });
                                        }}
                                        disabled={!condition.type}
                                        options={getWeatherConditionValue(
                                            conditionOptions,
                                            condition.type
                                        )}
                                        placeholder="Select value"
                                        error={
                                            showErrors &&
                                            errors[`weatherConditions.conditions.${index}.amount`]
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <SimpleSelect
                                    label="Time frame"
                                    value={condition.timeframe}
                                    onChange={event => {
                                        onChange({
                                            field: 'timeframe',
                                            value: event.target.value,
                                        });
                                    }}
                                    options={getWeatherConditionTimeframe(
                                        conditionOptions,
                                        condition.type
                                    )}
                                    placeholder="Select time frame"
                                    error={
                                        showErrors &&
                                        errors[`weatherConditions.conditions.${index}.timeframe`]
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </OrCardBody>
            </OrCard>
        </React.Fragment>
    );
};

import get from 'lodash/get';

export default function selector(storeState) {
    const { audienceSegments } = get(storeState, 'adobeDashboard');
    const totalSegmentCount = get(storeState, 'adobeDashboard.totalSegmentCount');
    const isLoading = get(storeState, 'adobeDashboard.isLoading');
    const organizationId = get(storeState, 'profile.organizationId');

    return {
        audienceSegments,
        totalSegmentCount,
        isLoading,
        organizationId,
    };
}

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styled } from '@mui/material/styles';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HelpIconMui from '@mui/icons-material/Help';

export const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: 10,
    },
}))(Tooltip);

export const DarkTooltip = styled(Tooltip)(({ theme }) => ({
    '&.MuiTooltip-tooltip': {
        backgroundColor: theme.palette.grey[300],
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: 10,
    },
}));

export const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#0CBAAA0A',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 175,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        borderRadius: '4px',
        boxShadow:
            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    },
}))(Tooltip);

const HelpIcon = styled(HelpIconMui)(({ theme }) => ({
    fontSize: 15,
    color: theme.palette.info.main,
}));

const DarkHelpIcon = styled(HelpIconMui)({
    fontSize: 15,
    marginBottom: -3,
    color: '#0000008A',
});

export function InfoTooltip(props) {
    return (
        <DarkTooltip
            placement={props.placement}
            title={<Typography variant="body2">{props.title}</Typography>}
        >
            <HelpIcon color="action" />
        </DarkTooltip>
    );
}

export function DarkInfoTooltip(props) {
    return (
        <DarkTooltip
            placement={props.placement}
            title={<Typography variant="body2">{props.title}</Typography>}
        >
            <DarkHelpIcon color="action" />
        </DarkTooltip>
    );
}

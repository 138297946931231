export const payload_formData = {
    name: '',
    advertiser_domain: '',
    iab_categories: [],

    currency: 'USD',
    default_timezone: 'UTC',

    notes: '',

    change_comment: '',
    clients: [],

    comment: '',
    start: '',
    end: '',

    paused: true,
    status: 'paused',
    progress: '',
    third_party_fees: [],

    billing_enabled: true,
    campaign_budget_enabled: true,
    billing_term: 'billable_media_cost_markup',
    billing_rate: 0,
    total_billings_local: 0,
    // Named this way at the request of the bidder team, to help distinguish it from
    // `ad.max_total_spend`
    campaign_max_total_spend_local: 0,
    custom_fields: [],
    revenueModel: 'cpmcpc',
    fta_version: 1,
    fta_enabled: true,
    fta_management_level: 'ad',
    fta_location_list: null,
    automaticBudgetAllocationOptimizationStrategy: 'cpc',
    fta_conversion_lookback_window: {
        type: 'unlimited',
        lookback_window_days: null,
    },
    fta_pre_campaign_interval_days: 10,
    fta_post_campaign_interval_days: 10,
    fta_lift_version: null,
    isUsingFtaFullyManagedFields: false,
    isUsingFtaLimitedFields: false,
    fta_partner_id: '',
    use_front_load_pacing: false,
    type: 'standard',
};

export const payload_nonFormData = {};

export const groupValidate = {};

export const payload_updateOnly = {};

export const payload_createOnly = {
    owner: '',
};

export const payload_clientData = {
    name: '',
    credit_limit: 0,
    revenueModel: null,
    agencyMarginRate: null,
    type: 'client',
    creatives: {
        standard: {
            margin_type: 'fixed',
            rates: [
                {
                    size: '300x50',
                    rate: 0,
                },
                {
                    size: '320x50',
                    rate: 0,
                },
                {
                    size: '728x90',
                    rate: 0,
                },
                {
                    size: '300x250',
                    rate: 0,
                },
                {
                    size: '320x480',
                    rate: 0,
                },
                {
                    size: '480x320',
                    rate: 0,
                },
                {
                    size: '768x1024',
                    rate: 0,
                },
                {
                    size: '1024x768',
                    rate: 0,
                },
            ],
            margin_rate: 0,
            allow_create: false,
            auto_approve: false,
        },
        video: {
            margin_type: 'fixed',
            rates: [
                {
                    size: '320x480',
                    rate: 0,
                },
                {
                    size: '480x320',
                    rate: 0,
                },
                {
                    size: '768x1024',
                    rate: 0,
                },
                {
                    size: '1024x768',
                    rate: 0,
                },
            ],
            margin_rate: 0,
            allow_create: false,
            auto_approve: false,
        },
        mraid: {
            margin_type: 'fixed',
            rates: [
                {
                    size: '300x50',
                    rate: 0,
                },
                {
                    size: '320x50',
                    rate: 0,
                },
                {
                    size: '728x90',
                    rate: 0,
                },
                {
                    size: '300x250',
                    rate: 0,
                },
                {
                    size: '320x480',
                    rate: 0,
                },
                {
                    size: '480x320',
                    rate: 0,
                },
                {
                    size: '768x1024',
                    rate: 0,
                },
                {
                    size: '1024x768',
                    rate: 0,
                },
            ],
            margin_rate: 0,
            allow_create: false,
            auto_approve: false,
        },
        custom_html: {
            margin_type: 'fixed',
            rates: [
                {
                    size: '300x50',
                    rate: 0,
                },
                {
                    size: '320x50',
                    rate: 0,
                },
                {
                    size: '728x90',
                    rate: 0,
                },
                {
                    size: '300x250',
                    rate: 0,
                },
                {
                    size: '320x480',
                    rate: 0,
                },
                {
                    size: '480x320',
                    rate: 0,
                },
                {
                    size: '768x1024',
                    rate: 0,
                },
                {
                    size: '1024x768',
                    rate: 0,
                },
            ],
            margin_rate: 0,
            allow_create: false,
            auto_approve: false,
        },
        native: {
            margin_type: 'fixed',
            rates: [
                {
                    size: '0x0',
                    rate: 0,
                },
            ],
            margin_rate: 0,
            allow_create: false,
            auto_approve: false,
        },
    },
};

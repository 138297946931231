import _ from 'lodash';

export default function select(state) {
    const isOpen = _.get(state, 'devSettings.isOpen');
    const isLoading = _.get(state, 'devSettings.isLoading');

    const apiMode = _.get(state, 'devSettings.apiMode');
    const environment = _.get(state, 'devSettings.environment');

    const currentBranch = _.get(state, 'devSettings.currentBranch');
    const branches = _.get(state, 'devSettings.branches');

    const isRemote = apiMode === 'remote';

    return {
        isOpen,
        isRemote,
        isLoading,

        currentBranch,
        environment,
        branches,
        apiMode,
    };
}

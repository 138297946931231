import { CreativeRotationMethodMapping } from "states/resources/ads/business-logic";

export function hasChangedBillableImpressions(entry) {
    if (entry.patch.billable_volume !== undefined && entry.snapshot.ef_billing_terms === 'CPM') {
        return true;
    }

    return false;
}

export function hasChangedNonBillableImpressions(entry) {
    if (
        entry.patch.non_billable_volume !== undefined &&
        entry.snapshot.ef_billing_terms === 'CPM'
    ) {
        return true;
    }

    return false;
}

/**
 * Check if the changes to creative weights were intentional by the user or just a background job
 * update.
 *
 * @param {*} patch The changes currently made
 * @param {*} snapshot State of the object before the change
 * @returns Whether there was a change to the creative weights intentionally by the user or not. If false then
 *          it was by the user, if true then it was a background job.
 */
export function checkOnlyCreativeWeightChanges(patch, snapshot) {
    const testWeightedOrScheduled = new RegExp('.*(weighted|scheduled).\\d.weight');
    return Object.keys(patch).filter(entry => !testWeightedOrScheduled.test(entry)).length === 0
             && snapshot.rotation_rules
              && (snapshot.rotation_rules.mode !== CreativeRotationMethodMapping.Weighted && snapshot.rotation_rules.mode !== CreativeRotationMethodMapping.Scheduled);
}

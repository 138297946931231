import _ from 'lodash';
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import toastr from 'toastr';
import API from 'services/api';
import selector from './selector';

import FormField from 'widgets-v5/form-field';
import PasswordActions from 'states/resources/password/actions';

import CenterLayout from 'widgets-v5/center-layout';
import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';
import Card from 'widgets-v5/card';

class ResetPassword extends React.Component {
    state = { formError: null };

    handleSubmit = e => {
        e.preventDefault();

        this.setState({ formError: null });

        const reset_token = this.props.location.query.token;
        const password = this.refs.password.value;
        const confirm = this.refs.confirm.value;

        if (!password) {
            return this.setState({ formError: 'A new password is required' });
        }

        const notMatch = password !== confirm;
        if (notMatch) {
            return this.setState({ formError: 'Confirmation password must match' });
        }

        this.props.dispatch(PasswordActions.reset(password, reset_token)).then(
            response => {
                browserHistory.push(`/`);
                toastr.success('Your password has been reset');
            },
            response => {
                toastr.error('Password reset failed');
            }
        );
    };

    render() {
        const formError = this.state.formError;
        return (
            <CenterLayout className="ef5-reset-password">
                <div>
                    <img className="ef5-reset-password__logo" src="/images/EngageFront-logo.png" />
                    <Card
                        className="ef5-reset-password__card"
                        title="Reset Password"
                        footer={
                            <ButtonGroup>
                                <PrimaryButton text="Reset Password" onClick={this.handleSubmit} />
                            </ButtonGroup>
                        }
                    >
                        <form ref="login-form" method="POST" onSubmit={this.handleSubmit}>
                            <p className="ef5-reset-password__instructions">
                                Enter a new password for your account
                            </p>
                            <FormField label="Password" isRequired={true}>
                                <input
                                    className="ef3-stdInput"
                                    type="password"
                                    ref="password"
                                    name="password"
                                />
                            </FormField>
                            <FormField
                                label="Password Confirmation"
                                isRequired={true}
                                showErrors={!!formError}
                                errors={[formError]}
                            >
                                <input
                                    className="ef3-stdInput"
                                    type="password"
                                    ref="confirm"
                                    name="confirm"
                                />
                            </FormField>
                        </form>
                    </Card>
                </div>
            </CenterLayout>
        );
    }
}

export default connect(selector)(ResetPassword);

import c from 'common/constants/flux-events';

const initialState = {
    isLoading: false,
    accessGranted: false,
};

export const NAME = 'campaignAccess';

export default function(state = initialState, action) {
    switch (action.type) {
        case c.CAMPAIGN__ACCESS__INIT: {
            const nextState = {
                ...initialState,
                isLoading: true,
            };

            return nextState;
        }
        case c.CAMPAIGN__ACCESS__INIT_COMPLETE: {
            return {
                ...state,
                isLoading: false,
                ownerName: action.payload.ownerName,
                accessGranted: action.payload.accessGranted,
            };
        }
        case 'CAMPAIGN__ACCESS__UNINIT': {
            return {
                ...state,
                accessGranted: false,
            };
        }

        default: {
            return state;
        }
    }
}

import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { makeActions } from 'utils/redux-tools';
import validate from 'utils/validate';

import { getDraftValidators, MIN_VALID_DRAFT } from '../services';
import {
    calculateMediaCost,
    calculateBidPrice,
    calculatePlatformTechFee,
    PlatformMapping,
} from 'states/resources/ads/business-logic';
import { getEnvironmentSettings } from 'services/environment';
import flags from 'containers/flags/service';

export const REDUCER_KEY = 'predictionEditor';
export function getDefaultState() {
    return {
        isLoading: true,
        isLoadingGeoData: true,
        showErrors: false,
        isUpdating: true,
        orgTechFee: 0,
        orgFtaFee: 0,
        totalFirstPartyDataFee: 0,
        platformTechFee: 0,
        audiencesMapping: {},
        audienceRates: {},
        draft: MIN_VALID_DRAFT(),
        errors: {},
    };
}

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState: getDefaultState(),
    reducers: {
        init: () => ({
            ...getDefaultState(),
            isLoading: true,
        }),
        initGeo: state => ({
            ...state,
            isLoadingGeoData: true,
        }),
        initGeoSuccess: state => ({
            ...state,
            isLoadingGeoData: false,
        }),
        initSuccess: (state, payload) => {
            const { audiences, organization, applists } = payload;
    
            const audiencesMapping = {};
            forEach(audiences, audience => (audiencesMapping[audience.id] = audience));

            const audienceRatesMapping = {};
            forEach(organization.audience_rates, rate => {
                audienceRatesMapping[rate.name] = rate.fee;
            });

            const country = getEnvironmentSettings().getInventoryForecastCountryCode();

            const overrides = {
                geotargets: [{ country, include: [], exclude: [] }],
            };

            if (flags.isEnabled('en_4869_inventory_predictor_v4')) {
                overrides.target_device_os = [];
                overrides.platforms = [PlatformMapping.INAPP, PlatformMapping.MWEB, PlatformMapping.Desktop];
            }

            const draft = MIN_VALID_DRAFT(overrides);

            return {
                ...state,
                audiencesMapping,
                audienceRatesMapping,
                orgFtaFee: organization.fta_fee,
                orgTechFee: organization.tech_fee,
                orgName: organization.name,
                organization,
                applists,
                draft,
                isLoading: false,
            };
        },
        updateDraft: (state, payload) => ({
            ...state,
            draft: {
                ...state.draft,
                [payload.field]: payload.value,
            },
            isUpdating: true,
        }),
        validateDraft: state => {
            const errors = validate(
                state.draft,
                getDraftValidators({ audiencesMapping: state.audiencesMapping })
            );

            return {
                ...state,
                errors,
                showErrors: errors.length,
            };
        },
        deriveDataCost: state => {
            const {
                audiencesMapping,
                audienceRatesMapping,
                orgFtaFee,
                draft: { ftaEnabled },
            } = state;

            let totalFirstPartyDataFee = 0;

            if (ftaEnabled) {
                totalFirstPartyDataFee += orgFtaFee;
            }

            let audiences = [];
            forEach(state.draft.tactics_generators, tactic => {
                forEach(tactic.targeting, target => {
                    if (target.module === 'audience_segments') {
                        audiences = audiences.concat(
                            target.draft.audiences,
                            target.draft.audience_exclude
                        );
                    }
                });
            });

            const audienceFee = _(audiences)
                .filter(x => x)
                .map(audienceId => {
                    const sourceType = get(audiencesMapping, [audienceId, 'source_type']);
                    const rate = get(audienceRatesMapping, [sourceType], 0);

                    return rate;
                })
                .max();

            totalFirstPartyDataFee += audienceFee || 0;

            return {
                ...state,
                totalFirstPartyDataFee,
            };
        },
        calculateBidPrice: state => {
            const {
                orgTechFee,
                totalFirstPartyDataFee,
                draft: { max_cpm_rate_local: totalSpend, totalThirdPartyFees },
            } = state;

            const mediaCost = calculateMediaCost({
                maxCpmRate: totalSpend,
                thirdPartyFees: totalThirdPartyFees,
                firstPartyFees: totalFirstPartyDataFee,
            });

            const platformTechFee = calculatePlatformTechFee({
                mediaCost,
                techFee: orgTechFee,
            });

            const bidRate = calculateBidPrice({
                mediaCost,
                platformTechFee,
            });

            return {
                ...state,
                platformTechFee,
                draft: {
                    ...state.draft,
                    bidRate,
                },
            };
        },
        generate: state => ({
            ...state,
            isUpdating: true,
        }),
        generateSuccess: state => ({
            ...state,
            isUpdating: false,
        }),
    },
});

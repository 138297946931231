import _ from 'lodash';

const PLATFORMS = [
    { value: 'inapp', label: 'Mobile In-App' },
    { value: 'mweb', label: 'Mobile Web' },
    { value: 'desktop', label: 'Desktop' },
    { value: 'ctv', label: 'Connected TV' },
    { value: 'dooh', label: 'Digital Out-of-Home' },
];

const MAPPING = {};
_.each(PLATFORMS, platform => (MAPPING[platform.value] = platform));

export const PLATFORM_MAPPING = MAPPING;

export default PLATFORMS;

import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const useStyles = makeStyles({
    fixedLayout: {
        position: 'fixed',
        paddingTop: 20,
        paddingRight: 20,
    },
});

export function FixedLayout(props) {
    const classes = useStyles();

    return <div className={classes.fixedLayout}>{props.children}</div>;
}

export function IncludeExcludeLayout({ type, label, children, marginBottom }) {
    return (
        <Box mb={marginBottom ? 1 : ''}>
            <Box mb={1}>
                <Grid container alignItems="center">
                    <Box mr={1}>
                        {type === 'Include' ? (
                            <CheckCircleOutlineIcon color="primary" />
                        ) : (
                            <CancelOutlinedIcon color="secondary" />
                        )}
                    </Box>
                    <div>{label ? label : type}</div>
                </Grid>
            </Box>
            <Box ml={3.7}>{children}</Box>
        </Box>
    );
}

const noResultsStyles = makeStyles({
    image: {
        width: '100%',
    },
});

export function NoResults(props) {
    const classes = noResultsStyles();
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="60vh"
        >
            <Box width="300" height="auto">
                <img
                    className={classes.image}
                    src="/images/audience-segments/audience-segments-not-found.png"
                />
            </Box>
            <Box mt={2}>
                <Typography variant="h6" gutterBottom>
                    {props.title}
                </Typography>
            </Box>
            {props.buttonText && (
                <Button variant="contained" color="primary" onClick={props.onClick} to={props.to}>
                    {props.buttonText}
                </Button>
            )}
        </Box>
    );
}

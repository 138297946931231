import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REDUCER_KEY, actions } from './reducer';
import {
    filterAudiences,
    selectRestrictedSegments,
    filteredfiltersByType,
    selectOrgId,
    editAudiences,
} from './selectors';

export function useOverallTableDashboardData() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);
    const filteredAudiences = useSelector(filterAudiences);
    const restrictedSegments = useSelector(selectRestrictedSegments);
    const audienceFiltersByType = useSelector(filteredfiltersByType);
    const orgId = useSelector(selectOrgId);
    const canEditAudiences = useSelector(editAudiences);

    return {
        ...state,
        ...boundActions,
        filteredAudiences,
        restrictedSegments,
        audienceFiltersByType,
        orgId,
        canEditAudiences,
    };
}

export default function reducer(state = {}, action) {
    switch (action.type) {
        case 'AD_PROGRESS__SETTING_TIMEZONE_DEFAULT': {
            return {
                ...state,
                timezone: action.payload.timezone,
            };
        }
        case 'AD_PROGRESS__TIMEZONE_CHANGE': {
            return {
                ...state,
                timezone: action.payload.timezone,
            };
        }
    }
    return state;
}

export const NAME = 'adProgress';

import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
/** import third-party libraries in the section above, keep the ascending order */

import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import {
    CTV_DEVICE_OS_OPTIONS,
    DOOH_DEVICES_OS_OPTIONS,
} from 'packages/forms/ad-form/services/default-values';
/** import classes and others in the section above, keep the ascending order */

/**
 * Fields from ad presets that are restricted on certain platforms.
 *
 * Note: The 'target_carriers' field is non-functional for any ad.
 * For detailed information, refer to the following pull request from 2020:
 * https://github.com/amoa/adbeans/pull/881
 *
 * This is defined in 'client/packages/forms/ad-form/index.js' within the 'setAdPreset' method.
 */
export const AdPresetPlatformRestrictedFields = {
    ctv: ['geoboxes', 'target_age_groups', 'target_device_language', 'target_genders'],
    dooh: [
        'target_age_groups',
        'target_carriers',
        'target_device_language',
        'target_device_os',
        'target_genders',
    ],
};

/**
 * Determines if the given platforms might have restrictions on ad presets.
 *
 * @param {*} platforms - The platforms to check for potential restrictions.
 * @returns {boolean} - Returns true if the platforms could have restrictions, otherwise false.
 */
export function couldAdPresetHaveRestrictions(platforms) {
    const platformsWithRestriction = Object.keys(AdPresetPlatformRestrictedFields);
    return platforms.some(platform => platformsWithRestriction.includes(platform));
}

/**
 * Check if the given ad preset field can be used on the specified platforms.
 *
 * @param {*} platforms - An array of platforms to check against.
 * @param {*} field - The ad preset field to verify.
 * @returns {boolean} - Returns true if the field can be used on the platforms, otherwise false.
 */
export function canAdPresetFieldBeUsed(platforms, field) {
    const isCTVAd = platforms.includes(CampaignTypeMapping.CTV);
    const isDOOHAd = platforms.includes(CampaignTypeMapping.DOOH);

    if (isCTVAd) {
        return !AdPresetPlatformRestrictedFields.ctv.includes(field);
    }
    if (isDOOHAd) {
        return !AdPresetPlatformRestrictedFields.dooh.includes(field);
    }

    return true;
}

/**
 * An array that combines the device OS options for both DOOH and CTV platforms.
 * This constant is used to filter out the target_device_os values that are not applicable
 * to the DOOH and CTV ad platforms.
 * @constant
 * @type {string[]}
 */
const DOOH_AND_CTV_DEVICE_OS_OPTIONS = [...DOOH_DEVICES_OS_OPTIONS, ...CTV_DEVICE_OS_OPTIONS];

/**
 * Adjusts the `target_device_os` field in the ad preset data to match the platform's device OS options
 * or removes it if it's empty after filtering
 *
 * @param {string[]} platforms - An array of platforms to check against, which can include ctv and dooh.
 * @param {Object} adPreset - The ad preset data object containing various ad configuration fields.
 * @param {string[]} enabledFields - An array of fields that are enabled and should be considered for filtering.
 * @returns {Object} The modified ad preset data object with `target_device_os` field filtered according to the platform.
 *
 * @example
 * const platforms = ['ctv'];
 * const adPreset = {
 *   target_device_os: ['Android', 'iOS', 'Roku'],
 *   other_field: 'value1'
 * };
 * const enabledFields = ['target_device_os', 'other_field'];
 * // result will have `target_device_os` filtered to only include 'Roku' if 'Roku' is in CTV_DEVICE_OS_OPTIONS.
 */
export function getAdPresetValuesToApply(platforms, adPreset, enabledFields) {
    const isCTVAd = platforms.includes(CampaignTypeMapping.CTV);
    const isDOOHAd = platforms.includes(CampaignTypeMapping.DOOH);

    const presetData = pick(adPreset, enabledFields);
    const { target_device_os: originalTargetOS } = presetData;

    // If 'target_device_os' is not used, return the 'presetData' early.
    if (isEmpty(originalTargetOS)) {
        return presetData;
    }

    // Helper function to set 'target_device_os' based on the provided options.
    function setTargetDeviceOS(options) {
        const filteredOS = intersection(originalTargetOS, options);
        if (isEmpty(filteredOS)) {
            delete presetData.target_device_os;
        } else {
            presetData.target_device_os = filteredOS;
        }
    }

    if (isCTVAd) {
        setTargetDeviceOS(CTV_DEVICE_OS_OPTIONS);
    } else if (isDOOHAd) {
        setTargetDeviceOS(DOOH_DEVICES_OS_OPTIONS);
    } else {
        presetData.target_device_os = difference(originalTargetOS, DOOH_AND_CTV_DEVICE_OS_OPTIONS);
        if (isEmpty(presetData.target_device_os)) {
            delete presetData.target_device_os;
        }
    }

    return presetData;
}

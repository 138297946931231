import React from 'react';
import LinearProgressMui from '@mui/material/LinearProgress';
import TypographyMui from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const styles = () =>
    createStyles({
        textContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 5,
        },
    });

const LinearProgress = styled(LinearProgressMui)(({ theme }) => ({
    '&.MuiLinearProgress-root': {
        height: 7,
        borderRadius: 3,
        backgroundColor: theme.palette.grey[200],
    },
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#646590',
    },
}));

const Typography = styled(TypographyMui)({
    '&.MuiTypography-root': {
        fontSize: '0.833rem',
    },
});

export default function Progress(props) {
    return (
        <div>
            <LinearProgress variant="determinate" value={props.value} color="primary" />
            <div className={styles.textContainer}>
                <div>
                    <Typography variant="p">{props.leftLabel}</Typography>
                </div>
                <div>
                    <Typography variant="p">{props.rightLabel}</Typography>
                </div>
            </div>
        </div>
    );
}

export function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

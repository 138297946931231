export const NAME = 'dealsTable';

const initialState = {
    loading: true,
    deals: [],
    sort: {},
    keywords: '',
    timezone: '',
    advertisers: [],
    filters: {
        advertiserIds: [],
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'INVENTORY_DEALS_TABLE__FETCH_START': {
            return {
                ...state,
                loading: true,
                keywords: action.payload.keywords,
                sort: action.payload.sort,
            };
        }

        case 'INVENTORY_DEALS_TABLE__FETCH_END': {
            return {
                ...state,
                deals: action.payload.deals,
                timezone: action.payload.timezone,
                loading: false,
            };
        }

        case 'INVENTORY_DEALS_TABLE__FETCH_ERROR': {
            return {
                ...state,
                loading: false,
            };
        }

        case 'INVENTORY_DEALS_MODAL__FETCH_ADVERTISERS_SUCESS': {
            const { advertisers } = action.payload;
            return {
                ...state,
                advertisers,
            };
        }

        case 'INVENTORY_DEALS_MODAL__APPLY_FILTERS': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        }

        default: {
            return state;
        }
    }
}

import React from 'react';

import { ConfirmDialog } from 'widgets-v6/modal';

const CreativeDeleteConfirmModal = ({
    isOpen,
    message,
    onCancel,
    canDelete,
    onConfirm,
    creativeToDelete,
    campaignId,
}) => {
    return (
        <ConfirmDialog
            isOpen={isOpen}
            title={canDelete ? 'Delete Creative?' : 'Cannot Delete Creative'}
            content={message}
            onCancel={canDelete ? onCancel : null}
            onConfirm={() => {
                return canDelete ? onConfirm(campaignId, creativeToDelete) : onCancel();
            }}
        />
    );
};

export default CreativeDeleteConfirmModal;

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import { BodyText, Caption } from 'widgets-v5/typography';
import InfoIconTooltip from 'widgets-v5/info-icon';

class FormField extends React.Component {
    static propTypes = {
        label: PropTypes.node,
        description: PropTypes.node,
        tooltip: PropTypes.shape({
            tooltip: PropTypes.node,
            tooltipTitle: PropTypes.string,
            position: PropTypes.oneOf(['left', 'right', 'top']),
        }),
        errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        showErrors: PropTypes.bool,
        isRequired: PropTypes.bool,
        className: PropTypes.string,
        marginRight: PropTypes.number,
        marginBottom: PropTypes.number,
        labelMarginBottom: PropTypes.number,
        marginTop: PropTypes.number,
    };

    static defaultProps = {
        marginBottom: 15,
        labelMarginBottom: 5,
    };

    render() {
        const {
            children,
            label,
            isRequired,
            showErrors,
            description,
            tooltip,
            isBetaFeature,
        } = this.props;

        const requiredAsterix = this.props.isRequired ? '*' : '';

        const errors = showErrors ? [].concat(this.props.errors) : [];

        return (
            <span
                style={this.props.style}
                className={classnames('ef5-form-field', this.props.className)}
            >
                {label && (
                    <span
                        style={{ marginBottom: this.props.labelMarginBottom }}
                        className="ef6-alignment__flex-center"
                    >
                        <BodyText>{label}</BodyText>
                        {isRequired && <sup className="ef5-form-field__required-label" />}
                        {tooltip && (
                            <InfoIconTooltip className="ef5-form-field__tooltip" {...tooltip} />
                        )}
                        {isBetaFeature && <sup className="ef5-form-field__beta-label">(BETA)</sup>}
                    </span>
                )}
                <div
                    className={classnames(
                        'ef5-form-field__children',
                        this.props.classNameChildren,
                        { 'ef5-form-field__children_has-error': showErrors && errors.length }
                    )}
                >
                    {children}
                </div>
                {description && (
                    <BodyText type="info">
                        <div
                            style={{ marginTop: this.props.descriptionlMarginTop }}
                            className="ef5-form-field__description"
                        >
                            {description}
                        </div>
                    </BodyText>
                )}
                {showErrors && errors.length > 0 && (
                    <ul className="ef5-form-field__errors">
                        {errors.map((error, i) => (
                            <li key={i} className="ef5-form-field__error">
                                {error}
                            </li>
                        ))}
                    </ul>
                )}
            </span>
        );
    }
}

export default FormField;

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REDUCER_KEY, actions } from './reducer';
import { selectOrgId } from '../overall-dashboard/v2/selectors';

export function usePermutiveDashboardData() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);
    const organizationId = useSelector(selectOrgId);

    return {
        ...state,
        ...boundActions,
        organizationId,
    };
}

import d3 from 'd3';
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import numeral from 'numeral';

/*
    prefix 'M_' is the moment wrapper
 */

var D3Chart = function() {};

D3Chart.prototype.init = function(el, data, meta) {
    var margin = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        padding = {
            top: 40,
            right: 75,
            bottom: 100,
            left: 75,
        };

    this._conf = {};
    this._conf.margin = margin;
    this._conf.padding = padding;
    this._conf.w_svg = 3000;
    this._conf.h_svg = 1000;
    this._conf.w_wrap = this._conf.w_svg - margin.left - margin.right;

    this._conf.h_wrap = this._conf.h_svg - margin.top - margin.bottom;
    this._conf.width = this._conf.w_wrap - padding.left - padding.right;
    this._conf.height = this._conf.h_wrap - padding.top - padding.bottom;

    this._conf.dataIndex = {};
    this._conf.dataIndex = meta.dataIndex;
    this._conf.timezone = meta.timezone;

    this._cache = {};

    this._beacon = {};
    this._beacon.beaconMeta = [];
    this._beacon.beaconDirective = [];
    this._beacon.items = {};

    this._selected = {};
    this._selected.dataLines_beacon = {};

    this._conf.isShown = {};
    this._conf.isShown_beacon = {};

    // if (data.length) this.updateCache(data, meta);
};

D3Chart.prototype.updateCache = function(data, meta) {
    var that = this;
    var _rawData = data; // Save this original data for later use in tool tips

    that._conf.timezone = meta.timezone;
    that._cache.data = data; // This data in _cached will be scaled, values in the data is used to plot chart

    // Ordinate (data axis)
    // Basic values
    var dataUnfilteredImpression = data.map(function(d) {
        return d.y[that._conf.dataIndex.unfilteredImpressions].value;
    });

    var dataFilteredImpressions = data.map(function(d) {
        return d.y[that._conf.dataIndex.filteredImpressions].value;
    });
    var dataFilteredClinks = data.map(function(d) {
        return d.y[that._conf.dataIndex.filteredClicks].value;
    });
    var dataFilteredSpend = data.map(function(d) {
        return d.y[that._conf.dataIndex.filteredSpend].value;
    });
    var dataFilteredRevenue = data.map(function(d) {
        return d.y[that._conf.dataIndex.filteredRevenue].value;
    });

    // Calculate presentation data values
    var maxFilteredImpression = d3.max(dataFilteredImpressions, function(d) {
        return d;
    });
    var minFilteredImpression = d3.min(dataFilteredImpressions, function(d) {
        return d;
    });

    var maxFilteredClick = d3.max(dataFilteredClinks, function(d) {
        return d;
    });
    var maxFilteredSpend = d3.max(dataFilteredSpend, function(d) {
        return d;
    });
    var maxFilteredTotalMediaCostLocal = d3.max(dataFilteredSpend, function(d) {
        return d;
    });
    var maxFilteredRevenue = d3.max(dataFilteredRevenue, function(d) {
        return d;
    });

    var offSet_head = 0.2 * maxFilteredImpression;
    var offSet_floor = 0;

    if (maxFilteredImpression !== 0 && minFilteredImpression !== 0) {
        // with head room and floor space
        that._cache.headRoom = offSet_head;
        that._cache.floorOffset = offSet_floor;

        that._cache.maxDataY = maxFilteredImpression;
        that._cache.minDataY = minFilteredImpression;

        that._cache.data = scaleFilteredClicksDataForDisplay(that._cache.data);
        that._cache.data = scaleFilteredSpendDataForDisplay(that._cache.data);
        that._cache.data = scale_filteredTotalMediaCostLocal_data_forDisplay(that._cache.data);
        that._cache.data = scaleFilteredRevenueDataForDisplay(that._cache.data);
    } else if (maxFilteredImpression !== 0 && minFilteredImpression === 0) {
        // with head room and no floor space
        that._cache.headRoom = offSet_head;
        that._cache.floorOffset = 0;

        that._cache.maxDataY = maxFilteredImpression;
        that._cache.minDataY = 0;

        that._cache.data = scaleFilteredClicksDataForDisplay(that._cache.data);
        that._cache.data = scaleFilteredSpendDataForDisplay(that._cache.data);
        that._cache.data = scale_filteredTotalMediaCostLocal_data_forDisplay(that._cache.data);
        that._cache.data = scaleFilteredRevenueDataForDisplay(that._cache.data);
    } else if (maxFilteredImpression === 0 && minFilteredImpression === 0) {
        // no head room and no floor space
        that._cache.headRoom = 0;
        that._cache.floorOffset = 0;

        that._cache.maxDataY = 100;
        that._cache.minDataY = 0;

        // data is a flatline
        that._cache.data = flateLine(data);
    }

    // The next three functions return a *clone* of data for which the named metric is scaled

    function scaleFilteredClicksDataForDisplay(_data) {
        if (_data.length) {
            var scalingFactor = 0.25; // for example if 0.5, maxFilteredClick is half as tall as maxFilteredImpression
            // or if 1, maxFilteredClick is as tall as maxFilteredImpression
            var displayScaling =
                maxFilteredClick !== 0
                    ? (maxFilteredImpression / maxFilteredClick) * scalingFactor
                    : 0;
            var dataIndex = that._conf.dataIndex.filteredClicks;

            var clone_data = _.cloneDeep(_data);
            var ret = _.map(clone_data, function(item) {
                item.y[dataIndex].value = item.y[dataIndex].value * displayScaling;
                return item;
            });
            return ret;
        }
    }

    function scaleFilteredSpendDataForDisplay(_data) {
        if (_data.length) {
            var scalingFactor = 0.25; // for example if 0.5, maxFilteredSpend is half as tall as maxFilteredImpression
            // or if 1, maxFilteredSpend is as tall as maxFilteredImpression
            var displayScaling =
                maxFilteredSpend !== 0
                    ? (maxFilteredImpression / maxFilteredSpend) * scalingFactor
                    : 0;
            var dataIndex = that._conf.dataIndex.filteredSpend;

            var clone_data = _.cloneDeep(_data);
            var ret = _.map(clone_data, function(item) {
                item.y[dataIndex].value = item.y[dataIndex].value * displayScaling;
                return item;
            });
            return ret;
        }
    }

    function scale_filteredTotalMediaCostLocal_data_forDisplay(_data) {
        if (_data.length) {
            var scalingFactor = 0.25; // for example if 0.5, maxFilteredTotalMediaCostLocal is half as tall as maxFilteredImpression
            // or if 1, maxFilteredTotalMediaCostLocal is as tall as maxFilteredImpression
            var displayScaling =
                maxFilteredTotalMediaCostLocal !== 0
                    ? (maxFilteredImpression / maxFilteredTotalMediaCostLocal) * scalingFactor
                    : 0;
            var dataIndex = that._conf.dataIndex.total_media_cost_local;

            var clone_data = _.cloneDeep(_data);
            var ret = _.map(clone_data, function(item) {
                item.y[dataIndex].value = item.y[dataIndex].value * displayScaling;
                return item;
            });
            return ret;
        }
    }

    function scaleFilteredRevenueDataForDisplay(_data) {
        if (_data.length) {
            var scalingFactor = 0.25; // for example if 0.5, maxFilteredSpend is half as tall as maxFilteredImpression
            // or if 1, maxFilteredSpend is as tall as maxFilteredImpression
            var displayScaling =
                maxFilteredRevenue !== 0
                    ? (maxFilteredImpression / maxFilteredRevenue) * scalingFactor
                    : 0;
            var dataIndex = that._conf.dataIndex.filteredRevenue;

            var clone_data = _.cloneDeep(_data);
            var ret = _.map(clone_data, function(item) {
                item.y[dataIndex].value = item.y[dataIndex].value * displayScaling;
                return item;
            });
            return ret;
        }
    }

    // The following function return flat line data
    function flateLine(_data) {
        if (_data.length) {
            var clone_data = _.cloneDeep(_data);
            var ret = _.map(clone_data, function(item) {
                item.y[that._conf.dataIndex.filteredImpressions].value =
                    that._cache.maxDataY * 0.05;
                item.y[that._conf.dataIndex.filteredClicks].value = that._cache.maxDataY * 0.02;
                item.y[that._conf.dataIndex.ctr].value = 0;
                return item;
            });
            return ret;
        }
    }

    var ordinateRange = this._cache.maxDataY - this._cache.minDataY;
    var deltaHeight_per_dataPoints = parseInt(this._conf.height / ordinateRange);
    this._cache.deltaHeight_per_dataPoints = deltaHeight_per_dataPoints;

    // Abscissa (date axis) is calculated based on unfiltered impressions
    var dataX = data.map(function(d) {
        return d.date.value;
    });
    var maxDataX = d3.max(dataX, function(d) {
        return d;
    });
    var minDataX = d3.min(dataX, function(d) {
        return d;
    });

    // prefix 'm_' is the moment wrapper
    var M_maxDataX = moment(
        d3.max(dataX, function(d) {
            return d;
        })
    );
    var M_minDataX = moment(
        d3.min(dataX, function(d) {
            return d;
        })
    );
    var howManyDays_btw_startEndDate = M_maxDataX.diff(M_minDataX, 'days') + 1; // include end point
    this._cache.dataResolutionOnXAxis = howManyDays_btw_startEndDate;

    // narrower plot area if less data point
    if (this._cache.dataResolutionOnXAxis > 10) {
        this._cache.plotAreaWidth = this._conf.width * 1;
    } else if (9 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 10) {
        this._cache.plotAreaWidth = this._conf.width * 0.9;
    } else if (7 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 9) {
        this._cache.plotAreaWidth = this._conf.width * 0.7;
    } else if (5 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 7) {
        this._cache.plotAreaWidth = this._conf.width * 0.5;
    } else if (3 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 5) {
        this._cache.plotAreaWidth = this._conf.width * 0.3;
    } else if (1 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 3) {
        this._cache.plotAreaWidth = this._conf.width * 0.2;
    } else if (1 === this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 3) {
        this._cache.plotAreaWidth = this._conf.width * 0.2;
    } else {
        this._cache.plotAreaWidth = this._conf.width * 1;
    }

    this._cache.plottingAreaLeftOffset = (this._conf.width - this._cache.plotAreaWidth) / 2;

    var deltaWidth_per_dataPoint = parseInt(
        this._cache.plotAreaWidth / howManyDays_btw_startEndDate
    );

    this._cache.deltaWidth_per_dataPoint = deltaWidth_per_dataPoint;

    this._cache.data_dates = dataX;
    this._cache.dateEnd = maxDataX;
    this._cache.dateStart = minDataX;

    // For ctr
    var dataCtr = data.map(function(d) {
        return d.y[that._conf.dataIndex.ctr].value;
    });
    var data_ctr_max = d3.max(dataCtr, function(d) {
        return d;
    });
    var data_ctr_min = d3.min(dataCtr, function(d) {
        return d;
    });
    this._cache.data_ctr_max = data_ctr_max;
    this._cache.data_ctr_min = data_ctr_min;

    // For eCPM
    var data_eCPM = data.map(function(d) {
        return d.y[that._conf.dataIndex.eCPM].value;
    });
    var data_eCPM_max = d3.max(data_eCPM, function(d) {
        return d;
    });
    var data_eCPM_min = d3.min(data_eCPM, function(d) {
        return d;
    });
    this._cache.data_eCPM_max = data_eCPM_max;
    this._cache.data_eCPM_min = data_eCPM_min;

    // For eCPC
    var data_eCPC = data.map(function(d) {
        return d.y[that._conf.dataIndex.eCPC].value;
    });
    var data_eCPC_max = d3.max(data_eCPC, function(d) {
        return d;
    });
    var data_eCPC_min = d3.min(data_eCPC, function(d) {
        return d;
    });
    this._cache.data_eCPC_max = data_eCPC_max;
    this._cache.data_eCPC_min = data_eCPC_min;

    // For eCPM
    var data_eRPM = data.map(function(d) {
        return d.y[that._conf.dataIndex.eRPM].value;
    });
    var data_eRPM_max = d3.max(data_eRPM, function(d) {
        return d;
    });
    var data_eRPM_min = d3.min(data_eRPM, function(d) {
        return d;
    });
    this._cache.data_eRPM_max = data_eRPM_max;
    this._cache.data_eRPM_min = data_eRPM_min;

    // For eCPC
    var data_eRPC = data.map(function(d) {
        return d.y[that._conf.dataIndex.eRPC].value;
    });
    var data_eRPC_max = d3.max(data_eRPC, function(d) {
        return d;
    });
    var data_eRPC_min = d3.min(data_eRPC, function(d) {
        return d;
    });
    this._cache.data_eRPC_max = data_eRPC_max;
    this._cache.data_eRPC_min = data_eRPC_min;

    // For daily uniq
    var data_daily_uniq = data.map(function(d) {
        return d.y[that._conf.dataIndex.daily_uniq].value;
    });
    var data_daily_uniq_max = d3.max(data_daily_uniq, function(d) {
        return d;
    });
    var data_daily_uniq_min = d3.min(data_daily_uniq, function(d) {
        return d;
    });
    this._cache.data_daily_uniq_max = data_daily_uniq_max;
    this._cache.data_daily_uniq_min = data_daily_uniq_min;

    // For unique users
    var data_unique_users = data.map(function(d) {
        return d.y[that._conf.dataIndex.unique_users].value;
    });
    var data_unique_users_max = d3.max(data_unique_users, function(d) {
        return d;
    });
    var data_unique_users_min = d3.min(data_unique_users, function(d) {
        return d;
    });
    this._cache.data_unique_users_max = data_unique_users_max;
    this._cache.data_unique_users_min = data_unique_users_min;

    // For media cost
    var data_owner_media_cost_2_local = data.map(function(d) {
        return d.y[that._conf.dataIndex.owner_media_cost_2_local].value;
    });
    var data_owner_media_cost_2_local_max = d3.max(data_owner_media_cost_2_local, function(d) {
        return d;
    });
    var data_owner_media_cost_2_local_min = d3.min(data_owner_media_cost_2_local, function(d) {
        return d;
    });
    this._cache.data_owner_media_cost_2_local_max = data_owner_media_cost_2_local_max;
    this._cache.data_owner_media_cost_2_local_min = data_owner_media_cost_2_local_min;

    // For VCR
    var data_VCR = data.map(function(d) {
        return d.y[that._conf.dataIndex.vcr].value;
    });
    var data_vcr_max = d3.max(data_VCR, function(d) {
        return d;
    });
    var data_vcr_min = d3.min(data_VCR, function(d) {
        return d;
    });
    this._cache.data_vcr_max = data_vcr_max;
    this._cache.data_vcr_min = data_vcr_min;

    // For total cost eCPCv
    var data_owner_total_media_cost_local_ecpcv = data.map(function(d) {
        return d.y[that._conf.dataIndex.owner_total_media_cost_local_ecpcv].value;
    });
    var data_owner_total_media_cost_local_ecpcv_max = d3.max(
        data_owner_total_media_cost_local_ecpcv,
        function(d) {
            return d;
        }
    );
    var data_owner_total_media_cost_local_ecpcv_min = d3.min(
        data_owner_total_media_cost_local_ecpcv,
        function(d) {
            return d;
        }
    );
    this._cache.data_owner_total_media_cost_local_ecpcv_max = data_owner_total_media_cost_local_ecpcv_max;
    this._cache.data_owner_total_media_cost_local_ecpcv_min = data_owner_total_media_cost_local_ecpcv_min;

    // For revenue eCPCv
    var data_revenue_ecpcv = data.map(function(d) {
        return d.y[that._conf.dataIndex.revenue_ecpcv].value;
    });
    var data_revenue_ecpcv_max = d3.max(data_revenue_ecpcv, function(d) {
        return d;
    });
    var data_revenue_ecpcv_min = d3.min(data_revenue_ecpcv, function(d) {
        return d;
    });
    this._cache.data_revenue_ecpcv_max = data_revenue_ecpcv_max;
    this._cache.data_revenue_ecpcv_min = data_revenue_ecpcv_min;

    // For average freq
    var data_average_freq = data.map(function(d) {
        return d.y[that._conf.dataIndex.average_freq].value;
    });
    var data_average_freq_max = d3.max(data_average_freq, function(d) {
        return d;
    });
    var data_average_freq_min = d3.min(data_average_freq, function(d) {
        return d;
    });
    this._cache.data_average_freq_max = data_average_freq_max;
    this._cache.data_average_freq_min = data_average_freq_min;

    // For win rate
    var data_win_rate = data.map(function(d) {
        return d.y[that._conf.dataIndex.win_rate].value;
    });
    var data_win_rate_max = d3.max(data_win_rate, function(d) {
        return d;
    });
    var data_win_rate_min = d3.min(data_win_rate, function(d) {
        return d;
    });
    this._cache.data_win_rate_max = data_win_rate_max;
    this._cache.data_win_rate_min = data_win_rate_min;

    // Beacons
    const beaconsCompareToThemself = true;
    if (beaconsCompareToThemself) {
        // Collect extent of all beacon into tmp and find extent oncee again
        const tmp = []; // hold all max/min for all beacons
        meta.beacons.forEach(b => {
            const index = b.index;
            const data_beacon = data.map(d => d.y[index].value);
            const extent = d3.extent(data_beacon, d => d);
            tmp.push(extent[0]);
            tmp.push(extent[1]);
        });
        const extentAll = d3.extent(tmp, d => d);
        // All becons will have the same extent
        meta.beacons.forEach(b => {
            const key = b.key;
            const item = that._beacon.items[key];
            item.min = extentAll[0];
            item.max = extentAll[1];
            item.scales = that._scales_beacon(data, extentAll);
        });
    } else {
        meta.beacons.forEach(b => {
            const key = b.key;
            const index = b.index;
            const item = that._beacon.items[key];
            const data_beacon = data.map(d => d.y[index].value);
            const extent = d3.extent(data_beacon, d => d);
            item.min = extent[0];
            item.max = extent[1];
            item.scales = that._scales_beacon(data, extent);
        });
    }

    // need to merge raw data back for display in tooltips
    _.each(this._cache.data, function(d, i) {
        _.each(d.y, function(dd, ii) {
            const rawValue = _rawData[i].y[ii].value;
            dd.rawValue = rawValue;
        });
    });
};

//////////////
// beacons  //
//////////////
D3Chart.prototype._manageBeacon = function(el, state) {
    var that = this;
    var data = state.data;
    var meta = state.meta;

    const beacons_next = meta.beacons;
    const beacons_current = this._beacon.beaconMeta;

    // const beaconDirective_current = [
    //     // { key: 'event_unload', name: 'event_unload', label: 'Page Load', index:11, style:'beacon02' },
    //     // { key: 'todesctory', name: 'event_unload', label: 'Page Load', index:11, style:'beacon02' }
    // ];

    const beaconDirective = []; // [key, current: true/false, next: true/false, lifeCycle: create/destroy/noAction]

    const beacons_unchanged = [];
    // beacons_unchanged is an array whose elements are the intersection of beacons_current and beacons_next.
    // These items are beacons exist in current and next, and does not required creation nor destruction
    // Since lodash-v3.10 does not have _.intersectWith(), we have to impliment our own:
    beacons_next.forEach(next => {
        const tmp = [];
        beacons_current.forEach(current => {
            tmp.push(next.key === current.key);
        });
        // If at least one element in tmp is true, [F,T,F,F,...], then:
        //      The item in outter loop (current) IS an item in beacons_next,
        //      which means the item (current) is NOT new.
        //      existsInBoth = ( F || T || F || F ) = true;
        // If all elements in tmp is false, [F,F,F,F..], then:
        //      The item in outter loop (current) is NOT an item in beacons_next,
        //      which means the item (current) is either a new item or an outdated item.
        //      existsInBoth = ( F || F || F || F ) = false.
        // If tmp is empty, [], then:
        //      The items in outter loop (current) is NOT an item in beacons_unchanged,
        //      which means the item (current) is either a new item or an outdated item.
        //      existsInBoth = false.
        const existsInBoth = tmp.reduce((acc, item) => {
            return acc || item;
        }, false);
        // console.log('yyyy: manageBeacon: existsInBoth: ', next.key, tmp, existsInBoth);
        if (existsInBoth) {
            const key = next.key;
            const current = _(beacons_current).find({ key });
            beaconDirective.push({
                key: next.key,
                next: true,
                current: true,
                lifeCycle: 'update',
                index: next.index,
                currentStyle: current.style,
                nextStyle: next.style,
            });
            beacons_unchanged.push(next);
        }
    });

    // Find the new beacon items that need to be created.
    // We want to find the items in beacons_next that does not exist in beacons_unchanged,
    // in otherword we are looking for the sysmmetric different (xor) of beacons_next and beacons_unchanged.
    // Since lodash-v3.10 does not have _.xorWith(), we have to impliment our own:
    beacons_next.forEach(next => {
        if (beacons_unchanged.length === 0) {
            beaconDirective.push({
                key: next.key,
                next: true,
                current: false,
                lifeCycle: 'create',
                index: next.index,
                currentStyle: void 0,
                nextStyle: next.style,
            });
        } else {
            const tmp = [];
            beacons_unchanged.forEach(unchanged => {
                tmp.push(next.key !== unchanged.key);
            });
            // If at least one elements in tmp is false, [T,F,T,T,...], then:
            //      the running item in outter loop (next) IS an item in beacons_unchanged, it is NOT new
            //      => isNew = ( T && F && T && T ) = false.
            // If all elements in tmp is true, [T,T,T,T..], then:
            //      the running item in outter loop (next) is NOT an item in beacons_unchanged, it is new
            //      => isNew = ( T && T && T && T ) = true.
            // If tmp is empty, [], then:
            //      the running items in outter loop (next) is NOT an item in beacons_unchanged
            //      => isNew = true.
            const isNew = tmp.reduce((acc, item) => {
                return acc && item;
            }, true);
            // console.log('yyyy: manageBeacon: isNew: ', next.key , tmp, isNew);
            if (isNew) {
                beaconDirective.push({
                    key: next.key,
                    next: true,
                    current: false,
                    lifeCycle: 'create',
                    index: next.index,
                    currentStyle: void 0,
                    nextStyle: next.style,
                });
            }
        }
    });

    // Find the outdated beacon items that need to be destroyed.
    // We want to find the items in beacons_current that does not exist in beacons_unchanged,
    // in otherword we are looking for the sysmmetric different (xor) of beacons_current and beacons_unchanged.
    // Since lodash-v3.10 does not have _.xorWith(), we have to impliment our own:
    beacons_current.forEach(current => {
        if (beacons_unchanged.length === 0) {
            beaconDirective.push({
                key: current.key,
                next: false,
                current: true,
                lifeCycle: 'destroy',
                index: current.index,
                currentStyle: current.style,
                nextStyle: void 0,
            });
        } else {
            const tmp = [];
            beacons_unchanged.forEach(unchanged => {
                tmp.push(current.key !== unchanged.key);
            });
            // If at least one elements in tmp is false, [T,F,T,T,...], then:
            //      the running item in outter loop (current) IS an item in beacons_unchanged, it is NOT outdated.
            //      => isOutdated = ( T && F && T && T ) = false.
            // If all elements in tmp is true, [T,T,T,T..], then:
            //      the running item in outter loop (current) is NOT an item in beacons_unchanged, it isOuted.
            //      => isOutdated = ( T && T && T && T ) = true.
            // If tmp is empty, [], then:
            //      the items in outter loop (current) is NOT an item in beacons_unchanged
            //      => isOutdated = true.
            const isOutdated = tmp.reduce((acc, item) => {
                return acc && item;
            }, true);
            // console.log('yyyy: manageBeacon: isOutdated: ', current.key, tmp, isOutdated);
            if (isOutdated) {
                beaconDirective.push({
                    key: current.key,
                    next: false,
                    current: true,
                    lifeCycle: 'destroy',
                    index: current.index,
                    currentStyle: current.style,
                    nextStyle: void 0,
                });
            }
        }
    });

    const dataLines_beacon = this._selected.dataLines_beacon;
    const dataArea = this._selected.dataArea;

    beaconDirective.forEach(b => {
        switch (b.lifeCycle) {
            case 'destroy':
                if (dataLines_beacon[b.key]) {
                    dataLines_beacon[b.key].remove();
                    delete this._beacon.items[b.key];
                } else {
                    throw new Error('Trying to remove non existence beacon plot');
                }
                break;
            case 'create':
                if (!dataLines_beacon[b.key]) {
                    dataLines_beacon[b.key] = dataArea
                        .append('g')
                        .classed('data-line-beacon', true)
                        .classed(b.nextStyle, true)
                        .classed('isVisible-false', true)
                        .attr('data-beaconName', b.key);
                    dataLines_beacon[b.key].append('path');
                    this._beacon.items[b.key] = {
                        scales: void 0,
                        max: void 0,
                        min: void 0,
                    };
                }
                break;
            case 'update':
                if (dataLines_beacon[b.key]) {
                    dataLines_beacon[b.key]
                        .classed(b.currentStyle, false)
                        .classed(b.nextStyle, true);
                }
                break;
        }

        // this._updateBeacons(data, meta, b);
    });

    this._beacon.beaconDirective = beaconDirective;
    this._beacon.beaconMeta = _.cloneDeep(beacons_next);
};

///////////////////////
// public method     //
///////////////////////
D3Chart.prototype.create = function(el, state, isClient) {
    var data = state.data;
    var meta = state.meta;
    this.isClient = isClient;

    this.init(el, data, meta);

    // =================
    // == Create Area ==
    // =================
    this._selected.component = el;

    var viewBoxMaxX = this._conf.w_svg;
    var viewBoxMaxY = this._conf.h_svg;

    var svgContainer = d3
        .select(el)
        .select('svg')
        .attr('class', 'd3')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', '0 0 ' + viewBoxMaxX + ' ' + viewBoxMaxY);
    this._selected.svg = svgContainer;

    var wrap = svgContainer
        .append('g')
        .classed('wrap', true)
        .attr(
            'transform',
            'translate(' + this._conf.margin.left + ',' + this._conf.margin.top + ')'
        );

    wrap.append('rect')
        .attr('class', 'wrap-background')
        .attr('width', this._conf.w_wrap)
        .attr('height', this._conf.h_wrap)
        .attr('fill', 'transparent');

    var plotArea = wrap
        .append('g')
        .classed('plot-area', true)
        .attr(
            'transform',
            'translate(' + this._conf.padding.left + ',' + this._conf.padding.top + ')'
        );
    this._selected.plotArea = plotArea;

    plotArea
        .append('rect')
        .attr('class', 'plot-area-background')
        .attr('width', this._conf.width)
        .attr('height', this._conf.height)
        .attr('fill', 'transparent');

    const dataArea = plotArea.append('g').classed('data-area', true);
    this._selected.dataArea = dataArea;

    var dataPoints_impressions = dataArea.append('g').classed('data-points-impressions', true);
    var dataPoints_clicks = dataArea.append('g').classed('data-points-clicks', true);
    var dataPoints_spend = dataArea.append('g').classed('data-points-spend', true);
    var dataPoints_totalMediaCostLocal = dataArea
        .append('g')
        .classed('data-points--total-media-cost-local', true);
    var dataPoints_revenue = dataArea.append('g').classed('data-points-revenue', true);

    var dataPoints_ctr = dataArea.append('g').classed('data-points-ctr', true);

    var dataLine_ctr = dataArea.append('g').classed('data-line-ctr', true);

    var dataLine_eCPM = dataArea.append('g').classed('data-line-eCPM', true);
    var dataLine_eCPC = dataArea.append('g').classed('data-line-eCPC', true);

    var dataLine_eRPM = dataArea.append('g').classed('data-line-eRPM', true);
    var dataLine_eRPC = dataArea.append('g').classed('data-line-eRPC', true);

    var dataLine_daily_uniq = dataArea.append('g').classed('data-line-daily_uniq', true);
    var dataLine_unique_users = dataArea.append('g').classed('data-line-unique_users', true);
    var dataLine_owner_media_cost_2_local = dataArea
        .append('g')
        .classed('data-line-owner_media_cost_2_local', true);
    var dataPoints_vcr = dataArea.append('g').classed('data-points-vcr', true);
    var dataLine_vcr = dataArea.append('g').classed('data-line-vcr', true);

    var dataLine_owner_total_media_cost_local_ecpcv = dataArea
        .append('g')
        .classed('data-line-owner_total_media_cost_local_ecpcv', true);
    var dataLine_revenue_ecpcv = dataArea.append('g').classed('data-line-revenue_ecpcv', true);

    var dataLine_average_freq = dataArea.append('g').classed('data-line-average_freq', true);
    var dataLine_win_rate = dataArea.append('g').classed('data-line-win_rate', true);

    dataLine_ctr.append('path');
    dataLine_eCPM.append('path');
    dataLine_eCPC.append('path');
    dataLine_eRPM.append('path');
    dataLine_eRPC.append('path');
    dataLine_daily_uniq.append('path');
    dataLine_unique_users.append('path');
    dataLine_owner_media_cost_2_local.append('path');
    dataLine_vcr.append('path');
    dataLine_owner_total_media_cost_local_ecpcv.append('path');
    dataLine_revenue_ecpcv.append('path');
    dataLine_average_freq.append('path');
    dataLine_win_rate.append('path');

    this._selected.dataPoints_impressions = dataPoints_impressions;
    this._selected.dataPoints_clicks = dataPoints_clicks;
    this._selected.dataPoints_spend = dataPoints_spend;
    this._selected.dataPoints_totalMediaCostLocal = dataPoints_totalMediaCostLocal;
    this._selected.dataPoints_revenue = dataPoints_revenue;
    this._selected.dataPoints_ctr = dataPoints_ctr;

    this._selected.dataLine_ctr = dataLine_ctr;
    this._selected.dataLine_eCPM = dataLine_eCPM;
    this._selected.dataLine_eCPC = dataLine_eCPC;

    this._selected.dataLine_eRPM = dataLine_eRPM;
    this._selected.dataLine_eRPC = dataLine_eRPC;

    this._selected.dataLine_daily_uniq = dataLine_daily_uniq;
    this._selected.dataLine_unique_users = dataLine_unique_users;
    this._selected.dataLine_owner_media_cost_2_local = dataLine_owner_media_cost_2_local;

    this._selected.dataPoints_vcr = dataPoints_vcr;
    this._selected.dataLine_vcr = dataLine_vcr;
    this._selected.dataLine_owner_total_media_cost_local_ecpcv = dataLine_owner_total_media_cost_local_ecpcv;
    this._selected.dataLine_revenue_ecpcv = dataLine_revenue_ecpcv;

    this._selected.dataLine_average_freq = dataLine_average_freq;
    this._selected.dataLine_win_rate = dataLine_win_rate;

    this._prepareXAxisContainer(el);
    this._prepareYAxisContainer(el);

    var dataPoints_mouseBands = plotArea.append('g').classed('data-points-mouseBands', true);
    this._selected.mouseBands = dataPoints_mouseBands;

    // mouse line
    const lineLength = this._conf.height;
    const mouseLineGroup = plotArea.append('g').classed('mouse-line', true);
    mouseLineGroup
        .append('line')
        .attr('x1', '0')
        .attr('y1', '0')
        .attr('x2', '0')
        .attr('y2', lineLength)
        .attr('stroke-dasharray', '5, 5')
        .attr('stroke-width', '2')
        .attr('fill', 'none');
    this._selected.mouseLineGroup = mouseLineGroup;

    // this.control({ isShown: { impressions: true, clicks: true, spend: true, ctr: true, ecpm: true, ecpc: true } });

    this._events_mount(el);
};

D3Chart.prototype.update = function(el, state) {
    var that = this;
    var data = state.data;
    var meta = state.meta;

    this._isLoading = state.isLoading;

    this._updateClassForStatsMetricsType(el, state);

    if (data.length) {
        this._manageBeacon(el, state);
        this.updateCache(data, meta);

        var plotArea = d3
            .select(el)
            .select('.plot-area')
            .attr(
                'transform',
                'translate(' +
                    (parseInt(this._conf.padding.left) + this._cache.plottingAreaLeftOffset) +
                    ',' +
                    this._conf.padding.top +
                    ')'
            )
            .attr('width', this._cache.plotAreaWidth)
            .attr('height', this._conf.height);

        plotArea.select('.plot-area-background').attr('width', this._cache.plotAreaWidth);

        this._updateXAxis(el);
        // this._updateYAxis(el);  // don't show y axis

        // filtered impressions
        var options_filteredImpressions = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.filteredImpressions,
            el: d3.select(el).select('.data-points-impressions'),
            scales: this._scalesFilteredImpressions(data),
            colorFill: '#D4E157',
            colorBorder: '#D4E157',
            fillOpacity: meta.statsMetricsType === 'impressions' ? '1' : '.5',
            strokeOpacity: meta.statsMetricsType === 'impressions' ? '1' : '.5',
            slimingFactor: 1,
        };
        this._plotBar(options_filteredImpressions);

        // filtered clicks
        var options_filteredClicks = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.filteredClicks,
            el: d3.select(el).select('.data-points-clicks'),
            scales: this._scalesFilteredClicks(data),
            colorFill: '#4DB6AC',
            colorBorder: '#4DB6AC',
            fillOpacity: meta.statsMetricsType === 'clicks' ? '1' : '.7',
            strokeOpacity: meta.statsMetricsType === 'clicks' ? '1' : '.7',
            slimingFactor: 2,
        };
        this._plotBar(options_filteredClicks);

        // filtered spend (new name is Billable Cost)
        var options_filteredSpend = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.filteredSpend,
            el: d3.select(el).select('.data-points-spend'),
            scales: this._scalesFilteredSpend(data),
            colorFill: '#3949AB',
            colorBorder: '#3949AB',
            fillOpacity: meta.statsMetricsType === 'spend' ? '1' : '.5',
            strokeOpacity: meta.statsMetricsType === 'spend' ? '1' : '.5',
            slimingFactor: 3,
        };
        this._plotBar(options_filteredSpend);

        // filtered Total Cost
        var options_filteredTotalMediaCostLocal = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.total_media_cost_local,
            el: d3.select(el).select('.data-points--total-media-cost-local'),
            scales: this._scalesTotalMediaCostLocal(data),
            colorFill: '#ff0000',
            colorBorder: '#ff0000',
            fillOpacity: meta.statsMetricsType === 'owner_total_media_cost_local' ? '1' : '.5',
            strokeOpacity: meta.statsMetricsType === 'owner_total_media_cost_local' ? '1' : '.5',
            slimingFactor: 4,
        };
        this._plotBar(options_filteredTotalMediaCostLocal);

        // filtered revenue
        var options_filteredRevenue = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.filteredRevenue,
            el: d3.select(el).select('.data-points-revenue'),
            scales: this._scalesFilteredRevenue(data),
            colorFill: '#111',
            colorBorder: '#111',
            fillOpacity: meta.statsMetricsType === 'revenue' ? '1' : '.5',
            strokeOpacity: meta.statsMetricsType === 'revenue' ? '1' : '.5',
            slimingFactor: 5,
        };
        this._plotBar(options_filteredRevenue);

        // ctr line (filtered data)
        var options_ctr = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.ctr,
            el: d3.select(el).select('.data-line-ctr > path'),
            scales: this._scalesCtr(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#9C27B0',
            strokeOpacity: meta.statsMetricsType === 'ctr' ? '1' : '.3',
            strokeWidth: '8',
            strokeDashArray: [],
        };
        this._plotLine(options_ctr);

        // eCPM line (filtered data)
        var options_eCPM = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.eCPM,
            el: d3.select(el).select('.data-line-eCPM > path'),
            scales: this._scales_eCPM(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#9575CD',
            strokeOpacity: meta.statsMetricsType === 'ecpm' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_eCPM);

        // eCPC line (filtered data)
        var options_eCPC = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.eCPC,
            el: d3.select(el).select('.data-line-eCPC > path'),
            scales: this._scales_eCPC(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#F06292',
            strokeOpacity: meta.statsMetricsType === 'ecpc' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [],
        };
        this._plotLine(options_eCPC);

        // eRPM line (filtered data)
        var options_eRPM = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.eRPM,
            el: d3.select(el).select('.data-line-eRPM > path'),
            scales: this._scales_eRPM(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#9575CD',
            strokeOpacity: meta.statsMetricsType === 'erpm' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_eRPM);

        // eRPC line (filtered data)
        var options_eRPC = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.eRPC,
            el: d3.select(el).select('.data-line-eRPC > path'),
            scales: this._scales_eRPC(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#9575CD',
            strokeOpacity: meta.statsMetricsType === 'erpc' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_eRPC);

        // Daily uniq line (filtered data)
        var options_daily_uniq = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.daily_uniq,
            el: d3.select(el).select('.data-line-daily_uniq > path'),
            scales: this._scales_daily_uniq(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#ff0000',
            strokeOpacity: meta.statsMetricsType === 'daily_uniq' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_daily_uniq);

        // Daily uniq line (filtered data)
        var options_unique_users = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.unique_users,
            el: d3.select(el).select('.data-line-unique_users > path'),
            scales: this._scales_unique_users(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#ff0000',
            strokeOpacity: meta.statsMetricsType === 'unique_users' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_unique_users);

        // Media Cost line (filtered data)
        var options_owner_media_cost_2_local = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.owner_media_cost_2_local,
            el: d3.select(el).select('.data-line-owner_media_cost_2_local > path'),
            scales: this._scales_owner_media_cost_2_local(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#ff0000',
            strokeOpacity: meta.statsMetricsType === 'owner_media_cost_2_local' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_owner_media_cost_2_local);

        // VCR
        var options_vcr = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.vcr,
            el: d3.select(el).select('.data-line-vcr > path'),
            scales: this._scalesVCR(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#ff0000',
            strokeOpacity: meta.statsMetricsType === 'vcr' ? '1' : '.3',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_vcr);

        // total cost eCPCv line (filtered data)
        var options_owner_total_media_cost_local_ecpcv = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.owner_total_media_cost_local_ecpcv,
            el: d3.select(el).select('.data-line-owner_total_media_cost_local_ecpcv > path'),
            scales: this._scales_owner_total_media_cost_local_ecpcv(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#F06292',
            strokeOpacity:
                meta.statsMetricsType === 'owner_total_media_cost_local_ecpcv' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [],
        };
        this._plotLine(options_owner_total_media_cost_local_ecpcv);

        // revenue ecpcv line (filtered data)
        var options_revenue_ecpcv = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.revenue_ecpcv,
            el: d3.select(el).select('.data-line-revenue_ecpcv > path'),
            scales: this._scales_revenue_ecpcv(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#9575CD',
            strokeOpacity: meta.statsMetricsType === 'revenue_ecpcv' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_revenue_ecpcv);

        // Average Frequency line (filtered data)
        var options_average_freq = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.average_freq,
            el: d3.select(el).select('.data-line-average_freq > path'),
            scales: this._scales_average_freq(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#ff0000',
            strokeOpacity: meta.statsMetricsType === 'average_freq' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_average_freq);

        // Win Rate line (filtered data)
        var options_win_rate = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.win_rate,
            el: d3.select(el).select('.data-line-win_rate > path'),
            scales: this._scales_win_rate(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#ff0000',
            strokeOpacity: meta.statsMetricsType === 'win_rate' ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10, 10],
        };
        this._plotLine(options_win_rate);

        // Beacons
        meta.beacons.forEach(b => {
            const key = b.key;
            const index = b.index;
            const item = that._beacon.items[key];
            const options_beacon = {
                data: that._cache.data,
                dataIndex: index,
                el: that._selected.dataLines_beacon[key].select('path'),
                scales: that._beacon.items[key].scales,
                fillColor: 'none',
                fillOpacity: '1',
                strokeColor: '#9575CD',
                strokeOpacity: '1',
                strokeWidth: '8',
                strokeDashArray: [10, 10],
            };
            // this._plotLine_dev(options_beacon);
            this._plotLine(options_beacon);
        });

        // Mouse band
        var options_mouseBand = {
            data: that._cache.data,
            el: d3.select(el).select('.data-points-mouseBands'),
            scales: this._scale_abscissa(),
            meta: state.meta,
        };
        this._plotMouseBand(options_mouseBand);
    }
};

D3Chart.prototype.distroy = function(el) {
    if (this._selected) {
        this._events_unMount(el);
        this._selected.svg.select('g.wrap').remove();
    }
};

D3Chart.prototype.control = function(opts) {
    // rpt[0].control( { isShown: { impressions: true, clicks: true, spend: true, ctr: true, ecpm: true, ecpc: true } } )

    const isShown = {};
    const metricCatelogue_static = [
        'impressions',
        'clicks',
        'spend',
        'ctr',
        'ecpm',
        'ecpc',
        'revenue',
        'erpm',
        'erpc',
        'unique_users',
        'daily_uniq',
        'average_freq',
        'win_rate',
        'owner_total_media_cost_local',
        'owner_media_cost_2_local',
        'vcr',
        'owner_total_media_cost_local_ecpcv',
        'revenue_ecpcv',
    ];

    metricCatelogue_static.forEach(m => {
        isShown[m] = opts && opts.isShown && opts.isShown ? opts.isShown[m] : false;
        this._conf.isShown[m] = isShown[m];
    });

    const isShown_beacon = _.omit(opts.isShown, ...metricCatelogue_static);
    this._conf.isShown_beacon = isShown_beacon;

    this._selected.dataPoints_impressions.classed(v2(isShown, 'impressions'));
    this._selected.dataPoints_spend.classed(v2(isShown, 'spend'));

    this._selected.dataPoints_totalMediaCostLocal.classed(
        v2(isShown, 'owner_total_media_cost_local')
    );

    this._selected.dataPoints_revenue.classed(v2(isShown, 'revenue'));
    this._selected.dataPoints_clicks.classed(v2(isShown, 'clicks'));

    this._selected.dataLine_ctr.classed(v2(isShown, 'ctr'));
    this._selected.dataLine_eCPM.classed(v2(isShown, 'ecpm'));
    this._selected.dataLine_eCPC.classed(v2(isShown, 'ecpc'));
    this._selected.dataLine_eRPM.classed(v2(isShown, 'erpm'));
    this._selected.dataLine_eRPC.classed(v2(isShown, 'erpc'));
    this._selected.dataLine_daily_uniq.classed(v2(isShown, 'daily_uniq'));
    this._selected.dataLine_unique_users.classed(v2(isShown, 'unique_users'));
    this._selected.dataLine_owner_media_cost_2_local.classed(
        v2(isShown, 'owner_media_cost_2_local')
    );
    this._selected.dataPoints_vcr.classed(v2(isShown, 'vcr'));
    this._selected.dataLine_vcr.classed(v2(isShown, 'vcr'));
    this._selected.dataLine_owner_total_media_cost_local_ecpcv.classed(
        v2(isShown, 'owner_total_media_cost_local_ecpcv')
    );
    this._selected.dataLine_revenue_ecpcv.classed(v2(isShown, 'revenue_ecpcv'));

    this._selected.dataLine_average_freq.classed(v2(isShown, 'average_freq'));
    this._selected.dataLine_win_rate.classed(v2(isShown, 'win_rate'));

    _.each(this._selected.dataLines_beacon, (dataLine, k) => {
        dataLine.classed(v2(isShown_beacon, k));
    });

    function v2(visibilityDirective, _metric) {
        const isVisible = visibilityDirective[_metric];
        return {
            'isVisible-true': function() {
                return isVisible;
            },
            'isVisible-false': function() {
                return !isVisible;
            },
        };
    }
};

/////////////////////////////////
// setup and tear down evetns  //
/////////////////////////////////
D3Chart.prototype._events_mount = function(el) {
    const that = this,
        selected = this._selected;

    // selected.plotArea.on('mousemove', function(d){
    //     const p = d3.mouse(this);
    //     const x = p[0];
    //     // console.log('events mount mouseover, x: ', x) ;
    //     // selected.mouseLineGroup.attr('transform', 'translate('+x+','+0+')');
    // });

    // Area events
    selected.plotArea.on('mouseenter', function(e) {
        that._plot_mouseLine('mouseenter');
    });
    selected.plotArea.on('mouseleave', function(e) {
        that._plot_mouseLine('mouseleave');
    });

    // Window events
    window.addEventListener('scroll', this._onDocumentScroll.bind(this));
    window.addEventListener('resize', this._onWindowResize.bind(this));
};

D3Chart.prototype._events_unMount = function(el) {
    const that = this,
        selected = this._selected;

    selected.plotArea.on('mouseenter', null);
    selected.plotArea.on('mouseleave', null);

    window.removeEventListener('scroll', this._onDocumentScroll.bind(this));
    window.removeEventListener('resize', this._onWindowResize.bind(this));

    // unbind event on mounse bands
    this._selected.mouseBands.selectAll('.data-point').on('mouseenter', null);
    this._selected.mouseBands.selectAll('.data-point').on('mouseleave', null);
};

//////////////////////////
// misc. helper        //
//////////////////////////
D3Chart.prototype._onDocumentScroll = function(el) {
    this._updateToolTipPositionInClient();
};

D3Chart.prototype._onWindowResize = function(el) {
    this._updateToolTipPositionInClient();
};

D3Chart.prototype._updateClassForStatsMetricsType = function(el, state) {
    var that = this;
    var data = state.data;
    var meta = state.meta;

    this._cache.statsMetricsType = meta.statsMetricsType;

    d3.select(el)
        .select('.data-points-impressions')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'impressions' ? true : false;
        });

    d3.select(el)
        .select('.data-points-clicks')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'clicks' ? true : false;
        });

    d3.select(el)
        .select('.data-line-ctr')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'ctr' ? true : false;
        });

    d3.select(el)
        .select('.data-points-spend')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'spend' ? true : false;
        });

    d3.select(el)
        .select('.data-line-eCPM')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'ecpm' ? true : false;
        });

    d3.select(el)
        .select('.data-line-eCPC')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'ecpc' ? true : false;
        });

    d3.select(el)
        .select('.data-points-revenue')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'revenue' ? true : false;
        });

    d3.select(el)
        .select('.data-line-eRPM')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'erpm' ? true : false;
        });

    d3.select(el)
        .select('.data-line-eRPC')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'erpc' ? true : false;
        });

    d3.select(el)
        .select('.data-line-daily_uniq')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'daily_uniq' ? true : false;
        });

    d3.select(el)
        .select('.data-line-unique_users')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'unique_users' ? true : false;
        });

    d3.select(el)
        .select('.data-line-owner_media_cost_2_local')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'owner_media_cost_2_local' ? true : false;
        });

    d3.select(el)
        .select('.data-line-average_freq')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'average_freq' ? true : false;
        });

    d3.select(el)
        .select('.data-line-win_rate')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'win_rate' ? true : false;
        });

    d3.select(el)
        .select('.data-points--total-media-cost-local')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'owner_total_media_cost_local' ? true : false;
        });

    d3.select(el)
        .select('.data-points-vcr')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'vcr' ? true : false;
        });

    d3.select(el)
        .select('.data-line-vcr')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'vcr' ? true : false;
        });

    d3.select(el)
        .select('.data-line-owner_total_media_cost_local_ecpcv')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'owner_total_media_cost_local_ecpcv' ? true : false;
        });

    d3.select(el)
        .select('.data-line-revenue_ecpcv')
        .classed('isActive', () => {
            return meta.statsMetricsType === 'revenue_ecpcv' ? true : false;
        });

    _.each(that._selected.dataLines_beacon, (beaconLine, k) => {
        beaconLine.classed('isActive', () => {
            return meta.statsMetricsType === k ? true : false;
        });
    });
};

D3Chart.prototype._plot_mouseLine = function(mode) {
    const that = this,
        selected = this._selected;
    const strokeColor = 'red';

    const mouseLine = selected.mouseLineGroup.select('line');

    if (mode === 'mouseenter') {
        mouseLine.attr('stroke', strokeColor);
    }

    if (mode === 'mouseleave') {
        mouseLine.attr('stroke', 'none');
    }
};

/////////////////////////
//  plotting data      //
/////////////////////////
D3Chart.prototype._plotBar = function(opts) {
    var that = this;

    var data = opts.data;
    var dataIndex = opts.dataIndex;
    var colorBorder = opts.colorBorder;
    var colorFill = opts.colorFill;
    var fillOpacity = opts.fillOpacity;
    var strokeOpacity = opts.strokeOpacity;
    var el = opts.el;
    var scales = opts.scales;
    var slimingFactor = opts.slimingFactor;

    const slimingCoefficient = {
        '1': 1,
        '2': 7 / 9,
        '3': 5 / 9,
        '4': 3 / 9,
        '5': 1 / 9,
    };

    // var halfBandWidth = parseInt(this._cache.deltaWidth_per_dataPoint/2);
    // var halfBandWidth = this._cache.deltaWidth_per_dataPoint/2;
    var BandWidth = this._cache.deltaWidth_per_dataPoint;
    var spaceBtwBar = BandWidth * 0.15;
    var barWidth = (BandWidth - spaceBtwBar) * slimingCoefficient[slimingFactor];

    var points = el;

    var point = points
        .selectAll('g')
        .classed('data-point', true)
        .data(data, function(d) {
            return d.id;
        });

    point
        .enter()
        .append('g')
        .classed('data-point', true);

    var dataContainer = point.attr('transform', function(d) {
        var dataDate = d.date.value;
        var dataX = scales.x(dataDate);
        var x = dataX;
        return 'translate(' + x + ',' + 0 + ')';
    });

    const hasNoRect = dataContainer.select('rect').empty();

    if (hasNoRect) {
        dataContainer
            .append('rect')
            // .attr( 'fill', colorFill)             // done in css
            // .attr( 'fill-opacity', fillOpacity)   // done in css
            // .attr( 'stroke', colorBorder)
            // .attr( 'stroke-opacity', strokeOpacity)
            // .attr( 'stroke-width', '4')
            .attr('width', barWidth)
            .attr('x', (-1 * barWidth) / 2)
            .attr('y', '0')
            .attr('transform', function(d, i) {
                const dataY = 0;
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            .attr('height', 0)
            .transition()
            .duration(750)
            .attr('transform', function(d, i) {
                const dataY = scales.y(d.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            .attr('height', function(d) {
                var o = scales.y(d.y[dataIndex].value);
                return o;
            });
    } else {
        dataContainer
            .selectAll('rect')
            .transition()
            .duration(750)
            .attr('transform', function(d, i) {
                const dataY = scales.y(d.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            .attr('height', function(d) {
                var o = scales.y(d.y[dataIndex].value);
                return o;
            });
    }

    // dataContainer.append('circle')
    //     .attr( 'r', 20 )
    //     .attr( 'x', '0' )
    //     .attr( 'y', '0')
    //     .attr( 'transform', function(d,i){
    //         const dataY = scales.y(d.y[dataIndex].value);
    //         const y = that._conf.height - dataY;
    //         return 'translate('+0+','+y+')';
    //     })
    //     .attr( 'stroke', 'red')
    //     .attr( 'stroke-width', '2')
    //     .attr( 'fill', 'transparent')
    //     .attr( 'class', 'origin' );

    point.exit().remove();
};

D3Chart.prototype._plotLine = function(opts) {
    var that = this;
    var data = opts.data;
    var dataIndex = opts.dataIndex;
    var el = opts.el;
    var fillColor = opts.fillColor;
    var fillOpacity = opts.fillOpacity;
    var strokeColor = opts.strokeColor;
    var strokeOpacity = opts.strokeOpacity;
    var strokeWidth = opts.strokeWidth;
    var strokeDashArray = opts.strokeDashArray;

    var scales = opts.scales;

    // draw line
    var valueline = d3.svg
        .line()
        .x(function(d) {
            var dataDate = d.date.value;
            var dataX = parseInt(scales.x(dataDate));
            return dataX;
        })
        .y(function(d) {
            var dataY = that._conf.height - scales.y(d.y[dataIndex].value);
            return dataY;
        })
        .interpolate('monotone');
    // .interpolate("cardinal");
    // .interpolate('basis');

    var path = el
        // .attr('fill', fillColor)
        // .attr('fill-opacity', fillOpacity)
        // .attr('stroke', strokeColor)
        // .attr('stroke-opacity', strokeOpacity)
        // .attr('stroke-width', strokeWidth)
        // .attr('stroke-dasharray', strokeDashArray)
        .transition()
        .duration(700)
        .attr('d', valueline(data));
};

D3Chart.prototype._plotLine_dev = function(opts) {
    var that = this;
    var data = opts.data;
    var dataIndex = opts.dataIndex;
    var el = opts.el;
    var fillColor = opts.fillColor;
    var fillOpacity = opts.fillOpacity;
    var strokeColor = opts.strokeColor;
    var strokeOpacity = opts.strokeOpacity;
    var strokeWidth = opts.strokeWidth;
    var strokeDashArray = opts.strokeDashArray;

    var scales = opts.scales;

    // draw line
    var valueline = d3.svg
        .line()
        .x(function(d) {
            var dataDate = d.date.value;
            var dataX = parseInt(scales.x(dataDate));
            return dataX;
        })
        .y(function(d) {
            var dataY = that._conf.height - scales.y(d.y[dataIndex].value);
            return dataY;
        })
        .interpolate('monotone');
    // .interpolate("cardinal");
    // .interpolate('basis');

    var path = el
        .attr('fill', fillColor)
        .attr('fill-opacity', fillOpacity)
        .attr('stroke', strokeColor)
        .attr('stroke-opacity', strokeOpacity)
        .attr('stroke-width', strokeWidth)
        .attr('stroke-dasharray', strokeDashArray)
        .transition()
        .duration(700)
        .attr('d', valueline(data));
};

D3Chart.prototype._plotScatterPoints = function(opts) {
    var that = this;

    var data = opts.data;
    var dataIndex = opts.dataIndex;
    var color = opts.color;
    var el = opts.el;
    var scales = opts.scales;

    var points = el;

    var point = points
        .selectAll('g')
        .classed('data-point', true)
        .data(data, function(d) {
            return d.id;
        });

    point
        .enter()
        .append('g')
        .classed('data-point', true);

    var dataContainer = point.attr('transform', function(d) {
        var dataDate = d.date.value;
        var dataX = parseInt(scales.x(dataDate));
        var dataY = parseInt(scales.y(d.y[dataIndex].value));
        var x = dataX;
        var y = that._conf.height - dataY;
        return 'translate(' + x + ',' + y + ')';
    });

    dataContainer
        .append('circle')
        .attr('class', 'origin')
        .attr('r', 20)
        .attr('stroke', color)
        .attr('stroke-width', '2')
        .attr('fill', 'none');

    point.exit().remove();
};

D3Chart.prototype._plotMouseBand = function(opts) {
    const that = this,
        selected = this._selected,
        conf = this._conf;

    const beaconMeta = this._beacon.beaconMeta;
    const beaconItems = this._beacon.items;

    var data = opts.data;
    var meta = opts.meta;

    var points = opts.el;
    var scales = opts.scales;

    var bandWidth = this._cache.deltaWidth_per_dataPoint;

    var point = points
        .selectAll('g')
        .classed('data-point', true)
        .data(data, function(d) {
            return d.id;
        });

    point
        .enter()
        .append('g')
        .classed('data-point', true);
    const point_exit = point.exit();
    point_exit.remove();

    var dataContainer = point.attr('transform', function(d) {
        var dataDate = d.date.value;
        var dataX = scales(dataDate);
        var x = dataX;
        return 'translate(' + x + ',' + 0 + ')';
    });

    const hasNoRect = dataContainer.select('rect').empty();
    if (hasNoRect) {
        dataContainer
            .append('rect')
            .attr('x', (-1 * bandWidth) / 2)
            .attr('y', '0')
            .attr('width', bandWidth)
            .attr('height', that._conf.height)
            // Debug
            // .attr( 'fill', '#aa0044')
            // .attr( 'fill-opacity', 0.2)
            // .attr( 'stroke', 'black')
            // .attr( 'stroke-opacity', 0.5)
            // .attr( 'stroke-width', '2')
            .attr('fill', 'transparent')
            .attr('fill-opacity', 1)
            .attr('stroke', 'none')
            .attr('stroke-opacity', 1);
    }

    // Start :: Events
    // mount event
    dataContainer.on('mouseenter', cb_onMouseenter);
    dataContainer.on('mouseleave', cb_onMouseleave);
    // unmount event on exit
    point_exit.on('mouseenter', null);
    point_exit.on('mouseleave', null);

    this._cache.mouseLineX;

    function visibility(_metric) {
        const isVisible = conf.isShown[_metric];
        return {
            'isVisible-true': function() {
                return isVisible;
            },
            'isVisible-false': function() {
                return !isVisible;
            },
        };
    }

    function cb_onMouseenter(d, i) {
        const dataDate = d.date.value;
        const x = scales(dataDate);
        that._cache.mouseLineX = x;

        selected.mouseLineGroup.attr('transform', 'translate(' + x + ',' + 0 + ')');
        that._updateToolTips.call(that, d);
        that._updateToolTipPositionInClient();

        const r = 10; //(bandWidth/3)/2;
        let dataIndex;
        let scalesMetric;

        // Filtered impression
        dataIndex = conf.dataIndex.filteredImpressions;
        scalesMetric = that._scalesFilteredImpressions();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-filteredImpressions')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'red')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'impressions' ? true : false;
            })
            .classed(visibility('impressions'));

        // Filtered Clicks
        dataIndex = conf.dataIndex.filteredClicks;
        scalesMetric = that._scalesFilteredClicks();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-filteredClicks')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'green')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'clicks' ? true : false;
            })
            .classed(visibility('clicks'));

        // Ctr
        dataIndex = conf.dataIndex.ctr;
        scalesMetric = that._scalesCtr();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-filteredCtr')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'blue')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'ctr' ? true : false;
            })
            .classed(visibility('ctr'));

        // Filter Spend
        dataIndex = conf.dataIndex.filteredSpend;
        scalesMetric = that._scalesFilteredSpend();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-filteredSpend')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'spend' ? true : false;
            })
            .classed(visibility('spend'));

        // filtered Total Cost
        dataIndex = conf.dataIndex.total_media_cost_local;
        scalesMetric = that._scalesTotalMediaCostLocal();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter--total-media-cost-local')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'owner_total_media_cost_local' ? true : false;
            })
            .classed(visibility('owner_total_media_cost_local'));
        dataIndex = conf.dataIndex.filteredSpend;
        scalesMetric = that._scalesFilteredSpend();

        // eCPM
        dataIndex = conf.dataIndex.eCPM;
        scalesMetric = that._scales_eCPM();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-eCPM')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'magenta')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'ecpm' ? true : false;
            })
            .classed(visibility('ecpm'));

        // eCPC
        dataIndex = conf.dataIndex.eCPC;
        scalesMetric = that._scales_eCPC();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-eCPC')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'cyan')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'ecpc' ? true : false;
            })
            .classed(visibility('ecpc'));

        // Filter revenue
        dataIndex = conf.dataIndex.filteredRevenue;
        scalesMetric = that._scalesFilteredRevenue();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-filteredRevenue')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'revenue' ? true : false;
            })
            .classed(visibility('revenue'));

        // eRPM
        dataIndex = conf.dataIndex.eRPM;
        scalesMetric = that._scales_eRPM();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-eRPM')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'erpm' ? true : false;
            })
            .classed(visibility('erpm'));

        // eRPC
        dataIndex = conf.dataIndex.eRPC;
        scalesMetric = that._scales_eRPC();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-eRPC')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'erpc' ? true : false;
            })
            .classed(visibility('erpc'));

        // daily uniq
        dataIndex = conf.dataIndex.daily_uniq;
        scalesMetric = that._scales_daily_uniq();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-daily_uniq')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'daily_uniq' ? true : false;
            })
            .classed(visibility('daily_uniq'));

        // unique users
        dataIndex = conf.dataIndex.unique_users;
        scalesMetric = that._scales_unique_users();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-unique_users')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'unique_users' ? true : false;
            })
            .classed(visibility('unique_users'));

        // Media cost
        dataIndex = conf.dataIndex.owner_media_cost_2_local;
        scalesMetric = that._scales_owner_media_cost_2_local();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-owner_media_cost_2_local')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'owner_media_cost_2_local' ? true : false;
            })
            .classed(visibility('owner_media_cost_2_local'));

        // VCR
        dataIndex = conf.dataIndex.ctr;
        scalesMetric = that._scalesCtr();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-vcr')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            .classed('isActive', () => {
                return meta.statsMetricsType === 'vcr' ? true : false;
            })
            .classed(visibility('vcr'));

        // owner_total_media_cost_local_ecpcv
        dataIndex = conf.dataIndex.owner_total_media_cost_local_ecpcv;
        scalesMetric = that._scales_owner_total_media_cost_local_ecpcv();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-owner_total_media_cost_local_ecpcv')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            .classed('isActive', () => {
                return meta.statsMetricsType === 'owner_total_media_cost_local_ecpcv'
                    ? true
                    : false;
            })
            .classed(visibility('owner_total_media_cost_local_ecpcv'));

        // revenue_ecpcv
        dataIndex = conf.dataIndex.revenue_ecpcv;
        scalesMetric = that._scales_revenue_ecpcv();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-revenue_ecpcv')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            .classed('isActive', () => {
                return meta.statsMetricsType === 'revenue_ecpcv' ? true : false;
            })
            .classed(visibility('revenue_ecpcv'));

        // average freq
        dataIndex = conf.dataIndex.average_freq;
        scalesMetric = that._scales_average_freq();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-average_freq')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'average_freq' ? true : false;
            })
            .classed(visibility('average_freq'));

        // win rate
        dataIndex = conf.dataIndex.win_rate;
        scalesMetric = that._scales_win_rate();

        d3.select(this)
            .append('circle')
            .attr('class', 'mouseScatter-win_rate')
            .attr('r', r)
            .attr('x', '0')
            .attr('y', '0')
            .attr('transform', function(dd, ii) {
                const dataY = scalesMetric.y(dd.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate(' + 0 + ',' + y + ')';
            })
            // .attr( 'stroke', 'yellow')
            // .attr( 'stroke-width', '4')
            // .attr( 'fill', 'transparent')
            .classed('isActive', () => {
                return meta.statsMetricsType === 'win_rate' ? true : false;
            })
            .classed(visibility('win_rate'));

        // Beacons
        function visibilityBeacon(_metric) {
            const isVisible = conf.isShown_beacon[_metric];
            return {
                'isVisible-true': function() {
                    return isVisible;
                },
                'isVisible-false': function() {
                    return !isVisible;
                },
            };
        }

        beaconMeta.forEach((v, k) => {
            const dataIndex = v.index;
            const key = v.key;
            const scalesMetric = beaconItems[key].scales;

            const label = v.label;
            const style = v.style;
            d3.select(this)
                .append('circle')
                .classed('mouseScatter-beacon', true)
                .classed(style, true)
                .attr('r', r)
                .attr('x', '0')
                .attr('y', '0')
                .attr('transform', function(dd, ii) {
                    const dataY = scalesMetric.y(dd.y[dataIndex].value);
                    const y = that._conf.height - dataY;
                    return 'translate(' + 0 + ',' + y + ')';
                })
                // .classed( 'isActive', true)
                .classed('isActive', () => {
                    return meta.statsMetricsType === key ? true : false;
                })
                // .attr( 'stroke', 'red')
                // .attr( 'stroke-width', '4')
                // .attr( 'fill', 'transparent')
                // .classed( 'isVisible-true', true );
                .classed(visibilityBeacon(key));
        });
    } // End :: cb_onMouseenter

    function cb_onMouseleave(d, i) {
        d3.select(this)
            .selectAll('circle')
            .remove();
    } // End :: cb_onMouseleave

    // End   :: Events
};

/////////////////////////
//  axises             //
/////////////////////////
D3Chart.prototype._updateXAxis = function(el) {
    var that = this;
    var startDate = this._cache.dateStart;
    var endDate = this._cache.dateEnd;

    // For testing
    // startDate = new Date("01-Jun-15");
    // endDate = new Date("04-Jun-15");

    // parameter for you to tweek
    var minorTickIntervalInHour = 12;
    var numberOfTicksWDate = 15; // this is an approximate number

    // setup scale for axis
    var xAxisMargin = parseInt(this._cache.deltaWidth_per_dataPoint / 2);

    var dS = startDate;
    var dE = endDate;
    var rS = 0 + xAxisMargin;
    var rE = this._cache.plotAreaWidth - xAxisMargin;
    var axisScale = d3.time
        .scale()
        .domain([dS, dE])
        .range([rS, rE]);

    // calculate details
    var M_startDate = moment(startDate);
    var M_endDate = moment(endDate);
    var howManyDays_btw_startEndDate = M_endDate.diff(M_startDate, 'days') + 1; // include end point
    var n = howManyDays_btw_startEndDate; // put it into shorter variable name

    // adjustment for edge cases
    var numOfDataPointsToSkipLabel = n <= numberOfTicksWDate ? 0 : parseInt(n / numberOfTicksWDate);
    var numberOfTicks = n === 1 ? n : n - 1; // a hack to take care of case when n is unity

    // major ticks
    var xAxis = d3.svg
        .axis()
        .scale(axisScale)
        .orient('bottom')
        .ticks(numberOfTicks)
        .tickSize(30, 0) // this ticksize also govern default label distance to axis
        .tickFormat(function(d, index) {
            var mod = index % (numOfDataPointsToSkipLabel + 1);
            var out = mod === 0 ? d3.time.format('%b %d')(d) : undefined;
            return out;
        });

    var xAxis_g = d3
        .select(el)
        .select('g.x.axis')
        .call(xAxis);

    // minor ticks
    xAxis_g
        .selectAll('line')
        .data(axisScale.ticks(d3.time.hour, minorTickIntervalInHour), function(d) {
            // console.log('minor tick loop: ', arguments)
            return d;
        })
        .enter()

        .append('line')
        .attr('class', 'minor')
        .attr('y1', 0)
        .attr('y2', 3)
        .attr('x1', function(d) {
            // console.log('x1: ', d);
            return axisScale(d);
        })
        .attr('x2', function(d) {
            // console.log('x2: ', d);
            return axisScale(d);
        });

    // major ticks with label
    xAxis_g
        .selectAll('.tick line')
        .classed('tick-w-label', function(d, index) {
            var mod = index % (numOfDataPointsToSkipLabel + 1);
            if (mod === 0) {
                return d;
            }
        })
        .attr('y2', function(d, index) {
            // console.log('attr for y2: ', d)
            var mod = index % (numOfDataPointsToSkipLabel + 1);
            if (mod !== 0) {
                return 0;
            } else {
                return 20;
            }
        });
};

D3Chart.prototype._updateYAxis = function(el) {
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS = maxDataY + this._cache.headRoom;
    var dE = 0 - this._cache.floorOffset;

    var rS = 0;
    var rE = this._conf.height;

    var AxisScale = d3.scale
        .linear()
        .domain([dS, dE])
        .range([rS, rE]);

    var yAxis = d3.svg
        .axis()
        .scale(AxisScale)
        .orient('left')
        //.ticks(this._cache.dataResolutionOnYAxis)
        .tickSize(10);

    d3.select(el)
        .select('g.y.axis')
        .call(yAxis);
};

D3Chart.prototype._prepareXAxisContainer = function(el) {
    var axisLocationY = this._conf.height;
    d3.select(el)
        .select('g.plot-area')
        .append('g') // Add the X Axis
        .attr('class', 'x axis')
        .attr('transform', 'translate(0, ' + axisLocationY + ')');
};

D3Chart.prototype._prepareYAxisContainer = function(el) {
    var axisLocationY = 0;
    var axisLocationX = 0;
    d3.select(el)
        .select('g.plot-area')
        .append('g')
        .attr('class', 'y axis')
        .attr('transform', 'translate(' + axisLocationX + ', ' + axisLocationY + ')');
};

/////////////////////////
//  Scales             //
/////////////////////////
D3Chart.prototype._scalesFilteredImpressions = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scalesFilteredClicks = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scalesFilteredSpend = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scalesTotalMediaCostLocal = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scalesFilteredRevenue = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scalesCtr = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_ctr_max;
    var minDataY = this._cache.data_ctr_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_eCPM = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_eCPM_max;
    var minDataY = this._cache.data_eCPM_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_eCPC = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_eCPC_max;
    var minDataY = this._cache.data_eCPC_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_eRPM = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_eRPM_max;
    var minDataY = this._cache.data_eRPM_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_eRPC = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_eRPC_max;
    var minDataY = this._cache.data_eRPC_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_daily_uniq = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_daily_uniq_max;
    var minDataY = this._cache.data_daily_uniq_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_unique_users = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_unique_users_max;
    var minDataY = this._cache.data_unique_users_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_owner_media_cost_2_local = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_owner_media_cost_2_local_max;
    var minDataY = this._cache.data_owner_media_cost_2_local_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scalesVCR = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_vcr_max;
    var minDataY = this._cache.data_vcr_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_owner_total_media_cost_local_ecpcv = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_owner_total_media_cost_local_ecpcv_max;
    var minDataY = this._cache.data_owner_total_media_cost_local_ecpcv_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_revenue_ecpcv = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_revenue_ecpcv_max;
    var minDataY = this._cache.data_revenue_ecpcv_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_average_freq = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_average_freq_max;
    var minDataY = this._cache.data_average_freq_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scales_win_rate = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_win_rate_max;
    var minDataY = this._cache.data_win_rate_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};
D3Chart.prototype._scales_beacon = function(data, extent) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = extent[1];
    var minDataY = extent[0];

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height / 3;
    var y = d3.scale
        .linear()
        .domain([dS_y, dE_y])
        .range([rS_y, rE_y]);

    return { x: x, y: y };
};

D3Chart.prototype._scale_abscissa = function() {
    var that = this;

    var maxDataX = this._cache.dateEnd;
    var minDataX = this._cache.dateStart;

    var pointDistance = this._cache.deltaWidth_per_dataPoint;
    var startRangeX = 0 + pointDistance / 2;
    var endRangeX = this._cache.plotAreaWidth - pointDistance / 2;

    var x = d3.time
        .scale()
        .domain([minDataX, maxDataX])
        .range([startRangeX, endRangeX]);

    return x;
};

///////////////////////////
// Tool Tips             //
///////////////////////////
D3Chart.prototype._updateToolTips = function(d) {
    const conf = this._conf;
    const data = d.y;
    const dataIndex = this._conf.dataIndex;
    const presentation_date =
        moment(d.date.value).format('MMM DD, YYYY') + ' ' + this._conf.timezone;

    const filtered_impressions = data[dataIndex.filteredImpressions].rawValue;
    const filtered_clicks = data[dataIndex.filteredClicks].rawValue;
    const ctr = data[dataIndex.ctr].rawValue;
    const filtered_spend = data[dataIndex.filteredSpend].rawValue;
    const owner_total_media_cost_local = data[dataIndex.total_media_cost_local].rawValue;
    const eCPM = data[dataIndex.eCPM].rawValue;
    const eCPC = data[dataIndex.eCPC].rawValue;
    const filtered_revenue = data[dataIndex.filteredRevenue].rawValue;
    const eRPM = data[dataIndex.eRPM].rawValue;
    const eRPC = data[dataIndex.eRPC].rawValue;
    const daily_uniq = data[dataIndex.daily_uniq].rawValue;
    const unique_users = data[dataIndex.unique_users].rawValue;
    const owner_media_cost_2_local = data[dataIndex.owner_media_cost_2_local].rawValue;
    const vcr = data[dataIndex.vcr].rawValue;
    const owner_total_media_cost_local_ecpcv =
        data[dataIndex.owner_total_media_cost_local_ecpcv].rawValue;
    const revenue_ecpcv = data[dataIndex.revenue_ecpcv].rawValue;
    const average_freq = data[dataIndex.average_freq].rawValue;
    const win_rate = data[dataIndex.win_rate].rawValue;

    const presentation_filtered_impressions = numeral(filtered_impressions).format('0,0');
    const presentation_filtered_clicks = numeral(filtered_clicks).format('0,0');
    const presentation_ctr = numeral(ctr * 100).format('0,0.00') + '%';
    const presentation_filtered_spend = '$' + numeral(filtered_spend).format('0,0.00');
    const presentation_owner_total_media_cost_local =
        '$' + numeral(owner_total_media_cost_local).format('0,0.00');
    const presentation_eCPM = '$' + numeral(eCPM).format('0,0.00');
    const presentation_eCPC = '$' + numeral(eCPC).format('0,0.00');
    const presentation_filtered_revenue = '$' + numeral(filtered_revenue).format('0,0.00');
    const presentation_eRPM = '$' + numeral(eRPM).format('0,0.00');
    const presentation_eRPC = '$' + numeral(eRPC).format('0,0.00');
    const presentation_daily_uniq = numeral(daily_uniq).format('0,0');
    const presentation_unique_users = numeral(unique_users).format('0,0');
    const presentation_owner_media_cost_2_local = numeral(owner_media_cost_2_local).format('0,0');
    const presentation_vcr = numeral(vcr * 100).format('0,0.00') + '%';
    const presentation_owner_total_media_cost_local_ecpcv =
        '$' + numeral(owner_total_media_cost_local_ecpcv).format('0,0.00');
    const presentation_revenue_ecpcv = '$' + numeral(revenue_ecpcv).format('0,0.00');
    const presentation_average_freq = numeral(average_freq).format('0,0.00');
    const presentation_win_rate = numeral(win_rate * 100).format('0,0.00') + '%';

    const selected = this._selected;
    const tooltip = d3.select(selected.component).select('.am-reports-toolTip');

    const isShown = {};
    isShown.impressions = conf.isShown.impressions ? 'isVisible-true' : 'isVisible-false';
    isShown.clicks = conf.isShown.clicks ? 'isVisible-true' : 'isVisible-false';
    isShown.ctr = conf.isShown.ctr ? 'isVisible-true' : 'isVisible-false';
    isShown.spend = conf.isShown.spend ? 'isVisible-true' : 'isVisible-false';
    isShown.owner_total_media_cost_local = conf.isShown.owner_total_media_cost_local
        ? 'isVisible-true'
        : 'isVisible-false';

    isShown.ecpm = conf.isShown.ecpm ? 'isVisible-true' : 'isVisible-false';
    isShown.ecpc = conf.isShown.ecpc ? 'isVisible-true' : 'isVisible-false';

    isShown.revenue = conf.isShown.revenue ? 'isVisible-true' : 'isVisible-false';
    isShown.erpm = conf.isShown.erpm ? 'isVisible-true' : 'isVisible-false';
    isShown.erpc = conf.isShown.erpc ? 'isVisible-true' : 'isVisible-false';
    isShown.daily_uniq = conf.isShown.daily_uniq ? 'isVisible-true' : 'isVisible-false';
    isShown.unique_users = conf.isShown.unique_users ? 'isVisible-true' : 'isVisible-false';
    isShown.owner_media_cost_2_local = conf.isShown.owner_media_cost_2_local
        ? 'isVisible-true'
        : 'isVisible-false';
    isShown.vcr = conf.isShown.vcr ? 'isVisible-true' : 'isVisible-false';
    isShown.owner_total_media_cost_local_ecpcv = conf.isShown.owner_total_media_cost_local_ecpcv
        ? 'isVisible-true'
        : 'isVisible-false';
    isShown.revenue_ecpcv = conf.isShown.revenue_ecpcv ? 'isVisible-true' : 'isVisible-false';
    isShown.average_freq = conf.isShown.average_freq ? 'isVisible-true' : 'isVisible-false';
    isShown.win_rate = conf.isShown.win_rate ? 'isVisible-true' : 'isVisible-false';

    const svgRect = '<rect x="3" y="0" width="10" height="10" ></rect>';
    const svgThinRect = '<rect x="3" y="3" width="10" height="3" ></rect>';
    const svgDash =
        '<line x1="3" y1="5" x2="13" y2="5" style="stroke:rgb(255,0,0);stroke-width:2" />';
    const svgEllipsis = `<g>
        <circle r="1.5" cx="4"  cy="5" ></circle>
        <circle r="1.5" cx="8"  cy="5" ></circle>
        <circle r="1.5" cx="12" cy="5" ></circle>
    </g>`;

    const isShown_beacon = this._conf.isShown_beacon;
    const beaconMeta = this._beacon.beaconMeta;
    const beaconRows = beaconMeta.map((v, k) => {
        const index = v.index;
        const key = v.key;
        const label = v.label;
        const style = v.style;
        const styleId = style.replace(/beacon(\d+)/, '$1');
        const presentation_value = data[index].rawValue;
        // const isShown = 'isVisible-true';
        const isShown = isShown_beacon[v.key] ? 'isVisible-true' : 'isVisible-false';
        const legend = styleId % 2 ? svgThinRect : svgEllipsis;
        const className = v.style;
        const o = `<tr class="${isShown} ${className}"><td><svg>${legend}</svg></td><td>${label}:</td><td>${presentation_value}</td> </tr>`;
        return o;
    });
    const beacons = beaconRows.join('');

    let billingsLabel = 'Billings';
    let billingsECPMLabel = 'Billings eCPM';
    let billingsECPCLabel = 'Billings eCPC';

    if (this.isClient) {
        billingsLabel = 'Spend';
        billingsECPMLabel = 'Spend eCPM';
        billingsECPCLabel = 'Spend eCPC';
    } else {
        billingsLabel = 'Revenue';
        billingsECPMLabel = 'Revenue eCPM';
        billingsECPCLabel = 'Revenue eCPC';
    }

    const html = `
    <div class="am-reports-toolTip-inner">
        <div class="am-reports-toolTip-title">${presentation_date}</div>
        <table>
            <tr class="${
                isShown.impressions
            } filtered-impressions"><td><svg>${svgRect}    </svg></td><td>Impressions: </td><td>${presentation_filtered_impressions}</td> </tr>
            <tr class="${
                isShown.clicks
            } filtered-clicks"          ><td><svg>${svgRect}    </svg></td><td>Clicks:      </td><td>${presentation_filtered_clicks}     </td> </tr>
            <tr class="${
                isShown.ctr
            } ctr"                         ><td><svg>${svgThinRect}</svg></td><td>CTR:         </td><td>${presentation_ctr}                 </td> </tr>
            <tr class="${
                isShown.vcr
            } vcr"                         ><td><svg>${svgRect}</svg></td><td>VCR:         </td><td>${presentation_vcr}                 </td> </tr>
            <tr class="${
                isShown.unique_users
            } unique_users"           ><td><svg>${svgThinRect}</svg></td><td>Unique Users:</td><td>${presentation_unique_users}          </td> </tr>
            <tr class="${
                isShown.owner_media_cost_2_local
            } owner_media_cost_2_local"     ><td><svg>${svgThinRect}</svg></td><td>Media Cost:</td><td>${presentation_owner_media_cost_2_local}          </td> </tr>
            <tr class="${
                isShown.daily_uniq
            } daily_uniq"           ><td><svg>${svgThinRect}</svg></td><td>Daily Unique Users:</td><td>${presentation_daily_uniq}          </td> </tr>
            <tr class="${
                isShown.average_freq
            } average_freq"       ><td><svg>${svgThinRect}</svg></td><td>Avg Frequency:</td><td>${presentation_average_freq}          </td> </tr>
            <tr class="${
                isShown.win_rate
            } win_rate"       ><td><svg>${svgThinRect}</svg></td><td>Win Rate:</td><td>${presentation_win_rate}          </td> </tr>

            <tr class="${
                isShown.spend
            } filtered-spend"            ><td><svg>${svgRect}    </svg></td><td>{'Billable Cost'}:       </td><td>${presentation_filtered_spend}      </td> </tr>
            <tr class="${
                isShown.ecpm
            } eCPM"                       ><td><svg>${svgEllipsis}</svg></td><td>{'Billable Cost eCPM'}:  </td><td>${presentation_eCPM}                </td> </tr>
            <tr class="${
                isShown.ecpc
            } eCPC"                       ><td><svg>${svgThinRect}</svg></td><td>{'Billable Cost eCPC'}:  </td><td>${presentation_eCPC}                </td> </tr>
            
            <tr class="${
                isShown.owner_total_media_cost_local_ecpcv
            } owner_total_media_cost_local_ecpcv"            ><td><svg>${svgRect}    </svg></td><td>Total Cost eCPCV:       </td><td>${presentation_owner_total_media_cost_local_ecpcv}      </td> </tr>

            <tr class="${
                isShown.owner_total_media_cost_local
            } total-media-cost-local"><td><svg>${svgRect}    </svg></td><td>Total Cost: </td><td>${presentation_owner_total_media_cost_local}                </td> </tr>

            <tr class="${
                isShown.revenue
            } filtered-revenue"        ><td><svg>${svgRect}    </svg></td><td>${billingsLabel}:     </td><td>${presentation_filtered_revenue}    </td> </tr>
            <tr class="${
                isShown.erpm
            } eRPM"                       ><td><svg>${svgEllipsis}</svg></td><td>${billingsECPMLabel}:        </td><td>${presentation_eRPM}                </td> </tr>
            <tr class="${
                isShown.erpc
            } eRPC"                       ><td><svg>${svgThinRect}</svg></td><td>${billingsECPCLabel}:        </td><td>${presentation_eRPC}                </td> </tr>
            <tr class="${
                isShown.revenue_ecpcv
            } revenue_ecpcv"            ><td><svg>${svgRect}    </svg></td><td>Revenue eCPCV:       </td><td>${presentation_revenue_ecpcv}      </td> </tr>
            ${beacons}
        </table>
    </div>
    `;
    // owner_total_media_cost_local_ecpcv
    // revenue_ecpcv
    tooltip.html(html);
};

D3Chart.prototype._updateToolTipPositionInClient = function(el) {
    const that = this,
        selected = this._selected;

    const bandWidth = this._cache.deltaWidth_per_dataPoint;
    const offSetSoYouCanSeeCircle = 15; // bandWidth/4;

    // x,y is the coordinate of tooltip in svg
    const y = 0;
    const x = this._cache.mouseLineX + offSetSoYouCanSeeCircle;
    const toolTipsPosition_clientCoord = this._getClientCoordinate({ x, y }); // Transform the above coordinates to client
    selected.component.toolTipsPosition = toolTipsPosition_clientCoord; // Mount it as a property of react component

    // Mouse line coordinate
    const mouseLineCoord_client = this._getClientCoordinate({ x: this._cache.mouseLineX, y: 0 });
    selected.component.mousLineCoord = mouseLineCoord_client;

    // Offset in client
    const toolTipsOffset_client = toolTipsPosition_clientCoord.x - mouseLineCoord_client.x;
    selected.component.toolTipsOffset = toolTipsOffset_client;
};

///////////////////////////
//  Transformation       //
///////////////////////////
D3Chart.prototype._getClientCoordinate = function({ x = null, y = null }) {
    const selected = this._selected;

    const svgNode = selected.svg.node();
    const p_user = svgNode.createSVGPoint();
    p_user.x = x;
    p_user.y = y;

    const plotAreaNode = selected.plotArea.select('.plot-area-background').node();
    const m = plotAreaNode.getScreenCTM(); // Transformation metrix;

    const p_client = p_user.matrixTransform(m);
    return { x: p_client.x, y: p_client.y };
};

export default D3Chart;

import React, { useRef } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { FixedSizeList } from 'react-window';
import Sort from '@mui/icons-material/Sort';
import LinearProgress from '@mui/material/LinearProgress';
import { NoResults } from 'widgets-v6/layout';
import ColumnsDropdown from 'widgets-v6/columns-dropdown';
import InfoIcon from '@mui/icons-material/Info';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadTextContents from 'widgets-v5/download-text-contents';

import { useTable, useSortBy, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
    tableWrapper: {
        width: '100%',
        maxWidth: '100%',
        '&.sticky .body': {
            position: 'relative',
            zIndex: 0,
        },
        '&.sticky [data-sticky-td="true"]': {
            position: 'sticky',
        },
        '&.sticky .header': {
            position: 'sticky',
            zIndex: 4,
            width: 'fit-content',
            top: 0,
        },
        '& .td, & .th': {
            backgroundColor: 'white',
        },
        '& .th': {
            borderBottom: '1px solid #E0E0E0',
        },
    },
    col: {
        padding: 10,
        'word-break': 'break-word',
    },
    header: {
        position: 'relative',
    },
    sortContent: {
        position: 'absolute',
        zIndex: '1',
        top: '9px',
    },
    flip: {
        transform: 'scaleY(-1)',
    },
    tooltip: {
        fontSize: '11px',
    },
    tableBody: {
        '& > div': {
            position: 'relative',
        },
    },
    clickable: {
        cursor: 'pointer',
        color: '#0277BD',
        '&:hover': {
            textDecoration: 'underline',
            color: '#0277BD',
        },
    },
    tableRow: {
        '&:hover': {
            backgroundColor: '#f5f5f5',
            '& .td, & .th': {
                backgroundColor: '#f5f5f5',
            },
        },
        borderBottom: '1px solid #E0E0E0',
        alignItems: 'center',
    },
}));

export const VirtualizedTable = ({
    columns: initialColumns,
    data,
    minHeight,
    itemHeight,
    hiddenColumns,
    sortBy,
    isLoading,
    noResultsText,
    canToggleColumns,
    toggleColumn,
    onSortChange,
    canExport,
    exportFilename,
    showConditions = {},
}) => {
    const classes = useStyles();
    const csvContents = useRef(null);
    const columns = useRef([]);


    useEffect(() => {
        columns.current = initialColumns
            ? initialColumns.filter(column => !column.showOn || column.showOn(showConditions))
            : [];
    }, [initialColumns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
        state: { sortBy: currentSort },
    } = useTable(
        {
            columns: columns.current,
            data,
            initialState: {
                hiddenColumns,
                sortBy,
            },
        },
        useSortBy,
        useFlexLayout,
        useSticky
    );

    const formatCsvContents = () => {
        const headerContent = columns.current
            .reduce((acc, column) => {
                // It is possible for the Header parameter of the column to be a function
                // according to react-table API. We need to check here for that case.
                const result = [...acc];
                if (!hiddenColumns.includes(column.id)) {
                    result.push(
                        typeof column.Header === 'function' ? column.Header() : column.Header
                    );
                }
                return result;
            }, [])
            .join(',');

        const bodyContent = rows
            .map(row => {
                const rowContent = columns.current.reduce((acc, column) => {
                    const result = [...acc];
                    if (!hiddenColumns.includes(column.id)) {
                        const cellValue = row.values[column.id];
                        const cellContent = column.exportFormat
                            ? column.exportFormat(cellValue, row)
                            : cellValue;
                        result.push(cellContent);
                    }

                    return result;
                }, []);

                return rowContent.join(',');
            })
            .join('\r\n');

        return `${headerContent}\r\n${bodyContent}`;
    };

    useEffect(() => {
        setHiddenColumns(hiddenColumns);
    }, [hiddenColumns]);

    useEffect(() => {
        onSortChange(currentSort);
    }, [currentSort]);

    useEffect(() => {
        if (rows && rows.length) {
            csvContents.current = formatCsvContents();
        }
    }, [rows, hiddenColumns]);

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = rows[index];
            prepareRow(row);

            return (
                <TableRow
                    {...row.getRowProps({
                        style,
                    })}
                    className={`${classes.tableRow} tr`}
                >
                    {row.cells.map(cell => {
                        const currentConfig = columns.current.find(
                            ({ id }) => cell.column.id === id
                        );

                        return (
                            (!currentConfig.showOn || currentConfig.showOn(showConditions)) && (
                                <Box
                                    textAlign={
                                        currentConfig.textAlign ? currentConfig.textAlign : 'left'
                                    }
                                    {...cell.getCellProps()}
                                    onClick={e => {
                                        if (currentConfig.onClick) {
                                            currentConfig.onClick(e, row.original);
                                        }
                                    }}
                                    className={`${classes.col} td ${
                                        currentConfig.onClick ? classes.clickable : ''
                                    }`}
                                >
                                    {cell.render('Cell')}
                                </Box>
                            )
                        );
                    })}
                </TableRow>
            );
        },
        [prepareRow, rows]
    );

    return (
        <Paper>
            {isLoading && <LinearProgress />}
            {!isLoading && canToggleColumns && data.length ? (
                <AppBar position="static" color="inherit" elevation={0}>
                    <Toolbar>
                        <Grid justifyContent="flex-end" alignItems="center" container>
                            {canExport ? (
                                <DownloadTextContents
                                    filename={exportFilename}
                                    contents={csvContents.current}
                                >
                                    {onDownload => {
                                        return (
                                            <IconButton onClick={onDownload} size="large">
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        );
                                    }}
                                </DownloadTextContents>
                            ) : null}
                            <ColumnsDropdown
                                columns={columns.current}
                                onClick={toggleColumn}
                                hiddenColumns={hiddenColumns.reduce(
                                    (acc, curr) => ({ ...acc, [curr]: true }),
                                    {}
                                )}
                            />
                        </Grid>
                    </Toolbar>
                </AppBar>
            ) : null}
            <Box
                {...getTableProps()}
                className={`${classes.tableWrapper} table sticky`}
                minHeight={minHeight}
            >
                {!isLoading && data.length ? (
                    <FixedSizeList
                        height={minHeight}
                        itemCount={rows.length}
                        itemSize={itemHeight}
                        width="100%"
                        innerElementType={({ children, style, ...rest }) => (
                            <React.Fragment>
                                <TableHead className="header">
                                    {headerGroups.map(headerGroup => (
                                        <TableRow
                                            {...headerGroup.getHeaderGroupProps()}
                                            className="tr"
                                            key={headerGroup.id}
                                        >
                                            {headerGroup.headers.map(column => {
                                                return (
                                                    (!column.showOn || column.showOn(showConditions)) && (
                                                        <TableCell
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps()
                                                            )}
                                                            key={column.id}
                                                            className={`${classes.header} th`}
                                                            align={
                                                                column.textAlign
                                                                    ? column.textAlign
                                                                    : 'left'
                                                            }
                                                        >
                                                            {column.tooltip ? (
                                                                <Tooltip
                                                                    title={column.tooltip}
                                                                    classes={{
                                                                        tooltip: classes.tooltip,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent={
                                                                            column.textAlign
                                                                                ? column.textAlign
                                                                                : 'left'
                                                                        }
                                                                    >
                                                                        {column.render('Header')}
                                                                        <Box ml={0.2}>
                                                                            <InfoIcon fontSize="small" />
                                                                        </Box>
                                                                    </Box>
                                                                </Tooltip>
                                                            ) : (
                                                                column.render('Header')
                                                            )}
                                                            {column.isSorted && (
                                                                <span
                                                                    className={classes.sortContent}
                                                                >
                                                                    {column.isSortedDesc ? (
                                                                        <Sort />
                                                                    ) : (
                                                                        <Sort
                                                                            className={classes.flip}
                                                                        />
                                                                    )}
                                                                </span>
                                                            )}
                                                        </TableCell>
                                                    )
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableHead>
                                <TableBody className={`${classes.tableBody} body`}>
                                    <Box {...getTableBodyProps()} {...rest} style={style}>
                                        {data.length > 0 || isLoading ? children : null}
                                    </Box>
                                </TableBody>
                            </React.Fragment>
                        )}
                    >
                        {RenderRow}
                    </FixedSizeList>
                ) : null}

                {!isLoading && data.length <= 0 && (
                    <Box textAlign="center">
                        <NoResults title={noResultsText} />
                    </Box>
                )}
            </Box>
        </Paper>
    );
};

export default VirtualizedTable;

import React from 'react';
import { useTemplate } from './hook';

export function Template() {
    const { draft, changeMessage } = useTemplate();

    return (
        <div>
            {draft.message}
            <input
                type="text"
                value={draft.message}
                onChange={e => changeMessage(e.target.value)}
            />
        </div>
    );
}

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import OrgForm_Button from 'containers/admin-org-item-form';
import { adminOrgItem as Action_adminOrgItem } from 'containers/admin-org-item/actions';

import { creativeTypes, creativeSettings } from 'services/resources/organization/setting';
import orgTypeMappings from 'common/constants/organizationTypeMappings';
import REVENUE_MODELS from 'common/constants/revenue-models';

import { BlockLoadGroup } from 'widgets-v5/load-group';
import { NeutralButton } from 'widgets-v5/buttons';
import { onRefresh, offRefresh } from 'containers/global-refresh';

const t = {
    standard: 'STANDARD',
    video: 'VIDEO',
    mraid: 'MRAID',
    custom_html: 'CUSTOM HTML',
    native: 'NATIVE',
    allow_create: 'Enabled',
    auto_approve: 'Auto Approve',
};

const mapStateToProps = (state, ownProps) => {
    const orgDetails = _.get(state, 'adminOrgItem.organization');

    let presentation = { auditors: [], auditees: [] };

    const auditors = _.get(state, 'adminOrgItem.auditors', []);
    const auditees = _.get(state, 'adminOrgItem.auditees', []);
    presentation = { auditors, auditees };

    const o = {
        orgId: _.get(ownProps, 'orgId', void 0),
        orgDetails,
        presentation,
    };
    return o;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch__init(orgId) {
            return dispatch(Action_adminOrgItem.init(orgId));
        },
    };
};

class CreativeTypeItemComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="creative-type-item">
                <div className="creative-head">{_.get(t, `${this.props.creativeType}`, '??')}</div>
                <div className="creative-body">
                    {this.props.creativeSettings
                        .filter(creativeSetting => {
                            if (creativeSetting && creativeSetting !== 'auto_approve') return true;
                            if (
                                creativeSetting &&
                                creativeSetting === 'auto_approve' &&
                                this.props.showAutoApprove
                            )
                                return true;
                        })
                        ///////////////////////////////////////////////////////////////////////
                        // Currently auto_approve is not implimented thus the filter bellow is added
                        // to only show allow_create.
                        .filter(creativeSetting => {
                            if (creativeSetting && creativeSetting !== 'auto_approve') return false;
                            if (creativeSetting && creativeSetting !== 'allow_create') return true;
                        })
                        // Remove the above filter when allow_create is implimented
                        ///////////////////////////////////////////////////////////////////////
                        .map((creativeSetting, k) => {
                            const settingValue = _.get(
                                this.props,
                                `orgDetails.creatives.${
                                    this.props.creativeType
                                }.${creativeSetting}`,
                                void 0
                            );
                            return (
                                <div className={`creative-setting ${creativeSetting}`} key={k}>
                                    <div className="creative-setting-name">
                                        {_.get(t, `${creativeSetting}`, '??')}
                                    </div>
                                    <div className="creative-setting-value">
                                        {settingValue ? (
                                            <span className="yes">Yes</span>
                                        ) : (
                                            <span className="no">No</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

class CreativeTypeCollectionComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={`creative-type-collection`}>
                {creativeTypes.map((creativeType, k) => {
                    return (
                        <CreativeTypeItemComponent
                            key={k}
                            creativeType={creativeType}
                            creativeSettings={creativeSettings}
                            {...this.props}
                        />
                    );
                })}
            </div>
        );
    }
}

class OrgItemView extends React.Component {
    state = {
        isLoading: false,
    };

    constructor(props) {
        super(props);
    }

    init = () => {
        this.setState({ isLoading: true });

        this.props
            .dispatch__init(this.props.orgId)
            .then(() => {
                this.setState({ isLoading: false });
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });
    };

    componentDidMount() {
        this.init();

        onRefresh(this.init);
    }

    componentWillUnmount() {
        offRefresh(this.init);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.orgId !== nextProps.orgId) {
            // organization has changed, so need to initialize again
            this.setState({ isLoading: true });

            this.props.dispatch__init(nextProps.orgId).then(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    render() {
        const orgDetials = this.props.orgDetails;
        const orgType = _.get(orgDetials, 'type', '');

        if (!orgDetials) {
            return <BlockLoadGroup size="medium" isLoading={true} />;
        }

        const relationship = orgDetials._relationship;

        let showTechFee = true;
        let showAutoApprove = false;
        let canEditOrg = true;
        let showSubsection = false;

        // tech_fee visibility
        // -------------------
        if (_.includes(['client', 'co_managed_client', 'admin'], orgType)) {
            // don't show platform fee if it is co-managed client,  client, admin
            showTechFee = false;
        }

        if (_.includes(['self'], relationship)) {
            // Not allow to edit org if realtionship is a self
            canEditOrg = true;
        }

        if (_.includes(['child'], relationship)) {
            showAutoApprove = true; // only show creative auto_approve field if it is a child

            if (_.includes(['admin', 'media_buyer'], orgType)) {
                showSubsection = true;
            }
            if (_.includes(['co_managed_client', 'client'], orgType)) {
                showSubsection = false;
            }
        }

        //const credit_limit =

        return (
            <BlockLoadGroup isLoading={this.state.isLoading}>
                <div
                    className={classnames('admin_organization-view', {
                        'admin_organization-view__hidden': this.state.isLoading,
                    })}
                >
                    <div className="head">
                        <div className="head-title-area">
                            <div className="org-name">{_.get(orgDetials, 'name', '')}</div>
                            <div className="org-type">
                                {_.get(orgTypeMappings, `${orgType}`, 'Unknown')}
                            </div>
                        </div>
                        <div className="head-control-area">
                            {canEditOrg ? (
                                <OrgForm_Button
                                    orgId={this.props.orgId}
                                    Button={
                                        <div className="ef5-interactive-area__visibility">
                                            <NeutralButton
                                                icon={<i className="fa fa-pencil" />}
                                                text="Edit"
                                            />
                                        </div>
                                    }
                                />
                            ) : (
                                <div>[can not edit org because realtionship is self]</div>
                            )}
                        </div>
                    </div>
                    <div className="body">
                        <div className="top-sections">
                            <div className="left">
                                {showTechFee ? (
                                    <div className="tech-fee field">
                                        <div className="name">Platform Fee </div>
                                        <div className="value">{`${(
                                            _.get(orgDetials, 'tech_fee', '') * 100
                                        ).toFixed(2)} %`}</div>
                                    </div>
                                ) : null}
                                {showSubsection ? (
                                    <div className="credit-limit field">
                                        <div className="name">Credit Limit </div>
                                        <div className="value">
                                            {_.get(orgDetials, 'credit_limit', '') === -1
                                                ? 'unlimited'
                                                : `$ ${_.get(orgDetials, 'credit_limit', '')}`}{' '}
                                        </div>
                                    </div>
                                ) : null}
                                {showSubsection ? (
                                    <div className="currency field">
                                        <div className="name">Currency </div>
                                        <div className="value">{_.get(orgDetials, 'currency')}</div>
                                    </div>
                                ) : null}
                                {showSubsection ? (
                                    <div className="revenueMode field">
                                        <div className="name">Revenue Model</div>
                                        <div className="value">
                                            {REVENUE_MODELS[_.get(orgDetials, 'revenueModel')]}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {showSubsection ? (
                                <div className="right">
                                    <div className="auditors field">
                                        <div className="name"> Auditors </div>
                                        <div className="value">
                                            {this.props.presentation.auditors.map((auditor, i) => {
                                                return (
                                                    <div className="user" key={i}>
                                                        {auditor}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="auditees field">
                                        <div className="name"> Auditees </div>
                                        <div className="value">
                                            {this.props.presentation.auditees.map((auditee, i) => {
                                                return (
                                                    <div className="user" key={i}>
                                                        {auditee}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {showSubsection ? (
                            <div className="creatives-container">
                                <div className="creatives field">
                                    <div className="name"> Creatives Setting </div>
                                    <CreativeTypeCollectionComponent
                                        showAutoApprove={showAutoApprove}
                                        {...this.props}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </BlockLoadGroup>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrgItemView);

import _ from 'lodash';
import React from 'react';
import flags from 'containers/flags/service';

export default function selector(storeState, props) {
    const { campaignId, creativeId } = props.params;

    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    if (!campaign) {
        return { creatives: [] };
    }

    // Asset
    const filesResource = _.get(storeState, `resources.files`, {});
    const campaignFiles = _.reduce(
        campaign.files,
        (acc, fileId) => {
            const file = filesResource[fileId];
            if (!file) {
                return acc;
            }
            return {
                ...acc,
                [fileId]: file,
            };
        },
        {}
    );
    const filteredCampaignFiles = _.filter(campaignFiles, x => x);
    const assets = _.filter(filteredCampaignFiles, (value, key) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? value : null;
    });
    const documents = _.filter(filteredCampaignFiles, (value, key) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? null : value;
    });

    const form = _.get(storeState, `creativeNewForm`, {});

    const showErrors = _.get(storeState, `creativeNewForm.showErrors`);
    const warnings = getCreativeWarnings(campaign);
    return {
        campaignId,
        creativeId,
        assets,
        documents,
        warnings,
        isSubmiting: form.isSubmiting,
        isLoading: form.isLoading,
        isOpen: form.isFormOpen,
        draft: form.draft,
        isDraftValid: form.isDraftValid,
        showErrors: form.showErrors,
        errors: reduceErrorList(form.errors),
    };
}

// function getNewCreativeDraft(storeState, props) {
//     const { campaignId } = props;
//     const draft = _.get(storeState, `creativeNewForm.draft`);
//     return draft;
// }

function reduceErrorList(errorsAsList = []) {
    return _(errorsAsList)
        .groupBy(e => e.field)
        .reduce((acc, v, k) => {
            const o = {
                ...acc,
                [k]: _.map(v, 'message'),
            };
            return o;
        }, {});
}

export const RESTRICTED_CATEGORY_CREATIVE_WARNINGS = {
    cannabis: {
        title: (
            <div>
                Please ensure that this creative is in compliance with <a style={{ color: '#00897B', textDecoration: 'underline' }} target="_blank" href="https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis/laws-regulations/promotion-prohibitions.html">Health Canada’s guidelines on cannabis promotion</a>. Our publisher and exchange partners reserve the right to block or limit cannabis-related creatives.
            </div>
        ),
        severity: "soft",
        message: `Restrictions include, but may not be limited to:
- All landing page URLs must be age-gated
In addition, it is prohibited to promote cannabis…
- by communicating information about its price or distribution
- by doing so in a manner that there are reasonable grounds to believe could be appealing to young persons
- by means of a testimonial or endorsement, however displayed or communicated
- by means of the depiction of a person, character or animal, whether real or fictional; or
- by presenting it or any of its brand elements in a manner that associates it or the brand element with, or evokes a positive or negative emotion about or image of, a way of life such as one that includes glamour, recreation, excitement, vitality, risk or daring.`
    }
}


export function getCreativeWarnings(campaign) {
    const warnings = [];

    if (
        campaign.restricted_category &&
        RESTRICTED_CATEGORY_CREATIVE_WARNINGS[campaign.restricted_category]) {
        warnings.push(RESTRICTED_CATEGORY_CREATIVE_WARNINGS[campaign.restricted_category]);
    }

    return warnings;
}

export default [
    {
        value: 'Auto & Vehicles',
        label: 'Auto & Vehicles',
        description: 'Android Only',
    },
    {
        value: 'Books & Reference',
        label: 'Books & Reference',
    },
    {
        value: 'Business',
        label: 'Business',
    },
    {
        value: 'Education',
        label: 'Education',
    },
    {
        value: 'Entertainment',
        label: 'Entertainment',
    },
    {
        value: 'Finance',
        label: 'Finance',
    },
    {
        value: 'Food & Drink',
        label: 'Food & Drink',
    },
    {
        value: 'Games',
        label: 'Games',
    },
    {
        value: 'Health & Fitness',
        label: 'Health & Fitness',
    },
    {
        value: 'House & Home',
        label: 'House & Home',
        description: 'Android Only',
    },
    {
        value: 'Lifestyle',
        label: 'Lifestyle',
    },
    {
        value: 'Maps & Navigation',
        label: 'Maps & Navigation',
    },
    {
        value: 'Medical',
        label: 'Medical',
    },
    {
        value: 'Music & Audio',
        label: 'Music & Audio',
    },
    {
        value: 'News & Magazines',
        label: 'News & Magazines',
    },
    {
        value: 'Parenting',
        label: 'Parenting',
        description: 'Android Only',
    },
    {
        value: 'Photo & Video',
        label: 'Photo & Video',
    },
    {
        value: 'Productivity',
        label: 'Productivity',
    },
    {
        value: 'Shopping',
        label: 'Shopping',
    },
    {
        value: 'Social',
        label: 'Social',
    },
    {
        value: 'Sports',
        label: 'Sports',
    },
    {
        value: 'Tools & Utilities',
        label: 'Tools & Utilities',
    },
    {
        value: 'Travel & Local',
        label: 'Travel & Local',
    },
    {
        value: 'Weather',
        label: 'Weather',
    },
    {
        value: 'Kids & Family',
        label: 'Kids & Family',
    },
    {
        value: 'Other',
        label: 'Other',
        description: 'Android Only',
    },
    {
        value: '',
        label: 'Unknown',
    },
];

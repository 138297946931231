import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { getRangePositionToNow } from 'common/date-calculator';
import TimezoneSelector from 'widgets-v5/timezone-selector';

import Card from 'widgets-v5/card';
import Tabs from 'widgets-v5/tabs';
import { PanelsLayout, WorkspacePanel, ScrollingSidebarPanel } from 'widgets-v5/panels-layout';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import Spacer from 'widgets-v5/spacer';
import { DateRangeSelector } from 'widgets-v6/date-range-selector';

class ReportLayoutWrapper extends React.Component {
    canSeeExploreTab = () => {
        const {
            campaign,
            campaignRelationship: { isOwner, isClient },
        } = this.props;

        const isExploreShared = _.get(
            campaign,
            'sharing_settings.report_tabs.explore.shared',
            false
        );

        if (this.isClientReportPage()) {
            if (isClient || this.isClientReportPage()) {
                return isExploreShared;
            }

            if (isOwner) {
                return true;
            }

            return false;
        } else {
            return isOwner || (isClient && isExploreShared);
        }
    };

    canSeeGeoTab = () => {
        const {
            campaign,
            campaignRelationship: { isOwner, isClient },
        } = this.props;

        const isGeoShared = _.get(campaign, 'sharing_settings.report_tabs.geo.shared', false);
        const geoDidExistWhenCampaignCreated = moment(campaign._created).isSameOrAfter(
            '2017-03-09'
        ); // Mar 09 geo release v4.3.0

        if (this.isClientReportPage()) {
            if (isClient || this.isClientReportPage()) {
                return isGeoShared;
            }

            if (isOwner) {
                return true;
            }

            return false;
        } else {
            return geoDidExistWhenCampaignCreated && (isOwner || (isClient && isGeoShared));
        }
    };

    canSeeChannelTab = () => {
        const {
            campaign,
            campaignRelationship: { isOwner, isClient },
        } = this.props;

        const isChannelShared = _.get(
            campaign,
            'sharing_settings.report_tabs.channel.shared',
            false
        );
        const channelDidExistWhenCampaignCreated = moment(campaign._created).isSameOrAfter(
            '2017-02-22'
        ); // Feb 22 channel release v4.2.0

        if (this.isClientReportPage()) {
            if (isClient || this.isClientReportPage()) {
                return isChannelShared;
            }

            if (isOwner) {
                return true;
            }

            return false;
        } else {
            return channelDidExistWhenCampaignCreated && (isOwner || (isClient && isChannelShared));
        }
    };

    isClientReportPage = () => /campaigns\/.*\/client-report/.test(window.location.pathname);

    getReportPath = () => {
        if (this.isClientReportPage()) {
            return 'client-report';
        } else {
            return 'report';
        }
    };

    render() {
        const { campaign, dateRange, tab, selectedMetric } = this.props;
        const selectedMetricStyle = _.get(selectedMetric, `style`, 'impressions');

        const { id } = campaign;
        const { start, end } = dateRange;

        const dropDownOptions = createDateRangePickerOptions(campaign, dateRange);

        const reportPath = this.getReportPath();

        const items = [
            {
                label: 'Explore',
                value: 'explore',
                link: `/campaigns/${id}/${reportPath}/explore`,
                hidden: !this.canSeeExploreTab(),
            },
            {
                label: 'By Geo',
                value: 'geo',
                link: `/campaigns/${id}/${reportPath}/geo`,
                hidden: !this.canSeeGeoTab(),
            },
            {
                label: 'By App & Site',
                value: 'channel',
                link: `/campaigns/${id}/${reportPath}/channel`,
                hidden: !this.canSeeChannelTab(),
            },
        ];

        if (campaign.fta_enabled && campaign.fta_version === 2) {
            items.push({
                label: 'Foot Traffic Attribution',
                value: 'foot-traffic-attribution',
                link: `/campaigns/${id}/${reportPath}/foot-traffic-attribution`,
                hidden: false,
            });
        }

        return (
            <BlockLoadGroup isLoading={!campaign}>
                <div
                    className={classnames(
                        'ef3-reports',
                        'ef5-reports',
                        `ef5-reports_metric-${selectedMetricStyle}`,
                        {
                            'ef5-reports_mode-explore': this.props.mode === 'dimension',
                            'ef5-reports_mode-pivotTable': this.props.mode === 'pivot',
                        }
                    )}
                >
                    <div className="ef5-reports__tabs-wrapper">
                        <Tabs
                            align="left"
                            activeItem={tab ? tab : 'explore'}
                            className="ef5-reports__tabs"
                            items={items}
                        />
                    </div>
                    <PanelsLayout direction="row">
                        <ScrollingSidebarPanel className="ef5-reports__sidebar">
                            <Card margin="16px 0 16px 16px">
                                <Box mb={1}>
                                    <FormLabel>Timezone</FormLabel>
                                </Box>
                                <TimezoneSelector
                                    timezone={this.props.timezone}
                                    onChange={this.props.updateTimezone}
                                    existingTimezones={this.props.existingTimezones}
                                />
                                <Spacer type="small" />
                                <Box mb={1}>
                                    <FormLabel>Date Range</FormLabel>
                                </Box>
                                <DateRangeSelector
                                    key={dateRange.cacheKey}
                                    timezone={this.props.timezone}
                                    campaignFlight={{ start, end }}
                                    dropDownOptions={dropDownOptions}
                                    onChange={this.props.filterByDateRange}
                                />
                                <Spacer type="small" />
                                {this.props.dimensionSelector && (
                                    <React.Fragment>
                                        <Box mb={1}>
                                            <FormLabel>Metric</FormLabel>
                                        </Box>
                                        {this.props.dimensionSelector}
                                    </React.Fragment>
                                )}
                                <Spacer type="small" />
                                {this.props.metricSelector}
                            </Card>
                        </ScrollingSidebarPanel>
                        <WorkspacePanel className="ef5-reports__workspace">
                            {this.props.workspace}
                        </WorkspacePanel>
                    </PanelsLayout>
                </div>
            </BlockLoadGroup>
        );
    }
}

export const ReportLayout = connect()(ReportLayoutWrapper);

function createDateRangePickerOptions(campaign, selectedDateRange) {
    const { start, end } = campaign;

    const campaignFlightInRelationToNow = getRangePositionToNow(campaign);

    const startDateAsMoment = moment.tz(start, selectedDateRange.timezone);
    const endDateAsMoment = moment.tz(end, selectedDateRange.timezone);

    const formattedStartDate = startDateAsMoment.format('YYYY-MM-DDTHH:mm:ss');
    const formattedEndDate = endDateAsMoment.format('YYYY-MM-DDTHH:mm:ss');

    let dropDownOptions = {};
    switch (campaignFlightInRelationToNow) {
        case 'future': {
            dropDownOptions = [
                {
                    title: 'Entire Campaign',
                    start,
                    end,
                    timeframe: 'everything',
                    selectedDefault: true,
                },
            ];
        }

        case 'past': {
            const monthsBetween = getMonthsBetween(startDateAsMoment, endDateAsMoment, selectedDateRange);
            const firstOptionGroup = [
                {
                    title: 'Entire Campaign',
                    start: startDateAsMoment.format(),
                    end: startDateAsMoment.format(),
                    timeframe: 'everything',
                    selectedKey: 'everything',
                    selectedDefault: selectedDateRange.selectedKey === 'everything' || !selectedDateRange.selectedKey,
                },
            ];

            dropDownOptions = [].concat(firstOptionGroup, monthsBetween, [{ title: 'Custom', selectedKey: 'custom', selectedDefault: selectedDateRange.selectedKey === 'custom' }]);
        }

        case 'present': {
            let defaultSelection = [
                {
                    title: 'Campaign to date (Live Stats)',
                    start: formattedStartDate,
                    end: formattedEndDate,
                    timeframe: 'campaign_to_date',
                    selectedKey: 'campaign_to_date',
                    selectedDefault: selectedDateRange.selectedKey === 'campaign_to_date' || !selectedDateRange.selectedKey,
                },
            ];

            const campaignToYesterday = [
                {
                    title: 'Campaign to Yesterday',
                    start: formattedStartDate,
                    end: moment()
                        .subtract(1, 'day')
                        .endOf('day')
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    timeframe: 'campaign_to_yesterday',
                    selectedKey: 'campaign_to_yesterday',
                    selectedDefault: selectedDateRange.selectedKey === 'campaign_to_yesterday',
                },
            ];

            const monthsBetween = getMonthsBetween(startDateAsMoment, endDateAsMoment, selectedDateRange);

            dropDownOptions = [].concat(defaultSelection, campaignToYesterday, monthsBetween, [
                { title: 'Custom', selectedKey: 'custom', selectedDefault: selectedDateRange.selectedKey === 'custom' },
            ]);
        }
    }

    return dropDownOptions;
}

function getMonthsBetween(start, end, selectedDateRange) {
    const monthsBetween = [];
    const endOfEndingMonth = end.endOf('month');
    const monthIncrement = start;

    let i = 0;

    while (endOfEndingMonth >= monthIncrement) {
        if (i > 100) {
            // throw new Error('The dates specified are too far apart.');
            break;
        }
        const title = monthIncrement.format('MMMM, YYYY');

        monthsBetween.push({
            title,
            start: monthIncrement.startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
            end: monthIncrement.endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
            selectedKey: title,
            selectedDefault: selectedDateRange.selectedKey === title,
        });

        monthIncrement.add(1, 'month');

        i++;
    }

    return monthsBetween;
}

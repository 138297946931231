import React, { useEffect } from 'react';

import PermutiveLayout from './layout';
import { usePermutiveDashboardData } from './hook';

export const PermutiveDashboard = ({ params }) => {
    const { init, audienceSegments, totalSegmentCount, isLoading, organizationId } = usePermutiveDashboardData();

    useEffect(() => {
        init();
    }, []);

    const onChange = value => {
        init({ searchString: value });
    };

    return (
        <PermutiveLayout
            audienceSegments={audienceSegments}
            onSearch={onChange}
            segmentCount={totalSegmentCount}
            isLoading={isLoading}
            activeAudience={params.audienceId}
            organizationId={organizationId}
        />
    );
};

export default PermutiveDashboard;

import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import config from '../../../../config';
import { browserHistory } from 'react-router';
import StandardSelect from 'widgets/form-inputs/standard-select';
import FormField from 'widgets-v5/form-field';

import { activateEdit as Action_activateEdit } from 'pages/activate/actions';
import selector from './selector';

import { BlockLoadGroup } from 'widgets-v5/load-group';
import CenterLayout from 'widgets-v5/center-layout';
import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';
import Card from 'widgets-v5/card';

const Activate = createReactClass({
    displayName: 'Activate',

    statics: {
        refresh(dispatch, nextState) {
            const activationToken = _.get(nextState, 'location.query.token');
            if (activationToken) {
                dispatch(Action_activateEdit.open(activationToken));
            }
        },
    },

    init() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        Activate.refresh(dispatch, nextState);
    },

    componentDidMount() {
        this.init();
    },

    onFieldChange(fieldName) {
        return fieldValue => {
            const newFormData = {
                [fieldName]: fieldValue,
            };

            this.props.dispatch(Action_activateEdit.updateDraft(newFormData));
        };
    },

    handleSubmit(e) {
        e.preventDefault();
        this.props.dispatch(Action_activateEdit.submitDraft());
    },

    render() {
        const draft = this.props.draft;
        const errors = this.props.errors;
        const showErrors = this.props.showErrors;

        return (
            <CenterLayout className="ef5-activate">
                <BlockLoadGroup size="large" isLoading={this.props.isLoading}>
                    <img className="ef5-activate__logo" src="/images/EngageFront-logo.png" />
                    <Card
                        className="ef5-activate__card"
                        title="Account Activation"
                        footer={
                            <ButtonGroup>
                                <PrimaryButton text="Activate" onClick={this.handleSubmit} />
                            </ButtonGroup>
                        }
                    >
                        <form ref="login-form" method="POST" onSubmit={this.handleSubmit}>
                            <FormField label="E-mail address" isRequired={true}>
                                <input
                                    className="ef3-stdInput"
                                    type="text"
                                    value={draft.email}
                                    disabled={true}
                                />
                            </FormField>
                            <FormField
                                label="First Name"
                                errors={errors.first_name}
                                showErrors={showErrors}
                                isRequired={true}
                            >
                                <input
                                    className="ef3-stdInput"
                                    type="text"
                                    value={draft.first_name}
                                    onChange={e => {
                                        this.onFieldChange('first_name')(e.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField
                                label="Last Name"
                                errors={errors.last_name}
                                showErrors={showErrors}
                                isRequired={true}
                            >
                                <input
                                    className="ef3-stdInput"
                                    type="text"
                                    value={draft.last_name}
                                    onChange={e => {
                                        this.onFieldChange('last_name')(e.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField
                                label="Password"
                                errors={errors.password}
                                showErrors={showErrors}
                                isRequired={true}
                            >
                                <input
                                    className="ef3-stdInput"
                                    type="password"
                                    defaultValue=""
                                    onChange={e => {
                                        this.onFieldChange('password')(e.target.value);
                                    }}
                                />
                            </FormField>
                            <FormField
                                label="Confirm Password"
                                errors={errors.confirmPassword}
                                showErrors={showErrors}
                                isRequired={true}
                            >
                                <input
                                    className="ef3-stdInput"
                                    type="password"
                                    defaultValue=""
                                    onChange={e => {
                                        this.onFieldChange('confirmPassword')(e.target.value);
                                    }}
                                />
                            </FormField>
                            <div className="ef3-formField_description is-error">
                                <div>
                                    {' '}
                                    {showErrors && errors.passwordsMatches
                                        ? errors.passwordsMatches
                                        : null}
                                </div>
                            </div>
                        </form>
                    </Card>
                </BlockLoadGroup>
            </CenterLayout>
        );
    },
});

const connectedComponent = connect(selector)(Activate);

export default connectedComponent;

import Joi from '@hapi/joi';

export default Joi.object({
    name: Joi.string()
        .required()
        .default('')
        .label('Name'),

    contentType: Joi.string()
        .default('ad')
        .allow('ad', 'creative')
        .label('Content Type'),

    sendToFta: Joi.bool()
        .default(false)
        .allow(null)
        .label('Sync with FTA'),

    hidden: Joi.bool()
        .default(false)
        .allow(null)
        .label('Hidden'),

    groups: Joi.array()
        .items(
            Joi.object({
                id: Joi.string(),
                name: Joi.string().required(),
                keys: Joi.array()
                    .items(Joi.string())
                    .required(),
            })
        )
        .required()
        .default([])
        .label('Groups'),
});

import _ from 'lodash';
import React from 'react';

import FormField from 'widgets-v5/form-field';
import { MultiSelect } from 'widgets-v6/select';

import LANGUAGE_CODES from 'common/constants/language-codes';

const _getLanguageCodesOptions = (includeUnknown) => {
    const languageCodesOptions = [];

    const newDictLang = includeUnknown ?
        {
            'NA': 'Unknown',
            ...LANGUAGE_CODES
        } : LANGUAGE_CODES;

    _(newDictLang).each((item, key) => {
        languageCodesOptions.push({
            label: item,
            value: key,
        });
    });
    return languageCodesOptions;
};

export function TargetDeviceLanguageExclude({ label, value, errors, showErrors, onChange, includeUnknown = false }) {
    const handleFormChange = value => {
        onChange(value);
    };

    return (
        <FormField
            label={label}
            description=""
            errors={errors}
            showErrors={showErrors}
            isRequired={false}
        >
            <MultiSelect
                value={value}
                options={_getLanguageCodesOptions(includeUnknown)}
                onChange={handleFormChange}
                placeholder={'Exclude languages'}
            />
        </FormField>
    );
}

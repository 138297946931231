/**
 * NOTE: This widget will only work if the parent's width isn't unlimited.
 *       This means it will not truncate the text if there is enough space to show everything.
 */

import React from 'react';

import Box from '@mui/material/Box';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    truncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

export const MiddleTruncatedText = ({ text }) => {
    const classes = useStyles();

    const halfPoint = Math.floor((text.length - 1) * 0.75);
    const firstPart = text.substr(0, halfPoint);
    const secondPart = text.substr(halfPoint, text.length - 1);
    return (
        <Box display="flex" minHeight={0}>
            <Box flex="0 3 auto" minWidth={0} className={classes.truncate}>
                {firstPart}
            </Box>
            <Box flex="3 0 auto" minWidth={0}>
                {secondPart}
            </Box>
        </Box>
    );
};

export default MiddleTruncatedText;

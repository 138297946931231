import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import flags from 'containers/flags/service';

import { getAgeGroups } from 'containers/ad-summary';
import { TargetAgeGroups_EF_1418 } from 'forms/ad-form/modules/checkbox-groups';

import shallowCompare from 'utils/shallow-compare';
import classnames from 'classnames';

export class AgeForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Age"
                fields={['target_age_groups']}
                shouldRefreshPredictionsOnSave={true}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <TargetAgeGroups_EF_1418
                        label={ads ? 'Age' : ''}
                        value={draft.target_age_groups}
                        errors={errors.targetAgeGroup}
                        showErrors={shouldShowErrors}
                        onChange={val => updateDraft({ target_age_groups: val })}
                    />
                )}
            </BaseColumnForm>
        );
    }
}

export class AgeSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;
        return (
            <div>
                {error ? (
                    <div>{error}</div>
                ) : (
                    <div>
                        {_.map(getAgeGroups(ad.target_age_groups), age => (
                            <div key={age}>{age}</div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

import actions from './actions';
import querystring from 'utils/querystring';

const reloadMw = () => ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === 'REPORT_JOB:COMPLETED') {
        const filter = querystring.parse(window.location.search);
        dispatch(actions.fetchPageData(filter));
    }
};

export const makeMiddlewares = deps => {
    return [reloadMw(deps)];
};
export const middlewares = makeMiddlewares({});

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CountryGeotarget from './modules/country-geotarget';

class GeotargetsSelector extends React.Component {
    static propTypes = {
        value: PropTypes.arrayOf(
            PropTypes.shape({
                country: PropTypes.string.isRequired,
                include: PropTypes.arrayOf(PropTypes.shape),
                exclude: PropTypes.arrayOf(PropTypes.shape),
            })
        ),
        onChange: PropTypes.func.isRequired,
        // Fake fetcher for testing
        fetcher: PropTypes.func,
    };

    onCountryGeotargetsChange = country => {
        return newCountryGeotargets => {
            const countryIndex = this.props.value.findIndex(
                geotargets => geotargets.country === country
            );

            const newGeotargets = [...this.props.value];
            newGeotargets.splice(countryIndex, 1, {
                country,
                include: newCountryGeotargets.include,
                exclude: newCountryGeotargets.exclude,
            });

            this.props.onChange(newGeotargets);
        };
    };

    onCountryGeotargetsDelete = country => {
        return () => {
            // Pass up the original value, but without the country
            return this.props.onChange(_.reject(this.props.value, { country }));
        };
    };

    render() {
        return (
            <div className="ef3-geotargetsSelector">
                {this.props.value.map(geotarget => (
                    <CountryGeotarget
                        key={`${geotarget.country}_${geotarget.include.join(
                            ','
                        )}_${geotarget.exclude.join(',')}`}
                        country={geotarget.country}
                        include={geotarget.include}
                        exclude={geotarget.exclude}
                        profileToken={this.props.profileToken}
                        fetcher={this.props.fetcher}
                        onChange={this.onCountryGeotargetsChange(geotarget.country)}
                        onDelete={this.onCountryGeotargetsDelete(geotarget.country)}
                        removeCountryButton={this.props.removeCountryButton}
                    />
                ))}
            </div>
        );
    }
}

export default GeotargetsSelector;

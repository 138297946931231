import React from 'react';
import _ from 'lodash';
import selector from './selector';
import FormField from 'widgets-v5/form-field';
import { connect } from 'react-redux';
import ScheduleConfigurator from './modules/schedule-configurator';
import RecipientSelector from './modules/recipient-selector';
import MetricSelector from './modules/metric-selector';
import SplitSelector from './modules/split-selector';
import PivotTable from 'widgets/pivot-table';
import { CampaignExportButton } from 'widgets/pivot-table/export-button';
import actions from 'containers/scheduled-report/actions';
import { formatDimensions, formatMetrics } from 'widgets/pivot-table/service';
import { getIsZeroStat } from 'services/resources/stats';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { DialogTitle } from 'widgets-v6/modal';

class ScheduledReport extends React.Component {
    UNSAFE_componentWillMount() {
        const { dispatch, campaign, recipients } = this.props;

        // Only initialize if we don't have the initial data setup yet.
        if (!recipients || !recipients.length) {
            dispatch(actions.init(campaign));
        }
    }

    openModal = () => {
        const { dispatch, campaign } = this.props;
        dispatch(actions.openModal(campaign));
    };

    closeModal = () => {
        const { dispatch } = this.props;

        dispatch(actions.closeModal());
    };

    onFieldChange = fieldName => {
        return fieldValue => {
            const { dispatch } = this.props;

            const newDraft = {
                [fieldName]: fieldValue,
            };

            dispatch(actions.updateDraft(newDraft));
        };
    };

    onSplitChange = splits => {
        this.props.dispatch(actions.updateSplits(splits));
    };

    handleUpdateSplits = splits => {
        this.props.dispatch(actions.updateSplits(splits));
    };

    onClickCB = value => {
        const { draft } = this.props;
        this.onFieldChange('scheduleInfo')({
            ...draft.scheduleInfo,
            excludeTodaysStat: !value,
        });
    };

    submitDraft = () => {
        const {
            dispatch,
            campaign: { scheduledReportId },
        } = this.props;

        dispatch(actions[scheduledReportId ? 'submitPatchDraft' : 'submitDraft']());
    };

    serializePivotTable = () => {
        return this.refs.pivotTable.serialize();
    };

    // previewReport = () => {
    //     const { dispatch, campaign } = this.props;

    //     const done = () => {
    //         setTimeout(() => {
    //             const payload = this.refs.exportButton.generateXLSPayload();
    //             dispatch(actions.previewReport(campaign.id, payload));
    //         }, 10);
    //     };

    //     dispatch(actions.fetchStats(campaign.id, done));
    // };

    // downloadPreview = () => {
    //     const { dispatch, campaign } = this.props;

    //     dispatch(actions.downloadPreview(campaign.id));
    // };

    toggleZeroRows = () => {
        const { dispatch } = this.props;

        dispatch(actions.toggleZeroRows());
    };

    render() {
        const {
            recipients,
            campaign,
            metrics,
            draft,
            errors,
            showErrors,
            pivotTable,
            splits,
            hasScheduleReport,
            dictionary,
            columns,
            hideZeroRows,
        } = this.props;

        const excludeTodaysStat = _.get(draft, 'scheduleInfo.excludeTodaysStat', true);

        const repeat = _.get(draft, 'scheduleInfo.repeat', 'none');

        return (
            <div className="ef3-scheduledReport_container">
                <button
                    className="ef3-scheduledReport_modalButton btn btn-secondary"
                    onClick={this.openModal}
                >
                    {hasScheduleReport ? (
                        <i className="fa fa-calendar-check-o" />
                    ) : (
                        <i className="fa fa-calendar" />
                    )}
                    &nbsp; Scheduled Reports
                </button>
                <Dialog open={this.props.isModalOpen}>
                    <DialogTitle onClose={this.closeModal}>
                        Scheduled Reports - {campaign.name}
                    </DialogTitle>
                    <DialogContent>
                        <Box mb={2.5}>
                            <Typography gutterBottom>Schedule</Typography>
                            <FormField errors={errors.scheduleInfo} showErrors={showErrors}>
                                <ScheduleConfigurator
                                    scheduleInfo={draft.scheduleInfo}
                                    onChange={this.onFieldChange}
                                />
                            </FormField>
                        </Box>
                        {repeat === 'none' && (
                            <div className="ef3-scheduledReport_spaceForFooter" />
                        )}
                        {repeat !== 'none' && (
                            <div>
                                <Box mb={2.5}>
                                    <Typography gutterBottom>Recipients</Typography>
                                    <FormField errors={errors.recipients} showErrors={showErrors}>
                                        <RecipientSelector
                                            recipients={recipients}
                                            onChange={this.onFieldChange}
                                        />
                                    </FormField>
                                </Box>
                                <Box mb={2.5}>
                                    <Typography gutterBottom>Report Options</Typography>
                                    <div className="ef3-scheduledReport_excludeStats">
                                        <FormField
                                            description={
                                                draft.scheduleInfo.repeat === 'once' ||
                                                draft.scheduleInfo.repeat === 'none'
                                                    ? null
                                                    : '(A final report including results from the end date will be sent the day after the campaign finishes, at 10:00 AM)'
                                            }
                                        >
                                            <label>
                                                <input
                                                    onChange={() =>
                                                        this.onClickCB(excludeTodaysStat)
                                                    }
                                                    type="checkbox"
                                                    checked={excludeTodaysStat}
                                                />
                                                <span>
                                                    Exclude active stats from incomplete days
                                                </span>
                                            </label>
                                        </FormField>
                                    </div>
                                </Box>
                                <FormField errors={errors.splits} showErrors={showErrors}>
                                    <SplitSelector
                                        hideZeroRows={hideZeroRows}
                                        splits={splits}
                                        onUpdateSplits={this.handleUpdateSplits}
                                        pivotTable={pivotTable}
                                        onChange={this.onSplitChange}
                                        toggleZeroRows={this.toggleZeroRows}
                                    />
                                </FormField>
                                <FormField errors={errors.metrics} showErrors={showErrors}>
                                    <MetricSelector
                                        metrics={metrics}
                                        campaign={campaign}
                                        beacons={campaign.beacons}
                                        conversions={campaign.conversions}
                                        onChange={this.onFieldChange}
                                    />
                                </FormField>
                            </div>
                        )}

                        <PivotTable
                            ref="pivotTable"
                            hidden={true}
                            config={pivotTable}
                            dictionary={dictionary}
                            columns={columns}
                            onSerializeFormatRow={row => {
                                let nextRow = { ...row };
                                nextRow = formatDimensions(
                                    nextRow,
                                    pivotTable.splits,
                                    dictionary,
                                    campaign
                                );

                                return nextRow;
                            }}
                            onRenderFormatRow={(row, columns) => {
                                let nextRow = { ...row };
                                nextRow = formatMetrics(nextRow, columns);
                                nextRow = formatDimensions(
                                    nextRow,
                                    pivotTable.splits,
                                    dictionary,
                                    campaign
                                );

                                return nextRow;
                            }}
                            filterRowHook={(filterRow, row, columns) => {
                                if (filterRow(row, columns) === false) {
                                    return false;
                                }

                                if (hideZeroRows) {
                                    return !getIsZeroStat(
                                        row.stats,
                                        _.map(metrics.enabledMetrics, metric => metric.name)
                                    );
                                }

                                return true;
                            }}
                            topbarWorkspace={
                                <span>
                                    <CampaignExportButton
                                        ref="exportButton"
                                        campaign={campaign}
                                        dateRange={campaign}
                                        options={['json', 'csv', 'xls']}
                                        serializePivotTable={this.serializePivotTable}
                                        dictionary={dictionary}
                                    />
                                </span>
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.submitDraft}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(selector)(ScheduledReport);

import React from 'react';

export default class extends React.Component {
    render() {
        return (
            <dd className="ef3-adSummaryView_description">
                <img className="creativePreviewImg" src={this.props.dataOut} />
            </dd>
        );
    }
}

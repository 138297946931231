import _ from 'lodash';
import { calcTotalStats } from 'services/resources/stats';

import { NAME } from './reducer';

export default function selector(storeState, props) {
    const state = _.get(storeState, NAME);

    return {
        ...state,
    };
}

import _ from 'lodash';

export default function selector(storeState) {
    const geoResources = _.get(storeState, 'resources.geoCategories');
    const organizationId = _.get(storeState, 'profile.organizationId');
    return {
        geoResources,
        organizationId,
    };
}

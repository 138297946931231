import React from 'react';
import withStyles from '@mui/styles/withStyles';

import FormControl from '@mui/material/FormControl';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
});

const FormControlStyled = withStyles(styles)(props => {
    const { children, classes, error } = props;

    return (
        <FormControl fullWidth error={error} className={classes.root}>
            {children}
        </FormControl>
    );
});

export default FormControlStyled;

import _ from 'lodash';

const virtualFieldRegex = /^__.*/;
const DEBUG = false;

function log(message) {
    if (DEBUG) {
        console.log(message);
    }
}

export default function validate(source, schema, fieldPrefix) {
    var _fieldPrefix = fieldPrefix !== undefined ? fieldPrefix + '.' : '';
    log('Prefix: ' + _fieldPrefix);

    if (isArrayOfScalars(source)) {
        log('Validating array of scalars');
        return validateArrayOfScalars(source, schema, _fieldPrefix);
    }

    if (isArrayOfObjects(source)) {
        log('Validating array of objects');
        return validateArrayOfObjects(source, schema, _fieldPrefix);
    }

    if (_.isPlainObject(source)) {
        log('Validating object');
        return validateObject(source, schema, _fieldPrefix);
    }

    return [];
}

function validateArrayOfScalars(source, schema, fieldPrefix) {
    return _.reduce(
        source,
        function(errors, valueToValidate, index) {
            var pipelineErrors = processPipeline(
                schema,
                valueToValidate,
                source,
                index,
                fieldPrefix
            );

            log('Scalar result: ' + pipelineErrors);

            return errors.concat(pipelineErrors);
        },
        []
    );
}

function validateArrayOfObjects(source, schema, fieldPrefix) {
    var _fieldPrefix = fieldPrefix !== undefined ? fieldPrefix : '';

    return _.reduce(
        source,
        function(errors, item, index) {
            var itemErrors = validate(item, schema, _fieldPrefix + index);
            return errors.concat(itemErrors);
        },
        []
    );
}

function validateObject(source, schema, fieldPrefix) {
    return _.reduce(
        schema,
        function(errors, validator, fieldName) {
            var isVirtualField = virtualFieldRegex.test(fieldName);
            var valueToValidate = isVirtualField ? source : source[fieldName];

            var pipelineErrors = processPipeline(
                validator,
                valueToValidate,
                source,
                fieldName,
                fieldPrefix
            );

            return errors.concat(pipelineErrors);
        },
        []
    );
}

function processPipeline(validators, valueToValidate, source, fieldName, fieldPrefix) {
    var breakEarly = false;
    var _validators = [].concat(validators);

    // log('valueToValidate: ' + JSON.stringify(valueToValidate));

    return _.reduce(
        _validators,
        function(errors, validator, index) {
            log('breakEarly: ' + breakEarly);
            if (breakEarly) {
                return errors;
            }

            if (_.some(errors)) {
                return errors;
            }

            if (!_.isFunction(validator)) {
                return errors;
            }

            var result = validator(valueToValidate, source);

            switch (true) {
                case isSchema(result):
                    log('Nested schema: ' + index);
                    return validate(valueToValidate, result, fieldPrefix + fieldName);

                case typeof result === 'string':
                    log('Error message: ' + index);
                    return [
                        {
                            field: fieldPrefix + fieldName,
                            value: valueToValidate,
                            message: result,
                        },
                    ];

                case result === true:
                    log('Break early: ' + index);
                    breakEarly = true;
                    return errors;

                default:
                    return errors;
            }
        },
        []
    );
}

function isArrayOfObjects(source) {
    if (!Array.isArray(source)) {
        return false;
    }

    return _.isPlainObject(source[0]);
}

function isArrayOfFunctions(value) {
    return _.isArray(value) && _.every(value, _.isFunction);
}

function isFunctionOrArrayOfFunctions(value) {
    return _.isFunction(value) || isArrayOfFunctions(value);
}

function isArrayOfScalars(source) {
    if (!Array.isArray(source)) {
        return false;
    }

    var firstItem = source[0];

    return _.includes(['number', 'boolean', 'string'], typeof firstItem);
}

function isSchema(value) {
    if (typeof value === 'function') {
        return true;
    }
    if (_.isPlainObject(value)) {
        return _.every(value, isFunctionOrArrayOfFunctions);
    }
}

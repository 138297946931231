import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import selector from './selector';
import ProgressGraph from './progress-graph/lazy';
import { fetchAdDeliveryData } from 'pages/campaigns/progress/ad-progress/progress-graph/actions';
const AdProgress = createReactClass({
    displayName: 'AdProgress',

    getInitialState: () => {
        return { isLoading: false };
    },
    init() {
        const { dispatch, location, params, routes,timezoneAtCampaignLevel } = this.props;
        const nextState = { location, params, routes };
        this.setState({ isLoading: true });
        dispatch(fetchAdDeliveryData(nextState.params.campaignId, nextState.params.adId, timezoneAtCampaignLevel)).then(
            () => {
                this.setState({ isLoading: false });
            }
        );
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        if (this.props.params.adId !== prevProps.params.adId) {
            this.init();
        }
    },

    render() {
        const { campaignBudgetAllocationMethod } = this.props;

        if (campaignBudgetAllocationMethod === 'auto') {
            return null;
        }

        const currentDeliveryPlot = this.props.progressGraph.dataGraph.progressData
            .currentDeliveryPlot
            ? this.props.progressGraph.dataGraph.progressData.currentDeliveryPlot
            : [];

        const projectedDeliveryPlot = this.props.progressGraph.dataGraph.progressData
            .projectedDeliveryPlot
            ? this.props.progressGraph.dataGraph.progressData.projectedDeliveryPlot
            : [];

        const updatedProgressGraph = (
            <ProgressGraph
                idealDeliveryPlot={
                    this.props.progressGraph.dataGraph.progressData.idealDeliveryPlot
                }
                idealDelivered={this.props.progressGraph.dataGraph.progressData.idealDelivered}
                currentDeliveryPlot={currentDeliveryPlot}
                currentDelivered={this.props.progressGraph.dataGraph.progressData.currentDelivered}
                currentPacing={this.props.progressGraph.dataGraph.progressData.currentPacing}
                projectedDelivery={
                    this.props.progressGraph.dataGraph.progressData.projectedDelivery
                }
                projectedDeliveryPlot={projectedDeliveryPlot}
                projectedPacing={this.props.progressGraph.dataGraph.progressData.projectedPacing}
                timeAxisData={this.props.progressGraph.dataGraph.progressData.timeAxisData}
                totalTarget={this.props.progressGraph.dataGraph.progressData.totalTarget}
                timezone={this.props.timezoneAtCampaignLevel}
                goal={this.props.progressGraph.dataGraph.progressData.goal}
                isLoading={this.state.isLoading}
                isEnded={this.props.isEnded}
            />
        );

        return <React.Fragment>{updatedProgressGraph}</React.Fragment>;
    },
});

export default connect(selector)(AdProgress);

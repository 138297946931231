import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { ConfirmDialog } from 'widgets-v6/modal';

import selector from './selector';
import flags from 'containers/flags/service';

const CreativeAuditWarning = props => {
    const { creatives, selectedCreativeIds } = props;
    const selectedCreatives = _.filter(creatives, creative =>
        selectedCreativeIds.includes(creative.id)
    );
    const connectedAds = _(selectedCreatives)
        .map(creative => creative.connectedAds)
        .flatten()
        .uniq()
        .value();

    return (
        <ConfirmDialog
            isOpen={props.open}
            onCancel={props.onClose}
            title="Editing Approved Creative(s)"
            content={
                <React.Fragment>
                    <Typography>Modified creatives must be resubmitted for approval.</Typography>
                    {connectedAds.length > 0 && (
                        <React.Fragment>
                            <Typography>
                                The following <strong>ads</strong> will be paused until creative has
                                been reapproved:
                            </Typography>
                            <List>
                                {connectedAds.map(ad => (
                                    <ListItem key={`${ad.id}-warning`}>
                                        {' '}
                                        {`#${ad.id} - ${ad.name}`}{' '}
                                    </ListItem>
                                ))}
                            </List>
                        </React.Fragment>
                    )}
                </React.Fragment>
            }
            onConfirm={props.openCreativeBulkEditForm}
            onConfirmText={connectedAds.length > 0 ? 'Pause ads and continue' : 'Continue'}
        />
    );
};

export default connect(selector)(CreativeAuditWarning);

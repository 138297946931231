import React, { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Icon from '@mdi/react';
import { mdiDatabase } from '@mdi/js';
import Tooltip from '@mui/material/Tooltip';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';

import { AudienceCard } from '../modules/audience-card';
import { AudienceTable } from '../modules/audience-table';
import { getEnvironmentSettings } from 'services/environment';
import { useOverallTableDashboardData } from '../overall-dashboard/v2/hook';
import { isInternalUser } from 'states/profile/business-rules';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

const sortingOptions = [
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
];

const useStyles = makeStyles({
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#098679',
    },
});

const PDSCustomLayout2 = ({
    audienceSegments,
    onSearch,
    segmentCount,
    goToSegmentBuilder,
    segmentBuilderUrl,
    activeAudience,
    organizationId
}) => {
    const classes = useStyles();
    const { canEditAudiences } = useOverallTableDashboardData();
    const [activeSort, setActiveSort] = useState({ value: 'name', direction: 'asc' });

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const handleSortChange = event => {
        const { value, direction } = _.find(sortingOptions, { value: event.target.value });
        setActiveSort({ value, direction });
    };

    const sortedAudiences = _.orderBy(audienceSegments, [activeSort.value], [activeSort.direction]);

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'custom' },
    };

    const environmentSettings = getEnvironmentSettings();

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Your Custom Audiences
                </RouterLink>
                <Typography color="textPrimary">Pelmorex Data</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <Icon path={mdiDatabase} size={3} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            Pelmorex Data
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            Geolocation segments, based on first-party SDK location data and precise
                            polygons, and tailor-made for your campaigns.
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Box mt={3}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Total
                                    </Typography>
                                    <Typography variant="h5">{segmentCount}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {canEditAudiences && environmentSettings.canUseAudienceBuilder() && (
                            <Box mt={1}>
                                <Tooltip
                                    title={!isInternalUser() ? (
                                            <Typography variant="body2">
                                                Please reach out to your dedicated Customer Engagement Specialist or Client Partner to create a Custom Audience!
                                            </Typography>
                                        ) : ''
                                    }
                                >
                                    <Box display='inline-block'>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => goToSegmentBuilder(segmentBuilderUrl)}
                                            disabled={!isInternalUser()}
                                        >
                                            <AddIcon /> New Audience
                                        </Button>
                                    </Box>
                                </Tooltip>
                                {!isInternalUser() && <Box mt={1}>
                                    <WarningText
                                        severity="medium"
                                        message="Please reach out to your dedicated Customer Engagement Specialist or Client Partner to create a Custom Audience!"
                                    />
                                </Box>}
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Box mb={2.5} mt={5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <SearchInput placeholder="Filter Audiences" onChange={handleSearch} />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort.value}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <AudienceTable
                    audiences={sortedAudiences}
                    cardSize="large"
                    activeAudience={activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            segment={segment}
                            active={segment.id === activeAudience}
                            organizationId={organizationId}
                        />
                    )}
                />
            </Container>
        </Box>
    );
};

export default PDSCustomLayout2;

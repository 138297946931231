import _ from 'lodash';

import validate from 'utils/validate';
import { getDraftValidators, MIN_VALID_DRAFT } from './services';
import {
    calculateMediaCost,
    calculateBidPrice,
    calculatePlatformTechFee,
} from 'states/resources/ads/business-logic';
import { getEnvironmentSettings } from 'services/environment';
import flags from 'containers/flags/service';

export const NAME = 'predictionEditor';

export function getDefaultState() {
    return {
        isLoading: true,
        isLoadingGeoData: true,
        showErrors: false,
        isUpdating: true,
        orgTechFee: 0,
        orgFtaFee: 0,
        totalFirstPartyDataFee: 0,
        platformTechFee: 0,
        audiencesMapping: {},
        audienceRates: {},
        draft: MIN_VALID_DRAFT(),
    };
}

const middleware = [
    {
        description: 'Initialize start',
        target(state, action) {
            return 'PREDICTION_EDITOR__INITIALIZE_DRAFT__START' === action.type;
        },
        reduce() {
            return {
                ...getDefaultState(),
                isLoading: true,
            };
        },
    },
    {
        description: 'Initialize geo data start',
        target(state, action) {
            return 'PREDICTION_EDITOR__INITIALIZE_GEO_DATA__START' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isLoadingGeoData: true,
            };
        },
    },
    {
        description: 'Initialize geo data success',
        target(state, action) {
            return 'PREDICTION_EDITOR__INITIALIZE_GEO_DATA__SUCCESS' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isLoadingGeoData: false,
            };
        },
    },
    {
        description: 'Initialize start success',
        target(state, action) {
            return 'PREDICTION_EDITOR__INITIALIZE_DRAFT__SUCCESS' === action.type;
        },
        reduce(state, action) {
            const { audiences, organization, applists } = action.payload;

            const audiencesMapping = {};
            _.each(audiences, audience => (audiencesMapping[audience.id] = audience));

            const audienceRatesMapping = {};
            _.each(organization.audience_rates, rate => {
                audienceRatesMapping[rate.name] = rate.fee;
            });

            const country = getEnvironmentSettings().getInventoryForecastCountryCode();

            const overrides = {
                geotargets: [{ country, include: [], exclude: [] }],
            };

            if (flags.isEnabled('en_4869_inventory_predictor_v4')) {
                overrides.target_device_os = [];
            }

            const draft = MIN_VALID_DRAFT(overrides);

            return {
                ...state,
                audiencesMapping,
                audienceRatesMapping,
                orgFtaFee: organization.fta_fee,
                orgTechFee: organization.tech_fee,
                orgName: organization.name,
                organization,
                applists,
                draft,
                isLoading: false,
            };
        },
    },
    {
        description: 'Enable FTA',
        target(state, action) {
            return 'PREDICTION_EDITOR__ENABLE_FTA' === action.type;
        },
        reduce(state) {
            const nextDraft = {
                ...state.draft,
                ftaEnabled: true,
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Disable FTA',
        target(state, action) {
            return 'PREDICTION_EDITOR__DISABLE_FTA' === action.type;
        },
        reduce(state) {
            const nextDraft = {
                ...state.draft,
                ftaEnabled: false,
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Update draft',
        target(state, action) {
            return 'PREDICTION_EDITOR__UPDATE_DRAFT' === action.type;
        },
        reduce(state, action) {
            let nextDraft = { ...state.draft, ...action.payload };

            return {
                ...state,
                draft: nextDraft,
                isUpdating: true,
            };
        },
    },
    {
        description: 'Validate draft',
        target(state, action) {
            return 'PREDICTION_EDITOR__VALIDATE_FORM' === action.type;
        },
        reduce(state) {
            const errors = validate(
                state.draft,
                getDraftValidators({ audiencesMapping: state.audiencesMapping })
            );

            return {
                ...state,
                errors,
                showErrors: errors.length,
            };
        },
    },
    {
        description: 'Derive data cost from FTA and Audience Segments',
        target(state, action) {
            return /^PREDICTION_EDITOR__/.test(action.type);
        },
        reduce(state) {
            const {
                audiencesMapping,
                audienceRatesMapping,
                orgFtaFee,
                draft: { ftaEnabled },
            } = state;

            let totalFirstPartyDataFee = 0;

            if (ftaEnabled) {
                totalFirstPartyDataFee += orgFtaFee;
            }

            let audiences = [];
            _.each(state.draft.tactics_generators, tactic => {
                _.each(tactic.targeting, target => {
                    if (target.module === 'audience_segments') {
                        audiences = audiences.concat(
                            target.draft.audiences,
                            target.draft.audience_exclude
                        );
                    }
                });
            });

            const audienceFee = _(audiences)
                .filter(x => x)
                .map(audienceId => {
                    const sourceType = _.get(audiencesMapping, [audienceId, 'source_type']);
                    const rate = _.get(audienceRatesMapping, [sourceType], 0);

                    return rate;
                })
                .max();

            totalFirstPartyDataFee += audienceFee || 0;

            return {
                ...state,
                totalFirstPartyDataFee,
            };
        },
    },
    {
        description: 'Calculate bid price',
        target(state, action) {
            return /^PREDICTION_EDITOR__/.test(action.type);
        },
        reduce(state) {
            const {
                orgTechFee,
                totalFirstPartyDataFee,
                draft: { max_cpm_rate_local: totalSpend, totalThirdPartyFees },
            } = state;

            const mediaCost = calculateMediaCost({
                maxCpmRate: totalSpend,
                thirdPartyFees: totalThirdPartyFees,
                firstPartyFees: totalFirstPartyDataFee,
            });

            const platformTechFee = calculatePlatformTechFee({
                mediaCost,
                techFee: orgTechFee,
            });

            const bidRate = calculateBidPrice({
                mediaCost,
                platformTechFee,
            });

            return {
                ...state,
                platformTechFee,
                draft: {
                    ...state.draft,
                    bidRate,
                },
            };
        },
    },
    {
        description: 'Success generating prediction',
        target(state, action) {
            return 'INVENTORY_PREDICTIONS__GENERATE_PREDICTION__COMPLETE' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isUpdating: false,
            };
        },
    },
];

export default function reducer(state = getDefaultState(), action) {
    if (!_.isString(action.type)) {
        return state;
    }

    let nextState = state;

    _.each(middleware, mw => {
        if (mw.target(nextState, action)) {
            nextState = mw.reduce(nextState, action);
        }
    });

    return nextState;
}

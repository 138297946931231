import _ from 'lodash';

export default function selector(storeState) {
    const processedAds = _.get(storeState, 'mediaPlanForm.processedAds', {});
    const activePlatformFilter = _.get(storeState, 'mediaPlanForm.activePlatformFilter');

    return {
        processedAds,
        activePlatformFilter,
    };
}

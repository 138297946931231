import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import selector from './selector';
import { browserHistory } from 'react-router';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import {
    deleteCreative,
    closeDeleteConfirmModal,
} from 'pages/campaigns/creatives/creatives-overview/actions';
import CampaignOverviewCreativesLayoutV2 from './creatives-overview-layout-v2';
import CreativeDeleteConfirmModal from './modules/creative-delete-confirmation';
import { useCampaignOverviewCreatives } from './v2/hook.js';
import flags from 'containers/flags/service';

const CampaignOverviewCreatives = createReactClass({
    displayName: 'CampaignOverviewCreatives',

    statics: {
        refresh(dispatch, nextState, campaignOverviewCreatives) {
            const campaignId = _.get(nextState, 'params.campaignId', void 0);
            const query = _.get(nextState, 'location.query', {});
            campaignOverviewCreatives.initCreativesOverview({ campaignId });

            if (query.search) {
                campaignOverviewCreatives.filterCreativeList2({
                    campaignId,
                    filterType: 'search',
                    value: query.search,
                });
            }
        },
    },

    init() {
        const { dispatch, location, params, routes, campaignOverviewCreatives } = this.props;
        const nextState = { location, params, routes };

        CampaignOverviewCreatives.refresh(dispatch, nextState, campaignOverviewCreatives);
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        if (this.props.params.campaignId !== prevProps.params.campaignId) {
            this.init();
        }
    },

    deleteCreative(campaignId, creativeId) {
        this.props.dispatch(deleteCreative(campaignId, creativeId));
    },

    openDeleteConfirmModal(campaignId, creativeId) {
        this.props.dispatch(openDeleteConfirmModal(campaignId, creativeId));
    },

    duplicateCreative(campaignId, creativeId) {
        browserHistory.push(`/campaigns/${campaignId}/setup/creatives/${creativeId}/duplicate`);
    },

    handleCancel() {
        const { dispatch } = this.props;
        dispatch(closeDeleteConfirmModal());
    },

    getArchiveMessages() {
        const {
            creativeArchiveMessage,
            creativesV2,
            creativeToArchive,
        } = this.props.campaignOverviewCreatives;
        const { connectedAds } = creativeArchiveMessage;

        const creative = _.find(creativesV2, creative => creative.id === creativeToArchive);
        const creativeName = creative ? creative.name : null;

        if (connectedAds.length === 0) {
            return (
                <div>
                    <Box mb={1}>
                        <Typography variant="subtitle1">
                            {' '}
                            # {creativeToArchive} {creativeName}
                        </Typography>
                    </Box>
                    <Typography>
                        {' '}
                        This creative will be permanently archived and can no longer be edited or
                        assigned to a live ad.
                    </Typography>
                </div>
            );
        } else {
            return (
                <div>
                    <Box mb={1}>
                        <Alert icon={false} severity="info">
                            <Typography variant="subtitle1">
                                {' '}
                                # {creativeToArchive} {creativeName}
                            </Typography>
                        </Alert>
                    </Box>
                    <Typography>
                        {' '}
                        Creative must be unassigned from following ads before archiving:
                    </Typography>
                    <Box mb={1}>
                        {_.map(connectedAds, ad => {
                            return (
                                <Typography key={ad.id} variant="subtitle1">
                                    # {ad.id}
                                </Typography>
                            );
                        })}
                    </Box>
                </div>
            );
        }
    },

    render() {
        const {
            campaignId,
            campaign,
            workspace,
            organization,
            isDeleteConfirmModalOpen,
            deleteConfirmModalMessage,
            canDeleteCreative,
            creativeToDelete,
        } = this.props;
        const {
            isArchiveConfirmModalOpen,
            openArchiveConfirmModal,
            openBulkArchiveConfirmModal,
            creativeArchiveMessage,
            creativeToArchive,
            closeArchiveConfirmModal,
            confirmArchiveCreative,
            toggleArchivedAdVisibility,
            shouldShowArchivedCreatives,
            creativesV2,
            filterCreativeList2,
            searchQuery2,
            setSelectedRows,
            selectedRows,
        } = this.props.campaignOverviewCreatives;
        return (
            <React.Fragment>
                <CampaignOverviewCreativesLayoutV2
                    dispatch={this.props.dispatch}
                    location={this.props.location}
                    creatives={creativesV2}
                    campaignId={campaignId}
                    searchQuery={searchQuery2}
                    campaign={campaign}
                    deleteCreative={this.deleteCreative}
                    duplicateCreative={this.duplicateCreative}
                    workspace={workspace}
                    organization={organization}
                    openArchiveConfirmModal={openArchiveConfirmModal}
                    toggleArchivedAdVisibility={toggleArchivedAdVisibility}
                    shouldShowArchivedCreatives={shouldShowArchivedCreatives}
                    filterCreativeList2={filterCreativeList2}
                    openBulkArchiveConfirmModal={openBulkArchiveConfirmModal}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                />
                <CreativeDeleteConfirmModal
                    isOpen={isDeleteConfirmModalOpen}
                    message={deleteConfirmModalMessage}
                    canDelete={canDeleteCreative}
                    onCancel={this.handleCancel}
                    onConfirm={this.deleteCreative}
                    creativeToDelete={creativeToDelete}
                    campaignId={campaignId}
                />
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={isArchiveConfirmModalOpen}
                    onClose={closeArchiveConfirmModal}
                >
                    <DialogTitle>{creativeArchiveMessage.title}</DialogTitle>
                    <DialogContent> {this.getArchiveMessages()} </DialogContent>
                    <DialogActions>
                        {' '}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const actionPayload = { campaignId, creativeToArchive };
                                creativeArchiveMessage.canArchiveCreative
                                    ? confirmArchiveCreative(actionPayload)
                                    : closeArchiveConfirmModal();
                            }}
                        >
                            {creativeArchiveMessage.canArchiveCreative ? 'Archive' : 'OK'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    },
});

function CampaignOverviewCreativesWrapper(props) {
    const campaignOverviewCreatives = useCampaignOverviewCreatives();

    return (
        <CampaignOverviewCreatives
            {...props}
            campaignOverviewCreatives={campaignOverviewCreatives}
        />
    );
}

export default connect(selector)(CampaignOverviewCreativesWrapper);

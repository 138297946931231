import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';

import Sidebar from '../../../containers/audience-sidebar';
import Layout from '../layout';
import AudienceHeader from '../modules/audience-header';

import actions from './actions';
import selector from './selector';
import moment from 'moment';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import { hasMatchInformation, isAudienceActive } from 'states/resources/audiences/business-logic';
import { platformMapping } from 'common/constants/audiences';
import { isInternalUser } from 'states/profile/business-rules';

import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import StandardInput from 'widgets-v5/standard-input';
import Spacer from 'widgets-v5/spacer';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { Caption } from 'widgets-v5/typography';
import Chip from '@mui/material/Chip';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const useStyles = makeStyles(theme => ({
    chip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    platformCell: {
        display: 'flex',
    },
}));

const RetailDashboard = ({ dispatch, audienceSegments }) => {
    const classes = useStyles();

    useEffect(() => {
        dispatch(actions.getAudiences());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    const sortedAudiences = _.orderBy(audienceSegments, ['name'], ['asc']);

    return (
        <Layout
            sidebar={<Sidebar />}
            header={
                <React.Fragment>
                    <AudienceHeader
                        title="Location Categories"
                        description="Target users who request top locations from select categories."
                        category="Precise Geolocation"
                        categoryIcon="fa fa-map-marker"
                        categoryDescription="Segments created using first-party SDK location data, points of interest database from Cleanlist, and hand-drawn building footprints — for the highest precision and accuracy."
                        imgUrl="/images/audience-segments/audience-segment__precise-ocation-cat.png"
                    />
                    <StandardInput placeholder="Filter audience" onChange={onChange} />
                    <Spacer />
                </React.Fragment>
            }
            body={
                <React.Fragment>
                    <BlockLoadGroup isLoading={!audienceSegments}>
                        <ComposableDataTable>
                            <Row header>
                                <Cell header>Name</Cell>
                                <Cell header>Platform(s)</Cell>
                                <Cell align="right" header>
                                    Number of POIs
                                </Cell>
                                <Cell header align="right">
                                    Number of Devices
                                </Cell>
                                <Cell header>Last Refreshed</Cell>
                                <Cell align="right" header>
                                    Data Cost
                                </Cell>
                            </Row>
                            {sortedAudiences &&
                                sortedAudiences.map(segment => (
                                    <Row key={segment.id}>
                                        <Cell>{segment.name}</Cell>
                                        <Cell>
                                            <div className={classes.platformCell}>
                                                {segment.supports.length > 0
                                                    ? _.map(segment.supports, support => (
                                                          <Chip
                                                              key={support}
                                                              label={platformMapping[support]}
                                                              size="small"
                                                              className={classes.chip}
                                                              variant="outlined"
                                                          />
                                                      ))
                                                    : 'N/A'}
                                            </div>
                                        </Cell>
                                        <Cell align="right">
                                            {segment.poiCount !== null
                                                ? formatNumber_whole(segment.poiCount)
                                                : 'N/A'}
                                        </Cell>
                                        <Cell align="right">
                                            {segment.uniques !== null ? (
                                                <span>{formatNumber_whole(segment.uniques)}</span>
                                            ) : (
                                                <span>N/A</span>
                                            )}
                                        </Cell>
                                        <Cell>
                                            {segment.lastRefreshed === null ? (
                                                <span>N/A</span>
                                            ) : (
                                                <InlineTooltip
                                                    tooltip={moment(segment.lastRefreshed).format(
                                                        'MMM Do YYYY'
                                                    )}
                                                    position="bottom"
                                                >
                                                    {_.capitalize(
                                                        moment(segment.lastRefreshed).fromNow()
                                                    )}
                                                </InlineTooltip>
                                            )}
                                            <Spacer type="x-small" />
                                            <Caption type="info">
                                                {hasMatchInformation(segment) &&
                                                isAudienceActive(segment) ? (
                                                    <span>
                                                        Refreshed Regularly
                                                        <i className="fa fa-fw fa-refresh audiences-dashboard__reports-table-matched-users-active" />
                                                    </span>
                                                ) : (
                                                    'Completed'
                                                )}
                                            </Caption>
                                        </Cell>
                                        <Cell
                                            align="right"
                                            className="audiences__table-cell--nowrap"
                                        >
                                            {segment.dataCost !== null ? (
                                                <span>
                                                    {formatNumber_currency(segment.dataCost)} CPM
                                                </span>
                                            ) : (
                                                <span>N/A</span>
                                            )}
                                        </Cell>
                                    </Row>
                                ))}
                        </ComposableDataTable>
                    </BlockLoadGroup>
                    {audienceSegments && audienceSegments.length === 0 && (
                        <div className="audiences__error">No Audiences Found</div>
                    )}
                </React.Fragment>
            }
        />
    );
};

export default connect(selector)(RetailDashboard);

import React, { useState, useMemo, useEffect } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Icon from '@mdi/react';
import { mdiBullseye } from '@mdi/js';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';

import { AudienceTable } from '../modules/audience-table';
import { AudienceCard } from '../modules/audience-card';
import { useOverallTableDashboardData } from '../overall-dashboard/v2/hook';
import RetargetingModal from './modules';
import flags from 'containers/flags/service';

export const retargetingOptionsMapping = {
    impression: {
        label: 'Impressions',
        color: '#FFCE50',
    },
    click: {
        label: 'Clicks',
        color: '#FFB0C1',
    },
    conversion: {
        label: 'Creative engagements',
        color: '#6ADF7E',
    },
    attribution: {
        label: 'Campaign-driven conversions',
        color: '#9FDCFD',
    },
    pixel: {
        label: 'All site visits or conversions',
        color: '#B1B1C7',
    },
};

const subCategoryColor = {
    Impressions: '#FFCE50',
    Clicks: '#FFB0C1',
    'Creative engagements': '#6ADF7E',
    attribution: '#9FDCFD',
    'All site visits or conversions': '#B1B1C7',
};

const tabValueMapping = {
    all: {
        value: ['impression', 'click', 'conversion', 'attribution', 'pixel'],
        label: 'All',
    },
    impression: {
        value: ['impression'],
        label: 'Impressions',
    },
    click: {
        value: ['click'],
        label: 'Clicks',
    },
    conversion: {
        value: ['conversion'],
        label: 'Creative engagements',
    },
    attribution: {
        value: ['attribution'],
        label: 'Campaign-driven conversions',
    },
    pixel: {
        value: ['pixel'],
        label: 'All site visits or conversions',
    },
};

const tabValueMappingV2 = {
    all: {
        value: [
            'Impressions',
            'Clicks',
            'Creative engagements',
            'Campaign-driven conversions',
            'All site visits or conversions',
        ],
        label: 'All',
    },
    impression: {
        value: ['Impressions'],
        label: 'Impressions',
    },
    click: {
        value: ['Clicks'],
        label: 'Clicks',
    },
    conversion: {
        value: ['Creative engagements'],
        label: 'Creative engagements',
    },
    attribution: {
        value: ['Campaign-driven conversions'],
        label: 'Campaign-driven conversions',
    },
    pixel: {
        value: ['All site visits or conversions'],
        label: 'All site visits or conversions',
    },
};

const getTabs = ({ audiences }) => {
    const tabs = [
        {
            value: 'all',
            label: 'All',
            avatar: <Avatar>{audiences && audiences.length}</Avatar>,
        },
        {
            value: 'impression',
            label: 'Impressions',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Impressions';
                            } else {
                                return audience.retargeting_type === 'impression';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'click',
            label: 'Clicks',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Clicks';
                            } else {
                                return audience.retargeting_type === 'Clicks';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'conversion',
            label: 'Creative engagements',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Creative engagements';
                            } else {
                                return audience.retargeting_type === 'conversion';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'attribution',
            label: 'Campaign-driven conversions',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Campaign-driven conversions';
                            } else {
                                return audience.retargeting_type === 'attribution';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'pixel',
            label: 'Pixels',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Pixels';
                            } else {
                                return audience.retargeting_type === 'pixel';
                            }
                        }).length}
                </Avatar>
            ),
        },
    ];

    return tabs;
};

const sortingOptions = [
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
];

const useStyles = makeStyles(() => ({
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#098679',
    },
}));

const RetargetingLayout = ({
    audienceSegments,
    onSearch,
    openModal,
    download,
    isSegmentDownloading,
    totalSegmentCount,
    activeAudience,
    organizationId,
}) => {
    const classes = useStyles();
    const { canEditAudiences } = useOverallTableDashboardData();
    const [audiences, setAudiences] = useState(audienceSegments);
    const [activeTab, setActiveTab] = useState('all');
    const [activeSort, setActiveSort] = useState({ value: '_created', direction: 'desc' });

    useEffect(() => {
        setAudiences(audienceSegments);
    }, [audienceSegments]);

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleChangeTab = newValue => {
        setActiveTab(newValue);
    };

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const handleSortChange = event => {
        const { value, direction } = _.find(sortingOptions, { value: event.target.value });
        setActiveSort({ value, direction });
    };

    const sortedAudiences = _.orderBy(audiences, [activeSort.value], [activeSort.direction]);

    const filteredAudiences = _.filter(sortedAudiences, audience => {
        if (flags.isEnabled('en_2987_use_audience_category_and_subCategory_code')) {
            const activeSubCategories = tabValueMappingV2[activeTab].value;
            return _.includes(activeSubCategories, audience.subCategory);
        }
        const activeSourceTypes = tabValueMapping[activeTab].value;
        return _.includes(activeSourceTypes, audience.retargeting_type);
    });

    const tabs = getTabs({ audiences: audienceSegments });

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'custom' },
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Your Custom Audiences
                </RouterLink>
                <Typography color="textPrimary">Retargeting</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <Icon path={mdiBullseye} size={3} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            Retargeting
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            Target users who have seen or interacted with an earlier ad, or
                            performed an action that fired a conversion pixel.
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total
                                </Typography>
                                <Typography variant="h6">{totalSegmentCount}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {canEditAudiences && (
                                <Box mb={2} mt={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => openModal(null)}
                                    >
                                        <AddIcon /> New Audience
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={5} mb={1.5}>
                    <Grid container spacing={1}>
                        {_.map(tabs, option => (
                            <Grid item key={option.value}>
                                <Chip
                                    label={option.label}
                                    avatar={option.avatar}
                                    color={activeTab === option.value ? 'primary' : 'default'}
                                    onClick={() => handleChangeTab(option.value)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box mb={2.5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <SearchInput
                                        placeholder="Filter Audiences"
                                        onChange={handleSearch}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort.value}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={0.5} mb={2} />
                <AudienceTable
                    audiences={filteredAudiences}
                    cardSize="large"
                    activeAudience={activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            segment={segment}
                            onClick={() => openModal(segment.id)}
                            categoryLabel={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? segment.subCategory
                                    : retargetingOptionsMapping[segment.retargeting_type].label
                            }
                            categoryColor={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? subCategoryColor[segment.subCategory]
                                    : retargetingOptionsMapping[segment.retargeting_type].color
                            }
                            onDownload={download}
                            isSegmentDownloading={isSegmentDownloading(segment)}
                            active={segment.id === activeAudience}
                            organizationId={organizationId}
                        />
                    )}
                />
                <RetargetingModal />
            </Container>
        </Box>
    );
};

export default RetargetingLayout;

import React from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { ConfirmDialog } from 'widgets-v6/modal';

const useStyles = makeStyles(theme => ({
    confirmMessage: {
        whiteSpace: 'pre-line',
    },
}));

const CreativeEditConfirmation = ({ isOpen, onConfirm, onCancel, message }) => {
    const classes = useStyles();
    return (
        <ConfirmDialog
            isOpen={isOpen}
            onCancel={onCancel}
            title="Modify Creative?"
            content={
                <React.Fragment>
                    <span className={classes.confirmMessage}>{message}</span>
                </React.Fragment>
            }
            onConfirm={onConfirm}
        />
    );
};

export default CreativeEditConfirmation;

import _ from 'lodash';
import richMediaSchema from './schemas/rich-media';
import standardSchema from './schemas/standard';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import {
    getTypeSizes,
    getAllViablePlatforms,
    getValidImageSizeMapping,
    DEFAULT_RENDER_DELAY,
} from 'forms/creative-form/constants';
import { getEnvironmentSettings } from 'services/environment';
import { securePartnerDomainsAndReplaceUrlMacros } from 'widgets-v5/url-standard-input';
import {
    processMacrosForThirdPartyPixels,
    processMacrosForCustomHtml,
} from './services/process-macros';

const options = {
    allowUnknown: true,
    abortEarly: false,
};

const typeSizes = getTypeSizes();

const getType = size => {
    const type = _.get(_.find(typeSizes, entry => entry.size === size), 'type');

    return type;
};

const processErrors = errors => {
    if (!errors.error) {
        return {};
    }

    const result = {};

    _.each(errors.error.details, err => {
        const { path, message } = err;
        result[path.join('.')] = err.type === 'any.custom' ? err.context.error.message : message;
    });
    return result;
};

export const validateDraft = (draft, type = 'standard') => {
    const errors =
        type === 'custom_html'
            ? richMediaSchema.validate(draft, options)
            : standardSchema.validate(draft, options);
    const processedErrors = processErrors(errors);

    return processedErrors;
};

const getNextErrorState = ({ drafts, existingErrors, format }) => {
    let nextErrors = { ...existingErrors };
    _.forEach(Object.keys(drafts), fileId => {
        const error = validateDraft(drafts[fileId], format);
        if (Object.keys(error).length > 0) {
            nextErrors = {
                ...nextErrors,
                [fileId]: error,
            };
        } else {
            nextErrors = _.omit(nextErrors, fileId);
        }
    });

    return nextErrors;
};

export const NAME = 'bulkUploadCreativesForm';

const initialState = {
    campaignId: '',
    isOpen: false,
    errors: {},
    format: 'standard',
    creativeVendor: '',
    draft: {},
    showErrors: false,
    loadingCreativeAssets: {},
    isSubmitting: false,
    imageGenerationErrors: {},
    specifiedThirdPartyVendors: [],
    failedAsset: [],
    showCreativeVendorError: false,
    version: 0,
    campaignType: CampaignTypeMapping.Standard,
};

const handlers = {
    CREATIVE_BULK_UPLOAD_FORM__OPEN_FORM: (state, action) => {
        return {
            ...state,
            isOpen: true,
            campaignId: action.payload.campaignId,
            format: action.payload.format,
            campaignType: action.payload.campaignType,
        };
    },

    CREATIVE_BULK_UPLOAD_FORM__CLOSE_FORM: state => {
        return {
            ...initialState,
            version: state.version + 1,
        };
    },
    CREATIVE_BULK_UPLOAD__UPDATE_DRAFT: (state, action) => {
        const { creativeKey, field, value } = action.payload;

        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                [field]: value,
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__CHANGE_CLICKTHROUGH_URL: (state, action) => {
        const { creativeKey, value } = action.payload;
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                clickthrough_url: value,
            },
        };
        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__TOGGLE_CLICKTHROUGH_URL: (state, action) => {
        const { checked, creativeKey } = action.payload;
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                third_party_clickthrough: checked,
                clickthrough_url: checked ? state.draft[creativeKey].clickthrough_url : '',
            },
        };
        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__CHANGE_CREATIVE_VENDOR: (state, action) => {
        const { value } = action.payload;

        return {
            ...state,
            creativeVendor: value,
        };
    },
    CREATIVE_BULK_UPLOAD__CHANGE_PLATFORM: (state, action) => {
        const { creativeKey, value, checked } = action.payload;
        const creativeDraft = _.get(state, `draft.${creativeKey}`);
        const existingPlatforms = creativeDraft.platforms;
        let nextPlatforms;
        if (checked) {
            nextPlatforms = _.concat(existingPlatforms, value);
        } else {
            nextPlatforms = _.filter(existingPlatforms, platform => platform !== value);
        }
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                platforms: nextPlatforms,
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__ADD_THIRD_PARTY_PIXEL: (state, action) => {
        const { creativeKey } = action.payload;
        const creativeDraft = _.get(state, `draft.${creativeKey}`);
        const existingPixels = creativeDraft.third_party_pixels;
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                third_party_pixels: _.concat(existingPixels, ''),
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__REMOVE_THIRD_PARTY_PIXEL: (state, action) => {
        const { creativeKey, index } = action.payload;
        const creativeDraft = _.get(state, `draft.${creativeKey}`);
        const existingPixels = creativeDraft.third_party_pixels;
        const newPixels = _.filter(existingPixels, (value, key) => key !== index);
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                third_party_pixels: newPixels,
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__CHANGE_THIRD_PARTY_PIXEL: (state, action) => {
        const { creativeKey, index, value } = action.payload;
        const creativeDraft = _.get(state, `draft.${creativeKey}`);
        const existingPixels = creativeDraft.third_party_pixels;
        const newPixels = _.map(existingPixels, (pixel, key) => {
            if (key === index) {
                return value;
            }
            return pixel;
        });
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                third_party_pixels: newPixels,
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__ADD_THIRD_PARTY_JS_PIXEL: (state, action) => {
        const { creativeKey } = action.payload;
        const creativeDraft = _.get(state, `draft.${creativeKey}`);
        const existingPixels = creativeDraft.third_party_javascript_urls;
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                third_party_javascript_urls: _.concat(existingPixels, ''),
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__REMOVE_THIRD_PARTY_JS_PIXEL: (state, action) => {
        const { creativeKey, index } = action.payload;
        const creativeDraft = _.get(state, `draft.${creativeKey}`);
        const existingPixels = creativeDraft.third_party_javascript_urls;
        const newPixels = _.filter(existingPixels, (value, key) => key !== index);
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                third_party_javascript_urls: newPixels,
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__CHANGE_THIRD_PARTY_JS_PIXEL: (state, action) => {
        const { creativeKey, index, value } = action.payload;
        const creativeDraft = _.get(state, `draft.${creativeKey}`);
        const existingPixels = creativeDraft.third_party_javascript_urls;
        const newPixels = _.map(existingPixels, (pixel, key) => {
            if (key === index) {
                return value;
            }
            return pixel;
        });
        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                third_party_javascript_urls: newPixels,
            },
        };

        return {
            ...state,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__CHANGE_IMAGE__START: (state, action) => {
        const { creativeKey } = action.payload;
        return {
            ...state,
            loadingCreativeAssets: {
                ...state.loadingCreativeAssets,
                [creativeKey]: true,
            },
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__CHANGE_IMAGE__SUCCESS: (state, action) => {
        const { file, creativeKey } = action.payload;
        const size = getValidImageSizeMapping()[`${file.width}x${file.height}`];
        const type = getType(size);
        const newCreative =
            state.draft[creativeKey].format === 'standard'
                ? {
                      ...state.draft[creativeKey],
                      image_asset: file.id,
                      image_url: file.url,
                      image_preview_url: file.preview_url,
                      size,
                      type,
                  }
                : {
                      ...state.draft[creativeKey],
                      reference_image_asset: file.id,
                      reference_image_url: file.url,
                      reference_image_preview_url: file.preview_url,
                      size,
                      type,
                  };
        const newDraft = {
            ...state.draft,
            [creativeKey]: newCreative,
        };
        return {
            ...state,
            draft: newDraft,
            loadingCreativeAssets: {
                ...state.loadingCreativeAssets,
                [creativeKey]: false,
            },
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__CHANGE_IMAGE__ERROR: (state, action) => {
        const { creativeKey } = action.payload;

        return {
            ...state,
            loadingCreativeAssets: {
                ...state.loadingCreativeAssets,
                [creativeKey]: false,
            },
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT: state => {
        return {
            ...state,
            loadingAssets: true,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUCCESS: (state, action) => {
        const { file, creativePlatformRestrictions } = action.payload;
        const size = getValidImageSizeMapping()[`${file.width}x${file.height}`];
        const format = 'standard';
        const type = getType(size);
        const campaignType = state.campaignType;
        const platforms = getAllViablePlatforms({
            type,
            size,
            format,
            placements: [],
            campaignType,
        });

        const allowedPlatforms = _.filter(
            platforms,
            platform => !_.includes(creativePlatformRestrictions, platform)
        );

        const draft = {
            name: file.name,
            format,
            size,
            image_asset: file.id,
            image_url: file.url,
            image_preview_url: file.preview_url,
            third_party_pixels: [],
            third_party_clickthrough: false,
            clickthrough_url: '',
            landing_page: '',
            third_party_vendors: ['engagefront__tag'],
            platforms: allowedPlatforms,
            type,
            assetSelectionMethod: 'autoGeneratedScreenshot',
            pending_variants: campaignType === CampaignTypeMapping.DOOH,
        };

        return {
            ...state,
            loadingAssets: false,
            creatives: {
                ...state.creatives,
                [file.id]: file,
            },
            draft: {
                ...state.draft,
                [file.id]: draft,
            },
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT_FAIL: (state, action) => {
        const payloadFiles = action.payload.assets;
        const assets = {
            ...state.assets,
            ...payloadFiles,
        };

        return {
            ...state,
            assets,
            loadingAssets: false,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT__PROGRESS: (state, action) => {
        const payloadFiles = action.payload.assets;
        const assets = {
            ...state.assets,
            ...payloadFiles,
        };

        return {
            ...state,
            assets,
            loadingAssets: true,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__SUBMIT__PROGRESS_COMPLETE: (state, action) => {
        const payloadFiles = action.payload.assets;
        const assets = {
            ...state.assets,
            ...payloadFiles,
        };

        return {
            ...state,
            assets,
            loadingAssets: false,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__FILE_UPLOAD__CLEAR_TEMP: (state, action) => {
        const assetToRemove = action.payload.asset;
        const filteredAssets = _.omit(state.assets, assetToRemove);
        const newState = {
            ...state,
            assets: filteredAssets,
        };

        return newState;
    },
    CREATIVE_BULK_UPLOAD_FORM__SHOW_ERRORS: state => {
        return {
            ...state,
            showErrors: true,
            isSubmitting: false,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__SUBMIT_START: state => {
        return {
            ...state,
            isSubmitting: true,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__SUBMIT_SUCCESS: state => {
        return {
            ...state,
            isSubmitting: false,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__SUBMIT_FAIL: state => {
        return {
            ...state,
            isSubmitting: false,
        };
    },
    CREATIVE_BULK_UPLOAD__DELETE_CREATIVE: (state, action) => {
        const { creativeKey } = action.payload;
        const newDraft = _.omit(state.draft, creativeKey);
        const newErrors = _.omit(state.errors, creativeKey);

        return {
            ...state,
            draft: newDraft,
            errors: newErrors,
        };
    },
    CREATIVE_BULK_UPLOAD__DUPLICATE_CREATIVE: (state, action) => {
        const { creativeKey } = action.payload;
        const duplicateCreativeKey = _.uniqueId(creativeKey);
        const duplicateDraft = {
            ...state.draft[creativeKey],
            name: `${state.draft[creativeKey].name} (copy)`,
        };

        return {
            ...state,
            draft: {
                ...state.draft,
                [duplicateCreativeKey]: duplicateDraft,
            },
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__GENERATE_IMAGE_START: (state, action) => {
        const { creativeKey } = action.payload;
        return {
            ...state,
            loadingCreativeAssets: {
                ...state.loadingCreativeAssets,
                [creativeKey]: true,
            },
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__GENERATE_IMAGE_SUCCESS: (state, action) => {
        const { file, creativeKey } = action.payload;
        const newDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                reference_image_asset: file.id,
                reference_image_url: file.url,
                reference_image_preview_url: file.preview_url,
            },
        };

        const newImageGenerationErrors = _.omit(state.imageGenerationErrors, creativeKey);

        return {
            ...state,
            draft: newDraft,
            loadingCreativeAssets: {
                ...state.loadingCreativeAssets,
                [creativeKey]: false,
            },
            imageGenerationErrors: newImageGenerationErrors,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__GENERATE_IMAGE_ERROR: (state, action) => {
        const { creativeKey } = action.payload;
        return {
            ...state,
            imageGenerationErrors: {
                ...state.imageGenerationErrors,
                [creativeKey]: true,
            },
            loadingCreativeAssets: {
                ...state.loadingCreativeAssets,
                [creativeKey]: false,
            },
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__TOGGLE_MRAID: (state, action) => {
        const { checked, creativeKey } = action.payload;
        const creative = state.draft[creativeKey];
        const newFormat = checked ? 'mraid' : 'custom_html';
        const campaignType = state.campaignType;

        const platforms = getAllViablePlatforms({
            type: creative.type,
            size: creative.size,
            format: newFormat,
            placements: [],
            campaignType,
        });
        const newDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                format: newFormat,
                platforms,
            },
        };
        return {
            ...state,
            draft: newDraft,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__PROCESS_TEMPLATE__SUCCESS: (state, action) => {
        const { tags, creativePlatformRestrictions } = action.payload;
        const useJobForBulkCreativeScreenshots = getEnvironmentSettings().useJobForBulkCreativeScreenshots();
        const baseUrl = getEnvironmentSettings().conversionDomain;
        const tempScheenshotImage = `${baseUrl}/images/EngageFront-logo.png`;

        const processedTags = {};
        _.forEach(tags, tag => {
            const size = getValidImageSizeMapping()[`${tag.size}`];
            const type = getType(size);
            const campaignType = state.campaignType;

            const platforms = getAllViablePlatforms({
                type,
                size,
                format: tag.format || 'custom_html',
                placements: [],
                campaignType,
            });
            const allowedPlatforms = _.filter(
                platforms,
                platform => !_.includes(creativePlatformRestrictions, platform)
            );
            processedTags[tag.creativeKey] = {
                ..._.omit(tag, 'creativeKey'),
                format: tag.format || 'custom_html',
                content_html: processMacrosForCustomHtml({ value: tag.content_html }),
                size,
                type,
                platforms: allowedPlatforms,
                third_party_pixels: tag.third_party_pixels
                    ? processMacrosForThirdPartyPixels(tag.third_party_pixels)
                    : [],
                third_party_clickthrough: tag.clickthrough_url ? true : false,
                clickthrough_url: tag.clickthrough_url
                    ? securePartnerDomainsAndReplaceUrlMacros(tag.clickthrough_url)
                    : '',
                landing_page: tag.landing_page || '',
                renderDelay: DEFAULT_RENDER_DELAY,
                assetSelectionMethod: useJobForBulkCreativeScreenshots
                    ? 'inputByUrl'
                    : 'autoGeneratedScreenshot',
                image_preview_url: useJobForBulkCreativeScreenshots ? tempScheenshotImage : '',
                reference_image_preview_url: useJobForBulkCreativeScreenshots
                    ? tempScheenshotImage
                    : '',
                has_screenshot: !useJobForBulkCreativeScreenshots,
            };
        });

        const newDraft = {
            ...state.draft,
            ...processedTags,
        };

        return {
            ...state,
            draft: newDraft,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__SHOW_LINE_ERRORS: (state, action) => {
        const { lineErrors } = action.payload;
        return {
            ...state,
            lineSizeErrors: lineErrors,
        };
    },
    CREATIVE_BULK_UPLOAD__UPDATE_SIZE: (state, action) => {
        const { size, creativeKey } = action.payload;
        const newLineSizeErrors = _.omit(state.lineSizeErrors, creativeKey);

        const type = getType(size);

        const nextDraft = {
            ...state.draft,
            [creativeKey]: {
                ...state.draft[creativeKey],
                size,
                type,
            },
        };

        return {
            ...state,
            lineSizeErrors: newLineSizeErrors,
            draft: nextDraft,
        };
    },
    CREATIVE_BULK_UPLOAD__SPECIFY_THIRD_PARTY_VENDORS: (state, action) => {
        const { value } = action.payload;

        return {
            ...state,
            specifiedThirdPartyVendors: value,
            showCreativeVendorError: false,
        };
    },
    CREATIVE_BULK_UPLOAD__SET_FILE_TYPE_ERROR: (state, action) => {
        const payloadFiles = action.payload.files;
        const failedAssets = _.map(payloadFiles, file => {
            return {
                name: file.name,
                reason: 'Invalid File Type',
            };
        });

        return {
            ...state,
            failedAssets,
        };
    },
    CREATIVE_BULK_UPLOAD_FORM__SHOW_CREATIVE_VENDOR_ERROR: state => {
        return {
            ...state,
            showCreativeVendorError: true,
            isSubmitting: false,
        };
    },
};

export default function(state = initialState, action) {
    let nextState = state;
    let nextErrors = {};
    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
        const drafts = nextState.draft;

        nextErrors = getNextErrorState({
            drafts,
            existingErrors: nextState.errors,
            format: nextState.format,
        });
    }

    return {
        ...nextState,
        errors: nextErrors,
    };
}

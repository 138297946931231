import _ from 'lodash';

import flags from 'containers/flags/service';
import validate, { isOptional, isRequired, isString, isArray } from 'utils/validate';

import { regEx } from 'utils/regular-expression-library';
const urlValidationRegExp = regEx.url;

export const thirdPartyVastValidators = {
    hosted_vast_url(value, source) {
        if (!value) {
            return 'Hosted VAST URL is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Hosted VAST URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    reference_screenshot(value, source) {
        if (!value) {
            return;
        }

        if (!urlValidationRegExp.test(value)) {
            return `Reference screenshot should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    video_duration(value, source) {
        if (!value) {
            return 'Duration is required.';
        }

        if (!_.isNumber(value)) {
            return `Duration must be a number.`;
        }

        if (value > 30) {
            return `Duration can be between 1 and 30 seconds long.`;
        }
    },
    video_bit_rate_kbps(value, source) {
        if (!value) {
            return;
        }

        if (!_.isNumber(value)) {
            return 'Bit rate must be a number';
        }
    },
    landing_page(value, source) {
        if (!value) {
            return 'Landing page is required.';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Landing page should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_pixels(value, source) {
        if (!value) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_pixels to be a list');
        }

        const allValid = _.every(value, pixel => urlValidationRegExp.test(pixel));

        if (!allValid) {
            return `Third Party Pixels should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_quartile_pixels(value, source) {
        if (!value) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_quartile_pixels to be a list');
        }

        const allValid = _.every(
            value,
            pixel => pixel.event && urlValidationRegExp.test(pixel.url)
        );

        if (!allValid) {
            return 'Third Party Quartile Pixels are missing event(s) or invalid URL(s)';
        }
    },
    creative_vendors(value, source) {
        if (!value.length) {
            return 'At least one creative vendor must be specified';
        }
    },
    placements(placements, source) {
        if (placements.length === 0) {
            return 'Placement is required';
        }
    },
};

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import memoizeOne from 'memoize-one';

import PrefixSuffixInput from 'widgets-v5/prefix-suffix-input';
import StandardInput from 'widgets-v5/standard-input';
import FormField from 'widgets-v5/form-field';
import Spacer from 'widgets-v5/spacer';

import classnames from 'classnames';

import { RemoveLayerButton } from 'forms/ad-form/modules/points-of-interest';

import GeoFencingLayerFinder from '../geo-fencing-layer-finder-media-plan';

import geoLayersSearch from 'services/gis/geo-layers-search';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const MIDDLE_EAST_CATEGORY_ID = '15';

const getSubCategoryMapping = memoizeOne(geoCategories => {
    const mapping = {};

    _.each(geoCategories, category => {
        _.each(category.subcategories, subCategory => {
            mapping[subCategory.id] = subCategory;
        });
    });

    return mapping;
});

const getLayerMapping = memoizeOne(geoLayers => {
    const mapping = {};

    _.each(geoLayers, layer => {
        mapping[layer.id] = layer;
    });

    return mapping;
});

const mapSubCategoryValuesToCategories = memoizeOne((subCategoryValues, subCategoryMapping) => {
    return _.map(subCategoryValues, value => subCategoryMapping[value]).filter(x => x);
});

const mapCustomLayerValuesToLayers = memoizeOne((customLayerValues, layerMapping) => {
    return _.map(customLayerValues, value => layerMapping[value]).filter(x => x);
});

const mapStandardLayerValuesToLayers = memoizeOne((standardLayerValues, layerMapping) => {
    return _.map(standardLayerValues, value => layerMapping[value]).filter(x => x);
});

const getSelectedMiddleEastSegments = memoizeOne(({ middleEastCategory }) => {
    const middleEastSubCategories = _.map(
        middleEastCategory.subcategories,
        subCategory => subCategory
    );
    const middleEastSubCategoryIds = _.map(
        middleEastCategory.subcategories,
        subCategory => subCategory.id
    );
    const middleEastLayerIds = _(middleEastSubCategories)
        .map(subCategory => subCategory.layers)
        .flatten()
        .map(layer => layer.id)
        .value();

    return _.concat(middleEastSubCategoryIds, middleEastLayerIds);
});

class Poi extends React.Component {
    static propTypes = {
        geo_targeting_settings: PropTypes.shape({
            categories: PropTypes.arrayOf(PropTypes.string),
            category_layers: PropTypes.arrayOf(PropTypes.string),
            // custom_layers: React.PropTypes.arrayOf(React.PropTypes.string),
            days_to_retarget: PropTypes.number,
            target: PropTypes.bool,
            retarget: PropTypes.bool,
        }),
        geoResources: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        isPoiRecategorized: PropTypes.bool,
    };

    static defaultProps = {
        isPoiRecategorized: false,
    };

    constructor(props) {
        super(props);

        this.search = _.debounce(this.search, 1000);

        this.handleSelectGeoCategory = this.handleSelectGeoCategory.bind(this);
        this.handleSelectGeoLayer = this.handleSelectGeoLayer.bind(this);

        this.handleSetTargetAndRetarget = this.handleSetTargetAndRetarget.bind(this);
        this.handleDays_to_retargetChange = this.handleDays_to_retargetChange.bind(this);

        this._cached = {};

        if (this.props.geo_targeting_settings.days_to_retarget) {
            this._cached.firstValues = {
                days_to_retarget: this.props.geo_targeting_settings.days_to_retarget,
            };
        }

        const filteredGeoCategories = _.filter(
            this.props.geoResources.geoCategories,
            geoCategories => geoCategories.id !== MIDDLE_EAST_CATEGORY_ID
        );
        const initialGeoResources = {
            ...this.props.geoResources,
            geoCategories: filteredGeoCategories,
        };

        this.state = {
            searchString: '',
            geoResources: initialGeoResources,
            filteredResources: initialGeoResources,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            !this.props.geo_targeting_settings.days_to_retarget &&
            nextProps.geo_targeting_settings.days_to_retarget
        ) {
            this._cached.firstValues = {
                days_to_retarget: nextProps.geo_targeting_settings.days_to_retarget,
            };
        }

        if (nextProps.geoResources.geoLayers.length !== this.state.geoResources.geoLayers.length) {
            this.setState({
                geoResources: nextProps.geoResources,
                filteredResources: geoLayersSearch(this.state.searchString, nextProps.geoResources),
            });
        }
    }

    handleSelectGeoLayer(layerId) {
        const geo_targeting_settings__next = _.cloneDeep(this.props.geo_targeting_settings);
        const custom_layers = _.get(geo_targeting_settings__next, `custom_layers`, void 0);

        _.includes(custom_layers, layerId)
            ? _.remove(custom_layers, id => id === layerId)
            : custom_layers.push(layerId);
        this.props.onChange(geo_targeting_settings__next);
    }

    handleSelectGeoCategory(categoryId, tag) {
        const geo_targeting_settings__next = _.cloneDeep(this.props.geo_targeting_settings);

        // component can pass a tag to signify a subcategory was selected
        if (tag) {
            // is category_layers
            const category_layers = _.get(geo_targeting_settings__next, `category_layers`, void 0);
            _.includes(category_layers, categoryId)
                ? _.remove(category_layers, id => id === categoryId)
                : category_layers.push(categoryId);
        } else {
            // is categories
            const categories = _.get(geo_targeting_settings__next, `categories`, void 0);
            _.includes(categories, categoryId)
                ? _.remove(categories, id => id === categoryId)
                : categories.push(categoryId);
        }
        this.props.onChange(geo_targeting_settings__next);
    }

    handleSetTargetAndRetarget(buttonClicked) {
        const geo_targeting_settings__prev = _.cloneDeep(this.props.geo_targeting_settings);
        let geo_targeting_settings__next;
        switch (buttonClicked) {
            case 'target':
                geo_targeting_settings__next = {
                    ...geo_targeting_settings__prev,
                    target: true,
                    retarget: false,
                    days_to_retarget: this._cached.firstValues.days_to_retarget, // revert to first value
                };
                break;
            case 'retarget':
                geo_targeting_settings__next = {
                    ...geo_targeting_settings__prev,
                    target: false,
                    retarget: true,
                };
                break;
            case 'both':
                geo_targeting_settings__next = {
                    ...geo_targeting_settings__prev,
                    target: true,
                    retarget: true,
                };
                break;
        }
        this.props.onChange(geo_targeting_settings__next);
        // this.setState({geo_targeting_settings: geo_targeting_settings__next});
    }

    handleDays_to_retargetChange(days_to_retarget__nxt) {
        const geo_targeting_settings__prev = _.cloneDeep(this.props.geo_targeting_settings);
        const geo_targeting_settings__next = {
            ...geo_targeting_settings__prev,
            days_to_retarget: days_to_retarget__nxt,
        };
        this.props.onChange(geo_targeting_settings__next);
    }

    changePointRadius(radius) {
        // original in modules/GeoFenching-v2.js
        const { geo_targeting_settings } = this.props;
        const currentRadius = geo_targeting_settings.radius;
        if (currentRadius === radius) {
            return;
        }
        // onChange('radius')(parseInt(radius, 10));
        const geo_targeting_settings__next = {
            ...geo_targeting_settings,
            radius: parseInt(radius),
        };
        this.props.onChange(geo_targeting_settings__next);
    }

    onQueryChange = searchString => {
        this.setState(
            {
                searchString,
            },
            this.search
        );
    };

    search = () => {
        this.setState({
            filteredResources: geoLayersSearch(this.state.searchString, this.state.geoResources),
        });
    };

    render() {
        let geoLayersForThisOrg = [];
        if (this.props.geoLayersForThisOrg) {
            geoLayersForThisOrg = this.props.geoLayersForThisOrg;
        } else if (this.props.organizationId) {
            geoLayersForThisOrg = _.filter(
                this.state.filteredResources.geoLayers,
                layer => layer.organization === this.props.organizationId
            );
        }

        const subCategoryMapping = getSubCategoryMapping(this.props.geoResources.geoCategories);
        const layerMapping = getLayerMapping(this.props.geoResources.geoLayers);

        const selectedSubCategories = mapSubCategoryValuesToCategories(
            this.props.geo_targeting_settings.categories,
            subCategoryMapping
        );

        const selectedCustomLayers = mapCustomLayerValuesToLayers(
            this.props.geo_targeting_settings.custom_layers,
            layerMapping
        );

        const selectedStandardLayers = mapStandardLayerValuesToLayers(
            this.props.geo_targeting_settings.category_layers,
            layerMapping
        );

        const allMiddleEastSegments = getSelectedMiddleEastSegments({
            middleEastCategory: _.find(
                this.props.geoResources.geoCategories,
                category => category.id === MIDDLE_EAST_CATEGORY_ID
            ),
        });

        return (
            <FormField
                label="Flex Geolocation Audiences"
                isBetaFeature={false}
                description=""
                isRequired={false}
            >
                <div className="ef3-geofencing_mapWrapper_buttonGroup_targeting">
                    <button
                        onClick={() => this.handleSetTargetAndRetarget('target')}
                        className={classnames(
                            'btn',
                            'btn-secondary',
                            'btn-sm',
                            'targetting-poi_targetingModeSelectorButton',
                            {
                                'is-active':
                                    this.props.geo_targeting_settings.target &&
                                    !this.props.geo_targeting_settings.retarget,
                            }
                        )}
                    >
                        Target
                    </button>
                    <button
                        onClick={() => this.handleSetTargetAndRetarget('retarget')}
                        className={classnames(
                            'btn',
                            'btn-secondary',
                            'btn-sm',
                            'targetting-poi_targetingModeSelectorButton',
                            {
                                'is-active':
                                    this.props.geo_targeting_settings.retarget &&
                                    !this.props.geo_targeting_settings.target,
                            }
                        )}
                    >
                        Retarget
                    </button>
                    <button
                        onClick={() => this.handleSetTargetAndRetarget('both')}
                        className={classnames(
                            'btn',
                            'btn-secondary',
                            'btn-sm',
                            'targetting-poi_targetingModeSelectorButton',
                            {
                                'is-active':
                                    this.props.geo_targeting_settings.target &&
                                    this.props.geo_targeting_settings.retarget,
                            }
                        )}
                    >
                        Both
                    </button>
                </div>
                <div className="ef3-geofencing_mapWrapper_retargetDays">
                    {this.props.geo_targeting_settings.retarget ? (
                        <div>
                            <div className="ef3-geofencing_mapWrapper_retargetDays_info">
                                Lookback Window (max 365):
                            </div>
                            <PrefixSuffixInput
                                value={this.props.geo_targeting_settings.days_to_retarget}
                                placeholder="0"
                                shouldAllowInput={input => {
                                    const inputAsInt = Number(input);
                                    return !isNaN(inputAsInt) && inputAsInt <= 365;
                                }}
                                onChange={value => {
                                    this.handleDays_to_retargetChange(Number(value));
                                }}
                                suffix="days"
                            />
                        </div>
                    ) : null}
                </div>
                <hr />
                <div className="ef6-alignment__flex">
                    <div className="ef5-poi-layer__finder">
                        <StandardInput
                            style={{ boxSizing: 'inherit' }}
                            placeholder="Search Flex Geolocation Audiences"
                            type="search"
                            onChange={this.onQueryChange}
                            value={this.state.searchString}
                        />
                        <div className="ef3-geofencing_mapWrapper_poiLayerFinder">
                            <GeoFencingLayerFinder
                                handle_addCustomLayer={() => {}}
                                toggleAddLayerModal={() => {}}
                                editLayer={() => {}}
                                layers={geoLayersForThisOrg}
                                categories={this.state.filteredResources.geoCategories}
                                selectGeoLayer={this.handleSelectGeoLayer}
                                selectGeoCategory={this.handleSelectGeoCategory}
                                includedCustomLayers={
                                    this.props.geo_targeting_settings.custom_layers
                                }
                                includedCategories={this.props.geo_targeting_settings.categories}
                                includedCategoryLayers={
                                    this.props.geo_targeting_settings.category_layers
                                }
                            />
                        </div>
                    </div>
                    <div className="ef5-poi-layer__selected-pois">
                        <div>Selected Audiences</div>
                        <Spacer />
                        {_.map(selectedSubCategories, category => {
                            return (
                                <div
                                    className="ef5-poi-layer__poi-item"
                                    key={category.id}
                                    title={category.name}
                                >
                                    <div className="icon">
                                        <i className="fa fa-fw fa-folder-open" />
                                    </div>
                                    <div className="name">{category.name}</div>
                                    <div className="remove ef5-interactive-area">
                                        {_.includes(allMiddleEastSegments, category.id) ? (
                                            <Tooltip
                                                title={
                                                    <Typography variant="body2">
                                                        Once you remove this segment you cannot add
                                                        it back
                                                    </Typography>
                                                }
                                            >
                                                <i
                                                    className="fa fa-fw fa-close"
                                                    onClick={() => {
                                                        this.handleSelectGeoCategory(category.id);
                                                    }}
                                                />
                                            </Tooltip>
                                        ) : (
                                            <i
                                                className="fa fa-fw fa-close"
                                                onClick={() => {
                                                    this.handleSelectGeoCategory(category.id);
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        {_.map(selectedStandardLayers, sub => {
                            return (
                                <div
                                    className="ef5-poi-layer__poi-item"
                                    key={sub.id}
                                    title={sub.name}
                                >
                                    <div className="icon">
                                        <i className="fa fa-fw fa-clone" />
                                    </div>
                                    <div className="name">{sub.name}</div>
                                    <div className="remove ef5-interactive-area">
                                        <RemoveLayerButton
                                            isMiddleEastSegment={_.includes(
                                                allMiddleEastSegments,
                                                sub.id
                                            )}
                                            isPoiRecategorized={this.props.isPoiRecategorized}
                                            onRemove={() => {
                                                this.handleSelectGeoCategory(sub.id, 'sub');
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {_.map(selectedCustomLayers, layer => {
                            return (
                                <div
                                    className="ef5-poi-layer__poi-item"
                                    key={layer.id}
                                    title={layer.name}
                                >
                                    <div className="icon">
                                        <i className="fa fa-fw fa-clone" />
                                    </div>
                                    <div className="name">{layer.name}</div>
                                    <div className="remove ef5-interactive-area">
                                        <RemoveLayerButton
                                            isMiddleEastSegment={_.includes(
                                                allMiddleEastSegments,
                                                layer.id
                                            )}
                                            isPoiRecategorized={this.props.isPoiRecategorized}
                                            onRemove={() => {
                                                this.handleSelectGeoLayer(layer.id);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </FormField>
        );
    }
}
export { Poi };

import _ from 'lodash';
import { browserHistory } from 'react-router';

import c from 'common/constants/flux-events';
import API from 'services/api';
import qs from 'utils/querystring';

import { fetchCampaign } from 'states/resources/campaigns/actions';

export function filterFileList(campaignId, filterOptions) {
    return (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW_FILES__INIT,
            payload: {
                campaignId,
            },
        });

        const pathname = window.location.pathname;

        const store_filterOptions = _.get(
            getState(),
            `campaigns.overview.${campaignId}.files.pagination.filterOptions`
        );
        const query = {
            ...store_filterOptions,
            [filterOptions.filterType]: filterOptions.value,
        };

        browserHistory.push(`${pathname}?${qs.serialize(query)}`);

        dispatch({
            type: c.OVERVIEW_FILES__FILE_LIST__FILTER,
            payload: {
                campaignId,
                filterType: filterOptions.filterType,
                value: filterOptions.value,
            },
        });
    };
}

export function showFileTypeError(files) {
    return {
        type: 'SYSTEM__CAMPAIGN_FILES__SET_FILE_TYPE_ERROR',
        payload: {
            files,
        },
    };
}

import _ from 'lodash';
import { doesHaveSetupWarnings } from 'pages/campaigns/ads/ads-overview/selector';

export default function selector(storeState, props) {
    const isCrossPlatformCampaign = _.get(props, 'campaign.isCrossPlatformCampaign');
    if (!isCrossPlatformCampaign) {
        return {
            hasAdSetupWarning: false,
        };
    }

    const audiences = _.get(storeState, 'audienceSegmentPicker.audienceSegments', {});
    const flexSegments = _.get(storeState, 'audienceSegmentPicker.flexSegments', {});

    const audienceMapping = {};
    const flexSegmentMapping = {};

    _.forEach(audiences, segment => {
        audienceMapping[segment.id] = segment;
    });

    _.forEach(flexSegments, segment => {
        flexSegmentMapping[segment.id] = segment;
    });

    const creativesResource = _.get(storeState, `resources.creatives`, {});

    const creativeAttributeMapping = {};

    _.forEach(creativesResource, creative => {
        creativeAttributeMapping[creative.id] = creative.attr;
    });

    const ad = _.get(props, 'ad');

    const hasAdSetupWarning = doesHaveSetupWarnings({
        ad,
        creativeMapping: creativeAttributeMapping,
        audienceMapping,
        flexSegmentMapping,
    });

    return {
        hasAdSetupWarning,
    };
}

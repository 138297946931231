import React from 'react';
import numeral from 'numeral';

import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { formatNumber_currency } from 'utils/formatting';

const useStyles = makeStyles(() => ({
    table: {
        '& > thead > tr > th': {
            border: 'none',
            verticalAlign: 'middle',
        },
        '& > tbody > tr > td': {
            border: 'none',
            verticalAlign: 'middle',
        },
    },
}));

const BillingTiers = ({
    tiers,
    onAddBillingTier,
    onDeleteBillingTier,
    onEditBillingTierMax,
    onEditBillingTierFee,
    canEditRestrictedFields,
    showErrors,
    isEditingSelf,
    errors,
    type,
}) => {
    const classes = useStyles();
    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>Min</TableCell>
                    <TableCell>Max</TableCell>
                    <TableCell>Platform Fee</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {_.map(tiers, (tier, index) => {
                    return (
                        <TableRow key={`${tier.id}_row`}>
                            <TableCell>
                                <Typography>{formatNumber_currency(tier.min)}</Typography>
                            </TableCell>
                            <TableCell>
                                <TextFieldFormatter
                                    initialValue={tier.max}
                                    key={`${tier.id}_max_${tier.min}_${index}_${tiers.length}`}
                                    onChange={value =>
                                        onEditBillingTierMax({ value, tierId: tier.id })
                                    }
                                    shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                    formatIn={v => {
                                        if (tier.max === -1) {
                                            return 'Infinity';
                                        }
                                        return _.isNil(v) ? '' : numeral(v).format('0,0.00');
                                    }}
                                    formatOut={v => {
                                        if (v) {
                                            return numeral(v).value();
                                        } else if (v === '') {
                                            return null;
                                        } else {
                                            return 0;
                                        }
                                    }}
                                    renderInput={({ value, onChange }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            required
                                            disabled={
                                                !canEditRestrictedFields ||
                                                tier.max === -1 ||
                                                isEditingSelf
                                            }
                                            helperText={
                                                showErrors && _.get(errors, `${type}.${index}.max`)
                                            }
                                            error={
                                                showErrors && _.get(errors, `${type}.${index}.max`)
                                            }
                                            startAdornment="$"
                                            disableMargin
                                        />
                                    )}
                                />
                            </TableCell>
                            <TableCell>
                                <TextFieldFormatter
                                    initialValue={tier.techFee}
                                    onChange={value =>
                                        onEditBillingTierFee({ value, tierId: tier.id })
                                    }
                                    shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                    formatIn={v => {
                                        return _.isNil(v)
                                            ? ''
                                            : numeral(v)
                                                  .multiply(100)
                                                  .format('0.00');
                                    }}
                                    formatOut={v => {
                                        if (v) {
                                            return numeral(v).value() / 100;
                                        } else if (v === '') {
                                            return null;
                                        } else {
                                            return 0;
                                        }
                                    }}
                                    renderInput={({ value, onChange }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            required
                                            disabled={!canEditRestrictedFields || isEditingSelf}
                                            helperText={
                                                showErrors &&
                                                _.get(errors, `${type}.${index}.techFee`)
                                            }
                                            error={
                                                showErrors &&
                                                _.get(errors, `${type}.${index}.techFee`)
                                            }
                                            endAdornment="%"
                                            disableMargin
                                        />
                                    )}
                                />
                            </TableCell>
                            <TableCell>
                                {tiers.length > 1 && (
                                    <IconButton
                                        fontSize="small"
                                        onClick={() => onDeleteBillingTier(tier.id)}
                                        disabled={isEditingSelf}
                                        size="large"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
                {!isEditingSelf && (
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Button variant="contained" color="primary" onClick={onAddBillingTier}>
                                Add a Tier
                            </Button>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default BillingTiers;

import _ from 'lodash';
import VError from 'verror';

import { createHttp } from 'utils/http';
import notify from 'utils/notify';
import conversionActions from 'states/resources/conversions/actions';

import draftToPayload from './services/draft-to-payload';

const actions = {
    init() {
        return async (dispatch, getState) => {
            try {
                dispatch(actions.initStart());

                const data = await fetchPageData();

                dispatch(actions.initSuccess(data));
            } catch (error) {
                notify({ error: new VError(error, 'failed to fetch Conversion Dashboard data') });

                dispatch(actions.initError(error));
            }
        };
    },
    initStart(data) {
        return { type: 'CONVERSIONS_DASHBOARD:INIT:START' };
    },
    initSuccess(data) {
        return {
            type: 'CONVERSIONS_DASHBOARD:INIT:SUCCESS',
            payload: { data },
        };
    },
    initError(error) {
        return {
            type: 'CONVERSIONS_DASHBOARD:INIT:ERROR',
            error,
        };
    },
    openForm(conversion) {
        return {
            type: 'CONVERSIONS_DASHBOARD:FORM:OPEN',
            payload: {
                conversion,
            },
        };
    },
    closeForm() {
        return {
            type: 'CONVERSIONS_DASHBOARD:FORM:CLOSE',
        };
    },
    viewAdvertiser(advertiser) {
        return {
            type: 'CONVERSIONS_DASHBOARD:VIEW_ADVERTISER',
            payload: { advertiser },
        };
    },
    viewAllAdvertisers() {
        return {
            type: 'CONVERSIONS_DASHBOARD:VIEW_ALL_ADVERTISERS',
        };
    },
    updateDraft(changes) {
        return {
            type: 'CONVERSIONS_DASHBOARD:FORM:UPDATE_DRAFT',
            payload: { changes },
        };
    },
    saveForm() {
        return async (dispatch, getState) => {
            const ownOrgId = _.get(getState(), 'profile.organizationId');
            try {
                const draft = _.get(getState(), 'conversionsDashboard.draft');
                const errors = _.get(getState(), 'conversionsDashboard.errors');
                const isSaving = _.get(getState(), 'conversionsDashboard.isSaving');
                const conversionToEdit = _.get(getState(), 'conversionsDashboard.conversionToEdit');

                if (isSaving) {
                    return;
                }

                dispatch(actions.saveFormStart());

                if (_.keys(errors).length > 0) {
                    dispatch(actions.saveFormError(new Error('Validation Error')));
                    return;
                }

                const payload = draftToPayload(draft);

                if (conversionToEdit) {
                    await dispatch(
                        conversionActions.updateOrganizationConversion({
                            organizationId: ownOrgId,
                            conversionId: conversionToEdit.id,
                            payload,
                        })
                    );
                } else {
                    await dispatch(
                        conversionActions.createOrganizationConversion({
                            organizationId: ownOrgId,
                            payload,
                        })
                    );
                }

                const data = await fetchPageData();

                dispatch(actions.initSuccess(data));

                dispatch(actions.saveFormSuccess());
            } catch (err) {
                notify({
                    error: new VError(err, `failed to save Conversion for Org: ${ownOrgId}`),
                });

                dispatch(actions.saveFormError(err));
            }
        };
    },
    saveFormStart() {
        return { type: 'CONVERSIONS_DASHBOARD:FORM:SAVE:START' };
    },
    saveFormSuccess() {
        return { type: 'CONVERSIONS_DASHBOARD:FORM:SAVE:SUCCESS' };
    },
    saveFormError(error) {
        return { type: 'CONVERSIONS_DASHBOARD:FORM:SAVE:ERROR', error };
    },
    deleteConversion(conversion) {
        return async (dispatch, getState) => {
            try {
                const ownOrgId = _.get(getState(), 'profile.organizationId');

                dispatch(actions.deleteConversionStart());

                await dispatch(
                    conversionActions.deleteOrganizationConversion({
                        organizationId: ownOrgId,
                        conversionId: conversion.id,
                    })
                );

                const data = await fetchPageData();

                dispatch(actions.initSuccess(data));

                dispatch(actions.deleteConversionSuccess());
            } catch (err) {
                dispatch(actions.deleteConversionError(err));
            }
        };
    },
    deleteConversionStart(conversion) {
        return { type: 'CONVERSIONS_DASHBOARD:CONVERSION:DELETE:START' };
    },
    deleteConversionSuccess() {
        return { type: 'CONVERSIONS_DASHBOARD:CONVERSION:DELETE:SUCCESS' };
    },
    deleteConversionError(error) {
        return { type: 'CONVERSIONS_DASHBOARD:CONVERSION:DELETE:ERROR', error };
    },
};

async function fetchPageData() {
    try {
        const http = createHttp();
        const query = `
            query getConversionPageData ($relationship: String, $clientType: [String]) {
                organizations(filters: { relationship: $relationship, client_type: $clientType }) {
                    id
                    name
                    client_type
                }
                ownOrganization {
                    conversions {
                        id
                        event_name
                        reporting_name
                        use_click_through
                        click_through_attribution_window
                        use_view_through
                        view_through_attribution_window
                        use_dynamic_data_subevent
                        use_dynamic_data_value
                        dynamic_data_subevent_name
                        dynamic_data_value_name
                        should_optimize_for_budget_allocation
                        advertiser {
                            id
                            name
                        }
                    }
                }
            }
        `;

        const variables = {
            relationship: 'child',
            clientType: ['advertiser'],
        };

        const data = await http.graphql(query, variables);

        const advertisers = _.filter(data.organizations, org => org.client_type === 'advertiser');

        return {
            ...data,
            organizations: advertisers,
        };
    } catch (err) {
        console.error(err);

        return [];
    }
}

export default actions;

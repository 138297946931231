// --------------
// Default values
// --------------
import { payload_formData } from './default-values';
import { payload_nonFormData } from './default-values';
import { groupValidate } from './default-values';
import { payload_updateOnly } from './default-values';
import { payload_createOnly } from './default-values';
import { payload_clientData } from './default-values';

export function getDefaults(type) {
    const defaults = {};
    defaults.payload_formData = { ...payload_formData };
    defaults.payload_nonFormData = { ...payload_nonFormData };
    defaults.groupValidate = { ...groupValidate };
    defaults.payload_updateOnly = { ...payload_updateOnly };
    defaults.payload_createOnly = { ...payload_createOnly };
    defaults.payload_clientData = { ...payload_clientData };
    return { ...defaults[type] };
}

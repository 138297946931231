import _ from 'lodash';

export default function selector(storeState, props) {
    const { adId, campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const isLoading = _.get(storeState, `campaignsOverview.isLoading`, true);
    const ownerName = _.get(storeState, `adsOverview.ownerName`);

    if (!campaign) {
        return {
            campaignId,
            adId,
            ownerName: ownerName,
        };
    }

    return { adId, campaignId };
}

function reduceErrorList(errorList = []) {
    return errorList.reduce((accumulator, error) => {
        accumulator[error.field] = accumulator[error.field] || [];
        accumulator[error.field].push(error.message);
        return accumulator;
    }, {});
}

function getImplicitLayer(storeState, campaignId, adId) {
    const geoLayers_super = _.get(storeState, `resources.geoCategories.geoLayers`, void 0);
    const form = _.get(storeState, `adEditForm.form`, {});
    const customLayers_draft = _.get(form, 'draft.geo_targeting_settings.custom_layers', void 0);

    const implicitLayer = _(geoLayers_super)
        .filter(itm => {
            return itm.name === '___implicit' && _.includes(customLayers_draft, itm.id);
        })
        .value()[0];

    return implicitLayer;
}

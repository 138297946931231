import toastr from 'toastr';
import { createHttp } from 'utils/http';

const http = createHttp();
const actions = {
    fetCategories: keywords => {
        return async (dispatch, getState) => {
            const query = `
                query getPoiCategories($keywords: String) {
                    flexCategories(filters: { keywords: $keywords }) {
                        name
                        id
                        points
                        brandsCount
                    }
                }`;
            const variables = {
                keywords,
            };

            try {
                dispatch({
                    type: 'FLEX_CATEGORIES_DASHBOARD__FETCH_CATEGORIES_DATA_START',
                    payload: {},
                });
                const { flexCategories } = await http.graphql(query, variables);
                dispatch({
                    type: 'FLEX_CATEGORIES_DASHBOARD__FETCH_CATEGORIES_DATA_END',
                    payload: {
                        data: flexCategories,
                    },
                });
            } catch (error) {
                console.log(error);
                dispatch({
                    type: 'FLEX_CATEGORIES_DASHBOARD__FETCH_CATEGORIES_DATA_ERROR',
                    payload: {},
                });
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

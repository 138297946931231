import _ from 'lodash';

import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import API from 'services/api';

const http = createHttp();
import Campaigns from 'states/resources/campaigns/actions';
import Organizations from 'states/resources/organizations/actions';
import flags from 'containers/flags/service';

import toastr from 'toastr';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

export const campaignHistory = {
    open(campaignId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.CAMPAIGN_DASHBOARD__HISTORY__OPEN,
                payload: { campaignId },
            });

            dispatch({
                type: c.CAMPAIGN_DASHBOARD__HISTORY__FETCH,
                payload: { campaignId },
            });

            Promise.all([
                dispatch(Campaigns.getHistory(campaignId)),
                fetchCampaignHistoryData(),
            ]).then(
                ([campaignHistory, data]) => {
                    dispatch({
                        type: c.CAMPAIGN_DASHBOARD__HISTORY__FETCH__SUCCESS,
                        payload: {
                            campaignId,
                            campaignHistory,
                            ...data,
                        },
                    });
                },
                error => {
                    // throw toaster error for server errors

                    dispatch({
                        type: c.CAMPAIGN_DASHBOARD__HISTORY__FETCH__FAIL,
                        payload: { campaignId },
                        error: error.body,
                    });
                }
            );
        };
    },

    close(campaignId) {
        return {
            type: c.CAMPAIGN_DASHBOARD__HISTORY__CLOSE,
            payload: {
                campaignId,
            },
        };
    },
};

async function fetchCampaignHistoryData() {
    const query = `
        query getCampaignHistoryData {
            organizations {
                id
                name
            }
            ftaLocationLists {
                id
                name
            }
        }
    `;

    try {
        const data = await http.graphql(query, {});

        return data;
    } catch (error) {
        if (error.response.status === 401) {
            return;
        }
        toastr.warning(
            '',
            '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
            TOASTR_OPTIONS
        );
        if (window.bugsnagClient) {
            window.bugsnagClient.notify(
                `Failed to fetch organization names data in Campaign History`,
                {
                    metaData: {},
                }
            );
        }
    }
}

import _ from 'lodash';
import React from 'react';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { SimpleSelect } from 'widgets-v6/select';
import { Grid } from '@mui/material';

import { isInternalUser } from 'states/profile/business-rules';
import { getCurrentFlight, getMostRecentFlight } from 'states/resources/campaigns/business-logic';
import GlobalRefresh from 'containers/global-refresh';
import { can } from 'services/can';
import CampaignSharing from 'containers/campaign-sharing';
import { PermissionsMapping } from 'common/constants/role-intents';

import FlightIndicator from 'widgets-v5/flight-indicator';
import WorkspaceTopBar from 'widgets-v5/workspace-top-bar';
import { ButtonGroup } from 'widgets-v5/buttons';
import { useCampaignPageContainer } from '../../v2';

const OVERVIEW_MENU = [
    {
        value: 'setup',
        label: 'Setup',
        link: campaignId => `/campaigns/${campaignId}/setup`,
        match: /campaigns\/.*\/setup.*/,
        requiresIntent: 'campaign__view',
        requiresCampaignAds: false,
        requiresBetaEnv: false,
    },
    {
        value: 'progress',
        label: 'Real-time Progress',
        link: campaignId => `/campaigns/${campaignId}/progress`,
        match: /campaigns\/.*\/progress.*/,
        requiresIntent: 'progress__view',
        requiresCampaignAds: true,
        requiresBetaEnv: false,
    },
    {
        value: 'analytics',
        label: 'Report',
        link: campaignId => `/campaigns/${campaignId}/report`,
        match: /campaigns\/.*\/report.*/,
        requiresIntent: 'campaign__view',
        requiresCampaignAds: true,
        requiresBetaEnv: false,
    },
    {
        value: 'client-report',
        label: 'Client Report',
        link: campaignId => `/campaigns/${campaignId}/client-report`,
        match: /campaigns\/.*\/client-report.*/,
        requiresIntent: 'report__view',
        requiresCampaignAds: true,
        requiresBetaEnv: false,
    },
];

function getMenuItems() {
    return OVERVIEW_MENU;
}

export default function CampaignHeader(props) {
    const { campaign } = props;

    const campaignPageContainer = useCampaignPageContainer();
    const selectedTab = getMenuItems().find(
        menuItem => menuItem.match && menuItem.match.test(window.location.pathname)
    );

    const visibleTabs = getMenuItems()
        .filter(menuItem => !menuItem.requiresIntent || can(menuItem.requiresIntent))
        // Eventually instead of hiding the tabs that require campaign ads, show them but grey them out
        .filter(menuItem => !menuItem.requiresCampaignAds || campaign.ads.length > 0);

    const tabs = visibleTabs.map(({ label, value, link, href }) => ({
        label,
        value,
        link: link && link(campaign.id),
        href: href && href(campaign.id),
    }));

    const flightOptions = [
        {
            value: 'campaign',
            label: 'Entire Campaign View',
        },
    ];
    if (campaignPageContainer.campaign) {
        const currentFlight = getCurrentFlight(campaignPageContainer.campaign);
        const mostRecentFlight = getMostRecentFlight(campaignPageContainer.campaign);

        _.each(campaignPageContainer.campaign.flights, flight => {
            let label = flight.name;
            if (currentFlight && currentFlight.id === flight.id) {
                label += ' (current)';
            }
            if (!currentFlight && mostRecentFlight && mostRecentFlight.id === flight.id) {
                label += ' (most recent)';
            }

            flightOptions.push({
                value: flight.id,
                label,
            });
        });
    }

    return (
        <WorkspaceTopBar
            className="ef5-campaign-overview-header"
            title={`#${campaign.id} ${campaign.name}`}
            badge={
                campaign.filterByDateRange && (
                    <div style={{ marginLeft: 10 }}>
                        <InlineTooltip
                            tooltip="Only showing impressions within flight period. Some impressions may be hidden."
                            position="bottom"
                        >
                            <i className="fa fa-filter" />
                        </InlineTooltip>
                    </div>
                )
            }
            status={<FlightIndicator position={'bottom'} label status={campaign.status} />}
            activeTab={selectedTab ? selectedTab.value : null}
            tabs={can(PermissionsMapping.CAMPAIGN__VIEW) ? tabs : []}
            rightContent={
                <Grid container direction="row">
                    {_.get(campaignPageContainer, 'campaign.flightPacingStrategy') === 'campaign' &&
                        _.get(campaignPageContainer, 'campaign.viewBy') !== null && (
                            <Grid item>
                                <SimpleSelect
                                    value={campaignPageContainer.viewBy}
                                    onChange={e => campaignPageContainer.changeView(e.target.value)}
                                    options={flightOptions}
                                    placeholder="Select Flight"
                                />
                            </Grid>
                        )}
                    <Grid item>
                        <ButtonGroup className="ef5-campaign-overview-header__button-group">
                            <GlobalRefresh />
                            {can(PermissionsMapping.ADMIN_ORG__EDIT) && isInternalUser() && (
                                <div className="ef5-campaign-overview-header__share-button">
                                    <CampaignSharing campaignId={campaign.id} />
                                </div>
                            )}
                        </ButtonGroup>
                    </Grid>
                </Grid>
            }
        />
    );
}

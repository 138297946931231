import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { any, string, func, arrayOf, shape } from 'prop-types';
import { styled } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import AutocompleteMui, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MenuItemMui from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { TextField, Box, Tab, Tabs, Paper, ClickAwayListener } from '@mui/material';

import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

const Select = styled(SelectMui)({
    '&.MuiSelect-select': {
        padding: 13,
        backgroundColor: 'white',
        borderRadius: 'inherit',
    },
});

const Autocomplete = styled(AutocompleteMui)({
    '&.MuiAutocomplete-root': {
        padding: '4.5px!important',
        backgroundColor: 'white',
    },
});

const MenuItem = styled(MenuItemMui)({
    '&.MuiMenuItem-root': {
        maxWidth: 400,
    },
});

export function SimpleSelect(props) {
    const {
        label,
        required,
        value,
        onChange,
        options,
        placeholder,
        className,
        margin,
        error,
        disabled,
    } = props;

    return (
        <div className={className}>
            <FormControl
                variant="outlined"
                fullWidth
                margin={margin}
                error={error}
                disabled={disabled ? disabled : false}
            >
                {label && (
                    <Box mb={1}>
                        <FormLabel component="legend" focused={false} required={required}>
                            {label}
                        </FormLabel>
                    </Box>
                )}
                <Select
                    id="demo-customized-select"
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        <Typography color="textSecondary">{placeholder}</Typography>
                    </MenuItem>
                    {_.map(options, option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled ? option.disabled : false}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export function CustomContentSelect(props) {
    const {
        label,
        required,
        value,
        onChange,
        options,
        placeholder,
        className,
        margin,
        error,
    } = props;

    return (
        <div className={className}>
            <FormControl variant="outlined" fullWidth margin={margin} error={error}>
                {label && (
                    <Box mb={1}>
                        <FormLabel component="legend" focused={false} required={required}>
                            {label}
                        </FormLabel>
                    </Box>
                )}
                <Select
                    id="demo-customized-select"
                    value={value ? value : ''}
                    onChange={onChange}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        {placeholder}
                    </MenuItem>
                    {_.map(options, option => (
                        <MenuItem key={option.value} value={option.value}>
                            {_.isFunction(option.content) ? option.content() : option.content}
                        </MenuItem>
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </div>
    );
}

//option.value and value must be string type
export function SingleSelect({
    value,
    options,
    onChange,
    placeholder,
    disabled,
    margin,
    error,
    label,
    required,
}) {
    const filteredOptions = _.filter(options, option => _.includes(value, option.value));
    const formattedValue =
        _.find(filteredOptions, option => option.value === value) || _.last(filteredOptions);

    return (
        <FormControl
            variant="outlined"
            fullWidth
            margin={margin}
            error={error}
            disabled={disabled ? disabled : false}
        >
            {label && (
                <FormLabel component="legend" focused={false} required={required}>
                    {label}
                </FormLabel>
            )}
            <Autocomplete
                value={formattedValue || ''}
                options={options}
                disabled={disabled}
                isOptionEqualToValue={(option, value) => {
                    if (value === '') {
                        return true;
                    }
                    return value.value === option.value;
                }}
                getOptionLabel={option => option.label || ''}
                renderOption={(props, option) => <div {...props}>{option.label}</div>}
                onChange={(event, value) => {
                    if (value !== null) {
                        onChange(value.value);
                    } else {
                        onChange(null);
                    }
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, placeholder: placeholder }}
                        variant="outlined"
                        fullWidth
                        error={error}
                    />
                )}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}

export function GroupedSingleSelect({
    options,
    placeholder,
    disabled,
    error,
    label,
    required,
    onChange,
    value,
}) {
    const formattedValue = _.find(options, option => _.includes(value, option.value));
    const newOptions = _.map(options, option => {
        const firstLetter = option.group[0].toUpperCase();
        return { firstLetter, ...option };
    });
    const optionsSorted = newOptions.sort((a, b) => {
        if (a.group) {
            return b.firstLetter.localeCompare(a.firstLetter);
        }
        return _.trim(a.label);
    });

    return (
        <div>
            <FormControl
                fullWidth
                variant="outlined"
                error={error}
                disabled={disabled ? disabled : false}
            >
                {label && (
                    <FormLabel component="legend" focused={false} required={required}>
                        {label}
                    </FormLabel>
                )}
                <Autocomplete
                    id="grouped-demo"
                    options={optionsSorted}
                    value={formattedValue}
                    groupBy={option => option.group}
                    getOptionLabel={option => option.label}
                    onChange={(event, option) => {
                        onChange(option.value);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputProps={{ ...params.inputProps, placeholder: placeholder }}
                            variant="outlined"
                            fullWidth
                            error={error}
                        />
                    )}
                />
            </FormControl>
        </div>
    );
}

export function MultiSelect({
    options,
    value,
    onChange,
    placeholder,
    required,
    error,
    errorMessage,
    disabled,
    fullWidth,
    formFullWidth = true,
    margin,
    label,
    formControlClass,
}) {
    const formattedValue = _.filter(options, option => _.includes(value, option.value));
    const isRequired = required ? required : false;
    if (isRequired && formattedValue.length < 1) {
        options.map(item => formattedValue.push(item));
    }

    return (
        <Box mt={1}>
            <FormControl
                variant="outlined"
                fullWidth={formFullWidth}
                margin={margin}
                error={error}
                className={formControlClass}
            >
                {label && (
                    <FormLabel component="legend" focused={false} required={required}>
                        {label}
                    </FormLabel>
                )}
                <Autocomplete
                    value={formattedValue}
                    multiple
                    disabled={disabled}
                    options={options}
                    disableCloseOnSelect
                    fullWidth={fullWidth}
                    getOptionLabel={option => option.label}
                    renderOption={(props, option, { selected }) => (
                        <ListItem component="div" {...props}>
                            <Checkbox
                                required={isRequired}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                color="primary"
                            />
                            <ListItemText primary={option.label} secondary={option.description} />
                        </ListItem>
                    )}
                    onChange={(event, selectedOptions) => {
                        const values = _.map(selectedOptions, option => option.value);
                        onChange(values);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputProps={{ ...params.inputProps, placeholder: placeholder }}
                            variant="outlined"
                            fullWidth
                            error={error}
                        />
                    )}
                />
                {error && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        </Box>
    );
}

export function GroupedMultiSelect({
    options,
    value,
    onChange,
    placeholder,
    required,
    error,
    disabled,
}) {
    const formattedValue = _.filter(options, option => _.includes(value, option.value));
    const isRequired = required ? required : false;
    if (isRequired && formattedValue.length < 1) {
        _.each(options, item => formattedValue.push(item));
    }

    const optionsSorted = _.sortBy(options, option => {
        if (option.group) {
            return option.group;
        }

        return _.trim(option.label);
    });

    const filterOptions = createFilterOptions({
        stringify: option => `${option.label}${option.key}`,
    });

    return (
        <Box mt={1}>
            <Autocomplete
                value={formattedValue}
                multiple
                disabled={disabled}
                disableCloseOnSelect
                options={optionsSorted}
                filterOptions={filterOptions}
                groupBy={option => option.group}
                getOptionLabel={option => option.label}
                renderOption={(props, option, { selected }) => (
                    <ListItem {...props}>
                        <Checkbox
                            required={isRequired}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            color="primary"
                        />
                        <ListItemText primary={option.label} secondary={option.description} />
                    </ListItem>
                )}
                onChange={(event, selectedOptions) => {
                    const values = _.map(selectedOptions, option => option.value);
                    onChange(values);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, placeholder: placeholder }}
                        variant="outlined"
                        fullWidth
                        error={error}
                    />
                )}
            />
        </Box>
    );
}

export function GroupedMultiSelectTabs({
    options,
    value,
    onChange,
    placeholder,
    required,
    error,
    disabled,
    tabs,
    defaultTab,
    tabField,
}) {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [open, setOpen] = useState(false);
    const listBoxRef = useRef(null); // Reference for listbox to manage scroll position
    const scrollPositionRef = useRef(0); // Keep track of the scroll position

    const formattedValue = _.filter(options, option => _.includes(value, option.value));

    const isRequired = required ? required : false;
    if (isRequired && formattedValue.length < 1) {
        _.each(options, item => formattedValue.push(item));
    }

    const optionsSorted = _.sortBy(options, option => {
        if (option.group) {
            return option.group;
        }

        return _.trim(option.label);
    });

    useEffect(() => {
        if (listBoxRef.current) {
            listBoxRef.current.scrollTop = scrollPositionRef.current;
        }
    }, [optionsSorted]);

    const filterOptions = createFilterOptions({
        stringify: option => `${option.label}${option.key}`,
    });

    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(false);
            }}
        >
            <Box mt={1}>
                <Autocomplete
                    value={formattedValue}
                    multiple
                    open={open}
                    disabled={disabled}
                    disableCloseOnSelect
                    options={optionsSorted.filter(option => option[tabField] === activeTab)}
                    filterOptions={filterOptions}
                    groupBy={option => option.group}
                    getOptionLabel={option => option.label}
                    renderOption={(props, option, { selected }) => (
                        <ListItem {...props}>
                            <Checkbox
                                required={isRequired}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                color="primary"
                            />
                            <ListItemText primary={option.label} secondary={option.description} />
                        </ListItem>
                    )}
                    onChange={(event, selectedOptions) => {
                        const values = _.map(selectedOptions, option => option.value);
                        onChange(values);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputProps={{ ...params.inputProps, placeholder: placeholder }}
                            variant="outlined"
                            onClick={() => setOpen(true)}
                            fullWidth
                            error={error}
                        />
                    )}
                    ListboxComponent={listboxProps => (
                        <ul
                            {...listboxProps}
                            ref={listBoxRef}
                            onScroll={() => {
                                // Save scroll position on scroll
                                if (listBoxRef.current) {
                                    scrollPositionRef.current = listBoxRef.current.scrollTop;
                                }
                            }}
                        />
                    )}
                    PaperComponent={({ children, ...props }) => {
                        return tabs.length >= 1 ? (
                            <Paper {...props}>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => {
                                        setActiveTab(newValue);
                                    }}
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    {tabs.map(({ value, label }) => (
                                        <Tab key={value} label={label} value={value} />
                                    ))}
                                </Tabs>
                                <Box p={1}>{children}</Box>
                            </Paper>
                        ) : (
                            <Paper {...props}>{children}</Paper>
                        );
                    }}
                />
            </Box>
        </ClickAwayListener>
    );
}

MultiSelect.propTypes = {
    values: arrayOf(string),
    options: arrayOf(
        shape({
            label: string,
            value: any,
        })
    ),
    onChange: func,
};

export function OptionCreatableSelect({ options, value, onChange, placeholder, error }) {
    const filter = createFilterOptions();

    return (
        <Autocomplete
            value={value}
            onChange={(event, value) => {
                if (value !== null) {
                    onChange(value.value);
                } else {
                    onChange(null);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    const newOption = [
                        {
                            value: params.inputValue,
                            label: params.inputValue,
                        },
                    ];
                    return filtered.concat(newOption);
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.label;
            }}
            renderOption={option => option.label}
            freeSolo
            renderInput={params => (
                <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: placeholder }}
                    variant="outlined"
                    fullWidth
                    error={error}
                />
            )}
        />
    );
}

OptionCreatableSelect.propTypes = {
    value: any,
    options: arrayOf(
        shape({
            label: string,
            value: any,
        })
    ),
    onChange: func,
};

import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';

import Tabs from 'widgets-v5/tabs';

import selector from './selector';

import { getCurrentTab } from './rules';

class PresetsShell extends React.Component {
    render() {
        return (
            <div>
                <Tabs
                    align="left"
                    activeItem={getCurrentTab()}
                    items={[
                        {
                            label: 'POI Segments',
                            value: 'pois',
                            link: `/presets/pois`,
                            hidden: false,
                        },
                    ]}
                />
                {this.props.workspace}
            </div>
        );
    }
}

export default connect(selector)(PresetsShell);

import _ from 'lodash';

import validate, {
    isRequired,
    isString,
    isBoolean,
    isUnique,
    isAlphaNumericWithSpacesAndUnderscores,
} from 'utils/validate';

export function validateDraft(draft, existingNames) {
    const validators = _.pick(draftValidators(existingNames), Object.keys(draft));
    return validate(draft, validators);
}

export function createBeacon(props) {
    const defaults = {
        id: _.uniqueId(),
        name: undefined,
        label: undefined,
        hide: false,
    };

    return _.assign({}, defaults, props);
}

export function serializeForApi(draft) {
    function serializeBeacon(beacon) {
        return {
            name: beacon.name,
            label: beacon.label,
            hide: !!beacon.hide,
        };
    }

    return draft.map(serializeBeacon);
}

export function draftValidators(existingNames) {
    return {
        name: [
            isRequired('Beacon name required'),
            {
                ...isString(1),
                message: 'A value is required for this field',
                breakOnFail: true,
            },
            isAlphaNumericWithSpacesAndUnderscores(),
            isUnique(existingNames),
        ],

        label: [
            isRequired(),
            {
                ...isString(1),
                message: 'A value is required for this field',
                breakOnFail: true,
            },
        ],

        hide: [isRequired(), isBoolean()],
    };
}

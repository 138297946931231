import moment from 'moment';
import 'moment-timezone';

// Construct the request payload from the draft
export function draftToPayload(draft) {
    const { dateRange, schedule, ...rest } = _.omit(draft, 'saveAsName');

    let start;
    if (dateRange.start) {
        start = moment
            .tz(dateRange.start, draft.timezone)
            .utc()
            .format();
    } else {
        start = undefined;
    }

    let end;
    if (dateRange.end) {
        end = moment
            .tz(dateRange.end, draft.timezone)
            .utc()
            .format();
    } else {
        end = undefined;
    }

    const payload = {
        ...rest,
        dateRange: {
            ...dateRange,
            // convert tz to UTC
            start,
            end,
        },
        schedule: {
            ...schedule,
            date: schedule.date ? moment(schedule.date).format('YYYY-MM-DDTHH:mm:ss') : null,
            time: schedule.time ? moment(schedule.time).format('YYYY-MM-DDTHH:mm:ss') : null,
        },
    };

    if (payload.campaigns.ids.length > 0) {
        payload.campaigns = {
            ...payload.campaigns,
            isEnabled: true,
        };
    }

    if (payload.reportMode === 'date') {
        payload.dateRange.isEnabled = true;
    }

    if (payload.reportMode === 'adEndDate') {
        payload.adEndDate = {
            ...payload.dateRange,
            isEnabled: true,
        };
    }

    return payload;
}

export function draftToPayloadV2(draft) {
    const { dateRange, adEndDate, campaigns, organizationFilter, schedule, ...rest } = _.omit(
        draft,
        'saveAsName'
    );

    let payload = {
        ...rest,
        schedule: {
            ...schedule,
            date: schedule.date ? moment(schedule.date).format('YYYY-MM-DDTHH:mm:ss') : null,
            time: schedule.time ? moment(schedule.time).format('YYYY-MM-DDTHH:mm:ss') : null,
        },
    };

    payload.dateRange = {
        ...draft.dateRange,
        start: convertTZtoUTC(dateRange.start, draft.timezone),
        end: convertTZtoUTC(dateRange.end, draft.timezone),
    };

    payload.adEndDate = {
        ...draft.adEndDate,
        start: convertTZtoUTC(adEndDate.start, draft.timezone),
        end: convertTZtoUTC(adEndDate.end, draft.timezone),
    };

    payload.campaigns = { ...campaigns };
    payload.organizationFilter = { ...organizationFilter };

    const payloadWithoutReportMode = _.omit(payload, ['reportMode']);

    payloadWithoutReportMode.ignore_filter_by_date_range = true;

    return payloadWithoutReportMode;
}

function convertTZtoUTC(date, timezone) {
    if (!date) {
        return;
    }

    return moment(date)
        .tz(timezone)
        .format('YYYY-MM-DDTHH:mm:ss');
}

import _ from 'lodash';

export default function selector(storeState, props) {
    let shouldHideFlexSegments = _.get(
        storeState,
        `resources.campaigns.${props.campaignId}.attr.shouldHideFlexSegments`,
        false
    );

    if (props.shouldHideFlexSegments !== undefined) {
        shouldHideFlexSegments = props.shouldHideFlexSegments;
    }

    return {
        shouldHideFlexSegments,
    };
}

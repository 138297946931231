import _ from 'lodash';

import validate from 'utils/validate';
import getValidators from './services/validation-rules';
import { defaultValues, defaultsByMode } from './services/constants';

export const NAME = 'retargetingModal';

export function getDefaultValues() {
    const flagVal = { ...defaultValues, advertiser_id: '', lookback: '' };
    return flagVal;
}

export function getInitialState() {
    const initialState = {
        isInitializing: false,
        isSaving: false,
        showErrors: false,
        isEditing: false,
        fileName: undefined,
        draft: getDefaultValues(),
        errors: {},
        serverError: undefined,
        invalidLines: [],
        isModalOpen: false,
    };
    return initialState;
}

const dashboardToModeMapping = {
    uploaded: {
        mode: 'upload',
        source_type: 'upload',
    },
    retargeting: {
        mode: 'retargeting',
    },
};

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case 'RETARGETING_EDITOR__INIT_START': {
            return {
                ...getInitialState(),
                isInitializing: true,
                isModalOpen: true,
                audienceId: action.payload.audienceId,
                draft: {
                    ...getInitialState().draft,
                    ...(dashboardToModeMapping[action.payload.target] || {}),
                },
            };
        }
        case 'RETARGETING_EDITOR__INIT_END': {
            return initializeDraft(state, action);
        }
        case 'RETARGETING_EDITOR__INIT_ERROR': {
            return {
                ...state,
                isInitializing: false,
            };
        }
        case 'RETARGETING_EDITOR__SAVE_START': {
            const errors = validateDraft(state.draft, getValidators(state.isEditing));

            return {
                ...state,
                isSaving: true,
                showErrors: true,
                errors,
            };
        }
        case 'RETARGETING_EDITOR__SAVE_END': {
            return getInitialState();
        }
        case 'RETARGETING_EDITOR__SAVE_CLIENT_ERROR': {
            return {
                ...state,
                isSaving: false,
            };
        }
        case 'RETARGETING_EDITOR__SAVE_SERVER_ERROR': {
            return {
                ...state,
                isSaving: false,
                serverError: action.payload.error,
                invalidLines: action.payload.invalidLines || [],
            };
        }
        case 'RETARGETING_EDITOR__UPDATE_DRAFT': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    ...action.payload.changes,
                },
            };

            const campaignMapping = {};
            const adMapping = {};

            _.each(nextState.campaigns, campaign => {
                campaignMapping[campaign.id] = campaign;
                _.each(campaign.ads, ad => {
                    adMapping[ad.id] = ad;
                });
            });

            if (nextState.draft.source_type === 'pixel') {
                nextState.draft.supports = ['inapp', 'mweb'];
            }

            if (nextState.draft.source_type === 'ad') {
                nextState.draft.supports = _(nextState.draft.source_id)
                    .map(adId => adMapping[adId])
                    .filter(ad => ad)
                    .map(ad => ad.platforms)
                    .flatten()
                    .map(platform => {
                        return platform === 'desktop' ? 'mweb' : platform;
                    })
                    .uniq()
                    .value();
            }

            if (nextState.draft.source_type === 'campaign') {
                nextState.draft.supports = _(nextState.draft.source_id)
                    .map(campaignId => campaignMapping[campaignId])
                    .filter(campaign => campaign)
                    .map(campaign => campaign.ads)
                    .flatten()
                    .map(ad => ad.platforms)
                    .flatten()
                    .map(platform => {
                        return platform === 'desktop' ? 'mweb' : platform;
                    })
                    .uniq()
                    .value();
            }

            // When a retargeting segment is of type Campaign-driven conversions
            // we need to make sure that if the source ad/campaign supports
            // Mobile in-app then the segments should support both Mobile in-app
            // and Mobile web.
            if (
                nextState.draft.retargeting_type === 'attribution' &&
                nextState.draft.supports.includes('inapp') &&
                !nextState.draft.supports.includes('mweb')
            ) {
                nextState.draft.supports.push('mweb');
            }

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'RETARGETING_EDITOR__UPDATE_MODE': {
            const { mode } = action.payload;

            if (mode === state.draft.mode) {
                return state;
            }

            const modeToSourceType = {
                upload: 'upload',
                retargeting: 'campaign',
            };

            let defaultsForMode = defaultsByMode[mode];
            let nextState = {
                ...getInitialState(),
                draft: {
                    ...getInitialState().draft,
                    mode,
                    source_type: modeToSourceType[mode],
                    name: state.draft.name,
                    ...defaultsForMode,
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'RETARGETING_EDITOR__UPDATE_FIELD': {
            let nextState = {
                ...state,
                ...action.payload,
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'RETARGETING_EDITOR__UPDATE_RETARGETING_TYPE': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    ...applyRetargetingType(action.payload.type),
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'RETARGETING_EDITOR__TOGGLE_INCLUDE_VIEW_THROUGH_ATTRIBUTION': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    include_view_through_attribution: !state.draft.include_view_through_attribution,
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'RETARGETING_EDITOR__TOGGLE_INCLUDE_CLICK_THROUGH_ATTRIBUTION': {
            let nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    include_click_through_attribution: !state.draft
                        .include_click_through_attribution,
                },
            };

            const errors = validateDraft(nextState.draft, getValidators(state.isEditing));

            return {
                ...nextState,
                errors,
            };
        }
        case 'RETARGETING_EDITOR__OPEN_EDITOR_MODAL': {
            return {
                ...state,
                isModalOpen: true,
                audienceId: action.payload.audienceId,
            };
        }
        case 'RETARGETING_EDITOR__CLOSE_EDITOR_MODAL': {
            return {
                ...state,
                isModalOpen: false,
            };
        }
        default: {
            return state;
        }
    }
}

function initializeDraft(state, action) {
    const { audience, data } = action.payload;

    const nextState = {
        ...state,
        isInitializing: false,
        isEditing: true,
        ...data,
    };

    if (!audience) {
        return {
            ...nextState,
            isEditing: false,
        };
    }

    const rest = _.omit(audience, ['id', '_etag', '_created', '_updated']);

    return {
        ...nextState,
        draft: {
            ...state.draft,
            // merge existing audience
            ...rest,
        },
    };
}

function validateDraft(draft, validators) {
    const draftErrors = validate(draft, validators);
    const errors = indexErrors(draftErrors);

    return errors;
}

function indexErrors(errorList) {
    const errors = {};
    _.each(errorList, error => {
        errors[error.field] = [error.message];
    });
    return errors;
}

// Reset event name when not retargeting is not conversion
function applyRetargetingType(retargetingType) {
    let changes = {
        retargeting_type: retargetingType,
    };

    if (retargetingType !== 'conversion') {
        changes.event_name = undefined;
    }

    return changes;
}

import toastr from 'toastr';
import { createHttp } from 'utils/http';

const actions = {
    getCustomDimensionsPageData: id => {
        return async dispatch => {
            try {
                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__FETCH_DATA__START',
                    payload: {
                        loading: true,
                    },
                });

                const http = createHttp();
                const query = `
                    query getCustomDimensionsPageData ($id: Int) {
                        campaign(id: $id) {
                            customDimensions {
                                id
                                name
                                hidden
                                viewType
                                contentType
                                sendToFta
                                groups {
                                    id
                                    keys
                                    name
                                }
                            }
                            ads {
                                id
                                name
                                primary_pacing
                                ef_billing_terms
                                max_daily_impressions
                                max_total_impressions
                                max_total_clicks
                                max_daily_clicks
                                billable_volume
                                hidden
                                max_total_billings
                                max_daily_billings
                                max_total_spend_local
                                max_daily_spend_local
                                billing_enabled
                                filled
                            }
                            creatives {
                                id
                                name
                            }
                            primaryDimension
                            isArchived
                            budget_allocation_method
                        }

                    }
                `;

                const variables = {
                    id: parseInt(id),
                };

                const data = await http.graphql(query, variables);

                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__FETCH_DATA__END',
                    payload: {
                        data: data.campaign,
                    },
                });
            } catch (err) {
                toastr.error('Custom dimensions failed to be fetched. Please try it again later.');
            }
        };
    },

    create(campaignId) {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__START',
                    payload: {},
                });

                const http = createHttp();
                await http.post(
                    `campaigns/${campaignId}/customDimensions`,
                    getState().customDimensionsOverview.dimensionDraft
                );

                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__SUCCESS',
                    payload: {},
                });

                await dispatch(actions.getCustomDimensionsPageData(campaignId));
            } catch (error) {
                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__ERROR',
                    error,
                });
            }
        };
    },

    patch(campaignId) {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__START',
                    payload: {},
                });

                const http = createHttp();
                await http.patch(
                    `campaigns/${campaignId}/customDimensions/${
                        getState().customDimensionsOverview.activeDimension.id
                    }`,
                    getState().customDimensionsOverview.dimensionDraft
                );

                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__SUCCESS',
                    payload: {},
                });

                await dispatch(actions.getCustomDimensionsPageData(campaignId));
            } catch (error) {
                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__ERROR',
                    error,
                });
            }
        };
    },

    delete(campaignId) {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__START',
                    payload: {},
                });

                const http = createHttp();
                await http.del(
                    `campaigns/${campaignId}/customDimensions/${
                        getState().customDimensionsOverview.activeDimension.id
                    }`
                );

                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__SUCCESS',
                    payload: {},
                });

                await dispatch(actions.getCustomDimensionsPageData(campaignId));
            } catch (error) {
                dispatch({
                    type: 'CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__ERROR',
                    error,
                });
            }
        };
    },

    changeDimensionName(name) {
        return {
            type: 'CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_NAME',
            payload: {
                name,
            },
        };
    },

    changeDimensionContentType(contentType) {
        return {
            type: 'CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_CONTENTTYPE',
            payload: {
                contentType,
            },
        };
    },

    changeDimensionSendToFta(sendToFta) {
        return {
            type: 'CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_SENDTOFTA',
            payload: {
                sendToFta,
            },
        };
    },

    changeDimensionHidden(hidden) {
        return {
            type: 'CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_HIDDEN',
            payload: {
                hidden,
            },
        };
    },

    changeDimensionIsPrimaryDimension(isPrimaryDimension) {
        return {
            type: 'CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_ISPRIMARYDIMENSION',
            payload: {
                isPrimaryDimension,
            },
        };
    },

    updateDimensionDraft(dimension) {
        return {
            type: 'CUSTOM_DIMENSIONS_OVERVIEW__UPDATE_DIMENSION_DRAFT',
            payload: {
                dimension,
            },
        };
    },

    resetDimensionDraft() {
        return {
            type: 'CUSTOM_DIMENSIONS_OVERVIEW__RESET_DIMENSION_DRAFT',
            payload: {},
        };
    },
};

export default actions;

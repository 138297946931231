import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';

import { DialogTitle } from 'widgets-v6/modal';

import Layout2 from './layout2';
import selector from './selector';
import actions from './actions';

import retargetingDashboardActions from '../actions';
import { getEnvironmentSettings } from 'services/environment';
import { isInternalUser } from 'states/profile/business-rules';

const modeOptions = [
    { label: 'RETARGETING SEGMENT', value: 'retargeting' },
    { label: 'UPLOADED SEGMENT', value: 'upload' },
];

const sourceTypeOptions = [{ label: 'Campaign', value: 'campaign' }, { label: 'Ad', value: 'ad' }];

class RetargetingModal extends React.Component {
    render() {
        return <AudienceEditor {...this.props} />;
    }
}

class AudienceEditor extends React.Component {
    updateDraft = field => {
        return value => {
            this.props.dispatch(
                actions.updateDraft({
                    [field]: value,
                })
            );
        };
    };

    handleSave = async () => {
        const { audienceId } = this.props;

        await this.props.dispatch(actions.save(audienceId));
        this.props.dispatch(retargetingDashboardActions.getAudiences());
    };

    updateMode = value => {
        this.props.dispatch(actions.updateMode(value));
    };

    handleFileChange = formData => {
        const { description } = this.props.audienceEditorState.draft;
        const file = formData.get('asset');

        this.props.dispatch(actions.updateDraft({ formData }));
        this.props.dispatch(actions.updateDraft({ description: description || file.name }));
        this.props.dispatch(actions.updateField('fileName', file.name));
    };

    updateRetargeting = type => {
        this.props.dispatch(actions.updateRetargetingType(type));
    };

    updateUploadOrigin = origin => {
        this.props.dispatch(actions.updateDraft({ upload_origin: origin }));
    };

    updateCustomUploadOrigin = value => {
        this.props.dispatch(actions.updateDraft({ customUploadOrigin: value }));
    };

    updateSource = source => {
        this.props.dispatch(
            actions.updateDraft({
                source_type: source,
                source_id: [],
            })
        );
    };

    handleClose = () => {
        this.props.dispatch(actions.closeModal());
    };

    render() {
        return (
            <Dialog open={this.props.isModalOpen} fullWidth maxWidth="lg">
                <DialogTitle onClose={this.handleClose}>Retargeting</DialogTitle>
                <DialogContent dividers>
                    <Layout2
                        isLoading={
                            this.props.audienceEditorState.isInitializing ||
                            this.props.audienceEditorState.isSaving
                        }
                        isCampaignsLoading={this.props.resourcesLoading}
                        showErrors={this.props.audienceEditorState.showErrors}
                        errors={this.props.audienceEditorState.errors}
                        serverError={this.props.audienceEditorState.serverError}
                        invalidLines={this.props.audienceEditorState.invalidLines}
                        draft={this.props.audienceEditorState.draft}
                        fileName={this.props.audienceEditorState.fileName}
                        isEditing={this.props.audienceEditorState.isEditing}
                        modeOptions={modeOptions}
                        campaignOptions={this.props.campaignOptions}
                        adOptions={this.props.adOptions}
                        advertiserOptions={this.props.advertiserOptions}
                        uploadOriginOptions={this.props.uploadOriginOptions}
                        retargetingOptions={getRetargetingOptions()}
                        conversionOptions={this.props.conversionOptions}
                        eventOptions={this.props.eventOptions}
                        attributionOptions={this.props.attributionOptions}
                        sourceTypeOptions={sourceTypeOptions}
                        onSourceTypeChange={this.updateSource}
                        onNameChange={this.updateDraft('name')}
                        onFrequencyChange={this.updateDraft('frequency')}
                        onSupportsChange={this.updateDraft('supports')}
                        onModeChange={this.updateMode}
                        onSourceChange={this.updateDraft('source_id')}
                        onAdvertiserChange={this.updateDraft('advertiser_id')}
                        onLookBackChange={this.updateDraft('lookback')}
                        onRetargetingChange={this.updateRetargeting}
                        onOriginChange={this.updateUploadOrigin}
                        onEventChange={this.updateDraft('event_name')}
                        onEventNameListChange={this.updateDraft('event_name_list')}
                        onFileChange={this.handleFileChange}
                        onDescriptionChange={this.updateDraft('description')}
                        onThirdPartyFeesChange={this.updateDraft('third_party_fees')}
                        onSave={this.handleSave}
                        onCancel={this.props.onClose}
                        updateCustomUploadOrigin={this.updateCustomUploadOrigin}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleSave} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function getRetargetingOptions() {
    let retargetingOptions2 = [
        { label: 'Impressions', value: 'impression' },
        { label: 'Clicks', value: 'click' },
        { label: 'Creative engagements', value: 'conversion' },
        { label: 'Campaign-driven conversions', value: 'attribution' },
        {
            label: 'All site visits or conversions (includes organic and campaign-driven)',
            value: 'pixel',
        },
    ];
    if (!isInternalUser() && !getEnvironmentSettings().canUseCTVOrDOOH()) {
        retargetingOptions2 = _.filter(
            retargetingOptions2,
            option => option.value !== 'conversion'
        );
    }
    return retargetingOptions2;
}

export default connect(selector)(RetargetingModal);

import _ from 'lodash';

export default function selector(storeState) {
    const draft = _.get(storeState, 'adminOrgForm.draft');
    const mode = _.get(storeState, 'adminOrgForm.mode');
    const relationship = _.get(storeState, 'adminOrgForm.relationship');
    return {
        draft,
        mode,
        relationship,
    };
}

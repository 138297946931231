import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';
import validate from 'utils/validate';
import { validateCreativeDraft } from 'forms/creative-form/services';
import { defaultSetting } from 'forms/creative-form/services';

const initialState = {
    draft: null,
    isDraftValid: false,
    errors: [],
    showErrors: false,
    isLoading: true,
    isSubmiting: false,
    isDirty: false,
    isFormOpen: false,
};

export const NAME = 'creativeNewForm';

export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.OVERVIEW__CREATIVE_DRAFT_NEW__INIT_STATE: {
            const { campaignId } = action.payload;

            // return findCacheState(campaignId, state, initialState);
            return initialState;
        }
        case c.OVERVIEW__CREATIVE_DRAFT_NEW__OPEN: {
            return {
                ...state,
                isFormOpen: true,
                isLoading: true,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_NEW__INIT: {
            let draft = {};
            const duplicateCreative = _.get(action, 'payload.creative');

            if (duplicateCreative) {
                draft = duplicateCreative;
            }

            const errors = validateCreativeDraft(draft);
            return {
                ...state,
                errors,
                isDraftValid: errors.length === 0,
                draft,
                isLoading: false,
                isDirty: false,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_NEW__UPDATE: {
            const { formData } = action.payload;
            const attr_session = _.get(state, `draft`);

            // Different creative format has different field, so the draft has to reconfigure.
            let draft = {};
            if (Object.keys(formData).indexOf('format') !== -1) {
                const manifest_creativeFormat = _.get(
                    defaultSetting,
                    `manifest.${formData.format}`,
                    []
                );
                const manifest = _.uniq([
                    ...defaultSetting.manifest.initial,
                    ...manifest_creativeFormat,
                ]);
                const attrPicked_default = _.pick(defaultSetting.defaultValues, manifest);
                const attrPicked_session = _.pick(attr_session, manifest);
                draft = {
                    ...attrPicked_default,
                    ...attrPicked_session,
                    ...formData,
                };
            } else {
                draft = {
                    ...attr_session,
                    ...formData,
                };
            }

            // Some field has value depedented on other field, and are set automatically
            if (!draft.third_party_clickthrough) {
                draft.clickthrough_url = draft.landing_page;
            }
            if (draft.format === 'native') {
                draft.size = '0x0';
            }

            const errors = validateCreativeDraft(draft);
            return {
                ...state,
                draft,
                isDirty: true,
                errors,
                isDraftValid: errors.length === 0,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_NEW__SHOW_ERRORS: {
            return {
                ...state,
                showErrors: true,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT: {
            return {
                ...state,
                isSubmiting: true,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_FAIL: {
            const errors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));

            return {
                ...state,
                errors,
                showErrors: true,
                isSubmiting: false,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_SUCCESS: {
            return {
                ...state,
                isSubmiting: false,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_NEW__CLOSE: {
            return {
                ...initialState,
            };
        }

        default: {
            return state;
        }
    }
}

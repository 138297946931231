import _ from 'lodash';
import { combineReducers } from 'redux';

import c from 'common/constants/flux-events';

import scopeFilter from './scope-filter';
import pivotTable from './pivot-table';
import components from './components';
import dictionary from './dictionary';
import dateRange from './date-range';
import metricSelector from './metric-selector';
import page from './page';

export default combineReducers({
    components,
    dictionary,
    scopeFilter,
    pivotTable,
    dateRange,
    metricSelector,
    page,
});

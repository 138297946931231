import { makeActions } from 'utils/redux-tools';
export const REDUCER_KEY = 'creativeBulkEditFormProgressBar';
export const initialState = {
    processedCreatives: 0,
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        updateBulkEditProgress: (state, updatedCreatives) => ({
            ...state,
            processedCreatives: updatedCreatives,
        }),
        bulkEditSuccess: state => ({
            ...state,
            processedCreatives: 0,
        }),
    },
});

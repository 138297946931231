import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import leftPad from 'left-pad';
import moment from 'moment';
import 'moment-timezone';

const TIME_BLOCKS = createTimeBlocks();
const BACKGROUND_BLOCKS = createBackgroundBlocks();
const START_TIME_TO_INDEX = TIME_BLOCKS.reduce((acc, block, i) => {
    const { hour, minute } = block.start;
    const timecode = `${leftPad(hour, 2, '0')}:${leftPad(minute, 2, '0')}:00`;
    acc[timecode] = i;
    return acc;
}, {});
const END_TIME_TO_INDEX = TIME_BLOCKS.reduce((acc, block, i) => {
    const { hour, minute } = block.end;
    const timecode = `${leftPad(hour, 2, '0')}:${leftPad(minute, 2, '0')}:59`;
    acc[timecode] = i;
    return acc;
}, {});

export default class extends React.Component {
    static displayName = 'Dayparts';

    static propTypes = {
        className: PropTypes.string,
        timezone: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(
            PropTypes.shape({
                start: PropTypes.string,
                end: PropTypes.string,
            })
        ).isRequired,
        date: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);
        this.lastHoveredIndex = null;
        const dayparts = convertUtcTimecodesToLocalDaypartIndices(
            props.value,
            this.getStandardTimezone(props.timezone),
            props.date
        );
        const mergedDayparts = mergeConsecutiveAndOverlappingDayparts(dayparts);

        this.state = {
            dayparts: mergedDayparts,
            activeDaypartIndex: null,
            resizeAnchorIndex: null,
            resizeAnchor: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // If value was changed, this means an outside source of truth has determined
        // what the final value should be. So this widget should behave passively
        if (nextProps.value !== this.props.value) {
            const dayparts = convertUtcTimecodesToLocalDaypartIndices(
                nextProps.value,
                this.getStandardTimezone(nextProps.timezone),
                nextProps.date
            );
            const mergedDayparts = mergeConsecutiveAndOverlappingDayparts(dayparts);
            this.setState({
                dayparts: mergedDayparts,
            });
            // If value wasn't changed, then something other prop which could
            // potentially affect the final value (e.g. timezone or date) was changed.
            // In this case, this widget so behave proactively to re-process and re-emit
            // what it thinks should be the new final value
        } else {
            this.emitChanges(
                this.state.dayparts,
                this.getStandardTimezone(nextProps.timezone),
                nextProps.date
            );
        }
    }

    getStandardTimezone = timezone => {
        return timezone === 'local' ? 'UTC' : timezone;
    };

    // By default, emit what's in the state and props
    // But it's overridable so that on receiving new timezone prop, the widget
    // can emit a new value immediately by passing in next props
    emitChanges = (
        dayparts = this.state.dayparts,
        timezone = this.getStandardTimezone(this.props.timezone),
        date = this.props.date
    ) => {
        const mergedDayparts = mergeConsecutiveAndOverlappingDayparts(dayparts);
        const utcTimecodes = convertLocalDaypartIndicesToUtcTimecodes(
            mergedDayparts,
            timezone,
            date
        );

        // If any single timecode crosses midnight, e.g. [{ start: '23:00:00', end: '01:59:59' }]
        // then break into two timecodes, e.g. [{ start: '23:00:00', end: '23:59:59' }, { start: '00:00:00', end: '01:59:59' }]
        // This can be detected by end time being 'before' the start time
        const newTimecodes = _.flatten(
            utcTimecodes.map(timecode => {
                if (timecode.end > timecode.start) {
                    return [timecode];
                } else {
                    return [
                        { start: timecode.start, end: '23:59:59' },
                        { start: '00:00:00', end: timecode.end },
                    ];
                }
            })
        );

        if (!_.isEqual(this.props.value, newTimecodes)) {
            this.props.onChange(newTimecodes);
        }
    };

    handleMouseDownTimeBlock = blockIndex => {
        // Add a new dayparts
        this.setState({
            dayparts: [...this.state.dayparts, { startIndex: blockIndex, endIndex: blockIndex }],
            // Immediately start a resize with the new daypart
            activeDaypartIndex: this.state.dayparts.length,
            resizeAnchorIndex: blockIndex,
        });
    };

    handleMouseUpTimeBlock = () => {
        // Ignore if not active
        if (this.state.activeDaypartIndex === null) {
            return;
        }
        // Stop resizing
        this.setState(
            {
                activeDaypartIndex: null,
                resizeAnchorIndex: null,
                resizeAnchor: null,
            },
            this.emitChanges
        );
    };

    handleMouseEnterTimeBlock = blockIndex => {
        // Ignore if not active
        if (this.state.activeDaypartIndex === null) {
            return;
        }

        this.lastHoveredIndex = blockIndex;

        const resizeAnchorIndex = this.state.resizeAnchorIndex;

        const updatedActiveDaypart = {
            startIndex: Math.min(resizeAnchorIndex, blockIndex),
            endIndex: Math.max(resizeAnchorIndex, blockIndex),
        };

        // If selection has 'flipped'
        // If flipped from right to left
        if (this.state.resizeAnchor === 'start' && blockIndex < updatedActiveDaypart.endIndex) {
            updatedActiveDaypart.endIndex = updatedActiveDaypart.endIndex - 1;
            // If flipped from left to right
        } else if (
            this.state.resizeAnchor === 'end' &&
            blockIndex > updatedActiveDaypart.startIndex
        ) {
            updatedActiveDaypart.startIndex = updatedActiveDaypart.startIndex + 1;
        }

        const updatedDayparts = [...this.state.dayparts];
        updatedDayparts.splice(this.state.activeDaypartIndex, 1, updatedActiveDaypart);

        this.setState({
            dayparts: updatedDayparts,
        });
    };

    handleMouseDownDaypartAnchor = (daypartIndex, resizeAnchor, resizeAnchorIndex) => {
        this.setState({
            activeDaypartIndex: daypartIndex,
            resizeAnchorIndex,
            resizeAnchor,
        });
    };

    handleMouseLeaveWidget = () => {
        // Ignore if not active
        if (this.state.activeDaypartIndex === null) {
            return;
        }
        this.handleMouseUpTimeBlock(this.lastHoveredIndex);
    };

    handleClickDaypartRemove = index => {
        const nextDayparts = [...this.state.dayparts];
        nextDayparts.splice(index, 1);

        this.setState(
            {
                dayparts: nextDayparts,
            },
            this.emitChanges
        );
    };

    render() {
        const isResizing = this.state.activeDaypartIndex !== null;
        return (
            <figure className="ef4-dayparts" onMouseLeave={() => this.handleMouseLeaveWidget()}>
                <div className="ef4-dayparts__time-blocks">
                    {TIME_BLOCKS.map((block, index) => (
                        <div
                            key={index}
                            style={{ left: `${index + 2}%` }}
                            className={classnames('ef4-dayparts__time-block', {
                                'ef4-dayparts__time-block_is-resizing': isResizing,
                            })}
                            onMouseDown={() => this.handleMouseDownTimeBlock(index)}
                            onMouseUp={() => this.handleMouseUpTimeBlock(index)}
                            onMouseEnter={() => this.handleMouseEnterTimeBlock(index)}
                        />
                    ))}
                </div>
                <div className="ef4-dayparts__dayparts">
                    {this.state.dayparts.map((daypart, index) => {
                        const startHourString = leftPad(
                            TIME_BLOCKS[daypart.startIndex].start.hour,
                            2,
                            '0'
                        );
                        const startMinuteString = leftPad(
                            TIME_BLOCKS[daypart.startIndex].start.minute,
                            2,
                            '0'
                        );
                        const endHourString = leftPad(
                            TIME_BLOCKS[daypart.endIndex].end.hour,
                            2,
                            '0'
                        );
                        const endMinuteString = leftPad(
                            TIME_BLOCKS[daypart.endIndex].end.minute,
                            2,
                            '0'
                        );
                        const timezoneLabel =
                            this.props.timezone === 'local'
                                ? 'Local Time'
                                : moment.tz(this.props.date, this.props.timezone).format('z');
                        return (
                            <div
                                key={index}
                                className="ef4-dayparts__daypart"
                                style={{
                                    width: `calc(${daypart.endIndex -
                                        daypart.startIndex +
                                        1}% + 1px)`,
                                    left: `${daypart.startIndex + 2}%`,
                                }}
                            >
                                <div
                                    className={classnames('ef4-dayparts__daypart-tooltip', {
                                        'ef4-dayparts__daypart-tooltip_is-active':
                                            this.state.activeDaypartIndex === index,
                                    })}
                                >
                                    {startHourString}:{startMinuteString}&ndash;{endHourString}:
                                    {endMinuteString}&nbsp;{timezoneLabel}
                                </div>
                                <div className="ef4-dayparts__daypart-remove">
                                    <i
                                        className="fa fa-times-circle ef4-dayparts__daypart-remove-icon"
                                        onMouseDown={() => this.handleClickDaypartRemove(index)}
                                    />
                                </div>
                                <div
                                    className="ef4-dayparts__daypart-border-start"
                                    onMouseDown={() =>
                                        this.handleMouseDownDaypartAnchor(
                                            index,
                                            'end',
                                            daypart.endIndex
                                        )
                                    }
                                />
                                <div
                                    className="ef4-dayparts__daypart-border-end"
                                    onMouseDown={() =>
                                        this.handleMouseDownDaypartAnchor(
                                            index,
                                            'start',
                                            daypart.startIndex
                                        )
                                    }
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="ef4-dayparts__background-blocks">
                    {BACKGROUND_BLOCKS.map((block, index) => (
                        <div
                            key={index}
                            style={{ left: `${index + 2}%` }}
                            className={classnames(
                                'ef4-dayparts__background-block',
                                {
                                    'ef4-dayparts__background-block_is-start-of-hour':
                                        block.isStartOfHour,
                                },
                                {
                                    'ef4-dayparts__background-block_is-last-block':
                                        block.isLastBlock,
                                }
                            )}
                        >
                            {block.isStartOfHour && (
                                <div className="ef4-dayparts__background-hour-caption">
                                    {block.caption}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </figure>
        );
    }
}

function createTimeBlocks() {
    return _.range(24 * 4).map(i => {
        const quarter = i % 4;
        const hour = Math.floor(i / 4);
        return {
            isStartOfHour: quarter === 0,
            start: { hour, minute: quarter * 15, second: 0 },
            end: { hour, minute: (quarter + 1) * 15 - 1, second: 59 },
        };
    });
}

function createBackgroundBlocks() {
    return _.range(24 * 4 + 1).map(i => {
        const quarter = i % 4;
        const hour = Math.floor(i / 4);
        return {
            isStartOfHour: quarter === 0,
            caption: quarter === 0 ? leftPad(hour, 2, '0') : null,
            isLastBlock: i === 96,
        };
    });
}

function mergeConsecutiveAndOverlappingDayparts(dayparts) {
    const sortedDayparts = _.sortBy(dayparts, 'startIndex');
    const mergedDayparts = sortedDayparts.reduce((acc, currentItem) => {
        // No previous items, not possible to overlap
        if (acc.length === 0) {
            acc.push(currentItem);
            return acc;
        }
        const previousItemIndex = acc.length - 1;
        const previousItem = acc[previousItemIndex];
        // Not overlap nor consecutive
        if (currentItem.startIndex > previousItem.endIndex + 1) {
            acc.push(currentItem);
            return acc;
        }
        // Overlaps or consecutive, so merge
        acc[previousItemIndex] = {
            startIndex: previousItem.startIndex,
            endIndex: Math.max(currentItem.endIndex, previousItem.endIndex),
        };
        return acc;
    }, []);
    return mergedDayparts;
}

// Interpret UTC timecodes as daypart indices using a timezone
function convertUtcTimecodesToLocalDaypartIndices(timecodes, timezone, date) {
    return timecodes.map(timecode => {
        const startMatch = timecode.start.match(/^(\d\d):(\d\d):(\d\d)$/);
        const utcStartHour = startMatch[1];
        const utcStartMinute = startMatch[2];
        const utcStartSecond = startMatch[3];

        const endMatch = timecode.end.match(/^(\d\d):(\d\d):(\d\d)$/);
        const utcEndHour = endMatch[1];
        const utcEndMinute = endMatch[2];
        const utcEndSecond = endMatch[3];

        const dateMoment = moment.tz(date, 'UTC');
        const startMoment = dateMoment
            .clone()
            .set({ hour: utcStartHour, minute: utcStartMinute, second: utcStartSecond });
        const endMoment = dateMoment
            .clone()
            .set({ hour: utcEndHour, minute: utcEndMinute, second: utcEndSecond });

        const localStartTime = startMoment.tz(timezone).format('HH:mm:ss');
        const localEndTime = endMoment.tz(timezone).format('HH:mm:ss');

        return {
            startIndex: START_TIME_TO_INDEX[localStartTime],
            endIndex: END_TIME_TO_INDEX[localEndTime],
        };
    });
}

// Convert local daypart indices to UTC timecodes using a timezone
function convertLocalDaypartIndicesToUtcTimecodes(dayparts, timezone, date) {
    return dayparts.map(daypart => {
        const startTime = TIME_BLOCKS[daypart.startIndex].start;
        const endTime = TIME_BLOCKS[daypart.endIndex].end;

        const dateMoment = moment.tz(date.toISOString().slice(0, 10), timezone);
        const startMoment = dateMoment.clone().set(startTime);
        const endMoment = dateMoment.clone().set(endTime);

        return {
            start: startMoment.tz('UTC').format('HH:mm:ss'),
            end: endMoment.tz('UTC').format('HH:mm:ss'),
        };
    });
}

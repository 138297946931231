import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions, REDUCER_KEY } from './reducer';
import {
    getProfileToken,
    getOwnOrganization,
    getPredictionState,
    getAllGeoCategories,
    getSelectedPoiCategories,
    getSelectedPoiSegments,
    getTargetingSubCategories,
} from './selectors';

export function usePredictionEditorData() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);
    const profileToken = useSelector(getProfileToken);
    const ownOrganization = useSelector(getOwnOrganization);
    const predictionState = useSelector(getPredictionState);
    const allGeoCategories = useSelector(getAllGeoCategories);
    const selectedPoiCategories = useSelector(getSelectedPoiCategories);
    const selectedPoiSegments = useSelector(getSelectedPoiSegments);
    const geoTargetingSubCategories = useSelector(getTargetingSubCategories);

    return {
        ...state,
        ...boundActions,
        ...predictionState,
        profileToken,
        ownOrganization,
        geoLayers: allGeoCategories ? allGeoCategories.geoLayers : [],
        selectedPoiCategories,
        selectedPoiSegments,
        geoTargetingSubCategories,
    };
}

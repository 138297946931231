import _ from 'lodash';
import Bacon from 'baconjs';
import toastr from 'toastr';

import { mockResponse, fetchReportStats } from 'services/resources/stats';
import BusinessReportActions from 'states/resources/business-reports/actions';
import createHttp from 'utils/http';
import { draftToPayloadV2 } from './services/draft-to-payload';

const ptStream = new Bacon.Bus();

const actions = {
    initializeReport({ businessReportId, reportJob }) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'BUSINESS_REPORT_EDITOR__INIT_REPORT__START',
                payload: {},
            });

            const ownOrgId = _.get(getState(), 'profile.organizationId');
            const {
                organization: ownOrg,
                organizations: clientOrgs,
                organizationsForFiltering,
            } = await getData(ownOrgId);

            if (!businessReportId) {
                return dispatch({
                    type: 'BUSINESS_REPORT_EDITOR__INIT_REPORT__END',
                    payload: {
                        businessReport: null,
                        ownOrg,
                        clientOrgs,
                        reportJob,
                        organizationsForFiltering,
                    },
                });
            }

            try {
                const businessReport = await dispatch(
                    BusinessReportActions.getOne(businessReportId)
                );
                dispatch({
                    type: 'BUSINESS_REPORT_EDITOR__INIT_REPORT__END',
                    payload: {
                        businessReport,
                        ownOrg,
                        organizationsForFiltering,
                    },
                });
            } catch (error) {
                dispatch({
                    type: 'BUSINESS_REPORT_EDITOR__INIT_REPORT__ERROR',
                    payload: {},
                    error,
                });
            }
        };
    },
    saveAs() {
        return (dispatch, getState) => {
            const draft = _.get(getState(), 'businessReportEditor.draft');

            const schemaVersion = _.get(getState(), 'businessReportEditor.schemaVersion', '1');

            const payload = {
                ...draftToPayloadV2(draft),
                name: draft.saveAsName,
                schemaVersion,
            };
            return dispatch(this.createOrUpdate(null, payload));
        };
    },
    save(businessReportId = null) {
        return (dispatch, getState) => {
            const draft = _.get(getState(), 'businessReportEditor.draft');

            const payload = draftToPayloadV2(draft);
            return dispatch(this.createOrUpdate(businessReportId, payload));
        };
    },
    createOrUpdate(businessReportId = null, payload) {
        return (dispatch, getState) => {
            dispatch({
                type: 'BUSINESS_REPORT_EDITOR__SAVE__START',
                payload: {},
            });

            const errors = _.get(getState(), 'businessReportEditor.errors', {});

            const scheduleErrors = _.get(getState(), 'businessReportEditor.scheduleDraftError');

            if (scheduleErrors) {
                dispatch({
                    type: 'BUSINESS_REPORT_EDITOR__SAVE__CLIENT_ERROR',
                });
                return Promise.reject(scheduleErrors);
            }

            if (_.keys(errors).length > 0) {
                dispatch({
                    type: 'BUSINESS_REPORT_EDITOR__SAVE__CLIENT_ERROR',
                    payload: {},
                    error: errors,
                });
                return Promise.reject(errors);
            }

            let request;
            if (businessReportId) {
                request = dispatch(BusinessReportActions.update(businessReportId, payload));
            } else {
                request = dispatch(BusinessReportActions.create(payload));
            }

            return request.then(
                businessReport => {
                    dispatch({
                        type: 'BUSINESS_REPORT_EDITOR__SAVE__END',
                        payload: {},
                    });

                    return businessReport;
                },
                error => {
                    dispatch({
                        type: 'BUSINESS_REPORT_EDITOR__SAVE__SERVER_ERROR',
                        payload: {},
                        error,
                    });

                    return Promise.reject(error);
                }
            );
        };
    },
    changeDate(date) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_DATE',
            payload: { date },
        };
    },
    changeTime(time) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_TIME',
            payload: { time },
        };
    },
    changeTimezone(timezone) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_TIMEZONE',
            payload: { timezone },
        };
    },
    changeDayOfWeek(dayOfWeek) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_DAY_OF_WEEK',
            payload: { dayOfWeek },
        };
    },
    changeDayOfMonth(dayOfMonth) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_DAY_OF_MONTH',
            payload: { dayOfMonth },
        };
    },
    changeFrequency(frequency) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__CHANGE_FREQUENCY',
            payload: { frequency },
        };
    },
    toggleRecipient(email) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__TOGGLE_RECIPIENT',
            payload: { email },
        };
    },
    openSchedule() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__OPEN',
            payload: {},
        };
    },
    saveSchedule() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__SAVE',
            payload: {},
        };
    },
    cancelSchedule() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__SCHEDULE__CANCEL',
            payload: {},
        };
    },
    cancel() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CANCEL',
            payload: {},
        };
    },
    updateDraft(changes, engagementOptions) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__UPDATE_DRAFT',
            payload: {
                changes,
                engagementOptions,
            },
        };
    },
    openSaveModal() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__OPEN_SAVE_MODAL',
            payload: {},
        };
    },
    closeSaveModal() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CLOSE_SAVE_MODAL',
            payload: {},
        };
    },
    openSaveAsModal() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__OPEN_SAVE_AS_MODAL',
            payload: {},
        };
    },
    closeSaveAsModal() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CLOSE_SAVE_AS_MODAL',
            payload: {},
        };
    },
    apply(businessReportId, engagementOptions, attributeOptions) {
        return (dispatch, getState) => {
            dispatch({
                type: 'BUSINESS_REPORT_EDITOR__APPLY',
                payload: {
                    businessReportId,
                },
            });

            const errors = _.get(getState(), 'businessReportEditor.errors', {});

            if (_.keys(errors).length > 0) {
                return Promise.reject(errors);
            }

            dispatch({
                type: 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__INIT_START',
                payload: {
                    businessReportId,
                    engagementOptions,
                    attributeOptions,
                },
            });

            const done = stats => {
                dispatch({
                    type: 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__INIT_END',
                    payload: {
                        stats,
                        businessReportId,
                    },
                });
            };

            dispatch(this.fetchPivotTableStats(businessReportId, engagementOptions, done));
        };
    },
    sortColumn(businessReportId, column) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__SORT',
            payload: {
                column,
                businessReportId,
            },
        };
    },
    expandRow(businessReportId, rowId) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__EXPAND_ROW',
            payload: {
                rowId,
                businessReportId,
            },
        };
    },
    collapseRow(businessReportId, rowId) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__COLLAPSE_ROW',
            payload: {
                rowId,
                businessReportId,
            },
        };
    },
    hideZerosToggle(businessReportId) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__PIVOT_TABLE__HIDE_ZEROS_TOGGLE',
            payload: {
                businessReportId,
            },
        };
    },
    fetchPivotTableStats(businessReportId, engagementOptions, done) {
        return (dispatch, getState) => {
            ptStream.push({ businessReportId, engagementOptions, getState, done });
        };
    },
    changeReportMode(mode) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CHANGE_REPORT_MODE',
            payload: {
                mode,
            },
        };
    },
    exportReport() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__EXPORT_REPORT',
        };
    },
    changeDateRangeType(type) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_TYPE',
            payload: { type },
        };
    },
    changeDateRangeLast(last) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_LAST',
            payload: { last },
        };
    },
    changeDateRangeLastFrame(lastFrame) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_LAST_FRAME',
            payload: { lastFrame },
        };
    },
    changeDateRangeFrom(from) {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CHANGE_DATE_RANGE_FROM',
            payload: { from },
        };
    },
    openEditor({ businessReportId, reportJob }) {
        return dispatch => {
            dispatch({
                type: 'BUSINESS_REPORT_EDITOR__OPEN_EDITOR',
                payload: { businessReportId, reportJob },
            });
            dispatch(actions.initializeReport({ businessReportId, reportJob }));
        };
    },
    closeEditor() {
        return {
            type: 'BUSINESS_REPORT_EDITOR__CLOSE_EDITOR',
        };
    },
};

ptStream
    .flatMapLatest(({ engagementOptions, getState, done }) => {
        // const campaignIds = [6216];
        // const campaignIds = [];
        const campaignIds = _.get(getState(), `businessReportEditor.draft.campaigns.ids`, []);
        const campaignMode = _.get(
            getState(),
            `businessReportEditor.draft.campaigns.mode`,
            'include'
        );
        const split = _.get(getState(), `businessReportEditor.draft.dimensions`, []);
        const beacons = _.get(getState(), `businessReportEditor.draft.engagements`, []);
        const dateRange = _.get(getState(), `businessReportEditor.draft.dateRange`, {});
        const timezone = _.get(getState(), `businessReportEditor.draft.timezone`, 'UTC');
        const ownOrg = _.get(getState(), 'profile.organizationId');

        let beaconsPayload;
        if (_.includes(beacons, 'overall_engagements')) {
            beaconsPayload = _.map(engagementOptions.slice(1), opt => opt.value);
        } else {
            beaconsPayload = beacons.slice();
        }

        const hasAnyCampaigns = campaignIds.length > 0;
        const includeCampaigns = {};
        const exclude = {};
        if (campaignMode === 'exclude') {
            exclude.campaign_id = campaignIds;
        } else {
            includeCampaigns.campaign_id = hasAnyCampaigns ? campaignIds : undefined;
        }

        const fetchData = () => {
            const promise = fetchReportStats(
                {
                    ...includeCampaigns,
                    camp_org: ownOrg,
                    start: dateRange.start === null ? undefined : dateRange.start,
                    end: dateRange.end === null ? undefined : dateRange.end,
                    match: {},
                    exclude,
                    timezone,
                    split,
                    beacons: beaconsPayload,
                },
                {
                    mockResponseOnError: false,
                }
            ).then(
                stats => {
                    return { stats, done };
                },
                error => {
                    // Server failed to respond, probably crashed due to too much memory used
                    // Or query was too large, causing a circuit breaking exception
                    if (error.code === 0 || error.body === 'too complex') {
                        warnStatsProblemTooComplex();
                    }

                    return { stats: mockResponse, done };
                }
            );

            return Bacon.fromPromise(promise, true);
        };

        return Bacon.retry({
            source: fetchData,
            retries: 2,
            delay() {
                return 1000;
            },
        });
    })
    .onValue(({ stats, done }) => {
        done(stats);
    });

function warnStatsProblemTooComplex() {
    toastr.remove();
    toastr.warning(
        '<p>Please try reducing the complexity of your Report</p>',
        '<p>We failed to build your Report</p>',
        {
            tapToDismiss: true,
            showMethod: 'slideDown',
            timeOut: 0,
            extendedTimeOut: 0,
            positionClass: 'toast-bottom-left',
        }
    );
}

async function getData(orgId) {
    const http = createHttp();

    const query = `
        query getReportData($orgId: String) {
            organizations(filters: {relationship: "child", type: ["client", "co_managed_client"]}) {
                id
                name
            }
            organizationsForFiltering: organizations(filters: { type: [ "admin", "media_buyer"]}) {
                id
                name
            }
            organization(id: $orgId) {
                id
                name
                default_timezone
                custom_fields {
                    value
                    name
                    required
                    key
                }
                type
                users {
                    id
                    first_name
                    last_name
                    email
                }
            }
        }
    `;

    const variables = {
        orgId,
    };

    const data = await http.graphql(query, variables);

    return data;
}

export default actions;

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

function noop() {}

class Toggle extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        position: PropTypes.oneOf(['left', 'right']).isRequired,
        onClick: PropTypes.func.isRequired,
        textLeft: PropTypes.string,
        textRight: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        width: 40,
        height: 20,
    };

    render() {
        const { position, onClick, textLeft, textRight, width, height } = this.props;

        const handleHeight = height * 0.9;

        const handleOffset = position === 'left' ? 0 : `calc(100% - ${handleHeight}px)`;
        const textOffset = {
            paddingLeft: position === 'left' ? height / 1.5 : 0,
            paddingRight: position === 'right' ? height / 1.5 : 0,
        };

        return (
            <span
                onClick={!this.props.disabled ? onClick : noop}
                className={classnames(
                    'ef5-toggle',
                    `ef5-toggle--is-${position}Position`,
                    `ef5-toggle--is-${this.props.status}`,
                    this.props.className,
                    {
                        'ef5-toggle--disabled': this.props.disabled,
                    }
                )}
                style={{ width, height }}
            >
                <span className="ef5-toggle__text" style={textOffset}>
                    {position === 'left' ? textLeft : position === 'right' ? textRight : null}
                </span>
                <span
                    className={`ef5-toggle__handle ef5-toggle--is-${this.props.status}`}
                    style={{ width: handleHeight, height: handleHeight, left: handleOffset }}
                />
            </span>
        );
    }
}

export default Toggle;

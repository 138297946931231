import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import QuickDialog from 'widgets-v5/quick-dialog';
import StandardInput from 'widgets-v5/standard-input';
import Dropdown from 'widgets-v5/dropdown';
import Menu, { VirtualMenu, MenuItem, MenuSeparator } from 'widgets-v5/menu';

class StandardSelector extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.any.isRequired,
            })
        ).isRequired,
        onChange: PropTypes.func,
        searchable: PropTypes.bool,
        clearable: PropTypes.bool,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        virtual: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        searchable: false,
        clearable: false,
        placeholder: 'Select a value',
        onChange() {},
        virtual: false,
        disabled: false,
    };

    state = {
        filter: '',
        isMenuOpen: false,
    };

    onFilterInput = event => {
        this.setState({ filter: event.target.value });
    };

    dialogOnClose = () => {
        // Clear filter in a timeout so that the results
        // do not reappear meanwhile the dialog is closing
        // The quick dialog takes 200ms to animate out
        window.setTimeout(() => this.setState({ filter: '' }), 200);
    };

    onSelectItem = (value, closeDialog) => {
        this.props.onChange(value);
        closeDialog();
    };

    clearValue = () => {
        if (this.props.clearable) {
            this.props.onChange(null);
        }
    };

    focusInput = () => {
        if (this.filterInput) {
            this.filterInput.focus();
        }
    };

    render() {
        const value = this.props.items.find(item => item.value === this.props.value);
        const filteredItems = this.props.items.filter(
            item => item.label.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
        );

        const hasValue = this.props.value !== undefined && this.props.value !== null;
        const onClear =
            hasValue && !this.props.disabled && this.props.clearable ? this.clearValue : null;

        return (
            <Dropdown
                className={this.props.className || ''}
                buttonLabel={
                    value ? (
                        <span className="ef4-standard-selector__value">{value.label}</span>
                    ) : (
                        <span className="ef4-standard-selector__default">
                            {this.props.placeholder}
                        </span>
                    )
                }
                onClear={onClear}
                clearable={hasValue && this.props.clearable}
                onOpen={this.focusInput}
                disabled={this.props.disabled}
                isLoading={this.props.isLoading}
                dropUp={this.props.dropUp}
                bordered={this.props.bordered}
            >
                {close => (
                    <div>
                        {this.props.searchable && (
                            <div className="ef4-standard-selector__input">
                                <input
                                    ref={input => {
                                        this.filterInput = input;
                                    }}
                                    className="ef4-standard-selector__filter"
                                    value={this.state.filter}
                                    onChange={this.onFilterInput}
                                    placeholder="Filter selections"
                                />
                            </div>
                        )}
                        <StandardSelectorMenu
                            items={filteredItems}
                            virtual={this.props.virtual}
                            onSelectItem={this.onSelectItem}
                            value={this.props.value}
                            close={close}
                        />
                    </div>
                )}
            </Dropdown>
        );
    }
}

class StandardSelectorMenu extends React.Component {
    render() {
        if (this.props.items.length === 0) {
            return (
                <Menu>
                    <MenuItem>
                        <span className="ef4-standard-selector__empty-list">No results</span>
                    </MenuItem>
                </Menu>
            );
        } else if (this.props.virtual) {
            return (
                <VirtualMenu items={this.props.items}>
                    {(item, style) => {
                        return (
                            <StandardSelectorMenuItem
                                key={item.value}
                                item={item}
                                onSelectItem={this.props.onSelectItem}
                                value={this.props.value}
                                close={this.props.close}
                                style={style}
                            />
                        );
                    }}
                </VirtualMenu>
            );
        } else {
            return (
                <Menu>
                    {_.map(this.props.items, (item, index) => (
                        <StandardSelectorMenuItem
                            key={item.value}
                            item={item}
                            onSelectItem={this.props.onSelectItem}
                            value={this.props.value}
                            close={this.props.close}
                        />
                    ))}
                </Menu>
            );
        }
    }
}
class StandardSelectorMenuItem extends React.Component {
    render() {
        const { item } = this.props;
        return (
            <MenuItem
                onClick={() => this.props.onSelectItem(item.value, this.props.close)}
                style={this.props.style}
            >
                <div
                    className={cn({
                        'ef4-standard-selector__item': true,
                        [`ef4-standard-selector__item--depth-${item.depth || 0}`]: true,
                        'ef4-standard-selector__item_is-active': item.value === this.props.value,
                        'ef4-standard-selector__item_is-custom': item.content,
                    })}
                >
                    {item.content ? item.content : item.label}
                </div>
            </MenuItem>
        );
    }
}
StandardSelectorMenuItem.defaultProps = {
    style: {},
};
export default StandardSelector;

export const IAS_BRAND_SAFETY_CATEGORIES = [
    { value: 'adult', label: 'Adult', platforms: ['inapp', 'mweb', 'desktop'] },
    { value: 'alcohol', label: 'Alcohol', platforms: ['inapp', 'mweb', 'desktop'] },
    {
        value: 'gambling',
        label: 'Gambling',
        platforms: ['inapp'],
    },
    { value: 'hateSpeech', label: 'Hate Speech', platforms: ['inapp', 'mweb', 'desktop'] },
    {
        value: 'illegalDownloads',
        label: 'Illegal Downloads',
        platforms: ['inapp', 'mweb', 'desktop'],
    },
    {
        value: 'illegalDrugs',
        label: 'Illegal Drugs',
        platforms: ['inapp', 'mweb', 'desktop'],
    },
    {
        value: 'offensiveLanguage',
        label: 'Offensive Language',
        platforms: ['inapp', 'mweb', 'desktop'],
    },
    { value: 'violence', label: 'Violence', platforms: ['inapp', 'mweb', 'desktop'] },
];

export const IAS_BRAND_SAFETY_OPTIONS = [
    { value: 0, label: 'Off' },
    { value: 2, label: 'Exclude HIGH risk content' },
    { value: 1, label: 'Exclude HIGH and MODERATE risk content' },
];

export const IAS_BRAND_SAFETY_MAPPING = {
    0: 'Off',
    2: 'Exclude HIGH risk content',
    1: 'Exclude HIGH and MODERATE risk content',
};

export const IAS_BRAND_SAFETY_GAMBLING_VALUES = {
    OFF: 0,
    EXCLUDE_HIGH_MODERATE_RISK: 1,
    EXCLUDE_HIGH_RISK: 2,
};

export const IAS_BRAND_SAFETY_UNRATEABLE_ON_LABEL = 'Exclude unrated content';

import _ from 'lodash';
import http from 'utils/http';

const actions = {
    getOne(audienceId) {
        return (dispatch, getState) => {
            return http()
                .get(`audiences/${audienceId}`)
                .then(
                    audience => {
                        dispatch({
                            type: 'SYSTEM__AUDIENCE__FETCH__SUCCESS',
                            payload: {
                                audience,
                            },
                        });

                        return audience;
                    },
                    err => {
                        console.error(`GET ONE audience error`, err);

                        throw err;
                    }
                );
        };
    },
    getAll(params) {
        return (dispatch, getState) => {
            return http()
                .get(`audiences`, params)
                .then(
                    response => {
                        const audiences = _.isArray(response) ? response : response.payload;
                        dispatch({
                            type: 'SYSTEM__AUDIENCE__FETCH__SUCCESS',
                            payload: {
                                audiences,
                            },
                        });

                        return response;
                    },
                    err => {
                        console.error(`GET ALL audience error`, err);

                        throw err;
                    }
                );
        };
    },
    create(payload) {
        return (dispatch, getState) => {
            if (payload.formData) {
                const { formData, ...fields } = payload;

                _.each(fields, (value, key) => {
                    if (value === undefined) {
                        return;
                    }
                    formData.append(key, value);
                });

                return http()
                    .post('audiences', null, {
                        contentType: false,
                        processData: false,
                        data: formData,
                    })
                    .then(
                        audience => {
                            dispatch({
                                type: 'SYSTEM__AUDIENCE__FETCH__SUCCESS',
                                payload: {
                                    audience,
                                },
                            });

                            return audience;
                        },
                        err => {
                            console.error(`CREATE audience error`, err);

                            throw err;
                        }
                    );
            } else {
                return http()
                    .post(`audiences`, payload)
                    .then(
                        audience => {
                            dispatch({
                                type: 'SYSTEM__AUDIENCE__FETCH__SUCCESS',
                                payload: {
                                    audience,
                                },
                            });

                            return audience;
                        },
                        err => {
                            console.error(`CREATE audience error`, err);

                            throw err;
                        }
                    );
            }
        };
    },
    createAndUpload(payload) {
        return (dispatch, getState) => {
            const { formData, ...fields } = payload;

            _.each(fields, (value, key) => {
                if (value === undefined) {
                    return;
                }
                formData.append(key, value);
            });

            return http()
                .post('audiences/upload', null, {
                    contentType: false,
                    processData: false,
                    data: formData,
                })
                .then(
                    audience => {
                        dispatch({
                            type: 'SYSTEM__AUDIENCE__FETCH__SUCCESS',
                            payload: {
                                audience,
                            },
                        });

                        return audience;
                    },
                    err => {
                        console.error(`CREATE AND UPLOAD audience error`, err);

                        throw err;
                    }
                );
        };
    },
    update(audienceId, payload) {
        return (dispatch, getState) => {
            return http()
                .patch(`audiences/${audienceId}`, payload)
                .then(
                    audience => {
                        dispatch({
                            type: 'SYSTEM__AUDIENCE__FETCH__SUCCESS',
                            payload: {
                                audience,
                            },
                        });

                        return audience;
                    },
                    err => {
                        console.error(`UPDATE audience error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default actions;

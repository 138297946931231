import _ from 'lodash';
import { getIASFraudIpWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

export default function selector(storeState) {
    const platform = _.get(storeState, 'adForm.form.draft.platform');
    const adPlatforms = _.get(storeState, 'adForm.form.draft.platforms');
    const isCrossPlatformCampaign = _.get(storeState, 'adForm.form.isCrossPlatformCampaign');

    const iasFraudPreventionRisk = _.get(
        storeState,
        'adForm.form.draft.ias.fraudPrevention.risk.value'
    );
    const iasFraudPreventionUnrateable = _.get(
        storeState,
        'adForm.form.draft.ias.fraudPrevention.unrateable.value'
    );
    const iasFraudPreventionIp = _.get(
        storeState,
        'adForm.form.draft.ias.fraudPrevention.ip.value'
    );

    const iasFraudPreventionIpDisabled = isCrossPlatformCampaign
        ? !_.includes(adPlatforms, 'mweb') && !_.includes(adPlatforms, 'desktop')
        : platform === 'inapp';

    const ipWarning = getIASFraudIpWarning({
        platforms: adPlatforms,
        ipValue: iasFraudPreventionIp,
    });

    return {
        iasFraudPreventionRisk,
        iasFraudPreventionUnrateable,
        iasFraudPreventionIp,
        iasFraudPreventionIpDisabled,
        isCrossPlatformCampaign,
        ipWarning,
    };
}

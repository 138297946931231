import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import selector from './selector';
import CampaignOverviewLayoutV2 from './modules/campaign-overview-layout-2';
import { duplicateCampaign, pause, unpause } from 'pages/campaigns/campaigns-overview/actions';
import CampaignFormEditModal from 'containers/campaign-form-edit';
import CenterLayout from 'widgets-v5/center-layout';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { useCampaignForm } from 'forms/campaign-form/v2';

class CampaignOverview extends React.Component {
    openCampaignEditForm = () => {
        const { campaignId } = this.props.params;
        const { campaignForm } = this.props;

        campaignForm.open(campaignId);
    };

    openCampaignHistory = () => {
        const { campaignId } = this.props.params;

        // navigate history path
        browserHistory.push(`/campaigns/${campaignId}/history`);
    };

    toggleCampaignStatus = () => {
        const isPaused = this.props.campaign.paused;
        const { campaignId } = this.props.params;

        if (isPaused) {
            this.props.dispatch(unpause(campaignId));
        } else {
            this.props.dispatch(pause(campaignId));
        }
    };

    duplicateCampaign = (payload) => {
        const {
            params: { campaignId },
            dispatch,
        } = this.props;
        return dispatch(duplicateCampaign(campaignId, payload));
    };

    render() {
        const { history, location, campaign } = this.props;

        if (!campaign) {
            return (
                <CenterLayout>
                    <BlockLoadGroup isLoading={true} />
                </CenterLayout>
            );
        }

        return (
            <div className="ef3-campaign-overview__layout-wrapper">
                <CampaignOverviewLayoutV2
                    campaign={this.props.campaign}
                    campaignStats={this.props.campaignStats}
                    campaignHealth={this.props.campaignHealth}
                    history={history}
                    location={location}
                    toggleCampaignStatus={this.toggleCampaignStatus}
                    duplicateCampaign={this.duplicateCampaign}
                    openCampaignEditForm={this.openCampaignEditForm}
                    openCampaignHistory={this.openCampaignHistory}
                    campaignStatsIsLoading={this.props.campaignStatsIsLoading}
                    organizationTimezone={this.props.organizationTimezone}
                    campaignCreator={this.props.campaignCreator}
                    currentFlight={this.props.currentFlight}
                    campaignDataShowing={this.props.campaignDataShowing}
                />
                <div>{this.props.workspace}</div>
                <CampaignFormEditModal campaignId={this.props.params.campaignId} />
            </div>
        );
    }
}

const CampaignOverviewWrapper = props => {
    const campaignForm = useCampaignForm();
    return <CampaignOverview {...props} campaignForm={campaignForm} />;
};

export default connect(selector)(CampaignOverviewWrapper);

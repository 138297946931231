import _ from 'lodash';

import { getDraftValidators_forPayload } from './validation-rules';
import validate from 'utils/validate';

export function validateScheduleReport(draft) {
    const _draft = {
        ...draft,
    };

    const draftValidators = getDraftValidators_forPayload();

    const validators = _.pick(draftValidators, _.keys(_draft));
    return validate(_draft, validators);
}

import toastr from 'toastr';
import { createHttp } from 'utils/http';

const http = createHttp();
const actions = {
    fetchCustomBrands: keywords => {
        return async (dispatch, getState) => {
            const query = `
                query getPoiBrands($keywords: String) {
                    flexBrands(filters: { keywords: $keywords, , source_type: "flex_custom_brand" }) {
                        name
                        id
                        status
                        geoCategory {
                            name
                        }
                        points
                        poi_source
                        uniques
                    }
                }`;
            const variables = {
                keywords,
            };

            try {
                dispatch({
                    type: 'FLEX_CUSTOM_DASHBOARD__FETCH_BRANDS_DATA_START',
                    payload: {},
                });
                const { flexBrands } = await http.graphql(query, variables);
                const filteredBrands = _.filter(flexBrands, segment => segment.id);
                const trimmedFlexBrands = _.map(filteredBrands, brand => {
                    const trimmedName = _.trim(brand.name);
                    return { ...brand, name: trimmedName, id: _.toNumber(brand.id) };
                });
                dispatch({
                    type: 'FLEX_CUSTOM_DASHBOARD__FETCH_BRANDS_DATA_END',
                    payload: {
                        data: trimmedFlexBrands,
                    },
                });
            } catch (error) {
                dispatch({
                    type: 'FLEX_CUSTOM_DASHBOARD__FETCH_BRANDS_ERROR',
                    payload: {},
                });
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

import c from 'common/constants/flux-events';

const initialState = {
    ftaLocationLists: [],
    isLoading: true,
};

export const NAME = 'adHistory';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'OVERVIEW_ADS__HISTORY__INIT': {
            const { ftaLocationLists, organization } = action.payload;

            return {
                ...state,
                ftaLocationLists,
                organization,
                isLoading: true,
            };
        }
        case 'OVERVIEW_ADS__HISTORY__END': {
            return {
                ...state,
                isLoading: false,
            }
        }
        case c.OVERVIEW_ADS__HISTORY__CLOSE: // tear down
            return {
                ...initialState,
            };

        default:
            return state;
    }
}

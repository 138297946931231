import _ from 'lodash';

import c from 'common/constants/flux-events';
import http from 'utils/http';
import flags from 'containers/flags/service';
const actions = {
    getOwn() {
        return (dispatch, getState) => {
            return http()
                .get(`user`)
                .then(
                    user => {
                        dispatch({
                            type: c.SYSTEM__USERS__FETCH__SUCCESS,
                            payload: { user },
                        });

                        return user;
                    },
                    err => {
                        console.error(`GET OWN users error`, err);

                        throw err;
                    }
                );
        };
    },
    getAll() {
        return (dispatch, getState) => {
            return http()
                .get(`users`)
                .then(
                    users => {
                        dispatch({
                            type: c.SYSTEM__USERS__FETCH__SUCCESS,
                            payload: { users },
                        });

                        return users;
                    },
                    err => {
                        console.error(`GET ALL users error`, err);

                        throw err;
                    }
                );
        };
    },
    getByOrgId(orgId) {
        return (dispatch, getState) => {
            return http()
                .get(`users`, { organization: orgId })
                .then(
                    users => {
                        dispatch({
                            type: c.SYSTEM__USERS__FETCH__SUCCESS,
                            payload: { users },
                        });
                        return users;
                    },
                    err => {
                        console.error(`GET BY ORG users error`, err);

                        throw err;
                    }
                );
        };
    },
    get(userId) {
        return (dispatch, getState) => {
            if (!userId) {
                return dispatch(actions.getOwn());
            }
            return http()
                .get(`users/${userId}`)
                .then(
                    user => {
                        dispatch({
                            type: c.SYSTEM__USERS__FETCH__SUCCESS,
                            payload: { user },
                        });

                        return user;
                    },
                    err => {
                        console.error(`GET ONE users error`, err);

                        throw err;
                    }
                );
        };
    },
    create(user) {
        return (dispatch, getState) => {
            return http()
                .post(`users`, user)
                .then(
                    user => {
                        dispatch({
                            type: c.SYSTEM__USERS__FETCH__SUCCESS,
                            payload: { user },
                        });
                        return user;
                    },
                    err => {
                        console.error(`GET OWN users error`, err);

                        throw err;
                    }
                );
        };
    },
    update(userId, user, userEtag) {
        return (dispatch, getState) => {
            let etag;
            etag = userEtag;

            return http()
                .setIfMatch(etag)
                .patch(`users/${userId}`, user)
                .then(
                    user => {
                        dispatch({
                            type: c.SYSTEM__USERS__FETCH__SUCCESS,
                            payload: { user },
                        });
                        return user;
                    },
                    err => {
                        console.error(`UPDATE users error`, err);

                        throw err;
                    }
                );
        };
    },
    delete(userId) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.users.${userId}.attr._etag`);
            return http()
                .setIfMatch(etag)
                .del(`users/${userId}`)
                .then(
                    user => {
                        dispatch({
                            type: c.SYSTEM__USERS__FETCH__SUCCESS,
                            payload: { user },
                        });
                        return user;
                    },
                    err => {
                        console.error(`DELETE users error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default actions;

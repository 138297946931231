import _ from 'lodash';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { connect } from 'react-redux';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import selector from './selector';
import { SimpleSelect } from 'widgets-v6/select';

const useStyles = makeStyles(theme => ({
    locationList: {
        width: 200,
        marginTop: 10,
    },
}));

function FootTrafficAttribution({
    campaignFtaEnabled,
    campaignFtaManagementLevel,
    campaignFtaLocationList,
    partnerId,
    ftaFee,
    ftaLocationListOptions,

    fta,
    onChange,
}) {
    const classes = useStyles();

    const ftaLocationListsMapping = {};
    _.each(ftaLocationListOptions, option => {
        ftaLocationListsMapping[option.value] = option;
    });

    if (campaignFtaEnabled === false) {
        return <div>Disabled</div>;
    }

    function handleChange(changes) {
        const nextFta = {
            ...fta,
            ...changes,
        };

        let locationList;
        if (campaignFtaManagementLevel === 'campaign') {
            locationList = campaignFtaLocationList;
        } else if (nextFta.enabled === false) {
            locationList = null;
        } else {
            locationList = nextFta.location_list;
        }

        onChange({
            fta_fee: nextFta.enabled ? ftaFee : 0,
            fta: {
                line_id: null,
                ...nextFta,
                location_list: locationList,
                partner_id: partnerId,
            },
        });
    }

    return (
        <div>
            {fta.enabled && campaignFtaManagementLevel === 'ad' && (
                <SimpleSelect
                    className={classes.locationList}
                    value={fta.location_list || ''}
                    onChange={e => {
                        handleChange({
                            location_list: e.target.value,
                        });
                    }}
                    options={ftaLocationListOptions}
                    placeholder="Select a location list"
                    required
                />
            )}

            {campaignFtaManagementLevel === 'campaign' && (
                <div>{ftaLocationListsMapping[campaignFtaLocationList].label}</div>
            )}
        </div>
    );
}

export default connect(selector)(FootTrafficAttribution);

import _ from 'lodash';

export const NAME = 'pdsCustomDashboard';

const initialState = {
    audienceBuilderVersion: 1,
    isLoading: false,
    audienceSegments: [],
    totalSegmentCount: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;

        case 'PDS_CUSTOM_DASHBOARD__FETCH_SEGMENTS_DATA__END': {
            return {
                ...state,
                audienceSegments: action.payload.data,
            };
        }

        case 'PDS_CUSTOM_DASHBOARD__INIT_START': {
            return {
                ...state,
                isLoading: true,
            };
        }

        case 'PDS_CUSTOM_DASHBOARD__INIT_END': {
            return {
                ...state,
                isLoading: false,
                totalSegmentCount: action.payload.totalSegmentCount,
                audienceBuilderVersion: action.payload.ownOrganization.audienceBuilderVersion,
            };
        }
    }
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import actions from './actions';
import selector from './selector';

import IntentLayout from './layout';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const IntentDashboard = ({ dispatch, audienceSegments, totalSegmentCount, isLoading, params, organizationId }) => {
    useEffect(() => {
        dispatch(actions.init());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    return (
        <IntentLayout
            audienceSegments={audienceSegments}
            onSearch={onChange}
            segmentCount={totalSegmentCount}
            isLoading={isLoading}
            activeAudience={params.audienceId}
            organizationId={organizationId}
        />
    );
};

export default connect(selector)(IntentDashboard);

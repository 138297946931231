import toastr from 'toastr';
import _ from 'lodash';
import c from 'common/constants/flux-events';
import { can } from 'services/can';
import { createHttp } from 'utils/http';
import notify from 'utils/notify';

import UserActions from 'states/resources/users/actions';
import * as usersServices from 'services/resources/users';
import { PermissionsMapping } from 'common/constants/role-intents';

const http = createHttp();

const actions = {
    openForm(userId, organizationId) {
        return dispatch => {
            dispatch({
                type: 'ADMIN_USERS_FORM__OPEN_FORM',
            });
            dispatch(actions.initializeDraft(userId, organizationId));
        };
    },
    initializeDraft(userId, organizationId) {
        return async dispatch => {
            dispatch({
                type: 'ADMIN_USERS_FORM__DRAFT_INIT_START',
                payload: { userId },
            });
            try {
                const query = `
                    query getOrganizationTypeForAdminUserForm ($orgId: String) {
                        organization (id: $orgId) {
                            type
                        }
                    }
                `;
                const variables = {
                    orgId: organizationId,
                };
                const { organization } = await http.graphql(query, variables);
                dispatch({
                    type: 'ADMIN_USERS_FORM__ORGANIZATION_FETCH_SUCCESS',
                    payload: { organizationType: organization.type },
                });
            } catch (error) {
                notify({ error, metaData: { userId } });
            }
            if (userId) {
                try {
                    const query = `
                        query getUserForAdminUserForm ($id: String) {
                            user (id: $id) {
                                id
                                first_name
                                last_name
                                email
                                global_role
                                organization
                                _etag
                            }
                        }
                    `;
                    const variables = {
                        id: userId,
                    };
                    const { user } = await http.graphql(query, variables);
                    dispatch({
                        type: 'ADMIN_USERS_FORM__DRAFT_INIT_END',
                        payload: { draft: user, etag: user._etag },
                    });
                } catch (error) {
                    notify({ error, metaData: { userId } });
                }
            } else {
                const defaultDraft = {
                    ..._.cloneDeep(usersServices.draftDefaultValues),
                    organization: organizationId,
                };

                dispatch({
                    type: 'ADMIN_USERS_FORM__DRAFT_INIT_END',
                    payload: { draft: defaultDraft },
                });
            }
        };
    },
    updateDraft(field, value) {
        return {
            type: 'ADMIN_USERS_FORM__UPDATE_DRAFT',
            payload: { field, value },
        };
    },
    submitDraft(refreshUsers) {
        return async (dispatch, getState) => {
            const draft = _.get(getState(), `adminUsersFormV2.draft`, void 0);
            const etag = _.get(getState(), `adminUsersFormV2.etag`);
            const errors = _.get(getState(), 'adminUsersFormV2.errors', null);
            const userId = _.get(getState(), 'adminUsersFormV2.userId', null);

            dispatch({
                type: 'ADMIN_USERS_FORM__DRAFT_SUBMIT_START',
                payload: {},
            });

            if (Object.keys(errors).length !== 0) {
                return dispatch({
                    type: 'ADMIN_USERS_FORM__SHOW_ERRORS',
                });
            }

            const meta = { userId };
            const payload = _.cloneDeep(usersServices.serializedForApi(draft, meta));

            if (userId) {
                try {
                    const user = await dispatch(UserActions.update(userId, payload, etag));
                    dispatch({
                        type: 'ADMIN_USERS_FORM__DRAFT_SUBMIT_SUCCESS',
                        payload: {},
                    });

                    // Users for impersonation are stored separately and must be
                    // fetched separately
                    if (can(PermissionsMapping.IMPERSONATE)) {
                        dispatch({
                            type: c.IMPERSONATION_MODAL__REFRESH_USER_SUCCESS,
                            payload: {
                                user,
                            },
                        });
                    }
                    dispatch(actions.closeForm());
                    refreshUsers();
                } catch (error) {
                    notify({ error, metaData: { userId } });
                    dispatch({
                        type: 'ADMIN_USERS_FORM__DRAFT_SUBMIT_FAIL',
                        payload: { error },
                    });
                }
            } else {
                try {
                    const user = await dispatch(UserActions.create(draft));
                    dispatch({
                        type: 'ADMIN_USERS_FORM__DRAFT_SUBMIT_SUCCESS',
                        payload: {},
                    });
                    toastr.success('Email notification sent', 'User created');

                    // Users for impersonation are stored separately and must be
                    // fetched separately
                    if (can(PermissionsMapping.IMPERSONATE)) {
                        dispatch({
                            type: c.IMPERSONATION_MODAL__REFRESH_USER_SUCCESS,
                            payload: {
                                user,
                            },
                        });
                    }
                    dispatch(actions.closeForm());
                    refreshUsers();
                } catch (error) {
                    notify({ error, metaData: { userId } });
                    dispatch({
                        type: 'ADMIN_USERS_FORM__DRAFT_SUBMIT_FAIL',
                        payload: { error },
                    });
                }
            }
        };
    },
    closeForm() {
        return dispatch => {
            dispatch({
                type: 'ADMIN_USERS_FORM__CLOSE_FORM',
            });
        };
    },
};

export default actions;

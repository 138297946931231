// Use hooks to access the state and behaviour of this package
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { REDUCER_KEY, actions } from './reducer';
import { getDraft } from './selectors';

export function useTemplate() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);
    const draft = useSelector(getDraft);

    return {
        ...state,
        ...boundActions,
        draft,
    };
}

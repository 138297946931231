import _ from 'lodash';

import { TAGS } from 'common/constants/vendors';
import {
    templateValidator,
    oldTemplateValidator,
    clickMacroValidator,
} from 'common/richmedia-validators';
const ENGAGEFRONT_JS_TAG_MATCHER = _.find(TAGS, { value: 'engagefront__tag__js' }).test;

import { regEx } from 'utils/regular-expression-library';
const urlValidationRegExp = regEx.url;

export const mraidValidators = {
    content_html(value) {
        if (!value) {
            return 'HTML Markup is required';
        }

        const clickMacroErrorMessage = clickMacroValidator(value);
        if (clickMacroErrorMessage) {
            return clickMacroErrorMessage;
        }

        const isNewCreativeTemplate = null !== value.match(/\<ef\-creative/gim);

        const hasMoreThanAscii = [...value].some(char => char.charCodeAt(0) > 127);

        if (hasMoreThanAscii) {
            return 'HTML Markup has Invalid non-ASCII characters';
        }

        if (!ENGAGEFRONT_JS_TAG_MATCHER(value)) {
            return;
        }

        return isNewCreativeTemplate ? templateValidator(value) : oldTemplateValidator(value);
    },
    reference_image_asset() {
        // optional
    },
    reference_image_preview_url(value, source) {
        if (!value && !source.reference_image_asset) {
            return 'Reference Screenshot is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Reference Screenshot should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_clickthrough(value) {
        if (!_.isBoolean(value)) {
            return 'Third Party Clickthrough is required';
        }
    },
    clickthrough_url(value, source) {
        if (!value && source.third_party_clickthrough === true) {
            return 'Clickthrough URL is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Clickthrough URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    landing_page(value, source) {
        if (!value) {
            return 'Landing Page is required';
        }

        if (value === source.clickthrough_url && source.third_party_clickthrough === true) {
            return 'Landing Page cannot be the same as Clickthrough URL';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Landing Page should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    creative_vendors(value) {
        if (!value.length) {
            return 'A creative vendor must be specified';
        }
    },
    third_party_pixels(value) {
        if (value === undefined) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_pixels to be a list');
        }

        const allValid = _.every(value, pixel => urlValidationRegExp.test(pixel));

        if (!allValid) {
            return `Third Party Pixels should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_javascript_urls(value) {
        if (value === undefined) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_javascript_urls to be a list');
        }

        const allValid = _.every(value, pixel => urlValidationRegExp.test(pixel));

        if (!allValid) {
            return `Third Party JavaScript should start with 'http://', 'https://', or 'tel:'`;
        }
    },
};

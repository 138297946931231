import forEach from 'lodash/forEach';

export const IAS_CONTEXTUAL_TARGETING_OPTIONS = [
    { value: 0, label: 'Arts' },
    { value: 1, label: 'Auto' },
    { value: 2, label: 'Business' },
    { value: 3, label: 'Careers' },
    { value: 4, label: 'Education' },
    { value: 5, label: 'Family' },
    { value: 6, label: 'Finance' },
    { value: 7, label: 'Food' },
    { value: 8, label: 'Health' },
    { value: 9, label: 'Hobbies' },
    { value: 10, label: 'Home' },
    { value: 11, label: 'Law & Government' },
    { value: 12, label: 'News' },
    { value: 13, label: 'Pets' },
    { value: 14, label: 'Real Estate' },
    { value: 15, label: 'Religion' },
    { value: 16, label: 'Science' },
    { value: 17, label: 'Shopping' },
    { value: 18, label: 'Society' },
    { value: 19, label: 'Sports' },
    { value: 20, label: 'Style' },
    { value: 21, label: 'Technology' },
    { value: 22, label: 'Travel' },
    { value: -1, label: 'Unknown' },
];

export const getIASContextualTargetingOptions = () => {
    return IAS_CONTEXTUAL_TARGETING_OPTIONS;
};

export const IAS_CONTEXTUAL_TARGETING_MAPPING = {};

forEach(IAS_CONTEXTUAL_TARGETING_OPTIONS, option => {
    IAS_CONTEXTUAL_TARGETING_MAPPING[option.value] = option.label;
});

import _ from 'lodash';

const actions = {
    open(selectedFlexBrandId) {
        return dispatch => {
            dispatch({
                type: 'FLEX_SEGMENT_FORM__OPEN',
                payload: { selectedFlexBrandId },
            });
        };
    },
    close() {
        return dispatch => {
            dispatch({
                type: 'FLEX_SEGMENT_FORM__CLOSE',
            });
        };
    },
};

export default actions;

import _ from 'lodash';
import flagService from 'containers/flags/service';
import config from '../../../config';

const { API_BASES } = config;
const LOCAL_URL = 'http://localhost:8000/v1/';
let remoteBases = API_BASES.slice();

export function request(args) {
    const {
        method = 'GET',
        url = '',
        data = {},
        headers = {},
        query = {},
        contentType = 'application/json',
    } = args;

    return {
        type: 'HTTP_REQUEST',
        payload: {
            method,
            url,
            data,
            headers,
            query,
            contentType,
        },
    };
}

export function graphqlRequest(args) {
    const { query = '', variables = {} } = args;

    return request({
        url: '/graphql',
        method: 'POST',
        data: {
            query,
            variables,
        },
    });
}

export function getBaseUrl(getState) {
    const apiMode = _.get(getState(), 'devSettings.apiMode', 'remote');

    if (config.ENV_NAME === 'development' && apiMode === 'remote') {
        return 'https://ef-dev.engagecore.com/v1/';
    } else if (config.ENV_NAME === 'development' && apiMode === 'local') {
        return LOCAL_URL;
    } else {
        const url = remoteBases.shift();
        remoteBases.push(url);

        return url;
    }
}

const httpMw = () => ({ getState }) => {
    return next => async action => {
        next(action);

        const { type, payload } = action;
        if (type === 'HTTP_REQUEST') {
            const { method, url, headers, data, query, contentType } = payload;

            const authorization = _.get(getState(), 'profile.authToken.auth_token', '');
            const defaultHeaders = {
                Authorization: authorization,
                'X-API-Client': process.env.VERSION,
                'X-Flags': flagService.getEnabledFlagKeys().join(','),
            };

            const fullUrl =
                getBaseUrl(getState) + (_.startsWith(url, '/') ? url.replace('/', '') : url);
            const result = await new Promise((resolve, reject) => {
                $.ajax({
                    type: method,
                    url: fullUrl,
                    data: _.toUpper(method) === 'GET' ? query : JSON.stringify(data),
                    headers: {
                        ...defaultHeaders,
                        ...headers,
                    },
                    contentType,
                    success: data => {
                        resolve(data);
                    },
                    error: (xhr, status, error) => {
                        reject(error);
                    },
                });
            });

            return result;
        }
    };
};

export const makeMiddlewares = deps => {
    return [httpMw(deps)];
};
export const middlewares = makeMiddlewares({});

import _ from 'lodash';

import { NAME } from './reducer';

export default function selector(storeState) {
    const {
        appLists,
        blackList,
        isBlacklisted,
        loadingId,
        isBlackListLoading,
        appListId,
        appList,
        isEditorOpen,
        advertiserOptions,
        filters,
        ownOrganization,
    } = _.get(storeState, 'appLists');

    const appListEditor = _.get(storeState, `${NAME}`);

    const isNew = _.get(appListEditor, `isNew`);
    const viewState = _.get(appListEditor, `viewState`);

    const draft = _.get(appListEditor, `draft`);

    const isLoading = _.get(appListEditor, `isLoading`);
    const isAppListsLoading = _.get(appListEditor, `isAppListsLoading`);
    const isSyncing = _.get(appListEditor, `isSyncing`);
    const isPaginationLoading = _.get(appListEditor, `isPaginationLoading`);

    const syncError = _.get(appListEditor, `syncError`);

    const onCloseRedirectPath = _.get(storeState, 'modal.onCloseRedirectPath', undefined);

    const showErrors = _.get(appListEditor, `showErrors`);
    const errors_pre = _.get(appListEditor, `errors`);
    const errors = _(errors_pre)
        .map(error => error.message)
        .value();

    const errorMapping = {};
    _.each(errors_pre, error => (errorMapping[error.field] = error.message));

    const invalidBundleAndDomainIDs = _(errors)
        .map(error => _.split(error, ','))
        .flatten()
        .value();
    
    const advertiserOptionsForSelect = _.map(advertiserOptions, advertiser => {
        return { label: advertiser.name, value: advertiser.id };
    });
    const out = {
        onCloseRedirectPath,
        isLoading,
        isBlackListLoading,
        isAppListsLoading,
        isPaginationLoading,
        isSyncing,
        draft,
        isNew,
        syncError,
        viewState,
        showErrors,
        errors,
        blackList,
        isBlacklisted,
        appLists,
        appListId,
        loadingId,
        appList,
        isEditorOpen,
        errorMapping,
        invalidBundleAndDomainIDs,
        advertiserOptions: advertiserOptionsForSelect,
        filters,
        ownOrganization,
    };
    return out;
}

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import c from 'common/constants/flux-events';
import selector from './selector';
import { browserHistory } from 'react-router';
import { fileUploadSubmit } from 'states/resources/campaigns/actions';
import { CreativeEdit as actions } from 'pages/campaigns/creatives/form/creative-edit-form/actions';

import CreativeForm from 'forms/creative-form';

class EditCreativeFormWrapper extends React.Component {
    static defaultProps = {
        getReturnUrl(params) {
            return `/campaigns/${params.campaignId}/setup/creatives`;
        },
    };

    submit = draft => {
        const { campaignId, creativeId, params, isSubmiting, dispatch } = this.props;

        if (isSubmiting) {
            return;
        }

        const returnURL = this.props.getReturnUrl(params);

        return dispatch(actions.submitDraft(draft, campaignId, creativeId)).then(() => {
            browserHistory.push(returnURL);
        });
    };

    submitAndAudit = async draft => {
        const { campaignId, creativeId, params, isSubmiting, dispatch } = this.props;

        if (isSubmiting) {
            return;
        }

        const returnURL = this.props.getReturnUrl(params);
        return dispatch(actions.submitDraftAndAudit(draft, campaignId, creativeId)).then(() => {
            browserHistory.push(returnURL);
        });
    };

    getInitialDraft = () => {
        const { dispatch, campaignId, creativeId } = this.props;
        return dispatch(actions.getInitialDraft(campaignId, creativeId));
    };

    handleClose = () => {
        const { campaignId, creativeId, dispatch, closeModal, params, isSubmiting } = this.props;

        if (isSubmiting) {
            return;
        }

        const returnURL = this.props.getReturnUrl(params);
        dispatch(actions.close(campaignId, creativeId));

        browserHistory.push(returnURL);
    };

    uploadFile = (formData, campaignId) => {
        const { dispatch } = this.props;

        return dispatch(fileUploadSubmit(formData, campaignId));
    };

    render() {
        return (
            <CreativeForm
                mode="edit"
                closeAction={this.handleClose}
                warnings={this.props.warnings}
                campaignId={this.props.campaignId}
                creativeId={this.props.creativeId}
                submit={this.submit}
                submitAndAudit={this.submitAndAudit}
                getInitialDraft={this.getInitialDraft}
                uploadFile={this.uploadFile}
            />
        );
    }
}

const CreativeEditFormConnected = connect(selector)(EditCreativeFormWrapper);

export function provideCreativeEdit(props) {
    class ProvideCreativeEdit extends React.Component {
        render() {
            return <CreativeEditFormConnected {...this.props} {...props} />;
        }
    }

    return ProvideCreativeEdit;
}

export default CreativeEditFormConnected;

import _ from 'lodash';

export const NAME = 'flexCustomDashboard';

const initialState = {
    loading: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;

        case 'FLEX_CUSTOM_DASHBOARD__FETCH_BRANDS_DATA_END': {
            return {
                ...state,
                brands: action.payload.data,
                loading: false,
            };
        }
        case 'FLEX_CUSTOM_DASHBOARD__FETCH_BRANDS_DATA_START': {
            return {
                ...state,
                loading: false,
            };
        }
        case 'FLEX_CUSTOM_DASHBOARD__FETCH_BRANDS_ERROR': {
            return {
                ...state,
                loading: false,
            };
        }
    }
}

import _ from 'lodash';

const actions = {
    init() {
        return {
            type: 'PICKER_SELECT_TABLE__AUDIENCES__FETCH_START',
        };
    },
    filterByText(keywords) {
        return (dispatch, getState) => {
            dispatch({
                type: 'PICKER_SELECT_TABLE__AUDIENCES__FILTER_START',
                payload: { keywords },
            });
            const options = _.get(getState(), 'audienceSegmentPickerModal.audienceSegments');
            const filteredAudienceSegments = _.filter(
                options,
                option =>
                    _.includes(_.toLower(option.name), _.toLower(keywords)) ||
                    _.includes(_.toLower(option.description), _.toLower(keywords))
            );
            dispatch({
                type: 'PICKER_SELECT_TABLE__AUDIENCES__FILTER_END',
                payload: { filteredAudienceSegments },
            });
        };
    },
    changeTab(tab) {
        return {
            type: 'PICKER_SELECT_TABLE__AUDIENCES__TAB_CHANGE',
            payload: {
                tab,
            },
        };
    },
    changeSourceTypeFilter(sourceType) {
        return {
            type: 'PICKER_SELECT_TABLE__AUDIENCES__SOURCE_TYPE_CHANGE',
            payload: {
                sourceType,
            },
        };
    },
};

export default actions;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormField from 'widgets-v5/form-field';
import { SimpleSelect } from 'widgets-v6/select';
import flags from 'containers/flags/service';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';

const useStyles = makeStyles({
    locationList: {
        width: 200,
        margin: '10 0 0 28',
    },
    helperText: {
        marginLeft: 28,
    },
    radioButtons: {
        marginLeft: 18,
    },
    toggleHelperText: {
        marginLeft: 48,
    },
    FTALabel: {
        color: '#0eafa0',
    },
});

function FootTrafficAttribution({
    ftaEnabled,
    ftaManagementLevel,
    ftaLocationList,
    locationListOptions,
    onChange,
    ftaLocationListError,
    ftaManagementLevelError,
    showErrors,
    campaignType
}) {
    const classes = useStyles();

    function handleChange(changes) {
        const nextState = {
            fta_enabled: ftaEnabled,
            fta_management_level: ftaManagementLevel,
            fta_location_list: ftaLocationList,
            ...changes,
        };

        if (nextState.ftaEnabled === false) {
            onChange({
                fta_enabled: false,
                fta_management_level: null,
                fta_location_list: null,
            });
            return;
        }

        onChange({
            ...nextState,
            fta_location_list:
                nextState.fta_management_level === 'ad' ? null : nextState.fta_location_list,
        });
    }

    function getLocationListsMapping() {
        const locationListsMapping = {};

        _.each(locationListOptions, option => {
            locationListsMapping[option.value] = option;
        });

        return locationListsMapping;
    }

    const locationListMapping = getLocationListsMapping();

    return (
        <Grid container direction="column">
            <FormControl>
                <FormGroup aria-label="position">
                    <FormControlLabel
                        value="end"
                        control={
                            <Switch
                                checked={!!ftaEnabled}
                                onChange={e => {
                                    handleChange({ fta_enabled: e.target.checked });
                                }}
                                color="primary"
                            />
                        }
                        label={
                            campaignType === CampaignTypeMapping.Standard
                                ? 'Enable Foot Traffic Attribution'
                                : 'Enable Foot Traffic Attribution (beta)'
                        }
                        labelPlacement="end"
                    />
                    <FTALabelDescription campaignType={campaignType} classes={classes} />
                </FormGroup>
            </FormControl>
            {!!ftaEnabled && (
                <FormControl className={classes.radioButtons}>
                    <RadioGroup
                        name="ftaManagementLevel"
                        value={ftaManagementLevel || ''}
                        onChange={e => {
                            handleChange({ fta_management_level: e.target.value });
                        }}
                    >
                        <FormControlLabel
                            value="campaign"
                            control={<Radio color="primary" />}
                            label="Enable for all ads"
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            gutterBottom
                            className={classes.helperText}
                        >
                            Apply FTA settings to all newly created ads in this campaign
                            automatically.
                        </Typography>
                        {ftaManagementLevel === 'campaign' && (
                            <>
                                <FormField
                                    errors={ftaLocationListError}
                                    showErrors={showErrors}
                                    isRequired={true}
                                >
                                    <SimpleSelect
                                        className={classes.locationList}
                                        label="Location List"
                                        value={ftaLocationList || ''}
                                        onChange={e => {
                                            handleChange({
                                                fta_location_list: e.target.value,
                                            });
                                        }}
                                        options={locationListOptions}
                                        placeholder="Select a location list"
                                        required
                                    />
                                </FormField>
                                {flags.isEnabled(
                                    'EN_3673_show_warning_when_ftaLocationList_status_is_processing'
                                ) &&
                                    _.get(locationListMapping, `${ftaLocationList}.status`) ===
                                        'GIS_PROCESSING' && (
                                        <WarningText
                                            message={
                                                'The selected location list is still being processed. Foot Traffic Attribution reports will not be available yet.'
                                            }
                                        />
                                    )}
                            </>
                        )}
                        <FormField
                            errors={ftaManagementLevelError}
                            showErrors={showErrors}
                            isRequired={true}
                        >
                            <React.Fragment>
                                <FormControlLabel
                                    value="ad"
                                    control={<Radio color="primary" />}
                                    label="Enable per ad"
                                />
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    gutterBottom
                                    className={classes.helperText}
                                >
                                    Select this if some ads do not require FTA, or if different
                                    ads require attribution to different Location Lists.
                                </Typography>
                            </React.Fragment>
                        </FormField>
                    </RadioGroup>
                </FormControl>
            )}
        </Grid>
    );
}

function FTALabelDescription({ campaignType, classes }) {
    return (
        <>
            {campaignType === CampaignTypeMapping.CTV && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                        className={classes.toggleHelperText}
                    >
                        Foot traffic attribution for Connected TV is a beta feature.{' '}
                        <a
                            href={
                                'https://www.notion.so/engagefront/Connected-TV-CTV-Campaigns-3cdea09e5d8d4970a38d10de2af2c7b6'
                            }
                            target="__blank"
                            className={classes.FTALabel}
                        >
                            See limitations.
                        </a>
                        <br />A data cost of $1.50 CPM will be added to applicable ads.
                    </Typography>
                )}
            {campaignType === CampaignTypeMapping.Standard && (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                    className={classes.toggleHelperText}
                >
                    A data cost of $1.50 CPM will be added to applicable ads.
                </Typography>
            )}
        </>
    );
}

export default FootTrafficAttribution;

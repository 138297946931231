import toastr from 'toastr';
import FileSaver from 'utils/file-saver';

import http from 'utils/http';
import { createHttp } from 'utils/http';
import flags from 'containers/flags/service';

const source_type = ['ad', 'campaign', 'pixel'];

const actions = {
    init: () => {
        return async dispatch => {
            dispatch({
                type: 'RETARGETING_DASHBOARD__INIT_START',
            });
            const { totalSegmentCount } = await dispatch(actions.getAudiences());

            dispatch({
                type: 'RETARGETING_DASHBOARD__INIT_END',
                payload: { totalSegmentCount },
            });
        };
    },
    getAudiences: keywords => {
        return async (dispatch) => {
            try {
                dispatch({
                    type: 'RETARGETING_DASHBOARD__FETCH_SEGMENTS_DATA__START',
                });

                const http = createHttp();

                let query;
                if (flags.isEnabled('en_2987_use_audience_category_and_subCategory_code')) {
                    query = `
                    query getAudiences ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                            dataCost
                            id
                            lastRefreshed
                            match_rate
                            mode
                            name
                            status
                            uniques
                            match_rate
                            _created
                            supports
                            subCategory
                            frequency
                        }
                    }
                `;
                } else {
                    query = `
                    query getAudiences ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                            dataCost
                            id
                            lastRefreshed
                            match_rate
                            mode
                            name
                            source_type
                            status
                            uniques
                            match_rate
                            _created
                            supports
                            retargeting_type
                            frequency
                        }
                    }
                `;
                }

                const variables = {
                    keywords,
                    source_type,
                    deprecated: false,
                };

                const data = await http.graphql(query, variables);

                dispatch({
                    type: 'RETARGETING_DASHBOARD__FETCH_SEGMENTS_DATA__END',
                    payload: {
                        data: data.audienceSegments,
                    },
                });

                return { totalSegmentCount: data.audienceSegments.length };
            } catch (err) {
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
    downloadAudience(id, name) {
        return (dispatch) => {
            dispatch({
                type: 'AUDIENCE_DASHBOARD__DOWNLOAD__START',
                payload: { audienceIdBeingDownloaded: id },
            });

            return http()
                .get(`audience-download/${id}`)
                .then(
                    response => {
                        FileSaver.saveAs(
                            new Blob([response], { type: `text/plain;charset=utf-8` }),
                            `${name}.csv`
                        );

                        dispatch({
                            type: 'AUDIENCE_DASHBOARD__DOWNLOAD__END',
                            payload: {},
                        });
                    },
                    err => {
                        dispatch({
                            type: 'AUDIENCE_DASHBOARD__DOWNLOAD__END',
                            payload: {},
                        });

                        throw err;
                    }
                );
        };
    },
};

export default actions;

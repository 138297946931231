import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCampaignSummary } from './hook';
import flags from 'containers/flags/service';
import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { SummaryLayout, SummaryGroup } from 'widgets/summary-view/summary-layout';
import SummaryTypeText from 'widgets/summary-view/summary-type-text';
import SummaryTypeComponent from 'widgets/summary-view/summary-type-component';
import SummaryTypeTextCollection from 'widgets/summary-view/summary-type-text-collection';
import SummaryTypeCreative from 'widgets/summary-view/summary-type-creative';
import SummaryTypeGeoTargeting from 'widgets/summary-view/summary-type-geo-targeting';
import Spacer from 'widgets-v5/spacer';
import { DialogTitle } from 'widgets-v6/modal';

import IABCategories from 'common/constants/iab-categories';

import { formatNumber_currency, formatNumber_percentage } from 'utils/formatting';
import { getEnvironmentSettings } from 'services/environment';
import { canViewCampaignCreator } from 'states/resources/campaigns/business-logic';
import { isInternalUser } from 'states/profile/business-rules';
import { SummaryContent } from './summary-content';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 550,
        padding: theme.spacing(2),
    },
}));

export function Summary(props) {
    const classes = useStyles();

    const { campaignId } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Tooltip title={<Typography variant="body2">Summary</Typography>}>
                <IconButton onClick={handleClick} size="small" className={classes.actionButton}>
                    <AssignmentIcon />
                </IconButton>
            </Tooltip>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.drawer }}
            >
                <SummaryContent
                    campaignId={campaignId}
                    hasDialogTitle
                    dialogClose={handleClose}
                    subTitle="Summary"
                />
            </Drawer>
        </React.Fragment>
    );
}

import _ from 'lodash';
import c from 'common/constants/flux-events';

export default function campaigns(state = {}, action) {
    const campaignId = _.get(action, 'payload.campaignId');
    if (!campaignId) {
        return state;
    }
    return {
        ...state,
        [campaignId]: reducer(state[campaignId], action),
    };
}

export const NAME = 'resources.history.campaigns';
const initialState = [];

function reducer(state = initialState, action) {
    const campaignId = _.get(action, 'payload.campaignId');

    switch (action.type) {
        case c.SYSTEM__CAMPAIGN_HISTORY__FETCH__SUCCESS:
            return _.get(action, 'payload.history', []);

        default:
            return state;
    }
}

import React from 'react';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import StandardInput from 'widgets-v5/standard-input';
import CreativeAutoComplete from 'widgets-v5/creative-auto-complete';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import {
    CreativeVendorSelector,
    TrackingVendorsSelector,
} from '../vendor-selector/vendor-selector';
import ThirdPartyPixelsInput from '../third-party-pixels-input';
import { creativeTrackingVendorsMapping, StyledLink } from '../../index';

const useStyles = makeStyles(theme => ({
    thirdPartyVendors: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function CreativeFormThirdPartyVastType(props) {
    const classes = useStyles();
    const { errors, draft, onFieldChange, showErrors, activeStep, updateVendors } = props;

    if (activeStep === 1) {
        return (
            <div>
                <div>
                    <AccordionItem
                        onClick={props.expandPanel('hostedVastUrl')}
                        expanded={props.expandedPanel === 'hostedVastUrl'}
                        title="Hosted VAST URL"
                        required
                        error={showErrors && errors.hosted_vast_url}
                        details={
                            <React.Fragment>
                                <div>
                                    <CreativeAutoComplete
                                        value={props.draft.hosted_vast_url}
                                        placeholder="http://', 'https://', or
                                    'tel:"
                                        onChange={onFieldChange('hosted_vast_url')}
                                    />
                                </div>
                                <CreativeVendorSelector
                                    showErrors={showErrors}
                                    errors={errors}
                                    draft={draft}
                                    onFieldChange={onFieldChange}
                                    updateVendors={updateVendors}
                                    creativeTagsMatched={props.creativeTagsMatched}
                                    creativeType={'third_party_vast'}
                                />
                            </React.Fragment>
                        }
                        summary={
                            <div>
                                <div>
                                    {draft.hosted_vast_url ? (
                                        <StyledLink>{draft.hosted_vast_url}</StyledLink>
                                    ) : (
                                        <Typography color="textSecondary">
                                            Add hosted VAST URL
                                        </Typography>
                                    )}
                                </div>
                                <div>
                                    {' '}
                                    {draft.creative_vendors.length > 0 &&
                                    draft.creative_vendors[0] ? (
                                        <div className={classes.thirdPartyVendors}>
                                            {_.map(draft.creative_vendors, vendor => {
                                                return (
                                                    <Chip
                                                        key={vendor}
                                                        label={
                                                            creativeTrackingVendorsMapping[
                                                                vendor
                                                            ] || vendor
                                                        }
                                                        variant="outlined"
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <Typography color="textSecondary">
                                            Add creative vendor
                                        </Typography>
                                    )}{' '}
                                </div>
                            </div>
                        }
                    />
                    <AccordionItem
                        onClick={props.expandPanel('referenceScreenshot')}
                        expanded={props.expandedPanel === 'referenceScreenshot'}
                        title="Reference Screenshot"
                        error={showErrors && errors.reference_screenshot}
                        details={
                            <CreativeAutoComplete
                                value={props.draft.reference_screenshot}
                                placeholder="Enter URL"
                                onChange={onFieldChange('reference_screenshot')}
                            />
                        }
                        summary={
                            draft.reference_screenshot ? (
                                <div>{draft.reference_screenshot}</div>
                            ) : (
                                <Typography color="textSecondary">
                                    Add reference screenshot
                                </Typography>
                            )
                        }
                    />
                    <AccordionItem
                        onClick={props.expandPanel('duration')}
                        expanded={props.expandedPanel === 'duration'}
                        title="Duration"
                        required
                        error={showErrors && errors.video_duration}
                        details={
                            <div>
                                <StandardInput
                                    placeholder="Number of seconds"
                                    formatIn={value => {
                                        if (!value) {
                                            return '';
                                        } else {
                                            return value.toString();
                                        }
                                    }}
                                    formatOut={string => {
                                        if (string === '') {
                                            return void 0;
                                        } else {
                                            return parseInt(string);
                                        }
                                    }}
                                    shouldAllowInput={input => {
                                        // Allow empty input
                                        if (!input) {
                                            return true;
                                        }
                                        // Check if all digits
                                        const isDigits = /^\d+$/.test(input);
                                        if (isDigits) return true;
                                        return false;
                                    }}
                                    onChange={onFieldChange('video_duration')}
                                    value={props.draft.video_duration}
                                />
                                <FormHelperText>Duration must be between 1 and 30</FormHelperText>
                            </div>
                        }
                        summary={
                            draft.video_duration ? (
                                <div>{draft.video_duration}</div>
                            ) : (
                                <Typography color="textSecondary">Add duration</Typography>
                            )
                        }
                    />

                    <AccordionItem
                        onClick={props.expandPanel('bitRate')}
                        expanded={props.expandedPanel === 'bitRate'}
                        title="Bit Rate (Kbps)"
                        error={showErrors && errors.video_bit_rate_kbps}
                        details={
                            <div>
                                <StandardInput
                                    placeholder="Kilobytes per second"
                                    formatIn={value => {
                                        if (!value) {
                                            return '';
                                        } else {
                                            return value.toString();
                                        }
                                    }}
                                    formatOut={string => {
                                        if (string === '') {
                                            return void 0;
                                        } else {
                                            return parseInt(string);
                                        }
                                    }}
                                    shouldAllowInput={input => {
                                        // Allow empty input
                                        if (!input) {
                                            return true;
                                        }
                                        // Check if all digits
                                        const isDigits = /^\d+$/.test(input);
                                        if (isDigits) return true;
                                        return false;
                                    }}
                                    onChange={onFieldChange('video_bit_rate_kbps')}
                                    value={props.draft.video_bit_rate_kbps}
                                />
                                <FormHelperText>Bit Rate must be a number</FormHelperText>
                            </div>
                        }
                        summary={
                            draft.video_bit_rate_kbps ? (
                                <div>{draft.video_bit_rate_kbps}</div>
                            ) : (
                                <Typography color="textSecondary">Add bit rate (Kbps)</Typography>
                            )
                        }
                    />
                    <AccordionItem
                        onClick={props.expandPanel('vraid')}
                        expanded={props.expandedPanel === 'vraid'}
                        title="VPAID"
                        error={showErrors && errors.video_bit_rate_kbps}
                        details={
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={draft.capabilities.contains_vpaid}
                                            color="primary"
                                            onChange={e =>
                                                onFieldChange('capabilities')({
                                                    contains_vpaid: !draft.capabilities
                                                        .contains_vpaid,
                                                })
                                            }
                                            value={draft.capabilities.contains_vpaid}
                                        />
                                    }
                                    label="Contains VPAID"
                                />
                            </FormControl>
                        }
                        summary={
                            <div>{draft.capabilities.contains_vpaid ? 'Enabled' : 'Disabled'}</div>
                        }
                    />
                    <AccordionItem
                        onClick={props.expandPanel('landingPage')}
                        expanded={props.expandedPanel === 'landingPage'}
                        title="Clickthrough Landing Page URL"
                        required
                        error={showErrors && errors.landing_page}
                        details={
                            <div>
                                <CreativeAutoComplete
                                    value={draft.landing_page}
                                    placeholder="http://', 'https://', or 'tel:"
                                    onChange={onFieldChange('landing_page')}
                                />
                            </div>
                        }
                        summary={
                            draft.clickthrough_url ? (
                                <StyledLink>{draft.clickthrough_url}</StyledLink>
                            ) : (
                                <Typography color="textSecondary">
                                    Add clickthrough landing page
                                </Typography>
                            )
                        }
                    />
                    <AccordionItem
                        onClick={props.expandPanel('thirdPartyPixels')}
                        expanded={props.expandedPanel === 'thirdPartyPixels'}
                        title="Third Party Pixels"
                        error={showErrors && errors.third_party_pixels}
                        details={
                            <ThirdPartyPixelsInput
                                onChange={onFieldChange}
                                third_party_quartile_pixels={draft.third_party_quartile_pixels}
                                third_party_pixels={draft.third_party_pixels}
                                error={showErrors && errors.third_party_pixels}
                                canAddVastPixels={draft.format === 'video'}
                            />
                        }
                        summary={
                            draft.third_party_pixels.length > 0 ? (
                                <div>
                                    {_.map(draft.third_party_pixels, pixel => (
                                        <StyledLink key={pixel}>{pixel}</StyledLink>
                                    ))}
                                </div>
                            ) : (
                                <Typography color="textSecondary">
                                    Add third party pixels
                                </Typography>
                            )
                        }
                    />
                    <AccordionItem
                        onClick={props.expandPanel('trackingVendors')}
                        expanded={props.expandedPanel === 'trackingVendors'}
                        title="Tracking Vendors"
                        error={props.showErrors && errors.tracking_vendors}
                        details={
                            <div>
                                <TrackingVendorsSelector
                                    showErrors={showErrors}
                                    errors={errors}
                                    draft={draft}
                                    onFieldChange={onFieldChange}
                                    updateVendors={updateVendors}
                                    trackingTagsMatched={props.trackingTagsMatched}
                                    creativeType={'third_party_vast'}
                                />
                            </div>
                        }
                        summary={
                            draft.tracking_vendors.length > 0 ? (
                                <div className={classes.thirdPartyVendors}>
                                    {_.map(draft.tracking_vendors, vendor => {
                                        return (
                                            <Chip
                                                key={vendor}
                                                label={
                                                    creativeTrackingVendorsMapping[vendor] || vendor
                                                }
                                                variant="outlined"
                                            />
                                        );
                                    })}
                                </div>
                            ) : (
                                <Typography color="textSecondary">Add tracking vendors</Typography>
                            )
                        }
                    />
                </div>
            </div>
        );
    }

    return null;
}

import _ from 'lodash';
import React from 'react';
import { browserHistory } from 'react-router';

import CreativeRotationSelector from '../creative-rotation-selector';
import { NeutralButton } from 'widgets-v5/buttons';

import {
    MAX_TOTAL_CREATIVE_ROTATION_WEIGHT,
} from '../../reducer';


class Creative extends React.Component {
    // Creative manipulation
    openCreativeNewForm = () => {
        const { campaignId, adId } = this.props;

        const pathname = window.location.pathname;
        const isNewAdForm = /^.*setup\/ads\/new$/.test(pathname);
        const isNew = !adId;

        if (isNew) {
            const url = `/campaigns/${campaignId}/setup/ads/new/creatives/new`;
            browserHistory.push(isNewAdForm ? `${url}?tactics=1` : url);
        } else {
            browserHistory.push(`/campaigns/${campaignId}/setup/ads/${adId}/edit/creatives/new`);
        }
    };

    editCreative = creativeId => {
        browserHistory.push(`${window.location}/creatives/${creativeId}/edit`);
    };

    duplicateCreative = creativeId => {
        browserHistory.push(`${window.location}/creatives/${creativeId}/duplicate`);
    };

    // Rotation settings manipulation
    changeWeight = (creativeId, newWeight) => {
        this.props.changeWeight(creativeId, newWeight);
    };

    shouldUpdateWeight = creativeId => {
        const { weighted } = this.props.formData.rotation_rules;

        return newWeight => {
            // User clears the field?
            if (newWeight === '') {
                return true;
            }

            if (/[^0-9]/.test(newWeight)) {
                return false;
            }

            const parsedWeight = parseInt(newWeight);

            // The user enters non numeric input?
            if (Number.isNaN(parsedWeight)) {
                return false;
            }

            const totalWeightForOtherCreatives = _(weighted)
                .filter(weighted => creativeId !== weighted.markup_id)
                .map(weighted => weighted.weight)
                .sum();

            return (
                parsedWeight + totalWeightForOtherCreatives <= MAX_TOTAL_CREATIVE_ROTATION_WEIGHT
            );
        };
    };

    switchMode = newMode => {
        let selectedCreatives = _(this.props.creatives)
            .filter(c => c.selected)
            .value();

        this.props.switchMode(newMode, selectedCreatives);
    };

    render() {
        const {
            formData,

            errors,
            showErrors,

            creatives,
            rotationMode,
            assignedPacing,
            pacingColumnEnabled,
            totalWeight,
            scheduledRotationWarningEnabled,
            campaign,
            disableCreatingCreative,
            disableScheduledRotation,
            disableEditingCreative,
            disableDuplicatingCreative,
            showWeightColumn,
            hasConversions,
            onCreativeRotationByWeatherChange,
            isCrossPlatformCampaign,
            creativeWarning,
            isCTVCampaign,
        } = this.props;
        const displayCreatingCreativeLink = !disableCreatingCreative;

        return (
            <div>
                {displayCreatingCreativeLink && (
                    <div className="ef6-alignment__right">
                        <NeutralButton
                            onClick={this.openCreativeNewForm}
                            icon={<i className="fa fa-plus" />}
                            text="New Creative"
                        />
                    </div>
                )}
                {disableScheduledRotation && (
                    <div className="ef6-alignment__right">
                        Selected ads must have the same flight when using Scheduled Rotation.
                    </div>
                )}
                <CreativeRotationSelector
                    hasConversions={hasConversions}
                    // Creative manipulation
                    editCreative={this.editCreative}
                    duplicateCreative={this.duplicateCreative}
                    // Rotation settings manipulation
                    changeWeight={this.changeWeight}
                    switchMode={this.switchMode}
                    shouldUpdateWeight={this.shouldUpdateWeight}
                    updateScheduleRow={this.props.updateScheduleRow}
                    addScheduleRow={this.props.addScheduleRow}
                    removeScheduleRow={this.props.removeScheduleRow}
                    selectSingleCreative={this.props.selectSingleCreative}
                    selectEvenCreative={this.props.selectEvenCreative}
                    selectWeightedCreative={this.props.selectWeightedCreative}
                    clearCreatives={this.props.clearCreatives}
                    onCreativeRotationByWeatherChange={onCreativeRotationByWeatherChange}
                    // Data
                    creativeWarning={creativeWarning}
                    showWeightColumn={showWeightColumn}
                    rotationMode={rotationMode}
                    primaryPacing={formData.primary_pacing}
                    assignedPacing={assignedPacing}
                    pacingColumnEnabled={pacingColumnEnabled}
                    creatives={creatives}
                    totalWeight={totalWeight}
                    rotationRules={formData.rotation_rules}
                    scheduledCreatives={formData.rotation_rules.scheduled}
                    weatherRotationSettings={formData.rotation_rules.weather}
                    adStart={formData.start}
                    adEnd={formData.end}
                    adTimezone={formData.timezone}
                    scheduledRotationWarningEnabled={scheduledRotationWarningEnabled}
                    campaign={this.props.campaign}
                    mode={this.props.mode}
                    disableScheduledRotation={disableScheduledRotation}
                    disableEditingCreative={disableEditingCreative}
                    disableDuplicatingCreative={disableDuplicatingCreative}
                    campaignBudgetAllocationMethod={campaign.budget_allocation_method}
                    errors={errors}
                    showErrors={showErrors}
                    isRequired={true}
                    addDateRange={this.props.addDateRange}
                    removeMultiCreatives={this.props.removeMultiCreatives}
                    removeScheduledWeightedCreative={this.props.removeScheduledWeightedCreative}
                    removeWarningMessage={this.props.removeWarningMessage}
                    removeDateRange={this.props.removeDateRange}
                    changeScheduledWeightedCreativeEndDate={
                        this.props.changeScheduledWeightedCreativeEndDate
                    }
                    selectScheduledWeightedCreative={this.props.selectScheduledWeightedCreative}
                    changeScheduledWeightedCreativeWeight={
                        this.props.changeScheduledWeightedCreativeWeight
                    }
                    changeScheduledEvenWeighted={this.props.changeScheduledEvenWeighted}
                    addScheduledWeightedCreative={this.props.addScheduledWeightedCreative}
                    isCrossPlatformCampaign={isCrossPlatformCampaign}
                    isCTVCampaign={isCTVCampaign}
                />
            </div>
        );
    }
}

export default Creative;

import _ from 'lodash';
import c from 'common/constants/flux-events';

// import AdEdit from 'pages/ad-form/ad-edit-form/actions';
// import AdNew from 'pages/ad-form/ad-new-form/actions';
import AdFormAction from 'forms/ad-form/actions';
import Ads from 'states/resources/ads/actions';
import GeoCategories from 'states/resources/geo-categories/actions';

export const poiEditor = {
    open(campaignId, adId, geoEntity /*, geoLayerId */) {
        return dispatch => {
            dispatch({
                type: c.POI_EDITOR__OPEN,
                payload: { campaignId, adId, geoEntity },
            });

            const geoLayerId = _.get(geoEntity, `id`, void 0);
            const geoLayerType = _.get(geoEntity, `type`, void 0);

            if (geoLayerId) {
                dispatch({
                    type: c.POI_EDITOR__INITIALIZE__START,
                    payload: { note: 'no-payload' },
                });

                Promise.all(
                    adId
                        ? [
                              dispatch(GeoCategories.getLayer(geoLayerId)),
                              dispatch(Ads.get(campaignId, adId)),
                          ]
                        : [dispatch(GeoCategories.getLayer(geoLayerId))]
                ).then(
                    ([layer]) => {
                        const { name, points, radius } = layer;
                        dispatch({
                            type: c.POI_EDITOR__INITIALIZE__SUCCESS,
                            payload: {
                                draft: {
                                    name,
                                    points,
                                    radius,
                                },
                                geoEntity,
                            },
                        });
                    },
                    error => {
                        dispatch({
                            type: c.POI_EDITOR__INITIALIZE__FAIL,
                            payload: { error: JSON.stringify(error) },
                        });
                    }
                );
            } else {
                const layerName = geoLayerType === 'implicit' ? '___implicit' : '';
                const draft_blank = {
                    name: layerName,
                    points: [],
                    radius: '500',
                };

                dispatch({
                    type: c.POI_EDITOR__INITIALIZE__SUCCESS,
                    payload: { draft: _.cloneDeep(draft_blank), geoEntity },
                });
            }
        };
    },

    close() {
        return dispatch => {
            dispatch({
                type: c.POI_EDITOR__CLOSE,
                payload: {},
            });
        };
    },

    addPoi(poi) {
        return dispatch => {
            dispatch({
                type: c.POI_EDITOR__DRAFT_ADD_POI,
                payload: { poi },
            });
        };
    },

    removePoiById(id_poi) {
        return dispatch => {
            dispatch({
                type: c.POI_EDITOR__DRAFT_REMOVE_POI_BYID,
                payload: { id: id_poi },
            });
        };
    },

    updateDraft(fieldName, value) {
        return (dispatch, getState) => {
            const draft = _.get(getState(), 'poiEditor.draft', void 0);
            const draft_sanitized = _.cloneDeep(draft);
            dispatch({
                type: c.POI_EDITOR__DRAFT_UPDATE,
                payload: {
                    nextDraft: {
                        ...draft_sanitized,
                        [fieldName]: value,
                    },
                },
            });
        };
    },

    saveDraft(done) {
        // See documentation for action logic flow chart
        // https://addictive.atlassian.net/wiki/display/CONSOLE/poi+editor%3A+save+action+flow+chart
        return (dispatch, getState) => {
            const draftErrors = _.get(getState(), 'poiEditor.draftErrors', void 0);
            if (draftErrors.length !== 0) {
                dispatch({ type: c.POI_EDITOR__SHOWERROR, payload: { draftErrors } });
                return;
            }

            const campaignId = _.get(getState(), `poiEditor.campaignId`, void 0);
            const adId = _.get(getState(), `poiEditor.adId`, void 0);

            const layerId = _.get(getState(), 'poiEditor.geoEntity.id', void 0);
            const layerType = _.get(getState(), 'poiEditor.geoEntity.type', void 0);

            const draft = _.get(getState(), 'poiEditor.draft', void 0);
            const draft_sanitized = _.cloneDeep(draft);

            draft_sanitized.points.forEach(point => {
                delete point.id;
                delete point.pointId;
            });

            draft_sanitized.radius = parseInt(draft_sanitized.radius);

            if (layerId) {
                // @ Update geo-layer
                if (draft_sanitized.points.length !== 0) {
                    // @@ there are no distingtion btw implicit and custom layer ( the only thing that identify implicit is the name '___implicit')
                    dispatch({
                        type: c.POI_EDITOR__SAVE__START,
                        payload: { layerId, draft: draft_sanitized },
                    });
                    Promise.all([
                        dispatch(GeoCategories.updateLayer(layerId, draft_sanitized)),
                    ]).then(
                        ([layer]) => {
                            dispatch({
                                type: c.POI_EDITOR__SAVE__SUCCESS,
                                payload: { draft: layer },
                            });
                            dispatch({ type: c.POI_EDITOR__CLOSE, payload: {} });

                            // content of this layer has changed need to get poi view to update
                            // const AdFormAction = (adId) ? AdEdit : AdNew;
                            dispatch(AdFormAction.refreshGeoEntityInMap(campaignId, adId));

                            dispatch(AdFormAction.geoLayerFinder_refresh(campaignId, adId));
                        },
                        error => {
                            dispatch({
                                type: c.POI_EDITOR__SAVE__FAIL,
                                payload: { error: JSON.stringify(error) },
                            });
                        } //@TODO need to show error on UI
                    );
                } else if (draft_sanitized.points.length === 0 && layerType === 'implicit') {
                    //@@ update implicit layer; but it has no point, so we delete it.
                    dispatch({
                        type: c.POI_EDITOR__SAVE__START,
                        payload: { note: 'deleting empty implicit layer; no-payload' },
                    });
                    Promise.all([dispatch(GeoCategories.deleteLayer(layerId))]).then(
                        () => {
                            // Update ad-draft with the REMOVAL of implicit geo-layer
                            const geo_targeting_settings_nxt = adId
                                ? _.cloneDeep(
                                      _.get(
                                          getState(),
                                          `adForm.form.draft.geo_targeting_settings`,
                                          void 0
                                      )
                                  )
                                : _.cloneDeep(
                                      _.get(
                                          getState(),
                                          `adForm.form.draft.geo_targeting_settings`,
                                          void 0
                                      )
                                  );
                            let custom_layers_nxt = _.cloneDeep(
                                _.get(geo_targeting_settings_nxt, `custom_layers`, void 0)
                            );
                            custom_layers_nxt = _.without(custom_layers_nxt, layerId); // remove the empty implicit goe-layer

                            // const AdFormAction = (adId) ? AdEdit : AdNew;
                            dispatch(
                                AdFormAction.updateDraft(
                                    {
                                        geo_targeting_settings: {
                                            ...geo_targeting_settings_nxt,
                                            custom_layers: custom_layers_nxt,
                                        },
                                    },
                                    campaignId,
                                    adId
                                )
                            );

                            if (adId) {
                                // this ad exist, we are currently editing the ad
                                // Update ad on API with the removal implicit layer
                                const payload_patch = {
                                    geo_targeting_settings: { custom_layers: custom_layers_nxt },
                                };
                                dispatch(Ads.update(campaignId, adId, payload_patch)).then(
                                    () => {
                                        dispatch({
                                            type: c.POI_EDITOR__SAVE__SUCCESS,
                                            payload: {},
                                        });
                                        dispatch({ type: c.POI_EDITOR__CLOSE, payload: {} });
                                    },
                                    error => {
                                        dispatch({
                                            type: c.POI_EDITOR__SAVE__FAIL,
                                            payload: { error: JSON.stringify(error) },
                                        });
                                    }
                                );
                            } else if (!adId) {
                                // this ad does not exit, we are currently creating ad
                                dispatch({ type: c.POI_EDITOR__SAVE__SUCCESS, payload: {} });
                                dispatch({ type: c.POI_EDITOR__CLOSE, payload: {} });
                            }

                            // Now that this implicite layer has been deleted it can no longer shown in poi viewer
                            const currentViewingEntityId = adId
                                ? _.get(getState(), `adForm.poiViewer.currentView.entityId`, void 0)
                                : _.get(
                                      getState(),
                                      `adForm.poiViewer.currentView.entityId`,
                                      void 0
                                  );
                            const isCurrentlyShownInPOIViewer = layerId === currentViewingEntityId;
                            if (isCurrentlyShownInPOIViewer) {
                                dispatch(
                                    AdFormAction.showGeoEntityInMap(
                                        campaignId,
                                        adId,
                                        'implicitLayer',
                                        void 0
                                    )
                                );
                            }
                        },
                        error => {
                            dispatch({
                                type: c.POI_EDITOR__SAVE__FAIL,
                                payload: { error: JSON.stringify(error) },
                            });
                        }
                    );
                } else if (draft_sanitized.points.length === 0 && layerType === 'custom') {
                    //@@ update custom layer; but it has no point, we don't allow user to save
                    dispatch({
                        type: c.POI_EDITOR__SAVE__FAIL,
                        payload: { error: 'No point to save' },
                    });
                }
            } else if (!layerId) {
                // @ Create geo-layer
                if (draft_sanitized.points.length !== 0) {
                    // @@ there are poi in the geolayer draft, so go ahead and save layer
                    dispatch({ type: c.POI_EDITOR__SAVE__START, payload: {} });
                    Promise.all([dispatch(GeoCategories.createLayer(draft_sanitized))]).then(
                        ([layer]) => {
                            if (layerType === 'custom') {
                                //@@ we are creating custom layer, don't add this layer to ad (user has to ad them themself)
                                dispatch({ type: c.POI_EDITOR__SAVE__SUCCESS, payload: {} });
                                dispatch({ type: c.POI_EDITOR__CLOSE, payload: {} });

                                // send callback to add new layer to the map
                                done(layer.id);

                                // const AdFormAction = (adId) ? AdEdit : AdNew;
                                dispatch(AdFormAction.geoLayerFinder_refresh(campaignId, adId));
                            } else if (layerType === 'implicit') {
                                // @@ we are creating an implicit layer, implic layer belong to current ad so we add it to the ad-draft and ad-resouces
                                // Update ad-draft with the NEWLY CREATED geo-layer
                                const geo_targeting_settings_nxt = adId
                                    ? _.cloneDeep(
                                          _.get(
                                              getState(),
                                              `adForm.form.draft.geo_targeting_settings`,
                                              void 0
                                          )
                                      )
                                    : _.cloneDeep(
                                          _.get(
                                              getState(),
                                              `adForm.form.draft.geo_targeting_settings`,
                                              void 0
                                          )
                                      );
                                const custom_layers_nxt = _.cloneDeep(
                                    _.get(geo_targeting_settings_nxt, `custom_layers`, void 0)
                                );
                                custom_layers_nxt.push(layer.id); // adding newly create geo-layer

                                // const AdFormAction = (adId) ? AdEdit : AdNew;
                                dispatch(
                                    AdFormAction.updateDraft(
                                        {
                                            geo_targeting_settings: {
                                                ...geo_targeting_settings_nxt,
                                                custom_layers: custom_layers_nxt,
                                            },
                                        },
                                        campaignId,
                                        adId
                                    )
                                );

                                // If poi viewer is currently viewing an implicit layer, we need to update this newly created layer by showing it
                                const currentViewingEntityType = adId
                                    ? _.get(
                                          getState(),
                                          `adForm.poiViewer.currentView.entityType`,
                                          void 0
                                      )
                                    : _.get(
                                          getState(),
                                          `adForm.poiViewer.currentView.entityType`,
                                          void 0
                                      );
                                if (currentViewingEntityType === 'implicitLayer') {
                                    dispatch(
                                        AdFormAction.showGeoEntityInMap(
                                            campaignId,
                                            adId,
                                            'implicitLayer',
                                            layer.id
                                        )
                                    );
                                }

                                if (adId) {
                                    // this ad exist, we are currently editing the ad
                                    // update ad on API with the reference of this newly create layer
                                    const payload_patch = {
                                        geo_targeting_settings: {
                                            custom_layers: custom_layers_nxt,
                                        },
                                    };
                                    dispatch(Ads.update(campaignId, adId, payload_patch)).then(
                                        () => {
                                            dispatch({
                                                type: c.POI_EDITOR__SAVE__SUCCESS,
                                                payload: {},
                                            });
                                            dispatch({ type: c.POI_EDITOR__CLOSE, payload: {} });

                                            // send callback to add new layer to the map
                                            done(layer.id);
                                        },
                                        error => {
                                            dispatch({
                                                type: c.POI_EDITOR__SAVE__FAIL,
                                                payload: { error: JSON.stringify(error) },
                                            });
                                        }
                                    );
                                } else {
                                    // this ad does not exit, we are currently creating the ad
                                    dispatch({ type: c.POI_EDITOR__SAVE__SUCCESS, payload: {} });
                                    dispatch({ type: c.POI_EDITOR__CLOSE, payload: {} });

                                    // send callback to add new layer to the map
                                    done(layer.id);
                                } // End handling adId
                            } // End handling layerType
                        },
                        error => {
                            dispatch({
                                type: c.POI_EDITOR__SAVE__FAIL,
                                payload: { error: JSON.stringify(error) },
                            });
                        }
                    );
                } else {
                    // @@ can not save layer because there are no poi in the goe-layer draft
                    if (layerType === 'implicit') {
                        // dispatch success but do nothing
                        dispatch({ type: c.POI_EDITOR__SAVE__SUCCESS, payload: {} });
                        dispatch({ type: c.POI_EDITOR__CLOSE, payload: {} });

                        // send callback to add new layer to the map
                        done();
                    } else {
                        dispatch({
                            type: c.POI_EDITOR__SAVE__FAIL,
                            payload: { error: 'No point to save' },
                        });
                    }
                }
            }
        };
    },
};

import _ from 'lodash';

import c from 'common/constants/flux-events';
import { detectImpersonation } from 'services/impersonation';
import { naivelyFetchQueryStringField } from 'utils/querystrings';
import { ROLE_INTENTS } from 'common/constants/role-intents';
import flags from 'containers/flags/service';
const ROLES_WITH_IMPERSONATION_PERMISSIONS = [
    'admin',
    'read_only_super_admin',
    'readOnlySuperAdmin',

    // Eventually we'll allow campaign managers to impersonate too:
    // 'admin',
    // 'campaign_manager'
];

function safelyClearLocalstorage() {
    const currentBranch = localStorage.getItem('devSettings.currentBranch');
    const apiMode = localStorage.getItem('devSettings.apiMode');
    const environment = localStorage.getItem('devSettings.environment');

    localStorage.clear();

    localStorage.setItem('devSettings.currentBranch', currentBranch);
    localStorage.setItem('devSettings.apiMode', apiMode);
    localStorage.setItem('devSettings.environment', environment);
}

export const NAME = 'profile';

export function getInitialState() {
    const isImpersonating = detectImpersonation();
    let canImpersonate;

    let authToken;
    let impersonatorsToken;

    const ROLE_TYPES = _.keys(ROLE_INTENTS);

    if (typeof sessionStorage !== 'undefined') {
        if (isImpersonating) {
            const impersonationUUID = naivelyFetchQueryStringField('token');

            if (localStorage.getItem(impersonationUUID)) {
                // In this case, the user is trying to impersonate someone new
                sessionStorage.clear();
                sessionStorage.setItem('authorization', localStorage.getItem(impersonationUUID));

                authToken = JSON.parse(localStorage.getItem(impersonationUUID));
                impersonatorsToken = JSON.parse(localStorage.getItem('authorization'));

                // We make sure to clear localStorage of the token afterwards
                localStorage.removeItem(impersonationUUID);
            } else {
                // In this case the user was already impersonating, but the page was refreshed or recovered
                authToken = JSON.parse(sessionStorage.getItem('authorization'));
                impersonatorsToken = JSON.parse(localStorage.getItem('authorization'));
            }
        } else {
            // Ensure that if we just stopped impersonating the old session data
            // is cleared
            sessionStorage.clear();

            authToken = impersonatorsToken = JSON.parse(localStorage.getItem('authorization'));
        }

        // This is a special case, where the user was signed in with the previous (non-impersonation enabled)
        // auth system and has a stale token. We force a logout and refresh to resolve it
        if (authToken && authToken.v3_impersonator === undefined) {
            sessionStorage.clear();
            safelyClearLocalstorage();

            authToken = null;
        }

        // This is a second special case, where the user was signed in with the previous authorization system, and has a
        // stale token. We force a logout to resolve it
        if (authToken && !_.includes(ROLE_TYPES, authToken.role.id)) {
            sessionStorage.clear();
            safelyClearLocalstorage();

            window.location.href = '/';
        }
    }

    // Determine impersonation permissions (done outside the `can` mechanism to avoid a circular dependancy)
    canImpersonate =
        authToken &&
        _.includes(ROLES_WITH_IMPERSONATION_PERMISSIONS, authToken.v3_impersonator.role)
            ? true
            : false;

    return {
        userId: null,
        userEmail: null,
        userFirstName: null,
        userLastName: null,
        organizationId: null,
        isImpersonating,
        canImpersonate,
        impersonatorsToken,
        authToken,
        isLoggedIn: !!authToken,
        error: null,
        globalRole: null,
        preferences: null,
        _etag: null,
        isLoadingUser: true,
    };
}

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case 'PROFILE__INIT_SUCCESS': {
            const {
                isImpersonating,
                canImpersonate,
                impersonatorsToken,
                authToken,
                isLoggedIn,
            } = action.payload;
            return {
                ...state,
                userId: null,
                userEmail: null,
                userFirstName: null,
                userLastName: null,
                organizationId: null,
                isImpersonating,
                canImpersonate,
                impersonatorsToken,
                authToken,
                isLoggedIn,
                error: null,
                globalRole: null,
                preferences: null,
                _etag: null,
                isLoadingUser: true,
            };
        }

        case c.PROFILE__USER__FETCH__SUCCESS:
            return {
                ...state,
                userId: action.payload.user.id,
                userEmail: action.payload.user.email,
                userFirstName: action.payload.user.first_name,
                userLastName: action.payload.user.last_name,
                organizationId: action.payload.user.organization,
                globalRole: action.payload.user.global_role,
                organizationTimezone: action.payload.user.org.default_timezone,
                restrictedSegments: action.payload.user.org.restrictedSegments,
                preferences: action.payload.user.preferences,
                _etag: action.payload.user._etag,
                ownOrganization: action.payload.ownOrganization,
                isLoadingUser: false,
            };

        case 'PROFILE__USER_PREFERENCES_NOTIFICATIONS_LAST_SEEN__UPDATE': {
            return {
                ...state,
                preferences: action.payload.user.preferences,
                _etag: action.payload.user._etag,
            };
        }

        case 'LOGIN':
            localStorage.setItem('authorization', JSON.stringify(action.payload, null, 2));

            const authToken = action.payload;
            const impersonatorsToken = _.clone(authToken);

            const canImpersonate =
                authToken &&
                _.includes(ROLES_WITH_IMPERSONATION_PERMISSIONS, authToken.v3_impersonator.role)
                    ? true
                    : false;

            return {
                ...state,
                authToken,
                impersonatorsToken,
                canImpersonate,
                isLoggedIn: true,
                error: null,
            };

        case 'LOGIN_FAIL':
            return {
                ...state,
                error: action.error,
            };

        case c.IMPERSONATION_MODAL__UNIMPERSONATE:
        case 'LOGOUT':
            sessionStorage.clear();

            // Simply refresh the tab if this is an impersonated session
            if (state.isImpersonating) {
                if (window.location.pathname.includes('/admin/')) {
                    const organization = _.get(state, 'impersonatorsToken.organization.id');
                    window.location = `/admin/${organization}`;
                } else {
                    window.location = window.location.pathname;
                }
                return {
                    ...state,
                };
            }

            safelyClearLocalstorage();

            return {
                ...getInitialState(flags),
                isImpersonating: false,
                isLoggedIn: false,
                authToken: undefined,
                impersonatorsToken: undefined,
                error: 'Unauthorized',
            };

        case 'UNAUTHORIZED':
            safelyClearLocalstorage();

            return {
                ...getInitialState(flags),
                isImpersonating: false,
                isLoggedIn: false,
                authToken: undefined,
                impersonatorsToken: undefined,
                error: 'Unauthorized',
            };

        case c.PROFILE_AD_PRESETS_FETCH: {
            return {
                ...state,
            };
        }

        case 'PROFILE__TIMEZONE__CHANGE':
            return {
                ...state,
                organizationTimezone: action.payload.organizationTimezone,
            };

        default:
            return state;
    }
}

import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import StandardSelector from 'widgets-v5/standard-selector';

import { adminOrgs as Action_adminOrgs } from 'containers/admin-orgs/actions';

import { createHttp } from 'utils/http';
import toastr from 'toastr';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};
const http = createHttp();

const mapStateToProps = (state, ownProps) => {
    const orgIds = _.get(state, 'admin.orgIds', []);

    const o = {
        orgId: _.get(ownProps, 'orgId', void 0),
        orgIds,
    };
    // console.log('xxx mapStateToProps: ', o );
    return o;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch__init() {
            dispatch(Action_adminOrgs.init(ownProps.orgId));
        },
    };
};

const OrganizationDropDownContainer = ({ orgIds, ...props }) => {
    const [dropdownItems, setDropdownItems] = useState([]);

    const fetchOrganizationNamesForDropdown = async () => {
        const query = `
        query getOrganizations ($orgFilters: OrganizationFilters) {
          organizations(filters: $orgFilters) {
            label: name
            value: id
          }
        }`;
        const variables = {
            orgFilters: { relationship: 'child' },
        };
        try {
            const { organizations } = await http.graphql(query, variables);
            const uniqueOrganizations = _.uniqBy(organizations, 'value');
            const items = _.sortBy(uniqueOrganizations, 'label');
            setDropdownItems(items);
        } catch (e) {
            toastr.warning(
                '',
                '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
                TOASTR_OPTIONS
            );
            if (window.bugsnagClient) {
                window.bugsnagClient.notify(`Failed to fetch organization list data`, {
                    metaData: {
                        idList: orgIds,
                    },
                });
            }
        }
    };

    useEffect(() => {
        fetchOrganizationNamesForDropdown();
    }, []);

    return <OrganizationDropDown {...props} dropdownItems={dropdownItems} />;
};

class OrganizationDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
    }

    init() {
        this.props.dispatch__init();
    }

    componentDidMount() {
        this.init();
    }

    handleDropDownChange(orgId) {
        if (!orgId) {
            return;
        }
        browserHistory.push(`/admin/${orgId}`);
    }
    render() {
        return (
            <StandardSelector
                autofocus={false}
                disabled={false}
                clearable={false}
                searchable={true}
                label="StandardSelector"
                value={_.get(this.props, `orgId`, void 0)}
                onChange={this.handleDropDownChange}
                placeholder="Select an Organization"
                items={this.props.dropdownItems}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationDropDownContainer);

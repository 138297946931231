import _ from 'lodash';

import { isArchivedAd } from 'states/resources/ads/business-logic';

export default function selector(storeState, props) {
    const { campaignId } = props;
    const mediaPlanForm = _.get(storeState, 'mediaPlanForm', {});
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const currentUser = _.get(storeState, 'profile.userId');
    const adIds = _.get(campaign, `ads`, []);
    const ads = _(adIds)
        .map(adId => _.get(storeState, `resources.ads.${adId}.attr`))
        .filter(ad => ad)
        .filter(ad => !isArchivedAd(ad))
        .map(ad => {
            const adId = ad.id;
            const dealsToAdMapping = _.get(storeState, 'mediaPlanForm.dealsToAdMapping');
            const deals = _.map(_.get(dealsToAdMapping, adId), deal => deal.id);
            return {
                ...ad,
                deals,
            };
        })
        .orderBy('id', 'desc')
        .value();

    return {
        ...mediaPlanForm,
        ads,
        campaign,
        currentUser,
    };
}

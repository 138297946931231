import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

import InteractiveItem from 'widgets-v5/interactive-item';
import { NeutralButton } from 'widgets-v5/buttons';

const basePropTypes = {
    className: PropTypes.string,
    activeItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        .isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            ...InteractiveItem.PropTypes,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
                .isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    align: PropTypes.oneOf(['left', 'justify', 'center']),
    disabled: PropTypes.bool,
};

class RadioSelector extends PureComponent {
    static propTypes = {
        ...basePropTypes,
    };

    static defaultProps = {
        className: null,
        style: null,
        align: 'left',
        disabled: false,
    };

    render() {
        return (
            <ul
                style={this.props.style}
                className={classnames(
                    'ef5-radio-selector',
                    `ef5-radio-selector_align-${this.props.align}`,
                    this.props.className,
                    this.props.disabled ? 'disabled' : null
                )}
            >
                {this.props.items.map(item => {
                    const itemIsActive = item.value === this.props.activeItem;
                    return (
                        <li
                            key={item.value}
                            className={classnames(
                                'ef5-radio-selector__item',
                                `ef5-radio-selector__item_align-${this.props.align}`,
                                { 'ef5-radio-selector__item_is-active': itemIsActive }
                            )}
                        >
                            <NeutralButton
                                {...item}
                                className={classnames(
                                    'ef5-radio-selector__item-name',
                                    `ef5-radio-selector__item-name_align-${this.props.align}`,
                                    { 'ef5-radio-selector__item-name_is-active': itemIsActive }
                                )}
                                icon={
                                    itemIsActive ? (
                                        <i className="fa fa-check-circle fa-lg fa-fw ef5-radio-selector__item-icon_is-active" />
                                    ) : (
                                        <i className="fa fa-circle-o fa-lg fa-fw ef5-radio-selector__item-icon_is-inactive" />
                                    )
                                }
                                text={item.label}
                                disabled={this.props.disabled || item.disabled}
                                onClick={
                                    this.props.disabled
                                        ? () => {}
                                        : value => {
                                              this.props.onChange &&
                                                  this.props.onChange(item.value);
                                          }
                                }
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }
}
export function RadioButton(props) {
    return (
        <i
            onClick={props.onClick || _.noop}
            className={classnames({
                'fa fa-lg fa-fw': true,
                'ef5-radio-selector__item-icon': true,
                'fa-check-circle ef5-radio-selector__item-icon_is-active': props.active,
                'fa-circle-o ef5-radio-selector__item-icon_is-inactive': !props.active,
            })}
        />
    );
}

export class VerticalRadioSelector extends PureComponent {
    static propTypes = {
        ...basePropTypes,
    };

    static defaultProps = {
        className: null,
        style: null,
    };

    render() {
        return (
            <div>
                <RadioSelector
                    {...this.props}
                    className={classnames('ef5-vertical-radio-selector', this.props.className)}
                />
            </div>
        );
    }
}

export default RadioSelector;

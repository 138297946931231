import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import FormField from 'widgets-v5/form-field';
import { ConfirmDialog } from 'widgets-v6/modal';
import Spacer from 'widgets-v5/spacer';
import { TextField } from 'widgets-v6/standard-input';
import { OptionCreatableSelect } from 'widgets-v6/select';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const { bool, string, func, array } = PropTypes;

const internalUserPartnerIdDefaults = [
    {
        value: '2121',
        label: 'Pelmorex Toronto/West (2121)',
    },
    {
        value: '3131',
        label: 'Pelmorex Montreal (3131)',
    },
];

class FootTrafficAttribution extends React.Component {
    static propTypes = {
        ftaEnabled: bool,
        errors: array,
        showErrors: bool,
        onChangeEnabled: PropTypes.func,

        partnerId: string,
        onChangePartnerId: func,

        lineId: string,
        onChangeLineId: func,

        isInternalUser: bool,
    };

    static defaultProps = {
        ftaEnabled: null,
        errors: [],
        showErrors: false,
        onChangeEnabled: () => {},
        partnerId: '',
        onChangePartnerId: () => {},
        lineId: '',
        onChangeLineId: () => {},
        isInternalUser: false,
    };

    handleToggle = ({ defaultValue }) => () => {
        // When going from an undefined value to a selection, use the default value
        if (this.props.ftaEnabled === null || defaultValue === true) {
            this.props.onChangeEnabled(defaultValue);
            this.setState({ confirmDialogIsOpen: false });

            return;
        } else {
            this.props.onChangeEnabled(defaultValue);
        }
    };

    state = {
        confirmDialogIsOpen: false,
    };

    handleCancelConfirmDialog = () => {
        this.setState({
            confirmDialogIsOpen: false,
        });
    };

    handleEnable = () => {
        this.setState({
            confirmDialogIsOpen: !this.state.confirmDialogIsOpen,
        });
    };

    getPartnerIdOptions = partnerId => {
        let partnerIdOptions = internalUserPartnerIdDefaults;
        if (!_.find(internalUserPartnerIdDefaults, { value: partnerId })) {
            return partnerIdOptions.concat({ value: partnerId, label: partnerId });
        }
        return partnerIdOptions;
    };

    getPartnerIdValue = partnerId => {
        let partnerValue = {};
        if (_.find(internalUserPartnerIdDefaults, { value: partnerId })) {
            partnerValue = _.find(internalUserPartnerIdDefaults, { value: partnerId });
        } else {
            partnerValue = { value: partnerId, label: partnerId };
        }
        return partnerValue;
    };

    render() {
        return (
            <div>
                <FormField
                    errors={this.props.errors}
                    showErrors={this.props.showErrors}
                    isRequired={true}
                    marginBottom={0}
                    label={false && 'FTA Pixel'}
                >
                    <Fragment>
                        <Alert severity="info">
                            Foot Traffic Attribution (FTA) must be enabled for this campaign before
                            inserting an FTA pixel. To enable FTA, contact your Account Manager /
                            Client Partner with the campaign budget, flight dates, and list of
                            locations to track.
                        </Alert>
                        <Spacer />
                        <Button
                            style={{ marginRight: 10 }}
                            onClick={this.handleEnable}
                            variant="contained"
                            color={this.props.ftaEnabled ? 'primary' : 'default'}
                        >
                            Insert FTA pixel
                        </Button>
                        <Button
                            onClick={this.handleToggle({ defaultValue: false })}
                            variant="contained"
                            color={!this.props.ftaEnabled ? 'primary' : 'default'}
                        >
                            Omit FTA pixel
                        </Button>
                    </Fragment>
                </FormField>

                <ConfirmDialog
                    isOpen={this.state.confirmDialogIsOpen}
                    title="Insert FTA pixel?"
                    content="Your Account Manager / Client Partner should confirm that Foot Traffic Attribution (FTA) has been enabled for this campaign before inserting an FTA pixel."
                    onConfirm={this.handleToggle({ defaultValue: true })}
                    onCancel={this.handleCancelConfirmDialog}
                />

                {this.props.isInternalUser && this.props.ftaEnabled && (
                    <div>
                        <Spacer />
                        <FormField label="Line ID" showErrors={false} isRequired={false}>
                            <TextField
                                disableMargin
                                defaultValue={this.props.lineId}
                                onChange={e => this.props.onChangeLineId(e.target.value)}
                                fullWidth
                            />
                        </FormField>
                        <Spacer />
                        <FormField label="Partner ID" showErrors={false} isRequired={true}>
                            <OptionCreatableSelect
                                required
                                placeholder="Partner ID"
                                value={this.getPartnerIdValue(this.props.partnerId)}
                                onChange={value => {
                                    if (value === null) {
                                        return;
                                    }
                                    this.props.onChangePartnerId(value);
                                }}
                                options={this.getPartnerIdOptions(this.props.partnerId)}
                            />
                        </FormField>
                    </div>
                )}
            </div>
        );
    }
}

export default FootTrafficAttribution;

import React from 'react';
import _ from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
}));

const DeleteConversionWarning = ({
    isOpen,
    handleCancel,
    handleConfirm,
    deletedConversionWithAffectedAdLines,
}) => {
    const classes = useStyles();

    return (
        <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleCancel}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                Delete A Conversion Event
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to delete this conversion event? This action is permanent
                    and the following ad lines will be affected:
                </Typography>

                <Typography>
                    Conversion: {deletedConversionWithAffectedAdLines.reporting_name} -{' '}
                    {deletedConversionWithAffectedAdLines.affectedAds &&
                        deletedConversionWithAffectedAdLines.affectedAds.join(', ')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleConfirm}>
                    Confirm
                </Button>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConversionWarning;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classnames from 'classnames';

export function InfoIcon(props) {
    return (
        <i
            onClick={props.onClick}
            style={props.style}
            className={classnames(props.className, 'fa fa-info-circle')}
        />
    );
}

export function WarningIcon(props) {
    return (
        <i
            onClick={props.onClick}
            style={props.style}
            className={classnames(props.className, 'fa fa-exclamation-circle')}
        />
    );
}

export function CloseIcon(props) {
    return (
        <i
            onClick={props.onClick}
            style={props.style}
            className={classnames(props.className, 'fa fa-close')}
        />
    );
}

export function ExpandCollapseIcon(props) {
    const [isToggled, setToggle] = useState(props.isToggled ? props.isToggled : false);

    return (
        <div onClick={props.onClick}>
            <i
                onClick={() => setToggle(!isToggled)}
                className={classnames(props.className, 'fa fa-chevron-down expand-collapse-icon', {
                    'expand-collapse-icon--is-active': isToggled,
                })}
            />
        </div>
    );
}

import _ from 'lodash';

export default function selector(storeState) {
    const { audienceSegments, totalSegmentCount, isLoading } = _.get(
        storeState,
        'mastercardDashboard'
    );
    const organizationId = _.get(storeState, 'profile.organizationId');

    return {
        audienceSegments,
        totalSegmentCount,
        isLoading,
        organizationId,
    };
}

import debounce from 'lodash/debounce';
import map from 'lodash/map';
import React, { useCallback, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import MuiCheckbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import StandardSelector from 'widgets-v5/standard-selector';
import { MultiSelect } from 'widgets-v6/select';
import Spacer from 'widgets-v5/spacer';
import { SearchInput } from 'widgets-v6/standard-input';
import InfiniteScrollWidget from 'widgets-v6/infinite-scroll';

import { isInternalUser } from 'states/profile/business-rules';
import DateRangeSelectorV2 from '../date-range-selector-v2';
import { useBusinessReportEditor } from '../../v2/hook';
import SummaryDetails from '../summary-details';

const useStyles = makeStyles(theme => ({
    list: {
        flexGrow: 1,
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 200,
    },
}));

function FilterSelector(props) {
    const classes = useStyles();

    const {
        errors,
        showErrors,
        draft,
        start,
        end,
        updateDateRange,
        schemaVersion,
        updateIabCategory,
        iabCategoryOptions,
        updateClient,
        clientOptions,
        campaignOptions,
        engagementsOptions,
    } = props;

    const {
        organiztionForFilteringOptions,
        setSearchFilter,
        searchFilter,
        selectAll,
        handleSelectAll,
        selections,
        handleItemToggle,
        selectAllTitle,
        selectedTab,
        setSelectedTab,
        selectOrganization,
        updateFilterDateRangeMode,
        updateFilterLast,
        updateLastFrame,
        updateFilterFrom,
        updatePresetModeDateRange,
        updateAutomaticModeDateRange,
        updateCustomModeDateRange,
        changeFilterMode,
        toggleFilter,
        fetchMoreCampaigns,
        hasMore,
        filteredCampaigns,
    } = useBusinessReportEditor({
        isInternalUser: isInternalUser(),
    });

    const reportModeOptions = [
        { label: 'By Date', value: 'dateRange' },
        { label: 'By Campaign', value: 'campaigns' },
    ];
    if (isInternalUser()) {
        reportModeOptions.push({
            label: 'By Organization',
            value: 'organizationFilter',
        });
        reportModeOptions.push({
            label: 'By Ad End Date',
            value: 'adEndDate',
        });
    }

    const [searchFilterInput, setSearchFilterInput] = useState(searchFilter);
    const searchFilterDebounced = useCallback(debounce(value => setSearchFilter(value), 1000), []);

    const handleSearchFilterChange = (e) => {
        setSearchFilterInput(e.target.value);
        searchFilterDebounced(e.target.value);
    }

    return (
        <React.Fragment>
            <Paper>
                <Tabs
                    value={selectedTab}
                    onChange={(event, newValue) => {
                        setSelectedTab(newValue);
                    }}
                    className={classes.tabs}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {map(reportModeOptions, option => (
                        <Tab key={option.value} label={option.label} value={option.value} />
                    ))}
                </Tabs>
                <Grid container>
                    <Grid item xs={7}>
                        <Box display="flex" height="100%">
                            <Box p={2} width="100%">
                                {selectedTab === 'dateRange' && (
                                    <React.Fragment>
                                        <FormControlLabel
                                            control={
                                                <MuiCheckbox
                                                    checked={draft.dateRange.isEnabled}
                                                    onChange={() => {
                                                        toggleFilter({
                                                            filterType: 'dateRange',
                                                        });
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Enable Filter"
                                        />
                                        <FormControl
                                            className="reports-editor__form"
                                            disabled={!draft.dateRange.isEnabled}
                                        >
                                            <div
                                                style={{
                                                    maxWidth: 500,
                                                }}
                                            >
                                                <DateRangeSelectorV2
                                                    timezone={draft.timezone}
                                                    start={start}
                                                    end={end}
                                                    updateDateRange={updateDateRange}
                                                    draft={draft}
                                                    schemaVersion={schemaVersion}
                                                    updateFilterDateRangeMode={
                                                        updateFilterDateRangeMode
                                                    }
                                                    updateFilterLast={updateFilterLast}
                                                    updateFilterFrom={updateFilterFrom}
                                                    updatePresetModeDateRange={
                                                        updatePresetModeDateRange
                                                    }
                                                    updateLastFrame={updateLastFrame}
                                                    filterType={'dateRange'}
                                                    campaignOptions={campaignOptions}
                                                    engagementsOptions={engagementsOptions}
                                                    updateAutomaticModeDateRange={
                                                        updateAutomaticModeDateRange
                                                    }
                                                    updateCustomModeDateRange={
                                                        updateCustomModeDateRange
                                                    }
                                                    disabled={!draft.dateRange.isEnabled}
                                                    errors={errors}
                                                />
                                            </div>
                                        </FormControl>
                                    </React.Fragment>
                                )}
                                {selectedTab === 'adEndDate' && (
                                    <React.Fragment>
                                        <FormControlLabel
                                            control={
                                                <MuiCheckbox
                                                    checked={draft.adEndDate.isEnabled}
                                                    onChange={() => {
                                                        toggleFilter({
                                                            filterType: 'adEndDate',
                                                        });
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Enable Filter"
                                        />
                                        <FormControl
                                            className="reports-editor__form"
                                            label="Date Range by ad end date"
                                            disabled={!draft.adEndDate.isEnabled}
                                        >
                                            <div style={{ maxWidth: 500 }}>
                                                <DateRangeSelectorV2
                                                    timezone={draft.timezone}
                                                    start={start}
                                                    end={end}
                                                    updateDateRange={updateDateRange}
                                                    draft={draft}
                                                    schemaVersion={schemaVersion}
                                                    updateFilterDateRangeMode={
                                                        updateFilterDateRangeMode
                                                    }
                                                    updateFilterLast={updateFilterLast}
                                                    updateFilterFrom={updateFilterFrom}
                                                    updatePresetModeDateRange={
                                                        updatePresetModeDateRange
                                                    }
                                                    updateLastFrame={updateLastFrame}
                                                    filterType={'adEndDate'}
                                                    campaignOptions={campaignOptions}
                                                    engagementsOptions={engagementsOptions}
                                                    updateAutomaticModeDateRange={
                                                        updateAutomaticModeDateRange
                                                    }
                                                    updateCustomModeDateRange={
                                                        updateCustomModeDateRange
                                                    }
                                                    disabled={!draft.adEndDate.isEnabled}
                                                />
                                            </div>
                                        </FormControl>
                                    </React.Fragment>
                                )}
                                {selectedTab === 'campaigns' && (
                                    <React.Fragment>
                                        <FormControlLabel
                                            control={
                                                <MuiCheckbox
                                                    checked={draft.campaigns.isEnabled}
                                                    onChange={() => {
                                                        toggleFilter({
                                                            filterType: 'campaigns',
                                                        });
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Enable Filter"
                                        />
                                        <FormControl
                                            className="reports-editor__select-campaigns"
                                            disabled={!draft.campaigns.isEnabled}
                                        >
                                            <FormLabel>
                                                {draft.campaigns.mode === 'exclude'
                                                    ? 'Exclude Campaign(s)'
                                                    : 'Include Campaign(s)'}
                                            </FormLabel>
                                            <Spacer />
                                            <RadioGroup
                                                value={
                                                    draft.campaigns.mode === 'include'
                                                        ? 'include'
                                                        : 'exclude'
                                                }
                                                onChange={e =>
                                                    changeFilterMode({
                                                        filterType: 'campaigns',
                                                        mode: e.target.value,
                                                    })
                                                }
                                                row
                                            >
                                                <FormControlLabel
                                                    value="include"
                                                    control={<Radio color="primary" />}
                                                    label="Include"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="exclude"
                                                    control={<Radio color="primary" />}
                                                    label="Exclude"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                            <SearchInput
                                                placeholder="Filter campaigns"
                                                onChange={handleSearchFilterChange}
                                                defaultValue={searchFilterInput}
                                            />
                                            {draft.campaigns.isEnabled &&
                                                !draft.dateRange.isEnabled &&
                                                !draft.adEndDate.isEnabled && (
                                                    <div className="ef6-alignment__flex">
                                                        <div className="reports-editor__selector reports-editor__selector-iab-category">
                                                            <FormLabel>IAB Category:</FormLabel>
                                                            <StandardSelector
                                                                disabled={false}
                                                                clearable
                                                                searchable
                                                                label="IAB Category"
                                                                placeholder="Filter by IAB category"
                                                                value={draft.iabCategory}
                                                                onChange={updateIabCategory}
                                                                items={iabCategoryOptions}
                                                            />
                                                        </div>
                                                        <div className="reports-editor__selector reports-editor__selector-client">
                                                            <FormLabel>Client:</FormLabel>
                                                            <StandardSelector
                                                                disabled={false}
                                                                clearable
                                                                searchable
                                                                isLoading={false}
                                                                onChange={updateClient}
                                                                value={draft.client}
                                                                placeholder="Filter by Partner / Advertiser / Sales Rep"
                                                                items={clientOptions}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            <Box mt={1}>
                                                <div className="include-exclude__top-toolbar">
                                                    <FormControlLabel
                                                        control={
                                                            <MuiCheckbox
                                                                checked={selectAll}
                                                                disabled={
                                                                    !draft.campaigns.isEnabled
                                                                }
                                                                onChange={() =>
                                                                    handleSelectAll({
                                                                        filteredCampaigns,
                                                                        selectAll,
                                                                    })
                                                                }
                                                                color="primary"
                                                            />
                                                        }
                                                        label={selectAllTitle}
                                                    />
                                                </div>
                                                <InfiniteScrollWidget
                                                    items={filteredCampaigns}
                                                    selections={selections}
                                                    handleItemToggle={handleItemToggle}
                                                    isEnabled={draft.campaigns.isEnabled}
                                                    fetchMoreData={fetchMoreCampaigns}
                                                    hasMore={
                                                        hasMore && filteredCampaigns.length > 0
                                                    }
                                                />
                                            </Box>
                                        </FormControl>
                                    </React.Fragment>
                                )}
                                {selectedTab === 'organizationFilter' && (
                                    <React.Fragment>
                                        <FormControlLabel
                                            control={
                                                <MuiCheckbox
                                                    checked={draft.organizationFilter.isEnabled}
                                                    onChange={() => {
                                                        toggleFilter({
                                                            filterType: 'organizationFilter',
                                                        });
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label="Enable Filter"
                                        />
                                        <FormControl
                                            className="reports-editor__select-campaigns"
                                            disabled={!draft.organizationFilter.isEnabled}
                                        >
                                            <FormLabel>
                                                {draft.organizationFilter.mode === 'exclude'
                                                    ? 'Exclude Organization(s)'
                                                    : 'Include Organization(s)'}
                                            </FormLabel>
                                            <Spacer />
                                            <RadioGroup
                                                value={
                                                    draft.organizationFilter.mode === 'include'
                                                        ? 'include'
                                                        : 'exclude'
                                                }
                                                onChange={e =>
                                                    changeFilterMode({
                                                        filterType: 'organizationFilter',
                                                        mode: e.target.value,
                                                    })
                                                }
                                                row
                                            >
                                                <FormControlLabel
                                                    value="include"
                                                    control={<Radio color="primary" />}
                                                    label="Include"
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    value="exclude"
                                                    control={<Radio color="primary" />}
                                                    label="Exclude"
                                                    labelPlacement="end"
                                                />
                                            </RadioGroup>
                                            <Spacer />

                                            <div className="ef6-alignment__flex">
                                                <div className="reports-editor__selector reports-editor__selector-iab-category">
                                                    <FormLabel>Organizations:</FormLabel>
                                                    <MultiSelect
                                                        disabled={
                                                            !draft.organizationFilter.isEnabled
                                                        }
                                                        clearable
                                                        fullWidth={true}
                                                        label="Organizations"
                                                        placeholder="Filter by Organizations"
                                                        onChange={value => {
                                                            selectOrganization(value);
                                                        }}
                                                        value={draft.organizationFilter.ids}
                                                        options={organiztionForFilteringOptions}
                                                    />
                                                </div>
                                            </div>
                                        </FormControl>
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={5}>
                        <Box p={2} height="550px" overflow="auto">
                            <SummaryDetails />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {showErrors && (
                <div>
                    <FormHelperText error>{errors.dateRange}</FormHelperText>
                    <FormHelperText error>{errors.adEndDate}</FormHelperText>
                    <FormHelperText error>{errors.iabCategory}</FormHelperText>
                    <FormHelperText error>{errors.client}</FormHelperText>
                    <FormHelperText error>{errors['campaigns.ids']}</FormHelperText>
                </div>
            )}
        </React.Fragment>
    );
}

export default FilterSelector;

import _ from 'lodash';
import c from 'common/constants/flux-events';

import contries from 'common/constants/countries';
import regions from 'common/constants/regions';
import findCacheState from 'utils/find-cache-state';
const initialState = prepareDictionary();

// dictionary.scope.{groupId}.value

export default function dictionary(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.CAMPAIGN_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS: {
            const { campaign } = action.payload;

            const adGroupingDict = {};
            const adGroupDict = {};

            _.forEach(campaign.customDimensions, dimension => {
                adGroupingDict[dimension.id] = {};
                adGroupDict[dimension.id] = {
                    value: dimension.name,
                };
                _.forEach(dimension.groups, group => {
                    adGroupingDict[dimension.id][group.id] = {
                        value: group.name,
                    };
                });
            });

            return {
                ...state,
                ...adGroupingDict,
                scope: adGroupDict,
            };
        }

        default:
            return state;
    }
}

export function prepareDictionary() {
    var out = {};

    var geo_country = {};
    var geo_country_region = {};
    _.each(contries, function(item) {
        var countryCode = item.id;
        var countryName = item.text;
        var regionsInCountry = regions[countryCode];

        _.each(regionsInCountry, function(regionName, regionKey) {
            geo_country_region[regionKey] = {
                value: regionName,
            };
        });

        geo_country[countryCode] = {
            value: countryName,
        };
    });

    var gender = {
        F: {
            value: 'Female',
            order: 0,
        },
        M: {
            value: 'Male',
            order: 1,
        },
        D: {
            value: 'Not Available',
            order: 2,
        },
    };

    var device_os = {
        ANDROID: {
            value: 'Android',
            order: 0,
        },
        'RIM OS': {
            value: 'Blackberry',
            order: 1,
        },
        IOS: {
            value: 'iOS',
            order: 2,
        },
        WINDOWS: {
            value: 'Windows',
            order: 3,
        },
        MAC: {
            value: 'Mac',
            order: 4,
        },
        OTHERS: {
            value: 'Others',
            order: 5,
        },
        '': {
            value: 'Not Available',
            order: 6,
        },
        unknown: {
            value: 'Not Available',
            order: 7,
        },
    };

    var age = {
        '1': {
            value: '13-18',
            order: 0,
        },
        '2': {
            value: '19-24',
            order: 1,
        },
        '3': {
            value: '25-34',
            order: 2,
        },
        '4': {
            value: '35+',
            order: 3,
        },
        '5': {
            value: '35-44',
            order: 5,
        },
        '6': {
            value: '45-54',
            order: 6,
        },
        '7': {
            value: '55-64',
            order: 7,
        },
        '8': {
            value: '65+',
            order: 8,
        },
        '-1': {
            value: 'Unknown',
            order: 9,
        },
    };

    var dimension = {
        gender: {
            value: 'Gender',
        },
        age: {
            value: 'Age Groups',
        },
        geo_country: {
            value: 'Country',
        },
        device_os: {
            value: 'Device OS',
        },
        geo_country_region: {
            value: 'Region',
        },
        hour: {
            value: 'Hour',
        },
        day_of_week: {
            value: 'Day of Week',
        },
        week_by_monday_to_sunday: {
            value: 'Monday to Sunday',
        },
        week_by_campaign_start_day: {
            value: 'Every 7 days',
        },
        markup_id: {
            value: 'Creative',
        },
    };

    var day_of_week = {
        0: {
            value: 'Sunday',
            order: 6,
        },
        1: {
            value: 'Monday',
            order: 0,
        },
        2: {
            value: 'Tuesday',
            order: 1,
        },
        3: {
            value: 'Wednesday',
            order: 2,
        },
        4: {
            value: 'Thursday',
            order: 3,
        },
        5: {
            value: 'Friday',
            order: 4,
        },
        6: {
            value: 'Saturday',
            order: 5,
        },
    };

    var day_of_week_short = {
        0: {
            value: 'Sun',
            order: 1,
        },
        1: {
            value: 'Mon',
            order: 2,
        },
        2: {
            value: 'Tue',
            order: 3,
        },
        3: {
            value: 'Wed',
            order: 4,
        },
        4: {
            value: 'Thu',
            order: 5,
        },
        5: {
            value: 'Fri',
            order: 6,
        },
        6: {
            value: 'Sat',
            order: 7,
        },
    };

    var hour = {
        24: {
            value: '12am - 1am',
            order: 0,
        },
        0: {
            value: '12am - 1am',
            order: 0,
        },
        1: {
            value: '1am - 2am',
            order: 1,
        },
        2: {
            value: '2am - 3am',
            order: 2,
        },
        3: {
            value: '3am - 4am',
            order: 3,
        },
        4: {
            value: '4am - 5am',
            order: 4,
        },
        5: {
            value: '5am - 6am',
            order: 5,
        },
        6: {
            value: '6am - 7am',
            order: 6,
        },
        7: {
            value: '7am - 8am',
            order: 7,
        },
        8: {
            value: '8am - 9am',
            order: 8,
        },
        9: {
            value: '9am - 10am',
            order: 9,
        },
        10: {
            value: '10am - 11am',
            order: 10,
        },
        11: {
            value: '11am - 12pm',
            order: 11,
        },
        12: {
            value: '12pm - 1pm',
            order: 12,
        },
        13: {
            value: '1pm - 2pm',
            order: 13,
        },
        14: {
            value: '2pm - 3pm',
            order: 14,
        },
        15: {
            value: '3pm - 4pm',
            order: 15,
        },
        16: {
            value: '4pm - 5pm',
            order: 16,
        },
        17: {
            value: '5pm - 6pm',
            order: 17,
        },
        18: {
            value: '6pm - 7pm',
            order: 18,
        },
        19: {
            value: '7pm - 8pm',
            order: 19,
        },
        20: {
            value: '8pm - 9pm',
            order: 20,
        },
        21: {
            value: '9pm - 10pm',
            order: 21,
        },
        22: {
            value: '10pm - 11pm',
            order: 22,
        },
        23: {
            value: '11pm - 12am',
            order: 23,
        },
    };

    var hourInterval = {
        '0-5': {
            value: '12a-6a',
            order: 0,
        },
        '6-11': {
            value: '6a-12p',
            order: 1,
        },
        '12-17': {
            value: '12p-6p',
            order: 2,
        },
        '18-24': {
            value: '6p-12a',
            order: 3,
        },
    };

    var scopes = {
        ad: {
            value: 'Ads',
        },
        ages: {
            value: 'Age Groups',
        },
        dayparts: {
            value: 'Dayparts',
        },
        devices: {
            value: 'Devices',
        },
        flight: {
            value: 'Flights',
        },
        genders: {
            value: 'Genders',
        },
        geotargets: {
            value: 'Geotargets',
        },
        languages: {
            value: 'Languages',
        },
        weekparts: {
            value: 'Weekparts',
        },
        markup_id: {
            value: 'Creatives',
        },
    };

    const audience_segment = {
        unknown: {
            value: 'Not Available',
        },
    };

    const markup_id = {
        unknown: {
            value: 'Not Available',
        },
    };

    const ad_id = {};

    var total = {
        total: {
            value: 'Total',
        },
    };

    return _.assign(
        out,
        { total: total },
        { gender: gender },
        { geo_country: geo_country },
        { device_os: device_os },
        { age: age },
        { geo_country_region: geo_country_region },
        { dimension: dimension },
        { hour: hour },
        { day_of_week: day_of_week },
        { day_of_week_short: day_of_week_short },
        { hourInterval: hourInterval },
        { scope: scopes },
        { ad_id: ad_id },
        { audience_segment },
        { markup_id }
    );
}

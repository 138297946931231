import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import map from 'lodash/map';
import includes from 'lodash/includes';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import remove from 'lodash/remove';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import VirtualizedTable from 'packages/widgets-v6/virtualized-table';

import { useOverviewAdData } from '../../hook';

export const MainDashboard = ({
    toggleColumn,
    onSortChange,
    selectSortBy,
    selectHiddenColumns,
    currentList,
    currentConfig,
    activeFilter,
    isSelfServeRoute,
}) => {
    const {
        init,
        fetchUserPreference,
        isLoading,
        assigneeFilter,
        updateAssigneeFilter,
    } = useOverviewAdData();

    const tableRef = useRef();
    const [data, setData] = useState({
        listData: currentList,
    });

    useEffect(() => {
        init();
        fetchUserPreference();
    }, []);

    useEffect(() => {
        if (assigneeFilter && assigneeFilter[activeFilter] && assigneeFilter[activeFilter].length) {
            // Filter the list according to the selected assignee(s).
            setData({
                listData: currentList.filter(({ assigneeName }) => includes(assigneeFilter[activeFilter], assigneeName)),
            });
        } else {
            setData({
                listData: currentList,
            });
        }
    }, [currentList, assigneeFilter]);

    const calculateMinHeight = () => {
        if (isLoading || !tableRef.current) {
            return 0;
        }

        // We calculate the distance between the table container to the bottom of the window.
        // This is so we can show as much ads/campaigns as possible in the window.
        // If there are a small number of ads/campaigns then we'll just calculate
        // the height based on that.
        return currentList.length
            ? Math.min(
                  window.innerHeight - tableRef.current.getBoundingClientRect().top - 70,
                  currentList.length * 87 + 40
              )
            : 400;
    };

    const handleChangeAssigneeFilter = value => {
        let currentSelectedAssignees = [];
        const currentAssigneeFilter = assigneeFilter[activeFilter];
        if (includes(currentAssigneeFilter, value)) {
            currentSelectedAssignees = remove(currentAssigneeFilter, assignee => {
                return assignee !== value;
            });
        } else {
            currentSelectedAssignees = uniq([...currentAssigneeFilter, value]);
        }

        updateAssigneeFilter({
            section: activeFilter,
            assigneeFilter: currentSelectedAssignees,
        });
    };

    const getAssigneeList = () => {
        return compact(uniq(map(currentList, ad => ad.assigneeName)));
    };

    return (
        <React.Fragment>
            {!isLoading && getAssigneeList().length !== 0 ? (
                <Grid container>
                    <Box style={{ paddingTop: '7px' }}>
                        <Typography variant="subtitle2">Filter by Assignee:</Typography>
                    </Box>
                    {map(getAssigneeList(), assigneeName => {
                        return (
                            <Box ml="10" mb="10">
                                <Chip
                                    label={assigneeName}
                                    color={
                                        includes(assigneeFilter[activeFilter], assigneeName)
                                            ? 'primary'
                                            : 'default'
                                    }
                                    onClick={() => handleChangeAssigneeFilter(assigneeName)}
                                />
                            </Box>
                        );
                    })}
                </Grid>
            ) : null}
            <Grid xs="12" ref={tableRef}>
                <VirtualizedTable
                    data={data.listData}
                    minHeight={calculateMinHeight()}
                    itemHeight={87}
                    isLoading={isLoading}
                    noResultsText="No Ads or Campaigns found."
                    canToggleColumns
                    toggleColumn={(column) => toggleColumn(column)}
                    onSortChange={onSortChange}
                    columns={currentConfig.columns}
                    hiddenColumns={selectHiddenColumns()}
                    sortBy={selectSortBy()}
                    canExport
                    exportFilename={`dashboard-${moment(new Date()).format('YYYY-MM-DD')}-${
                        activeFilter
                    }.csv`}
                    showConditions={{
                        isSelfServeRoute,
                    }}
                />
            </Grid>
        </React.Fragment>
    )
}

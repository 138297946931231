import _ from 'lodash';
import React from 'react';
import shallowCompare from 'utils/shallow-compare';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import BaseColumnForm from '../base-column-form';

import { formatDate_long } from 'utils/formatting';

export class FlightSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, name } = this.props;
        const start = formatDate_long(ad.start, ad.timezone);
        const end = formatDate_long(ad.end, ad.timezone);
        return (
            <div className="media-plan-form__overview-table-data">
                {ad.unalteredDuplicate ? (
                    <div>
                        {ad.isEndSet || name !== 'start_date'
                            ? 'Date required'
                            : 'End date must be set first'}
                    </div>
                ) : !ad.isStartSet && name === 'start_date' ? (
                    <div>Date required</div>
                ) : (
                    <div>{name === 'start_date' ? start : end}</div>
                )}
            </div>
        );
    }
}

export const FlightForm = props => {
    const { ad, ads, campaign, onSave, columnName } = props;
    const isStartDate = columnName === 'start_date';
    const maxDate = isStartDate && ad ? draft.end : undefined;
    const handleChange = (val, updateDraft) => {
        if (!_.isNaN(Date.parse(val))) {
            const updateObj = isStartDate
                ? { start: val.toISOString() }
                : { end: val.toISOString() };
            updateDraft(updateObj);
        }
    };

    const datePickerLabel = isStartDate ? 'Ad Start Date' : 'Ad End Date';
    const timePickerLabel = isStartDate ? 'Ad Start Time' : 'Ad End Time';
    const fields = isStartDate ? ['start'] : ['end'];
    const headline = isStartDate ? 'Ad Start Date' : 'Ad End Date';

    let startDateEditableAds;
    startDateEditableAds =
        ads &&
        _.filter(ads, ad => {
            return !ad.isDelivering && !ad.unalteredDuplicate;
        });

    const endDateEditableAds = ads;

    return (
        <BaseColumnForm
            ad={ad}
            ads={isStartDate ? startDateEditableAds : endDateEditableAds}
            campaign={campaign}
            headline={headline}
            fields={fields}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
            columnName={isStartDate ? 'Start Date' : 'End Date'}
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <FormControl fullWidth margin="normal">
                        <FormLabel>
                            {' '}
                            Timezone: {ad ? ad.timezone : campaign.default_timezone}{' '}
                        </FormLabel>
                        <FormGroup>
                            <Box display="flex" mt={1}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Box mr={2}>
                                    <Box display="flex" mb={1}>
                                        <FormLabel>
                                            {datePickerLabel}
                                        </FormLabel>
                                    </Box>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        margin="normal"
                                        format="YYYY-MM-DD"
                                        id="ad-flight-picker"
                                        minDate={!isStartDate && moment()}
                                        maxDate={isStartDate && ad ? moment(draft.end) : undefined}
                                        value={isStartDate ? moment(draft.start) : moment(draft.end)}
                                        onChange={val => handleChange(val, updateDraft)}
                                        slotProps={{
                                            textField: {
                                            helperText: shouldShowErrors && (errors.start || errors.end),
                                            error: shouldShowErrors && (errors.start || errors.end),
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Box display="flex" mb={1}>
                                        <FormLabel>
                                            {timePickerLabel}
                                        </FormLabel>
                                    </Box>
                                    <TimePicker
                                        margin="normal"
                                        id="ad-time-picker"
                                        value={isStartDate ? moment(draft.start) : moment(draft.end)} 
                                        onChange={val => handleChange(val, updateDraft)}
                                        ampm
                                    />
                                </Box>
                                </LocalizationProvider>
                            </Box>
                        </FormGroup>
                    </FormControl>
                );
            }}
        </BaseColumnForm>
    );
};

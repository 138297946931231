'use strict';
import _ from 'lodash';
import c from 'common/constants/flux-events';
import flags from 'containers/flags/service';
import AdPresetActions from 'states/resources/ad-presets/actions';
import { createHttp } from 'utils/http';

import UserActions from 'states/resources/users/actions';

const useGraphQLToGetOneUserByIdAndOwnOrganization = userId => {
    const http = createHttp();
    const query = `
        query getUserAndOrganizationDataForProfilePage ($id: String) {
            user (id: $id) {
                id
                first_name
                last_name
                email
                global_role
                suspended
                third_party
                organization
                _etag
                subscribedToEmailNotifications
            }
            ownOrganization {
                name
                id
            }
        }
    `;
    const variables = {
        id: userId,
    };
    return http.graphql(query, variables);
};

export function initProfile(orgId) {
    return async (dispatch, getState) => {
        const userId = _.get(getState(), 'profile.userId', undefined);

        try {
            const data = await useGraphQLToGetOneUserByIdAndOwnOrganization(userId);
            return dispatch({
                type: 'PROFILE_USER__FETCH_SUCCESS',
                payload: { user: data.user, organization: data.ownOrganization },
            });
        } catch (err) {
            dispatch({
                type: 'PROFILE_USER__FETCH_FAIL',
                payload: {
                    err: {
                        message: err,
                    },
                },
            });
        }

        dispatch(AdPresetActions.getAll(orgId)).then(
            adpresets => {
                dispatch({
                    type: c.PROFILE_AD_PRESETS_FETCH,
                    payload: {
                        adpresets,
                        orgId,
                    },
                });
            },
            error => {
                dispatch({
                    type: c.PROFILE_AD_PRESETS_FETCH_FAIL,
                    error: error.body,
                });
            }
        );
    };
}

export const updateSubscriptionPreferences = ({ shouldSubscribeToEmail }) => {
    return async (dispatch, getState) => {
        const userId = _.get(getState(), 'profilePage.user.id');
        const userEtag = _.get(getState(), 'profilePage.user.etag');
        const payload = { subscribedToEmailNotifications: shouldSubscribeToEmail };

        dispatch({
            type: 'PROFILE_PAGE__UPDATE_EMAIL_SUBSCRIPTION_PREFERENCES_START',
            payload: {},
        });

        try {
            const user = await dispatch(UserActions.update(userId, payload, userEtag));
            dispatch({
                type: 'PROFILE_PAGE__UPDATE_EMAIL_SUBSCRIPTION_PREFERENCES_SUCCESS',
                payload: { user },
            });
        } catch (error) {
            dispatch({
                type: 'PROFILE_PAGE__UPDATE_EMAIL_SUBSCRIPTION_PREFERENCES_FAIL',
                payload: {},
            });
        }
    };
};

const useGraphQLToGetOneUserById = userId => {
    const http = createHttp();
    const query = `
        query getUser ($id: String) {
            user(id: $id) {
                id
                first_name
                last_name
                email
                global_role
                suspended
                third_party
                organization
            }

        }
    `;
    const variables = {
        id: userId,
    };

    return http.graphql(query, variables);
};



import _ from 'lodash';

import c from 'common/constants/flux-events';
import { validateDraft } from 'services/resources/organization';

const initialState = {
    orgId: void 0,
    relationship: void 0,
    draft: null,
    isLoading: false,
    isSubmiting: false,
    httpError: void 0,
    isOpen: false,
    showErrors: false,
    formErrors: [],
};

export const NAME = 'adminOrgItemForm';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.ADMIN_ORG_ITEM_FORM__INIT_START: {
            return {
                ...state,
                isOpen: true,
                isLoading: true,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__INIT_SUCCESS: {
            const draft_current = _.get(state, `draft`, null);
            let draft_next;

            if (draft_current === null) {
                draft_next = _.get(action, 'payload.draft');
            } else {
                draft_next = _.cloneDeep(draft_current);
            }

            const relationship = _.get(action, 'payload.relationship', void 0);
            const meta = { orgId: state.orgId, relationship };
            const formErrors = validateDraft(draft_next, meta);

            return {
                ...state,
                orgId: _.get(action, 'payload.orgId'),
                draft: _.get(action, 'payload.draft'),
                isLoading: false,
                formErrors,
                relationship,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__INIT_FAIL: {
            const httpError = _.get(action, 'payload.err', void 0);

            return {
                ...state,
                httpError,
                isLoading: false,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__FIELD_CHANGED: {
            const error = _.get(action, 'payload.err', void 0);
            const data = _.get(action, 'payload.data', {});

            const draft_current = _.get(state, `draft`, null);
            let draft_next = {
                ..._.cloneDeep(draft_current),
                ...data,
            };

            draft_next = applyType(draft_next, data);

            const meta = { orgId: state.orgId, relationship: state.relationship };
            const formErrors = validateDraft(draft_next, meta);

            return {
                ...state,
                draft: draft_next,
                formErrors,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__USER_SUBMIT: {
            const payload = _.get(action, 'payload', void 0);

            return {
                ...state,
                showErrors: true,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__SUBMIT_START: {
            const payload = _.get(action, 'payload', void 0);

            // validate and show error
            return {
                ...state,
                isLoading: true,
                isSubmiting: true,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__SUBMIT_SUCESS: {
            const payload = _.get(action, 'payload', void 0);
            return {
                ...state,
                isLoading: false,
                isOpen: false,
                isSubmiting: false,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__SUBMIT_FAIL: {
            const payload = _.get(action, 'payload', void 0);
            const httpErrors = _.get(action, 'payload.httpErrors', void 0);
            return {
                ...state,
                httpErrors,
                formErrors: _.get(httpErrors, `body`, []),
                isLoading: false,
                isSubmiting: false,
            };
        }

        case c.ADMIN_ORG_ITEM_FORM__TEARDOWN: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
}

function applyType(draft, changes) {
    if (changes.type && changes.type !== 'client') {
        // reset client_type to undefined when org type is not client
        return _.omit(draft, 'client_type');
    }

    return draft;
}

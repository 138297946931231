import _ from 'lodash';

import c from 'common/constants/flux-events';
import API from 'services/api';
import { serializeForApi } from 'services/resources/ad-preset';

import { browserHistory } from 'react-router';
import GeoCategories from 'states/resources/geo-categories/actions';
import Organizations from 'states/resources/organizations/actions';
import AdPresetActions from 'states/resources/ad-presets/actions';
import UserActions from 'states/resources/users/actions';
import AudienceActions from 'states/resources/audiences/actions';
import CampaignsAPI from 'states/resources/campaigns/actions';
import flags from 'containers/flags/service';
// -----------------
// Edit Ad Preset Form
// -----------------
export const editAdPresetForm = {
    open(adPresetId, campaignId) {
        return (dispatch, getState) => {
            // Open the form
            dispatch({
                type: c.EDIT_AD_PRESET__DRAFT__OPEN,
                payload: {},
            });

            Promise.all([
                dispatch(UserActions.get()),
                dispatch(Organizations.getAll()),
                dispatch(GeoCategories.getCategories()),
                dispatch(GeoCategories.getAllLayers()),
                dispatch(AudienceActions.getAll()),
            ]).then(([user, organizations]) => {
                const organization = _.find(organizations, { id: user.organization });

                const owner = user.organization;
                dispatch(AdPresetActions.getOne(owner, adPresetId)).then(
                    response => {
                        const adpreset = response;
                        // Populate the draft
                        dispatch({
                            type: c.EDIT_AD_PRESET__DRAFT__INIT,
                            payload: {
                                owner,
                                organization,
                                adpreset,
                            },
                        });
                    },
                    error => {
                        // throw toaster error for server errors

                        dispatch({
                            type: c.EDIT_AD_PRESET__DRAFT__FETCH_FAIL,
                            error: error.body,
                        });
                    }
                );
            });
        };
    },

    updateDraft(formData) {
        return {
            type: c.EDIT_AD_PRESET__DRAFT__UPDATE,
            payload: { formData },
        };
    },

    showErrors(formData) {
        return {
            type: c.EDIT_AD_PRESET__DRAFT__SHOW_ERRORS,
            payload: {},
        };
    },

    submitDraft(formData, adPresetId) {
        return async (dispatch, getState) => {
            const sessionEditAdPresetForm = _.get(getState(), `adPresetEdit`, undefined);
            if (!sessionEditAdPresetForm) {
                throw new Error('Store error: session.adPreset does not exist');
            }

            const errors = _.get(sessionEditAdPresetForm, 'errors', []);
            if (errors.length !== 0) {
                // There are errors don't do ajax
                dispatch({
                    type: c.EDIT_AD_PRESET__DRAFT__SHOW_ERRORS,
                    payload: {},
                });
                return;
            }
            //
            // // No errors, essemble payload and serialize
            const draft = _.get(sessionEditAdPresetForm, `draft`, {});
            const payload_beforeSerialized = { ...draft };
            const payloadSerialized = serializeForApi(payload_beforeSerialized, []);

            dispatch({
                type: c.EDIT_AD_PRESET__DRAFT__SUBMIT,
            });

            const orgId = sessionEditAdPresetForm.organization.id;

            await dispatch(AdPresetActions.update(orgId, adPresetId, payloadSerialized)).then(
                response => {
                    const adpreset = response;

                    dispatch({
                        type: c.EDIT_AD_PRESET__DRAFT__SUBMIT_SUCCESS,
                        payload: { adpreset },
                    });

                    browserHistory.push(`/ad-preset`);

                    // Tear down
                    dispatch({
                        type: c.EDIT_AD_PRESET__DRAFT__CLOSE,
                    });
                },
                error => {
                    // throw toaster error for server errors

                    dispatch({
                        type: c.EDIT_AD_PRESET__DRAFT__SUBMIT_FAIL,
                        error: error.body,
                    });
                }
            );
        };
    },

    cancelDraft() {
        browserHistory.push(`/ad-preset`);
        return {
            type: c.EDIT_AD_PRESET__DRAFT__CLOSE,
        };
    },
};

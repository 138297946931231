import _ from 'lodash';
import noop from 'lodash/noop';
import React, { useState, useEffect } from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import { Link } from 'react-router';
import { useSelector, useDispatch, connect } from 'react-redux';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { browserHistory } from 'react-router';

import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ArchiveIcon from '@mui/icons-material/Archive';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Popover from '@mui/material/Popover';
import AssignmentIcon from '@mui/icons-material/Assignment';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import MuiPaper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';

import { useProfileState } from 'states/profile';
import AdStatusToggle from 'containers/ad-status-toggle';
import FlightIndicator from 'widgets-v5/flight-indicator';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { can } from 'services/can';
import { BodyText } from 'widgets-v5/typography';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import ColumnsDropdown from 'widgets-v6/columns-dropdown';
import { HealthChip } from 'widgets-v6/health-chip';
import { DialogTitle } from 'widgets-v6/modal';
import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';

import OverviewTabs from 'pages/campaigns/campaigns-overview/overview-tabs';

import AdSummary from 'containers/ad-summary';
import {
    canEditAd,
    canArchiveAd,
    canDuplicateAd,
    isArchivedAd,
} from 'states/resources/ads/business-logic';

import { canCreateAd } from 'states/resources/campaigns/business-logic';

import selector from './selector';

import {
    formatNumber_whole,
    formatNumber_currency,
    formatNumber_percentage,
    formatDate_date,
} from 'utils/formatting';

import {
    filterList,
    deleteAd,
    toggleColumn,
    fetchUserPreferences,
    fetchAudiencesForWarnings,
    pause,
    unpause,
    selectAd,
    selectAll,
    openArchiveConfirmation,
    closeArchiveConfirmation,
    archiveAd,
    toggleArchivedAdVisibility,
} from 'pages/campaigns/ads/ads-overview/actions';

import { isInternalUser } from 'states/profile/business-rules';
import { useAdsOverview } from './v2';
import { PermissionsMapping } from 'common/constants/role-intents';

const getRemainingDaysLabel = ad => {
    const isEnded = ad.status === 'ended';
    const prefix = isEnded ? 'Ended' : 'Ending';
    const daysBetween = moment(ad.end).from();

    if (!ad.end || ad.unalteredDuplicate) {
        return 'Incomplete setup';
    } else {
        return `${prefix} ${daysBetween}`;
    }
};

const getDateLabel = (ad, orgTimezone) => {
    const startDate = formatDate_date(new Date(ad.start), orgTimezone);
    const endDate = formatDate_date(new Date(ad.end), orgTimezone);

    if (ad.start && ad.end) {
        return `${startDate} - ${endDate}`;
    }
};

// =================
// Layout
// =================

const headers = [
    {
        label: () => 'Action',
        name: 'ad_action',
        isPermitted: () => true,
        align: 'left',
        component: ({ ad, campaignId, selectedAds, adsBeingProcessed }) => {
            if (!can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT)) {
                return null;
            }

            if (isArchivedAd(ad)) {
                return null;
            }

            return (
                <ActionColumn
                    ad={ad}
                    campaignId={campaignId}
                    selectedAds={selectedAds}
                    adsBeingProcessed={adsBeingProcessed}
                />
            );
        },
    },
    {
        label: () => 'Status',
        name: 'ad_status',
        isPermitted: () => true,
        align: 'center',
        component: ({ ad }) => (
            <FlightIndicator label size="small" position="bottom" status={ad.status} />
        ),
    },
    {
        label: () => 'ID',
        name: 'ad_id',
        isPermitted: () => true,
        align: 'left',
        component: ({ ad, campaignId }) => <AdId ad={ad} campaignId={campaignId} />,
    },
    {
        label: () => 'Name',
        name: 'ad_name',
        isPermitted: () => true,
        align: 'left',
        component: ({
            ad,
            campaignId,
            orgTimezone,
            isCrossPlatformCampaign,
            handlePopoverOpen,
            handlePopoverClose,
            openCreatives,
            anchorElCreatives,
            currentAd,
            currentAdId,
            classes,
            showUnapprovedCreatives,
            campaignBudgetAllocationMethod,
        }) => {
            const creativeLength =
                _.size(ad.creative) || _.size(ad.creativeRotationByWeatherSummary);

            const creativesExist = creativeLength > 0;

            return (
                <React.Fragment>
                    <Box display="flex" whiteSpace="nowrap">
                        <Typography color="textSecondary" variant="body2">
                            <span>{getRemainingDaysLabel(ad)}</span>
                            {ad.start && ad.end && !ad.unalteredDuplicate && (
                                <span className={classes.dotsbetweenItem}>
                                    {getDateLabel(ad, orgTimezone)}
                                </span>
                            )}
                            {ad.hasSetupWarnings && isCrossPlatformCampaign && (
                                <span className={classes.dotsbetweenItem}>
                                    <Box componentn="span" display="inline" color="orange">
                                        Low Inventory
                                    </Box>
                                </span>
                            )}
                        </Typography>
                    </Box>
                    <AdName ad={ad} campaignId={campaignId} />
                    <Box mt={1}>
                        <Grid container spacing={1}>
                            {campaignBudgetAllocationMethod === 'manual' && (
                                <Grid item>{ad.healthStatus && <AdHealth ad={ad} />}</Grid>
                            )}
                            <Grid item>
                                <HealthChip
                                    label={creativesExist ? 'Creatives' : 'No creatives assigned'}
                                    customcolor={creativesExist ? '#bdbdbd' : '#f7a35c'}
                                    size="small"
                                    onClick={
                                        creativesExist
                                            ? e =>
                                                  handlePopoverOpen({
                                                      event: e,
                                                      adId: ad.id,
                                                  })
                                            : noop
                                    }
                                    avatar={
                                        creativesExist && (
                                            <Avatar className="healthAvatar">
                                                {creativeLength}
                                            </Avatar>
                                        )
                                    }
                                />
                            </Grid>
                            {!!ad.unapprovedCreatives.length && (
                                <Grid item>
                                    <HealthChip
                                        label="Creatives require approval"
                                        customcolor="#f7a35c"
                                        size="small"
                                        onClick={e =>
                                            handlePopoverOpen({
                                                event: e,
                                                adId: ad.id,
                                                showUnapproved: true,
                                            })
                                        }
                                        avatar={
                                            <Avatar className="healthAvatar">
                                                {ad.unapprovedCreatives.length}
                                            </Avatar>
                                        }
                                    />
                                </Grid>
                            )}
                            {currentAdId === ad.id && (
                                <CreativePopover
                                    openCreatives={openCreatives}
                                    anchorElCreatives={anchorElCreatives}
                                    handlePopoverClose={handlePopoverClose}
                                    campaignId={campaignId}
                                    currentAd={currentAd}
                                    orgTimezone={orgTimezone}
                                    ad={ad}
                                    showUnapprovedCreatives={showUnapprovedCreatives}
                                />
                            )}
                        </Grid>
                    </Box>
                </React.Fragment>
            );
        },
    },
    {
        label: () => 'Actions',
        name: 'ad_actions',
        isPermitted: () => true,
        align: 'center',
        component: ({ adActions }) => <MoreOptionsMenu adActions={adActions} />,
    },
    {
        label: () => 'Summary',
        name: 'ad_summary',
        isPermitted: () => true,
        align: 'left',
        component: ({ ad, campaignId, editAd }) => (
            <Summary ad={ad} campaignId={campaignId} editAd={editAd} />
        ),
    },
    {
        label: () => 'Impressions',
        name: 'ad_impressions',
        isPermitted: () => true,
        align: 'right',
        component: ({ ad }) => <Impressions ad={ad} />,
    },
    {
        label: () => 'Clicks',
        name: 'ad_clicks',
        align: 'right',
        component: ({ ad }) => <Clicks ad={ad} />,
        isPermitted: () => true,
    },
    {
        label: () => 'Goal',
        name: 'ad_booked_value',
        align: 'left',
        component: ({ ad }) => <Goal ad={ad} />,
        isPermitted: () => {
            return true;
        },
    },
    {
        label: () => 'CTR',
        name: 'ad_ctr',
        align: 'right',
        component: ({ ad }) => {
            return !ad.hasStarted ? '-' : formatNumber_percentage(ad.stats.ctr);
        },
        isPermitted: () => true,
    },
    {
        label: ({ campaignCurrencyType }) => {
            return `Total Cost (${campaignCurrencyType})`;
        },
        name: 'ad_total_spend',
        align: 'right',
        component: ({ ad }) => <TotalSpend ad={ad} />,
        isPermitted: () => true,
    },
    {
        label: ({ campaignCurrencyType }) => {
            return `Today's Total Cost (${campaignCurrencyType})`;
        },
        name: 'ad_total_spend_daily',
        align: 'right',
        component: ({ ad }) => <TotalSpendDaily ad={ad} />,
        isPermitted: () => true,
    },
    {
        label: () => 'Total Cost eCPM',
        name: 'ad_total_spend_ecpm',
        align: 'right',
        component: ({ ad }) => {
            return !ad.hasStarted
                ? '-'
                : formatNumber_currency(ad.stats.owner_total_media_cost_local_ecpm);
        },
        isPermitted: () => true,
    },
    {
        label: () => 'Revenue',
        name: 'ad_revenue',
        align: 'right',
        component: ({ ad }) => formatNumber_currency(ad.stats.billings_local),
        isPermitted: ({ campaign }) => {
            return campaign.billing_enabled;
        },
    },
    {
        label: () => "Today's Revenue",
        name: 'ad_revenue_daily',
        align: 'right',
        component: ({ ad }) =>
            formatNumber_currency(ad.stats.daily ? ad.stats.daily.billings_local : 0),
        isPermitted: ({ campaign }) => {
            return campaign.billing_enabled;
        },
    },
    {
        label: () => 'Start date',
        name: 'ad_start_date',
        align: 'left',
        component: ({ ad }) => {
            return !ad.start ? '-' : moment(ad.start).format('MMM D');
        },
        isPermitted: () => {
            return true;
        },
    },
    {
        label: () => 'End date',
        name: 'ad_end_date',
        align: 'left',
        component: ({ ad }) => {
            return !ad.end ? '-' : moment(ad.end).format('MMM D');
        },
        isPermitted: () => {
            return true;
        },
    },
    {
        label: () => 'Bid Price',
        name: 'ad_bid_price',
        align: 'right',
        component: ({ ad }) => {
            return !ad.hasStarted ? '-' : `${ad.totalSpend} CPM`;
        },
        isPermitted: () => {
            return true;
        },
    },
    {
        label: () => 'Margin',
        name: 'ad_margin',
        align: 'right',
        component: ({ ad }) => {
            return formatNumber_percentage(
                (ad.stats.billings_local - ad.stats.owner_total_media_cost_local) /
                    ad.stats.billings_local
            );
        },
        isPermitted: ({ campaign }) => {
            return isInternalUser() && campaign.billing_enabled;
        },
    },
    {
        label: () => 'Win Rate',
        name: 'ad_win_rate',
        align: 'right',
        component: ({ ad }) => {
            return !ad.hasStarted ? '-' : formatNumber_percentage(ad.stats.win_rate);
        },
        isPermitted: () => true,
    },
    {
        label: () => "Today's Win Rate",
        name: 'ad_win_rate_daily',
        align: 'right',
        component: ({ ad }) => {
            return !ad.hasStarted
                ? '-'
                : formatNumber_percentage(ad.stats.daily ? ad.stats.daily.win_rate : 0);
        },
        isPermitted: () => true,
    },
    {
        label: () => 'Total Conversions',
        name: 'conv_overall',
        align: 'right',
        component: ({ ad }) => <OverallConversions ad={ad} />,
        isPermitted: () => true,
    },
    {
        label: () => 'View-through',
        name: 'conv_overall_imp',
        align: 'right',
        component: ({ ad }) => <OverallViewThrough ad={ad} />,
        isPermitted: () => true,
    },
    {
        label: () => 'Click-through',
        name: 'conv_overall_click',
        align: 'right',
        component: ({ ad }) => <OverallClickThrough ad={ad} />,
        isPermitted: () => true,
    },
    {
        label: () => 'Total Cost eCPA',
        name: 'conv_overall_total_cost_ecpa',
        align: 'right',
        component: ({ ad }) => <OverallTotalCostECPA ad={ad} />,
        isPermitted: () => true,
    },
    {
        label: () => 'Revenue eCPA',
        name: 'conv_overall_revenue_ecpa',
        align: 'right',
        component: ({ ad }) => <OverallRevenueECPA ad={ad} />,
        isPermitted: () => true,
    },
];

const getActions = ({
    editAd,
    openAdHistory,
    duplicateAd,
    campaignId,
    ad,
    campaign,
    handleClickArchive,
}) => {
    const options = [
        {
            label: 'Edit',
            link: null,
            canView: canEditAd(ad),
            onClick: () => editAd(campaignId, ad.id),
            icon: <i className="fa fa-fw fa-pencil" />,
        },
        {
            label: 'History',
            link: null,
            canView: () => true,
            onClick: () => openAdHistory(campaignId, ad.id),
            icon: <i className="fa fa-fw fa-clock-o" />,
        },
        {
            label: 'Duplicate',
            link: null,
            canView: canDuplicateAd(campaign),
            onClick: () => duplicateAd(campaignId, ad.id),
            icon: <i className="fa fa-fw fa-files-o" />,
        },
        {
            label: 'Progress',
            link: `/campaigns/${campaignId}/progress/${ad.id}`,
            canView: () => true,
            onClick: null,
            icon: <i className="fa fa-fw fa-tasks" />,
        },
        {
            label: 'Archive',
            link: null,
            canView: canArchiveAd(ad),
            onClick: () => handleClickArchive({ campaignId, ad }),
            icon: <ArchiveIcon />,
        },
    ];

    return _.filter(options, option => option.canView);
};

class CampaignOverviewAdsLayout extends React.Component {
    newAd = () => {
        const pathname = this.props.location.pathname;
        const isNewAdForm = /^.*setup\/ads\/new$/.test(pathname);
        if (!isNewAdForm) {
            browserHistory.push(`/campaigns/${this.props.campaignId}/setup/ads/new`);
        } else {
            return null;
        }
    };

    newTacticAd = () => {
        const pathname = this.props.location.pathname;
        const isNewAdForm = /^.*setup\/ads\/new$/.test(pathname);
        if (!isNewAdForm) {
            browserHistory.push(`/campaigns/${this.props.campaignId}/setup/ads/new?tactics=1`);
        } else {
            return null;
        }
    };

    newAdPreset = () => {
        const { campaignId } = this.props;
        browserHistory.push(`/ad-preset/new?campaign=${campaignId}`);
    };

    handleFilterChange = (filterType, value) => {
        const { campaignId } = this.props;
        const filterOptions = { filterType, value };
        this.props.dispatch(filterList(campaignId, filterOptions));
    };

    render() {
        const {
            toggleColumn,
            columns,
            adsList,
            campaignCurrencyType,
            searchQuery,
            sortType,
            hiddenColumns,
            fetchingHiddenColumns,
            selectedAds,
            handlePauseAds,
            handleStartAds,
            campaign,
            campaignId,
        } = this.props;

        return (
            <div className="ef3-CampaignOverviewAdsLayout">
                <div className="ef3-campaignOverview_AdList_workspaceToolbar">
                    <OverviewTabs tab="ads" campaignId={campaignId} />
                    <div className="ef3-standardToolbar">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={4}>
                                        <SearchInput
                                            placeholder="Search"
                                            onChange={e => {
                                                this.handleFilterChange('search', e.target.value);
                                            }}
                                            defaultValue={searchQuery}
                                        />
                                    </Grid>
                                    <Grid item>Sort:</Grid>
                                    <Grid item xs={4}>
                                        <SimpleSelect
                                            value={sortType}
                                            onChange={e => {
                                                this.handleFilterChange('sort', e.target.value);
                                            }}
                                            options={[
                                                { value: 'byEndDate', label: 'Ending soonest' },
                                                {
                                                    value: 'byDateCreated',
                                                    label: 'Last created',
                                                },
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1} justifyContent="flex-end">
                                    {campaign && canCreateAd(campaign) && (
                                        <React.Fragment>
                                            <Grid />
                                            <Grid item>
                                                <Grid container direction="column">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.newTacticAd}
                                                        startIcon={<AddIcon />}
                                                    >
                                                        New Ad
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <BlockLoadGroup isLoading={false}>
                    <Paper>
                        <MuiToolBar highlight={selectedAds.length}>
                            {selectedAds.length ? (
                                <React.Fragment>
                                    <Typography variant="subtitle1">
                                        {' '}
                                        {`${selectedAds.length} selected`}{' '}
                                    </Typography>
                                    <div>
                                        <Button onClick={handleStartAds}>Enable</Button>
                                        <Button onClick={handlePauseAds}>Disable</Button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Grid container spacing={5} alignItems="center">
                                        <Grid item>
                                            <Typography variant="h6"> All Ads </Typography>
                                        </Grid>
                                    </Grid>
                                    <ArchivedAdsVisibilityToggle />
                                    {!fetchingHiddenColumns && (
                                        <ColumnsDropdown
                                            columns={columns}
                                            campaignCurrencyType={campaignCurrencyType}
                                            onClick={toggleColumn}
                                            hiddenColumns={hiddenColumns}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </MuiToolBar>
                        {adsList}
                    </Paper>
                </BlockLoadGroup>
                <div className="ef3-CampaignOverviewAdsLayout_workspace1">
                    {this.props.workspace}
                </div>
                {this.props.archiveConfirmation}
                <ArchiveConfirmation />
            </div>
        );
    }
}

// =================
// Ad collection
// =================
const CampaignOverviewAds = createReactClass({
    displayName: 'CampaignOverviewAds',

    state: {
        currentAdId: null,
    },

    statics: {
        refresh(dispatch, nextState) {
            const campaignId = _.get(nextState, 'params.campaignId', void 0);
            const query = _.get(nextState, 'location.query', {});

            if (query.search) {
                dispatch(filterList(campaignId, { filterType: 'search', value: query.search }));
            }

            if (query.sort) {
                dispatch(filterList(campaignId, { filterType: 'sort', value: query.sort }));
            }
        },
    },

    init() {
        const { dispatch, location, params, routes, campaign } = this.props;
        const nextState = { location, params, routes };

        dispatch(fetchUserPreferences());
        if (campaign.isCrossPlatformCampaign) {
            dispatch(
                fetchAudiencesForWarnings({
                    shouldHideFlexSegments: campaign.shouldHideFlexSegments,
                })
            );
        }

        CampaignOverviewAds.refresh(dispatch, nextState, browserHistory.push);
    },

    componentDidMount() {
        this.init();
    },

    componentDidUpdate(prevProps) {
        if (
            prevProps.params.campaignId &&
            this.props.params.campaignId !== prevProps.params.campaignId
        ) {
            this.init();
        }
    },

    editAd(_destination_campaignId, _destination_adId) {
        // parse to string;
        const destination_adId = _destination_adId + '';
        const destination_campaignId = _destination_campaignId + '';

        if (destination_campaignId && destination_adId) {
            const destinationPath = `/campaigns/${destination_campaignId}/setup/ads/${destination_adId}/edit`;
            const currentPath = this.props.location.pathname;
            const isAdEditMode = /^.*campaigns\/(\d*)\/setup\/ads\/\d*\/edit$/.test(currentPath);
            const isAdsPathEndWithEdit = /^.*campaigns\/(\d*)\/setup\/ads.*(\/edit)$/.test(
                currentPath
            );
            const isAdsPathNotEdit = !isAdsPathEndWithEdit;

            const params_adId = this.props.params.adId;

            if (destination_adId === params_adId && isAdEditMode) {
                // already editing thus do nothing
                return void 0;
            } else if (destination_adId !== params_adId && isAdEditMode) {
                // edit another ad.
                browserHistory.push(destinationPath);
            } else if (destination_adId && destination_campaignId && isAdsPathNotEdit) {
                browserHistory.push(destinationPath);
            }
        } else {
            return void 0;
        }
    },

    deleteAd(adId) {
        this.props.dispatch(deleteAd(this.props.params.campaignId, adId));
    },

    openAdHistory(campaignId, adId) {
        browserHistory.push(`/campaigns/${campaignId}/setup/ads/${adId}/history`);
    },

    duplicateAd(campaignId, adId) {
        browserHistory.push(`/campaigns/${campaignId}/setup/ads/${adId}/duplicate`);
    },

    handleRowClick(adId) {
        this.setState({
            currentAdId: adId,
        });
    },

    closeAdSummary() {
        this.setState({
            currentAdId: null,
        });
    },

    toggleColumn(column) {
        this.props.dispatch(toggleColumn(column));
    },

    handleCheckAll({ checked }) {
        const { dispatch, params } = this.props;

        const campaignId = params.campaignId;

        dispatch(selectAll(checked, campaignId));
    },

    handleSelectAd({ adId, checked }) {
        const { selectedAds, dispatch } = this.props;

        dispatch(selectAd(adId, checked, selectedAds));
    },

    handlePauseAds() {
        const { dispatch, params, selectedAds } = this.props;

        const campaignId = params.campaignId;

        dispatch(pause(campaignId, selectedAds));
    },

    handleStartAds() {
        const { dispatch, params, selectedAds } = this.props;

        const campaignId = params.campaignId;

        dispatch(unpause(campaignId, selectedAds));
    },

    render() {
        const {
            params,
            ads,
            searchQuery,
            sortType,
            isInitialized,
            campaignCurrencyType,
            adsLength_beforeFilter,
            campaign,
            hiddenColumns,
            fetchingHiddenColumns,
            orgTimezone,
            selectedAds,
            adsBeingProcessed,
        } = this.props;

        const campaignId = params.campaignId;

        let filteredHeaders = [];
        if (campaign) {
            filteredHeaders =
                campaign.budget_allocation_method === 'manual'
                    ? _.filter(headers, header => header.isPermitted({ campaign }))
                    : _.filter(
                          headers,
                          header =>
                              header.isPermitted({ campaign }) && header.name !== 'ad_booked_value'
                      );
        }

        return (
            <CampaignOverviewAdsLayout
                campaign={campaign}
                hiddenColumns={hiddenColumns}
                toggleColumn={this.toggleColumn}
                columns={filteredHeaders}
                campaignId={campaignId}
                campaignCurrencyType={campaignCurrencyType}
                history={this.props.history}
                dispatch={this.props.dispatch}
                location={this.props.location}
                fetchingHiddenColumns={fetchingHiddenColumns}
                adsLength={ads.length}
                searchQuery={searchQuery}
                sortType={sortType}
                workspace={this.props.workspace}
                adsLength_beforeFilter={adsLength_beforeFilter}
                selectedAds={selectedAds}
                handlePauseAds={this.handlePauseAds}
                handleStartAds={this.handleStartAds}
                adsList={
                    <AdsTable
                        campaignCurrencyType={campaignCurrencyType}
                        columns={[{ name: 'ad 1' }]}
                        ads={ads}
                        campaignId={campaignId}
                        editAd={this.editAd}
                        deleteAd={this.deleteAd}
                        openAdHistory={this.openAdHistory}
                        duplicateAd={this.duplicateAd}
                        hiddenColumns={hiddenColumns}
                        campaign={campaign}
                        isInitialized={isInitialized}
                        adsLength={ads.length}
                        fetchingHiddenColumns={fetchingHiddenColumns}
                        orgTimezone={orgTimezone}
                        handleCheckAll={this.handleCheckAll}
                        handleSelectAd={this.handleSelectAd}
                        selectedAds={selectedAds}
                        adsBeingProcessed={adsBeingProcessed}
                    />
                }
            />
        );
    },
});

const useStyles = makeStyles(theme => ({
    rightSide: {
        display: 'flex',
        alignItems: 'center',
    },
    tableHeader: {
        whiteSpace: 'nowrap',
    },
    summary: {
        maxWidth: 400,
        maxHeight: 400,
    },
    paper: {
        margin: '0 20px',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableWrapper: {
        width: '100%',
        overflow: 'auto',
    },
    highlight: {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
    dotsbetweenItem: {
        '&:not(:first-child)::before': {
            content: '"\\2022"',
            padding: '0px 5px',
        },
    },
    drawer: {
        width: 550,
        padding: theme.spacing(2),
    },
    visibilityToggle: {
        minWidth: 180,
    },
}));

const Paper = withStyles({
    root: {
        margin: '0 20px',
    },
})(MuiPaper);

function MuiToolBar(props) {
    const classes = useStyles();

    return (
        <AppBar position="static" color="inherit" elevation={0}>
            <Toolbar
                classes={{ root: classes.toolbar }}
                className={classnames({
                    [classes.highlight]: props.highlight,
                })}
            >
                {props.children}
            </Toolbar>
        </AppBar>
    );
}

function AdsTable(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        ads,
        campaignId,
        editAd,
        deleteAd,
        openAdHistory,
        duplicateAd,
        hiddenColumns,
        campaign,
        campaignCurrencyType,
        isInitialized,
        adsLength,
        fetchingHiddenColumns,
        orgTimezone,
        handleCheckAll,
        handleSelectAd,
        selectedAds,
        adsBeingProcessed,
    } = props;

    if (!isInitialized) {
        return <LinearProgress />;
    }

    const [currentAdId, setCurrentAdId] = React.useState(null);

    const [anchorElCreatives, setAnchorElCreatives] = React.useState(null);
    const [showUnapprovedCreatives, setShowUnapprovedCreatives] = React.useState(false);

    const handlePopoverOpen = ({ event, adId, showUnapproved = false }) => {
        setCurrentAdId(adId);
        setAnchorElCreatives(event.currentTarget);
        setShowUnapprovedCreatives(showUnapproved);
    };

    const handlePopoverClose = () => {
        setAnchorElCreatives(null);
        setShowUnapprovedCreatives(false);
    };

    const openCreatives = Boolean(anchorElCreatives);

    let filteredColumns =
        campaign.budget_allocation_method === 'manual'
            ? _(headers)
                  .filter(header => header.isPermitted({ campaign }))
                  .filter(header => !hiddenColumns[header.name])
                  .value()
            : _(headers)
                  .filter(header => header.isPermitted({ campaign }))
                  .filter(header => !hiddenColumns[header.name])
                  .filter(header => header.name !== 'ad_booked_value')
                  .value();

    let conversionColumns = _.filter(filteredColumns, ({ name }) => _.includes(name, 'conv_'));
    let nonConversionColumns = _.filter(filteredColumns, ({ name }) => !_.includes(name, 'conv_'));
    let mainHeaderColumns = [];

    const hasConversions = conversionColumns.length > 0;

    if (hasConversions) {
        filteredColumns = _.concat(nonConversionColumns, conversionColumns);
        mainHeaderColumns = _.concat(nonConversionColumns, [
            {
                label: () => 'Overall Conversions',
                name: 'conv_header_overall',
                align: 'center',
                component: () => '',
                colSpan: conversionColumns.length,
                isPermitted: () => true,
            },
        ]);
    }

    const emptyColumns = _.map(_.range(nonConversionColumns.length + 1), iteration => {
        return {
            label: () => '',
            name: `empty${iteration}`,
            align: 'center',
            component: () => '',
            isPermitted: true,
        };
    });

    let subheaderColumns = _.concat(emptyColumns, conversionColumns);

    const isAllChecked = selectedAds.length > 0;

    let currentAd = {};

    if (currentAdId) {
        currentAd = _.find(ads, ad => ad.id === currentAdId);
    }

    const handleClickArchive = ({ campaignId, ad }) => {
        dispatch(openArchiveConfirmation({ campaignId, ad }));
    };

    return (
        <div className={classes.tableWrapper}>
            <Table stickyHeader>
                {!fetchingHiddenColumns && (
                    <TableHead>
                        <TableRow>
                            {ads.length > 0 && (
                                <TableCell>
                                    <Checkbox
                                        onClick={e => handleCheckAll({ checked: e.target.checked })}
                                        checked={isAllChecked}
                                        color="primary"
                                        disabled={false}
                                    />
                                </TableCell>
                            )}
                            {_.map(hasConversions ? mainHeaderColumns : filteredColumns, header => (
                                <TableCell
                                    className={classes.tableHeader}
                                    key={header.name}
                                    align={header.align}
                                    colSpan={
                                        header.name === 'conv_header_overall' ? header.colSpan : 1
                                    }
                                >
                                    {header.label({ campaignCurrencyType, ads })}
                                </TableCell>
                            ))}
                        </TableRow>
                        {hasConversions && (
                            <TableRow>
                                {_.map(subheaderColumns, header => (
                                    <TableCell
                                        className={classes.tableHeader}
                                        key={header.name}
                                        align={header.align}
                                    >
                                        {_.includes(header.name, 'conv_')
                                            ? header.label({ campaignCurrencyType, ads })
                                            : ''}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                    </TableHead>
                )}
                <TableBody>
                    {adsLength === 0 ? (
                        <TableRow>
                            <TableCell colSpan={filteredColumns.length}>No Ads Found</TableCell>
                        </TableRow>
                    ) : (
                        _.map(ads, ad => {
                            const adActions = getActions({
                                editAd,
                                openAdHistory,
                                duplicateAd,
                                deleteAd,
                                campaignId,
                                ad,
                                campaign,
                                handleClickArchive,
                            });

                            return (
                                <TableRow key={ad.id} hover>
                                    <TableCell>
                                        <Checkbox
                                            onClick={e =>
                                                handleSelectAd({
                                                    adId: ad.id,
                                                    checked: e.target.checked,
                                                })
                                            }
                                            checked={_.includes(selectedAds, ad.id)}
                                            color="primary"
                                            disabled={false}
                                        />
                                    </TableCell>
                                    {_.map(filteredColumns, column => (
                                        <TableCell
                                            key={column.name}
                                            component="th"
                                            scope="row"
                                            align={column.align}
                                        >
                                            {column.component({
                                                ad,
                                                campaign,
                                                campaignId,
                                                editAd,
                                                deleteAd,
                                                openAdHistory,
                                                duplicateAd,
                                                campaignBudgetAllocationMethod:
                                                    campaign.budget_allocation_method,
                                                orgTimezone,
                                                isCrossPlatformCampaign:
                                                    campaign.isCrossPlatformCampaign,
                                                selectedAds,
                                                adsBeingProcessed,
                                                adActions,
                                                handlePopoverOpen,
                                                handlePopoverClose,
                                                openCreatives,
                                                anchorElCreatives,
                                                currentAd,
                                                currentAdId,
                                                classes,
                                                showUnapprovedCreatives,
                                            })}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

function ActionColumn(props) {
    const { ad, campaignId, adsBeingProcessed } = props;

    return (
        <InlineTooltip
            isEnabled={ad.unalteredDuplicate}
            position="right"
            tooltip={
                <div>
                    This Ad cannot be set live because it does not have any start or end dates set.
                </div>
            }
        >
            {_.includes(adsBeingProcessed, ad.id) ? (
                <CircularProgress />
            ) : (
                <AdStatusToggle
                    key={ad.paused}
                    paused={ad.paused}
                    status={ad.status}
                    adId={ad.id}
                    campaignId={campaignId}
                    disabled={false}
                />
            )}
        </InlineTooltip>
    );
}

function AdName(props) {
    const { ad, campaignId } = props;
    const profileState = useProfileState();

    const canRoleAccessAd = _.includes(
        ['admin', 'campaign_manager', 'root', 'account_admin'],
        profileState.globalRole
    );

    if (canRoleAccessAd && !isArchivedAd(ad)) {
        return (
            <Link
                to={`/campaigns/${campaignId}/setup/ads/${ad.id}/edit`}
                color="initial"
                component={MuiLink}
            >
                <Typography variant="body1">{ad.name}</Typography>
            </Link>
        );
    } else {
        return (
            <div className="ef3-campaignOverview__ad-title">
                <BodyText className="ef3-campaignOverview__ad-title-name">{ad.name}</BodyText>
            </div>
        );
    }
}

function CreativesByScheduleWeight({ schedules, timezone }) {
    const classes = useStyles();

    return _.map(schedules, schedule => (
        <div key={schedule.id}>
            <Box display="flex" ml={1}>
                <Typography color="textSecondary" variant="body2">
                    <span>{`${moment
                        .tz(schedule.start, timezone)
                        .format('YYYY-MM-DD HH:mm z')} - ${moment
                        .tz(schedule.end, timezone)
                        .format('YYYY-MM-DD HH:mm z')}`}</span>
                    {schedule.isActive && (
                        <span className={classes.dotsbetweenItem}>
                            <Box display="inline" color="green">
                                Currently running
                            </Box>
                        </span>
                    )}
                </Typography>
            </Box>
            {_.map(schedule.weighted, weighted => (
                <Link key={weighted.id} color="initial" to={weighted.link} component={MuiLink}>
                    <ListItem button>
                        <Typography variant="body2">
                            #{weighted.markup_id} {weighted.name}
                        </Typography>
                        {weighted.hasCpcInsConflict && <CpcConflictTooltip />}
                    </ListItem>
                </Link>
            ))}
        </div>
    ));
}

const CreativesRotationByWeather = ({ settings }) => {
    return _.map(settings, setting => (
        <Link key={setting.id} color="initial" to={setting.creative.link} component={MuiLink}>
            <ListItem button>
                <Typography variant="body2">
                    {`#${setting.creative.markupId} ${setting.creative.name}`}
                </Typography>
            </ListItem>
        </Link>
    ));
};

function Creatives({ ad, orgTimezone, currentAd, campaignId, showUnapprovedCreatives }) {
    let creativeComponent;

    const createCreativeLink = ({ name, id, hasCpcInsConflict }) => {
        return (
            <Link
                key={id}
                color="initial"
                to={`/campaigns/${campaignId}/setup/creatives#${id}`}
                component={MuiLink}
            >
                <ListItem button>
                    <Typography variant="body2">
                        #{id} {name}
                    </Typography>
                </ListItem>
                {hasCpcInsConflict && <CpcConflictTooltip />}
            </Link>
        );
    };

    if (showUnapprovedCreatives) {
        creativeComponent = _.map(currentAd.unapprovedCreatives, createCreativeLink);
    } else if (ad.scheduledWeightedRotationSummary) {
        creativeComponent = (
            <CreativesByScheduleWeight
                schedules={ad.scheduledWeightedRotationSummary}
                timezone={orgTimezone}
            />
        );
    } else if (ad.creativeRotationByWeatherSummary) {
        creativeComponent = (
            <CreativesRotationByWeather settings={ad.creativeRotationByWeatherSummary} />
        );
    } else {
        creativeComponent = _.map(currentAd.creatives, createCreativeLink);
    }
    return <List>{creativeComponent}</List>;
}

function AdId(props) {
    const { ad, campaignId } = props;
    const profileState = useProfileState();
    const canRoleAccessAd = _.includes(
        ['admin', 'campaign_manager', 'root'],
        profileState.globalRole
    );

    if (canRoleAccessAd) {
        return (
            <Link to={`/campaigns/${campaignId}/setup/ads/${ad.id}/edit`}>
                <BodyText className="ef3-campaignOverview__ad-title-id">#{ad.id}</BodyText>
            </Link>
        );
    } else {
        return <BodyText className="ef3-campaignOverview__ad-title-id">#{ad.id}</BodyText>;
    }
}

function Impressions(props) {
    const { ad } = props;
    return <p>{ad.isLoading || !ad.hasStarted ? '-' : formatNumber_whole(ad.stats.impressions)}</p>;
}

function Goal(props) {
    const { ad } = props;

    if (ad.billingTerms === 'CPM') {
        return (
            <div>
                {ad.maxTotals.max_total_impressions > 0 ? (
                    <p className="ads-overview__stats-additional-info">
                        {formatNumber_percentage(
                            ad.stats.impressions / ad.maxTotals.max_total_impressions
                        )}{' '}
                        of {formatNumber_whole(ad.maxTotals.max_total_impressions)} impressions
                        booked
                    </p>
                ) : (
                    <p className="ads-overview__stats-additional-info" />
                )}
                {ad.maxDailyTotals.max_daily_impressions > 0 ? (
                    <p className="ads-overview__stats-additional-info">
                        {formatNumber_whole(ad.maxDailyTotals.max_daily_impressions)} daily
                    </p>
                ) : (
                    <p className="ads-overview__stats-additional-info" />
                )}
            </div>
        );
    }

    if (ad.billingTerms === 'CPC') {
        return (
            <div>
                {ad.maxTotals.max_total_clicks > 0 ? (
                    <p className="ads-overview__stats-additional-info">
                        {formatNumber_percentage(ad.stats.clicks / ad.maxTotals.max_total_clicks)}{' '}
                        of {formatNumber_whole(ad.maxTotals.max_total_clicks)} clicks
                    </p>
                ) : (
                    <p className="ads-overview__stats-additional-info" />
                )}
                {ad.maxDailyTotals.max_daily_clicks > 0 ? (
                    <p className="ads-overview__stats-additional-info">
                        {formatNumber_whole(ad.maxDailyTotals.max_daily_clicks)} daily
                    </p>
                ) : (
                    <p className="ads-overview__stats-additional-info" />
                )}
            </div>
        );
    }

    if (ad.billingTerms === 'billable_media_cost_margin') {
        return (
            <div>
                {ad.maxTotals.max_total_spend_local > 0 ? (
                    <p className="ads-overview__stats-additional-info">
                        {formatNumber_percentage(ad.stats.billings_local / ad.maxTotalDelivery)} of{' '}
                        {formatNumber_currency(ad.maxTotalDelivery)} revenue budget
                    </p>
                ) : (
                    <p className="ads-overview__stats-additional-info" />
                )}
                {ad.maxDailyTotals.max_daily_spend_local > 0 ? (
                    <p className="ads-overview__stats-additional-info">
                        ${formatNumber_whole(ad.maxDailyTotals.max_daily_spend_local)} daily
                    </p>
                ) : (
                    <p className="ads-overview__stats-additional-info" />
                )}
            </div>
        );
    }

    return null;
}

function Clicks(props) {
    const { ad } = props;
    return <p>{ad.isLoading || !ad.hasStarted ? '-' : formatNumber_whole(ad.stats.clicks)}</p>;
}

const adHealthStatus = {
    bad: {
        label: 'Critical',
        color: '#f36a7f',
    },
    good: {
        label: 'On Track',
        color: '#13baaa',
    },
    ok: {
        label: 'Behind',
        color: '#f8cf61',
    },
    pending: {
        label: 'Scheduled',
        color: '#9fbfff',
    },
};

function AdHealth({ ad }) {
    return (
        <HealthChip
            label={adHealthStatus[ad.healthStatus].label}
            customcolor={adHealthStatus[ad.healthStatus].color}
            size="small"
        />
    );
}

function CpcConflictTooltip() {
    return (
        <InlineTooltip
            position="right"
            tooltip={
                <div>
                    <div>
                        Warning: this ad is being paced by clicks and DoubleClick INS tags may not
                        track clicks properly.
                    </div>
                    <div>This ad requires frequent monitoring to prevent overbuying.</div>
                </div>
            }
        >
            <span className="ef3-campaignOverview_AdList_description--cpc-conflict-icon">
                <i className="fa fa-fw fa-exclamation-triangle" />
            </span>
        </InlineTooltip>
    );
}

function TotalSpend(props) {
    const { ad } = props;
    return (
        <p>
            {ad.isLoading || !ad.hasStarted
                ? '-'
                : formatNumber_currency(ad.stats.owner_total_media_cost_local)}
        </p>
    );
}

function TotalSpendDaily(props) {
    const { ad } = props;
    return (
        <p>
            {ad.isLoading || !ad.hasStarted
                ? '-'
                : formatNumber_currency(
                      ad.stats.daily ? ad.stats.daily.owner_total_media_cost_local : 0
                  )}
        </p>
    );
}

function OverallConversions({ ad }) {
    return <p>{ad.isLoading || !ad.hasStarted ? '-' : ad.stats.conv_overall}</p>;
}

function OverallViewThrough({ ad }) {
    return <p>{ad.isLoading || !ad.hasStarted ? '-' : ad.stats.conv_overall_imp}</p>;
}

function OverallClickThrough({ ad }) {
    return <p>{ad.isLoading || !ad.hasStarted ? '-' : ad.stats.conv_overall_click}</p>;
}

function OverallTotalCostECPA({ ad }) {
    return (
        <p>
            {ad.isLoading || !ad.hasStarted
                ? '-'
                : formatNumber_currency(ad.stats.conv_overall_total_cost_ecpa)}
        </p>
    );
}

function OverallRevenueECPA({ ad }) {
    return (
        <p>
            {ad.isLoading || !ad.hasStarted
                ? '-'
                : formatNumber_currency(ad.stats.conv_overall_revenue_ecpa)}
        </p>
    );
}

function Summary(props) {
    const classes = useStyles();

    const { ad, campaignId } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton onClick={handleClick} size="large">
                <AssignmentIcon />
            </IconButton>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.drawer }}
            >
                <DialogTitle onClose={handleClose}>{`${ad.id} ${ad.name}`}</DialogTitle>
                <AdSummary
                    adId={ad.id}
                    campaignId={campaignId}
                    actions={
                        canEditAd(ad) && (
                            <Box float="right">
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={() => props.editAd(campaignId, ad.id)}
                                        size="large"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )
                    }
                />
            </Drawer>
        </div>
    );
}

function ArchiveConfirmation() {
    const [status, setStatus] = useState('pending');
    const dispatch = useDispatch();
    const { isArchiveConfirmationOpen, adToArchive, campaignId } = useSelector(
        state => state.adsOverview
    );

    const handleCancel = () => {
        dispatch(closeArchiveConfirmation());
    };

    const handleArchive = async () => {
        try {
            if (status === 'loading') {
                return;
            }
            setStatus('loading');
            await dispatch(archiveAd({ campaignId, adId: adToArchive.id }));
            setStatus('pending');
            handleCancel();
        } catch (err) {
            setStatus('pending');
        }
    };

    if (!adToArchive) {
        return null;
    }

    return (
        <Dialog disableEscapeKeyDown maxWidth="sm" open={isArchiveConfirmationOpen}>
            <MuiDialogTitle>
                #{adToArchive.id} {adToArchive.name}
            </MuiDialogTitle>
            <DialogContent>
                This ad will be permanently archived and can no longer be edited or set live.
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleArchive}
                    disabled={status === 'loading'}
                    variant="outlined"
                    color="primary"
                >
                    Archive
                </Button>
                <Button autoFocus onClick={handleCancel} disabled={status === 'loading'}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ArchivedAdsVisibilityToggle() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { shouldShowArchivedAds } = useSelector(state => state.adsOverview);

    const handleToggle = () => {
        dispatch(toggleArchivedAdVisibility());
    };

    return (
        <FormControlLabel
            control={
                <Switch checked={shouldShowArchivedAds} onChange={handleToggle} color="primary" />
            }
            label="Show Archived Ads"
            className={classes.visibilityToggle}
        />
    );
}

function MoreOptionsMenu({ adActions }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={1}>
                {_.map(adActions, action => (
                    <MenuItem key={action.label} onClick={action.onClick}>
                        <Link to={action.link} color="inherit" underline="none" component={MuiLink}>
                            {action.label}
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}

function CreativePopover(props) {
    const { openCreatives, anchorElCreatives, handlePopoverClose } = props;

    return (
        <Popover
            id="mouse-over-popover"
            open={openCreatives}
            anchorEl={anchorElCreatives}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Creatives {...props} />
        </Popover>
    );
}

const CampaignOverviewAdsWrapper = props => {
    const { campaignId } = props.params;
    const { isInitialized, init, unInit } = useAdsOverview();
    useEffect(() => {
        init(campaignId);

        return unInit;
    }, []);

    if (!isInitialized) {
        return (
            <React.Fragment>
                <OverviewTabs tab="ads" campaignId={campaignId} />
                <LinearProgress />
            </React.Fragment>
        );
    }

    return <CampaignOverviewAds {...props} />;
};

export default connect(selector)(CampaignOverviewAdsWrapper);

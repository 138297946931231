import { makeActions } from 'utils/redux-tools';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

export const REDUCER_KEY = 'audiencesOverallTableDashboard';
export const initialState = {
    isLoading: false,
    audiences: [],
    audienceSidebar: {
        selectedAudience: {},
        audience: {},
        isLoading: false,
        isOpen: false,
        error: null,
    },
    filters: [],
    isModalOpen: false,
    searchString: null,
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        initAudiencesOverallTable: (state, { searchString }) => ({
            ...state,
            isLoading: true,
            searchString,
        }),
        initSuccess: (state, { audiences }) => ({
            ...state,
            isLoading: false,
            audiences,
        }),
        fetchSidebarAudience: (state, payload) => ({
            ...state,
            audienceSidebar: {
                ...state.audienceSidebar,
                selectedAudience: {
                    id: payload.id,
                    name: payload.name,
                    type: payload.type,
                },
                isLoading: true,
                error: null,
            },
        }),
        fetchSidebarAudienceSuccess: (state, payload) => ({
            ...state,
            audienceSidebar: {
                ...state.audienceSidebar,
                audience: payload.audience,
                isLoading: false,
                error: null,
            },
        }),
        fetchSidebarAudienceError: (state, payload) => ({
            ...state,
            audienceSidebar: {
                audience: {},
                isLoading: false,
                error: payload,
            },
        }),
        toggleSidebar: state => ({
            ...state,
            audienceSidebar: {
                ...state.audienceSidebar,
                isOpen: !state.audienceSidebar.isOpen,
            },
        }),
        toggleCategory: (state, { value }) => {
            let newFilters = [...state.filters];
            if (includes(state.filters, value)) {
                newFilters = filter(state.filters, _filter => _filter !== value);
            } else {
                newFilters.push(value);
            }
            return { ...state, filters: newFilters };
        },
        openModal: state => ({
            ...state,
            isModalOpen: true,
        }),
        closeModal: state => ({
            ...state,
            isModalOpen: false,
        }),
        editAudience: state => ({
            ...state,
            isLoading: true,
        }),
        editAudienceSuccess: state => ({
            ...state,
            isLoading: false,
        }),
        editAudienceFail: state => ({
            ...state,
            isLoading: false,
        }),
    },
});

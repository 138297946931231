export const DealType = {
    Private_Auction: 'private_auction',
    Private_Deal: 'private_deal',
};

export const DEAL_TYPE_OPTIONS = [
    { value: DealType.Private_Auction, label: 'Private Auction' },
    { value: DealType.Private_Deal, label: 'Preferred Deal' },
];

const TYPE_MAPPING = {};
DEAL_TYPE_OPTIONS.map(deal => (TYPE_MAPPING[deal.value] = deal));

export const DEAL_TYPE_MAPPING = TYPE_MAPPING;

export const DealCategory = {
    Private: 'private',
    Curated: 'curated',
};

export const DEAL_CATEGORY_OPTIONS = [
    { value: DealCategory.Private, label: 'Private Deals' },
    { value: DealCategory.Curated, label: 'Curated Deals' },
];

const CATEGORY_MAPPING = {};
DEAL_CATEGORY_OPTIONS.map(deal => (CATEGORY_MAPPING[deal.value] = deal));

export const DEAL_CATEGORY_MAPPING = CATEGORY_MAPPING;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        width: 200,
        height: 200,
    },
    dooh: {
        width: 135,
        height: 135,
    },
});

const IPHONE_HEIGHT = 100;
const IPHONE_WIDTH = 50;

const DESKTOP_HEIGHT = 100;
const DESKTOP_WIDTH = 150;

const IphoneDefaultIcon = props => (
    <svg fontSize="large" width={IPHONE_WIDTH} height={IPHONE_HEIGHT} viewBox="0 0 146 297">
        <g id="iphone_icon" stroke={props.color} strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="home-button" stroke={props.color} cx="73" cy="278" r="10" />
            <rect
                id="ipgone-screen"
                stroke={props.color}
                x="9.5"
                y="33.5"
                width="127"
                height="227"
            />
            <rect id="mircophone" fill={props.color} x="63" y="16" width="19" height="3" rx="1" />
            <circle id="camera" fill={props.color} cx="53.5" cy="17.5" r="2.5" />
            <circle id="top-circle" fill={props.color} cx="74" cy="9" r="2" />
            <rect
                id="iphone-body"
                stroke={props.color}
                x="0.5"
                y="0.5"
                width="145"
                height="296"
                rx="16"
            />
        </g>
    </svg>
);

const IphoneInAppIcon = props => (
    <svg fontSize="large" width={IPHONE_WIDTH} height={IPHONE_HEIGHT} viewBox="0 0 146 297">
        <g id="iphone_in_app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="home-button" stroke={props.color} cx="73" cy="278" r="10" />
            <rect
                id="ipgone-screen"
                stroke={props.color}
                x="9.5"
                y="33.5"
                width="127"
                height="227"
            />
            <rect
                id="mircophone"
                fill={props.color}
                fillRule="nonzero"
                x="63"
                y="16"
                width="19"
                height="3"
                rx="1"
            />
            <circle id="camera" fill={props.color} fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
            <circle id="top-circle" fill={props.color} fillRule="nonzero" cx="74" cy="9" r="2" />
            <rect
                id="iphone-body"
                stroke={props.color}
                x="0.5"
                y="0.5"
                width="145"
                height="296"
                rx="16"
            />
            <rect
                id="box"
                fill={props.color}
                fillRule="nonzero"
                x="19"
                y="47"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy"
                fill={props.color}
                fillRule="nonzero"
                x="47"
                y="47"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy-2"
                fill={props.color}
                fillRule="nonzero"
                x="76"
                y="47"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy-3"
                fill={props.color}
                fillRule="nonzero"
                x="104"
                y="47"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box"
                fill={props.color}
                fillRule="nonzero"
                x="19"
                y="105"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy"
                fill={props.color}
                fillRule="nonzero"
                x="47"
                y="105"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy-2"
                fill={props.color}
                fillRule="nonzero"
                x="76"
                y="105"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy-3"
                fill={props.color}
                fillRule="nonzero"
                x="104"
                y="105"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box"
                fill={props.color}
                fillRule="nonzero"
                x="19"
                y="76"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy"
                fill={props.color}
                fillRule="nonzero"
                x="47"
                y="76"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy-2"
                fill={props.color}
                fillRule="nonzero"
                x="76"
                y="76"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy-3"
                fill={props.color}
                fillRule="nonzero"
                x="104"
                y="76"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box"
                fill={props.color}
                fillRule="nonzero"
                x="19"
                y="137"
                width="23"
                height="23"
                rx="5"
            />
            <rect
                id="box-copy"
                fill={props.color}
                fillRule="nonzero"
                x="47"
                y="137"
                width="23"
                height="23"
                rx="5"
            />
        </g>
    </svg>
);

const IphoneMWebIcon = props => (
    <svg fontSize="large" width={IPHONE_WIDTH} height={IPHONE_HEIGHT} viewBox="0 0 146 297">
        <g id="mweb_icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="home-button" stroke={props.color} cx="73" cy="278" r="10" />
            <rect
                id="ipgone-screen"
                stroke={props.color}
                x="9.5"
                y="33.5"
                width="127"
                height="227"
            />
            <rect
                id="mircophone"
                fill="#535353"
                fillRule="nonzero"
                x="63"
                y="16"
                width="19"
                height="3"
                rx="1"
            />
            <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
            <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
            <rect
                id="iphone-body"
                stroke={props.color}
                x="0.5"
                y="0.5"
                width="145"
                height="296"
                rx="16"
            />
            <rect
                id="box"
                fill={props.color}
                fillRule="nonzero"
                x="10"
                y="34"
                width="126"
                height="16"
            />
            <rect
                id="box-copy-5"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="57"
                width="113"
                height="37"
            />
            <rect
                id="box-copy-6"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="101"
                width="40"
                height="30"
            />
            <rect
                id="box-copy-8"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="101"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-9"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="110"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-10"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="119"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-11"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="128"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-12"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="138"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-13"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="147"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-14"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="156"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-15"
                fill={props.color}
                fillRule="nonzero"
                x="62"
                y="165"
                width="67"
                height="3"
            />
            <rect
                id="box-copy-16"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="176"
                width="113"
                height="8"
            />
            <rect
                id="box-copy-17"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="188"
                width="113"
                height="8"
            />
            <rect
                id="box-copy-18"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="201"
                width="113"
                height="8"
            />
            <rect
                id="box-copy-19"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="213"
                width="113"
                height="8"
            />
            <rect
                id="box-copy-20"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="226"
                width="113"
                height="8"
            />
            <rect
                id="box-copy-21"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="238"
                width="113"
                height="8"
            />
            <rect
                id="box-copy-7"
                fill={props.color}
                fillRule="nonzero"
                x="16"
                y="138"
                width="40"
                height="30"
            />
            <rect
                id="box-copy-4"
                fill="#FFFFFF"
                fillRule="nonzero"
                x="13"
                y="38"
                width="109"
                height="8"
                rx="4"
            />
        </g>
    </svg>
);

const IphoneDesktopIcon = props => (
    <svg fontSize="large" width={DESKTOP_WIDTH} height={DESKTOP_HEIGHT} viewBox="0 0 400 297">
        <g id="desktop_icon" stroke="none" strokeWidth="" fill="none" fillRule="evenodd">
            <rect
                id="Rectangle-4-Copy-2"
                fill={props.color}
                x="28"
                y="55"
                width="333"
                height="97"
            />
            <rect
                id="Rectangle-4-Copy-3"
                fill={props.color}
                x="28"
                y="20"
                width="333"
                height="25"
            />
            <rect
                id="Rectangle-2"
                stroke={props.color}
                x="0.5"
                y="0.5"
                width="391"
                height="296"
                rx="5"
            />
            <g id="Group" transform="translate(30.000000, 165.000000)" fill={props.color}>
                <rect id="Rectangle-3-Copy-2" x="0" y="0" width="103" height="51" />
                <rect id="Rectangle-3-Copy-4" x="0" y="63" width="61" height="5" />
                <rect id="Rectangle-3-Copy-5" x="0" y="75" width="88" height="2" />
                <rect id="Rectangle-3-Copy-11" x="0" y="84" width="96" height="5" />
                <rect id="Rectangle-3-Copy-7" x="0" y="95" width="88" height="2" />
            </g>
            <g id="Group-3" transform="translate(260.000000, 165.000000)" fill={props.color}>
                <rect id="Rectangle-3-Copy-12" x="0" y="63" width="61" height="5" />
                <rect id="Rectangle-3-Copy-13" x="0" y="75" width="88" height="2" />
                <rect id="Rectangle-3-Copy-15" x="0" y="84" width="96" height="5" />
                <rect id="Rectangle-3-Copy-14" x="0" y="95" width="88" height="2" />
                <rect id="Rectangle-3-Copy-3" x="0" y="0" width="103" height="51" />
            </g>
            <g id="Group-2" transform="translate(145.000000, 165.000000)" fill={props.color}>
                <rect id="Rectangle-3-Copy" x="0" y="0" width="103" height="51" />
                <rect id="Rectangle-3-Copy-8" x="0" y="63" width="61" height="5" />
                <rect id="Rectangle-3-Copy-9" x="0" y="75" width="88" height="2" />
                <rect id="Rectangle-3-Copy-6" x="0" y="84" width="96" height="5" />
                <rect id="Rectangle-3-Copy-10" x="0" y="95" width="88" height="2" />
            </g>
        </g>
    </svg>
);

function IphoneBannerIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g id="banner" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="banner"
                    fill={props.color}
                    fillRule="nonzero"
                    x="10"
                    y="220"
                    width="126"
                    height="40"
                />
            </g>
        </svg>
    );
}

function IphoneBoxIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g id="box" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect id="mircophone" fill="#535353" x="63" y="16" width="19" height="3" rx="1" />
                <circle id="camera" fill="#535353" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect id="box" fill={props.color} x="27" y="102" width="90" height="90" />
            </g>
        </svg>
    );
}

function IphoneInterstitialIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g id="interstitial" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="banner"
                    fill={props.color}
                    fillRule="nonzero"
                    x="10"
                    y="34"
                    width="126"
                    height="226"
                />
            </g>
        </svg>
    );
}

function IphoneExpandableIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g id="expandable" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                    id="expanded-banner"
                    fill={props.color}
                    x="10"
                    y="74"
                    width="126"
                    height="187"
                />
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect id="mircophone" fill="#535353" x="63" y="16" width="19" height="3" rx="1" />
                <circle id="camera" fill="#535353" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect id="banner" fill="#D8D8D8" x="10" y="34" width="126" height="40" />
            </g>
        </svg>
    );
}

function IphoneVideoIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g id="video" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="banner"
                    fill={props.color}
                    fillRule="nonzero"
                    x="10"
                    y="107"
                    width="126"
                    height="80"
                />
                <polygon id="Triangle" fill="#FFFFFF" points="85 149 67 159 67 139" />
                <circle id="Oval" stroke="#FFFFFF" cx="73.5" cy="148.5" r="21.5" />
            </g>
        </svg>
    );
}

function IphoneInFeedIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g id="in-feed" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="in-feed-7"
                    fill={props.color}
                    fillRule="nonzero"
                    x="16"
                    y="133"
                    width="114"
                    height="52"
                />
                <rect
                    id="in-feed-7-copy"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="10"
                    y="34"
                    width="126"
                    height="32"
                />
                <rect
                    id="in-feed-7-copy-2"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="199"
                    width="114"
                    height="5"
                />
                <rect
                    id="in-feed-7-copy-3"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="209"
                    width="114"
                    height="5"
                />
                <rect
                    id="in-feed-7-copy-4"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="220"
                    width="114"
                    height="5"
                />
                <rect
                    id="in-feed-7-copy-5"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="230"
                    width="114"
                    height="30"
                />
                <rect
                    id="in-feed-6"
                    fill={props.color}
                    fillRule="nonzero"
                    x="16"
                    y="124"
                    width="114"
                    height="5"
                />
                <rect
                    id="in-feed-5"
                    fill={props.color}
                    fillRule="nonzero"
                    x="16"
                    y="115"
                    width="114"
                    height="5"
                />
                <rect
                    id="in-feed-4"
                    fill={props.color}
                    fillRule="nonzero"
                    x="55"
                    y="95"
                    width="44"
                    height="10"
                />
                <rect
                    id="in-feed-3"
                    fill={props.color}
                    fillRule="nonzero"
                    x="55"
                    y="86"
                    width="32"
                    height="3"
                />
                <rect
                    id="in-feed-2"
                    fill={props.color}
                    fillRule="nonzero"
                    x="55"
                    y="81"
                    width="32"
                    height="3"
                />
                <rect
                    id="in-feed-1"
                    fill={props.color}
                    fillRule="nonzero"
                    x="16"
                    y="79"
                    width="32"
                    height="32"
                />
            </g>
        </svg>
    );
}

function IphoneVideoInterstitialIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g
                id="placement-interstitial"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="banner"
                    fill={props.color}
                    fillRule="nonzero"
                    x="10"
                    y="34"
                    width="126"
                    height="226"
                />
                <polygon id="Triangle" fill="#FFFFFF" points="84 148 66 158 66 138" />
                <circle id="Oval" stroke="#FFFFFF" cx="72.5" cy="147.5" r="21.5" />
            </g>
        </svg>
    );
}

function IphoneVideoInStreamIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g
                id="placement-in-stream"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="banner"
                    fill={props.color}
                    fillRule="nonzero"
                    x="10"
                    y="107"
                    width="126"
                    height="80"
                />
                <rect
                    id="banner-copy"
                    fill="#E4E4E4"
                    fillRule="nonzero"
                    x="10"
                    y="187"
                    width="126"
                    height="5"
                />
                <rect
                    id="banner-copy-2"
                    fill="#8F8F8F"
                    fillRule="nonzero"
                    x="40"
                    y="187"
                    width="19"
                    height="5"
                />
                <polygon id="Triangle" fill="#FFFFFF" points="85 149 67 159 67 139" />
                <circle id="Oval" stroke="#FFFFFF" cx="73.5" cy="148.5" r="21.5" />
                <rect id="Rectangle" fill="#3B3B3B" x="38" y="183" width="5" height="9" rx="1" />
            </g>
        </svg>
    );
}

function IphoneVideoOutStreamIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g
                id="placement-out-stream"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="in-feed-7"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="34"
                    width="114"
                    height="52"
                />
                <rect
                    id="in-feed-6"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="255"
                    width="114"
                    height="5"
                />
                <rect
                    id="in-feed-5"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="246"
                    width="114"
                    height="5"
                />
                <rect
                    id="in-feed-4"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="55"
                    y="226"
                    width="44"
                    height="10"
                />
                <rect
                    id="in-feed-3"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="55"
                    y="217"
                    width="32"
                    height="3"
                />
                <rect
                    id="in-feed-2"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="55"
                    y="212"
                    width="32"
                    height="3"
                />
                <rect
                    id="in-feed-1"
                    fill="#D8D8D8"
                    fillRule="nonzero"
                    x="16"
                    y="210"
                    width="32"
                    height="32"
                />
                <rect
                    id="banner"
                    fill={props.color}
                    fillRule="nonzero"
                    x="10"
                    y="109"
                    width="126"
                    height="80"
                />
                <polygon id="Triangle" fill="#FFFFFF" points="85 151 67 161 67 141" />
                <circle id="Oval" stroke="#FFFFFF" cx="73.5" cy="150.5" r="21.5" />
            </g>
        </svg>
    );
}

function IphoneVideoUnknownIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={IPHONE_WIDTH}
            height={IPHONE_HEIGHT}
            viewBox="0 0 146 297"
            classes={classes.root}
        >
            <g id="placement-unknown" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="home-button" stroke="#979797" cx="73" cy="278" r="10" />
                <rect
                    id="ipgone-screen"
                    stroke="#979797"
                    x="9.5"
                    y="33.5"
                    width="127"
                    height="227"
                />
                <rect
                    id="mircophone"
                    fill="#535353"
                    fillRule="nonzero"
                    x="63"
                    y="16"
                    width="19"
                    height="3"
                    rx="1"
                />
                <circle id="camera" fill="#535353" fillRule="nonzero" cx="53.5" cy="17.5" r="2.5" />
                <circle id="top-circle" fill="#535353" fillRule="nonzero" cx="74" cy="9" r="2" />
                <rect
                    id="iphone-body"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="145"
                    height="296"
                    rx="16"
                />
                <rect
                    id="box"
                    fill={props.color}
                    fillRule="nonzero"
                    x="27"
                    y="102"
                    width="90"
                    height="90"
                />
                <text
                    id="?"
                    fontFamily="Avenir-Light, Avenir"
                    fontSize="48"
                    fontWeight="300"
                    fill="#FFFFFF"
                >
                    <tspan x="60" y="163">
                        ?
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function DesktopBannerIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={DESKTOP_WIDTH}
            height={DESKTOP_HEIGHT}
            viewBox="0 0 400 297"
            classes={classes.root}
        >
            <g id="desktop_banner" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                    id="Rectangle-4-Copy-2"
                    fill="#D8D8D8"
                    x="28"
                    y="55"
                    width="333"
                    height="97"
                />
                <rect
                    id="Rectangle-4-Copy-3"
                    fill={props.color}
                    x="28"
                    y="20"
                    width="333"
                    height="25"
                />
                <rect
                    id="Rectangle-2"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="391"
                    height="296"
                    rx="5"
                />
                <g id="Group" transform="translate(30.000000, 165.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-2" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-4" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-5" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-11" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-7" x="0" y="95" width="88" height="2" />
                </g>
                <g id="Group-3" transform="translate(260.000000, 165.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-12" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-13" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-15" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-14" x="0" y="95" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-3" x="0" y="0" width="103" height="51" />
                </g>
                <g id="Group-2" transform="translate(145.000000, 165.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-8" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-9" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-6" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-10" x="0" y="95" width="88" height="2" />
                </g>
            </g>
        </svg>
    );
}

function DesktopBoxIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={DESKTOP_WIDTH}
            height={DESKTOP_HEIGHT}
            viewBox="0 0 400 297"
            classes={classes.root}
        >
            <g id="desktop_box" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                    id="Rectangle-4-Copy-2"
                    fill="#D8D8D8"
                    x="28"
                    y="27"
                    width="218"
                    height="97"
                />
                <rect
                    id="Rectangle-4-Copy-3"
                    fill="#D8D8D8"
                    x="28"
                    y="249"
                    width="333"
                    height="25"
                />
                <rect
                    id="Rectangle-2"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="391"
                    height="296"
                    rx="5"
                />
                <g id="Group" transform="translate(28.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-2" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-4" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-5" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-11" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-7" x="0" y="95" width="88" height="2" />
                </g>
                <g id="Group-3" transform="translate(258.000000, 27.000000)" fill={props.color}>
                    <rect id="Rectangle-3-Copy-3" x="0" y="0" width="103" height="97" />
                </g>
                <g id="Group-2" transform="translate(143.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-8" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-9" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-6" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-10" x="0" y="95" width="88" height="2" />
                </g>
                <g id="Group-4" transform="translate(258.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-8" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-9" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-6" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-10" x="0" y="95" width="88" height="2" />
                </g>
            </g>
        </svg>
    );
}

function DesktopVerticalBannerIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={DESKTOP_WIDTH}
            height={DESKTOP_HEIGHT}
            viewBox="0 0 400 297"
            classes={classes.root}
        >
            <g
                id="desktop_vertical_banner"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <rect
                    id="Rectangle-4-Copy-2"
                    fill="#D8D8D8"
                    x="28"
                    y="27"
                    width="268"
                    height="97"
                />
                <rect
                    id="Rectangle-4-Copy-4"
                    fill="#D8D8D8"
                    x="28"
                    y="249"
                    width="268"
                    height="24"
                />
                <rect
                    id="Rectangle-4-Copy-3"
                    fill={props.color}
                    transform="translate(334.138522, 149.722114) rotate(-90.000000) translate(-334.138522, -149.722114) "
                    x="210.860636"
                    y="118.583592"
                    width="246.555772"
                    height="62.2770449"
                />
                <rect
                    id="Rectangle-2"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="391"
                    height="296"
                    rx="5"
                />
                <g id="Group" transform="translate(28.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-2" x="0" y="0" width="130" height="51" />
                    <rect id="Rectangle-3-Copy-4" x="0" y="63" width="77" height="5" />
                    <rect id="Rectangle-3-Copy-5" x="0" y="75" width="111" height="2" />
                    <rect id="Rectangle-3-Copy-11" x="0" y="84" width="121" height="5" />
                    <rect id="Rectangle-3-Copy-7" x="0" y="95" width="111" height="2" />
                </g>
                <g id="Group-Copy" transform="translate(166.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-2" x="0" y="0" width="130" height="51" />
                    <rect id="Rectangle-3-Copy-4" x="0" y="63" width="77" height="5" />
                    <rect id="Rectangle-3-Copy-5" x="0" y="75" width="111" height="2" />
                    <rect id="Rectangle-3-Copy-11" x="0" y="84" width="121" height="5" />
                    <rect id="Rectangle-3-Copy-7" x="0" y="95" width="111" height="2" />
                </g>
            </g>
        </svg>
    );
}

function DesktopOutStreamIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={DESKTOP_WIDTH}
            height={DESKTOP_HEIGHT}
            viewBox="0 0 400 297"
            classes={classes.root}
        >
            <g id="desktop_video" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                    id="Rectangle-4-Copy-2"
                    fill="#D8D8D8"
                    x="28"
                    y="27"
                    width="333"
                    height="97"
                />
                <rect
                    id="Rectangle-4-Copy-3"
                    fill="#D8D8D8"
                    x="28"
                    y="249"
                    width="333"
                    height="25"
                />
                <rect
                    id="Rectangle-2"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="391"
                    height="296"
                    rx="5"
                />
                <g id="Group" transform="translate(28.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-2" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-4" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-5" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-11" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-7" x="0" y="95" width="88" height="2" />
                </g>
                <g id="Group-3" transform="translate(258.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-12" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-13" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-15" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-14" x="0" y="95" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-3" x="0" y="0" width="103" height="51" />
                </g>
                <g id="Group-2" transform="translate(143.000000, 138.000000)">
                    <rect
                        id="Rectangle-3-Copy"
                        fill={props.color}
                        x="0"
                        y="0"
                        width="103"
                        height="51"
                    />
                    <rect
                        id="Rectangle-3-Copy-8"
                        fill="#D8D8D8"
                        x="0"
                        y="63"
                        width="61"
                        height="5"
                    />
                    <rect
                        id="Rectangle-3-Copy-9"
                        fill="#D8D8D8"
                        x="0"
                        y="75"
                        width="88"
                        height="2"
                    />
                    <rect
                        id="Rectangle-3-Copy-6"
                        fill="#D8D8D8"
                        x="0"
                        y="84"
                        width="96"
                        height="5"
                    />
                    <rect
                        id="Rectangle-3-Copy-10"
                        fill="#D8D8D8"
                        x="0"
                        y="95"
                        width="88"
                        height="2"
                    />
                    <polygon id="Triangle" fill="#FFFFFF" points="56 26.5 49 31 49 22" />
                    <circle id="Oval" stroke="#FFFFFF" cx="52" cy="26" r="9" />
                </g>
            </g>
        </svg>
    );
}

function DesktopInFeedIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={DESKTOP_WIDTH}
            height={DESKTOP_HEIGHT}
            viewBox="0 0 400 297"
            classes={classes.root}
        >
            <g id="desktop_in_feed" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                    id="Rectangle-4-Copy-2"
                    fill="#D8D8D8"
                    x="28"
                    y="27"
                    width="333"
                    height="97"
                />
                <rect
                    id="Rectangle-4-Copy-3"
                    fill="#D8D8D8"
                    x="28"
                    y="249"
                    width="333"
                    height="25"
                />
                <rect
                    id="Rectangle-2"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="391"
                    height="296"
                    rx="5"
                />
                <g id="Group" transform="translate(28.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy-2" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-4" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-5" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-11" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-7" x="0" y="95" width="88" height="2" />
                </g>
                <g id="Group-3" transform="translate(258.000000, 138.000000)" fill={props.color}>
                    <rect id="Rectangle-3-Copy-12" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-13" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-15" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-14" x="0" y="95" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-3" x="0" y="0" width="103" height="51" />
                </g>
                <g id="Group-2" transform="translate(143.000000, 138.000000)" fill="#D8D8D8">
                    <rect id="Rectangle-3-Copy" x="0" y="0" width="103" height="51" />
                    <rect id="Rectangle-3-Copy-8" x="0" y="63" width="61" height="5" />
                    <rect id="Rectangle-3-Copy-9" x="0" y="75" width="88" height="2" />
                    <rect id="Rectangle-3-Copy-6" x="0" y="84" width="96" height="5" />
                    <rect id="Rectangle-3-Copy-10" x="0" y="95" width="88" height="2" />
                </g>
            </g>
        </svg>
    );
}

function DesktopInStreamIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={DESKTOP_WIDTH}
            height={DESKTOP_HEIGHT}
            viewBox="0 0 400 297"
            classes={classes.root}
        >
            <g id="desktop_in_stream" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                    id="Rectangle-4-Copy-2"
                    fill="#D8D8D8"
                    x="28"
                    y="176"
                    width="337"
                    height="6"
                />
                <rect
                    id="Rectangle-4-Copy-5"
                    fill="#D8D8D8"
                    x="28"
                    y="188"
                    width="282"
                    height="6"
                />
                <rect
                    id="Rectangle-4-Copy-6"
                    fill="#D8D8D8"
                    x="28"
                    y="200"
                    width="282"
                    height="6"
                />
                <rect
                    id="Rectangle-4-Copy-7"
                    fill="#D8D8D8"
                    x="28"
                    y="219"
                    width="337"
                    height="55"
                />
                <rect
                    id="Rectangle-4-Copy-8"
                    fill="#D8D8D8"
                    x="95"
                    y="17"
                    width="270"
                    height="27"
                />
                <rect
                    id="Rectangle-4-Copy-14"
                    fill="#D8D8D8"
                    x="28"
                    y="17"
                    width="60"
                    height="27"
                />
                <rect
                    id="Rectangle-4-Copy-15"
                    fill="#D8D8D8"
                    x="254"
                    y="109"
                    width="52"
                    height="55"
                />
                <rect
                    id="Rectangle-4-Copy-16"
                    fill="#D8D8D8"
                    x="313"
                    y="109"
                    width="52"
                    height="55"
                />
                <rect
                    id="Rectangle-4-Copy-9"
                    fill="#D8D8D8"
                    x="254"
                    y="98"
                    width="111"
                    height="3"
                />
                <rect
                    id="Rectangle-4-Copy-10"
                    fill="#D8D8D8"
                    x="254"
                    y="55"
                    width="111"
                    height="6"
                />
                <rect
                    id="Rectangle-4-Copy-11"
                    fill="#D8D8D8"
                    x="254"
                    y="65"
                    width="52"
                    height="3"
                />
                <rect
                    id="Rectangle-4-Copy-12"
                    fill="#D8D8D8"
                    x="254"
                    y="74"
                    width="85"
                    height="6"
                />
                <rect
                    id="Rectangle-4-Copy-13"
                    fill="#D8D8D8"
                    x="254"
                    y="86"
                    width="85"
                    height="6"
                />
                <rect
                    id="Rectangle-2"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="391"
                    height="296"
                    rx="5"
                />
                <rect
                    id="Rectangle-3-Copy"
                    fill={props.color}
                    x="28"
                    y="55"
                    width="218"
                    height="110"
                />
                <polygon id="Triangle" fill="#FFFFFF" points="147 112.5 132 122 132 103" />
                <ellipse id="Oval" stroke="#FFFFFF" cx="138" cy="111.5" rx="19" ry="19.5" />
                <rect
                    id="banner-copy"
                    fill="#E4E4E4"
                    fillRule="nonzero"
                    x="28"
                    y="165"
                    width="218"
                    height="5"
                />
                <rect
                    id="banner-copy-2"
                    fill="#8F8F8F"
                    fillRule="nonzero"
                    x="80"
                    y="165"
                    width="33"
                    height="5"
                />
                <rect id="Rectangle" fill="#3B3B3B" x="76" y="161" width="4" height="9" rx="1" />
            </g>
        </svg>
    );
}

function DesktopVideoUnknownIcon(props) {
    const classes = useStyles();

    return (
        <svg
            fontSize="inherit"
            width={DESKTOP_WIDTH}
            height={DESKTOP_HEIGHT}
            viewBox="0 0 400 297"
            classes={classes.root}
        >
            <g
                id="desktop_video_unknown"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <rect id="Rectangle-4" fill="#D8D8D8" x="50" y="15" width="79" height="6" />
                <rect id="Rectangle-4-Copy" fill="#D8D8D8" x="50" y="28" width="40" height="6" />
                <rect id="Rectangle-4-Copy-2" fill="#D8D8D8" x="15" y="49" width="207" height="6" />
                <rect
                    id="Rectangle-2"
                    stroke="#979797"
                    x="0.5"
                    y="0.5"
                    width="391"
                    height="296"
                    rx="5"
                />
                <rect id="Rectangle-3" fill="#B4B4B4" x="15" y="13" width="27" height="28" />
                <rect
                    id="Rectangle-3-Copy"
                    fill={props.color}
                    x="15"
                    y="70"
                    width="362"
                    height="209"
                />
                <text
                    id="?"
                    fontFamily="Avenir-Light, Avenir"
                    fontSize="72"
                    fontWeight="300"
                    fill="#FFFFFF"
                >
                    <tspan x="179" y="191">
                        ?
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

function ConnectedTVIcon() {
    const classes = useStyles();

    return <img src="/images/ConnectedTV.png" className={classes.root} />;
}

function DOOHIcon() {
    const classes = useStyles();

    return <img src="/images/DOOHAd.png" className={classes.dooh} />;
}

function DOOHIconVideo() {
    const classes = useStyles();

    return <img src="/images/DOOHVideo.png" className={classes.dooh} />;
}

function DOOHIconDisplay() {
    const classes = useStyles();

    return <img src="/images/DOOHDisplay.png" className={classes.dooh} />;
}

function CreativeIcon(props) {
    switch (props.optionType) {
        case 'inapp':
            return <IphoneInAppIcon color={props.color ? props.color : null} />;
            break;
        case 'mweb':
            return <IphoneMWebIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop':
            return <IphoneDesktopIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_banner':
            return <IphoneBannerIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_box':
            return <IphoneBoxIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_interstitial':
            return <IphoneInterstitialIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_expandable':
            return <IphoneExpandableIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_video':
            return <IphoneVideoIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_native':
            return <IphoneInFeedIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_video_interstitial':
            return <IphoneVideoInterstitialIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_video_in_stream':
            return <IphoneVideoInStreamIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_video_out_stream':
            return <IphoneVideoOutStreamIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_video_unknown':
            return <IphoneVideoUnknownIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_banner':
            return <DesktopBannerIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_box':
            return <DesktopBoxIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_vertical_banner':
            return <DesktopVerticalBannerIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_video':
            return <DesktopInStreamIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_video_in_stream':
            return <DesktopInStreamIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_video_out_stream':
            return <DesktopOutStreamIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_video_unknown':
            return <DesktopVideoUnknownIcon color={props.color ? props.color : null} />;
            break;
        case 'desktop_native':
            return <DesktopInFeedIcon color={props.color ? props.color : null} />;
            break;
        case 'inapp_vertical':
            return <IphoneInterstitialIcon color={props.color ? props.color : null} />;
            break;
        case 'connected_tv':
            return <ConnectedTVIcon color={props.color ? props.color : null} />;
            break;
        case 'digital_out_of_home':
            return <DOOHIcon color={props.color ? props.color : null} />;
            break;
        case 'dooh_video':
            return <DOOHIconVideo color={props.color ? props.color : null} />;
            break;
        case 'dooh_banner':
            return <DOOHIconDisplay color={props.color ? props.color : null} />;
            break;
        default:
            return <IphoneDefaultIcon color={props.color ? props.color : null} />;
    }
}

export default CreativeIcon;

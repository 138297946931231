import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router';
import VirtualList from 'react-tiny-virtual-list';

class Menu extends React.Component {
    static displayName = 'Menu';

    static propTypes = {
        items: PropTypes.node,
    };

    scrollToTop = () => {
        if (this.menu) {
            this.menu.scrollTop = 0;
        }
    };

    setMenu = el => {
        this.menu = el;
    };

    render() {
        return (
            <div ref={this.setMenu} className={cn(this.props.className, 'menu')}>
                {this.props.children}
            </div>
        );
    }
}

export function MenuItem(props) {
    const { children, expandable, onClick, disabled, className, style, ...rest } = props;
    return (
        <div
            style={style}
            onClick={onClick}
            className={cn(className, {
                menu__item: true,
                'menu__item--expandable': expandable,
                'menu__item--disabled': disabled,
            })}
            {...rest}
        >
            {children}
        </div>
    );
}

MenuItem.defaultProps = {
    padding: 10,
};

export function MenuSeparator(props) {
    const { ...rest } = props;
    return <div className="menu__separator" {...rest} />;
}

export class VirtualMenu extends React.Component {
    static defaultProps = {
        onScroll() {},
    };
    state = {
        scrollToIndex: null,
    };

    static defaultProps = {
        onScroll: function() {},
    };

    scrollToTop() {
        this.setState(
            {
                scrollToIndex: 0,
            },
            () => {
                this.setState({
                    scrollToIndex: null,
                });
            }
        );
    }
    render() {
        const { itemSize, itemSizeLarge, items, maxHeight } = this.props;

        let height = items.length * itemSize;
        if (height > maxHeight) {
            height = maxHeight;
        }

        const overscanCount = parseInt(height / itemSize / 2, 10);

        return (
            <VirtualList
                className={cn('menu', this.props.className)}
                width="100%"
                height={height}
                onScroll={this.props.onScroll}
                overscanCount={overscanCount}
                itemCount={items.length}
                scrollToIndex={items.length > 0 ? this.state.scrollToIndex : null}
                itemSize={index => {
                    const item = items[index];
                    if (item.menuSeparator) {
                        return 1;
                    } else {
                        let itemSizeAdjusted = item.label.length > 42 ? itemSizeLarge : itemSize;
                        return itemSizeAdjusted;
                    }
                }}
                renderItem={({ index, style }) => this.props.children(items[index], style)}
            />
        );
    }
}
VirtualMenu.defaultProps = {
    maxHeight: 250,
    itemSize: 54,
    itemSizeLarge: 72,
};

export default Menu;

import _ from 'lodash';
import React from 'react';
import flags from 'containers/flags/service';

import FormField from 'widgets-v5/form-field';
import MultipleSelector from 'widgets-v5/multiple-selector';
import { MultiSelect } from 'widgets-v6/select';
import Spacer from 'widgets-v5/spacer';

import dict_carrierTargeting from 'common/constants/carrier-targeting';
const _getCarrierTargeting = () => {
    const carrierTargeting = [];
    _(dict_carrierTargeting).each((item, key) => {
        carrierTargeting.push({
            label: item,
            value: key,
        });
    });
    return _(carrierTargeting)
        .sortBy('label')
        .value();
};

class TargetCarrieries extends React.Component {
    static defaultProps = {
        label: 'Carrier Networks',
    };

    getValueForForm = () => {
        const { value } = this.props;
        return value;
    };

    handleFormChange = value => {
        this.props.onChange(value);
    };

    render() {
        const value = this.getValueForForm();
        return (
            <div className="ef5-ad-setup__target-carriers ef5-ad-setup__form-field-flex">
                <FormField
                    label={this.props.label}
                    description=""
                    errors={this.props.errors}
                    showErrors={this.props.showErrors}
                    isRequired={false}
                >
                    <MultiSelect
                        value={value}
                        options={_getCarrierTargeting()}
                        onChange={this.handleFormChange}
                        placeholder={'All carrier networks'}
                    />
                </FormField>
                <Spacer type="small" />
            </div>
        );
    }
}

export default TargetCarrieries;

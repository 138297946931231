import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'adsOverviewV2';
export const initialState = {
    campaign: null,
    isInitialized: false,
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        init: (state, campaignId) => ({
            ...initialState,
            campaignId,
        }),
        initSuccess: (state, { campaign, deals }) => ({
            ...state,
            campaign,
            deals,
            isInitialized: true,
        }),
        unInit: (state) => ({
            ...state,
            isInitialized: false,
        }),
        refreshSuccess: (state, campaign) => ({
            ...state,
            campaign,
        }),
    },
});

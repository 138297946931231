import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import flags from 'containers/flags/service';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import actions from './actions';
import selector from './selector';
import { isInternalUser } from 'states/profile/business-rules';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

import { IAS_FRAUD_PREVENTION_RISK_OPTIONS } from 'common/constants/ias-fraud-prevention';

function IASFraudPrevention({
    iasFraudPreventionRisk,
    iasFraudPreventionUnrateable,
    iasFraudPreventionIp,
    iasFraudPreventionIpDisabled,
    handleIASFraudPreventionRiskChange,
    handleIASFraudPreventionUnrateableChange,
    handleIASFraudPreventionIpChange,
    showSetupWarning,
    isCrossPlatformCampaign,
    ipWarning,
}) {
    return (
        <React.Fragment>
            <FormLabel component="legend">Content-based</FormLabel>
            <Select value={iasFraudPreventionRisk} onChange={handleIASFraudPreventionRiskChange}>
                {_.map(IAS_FRAUD_PREVENTION_RISK_OPTIONS, option => (
                    <MenuItem value={option.value} key={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={iasFraudPreventionUnrateable === 1}
                            onChange={handleIASFraudPreventionUnrateableChange}
                        />
                    }
                    label="Exclude unrated content"
                />
            </FormGroup>
            <FormLabel component="legend">IP Address-based</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={
                                isCrossPlatformCampaign ? false : iasFraudPreventionIpDisabled
                            }
                            checked={iasFraudPreventionIp === 1}
                            onChange={handleIASFraudPreventionIpChange}
                        />
                    }
                    label="Exclude fraudulent IP addresses (desktop and mobile web only)"
                />
                {ipWarning.hasWarning && isCrossPlatformCampaign && (
                    <WarningText message={ipWarning.message} severity={ipWarning.severity} />
                )}
            </FormGroup>
        </React.Fragment>
    );
}

function mapDispatch(dispatch) {
    return {
        handleIASFraudPreventionRiskChange: event => {
            const mode = event.target.value;

            dispatch(actions.changeIASFraudPreventionRisk(mode));
        },
        handleIASFraudPreventionUnrateableChange: event => {
            const checked = event.target.checked;

            dispatch(actions.changeIASFraudPreventionUnrateable(checked ? 1 : 0));
        },
        handleIASFraudPreventionIpChange: event => {
            const checked = event.target.checked;

            dispatch(actions.changeIASFraudPreventionIp(checked ? 1 : 0));
        },
    };
}

export default connect(
    selector,
    mapDispatch
)(IASFraudPrevention);

import _ from 'lodash';
import toastr from 'toastr';
import c from 'common/constants/flux-events';
import CampaignActions from 'states/resources/campaigns/actions';
import { createHttp } from 'utils/http';

const useGraphQLToGetClientOrgsAndUsersByIds = campaignClientOrganizationIds => {
    const http = createHttp();
    const query = `
        query getOrganizationsByIds ($ids: [String]) {
            organizations(filters: {ids: $ids}){
                name
                id
                client_type
                users {
                  email
                  id
                }
              }
         }
    `;
    const variables = {
        ids: campaignClientOrganizationIds,
    };

    return http.graphql(query, variables);
};

export function initialize(campaignId) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_SHARING__INIT,
            payload: {},
        });

        dispatch(CampaignActions.get(campaignId)).then(async campaign => {
            const formattedCampaign = { id: campaign.id, attr: campaign };
            let organizations = {};
            let formattedUsers = {};

            const campaignClientOrganizationIds = _.map(
                campaign.clients,
                client => client.organization
            );

            if (campaignClientOrganizationIds.length > 0) {
                let clientOrgsAndUsers;
                try {
                    clientOrgsAndUsers = await useGraphQLToGetClientOrgsAndUsersByIds(
                        campaignClientOrganizationIds
                    );
                } catch (err) {
                    dispatch({
                        type: 'CAMPAIGN_SHARING__FETCH_CLIENT_ORGANIZATIONS_AND_USERS__FAIL',
                        payload: {
                            err,
                        },
                    });
                    return;
                }

                _.each(clientOrgsAndUsers.organizations, ({ users, id, ...rest }) => {
                    const organizationId = id;
                    organizations[organizationId] = {
                        id: organizationId,
                        attr: { id: organizationId, ...rest },
                    };
                    _.each(users, ({ id, ...rest }) => {
                        formattedUsers[id] = {
                            id,
                            attr: { id, ...rest, organization: organizationId },
                        };
                    });
                });
            }

            dispatch({
                type: c.CAMPAIGN_SHARING__INIT_SUCCESS,
                payload: {
                    campaign: formattedCampaign,
                    organizations,
                    users: formattedUsers,
                },
            });
        });
    };
}
export function destroy(campaignId) {
    return {
        type: c.CAMPAIGN_SHARING__DESTROY,
        payload: {
            campaignId,
        },
    };
}
export function openForm(campaignId) {
    return {
        type: c.CAMPAIGN_SHARING__OPEN,
        payload: {
            campaignId,
        },
    };
}
export function closeForm(campaignId) {
    return {
        type: c.CAMPAIGN_SHARING__CLOSE,
        payload: {
            campaignId,
        },
    };
}
export function toggleEmail(campaignId, user, organization) {
    return {
        type: c.CAMPAIGN_SHARING__TOGGLE_EMAIL,
        payload: {
            campaignId,
            user,
            organization,
        },
    };
}
export function toggleShare(campaignId, organization) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_SHARING__TOGGLE_SHARE,
            payload: {
                campaignId,
                organization,
            },
        });
    };
}
export function updateMetric(campaignId, metrics) {
    return {
        type: c.CAMPAIGN_SHARING__UPDATE_METRICS,
        payload: {
            campaignId,
            metrics,
        },
    };
}
export function updateDimension(campaignId, tab, dimensions) {
    return {
        type: c.CAMPAIGN_SHARING__UPDATE_DIMENSIONS,
        payload: {
            campaignId,
            tab,
            dimensions,
        },
    };
}
export function updateReportTab(campaignId, tab, checked) {
    return {
        type: c.CAMPAIGN_SHARING__UPDATE_REPORT_TAB,
        payload: {
            campaignId,
            tab,
            checked,
        },
    };
}
export function selectAllEmails(campaignId, organization) {
    return {
        type: c.CAMPAIGN_SHARING__EMAILS_SELECT_ALL,
        payload: {
            campaignId,
            organization,
        },
    };
}
export function selectNoneEmails(campaignId, organization) {
    return {
        type: c.CAMPAIGN_SHARING__EMAILS_SELECT_NONE,
        payload: {
            campaignId,
            organization,
        },
    };
}
export function expandCollapseToggle(campaignId, organization) {
    return {
        type: c.CAMPAIGN_SHARING__EXPAND_COLLAPSE_TOGGLE,
        payload: {
            campaignId,
            organization,
        },
    };
}
export function share(campaignId, organizationId) {
    return {
        type: c.CAMPAIGN_SHARING__SHARE,
        payload: {
            campaignId,
            organizationId,
        },
    };
}
export function submitForm(campaignId) {
    return (dispatch, getState) => {
        const sharing = _.get(getState(), `campaignSharing`);

        if (!sharing) {
            return;
        }

        let userIds = [];
        _.each(sharing.usersByOrg, users => {
            const usersIds = _(users)
                .filter(u => u.checked)
                .map(u => u.id)
                .value();

            userIds = userIds.concat(usersIds);
        });

        dispatch({
            type: c.CAMPAIGN_SHARING__SEND,
            payload: {
                campaignId,
            },
        });

        const clients = _.map(sharing.organizations, org => ({
            organization: org.id,
            shared: org.shared,
            type: org.type,
        }));

        const payload = {
            sharing_settings: {
                metrics: sharing.metrics,
                report_tabs: sharing.reportTabs,
            },
            clients,
        };

        dispatch(CampaignActions.update(campaignId, payload)).then(
            () => {
                dispatch({
                    type: c.CAMPAIGN_SHARING__SEND__SUCCESS,
                    payload: {
                        campaignId,
                    },
                });

                if (userIds.length === 0) {
                    return;
                }

                dispatch(CampaignActions.shareNotification(campaignId, userIds)).then(() => {
                    toastr.success('Notifications successfully sent');
                });
            },
            () => {
                dispatch({
                    type: c.CAMPAIGN_SHARING__SEND__FAIL,
                    payload: {
                        campaignId,
                    },
                });
            }
        );
    };
}

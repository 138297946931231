import React from 'react';
import { connect } from 'react-redux';
import FormControl from '@mui/material/FormControl';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';
import actions from './actions';
import selector from './selector';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { IAS_VIEWABILITY_OPTIONS } from 'common/constants/ias-viewability';

const useStyles = makeStyles({
    form: {
        margin: '10px 0px',
    },
    label: {
        fontSize: 18,
    },
});

function IASViewability({ iasViewability, handleIASViewabilityChange, warningData }) {
    const classes = useStyles();

    return (
        <FormControl className={classes.form}>
            <FormGroup row>
                <Select value={iasViewability} onChange={handleIASViewabilityChange}>
                    {_.map(IAS_VIEWABILITY_OPTIONS, option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <Tooltip
                    placement="right"
                    title={
                        <Typography variant="body2">
                            According to the IAB: a display ad is viewable if 50% of the ad is in
                            view for 1 continuous second. A video ad is viewable if 50% of the ad is
                            in view for 2 continuous seconds.
                        </Typography>
                    }
                >
                    <InfoIcon color="action" fontSize="small" />
                </Tooltip>
                {warningData.hasWarning && (
                    <WarningText
                        message={warningData.message}
                        title={warningData.title}
                        severity={warningData.severity}
                    />
                )}
            </FormGroup>
        </FormControl>
    );
}

function mapDispatch(dispatch) {
    return {
        handleIASViewabilityChange: event => {
            const mode = event.target.value;

            dispatch(actions.changeIASViewability(mode));
        },
    };
}

export default connect(
    selector,
    mapDispatch
)(IASViewability);

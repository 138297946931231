import React, { useEffect } from 'react';
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { connect } from 'react-redux';
import 'moment-timezone';

import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { useCampaignPageContainer } from '../../campaign/v2';
import flags from 'containers/flags/service';

import Tabs from 'widgets-v5/tabs';
import selector from './selector';
import actions from './actions';

const moment = extendMoment(Moment);

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ftaWarning: {
        marginTop: 50,
        padding: 50,
    },
});

function FootTrafficAttribution(props) {
    const {
        campaign,
        ftaLocationLists,
        campaignRelationship: { isOwner, isClient },
        ftaCampaignMetaData,
    } = props;

    const classes = useStyles();
    const { viewBy } = useCampaignPageContainer();

    useEffect(() => {
        if (
            campaign.flightPacingStrategy === 'campaign'
        ) {
            if (viewBy && viewBy !== 'campaign') {
                props.dispatch(actions.init(props.params.campaignId, viewBy));
            }
        } else {
            props.dispatch(actions.init(props.params.campaignId));
        }

        return () => {
            props.dispatch(actions.destroy(props.params.campaignId));
        };
    }, [viewBy]);

    const campaignId = _.get(props, 'params.campaignId');
    const isClientReportPage = () => /campaigns\/.*\/client-report/.test(window.location.pathname);
    const canSeeExploreTab = () => {
        const isExploreShared = _.get(
            campaign,
            'sharing_settings.report_tabs.explore.shared',
            false
        );

        if (isClientReportPage()) {
            if (isClient || isClientReportPage()) {
                return isExploreShared;
            }

            if (isOwner) {
                return true;
            }

            return false;
        } else {
            return isOwner || (isClient && isExploreShared);
        }
    };

    const canSeeGeoTab = () => {
        const isGeoShared = _.get(campaign, 'sharing_settings.report_tabs.geo.shared', false);
        const geoDidExistWhenCampaignCreated = moment(campaign._created).isSameOrAfter(
            '2017-03-09'
        ); // Mar 09 geo release v4.3.0

        if (isClientReportPage()) {
            if (isClient || isClientReportPage()) {
                return isGeoShared;
            }

            if (isOwner) {
                return true;
            }

            return false;
        } else {
            return geoDidExistWhenCampaignCreated && (isOwner || (isClient && isGeoShared));
        }
    };

    const canSeeChannelTab = () => {
        const isChannelShared = _.get(
            campaign,
            'sharing_settings.report_tabs.channel.shared',
            false
        );
        const channelDidExistWhenCampaignCreated = moment(campaign._created).isSameOrAfter(
            '2017-02-22'
        ); // Feb 22 channel release v4.2.0

        if (isClientReportPage()) {
            if (isClient || isClientReportPage()) {
                return isChannelShared;
            }

            if (isOwner) {
                return true;
            }

            return false;
        } else {
            return channelDidExistWhenCampaignCreated && (isOwner || (isClient && isChannelShared));
        }
    };

    const getCampaignFtaLocationList = () => {
        const campaignLocationListId = _.get(campaign, 'fta_location_list');
        const campaignFtaLocationList = _.find(ftaLocationLists, { id: campaignLocationListId });

        return campaignFtaLocationList;
    };

    const campaignFtaLocationList = getCampaignFtaLocationList();
    const ftaTabValue = 'foot-traffic-attribution';
    const reportPath = isClientReportPage() ? 'client-report' : 'report';

    const items = [
        {
            label: 'Explore',
            value: 'explore',
            link: `/campaigns/${campaignId}/${reportPath}/explore`,
            hidden: !canSeeExploreTab(),
        },
        {
            label: 'By Geo',
            value: 'geo',
            link: `/campaigns/${campaignId}/${reportPath}/geo`,
            hidden: !canSeeGeoTab(),
        },
        {
            label: 'By App',
            value: 'channel',
            link: `/campaigns/${campaignId}/${reportPath}/channel`,
            hidden: !canSeeChannelTab(),
        },
    ];

    if (campaign.fta_enabled) {
        items.push({
            label: 'Foot Traffic Attribution',
            value: 'foot-traffic-attribution',
            link: `/campaigns/${campaignId}/${reportPath}/foot-traffic-attribution`,
            hidden: false,
        });
    }

    let content = props.isInitialized ? (
        <div>
            {ftaCampaignMetaData.iframeUrl &&
            (ftaCampaignMetaData.statusCode === 0 || ftaCampaignMetaData.statusCode === 1) ? (
                <iframe
                    width="100%"
                    height="100%"
                    src={props.ftaCampaignMetaData.iframeUrl?.replace('fta-dev', 'fta')}
                />
            ) : (
                <div className={classes.root}>
                    <Paper className={classes.ftaWarning}>
                        Foot Traffic Attribution is Not Available.
                    </Paper>
                </div>
            )}
        </div>
    ) : (
        <LinearProgress />
    );

    if (flags.isEnabled('EN_3673_show_warning_when_ftaLocationList_status_is_processing')) {
        if (_.get(campaignFtaLocationList, 'status') !== 'READY') {
            content = (
                <div className={classes.root}>
                    <Paper className={classes.ftaWarning}>
                        Foot Traffic Attribution report is not available while the location list is
                        still being processed..
                    </Paper>
                </div>
            );
        } else {
            content = (
                <div className={classes.root}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={props.ftaCampaignMetaData.iframeUrl?.replace('fta-dev', 'fta')}
                    />
                </div>
            );
        }
    }

    if (
        campaign.flightPacingStrategy === 'campaign' &&
        viewBy === 'campaign'
    ) {
        content = (
            <div className={classes.root}>
                <Paper className={classes.ftaWarning}>
                    Select a specific flight to see Foot Traffic Attribution
                </Paper>
            </div>
        );
    }

    return (
        <div>
            <div className="ef5-reports__tabs-wrapper">
                <Tabs
                    align="left"
                    activeItem={ftaTabValue}
                    className="ef5-reports__tabs"
                    items={items}
                />
            </div>
            {content}
        </div>
    );
}

export default connect(selector)(FootTrafficAttribution);

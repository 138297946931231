import _ from 'lodash';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

import { IAS_VIEWABILITY_OPTIONS } from 'common/constants/ias-viewability';
import { IAS_GROUPM_VIEWABILITY_OPTIONS } from 'common/constants/ias-groupm-viewability';

import Box from '@mui/material/Box';
import { getIASPublicisGroupMWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

const useStyles = makeStyles({
    form: {
        margin: '10px 0px',
    },
    label: {
        fontSize: 18,
    },
});

const IAS_VIEWABILITY_OPTIONS_MAPPING = {};
_.each(IAS_VIEWABILITY_OPTIONS, option => {
    IAS_VIEWABILITY_OPTIONS_MAPPING[option.value] = option;
});

const GroupMViewability = props => {
    const classes = useStyles();
    const { ad, ads, draft, updateDraft } = props;

    const handleGroupMViewabilityChange = e => {
        const { value } = e.target;
        const changes = {
            ...draft.ias,
            groupmViewability: {
                ...draft.ias.publicisViewability,
                value,
            },
        };
        updateDraft({ ias: changes });
    };

    const groupMValue = _.get(draft, 'ias.groupmViewability.value');
    let onlyHasInappPlatform;
    let hasInappPlatform;

    const adPlatforms = ad
        ? _.get(ad, 'platforms')
        : _(ads)
              .map(ad => ad.platforms)
              .flatten()
              .uniq()
              .value();

    if (props.isCrossPlatformCampaign) {
        const hasOnlyInapp =
            !_.includes(adPlatforms, 'mweb') && !_.includes(adPlatforms, 'desktop');

        onlyHasInappPlatform = hasOnlyInapp;
        hasInappPlatform = _.includes(adPlatforms, 'inapp');
    } else {
        if (ad) {
            const platform = _.get(ad, 'platform');
            onlyHasInappPlatform = platform === 'inapp';
            hasInappPlatform = platform === 'inapp';
        } else if (ads && ads.length > 0) {
            onlyHasInappPlatform = _.some(ads, { platform: 'inapp' });
            hasInappPlatform = _.some(ads, { platform: 'inapp' });
        }
    }

    const helpMessage =
        onlyHasInappPlatform || hasInappPlatform
            ? 'Supports desktop and mobile web platforms only'
            : null;

    const disabled = props.isCrossPlatformCampaign ? false : onlyHasInappPlatform;

    let groupMWarning = {};

    if (props.isCrossPlatformCampaign) {
        groupMWarning = getIASPublicisGroupMWarning({
            platforms: adPlatforms,
            viewabilityValue: groupMValue,
            field: 'groupm',
        });
    }

    return (
        <div>
            <FormControl className={classes.form}>
                <FormLabel component="legend">GroupM Performance Matching</FormLabel>
                <Select
                    value={groupMValue}
                    onChange={handleGroupMViewabilityChange}
                    disabled={disabled}
                >
                    {_.map(IAS_GROUPM_VIEWABILITY_OPTIONS, option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {helpMessage && <FormHelperText>{helpMessage}</FormHelperText>}
                {props.isCrossPlatformCampaign && groupMWarning.hasWarning && (
                    <Box marginTop={1}>
                        <WarningText
                            message={groupMWarning.message}
                            title={groupMWarning.title}
                            severity={groupMWarning.severity}
                        />
                    </Box>
                )}
            </FormControl>
        </div>
    );
};

export default GroupMViewability;

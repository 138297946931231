import forEach from 'lodash/forEach';

const revoked = [];
// the below list is V2 with new intents and roles
// EF permissions table: https://docs.google.com/spreadsheets/d/1SKF4xjO3KYydKhsjPtoSOMAjyhm2TIXEoPYA3DW4Szs/edit#gid=0

export const PermissionsMapping = {
    ACCOUNT__VIEW: 'account__view',
    ADMIN__VIEW: 'admin__view',
    ADMIN_ORG__VIEW: 'admin_org__view',
    ADMIN_ORG__EDIT: 'admin_org__edit',
    ADMIN_USER__VIEW: 'admin_user__view',
    ADMIN_USER__EDIT: 'admin_user__edit',
    ADMIN_SUB_ORG__VIEW: 'admin_subOrg__view',
    ADMIN_SUB_ORG__EDIT: 'admin_subOrg__edit',
    ADMIN_SUB_ORG_USER__EDIT: 'admin_subOrgUser__edit',
    ADMIN__VIEW_AND_EDIT: 'admin__view_and_edit',
    ADPRESET__VIEW: 'adpreset__view',
    ADPRESET__EDIT: 'adpreset__edit',
    AUDIENCE__VIEW: 'audience__view',
    AUDIENCE__EDIT: 'audience__edit',
    AUDITS__VIEW_AND_EDIT: 'audits__view_and_edit',
    CAMPAIGN__VIEW: 'campaign__view',
    CAMPAIGN__VIEW_AND_EDIT: 'campaign__view_and_edit',
    DASHBOARD__VIEW: 'dashboard__view',
    FTA__VIEW: 'fta__view',
    FTA__EDIT: 'fta__edit',
    IMPERSONATE: 'impersonate',
    MEDIAPLAN__VIEW_AND_EDIT: 'mediaplan__view_and_edit',
    FEATURE_NOTIFICATION: 'feature_notification',
    OVERVIEW__VIEW: 'overview__view',
    PIXEL__VIEW: 'pixel__view',
    PIXEL__EDIT: 'pixel__edit',
    PROGRESS__VIEW: 'progress__view',
    REPORT__VIEW: 'report__view',
};

// prettier-ignore
const permissionsTable = [
    [ '',                                        'analyst', 'userAdmin', 'mediaPlanner', 'campaignManager', 'superAdmin', 'accountAdmin', 'readOnlySuperAdmin'],
    [PermissionsMapping.ACCOUNT__VIEW,               0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.ADMIN__VIEW,                 0,          1,              1,              1,               1,             1,              1],
    [PermissionsMapping.ADMIN_ORG__VIEW,             0,          1,              1,              1,               1,             1,              1],
    [PermissionsMapping.ADMIN_ORG__EDIT,             0,          0,              0,              1,               1,             1,              0],
    [PermissionsMapping.ADMIN_USER__VIEW,            0,          1,              0,              1,               1,             1,              1],
    [PermissionsMapping.ADMIN_USER__EDIT,            0,          1,              0,              0,               1,             1,              0],
    [PermissionsMapping.ADMIN_SUB_ORG__VIEW,         0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.ADMIN_SUB_ORG__EDIT,         0,          0,              0,              1,               1,             1,              0],
    [PermissionsMapping.ADMIN_SUB_ORG_USER__EDIT,    0,          0,              0,              1,               1,             1,              0],
    [PermissionsMapping.ADMIN__VIEW_AND_EDIT,        0,          0,              0,              1,               1,             0,              0],
    [PermissionsMapping.ADPRESET__VIEW,              0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.ADPRESET__EDIT,              0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.AUDIENCE__VIEW,              0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.AUDIENCE__EDIT,              0,          0,              1,              1,               1,             1,              0],
    [PermissionsMapping.AUDITS__VIEW_AND_EDIT,       0,          0,              0,              0,               1,             0,              0],
    [PermissionsMapping.CAMPAIGN__VIEW,              0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT,     0,          0,              0,              1,               1,             1,              0],
    [PermissionsMapping.DASHBOARD__VIEW,             1,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.FTA__VIEW,                   0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.FTA__EDIT,                   0,          0,              1,              1,               1,             1,              0],
    [PermissionsMapping.IMPERSONATE,                 0,          0,              0,              0,               1,             0,              1],
    [PermissionsMapping.MEDIAPLAN__VIEW_AND_EDIT,    0,          0,              1,              1,               1,             0,              0],
    [PermissionsMapping.FEATURE_NOTIFICATION,        0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.OVERVIEW__VIEW,              0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.PIXEL__VIEW,                 0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.PIXEL__EDIT,                 0,          0,              1,              1,               1,             1,              0],
    [PermissionsMapping.PROGRESS__VIEW,              0,          0,              1,              1,               1,             1,              1],
    [PermissionsMapping.REPORT__VIEW,                1,          0,              1,              1,               1,             1,              1]
];

const intentsByRole = createRoleObjWithIntents(permissionsTable);
function createRoleObjWithIntents(permissionsTable) {
    let obj = {};
    const copy = [...permissionsTable];
    const roleKeys = copy.shift();
    forEach(copy, permission => {
        let intent;
        forEach(permission, (currentValue, currentIndex) => {
            if (currentIndex === 0) {
                intent = currentValue;
            } else {
                if (!obj[roleKeys[currentIndex]]) {
                    obj[roleKeys[currentIndex]] = [];
                }
                currentValue ? obj[roleKeys[currentIndex]].push(intent) : '';
            }
            return obj;
        });
    });
    return obj;
}

// Convert ['a', 'b'] to { a: true, b: true}
function toObject(list) {
    const obj = {};
    forEach(list, item => {
        obj[item] = true;
    });
    return obj;
}

export const ROLE_INTENTS = {
    revoked: toObject(revoked),
    analyst: toObject(intentsByRole.analyst),
    media_planner: toObject(intentsByRole.mediaPlanner),
    campaign_manager: toObject(intentsByRole.campaignManager),
    admin: toObject(intentsByRole.superAdmin),
    user_admin: toObject(intentsByRole.userAdmin),
    account_admin: toObject(intentsByRole.accountAdmin),
    read_only_super_admin: toObject(intentsByRole.readOnlySuperAdmin),
};

export const ALL_INTENTS = {};
forEach(ROLE_INTENTS, role => {
    forEach(role, (enabled, intent) => {
        ALL_INTENTS[intent] = enabled;
    });
});

import get from 'lodash/get';
import filter from 'lodash/filter';
import VError from 'verror';

import { actions } from './reducer';
import { default as uploadedAudiencesActions } from 'pages/audiences/uploaded-dashboard/actions';
import { default as AudienceActions } from 'states/resources/audiences/actions';
import notify from 'utils/notify';
import { graphqlRequest } from 'utils/http/redux';
import { TORSTAR_ID } from '../constants';

const SOURCE_TYPE = [
    'campaign',
    'pixel',
    'upload',
    'adobe',
    'ad',
    'pds_standard',
    'pds_brand',
    'pds_behaviour',
    'pds_custom',
    'pds_environics',
    'pds_mweb',
    'pds_mastercard',
    'pds_age',
    'pds_intent',
    'pds_manifold',
];

const initAudienceTableMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    if (action.type === actions.initAudiencesOverallTable.type) {
        try {
            const orgId = get(getState(), `profile.organizationId`, void 0);
            const keywords = action.payload.searchString;
            const audiences = await fetchAudiences(dispatch, keywords, undefined, undefined, orgId);
            dispatch(actions.initSuccess({ audiences }));
        } catch (err) {
            notify({
                error: new VError(err, 'Failed to fetch audience data for Audience Dashboard'),
            });
        }
    }

    if (action.type === actions.fetchSidebarAudience.type) {
        try {
            const orgId = get(getState(), `profile.organizationId`, void 0);
            const id = action.payload.id;
            const audience = await fetchAudience(dispatch, id, undefined, undefined, orgId);
            dispatch(actions.fetchSidebarAudienceSuccess({ audience: audience[0] }));
        } catch (err) {
            notify({
                error: new VError(err, 'Failed to fetch audience data for Audience Dashboard'),
            });
            dispatch(actions.fetchSidebarAudienceError(err));
        }
    }

    if (action.type === 'RETARGETING_EDITOR__SAVE_END') {
        dispatch(actions.closeModal());
        dispatch(actions.initAudiencesOverallTable({ searchString: '' }));
    }

    if (action.type === 'POIS_EDITOR__SAVE_END') {
        dispatch(actions.closeModal());
        dispatch(actions.initAudiencesOverallTable({ searchString: '' }));
    }

    if (action.type === 'AUDIENCE_EDITOR__SAVE_END') {
        dispatch(actions.closeModal());

        if (window.location.pathname === '/audiences/uploaded') {
            dispatch(uploadedAudiencesActions.init());
        }

        if (window.location.pathname === '/audiences/all') {
            dispatch(actions.initAudiencesOverallTable({ searchString: '' }));
        }
    }
};

const editAudienceMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    if (action.type === actions.editAudience.type) {
        const searchString = get(getState(), `audiencesOverallTableDashboard.searchString`, null);
        const audience = action.payload.audienceId;
        const changes = action.payload.changes;
        try {
            await dispatch(AudienceActions.update(audience, changes));
            await dispatch(actions.editAudienceSuccess());
            await dispatch(actions.initAudiencesOverallTable({ searchString }));
        } catch (e) {
            await dispatch(actions.editAudienceFail());
        }
    }
};

export const makeMiddlewares = deps => {
    return [initAudienceTableMw(deps), editAudienceMw(deps)];
};

export const middlewares = makeMiddlewares();

export async function fetchAudiences(dispatch, keywords, offset, limit, orgId) {
    const query = `
        query getAudiences ($keywords: String, $source_type: [String], $offset: Int, $limit: Int $excludeRescricted: Boolean, $deprecated: Boolean) {
            audienceSegments(filters: { keywords: $keywords, source_type: $source_type, offset: $offset, limit: $limit, excludeRescricted: $excludeRescricted, deprecated: $deprecated }) {
                id
                name
                dataCost
                type
                status
                category
                subCategory
                source_type
                uniques
                uniqueDevices
                uniqueCTVs
                uniqueDOOHs
                _created
                expire_at
                contact_ces
            }
            flexSegments(filters: { keywords: $keywords, source_type: ["flex_standard_brand", "flex_location_category", "flex_custom_brand"] }) {
                id
                name
                type
                audience_type
                status
                category
                source_type
                uniques
                uniqueDevices
                uniqueCTVs
                uniqueDOOHs
            }
        }
        `;

    const sourceType = orgId === TORSTAR_ID ? [...SOURCE_TYPE, 'permutive'] : SOURCE_TYPE;

    const variables = {
        keywords,
        source_type: sourceType,
        offset,
        limit,
        excludeRescricted: true,
        deprecated: false,
    };
    const {
        data: { audienceSegments, flexSegments },
    } = await dispatch(
        graphqlRequest({
            query,
            variables,
        })
    );

    const filteredFlexSegments = filter(flexSegments, segment => segment.id);
    const result = audienceSegments.concat(filteredFlexSegments);
    return result;
}

async function fetchAudience(dispatch, id, offset, limit, orgId) {
    const query = `
        query getAudiences ($id: String, $source_type: [String], $offset: Int, $limit: Int $excludeRescricted: Boolean) {
            audienceSegments(filters: { id: $id, source_type: $source_type, offset: $offset, limit: $limit, excludeRescricted: $excludeRescricted }) {
                id
                name
                description
                lastRefreshed
                dataCost
                uniques
                status
                uniqueDevices
                uniqueCookies
                uniqueCTVs
                uniqueDOOHs
                match_rate
                type
                category
                subCategory
                source_type
                source_type_category
                subType
                tier
                poiCount
                mode
                contact_ces
                environicsMetadata {
                    sesi
                    sg
                    ls
                    name
                    population
                    householdCount
                    size
                    officialLanguage
                    culturalDiversityIndex
                    averageIncome
                    incomeLevel
                    ageOfMaintainer
                    familyStatus
                    householdsWithChildren
                    education
                    jobType
                    ageOfChildren
                    dwellingType
                    ownOrRent
                }
                manifoldMetadata {
                    summaryPoints
                    description
                    avgIncome
                    homeValue
                    residence
                    education
                    purchaseItems
                    householdSize
                    avgMaintainersAge
                    ancestry
                    jobType
                    lifestyle
                    shopping
                }
                _created
                supports
                customUploadOrigin
                upload_origin
                third_party_fees
                retargeting_type
                frequency
                expire_at
            }
            flexSegments(filters: { id: $id, source_type: ["flex_standard_brand", "flex_location_category", "flex_custom_brand"] }) {
                id
                name
                type
                category
                status
                source_type
                source_type_category
                supports
                uniques
                uniqueDevices
                uniqueCTVs
                uniqueDOOHs
                points
            }
        }
        `;

    const sourceType = orgId === TORSTAR_ID ? [...SOURCE_TYPE, 'permutive'] : SOURCE_TYPE;

    const variables = {
        source_type: sourceType,
        offset,
        limit,
        excludeRescricted: true,
        id: id,
    };
    const {
        data: { audienceSegments, flexSegments },
    } = await dispatch(
        graphqlRequest({
            query,
            variables,
        })
    );

    const filteredFlexSegments = filter(flexSegments, segment => segment.id);
    const result = audienceSegments.concat(filteredFlexSegments);

    return result;
}

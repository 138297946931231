import React from 'react';

import Card from 'widgets-v5/card';
import { SortableChipRow } from 'widgets-v5/chip';
import cn from 'classnames';
import { SubheadingBold } from 'widgets-v5/typography';
import Spacer from 'widgets-v5/spacer';
import { MultiSelect } from 'widgets-v6/select';
import Grid from '@mui/material/Grid';

class InventoryReportLayout extends React.Component {
    render() {
        const formattedOptions = _.map(this.props.dimensionOptions, item => {
            if (item.label === 'App Name' && this.props.channel_type === 'SITE') {
                return { ...item, label: 'Site Name' };
            } else {
                return { ...item };
            }
        });

        return (
            <div>
                <Card margin={'26px 16px'}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <MultiSelect
                                label="Dimensions"
                                value={this.props.selectedDimensions}
                                onChange={this.props.updateDimensions}
                                placeholder="Dimensions"
                                options={formattedOptions}
                                error={this.props.errors.dimension && this.props.status === 'error'}
                                errorMessage={this.props.errors.dimension}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelect
                                label="Attributes"
                                value={this.props.selectedAttributes}
                                onChange={this.props.updateAttributes}
                                placeholder="Attributes"
                                options={this.props.attributeOptions}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MultiSelect
                                label="Metrics"
                                value={this.props.selectedMetrics}
                                onChange={this.props.updateMetrics}
                                placeholder="Metrics"
                                options={this.props.metricOptions}
                                error={this.props.errors.metric && this.props.status === 'error'}
                                errorMessage={this.props.errors.metric}
                            />
                        </Grid>
                    </Grid>
                    <Spacer type="small" />
                    <div>
                        <SortableChipRow
                            value={this.props.selectedDimensions}
                            items={formattedOptions}
                            onChange={this.props.updateDimensions}
                        />
                    </div>
                    <div style={{ marginTop: 10 }} className="ef6-alignment__space-between">
                        <span className="ef6-alignment__flex">
                            <span>Show top: </span>
                            <span
                                className={cn('ef5-interactive-area__primary', {
                                    'ef5-color-scheme_secondary': this.props.limit === 100,
                                })}
                                onClick={() => this.props.showLimit(100)}
                            >
                                100
                            </span>
                            <span> / </span>
                            <span
                                className={cn('ef5-interactive-area__primary', {
                                    'ef5-color-scheme_secondary': this.props.limit === 500,
                                })}
                                onClick={() => this.props.showLimit(500)}
                            >
                                500
                            </span>
                            <span> / </span>
                            <span
                                className={cn('ef5-interactive-area__primary', {
                                    'ef5-color-scheme_secondary': this.props.limit === -1,
                                })}
                                onClick={() => this.props.showLimit(-1)}
                            >
                                {this.props.channel_type === 'APP' ? 'All Apps' : 'All Sites'}
                            </span>
                        </span>
                        {this.props.applyButton}
                    </div>
                </Card>
                <Card margin={'26px 16px'}>
                    <div
                        className="ef6-alignment__space-between"
                        style={{ alignItems: 'center', marginBottom: 10 }}
                    >
                        <SubheadingBold>
                            Last 30 Days' Stats
                            <span style={{ marginLeft: 5, color: '#757575' }}>
                                <i style={{ fontSize: 14 }} className="fa fa-globe" />
                                <span style={{ marginLeft: 3, font: '400 12px Arial, sans-serif' }}>
                                    UTC
                                </span>
                            </span>
                        </SubheadingBold>
                        <div className="ef6-alignment__right">{this.props.exportReportButton}</div>
                    </div>
                    {this.props.pivotTable}
                </Card>
            </div>
        );
    }
}

export default InventoryReportLayout;

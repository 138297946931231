import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Bacon from 'baconjs';

import countryConstants from 'common/constants/countries';
import { getNextBase, createHttp } from 'utils/http';
import Toggle from 'widgets-v5/toggle';
import FormField from 'widgets-v5/form-field';
import MultiSelect from 'widgets/form-inputs/multi-select';
import QuickDialog from 'widgets-v5/quick-dialog';
import { NeutralButton } from 'widgets-v5/buttons';

import { formatGeoRegionCity } from 'services/gis/geo-region-format';
import BatchImport from '../batch-import';
import Spacer from 'widgets-v5/spacer';
import { Title } from 'widgets-v5/typography';

import { validateCities, validateFsas } from '../batch-import/validators';

import { getEnvironmentSettings } from 'services/environment';

export default class extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { include, exclude } = props;

        const includeItems = include ? this.updateSelectItems(include) : [];
        const excludeItems = exclude ? this.updateSelectItems(exclude) : [];

        this.state = {
            includeItems,
            excludeItems,
            includeSearchItems: [],
            excludeSearchItems: [],
            togglePosition: includeItems.length || excludeItems.length ? 'right' : 'left',
        };
    }

    static defaultProps = {
        goal: true,
        removeCountryButton: true,
    };

    togglePosition = () => {
        const togglePosition = this.state.togglePosition === 'left' ? 'right' : 'left';
        this.setState({ togglePosition }, () => {
            this.props.onChange(this.getValue());
        });
    };

    fetchResults = async query => {
        const authToken = this.props.profileToken;

        // Allow for fake fetchers to make it easy to test
        if (this.props.fetcher) {
            const fetchOptions = {
                type: 'GET',
                url: `${getNextBase()}geo?query=${query}&page=1&country=${this.props.country}`,
                headers: { Authorization: authToken.auth_token },
            };

            return fetcher(fetchOptions);
        }
        const http = createHttp();
        const graphQLQuery = `
                query getGeoLocations($page: Int, $country: String, $keywords: String) {
                    geoLocations(filters: { page: $page, country: $country, keywords: $keywords}) {
                        city
                        name
                        region
                        fsa
                    }
                }
            `;
        const variables = {
            page: 1,
            country: this.props.country,
            keywords: query,
        };
        const { geoLocations } = await http.graphql(graphQLQuery, variables);
        return geoLocations;
    };

    handleInputSearch = (value, type) => {
        if (value.length > 2) {
            this.stream.push({ value, type });
        }

        return value;
    };

    handleIncludeSelected = value => {
        // User clicked clear
        if (!value.length) {
            return this.setState({ includeItems: [] }, () => {
                this.props.onChange(this.getValue());
            });
        }

        const allSelectableItems = unionByValue(
            this.state.includeSearchItems,
            this.state.includeItems
        );
        const newItems = _.flatten(
            value.map(value => {
                const matchedItem = allSelectableItems.filter(item => item.value === value);
                return matchedItem;
            })
        );

        this.setState({ includeItems: newItems }, () => {
            this.props.onChange(this.getValue());
        });
    };

    handleExcludeSelected = value => {
        // User clicked clear
        if (!value.length) {
            return this.setState({ excludeItems: [] }, () => {
                this.props.onChange(this.getValue());
            });
        }

        const allSelectableItems = unionByValue(
            this.state.excludeSearchItems,
            this.state.excludeItems
        );
        const newItems = _.flatten(
            value.map(value => {
                const matchedItem = allSelectableItems.filter(item => item.value === value);
                return matchedItem;
            })
        );

        this.setState({ excludeItems: newItems }, () => {
            this.props.onChange(this.getValue());
        });
    };

    updateSelectItems = response => {
        const filteredResponse = response.map(item => {
            const label = formatGeoRegionCity(this.props.country, item);
            const filteredItem = {
                label,
                value: label.replace(/[, ]+/g, ' ').trim(),
            };
            if (item.city) {
                filteredItem.city = item.city;
            }

            if (item.region) {
                filteredItem.region = item.region;
            }

            if (item.fsa) {
                filteredItem.fsa = item.fsa;
            }

            return filteredItem;
        });

        return filteredResponse;
    };

    resetSelectItems = () => {
        this.setState({
            includeSearchItems: [],
            excludeSearchItems: [],
        });
    };

    componentDidMount() {
        this.stream = new Bacon.Bus();
        const self = this;

        this.stream
            .debounce(100)
            .flatMapLatest(query => {
                const promise = this.fetchResults(query.value).then(response => {
                    return {
                        response,
                        type: query.type,
                    };
                });
                return Bacon.fromPromise(promise, true);
            })
            .onValue(({ response, type }) => {
                const selectItemsAsArray = self.updateSelectItems(response);

                const itemsAsUniqueObj = _.reduce(
                    selectItemsAsArray,
                    (acc, item) => ({
                        ...acc,
                        [item.value]: item,
                    }),
                    {}
                );
                const itemsAsUniqueArray = _.sortBy(itemsAsUniqueObj, 'label');

                self.setState({ [`${type}SearchItems`]: itemsAsUniqueArray });
            });
    }

    getValue = () => {
        if (this.state.togglePosition === 'left') {
            return {
                include: [],
                exclude: [],
            };
        }

        return {
            include: this.state.includeItems,
            exclude: this.state.excludeItems,
        };
    };

    filterIncludeExcludeProps = targets => {
        const { country } = this.props;

        if (targets) {
            const filteredProp = targets.map(item => {
                const region = formatGeoRegionCity(country, item);
                if (item.fsa) {
                    return item.fsa;
                }
                return `${region.replace(/[, ]+/g, ' ').trim()}`;
            });
            return filteredProp;
        }
    };

    onImportClick = (validTargets, type) => {
        const formattedValues = _(validTargets)
            .map(item => {
                const mappedTarget = {
                    label: item.value,
                    value: item.value.replace(/[, ]+/g, ' ').trim(),
                };

                const splitValue = item.value.split(',');
                if (splitValue.length > 1) {
                    const [city, regionName] = splitValue;
                    mappedTarget.city = city;

                    if (regionName && regionName.replace(/\s+/g, '').length === 2) {
                        mappedTarget.isRegionCode = true;
                    }
                    mappedTarget.region = item.regionCode;
                } else {
                    mappedTarget.fsa = splitValue[0];
                    mappedTarget.region = item.region;
                }

                return mappedTarget;
            })
            .union(this.state[type + 'Items'])
            .uniqBy(item => {
                if (item.fsa) {
                    return item.fsa;
                }

                if (!item.city) {
                    return item.region;
                }
                return item.city.replace(/\s+/g, '') + item.region;
            })
            .value();
        this.setState(
            {
                [type + 'Items']: formattedValues,
            },
            () => {
                this.props.onChange(this.getValue());
            }
        );
    };

    getGeotargetItemType = item => {
        if (item.fsa) {
            return 'POSTAL CODE';
        }

        if (item.city) {
            return 'CITY';
        }

        return 'REGION';
    };

    render() {
        const { country } = this.props;
        const countryName = countryConstants.filter(value => value.id === country)[0].text;

        const initialInclude = this.filterIncludeExcludeProps(this.props.include);
        const initialExclude = this.filterIncludeExcludeProps(this.props.exclude);
        const selectableIncludeItems = _(this.state.includeSearchItems)
            .union(this.state.includeItems)
            .sortBy('label')
            .value();

        const selectableExcludeItems = _(this.state.excludeSearchItems)
            .union(this.state.excludeItems)
            .sortBy('label')
            .value();

        const environmentSettings = getEnvironmentSettings();

        return (
            <div className="ef3-geoTarget_countryBlock">
                <div className="ef3-geoTarget_nameToggleClose">
                    <Title className="ef3-geoTarget_countryName">{countryName}</Title>
                    {environmentSettings.canSpecifyCityTargeting() && (
                        <div className="ef3-geoTarget_specifyLocationsBlock">
                            <div className="ef3-geoTarget_specifyLocationsLabel">
                                Specify Locations:
                            </div>
                            <div className="ef3-geoTarget_specifyLocationsToggle">
                                <Toggle
                                    width={90}
                                    height={25}
                                    position={this.state.togglePosition}
                                    textLeft="Off"
                                    textRight="On"
                                    onClick={this.togglePosition}
                                />
                            </div>
                        </div>
                    )}
                    {this.props.removeCountryButton && (
                        <i className="fa fa-times" onClick={() => this.props.onDelete()} />
                    )}
                </div>
                {environmentSettings.canSpecifyCityTargeting() && (
                    <div
                        className={classnames('ef3-geoTarget_inputFields', {
                            'is-active': this.state.togglePosition === 'right',
                        })}
                    >
                        <FormField label="Include">
                            <div className="ef3-geoTarget_batchImport">
                                <QuickDialog
                                    verticalAlign="top"
                                    horizontalAlign="right"
                                    button={open => (
                                        <NeutralButton
                                            icon={<i className="fa fa-sign-in" />}
                                            text="Bulk Import"
                                            onClick={open}
                                        />
                                    )}
                                    dialog={close => (
                                        <BatchImport
                                            onImportClick={values =>
                                                this.onImportClick(values, 'include')
                                            }
                                            validators={[
                                                validateCities(country),
                                                validateFsas(country),
                                            ]}
                                            onClose={close}
                                        />
                                    )}
                                />
                            </div>
                            <MultiSelect
                                ref="include"
                                onBlur={this.resetSelectItems}
                                placeholder="Search for cities, regions, or postal codes"
                                value={initialInclude}
                                optionRenderer={option => (
                                    <div className="ef3-geoTarget_listItem">
                                        {option.label}&nbsp;
                                        <span className="ef3-geoTarget_listItem_type">
                                            {this.getGeotargetItemType(option)}
                                        </span>
                                    </div>
                                )}
                                noResultsText="No results, enter 3 or more characters to search"
                                onChange={value => this.handleIncludeSelected(value)}
                                onInputChange={value => this.handleInputSearch(value, 'include')}
                                options={selectableIncludeItems}
                                filterOption={() => {
                                    return true;
                                }}
                            />
                        </FormField>
                        <Spacer />
                        <FormField label="Exclude">
                            <div className="ef3-geoTarget_batchImport">
                                <QuickDialog
                                    verticalAlign="top"
                                    horizontalAlign="right"
                                    button={open => (
                                        <NeutralButton
                                            icon={<i className="fa fa-sign-in" />}
                                            text="Bulk Import"
                                            onClick={open}
                                        />
                                    )}
                                    dialog={close => (
                                        <BatchImport
                                            onImportClick={values =>
                                                this.onImportClick(values, 'exclude')
                                            }
                                            validators={[
                                                validateCities(country),
                                                validateFsas(country),
                                            ]}
                                            onClose={close}
                                        />
                                    )}
                                />
                            </div>
                            <MultiSelect
                                ref="exclude"
                                onBlur={this.resetSelectItems}
                                placeholder="Search for cities, regions, or postal codes"
                                value={initialExclude}
                                optionRenderer={option => (
                                    <div className="ef3-geoTarget_listItem">
                                        {option.label}&nbsp;
                                        <span className="ef3-geoTarget_listItem_type">
                                            {this.getGeotargetItemType(option)}
                                        </span>
                                    </div>
                                )}
                                noResultsText="No results, enter 3 or more characters to search"
                                onChange={value => this.handleExcludeSelected(value)}
                                onInputChange={value => this.handleInputSearch(value, 'exclude')}
                                options={selectableExcludeItems}
                                filterOption={() => {
                                    return true;
                                }}
                            />
                        </FormField>
                    </div>
                )}
            </div>
        );
    }
}

// Note: replace with _.unionBy when we upgrade to 4.x
function unionByValue(...arrays) {
    const uniqueItems = {};
    arrays.forEach(array =>
        array.forEach(object => {
            uniqueItems[object.value] = object;
        })
    );
    return _.map(uniqueItems, value => value);
}

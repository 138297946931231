import _ from 'lodash';

export default function selector(storeState) {
    const isFormOpen = _.get(storeState, 'conversionsDashboard.isFormOpen', false);
    const advertisers = _.get(storeState, 'conversionsDashboard.advertisers', []);
    const draft = _.get(storeState, 'conversionsDashboard.draft');
    const errors = _.get(storeState, 'conversionsDashboard.errors');
    const showErrors = _.get(storeState, 'conversionsDashboard.showErrors');
    const isSaving = _.get(storeState, 'conversionsDashboard.isSaving');
    const serverError = _.get(storeState, 'conversionsDashboard.serverError');
    const conversionToEdit = _.get(storeState, 'conversionsDashboard.conversionToEdit');

    const advertiserOptions = _.map(advertisers, advertiser => ({
        value: advertiser.id,
        label: advertiser.name,
    }));

    let serverErrorMessage;
    if (serverError && serverError.code === 400 && _.isString(serverError.body)) {
        serverErrorMessage = serverError.body;
    }

    return {
        isOpen: isFormOpen,
        isSaving,
        advertiserOptions,
        draft,
        serverErrorMessage,
        errors: showErrors ? errors : {},
        mode: conversionToEdit ? 'edit' : 'new',
    };
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class extends React.Component {
    static displayName = 'CenterLayout';

    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        onClick() {},
    };

    render() {
        return (
            <div
                className={classnames('ef4-center-layout', this.props.className)}
                onClick={this.props.onClick}
            >
                <div className="ef4-center-layout__workspace">{this.props.children}</div>
            </div>
        );
    }
}

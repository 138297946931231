import each from 'lodash/each';
import isArray from 'lodash/isArray';
/** import third-party libraries in the section above, keep the ascending order */

export const DOOH_VENUE_TYPES = {
    '1': 'Transit',
    '1-101': 'Airports',
    '1-102': 'Buses',
    '1-103': 'Taxi and Rideshare TV',
    '1-104': 'Taxi and Rideshare Top',
    '1-105': 'Subway',
    '1-106': 'Train Stations',
    '2': 'Retail',
    '2-201': 'Gas Stations',
    '2-202': 'Convenience Stores',
    '2-203': 'Grocery',
    '2-204': 'Liquor Stores',
    '2-205': 'Malls',
    '2-206': 'Dispensaries',
    '2-207': 'Pharmacies',
    '2-208': 'Parking Garages',
    '3': 'Outdoor',
    '3-301': 'Billboards',
    '3-302': 'Urban Panels',
    '3-303': 'Bus Shelters',
    '4': 'Health and Beauty',
    '4-401': 'Gyms',
    '4-402': 'Salons',
    '4-403': 'Spas',
    '5': 'Point of Care',
    '5-501': "Doctor's Offices",
    '5-502': "Veterinary's Offices",
    '6': 'Education',
    '6-601': 'Schools',
    '6-602': 'Colleges and Universities',
    '7': 'Offices',
    '7-701': 'Office Buildings',
    '8': 'Entertainment',
    '8-801': 'Recreational Locations',
    '8-802': 'Movie Theaters',
    '8-803': 'Sports Entertainment',
    '8-804': 'Bars',
    '8-805': 'Casual Dining',
    '8-806': 'QSR',
    '8-807': 'Hotels',
    '9': 'Government',
    '9-901': 'DMVs',
    '9-902': 'Military Bases',
    '10': 'Financial',
    '10-1001': 'Banks',
    '11': 'Residential',
    '11-1101': 'Apartment Buildings',
};

/**
 * Flattened DOOH venue types
 * It looks like this:
 * {
 *  "1" : "Transit",
 *  "101" : "Airports",
 *  "102" : "Buses",
 *  "2": "Retail",
 *  "201": "Gas Stations",
 * ...
 * }
 * @type {Object}
 */
export const DOOH_VENUE_TYPES_NO_PARENT = Object.keys(DOOH_VENUE_TYPES).reduce((acc, key) => {
    const splitKey = key.split('-');
    const selectedKey = splitKey[1] || splitKey[0];
    acc[selectedKey] = DOOH_VENUE_TYPES[key];
    return acc;
}, {});

/**
 * Flattened DOOH venue types to be used in child to parent mapping
 * It looks like
 * {
 * "1": "1",
 * "101": "1-101",
 * "102": "1-102",
 * "2": "2",
 * "201": "2-201",
 * ...
 * }
 */
const DOOH_CHILD_TO_PARENT = Object.keys(DOOH_VENUE_TYPES).reduce((acc, key) => {
    const splitKey = key.split('-');
    const selectedKey = splitKey[1] || splitKey[0];
    acc[selectedKey] = key;
    return acc;
}, {});

/**
 * Map DOOH venue types to parent
 * @param {Array} values
 * @returns {Array}
 */
export function doohChildToParent(values) {
    if (!isArray(values)) {
        return [];
    }

    const mappedValues = [];

    each(values, value => {
        if (DOOH_CHILD_TO_PARENT[value]) {
            mappedValues.push(DOOH_CHILD_TO_PARENT[value]);
        }
    });
    return mappedValues;
}

/**
 * Returns DOOH Venue Types Name
 * @param {Array} values
 * @returns {Array}
 */
export function doohVenueTranslate(values) {
    if (!isArray(values)) {
        return [];
    }
    let mappedValues = [];
    each(values, value => {
        if (DOOH_CHILD_TO_PARENT[value]) {
            const parentChild = DOOH_CHILD_TO_PARENT[value];
            mappedValues.push(DOOH_VENUE_TYPES[parentChild]);
        } else if (DOOH_VENUE_TYPES[value]) {
            mappedValues.push(DOOH_VENUE_TYPES[value]);
        }
    });
    return mappedValues.join(', ');
}

import _ from 'lodash';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles(theme => ({
    menu: {
        top: 450,
    },
    columns: {
        height: 300,
        overflow: 'auto',
    },
    dropdownLabel: {
        padding: 10,
    },
}));

export default function ColumnsDropdown(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = !!anchorEl;

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columnsButtonIcon = open ? <ExpandLessIcon /> : <ExpandMoreIcon />;

    return (
        <div style={{ position: 'relative' }}>
            <Button size="small" color="inherit" endIcon={columnsButtonIcon} onClick={openMenu}>
                Columns
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="long-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.menu }}
            >
                <div className={classes.dropdownLabel}>Show Columns</div>
                <div className={classes.columns}>
                    {_.map(props.columns, column => {
                        const columnName = column.name || column.id;
                        return (
                            <MenuItem key={columnName} onClick={() => props.onClick(columnName)}>
                                <Checkbox
                                    checked={!props.hiddenColumns[columnName]}
                                    value="checkedA"
                                    color="primary"
                                />
                                {props.campaignCurrencyType
                                    ? column.label({
                                          campaignCurrencyType: props.campaignCurrencyType,
                                      })
                                    : column.label || column.Header}
                            </MenuItem>
                        );
                    })}
                </div>
            </Menu>
        </div>
    );
}

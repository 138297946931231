import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';

const Apps = {
    batchFetch(bundle_ids) {
        return (dispatch, getState) => {
            dispatch({
                type: c.SYSTEM__APPS__FETCH,
                payload: {
                    bundle_ids,
                },
            });
            return http()
                .post(`apps`, {
                    bundle_ids: bundle_ids,
                })
                .then(
                    apps => {
                        dispatch({
                            type: c.SYSTEM__APPS__FETCH__SUCCESS,
                            payload: {
                                apps,
                            },
                        });
                        return apps;
                    },
                    err => {
                        console.error(`BATCH FETCH apps error`, err);

                        throw err;
                    }
                );
        };
    },

    create(orgId, payload) {
        return (dispatch, getState) => {
            return http()
                .post(`organizations/${orgId}/applists`, payload)
                .then(
                    appList => {
                        dispatch({
                            type: c.SYSTEM__APP_LIST__FETCH__SUCCESS,
                            payload: {
                                orgId,
                                appList,
                            },
                        });
                        return appList;
                    },
                    err => {
                        console.error(`CREATE apps error`, err);

                        throw err;
                    }
                );
        };
    },

    patch(orgId, applistId, payload) {
        return (dispatch, getState) => {
            return http()
                .patch(`organizations/${orgId}/applists/${applistId}`, payload)
                .then(
                    results => results,
                    err => {
                        console.error(`PATCH apps error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default Apps;

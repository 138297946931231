import React, { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Icon from '@mdi/react';
import { mdiFileUpload } from '@mdi/js';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';
import { AudienceCard } from '../modules/audience-card';
import { AudienceTable } from '../modules/audience-table';
import { default as UploadedAudiencesModal } from 'pages/audiences/editor';
import { default as uploadedEditorActions } from 'pages/audiences/editor/actions';
import { useDispatch } from 'react-redux';
import { useOverallTableDashboardData } from '../overall-dashboard/v2/hook';

const sortingOptions = [
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
];

const useStyles = makeStyles({
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#098679',
    },
});

const UploadedLayout = ({
    audienceSegments,
    onSearch,
    segmentCount,
    activeAudience,
    isModalOpen,
    organizationId,
}) => {
    const classes = useStyles();
    const { canEditAudiences } = useOverallTableDashboardData();
    const [activeSort, setActiveSort] = useState({ value: '_created', direction: 'desc' });
    const [audienceValue, setAudienceValue] = useState(null);

    const dispatch = useDispatch();

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const handleSortChange = event => {
        const { value, direction } = _.find(sortingOptions, { value: event.target.value });
        setActiveSort({ value, direction });
    };

    const sortedAudiences = _.orderBy(audienceSegments, [activeSort.value], [activeSort.direction]);

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'custom' },
    };

    const openUploadedEditorActions = (audienceId, target) => {
        setAudienceValue(audienceId);
        dispatch(uploadedEditorActions.initialize(audienceId, target));
        dispatch(uploadedEditorActions.openModal(audienceId));
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Your Custom Audiences
                </RouterLink>
                <Typography color="textPrimary">Uploaded</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <Icon path={mdiFileUpload} size={3} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            Uploaded
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            Upload your own segments from any source like third-party DMPs and CRM
                            platforms, based on mobile Device IDs.
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Typography variant="subtitle2" color="textSecondary">
                                    Total
                                </Typography>
                                <Typography variant="h5">{segmentCount}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Box mb={2} mt={2}>
                                {canEditAudiences && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => openUploadedEditorActions(null, 'uploaded')}
                                    >
                                        <AddIcon /> New Audience
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mb={2.5} mt={5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={7}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <SearchInput
                                        placeholder="Filter Audiences"
                                        onChange={handleSearch}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort.value}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <AudienceTable
                    audiences={sortedAudiences}
                    cardSize="small"
                    activeAudience={activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            segment={segment}
                            onClick={() => openUploadedEditorActions(segment.id, 'uploaded')}
                            active={segment.id === activeAudience}
                            organizationId={organizationId}
                        />
                    )}
                />
                {isModalOpen && <UploadedAudiencesModal audienceId={audienceValue} />}
            </Container>
        </Box>
    );
};

export default UploadedLayout;

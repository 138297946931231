import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { RevenueModelMapping } from '../../states/resources/campaigns/business-logic';

import { createSelector } from 'reselect';
import { REDUCER_KEY } from './reducer';

const alertsToShowOnlyItsLevel = ['projectedToUnderdeliver'];
export const getOverviewAdData = state => get(state, REDUCER_KEY);
export const getSidebarAlerts = createSelector(
    getOverviewAdData,
    overviewAdData => {
        return overviewAdData.alertsSidebar.alerts;
    }
);
export const getSidebarAlertsIsLoading = createSelector(
    getOverviewAdData,
    overviewAdData => {
        return overviewAdData.alertsSidebar.isLoading;
    }
);

export const getCampaignsFlightsAndAds = createSelector(
    getOverviewAdData,
    overviewAdData => {
        const {
            endingSoon,
            upcoming,
            recentlyEnded,
            campaignsEndingSoon,
            campaignsUpcoming,
            campaignsEnded,
            alerts,
            selfServeOrgList,
        } = overviewAdData;

        const campaignHasGoal = campaign => campaign.budget_allocation_method === 'auto';

        const campaignHasFlights = campaign => {
            return campaign.flightPacingStrategy === 'campaign';
        };

        const getCampaignPrimaryPacing = campaign => {
            if (
                [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                    campaign?.revenueModel
                ) &&
                campaign.budget_allocation_method === 'auto'
            ) {
                return 'impressions';
            }

            return campaign.revenueModel === RevenueModelMapping.AgencyMargin ? 'revenue' : 'spend';
        };

        const getCampaignTarget = campaign => {
            if (
                [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                    campaign?.revenueModel
                ) &&
                campaign.budget_allocation_method === 'auto'
            ) {
                return get(campaign, 'campaign_max_total_impressions');
            }

            return campaign.revenueModel === RevenueModelMapping.AgencyMargin
                ? get(campaign, 'total_billings_local')
                : get(campaign, 'campaign_max_total_spend');
        };

        const getOrgName = (orgId) => {
            if (selfServeOrgList && selfServeOrgList.length) {
                const org = selfServeOrgList.find(({ id }) => id === orgId);
                return org ? org.name : '';
            }

            return '';
        }

        const mergeAndFilterAdsAndCampaigns = (ads, campaigns, alerts) => {
            // Filter out campaigns which don't have a goal. This means
            // campaigns in which the goal is set at ad level. We don't
            // want to show these campaigns in the dashboard.
            const campaignsWithGoals = campaigns.filter(campaignHasGoal).map(campaign => ({
                ...campaign,
                primary_pacing: getCampaignPrimaryPacing(campaign),
                timezone: campaign.default_timezone,
                target: getCampaignTarget(campaign),
                metadata: {
                    progress: {
                        progressData: {
                            ...get(campaign, 'metadata.progressData', {}),
                        },
                    },
                    metrics: {
                        ...get(campaign, 'metadata.metrics', {}),
                    },
                },
                alerts: getAlertsCount({ campaign, alerts }),
                orgName: getOrgName(campaign.organization),
            }));
            const campaignIdList = campaignsWithGoals.map(({ id }) => id);

            const campaignsWithFlights = campaignsWithGoals.filter(campaignHasFlights);
            const campaignsWithoutFlights = campaignsWithGoals.filter(
                campaign => !campaignHasFlights(campaign)
            );

            const flights = [];
            campaignsWithFlights.forEach(campaign => {
                campaign.flights.forEach(flight => {
                    flights.push({
                        ...campaign,
                        ...flight,
                        metadata: {
                            progress: {
                                progressData: {
                                    ...get(flight, 'metadata.progressData', {}),
                                },
                            },
                            metrics: {
                                ...get(campaign, 'metadata.metrics', {}),
                            },
                        },
                        flightId: flight.id,
                        id: campaign.id,
                        type: 'Flight',
                    });
                });
            });

            // Filter the ads that belong to a campaign that has a goal. We don't
            // want to show ads in this situation as the campaign will contain
            // the relevant information.
            const filteredAds = ads
                .filter(({ campaignId }) => !campaignIdList.includes(campaignId))
                .map(ad => ({
                    ...ad,
                    primary_pacing:
                        ad.primary_pacing === 'billings' ? 'revenue' : ad.primary_pacing,
                    alerts: getAlertsCount({ ad, alerts }),
                    orgName: getOrgName(ad.organization),
                }));
            return [].concat(campaignsWithoutFlights, filteredAds, flights);
        };

        return {
            endingSoon: mergeAndFilterAdsAndCampaigns(endingSoon, campaignsEndingSoon, alerts),
            upcoming: mergeAndFilterAdsAndCampaigns(upcoming, campaignsUpcoming),
            recentlyEnded: mergeAndFilterAdsAndCampaigns(recentlyEnded, campaignsEnded),
        };
    }
);

function getFilteredAlertsByResourceLevel({ campaign, ad, alerts }) {
    if (campaign) {
        return alerts && alerts
            .filter(alert => alert.campaignId === campaign.id.toString())
            .filter(
                alert =>
                    !(
                        alert.resourceType === 'ad' &&
                        includes(alertsToShowOnlyItsLevel, alert.alertType)
                    )
            );
    }
    return filter(alerts, alert => alert.resourceId === ad.id);
}

function getAlertsCount({ campaign, ad, alerts }) {
    const filteredAlerts = getFilteredAlertsByResourceLevel({ campaign, ad, alerts });

    const severityCounts = reduce(
        filteredAlerts,
        (result, alert) => {
            result[alert.severity]++;
            return result;
        },
        { issue: 0, notice: 0 }
    );

    let icon = 'default';
    if (severityCounts.issue > 0) {
        icon = 'issue';
    } else if (severityCounts.notice > 0) {
        icon = 'notice';
    }

    return { ...severityCounts, icon };
}

export const getParsedSidebarAlerts = createSelector(
    getSidebarAlerts,
    sidebarAlerts => {
        // If this is a campaign we should get the alerts for the campaign itself and
        // all alerts related to its ads.
        let resourceAlerts = {
            campaign: {},
            ads: {},
        };

        if ((sidebarAlerts.type === 'Campaign' || sidebarAlerts.type === 'Flight') && sidebarAlerts.alerts) {
            resourceAlerts = sidebarAlerts.alerts.reduce(
                (acc, alert) => {
                    const result = { ...acc };

                    if (alert.resourceId === sidebarAlerts.id.toString()) {
                        // This is an alert for the campaign itself.
                        if (alert.severity === 'issue') {
                            if (result.campaign.issues) {
                                result.campaign.issues.push(alert);
                            } else {
                                result.campaign.issues = [alert];
                            }
                        } else if (alert.severity === 'notice') {
                            if (result.campaign.notices) {
                                result.campaign.notices.push(alert);
                            } else {
                                result.campaign.notices = [alert];
                            }
                        }
                    } else if (alert.campaignId === sidebarAlerts.id.toString()) {
                        // This is an alert of an ad of this campaign.
                        const adName = `${alert.resourceId} ${alert.resourceName}`;
                        if (includes(alertsToShowOnlyItsLevel, alert.alertType)) {
                            result;
                        } else if (result.ads[adName]) {
                            if (alert.severity === 'issue') {
                                if (result.ads[adName].issues) {
                                    result.ads[adName].issues.push(alert);
                                } else {
                                    result.ads[adName].issues = [alert];
                                }
                            } else {
                                if (result.ads[adName].notices) {
                                    result.ads[adName].notices.push(alert);
                                } else {
                                    result.ads[adName].notices = [alert];
                                }
                            }
                        } else {
                            result.ads[adName] = {
                                issues: alert.severity === 'issue' ? [alert] : [],
                                notices: alert.severity === 'notice' ? [alert] : [],
                            };
                        }
                    }

                    return result;
                },
                {
                    id: `${sidebarAlerts.id} ${sidebarAlerts.name}`,
                    campaign: {},
                    ads: {},
                }
            );
        } else if (sidebarAlerts.type === 'ad' && sidebarAlerts.alerts) {
            resourceAlerts = sidebarAlerts.alerts.reduce(
                (acc, alert) => {
                    const result = { ...acc };

                    if (alert.resourceId === sidebarAlerts.id) {
                        const adName = `${alert.resourceId} ${alert.resourceName}`;
                        if (result.ads[adName]) {
                            if (alert.severity === 'issue') {
                                if (result.ads[adName].issues) {
                                    result.ads[adName].issues.push(alert);
                                } else {
                                    result.ads[adName].issues = [alert];
                                }
                            } else if (alert.severity === 'notice') {
                                if (result.ads[adName].notices) {
                                    result.ads[adName].notices.push(alert);
                                } else {
                                    result.ads[adName].notices = [alert];
                                }
                            }
                        } else {
                            result.ads[adName] = {
                                issues: alert.severity === 'issue' ? [alert] : [],
                                notices: alert.severity === 'notice' ? [alert] : [],
                            };
                        }
                    }

                    return result;
                },
                {
                    id: `${sidebarAlerts.id} ${sidebarAlerts.name}`,
                    ads: {},
                }
            );
        }

        return {
            ...sidebarAlerts,
            alerts: resourceAlerts,
        };
    }
);

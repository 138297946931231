import _ from 'lodash';

export const NAME = 'audienceDashboard';

const initialState = {
    isLoading: false,
    isInitialized: false,
    itemsPerPage: 25,
    maxResults: 0,
    pagination: {
        // 1: [1,2,3],
        // 2: [4,5,6],
        // 3: [7,8,9],
    },
    filters: {
        search: '',
    },
    audienceIdBeingDownloaded: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'AUDIENCE_DASHBOARD__DOWNLOAD__START':
            return {
                ...state,
                audienceIdBeingDownloaded: action.payload.audienceIdBeingDownloaded,
            };
        case 'AUDIENCE_DASHBOARD__DOWNLOAD__END':
            return {
                ...state,
                audienceIdBeingDownloaded: null,
            };
        case 'AUDIENCE_DASHBOARD__INIT__START': {
            return {
                ...state,
                isInitialized: false,
                filters: action.payload.filters || {},
            };
        }
        case 'AUDIENCE_DASHBOARD__INIT__END': {
            return {
                ...state,
                isInitialized: true,
            };
        }
        case 'AUDIENCE_DASHBOARD__INIT__ERROR': {
            return {
                ...state,
                isInitialized: true,
            };
        }
        case 'AUDIENCE_DASHBOARD__FETCH_PAGE_DATA__START': {
            return {
                ...state,
                isLoading: true,
            };
        }
        case 'AUDIENCE_DASHBOARD__FETCH_PAGE_DATA__END': {
            const { page, audiences, maxResults } = action.payload;

            const pagination = indexPage(state.pagination, page, audiences);

            return {
                ...state,
                pagination,
                maxResults,
                filters: {
                    ...state.filters,
                    page,
                },
                isLoading: false,
            };
        }
        case 'AUDIENCE_DASHBOARD__FETCH_PAGE_DATA__ERROR': {
            return {
                ...state,
                isLoading: false,
                errors: action.payload.error,
            };
        }
        case 'AUDIENCE_DASHBOARD__SEARCH': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    search: action.payload.text,
                },
            };
        }
        default:
            return state;
    }
}

function indexPage(pagination, page, audiences) {
    return {
        ...pagination,
        [page]: _.map(audiences, b => b.id),
    };
}

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
    DESKTOP_DEVICE_OS_OPTIONS,
    INAPP_DEVICES_OS_OPTIONS,
    CTV_DEVICE_OS_OPTIONS,
} from '../../services/default-values';

import Geotargets from '../geotargets';

import { AdFormCheckboxList } from '../checkbox-groups';

import { TargetAgeGroups_EF_1418 } from '../checkbox-groups';
import { TargetGenders } from '../checkbox-groups';
import PointsOfInterest from '../points-of-interest';
import Geoboxes from '../geo-boxes';
import { TargetDeviceLanguage } from '../target-device-language';
import { TargetDeviceLanguageExclude } from '../target-device-language-exclude';
import TargetCarrieries from '../target-carrieries';
import TargetIABCategories from '../target-iab-categories';
import TargetAppStoreCategories from '../target-app-store-categories';
import Spacer from 'widgets-v5/spacer';
import FormField from 'widgets-v5/form-field';
import Overlay from 'widgets-v5/overlay';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { DeviceOSMapping } from 'states/resources/ads/business-logic';

class Targeting extends React.Component {
    static propTypes = {
        isPoiRecategorized: PropTypes.bool,
    };

    state = {};

    render() {
        const {
            formData,
            errors,
            showErrors,
            profileToken,
            geoCategories,
            dispatch,
            adId,
            campaignId,
            showGeoEntityInMap,
            geofencingEntityInView,
            poiViewer_eTag,
            adForm,
            tacticsEnabled,
            campaignType,
        } = this.props;

        const hasBlackBerrySelected = _.includes(formData.target_device_os, DeviceOSMapping.BlackBerry);
        const targetDeviceOptions = getDeviceOptions({
            platform: formData.platform,
            hasBlackBerrySelected,
        });

        const isAppCategoryExcluding = formData.target_app_store_cat_exclude.length > 0;

        // const shouldShowPoiOverlay = hasAnyAudiences(formData);
        // TODO: Temporary Change for Campaign: 7619
        const shouldShowPoiOverlay = false;

        return (
            <div>
                <Spacer type="small" />

                <Geotargets
                    value={formData.geotargets}
                    errors={errors.geotargets}
                    showErrors={showErrors}
                    profileToken={profileToken}
                    onChange={this.props.onFieldChange('geotargets')}
                    campaignType={campaignType}
                />

                {formData.platform === 'inapp' && (
                    <React.Fragment>
                        <Spacer marginBottom={35} />

                        <Geoboxes
                            ref="geoboxes"
                            value={formData.geoboxes}
                            errors={errors.geoboxes}
                            showErrors={showErrors}
                            onChange={this.props.onFieldChange('geoboxes')}
                        />

                        <Spacer type="large" />

                        {!tacticsEnabled && (
                            <Overlay
                                disabled
                                message="Points of Interests are not available when Audience Segments are selected."
                                show={shouldShowPoiOverlay}
                            >
                                <FormField
                                    errors={errors.geo_targeting_settings}
                                    showErrors={showErrors}
                                >
                                    <PointsOfInterest
                                        adId={adId}
                                        campaignId={campaignId}
                                        geoTargetingSettings={formData.geo_targeting_settings}
                                        dispatch={dispatch}
                                        geoCategories={geoCategories}
                                        geoLayerSearchString={this.props.geoLayerSearchString}
                                        showGeoEntityInMap={showGeoEntityInMap}
                                        geofencingEntityInView={geofencingEntityInView}
                                        poiViewer_eTag={poiViewer_eTag}
                                        onPoiSearchStringChanged={
                                            this.props.onPoiSearchStringChanged
                                        }
                                        onGeoLayerFinder_folderStateChanged={
                                            this.props.onGeoLayerFinder_folderStateChanged
                                        }
                                        onChange={this.props.onFieldChange(
                                            'geo_targeting_settings'
                                        )}
                                        isPoiRecategorized={this.props.isPoiRecategorized}
                                    />
                                </FormField>
                            </Overlay>
                        )}
                    </React.Fragment>
                )}

                <Spacer />

                <AdFormCheckboxList
                    label="Device OS"
                    values={formData.target_device_os}
                    minSelected={1}
                    options={targetDeviceOptions}
                    errors={errors.target_device_os}
                    showErrors={showErrors}
                    onChange={this.props.onFieldChange('target_device_os')}
                />
                <Spacer type="small" />

                {formData.platform === 'inapp' && (
                    <TargetCarrieries
                        value={formData.target_carriers}
                        errors={errors.target_carriers}
                        showErrors={showErrors}
                        onChange={this.props.onFieldChange('target_carriers')}
                    />
                )}

                {formData.platform === 'inapp' && (
                    <React.Fragment>
                        <div className="ef5-ad-setup__form-field-wrapper">
                            <TargetDeviceLanguage
                                value={formData.target_device_language}
                                errors={errors.target_device_language}
                                showErrors={showErrors}
                                onChange={this.props.onFieldChange('target_device_language')}
                            />

                            <TargetDeviceLanguageExclude
                                value={formData.target_device_language_exclude}
                                errors={errors.target_device_language_exclude}
                                showErrors={showErrors}
                                onChange={this.props.onFieldChange(
                                    'target_device_language_exclude'
                                )}
                            />
                        </div>
                        <Spacer type="small" />
                    </React.Fragment>
                )}

                {formData.platform === 'inapp' && (
                    <React.Fragment>
                        {/* Deprecating IAB in favor of App store categories  */}
                        {adForm.campaign.isAppStoreCatEnabled === false && (
                            <React.Fragment>
                                <TargetIABCategories
                                    label="IAB App Categories (Include)"
                                    errors={errors.target_iab_categories}
                                    showErrors={showErrors}
                                    checkedCategories={formData.target_iab_categories}
                                    onChange={this.props.onFieldChange('target_iab_categories')}
                                />
                                <Spacer />
                            </React.Fragment>
                        )}

                        {/* Deprecating IAB in favor of App store categories  */}
                        {adForm.campaign.isAppStoreCatEnabled === false && (
                            <React.Fragment>
                                <TargetIABCategories
                                    label="IAB App Categories (Exclude)"
                                    errors={errors.target_iab_categories_exclude}
                                    showErrors={showErrors}
                                    checkedCategories={formData.target_iab_categories_exclude}
                                    onChange={this.props.onFieldChange(
                                        'target_iab_categories_exclude'
                                    )}
                                />
                                <Spacer />
                            </React.Fragment>
                        )}

                        {!tacticsEnabled && adForm.campaign.isAppStoreCatEnabled === true && (
                            <FormField
                                label={`App Category (${
                                    isAppCategoryExcluding ? 'Exclude' : 'Include'
                                })`}
                                errors={[].concat(
                                    errors.target_app_store_cat,
                                    errors.target_app_store_cat_exclude
                                )}
                                showErrors={showErrors}
                            >
                                <TargetAppStoreCategories
                                    placeholder="Select App Category"
                                    key={adForm.adPresetKeyCounter}
                                    includeValues={formData.target_app_store_cat}
                                    excludeValues={formData.target_app_store_cat_exclude}
                                    onChange={({ values, exclude }) => {
                                        if (exclude) {
                                            this.props.onFieldChange('target_app_store_cat')([]);
                                            this.props.onFieldChange(
                                                'target_app_store_cat_exclude'
                                            )(values);
                                        } else {
                                            this.props.onFieldChange('target_app_store_cat')(
                                                values
                                            );
                                            this.props.onFieldChange(
                                                'target_app_store_cat_exclude'
                                            )([]);
                                        }
                                    }}
                                />
                            </FormField>
                        )}
                    </React.Fragment>
                )}
                {formData.platform === 'inapp' && (
                    <React.Fragment>
                        <Spacer />
                        <TargetGenders
                            value={formData.target_genders}
                            errors={errors.target_genders}
                            showErrors={showErrors}
                            onChange={this.props.onFieldChange('target_genders')}
                            marginBottom={25}
                        />
                        <Spacer type="small" />
                        <TargetAgeGroups_EF_1418
                            value={formData.target_age_groups}
                            errors={errors.targetAgeGroup}
                            showErrors={showErrors}
                            onChange={this.props.onFieldChange('target_age_groups')}
                            marginBottom={25}
                        />
                        {this.props.andOrTargetingModule}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

function getDeviceOptions({ platform, hasBlackBerrySelected }) {
    if (platform === 'desktop') {
        return DESKTOP_DEVICE_OS_OPTIONS;
    }

    const targetDeviceOptions = INAPP_DEVICES_OS_OPTIONS;

    if (hasBlackBerrySelected) {
        targetDeviceOptions.push({ value: DeviceOSMapping.BlackBerry, label: 'BlackBerry' });
    }

    return targetDeviceOptions;
}

export function DeviceOS(props) {
    const hasBlackBerrySelected = _.includes(props.formData.target_device_os, DeviceOSMapping.BlackBerry);
    const targetDeviceOptions = getDeviceOptions({
        platform: props.formData.platform,
        hasBlackBerrySelected,
    });
    let inappOptions = INAPP_DEVICES_OS_OPTIONS;
    if (hasBlackBerrySelected) {
        inappOptions = _.concat(inappOptions, { value: DeviceOSMapping.BlackBerry, label: 'BlackBerry' });
    }

    const handleChange = ({ value, checked }) => {
        const existingValues = props.formData.target_device_os;
        let newValues = [];
        if (!checked) {
            newValues = _.filter(existingValues, exisitngValue => exisitngValue !== value);
        } else {
            newValues = _.concat(existingValues, value);
        }
        props.onFieldChange(newValues);
    };

    if (props.isCrossPlatformCampaign) {
        return (
            <div>
                {!props.isCTVCampaign && <Typography color="textSecondary">In-App / Mobile Web Targets</Typography>}
                {!props.isCTVCampaign && _.map(inappOptions, option => (
                    <FormControlLabel
                        key={option.value}
                        control={
                            <Checkbox
                                checked={_.includes(props.formData.target_device_os, option.value)}
                                onChange={e =>
                                    handleChange({
                                        value: option.value,
                                        checked: e.target.checked,
                                    })
                                }
                                color="primary"
                                disabled={
                                    _.includes(props.formData.target_device_os, option.value) &&
                                    props.formData.target_device_os.length === 1
                                }
                            />
                        }
                        label={option.label}
                    />
                ))}
                {!props.isCTVCampaign && <Typography color="textSecondary">Desktop Targets</Typography>}
                {!props.isCTVCampaign && _.map(DESKTOP_DEVICE_OS_OPTIONS, option => (
                    <FormControlLabel
                        key={option.value}
                        control={
                            <Checkbox
                                checked={_.includes(props.formData.target_device_os, option.value)}
                                onChange={e =>
                                    handleChange({
                                        value: option.value,
                                        checked: e.target.checked,
                                    })
                                }
                                color="primary"
                                disabled={
                                    _.includes(props.formData.target_device_os, option.value) &&
                                    props.formData.target_device_os.length === 1
                                }
                            />
                        }
                        label={option.label}
                    />
                ))}
                {props.isCTVCampaign && (
                        <Typography color="textSecondary">Connected TV</Typography>
                    )}
                {props.isCTVCampaign && _.map(CTV_DEVICE_OS_OPTIONS, option => (
                        <FormControlLabel
                            key={option.value}
                            control={
                                <Checkbox
                                    checked={_.includes(
                                        props.formData.target_device_os,
                                        option.value
                                    )}
                                    onChange={e =>
                                        handleChange({
                                            value: option.value,
                                            checked: e.target.checked,
                                        })
                                    }
                                    color="primary"
                                    disabled={
                                        _.includes(props.formData.target_device_os, option.value) &&
                                        props.formData.target_device_os.length === 1
                                    }
                                />
                            }
                            label={option.label}
                        />
                    ))}
                {props.deviceOsWarnings.hasWarning && (
                    <WarningText
                        severity={props.deviceOsWarnings.severity}
                        message={props.deviceOsWarnings.message}
                    />
                )}
            </div>
        );
    }
    return (
        <AdFormCheckboxList
            values={props.formData.target_device_os}
            minSelected={1}
            options={targetDeviceOptions}
            errors={props.errors}
            showErrors={props.showErrors}
            onChange={props.onFieldChange}
        />
    );
}

export function AppCategories(props) {
    const { formData, errors, showErrors, adForm } = props;

    const isAppCategoryExcluding = formData.target_app_store_cat_exclude.length > 0;

    return (
        <FormField
            label={`App Category (${isAppCategoryExcluding ? 'Exclude' : 'Include'})`}
            errors={[].concat(errors.target_app_store_cat, errors.target_app_store_cat_exclude)}
            showErrors={showErrors}
        >
            <TargetAppStoreCategories
                placeholder="Select App Category"
                key={adForm.adPresetKeyCounter}
                includeValues={formData.target_app_store_cat}
                excludeValues={formData.target_app_store_cat_exclude}
                onChange={({ values, exclude }) => {
                    if (exclude) {
                        props.onFieldChange('target_app_store_cat')([]);
                        props.onFieldChange('target_app_store_cat_exclude')(values);
                    } else {
                        props.onFieldChange('target_app_store_cat')(values);
                        props.onFieldChange('target_app_store_cat_exclude')([]);
                    }
                }}
            />
        </FormField>
    );
}

export default Targeting;

import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import toastr from 'toastr';
import numeral from 'numeral';
import { LEVEL_BY_NAME } from 'common/constants/forensiq-risk-levels';
import {
    VALID_WEATHER_CONDITION_QUALIFIER,
    VALID_WEATHER_CONDITION_TYPE,
    VALID_WEATHER_CONDITION_TIMEFRAME,
} from 'common/constants/weather-condition-targeting';
import { isString, isArray, isNumber, isDatetimeISOString } from 'utils/validate';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import {
    getRevenueModelToAdBillingTermMapping,
    getTotalMediaCost,
    calculateFirstPartyDataFee,
    calculatePlatformTechFee,
    checkIsNewScheduledWeightedRotationMode,
    CreativeRotationMethodMapping,
    BiddingStrategyMode,
    DeviceOSMapping,
} from 'states/resources/ads/business-logic';
import { RevenueModelMapping, CampaignTypeMapping } from 'states/resources/campaigns/business-logic';

const TOTAL_ROTATION_WEIGHT = 100;

const POSSIBLE_INTERVAL_UNITS = ['hours', 'days', 'weeks', 'ad'];

const MIN_VALID_DRAFT = {
    // --- Basics ---//
    name: 'ad name',
    timezone: 'UTC',
    start: '2016-03-16T20:00:00.000Z',
    end: '2016-04-25T19:59:59.000Z',
    notes: 'Hello notes',
    custom_fields: [],

    // --- Creative ---//
    creative: '<div>Hello world!</div>',
    rotation_rules: {
        mode: CreativeRotationMethodMapping.Single,
        weighted: [],
    },

    // --- Target ---//
    geotargets: [{ country: 'CA', include: [], exclude: [] }],
    target_age_groups: [1, 2],
    target_genders: ['M', 'F'],
    dayparts: [{ start: '00:00:00', end: '24:00:00' }],
    weekparts: [true, true, true, true, true, true, true],
    target_device_language: ['en'],
    target_app_store_cat: ['hello', 'world'],
    target_app_store_cat_exclude: ['foo', 'bar'],
    target_app_categories: [10, 17],
    geoboxes: [
        {
            E: -79.3750512599945,
            N: 43.67153457292017,
            S: 43.640236977395496,
            W: -79.435476064682,
            type: 'geobox',
        },
    ],

    geo_targeting_settings: {
        categories: [],
        custom_layers: [],
        category_layers: [],
        radius: 500,
        retarget: false,
        days_to_retarget: undefined,
    },

    target_device_os: [DeviceOSMapping.IOS],
    target_carriers: ['ALO'],

    // Deprecated in favour of fcaps
    max_user_frequency: null,
    // Replacement field for frequency cap
    fcaps: {
        imp: 4,
        interval_count: 1,
        interval_unit: 'days',
        start: Date.now(),
    },

    // --- Budget ---//
    max_cpm_rate_local: 1,

    max_total_impressions: 100,
    max_daily_impressions: 0,

    max_total_clicks: 0,
    max_daily_clicks: 0,

    max_total_spend_local: 0,
    max_daily_spend_local: 0,

    max_daily_billings: 0,
    max_total_billings: 0,

    // --- Revenue Managemenet ---//
    ef_billing_terms: 'CPM',
    billable_volume: 1000,
    billing_rate: 1,

    // --- Meta ---//
    comment: '',

    // --- Not in form ---//
    billing_type: 'fixed',
    paused: false,
    cpc_rate: 0,
    cpm_rate: 0,

    status: 'paused',
    max_cpm_rate: 0,
    restrict_ips: ['127.0.0.1'],
    primary_pacing: 'impressions',

    billing_enabled: true,
    forensiq_risk_cap: LEVEL_BY_NAME['allow_low_risk'].value,

    // First Party Data Cost
    fta_fee: 0,
    audience_fee: 0,
    thirdPartyFees: [],
};

const fcapsSchema = {
    imp(value, fcaps) {
        if (value === null) {
            // All 3 values must be null to be valid
            if (fcaps.interval_count !== null || fcaps.interval_unit !== null) {
                return '"imp", "interval_count" and "interval_unit" must all be null';
            }
            return;
        }

        if (typeof value !== 'number') {
            return '"imp" must be a number';
        }
    },
    interval_count(value, fcaps) {
        if (value === null) {
            // All 3 values must be null to be valid
            if (fcaps.imp !== null || fcaps.interval_unit !== null) {
                return '"imp", "interval_count" and "interval_unit" must all be null';
            }
            return;
        }

        if (typeof value !== 'number') {
            return '"interval_count" must be a number';
        }
    },
    interval_unit(value, fcaps) {
        if (value === null) {
            // All 3 values must be null to be valid
            if (fcaps.imp !== null || fcaps.interval_count !== null) {
                return '"imp", "interval_count" and "interval_unit" must all be null';
            }
            return;
        }

        if (typeof value !== 'string' || !_.includes(POSSIBLE_INTERVAL_UNITS, value)) {
            return `"interval_unit" must be a string, and one of "${POSSIBLE_INTERVAL_UNITS.join(
                ', '
            )}"`;
        }
    },
    start(value) {
        if (typeof value !== 'number') {
            return '"start" must be a unix timestamp (number of seconds since epoch)';
        }
    },
};

const geotargetsIncludeExcludeSchema = {
    city(value) {
        if (value === undefined) {
            return;
        }

        if (!isString().validate(value)) {
            return 'City is required';
        }
    },

    region(value) {
        if (value === undefined) {
            return;
        }

        if (!isString().validate(value)) {
            return 'Region is required';
        }
    },
};


const geotargetsSchema = {
    country(value) {
        if (!isString().validate(value)) {
            return 'Country is required';
        }
    },

    exclude(value) {
        if (!_.isArray(value)) {
            return 'Exclude must be an array';
        }

        return geotargetsIncludeExcludeSchema;
    },

    include(value) {
        if (!_.isArray(value)) {
            return 'Include must be an array';
        }

        return geotargetsIncludeExcludeSchema;
    },
};

const daypartsSchema = {
    start(value) {
        if (!moment.utc(value, 'HH:mm:ss').isValid()) {
            return 'Start is not valid';
        }
    },
    end(value) {
        if (!moment.utc(value, 'HH:mm:ss').isValid()) {
            return 'End is not valid';
        }
    },
};

const geoboxSchema = {
    E(value) {
        if (!_.isNumber(value)) {
            return 'E must be a number';
        }
    },
    N(value) {
        if (!_.isNumber(value)) {
            return 'N must be a number';
        }
    },
    S(value) {
        if (!_.isNumber(value)) {
            return 'S must be a number';
        }
    },
    W(value) {
        if (!_.isNumber(value)) {
            return 'W must be a number';
        }
    },
};

const customFieldsSchema = {
    key(value) {
        if (!value) {
            throw new Error('Custom field is missing a key');
        }
    },
    name(value) {
        if (!_.isString(value)) {
            return 'Required field';
        }
    },
    required(value) {
        if (!_.isBoolean(value)) {
            throw new Error('custom_fields.required is required');
        }
    },
    value(value, source) {
        if (source.required && !value) {
            return 'Required field';
        }
    },
};

const thirdPartyCostsSchema = {
    description(value) {
        if (!value) {
            return 'Description is required';
        }
    },
    billing_model(value) {
        if (!value) {
            return 'Billing Model is required';
        }

        if (!_.includes(['cpm', 'fixed'], value)) {
            return `Billing Model must be one of: 'cpm', 'fixed'`;
        }
    },
    fee(value) {
        if (value === undefined) {
            return 'Fee is required';
        }

        if (!_.isNumber(value)) {
            return 'Fee must be a number';
        }
    },
    billable(value) {
        if (value === undefined) {
            return 'Billable is required';
        }

        if (!_.isBoolean(value)) {
            return 'Cost must be a true or false';
        }
    },
};

export const ERRORS = {
    FTA_ENABLED_REQUIRED: 'Foot Traffic Attribution requires an "Enabled" or "Disabled" selection',
    FTA_PARTNER_ID_REQUIRED: 'Partner ID is required',
};



export const MIN_VALID_AD = {
    name: 'Levi Strauss Co Dockers CPM CAN Interstitial MOB EN',
    start: '2017-09-01T04:00:00.000Z',
    end: '2018-01-01T04:59:00.000Z',
    notes: '3,030,303',
    creative: ['8YF8AF'],
    ef_billing_terms: 'CPM',
    billing_type: 'fixed',
    billing_enabled: true,
    billable_volume: 3030303,
    max_total_impressions: 3031000,
    max_daily_impressions: 0,
    max_total_clicks: 0,
    max_daily_clicks: 0,
    max_total_spend_local: 0,
    max_daily_spend_local: 0,
    max_total_billings: 49999.9995,
    max_daily_billings: 0,
    max_user_frequency: 4,
    restrict_ips: [],
    dayparts: [],
    weekparts: [true, true, true, true, true, true, true],
    weekparts_local: null,
    dayparts_local: null,
    geotargets: [
        {
            country: 'CA',
            exclude: [],
            include: [],
        },
    ],
    geoboxes: [],
    target_carriers: [],
    target_connection_types: [],
    target_device_os: [DeviceOSMapping.IOS, DeviceOSMapping.Android],
    target_device_language: [],
    target_device_language_exclude: [],
    timezone: 'UTC',
    status: 'live',
    _etag: '868ed3f4ad12eec4f7b460874bb87adc',
    target_age_groups: [],
    target_genders: [],
    paused: false,
    retargeting_POI: {
        '2': [1440, 5],
    },
    interest_target: [],
    target_app_store_cat: [],
    target_app_store_cat_exclude: [],
    target_app_categories: [],
    target_iab_categories: [],
    target_iab_categories_exclude: [],
    geo_targeting_settings: {
        days_to_retarget: 60,
        retarget: true,
        target: true,
        radius: 500,
        categories: [],
        category_layers: [],
        custom_layers: [],
    },
    primary_pacing: 'impressions',
    cpm_rate: 16.5,
    cpc_rate: 0,
    media_cost_markup_rate: 0,
    billable_media_cost_markup_rate: 0,
    ubx_audiences: [],
    audiences: [],
    audience_exclude: [],
    max_cpm_rate_local: 10,
    billable_media_cost_margin_rate: 0,
    max_bid_cpm_local: 10,
    hidden: false,
    rotation_rules: {
        scheduled: [],
        weighted: [],
        mode: CreativeRotationMethodMapping.Single,
    },
    non_billable_volume: 0,
    billing_rate: 16.5,
    fta_fee: 0,
    audience_fee: 0,

    forensiq_risk_cap: LEVEL_BY_NAME['allow_low_risk'].value,
    thirdPartyFees: [],
    unalteredDuplicate: false,
    isStartSet: true,
    isEndSet: true,
};

export function getValidDraft(overrides = {}) {
    return {
        ...MIN_VALID_DRAFT,
        ...overrides,
    };
}

//-- validation rules --//
export function getDraftValidators_forPayload({
    campaignBillingEnabled,
    originalAd,
    thirdPartyFees,
    orgFtaPartnerId,
    validGeoLayers,
    revenueModel,
    stats = null,
    campaignBudgetAllocationMethod,
    techFee,
    campaignFtaVersion,
    campaignType,
}) {
    const rules = {
        //==========
        // Basic
        //==========
        platform() {
            // ???
        },

        name(value) {
            if (!isString(1).validate(value)) {
                return 'Name is required';
            }
        },

        timezone(value) {
            if (!isString(1).validate(value)) {
                return 'Timezone is required';
            }
        },

        start(value, source) {
            if (!isAdStartDate().validate(value)) {
                return 'Start date is required';
            }

            if (!originalAd) {
                return;
            }

            // Exit if date hasn't change
            if (originalAd.start === value) {
                return;
            }

            // Start date cannot be changed
            if (originalAd.isDelivering) {
                return `Start date cannot be changed from ${moment(originalAd.start).format(
                    'LLLL'
                )} after going live`;
            }

            if (moment.utc(value).isSameOrAfter(source.end)) {
                return 'Start date must come before End date';
            }
        },

        notes(value) {
            if (value === undefined) {
                return;
            }

            if (!isString().validate(value)) {
                return 'Notes must be a string';
            }
        },

        //==========
        // Creative
        //==========
        creative(value) {
            if (value === undefined || value.length === 0) {
                return;
            }
        },
        rotation_rules(value) {
            if (!value) {
                return 'Rotation rules are required';
            }
            if (value.mode === CreativeRotationMethodMapping.Weather) {
                let missingType = false;
                let missingQualifier = false;
                let missingAmount = false;
                let missingCreative = false;
                let missingTimeframe = false;

                _.each(value.weather, setting => {
                    if (!setting.isDefault) {
                        const regex = new RegExp(/^\-?[0-9]{1,}$/);
                        const isValid = regex.test(setting.amount);
                        if (!isValid) {
                            missingAmount = true;
                        }

                        if (!_.includes(VALID_WEATHER_CONDITION_QUALIFIER, setting.qualifier)) {
                            missingQualifier = true;
                        }

                        if (!_.includes(VALID_WEATHER_CONDITION_TYPE, setting.type)) {
                            missingType = true;
                        }

                        if (!_.includes(VALID_WEATHER_CONDITION_TIMEFRAME, setting.timeframe)) {
                            missingTimeframe = true;
                        }
                    }

                    if (!setting.markupId) {
                        missingCreative = true;
                    }
                });

                if (missingType) {
                    return 'Each rule requires a condition';
                }
                if (missingQualifier) {
                    return 'Each rule requires a qualifier';
                }
                if (missingAmount) {
                    return 'Each rule requires an amount';
                }
                if (missingCreative) {
                    return 'Each rule requires a creative';
                }
                if (missingTimeframe) {
                    return 'Each rule requires a timeframe';
                }
            } else if (value.mode === CreativeRotationMethodMapping.Scheduled) {
                let creativeMissing;
                let dateMissing;
                let invalidRange;

                _.forEach(value.scheduled, row => {
                    if (!row.start || !row.end) {
                        dateMissing = true;
                        return false;
                    } else if (!row.markup_id) {
                        creativeMissing = true;
                        return false;
                    } else if (row.start && row.end) {
                        if (moment(row.end).isSameOrBefore(row.start)) {
                            invalidRange = true;
                            return false;
                        }
                    }
                });

                if (!checkIsNewScheduledWeightedRotationMode(value)) {
                    if (creativeMissing) {
                        return 'Each row requires a creative';
                    }
                }

                if (dateMissing) {
                    return 'Each row requires a start and end date';
                }

                if (invalidRange) {
                    return "Make sure each row's end dates and times are after the start date and time";
                }
            } else if (
                !_.includes([CreativeRotationMethodMapping.Single, CreativeRotationMethodMapping.OptimizedForClicks, CreativeRotationMethodMapping.OptimizedForConversions, CreativeRotationMethodMapping.OptimizedForVideoCompletion], value.mode)
            ) {
                if (value.weighted.length === 0) {
                    return;
                }

                if (!_.every(value.weighted, weighted => weighted.weight > 0)) {
                    return 'All selected creatives must have a weighting greater than 0';
                }

                let totalWeight = _(value.weighted)
                    .map(weighted => weighted.weight)
                    .sum();
                if (totalWeight !== TOTAL_ROTATION_WEIGHT) {
                    return 'Creative rotation weighting must equal exactly 100%';
                }
            }
        },

        //==========
        // Targeting
        //==========
        geotargets(value) {
            if (!isArray(1).validate(value)) {
                return 'Geotarget is required, select at least one country';
            }

            if (!_.every(value, _.isPlainObject)) {
                return 'Geotarget is an invalid type';
            }

            return geotargetsSchema;
        },

        target_age_groups(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Target Age Groups must be a list';
            }

            return ageGroupSchema;
        },

        target_genders(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Target Genders must be a list';
            }

            return gendersSchema;
        },

        dayparts(value) {
            if (!value) {
                return;
            }

            return daypartsSchema;
        },

        dayparts_local(value) {
            if (!value) {
                return;
            }

            return daypartsSchema;
        },

        weekparts(value) {
            if (!value) {
                return;
            }

            if (_.isArray(value) && value.length !== 7) {
                return 'Weekparts must have 7 elements';
            }

            return weekpartsSchema;
        },

        weekparts_local(value) {
            if (!value) {
                return;
            }

            if (_.isArray(value) && value.length !== 7) {
                return 'Weekparts_local must have 7 elements';
            }

            return weekpartsSchema;
        },

        target_device_language(value) {
            if (value === undefined || value === null) {
                return;
            }

            if (_.isNumber(value)) {
                return 'Invalid type';
            }

            if (_.isArray(value) && value.length === 0) {
                return;
            }
            return targetDeviceLanguageSchema;
        },

        target_device_language_exclude() {
            return undefined;
        },

        target_app_store_cat(value) {
            if (value === undefined) {
                return;
            }

            return _.isString;
        },
        target_app_store_cat_exclude(value) {
            if (value === undefined) {
                return;
            }

            return _.isString;
        },

        target_app_categories(value) {
            if (value === undefined) {
                return;
            }

            return appCategorySchema;
        },

        target_iab_categories(value) {
            if (value === undefined) {
                return;
            }

            return iabCategorySchema;
        },

        target_iab_categories_exclude(value) {
            if (value === undefined) {
                return;
            }

            return iabCategorySchema;
        },

        geoboxes(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Geoboxes must be a list';
            }

            return geoboxSchema;
        },

        geo_targeting_settings(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isPlainObject(value)) {
                return 'Invalid geo targetting settings data type';
            }

            // New Ads should not allow deprecated layers to be selected
            if (!originalAd) {
                const errors = [];
                const layers = [].concat(value.custom_layers, value.category_layers).filter(x => x);

                _.each(layers, layerId => {
                    const layer = validGeoLayers[layerId];
                    if (layer && layer.deprecated) {
                        errors.push(layer.name);
                    }
                });

                if (errors.length > 0) {
                    return `Please remove deprecated POI Segments: ${errors.join(', ')}`;
                }
            }
        },

        target_device_os(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Target Device OS must be a list';
            }

            return deviceOsSchema;
        },

        //========
        // Budget
        //========
        max_cpm_rate_local(value, source) {
            if (source.bid_strategy_mode === 'automatic_bid_price') {
                return;
            }

            if (
                source.dealApplicationType === 'deal_only' &&
                source.use_deal_price_to_bid === true
            ) {
                return;
            }

            if (!_.isNumber(value) || !(value > 0)) {
                return 'Total Bid Price must be greater than $0.00';
            }

            // Total Cost must be greater than First and Third party fees
            let dataFees = source.fta_fee + thirdPartyFees;

            dataFees += source.audience_fee;

            if (value <= dataFees) {
                return 'Net Bid Price must be greater than $0.00 (view breakdown for details)';
            }
        },

        bid_strategy_mode(value) {
            if (!_.isString(value) || value.length === 0) {
                return 'Must be one of "fixed_bid_price" and "automatic_bid_price"';
            }
        },

        automatic_bid_price(value, source) {
            if (source.bid_strategy_mode === 'fixed_bid_price') {
                return;
            }

            const ad = source;

            return {
                max_ecpm_local(value, source) {
                    if (source.apply_max_ecpm && value === 0) {
                        return 'Total Bid Price Limit must be greater than $0.00';
                    }

                    let platformFee = calculatePlatformTechFee({ mediaCost: value, techFee });

                    let dataCost = calculateFirstPartyDataFee(ad);

                    let extraFees = thirdPartyFees + dataCost + platformFee;

                    if (source.apply_max_ecpm && value <= extraFees) {
                        return `Total Bid Price Limit must be greater than $${numeral(
                            extraFees
                        ).format('0,0.00')} CPM`;
                    }

                    // Total Cost must be greater than First and Third party fees
                    let dataFees = ad.fta_fee + thirdPartyFees;

                    dataFees += ad.audience_fee;

                    if (value <= dataFees) {
                        return 'Net Bid Price must be greater than $0.00 (view breakdown for details)';
                    }
                },

                optimizing_strategy(value) {
                    if (value.mode === BiddingStrategyMode.Performance && campaignType !== CampaignTypeMapping.CTV && value.max_ecpc_local === 0) {
                        return 'Max eCPC must be greater than $0.00';
                    } else if (value.mode === BiddingStrategyMode.Performance && campaignType === CampaignTypeMapping.CTV && value.max_ecpcv_local === 0) {
                        return 'Max eCPCV must be greater than $0.00';
                    }
                },
            };
        },

        // Goal
        max_daily_impressions(value, source) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }

            // Unlimited
            if (value === 0) {
                return;
            }

            if (source.ef_billing_terms === 'CPM') {
                const totalTarget = source.billable_volume + source.non_billable_volume;
                if (value > totalTarget) {
                    return 'Daily goal must be less than Total target';
                }
            }
        },

        max_total_impressions(value) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }
        },

        max_daily_clicks(value, source) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }

            // Unlimited
            if (value === 0) {
                return;
            }

            if (source.ef_billing_terms === 'CPC') {
                const totalTarget = source.billable_volume + source.non_billable_volume;
                if (value > totalTarget) {
                    return 'Daily goal must be less than Total target';
                }
            }
        },
        max_total_clicks(value) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }
        },

        max_daily_spend_local(value, source) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }

            // Unlimited
            if (value === 0) {
                return;
            }

            if (source.ef_billing_terms === 'billable_media_cost_markup') {
                const totalTarget = source.billable_volume + source.non_billable_volume;
                if (value > totalTarget) {
                    return 'Daily goal must be less than Total target';
                }
            } else if (source.ef_billing_terms === 'billable_media_cost_margin') {
                const totalTarget = getTotalMediaCost(source);
                if (value > totalTarget) {
                    return 'Daily goal must be less than Total target';
                }
            }
        },
        max_total_spend_local(value) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }
        },

        max_daily_billings(value, source) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }

            // Unlimited
            if (value === 0) {
                return;
            }

            if (source.ef_billing_terms === 'billable_media_cost_margin') {
                const totalTarget = source.billable_volume;
                if (value > totalTarget) {
                    return 'Daily goal must be less than Total target';
                }
            }
        },
        max_total_billings(value) {
            if (!isNumber(0).validate(value)) {
                return 'Must be a number greater than or equal to 0';
            }
        },

        //===================
        // Revenue Management
        //===================
        ef_billing_terms(term) {
            const revenueModelToMapping = getRevenueModelToAdBillingTermMapping();

            if (!_.includes(revenueModelToMapping[revenueModel], term)) {
                const message = `Revenue Model ${revenueModel} is not in sync with Billing Term ${term}`;
                toastr.error('Something went wrong, please notify an administrator');

                return message;
            }
        },

        billing_rate(value, source) {
            // if camping billing disabled, validation should not be run.
            if (campaignBillingEnabled === false) {
                return;
            }

            if (source.billing_enabled === false) {
                return;
            }

            if (source.ef_billing_terms === 'billable_media_cost_margin' && value <= 0) {
                return 'Must be higher than 0';
            }

            if (source.billing_enabled && value === 0) {
                return 'Must be higher than 0';
            }

            if (value === undefined) {
                return;
            }

            switch (source.ef_billing_terms) {
                case 'billable_media_cost_margin':
                case 'billable_media_cost_markup':
                case 'media_cost_markup':
                    if (value > 100) {
                        return 'Must be maximum 100%';
                    }

                    if (value < 0) {
                        return 'Must be minimum 0%';
                    }
                    break;
                case 'CPM':
                case 'CPC':
                default:
                    if (!isNumber(0).validate(value)) {
                        return 'Must be a number greater than or equal to 0';
                    }
            }
        },

        non_billable_volume(value, source) {
            const billingEnabled = campaignBillingEnabled && source.billing_enabled;

            if (billingEnabled) {
                return;
            }

            const useDisabledORCPMCPCRevenueModelAndAutoBudgetAllocation =
                (revenueModel === RevenueModelMapping.Disabled ||
                    revenueModel === RevenueModelMapping.CPMCPC) &&
                campaignBudgetAllocationMethod === 'auto';

            if (
                !isNumber(1).validate(value) &&
                !useDisabledORCPMCPCRevenueModelAndAutoBudgetAllocation
            ) {
                return 'Must be higher than 0';
            }

            if (stats && originalAd && value !== originalAd.non_billable_volume) {
                if (revenueModel === RevenueModelMapping.Disabled) {
                    if (source.ef_billing_terms === 'CPM') {
                        if (value < stats.impressions) {
                            return `Must be greater than delivered impressions (${formatNumber_whole(
                                stats.impressions
                            )})`;
                        }
                    }
                    if (source.ef_billing_terms === 'CPC') {
                        if (value < stats.clicks) {
                            return `Must be greater than delivered clicks (${formatNumber_whole(
                                stats.clicks
                            )})`;
                        }
                    }
                    if (source.ef_billing_terms === 'billable_media_cost_markup') {
                        if (value < stats.owner_total_media_cost_local) {
                            return `Must be greater than total media cost (${formatNumber_currency(
                                stats.owner_total_media_cost_local
                            )})`;
                        }
                    }
                }
            }
        },
        billable_volume(value, source) {
            const billingEnabled = campaignBillingEnabled && source.billing_enabled;

            if (billingEnabled === false && value === 0) {
                return;
            }

            if (billingEnabled === false && value > 0) {
                return 'Must be zero when Billing is off';
            }

            if (!isNumber(1).validate(value) && campaignBudgetAllocationMethod !== 'auto') {
                return 'Must be higher than 0';
            }

            if (stats && originalAd && value !== originalAd.billable_volume) {
                if (revenueModel === RevenueModelMapping.CPMCPC) {
                    if (source.ef_billing_terms === 'CPM') {
                        if (value + source.non_billable_volume < stats.impressions) {
                            return `Must be higher than delievered impressions (${formatNumber_whole(
                                stats.impressions
                            )})`;
                        }
                    }
                    if (source.ef_billing_terms === 'CPC') {
                        if (value + source.non_billable_volume < stats.clicks) {
                            return `Must be higher than delievered clicks (${formatNumber_whole(
                                stats.clicks
                            )})`;
                        }
                    }
                }

                if (revenueModel === RevenueModelMapping.AgencyMargin) {
                    if ((1 - source.billing_rate) * value < stats.owner_total_media_cost_local) {
                        return `Cannot be lower than delivered Total Cost (${formatNumber_currency(
                            stats.owner_total_media_cost_local
                        )})`;
                    }
                }

                if (revenueModel === RevenueModelMapping.TotalSpendMarkup) {
                    if ((1 + source.billing_rate) * value < stats.billings_local) {
                        return `Cannot be lower than delivered Total Cost (${formatNumber_currency(
                            stats.billings_local
                        )})`;
                    }
                }
            }
        },

        billing_enabled(value) {
            if (!_.isBoolean(value)) {
                return 'Must be a boolean';
            }
        },

        primary_pacing() {
            // if (isOneOf(['impressions', 'clicks', 'spend'])(value)) {
            //     return;
            // }
            // return 'Must be one of: Impressions, Clicks, or Spend';
        },

        ubx_audiences() {
            return;
        },
        audiences() {
            return value => {
                if (!value) {
                    return 'invalid value';
                }
            };
        },
        audience_exclude() {
            return value => {
                if (!value) {
                    return 'invalid value';
                }
            };
        },
        custom_fields(value) {
            if (!_.isArray(value)) {
                return 'invalid type';
            }
            if (value.length === 0) {
                return;
            }

            return customFieldsSchema;
        },
        forensiq_risk_cap(value) {
            if (!_.isNumber(value)) {
                throw new Error('expected a number 0-100 or -1');
            }
        },
        fcaps(value) {
            if (typeof value !== 'object') {
                return '"fcaps" must be an object';
            }

            if (campaignType === CampaignTypeMapping.DOOH) {
                const isValid = value.imp === null && value.interval_count === null && value.interval_unit === null;
                if (!isValid) {
                    return 'Frequency cap can only be unlimited for DOOH ads';
                }
            }

            return fcapsSchema;
        },
        max_user_frequency() {
            return;
        },
        fta_fee() {
            return;
        },
        fta(fta) {
            if (campaignFtaVersion === 2) {
                if (fta.enabled && !fta.location_list) {
                    return 'Location List is required';
                }
                return;
            }

            // If the current org does not have an FTA Partner ID set up, ignore validation
            if (!orgFtaPartnerId) {
                return;
            }

            if (fta.enabled === null) {
                return ERRORS.FTA_ENABLED_REQUIRED;
            }

            if (fta.enabled === false) {
                return;
            }

            if (fta.enabled && !fta.partner_id) {
                return ERRORS.FTA_PARTNER_ID_REQUIRED;
            }
        },
        thirdPartyFees(value) {
            if (!_.isArray(value)) {
                throw new Error('Third Party Fees should be an array');
            }

            return thirdPartyCostsSchema;
        },
        unalteredDuplicate() {
            return;
        },
        isStartSet() {
            return;
        },
        isEndSet() {
            return;
        },
    };

    return rules;
}

function ageGroupSchema(value) {
    if (!isOneOf([-1, 1, 2, 3, 4, 5, 6, 7, 8])(value)) {
        return 'Age must be one of: -1, 1, 2, 3, 4, 5, 6, 7, 8';
    }
}

function gendersSchema(value) {
    if (!isOneOf(['M', 'F', 'D'])(value)) {
        return 'Gender must be one of: M, F, D';
    }
}

function weekpartsSchema(value) {
    if (!_.isBoolean(value)) {
        return 'Weekpart must be a boolean';
    }
}

function appCategorySchema(value) {
    if (!_.isNumber(value)) {
        return 'App Category must be a number';
    }
}

function iabCategorySchema(value) {
    if (value.indexOf('IAB') === -1) {
        return 'Invalid IAB Category';
    }
}

const DEVICE_OSes = Object.values(DeviceOSMapping);

function deviceOsSchema(value) {
    if (
        !isOneOf(DEVICE_OSes)(value)
    ) {
        return `Device OS must be one of: ${DEVICE_OSes.join(', ')}`;
    }
}

function targetDeviceLanguageSchema(value) {
    if (!isString(1).validate(value)) {
        return 'Target Device Language is required';
    }
}

//-- Custom validation helper --//
function isAdStartDate() {
    return {
        validate(value) {
            const _isDateTimeISOString = isDatetimeISOString().validate(value); // reusing exisiting validation rule in utilility
            const _isNull = value === null;

            if (_isDateTimeISOString || _isNull) {
                // Null is a valid value; it means start as soon as possible
                return true;
            }

            return false;
        },
        message() {
            return 'Invalid date.';
        },
    };
}

function isOneOf(list) {
    return function(value) {
        return _.includes(list, value);
    };
}

import _ from 'lodash';
import UUID from 'uuid';
import $ from 'jquery';
import c from 'common/constants/flux-events';
import toastr from 'toastr';
import { GraphQLClient } from 'graphql-request';

import { createHttp, getNextBase } from 'utils/http'; // <---- next
import API from 'services/api';
import flags from 'containers/flags/service';

// Copied from the HTTP module
const handleSuccess = resolve => (data, status, xhr) => {
    resolve(data);
};

const handleError = (reject, requestMetadata) => (xhr, status) => {
    const error = {
        ...requestMetadata,
        message: xhr.statusText,
        code: xhr.status,
        body: xhr.responseJSON,
        bodyText: xhr.responseText,
    };

    if (xhr.status === 401) {
        toastr.error('Unauthorized');
    } else if (xhr.status === 412) {
        // alert('Precondition failed');
        toastr.error(
            'Resource was modified by another user. To prevent overwriting, your changes have not been saved. Please refresh the page and try again.'
        );
    } else if (xhr.status >= 500) {
        console.error(error);

        // } else if (xhr.status === 400) {
        // createServerErrorAsToastr(error);
    } else {
        console.error(xhr.responseText);
    }

    reject(new Error(JSON.stringify(error)));
};

// ------------
// Authentication
// ------------
export function init() {
    const http = createHttp();

    return async (dispatch, getState) => {
        dispatch({
            type: c.IMPERSONATION_MODAL__INIT,
        });

        const headers = {
            ...http._getHeaders(),

            // This is the whole reason for us skipping the HTTP module in favour of a one time exception.
            // We have to send a seperate auth token, and we don't anticipate having to do this anywhere else.
            Authorization: _.get(getState(), 'profile.impersonatorsToken.auth_token'),
        };
        const state = getState();

        try {
            let users;
            let organizations;

            const graphqlUrl = getNextBase() + 'graphql';
            const client = new GraphQLClient(graphqlUrl, {
                headers: {
                    ...http._getHeaders(),
                    Authorization: _.get(getState(), 'profile.impersonatorsToken.auth_token'),
                },
            });

            const query = `
            query getImpersonationOrgs {
                organizations {
                    id
                    name
                    users {
                        id
                        first_name
                        last_name
                        email
                        global_role
                        organization
                        suspended
                        third_party
                    }
                }
            }`;

            const data = await client.request(query);

            const allUsers = [];
            const allOrganizations = [];
            _.each(data.organizations, org => {
                const { users, ...rest } = org;
                allOrganizations.push(rest);
                allUsers.push(...users);
            });

            users = allUsers;
            organizations = allOrganizations;

            dispatch({
                type: c.IMPERSONATION_MODAL__INIT_SUCCESS,
                payload: {
                    users,
                    organizations,
                    ownOrgId: _.get(state, 'profile.authToken.organization.id'),
                },
            });
        } catch (error) {
            dispatch({
                type: c.IMPERSONATION_MODAL__INIT_FAILURE,
                payload: {
                    error,
                },
            });

            console.error(error);
        }
    };
}

export function refreshUser(userId) {
    const http = createHttp();

    return (dispatch, getState) => {
        dispatch({
            type: c.IMPERSONATION_MODAL__REFRESH_USER,
        });

        const headers = {
            ...http._getHeaders(),

            // This is the whole reason for us skipping the HTTP module in favour of a one time exception.
            // We have to send a seperate auth token, and we don't anticipate having to do this anywhere else.
            Authorization: _.get(getState(), 'profile.impersonatorsToken.auth_token'),
        };

        new Promise((resolve, reject) => {
            const fullUrl = getNextBase() + `users/${userId}`;

            $.ajax({
                type: 'get',
                url: fullUrl,
                headers: headers,
                contentType: 'application/json',
                success: handleSuccess(resolve),
                error: handleError(reject, { url: fullUrl, method: 'get' }),
            });
        })
            .then(user => {
                dispatch({
                    type: c.IMPERSONATION_MODAL__REFRESH_USER_SUCCESS,
                    payload: {
                        user,
                    },
                });
            })
            .catch(error => {
                dispatch({
                    type: c.IMPERSONATION_MODAL__REFRESH_USER_FAILURE,
                    payload: {
                        error,
                    },
                });

                console.error(error);
            });
    };
}

export function refreshOrganization(orgId) {
    const http = createHttp();

    return (dispatch, getState) => {
        dispatch({
            type: c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION,
        });

        const headers = {
            ...http._getHeaders(),

            // This is the whole reason for us skipping the HTTP module in favour of a one time exception.
            // We have to send a seperate auth token, and we don't anticipate having to do this anywhere else.
            Authorization: _.get(getState(), 'profile.impersonatorsToken.auth_token'),
        };
        const state = getState();

        new Promise((resolve, reject) => {
            const fullUrl = getNextBase() + `organizations/${orgId}`;

            $.ajax({
                type: 'get',
                url: fullUrl,
                headers: headers,
                contentType: 'application/json',
                success: handleSuccess(resolve),
                error: handleError(reject, { url: fullUrl, method: 'get' }),
            });
        })
            .then(organization => {
                dispatch({
                    type: c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_SUCCESS,
                    payload: {
                        organization,
                    },
                });
            })
            .catch(error => {
                dispatch({
                    type: c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_FAILURE,
                    payload: {
                        error,
                    },
                });

                console.error(error);
            });
    };
}

export function impersonate(userId) {
    return (dispatch, getState) => {
        dispatch({
            type: c.IMPERSONATION_MODAL__IMPERSONATION_START,
        });

        const http = createHttp();
        const headers = {
            ...http._getHeaders(),

            // This is the whole reason for us skipping the HTTP module in favour of a one time exception.
            // We have to send a seperate auth token, and we don't anticipate having to do this anywhere else.
            Authorization: _.get(getState(), 'profile.impersonatorsToken.auth_token'),
        };

        return new Promise((resolve, reject) => {
            const fullUrl = getNextBase() + `auth/impersonate/${userId}`;

            $.ajax({
                type: 'get',
                url: fullUrl,
                headers: headers,
                contentType: 'application/json',
                success: handleSuccess(resolve),
                error: handleError(reject, { url: fullUrl, method: 'get' }),
            });
        }).then(token => {
            const uuid = UUID.v4();

            // We don't want resources bleeding between impersonated sessions, just
            // in case it dirties up the impersonated user's view so we make sure to
            // clear session storage before an impersonation occurs.
            sessionStorage.clear();

            localStorage.setItem(uuid, JSON.stringify(token, null, 2));

            dispatch({
                type: c.IMPERSONATION_MODAL__IMPERSONATION_SUCCESS,
                payload: {
                    impersonatee: userId,
                },
            });

            if (window.location.pathname.includes('/admin/')) {
                window.location = `/admin/${token.organization.id}?token=${uuid}`;
            } else {
                window.location = `${window.location.pathname}?token=${uuid}`;
            }
        });
    };
}

export function unimpersonate() {
    return {
        type: c.IMPERSONATION_MODAL__UNIMPERSONATE,
        payload: {},
    };
}

export function setOrganization(id) {
    return {
        type: c.IMPERSONATION_MODAL__SET_ORG,
        payload: {
            id,
        },
    };
}

export function closeModal() {
    return {
        type: c.IMPERSONATION_MODAL__CLOSE,
        payload: {},
    };
}

export function openModal() {
    return {
        type: c.IMPERSONATION_MODAL__OPEN,
        payload: {},
    };
}

import _ from 'lodash';
import { combineReducers } from 'redux';
import c from 'common/constants/flux-events';
// import pagination from './pagination';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    assets: {},
    loadingAssets: false,
    showFileTypeError: false,
    // pagination: pagination(),
    tempAssets: [],
    failedAssets: [],
};

const filesRegex = /^\/campaigns\/\d+\/setup\/files\/?$/;
const parseRoute = path => filesRegex.test(path);

export const NAME = 'filesOverview';

export default function(state = {}, action) {
    switch (action.type) {
        case c.OVERVIEW_FILES__INIT: {
            const { campaignId } = action.payload;
            return findCacheState(campaignId, state, initialState);
        }

        case '@@router/LOCATION_CHANGE': {
            const isOverviewFilesUrl = parseRoute(action.path);

            if (!isOverviewFilesUrl) {
                return {
                    ...state,
                    uploadAssetsErrorMessage: '',
                    failedAssets: [],
                }
            }

            return {
                ...state,
                filterUrl: action.path,
            };
        }

        case c.OVERVIEW_FILES__FILE_LIST__FILTER: {
            return {
                ...state,
                isLoading: true,
                filterOptions: {
                    ...state.filterOptions,
                    [action.payload.filterType]: action.payload.value,
                },
            };
        }

        case c.SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS:
            return {
                ...state,
                loadingAssets: false,
            };

        case 'SYSTEM__CAMPAIGN_FILES__UPLOAD__ERROR':
            return {
                ...state,
                loadingAssets: false,
                uploadAssetsErrorMessage: action.payload.uploadAssetsErrorMessage,
            };

        case 'SYSTEM__CAMPAIGN_FILES__SET_FILE_TYPE_ERROR': {
            const payloadFiles = action.payload.files;
            const failedAssets = _.map(payloadFiles, file => {
                return {
                    name: file.name,
                    reason: 'Invalid File Type',
                };
            });

            return {
                ...state,
                failedAssets,
                uploadAssetsErrorMessage: '',
            };
        }

        case c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT: {
            return {
                ...state,
                loadingAssets: true,
                showFileTypeError: false,
                uploadAssetsErrorMessage: '',
                failedAssets: [],
            };
        }

        case c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT_FAIL: {
            // const failedAssets = [
            //     ...state.failedAssets,
            //     ...action.payload.failed
            // ];

            const payloadFiles = action.payload.assets;
            const assets = {
                ...state.assets,
                ...payloadFiles,
            };

            const failedAssets = state.failedAssets.concat([action.payload.failedAsset]);
            // const failedAssets = _.concat(state.failedAssets, action.payload.failedAsset);

            return {
                ...state,
                failedAssets,
                assets,
                loadingAssets: false,
            };
        }

        case c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT__PROGRESS: {
            const payloadFiles = action.payload.assets;
            const assets = {
                ...state.assets,
                ...payloadFiles,
            };

            return {
                ...state,
                assets,
                loadingAssets: true,
                showFileTypeError: false,
            };
        }

        case c.OVERVIEW_FILES__FILE_UPLOAD__SUBMIT__PROGRESS_COMPLETE: {
            const payloadFiles = action.payload.assets;
            const assets = {
                ...state.assets,
                ...payloadFiles,
            };

            return {
                ...state,
                assets,
                loadingAssets: false,
            };
        }

        case c.OVERVIEW_FILES__FILE_UPLOAD__CLEAR_TEMP: {
            const assetToRemove = action.payload.asset;
            const filteredAssets = _.omit(state.assets, assetToRemove);
            const newState = {
                ...state,
                assets: filteredAssets,
            };

            return newState;
        }

        default:
            return state;
        // const _pagination = pagination(state.pagination, action);
        // return {
        //     ...state,
        //     pagination: pagination(state.pagination, action)
        // };
    }
}

import _ from 'lodash';
import Moment from 'moment';
import numeral from 'numeral';
import { extendMoment } from 'moment-range';
import 'moment-timezone';
const moment = extendMoment(Moment);

import { getAsAbsolute } from 'services/resources/stats';
import { metricConfig } from 'services/metrics';

import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_wholeFixed,
} from 'utils/formatting';

function formatNumber_whole(number) {
    if (window.isNaN(number)) {
        return '0';
    }
    return numeral(number).format('0,0');
}

function formatValue(metricValue, formatType) {
    switch (formatType) {
        case 'thousands':
            return formatNumber_whole(metricValue);

        case 'percentage':
            return formatNumber_percentage(metricValue);

        case 'dollar':
            return formatNumber_currency(metricValue);

        case 'whole-fixed':
            return formatNumber_wholeFixed(metricValue, 2);

        default:
            return metricValue;
    }
}

export default function createPivotTableService() {
    return {
        generatePivotTableState,
        expandRow,
        collapseRow,
        sortColumn,
        addSplit,
        removeSplit,
        updateSplits,
    };

    function generatePivotTableState(state, stats) {
        const tree = _generateTree(state, stats);

        return {
            ...state,
            tree,
        };
    }

    function expandRow(state, rowId) {
        return _setExpanded(state, rowId, true);
    }

    function collapseRow(state, rowId) {
        return _setExpanded(state, rowId, false);
    }

    function sortColumn(state, column) {
        let order = 'asc';

        // toggle if same column
        if (state.sort.column === column.name) {
            order = state.sort.order === 'asc' ? 'desc' : 'asc';
        }

        return {
            ...state,
            sort: {
                column: column.name,
                order,
            },
        };
    }

    function addSplit(state, dimension) {
        return {
            ...state,
            splits: state.splits.concat(dimension),
        };
    }

    function removeSplit(state, split) {
        return {
            ...state,
            splits: _.filter(state.splits, s => s.name !== split.name),
        };
    }

    function updateSplits(state, splits) {
        const dimensionLookup = {};

        function indexDimensions(dimensions) {
            _.each(dimensions, dimension => {
                dimensionLookup[dimension.name] = dimension;
                if (dimension.children) {
                    indexDimensions(dimension.children);
                }
            });
        }

        indexDimensions(state.dimensions);

        const splitsUpdated = _.map(splits, splitName => dimensionLookup[splitName]);

        return {
            ...state,
            splits: splitsUpdated,
        };
    }

    // private functions
    function _setExpanded(state, rowId, isExpanded) {
        const tree = {
            ...state.tree,
            [rowId]: {
                ...state.tree[rowId],
                isExpanded,
            },
        };
        return {
            ...state,
            tree,
        };
    }
    function _findExpandedRows(tree) {
        const expandedRows = {};

        _.each(tree, node => {
            if (node.isExpanded === true) {
                expandedRows[node.id] = true;
            }
            if (node.isExpanded === false) {
                expandedRows[node.id] = false;
            }
        });

        return expandedRows;
    }
    function _generateTree(state, statRoot) {
        const expandedRows = _findExpandedRows(state.tree);
        const tree = {};

        const baseMetrics = _findMetrics(statRoot);

        function walk(node, depth) {
            if (!node) {
                return;
            }

            const id = _generateId(node);

            // if no split exists, it is the root node
            const lastSplit = _.last(node.split);
            const dimension = _.get(lastSplit, 'dimension', 'total');
            const group = _.get(lastSplit, 'group', 'total');
            const label = _.get(lastSplit, 'label');

            const columnData = _getColumnDataFromStat(baseMetrics, node);

            tree[id] = {
                id,
                depth,
                columnData,
                stats: getAsAbsolute(columnData),
                isExpanded: _getIsExpanded(id, expandedRows, node, depth),
                children: _.map(node.stats, s => _generateId(s)),
                split: node.split,
                attributes: node.attributes,
                dimension,
                group,
                label,
            };

            _.each(node.stats, s => walk(s, depth + 1));
        }

        // start at depth -1 so that root depth is ignored
        if (statRoot && statRoot.stats && statRoot.stats.length > 0) {
            walk(statRoot, -1);
        } else {
            walk({ ...baseMetrics, ...statRoot }, -1);
        }

        return tree;
    }
    function _generateId(stat) {
        if (_.get(stat, 'split.length', 0) === 0) {
            return 'root';
        }

        return _.map(stat.split, s => `${s.dimension}_${s.group}`).join('__');
    }

    function _getColumnDataFromStat(baseMetrics, stat) {
        const splits = {};
        _.each(stat.split, split => (splits[split.dimension] = split.group));

        const metrics = getAsAbsolute(_sumChildren(baseMetrics, stat));

        const out = {
            ...stat,
            ...splits,
            ...metrics,
        };

        return out;
    }

    function _sumChildren(baseMetrics, node) {
        if (node && node.stats && node.stats.length === 0) {
            return _.omit(node, ['stats', 'split', 'attributes']);
        }

        const metrics = { ...baseMetrics };

        _.each(node.stats, stat => {
            Object.keys(metrics).forEach(metric => {
                metrics[metric] += stat[metric] || 0;
            });
        });

        return metrics;
    }

    function _findMetrics(node) {
        const metrics = {};
        Object.keys(node).forEach(key => {
            if (typeof node[key] === 'number') {
                metrics[key] = 0;
            }
        });

        return metrics;
    }
    function _getIsExpanded(id, expandedRows, node, depth) {
        const hasChildren = _.get(node, 'stats.length', 0) > 0;

        if (expandedRows[id] === true && hasChildren) {
            return true;
        }
        if (expandedRows[id] === false && hasChildren) {
            return false;
        }

        // if a node has no children, do not specify an expanded state
        if (!hasChildren) {
            return undefined;
        }

        if (node.isExpanded !== undefined) {
            return node.isExpanded;
        }

        if (depth <= 0) {
            return true;
        }

        return false;
    }
}

export function formatMetrics(row, columns) {
    const columnData = {};

    _.each(columns, column => {
        if (metricConfig[column.name]) {
            columnData[column.name] = metricConfig[column.name].getFormattedValueFromRecord({
                statRecord: row.stats,
            });
            return;
        }

        let columnValue = row.stats[column.name];
        if (columnValue === undefined && column.defaultValue !== undefined) {
            columnValue = column.defaultValue;
        }

        columnData[column.name] = formatValue(columnValue, column.formatType, row);
    });

    return {
        ...row,
        columnData,
    };
}

// Decoupled formatting from the PivotTable widget
export function formatDimensions(row, splits, dictionary, campaign) {
    const columnData = {
        ...row.columnData,
        dimension: formatDimension(campaign, dictionary, row.dimension, row.group, row.label),
    };

    const splitsDict = {};
    _.each(row.split, split => {
        splitsDict[split.dimension] = split.group;
    });

    _.each(splits, column => {
        let thisColumnsSplit = _.find(row.split, split => split.dimension === column.name);
        let label = thisColumnsSplit ? thisColumnsSplit.label : row.group;

        columnData[column.name] = formatDimension(
            campaign,
            dictionary,
            column.name,
            splitsDict[column.name],
            label
        );
    });

    return {
        ...row,
        columnData,
    };
}

function formatDimension(campaign, dictionary, dimension, group, label) {
    if (group === undefined) {
        return;
    }

    switch (dimension) {
        case 'date':
            return group.split('T')[0];
        case 'month':
            return moment.utc(group).format('MMMM YYYY');
        case 'geo_country_region': {
            return formatGeoCountryRegion(group, dictionary);
        }
        case 'week_by_monday_to_sunday':
        case 'week_by_campaign_start_day':
            const campaignStartMoment = moment.utc(campaign.start);
            const weekStart = moment.utc(group);
            let startLabel;
            if (weekStart.isBefore(campaignStartMoment)) {
                startLabel = campaignStartMoment.format('MMM D');
            } else {
                startLabel = weekStart.format('MMM D');
            }
            const end = moment
                .utc(group)
                .add(6, 'days')
                .format('MMM D');
            return `${startLabel} - ${end}`;

        default:
            return _.get(dictionary, `${dimension}.${group}.value`) || label || group;
    }
}

export function formatGeoCountryRegion(group, dictionary) {
    const groupSplit = group.split('-');
    // CA-??
    if (groupSplit.length >= 2 && groupSplit[1] === '??') {
        const countryName = _.get(dictionary, `geo_country.${groupSplit[0]}.value`, '');
        return `Not Available (${countryName})`;
    }
    return _.get(dictionary, `geo_country_region.${group}.value`, group);
}

// Takes the raw stats response from the `POST /report` endpoint
export function recursivelyFindSplitGroupsByName(rawStats, dimensionName) {
    if (rawStats.stats.length === 0) {
        // Found the bottom, the split doesn't exist
        return [];
    }

    const idsFound = _.find(rawStats.stats, stat =>
        _.find(stat.split, sp => sp.dimension === dimensionName)
    );
    if (idsFound) {
        return _.map(
            rawStats.stats,
            s => _.find(s.split, sp => sp.dimension === dimensionName).group
        );
    }

    return _(rawStats.stats)
        .map(s => recursivelyFindSplitGroupsByName(s, dimensionName))
        .flatten()
        .uniq()
        .value();
}

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';

import { WarningIcon } from 'widgets-v5/icons';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: 5,
        color: '#2196f3',
    },
}));

const propTypes = {
    icon: PropTypes.element,
    content: PropTypes.string,
    onClick: PropTypes.func,
};

export class WarningBanner extends React.Component {
    static propTypes = propTypes;

    render() {
        return (
            <Banner
                {...this.props}
                className="banner__warning"
                icon={<WarningIcon className="banner__warning-icon" style={{ paddingRight: 5 }} />}
                content={this.props.content}
            />
        );
    }
}

export function InfoBanner(props) {
    const classes = useStyles();

    return (
        <Banner
            {...props}
            className="banner__info"
            icon={<InfoIcon className={classes.icon} fontSize="small" />}
            content={props.content}
        />
    );
}

export function InfoBannerSimple(props) {
    const classes = useStyles();

    return (
        <Banner
            {...props}
            className={`banner__info__simple ${props.className}`}
            icon={<InfoIcon className={classes.icon} fontSize="small" />}
            content={props.content}
        />
    );
}

function Banner(props) {
    return (
        <div onClick={props.onClick} className={classnames('banner', props.className)}>
            <div>{props.icon}</div>
            <div>{props.content}</div>
        </div>
    );
}

import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import classnames from 'classnames';

import { InlineBlockLoadGroup } from 'widgets-v5/load-group';
import { NeutralButton } from 'widgets-v5/buttons';
import InlineTooltip from 'widgets-v5/inline-tooltip';

const GlobalRefresh = createReactClass({
    displayName: 'GlobalRefresh',

    contextTypes: {
        globalRefresh: PropTypes.func,
    },

    propTypes: {
        isLoading: PropTypes.bool,
    },

    getInitialState() {
        return {
            isLoading: false,
            mounted: false,
        };
    },

    componentDidMount() {
        this.setState({
            mounted: true,
        });
    },

    refresh(e) {
        e.preventDefault();

        // Fake a 2 second loading time when isLoading is not
        // provided from the parent
        if (this.props.isLoading === undefined) {
            this.setState({
                isLoading: true,
            });

            setTimeout(() => {
                if (this.state.mounted) {
                    this.setState({
                        isLoading: false,
                    });
                }
            }, 2000);
        }

        this.context.globalRefresh();
        refresh();
    },

    render() {
        const shouldSpin = this.props.isLoading || this.state.isLoading;

        return (
            <InlineBlockLoadGroup size="small">
                <InlineTooltip tooltip="Refresh" position="bottom" top={30}>
                    <NeutralButton
                        icon={<i className="fa fa-refresh fa-fw" />}
                        // text="Refresh"
                        onClick={this.refresh}
                        heapEvent={this.props.heapEvent}
                    />
                </InlineTooltip>
            </InlineBlockLoadGroup>
        );
    },
});

let handlers = [];
export function onRefresh(handler) {
    handlers.push(handler);
}
export function offRefresh(handler) {
    handlers = handlers.filter(h => h !== handler);
}

function refresh() {
    _.each(handlers, handler => {
        if (_.isFunction(handler)) {
            handler();
        }
    });
}

export default GlobalRefresh;

export default {
    // Default values for draft
    draftDefaultValues: {
        name: '',
        bundle_ids: [],
        userInput: '',
    },
};

export const EN_279_defaultValues = {
    name: '',
    bundle_ids: [],
    domains: [],
    bundleIdInput: '',
    domainInput: '',
    advertiserId: '',
};

import _ from 'lodash';

import http from 'utils/http';

const actions = {
    forgot(email) {
        return (dispatch, getState) => {
            return http()
                .post(`password/forgot`, { email })
                .catch(err => {
                    console.error(`FORGOT password error`, err);

                    throw err;
                });
        };
    },
    reset(password, reset_token) {
        return (dispatch, getState) => {
            return http()
                .post(`password/reset`, { password, reset_token })
                .catch(err => {
                    console.error(`FORGOT password error`, err);

                    throw err;
                });
        };
    },
};

export default actions;

import { actions } from './reducer';
import { graphqlRequest } from 'utils/http/redux';
import c from 'common/constants/flux-events';

const initMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    if (action.type === actions.init.type) {
        const campaignId = action.payload;
        const viewBy = getState().campaignPageContainer.viewBy;
        const flightId = viewBy === 'campaign' ? undefined : viewBy;

        const res = await dispatch(fetchPageData(campaignId, flightId));
        dispatch(actions.initSuccess({ campaign: res.data.campaign, deals: res.data.deals }));
    }
};

const refreshMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    if (
        action.type === 'AD:PAUSED:SUCCESS' ||
        action.type === 'AD:UNPAUSED:SUCCESS' ||
        action.type === 'OVERVIEW_ADS__AD__PAUSE_SUCCESS' ||
        action.type === 'OVERVIEW_ADS__AD__UNPAUSE_SUCCESS' ||
        action.type === 'OVERVIEW__CAMPAIGN__PAUSE_SUCCESS' ||
        action.type === 'OVERVIEW__CAMPAIGN__UNPAUSE_SUCCESS' ||
        action.type === 'OVERVIEW_ADS__ARCHIVE_CONFIRMATION__CLOSE' ||
        action.type === 'campaignForm:submitSuccess' ||
        action.type === 'campaignPageContainer:changeView'
    ) {
        const campaignId = getState().adsOverviewV2.campaignId;
        const viewBy = getState().campaignPageContainer.viewBy;
        const flightId = viewBy === 'campaign' ? undefined : viewBy;

        const res = await dispatch(fetchPageData(campaignId, flightId));
        dispatch(actions.refreshSuccess(res.data.campaign));
    } else if (action.type === c.OVERVIEW_CAMPAIGN__DUPLICATE__SUCCESS) {
        const campaignId = action.payload.duplicateCampaignId;
        const viewBy = getState().campaignPageContainer.viewBy;
        const flightId = viewBy === 'campaign' ? undefined : viewBy;

        const res = await dispatch(fetchPageData(campaignId, flightId));
        dispatch(actions.refreshSuccess(res.data.campaign));
    }
};

function fetchPageData(campaignId, flightId) {
    return graphqlRequest({
        query: `
            query fetchAdsOverviewData($campaignId: Int, $flightId: String, $runTime: String) {
                campaign(id: $campaignId) {
                    id
                    name
                    paused
                    status
                    currency
                    currency_rate
                    isCrossPlatformCampaign
                    budget_allocation_method
                    billing_enabled
                    shouldHideFlexSegments
                    tech_fee
                    flights {
                        id
                        name
                        start
                        end
                    }
                    creatives {
                        id
                        name
                        third_party_vendors
                        audit_status
                        platforms
                        isArchived
                    }
                    ads {
                        id
                        name
                        status
                        paused
                        start
                        end
                        timezone
                        primary_pacing
                        max_daily_clicks
                        max_daily_impressions
                        max_daily_spend_local
                        max_total_impressions
                        max_total_clicks
                        max_total_impressions
                        max_total_spend_local
                        max_total_billings
                        max_bid_cpm_local
                        ef_billing_terms
                        use_front_load_pacing
                        unalteredDuplicate
                        max_cpm_rate_local
                        fta_fee
                        audience_fee
                        thirdPartyFees {
                            fee
                        }
                        bid_strategy_mode
                        automatic_bid_price {
                            max_ecpm_local
                        }
                        isStartSet
                        isEndSet
                        creative
                        isArchived
                        target_carriers
                        target_device_os
                        platforms
                        tactics_generators {
                            id
                            title
                            targeting {
                                ... on AudienceTargeting {
                                    id
                                    module
                                    draft {
                                        audiences
                                        audience_exclude
                                        audience_fee
                                        geo_targeting_settings {
                                            categories
                                            category_layers
                                            custom_layers

                                        }
                                    }
                                }
                            }
                        }
                        rotation_rules {
                            mode
                            weighted {
                                id
                                markup_id
                                weight
                            }
                            weather {
                                id
                                markupId
                            }
                            scheduled {
                                id
                                markup_id
                                start
                                end
                                weighted {
                                    id
                                    markup_id
                                    weight
                                }
                            }
                        }
                        metadata {
                            progress {
                                progressData {
                                    health
                                    goal
                                }
                            }
                            metrics {
                                impressions
                                clicks
                                ctr
                                win_rate
                                billings_local
                                owner_total_media_cost_local
                                owner_total_media_cost_local_ecpm
                                conv_overall
                                conv_overall_imp
                                conv_overall_click
                                conv_overall_total_cost_ecpa
                                conv_overall_revenue_ecpa
                            }
                            metricsDaily {
                                billings_local
                                owner_total_media_cost_local
                                win_rate
                            }
                            isStale
                            isDelivering
                        }
                        flight (flightId: $flightId, runTime: $runTime) {
                            id
                            name
                            start
                            end
                            total_cost_budget
                            metadata {
                                metrics {
                                    impressions
                                    clicks
                                    ctr
                                    win_rate
                                    billings_local
                                    owner_total_media_cost_local
                                    owner_total_media_cost_local_ecpm
                                    conv_overall
                                    conv_overall_imp
                                    conv_overall_click
                                    conv_overall_total_cost_ecpa
                                    conv_overall_revenue_ecpa
                                }
                                metricsDaily {
                                    billings_local
                                    owner_total_media_cost_local
                                    win_rate
                                }
                            }
                        }
                    }
                }
                deals {
                    id
                    app_name
                    currency
                    price_local
                    targeting {
                        target {
                            ad_id
                        }
                        deal_only {
                            ad_id
                        }
                    }
                }
            }
        `,
        variables: {
            campaignId: Number(campaignId),
            flightId,
            runTime: flightId ? undefined : 'live',
        },
    });
}

export const makeMiddlewares = deps => {
    return [initMw(deps), refreshMw(deps)];
};

export const middlewares = makeMiddlewares({});

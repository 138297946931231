import _ from 'lodash';

import { mapFirst, flatMap } from 'utils/enum';
import { calcTotalStats, getAsAbsolute, getMetricValue } from 'services/resources/stats';

import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
    formatNumber_wholeFixed,
} from 'utils/formatting';

// Date range picker
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { getRangePositionToNow } from 'common/date-calculator';

import chartExplorerSummarySelector from './chart-explorer-summary';
import heatmapSelector from './heatmap';
import { formatGeoCountryRegion } from 'widgets/pivot-table/service';
import { getEnvironmentSettings } from 'services/environment';

export default function selector(storeState, props) {
    const isInitialized = _.get(storeState, 'reportExplore.page.isInitialized', false);

    if (!isInitialized) {
        return {
            isInitialized,
        };
    }

    const { campaignId } = props.params;
    const report = _.get(storeState, `reportExplore`);
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);

    const componentItems = _.get(storeState, 'reportExplore.components.items', []);
    const selectedMetrics = _.get(storeState, 'reportExplore.metricSelector.selectedMetrics', []);
    const visibleMetrics = _.get(storeState, 'reportExplore.metricSelector.visibleMetrics', []);

    if (!report || !campaign || componentItems.length === 0) {
        return {};
    }

    const pivotTableState = _.get(storeState, `reportExplore.pivotTable`);
    const selectedDateRange = _.get(storeState, `reportExplore.dateRange`);
    const componentDict = _.reduce(
        componentItems,
        (acc, component) => ({
            ...acc,
            [component.type]: []
                .concat(acc[component.type])
                .concat(component)
                .filter(x => x),
        }),
        {}
    );
    const mode = _.get(report, `controls.tab`, 'dimension');
    
    let fixedCampaign = campaign;

    if (!campaign.start && !campaign.end) {
        // root issue located in urban get/<campaignId>, temp fix by setting correct start/end
        fixedCampaign.start = selectedDateRange.campaignStart;
        fixedCampaign.end = selectedDateRange.campaignEnd;
    }

    const dropDownOptions = createDateRangePickerOptions(storeState, fixedCampaign);

    const scopeComponents = scopeComponentsSelector(componentDict.scope, storeState, campaignId);

    const selectedScopeTab = _.get(storeState, `reportOverview.activeScopeTab`);
    const activeScopeTab = selectedScopeTab
        ? // If user manually set the active scope
          selectedScopeTab
        : // If user has not selected anything (report just initalized) and value is null
          scopeComponents && scopeComponents[0] && scopeComponents[0].grouping;

    const ret = {
        isInitialized,
        campaign: fixedCampaign,
        mode,
        dropDownOptions,
        selectedDateRange,
        pivotTableState,
        scopeComponents,
        activeScopeTab,

        isClient: isClient(storeState, props),
        campaignRelationship: getRelationship(storeState, props),
        dictionary: createDictionary(storeState, props),
        selectedMetric: getSelectedMetric(storeState, props),

        columns: getVisibleColumns(storeState, props),
        metrics: getVisibleMetrics(storeState, props),
        controls: controlsSelector(storeState, campaignId),
        metricSelector: metricSelectorSelector(storeState, campaignId),

        chartExplorerSummary: chartExplorerSummarySelector(
            componentDict.overview,
            storeState,
            props,
            campaignId
        ),

        dimensionFilter: dimensionFilterSelector(storeState, campaignId),
        scopeFilter: scopeFilterSelector(storeState, campaignId),

        dimensionComponents: dimensionComponentsSelector(
            componentDict.dimension,
            storeState,
            campaignId
        ),
        organizationTimezone: storeState.profile.organizationTimezone,
        selectedMetrics,
        visibleMetrics,
    };

    // This component might be hidden by campaign sharing settings
    if (componentDict.heatmap) {
        ret.heatmap = heatmapSelector(componentDict.heatmap, storeState, campaignId);
    }

    return ret;
}

function isClient(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const userOrganization = _.get(storeState, 'profile.organizationId');
    const campaignOwner = campaign.owner;

    return userOrganization !== campaignOwner;
}
function getRelationship(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`, {});
    const userOrganization = _.get(storeState, 'profile.organizationId');

    const isClient = !!_.find(campaign.clients, { organization: userOrganization });
    const isOwner = campaign.owner === userOrganization;

    return {
        isOwner,
        isClient,
    };
}
 
function getSelectedMetric(storeState) {
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`
    );
    const selectedMetric = _.find(metricComponentsConfig, { status: 'selected' });

    return selectedMetric;
}

// Merge the global dictionary with Geo's dictionary
function createDictionary(storeState) {
    const dictionary = _.get(storeState, `reportExplore.dictionary`);
    const pivotTableDictionary = _.get(
        storeState,
        `reportExplore.pivotTable.pivotTable.dictionary`,
        {}
    );
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`,
        []
    );

    const metricKeys = {};
    _.each(metricComponentsConfig, (config, i) => {
        metricKeys[config.metricType] = {
            value: config.metricType,
            order: i,
        };
    });

    return {
        ...dictionary,
        ...pivotTableDictionary,
        metricKeys,
    };
}

// Convert visible metrics to columns
function getVisibleColumns(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const columns = _.get(storeState, `reportExplore.pivotTable.columns`);
    const selectedMetrics = _.get(storeState, `reportExplore.metricSelector.selectedMetrics`);
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`,
        []
    );

    const metricLookup = {};
    _.each(metricComponentsConfig, metric => {
        metricLookup[metric.metricType] = metric;
    });

    const isBillingsVisible = _.includes(selectedMetrics, 'revenue')

    const visibleColumns = _(columns)
        .filter(column => {
            if (column.disabled) {
                return false;
            }

            // Hide Billing Rate column when Billings is hidden
            if (column.name === 'billing_rate' && !isBillingsVisible) {
                return false;
            }

            const isEnabled = _.includes(selectedMetrics, column.name);
            return isEnabled;
        })
        .map(column => ({
            ...metricLookup[column.name],
            name: column.name,
            label: _.get(metricLookup, `${column.name}.presentationName`, column.label),
            exportLabel: getColumnLabelForExport({ column, campaign, metricLookup }),
            formatType: _.get(metricLookup, `${column.name}.formatType`, column.formatType),
        }))
        .value();

    return visibleColumns;
}

function getVisibleMetrics(storeState) {
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`,
        []
    );

    const selectedMetrics = _.get(
        storeState,
        `reportExplore.metricSelector.selectedMetrics`,
        []
    );

    const filteredVisibleMetrics = _(metricComponentsConfig)
        .filter(m => _.includes(selectedMetrics, m.metricType))
        .map(m => m.metricType)
        .value();

    return filteredVisibleMetrics;
}
function controlsSelector(storeState, campaignId) {
    const dimensionFilter = _.get(storeState, `reportExplore.dimensionFilter`, {});
    const scopeFilter = _.get(storeState, `reportExplore.scopeFilter`, {});

    const componentItems = _.get(storeState, `reportExplore.components.items`, []);
    const component_overview = _.find(componentItems, { type: 'overview' });

    const stats_byDate = _.get(
        storeState,
        `resources.stats.campaigns.report.explore.${campaignId}.explore.${
            component_overview.id
        }.stats`
    );
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.controls.metricComponentsConfig`,
        []
    );

    let total;
    if (stats_byDate) {
        total = calcTotalStats(stats_byDate);
    }

    let totalFilteredStats;
    if (total) {
        totalFilteredStats = total;
    } else {
        totalFilteredStats = {
            presentationImpressions: '',
            presentationClicks: '',
            presentationOverallCtr: '',
            presentationOverallSpend: '',
            presentationOverallEcpm: '',
            presentationOverallEcpc: '',
            presentationOverallRevenue: '',
            presentationOverallErpm: '',
            presentationOverallErpc: '',
        };
    }

    const statsMetricsType_visibility = _.get(
        storeState,
        `reportExplore.controls.statsMetricsType_visibility`,
        []
    );

    const metricType = _.get(storeState, `reportExplore.controls.metricType`);
    const unitType = _.get(storeState, `reportExplore.controls.unitType`);
    const tab = _.get(storeState, `reportExplore.controls.tab`);
    const currency = _.get(storeState, `resources.campaigns.${campaignId}.attr.currency`);

    const allFilters = {
        ...dimensionFilter,
        ...scopeFilter,
    };

    const selectedItemCount = _.reduce(
        allFilters,
        (sum, filteredValues) => {
            if (_.isArray(filteredValues)) {
                return sum + filteredValues.length;
            }

            return sum;
        },
        0
    );

    const selectedMetric = _.find(metricComponentsConfig, { metricType });

    return {
        isFilterBarVisible: !!selectedItemCount,
        statsMetricsType_visibility,
        metricType,
        selectedMetric,
        unitType,
        tab,
        totalFilteredStats,
        currency,
        metricComponentsConfig,
    };
}

function metricSelectorSelector(storeState, campaignId) {
    const reportSession = _.get(storeState, `reportExplore`);
    const component_overview = _.find(_.get(reportSession, 'components.items'), {
        type: 'overview',
    });
    if (!component_overview) {
        return;
    }
    const stats_byDate = _.get(
        storeState,
        `resources.stats.campaigns.report.explore.${campaignId}.${component_overview.id}.stats`
    );
    const rootStats = _.get(
        storeState,
        `resources.stats.campaigns.report.explore.${campaignId}.${component_overview.id}`
    );
    let metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`,
        []
    );

    let totalFilteredStats;
    if (
        (stats_byDate && stats_byDate.group) ||
        (stats_byDate && getEnvironmentSettings().getReportingDatasource() !== 'snowflake')
    ) {
        totalFilteredStats = calcTotalStats(stats_byDate);
    } else if (rootStats) {
        totalFilteredStats = { ..._.omit(rootStats, ['id', 'stats', 'split']) };
    }

    const statsMetricsType_visibility = _.get(
        storeState,
        `reportExplore.metricSelector.statsMetricsType_visilbility`,
        []
    );

    const metricType = _.get(
        storeState,
        `reportExplore.metricSelector.currentSelectedMetricType`,
        'impressions'
    );
    const currency = _.get(storeState, `resources.campaigns.${campaignId}.attr.currency`);

    const selectedMetric = _.find(metricComponentsConfig, { metricType });

    // For subevents we want to remove the ones which have no data so that we don't show a bunch of unnecessary
    // items for the user to select.
    if (totalFilteredStats) {
        metricComponentsConfig = metricComponentsConfig.map(config => {
            if (config.isMultiSelect && totalFilteredStats) {
                // Check if the options should be filtered in case no data is present for them.
                const newConfig = _.omit(config, ['options']);
                newConfig.options = config.options.filter(option => !!totalFilteredStats[option.value]);
                return newConfig;
            }

            return config;
        });
    }

    return {
        statsMetricsType_visibility,
        metricType,
        selectedMetric,
        totalFilteredStats,
        currency,
        metricComponentsConfig,
    };
}

function dimensionFilterSelector(storeState, campaignId) {
    return filterSelector(storeState, campaignId, 'Dimensions', 'dimension');
}

function scopeFilterSelector(storeState, campaignId) {
    return filterSelector(storeState, campaignId, 'Custom Dimensions', 'scope');
}

function filterSelector(storeState, campaignId, title, componentType) {
    const groupings = _.omit(_.get(storeState, `reportExplore.${componentType}Filter`), [
        'cache',
        'cacheKey',
    ]);
    const dictionary = _.get(storeState, `reportExplore.dictionary`);

    const filterItems = _.map(groupings, (selectedGroups, grouping) => {
        const groups = _.map(selectedGroups, group => {
            return {
                id: group,
                name: group,
                title: _.get(dictionary, `${grouping}.${group}.value`, group),
            };
        });

        return {
            grouping,
            groupingPresentationName: _.get(
                dictionary,
                `${componentType}.${grouping}.value`,
                grouping
            ),
            groups,
        };
    });

    const isBarVisible = flatMap(filterItems, 'groups').length > 0;

    const shouldShowClearButton =
        _(filterItems)
            .map('groups')
            .filter(filterGroup => filterGroup.length > 0)
            .value().length > 1;

    return {
        title,
        filterItems,
        shouldShowClearButton,
        isBarVisible,
    };
}

function dimensionComponentsSelector(componentsRaw, storeState, campaignId) {
    return adGroupSelector(componentsRaw, storeState, campaignId);
}

function scopeComponentsSelector(componentsRaw, storeState, campaignId) {
    const components = adGroupSelector(componentsRaw, storeState, campaignId);
    return components;
}

export function adGroupSelector(componentsRaw, storeState, campaignId) {
    const reportSession = _.get(storeState, `reportExplore`, {});
    const dictionary = _.get(storeState, `reportExplore.dictionary`);
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`,
        []
    );

    return _.map(componentsRaw, function(component) {
        const grouping = component.groupings[0];

        const isLoading = _.get(
            storeState,
            `resources.stats.campaigns.report.explore.${campaignId}.${component.id}.isLoading`,
            false
        );
        const componentResource = _.get(
            storeState,
            `resources.stats.campaigns.report.explore.${campaignId}.${component.id}`
        );
        const componentSelection = _.get(reportSession, `${component.type}Filter.${grouping}`, []);
        const metricType = _.get(
            storeState,
            `reportExplore.metricSelector.currentSelectedMetricType`,
            'impressions'
        );

        if (!componentResource) {
            return null;
        }

        const presentationNames = {};
        _.each(componentResource.stats, stat => {
            const lastSplit = _.last(stat.split);
            let groupLabel;
            if (lastSplit.dimension === 'geo_country_region') {
                groupLabel = formatGeoCountryRegion(lastSplit.group, dictionary);
            } else {
                groupLabel = _.get(dictionary, `${grouping}.${lastSplit.group}.value`);
            }

            presentationNames[lastSplit.group] = groupLabel || lastSplit.label || 'Not Available';
        });
        const statsLookup = _.reduce(
            componentResource.stats,
            (acc, stat) => ({
                ...acc,
                [_.last(stat.split).group]: stat,
            }),
            {}
        );

        const distinctValues = _.reduce(
            componentResource.stats,
            (acc, stat) => {
                const dimension = stat.split[0].dimension;

                const dv = acc[dimension] ? acc[dimension] : [];

                return {
                    ...acc,
                    [dimension]: dv.concat(stat.split[0].group),
                };
            },
            {}
        );

        // Key of distinctValues, which is the grouping name ( Gender, age group, region etc).
        const distinctValues_key = Object.keys(distinctValues)[0];

        const distinctValues_value_temp = []; // use to store subsequence sort collection
        const distinctValues_value = _(distinctValues[distinctValues_key])
            .map(grouping_item => ({
                // Transforming [F, M, D] ---> [{}, {}, {}]
                grouping_item,
                groupingName: grouping,
                presentation: presentationNames[grouping_item],
                // presentation : _.get(dictionary, `${grouping}.${grouping_item}.value`, 'Not Available'),
                temp: '', // use to store temporary country name
            }))
            .sort((a, b) => {
                if (a.presentation.indexOf('Not Available') > -1) {
                    return 2;
                }
                if (a.presentation < b.presentation) {
                    return -1;
                } else {
                    return 1;
                }
                /*
                if (
                    a.grouping_item === 'unknown' ||
                    b.grouping_item === 'unknown' ||
                    a.presentation.indexOf('Not Available') > -1 ||
                    b.presentation.indexOf('Not Available') > -1
                ) {
                    return 1;
                }
                return a.presentation.localeCompare(b.presentation);
                */
            }) // Sort alphabatically
            .map(grouping_item => {
                // For geo_country we want to sort all the unknown by contry,
                // so we push all the Unknown into the temp array to be sorted again later
                if (
                    grouping_item.groupingName === 'geo_country_region' &&
                    grouping_item.presentation === 'Not Available'
                ) {
                    const countryCode = grouping_item.grouping_item.split('-')[0];
                    const countryName = _.get(
                        dictionary,
                        `geo_country.${countryCode}.value`,
                        'Not Available'
                    );
                    const grouping_item_new = { ...grouping_item, temp: countryName };
                    distinctValues_value_temp.push(grouping_item_new);
                    return void 0;
                } else {
                    return grouping_item;
                }
            })
            .filter(x => x) // removed undefined items
            .value();

        // Sort all the Unknown by country name.
        const distinctValues_value_tempSorted = distinctValues_value_temp
            .sort((a, b) => a.temp.localeCompare(b.temp))
            .map(grouping_item => ({
                ...grouping_item,
                presentation: 'Not Available (' + grouping_item.temp + ')',
            }));

        // Merge the first sort and second sort.
        const distinctValues_value_final = distinctValues_value.concat(
            distinctValues_value_tempSorted
        );

        const distinctValues_sorted = {
            ...componentResource.distinctValue,
            distinctValues_key: distinctValues_value_final,
        };

        const groups = mapFirst(distinctValues_sorted, []).map(item => {
            const dv = item.grouping_item;
            const presentationName = item.presentation;
            const stat = statsLookup[dv];

            if (!stat) {
                return {
                    id: dv,
                    // When 'Not Available', always hide
                    visible: presentationName === 'Not Available' ? false : true,
                    grouping: grouping,
                    presentationName: presentationName,
                    selected: _.includes(componentSelection, dv),
                    statsMetrics: '-',
                    metricValue: 0,
                };
            }

            const statAsAbsolute = getAsAbsolute(stat);
            const metricValue = getMetricValue({
                metricName: metricType,
                statRecord: statAsAbsolute,
            });

            const metricConfig = _.find(metricComponentsConfig, c => c.metricType === metricType);
            const statsMetrics_presentation = metricConfig
                ? formatValue(metricValue, metricConfig.formatType)
                : '';

            let visibility = true;
            // Hide 'Not Available' when metric value is 0
            if (presentationName === 'Not Available' && metricValue === 0) {
                visibility = false;
            }

            return {
                id: dv,
                visible: visibility,
                presentationName: presentationName,
                selected: _.includes(componentSelection, dv),
                statsMetrics: statsMetrics_presentation,
                metricValue,
            };
        });

        const totalMetricValue = _.reduce(groups, (sum, group) => sum + group.metricValue, 0);

        const groupsWithBarGraphLength = _.map(groups, group => ({
            ...group,
            barGraphLength:
                group.metricValue === 0 ? 0 : (group.metricValue / totalMetricValue) * 100,
        }));

        const title =
            component.title ||
            component.groupings
                .map(grouping => _.get(dictionary, `${component.type}.${grouping}.value`, grouping))
                .join(' x ');

        return {
            id: component.id,
            title,
            grouping,
            groups: groupsWithBarGraphLength,
            isLoading,
            component,
        };
    }).filter(x => x);
}

function formatValue(metricValue, formatType) {
    switch (formatType) {
        case 'thousands':
            return formatNumber_whole(metricValue);

        case 'percentage':
            return formatNumber_percentage(metricValue);

        case 'dollar':
            return formatNumber_currency(metricValue);

        case 'whole-fixed':
            return formatNumber_wholeFixed(metricValue, 2);

        default:
            return metricValue;
    }
}

function createDateRangePickerOptions(storeState, campaign) {
    const { start, end } = campaign;

    let campaignFlightInRelationToNow = null;
    if (!campaign.start && !campaign.end && campaign.ads && campaign.ads.length) {
        // All ads in this campaign are deleted or archived which is why this campaign
        // has no start and end date. We should instead fetch the start and end
        // dates from all ads.
        const ads = campaign.ads;

        const start = _(ads)
            .map('start')
            .compact()
            .min();
        const end = _(ads)
            .map('end')
            .compact()
            .max();

        campaignFlightInRelationToNow = getRangePositionToNow({ start, end });
    } else {
        campaignFlightInRelationToNow = getRangePositionToNow(campaign);
    }

    const startDateAsMoment = moment.utc(start);
    const endDateAsMoment = moment.utc(end);

    const formattedStartDate = startDateAsMoment.format();
    const formattedEndDate = endDateAsMoment.format();

    const dropDownOptions = (() => {
        switch (campaignFlightInRelationToNow) {
            case 'future': {
                return [{ title: 'Entire Campaign', start, end }];
            }

            case 'past': {
                const monthsBetween = getMonthsBetween(startDateAsMoment, endDateAsMoment);
                const firstOptionGroup = [
                    {
                        title: 'Entire Campaign',
                        start: startDateAsMoment.format(),
                        end: startDateAsMoment.format(),
                    },
                ];

                return [].concat(firstOptionGroup, monthsBetween, [{ title: 'Custom' }]);
            }

            case 'present': {
                const campaignToDate = [
                    {
                        title: 'Campaign to date (Live Stats)',
                        start: formattedStartDate,
                        end: formattedEndDate,
                    },
                ];
                const campaignToYesterday = [
                    {
                        title: 'Campaign to Yesterday',
                        start: formattedStartDate,
                        end: moment
                            .utc()
                            .subtract(1, 'day')
                            .endOf('day')
                            .format(),
                    },
                ];
                const monthsBetween = getMonthsBetween(startDateAsMoment, endDateAsMoment);

                return [].concat(campaignToDate, campaignToYesterday, monthsBetween, [
                    { title: 'Custom' },
                ]);
            }
        }
    })();

    return dropDownOptions;
}

function getMonthsBetween(start, end) {
    const monthsBetween = [];
    const endOfEndingMonth = end.endOf('month');
    const monthIncrement = start;

    let i = 0;

    while (endOfEndingMonth >= monthIncrement) {
        if (i > 100) {
            // throw new Error('The dates specified are too far apart.');
            break;
        }

        monthsBetween.push({
            title: monthIncrement.format('MMMM, YYYY'),
            start: monthIncrement.startOf('month').format(),
            end: monthIncrement.endOf('month').format(),
        });

        monthIncrement.add(1, 'month');

        i++;
    }

    return monthsBetween;
}

export function getColumnLabelForExport({ column, campaign, metricLookup }) {
    const label = _.get(metricLookup, `${column.name}.presentationName`, column.label);

    if (
        campaign &&
        campaign.conversions &&
        campaign.conversions.length > 0 &&
        _.includes(column.name, 'conv_')
    ) {
        const conversionNameMapping = {};

        _.each(campaign.conversions, ({ event_name, reporting_name }) => {
            conversionNameMapping[event_name] = reporting_name;
        });

        _.each(campaign.advertiserConversions, ({ event_name, reporting_name }) => {
            conversionNameMapping[`adv_${event_name}`] = reporting_name;
        });

        if (_.includes(column.name, 'conv_overall')) {
            return label;
        }

        let mappedName = '';

        if (_.includes(column.name, 'conv_total_cost_ecpa_')) {
            mappedName = column.name.split('conv_total_cost_ecpa_').join('');
            return `${conversionNameMapping[mappedName]}: ${label}`;
        }
        if (_.includes(column.name, 'conv_revenue_ecpa_')) {
            mappedName = column.name.split('conv_revenue_ecpa_').join('');
            return `${conversionNameMapping[mappedName]}: ${label}`;
        }
        if (_.includes(column.name, 'conv_total_click_')) {
            mappedName = column.name.split('conv_total_click_').join('');
            return `${conversionNameMapping[mappedName]}: ${label}`;
        }
        if (_.includes(column.name, 'conv_total_imp_')) {
            mappedName = column.name.split('conv_total_imp_').join('');
            return `${conversionNameMapping[mappedName]}: ${label}`;
        }
        if (_.includes(column.name, 'conv_total_')) {
            mappedName = column.name.split('conv_total_').join('');
            return `${conversionNameMapping[mappedName]}: Total`;
        }

        return `(${conversionNameMapping[column.name]}) ${label}`;
    }

    return label;
}

import flags from 'containers/flags/service';

export function handleChangeIASContextualTargeting() {
    return {
        description: 'Handle IAS Contextual Targeting Change',
        target(state, action) {
            return 'AD_FORM__IAS_CONTEXTUAL_TARGETING__CHANGE' === action.type;
        },
        reduce(state, action) {
            const { values } = action.payload;

            return updateIASContextualTargeting(state, values);
        },
    };
}

export function deriveIASContextualTargeting() {
    return {
        description: 'Reset IAS Contextual Targeting when platform is inapp',
        target(state, action, prevState) {
            return /^AD_FORM__/.test(action.type) && state.draft && prevState.draft;
        },
        reduce(state, action) {
            if (state.draft.platform === 'inapp') {
                return updateIASContextualTargeting(state, []);
            }

            return state;
        },
    };
}

function updateIASContextualTargeting(state, values) {
    return {
        ...state,
        draft: {
            ...state.draft,
            ias: {
                ...state.draft.ias,
                contextualTargeting: {
                    ...state.draft.ias.contextualTargeting,
                    value: values,
                },
            },
        },
    };
}

import { fetchComponentData } from './actions';

const initMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    const re = /\/campaigns\/[0-9]*\/report/;
    const isReportPage = re.test(window.location.pathname);

    const reFTA = /foot-traffic-attribution/;
    const isFTAPage = reFTA.test(window.location.pathname);
    if (action.type === 'campaignPageContainer:changeView' && isReportPage && !isFTAPage) {
        const campaignId = getState().campaignPageContainer.campaign.id;
        fetchComponentData(campaignId, dispatch, getState);
    }
};

export const makeMiddlewares = deps => {
    return [initMw(deps)];
};
export const middlewares = makeMiddlewares({});

import _ from 'lodash';
import React from 'react';
import shallowCompare from 'utils/shallow-compare';
import Typography from '@mui/material/Typography';
import { isInternalUser } from 'states/profile/business-rules';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import { IAS_FRAUD_PREVENTION_RISK_MAPPING } from 'common/constants/ias-fraud-prevention';
import { IAS_VIEWABILITY_OPTIONS } from 'common/constants/ias-viewability';
import { IAS_GROUPM_VIEWABILITY_MAPPING } from 'common/constants/ias-groupm-viewability';
import { IAS_PUBLICIS_VIEWABILITY_MAPPING } from 'common/constants/ias-publicis-viewability';
import {
    IAS_BRAND_SAFETY_CATEGORIES,
    IAS_BRAND_SAFETY_OPTIONS,
} from 'common/constants/ias-brand-safety';

import IASFraudPrevention from './modules/ias-fraud-prevention';
import IASViewability from './modules/ias-viewability';
import IASBrandSafety from './modules/ias-brand-safety';

const IAS_VIEWABILITY_OPTIONS_MAPPING = {};
_.each(IAS_VIEWABILITY_OPTIONS, option => {
    IAS_VIEWABILITY_OPTIONS_MAPPING[option.value] = option;
});

export class ThirdPartyVerificationSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    getGoalType = () => {
        const { ad } = this.props;
        const mapping = {
            CPM: 'Impressions',
            CPC: 'Clicks',
            billable_media_cost_markup: 'Total Cost',
        };

        return mapping[ad.ef_billing_terms];
    };

    getPacingValue = term => {
        const { ad } = this.props;

        const termMapping = {
            CPM: 'max_daily_impressions',
            CPC: 'max_daily_clicks',
            billable_media_cost_markup: 'max_daily_spend_local',
            billable_media_cost_margin: 'max_daily_billings',
        };

        if (ad.ef_billing_terms === term && !ad[termMapping[term]]) {
            return 'Even Pacing';
        }

        if (!ad[termMapping[term]]) {
            return 'Auto';
        }

        switch (term) {
            case 'CPM':
                return formatNumber_whole(ad.max_daily_impressions);
            case 'CPC':
                return formatNumber_whole(ad.max_daily_clicks);
            case 'billable_media_cost_markup':
                return formatNumber_currency(ad.max_daily_spend_local);
            case 'billable_media_cost_margin':
                return formatNumber_currency(ad.max_daily_billings);
        }
    };

    getIasBrandSafetyRiskLabels = () => {
        const { ad } = this.props;
        const labels = _.map(IAS_BRAND_SAFETY_CATEGORIES, category => {
            const value = _.get(ad, `ias.brandSafety.${category.value}.value`);
            const option = _.find(IAS_BRAND_SAFETY_OPTIONS, { value });
            if (value === 0) {
                return;
            }
            let selectionLabel;
            if (option) {
                selectionLabel = option.label;
            }

            return `${category.label}: ${selectionLabel}`;
        }).filter(label => label);

        return labels;
    };

    render() {
        const { ad } = this.props;
        const brandSafetyLabels = this.getIasBrandSafetyRiskLabels();

        const { fraudPrevention, viewability, groupmViewability, publicisViewability } = ad.ias;

        const isViewabilityOff =
            viewability.value === 0 &&
            groupmViewability.value === 0 &&
            publicisViewability.value === 0;

        return (
            <div className="media-plan-form__overview-table-data">
                <div>
                    <Typography gutterBottom>
                        {' '}
                        {`Fraud Prevention: ${
                            IAS_FRAUD_PREVENTION_RISK_MAPPING[fraudPrevention.risk.value]
                        }`}
                    </Typography>
                </div>
                <div>
                    {isInternalUser() ? (
                        <React.Fragment>
                            {isViewabilityOff ? (
                                <Typography>Viewability: Off</Typography>
                            ) : (
                                <React.Fragment>
                                    {viewability.value !== 0 && (
                                        <Typography gutterBottom>
                                            {`Viewability: ${
                                                IAS_VIEWABILITY_OPTIONS_MAPPING[viewability.value]
                                                    .label
                                            }`}
                                        </Typography>
                                    )}
                                    {groupmViewability.value !== 0 && (
                                        <Typography gutterBottom>
                                            {`GroupM Performance Matching: ${
                                                IAS_GROUPM_VIEWABILITY_MAPPING[
                                                    groupmViewability.value
                                                ]
                                            }`}
                                        </Typography>
                                    )}
                                    {publicisViewability.value !== 0 && (
                                        <Typography gutterBottom>
                                            {`Publicis Performance Matching: ${
                                                IAS_PUBLICIS_VIEWABILITY_MAPPING[
                                                    publicisViewability.value
                                                ]
                                            }`}
                                        </Typography>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <Typography gutterBottom>
                            {`Viewability: ${
                                IAS_VIEWABILITY_OPTIONS_MAPPING[viewability.value].label
                            }`}
                        </Typography>
                    )}
                </div>
                <div>
                    {brandSafetyLabels.length === 0 ? (
                        <Typography>Brand Safety: Off</Typography>
                    ) : (
                        <React.Fragment>
                            <Typography gutterBottom>Brand Safety</Typography>
                            {_.map(brandSafetyLabels, label => (
                                <Typography>{label}</Typography>
                            ))}
                        </React.Fragment>
                    )}
                    <Typography>
                        {ad.ias.brandSafety.unrateable.value === 1
                            ? 'Exclude unrated content: On'
                            : 'Exclude unrated content: Off'}
                    </Typography>
                </div>
            </div>
        );
    }
}

export const ThirdPartyVerificationForm = props => {
    const { ad, ads, campaign, onSave } = props;

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Third Party Verification"
            fields={['ias']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
            columnName="Third Party Verification"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <React.Fragment>
                        <IASFraudPrevention
                            draft={draft}
                            ad={ad}
                            ads={ads}
                            updateDraft={updateDraft}
                            isCrossPlatformCampaign={campaign.isCrossPlatformCampaign}
                        />
                        <IASViewability
                            draft={draft}
                            ad={ad}
                            ads={ads}
                            updateDraft={updateDraft}
                            isCrossPlatformCampaign={campaign.isCrossPlatformCampaign}
                        />
                        <IASBrandSafety
                            draft={draft}
                            ad={ad}
                            ads={ads}
                            updateDraft={updateDraft}
                            isCrossPlatformCampaign={campaign.isCrossPlatformCampaign}
                        />
                    </React.Fragment>
                );
            }}
        </BaseColumnForm>
    );
};

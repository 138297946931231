import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import UserActions from 'states/resources/users/actions';
import MultipleSelector from 'widgets-v5/multiple-selector';

import selector from './selector';

class UsersByOrgSelector extends React.Component {
    constructor(props) {
        super(props);

        this.getOrgUsers = _.throttle(this.getOrgUsers.bind(this), 1000);

        this.state = {
            isLoading: false,
            users: void 0,
            userIds: [],
            selections: [],
        };

        this._hasMounted = false;
    }

    getOrgUsers(orgId) {
        const { dispatch } = this.props;
        if (orgId) {
            if (this._hasMounted) {
                this.setState({ isLoading: true });
                dispatch(UserActions.getByOrgId(orgId)).then(
                    users => {
                        const userIds = _(users)
                            .map(v => v.id)
                            .value();
                        const selections = users.map(user => {
                            const firstName = _.get(user, `first_name`, '');
                            const lastName = _.get(user, `last_name`, '');
                            const email = _.get(user, `email`, '');
                            return {
                                value: user.id,
                                label: `${firstName} ${lastName} (${email})`,
                            };
                        });
                        this.setState({
                            isLoading: false,
                            userIds,
                            users,
                            selections,
                        });
                    },
                    err => {
                        this.setState({
                            isLoading: false,
                            users: void 0,
                            userIds: [],
                            selections: [],
                        });
                        console.error('Http Error: ', err);
                    }
                );
            }
        }
    }

    componentDidMount() {
        this._hasMounted = true;
        this.getOrgUsers(this.props.orgId);
    }

    componentWillUnMount() {
        this._hasMounted = false;
        // promise cancel is not implimented
        //this.getOrgUsers.cancel();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.orgId !== nextProps.orgId) {
            this.getOrgUsers(nextProps.orgId);
        }
    }

    render() {
        if (this.state.isLoading) {
            return <div>isLoading</div>;
        }

        return (
            <div className="users-by-org-selector">
                <MultipleSelector
                    value={this.props.value}
                    clearable={true}
                    placeholder={'Select a User'}
                    options={this.state.selections}
                    onChange={values => {
                        this.props.onChange(values);
                    }}
                />
            </div>
        );
    }
}

export default connect(selector)(UsersByOrgSelector);

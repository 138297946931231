import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions, REDUCER_KEY } from './redux';
import reportEditorActions from 'pages/reports/editor/actions';
import { getPendingJobCount, getUnSavedReports, getExportErrorMessagesAsCsv } from './selectors';

export function useReportJobs() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);
    const { openEditor } = bindActionCreators(reportEditorActions, dispatch);
    const exportErrorMessage = useSelector(getExportErrorMessagesAsCsv);
    const pendingJobCount = useSelector(getPendingJobCount);
    const unSavedReports = useSelector(getUnSavedReports);

    return {
        ...state,
        ...boundActions,
        openEditor,
        exportErrorMessage,
        pendingJobCount,
        unSavedReports,
    };
}

import _ from 'lodash';

export function getValidators({ isSavingAs }) {
    return {
        name(value) {
            if (!value) {
                return 'Required field';
            }
        },
        // when does this field get validated
        saveAsName(value) {
            if (!isSavingAs) {
                return;
            }

            if (!value) {
                return 'Required field';
            }
        },
        dateRange() {
            return {
                start(value, source) {
                    if (_.includes(['automatic', 'dynamic'], source.type)) {
                        return;
                    }

                    if (!value) {
                        return 'Required field';
                    }
                },
                end(value, source) {
                    if (_.includes(['automatic', 'dynamic'], source.type)) {
                        return;
                    }
                    if (!value) {
                        return 'Required field';
                    }
                },
                lastFrame(value, source) {
                    if (source.type === 'dynamic') {
                        if (!value) {
                            return 'Required field';
                        }
                    }
                },
                last(value, source) {
                    if (source.type === 'dynamic') {
                        if (!value) {
                            return;
                        }

                        if (!value) {
                            return 'Required field';
                        }
                    }
                },
            };
        },
        campaigns(campaigns, source) {
            return {
                ids(value) {
                    if (!_.isArray(value)) {
                        throw new Error(`This shouldn't happen`);
                    }

                    if (source.reportMode === 'campaign' && value.length === 0) {
                        return 'Required field';
                    }

                    return value => {
                        if (!value) {
                            throw new Error(`This shouldn't happen.`);
                        }
                    };
                },
                mode(value) {
                    if (!value) {
                        return 'Required field';
                    }
                },
            };
        },
        campaignStatus(value) {
            // can be empty
            if (!value) {
                return;
            }
            if (!_.includes(['live', 'paused', 'pending', 'ended'], value)) {
                return 'expected one of: Live, Paused, Pending, or Ended';
            }
        },
        /*
        creativeType (value) {
            // can be empty
            if (value === null) {
                return;
            }
            if (!_.includes(CREATIVE_TYPES.map(c => c.value), value)) {
                return 'expected one of: ' + CREATIVE_TYPES.map(c => c.label).join(', ');
            }
        },
        */
        iabCategory() {
            // optional
        },
        dimensions(value) {
            if (!value) {
                return 'Required field';
            }
            if (!_.isArray(value)) {
                return 'expected a list';
            }
            if (value.length === 0) {
                return 'select at least one dimension';
            }
            if (value.length > 9) {
                return 'select at maximum nine dimensions';
            }

            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
        metrics(value) {
            if (!value) {
                return 'Required field';
            }
            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
        attributes(value) {
            if (!value) {
                return;
            }

            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
        client() {
            // optional
        },
        engagements(value) {
            if (!value) {
                return 'Required field';
            }
            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
    };
}

export function getValidatorsV2({ isSavingAs, currentErrors }) {
    return {
        name(value) {
            if (!value) {
                return 'Required field';
            }
        },
        saveAsName(value) {
            if (!isSavingAs) {
                return;
            }

            if (!value) {
                return 'Required field';
            }
        },
        dateRange(dateRange) {
            if (!dateRange.isEnabled) {
                return;
            }

            if (currentErrors && currentErrors.dateRange) {
                return currentErrors.dateRange;
            }

            return {
                start(value, source) {
                    if (_.includes(['automatic', 'dynamic'], source.type)) {
                        return;
                    }

                    if (!value) {
                        return 'start date is required field';
                    }
                },
                end(value, source) {
                    if (_.includes(['automatic', 'dynamic'], source.type)) {
                        return;
                    }
                    if (!value) {
                        return 'end date is required field';
                    }
                },
                lastFrame(value, source) {
                    if (source.type === 'dynamic') {
                        if (!value) {
                            return 'Required field';
                        }
                    }
                },
                last(value, source) {
                    if (source.type === 'dynamic') {
                        if (!value) {
                            return;
                        }

                        if (!value) {
                            return 'Last is Required field';
                        }
                    }
                },
            };
        },
        adEndDate(adEndDate) {
            if (!adEndDate.isEnabled) {
                return;
            }
            return {
                start(value, source) {
                    if (_.includes(['automatic', 'dynamic'], source.type)) {
                        return;
                    }

                    if (!value) {
                        return 'start ad end date is required field';
                    }
                },
                end(value, source) {
                    if (_.includes(['automatic', 'dynamic'], source.type)) {
                        return;
                    }
                    if (!value) {
                        return 'end ad end date is required field';
                    }
                },
                lastFrame(value, source) {
                    if (source.type === 'dynamic') {
                        if (!value) {
                            return 'Required field';
                        }
                    }
                },
                last(value, source) {
                    if (source.type === 'dynamic') {
                        if (!value) {
                            return;
                        }

                        if (!value) {
                            return 'Required field';
                        }
                    }
                },
            };
        },
        campaigns(campaigns) {
            if (!campaigns.isEnabled) {
                return;
            }
            return {
                ids(value) {
                    if (!_.isArray(value)) {
                        throw new Error(`This shouldn't happen`);
                    }

                    return value => {
                        if (!value) {
                            throw new Error(`This shouldn't happen.`);
                        }
                    };
                },
                mode(value) {
                    if (!value) {
                        return 'Required field';
                    }
                },
            };
        },
        organizationFilter(organizationFilter) {
            if (!organizationFilter.isEnabled) {
                return;
            }

            return {
                ids(value) {
                    if (!_.isArray(value)) {
                        throw new Error(`This shouldn't happen`);
                    }

                    return value => {
                        if (!value) {
                            throw new Error(`This shouldn't happen.`);
                        }
                    };
                },
                mode(value) {
                    if (!value) {
                        return 'Required field';
                    }
                },
            };
        },
        campaignStatus(value) {
            // can be empty
            if (!value) {
                return;
            }
            if (!_.includes(['live', 'paused', 'pending', 'ended'], value)) {
                return 'expected one of: Live, Paused, Pending, or Ended';
            }
        },
        iabCategory() {
            // optional
        },
        dimensions(value) {
            if (!value) {
                return 'Required field';
            }
            if (!_.isArray(value)) {
                return 'expected a list';
            }
            if (value.length === 0) {
                return 'select at least one dimension';
            }
            if (value.length > 9) {
                return 'select at maximum nine dimensions';
            }

            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
        metrics(value) {
            if (!value) {
                return 'Required field';
            }
            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
        attributes(value) {
            if (!value) {
                return;
            }

            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
        client() {
            // optional
        },
        engagements(value) {
            if (!value) {
                return 'Required field';
            }
            return value => {
                if (!_.isString(value)) {
                    return 'expected a string';
                }
            };
        },
    };
}

import VError from 'verror';

import notify from 'utils/notify';
import createHttp from 'utils/http';
import { actions } from './reducer';

const initMw = () => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.init.type) {
        try {
            const campaignId = action.payload;
            const campaign = await fetchCampaign(campaignId);

            dispatch(actions.initSucccess(campaign));
        } catch (err) {
            dispatch(actions.initError(err));
            notify({
                error: new VError(err, 'Failed to fetch data for Campaign Form'),
            });
        }
    }
};

async function fetchCampaign(campaignId) {
    const http = createHttp();
    const query = `
        query getCampaignFlightMetadata($campaignId: Int) {
            campaign(id: $campaignId) {
                id
                start
                end
                flightPacingStrategy
                flights {
                    id
                    name
                    start
                    end
                }
                currentFlight: flight(runTime: "live") {
                    id
                    name
                    start
                    end
                }
            }
        }
    `;

    const variables = {
        campaignId: Number(campaignId),
    };

    const res = await http.graphql(query, variables);

    return res.campaign;
}

export const makeMiddlewares = deps => {
    return [initMw(deps)];
};
export const middlewares = makeMiddlewares({});

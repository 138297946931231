import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    activeScopeTab: null,
};

export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;
            return findCacheState(campaignId, state, initialState);
        }

        case c.CAMPAIGN_REPORT__CONTROLS__SET_ACTIVE_SCOPE_TAB: {
            const { scopeId } = action.payload;
            return {
                ...state,
                activeScopeTab: scopeId,
            };
        }

        default:
            return state;
    }
}

export const NAME = 'reportOverview';

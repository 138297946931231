import toastr from 'toastr';

class Logger {
    notify(error, metaData) {
        let user;

        if (window.__store) {
            const profile = window.__store.getState().profile;

            if (profile) {
                user = {
                    userId: profile.userId,
                    organization: profile.organizationId,
                    firstName: profile.userFirstName,
                    lastName: profile.userLastName,
                    email: profile.userEmail,
                };
            }
        }

        if (window.bugsnagClient) {
            window.bugsnagClient.notify(error, {
                metaData,
                user,
            });
        } else {
            toastr.warn(error.message, error.stack);
        }
    }
}

export default new Logger();

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { HistoryTableV3 } from 'widgets/history-table';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import selector from './selector';
// import {setModalOnCloseRedirectPath  as Action_setModalOnCloseRedirectPath } from 'actions/system';
import { campaignHistory as Action_campaignHistory } from 'pages/campaigns/campaign-history/actions';

// ========
//  Main
// ========
const CampaignHistory = class extends React.Component {
    static refresh(dispatch, nextState) {
        const campaignId = _.get(nextState, 'params.campaignId', void 0);

        // const query = _.get(nextState, 'location.query', {});
        // if (query.selectedGrouping) {
        //     dispatch(
        //         Action_adGroupingsList.filterList(campaignId, {filterType: 'testType', value: query.testType})
        //     );
        // }

        dispatch(Action_campaignHistory.open(campaignId));
    }

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    init() {
        const { dispatch } = this.props;
        const { location, params, routes } = this.props;
        const nextState = { location, params, routes };

        CampaignHistory.refresh(dispatch, nextState, browserHistory.push);

        // if return path is not set, that means user has hardloaded the form
        // under this condition we have to specify where to redirect when modal is closed
        // const { onCloseRedirectPath }  = this.props;
        // if  (!onCloseRedirectPath) {
        // const returnPath = `/campaigns/${campaignId}/overview`;
        // dispatch( Action_setModalOnCloseRedirectPath(returnPath));
        // }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        // This will handle the case when props was update but component did not re mount
        const campaignId_prev = prevProps.params.campaignId;
        const campaignId = this.props.params.campaignId;
        if (campaignId !== campaignId_prev) {
            this.init();
        }
    }

    handleClose() {
        const { dispatch } = this.props;
        const { campaignId } = this.props.params;

        // this.props.closeModal();
        // Close form to wipe session in store
        dispatch(Action_campaignHistory.close(campaignId));

        browserHistory.push(`/campaigns/${campaignId}/setup`);
    }

    render() {
        const {
            isLoading,
            organizationTimezone,
        } = this.props;

        return (
            <Dialog fullWidth maxWidth={'lg'} open={true} onClose={this.handleClose}>
                <DialogContent>
                    <HistoryTableV3
                        title="Campaign History"
                        secondaryTitle={`#${this.props.campaign.id} ${this.props.campaign.name}`}
                        historyEntries={this.props.historyEntries}
                        fields={this.props.fields}
                        organizationTimezone={organizationTimezone}
                        isLoading={isLoading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default connect(selector)(CampaignHistory);

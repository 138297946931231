import _ from 'lodash';

import c from 'common/constants/flux-events';
import { validateClientDraft } from 'forms/campaign-form/services';
import update from 'utils/update';
import flags from 'containers/flags/service';
export const NAME = 'campaignSharing';

const initialState = {
    isInitialized: false,
    isSending: false,
    isFormOpen: false,
    campaign: {},

    usersByOrg: {
        // [orgId]: [{
        //     checked,
        //     email,
        //     id
        // }]
    },
    organizations: {},
    // {
    //     [orgId]: {
    //         expanded,
    //         id,
    //         name,
    //         shared,
    //         users: {
    //             [userId]: {
    //                 checked,
    //                 email,
    //                 id
    //             }
    //         }
    //     }
    // }

    metrics: [
        // {name: 'impressions', shared: false}
    ],
    dimensions: [
        // {name: 'gender', shared: false}
    ],
    clients: [
        // {organization: 223, shared: false}
    ],
    organizationsLoaded: false,
    usersLoaded: false,

    campaignSharingClientForm: {
        draft: null,
        isDraftValid: false,
        errors: [],
        showErrors: false,
        isLoading: true,
        isSubmiting: false,
        isDirty: false,
        isFormOpen: false,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.CAMPAIGN_SHARING__INIT: {
            return {
                ...state,
                isInitialized: false,
                graphqlFetchingOrgsAndUsersLoading: true,
            };
        }
        case 'CAMPAIGN_SHARING__FETCH_CLIENT_ORGANIZATIONS_AND_USERS__FAIL': {
            return {
                ...state,
                graphqlFetchingOrgsAndUsersLoading: false,
                showUsersError: action.payload.err.message,
            };
        }
        case c.CAMPAIGN_SHARING__INIT_SUCCESS: {
            const { organizations, users, campaign } = action.payload;

            const usersByOrg = {};
            _.each(users, user => {
                if (!usersByOrg[user.attr.organization]) {
                    usersByOrg[user.attr.organization] = [];
                }
                usersByOrg[user.attr.organization].push({
                    checked: _.get(user, 'attr.checked', false),
                    email: _.get(user, 'attr.email'),
                    id: user.id,
                });
            });

            // organization clients
            const orgList = [];
            _.each(_.get(campaign, 'attr.clients'), (client, i) => {
                const org = organizations[client.organization];

                orgList.push({
                    id: org.id,
                    name: _.get(org, 'attr.name'),
                    shared: !!client.shared,
                    expanded: true,
                    type: org.attr.client_type,
                });
            });

            const metrics = _.get(campaign, 'attr.sharing_settings.metrics', []);
            const reportTabs = _.get(campaign, 'attr.sharing_settings.report_tabs');
            const clients = _.map(campaign.attr.clients, client => client.organization);

            return {
                ...state,
                isInitialized: true,
                campaign,
                organizations: orgList,
                usersByOrg,
                metrics,
                reportTabs,
                clients,
                graphqlFetchingOrgsAndUsersLoading: false,
            };
        }
        case c.CAMPAIGN_SHARING__DESTROY: {
            return {
                ...initialState,
                organizationsLoaded: true,
                usersLoaded: true,
            };
        }

        case c.CAMPAIGN_SHARING__TOGGLE_EMAIL: {
            const { campaignId, user, organization } = action.payload;

            const usersByOrgNext = {
                ...state.usersByOrg,
                [organization.id]: _.map(state.usersByOrg[organization.id], u => {
                    if (u.id === user.id) {
                        return {
                            ...u,
                            checked: !u.checked,
                        };
                    }

                    return u;
                }),
            };

            return {
                ...state,
                usersByOrg: usersByOrgNext,
            };
        }
        case c.CAMPAIGN_SHARING__UPDATE_METRICS: {
            const { metrics } = action.payload;

            const metricsNext = _.map(metrics, input => ({
                name: input.key,
                shared: input.value,
            }));

            return {
                ...state,
                metrics: metricsNext,
            };
        }
        case c.CAMPAIGN_SHARING__UPDATE_DIMENSIONS: {
            const { tab, dimensions } = action.payload;

            const dimensionsNext = _.map(dimensions, input => ({
                name: input.key,
                shared: input.value,
            }));

            return {
                ...state,
                reportTabs: {
                    ...state.reportTabs,
                    [tab]: {
                        ...state.reportTabs[tab],
                        dimensions: dimensionsNext,
                    },
                },
            };
        }
        case c.CAMPAIGN_SHARING__UPDATE_REPORT_TAB: {
            const { tab, checked } = action.payload;

            return {
                ...state,
                reportTabs: {
                    ...state.reportTabs,
                    [tab]: {
                        ...state.reportTabs[tab],
                        shared: checked,
                    },
                },
            };
        }
        case c.CAMPAIGN_SHARING__UPDATE_CLIENTS: {
            const { campaignId, clients, organizationsResource } = action.payload;

            // add clients dont exist in organizations
            // push new client entry

            const organizationsDict = {};
            _.each(state.organizations, org => (organizationsDict[org.id] = org));

            const organizations = _.map(clients, clientId => {
                if (organizationsDict[clientId]) {
                    return organizationsDict[clientId];
                }

                const org = organizationsResource[clientId];

                return {
                    id: org.id,
                    name: _.get(org, 'attr.name'),
                    shared: false,
                    expanded: false,
                    type: org.attr.client_type,
                };
            });

            return {
                ...state,
                clients,
                organizations,
            };
        }
        case c.CAMPAIGN_SHARING__TOGGLE_SHARE: {
            const { campaignId, organization } = action.payload;

            const nextShared = !organization.shared;

            const organizationsNext = _.map(state.organizations, org => {
                if (org.id === organization.id) {
                    return {
                        ...org,
                        shared: nextShared,
                        expanded: nextShared === true,
                    };
                }

                return org;
            });

            // Emails should be unchecked when sharing is off
            // Emails should be checked when sharing is on
            const usersByOrgNext = {
                ...state.usersByOrg,
                [organization.id]: _.map(state.usersByOrg[organization.id], u => {
                    return {
                        ...u,
                        checked: nextShared,
                    };
                }),
            };

            return {
                ...state,
                usersByOrg: usersByOrgNext,
                organizations: organizationsNext,
            };
        }
        case c.CAMPAIGN_SHARING__EMAILS_SELECT_ALL: {
            const { campaignId, organization } = action.payload;

            const usersByOrgNext = {
                ...state.usersByOrg,
                [organization.id]: _.map(state.usersByOrg[organization.id], u => {
                    return {
                        ...u,
                        checked: true,
                    };
                }),
            };

            return {
                ...state,
                usersByOrg: usersByOrgNext,
            };
        }
        case c.CAMPAIGN_SHARING__EMAILS_SELECT_NONE: {
            const { campaignId, organization } = action.payload;

            const usersByOrgNext = {
                ...state.usersByOrg,
                [organization.id]: _.map(state.usersByOrg[organization.id], u => {
                    return {
                        ...u,
                        checked: false,
                    };
                }),
            };

            return {
                ...state,
                usersByOrg: usersByOrgNext,
            };
        }
        case c.CAMPAIGN_SHARING__EXPAND_COLLAPSE_TOGGLE: {
            const { campaignId, organization } = action.payload;

            const organizationsNext = _.map(state.organizations, org => {
                if (org.id === organization.id) {
                    return {
                        ...org,
                        expanded: !org.expanded,
                    };
                }

                return org;
            });

            return {
                ...state,
                organizations: organizationsNext,
            };
        }
        case c.CAMPAIGN_SHARING__SEND: {
            return {
                ...state,
                isSending: true,
            };
        }
        case c.CAMPAIGN_SHARING__SEND__SUCCESS: {
            return {
                ...state,
                isSending: false,
                isFormOpen: false,
            };
        }
        case c.CAMPAIGN_SHARING__SEND__FAIL: {
            return {
                ...state,
                isSending: false,
                isFormOpen: true,
            };
        }
        case c.CAMPAIGN_SHARING__OPEN: {
            return {
                ...state,
                isFormOpen: true,
            };
        }
        case c.CAMPAIGN_SHARING__CLOSE: {
            return {
                ...state,
                isFormOpen: false,
            };
        }
        case c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS: {
            return {
                ...state,
                organizationsLoaded: true,
            };
        }
        case c.SYSTEM__USERS__FETCH__SUCCESS: {
            return {
                ...state,
                usersLoaded: true,
            };
        }

        default:
            return state;
    }
}

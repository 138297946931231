import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';

import Separator from 'widgets-v5/separator';

export default class extends React.Component {
    static displayName = 'Navigation';

    static propTypes = {
        className: PropTypes.string,
        activeItem: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                href: PropTypes.string,
            })
        ),
        onChange: PropTypes.func,
    };

    render() {
        return (
            <nav className={classnames('ef4-navigation', this.props.className)}>
                {this.props.children && <ul>{this.props.children}</ul>}
                {!this.props.children && (
                    <ul>
                        {this.props.items.map(item => (
                            <NavigationItem
                                key={item.value}
                                value={item.value}
                                label={item.label}
                                href={item.href}
                                onClick={() => {
                                    this.props.onChange && this.props.onChange(item.value);
                                }}
                                isActive={this.props.activeItem === item.value}
                            />
                        ))}
                    </ul>
                )}
            </nav>
        );
    }
}

export function NavigationItem({ label, value, href, onClick, isActive }) {
    // External link
    if (href && href.search(/^https?:\/\//) === 0) {
        return (
            <li
                className={classnames('ef4-navigation__item', {
                    'ef4-navigation__item_is-active': isActive,
                })}
            >
                <a
                    href={href}
                    target="_blank"
                    className={classnames('ef4-navigation__item-name', {
                        'ef4-navigation__item-name_is-active': isActive,
                    })}
                >
                    Ext: {label}
                </a>
            </li>
        );

        // Internal link
    } else if (href) {
        return (
            <li
                className={classnames('ef4-navigation__item', {
                    'ef4-navigation__item_is-active': isActive,
                })}
            >
                <Link
                    to={href}
                    className={classnames('ef4-navigation__item-name', {
                        'ef4-navigation__item-name_is-active': isActive,
                    })}
                >
                    {label}
                </Link>
            </li>
        );

        // onChange handler
    } else {
        return (
            <li
                onClick={onClick}
                className={classnames('ef4-navigation__item', {
                    'ef4-navigation__item_is-active': isActive,
                })}
            >
                <span
                    className={classnames('ef4-navigation__item-name', {
                        'ef4-navigation__item-name_is-active': isActive,
                    })}
                >
                    {label}
                </span>
            </li>
        );
    }
}

export function NavigationSeparator({ label }) {
    return (
        <li className="ef4-navigation__separator">
            <Separator label={label} />
        </li>
    );
}

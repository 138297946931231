export default function selector(storeState) {
    const ownOrgId = _.get(storeState, 'profile.organizationId');
    const ownOrganization = null;
    const geoLayers = _.get(storeState, 'resources.geoCategories.geoLayers');

    const validGeoLayers = {};
    _.each(geoLayers, geoLayer => (validGeoLayers[geoLayer.id] = geoLayer));
    return {
        ownOrgId,
        ownOrganization,
        validGeoLayers,
    };
}

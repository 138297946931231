import React, { lazy, Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const PredictionEditor = lazy(() => import('./component'));

export const PredictionEditorLazy = () => (
    <Suspense fallback={<LinearProgress />}>
        <PredictionEditor />
    </Suspense>
);

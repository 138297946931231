import $ from 'jquery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import MuiChip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

class Chip extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.string,
        deletable: PropTypes.bool,
        onDelete: PropTypes.func,
    };

    render() {
        return (
            <MuiChip
                className={classnames('ef5-chip', this.props.className)}
                label={this.props.label}
                onDelete={() => this.props.onDelete()}
            />
        );
    }
}

export default Chip;

export class ChipRow extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        return (
            <div className={classnames('ef5-chip-row', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

export class SortableChipRow extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onChange: PropTypes.func,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            })
        ).isRequired,
        value: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    state = {
        isSorting: false,
    };

    onSortStart = () => {
        // Can't use React state here because Sortable-HOC duplicates
        // the elements for animation while dragging, causing weird
        // issues to arris
        $(this.rootEl).addClass('ef5-sortable-chip-row_is-sorting');
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        $(this.rootEl).removeClass('ef5-sortable-chip-row_is-sorting');
        const newValue = [...this.props.value];
        const changedItem = newValue.splice(oldIndex, 1)[0];
        newValue.splice(newIndex, 0, changedItem);
        this.props.onChange(newValue);
    };

    onDelete = index => {
        const newValue = [...this.props.value];
        newValue.splice(index, 1);
        this.props.onChange(newValue);
    };

    render() {
        const itemsMap = new Map();
        this.props.items.forEach(item => itemsMap.set(item.value, item));

        const SortableChip = SortableElement(props => (
            <Chip className="ef5-chip_is-draggable" {...props} />
        ));

        const SortableChipRowContainer = SortableContainer(() => (
            <div
                ref={el => (this.rootEl = el)}
                className={classnames('ef5-sortable-chip-row', this.props.className)}
            >
                <Box display="flex" alignItems="center">
                    {this.props.value.map((value, i) => {
                        const item = itemsMap.get(value);
                        return (
                            <React.Fragment key={value}>
                                <SortableChip
                                    {...item}
                                    onDelete={() => this.onDelete(i)}
                                    index={i}
                                />
                                {i + 1 !== this.props.value.length && (
                                    <SubdirectoryArrowRightIcon />
                                )}
                            </React.Fragment>
                        );
                    })}
                </Box>
            </div>
        ));

        return (
            <SortableChipRowContainer
                lockToContainerEdges={true}
                axis="x"
                lockAxis="x"
                helperClass="ef5-chip_is-dragging"
                shouldCancelStart={e => {
                    if (_.isFunction(_.get(e, 'target.className.indexOf'))) {
                        const isDeleteButton =
                            e.target.className.indexOf('ef5-chip__delete-button') !== -1;
                        return isDeleteButton;
                    }
                    return true;
                }}
                onSortStart={this.onSortStart}
                onSortEnd={this.onSortEnd}
            />
        );
    }
}

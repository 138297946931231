import { CreativeRotationMethodMapping } from "states/resources/ads/business-logic";

export function serialize({ draft, isCrossPlatformCampaign }) {
    // In order to support not losing scheduled rotation settings while playing with the ad
    // configuration, we keep the scheduled rules stored in the draft until the last moment
    const serializedDraft = {
        ...draft,
        platforms: isCrossPlatformCampaign ? draft.platforms : [draft.platform],
        rotation_rules: {
            ...draft.rotation_rules,
            weighted: _.map(draft.rotation_rules.weighted, w => ({
                ...w,
                weight: parseFloat((w.weight / 100).toFixed(2)),
            })),
            scheduled:
                draft.rotation_rules.mode === CreativeRotationMethodMapping.Scheduled ? draft.rotation_rules.scheduled : [],
        },
        usingSeparateApplistFields: true,
    };

    return serializedDraft;
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class OverflowLayout extends React.Component {
    static propTypes = {
        responsive: PropTypes.oneOf(['mobile', 'mobile-tablet', 'tablet', 'desktop', 'all'])
            .isRequired,
    };

    static defaultProps = {
        responsive: 'all',
    };

    render() {
        return (
            <div
                className={classnames(
                    this.props.className,
                    'ef5-overflow-layout',
                    `ef5-overflow-layout--is-${this.props.responsive}`
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

export default OverflowLayout;

import _ from 'lodash';
import flags from 'containers/flags/service';

export default function selector(storeState, props) {
    const { campaignId } = props;

    const sharing = _.get(storeState, `campaignSharing`, {});
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const graphqlFetchingOrgsAndUsersLoading = _.get(
        sharing,
        `graphqlFetchingOrgsAndUsersLoading`,
        null
    );
    const orgList = _.map(sharing.organizations, org => {
        return {
            ...org,
            users: _.get(sharing, `usersByOrg.${org.id}`, []),
        };
    });
    const organizationIdList = sharing.clients;

    const hasNoAds =
        _.get(storeState, `resources.campaigns.${props.campaignId}.attr.ads`, []).length === 0;

    let organizationsResource = null;
    let ownOrganizationId;
    let ownOrganization;
    let childOrganizations;
    let organizations;

    return {
        campaign,
        metrics: sharing.metrics,
        reportTabs: sharing.reportTabs,
        hasNoAds,

        orgs: orgList,
        isFormOpen: sharing.isFormOpen,
        isSending: sharing.isSending,

        // Client Form
        organizationIdList,
        graphqlFetchingOrgsAndUsersLoading,
    };
}

function reduceErrorList(errorsAsList = []) {
    const errorsMapping = _(errorsAsList)
        .groupBy(e => e.field)
        .reduce(
            (acc, value, key) => ({
                ...acc,
                [key]: _.map(value, 'message'),
            }),
            {}
        );
    return errorsMapping;
}

function isChildOfTypeClientOrComanaged(org) {
    return (
        org.attr._relationship === 'child' &&
        (org.attr.type === 'client' || org.attr.type === 'co_managed_client')
    );
}

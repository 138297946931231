import _ from 'lodash';

export default function selector(storeState) {
    const { isInventoryDealsModalOpen, ...modalState } = _.get(storeState, 'dealsInventoryModal');

    return {
        open: isInventoryDealsModalOpen,
        ...modalState,
    };
}

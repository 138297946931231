import _ from 'lodash';
import moment from 'moment';

import config from '../../../../config';

const DATE_FORMAT = 'MMMM Do, YYYY @ h:ma';

class Flags {
    constructor(initialFlags) {
        this.changeHandlers = [];
        this.flags = initialFlags;
        this.enabledFlags = [];
    }

    getFlags() {
        return this.flags;
    }

    getEnabledFlagKeys() {
        if(this.enabledFlags.length > 0) {
            return this.enabledFlags;
        }

        for (const key in this.flags) {
            if (this.isEnabled(key)) {
                this.enabledFlags.push(key);
            }
        }

        return this.enabledFlags;
    }

    isEnabled(key) {
        const flag = this._findFlag(key);
        if(!flag) {
            return false;
        }

        //ENV_NAME is not "canada" but "production", "demo"
        //To standardize the validate, if not spain, then it is canada
        const env = config.ENV_NAME === 'spain' ? 'spain' : 'canada';

        const isFlagForAllEnvs = (undefined === flag.env || flag.env === 'all' || flag.env === '');
        return flag.enabled && (isFlagForAllEnvs || flag.env === env);
    }

    isDisabled(key) {
        return this._findFlag(key).enabled === false;
    }

    disable(key) {
        const flag = this._findFlag(key);
        flag.enabled = false;

        this._persistFlags();
        this.emitChange();
    }

    enable(key) {
        const flag = this._findFlag(key);
        flag.enabled = true;

        this._persistFlags();
        this.emitChange();
    }

    reset() {
        // clear cache
        localStorage.setItem('LOCAL_FLAGS', JSON.stringify({}));

        // reset flags
        this.populate(this.initialFlags);

        this._persistFlags();
        this.emitChange();
    }

    toggleFlag(key) {
        const flag = this._findFlag(key);

        if (flag.enabled) {
            this.disable(key);
        } else {
            this.enable(key);
        }
    }

    emitChange() {
        _.each(this.changeHandlers, fn => fn(this));
    }

    onChange(fn) {
        this.changeHandlers.push(fn);
    }

    offChange(fn) {
        this.changeHandlers = this.changeHandlers.filter(handler => handler !== fn);
    }

    canManageFlags() {
        return _.includes(
                  ['development', 'integration', 'release', 'cloud-dev', 'staging'],
                  config.ENV_NAME
              )
    }

    _findFlag(key) {
        const flag = this.flags[key];
        if (!flag) {
            throw new Error(`Flag ${key} not found. Clean up flag reference or create the flag`);
        }

        return flag;
    }

    populate(flags) {
        let STORAGE_FLAGS = {};
        this.initialFlags = flags;

        try {
            if (config.ENV_NAME === 'development') {
                STORAGE_FLAGS = JSON.parse(localStorage.getItem('LOCAL_FLAGS') || '{}');
            }
        } catch (err) {
            STORAGE_FLAGS = {};
        }

        // Merge localStorage flags with most recent flag
        const allFlags = {};
        _.each(flags, flag => {
            allFlags[flag.key] = {
                ...flag,
                enabled: STORAGE_FLAGS[flag.key] ? STORAGE_FLAGS[flag.key].enabled : flag.enabled,
            };
        });

        this.flags = allFlags;
    }

    _persistFlags() {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem('LOCAL_FLAGS', JSON.stringify(this.flags));
            this._resetEnabledFlags();
        }
    }

    _resetEnabledFlags() {
        this.enabledFlags = [];
    }

    getStaleFlags() {
        return _(this.flags)
            .map(flag => flag)
            .filter(flag => flag.released)
            .filter(flag => moment().diff(moment(flag.released, DATE_FORMAT), 'months') >= 1)
            .groupBy('author')
            .value();
    }
}

const flagService = new Flags({});

export default flagService;

import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';

const PoiActions = {
    getAll(query) {
        return (dispatch, getState) => {
            return http()
                .get(`geolayers/v2/`, query)
                .then(response => {
                    dispatch({
                        type: 'SYSTEM__POI_LAYERS__FETCH__SUCCESS',
                        payload: {
                            layers: response.payload,
                        },
                    });

                    return response;
                });
        };
    },
    getOne(poiId) {
        return (dispatch, getState) => {
            return http()
                .get(`geolayers/${poiId}`)
                .then(response => {
                    dispatch({
                        type: 'SYSTEM__POI_LAYERS__FETCH__SUCCESS',
                        payload: {
                            layers: [response],
                        },
                    });

                    return response;
                });
        };
    },
    createPoi(payload) {
        return (dispatch, getState) => {
            return http()
                .post('geolayers', payload)
                .then(results => {
                    dispatch({
                        type: 'SYSTEM__POIS__FETCH__SUCCESS',
                        payload: {
                            geolayers: [results.geolayer],
                            points: results.points,
                        },
                    });

                    return results;
                });
        };
    },
    updatePoi(payload, poiId) {
        return (dispatch, getState) => {
            return http()
                .patch(`geolayers/${poiId}`, payload)
                .then(
                    results => {
                        dispatch({
                            type: 'SYSTEM__POIS__FETCH__SUCCESS',
                            payload: {
                                geolayers: [results.geolayer],
                                points: results.points,
                            },
                        });

                        return results;
                    },
                    err => {
                        console.error(`CREATE Geolayer error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default PoiActions;

import _ from 'lodash';

export default function selector(storeState, props) {
    const { campaignId, creativeId } = props;

    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    if (!campaign) {
        return { creatives: [] };
    }

    const currentOrg = _.get(storeState, 'profile.organizationId');
    const currentUser = _.get(storeState, 'profile.userId');

    // Asset
    const filesResource = _.get(storeState, `resources.files`, {});
    const campaignFiles = _.reduce(
        campaign.files,
        (acc, fileId) => {
            const file = filesResource[fileId];
            if (!file) {
                return acc;
            }
            return {
                ...acc,
                [fileId]: file,
            };
        },
        {}
    );
    const filteredCampaignFiles = _.filter(campaignFiles, x => x);
    const assets = _.filter(filteredCampaignFiles, (value) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? value : null;
    });
    const documents = _.filter(filteredCampaignFiles, (value) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? null : value;
    });

    const form = _.get(storeState, `creativeForm`, {});

    const isEditConfirmationModalOpen = _.get(
        storeState,
        `creativeForm.isEditConfirmationModalOpen`,
        false
    );
    const confirmModalMessage = _.get(storeState, `creativeForm.confirmModalMessage`, '');
    const isEditConfirmSaveAndAudit = _.get(
        storeState,
        `creativeForm.isEditConfirmSaveAndAudit`,
        false
    );

    const areAssetsLoading = _.get(storeState, 'filesOverview.loadingAssets');
    const uploadAssetsErrorMessage = _.get(storeState, 'filesOverview.uploadAssetsErrorMessage');

    return {
        campaignId,
        creativeId,
        currentOrg,
        currentUser,
        assets,
        documents,
        isSubmitting: form.isSubmitting,
        isLoading: form.isLoading,
        isOpen: form.isFormOpen,
        draft: form.draft,
        isDraftValid: form.isDraftValid,
        showErrors: form.showErrors,
        errors: reduceErrorList(form.errors),
        isReferenceImageLoading: form.isReferenceImageLoading,
        isZipUploadLoading: form.isZipUploadLoading,
        areAssetsLoading,
        isEditConfirmationModalOpen,
        confirmModalMessage,
        isEditConfirmSaveAndAudit,
        creativeTagsMatched: form.creativeTagsMatched,
        trackingTagsMatched: form.trackingTagsMatched,
        showHTMLblock: form.showHTMLblock,
        campaignType: campaign.type,
        uploadAssetsErrorMessage,
    };
}

function reduceErrorList(errorsAsList = []) {
    return _(errorsAsList)
        .groupBy(e => e.field)
        .reduce((acc, v, k) => {
            const o = {
                ...acc,
                [k]: _.map(v, 'message'),
            };
            return o;
        }, {});
}

// import notify from 'utils/notify';
import { createHttp } from 'utils/http';

const actions = {
    init(campaignId, flightId) {
        return async dispatch => {
            dispatch({
                type: 'CAMPAIGN_REPORT__FTA__INIT__START',
                payload: {},
            });

            try {
                const data = await fetchPageData(campaignId, flightId);

                dispatch({
                    type: 'CAMPAIGN_REPORT__FTA__INIT__SUCCESS',
                    payload: {
                        ...data,
                    },
                });
            } catch (err) {
                /*
                notify({
                    error: new VError(err, 'Failed to fetch data for Campaign Report FTA page'),
                });
                */

                dispatch({
                    type: 'CAMPAIGN_REPORT__FTA__INIT__ERROR',
                    payload: {},
                    error: err,
                });
            }
        };
    },
    destroy() {
        return {
            type: 'CAMPAIGN_REPORT__FTA__DESTROY',
        };
    },
};

async function fetchPageData(campaignId, flightId) {
    try {
        const http = createHttp();
        const query = `
            query getFtaPageData($campaignId: String, $flightId: String) {
                ownOrganization {
                    fta_partner_id
                }
                ftaCampaignMetaData(campaignId: $campaignId, flightId: $flightId) {
                    statusCode
                    status
                    iframeUrl
                }
                ftaLocationLists {
                    id
                    status
                }
            }
        `;

        const variables = {
            campaignId: String(campaignId),
            flightId: flightId && String(flightId),
        };

        const data = await http.graphql(query, variables);

        return data;
    } catch (err) {
        console.error(err);

        return [];
    }
}

export default actions;

const initialState = {
    isOpen: false,
    userId: '',
    isDeleting: false,
};

export const NAME = 'deleteUserModal';

const handlers = {
    DELETE_USER_MODAL__OPEN_MODAL: (state, action) => {
        return {
            ...state,
            isOpen: true,
            userId: action.payload.userId,
        };
    },
    DELETE_USER_MODAL__CLOSE_MODAL: state => {
        return {
            ...state,
            isOpen: false,
        };
    },
    DELETE_USER_MODAL__DELETE_START: state => {
        return {
            ...state,
            isDeleting: true,
        };
    },
    DELETE_USER_MODAL__DELETE_SUCCESS: state => {
        return {
            ...state,
            isDeleting: false,
        };
    },
    DELETE_USER_MODAL__DELETE_FAIL: state => {
        return {
            ...state,
            isDeleting: false,
        };
    },
};

export default function reducer(state = initialState, action) {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
    }

    return nextState;
}

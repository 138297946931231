import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';

import { formatNumber_currency } from 'utils/formatting';

import Section from 'widgets-v5/section';

import { getIABCat } from 'containers/ad-summary';
import TargetIABCategories from 'forms/ad-form/modules/target-iab-categories';
import shallowCompare from 'utils/shallow-compare';
import classnames from 'classnames';

import FormField from 'widgets-v5/form-field';
import TargetAppStoreCategories from 'forms/ad-form/modules/target-app-store-categories';

export class AppCategoryForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="App Categories"
                fields={['target_app_store_cat', 'target_app_store_cat_exclude']}
                shouldRefreshPredictionsOnSave={false}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => {
                    const isAppCategoryExcluding = draft.target_app_store_cat_exclude.length > 0;

                    const formLabel = `App Category (${
                        isAppCategoryExcluding ? 'Exclude' : 'Include'
                    })`;
                    return (
                        <div className="media-plan-form__app-category-selector">
                            <FormField label={ads ? formLabel : ''}>
                                <TargetAppStoreCategories
                                    includeValues={draft.target_app_store_cat}
                                    excludeValues={draft.target_app_store_cat_exclude}
                                    onChange={({ values, exclude }) => {
                                        if (exclude) {
                                            updateDraft({
                                                target_app_store_cat: [],
                                                target_app_store_cat_exclude: values,
                                            });
                                        } else {
                                            updateDraft({
                                                target_app_store_cat: values,
                                                target_app_store_cat_exclude: [],
                                            });
                                        }
                                    }}
                                />
                            </FormField>
                        </div>
                    );
                }}
            </BaseColumnForm>
        );
    }
}

export class AppCategorySummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;

        let label;
        if (ad.target_app_store_cat.length > 0) {
            label = _.map(ad.target_app_store_cat, category => (
                <div key={category}>{category}</div>
            ));
        } else if (ad.target_app_store_cat_exclude.length > 0) {
            label = _.map(ad.target_app_store_cat_exclude, category => (
                <div key={category}>{category}</div>
            ));
        } else {
            label = 'All Categories';
        }
        const hasExceededHeight = ad.target_iab_categories.length > 10;
        return <div>{error ? <div>{error}</div> : <div>{label}</div>}</div>;
    }
}

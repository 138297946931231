import _ from 'lodash';

const isClientReportPage = () => /campaigns\/.*\/client-report/.test(window.location.pathname);

export const isMetricShared = (campaign, metricName) => {
    let isShared = true;
    const campaignSharingSettingsMetrics = _.get(campaign, 'sharing_settings.metrics', []);
    _.each(campaignSharingSettingsMetrics, sharedMetrics => {
        if (isClientReportPage()) {
            if (sharedMetrics.name === metricName) {
                isShared = sharedMetrics.shared;
            }
        }
    });
    return isShared;
};

export const isDimensionShared = (campaign, dimensionName) => {
    let isShared = true;
    const campaignSharingSettingsDimensions = _.get(
        campaign,
        'sharing_settings.report_tabs.explore.dimensions',
        []
    );
    _.each(campaignSharingSettingsDimensions, sharedDimensions => {
        if (isClientReportPage()) {
            if (sharedDimensions.name === dimensionName) {
                isShared = sharedDimensions.shared;
            }
        }
    });
    return isShared;
};

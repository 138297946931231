// Use hooks to access the state and behaviour of this package
import { useSelector } from 'react-redux';

const validate = state => {
    const errors = {};

    if (state.draft.selectedMetrics.length === 0) {
        errors.metric = 'Select at least 1 metric';
    }

    if (state.draft.selectedDimensions.length === 0) {
        errors.dimension = 'Select at least 1 dimension';
    }

    return {
        errors,
        status: _.keys(errors).length > 0 ? 'error' : state.status,
    };
};

export function useAppReport() {
    const state = useSelector(state => state.appsReport);

    const output = validate(state);

    return {
        ...state,
        ...output,
    };
}

import React from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MUITabs from '@mui/material/Tabs';

import { useOverviewAdData } from '../../hook';

export const Tabs = ({
    handleChangeActiveFilter,
    generatedConfigs,
}) => {
    const {
        endingSoon,
        isLoading,
        upcoming,
        recentlyEnded,
    } = useOverviewAdData();

    const [tabValue, setTabValue] = React.useState('live');

    const handleTabValue = (event, newTabValue) => {
        setTabValue(newTabValue);
        handleChangeActiveFilter(newTabValue, generatedConfigs);
    };

    return !isLoading && (
        <Box mb={3} borderBottom="1px solid lightGrey">
            <MUITabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabValue}
            >
                <Tab label={`(${endingSoon.length}) Currently Live`} value="live" />
                <Tab
                    label={`(${recentlyEnded.length}) Ended Last 7 Days`}
                    value="ended"
                />
                <Tab
                    label={`(${upcoming.length}) Starting Next 7 Days`}
                    value="upcoming"
                />
            </MUITabs>
        </Box>
    )
}

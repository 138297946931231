import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';

import allRegions from 'common/constants/regions';
import COUNTRIES from 'common/constants/countries';
import regions from 'common/constants/regions';
import rawCategories from 'common/constants/iab-categories';
import timezones from 'common/constants/timezones';
// general utility functions

export function formatNumber(number, format) {
    if (isNaN(number)) {
        return '-';
    }

    if (_.isNumber(number)) {
        switch (format) {
            case 'approx':
                return numeral(number).format('0,0[.]0a');
            case 'percentage':
                return numeral(number).format('0[.]00%');
            default:
                return numeral(number).format('0,0');
        }
    }

    return '--';
}

export function formatNumberThousands(number) {
    if (_.isNumber(number)) {
        return formatNumber(number, 'thousands');
    }
    return number;
}

export function formatPercentage(number) {
    return formatNumber(number, 'percentage');
}

export function formatCurrency(num, withSign) {
    let _num = num;
    if (!_.isNumber(num)) {
        _num = 0;
    }

    if (withSign === false) {
        return numeral(_num).format('0,0.00');
    }
    return numeral(_num).format('$0,0.00');
}

export function randomId(mine, his) {
    return _.uniqueId('rand_');
}

export function humanize(str) {
    return S(str).humanize();
}

export function objValue(obj, key) {
    return JSON.stringify(obj[key]);
}

export function getCountryName(code) {
    var country = _.find(COUNTRIES, function(c) {
        return c.id === code;
    });
    return country && country.text;
}

export function getWeekdayLabel(index) {
    return ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'][index];
}

export function convertTo(func) {
    return function(name, model, val) {
        var actual = 0;
        if (val) {
            if (_.isString(val) || _.isNumber(val)) {
                var parsed = func(val, 10);
                actual = _.isNaN(parsed) ? 0 : parsed;
            }
        }
        return model.set(name, actual);
    };
}

export function getCombinations(arr, n) {
    /* jshint maxcomplexity: false */
    var ret = [],
        i,
        j;
    if (n === 1) {
        for (i = 0; i < arr.length; i = i + 1) {
            for (j = 0; j < arr[i].length; j = j + 1) {
                ret.push([arr[i][j]]);
            }
        }
        return ret;
    } else {
        for (i = 0; i < arr.length; i = i + 1) {
            var elem = arr.shift();
            for (j = 0; j < elem.length; j = j + 1) {
                var childperm = getCombinations(arr.slice(), n - 1);
                for (var k = 0; k < childperm.length; k = k + 1) {
                    ret.push([elem[j]].concat(childperm[k]));
                }
            }
        }
        return ret;
    }
}

/**
 * Normalize url before comparison
 *
 * It does the following processes
 * 1. strip slashes
 *
 * @param  {string} url
 * @return {[type]}     [description]
 */
export function normalizeUrl(url) {
    return S(url)
        .chompLeft('/')
        .chompRight('/').s;
}

/**
 * Compare 2 arrays
 *
 * NOTE
 *     If the array contain object, this may not work.
 *     Array is assumed to be sorted.
 *
 * @param  {[]}  array1
 * @param  {[]}  array2
 * @return {Boolean}        [description]
 */
export function isArrayEuqal(array1, array2) {
    /* jshint maxcomplexity:false */

    if (!array1 || !array2) {
        return false;
    }

    // compare lengths - can save a lot of time
    if (array1.length !== array2.length) {
        return false;
    }

    for (var i = 0, l = array1.length; i < l; i = i + 1) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!isArrayEuqal(array1[i], array2[i])) {
                return false;
            }
        } else if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
}

// inconsistent region code
export function decodeRegion(regionCode, countryCode) {
    var countryRegions;

    var splited = regionCode.split('-');

    if (splited.length === 0) {
        return '';
    }

    if (countryCode) {
        countryRegions = allRegions[countryCode];
    } else {
        countryRegions = allRegions[splited[0]];
    }

    if (_.isEmpty(countryRegions)) {
        return '';
    }

    if (splited.length === 2) {
        return countryRegions[regionCode];
    } else {
        return countryRegions[countryCode + '-' + splited[0]];
    }
}

export function getRegionName(countryCode, regionCode) {
    return regions[countryCode][countryCode + '-' + regionCode];
}

export function decodeIABCategory(cat) {
    return rawCategories[cat];
}

export function formatDimension(arr) {
    return arr.join('x');
}

function singular(plural) {
    switch (plural) {
        case 'are':
            return 'is';
        case 'have':
            return 'has';
    }
}

export function plural(num, pluralForm) {
    const _pluralForm = pluralForm || 'are';

    if (num <= 1) {
        return singular(_pluralForm);
    }
    return _pluralForm;
}

// TODO: change to non hard coded version
export function textEllipsize(str) {
    var characterCount = 325,
        ret,
        pattern = new RegExp(/[A-z\!\.\,]/);
    if (str.length <= characterCount) {
        return str;
    }
    ret = str.substring(0, characterCount);
    // Check if ending character is any alphabetic character,
    // !, . or comma.
    if (pattern.test(str.charAt(characterCount))) {
        ret = ret.substring(0, ret.lastIndexOf(' ') + 1) + ' ';
    }
    ret += '...';
    return ret;
}

/**
 * Format time nicely
 *
 * @param  {string} timeStr ISO Date String, with timezone info
 * @return {string} formatted time string in user set timezone
 */
export function niceTime(timeStr) {
    if (timeStr) {
        return moment(timeStr).format('YYYY/MM/DD HH:mm:ss');
    } else {
        return '';
    }
}

/**
 * Format time in just month and date
 *
 * @param  {string} timeStr ISO Date String, with timezone info
 * @return {string} formatted time string in user set timezone
 */
export function simpleTime(timeStr) {
    if (timeStr) {
        return moment(timeStr).format('MMM DD');
    } else {
        return '';
    }
}

/**
 * Format time in month day, year
 *
 * @param  {string} timeStr ISO Date String, with timezone info
 * @return {string} formatted time string in user set timezone
 */
export function simpleTimeYear(timeStr) {
    if (timeStr) {
        return moment(timeStr).format('MMM DD, YYYY');
    } else {
        return '';
    }
}

export function timeAgo(timeStr) {
    return jQuery.timeago(timeStr);
}

export function formatGeoRegionCity(countryCode, obj) {
    var regionName = decodeRegion(obj.region, countryCode);
    if (!_.isEmpty(obj.city)) {
        return obj.city + ', ' + regionName;
    }
    return regionName;
}

/**
 * @typedef Daypart
 * @property {string} start
 * @property {string} end
 */

/**
 * convert and merge daypart hours to UTC hours
 * @param {Daypart[]} parts
 * @param fromTimezone - from timezone
 * @param toTimezone - to timezone
 * @returns {Dayparts[]} converted
 */
export function convertDaypartsByTimezone(parts, fromTimezone, toTimezone, startDate) {
    var zoneDiff =
        moment(startDate)
            .tz(fromTimezone)
            .zone() -
        moment(startDate)
            .tz(toTimezone)
            .zone();

    var convertedParts = _.map(parts, function(part) {
        var adjustedStart = moment.tz(part.start, 'HH:mm', fromTimezone).add(zoneDiff, 'minutes');
        var adjustedEnd = moment.tz(part.end, 'HH:mm', fromTimezone).add(zoneDiff, 'minutes');

        if (adjustedStart.hour() > adjustedEnd.hour()) {
            // split
            return [
                {
                    start: '00:00',
                    end: adjustedEnd.format('HH:mm'),
                },
                {
                    start: adjustedStart.format('HH:mm'),
                    end: '24:00',
                },
            ];
        } else {
            return {
                start: adjustedStart.format('HH:mm'),
                end: adjustedEnd.format('HH:mm'),
            };
        }
    });

    convertedParts = _.flatten(convertedParts);

    return convertedParts;
}

export function mergeTimeranges(timeRanges) {
    // merge
    var sequence = [];

    _.each(timeRanges, function(part) {
        sequence.push({ time: part.start, type: 'start' });
        sequence.push({ time: part.end, type: 'end' });
    });

    sequence = _.sortBy(sequence, 'time');

    var ranges = [];
    var mark = 0;
    var currentStart;

    _.each(sequence, function(point) {
        if (point.type === 'start') {
            if (mark === 0) {
                // this is the start of a section
                currentStart = point.time;
            }
            mark += 1;
        } else {
            if (mark === 1) {
                // this is the end of a section
                ranges.push({
                    start: currentStart,
                    end: point.time,
                });
            }
            mark -= 1;
        }
    });

    // clean up
    // when adjacent range is connected, merge them
    var ret = _.reduce(
        ranges,
        function(acc, ele) {
            if (acc.length > 0) {
                var last = _.last(acc);
                if (last.end === ele.start) {
                    // merge adjacent
                    last = acc.pop();
                    acc.push({ start: last.start, end: ele.end });
                    return acc;
                }
            }
            acc.push(ele);

            return acc;
        },
        []
    );

    // when the range is empty, remove it
    var idx;
    var isEmptyRange = function(r) {
        return r.start === r.end;
    };

    idx = _.findIndex(ret, isEmptyRange);
    while (idx !== -1) {
        ret.splice(idx, 1);
        idx = _.findIndex(ret, isEmptyRange);
    }
    return ret;
}

/**
 * Assemble a new date {moment} object based on date object and timezone
 *
 * @param {moment} date
 * @param {string} targetTimezone - string from moment timezone
 */

export function convertTimezoneOnly(date, targetTimezone) {
    if (_.isEmpty(date)) {
        return null;
    }

    var year = date.year();
    var month = date.month();
    var day = date.date();
    var hour = date.hour();
    var minute = date.minute();

    var retDate = moment.tz(targetTimezone);

    retDate.year(year);
    retDate.month(month);
    retDate.date(day);
    retDate.hour(hour);
    retDate.minute(minute);

    return retDate;
}

/**
 * Calculate the width of each components for bullet graph
 * @param m - either campaign/ad
 * @returns {
 *  wx: width for before beginning of today,
 *  wy: width for today (maybe shorter than 1 day),
 *  wz: width for after the end of today}
 */
export function getBulletGraphAttributes(m) {
    var wx,
        wy,
        wz,
        tx,
        ty,
        tz,
        t = m.getDuration(),
        s = moment.utc(m.get('start')).valueOf(),
        e = moment.utc(m.get('end')).valueOf();
    var beginOfToday = moment
        .utc()
        .startOf('day')
        .valueOf();
    var endOfToday = moment
        .utc()
        .endOf('day')
        .valueOf();
    var rounder = 1000;

    if (isNaN(s) || isNaN(e)) {
        return { wx: 0, wy: 0, yz: 0 };
    }

    tx = Math.ceil((Math.min(Math.max(beginOfToday - s, 0), t) / t) * rounder);
    ty = Math.ceil((Math.max(Math.min(endOfToday, e) - beginOfToday, 0) / t) * rounder);
    tz = rounder - tx - ty;

    wx = numeral(tx / rounder).format('0.0%');
    wy = numeral(ty / rounder).format('0.0%');
    wz = numeral(tz / rounder).format('0.0%');

    return {
        wx: wx,
        wy: wy,
        wz: wz,
    };
}

/**
 * @function formattingFn
 * @param {string | array} v - values to be formatted
 * @return {string | ReactComponent} displayable object for react
 */

/**
 * @function formatter
 * @param {string} key - key of the object with history
 * @param {object} snapshot - snapshot for current version
 * @return {formattingFn}
 */

/**
 * Transform Data to be usable for history widget
 * *
 * @param {Object} data - returned from history API
 * @param {Object} attrMappings - attrName: displayName mappings for the entity
 * @param {formatter} formatter
 * @returns {History} - refer to scripts/widgets/history/index.jsx
 *
 */
export function historyFormatter(data, attrMappings, formatter, alias) {
    let _alias = alias;

    // filter out empty data and make sure it's sorted by date
    const _data = _.sortBy(
        _.filter(data, function(d) {
            return !_.isEmpty(d.patch);
        }),
        'date'
    ).reverse();

    var metas = _data.map(function(d) {
        return {
            comment: d.comment,
            time: d.date,
            author: d.user.first_name + ' ' + d.user.last_name,
        };
    });

    var containAlias = function(k, snapshot) {
        if (!_.isObject(_alias)) {
            return false;
        }

        var a = _alias[k];
        if (_.isFunction(a)) {
            _alias = a(snapshot);
            return _.includes(patchKeys, aliasKey);
        }
    };

    var attrs = _.map(attrMappings, function(a, k) {
        return {
            name: a,
            changes: _data.map(function(d, idx) {
                // here we do formatting for _data
                var val = d.snapshot[k];
                var prevVal = _.isArray(val) ? [] : '';
                var format = formatter(k, d.snapshot) || _.identity;
                var patchKeys = _.keys(d.patch);

                if (idx !== 0) {
                    prevVal = _data[idx - 1].snapshot[k];
                }

                return {
                    from: format(prevVal),
                    to: format(val),
                    isChanged: _.includes(patchKeys, k) || containAlias(k, d.snapshot),
                };
            }),
        };
    });

    return { metas: metas, attrs: attrs };
}

export function getFormattedTimezones() {
    var tzs = [];
    _.each(timezones, function(cities, countryCode) {
        _.each(cities, function(city) {
            var formattedName;

            // region is not null
            if (city[3] !== null) {
                formattedName = city[1] + ', ' + city[3] + ' — ' + city[2];
            } else {
                formattedName = city[1] + ' — ' + city[2];
            }

            tzs.push({ value: city[0], label: formattedName });
        });
    });

    return tzs;
}

import _ from 'lodash';

export default function selector(storeState) {
    const draft = _.get(storeState, `conversionsOverview.draft`, []);
    const isLoading = _.get(storeState, `conversionsOverview.isLoading`, false);
    const isSaving = _.get(storeState, `conversionsOverview.isSaving`, false);
    const errors = _.get(storeState, `conversionsOverview.errors`, {});
    const showErrors = _.get(storeState, `conversionsOverview.showErrors`, false);
    const mode = _.get(storeState, `conversionsOverview.mode`, 'view');
    const campaign = _.get(storeState, `conversionsOverview.campaign`, {});
    const ownOrganization = _.get(storeState, `conversionsOverview.ownOrganization`, {});
    const deletedConversionWithAffectedAdLines = _.get(
        storeState,
        `conversionsOverview.deletedConversionWithAffectedAdLines`
    );
    const isDeleteConversionWarningOpen = _.get(
        storeState,
        `conversionsOverview.isDeleteConversionWarningOpen`
    );
    return {
        campaign,
        draft,
        isLoading,
        isSaving,
        errors,
        showErrors,
        mode,
        deletedConversionWithAffectedAdLines,
        isDeleteConversionWarningOpen,
        ownOrganization,
    };
}

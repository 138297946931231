import { regEx } from 'utils/regular-expression-library';

function invalidEmail(email) {
    return !regEx.email.test(email);
}

const validationRules = {
    email(value) {
        if (typeof value !== 'string' || (typeof value === 'string' && value === '')) {
            return 'Email is required.';
        } else if (typeof value === 'string' && value !== '' && invalidEmail(value)) {
            return 'Email is invalid.';
        }
    },
    first_name(value) {
        if (typeof value !== 'string' || (typeof value === 'string' && value === '')) {
            return 'First name is required.';
        }
    },

    last_name(value) {
        if (typeof value !== 'string' || (typeof value === 'string' && value === '')) {
            return 'Last name is required.';
        }
    },

    global_role(value) {
        if (typeof value !== 'string' || (typeof value === 'string' && value === '')) {
            return 'Must choose a role for this user.';
        }
    },
};

export default validationRules;

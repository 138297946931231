import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';
import Geoboxes from 'forms/ad-form/modules/geo-boxes';
import { getGeofencing } from 'containers/ad-summary';
import shallowCompare from 'utils/shallow-compare';
import selector from './selector';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { getGeoFencingWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

export const GeoFencingForm = connect(selector)(
    class GeoFencingForm extends React.Component {
        state = { lastCopiedAd: null };

        render() {
            const { ad, ads, campaign, onSave } = this.props;
            const adPlatforms = ad
                ? _.get(ad, 'platforms')
                : _(ads)
                      .map(ad => ad.platforms)
                      .flatten()
                      .uniq()
                      .value();
            return (
                <BaseColumnForm
                    ad={ad}
                    ads={ads}
                    campaign={campaign}
                    headline="Geofencing"
                    fields={['geoboxes']}
                    shouldRefreshPredictionsOnSave={true}
                    onSave={onSave}
                    onCancel={onSave}
                    onCopyAd={ad => this.setState({ lastCopiedAd: ad.id })}
                    columnName="Geofencing"
                >
                    {(updateDraft, shouldShowErrors, errors, draft) => {
                        let warningData = {};
                        if (campaign.isCrossPlatformCampaign) {
                            warningData = getGeoFencingWarning({
                                geofence: draft.geoboxes,
                                platforms: adPlatforms,
                            });
                        }

                        return (
                            <React.Fragment>
                                <Geoboxes
                                    key={this.state.lastCopiedAd}
                                    value={draft.geoboxes}
                                    errors={errors.geoboxes}
                                    showErrors={shouldShowErrors}
                                    onChange={val => updateDraft({ geoboxes: val })}
                                />
                                {campaign.isCrossPlatformCampaign && warningData.hasWarning && (
                                    <React.Fragment>
                                        <br />
                                        <WarningText
                                            message={warningData.message}
                                            title={warningData.title}
                                            severity={warningData.severity}
                                        />
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        );
                    }}
                </BaseColumnForm>
            );
        }
    }
);
export class GeoFencingSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, error } = this.props;

        const hasExceededHeight = getGeofencing(ad.geoboxes).length > 3;
        return (
            <div>
                {error ? (
                    <div>{error}</div>
                ) : (
                    <div>
                        <div
                            className={classnames({
                                'media-plan-form__overview-table-expand': hasExceededHeight,
                                'media-plan-form__overview-table-expand--is-active': this.props
                                    .isExpanded,
                            })}
                        >
                            <div className="media-plan-form__overview-table-geo-group">
                                {ad.geoboxes.length > 0 && <div>Geofencing</div>}
                                {_.map(getGeofencing(ad.geoboxes), geobox => (
                                    <div key={geobox}>{geobox}</div>
                                ))}
                            </div>

                            {hasExceededHeight && (
                                <i
                                    onClick={this.props.toggleMore}
                                    aria-hidden="true"
                                    className={classnames({
                                        'fa fa-chevron-down media-plan-form__overview-table-expand-icon': true,
                                        'media-plan-form__overview-table-expand-icon--is-active': this
                                            .props.isExpanded,
                                    })}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

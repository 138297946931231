import _ from 'lodash';
import toastr from 'toastr';
import { GraphQLClient } from 'graphql-request';

import { createHttp, getNextBase } from 'utils/http';
import { serialize_EN_279 as serializeForApi } from 'services/resources/apps';
import { createServerErrorAsToastr } from 'common/server-errors-toastr';
import Apps from 'states/resources/apps/actions';

import { NAME } from './reducer';
import { PAGINATION_LIMIT } from './constants';
import Organizations from 'states/resources/organizations/actions';

const http = createHttp();

const actions = {
    getBlackList: () => {
        return async dispatch => {
            try {
                dispatch({
                    type: 'APP_LISTS__FETCH_BLACKLISTS__START',
                    payload: {},
                });
                const http = createHttp();
                const query = `
                    query getBlacklists {
                        ownOrganization{
                            id
                            blacklistedAppsAndSites
                        }
                    }
                `;
                const data = await http.graphql(query, {});
                dispatch({
                    type: 'APP_LISTS__FETCH_BLACKLISTS__END',
                    payload: {
                        blackList: data.ownOrganization.blacklistedAppsAndSites,
                    },
                });
            } catch (err) {
                toastr.error('App Lists failed to be fetched. Please try it again later.');
            }
        };
    },

    updateBlackList: blackListId => {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'APP_LISTS__UPDATE_BLACKLISTS__START',
                    payload: { loadingId: blackListId },
                });
                const blackList = [..._.get(getState(), 'appLists.blackList', [])];
                blackList.indexOf(blackListId) > -1
                    ? blackList.splice(blackList.indexOf(blackListId), 1)
                    : blackList.push(blackListId);
                const orgId = _.get(getState(), 'profile.organizationId');

                const payload = {
                    blacklistedAppsAndSites: blackList,
                };
                const org = await dispatch(Organizations.update(orgId, payload));
                dispatch({
                    type: 'APP_LISTS__UPDATE_BLACKLISTS__END',
                    payload: {
                        blackList: org.blacklistedAppsAndSites,
                    },
                });
            } catch (err) {
                toastr.error('Update blacklist failed to be fetched. Please try it again later.');
            }
        };
    },

    getApplists: () => {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'APP_LISTS__FETCH_APPLISTS__START',
                    payload: {},
                });

                await dispatch(actions.getAdvertiserOptions());

                const { filters } = _.get(getState(), 'appLists');

                const http = createHttp();
                const query = `
                    query getApplists($advertiserIds: [String]) {
                        applists(filters: {advertiserIds:  $advertiserIds}) {
                            id
                            name
                            advertiserId
                        }
                    }
                `;

                const variables = {
                    advertiserIds: filters.advertiserIds,
                };
                const data = await http.graphql(query, variables);
                dispatch({
                    type: 'APP_LISTS__FETCH_APPLISTS__END',
                    payload: {
                        appLists: data.applists,
                    },
                });
            } catch (err) {
                toastr.error('App Lists failed to be fetched. Please try it again later.');
            }
        };
    },

    getApplist: appListId => {
        return async dispatch => {
            try {
                const http = createHttp();
                const query = `
                    query getApplist($appListId: String, $limit: Int, $page: Int) {
                        applist(appListId: $appListId) {
                            id
                            name
                            appCategories(limit: $limit, page: $page) {
                                id
                                app_name_decrypted
                                bundle_id
                                app_url
                                app_cat_name
                            }
                            domains
                            bundle_ids
                            connected_tv_ids
                            dooh_board_ids
                            advertiserId
                        }
                    }
                `;

                const variables = {
                    appListId,
                };

                variables.limit = PAGINATION_LIMIT;
                variables.page = 0;

                const { applist } = await http.graphql(query, variables);
                const bundleIdsChunks = _.chunk(applist.bundle_ids, PAGINATION_LIMIT);

                const selectedBundleIds = bundleIdsChunks[0] ? bundleIdsChunks[0] : [];

                const draft = {
                    bundle_ids: selectedBundleIds,
                    allBundleIds: applist.bundle_ids,
                    domains: applist.domains,
                    bundleIdInput: selectedBundleIds.join('\n'),
                    domainInput: applist.domains ? applist.domains.join('\n') : '',
                    connected_tv_ids: applist.connected_tv_ids,
                    connectedTVIdsInput: applist.connected_tv_ids ? applist.connected_tv_ids.join('\n') : '',
                    dooh_board_ids: applist.dooh_board_ids,
                    doohBoardIdsInput: applist.dooh_board_ids ? applist.dooh_board_ids.join('\n') : '',
                    name: applist.name,
                    advertiserId: applist.advertiserId,
                };

                dispatch({
                    type: 'APP_LISTS__FETCH_APPLIST__END',
                    payload: {
                        appList: applist,
                        draft,
                    },
                });
            } catch (err) {
                toastr.error('App List failed to be fetched. Please try it again later.');
            }
        };
    },

    getAdvertiserOptions: () => {
        return async (dispatch, getState) => {
            try {
                const graphqlUrl = getNextBase() + 'graphql';

                const client = new GraphQLClient(graphqlUrl, {
                    headers: {
                        ...http._getHeaders(),
                        Authorization: _.get(getState(), 'profile.authToken.auth_token'),
                    },
                });
                const query = `
                {
                    organizations(filters: {relationship: "child", type: ["client", "co_managed_client"], client_type: ["advertiser"]}) {
                        id
                        name
                        type
                        client_type
                    }
                    ownOrganization {
                        id
                        name
                        type
                        users {
                            first_name
                            last_name
                            id
                            suspended
                            global_role
                        }
                    }
                }
            `;
                const data = await client.request(query);
                const advertiserOptions = data.organizations;
                const ownOrganization = data.ownOrganization;

                dispatch({
                    type: 'APP_LISTS__FETCH_ADVERTISERS_SUCESS',
                    payload: { advertiserOptions, ownOrganization },
                });
            } catch (error) {
                console.log('error occuered while fetching org/advertiser data', error);
            }
        };
    },

    applyFilters: selectedFilters => {
        return async dispatch => {
            dispatch({
                type: 'APP_LISTS__APPLY_FILTERS',
                payload: selectedFilters,
            });
            await dispatch(actions.getApplists());
        };
    },

    openAppListEditorNew() {
        return dispatch => {
            dispatch({
                type: `APP_LISTS__EDITOR__APPLIST__NEW`,
                payload: {},
            });
        };
    },

    openAppListEditor(appListId, blackList) {
        return dispatch => {
            dispatch({
                type: `APP_LISTS__EDITOR__APPLIST__EDIT`,
                payload: { appListId, blackList },
            });
        };
    },

    closeAppListEditor() {
        return dispatch => {
            dispatch({
                type: `APP_LISTS__EDITOR__APPLIST__CLOSE`,
                payload: {},
            });
        };
    },

    cancel() {
        return dispatch => {
            dispatch({
                type: `APP_LISTS__EDITOR__CANCEL`,
                payload: {},
            });
        };
    },

    showErrors() {
        return dispatch => {
            dispatch({
                type: `APP_LISTS__EDITOR__SHOW_ERRORS`,
                payload: {},
            });
        };
    },

    updateBlacklistStatus(isBlacklisted) {
        return dispatch => {
            dispatch({
                type: `APP_LISTS__EDITOR__BLACKLIST__CHECKED`,
                payload: { isBlacklisted: isBlacklisted },
            });
        };
    },

    sync({ appListId, data, page }) {
        return (dispatch, getState) => {
            const session_appListEditor = _.cloneDeep(_.get(getState(), `${NAME}`, void 0));
            const viewState_prev = _.cloneDeep(_.get(session_appListEditor, `viewState`, void 0));
            const draft_prev = _.cloneDeep(_.get(session_appListEditor, `draft`, void 0));

            let draft_next;

            if (viewState_prev === 'inputing') {
                const bundle_ids = getProcessedInput(data.bundleIdInput);
                const domains = getProcessedInput(data.domainInput);
                const connected_tv_ids = getProcessedInput(data.connectedTVIdsInput);
                const dooh_board_ids = getProcessedInput(data.doohBoardIdsInput);

                draft_next = {
                        ...draft_prev,
                        domains,
                        bundleIdInput: _.cloneDeep(data.bundleIdInput),
                        domainInput: _.cloneDeep(data.domainInput),
                        connected_tv_ids,
                        connectedTVIdsInput: _.cloneDeep(data.connectedTVIdsInput),
                        dooh_board_ids,
                        doohBoardIdsInput: _.cloneDeep(data.doohBoardIdsInput),
                    };

                dispatch({
                    type: `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC`,
                    payload: { draft: draft_next },
                });

                if (bundle_ids.length !== 0) {
                    const bundleIdsChunk = _.chunk(bundle_ids, PAGINATION_LIMIT)[page ? page : 0];
                    dispatch(Apps.batchFetch(bundleIdsChunk)).then(
                        _bundles => {
                            const _draft_prev = _.cloneDeep(
                                _.get(getState(), `${NAME}.draft`, void 0)
                            );
                            draft_next = {
                                ..._draft_prev,
                                bundle_ids: bundleIdsChunk,
                                allBundleIds: bundle_ids,
                            };

                            dispatch({
                                type: `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_SUCCESS`,
                                payload: {
                                    draft: draft_next,
                                    appList: {
                                        ...session_appListEditor.appList,
                                        appCategories: _bundles.map(appCategory => {
                                            return {
                                                ...appCategory,
                                                app_cat_name: appCategory.category,
                                                app_url: appCategory.url,
                                                app_name_decrypted: appCategory.name,
                                            };
                                        }),
                                    },
                                },
                            });
                        },
                        () => {
                            dispatch({
                                type: `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_FAIL`,
                                payload: {
                                    syncError: 'Server Error while convertion bundle Ids',
                                },
                            });
                        }
                    );
                } else if (domains.length || connected_tv_ids.length > 0 || dooh_board_ids.length > 0) {
                    dispatch({
                        type: `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_SUCCESS`,
                        payload: {
                            appListId,
                            draft: _.get(getState(), `${NAME}.draft`),
                            appList: {
                                ..._.get(getState(), `${NAME}.appList`, {}),
                            },
                        },
                    });
                } else {
                    dispatch({
                        type: `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_FAIL`,
                        payload: { syncError: 'No lists to convert' },
                    });
                }
            }

            if (viewState_prev === 'editing') {
                draft_next = {
                    ...draft_prev,
                };

                dispatch({
                    type: `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC`,
                    payload: {
                        appListId,
                        draft: draft_next,
                    },
                });

                const bundleIdInput = draft_prev.allBundleIds
                    ? draft_prev.allBundleIds.join('\n')
                    : '';
                const domainInput = draft_prev.domains ? draft_prev.domains.join('\n') : '';
                const connectedTVIdsInput = draft_prev.connected_tv_ids ? draft_prev.connected_tv_ids.join('\n') : '';
                const doohBoardIdsInput = draft_prev.dooh_board_ids ? draft_prev.dooh_board_ids.join('\n') : '';

                draft_next = {
                    ...draft_prev,
                    bundleIdInput,
                    domainInput,
                    connectedTVIdsInput,
                    doohBoardIdsInput,
                };

                dispatch({
                    type: `APP_LISTS__EDITOR__USER_INPUT_AND_BUNDLE_IDS__SYNC_SUCCESS`,
                    payload: { appListId, draft: draft_next },
                });
            }
        };
    },

    changePage({ page }) {
        return (dispatch, getState) => {
            const sessionAppListEditor = _.cloneDeep(_.get(getState(), `${NAME}`, void 0));
            const draftPrev = _.cloneDeep(_.get(sessionAppListEditor, `draft`, void 0));

            dispatch({
                type: `APP_LISTS__EDITOR__CHANGE_PAGE`,
                payload: {},
            });

            if (draftPrev.allBundleIds.length !== 0) {
                const bundleIdsChunk = _.chunk(draftPrev.allBundleIds, PAGINATION_LIMIT)[
                    page ? page : 0
                ];
                dispatch(Apps.batchFetch(bundleIdsChunk)).then(
                    bundles => {
                        const draftPrevRaw = _.cloneDeep(
                            _.get(getState(), `${NAME}.draft`, void 0)
                        );
                        const draftNext = {
                            ...draftPrevRaw,
                            bundle_ids: bundleIdsChunk,
                        };

                        dispatch({
                            type: `APP_LISTS__EDITOR__CHANGE_PAGE__SUCCESS`,
                            payload: {
                                draft: draftNext,
                                appList: {
                                    ...sessionAppListEditor.appList,
                                    appCategories: bundles.map(appCategory => {
                                        return {
                                            ...appCategory,
                                            app_cat_name: appCategory.category,
                                            app_url: appCategory.url,
                                            app_name_decrypted: appCategory.name,
                                        };
                                    }),
                                },
                            },
                        });
                    },
                    () => {
                        dispatch({
                            type: `APP_LISTS__EDITOR__CHANGE_PAGE__FAIL`,
                            payload: {
                                syncError: 'Server Error while convertion bundle Ids',
                            },
                        });
                    }
                );
            } else {
                dispatch({
                    type: `APP_LISTS__EDITOR__CHANGE_PAGE__FAIL`,
                    payload: { syncError: 'No lists to convert' },
                });
            }
        };
    },

    updateDraft(formData) {
        return (dispatch, getState) => {
            const draft_prev = _.cloneDeep(_.get(getState(), `${NAME}.draft`, void 0));

            const propertyToUpdate_name = Object.keys(formData)[0];
            const propertyToUpdate_value = formData[propertyToUpdate_name];

            const draft_next = {
                ...draft_prev,
                [propertyToUpdate_name]: propertyToUpdate_value,
            };

            dispatch({
                type: `APP_LISTS__EDITOR__APPLIST_DRAFT__UPDATE`,
                payload: {
                    formData,
                    draft: draft_next,
                },
            });
        };
    },

    submit(appListId) {
        return (dispatch, getState) => {
            const _draft = _.cloneDeep(_.get(getState(), `${NAME}.draft`, void 0));
            const payload_beforeSerialized = {
                ..._draft,
                bundle_ids: _draft.allBundleIds ? _draft.allBundleIds : _draft.bundle_ids,
            };
            const payloadSerialized = serializeForApi(payload_beforeSerialized);
            const appLists = _.get(getState(), `appLists.appLists`);
            const isBlacklisted = _.get(getState(), `appLists.isBlacklisted`);

            const isDraftValid = _.get(getState(), `${NAME}.isDraftValid`, void 0);

            if (!isDraftValid) {
                dispatch(actions.showErrors(appListId));
                return;
            }

            dispatch({
                type: `APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT`,
                payload: { appListId },
            });

            const orgId = _.get(getState(), 'profile.organizationId', void 0);

            if (appListId) {
                dispatch(Apps.patch(orgId, appListId, payloadSerialized))
                    .then(results => {
                        const updatedAppLists = [];

                        _.each(appLists, appList => {
                            if (appList.id === results.id) {
                                updatedAppLists.push({
                                    id: results.id,
                                    name: results.name,
                                    advertiserId: results.advertiserId,
                                });
                            } else {
                                updatedAppLists.push(appList);
                            }
                        });

                        dispatch({
                            type: `APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT_SUCCESS`,
                            payload: {
                                appListId,
                                results,
                                appLists: updatedAppLists,
                            },
                        });
                        return results;
                    })
                    .then(
                        () => {
                            dispatch({
                                type: `APP_LISTS__EDITOR__APPLIST__TEARDOWN`,
                                payload: { appListId },
                            });
                        },
                        error => {
                            createServerErrorAsToastr(error); // throw toaster error for server errors
                            throw new Error('Cannot patch applist: ', error);
                        }
                    )
                    .then(() => isBlacklisted && dispatch(actions.updateBlackList(appListId)));
            }

            if (!appListId) {
                dispatch(Apps.create(orgId, payloadSerialized))
                    .then(newAppList => {
                        dispatch({
                            type: `APP_LISTS__EDITOR__BUNDLE_IDS__SUBMIT_SUCCESS`,
                            payload: {
                                newAppList,
                                appLists: _.concat(
                                    {
                                        id: newAppList.id,
                                        name: newAppList.name,
                                        advertiserId: newAppList.advertiserId,
                                    },
                                    appLists
                                ),
                            },
                        });
                        return newAppList;
                    })
                    .then(newAppList => {
                        dispatch({
                            type: `APP_LISTS__EDITOR__APPLIST__TEARDOWN`,
                            payload: { appListId },
                        });

                        return newAppList;
                    })
                    .then(
                        newAppList =>
                            isBlacklisted && dispatch(actions.updateBlackList(newAppList.id))
                    );
            }
        };
    },

    removeBundle(appListId, bundle_id) {
        return (dispatch, getState) => {
            const session_appListEditor = _.get(getState(), `${NAME}`, void 0);
            const draft_prev = _.cloneDeep(_.get(session_appListEditor, `draft`, void 0));
            const bundle_ids_next = _.pull(draft_prev.bundle_ids, bundle_id);
            const draft_next = {
                ...draft_prev,
                bundleIdInput: bundle_ids_next.join('\n'),
                allBundleIds: _.filter(draft_prev.allBundleIds, bundleId => {
                    return bundle_id === bundleId ? false : true;
                }),
            };
            dispatch({
                type: `APP_LISTS__EDITOR__BUNDLE_ID__REMOVE`,
                payload: { appListId, draft: draft_next },
            });
        };
    },

    removeDomain(appListId, domain) {
        return (dispatch, getState) => {
            const session_appListEditor = _.get(getState(), `${NAME}`, void 0);
            const draft_prev = _.cloneDeep(_.get(session_appListEditor, `draft`, void 0));
            const domains_next = _.pull(draft_prev.domains, domain);
            const draft_next = {
                ...draft_prev,
                domainInput: domains_next.join('\n'),
            };
            dispatch({
                type: `APP_LISTS__EDITOR__DOMAIN__REMOVE`,
                payload: { appListId, draft: draft_next },
            });
        };
    },

    removeConnectedTV(appListId, connected_tv_id) {
        return (dispatch, getState) => {
            const session_appListEditor = _.get(getState(), `${NAME}`, void 0);
            const draft_prev = _.cloneDeep(_.get(session_appListEditor, `draft`, void 0));
            const connected_tv_id_next = _.pull(draft_prev.connected_tv_ids, connected_tv_id);
            const draft_next = {
                ...draft_prev,
                connectedTVIdsInput: connected_tv_id_next.join('\n'),
            };
            dispatch({
                type: `APP_LISTS__EDITOR__CONNECTED_TV__REMOVE`,
                payload: { appListId, draft: draft_next },
            });
        };
    },

    removeDoohBoardId(appListId, doohBoardId) {
        return (dispatch, getState) => {
            const session_appListEditor = _.get(getState(), `${NAME}`, void 0);
            const draft_prev = _.cloneDeep(_.get(session_appListEditor, `draft`, void 0));
            const dooh_board_ids_next = _.pull(draft_prev.dooh_board_ids, doohBoardId);
            const draft_next = {
                ...draft_prev,
                doohBoardIdsInput: dooh_board_ids_next.join('\n'),
            };
            dispatch({
                type: `APP_LISTS__EDITOR__DOOH_BOARD_ID__REMOVE`,
                payload: { appListId, draft: draft_next },
            });
        };
    },
};

// ----------------------------------
// Helper function for Sync action
// ----------------------------------
function getProcessedInput(input) {
    return _(input.split('\n'))
        .map(id => id.trim())
        .filter(id => id !== '')
        .uniq()
        .value();
}
export default actions;

import _ from 'lodash';

export default function selector(storeState, props) {
    const isFormOpen = _.get(storeState, 'adminUsersFormV2.isFormOpen');
    const draft = _.get(storeState, 'adminUsersFormV2.draft');
    const isLoading = _.get(storeState, 'adminUsersFormV2.isLoading');
    const errors = _.get(storeState, 'adminUsersFormV2.errors');
    const showErrors = _.get(storeState, 'adminUsersFormV2.showErrors');
    const isSubmitting = _.get(storeState, 'adminUsersFormV2.isSubmitting');
    const organizationType = _.get(storeState, 'adminUsersFormV2.organizationType');
    const mode = _.get(storeState, 'adminUsersFormV2.mode');
    return {
        isFormOpen,
        draft,
        isLoading,
        errors,
        showErrors,
        isSubmitting,
        organizationType,
        mode,
    };
}

import PropTypes from 'prop-types';
import React from 'react';

class TopBarLayout extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        barHeight: PropTypes.number,
        topBar: PropTypes.node,
        workspace: PropTypes.node,
    };

    render() {
        const className = this.props.className + ' ef3-topBarLayout';
        const workspaceHeight = 'calc(100% - ' + this.props.barHeight + 'px)';

        return (
            <div className={className}>
                <div className="ef3-topBarLayout_bar" style={{ height: this.props.barHeight }}>
                    {this.props.topBar}
                </div>
                <div className="ef3-topBarLayout_workspace" style={{ height: workspaceHeight }}>
                    {this.props.workspace}
                </div>
            </div>
        );
    }
}

export default TopBarLayout;

import _ from 'lodash';
import moment from 'moment';
import React from 'react';

class RecipientSelector extends React.Component {
    onClickUser = userId => {
        const { onChange, recipients } = this.props;
        onChange('recipients')(
            _.map(recipients, recipient => {
                const users = _.map(recipient.users, user => {
                    if (user.id !== userId) {
                        return user;
                    }

                    return {
                        ...user,
                        checked: !user.checked,
                    };
                });
                return {
                    ...recipient,
                    users,
                };
            })
        );
    };

    onHandleCollapse = orgId => {
        const { onChange, recipients } = this.props;
        onChange('recipients')(
            _.map(recipients, recipient => {
                if (recipient.organization !== orgId) {
                    return {
                        ...recipient,
                    };
                }

                return {
                    ...recipient,
                    expanded: !recipient.expanded,
                };
            })
        );
    };

    onClickSelectAll = (orgId, isExpanded) => {
        if (!isExpanded) {
            return;
        }
        const { onChange, recipients } = this.props;
        onChange('recipients')(
            _.map(recipients, recipient => {
                if (recipient.organization !== orgId) {
                    return {
                        ...recipient,
                    };
                }

                const users = _.map(recipient.users, user => {
                    return {
                        ...user,
                        checked: true,
                    };
                });
                return {
                    ...recipient,
                    users,
                };
            })
        );
    };

    onClickSelectNone = (orgId, isExpanded) => {
        if (!isExpanded) {
            return;
        }
        const { onChange, recipients } = this.props;
        onChange('recipients')(
            _.map(recipients, recipient => {
                if (recipient.organization !== orgId) {
                    return {
                        ...recipient,
                    };
                }

                const users = _.map(recipient.users, user => {
                    return {
                        ...user,
                        checked: false,
                    };
                });
                return {
                    ...recipient,
                    users,
                };
            })
        );
    };

    render() {
        const { recipients } = this.props;

        return (
            <div className="ef3-scheduledReport_recipientSelector">
                <div className="ef3-scheduledReport_recipientSelector_header">
                    <span className="ef3-scheduledReport_recipientSelector_header_organizationText">
                        Organization
                    </span>
                    <span className="ef3-scheduledReport_recipientSelector_header_recipientText">
                        Recipients
                    </span>
                </div>

                <div className="ef3-scheduledReport_recipientSelector_body">
                    {_.map(recipients, (recipient, index) => {
                        const userList = _.map(recipient.users, (usr, ind) => {
                            return (
                                <label key={ind + 'label'}>
                                    <input
                                        onChange={() => this.onClickUser(usr.id)}
                                        type="checkbox"
                                        checked={usr.checked}
                                    />
                                    <span>{usr.email}</span>
                                </label>
                            );
                        });

                        return (
                            <div
                                key={index + 'recipient'}
                                className="ef3-scheduledReport_recipientSelector_body_row"
                            >
                                <div
                                    className="ef3-scheduledReport_recipientSelector_body_row_client"
                                    onClick={() => this.onHandleCollapse(recipient.organization)}
                                >
                                    <span>{recipient.clientName}</span>
                                </div>
                                <div className="ef3-scheduledReport_recipientSelector_body_row_recipient">
                                    <div
                                        className={
                                            'ef3-scheduledReport_recipientSelector_body_row_recipient_selectList' +
                                            (recipient.expanded ? '_expanded' : '_collapsed')
                                        }
                                    >
                                        <span
                                            onClick={() =>
                                                this.onHandleCollapse(recipient.organization)
                                            }
                                        >
                                            {_.filter(recipient.users, user => user.checked).length}
                                            /{recipient.users.length} users selected
                                        </span>
                                        <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={() =>
                                                this.onClickSelectAll(
                                                    recipient.organization,
                                                    recipient.expanded
                                                )
                                            }
                                        >
                                            Select All
                                        </button>
                                        <button
                                            className="btn btn-sm btn-secondary"
                                            onClick={() =>
                                                this.onClickSelectNone(
                                                    recipient.organization,
                                                    recipient.expanded
                                                )
                                            }
                                        >
                                            Select None
                                        </button>
                                    </div>

                                    <div
                                        className={
                                            'ef3-scheduledReport_recipientSelector_body_row_recipient' +
                                            (recipient.expanded ? '_expanded' : '_collapsed')
                                        }
                                    >
                                        {userList}
                                    </div>
                                </div>
                                <span className="ef3-scheduledReport_recipientSelector_body_row_recipient_arrow">
                                    {recipient.expanded ? (
                                        <i
                                            className="fa fa-chevron-up"
                                            onClick={() =>
                                                this.onHandleCollapse(recipient.organization)
                                            }
                                        />
                                    ) : (
                                        <i
                                            className="fa fa-chevron-down"
                                            onClick={() =>
                                                this.onHandleCollapse(recipient.organization)
                                            }
                                        />
                                    )}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default RecipientSelector;

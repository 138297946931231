import _ from 'lodash';

export default function selector(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const campaignDataShowing =
        _.get(storeState, `campaignPageContainer.viewBy`) === 'campaign' ? 'campaign' : 'flight';
    const campaignStats =
        campaignDataShowing === 'campaign'
            ? _.get(storeState, `campaignsOverview.campaign.metadata.metrics`)
            : _.get(storeState, `campaignsOverview.campaign.flight.metadata.metrics`);
    const campaignHealth = _.get(
        storeState,
        `campaignsOverview.campaign.metadata.progressData.health`
    );
    const isCampaignLoading = _.get(storeState, `campaignsOverview.isCampaignLoading`, true);
    const isAdsLoading = _.get(storeState, `campaignsOverview.isAdsLoading`, true);
    const currentFlight = _.get(storeState, 'campaignsOverview.campaign.flight');

    const campaignCreator = !campaign
        ? ''
        : storeState.profile.ownOrganization.users.map(user => {
              if (user.id === campaign.creatorId) return user.first_name + ' ' + user.last_name;
          });

    return {
        campaignStats,
        campaignHealth,
        campaign,
        currentFlight,
        isAdsLoading,
        isCampaignLoading,
        campaignStatsIsLoading: !campaignStats,
        organizationTimezone: storeState.profile.organizationTimezone,
        campaignCreator,
        campaignDataShowing,
    };
}

import _ from 'lodash';

function shallowCompare(a, b) {
    const aKeys = _.keys(a);
    const bKeys = _.keys(b);

    // If there is a difference in the number of keys, then the objects are not equal
    if (aKeys.length !== bKeys.length) {
        return false;
    }

    let match = true;
    _.each(aKeys, key => {
        match = match && a[key] === b[key];
        if (match === false) {
            return false;
        }
    });

    return match;
}

export default shallowCompare;

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import Separator from 'widgets-v5/separator';
import QuickDialog from '../quick-dialog';
import Menu, { MenuItem, MenuSeparator } from 'widgets-v5/menu';

export default class extends React.Component {
    static displayName = 'Dropdown';

    static propTypes = {
        buttonLabel: PropTypes.node.isRequired,
        children: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        disabled: PropTypes.bool,
        onClear: PropTypes.func,
        onClose: PropTypes.func,
        onOpen: PropTypes.func,
        dropUp: PropTypes.bool,
        clearable: PropTypes.bool,
    };

    static defaultProps = {
        onClose() {},
        onOpen() {},
        dropUp: false,
        clearable: false,
        bordered: true,
    };

    toggleMenu = (open, close, isOpen) => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    handleClear = e => {
        e.stopPropagation();
        this.props.onClear();
    };

    render() {
        return (
            <QuickDialog
                className={cn('dropdown-toggle__quick-dialog', this.props.className)}
                offsetTop
                animate={false}
                onClose={this.props.onClose}
                onOpen={this.props.onOpen}
                dropUp={this.props.dropUp}
                button={(open, close, isOpen) => (
                    <DropdownToggle
                        isLoading={this.props.isLoading}
                        disabled={this.props.disabled}
                        label={this.props.buttonLabel}
                        onClear={this.props.onClear ? this.handleClear : null}
                        clearable={this.props.clearable}
                        onClick={() => this.toggleMenu(open, close, isOpen)}
                        bordered={this.props.bordered}
                    />
                )}
                dialog={close => this.props.children(close)}
            />
        );
    }
}

export function DropdownToggle(props) {
    const onClick = props.disabled ? _.noop : props.onClick;
    return (
        <div
            className={cn({
                'dropdown-toggle': true,
                'dropdown-toggle--disabled': props.disabled,
                'dropdown-toggle--bordered': props.bordered,
            })}
            onClick={onClick}
        >
            <span
                className={cn('dropdown-toggle__text', {
                    'dropdown-toggle__text--bordered': props.bordered,
                    'dropdown-toggle__text--not-bordered': props.bordered === false,
                })}
            >
                {props.label}
            </span>
            <div className="dropdown-toggle__icons">
                {props.isLoading && (
                    <i className="fa fa-circle-o-notch dropdown-toggle__loading-icon" />
                )}
                {props.clearable && (
                    <i
                        className="fa fa-times dropdown-toggle__clear-icon"
                        onClick={props.onClear}
                    />
                )}
                {props.disabled && (
                    <i
                        className="fa fa-caret-down dropdown-toggle__dropdown-icon"
                        onClick={() => {}}
                    />
                )}
                {!props.disabled && (
                    <i
                        className="fa fa-caret-down dropdown-toggle__dropdown-icon"
                        onClick={props.onClick}
                    />
                )}
            </div>
        </div>
    );
}

import toastr from 'toastr';
import { createHttp } from 'utils/http';

const source_type = ['adobe'];

const actions = {
    init: () => {
        return async dispatch => {
            dispatch({
                type: 'ADOBE_DASHBOARD__INIT_START',
            });
            const { totalSegmentCount } = await dispatch(actions.getAudiences());

            dispatch({
                type: 'ADOBE_DASHBOARD__INIT_END',
                payload: { totalSegmentCount },
            });
        };
    },
    getAudiences: keywords => {
        return async (dispatch) => {
            try {
                const http = createHttp();
                const query = `
                    query getAudiences ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                            id
                            name
                            lastRefreshed
                            dataCost
                            uniques
                            _created
                            supports
                        }
                    }
                `;

                const variables = {
                    keywords,
                    source_type,
                    deprecated: false,
                };

                const data = await http.graphql(query, variables);

                dispatch({
                    type: 'ADOBE_DASHBOARD__FETCH_SEGMENTS_DATA__END',
                    payload: {
                        data: data.audienceSegments,
                    },
                });
                return { totalSegmentCount: data.audienceSegments.length };
            } catch (err) {
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

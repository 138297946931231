import { PermissionsMapping } from 'common/constants/role-intents';
import _ from 'lodash';
import c from 'common/constants/flux-events';
import toastr from 'toastr';

import { can } from 'services/can';

import UserActions from 'states/resources/users/actions';
import { adminUsers as Action_users } from 'containers/admin-users/actions';

import * as usersServices from 'services/resources/users';

export const adminUsersForm = {
    draftInit(userId, orgId) {
        return dispatch => {
            dispatch({
                type: c.ADMIN_USERS_FORM__INIT_START,
                payload: { userId, orgId },
            });

            if (userId) {
                dispatch(UserActions.get(userId)).then(
                    user => {
                        const draftFields = Object.keys(usersServices.draftDefaultValues);

                        // prepare default draft
                        const defaultDraft = {
                            ..._.cloneDeep(usersServices.draftDefaultValues),
                            ..._.pick(user, draftFields),
                            organization: orgId,
                        };

                        dispatch({
                            type: c.ADMIN_USERS_FORM__INIT_SUCCESS,
                            payload: { draft: defaultDraft, etag: user._etag },
                        });
                    },
                    httpErrors => {
                        dispatch({
                            type: c.ADMIN_USERS_FORM__INIT_FAIL,
                            payload: { httpErrors },
                        });
                    }
                );
            } else if (!userId) {
                // prepare default draft
                const defaultDraft = {
                    ..._.cloneDeep(usersServices.draftDefaultValues),
                    organization: orgId,
                };

                dispatch({
                    type: c.ADMIN_USERS_FORM__INIT_SUCCESS,
                    payload: { draft: defaultDraft },
                });
            }
        };
    },

    fieldChanged(userId, orgId, data) {
        return dispatch => {
            dispatch({
                type: c.ADMIN_USERS_FORM__FIELD_CHANGED,
                payload: { userId, data },
            });
        };
    },

    draftSubmit(userId, orgId) {
        return (dispatch, getState) => {
            const draft = _.get(getState(), `adminUsersForm.draft`, void 0);
            const etag = _.get(getState(), `adminUsersForm.etag`);
            dispatch({
                type: c.ADMIN_USERS_FORM__USER_SUBMIT,
                payload: { userId, orgId, draft },
            });

            const formErrors = _.get(getState(), 'adminUsersForm.formErrors', null);
            if (formErrors.length !== 0) {
                // can not send because there are erorrs in form
                return Promise.reject();
            }

            const meta = { userId };
            const payload = _.cloneDeep(usersServices.serializedForApi(draft, meta));

            dispatch({
                type: c.ADMIN_USERS_FORM__SUBMIT_START,
                payload: { userId, orgId, payload },
            });

            if (userId) {
                return dispatch(UserActions.update(userId, payload, etag)).then(
                    user => {
                        dispatch({
                            type: c.ADMIN_USERS_FORM__SUBMIT_SUCESS,
                            payload: { userId, user },
                        });

                        // Users for impersonation are stored separately and must be
                        // fetched separately
                        if (can(PermissionsMapping.IMPERSONATE)) {
                            dispatch({
                                type: c.IMPERSONATION_MODAL__REFRESH_USER_SUCCESS,
                                payload: {
                                    user,
                                },
                            });
                        }
                    },
                    httpErrors => {
                        dispatch({
                            type: c.ADMIN_USERS_FORM__SUBMIT_FAIL,
                            payload: { httpErrors },
                        });
                    }
                );
            } else if (!userId) {
                return dispatch(UserActions.create(draft)).then(
                    user => {
                        dispatch(Action_users.init(orgId));
                        dispatch({
                            type: c.ADMIN_USERS_FORM__SUBMIT_SUCESS,
                            payload: { userId, user },
                        });
                        toastr.success('Email notification sent', 'User created');

                        // Users for impersonation are stored separately and must be
                        // fetched separately
                        if (can(PermissionsMapping.IMPERSONATE)) {
                            dispatch({
                                type: c.IMPERSONATION_MODAL__REFRESH_USER_SUCCESS,
                                payload: {
                                    user,
                                },
                            });
                        }
                    },
                    httpErrors => {
                        // console.log('httpErrors: ', JSON.stringify(httpErrors, null, 4))
                        // httpErrors has the following shape:
                        // {
                        //     "message": "Bad Request",
                        //     "code": 400,
                        //     "body": [
                        //         {
                        //             "field": "email",
                        //             "error": "ERROR_GENERIC",
                        //             "message": "`dsfasdf@dsfasd.dfsdf` is not a valid e-mail address"
                        //         }
                        //     ],
                        //     "bodyText": "[{\"field\":\"email\",\"error\":\"ERROR_GENERIC\",\"message\":\"`dsfasdf@dsfasd.dfsdf` is not a valid e-mail address\"}]"
                        // }
                        dispatch({
                            type: c.ADMIN_USERS_FORM__SUBMIT_FAIL,
                            payload: { httpErrors },
                        });
                    }
                );
            }
        };
    },

    draftTearDown(userId) {
        return dispatch => {
            dispatch({
                type: c.ADMIN_USERS_FORM__TEARDOWN,
                payload: { userId },
            });
        };
    },
};

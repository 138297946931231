import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

class GeoFencingLayerFinder extends React.Component {
    static propTypes = {
        selectGeoLayer: PropTypes.func,
        selectGeoCategory: PropTypes.func,
        includedCustomLayers: PropTypes.array,
        includedCategories: PropTypes.array,
        includedCategoryLayers: PropTypes.array,
    };

    static defaultProps = {
        includedCustomLayers: [],
        includedCategories: [],
        includedCategoryLayers: [],
        categories: [],
    };

    state = {
        isCollapsed_customLayer: true,
    };

    handleToggle_isCollapsed_customLayer = () => {
        this.state.isCollapsed_customLayer
            ? this.setState({ isCollapsed_customLayer: false })
            : this.setState({ isCollapsed_customLayer: true });
    };

    includeLayer = id => {
        this.props.selectGeoLayer(id);
    };

    includeCategory = (id, tag) => {
        this.props.selectGeoCategory(id, tag);
    };

    render() {
        return (
            <div className="ef3-geoFencingLayerFinder">
                <div
                    className={`
                        ef3-geoFencingLayerFinder_fileTree
                        tree-head
                        ef3-geoFencingLayerFinder_fileTree_category_head
                        ef3-geoFencingLayerFinder_fileTree_category_head__type-category
                    `}
                    onClick={this.handleToggle_isCollapsed_customLayer}
                >
                    <span className="left">
                        <span className="fileTreeCaret">
                            {this.state.isCollapsed_customLayer ? (
                                <i className="fa fa-fw fa-caret-right" />
                            ) : (
                                <i className="fa fa-fw fa-caret-down" />
                            )}
                        </span>
                    </span>
                    <span className="right ">
                        {this.state.isCollapsed_customLayer ? (
                            <i className="fa fa-fw fa-folder" />
                        ) : (
                            <i className="fa fa-fw fa-folder-open" />
                        )}
                        <span className="name">Custom Flex Geolocation Audiences</span>
                    </span>
                </div>

                <div
                    className={`
                        tree-head
                    `}
                >
                    {this.state.isCollapsed_customLayer ? null : (
                        <FileTree
                            editLayer={this.props.editLayer}
                            flat={true}
                            includedCustomLayers={this.props.includedCustomLayers}
                            includeLayer={this.includeLayer}
                            categories={this.props.layers}
                        />
                    )}
                    <FileTree
                        editLayer={this.props.editLayer}
                        includedCategories={this.props.includedCategories}
                        includedCategoryLayers={this.props.includedCategoryLayers}
                        includeLayer={this.includeCategory}
                        categories={this.props.categories}
                    />
                </div>
            </div>
        );
    }
}

class FileTree extends React.Component {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        includeLayer: PropTypes.func.isRequired,
        includedCategories: PropTypes.array,
        includedCategoryLayers: PropTypes.array,
        includedCustomLayers: PropTypes.array,
    };

    static defaultProps = {
        includedCategories: [],
        includedCategoryLayers: [],
        includedCustomLayers: [],
    };

    constructor(props) {
        super(props);
        const { categories } = props;
        const mappedIdsAsNestedObject = createIdsAsNestedObject(categories);
        const subcategoryOrLayerToCategoryLookup = createSubcategoryOrLayerToCategoryLookup(
            categories
        );
        const openedFolders = createOpenFoldersLookup(categories);

        this.state = {
            openedFolders,
            mappedIdsAsNestedObject,
            subcategoryOrLayerToCategoryLookup,
        };
    }

    toggleFolder = id => {
        const openedFoldersWithHiddenCategory = {
            ...this.state.openedFolders,
            [id]: !this.state.openedFolders[id],
        };

        this.setState({
            openedFolders: openedFoldersWithHiddenCategory,
        });
    };

    render() {
        const {
            categories,
            includeLayer,
            includedCategories,
            includedCategoryLayers,
            includedCustomLayers,
            flat,
        } = this.props;

        const CommonDataProps = {
            includedCategories,
            includedCategoryLayers,
            mappedIdsAsNestedObject: this.state.mappedIdsAsNestedObject,
            subcategoryOrLayerToCategoryLookup: this.state.subcategoryOrLayerToCategoryLookup,
            openedFolders: this.state.openedFolders,
        };

        return (
            <div className="ef3-geoFencingLayerFinder_fileTree">
                {_.map(categories, category => {
                    return (
                        <ol
                            key={category.id}
                            className={classnames('ef3-geoFencingLayerFinder_fileTree_category', {
                                'is-closed': this.state.openedFolders[category.id],
                            })}
                        >
                            {flat ? (
                                <FileTreeLayer
                                    editLayer={this.props.editLayer}
                                    key={category.id}
                                    layer={category}
                                    categoryId={'categoryId'}
                                    subCategoryId={'not-subCategory'}
                                    includeLayer={() => includeLayer(category.id)}
                                    includedCustomLayers={includedCustomLayers}
                                    {...CommonDataProps}
                                />
                            ) : (
                                <FileTreeFolder
                                    id={category.id}
                                    name={category.name}
                                    type="category"
                                    includeLayer={() => console.log({ category })}
                                    toggleFolder={() => this.toggleFolder(category.id)}
                                    {...CommonDataProps}
                                />
                            )}

                            {this.state.openedFolders[category.id] ||
                                _.map(category.subcategories, subcat => {
                                    const parentIsChecked =
                                        _.indexOf(includedCategories, category.id) !== -1;
                                    const isClosed = this.state.openedFolders[subcat.id];

                                    return (
                                        <ol
                                            key={subcat.id}
                                            className={classnames(
                                                'ef3-geoFencingLayerFinder_fileTree_subCategory',
                                                {
                                                    'is-closed': isClosed,
                                                    'is-disabled': parentIsChecked,
                                                }
                                            )}
                                        >
                                            <FileTreeFolder
                                                id={subcat.id}
                                                name={subcat.name}
                                                type="sub"
                                                categoryId={category.id}
                                                includeLayer={() => {
                                                    if (parentIsChecked) return;
                                                    includeLayer(subcat.id);
                                                }}
                                                toggleFolder={() => this.toggleFolder(subcat.id)}
                                                {...CommonDataProps}
                                            />

                                            {_.map(subcat.layers, layer => {
                                                return (
                                                    <FileTreeLayer
                                                        editLayer={this.props.editLayer}
                                                        key={layer.id}
                                                        layer={layer}
                                                        categoryId={category.id}
                                                        subCategoryId={subcat.id}
                                                        includeLayer={() =>
                                                            includeLayer(layer.id, 'sub')
                                                        }
                                                        {...CommonDataProps}
                                                    />
                                                );
                                            })}
                                        </ol>
                                    );
                                })}
                        </ol>
                    );
                })}
            </div>
        );
    }
}

class FileTreeCaret extends React.Component {
    static propTypes = {
        toggleFolder: PropTypes.func.isRequired,
        openedFolders: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
    };

    render() {
        const { toggleFolder, openedFolders, id } = this.props;

        return (
            <span onClick={toggleFolder}>
                {openedFolders[id] ? (
                    <i className="fa fa-fw fa-caret-right" />
                ) : (
                    <i className="fa fa-fw fa-caret-down" />
                )}
            </span>
        );
    }
}

class FileTreeLayer extends React.Component {
    static propTypes = {
        layer: PropTypes.object.isRequired,
        includeLayer: PropTypes.func.isRequired,
        categoryId: PropTypes.string.isRequired,
        subCategoryId: PropTypes.string.isRequired,
        includedCategories: PropTypes.array.isRequired,
        includedCategoryLayers: PropTypes.array.isRequired,
        mappedIdsAsNestedObject: PropTypes.object.isRequired,
    };

    checkLayer = (parentCategoryIsChecked, parentSubCategoryIsChecked, id) => {
        if (this.props.categoryId === 'categoryId') {
            this.props.includeLayer(id, 'layer');
            return;
        }

        if (parentCategoryIsChecked || parentSubCategoryIsChecked) {
            return;
        }

        this.props.includeLayer(id, 'layer');
    };

    handle_editLayer = layerId => {
        this.props.editLayer(layerId);
    };

    render() {
        const {
            layer,
            categoryId,
            subCategoryId,
            includedCategories,
            includedCategoryLayers,
            includedCustomLayers,
            mappedIdsAsNestedObject,
        } = this.props;

        const { id, name } = layer;

        if (_.includes(name, '___implicit')) {
            return <span />;
        }

        const parentCatIsChecked = _.includes(includedCategories, categoryId);
        const parentSubCatIsChecked = _.includes(includedCategories, subCategoryId);

        let idForCheckbox = id;
        if (parentCatIsChecked) {
            idForCheckbox = categoryId;
        } else {
            if (parentSubCatIsChecked) {
                idForCheckbox = subCategoryId;
            }
        }

        const isDisabled = parentCatIsChecked || parentSubCatIsChecked;

        return (
            <li
                className={classnames('ef3-geoFencingLayerFinder_fileTree_layer', {
                    'is-disabled': isDisabled,
                })}
                onClick={() => {
                    this.checkLayer(parentCatIsChecked, parentSubCatIsChecked, id);
                }}
            >
                <div className="layer" title={name}>
                    <FileCheckbox
                        id={idForCheckbox}
                        type="category"
                        mappedIdsAsNestedObject={mappedIdsAsNestedObject}
                        includedCategories={includedCategories}
                        includedCategoryLayers={includedCategoryLayers}
                        includedCustomLayers={includedCustomLayers}
                    />
                    <i className="fa fa-fw fa-clone" />
                    <span>{name}</span>
                </div>
                {/*(subCategoryId === 'not-subCategory') ?
                        <button
                            className={classnames('btn btn-secondary btn-sm', { 'is-disabled': false })}
                            onClick={ ()=>{ this.handle_editLayer(id); } } >
                            <i className="fa fa-fw fa-pencil" />
                        </button> : null
                    */}
            </li>
        );
    }
}

class FileCheckbox extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['category', 'sub']).isRequired,
        mappedIdsAsNestedObject: PropTypes.object.isRequired,
    };

    // shouldComponentUpdate(nextProps, nextState) {

    //     if (this.props.id == 1) {
    //         console.log({
    //             nextProps,
    //             nextState
    //         })
    //     }

    //     if (
    //         nextProps.includedCategories !== this.props.includedCategories ||
    //         nextProps.includedCategoryLayers !== this.props.includedCategoryLayers
    //     ) {
    //         return true;
    //     }
    //     return false;
    // },

    static defaultProps = {
        includedCategories: [],
        includedCategoryLayers: [],
        includedCustomLayers: [],
    };

    render() {
        const {
            includedCategories,
            includedCategoryLayers,
            includedCustomLayers,
            id,
            type,
            mappedIdsAsNestedObject,
            onClick,
        } = this.props;

        const isChecked =
            _.indexOf(
                [].concat(includedCategories, includedCategoryLayers, includedCustomLayers),
                id
            ) !== -1;
        if (type && isChecked) {
            // return (
            //     <i className={classnames('fa fa-fw', 'fa-check-square-o')}></i>
            // );
            //
            return (
                <span className="checkbox" onClick={onClick}>
                    <i className={classnames('fa fa-fw', 'fa-check-square-o')} />
                </span>
            );
        }

        let hasCheckedChild = false;
        switch (type) {
            case 'sub':
                {
                    const layersInSubCategory = _.reduce(
                        mappedIdsAsNestedObject,
                        (acc, category) => {
                            return category[id] ? category[id] : acc;
                        },
                        []
                    );

                    if (hasCheckedLayer(layersInSubCategory, includedCategoryLayers)) {
                        hasCheckedChild = true;
                    }
                }
                break;
        }

        // return (
        //     hasCheckedChild ?
        //     <i className={classnames('fa fa-fw', 'fa-square')}></i>
        //     : <i className={classnames('fa fa-fw', {
        //         'fa-square-o': !isChecked,
        //         'fa-check-square-o': isChecked
        //     })}></i>
        // );
        return (
            <span className="checkbox">
                {hasCheckedChild ? (
                    <i className={classnames('fa fa-fw', 'fa-square')} />
                ) : (
                    <i
                        className={classnames('fa fa-fw', {
                            'fa-square-o': !isChecked,
                            'fa-check-square-o': isChecked,
                        })}
                    />
                )}
            </span>
        );
    }
}

class FileTreeFolder extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['category', 'sub']).isRequired,
        categoryId: PropTypes.string,
        includeLayer: PropTypes.func.isRequired,
        mappedIdsAsNestedObject: PropTypes.object.isRequired,
        openedFolders: PropTypes.object.isRequired,
        toggleFolder: PropTypes.func.isRequired,
    };

    didClickInCheckbox = e => {
        return $(e.target).closest('.checkbox').length === 1;
    };

    render() {
        const {
            id,
            name,
            type,
            includedCategories,
            includedCategoryLayers,
            includeLayer,
            mappedIdsAsNestedObject,
            openedFolders,
            toggleFolder,
            subcategoryOrLayerToCategoryLookup,
        } = this.props;

        let idForCheckbox = id;

        if (type === 'sub') {
            const parentIsChecked = _.indexOf(includedCategories, this.props.categoryId) !== -1;
            idForCheckbox = parentIsChecked ? this.props.categoryId : id;
        }

        const subcategoryAndLayerSelections = [].concat(includedCategories, includedCategoryLayers);

        const highlightCategory =
            type === 'category'
                ? _.some(
                      subcategoryAndLayerSelections,
                      selection => subcategoryOrLayerToCategoryLookup[selection] === id
                  )
                : false;

        // return (
        //     <li
        //         className={classnames(
        //             'ef3-geoFencingLayerFinder_fileTree_category_head',
        //             `ef3-geoFencingLayerFinder_fileTree_category_head__type-${type}`
        //         )}
        //         onClick={e => this.didClickInCheckbox(e) ? includeLayer() : toggleFolder()}
        //     >
        //         <FileTreeCaret
        //             toggleFolder={toggleFolder}
        //             openedFolders={openedFolders}
        //             id={id}
        //         />
        //             <span onClick={includeLayer}>
        //                 {
        //                     // show a checkbox when not a major category
        //                     type !== 'category' ?
        //                     <FileCheckbox
        //                         id={idForCheckbox}
        //                         type={type}
        //                         mappedIdsAsNestedObject={mappedIdsAsNestedObject}
        //                         includedCategories={includedCategories}
        //                         includedCategoryLayers={includedCategoryLayers}
        //                     /> : null
        //                 }
        //                 <span className={classnames({
        //                     'ef3-geoFencingLayerFinder_fileTree_majorCategory': highlightCategory
        //                 })}>
        //                     <i className="fa fa-fw fa-folder-open"></i>
        //                     &nbsp;{name}
        //                 </span>
        //             </span>
        //     </li>
        // );

        return (
            <li
                className={classnames(
                    'ef3-geoFencingLayerFinder_fileTree_category_head',
                    `ef3-geoFencingLayerFinder_fileTree_category_head__type-${type}`
                )}
                onClick={e => {
                    return this.didClickInCheckbox(e) ? includeLayer() : toggleFolder();
                }}
            >
                <span className="left">
                    <FileTreeCaret
                        toggleFolder={toggleFolder}
                        openedFolders={openedFolders}
                        id={id}
                    />
                </span>
                <span className="right" title={name}>
                    {// show a checkbox when not a major category
                    type !== 'category' ? (
                        <FileCheckbox
                            id={idForCheckbox}
                            type={type}
                            onClick={includeLayer}
                            mappedIdsAsNestedObject={mappedIdsAsNestedObject}
                            includedCategories={includedCategories}
                            includedCategoryLayers={includedCategoryLayers}
                        />
                    ) : null}
                    <span
                        className={classnames('ef3-geoFencingLayerFinder_fileTree_majorCategory', {
                            isActive: highlightCategory,
                        })}
                    >
                        <span className="ef3-geoFencingLayerFinder_itemTypeIcon">
                            {openedFolders[id] ? (
                                <i className="fa fa-fw fa-folder" />
                            ) : (
                                <i className="fa fa-fw fa-folder-open" />
                            )}
                        </span>
                        {name}
                    </span>
                </span>
            </li>
        );
    }
}

function hasCheckedLayer(layers, includedIds) {
    return _.reduce(
        layers,
        (result, layer) => {
            return _.indexOf(includedIds, layer) !== -1 ? true : result;
        },
        false
    );
}

function createSubcategoryOrLayerToCategoryLookup(categories) {
    const table = {};

    _.each(categories, category => {
        _.each(category.subcategories, subcategory => {
            _.each(subcategory.layers, layer => {
                table[layer.id] = category.id;
            });
            table[subcategory.id] = category.id;
        });
    });

    return table;
}

function createIdsAsNestedObject(categories) {
    return _.reduce(
        categories,
        (acc, category) => {
            const subcategoryMapping = _.reduce(
                category.subcategories,
                (mapping, sub) => {
                    return {
                        ...mapping,
                        [sub.id]: _.reduce(
                            sub.layers,
                            (arr, layer) => {
                                return [...arr, layer.id];
                            },
                            []
                        ),
                    };
                },
                {}
            );

            return {
                ...acc,
                [category.id]: subcategoryMapping,
            };
        },
        {}
    );
}

function createOpenFoldersLookup(categories) {
    const table = {};

    _.each(categories, category => {
        _.each(category.subcategories, subcategory => {
            table[subcategory.id] = true;
        });
        table[category.id] = true;
    });

    return table;
}

export default GeoFencingLayerFinder;

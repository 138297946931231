import _ from 'lodash';

export default function findCacheState(nextKey, state, initialState) {
    if (!state.cacheKey) {
        return {
            ...initialState,
            cacheKey: nextKey,
            cache: {},
        };
    }

    // return current state, nothing changed
    if (nextKey === state.cacheKey) {
        return state;
    }

    const currentState = _.omit(state, ['cache']);
    let nextState = { ...initialState };

    // return cached state
    const cachedState = _.get(state, `cache.${nextKey}`);
    if (cachedState) {
        nextState = cachedState;
    }

    // if cacheKey exists (after intialization), store in cache
    let cache = { ...state.cache };
    if (currentState.cacheKey) {
        cache[currentState.cacheKey] = currentState;
    }

    return {
        ...nextState,
        cache,
        cacheKey: nextKey,
    };
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { getNumberOfSecondsInRange } from 'common/date-calculator';
import { formatDate_long, formatDate_date, getTimezoneAbbrv } from 'utils/formatting';
import InlineTooltip from 'widgets-v5/inline-tooltip';

class ProgressBar extends React.Component {
    static propTypes = {
        status: PropTypes.oneOf([
            'live',
            'paused',
            'scheduled',
            'pending',
            'ended',
            'incomplete',
            'campaignIsOff',
            'archived',
            null,
        ]).isRequired,
        startDate: PropTypes.object.isRequired, // example: 'MMM D, YYYY HH:mm z'
        endDate: PropTypes.object.isRequired, // example: 'MMM D, YYYY HH:mm z'
        deliveryProgress: PropTypes.number,
        deliveryHealth: PropTypes.oneOf(['good', 'ok', 'bad', 'pending', null]),
        labelLeft: PropTypes.string,
        labelRight: PropTypes.string,
        rangeLeft: PropTypes.string,
        rangeRight: PropTypes.string,
        offsetLeft: PropTypes.number, // will be percentage
        offsetRight: PropTypes.number, // will be percentage
        dateLabel: PropTypes.bool,
        primaryTimezone: PropTypes.string,
        secondaryTimezone: PropTypes.string,
        isAd: PropTypes.bool,
        elapsedTime: PropTypes.bool, // specifies progress bar type
    };

    static defaultProps = {
        offsetLeft: 0,
        offsetRight: 0,
        secondaryTimezone: 'UTC',
        primaryTimezone: 'UTC',
    };

    render() {
        const now = new Date();

        const campaignRange = {
            start: this.props.startDate,
            end: this.props.endDate,
        };

        let widthOfElapsedTime;
        // Campaign has either not started, or already ended
        if (now < this.props.startDate) {
            widthOfElapsedTime = 0;
        } else if (now > this.props.endDate) {
            widthOfElapsedTime = 100;
            // Campaign is in flight
        } else {
            const numberOfSecondsInCampaign = getNumberOfSecondsInRange(campaignRange);
            const elapsedSecondsInCampaign = getNumberOfSecondsInRange({
                start: this.props.startDate,
                end: now,
            });

            widthOfElapsedTime = (elapsedSecondsInCampaign / numberOfSecondsInCampaign) * 100;
        }

        const {
            rangeRight,
            rangeLeft,
            endDate,
            startDate,
            primaryTimezone,
            secondaryTimezone,
            elapsedTime,
        } = this.props;

        let matchingTimezones;
        let altLongRangeRightValue;
        let altLongRangeLeftValue;
        let altRangeRightValue;
        let altRangeLeftValue;
        let primaryTimezoneAbbrv;
        let secondaryTimezoneAbbrv;

        primaryTimezoneAbbrv = getTimezoneAbbrv(primaryTimezone);
        secondaryTimezoneAbbrv = getTimezoneAbbrv(secondaryTimezone);
        matchingTimezones = primaryTimezoneAbbrv === secondaryTimezoneAbbrv;

        if (!matchingTimezones && startDate && endDate) {
            altLongRangeLeftValue = formatDate_long(startDate, secondaryTimezone);
            altLongRangeRightValue = formatDate_long(endDate, secondaryTimezone);

            altRangeLeftValue = formatDate_date(startDate, secondaryTimezone);
            altRangeRightValue = formatDate_date(endDate, secondaryTimezone);
        }

        const longRangeRightValue =
            rangeRight || (endDate && formatDate_long(endDate, primaryTimezone));
        const longRangeLeftValue =
            rangeLeft || (startDate && formatDate_long(startDate, primaryTimezone));

        // default values for the ranges. In the case that they are not provided
        // NOTE: that this defaults to UTC time and currently doesn't accept timezones
        const rangeRightValue =
            rangeRight || (endDate && formatDate_date(endDate, primaryTimezone));
        const rangeLeftValue =
            rangeLeft || (startDate && formatDate_date(startDate, primaryTimezone));

        return (
            <InlineTooltip
                className="ef5-progress-bar__tooltip"
                isEnabled={Boolean(startDate && endDate)}
                tooltip={
                    startDate &&
                    endDate && (
                        <div>
                            <div className="ef5-progress-bar__tooltip-values">
                                <div
                                    className={`ef5-progress-bar__tooltip-left-value${!matchingTimezones &&
                                        !elapsedTime &&
                                        '-primary'}`}
                                >
                                    {longRangeLeftValue}
                                </div>
                                <div>&nbsp;-&nbsp;</div>
                                <div
                                    className={`ef5-progress-bar__tooltip-right-value${!matchingTimezones &&
                                        !elapsedTime &&
                                        '-primary'}`}
                                >
                                    {longRangeRightValue}
                                </div>
                            </div>
                            {!matchingTimezones && !elapsedTime && (
                                <React.Fragment>
                                    <div className="ef5-progress-bar__tooltip-values">
                                        <div className="ef5-progress-bar__tooltip-left-value-alt">
                                            {altLongRangeLeftValue}
                                        </div>
                                        <div>-</div>
                                        <div className="ef5-progress-bar__tooltip-right-value-alt">
                                            {altLongRangeRightValue}
                                        </div>
                                    </div>
                                    <div className="ef5-progress-bar__tooltip-legend">
                                        <div className="ef5-progress-bar__tooltip-legend-value">
                                            <div className="ef5-progress-bar__tooltip-legend-value-colour-primary" />
                                            Organization Timezone
                                        </div>
                                        <div className="ef5-progress-bar__tooltip-legend-value">
                                            <div className="ef5-progress-bar__tooltip-legend-value-colour-alt" />
                                            {this.props.isAd ? 'Ad Timezone' : 'Campaign Timezone'}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )
                }
            >
                <div className="ef5-progress-bar">
                    {(this.props.labelLeft || this.props.labelRight) && (
                        <div className="ef5-progress-bar__labels">
                            <div className="ef5-progress-bar__label-left">
                                <div>{this.props.labelLeft}</div>
                                <div>{!matchingTimezones && this.props.altLabelLeft}</div>
                            </div>
                            <div className="ef5-progress-bar__label-right">
                                <div>{this.props.labelRight}</div>
                                <div>{!matchingTimezones && this.props.altLabelRight}</div>
                            </div>
                        </div>
                    )}

                    {this.props.dateLabel && (
                        <div className="ef5-progress-bar__date-label">
                            <div>
                                <Typography variant="caption">
                                    {rangeLeftValue}{' '}
                                    {!matchingTimezones &&
                                        !elapsedTime &&
                                        `(${primaryTimezoneAbbrv})`}
                                </Typography>
                                {!matchingTimezones && (
                                    <Typography variant="caption">{`${altRangeLeftValue} (${secondaryTimezoneAbbrv})`}</Typography>
                                )}
                            </div>
                            <div>
                                <Typography variant="caption">
                                    {rangeRightValue}{' '}
                                    {!matchingTimezones && `(${primaryTimezoneAbbrv})`}
                                </Typography>
                                {!matchingTimezones && (
                                    <Typography variant="caption">
                                        >{`${altRangeRightValue} (${secondaryTimezoneAbbrv})`}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="ef5-progress-bar__bar-wrapper">
                        {this.props.deliveryHealth ? (
                            <div
                                className={classnames(
                                    'ef5-progress-bar__health-status-bar',
                                    `ef5-progress-bar__health-status-bar--is-${
                                        this.props.deliveryHealth
                                    }`
                                )}
                                style={{ width: Math.min(this.props.deliveryProgress, 100) + '%' }}
                            />
                        ) : null}
                        <div
                            className={classnames(
                                'ef5-progress-bar__bar',
                                `ef5-progress-bar__bar--is-${this.props.status}`
                            )}
                            style={{
                                width: widthOfElapsedTime + '%',
                            }}
                        >
                            <div
                                className={classnames(
                                    'ef5-progress-bar__remaining-region',
                                    `is-${this.props.status}`
                                )}
                                style={{
                                    left: `${this.props.offsetLeft}%`,
                                    right: `${this.props.offsetRight}%`,
                                }}
                            />
                        </div>
                    </div>
                    {(rangeRight || rangeLeft) && (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography variant="caption">
                                    <div>{rangeLeftValue}</div>
                                    {!matchingTimezones && !elapsedTime && (
                                        <div>{altLongRangeLeftValue}</div>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box textAlign="right">
                                    <Typography variant="caption">
                                        <div>{rangeRightValue}</div>
                                        {!matchingTimezones && !elapsedTime && (
                                            <div>{altLongRangeRightValue}</div>
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </InlineTooltip>
        );
    }
}

export default ProgressBar;

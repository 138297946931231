import _ from 'lodash';
import { createSelector } from 'reselect';

import smartQuery from 'utils/smart-query';

const getcreativeToArchive = state => state.creativesOverviewV2.creativeToArchive;
const getSelectedRows = state => state.creativesOverviewV2.selectedRows;

const getAds = state => state.creativesOverviewV2.ads;
const getCreatives = state => state.creativesOverviewV2.creatives;
export const getSearchQuery = state => state.creativesOverviewV2.filterOptions.search;
const getShouldShowArchivedCreatives = state =>
    state.creativesOverviewV2.shouldShowArchivedCreatives;

export const getCreativeArchiveMessage = createSelector(
    getcreativeToArchive,
    getAds,
    (creativeToArchive, ads) => {
        const connectedAds = getConnectedAds(ads, creativeToArchive);
        const canArchiveCreative = connectedAds.length === 0;
        const title = canArchiveCreative ? 'Archive Creative?' : 'Cannot Archive Creative';

        return { connectedAds, canArchiveCreative, title };
    }
);

export const getCreativeBulkArchiveMessage = createSelector(
    getSelectedRows,
    getAds,
    getCreatives,
    (selectedRows, ads, creatives) => {
        const creativesWithConnectedAds = [];
        const creativesToBulkArchive = [];
        _.forEach(selectedRows, creativeId => {
            const connectedAds = getConnectedAds(ads, creativeId);
            const creativeName = _.find(creatives, creative => creative.id === creativeId).name;
            if (connectedAds.length > 0) {
                creativesWithConnectedAds.push({ id: creativeId, name: creativeName });
            } else {
                creativesToBulkArchive.push({ id: creativeId, name: creativeName });
            }
            return connectedAds;
        });

        const title =
            creativesWithConnectedAds.length === 0
                ? 'Bulk Archive Creatives?'
                : 'Cannot Bulk Archive Creatives';

        return {
            title,
            creativesWithConnectedAds,
            creativesToBulkArchive,
        };
    }
);

export const getFilteredCreatives = createSelector(
    getCreatives,
    getAds,
    getSearchQuery,
    getShouldShowArchivedCreatives,
    (creatives, ads, searchQuery, shouldShowArchivedCreatives) => {
        const searchQueryClean = searchQuery.toLowerCase();
        const creativesWithConnectedAds = _.map(creatives, creative => {
            const connectedAds = getConnectedAds(ads, creative.id);

            return {
                ...creative,
                connectedAds: connectedAds || 'None',
            };
        });
        const filteredCreatives = _(creativesWithConnectedAds)
            .filter(creative => {
                const stringBeingSearch = _.values(_.pick(creative, ['id', 'name'])).join(' ');
                const stringBeingSearch_lowercase = stringBeingSearch.toLowerCase();
                if (searchQueryClean === '') return true;
                if (smartQuery(searchQueryClean).test(stringBeingSearch_lowercase)) return true;
                return false;
            })
            .filter(creative => {
                if (shouldShowArchivedCreatives) {
                    return true;
                } else {
                    return !creative.isArchived;
                }
            })
            .orderBy('_created', 'desc')
            .value();
        return filteredCreatives;
    }
);

export function getConnectedAds(ads, creativeId) {
    const connectedAds = _.filter(ads, ad => {
        if (ad.creative.length && _.includes(ad.creative, creativeId)) {
            return true;
        }

        const weightedCreatives = _.map(ad.rotation_rules.weighted, c => c.markup_id);
        if (_.includes(weightedCreatives, creativeId)) {
            return true;
        }

        for (const schedule of ad.rotation_rules.scheduled) {
            if (schedule.markup_id === creativeId) {
                return true;
            }

            for (const weighted of schedule.weighted) {
                if (weighted.markup_id === creativeId) {
                    return true;
                }
            }
        }

        const weatherCreatives = _.map(ad.rotation_rules.weather, c => c.markupId);
        if (_.includes(weatherCreatives, creativeId)) {
            return true;
        }

        return false;
    });
    return connectedAds;
}

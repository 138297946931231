import React, { Component } from 'react';

class Deactivate_self_when_click_is_not_self extends Component {
    // A wrapper component that deactive self when click event is
    // not originate from self.
    //
    //      Will be actived when it is clicked
    //      Will be deactive self when the click is not itself.
    //      Does not required an unique id
    //

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        };
        this._selfBeingClicked = false;
        this._componentIsMounted = false;
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleSelfBeingClicked = this.handleSelfBeingClicked.bind(this);
    }

    componentDidMount() {
        this._componentIsMounted = true;
        document.addEventListener('click', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        this._componentIsMounted = false;
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps, prevState) {
        // Reset registered before next click
        this._selfBeingClicked = false;
    }

    handleClickOutside(e) {
        // This handler always trigger after handleSelfBeingClicked
        if (this._selfBeingClicked) {
            this.setState({ isActive: true });
        } else {
            this.setState({ isActive: false });
        }
    }

    handleSelfBeingClicked(e) {
        // handleSelfBeingClicked always triggered before handleClickOutside
        // All this handle does is set a flag to note that this
        // component has been clicked. It does not decide any thing should be
        // redering should be done. Redering or not, the decision is done
        // in handleClickOutside
        this._selfBeingClicked = true;
    }

    render() {
        // const activeStyle =  {color: (this.state.isActive === true) ? 'red' : 'black'};
        const className = `deactivate-self-when-click-outside ${
            this.state.isActive ? 'is-active' : ''
        }`;

        // Merge this.state.isActive into properties of this.props.children
        const clonedChildren = [];
        const hasMoreThenOneChildren =
            Object.prototype.toString.call(this.props.children) === '[object Array]';
        const hasOnlyOneChild =
            Object.prototype.toString.call(this.props.children) === '[object Object]';

        if (hasMoreThenOneChildren) {
            this.props.children.forEach(child => {
                // Dirty dirty dirty hack to prevent isActive being applied to divs
                if (child.type === 'div') {
                    const clone = React.cloneElement(child);
                    clonedChildren.push(clone);
                } else {
                    const clone = React.cloneElement(child, { isActive: this.state.isActive });
                    clonedChildren.push(clone);
                }
            });
        }
        if (hasOnlyOneChild) {
            // Dirty dirty dirty hack to prevent isActive being applied to divs
            if (this.props.children.type === 'div') {
                const clone = React.cloneElement(this.props.children);
                clonedChildren.push(clone);
            } else {
                const clone = React.cloneElement(this.props.children, {
                    isActive: this.state.isActive,
                });
                clonedChildren.push(clone);
            }
        }

        return (
            <div
                className={className}
                onClick={e => {
                    this.handleSelfBeingClicked(e);
                }}
            >
                {clonedChildren.map((clonedChild, i) => {
                    return <div key={i}> {clonedChild} </div>;
                })}
            </div>
        );
    }
}

const Demo = () => (
    <div className={`widget-self-aware-click ${style['module-style']}`}>
        {[1, 2, 3, 1, 2, 3].map((v, i) => (
            <Deactivate_self_when_click_is_not_self key={i}>
                {v}
            </Deactivate_self_when_click_is_not_self>
        ))}
    </div>
);

export default Deactivate_self_when_click_is_not_self;
export { Demo };

import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';
const creativesRegex = /^\/campaigns\/\d+\/setup\/creatives\/?$/;
const parseRoute = path => creativesRegex.test(path);
const initialState = {
    isLoading: false,
    filterOptions: {
        search: '',
    },
    creativesExpanded: {},
};

export const NAME = 'creativesOverview';

export default function(state = {}, action) {
    // const creativeId = _.get(action, `payload.creativeId`);

    // // NOTE: this is causing any action without a creative id not to fire
    //     // see the default case for fix
    // // if (!creativeId) {
    // //     const _pagination = pagination(state.pagination, action);
    // //     return {
    // //         ...state,
    // //         pagination: _pagination
    // //     };
    // // }

    // const { payload } = action;

    switch (action.type) {
        case c.OVERVIEW__CAMPAIGN__INIT:
        case c.OVERVIEW_CREATIVES__INIT: {
            const { campaignId } = action.payload;

            // return initialState;
            return findCacheState(campaignId, state, initialState);
        }

        case '@@router/LOCATION_CHANGE': {
            const isOverviewCreativesUrl = parseRoute(action.path);

            if (!isOverviewCreativesUrl) {
                return state;
            }

            return {
                ...state,
                filterUrl: action.path,
            };
        }
        case c.OVERVIEW_CREATIVES__CREATIVE_LIST__FILTER: {
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    [action.payload.filterType]: action.payload.value,
                },
            };
        }

        case c.OVERVIEW_CREATIVES__CREATIVE_EXPAND:
            return {
                ...state,
                creativesExpanded: {
                    ...state.creativesExpanded,
                    [action.payload.creativeId]: true,
                },
            };

        case c.OVERVIEW_CREATIVES__CREATIVE_COLLAPSE:
            return {
                ...state,
                creativesExpanded: {
                    ...state.creativesExpanded,
                    [action.payload.creativeId]: false,
                },
            };

        case c.OVERVIEW_CREATIVES__CREATIVE_EXPAND_ALL: {
            const creativesExpanded = {};
            _.each(action.payload.creativeIds, creativeId => {
                creativesExpanded[creativeId] = true;
            });
            return {
                ...state,
                creativesExpanded,
            };
        }

        case c.OVERVIEW_CREATIVES__CREATIVE_COLLAPSE_ALL: {
            const creativesExpanded = {};
            _.each(action.payload.creativeIds, creativeId => {
                creativesExpanded[creativeId] = false;
            });

            return {
                ...state,
                creativesExpanded,
            };
        }

        default:
            return state;
    }
}

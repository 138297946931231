import _ from 'lodash';
import Joi from '@hapi/joi';
import { createSelector } from 'reselect';

import { REDUCER_KEY } from './redux';
import { REPORT_JOB_STATUSES } from './constants';
import { draftToPayloadV2 } from 'pages/reports/editor/services/draft-to-payload';

const { PENDING, PROCESSING } = REPORT_JOB_STATUSES;

const schema = Joi.object({
    campaigns: Joi.object({
        ids: Joi.array().items(Joi.string()),
    }),
    dimensions: Joi.array()
        .items(Joi.string())
        .min(1)
        .messages({
            'array.min': 'Select at least 1 dimension',
        }),
    metrics: Joi.array()
        .items(Joi.string())
        .custom((metrics, helpers) => {
            const engagmentsSize = _.size(helpers.prefs.context.businessReport.draft.engagements);
            const metricsSize = _.size(metrics);
            if (engagmentsSize + metricsSize < 1) {
                return helpers.message('Select at least 1 metric');
            }
        }),
});

export const getPendingJobCount = state => {
    const reportJobs = _.get(state, `${REDUCER_KEY}.reportJobs`);

    return _(reportJobs)
        .filter(reportJob => _.includes([PENDING, PROCESSING], reportJob.status))
        .size();
};

export const getExportErrors = state => {
    const draft = _.get(state, 'businessReportEditor.draft');
    const businessReportEditor = _.get(state, 'businessReportEditor');
    const options = {
        allowUnknown: true,
        abortEarly: false,
        context: {
            businessReport: businessReportEditor,
        },
    };

    const result = schema.validate(draft, options);
    const errors = {};
    if (result.error) {
        _.each(result.error.details, error => {
            errors[error.path.join('.')] = error.message;
        });
    }

    return errors;
};

export const getExportErrorMessagesAsCsv = createSelector(
    getExportErrors,
    errors => {
        return _.values(errors).join(', ');
    }
);

const getReportJobs = state => {
    return _.get(state, `${REDUCER_KEY}.reportJobs`);
};

export const getUnSavedReports = createSelector(
    getReportJobs,
    reportJobs => {
        return _.filter(reportJobs, job => !job.isSavedReport);
    }
);

export const getReportJobPayload = state => {
    const shouldSendViaEmail = _.get(state, `${REDUCER_KEY}.shouldSendViaEmail`);
    const draft = _.get(state, `businessReportEditor.draft`);

    const businessReport = draftToPayloadV2(draft);

    return {
        shouldSendViaEmail,
        businessReport,
    };
};

import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { getEnv } from 'services/environment';
import MultipleSelector from 'widgets/multiple-selector';
import METRICS from 'common/constants/metrics';
import flags from 'containers/flags/service';

const SHARING_SETTINGS_PRESENTATION_MAPPING = {};
_.each(METRICS, metric => {
    SHARING_SETTINGS_PRESENTATION_MAPPING[metric.name] = metric.label;
});

class MetricSelector extends React.Component {
    onHandleEnabledMetricChange = _metrics => {
        const { onChange, metrics } = this.props;
        const enabledMetrics = _.map(_metrics, _metric => {
            return {
                isBeacon: _metric.isBeacon,
                name: _metric.key,
                checked: _metric.value,
            };
        });

        onChange('metrics')({
            ...metrics,
            enabledMetrics,
        });
    };

    onHandleDisabledMetricChange = _metrics => {
        const { onChange, metrics } = this.props;
        const disabledMetrics = _.map(_metrics, _metric => {
            return {
                isBeacon: _metric.isBeacon,
                name: _metric.key,
                checked: _metric.value,
            };
        });

        onChange('metrics')({
            ...metrics,
            disabledMetrics,
        });
    };

    render() {
        const { metrics, beacons, campaign, conversions } = this.props;

        _.forEach(beacons, beacon => {
            SHARING_SETTINGS_PRESENTATION_MAPPING[beacon.name] = beacon.label;
        });

        _.forEach(conversions, conversion => {
            SHARING_SETTINGS_PRESENTATION_MAPPING[`conv_total_${conversion.event_name}`] =
                conversion.reporting_name;
        });

        const enabledMetrics = _.map(metrics.enabledMetrics, metric => {
            let hidden = false;

            // if billings module is off, hide billing options
            if (_.includes(['revenue', 'erpc', 'erpm'], metric.name) && !campaign.billing_enabled) {
                hidden = true;
            } else if (
                campaign.third_party_fees.length === 0 &&
                metric.name === 'owner_total_media_cost_local'
            ) {
                hidden = true;
            } else if (metric.name === 'unique_users') {
                const isUniqueUsersDisabled = campaign.isUniqueUsersDisabled;
                hidden = isUniqueUsersDisabled;
            }

            if (!campaign.isUniqueUsersDisabled) {
                if (_.includes(['daily_uniq', 'average_freq'], metric.name)) {
                    hidden = true;
                }
            }

            return {
                value: metric.checked,
                isBeacon: metric.isBeacon,
                text: SHARING_SETTINGS_PRESENTATION_MAPPING[metric.name],
                key: metric.name,
                hidden,
            };
        });
        const disabledMetrics = _.map(metrics.disabledMetrics, metric => {
            let hidden = false;

            if (metric.name === 'unique_users') {
                const isUniqueUsersDisabled = campaign.isUniqueUsersDisabled;
                hidden = isUniqueUsersDisabled;
            }

            if (!campaign.isUniqueUsersDisabled) {
                if (_.includes(['daily_uniq', 'average_freq'], metric.name)) {
                    hidden = true;
                }
            }

            return {
                value: false,
                text: SHARING_SETTINGS_PRESENTATION_MAPPING[metric.name],
                isBeacon: metric.isBeacon,
                key: metric.name,
                disabled: true,
                hidden,
            };
        });
        return (
            <div className="ef3-scheduledReport_metricSelector">
                <div
                    className={`ef3-scheduledReport_metricSelector_metrics ${
                        enabledMetrics.length > 15
                            ? 'ef3-scheduledReport_metricSelector_double_column'
                            : ''
                    }`}
                >
                    <MultipleSelector
                        headerText="Metrics"
                        values={enabledMetrics}
                        onChange={this.onHandleEnabledMetricChange}
                    />
                </div>
                <div
                    className={`ef3-scheduledReport_metricSelector_disabledMetrics ${
                        disabledMetrics.length > 15
                            ? 'ef3-scheduledReport_metricSelector_double_column'
                            : ''
                    }`}
                >
                    <MultipleSelector
                        headerText="Metrics not shared"
                        values={disabledMetrics}
                        onChange={this.onHandleDisabledMetricChange}
                    />
                </div>
            </div>
        );
    }
}

export default MetricSelector;

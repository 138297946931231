export const platformMapping = {
    inapp: 'In-App',
    mweb: 'Mobile Web / Desktop',
};

export const sourceTypeLabelMapping = {
    ad: 'Ad',
    campaign: 'Campaign',
    pixel: 'Advertiser-level-pixel',
};

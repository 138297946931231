import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PresetsPoiEditor from 'pages/presets/poi-editor';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

import actions from './actions';
import selector from './selector';

const useStyles = makeStyles(theme => ({
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
}));

const FlexSegmentsForm = ({ onSave, selectedFlexBrandId, type, open, dispatch, title }) => {
    const classes = useStyles();

    const onClose = () => {
        dispatch(actions.close());
    };

    const handleSave = () => {
        setTimeout(() => {
            onSave();
            onClose();
        }, 1500);
    };

    let segmentFormTitle;

    if (title) {
        segmentFormTitle = title;
    } else if (selectedFlexBrandId) {
        segmentFormTitle = 'Edit Flex Segment';
    }

    return (
        <Dialog fullScreen={false} fullWidth maxWidth="lg" open={open}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <Typography variant="h6">{segmentFormTitle}</Typography>
                    </div>
                    <div>
                        <IconButton onClick={onClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <PresetsPoiEditor
                    params={{ poiId: selectedFlexBrandId }}
                    onClose={onClose}
                    onSave={handleSave}
                    type={type}
                />
            </DialogContent>
        </Dialog>
    );
};

export default connect(selector)(FlexSegmentsForm);

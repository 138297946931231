import PropTypes from 'prop-types';
import React from 'react';

import FormControl from '@mui/material/FormControl';

import Draft from 'widgets-v5/draft';
import TargetAppStoreCategories from '../../../target-app-store-categories';

class AppCategoryForm extends React.Component {
    static propTypes = {
        target_app_store_cat: PropTypes.array,
        target_app_store_cat_exclude: PropTypes.array,
        exclude: PropTypes.bool,

        showErrors: PropTypes.bool,
        onDraftChange: PropTypes.func,
    };

    static defaultProps = {
        target_app_store_cat: [],
        target_app_store_cat_exclude: [],
        exclude: false,
    };

    state = {
        initialDraft: {
            target_app_store_cat: this.props.target_app_store_cat,
            target_app_store_cat_exclude: this.props.target_app_store_cat_exclude,
            exclude: this.props.exclude,
        },
        schema: {
            target_app_store_cat() {
                return value => {
                    if (!value) {
                        return 'invalid value';
                    }
                };
            },
            target_app_store_cat_exclude() {
                return value => {
                    if (!value) {
                        return 'invalid value';
                    }
                };
            },
            __require_selection(source) {
                const selections = [].concat(
                    source.target_app_store_cat,
                    source.target_app_store_cat_exclude
                );

                if (selections.length === 0) {
                    return 'A category must be specified for this tactic';
                }
            },
        },
    };

    render() {
        const { onDraftChange, isProspectMedia } = this.props;

        const { initialDraft, schema } = this.state;

        return (
            <Draft
                initialDraft={initialDraft}
                schema={schema}
                onDraftChange={onDraftChange}
                onInit={onDraftChange}
            >
                {({ draft, updateDraft }) => {
                    return (
                        <FormControl>
                            <TargetAppStoreCategories
                                exclude={draft.exclude}
                                includeValues={draft.target_app_store_cat}
                                excludeValues={draft.target_app_store_cat_exclude}
                                isProspectMedia={isProspectMedia}
                                onChange={({ values, exclude }) => {
                                    if (exclude) {
                                        updateDraft({
                                            target_app_store_cat: [],
                                            target_app_store_cat_exclude: values,
                                            exclude: true,
                                        });
                                    } else {
                                        updateDraft({
                                            target_app_store_cat: values,
                                            target_app_store_cat_exclude: [],
                                            exclude: false,
                                        });
                                    }
                                }}
                            />
                        </FormControl>
                    );
                }}
            </Draft>
        );
    }
}

export default AppCategoryForm;

import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';

import GoogleMapsApiLoader from 'containers/google-maps-api-loader';
import GeofenceViewer from './modules/geofencing-map-viewer';
import Gis from 'states/resources/gis/actions';

const PoiViewer = createReactClass({
    displayName: 'PoiViewer',

    getPois(entity) {
        const dispatch = this.props.dispatch;
        const entityType = entity.entityType;
        const entityId = entity.entityId;
        if (entityType && entityId) {
            this.setState({ isLoading: true });
            dispatch(Gis.getPoisByEntity(entity.entityType, entity.entityId)).then(
                pois => {
                    if (this.state.mounted) {
                        this.setState({
                            pois: _.get(pois, `points`, {}),
                            isLoading: false,
                            entityType,
                            entityId,
                        });
                    }
                },
                error => {
                    if (this.state.mounted) {
                        this.setState({
                            pois: {},
                            isLoading: false,
                            errorMsg: JSON.stringify(error),
                        });
                    }
                }
            );
        } else {
            if (this.state.mounted) {
                this.setState({
                    pois: {},
                    isLoading: false,
                    entityType,
                    entityId,
                });
            }
        }
    },

    getInitialState() {
        return {
            pois: {},
            isLoading: false,
            errorMsg: '',
            entityType: void 0,
            entityId: void 0,
            mounted: false,
        };
    },

    UNSAFE_componentWillMount() {
        this.throttleGetPois = _.throttle(this.getPois, 1000);
    },

    componentDidMount() {
        this.setState({
            mounted: true,
        });
        const { geofencingEntityInView } = this.props;
        const entity = _.pick(geofencingEntityInView, ['entityType', 'entityId']);
        this.getPois(entity);
    },

    componentWillUnmount() {
        this.setState({
            mounted: false,
        });
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        // ComponentWillReceiveProps will decide if viewer should refetch pois from API

        // next
        const geofencingEntityInView_nxt = _.get(nextProps, `geofencingEntityInView`, void 0);
        const entity_nxt = _.pick(geofencingEntityInView_nxt, ['entityType', 'entityId']);
        const entityType_nxt = entity_nxt.entityType;
        const entityId_nxt = entity_nxt.entityId;

        // previous
        const geofencingEntityInView_prv = _.get(this.props, `geofencingEntityInView`, void 0);
        const entity_prv = _.pick(geofencingEntityInView_prv, ['entityType', 'entityId']);
        const entityType_prv = entity_prv.entityType;
        const entityId_prv = entity_prv.entityId;

        // only fetch pois if entity is different
        const isDifferentEntity = !(
            entityId_prv === entityId_nxt && entityType_prv === entityType_nxt
        );
        if (isDifferentEntity) {
            this.throttleGetPois(entity_nxt);
        }

        if (entityId_nxt === void 0) {
            // still fetch if has no id, let getPois generate an fake empty layer
            this.throttleGetPois(entity_nxt);
        }

        const poiViewer_eTag_nxt = _.get(nextProps, 'poiViewer_eTag', void 0);
        const poiViewer_eTag_prv = _.get(this.props, 'poiViewer_eTag', void 0);
        if (poiViewer_eTag_prv !== poiViewer_eTag_nxt) {
            // fetch if user has edit poi layer
            this.throttleGetPois(entity_nxt);
        }
    },

    shouldComponentUpdate(nextProps, nextState) {
        // shouldComponentUpdate will decide if viewer should be updated
        const entityId_next = _.get(nextState, 'entityId', void 0);
        const entityId_prev = _.get(this.state, 'entityId', void 0);
        const entityType_next = _.get(nextState, 'entityType', void 0);
        const entityType_prev = _.get(this.state, 'entityType', void 0);

        const isDifferentEntity = !(
            entityId_prev === entityId_next && entityType_prev === entityType_next
        );

        const isLoading_next = _.get(nextState, 'isLoading', void 0);
        const isLoading_prev = _.get(this.state, 'isLoading', void 0);

        const loadingStatusHasChanged = isLoading_next !== isLoading_prev;

        let shouldUpdate = false;
        if (isDifferentEntity || loadingStatusHasChanged) {
            shouldUpdate = true;
        }

        const poiViewer_eTag_nxt = _.get(nextState, 'poiViewer_eTag', void 0);
        const poiViewer_eTag_prv = _.get(this.state, 'poiViewer_eTag', void 0);
        if (poiViewer_eTag_prv !== poiViewer_eTag_nxt) {
            shouldUpdate = true;
        }

        return shouldUpdate;
    },

    render() {
        const {
            geoCategories: {
                geoResources_unfiltered: { geoLayers },
            },
        } = this.props;

        return (
            <div className="poi-viewer">
                <div className="mapContainer">
                    <GoogleMapsApiLoader>
                        <GeofenceViewer
                            ref="poiViewer"
                            geoTargetingSettings={this.props.geoTargetingSettings}
                            geoLayers={geoLayers}
                            pois={this.state.pois}
                            parent={this}
                        />
                    </GoogleMapsApiLoader>
                </div>
                {this.state.isLoading ? (
                    <div className="mapIsLoading">
                        <i className="fa fa-spinner fa-spin fa-5x" />
                    </div>
                ) : null}
            </div>
        );
    },
});

const mapStateToProps = (state, ownProps) => {
    return ownProps;
};

export default connect(mapStateToProps)(PoiViewer);

import { fetchOverviewPageGraphqlData, changeDataSelection } from '../../actions';

const initMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    const re = /\/campaigns\/[0-9]*\/setup/;
    const isSetupPage = re.test(window.location.pathname);
    if (action.type === 'campaignPageContainer:changeView' && isSetupPage) {
        const campaignSetupDataSelection = action.payload === 'campaign' ? 'campaign' : 'flight';

        dispatch(changeDataSelection(campaignSetupDataSelection));

        if (campaignSetupDataSelection === 'flight') {
            const campaignId = getState().campaignPageContainer.campaign.id;
            const flightId = getState().campaignPageContainer.viewBy;
            dispatch(fetchOverviewPageGraphqlData(campaignId, flightId));
        }
    }
};

export const makeMiddlewares = deps => {
    return [initMw(deps)];
};
export const middlewares = makeMiddlewares({});

import _ from 'lodash';

export const NAME = 'adminV2.ownOrganization';

const initialState = {
    organization: {},
    users: [],
    loading: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
        case 'ADMIN_V2__FETCH_OWN_ORGANIZATION_START': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'ADMIN_V2__FETCH_OWN_ORGANIZATION_END': {
            return {
                ...state,
                loading: false,
                organization: action.payload.data.organization,
                users: action.payload.data.users,
            };
        }
        case 'ADMIN_V2__FILTER_USERS_START': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'ADMIN_V2__FILTER_USERS_END': {
            return {
                ...state,
                users: action.payload.filteredUsers,
                loading: false,
            };
        }
    }
}

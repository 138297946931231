import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import http from 'utils/http';

const DATE_FORMAT = 'MMMM Do, YYYY @ h:ma';

import Toggle from 'widgets-v5/toggle';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';

import { withFlags } from 'containers/flags';

class Flags extends React.Component {
    toggleFlag = flag => {
        const { flags, dispatch } = this.props;
        flags.toggleFlag(flag.key);
        dispatch({
            type: 'FEATURE_FLAGS:CHANGED',
            payload: flags.getFlags(),
        });
    };

    publishNewUpdatesNotification = () => {
        let confirmed = confirm(
            'Are you sure you want to nofify all Engagefront users of new updates?'
        );

        if (confirmed) {
            http().post('notifications/new-updates-released');
        }
    };

    reset = () => {
        this.props.flags.reset();
    };

    render() {
        const flags = this.props.flags.getFlags();
        const sortedFlags = _(flags)
            .map(flag => flag)
            .sortBy(flag => moment(flag.released, DATE_FORMAT).toISOString())
            .value();

        return (
            <React.Fragment>
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item>
                        <Button variant="contained" onClick={this.reset}>
                            Reset Flags to Default
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.publishNewUpdatesNotification}
                        >
                            Publish New Updates Notification
                        </Button>
                    </Grid>
                    <Grid item>
                        <Link
                            href="https://console.firebase.google.com/project/addictive-ef-prod/config"
                            rel="noopener"
                            target="_blank"
                        >
                            Remote Config Dashboard
                        </Link>
                    </Grid>
                </Grid>
                <ComposableDataTable>
                    <Row header>
                        <Cell header>Key</Cell>
                        <Cell header>Author</Cell>
                        <Cell header>Released</Cell>
                        <Cell header>Enabled</Cell>
                    </Row>
                    {_.map(sortedFlags, flag => (
                        <Row key={flag.key}>
                            <Cell>{flag.key}</Cell>
                            <Cell>{flag.author}</Cell>
                            <Cell>
                                {flag.released &&
                                    moment
                                        .utc(moment(flag.released, DATE_FORMAT).toISOString())
                                        .fromNow()}
                            </Cell>
                            <Cell>
                                <Toggle
                                    position={flag.enabled ? 'right' : 'left'}
                                    onClick={() => this.toggleFlag(flag)}
                                    textLeft="off"
                                    textRight="on"
                                    width={50}
                                    height={22}
                                />
                            </Cell>
                        </Row>
                    ))}
                </ComposableDataTable>
            </React.Fragment>
        );
    }
}

export default connect()(withFlags(Flags));

import get from 'lodash/get';
import React from 'react';
import { browserHistory } from 'react-router';
import { RoutePermissionMapping } from '../root';
import { connect } from 'react-redux';

export const NotFound = props => {
    const firstRouteUserHasAccess = RoutePermissionMapping.find(({ auth }) => auth(props));
    browserHistory.replace(`${firstRouteUserHasAccess.route(props)}`);
    return <div>PAGE NOT FOUND</div>;
};

function mapStateToProps(state) {
    return {
        ...state,
        dashboardUrl: get(state, 'dashboard.filterUrl', '/dashboard'),
    };
}

export default connect(mapStateToProps)(NotFound);

export const NAME = 'campaignReportFta';

const initialState = {
    isInitialized: false,
    isLoading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'CAMPAIGN_REPORT__FTA__INIT__START': {
            return {
                ...state,
                isLoading: true,
            };
        }
        case 'CAMPAIGN_REPORT__FTA__INIT__SUCCESS': {
            const { ownOrganization, ftaCampaignMetaData, ftaLocationLists } = action.payload;
            return {
                ...state,
                ownOrganization,
                ftaLocationLists,
                ftaCampaignMetaData,
                isLoading: false,
                isInitialized: true,
            };
        }
        case 'CAMPAIGN_REPORT__FTA__INIT__ERROR': {
            return {
                ...state,
                isLoading: false,
            };
        }
        case 'CAMPAIGN_REPORT__FTA__DESTROY': {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class FillProgressBar extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['clicks', 'spend', 'impressions']).isRequired,
        goal: PropTypes.number.isRequired,
        current: PropTypes.number.isRequired,
    };

    static defaultProps = {
        goal: 0,
        current: 0,
    };

    render() {
        const { current, goal, type } = this.props;

        return (
            <div className="ef3-fillProgressBar">
                <div
                    className={classnames('ef3-fillProgressBar_progress', `is-${type}`)}
                    style={{ width: (current / goal) * 100 + '%' }}
                />
            </div>
        );
    }
}

export default FillProgressBar;

import _ from 'lodash';
import React from 'react';
import flags from 'containers/flags/service';

import FormField from 'widgets-v5/form-field';
import MultipleSelector from 'widgets-v5/multiple-selector';
import { MultiSelect } from 'widgets-v6/select';
import Spacer from 'widgets-v5/spacer';

const ages = {
    '1': '13 - 18',
    '2': '19 - 24',
    '3': '25 - 34',
    '5': '35 - 44',
    '6': '45 - 54',
    '7': '55 - 64',
    '8': '65 +',
    '-1': 'Unknown',
};

const _getAgeTargeting = () => {
    const ageTargeting = [];
    _(ages).each((key, value) => {
        ageTargeting.push({
            label: key,
            value: Number(value),
        });
    });
    return _(ageTargeting)
        .sortBy('label')
        .value();
};

class TargetAge extends React.Component {
    static defaultProps = {
        label: 'Age Groups',
    };

    getValueForForm = () => {
        const { value } = this.props;
        return value;
    };

    handleFormChange = value => {
        this.props.onChange(value);
    };

    render() {
        const value = this.getValueForForm();
        return (
            <div className="ef5-ad-setup__target-carriers ef5-ad-setup__form-field-flex">
                <FormField
                    label={this.props.label}
                    description=""
                    errors={this.props.errors}
                    showErrors={this.props.showErrors}
                    isRequired={true}
                >
                    <MultiSelect
                        value={value}
                        options={_getAgeTargeting()}
                        onChange={this.handleFormChange}
                        placeholder={'All age group'}
                        required={true}
                    />
                </FormField>
                <Spacer type="small" />
            </div>
        );
    }
}

export default TargetAge;

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { NeutralButton } from 'widgets-v5/buttons';

export default class extends React.Component {
    static propTypes = {
        values: PropTypes.array,
        headerText: PropTypes.string,
    };

    static defaultProps = {
        values: [],
        leftButton: 'Select All',
        rightButton: 'Select None',
        useLeftRightButtons: true,
        isHeaderDisabled: false,
    };

    onClickButton = selection => {
        const newValues = _.map(this.props.values, input => {
            return {
                ...input,
                value: input.disabled ? input.value : selection,
            };
        });

        this.props.onChange(newValues);
    };

    onClickCB = (val, text) => {
        const newValues = _.map(this.props.values, input => {
            if (text === input.text) {
                return {
                    ...input,
                    value: !val,
                };
            }
            return input;
        });

        this.props.onChange(newValues);
    };

    render() {
        const {
            values,
            useLeftRightButtons,
            onHeaderClick,
            isHeaderChecked,
            isHeaderDisabled,
        } = this.props;

        const visibleValues = _.filter(values, v => !v.hidden);
        const allHidden = _.every(visibleValues, v => v === false);

        return (
            <div className="ef3-multipleSelector">
                <div className="ef3-multipleSelector_header">
                    {onHeaderClick && (
                        <input
                            disabled={isHeaderDisabled}
                            className="ef3-multipleSelector_headerCheckbox"
                            onChange={() => onHeaderClick(!isHeaderChecked)}
                            type="checkbox"
                            checked={isHeaderChecked}
                        />
                    )}
                    <div className="ef3-multipleSelector_header_text">{this.props.headerText}</div>

                    {useLeftRightButtons && (
                        <span>
                            <NeutralButton
                                onClick={() => this.onClickButton(false)}
                                className="ef3-multipleSelector_header_button"
                                text={this.props.rightButton}
                            />
                            <NeutralButton
                                onClick={() => this.onClickButton(true)}
                                className="ef3-multipleSelector_header_button"
                                text={this.props.leftButton}
                            />
                        </span>
                    )}
                </div>

                <div
                    className={`ef3-multipleSelector_body${
                        visibleValues.length > 15 ? '_double_column' : ''
                    }`}
                >
                    {isHeaderChecked || !onHeaderClick ? (
                        _.map(visibleValues, (input, index) => (
                            <div
                                key={'checkboxVal-' + index + input.key}
                                className="ef3-multipleSelector_body_checkboxLine"
                            >
                                <label>
                                    <input
                                        className={cn('ef3-multipleSelector_checkbox', {
                                            'ef3-multipleSelector_checkbox-disabled':
                                                input.disabled,
                                        })}
                                        onChange={() => this.onClickCB(input.value, input.text)}
                                        type="checkbox"
                                        checked={input.value}
                                        id={'checkboxVal-' + index + input.key}
                                        name={'checkboxVal-' + index + input.key}
                                        disabled={input.disabled}
                                    />
                                    <span
                                        className={cn('ef3-multipleSelector_name', {
                                            'ef3-multipleSelector_name-disabled': input.disabled,
                                        })}
                                    >
                                        {input.text}
                                    </span>
                                </label>
                            </div>
                        ))
                    ) : (
                        <div>Hidden</div>
                    )}
                </div>
            </div>
        );
    }
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import flags from 'containers/flags/service';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import LinearProgress from '@mui/material/LinearProgress';

import Sidebar from '../../../containers/audience-sidebar';
import AudienceHeader from '../modules/audience-header';
import Layout from '../layout';

import actions from './actions';
import selector from './selector';
import moment from 'moment';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import { hasMatchInformation, isAudienceActive } from 'states/resources/audiences/business-logic';
import { platformMapping } from 'common/constants/audiences';
import { isInternalUser } from 'states/profile/business-rules';

import InlineTooltip from 'widgets-v5/inline-tooltip';
import StandardInput from 'widgets-v5/standard-input';
import Spacer from 'widgets-v5/spacer';
import { Caption } from 'widgets-v5/typography';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const useStyles = makeStyles(theme => ({
    chip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    platformCell: {
        display: 'flex',
    },
    chipContainer: {
        width: 'fit-content',
        marginTop: theme.spacing(1),
    },
    tooltip: {
        fontSize: theme.tooltip.fontSize,
    },
}));

const BehaviourDashboard = ({ dispatch, audienceSegments }) => {
    const classes = useStyles();

    useEffect(() => {
        dispatch(actions.getAudiences());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    const sortedAudiences = _.orderBy(audienceSegments, ['name'], ['asc']);

    return (
        <Layout
            sidebar={<Sidebar />}
            header={
                <React.Fragment>
                    <AudienceHeader
                        title="Behavioural"
                        description="Group consumers based on specific patterns they display, paired with frequently visited locations and first-party SDK location data."
                        category="Lifestyle"
                        categoryIcon="fa fa-tag"
                        categoryDescription="Segments based on demographic, psychographic, and behavioural traits."
                        imgUrl="/images/audience-segments/audience-segment__lifestyle-behavioural.png"
                    />
                    <StandardInput placeholder="Filter audience" onChange={onChange} />
                    <Spacer />
                </React.Fragment>
            }
            body={
                <React.Fragment>
                    {!audienceSegments && <LinearProgress />}
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Platform(s)</TableCell>
                                <TableCell align="right">Uniques</TableCell>
                                <TableCell>Last Refreshed</TableCell>
                                <TableCell align="right">Data Cost</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedAudiences &&
                                sortedAudiences.map(segment => (
                                    <TableRow key={segment.id}>
                                        <TableCell>
                                            <div>
                                                {segment.name}
                                                {segment.description && <Spacer type="small" />}
                                                <Caption type="info">{segment.description}</Caption>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.platformCell}>
                                                {segment.supports.length > 0
                                                    ? _.map(segment.supports, support => (
                                                          <Chip
                                                              label={platformMapping[support]}
                                                              size="small"
                                                              className={classes.chip}
                                                              variant="outlined"
                                                          />
                                                      ))
                                                    : 'N/A'}
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">
                                            {segment.uniques !== null ? (
                                                <span>{formatNumber_whole(segment.uniques)}</span>
                                            ) : (
                                                <span>N/A</span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {segment.lastRefreshed === null ? (
                                                <span>N/A</span>
                                            ) : (
                                                <InlineTooltip
                                                    tooltip={moment(segment.lastRefreshed).format(
                                                        'MMM Do YYYY'
                                                    )}
                                                    position="bottom"
                                                >
                                                    {_.capitalize(
                                                        moment(segment.lastRefreshed).fromNow()
                                                    )}
                                                </InlineTooltip>
                                            )}
                                            <Spacer type="x-small" />
                                            <Caption type="info">
                                                {hasMatchInformation(segment) &&
                                                isAudienceActive(segment) ? (
                                                    <span>
                                                        Refreshed Regularly
                                                        <i className="fa fa-fw fa-refresh audiences-dashboard__reports-table-matched-users-active" />
                                                    </span>
                                                ) : (
                                                    'Completed'
                                                )}
                                            </Caption>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            className="audiences__table-cell--nowrap"
                                        >
                                            {segment.dataCost !== null ? (
                                                <span>
                                                    {formatNumber_currency(segment.dataCost)} CPM
                                                </span>
                                            ) : (
                                                <span>N/A</span>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    {audienceSegments && audienceSegments.length === 0 && (
                        <div className="audiences__error">No Audiences Found</div>
                    )}
                </React.Fragment>
            }
        />
    );
};

export default connect(selector)(BehaviourDashboard);

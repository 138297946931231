import _ from 'lodash';

export const NAME = 'businessReportDashboard';

const initialState = {
    isLoading: false,
    isInitialized: false,
    isDeleteConfirmationModalOpen: false,
    itemsPerPage: 25,
    maxResults: 0,
    allBusinessReportCount: 0,
    myBusinessReportCount: 0,
    pagination: {
        // 1: [1,2,3],
        // 2: [4,5,6],
        // 3: [7,8,9],
    },
    filters: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'BUSINESS_REPORT_DASHBOARD__INIT__START': {
            return {
                ...state,
                isInitialized: false,
                filters: action.payload.filters || {},
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__INIT__END': {
            return {
                ...state,
                isInitialized: true,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__INIT__ERROR': {
            return {
                ...state,
                isInitialized: true,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__OPEN_DELETE_CONFIRMATION_MODAL': {
            return {
                ...state,
                isDeleteConfirmationModalOpen: true,
                businessReportToDelete: action.payload.businessReportId,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__CLOSE_DELETE_CONFIRMATION_MODAL': {
            return {
                ...state,
                isDeleteConfirmationModalOpen: false,
                businessReportToDelete: null,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__DELETE__SUCCESS': {
            return {
                ...state,
                isDeleteConfirmationModalOpen: false,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__DELETE__ERROR': {
            return {
                ...state,
                isDeleteConfirmationModalOpen: false,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__FETCH_PAGE_DATA__START': {
            return {
                ...state,
                isLoading: true,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__FETCH_PAGE_DATA__END': {
            const {
                page,
                businessReports,
                maxResults,
                allBusinessReportCount,
                myBusinessReportCount,
            } = action.payload;

            const pagination = indexPage(state.pagination, page, businessReports);

            return {
                ...state,
                pagination,
                maxResults,
                allBusinessReportCount,
                myBusinessReportCount,
                filters: {
                    ...state.filters,
                    page,
                },
                isLoading: false,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__FETCH_PAGE_DATA__ERROR': {
            return {
                ...state,
                isLoading: false,
                errors: action.payload.error,
            };
        }
        case 'BUSINESS_REPORT_DASHBOARD__SEARCH': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    search: action.payload.text,
                },
            };
        }
        default:
            return state;
    }
}

function indexPage(pagination, page, businessReports) {
    return {
        ...pagination,
        [page]: _.map(businessReports, b => b.id),
    };
}

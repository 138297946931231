import toastr from 'toastr';
import { createHttp } from 'utils/http';
import flags from 'containers/flags/service';

const http = createHttp();
const actions = {
    fetchBrands: keywords => {
        return async (dispatch, getState) => {
            let query;
            if (flags.isEnabled('en_2987_use_audience_category_and_subCategory_code')) {
                query = `
                query getPoiBrands($keywords: String) {
                    flexBrands(filters: { keywords: $keywords, source_type: "flex_standard_brand" }) {
                        name
                        id
                        geoCategory {
                            name
                        }
                        points
                        subCategory
                        uniques
                        supports
                        _etag
                    }
                    flexCategories(filters: { keywords: $keywords }) {
                        name
                        id
                        points
                        brandsCount
                        subCategory
                        uniques
                        supports
                        _etag
                    }
                }`;
            } else {
                query = `
                query getPoiBrands($keywords: String) {
                    flexBrands(filters: { keywords: $keywords, source_type: "flex_standard_brand" }) {
                        name
                        id
                        geoCategory {
                            name
                        }
                        points
                        source_type
                        uniques
                        supports
                        _etag
                    }
                    flexCategories(filters: { keywords: $keywords }) {
                        name
                        id
                        points
                        brandsCount
                        source_type
                        uniques
                        supports
                        _etag
                    }
                }`;
            }

            const variables = {
                keywords,
            };

            try {
                dispatch({
                    type: 'FLEX_BRAND_DASHBOARD__FETCH_BRANDS_DATA_FETCH_START',
                    payload: {},
                });
                const { flexBrands, flexCategories } = await http.graphql(query, variables);
                const allFlexSegments = _.concat(flexBrands, flexCategories);
                const sortedSegments = _.sortBy(allFlexSegments, segment => segment.name);

                const trimmedFlexBrands = _.map(sortedSegments, brand => {
                    const trimmedName = _.trim(brand.name);
                    return { ...brand, name: trimmedName, id: brand.id };
                });
                dispatch({
                    type: 'FLEX_BRAND_DASHBOARD__FETCH_BRANDS_DATA_END',
                    payload: {
                        data: trimmedFlexBrands,
                    },
                });
            } catch (error) {
                dispatch({
                    type: 'FLEX_BRAND_DASHBOARD__FETCH_BRANDS_DATA_FETCH_ERROR',
                    payload: {},
                });
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import MaterialLink from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import GetAppIcon from '@mui/icons-material/GetApp';

import { Pagination } from 'widgets-v5/pagination';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { SearchInput } from 'widgets-v6/standard-input';
import { NoResults } from 'widgets-v6/layout';
import { DialogTitle } from 'widgets-v6/modal';
import UnsavedReportJobs from 'containers/report-jobs/unsaved-report-jobs';

import moment from 'moment';

function Layout(props) {
    let resultsText;

    if (props.maxPage > 0) {
        resultsText = `Page ${props.currentPage} of ${props.maxPage}.`;
    }

    const myReportsAreShowing =
        isEmpty(props.filters) ||
        props.filters.type === 'my-reports' ||
        (Object.keys(props.filters).length === 1 && props.filters.token);
    const allReportsAreShowing = props.filters && props.filters.type === 'all-reports';

    return (
        <Container maxWidth="lg">
            <Box>
                <Toolbar disableGutters>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h4">Reports</Typography>
                        </Grid>

                        <Grid item>
                            <Box textAlign="right">
                                <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => props.openEditor({})}
                                >
                                    New Report
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Box>
            <UnsavedReportJobs />
            {(props.allBusinessReportCount || props.myBusinessReportCount) && (
                <Box my={1} xs={3}>
                    <Box mb={1}>
                        <Typography variant="h6">Saved Reports</Typography>
                    </Box>

                    <Box mb={2}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Box>
                                <Chip
                                    label="My Reports"
                                    avatar={<Avatar>{props.myBusinessReportCount}</Avatar>}
                                    color={myReportsAreShowing ? 'primary' : 'default'}
                                    onClick={() => props.search('type', 'my-reports')}
                                />
                            </Box>
                            <Box ml="10">
                                <Chip
                                    label="All Reports"
                                    avatar={<Avatar>{props.allBusinessReportCount}</Avatar>}
                                    color={allReportsAreShowing ? 'primary' : 'default'}
                                    onClick={() => props.search('type', 'all-reports')}
                                />
                            </Box>
                        </Grid>
                    </Box>

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <SearchInput
                                placeholder="Search by report name"
                                defaultValue={props.filters.search}
                                onChange={e => props.search('search', e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="caption" color="textSecondary">
                        {props.maxResults} reports found. {resultsText}
                    </Typography>
                </Box>
            )}
            <Box mb={2}>
                <TableContainer component={Paper}>
                    {props.isLoading && <LinearProgress />}
                    {isEmpty(props.businessReportsResource) &&
                        !props.myBusinessReportCount &&
                        !props.allBusinessReportCount &&
                        props.isInitialized && (
                            <NoResults
                                title="Your organization doesn't have any reports yet."
                                buttonText="New Report"
                                onClick={() => props.openEditor({})}
                                to={''}
                            />
                        )}
                    {props.businessReports.length === 0 &&
                        !isEmpty(props.businessReportsResource) &&
                        props.currentPage === 1 &&
                        props.isInitialized && (
                            <NoResults
                                title="No found reports"
                                buttonText="Reset"
                                onClick={props.resetReports}
                            />
                        )}
                    {props.businessReports.length > 0 && (
                        <React.Fragment>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Schedule</TableCell>
                                        <TableCell>Created At</TableCell>
                                        <TableCell>Created By</TableCell>
                                        <TableCell>Last Updated</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(props.businessReports, br => (
                                        <TableRow key={br.id}>
                                            <TableCell>
                                                <MaterialLink
                                                    onClick={() =>
                                                        props.openEditor({
                                                            businessReportId: br.id,
                                                        })
                                                    }
                                                    component="button"
                                                    color="primary"
                                                >
                                                    {br.name}
                                                </MaterialLink>
                                            </TableCell>
                                            <TableCell>
                                                {br.schedule
                                                    ? _.capitalize(br.schedule.frequency)
                                                    : 'None'}
                                                {br.schedule &&
                                                    br.schedule.frequency === 'once' &&
                                                    ` - ${moment(br.schedule.date)
                                                        .tz(props.organizationTimezone)
                                                        .format('MMM Do YYYY')}`}
                                            </TableCell>
                                            <TableCell>
                                                {moment(br._created)
                                                    .tz(props.organizationTimezone)
                                                    .format('MMM Do YYYY')}
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                {_.get(br, 'createdByFullName', '')}{' '}
                                            </TableCell>
                                            <TableCell>
                                                <InlineTooltip
                                                    tooltip={moment(Number(br._updated))
                                                        .tz(props.organizationTimezone)
                                                        .format('MMM Do YYYY')}
                                                    position="bottom"
                                                >
                                                    <span>
                                                        {_.capitalize(
                                                            moment(Number(br._updated))
                                                                .tz(props.organizationTimezone)
                                                                .fromNow()
                                                        )}
                                                    </span>
                                                </InlineTooltip>
                                            </TableCell>
                                            <TableCell align="left">
                                                {!_.isNil(br.downloadUrl) && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            downloadURI(br.downloadUrl, br.name)
                                                        }
                                                    >
                                                        <GetAppIcon />
                                                    </IconButton>
                                                )}
                                                <IconButton
                                                    aria-label="Delete"
                                                    onClick={() =>
                                                        props.openDeleteConfirmationModal(br.id)
                                                    }
                                                    size="large"
                                                >
                                                    <Icon
                                                        className="fa fa-trash"
                                                        fontSize="small"
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {props.businessReports.length > 0 && (
                                <Pagination
                                    url="/reports"
                                    query={props.filters}
                                    padding={2}
                                    itemsPerPage={props.itemsPerPage}
                                    currentPage={props.currentPage}
                                    maxResults={props.maxResults}
                                />
                            )}
                        </React.Fragment>
                    )}
                </TableContainer>
            </Box>
            <ConfirmationDialogModal
                open={props.isDeleteConfirmationModalOpen}
                onClose={props.closeDeleteConfirmationModal}
                businessReportId={props.businessReportToDelete}
                confirmDeleteBusinessReport={props.confirmDeleteBusinessReport}
                filters={props.filters}
            />
        </Container>
    );
}

function ConfirmationDialogModal({
    open,
    onClose,
    businessReportId,
    confirmDeleteBusinessReport,
    filters,
}) {
    function handleConfirmation() {
        confirmDeleteBusinessReport(businessReportId, filters);
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" onClose={onClose}>
                Delete Report?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>You will not be able to recover it.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmation} variant="contained" color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default Layout;

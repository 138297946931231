import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';

import { Title, Subheading, BodyBold } from 'widgets-v5/typography';
import Separator from 'widgets-v5/separator';
import { PanelsLayout, WorkspacePanel, OverlaySidebarPanel } from 'widgets-v5/panels-layout';
import Spacer from 'widgets-v5/spacer';
import { BodyText } from 'widgets-v5/typography';
import Card from 'widgets-v5/card';

export function Layout(props) {
    return (
        <LayoutWrapper>
            <PanelsLayout direction="row" padding>
                <OverlaySidebarPanel>
                    {props.sidebarContent.length && <Sidebar sections={props.sidebarContent} />}
                </OverlaySidebarPanel>
                <WorkspacePanel className="help-topic__body">
                    <Card>
                        <Title>{props.title}</Title>
                        {props.children}
                    </Card>
                </WorkspacePanel>
            </PanelsLayout>
        </LayoutWrapper>
    );
}

export function Section(props) {
    return (
        <SectionWrapper link={props.link}>
            <SectionHeader>{props.title}</SectionHeader>
            <SectionContent>{props.children}</SectionContent>
        </SectionWrapper>
    );
}

export function LayoutWrapper(props) {
    return <div className="help-topic__layout">{props.children}</div>;
}

export class SectionWrapper extends Component {
    render() {
        const { props } = this;

        return (
            <div ref={props.link} className="help-topic__section">
                {props.children}
            </div>
        );
    }
}

export function SectionHeader(props) {
    return (
        <Fragment>
            <Subheading>{props.children}</Subheading>
            <Separator />
        </Fragment>
    );
}

export function SectionContent(props) {
    return <div className="help-topic__section-content">{props.children}</div>;
}

export function Image(props) {
    return <img className="help-topic__image" src={props.url} />;
}

export function List(props) {
    return (
        <div className="help-topic__list-wrapper">
            <ul className="help-topic__list">{props.children}</ul>
        </div>
    );
}

export function ListItem(props) {
    return <li>{props.children}</li>;
}

export function Sidebar(props) {
    return (
        <SideBarWrapper>
            <SidebarIcon />
            <SidebarList>
                <SidebarListItems sections={props.sections} />
            </SidebarList>
        </SideBarWrapper>
    );
}

export function SideBarWrapper(props) {
    return <div className="help-topic__sidebar">{props.children}</div>;
}

export function SidebarIcon(props) {
    return (
        <div>
            <i className="fa fa-align-right help-topic__sidebar-icon" />
            CONTENT
        </div>
    );
}

export function SidebarList(props) {
    return <ul className="help-topic__sidebar-list">{props.children}</ul>;
}

export function SidebarListItems(props) {
    return _.map(props.sections, (section, index) => {
        const { title, link } = section;

        return (
            <SidebarListItem onClick={() => link.current.scrollIntoView()}>
                <BodyText type="link">{title}</BodyText>
            </SidebarListItem>
        );
    });
}

export function SidebarListItem(props) {
    return (
        <div className="help-topic__list-item" onClick={props.onClick}>
            {props.children}
        </div>
    );
}

export function HelpLink(props) {
    return (
        <Link className="help-topic__link" to={props.url} target={props.target}>
            <BodyText type="link">
                {props.icon && <HelpLinkIcon />}
                {props.text}
            </BodyText>
        </Link>
    );
}

export function HelpLinkIcon(props) {
    return <i className="fa fa-external-link" />;
}

export function SubSection(props) {
    return (
        <Fragment>
            <Spacer />
            <BodyBold>{props.title}</BodyBold>
            <Spacer type="small" />
            {props.children}
        </Fragment>
    );
}

export function Paragraph(props) {
    return <p>{props.children}</p>;
}

export function BoldText(props) {
    return <strong>{props.children}</strong>;
}

export function EmphasizedText(props) {
    return <em>{props.children}</em>;
}

import _ from 'lodash';

export const NAME = 'mediaPlanForm';

const initialState = {
    isInit: false,
    isLoading: true,
    inventoryPredictions: {},
    adMeta: {},
    hiddenColumns: {
        billing_rate: true,
        estimated_total_impressions: true,
        estimated_daily_impressions: true,
        estimated_uniques: true,
        geo_fencing: true,
    },
    organization: {},
    ftaLocationLists: [],
    applists: [],
    dealsToAdMapping: {},
    dealsByOrganization: [],
    processedAds: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'MEDIA_PLAN_FORM__INIT': {
            return {
                ...initialState,
                isLoading: true,
                isInit: false,
            };
        }
        case 'MEDIA_PLAN_FORM__INIT_END': {
            return initializeDraft(initialState, action.payload);
        }
        case 'MEDIA_PLAN_FORM__CLEANUP': {
            return {
                ...state,
                isInit: false,
            };
        }
        case 'MEDIA_PLAN_FORM__FETCH_PREDICTION_START': {
            const { adId } = action.payload;
            return {
                ...state,
                inventoryPredictions: {
                    ...state.inventoryPredictions,
                    [action.payload.adId]: {
                        isLoading: true,
                    },
                },
            };
        }
        case 'MEDIA_PLAN_FORM__FETCH_PREDICTION_END__EF_51': {
            const { adId } = action.payload;
            return {
                ...state,
                inventoryPredictions: {
                    ...state.inventoryPredictions,
                    [adId]: {
                        isLoading: false,
                    },
                },
            };
        }
        case 'MEDIA_PLAN_FORM__FETCH_PREDICTION_ERROR': {
            const { adId } = action.payload;
            return {
                ...state,
                inventoryPredictions: {
                    ...state.inventoryPredictions,
                    [adId]: {
                        isLoading: false,
                        error: action.error,
                    },
                },
            };
        }
        case 'MEDIA_PLAN_FORM__SAVE_AD_START': {
            return updateAdMeta(state, action.payload.adId, {
                isLoading: true,
            });
        }
        case 'MEDIA_PLAN_FORM__SAVE_AD_END': {
            const { adId, column } = action.payload;
            return {
                ...updateAdMeta(state, adId, {
                    isLoading: false,
                    errors: {
                        ..._.get(state, `adMeta.${adId}.errors`, {}),
                        [column]: null,
                    },
                }),
                processedAds: state.processedAds + 1,
            };
        }
        case 'MEDIA_PLAN_FORM__SAVE_AD_ERROR': {
            const { adId, column } = action.payload;
            return updateAdMeta(state, action.payload.adId, {
                isLoading: false,
                errors: {
                    ..._.get(state, `adMeta.${adId}.errors`, {}),
                    [column]: action.error,
                },
            });
        }
        case 'MEDIA_PLAN_FORM__TOGGLE_COLUMN': {
            const { column } = action.payload;
            return {
                ...state,
                hiddenColumns: {
                    ...state.hiddenColumns,
                    [column]: !state.hiddenColumns[column],
                },
            };
        }
        case 'MEDIA_PLAN_FORM__FETCH_DEAL_END': {
            const { adId, deals } = action.payload;
            return {
                ...state,
                dealsToAdMapping: {
                    ...state.dealsToAdMapping,
                    [adId]: deals,
                },
            };
        }
        case 'MEDIA_PLAN_FORM__SAVE_ALL_COLUMN_ADS_END': {
            return {
                ...state,
                processedAds: 0,
            };
        }
        case 'MEDIA_PLAN_FORM__SET_ACTIVE_PLATFORM_FILTER': {
            return {
                ...state,
                activePlatformFilter: action.payload.filter,
            };
        }
        default: {
            return state;
        }
    }
}

function initializeDraft(state, data) {
    const audienceMapping = {};
    const flexMapping = {};
    _.each(data.audienceSegments, option => (audienceMapping[option.value] = option));
    _.each(data.flexSegments, option => (flexMapping[option.value] = option));

    return {
        ...state,
        isLoading: false,
        isInit: true,
        organization: data.organization,
        ftaLocationLists: data.ftaLocationLists,
        applists: data.applists,
        dealsToAdMapping: data.dealsToAdMapping,
        dealsByOrganization: data.deals,
        processedAds: 0,
        audienceMapping,
        flexMapping,
    };
}
function updateAdMeta(state, adId, change) {
    return {
        ...state,
        adMeta: {
            ...state.adMeta,
            [adId]: {
                ...(state.adMeta[adId] || {}),
                ...change,
            },
        },
    };
}

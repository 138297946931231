import React, { lazy, Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const OverallDashboardV2 = lazy(() => import('./v2/component'));

const OverallDashboardLazy = () => (
    <Suspense fallback={<LinearProgress />}>
        <OverallDashboardV2 />
    </Suspense>
);

export default OverallDashboardLazy;

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import Label from '../label';

import StandardSelect from 'widgets/form-inputs/standard-select';
import CreativeAutoComplete from 'widgets-v5/creative-auto-complete';
import { NeutralButton, SecondaryButton, ButtonGroup } from 'widgets-v5/buttons';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export default class extends React.Component {
    static propTypes = {
        third_party_pixels: PropTypes.arrayOf(PropTypes.string),
        third_party_quartile_pixels: PropTypes.arrayOf(
            PropTypes.shape({
                event: PropTypes.string,
                url: PropTypes.string,
            })
        ),
    };

    updateInput = (type, value, index) => {
        const { third_party_pixels, third_party_quartile_pixels } = this.props;

        if (type === 'impression') {
            const updatedPixels = _.map(third_party_pixels, (pixel, key) => {
                if (key === index) {
                    return value;
                }

                return pixel;
            });
            return this.sendToOnChange('impression', updatedPixels);
        }

        if (type === 'quartile') {
            const updatedPixels = _.map(third_party_quartile_pixels, (pixel, key) => {
                if (key === index) {
                    return {
                        ...pixel,
                        url: value,
                    };
                }

                return pixel;
            });
            return this.sendToOnChange('quartile', updatedPixels);
        }
    };

    updatePixelType = (value, pixel, index) => {
        if (value === pixel.event) {
            return;
        }

        const { third_party_quartile_pixels } = this.props;

        const updatedPixels = _.map(third_party_quartile_pixels, (px, key) => {
            if (key === index) {
                return {
                    ...px,
                    event: value,
                };
            }

            return px;
        });
        this.sendToOnChange('quartile', updatedPixels);
    };

    addInputRow = type => {
        const { third_party_pixels, third_party_quartile_pixels } = this.props;

        if (type === 'impression') {
            if (third_party_pixels) {
                const newData = [].concat(third_party_pixels, ['']);
                return this.sendToOnChange('impression', newData);
            }

            return this.sendToOnChange('impression', ['']);
        }

        if (type === 'quartile') {
            if (third_party_quartile_pixels) {
                const newData = [].concat(third_party_quartile_pixels, [
                    {
                        url: '',
                        event: 'firstQuartile',
                    },
                ]);
                return this.sendToOnChange('quartile', newData);
            }

            return this.sendToOnChange('quartile', [
                {
                    url: '',
                    event: 'firstQuartile',
                },
            ]);
        }
    };

    removeInputRow = (type, index) => {
        const { third_party_pixels, third_party_quartile_pixels } = this.props;

        if (type === 'impression') {
            const filteredData = _.filter(third_party_pixels, (v, key) => index !== key);
            return this.sendToOnChange('impression', filteredData);
        }

        if (type === 'quartile') {
            const filteredData = _.filter(third_party_quartile_pixels, (v, key) => index !== key);
            return this.sendToOnChange('quartile', filteredData);
        }
    };

    sendToOnChange = (type, data) => {
        const { onChange } = this.props;

        type === 'impression'
            ? onChange('third_party_pixels')(data)
            : onChange('third_party_quartile_pixels')(data);
    };

    render() {
        const { third_party_pixels, third_party_quartile_pixels, canAddVastPixels } = this.props;

        const dropDownOptions = [
            {
                value: 'start',
                label: 'Video Start (00:02)',
            },
            {
                value: 'firstQuartile',
                label: '1st Quartile 25%',
            },
            {
                value: 'midpoint',
                label: '2nd Quartile 50%',
            },
            {
                value: 'thirdQuartile',
                label: '3rd Quartile 75%',
            },
            {
                value: 'complete',
                label: 'Video Complete',
            },
        ];

        return (
            <FormControl margin="normal" error={this.props.error} fullWidth>
                <Label header component="legend">
                    Third Party Pixels
                </Label>

                <ButtonGroup align="right">
                    <NeutralButton
                        icon={<i className="fa fa-plus" />}
                        text="Add Impression Pixel"
                        onClick={() => this.addInputRow('impression')}
                    />
                    {canAddVastPixels && (
                        <NeutralButton
                            icon={<i className="fa fa-plus" />}
                            text="Add VAST Pixel"
                            onClick={() => this.addInputRow('quartile')}
                        />
                    )}
                </ButtonGroup>

                {_.map(third_party_pixels, (pixel, index) => {
                    return (
                        <div key={index} className="ef3-thirdPartyPixelsInput_item">
                            <StandardSelect
                                disabled={true}
                                value={'impression'}
                                placeholder="Choose an event"
                                onChange={value => this.updatePixelType(value, pixel, index)}
                                options={[
                                    {
                                        value: 'impression',
                                        label: 'Impression',
                                    },
                                ]}
                            />
                            <div className="ef3-thirdPartyPixelsInput_inputSection">
                                <div className="ef3-thirdPartyPixelsInput_inputSectionInput">
                                    <CreativeAutoComplete
                                        value={pixel}
                                        onChange={val => this.updateInput('impression', val, index)}
                                    />
                                </div>
                                <NeutralButton
                                    text="Remove"
                                    onClick={() => this.removeInputRow('impression', index)}
                                />
                            </div>
                        </div>
                    );
                })}

                <div className="ef3-thirdPartyPixelsInput_divider" />

                {_.map(third_party_quartile_pixels, (pixel, index) => {
                    return (
                        <div key={index} className="ef3-thirdPartyPixelsInput_item">
                            <StandardSelect
                                value={pixel.event}
                                placeholder="Choose an event"
                                onChange={value => this.updatePixelType(value, pixel, index)}
                                options={dropDownOptions}
                            />
                            <div className="ef3-thirdPartyPixelsInput_inputSection">
                                <div className="ef3-thirdPartyPixelsInput_inputSectionInput">
                                    <CreativeAutoComplete
                                        value={pixel.url}
                                        onChange={val => this.updateInput('quartile', val, index)}
                                    />
                                </div>
                                <NeutralButton
                                    text="Remove"
                                    onClick={() => this.removeInputRow('quartile', index)}
                                />
                            </div>
                        </div>
                    );
                })}
            </FormControl>
        );
    }
}

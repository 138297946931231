import _ from 'lodash';
import flags from 'containers/flags/service';

export function handleChangeIASFraudPreventionRisk() {
    return {
        description: 'Handle change of IAS Fraud Prevention risk',
        target(state, action) {
            return 'AD_FORM__IAS_FRAUD_PREVENTION_RISK__CHANGE' === action.type;
        },
        reduce(state, action) {
            const nextState = updateIASFraudPrevention(state, 'risk', action.payload.code);

            return mapIASFraudPreventionToForensiq(nextState, action.payload.code);
        },
    };
}

export function handleChangeIASFraudPreventionUnrateable() {
    return {
        description: 'Handle change of IAS Fraud Prevention unrateable',
        target(state, action) {
            return 'AD_FORM__IAS_FRAUD_PREVENTION_UNRATEABLE__CHANGE' === action.type;
        },
        reduce(state, action) {
            return updateIASFraudPrevention(state, 'unrateable', action.payload.code);
        },
    };
}

export function handleChangeIASFraudPreventionIp() {
    return {
        description: 'Handle change of IAS Fraud Prevention ip',
        target(state, action) {
            return 'AD_FORM__IAS_FRAUD_PREVENTION_IP__CHANGE' === action.type;
        },
        reduce(state, action) {
            return updateIASFraudPrevention(state, 'ip', action.payload.code);
        },
    };
}

export function deriveIASFraudPrevention() {
    return {
        description: 'Reset IAS Fraud Prevention IP when platform is In-App',
        target(state, action) {
            return /^AD_FORM__/.test(action.type) && state.draft;
        },
        reduce(state, action) {
            const adPlatforms = _.get(state, 'draft.platforms');
            const isCrossPlatformCampaign = _.get(state, 'isCrossPlatformCampaign');

            if (
                isCrossPlatformCampaign &&
                (_.includes(adPlatforms, 'mweb') || _.includes(adPlatforms, 'desktop'))
            ) {
                return state;
            }
            if (state.draft.platform === 'inapp' && !isCrossPlatformCampaign) {
                return updateIASFraudPrevention(state, 'ip', 0);
            }

            return state;
        },
    };
}

const FORENSIQ_TO_VALUE = {
    '-1': 0,
    '65': 1,
    '80': 2,
};

const FORENSIQ_TO_SEG_ID = {
    desktop: {
        display: {
            '-1': -1,
            '65': 402,
            '80': 401,
        },
        video: {
            '-1': -1,
            '65': 407,
            '80': 404,
        },
    },
    inapp: {
        display: {
            '-1': -1,
            '65': 4017,
            '80': 4016,
        },
        video: {
            '-1': -1,
            '65': 8017,
            '80': 8016,
        },
    },
};

function getCreativeFormat(creativeId, creatives) {
    const creative = _.find(creatives, creative => creative.id === creativeId);

    if (creative) {
        return creative.format;
    }

    return null;
}

function updateIASFraudPrevention(state, field, value) {
    return {
        ...state,
        draft: {
            ...state.draft,
            ias: {
                ...state.draft.ias,
                fraudPrevention: {
                    ...state.draft.ias.fraudPrevention,
                    [field]: {
                        ...state.draft.ias.fraudPrevention[field],
                        value,
                    },
                },
            },
        },
    };
}

function mapIASFraudPreventionToForensiq(state, code) {
    const mapping = {
        0: -1,
        1: 65,
        2: 80,
    };

    const forensiqId = mapping[code];

    return {
        ...state,
        draft: {
            ...state.draft,
            forensiq_risk_cap: forensiqId,
        },
    };
}

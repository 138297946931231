import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import React from 'react';

import Splits from 'widgets/pivot-table/splits';

class SplitSelector extends React.Component {
    removeSplitsFromDimensions = () => {
        const {
            pivotTable: { splits, dimensions },
        } = this.props;
        const splitsIndex = {};
        _.each(splits, split => (splitsIndex[split.name] = true));

        const dimensionsWithoutSplits = _(dimensions)
            // Remove dimensions that have been selected
            .filter(dimension => !splitsIndex[dimension.name])
            .filter(dimension => {
                // Remove dimensions that have all children selected
                if (dimension.children) {
                    return _.filter(dimension.children, c => !splitsIndex[c.name]).length > 0;
                }

                return true;
            })
            .map(dimension => {
                return {
                    ...dimension,
                    // Remove children that have been selected
                    children: _.filter(dimension.children, c => !splitsIndex[c.name]),
                };
            })
            .value();

        return dimensionsWithoutSplits;
    };

    onRemoveSplit = split => {
        const {
            onChange,
            pivotTable: { splits },
        } = this.props;
        onChange(_.filter([...splits], s => s.name !== split.name));
    };

    onAddSplit = split => {
        const {
            onChange,
            pivotTable: { splits },
        } = this.props;
        onChange([...splits].concat(split));
    };

    onUpdateSplits = splits => {
        const { dimensions } = this.props.pivotTable;

        const dimensionLookup = {};
        function walkDimensions(_dimensions) {
            _.each(_dimensions, d => {
                if (d.children) {
                    walkDimensions(d.children);
                } else {
                    dimensionLookup[d.name] = d;
                }
            });
        }
        walkDimensions(dimensions);

        const splitsUpdated = _.map(splits, splitName => {
            return dimensionLookup[splitName];
        });

        this.props.onUpdateSplits(splitsUpdated);
    };

    onToggleZeroRows = e => {
        e.preventDefault();
        e.stopPropagation();

        this.props.toggleZeroRows();
    };

    render() {
        const {
            pivotTable: { splits },
        } = this.props;

        const dimensionsFiltered = this.removeSplitsFromDimensions();
        const hasDimensionsAvailable = dimensionsFiltered.length > 0;

        return (
            <div className="ef3-scheduledReport_splitSelector">
                <div className="ef3-scheduledReport_splitSelectorTitle">Dimensions</div>
                <div className="ef3-scheduledReport_splitSelectorContents">
                    <Splits
                        splits={splits}
                        hasDimensionsAvailable={hasDimensionsAvailable}
                        dimensionsFiltered={dimensionsFiltered}
                        onRemoveSplit={this.onRemoveSplit}
                        onAddSplit={this.onAddSplit}
                        onUpdateSplits={this.onUpdateSplits}
                    />
                    <div className="ef3-scheduledReport_splitSelectorZeroToggle">
                        <label onClick={this.onToggleZeroRows}>
                            <input
                                type="checkbox"
                                value={this.props.hideZeroRows}
                                checked={this.props.hideZeroRows}
                                onChange={_.noop}
                            />
                            <span>Hide Zero Rows</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }
}

export default SplitSelector;

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

import { TextField } from 'widgets-v6/standard-input';

import selector from './selector';

const useStyles = makeStyles(() => ({
    button: {
        width: '90px',
    },
    fieldsWrapper: {
        marginBottom: 1,
    },
}));

const CustomFields = ({ draft, addCustomField, removeCustomField, updateCustomField }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container direction="column" spacing={2} className={classes.fieldsWrapper}>
                {_.map(draft.custom_fields, (customField, index) => (
                    <Grid key={index} item xs={12}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={6}>
                                <TextField
                                    label="Field Name"
                                    value={customField.name}
                                    fullWidth
                                    disableMargin
                                    onChange={e =>
                                        updateCustomField(customField.key, 'name', e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container justifyContent="center">
                                    <FormControlLabel
                                        labelPlacement="top"
                                        label="Required"
                                        checked={customField.required}
                                        control={
                                            <Switch
                                                color="primary"
                                                onChange={e =>
                                                    updateCustomField(
                                                        customField.key,
                                                        'required',
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    className={classes.button}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => removeCustomField(customField.key)}
                                >
                                    Remove
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Box mt={1}>
                <Button
                    className={classes.button}
                    onClick={addCustomField}
                    variant="outlined"
                    color="primary"
                >
                    Add
                </Button>
            </Box>
        </React.Fragment>
    );
};

export default connect(selector)(CustomFields);

import _ from 'lodash';
import React from 'react';

class StatusPage extends React.Component {
    render() {
        return <div>Welcome to the Status page.</div>;
    }
}

export default StatusPage;

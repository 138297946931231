import _ from 'lodash';
import React from 'react';

import FormField from 'widgets-v5/form-field';
import GoogleMapsApiLoader from 'containers/google-maps-api-loader';
import GeofenceSelector from 'widgets/form-inputs/geofence-selector';
import Spacer from 'widgets-v5/spacer';
import Separator from 'widgets-v5/separator';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { getGeoFencingWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

class GeoFencingOld extends React.Component {
    handleFormChange = value => {
        this.props.onChange(value);
    };

    render() {
        const { value, platforms, isCrossPlatformCampaign } = this.props;
        const geoFencingWarning = getGeoFencingWarning({ platforms, geofence: value });

        return (
            <div className="ef5-ad-setup-form-geo-boxes">
                <Separator label="Geofencing" />
                <Spacer />
                <FormField
                    description=""
                    errors={this.props.errors}
                    showErrors={this.props.showErrors}
                    isRequired={false}
                >
                    <GoogleMapsApiLoader>
                        <GeofenceSelector
                            onChange={this.handleFormChange}
                            canDrawCircles={false}
                            value={this.props.value}
                        />
                    </GoogleMapsApiLoader>
                </FormField>
                {isCrossPlatformCampaign && geoFencingWarning.hasWarning && (
                    <React.Fragment>
                        <br />
                        <WarningText
                            message={geoFencingWarning.message}
                            title={geoFencingWarning.title}
                            severity={geoFencingWarning.severity}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default GeoFencingOld;

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'utils/shallow-compare';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import makeStyles from '@mui/styles/makeStyles';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import { SimpleSelect } from 'widgets-v6/select';

import selector from './selector';

const useStyles = makeStyles(theme => ({
    locationList: {
        width: 200,
        marginTop: 10,
    },
}));

class FootTrafficAttributionV2SummaryComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, locationListMapping } = this.props;

        return (
            <div className="media-plan-form__overview-table-data">
                {campaign.fta_management_level === 'campaign' && (
                    <Typography gutterBottom>FTA Enabled - Managed by Campaign</Typography>
                )}
                <Typography gutterBottom>{ad.fta.enabled ? 'Enabled' : 'Disabled'}</Typography>
                {ad.fta.enabled && (
                    <React.Fragment>
                        <Typography gutterBottom>
                            Location List: {locationListMapping[ad.fta.location_list]}
                        </Typography>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export const FootTrafficAttributionV2Summary = connect(selector)(
    FootTrafficAttributionV2SummaryComponent
);

const FootTrafficAttributionV2FormComponent = props => {
    const classes = useStyles();
    const {
        ad,
        ads,
        campaign,
        onSave,
        locationListMapping,
        ftaLocationListOptions,
        partner_id,
    } = props;
    const { fta_management_level, fta_fee_standard, fta_enabled } = campaign;
    const campaignFtaLocationList = _.get(campaign, 'fta_location_list', '');
    const updateFta = ({ changes, draft, updateDraft }) => {
        const ftaChanges = {
            ...draft.fta,
            ...changes,
            partner_id,
        };
        const fta_fee = ftaChanges.enabled ? fta_fee_standard : 0;
        if (fta_management_level === 'campaign') {
            ftaChanges.location_list = campaignFtaLocationList;
        } else if (ftaChanges.enabled === false) {
            ftaChanges.location_list = null;
        } else {
            ftaChanges.location_list = ftaChanges.location_list;
        }

        updateDraft({ fta: ftaChanges, fta_fee });
    };

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Foot Traffic Attribution"
            fields={['fta_fee', 'fta']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
            columnName="Foot Traffic Attribution"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                if (!fta_enabled) {
                    return <Typography variant="h6">FTA is disabled for this campaign.</Typography>;
                }
                return (
                    <React.Fragment>
                        {fta_management_level === 'ad' && (
                            <FormControl error={shouldShowErrors && errors.fta}>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!draft.fta.enabled}
                                                onChange={() => {
                                                    updateFta({
                                                        changes: { enabled: !draft.fta.enabled },
                                                        draft,
                                                        updateDraft,
                                                    });
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Enable FTA"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        )}
                        {fta_management_level === 'campaign' && (
                            <Typography>FTA Enabled - Managed by Campaign</Typography>
                        )}
                        {draft.fta.enabled && (
                            <div>
                                <FormLabel error={shouldShowErrors && errors.fta}>
                                    FTA Location List
                                </FormLabel>
                                {draft.fta.enabled && fta_management_level === 'ad' && (
                                    <SimpleSelect
                                        className={classes.locationList}
                                        value={draft.fta.location_list || ''}
                                        onChange={e => {
                                            updateFta({
                                                changes: { location_list: e.target.value },
                                                draft,
                                                updateDraft,
                                            });
                                        }}
                                        options={ftaLocationListOptions}
                                        placeholder="Select a location list"
                                        required
                                    />
                                )}

                                {fta_management_level === 'campaign' && (
                                    <Typography>
                                        Location List:{' '}
                                        {locationListMapping[campaignFtaLocationList]}
                                    </Typography>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                );
            }}
        </BaseColumnForm>
    );
};

export const FootTrafficAttributionV2Form = connect(selector)(
    FootTrafficAttributionV2FormComponent
);

import React, { lazy, Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const ProgressGraph = lazy(() => import('./index'));

const ProgressGraphLazy = props => {
    return (
        <Suspense fallback={<LinearProgress />}>
            <ProgressGraph {...props} />
        </Suspense>
    );
};

export default ProgressGraphLazy;

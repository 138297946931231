import React, { Fragment } from 'react';

import {
    Section,
    List,
    ListItem,
    Layout,
    Paragraph,
    BoldText,
    EmphasizedText,
} from '../modules/layout.js';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import Spacer from 'widgets-v5/spacer';

export default {
    category: 'Reference',
    topic: 'Content Restrictions',
    url: 'reference/content-restrictions',
    published: false,
    Component: class Article extends React.Component {
        constructor(props) {
            super(props);

            this.prohibited = React.createRef();
            this.restricted = React.createRef();
        }

        render() {
            return (
                <Layout
                    title="App Categories"
                    sidebarContent={[
                        {
                            title: 'Prohibited Content',
                            link: this.prohibited,
                        },
                        {
                            title: 'Restricted Content',
                            link: this.restricted,
                        },
                    ]}
                >
                    <Section link={this.prohibited} title="Prohibited Content">
                        <Paragraph>
                            Media Buyers must not serve ads which promote the following content.
                        </Paragraph>
                        <List>
                            <ListItem>
                                Ads making misleading or deceptive claims: IAB26-1 (Illegal Content)
                            </ListItem>
                            <ListItem>Adult sexual content: IAB25-3 (Pornography)</ListItem>
                            <ListItem>
                                Copyrighted materials: IAB26-4 (Copyright Infringement)
                            </ListItem>
                            <ListItem>Counterfeit goods: IAB26-4 (Copyright Infringement)</ListItem>
                            <ListItem>
                                Drugs and drug paraphernalia: IAB26-1 (Illegal Content)
                            </ListItem>
                            <ListItem>
                                Endangered species products: IAB26-1 (Illegal Content)
                            </ListItem>
                            <ListItem>
                                Hate content, sensitive topics and violence: IAB25-5 (Hate Content),
                                IAB25-2 (Extreme Graphic/Explicit Violence)
                            </ListItem>
                            <ListItem>
                                Illegal products and services: IAB26-1 (Illegal Content)
                            </ListItem>
                            <ListItem>
                                Incentivized clicks and downloads: IAB25-7 (Incentivized)*
                            </ListItem>
                            <ListItem>
                                Spyware and illegal hacking: IAB26-3 (Spyware/Malware)
                            </ListItem>
                            <ListItem>Tobacco and tobacco accessories: IAB9-9 (Cigars)</ListItem>
                            <ListItem>
                                P2P file-sharing apps, torrent or any apps that facilitate or
                                promote copyright infringement: IAB26-4 (Copyright Infringement)
                            </ListItem>
                            <ListItem>
                                Unauthorized ticket sales: IAB26-1 (Illegal Content)
                            </ListItem>
                            <ListItem>
                                Weapons and weapons accessories: IAB26-1 (Illegal Content)
                            </ListItem>
                        </List>
                    </Section>
                    <Section link={this.restricted} title="Restricted Content">
                        <Paragraph>
                            Media Buyers may run ads that contain certain restrictied content, where
                            compliant with the following restrictions{' '}
                            <EmphasizedText>
                                <BoldText>in addition to</BoldText>
                            </EmphasizedText>
                            the applicable laws, licensing requirements, and industry standards for
                            any location that you target.
                        </Paragraph>
                        <Spacer />
                        <ComposableDataTable>
                            <Row header>
                                <Cell>Content</Cell>
                                <Cell>Restrictions</Cell>
                            </Row>
                            <Row>
                                <Cell>Alcohol</Cell>
                                <Cell>
                                    <Paragraph>
                                        <BoldText>Advertiser Category</BoldText>
                                        <br />
                                        Campaigns must be tagged as IAB8-5 (Cocktails/Beer) or
                                        IAB8-18 (Wine).
                                    </Paragraph>
                                    <Paragraph>
                                        <BoldText>Age Targeting</BoldText>
                                        <br />
                                        Ads must be age targeted to at least 19+ in Canada, or the
                                        applicable minimum drinking age in the country to which you
                                        are advertising.
                                    </Paragraph>
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Financial services</Cell>
                                <Cell>
                                    <Paragraph>
                                        <BoldText>Advertiser Category</BoldText>
                                        <br />
                                        Campaigns must be tagged as IAB13-1 (Beginning Investing),
                                        IAB13-2 (Credit/Debt & Loans), IAB13-4 (Financial Planning),
                                        IAB13-5 (Hedge Fund), IAB13-6 (Insurance), IAB13-7
                                        (Investing), IAB13-8 (Mutual Funds), IAB13-9 (Options),
                                        IAB13-10 (Retirement Planning), or IAB13-11 (Stocks).
                                    </Paragraph>
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Gambling</Cell>
                                <Cell>
                                    <Paragraph>
                                        <BoldText>Advertiser Category</BoldText>
                                        <br />
                                        Campaigns must be tagged as IAB9-7 (Card Games)
                                    </Paragraph>
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Lotteries</Cell>
                                <Cell>
                                    <Paragraph>
                                        <BoldText>Advertiser Category</BoldText>
                                        <br />
                                        Campaigns must be tagged as IAB3-7 (Government)
                                    </Paragraph>
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Health and pharmaceutical</Cell>
                                <Cell>
                                    <Paragraph>
                                        <BoldText>Advertiser Category</BoldText>
                                        <br />
                                        Campaigns must be tagged as IAB7-5 (Alternative Medicine)
                                    </Paragraph>
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Political campaigning</Cell>
                                <Cell>
                                    <Paragraph>
                                        <BoldText>Advertiser Category</BoldText>
                                        <br />
                                        Campaigns must be tagged as IAB11-4 (Politics)
                                    </Paragraph>
                                </Cell>
                            </Row>
                        </ComposableDataTable>
                    </Section>
                </Layout>
            );
        }
    },
};

import _ from 'lodash';

export const NAME = 'appsReport';

const getInitialState = () => {
    return {
        isFilterPopUpOpen: false,
        selectedMetrics: ['impressions', 'clicks', 'ctr'],
        selectedDimensions: ['channel_name'],
        selectedAttributes: ['app_store_cat_attr'],
        hideZeroRows: false,
        limit: 100,
        sortDirection: 'desc',
        errors: {},
        draft: {
            selectedMetrics: ['impressions', 'clicks', 'ctr'],
            selectedDimensions: ['channel_name'],
            selectedAttributes: ['app_store_cat_attr'],
            limit: 100,
        },
        showErrors: false,
    };
};

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case 'APPS_REPORT__OPEN_FILTER_OPTIONS': {
            return {
                ...state,
                isFilterPopUpOpen: true,
            };
        }

        case 'APPS_REPORT__CLOSE_FILTER_OPTIONS': {
            return {
                ...state,
                isFilterPopUpOpen: false,
                showErrors: false,
            };
        }

        case 'APPS_REPORT__UPDATE_DIMENSIONS': {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    selectedDimensions: action.payload.dimensions,
                },
            };
        }

        case 'APPS_REPORT__UPDATE_ATTRIBUTES': {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    selectedAttributes: action.payload.attributes,
                },
            };
        }

        case 'APPS_REPORT__UPDATE_METRICS': {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    selectedMetrics: action.payload.metrics,
                },
            };
        }

        case 'APPS_REPORT__UPDATE_LIMIT': {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    limit: action.payload.limit,
                },
            };
        }

        case 'APPS_REPORT__DELETE_DIMENSION': {
            const filteredDimensions = _.filter(
                state.selectedDimensions,
                dimension => dimension !== action.payload.dimension
            );

            return {
                ...state,
                selectedDimensions: filteredDimensions,
            };
        }

        case 'APPS_REPORT__APPLY_FILTERS_START': {
            return {
                ...state,
                showErrors: true,
            };
        }

        case 'APPS_REPORT__APPLY_FILTERS_SUCCESS': {
            return {
                ...state,
                selectedMetrics: state.draft.selectedMetrics,
                selectedDimensions: state.draft.selectedDimensions,
                selectedAttributes: state.draft.selectedAttributes,
                limit: state.draft.limit,
            };
        }

        default: {
            return state;
        }
    }
}

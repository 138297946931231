import React, { useState, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Icon from '@mdi/react';
import { mdiLeafMaple } from '@mdi/js';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';

import { AudienceDetails } from '../modules/audience-details';

import { AudienceTable } from '../modules/audience-table';
import { AudienceCard } from '../modules/audience-card';

const sortingOptions = [
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
];

export const manifoldMetaDataDescriptions = [
    {
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.avgIncome', 'N/A') || 'N/A',
        label: 'Average Income',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.homeValue', 'N/A') || 'N/A',
        label: 'Home Value',
    },
    {
        getValue: ({ segment }) =>
            _.get(segment, 'manifoldMetadata.avgMaintainersAge', 'N/A') || 'N/A',
        label: "Average Maintainer's Age",
    },
    {
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.householdSize', 'N/A') || 'N/A',
        label: 'Household Size',
    },
];

export const leftSideSegmentDescriptions = [
    {
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.residence', 'N/A') || 'N/A',
        label: 'Residence',
        img: '/images/audience-segments/audience-details_residence.png',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.ancestry', 'N/A') || 'N/A',
        label: 'Ancestry',
        img: '/images/audience-segments/audience-details_ancestry.png',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.shopping', 'N/A') || 'N/A',
        label: 'Shopping',
        img: '/images/audience-segments/audience-details_shopping.png',
    },
    {
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.education', 'N/A') || 'N/A',
        label: 'Education',
        img: '/images/audience-segments/audience-details_education.png',
    },
];

export const rightSideSegmentDescriptions = [
    {
        value: 'lifestyle',
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.lifestyle', 'N/A') || 'N/A',
        label: 'Lifestyle',
        img: '/images/audience-segments/audience-details_lifestyle.png',
    },
    {
        value: 'jobType',
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.jobType', 'N/A') || 'N/A',
        label: 'Job Type',
        img: '/images/audience-segments/audience-details_job.png',
    },
    {
        value: 'bigTicket',
        getValue: ({ segment }) => _.get(segment, 'manifoldMetadata.purchaseItems', 'N/A') || 'N/A',
        label: 'Purchasing Big Ticket Items',
        img: '/images/audience-segments/audience-details_lifestyle.png',
    },
];

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: 400,
        width: '100%',
    },
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#098679',
    },
}));

const Layout = ({ audienceSegments, onSearch, activeAudience, organizationId }) => {
    const classes = useStyles();
    const [audiences, setAudiences] = useState(audienceSegments);
    const [segmentInFocus, setSegmentInFocus] = useState(null);
    const [activeSort, setActiveSort] = useState('name');

    useEffect(() => {
        setAudiences(audienceSegments);
    }, [audienceSegments]);

    const handleSortChange = event => {
        setActiveSort(event.target.value);
    };

    const handleSegmentSelect = segment => {
        setSegmentInFocus(segment);
    };

    const handleClose = () => {
        setSegmentInFocus(null);
    };

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const sortedAudiences = _.orderBy(
        audiences,
        [activeSort],
        [_.find(sortingOptions, option => activeSort === option.value).direction]
    );

    const goToNextAudience = () => {
        const currentAudienceIndex = _.findIndex(
            sortedAudiences,
            audience => audience.id === segmentInFocus.id
        );

        const nextSegment = sortedAudiences[currentAudienceIndex + 1];

        setSegmentInFocus(nextSegment);
    };

    const goToPrevAudience = () => {
        const currentAudienceIndex = _.findIndex(
            sortedAudiences,
            audience => audience.id === segmentInFocus.id
        );

        const nextSegment = sortedAudiences[currentAudienceIndex - 1];

        setSegmentInFocus(nextSegment);
    };

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'pelmorex' },
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Pelmorex Audiences
                </RouterLink>
                <Typography color="textPrimary">CanaCode</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <Icon path={mdiLeafMaple} size={3} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            CanaCode
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            CanaCode classifies Canada's consumer landscape into 18 distinct
                            lifestyle segments based on consumer indicators such as
                            socio-demographics, behaviour, spending, etc.
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Box mt={3}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Total
                                    </Typography>
                                    <Typography variant="h5">
                                        {audienceSegments ? audienceSegments.length : 0}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mb={2.5} mt={5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <SearchInput placeholder="Filter Audiences" onChange={handleSearch} />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <AudienceTable
                    audiences={sortedAudiences}
                    activeAudience={activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            segment={segment}
                            active={activeAudience === segment.id}
                            onClick={() => handleSegmentSelect(segment)}
                            metaData={
                                <Grid container spacing={1}>
                                    {_.map(segment.manifoldMetadata.summaryPoints, summaryPoint => (
                                        <Grid item key={`${segment.id}_${summaryPoint}`}>
                                            <Chip
                                                label={summaryPoint}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                            organizationId={organizationId}
                        />
                    )}
                />
                {!_.isNil(segmentInFocus) && (
                    <AudienceDetails
                        isOpen={!_.isNil(segmentInFocus)}
                        segment={segmentInFocus}
                        onClose={handleClose}
                        onClickNextAudience={goToNextAudience}
                        onClickPrevAudience={goToPrevAudience}
                        isLastSegment={
                            _.findIndex(sortedAudiences, { id: segmentInFocus.id }) ===
                            sortedAudiences.length - 1
                        }
                        isFirstSegment={
                            _.findIndex(sortedAudiences, { id: segmentInFocus.id }) === 0
                        }
                        segmentDetails={manifoldMetaDataDescriptions}
                        summaryPoints={segmentInFocus.manifoldMetadata.summaryPoints}
                        body={
                            <React.Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Grid container direction="column" spacing={2}>
                                            {_.map(leftSideSegmentDescriptions, description => (
                                                <Grid
                                                    item
                                                    key={`${segmentInFocus.id}_${
                                                        description.label
                                                    }`}
                                                >
                                                    <Card elevation={0} variant="outlined">
                                                        <CardContent>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <img
                                                                        className={classes.image}
                                                                        src={description.img}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Typography gutterBottom>
                                                                        {description.label}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {description.getValue({
                                                                            segment: segmentInFocus,
                                                                        })}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container direction="column" spacing={2}>
                                            {_.map(rightSideSegmentDescriptions, description => (
                                                <Grid
                                                    item
                                                    key={`${segmentInFocus.id}_${
                                                        description.label
                                                    }`}
                                                >
                                                    <Card elevation={0} variant="outlined">
                                                        <CardContent>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <img
                                                                        className={classes.image}
                                                                        src={description.img}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Typography gutterBottom>
                                                                        {description.label}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {description.getValue({
                                                                            segment: segmentInFocus,
                                                                        })}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    />
                )}
            </Container>
        </Box>
    );
};

export default Layout;

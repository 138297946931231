import _ from 'lodash';
import React from 'react';
import 'moment-timezone';
import shallowCompare from 'utils/shallow-compare';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import withStyles from '@mui/styles/withStyles';

import BaseColumnForm from '../base-column-form';

import { PlatformMapping } from 'states/resources/ads/business-logic';
import { revenueModelToBillingTerm } from 'states/resources/campaigns/business-logic';

import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';

import {
    ImpressionsPacing,
    ClicksPacing,
    TotalMediaSpendPacing,
    RevenuePacing,
} from 'forms/ad-form/modules/pacing';

const OptionCaption = withStyles(theme => ({
    root: {
        marginLeft: '30px',
        width: '60%',
    },
}))(Typography);

export class PacingStrategySummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    getGoalType = () => {
        const { ad } = this.props;
        const mapping = {
            CPM: 'Impressions',
            CPC: 'Clicks',
            billable_media_cost_markup: 'Total Cost',
        };

        return mapping[ad.ef_billing_terms];
    };

    getPacingValue = term => {
        const { ad } = this.props;

        const termMapping = {
            CPM: 'max_daily_impressions',
            CPC: 'max_daily_clicks',
            billable_media_cost_markup: 'max_daily_spend_local',
            billable_media_cost_margin: 'max_daily_billings',
        };

        if (!ad[termMapping[term]]) {
            return 'Auto';
        }

        switch (term) {
            case 'CPM':
                return formatNumber_whole(ad.max_daily_impressions);
            case 'CPC':
                return formatNumber_whole(ad.max_daily_clicks);
            case 'billable_media_cost_markup':
                return formatNumber_currency(ad.max_daily_spend_local);
            case 'billable_media_cost_margin':
                return formatNumber_currency(ad.max_daily_billings);
        }
    };

    render() {
        const { ad, campaign } = this.props;
        const goalType = this.getGoalType();

        if (campaign.budget_allocation_method === 'auto') {
            return (
                <div>
                    <Typography> Auto-budget enabled at campaign level </Typography>
                </div>
            );
        }
        return (
            <div className="media-plan-form__overview-table-data">
                <div>Pacing Strategy:</div>
                <Typography gutterBottom>
                    {ad.use_front_load_pacing
                        ? _.isNil(goalType)
                            ? `Front-loaded Pacing`
                            : `Front-loaded Pacing: ${goalType}`
                        : _.isNil(goalType)
                        ? `Even Pacing`
                        : `Even Pacing: ${goalType}`}
                </Typography>
                {!ad.use_front_load_pacing && (
                    <div>
                        <Typography>Daily Pacing:</Typography>
                        {this.getPacingValue('CPM') === 'Auto' &&
                        this.getPacingValue('CPC') === 'Auto' &&
                        this.getPacingValue('billable_media_cost_margin') === 'Auto' &&
                        this.getPacingValue('billable_media_cost_markup') === 'Auto' ? (
                            <Typography>Auto</Typography>
                        ) : (
                            <React.Fragment>
                                {this.getPacingValue('CPM') !== 'Auto' && (
                                    <React.Fragment>
                                        <Typography>
                                            Impressions: {`${this.getPacingValue('CPM')} per day`}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                {this.getPacingValue('CPC') !== 'Auto' && (
                                    <React.Fragment>
                                        <Typography>
                                            Clicks: {`${this.getPacingValue('CPC')} per day`}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                {ad.ef_billing_terms === 'billable_media_cost_margin' &&
                                    this.getPacingValue('billable_media_cost_margin') !==
                                        'Auto' && (
                                        <React.Fragment>
                                            <Typography>
                                                Revenue:{' '}
                                                {`${this.getPacingValue(
                                                    'billable_media_cost_margin'
                                                )} per day`}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                {this.getPacingValue('billable_media_cost_markup') !== 'Auto' && (
                                    <React.Fragment>
                                        <Typography>
                                            Total Cost:{' '}
                                            {`${this.getPacingValue(
                                                'billable_media_cost_markup'
                                            )} per day`}
                                        </Typography>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export const PacingStrategyForm = props => {
    const { ad, ads, campaign, onSave } = props;
    const handleChange = (e, updateDraft) => {
        const isUsingFrontLoadPacing = e.target.value === 'true';
        const updateObj = { use_front_load_pacing: isUsingFrontLoadPacing };
        updateDraft(updateObj);
    };

    const handlePacingFieldChange = (fieldName, updateDraft) => {
        return fieldValue => {
            const newFormData = {
                [fieldName]: fieldValue,
            };

            updateDraft(newFormData);
        };
    };

    const getGoalType = () => {
        const { ad } = props;
        if (!ad) {
            return;
        }
        const mapping = {
            CPM: 'Impressions',
            CPC: 'Clicks',
            billable_media_cost_markup: 'Total Cost',
        };

        return mapping[ad.ef_billing_terms];
    };

    const getDefaultBillingTerm = () => {
        return revenueModelToBillingTerm(campaign.revenueModel);
    };

    const isStandardCampaign = campaign.type === PlatformMapping.Standard;

    const efBillingTerm = ad ? ad.ef_billing_terms : getDefaultBillingTerm();
    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Pacing Strategy"
            fields={[
                'use_front_load_pacing',
                'max_daily_impressions',
                'max_daily_clicks',
                'max_daily_billings',
                'max_daily_spend_local',
            ]}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
            columnName="Pacing Strategy"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <React.Fragment>
                        <FormControl fullWidth margin="normal">
                            <FormGroup row>
                                <RadioGroup
                                    aria-label="use_front_load_pacing"
                                    name="use_front_load_pacing"
                                    value={draft.use_front_load_pacing}
                                    onChange={e => handleChange(e, updateDraft)}
                                >
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio color="primary" />}
                                        label={`${
                                            _.isNil(getGoalType())
                                                ? `Even Pacing`
                                                : `Even Pacing: ${getGoalType()}`
                                        }`}
                                        error={shouldShowErrors}
                                        helperText={
                                            shouldShowErrors && errors.use_front_load_pacing
                                        }
                                    />
                                    <OptionCaption variant="body2" paragraph color="textSecondary">
                                        System will evenly pace the ad line to deliver the same
                                        daily goal - either spend, impressions or clicks -
                                        throughout the duration of the ad line flight.
                                    </OptionCaption>
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio color="primary" />}
                                        error={shouldShowErrors}
                                        helperText={
                                            shouldShowErrors && errors.use_front_load_pacing
                                        }
                                        label={`${
                                            _.isNil(getGoalType())
                                                ? `Front-loaded Pacing`
                                                : `Front-loaded Pacing: ${getGoalType()}`
                                        }`}
                                    />
                                    <OptionCaption variant="body2" paragraph color="textSecondary">
                                        System will frontload the ad line to deliver 50% of the
                                        total goal - either spend, impression or clicks, within the
                                        first third of the ad line flight. Use this method to
                                        mitigate under delivery risk.
                                    </OptionCaption>
                                </RadioGroup>
                            </FormGroup>
                        </FormControl>
                        {!draft.use_front_load_pacing && (
                            <FormGroup>
                                <Typography gutterBottom>Daily Pacing Overrides</Typography>
                                <FormControl
                                    margin="normal"
                                    error={shouldShowErrors && errors.max_daily_impressions}
                                >
                                    <div>
                                        <Typography>Impressions</Typography>
                                        <ImpressionsPacing
                                            formData={draft}
                                            onFieldChange={fieldName => fieldValue =>
                                                handlePacingFieldChange(fieldName, updateDraft)(
                                                    fieldValue
                                                )}
                                            errors={errors}
                                            showErrors={shouldShowErrors}
                                            updateDraft={newFormData => updateDraft(newFormData)}
                                        />
                                    </div>
                                </FormControl>
                                {isStandardCampaign && (
                                    <FormControl
                                        margin="normal"
                                        error={shouldShowErrors && errors.max_daily_impressions}
                                    >
                                        <Typography>Clicks</Typography>
                                        <ClicksPacing
                                            formData={draft}
                                            onFieldChange={fieldName => fieldValue =>
                                                handlePacingFieldChange(fieldName, updateDraft)(
                                                    fieldValue
                                                )}
                                            errors={errors}
                                            showErrors={shouldShowErrors}
                                            updateDraft={newFormData => updateDraft(newFormData)}
                                        />
                                    </FormControl>
                                )}
                                {efBillingTerm === 'billable_media_cost_margin' ? (
                                    <FormControl
                                        margin="normal"
                                        error={shouldShowErrors && errors.max_daily_billings}
                                        fullWidth
                                    >
                                        <Typography>Revenue</Typography>
                                        <RevenuePacing
                                            formData={draft}
                                            onFieldChange={fieldName => fieldValue =>
                                                handlePacingFieldChange(fieldName, updateDraft)(
                                                    fieldValue
                                                )}
                                            errors={errors}
                                            showErrors={shouldShowErrors}
                                            updateDraft={newFormData => updateDraft(newFormData)}
                                        />
                                    </FormControl>
                                ) : (
                                    <FormControl
                                        margin="normal"
                                        error={shouldShowErrors && errors.max_daily_spend_local}
                                        fullWidth
                                    >
                                        <Typography>Total Cost</Typography>
                                        <TotalMediaSpendPacing
                                            formData={draft}
                                            onFieldChange={fieldName => fieldValue =>
                                                handlePacingFieldChange(fieldName, updateDraft)(
                                                    fieldValue
                                                )}
                                            errors={errors}
                                            showErrors={shouldShowErrors}
                                            updateDraft={newFormData => updateDraft(newFormData)}
                                        />
                                    </FormControl>
                                )}
                            </FormGroup>
                        )}
                    </React.Fragment>
                );
            }}
        </BaseColumnForm>
    );
};

import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.adPresets';
export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__AD_PRESET__FETCH__SUCCESS:
            const { adpreset, adpresets } = action.payload;
            return mergeResource(
                state,
                []
                    .concat(adpreset)
                    .concat(adpresets)
                    .filter(x => x)
            );
        default:
            return state;
    }
}

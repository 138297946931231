import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import makeStyles from '@mui/styles/makeStyles';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';

import { DialogTitle } from 'widgets-v6/modal';
import DateTimeRangePicker from 'widgets/date-time-range-picker';

const useStyles = makeStyles(() => ({
    textFieldInput: {
        padding: 13,
        backgroundColor: 'white',
        borderRadius: 'inherit',
    },
}));

export function DateRangeSelector(props) {
    const { timezone, dropDownOptions } = props;

    const [open, setOpen] = React.useState(false);
    const defaultOption = _.find(dropDownOptions, { selectedDefault: true });
    const [value, setValue] = React.useState(
        defaultOption ? defaultOption.title : dropDownOptions[0].title
    );
    const [customStartEnd, setCustomDate] = React.useState({
        start: moment.tz(props.campaignFlight.start, timezone).format(),
        end: moment.tz(props.campaignFlight.end, timezone).format(),
    });
    const classes = useStyles();

    const handleChange = value => {
        setValue(value);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSetCustomDate = (start, end) => {
        setCustomDate({ start, end });
    };

    const selectFromList = index => {
        const { timezone } = props;
        let activeItem;
        if (index === props.dropDownOptions.length - 1 && !props.disableCustomRange) {
            activeItem = {
                ...props.dropDownOptions[index],
                start: moment.tz(customStartEnd.start, timezone).format('YYYY-MM-DDTHH:mm:ss'),
                end: moment.tz(customStartEnd.end, timezone).format('YYYY-MM-DDTHH:mm:ss'),
            };
        } else {
            activeItem = props.dropDownOptions[index];
        }

        props.onChange(activeItem);
    };

    const handleApply = () => {
        if (customStartEnd.start && customStartEnd.end) {
            selectFromList(props.dropDownOptions.length - 1);
        }
        handleClose();
    };

    return (
        <React.Fragment>
            <Box mb={0.5}>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        classes={{ root: classes.textFieldInput }}
                        value={value}
                        onChange={e => handleChange(e.target.value)}
                    >
                        {_.map(props.dropDownOptions, (option, index) => {
                            if (option.isSubheader) {
                                return (
                                    <ListSubheader key={option.title}>{option.title}</ListSubheader>
                                );
                            }
                            const isCustomRange =
                                index === props.dropDownOptions.length - 1 &&
                                !props.disableCustomRange;

                            return (
                                <MenuItem
                                    key={option.title}
                                    value={option.title}
                                    onClick={
                                        isCustomRange ? handleOpen : () => selectFromList(index)
                                    }
                                >
                                    {option.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
            <Typography>{`${moment
                .tz(props.campaignFlight.start, timezone)
                .format('LL')} - ${moment
                .tz(props.campaignFlight.end, timezone)
                .format('LL')}`}</Typography>
            <Dialog open={open}>
                <DialogTitle>Custom Range</DialogTitle>
                <DialogContent dividers>
                    <DateTimeRangePicker
                        timezone={props.timezone}
                        mode="inline"
                        value={{
                            start: customStartEnd.start,
                            end: customStartEnd.end,
                        }}
                        onChange={({ start, end }) => handleSetCustomDate(start, end)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleApply}
                        disabled={!customStartEnd.start && !customStartEnd.end}
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

import { map, filter, get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import moment from 'moment';

import FileUpload from 'widgets-v5/file-upload';
import { ComposableDataTable, Row, Cell, MobileHeader } from 'widgets-v5/data-table';
import { SecondaryButton, ButtonGroup, NeutralButton } from 'widgets-v5/buttons';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import Card from 'widgets-v5/card';
import DateTimeRangePicker from 'widgets/date-time-range-picker';
import { ButtonTitle } from 'widgets-v5/typography';
import DragAndDropFileUpload from 'widgets-v5/drag-and-drop-file-upload';
import RadioSelector from 'widgets-v5/radio-selector';
import DownloadTextContents from 'widgets-v5/download-text-contents';
import { TextField } from 'widgets-v6/standard-input';
import { SimpleSelect, SingleSelect } from 'widgets-v6/select';
import { InfoTooltip } from 'widgets-v6/tooltip';

import { isInternalUser } from 'states/profile/business-rules';

import {
    RADIUS_OPTIONS,
    TEMPLATE_CONTENTS,
    TEMPLATE_FILENAME,
    EXAMPLE_TEMPLATE_CONTENTS_EN_1562,
    EXAMPLE_TEMPLATE_FILENAME,
    AUDIENCE_STATUS_MAPPING,
} from './services/constants';

const ErrorContainer = withStyles(theme => ({
    root: {
        margin: `${theme.spacing(1)} 0`,
        padding: 0,
    },
}))(Container);

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audienceStatus: false,
        };
    }

    static propTypes = {
        updateLayer: PropTypes.func.isRequired,
        onFileChange: PropTypes.func.isRequired,

        isInitializing: PropTypes.bool.isRequired,
    };

    handleUnsuspendAudience = () => {
        const { audienceStatus } = this.state;
        const checkedAudienceStatus = !audienceStatus;
        this.setState({ audienceStatus: checkedAudienceStatus });

        this.props.updateLayer(
            'status',
            checkedAudienceStatus
                ? AUDIENCE_STATUS_MAPPING.active
                : AUDIENCE_STATUS_MAPPING.suspended
        );
        this.props.updateLayer(
            'manual_activation_until',
            checkedAudienceStatus
                ? moment()
                      .add(18, 'months')
                      .toISOString()
                : null
        );
    };

    render() {
        const {
            updateLayer,
            onFileChange,

            draft: {
                layerMetadata: { name, radius, category_code, time_constraint, status },
                audience_type,
            },

            metadataErrors: {
                name: nameError,
                radius: radiusError,
                category_code: categoryError,
                time_constraint: timeConstraintError,
            },

            isInitializing,
            failedAssets,
            showInvalidFileError,
            showErrors,
            isInterpolatingPoints,

            poiErrors,
            poiStatus,
            geoResources,
            cleanlistErrors,
            serverSaveErrors,
            csvErrors,
        } = this.props;

        const poiCategoryOptions = [];

        const poiAudienceTypeOptions = [
            { label: 'Always On', value: 'always-on' },
            { label: 'Time Limited', value: 'time-limited' },
        ];

        if (geoResources.geoCategories) {
            geoResources.geoCategories.forEach(majorCategory => {
                majorCategory.subcategories.forEach(minorCategory => {
                    poiCategoryOptions.push({
                        label: `${majorCategory.name} - ${minorCategory.name}`,
                        value: minorCategory.id,
                    });
                });
            });
        }

        let showDateRangePicker = audience_type === poiAudienceTypeOptions[1]?.value;

        const notProcessedPoints = [];
        if (this.props.draft.points.length > 0) {
            /**
             * Store points that have no latitude or longitude
             *             */
            this.props.draft.points.forEach(point => {
                if (point.latitude === 0 || point.longitude === 0) {
                    notProcessedPoints.push(point);
                }
            });
        }

        return (
            <BlockLoadGroup isLoading={isInitializing || isInterpolatingPoints}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box my={2}>
                            <TextField
                                label="Segment Name"
                                value={name}
                                onChange={e => updateLayer('name', e.target.value)}
                                disabled={isInitializing}
                                error={nameError && showErrors}
                                required
                                fullWidth
                                disableMargin
                            />
                        </Box>

                        <Box mb={2}>
                            <SimpleSelect
                                label="POI Radius"
                                error={radiusError && showErrors}
                                options={filter(
                                    RADIUS_OPTIONS,
                                    option => option.value <= 2000 || isInternalUser()
                                )}
                                onChange={e => updateLayer('radius', e.target.value)}
                                value={radius}
                                disabled={isInitializing}
                            />
                        </Box>
                        <Box mb={2}>
                            <SingleSelect
                                label="Segment Category"
                                options={poiCategoryOptions}
                                error={categoryError && showErrors}
                                required
                                placeholder="Select segment category"
                                onChange={newValue => updateLayer('category_code', newValue)}
                                value={category_code}
                                disabled={isInitializing}
                            />
                        </Box>
                        <>
                            <Box mb={2}>
                                <Typography> Type </Typography>
                                <RadioSelector
                                    onChange={
                                        this.props.isEditing
                                            ? () => {}
                                            : this.props.changeFlexGeoLocationAudienceType
                                    }
                                    items={poiAudienceTypeOptions}
                                    activeItem={audience_type}
                                    disabled={this.props.isEditing}
                                />
                            </Box>
                            {showDateRangePicker && (
                                <Box mb={2}>
                                    <DateTimeRangePicker
                                        label={
                                            <Box display="flex">
                                                <Box mr={0.5}>Date Range (UTC)</Box>
                                                <InfoTooltip
                                                    placement="right"
                                                    title={
                                                        <React.Fragment>
                                                            Limit tracking to a specific date range
                                                            <br />
                                                            Select a date range to limit time period
                                                            for tracking. Users who do visit POIs
                                                            outside of date range will not be
                                                            considered. This is useful for targeting
                                                            events, conferences and other
                                                            time-limited activities.
                                                            <br />
                                                            Leave empty to keep tracking on
                                                            indefinitely.
                                                        </React.Fragment>
                                                    }
                                                />
                                            </Box>
                                        }
                                        error={timeConstraintError && showErrors}
                                        timezone="UTC"
                                        onChange={({ start, end }) => {
                                            updateLayer('time_constraint', {
                                                date_range: [{ start, end }],
                                            });
                                        }}
                                        value={time_constraint.date_range[0]}
                                        disabled={isInitializing || this.props.isEditing}
                                    />
                                </Box>
                            )}
                            {this.props.isEditing &&
                                audience_type === poiAudienceTypeOptions[0].value &&
                                poiStatus === AUDIENCE_STATUS_MAPPING.suspended && (
                                    <Box mb={2} mt={2}>
                                        <Alert severity="info">
                                            <Typography>
                                                This audience has been suspended due to 18 months of
                                                inactivity.
                                            </Typography>
                                            <Link
                                                href="https://engagefront.notion.site/Flex-Geolocation-Audiences-65186564df84408db4278943e03a004e#84055f3d079748ef8c0a5dab9d3c47a8"
                                                target="_blank"
                                                color="inherit"
                                            >
                                                Details.
                                            </Link>
                                        </Alert>

                                        <Box mb={2}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            status ===
                                                            AUDIENCE_STATUS_MAPPING.active
                                                        }
                                                        color="primary"
                                                        onChange={this.handleUnsuspendAudience}
                                                    />
                                                }
                                                label="Reactivate audience"
                                            />
                                        </Box>
                                    </Box>
                                )}
                        </>
                    </Grid>
                    <Grid item xs={9}>
                        <RadioSelector
                            disabled={this.props.isEditing}
                            onChange={this.props.isEditing ? () => {} : this.props.changeTab}
                            items={[
                                { label: 'Upload POI List', value: 'uploaded' },
                                { label: 'Search for POIs', value: 'cleanlist' },
                            ]}
                            activeItem={this.props.draft.source}
                        />
                        {this.props.draft.source === 'cleanlist' ? (
                            <React.Fragment>
                                <div style={{ marginBottom: 10, marginTop: 20 }}>
                                    {this.props.poiSearch}
                                </div>
                                {showErrors &&
                                    cleanlistErrors &&
                                    cleanlistErrors.length > 0 &&
                                    map(cleanlistErrors, error => (
                                        <Typography
                                            key={`${error.field}_${error.message}`}
                                            color="error"
                                        >
                                            {error.message}
                                        </Typography>
                                    ))}
                            </React.Fragment>
                        ) : (
                            <div
                                style={{ marginTop: 16 }}
                                className="poi-editor-v2__file-uploader-wrapper"
                            >
                                <React.Fragment>
                                    <div className="poi-editor-v2__file-uploader">
                                        <div style={{ marginBottom: 10, marginTop: 20 }}>
                                            <Alert severity="info">
                                                <Typography>
                                                    1. Download the provided template or example CSV
                                                    file.
                                                </Typography>
                                                <Typography>
                                                    2. Complete the table with 1 point of interest
                                                    (POI) per row. Do not remove the header row.
                                                </Typography>
                                                <Box pl={2}>
                                                    <Typography>Required fields are:</Typography>
                                                    <Typography>
                                                        <Box pl={2} display="flex">
                                                            Address, city, and province
                                                            <Box color="text.secondary" mx={1}>
                                                                OR
                                                            </Box>
                                                            Address and postal code
                                                            <Box color="text.secondary" mx={1}>
                                                                OR
                                                            </Box>
                                                            Latitude and Longitude
                                                        </Box>
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    3. Save as CSV format and upload.
                                                </Typography>
                                            </Alert>
                                        </div>
                                        <DragAndDropFileUpload
                                            accept=".csv, text/csv"
                                            failedAssets={failedAssets}
                                            onChange={onFileChange}
                                            onDropRejected={showInvalidFileError}
                                        >
                                            <div style={{ marginBottom: 10 }}>
                                                Drag and drop files here to upload
                                            </div>
                                            <div style={{ marginBottom: 10 }}>OR</div>
                                            <FileUpload
                                                accept={'.csv'}
                                                onChange={onFileChange}
                                                disabled={isInitializing}
                                                buttonText="Upload File"
                                            />
                                        </DragAndDropFileUpload>
                                        <ButtonGroup align="right" style={{ marginTop: 10 }}>
                                            <DownloadTextContents
                                                filename={EXAMPLE_TEMPLATE_FILENAME}
                                                contents={EXAMPLE_TEMPLATE_CONTENTS_EN_1562}
                                            >
                                                {onDownload => (
                                                    <SecondaryButton
                                                        icon={<i className="fa-download fa" />}
                                                        text="Download Example"
                                                        onClick={onDownload}
                                                    />
                                                )}
                                            </DownloadTextContents>
                                            <DownloadTextContents
                                                filename={TEMPLATE_FILENAME}
                                                contents={TEMPLATE_CONTENTS}
                                            >
                                                {onDownload => (
                                                    <SecondaryButton
                                                        icon={<i className="fa-download fa" />}
                                                        text="Download Template"
                                                        onClick={onDownload}
                                                    />
                                                )}
                                            </DownloadTextContents>
                                        </ButtonGroup>
                                        <div style={{ marginBottom: 10 }}>
                                            {this.props.fileName ? (
                                                <div>Selected File</div>
                                            ) : (
                                                <div>No Attached File</div>
                                            )}
                                        </div>
                                        <div className="audience-editor__file-title">
                                            {this.props.fileName && (
                                                <div>
                                                    <i className="fa fa-file-text-o" />{' '}
                                                    {this.props.fileName}
                                                </div>
                                            )}
                                        </div>

                                        {poiErrors.length === 0 ? (
                                            <div>
                                                {this.props.poisProcessed} points detected.{' '}
                                                {this.props.hasDuplicates && (
                                                    <Typography
                                                        color="textSecondary"
                                                        display="inline"
                                                    >
                                                        Duplicate points were filtered out.
                                                    </Typography>
                                                )}
                                            </div>
                                        ) : showErrors ? (
                                            <div>
                                                {map(poiErrors, error => (
                                                    <Typography color="error" key={error}>
                                                        {error}
                                                    </Typography>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    {this.props.draft.points.length > 0 && (
                                        <div className="poi-editor-v2__file-uploader-results">
                                            <div style={{ marginBottom: 10, marginTop: 20 }}>
                                                <ButtonTitle>Bulk Imported POIs</ButtonTitle>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    marginBottom: 10,
                                                }}
                                            >
                                                {this.props.downloadExistingDataButton}
                                            </div>
                                            <Card padding={0} autoHeight margin={0}>
                                                <div className="poi-editor-v2__file-uploader-table">
                                                    <ComposableDataTable stackable>
                                                        <Row header>
                                                            <Cell header>Address</Cell>
                                                            <Cell header>City</Cell>
                                                            <Cell header>Province</Cell>
                                                            <Cell header>Postal Code</Cell>
                                                            <Cell header>Latitude</Cell>
                                                            <Cell header>Longitude</Cell>
                                                            <Cell header />
                                                        </Row>
                                                        {map(notProcessedPoints, (point, index) => (
                                                            <Row key={index} error={true}>
                                                                <Cell>
                                                                    <MobileHeader>
                                                                        Address
                                                                    </MobileHeader>
                                                                    <Grid
                                                                        container
                                                                        spacing={1}
                                                                        direction="row"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item>
                                                                            <Typography>
                                                                                {point.address}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Cell>
                                                                <Cell>
                                                                    <MobileHeader>
                                                                        City
                                                                    </MobileHeader>
                                                                    <Typography>
                                                                        {point.city}
                                                                    </Typography>
                                                                </Cell>
                                                                <Cell>
                                                                    <MobileHeader>
                                                                        Province
                                                                    </MobileHeader>
                                                                    <Typography>
                                                                        {point.region}
                                                                    </Typography>
                                                                </Cell>
                                                                <Cell>
                                                                    <MobileHeader>
                                                                        Postal Code
                                                                    </MobileHeader>
                                                                    <Typography>
                                                                        {point.postalCode}
                                                                    </Typography>
                                                                </Cell>
                                                                <Cell>
                                                                    <MobileHeader>
                                                                        Latitude
                                                                    </MobileHeader>
                                                                    <Typography>
                                                                        {point.latitude}
                                                                    </Typography>
                                                                </Cell>
                                                                <Cell>
                                                                    <MobileHeader>
                                                                        Longitude
                                                                    </MobileHeader>
                                                                    <Typography>
                                                                        {point.longitude}
                                                                    </Typography>
                                                                </Cell>
                                                                <Cell>
                                                                    Address couldn't be geocoded and
                                                                    won't be saved.
                                                                </Cell>
                                                            </Row>
                                                        ))}
                                                        {map(
                                                            this.props.draft.points,
                                                            (point, index) => {
                                                                return notProcessedPoints.includes(
                                                                    point
                                                                ) ? (
                                                                    ''
                                                                ) : (
                                                                    <Row key={index}>
                                                                        <Cell>
                                                                            <MobileHeader>
                                                                                Address
                                                                            </MobileHeader>
                                                                            <Grid
                                                                                container
                                                                                spacing={1}
                                                                                direction="row"
                                                                                alignItems="center"
                                                                            >
                                                                                <Grid item>
                                                                                    <Typography>
                                                                                        {
                                                                                            point.address
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Cell>
                                                                        <Cell>
                                                                            <MobileHeader>
                                                                                City
                                                                            </MobileHeader>
                                                                            <Typography>
                                                                                {point.city}
                                                                            </Typography>
                                                                        </Cell>
                                                                        <Cell>
                                                                            <MobileHeader>
                                                                                Province
                                                                            </MobileHeader>
                                                                            <Typography>
                                                                                {point.region}
                                                                            </Typography>
                                                                        </Cell>
                                                                        <Cell>
                                                                            <MobileHeader>
                                                                                Postal Code
                                                                            </MobileHeader>
                                                                            <Typography>
                                                                                {point.postalCode}
                                                                            </Typography>
                                                                        </Cell>
                                                                        <Cell>
                                                                            <MobileHeader>
                                                                                Latitude
                                                                            </MobileHeader>
                                                                            <Typography>
                                                                                {point.latitude}
                                                                            </Typography>
                                                                        </Cell>
                                                                        <Cell>
                                                                            <MobileHeader>
                                                                                Longitude
                                                                            </MobileHeader>
                                                                            <Typography>
                                                                                {point.longitude}
                                                                            </Typography>
                                                                        </Cell>
                                                                        <Cell>
                                                                            <NeutralButton
                                                                                text="Remove"
                                                                                onClick={() =>
                                                                                    this.props.removePoint(
                                                                                        point
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Cell>
                                                                    </Row>
                                                                );
                                                            }
                                                        )}
                                                    </ComposableDataTable>
                                                </div>
                                            </Card>
                                            <ErrorContainer disableGutters>
                                                {showErrors &&
                                                    Object.keys(csvErrors).length > 0 &&
                                                    map(Object.keys(csvErrors), errorId => {
                                                        const errorDetails = csvErrors[errorId];
                                                        const { message } = errorDetails;
                                                        return (
                                                            <Typography color="error" key={errorId}>
                                                                {message}
                                                            </Typography>
                                                        );
                                                    })}
                                            </ErrorContainer>
                                        </div>
                                    )}
                                </React.Fragment>
                            </div>
                        )}
                    </Grid>
                </Grid>
                <div>
                    {serverSaveErrors &&
                        serverSaveErrors.length > 0 &&
                        map(serverSaveErrors, error => (
                            <p
                                key={
                                    'server err: ' + error.field + ' ' + error.message.toLowerCase()
                                }
                                className="ef5-form-field__errors"
                            >
                                {error.field + ' ' + error.message.toLowerCase()}
                            </p>
                        ))}
                    <Grid container justifyContent="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={this.props.draft.source === 'legacy'}
                            onClick={this.props.save}
                        >
                            Save
                        </Button>
                    </Grid>
                </div>
                {this.props.uploadConfirmationModal}
            </BlockLoadGroup>
        );
    }
}

export default Layout;

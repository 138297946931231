import _ from 'lodash';
import c from 'common/constants/flux-events';

export default function creatives(state = {}, action) {
    const creativeId = _.get(action, 'payload.creativeId');

    if (!creativeId) {
        return state;
    }

    return {
        ...state,
        [creativeId]: reducer(state[creativeId], action),
    };
}

export const NAME = 'resources.history.creatives';

const initialState = [];

function reducer(state = initialState, action) {
    const creativeId = _.get(action, 'payload.creativeId');

    switch (action.type) {
        case c.SYSTEM__CREATIVE_HISTORY__FETCH__SUCCESS:
            return _.get(action, 'payload.history', []);

        default:
            return state;
    }
}

import _ from 'lodash';

export default function selector(storeState) {
    const { audienceSegments, totalSegmentCount } = _.get(storeState, 'uploadedDashboard');
    const { isModalOpen } = _.get(storeState, 'audienceEditor');
    const organizationId = _.get(storeState, 'profile.organizationId');

    return {
        audienceSegments,
        totalSegmentCount,
        isModalOpen,
        organizationId,
    };
}

import React, { Fragment } from 'react';
import { Link } from 'react-router';

import {
    Section,
    List,
    Layout,
    SubSection,
    HelpLink,
    ListItem,
    Paragraph,
    BoldText,
} from '../modules/layout.js';
import Spacer from 'widgets-v5/spacer';
import { BodyText, Subheading } from 'widgets-v5/typography';

export default {
    category: 'Reference',
    topic: 'Campaign Setup',
    url: 'reference/campaign-setup',
    published: true,
    Component: class Article extends React.Component {
        constructor(props) {
            super(props);

            this.basic = React.createRef();
            this.categorization = React.createRef();
            this.budgetBilling = React.createRef();
        }

        render() {
            return (
                <Layout
                    title="Campaign Setup"
                    sidebarContent={[
                        {
                            title: 'Basic',
                            link: this.basic,
                        },
                        {
                            title: 'Categorization',
                            link: this.categorization,
                        },
                        {
                            title: 'Budget & Billing',
                            link: this.budgetBilling,
                        },
                    ]}
                >
                    <Spacer type="small" />
                    <Paragraph>Required fields are marked with an asterisk (*).</Paragraph>
                    <Section link={this.basic} title="Basic">
                        <SubSection title="Campaign Name*">
                            <Paragraph>
                                Used for identification. We recommend using a consistent format
                                where possible.
                            </Paragraph>
                            <Paragraph>
                                For example: [Brand] [YY]-[MM] [Campaign Name] (i.e. Unilever 18-10
                                Axe Body Spray Halloween Promotion)
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Notes">
                            Attach free-form notes to this campaign. Notes are searchable from the
                            homepage.
                        </SubSection>
                        <SubSection title="Default Timezone*">
                            <Paragraph>
                                Used as the default timezone for ad setup and campaign reports.
                                Select the nearest major city, e.g. select "Toronto, ON — Canada" to
                                run in Eastern Time. Contact us to change the default timezone
                                option for your organization.
                            </Paragraph>
                            <Paragraph>
                                <BoldText>Note:</BoldText> You will always billed according to
                                Eastern Time (Canada), regardless of the campaign's timezone.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.categorization} title="Categorization">
                        <SubSection title="Advertiser">
                            The Advertiser is the brand whose products or services are being
                            advertised. While some DSPs require an 'Advertiser' (sometimes known as
                            'Brand') to be created above the campaign level, this field is optional
                            on EngageFront and is placed at the campaign level. Assigning an
                            Advertiser to a campaign helps categorize it and makes future campaign
                            lookups and reports easier.
                        </SubSection>
                        <SubSection title="Advertiser Domain*">
                            Enter the domain of the advertiser. If you are unsure of the domain, you
                            can usually extract this from the URL of the landing page. The domain
                            format should be "acme.com" (no "https://" or "www"). This field is
                            submitted to our exchange partners and allows app publishers to block
                            certain advertisers.
                        </SubSection>
                        <SubSection title="Advertiser Category*">
                            <Paragraph>
                                Select all categories relevant to the advertiser and contents of the
                                creative being served. This field is submitted to our exchange
                                partners and allows exchanges and app publishers to block certain
                                types of ads.
                            </Paragraph>
                            <Paragraph>
                                <BoldText>Important:</BoldText> Certain categories of ads, e.g.
                                alcohol ads, have additional restrictions to ensure that they do not
                                run on children's apps. See content restrictions for more details.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.budgetBilling} title="Budget & Billing">
                        <SubSection title="Currency*">
                            Select the currency you want to use for bidding, budgeting, and for
                            reporting spend and revenue throughout this campaign. You will also be
                            billed in this currency. For example, if you choose to use CAD...
                        </SubSection>
                        <List>
                            <ListItem>
                                during setup, you will be budgeting and bidding using CAD
                            </ListItem>
                            <ListItem>
                                during reporting, both spend and revenue will be reported in CAD
                            </ListItem>
                            <ListItem>you will be invoiced in CAD</ListItem>
                        </List>
                        <Paragraph>
                            Contact us to change the default currency option for your organization.
                        </Paragraph>
                        <Paragraph>
                            Note: Currency cannot be changed once the campaign is created.
                        </Paragraph>
                        <SubSection title="Revenue Model*">
                            Select an appropriate revenue model.
                            <List>
                                <ListItem>
                                    <BoldText>CPM / CPC</BoldText> — Revenue is calculated based on
                                    the number of impressions or clicks served, and is completely
                                    detached from the Total Cost of the campaign.
                                </ListItem>
                                <ListItem>
                                    <BoldText>Agency Margin</BoldText> — Expected revenue (Client
                                    Budget) is entered directly into EngageFront. Total Cost is
                                    automatically calculated by first deducting a percentage margin
                                    (Agency Margin).
                                </ListItem>
                                <ListItem>
                                    <BoldText>Total Cost Markup</BoldText> — Revenue is calculated
                                    by multiplying a markup rate to Total Cost.
                                </ListItem>
                                <ListItem>
                                    <BoldText>Disabled</BoldText> — Revenue is not managed on
                                    EngageFront. All revenue-related fields and metrics will be
                                    hidden to simplify your view.
                                </ListItem>
                            </List>
                            <Paragraph>
                                See
                                <HelpLink
                                    url="/help/guides/budget-revenue-model"
                                    text="Budget & Revenue Model"
                                />
                                for details. Contact us to change the default Revenue Model option
                                for your organization.
                            </Paragraph>
                            <Paragraph>
                                Note: Revenue model cannot be changed once the campaign is created.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Client Budget">
                            <Paragraph>
                                Enter the client's budget for this campaign, before deducting your
                                margin.
                            </Paragraph>
                            <Paragraph>
                                This field is only visible and required when Revenue Model is set to
                                Agency Margin.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Agency Margin">
                            <Paragraph>
                                Enter the percentage margin used when deducting from Client Budget
                                to determine Campaign Total Cost.
                            </Paragraph>
                            <Paragraph>
                                This field is only visible and required when Revenue Model is set to
                                Agency Margin.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Limit Campaign Total Cost">
                            Place a limit on Total Cost for this campaign. Total Cost combines all
                            costs associated with this campaign, including media costs, data costs,
                            and third party fees.
                        </SubSection>
                        <SubSection title="Default Third Party Fees">
                            EngageFront can help you track and pace your total spend holistically by
                            tracking CPM fees from third-party vendors. Third-party fees will be
                            deducted from your campiagn's Total Cost as impressions are delivered to
                            make sure you don't go over-budget. Third-party fees are not billed by
                            Pelmorex.
                            <br />
                            <br />
                            Third Party Fees saved in the campaign will be automatically added to
                            new ads. Fees can be customized per ad.
                        </SubSection>
                    </Section>
                </Layout>
            );
        }
    },
};

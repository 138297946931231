import React, { useState, useRef, useEffect } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

const useStyles = makeStyles({
    content: {
        position: 'relative',
    },
    doubleLineClamp: props => ({
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': props.lineClamp,
        '-webkit-box-orient': 'vertical',
        visibility: 'visible',
    }),
    viewMore: {
        position: 'absolute',
        bottom: 0,
        color: '#4384bf',
        background: 'none',
        textDecoration: 'underline',
        border: 'none',
        cursor: 'pointer',
    },
    viewMoreLeft: {
        left: '-5px',
        bottom: '-15px',
    },
    viewMoreRight: {
        right: 0,
    },
});

export const ViewMore = ({ children, lineClamp = 2 }) => {
    const classes = useStyles({ lineClamp });
    const [open, setOpen] = useState(false);
    const truncateElRef = useRef();
    const [isMultiline, setIsMultiline] = useState(true);

    useEffect(() => {
        // Here we check if the element is showing an ellipsis. If it is we will then show
        // the 'View More' button to expand the section and show more info.
        if (truncateElRef.current) {
            setIsMultiline(truncateElRef.current.scrollHeight > truncateElRef.current.clientHeight);
        }
    }, []);

    return (
        <div className={classes.content}>
            {isMultiline && (
                <button
                    className={`${classes.viewMore} ${
                        !open ? classes.viewMoreRight : classes.viewMoreLeft
                    }`}
                    onClick={() => setOpen(!open)}
                >
                    {!open ? 'View More' : 'View Less'}
                </button>
            )}
            {!open && (
                <Box ref={truncateElRef} className={classes.doubleLineClamp} width="88%">
                    {children}
                </Box>
            )}
            {isMultiline && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            )}
        </div>
    );
};

export default ViewMore;

import FileSaver from 'utils/file-saver';
import _ from 'lodash';
import http from 'utils/http';

import PoiActions from 'states/resources/pois/actions';
import GeoCategories from 'states/resources/geo-categories/actions';
import flags from 'containers/flags/service';

import { NAME } from './reducer';

const actions = {
    init(filters) {
        return (dispatch, getState) => {
            dispatch({
                type: 'POI_DASHBOARD__INIT__START',
                payload: {
                    filters,
                },
            });
            dispatch(GeoCategories.getCategories({ allowCategoriesWithoutLayers: true }));
            return dispatch(actions.fetchPageData(filters)).then(
                () => {
                    dispatch({
                        type: 'POI_DASHBOARD__INIT__END',
                        payload: {},
                    });
                },
                error => {
                    dispatch({
                        type: 'POI_DASHBOARD__INIT__ERROR',
                        payload: {},
                        error,
                    });

                    throw error;
                }
            );
        };
    },
    fetchPageData(filters) {
        return (dispatch, getState) => {
            const limit = _.get(getState(), `${NAME}.itemsPerPage`, 25);

            const query = {
                limit,
                page: filters.page || 1,
                search: filters.search,
            };

            dispatch({
                type: 'POI_DASHBOARD__FETCH_PAGE_DATA__START',
                payload: {},
            });

            return dispatch(PoiActions.getAll(query)).then(
                response => {
                    dispatch({
                        type: 'POI_DASHBOARD__FETCH_PAGE_DATA__END',
                        payload: {
                            pois: response.payload,
                            maxResults: response.meta.max_results,
                            page: query.page,
                        },
                    });
                },
                error => {
                    dispatch({
                        type: 'POI_DASHBOARD__FETCH_PAGE_DATA__START',
                        payload: {},
                        error,
                    });

                    throw error;
                }
            );
        };
    },
    downloadTemplate() {
        return (dispatch, getState) => {
            dispatch({
                type: 'POI_DASHBOARD__DOWNLOAD__START',
                payload: { audienceIdBeingDownloaded: id },
            });

            const audience = _.get(getState(), `resources.pois.${id}.attr`);

            return http()
                .get(`audience-download/${id}`)
                .then(
                    response => {
                        FileSaver.saveAs(
                            new Blob([response], { type: `text/plain;charset=utf-8` }),
                            `template.csv`
                        );

                        dispatch({
                            type: 'POI_DASHBOARD__DOWNLOAD__END',
                            payload: {},
                        });
                    },
                    err => {
                        console.error(`DOWNLOAD audience error`, err);

                        dispatch({
                            type: 'POI_DASHBOARD__DOWNLOAD__END',
                            payload: {},
                        });

                        throw err;
                    }
                );
        };
    },
};
export default actions;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import AdvertisersList from './modules/advertiser-list';
import ConversionsList from './modules/conversion-list';
import ConversionForm from './modules/conversion-form';

import actions from './actions';
import selector from './selector';

let ConversionsPage = props => {
    const { init } = props;

    useEffect(() => {
        init();
    }, []);

    return (
        <Box m={3}>
            <Box mb={4}>
                <Typography variant="h4">Advertiser Pixels</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <AdvertisersList />
                </Grid>
                <Grid item xs={9}>
                    <ConversionsList />
                </Grid>
            </Grid>
            <ConversionForm />
        </Box>
    );
};

function mapDispatch(dispatch) {
    return {
        init: () => dispatch(actions.init()),
    };
}

ConversionsPage = connect(
    selector,
    mapDispatch
)(ConversionsPage);

export default ConversionsPage;

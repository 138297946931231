import _ from 'lodash';
/*
    // Purpose: Only handle the most recent promise.
    //
    // Usage

    const flatMapLatest = makeFlatMapLatest();

    flatMapLatest(SOME_ASYNC_PROMISE, (err, result) => {
        // ...
    });
*/
function flatMapLatest() {
    let stack = [];

    return (promise, callback) => {
        stack.push(promise);

        promise.then(
            res => {
                if (_.last(stack) === promise) {
                    callback(null, res);
                    stack = [];
                }
            },
            err => {
                if (_.last(stack) === promise) {
                    callback(err, null);
                    stack = [];
                }
            }
        );
    };
}

export default flatMapLatest;

import each from 'lodash/each';
import get from 'lodash/get';
import includes from 'lodash/includes';
import moment from 'moment';
/** import third-party libraries in the section above, keep the ascending order */

import { can } from 'services/can';
import { EXCHANGE_LABEL_MAPPING } from 'forms/ad-form/constants';
import { formatNumber_wholeFixed, formatNumber_whole } from 'utils/formatting';
import PLATFORMS from 'common/constants/platforms';
import {
    RevenueModelMapping,
    CampaignTypeMapping,
} from 'states/resources/campaigns/business-logic';
import { PermissionsMapping } from 'common/constants/role-intents';
/** import classes and others in the section above, keep the ascending order */

export const CreativeRotationMethodMapping = {
    Single: 'single',
    Even: 'even',
    Weighted: 'weighted',
    Scheduled: 'scheduled',
    OptimizedForClicks: 'optimizedForClicks',
    OptimizedForConversions: 'optimizedForConversions',
    OptimizedForVideoCompletion: 'optimizedForVideoCompletion',
    Weather: 'weather',
};

export const BiddingStrategyMode = {
    SpendFullBudget: 'spending_full_budget',
    Performance: 'performance',
};

export const DeviceOSMapping = {
    Windows: 'windows',
    Mac: 'mac',
    IOS: 'iOS',
    Android: 'Android',
    Others: 'others',
    Rim: 'RIM',
    RimOS: 'RIM OS',
    BlackBerry: 'BlackBerry',
    AndroidTV: 'androidtv',
    AppleTV: 'appletv',
    Chromecast: 'chromecast',
    Fire: 'fire',
    LG: 'lg',
    Roku: 'roku',
    Samsung: 'samsung',
    CTVOthers: 'ctv_others',
    DOOH: 'dooh',
};

export function isCpcAd(ad) {
    return ad.ef_billing_terms === 'CPC';
}

export function isCpmCpcAd(ad) {
    return includes(['CPM', 'CPC'], ad.ef_billing_terms);
}
export function isBonusAd(ad, campaign) {
    return !ad.billing_enabled && campaign.billing_enabled;
}

export function getTotalTarget(ad) {
    switch (ad.primary_pacing) {
        case 'impressions':
            return ad.max_total_impressions;
        case 'clicks':
            return ad.max_total_clicks;
        case 'billings':
            return ad.max_total_billings;
        case 'spend':
            return ad.max_total_spend_local;
        default:
            return 0;
    }
}
export function getTotalBillings(ad) {
    const billableVolume = Math.max(0, ad.billable_volume);

    switch (ad.ef_billing_terms) {
        case 'CPM':
            return (ad.billing_rate * billableVolume) / 1000;
        case 'CPC':
            return ad.billing_rate * billableVolume;
        case 'billable_media_cost_markup':
            return (1 + ad.billing_rate) * billableVolume;
        case 'billable_media_cost_margin':
            return billableVolume;
    }
}

export function getTotalMediaCost(ad) {
    if (ad.ef_billing_terms !== 'billable_media_cost_margin') {
        return -1;
    }

    const billableVolume = ad.billable_volume || 0;
    const nonBillableVolume = ad.non_billable_volume || 0;
    const billingRate = ad.billing_rate || 0;

    return billableVolume * (1 - billingRate) + nonBillableVolume;
}

export function getBillableVolume(ad) {
    switch (ad.ef_billing_terms) {
        case 'CPM':
        case 'CPC':
        case 'billable_media_cost_markup':
            return ad.billable_volume;
        case 'billable_media_cost_margin':
            return (1 - ad.billing_rate) * ad.billable_volume;
    }
}

// This function is necessary to avoid conflicts with Ad.status
// Ad.status is derived from Campaign and Creatives
export function isAdEnded(ad) {
    if (ad) {
        const now = moment.utc();
        return now.isAfter(moment.utc(ad.end));
    } else {
        return false;
    }
}

// This function is necessary to avoid conflicts with Ad.status
// Ad.status is derived from Campaign and Creatives
export function isAdPaused(ad) {
    if (ad) {
        return ad.paused;
    } else {
        return false;
    }
}

export function formatBillingTerm(efBillingTerm) {
    const MAPPING = {
        CPM: 'CPM',
        CPC: 'CPC',
        billable_media_cost_markup: 'Markup',
        billable_media_cost_margin: 'Margin',
    };

    return MAPPING[efBillingTerm];
}
export function getAdDateRules({ isStale, isDelivering }) {
    if (isDelivering && isStale) {
        return {
            start: 'locked',
            end: 'locked',
            isDelivering,
            isStale,
        };
    }

    if (isDelivering && !isStale) {
        return {
            start: 'locked',
            end: 'future',
            isDelivering,
            isStale,
        };
    }

    if (!isDelivering) {
        return {
            start: 'any',
            end: 'any',
            isDelivering,
            isStale,
        };
    }

    // This case should not happen. Freak out!
    throw new Error(`Could not determine date rules`);
}

export function updateFrequencyCapOverall(draft, changes) {
    return {
        ...draft.fcaps,
        ...changes,
        start: Date.now(),
    };
}

export function updateFrequencyCap(draft, fieldName, newValue) {
    return {
        ...draft.fcaps,
        [fieldName]: newValue,
        start: Date.now(),
    };
}

export function shouldShowFrequencyCapWarningOnChange(adId, draft) {
    if (!adId) {
        return false;
    }

    if (draft.fcaps.imp === null) {
        return false;
    }

    return true;
}

export function updateFrequencyCapWarning(state) {
    const frequencyCapMode = calculateFrequencyCapMode(state.draft);

    if (frequencyCapMode === 'unlimited') {
        return false;
    }

    return true;
}

export function calculateFrequencyCapMode(draft) {
    if (draft.fcaps.imp === null) {
        return 'unlimited';
    }

    return 'limited';
}

export function getDaysRunning(ad) {
    if (!(ad.start && ad.end && ad.weekparts)) {
        return 0;
    }

    const startDateMoment = moment(ad.start);
    const endDateMoment = moment(ad.end);
    let daysRunning = 0;

    // We change weekparts to match how `moment` starts on Sunday vs our starting on Monday
    let shiftedWeekparts = ad.weekparts.slice();
    shiftedWeekparts.unshift(shiftedWeekparts.pop());

    startDateMoment.startOf('day');
    endDateMoment.startOf('day');

    while (startDateMoment.isSameOrBefore(endDateMoment)) {
        if (shiftedWeekparts[startDateMoment.day()]) {
            daysRunning++;
        }

        startDateMoment.add(1, 'days');
    }

    daysRunning = daysRunning || 1;

    return daysRunning;
}

export function getMaxCpmRate(ad) {
    return ad.max_cpm_rate_local;
}

export function calculateBillableCost({ mediaCost, firstPartyFees }) {
    return mediaCost + firstPartyFees;
}

export function calculateMediaCost({ maxCpmRate, thirdPartyFees, firstPartyFees }) {
    return Math.max(maxCpmRate - thirdPartyFees - firstPartyFees, 0);
}

export function calculatePlatformTechFee({ mediaCost, techFee }) {
    return Math.max(mediaCost * (techFee / (1 + techFee)), 0);
}

export function calculateBidPrice({ mediaCost, platformTechFee }) {
    return Math.max(mediaCost - platformTechFee, 0);
}

export function calculateBidPriceFromAd(ad, techFee) {
    const mediaCost = calculateMediaCost({
        maxCpmRate: ad.max_cpm_rate_local,
        thirdPartyFees: calculateThirdPartyFees(ad),
        firstPartyFees: calculateFirstPartyDataFee(ad),
    });

    const platformTechFee = calculatePlatformTechFee({ mediaCost, techFee });

    return calculateBidPrice({ mediaCost, platformTechFee });
}

export function calculateAudienceFee({ includedAudiences, excludedAudiences, audienceRates }) {
    const rateMapping = {};
    each(audienceRates, rate => (rateMapping[rate.name] = rate.fee));

    const maxFee = _(includedAudiences)
        .concat(excludedAudiences)
        .filter(item => item)
        .map(item => {
            if (rateMapping[item.source_type] === undefined) {
                throw new Error(`Audience Rate not found for ${item.source_type}`);
            }

            return rateMapping[item.source_type];
        })
        .max();

    return maxFee || 0;
}

export function calculateFirstPartyDataFee(ad) {
    const ftaFee = ad.fta_fee || 0;
    const audienceFee = ad.audience_fee || 0;

    return ftaFee + audienceFee;
}

export function isTacticsEnabled(tacticsEnabled) {
    if (tacticsEnabled === false) {
        return false;
    }

    if (tacticsEnabled === true) {
        return true;
    }

    return false;
}

export function getAudienceFeeFromTactics(tacticsGenerators) {
    let audienceFee = 0;

    each(tacticsGenerators, tactic => {
        each(tactic.targeting, target => {
            if (target.module === 'audience_segments') {
                audienceFee = Math.max(get(target, 'draft.audience_fee', 0), audienceFee);
            }
        });
    });

    return audienceFee;
}

export function hasAnyPois(ad) {
    if (!(ad && ad.geo_targeting_settings)) {
        return false;
    }

    return (
        []
            .concat(
                ad.geo_targeting_settings.categories,
                ad.geo_targeting_settings.category_layers,
                ad.geo_targeting_settings.custom_layers
            )
            .filter(x => x).length > 0
    );
}

export function hasAnyAudiences(ad) {
    if (!ad) {
        return false;
    }

    return [].concat(ad.audiences, ad.audience_exclude).filter(x => x).length > 0;
}

export function hasAnyPattisonBillboards(ad) {
    if (!ad) {
        return false;
    }

    return ad.pattisonBillboards.length > 0;
}

export function calculateThirdPartyFees(ad) {
    let total = 0;

    each(ad.thirdPartyFees, fee => {
        total += fee.fee;
    });

    return total;
}

export function calculateTacTicsThirdPartyFees({ includedAudiences, excludedAudiences }) {
    let total = 0;

    each(includedAudiences, item => {
        if (item) {
            total += item.third_party_fees;
        }
    });

    each(excludedAudiences, item => {
        if (item) {
            total += item.third_party_fees;
        }
    });

    return total;
}

export function getRevenueModelToAdBillingTermMapping() {
    return {
        disabled: ['CPM', 'CPC', 'billable_media_cost_markup'],
        cpmcpc: ['CPM', 'CPC'],
        cpm: ['CPM'],
        agencyMargin: ['billable_media_cost_margin'],
        totalSpendMarkup: ['billable_media_cost_markup'],
    };
}

export function getEfBillingTermsOptions(campaignRevenueModel) {
    if (campaignRevenueModel === RevenueModelMapping.CPMCPC) {
        return [{ label: 'CPM', value: 'CPM' }, { label: 'CPC', value: 'CPC' }];
    }

    if (campaignRevenueModel === RevenueModelMapping.CPM) {
        return [{ label: 'CPM', value: 'CPM' }];
    }

    return [{ label: 'CPM', value: 'CPM' }];
}

export const AD_BILLING_TERMS = [
    'CPM',
    'CPC',
    'billable_media_cost_markup',
    'billable_media_cost_margin',
];

export function checkIsNewScheduledWeightedRotationMode(rotationRules) {
    return !!get(rotationRules, 'scheduled.0.id');
}

export function isAdUsingDisplayCreative(ad, creatives) {
    if (!ad) {
        return false;
    }

    const creativeMapping = {};
    each(creatives, creative => (creativeMapping[creative.id] = creative));

    const allCreativeIds = [...ad.creative];

    each(ad.rotation_rules.scheduled, schedule => {
        each(schedule.weighted, weightConfig => {
            allCreativeIds.push(weightConfig.markup_id);
        });
    });

    each(ad.rotation_rules.weather, weatherCreative => {
        allCreativeIds.push(weatherCreative.markupId);
    });

    let targetedCreativeFormat = '';
    if (allCreativeIds[0] && creativeMapping[allCreativeIds[0]]) {
        targetedCreativeFormat = creativeMapping[allCreativeIds[0]].format;
    }

    return includes(['standard', 'mraid', 'custom_html', 'native'], targetedCreativeFormat);
}

export function formatBillableVolume(value, billingTerm) {
    switch (billingTerm) {
        case 'billable_media_cost_markup':
        case 'billable_media_cost_margin':
            return formatNumber_wholeFixed(value);
        default:
            return formatNumber_whole(value);
    }
}

export function canArchiveAd(ad) {
    if (ad.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canEditAd(ad) {
    if (ad.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function isArchivedAd(ad) {
    return ad.isArchived;
}

export function canDuplicateAd(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function getGoalTypeOptions(campaign) {
    const goalTypeOptions = [
        {
            label: 'Impressions',
            value: 'CPM',
        },
        {
            label: 'Clicks',
            value: 'CPC',
        },
        {
            label: 'Total Cost',
            value: 'billable_media_cost_markup',
        },
    ];

    if (
        campaign.revenueModel === RevenueModelMapping.Disabled &&
        (campaign.type === CampaignTypeMapping.CTV || campaign.type === CampaignTypeMapping.DOOH)
    ) {
        return goalTypeOptions.filter(i => i.value !== 'CPC');
    }

    return goalTypeOptions;
}

/**
 * Based on campaign properties, define the options available for the exchange field
 * @param {*} campaign params
 * @returns Array of {label: string, value: string}
 */
export function getExchangeOptions({ type }) {
    const UNAVAILABLE_EXCHANGES = {
        [CampaignTypeMapping.CTV]: [
            'adx',
            'aerserv',
            'mobfox',
            'mopub',
            'nexage',
            'omax',
            'pubmatic',
            'rubicon',
            'vistar',
        ],
        [CampaignTypeMapping.Standard]: ['vistar'],
        [CampaignTypeMapping.DOOH]: [
            'adx',
            'aerserv',
            'mobfox',
            'mopub',
            'nexage',
            'omax',
            'pubmatic',
            'rubicon',
            'index',
            'smaato',
            'sharethrough',
        ],
    };

    const EXCHANGE_MAPPING = Object.keys(EXCHANGE_LABEL_MAPPING)
        .map(value => ({
            label: EXCHANGE_LABEL_MAPPING[value],
            value,
        }))
        .sort((a, b) => a.label - b.label);

    return EXCHANGE_MAPPING.filter(m => !includes(UNAVAILABLE_EXCHANGES[type], m.value));
}

export const PlatformMapping = {
    CTV: 'ctv',
    INAPP: 'inapp',
    MWEB: 'mweb',
    Desktop: 'desktop',
    DOOH: 'dooh',
};

/**
 * Based on campaign type property, define the options available for the platforms
 * @param {*} type params
 * @returns Array of {label: string, value: string} || [String]
 */
export function getAllowedPlatformOptions(type) {
    const PlatformMappingByType = {
        [CampaignTypeMapping.DOOH]: [PlatformMapping.DOOH],
        [CampaignTypeMapping.CTV]: [PlatformMapping.CTV],
        [CampaignTypeMapping.Standard]: [
            PlatformMapping.INAPP,
            PlatformMapping.MWEB,
            PlatformMapping.Desktop,
        ],
    };

    switch (type) {
        case CampaignTypeMapping.DOOH:
            return PlatformMappingByType[CampaignTypeMapping.DOOH];
        case CampaignTypeMapping.CTV:
            return PlatformMappingByType[CampaignTypeMapping.CTV];
        case CampaignTypeMapping.Standard:
            return PlatformMappingByType[CampaignTypeMapping.Standard];
        default:
            return PlatformMappingByType;
    }
}

/**
 * Based on campaign type property, define the options available for the platform field
 * @param {*} type params
 * @returns Array of {label: string, value: string}
 */
export function getPlatformOptions(type) {
    const ALLOWED_PLATFORMS = getAllowedPlatformOptions();

    return PLATFORMS.filter(platform => includes(ALLOWED_PLATFORMS[type], platform.value));
}

/**
 * Handles the change event for DOOH venue type selection.
 * This function sorts the given field values in ascending order based on the parent-child relationship
 * (e.g., "1-101", "2-201", "11-1001", "1-106", "10-1101") and then extracts only the child values.
 * The sorted and extracted child values are then used to update the draft state.
 * @param {string} fieldName - The name of the field being updated.
 * @param {string[]} fieldValue - An array of strings representing the parent-child values to be sorted and processed.
 * @returns {Object} - An object containing the updated field values.
 */
export const formatSelectedDOOHVenueTypes = fieldValue => {
    const doohVenueValues = fieldValue
        .sort((a, b) => {
            const aParts = a.split('-').map(Number);
            const bParts = b.split('-').map(Number);
            return aParts[0] - bParts[0] || (aParts[1] || 0) - (bParts[1] || 0);
        })
        .map(venue => parseInt(venue.split('-').pop()));

    return doohVenueValues;
};

import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import selector from './selector';
import { fetchCampaignData, unInitCampaign } from './actions';
import CenterLayout from 'packages/widgets-v5/center-layout';
import { InlineBlockLoadGroup } from 'packages/widgets-v5/load-group';

export default ProtectedComponent => {
    const CampaignAccessCheck = createReactClass({
        displayName: 'CampaignAccessCheck',

        statics: {
            refresh(dispatch, nextState) {
                dispatch(fetchCampaignData(nextState.params.campaignId));
            },
        },

        componentDidMount() {
            this.init();
        },

        componentWillUnmount() {
            this.props.dispatch(unInitCampaign());
        },

        init() {
            const { dispatch, location, params, routes } = this.props;
            const nextState = { location, params, routes };

            CampaignAccessCheck.refresh(dispatch, nextState, browserHistory.push);
        },

        render() {
            const { dispatch, location, params, routes, history, workspace, router } = this.props;
            const { loading, accessGranted, ownerName } = this.props;
            const { campaignId } = this.props.params;

            const topLevelClassNames = 'ef5-campaign-access ef5-text-size_headline';
            const boldedClassNames = 'ef5-campaign-access__bolded';

            if (accessGranted) {
                return (
                    <ProtectedComponent
                        dispatch={dispatch}
                        location={location}
                        params={params}
                        routes={routes}
                        router={router}
                        workspace={workspace}
                        history={history}
                    />
                );
            }

            let message;
            if (loading) {
                message = <div className={topLevelClassNames}>&#32;</div>;
            } else if (!ownerName) {
                // In this case, the requested campaign doesn't exist, or belongs to a non-descendant organization
                message = (
                    <div className={topLevelClassNames}>
                        You don't have access to campaign #
                        <span className={boldedClassNames}>{campaignId}</span>, or it doesn't exist.
                    </div>
                );
            } else {
                message = (
                    <div className={topLevelClassNames}>
                        Your organization doesn't have access to campaign #
                        <span className={boldedClassNames}>{campaignId}</span>. <br />
                        Try impersonating a user from organization that owns this campaign:{' '}
                        <span className={boldedClassNames}>{this.props.ownerName}</span>
                    </div>
                );
            }

            return (
                <CenterLayout>
                    <InlineBlockLoadGroup size="large" isLoading={this.props.loading}>
                        {message}
                    </InlineBlockLoadGroup>
                </CenterLayout>
            );
        },
    });

    return connect(selector)(CampaignAccessCheck);
};

import _ from 'lodash';

// Only returns a value if there is a querystring and it contains the requested field. Otherwise returns undefined
export function naivelyFetchQueryStringField(field) {
    const queryString = window.location.search.substring(1);

    if (queryString.length === 0) {
        return;
    }

    const rawVars = queryString.split('&');
    const fieldValuePairs = _.map(rawVars, variable => variable.split('='));

    const matchingPair = _.find(fieldValuePairs, v => {
        return decodeURIComponent(v[0]) === field;
    });

    if (!matchingPair) {
        return;
    }

    return decodeURIComponent(matchingPair[1]);
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Toggle from 'widgets-v5/toggle';

class StatusToggle extends React.Component {
    static propTypes = {
        togglePosition: PropTypes.string,
        onClick: PropTypes.func,
        muted: PropTypes.bool,
        status: PropTypes.oneOf([
            'live',
            'paused',
            'ended',
            'scheduled',
            'pending',
            'incomplete',
            'campaignIsOff',
        ]).isRequired,
    };

    static defaultProps = {
        muted: false,
    };

    render() {
        const isEnded = this.props.status === 'ended';

        return (
            <div
                className={classnames('ef3-statusToggle', this.props.className, {
                    'is-muted': this.props.muted || isEnded,
                })}
            >
                <Toggle
                    disabled={this.props.disabled}
                    status={this.props.status}
                    position={this.props.togglePosition}
                    onClick={this.props.onClick}
                    textLeft="off"
                    textRight="on"
                    width={50}
                    height={22}
                />
            </div>
        );
    }
}

export default StatusToggle;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import actions from './actions';
import selector from './selector';

import Layout from './layout';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.getAudiences(keywords));
};

const AgeDashboard = ({ dispatch, audienceSegments, totalSegmentCount, ...props }) => {
    useEffect(() => {
        dispatch(actions.init());
    }, [1]);

    const onChange = value => {
        handleSearch(value, dispatch);
    };

    return (
        <Layout
            audienceSegments={audienceSegments}
            onSearch={onChange}
            totalSegmentCount={totalSegmentCount}
            activeAudience={props.params.audienceId}
            organizationId={props.organizationId}
        />
    );
};

export default connect(selector)(AgeDashboard);

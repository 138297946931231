import flags from 'containers/flags/service';

export default function draftToPayload(draft, isEditing) {
    const {
        retargeting_type,
        event_name,
        description,
        file_name,
        source_id,
        advertiser_id,
        lookback,
        ...rest
    } = draft;
    if (draft.mode === 'upload') {
        const payload = {
            ...rest,
            description,
            file_name,
        };

        if (draft.supports) {
            payload.supports = JSON.stringify(draft.supports);
        }

        return payload;
    }

    let event_name_list = draft.event_name_list;
    return {
        ...rest,
        retargeting_type,
        event_name,
        source_id,
        advertiser_id,
        lookback,
        event_name_list,
    };
}

import moment from 'moment';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useReportJobs } from '../hook';
import { REPORT_JOB_STATUSES } from '../constants';
const { PENDING, PROCESSING, COMPLETED, CANCELLED } = REPORT_JOB_STATUSES;

const useStyles = makeStyles(() => ({
    list: {
        maxHeight: 200,
        overflow: 'scroll',
    },
}));

function ReportJobList(props) {
    const { reportJobs } = props;
    const { openEditor, downloadReport, cancelReport, retryReport } = useReportJobs();

    const [reportJobsWithFromNow, setReportJobsWithFromNow] = useState(reportJobs);

    useEffect(() => {
        const callback = () => {
            setReportJobsWithFromNow(
                _.map(reportJobs, reportJob => ({
                    ...reportJob,
                    fromNow: moment(reportJob._created).fromNow(),
                }))
            );
        };

        callback();
        const interval = setInterval(callback, 15000);

        return () => clearInterval(interval);
    }, [reportJobs]);

    const classes = useStyles();

    return (
        <List dense className={classes.list}>
            {_.map(reportJobsWithFromNow, reportJob => (
                <ListItem key={reportJob.id} button onClick={() => openEditor({ reportJob })}>
                    <ListItemText
                        primary={reportJob.reportParameters.name || 'Untitled Report'}
                        secondary={
                            reportJob.status === 'processing'
                                ? `processing; started ${moment(reportJob._created).fromNow()}`
                                : `${reportJob.status} ${moment(reportJob._created).fromNow()}`
                        }
                    />
                    <ListItemSecondaryAction>
                        <Grid container alignItems="center" spacing={2}>
                            {_.includes([PENDING, PROCESSING], reportJob.status) && (
                                <Grid item>
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        onClick={() => cancelReport(reportJob)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            )}
                            {_.includes([PENDING, PROCESSING], reportJob.status) && (
                                <Grid item>
                                    <CircularProgress size={20} />
                                </Grid>
                            )}
                            {reportJob.status === COMPLETED && (
                                <Grid item>
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        onClick={() => downloadReport(reportJob)}
                                    >
                                        <GetAppIcon />
                                    </IconButton>
                                </Grid>
                            )}
                            {reportJob.status === CANCELLED && (
                                <Grid item>
                                    <IconButton
                                        edge="end"
                                        size="small"
                                        onClick={() => retryReport(reportJob)}
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
}

export default ReportJobList;

import React from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router';
import { connect } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { getEnv } from 'services/environment';
import selector from './selector';

export function getAvailableCategoriesV2() {
    const availableCategories = [
        {
            category: 'All',
            icon: 'fa fa-users',
            link: '/audiences/all',
        },
        {
            category: 'Pelmorex Audiences',
            icon: 'fa fa-map-marker',
            link: '/audiences/flex-brand',
            subcategories: [
                {
                    title: 'Precise Geolocation',
                    link: '/audiences/brand',
                    source: ['pds_brand', 'pds_standard', 'pds_behaviour'],
                    img: '/images/audience-segments/audience-segments-precise.png',
                },
                {
                    title: 'Flex Geolocation',
                    link: '/audiences/flex-brand',
                    source: 'flex_standard_brand',
                    img: '/images/audience-segments/audience-segments-flex-geo.png',
                },
                {
                    title: 'Online Behavioural',
                    link: '/audiences/intent',
                    source: 'pds_intent',
                    img: '/images/audience-segments/audience-segments-placeholder.png',
                },
                {
                    title: 'CanaCode',
                    link: '/audiences/cana-code',
                    source: 'pds_manifold',
                    img: '/images/audience-segments/audience-segments-canacode.png',
                },
                {
                    title: 'Environics',
                    link: '/audiences/environics',
                    source: 'pds_environics',
                    img: '/images/audience-segments/audience-segments-environics.png',
                },
                {
                    title: 'Mastercard',
                    link: '/audiences/mastercard',
                    source: 'pds_mastercard',
                    img: '/images/audience-segments/audience-segments-mastercard.png',
                },
                {
                    title: 'Declared Age',
                    link: '/audiences/age',
                    source: 'pds_age',
                    img: '/images/audience-segments/audience-segments-declared-age.png',
                },
            ],
        },
        {
            category: 'Custom Audiences',
            icon: 'fa fa-pencil',
            link: '/audiences/pds-custom',
            subcategories: [
                {
                    title: 'Pelmorex Data',
                    link: '/audiences/pds-custom',
                    source: 'pds_custom',
                    img: '/images/audience-segments/audience-segments-custom-pelmorex.png',
                },
                {
                    title: 'Flex Geolocation',
                    link: '/audiences/flex-custom',
                    source: 'flex_custom_brand',
                    img: '/images/audience-segments/audience-segments-custom-precise.png',
                },
                {
                    title: 'Retargeting',
                    link: '/audiences/retargeting',
                    source: ['ad', 'campaign'],
                    img: '/images/audience-segments/audience-segments-retargeting.png',
                },
                {
                    title: 'Uploaded',
                    link: '/audiences/uploaded',
                    source: 'upload',
                    img: '/images/audience-segments/audience-segments-uploaded.png',
                },
            ],
        },
        {
            category: 'DMP integrations',
            icon: 'fa fa-connectdevelop',
            link: '/audiences/adobe',
            subcategories: [
                {
                    title: 'Adobe',
                    link: '/audiences/adobe',
                    source: 'adobe',
                    img: '/images/audience-segments/audience-segments-adobe.png',
                },
            ],
        },
    ];

    return availableCategories;
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        background: 'none',
        overflow: 'auto',
    },
    list: {
        minWidth: 273,
    },
    main: {
        padding: '0 16px',
        color: 'black',
        textTransform: 'uppercase',
    },
    mainCat: {
        '&$mainCatSelected': {
            backgroundColor: 'transparent',
            '& $avatar': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
            },
        },
    },
    mainCatSelected: {},
    nested: {
        paddingLeft: theme.spacing(9.5),
        paddingTop: 0,
        paddingBottom: 0,
        color: 'black',
        minHeight: 'auto',
    },
    avatar: {
        margin: 10,
        textAlign: 'center',
    },
    icon: {
        overflow: 'unset',
    },
    nestedRoot: {
        '&$nestedSelected': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
        },
    },
    nestedSelected: {},
    segmentBuilderLink: {
        'padding-left': '76px',
    },
}));

const SideBarContainer = ({ restrictedSegments, ...props }) => {
    let filteredCategories;

    filteredCategories = getAvailableCategoriesV2();

    filteredCategories = _.map(filteredCategories, category => {
        return {
            ...category,
            subcategories: _.filter(
                category.subcategories,
                subCategory => !_.includes(restrictedSegments, subCategory.source)
            ),
        };
    });

    filteredCategories = _.filter(filteredCategories, category => {
        if (category.link === '/audiences/all') {
            return true;
        }
        return category.subcategories.length > 0;
    });

    filteredCategories = _.map(filteredCategories, category => {
        const firstSubCategory = _.head(category.subcategories);
        return category.link === '/audiences/all'
            ? category
            : { ...category, link: firstSubCategory.link };
    });

    return <Sidebar {...props} availableCategories={filteredCategories} />;
};

function Sidebar(props) {
    const { location, availableCategories } = props;
    const classes = useStyles();

    const orgName = window.encodeURIComponent(props.userOrganizationName);

    let segmentBuilderUrl;

    if (getEnv() === 'production') {
        segmentBuilderUrl = `http://segment.data.pelmorex.com/?eftoken=789&orgId=${
            props.organizationId
        }&orgName=${orgName}`;
    } else {
        segmentBuilderUrl = `http://segment-dev.data.pelmorex.com/?eftoken=789&orgId=${
            props.organizationId
        }&orgName=${orgName}`;
    }

    const showAudienceBuilderButton = false;

    return (
        <Paper className={classes.root}>
            <List className={classes.list}>
                {_.map(availableCategories, ({ category, icon, link, subcategories }) => (
                    <React.Fragment key={category}>
                        <Link
                            component={RouterLink}
                            to={link}
                            underline="none"
                            className={classes.root}
                        >
                            <ListItem
                                selected={
                                    !!_.find(subcategories, { link: location.pathname }) ||
                                    location.pathname === link
                                }
                                className={classes.main}
                                classes={{
                                    root: classes.mainCat,
                                    selected: classes.mainCatSelected,
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <Icon
                                            fontSize="small"
                                            className={icon}
                                            classes={{ root: classes.icon }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={category} />
                            </ListItem>
                        </Link>
                        {_.map(subcategories, ({ title, link }) => (
                            <Link
                                key={title}
                                color="inherit"
                                component={RouterLink}
                                to={link}
                                underline="none"
                            >
                                <ListItem
                                    selected={location.pathname && location.pathname === link}
                                    className={classes.nested}
                                    classes={{
                                        root: classes.nestedRoot,
                                        selected: classes.nestedSelected,
                                    }}
                                >
                                    <ListItemText primary={title} />
                                </ListItem>
                            </Link>
                        ))}
                    </React.Fragment>
                ))}
                {showAudienceBuilderButton && (
                    <ListItem className={classes.segmentBuilderLink}>
                        <Button
                            color="primary"
                            variant="contained"
                            href={segmentBuilderUrl}
                            target="_blank"
                        >
                            Segment Builder
                        </Button>
                    </ListItem>
                )}
            </List>
        </Paper>
    );
}

export default connect(selector)(withRouter(SideBarContainer));

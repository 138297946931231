import flags from 'containers/flags/service';

export function draftToPayloadPOIv2(draft) {
    const {
        points,
        layerMetadata: {
            name,
            radius,
            category_code,
            status,
            organization,
            time_constraint,
            manual_activation_until,
            created_date,
        },
        audience_type,
        cleanlist_entries,
        source,
    } = draft;

    let trueTimeConstraint;

    if (time_constraint.date_range[0].start === null) {
        trueTimeConstraint = null;
    } else {
        trueTimeConstraint = time_constraint;
    }

    return {
        name,
        radius,
        time_constraint: trueTimeConstraint,
        category_code,
        status,
        manual_activation_until,
        organization,
        created_date,
        audience_type,
        source,
        points: source === 'uploaded' ? points : null,
        cleanlist_entries: source === 'cleanlist' ? cleanlist_entries : null,
    };
}

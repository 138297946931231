import { DeviceOSMapping } from "states/resources/ads/business-logic";

export const payload_formData = {
    // --- Name ---//
    ad_preset_name: '',
    // --- Budget & Optimizations ---//
    max_user_frequency: 4,
    max_cpm_rate_local: 0,

    // --- Geo ---//

    geotargets: [],
    geoboxes: [],
    geo_targeting_settings: {
        categories: [],
        custom_layers: [],
        category_layers: [],
        radius: 500,
        retarget: true,
        target: true,
        days_to_retarget: 21,
    },

    target_app_categories: [],
    target_app_store_cat: [],
    target_app_store_cat_exclude: [],
    target_genders: [],
    target_age_groups: [],
    target_device_os: [DeviceOSMapping.IOS, DeviceOSMapping.Android],
    target_carriers: [],
    target_device_language: [],
    audiences: [],
    audience_exclude: [],

    ad_preset_properties: [
        {
            groupName: 'Budget & Optimization',
            properties: [
                {
                    name: 'Total Cost',
                    key: 'max_cpm_rate_local',
                    fields: ['max_cpm_rate_local'],
                    checked: false,
                },
                {
                    name: 'Daily Frequency',
                    key: 'max_user_frequency',
                    fields: ['max_user_frequency'],
                    checked: false,
                },
            ],
        },
        {
            groupName: 'Geo',
            properties: [
                {
                    name: 'Geotargeting',
                    key: 'geotargets',
                    fields: ['geotargets'],
                    checked: false,
                },
                { name: 'Geofencing', key: 'geoboxes', fields: ['geoboxes'], checked: false },
                {
                    name: 'POI Segments',
                    key: 'geo_targeting_settings',
                    fields: ['geo_targeting_settings'],
                    checked: false,
                },
            ],
        },
        {
            groupName: 'Audience',
            properties: [
                {
                    name: 'Gender',
                    key: 'target_genders',
                    fields: ['target_genders'],
                    checked: false,
                },
                {
                    name: 'Age',
                    key: 'target_age_groups',
                    fields: ['target_age_groups'],
                    checked: false,
                },
                {
                    name: 'Device OS',
                    key: 'target_device_os',
                    fields: ['target_device_os'],
                    checked: false,
                },
                {
                    name: 'Target Carrier',
                    key: 'target_carriers',
                    fields: ['target_carriers'],
                    checked: false,
                },
                {
                    name: 'User Language',
                    key: 'target_device_language',
                    fields: ['target_device_language'],
                    checked: false,
                },
                {
                    name: 'Audience Segments',
                    key: 'audiences',
                    fields: ['audiences', 'audience_exclude', 'audience_fee'],
                    checked: false,
                },
                {
                    name: 'App Categories',
                    key: 'target_app_store_cat',
                    fields: ['target_app_store_cat', 'target_app_store_cat_exclude'],
                    checked: false,
                },
            ],
        },
    ],
};

import _ from 'lodash';

export default function mergeResourceV2(resourceMap, incomingList) {
    // [ { id: 123 } ] =>
    // [
    //  {
    //      id: 123,
    //      attr: { id: 123 }
    //  }
    // ]
    // for each incoming item, convert to attr format
    /*
    const incomingWithAttr = []
        .concat(incomingList)
        .filter(item => item)
        .map(item => ({
            attr: item,
            id: item.id
        }));
    */

    const nextResource = { ...resourceMap };
    // _.each(_.filter(incomingList, x=>x), record => {
    _.each(incomingList, record => {
        const existingRecord = nextResource[record.id];
        nextResource[record.id] = {
            id: record.id,
            ...(nextResource[record.id] || {}),
            /*
            attr: record,
            */
            attr: {
                ...((existingRecord && existingRecord.attr) || {}),
                ...record,
            },
        };
    });

    return nextResource;
}

export function mergeResource(resourceMap, incomingList) {
    // [ { id: 123 } ] =>
    // [
    //  {
    //      id: 123,
    //      attr: { id: 123 }
    //  }
    // ]
    // for each incoming item, convert to attr format
    const incomingWithAttr = []
        .concat(incomingList)
        .filter(item => item)
        .map(item => ({
            attr: item,
            id: item.id,
        }));

    // concat resources with incoming, then merge left (old) to right (new)
    return _(resourceMap)
        .map(item => item)
        .concat(incomingWithAttr)
        .filter(item => item.id)
        .reduce((acc, item) => {
            return {
                ...acc,
                [item.id]: {
                    id: item.id,
                    ...acc[item.id],
                    ...item,
                    attr: {
                        // see NOTE in CreativeAssetSelector for issue on merging an unset field
                        ...(acc[item.id] ? acc[item.id].attr : {}),
                        ...item.attr,
                    },
                },
            };
        }, {});
    // .reduce((acc, item) =>
    //     ({
    //         ...acc,
    //         [item.id]: {
    //             id: item.id,
    //             ...acc[item.id],
    //             attr: {
    //                 ...(acc[item.id] ? acc[item.id].attr : {}),
    //                 ...item.attr
    //             }
    //         }
    //     }),
    //     {}
    // );
}

import { parse } from 'csv-parse/sync';
import validate from 'utils/validate';
import map from 'lodash/map';
import forEach from 'lodash/map';

const validators = {
    // TODO: Apply validation like so: name and either address or lat/long pair
    locationName() {},
    address(value, source) {
        if (source.lat && source.long && value === '') {
            return;
        }

        if (!value) {
            return 'a valid address is required';
        }
    },
    city() {},
    postalCode(value) {
        if (!/[a-z][0-9][a-z]\s?[0-9][a-z][0-9]/i.test(value)) {
            return 'Invalid postal code format. Only Canadian addresses are accepted at this time';
        }
    },
    country(value) {
        if (!/canada/i.test(value) || !/ca/i.test(value)) {
            return 'Invalid country name. Only Canadian addresses are accepted at this time';
        }
    },
    lat(value, source) {
        if (source.address && value === '') {
            return;
        }

        if (!value) {
            return 'a latitude is required';
        }

        if (Number(value) === NaN) {
            return 'a valid latitude is required';
        }
    },
    long(value, source) {
        if (source.address && value === '') {
            return;
        }

        if (!value) {
            return 'a latitude is required';
        }

        if (Number(value) === NaN) {
            return 'a valid latitude is required';
        }
    },
};

export function validateLocations(csvText) {
    let parsedCsv;
    try {
        parsedCsv = parse(csvText, { columns: true });
        parsedCsv = filterParsedCsv(parsedCsv);
        if (!parsedCsv || parsedCsv.length === 0) {
            throw new Error('CSV is empty or has wrong formatting!');
        }
    } catch (e) {
        console.error('Error parsing CSV: ', e);
        return ['Invalid CSV submitted with error:', e.message];
    }

    let errors = [];
    forEach(parsedCsv, (locationData, index) => {
        let lineErrors = validate(locationData, validators);
        let condensedError = `Line ${index + 2}:`;

        forEach(
            lineErrors,
            error => (condensedError = `${condensedError} [${error.field}: ${error.message}],`)
        );

        if (lineErrors.length) {
            errors.push(condensedError);
        }
    });

    return errors.length ? errors : undefined;
}

export function constructLocations(csvText) {
    let parsedCsv = parse(csvText, { columns: true });
    parsedCsv = filterParsedCsv(parsedCsv);

    return map(parsedCsv, record => ({
        locationName: record.locationName,
        address: record.address,
        city: record.city,
        postalCode: record.postalCode,
        country: record.country,
        lat: Number(record.lat),
        long: Number(record.long),
    }));
}

function filterParsedCsv(csv) {
    return csv.filter(
        record =>
            record.locationName !== '' &&
            record.city !== '' &&
            record.postalCode !== '' &&
            record.country !== ''
    );
}

export function filterEmptyRawCsvLocations(csv) {
    const records = csv.split('\n');

    const nonEmptyRecords = records.filter(record => record.trim() !== ',,,,,,');

    return nonEmptyRecords.join('\n');
}

import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';

import ExportReportButton from 'containers/export-report-button';
import InventoryReportLayout from '../shared/layout';
import InventoryReportPivotTable from '../shared/pivot-table';

import { APP_STORE_CAT_RELEASE_DATE } from 'common/constants/date-flags';
import metricConfig from 'common/metrics';
import dimensionConfig from 'common/dimensions';
import attributeConfig from 'common/attributes';

import selector from './selector';

const DIMENSION_OPTIONS = _.map(
    ['channel_name', 'channel_type', 'app_store_cat'],
    key => dimensionConfig[key]
);

const ATTRIBUTE_OPTIONS = _.map(['app_store_cat_attr'], key => attributeConfig[key]);

const METRIC_OPTIONS = _.map(['impressions', 'clicks', 'ctr'], key => metricConfig[key]);

class InventoryReport extends React.Component {
    state = {
        selectedMetrics: ['impressions', 'clicks', 'ctr'],
        selectedDimensions: ['channel_name'],
        selectedAttributes: ['app_store_cat_attr'],
        // This key is used to force render the Pivot Table when
        // criteria changes so that new data can be fetched
        pivotTableKey: null,
        request: null,
        hideZeroRows: false,
        limit: 100,
        sortColumn: 'impressions',
        sortDirection: 'desc',
        status: 'waiting',
        errors: {},
    };

    apply = () => {
        if (this.state.status === 'error') {
            return;
        }

        // If the current sort column was removed, we need to fall back to another one
        const sortableColumns = [
            ...this.state.selectedMetrics,
            'dimension',
            ...this.state.selectedAttributes,
        ];
        let sortColumn = this.state.sortColumn;
        if (!_.includes(sortableColumns, this.state.sortColumn)) {
            sortColumn = _.first(sortableColumns);
        }

        this.setState({
            pivotTableKey: Date.now(),
            request: this.createRequest(),
            sortColumn,
            status: 'waiting',
        });
    };

    createRequest = () => {
        // By default we should show data only for the last 30 days, however
        // we need to exclude the data that was generated pre-release of the
        // app cat feature
        const thirtyDaysAgo = moment
            .utc()
            .subtract(30, 'days')
            .startOf('day');
        const start = thirtyDaysAgo.isAfter(APP_STORE_CAT_RELEASE_DATE)
            ? thirtyDaysAgo
            : APP_STORE_CAT_RELEASE_DATE;

        let request = {
            match: {
                camp_org: [this.props.profile.organizationId],
                channel_type: ['SITE'],
            },
            split: this.state.selectedDimensions,
            metrics: this.state.selectedMetrics,
            attributes: this.state.selectedAttributes,
            start: start.format().replace('Z', ''),
            end: moment
                .utc()
                .endOf('day')
                .format()
                .replace('Z', ''),
            timezone: 'UTC',
            top: {
                field: 'channel_id',
                limit: this.state.limit,
            },
            source: 'channel',
            version: 'v3',
        };

        return request;
    };

    sort = column => {
        const sortColumn = column.value;

        let sortDirection = 'desc';
        if (this.state.sortColumn === sortColumn && this.state.sortDirection === 'desc') {
            sortDirection = 'asc';
        }

        this.setState({ sortDirection, sortColumn });
    };

    updateDimensions = dimensions => {
        this.setState({ selectedDimensions: dimensions, status: 'waiting' }, this.validate);
    };

    updateMetrics = metrics => {
        this.setState({ selectedMetrics: metrics, status: 'waiting' }, this.validate);
    };

    validate = () => {
        const errors = {};

        if (this.state.selectedMetrics.length === 0) {
            errors.metric = 'Select at least 1 metric';
        }

        if (this.state.selectedDimensions.length === 0) {
            errors.dimension = 'Select at least 1 dimension';
        }

        this.setState({
            errors,
            status: _.keys(errors).length > 0 ? 'error' : this.state.status,
        });
    };

    componentDidMount() {
        this.apply();
    }

    render() {
        return (
            <InventoryReportLayout
                status={this.state.status}
                errors={this.state.errors}
                channel_type={'SITE'}
                selectedDimensions={this.state.selectedDimensions}
                dimensionOptions={DIMENSION_OPTIONS}
                updateDimensions={this.updateDimensions}
                selectedAttributes={this.state.selectedAttributes}
                attributeOptions={ATTRIBUTE_OPTIONS}
                updateAttributes={attributes => {
                    this.setState({ selectedAttributes: attributes });
                }}
                selectedMetrics={this.state.selectedMetrics}
                metricOptions={METRIC_OPTIONS}
                updateMetrics={this.updateMetrics}
                limit={this.state.limit}
                showLimit={limit => {
                    this.setState({ limit });
                }}
                exportReportButton={
                    this.state.request && (
                        <ExportReportButton
                            request={{
                                ...this.state.request,
                                format: 'csv',
                                hideZeroRows: this.state.hideZeroRows,
                                sortColumn: this.state.sortColumn,
                                sortDirection: this.state.sortDirection,
                            }}
                            fileName={`${moment().format('YYYY_MM_DD')}.csv`}
                        />
                    )
                }
                applyButton={
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.apply}
                        startIcon={<CheckIcon />}
                    >
                        Apply
                    </Button>
                }
                pivotTable={
                    this.state.request && (
                        <InventoryReportPivotTable
                            key={this.state.pivotTableKey}
                            hideZeroRows={this.state.hideZeroRows}
                            request={this.state.request}
                            channel_type={'SITE'}
                            sortColumn={this.state.sortColumn}
                            sortDirection={this.state.sortDirection}
                            onSort={this.sort}
                        />
                    )
                }
            />
        );
    }
}

export default connect(selector)(InventoryReport);

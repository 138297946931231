import _ from 'lodash';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import reduxModules from './redux-modules';
const reducerModules = [
    require('../packages/forms/advertiser-form/reducer.js'),
    require('../packages/forms/creative-form/reducer.js'),
    require('../packages/forms/media-plan-form/reducer.js'),
    require('../packages/forms/admin-users-form/reducer.js'),
    require('../packages/forms/ad-form/reducer.js'),
    require('../packages/forms/admin-org-form/reducer.js'),
    require('../packages/forms/flex-segments-form/reducer.js'),
    require('../packages/forms/creative-bulk-edit-form/reducer.js'),
    require('../packages/forms/creative-bulk-upload-form/reducer.js'),
    require('../packages/states/resources/audiences/reducer.js'),
    require('../packages/states/resources/ads/reducer.js'),
    require('../packages/states/resources/organizations/reducer.js'),
    require('../packages/states/resources/password/reducer.js'),
    require('../packages/states/resources/inventory-predictions/reducer.js'),
    require('../packages/states/resources/ubx-audiences/reducer.js'),
    require('../packages/states/resources/business-reports/reducer.js'),
    require('../packages/states/resources/geo-categories/reducer.js'),
    require('../packages/states/resources/report-campaigns/reducer.js'),
    require('../packages/states/resources/scheduled-reports/reducer.js'),
    require('../packages/states/resources/users/reducer.js'),
    require('../packages/states/resources/history/ads/reducer.js'),
    require('../packages/states/resources/history/campaigns/reducer.js'),
    require('../packages/states/resources/history/creatives/reducer.js'),
    require('../packages/states/resources/ad-groupings/reducer.js'),
    require('../packages/states/resources/app-lists/reducer.js'),
    require('../packages/states/resources/files/reducer.js'),
    require('../packages/states/resources/ad-presets/reducer.js'),
    require('../packages/states/resources/activate/reducer.js'),
    require('../packages/states/resources/campaigns/reducer.js'),
    require('../packages/states/resources/creatives/reducer.js'),
    require('../packages/states/resources/apps/reducer.js'),
    require('../packages/states/resources/pois/reducer.js'),
    require('../packages/states/resources/stats/ad/reducer.js'),
    require('../packages/states/resources/stats/campaign/reducer.js'),
    require('../packages/states/resources/stats/campaigns/report/geo/reducer.js'),
    require('../packages/states/resources/stats/campaigns/report/explore/reducer.js'),
    require('../packages/states/resources/stats/campaigns/report/channel/reducer.js'),
    require('../packages/states/resources/stats/account/reducer.js'),
    require('../packages/states/report-campaigns-loader/reducer.js'),
    require('../packages/states/profile/reducer.js'),
    require('../packages/states/dictionary/reducer.js'),
    require('../packages/containers/admin-org-item-form/reducer.js'),
    require('../packages/containers/sidebar/reducer.js'),
    require('../packages/containers/campaign-sharing/reducer.js'),
    require('../packages/containers/admin-org-item/reducer.js'),
    require('../packages/containers/campaign-access-check/reducer.js'),
    require('../packages/containers/poi-editor/reducer.js'),
    require('../packages/containers/scheduled-report/reducer.js'),
    require('../packages/containers/poi-viewer/reducer.js'),
    require('../packages/containers/audience-segment-picker/reducer.js'),
    require('../packages/containers/audience-segment-picker/modules/picker-v2/reducer.js'),
    require('../packages/containers/admin-users-form/reducer.js'),
    require('../packages/containers/dev-settings/reducer.js'),
    require('../packages/containers/impersonate/reducer.js'),
    require('../packages/containers/delete-user-modal/reducer.js'),
    require('../packages/pages/audiences/environics-dashboard/reducer.js'),
    require('../packages/pages/audiences/flex-brand-dashboard/reducer.js'),
    require('../packages/pages/audiences/flex-category-dashboard/reducer.js'),
    require('../packages/pages/audiences/uploaded-dashboard/reducer.js'),
    require('../packages/pages/audiences/reducer.js'),
    require('../packages/pages/audiences/pds-custom-dashboard/reducer.js'),
    require('../packages/pages/audiences/adobe-dashboard/reducer.js'),
    require('../packages/pages/audiences/intent-dashboard/reducer.js'),
    require('../packages/pages/audiences/retargeting-dashboard/reducer.js'),
    require('../packages/pages/audiences/retargeting-dashboard/modules/reducer.js'),
    require('../packages/pages/audiences/dashboard/reducer.js'),
    require('../packages/pages/audiences/manifold-dashboard/reducer.js'),
    require('../packages/pages/audiences/mastercard-dashboard/reducer.js'),
    require('../packages/pages/audiences/flex-custom-dashboard/reducer.js'),
    require('../packages/pages/audiences/retail-dashboard/reducer.js'),
    require('../packages/pages/audiences/brand-dashboard/reducer.js'),
    require('../packages/pages/audiences/behaviour-dashboard/reducer.js'),
    require('../packages/pages/audiences/editor/reducer.js'),
    require('../packages/pages/audiences/age-dashboard/reducer.js'),
    require('../packages/pages/test/reducer.js'),
    require('../packages/pages/conversions/reducer.js'),
    require('../packages/pages/admin-v2/child-organization/reducer.js'),
    require('../packages/pages/admin-v2/own-organization/reducer.js'),
    require('../packages/pages/admin/reducer.js'),
    require('../packages/pages/dashboard/reducer.js'),
    require('../packages/pages/ad-form/ad-edit-form/reducer.js'),
    require('../packages/pages/ad-form/ad-new-form/reducer.js'),
    require('../packages/pages/ad-preset/ad-preset-new/reducer.js'),
    require('../packages/pages/ad-preset/ad-preset-edit/reducer.js'),
    require('../packages/pages/profile/reducer.js'),
    require('../packages/pages/forgot-password/reducer.js'),
    require('../packages/pages/reset-password/reducer.js'),
    require('../packages/pages/fta-location-lists/reducer.js'),
    require('../packages/pages/fta-location-lists/editor/reducer.js'),
    require('../packages/pages/activate/reducer.js'),
    require('../packages/pages/apps-and-sites/deals-inventory/reducer.js'),
    require('../packages/pages/apps-and-sites/deals-inventory/deal-modal/reducer.js'),
    require('../packages/pages/apps-and-sites/deals-inventory/delete-deal-modal/reducer.js'),
    require('../packages/pages/apps-and-sites/sites-report/reducer.js'),
    require('../packages/pages/apps-and-sites/apps-report/reducer.js'),
    require('../packages/pages/apps-and-sites/apps-and-sites-list/reducer.js'),
    require('../packages/pages/campaigns/ads/ads-overview/reducer.js'),
    require('../packages/pages/campaigns/ads/ad-history/reducer.js'),
    require('../packages/pages/campaigns/beacons-overview/reducer.js'),
    require('../packages/pages/campaigns/custom-dimensions/custom-dimensions-overview/reducer.js'),
    require('../packages/pages/campaigns/progress/campaign-progress/reducer.js'),
    require('../packages/pages/campaigns/progress/ad-progress/reducer.js'),
    require('../packages/pages/campaigns/conversions-overview/reducer.js'),
    require('../packages/pages/campaigns/campaigns-overview/reducer.js'),
    require('../packages/pages/campaigns/campaigns-overview/modules/workspace/reducer.js'),
    require('../packages/pages/campaigns/campaign-history/reducer.js'),
    require('../packages/pages/campaigns/campaign/reducer.js'),
    require('../packages/pages/campaigns/report/report-geo/reducer.js'),
    require('../packages/pages/campaigns/report/report-explore/reducer.js'),
    require('../packages/pages/campaigns/report/report-channel/reducer.js'),
    require('../packages/pages/campaigns/report/foot-traffic-attribution/reducer.js'),
    require('../packages/pages/campaigns/report/report-overview/reducer.js'),
    require('../packages/pages/campaigns/files-overview/reducer.js'),
    require('../packages/pages/campaigns/creatives/creatives-overview/reducer.js'),
    require('../packages/pages/campaigns/creatives/creatives-overview/modules/creative-bulk-edit-audit-warning/reducer.js'),
    require('../packages/pages/campaigns/creatives/form/creative-edit-form/reducer.js'),
    require('../packages/pages/campaigns/creatives/form/creative-new-form/reducer.js'),
    require('../packages/pages/campaigns/creatives/creatives-history/reducer.js'),
    require('../packages/pages/account/reducer.js'),
    require('../packages/pages/presets/poi-editor/reducer.js'),
    require('../packages/pages/presets/reducer.js'),
    require('../packages/pages/presets/poi-dashboard/reducer.js'),
    require('../packages/pages/login/reducer.js'),
    require('../packages/pages/reports/dashboard/reducer.js'),
    require('../packages/pages/reports/editor/reducer.js'),
    require('../packages/pages/prediction-editor/reducer.js'),
];

// multiple depth
function requireReducers() {
    let reducers = {};

    reducers = _.reduce(
        reducerModules,
        (reducers, reducerModule) => {
            reducers = _.set(reducers, reducerModule.NAME, reducerModule.default);
            return reducers;
        },
        {
            routing: routerReducer,
        }
    );

    return reducers;
}

let allReducers = {};

// require.context fails in test mode and we need to use a placeholder reducer
try {
    allReducers = requireReducers();
} catch (error) {
    console.log('error', error);
    allReducers = {
        ROOT: function(state = {}) {
            return state;
        },
    };
}

_.each(reduxModules, ({ REDUCER_KEY, reducer }) => {
    if (REDUCER_KEY && reducer) {
        allReducers[REDUCER_KEY] = reducer;
    }
});

export function combineNestedReducers(reducer) {
    if (typeof reducer === 'object') {
        const keys = _.keys(reducer);
        let reducers = {};
        _.forEach(keys, key => {
            reducers[key] =
                typeof reducer[key] === 'object'
                    ? combineNestedReducers(reducer[key])
                    : reducer[key];
        });
        return combineReducers(reducers);
    }

    return reducer;
}

export const staticReducers = allReducers;

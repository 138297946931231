import React from 'react';
import classnames from 'classnames';

export function Flexbox(props) {
    return <div style={{ flex: props.flex }}>{props.children}</div>;
}

export function Flex(props) {
    return (
        <div style={props.style} className={classnames(props.className, 'ef6-alignment__flex')}>
            {props.children}
        </div>
    );
}

export function CenterLayout(props) {
    return (
        <div style={props.style} className={classnames(props.className, 'ef6-alignment__center')}>
            {props.children}
        </div>
    );
}

export function VerticalCenterLayout(props) {
    return (
        <div
            style={props.style}
            className={classnames(props.className, 'ef6-alignment__flex-center')}
        >
            {props.children}
        </div>
    );
}

export function SpaceBetweenLayout(props) {
    return (
        <div
            style={props.style}
            className={classnames(props.className, 'ef6-alignment__space-between')}
        >
            {props.children}
        </div>
    );
}

export function FlexEndLayout(props) {
    return (
        <div style={props.style} className={classnames(props.className, 'ef6-alignment__flex-end')}>
            {props.children}
        </div>
    );
}

export function AlignRight(props) {
    return (
        <div style={props.style} className={classnames(props.className, 'ef6-alignment__right')}>
            {props.children}
        </div>
    );
}

export function AlignLeft(props) {
    return (
        <div style={props.style} className={classnames(props.className, 'ef6-alignment__left')}>
            {props.children}
        </div>
    );
}

export function AnnotatedLayout(props) {
    return (
        <div style={props.style} className={classnames(props.className, 'layout__annotated')}>
            {props.children}
        </div>
    );
}

export function AnnotatedLayoutBody(props) {
    return (
        <div className={classnames(props.className, 'layout__annotated-body')}>
            {props.children}
        </div>
    );
}

export function AnnotatedLayoutDescription(props) {
    return (
        <div className={classnames(props.className, 'layout__annotated-description')}>
            {props.children}
        </div>
    );
}

export function FixedLayout(props) {
    return (
        <div style={props.style} className={classnames(props.className, 'ef6-alignment__fixed')}>
            {props.children}
        </div>
    );
}

import _ from 'lodash';
import flags from 'containers/flags/service';
import createHttp from 'utils/http';

export const getGeocodingQuery = point => {
    let query = point.address;

    if (point.city) {
        query = `${query}, ${point.city}`;
    }

    if (point.region) {
        query = `${query}, ${point.region}`;
    }

    if (point.postalCode) {
        query = `${query}, ${point.postalCode}`;
    }

    return query;
};

export async function interpolatePoints(points) {
    const pointsToInterpolate = [];

    _.each(points, point => {
        const isMissingLatLong =
            point.latitude === null ||
            point.latitude === 0 ||
            point.longitude === null ||
            point.longitude === 0;
        const hasAddressWithCityProvince = point.address && point.city && point.region;
        const hasAddressWithPostalCode = point.address && point.postalCode;

        if (isMissingLatLong && (hasAddressWithCityProvince || hasAddressWithPostalCode)) {
            pointsToInterpolate.push(getGeocodingQuery(point));
        }
    });

    const http = createHttp();

    const { coordinates: interpolatedPoints } = await http.post('geolayers/interpolate-points', {
        addresses: pointsToInterpolate,
    });

    const output = _.map(points, point => {
        const geocodingQuery = getGeocodingQuery(point);

        if (point.address && _.includes(pointsToInterpolate, geocodingQuery)) {
            const interpolatedPoint = _.find(interpolatedPoints, { address: geocodingQuery });
            if (interpolatedPoint) {
                return {
                    ...point,
                    latitude: interpolatedPoint.coordinates[0],
                    longitude: interpolatedPoint.coordinates[1],
                };
            }
        }

        return point;
    });

    return output;
}


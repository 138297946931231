import _ from 'lodash';
import smartQuery from 'utils/smart-query';

function recursivelyOpenParentsFolder(node) {
    if (node.parent) {
        node.parent.isOpen = true;
        recursivelyOpenParentsFolder(node.parent);
    }
}

function match(_stringToSearch, _searchQuery) {
    const stringTosearch = _stringToSearch;
    const searchQuery = _searchQuery;
    const matched = smartQuery(searchQuery).test(stringTosearch);

    // -- debug script --
    // if (/.*shop.*/i.test(_stringToSearch)) {
    //     console.log('xxx: ', _stringToSearch, '|', _searchQuery, '|', matched)
    // }

    return matched;
}

export default function geoLayersSearch(searchString, _geoCategories_in) {
    let geoCategories_out = {
        ..._geoCategories_in,
    };
    _.set(geoCategories_out, `geoLayers.isHidden`, false);
    _.set(geoCategories_out, `geoLayers.isOpen`, false);

    const searchQueryClean = searchString.toLowerCase();

    if (searchQueryClean !== '') {
        const geoCategories_in = _.cloneDeep(_geoCategories_in);

        const geoCategories_unfiltered = _.get(geoCategories_in, 'geoCategories', []);
        const geoLayers_unfiltered = _.get(geoCategories_in, 'geoLayers', []);

        // ===================================================
        // Custum layer
        // ===================================================

        const geoLayers_filtered = []; //geoLayers_unfiltered;

        geoLayers_filtered.isHidden = false;
        geoLayers_unfiltered.forEach(layer => {
            if (match(layer.name, searchQueryClean)) {
                geoLayers_filtered.push(layer);
            }
        });

        if (geoLayers_filtered.length === 0) {
            geoLayers_filtered.isHidden = true;
            geoLayers_filtered.isOpen = false;
        } else if (geoLayers_filtered.length !== 0) {
            geoLayers_filtered.isHidden = false;
            geoLayers_filtered.isOpen = true;
        }

        if (match('Custom POI Segments', searchQueryClean) && geoLayers_filtered.length === 0) {
            geoLayers_unfiltered.forEach(layer => {
                geoLayers_filtered.push(layer);
            });
            geoLayers_filtered.isHidden = false;
            geoLayers_filtered.isOpen = false;
        }

        // ===================================================
        // Category layer
        // ===================================================

        // --------------------------
        // filtering Strategy:
        //
        // Recussively traversing the tree starting from the root.
        // Before entering a node, a node of a separate tree is
        // created (postfix with 'filtered') to hold filtered children (children can be a leave or node)
        // --------------------------

        const geoCategories_filtered = [];

        geoCategories_unfiltered.forEach(geoCategory => {
            const geoCategory_cached = _.cloneDeep(geoCategory);
            const geoCategory_filtered = _.cloneDeep(geoCategory);

            geoCategory_filtered.subcategories = [];

            geoCategory_cached.subcategories.forEach(subcategory => {
                const subcategory_cached = _.cloneDeep(subcategory);
                const subcategory_filtered = _.cloneDeep(subcategory);

                subcategory_filtered.layers = [];

                subcategory_cached.layers.forEach(layer => {
                    const layer_cached = _.cloneDeep(layer);

                    const layerName = _.get(layer, 'name', '');

                    if (match(layerName, searchQueryClean)) {
                        subcategory_filtered.layers.push(layer_cached);
                    }
                });

                if (subcategory_filtered.layers.length !== 0) {
                    geoCategory_filtered.subcategories.push(subcategory_filtered);
                } else if (subcategory_filtered.layers.length === 0) {
                    if (match(subcategory_filtered.name, searchQueryClean)) {
                        geoCategory_filtered.subcategories.push(subcategory_cached);
                    }
                }
            });

            if (geoCategory_filtered.subcategories.length !== 0) {
                geoCategories_filtered.push(geoCategory_filtered);
            } else if (geoCategory_filtered.subcategories.length === 0) {
                if (match(geoCategory_filtered.name, searchQueryClean)) {
                    geoCategories_filtered.push(geoCategory_cached);
                }
            }
        });

        // // cached the above
        // const geoCategories_filtered_cachaed = _.cloneDeep(geoCategories_filtered);

        // ---------------------------------------
        // Traversing the tree to add a parent
        // pointer to each note and leave
        // ---------------------------------------

        geoCategories_filtered.forEach(geoCategory => {
            const subcategories = _.get(geoCategory, 'subcategories', []);
            subcategories.forEach(subcategory => {
                subcategory.parent = geoCategory;
                // recursivelyOpenParentsFolder(subcategory); // for Testing
                const layers = _.get(subcategory, 'layers', []);
                layers.forEach(layer => {
                    layer.parent = subcategory;
                    // recursivelyOpenParentsFolder(layer); // for Testing
                });
            });
        });

        // // cached the above
        // const geoCategories_filtered_pointer = _.cloneDeep(geoCategories_filtered);

        // -----------------------------------------------------------
        // Travesing down the tree, when it reach the
        // bottom and when exiting a node
        // add a 'opening' flag to parent node recussively
        // if the node or leave has a matched name
        // -----------------------------------------------------------

        geoCategories_filtered.forEach(geoCategory => {
            const subcategories = _.get(geoCategory, 'subcategories', []);

            subcategories.forEach(subcategory => {
                subcategory.parent = geoCategory;
                const layers = _.get(subcategory, 'layers', []);
                layers.forEach(layer => {
                    const layerName = _.get(layer, 'name', '');
                    if (match(layerName, searchQueryClean)) {
                        recursivelyOpenParentsFolder(layer);
                    }
                });

                // if (subcategory.layers.length !== 0) {
                //     const _doNothing = void 0;
                // } else if (subcategory.layers.length === 0) {
                //
                //     if ( match(subcategory.name, searchQueryClean) ) {
                //         recursivelyOpenParentsFolder(subcategory);
                //     }
                // }

                if (match(subcategory.name, searchQueryClean)) {
                    recursivelyOpenParentsFolder(subcategory);
                }
            });

            // if (geoCategory.subcategories.length !== 0) {
            //     const _doNothing = void 0;
            // } else if (geoCategory.subcategories.length === 0) {
            //     if ( match(geoCategory.name, searchQueryClean) ) {
            //         recursivelyOpenParentsFolder(geoCategory);
            //     }
            // }
        });

        // console.log('xxxx geoCategories_filtered: ', geoCategories_filtered);
        geoCategories_out = {
            ...geoCategories_in,
            geoCategories: geoCategories_filtered,
            geoLayers: geoLayers_filtered,
        };
    }

    // console.log('xxxx geoCategories_out: ', geoCategories_out );
    return geoCategories_out;
}

import { default as React, Component } from 'react';
import config from '../../../../config';

class GoogleMapsApiLoader extends React.Component {
    state = {
        apiHasLoaded: typeof window.google === 'object',
    };

    onLoad = () => {
        this.setState({ apiHasLoaded: true });
    };

    componentDidMount() {
        if (!GoogleMapsApiLoader.apiHasLoaded) {
            GoogleMapsApiLoader.waitingComponents.push(this);
            if (!GoogleMapsApiLoader.apiIsLoading) {
                loadGoogleMapsApi();
            }
        }
    }

    render() {
        return (
            <div className="ef3-googleMapsApiLoader">
                {this.state.apiHasLoaded ? this.props.children : 'Loading...'}
            </div>
        );
    }
}

GoogleMapsApiLoader.apiHasLoaded = false;
GoogleMapsApiLoader.apiIsLoading = false;
GoogleMapsApiLoader.waitingComponents = [];

export default GoogleMapsApiLoader;

function loadGoogleMapsApi() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
        config.API_KEYS.GOOGLE_MAPS
    }&libraries=drawing,places&callback=onGoogleMapsApiLoad`;
    document.body.appendChild(script);

    GoogleMapsApiLoader.apiIsLoading = true;
    window.onGoogleMapsApiLoad = onGoogleMapsApiLoad;
}

function onGoogleMapsApiLoad() {
    delete window.onGoogleMapsApiLoad;
    GoogleMapsApiLoader.apiHasLoaded = true;
    GoogleMapsApiLoader.apiIsLoading = false;
    GoogleMapsApiLoader.waitingComponents.forEach(component => component.onLoad());
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Tabs from 'widgets-v5/tabs';

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        rightContent: PropTypes.node,
        line2Content: PropTypes.node,
        tabs: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
                href: PropTypes.string,
            })
        ),
        activeTab: PropTypes.string,
    };

    render() {
        return (
            <div className={classnames('ef4-workspace-top-bar', this.props.className)}>
                <div className="ef4-workspace-top-bar_line-1">
                    <div className="ef6-alignment__flex-center">
                        <h2 className="ef4-workspace-top-bar_title">{this.props.title}</h2>
                        {this.props.status}
                        {this.props.badge && <div>{this.props.badge}</div>}
                    </div>
                    {this.props.rightContent && (
                        <div className="ef4-workspace-top-bar_right">{this.props.rightContent}</div>
                    )}
                </div>
                {this.props.line2Content && (
                    <div className="ef4-workspace-top-bar_line-2">{this.props.line2Content}</div>
                )}
                {this.props.tabs && (
                    <Tabs
                        className="ef4-workspace-top-bar_tabs"
                        align="left"
                        activeItem={this.props.activeTab}
                        items={this.props.tabs}
                    />
                )}
            </div>
        );
    }
}

import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import numeral from 'numeral';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import Menu from '@mui/material/Menu';
import CircularProgress from '@mui/material/CircularProgress';

import OverviewTabs from 'pages/campaigns/campaigns-overview/overview-tabs';
import FormDialog from '../custom-dimensions-form';
import {
    DimensionConfirmationDialog,
    GroupConfirmationDialog,
    CreateGroupDialog,
} from '../custom-dimensions-form';
import actions from './actions';
import selector from './selector';
import { canEditCustomDimension } from 'states/resources/custom-dimensions/business-logic';

import { formatNumber_whole } from 'utils/formatting';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tableRoot: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    tabs: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    appBar: {
        flexGrow: 1,
    },
    groupTitle: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    highlight:
        theme.palette.mode === 'light'
            ? {
                  color: theme.palette.primary.main,
                  backgroundColor: lighten(theme.palette.primary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.primary.dark,
              },
    newDimensionButton: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    actionIcon: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'visible',
    },
    progress: {
        margin: theme.spacing(2),
    },
}));

const CustomDimensions = ({
    dispatch,
    customDimensions,
    adsMapping,
    creativesMapping,
    loading,
    params,
    primaryDimension,
    activeDimension,
    showErrors,
    errors,
    campaignBudgetAllocationMethod,
    campaign,
}) => {
    useEffect(() => {
        dispatch(actions.getCustomDimensionsPageData(params.campaignId));
    }, [1]);

    function updateDimensionDraft(dimension) {
        dispatch(actions.updateDimensionDraft(dimension));
    }

    function resetDimensionDraft() {
        dispatch(actions.resetDimensionDraft());
    }

    function handleEditGroupName(newGroupName, groupId) {
        const updatedGroups = [...activeDimension.groups];

        _.forEach(updatedGroups, group => {
            if (group.id === groupId) {
                group.name = newGroupName;
            }
        });

        dispatch(
            actions.updateDimensionDraft({
                ...activeDimension,
                groups: updatedGroups,
            })
        );

        dispatch(actions.patch(params.campaignId));
    }

    return (
        <React.Fragment>
            <ScrollableTabsButtonAuto
                customDimensions={customDimensions}
                adsMapping={adsMapping}
                creativesMapping={creativesMapping}
                loading={loading}
                campaignId={params.campaignId}
                updateDimensionDraft={updateDimensionDraft}
                resetDimensionDraft={resetDimensionDraft}
                primaryDimension={primaryDimension}
                activeDimension={activeDimension}
                editGroupName={handleEditGroupName}
                showErrors={showErrors}
                errors={errors}
                campaignBudgetAllocationMethod={campaignBudgetAllocationMethod}
                campaign={campaign}
            />
        </React.Fragment>
    );
};

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function ScrollableTabsButtonAuto({
    customDimensions,
    adsMapping,
    creativesMapping,
    loading,
    campaignId,
    updateDimensionDraft,
    resetDimensionDraft,
    toggleShow,
    toggleSendToFta,
    setFavourite,
    primaryDimension,
    activeDimension,
    deleteGroup,
    editGroupName,
    showErrors,
    errors,
    campaignBudgetAllocationMethod,
    campaign,
}) {
    const classes = useStyles();

    const [isFormOpen, setFormOpen] = useState(false);
    const [isDimensionConfirmationOpen, setDimensionConfirmationOpen] = useState(false);
    const [formMode, setFormMode] = useState('new');
    const [dimensionConfirmationAction, setDimensionConfirmationAction] = useState('');
    const [dimensionIndex, setDimensionIndex] = useState(0);

    useEffect(() => {
        if (customDimensions && customDimensions[dimensionIndex]) {
            updateDimensionDraft(customDimensions[dimensionIndex]);
        }
    }, [dimensionIndex, customDimensions]);

    function handleChange(event, newIndex) {
        setDimensionIndex(newIndex);
    }

    function handleFormCancel() {
        setFormOpen(false);
        setFormMode('new');
    }

    function handleDimensionConfirmationCancel() {
        setDimensionConfirmationOpen(false);
        setDimensionConfirmationAction('');
    }

    function handleFormClose() {
        if (_.keys(errors).length === 0 || !showErrors) {
            setFormOpen(false);
            setFormMode('new');
        }
    }

    function handleFormCreate() {
        if (_.keys(errors).length === 0 || !showErrors) {
            setFormOpen(false);
            setFormMode('new');
            setDimensionIndex(customDimensions.length);
        }
    }

    function handleDimensionConfirmationClose() {
        setDimensionConfirmationOpen(false);
        setDimensionConfirmationAction('');
    }

    function handleAddNewDimension() {
        resetDimensionDraft();
        setFormMode('new');
        setFormOpen(true);
    }

    function handleEditDimension() {
        setFormMode('edit');
        setFormOpen(true);
    }

    function handleDeleteDimension() {
        setDimensionConfirmationOpen(true);
        setDimensionConfirmationAction('deleteDimension');
    }

    function handleConfirmDeleteDimension(newIndex) {
        setDimensionConfirmationOpen(false);
        setDimensionIndex(newIndex);
    }

    function handleToggleShow() {
        setDimensionConfirmationOpen(true);
        setDimensionConfirmationAction('toggleShow');
    }

    function handleToggleSendToFta() {
        setDimensionConfirmationOpen(true);
        setDimensionConfirmationAction('toggleSendToFta');
    }

    function handleSetFavourite() {
        setDimensionConfirmationOpen(true);
        setDimensionConfirmationAction('setFavourite');
    }

    function attachUngroupedKeys(dimension, keyMapping) {
        const allKeys = _.keys(keyMapping);
        const usedKeys = _.uniq(_.flatten(_.map(dimension.groups, group => group.keys)));
        const unusedKeys = _.difference(allKeys, usedKeys);

        if (unusedKeys.length) {
            return {
                ...dimension,
                groups: _.union(dimension.groups, [
                    {
                        name: `Ungrouped ${dimension.contentType === 'ad' ? 'Ads' : 'Creatives'}`,
                        keys: unusedKeys,
                        isUngrouped: true,
                    },
                ]),
            };
        }

        return dimension;
    }

    let dimension = null;

    if (activeDimension) {
        dimension = attachUngroupedKeys(
            activeDimension,
            activeDimension.contentType === 'ad' ? adsMapping : creativesMapping
        );
    }

    return (
        <div className={classes.root}>
            <OverviewTabs tab="custom-dimensions" campaignId={campaignId} />
            <AppBar position="static" color="default" className={classes.tabs}>
                <Tabs
                    value={
                        customDimensions ? Math.min(dimensionIndex, customDimensions.length - 1) : 0
                    }
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {_.map(customDimensions, dimension => (
                        <Tab key={dimension.id} label={dimension.name} />
                    ))}
                </Tabs>
                {canEditCustomDimension(campaign) && (
                    <Tooltip title="New Custom Dimension">
                        <IconButton
                            className={classes.newDimensionButton}
                            aria-label="New Custom Dimension"
                            onClick={handleAddNewDimension}
                            size="large"
                        >
                            <Icon className="fa fa-plus" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                <FormDialog
                    open={isFormOpen}
                    mode={formMode}
                    onCancel={handleFormCancel}
                    onClose={handleFormClose}
                    onCreate={handleFormCreate}
                    campaignId={campaignId}
                    showErrors={showErrors}
                    errors={errors}
                    customDimensions={customDimensions}
                />
                <DimensionConfirmationDialog
                    open={isDimensionConfirmationOpen}
                    onCancel={handleDimensionConfirmationCancel}
                    onClose={handleDimensionConfirmationClose}
                    onDelete={() => handleConfirmDeleteDimension(dimensionIndex - 1)}
                    campaignId={campaignId}
                    confirmationAction={dimensionConfirmationAction}
                    customDimensions={customDimensions}
                />
            </AppBar>
            {dimension && (
                <TabContainer>
                    {activeDimension.viewType !== 'default' && (
                        <React.Fragment>
                            {canEditCustomDimension(campaign) && (
                                <Tooltip title="Favourite">
                                    <IconButton
                                        aria-label="Favourite"
                                        onClick={handleSetFavourite}
                                        size="large"
                                    >
                                        <Icon
                                            fontSize="small"
                                            className={classes.actionIcon}
                                            color={
                                                activeDimension.id === primaryDimension
                                                    ? 'primary'
                                                    : 'inherit'
                                            }
                                        >
                                            <StarBorderIcon />
                                        </Icon>
                                    </IconButton>
                                </Tooltip>
                            )}
                            {canEditCustomDimension(campaign) && (
                                <React.Fragment>
                                    {activeDimension.viewType !== '_split_by_ad' && (
                                        <Tooltip title="Sync with FTA">
                                            <IconButton
                                                aria-label="Sync with FTA"
                                                onClick={handleToggleSendToFta}
                                                size="large"
                                            >
                                                <Icon
                                                    fontSize="small"
                                                    className={classes.actionIcon}
                                                    color={
                                                        activeDimension.sendToFta
                                                            ? 'primary'
                                                            : 'inherit'
                                                    }
                                                >
                                                    <DirectionsWalkIcon />
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </React.Fragment>
                            )}
                            {!activeDimension.viewType.includes('default') && (
                                <React.Fragment>
                                    <Tooltip title="Edit Dimension">
                                        <IconButton
                                            aria-label="Edit"
                                            onClick={handleEditDimension}
                                            size="large"
                                        >
                                            <Icon className="fa fa-pencil" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Dimension">
                                        <IconButton
                                            aria-label="Delete"
                                            onClick={handleDeleteDimension}
                                            size="large"
                                        >
                                            <Icon className="fa fa-trash" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {_.map(dimension.groups, group => (
                        <GroupTable
                            key={group.id}
                            group={group}
                            contentType={dimension.contentType}
                            isDefault={dimension.viewType.includes('default')}
                            groups={dimension.groups}
                            adsMapping={adsMapping}
                            creativesMapping={creativesMapping}
                            campaignId={campaignId}
                            deleteGroup={deleteGroup}
                            editGroupName={editGroupName}
                            campaignBudgetAllocationMethod={campaignBudgetAllocationMethod}
                            campaign={campaign}
                        />
                    ))}
                </TabContainer>
            )}
        </div>
    );
}

function getBillableVol(ad) {
    if (ad.billable_volume !== -1 && ad.billable_volume !== void 0 && ad.billing_enabled) {
        if (
            _.includes(
                ['billable_media_cost_markup', 'billable_media_cost_margin'],
                ad.ef_billing_terms
            )
        ) {
            return numeral(ad.billable_volume).format('$0,0.00');
        }
        return numeral(ad.billable_volume).format('0,0');
    }

    return 'n/a';
}

function getTotalTargetDailyCap(ad) {
    let totalTarget, dailyCap;
    switch (ad.primary_pacing) {
        case 'impressions':
            totalTarget =
                ad.max_total_impressions && ad.max_total_impressions > 0
                    ? numeral(ad.max_total_impressions).format('0,0')
                    : 'unlimited';
            dailyCap =
                ad.max_daily_impressions && ad.max_daily_impressions > 0
                    ? numeral(ad.max_daily_impressions).format('0,0')
                    : 'unlimited';
            break;
        case 'clicks':
            totalTarget =
                ad.max_total_clicks && ad.max_total_clicks > 0
                    ? numeral(ad.max_total_clicks).format('0,0')
                    : 'unlimited';
            dailyCap =
                ad.max_daily_clicks && ad.max_daily_clicks > 0
                    ? numeral(ad.max_daily_clicks).format('0,0')
                    : 'unlimited';
            break;
        case 'billings':
            totalTarget =
                ad.max_total_billings && ad.max_total_billings > 0
                    ? numeral(ad.max_total_billings).format('$0,0.00')
                    : 'unlimited';
            dailyCap =
                ad.max_daily_billings && ad.max_daily_billings > 0
                    ? numeral(ad.max_daily_billings).format('$0,0.00')
                    : 'unlimited';
            break;
        case 'spend':
            totalTarget =
                ad.max_total_spend_local && ad.max_total_spend_local > 0
                    ? numeral(ad.max_total_spend_local).format('$0,0.00')
                    : 'unlimited';
            dailyCap =
                ad.max_daily_spend_local && ad.max_daily_spend_local > 0
                    ? numeral(ad.max_daily_spend_local).format('$0,0.00')
                    : 'unlimited';
            break;
    }
    return {
        totalTarget,
        dailyCap,
    };
}

function getBillingTerms(ad) {
    let billingTerms;
    if (!ad.billing_enabled) {
        billingTerms = 'Bonus';
    } else {
        switch (ad.ef_billing_terms) {
            case 'billable_media_cost_margin':
                billingTerms = 'Margin';
                break;
            case 'billable_media_cost_markup':
                billingTerms = 'Markup';
                break;
            default:
                billingTerms = ad.ef_billing_terms;
        }
    }
    return billingTerms;
}

function formatFilled(ad) {
    if (ad.primary_pacing === 'impressions' || ad.primary_pacing === 'clicks') {
        return formatNumber_whole(ad.filled);
    } else if (ad.primary_pacing === 'billings' || ad.primary_pacing === 'spend') {
        return numeral(ad.filled).format('$0,0.00');
    } else {
        return 'N/A';
    }
}

function GroupTable({
    group,
    contentType,
    adsMapping,
    creativesMapping,
    groups,
    campaignId,
    deleteGroup,
    editGroupName,
    isDefault,
    campaignBudgetAllocationMethod,
    campaign,
}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [edit, setEdit] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isCreateGroupOpen, setCreateGroupOpen] = useState(false);
    const [isGroupConfirmationOpen, setGroupConfirmationOpen] = useState(false);
    const [groupConfirmationAction, setGroupConfirmationAction] = useState('');

    const classes = useStyles();

    function handleCheckRow(key) {
        if (_.includes(selectedRows, key)) {
            setSelectedRows(_.filter(selectedRows, row => row !== key));
        } else {
            setSelectedRows(_.union(selectedRows, [key]));
        }
    }

    function handleCheckAll() {
        if (selectedRows.length === group.keys.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(group.keys);
        }
    }

    function handleOpenCreateGroup() {
        setCreateGroupOpen(true);
    }

    function handleCloseCreateGroup() {
        setCreateGroupOpen(false);
    }

    function getCheckedStatus(key) {
        return _.includes(selectedRows, key);
    }

    function handleGroupConfirmationCancel() {
        setGroupConfirmationOpen(false);
        setGroupConfirmationAction('');
    }

    function handleGroupConfirmationClose() {
        setGroupConfirmationOpen(false);
        setGroupConfirmationAction('');
    }

    function handleDeleteGroup(groupId) {
        setSelectedGroupId(groupId);
        setGroupConfirmationAction('deleteGroup');
        setGroupConfirmationOpen(true);
    }

    return (
        <Paper className={classes.tableRoot}>
            <AppBar position="static" color="default" className={classes.appBar}>
                <Toolbar
                    className={classnames({
                        [classes.highlight]: selectedRows.length,
                    })}
                >
                    {selectedRows.length ? (
                        <React.Fragment>
                            <Typography
                                color="inherit"
                                variant="subtitle1"
                                className={classes.groupTitle}
                            >
                                {`${selectedRows.length} selected`}
                            </Typography>
                            <CreateGroupDialog
                                openCreateGroup={handleOpenCreateGroup}
                                onClose={handleCloseCreateGroup}
                                isOpen={isCreateGroupOpen}
                                currentGroup={group}
                                groups={groups}
                                selectedRows={selectedRows}
                                campaignId={campaignId}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className={classes.groupTitle}>
                                {edit ? (
                                    <EditGroupTitle
                                        defaultValue={group.name}
                                        onSave={editGroupName}
                                        groups={groups}
                                        onCancel={() => setEdit(false)}
                                        groupId={group.id}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Typography variant="h6">{group.name}</Typography>
                                        {!group.isUngrouped && !isDefault && (
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    aria-label="Edit"
                                                    onClick={() => setEdit(!edit)}
                                                    size="large"
                                                >
                                                    <Icon
                                                        className="fa fa-pencil"
                                                        fontSize="small"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                            {!group.isUngrouped && !isDefault && (
                                <Tooltip title="Delete">
                                    <IconButton
                                        aria-label="Delete"
                                        onClick={() => handleDeleteGroup(group.id)}
                                        size="large"
                                    >
                                        <Icon className="fa fa-trash" fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </React.Fragment>
                    )}
                </Toolbar>
                <GroupConfirmationDialog
                    open={isGroupConfirmationOpen}
                    onCancel={handleGroupConfirmationCancel}
                    onClose={handleGroupConfirmationCancel}
                    campaignId={campaignId}
                    selectedGroupId={selectedGroupId}
                    confirmationAction={groupConfirmationAction}
                />
            </AppBar>

            {contentType === 'ad' ? (
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                {canEditCustomDimension(campaign) && (
                                    <Checkbox
                                        onClick={handleCheckAll}
                                        checked={selectedRows.length === group.keys.length}
                                    />
                                )}
                            </TableCell>
                            <TableCell>Ad ID</TableCell>
                            <TableCell>Ad Name</TableCell>
                            <TableCell>Primary Pacing</TableCell>
                            {campaignBudgetAllocationMethod === 'manual' && (
                                <React.Fragment>
                                    <TableCell align="right">Total Target</TableCell>
                                    <TableCell>Daily Cap</TableCell>
                                </React.Fragment>
                            )}
                            <TableCell align="right">Filled</TableCell>
                            <TableCell>Billing Terms</TableCell>
                            <TableCell align="right">Billable Vol.</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(_.filter(group.keys, key => key !== null), key => {
                            const ad = adsMapping[key];
                            if (!ad) {
                                return null;
                            }

                            return (
                                <AdRow
                                    key={key}
                                    ad={ad}
                                    campaign={campaign}
                                    campaignBudgetAllocationMethod={campaignBudgetAllocationMethod}
                                    checkRow={() => handleCheckRow(key)}
                                    checked={_.includes(selectedRows, key)}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                {canEditCustomDimension(campaign) && (
                                    <Checkbox
                                        onClick={handleCheckAll}
                                        checked={selectedRows.length === group.keys.length}
                                    />
                                )}
                            </TableCell>
                            <TableCell>Creative ID</TableCell>
                            <TableCell>Creative Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(group.keys, key => {
                            const creative = creativesMapping[key];

                            return (
                                <CreativeRow
                                    key={key}
                                    creative={creative}
                                    campaign={campaign}
                                    checkRow={() => handleCheckRow(key)}
                                    checked={_.includes(selectedRows, key)}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </Paper>
    );
}

function AdRow(props) {
    const { ad, campaign, checkRow, checked, campaignBudgetAllocationMethod } = props;
    const { totalTarget, dailyCap } = getTotalTargetDailyCap(ad);

    const billableVol = getBillableVol(ad);
    const billingTerms = getBillingTerms(ad);

    return (
        <TableRow hover role="checkbox" aria-checked={true} tabIndex={-1} selected={false}>
            <TableCell padding="checkbox">
                {canEditCustomDimension(campaign) && (
                    <Checkbox onClick={checkRow} checked={checked} value={'checkedRow'} />
                )}
            </TableCell>
            <TableCell>{ad.id}</TableCell>
            <TableCell>{ad.name}</TableCell>
            <TableCell>
                {ad.primary_pacing && ad.primary_pacing === 'billings'
                    ? 'revenue'
                    : ad.primary_pacing}
            </TableCell>
            {campaignBudgetAllocationMethod === 'manual' && (
                <React.Fragment>
                    <TableCell align="right">{totalTarget}</TableCell>
                    <TableCell>{dailyCap}</TableCell>
                </React.Fragment>
            )}
            <TableCell align="right">{formatFilled(ad)}</TableCell>
            <TableCell>{billingTerms}</TableCell>
            <TableCell align="right">{billableVol}</TableCell>
        </TableRow>
    );
}

function CreativeRow(props) {
    const { creative, campaign, checkRow, checked } = props;
    return (
        <TableRow hover role="checkbox" aria-checked={true} tabIndex={-1} selected={true}>
            <TableCell padding="checkbox">
                {canEditCustomDimension(campaign) && (
                    <Checkbox onClick={checkRow} checked={checked} value={'checkedRow'} />
                )}
            </TableCell>
            <TableCell>{creative.id}</TableCell>
            <TableCell>{creative.name}</TableCell>
        </TableRow>
    );
}

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'EDIT_GROUP_TITLE__SET_NEW_GROUP_NAME':
            return { value: payload.value, errors: null };
        case 'EDIT_GROUP_TITLE__DUPLICATE_NAME_ERROR':
            return { ...state, errors: payload.errors };
    }
};
function EditGroupTitle({ defaultValue, onSave, onCancel, groupId, groups }) {
    const [state, dispatch] = useReducer(reducer, { value: defaultValue, errors: null });

    function handleChange(event) {
        dispatch({
            type: 'EDIT_GROUP_TITLE__SET_NEW_GROUP_NAME',
            payload: { value: event.target.value },
        });
    }

    function validateGroupName() {
        if (_.find(groups, { name: state.value })) {
            return dispatch({
                type: 'EDIT_GROUP_TITLE__DUPLICATE_NAME_ERROR',
                payload: { errors: 'Group name already exists' },
            });
        }
        onSave(state.value, groupId);
    }

    return (
        <React.Fragment>
            <TextField
                id="standard-bare"
                defaultValue={state.value}
                onChange={handleChange}
                margin="normal"
                error={!!state.errors}
                helperText={state.errors}
            />
            <Tooltip title="Save">
                <IconButton aria-label="Save" onClick={validateGroupName} size="large">
                    <Icon className="fa fa-check" fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
                <IconButton aria-label="Cancel" onClick={onCancel} size="large">
                    <Icon className="fa fa-times" fontSize="small" />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}

export default connect(selector)(CustomDimensions);

import get from 'lodash/get';
import map from 'lodash/map';
import VError from 'verror';

import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
const http = createHttp();
import Campaigns, { fetchCampaign } from 'states/resources/campaigns/actions';
import Creatives from 'states/resources/creatives/actions';
import Files from 'states/resources/files/actions';
import { browserHistory } from 'react-router';
import notify from 'utils/notify';

export function fetchOverviewPageData(campaignId) {
    return async (dispatch, getState) => {
        dispatch({
            type: c.OVERVIEW__CAMPAIGN__INIT,
            payload: { campaignId },
        });

        let campaign = get(getState(), `resources.campaigns.${campaignId}.attr`);
        if (!campaign) {
            campaign = await dispatch(fetchCampaign(campaignId));
        }

        dispatch({
            type: c.OVERVIEW_ADS__INIT,
            payload: { campaignId, campaign },
        });

        dispatch({
            type: c.OVERVIEW_ADS__FETCH,
            payload: { campaignId },
        });

        const adIds = map(campaign.ads, ad => ad.id);

        // Initialize ad loading status
        dispatch({
            type: c.SYSTEM__AD_STATS_TOTAL__FETCH_ALL,
            payload: {
                adIds,
            },
        });

        dispatch({
            type: c.SYSTEM__CAMPAIGN_STATS_TOTAL__FETCH,
            payload: {
                campaignId,
            },
        });

        try {
            await Promise.all([
                dispatch(Creatives.getAll(campaignId)),
                dispatch(Files.getAll(campaignId)),
                dispatch(fetchOverviewPageGraphqlData(Number(campaignId))),
            ]);

            dispatch({
                type: c.OVERVIEW__CAMPAIGN__INIT_SUCCESS,
                payload: {
                    isCampaignLoading: false,
                    campaignId,
                },
            });

            dispatch({
                type: c.OVERVIEW_ADS__FETCH__SUCCESS,
                payload: {
                    isAdsLoading: false,
                    campaignId,
                },
            });
        } catch (err) {
            dispatch({
                type: c.OVERVIEW_ADS__FETCH__FAIL,
                payload: {
                    isAdsLoading: false,
                    campaignId,
                },
            });
        }
    };
}

export function duplicateCampaign(campaignId, payload) {
    return dispatch => {
        return http.post(`campaigns/${campaignId}/duplicate`, payload).then(
            duplicateCampaign => {
                dispatch({
                    type: c.OVERVIEW_CAMPAIGN__DUPLICATE__SUCCESS,
                    payload: {
                        duplicateCampaignId: duplicateCampaign.id,
                        originalCampaignId: campaignId,
                        duplicateCampaign,
                    },
                });
                browserHistory.push(`/campaigns/${duplicateCampaign.id}/setup`);
            },
            err => {
                dispatch({
                    type: c.OVERVIEW_CAMPAIGN__DUPLICATE__FAIL,
                    payload: {
                        originalCampaignId: campaignId,
                        err,
                    },
                });
            }
        );
    };
}

export function closeCampaignEditForm(campaignId) {
    return {
        type: c.OVERVIEW__CAMPAIGN_DRAFT__CLOSE,
        payload: { campaignId },
    };
}

export function pause(campaignId) {
    return dispatch => {
        dispatch({
            type: 'OVERVIEW__CAMPAIGN__PAUSE',
            payload: {
                campaignId,
            },
        });

        const payload = {
            paused: true,
        };

        return dispatch(Campaigns.update(campaignId, payload)).then(
            () => {
                // dispatch(Ads.getAll(campaignId));
                dispatch(fetchCampaign(campaignId));
                dispatch({
                    type: 'OVERVIEW__CAMPAIGN__PAUSE_SUCCESS',
                    payload: {
                        campaignId,
                    },
                });
            },

            () => {
                dispatch({
                    type: 'OVERVIEW__CAMPAIGN__PAUSE_FAIL',
                    payload: {
                        campaignId,
                    },
                });
            }
        );
    };
}

export function unpause(campaignId) {
    return dispatch => {
        dispatch({
            type: 'OVERVIEW__CAMPAIGN__UNPAUSE',
            payload: {
                campaignId,
            },
        });

        const payload = {
            paused: false,
        };

        return dispatch(Campaigns.update(campaignId, payload)).then(
            () => {
                // dispatch(Ads.getAll(campaignId));
                dispatch(fetchCampaign(campaignId));
                dispatch({
                    type: 'OVERVIEW__CAMPAIGN__UNPAUSE_SUCCESS',
                    payload: {
                        campaignId,
                    },
                });
            },

            () => {
                dispatch({
                    type: 'OVERVIEW__CAMPAIGN__UNPAUSE_FAIL',
                    payload: {
                        campaignId,
                    },
                });
            }
        );
    };
}

export function fetchOverviewPageGraphqlData(campaignId, flightId) {
    return async dispatch => {
        try {
            const http = createHttp();
            const query = `
                query fetchCampaignOverviewData ($campaignId: Int, $flightId: String, $runTime: String) {
                    campaign(id: $campaignId) {
                        budget_allocation_method
                        automaticBudgetAllocationOptimizationStrategy
                        advertiserConversions {
                            event_name
                        }
                        conversions {
                            event_name
                        }
                        ads {
                            rotation_rules {
                                mode
                            }
                        }
                        flight(flightId: $flightId, runTime: $runTime) {
                            id
                            name
                            start
                            end
                            total_cost_budget
                            metadata {
                                metrics {
                                    impressions
                                    clicks
                                    spend
                                    billings_local
                                    ctr
                                    owner_total_media_cost_local
                                    owner_total_media_cost_local_ecpm
                                    win_rate
                                    revenue
                                }
                                progressData {
                                    health
                                }
                            }
                        }
                        metadata {
                            metrics {
                                impressions
                                clicks
                                spend
                                billings_local
                                ctr
                                owner_total_media_cost_local
                                owner_total_media_cost_local_ecpm
                                win_rate
                                revenue
                            }
                            progressData {
                                health
                                currentDelivered
                            }
                        }
                    }
                }
            `;
            let variables = {
                campaignId,
                flightId: flightId ? flightId : undefined,
                runTime: flightId ? undefined : 'live',
            };

            const data = await http.graphql(query, variables);

            dispatch({
                type: 'OVERVIEW__CAMPAIGN__FETCH_DATA_SUCCESS',
                payload: {
                    campaign: data.campaign,
                },
            });
        } catch (err) {
            notify({
                error: new VError(err, `failed to fetch campaign overview data for: ${campaignId}`),
            });

            throw err;
        }
    };
}

export function archiveCampaign(campaignId) {
    return async dispatch => {
        try {
            const payload = {
                isArchived: true,
            };

            await dispatch(Campaigns.update(campaignId, payload));

            await dispatch(fetchOverviewPageData(campaignId));
        } catch (err) {
            const error = new VError(err, `failed to archive campaign ${campaignId}`);

            notify({
                error,
                metaData: {
                    campaignId,
                },
            });

            throw error;
        }
    };
}

export function openArchiveConfirmation() {
    return {
        type: 'OVERVIEW__CAMPAIGN__ARCHIVE_CONFIRMATION_OPEN',
    };
}

export function closeArchiveConfirmation() {
    return {
        type: 'OVERVIEW__CAMPAIGN__ARCHIVE_CONFIRMATION_CLOSE',
    };
}

export function changeDataSelection(campaignDataShowing) {
    return {
        type: 'OVERVIEW__CAMPAIGN__CHANGE_DATA_SELECTION',
        payload: campaignDataShowing,
    };
}

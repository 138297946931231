import defaultForApi from './default-values';

function serializedForApi(draft, meta) {
    const { useId } = meta;

    const payload = {
        ...defaultForApi,
        ...draft,
    };

    if (useId) {
        // don't send email if editing
        delete payload.email;
    }

    console.log('serialized payload: ', JSON.stringify(payload, null, 4));

    return payload;
}

export default serializedForApi;

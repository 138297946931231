import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'creativesOverviewV2';
export const initialState = {
    isArchiveConfirmModalOpen: false,
    creativeToArchive: '',

    isBulkArchiveConfirmModalOpen: false,
    shouldShowArchivedCreatives: false,
    filterOptions: {
        search: '',
    },
    campaignId: '',
    creatives: [],
    ads: [],
    selectedRows: [],
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        initCreativesOverview: (state, { campaignId }) => ({
            ...state,
            campaignId,
        }),
        initSuccess: (state, { creatives, ads }) => ({
            ...state,
            creatives,
            ads,
        }),
        filterCreativeList2: (state, { campaignId, filterType, value }) => {
            return {
                ...state,
                campaignId,
                filterOptions: {
                    ...state.filterOptions,
                    [filterType]: value,
                },
            };
        },
        openArchiveConfirmModal: (state, creativeId) => ({
            ...state,
            isArchiveConfirmModalOpen: true,
            creativeToArchive: creativeId,
        }),
        openBulkArchiveConfirmModal: (state, selectedRows) => ({
            ...state,
            isBulkArchiveConfirmModalOpen: true,
        }),
        closeArchiveConfirmModal: state => ({
            ...state,
            isArchiveConfirmModalOpen: false,
            creativeToArchive: '',
        }),
        closeBulkArchiveConfirmModal: state => ({
            ...state,
            isBulkArchiveConfirmModalOpen: false,
        }),
        confirmArchiveCreative: state => ({
            ...state,
        }),
        confirmBulkArchiveCreatives: state => ({
            ...state,
        }),
        toggleArchivedAdVisibility: state => ({
            ...state,
            shouldShowArchivedCreatives: !state.shouldShowArchivedCreatives,
        }),
        setSelectedRows: (state, selectedRows) => ({
            ...state,
            selectedRows: selectedRows,
        }),
        creativeArchiveSuccess: state => ({
            ...state,
            isArchiveConfirmModalOpen: false,
            creativeToArchive: '',
        }),
        creativeBulkArchiveSuccess: state => ({
            ...state,
            selectedRows: [],
            isBulkArchiveConfirmModalOpen: false,
        }),
    },
});

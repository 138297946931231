import _ from 'lodash';

import querystring from 'utils/querystring';

export default function selector(storeState, props) {
    const businessReportDashboardState = _.get(storeState, 'businessReportDashboard');
    const businessReportsResource = _.get(storeState, 'resources.businessReports', {});
    const organizationTimezone = _.get(storeState, 'profile.organizationTimezone');

    const filters = querystring.parse(window.location.href.split('?')[1]);
    const {
        pagination,
        isDeleteConfirmationModalOpen,
        businessReportToDelete,
        allBusinessReportCount,
        myBusinessReportCount,
    } = businessReportDashboardState;

    const businessReports = _(pagination[filters.page || 1])
        .map(id => businessReportsResource[id])
        .filter(br => br)
        .map(br => br.attr)
        .value();

    return {
        businessReportDashboardState,
        businessReports,
        organizationTimezone,
        isDeleteConfirmationModalOpen,
        businessReportToDelete,
        businessReportsResource,
        allBusinessReportCount,
        myBusinessReportCount,
    };
}

import React, { useEffect } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

import { connect } from 'react-redux';

import { TableWrapper } from 'widgets-v6/table';
import { SearchInput } from 'widgets-v6/standard-input';

import actions from './actions';
import selector from './selector';

import AdminUserForm from 'packages/forms/admin-users-form';
import { default as adminUserFormActions } from 'packages/forms/admin-users-form/actions';

import AdminOrgForm from 'packages/forms/admin-org-form';
import { default as adminOrgFormActions } from 'packages/forms/admin-org-form/actions';

import DeleteUserModal from 'packages/containers/delete-user-modal';
import { default as deleteModalActions } from 'packages/containers/delete-user-modal/actions';
import { rolePresentationDictionary } from 'common/constants/role-presentation-dict';
import REVENUE_MODELS from 'common/constants/revenue-models';
import orgTypeMappings from 'common/constants/organizationTypeMappings';
import { can } from 'services/can';
import { formatNumber_currency, formatNumber_percentage } from 'utils/formatting';
import { PermissionsMapping } from 'common/constants/role-intents';

const dashboardStyles = makeStyles(theme => ({
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    sidebarContent: {
        padding: theme.spacing(1.5),
    },
    name: {
        marginRight: theme.spacing(0.5),
    },
    sidebarSection: {
        marginBottom: theme.spacing(1.5),
    },
    gridRoot: {
        height: '91%',
    },
    tableContainer: {
        height: '87%',
    },
    billingTiersTable: {
        '& > thead > tr > th': {
            border: 'none',
            verticalAlign: 'middle',
        },
        '& > tbody > tr > td': {
            border: 'none',
            verticalAlign: 'middle',
        },
    },
}));

const OwnOrganizationDashboard = ({ dispatch, organization, users }) => {
    const classes = dashboardStyles();
    useEffect(() => {
        dispatch(actions.fetchOrganizationData());
    }, []);

    const onChangeTextFilter = e => {
        const { value } = e.target;
        dispatch(actions.filterUsers(value));
    };
    return (
        <div>
            <Grid container spacing={1} className={classes.gridRoot}>
                <Grid item xs={4}>
                    {can(PermissionsMapping.ADMIN_ORG__VIEW) && (
                        <Paper>
                            <div className={classes.sidebarContent}>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    className={classes.sidebarSection}
                                >
                                    <Typography variant="h6" className={classes.name}>
                                        {organization.name}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            dispatch(adminOrgFormActions.openForm(organization.id))
                                        }
                                    >
                                        {can(PermissionsMapping.ADMIN_ORG__EDIT) && (
                                            <EditOutlinedIcon />
                                        )}
                                    </IconButton>
                                </Grid>
                                <div className={classes.sidebarSection}>
                                    <Chip
                                        label={_.startCase(orgTypeMappings[organization.type])}
                                        color="primary"
                                        size="small"
                                    />
                                </div>
                                <Grid
                                    container
                                    direction="row"
                                    className={classes.sidebarSection}
                                    spacing={2}
                                >
                                    <Grid item xs={6} xl={4}>
                                        <Typography variant="body2" color="textSecondary">
                                            Default Timezone
                                        </Typography>
                                        <Typography>{organization.default_timezone}</Typography>
                                    </Grid>
                                    <Grid item xs={6} xl={4}>
                                        <Typography variant="body2" color="textSecondary">
                                            Default Currency
                                        </Typography>
                                        <Typography>{organization.currency}</Typography>
                                    </Grid>
                                    <Grid item xs={6} xl={4}>
                                        <Typography variant="body2" color="textSecondary">
                                            Default Revenue Model
                                        </Typography>
                                        <Typography>
                                            {REVENUE_MODELS[organization.revenueModel]}
                                        </Typography>
                                    </Grid>
                                    {organization.type === 'media_buyer' && (
                                        <Grid item xs={6} xl={3}>
                                            <Typography variant="body2" color="textSecondary">
                                                {'Current Platform Fee'}
                                            </Typography>
                                            <Typography>
                                                {`${(organization.tech_fee * 100).toFixed(2)}%`}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                {organization.type === 'media_buyer' && (
                                    <Box mt={2}>
                                        <Box mb={1}>
                                            <Typography variant="body2" color="textSecondary">
                                                {' '}
                                                Private Deal Tiers{' '}
                                            </Typography>
                                            <Table
                                                size="small"
                                                className={classes.billingTiersTable}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Min</TableCell>
                                                        <TableCell>Max</TableCell>
                                                        <TableCell>Platform Fee</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {_.map(
                                                        organization.privateInventorySpendTiers,
                                                        tier => (
                                                            <TableRow>
                                                                <TableCell>
                                                                    {formatNumber_currency(
                                                                        tier.min
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {tier.max === -1
                                                                        ? 'Infinity'
                                                                        : formatNumber_currency(
                                                                              tier.max
                                                                          )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatNumber_percentage(
                                                                        tier.techFee
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                        <Box mb={1}>
                                            <Typography variant="body2" color="textSecondary">
                                                {' '}
                                                Public Inventory Tiers{' '}
                                            </Typography>
                                            <Table
                                                size="small"
                                                className={classes.billingTiersTable}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Min</TableCell>
                                                        <TableCell>Max</TableCell>
                                                        <TableCell>Platform Fee</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {_.map(
                                                        organization.publicInventorySpendTiers,
                                                        tier => (
                                                            <TableRow>
                                                                <TableCell>
                                                                    {formatNumber_currency(
                                                                        tier.min
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {tier.max === -1
                                                                        ? 'Infinity'
                                                                        : formatNumber_currency(
                                                                              tier.max
                                                                          )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatNumber_percentage(
                                                                        tier.techFee
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                )}
                            </div>
                        </Paper>
                    )}
                </Grid>
                <Grid item xs={8}>
                    <Grid container className={classes.marginBottom}>
                        <Grid item xs={6}>
                            {can(PermissionsMapping.ADMIN_USER__VIEW) && (
                                <SearchInput
                                    onChange={onChangeTextFilter}
                                    placeholder="Search User"
                                />
                            )}{' '}
                        </Grid>
                    </Grid>
                    {can(PermissionsMapping.ADMIN_USER__VIEW) && (
                        <TableWrapper
                            title="Users"
                            actions={
                                can(PermissionsMapping.ADMIN_USER__EDIT) && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() =>
                                            dispatch(
                                                adminUserFormActions.openForm(null, organization.id)
                                            )
                                        }
                                    >
                                        New User
                                    </Button>
                                )
                            }
                        >
                            <div className={classes.tableContainer}>
                                <Table size="small" stickyHeader className={classes.marginBottom}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox" />
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell padding="checkbox" />
                                            <TableCell padding="checkbox" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(
                                            users,
                                            user =>
                                                (!_.includes(organization.name, 'Self Serve') ||
                                                    (_.includes(organization.name, 'Self Serve') &&
                                                        !_.includes(
                                                            user.email,
                                                            'pelmorex.com'
                                                        ))) && (
                                                    <TableRow key={user.id}>
                                                        <TableCell />
                                                        <TableCell>{`${user.first_name} ${
                                                            user.last_name
                                                        }`}</TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>
                                                            {rolePresentationDictionary[
                                                                user.global_role
                                                            ] || 'Unknown'}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    dispatch(
                                                                        adminUserFormActions.openForm(
                                                                            user.id,
                                                                            organization.id
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                {can('admin_user__edit') && (
                                                                    <EditOutlinedIcon />
                                                                )}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    dispatch(
                                                                        deleteModalActions.open(
                                                                            user.id
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                {can('admin_user__edit') && (
                                                                    <DeleteOutlineIcon />
                                                                )}
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                    </TableBody>
                                </Table>
                                {users.length === 0 && (
                                    <Typography align="center">No Users Found</Typography>
                                )}
                            </div>
                        </TableWrapper>
                    )}
                </Grid>
            </Grid>
            <DeleteUserModal refreshUsers={() => dispatch(actions.fetchOrganizationData())} />
            <AdminUserForm refreshUsers={() => dispatch(actions.fetchOrganizationData())} />
            <AdminOrgForm refreshOrganization={() => dispatch(actions.fetchOrganizationData())} />
        </div>
    );
};

export default connect(selector)(OwnOrganizationDashboard);

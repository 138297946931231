import toastr from 'toastr';

import { createHttp } from 'utils/http';
import flags from 'containers/flags/service';

const source_type = ['pds_mastercard'];

const actions = {
    init: () => {
        return async dispatch => {
            dispatch({
                type: 'MASTERCARD_DASHBOARD__INIT_START',
            });
            const { totalSegmentCount } = await dispatch(actions.getAudiences());

            dispatch({
                type: 'MASTERCARD_DASHBOARD__INIT_END',
                payload: { totalSegmentCount },
            });
        };
    },
    getAudiences: keywords => {
        return async (dispatch) => {
            try {
                const http = createHttp();
                let query;
                if (flags.isEnabled('en_2987_use_audience_category_and_subCategory_code')) {
                    query = `
                    query getAudiences ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                            id
                            name
                            description
                            lastRefreshed
                            dataCost
                            subCategory
                            uniques
                            status
                            match_rate
                            tier
                            supports
                        }
                    }
                `;
                } else {
                    query = `
                    query getAudiences ($keywords: String, $source_type: [String], $deprecated: Boolean) {
                        audienceSegments(filters: { keywords: $keywords, source_type: $source_type, deprecated: $deprecated }) {
                            id
                            name
                            description
                            lastRefreshed
                            dataCost
                            subType
                            uniques
                            status
                            match_rate
                            tier
                            supports
                        }
                    }
                `;
                }

                const variables = {
                    keywords,
                    source_type,
                    deprecated: false,
                };

                const data = await http.graphql(query, variables);
                dispatch({
                    type: 'MASTERCARD_DASHBOARD__FETCH_SEGMENTS_DATA__END',
                    payload: {
                        data: data.audienceSegments,
                    },
                });

                return {
                    segments: data.audienceSegments,
                    totalSegmentCount: data.audienceSegments.length,
                };
            } catch (err) {
                toastr.error('Audience segments failed to be fetched. Please try it again later.');
            }
        };
    },
};

export default actions;

import concat from 'lodash/concat';
import each from 'lodash/each';
import fill from 'lodash/fill';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment';
import 'moment-timezone';
import numeral from 'numeral';
import React, { Component } from 'react';
import reduce from 'lodash/reduce';
import tools from 'utils/tools';
/** import third-party libraries in the section above, keep the ascending order */

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
/** import mui components in the section above, keep the ascending order */

import { IncludeAndExcludeSummary } from 'widgets-v5/include-and-exclude';
import { SummaryLayout, SummaryGroup } from 'widgets/summary-view/summary-layout';

import Spacer from 'widgets-v5/spacer';
import SummaryTypeComponent from 'widgets/summary-view/summary-type-component';
import SummaryTypeGeoTargeting from 'widgets/summary-view/summary-type-geo-targeting';
import SummaryTypeText from 'widgets/summary-view/summary-type-text';
import SummaryTypeTextCollection from 'widgets/summary-view/summary-type-text-collection';
/** import widgets components in the section above, keep the ascending order */

import { BodyBold, Caption } from 'widgets-v5/typography';
import {
    canAdPresetFieldBeUsed,
    couldAdPresetHaveRestrictions,
} from 'states/resources/ad-presets/business-logic';
import { DeviceOSMapping } from 'states/resources/ads/business-logic';

import APPCATEGORY_TARGETING from 'common/constants/app-category-targeting';
import AudienceOptions from 'containers/audience-options';
import CARRIER_TARGETING from 'common/constants/carrier-targeting';
import LANGUAGE_CODES from 'common/constants/language-codes';
/** import classes and others in the section above, keep the ascending order */

const getAgeGroups = values => {
    // Reuse code from v2
    function _formatAgeGroup(val) {
        if (!val) return '';

        let out;
        if (val.length === 0 || val.length === 8) {
            out = ['Everyone'];
        } else {
            var displayTextArray = [];
            forEach(val, function(item) {
                switch (item) {
                    case 1:
                        displayTextArray.push('13-18');
                        break;
                    case 2:
                        displayTextArray.push('19-24');
                        break;
                    case 3:
                        displayTextArray.push('25-34');
                        break;
                    case 4:
                        displayTextArray.push('35+');
                        break;
                    case 5:
                        displayTextArray.push('35-44');
                        break;
                    case 6:
                        displayTextArray.push('45-54');
                        break;
                    case 7:
                        displayTextArray.push('55-64');
                        break;
                    case 8:
                        displayTextArray.push('65+');
                        break;
                    case -1:
                        displayTextArray.push('Unknown');
                        break;
                    default:
                        throw new Error(
                            'Age group array is not empthy and contains unregonize value'
                        );
                }
                out = displayTextArray;
            });
        } // End else
        return out;
    }

    return _formatAgeGroup(values);
};

const getGenders = values => {
    // Reuse code from v2
    function _formatTargetGenders(val) {
        if (isArray(val)) {
            if (val.length === 0 || val.length === 3) {
                return 'Everyone';
            } else {
                var displayTextArray = [];
                forEach(val, function(item) {
                    if (item === 'M') {
                        displayTextArray.push('Male');
                    } else if (item === 'F') {
                        displayTextArray.push('Female');
                    } else if (item === 'D') {
                        displayTextArray.push('Unknown');
                    } else {
                        throw new Error('Gender array is not empthy and contains unregonize value');
                    }
                });
                return displayTextArray.join(', ');
            } // End else
        }
    }

    return _formatTargetGenders(values);
};

const getDeviceOs = values => {
    function _formatDeviceOs(val) {
        var displayTextArray = [];
        if (isArray(val)) {
            if (val.length === 0) {
                displayTextArray = [
                    DeviceOSMapping.IOS,
                    DeviceOSMapping.Android,
                    DeviceOSMapping.BlackBerry,
                ];
                return displayTextArray.join(', ');
            } else {
                return val.join(', ');
            } // End else
        }
    }
    return _formatDeviceOs(values);
};

export const getDayPart = (dayparts, timezone, adStart) => {
    if (dayparts.value.length < 1) return ['Full Day'];

    const formattedTimezone = moment(adStart)
        .tz(timezone)
        .format('z');
    const dateAsYearMonthDay = adStart.slice(0, 10);

    const createOutputString = (start, end) =>
        `${start.hour}:${start.minute} ~ ${end.hour}:${end.minute} ${
            dayparts.field === 'dayparts' ? formattedTimezone : 'Local Time'
        }`;

    function createMergedTimeranges(timeranges) {
        function getSelectedTimeBlocks(_dayparts, _timezone) {
            const selectedTimeblocks = [];
            selectedTimeblocks.length = 96;
            fill(selectedTimeblocks, false);

            _dayparts.forEach(daypart => {
                const startMoment = moment
                    .utc(`${dateAsYearMonthDay}T${daypart.start}Z`)
                    .tz(_timezone);
                const endMoment = moment.utc(`${dateAsYearMonthDay}T${daypart.end}Z`).tz(_timezone);

                const startIndex =
                    startMoment.hours() * 4 +
                    (startMoment.minutes() >= 15 ? 1 : 0) +
                    (startMoment.minutes() >= 30 ? 1 : 0) +
                    (startMoment.minutes() >= 45 ? 1 : 0);

                const endIndex =
                    endMoment.hours() * 4 +
                    (endMoment.minutes() >= 15 ? 1 : 0) +
                    (endMoment.minutes() >= 30 ? 1 : 0) +
                    (endMoment.minutes() >= 45 ? 1 : 0);

                fill(selectedTimeblocks, true, startIndex, endIndex + 1);
            });
            return selectedTimeblocks;
        }

        function getContinuousTrueValues(array) {
            const startEndPairs = [];
            let trueStartIndex = null;
            let inTrueRange = false;
            array.forEach((value, index) => {
                if (value && !inTrueRange) {
                    trueStartIndex = index;
                    inTrueRange = true;
                }
                if (!value && inTrueRange) {
                    startEndPairs.push({
                        start: trueStartIndex,
                        end: index - 1,
                    });
                    trueStartIndex = null;
                    inTrueRange = false;
                }
                // If last item
                if (index === array.length - 1 && inTrueRange) {
                    startEndPairs.push({
                        start: trueStartIndex,
                        end: index,
                    });
                }
            });
            return startEndPairs;
        }

        function createTimeBlocks() {
            const timeblocks = [];
            for (let i = 0; i < 24; i++) {
                const blocks = [
                    {
                        label: `${i}`,
                        start: { hour: i, minute: '00', second: '00' },
                        end: { hour: i, minute: '14', second: '59' },
                    },
                    {
                        label: '',
                        start: { hour: i, minute: '15', second: '00' },
                        end: { hour: i, minute: '29', second: '59' },
                    },
                    {
                        label: '',
                        start: { hour: i, minute: '30', second: '00' },
                        end: { hour: i, minute: '44', second: '59' },
                    },
                    {
                        label: '',
                        start: { hour: i, minute: '45', second: '00' },
                        end: { hour: i, minute: '59', second: '59' },
                    },
                ];
                timeblocks.push(...blocks);
            }
            return timeblocks;
        }

        function make2Digits(n) {
            n.hour = n.hour > 9 ? '' + n.hour : '0' + n.hour;
            return n;
        }

        const selectedTimeblocks = getSelectedTimeBlocks(timeranges, timezone);
        const startEndIndexes = getContinuousTrueValues(selectedTimeblocks);
        const timeBlocksWithTimes = createTimeBlocks();

        const startEndBlocks = startEndIndexes.map(({ start: startIndex, end: endIndex }) => ({
            start: make2Digits(timeBlocksWithTimes[startIndex].start),
            end: make2Digits(timeBlocksWithTimes[endIndex].end),
        }));

        return startEndBlocks;
    }

    const mergedTimeranges = createMergedTimeranges(dayparts.value);
    const formattedRanges = map(mergedTimeranges, item => createOutputString(item.start, item.end));
    return formattedRanges;
};

const getDeviceLanguage = obj => {
    if (isArray(obj) && isEmpty(obj)) {
        return 'All Languages';
    }
    return isArray(obj) ? map(obj, item => LANGUAGE_CODES[item]).join(', ') : 'All Languages';
};

const getAppCat = values => {
    if (isArray(values) && isEmpty(values)) {
        return 'All Categories';
    }
    return isArray(values)
        ? map(values, function(i) {
              return APPCATEGORY_TARGETING[i];
          }).join(', ')
        : 'All Categories';
};

const getGeofencingOld = obj => {
    if (!obj) return [];

    return obj.map(function(box) {
        var shorter = {};
        each(box, function(val, key) {
            if (key !== 'type') {
                shorter[key] = val.toFixed(2);
            }
        });
        return `E: ${shorter.E}, W: ${shorter.W}, S: ${shorter.S}, N: ${shorter.N}`;
    });
};

const getTargetCarriers = obj => {
    if (isArray(obj) && isEmpty(obj)) {
        return 'All Carriers';
    }
    return isArray(obj)
        ? map(obj, function(i) {
              return CARRIER_TARGETING[i];
          }).join(', ')
        : 'All Carriers';
};

const format$ = val => {
    if (tools.isLikeNumberComma(val)) {
        return numeral(val).format('$0,0.00[000000]');
    } else {
        return val;
    }
};

function getMaxUserFrequency(value) {
    if (value === 0) {
        return 'Unlimited';
    }

    return value;
}

function formatFormData(formData, self) {
    return reduce(
        formData,
        (result, value, key) => {
            switch (key) {
                case 'max_cpm_rate_local':
                    result[key] = format$(value);
                    break;
                case 'max_user_frequency':
                    result[key] = getMaxUserFrequency(value);
                    break;
                case 'geoboxes':
                    result[key] = getGeofencingOld(value);
                    break;
                case 'geo_targeting_settings':
                    result[key] = self.getGeofencing(value);
                    break;
                case 'target_device_os':
                    result[key] = getDeviceOs(value);
                    break;
                case 'target_carriers':
                    result[key] = getTargetCarriers(value);
                    break;
                case 'target_device_language':
                    result[key] = getDeviceLanguage(value);
                    break;
                case 'target_app_categories':
                    result[key] = getAppCat(value);
                    break;
                case 'target_genders':
                    result[key] = getGenders(value);
                    break;
                case 'target_age_groups':
                    result[key] = getAgeGroups(value);
                    break;
                case 'target_app_store_cat_exclude':
                case 'target_app_store_cat':
                    result[key] = []
                        .concat(
                            formData.target_app_store_cat_exclude,
                            formData.target_app_store_cat
                        )
                        .join(', ');
                    break;
                default:
                    result[key] = value;
            }
            return result;
        },
        {}
    );
}

class AdSummary extends Component {
    getAudiences = audiences => {
        const { audienceResources } = this.props;

        const out = map(audiences, audienceId => get(audienceResources, audienceId))
            .filter(audience => !!audience)
            .map(audience => audience.attr.name)
            .join(', ');

        return out;
    };

    getGeofencing = obj => {
        if (!obj) return [];

        const { categories, custom_layers, category_layers } = obj;

        const unassignedPoints = get(this.props, 'geoCategories.unassignedPoints', []);

        // Explicitly handle the case where targeting is effectively disabled:
        if (
            categories.length === 0 &&
            custom_layers.length === 0 &&
            category_layers.length === 0 &&
            unassignedPoints.length === 0
        ) {
            return [];
        }

        const adGeoCategoryLayers = createSummaryString(
            [].concat(categories),
            'POI Category',
            'POI Categories'
        );
        const adGeoLayers = createSummaryString(
            [].concat(category_layers),
            'Standard POI Segment',
            'Standard POI Segments'
        );
        const adCustomLayers = createSummaryString(
            custom_layers,
            'Custom POI Segment',
            'Custom POI Segments'
        );

        return [].concat(
            adGeoCategoryLayers,
            adGeoLayers,
            adCustomLayers,
            `Retarget - ${obj.retarget}`,
            `Target - ${obj.target}`
        );

        function createSummaryString(list, name, pluralName) {
            if (!list.length) return [];
            return `${list.length} ${list.length > 1 ? pluralName : name}`;
        }
    };

    tooltipUnavailableField(adPlatforms) {
        return (
            <Tooltip
                title={
                    <Typography variant="body2">
                        This field is not compatible with {adPlatforms.join(', ')} platforms
                    </Typography>
                }
                placement="left"
                style={{ color: 'gray' }}
            >
                <i className="fa fa-question-circle" />
            </Tooltip>
        );
    }

    tooltipPartialField(adPlatforms) {
        return (
            <Tooltip
                title={
                    <Typography variant="body2">
                        Only values compatible with {adPlatforms.join(', ')} will be applied
                    </Typography>
                }
                placement="left"
                style={{ color: 'gray' }}
            >
                <i className="fa fa-info-circle" />
            </Tooltip>
        );
    }

    getFieldTooltip(adPresetMayHaveRestrictions, adPlatforms, field) {
        const isFieldAvailable = adPresetMayHaveRestrictions
            ? canAdPresetFieldBeUsed(adPlatforms, field)
            : true;

        if (!isFieldAvailable) {
            return this.tooltipUnavailableField(adPlatforms);
        }

        if (
            field === 'target_device_os' &&
            (adPlatforms.includes('ctv') || adPlatforms.includes('dooh'))
        ) {
            return this.tooltipPartialField(adPlatforms);
        }

        return null;
    }

    render() {
        const { formData, shouldHideFlexSegments, adPlatforms } = this.props;
        if (!formData) {
            return <div>loading... </div>;
        }
        const self = this;
        const formatedFormData = formatFormData(formData, self);
        const adPresetMayHaveRestrictions = couldAdPresetHaveRestrictions(adPlatforms);

        return (
            <SummaryLayout hiddinHeaderText={this.props.hiddinHeaderText} title="Summary">
                <BodyBold>
                    {formData.ad_preset_name ? formData.ad_preset_name : <span>&nbsp;</span>}
                </BodyBold>
                <Spacer type="small" />
                {adPresetMayHaveRestrictions && (
                    <Caption type="info">
                        <i className="fa fa-info-circle" /> Some properties of this ad preset won't
                        be applied due to incompatibily with {adPlatforms.join(', ')} platforms
                    </Caption>
                )}
                <Spacer type="small" />
                {map(formatedFormData.ad_preset_properties, (group, index) => {
                    const summaryList = map(
                        filter(group.properties, property => property.checked === true),
                        (prop, index) => {
                            switch (prop.key) {
                                case 'geotargets': {
                                    const tooltip = this.getFieldTooltip(
                                        adPresetMayHaveRestrictions,
                                        adPlatforms,
                                        prop.key
                                    );

                                    return (
                                        <SummaryTypeGeoTargeting
                                            key={prop.key + index}
                                            keyName="Geotargeting"
                                            value={formatedFormData.geotargets}
                                            strikethroughText={!!tooltip}
                                            tooltip={tooltip}
                                        />
                                    );
                                }
                                case 'geoboxes':
                                case 'geo_targeting_settings':
                                case 'target_age_groups': {
                                    const tooltip = this.getFieldTooltip(
                                        adPresetMayHaveRestrictions,
                                        adPlatforms,
                                        prop.key
                                    );

                                    return (
                                        <SummaryTypeTextCollection
                                            key={prop.key + index}
                                            keyName={prop.name}
                                            values={formatedFormData[prop.key]}
                                            strikethroughText={!!tooltip}
                                            tooltip={tooltip}
                                        />
                                    );
                                }
                                case 'audiences':
                                    const { geo_targeting_settings } = formData;
                                    const categoryLayers = get(
                                        geo_targeting_settings,
                                        'category_layers'
                                    );
                                    const categories = get(geo_targeting_settings, 'categories');
                                    const customLayers = get(
                                        geo_targeting_settings,
                                        'customLayers'
                                    );
                                    const includedFlexSegments = concat(
                                        formData.audiences,
                                        categoryLayers,
                                        categories,
                                        customLayers
                                    ).filter(item => item);

                                    const tooltip = this.getFieldTooltip(
                                        adPresetMayHaveRestrictions,
                                        adPlatforms,
                                        prop.key
                                    );

                                    return (
                                        <SummaryTypeComponent
                                            key="Audience Segments"
                                            keyName="Audience Segments"
                                            value={
                                                <AudienceOptions
                                                    shouldHideFlexSegments={shouldHideFlexSegments}
                                                >
                                                    {({ mapping, flexMapping }) => {
                                                        const mappedAudiences = map(
                                                            formData.audiences,
                                                            value => mapping[value]
                                                        );
                                                        const mappedFlexSegments = map(
                                                            includedFlexSegments,
                                                            value => flexMapping[value]
                                                        );
                                                        return (
                                                            <IncludeAndExcludeSummary
                                                                includedItems={concat(
                                                                    mappedAudiences,
                                                                    mappedFlexSegments
                                                                )}
                                                                excludedItems={map(
                                                                    formData.audience_exclude,
                                                                    value => mapping[value]
                                                                )}
                                                            />
                                                        );
                                                    }}
                                                </AudienceOptions>
                                            }
                                            strikethroughText={!!tooltip}
                                            tooltip={tooltip}
                                        />
                                    );
                                default: {
                                    const tooltip = this.getFieldTooltip(
                                        adPresetMayHaveRestrictions,
                                        adPlatforms,
                                        prop.key
                                    );

                                    return (
                                        <SummaryTypeText
                                            key={prop.key + index}
                                            keyName={prop.name}
                                            value={formatedFormData[prop.key]}
                                            strikethroughText={!!tooltip}
                                            tooltip={tooltip}
                                        />
                                    );
                                }
                            }
                        }
                    );

                    if (!summaryList.length) {
                        return;
                    }

                    return (
                        <SummaryGroup key={group.groupName + index} groupName={group.groupName}>
                            {summaryList}
                        </SummaryGroup>
                    );
                })}
            </SummaryLayout>
        );
    }
}

export default AdSummary;

import _ from 'lodash';
import { calcTotalStats } from 'services/resources/stats';

export default function selector(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    const isLoading = _.get(storeState, `campaignAccess.isLoading`, true);
    const ownerName = _.get(storeState, `campaignAccess.ownerName`);
    const accessGranted = _.get(storeState, `campaignAccess.accessGranted`);

    const campaignStats = _.get(
        storeState,
        `resources.stats.campaign.${campaignId}.standardTotal`,
        []
    );
    const campaignStatsIsLoading = _.get(
        storeState,
        `resources.stats.campaign.${campaignId}.isLoading`,
        false
    );
    const totalStats = calcTotalStats([].concat(campaignStats));

    return {
        campaign,
        ownerName,
        loading: isLoading,
        accessGranted,
    };
}

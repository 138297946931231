import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { AccentButton, NeutralButton } from 'widgets-v5/buttons';

const dataTablePropTypes = {
    className: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.node),
    rows: PropTypes.arrayOf(PropTypes.node),
    rowClassNames: PropTypes.string,
    columnClassNames: PropTypes.arrayOf(PropTypes.string),
    finalRow: PropTypes.array,
    totalRow: PropTypes.node,
    footer: PropTypes.node,
    stackable: PropTypes.bool,
    condensed: PropTypes.bool,
    responsive: PropTypes.oneOf([
        'mobile-only',
        'mobile-tablet-only',
        'tablet-only',
        'desktop-only',
        'mobile-and-tablet-only',
        'tablet-and-desktop-only',
        'all',
    ]),
    activeRow: PropTypes.bool,
};

class DataTable extends React.Component {
    static propTypes = dataTablePropTypes;

    static defaultProps = {
        columnClassNames: [],
        headers: [],
        responsive: 'all',
        // width: 300
    };

    getColspan = (columnIndex, row) => {
        let i = columnIndex + 1;
        while (row[i] === null) {
            i++;
        }

        const colspan = i - columnIndex;

        return colspan;
    };

    getRowspan = (rowIndex, columnIndex, rows) => {
        var i = rowIndex + 1;
        var nextRow = rows[i];

        while (nextRow && nextRow[columnIndex] === null) {
            i++;
            nextRow = rows[i];
        }

        var rowspan = i - rowIndex;

        return rowspan;
    };

    render() {
        return (
            <ComposableDataTable className={this.props.className} footer={this.props.footer}>
                {this.props.headers && (
                    <Row key="header" header>
                        {_.map(this.props.headers, (col, i) => {
                            if (col === null) {
                                return null;
                            }
                            return (
                                <Cell
                                    key={i}
                                    className={this.props.columnClassNames[i]}
                                    colSpan={this.getColspan(i, this.props.headers)}
                                    fixedFirstColumn={this.props.fixedFirstColumn && i === 0}
                                    bordered={this.props.bordered}
                                    align={this.props.align}
                                    width={this.props.width}
                                    style={this.props.style}
                                >
                                    {col}
                                </Cell>
                            );
                        })}
                    </Row>
                )}
                {this.props.rows.length === 0 && (
                    <Row key="nodata" noData className={this.props.rowClassNames}>
                        <Cell noData colSpan={this.props.headers ? this.props.headers.length : 1}>
                            No Data
                        </Cell>
                    </Row>
                )}
                {this.props.totalRow && (
                    <Row key="totalRow" totalRow>
                        {this.props.totalRow.map((col, cIndex) => {
                            if (col === null) {
                                return null;
                            }
                            return (
                                <Cell
                                    key={cIndex}
                                    className={this.props.columnClassNames[cIndex]}
                                    colSpan={this.getColspan(cIndex, this.props.totalRow)}
                                    fixedFirstColumn={this.props.fixedFirstColumn && cIndex === 0}
                                    bordered={this.props.bordered}
                                    align={this.props.align}
                                    width={this.props.width}
                                    style={this.props.style}
                                >
                                    {col}
                                </Cell>
                            );
                        })}
                    </Row>
                )}
                {this.props.rows.map((row, rowIndex) => (
                    <Row className={this.props.rowClassNames} key={rowIndex}>
                        {row.map((col, columnIndex) => {
                            if (col === null) {
                                return null;
                            }

                            return (
                                <Cell
                                    key={columnIndex}
                                    className={this.props.columnClassNames[columnIndex]}
                                    rowSpan={this.getRowspan(
                                        rowIndex,
                                        columnIndex,
                                        this.props.rows
                                    )}
                                    colSpan={this.getColspan(columnIndex, row)}
                                    fixedFirstColumn={
                                        this.props.fixedFirstColumn && columnIndex === 0
                                    }
                                    bordered={this.props.bordered}
                                    align={this.props.align}
                                    width={this.props.width}
                                    style={this.props.style}
                                >
                                    {this.props.stackable && (
                                        <MobileHeader>
                                            {this.props.headers[columnIndex]}
                                        </MobileHeader>
                                    )}
                                    <CellBody>{row[columnIndex]}</CellBody>
                                </Cell>
                            );
                        })}
                    </Row>
                ))}
                {this.props.finalRow && (
                    <Row key="finalrow">
                        {this.props.finalRow.map((col, cIndex) => {
                            if (col === null) {
                                return null;
                            }
                            return (
                                <Cell
                                    key={cIndex}
                                    className={this.props.columnClassNames[cIndex]}
                                    colSpan={this.getColspan(cIndex, this.props.finalRow)}
                                    fixedFirstColumn={this.props.fixedFirstColumn && cIndex === 0}
                                    bordered={this.props.bordered}
                                    align={this.props.align}
                                    width={this.props.width}
                                    style={this.props.style}
                                >
                                    {col}
                                </Cell>
                            );
                        })}
                    </Row>
                )}
            </ComposableDataTable>
        );
    }
}

export class ComposableDataTable extends React.Component {
    onScroll = handler => {
        if (!this.handlers) {
            this.handlers = [];
        }
        this.handlers.push(handler);
    };

    offScroll = handler => {
        this.handlers = this.handlers.filter(h => h === handler);
    };

    handleScroll = e => {
        requestAnimationFrame(() => {
            _.each(this.handlers, handler => handler(e));
        });
    };

    componentDidMount() {
        if (this.props.scrollable) {
            this.table.addEventListener('scroll', this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (this.props.scrollable) {
            this.table.removeEventListener('scroll', this.handleScroll);
        }
    }

    render() {
        const { scrollable, children, footer } = this.props;
        const data = {
            onScroll: this.onScroll,
            offScroll: this.offScroll,
        };
        return (
            <div
                ref={table => (this.table = table)}
                className={cn('ef4-data-table', this.props.className, {
                    'ef4-data-table--fixed': scrollable,
                })}
            >
                <table
                    className={cn('ef4-data-table__table', {
                        dragscroll: scrollable,
                        'ef4-data-table--fixed': scrollable,
                        'ef5-stackable-data-table': this.props.stackable,
                        'ef4-data-table--condensed': this.props.condensed,
                        'ef4-data-table--bordered': this.props.bordered,
                        'ef4-data-table--overflow': this.props.overflow,
                    })}
                >
                    <Tbody>{_.isFunction(children) ? children(data) : children}</Tbody>
                </table>
                {footer && <Footer>{footer}</Footer>}
            </div>
        );
    }
}
export function Tbody(props) {
    return <tbody>{props.children}</tbody>;
}

function Footer(props) {
    return <div className="ef4-data-table__footer">{props.children}</div>;
}
export function MobileHeader(props) {
    return (
        <span className={cn(props.className, 'ef5-stackable-data-table__mobile-header')}>
            {props.children}
        </span>
    );
}
export function CellBody(props) {
    return (
        <span className={cn(props.className, 'ef5-stackable-data-table__body')}>
            {props.children}
        </span>
    );
}
export function Row(props) {
    // const responsiveClass = `ef4-data-table__responsive-row--${this.props.responsive}`;
    return (
        <tr
            onClick={props.onClick}
            className={cn(props.className, {
                'ef4-data-table__total-row': props.totalRow,
                'ef4-data-table__data-row': !props.header,
                'ef4-data-table__header-row': props.header,
                'ef4-data-table__data-row_no-data': props.noData,
                'ef4-data-table--condensed-row': props.condensed,
                // `ef4-data-table__responsive-row--${this.props.responsive}`,
                'ef4-data-table__data-row--disabled': props.disabled,
                'ef4-data-table__data-row--selectable': props.selectable,
                'ef4-data-table__data-row--active': props.activeRow,
                'ef4-data-table__data-row--borderless': props.borderless,
                'ef4-data-table__data-row--hasError': props.error,
            })}
        >
            {props.children}
        </tr>
    );
}

export class Cell extends React.Component {
    handleScroll = e => {
        if (this.fixedContent) {
            this.fixedContent.style.left = e.target.scrollLeft - 1;
        }
    };

    componentDidMount() {
        if (this.props.fixedFirstColumn) {
            this.props.onScroll(this.handleScroll);
        }
    }

    componentWillUnmount() {
        if (this.props.fixedFirstColumn) {
            this.props.offScroll(this.handleScroll);
        }
    }
    render() {
        const { fixedFirstColumn } = this.props;

        return (
            <td
                style={this.props.style}
                rowSpan={this.props.rowSpan}
                colSpan={this.props.colSpan}
                onClick={this.props.onClick}
                className={cn(this.props.className, {
                    'ef4-data-table__data-cell': true,
                    'ef4-data-table__data-cell-fixed': fixedFirstColumn,
                    'ef4-data-table__data-cell_no-data': this.props.noData,
                    'ef4-data-table__header-cell': this.props.header,
                    'ef4-data-table__data-cell-bordered': this.props.bordered,
                    'ef4-data-table__data-cell--disabled': this.props.disabled,
                    'ef4-data-table__data-cell--no-padding': this.props.noPadding,
                    [`ef4-data-table__data-cell-align-${this.props.align}`]: this.props.align,
                })}
            >
                <div
                    style={{ width: `${this.props.width}` }}
                    className={cn({
                        'ef4-data-table__content-wrapper-fixed': fixedFirstColumn,
                        'ef4-data-table__content-wrapper': !fixedFirstColumn,
                    })}
                >
                    {fixedFirstColumn && (
                        <div
                            className="ef4-data-table__fixed-content"
                            ref={fixedContent => (this.fixedContent = fixedContent)}
                        >
                            {this.props.children}
                        </div>
                    )}
                    <div className="ef4-data-table__content">{this.props.children}</div>
                </div>
            </td>
        );
    }
}

class CheckboxDataTable extends React.Component {
    static propTypes = {
        ...dataTablePropTypes,
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                data: PropTypes.arrayOf(PropTypes.node).isRequired,
            })
        ),
        onChange: PropTypes.func,
        value: PropTypes.arrayOf(PropTypes.string),
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        onChange: () => {},
        value: [],
        columnClassNames: [],
    };

    onSelectRow = value => {
        if (this.props.value.includes(value)) {
            const newValue = this.props.value.filter(item => item !== value);
            this.props.onChange(newValue);
        } else {
            const newValue = [].concat(this.props.value, value);
            this.props.onChange(newValue);
        }
    };

    render() {
        // Add classnames to first and second columns
        const [
            firstColumnClassName,
            secondColumnClassName,
            ...otherColumnClassNames
        ] = this.props.columnClassNames;
        const columnClassNames = [
            cn('ef4-checkbox-data-table__selector-cell', firstColumnClassName),
            cn('ef4-checkbox-data-table__leader-cell', secondColumnClassName),
        ].concat(otherColumnClassNames);

        return (
            <DataTable
                {...this.props}
                className={cn('ef4-checkbox-data-table', this.props.className)}
                headers={[''].concat(this.props.headers)}
                columnClassNames={columnClassNames}
                totalRow={this.props.totalRow}
                finalRow={this.props.finalRow}
                activeRow={this.props.activeRow}
                rows={this.props.rows.map((row, index) =>
                    [
                        <label key={index} className="ef4-checkbox-data-table__selector-click-area">
                            <input
                                type="checkbox"
                                className="ef4-checkbox-data-table__selector"
                                checked={this.props.value.includes(row.value)}
                                disabled={this.props.disabled}
                                onChange={() => this.onSelectRow(row.value)}
                            />
                        </label>,
                    ].concat(row.data)
                )}
            />
        );
    }
}

class RadioDataTable extends React.Component {
    static propTypes = {
        ...dataTablePropTypes,
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                data: PropTypes.arrayOf(PropTypes.node).isRequired,
            })
        ),
        onChange: PropTypes.func,
        value: PropTypes.string,
    };

    static defaultProps = {
        onChange: () => {},
        value: null,
        columnClassNames: [],
    };

    onSelectRow = value => {
        this.props.onChange(value);
    };

    UNSAFE_componentWillMount() {
        this.randomId = Date.now().toString();
    }

    render() {
        // Add classnames to first and second columns
        const [
            firstColumnClassName,
            secondColumnClassName,
            ...otherColumnClassNames
        ] = this.props.columnClassNames;
        const columnClassNames = [
            cn('ef4-radio-data-table__selector-cell', firstColumnClassName),
            cn('ef4-radio-data-table__leader-cell', secondColumnClassName),
        ].concat(otherColumnClassNames);

        return (
            <DataTable
                {...this.props}
                className={cn('ef4-radio-data-table', this.props.className)}
                headers={[''].concat(this.props.headers)}
                columnClassNames={columnClassNames}
                totalRow={this.props.totalRow}
                finalRow={this.props.finalRow}
                activeRow={this.props.activeRow}
                rows={this.props.rows.map((row, index) =>
                    [
                        <label key={index} className="ef4-radio-data-table__selector-click-area">
                            <input
                                type="radio"
                                name={this.randomId}
                                className="ef4-radio-data-table__selector"
                                checked={this.props.value === row.value}
                                onChange={() => this.onSelectRow(row.value)}
                            />
                        </label>,
                    ].concat(row.data)
                )}
            />
        );
    }
}

class InputDataTable extends React.Component {
    static propTypes = {
        ...dataTablePropTypes,
        onAddRow: PropTypes.func,
        onRemoveRow: PropTypes.func,
    };

    render() {
        const headers = [].concat(this.props.headers, [
            <AccentButton
                key="add"
                className="ef5-input-data-table__add-row-button"
                text="Add"
                onClick={this.props.onAddRow}
            />,
        ]);

        const rows = this.props.rows.map((row, i) =>
            [].concat(row, [
                <NeutralButton key={i} text="Remove" onClick={() => this.props.onRemoveRow(i)} />,
            ])
        );

        return (
            <DataTable
                {...this.props}
                className={cn('ef5-input-data-table', this.props.className)}
                headers={headers}
                rows={rows}
                totalRow={this.props.totalRow}
                finalRow={this.props.finalRow}
                activeRow={this.props.activeRow}
                columnClassNames={[]}
            />
        );
    }
}

export { DataTable as default, CheckboxDataTable, RadioDataTable, InputDataTable };

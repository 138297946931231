import VError from 'verror';
import Ads from 'states/resources/ads/actions';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import notify from 'utils/notify';

const actions = {
    pause(campaignId, adId) {
        return async (dispatch, getState) => {
            try {
                await dispatch(Ads.pause(campaignId, adId));

                dispatch({ type: 'AD:PAUSED:SUCCESS' });
            } catch (err) {
                dispatch({ type: 'AD:PAUSED:ERROR' });

                notify({
                    error: new VError(err, `Failed to pause ad: ${adId}`),
                    metaData: {
                        campaignId,
                        adId,
                    },
                });
            }
        };
    },
    unpause(campaignId, adId) {
        return async (dispatch, getState) => {
            try {
                await dispatch(Ads.unpause(campaignId, adId));

                dispatch({ type: 'AD:UNPAUSED:SUCCESS' });
            } catch (err) {
                dispatch({ type: 'AD:UNPAUSED:ERROR' });

                notify({
                    error: new VError(err, `Failed to unpause ad: ${adId}`),
                    metaData: {
                        campaignId,
                        adId,
                    },
                });
            }
        };
    },
};

export default actions;

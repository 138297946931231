import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import cx from 'classnames';

import makeStyles from '@mui/styles/makeStyles';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { DialogTitle, DialogContent, DialogActions } from 'widgets-v6/modal';

import selector from './selector';
import { useAdForm } from '../../v2/hook';

const useStyles = makeStyles(theme => ({
    warning: props => {
        let color = theme.palette.warning.main;
        if (props.severity === 'hard') {
            color = theme.palette.error.main;
        } else if (props.severity === 'medium') {
            color = theme.palette.warning.secondary;
        }

        return {
            color,
            whiteSpace: 'pre-line',
        }
    },
    title: {
        fontWeight: 'bold',
    },
}));

export const WarningText = props => {
    const classes = useStyles(props);
    return (
        <Grid container spacing={1}>
            <Grid item>
                {props.severity === 'hard' ? (
                    <WarningIcon color="error" />
                ) : (
                    <InfoIcon classes={{ root: classes.warning }} />
                )}
            </Grid>
            <Grid item xs={9}>
                {props.title && (
                    <Typography className={cx(classes.warning, classes.title)}>
                        {props.title}
                    </Typography>
                )}
                <Typography className={classes.warning}>{props.message}</Typography>
            </Grid>
        </Grid>
    );
};

const modalStyles = makeStyles(theme => ({
    contentSection: {
        marginBottom: theme.spacing(1),
    },
    segmentNameList: {
        padding: 'revert',
        listStyle: 'initial',
    },
    warningDetails: {
        marginLeft: theme.spacing(2),
    },
}));

const SetupWarningModal = ({
    tacticWarnings,
    targetingWarnings,
    isOpen,
    onCancel,
    onConfirm,
    isSubmitting,
    hardTacticWarning,
}) => {
    const classes = modalStyles();
    const { overrideDealPriceWarning, hasCrossPlatformCampaignWarning } = useAdForm();
    return (
        <Dialog open={isOpen} onClose={onCancel}>
            <DialogTitle>Low Inventory</DialogTitle>
            <DialogContent>
                {hasCrossPlatformCampaignWarning && (
                    <div>
                        <div className={classes.contentSection}>
                            <Typography>
                                Low inventory can affect performance. The follow targeting criteria
                                might require adjustment:
                            </Typography>
                        </div>
                        {_.map(targetingWarnings, warning => (
                            <div className={classes.contentSection} key={warning.title}>
                                <Typography>{warning.title}:</Typography>
                                <Typography className={classes.warningDetails}>
                                    {warning.message}
                                </Typography>
                            </div>
                        ))}
                        <div className={classes.contentSection}>
                            {tacticWarnings.length > 0 && (
                                <React.Fragment>
                                    <Typography gutterBottom>Tactics:</Typography>
                                    <div className={classes.warningDetails}>
                                        {_.map(tacticWarnings, tacticWarning => (
                                            <React.Fragment key={tacticWarning.key}>
                                                {_.map(
                                                    tacticWarning.moduleWarnings,
                                                    moduleWarning => (
                                                        <React.Fragment key={moduleWarning.id}>
                                                            <Typography
                                                                key={`${tacticWarning.key}-${
                                                                    moduleWarning.id
                                                                }`}
                                                            >
                                                                {' '}
                                                                {`${tacticWarning.title} > ${
                                                                    moduleWarning.title
                                                                }`}
                                                                :{' '}
                                                            </Typography>
                                                            <ul className={classes.segmentNameList}>
                                                                {_.map(
                                                                    moduleWarning.segmentNames,
                                                                    segment => (
                                                                        <li key={segment}>
                                                                            {segment}
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )}
                {overrideDealPriceWarning.hasWarning && (
                    <div className={classes.contentSection}>
                        <Typography>
                            Net Bid Price is lower than the floor price for some private deals.
                            Increase your Total Bid Price to avoid underdelivery for these deal.
                        </Typography>
                    </div>
                )}
                {hardTacticWarning.hasWarning && (
                    <div className={classes.contentSection}>
                        <Typography>{hardTacticWarning.message}</Typography>
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onCancel}
                    disabled={isSubmitting}
                >
                    Cancel save and return to the form
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                    disabled={isSubmitting}
                >
                    Continue saving the ad
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const SetupWarningConfirmation = connect(selector)(SetupWarningModal);

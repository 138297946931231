import React, { useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { browserHistory } from 'react-router';
import { useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import CampaignOverallProgress from '../modules/campaign-overall-progress';
import { TemporaryDrawer } from 'widgets-v6/modal';
import CircularProgress from '@mui/material/CircularProgress';

import Separator from 'widgets-v5/separator';
import TimezoneSelector from 'widgets-v5/timezone-selector';
import StatsSummaryTable from '../../campaign-progress/modules/stats-summary-table';
import { NeutralButton } from 'widgets-v5/buttons';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import {
    formatNumber_percentage,
    formatNumber_currency,
    formatNumber_whole,
} from 'utils/formatting';
import { getPacingColor, getProjectedPacingColor } from '../layout';
import { SummaryContent } from 'pages/campaigns/campaigns-overview/modules/campaign-summary/summary-content';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    drawer: {
        minWidth: '45%',
        maxWidth: '60%',
    },
    drawerContent: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 64px)',
        margin: theme.spacing(2),
    },
    drawerHeader: {
        flex: '0 0 auto',
    },
    drawerBody: {
        flex: '1 1 auto',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    metrics: {
        padding: '0px 35px',
    },
    metric: {
        fontSize: 14,
    },
    infoIcon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: '15px',
        paddingLeft: '40%',
    },
    popoverContent: {
        padding: theme.spacing(2),
        minWidth: '320px',
        maxHeight: '600px',
    },
}));

const GOAL_MAPPING = {
    SPEND: 'spend',
    IMPRESSIONS: 'impressions',
    BILLINGS: 'billings',
};

export function GraphLayout(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const { viewBy } = useSelector(state => state.campaignPageContainer);
    const {
        campaignId,
        campaign,
        statsTableData,
        summary,
        ads,
        workspace,
        flight,
        dataForOverallGraph,
        organizationTimezone,
        timezoneAtCampaignLevel,
        changeTimezoneAtCampaignLevel,
        isLoading,
        campaignMetadata,
    } = props;

    useEffect(() => {
        if (campaignId) {
            changeTimezoneAtCampaignLevel(campaign.default_timezone);
            setOpen(true);
        }
    }, []);

    const { adId } = props.params;
    const isEnded = campaign.status === 'ended';

    const {
        currentPacing,
        currentDelivered,
        goal,
        idealDelivered,
        totalTarget,
        projectedPacing,
        projectedDelivery,
        spendAtRisk,
        revenueAtRisk,
        variance,
    } = campaignMetadata;

    const projectedPacingColor = getProjectedPacingColor(projectedPacing);
    const totalPacingColor = getPacingColor(currentPacing, isEnded);
    let goalTitle = '';

    if (goal === GOAL_MAPPING.SPEND) {
        goalTitle = 'Spend';
    } else if (goal === GOAL_MAPPING.IMPRESSIONS) {
        goalTitle = 'Impressions';
    } else {
        goalTitle = 'Revenue';
    }

    const openDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
        browserHistory.push(`/campaigns/${campaignId}/progress/`);
    };

    let metricsLeftColumn = [];
    let metricsMiddleColumn = [];
    let metricsRightColumn = [];

    const showFormattedNumber = (goalType, value) => {
        return goalType === GOAL_MAPPING.IMPRESSIONS
            ? formatNumber_whole(value)
            : formatNumber_currency(value);
    };

    if (isEnded) {
        metricsLeftColumn = [
            {
                title: `Delivered ${goalTitle}`,
                value: showFormattedNumber(goal, currentDelivered),
                color: 'black',
            },
            {
                title: 'Target',
                value: showFormattedNumber(goal, totalTarget),
                color: 'black',
            },
        ];

        metricsMiddleColumn = [
            {
                title: 'Delivered %',
                value: formatNumber_percentage(currentPacing),
                color: totalPacingColor,
            },
            {
                title: 'Variance',
                value: formatNumber_currency(variance),
                color: 'black',
            },
        ];
    } else {
        metricsLeftColumn = [
            {
                title: 'Current Pacing',
                value: formatNumber_percentage(currentPacing),
                color: totalPacingColor,
            },
            {
                title: `Delivered ${goalTitle}`,
                value: showFormattedNumber(goal, currentDelivered),
                color: 'black',
            },
            {
                title: 'Ideal Delivery',
                value: showFormattedNumber(goal, idealDelivered),
                color: 'black',
            },
        ];

        let projectedDeliveryValue = '';
        if (currentPacing === 0) {
            projectedDeliveryValue = 'N/A';
        } else {
            projectedDeliveryValue = showFormattedNumber(goal, projectedDelivery);
        }

        metricsMiddleColumn = [
            {
                title: 'Projected Pacing',
                value: currentPacing === 0 ? 'N/A' : formatNumber_percentage(projectedPacing),
                color: projectedPacingColor,
            },
            {
                title: 'Projected Delivery',
                value: projectedDeliveryValue,
                color: 'black',
            },
            {
                title: 'Target',
                value: showFormattedNumber(goal, totalTarget),
                color: 'black',
            },
        ];

        if (goal === GOAL_MAPPING.SPEND) {
            metricsRightColumn.push({
                title: 'Spend At Risk',
                value: formatNumber_currency(spendAtRisk),
                color: 'black',
            });
        } else if (goal === GOAL_MAPPING.BILLINGS) {
            metricsRightColumn.push({
                title: 'Revenue At Risk',
                value: formatNumber_currency(revenueAtRisk),
                color: 'black',
            });
        } else if (goal === GOAL_MAPPING.IMPRESSIONS) {
            metricsRightColumn.push({
                title: 'Impressions At Risk',
                value: formatNumber_whole(spendAtRisk),
                color: 'black',
            });
        }
    }

    const isAutoCampaign = campaign.budget_allocation_method === 'auto';
    const isViewingByEntireCampaign =
        viewBy === 'campaign' && campaign.flightPacingStrategy === 'campaign';

    const isCampaignGraphVisible = isAutoCampaign && !isViewingByEntireCampaign;

    return (
        <div className="ef5-progress-page">
            <Box mb={2}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item sm={12} md={8} lg={9}>
                        <i className="fa fa-info-circle ef5-progress-page__filter-info" />
                        {
                            'Real-time data may contain unvalidated impressions and costs that will be removed within three days.'
                        }
                    </Grid>
                    <Grid item sm={12} md={4} lg={3}>
                        <Box mb={1}>
                            <FormLabel>Timezone</FormLabel>
                        </Box>
                        <TimezoneSelector
                            timezone={timezoneAtCampaignLevel}
                            existingTimezones={[organizationTimezone, campaign.default_timezone]}
                            onChange={changeTimezoneAtCampaignLevel}
                        />
                    </Grid>
                </Grid>
            </Box>
            <div
                className={classnames('ef5-progress-page__workspace', {
                    'ef5-progress-page__workspace_is-campaign-progress': !workspace,
                })}
            >
                <div className="ef5-progress-page__ad-list">
                    <CampaignOverallProgress
                        ads={ads}
                        currentAd={adId}
                        campaign={campaign}
                        dataForOverallGraph={dataForOverallGraph}
                        campaignId={campaignId}
                        flight={flight}
                        timezoneAtCampaignLevel={timezoneAtCampaignLevel}
                        onClick={openDrawer}
                        isLoading={isLoading}
                    />
                </div>
                <TemporaryDrawer
                    side="right"
                    open={open}
                    onClose={closeDrawer}
                    classes={{ paper: classes.drawer }}
                >
                    <div className={classes.drawerContent}>
                        {campaignId && (
                            <div className={classes.drawerHeader}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Grid item>
                                        <h1 className="ef5-progress-page__ad-name">
                                            #{campaignId} {campaign.name}
                                        </h1>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            aria-label="close"
                                            onClick={closeDrawer}
                                            size="large"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        <Box mb={2}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <Grid item sm={12} md={8} lg={5}>
                                    <Box mb={1}>
                                        <FormLabel>Campaign Timezone</FormLabel>
                                    </Box>
                                    <TimezoneSelector
                                        timezone={timezoneAtCampaignLevel}
                                        existingTimezones={[
                                            organizationTimezone,
                                            campaign.default_timezone,
                                            ...campaign.adTimezones,
                                            'UTC',
                                        ]}
                                        onChange={changeTimezoneAtCampaignLevel}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {isCampaignGraphVisible && (
                            <div className={classes.drawerBody}>
                                <Separator />
                                {workspace ? workspace : <CircularProgress />}
                            </div>
                        )}
                    </div>
                    {isCampaignGraphVisible && (
                        <Grid container className={classes.metrics} spacing={4}>
                            <Grid item xs={isEnded ? 6 : 4}>
                                {_.map(metricsLeftColumn, metric => (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        key={metric.title}
                                        color={metric.color}
                                    >
                                        <Typography
                                            className={classes.metric}
                                            color="textSecondary"
                                        >
                                            {`${metric.title}:`}
                                        </Typography>
                                        <Typography className={classes.metric}>
                                            {metric.value}
                                        </Typography>
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={isEnded ? 6 : 4}>
                                {_.map(metricsMiddleColumn, metric => (
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        key={metric.title}
                                        color={metric.color}
                                    >
                                        <Typography
                                            className={classes.metric}
                                            color="textSecondary"
                                        >{`${metric.title}:`}</Typography>
                                        <Typography className={classes.metric}>
                                            {metric.value}
                                        </Typography>
                                    </Box>
                                ))}
                            </Grid>
                            {!isEnded && (
                                <Grid item xs={4}>
                                    {_.map(metricsRightColumn, metric => (
                                        <Box
                                            display="flex"
                                            key={metric.title}
                                            justifyContent="space-between"
                                            color={metric.color}
                                        >
                                            <Typography
                                                className={classes.metric}
                                                color="textSecondary"
                                            >{`${metric.title}:`}</Typography>
                                            <Typography className={classes.metric}>
                                                {metric.value}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {isCampaignGraphVisible && (
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography className={classes.infoIcon}>
                                    <InfoOutlinedIcon fontSize="small" />
                                    <Typography>Progress Graph updated every 1 hour</Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <StatsTable
                        campaignId={campaignId}
                        status={campaign.status}
                        summary={summary}
                        statsTableData={statsTableData}
                        changeTimezone={changeTimezoneAtCampaignLevel}
                        isLoading={false}
                    />
                </TemporaryDrawer>
            </div>
        </div>
    );
}

function StatsTable(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const classes = useStyles();
    const { statsTableData, status, isLoading, campaignId } = props;
    return (
        <div className="ef3-campaign-delivery__stats-table">
            <div className={classes.toolbar}>
                <React.Fragment>
                    <NeutralButton
                        onClick={handleClick}
                        icon={<i className="fa fa-list" />}
                        text="Summary"
                    />
                    <Popover
                        id="campaign-summary-popup"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div className={classes.popoverContent}>
                            <SummaryContent campaignId={campaignId} subTitle="Campaign Summary" />
                        </div>
                    </Popover>
                </React.Fragment>
            </div>
            <StatsSummaryTable
                className="ef3-campaign-delivery__table-body"
                headerItems={statsTableData.headerItems}
                bodyItems={statsTableData.bodyItems}
                isLiveStats={status === 'live'}
                isLoading={isLoading}
            />
        </div>
    );
}

export default GraphLayout;

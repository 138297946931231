import _ from 'lodash';

import c from 'common/constants/flux-events';

const initialState = {
    liveByDate: [],
    liveByHour: [],
    standardByDate: [],
    standardByHour: [],
    standardTotal: undefined,
};

export const NAME = 'resources.stats.campaign';

export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__CAMPAIGN_STATS__FETCH_ALL_START: {
            const { campaignIds } = action.payload;

            const states = {};
            _.each(campaignIds, campaignId => {
                states[campaignId] = { isLoading: true };
            });
            return {
                ...state,
                ...states,
            };
        }
        case c.SYSTEM__CAMPAIGN_STATS__FETCH_ALL_STOP: {
            const { campaignIds } = action.payload;

            const states = {};
            _.each(campaignIds, campaignId => {
                states[campaignId] = {
                    ...state[campaignId],
                    isLoading: false,
                };
            });
            return {
                ...state,
                ...states,
            };
        }
        case c.SYSTEM__CAMPAIGN_STATS_BY_HOUR__FETCH__SUCCESS: {
            const { campaignId } = action.payload;
            return {
                ...state,
                [campaignId]: {
                    ...state[campaignId],
                    standardByHour: action.payload.stats,
                    isLoading: false,
                },
            };
        }

        case c.SYSTEM__CAMPAIGN_STATS_LIVE_BY_HOUR__FETCH__SUCCESS: {
            const { campaignId } = action.payload;
            return {
                ...state,
                [campaignId]: {
                    ...state[campaignId],
                    liveByHour: action.payload.stats,
                    isLoading: false,
                },
            };
        }

        case c.SYSTEM__CAMPAIGN_STATS_BY_DATE__FETCH__SUCCESS: {
            const { campaignId } = action.payload;
            return {
                ...state,
                [campaignId]: {
                    ...state[campaignId],
                    standardByDate: action.payload.stats,
                    isLoading: false,
                },
            };
        }

        case c.SYSTEM__CAMPAIGN_STATS_TOTAL__FETCH: {
            const { campaignId } = action.payload;
            return {
                ...state,
                [campaignId]: {
                    ...state[campaignId],
                    isLoading: true,
                },
            };
        }

        case c.SYSTEM__CAMPAIGN_STATS_TOTAL__FETCH__SUCCESS: {
            const { campaignId } = action.payload;
            return {
                ...state,
                [campaignId]: {
                    ...state[campaignId],
                    standardTotal: action.payload.stat,
                    isLoading: false,
                },
            };
        }

        default:
            return state;
    }
}

import _ from 'lodash';
import { combineReducers } from 'redux';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';
import {
    validateAdDraft,
    applyBillingTermChange,
    applyBillingEnabledChange,
    applyMaxTotalSpendFix,
    applyBillingRateFix,
} from 'forms/ad-form/services';

import poiViewer from './reducer-poi-viewer';

const initialState = {
    draft: null,
    isDraftValid: false,
    errors: [],
    showErrors: false,
    isLoading: true,
    isSubmiting: false,
    isAdPresetModalOpen: false,
    isDirty: false,
    startNow: false,
    unassignedPoints: [], // @TODO to remove
    isOpen: false,
    poiViewer: null,
    searchString: '',
    // geoLayerFinder_folderState: {}
    geoCategories: null,
};

export const NAME = 'adNewForm';

export default combineReducers({
    form,
    poiViewer,
});

function form(state = {}, action) {
    switch (action.type) {
        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__INIT_STATE: {
        //     const { campaignId} = action.payload;

        //     return findCacheState(campaignId, state, initialState);
        //     // return initialState;
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__OPEN: {
        //     return {
        //         ...state,
        //         isLoading: true,
        //         isOpen: true
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__INIT_GEO_POINTS: {
        //     // add a temp pointId for tracking adding and deleting points from session
        //     const unassignedPoints = _.map(action.payload.points, point => {
        //         return {
        //             ...point,
        //             pointId: _.uniqueId(),
        //         };
        //     });
        //     return {
        //         ...state,
        //         unassignedPoints,
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__INIT: {
        //     const sessionDraft_current = _.get(state, `draft`, null);
        //     let sessionDraft_next  = sessionDraft_current;

        //     if ( sessionDraft_current === null ) {
        //         // Only inititialize session's draft if there isn't already one.
        //         // This is to prevent router reruning when user navigates to edit creative form.
        //         sessionDraft_next = _.get(action, 'payload.ad');
        //     }

        //     const errors = validateAdDraft( {...sessionDraft_next} );

        //     // poi module
        //     const geoCategories_current = _.get(state, 'geoResources', null);
        //     let geoCategories_next = geoCategories_current;
        //     if ( geoCategories_current === null ) {
        //         // Only inititialize session's draft if there isn't already one.
        //         // This is to prevent router reruning when user navigates to edit creative form.
        //         geoCategories_next = _.get(action, 'payload.geoCategories');
        //     }

        //     return {
        //         ...state,
        //         errors,
        //         isDraftValid: errors.length === 0,
        //         draft: sessionDraft_next,
        //         isLoading: false,
        //         isDirty: false,
        //         geoCategories: geoCategories_next
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__INIT_FAIL: {
        //     const httpErrors = _.map(action.error, error => ({
        //         field: error.field,
        //         message: error.message
        //     }));
        //     return {
        //         ...state,
        //         errors: httpErrors
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__UPDATE: {
        //     const { formData, campaignBillingEnabled } = action.payload;

        //     let nextDraft = {
        //         ...state.draft,
        //         ...formData
        //     };

        //     nextDraft = applyBillingTermChange(nextDraft, state, formData);
        //     nextDraft = applyBillingEnabledChange(nextDraft, state, formData);
        //     nextDraft = applyBillingRateFix(nextDraft, state, formData);
        //     nextDraft = applyMaxTotalSpendFix(nextDraft, state, formData);
        //     const errors = validateAdDraft(nextDraft, campaignBillingEnabled);

        //     return {
        //         ...state,
        //         draft: nextDraft,
        //         isDirty: true,
        //         errors,
        //         isDraftValid: errors.length === 0
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__SHOW_ERRORS: {
        //     return {
        //         ...state,
        //         showErrors: true,
        //     };
        // }

        case c.OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT: {
            return {
                ...state,
                isSubmiting: true,
            };
        }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW_OPEN_AD_PRESET_MODAL: {
        //     return {
        //         ...state,
        //         isAdPresetModalOpen: true
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW_CLOSE_AD_PRESET_MODAL: {
        //     return {
        //         ...state,
        //         isAdPresetModalOpen: false
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW_SET_AD_PRESET: {
        //     const { adpreset } = action.payload;

        //     const enabledFields = _.reduce(adpreset.ad_preset_properties, (result, item) => {
        //         _.each(_.filter(item.properties, pr => pr.checked === true), prop => {
        //             result.push(prop.key);
        //         }, []);
        //         return result;
        //     }, []);

        //     return {
        //         ...state,
        //         draft: {
        //             ...state.draft,
        //             ..._.pick(
        //                 adpreset,
        //                 enabledFields
        //             )
        //         },
        //         isAdPresetModalOpen: false
        //     };
        // }

        case c.OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT_FAIL: {
            const httpErrors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));

            return {
                ...state,
                errors: httpErrors,
                showErrors: true,
                isLoading: false,
                isSubmiting: false,
                isDraftValid: false,
            };
        }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__SUBMIT_SUCCESS: {
        //     return {
        //         ...state,
        //         isSubmiting: false
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__SET_START_ASAP: {
        //     const startNow = !state.startNow;
        //     return {
        //         ...state,
        //         startNow
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__CLOSE: {
        //     return {
        //         ...initialState,
        //         unassignedPoints: [],
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__ADD_GEO_POINT: {
        //     const { unassignedPoints } = state;
        //     const { point } = action.payload;

        //     const newPoints = [
        //         ...unassignedPoints,
        //         point,
        //     ];

        //     return {
        //         ...state,
        //         unassignedPoints: newPoints
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__REMOVE_GEO_POINT: {
        //     const { unassignedPoints } = state;
        //     const { tempPointId } = action.payload;

        //     const newPoints = _.filter(unassignedPoints, point => point.pointId !== tempPointId);

        //     return {
        //         ...state,
        //         unassignedPoints: newPoints
        //     };
        // }

        // case c.SYSTEM__GEO_LAYERS__POST__SUCCESS: {
        //     return {
        //         ...state,
        //         unassignedPoints: [],
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__GEOLAYER_SEARCH_STRING_CHANGED: {
        //     const { searchString } = action.payload;
        //     const { geoCategories } = action.payload;

        //     return {
        //         ...state,
        //         searchString,
        //         geoCategories
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__GEOLAYER_FINDER__REFRESH: {
        //     const { geoCategories } = action.payload;

        //     return {
        //         ...state,
        //         geoCategories
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DRAFT_NEW__GEOLAYER_FINDER__FOLDER_STATE_CHANGED: {
        //     const { geoCategories } = action.payload;

        //     return {
        //         ...state,
        //         geoCategories
        //     };
        // }

        // case c.OVERVIEW_ADS__AD_DUPLICATE: {
        //     return {
        //         ...state,
        //         draft: action.payload.newAd
        //     };
        // }

        default:
            return state;
    }
}

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import VirtualList from 'react-tiny-virtual-list';
import toastr from 'toastr';
import { isInternalUser } from 'states/profile/business-rules';

import flags from 'containers/flags/service';

import { formatNumber_whole } from 'utils/formatting';
import CheckboxMenu from 'widgets-v5/checkbox-menu';
import { ButtonTitle } from 'widgets-v5/typography';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import Checkbox from 'widgets-v5/checkbox';
import { SecondaryButton, NeutralButton } from 'widgets-v5/buttons';
import FormField from 'widgets-v5/form-field';
import StandardInput from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import { ComposableDataTable, Row, Cell, MobileHeader } from 'widgets-v5/data-table';
import Card from 'widgets-v5/card';
import Spacer from 'widgets-v5/spacer';
import DownloadTextContents from 'widgets-v5/download-text-contents';
import PointViewer from 'widgets-v5/point-viewer';
import GoogleMapsApiLoader from 'containers/google-maps-api-loader';

import PROVINCES_CITIES from 'common/constants/provinces-cities';
import http from '../../../../../../utils/http';

const PROVINCE_OPTIONS = [];
const PROVINCES_TO_CITY_OPTIONS = {};

_(PROVINCES_CITIES)
    .sortBy(province => province._id)
    .each(province => {
        PROVINCE_OPTIONS.push({
            value: province._id,
            label: province._id,
        });

        const cities = [
            ..._(province.cities)
                .filter(x => x)
                .sort()
                .uniq()
                .map(city => ({
                    label: city,
                    value: city,
                }))
                .value(),
        ];

        // track cities by province
        PROVINCES_TO_CITY_OPTIONS[province._id] = cities;
    });

const ALL_CITIES = _(PROVINCES_TO_CITY_OPTIONS)
    .map(cities => cities)
    .flatten()
    .uniqBy(city => city.value)
    .value();

// This function is to manage the "back pressure" of multiple API requests.
// If multiple promises are pushed through the stream, only the latest promise
// should have it's 'onSuccess' or 'onError' executed.
function createLatest() {
    const stack = [];

    return function latest({ promise, onSuccess, onError }) {
        stack.push(promise);

        const originalSize = stack.length;

        const handle = callback => value => {
            // only call onSuccess when the promise is the top stack item
            if (stack.length === originalSize) {
                callback && callback(value);
            }

            // remove reference to promise
            stack[originalSize - 1] = null;
        };

        promise.then(handle(onSuccess), handle(onError));
    };
}

const latest = createLatest();

class POISearch extends React.Component {
    state = {
        height: 400,
        entryOptions: [],
        selectedItems: [],
        selectedItemsChecked: false,
        isSearching: false,
        isEndOfPagination: false,
        query: {
            search: '',
            city: null,
            province: null,
            page: 1,
            limit: 500,
        },
        maxResults: 0,
        isFetchingPoints: false,
        trackedPois: [],
    };

    loadMore = () => {
        if (this.state.isEndOfPagination) {
            return;
        }

        // increment page
        this.setState(
            {
                ...this.state,
                isSearching: true,
                query: {
                    ...this.state.query,
                    page: this.state.query.page + 1,
                },
            },
            () => {
                // fetch pois
                this.fetchPois({
                    onSuccess: res => {
                        this.setState({
                            isEndOfPagination: res.payload.length < this.state.query.limit,
                            maxResults: res.meta.max_results,
                            // add new pois to old pois
                            entryOptions: this.state.entryOptions.concat(
                                _.map(res.payload, poi => ({
                                    value: poi.brand,
                                    label: `${poi.brand}`,
                                    ...poi,
                                }))
                            ),
                        });
                    },
                    onError: err => {
                        this.setState({ error: err });
                    },
                });
            }
        );
    };

    componentDidMount() {
        this.search();
    }

    search = () => {
        this.fetchPois({
            onSuccess: res => {
                this.setState({
                    isEndOfPagination: res.payload.length < this.state.query.limit,
                    maxResults: res.meta.max_results,
                    entryOptions: _.map(res.payload, poi => ({
                        value: poi.brand,
                        label: `${poi.brand}`,
                        ...poi,
                    })),
                });
            },
            onError: err => {
                this.setState({ error: err });
            },
        });
    };

    fetchPois = _.debounce(({ onSuccess, onError }) => {
        const missingSearchText = !this.state.query.search;
        const missingProvince = !this.state.query.province;
        const missingCity = !this.state.query.city;

        // Don't fetch unless one option is selected
        if (missingSearchText && missingProvince && missingCity) {
            return;
        }

        // Take only truthy values
        const query = _.pickBy(this.state.query, v => !!v);

        this.setState({ isSearching: true });

        latest({
            promise: http().get('cleanlist', query),
            onSuccess: res => {
                this.setState({ isSearching: false });
                onSuccess(res);
            },
            onError: err => {
                toastr.error('Something went wrong :(');
                console.error(err);
                this.setState({ isSearching: false });
                onError(err);
            },
        });
    }, 1000);

    handleQueryChange = name => value => {
        this.setState(
            {
                ...this.state,
                isEndOfPagination: false,
                selectedItems: [],
                query: {
                    ...this.state.query,
                    page: 1,
                    [name]: value,
                },
            },
            () => {
                const missingSearchText = !this.state.query.search;
                const missingProvince = !this.state.query.province;
                const missingCity = !this.state.query.city;

                // clear options when all filters are cleared
                if (missingSearchText && missingProvince && missingCity) {
                    this.setState({
                        entryOptions: [],
                    });
                } else {
                    this.search();
                }
            }
        );
    };

    handleItemToggle = item => {
        let selectedItems;
        if (_.includes(this.state.selectedItems, item.value)) {
            selectedItems = _.filter(this.state.selectedItems, val => val !== item.value);
        } else {
            selectedItems = this.state.selectedItems.concat(item.value);
        }

        this.setState({ selectedItems });
    };

    selectAll = () => {
        const selectedItems = _.map(this.state.entryOptions, option => option.value);
        this.setState({
            selectedItemsChecked: !this.state.selectedItemsChecked,
            selectedItems: this.state.selectedItemsChecked === true ? [] : selectedItems,
        });
    };

    handleAddToLayer = () => {
        const entryOptionsMap = {};
        _.each(this.state.entryOptions, item => (entryOptionsMap[item.brand] = item));

        const cleanlistEntriesMap = {};
        _.each(this.props.draft.cleanlist_entries, entry => {
            cleanlistEntriesMap[entry.brand + entry.province + entry.city] = true;
        });

        // Prevent adding duplicates
        const { province, city } = this.state.query;

        const selectedItems = _(this.state.selectedItems)
            .filter(brand => !cleanlistEntriesMap[brand + province + city])
            .map(brand => ({
                brand,
                province: this.state.query.province,
                city: this.state.query.city,
                points: entryOptionsMap[brand].points,
            }))
            .value();

        this.props.onCreateLater(selectedItems);

        this.setState({
            selectedItems: [],
        });
    };

    getTrackedPoisDataFileName = () => {
        return this.props.draft.layerMetadata.name || 'pois';
    };

    getTrackedPoisDataFileContents = async () => {
        const selectedBrands = this.props.draft.cleanlist_entries.map(entry => {
            return this.extractTrackedPoiSearchCondition(entry);
        });

        if (selectedBrands.length < 1) {
            return;
        }

        const body = { brands: selectedBrands };
        const response = await http().post('cleanlist/points', body);

        return this.formatCsvData(response);
    };

    handleTrackedPoiClick = async entry => {
        this.setState({
            isFetchingPoints: true,
        });

        const body = {
            brands: [this.extractTrackedPoiSearchCondition(entry)],
        };

        const response = await http().post('cleanlist/points', body);
        this.setState({
            isFetchingPoints: false,
            trackedPois: response.payload,
        });
    };

    extractTrackedPoiSearchCondition = entry => {
        const condition = {
            company: entry.brand,
        };

        if (entry.city) {
            condition.city = entry.city;
        }

        if (entry.province) {
            condition.prov = entry.province;
        }

        return condition;
    };

    formatCsvData = response => {
        const headers = 'latitude,longitude,address,city,postalCode,province';
        const pois = _.map(response.payload, brand => {
            return `${brand.latitude},${brand.longitude},"${brand.address}","${brand.city}","${
                brand.postalCode
            }","${brand.region}"`;
        });
        return [].concat(headers, pois).join('\r\n');
    };

    render() {
        const layerMap = {};
        _.each(this.props.draft.cleanlist_entries, entry => (layerMap[entry.brand] = true));

        const entryOptions = this.state.entryOptions;

        const selections = {};
        _.each(this.state.selectedItems, itemValue => (selections[itemValue] = true));

        return (
            <div className="poi-editor-v2__search-wrapper">
                <div className="poi-editor-v2__search">
                    <div style={{ marginBottom: 10 }} className="poi-editor-v2__search-title">
                        <ButtonTitle>Find POIs</ButtonTitle>
                    </div>
                    <div className="poi-editor-v2__forms">
                        <FormField
                            className="poi-editor-v2__form poi-editor-v2__form-brand"
                            label="Brand / Name"
                            errors={null}
                            showErrors={false}
                        >
                            <StandardInput
                                placeholder="e.g. 'Tim Hortons' or 'pizza'"
                                value={this.state.query.search}
                                onChange={this.handleQueryChange('search')}
                                disabled={false}
                            />
                        </FormField>
                        <Spacer type="small" />
                        <div className="ef6-alignment__flex">
                            <FormField
                                className="poi-editor-v2__form poi-editor-v2__form-province"
                                label="Province"
                                errors={null}
                                showErrors={false}
                            >
                                <StandardSelector
                                    placeholder="All"
                                    clearable
                                    items={PROVINCE_OPTIONS}
                                    onChange={this.handleQueryChange('province')}
                                    value={this.state.query.province}
                                    disabled={false}
                                />
                            </FormField>
                            <FormField
                                className="poi-editor-v2__form-city"
                                label="City"
                                errors={null}
                                showErrors={false}
                            >
                                <StandardSelector
                                    virtual
                                    searchable
                                    clearable
                                    placeholder="All"
                                    items={
                                        PROVINCES_TO_CITY_OPTIONS[this.state.query.province] ||
                                        ALL_CITIES
                                    }
                                    onChange={this.handleQueryChange('city')}
                                    value={this.state.query.city}
                                    disabled={false}
                                />
                            </FormField>
                        </div>
                        <Spacer type="small" />
                    </div>

                    {entryOptions.length === 0 ? (
                        <BlockLoadGroup isLoading={this.state.isSearching}>
                            {!!this.state.query.search ? (
                                <div>0 results. Try broadening your search.</div>
                            ) : (
                                <div>
                                    Start by entering a brand / name, or selecting a province or
                                    city filter.
                                </div>
                            )}
                        </BlockLoadGroup>
                    ) : (
                        <React.Fragment>
                            <BlockLoadGroup isLoading={this.state.isSearching}>
                                <div className="ef6-alignment__space-between poi-editor-v2__search-table-header">
                                    <div>
                                        <span>
                                            Top{' '}
                                            {formatNumber_whole(
                                                Math.min(
                                                    this.state.query.limit * this.state.query.page,
                                                    this.state.maxResults
                                                )
                                            )}
                                        </span>
                                        <span> of {formatNumber_whole(this.state.maxResults)}</span>
                                    </div>
                                    <div className="ef6-alignment__right">
                                        <SecondaryButton
                                            icon={<i className="fa fa-plus" />}
                                            text={`Track ${
                                                this.state.selectedItems.length > 0
                                                    ? `${this.state.selectedItems.length} `
                                                    : ''
                                            }selected ${
                                                this.state.selectedItems.length > 1 ? 'POIs' : 'POI'
                                            }`}
                                            disabled={this.state.selectedItems.length === 0}
                                            onClick={this.handleAddToLayer}
                                        />
                                    </div>
                                </div>
                                <Card padding={0} margin={0}>
                                    <ComposableDataTable condensed>
                                        <Row header>
                                            <Cell>
                                                <Checkbox
                                                    checked={this.state.selectedItemsChecked}
                                                    onChange={this.selectAll}
                                                    label={
                                                        this.state.selectedItemsChecked
                                                            ? 'Select None'
                                                            : 'Select All'
                                                    }
                                                />
                                            </Cell>
                                            <Cell align="right">
                                                <div>
                                                    Number of POIs{' '}
                                                    <i className="fa fa-caret-down" />
                                                </div>
                                            </Cell>
                                        </Row>

                                        <Row className="poi-editor-v2__search-table-row">
                                            <Cell
                                                className="poi-editor-v2__search-table-cell"
                                                colSpan={3}
                                            >
                                                <VirtualList
                                                    width="100%"
                                                    height={this.state.height}
                                                    itemCount={entryOptions.length}
                                                    itemSize={50}
                                                    renderItem={({ index, style }) => {
                                                        const item = entryOptions[index];
                                                        return (
                                                            <div
                                                                className="ef6-alignment__space-between poi-editor-v2__search-table-item"
                                                                key={item.value}
                                                                style={style}
                                                            >
                                                                <Checkbox
                                                                    label={item.label}
                                                                    checked={selections[item.value]}
                                                                    onChange={() =>
                                                                        this.handleItemToggle(item)
                                                                    }
                                                                />
                                                                <div>{item.points}</div>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </Cell>
                                        </Row>

                                        <Row footer>
                                            <Cell colSpan={3}>
                                                <div className="ef6-alignment__center">
                                                    <NeutralButton
                                                        text={
                                                            this.state.isEndOfPagination
                                                                ? `No more results`
                                                                : `Load next ${
                                                                      this.state.query.limit
                                                                  } of ${formatNumber_whole(
                                                                      this.state.maxResults
                                                                  )}`
                                                        }
                                                        onClick={this.loadMore}
                                                        disabled={this.state.isEndOfPagination}
                                                    />
                                                </div>
                                            </Cell>
                                        </Row>
                                    </ComposableDataTable>
                                </Card>
                            </BlockLoadGroup>
                        </React.Fragment>
                    )}
                </div>
                <div className="poi-editor-v2__search-results">
                    <div style={{ marginBottom: 10 }}>
                        <ButtonTitle>Tracked POIs</ButtonTitle>
                    </div>
                    {isInternalUser() && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: 10,
                            }}
                        >
                            <DownloadTextContents
                                contentType="text/csv"
                                filename={this.getTrackedPoisDataFileName()}
                                getContents={this.getTrackedPoisDataFileContents}
                            >
                                {onDownload => (
                                    <SecondaryButton
                                        icon={<i className="fa-download fa" />}
                                        text="Tracked POIs (CSV)"
                                        onClick={onDownload}
                                    />
                                )}
                            </DownloadTextContents>
                        </div>
                    )}
                    <Card padding={0} margin={0}>
                        <div className="poi-editor-v2__search-results-table">
                            <ComposableDataTable condensed stackable>
                                <Row header>
                                    <Cell>Brand / Name</Cell>
                                    <Cell>Province</Cell>
                                    <Cell>City</Cell>
                                    <Cell>Number of POIs</Cell>
                                    <Cell />
                                </Row>
                                {_.map(this.props.draft.cleanlist_entries, entry => (
                                    <Row
                                        className="ef5-interactive-area"
                                        key={entry.brand}
                                        onClick={() => this.handleTrackedPoiClick(entry)}
                                    >
                                        <Cell>
                                            <MobileHeader>Brands</MobileHeader>
                                            {entry.brand}
                                        </Cell>
                                        <Cell>
                                            <MobileHeader>Province</MobileHeader>
                                            {entry.province}
                                        </Cell>
                                        <Cell>
                                            <MobileHeader>City</MobileHeader>
                                            {entry.city}
                                        </Cell>
                                        <Cell>
                                            <MobileHeader>Locations</MobileHeader>
                                            {entry.points === 0 && <span>[WARNING]</span>}
                                            {entry.points}
                                        </Cell>
                                        <Cell>
                                            <NeutralButton
                                                text="Remove"
                                                onClick={() => this.props.onRemoveFromLayer(entry)}
                                            />
                                        </Cell>
                                    </Row>
                                ))}
                            </ComposableDataTable>
                        </div>
                    </Card>
                    <Spacer />
                    <div className="poi-viewer">
                        <div className="mapContainer">
                            <GoogleMapsApiLoader>
                                <PointViewer
                                    pois={this.state.trackedPois}
                                    isLoading={this.state.isFetchingPoints}
                                />
                            </GoogleMapsApiLoader>
                        </div>
                        {this.state.isFetchingPoints ? (
                            <div className="mapIsLoading">
                                <i className="fa fa-spinner fa-spin fa-5x" />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default POISearch;

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const { func, string } = PropTypes;

function createAndDownloadFile(filename, contentsAsUri) {
    const temporaryElement = window.document.createElement('a');
    temporaryElement.style.display = 'none';
    temporaryElement.setAttribute('download', filename);
    temporaryElement.setAttribute('href', contentsAsUri);
    window.document.body.appendChild(temporaryElement);
    temporaryElement.click();
    window.document.body.removeChild(temporaryElement);
}

function encodeContents(contentType, contents) {
    return `data:${contentType};charset=utf-8,${encodeURIComponent(contents)}`;
}

// Approach inspired by:
// https://stackoverflow.com/questions/3665115/create-a-file-in-memory-for-user-to-download-not-through-server/18197341?noredirect=1#comment-16342145
export default class DownloadTextContents extends React.Component {
    static displayName = 'DownloadTextContents';

    static propTypes = {
        children: func.isRequired,
        filename: string.isRequired,
        contents: string.isRequired,
        contentType: string,
        getContents: func,
    };

    static defaultProps = {
        contentType: 'text/plain',
        contents: '',
    };

    download = async () => {
        const { filename, contents, contentType, getContents } = this.props;

        let contentsToExport = contents;

        if (getContents) {
            contentsToExport = await getContents();
        }
        const contentsAsUri = encodeContents(contentType, contentsToExport);
        createAndDownloadFile(filename, contentsAsUri);
    };

    render() {
        return this.props.children(this.download);
    }
}

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import flags from 'containers/flags/service';
import selector from './selector';
import pickerActions from '../audience-segment-picker/actions';

class AudienceOptions extends React.Component {
    state = {
        options: [],
        mapping: {},
        flexMapping: {},
        loading: true,
    };
    componentDidMount() {
        const { dispatch, shouldHideFlexSegments } = this.props;
        dispatch(pickerActions.fetchAudiencesWithGraphQl(shouldHideFlexSegments));
    }

    render() {
        if (this.props.isLoading) {
            return (
                <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" />
                </Grid>
            );
        }

        if (this.props.options.length === 0) {
            return null;
        }

        return this.props.children({
            options: this.props.options,
            mapping: this.props.mapping,
            flexMapping: this.props.flexMapping,
        });
    }
}

export default connect(selector)(AudienceOptions);

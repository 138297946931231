import _ from 'lodash';
import flags from 'containers/flags/service';
import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.appLists';
export default function appLists(state = null, action) {
    switch (action.type) {
        case c.SYSTEM__APP_LISTS__FETCH__SUCCESS: {
            const { appLists, orgId } = action.payload;
            const o = mergeResource(state, appLists);
            return o;
        }

        case c.SYSTEM__APP_LIST__FETCH__SUCCESS: {
            return state;
        }

        default:
            return state;
    }
}

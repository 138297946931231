import _ from 'lodash';

import { validateCreativeDraft } from 'forms/creative-form/services';
import {
    parseDCMTrackerTags,
    parseDCMTags,
} from '../creative-bulk-upload-form/services/rich-media-template-parser.js';
import { securePartnerDomainsAndReplaceUrlMacros } from 'widgets-v5/url-standard-input';

const initialState = {
    selectedFields: ['name'],
    creativesDefaultValues: [],
    draft: [],
    errors: {}, // {creativeId1: [{field, value, message}, {field, value, message}], creativeId2: [{field, value, message}, {field, value, message}]}
    showErrors: false,
    isLoading: true,
    isSubmitting: false,
    openForm: false,
    serverSideErrors: {},
    files: [],
    needMatch: false,
    fileData: {},
    uploadError: [],
    isFileUploaded: false,
};

export const NAME = 'creativeBulkEditForm';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'CREATIVE_BULK_EDIT_FORM__INIT_START': {
            return {
                ...state,
                isLoading: true,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__INIT': {
            const { creatives, files } = action.payload;

            return {
                ...state,
                creativesDefaultValues: creatives,
                draft: creatives,
                isLoading: false,
                showErrors: false,
                files,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__SUBMIT': {
            return {
                ...state,
                isSubmitting: true,
            };
        }

        case 'CREATIVE_BULK_EDIT__SEARCH_MATCH_OPTIONS__START': {
            return {
                ...state,
                isLoading: true,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__UPDATE_FIELD': {
            const { creativeId, fieldName, fieldValue } = action.payload;

            const updatedDraft = updateFields({
                draft: state.draft,
                fieldName,
                fieldValue,
                creativeId,
            });

            const creativeErrors = validateCreativeDraft(
                _.find(updatedDraft, creative => creative.id === creativeId)
            );

            if (creativeErrors.length > 0) {
                return {
                    ...state,
                    draft: updatedDraft,
                    errors: {
                        ...state.errors,
                        [creativeId]: creativeErrors,
                    },
                };
            } else {
                return {
                    ...state,
                    draft: updatedDraft,
                    errors: {
                        ..._.omit(state.errors, [creativeId]),
                    },
                };
            }
        }

        case 'CREATIVE_BULK_EDIT_FORM__ADD_FIELD': {
            const { fieldName } = action.payload;

            const newFields = addFields({ selectedFields: state.selectedFields, fieldName });
            return {
                ...state,
                selectedFields: newFields,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__REMOVE_FIELD': {
            const { fieldName } = action.payload;

            const updatedSelectedFields = _.filter(
                state.selectedFields,
                field => field !== fieldName
            );

            return {
                ...state,
                selectedFields: updatedSelectedFields,
                draft: _.map(state.draft, creativeInDraft => {
                    const { id } = creativeInDraft;
                    const defaultValues = _.find(
                        state.creativesDefaultValues,
                        creative => creative.id === id
                    );

                    return {
                        ...creativeInDraft,
                        [fieldName]: defaultValues[fieldName],
                    };
                }),
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__SHOW_SERVER_ERRORS': {
            const { errors } = action.payload;

            return {
                ...state,
                serverSideErrors: errors,
                showErrors: true,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__SHOW_ERRORS': {
            return {
                ...state,
                showErrors: true,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__OPEN_FORM': {
            return {
                ...state,
                openForm: true,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__CLOSE_FORM': {
            return {
                ...state,
                selectedFields: ['name'],
                draft: [],
                errors: {},
                showErrors: false,
                isSubmitting: false,
                openForm: false,
                isServerSideError: false,
                needMatch: false,
                fileData: {},
            };
        }

        case 'CREATIVE_BULK_EDIT__READ_DCM_TEMPLATE__START': {
            return {
                ...state,
                isLoading: true,
            };
        }

        case 'CREATIVE_BULK_EDIT__PROCESS_TEMPLATE__SUCCESS': {
            return {
                ...state,
                isLoading: false,
                isFileUploaded: true,
            };
        }

        case 'CREATIVE_BULK_EDIT__PROCESS_TEMPLATE__FAILURE': {
            return {
                ...state,
                isLoading: false,
            };
        }

        case 'CREATIVE_BULK_EDIT_FORM__MATCH_UPLOAD__OPEN': {
            return {
                ...state,
                isloading: false,
                needMatch: true,
            };
        }

        case 'CREATIVE_BULK_EDIT__PROCESS_GENERIC_TEMPLATE__START': {
            const { fileData } = action.payload;

            return {
                ...state,
                fileData,
                isLoading: true,
            };
        }

        case 'CREATIVE_BULK_EDIT__READ_DCM_TEMPLATE__SUCCESS': {
            const { Sheets, selectedCreativeIds, dcmFileType } = action.payload;
            let tags;
            let error;
            if (dcmFileType === 'tracker') {
                ({ tags, error } = parseDCMTrackerTags(Sheets));
            } else if (dcmFileType === 'vendor') {
                ({ tags, error } = parseDCMTags(Sheets));
            }

            if (error) {
                return {
                    ...state,
                    uploadError: [error],
                    isLoading: false,
                    showErrors: true,
                };
            }

            const { updatedDraft, newFields } = getUpdatesFromImportedTags({
                tags,
                selectedCreativeIds,
                draft: state.draft,
                selectedFields: state.selectedFields,
            });
            return {
                ...state,
                draft: updatedDraft,
                selectedFields: newFields,
                isLoading: false,
                isFileUploaded: true,
            };
        }

        default: {
            return state;
        }
    }
}

function updateFields({ draft, fieldName, fieldValue, creativeId }) {
    const updatedDraft = _.map(draft, creative => {
        if (creative.id !== creativeId) {
            return {
                ...creative,
            };
        } else {
            if (fieldName === 'landing_page' && !creative.third_party_clickthrough) {
                return {
                    ...creative,
                    [fieldName]: fieldValue,
                    clickthrough_url: fieldValue,
                };
            }
            if (Array.isArray(creative[fieldName]) && !Array.isArray(fieldValue)) {
                return { ...creative, [fieldName]: [fieldValue] };
            }

            return {
                ...creative,
                [fieldName]: fieldValue,
            };
        }
    });

    return updatedDraft;
}

// landing_page and clickthrough_url is not independent/main fields but depends on clicktracking.
// so it should be removed from selectedFields so that only the clicktracking be shown in the left side options
function addFields({ selectedFields, fieldName }) {
    const fields = _.union(selectedFields, [fieldName]);
    return _(fields)
        .filter(field => field !== 'landing_page')
        .filter(field => field !== 'clickthrough_url')
        .value(x => x);
}

function getUpdatesFromImportedTags({ tags, selectedCreativeIds, draft, selectedFields }) {
    const selectedCreatives = _.filter(draft, creative =>
        _.includes(selectedCreativeIds, creative.id)
    );
    let updatedDraft = [...draft];
    let newFields = selectedFields;
    _.forEach(tags, tag => {
        const creativeName = tag.name;
        const matchedCreative = _.find(
            selectedCreatives,
            creative => creative.name === creativeName
        );

        if (matchedCreative) {
            _.forEach(tag, (value, key) => {
                const fieldName = key;
                let fieldValue = value;
                if (fieldName === 'third_party_pixels') {
                    fieldValue = [securePartnerDomainsAndReplaceUrlMacros(fieldValue[0])];
                }

                // if the file has clickthrough_url tag, then the third_party_clickthrough should be set to true and show the clickTracking group
                if (fieldName === 'clickthrough_url') {
                    newFields = addFields({
                        selectedFields: newFields,
                        fieldName: 'third_party_clickthrough',
                    });
                    updatedDraft = updateFields({
                        draft: updatedDraft,
                        fieldName: 'third_party_clickthrough',
                        fieldValue: true,
                        creativeId: matchedCreative.id,
                    });
                    updatedDraft = updateFields({
                        draft: updatedDraft,
                        fieldName,
                        fieldValue,
                        creativeId: matchedCreative.id,
                    });
                } else {
                    newFields = addFields({ selectedFields: newFields, fieldName });
                    updatedDraft = updateFields({
                        draft: updatedDraft,
                        fieldName,
                        fieldValue,
                        creativeId: matchedCreative.id,
                    });
                }
            });
        }
    });
    return { updatedDraft, newFields };
}

import _ from 'lodash';

const initialState = {};

export const NAME = 'login';
export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

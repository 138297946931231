import _ from 'lodash';
import c from 'common/constants/flux-events';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import { serializeForApi } from 'forms/creative-form/services';
import { createHttp } from 'utils/http';

import Campaigns from 'states/resources/campaigns/actions';
import Creatives from 'states/resources/creatives/actions';
import Files from 'states/resources/files/actions';

import { defaultSetting } from 'forms/creative-form/services';

import { getEnvironmentSettings } from 'services/environment';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { getPlatformsAvailableForCampaign } from 'forms/creative-form/constants';

function generateCreativeForDuplication(getState, creativeId) {
    const creativeToDuplicate = _.get(getState(), `resources.creatives.${creativeId}`);
    const attrsToDuplicate = _.omit(creativeToDuplicate.attr, ['id', 'audit_status']); // <-- note that we don't want to copy id and audit status
    const creativeFormat = _.get(attrsToDuplicate, `format`, void 0);

    const attrPicked_default = {
        ...defaultSetting.defaultValues.initial,
        ...defaultSetting.defaultValues[creativeFormat],
    };

    const attrPicked_toDuplicate = _.pick(attrsToDuplicate, Object.keys(attrPicked_default));

    // Some field has value depedended on other field, and are set automatically
    //      Ideally the duplicated creative shouldn't have missing field, but just in case.
    if (attrPicked_toDuplicate.format === 'native') {
        attrPicked_toDuplicate.size = '0x0';
    }

    return {
        ...attrPicked_default,
        ...attrPicked_toDuplicate,
    };
}

// ----------------------------------
// Helper function for Audit Messages
// ----------------------------------
const getAdvertiserIdsFromCampaign = campaign => {
    let advertisers = [];
    const clients = campaign.clients;
    _.forEach(clients, client => {
        if (client.type === 'advertiser') {
            advertisers.push(client.organization);
        }
    });
    return advertisers;
};

const fetchOrgsByIds = advertiserIds => {
    const http = createHttp();
    const query = `
        query getOrganizationsByIds ($ids: [String]) {
            organizations(filters: {ids: $ids}){
                name
                id
              }
         }
    `;
    const variables = {
        ids: advertiserIds,
    };

    return http.graphql(query, variables);
};

export const CreativeNew = {
    getInitialDraft({ campaignId, creativeId }) {
        const environmentSettings = getEnvironmentSettings();
        return (dispatch, getState) => {
            return dispatch(Files.getAll(campaignId))
                .then(() => {
                    return dispatch(Campaigns.get(campaignId));
                })
                .then(
                    async campaign => {
                        const advertiserIds = getAdvertiserIdsFromCampaign(campaign);
                        let advertiserOrgs;
                        if (advertiserIds.length > 0) {
                            advertiserOrgs = await fetchOrgsByIds(advertiserIds);
                        }
                        if (creativeId) {
                            return generateCreativeForDuplication(getState, creativeId);
                        }

                        const creativeDefaults = { ...defaultSetting.defaultValues.initial };

                        if (!environmentSettings.canCreativesUseInapp()) {
                            creativeDefaults.platforms = ['mweb'];
                        }

                        // Selects "ctv" for ctv and "dooh" for dooh campaigns
                        if (campaign.type !== CampaignTypeMapping.Standard) {
                            const platforms = getPlatformsAvailableForCampaign(campaign.type)
                                .map(x => x.value);

                            creativeDefaults.platforms = platforms;
                        }

                        return {
                            ...creativeDefaults,
                            advertisers: advertiserOrgs.organizations,
                        };
                    },
                    error => {
                        dispatch({
                            type: c.OVERVIEW__CREATIVE_DRAFT_NEW__INIT_FAIL,
                            payload: { campaignId },
                            error: error.body,
                        });
                    }
                );
        };
    },
    open(campaignId, creativeId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.OVERVIEW__CREATIVE_DRAFT_NEW__INIT_STATE,
                payload: { campaignId, creativeId },
            });

            dispatch({
                type: c.OVERVIEW__CREATIVE_DRAFT_NEW__OPEN,
                payload: { campaignId, creativeId },
            });

            dispatch(Campaigns.get(campaignId))
                .then(() => {
                    return dispatch(Files.getAll(campaignId));
                })
                .then(
                    () => {
                        const creativeAttr_prerequisite = _.pick(
                            defaultSetting.defaultValues,
                            defaultSetting.manifest.initial
                        );
                        let creativeAttr_form = {
                            ...creativeAttr_prerequisite,
                        };

                        if (creativeId) {
                            creativeAttr_form = generateCreativeForDuplication(
                                getState,
                                creativeId
                            );
                        }

                        dispatch({
                            type: c.OVERVIEW__CREATIVE_DRAFT_NEW__INIT,
                            payload: {
                                campaignId,
                                creative: creativeAttr_form,
                            },
                        });
                    },
                    error => {
                        dispatch({
                            type: c.OVERVIEW__CREATIVE_DRAFT_NEW__INIT_FAIL,
                            payload: { campaignId },
                            error: error.body,
                        });
                    }
                )
                .catch(reason => {
                    throw new Error(reason);
                });
        };
    },

    submitDraft(draft, campaignId) {
        if (!campaignId) {
            throw new Error('New Creative campaignId not found');
        }

        return (dispatch, getState) => {
            const payloadSerialized = serializeForApi(draft);

            dispatch({
                type: c.OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT,
                payload: { campaignId, payloadSerialized },
            });

            return dispatch(Creatives.create(campaignId, payloadSerialized)).then(
                response => {
                    const creative = response;

                    dispatch({
                        type: c.OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_SUCCESS,
                        payload: { campaignId, creative },
                    });

                    fetchCampaign(campaignId)(dispatch, getState);

                    // Navigate away from form
                    // const returnURL = _.get(getState(), `modal.onCloseRedirectPath`);
                    // dispatch({ type: c.SYSTEM__MODAL__CLOSE, });
                    // browserHistory.push(returnURL);

                    dispatch({
                        type: c.OVERVIEW__CREATIVE_DRAFT_NEW__CLOSE,
                        payload: { campaignId },
                    });

                    dispatch({
                        type: 'CREATIVE__CREATED',
                        payload: { campaignId, creative },
                    });
                },
                error => {
                    // throw toaster error for server errors

                    dispatch({
                        type: c.OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_FAIL,
                        error: error.body,
                        payload: {
                            campaignId,
                        },
                    });

                    return Promise.reject(error.body);
                }
            );
        };
    },

    submitDraftAndAudit(draft, campaignId) {
        return (dispatch) => {
            const isSaveAudit = true;
            return dispatch(CreativeNew.submitDraft(draft, campaignId, isSaveAudit));
        };
    },

    close(campaignId) {
        return (dispatch) => {
            dispatch({
                type: c.OVERVIEW__CREATIVE_DRAFT_NEW__CLOSE,
                payload: { campaignId },
            });
        };
    },
};


export const NEW_DIMENSIONS = [
    'geo_country',
    'geo_country_region',
    'geo_city',
    'fsa',
    'device_type_name',
    'device_language',
    'device_carrier',
    'exchange_id',
    'inventory_type',
    'inventory_id',
    'inventory_name',
    'app_store_cat',
    'pmp_id',
    'pmp_deal_name',
    'creative_size',
    'creative_channel',
    'video_length',
    'video_placement_name',
    'video_position_name',
    'conversion_lag',
    'device_model',
    'pmp_deal_source_type',
    'video_skippable',
    'audience_category',
    'audience_subcategory',
];

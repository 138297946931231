import React from 'react';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

export const TableForm = ({ onChange, data, columns }) => {
    const handleFormChange = ({ target: { id, value } }) => {
        onChange({ id, value });
    };

    return (
        <form onChange={handleFormChange}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columns.map(column => {
                            return (column.showOn && column.showOn()) || !column.showOn ? (
                                <TableCell key={column.id} padding={column.padding}>
                                    {typeof column.Header === 'function'
                                        ? column.Header()
                                        : column.Header}
                                    {column.required && '*'}
                                </TableCell>
                            ) : null;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((currentData, index) => {
                        return (
                            <TableRow key={currentData.id}>
                                {columns.map(column => {
                                    const currentValue = currentData[column.id];
                                    return (column.showOn && column.showOn()) || !column.showOn ? (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            size={column.size}
                                        >
                                            {column.Cell && typeof column.Cell === 'function'
                                                ? column.Cell({
                                                      value: currentValue,
                                                      index,
                                                      data,
                                                      currentData: currentData,
                                                      handleFormChange,
                                                  })
                                                : currentValue}
                                        </TableCell>
                                    ) : null;
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </form>
    );
};

import _ from 'lodash';
import React from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import actions from './actions';
import selector from './selector';
import { connect } from 'react-redux';
import PickerV2 from './modules/picker-v2';
import withStyles from '@mui/styles/withStyles';

const LoadingContainer = withStyles(() => ({
    root: {
        height: '100%',
    },
}))(Grid);

const getOptionMapping = memoizeOne(options => {
    const mapping = {};
    _.each(options, o => (mapping[o.value] = o));

    return mapping;
});

class AudienceSegmentPicker extends React.Component {
    static propTypes = {
        platform: PropTypes.string,
    };

    static defaultProps = {
        platform: 'inapp',
    };

    constructor(props) {
        super(props);
        this.state = {
            includedItems: this.props.includedAudiences || [],
            excludedItems: this.props.excludedAudiences || [],
            geo_targeting_settings: this.props.geo_targeting_settings,
            filters: {
                keywords: '',
                category: '',
            },
        };
    }

    componentDidMount() {
        this.props.dispatch(actions.fetchAudiencesWithGraphQl(this.props.shouldHideFlexSegments));
    }

    toggleInclude = item => {
        var isAlreadyIncluded = _.includes(this.state.includedItems, item.value);

        // if its already included, remove it
        if (isAlreadyIncluded) {
            const nextIncluded = _.filter(this.state.includedItems, value => value && value !== item.value);
            this.setState(
                {
                    includedItems: nextIncluded,
                },
                () => {
                    this.emitChange();
                }
            );
            // otherwise add it to included, and remove it from excluded
        } else {
            const nextExcluded = _.filter(this.state.excludedItems, value => value && value !== item.value);
            const nextIncluded = this.state.includedItems.filter(item => item).concat(item.value);

            this.setState(
                {
                    includedItems: nextIncluded,
                    excludedItems: nextExcluded,
                },
                () => {
                    this.emitChange();
                }
            );
        }
    };

    removeIncludedItem = includedValue => {
        const nextIncluded = _.filter(this.state.includedItems, value => value !== includedValue);

        this.setState(
            {
                includedItems: nextIncluded,
            },
            () => {
                this.emitChange();
            }
        );
    };

    emitChange = () => {
        const itemLookup = {};
        const {
            audienceSegmentOptions,
            flexSegmentsOptions,
            shouldHideFlexSegments,
        } = this.props;
        const { geo_targeting_settings } = this.state;
        _.each(audienceSegmentOptions, option => {
            itemLookup[option.value] = option;
        });
        let mappedGeoTargetingSettings = {};
        if (!shouldHideFlexSegments) {
            mappedGeoTargetingSettings = getOptionMapping(flexSegmentsOptions);
        }

        const categoryLayers = _.get(geo_targeting_settings, 'category_layers');
        const geoCategories = _.get(geo_targeting_settings, 'categories');
        const customLayers = _.get(geo_targeting_settings, 'custom_layers');

        const allFlexGeoItems = _.concat(categoryLayers, geoCategories, customLayers);

        this.props.onChange({
            includedItemValues: this.state.includedItems,
            excludedItemValues: this.state.excludedItems,

            includedItems: _.map(this.state.includedItems, value => itemLookup[value]).filter(audience => audience),
            excludedItems: _.map(this.state.excludedItems, value => itemLookup[value]).filter(audience => audience),
            geo_targeting_settings: this.state.geo_targeting_settings,
            selectedFlexSegments: _.map(
                allFlexGeoItems,
                geoItem => mappedGeoTargetingSettings[geoItem]
            ),
        });
    };

    removeExcludedItem = excludedValue => {
        const nextExcluded = _.filter(this.state.excludedItems, value => value !== excludedValue);

        this.setState(
            {
                excludedItems: nextExcluded,
            },
            () => {
                this.emitChange();
            }
        );
    };

    toggleExclude = item => {
        var isAlreadyExcluded = _.includes(this.state.excludedItems, item.value);

        // if its already excluded, remove it
        if (isAlreadyExcluded) {
            const nextExcluded = _.filter(this.state.excludedItems, value => value !== item.value);
            this.setState(
                {
                    excludedItems: nextExcluded,
                },
                () => {
                    this.emitChange();
                }
            );
            // otherwise add it to excluded, and remove it from included
        } else {
            const nextIncluded = _.filter(this.state.includedItems, value => value !== item.value);
            const nextExcluded = this.state.excludedItems.concat(item.value);

            this.setState(
                {
                    includedItems: nextIncluded,
                    excludedItems: nextExcluded,
                },
                () => {
                    this.emitChange();
                }
            );
        }
    };

    onKeywordChange = keywords => {
        this.setState({ filters: { ...this.state.filters, keywords } });
    };

    onCategoryChange = value => {
        this.setState({ filters: { ...this.state.filters, category: value } });
    };

    removeAllIncluded = () => {
        this.setState(
            {
                includedItems: [],
                geo_targeting_settings: {
                    ...this.state.geo_targeting_settings,
                    category_layers: [],
                    categories: [],
                    custom_layers: [],
                    lookbackMap: {},
                    targetingSettings: {},
                },
            },
            () => {
                this.emitChange();
            }
        );
    };
    removeAllExcluded = () => {
        this.setState(
            {
                excludedItems: [],
            },
            () => {
                this.emitChange();
            }
        );
    };

    getGeoSettingsField = source_type => {
        let fieldToChange;
        if (source_type === 'flex_standard_brand') {
            fieldToChange = 'category_layers';
        } else if (source_type === 'flex_location_category') {
            fieldToChange = 'categories';
        } else if (source_type === 'flex_custom_brand') {
            fieldToChange = 'custom_layers';
        }
        return fieldToChange;
    };

    toggleIncludeFlexSegment = ({ id, source_type }) => {
        const { geo_targeting_settings } = this.state;
        const fieldToChange = this.getGeoSettingsField(source_type);
        const isAlreadyIncluded = _.includes(geo_targeting_settings[fieldToChange], id);
        if (isAlreadyIncluded) {
            const nextIncluded = _.filter(
                geo_targeting_settings[fieldToChange],
                segment => segment !== id
            );
            const newLookbackMap = _.omit(this.state.geo_targeting_settings.lookbackMap, id);
            const newTargetingSettings = _.omit(
                this.state.geo_targeting_settings.newTargetingSettings,
                id
            );
            return this.setState(
                {
                    geo_targeting_settings: {
                        ...geo_targeting_settings,
                        [fieldToChange]: nextIncluded,
                        lookbackMap: newLookbackMap,
                        targetingSettings: newTargetingSettings,
                    },
                },
                () => this.emitChange()
            );
        } else {
            const nextIncluded = _.concat(geo_targeting_settings[fieldToChange], id);
            return this.setState(
                {
                    geo_targeting_settings: {
                        ...geo_targeting_settings,
                        [fieldToChange]: nextIncluded,
                        lookbackMap: {
                            ...geo_targeting_settings.lookbackMap,
                            [id]: 21,
                        },
                        targetingSettings: {
                            ...geo_targeting_settings.targetingSettings,
                            [id]: { target: true, retarget: true },
                        },
                    },
                },
                () => this.emitChange()
            );
        }
    };

    removeFlexSegment = ({ id, source_type }) => {
        const fieldToChange = this.getGeoSettingsField(source_type);
        const nextIncluded = _.filter(
            this.state.geo_targeting_settings[fieldToChange],
            tagId => tagId !== id
        );
        const newLookbackMap = _.omit(this.state.geo_targeting_settings.lookbackMap, id);
        const newTargetingSettings = _.omit(
            this.state.geo_targeting_settings.targetingSettings,
            id
        );

        return this.setState(
            {
                geo_targeting_settings: {
                    ...this.state.geo_targeting_settings,
                    [fieldToChange]: nextIncluded,
                    lookbackMap: newLookbackMap,
                    targetingSettings: newTargetingSettings,
                },
            },
            () => this.emitChange()
        );
    };

    changeLookbackWindow = ({ id, newLookbackValue }) => {
        const { lookbackMap } = this.state.geo_targeting_settings;
        const newLookbackMap = { ...lookbackMap, [id]: newLookbackValue };
        return this.setState(
            {
                geo_targeting_settings: {
                    ...this.state.geo_targeting_settings,
                    lookbackMap: newLookbackMap,
                },
            },
            () => this.emitChange()
        );
    };

    handleChangeTargetingSetting = ({ id, newTargetingSetting }) => {
        const { targetingSettings } = this.state.geo_targeting_settings;
        const newTargetingSettings = { ...targetingSettings, [id]: newTargetingSetting };

        return this.setState(
            {
                geo_targeting_settings: {
                    ...this.state.geo_targeting_settings,
                    targetingSettings: newTargetingSettings,
                },
            },
            () => this.emitChange()
        );
    };

    render() {
        const {
            audienceSegmentOptions,
            isLoading,
            flexSegmentsOptions,
            isCrossPlatformCampaign,
            audienceWarning,
            isCTVCampaign,
            isDOOHCampaign,
        } = this.props;

        if (isLoading) {
            return (
                <LoadingContainer container justify="center" alignItems="center">
                    <CircularProgress color="primary" />
                </LoadingContainer>
            );
        }

        const optionMapping = getOptionMapping(audienceSegmentOptions);
        let flexSegmentMapping = {};
        if (!this.props.shouldHideFlexSegments) {
            flexSegmentMapping = getOptionMapping(flexSegmentsOptions);
        }

        const rateMapping = {};

        _.each(this.props.audienceRates, rate => (rateMapping[rate.name] = rate.fee));

        const { geo_targeting_settings } = this.state;

        return (
            <PickerV2
                optionMapping={optionMapping}
                flexSegmentMapping={flexSegmentMapping}
                toggleInclude={this.toggleInclude}
                includedItems={this.state.includedItems}
                toggleExclude={this.toggleExclude}
                excludedItems={this.state.excludedItems}
                removeIncludedItem={this.removeIncludedItem}
                removeExcludedItem={this.removeExcludedItem}
                audienceRates={this.props.audienceRates}
                isLoading={isLoading}
                removeAllIncluded={this.removeAllIncluded}
                removeAllExcluded={this.removeAllExcluded}
                ownOrganizationId={this.props.ownOrganizationId}
                toggleIncludeFlexSegment={this.toggleIncludeFlexSegment}
                removeFlexSegment={this.removeFlexSegment}
                geo_targeting_settings={geo_targeting_settings}
                changeLookbackWindow={this.changeLookbackWindow}
                shouldHideFlexSegments={this.props.shouldHideFlexSegments}
                platform={this.props.platform}
                platforms={this.props.platforms}
                handleChangeTargetingSetting={this.handleChangeTargetingSetting}
                isCrossPlatformCampaign={isCrossPlatformCampaign}
                audienceWarning={audienceWarning}
                isCTVCampaign={isCTVCampaign}
                isDOOHCampaign={isDOOHCampaign}
            />
        );
    }
}

export default connect(selector)(AudienceSegmentPicker);

import _ from 'lodash';
import http from 'utils/http';

const actions = {
    getOne(businessReportId, query) {
        return (dispatch, getState) => {
            return http()
                .get(`business-reports/${businessReportId}`, query)
                .then(
                    businessReport => {
                        dispatch({
                            type: 'SYSTEM__BUSINESS_REPORT__FETCH__SUCCESS',
                            payload: { businessReport },
                        });

                        return businessReport;
                    },
                    err => {
                        console.error(`GET ONE business reports error`, err);

                        throw err;
                    }
                );
        };
    },
    getAll(query) {
        return (dispatch, getState) => {
            return http()
                .get(`business-reports`, query)
                .then(
                    response => {
                        const businessReports = _.isArray(response) ? response : response.payload;

                        dispatch({
                            type: 'SYSTEM__BUSINESS_REPORT__FETCH__SUCCESS',
                            payload: {
                                businessReports,
                            },
                        });

                        return response;
                    },
                    err => {
                        console.error(`GET ALL business reports error`, err);

                        throw err;
                    }
                );
        };
    },
    create(payload) {
        return (dispatch, getState) => {
            return http()
                .post(`business-reports`, payload)
                .then(
                    businessReport => {
                        dispatch({
                            type: 'SYSTEM__BUSINESS_REPORT__FETCH__SUCCESS',
                            payload: { businessReport },
                        });

                        return businessReport;
                    },
                    err => {
                        console.error(`CREATE business reports error`, err);

                        throw err;
                    }
                );
        };
    },
    update(businessReportId, payload) {
        return (dispatch, getState) => {
            return http()
                .patch(`business-reports/${businessReportId}`, payload)
                .then(
                    businessReport => {
                        dispatch({
                            type: 'SYSTEM__BUSINESS_REPORT__FETCH__SUCCESS',
                            payload: { businessReport },
                        });

                        return businessReport;
                    },
                    err => {
                        console.error(`UPDATE business reports error`, err);

                        throw err;
                    }
                );
        };
    },
    delete(businessReportId) {
        return (dispatch, getState) => {
            return http()
                .del(`business-reports/${businessReportId}`)
                .then(
                    businessReport => {
                        dispatch({
                            type: 'SYSTEM__BUSINESS_REPORT__DELETE__SUCCESS',
                            payload: { businessReport },
                        });

                        return businessReport;
                    },
                    err => {
                        console.error(`DELETE business reports error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default actions;

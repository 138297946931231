import React from 'react';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { Link } from 'react-router';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import MuiLink from '@mui/material/Link';
import flags from 'containers/flags/service';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import ErrorIcon from '@mui/icons-material/Error';
import makeStyles from '@mui/styles/makeStyles';

import {
    formatNumber_whole,
    formatDate_date,
    formatNumber_percentage,
    formatNumber_currency,
    formatNumber_wholeFixed,
} from 'utils/formatting';

import { isInternalUser } from 'states/profile/business-rules';
import { RevenueModelMapping } from '../../states/resources/campaigns/business-logic';
import { Notes } from './modules/notes';

const LIGHT_GREEN = '#6BDF7E';
const PALE_PINK = '#FFBFCC';
const AMBER = '#FFCE51';

const useStyles = makeStyles(theme => ({
    alertCountIssue: {
        color: theme.palette.error.main,
        position: 'absolute',
        right: 10,
        top: -10,
    },
    alertCountNotice: {
        color: theme.palette.info.main,
        position: 'absolute',
        right: 0,
        top: -10,
    },
    alertCountNoticeAlone: {
        color: theme.palette.info.main,
        position: 'absolute',
        right: 10,
        top: -10,
    },
    alertNotice: {
        color: theme.palette.info.main,
    },
}));

function getPacingColor(pacingPercentage, isEnded = false) {
    if (isEnded && pacingPercentage >= 100) {
        return LIGHT_GREEN;
    }

    if (isEnded && pacingPercentage < 100) {
        return PALE_PINK;
    }

    if (pacingPercentage >= 90) {
        return LIGHT_GREEN;
    }

    if (pacingPercentage >= 80) {
        return AMBER;
    }

    return PALE_PINK;
}

const formatCurrencyBasedOnGoal = ({ value, row: { original } }) => {
    if (original.primary_pacing === 'spend' || original.primary_pacing === 'revenue') {
        return formatNumber_currency(value);
    }

    return formatNumber_whole(value);
};

const formatDateWithTimezone = ({ value, row: { original } }) =>
    formatDate_date(value, original.timezone);

const formatExportDateWithTimezone = (value, { original }) => {
    return moment(value)
        .tz(original.timezone)
        .format('YYYY-MM-DD');
};

const formatDateDiff = value => moment(value).diff(moment(), 'days');

const formatExportNumber = value => formatNumber_wholeFixed(value, 4, '0');

const formatExportNumberBasedOnGoal = (value, { original }) => {
    if (original.primary_pacing === 'spend' || original.primary_pacing === 'revenue') {
        return formatExportNumber(value);
    }

    return formatNumber_wholeFixed(value, 0, '0');
};

const formatExportStatus = (currentValue, { values }) => {
    return `"${values.currentStatus}"`;
};

const formatExportName = value => `"${value}"`;

const formatNameToLink = ({ value, row: { original } }) => {
    if (original.type === 'Flight') {
        return (
            <Link
                to={`/campaigns/${original.id}/progress/overall?flightId=${original.flightId}`}
                color="initial"
                component={props => <MuiLink {...props} />}
            >
                <Typography variant="body1">{original.name}</Typography>
            </Link>
        );
    }

    return (
        <Link
            to={`/campaigns/${
                original.type === 'ad' ? original.campaignId : original.id
            }/progress/${original.type === 'ad' ? original.id : 'overall'}`}
            color="initial"
            component={props => <MuiLink {...props} />}
        >
            <Typography variant="body1">{value}</Typography>
        </Link>
    );
};

const formatExportAlert = value => {
    if (!value || (!value.issue && !value.notice)) {
        return 'no alerts';
    }

    let contents = '';

    if (value && value.issue) {
        contents += `${value.issue} issues`;
    }
    if (value && value.notice) {
        contents += `${value.notice} notices`;
    }

    return contents;
};

const formatAlertIcon = value => {
    const classes = useStyles();

    switch (value.icon) {
        case 'issue':
            return (
                <Box textAlign="center" position="relative">
                    <div className={classes.alertCountIssue}>{value.issue}</div>
                    {value.notice ? (
                        <div className={classes.alertCountNotice}>{value.notice}</div>
                    ) : null}
                    <NotificationImportantIcon color="error" />
                </Box>
            );
        case 'notice':
            return (
                <Box textAlign="center" position="relative">
                    <div className={classes.alertCountNoticeAlone}>{value.notice}</div>
                    <ErrorIcon className={classes.alertNotice} />
                </Box>
            );
        default:
            return null;
    }
};

const formatNotes = value => {
    return value && value.replace(/[\n\r]/g, ' ');
}

function formatSortAlert(a, b) {
    return (
        a.values.alerts.issue - b.values.alerts.issue ||
        a.values.alerts.notice - b.values.alerts.notice
    );
}

export const generateLiveAdsConfig = (handleAlertColumnClick) => ({
    columns: [
        {
            Header: 'Status',
            id: 'status',
            Cell: ({ value }) => {
                return (
                    <Box textAlign="center">
                        <Brightness1Icon htmlColor={getPacingColor(value * 100)} />
                    </Box>
                );
            },
            accessor: 'metadata.progress.progressData.currentPacing',
            maxWidth: 70,
            disableSortBy: true,
            tooltip: (
                <div>
                    Green, yellow, or red circles indicate whether the campaign or ad is pacing
                    within 90%, within 80%, or less than 80%.
                </div>
            ),
            sticky: 'left',
            exportFormat: formatExportStatus,
        },
        {
            Header: 'Alerts',
            id: 'alerts',
            accessor: 'alerts',
            Cell: ({ value }) => formatAlertIcon(value),
            maxWidth: 60,
            sticky: 'left',
            showOn: () => {
                if (flags.isEnabled('internal_show_alert_column_to_all_users')) {
                    return true;
                }
                return isInternalUser();
            },
            onClick: (e, original) => handleAlertColumnClick(e, original),
            exportFormat: value => formatExportAlert(value),
            sortType: (rowA, rowB) => formatSortAlert(rowA, rowB),
        },
        {
            Header: 'Organization',
            id: 'organization',
            accessor: 'orgName',
            maxWidth: 150,
            exportFormat: value => value,
            showOn: (conditions) => conditions.isSelfServeRoute,
            sticky: 'left',
        },
        {
            Header: 'ID',
            id: 'id',
            accessor: 'id',
            Cell: ({ value }) => `#${value}`,
            maxWidth: 85,
            sticky: 'left',
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            minWidth: 400,
            sticky: 'left',
            Cell: formatNameToLink,
            exportFormat: formatExportName,
        },
        {
            Header: 'Type',
            id: 'type',
            accessor: 'type',
            Cell: ({ value }) => capitalize(value),
            maxWidth: 85,
        },
        {
            Header: 'Creative Channel',
            id: 'creativeChannels',
            accessor: 'creativeChannels',
            Cell: ({ value }) => value && value.join(', '),
            // We're using a different separator for the export format due to csv clashing
            // when exporting. CSV uses comma separator.
            exportFormat: value => value && value.join(' - '),
            maxWidth: 120,
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Assignee',
            id: 'assigneeName',
            accessor: 'assigneeName',
            maxWidth: 150,
            exportFormat: value => value,
        },
        {
            Header: 'Start Date',
            id: 'startDate',
            accessor: 'start',
            Cell: formatDateWithTimezone,
            exportFormat: formatExportDateWithTimezone,
            maxWidth: 76,
        },
        {
            Header: 'End Date',
            id: 'endDate',
            accessor: 'end',
            Cell: formatDateWithTimezone,
            exportFormat: formatExportDateWithTimezone,
            maxWidth: 70,
        },
        {
            Header: 'Days Left',
            id: 'daysLeft',
            accessor: 'end',
            Cell: ({ value }) => formatDateDiff(value),
            maxWidth: 72,
            textAlign: 'right',
            exportFormat: formatDateDiff,
        },
        {
            Header: 'Impressions',
            id: 'impressions',
            accessor: 'metadata.metrics.impressions',
            maxWidth: 100,
            textAlign: 'right',
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Clicks',
            id: 'clicks',
            accessor: 'metadata.metrics.clicks',
            maxWidth: 72,
            textAlign: 'right',
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'CTR',
            id: 'ctr',
            accessor: 'metadata.metrics.ctr',
            Cell: ({ value }) => formatNumber_percentage(value),
            maxWidth: 72,
            textAlign: 'right',
            exportFormat: formatExportNumber,
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Total Cost eCPM',
            id: 'owner_total_media_cost_local_ecpm',
            accessor: 'metadata.metrics.owner_total_media_cost_local_ecpm',
            Cell: ({ value }) => formatNumber_currency(value),
            maxWidth: 120,
            textAlign: 'right',
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Goal',
            id: 'goal',
            accessor: 'primary_pacing',
            Cell: ({ value }) => capitalize(value),
            exportFormat: value => capitalize(value),
            maxWidth: 100,
        },
        {
            Header: 'Target',
            id: 'target',
            accessor: 'metadata.progress.progressData.totalTarget',
            maxWidth: 95,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Today',
            id: 'today',
            accessor: 'metadata.progress.progressData.deliveredToday',
            maxWidth: 95,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Yesterday',
            id: 'yesterday',
            accessor: 'metadata.progress.progressData.deliveredYesterday',
            maxWidth: 95,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Two Days Ago',
            id: 'twoDaysAgo',
            accessor: 'metadata.progress.progressData.deliveredTwoDaysAgo',
            maxWidth: 110,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Three Days Ago',
            id: 'threeDaysAgo',
            accessor: 'metadata.progress.progressData.deliveredThreeDaysAgo',
            maxWidth: 120,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Current Status',
            id: 'currentStatus',
            accessor: 'metadata.progress.progressData.currentStatus',
            maxWidth: 101,
        },
        {
            Header: 'Current Pacing',
            id: 'currentPacing',
            accessor: 'metadata.progress.progressData.currentPacing',
            Cell: ({ value }) => formatNumber_percentage(value),
            tooltip:
                'Total amount delivered, compared with the ideal amount delivered (in the absence of inventory limitations)',
            maxWidth: 122,
            textAlign: 'right',
            exportFormat: formatExportNumber,
        },
        {
            Header: 'Delivered to Date',
            id: 'currentDelivered',
            accessor: 'metadata.progress.progressData.currentDelivered',
            Cell: formatCurrencyBasedOnGoal,
            tooltip: <div>Total amount delivered (to date)</div>,
            maxWidth: 140,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Ideal Delivery to Date',
            id: 'idealDelivery',
            accessor: 'metadata.progress.progressData.idealDelivered',
            Cell: formatCurrencyBasedOnGoal,
            tooltip: <div>Ideal delivery (to date) in the absence of inventory limitations</div>,
            minWidth: 161,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Projected Status',
            id: 'projectedStatus',
            accessor: 'metadata.progress.progressData.projectedStatus',
            maxWidth: 112,
        },
        {
            Header: 'Projected Pacing',
            id: 'projectedPacing',
            accessor: 'metadata.progress.progressData.projectedPacing',
            Cell: ({ value }) => formatNumber_percentage(value),
            maxWidth: 115,
            textAlign: 'right',
            exportFormat: formatExportNumber,
        },
        {
            Header: 'Projected Delivery',
            id: 'projectedDelivery',
            accessor: 'metadata.progress.progressData.projectedDelivery',
            Cell: formatCurrencyBasedOnGoal,
            maxWidth: 140,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Goal At Risk',
            id: 'goalAtRisk',
            accessor: original => {
                if (get(original, ['type']) === 'ad') {
                    return get(original, ['metadata', 'progress', 'progressData', 'goalAtRisk']);
                } else {
                    const revenueModel = get(original, 'revenueModel');
                    if (revenueModel === RevenueModelMapping.AgencyMargin) {
                        return get(original, [
                            'metadata',
                            'progress',
                            'progressData',
                            'revenueAtRisk',
                        ]);
                    }

                    if (
                        get(original, 'revenueModel') === RevenueModelMapping.Disabled ||
                        (get(original, 'revenueModel') === RevenueModelMapping.CPMCPC &&
                            get(original, 'budget_allocation_method') === 'auto')
                    ) {
                        return get(original, [
                            'metadata',
                            'progress',
                            'progressData',
                            'spendAtRisk',
                        ]);
                    }

                    return get(original, [
                        'metadata',
                        'progress',
                        'progressData',
                        'goalAtRisk',
                    ]);
                }
            },
            Cell: ({ value, row: { original } }) => {
                return get(original, 'revenueModel') === RevenueModelMapping.AgencyMargin
                    ? 'N/A'
                    : formatCurrencyBasedOnGoal({ value, row: { original } });
            },
            maxWidth: 100,
            textAlign: 'right',
            exportFormat: (value, { original }) => {
                return get(original, 'revenueModel') === RevenueModelMapping.AgencyMargin
                    ? 'N/A'
                    : formatExportNumberBasedOnGoal(value, { original });
            },
        },
        {
            Header: 'Revenue At Risk',
            id: 'revenueAtRisk',
            accessor: 'metadata.progress.progressData.revenueAtRisk',
            Cell: ({ value, row: { original } }) => {
                return get(original, 'revenueModel') === RevenueModelMapping.Disabled ||
                    ([RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(get(original, 'revenueModel')) &&
                            get(original, 'budget_allocation_method') === 'auto')
                    ? 'N/A'
                    : formatNumber_currency(value);
            },
            maxWidth: 120,
            textAlign: 'right',
            exportFormat: (value, { original }) => {
                return get(original, 'revenueModel') === RevenueModelMapping.Disabled ||
                    ([RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(get(original, 'revenueModel')) &&
                            get(original, 'budget_allocation_method') === 'auto')
                    ? 'N/A'
                    : formatExportNumber(value);
            },
        },
        {
            Header: 'FTA Enabled',
            id: 'fta',
            accessor: original => {
                if (get(original, ['type']) === 'ad') {
                    return original.fta ? original.fta.enabled : false;
                }

                return original.ftaEnabled;
            },
            Cell: ({ row: { original } }) => {
                if (get(original, ['type']) === 'ad') {
                    return original.fta && original.fta.enabled ? 'Enabled' : 'Disabled';
                }

                return original.ftaEnabled ? 'Enabled' : 'Disabled';
            },
            exportFormat: value => {
                return value ? 'Enabled' : 'Disabled';
            },
            maxWidth: 100,
        },
        {
            Header: 'Notes',
            id: 'notes',
            accessor: 'notes',
            exportFormat: formatNotes,
            maxWidth: 150,
            Cell: ({ value, row: { original } }) => {
                return get(original, 'notes') ? <Notes>{value}</Notes> : 'N/A';
            },
        },
    ],
    hiddenColumns: ['type', 'goal', 'fta', 'notes'],
    sortBy: [
        {
            id: 'daysLeft',
            desc: false,
        },
    ],
});

export const recentlyEndedAdsConfig = {
    columns: [
        {
            Header: 'Status',
            id: 'status',
            Cell: ({ value }) => <Brightness1Icon htmlColor={getPacingColor(value * 100, true)} />,
            accessor: 'metadata.progress.progressData.currentPacing',
            maxWidth: 70,
            disableSortBy: true,
            tooltip: (
                <div>
                    Green, yellow, or red circles indicate whether the campaign or ad is delivered
                    equal to 100%, within 80%, or less than 80% of its target.
                </div>
            ),
            sticky: 'left',
            textAlign: 'center',
            exportFormat: (currentValue, { values }) => values.result,
        },
        {
            Header: 'Organization',
            id: 'organization',
            accessor: 'orgName',
            maxWidth: 150,
            exportFormat: value => value,
            showOn: (conditions) => conditions.isSelfServeRoute,
            sticky: 'left',
        },
        {
            Header: 'ID',
            id: 'id',
            accessor: 'id',
            Cell: ({ value }) => `#${value}`,
            maxWidth: 85,
            sticky: 'left',
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            minWidth: 400,
            Cell: formatNameToLink,
            sticky: 'left',
            exportFormat: formatExportName,
        },
        {
            Header: 'Type',
            id: 'type',
            accessor: 'type',
            Cell: ({ value }) => capitalize(value),
            maxWidth: 90,
            exportFormat: value => capitalize(value),
        },
        {
            Header: 'Creative Channel',
            id: 'creativeChannels',
            accessor: 'creativeChannels',
            Cell: ({ value }) => value && value.join(', '),
            // We're using a different separator for the export format due to csv clashing
            // when exporting. CSV uses comma separator.
            exportFormat: value => value && value.join(' - '),
            maxWidth: 120,
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Assignee',
            id: 'assigneeName',
            accessor: 'assigneeName',
            maxWidth: 150,
        },
        {
            Header: 'Start Date',
            id: 'startDate',
            accessor: 'start',
            Cell: formatDateWithTimezone,
            exportFormat: formatExportDateWithTimezone,
            maxWidth: 76,
        },
        {
            Header: 'End Date',
            id: 'endDate',
            accessor: 'end',
            Cell: formatDateWithTimezone,
            exportFormat: formatExportDateWithTimezone,
            maxWidth: 76,
        },
        {
            Header: 'Days Ended',
            id: 'daysEnded',
            accessor: 'end',
            Cell: ({ value }) => Math.abs(formatDateDiff(value)),
            maxWidth: 86,
            textAlign: 'right',
            exportFormat: value => Math.abs(formatDateDiff(value)),
        },
        {
            Header: 'Impressions',
            id: 'impressions',
            accessor: 'metadata.metrics.impressions',
            maxWidth: 100,
            textAlign: 'right',
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Clicks',
            id: 'clicks',
            accessor: 'metadata.metrics.clicks',
            maxWidth: 72,
            textAlign: 'right',
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'CTR',
            id: 'ctr',
            accessor: 'metadata.metrics.ctr',
            Cell: ({ value }) => formatNumber_percentage(value),
            maxWidth: 72,
            textAlign: 'right',
            exportFormat: formatExportNumber,
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Total Cost eCPM',
            id: 'owner_total_media_cost_local_ecpm',
            accessor: 'metadata.metrics.owner_total_media_cost_local_ecpm',
            Cell: ({ value }) => formatNumber_currency(value),
            maxWidth: 120,
            textAlign: 'right',
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Goal',
            id: 'goal',
            accessor: 'primary_pacing',
            Cell: ({ value }) => capitalize(value),
            exportFormat: value => capitalize(value),
            maxWidth: 100,
        },
        {
            Header: 'Target',
            id: 'target',
            accessor: 'metadata.progress.progressData.totalTarget',
            maxWidth: 90,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Today',
            id: 'today',
            accessor: 'metadata.progress.progressData.deliveredToday',
            maxWidth: 80,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Yesterday',
            id: 'yesterday',
            accessor: 'metadata.progress.progressData.deliveredYesterday',
            maxWidth: 80,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Two Days Ago',
            id: 'twoDaysAgo',
            accessor: 'metadata.progress.progressData.deliveredTwoDaysAgo',
            maxWidth: 110,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Three Days Ago',
            id: 'threeDaysAgo',
            accessor: 'metadata.progress.progressData.deliveredThreeDaysAgo',
            maxWidth: 120,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Result',
            id: 'result',
            accessor: 'metadata.progress.progressData.result',
            maxWidth: 115,
        },
        {
            Header: 'Delivered %',
            id: 'deliveredRatio',
            accessor: 'metadata.progress.progressData.deliveredRatio',
            maxWidth: 101,
            tooltip: <div>Total amount delivered compared with its target</div>,
            Cell: ({ value }) => `${Math.round(value * 100) / 100}%`,
            textAlign: 'right',
            exportFormat: formatExportNumber,
        },
        {
            Header: 'Delivered',
            id: 'currentDelivered',
            accessor: 'metadata.progress.progressData.currentDelivered',
            Cell: formatCurrencyBasedOnGoal,
            tooltip: <div>Total amount delivered (to date)</div>,
            maxWidth: 90,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'Variance',
            id: 'variance',
            accessor: 'metadata.progress.progressData.variance',
            maxWidth: 90,
            tooltip: <div>Difference between total amount delivered and target</div>,
            Cell: formatCurrencyBasedOnGoal,
            textAlign: 'right',
            exportFormat: formatExportNumberBasedOnGoal,
        },
        {
            Header: 'FTA Enabled',
            id: 'fta',
            accessor: original => {
                if (get(original, ['type']) === 'ad') {
                    return original.fta ? original.fta.enabled : false;
                }

                return original.ftaEnabled;
            },
            Cell: ({ row: { original } }) => {
                if (get(original, ['type']) === 'ad') {
                    return original.fta && original.fta.enabled ? 'Enabled' : 'Disabled'
                }

                return original.ftaEnabled ? 'Enabled' : 'Disabled';
            },
            exportFormat: value => {
                return value ? 'Enabled' : 'Disabled';
            },

            maxWidth: 100,
        },
        {
            Header: 'Notes',
            id: 'notes',
            accessor: 'notes',
            exportFormat: formatNotes,
            maxWidth: 150,
            Cell: ({ value, row: { original } }) => {
                return get(original, 'notes') ? <Notes>{value}</Notes> : 'N/A';
            },
        },
    ],
    hiddenColumns: ['type', 'goal', 'fta', 'notes'],
    sortBy: [
        {
            id: 'daysEnded',
            desc: false,
        },
    ],
};

export const upcomingAdsConfig = {
    columns: [
        {
            Header: 'ID',
            id: 'id',
            accessor: 'id',
            Cell: ({ value }) => `#${value}`,
            maxWidth: 85,
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            minWidth: 400,
            Cell: formatNameToLink,
            exportFormat: formatExportName,
        },
        {
            Header: 'Type',
            id: 'type',
            accessor: 'type',
            Cell: ({ value }) => capitalize(value),
            maxWidth: 80,
            exportFormat: value => capitalize(value),
        },
        {
            Header: 'Assignee',
            id: 'assigneeName',
            accessor: 'assigneeName',
            maxWidth: 150,
        },
        {
            Header: 'Organization',
            id: 'organization',
            accessor: 'orgName',
            maxWidth: 150,
            exportFormat: value => value,
            showOn: (conditions) => conditions.isSelfServeRoute,
        },
        {
            Header: 'Start Date',
            id: 'startDate',
            accessor: 'start',
            Cell: formatDateWithTimezone,
            exportFormat: formatExportDateWithTimezone,
            maxWidth: 76,
        },
        {
            Header: 'End Date',
            id: 'endDate',
            accessor: 'end',
            Cell: formatDateWithTimezone,
            exportFormat: formatExportDateWithTimezone,
            maxWidth: 70,
        },
        {
            Header: 'Days Until Start',
            id: 'daysStart',
            accessor: 'start',
            Cell: ({ value }) => formatDateDiff(value),
            maxWidth: 110,
            textAlign: 'right',
            exportFormat: formatDateDiff,
        },
        {
            Header: 'Goal',
            id: 'goal',
            accessor: 'primary_pacing',
            Cell: ({ value }) => capitalize(value),
            textAlign: 'right',
            exportFormat: value => capitalize(value),
            maxWidth: 100,
        },
        {
            Header: 'Target',
            id: 'target',
            accessor: 'metadata.progress.progressData.totalTarget',
            maxWidth: 90,
            Cell: ({ value }) => formatNumber_whole(value),
            textAlign: 'right',
            exportFormat: formatExportNumber,
        },
        {
            Header: 'FTA Enabled',
            id: 'fta',
            accessor: original => {
                if (get(original, ['type']) === 'ad') {
                    return original.fta ? original.fta.enabled : false;
                }

                return original.ftaEnabled;
            },
            Cell: ({ row: { original } }) => {
                if (get(original, ['type']) === 'ad') {
                    return original.fta && original.fta.enabled ? 'Enabled' : 'Disabled';
                }

                return original.ftaEnabled ? 'Enabled' : 'Disabled';
            },
            exportFormat: value => {
                return value ? 'Enabled' : 'Disabled';
            },
            maxWidth: 100,
        },
        {
            Header: 'Notes',
            id: 'notes',
            accessor: 'notes',
            minWidth: 350,
            maxWidth: 400,
            Cell: ({ value, row: { original } }) => {
                return get(original, 'notes') ? <Notes>{value}</Notes> : 'N/A';
            },
            exportFormat: formatNotes,
        },
    ],
    hiddenColumns: ['type', 'goal', 'fta', 'notes'],
    sortBy: [
        {
            id: 'daysStart',
            desc: false,
        },
    ],
};

export default ({
    onAlertColumnClick,
}) => {
    const liveAdsConfig = generateLiveAdsConfig(onAlertColumnClick);

    return {
        liveAdsConfig,
        recentlyEndedAdsConfig,
        upcomingAdsConfig,
    }
}

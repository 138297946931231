import _ from 'lodash';
import { createSelector } from 'reselect';

export default function selector(storeState, props) {
    const poisEditorState = _.get(storeState, 'poisEditor');
    const poiActionsState = _.get(storeState, 'resources.pois');
    const ownOrgId = _.get(storeState, 'profile.organizationId');
    const geoResources = _.get(storeState, 'resources.geoCategories');

    return {
        poisEditorState,
        poiActionsState,
        ownOrgId,
        geoResources,
    };
}

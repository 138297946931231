import _ from 'lodash';
import schema from './schema';

import { isMaxFta } from 'states/resources/custom-dimensions/business-logic';

export const NAME = 'customDimensionsOverview';

const initialState = {
    dimensionDraft: {
        name: '',
        contentType: 'ad',
        sendToFta: false,
        hidden: false,
        isPrimaryDimension: false,
        groups: [],
    },
};

const handlers = {
    CUSTOM_DIMENSIONS_OVERVIEW__FETCH_DATA__START: (state, action) => {
        return {
            ...state,
            loading: action.payload.loading,
        };
    },

    CUSTOM_DIMENSIONS_OVERVIEW__FETCH_DATA__END: (state, action) => {
        return {
            ...state,
            customDimensions: action.payload.data.customDimensions,
            ads: action.payload.data.ads,
            creatives: action.payload.data.creatives,
            loading: false,
            primaryDimension: action.payload.data.primaryDimension,
            activeDimension: action.payload.data.customDimensions[0],
            campaign: action.payload.data,
        };
    },

    CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_NAME: (state, action) => {
        return updateDimensionDraft(state, {
            name: action.payload.name,
        });
    },

    CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_CONTENTTYPE: (state, action) => {
        return updateDimensionDraft(state, {
            contentType: action.payload.contentType,
        });
    },

    CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_SENDTOFTA: (state, action) => {
        return updateDimensionDraft(state, {
            sendToFta: action.payload.sendToFta,
        });
    },

    CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_HIDDEN: (state, action) => {
        return updateDimensionDraft(state, {
            hidden: action.payload.hidden,
        });
    },

    CUSTOM_DIMENSIONS_OVERVIEW__CHANGE_DIMENSION_ISPRIMARYDIMENSION: (state, action) => {
        return updateDimensionDraft(state, {
            isPrimaryDimension: action.payload.isPrimaryDimension,
        });
    },

    CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__START: (state, action) => {
        return {
            ...state,
            dimensionDraftLoading: true,
        };
    },

    CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__SUCCESS: (state, action) => {
        return {
            ...state,
            dimensionDraftLoading: false,
            showErrors: false,
            errors: {},
        };
    },

    CUSTOM_DIMENSIONS_OVERVIEW__SAVE_DIMENSION__ERROR: (state, action) => {
        return {
            ...state,
            dimensionDraftLoading: false,
            showErrors: true,
            dimensionDraftError: action.error,
        };
    },

    CUSTOM_DIMENSIONS_OVERVIEW__UPDATE_DIMENSION_DRAFT: (state, action) => {
        return {
            ...state,
            dimensionDraft: {
                ..._.omit(action.payload.dimension, 'id'),
                isPrimaryDimension: state.primaryDimension === action.payload.dimension.id,
            },
            activeDimension: action.payload.dimension,
        };
    },

    CUSTOM_DIMENSIONS_OVERVIEW__RESET_DIMENSION_DRAFT: (state, action) => {
        return {
            ...state,
            dimensionDraft: initialState.dimensionDraft,
        };
    },
};

function validateSchema(draft) {
    const options = {
        allowUnknown: true,
        abortEarly: false,
    };

    const result = schema.validate(draft, options);

    if (!result.error) {
        return {};
    }

    const errors = {};

    _.each(result.error.details, detail => {
        errors[detail.path.join('.')] = detail.message;
    });

    return errors;
}

function validateBusinessLogic(draft, customDimensions) {
    const errors = {};

    if (isMaxFta(customDimensions) && draft.sendToFta === true) {
        errors['sendToFta'] = 'Maximum number of dimensions reached for sending FTA metadata';
    }

    return errors;
}

function updateState(state) {
    const schemaErrors = validateSchema(state.dimensionDraft);
    const businessErrors = validateBusinessLogic(state.dimensionDraft, state.customDimensions);

    return {
        ...state,
        errors: {
            ...schemaErrors,
            ...businessErrors,
        },
    };
}

function updateDimensionDraft(state, changes) {
    return {
        ...state,
        dimensionDraft: {
            ...state.dimensionDraft,
            ...changes,
        },
    };
}

export default function reducer(state = initialState, action) {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
    }

    if (nextState === state) {
        return state;
    } else {
        return updateState(nextState);
    }
}

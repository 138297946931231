import find from 'lodash/find';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import get from 'lodash/get';
import Joi from '@hapi/joi';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';

import iabCategories from 'common/constants/iab-categories';
import { CampaignTypeMapping, RevenueModelMapping } from '../../../states/resources/campaigns/business-logic';
const iabCategoryKeys = Object.keys(iabCategories);

export const schema = Joi.object({
    name: Joi.string().required(),
    iab_categories: Joi.array()
        .items(Joi.string())
        .min(1)
        .allow(...iabCategoryKeys),
    advertiser_domain: Joi.string()
        .required()
        .custom((value, helper) => {
            if (/www/.test(value) || /http/.test(value)) {
                return helper.message('Do not include the "http://" or "www." prefixes');
            }

            return value;
        })
        .messages({
            'string.empty': "You must provide the advertiser's domain name, e.g. abc.com",
        }),
    owner: Joi.string().required(),
    clients: Joi.array().items(
        Joi.object({
            organization: Joi.string().required(),
            type: Joi.string().required(),
            shared: Joi.bool().required(),
        })
    ),
    default_timezone: Joi.string().required(),
    notes: Joi.string()
        .optional()
        .allow(''),
    third_party_fees: Joi.array().items(
        Joi.object({
            description: Joi.string().required(),
            billing_model: Joi.string()
                .required()
                .allow('cpm', 'fixed'),
            fee: Joi.number().required(),
            billable: Joi.bool().required(),
        })
    ),
    currency: Joi.string().required(),
    billing_enabled: Joi.bool().required(),
    campaign_budget_enabled: Joi.bool().required(),
    total_billings_local: Joi.number().optional(),
    campaign_max_total_spend_local: Joi.number().required(),
    billing_rate: Joi.number().required(),
    billing_term: Joi.string().required(),
    custom_fields: Joi.array().items(
        Joi.object({
            key: Joi.string().required(),
            name: Joi.string().required(),
            required: Joi.bool().required(),
            value: Joi.any(),
        })
    ),
    flightPacingStrategy: Joi.string().required(),
    flights: Joi.array().items(
        Joi.object({
            name: Joi.string().required(),
            notes: Joi.any(),
            start: Joi.string().required(),
            end: Joi.string().required(),
            total_cost_budget: Joi.number(),
            total_impressions: Joi.number(),
            billings_local: Joi.number(),
        })
    ),
    revenueModel: Joi.string()
        .required()
        .allow('disabled', 'cpccpm', 'agencyMargin', 'totalSpendMarkup'),
    creatorId: Joi.string().required(),
    fta_enabled: Joi.bool().required(),
    fta_management_level: Joi.string().allow(null, 'campaign', 'ad'),
    fta_location_list: Joi.string()
        .required()
        .allow(null),
    fta_partner_id: Joi.string().allow(null),
    fta_conversion_lookback_window: Joi.object({
        type: Joi.string().allow('limited', 'unlimited'),
        lookback_window_days: Joi.number().allow(null),
    }),
    fta_pre_campaign_interval_days: Joi.number()
        .min(0)
        .max(90)
        .required(),
    fta_post_campaign_interval_days: Joi.number()
        .min(0)
        .max(90)
        .required(),
    fta_lift_version: Joi.string().allow('standard', 'lookalike', null),
    isUsingFtaFullyManagedFields: Joi.bool().required(),
    use_front_load_pacing: Joi.bool().required(),
    campaign_max_total_impressions: Joi.number().min(0),
    campaign_primary_pacing: Joi.string().required(),
});

export function customValidator(args) {
    const { draft, originalCampaign, flightMetrics, campaignMetadata } = args;
    let errors = {};
    errors = validateAdvertiser({ errors, draft });
    errors = validateTotalBillingsLocal({ errors, draft });
    errors = validateCampaignSpend({ errors, draft, campaignMetadata, originalCampaign });
    errors = validateFta({ errors, draft });
    errors = validateFlightBudget({ errors, draft, flightMetrics });
    errors = validateFlightsRevenueBudget({ errors, draft });
    errors = validateTotalImpressions({ errors, draft, campaignMetadata });
    errors = validateFlightImpressions({ errors, draft, flightMetrics });
    errors = validateRestrictedCategory({ errors, draft });
    errors = validateCustomFields({ errors, draft });

    return errors;
}

function validateFlightBudget({ errors, draft, flightMetrics }) {
    const err = { ...errors };
    const { flights } = draft;
    forEach(flights, (flight, index) => {
        if (
            !flight.total_cost_budget &&
            ![RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(draft?.revenueModel)
        ) {
            err[`flights.${index}.total_impressions`] = `Budget is required`;
        } else if (flightMetrics && flightMetrics.length) {
            const currentFlight = find(flightMetrics, metric => metric.flightId === flight._id);
            const isFlightEnded = new Date(flight.end) < new Date();
            const spent = currentFlight ? currentFlight.owner_total_media_cost_local : 0;
            const flightBudget = flight.total_cost_budget || 0;
            const budgetLesserThanSpent =
                Number(flightBudget.toFixed(2)) < Number(spent.toFixed(2));
            if (!isFlightEnded && budgetLesserThanSpent) {
                const spentLabel = formatNumber_currency(spent);
                err[
                    `flights.${index}.billings_local`
                ] = `Budget cannot be lower than existing spend (${spentLabel})`;
            }
        }
    });
    return err;
}

function validateFlightImpressions({ errors, draft, flightMetrics }) {
    const err = { ...errors };
    const { flights } = draft;
    forEach(flights, (flight, index) => {
        if (
            !flight.total_impressions &&
            [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(draft?.revenueModel)
        ) {
            err[`flights.${index}.total_impressions`] = `Total impressions required`;
        } else if (
            flightMetrics &&
            flightMetrics.length &&
            [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(draft?.revenueModel)
        ) {
            const currentFlight = find(flightMetrics, metric => metric.flightId === flight._id);
            const spent = currentFlight ? currentFlight.impressions : 0;
            if (flight.total_impressions < spent) {
                const spentLabel = formatNumber_whole(spent);
                err[
                    `flights.${index}.total_impressions`
                ] = `Impressions cannot be lower than already delivered (${spentLabel})`;
            }
        }
    });
    return err;
}

function validateFlightsRevenueBudget({ errors, draft }) {
    const err = { ...errors };
    const { flights, revenueModel, flightPacingStrategy } = draft;

    if (revenueModel === 'agencyMargin' && flightPacingStrategy === 'campaign') {
        forEach(flights, (flight, index) => {
            if (flight.billings_local === 0) {
                err[`flights.${index}.billings_local`] = `Revenue Budget can't be empty`;
            }
        });
    }

    return err;
}

function validateAdvertiser({ errors, draft }) {
    const err = { ...errors };
    const advertisers = filter(draft.clients, { type: 'advertiser' });
    if (advertisers.length === 0) {
        err.advertiser = 'Required field';
    }

    return err;
}

function validateTotalBillingsLocal({ errors, draft }) {
    const err = { ...errors };
    const { revenueModel, total_billings_local } = draft;

    if (revenueModel === 'agencyMargin' && !total_billings_local) {
        err.total_billings_local = 'Required field';
    }

    return err;
}

function validateCampaignSpend({ errors, draft, campaignMetadata, originalCampaign }) {
    const err = { ...errors };
    const { budget_allocation_method, campaign_max_total_spend_local, revenueModel } = draft;
    if (campaignMetadata && campaignMetadata.metrics && originalCampaign && campaign_max_total_spend_local !== 0) {
        if (
            campaign_max_total_spend_local !== originalCampaign.campaign_max_total_spend_local &&
            parseFloat(parseFloat(campaign_max_total_spend_local).toFixed(2)) <
                parseFloat(parseFloat(campaignMetadata.metrics.owner_total_media_cost_local).toFixed(2))
        ) {
            const spendLabel = formatNumber_currency(campaignMetadata.metrics.owner_total_media_cost_local);
            err.campaign_max_total_spend_local = `Budget cannot be lower than delivered Total Cost (${spendLabel})`;
        }
    }

    const useDisabledRevenueModelAndAutoBudgetAllocation =
        revenueModel === 'disabled' &&
        budget_allocation_method &&
        budget_allocation_method === 'auto';

    if (useDisabledRevenueModelAndAutoBudgetAllocation && campaign_max_total_spend_local === 0) {
        err.campaign_max_total_spend_local = `Budget is required if auto budget allocation is selected.`;
    }

    return err;
}

function validateFta({ errors, draft }) {
    const err = { ...errors };
    const {
        fta_enabled,
        fta_version,
        fta_location_list,
        fta_management_level,

        fta_conversion_lookback_window,
        isUsingFtaFullyManagedFields,
    } = draft;

    if (fta_version === 2) {
        if (fta_enabled && !fta_management_level) {
            err.fta_management_level = 'Management level is required';
        }

        if (fta_enabled && fta_management_level === 'campaign' && !fta_location_list) {
            err.fta_location_list = 'Location List is required';
        }
    }

    if (isUsingFtaFullyManagedFields) {
        if (
            fta_conversion_lookback_window.type === 'limited' &&
            !fta_conversion_lookback_window.lookback_window_days
        ) {
            err.fta_conversion_lookback_window = 'Number of days is required when type is limited';
        }
    }

    return err;
}

function validateTotalImpressions({ errors, draft, campaignMetadata }) {
    const err = { ...errors };
    const validTotalImpressions =
        !campaignMetadata ||
        !campaignMetadata.progressData ||
        campaignMetadata.progressData.currentDelivered < draft.campaign_max_total_impressions;

    if (
        [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(draft?.revenueModel) &&
        draft.budget_allocation_method === 'auto'
    ) {
        if (draft.campaign_max_total_impressions <= 0) {
            err.campaign_max_total_impressions = 'Total Impressions is required';
        } else if (!validTotalImpressions) {
            err.campaign_max_total_impressions = `Can\'t be less than impressions already delivered (${get(
                campaignMetadata,
                ['progressData', 'currentDelivered'],
                0
            )})`;
        }
    }

    return err;
}

export function validateRestrictedCategory({ errors, draft }) {
    const err = { ...errors };

    if (draft.restricted_category === 'cannabis' && draft.type === CampaignTypeMapping.CTV) {
        err.restricted_category = 'Cannabis campaigns cannot target Connected TVs due to the shared nature of the device.';
    }

    if (draft.restricted_category === 'cannabis' && draft.type === CampaignTypeMapping.DOOH) {
        err.restricted_category = 'Cannabis campaigns cannot target Digital Out-of-Home devices.';
    }

    return err;
}

export function validateCustomFields({ errors, draft }) {
    const err = { ...errors };

    if (draft.custom_fields && draft.custom_fields.length !== 0) {
        draft.custom_fields.forEach(({ name, value, required }) => {
            if (required && !value) {
                // Field is required but has no value, so we need to show an error for the user.
                if (err.custom_fields) {
                    err.custom_fields.push({ value: `${name} cannot be empty` });
                } else {
                    err.custom_fields = [{value: `${name} cannot be empty` }]
                }
            }
        });
    }

    return err;
}

import _ from 'lodash';
import c from 'common/constants/flux-events';

function getInitialState() {
    let apiMode;
    let environment;

    if (typeof localStorage !== 'undefined') {
        apiMode = localStorage.getItem('devSettings.apiMode');
        environment = localStorage.getItem('devSettings.environment');
    }

    if (!apiMode || apiMode === 'null') {
        apiMode = 'remote';
    }
    if (!environment || environment === 'null') {
        environment = 'production';
    }

    return {
        isOpen: false,
        isLoading: false,

        apiMode,
        environment,

        branches: [],
    };
}

export const NAME = 'devSettings';

export default function devSettings(state = getInitialState(), action) {
    switch (action.type) {
        case c.DEV_SETTINGS__TOGGLE_ENVIRONMENT:
            const environment = action.payload.env;

            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('devSettings.environment', environment);
            }

            return {
                ...state,
                environment,
            };

        case c.DEV_SETTINGS__TOGGLE_ENDPOINT_MODE:
            const apiMode = state.apiMode === 'remote' ? 'local' : 'remote';

            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('devSettings.apiMode', apiMode);
            }

            return {
                ...state,
                apiMode,
            };

        case c.LOGOUT: {
            // Resets the storage mechanism
            return getInitialState();
        }

        default:
            return state;
    }
}

import _ from 'lodash';

export default function selector(storeState) {
    const draft = _.get(storeState, 'adminOrgForm.draft');
    const errors = _.get(storeState, 'adminOrgForm.errors');
    const showErrors = _.get(storeState, 'adminOrgForm.showErrors');
    const mode = _.get(storeState, 'adminOrgForm.mode');
    const relationship = _.get(storeState, 'adminOrgForm.relationship');
    return {
        draft,
        errors,
        showErrors,
        mode,
        relationship,
    };
}

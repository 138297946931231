import _ from 'lodash';
import flatten from 'lodash/flatten';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
/** import mui components in the section above, keep the ascending order */

import { MultiSelect } from 'widgets-v6/select';
/** import widgets-v6 components in the section above, keep the ascending order */

import { getAllowedPlatformOptions } from 'states/resources/ads/business-logic';
import { getExchangeOptions } from 'states/resources/ads/business-logic';
import { getExchangesBlocked } from 'containers/ad-summary';
import { getExchangeTargetingWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import BaseColumnForm from '../base-column-form';
import shallowCompare from 'utils/shallow-compare';
/** import classes and others in the section above, keep the ascending order */

const InvetoryIconContainer = withStyles(() => ({
    root: {
        paddingRight: '9px',
        flexBasis: '4.33%',
    },
}))(Grid);
const TitleContainer = withStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
}))(Grid);

export class ExchangesSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;

        return (
            <div>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <TitleContainer container alignItems="center">
                            <InvetoryIconContainer item>
                                <CheckIcon fontSize="small" color="primary" />
                            </InvetoryIconContainer>
                            <Grid item>Included Exchanges</Grid>
                        </TitleContainer>
                        <Grid container alignItems="center" wrap="nowrap">
                            <InvetoryIconContainer />
                            <Grid item>Included: All compatible and new exchanges</Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TitleContainer container alignItems="center">
                            <InvetoryIconContainer item>
                                <BlockIcon fontSize="small" color="secondary" />
                            </InvetoryIconContainer>
                            <Grid item>Blocked Exchanges</Grid>
                        </TitleContainer>
                        <Grid container alignItems="center" wrap="nowrap">
                            <InvetoryIconContainer item />
                            <Grid item>{getExchangesBlocked(ad.exchanges_blocked_ui)}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export const ExchangesForm = props => {
    const { ad, ads, campaign, onSave, onCancel } = props;
    const ALLOWED_PLATFORMS = getAllowedPlatformOptions(campaign.type);

    const adPlatforms = ad
        ? _.get(ad, 'platforms')
        : _(ads)
              .map(ad => ad.platforms)
              .flatten()
              .uniq()
              .value();

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Exchanges"
            fields={['exchanges_blocked_ui', 'exchanges_blocked']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onCancel}
            columnName="Exchanges"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                let warningData = {};
                if (campaign.isCrossPlatformCampaign) {
                    warningData = getExchangeTargetingWarning({
                        platforms: adPlatforms,
                        exchangesBlocked: draft.exchanges_blocked,
                    });
                }

                return (
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container alignItems="center">
                                <InvetoryIconContainer item>
                                    <CheckIcon color="primary" />
                                </InvetoryIconContainer>
                                <Grid item>Include</Grid>
                            </Grid>
                            <Grid container alignItems="center" wrap="nowrap">
                                <InvetoryIconContainer item />
                                <Grid item>Included: All compatible and new exchanges</Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center">
                                <InvetoryIconContainer item>
                                    <BlockIcon color="secondary" />
                                </InvetoryIconContainer>
                                <Grid item>Exclude</Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <InvetoryIconContainer item />
                                <Grid item xs={8}>
                                    <MultiSelect
                                        value={draft.exchanges_blocked_ui}
                                        onChange={val => {
                                            const parsedValues = val.map(currentExchange =>
                                                ALLOWED_PLATFORMS.map(
                                                    platform => `${currentExchange}_${platform}`
                                                )
                                            );
                                            updateDraft({
                                                exchanges_blocked_ui: val,
                                                exchanges_blocked: flatten(parsedValues),
                                            });
                                        }}
                                        options={getExchangeOptions(campaign)}
                                        placeholder="Select exchanges to exclude"
                                    />
                                </Grid>
                                {campaign.isCrossPlatformCampaign && warningData.hasWarning && (
                                    <Box marginTop={1} marginLeft={4} width="100%">
                                        <WarningText
                                            message={warningData.message}
                                            title={warningData.title}
                                            severity={warningData.severity}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }}
        </BaseColumnForm>
    );
};

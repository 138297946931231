import _ from 'lodash';
import c from 'common/constants/flux-events';
import { validateDraft_activate as validateDraft } from './services';
import { getDefaults } from './services';

const initialState = {
    draft: { ...getDefaults('payload_formData') },
    isDraftValid: false,
    errors: [],
    showErrors: false,
    isLoading: false,
    isOpen: false,
    isSubmiting: false,
    isDirty: false,
};

export const NAME = 'activate';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.ACTIVATION__DRAFT__OPEN: {
            return {
                ...state,
                isLoading: true,
                isOpen: true,
            };
        }

        case c.ACTIVATION__DRAFT__FETCH__SUCCESS: {
            return {
                ...state,
            };
        }

        case c.ACTIVATION__DRAFT__FETCH__FAIL: {
            return {
                ...state,
            };
        }

        case c.ACTIVATION__DRAFT__INIT: {
            const activateAttr = _.get(action, 'payload.activateAttr');
            const activateDraft = {
                ...state.draft,
                ...activateAttr,
            };

            const errors = validateDraft(activateDraft);

            const activation_token = _.get(action, 'payload.activation_token');
            const token_default = getDefaults('payload_nonformData').activation_token;

            return {
                ...state,
                errors,
                isDraftValid: errors.length === 0,
                draft: activateDraft,
                isLoading: false,
                isDirty: false,
                activation_token: activation_token ? activation_token : token_default,
            };
        }
        case c.ACTIVATION__DRAFT__UPDATE: {
            const { formData } = action.payload;

            const nextDraft = {
                ...state.draft,
                ...formData,
            };

            const errors = validateDraft(nextDraft);

            return {
                ...state,
                draft: nextDraft,
                isDirty: true,
                errors,
                isDraftValid: errors.length === 0,
            };
        }

        case c.ACTIVATION__DRAFT__SHOW_ERRORS: {
            return {
                ...state,
                showErrors: true,
            };
        }

        case c.ACTIVATION__DRAFT__SUBMIT: {
            return {
                ...state,
                isSubmiting: true,
            };
        }

        case c.ACTIVATION__DRAFT__SUBMIT_FAIL: {
            const errors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));

            return {
                ...state,
                errors,
                showErrors: true,
                isLoading: false,
                isSubmiting: false,
                isDraftValid: false,
            };
        }

        case c.ACTIVATION__DRAFT__SUBMIT_SUCCESS:
            return {
                ...state,
                isSubmiting: false,
            };

        case c.ACTIVATION__DRAFT__CLOSE: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
}

import React, { lazy, Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const HtmlEditor = lazy(() => import('widgets-v5/html-editor'));

const HtmlEditorLazy = props => {
    return (
        <Suspense fallback={<LinearProgress />}>
            <HtmlEditor {...props} />
        </Suspense>
    );
};

export default HtmlEditorLazy;

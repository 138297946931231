import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import 'moment-timezone';
import 'moment-range';
import withStyles from '@mui/styles/withStyles';
import leftPad from 'left-pad';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

function getStartOfLocalDay(timezone = 'UTC') {
    const startOfToday = moment().tz(timezone);
    return startOfToday;
}

function mergeDateTimeMoments(moments, timezone) {
    const { startDate, startTime, endDate, endTime, lastSelectableDate } = moments;

    let start = startDate
        ? moment.tz(`${startDate.format('YYYY-MM-DD')} ${startTime.format('HH:mm:ss')}`, timezone)
        : null;

    let end = endDate
        ? moment.tz(`${endDate.format('YYYY-MM-DD')} ${endTime.format('HH:mm:ss')}`, timezone)
        : null;

    if (lastSelectableDate && end && end.isAfter(lastSelectableDate)) {
        end = moment.tz(lastSelectableDate, timezone);
    }

    return { start, end };
}

const styles = {
    cursorDisable: {
        cursor: 'not-allowed',
    },
};

class DateTimeRangePicker extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        timezone: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        lastSelectableDate: PropTypes.string,
        startNow: PropTypes.bool,
        disableStart: PropTypes.bool,
        disableEnd: PropTypes.bool,
        minEndDate: PropTypes.string,
        /*
        value: React.PropTypes.shape({
            start: React.PropTypes.instanceOf(Date),
            end: React.PropTypes.instanceOf(Date),
        }).isRequired,
        */
    };

    static defaultProps = {
        resetable: true,
        placeholder: 'Select date range',
    };

    constructor(props) {
        super(props);
        const {
            value: { start, end },
            timezone,
            startNow,
        } = props;
        const now = moment().tz(timezone);

        // Immediately convert into timezone
        // All calculations internally will use this timezone
        const startDate = startNow
            ? getStartOfLocalDay(timezone)
            : start
            ? moment.tz(start, timezone)
            : null;
        const endDate = end ? moment.tz(end, timezone) : null;

        // Also create an extra pair of moments to hold
        // the time separately so that time isn't reset
        // when user has not chosen a date
        // These default to start and end of day respectively
        const startTime = startDate ? startDate.clone() : now.clone().startOf('day');
        const endTime = endDate ? endDate.clone() : now.clone().endOf('day');

        // Use start date for left calendar or current month if not available
        const leftCalendarDisplayedMonth = startDate
            ? startDate.clone().startOf('month')
            : now.clone().startOf('month');
        const rightCalendarDisplayedMonth = endDate
            ? endDate.clone().startOf('month')
            : now.clone().startOf('month');

        this.state = {
            changeHint: null,
            startDate,
            startTime,
            endDate,
            endTime,
            leftCalendarDisplayedMonth,
            rightCalendarDisplayedMonth,
        };
    }

    // TODO: currently this assumes only one prop will change at a time
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.startNow && nextProps.startNow) {
            const startOfToday = getStartOfLocalDay(this.props.timezone || nextProps.timezone);

            this.emitChange({
                startDate: startOfToday,
                startTime: startOfToday,
            });
        } else if (this.props.timezone !== nextProps.timezone) {
            const { startDate, endDate } = this.state;

            let start = {};
            if (startDate && nextProps.disableStart) {
                const startClone = startDate.clone();
                startClone.tz(nextProps.timezone);
                start.startDate = startClone;
                start.startTime = startClone;
            }

            let end = {};
            if (endDate && nextProps.disableEnd) {
                const endClone = endDate.clone();
                endClone.tz(nextProps.timezone);
                end.endDate = endClone;
                end.endTime = endClone;
            }

            this.emitChange({
                timezone: nextProps.timezone,
                ...start,
                ...end,
            });

            const now = moment().tz(nextProps.timezone);

            // Use start date for left calendar or current month if not available
            const leftCalendarDisplayedMonth = startDate
                ? startDate
                      .clone()
                      .tz(nextProps.timezone)
                      .startOf('month')
                : now.clone().startOf('month');

            const rightCalendarDisplayedMonth = endDate
                ? endDate
                      .clone()
                      .tz(nextProps.timezone)
                      .startOf('month')
                : now.clone().startOf('month');

            this.setState({
                ...start,
                ...end,
                leftCalendarDisplayedMonth,
                rightCalendarDisplayedMonth,
            });
        } else {
            const {
                value: { start, end },
                timezone,
            } = nextProps;

            // Get the latest start end dates
            const startDate = start ? moment.tz(start, timezone) : null;
            const endDate = end ? moment.tz(end, timezone) : null;

            // IF start date or end date has been unset, keep original times
            const startTime = start ? startDate.clone() : this.state.startTime;
            const endTime = end ? endDate.clone() : this.state.endTime;

            this.setState({
                startDate,
                endDate,
                startTime,
                endTime,
            });
        }
    }

    onClickLeftCalendar = (date, startTime) => {
        this.emitChange({
            startDate: date,
            startTime,
        });
    };

    onClickRightCalendar = date => {
        this.emitChange({
            endDate: date,
        });
    };

    onHoverLeftCalendar = date => {
        if (!date) {
            this.setState({
                changeHint: null,
            });
        } else {
            this.setState({
                changeHint: 'startDate',
                changeHintDay: date,
            });
        }
    };

    onHoverRightCalendar = date => {
        if (!date) {
            this.setState({
                changeHint: null,
            });
        } else {
            this.setState({
                changeHint: 'endDate',
                changeHintDay: date,
            });
        }
    };

    resetDates = () => {
        if (!this.props.disableStart && !this.props.startNow) {
            this.props.onChange({ start: '' });
        }
        if (!this.props.disableEnd) {
            this.props.onChange({ end: '' });
        }
    };

    emitChange = changes => {
        // Merge in changes with values with state and destructure
        const { startDate, startTime, endDate, endTime, timezone } = {
            startDate: this.state.startDate,
            startTime: this.state.startTime,
            endDate: this.state.endDate,
            endTime: this.state.endTime,
            timezone: this.props.timezone,
            ...changes,
        };

        const { lastSelectableDate } = this.props;

        // Merge dates and times
        const { start, end } = mergeDateTimeMoments(
            {
                startDate,
                startTime,
                endDate,
                endTime,
                lastSelectableDate: lastSelectableDate ? lastSelectableDate : null,
            },
            timezone
        );

        // Propagate changes
        this.props.onChange({
            start: start ? start.toDate() : null,
            end: end ? end.toDate() : null,
        });
    };

    setLeftCalendarDisplayMonth = month => {
        this.setState({
            leftCalendarDisplayedMonth: month,
        });
    };

    setRightCalendarDisplayMonth = month => {
        this.setState({
            rightCalendarDisplayedMonth: month,
        });
    };

    render() {
        const { startDate, endDate, startTime, endTime } = this.state;

        const {
            startNow,
            lastSelectableDate,
            disableStart,
            timezone,
            minEndDate,
            resetable,
            classes,
        } = this.props;

        // Calculate displayed strings
        let startDateString = null;
        if (this.state.changeHint === 'startDate') {
            startDateString = this.state.changeHintDay.format('MMMM D, YYYY');
        } else if (startDate) {
            startDateString = startDate.format('MMMM D, YYYY');
        }
        let endDateString = null;
        if (this.state.changeHint === 'endDate') {
            endDateString = this.state.changeHintDay.format('MMMM D, YYYY');
        } else if (endDate) {
            endDateString = endDate.format('MMMM D, YYYY');
        }

        // const startDateString = startDate ? startDate.format('YYYY-MM-DD') : null;
        const startDatePrettyString = startNow
            ? 'ASAP'
            : startDate
            ? `${startDate.format('MMMM D, YYYY')} ${startTime.format('HH:mm z')}`
            : '';
        // const endDateString = endDate ? endDate.format('YYYY-MM-DD') : null;
        const endStringPrettyString = endDate
            ? `${endDate.format('MMMM D, YYYY')} ${endTime.format('HH:mm z')}`
            : '';

        let startOfStartDate;
        if (minEndDate) {
            startOfStartDate = moment.tz(minEndDate, timezone).startOf('day');
        } else if (startDate) {
            startOfStartDate = startDate.clone().startOf('day');
        } else {
            startOfStartDate = null;
        }

        let startOfEndDate;
        if (minEndDate) {
            startOfEndDate = moment.tz(minEndDate, timezone).startOf('day');
        } else if (endDate) {
            startOfEndDate = endDate.clone().startOf('day');
        } else {
            startOfEndDate = null;
        }

        const now = moment().tz(timezone);

        const startTimeTimezone = startDate ? startTime.format('z') : null;
        const endTimeTimezone = endDate ? endTime.format('z') : null;

        let visibleHalf;
        if (this.props.visibleHalf === 'start') {
            visibleHalf = 'start';
        } else if (this.props.visibleHalf === 'end') {
            visibleHalf = 'end';
        } else {
            visibleHalf = 'both';
        }

        const isSameSelectedStartDay =
            startDate && startDate.isSame(this.props.firstSelectableDate, 'date');
        const isSameSelectedEndDay = startDate && startDate.isSame(endDate, 'date');
        const isSameSelectedEndHour = startDate && startDate.isSame(endDate, 'hour');
        const firstSelectableHour =
            this.props.firstSelectableDate &&
            `${leftPad(moment(this.props.firstSelectableDate).hour(), 2, '0')}`;
        const firstSelectableMinute =
            this.props.firstSelectableDate &&
            `${leftPad(moment(this.props.firstSelectableDate).minute(), 2, '0')}`;

        return (
            <FormControl
                variant="outlined"
                fullWidth
                margin={this.props.margin}
                error={this.props.error}
                disabled={this.props.disabled}
                className={this.props.disabled ? classes.cursorDisable : null}
            >
                {this.props.label && (
                    <Box mb={1}>
                        <FormLabel
                            component="legend"
                            focused={false}
                            required={this.props.required}
                        >
                            {this.props.label}
                        </FormLabel>
                    </Box>
                )}
                <Dropdown
                    startDate={startDatePrettyString}
                    endDate={endStringPrettyString}
                    placeholder={this.props.placeholder}
                    error={this.props.error}
                    displayText={this.props.displayText}
                    width={startNow ? 400 : 'auto'}
                    disabled={this.props.disabled}
                >
                    <React.Fragment>
                        <Grid container spacing={1}>
                            {(visibleHalf === 'both' || visibleHalf === 'start') && (
                                <Grid item xs={12} lg={visibleHalf === 'start' ? 12 : 6}>
                                    <Box textAlign="center">
                                        <div className="ef3-dateTimeRangePicker_label">Start:</div>
                                        <div
                                            className={classnames(
                                                'ef3-dateTimeRangePicker_startInfo',
                                                {
                                                    'is-hinted':
                                                        this.state.changeHint === 'startDate',
                                                }
                                            )}
                                        >
                                            {disableStart ? (
                                                <div>{startDatePrettyString}</div>
                                            ) : (
                                                <div>
                                                    {startNow
                                                        ? 'ASAP'
                                                        : startDateString
                                                        ? startDateString
                                                        : '\u2014'}
                                                    {startNow ? null : (
                                                        <TimePicker
                                                            value={startTime}
                                                            onChange={time =>
                                                                this.emitChange({ startTime: time })
                                                            }
                                                            timezone={startTimeTimezone}
                                                            isSameSelectedDay={
                                                                isSameSelectedStartDay
                                                            }
                                                            firstSelectableHour={
                                                                firstSelectableHour
                                                            }
                                                            firstSelectableMinute={
                                                                firstSelectableMinute
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </Box>
                                    {startNow || disableStart ? null : (
                                        <div>
                                            <hr className="ef3-dateTimeRangePicker_timeCalendarSeparator" />
                                            <Calendar
                                                onClick={this.onClickLeftCalendar}
                                                onHover={this.onHoverLeftCalendar}
                                                timezone={this.props.timezone}
                                                highlight={{ start: startDate, end: endDate }}
                                                primaryHighlight="start"
                                                displayedMonth={
                                                    this.state.leftCalendarDisplayedMonth
                                                }
                                                onDisplayedMonthChange={
                                                    this.setLeftCalendarDisplayMonth
                                                }
                                                lastDisplayableMonth={endDate}
                                                lastSelectableDate={startOfEndDate}
                                                firstSelectableDate={this.props.firstSelectableDate}
                                                firstSelectableHour={
                                                    isSameSelectedStartDay && firstSelectableHour
                                                }
                                            />
                                        </div>
                                    )}
                                </Grid>
                            )}
                            {(visibleHalf === 'both' || visibleHalf === 'end') && (
                                <Grid item xs={12} lg={visibleHalf === 'end' ? 12 : 6}>
                                    <Box textAlign="center">
                                        <div className="ef3-dateTimeRangePicker_label">End:</div>
                                        <div
                                            className={classnames(
                                                'ef3-dateTimeRangePicker_endInfo',
                                                {
                                                    'is-hinted':
                                                        this.state.changeHint === 'endDate',
                                                }
                                            )}
                                        >
                                            {this.props.disableEnd ? (
                                                <div>{endStringPrettyString}</div>
                                            ) : (
                                                <div>
                                                    {endDateString ? endDateString : '\u2014'}
                                                    <TimePicker
                                                        lastSelectableDate={lastSelectableDate}
                                                        currentlySelectedDate={endDate}
                                                        value={endTime}
                                                        onChange={time =>
                                                            this.emitChange({ endTime: time })
                                                        }
                                                        timezoneLabel={endTimeTimezone}
                                                        timezone={this.props.timezone}
                                                        isSameSelectedDay={isSameSelectedEndDay}
                                                        firstSelectableHour={firstSelectableHour}
                                                        firstSelectableMinute={
                                                            firstSelectableMinute
                                                        }
                                                        isSameSelectedHour={isSameSelectedEndHour}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Box>
                                    {!this.props.disableEnd && (
                                        <div>
                                            <hr className="ef3-dateTimeRangePicker_timeCalendarSeparator" />
                                            <Calendar
                                                onClick={this.onClickRightCalendar}
                                                onHover={this.onHoverRightCalendar}
                                                timezone={this.props.timezone}
                                                highlight={{ start: startDate, end: endDate }}
                                                primaryHighlight="end"
                                                displayedMonth={
                                                    this.state.rightCalendarDisplayedMonth
                                                }
                                                onDisplayedMonthChange={
                                                    this.setRightCalendarDisplayMonth
                                                }
                                                firstDisplayableMonth={startDate}
                                                firstSelectableDate={
                                                    this.props.disableStart ? now : startOfStartDate
                                                }
                                                lastSelectableDate={lastSelectableDate}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        {resetable && (
                            <div
                                className="ef3-dateTimeRangePicker_reset"
                                onClick={this.resetDates}
                            >
                                Reset
                            </div>
                        )}
                    </React.Fragment>
                </Dropdown>
            </FormControl>
        );
    }
}

class Calendar extends React.Component {
    displayPrevMonth = () => {
        const newMonthToDisplay = this.props.displayedMonth.clone().subtract(1, 'month');
        this.props.onDisplayedMonthChange(newMonthToDisplay);
    };

    displayNextMonth = () => {
        const newMonthToDisplay = this.props.displayedMonth.clone().add(1, 'month');
        this.props.onDisplayedMonthChange(newMonthToDisplay);
    };

    onMouseOver = day => {
        this.props.onHover(day);
    };

    onMouseOut = () => {
        this.props.onHover(null);
    };

    render() {
        const { displayedMonth, firstSelectableHour } = this.props;

        // Calculate the dates to be displayed on the calendar
        const displayedStartDate = displayedMonth
            .clone()
            .startOf('month')
            .startOf('week');
        const displayedEndDate = displayedMonth
            .clone()
            .endOf('month')
            .endOf('week');
        const displayedRange = moment.range(displayedStartDate, displayedEndDate);
        const displayedDaysByWeek = [];
        let i = 0;
        Array.from(displayedRange.by('day')).forEach(day => {
            const newDay = day.clone();
            if (
                this.props.highlight.start &&
                day.isSame(this.props.highlight.start, 'date') &&
                this.props.primaryHighlight === 'start'
            ) {
                newDay.hour(this.props.highlight.start.hour());
                newDay.minute(this.props.highlight.start.minute());
            } else if (
                this.props.highlight.end &&
                day.isSame(this.props.highlight.end, 'date') &&
                this.props.primaryHighlight === 'end'
            ) {
                newDay.hour(moment(this.props.highlight.end.hour()));
                newDay.minute(moment(this.props.highlight.end.minute()));
            }
            const weekNumber = Math.floor(i / 7);
            displayedDaysByWeek[weekNumber] = displayedDaysByWeek[weekNumber] || [];
            displayedDaysByWeek[weekNumber].push(firstSelectableHour ? newDay : day.startOf('day'));
            i++;
        });

        // Calculate which ranges should be highlighted as selected
        const highlightStart = moment(this.props.highlight.start);
        const highlightEnd = moment(this.props.highlight.end);
        const highlightRange =
            highlightStart && highlightEnd ? moment.range(highlightStart, highlightEnd) : null;

        // Calculate when to display prev/next months buttons
        const displayPrevMonthButton = this.props.firstDisplayableMonth
            ? parseInt(displayedMonth.format('YYYYMM')) -
                  parseInt(this.props.firstDisplayableMonth.format('YYYYMM')) >
              0
            : true;
        const displayNextMonthButton = this.props.lastDisplayableMonth
            ? parseInt(this.props.lastDisplayableMonth.format('YYYYMM')) -
                  parseInt(displayedMonth.format('YYYYMM')) >
              0
            : true;

        const primaryHighlightStyle =
            this.props.primaryHighlight === 'start'
                ? 'is-primaryHighlightStart'
                : 'is-primaryHighlightEnd';

        return (
            <div className="ef3-dateTimeRangePicker_dateTimePicker">
                <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                    {displayPrevMonthButton ? (
                        <Grid item>
                            <IconButton onClick={this.displayPrevMonth} size="large">
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Grid>
                    ) : (
                        <Grid item xs={2} />
                    )}
                    <Grid item xs={7}>
                        <Box textAlign="center" whiteSpace="nowrap">
                            <Typography variant="body1">
                                {displayedMonth.format('MMMM YYYY')}
                            </Typography>
                        </Box>
                    </Grid>
                    {displayNextMonthButton ? (
                        <Grid item>
                            <IconButton onClick={this.displayNextMonth} size="large">
                                <NavigateNextIcon />
                            </IconButton>
                        </Grid>
                    ) : (
                        <Grid item xs={2} />
                    )}
                </Grid>
                <table className={`ef3-dateTimeRangePicker_calendar ${primaryHighlightStyle}`}>
                    <thead>
                        <tr>
                            {_.map(daysOfWeek, day => (
                                <th>
                                    <Typography variant="caption" color="textSecondary">
                                        {day}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayedDaysByWeek.map((week, j) => (
                            <tr key={j}>
                                {week.map((day, k) => {
                                    const isDisplayedMonth = day
                                        .clone()
                                        .startOf('month')
                                        .isSame(displayedMonth);
                                    const isStartDate = highlightStart
                                        ? highlightStart.isSame(day, 'date')
                                        : false;
                                    const isInRange = highlightRange
                                        ? isStartDate || highlightRange.contains(day)
                                        : false;
                                    const isEndDate = highlightEnd
                                        ? highlightEnd.isSame(day, 'date')
                                        : false;

                                    const isPrimaryHighlight =
                                        (isStartDate && this.props.primaryHighlight === 'start') ||
                                        (isEndDate && this.props.primaryHighlight === 'end');

                                    const isSameDay =
                                        day && day.isSame(this.props.firstSelectableDate, 'date');
                                    const firstSelectableHour =
                                        this.props.firstSelectableDate &&
                                        `${moment(this.props.firstSelectableDate).hour()}`;
                                    const firstSelectableMinute =
                                        this.props.firstSelectableDate &&
                                        `${moment(this.props.firstSelectableDate).minute()}`;

                                    let isSelectable = true;
                                    if (
                                        this.props.firstSelectableDate &&
                                        day.isBefore(
                                            moment(this.props.firstSelectableDate).startOf('day')
                                        )
                                    ) {
                                        isSelectable = false;
                                    }
                                    if (
                                        this.props.lastSelectableDate &&
                                        day.isAfter(
                                            moment(this.props.lastSelectableDate).startOf('day')
                                        )
                                    ) {
                                        isSelectable = false;
                                    }

                                    const dayBlockClassNames = classnames(
                                        'ef3-dateTimeRangePicker_dayBlock',
                                        'adForm',
                                        {
                                            'is-startDate': isStartDate,
                                            'is-inSelectedRange': isInRange,
                                            'is-endDate': isEndDate,
                                            'is-primaryHighlight': isPrimaryHighlight,
                                            'is-otherMonth': !isDisplayedMonth,
                                            'is-selectable': isSelectable,
                                            'is-notSelectable': !isSelectable,
                                        }
                                    );

                                    return (
                                        <td
                                            key={k}
                                            className="ef3-dateTimeRangePicker_day"
                                            onClick={() => {
                                                const newDay = day.clone();
                                                if (this.props.primaryHighlight === 'start') {
                                                    if (
                                                        isSameDay &&
                                                        firstSelectableHour &&
                                                        firstSelectableMinute
                                                    ) {
                                                        newDay.hour(firstSelectableHour);
                                                        newDay.minute(firstSelectableMinute);
                                                    }

                                                    if (isSelectable)
                                                        this.props.onClick(newDay, newDay);
                                                } else if (isSelectable) this.props.onClick(newDay);
                                            }}
                                            onMouseOver={() => {
                                                if (isSelectable) this.onMouseOver(day);
                                            }}
                                            onMouseOut={() => {
                                                if (isSelectable) this.onMouseOut();
                                            }}
                                        >
                                            <div className={dayBlockClassNames}>
                                                {day.format('D')}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

class TimePicker extends React.Component {
    onHourChange = hour => {
        this.props.onChange(this.props.value.clone().hour(hour));
    };

    onMinuteChange = minute => {
        this.props.onChange(this.props.value.clone().minute(minute));
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.value !== null && nextProps.value !== undefined;
    }

    render() {
        const hour = this.props.value.format('HH');
        const minute = this.props.value.format('mm');

        const {
            lastSelectableDate,
            currentlySelectedDate,
            firstSelectableHour,
            firstSelectableMinute,
            isSameSelectedDay,
            isSameSelectedHour,
        } = this.props;

        let lastSelectableHour;
        let lastSelectableMinute;

        if (!currentlySelectedDate) {
            lastSelectableHour = '23';
            lastSelectableMinute = '59';
        } else if (lastSelectableDate) {
            let lastDateMoment = moment(lastSelectableDate).tz(this.props.timezone);

            // If the selected date is on a day before the last selectable, all time options are available
            if (currentlySelectedDate.isBefore(lastSelectableDate, 'day')) {
                lastSelectableHour = '23';
            } else {
                // Otherwise the hour is restricted
                lastSelectableHour = lastDateMoment.format('HH');
            }

            // And maybe the minute, if set to the last possible hour
            if (currentlySelectedDate.isBefore(lastSelectableDate, 'hour')) {
                lastSelectableMinute = '59';
            } else {
                lastSelectableMinute = lastDateMoment.format('mm');
            }
        } else {
            lastSelectableHour = '23';
            lastSelectableMinute = '59';
        }

        return (
            <Box m={1} whiteSpace="nowrap">
                <HourPicker
                    firstSelectableHour={isSameSelectedDay && firstSelectableHour}
                    lastSelectableHour={lastSelectableHour}
                    value={hour}
                    onChange={this.onHourChange}
                />
                &nbsp;:&nbsp;
                <MinutePicker
                    firstSelectableMinute={
                        isSameSelectedDay && isSameSelectedHour && firstSelectableMinute
                    }
                    lastSelectableMinute={lastSelectableMinute}
                    value={minute}
                    onChange={this.onMinuteChange}
                />
                <span className="ef3-dateTimeRangePicker_timePickerTimezone">
                    {this.props.timezoneLabel}
                </span>
            </Box>
        );
    }
}

class Selector extends React.Component {
    render() {
        return (
            <select
                value={this.props.value}
                onChange={event => this.props.onChange(event.target.value)}
            >
                {this.props.options.map(value => {
                    return (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    );
                })}
            </select>
        );
    }
}

class HourPicker extends React.Component {
    render() {
        let baseOptions = [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
        ];

        let finalOptions;
        if (this.props.firstSelectableHour) {
            let initialIndex = _.indexOf(baseOptions, this.props.firstSelectableHour);
            finalOptions = _.slice(baseOptions, initialIndex, 24);
        } else if (this.props.lastSelectableHour) {
            let finalIndex = _.lastIndexOf(baseOptions, this.props.lastSelectableHour);
            finalOptions = _.slice(baseOptions, 0, finalIndex + 1);
        } else {
            finalOptions = baseOptions;
        }

        return (
            <Selector
                value={this.props.value}
                onChange={this.props.onChange}
                options={finalOptions}
            />
        );
    }
}

class MinutePicker extends React.Component {
    render() {
        let baseOptions = [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '58',
            '59',
        ];

        let finalOptions;
        if (this.props.firstSelectableMinute) {
            let initialIndex = _.indexOf(baseOptions, this.props.firstSelectableMinute);
            finalOptions = _.slice(baseOptions, initialIndex, 60);
        } else if (this.props.lastSelectableMinute) {
            let finalIndex = _.lastIndexOf(baseOptions, this.props.lastSelectableMinute);
            finalOptions = _.slice(baseOptions, 0, finalIndex + 1);
        } else {
            finalOptions = baseOptions;
        }

        return (
            <Selector
                value={this.props.value}
                onChange={this.props.onChange}
                options={finalOptions}
            />
        );
    }
}

const DateRangeSelector = withStyles(() => ({
    root: {
        textTransform: 'none',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        padding: '10px 16px',
        width: '100%',
    },
    label: {
        justifyContent: 'space-between',
    },
}))(Button);

function Dropdown(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <DateRangeSelector
                onClick={handleClick}
                variant="outlined"
                endIcon={<ArrowDropDownIcon color="action" />}
                color={props.error ? 'secondary' : 'inherit'}
                disabled={props.disabled}
            >
                {props.startDate === '' && props.endDate === '' ? (
                    <Typography color={props.error ? 'error' : 'textSecondary'}>
                        {props.placeholder}
                    </Typography>
                ) : (
                    <Typography color="inititial">
                        {props.displayText
                            ? props.displayText
                            : `${props.startDate} - ${props.endDate}`}
                    </Typography>
                )}
            </DateRangeSelector>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                className={props.className}
            >
                <Box p={2} width={props.width}>
                    {props.children}
                </Box>
            </Popover>
        </React.Fragment>
    );
}

export default withStyles(styles)(DateTimeRangePicker);

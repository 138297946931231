import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import 'moment-range';

import { metricConfig } from 'services/metrics';

export default function chartExplorerSummaryReducer(components, storeState, props, campaignId) {
    const component = components[0];

    const componentResource = _.get(
        storeState,
        `resources.stats.campaigns.report.explore.${campaignId}.${component.id}`
    );
    const controls = _.get(storeState, `reportExplore.controls`);
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}`);
    const userOrgId = _.get(storeState, 'profile.organizationId');
    const componentIsLoading = _.get(
        storeState,
        `resources.stats.campaigns.report.explore.${campaignId}.${component.id}.isLoading`,
        true
    );
    const currentSelectedMetricType = _.get(
        storeState,
        `reportExplore.metricSelector.currentSelectedMetricType`
    );
    const metricComponentsConfig = _.get(
        storeState,
        `reportExplore.metricSelector.metricComponentsConfig`
    );
    const selectedMetrics = _.get(
        storeState,
        `reportExplore.metricSelector.selectedMetrics`
    );
    const metricComponentsConfig_beacon = _(metricComponentsConfig)
        .filter({ category: 'beacon' })
        .value();

    let statsMetricsType_visibility = {};
    _.each(metricComponentsConfig, config => {
        const isVisible = _.includes(selectedMetrics, config.metricType);
        statsMetricsType_visibility[config.metricType] = isVisible;
    });

    // Make sure to toggle the visibility of the current selected metric type (the Metric dropdown option)
    statsMetricsType_visibility[currentSelectedMetricType] = true;

    if (isClient(userOrgId, campaign)) {
        statsMetricsType_visibility = applyMetricPermissions(statsMetricsType_visibility, campaign);
    }

    if (_.get(campaign, 'attr.billing_enabled') === false) {
        statsMetricsType_visibility.revenue = false;
        statsMetricsType_visibility.erpm = false;
        statsMetricsType_visibility.erpc = false;
    }

    if (componentIsLoading) {
        return {
            data: [],
            isLoading: true,
            startDate: '2015-01-01',
            meta: {
                statsMetricsType: 'impressions',
                campaignId,
            },
            control: {},
        };
    }

    return {
        data: getStatsOverall(campaign.attr, componentResource, metricComponentsConfig_beacon),
        isLoading: false,
        startDate: campaign.attr.start,
        meta: {
            statsMetricsType: currentSelectedMetricType,
            campaignId,
        },
        control: {
            ...controls,
            statsMetricsType_visibility,
            metricComponentsConfig,
        },
        timezone: _.get(campaign, 'attr.default_timezone', 'UTC'),
    };
}
function isClient(userOrgId, campaign) {
    return _.get(campaign, 'attr.owner') !== userOrgId;
}
function applyMetricPermissions(statsMetricsType_visibility, campaign) {
    const metricSettings = { ...statsMetricsType_visibility };
    _.get(campaign, 'attr.sharing_settings.metrics', []).forEach(metric => {
        if (!metric.shared) {
            metricSettings[metric.name] = false;
        }
    });

    return metricSettings;
}
function getStatsOverall(campaign, componentResource, metricComponentsConfig_beacon) {
    var results = [];

    var stats = {
        date: componentResource,
    };
    var campaignModel = campaign;

    var timeZoneOffset = new Date().getTimezoneOffset();
    var campaignStart_utcStartOfDay = moment(campaignModel.start)
        .add(timeZoneOffset, 'm')
        .format('YYYY-MM-DD');
    var campaignEnd_utcStartOfDay = moment(campaignModel.end)
        .add(timeZoneOffset, 'm')
        .format('YYYY-MM-DD');

    // if (stats && stats.date && stats.date.total && stats.date.total.stats ) {
    //     do stuff with total impressions and total clicks
    // }

    if (stats && stats.date && stats.date.stats) {
        var M_range = moment.range(
            moment(campaignStart_utcStartOfDay),
            moment(campaignEnd_utcStartOfDay)
        );

        const acc = Array.from(M_range.by('day'));
        acc.forEach(function(M_day) {
            var day_iso = M_day.toISOString();

            // ** UNCOMMENT NEXT LINE TO PRINT OUT STATS
            // console.log('&&&&& stats.date.stats: ',stats.date.stats )

            var matched = _.find(stats.date.stats, function(statsItem) {
                // var date = statsItem.group.date;
                var date = statsItem.split[0].group.split('T')[0];
                var M_date = moment(date);
                var isSame = M_date.isSame(M_day);
                return isSame;
            });

            let filtered_beacons = {};
            if (matched) {
                filtered_beacons = _(metricComponentsConfig_beacon).reduce((acc, item) => {
                    const metricType = item.metricType;
                    acc[metricType] = matched[metricType] ? matched[metricType] : 0;
                    return acc;
                }, {});
                results.push({
                    date: day_iso,
                    filtered_clicks: matched.clicks ? matched.clicks : 0,
                    filtered_impressions: matched.impressions ? matched.impressions : 0,
                    filtered_video_impressions: matched.video_impressions
                        ? matched.video_impressions
                        : 0,
                    filtered_spend: matched.spend ? matched.spend : 0,
                    filtered_owner_total_media_cost_local: matched.owner_total_media_cost_local
                        ? matched.owner_total_media_cost_local
                        : 0,
                    filtered_revenue: matched.revenue ? matched.revenue : 0,
                    filtered_daily_uniq: matched.daily_uniq ? matched.daily_uniq : 0,
                    filtered_unique_users:
                        metricConfig.unique_users.getValueFromRecord({ statRecord: matched }) || 0,
                    filtered_owner_media_cost_2_local: matched.owner_media_cost_2_local
                        ? matched.owner_media_cost_2_local
                        : 0,
                    filtered_placed_bid: matched.placed_bid ? matched.placed_bid : 0,
                    filtered_beacons,
                });
            } else {
                filtered_beacons = _(metricComponentsConfig_beacon).reduce((acc, item) => {
                    const metricType = item.metricType;
                    acc[metricType] = 0;
                    return acc;
                }, {});
                results.push({
                    date: day_iso,
                    filtered_clicks: 0,
                    filtered_impressions: 0,
                    filtered_video_impressions: 0,
                    filtered_spend: 0,
                    filtered_owner_total_media_cost_local: 0,
                    filtered_revenue: 0,
                    filtered_daily_uniq: 0,
                    filtered_unique_users: 0,
                    filtered_owner_media_cost_2_local: 0,
                    filtered_placed_bid: 0,
                    filtered_beacons,
                });
            }
        });
    }

    return results;
}

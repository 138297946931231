import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import PopupContent from 'containers/app-popup/popup-content';
import { Cell, Row, ComposableDataTable } from 'widgets-v5/data-table';
import { PivotTableData } from 'widgets/pivot-table/v2';
import { BlockLoadGroup } from 'widgets-v5/load-group';

import metricConfig from 'common/metrics';
import dimensionConfig from 'common/dimensions';
import attributeConfig from 'common/attributes';

const { arrayOf, shape, string, array, object, func, number, bool, any } = PropTypes;

class InventoryReportPivotTable extends React.Component {
    static propTypes = {
        hideZeroRows: PropTypes.bool,

        request: shape({
            start: string,
            end: string,
            match: shape({
                camp_org: arrayOf(string),
            }),
            metrics: arrayOf(string),
            attributes: arrayOf(string),
            split: arrayOf(string),
            timezone: string,
            limit: number,
            source: string,
        }),
    };

    state = {
        ptData: null,
        status: 'loading',
    };
    async componentDidMount() {
        const ptData = (window.__ptData = new PivotTableData());

        ptData.onChange(ptData => {
            this.setState({
                ptData,
                status: 'waiting',
            });
        });

        ptData.onError(ptData => {
            this.setState({
                ptData,
                status: 'error',
            });
        });

        await ptData.init({ request: this.props.request });
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);

        if (this.state.ptData) {
            this.state.ptData.destroy();
        }
    }

    expandOrCollapse = row => {
        if (row.isExpanded) {
            this.state.ptData.collapse(row.id);
        } else {
            this.state.ptData.expand(row.id);
        }
    };

    render() {
        if (this.state.status === 'loading') {
            return <BlockLoadGroup isLoading={true} />;
        }

        if (this.state.status === 'error') {
            return <div>Something went wrong. Try again shortly.</div>;
        }

        const columns = [
            {
                label: 'Dimensions',
                value: 'dimension',
                getTotalFormatValue: () => 'Total',
                formatValue: stat =>
                    dimensionConfig[stat.dimension].formatValue({
                        stat: stat,
                        channel_type: this.props.channel_type,
                    }),
                orderValue: stat => dimensionConfig[stat.dimension].orderValue(stat),
            },
            ..._.map(this.props.request.attributes, name => attributeConfig[name]),
            ..._.map(this.props.request.metrics, name => metricConfig[name]),
        ];

        const columnMapping = {};
        _.each(columns, c => (columnMapping[c.value] = c));
        const rows = this.state.ptData.getRows({
            filterRow: row => {
                if (!this.props.hideZeroRows) {
                    return true;
                }

                // Hide Zero Rows
                const zeroRowColumns = _.filter(columns, c => c.isZeroRow);
                const isZeroRow = _.every(zeroRowColumns, column => {
                    return column.isZeroRow(row) === true;
                });

                if (isZeroRow) {
                    return false;
                } else {
                    return true;
                }
            },

            // Groups will be the children of a parent group. Groups may be:
            // Male, Female, Not Available or
            // IOS, Android, Not Available etc
            sortGroups: groups => {
                if (!this.props.sortColumn) {
                    return groups;
                }

                const sortedChildren = _.orderBy(
                    groups,
                    group => columnMapping[this.props.sortColumn].orderValue(group),
                    this.props.sortDirection
                );

                return sortedChildren;
            },
        });

        const totalRow = this.state.ptData.getTotalRow();

        return (
            <ComposableDataTable>
                <Row key="header" header>
                    {_.map(columns, column => (
                        <Cell key={column.value} header>
                            <div
                                className="ef5-interactive-area"
                                onClick={() => this.props.onSort(column)}
                            >
                                {column.label}
                                {this.props.sortColumn === column.value && (
                                    <i
                                        style={{ paddingLeft: 5 }}
                                        className={`ef5-interactive-area__primary fa fa-chevron-${
                                            this.props.sortDirection === 'asc' ? 'up' : 'down'
                                        }`}
                                    />
                                )}
                            </div>
                        </Cell>
                    ))}
                </Row>

                {totalRow && (
                    <Row key="total" totalRow>
                        {_.map(columns, column => (
                            <Cell key={column.value} total>
                                {column.getTotalFormatValue
                                    ? column.getTotalFormatValue(totalRow)
                                    : column.formatValue(totalRow)}
                            </Cell>
                        ))}
                    </Row>
                )}

                {_.map(rows, row => (
                    <Row key={row.id}>
                        {_.map(columns, column => (
                            <Cell key={column.value}>
                                {column.value === 'dimension' ? (
                                    <div style={{ paddingLeft: (row.depth - 1) * 50 }}>
                                        {row.isExpandable &&
                                            (row.isExpanding ? (
                                                <i
                                                    style={{ marginRight: 5 }}
                                                    className="fa fa-circle-o-notch ef4-load-group__spin ef5-color-scheme_secondary"
                                                />
                                            ) : (
                                                <span onClick={() => this.expandOrCollapse(row)}>
                                                    <i
                                                        className={cn(
                                                            'ef5-interactive-area__primary ef5-interactive-area__neutral-icon',
                                                            {
                                                                'fa fa-fw': true,
                                                                'fa-minus-square-o':
                                                                    row.isExpanded === true,
                                                                'fa-plus-square':
                                                                    row.isExpanded === false,
                                                            }
                                                        )}
                                                    />
                                                </span>
                                            ))}

                                        {row.dimension === 'channel_name' ? (
                                            <PopUp
                                                renderContent={() => {
                                                    return (
                                                        <PopupContent
                                                            channel_id={row.group}
                                                            channel_name={row.label}
                                                            channel_type={this.props.channel_type}
                                                        />
                                                    );
                                                }}
                                                renderButton={({ open }) => (
                                                    <span
                                                        className="ef5-interactive-area__primary"
                                                        onClick={open}
                                                    >
                                                        {column.formatValue(row)}
                                                    </span>
                                                )}
                                            />
                                        ) : (
                                            <span>{column.formatValue(row)}</span>
                                        )}
                                    </div>
                                ) : (
                                    column.formatValue(row)
                                )}
                            </Cell>
                        ))}
                    </Row>
                ))}
            </ComposableDataTable>
        );
    }
}

const modalRoot = document.getElementById('modal-root');
class PopUp extends React.Component {
    state = {
        isOpen: false,
    };

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    close = () => {
        modalRoot.removeChild(this.el);
        this.setState({ isOpen: false });
    };

    open = event => {
        modalRoot.appendChild(this.el);
        this.setState({
            anchorEl: event.currentTarget,
            isOpen: true,
        });
    };

    render() {
        let rect;
        if (this.state.isOpen) {
            rect = this.state.anchorEl.getBoundingClientRect();
        }

        return (
            <React.Fragment>
                {this.props.renderButton({
                    open: this.open,
                    close: this.close,
                })}

                {this.state.isOpen &&
                    ReactDOM.createPortal(
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 100,
                            }}
                            onClick={this.close}
                        >
                            <div
                                style={{
                                    position: 'fixed',
                                    top: rect.bottom,
                                    left: rect.left,
                                    zIndex: 101,
                                }}
                                onClick={e => e.stopPropagation()}
                            >
                                {this.props.renderContent()}
                            </div>
                        </div>,
                        this.el
                    )}
            </React.Fragment>
        );
    }
}

export default InventoryReportPivotTable;

import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';
const initialState = {};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }

        case c.REPORT_CHANNEL__AVAILABLE_ADGROUPS__FETCH_SUCCESS: {
            const { response } = action.payload;

            return _.reduce(
                response,
                (acc, scope) => ({
                    ...acc,
                    [scope.id]: acc[scope.id] || [],
                }),
                state
            );
        }

        case c.CAMPAIGN_REPORT__ADGROUP__TOGGLE: {
            const { campaignId } = action.payload;

            const componentType = _.get(action, 'payload.component.type');

            if (componentType !== 'scope') {
                return state;
            }

            const grouping = _.get(action, 'payload.grouping');

            const { group } = action.payload;

            const groupSelection = state[grouping] || [];

            const nextGroupValue = _.includes(groupSelection, group.id)
                ? _.without(groupSelection, group.id)
                : _.uniq(groupSelection.concat(group.id));

            const nextState = {
                ...state,
                [grouping]: nextGroupValue,
            };

            return findCacheState(campaignId, nextState, nextState);
        }

        case c.CAMPAIGN_REPORT__ADGROUP__RESET: {
            const componentType = _.get(action, 'payload.component.type');

            if (componentType !== 'scope') {
                return state;
            }

            const groupings = _.get(action, 'payload.component.groupings', []);

            return _.reduce(
                groupings,
                (acc, grouping) => ({
                    ...acc,
                    [grouping]: [],
                }),
                state
            );
        }

        case c.REPORT_CHANNEL__SCOPE_FILTER__CLEAR: {
            const { grouping } = action.payload;

            return {
                ...state,
                [grouping]: [],
            };
        }

        case c.REPORT_CHANNEL__SCOPE_FILTER__CLEAR_ALL: {
            return _.reduce(
                state,
                (acc, groups, grouping) => ({
                    ...acc,
                    [grouping]: [],
                }),
                state
            );
        }

        default:
            return state;
    }
}

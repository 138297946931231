import _ from 'lodash';
import { getCreativeWarnings } from '../creative-new-form/selector';

export default function selector(storeState, props) {
    const { campaignId, creativeId } = props.params;

    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    if (!campaign) {
        return { creatives: [] };
    }

    // Asset
    const filesResource = _.get(storeState, `resources.files`, {});
    const campaignFiles = _.reduce(
        campaign.files,
        (acc, fileId, index) => {
            const file = filesResource[fileId];
            if (!file) {
                return acc;
            }
            return {
                ...acc,
                [fileId]: file,
            };
        },
        {}
    );
    const filteredCampaignFiles = _.filter(campaignFiles, x => x);
    const assets = _.filter(filteredCampaignFiles, (value, key) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? value : null;
    });
    const documents = _.filter(filteredCampaignFiles, (value, key) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? null : value;
    });

    const form = _.get(storeState, `creativeEditForm`, {});

    const showErrors = _.get(storeState, `creativeEditForm.showErrors`, false);
    // const currentEditFormCreativeDraft = getCurrentEditFormCreative(storeState, props);
    const warnings = getCreativeWarnings(campaign);
    return {
        campaignId,
        creativeId,
        warnings,
        assets,
        documents,
        isSubmiting: form.isSubmiting,
        isLoading: form.isLoading,
        isOpen: form.isFormOpen,
        draft: form.draft,
        isDraftValid: form.isDraftValid,
        showErrors: form.showErrors,
        errors: reduceErrorList(form.errors),
    };
}

// function getCurrentEditFormCreative(storeState, props) {
//     const { campaignId, creativeId } = props;
//     const allCreatives = _.get(storeState, `session.campaigns.overview.${campaignId}.creativeEditForm`);
//
//     const creative = _.reduce(allCreatives, (acc, value, key) => {
//         if (value.isFormOpen) {
//             return _.get(storeState, `resources.creatives.${key}`);
//         } else {
//             return acc;
//         }
//     }, null);
//
//     if (!creative) {
//         return { isLoading: true };
//     }
//
//     const defaults = _.get(storeState, `creativeEditForm.draft`);
//
//     return defaults;
//
//
//     // NOTE: the below filters out undefined and in some cases replaces them with stale data
//     // const defaultsFiltered = _.pick(defaults, prop => prop !== undefined);
//     // const editableFields = _.pick(creative.attr, Object.keys(defaults));
//
//     // const currentDraft = _.assign({}, editableFields, defaultsFiltered);
//
//     // return currentDraft;
// }

function reduceErrorList(errorsAsList = []) {
    return _(errorsAsList)
        .groupBy(e => e.field)
        .reduce((acc, v, k) => {
            const o = {
                ...acc,
                [k]: _.map(v, 'message'),
            };
            return o;
        }, {});
}

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REDUCER_KEY, actions } from './reducer';
import selector from '../selector';
import {
    getMetricMenu,
    getSelectedMetrics,
    campaignsSummarySelector,
    organizationForFilteringOptionSelector,
    selectedSubeventsSelector,
} from '../selector';

export function useBusinessReportEditor({ isInternalUser }) {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const oldState = useSelector(selector);

    const boundActions = bindActionCreators(actions, dispatch);

    const metricMenu = useSelector(state => getMetricMenu(state, isInternalUser));
    const selectedMetrics = useSelector(state => getSelectedMetrics(state, isInternalUser));
    const campaignsSummary = useSelector(state => campaignsSummarySelector(state, isInternalUser));
    const organizationForFilteringOptions = useSelector(state => organizationForFilteringOptionSelector(state, isInternalUser));
    const selectedSubevents = useSelector(state => selectedSubeventsSelector(state, isInternalUser));

    return {
        ...state,
        ...boundActions,
        ...oldState,
        metricMenu,
        selectedMetrics,
        campaignsSummary,
        organizationForFilteringOptions,
        selectedSubevents,
    };
}

import _ from 'lodash';
import PropTypes from 'prop-types';
import { default as React } from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

const shapeEditableMode = { editable: true, draggable: true };
const shapeUneditableMode = { clickable: true, editable: false, draggable: false };

export default createReactClass({
    displayName: 'geofence-selector',

    propTypes: {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.shape({
                    type: PropTypes.oneOf(['geobox']),
                    N: PropTypes.number.isRequired,
                    S: PropTypes.number.isRequired,
                    E: PropTypes.number.isRequired,
                    W: PropTypes.number.isRequired,
                }),
                PropTypes.shape({
                    type: PropTypes.oneOf(['geocircle']).isRequired,
                    lat: PropTypes.number.isRequired,
                    lng: PropTypes.number.isRequired,
                    radius: PropTypes.number.isRequired,
                }),
            ])
        ).isRequired,
        defaultZoom: PropTypes.number,
        defaultCenter: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        canDrawRectangles: PropTypes.bool,
        canDrawCircles: PropTypes.bool,
    },

    getDefaultProps() {
        return {
            value: [],
            defaultZoom: 12,
            defaultCenter: { lat: 43.6525, lng: -79.381667 }, // Toronto
            canDrawRectangles: true,
            canDrawCircles: true,
        };
    },

    getInitialState() {
        return {
            shapes: [],
            selectedShape: null,
        };
    },

    componentDidMount() {
        this.map = new google.maps.Map(this.refs.map, {
            center: this.props.defaultCenter,
            zoom: this.props.defaultZoom,
            scrollwheel: false,
        });

        // Draw provided shapes onto map
        this.drawShapes(this.props.value);

        // If shapes were drawn, ensure that they are within viewport
        if (this.props.value.length) {
            this.fitBoundsToShapes();
        }

        // Init DrawingManager for drawing NEW shapes on map
        const drawingModes = [];
        if (this.props.canDrawRectangles) {
            drawingModes.push(google.maps.drawing.OverlayType.RECTANGLE);
        }
        if (this.props.canDrawCircles) {
            drawingModes.push(google.maps.drawing.OverlayType.CIRCLE);
        }
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: drawingModes,
            },
        });
        this.drawingManager.setMap(this.map);

        // Add interactivity to newly drawn shapes
        google.maps.event.addListener(this.drawingManager, 'rectanglecomplete', rectangle => {
            this.drawingManager.setOptions({ drawingMode: null });
            this.initRectangle(rectangle);
            this.onShapeUpdate();
            // Auto select the latest shape
            this.focusShape(rectangle);
        });
        google.maps.event.addListener(this.drawingManager, 'circlecomplete', circle => {
            this.drawingManager.setOptions({ drawingMode: null });
            this.initCircle(circle);
            this.onShapeUpdate();
            // Auto select the latest shape
            this.focusShape(circle);
        });

        // Click anywhere to unfocus shape
        google.maps.event.addListener(this.map, 'click', () => {
            this.unfocusShapes();
        });
        this.refs.widget.addEventListener('click', this.onWidgetClick);
        document.body.addEventListener('click', this.onBodyClick);

        // Initialize searchbox
        this.searchResultMarkers = [];
        this.searchBox = new google.maps.places.SearchBox(this.refs.searchBox);
        google.maps.event.addListener(this.searchBox, 'places_changed', () => {
            // Clear search box
            this.refs.searchBox.value = '';

            // Clear previous markers
            this.searchResultMarkers.forEach(marker => marker.setMap(null));

            // Get new places
            const places = this.searchBox.getPlaces();
            if (!places.length) {
                return;
            }

            // Draw markers for each search result
            // There may be multiple if user is making a generic search
            // e.g. 'Pizza near Toronto, ON'
            places.forEach(place => {
                const marker = new google.maps.Marker({
                    position: place.geometry.location,
                    map: this.map,
                });
                this.searchResultMarkers.push(marker);
            });

            // Ensure all new places are visible on map
            const bounds = new google.maps.LatLngBounds();
            places.forEach(place => bounds.extend(place.geometry.location));
            this.map.fitBounds(bounds);
            // Prevent getting too close
            if (this.map.getZoom() > 12) {
                this.map.setZoom(12);
            }
        });
    },

    componentWillUnmount() {
        // ATTENTION:
        // https://code.google.com/p/gmaps-api-issues/issues/detail?id=3803
        // There is no way official way to clean up a Google Map
        // Best you can do is remove the event listeners that you manually added
        //
        // TODO in the far off future:
        // Figure out how to re-initialize and re-use existing Map instances
        google.maps.event.clearListeners(this.drawingManager);
        google.maps.event.clearListeners(this.searchBox);
        this.state.shapes.forEach(shape => google.maps.event.clearListeners(shape));

        this.refs.widget.removeEventListener('click', this.onWidgetClick);
        document.body.removeEventListener('click', this.onBodyClick);
    },

    shouldComponentUpdate(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.deleteAllShapes();
            this.drawShapes(nextProps.value);
        }
        return false;
    },

    onWidgetClick(event) {
        event.origin = this;
    },

    onBodyClick(event) {
        // Clicked outside map
        if (event.origin !== this) {
            this.unfocusShapes();
        }
    },

    focusShape(shape) {
        this.unfocusShapes();
        this.setState({ selectedShape: shape });
        shape.setOptions(shapeEditableMode);
    },

    unfocusShapes() {
        if (this.state.selectedShape) {
            this.state.selectedShape.setOptions(shapeUneditableMode);
            this.setState({ selectedShape: null });
        }
    },

    drawShapes(shapes) {
        if (!shapes) {
            return;
        }
        shapes.forEach(shape => {
            switch (shape.type) {
                default:
                case 'geobox':
                    const rectangle = new google.maps.Rectangle({
                        // Draws rectangle to map automatically
                        map: this.map,
                        bounds: {
                            north: shape.N,
                            south: shape.S,
                            east: shape.E,
                            west: shape.W,
                        },
                    });
                    this.initRectangle(rectangle);
                    break;

                case 'geocircle':
                    const circle = new google.maps.Circle({
                        // Draws circle to map automatically
                        map: this.map,
                        center: {
                            lat: shape.lat,
                            lng: shape.lng,
                        },
                        radius: shape.radius,
                    });

                    this.initCircle(circle);
                    break;
            }
        });
    },

    fitBoundsToShapes() {
        const bounds = new google.maps.LatLngBounds();

        this.state.shapes.forEach(shape => {
            const shapeBounds = shape.getBounds();
            const northEast = shapeBounds.getNorthEast();
            const southWest = shapeBounds.getSouthWest();
            bounds.extend(northEast);
            bounds.extend(southWest);
        });

        this.map.fitBounds(bounds);
    },

    onClickDeleteShape() {
        this.deleteSelectedShape();
        this.onShapeUpdate();
    },

    onClickDeleteAllShapes() {
        this.deleteAllShapes();
        this.onShapeUpdate();
    },

    deleteSelectedShape() {
        if (this.state.selectedShape) {
            // Remove from map
            this.state.selectedShape.setMap(null);
            // Remove references
            _.remove(this.state.shapes, this.state.selectedShape);
            this.setState({ selectedShape: null });
        }
    },

    deleteAllShapes() {
        // Remove from map
        this.state.shapes.forEach(shape => {
            shape.setMap(null);
        });
        // Remove references
        this.state.shapes.length = 0;
        this.setState({ selectedShape: null });
    },

    initRectangle(rectangle) {
        rectangle.addListener('click', () => this.focusShape(rectangle));
        rectangle.addListener('bounds_changed', this.onShapeUpdate);
        this.state.shapes.push(rectangle);
    },

    initCircle(circle) {
        circle.addListener('click', () => this.focusShape(circle));
        circle.addListener('radius_changed', this.onShapeUpdate);
        circle.addListener('center_changed', this.onShapeUpdate);
        this.state.shapes.push(circle);
    },

    onShapeUpdate: _.debounce(function() {
        const fences = this.state.shapes.map(shape => {
            switch (true) {
                case shape instanceof google.maps.Rectangle:
                    const bounds = shape.getBounds();
                    const boundsSW = bounds.getSouthWest();
                    const boundsNE = bounds.getNorthEast();

                    return {
                        type: 'geobox',
                        N: boundsNE.lat(),
                        S: boundsSW.lat(),
                        E: boundsNE.lng(),
                        W: boundsSW.lng(),
                    };

                case shape instanceof google.maps.Circle:
                    const center = shape.getCenter();
                    const radius = shape.getRadius();

                    return {
                        type: 'geocircle',
                        lat: center.lat(),
                        lng: center.lng(),
                        radius: radius,
                    };
            }
        });
        this.props.onChange(fences);
    }, 300),

    render() {
        const rootClass = classnames('ef3-geofenceSelector', this.props.className);

        return (
            <div ref="widget" className={rootClass}>
                <input ref="searchBox" className="ef3-geofenceSelector_searchBox" />
                <div ref="map" className="ef3-geofenceSelector_map" />
                <button onClick={this.onClickDeleteShape}>Remove selected fence</button>
                <button onClick={this.onClickDeleteAllShapes}>Remove all fences</button>
                <button onClick={this.fitBoundsToShapes}>See all fences</button>
            </div>
        );
    },
});

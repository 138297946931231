import React from 'react';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import MultilineInput from 'widgets-v5/multiline-input';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import CreativeAssetSelector from '../creative-asset-selector/index';
import ClickthroughCard from '../click-through-card/click-through-card';
import {
    CreativeVendorSelector,
    TrackingVendorsSelector,
} from '../vendor-selector/vendor-selector';
import { creativeTrackingVendorsMapping, StyledLink } from '../../index';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';

const useStyles = makeStyles(theme => ({
    image: {
        width: 70,
    },
    thirdPartyVendors: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function CreativeFormStandardType(props) {
    const classes = useStyles();
    const { showErrors, errors, draft, onFieldChange, activeStep, updateVendors } = props;
    const errorsForImage = []
        .concat(errors.image_asset, errors.image_url, errors.image_preview_url)
        .filter(error => error);

    const landingPageClickthroughErrors = [].concat(errors.clickthrough_url).filter(error => error);

    const landingPageErrors = [].concat(errors.landing_page).filter(error => error);
    const isDOOHCampaign = props.campaignType === CampaignTypeMapping.DOOH;

    if (activeStep === 1) {
        return (
            <div>
                <AccordionItem
                    onClick={props.expandPanel('creativeAsset')}
                    expanded={props.expandedPanel === 'creativeAsset'}
                    title="Creative Asset"
                    required
                    error={showErrors && (errorsForImage.length > 0 || errors.creative_vendors)}
                    details={
                        <React.Fragment>
                            <CreativeAssetSelector
                                mode={props.mode}
                                uploadFile={props.uploadFile}
                                assets={props.assets}
                                draft={props.draft}
                                onFieldChange={props.onFieldChange}
                                onMultiFieldChange={props.onMultiFieldChange}
                                inputUrlFieldsType="standard"
                                showErrors={showErrors}
                                errors={errors}
                                areAssetsLoading={props.areAssetsLoading}
                                onChangeAssetSelectionMethod={props.onChangeAssetSelectionMethod}
                                uploadAssetsErrorMessage={props.uploadAssetsErrorMessage}
                                hideUploadAssetsErrorMessage={props.hideUploadAssetsErrorMessage}
                            />
                            {draft.assetSelectionMethod === 'inputByUrl' && (
                                <CreativeVendorSelector
                                    showErrors={showErrors}
                                    errors={errors}
                                    draft={draft}
                                    onFieldChange={onFieldChange}
                                    updateVendors={updateVendors}
                                    creativeTagsMatched={props.creativeTagsMatched}
                                    creativeType={'standard'}
                                />
                            )}
                        </React.Fragment>
                    }
                    summary={
                        <div>
                            <div>
                                {' '}
                                {draft.image_preview_url ? (
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <img
                                                className={classes.image}
                                                src={draft.image_preview_url}
                                                alt=""
                                            />
                                        </Grid>
                                        <Grid item>
                                            {props.creativeMapping[draft.image_preview_url]}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add creative asset
                                    </Typography>
                                )}{' '}
                            </div>
                            <div>
                                {' '}
                                {draft.creative_vendors.length > 0 && draft.creative_vendors[0] ? (
                                    <div className={classes.thirdPartyVendors}>
                                        {_.map(draft.creative_vendors, vendor => {
                                            return (
                                                <Chip
                                                    key={vendor}
                                                    label={
                                                        creativeTrackingVendorsMapping[vendor] ||
                                                        vendor
                                                    }
                                                    variant="outlined"
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <Typography color="textSecondary">
                                        Add creative vendor
                                    </Typography>
                                )}{' '}
                            </div>
                        </div>
                    }
                />
                {!isDOOHCampaign && (
                    <AccordionItem
                        onClick={props.expandPanel('thirdPartyClicktracker')}
                        expanded={props.expandedPanel === 'thirdPartyClicktracker'}
                        title="Clicktracking"
                        required
                        error={
                            showErrors &&
                            (landingPageClickthroughErrors.length > 0 ||
                                landingPageErrors.length > 0)
                        }
                        details={
                            <React.Fragment>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={draft.third_party_clickthrough}
                                                color="primary"
                                                onChange={() => {
                                                    onFieldChange('third_party_clickthrough')(
                                                        !draft.third_party_clickthrough
                                                    );
                                                }}
                                                value={draft.third_party_clickthrough}
                                            />
                                        }
                                        label="Use Third-party Clicktracker"
                                    />
                                </FormControl>
                                <Box mt={2}>
                                    <Divider />
                                </Box>
                                <ClickthroughCard
                                    draft={props.draft}
                                    errors={props.errors}
                                    onFieldChange={props.onFieldChange}
                                    showErrors={showErrors}
                                />
                            </React.Fragment>
                        }
                        summary={
                            <div>
                                <Box mb={1}>
                                    {draft.third_party_clickthrough
                                        ? 'Use Third-party Clicktracker'
                                        : "Don't use Third-party Clicktracker"}
                                </Box>
                                {draft.landing_page || draft.clickthrough_url ? (
                                    draft.third_party_clickthrough ? (
                                        <div>
                                            {draft.clickthrough_url && (
                                                <div>
                                                    <Typography color="textSecondary">
                                                        Third-Party Clicktracker URL
                                                    </Typography>
                                                    <StyledLink>
                                                        {draft.clickthrough_url}
                                                    </StyledLink>
                                                </div>
                                            )}
                                            {draft.landing_page && (
                                                <div>
                                                    <Typography color="textSecondary">
                                                        Landing Page
                                                    </Typography>
                                                    <StyledLink>{draft.landing_page}</StyledLink>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        draft.landing_page && (
                                            <div>
                                                <Typography color="textSecondary">
                                                    Landing Page
                                                </Typography>
                                                <StyledLink>{draft.landing_page}</StyledLink>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <Typography color="textSecondary">
                                        {draft.third_party_clickthrough
                                            ? 'Add third-party clicktracker URL and landing page'
                                            : 'Add landing page'}
                                    </Typography>
                                )}
                            </div>
                        }
                    />
                )}

                {!isDOOHCampaign && (
                    <AccordionItem
                        onClick={props.expandPanel('thirdPartyPixels')}
                        expanded={props.expandedPanel === 'thirdPartyPixels'}
                        title="Third Party Pixels"
                        helper="Enter a tracking pixel URL from a supported third-party verification provider. HTML tags are not supported."
                        externalLink="https://engagefront.notion.site/Third-Party-Tracking-for-Impressions-and-Viewability-dbdf1a44a16742f495ad10e5127b4f9c?pvs=74"
                        error={showErrors && errors.third_party_pixels}
                        details={
                            <MultilineInput
                                onChange={props.onFieldChange('third_party_pixels')}
                                inputs={props.draft.third_party_pixels}
                                addText="Add Pixel"
                            />
                        }
                        summary={
                            draft.third_party_pixels.length > 0 ? (
                                <div>
                                    {_.map(draft.third_party_pixels, pixel => (
                                        <StyledLink key={pixel}>{pixel}</StyledLink>
                                    ))}
                                </div>
                            ) : (
                                <Typography color="textSecondary">
                                    Add Third Party Pixels
                                </Typography>
                            )
                        }
                    />
                )}
                {!isDOOHCampaign && (
                    <AccordionItem
                        onClick={props.expandPanel('thirdPartyJavascriptURLs')}
                        expanded={props.expandedPanel === 'thirdPartyJavascriptURLs'}
                        title="Third Party Javascript"
                        helper="Enter a JavaScript measurement URL from a supported third-party verification provider. HTML script tags are not supported."
                        externalLink="https://engagefront.notion.site/Third-Party-Tracking-for-Impressions-and-Viewability-dbdf1a44a16742f495ad10e5127b4f9c?pvs=74"
                        error={showErrors && errors.third_party_javascript_urls}
                        details={
                            <MultilineInput
                                onChange={props.onFieldChange('third_party_javascript_urls')}
                                inputs={props.draft.third_party_javascript_urls}
                                addText="Add Javascript URL"
                            />
                        }
                        summary={
                            draft.third_party_javascript_urls.length > 0 ? (
                                <div>
                                    {_.map(draft.third_party_javascript_urls, url => (
                                        <StyledLink key={url}>{url}</StyledLink>
                                    ))}
                                </div>
                            ) : (
                                <Typography color="textSecondary">
                                    Add Third Party Javascript URL
                                </Typography>
                            )
                        }
                    />
                )}
                {!isDOOHCampaign && (
                    <AccordionItem
                        onClick={props.expandPanel('trackingVendors')}
                        expanded={props.expandedPanel === 'trackingVendors'}
                        title="Tracking Vendors"
                        error={showErrors && errors.tracking_vendors}
                        details={
                            <TrackingVendorsSelector
                                showErrors={showErrors}
                                errors={errors}
                                draft={draft}
                                onFieldChange={onFieldChange}
                                updateVendors={updateVendors}
                                trackingTagsMatched={props.trackingTagsMatched}
                                creativeType={'standard'}
                            />
                        }
                        summary={
                            draft.tracking_vendors.length > 0 ? (
                                <div className={classes.thirdPartyVendors}>
                                    {_.map(draft.tracking_vendors, vendor => {
                                        return (
                                            <Chip
                                                key={vendor}
                                                label={
                                                    creativeTrackingVendorsMapping[vendor] || vendor
                                                }
                                                variant="outlined"
                                            />
                                        );
                                    })}
                                </div>
                            ) : (
                                <Typography color="textSecondary">Add tracking vendors</Typography>
                            )
                        }
                    />
                )}
            </div>
        );
    }

    return null;
}

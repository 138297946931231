import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import routes from './routes';
import store from './store';
import Tracking from 'services/tracking';
import { syncHistoryWithStore } from 'react-router-redux';

import CatchAll from 'error-boundaries/catch-all';

function routerUpdateHandler(history) {
    const path = window.location.pathname;

    startTracking(path);
    handleLegacyRedirects(path, history);
}

function startTracking(path) {
    Tracking.logPageView(path);
}

const redirectables = {
    // /campaigns -> /dashboard
    campaigns: /^\/campaigns$/,
    // /campaigns/4125 -> /campaigns/4125/overview
    campaignsDetail: /^\/campaigns\/(\d{4})\/?$/,

    // /campaigns/4125/ads -> /campaigns/4125/overview/ads
    campaignsDetailAds: /^\/campaigns\/(\d{4})\/ads\/?$/,
    campaignsDetailCreatives: /^\/campaigns\/(\d{4})\/creatives\/?$/,
    campaignsDetailFiles: /^\/campaigns\/(\d{4})\/files\/?$/,
    campaignsDetailTrafficSheet: /^\/campaigns\/(\d{4})\/traffic-sheet\/?$/,

    // /accounting -> /account
    accounting: /^\/accounting/,

    // /campaigns/4125/delivery -> /campaigns/4125/progress
    delivery: /^\/campaigns\/(\d{4})\/delivery\/?$/,

    // /campaigns/4125/delivery/412501 -> /campaigns/4125/progress/412501
    deliveryAd: /^\/campaigns\/(\d{4})\/delivery\/(\d{6})\/?$/,

    // /campaigns/4125/ads/412501 -> /campaigns/4125/overview/ads#412501
    adDetail: /^\/campaigns\/(\d+)\/ads\/(\d+)\/?$/,

    // /campaigns/4125/creatives/ABCDEF -> /campaigns/4125/overview/creatives#ABCDEF
    creativeDetail: /^\/campaigns\/(\d+)\/creatives\/(\w+)\/?$/,

    // /campaigns/4125/overview/* -> /campaigns/4125/setup/*
    campaignsOverview: /^\/campaigns\/\d{4}\/overview.*/,

    analytics: /^\/campaigns\/(.*)\/report.*/,
};

function handleLegacyRedirects(path, history) {
    if (redirectables.campaignsOverview.test(path)) {
        const url = path.replace('overview', 'setup');
        history.push(url);
    } else if (redirectables.campaigns.test(path)) {
        history.push('/dashboard');
    } else if (redirectables.campaignsDetail.test(path)) {
        const capture = redirectables.campaignsDetail.exec(path);
        history.push(`/campaigns/${capture[1]}/overview`);
    } else if (redirectables.campaignsDetailAds.test(path)) {
        const capture = redirectables.campaignsDetailAds.exec(path);
        history.push(`/campaigns/${capture[1]}/overview/ads`);
    } else if (redirectables.campaignsDetailCreatives.test(path)) {
        const capture = redirectables.campaignsDetailCreatives.exec(path);
        history.push(`/campaigns/${capture[1]}/overview/creatives`);
    } else if (redirectables.campaignsDetailFiles.test(path)) {
        const capture = redirectables.campaignsDetailFiles.exec(path);
        history.push(`/campaigns/${capture[1]}/overview/files`);
    } else if (redirectables.campaignsDetailTrafficSheet.test(path)) {
        const capture = redirectables.campaignsDetailTrafficSheet.exec(path);
        history.push(`/campaigns/${capture[1]}/overview`);
    } else if (redirectables.accounting.test(path)) {
        history.push('/account');
    } else if (redirectables.delivery.test(path)) {
        const capture = redirectables.delivery.exec(path);
        history.push(`/campaigns/${capture[1]}/progress`);
    } else if (redirectables.deliveryAd.test(path)) {
        const capture = redirectables.deliveryAd.exec(path);
        history.push(`/campaigns/${capture[1]}/progress/${capture[2]}`);
    } else if (redirectables.adDetail.test(path)) {
        const capture = redirectables.adDetail.exec(path);
        history.push(`/campaigns/${capture[1]}/overview/ads#${capture[2]}`);
    } else if (redirectables.creativeDetail.test(path)) {
        const capture = redirectables.creativeDetail.exec(path);
        history.push(`/campaigns/${capture[1]}/overview/creatives#${capture[2]}`);
    } else if (isAnalyst() && redirectables.analytics.test(path)) {
        const capture = redirectables.analytics.exec(path);
        history.push(`/campaigns/${capture[1]}/client-report`);
    }
}

function isAnalyst() {
    return _.get(store.getState(), 'profile.authToken.role.id') === 'analyst';
}

function App() {
    const history = useMemo(() => syncHistoryWithStore(browserHistory, store), []);

    return (
        <CatchAll>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Router
                        key={Math.random()}
                        history={history}
                        routes={routes}
                        onUpdate={() => routerUpdateHandler(history)}
                    />
                </LocalizationProvider>
            </Provider>
        </CatchAll>
    );
}

export default App;

import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';
import validate from 'utils/validate';
import { validateCreativeDraft } from 'forms/creative-form/services';
import { defaultSetting } from 'forms/creative-form/services';

const initialState = {
    draft: null,
    isDraftValid: false,
    errors: [],
    showErrors: false,
    isLoading: true,
    isSubmiting: false,
    isDirty: false,
    isFormOpen: false,
};

export const NAME = 'creativeEditForm';

export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__INIT_STATE: {
            const { campaignId } = action.payload;

            // return findCacheState(campaignId, state, initialState);
            return initialState;
        }
        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__OPEN: {
            return {
                ...state,
                isFormOpen: true,
                isLoading: true,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__INIT: {
            const sessionDraft_current = _.get(state, `draft`, null);

            let sessionDraft_next = sessionDraft_current;
            if (sessionDraft_current === null) {
                // Only inititialize session's draft if there isn't already one.
                // This is to prevent router reruning when user navigates to edit creative form.
                sessionDraft_next = _.get(action, 'payload.creative');
            }

            const errors = validateCreativeDraft({ ...sessionDraft_next });

            return {
                ...state,
                creativeId: action.payload.creativeId,
                errors,
                isDraftValid: errors.length === 0,
                draft: sessionDraft_next,
                isLoading: false,
                isDirty: false,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__UPDATE: {
            const { formData } = action.payload;

            const draft = {
                ...state.draft,
                ...formData,
            };

            if (!draft.third_party_clickthrough) {
                draft.clickthrough_url = draft.landing_page;
            }

            if (draft.format === 'native') {
                draft.size = '0x0';
            }

            const errors = validateCreativeDraft(draft);
            return {
                ...state,
                draft,
                errors,
                isDraftValid: errors.length === 0,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__SHOW_ERRORS: {
            return {
                ...state,
                showErrors: true,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT: {
            return {
                ...state,
                isSubmiting: true,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_FAIL: {
            const errors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));

            return {
                ...state,
                errors,
                showErrors: true,
                isSubmiting: false,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_SUCCESS: {
            return {
                ...state,
                isSubmiting: false,
            };
        }

        case c.OVERVIEW__CREATIVE_DRAFT_EDIT__CLOSE: {
            return {
                ...initialState,
            };
        }

        default: {
            return state;
        }
    }
}

import React from 'react';
import { connect } from 'react-redux';

import selector from './selector';
import CampaignForm from 'forms/campaign-form';
import { isInternalUser } from 'states/profile/business-rules';

class EditCampaignForm extends React.Component {
    render() {
        const { campaignId, dataFeesEnabled } = this.props;

        const fields = [
            'advertiser_domain',
            'billing_enabled',
            'billing_rate',
            'billing_term',
            'campaign_budget_enabled',
            'campaign_max_total_spend_local',
            'clients',
            'currency',
            'default_timezone',
            'iab_categories',
            'name',
            'notes',
            'third_party_fees',
            'total_billings_local',
            'custom_fields',
            'use_front_load_pacing',
        ];

        fields.push('fta_enabled', 'fta_management_level', 'fta_location_list');

        if (dataFeesEnabled) {
            fields.push('third_party_fees');
        }

        if (isInternalUser()) {
            fields.push('creatorId');
        }

        const ftaFullyManagedFields = [
            'fta_conversion_lookback_window',
            'fta_pre_campaign_interval_days',
            'fta_post_campaign_interval_days',
            'fta_lift_version',
            'fta_partner_id',
        ];

        fields.push(...ftaFullyManagedFields);

        return <CampaignForm fields={fields} campaignId={campaignId} type={'edit'} />;
    }
}

export default connect(selector)(EditCampaignForm);

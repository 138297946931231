import _ from 'lodash';
import { getSchema } from './schema';
import { getEnv } from 'services/environment';
import { DealCategory, DealType } from '../../../../../common/constants/deals';

export const NAME = 'dealsInventoryModal';

const initialDraft = {
    app_name: '',
    type: DealType.Private_Auction,
    id: '',
    price_local: 0,
    pub_name: '',
    currency: '',
    supply_source: null,
    advertiserId: null,
    category: DealCategory.Private,
};

const initialState = {
    isInventoryDealsModalOpen: false,
    deal: {},
    isDetailsMode: false,
    loading: false,
    draft: initialDraft,
    showErrors: false,
    errors: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'INVENTORY_DEALS_MODAL__OPEN_FORM': {
            const options = {
                allowUnknown: true,
                abortEarly: false,
            };
            const result = getSchema(action.payload.category || state.draft.category).validate(
                state.draft,
                options
            );
            const errorsMap = {};

            if (result.error) {
                _.each(result.error.details, detail => {
                    errorsMap[detail.path.join('.')] = detail.message;
                });
            }

            let newDraft;

            if (getEnv() === 'spain') {
                newDraft = { ...state.draft, supply_source: 'Google Ad Manager', currency: 'USD' };
            } else {
                newDraft = state.draft;
            }

            return {
                ...state,
                isInventoryDealsModalOpen: true,
                isDetailsMode: false,
                errors: errorsMap,
                draft: { ...newDraft, category: action.payload.category },
            };
        }

        case 'INVENTORY_DEALS_MODAL__CLOSE_MODAL': {
            return {
                ...state,
                isInventoryDealsModalOpen: false,
                deal: {},
                draft: initialDraft,
                showErrors: false,
                errors: {},
            };
        }

        case 'INVENTORY_DEALS_MODAL__INIT_DETAILS': {
            return {
                ...state,
                isInventoryDealsModalOpen: true,
                isDetailsMode: true,
                loading: true,
            };
        }

        case 'INVENTORY_DEALS_MODAL__DETAILS_DATA_END': {
            const options = {
                allowUnknown: true,
                abortEarly: false,
            };
            const result = getSchema(state.draft.category).validate(action.payload.deal, options);
            const errorsMap = {};

            if (result.error) {
                _.each(result.error.details, detail => {
                    errorsMap[detail.path.join('.')] = detail.message;
                });
            }
            return {
                ...state,
                draft: action.payload.deal,
                loading: false,
                deal: action.payload.deal,
                errors: errorsMap,
            };
        }

        case 'INVENTORY_DEALS_MODAL__DETAILS_DATA_ERROR': {
            return {
                ...state,
                loading: false,
            };
        }

        case 'INVENTORY_DEALS_MODAL_UPDATE_FORM_FIELD': {
            const { fieldName, fieldValue } = action.payload;
            const options = {
                allowUnknown: true,
                abortEarly: false,
            };
            const newDraft = { ...state.draft, [fieldName]: fieldValue };
            const result = getSchema(state.draft.category).validate(newDraft, options);

            const errorsMap = {};
            if (result.error) {
                _.each(result.error.details, detail => {
                    errorsMap[detail.path.join('.')] = detail.message;
                });
            }

            return {
                ...state,
                draft: newDraft,
                errors: errorsMap,
            };
        }

        case 'INVENTORY_DEALS_MODAL_SHOW_FIELD_ERRORS': {
            return {
                ...state,
                showErrors: true,
            };
        }

        case 'INVENTORY_DEALS_MODAL_SUBMIT_DRAFT_SUCCESS': {
            return {
                ...state,
                isInventoryDealsModalOpen: false,
                showErrors: false,
                draft: initialDraft,
                deal: {},
            };
        }

        case 'INVENTORY_DEALS_MODAL_SUBMIT_DRAFT_ERROR': {
            const { error } = action.payload;
            return {
                ...state,
                showErrors: true,
                errors: error.body.reduce(
                    (acc, currentError) => ({ ...acc, [currentError.field]: currentError.message }),
                    {}
                ),
            };
        }

        default: {
            return state;
        }
    }
}

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// gridSize           number    The grid size of a cluster in pixels.
// maxZoom            number    The maximum zoom level that a marker can be part of a cluster.
// zoomOnClick        boolean   Whether the default behaviour of clicking on a cluster is to zoom into it.
// averageCenter      boolean   Whether the center of each cluster should be the average of all markers in the cluster.
// minimumClusterSize number    The minimum number of markers to be in a cluster before the markers are hidden and a count is shown.
// styles             object    An object that has style properties.

var MC_OPTIONS = {
    gridSize: 20,
    // maxZoom
    zoomOnClick: true,
    averageCenter: true,
    minimumClusterSize: 6,
    //styles: clusterStyles,
    imagePath: '/images/marker-clusterer/m',
};

var MAP_OPTIONS = {
    center: { lat: 43.6525, lng: -79.381667 }, // Toronto,
    zoom: 12,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
    maxZoom: 18,
};

function clusterCalculator(markers) {
    var count = markers.length; // the markers length is double counted (waypoint + circle)
    var actualCount = count / 2; // so we devided by 2 to get the actual number of point
    var dv = count;
    while (dv !== 0) {
        dv = parseInt(dv / 10, 10);
    }

    return {
        text: Math.round(actualCount),
        index: 3, // fix the color for clustering market at red
    };
}

function getPoint(id, pois) {
    let point = void 0;
    const poi = _.get(pois, `${id}`, void 0);
    if (poi) {
        point = {
            id: id,
            latitude: poi.lat_lng[0],
            longitude: poi.lat_lng[1],
            name: poi.name,
            tag: poi.tag,
        };
    }
    return point;
}

export default class extends React.Component {
    static propTypes = {
        pois: PropTypes.object.isRequired,
        parent: PropTypes.object.isRequired,
        geoLayers: PropTypes.array.isRequired,
    };

    UNSAFE_componentWillMount() {
        // window.m = this;
        this._cached = {};
        this._cached.markers = [];
        this._cached.circles = [];
        this._cached.points = [];
    }

    componentDidMount() {
        this.map = new google.maps.Map(this.refs.map, MAP_OPTIONS);

        this.cluster = new MarkerClusterer(this.map, [], MC_OPTIONS);
        this.cluster.setCalculator(clusterCalculator);

        // Initialize Click events for points/markers
        // this.initializeGoogleClickEvents();
    }

    componentWillUnmount() {
        // ATTENTION:
        // https://code.google.com/p/gmaps-api-issues/issues/detail?id=3803
        // There is no way official way to clean up a Google Map
        // Best you can do is remove the event listeners that you manually added
        //
        // TODO in the far off future:
        // Figure out how to re-initialize and re-use existing Map instances
        // google.maps.event.clearListeners(this.drawingManager);

        google.maps.event.clearListeners(this.map);
        // this.refs.widget.removeEventListener('click', this.onWidgetClick);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const that = this;

        const layerIdToRadiusMapping = {};
        _.forEach(nextProps.geoLayers, layer => {
            layerIdToRadiusMapping[layer.id] = layer.radius;
        });

        // pois
        const pois_nxt = _.get(nextProps, `pois`, void 0);
        const pois_prv = _.get(this.props, `pois`, void 0);
        const ids_nxt = pois_nxt ? Object.keys(pois_nxt) : [];
        const ids_prv = pois_prv ? Object.keys(pois_prv) : [];

        // clear all points before show next batch
        this.cluster.clearMarkers();
        this._cached.markers = [];
        this._cached.circles = [];
        this._cached.points = [];

        ids_nxt.forEach(id => {
            const _point = getPoint(id, pois_nxt);
            this._cached.points.push(_point);

            const { marker, circle } = that.createGoogleMapMarker(_point, layerIdToRadiusMapping);
            that._cached.markers.push(marker);
            that._cached.circles.push(circle);
        });

        this.cluster.addMarkers(this._cached.markers);
        this.cluster.addMarkers(this._cached.circles);

        const symmetricDiff = _.xor(ids_nxt, ids_prv);
        if (symmetricDiff.length !== 0) {
            // this is new set of pois so show all of them
            this.fitBoundsToPoints();
        }
    }

    shouldComponentUpdate() {
        // component should never update because updating is delegate to goggle map
        return false;
    }

    // initializeGoogleClickEvents() { },

    createGoogleMapMarker = (pointData, layerIdToRadiusMapping) => {
        const marker = new google.maps.Marker({
            position: {
                lat: pointData.latitude,
                lng: pointData.longitude,
            },
            map: this.map,
            pointId: pointData.id,
        });

        const circle = this.createNewGoogleMapCircle(marker, pointData, layerIdToRadiusMapping);
        return { marker, circle };
    };

    createNewGoogleMapCircle = (marker, pointData, layerIdToRadiusMapping) => {
        const radius = layerIdToRadiusMapping[pointData.tag];

        const markerCenter = marker.getPosition();

        // Add circle overlay and bind to marker
        const circle = new google.maps.Circle({
            map: this.map,
            radius: parseInt(radius, 10),
            fillColor: '#BBDDDD', // @ef5-color__deep-cyan-1
            strokeColor: '@ef5-color__deep-cyan-3', // @ef5-color__deep-cyan-3
            strokeWeight: 1,
            strokeOpacity: 0.5,
            pointId: 'circle_' + pointData.id,
            center: markerCenter,
        });

        circle.getPosition = circle.getCenter;
        // The above is a hack, markerClusterer library look for getPosition, but the
        // equivalent of getPosition for google.maps.Circle class is getCenter

        // circle.bindTo('center', marker, 'position');
        return circle;
    };

    fitBoundsToPoints = () => {
        const pointsForBoundary = this._cached.points;

        // ignore if no points, otherwise you'll end up in the ocean
        if (!pointsForBoundary || !pointsForBoundary.length) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        pointsForBoundary.forEach(point => {
            bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));
        });

        this.map.fitBounds(bounds);

        // zoom out if there is only one point
        if (pointsForBoundary.length === 1) {
            this.map.setZoom(14);
        }
    };

    render() {
        return (
            <div ref="widget" className="ef3-geofencing_mapWrapper">
                <div ref="map" className="ef3-geofencing_mapWrapper_map" />
                <div className="ef3-geofencing_mapWrapper_buttonGroup">
                    <button
                        onClick={() => this.fitBoundsToPoints()}
                        className="btn btn-secondary btn-sm"
                    >
                        See All Points
                    </button>
                </div>
            </div>
        );
    }
}

import _ from 'lodash';

export default function selector(storeState, props) {
    const partnerId = _.get(storeState, 'campaignReportFta.ownOrganization.fta_partner_id');
    const ftaLocationLists = _.get(storeState, 'campaignReportFta.ftaLocationLists');
    const isInitialized = _.get(storeState, 'campaignReportFta.isInitialized');
    const ftaCampaignMetaData = _.get(storeState, 'campaignReportFta.ftaCampaignMetaData');

    const { campaignId } = props.params;

    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);

    return {
        campaign,
        ftaLocationLists,
        campaignRelationship: getRelationship(storeState, props),
        partnerId,
        isInitialized,
        ftaCampaignMetaData,
    };
}

function getRelationship(storeState, props) {
    const { campaignId } = props.params;
    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`, {});
    const userOrganization = _.get(storeState, 'profile.organizationId');

    const isClient = !!_.find(campaign.clients, { organization: userOrganization });
    const isOwner = campaign.owner === userOrganization;

    return {
        isOwner,
        isClient,
    };
}

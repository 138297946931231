import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CenterLayout from 'packages/widgets-v5/center-layout';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DownloadTextContents from 'widgets-v5/download-text-contents';
import { InfoTooltip } from 'widgets-v6/tooltip';
import DropdownWithMultipleSelect from './DropdownWithMultipleSelect';
import { BulkEditProgressBar } from './modules/progress-bar';
import { CreativeBulkEdit as actions } from './actions';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import FileUpload from 'widgets-v5/file-upload';
import { DialogTitle } from 'widgets-v6/modal';
import MatchUpload from './modules/match-upload';
import VirtualizedCreativeEditTableGenerator from './modules/table-generator';
import selector from './selector';

const dialogStyles = theme => ({
    root: {
        width: '75%',
        height: '65%',
        minHeight: '400px',
    },
    container: {
        height: '100%',
    },
    'grid-xs-3': {
        borderRight: `2px solid ${theme.palette.grey[300]}`,
    },
    'grid-xs-9': {
        height: '100%',
    },
    dialogBody: {
        overflow: 'auto',
    },
    buttonWrapper: {
        paddingRight: '8px',
        borderRight: `2px solid ${theme.palette.grey[300]}`,
    },
});

export function getCreativeFormatTextFieldMapping() {
    const mapping = {
        standard: [
            'name',
            'landing_page',
            'clickthrough_url',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        video: [
            'name',
            'landing_page',
            'clickthrough_url',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        native: [
            'name',
            'landing_page',
            'clickthrough_url',
            'third_party_pixels',
            'third_party_javascript_urls',
            'star_rating',
            'title',
            'text',
            'call_to_action',
        ],
        third_party_vast: [
            'name',
            'hosted_vast_url',
            'reference_screenshot',
            'video_duration',
            'video_bit_rate_kbps',
            'landing_page',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        custom_html: [
            'clickthrough_url',
            'content_html',
            'landing_page',
            'name',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        mraid: [
            'clickthrough_url',
            'content_html',
            'landing_page',
            'name',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
    };

    return mapping;
}

export function getCreativeFormatTextFieldMapping2() {
    const mapping = {
        standard: [
            'name',
            'third_party_clickthrough',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        video: [
            'name',
            'third_party_clickthrough',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        native: [
            'name',
            'third_party_clickthrough',
            'third_party_pixels',
            'third_party_javascript_urls',
            'star_rating',
            'title',
            'text',
            'call_to_action',
        ],
        third_party_vast: [
            'name',
            'hosted_vast_url',
            'reference_screenshot',
            'video_duration',
            'video_bit_rate_kbps',
            'third_party_clickthrough',
            'landing_page',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        custom_html: [
            'third_party_clickthrough',
            'content_html',
            'name',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
        mraid: [
            'third_party_clickthrough',
            'content_html',
            'name',
            'third_party_pixels',
            'third_party_javascript_urls',
        ],
    };

    return mapping;
}

export const optionKeyValueMapping = {
    id: 'Creative ID',
    name: 'Name',
    landing_page: 'Landing Page',
    clickthrough_url: 'Clickthrough URL',
    third_party_clickthrough: 'Clicktracking',
    third_party_pixels: 'Third Party Pixels',
    third_party_javascript_urls: 'Third Party Javascript',
    star_rating: 'Star Rating',
    title: 'Ad Title',
    text: 'Main Text',
    call_to_action: 'Call To Action',
    hosted_vast_url: 'Hosted VAST URL',
    reference_screenshot: 'Reference Screenshot',
    video_duration: 'Duration',
    video_bit_rate_kbps: 'Bit Rate (Kbps)',
    content_html: 'HTML Markup',
    image_url: 'Creative Asset',
};

class CreativeBulkEditForm extends React.Component {
    constructor(props) {
        super(props);
        this.csvLink = React.createRef();
    }

    handleCreativeFieldChange = _.debounce((creativeId, fieldName, fieldValue) => {
        const { dispatch } = this.props;

        dispatch(actions.updateField(creativeId, fieldName, fieldValue));
    });

    handleCreativeArrayFieldChange = (creative, field, value, index) => {
        const arrayBefore = creative[field];

        const arrayAfter =
            arrayBefore.length > 0
                ? _.map(arrayBefore, (valueInArray, key) => {
                      if (key === index) {
                          return value;
                      }

                      return valueInArray;
                  })
                : value;

        this.handleCreativeFieldChange(creative.id, field, arrayAfter);
    };

    handleSelectedFieldChange = fields => {
        if (fields.length < this.props.selectedFields.length) {
            const removedField = _.filter(
                this.props.selectedFields,
                field => !_.includes(fields, field)
            )[0];
            this.props.dispatch(actions.removeField(removedField));
        } else {
            const addedField = _.filter(
                fields,
                field => !_.includes(this.props.selectedFields, field)
            )[0];
            this.props.dispatch(actions.addField(addedField));
        }
    };

    handleSaveForm = isSaveAudit => {
        const { selectedCreativeIds, campaignId } = this.props;
        this.props.dispatch(actions.saveBulkEdit2(campaignId, selectedCreativeIds, isSaveAudit));
    };

    cancelChangesInForm = () => {
        this.props.dispatch(actions.closeForm());
    };

    handleTemplateUpload = (formData, selectedCreatives, fileType) => {
        const { selectedCreativeIds } = this.props;

        if (fileType === 'generic') {
            this.props.dispatch(
                actions.processUploadedTemplateToGetMatchOption(formData, selectedCreatives)
            );
        } else if (fileType === 'tracker' || fileType === 'vendor') {
            this.props.dispatch(
                actions.readUploadedDCMTemplate(formData, selectedCreativeIds, fileType)
            );
        }
    };

    handleDownloadData = () => {
        this.csvLink.link.click();
    };

    render() {
        const {
            hasSelectedFields,
            selectedCreativeIds,
            classes,
            selectedFields,
            errors,
            showErrors,
            isLoading,
            isSubmitting,
            openForm,
            serverSideErrors,
            files,
            selectedCreatives,
            needMatch,
            fileData,
            updatedFields,
            uploadError,
            csvContents,
            isFileUploaded,
        } = this.props;
        if (!openForm) {
            return null;
        }
        const selectedCreativeFormats = _.map(selectedCreatives, creative => creative.format);

        const optionsToSelect = _.union(
            ...selectedCreativeFormats.map(format => {
                return getCreativeFormatTextFieldMapping2()[format];
            })
        ).map(option => ({
            value: option,
            label: optionKeyValueMapping[option],
        }));

        const selectedFieldsErrors = _.values(errors)
            .map(errorsOfOneCreative => {
                return _.filter(_.keys(errorsOfOneCreative), errorField =>
                    _.includes(selectedFields, errorField)
                );
            })
            .filter(errorArray => errorArray.length > 0);

        return (
            <div>
                <Dialog
                    open={openForm && selectedCreativeIds.length > 0}
                    maxWidth="lg"
                    PaperProps={{ classes: { root: classes.root } }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle onClose={this.cancelChangesInForm}>Edit Creatives</DialogTitle>

                    <Box mx={2} my={1}>
                        <Grid container spacing={1}>
                            {needMatch && (
                                <Grid item>
                                    <MatchUpload
                                        needMatch={needMatch}
                                        fileData={fileData}
                                        selectedCreatives={selectedCreatives}
                                    />
                                </Grid>
                            )}
                            <Grid item>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <div className={classes.buttonWrapper}>
                                            <Box display="flex">
                                                <Typography gutterBottom>
                                                    Upload Edits or Modify Inline
                                                </Typography>
                                                <InfoTooltip
                                                    title={
                                                        <div>
                                                            <div>
                                                                1.Select the fields you want to edit
                                                                from Available Fields
                                                            </div>
                                                            <div>
                                                                2.Modify the fields inline, or
                                                                download this data as a CSV and
                                                                reupload the saved file
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </Box>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <FileUpload
                                                        key={'generic'}
                                                        accept={'.csv'}
                                                        onChange={formdata =>
                                                            this.handleTemplateUpload(
                                                                formdata,
                                                                selectedCreatives,
                                                                'generic'
                                                            )
                                                        }
                                                        disabled={false}
                                                        buttonText="Upload Edits"
                                                        disableElevation
                                                        variant="outlined"
                                                        color="primary"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DownloadTextContents
                                                        contentType="text/csv"
                                                        filename="creatives_bulk_edit.csv"
                                                        contents={csvContents}
                                                    >
                                                        {onDownload => (
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={onDownload}
                                                                className={classes.templateButton}
                                                            >
                                                                Download Data
                                                            </Button>
                                                        )}
                                                    </DownloadTextContents>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Box display="flex">
                                            <Typography gutterBottom>DCM Tracker Tags</Typography>
                                            <InfoTooltip title="Upload a spreadsheet containing DCM Tracker Tags. Impression tracking and click tracking pixels will be automatically applied to creatives with matching names" />
                                        </Box>
                                        <FileUpload
                                            key={'dcm'}
                                            accept={'.xlsx, .xls'}
                                            onChange={formdata =>
                                                this.handleTemplateUpload(
                                                    formdata,
                                                    selectedCreatives,
                                                    'tracker'
                                                )
                                            }
                                            disabled={false}
                                            buttonText="Upload DCM Tracker Tags"
                                            disableElevation
                                            variant="outlined"
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <DialogContent classes={{ root: classes.dialogRoot }}>
                        {isLoading ? (
                            <div style={{ width: '100%', height: 100 }}>
                                <CenterLayout>
                                    <BlockLoadGroup isLoading={true} />
                                </CenterLayout>
                            </div>
                        ) : (
                            <Grid container spacing={3} classes={{ container: classes.container }}>
                                <Grid item xs={3} classes={{ 'grid-xs-3': classes['grid-xs-3'] }}>
                                    <DropdownWithMultipleSelect
                                        selectedFields={selectedFields}
                                        optionsToSelect={optionsToSelect}
                                        onChange={this.handleSelectedFieldChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                    classes={{ 'grid-xs-9': classes['grid-xs-9'] }}
                                    className={classes.dialogBody}
                                >
                                    <React.Fragment>
                                        <VirtualizedCreativeEditTableGenerator
                                            selectedCreatives={selectedCreatives}
                                            fields={selectedFields}
                                            onChange={this.handleCreativeFieldChange}
                                            onArrayValueChange={this.handleCreativeArrayFieldChange}
                                            errors={errors}
                                            showErrors={showErrors}
                                            files={files}
                                            updatedFields={updatedFields}
                                            isFileUploaded={isFileUploaded}
                                        />
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                        )}
                        {isSubmitting && (
                            <BulkEditProgressBar selectedCreatives={selectedCreatives} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        {showErrors && selectedFieldsErrors.length > 0 && (
                            <FormControl error>
                                <FormHelperText>
                                    Please resolve form errors before continuing.
                                </FormHelperText>
                            </FormControl>
                        )}
                        {showErrors && _.keys(serverSideErrors).length > 0 && (
                            <FormControl error>
                                <FormHelperText>
                                    Creatives {_.keys(serverSideErrors).join(', ')} failed to save.
                                    Please try again later.
                                </FormHelperText>
                            </FormControl>
                        )}
                        {showErrors && uploadError.length > 0 && (
                            <FormControl error>
                                <FormHelperText classes={{ root: classes.arrayHelperText }}>
                                    {uploadError[0]}
                                </FormHelperText>
                            </FormControl>
                        )}
                        <Button
                            onClick={() => this.handleSaveForm(false)}
                            autoFocus
                            variant="outlined"
                            color="primary"
                            disabled={isSubmitting || !hasSelectedFields}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => this.handleSaveForm(true)}
                            color="primary"
                            variant="outlined"
                            disabled={isSubmitting || !hasSelectedFields}
                        >
                            Save And Audit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(dialogStyles)(connect(selector)(CreativeBulkEditForm));

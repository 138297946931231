import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'adSummary';
export const initialState = {
    ad: null,
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        init: state => state,
        initSuccess: (state, ad) => ({
            ...state,
            ad,
        }),
        reset: () => initialState,
    },
});

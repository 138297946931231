import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import QuickDialog from 'widgets-v5/quick-dialog';
import StandardInput from 'widgets-v5/standard-input';
import Dropdown from 'widgets-v5/dropdown';
import Menu, { MenuItem, MenuSeparator } from 'widgets-v5/menu';
import CheckboxMenu from 'widgets-v5/checkbox-menu';
import Checkbox from 'widgets-v5/checkbox';

export default class extends React.Component {
    static displayName = 'MultipleSelector';

    static propTypes = {
        className: PropTypes.string,
        searchable: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.any.isRequired,
            })
        ).isRequired,
        values: PropTypes.array.isRequired,
        placeholder: PropTypes.string.isRequired,
        clearable: PropTypes.bool,
        useAllButton: PropTypes.bool,
    };

    static defaultProps = {
        searchable: false,
        clearable: false,
        onChange() {},
        values: [],
        useAllButton: false,
    };

    state = {
        filter: '',
    };

    focusInput = () => {
        if (this.filterInput) {
            this.filterInput.focus();
        }
    };

    onFilterInput = event => {
        this.setState({ filter: event.target.value });
    };

    clearValues = () => {
        if (this.props.clearable) {
            this.props.onChange([], null);
        }
    };

    selectAll = () => {
        this.props.onChange([], null);
    };

    render() {
        const filteredItems = this.props.options.filter(
            o => o.label.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
        );

        const checkedValues = {};
        _.each(this.props.values, value => {
            checkedValues[value] = true;
        });

        let onClear;
        if (this.props.clearable) {
            onClear = this.props.values.length > 0 ? this.clearValues : null;
        }

        let buttonLabel;
        if (this.props.values.length === 0) {
            buttonLabel = this.props.useAllButton ? (
                <div>{this.props.placeholder}</div>
            ) : (
                <div className="ef4-standard-selector__default">{this.props.placeholder}</div>
            );
        } else {
            buttonLabel = this.props.options
                .filter(o => checkedValues[o.value])
                .map(o => o.label)
                .join(', ');
        }

        return (
            <Dropdown
                buttonLabel={buttonLabel}
                onClear={onClear}
                clearable={this.props.values.length > 0 && this.props.clearable}
                onOpen={this.focusInput}
                disabled={this.props.disabled}
                isLoading={this.props.isLoading}
            >
                {close => (
                    <div>
                        {this.props.searchable && (
                            <div className="ef4-standard-selector__input">
                                <input
                                    ref={input => {
                                        this.filterInput = input;
                                    }}
                                    className="ef4-standard-selector__filter"
                                    value={this.state.filter}
                                    onChange={this.onFilterInput}
                                    placeholder="Filter selections"
                                />
                            </div>
                        )}
                        {this.props.useAllButton && (
                            <Checkbox
                                className="checkbox-menu__item"
                                checked={this.props.values.length === 0 ? true : false}
                                onChange={this.selectAll}
                                label={'All'}
                            />
                        )}
                        <CheckboxMenu
                            values={this.props.values}
                            options={filteredItems}
                            onChange={this.props.onChange}
                        />
                    </div>
                )}
            </Dropdown>
        );
    }
}

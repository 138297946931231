import React, { useState } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Row, Col } from 'react-flexbox-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {
    calculateAudienceFee,
    hasAnyAudiences,
    calculateTacTicsThirdPartyFees,
} from 'states/resources/ads/business-logic';
import { formatNumber_currency } from 'utils/formatting';
import { NeutralButton, SecondaryButton } from 'widgets-v5/buttons';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import AudienceOptions from 'containers/audience-options';
import AudienceSegmentPicker from 'containers/audience-segment-picker';
import AudienceSegmentSummaryV2 from 'containers/audience-segment-picker/modules/audience-segment-summary-v2';

import { getAudienceWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

const defaultGeoTargetingSettings = {
    category_layers: [],
    categories: [],
    custom_layers: [],
    target: true,
    retarget: true,
    lookbackMap: {},
    targetingSettings: {},
    days_to_retarget: 21,
};

const useModalStyles = makeStyles({
    audienceColumn: {
        height: 'fit-content',
    },
});

const AudienceSegmentModal = ({
    onChangeSegments,
    audiences,
    audienceExclude,
    audienceRates,
    platform,
    geo_targeting_settings,
    campaignId,
    shouldHideFlexSegments,
    platforms,
    audienceWarning,
    isCTVCampaign,
    isDOOHCampaign,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalDraft, setModalDraft] = useState({
        includedItems: audiences,
        excludedItems: audienceExclude,
        geo_targeting_settings: geo_targeting_settings || defaultGeoTargetingSettings,
        audienceWarning,
    });

    const classes = useModalStyles();

    const mapAudiences = (audienceMapping, audiencesToMap = audiences) => {
        return _(audiencesToMap)
        .map(audience => audienceMapping[audience])
        .filter(audience => audience)
        .value();
    }

    function closeModal() {
        setIsOpen(false);
        setModalDraft({
            includedItems: [],
            excludedItems: [],
            geo_targeting_settings: defaultGeoTargetingSettings,
            audienceWarning,
        });
    }

    function openModal() {
        setIsOpen(true);
    }

    function openModalInEditMode() {
        setModalDraft({
            includedItems: audiences,
            excludedItems: audienceExclude,
            geo_targeting_settings: geo_targeting_settings || defaultGeoTargetingSettings,
            audienceWarning,
        });

        openModal();
    }

    function save() {
        onChangeSegments(modalDraft);
        closeModal();
    }

    function handleChangeSegments({
        includedItems,
        excludedItems,
        geo_targeting_settings,
        selectedFlexSegments,
    }) {
        const allMappedSegments = _.concat(includedItems, selectedFlexSegments);
        const warningData = getAudienceWarning({ adPlatforms: platforms, allMappedSegments, isCTVCampaign, isDOOHCampaign });

        setModalDraft({
            includedItems,
            excludedItems,
            geo_targeting_settings,
            audienceWarning: warningData,
        });
    }

    const removeExcludedItem = ({ id, audienceMapping }) => {
        const newExcludedAudiences = _(audienceExclude)
            .filter(audienceId => audienceId !== id)
            .map(id => audienceMapping[id])
            .value();
        const newAudiences = mapAudiences(audienceMapping);

        onChangeSegments({
            includedItems: newAudiences,
            geo_targeting_settings,
            excludedItems: newExcludedAudiences,
        });
        setModalDraft({
            ...modalDraft,
            excludedItems: _.filter(modalDraft.excludedItems, audienceId => audienceId !== id),
        });
    };

    const removeAllExcluded = ({ audienceMapping }) => {
        const newAudiences = mapAudiences(audienceMapping);
        onChangeSegments({
            includedItems: newAudiences,
            geo_targeting_settings,
            excludedItems: [],
        });
        setModalDraft({
            ...modalDraft,
            excludedItems: [],
        });
    };
    const removeIncludedItem = ({ id, audienceMapping }) => {
        const newAudiences = _(audiences)
            .filter(audienceId => audienceId !== id)
            .map(id => audienceMapping[id])
            .value();
        const newExcludedAudiences = mapAudiences(audienceMapping, audienceExclude);

        onChangeSegments({
            includedItems: newAudiences,
            geo_targeting_settings,
            excludedItems: newExcludedAudiences,
        });
        setModalDraft({
            ...modalDraft,
            includedItems: _.filter(modalDraft.includedItems, audienceId => audienceId !== id),
        });
    };

    const removeAllIncluded = ({ audienceMapping }) => {
        const newExcludedAudiences = mapAudiences(audienceMapping, audienceExclude);
        onChangeSegments({
            includedItems: [],
            geo_targeting_settings: defaultGeoTargetingSettings,
            excludedItems: newExcludedAudiences,
        });
        setModalDraft({
            ...modalDraft,
            includedItems: [],
        });
    };

    const getGeoSettingsField = source_type => {
        let fieldToChange;
        if (source_type === 'flex_standard_brand') {
            fieldToChange = 'category_layers';
        } else if (source_type === 'flex_location_category') {
            fieldToChange = 'categories';
        } else if (source_type === 'flex_custom_brand') {
            fieldToChange = 'custom_layers';
        }
        return fieldToChange;
    };

    const removeFlexSegment = ({ id, source_type, audienceMapping }) => {
        const newAudiences = mapAudiences(audienceMapping);
        const newExcludedAudiences = mapAudiences(audienceMapping, audienceExlclude);

        const fieldToChange = getGeoSettingsField(source_type);
        const nextIncluded = _.filter(geo_targeting_settings[fieldToChange], tagId => tagId !== id);
        const newLookbackMap = _.omit(geo_targeting_settings.lookbackMap, id);
        const newTargetingSettings = _.omit(geo_targeting_settings.targetingSettings, id);

        const newGeoTargetingSettings = {
            ...geo_targeting_settings,
            [fieldToChange]: nextIncluded,
            lookbackMap: newLookbackMap,
            targetingSettings: newTargetingSettings,
        };

        onChangeSegments({
            includedItems: newAudiences,
            geo_targeting_settings: newGeoTargetingSettings,
            excludedItems: newExcludedAudiences,
        });
        setModalDraft({
            ...modalDraft,
            geo_targeting_settings: newGeoTargetingSettings,
        });
    };

    const categoryLayers = _.get(geo_targeting_settings, 'category_layers');
    const categories = _.get(geo_targeting_settings, 'categories');
    const customLayers = _.get(geo_targeting_settings, 'custom_layers');

    const audiencesWithFlexSegments = _.concat(
        audiences,
        categoryLayers,
        categories,
        customLayers
    ).filter(audience => audience);

    const flexSegments = _.concat(categoryLayers, categories, customLayers).filter(
        audience => audience
    );

    const hasAudiences = hasAnyAudiences({
        audiences: !shouldHideFlexSegments ? audiencesWithFlexSegments : audiences,
        audience_exclude: audienceExclude,
    });

    const audienceRatesMap = {};
    _.forEach(audienceRates, rate => {
        audienceRatesMap[rate.name] = rate.fee;
    });

    return (
        <React.Fragment>
            <Row>
                {hasAudiences && (
                    <Col xs={11} className={classes.audienceColumn}>
                        <AudienceOptions shouldHideFlexSegments={shouldHideFlexSegments}>
                            {({ mapping, flexMapping }) => {
                                const mappedAudiences = mapAudiences(mapping);
                                const mappedFlexSegments = _.map(
                                    flexSegments,
                                    value => flexMapping[value]
                                );

                                return (
                                    <AudienceSegmentSummaryV2
                                        includedItems={
                                            !shouldHideFlexSegments
                                                ? _.concat(mappedAudiences, mappedFlexSegments)
                                                : mappedAudiences
                                        }
                                        excludedItems={_.map(
                                            audienceExclude,
                                            value => mapping[value]
                                        )}
                                        footerLabel={({ includedItems, excludedItems }) => {
                                            const audienceFee = calculateAudienceFee({
                                                includedAudiences: includedItems,
                                                excludedAudiences: excludedItems,
                                                audienceRates: audienceRates,
                                            });

                                            return `Data Cost: ${formatNumber_currency(
                                                audienceFee
                                            )} CPM`;
                                        }}
                                        thirdPartyFees={({ includedItems, excludedItems }) => {
                                            const thirdPartyFees = calculateTacTicsThirdPartyFees({
                                                includedAudiences: includedItems,
                                                excludedAudiences: excludedItems,
                                            });
                                            return `Third Party Fees: ${formatNumber_currency(
                                                thirdPartyFees
                                            )} CPM`;
                                        }}
                                        audienceRatesMap={audienceRatesMap}
                                        geo_targeting_settings={
                                            shouldHideFlexSegments
                                                ? defaultGeoTargetingSettings
                                                : geo_targeting_settings
                                        }
                                        removeIncludedItem={id =>
                                            removeIncludedItem({ id, audienceMapping: mapping })
                                        }
                                        removeExcludedItem={id =>
                                            removeExcludedItem({ id, audienceMapping: mapping })
                                        }
                                        removeAllExcluded={() =>
                                            removeAllExcluded({ audienceMapping: mapping })
                                        }
                                        removeAllIncluded={() =>
                                            removeAllIncluded({ audienceMapping: mapping })
                                        }
                                        removeFlexSegment={({ id, source_type }) =>
                                            removeFlexSegment({
                                                id,
                                                source_type,
                                                audienceMapping: mapping,
                                            })
                                        }
                                        hideGeoTargetingEditor
                                    />
                                );
                            }}
                        </AudienceOptions>
                    </Col>
                )}
                {hasAudiences ? (
                    <Col xs={1}>
                        <NeutralButton
                            icon={<i className="fa fa-pencil" />}
                            onClick={openModalInEditMode}
                        />
                    </Col>
                ) : (
                    <Col>
                        <SecondaryButton text="Add Audiences" onClick={openModal} />
                    </Col>
                )}
            </Row>
            <AudienceDialog onClose={closeModal} open={isOpen} onSave={save}>
                <AudienceSegmentPicker
                    platform={platform}
                    onChange={handleChangeSegments}
                    geo_targeting_settings={modalDraft.geo_targeting_settings}
                    includedAudiences={modalDraft.includedItems}
                    excludedAudiences={modalDraft.excludedItems}
                    audienceRates={audienceRates}
                    campaignId={campaignId}
                    platforms={platforms}
                    audienceWarning={modalDraft.audienceWarning}
                    isCTVCampaign={isCTVCampaign}
                />
            </AudienceDialog>
        </React.Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    dialogContent: {
        height: 600,
    },
    dialogMaxWidth: {
        maxWidth: '1450px',
    },
    dialogTitle: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
}));

function AudienceDialog(props) {
    const classes = useStyles();

    return (
        <Dialog
            classes={{ paperWidthLg: classes.dialogMaxWidth }}
            onClose={props.onClose}
            open={props.open}
            fullWidth
            maxWidth="lg"
            scroll={undefined}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div>
                        <Typography variant="h6">Audiences</Typography>
                    </div>
                    <div>
                        <IconButton onClick={props.onClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>{props.children}</DialogContent>
            <DialogActions>
                <Button onClick={props.onSave} color="primary" variant="contained">
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AudienceSegmentModal;

import _ from 'lodash';
import React from 'react';

import Typography from '@mui/material/Typography';

import BaseColumnForm from '../base-column-form';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import { applyBillingTermFix, updateBudgetFields } from 'forms/ad-form/services';
import { isBillingsManagedThroughEF } from 'states/resources/campaigns/business-logic';
import { isBonusAd, getBillableVolume } from 'states/resources/ads/business-logic';
import Budget from 'forms/ad-form/modules/budget';
import shallowCompare from 'utils/shallow-compare';
import flags from 'containers/flags/service';

export class GoalForm extends React.Component {
    onFieldChange = (draft, updateDraft) => {
        return field => {
            return value => {
                const formData = { [field]: value };
                const state = { draft };

                let nextDraft = { ...draft, ...formData };
                nextDraft = applyBillingTermFix(nextDraft, state, formData);
                nextDraft = updateBudgetFields(nextDraft, this.props.campaign, formData);

                updateDraft(nextDraft);
            };
        };
    };

    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Budget"
                fields={[
                    'billing_rate',
                    'ef_billing_terms',
                    'billable_volume',
                    'non_billable_volume',
                    'billing_enabled',
                    'max_daily_billings',
                    'max_daily_spend_local',
                    'max_total_billings',
                    'max_total_clicks',
                    'max_total_impressions',
                    'max_total_spend_local',
                    'primary_pacing',
                ]}
                shouldRefreshPredictionsOnSave={false}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <Budget
                        formData={draft}
                        campaign={campaign}
                        onFieldChange={this.onFieldChange(draft, updateDraft)}
                        errors={errors}
                        showErrors={shouldShowErrors}
                        hideThirdPartyFees={true}
                    />
                )}
            </BaseColumnForm>
        );
    }
}

export class GoalSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;

        let summary;

        if (campaign.budget_allocation_method === 'auto') {
            return (
                <div>
                    <Typography> Auto-budget enabled at campaign level </Typography>
                </div>
            );
        }

        if (isBillingsManagedThroughEF(campaign)) {
            let templateData = {};
            switch (ad.primary_pacing) {
                case 'clicks':
                case 'impressions':
                    templateData = {
                        targetLabel: `Billable ${ad.primary_pacing}`,
                        targetValue: formatNumber_whole(ad.billable_volume),
                        bonusLabel: `Non-billable ${ad.primary_pacing}`,
                        bonusValue: formatNumber_whole(ad.non_billable_volume),
                    };
                    break;
                case 'billings':
                    templateData = {
                        targetLabel: 'Revenue Budget',
                        targetValue: formatNumber_currency(ad.max_total_billings),
                    };
                    break;
                case 'spend':
                    templateData = {
                        targetLabel: 'Total Cost Budget',
                        targetValue: formatNumber_currency(getBillableVolume(ad)),
                    };
                    break;
            }
            summary = (
                <div>
                    {!isBonusAd(ad, campaign) && (
                        <div>
                            <div className="ef5-text-size_hint">{templateData.targetLabel}</div>
                            <div className="media-plan-form__overview-table-goal-number">
                                {templateData.targetValue}
                            </div>
                        </div>
                    )}
                    {ad.ef_billing_terms === 'cpmcpc' && (
                        <React.Fragment>
                            <div className="ef5-text-size_hint">{templateData.bonusLabel}</div>
                            <div className="media-plan-form__overview-table-goal-number">
                                {templateData.bonusValue}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            );
        } else {
            switch (ad.primary_pacing) {
                case 'clicks':
                    summary = <div>{formatNumber_whole(ad.non_billable_volume)}</div>;
                    break;
                case 'impressions':
                    summary = <div>{formatNumber_whole(ad.non_billable_volume)}</div>;
                    break;
                case 'spend':
                    summary = <div>{formatNumber_currency(ad.non_billable_volume)}</div>;
                    break;
            }
        }

        return <div>{error ? <div>{error}</div> : <div>{summary}</div>}</div>;
    }
}

import _ from 'lodash';

const initialState = {
    isDeleteDealsModalOpen: false,
    dealId: '',
    dealName: '',
    isDeleting: false,
};

export const NAME = 'deleteDealModal';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'DELETE_DEAL_MODAL__OPEN': {
            return {
                ...state,
                isDeleteDealsModalOpen: true,
                dealId: action.payload.dealId,
                dealName: action.payload.appName,
            };
        }

        case 'DELETE_DEAL_MODAL__CLOSE': {
            return { ...state, isDeleteDealsModalOpen: false };
        }

        case 'DELETE_DEAL_MODAL__DELETE_START': {
            return { ...state, isDeleting: true };
        }

        case 'DELETE_DEAL_MODAL__DELETE_SUCCESS': {
            return { ...state, isDeleteDealsModalOpen: false, isDeleting: false };
        }

        case 'DELETE_DEAL_MODAL__DELETE_ERROR': {
            return { ...state, isDeleting: false };
        }

        default: {
            return state;
        }
    }
}

import each from 'lodash/each';

export const PaceMapping = {
    Override: 'override',
    Default: 'default',
};

export const PACING_STRATEGY_OPTIONS = [
    { value: 'override', label: 'Override' },
    { value: 'default', label: 'Auto' },
];

const MAPPING = {};
each(PACING_STRATEGY_OPTIONS, pacing => (MAPPING[pacing.value] = pacing));

export const PACING_STRATEGY_MAPPING = MAPPING;

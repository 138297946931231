import _ from 'lodash';
import React, { useState } from 'react';

/*
    //  usage
    import numeral from "numeral";

    export default function App() {
      const [text, setText] = useState(1000);

      return (
          <TextFieldFormatter
            initialValue={text}
            onChange={v => setText(v)}
            shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
            formatIn={v => numeral(v).format("$0,0.00")}
            formatOut={v => numeral(v).value()}
            renderInput={({ value, onChange, onBlur }) => (
              <input
                type="text"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
      );
    }
*/
function TextFieldFormatter(props) {
    const { initialValue, onChange, shouldAllowChange, formatIn, formatOut, renderInput } = props;

    const [text, setText] = useState(formatIn(initialValue));

    function handleChange(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        const output = _.get(e, 'target.value', e);
        if (!shouldAllowChange(output)) {
            return;
        }
        onChange(formatOut(output));
        setText(output);
    }

    function handleBlur() {
        setText(formatIn(formatOut(text)));
    }

    return renderInput({
        onChange: handleChange,
        onBlur: handleBlur,
        value: text,
    });
}

TextFieldFormatter.defaultProps = {
    initialValue: '',
    onChange: () => {},
    onChangeDebounceDelay: 0,
    shouldAllowChange: () => true,
    formatIn: value => value,
    formatOut: value => value,
    renderInput: () => null,
};

export default TextFieldFormatter;

import React from 'react';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';

import dictTimezone from 'common/constants/timezones';
import FormField from 'widgets-v5/form-field';
import { SimpleSelect } from 'widgets-v6/select';
import { TextField, MuiFormLabel } from 'widgets-v6/standard-input';
import PrefixSuffixInput from 'widgets-v6/prefix-suffix-input';
import Spacer from 'widgets-v5/spacer';

import { getEnvironmentSettings } from 'services/environment';

import selector from './selector';

import { ApplistSelector } from '../../../ad-form/modules/target-applists';

const REVENUE_MODEL_OPTIONS = [
    { label: 'CPM / CPC', value: 'cpmcpc' },
    { label: 'Agency margin', value: 'agencyMargin' },
    // { label: 'Total cost markup', value: 'totalSpendMarkup' },
    { label: 'Disabled', value: 'disabled' },
];

const getTimezoneOptions = () => {
    const environmentSettings = getEnvironmentSettings();
    const countryCodes = environmentSettings.getTimezoneCountryCodes();

    const validTimezoneEntries = _.pickBy(dictTimezone, (value, countryCode) => {
        return _.includes(countryCodes, countryCode);
    });

    const timezones = _(validTimezoneEntries)
        .map(v => v)
        .flatten()
        .map(v => ({
            label: `${v[1]}${v[3] ? ', ' + v[3] : ''} - ${v[2]}`,
            value: `${v[0]}`,
        }))
        .value();

    return timezones;
};

const BasicInformation = ({ draft, onChange, errors, showErrors }) => {
    const environmentSettings = getEnvironmentSettings();
    const showRestrictedFields = draft.type === 'media_buyer' || draft.type === 'admin';
    const showAdvertiserSettings = draft.client_type === 'advertiser';

    const timezoneOptions = getTimezoneOptions();
    const currencyOptions = environmentSettings.getCurrencyOptions();

    return (
        <div>
            <Grid container direction="column" spacing={1}>
                <Grid item xs={7}>
                    <TextField
                        value={draft.name}
                        onChange={e => onChange('name', e.target.value)}
                        required
                        label="Name"
                        fullWidth
                        helperText={showErrors && errors.name}
                        error={showErrors && errors.name}
                        disableMargin
                    />
                </Grid>
                <Grid item xs={7}>
                    <SimpleSelect
                        required
                        label="Default Timezone"
                        options={timezoneOptions}
                        value={draft.default_timezone}
                        onChange={e => onChange('default_timezone', e.target.value)}
                        margin="normal"
                        helperText={showErrors && errors.default_timezone}
                        error={showErrors && errors.default_timezone}
                    />
                </Grid>
                {showAdvertiserSettings && (
                    <Grid item xs={7}>
                        <FormField
                            errors={errors.revenueModel}
                            showErrors={showErrors}
                            isRequired={true}
                        >
                            <SimpleSelect
                                required
                                label="Default Revenue Model"
                                options={REVENUE_MODEL_OPTIONS}
                                value={draft.revenueModel}
                                onChange={e => onChange('revenueModel', e.target.value)}
                                margin="normal"
                                helperText={showErrors && errors.revenueModel}
                                error={showErrors && errors.revenueModel}
                            />
                        </FormField>
                    </Grid>
                )}
                {showAdvertiserSettings && draft.revenueModel === 'agencyMargin' && (
                    <Grid item xs={12}>
                        <Spacer />
                        <MuiFormLabel component="legend">Default Agency Margin</MuiFormLabel>
                        <FormField
                            errors={errors.revenueModel}
                            showErrors={showErrors}
                            isRequired={true}
                        >
                            {draft.revenueModel === 'agencyMargin' && (
                                <PrefixSuffixInput
                                    label="Field Name"
                                    disabled={false}
                                    placeholder=""
                                    suffix={'%'}
                                    formatIn={formatMarginValueToString}
                                    formatOut={formatMarginStringToValue}
                                    shouldAllowInput={shouldAllowInputForBillingRate}
                                    onChange={e => onChange('agencyMarginRate', e)}
                                    value={draft.agencyMarginRate}
                                />
                            )}
                        </FormField>
                    </Grid>
                )}
                {showRestrictedFields && (
                    <React.Fragment>
                        <Grid item xs={7}>
                            <FormField
                                errors={errors.currency}
                                showErrors={showErrors}
                                isRequired={true}
                            >
                                <SimpleSelect
                                    required
                                    label="Default Currency"
                                    options={currencyOptions}
                                    value={draft.currency}
                                    onChange={e => onChange('currency', e.target.value)}
                                    margin="normal"
                                    helperText={showErrors && errors.currency}
                                    error={showErrors && errors.currency}
                                />
                            </FormField>
                        </Grid>
                        <Grid item xs={7}>
                            <FormField
                                errors={errors.revenueModel}
                                showErrors={showErrors}
                                isRequired={true}
                            >
                                <SimpleSelect
                                    required
                                    label="Default Revenue Model"
                                    options={REVENUE_MODEL_OPTIONS}
                                    value={draft.revenueModel}
                                    onChange={e => onChange('revenueModel', e.target.value)}
                                    margin="normal"
                                    helperText={showErrors && errors.revenueModel}
                                    error={showErrors && errors.revenueModel}
                                />
                            </FormField>
                        </Grid>
                        {draft.revenueModel === 'agencyMargin' && (
                            <Grid item xs={12}>
                                <Spacer />
                                <MuiFormLabel component="legend">
                                    Default Agency Margin
                                </MuiFormLabel>
                                <FormField
                                    errors={errors.revenueModel}
                                    showErrors={showErrors}
                                    isRequired={true}
                                >
                                    {draft.revenueModel === 'agencyMargin' && (
                                        <PrefixSuffixInput
                                            label="Field Name"
                                            disabled={false}
                                            placeholder=""
                                            suffix={'%'}
                                            formatIn={formatMarginValueToString}
                                            formatOut={formatMarginStringToValue}
                                            shouldAllowInput={shouldAllowInputForBillingRate}
                                            onChange={e => onChange('agencyMarginRate', e)}
                                            value={draft.agencyMarginRate}
                                        />
                                    )}
                                </FormField>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                <Spacer />
                {showAdvertiserSettings && (
                    <MuiFormLabel component="legend">Default Apps & Sites</MuiFormLabel>
                )}
                {showAdvertiserSettings && (
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={7}>
                            <ApplistSelector
                                type="Include"
                                label="Allowed"
                                placeholder={'Default Whitelist'}
                                onChange={values => onChange('whitelistedAppsAndSites', values)}
                                values={draft.whitelistedAppsAndSites}
                                options={_(draft.applists)
                                    .filter(
                                        applist =>
                                            !_.includes(draft.blacklistedAppsAndSites, applist.id)
                                    )
                                    .map(applist => ({
                                        value: applist.id,
                                        label: applist.name,
                                    }))
                                    .value()}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <ApplistSelector
                                type="Exclude"
                                label="Blocked"
                                placeholder="Default Blacklist"
                                onChange={values => onChange('blacklistedAppsAndSites', values)}
                                values={draft.blacklistedAppsAndSites}
                                options={_(draft.applists)
                                    .filter(
                                        applist =>
                                            !_.includes(draft.whitelistedAppsAndSites, applist.id)
                                    )
                                    .map(applist => ({
                                        value: applist.id,
                                        label: applist.name,
                                    }))
                                    .value()}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

function shouldAllowInputForBillingRate(input) {
    // Allow empty input
    if (!input) {
        return true;
    }

    let parsedInput = parseInt(input);

    // Check allowable characters
    if (Number.isNaN(parsedInput)) {
        return false;
    }

    // Check character length
    return parsedInput < 100;
}

function formatMarginValueToString(value) {
    return value && value >= 0 ? `${(value * 100).toFixed(2)}` : '';
}

function formatMarginStringToValue(valueAsString) {
    return valueAsString === '' ? 0 : parseFloat(valueAsString) / 100;
}

export default connect(selector)(BasicInformation);

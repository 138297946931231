import React, { useEffect, useState } from 'react';

import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

export default function DropdownWithMultipleSelect(props) {
    const [options, setOptions] = useState(props.selectedFields);

    useEffect(() => {
        setOptions(props.selectedFields);
    }, [props.selectedFields]);

    const handleChange = value => () => {
        const newOptions = options.includes(value)
            ? options.filter(option => option !== value)
            : [...options, value];
        setOptions(newOptions);

        const { onChange } = props;
        onChange(newOptions);
    };
    const { optionsToSelect } = props;

    return (
        <div>
            <Typography>Avaliable Fields</Typography>
            <List>
                {optionsToSelect.map(({ value, label }) => (
                    <ListItem key={value} role={undefined} button onClick={handleChange(value)}>
                        <Checkbox color="primary" checked={options.indexOf(value) > -1} />
                        <ListItemText id={value} primary={label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

import _ from 'lodash';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

import { IAS_PUBLICIS_VIEWABILITY_OPTIONS } from 'common/constants/ias-publicis-viewability';

import Box from '@mui/material/Box';
import { getIASPublicisGroupMWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

const useStyles = makeStyles({
    form: {
        margin: '10px 0px',
    },
    label: {
        fontSize: 18,
    },
});

const IASViewability = props => {
    const classes = useStyles();
    const { draft, updateDraft, ad, ads } = props;

    const handlePublicisChange = e => {
        const { value } = e.target;
        const changes = {
            ...draft.ias,
            publicisViewability: {
                ...draft.ias.publicisViewability,
                value,
            },
        };
        updateDraft({ ias: changes });
    };
    let disabled;
    let hasInappPlatform;

    const publicisValue = _.get(draft, 'ias.publicisViewability.value');

    const adPlatforms = ad
        ? _.get(ad, 'platforms')
        : _(ads)
              .map(ad => ad.platforms)
              .flatten()
              .uniq()
              .value();

    if (props.isCrossPlatformCampaign) {
        disabled = false;
        hasInappPlatform = _.includes(adPlatforms, 'inapp');
    } else {
        if (ad) {
            const platform = _.get(ad, 'platform');
            disabled = platform === 'inapp';
            hasInappPlatform = platform === 'inapp';
        } else {
            const platforms = _.map(ads, ad => ad.platform);
            disabled = _.includes(platforms, 'inapp');
            hasInappPlatform = _.includes(platforms, 'inapp');
        }
    }

    const helpMessage =
        disabled || hasInappPlatform
            ? 'Supports desktop, mobile web platforms and video formats only'
            : null;

    let publicisWarning = {};

    if (props.isCrossPlatformCampaign) {
        publicisWarning = getIASPublicisGroupMWarning({
            platforms: adPlatforms,
            viewabilityValue: publicisValue,
            field: 'publicis',
        });
    }

    return (
        <div>
            <FormControl className={classes.form}>
                <FormLabel component="legend">Publicis Performance Matching</FormLabel>
                <Select value={publicisValue} onChange={handlePublicisChange} disabled={disabled}>
                    {_.map(IAS_PUBLICIS_VIEWABILITY_OPTIONS, option => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {helpMessage && <FormHelperText>{helpMessage}</FormHelperText>}
                {props.isCrossPlatformCampaign && publicisWarning.hasWarning && (
                    <Box marginTop={1}>
                        <WarningText
                            message={publicisWarning.message}
                            title={publicisWarning.title}
                            severity={publicisWarning.severity}
                        />
                    </Box>
                )}
            </FormControl>
        </div>
    );
};

export default IASViewability;

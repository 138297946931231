import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
        alignItems: 'center',
    },
    closeButton: {
        right: '-5px',
        color: theme.palette.grey[500],
        height: 'fit-content',
    },
});

export const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, textVariant = 'h6', ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant={textVariant}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export function ConfirmDialog(props) {
    return (
        <Dialog open={!!props.isOpen} onClose={props.onCancel}>
            <DialogTitle onClose={props.onCancel}>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={function() {
                        props.onConfirm(...arguments);
                    }}
                    disabled={props.isConfirmDisabled}
                >
                    {props.onConfirmText ? props.onConfirmText : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function TemporaryDrawer({ side, open, onClose, classes, children }) {
    return (
        <Drawer anchor={side} open={open} onClose={onClose} classes={classes}>
            {children}
        </Drawer>
    );
}

import { DEFAULT_RENDER_DELAY } from '../constants';
export default {
    defaultValues: {
        // The initial draft is shared by all creative types, with additional fields added
        // after the format has been set by the user
        initial: {
            name: '',
            size: '',
            format: '',
            platforms: ['inapp'],
            type: '',
            exporter: '',
            creative_vendors: [],
            tracking_vendors: [],
            pending_variants: false,
        },
        standard: {
            format: 'standard',
            image_asset: '',
            image_url: '',
            image_preview_url: '',
            third_party_pixels: [],
            third_party_javascript_urls: [],
            third_party_clickthrough: false,
            clickthrough_url: '',
            landing_page: '',
            third_party_vendors: [],
            assetSelectionMethod: 'selectFromAssets',
            isCreativeVendorAutoDetected: true,
            isTrackingVendorsAutoDetected: true,
            creative_vendors: [],
            tracking_vendors: [],
            creativeVendorsOptions: [],
            trackingVendorsOptions: [],
        },
        video: {
            format: 'video',
            video_asset: '',
            video_url: '',
            video_preview_url: '',
            video_duration: '', // API expecting num type, string type is here never send (guarded by validation)
            video_bit_rate_kbps: '', // API expecting num type, string type is here never send (guarded by validation)
            companion_asset: '',
            companion_url: '',
            companion_preview_url: '',
            third_party_quartile_pixels: [],
            third_party_pixels: [],
            third_party_javascript_urls: [],
            third_party_clickthrough: false,
            image_preview_url: '',
            clickthrough_url: '',
            landing_page: '',
            third_party_vendors: [],
            // audit_status ?
            placements: [],
            positions: [],
            assetSelectionMethod: 'selectFromAssets',
            isCreativeVendorAutoDetected: true,
            isTrackingVendorsAutoDetected: true,
            creative_vendors: [],
            tracking_vendors: [],
            creativeVendorsOptions: [],
            trackingVendorsOptions: [],
            audio_required: null,
        },
        third_party_vast: {
            format: 'third_party_vast',
            capabilities: { contains_vpaid: false },
            hosted_vast_url: '',
            reference_screenshot: '',
            video_duration: '', // API expecting num type, string type is here never send (guarded by validation)
            video_bit_rate_kbps: '', // API expecting num type, string type is here never send (guarded by validation)
            third_party_quartile_pixels: [],
            third_party_pixels: [],
            third_party_javascript_urls: [],
            landing_page: '',
            third_party_vendors: [],
            // audit_status ?
            placements: [],
            positions: [],
            assetSelectionMethod: 'selectFromAssets',
            isCreativeVendorAutoDetected: true,
            isTrackingVendorsAutoDetected: true,
            creative_vendors: [],
            tracking_vendors: [],
            creativeVendorsOptions: [],
            trackingVendorsOptions: [],
        },
        mraid: {
            format: 'mraid',
            reference_image_asset: '',
            reference_image_preview_url: '',
            reference_image_url: '',
            content_html: '',
            third_party_clickthrough: false,
            clickthrough_url: '',
            landing_page: '',
            third_party_vendors: [],
            third_party_pixels: [],
            third_party_javascript_urls: [],
            renderDelay: DEFAULT_RENDER_DELAY,
            assetSelectionMethod: 'autoGeneratedScreenshot',
            isCreativeVendorAutoDetected: true,
            isTrackingVendorsAutoDetected: true,
            creative_vendors: [],
            tracking_vendors: [],
            creativeVendorsOptions: [],
            trackingVendorsOptions: [],
        },
        custom_html: {
            format: 'custom_html',
            reference_image_asset: '',
            reference_image_preview_url: '',
            reference_image_url: '',
            content_html: '',
            third_party_clickthrough: false,
            clickthrough_url: '',
            landing_page: '',
            third_party_vendors: [],
            third_party_pixels: [],
            third_party_javascript_urls: [],
            renderDelay: DEFAULT_RENDER_DELAY,
            assetSelectionMethod: 'autoGeneratedScreenshot',
            isCreativeVendorAutoDetected: true,
            isTrackingVendorsAutoDetected: true,
            creative_vendors: [],
            tracking_vendors: [],
            creativeVendorsOptions: [],
            trackingVendorsOptions: [],
        },
        native: {
            format: 'native',
            icon_image_asset: '',
            icon_image_url: '',
            icon_image_preview_url: '',
            main_image_asset: '',
            main_image_url: '',
            main_image_preview_url: '',
            title: '',
            text: '',
            sponsoredBy: '',
            call_to_action: 'Visit',
            star_rating: null,
            third_party_clickthrough: false,
            third_party_pixels: [],
            third_party_javascript_urls: [],
            clickthrough_url: '',
            landing_page: '',
            third_party_vendors: [],
            assetSelectionMethod: 'selectFromAssets',
            isCreativeVendorAutoDetected: true,
            isTrackingVendorsAutoDetected: true,
            creative_vendors: [],
            tracking_vendors: [],
            creativeVendorsOptions: [],
            trackingVendorsOptions: [],
        },
    },
    // Value not present in creative form's draft
    meta: {
        audit_status: 'no_audit',
    },
    // item use in group validation but not in the form darft
    groupValidate: {
        demo_groupValidate: '',
    },
};

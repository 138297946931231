import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router';
import Tooltip from '@mui/material/Tooltip';
import PinDrop from '@mui/icons-material/PinDrop';
import Map from '@mui/icons-material/Map';
import HomeWork from '@mui/icons-material/HomeWork';
import CreditCard from '@mui/icons-material/CreditCard';
import { InfoBannerSimple } from 'widgets-v5/banners';
import { getEnvironmentSettings } from 'services/environment';
import Icon from '@mdi/react';
import {
    mdiHumanMaleFemaleChild,
    mdiDatabase,
    mdiMapSearch,
    mdiBullseye,
    mdiFileUpload,
    mdiGestureTapButton,
    mdiLeafMaple,
} from '@mdi/js';

import { includes } from 'lodash';

import OverallTableDashboard from '../../table-dashboard';
import { useOverallTableDashboardData } from './hook';
import { TORSTAR_ID } from '../constants';

export function getAvailableCategories(classes) {
    const excludedAudiences = getEnvironmentSettings().excludedAudiences();

    const availableCategories = [
        {
            type: 'Pelmorex Audiences',
            value: 'pelmorex',
            description:
                'These are audience categories that Pelmorex compiled so that you don’t have to do the work. Feel free to explore each category to find your target audience.',
            categories: [
                {
                    title: 'Precise Geolocation',
                    link: '/audiences/brand',
                    source: ['pds_brand', 'pds_standard', 'pds_behaviour'],
                    icon: <PinDrop className={classes.icon} />,
                    tooltip:
                        'Segments created using first-party SDK location data, points of interest database from Cleanlist, and hand-drawn building footprints -- for the highest precision and accuracy.',
                },
                {
                    title: 'Flex Geolocation',
                    link: '/audiences/flex-brand',
                    source: 'flex_standard_brand',
                    icon: <Map className={classes.icon} />,
                    tooltip:
                        'Segments combining bidstream data and virtual beacons — composed of location radiuses and points of interest from Cleanlist.',
                },
                {
                    title: 'Online Behavioural',
                    link: '/audiences/intent',
                    source: 'pds_intent',
                    icon: <Icon path={mdiGestureTapButton} size={3} />,
                    tooltip:
                        'Target users based on common keywords and URLs found in content that people browse while researching a given product or service.',
                },
                {
                    title: 'CanaCode',
                    link: '/audiences/cana-code',
                    source: 'pds_manifold',
                    icon: <Icon path={mdiLeafMaple} size={3} />,
                    tooltip:
                        "CanaCode classifies Canada's consumer landscape into 18 distinct lifestyle segments based on consumer indicators such as socio-demographics, behaviour, spending, etc.",
                },
                {
                    title: 'Environics',
                    link: '/audiences/environics',
                    source: 'pds_environics',
                    icon: <HomeWork className={classes.icon} />,
                    tooltip:
                        'Environics PRIZM5 categorizes Canadian neighbourhoods into one of 68 distinct lifestyle segments based on the characteristics of households.',
                },
                {
                    title: 'Mastercard',
                    link: '/audiences/mastercard',
                    source: 'pds_mastercard',
                    icon: <CreditCard className={classes.icon} />,
                    tooltip:
                        'Target users based on aggregate consumer spending behaviour. Reach more of the likeliest purchasers, with less waste in your online spend.',
                },
                {
                    title: 'Declared Age',
                    link: '/audiences/age',
                    source: 'pds_age',
                    icon: <Icon path={mdiHumanMaleFemaleChild} size={3} />,
                    tooltip:
                        'Users segmented by age groups, based on self-declared and survey information within The Weather Network apps.',
                },
            ],
        },
        {
            type: 'Custom Audiences',
            value: 'custom',
            description:
                'Create custom audiences that are fine-tuned for your campaigns, by mixing and matching our Pelmorex Audience data or by leveraging your own first-party data.',
            sections: [
                {
                    title: 'Pelmorex Data',
                    categories: [
                        {
                            title: 'Pelmorex Data',
                            link: '/audiences/pds-custom',
                            source: 'pds_custom',
                            tooltip:
                                "Pelmorex Data is a group of Geolocation segments, based on first-party SDK location data and precise polygons, and tailor-made for your campaigns. Plan and create audience segments through our Pelmorex Data's Audience Builder.",
                            icon: <Icon path={mdiDatabase} size={3} />,
                        },
                    ],
                },
                {
                    title: 'Other Custom Audience categories',
                    categories: [
                        {
                            title: 'Flex Geolocation',
                            link: '/audiences/flex-custom',
                            source: 'flex_custom_brand',
                            icon: <Icon path={mdiMapSearch} size={3} />,
                            tooltip:
                                "Create your own Flex Geolocation segments by searching through Cleanlist's points of interest database, or by uploading your own store list.",
                        },
                        {
                            title: 'Retargeting',
                            link: '/audiences/retargeting',
                            source: ['ad', 'campaign'],
                            icon: <Icon path={mdiBullseye} size={3} />,
                            tooltip:
                                'Target users who have seen or interacted with an earlier ad, or performed an action that fired a conversion pixel.',
                        },
                        {
                            title: 'Uploaded',
                            link: '/audiences/uploaded',
                            source: 'upload',
                            icon: <Icon path={mdiFileUpload} size={3} />,
                            tooltip:
                                'Upload your own segments from any source like third-party DMPs and CRM platforms, based on mobile Device IDs.',
                        },
                    ],
                },
            ],
        },
        {
            type: 'DMP integrations',
            value: 'dmp',
            description:
                'Audiences pushed from third-party DMPs will appear here. Contact us for more information.',
            categories: [
                {
                    title: 'Adobe',
                    link: '/audiences/adobe',
                    source: 'adobe',
                    img: '/images/AdobeAudienceManagerImage.png',
                    tooltip: 'Segments pushed from Adobe Audience Manager will appear here.',
                    icon: (
                        <img
                            className={classes.image}
                            src="/images/AdobeAudienceManagerImage.png"
                        />
                    ),
                },
                {
                    title: 'Permutive',
                    link: '/audiences/permutive',
                    source: 'permutive',
                    img: '/images/PermutiveImage.png',
                    tooltip: 'Segments pushed from Permutive will appear here.',
                    icon: <img className={classes.image} src="/images/PermutiveImage.png" />,
                    showOn: orgId => orgId === TORSTAR_ID,
                },
            ],
        },
    ];
    return availableCategories.map(availableCategory => {
        const excludedAudiencesList = excludedAudiences[availableCategory.value];
        if (undefined === excludedAudiencesList) {
            return availableCategory;
        }
        availableCategory.categories = _.filter(availableCategory.categories, category => {
            if (_.includes(excludedAudiencesList, category.source)) {
                return false;
            }
            return true;
        });
        return availableCategory;
    });
}

const tabs = [
    {
        label: 'All Audiences',
        value: 'all',
    },
    {
        label: 'Pelmorex Audiences',
        value: 'pelmorex',
    },
    {
        label: 'Your Custom Audiences',
        value: 'custom',
    },
    {
        label: 'DMP Integrations',
        value: 'dmp',
    },
];

const useStyles = makeStyles({
    sectionTab: {
        fontSize: '14px',
        textTransform: 'uppercase',
    },
    audienceCardItem: {
        flexShrink: 0,
    },
    image: {
        width: '70%',
    },
    overlay: {
        visibility: 'hidden',
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: '0.95',
        backgroundColor: '#000',
        color: '#fff',
        padding: '20px 15px 0 15px',
    },
    root: {
        '&:hover > div': {
            visibility: 'visible',
        },
        position: 'relative',
    },
    icon: {
        width: '3.5em',
        height: '3.5em',
    },
    iconColor: {
        color: '#098679',
    },
});

const OverallDashboardV2 = () => {
    const [currentTab, setCurrentTab] = useState('all');
    const { restrictedSegments, orgId } = useOverallTableDashboardData();

    const classes = useStyles();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const audienceTab = urlParams.get('audienceTab');
        if (audienceTab && audienceTab !== currentTab) {
            setCurrentTab(audienceTab);
            browserHistory.push('/audiences/all');
        }
    }, []);

    const onChangeTab = (event, value) => {
        setCurrentTab(value);
    };

    const audienceCategories = getAvailableCategories(classes);

    const selectedType =
        currentTab !== 'all'
            ? audienceCategories.find(category => category.value === currentTab)
            : null;

    const getTabTooltip = tabValue => {
        const tab = audienceCategories.find(category => category.value === tabValue);
        return tab
            ? tab.description
            : 'Gain insight on your target audience by browsing through our directory of audiences.';
    };

    return (
        <Container maxWidth="xl">
            <InfoBannerSimple content="Search for audiences across all categories more easily with the new “All Audiences” tab. You can still explore individual audience categories, under “Pelmorex Audiences”, “Your Custom Audiences” and “DMP Integrations”" />
            <Box mt={2}>
                <Typography variant="h3" gutterBottom>
                    Audiences
                </Typography>
            </Box>
            <Tabs
                value={currentTab}
                onChange={onChangeTab}
                indicatorColor="primary"
                textColor="primary"
            >
                {_.map(tabs, tab => (
                    <Tab
                        className={classes.sectionTab}
                        key={tab.value}
                        value={tab.value}
                        label={
                            <Tooltip
                                title={
                                    <Typography variant="body2">
                                        {getTabTooltip(tab.value)}
                                    </Typography>
                                }
                            >
                                <span>{tab.label}</span>
                            </Tooltip>
                        }
                    />
                ))}
            </Tabs>
            <div>
                {currentTab === 'all' ? (
                    <Box mb={3}>
                        <OverallTableDashboard />
                    </Box>
                ) : null}
                {selectedType ? (
                    <Box mb={3} key={selectedType.type}>
                        <Box display="flex" flexDirection="column" mb={4} mt={4}>
                            <Typography color="textPrimary" variant="h4" gutterBottom>
                                {selectedType.type}
                            </Typography>
                            <Typography color="textSecondary" variant="h6" gutterBottom>
                                {selectedType.description}
                            </Typography>
                        </Box>
                        {selectedType.categories && (
                            <CardGrid
                                categories={selectedType.categories.filter(
                                    category =>
                                        !includes(restrictedSegments, category.source) &&
                                        (!category.showOn || category.showOn(orgId))
                                )}
                            />
                        )}
                        {selectedType.sections &&
                            selectedType.sections.map(section => {
                                return (
                                    <React.Fragment key={section.title}>
                                        <Box display="flex" flexDirection="column" mb={2} mt={4}>
                                            <Typography
                                                color="textPrimary"
                                                variant="h6"
                                                gutterBottom
                                            >
                                                {section.title}
                                            </Typography>
                                        </Box>
                                        {section.categories && (
                                            <CardGrid
                                                categories={section.categories.filter(
                                                    categoty =>
                                                        !includes(
                                                            restrictedSegments,
                                                            categoty.source
                                                        )
                                                )}
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </Box>
                ) : null}
            </div>
        </Container>
    );
};

const CardGrid = ({ categories }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            {_.map(categories, category => (
                <Grid item xs={3} key={category.title}>
                    <RouterLink
                        color="inherit"
                        component={Link}
                        to={category.link}
                        underline="none"
                    >
                        <Card classes={{ root: classes.root }}>
                            {category.tooltip && (
                                <Box className={classes.overlay}>
                                    <Typography variant="h5" gutterBottom>
                                        {category.title}
                                    </Typography>
                                    {category.tooltip}
                                </Box>
                            )}
                            <CardContent>
                                <Box textAlign="center">
                                    <Box justifyContent="center" display="flex" fontWeight="bold">
                                        <Typography variant="h5" color="textPrimary" gutterBottom>
                                            {category.title}
                                        </Typography>
                                    </Box>
                                    <Box
                                        height="100"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        mb={1}
                                        className={classes.iconColor}
                                    >
                                        {category.icon}
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </RouterLink>
                </Grid>
            ))}
        </Grid>
    );
};

export default OverallDashboardV2;

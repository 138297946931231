import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import withStyles from '@mui/styles/withStyles';

import FormLabel from '@mui/material/FormLabel';
import { NeutralButton } from 'widgets-v5/buttons';
import { TextFieldInput, PreFixSuffixInputInStrOutNum } from 'widgets-v6/standard-input';

import { getEnvironmentSettings } from 'services/environment';

const styles = () => ({
    red: {
        color: 'red',
    },
});

class ThirdPartyFees extends React.Component {
    static propTypes = {
        currency: PropTypes.string.isRequired,
        value: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        showErrors: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        showErrors: false,
        errors: {},
    };

    addNewFeeItem = () => {
        const newFeeItem = {
            description: undefined,
            billing_model: 'cpm',
            fee: undefined,
            billable: true,
            disabled: false,
        };

        const thirdPartyFees = [].concat(this.props.value, [newFeeItem]);

        this.props.onChange(thirdPartyFees);
    };

    removeFeeItemAtIndex = index => {
        const thirdPartyFees = _.filter(this.props.value, (feeItem, i) => {
            if (index === i) {
                return false;
            }
            return true;
        });

        this.props.onChange(thirdPartyFees);
    };

    updateFeeItemAtIndex = (index, field) => {
        return newFieldValue => {
            const thirdPartyFees = _.map(this.props.value, (fee, i) => {
                if (index === i) {
                    return {
                        ...fee,
                        [field]: newFieldValue,
                    };
                }

                return fee;
            });

            this.props.onChange(thirdPartyFees);
        };
    };

    render() {
        const environmentSettings = getEnvironmentSettings();
        const thirdPartyFees = this.props.value;
        const currency = this.props.currency;
        return (
            <div className="ef3-third-party-fees">
                <div className="ef3-third-party-fees__header">
                    <div className="ef3-third-party-fees__header-button">
                        <NeutralButton
                            icon={<i className="fa fa-plus" />}
                            text="Add"
                            onClick={this.addNewFeeItem}
                        />
                    </div>
                </div>
                <div>
                    {thirdPartyFees.length > 0 && (
                        <div className="ef3-third-party-fees__fee-item-header">
                            <div className="ef3-third-party-fees__fee-item-description">
                                <FormLabel
                                    required
                                    classes={{
                                        asterisk: this.props.classes.red,
                                    }}
                                >
                                    Description
                                </FormLabel>
                            </div>
                            <div className="ef3-third-party-fees__fee-item-fee">
                                <FormLabel
                                    required
                                    classes={{
                                        asterisk: this.props.classes.red,
                                    }}
                                >
                                    Fee{' '}
                                    {`${
                                        environmentSettings.showCurrencyLabel()
                                            ? `(${currency})`
                                            : ''
                                    }`}
                                </FormLabel>
                            </div>
                            <div className="ef3-third-party-fees__fee-item-button-action" />
                        </div>
                    )}
                    {_.map(thirdPartyFees, (feeItem, index) => {
                        return (
                            <div key={index} className="ef3-third-party-fees__fee-item">
                                <div className="ef3-third-party-fees__fee-item-description">
                                    <TextFieldInput
                                        placeholder="Description"
                                        disableMargin
                                        autoFocus={true}
                                        error={
                                            this.props.errors[
                                                `thirdPartyFees.${index}.description`
                                            ] && this.props.showErrors
                                        }
                                        value={feeItem.description}
                                        onChange={this.updateFeeItemAtIndex(index, 'description')}
                                        fullWidth
                                    />
                                </div>
                                <div className="ef3-third-party-fees__fee-item-fee">
                                    <PreFixSuffixInputInStrOutNum
                                        prefix="$"
                                        suffix="CPM"
                                        formatIn={formatFeeValueToString}
                                        formatOut={formatFeeStringToValue}
                                        onChange={this.updateFeeItemAtIndex(index, 'fee')}
                                        value={feeItem.fee}
                                        disabled={feeItem.disabled}
                                        isNaNValue={'0.00'}
                                    />
                                </div>
                                {!feeItem.disabled && (
                                    <NeutralButton
                                        text="Remove"
                                        onClick={() => this.removeFeeItemAtIndex(index)}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function formatFeeValueToString(value) {
    if (!value && !_.isNumber(value)) {
        return '';
    } else {
        const [dollars, cents] = value.toFixed(2).split('.');
        const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
        return `${formattedDollars}.${cents}`;
    }
}

function formatFeeStringToValue(string) {
    if (string === '') {
        return undefined;
    } else {
        return parseFloat(string.replace(/,/g, ''));
    }
}

export default withStyles(styles)(ThirdPartyFees);

import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'overviewDashboardv2';

export const initialState = {
    isLoading: false,
    etag: null,
    endingSoon: [],
    upcoming: [],
    recentlyEnded: [],
    campaignsEndingSoon: [],
    campaignsUpcoming: [],
    campaignsEnded: [],
    alerts: [],
    hiddenColumns: {
        live: [],
        ended: [],
        upcoming: [],
    },
    sortBy: {
        live: [],
        ended: [],
        upcoming: [],
    },
    assigneeFilter: {
        live: [],
        ended: [],
        upcoming: [],
    },
    organizationFilter: {
        live: [],
        ended: [],
        upcoming: [],
    },
    ownOrganization: {},
    alertsSidebar: {
        alerts: {},
        isLoading: false,
        error: null,
    },
    selfServeOrgList: [],
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        init: state => ({
            ...state,
            isLoading: true,
        }),
        initSuccess: (state, payload) => ({
            ...state,
            endingSoon: payload.endingSoon,
            upcoming: payload.upcoming,
            recentlyEnded: payload.recentlyEnded,
            campaignsEndingSoon: payload.campaignsEndingSoon,
            campaignsUpcoming: payload.campaignsUpcoming,
            campaignsEnded: payload.campaignsEnded,
            alerts: payload.alerts,
            ownOrganization: payload.ownOrganization,
            isLoading: false,
        }),
        initError: state => ({
            ...state,
            isLoading: false,
        }),
        fetchUserPreference: state => state,
        fetchUserPreferenceSuccess: (state, payload) => ({
            ...state,
            hiddenColumns: payload.hiddenColumns,
            sortBy: payload.sortBy,
            assigneeFilter: payload.assigneeFilter || {},
            organizationFilter: payload.organizationFilter || {},
            etag: payload.etag,
        }),
        updateHiddenColumns: (state, payload) => ({
            ...state,
            hiddenColumns: {
                ...state.hiddenColumns,
                [payload.section]: payload.hiddenColumns,
            },
        }),
        updateHiddenColumnsSuccess: (state, payload) => ({
            ...state,
            hiddenColumns: payload.hiddenColumns,
        }),
        updateSortBy: (state, payload) => ({
            ...state,
            sortBy: {
                ...state.sortBy,
                [payload.section]: payload.sortBy,
            },
        }),
        updateSortBySuccess: (state, payload) => ({
            ...state,
            sortBy: payload.sortBy,
        }),
        updateAssigneeFilter: (state, payload) => ({
            ...state,
            assigneeFilter: {
                ...state.assigneeFilter,
                [payload.section]: payload.assigneeFilter,
            },
        }),
        updateOrganizationFilter: (state, payload) => ({
            ...state,
            organizationFilter: {
                ...state.organizationFilter,
                [payload.section]: payload.organizationFilter,
            },
        }),
        fetchSidebarAlerts: (state, payload) => ({
            ...state,
            alertsSidebar: {
                alerts: {
                    issue: payload.issue,
                    notice: payload.notice,
                    id: payload.id,
                    name: payload.name,
                    type: payload.type,
                },
                isLoading: true,
                error: null,
            }
        }),
        fetchSidebarAlertsSuccess: (state, payload) => ({
            ...state,
            alertsSidebar: {
                alerts: {
                    ...state.alertsSidebar.alerts,
                    alerts: payload,
                },
                isLoading: false,
                error: null,
            }
        }),
        fetchSidebarAlertsError: (state, payload) => ({
            ...state,
            alertsSidebar: {
                alerts: {},
                isLoading: false,
                error: payload,
            }
        }),
        fetchSelfServeOrgList: (state) => ({
            ...state,
            selfServeOrgList: [],
        }),
        fetchSelfServeOrgListSuccess: (state, payload) => ({
            ...state,
            selfServeOrgList: payload.selfServeOrgList,
        })
    },
});

import React from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import { MultiSelect } from 'widgets-v6/select';

import selector from './selector';

const Contacts = ({ draft, users, profile, relationship, onChange }) => {
    const showAuditors =
        profile.ownOrganization.type === 'admin' &&
        draft.type === 'media_buyer' &&
        relationship === 'child';
    return (
        <div>
            <Grid container direction="column" spacing={2}>
                {showAuditors && (
                    <Grid item xs={5}>
                        <FormLabel>Auditors</FormLabel>
                        <MultiSelect
                            value={draft.auditors}
                            onChange={value => onChange('auditors', value)}
                            options={users}
                            margin="normal"
                        />
                    </Grid>
                )}
                <Grid item xs={5}>
                    <FormLabel>Auditees</FormLabel>
                    <MultiSelect
                        value={draft.auditees}
                        onChange={value => onChange('auditees', value)}
                        options={users}
                        margin="normal"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(selector)(Contacts);

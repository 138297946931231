import _ from 'lodash';
import { createSelector } from 'reselect';
import { validateAdDraft } from '../services';
import schema from '../schema';

import { calculateThirdPartyFees } from 'states/resources/campaigns/business-logic';
import {
    calculateThirdPartyFees as calculateAdThirdPartyFees,
    calculateFirstPartyDataFee,
    calculateBidPrice,
    calculatePlatformTechFee,
    calculateMediaCost,
} from 'states/resources/ads/business-logic';
import {
    getDealPriceOverrideWarning,
    getAdOverBudgetWarning,
} from '../modules/cross-platform-setup-warning/helpers';
import { getHardSeverityTacticWarnings } from '../actions';
import { getTotalTarget } from 'common/progress-calculator-v2';

const getDraft = state => state.adForm.form.draft;
const getCurrentAd = state => state.adForm.form.currentAd;
const getCampaign = state => state.adForm.form.campaign;
const getOwnOrg = state => state.adForm.form.ownOrganization;
const getValidGeoLayers = state => state.adForm.form.validGeoLayers;
const getStats = state => state.adForm.form.stats;
const getDealsByOrganization = state => state.adForm.form.dealsByOrganization;
const getTechFee = state => state.adForm.form.techFee;
const getIsCrossPlatformCampaign = state => state.adForm.form.isCrossPlatformCampaign;
const getAdFormWarnings = state => state.adForm.form.warnings;
export const getCurrentUser = state => state.profile.userId;

export const getErrors = createSelector(
    getDraft,
    getCurrentAd,
    getCampaign,
    getOwnOrg,
    getValidGeoLayers,
    getStats,
    (draft, currentAd, campaign, ownOrganization, validGeoLayers, stats) => {
        if (!campaign) return {};
        if (!ownOrganization) return {};

        const errorsAsList = validateAdDraft({
            draft,
            originalAd: currentAd,
            billingEnabled: campaign.billing_enabled,
            thirdPartyFees: calculateThirdPartyFees(campaign),
            orgFtaPartnerId: ownOrganization.fta_partner_id,
            validGeoLayers,
            revenueModel: campaign.revenueModel,
            stats,
            techFee: campaign.tech_fee,
            campaignFtaVersion: campaign.fta_version,
            campaignType: campaign.type,
        });

        const mapping = {};
        _.each(errorsAsList, error => {
            mapping[error.field] = error.message;
        });

        const options = {
            allowUnknown: true,
            abortEarly: false,
            context: {
                draft,
            },
        };

        const result = schema.validate(draft, options);

        if (result.error) {
            _.each(result.error.details, detail => {
                mapping[detail.path.join('.')] = detail.message;
            });
        }

        return mapping;
    }
);


export const calculateConvertedDealPrice = (deals, campainCurrency, exchangeRate) => {
    return _.map(deals, deal => {
        const dealCurrency = deal.currency;
        const dealPrice = deal.price_local;
        let convertedPrice;

        if (campainCurrency !== dealCurrency) {
            if (campainCurrency === 'USD') {
                convertedPrice = dealPrice * exchangeRate;
            } else {
                convertedPrice = dealPrice / exchangeRate;
            }
        }
        return { ...deal, convertedPrice };
    });
};

export const getDealsWithConvertedPrice = createSelector(
    getDealsByOrganization,
    getCampaign,
    (dealsByOrganization, campaign) => {
        if (!campaign) return dealsByOrganization;

        const campainCurrency = campaign.currency;
        const rate = campaign.currency_rate;
        const exchangeRate = getRate(campainCurrency, rate);

        const dealsWithConvertedPrice = calculateConvertedDealPrice(
            dealsByOrganization,
            campainCurrency,
            exchangeRate
        );

        return dealsWithConvertedPrice;
    }
);


export const getNetBidPriceAndPlatformTechFee = createSelector(
    getDraft,
    getTechFee,
    getCampaign,
    (draft, techFee, campaign) => {
        if (!draft) return 0;
        if (!campaign) return 0;

        const maxCpmRate =
            draft.bid_strategy_mode === 'fixed_bid_price'
                ? draft.max_cpm_rate_local
                : draft.automatic_bid_price.max_ecpm_local;
        const thirdPartyFees = calculateAdThirdPartyFees(draft);
        const firstPartyFees = calculateFirstPartyDataFee(draft);
        const mediaCost = calculateMediaCost({ maxCpmRate, thirdPartyFees, firstPartyFees });
        const platformTechFee = calculatePlatformTechFee({ mediaCost, techFee });

        const netBidPrice = calculateBidPrice({ mediaCost, platformTechFee });
        return { netBidPrice, platformTechFee };
    }
);

export const shouldShowWarning = (selectedDeals, netBidPrice) => {
    return _.some(selectedDeals, deal => {
        if (deal.convertedPrice) {
            return netBidPrice < deal.convertedPrice;
        } else {
            return netBidPrice < deal.price_local;
        }
    });
};

export const getOverrideDealPriceWarning = createSelector(
    getDraft,
    getNetBidPriceAndPlatformTechFee,
    getDealsWithConvertedPrice,
    (draft, fees, dealsWithConvertedPrice) => {
        if (!draft) return false;
        if (draft.dealApplicationType === 'deal_only' && draft.use_deal_price_to_bid === true) {
            return false;
        }

        const { netBidPrice } = fees;
        const selectedDeals = _.filter(dealsWithConvertedPrice, deal => {
            return _.includes(draft.deals, deal.id);
        });

        const showWarning = shouldShowWarning(selectedDeals, netBidPrice);

        const overrideDealPriceWarning = getDealPriceOverrideWarning(showWarning);

        return overrideDealPriceWarning;
    }
);

export const getAdOverBudgetWarnings = createSelector(
    getCurrentAd,
    getCampaign,
    getDraft,
    (currentAd, campaign, draft) => {
        if (!draft) return false;

        const { budget_allocation_method, campaign_max_total_spend_local, ads } = campaign;
        if (
            currentAd &&
            budget_allocation_method === 'manual'
        ) {
            const totalAdCost = ads
                .filter(ad => ad.id !== currentAd.id)
                .reduce((acc, ad) => acc + getTotalTarget(ad), 0);
            const currentAdCost = getTotalTarget(draft);

            if (totalAdCost + currentAdCost > campaign_max_total_spend_local) {
                return getAdOverBudgetWarning(campaign_max_total_spend_local);
            }
        }

        return false;
    }
);

export const showCrossPlatformCampaignWarning = createSelector(
    getIsCrossPlatformCampaign,
    getAdFormWarnings,
    (isCrossPlatformCampaign, adFormWarnings) => {
        if (isCrossPlatformCampaign) {
            const targetingWarnings = _.omit(adFormWarnings, ['tactics']);
            const hasTargetingWarnings = _.some(
                Object.keys(targetingWarnings),
                targetingKey =>
                    targetingWarnings[targetingKey].hasWarning &&
                    targetingWarnings[targetingKey].severity === 'hard'
            );

            const tacticWarnings = getHardSeverityTacticWarnings({
                tacticWarnings: adFormWarnings.tactics,
            });
            const hasTacticWarnings = tacticWarnings.length > 0;

            return hasTargetingWarnings || hasTacticWarnings;
        }

        return false;
    }
);

function getRate(currency, rates) {
    switch (currency) {
        case 'CAD':
            return rates['addictive_CAD/USD'];

        case 'EUR':
            return rates['addictive_EUR/USD'];

        case 'AED':
            return rates['addictive_AED/USD'];

        case 'GBP':
            return rates['addictive_GBP/USD'];

        case 'USD':
            return 1;

        default:
            return 1;
    }
}

import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.ads';
export default function ads(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS: {
            const { ad, ads } = action.payload;

            const incomingAds = []
                .concat(ad)
                .concat(ads)
                .filter(x => x);
            const adsResource = mergeResource(state, incomingAds);

            const incomingAdIds = incomingAds.filter(ad => ad).map(ad => ad.id);

            incomingAdIds.forEach(adId => (adsResource[adId].isComplete = true));

            return adsResource;
        }

        case c.OVERVIEW_ADS__AD_DELETE__SUCCESS: {
            return _.omit(state, [action.payload.adId]);
        }

        case 'OVERVIEW_ADS__BULK_UPDATE':
        case 'SYSTEM__CAMPAIGN_ADS__AUTO_BUDGET_AD__EDIT': {
            const { adIds, etag } = action.payload;

            const adsWithNewEtag = {
                ...state,
            };
            _.each(adIds, adId => {
                adsWithNewEtag[adId] = {
                    ...adsWithNewEtag[adId],
                    attr: {
                        ...adsWithNewEtag[adId].attr,
                        _etag: etag,
                    },
                };
            });

            return adsWithNewEtag;
        }

        default:
            return state;
    }
}

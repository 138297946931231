import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    copyButton: {
        color: '#fff',
        backgroundColor: '#4fc3f7',
        border: '1px solid #4fc3f7',
        cursor: 'pointer',
        borderRadius: 2,
    },
});

const ClickAndCopyButton = props => {
    const classes = useStyles();
    const { textToDisplay, textToCopy } = props;
    const [isCopied, setIsCopied] = useState(false);
    let buttonLabel = isCopied ? 'Copied' : textToDisplay;

    function destroyCopy() {
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    }

    return (
        <div>
            <CopyToClipboard
                text={textToCopy}
                onCopy={() => {
                    setIsCopied(true);
                }}
            >
                <button
                    className={classes.copyButton}
                    onClick={() => {
                        destroyCopy();
                    }}
                >
                    {isCopied ? <i className={'fa fa-check'} /> : <i className={'fa fa-files-o'} />}
                    &nbsp;
                    {buttonLabel}
                </button>
            </CopyToClipboard>
        </div>
    );
};

export default ClickAndCopyButton;

import _ from 'lodash';
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import ProgressBar from 'widgets-v5/progress-bar';
import Progress from 'widgets-v6/progress';
import { formatNumber_currency, formatNumber_percentage, formatDate_long } from 'utils/formatting';
import { RevenueModelMapping } from '../../../../../states/resources/campaigns/business-logic';

const useStyles = makeStyles(() => ({
    cardWrapper: {
        minHeight: 160,
        padding: 16,
        overflow: 'visible',
    },
    progressContainer: {
        marginTop: 10,
    },
    textContainer: {
        width: '100%',
        textAlign: 'center',
    },
}));

const CampaignProgress = props => {
    const {
        campaign,
        campaignStats,
        campaignStatsIsLoading,
        organizationTimezone,
        currentFlight,
        campaignDataShowing,
    } = props;
    const classes = useStyles();
    const { status } = campaign;
    let start,
        end = null;

    if (campaignDataShowing === 'campaign') {
        start = campaign.start;
        end = campaign.end;
    } else if (currentFlight) {
        start = currentFlight.start;
        end = currentFlight.end;
    }

    const startAsDateObject = start && new Date(start);
    const endAsDateObject = end && new Date(end);
    const rangeLeft = start && formatDate_long(startAsDateObject, organizationTimezone);
    const rangeRight = end && formatDate_long(endAsDateObject, organizationTimezone);
    const isAgencyMargin = campaign.revenueModel === RevenueModelMapping.AgencyMargin;
    const isCPMCPCOrCPM = [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
        campaign?.revenueModel
    );

    let value = 0;
    let spend = 0;
    let budget = 0;
    if (!campaignStatsIsLoading) {
        if (campaignDataShowing === 'flight') {
            if (currentFlight) {
                value =
                    currentFlight.metadata.metrics.owner_total_media_cost_local /
                    currentFlight.total_cost_budget;
                spend = currentFlight.metadata.metrics.owner_total_media_cost_local;
                budget = currentFlight.total_cost_budget;
            }
        } else if (isAgencyMargin) {
            value = campaignStats.revenue / campaign.total_billings_local;
            spend = campaignStats.revenue;
            budget = campaign.total_billings_local;
        } else {
            value =
                campaignStats.owner_total_media_cost_local /
                campaign.campaign_max_total_spend_local;
            spend = campaignStats.owner_total_media_cost_local;
            budget = campaign.campaign_max_total_spend_local;
        }
    }

    return (
        <Card className={classes.cardWrapper}>
            {start && end && (
                <React.Fragment>
                    <div className={classes.textContainer}>
                        <Typography variant="subtitle2">Date Range</Typography>
                    </div>
                    <ProgressBar
                        status={status}
                        startDate={startAsDateObject}
                        endDate={endAsDateObject}
                        rangeLeft={rangeLeft}
                        rangeRight={rangeRight}
                        primaryTimezone={organizationTimezone}
                        secondaryTimezone={campaign.default_timezone}
                    />
                </React.Fragment>
            )}

            {(!isAgencyMargin && campaign.campaign_max_total_spend_local === 0) ||
            (campaignDataShowing === 'flight' && !currentFlight) ||
            (isCPMCPCOrCPM && campaign.budget_allocation_method === 'auto') ? null : (
                <div className={classes.progressContainer}>
                    <div className={classes.textContainer}>
                        <Typography variant="subtitle2">
                            {isAgencyMargin ? 'Revenue' : 'Total Cost'}
                        </Typography>
                    </div>
                    <Progress
                        leftLabel={
                            <Typography variant="caption">
                                <strong>Spend: </strong>
                                {`${formatNumber_currency(spend)} (${formatNumber_percentage(
                                    value
                                )})`}
                            </Typography>
                        }
                        rightLabel={
                            <Typography variant="caption">
                                <strong>Budget: </strong>
                                {formatNumber_currency(budget)}
                            </Typography>
                        }
                        value={_.isFinite(value) ? _.min([value * 100, 100]) : 0}
                    />
                </div>
            )}
        </Card>
    );
};

export default CampaignProgress;

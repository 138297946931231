import { naivelyFetchQueryStringField } from 'utils/querystrings';

export function detectImpersonation() {
    // test environment does not have session or local storage
    if (typeof sessionStorage === 'undefined') {
        return false;
    }

    // First, do we have a token in session storage?
    if (sessionStorage.getItem('authorization')) {
        return true;
    }

    // Next, do we have the token field on the querystring?
    const impersonationUUID = naivelyFetchQueryStringField('token');

    // Finally, does that UUID match an auth token in localStorage?
    if (!impersonationUUID || !localStorage.getItem(impersonationUUID)) {
        return false;
    } else {
        return true;
    }
}

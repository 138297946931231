import React from 'react';
import _ from 'lodash';
import flags from 'containers/flags/service';
import config from '../../../../config';

export function StaleFlags() {
    const staleFlags = flags.getStaleFlags();

    const staleFlagsSorted = _(staleFlags)
        .map((flags, author) => ({
            author,
            flags,
        }))
        .sortBy(record => record.flags.length)
        .reverse()
        .value();

    const styles = {
        position: 'fixed',
        top: 0,
        right: 0,
        padding: 10,
        backgroundColor: 'yellow',
    };

    if (!config.DEV_SETTINGS_ENABLED) {
        return null;
    }

    return (
        <div style={styles}>
            Stale Flags:
            {_.map(staleFlagsSorted, ({ author, flags }) => (
                <div key={author}>{`${author} - ${flags.length}`}</div>
            ))}
        </div>
    );
}

import Joi from '@hapi/joi';
import flags from 'containers/flags/service';
import { DealCategory } from '../../../../../common/constants/deals';

export const getSchema = dealCategory =>
    Joi.object({
        app_name: Joi.string()
            .required()
            .label(flags.isEnabled('efb_35_curated_deals') ? 'Deal Name' : 'App/Site Name')
            .messages({
                'string.empty': `${
                    flags.isEnabled('efb_35_curated_deals') ? 'Deal Name' : 'App/Site Name'
                } is required`,
            }),

        id: Joi.string()
            .required()
            .label('Deal ID')
            .messages({
                'string.empty': 'Deal ID is required',
            })
            .custom((value, helper) => {
                const validChars = new RegExp(/^[A-Za-z0-9\.\-_]+$/, 'gm');
                return (
                    validChars.test(value) ||
                    helper.message(
                        'Deal ID can only contain letters, numbers, dots (.), underscores (_), and dashes (-).'
                    )
                );
            }),

        pub_name: Joi.string()
            .required()
            .label(dealCategory === DealCategory.Curated ? 'Description' : 'Publisher Name')
            .messages({
                'string.empty': `${
                    dealCategory === DealCategory.Curated ? 'Description' : 'Publisher Name'
                } is required`,
            }),

        price_local: Joi.number()
            .required()
            .greater(0)
            .label('Price')
            .messages({
                'number.greater': 'Price must be greater than 0',
                'number.base': 'Price is required',
            }),

        supply_source: Joi.string()
            .required()
            .label(flags.isEnabled('efb_35_curated_deals') ? 'Exchange' : 'Supply Source')
            .messages({
                'any.required': `${
                    flags.isEnabled('efb_35_curated_deals') ? 'Exchange' : 'Supply Source'
                } is required`,
            }),

        category: Joi.string(),
    });

import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';

const Beacons = {
    create(campaignId, beacons) {
        return (dispatch, getState) => {
            return http()
                .put(`campaigns/${campaignId}/beacons`, beacons)
                .catch(err => {
                    console.error(`CREATE beacons error`, err);

                    throw err;
                });
        };
    },
};

export default Beacons;

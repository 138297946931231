import React from 'react';
import classnames from 'classnames';

class Overlay extends React.Component {
    static defaultProps = {
        disabled: false,
    };

    render() {
        if (!this.props.show) {
            return this.props.children;
        }

        return (
            <div className="ef5-overlay">
                <div
                    className={classnames('ef5-overlay__body', {
                        'ef5-overlay__body--is-disabled': this.props.disabled,
                    })}
                >
                    {this.props.message}
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default Overlay;

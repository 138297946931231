import React from 'react';

import Accordion from 'widgets-v6/accordion';
import MiddleTruncatedText from 'widgets-v6/middle-truncated-text';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import { browserHistory } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import ViewMore from 'widgets-v6/view-more';

const NON_FIXABLE_ALERTS = ['zeroCtr', 'adEndingSoon', 'zeroVcr'];

const useStyles = makeStyles({
    header: props => ({
        backgroundColor: props.isIssue ? 'rgba(211, 47, 47, 0.12)' : 'rgba(2, 136, 209, 0.12)',
        color: props.isIssue ? '#C62828' : '#01579B',
    }),
    adHeader: props => ({
        backgroundColor: props.isIssue ? 'rgba(211, 47, 47, 0.04)' : 'rgba(2, 136, 209, 0.04)',
        marginRight: '-15px',
        marginLeft: '-15px',
        height: '40px',
        padding: '14px 16px 0 16px',
    }),
    firstHalfContent: {
        flex: '0 3 auto',
        minWidth: '0',
    },
    secondHalfContent: {
        flex: '3 0 auto',
        minWidth: '0',
    },
    bold: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    subText: {
        fontSize: '12px',
    },
    content: {
        '& > div': {
            paddingTop: '14px',
        },
    },
    accordionDetails: {
        paddingTop: '0',
    },
    emptyContent: {
        fontSize: '16px',
        color: '#757575',
        padding: '24px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        opacity: '0.6',
    },
    dotsbetweenItem: {
        color: '#666',
        '&::before': {
            content: '"\\2022"',
            padding: '0px 5px',
        },
    },
    buttonContent: {
        minWidth: '106px',
        fontSize: '14px',
    },
    preFormatted: {
        overflow: 'unset',
        fontFamily: 'unset',
        whiteSpace: 'pre-wrap',
    },
});

export const SidebarAlertItems = ({
    issue,
    notice,
    type,
    alerts: { campaign = {}, ads = {}, id },
}) => {
    const classes = useStyles();

    const isEmpty = !issue && !notice;

    const parsedAds = Object.keys(ads).reduce(
        (acc, alertKey) => {
            const result = { ...acc };

            if (ads[alertKey].issues.length) {
                result.numOfIssues += ads[alertKey].issues.length;
                result.issues.push({
                    id: alertKey,
                    alerts: ads[alertKey].issues,
                });
            }

            if (ads[alertKey].notices.length) {
                result.numOfNotices += ads[alertKey].notices.length;
                result.notices.push({
                    id: alertKey,
                    alerts: ads[alertKey].notices,
                });
            }
            return result;
        },
        {
            numOfIssues: 0,
            numOfNotices: 0,
            issues: [],
            notices: [],
        }
    );

    return (
        <React.Fragment>
            <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
                <Typography variant="subtitle1" title={`#${id}`}>
                    <MiddleTruncatedText text={`#${id}`} />
                </Typography>
            </Box>
            {isEmpty && (
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    className={classes.emptyContent}
                >
                    <span>Currently no alerts</span>
                </Box>
            )}
            {campaign.issues && !!campaign.issues.length && (
                <CampaignItems
                    id={campaign.id}
                    isIssue
                    numOfAlerts={campaign.issues.length}
                    alerts={campaign.issues}
                />
            )}
            {campaign.notices && !!campaign.notices.length && (
                <CampaignItems
                    id={campaign.id}
                    numOfAlerts={campaign.notices.length}
                    alerts={campaign.notices}
                />
            )}
            {parsedAds.issues && !!parsedAds.issues.length && (
                <AdItems
                    isIssue
                    numOfAlerts={parsedAds.numOfIssues}
                    alerts={parsedAds.issues}
                    isAd={type === 'ad'}
                />
            )}
            {parsedAds.notices && !!parsedAds.notices.length && (
                <AdItems
                    numOfAlerts={parsedAds.numOfNotices}
                    alerts={parsedAds.notices}
                    isAd={type === 'ad'}
                />
            )}
        </React.Fragment>
    );
};

export const CampaignItems = ({ numOfAlerts, isIssue = false, alerts }) => {
    const classes = useStyles({ isIssue });

    return (
        <Accordion
            summary={
                <SummaryContent
                    isIssue={isIssue}
                    numOfAlerts={numOfAlerts}
                    resourceType="campaign"
                />
            }
            headerClassName={classes.header}
        >
            <Box display="flex" flexDirection="column" className={classes.content}>
                {alerts.map(alert => (
                    <ItemContent
                        key={alert.id}
                        alert={alert}
                        isIssue={isIssue}
                        resourceType="campaign"
                    />
                ))}
            </Box>
        </Accordion>
    );
};

export const AdItems = ({ numOfAlerts, isIssue = false, alerts, isAd }) => {
    const classes = useStyles({ isIssue });

    return (
        <Accordion
            summary={
                <SummaryContent isIssue={isIssue} numOfAlerts={numOfAlerts} resourceType="ad" />
            }
            defaultExpanded={isAd}
            headerClassName={classes.header}
            detailsClassName={classes.accordionDetails}
        >
            <Box display="flex" flexDirection="column" width="100%">
                {alerts.map(alert => {
                    return (
                        <Box
                            key={alert.id}
                            display="flex"
                            flexDirection="column"
                            className={classes.content}
                        >
                            {!isAd && (
                                <Box className={classes.adHeader} title={`#${alert.id}`}>
                                    <MiddleTruncatedText text={`#${alert.id}`} />
                                </Box>
                            )}
                            {alert.alerts.map(innerAlert => (
                                <ItemContent
                                    key={innerAlert.id}
                                    alert={innerAlert}
                                    isIssue={isIssue}
                                    resourceType="ad"
                                />
                            ))}
                        </Box>
                    );
                })}
            </Box>
        </Accordion>
    );
};

const SummaryContent = ({ isIssue, numOfAlerts, resourceType }) => {
    const IconElement = isIssue ? NotificationImportantIcon : InfoIcon;
    return (
        <Box display="flex" alignItems="center">
            <Box marginRight={2}>
                <IconElement fontSize="large" />
            </Box>
            <Box fontSize={16}>
                {numOfAlerts}&nbsp;
                {`${resourceType === 'ad' ? 'Ad' : 'Campaign'} ${
                    isIssue ? 'Issue(s)' : 'Notice(s)'
                }`}
            </Box>
        </Box>
    );
};

const ItemContent = ({ alert, isIssue }) => {
    const classes = useStyles();
    const resourceId = alert.resourceId;

    const getUrl = alert => {
        let url;
        if (alert.resourceType === 'ad') {
            if (
                alert.alertType === 'projectedToUnderdeliver' ||
                alert.alertType === 'underpacing'
            ) {
                url = `/campaigns/${alert.campaignId}/progress/${resourceId}`;
            } else {
                url = `/campaigns/${resourceId
                    .toString()
                    .substr(0, resourceId.length - 2)}/setup/ads/${resourceId}/edit`;
            }
        } else {
            if (
                alert.alertType === 'projectedToUnderdeliver' ||
                alert.alertType === 'underpacing'
            ) {
                url = `/campaigns/${resourceId}/progress/overall`;
            } else {
                url = `/campaigns/${resourceId}/setup`;
            }
        }
        browserHistory.push(url);
    };

    const getButtonName = alertType => {
        return alertType === 'projectedToUnderdeliver' ? 'View Progress' : 'Fix Setup';
    };

    return (
        <Box display="flex" alignItems="center">
            <Box paddingRight={2}>
                <Typography className={classes.bold}>{alert.title}</Typography>
                <Typography className={classes.subText}>
                    <Box display="flex" alignItems="center">
                        <Box position="relative" flex="0 2 auto">
                            <ViewMore>
                                <pre className={classes.preFormatted}>{alert.body}</pre>
                            </ViewMore>
                        </Box>
                        <Box flex="2 0 auto">
                            <span className={classes.dotsbetweenItem}>
                                {moment(alert.start).fromNow()}
                            </span>
                        </Box>
                    </Box>
                </Typography>
            </Box>
            <Box minWidth="17%">
                {isIssue && !NON_FIXABLE_ALERTS.includes(alert.alertType) && (
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.buttonContent}
                        onClick={() => getUrl(alert)}
                    >
                        {getButtonName(alert.alertType)}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

import _ from 'lodash';
import React from 'react';
import actions from '../actions';

import { ComposableDataTable, Cell, Row } from 'widgets-v5/data-table';
import Card from 'widgets-v5/card';
import CircularProgress from '@mui/material/CircularProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { PAGINATION_LIMIT, ApplistMapping } from '../constants';

class Editor extends React.Component {
    state = {
        activeTab: 'app',
        page: 0,
    };

    handleUserInputAndBundleIdSync = () => {
        const appListId = _.get(this.props, 'appListId', void 0);
        this.props.dispatch(actions.sync({ appListId, page: this.state.page }));

        const selectedTab = this.state.activeTab === 'app' ? 'editor' : 'app';
        this.setState({
            activeTab: selectedTab,
        });
    };

    handleChangeSummaryNextPage = () => {
        const appListId = _.get(this.props, 'appListId', void 0);
        this.setState(
            {
                page: Math.min(
                    this.state.page + 1,
                    _.chunk(this.props.draft.allBundleIds, PAGINATION_LIMIT).length - 1
                ),
            },
            () => {
                this.props.dispatch(actions.changePage({ appListId, page: this.state.page }));
            }
        );
    };

    handleChangeSummaryPrevPage = () => {
        const appListId = _.get(this.props, 'appListId', void 0);
        this.setState(
            {
                page: Math.max(0, this.state.page - 1),
            },
            () => {
                this.props.dispatch(actions.changePage({ appListId, page: this.state.page }));
            }
        );
    };

    onFieldChange = fieldName => {
        const { dispatch } = this.props;
        const appListId = _.get(this.props, 'appListId', void 0);

        return fieldValue => {
            const newFormData = { [fieldName]: fieldValue };
            dispatch(actions.updateDraft(newFormData, appListId));
        };
    };

    handleRemoveApp = bundle_id => {
        const { appListId } = this.props;

        this.props.dispatch(actions.removeBundle(appListId, bundle_id));
    };

    handleRemoveDomain = domain => {
        const { appListId } = this.props;

        this.props.dispatch(actions.removeDomain(appListId, domain));
    };

    handleRemoveConnectedTV = connectedTVId => {
        const { appListId } = this.props;

        this.props.dispatch(actions.removeConnectedTV(appListId, connectedTVId));
    };

    handleRemoveDoohBoardId = doohBoardId => {
        const { appListId } = this.props;

        this.props.dispatch(actions.removeDoohBoardId(appListId, doohBoardId));
    };

    handleSubmit = () => {
        const appListId = _.get(this.props, 'appListId', void 0);
        this.props.dispatch(actions.submit(appListId));
    };

    handleCancel = () => {
        const { dispatch } = this.props;
        const appListId = _.get(this.props, 'appListId', void 0);
        const { onCloseRedirectPath } = this.props;

        dispatch(actions.cancel(appListId));
    };

    render() {
        const {
            draft,
            appList,
            showErrors,
            errors,
            invalidBundleAndDomainIDs,
            isPaginationLoading,
        } = this.props;
        const { bundle_ids, domains, connected_tv_ids, dooh_board_ids, allBundleIds } = draft

        const apps = {};
        _.each(appList.appCategories, appCategory => {
            apps[appCategory.bundle_id] = appCategory;
        });

        const allBundleIdsChunksSize = _.chunk(allBundleIds, PAGINATION_LIMIT).length;

        let validation_error_count = 0;

        const showAppNameColumn = bundle_ids.length > 0;
        const showAppCategoryColumn = bundle_ids.length > 0;

        return (
            <div className="appsEditor">
                <div className="ef3-campaignOverview__table">
                    <Card padding={0} margin={0}>
                        {isPaginationLoading ? (
                            <Box m={4}>
                                <FlexRow center="xs">
                                    <FlexCol xs={12}>
                                        <CircularProgress />
                                    </FlexCol>
                                </FlexRow>
                            </Box>
                        ) : (
                            <div>
                                {bundle_ids.length > 0 || domains.length > 0 || connected_tv_ids.length > 0 || dooh_board_ids.length > 0 ? (
                                    <ComposableDataTable>
                                        <Row header>
                                            <Cell style={{ maxWidth: 150 }}>ID</Cell>
                                            <Cell>Type</Cell>
                                            {showAppNameColumn && <Cell style={{ maxWidth: 300 }}>App Name</Cell>}
                                            {showAppCategoryColumn && <Cell>App Category</Cell>}
                                            <Cell>Delete</Cell>
                                        </Row>
                                        {_.map(bundle_ids, (bundle_id, index) => {
                                            const bundleName = _.get(
                                                apps[bundle_id],
                                                `app_name_decrypted`,
                                                ''
                                            );
                                            const category = _.startCase(
                                                _.get(apps[bundle_id], `app_cat_name`, '')
                                            );

                                            const validation_error = _.get(
                                                apps[bundle_id],
                                                `validation_error`,
                                                ''
                                            );

                                            validation_error ? validation_error_count++ : null;

                                            const validation_error_flag = validation_error ? <InvalidBundleIDAndDomainIdentifierFlag /> : null;

                                            return (
                                                <Row
                                                    className="ad-list-overview__row"
                                                    key={`${index}_bundle`}
                                                >
                                                    <Cell
                                                        className="ef3-campaignOverview__cell"
                                                        style={{ maxWidth: 150 }}
                                                    >
                                                        {bundle_id}
                                                        {validation_error_flag}
                                                    </Cell>
                                                    <Cell>{ApplistMapping.BundleId}</Cell>
                                                    {showAppNameColumn &&
                                                        <Cell
                                                            className="ef3-campaignOverview__cell"
                                                            style={{ maxWidth: 300 }}
                                                        >
                                                            {bundleName}
                                                        </Cell>
                                                    }
                                                    {showAppCategoryColumn && <Cell>{category}</Cell>}
                                                    <Cell>
                                                        <IconButton
                                                            onClick={() =>
                                                                this.handleRemoveApp(bundle_id)
                                                            }
                                                            size="large"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Cell>
                                                </Row>
                                            );
                                        })}
                                        {_.map(domains, (domain, index) => {
                                            const site = _.includes(invalidBundleAndDomainIDs, domain) ?
                                                <>
                                                    {domain}
                                                    <InvalidBundleIDAndDomainIdentifierFlag />
                                                </> : domain
                                            return (
                                                <Row
                                                    className="ad-list-overview__row"
                                                    key={`${index}_domain`}
                                                >
                                                    <Cell
                                                        className="ef3-campaignOverview__cell"
                                                        style={{ maxWidth: 150 }}
                                                    >
                                                        {site}
                                                    </Cell>
                                                    <Cell>{ApplistMapping.Domain}</Cell>
                                                    {showAppNameColumn && <Cell />}
                                                    {showAppCategoryColumn && <Cell />}
                                                    <Cell>
                                                        <IconButton
                                                            onClick={() =>
                                                                this.handleRemoveDomain(domain)
                                                            }
                                                            size="large"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Cell>
                                                </Row>
                                            );
                                        })}
                                        {_.map(connected_tv_ids, (ctvId, index) => {
                                            return (
                                                <Row
                                                    className="ad-list-overview__row"
                                                    key={`${index}_ctvId`}
                                                >
                                                    <Cell
                                                        className="ef3-campaignOverview__cell"
                                                        style={{ maxWidth: 150 }}
                                                    >
                                                        {ctvId}
                                                    </Cell>
                                                    <Cell>{ApplistMapping.ConnectedTVId}</Cell>
                                                    {showAppNameColumn && <Cell />}
                                                    {showAppCategoryColumn && <Cell />}
                                                    <Cell>
                                                        <IconButton
                                                            onClick={() =>
                                                                this.handleRemoveConnectedTV(ctvId)
                                                            }
                                                            size="large"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Cell>
                                                </Row>
                                            );
                                        })}
                                        {_.map(dooh_board_ids, (doohBoardId, index) => {
                                            return (
                                                <Row
                                                    className="ad-list-overview__row"
                                                    key={`${index}_doohBoardId`}
                                                >
                                                    <Cell
                                                        className="ef3-campaignOverview__cell"
                                                        style={{ maxWidth: 150 }}
                                                    >
                                                        {doohBoardId}
                                                    </Cell>
                                                    <Cell>{ApplistMapping.DoohBoardId}</Cell>
                                                    {showAppNameColumn && <Cell />}
                                                    {showAppCategoryColumn && <Cell />}
                                                    <Cell>
                                                        <IconButton
                                                            onClick={() =>
                                                                this.handleRemoveDoohBoardId(doohBoardId)
                                                            }
                                                            size="large"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Cell>
                                                </Row>
                                            );
                                        })}
                                    </ComposableDataTable>
                                ) : (
                                    <div>No Apps</div>
                                )}
                            </div>
                        )}
                    </Card>
                </div>
                <Box m={4}>
                    <FlexRow center="xs">
                        <FlexCol xs={12}>
                            {allBundleIdsChunksSize > 0 && (
                                <ButtonGroup
                                    variant={'text'}
                                    color="primary"
                                    aria-label="text primary button group"
                                >
                                    <Button
                                        disabled={this.state.page <= 0}
                                        onClick={this.handleChangeSummaryPrevPage}
                                    >
                                        <ChevronLeftIcon />
                                    </Button>
                                    <Button disabled={true}>
                                        {this.state.page + 1} of {allBundleIdsChunksSize}
                                    </Button>
                                    <Button
                                        disabled={this.state.page >= allBundleIdsChunksSize - 1}
                                        onClick={this.handleChangeSummaryNextPage}
                                    >
                                        <ChevronRightIcon />
                                    </Button>
                                </ButtonGroup>
                            )}
                        </FlexCol>
                    </FlexRow>
                </Box>
                <div className="ef3-campaignOverview_appListsEditor_layout_foot">
                    <div className="group-left">
                        <span className="userInputErrorMsg">
                            {validation_error_count ? (
                                <div>{validation_error_count} Invalid bundle ids </div>
                            ) : null}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

function InvalidBundleIDAndDomainIdentifierFlag(props) {
    return (
        <span className="invalid-id-flag" style={{ color: '#f44336' }}>
            <i className="fa fa-warning" />
            &nbsp;
            <span className="tag">Invalid ID</span>
        </span>
    );
}

export default Editor;

import React from 'react';
import { connect } from 'react-redux';

import OverviewTabs from 'pages/campaigns/campaigns-overview/overview-tabs';
import MediaPlanForm from 'forms/media-plan-form';
import selector from './selector';

class CampaignOverviewWorkspace extends React.Component {
    render() {
        const { campaignId } = this.props.params;
        return (
            <div>
                <OverviewTabs tab="overview" campaignId={campaignId} />
                <MediaPlanForm campaignId={campaignId} />
            </div>
        );
    }
}

export default connect(selector)(CampaignOverviewWorkspace);

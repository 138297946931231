import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Draft from 'widgets-v5/draft';
import StandardInput from 'widgets-v5/standard-input';
import Spacer from 'widgets-v5/spacer';

class TacticForm extends React.Component {
    static propTypes = {
        title: PropTypes.string,

        onDraftChange: PropTypes.func,
    };

    static defaultProps = {
        title: '',
    };

    state = {
        initialDraft: {
            title: this.props.title,
        },
        schema: {
            title(value) {
                if (!value) {
                    return 'Title is required';
                }
            },
        },

        isEditing: false,
    };

    edit = () => {
        this.setState({ isEditing: true });
    };

    confirmChanges = (draft, errors) => {
        if (errors.length) {
            return;
        }

        this.setState({ isEditing: false }, () => {
            this.props.onDraftChange({ draft, errors });
        });
    };

    cancelChanges = updateDraft => {
        this.setState({ isEditing: false }, () => {
            updateDraft(this.state.initialDraft);
        });
    };

    render() {
        const { initialDraft, schema } = this.state;

        return (
            <Draft initialDraft={initialDraft} schema={schema}>
                {({ draft, errors, updateDraft }) => {
                    if (this.state.isEditing) {
                        return (
                            <React.Fragment>
                                <div className="ef6-alignment__flex">
                                    <div>
                                        <StandardInput
                                            value={draft.title}
                                            onChange={title => updateDraft({ title })}
                                        />
                                        <Spacer />
                                        {_.map(errors, (e, index) => (
                                            <span className="ef5-color__error" key={index}>
                                                {e.message}
                                            </span>
                                        ))}
                                    </div>
                                    <div>
                                        <span onClick={() => this.confirmChanges(draft, errors)}>
                                            <i
                                                style={{ padding: 10 }}
                                                className="fa fa-check ef5-interactive-area__primary"
                                            />
                                        </span>
                                        <span onClick={() => this.cancelChanges(updateDraft)}>
                                            <i
                                                style={{ padding: 10 }}
                                                className="fa fa-close ef5-interactive-area__primary"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <div className="ef6-alignment__flex">
                                {/*<i className="fa fa-chevron-down" onClick={this.props.onClick}/>*/}
                                {draft.title}
                                <i
                                    style={{ paddingLeft: 5 }}
                                    className="fa fa-pencil ef5-interactive-area__primary"
                                    onClick={this.edit}
                                />
                            </div>
                        );
                    }
                }}
            </Draft>
        );
    }
}

export default TacticForm;

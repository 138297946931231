import _ from 'lodash';
import { metricConfig } from 'services/metrics';

/*
 * The unique metric (unique_campaign, unique_creative, unique_ad) is dynamic per per dimension:
 * - When a dimension has a matching unique, use that metric.
 * - When a dimension does not have a matching unique, use the unique from the nearest ancestor.
 * - When a dimension does not have an ancestor with a matching unique, default to 'unique_campaign'.
 *
 * Example:
 * Given a split of:
 *     Gender           > Creative          > Device OS         > Ad        > Age
 *
 * It should map to uniques:
 *     unique_campaign  > unique_creative   > unique_creative   > unique_ad > unique_ad
 */

export function getUniqueBySplit({ splits, row }) {
    const dimensionIndex = _.findIndex(splits, s => s.name === row.dimension);
    const parentAndSelfSplits = _.slice(splits, 0, dimensionIndex + 1);
    const parentUnique = _.findLast(parentAndSelfSplits, s =>
        _.includes(['creative', 'split by ad id', 'ad'], s.label.toLowerCase())
    );

    let uniqueUsers;
    if (!parentUnique) {
        uniqueUsers = metricConfig.unique_campaign.getFormattedValueFromRecord({
            statRecord: row.stats,
        });
    } else if (parentUnique.label.toLowerCase() === 'creative') {
        uniqueUsers = metricConfig.unique_creative.getFormattedValueFromRecord({
            statRecord: row.stats,
        });
    } else if (_.includes(['split by ad id', 'ad'], parentUnique.label.toLowerCase())) {
        uniqueUsers = metricConfig.unique_ad.getFormattedValueFromRecord({ statRecord: row.stats });
    }

    return uniqueUsers;
}

import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';

import { AdFormCheckboxList } from 'forms/ad-form/modules/checkbox-groups';
import { getDeviceOs } from 'containers/ad-summary';
import shallowCompare from 'utils/shallow-compare';
import {
    INAPP_DEVICES_OS_OPTIONS,
    DESKTOP_DEVICE_OS_OPTIONS,
    CTV_DEVICE_OS_OPTIONS,
    DOOH_DEVICES_OS_OPTIONS,
} from 'forms/ad-form/services/default-values';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { getDeviceOsWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';

export class DeviceOsForm extends React.Component {
    handleChange = ({ value, checked, updateDraft, existingValues }) => {
        let newValues = [];
        if (checked) {
            newValues = _.concat(existingValues, value);
        } else {
            newValues = _.filter(existingValues, exisitngValue => exisitngValue !== value);
        }

        updateDraft({ target_device_os: newValues });
    };

    render() {
        const { ad, ads, campaign, onSave, platformFilter } = this.props;
        let options = INAPP_DEVICES_OS_OPTIONS;

        if (platformFilter === 'desktop') {
            options = DESKTOP_DEVICE_OS_OPTIONS;
        } else if (platformFilter === 'ctv') {
            options = CTV_DEVICE_OS_OPTIONS;
        } else if (platformFilter === 'dooh') {
            options = [];
        }

        const { isCrossPlatformCampaign, type } = campaign;
        const isCTVCampaign = type === CampaignTypeMapping.CTV;
        const isDOOHCampaign = type === CampaignTypeMapping.DOOH;
        const isStandardCampaign = type === CampaignTypeMapping.Standard;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Device OS"
                fields={['target_device_os']}
                shouldRefreshPredictionsOnSave={true}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => {
                    if (isCrossPlatformCampaign) {
                        const adPlatforms = ad
                            ? _.get(ad, 'platforms')
                            : _(ads)
                                  .map(ad => ad.platforms)
                                  .flatten()
                                  .uniq()
                                  .value();

                        const deviceOsWarnings = getDeviceOsWarning({
                            selectedDevices: draft.target_device_os,
                            platforms: adPlatforms,
                        });
                        return (
                            <div>
                                {isStandardCampaign && (
                                    <Typography color="textSecondary">
                                        In-App / Mobile Web Targets
                                    </Typography>
                                )}
                                {isStandardCampaign &&
                                    _.map(options, option => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    checked={_.includes(
                                                        draft.target_device_os,
                                                        option.value
                                                    )}
                                                    onChange={e =>
                                                        this.handleChange({
                                                            value: option.value,
                                                            checked: e.target.checked,
                                                            existingValues: draft.target_device_os,
                                                            updateDraft,
                                                        })
                                                    }
                                                    color="primary"
                                                    disabled={
                                                        _.includes(
                                                            draft.target_device_os,
                                                            option.value
                                                        ) && draft.target_device_os.length === 1
                                                    }
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}
                                {isStandardCampaign && (
                                    <Typography color="textSecondary">Desktop Targets</Typography>
                                )}
                                {isStandardCampaign &&
                                    _.map(DESKTOP_DEVICE_OS_OPTIONS, option => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    checked={_.includes(
                                                        draft.target_device_os,
                                                        option.value
                                                    )}
                                                    onChange={e =>
                                                        this.handleChange({
                                                            value: option.value,
                                                            checked: e.target.checked,
                                                            existingValues: draft.target_device_os,
                                                            updateDraft,
                                                        })
                                                    }
                                                    color="primary"
                                                    disabled={
                                                        _.includes(
                                                            draft.target_device_os,
                                                            option.value
                                                        ) && draft.target_device_os.length === 1
                                                    }
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}
                                {isCTVCampaign && (
                                    <Typography color="textSecondary">Connected TV</Typography>
                                )}
                                {isCTVCampaign &&
                                    _.map(CTV_DEVICE_OS_OPTIONS, option => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    checked={_.includes(
                                                        draft.target_device_os,
                                                        option.value
                                                    )}
                                                    onChange={e =>
                                                        this.handleChange({
                                                            value: option.value,
                                                            checked: e.target.checked,
                                                            existingValues: draft.target_device_os,
                                                            updateDraft,
                                                        })
                                                    }
                                                    color="primary"
                                                    disabled={
                                                        _.includes(
                                                            draft.target_device_os,
                                                            option.value
                                                        ) && draft.target_device_os.length === 1
                                                    }
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}
                                {isDOOHCampaign && (
                                    <Typography color="textSecondary">Digital Out-of-Home</Typography>
                                )}
                                {isDOOHCampaign &&
                                    _.map(DOOH_DEVICES_OS_OPTIONS, option => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={
                                                <Checkbox
                                                    checked={_.includes(
                                                        draft.target_device_os,
                                                        option.value
                                                    )}
                                                    onChange={e =>
                                                        this.handleChange({
                                                            value: option.value,
                                                            checked: e.target.checked,
                                                            existingValues: draft.target_device_os,
                                                            updateDraft,
                                                        })
                                                    }
                                                    color="primary"
                                                    disabled={
                                                        _.includes(
                                                            draft.target_device_os,
                                                            option.value
                                                        ) && draft.target_device_os.length === 1
                                                    }
                                                />
                                            }
                                            label={option.label}
                                        />
                                    ))}
                                {deviceOsWarnings.hasWarning && (
                                    <WarningText
                                        severity={deviceOsWarnings.severity}
                                        message={deviceOsWarnings.message}
                                    />
                                )}
                            </div>
                        );
                    } else {
                        return (
                            <AdFormCheckboxList
                                label={ads ? 'Device OS' : ''}
                                values={draft.target_device_os}
                                minSelected={1}
                                options={options}
                                errors={errors.target_device_os}
                                showErrors={shouldShowErrors}
                                onChange={val => updateDraft({ target_device_os: _.uniq(val) })}
                            />
                        );
                    }
                }}
            </BaseColumnForm>
        );
    }
}
export class DeviceOsSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;

        if (campaign.isCrossPlatformCampaign) {
            return (
                <div>
                    {error ? (
                        <div>{error}</div>
                    ) : (
                        <div>
                            {_.map(getDeviceOs(ad.target_device_os).split(','), device => {
                                const isDeviceOs = _.includes(DESKTOP_DEVICE_OS_OPTIONS, device);
                                let label = device;
                                if (isDeviceOs) {
                                    label = _.find(
                                        DESKTOP_DEVICE_OS_OPTIONS,
                                        option => option.value === device
                                    ).label;
                                }
                                return <div key={label}>{label}</div>;
                            })}
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div>
                {error ? (
                    <div>{error}</div>
                ) : (
                    <div>
                        {ad.platform === 'desktop'
                            ? _.map(DESKTOP_DEVICE_OS_OPTIONS, deviceOption => {
                                  const foundOs = ad.target_device_os.includes(deviceOption.value);
                                  return (
                                      <div key={deviceOption.value}>
                                          {foundOs && _.get(deviceOption, 'label')}
                                      </div>
                                  );
                              })
                            : _.map(getDeviceOs(ad.target_device_os).split(','), device => (
                                  <div key={device}>{device}</div>
                              ))}
                    </div>
                )}
            </div>
        );
    }
}

import filter from 'lodash/filter';

import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';

export const MAX_DIMENSION_SENDTOFTA_COUNT = 5;

export function isMaxFta(customDimensions) {
    return (
        filter(customDimensions, dimension => dimension.sendToFta === true).length ===
        MAX_DIMENSION_SENDTOFTA_COUNT
    );
}

export function canEditCustomDimension(campaign) {
    if (campaign && campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

import React, { useMemo } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import numeral from 'numeral';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Label } from 'widgets-v5/typography';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';

const useStyles = makeStyles({
    lookbackWindowDaysBox: {
        marginLeft: 35,
    },
    lookbackWindowDaysTextField: {
        marginTop: 8,
    },
});

export const ConversionLookBackWindowForm = ({
    value,
    onChange,
    showErrors,
    lookBackWindowDaysError,
}) => {
    const classes = useStyles();

    return (
        <FormControl component="fieldset">
            <RadioGroup
                value={value.type}
                onChange={e => onChange({ value: e.target.value, field: 'type' })}
            >
                <FormControlLabel
                    value="unlimited"
                    control={<Radio color="primary" />}
                    label="Unlimited"
                />
                <FormControlLabel
                    value="limited"
                    control={<Radio color="primary" />}
                    label="Limited"
                />
                {value.type === 'limited' && (
                    <Grid container>
                        <Grid item xs={10}>
                            <Box>
                                <Alert severity="info">
                                    For best results, the Lookback Window should be greater than or
                                    equal to the Post-Campaign Analysis Window.
                                </Alert>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box className={classes.lookbackWindowDaysBox}>
                                <Typography> Number of days</Typography>
                                <LookBackWindowDays
                                    value={value.lookback_window_days}
                                    onChange={onChange}
                                    error={showErrors && lookBackWindowDaysError}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </RadioGroup>
        </FormControl>
    );
};

export const ConversionLookBackWindowSummary = ({ data }) => {
    return (
        <React.Fragment>
            {data.type === 'limited' && !data.lookback_window_days && (
                <Typography color="error">Incomplete fields</Typography>
            )}
            {data.type === 'unlimited' && <Label>Unlimited</Label>}
            {data.type === 'limited' && data.lookback_window_days > 0 && (
                <React.Fragment>
                    <Label>Limited</Label>
                    <Typography color="textSecondary">{data.lookback_window_days} days</Typography>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const LookBackWindowDays = props => {
    const classes = useStyles();

    const handleChange = useMemo(() => {
        return _.debounce(props.onChange, 500);
    }, []);

    return (
        <TextFieldFormatter
            initialValue={props.value}
            onChange={value => handleChange({ field: 'lookback_window_days', value })}
            shouldAllowChange={v => !/[a-zA-Z-]/.test(v)}
            formatIn={v => {
                if (!v || v === 0) {
                    return '';
                }

                return numeral(v).format('0');
            }}
            formatOut={v => {
                return v ? numeral(v).value() : null;
            }}
            renderInput={({ value, onChange, onBlur }) => (
                <TextField
                    disableMargin
                    error={props.error}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={classes.lookbackWindowDaysTextField}
                />
            )}
        />
    );
};

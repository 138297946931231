import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import numeral from 'numeral';

const StandardInput = createReactClass({
    displayName: 'StandardInput',

    propTypes: {
        value: PropTypes.any,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        formatIn: PropTypes.func,
        formatOut: PropTypes.func,
        autocorrect: PropTypes.func,
        autocomplete: PropTypes.string,
        placeholder: PropTypes.string,
        shouldAllowInput: PropTypes.func,
        type: PropTypes.oneOf(['text', 'email', 'search', 'password']),
        className: PropTypes.string,
        id: PropTypes.string,
        disabled: PropTypes.bool,
    },

    getDefaultProps() {
        return {
            onChange: () => {},
            shouldAllowInput: () => true,
            autocorrect: value => value,
            formatIn: value => value,
            disabled: false,
            formatOut: string => string,
            id: null,
            className: '',
            type: 'text',
        };
    },

    getInitialState() {
        return {
            cachedValue: this.props.value,
            displayedString: this.props.formatIn(trim(this.props.value)),
            lastOut: this.props.value,
            mounted: false,
        };
    },

    UNSAFE_componentWillMount() {
        this.emitChange = _.debounce(() => {
            const cachedValue = this.props.formatOut(trim(this.state.displayedString));
            // set lastOut directly, re-render is not necessary
            this.state.lastOut = cachedValue;
            this.props.onChange(cachedValue);
        }, 150);
    },

    componentDidMount() {
        this.setState({
            mounted: true,
        });
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value && nextProps.value !== this.state.lastOut) {
            this.setState({
                cachedValue: nextProps.value,
                displayedString: nextProps.formatIn(trim(nextProps.value)),
                lastOut: nextProps.value,
            });
        }
    },

    shouldComponentUpdate(nextProps, nextState) {
        // Only ever update if what is displayed needs to be updated
        return (
            nextState.displayedString !== this.state.displayedString ||
            nextProps.disabled !== this.props.disabled
        );
    },

    select() {
        if (this.input) {
            this.input.select();
        }
    },

    focus() {
        if (this.input) {
            this.input.focus();
        }
    },

    onInputChange(event) {
        const domString = event.target.value;
        const nextValue = this.props.formatOut(trim(domString));

        if (this.props.shouldAllowInput(domString)) {
            this.setState({
                cachedValue: nextValue,
                displayedString: domString,
            });
            this.emitChange(nextValue);
        }
    },

    // On blur
    // 1) autocorrect internal value
    // If applicable:
    // 2) flush out all internal changes immediately to prepare for form submission
    // 3) format displayed text
    onInputBlur() {
        const correctedValue = this.props.autocorrect(this.state.cachedValue);
        // const correctedValue = this.props.autocorrect(event.target.value); // this fixed something?
        const correctedDisplayString = this.props.formatIn(trim(correctedValue));

        if (correctedDisplayString !== this.state.displayedString) {
            if (this.state.mounted) {
                this.setState({
                    displayedString: correctedDisplayString,
                });
            }
        }
        if (correctedValue !== this.state.cachedValue) {
            this.setState(
                {
                    cachedValue: correctedValue,
                },
                () => this.props.onChange(correctedValue)
            );
        }
    },

    render() {
        return (
            <input
                ref={input => {
                    this.input = input;

                    if (this.props.innerRef) {
                        this.props.innerRef(input);
                    }
                }}
                data-heap-event={this.props.heapEvent}
                data-ef-event={this.props.efEvent}
                className={classnames(
                    'ef4-standard-input',
                    { 'ef4-standard-input_disabled': this.props.disabled },
                    this.props.className
                )}
                onFocus={this.props.onFocus}
                autoComplete={this.props.autocomplete}
                disabled={this.props.disabled}
                placeholder={this.props.disabled ? null : this.props.placeholder}
                type={this.props.type}
                value={this.state.displayedString}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                id={this.props.id}
                style={this.props.style}
            />
        );
    },
});

export default StandardInput;

export function formatNumber(value) {
    if (!value) {
        return value;
    }

    return numeral(value).format('0,0');
}

export function unformatNumber(value) {
    if (!value) {
        return value;
    }

    return numeral().unformat(value);
}

export function allowMoney(input) {
    // Allow empty input
    if (!input) {
        return true;
    }
    // Check allowable characters
    if (!/^[\d,]*(\.\d{0,2})?$/g.test(input)) {
        return false;
    }
    // Check character length
    const [dollars, cents] = input.split('.');
    const numDollarsDigits = dollars.match(/\d/g).length;
    const numCentsDigits = cents ? cents.length : 0;
    return numDollarsDigits <= 9 && numCentsDigits <= 2;
}

export function numberToMoney(value) {
    if (!value) {
        return '';
    } else {
        const [dollars, cents] = value.toFixed(2).split('.');
        const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
        return `${formattedDollars}.${cents}`;
    }
}

export function moneyToNumber(string) {
    if (string === '') {
        return 0;
    } else {
        return parseFloat(string.replace(/,/g, ''));
    }
}

export function allowInts(input) {
    const isEmpty = input === '';
    const isUnsignNumber = /^\d+$/.test(input);
    const isNotUnsignInteger = !/(^\d+\.\d*$)/.test(input);
    const shouldAllow = isEmpty || (isUnsignNumber && isNotUnsignInteger);
    return shouldAllow;
}

export function intToString(value) {
    const exists = value !== undefined && value !== null;

    if (value === 0) {
        return '';
    } else if (exists) {
        return value.toString();
    } else {
        return '';
    }
}

export function stringToInt(string) {
    const isDigit = /^\d+$/.test(string);

    if (isDigit) {
        return +string;
    } else if (string === '') {
        return 0;
    } else {
        return string;
    }
}
export function trim(value) {
    if (!_.isString(value)) {
        return value;
    }
    return _.trim(value);
}

import React, { Fragment } from 'react';

import { Section, Image, List, ListItem, Layout, Paragraph } from '../modules/layout.js';
import Spacer from 'widgets-v5/spacer';

export default {
    category: 'Guides',
    topic: 'Getting Started',
    url: 'guides/getting-started',
    published: true,
    Component: class Article extends React.Component {
        constructor(props) {
            super(props);

            this.platform_organization = React.createRef();
            this.campaigns = React.createRef();
            this.ads = React.createRef();
            this.creatives = React.createRef();
        }

        render() {
            return (
                <Layout
                    title="Getting Started"
                    sidebarContent={[
                        {
                            title: 'Platform Organization',
                            link: this.platform_organization,
                        },
                        {
                            title: 'Campaigns',
                            link: this.campaigns,
                        },
                        {
                            title: 'Ads',
                            link: this.ads,
                        },
                        {
                            title: 'Creatives',
                            link: this.creatives,
                        },
                    ]}
                >
                    <Section link={this.platform_organization} title="Platform Organization">
                        <Paragraph>
                            EngageFront has a simple hierarchy for campaign management and
                            organization. To get started, there's three concepts you should
                            understand:
                        </Paragraph>
                        <Spacer />
                        <Image url="/images/help/platform-hierarchy.png" />
                    </Section>
                    <Section link={this.campaigns} title="Campaigns">
                        <Paragraph>
                            A campaign is an overarching "folder" of related ads, creatives, and
                            other resources with a common advertising goal.
                        </Paragraph>
                        <Paragraph>At the campaign level you can…</Paragraph>
                        <List>
                            <ListItem>
                                limit total spend by setting an overarching budget across ads
                            </ListItem>
                            <ListItem>setup the revenue model used in reporting</ListItem>
                            <ListItem>
                                turn on or off the entire campaign (overriding its ads' on/off
                                status)
                            </ListItem>
                        </List>
                    </Section>
                    <Section link={this.ads} title="Ads">
                        <Paragraph>
                            An ad is a single set of targeting parameters, with an associated budget
                            and billing rate. Ads must be created under a campaign. It is equivalent
                            to "line items" in some DSPs.
                        </Paragraph>
                        <Paragraph>At the ad level you can…</Paragraph>
                        <List>
                            <ListItem>specify the start and end dates of the ad flight</ListItem>
                            <ListItem>
                                setup bid rates, targeting parameters and audience segments to
                                target or suppress
                            </ListItem>
                            <ListItem>
                                assign one or more creatives to be displayed to the user when a bid
                                is won
                            </ListItem>
                        </List>
                    </Section>
                    <Section link={this.creatives} title="Creatives">
                        <Paragraph>
                            A creative is the set of resources — including any image or video
                            assets, HTML and JavaScript tags, third-party pixels — ready to be
                            delivered and displayed on a user's device. Creatives must be created
                            under a campaign.
                        </Paragraph>
                        <Paragraph>At the creative level you can…</Paragraph>
                        <List>
                            <ListItem>upload JavaScript tags from third-party ad servers</ListItem>
                            <ListItem>
                                upload image or video assets, and use EngageFront to generate simple
                                standard banners or video interstitials
                            </ListItem>
                            <ListItem>
                                add additional third-party impression pixels or click trackers
                            </ListItem>
                        </List>
                    </Section>
                </Layout>
            );
        }
    },
};

import React from 'react';
import map from 'lodash/map';
import isNaN from 'lodash/isNaN';
import includes from 'lodash/includes';
import filter from 'lodash/filter';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { browserHistory } from 'react-router';
import HealthIndicator from 'widgets-v5/health-indicator';
import ElapsedTimeProgressBar from 'widgets-v5/elapsed-time-progress-bar';
import LinearProgress from '@mui/material/LinearProgress';
import FlightIndicator from 'widgets-v5/flight-indicator';

import {
    formatTimeRangeInDaysHours,
    formatNumber_whole,
    formatNumber_currency,
    formatDate_short,
} from 'utils/formatting';

import { getDateProgressRelativeToRange } from 'common/date-calculator';

const getOverallStats = () => {
    return [
        {
            Cell: () => {
                return <Typography variant="h6">Campaign Overall Progress</Typography>;
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            Cell: ({ campaign }) => {
                return <FlightIndicator size="medium" label status={campaign.status} />;
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            Cell: ({ startAsDateObj, endAsDateObj, campaign }) => {
                return (
                    <ElapsedTimeProgressBar
                        type="campaign"
                        status={campaign.status}
                        startDate={startAsDateObj}
                        endDate={endAsDateObj}
                        labelLeft={formatDate_short(startAsDateObj, campaign.default_timezone)}
                        labelRight={formatDate_short(endAsDateObj, campaign.default_timezone)}
                        primaryTimezone={campaign.default_timezone}
                        secondaryTimezone={campaign.default_timezone}
                    />
                );
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            Cell: ({ itemsForProgressStats }) => {
                return map(itemsForProgressStats, item => (
                    <div key={item.name}>
                        <Typography variant="h6">
                            {item.name}: {item.value}
                        </Typography>
                    </div>
                ));
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            Cell: ({ campaignId, handleRowClick }) => {
                return (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            handleRowClick(`/campaigns/${campaignId}/progress/overall`);
                        }}
                    >
                        View progress
                    </Button>
                );
            },
            maxWidth: 70,
            sticky: 'left',
        },
    ];
};

const getColumns = () => {
    return [
        {
            header: 'Title',
            id: 'title',
            Cell: ({ ad, campaign }) => {
                return (
                    <div>
                        {ad.status === 'pending' ? (
                            <div>clock</div>
                        ) : campaign.budget_allocation_method === 'manual' ? (
                            <HealthIndicator status={ad.health} />
                        ) : null}
                        #{ad.id} {ad.name}
                    </div>
                );
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            header: 'Status',
            id: 'status',
            Cell: ({ ad }) => {
                return <FlightIndicator size="medium" label status={ad.status} />;
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            header: 'Progress',
            id: 'progress',
            Cell: ({ campaign, ad, elapsedTime, campaignStart, campaignEnd }) => {
                return (
                    campaign.budget_allocation_method === 'manual' && (
                        <ElapsedTimeProgressBar
                            type="ad-row"
                            deliveryHealth={ad.health}
                            status={ad.status}
                            deliveryProgress={(ad.totalDelivered / ad.maxTotalDelivery) * 100}
                            campaignFlight={{ campaignStart, campaignEnd }}
                            startDate={new Date(ad.start)}
                            endDate={new Date(ad.end)}
                            offsetLeft={elapsedTime.start}
                            offsetRight={elapsedTime.end}
                            labelLeft={`${formatTimeRangeInDaysHours({
                                start: ad.start,
                                end: ad.end,
                            })} booked`}
                        />
                    )
                );
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            header: 'Stats',
            id: 'stats',
            Cell: ({ ad, getGoalDeliveryString, campaign, getRemainingDeliveryString }) => {
                return (
                    <div>
                        {ad.totalTarget !== 0 ? (
                            <span>
                                <div>{getGoalDeliveryString(ad.totalDelivered, ad.goal)}</div>
                                {campaign.budget_allocation_method === 'manual' && (
                                    <div>
                                        {getRemainingDeliveryString(
                                            ad.totalTarget,
                                            ad.totalDelivered,
                                            ad.goal
                                        )}{' '}
                                        / {createBookedDeliveryString(ad.totalTarget, ad.goal)}
                                    </div>
                                )}
                            </span>
                        ) : null}
                        {ad.dailyCap ? (
                            <span>
                                <div>{createBookedDeliveryString(ad.dailyCap, ad.goal)} today</div>
                                <div>
                                    {getGoalDeliveryString(ad.dailyDelivered, ad.goal)} today /{' '}
                                    {getRemainingDeliveryString(
                                        ad.dailyCap,
                                        ad.dailyDelivered,
                                        ad.goal
                                    )}{' '}
                                    remaining today
                                </div>
                            </span>
                        ) : null}
                    </div>
                );
            },
            maxWidth: 70,
            sticky: 'left',
        },
        {
            Header: 'Actions',
            id: 'actions',
            Cell: ({ ad, campaignId, handleAdRowClick }) => {
                return (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                            handleAdRowClick(`/campaigns/${campaignId}/progress/${ad.id}`)
                        }
                    >
                        View progress
                    </Button>
                );
            },
            maxWidth: 70,
            sticky: 'left',
        },
    ];
};

function CampaignOverallProgress(props) {
    const handleRowClick = url => {
        browserHistory.push(url);
    };

    const handleAdRowClick = url => {
        props.onClick();
        browserHistory.push(url);
    };

    const getGoalDeliveryString = (total, goal) => {
        if (isNaN(total) || !total) {
            return '-';
        }
        return createGoalDeliveryString(total, goal);
    };

    const getRemainingDeliveryString = (cap, delivered, goal) => {
        if (!delivered) {
            return '-';
        }

        if (goal === 'spend' || goal === 'billings' || goal === 'revenue') {
            return `${formatNumber_currency(Math.max(0, cap - delivered))} remaining`;
        }

        return `${formatNumber_whole(Math.max(0, cap - delivered))} remaining`;
    };

    const { campaignId, ads, flight, dataForOverallGraph, campaign, isLoading } = props;

    const { start, end } = flight;

    const validStatTypes = ['impressions', 'clicks', 'spend', 'revenue', 'billings'];

    const itemsForProgressStats = filter(dataForOverallGraph, item => {
        const name = formatNameToSimpleString(item.name);
        return includes(validStatTypes, name);
    });

    const startAsDateObj = new Date(start);
    const endAsDateObj = new Date(end);

    const campaignStart = start;
    const campaignEnd = end;

    const elapsedTime = {
        start:
            getDateProgressRelativeToRange({ start: campaignStart, end: campaignEnd }, start) * 100,
        end:
            100 -
            getDateProgressRelativeToRange({ start: campaignStart, end: campaignEnd }, end) * 100,
    };

    const columns = getColumns(ads, campaign, campaignId, elapsedTime);
    const overallStats = getOverallStats();

    return (
        <Paper>
            <Table stickyHeader>
                {isLoading ? <LinearProgress /> : null}
                <TableHead>
                    <TableRow>
                        {map(columns, column => (
                            <TableCell>{column.header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {map(overallStats, stat => (
                            <TableCell>
                                {stat.Cell({
                                    startAsDateObj,
                                    endAsDateObj,
                                    campaign,
                                    itemsForProgressStats,
                                    campaignId,
                                    handleRowClick,
                                })}
                            </TableCell>
                        ))}
                    </TableRow>
                    {map(ads, ad => (
                        <TableRow>
                            {map(columns, column => (
                                <TableCell component="th" scope="row">
                                    {column.Cell({
                                        ad,
                                        campaign,
                                        campaignId,
                                        elapsedTime,
                                        campaignStart,
                                        campaignEnd,
                                        getGoalDeliveryString,
                                        getRemainingDeliveryString,
                                        handleAdRowClick,
                                    })}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default CampaignOverallProgress;

export function createGoalDeliveryString(total, goal) {
    if (isNaN(total) || !total) {
        if (goal === 'billings') {
            return `Revenue: $0`;
        }

        return goal === 'spend' || goal === 'revenue' ? '$0 spent' : '0 delivered';
    }

    if (goal === 'billings') {
        return `Revenue: ${formatNumber_currency(total)}`;
    }

    if (goal === 'spend' || goal === 'revenue') {
        return `${formatNumber_currency(total)} spent`;
    }

    return `${formatNumber_whole(total)} delivered`;
}

export function createRemainingDeliveryString(cap, delivered, goal) {
    if (!delivered) {
        return goal === 'spend' || goal === 'billings' || goal === 'revenue'
            ? `${formatNumber_currency(cap)}`
            : `${formatNumber_whole(cap)}`;
    }

    if (goal === 'spend' || goal === 'billings' || goal === 'revenue') {
        return `${formatNumber_currency(Math.max(0, cap - delivered))}`;
    }

    return `${formatNumber_whole(Math.max(0, cap - delivered))}`;
}

export function createBookedDeliveryString(target, goal) {
    if (goal === 'billings') {
        return `${formatNumber_currency(target)} booked`;
    }

    if (goal === 'spend' || goal === 'revenue') {
        return `${formatNumber_currency(target)} booked`;
    }

    return `${formatNumber_whole(target)} ${goal} booked`;
}

function formatNameToSimpleString(name) {
    const formattedName = name.split(' ')[0];
    const nameInLowerCase = formattedName.toLowerCase();
    return nameInLowerCase;
}

import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import notify from 'utils/notify';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import Chip from '@mui/material/Chip';
import CreativeIcon from './modules/creative-icons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import withStyles from '@mui/styles/withStyles';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
/** import mui components in the section above, keep the ascending order */

import CreativeSimulator from 'widgets/simulator';
import { BlockLoadGroup } from 'widgets-v5/load-group';
/** import widgets components in the section above, keep the ascending order */

import { getEnvironmentSettings } from 'services/environment';
import {
    getAudioSizes,
    getAudios,
    getFormats,
    getPlacements,
    getPlatforms,
    getPositions,
    getSizes,
    getTypes,
} from './constants';
import { VENDOR_OPTIONS_BY_CREATIVE_TYPE, TAGS } from 'common/constants/vendors';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import actions from './actions';
import asyncActions from './async-actions';
import CreativeEditConfirmation from './modules/creative-edit-confirmation';
import CreativeFormMraidType from './modules/mraid-type-form/mraid-type-form';
import CreativeFormNativeType from './modules/native-type-form/native-type-form';
import CreativeFormStandardType from './modules/standard-type-form/standard-type-form';
import CreativeFormThirdPartyVastType from './modules/third-party-vast-type-form/third-party-vast-type-form';
import CreativeFormVideoType from './modules/video-type-form/video-type-form';
import Label from './modules/label';
import selector from './selector';
import flags from 'containers/flags/service';
/** import classes and others in the section above, keep the ascending order */

export function mergeVendorOptions({ creativeType, vendorsFromDraft, tagsMatched, vendorType }) {
    const baseVendors = _.map(VENDOR_OPTIONS_BY_CREATIVE_TYPE[creativeType], x => x.value);

    const customVendors = _.difference(vendorsFromDraft, baseVendors);

    const commonVendors = [];
    _.forEach(VENDOR_OPTIONS_BY_CREATIVE_TYPE[creativeType], option => {
        _.forEach(vendorType, vendor => {
            if (option.value === vendor.value) {
                commonVendors.push(option);
            }
        });
    });

    return _(commonVendors)
        .concat(_.map(customVendors, v => ({ value: v, label: v })))
        .concat(tagsMatched)
        .uniqBy(vendor => vendor.label)
        .sortBy(vendor => vendor.label)
        .value();
}

const ORGS_WITH_3RD_PARTY_VAST = [
    '53271100d26f9a2f917ed289',
    '53271100d26f9a2f917ed290',
    '57ed40c029c4b61f7a59c488',
    '5abd1ca87dbf1e063e96ca49',
];

export const ENABLED_CLICKTRACKING_MESSAGE = (
    <Typography variant="body2">
        Use the macro <MacroHighlight>%%CLICKTHROUGH%%</MacroHighlight> in the HTML Markup to
        provide the EngageFront Clicktracker URL to the third-party ad server. Use{' '}
        <MacroHighlight>%%CLICKTHROUGH_ESC%%</MacroHighlight> for a URL-escaped version.
    </Typography>
);
export const DISABLED_CLICKTRACKING_MESSAGE = (
    <Typography variant="body2">
        Use the macro <MacroHighlight>%%REDIRECT%%</MacroHighlight> in the HTML Markup to provide
        the EngageFront Clicktracker URL to the third-party ad server. Use{' '}
        <MacroHighlight>%%REDIRECT_ESC%%</MacroHighlight> for a URL-escaped version.
    </Typography>
);

const getCreativeTrackingVendorsMapping = () => {
    let mapping = {};

    _.forEach(TAGS, tag => {
        mapping[tag.value] = tag.label;
    });
    return mapping;
};

export const creativeTrackingVendorsMapping = getCreativeTrackingVendorsMapping();

const styles = theme => ({
    label: {
        marginBottom: 10,
    },
    chip: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        padding: '10px 40px',
        position: 'relative',

        '&.disabledIcon': {
            cursor: 'not-allowed',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&:active, &:focus': {
                boxShadow: 'none',
                backgroundColor: 'transparent',
            },
        },

        '& .MuiChip-label': {
            paddingBottom: 2,
        },
    },
    chipIcon: {
        fontSize: 45,
        height: 45,
        margin: 10,
    },
    icon: {
        padding: '15px 0px',
        margin: 0,
    },
    checkIcon: {
        fontSize: 20,
        top: 10,
        position: 'absolute',
        left: 15,
    },
    activeDisabledIcon: {
        color: theme.palette.primary[200],
    },
    disabledIcon: {
        color: theme.palette.grey[300],
    },
});

const useStyles = makeStyles(() => ({
    nameField: {
        minWidth: 600,
    },
    nameFieldInput: {
        padding: 12,
    },
    videoPreview: props => ({
        maxWidth: 70,
        backgroundImage: `url("${props.previewImage}")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }),
    timeline: {
        '&:before': {
            content: 'none',
        },
    },
    timelineDotMargin: {
        marginLeft: 10,
    },
    macros: {
        backgroundColor: 'yellow',
    },
    link: {
        background: '#f9f9f9',
        padding: 10,
        font:
            "12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace",
        lineHeight: '18px',
        marginBottom: 10,
        wordBreak: 'break-all',
    },
}));

class CreativeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: props.creativeId ? 1 : 0,
            completed: {},
            errorStep: [],
            expandedPanel: '',
            expandedExamples: false,
            expandedExample: '',
            hideUploadAssetsErrorMessage: false,
        };

        this.stepsMapping = {
            '0': {
                isValid: () => {
                    const { errors } = this.props;

                    let requiredFields = [
                        'name',
                        'platforms',
                        'type',
                        'format',
                    ];

                    let nonDoohCampaignRequired = [
                        'placements',
                        'positions',
                        'size',
                    ];

                    let mergedRequiredFields = requiredFields.slice(0);
                    if (props.campaignType !== CampaignTypeMapping.DOOH) {
                        mergedRequiredFields = mergedRequiredFields.concat(requiredFields, nonDoohCampaignRequired);
                    }

                    const hasFieldErrors = _.keys(_.pick(errors, mergedRequiredFields)).length > 0;

                    if (hasFieldErrors) {
                        return false;
                    }

                    return true;
                },
            },
            '1': {
                isValid: () => {
                    const { errors } = this.props;

                    const hasFieldErrors = _.keys(errors).length > 0;

                    if (hasFieldErrors) {
                        return false;
                    }

                    return true;
                },
            },
        };
    }

    static defaultProps = {
        draft: {},
        errors: {},
        showErrors: false,
        assets: [],
        warnings: [],
    };

    componentDidMount() {
        const { getInitialDraft, campaignId, creativeId, dispatch, campaignType } = this.props;
        dispatch(actions.open(getInitialDraft, campaignId, creativeId, window.location, campaignType));
    }

    uploadFile = formData => {
        const { campaignId, uploadFile } = this.props;

        this.setState({ hideUploadAssetsErrorMessage: false });

        uploadFile(formData, campaignId);
    };

    onSelectAssetWithWarnings = errors => {
        this.setState({ showCTVFileUploadWarning: !_.isEmpty(errors) });
    };

    handleFieldChange = fieldName => fieldValue => {
        const { campaignId, creativeId, dispatch } = this.props;
        const newFormData = {
            [fieldName]: fieldValue,
        };

        dispatch(actions.updateDraft(newFormData, campaignId, creativeId));
    };

    handleUpdateVendors = (value, type) => {
        if (type === 'creative_vendors') {
            this.handleFieldChange('isCreativeVendorAutoDetected')(false);
            this.handleFieldChange('creative_vendors')(value);
        } else if (type === 'tracking_vendors') {
            this.handleFieldChange('isTrackingVendorsAutoDetected')(false);
            this.handleFieldChange('tracking_vendors')(value);
        }
    };

    handleMultiFieldChange = newFormData => {
        const { campaignId, creativeId, dispatch } = this.props;
        dispatch(actions.updateDraft(newFormData, campaignId, creativeId));
    };

    handleSave = () => {
        const { campaignId, creativeId, dispatch } = this.props;

        dispatch(actions.submitDraft(campaignId, creativeId)).then(
            draft => {
                this.props.submit(draft).then(null, error => {
                    dispatch(actions.showServerErrors(error));
                });
            },
            () => {
                console.log('Creative form draft failed to submit');
            }
        );
    };

    validateDraft = ({ isSaveAudit }) => {
        const { campaignId, creativeId, isSubmitting, dispatch } = this.props;

        if (isSubmitting) {
            return;
        }
        try {
            dispatch(
                actions.validateDraft({
                    campaignId,
                    creativeId,
                    onValidDraft: isSaveAudit ? this.onSaveAndAudit : this.handleSave,
                    isSaveAudit,
                })
            );
        } catch (error) {
            notify({ error, metaData: { campaignId, creativeId } });
        }
    };

    onSaveAndAudit = () => {
        const { campaignId, creativeId, dispatch } = this.props;

        dispatch(actions.submitDraftAndAudit(campaignId, creativeId)).then(
            draft => {
                return this.props.submitAndAudit(draft).then(null, error => {
                    dispatch(actions.showServerErrors(error));
                });
            },
            () => {
                console.log('Creative form draft failed to submit');
            }
        );
    };

    changeSizeSelectorItems = value => {
        switch (value) {
            case 'standard':
                return [
                    {
                        value: '320x50',
                        label: '320 x 50 (Mobile - Banner)',
                    },
                    {
                        value: '300x50',
                        label: '300 x 50 (Mobile - Banner)',
                    },
                    {
                        value: '320x480',
                        label: '320 x 480 (Mobile - Interstitial)',
                    },
                    {
                        value: '480x320',
                        label: '480 x 320 (Mobile - Interstital)',
                    },
                    {
                        value: '728x90',
                        label: '728 x 90 (Tablet - Banner)',
                    },
                    {
                        value: '768x1024',
                        label: '768 x 1024 (Tablet - Interstital)',
                    },
                    {
                        value: '1024x768',
                        label: '1024 x 768 (Tablet - Interstitial)',
                    },
                    {
                        value: '300x250',
                        label: '300 x 250 (Banner / Interstitial)',
                    },
                ];
            case 'mraid':
            case 'custom_html':
                return [
                    {
                        value: '320x50',
                        label: '320 x 50 (Mobile - Banner)',
                    },
                    {
                        value: '300x50',
                        label: '300 x 50 (Mobile - Banner)',
                    },
                    {
                        value: '320x480',
                        label: '320 x 480 (Mobile - Interstitial)',
                    },
                    {
                        value: '480x320',
                        label: '480 x 320 (Mobile - Interstital)',
                    },
                    {
                        value: '728x90',
                        label: '728 x 90 (Tablet - Banner)',
                    },
                    {
                        value: '768x1024',
                        label: '768 x 1024 (Tablet - Interstital)',
                    },
                    {
                        value: '1024x768',
                        label: '1024 x 768 (Tablet - Interstitial)',
                    },
                    {
                        value: '300x250',
                        label: '300 x 250 (Banner / Interstitial)',
                    },
                ];
            case 'third_party_vast':
            case 'video':
                return [
                    {
                        value: '320x480',
                        label: '320 X 480 (Mobile - Interstitial)',
                    },
                    {
                        value: '480x320',
                        label: '480 X 320 (Mobile - Interstitial)',
                    },
                    {
                        value: '768x1024',
                        label: '768 X 1024 (Tablet - Interstitial)',
                    },
                    {
                        value: '1024x768',
                        label: '1024 X 768 (Tablet - Interstitial)',
                    },
                ];
            default:
                return [];
        }
    };

    handleTogglePlatform = platform => {
        if (this.isEditMode()) {
            return;
        }

        this.props.dispatch(actions.togglePlatform(platform));
    };

    handleChangeName = event => {
        this.props.dispatch(actions.changeName(event.target.value));
    };

    handleChangeType = type => {
        if (this.isEditMode()) {
            return;
        }

        this.props.dispatch(actions.changeType(type));
    };

    handleTogglePlacement = placement => {
        this.props.dispatch(actions.togglePlacement(placement));
    };

    handleTogglePosition = position => {
        this.props.dispatch(actions.togglePosition(position));
    };

    handleChangeSize = size => {
        if (this.props.campaignType !== CampaignTypeMapping.DOOH && this.isEditMode()) {
            return;
        }

        this.props.dispatch(actions.changeSize(size));
    };

    handleChangeAudio = audio_required => {
        this.props.dispatch(actions.changeAudio(audio_required));
    };

    handleChangeFormat = format => {
        if (this.isEditMode()) {
            return;
        }

        this.props.dispatch(actions.changeFormat(format));
    };

    isEditMode = () => {
        const { creativeId } = this.props;

        return !!creativeId;
    };

    getSteps = () => {
        return ['Choose a format', 'Setup Creative'];
    };

    handleNextStep = step => {
        const environmentSettings = getEnvironmentSettings();

        if (!this.stepsMapping[step].isValid()) {
            this.props.dispatch(actions.showErrors());
            return;
        }

        this.props.dispatch(
            actions.nextStep({
                creativePlatformRestrictions: environmentSettings.getCreativePlatformRestrictions(),
            })
        );

        this.setState({ activeStep: step + 1 });
    };

    handleBackStep = step => {
        this.setState({
            activeStep: step - 1,
            showCTVFileUploadWarning: false,
            hideUploadAssetsErrorMessage: true,
        });

        if (!this.isEditMode()) {
            const { campaignId, creativeId, dispatch, campaignType } = this.props;

            dispatch(actions.back(campaignId, creativeId, campaignType));
        }
    };

    generateReferenceImage = () => {
        const { draft, dispatch, campaignId } = this.props;
        const { content_html, renderDelay } = draft;

        dispatch(asyncActions.generateReferenceImage(content_html, campaignId, renderDelay));
    };

    handleChangeRenderDelay = value => {
        this.handleFieldChange('renderDelay')(value);
    };

    handleChangeAssetSelectionMethod = e => {
        const { dispatch } = this.props;
        dispatch(actions.changeAssetSelectionMethod(e.target.value));
    };

    handleZipUpload = formData => {
        const { dispatch, campaignId } = this.props;
        dispatch(asyncActions.processUploadedZip(formData, campaignId));
    };

    handleCloseEditConfirmationModal = () => {
        const { dispatch } = this.props;
        dispatch(actions.closeEditConfirmationModal());
    };

    expandPanel = panel => {
        const { uploadAssetsErrorMessage } = this.props;
        return () => {
            if (this.state.expandedPanel === panel) {
                this.setState({ expandedPanel: '' })
                return;
            }

            if (!_.isEmpty(uploadAssetsErrorMessage)) {
                this.setState({ hideUploadAssetsErrorMessage: true });
            }

            this.setState({ expandedPanel: panel });
        };
    };

    expandExamples = () => {
        return this.setState({
            expandedExamples: !this.state.expandedExamples,
        });
    };

    expandExample = example => {
        return () => {
            this.state.expandedExample === example
                ? this.setState({
                      expandedExample: '',
                  })
                : this.setState({
                      expandedExample: example,
                  });
        };
    };

    getCreativeMapping = () => {
        let mapping = {};

        _.forEach(this.props.assets, asset => {
            mapping[asset.attr.preview_url] = asset.attr.name;
        });

        return mapping;
    };

    render() {
        const {
            currentOrg,
            draft,
            errors,
            showErrors,
            isReferenceImageLoading,
            isZipUploadLoading,
            areAssetsLoading,
            isEditConfirmationModalOpen,
            confirmModalMessage,
            isEditConfirmSaveAndAudit,
            isCreativeVendorAutoDetected,
            isTrackingVendorsAutoDetected,
            showHTMLblock,
            warnings,
            campaignType,
            uploadAssetsErrorMessage,
        } = this.props;
        const { activeStep } = this.state;
        if (this.props.isLoading) {
            return null;
        }

        let rightSideContent;

        const creativeMapping = this.getCreativeMapping();

        let clicktrackingMessage;

        if (draft.third_party_clickthrough) {
            clicktrackingMessage = ENABLED_CLICKTRACKING_MESSAGE;
        } else {
            clicktrackingMessage = DISABLED_CLICKTRACKING_MESSAGE;
        }

        const sizeSelectorItems = this.changeSizeSelectorItems(this.props.draft.format);
        const creativeTypeProps = {
            uploadFile: this.uploadFile,
            draft: this.props.draft,
            assets: this.props.assets,
            onFieldChange: fieldName => this.handleFieldChange(fieldName),
            updateVendors: this.handleUpdateVendors,
            onMultiFieldChange: this.handleMultiFieldChange,
            errors: this.props.errors,
            mode: this.props.mode,
            creativeTagsMatched: this.props.creativeTagsMatched,
            trackingTagsMatched: this.props.trackingTagsMatched,
            showErrors: this.props.showErrors,
            activeStep: this.state.activeStep,
            areAssetsLoading: areAssetsLoading,
            expandPanel: this.expandPanel,
            expandedPanel: this.state.expandedPanel,
            expandedExamples: this.state.expandedExamples,
            expandExamples: this.expandExamples,
            expandExample: this.expandExample,
            expandedExample: this.state.expandedExample,
            creativeMapping: creativeMapping,
            clicktrackingMessage: clicktrackingMessage,
            onSelectAssetWithWarnings: this.onSelectAssetWithWarnings,
            uploadAssetsErrorMessage,
            hideUploadAssetsErrorMessage: this.state.hideUploadAssetsErrorMessage,
            campaignType,
        };

        switch (this.props.draft.format) {
            case 'standard':
                rightSideContent = (
                    <CreativeFormStandardType
                        {...creativeTypeProps}
                        onChangeAssetSelectionMethod={this.handleChangeAssetSelectionMethod}
                    />
                );
                break;
            case 'mraid':
            case 'custom_html':
                rightSideContent = (
                    <CreativeFormMraidType
                        {...creativeTypeProps}
                        isReferenceImageLoading={isReferenceImageLoading}
                        isZipUploadLoading={isZipUploadLoading}
                        generateReferenceImage={this.generateReferenceImage}
                        handleZipUpload={this.handleZipUpload}
                        onChangeRenderDelay={this.handleChangeRenderDelay}
                        onChangeAssetSelectionMethod={this.handleChangeAssetSelectionMethod}
                        renderDelay={draft.renderDelay}
                        isCreativeVendorAutoDetected={isCreativeVendorAutoDetected}
                        isTrackingVendorsAutoDetected={isTrackingVendorsAutoDetected}
                        showHTMLblock={showHTMLblock}
                    />
                );
                break;
            case 'native':
                rightSideContent = (
                    <CreativeFormNativeType
                        {...creativeTypeProps}
                        onChangeAssetSelectionMethod={this.handleChangeAssetSelectionMethod}
                    />
                );
                break;
            case 'video':
                rightSideContent = (
                    <CreativeFormVideoType
                        {...creativeTypeProps}
                        onChangeAssetSelectionMethod={this.handleChangeAssetSelectionMethod}
                    />
                );
                break;
            case 'third_party_vast':
                rightSideContent = <CreativeFormThirdPartyVastType {...creativeTypeProps} />;
                break;
            default:
                null;
        }

        let hasAcceptedSize = _.reduce(
            sizeSelectorItems,
            (result, item) => {
                return item.value === this.props.draft.size ? true : result;
            },
            false
        );

        const isNativeFormat = this.props.draft.format === 'native';

        let showSimulator;

        if (this.props.draft.format === 'third_party_vast') {
            showSimulator = false;
        } else {
            showSimulator = hasAcceptedSize || isNativeFormat;
        }

        hasAcceptedSize = !!draft.size;

        const formatOptions = [
            {
                value: 'standard',
                label: 'Standard',
            },
            {
                value: 'video',
                label: 'Video Interstitial',
            },
            {
                value: 'custom_html',
                label: 'Rich Media',
            },
            {
                value: 'mraid',
                label: 'MRAID Rich Media',
            },
            {
                value: 'native',
                label: 'Native',
            },
        ];

        if (_.includes(ORGS_WITH_3RD_PARTY_VAST, currentOrg)) {
            formatOptions.push({
                value: 'third_party_vast',
                label: '3rd Party Hosted VAST',
            });
        }

        const environmentSettings = getEnvironmentSettings();

        const steps = this.getSteps();

        const platforms = _.filter(getPlatforms(), platform => {
            if (platform.allowedOnCampaignType !== campaignType) {
                return false;
            }

            return !_.includes(
                environmentSettings.getCreativePlatformRestrictions(),
                platform.value
            );
        });

        const types = getTypes(draft.platforms, draft.exporter, campaignType);
        const placements = getPlacements(draft.platforms, campaignType);
        const positions = getPositions(campaignType);
        const sizes = draft.audio_required && flags.isEnabled('ctef_178_add_new_audio_required_field') ? getAudioSizes(draft.platforms, draft.type, draft.audio_required) : getSizes(draft.platforms, draft.type, draft.placements);
        const audios = flags.isEnabled('ctef_178_add_new_audio_required_field') ? getAudios(draft.platforms, draft.type) : [];

        const formats = getFormats({
            platforms: draft.platforms,
            type: draft.type,
            exporter: draft.exporter,
        });
        const categorizedFormats = _.groupBy(types, 'category');

        return (
            <Dialog fullScreen open={true} onClose={this.props.closeAction}>
                <AppBar position="relative">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.props.closeAction}
                            aria-label="close"
                            size="large"
                        >
                            <Icon className="fa fa-times" />
                        </IconButton>
                        <DialogTitle>Creative Form</DialogTitle>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <BlockLoadGroup size="large" isLoading={this.props.isLoading}>
                        <React.Fragment>
                            <Stepper activeStep={this.state.activeStep}>
                                {_.map(steps, (label, index) => {
                                    const isStepValid = this.stepsMapping[index].isValid();
                                    const isStepActive = this.state.activeStep === index;
                                    const errorStep = showErrors && !isStepValid && isStepActive;

                                    return (
                                        <Step key={label}>
                                            {errorStep ? (
                                                <StepLabel error={errorStep}>
                                                    Resolve errors
                                                </StepLabel>
                                            ) : (
                                                <StepLabel error={errorStep}>{label}</StepLabel>
                                            )}
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {warnings.length !== 0 &&
                                warnings.map(warning => (
                                    <WarningText
                                        title={warning.title}
                                        severity={warning.severity}
                                        message={warning.message}
                                        key={warning.title}
                                    />
                                ))}
                            {this.state.activeStep === 0 && (
                                <div>
                                    <NameField
                                        required
                                        error={this.props.showErrors && this.props.errors.name}
                                        label="Name"
                                        value={this.props.draft.name}
                                        onChange={this.handleChangeName}
                                    />
                                    <FormControl
                                        required
                                        fullWidth
                                        margin="normal"
                                        error={showErrors && !!errors.platforms}
                                    >
                                        <Label component="legend" required={true}>
                                            Platform(s)
                                        </Label>
                                        <FormGroup row>
                                            {_.map(platforms, platform => (
                                                <ChipSelector
                                                    key={platform.value}
                                                    icon
                                                    label={platform.label}
                                                    optionType={platform.icon}
                                                    active={_.includes(
                                                        draft.platforms,
                                                        platform.value
                                                    )}
                                                    onClick={() =>
                                                        this.handleTogglePlatform(platform.value)
                                                    }
                                                    multipleSelect
                                                    disabled={this.isEditMode()}
                                                />
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                    {draft.platforms && draft.platforms.length > 0 && (
                                        <FormControl
                                            fullWidth
                                            margin="normal"
                                            error={showErrors && !!errors.type}
                                        >
                                            <Label component="legend" required={true}>
                                                Format
                                            </Label>
                                            <FormGroup row>
                                                {_.map(categorizedFormats, (types, category) => (
                                                    <div
                                                        key={category}
                                                        className="ef3-creativeForm__format-type"
                                                    >
                                                        <div className="ef3-creativeForm__format-type-line">
                                                            {category}
                                                        </div>
                                                        <FormGroup row>
                                                            {_.map(types, type => (
                                                                <ChipSelector
                                                                    key={type.value}
                                                                    icon
                                                                    label={type.label}
                                                                    optionType={type.icon}
                                                                    active={
                                                                        draft.type === type.value
                                                                    }
                                                                    onClick={() =>
                                                                        this.handleChangeType(
                                                                            type.value
                                                                        )
                                                                    }
                                                                    disabled={this.isEditMode()}
                                                                />
                                                            ))}
                                                        </FormGroup>
                                                    </div>
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    )}
                                    {placements.length &&
                                       draft.type === 'video' ? (
                                        <FormControl
                                            fullWidth
                                            margin="normal"
                                            error={showErrors && !!errors.placements}
                                        >
                                            <Label component="legend" required={true}>
                                                Placement(s)
                                            </Label>
                                            <FormGroup row>
                                                {_.map(placements, placement => (
                                                    <ChipSelector
                                                        key={placement.value}
                                                        icon
                                                        label={placement.label}
                                                        active={_.includes(
                                                            draft.placements,
                                                            placement.value
                                                        )}
                                                        onClick={() =>
                                                            this.handleTogglePlacement(
                                                                placement.value
                                                            )
                                                        }
                                                        optionType={placement.icon}
                                                        multipleSelect
                                                        disabled={false}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    ) : null}
                                    {positions.length &&
                                        draft.type === 'video' &&
                                        _.includes(draft.placements, 'in_stream') ? (
                                            <FormControl
                                                fullWidth
                                                margin="normal"
                                                error={showErrors && !!errors.positions}
                                            >
                                                <Label component="legend" required={true}>
                                                    Position(s)
                                                </Label>
                                                <FormGroup row>
                                                    {_.map(positions, position => (
                                                        <ChipSelector
                                                            key={position.value}
                                                            label={
                                                                <Typography variant="subtitle2">
                                                                    {position.label}
                                                                </Typography>
                                                            }
                                                            active={_.includes(
                                                                draft.positions,
                                                                position.value
                                                            )}
                                                            onClick={() =>
                                                                this.handleTogglePosition(
                                                                    position.value
                                                                )
                                                            }
                                                            disabled={false}
                                                            multipleSelect
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        ) : null}
                                    {draft.type && audios.length > 0 ? (
                                        <FormControl
                                            fullWidth
                                            margin="normal"
                                            error={showErrors && !!errors.audio_required}
                                        >
                                            <Label component="legend" required={true}>
                                                Audio
                                            </Label>
                                            <FormGroup row>
                                                {_.map(audios, audio => (
                                                    <ChipSelector
                                                        key={audio.value}
                                                        label={
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    {audio.label}
                                                                </Typography>
                                                            </div>
                                                        }
                                                        active={draft.audio_required === audio.value}
                                                        onClick={() =>
                                                            this.handleChangeAudio(audio.value)
                                                        }
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    ) : null}
                                    {(((audios && audios.length && draft.audio_required !== null) && draft.type && sizes.length > 0) ||
                                       ((!audios || !audios.length) && draft.type && sizes.length)) ? (
                                        <FormControl
                                            fullWidth
                                            margin="normal"
                                            error={showErrors && !!errors.size}
                                        >
                                            <Label component="legend" required={true}>
                                                Size
                                            </Label>
                                            <FormGroup row>
                                                {_.map(sizes, size => (
                                                    <ChipSelector
                                                        key={size.value}
                                                        label={
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    {size.label}
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    {_.map(
                                                                        size.content,
                                                                        content => (
                                                                            <ChipContent
                                                                                key={
                                                                                    content.message
                                                                                }
                                                                                message={
                                                                                    content.message
                                                                                }
                                                                                icon={content.icon}
                                                                                active={
                                                                                    draft.size ===
                                                                                    size.value
                                                                                }
                                                                                disabled={campaignType !== CampaignTypeMapping.DOOH && this.isEditMode()}
                                                                            />
                                                                        )
                                                                    )}
                                                                </Typography>
                                                            </div>
                                                        }
                                                        active={draft.size === size.value}
                                                        onClick={() =>
                                                            this.handleChangeSize(size.value)
                                                        }
                                                        disabled={campaignType !== CampaignTypeMapping.DOOH && this.isEditMode()}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    ) : null}
                                    {draft.type && (
                                        <FormControl
                                            fullWidth
                                            margin="normal"
                                            error={showErrors && !!errors.format}
                                        >
                                            <Label component="legend" required={true}>
                                                Type
                                            </Label>
                                            <FormGroup row>
                                                {_.map(formats, format => (
                                                    <ChipSelector
                                                        key={format.value}
                                                        label={
                                                            <div>
                                                                <Typography variant="subtitle2">
                                                                    {format.label}
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    {_.map(
                                                                        format.content,
                                                                        content => (
                                                                            <ChipContent
                                                                                key={
                                                                                    content.message
                                                                                }
                                                                                icon="check"
                                                                                active={
                                                                                    draft.format ===
                                                                                    format.value
                                                                                }
                                                                                disabled={this.isEditMode()}
                                                                                message={
                                                                                    content.message
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Typography>
                                                            </div>
                                                        }
                                                        active={draft.format === format.value}
                                                        onClick={() =>
                                                            this.handleChangeFormat(format.value)
                                                        }
                                                        disabled={this.isEditMode()}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    )}
                                </div>
                            )}
                            {this.state.activeStep === 1 && (
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={this.props.draft.format === 'third_party_vast' ? 12 : 8}
                                        className="ef3-creativeForm__overflow"
                                    >
                                        <div
                                            className={classnames('ef3-creativeForm__right', {
                                                'is-active':
                                                    this.props.draft.format === 'native'
                                                        ? true
                                                        : this.props.draft.format &&
                                                          hasAcceptedSize,
                                            })}
                                        >
                                            {rightSideContent}
                                        </div>
                                    </Grid>
                                    {this.props.draft.format !== 'third_party_vast' && (
                                        <Grid item xs={12} sm={4}>
                                            <div>
                                                {showSimulator ? (
                                                    <CreativeSimulator
                                                        className="ef3-creativeForm__creative-simulator"
                                                        creative={this.props.draft}
                                                    />
                                                ) : null}
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </React.Fragment>
                        {this.state.showCTVFileUploadWarning && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                marginTop="20px"
                            >
                                <Alert
                                    severity="warning"
                                    onClose={() =>
                                        this.setState({ showCTVFileUploadWarning: false })
                                    }
                                >
                                    The selected video does not meet the video quality
                                    recommendations for Connected TV. For best results,{' '}
                                    <a
                                        href="https://engagefront.notion.site/Setting-up-Connected-TV-CTV-Campaigns-42642f8f286e4bb99e011e76682c7ea7#9fb883563e3a4f24bd6c31d2dd616606"
                                        target="_blank"
                                        title="Recommended Settings for CTV Videos"
                                    >
                                        View recommended settings
                                    </a>
                                    .
                                </Alert>
                            </Box>
                        )}
                    </BlockLoadGroup>
                </DialogContent>

                <DialogActions>
                    {showErrors && !this.stepsMapping[activeStep].isValid() && (
                        <FormControl error>
                            <FormHelperText>Resolve form errors before continuing</FormHelperText>
                        </FormControl>
                    )}
                    <Button
                        disabled={activeStep === 0}
                        onClick={() => this.handleBackStep(activeStep)}
                    >
                        Back
                    </Button>
                    {_.keys(this.stepsMapping).length - 1 === activeStep ? (
                        <React.Fragment>
                            <Button
                                onClick={
                                    this.props.isLoading
                                        ? this.props.closeAction
                                        : this.validateDraft
                                }
                                color="primary"
                            >
                                Save Draft
                            </Button>
                            <Button
                                onClick={() => {
                                    return this.props.isLoading
                                        ? this.props.closeAction()
                                        : this.validateDraft({ isSaveAudit: true });
                                }}
                                color="primary"
                                variant="contained"
                                autoFocus
                            >
                                Save and Submit
                            </Button>
                        </React.Fragment>
                    ) : (
                        <Button
                            onClick={() => this.handleNextStep(activeStep)}
                            color="primary"
                            variant="contained"
                        >
                            Next
                        </Button>
                    )}
                </DialogActions>
                <CreativeEditConfirmation
                    isOpen={isEditConfirmationModalOpen}
                    onConfirm={isEditConfirmSaveAndAudit ? this.onSaveAndAudit : this.handleSave}
                    onCancel={this.handleCloseEditConfirmationModal}
                    message={confirmModalMessage}
                />
            </Dialog>
        );
    }
}

const ChipSelector = withStyles(styles)(props => {
    const { classes } = props;
    const theme = useTheme();

    const multipleSelectIcon = props.active ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
    const singleSelectIcon = props.active ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />;

    const icon = props.multipleSelect ? multipleSelectIcon : singleSelectIcon;

    let iconClass;
    let iconColor;

    if (props.active && props.disabled) {
        iconColor = theme.palette.primary[200];
        iconClass = classes.activeDisabledIcon;
    } else if (props.active) {
        iconColor = theme.palette.primary.main;
    } else if (props.disabled) {
        iconColor = theme.palette.grey[300];
        iconClass = classes.disabledIcon;
    } else {
        iconColor = theme.palette.grey[500];
    }

    return (
        <Chip
            icon={
                <React.Fragment>
                    <div className={classes.checkIcon}>{icon}</div>
                    {props.icon ? (
                        <div className={classes.icon}>
                            <CreativeIcon optionType={props.optionType} color={iconColor} />
                        </div>
                    ) : null}
                </React.Fragment>
            }
            className={`${classes.chip} ${props.disabled ? 'disabledIcon' : null} ${iconClass}`}
            label={props.label}
            variant="outlined"
            onClick={props.onClick}
            color={props.active ? 'primary' : 'default'}
        />
    );
});

function NameField(props) {
    const classes = useStyles();

    return (
        <FormControl margin="normal" error={props.error} className={classes.nameField}>
            <Label component="legend" required={true}>
                {props.label}
            </Label>
            <OutlinedInput
                id="component-simple"
                value={props.value}
                onChange={props.onChange}
                autoFocus
                classes={{ input: classes.nameFieldInput }}
            />
        </FormControl>
    );
}

const ChipContent = withStyles(styles)(props => {
    const theme = useTheme();

    const icons = {
        check: <CheckIcon style={{ fontSize: 12 }} />,
        warning: <WarningIcon style={{ fontSize: 12 }} />,
        info: <InfoIcon style={{ fontSize: 12 }} />,
        expand: <OpenWithIcon style={{ fontSize: 12 }} />,
    };

    const colors = {
        check: 'green',
        warning: 'orange',
        info: 'steelblue',
    };

    let chipContentColor;

    if (props.active && props.disabled) {
        chipContentColor = theme.palette.primary[200];
    } else if (props.disabled) {
        chipContentColor = theme.palette.grey[300];
    } else {
        chipContentColor = colors[props.icon] || theme.palette.grey[500];
    }

    return (
        <div>
            {props.icon && (
                <Icon style={{ color: chipContentColor, marginRight: 5 }}>{icons[props.icon]}</Icon>
            )}
            {props.message}
        </div>
    );
});

function MacroHighlight(props) {
    const classes = useStyles();
    return <span className={classes.macros}>{props.children}</span>;
}

export function StyledLink(props) {
    const classes = useStyles();
    return <div className={classes.link}>{props.children}</div>;
}

export default connect(selector)(CreativeForm);

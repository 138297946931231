import _ from 'lodash';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';
import { isInternalUser } from 'states/profile/business-rules';
import { getEnvironmentSettings } from 'services/environment';

import { IAS_VIEWABILITY_OPTIONS } from 'common/constants/ias-viewability';

import GroupMViewability from './groupm-viewability';
import PublicisViewability from './publicis-viewability';

const useStyles = makeStyles({
    form: {
        margin: '10px 0px',
    },
    label: {
        fontSize: 18,
    },
});

const IAS_VIEWABILITY_OPTIONS_MAPPING = {};
_.each(IAS_VIEWABILITY_OPTIONS, option => {
    IAS_VIEWABILITY_OPTIONS_MAPPING[option.value] = option;
});

const IASViewability = props => {
    const classes = useStyles();
    const { ad, campaign, ads, draft, updateDraft } = props;
    const handleViewabilityChange = e => {
        const { value } = e.target;
        const changes = {
            ...draft.ias,
            viewability: {
                ...draft.ias.viewability,
                value,
            },
        };
        updateDraft({ ias: changes });
    };

    const iasViewability = _.get(draft, 'ias.viewability.value');
    const environmentSettings = getEnvironmentSettings();

    return (
        <div>
            <Typography>Viewability</Typography>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <FormControl className={classes.form}>
                        <FormGroup row>
                            <Select value={iasViewability} onChange={handleViewabilityChange}>
                                {_.map(IAS_VIEWABILITY_OPTIONS, option => (
                                    <MenuItem value={option.value} key={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Tooltip
                                placement="right"
                                title={
                                    <Typography variant="body2">
                                        According to the IAB: a display ad is viewable if 50% of the
                                        ad is in view for 1 continuous second. A video ad is
                                        viewable if 50% of the ad is in view for 2 continuous
                                        seconds.
                                    </Typography>
                                }
                            >
                                <InfoIcon color="action" fontSize="small" />
                            </Tooltip>
                        </FormGroup>
                    </FormControl>
                </Grid>

                <React.Fragment>
                    {_.includes(environmentSettings.getViewabilityOptions(), 'groupM') && (
                        <Grid item>
                            <GroupMViewability
                                draft={draft}
                                ad={ad}
                                ads={ads}
                                updateDraft={updateDraft}
                                isCrossPlatformCampaign={props.isCrossPlatformCampaign}
                            />
                        </Grid>
                    )}
                    {_.includes(environmentSettings.getViewabilityOptions(), 'publicis') && (
                        <Grid item>
                            <PublicisViewability
                                draft={draft}
                                ad={ad}
                                ads={ads}
                                updateDraft={updateDraft}
                                isCrossPlatformCampaign={props.isCrossPlatformCampaign}
                            />
                        </Grid>
                    )}
                </React.Fragment>
            </Grid>
        </div>
    );
};

export default IASViewability;

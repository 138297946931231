import _ from 'lodash';

export const NAME = 'flexBrandsDashboard';

const initialState = {
    loading: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;

        case 'FLEX_BRAND_DASHBOARD__FETCH_BRANDS_DATA_FETCH_START': {
            return {
                ...state,
                loading: true,
            };
        }

        case 'FLEX_BRAND_DASHBOARD__FETCH_BRANDS_DATA_END': {
            return {
                ...state,
                brands: action.payload.data,
                loading: false,
            };
        }

        case 'FLEX_BRAND_DASHBOARD__FETCH_BRANDS_DATA_FETCH_ERROR': {
            return {
                ...state,
                loading: false,
            };
        }
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class ClickToCopyButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    static propTypes = {
        iconClass: PropTypes.string,
        buttonClass: PropTypes.string,
        activeButtonClass: PropTypes.string,
        textToDisplay: PropTypes.string.isRequired,
        textToCopy: PropTypes.string.isRequired,
    }

    onCopy = () => {
        const timeOutID = setTimeout(() => {
            if (this.state.mounted) {
                this.setState({
                    timeoutID: null,
                    copying: false,
                });
            }
        }, 2000); // Two seconds

        navigator.clipboard.writeText(this.props.textToCopy || '');
        this.setState({
            copying: true,
            timeoutID: timeOutID,
        });
    }

    getInitialState() {
        return {
            copying: false,
            mounted: false,
        };
    }

    componentDidMount = () => {
        this.setState({
            mounted: true,
        });
    }

    componentWillUnmount = () => {
        clearInterval(this.state.timeoutID);
    }

    render() {
        const textToDisplay = this.state.copying ? 'Copied' : this.props.textToDisplay;
        const iconClass = this.state.copying
            ? classnames('fa fa-check', this.props.iconClass)
            : classnames('fa fa-files-o', this.props.iconClass);
        let buttonClass = this.props.buttonClass ? this.props.buttonClass : 'btn';

        if (this.state.copying) {
            buttonClass = classnames(
                buttonClass,
                this.props.activeButtonClass,
                'zeroclipboard-hover'
            );
        }

        return (
            <button
                title="Click to copy"
                className={buttonClass}
                onClick={this.onCopy}
            >
                <i className={iconClass} />
                &nbsp;
                {textToDisplay}
            </button>
        );
    }
}

export default ClickToCopyButton;

import _ from 'lodash';

export default function selector(storeState) {
    const draft = _.get(storeState, 'adminOrgForm.draft');
    const users = _.get(storeState, 'adminOrgForm.users', []);
    const relationship = _.get(storeState, 'adminOrgForm.relationship', []);
    const profile = _.get(storeState, 'profile', []);

    return {
        draft,
        users,
        relationship,
        profile,
    };
}

import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import DevicesIcon from '@mui/icons-material/Devices';
import Alert from '@mui/material/Alert';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import Tooltip from '@mui/material/Tooltip';
import flags from 'containers/flags/service';

import { AUDIENCE_STATUS_MAPPING } from '../../../presets/poi-editor/services/constants';

import filter from 'lodash/filter';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

import { isInternalUser } from 'states/profile/business-rules';

import {
    formatDate_dateWithYear,
    formatNumber_whole,
    formatNumber_currency,
} from 'utils/formatting';
import { environicsMetaDataDescriptions } from '../../environics-dashboard/layout';
import {
    manifoldMetaDataDescriptions,
    leftSideSegmentDescriptions,
    rightSideSegmentDescriptions,
} from '../../manifold-dashboard/layout';
import { retargetingOptionsMapping } from '../../retargeting-dashboard/layout';
import { PlatformMapping } from 'states/resources/ads/business-logic';
import {
    getAudienceStatus,
    RETARGETING_TYPES,
    STANDARD_RETARGETING_TYPES,
} from 'states/resources/audiences/business-logic';
import { getEnvironmentSettings } from 'services/environment';

const PLUSCO_SHOWROOM_ID = '6487683d04a1620f4c1af7f6';
const ROOT_ORG_ID = '53271100d26f9a2f917ed289';
const ADDICTIVE_ORG_ID = '53271100d26f9a2f917ed290';

const useStyles = makeStyles({
    grid_right: {
        paddingLeft: '10px',
    },
    content: {
        '& > div': {
            paddingTop: '14px',
        },
    },
    emptyContent: {
        fontSize: '16px',
        color: '#757575',
        padding: '24px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        opacity: '0.6',
        paddingTop: '8px',
    },
    viewMoreRight: {
        right: 30,
        position: 'absolute',
        margin: 10,
    },
    boxRight: {
        padding: 2,
        border: 0.5,
        marginRight: 5,
    },
    card: {
        margin: 3,
    },
    icons: {
        verticalAlign: 'middle',
        fontSize: 'small',
        fontColor: 'grey',
    },
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: 0.5,
        },
        margin: 1,
    },
    chip: {
        marginRight: 2,
        padding: 1,
    },
});

const sourceTypeMapping = sourceTypeLabel => {
    if (sourceTypeLabel === 'flex_location_category') {
        return 'Location Categories';
    }
    if (sourceTypeLabel === 'flex_standard_brand') {
        return 'Brand';
    }
    return startCase(toLower(sourceTypeLabel));
};

const audienceFields = {
    supports: {
        getLabel: () => 'Platforms',
        getValue: ({ audience }) => startCase(toLower(get(audience, 'supports', 'N/A'))) || 'N/A',
    },
    poiCount: {
        getLabel: () => 'Number of POIs',
        getValue: ({ audience }) => formatNumber_whole(get(audience, 'poiCount', 'N/A')) || 'N/A',
    },
    points: {
        getLabel: () => 'Number of POIs',
        getValue: ({ audience }) => formatNumber_whole(get(audience, 'points', 'N/A')) || 'N/A',
    },
    description: {
        getLabel: () => 'Description',
        getValue: ({ audience }) =>
            startCase(toLower(get(audience, 'description', 'N/A'))) || 'N/A',
    },
    category: {
        getLabel: () => 'Category',
        getValue: ({ audience }) => startCase(toLower(get(audience, 'category', 'N/A'))) || 'N/A',
    },
    type: {
        getLabel: () => 'Type',
        getValue: ({ audience }) => startCase(toLower(get(audience, 'type', 'N/A'))) || 'N/A',
    },
    _created: {
        getLabel: () => 'Created',
        getValue: ({ audience }) =>
            formatDate_dateWithYear(get(audience, '_created', 'N/A')) || 'N/A',
    },
    lastRefreshed: {
        getLabel: () => 'Last Updated',
        getValue: ({ audience }) =>
            formatDate_dateWithYear(get(audience, 'lastRefreshed', 'N/A')) || 'N/A',
    },
    status: {
        getLabel: () => 'Status',
        getValue: ({ audience }) => getAudienceStatus(audience),
    },
    expire_at: {
        getLabel: () => 'Expiration',
        getValue: ({ audience }) => {
            return (
                <>
                    {formatDate_dateWithYear(get(audience, 'expire_at', 'N/A')) || 'N/A'}
                    {audience?.source_type === 'pds_custom' && (
                        <>
                            &nbsp;
                            <Tooltip
                                title={
                                    <Typography variant="body2">
                                        This audience will expire 90 days after last usage.
                                        <br />
                                        A suspending status will appear 30 days before the audience
                                        is moved to suspended.
                                        <br />
                                        To reactivate an audience you can either click reactive
                                        button under actions in the audience tab or apply it to any
                                        upcoming or active campaigns.
                                        <br />
                                    </Typography>
                                }
                                placement="right"
                            >
                                <i className="fa fa-question-circle" />
                            </Tooltip>
                        </>
                    )}
                </>
            );
        },
    },
    dataCost: {
        getLabel: () => 'Data Cost',
        getValue: ({ audience }) =>
            formatNumber_currency(get(audience, 'dataCost', 'N/A')) || 'N/A',
    },
    locationCategory: {
        getLabel: () => 'Location Category',
        getValue: ({ audience }) =>
            startCase(toLower(get(audience, 'locationCategory', 'N/A'))) || 'N/A',
    },
    summaryPoints: {
        getLabel: () => 'Tags',
        getValue: ({ audience }) =>
            startCase(toLower(get(audience, 'summaryPoints', 'N/A'))) || 'N/A',
    },
    subCategory: {
        getLabel: () => 'SubType',
        getValue: ({ audience }) =>
            startCase(toLower(get(audience, 'subCategory', 'N/A'))) || 'N/A',
    },
    tier: {
        getLabel: () => 'Tier',
        getValue: ({ audience }) => startCase(toLower(get(audience, 'tier', 'N/A'))) || 'N/A',
    },
    uniques: {
        getLabel: () => 'Matched Uniques',
        getValue: ({ audience }) => formatNumber_whole(get(audience, 'uniques', 'N/A')) || 'N/A',
    },
    upload_origin: {
        getLabel: () => 'Data Provider',
        getValue: ({ audience }) =>
            startCase(toLower(get(audience, 'upload_origin', 'N/A'))) || 'N/A',
    },
    third_party_fees: {
        getLabel: () => 'Third Party Data Fees',
        getValue: ({ audience }) =>
            formatNumber_currency(get(audience, 'third_party_fees', 'N/A')) || 'N/A',
    },
    retargeting_type: {
        getLabel: () => 'Event Type',
        getValue: ({ audience }) =>
            retargetingOptionsMapping[get(audience, 'retargeting_type', 'N/A')]?.label || 'N/A',
    },
    source_type: {
        getLabel: () => 'Source',
        getValue: ({ audience }) => sourceTypeMapping(get(audience, 'source_type', 'N/A')) || 'N/A',
    },
};

const audienceSidebarConfig = {
    'Pelmorex Audiences': [
        {
            audienceCategory: 'Precise Geolocation',
            fields: [
                'supports',
                'poiCount',
                'description',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
            ],
        },
        {
            audienceCategory: 'Flex Geolocation',
            fields: ['supports', 'points', 'source_type', 'category', 'type', 'dataCost'],
        },
        {
            audienceCategory: 'Online Behavioural',
            fields: [
                'supports',
                'description',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
            ],
        },
        {
            audienceCategory: 'CanaCode',
            fields: [
                'supports',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
                'summaryPoints',
            ],
        },
        {
            audienceCategory: 'Environics',
            fields: [
                'supports',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
                'description',
            ],
        },
        {
            audienceCategory: 'Mastercard',
            fields: [
                'supports',
                'description',
                'subCategory',
                'tier',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
            ],
        },
        {
            audienceCategory: 'Declared Age',
            fields: ['supports', 'category', 'type', '_created', 'lastRefreshed', 'dataCost'],
        },
    ],
    'Custom Audiences': [
        {
            audienceCategory: 'Pelmorex Data',
            fields: [
                'supports',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
                'status',
                'expire_at',
            ],
        },
        {
            audienceCategory: 'Flex Geolocation',
            fields: ['supports', 'category', 'type', 'dataCost'],
        },
        {
            audienceCategory: 'Retargeting',
            fields: [
                'supports',
                'retargeting_type',
                'source_type',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
            ],
        },
        {
            audienceCategory: 'Uploaded',
            fields: [
                'upload_origin',
                'third_party_fees',
                'supports',
                'category',
                'type',
                '_created',
                'lastRefreshed',
                'dataCost',
            ],
        },
    ],
};

const manifoldSegmentDescriptions = flatten([
    leftSideSegmentDescriptions,
    rightSideSegmentDescriptions,
]);

export const SidebarAudienceItems = props => {
    const classes = useStyles();
    const { audience, organizationId } = props;
    const audienceTypeConfig = audienceSidebarConfig[(audience?.type)];

    const isFlexGeolocationSuspended =
        audience.category === 'Flex Geolocation' &&
        audience.status === AUDIENCE_STATUS_MAPPING.suspended;

    const audienceCategoryConfig = filter(audienceTypeConfig, {
        audienceCategory: audience?.category,
    })[0];

    const isRetargetingAudience = audience.category === 'Retargeting';
    const isFromStandardTypes = STANDARD_RETARGETING_TYPES.includes(audience.retargeting_type);
    const isCTVImpressionsRetargetingType =
        audience.supports.includes(PlatformMapping.CTV) &&
        audience.retargeting_type === RETARGETING_TYPES.Impression;
    const isCTVAttributionRetargetingType =
        audience.supports.includes(PlatformMapping.CTV) &&
        audience.retargeting_type === RETARGETING_TYPES.Attribution;
    const isFromPixelRetargetingType = audience.retargeting_type === RETARGETING_TYPES.Pixel;
    let parsedSupportedPlatforms = audience.supports
        ? audience.supports.filter(platform => platform)
        : [];

    if (!getEnvironmentSettings().canUseCTVOrDOOH()) {
        parsedSupportedPlatforms = parsedSupportedPlatforms.filter(
            platform => platform !== PlatformMapping.CTV
        );
    }

    if (!getEnvironmentSettings().canUseCTVOrDOOH() || !flags.isEnabled('en_4657_dooh_campaigns')) {
        parsedSupportedPlatforms = parsedSupportedPlatforms.filter(
            platform => platform !== PlatformMapping.DOOH
        );
    }

    if (isRetargetingAudience && (isFromStandardTypes || isFromPixelRetargetingType)) {
        // Retargeting audiences should support CTV even if source isn't a CTV campaign/ad.
        parsedSupportedPlatforms = parsedSupportedPlatforms
            ? sortBy(
                  uniq(parsedSupportedPlatforms.concat([PlatformMapping.CTV])),
                  platform => platform
              )
            : [];
    }

    if (
        isRetargetingAudience &&
        (isCTVImpressionsRetargetingType ||
            isCTVAttributionRetargetingType ||
            isFromPixelRetargetingType)
    ) {
        // Retargeting audiences should support CTV, INAPP, DESKTOP if source is a CTV campaign/ad.
        parsedSupportedPlatforms = parsedSupportedPlatforms
            ? sortBy(
                  uniq(
                      parsedSupportedPlatforms.concat([PlatformMapping.INAPP, PlatformMapping.MWEB])
                  ),
                  platform => platform
              )
            : [];
    }

    return (
        audienceCategoryConfig && (
            <React.Fragment>
                <Box
                    display="flex"
                    justifyContent="center"
                    width="100%"
                    className={classes.emptyContent}
                />
                <Grid container>
                    <Grid item xs={8}>
                        {isInternalUser() ? (
                            <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
                                <Typography variant="subtitle2">ID</Typography>
                                <Typography variant="body1">{audience.id}</Typography>
                            </Box>
                        ) : null}
                    </Grid>

                    <Grid item xs={4} classes={{ root: classes.viewMoreRight }}>
                        {audience.category === 'Flex Geolocation' && (
                            <Card elevation={0} variant="outlined" className={classes.card}>
                                <CardContent>
                                    <Typography gutterBottom align="left" variant="subtitle2">
                                        Status
                                    </Typography>
                                    <Typography align="left">
                                        {upperFirst(audience.status) || 'N/A'}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}

                        <Card elevation={0} variant="outlined" className={classes.card}>
                            <CardContent>
                                <Typography gutterBottom align="left" variant="subtitle2">
                                    Uniques Total
                                </Typography>
                                <Typography align="left">
                                    {formatNumber_whole(audience.uniques) || 'N/A'}
                                    {isFlexGeolocationSuspended ? '*' : ''}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card elevation={0} variant="outlined" className={classes.card}>
                            <CardContent>
                                <Typography gutterBottom align="left" variant="subtitle2">
                                    Uniques (Mobile in-app)
                                </Typography>
                                <Typography align="left">
                                    {audience.category === 'Uploaded' &&
                                    parsedSupportedPlatforms.includes(PlatformMapping.INAPP)
                                        ? formatNumber_whole(audience.uniques) || 'N/A'
                                        : formatNumber_whole(audience.uniqueDevices) || 'N/A'}
                                    {isFlexGeolocationSuspended ? '*' : ''}
                                </Typography>
                            </CardContent>
                            <CardContent>
                                <Typography gutterBottom align="left" variant="subtitle2">
                                    Uniques (Mobile Web + Desktop)
                                </Typography>
                                <Typography align="left">
                                    {audience.category === 'Uploaded' &&
                                    parsedSupportedPlatforms.includes(PlatformMapping.MWEB)
                                        ? formatNumber_whole(audience.uniques) || 'N/A'
                                        : formatNumber_whole(audience.uniqueCookies) || 'N/A'}
                                    {isFlexGeolocationSuspended ? '*' : ''}
                                </Typography>
                            </CardContent>
                            {parsedSupportedPlatforms.includes(PlatformMapping.CTV) &&
                                getEnvironmentSettings().canUseCTVOrDOOH() && (
                                    <CardContent>
                                        <Typography gutterBottom align="left" variant="subtitle2">
                                            Uniques (Connected TV)
                                        </Typography>
                                        <Typography align="left">
                                            {audience.category === 'Uploaded' &&
                                            parsedSupportedPlatforms.includes(PlatformMapping.CTV)
                                                ? formatNumber_whole(audience.uniques) || 'N/A'
                                                : formatNumber_whole(audience.uniqueCTVs) || 'N/A'}
                                            {isFlexGeolocationSuspended ? '*' : ''}
                                        </Typography>
                                    </CardContent>
                                )}
                            {parsedSupportedPlatforms.includes(PlatformMapping.DOOH) &&
                                getEnvironmentSettings().canUseCTVOrDOOH() && (
                                    <CardContent>
                                        <Typography gutterBottom align="left" variant="subtitle2">
                                            Unique Boards (Digital Out-of-Home)
                                        </Typography>
                                        <Typography align="left">
                                            {formatNumber_whole(audience.uniqueDOOHs) || 'N/A'}
                                        </Typography>
                                    </CardContent>
                                )}
                        </Card>
                        {isFlexGeolocationSuspended && (
                            <Alert severity="warning">
                                <Typography>
                                    *estimated Uniques,
                                    <br />
                                    based on audience size before suspension.
                                </Typography>
                            </Alert>
                        )}
                    </Grid>
                    {map(audienceCategoryConfig.fields, fieldName => {
                        const audienceField = audienceFields[fieldName];
                        if (fieldName === 'supports') {
                            return (
                                <Grid item xs={8}>
                                    <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
                                        <Typography variant="subtitle2">
                                            {audienceField.getLabel()}
                                        </Typography>
                                        {map(parsedSupportedPlatforms, (supportedType, index) => {
                                            return (
                                                <React.Fragment>
                                                    <PlatformListItem platform={supportedType} />
                                                    {index === parsedSupportedPlatforms.length - 1
                                                        ? ''
                                                        : ', '}
                                                </React.Fragment>
                                            );
                                        })}
                                    </Box>
                                </Grid>
                            );
                        } else if (
                            audienceCategoryConfig.audienceCategory === 'CanaCode' &&
                            fieldName === 'summaryPoints'
                        ) {
                            return (
                                <Grid item xs={8}>
                                    <Box paddingLeft={2} paddingRight={2} paddingBottom={1}>
                                        <Typography variant="subtitle2">
                                            {audienceField.getLabel()}
                                        </Typography>
                                        <div className={classes.root}>
                                            {map(
                                                audience['manifoldMetadata']?.summaryPoints,
                                                summaryPoint => {
                                                    return (
                                                        <Chip
                                                            display="inline"
                                                            variant="outlined"
                                                            label={`${summaryPoint}`}
                                                            className={classes.chip}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    </Box>
                                </Grid>
                            );
                        } else if (
                            ['status', 'expire_at'].includes(fieldName) &&
                            !flags.isEnabled('en_4634_audience_suspension')
                        ) {
                            return <></>;
                        }
                        return (
                            <Grid item xs={8}>
                                <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
                                    <Typography variant="subtitle2">
                                        {audienceField.getLabel()}
                                    </Typography>
                                    <Typography variant="body1">
                                        {audienceField.getValue({ audience })}
                                    </Typography>
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
                {audienceCategoryConfig.audienceCategory === 'Environics' ? (
                    <React.Fragment>
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                            className={classes.emptyContent}
                        />
                        <Grid container justifyContent="space-between">
                            {map(environicsMetaDataDescriptions, description => (
                                <Grid item xs={4} key={description.label}>
                                    <Card elevation={0} variant="outlined" className={classes.card}>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                align="center"
                                                variant="subtitle2"
                                            >
                                                {description.label}
                                            </Typography>
                                            <Typography align="center">
                                                {description.getValue({
                                                    segment: audience,
                                                })}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ) : null}

                {audienceCategoryConfig.audienceCategory === 'CanaCode' ? (
                    <React.Fragment>
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                            className={classes.emptyContent}
                            marginTop="50px"
                        />
                        <Grid container justifyContent="space-between">
                            {map(manifoldMetaDataDescriptions, description => (
                                <Grid item xs={4} key={description.label}>
                                    <Card elevation={0} variant="outlined" className={classes.card}>
                                        <CardContent>
                                            <Typography
                                                gutterBottom
                                                align="center"
                                                variant="subtitle2"
                                            >
                                                {description.label}
                                            </Typography>
                                            <Typography align="center">
                                                {description.getValue({
                                                    segment: audience,
                                                })}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                            className={classes.emptyContent}
                            marginTop="30px"
                        />
                        {map(manifoldSegmentDescriptions, description => (
                            <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
                                <Typography variant="subtitle2">{description.label}</Typography>
                                <Typography variant="body1">
                                    {description.getValue({
                                        segment: audience,
                                    })}
                                </Typography>
                            </Box>
                        ))}
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        )
    );
};

const PlatformListItem = ({ platform }) => {
    const classes = useStyles();
    const platformConfig = {
        [PlatformMapping.INAPP]: {
            icon: DevicesIcon,
            description: 'Mobile In-App',
        },
        [PlatformMapping.MWEB]: {
            icon: PhoneAndroidIcon,
            description: 'Web / Desktop',
        },
        [PlatformMapping.CTV]: {
            icon: ConnectedTvIcon,
            description: 'Connected TV',
        },
        [PlatformMapping.DOOH]: {
            icon: HomeMaxIcon,
            description: 'Digital Out-of-Home',
        },
    };

    const PlatformIcon = platformConfig[platform].icon;
    return (
        <Typography marginTop={2} display="inline" variant="body1">
            <PlatformIcon className={classes.icons} /> {platformConfig[platform].description}{' '}
        </Typography>
    );
};

import _ from 'lodash';
import Joi from '@hapi/joi';

export default Joi.object({
    first_name: Joi.string()
        .required()
        .messages({
            'string.empty': 'Not allowed to be empty',
        }),
    last_name: Joi.string()
        .trim()
        .required()
        .messages({
            'string.empty': 'Not allowed to be empty',
        }),
    email: Joi.string()
        .trim()
        .required()
        .messages({
            'string.empty': 'Not allowed to be empty',
        }),
    global_role: Joi.string()
        .trim()
        .required()
        .messages({
            'string.empty': 'Not allowed to be empty',
            'any.required': 'Must select role for user',
        }),
});

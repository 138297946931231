import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import { TextField } from 'widgets-v6/standard-input';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import { NeutralButton } from 'widgets-v5/buttons';

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            inputValue: props.filename,
        };
    }

    showHideEditField = () => {
        this.setState({ editing: !this.state.editing });
    };

    saveInput = () => {
        this.setState(
            {
                editing: !this.state.editing,
            },
            () => {
                if (this.state.inputValue === '') {
                    return;
                }
                this.props.editAction(this.state.inputValue);
            }
        );
    };

    handleInputChange = value => {
        this.setState({
            inputValue: value,
        });
    };

    downloadFile = () => {
        const { downloadUrl } = this.props;

        if (!downloadUrl) {
            return;
        }

        const element = document.createElement('a');
        element.setAttribute('href', downloadUrl);
        element.setAttribute('download', true);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    render() {
        return (
            <div className="ef3-inlineTextboxForm ef6-alignment__flex-center ef6-alignment__space-between">
                <div className="ef3-inlineTextboxForm_field">
                    {this.state.editing ? (
                        <div className="ef3-inputGroup">
                            <TextField
                                value={this.state.inputValue}
                                onChange={({ target: { value } }) => this.handleInputChange(value)}
                                disableMargin
                            />
                            <span onClick={this.saveInput} className="ef3-inputGroup_addOn">
                                <i className="fa fa-floppy-o" />
                            </span>
                            <span
                                onClick={this.showHideEditField}
                                className="ef3-inputGroup_addOnCancel"
                            >
                                Cancel
                            </span>
                        </div>
                    ) : (
                        <Box display="flex" alignItems="center">
                            <div>{this.props.filename}</div>
                                {this.props.editAction && (
                                    <IconButton onClick={this.showHideEditField} size="large">
                                        <EditIcon />
                                    </IconButton>
                                )}
                        </Box>
                    )}
                </div>
                <div className="ef3-campaign-overview-files__contextual-menu">
                    {this.props.editAction && (
                        <InlineTooltip tooltip="Edit" top={25} position="bottom">
                            <NeutralButton
                                onClick={this.showHideEditField}
                                icon={<i className="fa fa-pencil" />}
                                style={{ marginRight: 5, padding: 8 }}
                            />
                        </InlineTooltip>
                    )}
                    {this.props.deleteAction && (
                        <InlineTooltip tooltip="Delete" top={25} position="bottom">
                            <NeutralButton
                                onClick={this.props.deleteAction}
                                icon={<i className="fa fa-trash" />}
                                style={{ marginRight: 5, padding: 8 }}
                            />
                        </InlineTooltip>
                    )}
                    {this.props.downloadUrl && (
                        <InlineTooltip tooltip="Download" top={25} position="bottom">
                            <NeutralButton
                                onClick={this.downloadFile}
                                icon={<i className="fa fa-download" />}
                                style={{ marginRight: 5, padding: 8 }}
                            />
                        </InlineTooltip>
                    )}
                </div>
            </div>
        );
    }
}

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Layout from './layout';
import selector from './selector';
import actions from './actions';
import { default as editorActions } from 'pages/reports/editor/actions';
import reportCampaignsLoaderActions from 'states/report-campaigns-loader/actions';

import EditorModal from 'pages/reports/editor/editor-modal';

class Dashboard extends React.Component {
    getCurrentPage = () => {
        return parseInt(this.getFilters().page || 1, 10);
    };

    search = debounce((key, value) => {
        const filters = this.getFilters();
        this.props.dispatch(actions.search(key, value, filters));
    }, 200);

    componentDidMount() {
        this.props.dispatch(reportCampaignsLoaderActions.getAll());
        const filters = { ...this.getFilters() };

        filters.type = filters.type || 'my-reports';

        this.props.dispatch(actions.init(filters));
    }

    componentDidUpdate(prevProps) {
        const prevFilters = prevProps.location.query;
        const filters = this.getFilters();

        if (!isEqual(prevFilters, filters)) {
            this.props.dispatch(actions.fetchPageData(filters));
        }
    }

    getFilters = () => {
        const filters = this.props.location.query;
        return filters || {};
    };

    goToNewReport = () => {
        browserHistory.push('/reports/new');
    };

    openEditor = ({ businessReportId }) => {
        this.props.dispatch(editorActions.openEditor({ businessReportId }));
    };

    openDeleteConfirmationModal = businessReportId => {
        this.props.dispatch(actions.openDeleteConfirmationModal(businessReportId));
    };

    confirmDeleteBusinessReport = (businessReportId, filters) => {
        this.props.dispatch(actions.delete(businessReportId, filters));
    };

    closeDeleteConfirmationModal = () => {
        this.props.dispatch(actions.closeDeleteConfirmationModal());
    };

    resetReports = () => {
        this.props.dispatch(actions.reset());
    };

    render() {
        const page = this.getCurrentPage();
        const maxPage = Math.ceil(
            this.props.businessReportDashboardState.maxResults /
                this.props.businessReportDashboardState.itemsPerPage
        );

        return (
            <React.Fragment>
                <Layout
                    goToNewReport={this.goToNewReport}
                    businessReports={this.props.businessReports}
                    businessReportsResource={this.props.businessReportsResource}
                    isInitialized={this.props.businessReportDashboardState.isInitialized}
                    isLoading={this.props.businessReportDashboardState.isLoading}
                    currentPage={page}
                    maxResults={this.props.businessReportDashboardState.maxResults}
                    itemsPerPage={this.props.businessReportDashboardState.itemsPerPage}
                    maxPage={maxPage}
                    filters={this.getFilters()}
                    search={this.search}
                    organizationTimezone={this.props.organizationTimezone}
                    openEditor={this.openEditor}
                    businessReportToDelete={this.props.businessReportToDelete}
                    confirmDeleteBusinessReport={this.confirmDeleteBusinessReport}
                    isDeleteConfirmationModalOpen={this.props.isDeleteConfirmationModalOpen}
                    openDeleteConfirmationModal={this.openDeleteConfirmationModal}
                    closeDeleteConfirmationModal={this.closeDeleteConfirmationModal}
                    resetReports={this.resetReports}
                    allBusinessReportCount={this.props.allBusinessReportCount}
                    myBusinessReportCount={this.props.myBusinessReportCount}
                />
                <EditorModal />
            </React.Fragment>
        );
    }
}

export default connect(selector)(Dashboard);

import _ from 'lodash';

import validate, { isOptional, isRequired, isString, isArray } from 'utils/validate';

export const validators_group = {
    demo_groupValidate(value, source) {
        if (value === undefined) {
            return 'required field';
        }
    },
    /*
    demo_groupValidate: [
        isRequired(), {
            validate (value, source) {
                const isValid = true; //<-- set to false for testing
                return isValid
            },
            message: 'demoing group validation'
        }
    ],
*/
};

import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';

import { useReportJobs } from '../hook';
import ReportJobList from '../report-job-list';

function UnsavedReportJobs() {
    const { loadReportJobs, unSavedReports } = useReportJobs();

    useEffect(() => {
        loadReportJobs();
    }, []);

    if (unSavedReports.length > 0) {
        return (
            <Box mb={2}>
                <Typography variant="h6">My Unsaved Reports</Typography>
                <Box mb={2}>
                    <Typography variant="caption" color="textSecondary">
                        Save your reports to keep them after 10 days.
                    </Typography>
                </Box>
                <TableContainer component={Paper}>
                    <ReportJobList reportJobs={unSavedReports} />;
                </TableContainer>
            </Box>
        );
    } else {
        return null;
    }
}
export default UnsavedReportJobs;

import _ from 'lodash';
import React from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import makeStyles from '@mui/styles/makeStyles';

import { PanelsLayout, ScrollingSidebarPanel, OverlaySidebarPanel } from 'widgets-v5/panels-layout';

const useStyles = makeStyles({
    paper: {
        display: 'inline-block',
        minWidth: '100%',
    },
});

function Layout(props) {
    const classes = useStyles();
    return (
        <div className="audiences">
            <div>{props.sidebar}</div>
            <PanelsLayout
                direction="column"
                className="panels-layout-column-example audiences__workspace"
            >
                <OverlaySidebarPanel style={{ zIndex: 2 }}>
                    <div>{props.header}</div>
                </OverlaySidebarPanel>
                <ScrollingSidebarPanel>
                    <Box height="100vh" overflow="auto">
                        <Paper className={classes.paper}>{props.body}</Paper>
                    </Box>
                </ScrollingSidebarPanel>
            </PanelsLayout>
        </div>
    );
}

export default Layout;

import React from 'react';
import find from 'lodash/find';
import moment from 'moment';
import numeral from 'numeral';

import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { TableForm } from 'widgets-v6/table-form';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import HalfDateTimeRangePicker from 'widgets/half-date-time-range-picker';
import { useCampaignForm } from '../../v2';
import { formatNumber_currency, formatNumber_whole } from 'utils/formatting';
import { RevenueModelMapping } from '../../../../states/resources/campaigns/business-logic';

const generateTableConfig = (
    now,
    timezone,
    removeFlight,
    showErrors,
    errors,
    isEdit,
    flightMetrics,
    revenueModel,
    billing_rate
) => [
    {
        Header: 'Flight Name',
        id: 'name',
        required: true,
        Cell: ({ value, index }) => {
            return (
                <TextField
                    id={`${index}.name`}
                    disableMargin
                    value={value}
                    fullWidth
                    error={showErrors && errors[`flights.${index}.name`]}
                />
            );
        },
    },
    {
        Header: 'Notes',
        id: 'notes',
        Cell: ({ value, index }) => {
            return <TextField id={`${index}.notes`} disableMargin value={value} fullWidth />;
        },
    },
    {
        Header: 'Start',
        id: 'start',
        required: true,
        size: 'medium',
        Cell: ({ value, index, currentData, handleFormChange, data }) => {
            let previousFlight = data && data.length && index && data[index - 1];
            return (
                <Box sx={{ minWidth: 200 }}>
                    <HalfDateTimeRangePicker
                        id={`${index}.start`}
                        className="ef3-creativeRotationSelector_scheduledRowEndPicker"
                        timezone={timezone}
                        onChange={currentValue =>
                            handleFormChange({
                                target: {
                                    id: `${index}.start`,
                                    value: moment(currentValue.start).toISOString(),
                                },
                            })
                        }
                        value={{
                            start: value,
                            end: currentData.end,
                        }}
                        firstSelectableDate={moment
                            .tz(previousFlight.end || now, timezone)
                            .add(index === 0 ? 5 : 1, 'minutes')}
                        visibleHalf="start"
                        resetable
                        disabled={isEdit && now.isAfter(moment.tz(value, timezone))}
                        displayText={
                            value
                                ? moment(value)
                                      .tz(timezone)
                                      .format('MMM DD, YYYY HH:mm z')
                                : 'Select start date'
                        }
                        error={showErrors && errors[`flights.${index}.start`]}
                    />
                </Box>
            );
        },
    },
    {
        Header: 'End',
        id: 'end',
        required: true,
        size: 'medium',
        Cell: ({ value, index, currentData, handleFormChange, data }) => {
            let firstFlight = data && data.length && data[0];
            return (
                <Box sx={{ minWidth: 200 }}>
                    <HalfDateTimeRangePicker
                        id={`${index}.end`}
                        className="ef3-creativeRotationSelector_scheduledRowEndPicker"
                        timezone={timezone}
                        onChange={currentValue =>
                            handleFormChange({
                                target: {
                                    id: `${index}.end`,
                                    value: moment(currentValue.end).toISOString(),
                                },
                            })
                        }
                        value={{
                            start: currentData.start,
                            end: value,
                        }}
                        firstSelectableDate={
                            currentData.start &&
                            moment.tz(currentData.start, timezone).add(1, 'minutes')
                        }
                        lastSelectableDate={
                            firstFlight &&
                            firstFlight.start &&
                            moment.tz(firstFlight.start, timezone).add(1, 'years')
                        }
                        visibleHalf="end"
                        resetable
                        disabled={
                            (isEdit && !currentData.start) ||
                            now.isAfter(moment.tz(value, timezone))
                        }
                        displayText={
                            value
                                ? moment(value)
                                      .tz(timezone)
                                      .format('MMM DD, YYYY HH:mm z')
                                : 'Select end date'
                        }
                        minWidth={170}
                        error={showErrors && errors[`flights.${index}.end`]}
                    />
                </Box>
            );
        },
    },
    {
        Header: 'Revenue Budget',
        id: 'billings_local',
        required: revenueModel === RevenueModelMapping.AgencyMargin,
        showOn: () => revenueModel === RevenueModelMapping.AgencyMargin,
        Cell: ({ value: currentValue, index, handleFormChange, currentData }) => {
            return (
                <div>
                    <TextFieldFormatter
                        initialValue={currentValue}
                        shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                        formatIn={v => numeral(v).format('0,0.00')}
                        formatOut={v => numeral(v).value()}
                        onChange={formattedValue => {
                            handleFormChange({
                                target: { id: `${index}.billings_local`, value: formattedValue },
                            });
                            handleFormChange({
                                target: {
                                    id: `${index}.total_cost_budget`,
                                    value: formattedValue - formattedValue * billing_rate,
                                },
                            });
                        }}
                        renderInput={({ value, onChange, onBlur }) => (
                            <TextField
                                id={`${index}.billings_local`}
                                disableMargin
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                startAdornment={<div>$</div>}
                                disabled={now.isAfter(moment.tz(currentData.end, timezone))}
                                error={showErrors && errors[`flights.${index}.billings_local`]}
                            />
                        )}
                    />
                    <Typography color="error">
                        {showErrors && errors[`flights.${index}.billings_local`]}
                    </Typography>
                </div>
            );
        },
    },
    {
        Header: 'Total Impressions',
        id: 'total_impressions',
        showOn: () => [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(revenueModel),
        required: true,
        Cell: ({ value: currentValue, index, handleFormChange, currentData }) => {
            return (
                <div>
                    <TextFieldFormatter
                        initialValue={currentValue}
                        shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                        formatIn={v => numeral(v).format('0,0')}
                        formatOut={v => numeral(v).value()}
                        onChange={formattedValue => {
                            handleFormChange({
                                target: { id: `${index}.total_impressions`, value: formattedValue },
                            });
                        }}
                        renderInput={({ value, onChange, onBlur }) => {
                            return (
                                <TextField
                                    id={`${index}.total_impressions`}
                                    disableMargin
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    disabled={now.isAfter(moment.tz(currentData.end, timezone))}
                                    // readOnly={revenueModel === 'agencyMargin'}
                                    error={
                                        showErrors && errors[`flights.${index}.total_impressions`]
                                    }
                                />
                            );
                        }}
                    />
                    <Typography color="error">
                        {showErrors && errors[`flights.${index}.total_impressions`]}
                    </Typography>
                </div>
            );
        },
    },
    {
        Header: 'Total Cost Budget',
        id: 'total_cost_budget',
        showOn: () => ![RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(revenueModel),
        required: true,
        Cell: ({ value: currentValue, index, handleFormChange, currentData }) => {
            return revenueModel !== RevenueModelMapping.AgencyMargin ? (
                <div>
                    <TextFieldFormatter
                        initialValue={currentValue}
                        shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                        formatIn={v => numeral(v).format('0,0.00')}
                        formatOut={v => numeral(v).value()}
                        onChange={formattedValue => {
                            handleFormChange({
                                target: { id: `${index}.total_cost_budget`, value: formattedValue },
                            });
                        }}
                        renderInput={({ value, onChange, onBlur }) => {
                            return (
                                <TextField
                                    id={`${index}.total_cost_budget`}
                                    disableMargin
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    startAdornment={<div>$</div>}
                                    disabled={now.isAfter(moment.tz(currentData.end, timezone))}
                                    readOnly={revenueModel === RevenueModelMapping.AgencyMargin}
                                    error={
                                        showErrors && errors[`flights.${index}.total_cost_budget`]
                                    }
                                />
                            );
                        }}
                    />
                    <Typography color="error">
                        {showErrors && errors[`flights.${index}.total_cost_budget`]}
                    </Typography>
                </div>
            ) : (
                <Typography align={'right'}>{`${formatNumber_currency(currentValue)}`}</Typography>
            );
        },
    },
    {
        Header: 'Spent',
        id: 'owner_total_media_cost_local',
        showOn: () => revenueModel !== RevenueModelMapping.CPMCPC,
        Cell: ({ currentData }) => {
            return (
                <Typography align={'right'}>
                    {`${formatNumber_currency(
                        getSpentValue({ flightId: currentData.id, flightMetrics, revenueModel })
                    )}`}
                </Typography>
            );
        },
    },
    {
        Header: 'Delivered',
        id: 'currentDelivered',
        showOn: () => [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(revenueModel),
        Cell: ({ currentData }) => {
            return (
                <Typography align={'right'}>
                    {`${formatNumber_whole(
                        getSpentValue({ flightId: currentData.id, flightMetrics, revenueModel })
                    )}`}
                </Typography>
            );
        },
    },
    {
        Header: 'Remaining',
        id: 'remaining',
        Cell: ({ currentData }) => {
            return (
                <Typography align={'right'}>
                    {[RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(revenueModel)
                        ? `${formatNumber_whole(
                              getRemainingValue({ flightId: currentData.id, flightMetrics })
                          )}`
                        : `${formatNumber_currency(
                              getRemainingValue({ flightId: currentData.id, flightMetrics })
                          )}`}
                </Typography>
            );
        },
    },
    {
        Header: '',
        id: 'remove',
        Cell: ({ index, currentData }) => {
            const hasStarted = now.isAfter(moment.tz(currentData.start, timezone));
            return (
                <DeleteIcon
                    color={`${(!index || hasStarted) && 'disabled'}`}
                    disabled={!index || hasStarted}
                    onClick={() => removeFlight(index)}
                    style={{
                        cursor: 'pointer',
                        pointerEvents: !index || hasStarted ? 'none' : undefined,
                    }}
                />
            );
        },
    },
];

export const FlightsTable = props => {
    const {
        draft: { default_timezone: timezone, flights, revenueModel, billing_rate },
        addNewFlight,
        removeFlight,
        updateFlight,
        errors,
        showErrors,
        flightMetrics,
    } = useCampaignForm();
    const now = moment().tz(timezone);
    return (
        <React.Fragment>
            <TableForm
                data={flights}
                columns={generateTableConfig(
                    now,
                    timezone,
                    removeFlight,
                    showErrors,
                    errors,
                    props.isEdit,
                    flightMetrics,
                    revenueModel,
                    billing_rate
                )}
                onChange={updateFlight}
            />
            <Button color="primary" onClick={addNewFlight}>
                + Add New Flight
            </Button>
        </React.Fragment>
    );
};

function getSpentValue({ flightId, flightMetrics, revenueModel }) {
    const flight = find(flightMetrics, flight => flight.flightId === flightId);
    if (flight) {
        if (revenueModel === RevenueModelMapping.AgencyMargin) {
            return flight.billings_local;
        }

        if ([RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(revenueModel)) {
            return flight.impressions;
        }

        return flight.owner_total_media_cost_local;
    }
    return 0;
}

function getRemainingValue({ flightId, flightMetrics }) {
    const flight = find(flightMetrics, flight => flight.flightId === flightId);
    return flight ? flight.remaining : null;
}

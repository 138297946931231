import moment from 'moment';

const SAFE_DATE_FOR_AUDIENCE_DATA = moment('2018-08-23T00:00:00.000Z');

export function audienceDimensionIsHidden(campaignOptions, campaignsInDraft, dateRangeInDraft) {
    if (dateRangeInDraft && dateRangeInDraft.type !== 'automatic') {
        if (moment(dateRangeInDraft.start).isSameOrAfter(SAFE_DATE_FOR_AUDIENCE_DATA)) {
            return false;
        }
        return true;
    }

    const draftCampaigns = campaignsInDraft.ids;
    let campaignsIncluded;

    if (campaignsInDraft.mode === 'exclude') {
        // If exclude is selected, match all non-selected campaigns
        campaignsIncluded = _.filter(
            campaignOptions,
            campaignOption => draftCampaigns.indexOf(campaignOption.value) === -1
        );
    } else {
        campaignsIncluded = _.filter(
            campaignOptions,
            campaignOption => draftCampaigns.indexOf(campaignOption.value) !== -1
        );

        if (campaignsIncluded.length === 0) {
            campaignsIncluded = campaignOptions.slice();
        }
    }

    // Only allow this dimension when the campaigns contain good audience data.
    return _.some(campaignsIncluded, ['mayContainBadAudienceReportData', true]);
}

import { actions } from './reducer';
import { graphqlRequest } from 'utils/http/redux';

const mw = () => ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === actions.init.type) {
        const campaignId = action.payload;
        const res = await dispatch(getCampaignSummaryData(campaignId));
        dispatch(actions.initSuccess(res.data));
    }

    if (
        action.type === actions.fetchArchivedAdsAndCreatives.type ||
        action.type === 'OVERVIEW_ADS__AD__ARCHIVE__SUCCESS' ||
        action.type === 'creativesOverviewV2:creativeArchiveSuccess'
    ) {
        const { campaignId } = action.payload;
        const res = await dispatch(getArchivedAdsAndCreatives(campaignId));
        dispatch(actions.fetchArchivedAdsAndCreativesSuccess(res.data.campaign));
    }
};

function getCampaignSummaryData(campaignId) {
    try {
        return graphqlRequest({
            query: `
                query fetchCampaignSummaryData($campaignId: Int) {
                    organizations(filters: {relationship: "child", type: ["client", "co_managed_client"]}) {
                        id
                        name
                        currency
                        default_timezone
                        custom_fields {
                            name
                            value
                            required
                            key
                        }
                        type
                        client_type
                        revenueModel
                        ftaEnabled
                        fta_partner_id
                        agencyMarginRate
                    }
                    ownOrganization {
                        id
                        type
                        fta_partner_id
                        agencyMarginRate
                        revenueModel
                        users {
                            first_name
                            last_name
                            id
                            suspended
                            global_role
                        }
                        ftaEnabled
                    }
                    ftaLocationLists {
                        id
                        name
                    }
                    campaign(id: $campaignId) {
                        id
                        name
                        paused
                        status
                        currency
                        currency_rate
                        isCrossPlatformCampaign
                        budget_allocation_method
                        billing_enabled
                        shouldHideFlexSegments
                        tech_fee
                        advertiser_domain
                        iab_categories
                        revenueModel
                        automaticBudgetAllocationOptimizationStrategy
                        billing_rate
                        campaign_max_total_spend_local
                        campaign_max_total_impressions
                        clients {
                            type
                            organization
                        }
                        creatorId
                        custom_fields {
                            value
                            required
                            name
                            key
                        }
                        default_timezone
                        fta_conversion_lookback_window {
                            type
                            lookback_window_days
                        }
                        fta_enabled
                        fta_lift_version
                        fta_location_list
                        fta_management_level
                        fta_partner_id
                        fta_pre_campaign_interval_days
                        fta_version
                        isUsingFtaFullyManagedFields
                        notes
                        third_party_fees {
                            description
                            billing_model
                            fee
                            billable
                            disabled
                        }
                        total_billings_local
                        use_front_load_pacing
                        restricted_category
                    }
                }
            `,
            variables: {
                campaignId: Number(campaignId),
            },
        });
    } catch (err) {
        console.error(`GET ALL organizations error`, err);

        throw err;
    }
}

function getArchivedAdsAndCreatives(campaignId) {
    try {
        return graphqlRequest({
            query: `
                query fetchArchivedAdsAndCreatives($campaignId: Int) {
                    campaign(id: $campaignId) {
                        ads {
                            isArchived
                        }
                        creatives {
                            isArchived
                        }
                    }
                }
            `,
            variables: {
                campaignId: Number(campaignId),
            },
        });
    } catch (err) {
        console.error(`GET archived ads and creatives error`, err);

        throw err;
    }
}

export const makeMiddlewares = deps => {
    return [mw(deps)];
};

export const middlewares = makeMiddlewares({});

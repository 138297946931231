import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';

import Section from 'widgets-v5/section';
import FormField from 'widgets-v5/form-field';
import StandardInput, { allowInts, stringToInt, intToString } from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
import shallowCompare from 'utils/shallow-compare';
import classnames from 'classnames';
import { VerticalRadioSelector } from 'widgets-v5/radio-selector';
import { calculateFrequencyCapMode } from 'states/resources/ads/business-logic';

export class FrequencyCapForm extends React.Component {
    fillRange = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((item, index) => start + index);
    };

    updateDraft = (name, val, updateDraft, draft) => {
        if (name === 'interval_unit' && val === 'ad') {
            updateDraft({
                fcaps: {
                    ...draft.fcaps,
                    start: Date.now(),
                    [name]: val,
                    interval_count: 1,
                },
            });
        } else {
            updateDraft({
                fcaps: {
                    ...draft.fcaps,
                    start: Date.now(),
                    [name]: val,
                },
            });
        }
    };

    toggleFrequencyCapMode = (updateDraft, draft) => {
        if (draft.fcaps.imp === null) {
            updateDraft({
                fcaps: {
                    imp: 5,
                    interval_count: 1,
                    interval_unit: 'days',
                    start: Date.now(),
                },
            });
        } else {
            updateDraft({
                fcaps: {
                    imp: null,
                    interval_count: null,
                    interval_unit: null,
                    start: Date.now(),
                },
            });
        }
    };

    render() {
        const { ad, ads, campaign, onSave } = this.props;

        const impressionsOptionsArray = this.fillRange(1, 99);

        const intervalCountOptionsArray = this.fillRange(1, 99);

        const impressionsOptions = impressionsOptionsArray.map(option => ({
            label: option.toString(),
            value: option,
        }));

        const intervalCountOptions = intervalCountOptionsArray.map(option => ({
            label: option.toString(),
            value: option,
        }));

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Frequency Cap"
                fields={['fcaps']}
                shouldRefreshPredictionsOnSave={true}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => {
                    const frequencyCapMode = calculateFrequencyCapMode(draft);
                    return (
                        <FormField
                            errors={errors.max_user_frequency}
                            showErrors={shouldShowErrors}
                            isRequired={true}
                            label={ads ? 'Frequency Cap' : ''}
                        >
                            <div className="ef5-ad-setup__row">
                                <VerticalRadioSelector
                                    onChange={() => this.toggleFrequencyCapMode(updateDraft, draft)}
                                    activeItem={frequencyCapMode}
                                    items={[
                                        { label: 'Limit to', value: 'limited' },
                                        { label: 'No limit', value: 'unlimited' },
                                    ]}
                                />

                                <div className="ef6-alignment__flex ef5-ad-setup-optimization__limit-impression">
                                    <StandardSelector
                                        disabled={frequencyCapMode === 'unlimited'}
                                        className="ef5-ad-setup-optimization__selector"
                                        onChange={val =>
                                            this.updateDraft('imp', val, updateDraft, draft)
                                        }
                                        value={draft.fcaps.imp}
                                        items={impressionsOptions}
                                    />
                                    <span className="ef5-ad-setup-optimization__limit-impression-label">
                                        impression(s) per
                                    </span>
                                </div>

                                <div className="ef5-ad-setup-optimization__limit-interval">
                                    <div className="ef6-alignment__flex">
                                        {draft.fcaps.interval_unit === 'ad' ? null : (
                                            <StandardSelector
                                                virtual
                                                disabled={
                                                    frequencyCapMode === 'unlimited' ||
                                                    this.props.ftaModeLocked
                                                }
                                                className="ef5-ad-setup-optimization__selector"
                                                onChange={val =>
                                                    this.updateDraft(
                                                        'interval_count',
                                                        val,
                                                        updateDraft,
                                                        draft
                                                    )
                                                }
                                                value={draft.fcaps.interval_count}
                                                items={intervalCountOptions}
                                            />
                                        )}
                                        <StandardSelector
                                            disabled={
                                                frequencyCapMode === 'unlimited' ||
                                                this.props.ftaModeLocked
                                            }
                                            onChange={val =>
                                                this.updateDraft(
                                                    'interval_unit',
                                                    val,
                                                    updateDraft,
                                                    draft
                                                )
                                            }
                                            value={draft.fcaps.interval_unit}
                                            items={[
                                                { label: 'hour(s)', value: 'hours' },
                                                { label: 'day(s)', value: 'days' },
                                                { label: 'week(s)', value: 'weeks' },
                                                { label: 'lifetime of ad', value: 'ad' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </FormField>
                    );
                }}
            </BaseColumnForm>
        );
    }
}

const labelMap = {
    days: 'day(s)',
    hours: 'hour(s)',
    weeks: 'week(s)',
    ad: 'lifetime of ad',
};


export class FrequencyCapSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;

        return (
            <div>
                {error ? (
                    <div>{error}</div>
                ) : (
                    <div>
                        {ad.fcaps.interval_unit ? (
                            <span>
                                {ad.fcaps.imp} impressions per {ad.fcaps.interval_count}{' '}
                                {labelMap[ad.fcaps.interval_unit]}
                            </span>
                        ) : (
                            <span>No Limit</span>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

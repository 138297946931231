import _ from 'lodash';
import findCacheState from 'utils/find-cache-state';
import c from 'common/constants/flux-events';
const initialState = {};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.REPORT_CHANNEL__AVAILABLE_ADGROUPS__FETCH_SUCCESS: {
            const { campaign } = action.payload;

            const adGroupingDict = {};
            const adGroupDict = {};

            _.forEach(campaign.customDimensions, dimension => {
                adGroupingDict[dimension.id] = {};
                adGroupDict[dimension.id] = {
                    value: dimension.name,
                };
                _.forEach(dimension.groups, group => {
                    adGroupingDict[dimension.id][group.id] = {
                        value: group.name,
                    };
                });
            });

            return {
                ...state,
                ...adGroupingDict,
                scope: adGroupDict,
            };
        }
        default:
            return state;
    }
}

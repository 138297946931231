import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { SearchInput } from 'widgets-v6/standard-input';

import actions from '../../actions';
import selector from './selector';

function AdvertisersList(props) {
    const {
        advertisers,
        advertiserIdSelected,
        onClickAllAdvertisers,
        onClickAdvertiser,
        isInitialized,
    } = props;

    const [searchText, setSearchText] = useState('');

    const handleSearch = _.debounce(text => {
        setSearchText(_.trim(text));
    }, 200);

    const advertisersFiltered = _.filter(advertisers, advertiser => {
        if (!searchText) {
            return true;
        }

        if (advertiser.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
            return true;
        }

        return false;
    });

    return (
        <Paper>
            <Box p={2}>
                <SearchInput
                    placeholder="Filter Pixels by Advertisers"
                    defaultValue={searchText}
                    onChange={event => handleSearch(event.target.value)}
                />
            </Box>
            {!isInitialized && <LinearProgress />}
            {isInitialized && advertisers.length === 0 && (
                <Typography align="center">No results found</Typography>
            )}
            {isInitialized && advertisers.length > 0 && (
                <Box height="70vh" overflow="auto">
                    <List>
                        <ListItem
                            onClick={onClickAllAdvertisers}
                            selected={!advertiserIdSelected}
                            button
                        >
                            <ListItemText>All advertisers</ListItemText>
                        </ListItem>
                        {_.map(advertisersFiltered, advertiser => (
                            <ListItem
                                key={advertiser.id}
                                onClick={() => onClickAdvertiser(advertiser)}
                                selected={advertiserIdSelected === advertiser.id}
                                button
                            >
                                <ListItemText>{advertiser.name}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Paper>
    );
}

function mapDispatch(dispatch) {
    return {
        onClickAllAdvertisers: () => dispatch(actions.viewAllAdvertisers()),
        onClickAdvertiser: advertiser => dispatch(actions.viewAdvertiser(advertiser)),
    };
}

export default connect(
    selector,
    mapDispatch
)(AdvertisersList);

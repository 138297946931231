import _ from 'lodash';

export default function selector(storeState, props) {
    const { campaignId } = props.params;

    return { campaignId };
}

function getImplicitLayer(storeState, campaignId) {
    const geoLayers_super = _.get(storeState, `resources.geoCategories.geoLayers`, void 0);
    const form = _.get(storeState, `adNewForm.form`, {});
    const customLayers_draft = _.get(form, 'draft.geo_targeting_settings.custom_layers', void 0);

    const implicitLayer = _(geoLayers_super)
        .filter(itm => {
            return itm.name === '___implicit' && _.includes(customLayers_draft, itm.id);
        })
        .value()[0];

    return implicitLayer;
}

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class MediaPreview extends React.Component {
    static defaultProps = { width: 100, height: 100 };
    state = { isActive: false };

    onMouseOver = () => {
        if (this.props.type === 'video') {
            let playPromise = this.refs.video.play();

            // As of Chrome 50, `.play()` returns a promise
            // and `.pause()` causes it to throw an error.
            if (playPromise) {
                playPromise.catch(e => {
                    if (e.name !== 'AbortError') {
                        throw e;
                    }
                });
            }
        }
        this.setState({ isActive: true });
    };

    onMouseOut = () => {
        if (this.props.type === 'video') {
            this.refs.video.pause();
            this.refs.video.currentTime = 0;
        }
        this.setState({ isActive: false });
    };

    render() {
        return (
            <div
                className={classnames('ef4-media-preview', this.props.className)}
                style={{
                    width: `${this.props.width}px`,
                    height: `${this.props.height}px`,
                    backgroundImage: `url('${
                        this.props.type === 'video' ? this.props.poster : this.props.src
                    }')`,
                }}
            >
                <a href={this.props.src} target="_blank">
                    <div
                        className="ef4-media-preview__hover-area"
                        onMouseOver={this.onMouseOver}
                        onMouseOut={this.onMouseOut}
                    />
                </a>
                {this.props.type === 'image' && (
                    <img
                        className={classnames('ef4-media-preview__preview', {
                            'ef4-media-preview__preview_is-active': this.state.isActive,
                        })}
                        src={this.props.src}
                    />
                )}
                {this.props.type === 'video' && (
                    <video
                        ref="video"
                        muted
                        loop
                        preload="none"
                        className={classnames('ef4-media-preview__preview', {
                            'ef4-media-preview__preview_is-active': this.state.isActive,
                        })}
                        src={this.props.src}
                    />
                )}
            </div>
        );
    }
}

export class ImagePreview extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        src: PropTypes.string.isRequired,
    };

    render() {
        return <MediaPreview {...this.props} />;
    }
}

export class VideoPreview extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        poster: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
    };

    render() {
        return <MediaPreview {...this.props} />;
    }
}

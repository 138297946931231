import React from 'react';
import ChipMui from '@mui/material/Chip';
import BoxMui from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { createStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

const Box = styled(BoxMui)(({ theme }) => ({
    '&.MuiBox-root': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        position: 'relative',
        margin: `${theme.spacing(3.75)} 0px`,
    },
}));

const styles = () =>
    createStyles({
        chipContainer: {
            position: 'absolute',
            top: '-9px',
            left: '10px',
        },
        customChip: {
            fontSize: '10px',
            height: '19px',
        },
        label: {
            padding: '0px 8px',
        },
    });

const LogicalDivider = (props) => {
    return (
        <Box>
            <div className={props.classes.chipContainer}>
                <ChipMui label={props.label} classes={{ root: props.classes.customChip, label: props.classes.label }} />
            </div>
        </Box>
    );
};

export default withStyles(styles)(LogicalDivider);

import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { connect, useSelector } from 'react-redux';
import selector from './selector';

import { ProgressLayout } from './layout';
import { GraphLayout } from './progress-graph/graph-layout';

import {
    fetchCampaignDeliveryData,
    updateTimezoneAtCampaignLevel,
    resetState,
} from 'pages/campaigns/progress/campaign-progress/actions';

import CenterLayout from 'widgets-v5/center-layout';
import { BlockLoadGroup } from 'widgets-v5/load-group';

const CampaignProgress = createReactClass({
    displayName: 'CampaignProgress',

    propTypes: {
        campaign: PropTypes.object,
        ads: PropTypes.array,
    },

    statics: {
        refresh() {},
    },

    init() {
        const { dispatch, location, params, routes, timezoneAtCampaignLevel, campaign } = this.props;
        const nextState = { location, params, routes };
        this.setState({ isLoading: true });
        dispatch(fetchCampaignDeliveryData(nextState.params.campaignId, null, timezoneAtCampaignLevel || campaign.default_timezone)).then(() => {
            this.setState({ isLoading: false });
        });
    },

    componentDidUpdate(prevProps) {
        if (
            this.props.params.campaignId !== prevProps.params.campaignId ||
            this.props.flightId !== prevProps.flightId
        ) {
            this.init();
        }
    },

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(resetState());
    },

    changeTimezoneAtCampaignLevel(timezone) {
        this.props.dispatch(updateTimezoneAtCampaignLevel(timezone));
    },

    componentDidMount() {
        this.init();

        this.setState({
            mounted: true,
        });
    },

    render() {
        if (!this.props.previouslyLoaded && this.props.isLoading) {
            return (
                <CenterLayout>
                    <BlockLoadGroup isLoading={true} />
                </CenterLayout>
            );
        }
        if (_.last(this.props.routes).path === 'overall') {
            return (
                <GraphLayout
                    {...this.props}
                    changeTimezoneAtCampaignLevel={this.changeTimezoneAtCampaignLevel}
                />
            );
        }

        return (
            <ProgressLayout
                {...this.props}
                isLoading={false}
                changeTimezoneAtCampaignLevel={this.changeTimezoneAtCampaignLevel}
            />
        );
    },
});

function CampaignProgressWrapper(props) {
    const { viewBy } = useSelector(state => state.campaignPageContainer);

    let flightId = null;
    if (viewBy !== 'campaign') {
        flightId = viewBy;
    }

    return <CampaignProgress {...props} flightId={flightId} />;
}

export default connect(selector)(CampaignProgressWrapper);

import get from 'lodash/get';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Sidebar from 'containers/sidebar';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import flags from 'containers/flags/service';

import selector from './selector';
import { bootstrap } from 'states/profile/actions';
import config from '../../../../config';

import Login from 'pages/login';
import DevSettings from 'containers/dev-settings';
import { StaleFlags } from 'containers/stale-flags';
import { PanelsLayout, WorkspacePanel, OverlaySidebarPanel } from 'widgets-v5/panels-layout';

import { slideToggleSidebar } from 'containers/sidebar/actions';
import JobsProgressWindow from 'containers/report-jobs/progress-window';

import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';
import { isInternalUser } from 'states/profile/business-rules';
import { getEnvironmentSettings } from 'services/environment';
import { userBelongsTo } from 'states/profile/business-rules';
import CircularProgress from '@mui/material/CircularProgress';

if (window.location.search === '?reset') {
    flags.reset();
    window.location = window.location.pathname;
}

export const RoutePermissionMapping = [
    {
        route: props => props.dashboardUrl.split('?')[0],
        auth: ({ globalRole }) => {
            return can(PermissionsMapping.DASHBOARD__VIEW, globalRole);
        },
    },
    {
        route: () => '/home',
        auth: ({ globalRole }) => {
            return can(PermissionsMapping.OVERVIEW__VIEW, globalRole);
        },
    },
    {
        route: () => /^\/campaigns\/\d*\/?(setup|progress|history)?\/?/,
        auth: ({ globalRole }) => can(PermissionsMapping.CAMPAIGN__VIEW, globalRole),
    },
    {
        route: () => /^\/campaigns\/\d*\/(report|client-report)\/?/,
        auth: ({ globalRole }) => can(PermissionsMapping.REPORT__VIEW, globalRole),
    },
    {
        route: () => '/home/self-serve',
        auth: () => {
            return isInternalUser();
        },
    },
    {
        route: () => '/inventory',
        auth: ({ globalRole }) => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseInventoryForecast()) {
                return false;
            }
            return can(PermissionsMapping.CAMPAIGN__VIEW, globalRole);
        },
    },
    {
        route: () => '/reports',
        auth: ({ globalRole }) => {
            return can(PermissionsMapping.CAMPAIGN__VIEW, globalRole);
        },
    },
    {
        route: () => '/account',
        auth: ({ globalRole }) => {
            return can(PermissionsMapping.ACCOUNT__VIEW, globalRole);
        },
    },
    {
        route: () => '/admin',
        auth: ({ profileState, globalRole }) => {
            if (
                includes(
                    [
                        'admin',
                        'campaign_manager',
                        'media_planner',
                        'user_admin',
                        'account_admin',
                        'read_only_super_admin',
                    ],
                    profileState.globalRole
                ) &&
                can(PermissionsMapping.ADMIN__VIEW, globalRole)
            ) {
                return true;
            }
        },
    },
    {
        route: () => '/audiences',
        auth: ({ profileState, globalRole }) => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseAudiences()) {
                return false;
            }
            return (
                userBelongsTo(profileState, ['addictive mobility', 'root', 'el tiempo']) ||
                can(PermissionsMapping.AUDIENCE__VIEW, globalRole)
            );
        },
    },
    {
        route: () => '/fta-location-lists',
        auth: ({ globalRole }) => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseFta()) {
                return false;
            }
            return can(PermissionsMapping.FTA__VIEW, globalRole);
        },
    },
    {
        route: () => '/apps-and-sites',
        auth: ({ globalRole }) => {
            return can(PermissionsMapping.CAMPAIGN__VIEW, globalRole);
        },
    },
    {
        route: () => '/pixels',
        auth: ({ globalRole }) => {
            return can(PermissionsMapping.PIXEL__VIEW, globalRole);
        },
    },
    {
        route: () => '/ad-preset',
        auth: ({ globalRole }) => {
            const environmentSettings = getEnvironmentSettings();
            if (!environmentSettings.canUseAdPresets()) {
                return false;
            }
            return can(PermissionsMapping.ADPRESET__VIEW, globalRole);
        },
    },
    {
        route: () => '/profile',
        auth: () => true,
    },
    {
        route: () => '/flags',
        auth: () => true,
    },
];

class Root extends React.Component {
    state = {
        isBootstrapped: false,
    };

    tryBootstrap = () => {
        if (!this.props.isLoggedIn) {
            this.state.isBootstrapped = false;
            return;
        }

        if (!this.state.isBootstrapped) {
            this.props.dispatch(bootstrap());
            this.state.isBootstrapped = true;
        }
    };

    toggleSliding = () => {
        this.props.dispatch(slideToggleSidebar());
    };

    render() {
        this.tryBootstrap();
        if (this.props.nonLoginRoutedPage) {
            return this.props.nonLoginRoutedPage;
        }
        if (!this.props.isLoggedIn) {
            return (
                <div className="ef3-root">
                    <Login {...this.props} />
                    <DevSettings />
                </div>
            );
        } else {
            // ---- Sort out intent ----------
            const pathName = get(this.props, `location.pathname`, void 0);

            // Check if the user has access to the page he's currently trying to access.
            if (!this.props.isLoadingUser && pathName !== '/') {
                const routeObject = RoutePermissionMapping.find(({ route }) => {
                    const currentRoute = new RegExp(route(this.props));
                    return currentRoute.test(pathName);
                });

                const hasPermission = routeObject ? routeObject.auth(this.props) : false;

                if (!hasPermission) {
                    // User doesn't have access to this route.
                    // Find the first route the user has access to and boot him to that instead.
                    const firstRouteUserHasAccess = RoutePermissionMapping.find(({ auth }) =>
                        auth(this.props)
                    );
                    browserHistory.replace(`${firstRouteUserHasAccess.route(this.props)}`);
                    return null;
                }
            }

            const currentPath = this.props.location.pathname;
            const isCampaign = /campaigns/.test(currentPath);
            const isBusinessReports = /reports/.test(currentPath);
            const isDashboard = /dashboard/.test(currentPath);
            const isAccount = /account/.test(currentPath);
            const isAdmin = /admin/.test(currentPath);
            const isProfile = /profile/.test(currentPath);
            const isAudiences = /audiences/.test(currentPath);

            let mobileMenuTitle;

            if (isCampaign || isDashboard) {
                mobileMenuTitle = 'campaigns';
            } else if (isBusinessReports) {
                mobileMenuTitle = 'reports';
            } else if (isAudiences) {
                mobileMenuTitle = 'audiences';
            } else if (isAccount) {
                mobileMenuTitle = 'account';
            } else if (isAdmin) {
                mobileMenuTitle = 'admin';
            } else if (isProfile) {
                mobileMenuTitle = 'profile';
            } else {
                null;
            }

            return (
                <div className="ef5-root">
                    <PanelsLayout direction="row" className="ef5-root__layout">
                        <OverlaySidebarPanel
                            className={classnames(
                                { 'ef5-root__sidebar--is-on-screen': this.props.sidebarIsOnScreen },
                                {
                                    'ef5-root__sidebar--is-off-screen': !this.props
                                        .sidebarIsOnScreen,
                                }
                            )}
                        >
                            <Sidebar {...this.props} />
                        </OverlaySidebarPanel>
                        <WorkspacePanel
                            className={classnames(
                                {
                                    'ef5-root__workspace--is-off-screen': this.props
                                        .sidebarIsOnScreen,
                                },
                                {
                                    'ef5-root__workspace--is-on-screen': !this.props
                                        .sidebarIsOnScreen,
                                }
                            )}
                        >
                            <div className="ef5-root__mobile-nav" onClick={this.toggleSliding}>
                                <div>
                                    <div className="ef5-root__hamburger-icon" />
                                </div>
                                <div className="ef5-root__mobile-nav-title">{mobileMenuTitle}</div>
                            </div>
                            <div
                                onClick={this.toggleSliding}
                                className={classnames('ef5-root__workspace-shade', {
                                    'ef5-root__workspace-shade_is-active': this.props
                                        .sidebarIsOnScreen,
                                })}
                            />
                            {this.props.isLoadingUser || !this.props.workspace ? (
                                <CircularProgress isLoading={true} />
                            ) : (
                                this.props.workspace
                            )}
                        </WorkspacePanel>
                    </PanelsLayout>
                    {config.DEV_SETTINGS_ENABLED && <DevSettings />}
                    <JobsProgressWindow />
                    <StaleFlags />
                </div>
            );
        }
    }
}

function provideGlobalRefresh(Component) {
    return class extends React.Component {
        static childContextTypes = {
            globalRefresh: PropTypes.func,
        };

        getChildContext() {
            const self = this;
            return {
                globalRefresh() {
                    const { dispatch, location, params, routes } = self.props;
                    const nextState = { location, params, routes };

                    forEach(routes, route => {
                        const component = get(route, 'component.WrappedComponent');
                        const components = map(
                            route.components,
                            component => component.WrappedComponent
                        );

                        []
                            .concat(component, components)
                            .filter(c => c)
                            .forEach(component => {
                                if (component && component.refresh) {
                                    component.refresh(dispatch, nextState, browserHistory.push);
                                }
                            });
                    });
                },
            };
        }

        render() {
            return <Component {...this.props} />;
        }
    };
}

export default connect(selector)(provideGlobalRefresh(Root));

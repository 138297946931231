import _ from 'lodash';
import React from 'react';
import OverviewTabs from 'pages/campaigns/campaigns-overview/overview-tabs';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import AdvertiserConversions, { IntegratePixelPopover } from './modules/advertiser-conversions';
import {
    getJavaScriptTag,
    getHtmlTag,
    getPixelUrl,
    canEditConversion,
} from 'states/resources/conversions/business-logic';
import FormField from 'widgets-v5/form-field';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import { PanelsLayout, ScrollingSidebarPanel } from 'widgets-v5/panels-layout';
import { SimpleSelect } from 'widgets-v6/select';
import { WarningBanner } from 'widgets-v5/banners';
import { TableWrapper } from 'widgets-v6/table';
import {
    isCampaignCpaOptimized,
    hasConversionsRequiredWarning,
    hasAnyAdsWithCreativeRotationByConversionOptimization,
} from 'states/resources/campaigns/business-logic';


import Toggle from 'widgets-v5/toggle';
import { getEnv } from 'services/environment';
import { CompatiblityTable } from './modules/compatibility-table';
const useStyles = makeStyles(theme => ({
    attributionWindowUnit: {
        paddingTop: 35,
        paddingLeft: 10,
    },
    attributionWindow: {
        paddingLeft: 28,
    },
    warningMessage: {
        color: 'orange',
    },
    banner: {
        margin: theme.spacing(2),
    },
    compatabilityTableAvailableCell: {
        color: 'green',
    },
    compatabilityTableNotAvailableCell: {
        color: 'red',
    },
    advertiserConversions: {
        marginTop: 20,
        marginBottom: 20,
    },
    editButton: {
        paddingRight: 20,
    },
    buttonContainer: {
        paddingRight: 15,
    },
    testPixelsLabel: {
        marginRight: 30,
    },
}));

const ConversionAttributionEdit = ({
    conversion,
    attributionWindowOptions,
    onNonTextFieldChange,
    errors,
    showErrors,
    shouldShowAttributionWindowWarning,
}) => {
    const classes = useStyles();

    return (
        <Grid container direction="column">
            <Grid item>
                <FormControl component="fieldset">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={conversion.use_click_through}
                                onChange={event =>
                                    onNonTextFieldChange('use_click_through')(
                                        conversion,
                                        event.target.checked
                                    )
                                }
                                color="primary"
                            />
                        }
                        label="Click-through attribution"
                    />
                </FormControl>
            </Grid>
            <Grid item>
                {conversion.use_click_through && (
                    <FormGroup row className={classes.attributionWindow}>
                        <SimpleSelect
                            label={'Attribution window'}
                            options={attributionWindowOptions}
                            value={conversion.click_through_attribution_window}
                            onChange={event =>
                                onNonTextFieldChange('click_through_attribution_window')(
                                    conversion,
                                    event.target.value
                                )
                            }
                        />
                        <span className={classes.attributionWindowUnit}>days</span>
                    </FormGroup>
                )}
            </Grid>
            <Grid item>
                <FormControl component="fieldset">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={conversion.use_view_through}
                                onChange={event =>
                                    onNonTextFieldChange('use_view_through')(
                                        conversion,
                                        event.target.checked
                                    )
                                }
                                color="primary"
                            />
                        }
                        label="View-through attribution"
                    />
                </FormControl>
            </Grid>
            <Grid item>
                {conversion.use_view_through && (
                    <FormGroup row className={classes.attributionWindow}>
                        <SimpleSelect
                            label={'Attribution window'}
                            options={attributionWindowOptions}
                            value={conversion.view_through_attribution_window}
                            onChange={event =>
                                onNonTextFieldChange('view_through_attribution_window')(
                                    conversion,
                                    event.target.value
                                )
                            }
                        />
                        <span className={classes.attributionWindowUnit}>days</span>
                    </FormGroup>
                )}
            </Grid>
            {shouldShowAttributionWindowWarning && (
                <Grid item>
                    <FormHelperText className={classes.warningMessage}>
                        Changes to attribution model and window are only applied for new conversions
                        going forward.
                    </FormHelperText>
                </Grid>
            )}
            {showErrors && (
                <Grid item>
                    <FormControl error>
                        <FormHelperText>{errors}</FormHelperText>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};

const DynamicDataEdit = ({
    conversion,
    onNonTextFieldChange,
    onDynamicDataSubeventNameChange,
    onDynamicDataValueNameChange,
    errors,
    showErrors,
}) => {
    const classes = useStyles();

    return (
        <Grid container direction="column">
            <Grid item>
                <FormControl component="fieldset">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={conversion.use_dynamic_data_subevent}
                                onChange={event =>
                                    onNonTextFieldChange('use_dynamic_data_subevent')(
                                        conversion,
                                        event.target.checked
                                    )
                                }
                                color="primary"
                            />
                        }
                        label="Track event parameter (text string) from GTM, e.g. Product SKU"
                    />
                </FormControl>
            </Grid>
            <Grid item>
                {conversion.use_dynamic_data_subevent && (
                    <React.Fragment>
                        <Typography component="div">
                            <Box fontWeight="fontWeightBold">Google Tag Manager (GTM) Variable</Box>
                        </Typography>
                        <TextFieldFormatter
                            initialValue={conversion.dynamic_data_subevent_name}
                            onChange={value => onDynamicDataSubeventNameChange(conversion, value)}
                            renderInput={({ value, onChange }) => (
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    placeholder={'GTM User-Defined Variable Name'}
                                    disableMargin
                                />
                            )}
                        />
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Enter the name of the Built-In Variable or User-Defined Variable to
                            capture from GTM.
                        </Typography>
                    </React.Fragment>
                )}
            </Grid>
            <Grid item>
                <FormControl component="fieldset">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={conversion.use_dynamic_data_value}
                                onChange={event =>
                                    onNonTextFieldChange('use_dynamic_data_value')(
                                        conversion,
                                        event.target.checked
                                    )
                                }
                                color="primary"
                            />
                        }
                        label="Track event metric (numeric) from GTM, e.g. Product Price"
                    />
                </FormControl>
            </Grid>
            <Grid item>
                {conversion.use_dynamic_data_value && (
                    <React.Fragment>
                        <Typography component="div">
                            <Box fontWeight="fontWeightBold">Google Tag Manager (GTM) Variable</Box>
                        </Typography>
                        <TextFieldFormatter
                            initialValue={conversion.dynamic_data_value_name}
                            onChange={value => onDynamicDataValueNameChange(conversion, value)}
                            renderInput={({ value, onChange }) => (
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    placeholder={'GTM User-Defined Variable Name'}
                                    disableMargin
                                />
                            )}
                        />
                        <Typography variant="body2" gutterBottom color="textSecondary">
                            Enter the name of the Built-In Variable or User-Defined Variable to
                            capture from GTM.
                        </Typography>
                    </React.Fragment>
                )}
            </Grid>
            {showErrors && (
                <Grid item>
                    <FormControl>
                        <FormHelperText className={classes.warningMessage}>{errors}</FormHelperText>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};

const CampaignOverviewConversionsLayout = props => {
    const {
        campaign,
        campaignId,
        draft,
        isLoading,
        isSaving,
        showErrors,
        mode,
        manageButtonEnabled,
        showManageButton,
        headers,
        onOpenManageConversionsView,
        errors,
        onToggleOptimization,
        onReportingNameChange,
        onDynamicDataSubeventNameChange,
        onDynamicDataValueNameChange,
        onDeleteConversion,
        onAddConversion,
        onSaveEdit,
        onCancelEdit,
        attributionWindowOptions,
        onNonTextFieldChange,
        isInTestMode,
        onTestModeChange,
        hasConversions,
        ownOrganization,
    } = props;

    const classes = useStyles();

    function isLastConversionWithOptimizationEnabled(conversions, conversion) {
        const isConversionOptimizationOn = conversion.should_optimize_for_budget_allocation;
        const isOnlyOneConversionWithOptimizationEnabled =
            _.filter(conversions, conversion => conversion.should_optimize_for_budget_allocation)
                .length === 1;

        if (isConversionOptimizationOn && isOnlyOneConversionWithOptimizationEnabled) {
            return true;
        }

        return false;
    }

    return (
        <div className="ef3-campaign-overview-conversions">
            <OverviewTabs tab="pixels" campaignId={campaignId} />
            {hasConversionsRequiredWarning(campaign) && (
                <div className={classes.banner}>
                    <WarningBanner
                        content={
                            <React.Fragment>
                                <Typography>
                                    Create at least 1 pixel to track conversions and enable CPA
                                    Optimization features.
                                </Typography>
                                <br />
                                <Typography>
                                    If conversions are not tracked, the following features will be
                                    disabled:
                                </Typography>
                                {isCampaignCpaOptimized(campaign) && (
                                    <Typography>
                                        - Budget Allocation: Optimize for CPA will be defaulted to
                                        Even Allocation
                                    </Typography>
                                )}
                                {hasAnyAdsWithCreativeRotationByConversionOptimization(
                                    campaign
                                ) && (
                                    <Typography>
                                        - Creative Rotation: Optimize for Conversions will be
                                        defaulted to Even Rotation
                                    </Typography>
                                )}
                            </React.Fragment>
                        }
                    />
                </div>
            )}
            {hasConversions && isInTestMode && (
                <div>
                    <WarningBanner
                        content={
                            <React.Fragment>
                                <Typography variant="h6">
                                    Preview Mode enabled for this browser window.
                                </Typography>
                                <Typography>
                                    Open a new tab and navigate to the website where the pixels
                                    listed below have been implemented. You will receive a
                                    notification when a pixel is triggered. Leaving this page will
                                    automatically turn off Preview Mode.
                                </Typography>
                                <br />
                                <Typography>
                                    Preview Mode only works with JavaScript tags, and currently
                                    cannot detect pixels fired in Safari or within Floodlight tags.
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </div>
            )}
            <CompatiblityTable ownOrganization={ownOrganization} />
            {getEnv() === 'production' && hasConversions ? (
                <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    className={classes.buttonContainer}
                >
                    <Grid item>
                        {canEditConversion(campaign) && (
                            <PreviewModeToggle
                                checked={isInTestMode}
                                handleChange={onTestModeChange}
                            />
                        )}
                    </Grid>
                    <Grid item className={classes.testPixelsLabel}>
                        {canEditConversion(campaign) && <span>PREVIEW MODE</span>}
                    </Grid>
                    <Grid item>
                        {canEditConversion(campaign) && (
                            <EditButton
                                manageButtonEnabled={manageButtonEnabled}
                                onOpenManageConversionsView={onOpenManageConversionsView}
                            />
                        )}
                    </Grid>
                </Grid>
            ) : (
                showManageButton && (
                    <Grid container justifyContent="flex-end" className={classes.editButton}>
                        {canEditConversion(campaign) && (
                            <EditButton
                                manageButtonEnabled={manageButtonEnabled}
                                onOpenManageConversionsView={onOpenManageConversionsView}
                            />
                        )}
                    </Grid>
                )
            )}

            <div className={classes.advertiserConversions}>
                <AdvertiserConversions />
            </div>
            <PanelsLayout
                className="ef3-campaign-overview-conversions__panels-layout"
                direction="column"
            >
                <ScrollingSidebarPanel className="ef3-campaign-overview-conversions__scrolling-side-bar-panel">
                    <Box mx={2}>
                        <TableWrapper title="Campaign Pixels">
                            {isLoading && <LinearProgress />}
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {_.map(headers, header => (
                                            <TableCell>{header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(draft, (conversion, index) => (
                                        <React.Fragment>
                                            {mode === 'view' ? (
                                                <TableRow>
                                                    {isCampaignCpaOptimized(campaign) && (
                                                        <TableCell>
                                                            {conversion.should_optimize_for_budget_allocation
                                                                ? 'On'
                                                                : 'Off'}
                                                        </TableCell>
                                                    )}
                                                    <TableCell>
                                                        {conversion.reporting_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div>
                                                            {conversion.use_click_through &&
                                                                'Click-through attribution'}
                                                        </div>
                                                        <div>
                                                            {conversion.use_view_through &&
                                                                'View-through attribution'}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div>
                                                            {conversion.use_click_through &&
                                                                `${
                                                                    conversion.click_through_attribution_window
                                                                } ${
                                                                    conversion.click_through_attribution_window >
                                                                    1
                                                                        ? 'days'
                                                                        : 'day'
                                                                }`}
                                                        </div>
                                                        <div>
                                                            {conversion.use_view_through &&
                                                                `${
                                                                    conversion.view_through_attribution_window
                                                                } days`}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div>
                                                            {conversion.use_dynamic_data_subevent &&
                                                                `${
                                                                    conversion.dynamic_data_subevent_name
                                                                } (parameter)`}
                                                        </div>
                                                        <div>
                                                            {conversion.use_dynamic_data_value &&
                                                                `${
                                                                    conversion.dynamic_data_value_name
                                                                } (value)`}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IntegratePixelPopover
                                                            textToCopy={{
                                                                javascriptTag: getJavaScriptTag({
                                                                    conversion,
                                                                    campaignId,
                                                                }),
                                                                htmlTag: getHtmlTag({
                                                                    conversion,
                                                                    campaignId,
                                                                }),
                                                                pixelUrl: getPixelUrl({
                                                                    conversion,
                                                                    campaignId,
                                                                }),
                                                            }}
                                                            reportingName={
                                                                conversion.reporting_name
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                <TableRow>
                                                    {isCampaignCpaOptimized(campaign) && (
                                                        <TableCell>
                                                            <FormField>
                                                                <Tooltip
                                                                    title={
                                                                        isLastConversionWithOptimizationEnabled(
                                                                            draft,
                                                                            conversion
                                                                        )
                                                                            ? 'Cannot disable. At least one conversion with CPA optimization enabled must exist.'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={
                                                                                    conversion.should_optimize_for_budget_allocation
                                                                                }
                                                                                onChange={e => {
                                                                                    onToggleOptimization(
                                                                                        conversion,
                                                                                        e.target
                                                                                            .checked
                                                                                    );
                                                                                }}
                                                                                name="checkedA"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={
                                                                            conversion.should_optimize_for_budget_allocation
                                                                                ? 'On'
                                                                                : 'Off'
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            </FormField>
                                                        </TableCell>
                                                    )}
                                                    <TableCell>
                                                        <TextFieldFormatter
                                                            initialValue={conversion.reporting_name}
                                                            onChange={value =>
                                                                onReportingNameChange(
                                                                    conversion,
                                                                    value
                                                                )
                                                            }
                                                            renderInput={({ value, onChange }) => (
                                                                <TextField
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    required
                                                                    helperText={
                                                                        showErrors &&
                                                                        _.get(
                                                                            errors,
                                                                            `${index}.reporting_name`
                                                                        )
                                                                    }
                                                                    error={
                                                                        showErrors &&
                                                                        _.get(
                                                                            errors,
                                                                            `${index}.reporting_name`
                                                                        )
                                                                    }
                                                                    placeholder={'Name'}
                                                                    disableMargin
                                                                />
                                                            )}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <ConversionAttributionEdit
                                                            conversion={conversion}
                                                            attributionWindowOptions={
                                                                attributionWindowOptions
                                                            }
                                                            onNonTextFieldChange={
                                                                onNonTextFieldChange
                                                            }
                                                            errors={
                                                                errors[`${index}.use_view_through`]
                                                            }
                                                            showErrors={showErrors}
                                                            shouldShowAttributionWindowWarning={
                                                                !!_.find(campaign.conversions, {
                                                                    event_name:
                                                                        conversion.event_name,
                                                                })
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <DynamicDataEdit
                                                            conversion={conversion}
                                                            onNonTextFieldChange={
                                                                onNonTextFieldChange
                                                            }
                                                            onDynamicDataSubeventNameChange={
                                                                onDynamicDataSubeventNameChange
                                                            }
                                                            onDynamicDataValueNameChange={
                                                                onDynamicDataValueNameChange
                                                            }
                                                            errors={
                                                                errors[
                                                                    `${index}.dynamic_data_subevent_name`
                                                                ] ||
                                                                errors[
                                                                    `${index}.dynamic_data_value_name`
                                                                ]
                                                            }
                                                            showErrors={showErrors}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() =>
                                                                onDeleteConversion(conversion)
                                                            }
                                                            size="large"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableWrapper>
                        {mode === 'edit' && (
                            <Box mt={2}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Button onClick={onAddConversion} startIcon={<AddIcon />}>
                                            New Campaign Pixel
                                        </Button>
                                    </Grid>
                                    {showErrors && errors.conversions && (
                                        <Grid item>
                                            <FormControl error>
                                                <FormHelperText>
                                                    {errors.conversions}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Grid
                                            container
                                            spacing={1}
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={onSaveEdit}
                                                    disabled={isSaving}
                                                >
                                                    Save
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={onCancelEdit} disabled={isSaving}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Box>
                </ScrollingSidebarPanel>
            </PanelsLayout>
        </div>
    );
};

function PreviewModeToggle(props) {
    const { checked, handleChange } = props;
    return (
        <Toggle
            position={checked ? 'right' : 'left'}
            onClick={handleChange}
            textLeft="off"
            textRight="on"
            width={60}
            height={25}
        />
    );
}

function EditButton(props) {
    return (
        <Button
            onClick={() => {
                if (props.manageButtonEnabled) {
                    props.onOpenManageConversionsView();
                }
            }}
            color="primary"
            variant="contained"
            startIcon={<EditIcon />}
            disabled={!props.manageButtonEnabled}
        >
            Manage Pixels
        </Button>
    );
}

export default CampaignOverviewConversionsLayout;

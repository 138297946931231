import PropTypes from 'prop-types';
import React from 'react';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

class PreFixSuffixInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cachedValue: this.props.value,
            displayedString: this.props.formatIn(trim(this.props.value)),
            lastOut: this.props.value,
            mounted: false,
        };
    }

    static defaultProps = {
        className: '',
        onChange: () => {},
        value: '',
    };

    UNSAFE_componentWillMount() {
        this.emitChange = _.debounce(() => {
            const cachedValue = this.props.formatOut(trim(this.state.displayedString));
            // set lastOut directly, re-render is not necessary
            this.state.lastOut = cachedValue;
            this.props.onChange(cachedValue);
        }, 150);
    }

    componentDidMount() {
        this.setState({
            mounted: true,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value && nextProps.value !== this.state.lastOut) {
            this.setState({
                cachedValue: nextProps.value,
                displayedString: nextProps.formatIn(trim(nextProps.value)),
                lastOut: nextProps.value,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Only ever update if what is displayed needs to be updated
        return (
            nextState.displayedString !== this.state.displayedString ||
            nextProps.disabled !== this.props.disabled
        );
    }

    select() {
        if (this.input) {
            this.input.select();
        }
    }

    focus() {
        if (this.input) {
            this.input.focus();
        }
    }

    onInputChange(event) {
        const domString = event.target.value;
        const nextValue = this.props.formatOut(trim(domString));

        if (this.props.shouldAllowInput(domString)) {
            this.setState({
                cachedValue: nextValue,
                displayedString: domString,
            });
            this.emitChange(nextValue);
        }
    }

    // On blur
    // 1) autocorrect internal value
    // If applicable:
    // 2) flush out all internal changes immediately to prepare for form submission
    // 3) format displayed text
    onInputBlur() {
        const correctedValue = this.state.cachedValue;
        const correctedDisplayString = this.props.formatIn(trim(correctedValue));

        if (correctedDisplayString !== this.state.displayedString) {
            if (this.state.mounted) {
                this.setState({
                    displayedString: correctedDisplayString,
                });
            }
        }
        if (correctedValue !== this.state.cachedValue) {
            this.setState(
                {
                    cachedValue: correctedValue,
                },
                () => this.props.onChange(correctedValue)
            );
        }
    }

    render() {
        return (
            <FormControl>
                <OutlinedInput
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    value={this.state.displayedString}
                    onChange={e => this.onInputChange(e)}
                    onBlur={e => this.onInputBlur(e)}
                    className={this.props.className}
                    startAdornment={
                        this.props.prefix ? (
                            <InputAdornment position="start">{this.props.prefix}</InputAdornment>
                        ) : null
                    }
                    endAdornment={
                        this.props.suffix ? (
                            <InputAdornment position="end">{this.props.suffix}</InputAdornment>
                        ) : null
                    }
                />
            </FormControl>
        );
    }

    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        formatIn: PropTypes.func,
        formatOut: PropTypes.func,
        autocorrect: PropTypes.func,
        placeholder: PropTypes.string,
        shouldAllowInput: PropTypes.func,
    };
}

export function trim(value) {
    if (!_.isString(value)) {
        return value;
    }
    return _.trim(value);
}

export default PreFixSuffixInput;

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import StandardInput from 'widgets-v5/standard-input';

import Dropdown from 'widgets-v5/dropdown';
import { VirtualMenu, MenuItem, MenuSeparator } from 'widgets-v5/menu';
import Checkbox from 'widgets-v5/checkbox';

export default class extends React.Component {
    static displayName = 'IncludeExclude';

    static propTypes = {
        label: PropTypes.string,
        buttonText: PropTypes.string,
        onApply: PropTypes.func,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        leftItems: PropTypes.array,
        rightItems: PropTypes.object,
        isLoading: PropTypes.bool,
        useCheckboxes: PropTypes.bool,
        useSelectAll: PropTypes.bool,
        smartSubMenu: PropTypes.bool,
        exclude: PropTypes.bool,
        useExcludeButton: PropTypes.bool,
        dropUp: PropTypes.bool,
    };

    static defaultProps = {
        values: [],
        exclude: false,
        useExcludeButton: true,
        dropUp: false,
    };

    state = {
        searchText: '',
        exclude: !!this.props.exclude, // Small hack to default to false if the prop is omitted
        staged: this.props.values,
        selectAll: false,
        selectAllTitle: 'Select All',
    };

    applyChanges = close => {
        const leftItemValues = _.map(this.props.leftItems, item => item.value);
        const intersection = _.intersection(leftItemValues, this.state.staged);

        const out = {
            exclude: this.state.exclude,
            // Ensure that all out values exist in options
            values: intersection,
        };

        this.props.onApply(out);

        this.setState({
            searchText: '',
            staged: out.values,
        });

        close();
    };

    filterList = searchText => {
        this.setState(
            {
                searchText,
                selectAll: false,
            },
            () => {
                this.setState({
                    selectAllTitle: this.getSelectAllTitle(),
                });
            }
        );
    };

    toggleCheck = (item, isChecked) => {
        if (item.disabled) {
            return;
        }

        let newStaged;
        if (isChecked) {
            newStaged = this.state.staged.filter(v => v !== item.value);
        } else {
            newStaged = this.state.staged.concat(item.value);
        }

        this.setState({
            staged: newStaged,
            exclude: newStaged.length === 0 ? false : this.state.exclude,
        });
    };

    toggleExclude = () => {
        this.setState({
            exclude: !this.state.exclude,
        });
    };

    clearItems = () => {
        this.setState({
            staged: [],
            exclude: false,
        });

        this.props.onApply({
            exclude: this.state.exclude,
            values: [],
        });
    };

    handleClose = () => {
        // Auto apply on close
        this.applyChanges(_.noop);

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    handleOpen = () => {
        this.setState({
            staged: this.props.values,
        });
        if (this.menu) {
            this.menu.scrollToTop();
        }
        if (this.filterInput) {
            this.filterInput.focus();
        }

        if (this.props.onOpen) {
            this.props.onOpen();
        }
    };

    setMenu = menu => {
        this.menu = menu;
    };

    selectAll = availableItems => {
        const availableItemsList = _.map(availableItems, item => item.value);
        const selectAll = !this.state.selectAll;

        this.setState(
            {
                staged: selectAll ? this.state.staged.concat(availableItemsList) : [],
                selectAll: selectAll,
            },
            () => {
                this.setState({
                    selectAllTitle: this.getSelectAllTitle(),
                });
            }
        );
    };

    getSelectAllTitle = () => {
        const mapping = {
            checked: {
                text: 'Unselect Filtered',
                noText: 'Select None',
            },
            unchecked: {
                text: 'Select Filtered',
                noText: 'Select All',
            },
        };

        const selectAllStatus = this.state.selectAll ? 'checked' : 'unchecked';
        const filterStatus = this.state.searchText.length > 0 ? 'text' : 'noText';

        return mapping[selectAllStatus][filterStatus];
    };

    render() {
        const appliedItems = _.filter(this.props.leftItems, item => {
            return _.includes(this.props.values, item.value);
        });

        const checkedItems = {};
        _.each(this.state.staged, value => {
            checkedItems[value] = true;
        });

        const checkedItemsCounter = this.state.staged.length;

        const availableItems = this.props.leftItems.filter(item => {
            const label = item.label.toLowerCase();
            const searchText = this.state.searchText.toLowerCase();

            const labelMatch = label.indexOf(searchText) > -1;
            let descriptionMatch;
            if (item.description) {
                const description = item.description.toLowerCase();
                descriptionMatch = description.indexOf(searchText) > -1;
            }

            const textMatch = labelMatch || descriptionMatch;

            const isInAppliedItems = _.find(appliedItems, appliedItem => {
                return appliedItem.value === item.value;
            });

            return textMatch && !isInAppliedItems;
        });

        let buttonLabel;
        if (appliedItems.length === 0) {
            buttonLabel = <div className="ef4-standard-selector__default">{this.props.label}</div>;
        } else {
            buttonLabel = _.map(appliedItems, item => item.label).join(', ');
        }

        const onClear = this.props.values.length > 0 ? this.clearItems : null;

        const separator1 = [];
        if (appliedItems.length > 0) {
            separator1.push({ menuSeparator: true, key: 'SEPARATOR_1' });
        }
        const menuItems = [].concat(appliedItems, separator1, availableItems);

        return (
            <div className="include-exclude">
                <Dropdown
                    isLoading={this.props.isLoading}
                    buttonLabel={buttonLabel}
                    clearable={appliedItems.length > 0 && this.props.clearable}
                    onClear={onClear}
                    onOpen={this.handleOpen}
                    onClose={this.handleClose}
                    dropUp={this.props.dropUp}
                >
                    {() => (
                        <div>
                            <div className="include-exclude__top-toolbar">
                                <Checkbox
                                    label={this.state.selectAllTitle}
                                    checked={this.state.selectAll}
                                    onChange={() => this.selectAll(availableItems)}
                                />
                                {this.props.useExcludeButton && (
                                    <Checkbox
                                        className="include-exclude__exclude-button"
                                        label="Exclude"
                                        checkType={this.state.exclude ? 'cross' : 'check'}
                                        checked={this.state.exclude}
                                        onChange={() => this.toggleExclude(checkedItemsCounter)}
                                    />
                                )}
                            </div>
                            <div className="include-exclude__standard-input">
                                <StandardInput
                                    ref={input => {
                                        this.filterInput = input;
                                    }}
                                    value={this.state.searchText}
                                    placeholder="Filter selections"
                                    onChange={this.filterList}
                                />
                            </div>
                            <VirtualMenu ref={this.setMenu} items={menuItems}>
                                {(item, style) => {
                                    if (item.menuSeparator) {
                                        return <MenuSeparator key={item.key} style={style} />;
                                    }

                                    return (
                                        <MenuItem
                                            key={item.value}
                                            style={style}
                                            onClick={() =>
                                                this.toggleCheck(item, checkedItems[item.value])
                                            }
                                            disabled={item.disabled}
                                        >
                                            <div className="include-exclude__menu-item">
                                                <Checkbox
                                                    label={item.label}
                                                    description={item.description}
                                                    checkType={
                                                        this.state.exclude ? 'cross' : 'check'
                                                    }
                                                    checked={!!checkedItems[item.value]}
                                                    strikeThrough={item.strikeThrough}
                                                    onChange={_.noop}
                                                />
                                            </div>
                                        </MenuItem>
                                    );
                                }}
                            </VirtualMenu>
                        </div>
                    )}
                </Dropdown>
            </div>
        );
    }
}

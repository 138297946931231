import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    gender: [],
    geo_country: [],
    device_os: [],
    age: [],
    geo_country_region: [],
    hour: [],
    day_of_week: [],
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.CAMPAIGN_REPORT__ADGROUP__TOGGLE: {
            const componentType = _.get(action, 'payload.component.type');

            if (!_.includes(['heatmap', 'dimension'], componentType)) {
                return state;
            }

            const grouping = _.get(action, 'payload.grouping');

            if (!grouping) {
                return state;
            }

            const { group } = action.payload;

            const groupSelection = state[grouping] || [];

            const groups = _.includes(groupSelection, group.id)
                ? _.without(groupSelection, group.id)
                : _.uniq(groupSelection.concat(group.id));

            return {
                ...state,
                [grouping]: groups,
            };
        }

        case c.CAMPAIGN_REPORT__ADGROUP__RESET: {
            const componentType = _.get(action, 'payload.component.type');

            if (!_.includes(['heatmap', 'dimension'], componentType)) {
                return state;
            }

            const groupings = _.get(action, 'payload.component.groupings', []);

            return _.reduce(
                groupings,
                (acc, grouping) => ({
                    ...acc,
                    [grouping]: [],
                }),
                state
            );
        }

        case c.CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR: {
            const { grouping } = action.payload;

            return {
                ...state,
                [grouping]: [],
            };
        }

        case c.CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR_ALL: {
            return _.reduce(
                state,
                (acc, groups, grouping) => ({
                    ...acc,
                    [grouping]: [],
                }),
                state
            );
        }

        default:
            return state;
    }
}

import React, { useEffect, useRef } from 'react';

import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';

import { BarGraphTile } from 'widgets-v5/bar-graph-tile';
import { useReportExplore } from '../../hook';

export const Dimensions = ({
    isClient,
    isClientReportPage,
    onReset,
    onCheckboxSelected,
}) => {
    const {
        campaign,
        dimensionsWithValues,
        dimensionItems,
        initializeDimensions,
    } = useReportExplore();

    useEffect(() => {
        if (!dimensionsWithValues || !dimensionsWithValues.length) {
            initializeDimensions({
                components: dimensionItems,
                campaignId: campaign.id,
            });
        }
    }, []);

    const sharingSettings = get(
        campaign,
        'sharing_settings.report_tabs.explore.dimensions',
        []
    );

    const parsedDimensionComponents = isClient || isClientReportPage ? dimensionsWithValues.filter(item => {
        // This is where we enforce campaign sharing settings on dimensions.
        // We do the same for metrics in the metricSelector
        const sharingConfig = find(sharingSettings, setting => {
            return setting.name === item.component.serverName;
        });

        return isClient || isClientReportPage
            ? get(sharingConfig, 'shared', false)
            : true;
    }) : dimensionsWithValues;

    return map(parsedDimensionComponents, component => (
        <div
            className="ef5-explore-report__dimension-tile"
            key={component.id}
        >
            <TileComponent
                component={component}
                onReset={onReset}
                onCheckboxSelected={onCheckboxSelected}
            />
        </div>
    ));
}

const TileComponent = ({
    component,
    onReset,
    onCheckboxSelected,
}) => {
    const {
        fetchDimensionItemData,
        dateRange,
        dimensionMatch,
        scopeMatch,
        campaign,
    } = useReportExplore();

    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            // First render, check if we have data already before fetching more.
            if (!component.groups || !component.groups.length) {
                fetchDimensionItemData({
                    component,
                    campaignId: campaign.id,
                });
            }
            mounted.current = true;
        } else {
            // Subsequent renders are ok to fetch since some of the filtering might have changed.
            fetchDimensionItemData({
                component,
                campaignId: campaign.id,
            });
        }
    }, [dateRange, dimensionMatch, scopeMatch]);

    return (
        <BarGraphTile
            {...component}
            onReset={onReset}
            onCheckboxSelected={onCheckboxSelected}
        />
    )
}

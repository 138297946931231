import _ from 'lodash';
import flags from 'containers/flags/service';

export const NAME = 'profilePage';
export default function profile(state = {}, action) {
    switch (action.type) {
        case 'PROFILE_USER__FETCH_SUCCESS':
            const { user, organization } = action.payload;
            return {
                ...state,
                user: {
                    id: user.id,
                    attr: user,
                    etag: user._etag,
                },
                organization: {
                    id: organization.id,
                    attr: organization,
                },
            };
        case 'PROFILE_USER__FETCH_FAIL':
            const payload = _.get(action, 'payload', void 0);
            return {
                ...state,
                showUsersError: payload.err.message,
            };

        case 'PROFILE_PAGE__UPDATE_EMAIL_SUBSCRIPTION_PREFERENCES_START':
            return {
                ...state,
                isUpdatingSubscriptionPreferences: true,
            };
        case 'PROFILE_PAGE__UPDATE_EMAIL_SUBSCRIPTION_PREFERENCES_SUCCESS':
            return {
                ...state,
                isUpdatingSubscriptionPreferences: false,
                user: {
                    ...state.user,
                    attr: action.payload.user,
                },
            };
        case 'PROFILE_PAGE__UPDATE_EMAIL_SUBSCRIPTION_PREFERENCES_FAIL': {
            return {
                ...state,
                isUpdatingSubscriptionPreferences: false,
            };
        }
        default:
            return state;
    }
}

import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import config from '../../../../config';

import { OWNER_MEDIA_COST_RELEASE_DATE } from 'common/constants/date-flags';
import { getRangePositionToNow } from 'common/date-calculator';
import { mapFirst, flatMap } from 'utils/enum';
import { calcTotalStats, getAsAbsolute, getAsPercentage } from 'services/resources/stats';
import {
    formatNumber_currency,
    formatNumber_percentage,
    formatNumber_whole,
    formatNumber_wholeFixed,
} from 'utils/formatting';
import flags from 'containers/flags/service';

const inventoryCostImplementationDate = moment('2016-09-30', 'YYYY-MM-DD');

export default function select(state, props) {
    const query = _.get(props, 'location.query', {});

    const userOrganization = _.get(state, 'account.accountDetails.userOrganization');
    const creditLimitForOrganization = _.get(userOrganization, 'credit_limit');
    const organizationCustomFields = _.get(userOrganization, 'custom_fields', []);
    const organizationName = _.get(userOrganization, 'name');
    const orgCurrency = _.get(userOrganization, 'currency', '');

    const isAccountSummaryLoading = _.get(state, `account.accountDetails.isAccountSummaryLoading`);
    const isPivotTableLoading = _.get(state, `account.accountDetails.isPivotTableLoading`);
    const isInitializing = _.get(state, `account.accountDetails.isInitializing`);

    const statsExist = _.keys(_.get(state, `account.accountDetails.pivotTableStats`)).length > 0;

    const pivotTableState = _.get(state, `account.accountDetails.pivotTable`, {});
    const earliestStart = _.get(state, 'account.accountDetails.earliestStart');
    const latestEnd = _.get(state, 'account.accountDetails.latestEnd');
    const dateRange = _.get(state, 'account.accountDetails.dateRange');

    const totalForThisMonth = _.get(
        state,
        'account.accountDetails.selectedRangeCosts.ownerCostLocal'
    );
    const totalInventoryCostForThisMonth = _.get(
        state,
        'account.accountDetails.selectedRangeCosts.inventoryCost'
    );

    const campaigns = _.get(state, 'resources.campaigns', {});
    const ads = _.get(state, 'resources.ads', {});
    const responseTimeout = _.get(state, 'account.accountDetails.responseTimeout');
    const metricSelector = _.get(state, 'account.metricSelector');
    const stats = _.get(state, `account.metricSelector.stats`, []);

    const campaignSearch = _.get(state, `account.accountDetails.campaignSearch`, '');
    const selectedClient = _.get(state, `account.accountDetails.selectedClient`, null);
    const campaignStatus = _.get(state, 'account.accountDetails.campaignStatus', null);
    let filteredCampaigns = _.get(state, 'account.accountDetails.filteredCampaigns', []);

    const initKey = _.get(state, 'account.accountDetails.initKey', 0);

    const childOrganizations = _.get(state, 'account.accountDetails.clientOrganizations');
    const clients = _(childOrganizations)
        .filter(x => x)
        .map(org => ({
            label: org.name,
            value: org.id,
        }))
        .sortBy('label')
        .value();

    const currencyByAdId = _.get(state, 'account.accountDetails.currencyByAdId', {});

    const adNamesById = _.reduce(
        ads,
        (result, ad) => {
            return {
                ...result,
                [ad.attr.id]: ad.attr.name,
            };
        },
        {}
    );

    const isCampaignSplitSelected =
        _.findIndex(pivotTableState.splits, split => split.name === 'campaign_id') > -1;
    const searchString = _.get(state, 'account.accountDetails.campaignSearch');

    return {
        searchString,
        organizationCustomFields,
        organizationName,

        isAccountSummaryLoading,
        isPivotTableLoading,
        isInitializing,
        statsExist,

        query,
        pivotTableState,
        dictionary: _.get(state, `account.accountDetails.dictionary`, {}),
        columns: getVisibleColumns(state),
        metrics: getVisibleMetrics(state),
        totalFilteredStats: calcTotalStats(stats),

        selectedDateRange: dateRange,
        earliestStart,
        latestEnd,

        totalForThisMonth,
        totalInventoryCostForThisMonth,

        currencyByAdId,
        adNamesById,

        isCampaignSplitSelected,

        metricSelector,
        campaignSearch,
        campaignStatus,
        selectedClient,
        clients,

        orgCurrency,
        creditLimitForOrganization,
        initKey,
        responseTimeout,
    };
}

function formatValue(metricValue, formatType) {
    switch (formatType) {
        case 'thousands':
            return formatNumber_whole(metricValue);

        case 'percentage':
            return formatNumber_percentage(metricValue);

        case 'dollar':
            return formatNumber_currency(metricValue);

        case 'whole-fixed':
            return formatNumber_wholeFixed(metricValue, 2);

        default:
            return metricValue;
    }
}

// Convert visible metrics to columns, then merge with Pivot Table columns
function getVisibleColumns(storeState) {
    const columns = _.get(storeState, `account.accountDetails.columns`, []);
    const startDate = _.get(storeState, `account.accountDetails.dateRange.start`);
    const metricComponentsConfig = _.get(
        storeState,
        `account.metricSelector.metricComponentsConfig`,
        []
    );

    const metricLookup = {};
    _.each(metricComponentsConfig, metric => {
        metricLookup[metric.metricType] = metric;
    });

    const visibleColumns = _(columns)
        .filter(filterMediaCostColumn(startDate))
        .filter(column => {
            const status = _.get(metricLookup, `${column.name}.status`);

            if (!status) {
                return true;
            }

            return _.includes(['visible', 'selected'], status);
        })
        .map(column => ({
            ...metricLookup[column.name],
            name: column.name,
            label: _.get(metricLookup, `${column.name}.presentationName`, column.label),
            formatType: _.get(metricLookup, `${column.name}.formatType`, column.formatType),
        }))
        .value();

    return visibleColumns;
}

// if start date is before Nov 1, do not show Media Cost column
function filterMediaCostColumn(startDate) {
    return column => {
        // Pre-release, we want to show the column always for testing purposes
        if (config.DEV_SETTINGS_ENABLED) {
            return true;
        }

        const isMediaCostColumn = column.name === 'owner_media_cost_2_local';
        const isStartDateBeforeNow = moment(startDate).isBefore(
            moment(OWNER_MEDIA_COST_RELEASE_DATE)
        );

        if (isMediaCostColumn && isStartDateBeforeNow) {
            return false;
        }

        return true;
    };
}

function getVisibleMetrics(storeState) {
    const metricComponentsConfig = _.get(
        storeState,
        `account.metricSelector.metricComponentsConfig`,
        []
    );

    const visibleMetrics = _(metricComponentsConfig)
        .filter(m => _.includes(['visible', 'selected'], m.status))
        .map(m => m.metricType)
        .value();

    return visibleMetrics;
}

function isChildOfTypeClientOrComanaged(org) {
    return (
        org.attr._relationship === 'child' &&
        (org.attr.type === 'client' || org.attr.type === 'co_managed_client')
    );
}

import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';

const autoBudgetAllocationMethodMapping = {
    cpc: 'eCPC',
    cpa: 'eCPA',
    cpcv: 'eCPCV',
};

const LockedMessage = ({
    pacing,
    autoBudgetAllocationMethod,
    title = ' Auto-budget enabled at campaign level ',
    body,
}) => {
    let bodyContent = body;

    if (!bodyContent) {
        bodyContent = (
            <Typography variant="body2">
                {' '}
                {`${pacing ? 'Pacing' : 'Budget'} will be automatically adjusted based on ${
                    autoBudgetAllocationMethodMapping[autoBudgetAllocationMethod]
                }`}{' '}
            </Typography>
        );
    }

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={1}>
                        <LockIcon />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography>{title}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        {bodyContent}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LockedMessage;

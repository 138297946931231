const modules = [
    require('utils/http/redux'), // http middleware must be at the top
    require('../packages/template'),
    require('containers/ad-summary/v2'),
    require('containers/report-jobs/redux'),
    require('forms/ad-form/v2'),
    require('forms/campaign-form/v2'),
    require('forms/creative-bulk-edit-form/modules/progress-bar'),
    require('forms/creative-form/v2'),
    require('pages/audiences/overall-dashboard/v2'),
    require('pages/audiences/permutive-dashboard'),
    require('pages/campaigns/ads/ads-overview/v2'),
    require('pages/campaigns/campaign/v2'),
    require('pages/campaigns/campaigns-overview/modules/campaign-metrics'),
    require('pages/campaigns/campaigns-overview/modules/campaign-summary'),
    require('pages/campaigns/creatives/creatives-overview/v2'),
    require('pages/campaigns/report/report-explore/v2/redux'),
    require('pages/campaigns/report/report-explore/middleware'),
    require('pages/campaigns/report/report-geo/middleware'),
    require('pages/campaigns/report/report-channel/middleware'),
    require('pages/overview'),
    require('pages/reports/dashboard/redux'),
    require('pages/reports/editor/v2'),
    require('pages/prediction-editor/v2'),
    require('services/events/redux'),
    require('states/notifications'),
];

export default modules;

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router';

const propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    text: PropTypes.string,
    onClick: PropTypes.func,
    link: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    efEvent: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default']),
};

function getDefaultProps() {
    return {
        target: '_blank',
        size: 'default',
    };
}

export class PrimaryButton extends React.Component {
    static propTypes = propTypes;
    static defaultProps = getDefaultProps();

    render() {
        return (
            <Button
                {...this.props}
                className={classnames(
                    {
                        'ef4-button_raised-primary': !this.props.disabled,
                        'ef4-button_is-disabled': this.props.disabled,
                        'ef4-button__small': this.props.size === 'small',
                    },
                    this.props.className
                )}
            />
        );
    }
}

export class AccentButton extends React.Component {
    static propTypes = propTypes;
    static defaultProps = getDefaultProps();

    render() {
        return (
            <Button
                {...this.props}
                className={classnames(
                    {
                        'ef4-button_raised-accent': !this.props.disabled,
                        'ef4-button_is-disabled': this.props.disabled,
                        'ef4-button__small': this.props.size === 'small',
                    },
                    this.props.className
                )}
            />
        );
    }
}

export class SecondaryButton extends React.Component {
    static propTypes = propTypes;
    static defaultProps = getDefaultProps();

    render() {
        return (
            <Button
                {...this.props}
                className={classnames(
                    {
                        'ef4-button_flat-primary': !this.props.disabled,
                        'ef4-button_is-disabled': this.props.disabled,
                        'ef4-button__small': this.props.size === 'small',
                    },
                    this.props.className
                )}
            />
        );
    }
}

export class NeutralButton extends React.Component {
    static propTypes = propTypes;
    static defaultProps = getDefaultProps();

    render() {
        return (
            <Button
                {...this.props}
                className={classnames(
                    {
                        'ef4-button_flat-secondary': !this.props.disabled,
                        'ef4-button_is-disabled': this.props.disabled,
                        'ef4-button__small': this.props.size === 'small',
                    },
                    this.props.className
                )}
            />
        );
    }
}

export class NegativeButton extends React.Component {
    static propTypes = propTypes;
    static defaultProps = getDefaultProps();

    render() {
        return (
            <Button
                {...this.props}
                className={classnames(
                    {
                        'ef4-button_flat-negative': !this.props.disabled,
                        'ef4-button_is-disabled': this.props.disabled,
                        'ef4-button__small': this.props.size === 'small',
                    },
                    this.props.className
                )}
            />
        );
    }
}

export class ButtonGroup extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        align: PropTypes.oneOf(['inline', 'left', 'right', 'justify']),
    };

    static defaultProps = {
        align: 'inline',
    };

    render() {
        return (
            <div
                style={this.props.style}
                className={classnames(
                    'ef4-button-group',
                    `ef4-button-group_align-${this.props.align}`,
                    this.props.className
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

function Button(props) {
    if (props.disabled) {
        return (
            <div
                className={classnames('ef4-button', props.className)}
                data-heap-event={props.heapEvent}
                data-ef-event={props.efEvent || ''}
                style={props.style}
            >
                {props.icon && <span className="ef4-button__icon">{props.icon}</span>}
                {props.text}
            </div>
        );
    } else if (props.link) {
        return (
            <Link
                className={classnames('ef4-button', props.className)}
                to={props.link}
                data-heap-event={props.heapEvent}
                data-ef-event={props.efEvent || ''}
                style={props.style}
                onClick={props.onClick}
            >
                {props.icon && <span className="ef4-button__icon">{props.icon}</span>}
                {props.text}
            </Link>
        );
    } else if (props.href) {
        return (
            <a
                target={props.target}
                href={props.href}
                className={classnames('ef4-button', props.className)}
                data-heap-event={props.heapEvent}
                data-ef-event={props.efEvent || ''}
                style={props.style}
            >
                {props.icon && <span className="ef4-button__icon">{props.icon}</span>}
                {props.text}
            </a>
        );
    } else {
        return (
            <button
                className={classnames('ef4-button', props.className)}
                onClick={props.onClick}
                data-heap-event={props.heapEvent}
                data-ef-event={props.efEvent || ''}
                style={props.style}
            >
                {props.icon && <span className="ef4-button__icon">{props.icon}</span>}
                {props.text}
            </button>
        );
    }
}

import _ from 'lodash';
import c from 'common/constants/flux-events';

const initialState = {
    spendStats: [],
};

export const NAME = 'resources.stats.account';

export default function account(state = initialState, action) {
    switch (action.type) {
        case c.ACCOUNT__STATS__FETCH_SUCCESS:
            const { spendStats } = action.payload;

            return {
                ...state,
                spendStats,
            };

        default:
            return state;
    }
}

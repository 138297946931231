import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import toastr from 'toastr';
import accept from 'attr-accept';

import Dropzone from 'react-dropzone';
import Typography from '@mui/material/Typography';

class DragAndDropFileUpload extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onDropRejected: PropTypes.func.isRequired,
        tempAssets: PropTypes.array,
        failedAssets: PropTypes.array,
        accept: PropTypes.string,
    };

    static defaultProps = {
        maxSizeInMB: 20,
    };

    onDrop = files => {
        const errors = this.validateFiles(files);
        const invalidFiles = this.validateFileType(files);
        const validFiles = _.differenceWith(files, invalidFiles, _.isEqual);

        if (_.keys(errors).length > 0) {
            return;
        }

        if (_.keys(invalidFiles).length > 0) {
            this.props.onDropRejected(invalidFiles);
        } else {
            this.props.onDropRejected([]);
        }

        _.map(validFiles, file => {
            const formData = new FormData();
            formData.append('asset', file);
            this.props.onChange(formData, file);
        });
    };

    validateFileType = files => {
        const invalidFiles = _.filter(files, file => {
            return !accept({ name: file.name, type: file.type }, this.props.accept);
        });

        return invalidFiles;
    };

    validateFiles = files => {
        const { maxSizeInMB } = this.props;

        const errors = {};
        _.each(files, file => {
            if (bytesToMb(file.size) > maxSizeInMB) {
                errors.fileSize = `File size is too large. Max ${maxSizeInMB}MB`;
            }
        });

        if (errors.fileSize) {
            toastr.warning(errors.fileSize, 'File Upload Error');
        }

        return errors;
    };

    render() {
        return (
            <div className={classnames(this.props.className, 'ef3-dragAndDropFileUpload')}>
                <Dropzone
                    disableClick={true}
                    className="ef3-dragAndDropFileUpload_dropZone"
                    onDrop={this.onDrop}
                >
                    {this.props.children}
                    <div className="ef3-dragAndDropFileUpload_results">
                        {_.map(this.props.tempAssets, (asset, key) => {
                            return (
                                <div key={key} className="ef3-dragAndDropFileUpload_fileLoadingBar">
                                    <div
                                        className={classnames(
                                            'ef3-dragAndDropFileUpload_fileLoadingBar_bar',
                                            {
                                                'did-fail': asset.didFail,
                                            }
                                        )}
                                    >
                                        <div
                                            className={classnames(
                                                'ef3-dragAndDropFileUpload_fileLoadingBar_barFill',
                                                {
                                                    'is-complete':
                                                        asset.progress === 100 && !asset.didFail,
                                                }
                                            )}
                                            style={{ width: asset.progress + '%' }}
                                        />
                                    </div>
                                    <span className="ef3-dragAndDropFileUpload_fileLoadingBar_name">
                                        {key}
                                    </span>
                                </div>
                            );
                        })}

                        {_.map(this.props.failedAssets, (asset, index) => {
                            return (
                                <div
                                    key={index}
                                    className="ef3-dragAndDropFileUpload_fileLoadingError"
                                >
                                    <span className="ef3-dragAndDropFileUpload_fileLoadingError_name">
                                        '{asset.name}' failed to upload, {asset.reason}.
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    {this.props.accept && (
                        <Typography>Accepted Format: {this.props.accept}</Typography>
                    )}
                </Dropzone>
            </div>
        );
    }
}

function bytesToMb(bytes) {
    return bytes / 1000000;
}

export default DragAndDropFileUpload;

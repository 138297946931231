import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import OrganizationDropDown from 'containers/admin-orgs';
import OrgItemView from 'containers/admin-org-item';
import Users from 'containers/admin-users';
import OrgForm_Button from 'containers/admin-org-item-form';
import GlobalRefresh from 'containers/global-refresh';
import { PrimaryButton } from 'widgets-v5/buttons';
import { Title } from 'widgets-v5/typography';

class AdminLayout extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.workspace) {
            return this.props.workspace;
        }
        return (
            <div className="admin-layout">
                <Title>Admin</Title>
                <div className="admin-layout_head">
                    <div className="admin_organizations">
                        <div className="switch-org-label">Switch Organization</div>
                        <div className="switch-org-dropdown-container">
                            {this.props.OrganizationDropDown}
                        </div>
                    </div>
                    <div className="admin_organizations_add">
                        <GlobalRefresh />
                        {this.props.OrgForm_Button}
                    </div>
                </div>
                <div className="admin-layout_body">
                    <div className="admin_organizations-view-container">
                        {this.props.OrgItemView}
                    </div>
                    <div className="admin_users-view-container">{this.props.Users}</div>
                </div>
            </div>
        );
    }
}

class Admin extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <AdminLayout
                orgId={this.props.orgId}
                workspace={this.props.workspace}
                OrganizationDropDown={
                    <OrganizationDropDown orgId={this.props.orgId} history={this.props.history} />
                }
                OrgForm_Button={
                    <OrgForm_Button
                        orgId={void 0}
                        history={this.props.history}
                        Button={
                            <PrimaryButton
                                icon={<i className="fa fa-plus" />}
                                text="New Organization"
                            />
                        }
                    />
                }
                OrgItemView={<OrgItemView orgId={this.props.orgId} history={this.props.history} />}
                Users={<Users orgId={this.props.orgId} history={this.props.history} />}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        orgId: _.get(ownProps, 'params.orgId', void 0),
    };
}

export default connect(mapStateToProps)(Admin);

import _ from 'lodash';

import validate from 'utils/validate';

// --------------
// Default values
// --------------
import _defaultSetting from './default-values';
export const defaultSetting = _defaultSetting;

// ----------
// Validation
// ----------
import { mraidValidators as _mraidValidators } from './validators-mraid';
import { nativeValidators as _nativeValidators } from './validators-native';
import { standardValidators as _standardValidators } from './validators-standard';
import { videoValidators as _videoValidators } from './validators-video';
import { thirdPartyVastValidators as _thirdPartyVastValidators } from './validators-third-party-vast';
import { commonValidators as _masterValidators } from './validators-common';
import { validators_group as _validators_group } from './validators-group';

export const mraidValidators = _mraidValidators;
export const nativeValidators = _nativeValidators;
export const standardValidators = _standardValidators;
export const videoValidators = _videoValidators;
export const thirdPartyVastValidators = _thirdPartyVastValidators;
export const masterValidators = _masterValidators;
export const validators_group = _validators_group;

export function validateCreativeDraft(draft) {
    let validators;

    switch (draft.format) {
        case 'standard':
            validators = _.assign({}, standardValidators, masterValidators);
            break;
        case 'third_party_vast':
            validators = _.assign({}, thirdPartyVastValidators, masterValidators);
            break;
        case 'video':
            validators = _.assign({}, videoValidators, masterValidators);
            break;
        case 'native':
            validators = _.assign({}, nativeValidators, masterValidators);
            break;
        case 'mraid':
        case 'mraid_interstitial':
        case 'custom_html':
        case 'custom_html_interstitial':
            validators = _.assign({}, mraidValidators, masterValidators);
            break;
        default:
            validators = masterValidators;
    } // End switch

    const validatorsCombined = {
        ...validators,
        ...validators_group,
    };

    const draftCombined = {
        ...draft,
        ..._defaultSetting.groupValidate,
    };

    return validate(draftCombined, validatorsCombined);
}

// -----------------------------------------
// Data transformation before sending to api
// -----------------------------------------
import { serialize as _serialize } from './serialize-for-api';
export const serializeForApi = _serialize;

import _ from 'lodash';
import iabCategories from 'common/constants/iab-categories';
import { can } from 'services/can';
import { STATS_VIEWS } from './constants';
import { PermissionsMapping } from 'common/constants/role-intents';

const CLIENT_TYPES = {
    sales_rep: 'Sales Rep',
    advertiser: 'Advertiser',
    partner: 'Partner',
};

export const iabCategoryOptions = _(iabCategories)
    .map((categoryName, categoryId) => ({
        value: categoryId,
        label: categoryName,
    }))
    // remove subcategories (contains a dash in the name)
    .filter(category => category.value.indexOf('-') === -1)
    .value();

export default function selector(storeState, props) {
    const { campaignId } = props.params;

    const page = _.get(props, 'location.query.page', 1);
    const campaigns = _.get(storeState, `dashboard.pageIndex.${page}`, []);
    const currentPage = _.get(storeState, 'dashboard.filters.page', 1);
    const resultsPerPage = _.get(storeState, 'dashboard.resultsPerPage', 25);
    const maxResults = _.get(storeState, 'dashboard.maxResults', 0);
    const hiddenColumns = _.get(storeState, `dashboard.hiddenColumns`);
    const fetchingHiddenColumns = _.get(storeState, `dashboard.fetchingHiddenColumns`);

    const ownOrganization = _.get(storeState, 'dashboard.ownOrganization');
    const ownOrganizationType = ownOrganization.type;
    const childOrganizations = _.get(storeState, 'dashboard.childOrganizations');
    const organizations = _(childOrganizations)
        .filter(x => x)
        .map(org => ({
            label: org.name,
            value: org.id,
        }))
        .sortBy('label')
        .value();

    const organizationTimezone = _.get(storeState, 'profile.organizationTimezone');

    const search = _.get(storeState, 'dashboard.search');
    const isDashboardLoading = _.get(storeState, 'dashboard.isLoading');
    const statsView = _.get(storeState, 'dashboard.statsView');

    const campaignsFormatted = _.map(campaigns, campaign => {
        const clients = _.map(campaign.clients, client => ({
            ...client,
            id: client.organization,
            client_type: CLIENT_TYPES[client.type] || 'N/A',
        }));

        const metricDefaults = {
            impressions: 0,
            clicks: 0,
            ctr: 0,
            owner_total_media_cost_local: 0,
            owner_total_media_cost_local_ecpm: 0,
            billings_local: 0,
        };

        const BLANK_METRICS = {};
        Object.keys(metricDefaults).forEach(metricName => {
            BLANK_METRICS[metricName] = null;
        });

        let stats = _.get(campaign, 'metadata.metrics', metricDefaults);

        if (!stats) {
            stats = metricDefaults;
        }
        if (campaign.flightPacingStrategy === 'campaign' && statsView === STATS_VIEWS.FLIGHT) {
            const currentMetrics = _.get(campaign, 'liveFlight.metadata.metrics');
            stats = currentMetrics || BLANK_METRICS;
        }

        return {
            ...campaign,
            stats,
            clients,
        };
    });

    const maxInSet = Math.min(currentPage * resultsPerPage, maxResults);
    const minInSet = Math.max(maxInSet - resultsPerPage + 1, 0);

    const paginationLocation = `${minInSet} - ${maxInSet} of ${maxResults}`;

    //    25  50  75  100
    // < [1,  2,  3,  4,  5] >
    // < [6,  7,  8 , 9,  10] >
    // < [11, 12, 13 ,14, 15] >
    // < [16, 17, 18 ,19, 20] >

    const pagination = (function() {
        const filters = _.get(storeState, 'dashboard.filters', {});
        const isLoading = _.get(storeState, 'dashboard.isLoading');

        const buffer = 2;
        var activeIndex = buffer;

        const start = Math.max(currentPage - buffer, 1);
        const numberOfPagesForDisplay = buffer * 2 + 1;

        const maxPage = Math.ceil(maxResults / resultsPerPage);

        // when the current page is too close to the start or end, highlight
        // should not be centered
        const highlightCurrentPage = currentPage <= buffer || currentPage >= maxPage - buffer;

        const links = _.times(numberOfPagesForDisplay).reduce((acc, tabIndex) => {
            // merge
            var filter = {
                ...filters,
                page: start + tabIndex,
            };

            // Only add pages which have results to show
            if (filter.page > maxPage) {
                return acc;
            }

            if (highlightCurrentPage && filter.page === currentPage) {
                activeIndex = tabIndex;
            }

            if (filters.search) {
                filter.search = filters.search;
            }

            // Filter out empty query values
            // This is to avoid urls like: ?status=&creative_type=&search=
            const querystring = _(filter)
                .pick(['page', 'search', 'status', 'creative_type', 'category', 'archived'])
                .omit(val => val === '' || val === null || val === undefined)
                .value();

            return acc.concat({
                id: start + tabIndex,
                query: querystring,
            });
        }, []);

        const currentLink = _.find(links, { id: currentPage });

        const pageForwardNumber = Math.min(currentPage + 1, maxPage);

        var moveForward = {
            id: pageForwardNumber,
            hide: currentPage === maxPage,
        };

        const pageBackNumber = Math.max(currentPage - 1, 1);

        var moveBack = {
            id: pageBackNumber,
            hide: currentPage === 1,
        };

        if (currentLink) {
            moveForward.query = {
                ...currentLink.query,
                page: moveForward.id,
            };

            moveBack.query = {
                ...currentLink.query,
                page: moveBack.id,
            };
        }

        return {
            filters,
            links,
            moveForward,
            moveBack,
            iabCategoryOptions,
            isLoading,
            currentLinkIndex: activeIndex,
            maxPage,
        };
    })();

    return {
        campaigns: campaignsFormatted,
        currentPage,
        search,
        paginationLocation,
        pagination,
        organizations,
        ownOrganizationType,
        ownOrganization,
        campaignId,
        organizationTimezone,
        canViewSetup: can(PermissionsMapping.CAMPAIGN__VIEW),
        isDashboardLoading,
        maxResults,
        hiddenColumns,
        fetchingHiddenColumns,
    };
}

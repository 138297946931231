import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import selector from './selector';
import AdPreset from 'containers/ad-preset';

class AdPresetEdit extends React.Component {
    render() {
        return (
            <div>
                <AdPreset
                    location={this.props.location}
                    params={this.props.params}
                    routes={this.props.routes}
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default connect(selector)(AdPresetEdit);

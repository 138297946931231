import toastr from 'toastr';
import _ from 'lodash';
import { createHttp } from 'utils/http';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const actions = {
    fetchOrganizationData: () => {
        return async (dispatch, getState) => {
            try {
                dispatch({
                    type: 'ADMIN_V2__FETCH_OWN_ORGANIZATION_START',
                    payload: {},
                });
                const http = createHttp();
                const query = `
                    query getOrganizationDataAndUsers ($id: String) {
                        organization(id: $id) {
                            id
                            name
                            type
                            default_timezone
                            revenueModel
                            currency
                            tech_fee
                            agencyMarginRate
                            users {
                                id
                                first_name
                                last_name
                                email
                                global_role
                            }
                            privateInventorySpendTiers {
                                techFee
                                min
                                max
                            }
                            publicInventorySpendTiers {
                                techFee
                                min
                                max
                            }
                        }
                    }
                `;

                const variables = {
                    id: _.get(getState(), 'profile.organizationId'),
                };

                const { organization } = await http.graphql(query, variables);
                const sortedUsers = _.sortBy(organization.users, user => {
                    const fullName = `${user.first_name} ${user.last_name}`;
                    return fullName;
                });

                dispatch({
                    type: 'ADMIN_V2__FETCH_OWN_ORGANIZATION_END',
                    payload: {
                        data: { organization, users: sortedUsers },
                    },
                });
            } catch (err) {
                toastr.error('', 'Failed to get users for organization', TOASTR_OPTIONS);
                if (window.bugsnagClient) {
                    window.bugsnagClient.notify(
                        `Failed to fetch organization user data in admin page`,
                        {
                            metaData: {
                                organizationId: _.get(getState(), 'profile.organizationId'),
                            },
                        }
                    );
                }
            }
        };
    },

    filterUsers: keywords => {
        return (dispatch, getState) => {
            dispatch({
                type: 'ADMIN_V2__FILTER_USERS_START',
                payload: {},
            });
            const users = _.get(getState(), 'adminV2.ownOrganization.organization.users');
            const pattern = new RegExp(keywords, 'i');
            const filteredUsers = _.filter(users, user => {
                const userFullName = `${user.first_name} ${user.last_name}`;
                return pattern.test(userFullName);
            });
            dispatch({
                type: 'ADMIN_V2__FILTER_USERS_END',
                payload: { filteredUsers },
            });
        };
    },
};

export default actions;

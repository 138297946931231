import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';

const InfiniteScrollWidget = ({
    items,
    selections,
    handleItemToggle,
    isEnabled,
    fetchMoreData,
    hasMore,
}) => {
    return (
        <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LinearProgress />}
            height={400}
        >
            {items.map((item, index) => (
                <FormControlLabel
                    key={index}
                    control={
                        <MuiCheckbox
                            label={item.label}
                            checked={!!selections[item.value]}
                            onChange={() => handleItemToggle({ item })}
                            disabled={!isEnabled}
                            color="primary"
                        />
                    }
                    label={item.label}
                    sx={{ display: 'block', marginLeft: 0 }}
                />
            ))}
        </InfiniteScroll>
    );
};

export default InfiniteScrollWidget;

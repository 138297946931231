import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

export default class extends React.Component {
    render() {
        // const style = (this.props.highlight) ? {background:'#ffaaaa'} : null;
        const style = this.props.strikethroughText
            ? {
                  textDecoration: 'line-through',
                  color: 'gray',
              }
            : null;

        return (
            <dl className="ef3-adSummaryView_panelBlock">
                <div className="ef3-adSummaryView__item-group">
                    <dt className="ef3-adSummaryView_term">{this.props.keyName}</dt>
                    <dd className="ef3-adSummaryView_description" style={style}>
                        <div className="TODO widget--summary-type-text-collection">
                            {this.props.value}
                        </div>
                    </dd>
                    {this.props.tooltip}
                </div>
            </dl>
        );
    }
}

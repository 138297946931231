// https://facebook.github.io/react/docs/update.html
import update from 'immutability-helper';
import _ from 'lodash';

/*


This helper is to reduce boilerplate around transforming
deeply nested objects in an immutable way.



Example:

report = {
    4123: {
        components: [],
        pivotTable: {
            expandedRows: {
                '2015-10-01': true
            }
        }
    }
}

To change the above '2015-10-01' key to true,
we would have to normally write this:

report = {
    ...state,
    [campaignId]: {
        ...report,
        pivotTable: {
            ...report.pivotTable,
            expandedRows: {
                ...report.pivotTable.expandedRows,
                ['2015-10-01']: true
            }
        }
    }
}

This helper allows us to reduce this to a single line like so:

report = update(report, 'pivotTable.expandedRows.2015-10-01', true)

*/

// convert 'a.b.c.2.3.4' to { a: {b: {c: {2: {3: {4: {}}}}}}}
function setObjectPath(path, value) {
    return path
        .split('.')
        .reverse()
        .reduce(function(acc, key, index) {
            var out = {};
            out[key] = acc;
            return out;
        }, value);
}

/**
    @state (object): value to be transformed
    @path (string): path to value in state, i.e. 'pivotTable.expandedRows.2015-10-01'
    @value (any): value to merge/set into the final path property
    */
function updateProxy(state, path, value) {
    // const operation = _.isPlainObject(value) ? '$merge' : '$set';
    const operation = '$set';

    const query = setObjectPath(path, {
        [operation]: value,
    });

    return update(state, query);
}

export default updateProxy;

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

import D3Chart from './services/d3Chart';

class ChartExplorerSummary extends React.Component {
    constructor(props) {
        super(props);

        this.el = null;
    }

    static propTypes = {
        isClient: PropTypes.bool,
    };

    // ===================
    // Component setup and tear down
    // ===================
    UNSAFE_componentWillMount() {
        this.debug = false;
    }

    componentDidMount() {
        this.mountChart(this.props);

        // Mount events
        window.addEventListener('resize', this.windowHandleResize);
        $('.ef3-leftBarLayout_workspace').on('scroll', this.windowHandleScroll);

        this.caculateElementOffsets();
    }

    mountChart = nextProps => {
        var el = this.el;
        var $el = (this.$el = $(el));
        this.$tooltip = $el.find('.am-reports-toolTip');

        if (!this.d3Chart) {
            this.d3Chart = new D3Chart();
            this.d3Chart.campaignId = nextProps.meta.campaignId;
            // Dev
            window.rpt = this.d3Chart;
        }

        // this.stringifiedData = JSON.stringify(nextProps);

        // Initializing Chart
        const state = this.prepareGraphState(nextProps);

        this.d3Chart.create(el, state, this.props.isClient);

        const statsMetricsType_visibility = nextProps.control.statsMetricsType_visibility;
        this.d3Chart.control({ isShown: statsMetricsType_visibility });

        this.updateMessage(this.props, { isCreating: true });
    };

    tearDownChart = () => {
        const el = this.el;
        this.d3Chart.distroy(el);
        delete this.d3Chart;
    };

    componentWillUnmount() {
        // Remove events;
        window.removeEventListener('resize', this.windowHandleResize);
        $('.ef3-leftBarLayout_workspace').off('scroll', this.windowHandleScroll);

        this.tearDownChart();
    }

    // ===================
    // tooltips coordinate
    // ===================
    caculateElementOffsets = () => {
        this.el_offset_viewPort = this.$el[0].getBoundingClientRect();
        this.el_offset = this.$el.offset();
    };

    updateToolTipsCoordinate = () => {
        if (this.el && this.el.toolTipsPosition) {
            const left = this.el.toolTipsPosition.x - this.el_offset.left;
            const top = this.el.toolTipsPosition.y - this.el_offset_viewPort.top;

            const toolTipWidth = this.$tooltip.width();
            const componentWidth = this.$el.width();
            const leftOverSpace = componentWidth - left;

            let left_adjusted;
            if (leftOverSpace > toolTipWidth + this.el.toolTipsOffset) {
                left_adjusted = left;
            } else {
                left_adjusted = left - toolTipWidth - 2 * this.el.toolTipsOffset;
            }

            this.$tooltip.css({ left: left_adjusted, top });
        }
    };

    // ===================
    // Window event's callbacks
    // ===================
    windowHandleScroll = () => {
        this.caculateElementOffsets();
        this.updateToolTipsCoordinate();
    };

    windowHandleResize = () => {
        this.caculateElementOffsets();
        this.updateToolTipsCoordinate();
    };

    // ===================
    // React component event's callbacks
    // ===================
    reactHandleMouseMove = () => {
        // const x = e.pageX;    //<----  comented out because mouse coordinate here is reading from svg user coordinate
        // const y = e.pageY;
        this.caculateElementOffsets();
        this.updateToolTipsCoordinate();
    };

    reactHandleMouseOver = () => {
        if (!this._isLoading) this.$tooltip.css({ display: 'block' });
    };

    reactHandleMouseOut = () => {
        this.$tooltip.css({ display: 'none' });
    };

    // ===================
    // Chart's overlay visibility control
    // ===================
    chartOverlay_show_message = opts => {
        this.$el.find('.am-reports-timeSeriesGraph-overlay').hide();
        this.$el.find('.am-reports-timeSeriesGraph-overlay-message').show();

        this.$el.find('.am-reports-timeSeriesGraph-messageContainer-title').html(opts.title);
        this.$el.find('.am-reports-timeSeriesGraph-messageContainer-body').html(opts.message);
        this.$el.find('.am-reports-timeSeriesGraph-messageContainer-icon').attr('src', opts.img);
    };

    chartOverlay_show_loader = () => {
        this.$el.find('.am-reports-timeSeriesGraph-overlay').hide();
        this.$el.find('.am-reports-timeSeriesGraph-overlay-loading').show();
    };

    chartOverlay_hide_all = () => {
        this.$el.find('.am-reports-timeSeriesGraph-overlay').hide();
    };

    // ==================
    // Chart data
    // ==================
    shouldComponentUpdate(nextProps) {
        // var newStringifiedData = JSON.stringify(nextProps);
        // this.stringifiedData = newStringifiedData;

        const isLoading = nextProps.isLoading;
        this._isLoading = isLoading;

        if (isLoading) {
            return false;
        } // Do not render when it is loading

        this.updateMessage(nextProps);

        // Chart needs to be initialized if user switch campaign
        if (nextProps.meta.campaignId !== this.props.meta.campaignId) {
            // Tearing Down previous chart and mouting new one
            this.tearDownChart();
            this.mountChart(nextProps);
        }

        // Prepare state for graph
        var state = this.prepareGraphState(nextProps);

        _.assign(state, { isLoading });
        this.d3Chart.update(this.el, state);

        // Plot's visibility
        const previous_statsMetricsType_visibility = this.props.control.statsMetricsType_visibility;
        const next_statsMetricsType_visibility = nextProps.control.statsMetricsType_visibility;

        if (
            JSON.stringify(previous_statsMetricsType_visibility) !==
            JSON.stringify(next_statsMetricsType_visibility)
        ) {
            this.d3Chart.control({ isShown: next_statsMetricsType_visibility });
        }

        return false;
    }

    prepareGraphState = props => {
        var allData = props.data;

        // =====================
        // Static data directive
        // =====================

        var meta = {
            timezone: props.timezone,
        };
        meta.dataIndex = {
            unfilteredImpressions: 0,
            filteredImpressions: 1,
            filteredClicks: 2,
            ctr: 3,
            filteredSpend: 4, // new name: billable cost
            eCPM: 5,
            eCPC: 6,
            filteredRevenue: 7,
            eRPM: 8,
            eRPC: 9,
            daily_uniq: 10,
            average_freq: 11,
            total_media_cost_local: 12,
            win_rate: 13,
            unique_users: 14,
            owner_media_cost_2_local: 15,
            vcr: 16,
            owner_total_media_cost_local_ecpcv: 17,
            revenue_ecpcv: 18,
        };

        // =================================
        // Dyanamic data direcive for Beacon
        // =================================

        meta.beacons = [];
        // Built dynamically as:
        // meta.beacons = [
        //     { key: 'event_new',    name: 'event_load'  , label: 'Page Load', index:10, style:'beacon01' },
        //     { key: 'event_unload', name: 'event_unload', label: 'Page Load', index:11, style:'beacon02' }
        // ];

        const metricComponentsConfig = _.get(props, 'control.metricComponentsConfig');
        const metricComponentsConfig_beacon = _(metricComponentsConfig)
            .filter({ category: 'beacon' })
            .value();

        const beacon_startIndex = Object.keys(meta.dataIndex).length;

        let beacon_runningIndex = beacon_startIndex;
        metricComponentsConfig_beacon.forEach(b => {
            meta.beacons.push({
                key: b.metricType,
                name: b.metricType,
                label: b.presentationName,
                index: beacon_runningIndex,
                style: b.style,
            });
            beacon_runningIndex++;
        });

        // =================================
        // Preparing data
        // =================================

        var data = _.map(allData, function(d) {
            var datum = {
                date: {
                    key: 'date-time',
                    value: moment(d.date).toDate(),
                    displayName: 'Date',
                },
                y: [
                    {
                        // dataIndex 0
                        key: 'unfiltered_impressions',
                        value: d.unfiltered_impressions,
                        displayName: 'Total impression',
                    },
                    {
                        // dataIndex 1
                        key: 'filtered_impressions',
                        value: d.filtered_impressions,
                        displayName: 'Filtered impression',
                    },
                    {
                        // dataIndex 2
                        key: 'filtered_clicks',
                        value: d.filtered_clicks * 1,
                        displayName: 'Filtered clicks',
                    },
                    {
                        // dataIndex 3
                        key: 'ctr',
                        value:
                            d.filtered_impressions === 0
                                ? 0
                                : d.filtered_clicks / d.filtered_impressions,
                        displayName: 'ctr',
                    },
                    // spend
                    {
                        // dataIndex 4
                        key: 'filtered_spend', // new name: billable cost
                        value: d.filtered_spend * 1,
                        displayName: 'Filtered spend',
                    },
                    {
                        // dataIndex 5
                        key: 'eCPM',
                        value:
                            d.filtered_impressions === 0
                                ? 0
                                : d.filtered_spend / (d.filtered_impressions / 1000),
                        displayName: 'eCPM',
                    },
                    {
                        // dataIndex 6
                        key: 'eCPC',
                        value: d.filtered_clicks === 0 ? 0 : d.filtered_spend / d.filtered_clicks,
                        displayName: 'eCPC',
                    },
                    // revenue
                    {
                        // dataIndex 7
                        key: 'filtered_revenue',
                        value: d.filtered_revenue * 1,
                        displayName: 'Filtered revenue',
                    },
                    {
                        // dataIndex 8
                        key: 'eRPM',
                        value:
                            d.filtered_impressions === 0
                                ? 0
                                : d.filtered_revenue / (d.filtered_impressions / 1e3),
                        displayName: 'eRPM',
                    },
                    {
                        // dataIndex 9
                        key: 'eRPC',
                        value: d.filtered_clicks === 0 ? 0 : d.filtered_revenue / d.filtered_clicks,
                        displayName: 'eRPC',
                    },
                    //  Unique user
                    {
                        // dataIndex 10
                        key: 'daily_uniq',
                        value: d.filtered_daily_uniq,
                        displayName: 'Daily Ave. Uniq',
                    },
                    {
                        // dataIndex 11
                        key: 'average_freq',
                        value:
                            d.filtered_daily_uniq === 0
                                ? 0
                                : d.filtered_impressions / d.filtered_daily_uniq,
                        displayName: 'Daily Ave. Freq',
                    },
                    {
                        // dataIndex 12
                        key: 'total_media_cost_local',
                        value: d.filtered_owner_total_media_cost_local * 1,
                        displayName: 'Total Cost',
                    },
                    {
                        // dataIndex 13
                        key: 'win_rate',
                        value:
                            d.filtered_placed_bid === 0
                                ? 0
                                : d.filtered_impressions / d.filtered_placed_bid,
                        displayName: 'Win Rate',
                    },
                    {
                        // dataIndex 14
                        key: 'unique_users',
                        value: d.filtered_unique_users,
                        displayName: 'Unique Users',
                    },
                    {
                        // dataIndex 15
                        key: 'owner_media_cost_2_local',
                        value: d.filtered_owner_media_cost_2_local,
                        displayName: 'Media Cost',
                    },
                    {
                        // dataIndex 16
                        key: 'vcr',
                        value:
                            d.filtered_video_impressions === 0 ||
                            _.isNull(d.filtered_video_impressions)
                                ? 0
                                : d.filtered_beacons.event_video_complete /
                                  d.filtered_video_impressions,
                        displayName: 'VCR',
                    },
                    {
                        // dataIndex 17
                        key: 'owner_total_media_cost_local_ecpcv',
                        value:
                            d.filtered_beacons.event_video_complete === 0 ||
                            _.isNull(d.filtered_beacons.event_video_complete)
                                ? 0
                                : d.filtered_owner_total_media_cost_local /
                                  d.filtered_beacons.event_video_complete,
                        displayName: 'Total Cost eCPCV',
                    },
                    {
                        // dataIndex 18
                        key: 'revenue_ecpcv',
                        value:
                            d.filtered_beacons.event_video_complete === 0 ||
                            _.isNull(d.filtered_beacons.event_video_complete)
                                ? 0
                                : d.filtered_revenue / d.filtered_beacons.event_video_complete,
                        displayName: 'Revenue eCPCV',
                    },
                ],
                // id: _.uniqueId()
                id: props.meta.campaignId + '-' + moment(d.date).toISOString(),
            };

            let dataSeries_beacon_nextIndex = beacon_startIndex;

            meta.beacons.forEach(b => {
                datum.y[dataSeries_beacon_nextIndex] = {
                    key: b.key,
                    value: _.get(d, `filtered_beacons.${b.key}`, 0),
                    // value: Math.floor(Math.random()*1000 + 1), //<---- for testing, randomly generated
                    displayName: b.label,
                    // b, d
                };
                dataSeries_beacon_nextIndex++;
            });

            return datum;
        });

        // =========================
        // Additional meta:
        // =========================
        meta.statsMetricsType = props.meta.statsMetricsType;
        meta.campaignId = props.meta.campaignId;

        // Mearge meta and data
        var state = {};
        _.extend(state, { data: data }, { meta: meta });

        return state;
    };

    // ==================
    // Views
    // ==================
    updateMessage = (props, opts) => {
        var debug = this.debug;
        var M_startDate = moment(props.startDate);

        var isCreating = opts && opts.isCreating ? true : false;
        var startDateIsNull = props.startDate === null;
        var campaignHasNotStarted = moment().isBefore(M_startDate) ? true : false;
        var isLoading = props.isLoading;
        var noData = props.data.length ? false : true;

        var totalImpressions = props.data.reduce((sum, item) => {
                return item.filtered_impressions + sum;
            }, 0),
            zeroImpressions = totalImpressions === 0;

        // For debug:
        // var isCreating = false;
        // var startDateIsNull = false;
        // var campaignHasNotStarted = true;
        // var isLoading = false;
        // var noData = false;

        var message = {};
        message.campaignHasNotStarted = {
            title: "A little impatient, aren't we? ;-)",
            message:
                'This campaign is scheduled to start on ' +
                M_startDate.format('MMMM DD, YYYY') +
                '.<br>You might want to check back then.',
            img: '/images/report/icon-hourglass.png',
        };
        message.noData = {
            title: "We haven't matched any users yet",
            message:
                'If the campaign just began, give it some time -<br>our systems are working hard to acquire the right users.<br>Your targeting may also be too narrow. Try:<br><ul><li>Loosening your criteria</li><li>Increasing the max bid</li></ul>',
            img: '/images/report/strict-target.png',
        };

        switch (true) {
            case isCreating:
                this.chartOverlay_show_loader();
                if (debug) console.log('.... is creating');
                break;

            case startDateIsNull:
                this.chartOverlay_show_loader();
                if (debug) console.log('.... start date is null');
                break;

            case campaignHasNotStarted:
                this.chartOverlay_show_message(message.campaignHasNotStarted);
                if (debug) console.log('.... campaign has not started');
                break;

            case isLoading:
                this.chartOverlay_show_loader();
                if (debug) console.log('.... is loading');
                break;

            case noData:
                this.chartOverlay_show_message(message.noData);
                if (debug) console.log('.... data is empty');
                break;

            case zeroImpressions:
                this.chartOverlay_show_message(message.noData);
                break;

            default:
                this.chartOverlay_hide_all();
        }
    };

    render() {
        return (
            <div
                className="am-reports-timeSeriesGraph"
                onMouseMove={this.reactHandleMouseMove}
                onMouseOver={this.reactHandleMouseOver}
                onMouseOut={this.reactHandleMouseOut}
                ref={(currentRef) => { this.el = currentRef }}
            >
                <div className="am-reports-timeSeriesGraph-inner">
                    <svg />
                    <div className="am-reports-timeSeriesGraph-overlay am-reports-timeSeriesGraph-overlay-loading">
                        <div className="ef6-alignment__center">
                            <i className="fa fa-spinner fa-spin fa-3x" />
                        </div>
                    </div>

                    <div className="am-reports-timeSeriesGraph-overlay am-reports-timeSeriesGraph-overlay-message">
                        <div className="ef6-alignment__center">
                            <div className="am-reports-timeSeriesGraph-messageContainer">
                                <img
                                    className="am-reports-timeSeriesGraph-messageContainer-icon"
                                    src=""
                                />
                                <div className="am-reports-timeSeriesGraph-messageContainer-text">
                                    <div className="am-reports-timeSeriesGraph-messageContainer-title" />
                                    <div className="am-reports-timeSeriesGraph-messageContainer-body" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="am-reports-toolTip" />
                </div>
            </div>
        );
    }
}

export default ChartExplorerSummary;

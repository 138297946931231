import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import selector from './selector';
import actions from './actions';
import flags from 'containers/flags/service';

import { getEnv } from 'services/environment';
class Audiences extends React.Component {
    render() {
        return this.props.workspace;
    }
}

export default connect(selector)(Audiences);

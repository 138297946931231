import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import flags from 'containers/flags/service';
import GeoFencingLayerFinder from '../geo-fencing-layer-finder';
import classnames from 'classnames';
import PoiViewer from 'containers/poi-viewer';
import PoisEditor from 'containers/poi-editor';
import PrefixSuffixInput from 'widgets-v5/prefix-suffix-input';
import StandardInput from 'widgets-v5/standard-input';
import FormField from 'widgets-v5/form-field';
import RadioSelector from 'widgets-v5/radio-selector';
import Separator from 'widgets-v5/separator';
import Spacer from 'widgets-v5/spacer';
import { InlineTooltip, InlineTooltipContent } from 'widgets-v5/inline-tooltip';

export default class extends React.Component {
    static propTypes = {
        isPoiRecategorized: PropTypes.bool,
    };

    static defaultProps = {
        geoTargetingSettings: {
            categories: [],
            custom_layers: [],
            category_layers: [],
            retarget: false,
            days_to_retarget: 0,
        },
        isPoiRecategorized: false,
    };

    state = {
        layerModalActive: false,
        geoEntity_inEditor: void 0,
        searchWithinMapBounds: true, // New @TODO to be remove in pois viewer
        selectedValue: 'both',
    };

    handleFieldChange = type => {
        return value => {
            const geoSettings = {
                ...this.props.geoTargetingSettings,
                [type]: value,
            };

            this.props.onChange(geoSettings);
        };
    };

    handleMultiFieldChange = formData => {
        const geoSettings = {
            ...this.props.geoTargetingSettings,
            ...formData,
        };
        this.props.onChange(geoSettings);
    };

    toggleAddLayerModal = () => {
        this.setState({ layerModalActive: !this.state.layerModalActive });
    };

    selectGeoLayer = layerId => {
        const { geoTargetingSettings } = this.props;
        const { custom_layers } = geoTargetingSettings;

        this.handleFieldChange('custom_layers')(this.updateCollection(layerId, custom_layers));
    };

    selectGeoCategory = (categoryId, tag) => {
        const { geoTargetingSettings } = this.props;
        const { categories, category_layers } = geoTargetingSettings;

        // component can pass a tag to signify a subcategory was selected
        tag
            ? this.handleFieldChange('category_layers')(
                  this.updateCollection(categoryId, category_layers)
              )
            : this.handleFieldChange('categories')(this.updateCollection(categoryId, categories));
    };

    updateCollection = (id, collection) => {
        if (_.includes(collection, id)) {
            return _.without(collection, id);
        } else {
            return [].concat(collection, [id]);
        }
    };

    changePointRadius = radius => {
        const { geoTargetingSettings, onChange } = this.props;
        const currentRadius = geoTargetingSettings.radius;
        if (currentRadius === radius) {
            return;
        }
        this.handleFieldChange('radius')(parseInt(radius, 10));
    };

    setTargetAndRetarget = value => {
        switch (value) {
            case 'both':
                return this.setState({ selectedValue: value });
            case 'target':
                return this.setState({ selectedValue: value });
            case 'retarget':
                return this.setState({ selectedValue: value });
        }
    };

    handle_editImplicitLayer = id => {
        this.setState({ layerModalActive: !this.state.layerModalActive });
        this.setState({ geoEntity_inEditor: { type: 'implicit', id } });
    };

    handle_editCustomLayer = id => {
        this.setState({ layerModalActive: !this.state.layerModalActive });
        this.setState({ geoEntity_inEditor: { type: 'custom', id } });
    };

    handle_showGeoEntityInMap = (entityType, id) => {
        this.props.showGeoEntityInMap(entityType, id);
    };

    createLayerDone = layerId => {
        this.refs.GeoFencingLayerFinder.createLayerDone(layerId);
        this.props.showGeoEntityInMap('customLayer', layerId);
    };

    render() {
        const {
            adId,
            campaignId,
            geoTargetingSettings,
            geoCategories,
            geofencingEntityInView,
            poiViewer_eTag,
        } = this.props;

        if (!geoCategories) {
            return <div>Loading...</div>;
        }

        const { categories, custom_layers, category_layers } = geoTargetingSettings;

        const {
            selectedCategories,
            selectedCategoryLayers,
            selectedCustomLayers,
            id_implicitLayer,
            geoResources_unfiltered,
            geoLayersForThisOrg,
        } = geoCategories;

        const { radius } = geoTargetingSettings;
        const pointRadius = radius.toString();

        const adId_presentation = adId ? `Custom Layer (Ad #${adId})` : 'Custom Layer (New Ad)';

        return (
            <div>
                <Separator label="POI Segments" />
                <Spacer style="small" />
                <RadioSelector
                    onChange={this.setTargetAndRetarget}
                    activeItem={this.state.selectedValue}
                    items={[
                        { label: 'Target and Retarget', value: 'both' },
                        { label: 'Target', value: 'target' },
                        { label: 'Retarget', value: 'retarget' },
                    ]}
                />
                <Spacer style="small" />
                <div className="targetting-poi">
                    <div className="targetting-poi_inner">
                        <div className="targetting-poi_layerSettings">
                            <div className="targetting-poi_layerFinder">
                                <div className="targetting-poi_layerSettingsScrollPanel">
                                    <div className="poi-finder-filter">
                                        <StandardInput
                                            placeholder="Search POI segments"
                                            type="search"
                                            onChange={searchString => {
                                                this.props.onPoiSearchStringChanged(searchString);
                                            }}
                                        />
                                    </div>
                                    <div className="poi-finder-viewport">
                                        <GeoFencingLayerFinder
                                            handle_addCustomLayer={() =>
                                                this.handle_editCustomLayer(void 0)
                                            }
                                            ref="GeoFencingLayerFinder"
                                            toggleAddLayerModal={this.toggleAddLayerModal}
                                            editLayer={this.handle_editCustomLayer}
                                            layers={geoLayersForThisOrg}
                                            categories={geoCategories.geoCategories}
                                            geoLayerSearchString={this.props.geoLayerSearchString}
                                            selectGeoLayer={this.selectGeoLayer}
                                            selectGeoCategory={this.selectGeoCategory}
                                            onGeoLayerFinder_folderStateChanged={
                                                this.props.onGeoLayerFinder_folderStateChanged
                                            }
                                            includedCustomLayers={custom_layers}
                                            includedCategories={categories}
                                            includedCategoryLayers={category_layers}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="targetting-poi_layerViewer">
                                <PoiViewer
                                    ref="geofenceViewer2"
                                    geoCategories={geoCategories}
                                    geoTargetingSettings={this.props.geoTargetingSettings}
                                    geofencingEntityInView={geofencingEntityInView}
                                    poiViewer_eTag={poiViewer_eTag}
                                />
                            </div>

                            <div className="targetting-poi_selectedLayers">
                                <div className="targetting-poi_layerSettingsScrollPanel poi-selection-viewport">
                                    <div className="targetting-poi__lookback-window">
                                        {(this.state.selectedValue === 'both' ||
                                            this.state.selectedValue === 'retarget') && (
                                            <FormField label="Lookback Window (max 365)">
                                                <PrefixSuffixInput
                                                    value={geoTargetingSettings.days_to_retarget}
                                                    placeholder="0"
                                                    shouldAllowInput={input => {
                                                        const inputAsInt = Number(input);
                                                        return (
                                                            !isNaN(inputAsInt) && inputAsInt <= 365
                                                        );
                                                    }}
                                                    onChange={value =>
                                                        this.handleFieldChange('days_to_retarget')(
                                                            Number(value)
                                                        )
                                                    }
                                                    suffix="days"
                                                />
                                            </FormField>
                                        )}
                                    </div>
                                    <h4 className="targetting-poi__targetedHeader">Targeted</h4>
                                    <div className="poi-selection">
                                        <div className="non-implicit-selection">
                                            {_.map(selectedCategories, (category, key) => {
                                                return (
                                                    <div
                                                        className={classnames(
                                                            'poi-layer',
                                                            geofencingEntityInView.entityId ===
                                                                category.id
                                                                ? 'isActive'
                                                                : ''
                                                        )}
                                                        key={key}
                                                        title={category.name}
                                                    >
                                                        <div className="icon">
                                                            <i className="fa fa-fw fa-folder-open" />
                                                        </div>
                                                        <div
                                                            className="name"
                                                            onClick={() =>
                                                                this.handle_showGeoEntityInMap(
                                                                    'subCategory',
                                                                    category.id
                                                                )
                                                            }
                                                        >
                                                            {category.name}
                                                        </div>
                                                        <div className="remove">
                                                            <i
                                                                className="fa fa-fw fa-close"
                                                                onClick={() => {
                                                                    this.selectGeoCategory(
                                                                        category.id
                                                                    );
                                                                    this.handle_showGeoEntityInMap(
                                                                        void 0,
                                                                        void 0
                                                                    ); // show nothing in poi viewer
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {_.map(selectedCategoryLayers, (sub, key) => {
                                                return (
                                                    <div
                                                        className={classnames(
                                                            'poi-layer',
                                                            geofencingEntityInView.entityId ===
                                                                sub.id
                                                                ? 'isActive'
                                                                : ''
                                                        )}
                                                        key={key}
                                                        title={sub.name}
                                                    >
                                                        <div className="icon">
                                                            <i className="fa fa-fw fa-clone" />
                                                        </div>
                                                        <div
                                                            className="name"
                                                            onClick={() =>
                                                                this.handle_showGeoEntityInMap(
                                                                    'categoryLayer',
                                                                    sub.id
                                                                )
                                                            }
                                                        >
                                                            {sub.name}
                                                        </div>
                                                        <div className="remove">
                                                            <RemoveLayerButton
                                                                isPoiRecategorized={
                                                                    this.props.isPoiRecategorized
                                                                }
                                                                onRemove={() => {
                                                                    this.selectGeoCategory(
                                                                        sub.id,
                                                                        'sub'
                                                                    );
                                                                    this.handle_showGeoEntityInMap(
                                                                        void 0,
                                                                        void 0
                                                                    ); // show nothing in poi viewer
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {_.map(selectedCustomLayers, (layer, key) => {
                                                return (
                                                    <div
                                                        className={classnames(
                                                            'poi-layer',
                                                            geofencingEntityInView.entityId ===
                                                                layer.id
                                                                ? 'isActive'
                                                                : ''
                                                        )}
                                                        key={key}
                                                        title={layer.name}
                                                    >
                                                        <div className="icon">
                                                            <i className="fa fa-fw fa-clone" />
                                                        </div>
                                                        <div
                                                            className="name"
                                                            onClick={() =>
                                                                this.handle_showGeoEntityInMap(
                                                                    'customLayer',
                                                                    layer.id
                                                                )
                                                            }
                                                        >
                                                            {layer.name}
                                                        </div>
                                                        <div className="remove">
                                                            <RemoveLayerButton
                                                                isPoiRecategorized={
                                                                    this.props.isPoiRecategorized
                                                                }
                                                                onRemove={() => {
                                                                    this.selectGeoLayer(layer.id);
                                                                    this.handle_showGeoEntityInMap(
                                                                        void 0,
                                                                        void 0
                                                                    ); // show nothing in poi viewer
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.layerModalActive ? (
                        <PoisEditor
                            adId={adId}
                            campaignId={campaignId}
                            createLayerDone={this.createLayerDone}
                            geoTargetingSettings={this.props.geoTargetingSettings}
                            geoEntity={this.state.geoEntity_inEditor}
                            closeModal={this.toggleAddLayerModal}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

export function RemoveLayerButton(props) {
    const [anchor, setAnchor] = useState(null);

    return (
        <React.Fragment>
            <i
                onMouseEnter={event => setAnchor(event.currentTarget)}
                onMouseLeave={() => setAnchor(null)}
                className="fa fa-fw fa-close"
                onClick={props.onRemove}
            />

            {props.isPoiRecategorized ? (
                <InlineTooltip anchorEl={anchor}>
                    <InlineTooltipContent style={{ top: -100 }}>
                        This POI Segment may have been added from an old category. Removing the
                        Segment will not return it back to it's original category.
                    </InlineTooltipContent>
                </InlineTooltip>
            ) : props.isMiddleEastSegment ? (
                <InlineTooltip anchorEl={anchor}>
                    <InlineTooltipContent style={{ top: -55 }}>
                        Once you remove this segment you cannot add it back
                    </InlineTooltipContent>
                </InlineTooltip>
            ) : null}
        </React.Fragment>
    );
}

RemoveLayerButton.propTypes = {
    onRemove: PropTypes.func,
    isPoiRecategorized: PropTypes.bool,
};

import _ from 'lodash';
import { getHealth } from 'forms/ad-form/services';
import progressGraphSelector from './progress-graph-selector';
import ProgressCalculator from 'common/progress-calculator';

export default function selector(storeState, props) {
    const { campaignId, adId } = props.params;

    const ad = _.get(storeState, `resources.ads.${adId}`, {});
    const adStats = _.get(storeState, `resources.stats.ad.${ad.id}.standardTotal`, []);
    const adHealth = getHealth(ad.attr, [].concat(adStats));
    const campaign = _.get(storeState, `resources.campaigns.${props.params.campaignId}.attr`);
    const progressCalculator = new ProgressCalculator(ad.attr);
    const goal = progressCalculator.getGoal();
    const isUsingFrontLoadedPacing = ad.attr.use_front_load_pacing;
    const isUsingDailyCaps =
        ad.attr.max_daily_clicks + ad.attr.max_daily_impressions + ad.attr.max_daily_spend_local >
        0;
    const isEnded = ad.attr.status === 'ended';
    const timezoneAtCampaignLevel = _.get(storeState, 'campaignProgress.timezone');

    return {
        goal,
        progressGraph: progressGraphSelector(storeState, campaignId, adId, adHealth),
        campaignBudgetAllocationMethod: campaign.budget_allocation_method,
        isUsingFrontLoadedPacing,
        isUsingDailyCaps,
        isEnded,
        timezoneAtCampaignLevel,
    };
}

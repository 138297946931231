import React, { useState } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import map from 'lodash/map';
import makeStyles from '@mui/styles/makeStyles';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ComposableDataTable, Cell, Row } from 'widgets-v5/data-table';
import { DialogTitle } from 'widgets-v6/modal';
import EndDateSelector from './end-date-selector';
import Spacer from 'widgets-v5/spacer';
import StandardInput from 'widgets-v5/standard-input';
import { SearchInput } from 'widgets-v6/standard-input';
import Toggle from 'widgets-v5/toggle';

import { PLATFORM_MAPPING } from 'common/constants/platforms';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

const useStyles = makeStyles(theme => ({
    dateRange: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            whiteSpace: 'normal',
        },
    },
    tableHeader: {
        backgroundColor: '#EEEEEE',
    },
    previewImg: {
        width: '100%',
    },
    totalWeight: {
        border: 'none',
    },
    totalWeightError: {
        color: 'red',
    },
    scheduleTable: {
        [theme.breakpoints.down('lg')]: {
            '& .MuiTableCell-sizeSmall': {
                padding: '6px 12px 6px 12px',
            },
        },
    },
    manualWeightInput: {
        display: 'flex',
        alignItems: 'center',
        width: 60,
    },
    image: {
        width: 'auto',
        maxHeight: 100,
        maxWidth: 200,
    },
    title: {
        wordBreak: 'break-word',
    },
    error: {
        color: '#F44336',
    },

    //dialog
    dialogTitle: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    previewImgTableCell: {
        width: '15%',
    },
}));

function MultiPurposeCreativeTable({
    errors,
    showErrors,
    adEnd,
    addDateRange,
    removeMultiCreatives,
    campaign,
    creatives,
    removeScheduledWeightedCreative,
    scheduledCreatives,
    timezone,
    changeScheduledWeightedCreativeEndDate,
    updateScheduleRow,
    selectScheduledWeightedCreative,
    changeScheduledWeightedCreativeWeight,
    changeScheduledEvenWeighted,
    addScheduledWeightedCreative,
    removeDateRange,
    isCrossPlatformCampaign,
    disableScheduledRotation,
    creativeWarning,
    rotationErrors,
}) {
    const classes = useStyles();
    const [collapsedRows, setCollapsedRows] = useState({});
    const [isModalOpen, setModal] = useState(false);
    const [selectedCreatives, setSelectedCreatives] = useState([]);
    const [currentScheduleIndex, setCurrentScheduleIndex] = useState();
    const creativeMapping = {};

    _.each(creatives, creative => {
        creativeMapping[creative.creativeId] = creative;
    });

    let combinedCreatives = _.map(scheduledCreatives, creative => {
        const newCreative = {
            ...creative,
            weighted: _.map(creative.weighted, weight => {
                if (creativeMapping[weight.markup_id]) {
                    weight = { ...weight, selectedCreative: creativeMapping[weight.markup_id] };
                }
                return weight;
            }),
        };
        return newCreative;
    });

    const handleModal = ({ status, scheduleIndex }) => {
        if (scheduleIndex > -1) {
            setCurrentScheduleIndex(scheduleIndex);
        }
        setModal(status);
    };

    const collapseRow = dateRangeIndex => () => {
        removeDateRange(dateRangeIndex);
        setCollapsedRows({
            ...collapsedRows,
            [dateRangeIndex]: !collapsedRows[dateRangeIndex],
        });
    };

    const handleManualWeightChange = ({ dateRangeIndex, creativeIndex }) => weightAsText => {
        const weightAsNumber = weightAsText ? parseInt(weightAsText, 10) : 0;
        const weight = Math.max(Math.min(weightAsNumber, 100), 0);

        changeScheduledWeightedCreativeWeight({ dateRangeIndex, creativeIndex, weight });
    };

    const handleAddScheduledWeightedCreative = ({ currentScheduleIndex }) => {
        addScheduledWeightedCreative(currentScheduleIndex);
    };


    const handleAddWeightChange = ({ checkedCreativesIds }) => {
        _.each(creatives, (creative, creativeIndex) => {
            if (_.includes(checkedCreativesIds, creative.creativeId)) {
                handleAddScheduledWeightedCreative({ currentScheduleIndex });
                selectScheduledWeightedCreative({
                    dateRangeIndex: currentScheduleIndex,
                    creativeIndex,
                    creativeId: creative.creativeId,
                });
            }
        });
        changeScheduledEvenWeighted({
            dateRangeIndex: currentScheduleIndex,
        });
    };

    const removeWeights = ({ dateRangeIndex, creativeIndex }) => {
        removeScheduledWeightedCreative({
            dateRangeIndex,
            creativeIndex,
        });
    };

    const handleCreativesFromDialog = ({ newScheduleCreatives }) => {
        removeMultiCreatives({ dateRangeIndex: currentScheduleIndex });
        setSelectedCreatives(newScheduleCreatives);
        handleAddWeightChange({ checkedCreativesIds: newScheduleCreatives });
    };

    const handleManualToggle = (isManualWeight, dateRangeIndex) => {
        const newManualWeight = !isManualWeight;
        updateScheduleRow(dateRangeIndex, { isManualWeight: newManualWeight });
        if (!newManualWeight) {
            changeScheduledEvenWeighted({
                dateRangeIndex,
            });
        }
    };

    if (_.size(combinedCreatives) < 1) {
        addDateRange();
    }

    const isDisableScheduledRotation =
        _.isNil(disableScheduledRotation) || !disableScheduledRotation;

    return (
        <div>
            <Paper>
                <Box overflow="auto">
                    <Table size="small" className={classes.scheduleTable}>
                        {map(combinedCreatives, (scheduledCreative, scheduleIndex) => (
                            <CreativeTableBody
                                key={scheduleIndex}
                                scheduleIndex={scheduleIndex}
                                scheduledCreative={scheduledCreative}
                                timezone={timezone}
                                changeScheduledWeightedCreativeEndDate={
                                    changeScheduledWeightedCreativeEndDate
                                }
                                rotationErrors={rotationErrors}
                                handleManualToggle={handleManualToggle}
                                disableScheduledRotation={disableScheduledRotation}
                                adEnd={adEnd}
                                errors={errors}
                                showErrors={showErrors}
                                handleModal={handleModal}
                                collapseRow={collapseRow}
                                creativeWarning={creativeWarning}
                                isCrossPlatformCampaign={isCrossPlatformCampaign}
                                scheduledCreativesLength={_.size(scheduledCreatives)}
                                handleManualWeightChange={handleManualWeightChange}
                                removeWeights={removeWeights}
                                changeScheduledEvenWeighted={changeScheduledEvenWeighted}
                            />
                        ))}
                        {isDisableScheduledRotation && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={5}>
                                        <Button
                                            color="primary"
                                            onClick={addDateRange}
                                            startIcon={<AddIcon />}
                                        >
                                            Add Date Range
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </Box>
            </Paper>
            {isModalOpen && (
                <NewCreativeDialog
                    campaign={campaign}
                    creativeMapping={creativeMapping}
                    isModalOpen={isModalOpen}
                    handleModal={handleModal}
                    creatives={creatives}
                    selectedCreatives={selectedCreatives}
                    currentScheduleIndex={currentScheduleIndex}
                    combinedCreatives={combinedCreatives}
                    scheduledCreatives={scheduledCreatives}
                    handleCreativesFromDialog={handleCreativesFromDialog}
                />
            )}
        </div>
    );
}

const CreativeTableBody = ({
    scheduleIndex,
    scheduledCreative,
    timezone,
    changeScheduledWeightedCreativeEndDate,
    rotationErrors,
    handleManualToggle,
    disableScheduledRotation,
    adEnd,
    errors,
    showErrors,
    handleModal,
    creativeWarning,
    isCrossPlatformCampaign,
    scheduledCreativesLength,
    collapseRow,
    handleManualWeightChange,
    removeWeights,
    changeScheduledEvenWeighted,
}) => {
    const classes = useStyles();
    const creativeWarningIndex =
        creativeWarning.hasWarning &&
        creativeWarning.rows.findIndex(element => element.row === scheduleIndex + 1);

    return (
        <TableBody>
            <ScheduleHeader
                start={scheduledCreative.start}
                end={scheduledCreative.end}
                timezone={timezone}
                dateRangeIndex={scheduleIndex}
                changeScheduledWeightedCreativeEndDate={changeScheduledWeightedCreativeEndDate}
                error={rotationErrors}
                scheduledCreativesLength={scheduledCreativesLength}
                onExpand={collapseRow(scheduleIndex)}
                isFinalRow={scheduleIndex === scheduledCreativesLength - 1}
                handleManualToggle={handleManualToggle}
                isManualWeight={scheduledCreative.isManualWeight}
                selectedCreativeSize={_.size(scheduledCreative.weighted)}
                disableScheduledRotation={disableScheduledRotation}
                lastSelectableDate={moment(adEnd)
                    .tz(timezone)
                    .subtract(1, 'hours')
                    .toISOString()}
            />
            {map(scheduledCreative.weighted, (creative, index) => (
                <CreativeWeightedRow
                    key={creative.markup_id}
                    creative={creative}
                    index={index}
                    scheduledCreative={scheduledCreative}
                    scheduleIndex={scheduleIndex}
                    handleManualWeightChange={handleManualWeightChange}
                    removeWeights={removeWeights}
                    changeScheduledEvenWeighted={changeScheduledEvenWeighted}
                />
            ))}
            <TableRow>
                <TableCell colSpan={5} className={classes.totalWeight} align="right">
                    {_.size(scheduledCreative.weighted) > 1 && (
                        <div>
                            <Box
                                display={_.size(errors.rotation_rules) > 1 ? 'flex' : null}
                                justifyContent="space-between"
                            >
                                {errors.rotation_rules && (
                                    <div className={classes.totalWeightError}>
                                        {errors.rotation_rules}
                                    </div>
                                )}
                                <p>{_.sumBy(scheduledCreative.weighted, 'weight')}%</p>
                            </Box>
                        </div>
                    )}
                    {showErrors && errors[`rotation_rules.scheduled.${scheduleIndex}`] && (
                        <div className={classes.totalWeightError}>
                            {errors[`rotation_rules.scheduled.${scheduleIndex}`]}
                        </div>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={5}>
                    <Button
                        color="primary"
                        onClick={() => handleModal({ status: true, scheduleIndex })}
                        startIcon={<AddIcon />}
                    >
                        Add Creative
                    </Button>
                </TableCell>
            </TableRow>
            {creativeWarning.hasWarning && isCrossPlatformCampaign && creativeWarningIndex !== -1 && (
                <TableRow>
                    <TableCell colSpan={5}>
                        <WarningText
                            severity={creativeWarning.severity}
                            message={creativeWarning.rows[creativeWarningIndex].message}
                        />
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};

const CreativeWeightedRow = ({
    creative,
    index,
    scheduledCreative,
    scheduleIndex,
    handleManualWeightChange,
    removeWeights,
    changeScheduledEvenWeighted,
}) => {
    const classes = useStyles();
    if (creative.selectedCreative) {
        return (
            <TableRow key={creative.markup_id}>
                <TableCell className={classes.previewImgTabelCell}>
                    <CardMedia
                        component="img"
                        image={creative.selectedCreative.previewImg}
                        className={classes.image}
                    />
                </TableCell>
                <TableCell colSpan={2}>
                    <Typography classes={{ root: classes.title }} color="textPrimary" gutterBottom>
                        {creative.selectedCreative.name}
                    </Typography>
                    <Grid container spacing={1} alignItems="center">
                        {_.map(creative.selectedCreative.platforms, platform => (
                            <Grid item>
                                <Chip
                                    key={platform}
                                    label={platform}
                                    size="small"
                                    variant="outlined"
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box mt={0.5}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Chip label={creative.selectedCreative.size} size="small" />
                            </Grid>
                            <Grid>
                                <Chip label={creative.selectedCreative.type} size="small" />
                            </Grid>
                        </Grid>
                    </Box>
                </TableCell>
                {scheduledCreative.weighted.length > 1 ? (
                    <TableCell align="right">
                        {scheduledCreative.isManualWeight ? (
                            <Box display="flex" justifyContent="flex-end">
                                <div className={classes.manualWeightInput}>
                                    <StandardInput
                                        onChange={handleManualWeightChange({
                                            dateRangeIndex: scheduleIndex,
                                            creativeIndex: index,
                                        })}
                                        value={creative.weight}
                                    />
                                    %
                                </div>
                            </Box>
                        ) : (
                            <div>{creative.weight}%</div>
                        )}
                    </TableCell>
                ) : (
                    <TableCell />
                )}
                <TableCell align="right">
                    <IconButton
                        aria-label="delete"
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeWeights({
                                dateRangeIndex: scheduleIndex,
                                creativeIndex: index,
                            });
                            changeScheduledEvenWeighted({
                                dateRangeIndex: scheduleIndex,
                            });
                        }}
                        size="large"
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
    return <TableRow key={creative.markup_id} />;
};

function ScheduleHeader(props) {
    const classes = useStyles();

    const {
        start,
        end,
        timezone,
        dateRangeIndex,
        selectedCreativeSize,
        scheduledCreativesLength,
        changeScheduledWeightedCreativeEndDate,
        isFinalRow,
        lastSelectableDate,
        handleManualToggle,
        isManualWeight,
        onExpand,
        disableScheduledRotation,
        error,
    } = props;
    if (disableScheduledRotation) {
        return (
            <TableRow className={classes.tableHeader}>
                <TableCell className={classes.dateRange} align="left" colSpan={3}>
                    Edit Date not allowed in different start and end-date of ads
                </TableCell>
                <TableCell colSpan={2}>
                    <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                        <Grid item>
                            {selectedCreativeSize > 1 && (
                                <Typography noWrap>Manual Weighting</Typography>
                            )}
                        </Grid>
                        <Grid item>
                            {selectedCreativeSize > 1 && (
                                <Toggle
                                    position={isManualWeight ? 'right' : 'left'}
                                    onClick={() =>
                                        handleManualToggle(isManualWeight, dateRangeIndex)
                                    }
                                    textLeft="off"
                                    textRight="on"
                                    width={60}
                                    height={25}
                                />
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        );
    }
    if (scheduledCreativesLength < 2) {
        return (
            <TableRow className={classes.tableHeader}>
                {_.isEmpty(start) || _.isEmpty(end) ? (
                    <TableCell className={classes.dateRange} align="left" colSpan={3}>
                        Please Choose Ad Start and End date
                    </TableCell>
                ) : (
                    <TableCell className={classes.dateRange} align="left" colSpan={3}>
                        {moment(start)
                            .tz(timezone)
                            .format('MMM DD, YYYY HH:mm z')}{' '}
                        -{' '}
                        {moment(end)
                            .tz(timezone)
                            .format('MMM DD, YYYY HH:mm z')}
                    </TableCell>
                )}
                <TableCell colSpan={2}>
                    <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                        <Grid item>
                            {selectedCreativeSize > 1 && (
                                <Typography noWrap>Manual Weighting</Typography>
                            )}
                        </Grid>
                        <Grid item>
                            {selectedCreativeSize > 1 && (
                                <Toggle
                                    position={isManualWeight ? 'right' : 'left'}
                                    onClick={() =>
                                        handleManualToggle(isManualWeight, dateRangeIndex)
                                    }
                                    textLeft="off"
                                    textRight="on"
                                    width={60}
                                    height={25}
                                />
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <TableRow className={classes.tableHeader}>
            {_.isEmpty(start) ? (
                <TableCell
                    className={`${classes.dateRange} ${error ? classes.error : ''}`}
                    align="left"
                >
                    Select end date for previous date range
                </TableCell>
            ) : (
                <TableCell className={classes.dateRange} colSpan={1} align="left">
                    {moment(start)
                        .tz(timezone)
                        .format('MMM DD, YYYY HH:mm z')}{' '}
                    -{' '}
                </TableCell>
            )}
            <TableCell align="left" colSpan={1} className={classes.dateRangeTableCell}>
                <EndDateSelector
                    error={error}
                    isFinalRow={isFinalRow}
                    rowStart={start}
                    rowEnd={end}
                    timezone={timezone}
                    lastSelectableDate={lastSelectableDate}
                    isDisabled={false}
                    onChange={v => {
                        changeScheduledWeightedCreativeEndDate({
                            dateRangeIndex,
                            end: moment.utc(v.end).format(),
                        });
                    }}
                />
            </TableCell>
            <TableCell colSpan={2}>
                <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>
                        {selectedCreativeSize > 1 && (
                            <Typography noWrap>Manual Weighting</Typography>
                        )}
                    </Grid>
                    <Grid item>
                        {selectedCreativeSize > 1 && (
                            <Toggle
                                position={isManualWeight ? 'right' : 'left'}
                                onClick={() => handleManualToggle(isManualWeight, dateRangeIndex)}
                                textLeft="off"
                                textRight="on"
                                width={60}
                                height={25}
                            />
                        )}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align="right">
                <IconButton onClick={onExpand} size="large">
                    <DeleteIcon className={classes.icon} />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}

function NewCreativeDialog({
    creatives,
    currentScheduleIndex,
    scheduledCreatives,
    isModalOpen,
    handleModal,
    handleCreativesFromDialog,
}) {
    const classes = useStyles();

    const mappedCreativeIds = _.map(
        scheduledCreatives[currentScheduleIndex].weighted,
        weight => weight.markup_id
    ).filter(w => !_.isNil(w));

    const [newScheduleCreativesIds, setNewScheduleCreativesIds] = useState(mappedCreativeIds);
    const [allChecked, setAllChecked] = useState(false);
    const [showAllCheckError, setShowError] = useState(false);
    const [searchText, setSearchText] = useState('');

    const newCreatives = _.map(creatives, creative => {
        let newCreative = creative;
        if (_.includes(newScheduleCreativesIds, creative.creativeId)) {
            newCreative = { ...creative, selected: true };
        } else {
            newCreative = { ...creative, selected: false };
        }
        return newCreative;
    });

    const handleCreativeSelection = creative => {
        if (creative.disableSelection) {
            return;
        }

        let newIds;
        if (_.includes(newScheduleCreativesIds, creative.creativeId)) {
            newIds = _.difference(newScheduleCreativesIds, [creative.creativeId]);
        } else {
            newIds = [...newScheduleCreativesIds, creative.creativeId];
        }
        setNewScheduleCreativesIds(newIds);
    };

    const filteredCreatives = _.filter(newCreatives, creative => {
        if (!searchText) {
            return true;
        }
        if (
            creative.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            creative.creativeId.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        ) {
            return true;
        }
        return false;
    });

    const handleSelectAll = () => {
        let newIds;
        if (allChecked) {
            newIds = [];
            setAllChecked(false);
        } else {
            const validCreatives = _.filter(
                filteredCreatives,
                creative => !creative.disableSelection
            );
            newIds = _.map(validCreatives, creative => creative.creativeId);
            setAllChecked(true);
        }
        setShowError(false);
        setNewScheduleCreativesIds(newIds);
    };

    const handleAdd = () => {
        if (!showAllCheckError) {
            handleCreativesFromDialog({ newScheduleCreatives: newScheduleCreativesIds });
            handleModal({ status: false });
        }
    };

    const handleSearch = _.debounce(text => {
        setSearchText(_.trim(text));
    }, 200);

    return (
        <Dialog
            onClose={() => {
                handleModal({ status: false });
            }}
            open={isModalOpen}
            maxWidth="md"
            scroll="paper"
            fullWidth
        >
            <DialogTitle onClose={() => handleModal({ status: false })}>
                Select Creatives
            </DialogTitle>
            <DialogContent dividers="paper">
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item xs={3}>
                        <SearchInput
                            placeholder="Filter Creatives"
                            defaultValue={searchText}
                            onChange={event => handleSearch(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} width="100%">
                        <Box height="100%" maxHeight="400" overflow="auto">
                            <Spacer type="small" />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={allChecked}
                                        color="primary"
                                        onChange={handleSelectAll}
                                    />
                                }
                                label="Select all"
                            />
                            <Spacer type="small" />
                            <ComposableDataTable>
                                <Row
                                    key="header"
                                    header={true}
                                    className="ef3-creativeRotationSelector_creative"
                                >
                                    <Cell />
                                    <Cell className="ef3-creativeRotationSelector_name">
                                        Creative Name
                                    </Cell>
                                    <Cell className="ef3-creativeRotationSelector_preview">
                                        Preview
                                    </Cell>
                                </Row>
                                {_.map(filteredCreatives, (creative, index) => (
                                    <Row
                                        key={creative.creativeId}
                                        disabled={creative.disableSelection}
                                        className="ef3-creativeRotationSelector_customRotationRow"
                                        onClick={() => handleCreativeSelection(creative, index)}
                                    >
                                        <Cell>
                                            <Checkbox
                                                checked={creative.selected}
                                                color="primary"
                                                onChange={() =>
                                                    handleCreativeSelection(creative, index)
                                                }
                                                disabled={creative.disableSelection}
                                            />
                                        </Cell>
                                        <Cell>
                                            <Typography
                                                className={classes.title}
                                                color="textPrimary"
                                                gutterBottom
                                            >
                                                #{creative.creativeId} {creative.name}
                                            </Typography>
                                            <Grid container direction="row" spacing={1}>
                                                {_.map(creative.platforms, platform => (
                                                    <Grid item key={platform}>
                                                        <Chip
                                                            size="small"
                                                            label={PLATFORM_MAPPING[platform].label}
                                                            variant="outlined"
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Box mt={1}>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <Chip label={creative.size} size="small" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Chip label={creative.type} size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Cell>
                                        <Cell className="ef3-creativeRotationSelector_preview">
                                            <img
                                                className="ef3-creativeRotationSelector_previewImage"
                                                src={creative.previewImg}
                                            />
                                        </Cell>
                                    </Row>
                                ))}
                            </ComposableDataTable>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleAdd}>
                    Add
                </Button>
            </DialogActions>
            {showAllCheckError && (
                <Typography>Its not possible to check different types</Typography>
            )}
        </Dialog>
    );
}

export default MultiPurposeCreativeTable;

import _ from 'lodash';

export const NAME = 'behaviourDashboard';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;

        case 'BEHAVIOUR_DASHBOARD__FETCH_SEGMENTS_DATA__END': {
            return {
                ...state,
                audienceSegments: action.payload.data,
            };
        }
    }
}

// Use selectors to generate derived state, e.g. errors are derived from the draft
import { createSelector } from 'reselect';
import { REDUCER_KEY } from './reducer';

export const getTemplateState = state => state[REDUCER_KEY];

export const getDraft = createSelector(
    getTemplateState,
    templateState => {
        return templateState.draft;
    }
);

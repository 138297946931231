import _ from 'lodash';
import VError from 'verror';

import Ads from 'states/resources/ads/actions';
import Campaigns from 'states/resources/campaigns/actions';
import UserActions from 'states/resources/users/actions';
import c from 'common/constants/flux-events';
import notify from 'utils/notify';
import { GraphQLClient } from 'graphql-request';
import { createHttp, getNextBase } from 'utils/http';
import { getDefaults } from 'forms/campaign-form/services';
import { getEnvironmentSettings } from 'services/environment';
import { getRevenueModelDefaults } from 'states/resources/campaigns/business-logic';
import { isInternalUser } from 'states/profile/business-rules';
import { initializeConversionsDraft } from 'pages/campaigns/conversions-overview/actions';
import { fetchOverviewPageGraphqlData } from 'pages/campaigns/campaigns-overview/actions';

const defaultsCampaignDraft = {
    ...getDefaults('payload_formData'),
    ...getDefaults('payload_createOnly'),
};

import { actions } from './reducer';
import { getDraftErrors } from './selectors';

const initMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.init.type) {
        const { campaignId } = action.payload;

        const data = await fetchFormData();

        let draft;
        let flights = [];
        if (campaignId) {
            draft = await getDraftForExistingCampaign({ dispatch, getState, campaignId });
            flights = await fetchCampaignFlightMetadata(campaignId);
        } else {
            draft = await getDraftForNewCampaign({ dispatch, getState });
        }

        dispatch(actions.initSuccess({
            campaign: draft,
            ...data,
            flights,
        }))

        return draft;
    }
};

const submitMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.submit.type) {
        const campaignId = action.payload;
        const onSubmit = action.meta;

        const errors = getDraftErrors(getState());

        if (_.size(errors) > 0) {
            if (window.DEBUG) {
                console.log('errors', errors);
            }
            dispatch(actions.submitClientErrorsUnresolved(errors));
            return;
        }

        const draft = _.get(getState(), `campaignForm.draft`, []);

        try {
            let campaign;
            if (campaignId) {
                campaign = await updateCampaign({ dispatch, getState, campaignId, draft });
            } else {
                campaign = await createCampaign({ dispatch, draft });
            }

            if (onSubmit) {
                onSubmit(campaign);
            }
        } catch (err) {
            dispatch(actions.submitServerErrors(err));
        }
    }
};

const advertiserCreatedMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    if (action.type === 'ADVERTISER_FORM__CREATE__SUCCESS') {
        getOrganizations({ dispatch, getState });
    }
};

async function fetchFormData() {
    try {
        const http = createHttp();
        const query = `
            query getCampaignFormData {
                ftaLocationLists {
                    id
                    name
                    status
                }
            }
        `;

        const variables = {};

        const data = await http.graphql(query, variables);

        return data;
    } catch (err) {
        notify({
            error: new VError(err, 'Failed to fetch data for Campaign Form'),
        });
    }
}

async function fetchCampaignFlightMetadata(campaignId) {
    try {
        const http = createHttp();
        const query = `
            query getCampaignFlightMetadata($campaignId: Int) {
                campaign(id: $campaignId) {
                    flights {
                        metadata {
                            flightId
                            metrics {
                                impressions
                                owner_total_media_cost_local
                                billings_local
                            }
                        }
                    }
                }
            }
        `;

        const variables = {
            campaignId: Number(campaignId),
        };

        const {
            campaign: { flights },
        } = await http.graphql(query, variables);

        return _.map(flights, flight => ({
            flightId: _.get(flight, 'metadata.flightId'),
            owner_total_media_cost_local: _.get(
                flight,
                'metadata.metrics.owner_total_media_cost_local',
                0
            ),
            billings_local: _.get(flight, 'metadata.metrics.billings_local', 0),
            impressions: _.get(flight, 'metadata.metrics.impressions', 0),
        }));
    } catch (err) {
        notify({
            error: new VError(err, 'Failed to fetch data for Campaign Form'),
        });
    }
}

async function getDraftForExistingCampaign({ dispatch, getState, campaignId }) {
    try {
        const [user, organizations, campaign, ads] = await Promise.all([
            dispatch(UserActions.get()),
            getOrganizations({ dispatch, getState }),
            dispatch(Campaigns.get(campaignId)),
            dispatch(Ads.getAll(campaignId)),
        ]);

        const organization = _.find(organizations, { id: user.organization });

        const currency = organization ? organization.currency : defaultsCampaignDraft.currency;
        const owner = user.organization;

        const campaignAttr_form = {
            ...defaultsCampaignDraft, // so that attr form does not has missing field
            currency,
            owner,
            ...campaign,
            ads,
        };

        return campaignAttr_form;
    } catch (error) {
        dispatch({
            type: c.OVERVIEW__CAMPAIGN_DRAFT__FETCH_ORGANIZATIONS_FAIL,
            payload: {
                error,
            },
        });

        console.error(error);
    }
}

async function getDraftForNewCampaign({ dispatch, getState }) {
    try {
        const [user, organizations] = await Promise.all([
            dispatch(UserActions.get()),
            getOrganizations({ dispatch, getState }),
        ]);

        const environmentSettings = getEnvironmentSettings();

        const organization = _.find(organizations, { id: user.organization });
        const currency = organization
            ? organization.currency
            : environmentSettings.getDefaultCurrency();
        const default_timezone = organization
            ? organization.default_timezone
            : defaultsCampaignDraft.default_timezone;
        const owner = user.organization;

        let campaignAttr_form = {
            ...defaultsCampaignDraft, // so that attr form does not has missing field
            currency,
            default_timezone,
            owner,
            custom_fields: organization.custom_fields,
        };

        const revenueModelDefaults = getRevenueModelDefaults(organization.revenueModel);

        campaignAttr_form = {
            ...campaignAttr_form,
            ...revenueModelDefaults,
        };
        campaignAttr_form.creatorId = _.get(getState(), 'profile.userId');
        campaignAttr_form.revenueModel = organization.revenueModel || null;

        if (
            campaignAttr_form.billing_rate === 0 &&
            campaignAttr_form.revenueModel === 'agencyMargin'
        ) {
            campaignAttr_form.billing_rate = organization.agencyMarginRate;
        }

        // Full-serve should use old FTA pixel method. Self-serve should use new automated FTA method
        campaignAttr_form.fta_version = 2;
        campaignAttr_form.fta_enabled = false;
        campaignAttr_form.fta_management_level = null;
        campaignAttr_form.fta_location_list = null;
        campaignAttr_form.fta_partner_id = organization.fta_partner_id;

        if (isInternalUser()) {
            campaignAttr_form.isUsingFtaFullyManagedFields = true;
        }

        if ('623c83c69d8447ab615c7c7d' === organization.id) {
            campaignAttr_form.isUsingFtaLimitedFields = true;
        }

        // Make sure to initialize the campaign_primary_pacing field here
        // This is because the field doesn't exist in the UI, it's just set
        // when changing the revenue model or budget allocation method.
        campaignAttr_form.campaign_primary_pacing = 'spend';
        campaignAttr_form.campaign_max_total_impressions = 0;

        return campaignAttr_form;
    } catch (error) {
        dispatch({
            type: c.NEW_CAMPAIGN__DRAFT__INIT_FAIL,
            payload: {
                error,
            },
        });

        console.error(error);
    }
}

async function getOrganizations({ dispatch, getState }) {
    try {
        const http = createHttp();

        const graphqlUrl = getNextBase() + 'graphql';

        const client = new GraphQLClient(graphqlUrl, {
            headers: {
                ...http._getHeaders(),
                Authorization: _.get(getState(), 'profile.authToken.auth_token'),
            },
        });

        const query = `
            {
                organizations(filters: {relationship: "child", type: ["client", "co_managed_client"]}) {
                    id
                    name
                    currency
                    default_timezone
                    custom_fields {
                        name
                        value
                        required
                        key
                    }
                    type
                    client_type
                    revenueModel
                    ftaEnabled
                    fta_partner_id
                    agencyMarginRate
                }
                ownOrganization {
                    id
                    type
                    fta_partner_id
                    agencyMarginRate
                    revenueModel
                    users {
                        first_name
                        last_name
                        id
                        suspended
                        global_role
                    }
                    ftaEnabled
                }
            }
        `;

        const data = await client.request(query);

        let organizations = data.organizations;

        let formattedOrganizations = {};
        _.each(organizations, org => {
            formattedOrganizations[org.id] = {
                id: org.id,
                attr: org,
            };
        });

        let ownOrganization = data.ownOrganization;
        const { id, users } = ownOrganization;
        formattedOrganizations[id] = {
            ...formattedOrganizations[id],
            attr: {
                ...formattedOrganizations[id].attr,
                users,
            },
        };

        dispatch(
            actions.loadOrganizations({
                organizations: formattedOrganizations,
                ownOrganization,
            })
        );

        return organizations;
    } catch (err) {
        console.error(`GET ALL organizations error`, err);

        throw err;
    }
}

async function createCampaign({ dispatch, draft }) {
    return dispatch(Campaigns.create(draft)).then(
        response => {
            const campaign = response;

            dispatch(actions.submitSuccess());

            return campaign;
        },
        error => {
            // throw toaster error for server errors
            dispatch({
                type: c.NEW_CAMPAIGN__DRAFT__SUBMIT_FAIL,
                error: error.body,
            });

            return Promise.reject(error.body);
        }
    );
}

async function updateCampaign({ dispatch, campaignId, draft }) {
    if (!campaignId) {
        throw new Error('Edit campaign campaignId not found');
    }

    dispatch({
        type: c.OVERVIEW__CAMPAIGN_DRAFT__SUBMIT,
        payload: { campaignId, draft },
    });

    return dispatch(Campaigns.update(campaignId, draft)).then(
        () => {
            dispatch(actions.submitSuccess());
            dispatch(Ads.getAll(campaignId));
            dispatch(initializeConversionsDraft(campaignId));
            dispatch(fetchOverviewPageGraphqlData(Number(campaignId)));
        },
        error => {
            dispatch({
                type: c.OVERVIEW__CAMPAIGN_DRAFT__SUBMIT_FAIL,
                payload: { campaignId },
                error: error.body,
            });

            return Promise.reject(error.body);
        }
    );
}

export const makeMiddlewares = deps => {
    return [initMw(deps), submitMw(deps), advertiserCreatedMw(deps)];
};
export const middlewares = makeMiddlewares({});

import _ from 'lodash';
import React from 'react';

import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';
import { getCarrierIspOptions } from 'common/constants/carrier-targeting-v2';
import { MultiSelect } from 'widgets-v6/select';

import {
    getTargetCarriersInclude,
    getTargetCarriersExclude,
} from 'common/constants/carrier-targeting-v2';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { getCarrierISPWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

const InvetoryIconContainer = withStyles(theme => ({
    root: {
        paddingRight: '9px',
        flexBasis: '4.33%',
    },
}))(Grid);

const TitleContainer = withStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
}))(Grid);

const getOptionsExcludedFromList = (options, list) => {
    return _.filter(options, ({ value, label }) => !_.includes(list, value));
};

const getIcon = (iconType, color) => {
    return iconType === 'check' ? (
        <CheckIcon fontSize="small" color={color} />
    ) : (
        <BlockIcon fontSize="small" color={color} />
    );
};

const useStyles = makeStyles({
    carrierItems: {
        marginLeft: 10,
    },
});

const MultiSelectSummary = ({ color, title, items, iconType }) => {
    const classes = useStyles();
    return (
        <Grid item>
            <TitleContainer container alignItems="center">
                <InvetoryIconContainer item>{getIcon(iconType, color)}</InvetoryIconContainer>
                <Grid item>{title}</Grid>
            </TitleContainer>
            <Grid item className={classes.carrierItems}>
                <InvetoryIconContainer item />
                <Grid item>{items}</Grid>
            </Grid>
        </Grid>
    );
};

export class TargetCarriersIspsSummary extends React.Component {
    shouldComponentUpdate(nextProps) {
        const shouldUpdate =
            this.props.ad.target_carriers !== nextProps.ad.target_carriers ||
            this.props.ad.carriers_exclude !== nextProps.ad.carriers_exclude ||
            this.props.ad.platform !== nextProps.ad.platform;

        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;

        const carrierISPIncludeValue = getTargetCarriersInclude(ad.target_carriers);
        const carrierISPExcludeValue = getTargetCarriersExclude(ad.carriers_exclude);

        return (
            <div>
                <Grid container spacing={2} direction="column">
                    <MultiSelectSummary
                        color="primary"
                        title="Include"
                        items={carrierISPIncludeValue}
                        iconType="check"
                    />
                    <MultiSelectSummary
                        color="secondary"
                        title="Exclude"
                        items={carrierISPExcludeValue}
                        iconType="block"
                    />
                </Grid>
            </div>
        );
    }
}

export const TargetCarriersIspsForm = props => {
    const { ad, ads, campaign, onSave, onCancel } = props;

    const platform = ad ? ad.platform : ads[0].platform;
    const platforms = ad
        ? _.get(ad, 'platforms')
        : _(ads)
              .map(ad => ad.platforms)
              .flatten()
              .uniq()
              .value();
    const options = getCarrierIspOptions({
        platform,
        platforms,
        isCrossPlatformCampaign: campaign.isCrossPlatformCampaign,
    });

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Carrier / ISP"
            fields={['target_carriers', 'carriers_exclude']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onCancel}
            columnName="Carrier / ISP"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                let warningData = {};
                if (campaign.isCrossPlatformCampaign) {
                    warningData = getCarrierISPWarning({
                        platforms,
                        includedCarriers: draft.target_carriers,
                    });
                }

                return (
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <Grid container alignItems="center">
                                <InvetoryIconContainer item>
                                    <CheckIcon color="primary" />
                                </InvetoryIconContainer>
                                <Grid item>Include</Grid>
                            </Grid>
                            <Grid container alignItems="center" wrap="nowrap">
                                <InvetoryIconContainer item />
                                <Grid item xs>
                                    <MultiSelect
                                        value={draft.target_carriers}
                                        onChange={val => updateDraft({ target_carriers: val })}
                                        options={getOptionsExcludedFromList(
                                            options,
                                            draft.carriers_exclude
                                        )}
                                        placeholder="Select carriers / ISPs to include"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container alignItems="center">
                                <InvetoryIconContainer item>
                                    <BlockIcon color="secondary" />
                                </InvetoryIconContainer>
                                <Grid item>Exclude</Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <InvetoryIconContainer item />
                                <Grid item xs>
                                    <MultiSelect
                                        value={draft.carriers_exclude}
                                        onChange={val => updateDraft({ carriers_exclude: val })}
                                        options={getOptionsExcludedFromList(
                                            options,
                                            draft.target_carriers
                                        )}
                                        placeholder="Select carriers / ISPs to exclude"
                                    />
                                </Grid>
                            </Grid>
                            {campaign.isCrossPlatformCampaign && warningData.hasWarning && (
                                <React.Fragment>
                                    <br />
                                    <WarningText
                                        message={warningData.message}
                                        title={warningData.title}
                                        severity={warningData.severity}
                                    />
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                );
            }}
        </BaseColumnForm>
    );
};

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions, REDUCER_KEY } from './reducer';
import { getEnvironmentSettings } from 'services/environment';

export function useCampaignSummary() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);

    const {
        organizations = [],
        ownOrganization = {},
        ftaLocationLists = [],
        campaign = {},
    } = state;

    const REVENUE_MODEL_OPTIONS = [
        { label: 'CPM / CPC', value: 'cpmcpc' },
        { label: 'CPM', value: 'cpm' },
        { label: 'Agency margin', value: 'agencyMargin' },
        { label: 'Disabled', value: 'disabled' },
    ];

    const BUDGET_ALLOCATION_OPTIONS = [
        { label: 'Auto', value: 'auto' },
        { label: 'Manual', value: 'manual' },
    ];

    const RESTRICTED_CATEGORY_OPTIONS = [
        { label: 'Cannabis', value: 'cannabis' },
    ];

    const getShowFta = () =>
        (campaign.fta_version === 2 && ownOrganization.ftaEnabled) ||
        campaign.isUsingFtaFullyManagedFields;

    const getShowFtaFullyManagedFields = () =>
        campaign.isUsingFtaFullyManagedFields && campaign.fta_enabled;

    const salesReps = _(organizations)
        .filter(x => x)
        .filter(org => org.client_type === 'sales_rep')
        .map(org => ({
            label: org.name,
            value: org.id,
        }))
        .sortBy('label')
        .value();

    const partners = _(organizations)
        .filter(x => x)
        .filter(org => org.client_type === 'partner')
        .map(org => ({
            label: org.name,
            value: org.id,
        }))
        .sortBy('label')
        .value();

    const advertisers = _(organizations)
        .filter(x => x)
        .filter(org => org.client_type === 'advertiser')
        .map(org => ({
            label: org.name,
            revenueModel: org.revenueModel,
            agencyMarginRate: org.agencyMarginRate,
            value: org.id,
        }))
        .sortBy('label')
        .value();

    const getUsersMapping = () => {
        const usersMapping = {};

        _.each(ownOrganization.users, user => {
            usersMapping[user.id] = {
                ...user,
                fullname: `${user.first_name} ${user.last_name}`,
            };
        });

        return usersMapping;
    };

    const getPartnersMapping = () => {
        const partnersMapping = {};

        _.each(partners, partner => {
            partnersMapping[partner.value] = partner;
        });

        return partnersMapping;
    };

    const getSalesRepsMapping = () => {
        const salesRepsMapping = {};

        _.each(salesReps, salesRep => {
            salesRepsMapping[salesRep.value] = salesRep;
        });

        return salesRepsMapping;
    };

    const getAdvertisersMapping = () => {
        const advertisersMapping = {};

        _.each(advertisers, advertiser => {
            advertisersMapping[advertiser.value] = advertiser;
        });

        return advertisersMapping;
    };

    const getLocationListsMapping = () => {
        const locationListsMapping = {};

        _.each(ftaLocationLists, option => {
            locationListsMapping[option.id] = option;
        });

        return locationListsMapping;
    };

    const getRevenueModelMapping = () => {
        const revenueModelMapping = {};

        _.each(REVENUE_MODEL_OPTIONS, option => {
            revenueModelMapping[option.value] = option;
        });

        return revenueModelMapping;
    };

    const getBudgetAllocationMapping = () => {
        const budgetAllocationMapping = {};

        _.each(BUDGET_ALLOCATION_OPTIONS, option => {
            budgetAllocationMapping[option.value] = option;
        });

        return budgetAllocationMapping;
    };

    const getRestrictedCategoryMapping = () => {
        const restrictedCategoryMapping = {};

        _.each(RESTRICTED_CATEGORY_OPTIONS, option => {
            restrictedCategoryMapping[option.value] = option;
        });

        return restrictedCategoryMapping;
    };

    const userMapping = getUsersMapping();
    const partnersMapping = getPartnersMapping();
    const salesRepsMapping = getSalesRepsMapping();
    const advertisersMapping = getAdvertisersMapping();
    const locationListsMapping = getLocationListsMapping();
    const revenueModelMapping = getRevenueModelMapping();
    const budgetAllocationMapping = getBudgetAllocationMapping();
    const showFta = getShowFta();
    const showFtaFullyManagedFields = getShowFtaFullyManagedFields();
    const restrictedCategoryMapping = getRestrictedCategoryMapping();

    const clientGrouping = _.groupBy(campaign.clients, 'type');

    const envSettings = getEnvironmentSettings();

    const showBudgetOptimizationField = envSettings.canUseAutoBudgetAllocation();

    return {
        ...state,
        ...boundActions,
        userMapping,
        partnersMapping,
        salesRepsMapping,
        advertisersMapping,
        locationListsMapping,
        revenueModelMapping,
        budgetAllocationMapping,
        showFta,
        showFtaFullyManagedFields,
        clientGrouping,
        showBudgetOptimizationField,
        restrictedCategoryMapping,
    };
}

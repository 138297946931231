import React, { Fragment } from 'react';

import {
    Section,
    Image,
    Layout,
    SubSection,
    BoldText,
    Paragraph,
    EmphasizedText,
} from '../modules/layout.js';
import Spacer from 'widgets-v5/spacer';
import { Subheading } from 'widgets-v5/typography';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';

export default {
    category: 'Guides',
    topic: 'Budget & Revenue Model',
    url: 'guides/budget-revenue-model',
    published: true,
    Component: class Article extends React.Component {
        constructor(props) {
            super(props);

            this.overview = React.createRef();
            this.managingSpend = React.createRef();
            this.managingRevenue = React.createRef();
            this.campaignAdBudgets = React.createRef();
        }

        render() {
            return (
                <Layout
                    title="Budget & Revenue Model"
                    sidebarContent={[
                        {
                            title: 'Overview',
                            link: this.overview,
                        },
                        {
                            title: 'Managing Spend',
                            link: this.managingSpend,
                        },
                        {
                            title: 'Managing Revenue',
                            link: this.managingRevenue,
                        },
                        {
                            title: 'Campaign Budget vs Ad Budget',
                            link: this.campaignAdBudgets,
                        },
                    ]}
                >
                    <Section link={this.overview} title="Overview">
                        <Subheading>Difference between Budget, Spend, and Revenue</Subheading>
                        <Image url="/images/help/spend-revenue-budget.png" />
                        <dl>
                            <dt>
                                <BoldText>Budget</BoldText>
                            </dt>
                            <dd>
                                <Paragraph>
                                    Budget is the size of your metaphorical wallet. It sets an upper
                                    limit on how much media is bought.
                                </Paragraph>
                                <Paragraph>
                                    You can budget by impressions, clicks, spend, and revenue.
                                </Paragraph>
                            </dd>
                            <dt>
                                <BoldText>Spend</BoldText>
                            </dt>
                            <dd>
                                <Paragraph>
                                    Spend is the result of bidding on an exchange. No matter how you
                                    manage your budget, there is always spend when you media is
                                    bought.
                                </Paragraph>
                                <Paragraph>
                                    EngageFront provides a number of spend-related metrics. When
                                    budgeting and pacing, Total Cost is used.
                                </Paragraph>
                            </dd>
                            <dt>
                                <BoldText>Revenue</BoldText>
                            </dt>
                            <dd>
                                When Revenue is optionally enabled, EngageFront you specify the rate
                                at which you've sold a campaign. This adds revenue metrics to
                                reports and makes margin calculation easier.
                            </dd>
                        </dl>
                    </Section>
                    <Section link={this.managingSpend} title="Managing Spend">
                        <Paragraph>
                            Spend is broken out into different metrics to help you track your
                            spending more granularly. Refer to the following diagram and table to
                            understand each component.
                        </Paragraph>
                        <Paragraph>
                            Of note, you are charged only according to the Billable Cost amount, but
                            campaign budget and pacing is based on the Total Cost amount (a holistic
                            value which includes 3rd Party Fees that are not charged by
                            EngageFront).
                        </Paragraph>
                        <Image url="/images/help/spend-model.png" />
                        <ComposableDataTable>
                            <Row header>
                                <Cell>Metric</Cell>
                                <Cell>Definition</Cell>
                            </Row>
                            <Row>
                                <Cell>Media Cost</Cell>
                                <Cell>
                                    Cost of buying the impression through EngageFront, inclusive of
                                    platform fees.
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Data Cost</Cell>
                                <Cell>
                                    Cost of audience segments, Foot Traffic Attribution, and other
                                    add-on features billed through EngageFront.
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Billable Cost</Cell>
                                <Cell>
                                    Sum of <EmphasizedText>Media Cost</EmphasizedText> and{' '}
                                    <EmphasizedText>Data Cost</EmphasizedText>. This is the total
                                    amount billed through EngageFront.
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>3rd Party Fees</Cell>
                                <Cell>
                                    Other costs involved in running the campaign to track within
                                    your budget, but <EmphasizedText>not</EmphasizedText> billed
                                    through EngageFront, e.g. DCM fees.
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Total Cost</Cell>
                                <Cell>
                                    Sum of <EmphasizedText>Billable Cost</EmphasizedText> and{' '}
                                    <EmphasizedText>Third-party Fees</EmphasizedText>. Used for
                                    overall tracking overall spend and pacing.
                                </Cell>
                            </Row>
                        </ComposableDataTable>
                    </Section>
                    <Section link={this.managingRevenue} title="Managing Revenue">
                        <Paragraph>
                            Managing revenue on EngageFront is optional. If disabled, all
                            revenue-related fields and metrics will be hidden to simplify your view.
                        </Paragraph>
                        <SubSection title="CPM / CPC">
                            <Paragraph>
                                If your revenue is based on the number of impressions or clicks sold
                                and a fixed CPM or CPC rate, pick{' '}
                                <EmphasizedText>CPM / CPC</EmphasizedText>.
                            </Paragraph>
                            <Image url="/images/help/revenue-model-cpm-cpc.png" />
                            <ComposableDataTable>
                                <Row header>
                                    <Cell>Revenue Model</Cell>
                                    <Cell>Description</Cell>
                                </Row>
                                <Row>
                                    <Cell>CPM / CPC</Cell>
                                    <Cell>
                                        <Paragraph>
                                            Revenue is calculated based on the number of impressions
                                            or clicks served, and is not connected in any way to the
                                            campaign's Spend.
                                        </Paragraph>
                                        <Paragraph>
                                            <EmphasizedText>Example</EmphasizedText>
                                            <br />
                                            CPM Rate: $8 CPM
                                            <br />
                                            Impressions: 1,000,000
                                            <br />
                                            ----------
                                            <br />
                                            Revenue: $8,000
                                            <br />
                                        </Paragraph>
                                        <Paragraph>
                                            <EmphasizedText>Example</EmphasizedText>
                                            <br />
                                            CPC Rate: $8 CPC
                                            <br />
                                            Clicks: 1,000
                                            <br />
                                            ----------
                                            <br />
                                            Revenue: $8,000
                                            <br />
                                        </Paragraph>
                                    </Cell>
                                </Row>
                            </ComposableDataTable>
                        </SubSection>
                        <SubSection title="Agency Margin and Total Cost Markup">
                            <Paragraph>
                                If your revenue is directly related to how your spend, pick{' '}
                                <EmphasizedText>Agency Margin</EmphasizedText> or{' '}
                                <EmphasizedText>Total Cost Markup</EmphasizedText>. Revenue is
                                calculated as a percentage of Spend in both models. The difference
                                is in the inputs and how the percentage is applied.
                            </Paragraph>
                            <Image url="/images/help/revenue-model-margin-markup.png" />
                            <ComposableDataTable>
                                <Row>
                                    <Cell>Total Cost Markup</Cell>
                                    <Cell>
                                        <Paragraph>
                                            This revenue model requires Total Cost target and a
                                            markup rate. Revenue is calculated by{' '}
                                            <BoldText>adding</BoldText> the markup on top of Total
                                            Spend.
                                        </Paragraph>
                                        <Paragraph>
                                            <EmphasizedText>Example</EmphasizedText>
                                            <br />
                                            Total Cost: $10,000
                                            <br />
                                            Markup Rate: 20%
                                            <br />
                                            ----------
                                            <br />
                                            Revenue: $12,000
                                            <br />
                                        </Paragraph>
                                    </Cell>
                                </Row>
                                <Row>
                                    <Cell>Agency Margin</Cell>
                                    <Cell>
                                        <Paragraph>
                                            This revenue model requires a Client Budget (expected
                                            revenue) and an agency margin. The percentage margin is{' '}
                                            <BoldText>deducted</BoldText> from Client Budget to
                                            calculate Total Cost.
                                        </Paragraph>
                                        <Paragraph>
                                            <EmphasizedText>Example</EmphasizedText>
                                            <br />
                                            Client Budget (Revenue): $10,000
                                            <br />
                                            Agency Margin: 20%
                                            <br />
                                            ----------
                                            <br />
                                            Total Cost: $8,000
                                            <br />
                                        </Paragraph>
                                    </Cell>
                                </Row>
                            </ComposableDataTable>
                        </SubSection>
                    </Section>
                    <Section link={this.campaignAdBudgets} title="Campaign Budget vs Ad Budget">
                        <Paragraph>
                            A budget is always required at the ad level and optional at the campaign
                            level. If both a campaign-level and an ad-level budget is set, the ad
                            will stop running when either budget has been met. i.e, If a campaign
                            budget is less than the sum of its ads' budgets, the campaign budget
                            will be the limiting factor.
                        </Paragraph>
                        <Paragraph>
                            The budget settings available at the campaign level and at the ad level
                            depends on the revenue model.
                        </Paragraph>
                        <Spacer type="small" />
                        <ComposableDataTable>
                            <Row header>
                                <Cell>Revenue Model</Cell>
                                <Cell>
                                    Budget settings available at
                                    <br />
                                    <BoldText>Campaign level</BoldText>
                                </Cell>
                                <Cell>
                                    Budget settings available at
                                    <br />
                                    <BoldText>Ad level</BoldText>
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>CPM / CPC</Cell>
                                <Cell>Total Cost</Cell>
                                <Cell>Impressions / Clicks</Cell>
                            </Row>
                            <Row>
                                <Cell>Agency Margin</Cell>
                                <Cell>Total Cost / Client Budget (Revenue)</Cell>
                                <Cell>Total Cost / Client Budget (Revenue)</Cell>
                            </Row>
                            <Row>
                                <Cell>Total Cost Markup</Cell>
                                <Cell>Total Cost</Cell>
                                <Cell>Total Cost</Cell>
                            </Row>
                            <Row>
                                <Cell>Disabled</Cell>
                                <Cell>Total Cost</Cell>
                                <Cell>Impressions / Clicks / Total Cost</Cell>
                            </Row>
                        </ComposableDataTable>
                    </Section>
                </Layout>
            );
        }
    },
};

import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';

import Section from 'widgets-v5/section';

import TargetCarrieries from 'forms/ad-form/modules/target-carrieries';
import { getTargetCarriers } from 'containers/ad-summary';
import shallowCompare from 'utils/shallow-compare';
import classnames from 'classnames';

export class CarrierForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Carrier Networks"
                fields={['target_carriers']}
                shouldRefreshPredictionsOnSave={false}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <div className="media-plan-form__carrier-selector">
                        <TargetCarrieries
                            label={ads ? 'Carrier Networks' : ''}
                            value={draft.target_carriers}
                            errors={errors.target_carriers}
                            showErrors={shouldShowErrors}
                            onChange={val => updateDraft({ target_carriers: val })}
                        />
                    </div>
                )}
            </BaseColumnForm>
        );
    }
}

export class CarrierSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;

        let label;
        if (ad.target_carriers.length > 0) {
            label = _.map(getTargetCarriers(ad.target_carriers).split(','), carrier => (
                <div key={carrier}>{carrier}</div>
            ));
        } else {
            label = 'All Carriers';
        }

        return <div>{error ? <div>{error}</div> : <div>{label}</div>}</div>;
    }
}

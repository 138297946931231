import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'creativeForm';
export const initialState = {};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        changeName: (state, name) => ({
            ...state,
            draft: {
                ...state.draft,
                name,
            },
        }),
    },
});

import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';

import { formatNumber_currency } from 'utils/formatting';

import Section from 'widgets-v5/section';

import { getIABCat } from 'containers/ad-summary';
import TargetIABCategories from 'forms/ad-form/modules/target-iab-categories';
import shallowCompare from 'utils/shallow-compare';
import classnames from 'classnames';

export class IABCategoryForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="App Categories"
                fields={['target_iab_categories']}
                shouldRefreshPredictionsOnSave={false}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <div className="media-plan-form__app-category-selector">
                        <TargetIABCategories
                            label={ads ? 'IAB App Categories' : ''}
                            errors={errors.target_iab_categories}
                            showErrors={shouldShowErrors}
                            checkedCategories={draft.target_iab_categories}
                            onChange={val => updateDraft({ target_iab_categories: val })}
                        />
                    </div>
                )}
            </BaseColumnForm>
        );
    }
}

export class IABCategorySummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;

        let label;
        if (ad.target_iab_categories.length > 0) {
            label = _.map(getIABCat(ad.target_iab_categories).split(','), category => (
                <div key={category}>{category}</div>
            ));
        } else {
            label = 'All Categories';
        }

        return <div>{error ? <div>{error}</div> : <div>{label}</div>}</div>;
    }
}

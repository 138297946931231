import _ from 'lodash';

import validate, {
    isOptional,
    isRequired,
    isString,
    isArray,
    isNumber,
    isDatetimeISOString,
    isLikeNumber,
    isLikeNumberComma,
} from 'utils/validate';

// ----------------
// Draft Validators
// ----------------
export const draftValidators_forPayLoad = {
    first_name: [
        isRequired(),
        {
            ...isString(1),
            message: 'First name is required.',
        },
    ],

    last_name: [
        isRequired(),
        {
            ...isString(1),
            message: 'Last name is required.',
        },
    ],

    password: [
        isRequired(),
        {
            ...isString(1),
            message: 'Password is required',
        },
    ],

    confirmPassword: [
        isRequired(),
        {
            ...isString(1),
            message: 'Password confirmation is required',
        },
    ],
};

export const draftValidators_notForPayload = {
    passwordsMatches: [
        {
            validate(value, source) {
                const password = source.password;
                const confirmPassword = source.confirmPassword;

                const notBothIsEmpty = !(password !== '' && confirmPassword !== '');
                const isSame = password === confirmPassword;

                const isMatch = notBothIsEmpty || isSame;
                return isMatch;
            },
            message: 'Passwords must match',
        },
    ],
};

// --------------
// Default values
// --------------
export function getDefaults(type) {
    const defaults = {};
    defaults.payload_formData = {
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        confirmPassword: '',
    };
    defaults.payload_nonformData = {
        activation_token: void 0,
    };
    defaults.groupValidate = {
        passwordsMatches: false,
    };

    return { ...defaults[type] };
}

// ----------------------------
// Validators calling end point
// ----------------------------
export function validateDraft_activate(draft) {
    const _draft = {
        ...draft,
        ...getDefaults('groupValidate'),
    };
    const draftValidators = { ...draftValidators_forPayLoad, ...draftValidators_notForPayload };
    const validators = _.pick(draftValidators, Object.keys(_draft));
    const validateResult = validate(_draft, validators);
    return validateResult;
}

// ---------------------
// Serialization for api
// ---------------------
export function serializeForApi(payload_beforeSerialized) {
    const serializedPayload = {
        ...payload_beforeSerialized,
    };
    return serializedPayload;
}

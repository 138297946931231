import { makeActions } from 'utils/redux-tools';

export * from './middlewares';
export * from './hook';
export const REDUCER_KEY = 'reportJobs';
export const initialState = {
    reportJobs: [],
    shouldSendViaEmail: true,
    showExportErrors: false,
    isWindowExpanded: false,
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        cancelReport: state => state,
        downloadReport: state => state,
        retryReport: state => state,
        exportReport: state => ({
            ...state,
            showExportErrors: true,
            isWindowExpanded: true,
        }),
        exportReportSuccess: state => state,
        loadReportJobs: state => state,
        loadReportJobsSuccess: (state, reportJobs) => ({
            ...state,
            reportJobs,
        }),
        toggleSendReportMethod: state => ({
            ...state,
            shouldSendViaEmail: !state.shouldSendViaEmail,
        }),
        expandWindow: state => ({ ...state, isWindowExpanded: true }),
        collapseWindow: state => ({ ...state, isWindowExpanded: false }),
    },
});

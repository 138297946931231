import _ from 'lodash';
import notify from 'utils/notify';
import { createHttp } from 'utils/http';
import UserActions from 'states/resources/users/actions';

const actions = {
    open(userId) {
        return dispatch => {
            dispatch({
                type: 'DELETE_USER_MODAL__OPEN_MODAL',
                payload: { userId },
            });
        };
    },
    close() {
        return dispatch => {
            dispatch({
                type: 'DELETE_USER_MODAL__CLOSE_MODAL',
            });
        };
    },
    delete(refreshUsers) {
        return async (dispatch, getState) => {
            const userId = _.get(getState(), 'deleteUserModal.userId');
            dispatch({
                type: 'DELETE_USER_MODAL__DELETE_START',
            });
            try {
                await dispatch(UserActions.delete(userId));
                dispatch({
                    type: 'DELETE_USER_MODAL__DELETE_SUCCESS',
                });
                dispatch(actions.close());
                refreshUsers();
            } catch (error) {
                dispatch({
                    type: 'DELETE_USER_MODAL__DELETE_FAIL',
                });
                notify({ error, metaData: userId });
            }
        };
    },
};

export default actions;

import _ from 'lodash';

const beaconOptions = [
    { value: 'page_loaded', label: 'page_loaded' },
    { value: 'external_link_info', label: 'external_link_info' },
    { value: 'external_link_shop', label: 'external_link_shop' },
    { value: 'external_link_locations', label: 'external_link_locations' },
    { value: 'external_link_video', label: 'external_link_video' },
    { value: 'external_link_map', label: 'external_link_map' },
    { value: 'external_link_call', label: 'external_link_call' },
    { value: 'video_start', label: 'video_start' },
    { value: 'video_progress_25', label: 'video_progress_25' },
    { value: 'video_progress_50', label: 'video_progress_50' },
    { value: 'video_progress_75', label: 'video_progress_75' },
    { value: 'video_complete', label: 'video_complete' },
    { value: 'video_incomplete', label: 'video_incomplete' },
    { value: 'video_clickthrough', label: 'video_clickthrough' },
    { value: 'game_start', label: 'game_start' },
    { value: 'game_progress', label: 'game_progress' },
    { value: 'game_score', label: 'game_score' },
    { value: 'game_complete', label: 'game_complete' },
    { value: 'social_media_facebook', label: 'social_media_facebook' },
    { value: 'social_media_twitter', label: 'social_media_twitter' },
    { value: 'social_media_youtube', label: 'social_media_youtube' },
    { value: 'social_media_instagram', label: 'social_media_instagram' },
    { value: 'social_media_pinterest', label: 'social_media_pinterest' },
    { value: 'social_media_google_plus', label: 'social_media_google_plus' },
    { value: 'share_media_facebook', label: 'share_media_facebook' },
    { value: 'share_media_twitter', label: 'share_media_twitter' },
    { value: 'share_media_youtube', label: 'share_media_youtube' },
    { value: 'share_media_instagram', label: 'share_media_instagram' },
    { value: 'share_media_pinterest', label: 'share_media_pinterest' },
    { value: 'share_media_google_plus', label: 'share_media_google_plus' },
    { value: 'subscribe_facebook', label: 'subscribe_facebook' },
    { value: 'subscribe_twitter', label: 'subscribe_twitter' },
    { value: 'subscribe_youtube', label: 'subscribe_youtube' },
    { value: 'subscribe_instagram', label: 'subscribe_instagram' },
    { value: 'subscribe_pinterest', label: 'subscribe_pinterest' },
    { value: 'subscribe_google_plus', label: 'subscribe_google_plus' },
    { value: 'subscribe_email', label: 'subscribe_email' },
    { value: 'app_download', label: 'app_download' },
    { value: 'app_open', label: 'app_open' },
    { value: 'gallery_view', label: 'gallery_view' },
    { value: 'permission_geolocation', label: 'permission_geolocation' },
];

export default function selector(storeState, props) {
    const draft = _.get(storeState, `beaconsOverview.draft`, []);
    const isLoading = _.get(storeState, `beaconsOverview.isLoading`, false);
    const campaign = _.get(storeState, `beaconsOverview.campaign`);

    const beaconsCount = draft.length;

    const usedEvents = _.map(draft, 'name');
    const filteredBeaconsOptions = beaconOptions.filter(option => {
        return !_.includes(usedEvents, option.value);
    });

    const primaryActionType = draft.length > 0 ? 'manage' : 'new';
    const errors = _.get(storeState, `beaconsOverview.errors`, []);
    const showErrors = _.get(storeState, `beaconsOverview.showErrors`, false);
    const mode = _.get(storeState, `beaconsOverview.mode`, 'view');

    return {
        draft,
        errors,
        showErrors,
        mode,
        primaryActionType,
        isLoading,
        beaconsCount,
        availableBeaconOptions: filteredBeaconsOptions,
        rawBeaconOptions: beaconOptions,
        campaign,
    };
}

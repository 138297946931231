import PropTypes from 'prop-types';
import React from 'react';
import { Link as LinkRouter } from 'react-router';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const { node, string, bool, func, shape, arrayOf, oneOf } = PropTypes;

const AdapterLink = React.forwardRef((props, ref) => <LinkRouter innerRef={ref} {...props} />);
export default class extends React.Component {
    static displayName = 'Tabs';

    static propTypes = {
        className: string,
        activeItem: string,
        items: arrayOf(
            shape({
                label: node.isRequired,
                value: string.isRequired,
                link: string,
                hidden: bool,
                onClick: func,
            })
        ).isRequired,
        onChange: func,
        disabled: bool,
        align: oneOf(['left', 'center', 'justify']),
    };

    static defaultProps = {
        align: 'justify',
        disabled: false,
    };

    render() {
        const { onChange, disabled } = this.props;
        const _onChange = disabled ? _.noop : onChange;

        return (
            <Box borderBottom={1} color="#E0E0E0" sx={{ zIndex: 1 }}>
                <Tabs
                    value={this.props.activeItem}
                    onChange={(e, value) => _onChange && _onChange(value)}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {_(this.props.items)
                        .filter(tab => !tab.hidden)
                        .map(tab => (
                            <Tab
                                classes={{ root: tab.className || '' }}
                                key={tab.value}
                                value={tab.value}
                                label={tab.label}
                                to={tab.link}
                                component={AdapterLink}
                                disabled={!!disabled}
                            >
                                {tab.label}
                            </Tab>
                        ))
                        .value()}
                </Tabs>
            </Box>
        );
    }
}


import React from 'react';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import selector from './selector';
import { HistoryTableV3 } from 'widgets/history-table';
import { fetchAdHistory } from 'pages/campaigns/ads/ad-history/actions';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { closeAdHistory } from './actions';

const AdHistory = createReactClass({
    displayName: 'AdHistory',

    statics: {
        refresh(dispatch, nextState) {
            const { adId, campaignId } = nextState.params;

            dispatch(fetchAdHistory(adId, campaignId));
        },
    },

    componentDidMount() {
        const { dispatch, location, params, routes } = this.props;
        const nextState = { location, params, routes };

        AdHistory.refresh(dispatch, nextState, browserHistory.push);
    },

    handleClose() {
        const { dispatch, params: { campaignId } } = this.props;
        browserHistory.push(`/campaigns/${campaignId}/setup/ads`);
        dispatch(closeAdHistory())
    },

    render() {
        const {
            isLoading,
            adName,
            historyEntries,
            organizationTimezone,
            organizationId,
            fields,
        } = this.props;

        return (
            <Dialog fullWidth maxWidth='lg' open={true} onClose={this.handleClose}>
                <DialogContent>
                    <HistoryTableV3
                        title="Ad History"
                        secondaryTitle={adName}
                        historyEntries={historyEntries}
                        fields={fields}
                        organizationTimezone={organizationTimezone}
                        organizationId={organizationId}
                        isLoading={isLoading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={this.handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
});

export default connect(selector)(AdHistory);

import _ from 'lodash';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { browserHistory } from 'react-router';
import $ from 'jquery';
import toastr from 'toastr';
import VError from 'verror';
import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { firebaseRemoteConfig } from './services/firebase';
import flags from 'containers/flags/service';

import unhandledRejection from 'unhandled-rejection';

import { storageSelector } from 'utils/browser-storage';

import config from '../config';
import store from 'core/store';

import additionalTimezones from 'common/constants/customTimezones';
import moment from 'moment';
import 'moment-timezone';
import VersionPoller from 'services/version-poller';
import Tracking from 'services/tracking';
import GlobalMessagePoller from 'services/global-message-poller';

import { AppContainer } from 'react-hot-loader';
import './services/beamer';
import 'styles/app.less';

import { teal, grey, pink, amber } from '@mui/material/colors';

const rejectionEmitter = unhandledRejection();

if (config.ENFORCE_HTTPS && window.location.protocol !== 'https:') {
    const redirect = window.location.href.replace('http://', 'https://');
    window.location = redirect;
}

// for xml request enabling
$.support.cors = true;

// Start Google Analytics tracking
Tracking.init();

// Discussable
moment.tz.link(
    _.map(additionalTimezones, arr => {
        return arr.join('|');
    })
);

toastr.options = {
    tapToDismiss: false,
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-bottom-left',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
};

// Detect adblock
function adBlockDetected() {
    toastr.warning(
        '<p>Please disable it otherwise some features of the site may not work correctly</p>',
        'You may have an Ad Blocker enabled.',
        {
            tapToDismiss: false,
            showDuration: 1000,
            showMethod: 'slideDown',
            timeOut: 0,
            extendedTimeOut: 0,
            positionClass: 'toast-bottom-left',
        }
    );
}

window.goToUrl = function(url) {
    browserHistory.push(url);
};

window.__config = config;
if (config.BUGSNAG_ENABLED) {
    var Bugsnag = require('bugsnag-js');

    // Initialize Bugsnag
    window.bugsnagClient = Bugsnag({
        apiKey: config.API_KEYS.BUGSNAG,
        releaseStage: config.ENV_NAME,
        appVersion: process.env.VERSION,
        notifyReleaseStages: ['demo', 'spain', 'production'],
        beforeSend: function(report) {
            const state = store.getState();
            const profile = _.get(state, 'profile', {});
            const impersonator = _.get(profile, `authToken.v3_impersonator`, null);

            let user;

            if (impersonator) {
                user = _.get(state, `resources.users.${impersonator.user}.attr`, {});
            } else if (profile.userId) {
                user = _.get(state, `resources.users.${profile.userId}.attr`, {});
            }

            if (user) {
                report.user = {
                    id: user.id,
                    ipAddress: user.client_Ip,
                    email: user.email,
                    name: `${user.first_name} ${user.last_name}`,
                    organization: user.organization,
                    authToken: profile.authToken,
                };
            }
        },
    });

    rejectionEmitter.on('unhandledRejection', error => {
        console.error('Unhandled promise error', error);

        error instanceof Error
            ? bugsnagClient.notify(error, {
                  metadata: {
                      originalStackTrace:
                          (error instanceof Error ? VError.fullStack(error) : error) ||
                          'unsupported in browser',
                      info: error instanceof Error ? VError.info(error) : {},
                  },
              })
            : bugsnagClient.notify(
                  {
                      name: 'Unhandled promise rejection',
                      message: 'A promise was rejected without being handled',
                  },
                  {
                      metaData: {
                          rejectedError: JSON.stringify(error),
                          name: error.name,
                          message: error.message,
                      },
                  }
              );
    });
} else {
    rejectionEmitter.on('unhandledRejection', error => {
        if (error instanceof Error) {
            console.error('Unhandled promise error', VError.fullStack(error));
            console.error('info', VError.info(error));
        } else {
            console.error('Unhandled promise error', error);
        }
    });

    // Console log info table when page enter
    const formattedTable = _.mapValues(config, value => ({ value }));

    if (!console.table) {
        window.console.table = window.console.log;
    }

    console.table(formattedTable);
}

//load SENTRY
if (undefined !== config.SENTRY) {
    var Sentry = require('@sentry/react');
    var BrowserTracing = require('@sentry/tracing').BrowserTracing;
    Sentry.init({
        dsn: config.SENTRY.dsn,
        integrations: [
            new BrowserTracing({
                tracePropagationTargets: [],
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: config.SENTRY.tracesSampleRate,
    });
}
// Fetch the advertisement.js file after a delay because of an adblock bug which
// doesn't detect the whitelist properly on load
setTimeout(() => {
    $.get('/advertisement.js').fail(() => adBlockDetected());
}, 5000);

window.onbeforeunload = function() {
    const isImpersonating = _.get(store.getState(), 'profile.isImpersonating');

    const browserStorage = storageSelector(isImpersonating);

    const storeState = store.getState();
    const mediaPlans = _.get(storeState, 'resources.mediaPlans', {});
    const selectedCampaigns = _.get(storeState, 'sidebar.selectedCampaigns', {});
    const selectedMediaPlans = _.get(storeState, 'sidebar.mediaPlans', {});
    const businessReportTabs = _.get(storeState, 'sidebar.businessReports', []);

    // Remove unpinned campaign tabs from localstorage
    // const selectedCampaigns_prv = JSON.parse(localStorage.getItem('SIDEBAR_TABS'));
    const selectedCampaigns_next = _(selectedCampaigns).reduce((acc, val, key) => {
        if (val.isPinned === true) {
            acc[key] = val;
        }
        return acc;
    }, {});
    const sidebarTabs = JSON.stringify(selectedCampaigns_next);

    browserStorage.setItem('RESOURCE_MEDIA_PLANS', JSON.stringify(mediaPlans));
    browserStorage.setItem('SIDEBAR_MEDIA_PLAN_TABS', JSON.stringify(selectedMediaPlans));
    browserStorage.setItem('SIDEBAR_BUSINESS_REPORT_TABS', JSON.stringify(businessReportTabs));
    browserStorage.setItem('SIDEBAR_TABS', sidebarTabs);

    localStorage.removeItem('ENGAGE_FRONT_PIXELS_METADATA');

    console.log('Saving data to local storage');
};

// Poll for changes to the global message
GlobalMessagePoller.start();

// Poll for changes to application version
VersionPoller.start();

const theme = {
    palette: {
        primary: {
            light: '#C1EDE9',
            main: '#0eafa0',
            dark: teal[500],
            contrastText: '#fff',
        },
        secondary: {
            main: pink['A400'],
        },
        warning: {
            main: amber[300],
            secondary: '#E65100',
            contrastText: '#fff',
            dark: amber[500],
        },
    },
    tooltip: {
        fontSize: '11px',
    },
    typography: {
        fontFamily: 'Poppins',
        htmlFontSize: 18,
        h5: {
            fontWeight: 500,
        },
        subtitle2: {
            fontWeight: 'normal',
            color: grey[600],
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 14,
        },
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        MuiPaper: {
            elevation1: {
                boxShadow:
                    '0px 2px 1px -1px rgb(0 0 0 / 6%), 0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 7%)',
            },
        },
        MuiButton: {
            root: {
                borderRadius: 5,
            },
            sizeMedium: {
                fontSize: 14,
            },
            sizeSmall: {
                fontSize: 13,
            },
        },
        MuiTableCell: {
            root: {
                padding: 10,
            },
            head: {
                color: grey[600],
                fontWeight: 400,
            },
            stickyHeader: {
                backgroundColor: 'white',
            },
            body: {
                fontSize: 12.5,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 5,
            },
            input: {
                paddingTop: 13,
                paddingBottom: 13,
            },
        },
        MuiContainer: {
            root: {
                paddingTop: 40,
                paddingBottom: 40,
            },
        },
        MuiTab: {
            root: {
                fontSize: 12,
                fontWeight: 'normal',
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: '#FF6584',
            },
        },
        MuiAccordionSummary: {
            root: {
                '&.Mui-focusVisible': {
                    backgroundColor: 'transparent !important',
                },
                '&.Mui-focused': {
                    backgroundColor: 'transparent !important',
                },
                '&.MuiButtonBase-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important',
                    },
                },
            },
        },
    },
    props: {
        MuiRadio: {
            color: 'primary',
        },
    },
};

import App from './core/app';

// We first need to fetch and activate the flags from firebase. Only
// after that do we actually initialize the app itself.
const getAndPopulateFlags = () => {
    const fetchFlags = firebaseRemoteConfig.getAll();
    const parsedFlags = Object.keys(fetchFlags).reduce((acc, flag) => {
        acc.push(JSON.parse(fetchFlags[flag]._value));
        return acc;
    }, []);

    flags.populate(parsedFlags);
};

// Invalidate local cache every hour by default so that the user would have the
// latest flags available when fetching.
firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60;

// Uncomment the below line to quickly refresh the remote config parameters.
// firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 100;

firebaseRemoteConfig
    .fetchAndActivate()
    .then(getAndPopulateFlags)
    .catch(error => {
        console.log('Error fetching flags:', error);

        // Activate the last fetched flags
        firebaseRemoteConfig.activate().then(getAndPopulateFlags);
    })
    .finally(() => {
        const render = Component => {
            const container = document.getElementById('root');
            const root = createRoot(container);
            root.render(
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={responsiveFontSizes(createTheme(adaptV4Theme(theme)))}>
                        <AppContainer>
                            <Component />
                        </AppContainer>
                    </ThemeProvider>
                </StyledEngineProvider>
            );
        };

        render(App);
        // Hot Module Replacement API
        if (module.hot) {
            module.hot.accept('./core/app', () => {
                const NextApp = require('./core/app').default;
                render(NextApp);
            });
        }
    });

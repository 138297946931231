import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { push } from 'react-router-redux';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import { GlobalModal } from 'widgets-v5/modal';
import { NeutralButton } from 'widgets-v5/buttons';

import flags from 'containers/flags/service';

import POISearch from './modules/poi-search';
import LayoutV2 from './layoutV2';
import selector from './selector';
import actions from './actions';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import DownloadTextContents from 'widgets-v5/download-text-contents';
import { SecondaryButton } from 'widgets-v5/buttons';

import config from '../../../../../config';

class PoiEditorWrapper extends React.Component {
    render() {
        return <PoiEditor {...this.props} />;
    }
}

class PoiEditor extends React.Component {
    state = {
        isConfirmingUpload: false,
        isSavingNewPOISegments: false,
        error: null,
    };

    componentDidMount() {
        const { poiId } = this.props.params;
        const { type } = this.props;
        this.props.dispatch(actions.initialize(poiId, type));
    }

    updateLayer = (field, value) => {
        this.props.dispatch(actions.updateLayer(field, value));
    };

    showInvalidFileError = files => {
        this.props.dispatch(actions.setFileTypeError(files));
    };

    onFileChange = formData => {
        // if points exist, show modal to pick overwrite or append
        if (this.props.poisEditorState.draft.points.length > 0) {
            this.setState({ isConfirmingUpload: true, formData });
            return;
        }

        this.props.dispatch(actions.processUploadedPoints(formData));
    };

    overwritePOIs = () => {
        this.setState({ isConfirmingUpload: false });
        this.props.dispatch(actions.processUploadedPoints(this.state.formData));
    };

    appendPOIs = () => {
        this.setState({ isConfirmingUpload: false });
        this.props.dispatch(actions.processUploadedPoints(this.state.formData, { append: true }));
    };

    goBack = () => {
        this.props.onClose();
    };

    save = () => {
        this.setState({ isSavingNewPOISegments: true }, () => {
            this.props
                .dispatch(actions.save())
                .then(() => {
                    this.props.onSave();
                })
                .catch(error => {
                    this.setState({ isSavingNewPOISegments: false });
                });
        });
    };

    changeTab = tab => {
        this.props.dispatch(
            actions.updateDraft({
                source: tab,
            })
        );
    };

    changeFlexGeoLocationAudienceType = tab => {
        this.props.dispatch(
            actions.updateDraft({
                audience_type: tab,
            })
        );
    };

    handleCreateLayer = entries => {
        this.props.dispatch(
            actions.updateDraft({
                cleanlist_entries: [
                    ...this.props.poisEditorState.draft.cleanlist_entries,
                    ...entries,
                ],
            })
        );
    };

    handleRemoveFromLayer = entry => {
        const cleanlist_entries = _.filter(
            this.props.poisEditorState.draft.cleanlist_entries,
            _entry => _entry !== entry
        );

        this.props.dispatch(
            actions.updateDraft({
                cleanlist_entries,
            })
        );
    };

    removePoint = point => {
        this.props.dispatch(actions.removePoint(point));
    };

    getExistingDataFileName = () => {
        return this.props.poisEditorState.draft.layerMetadata.name || 'pois';
    };

    getExistingDataFileContents = () => {
        if (this.props.poisEditorState.draft.points.length === 0) {
            return '';
        }

        const headers = 'latitude,longitude,address,city,postalCode,province';
        const pois = _.map(this.props.poisEditorState.draft.points, point => {
            return `${point.latitude},${point.longitude},"${point.address}","${point.city}","${
                point.postalCode
            }","${point.region}"`;
        });

        return [].concat(headers, pois).join('\r\n');
    };

    render() {
        let metadataErrors = {};
        _.forEach(
            this.props.poisEditorState.metadataErrors,
            error => (metadataErrors[error.field] = [error.message])
        );

        return (
            <BlockLoadGroup isLoading={this.state.isSavingNewPOISegments}>
                <LayoutV2
                    changeTab={this.changeTab}
                    changeFlexGeoLocationAudienceType={this.changeFlexGeoLocationAudienceType}
                    updateLayer={this.updateLayer}
                    onFileChange={this.onFileChange}
                    removePoint={this.removePoint}
                    downloadExisting={this.downloadExisting}
                    goBack={this.goBack}
                    save={this.save}
                    draft={this.props.poisEditorState.draft}
                    fileName={this.props.poisEditorState.fileName}
                    poisProcessed={this.props.poisEditorState.draft.points.length}
                    poiErrors={this.props.poisEditorState.poiErrors}
                    poiStatus={this.props.poiActionsState.layers.undefined?.attr.status}
                    cleanlistErrors={this.props.poisEditorState.cleanlistErrors}
                    metadataErrors={metadataErrors}
                    serverSaveErrors={this.props.poisEditorState.serverSaveErrors}
                    isEditing={this.props.poisEditorState.isEditing}
                    isInitializing={this.props.poisEditorState.isInitializing}
                    isInterpolatingPoints={this.props.poisEditorState.isInterpolatingPoints}
                    poisProcessing={this.props.poisEditorState.poisProcessing}
                    showFileErrors={this.props.poisEditorState.showFileErrors}
                    failedAssets={this.props.poisEditorState.failedAssets}
                    showInvalidFileError={this.showInvalidFileError}
                    showErrors={this.props.poisEditorState.showErrors}
                    ownOrgId={this.props.ownOrgId}
                    geoResources={this.props.geoResources}
                    csvErrors={this.props.poisEditorState.csvErrors}
                    hasDuplicates={this.props.poisEditorState.hasDuplicates}
                    downloadExistingDataButton={
                        <DownloadTextContents
                            contentType="text/csv"
                            filename={this.getExistingDataFileName()}
                            contents={this.getExistingDataFileContents()}
                        >
                            {onDownload => (
                                <SecondaryButton
                                    icon={<i className="fa-download fa" />}
                                    text="Existing Data (CSV)"
                                    onClick={onDownload}
                                />
                            )}
                        </DownloadTextContents>
                    }
                    poiSearch={
                        <POISearch
                            draft={this.props.poisEditorState.draft}
                            onCreateLater={this.handleCreateLayer}
                            onRemoveFromLayer={this.handleRemoveFromLayer}
                        />
                    }
                    uploadConfirmationModal={
                        <Dialog
                            open={this.state.isConfirmingUpload}
                            onClose={() => this.setState({ isConfirmingUpload: false })}
                        >
                            <DialogTitle>Do you want to overwrite POIs?</DialogTitle>
                            <DialogContent>
                                <div>
                                    <NeutralButton
                                        onClick={this.overwritePOIs}
                                        text="Yes. Overwrite all POIs."
                                    />
                                </div>
                                <div>
                                    <NeutralButton
                                        onClick={this.appendPOIs}
                                        text="No. Keep existing and add POIs."
                                    />
                                </div>
                            </DialogContent>
                        </Dialog>
                    }
                />
            </BlockLoadGroup>
        );
    }
}

export default connect(selector)(PoiEditorWrapper);

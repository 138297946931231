import _ from 'lodash';
import c from 'common/constants/flux-events';

export const NAME = 'resources.history.ads';
export default function ads(state = {}, action) {
    const adId = _.get(action, 'payload.adId');

    if (!adId) {
        return state;
    }

    return {
        ...state,
        [adId]: reducer(state[adId], action),
    };
}

const initialState = [];

function reducer(state = initialState, action) {
    const adId = _.get(action, 'payload.adId');

    switch (action.type) {
        case c.SYSTEM__AD_HISTORY__FETCH__SUCCESS:
            return _.get(action, 'payload.history', []);

        default:
            return state;
    }
}

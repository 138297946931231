import flags from 'containers/flags/service';

export const uploadOriginTypes = [
    {
        label: 'Kochava',
        value: 'kochava',
    },
    {
        label: 'Proprietary / Client-provided',
        value: 'client',
    },
    {
        label: 'Other provider not listed',
        value: 'other',
    },
];

export const defaultValues = {
    name: '',
    source_type: 'campaign',
    source_id: [],
    mode: 'retargeting',
    retargeting_type: 'impression',
    event_name: undefined,
    event_name_list: [],
    description: undefined,
    formData: undefined,
    upload_origin: undefined,
    supports: ['inapp'],
    third_party_fees: 0,
};

export const defaultsByMode = {
    upload: {},
};

import _ from 'lodash';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const useStyles = makeStyles({
    menu: {
        top: 450,
    },
    columns: {
        height: 300,
        overflow: 'auto',
    },
    rightSide: {
        display: 'flex',
        alignItems: 'center',
    },
    dropdownLabel: {
        padding: 10,
    },
    tableHeader: {
        whiteSpace: 'nowrap',
    },
    summary: {
        maxWidth: 400,
        maxHeight: 400,
    },
    paper: {
        margin: '0 20px',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableWrapper: {
        width: '100%',
        overflow: 'auto',
    },
});

export const TotalRow = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.action.hover,
    },
}))(TableRow);

export function TableWrapper(props) {
    const classes = useStyles();

    return (
        <Paper>
            <AppBar position="static" color="inherit" elevation={0}>
                <Toolbar classes={{ root: classes.toolbar }}>
                    <Typography variant="h6">{props.title}</Typography>
                    {props.actions}
                </Toolbar>
            </AppBar>
            <div className={classes.tableWrapper}>{props.children}</div>
        </Paper>
    );
}

export function ColumnsDropdown(props) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = !!anchorEl;

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const columnsButtonIcon = open ? <ExpandLessIcon /> : <ExpandMoreIcon />;

    return (
        <div style={{ position: 'relative' }}>
            <Button size="small" endIcon={columnsButtonIcon} onClick={openMenu}>
                Columns
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="long-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.menu }}
            >
                <div className={classes.dropdownLabel}>Show Columns</div>
                <div className={classes.columns}>
                    {_.map(props.columns, column => (
                        <MenuItem key={column.name} onClick={() => props.onClick(column.name)}>
                            <Checkbox
                                checked={!props.hiddenColumns[column.name]}
                                value="checkedA"
                                color="primary"
                            />
                            {column.label({ campaignCurrencyType: props.campaignCurrencyType })}
                        </MenuItem>
                    ))}
                </div>
            </Menu>
        </div>
    );
}

import { isString } from 'utils/validate';

const ASCII_ONLY = /^[ -~]+$/;

//-- validation rules --//
export const draftValidators_forPayload = {
    name(value) {
        if (!isString(1).validate(value)) {
            return 'Name is required';
        }

        if (!ASCII_ONLY.test(value)) {
            return 'Only ASCII characters are allowed';
        }
    },
    points(value, source) {
        const name = source.name;
        const points = source.points;
        if (name !== '___implicit' && points.length === 0) {
            return 'You must select at least one point of interest on the map';
        }
    },
};

export const draftValidators_notForPayload = {};

import React from 'react';
import _ from 'lodash';

import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';

import { MultiSelect, SingleSelect } from 'widgets-v6/select';
import NewVendorForm from './new-vendor-form';
import { CREATIVES_VENDOR_TAGS, TRACKING_VENDOR_TAGS } from 'common/constants/vendors';
import { mergeVendorOptions } from '../../index';

export function CreativeVendorSelector(props) {
    const {
        showErrors,
        errors,
        draft,
        onFieldChange,
        updateVendors,
        creativeType,
        creativeTagsMatched,
        required = true,
    } = props;

    const finalOptions = mergeVendorOptions({
        creativeType: creativeType,
        vendorsFromDraft: draft.creativeVendorsOptions,
        tagsMatched: creativeTagsMatched,
        vendorType: CREATIVES_VENDOR_TAGS,
    });

    return (
        <React.Fragment>
            <Box mt={1}>
                <Grid container alignItems="center">
                    <Grid item xs={9}>
                        <SingleSelect
                            disabled={false}
                            required={required}
                            error={showErrors && errors.creative_vendors}
                            label={'Creative vendor'}
                            value={draft.creative_vendors[0]}
                            onChange={value => {
                                const creativeVendor = [value];
                                updateVendors(creativeVendor, 'creative_vendors');
                            }}
                            placeholder="Choose your vendors"
                            options={finalOptions}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NewVendorForm
                            vendorType={'creative'}
                            onFieldChange={onFieldChange}
                            draft={draft}
                        />
                    </Grid>
                </Grid>
            </Box>
            <FormHelperText>{showErrors && errors.creative_vendors}</FormHelperText>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={draft.isCreativeVendorAutoDetected}
                        onChange={() =>
                            onFieldChange('isCreativeVendorAutoDetected')(
                                !draft.isCreativeVendorAutoDetected
                            )
                        }
                        value={draft.isCreativeVendorAutoDetected}
                        color="primary"
                    />
                }
                label="Automatically detect creative vendor"
            />
        </React.Fragment>
    );
}

export function TrackingVendorsSelector(props) {
    const {
        showErrors,
        errors,
        draft,
        onFieldChange,
        updateVendors,
        creativeType,
        trackingTagsMatched,
    } = props;

    const finalOptions = mergeVendorOptions({
        creativeType: creativeType,
        vendorsFromDraft: draft.trackingVendorsOptions,
        tagsMatched: trackingTagsMatched,
        vendorType: TRACKING_VENDOR_TAGS,
    });

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center">
                <Box width="100%">
                    <MultiSelect
                        label={'Tracking vendors'}
                        disabled={false}
                        searchable={true}
                        error={showErrors && errors.tracking_vendors}
                        value={draft.tracking_vendors}
                        onChange={value => updateVendors(value, 'tracking_vendors')}
                        placeholder="Choose your vendors"
                        options={finalOptions}
                    />
                </Box>
                <NewVendorForm
                    vendorType={'tracking'}
                    onFieldChange={onFieldChange}
                    draft={draft}
                />
            </Box>
            <FormHelperText>{showErrors && errors.tracking_vendors}</FormHelperText>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={draft.isTrackingVendorsAutoDetected}
                        onChange={() =>
                            props.onFieldChange('isTrackingVendorsAutoDetected')(
                                !draft.isTrackingVendorsAutoDetected
                            )
                        }
                        value={draft.isTrackingVendorsAutoDetected}
                        color="primary"
                    />
                }
                label="Automatically detect tracking vendors"
            />
        </React.Fragment>
    );
}

import _ from 'lodash';
import flags from 'containers/flags/service';

import { payload_formData } from './default-values';
import { getDraftValidators_forPayload } from './validation-rules';
import validate from 'utils/validate';

export function getDefaults() {
    const defaults = { ...payload_formData };
    defaults.ad_preset_properties = [
        {
            groupName: 'Targeting',
            properties: [
                {
                    name: 'Geotargeting',
                    key: 'geotargets',
                    fields: ['geotargets'],
                    checked: false,
                },
                { name: 'Geofencing', key: 'geoboxes', fields: ['geoboxes'], checked: false },
                {
                    name: 'Device OS',
                    key: 'target_device_os',
                    fields: ['target_device_os'],
                    checked: false,
                },
                {
                    name: 'Target Carrier',
                    key: 'target_carriers',
                    fields: ['target_carriers'],
                    checked: false,
                },
                {
                    name: 'User Language',
                    key: 'target_device_language',
                    fields: ['target_device_language'],
                    checked: false,
                },
                {
                    name: 'Gender',
                    key: 'target_genders',
                    fields: ['target_genders'],
                    checked: false,
                },
                {
                    name: 'Age',
                    key: 'target_age_groups',
                    fields: ['target_age_groups'],
                    checked: false,
                },
            ],
        },
        {
            groupName: 'Bid Optimization',
            properties: [
                {
                    name: 'Total Bid Price',
                    key: 'max_cpm_rate_local',
                    fields: ['max_cpm_rate_local'],
                    checked: false,
                },
            ],
        },
    ];
    return { ...defaults };
}

export function validateAdPresetDraft(draft, campaignBillingEnabled) {
    const _draft = {
        ...draft,
    };

    const draftValidators_forPayload = getDraftValidators_forPayload();

    const validators = _.pick(draftValidators_forPayload, Object.keys(_draft));
    return validate(_draft, validators);
}

// -----------------------------------------
// Data transformation before sending to api
// -----------------------------------------
import { serialize as _serialize } from './serialize-for-api';
export const serializeForApi = _serialize;

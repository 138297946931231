const creativeTypeOptions = [
    {
        value: 'banner',
        label: 'Banner',
    },
    {
        value: 'interstitial',
        label: 'Interstitial',
    },
    {
        value: 'video interstitial',
        label: 'Video Interstitial',
    },
    {
        value: 'native ad',
        label: 'Native Ad',
    },
    {
        value: 'mobile',
        label: 'Mobile',
    },
    {
        value: 'tablet',
        label: 'Tablet',
    },
    {
        value: 'medium_rectangle',
        label: 'Medium Rectangle',
    },
];

export default creativeTypeOptions;

import flags from 'containers/flags/service';
import { getFormats, getTypes, getSizes } from 'forms/creative-form/constants';

const PLATFORMS = ['inapp'];
const PLACEMENTS = ['interstitial'];

export function getTypesForForecast(exporter, platforms = PLATFORMS) {
    const types = getTypes(platforms, exporter)
        .map(type => ({
            ...type,
            formats: type.formats.filter(format => {
                if (
                    !flags.isEnabled('en_4869_inventory_predictor_v4') &&
                    format.value === 'mraid'
                ) {
                    return false;
                }
                return true;
            }),
        }))
        .filter(type => type.formats.length > 0);

    return types;
}

export function getSizesForForecast(type, platforms = PLATFORMS, placements = PLACEMENTS) {
    const sizes = getSizes(platforms, type, placements).map(({ value, label }) => ({
        value,
        label,
    }));

    return sizes;
}

export function getFormatsForForecast(type, platforms = PLATFORMS) {
    const formats = getFormats({ platforms, type })
        .filter(format => {
            if (!flags.isEnabled('en_4869_inventory_predictor_v4') && format.value === 'mraid') {
                return false;
            }
            return true;
        })
        .map(({ value, label }) => ({ value, label }));

    return formats;
}

export const VIDEO_SKIPPABLE_ICONS = {
    exclude: 'CANCEL',
    include: 'CHECK',
    none: 'NONE',
};

export const VIDEO_SKIPPABLE_OPTIONS = [
    {
        label: 'Apply no filter',
        value: '',
        icon: VIDEO_SKIPPABLE_ICONS.none,
        grayColor: true,
    },
    {
        label: 'Exclude skippable videos',
        value: 0,
        icon: VIDEO_SKIPPABLE_ICONS.exclude,
    },
    {
        label: 'Show only skippable videos',
        value: 1,
        icon: VIDEO_SKIPPABLE_ICONS.include,
    },
];

/**
 * Check if the request for ctr=1 and the estimated data should be used
 * @param string creativeFormat 
 * @returns boolean
 */
export function showEstimateData(creativeFormat) {
    return !['video', 'native'].includes(creativeFormat);
}

import React from 'react';
import withStyles from '@mui/styles/withStyles';

import FormLabel from '@mui/material/FormLabel';

const styles = theme => ({
    label: {
        marginBottom: theme.spacing(1),
    },
    header: {
        fontSize: 20,
        marginBottom: theme.spacing(1),
    },
    chip: {
        marginRight: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        padding: '5px 40px',
    },
    chipIcon: {
        fontSize: 45,
        height: 45,
        margin: 10,
    },
    icon: {
        padding: '15px 0px',
        margin: 0,
    },
});

const Label = withStyles(styles)(props => {
    const { children, classes, header } = props;

    return (
        <FormLabel
            className={header ? classes.header : classes.label}
            component="legend"
            focused={false}
            required={props.required}
        >
            {children}
        </FormLabel>
    );
});

export default Label;

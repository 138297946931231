import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'utils/shallow-compare';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';

import BaseColumnForm from '../base-column-form';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';

import { getApplists } from 'containers/ad-summary';
import IncludeExclude from 'widgets-v5/include-exclude';

import { ApplistSelector, ApplistSummary } from 'forms/ad-form/modules/target-applists';
import flags from 'containers/flags/service';

import selector from './selector';

class AppsAndSitesSummaryComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, applists } = this.props;

        return (
            <div>
                <ApplistSummary
                    allowedList={getApplists(ad.whitelistedAppsAndSites, applists)}
                    blockedList={getApplists(ad.blacklistedAppsAndSites, applists)}
                />
            </div>
        );
    }
}
export const AppsAndSitesSummary = connect(selector)(AppsAndSitesSummaryComponent);

const AppsAndSitesFormContainer = props => {
    const { ad, ads, campaign, onSave, applists } = props;
    const handleChange = ({ exclude, values }, updateDraft) => {
        const changes = {
            mode: exclude ? 'blacklist' : 'whitelist',
            listIds: values,
        };
        updateDraft({ applistSettings: changes });
    };

    const options = _.map(applists, applist => {
        return {
            value: applist.id,
            label: applist.name,
        };
    });

    const fields = ['whitelistedAppsAndSites', 'blacklistedAppsAndSites'];

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Apps & Sites"
            fields={fields}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
            columnName="Apps & Sites"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <div>
                        <FormControl fullWidth margin="normal">
                            <ApplistSelector
                                type="Include"
                                label="Allowed"
                                placeholder="All Apps & Sites"
                                onChange={values =>
                                    updateDraft({ whitelistedAppsAndSites: values })
                                }
                                values={draft.whitelistedAppsAndSites}
                                options={_(applists)
                                    .filter(
                                        applist =>
                                            !_.includes(draft.blacklistedAppsAndSites, applist.id)
                                    )
                                    .map(applist => ({
                                        value: applist.id,
                                        label: applist.name,
                                    }))
                                    .value()}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <ApplistSelector
                                placeholder="None"
                                label="Blocked"
                                onChange={values =>
                                    updateDraft({ blacklistedAppsAndSites: values })
                                }
                                values={draft.blacklistedAppsAndSites}
                                options={_(applists)
                                    .filter(
                                        applist =>
                                            !_.includes(draft.whitelistedAppsAndSites, applist.id)
                                    )
                                    .map(applist => ({
                                        value: applist.id,
                                        label: applist.name,
                                    }))
                                    .value()}
                            />
                        </FormControl>
                    </div>
                );
            }}
        </BaseColumnForm>
    );
};

export const AppsAndSitesForm = connect(selector)(AppsAndSitesFormContainer);

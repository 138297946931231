import config from '../../config';
import toastr from 'toastr';
import { firebase, collection } from './firebase';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const GlobalMessagePoller = {
    displayedMessages: {},

    start() {
        firebase
            .auth()
            .signInAnonymously()
            .then(() => {
                const environmentFilter = `show_in_env_${config.ENV_NAME}`;
                const unsubscribe = collection
                    .where('expiredAt', '==', null)
                    .where(environmentFilter, '==', true)
                    .orderBy('createdAt', 'desc')
                    .onSnapshot(snapshot => {
                        snapshot.docs.forEach(doc => {
                            const message = {
                                id: doc.id,
                                ...doc.data(),
                            };
                            // Only display new messages
                            if (!this.displayedMessages[message.id]) {
                                this.displayMessage(message);
                                this.displayedMessages[message.id] = true;
                            }
                        });
                    });
                this._unsubscribe = unsubscribe;
            })
            .catch(error => {
                console.error(error);
            });
    },

    stop() {
        if (typeof this._unsubscribe === 'function') {
            this._unsubscribe();
        }
    },

    displayMessage(message) {
        const { title, body, severity } = message;
        switch (severity) {
            case 'info':
                toastr.info(body, title, TOASTR_OPTIONS);
                break;
            case 'warning':
                toastr.warning(body, title, TOASTR_OPTIONS);
                break;
            case 'error':
                toastr.error(body, title, TOASTR_OPTIONS);
                break;
        }
    },
};

export default GlobalMessagePoller;

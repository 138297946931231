import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import 'moment-timezone';
import shallowCompare from 'utils/shallow-compare';
import Typography from '@mui/material/Typography';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import { isInternalUser } from 'states/profile/business-rules';

import FootTrafficAttribution from 'forms/ad-form/modules/foot-traffic-attribution';

import selector from './selector';

export class FootTrafficAttributionV1Summary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;

        return (
            <div className="media-plan-form__overview-table-data">
                {ad.fta.enabled ? (
                    <Typography>Enabled</Typography>
                ) : (
                    <Typography>Disabled</Typography>
                )}
                {ad.fta.line_id && (
                    <Typography>Line ID: {ad.fta.line_id ? ad.fta.line_id : ''}</Typography>
                )}
                {ad.fta.partner_id && (
                    <Typography>
                        Partner ID: {ad.fta.partner_id ? ad.fta.partner_id : ''}
                    </Typography>
                )}
            </div>
        );
    }
}

const FootTrafficAttributionV1FormContainer = props => {
    const { ad, ads, campaign, onSave, organization } = props;

    const handleChangeLineId = ({ line_id, draft, updateDraft }) => {
        const changes = {
            ...draft.fta,
            line_id,
        };
        updateDraft({ fta: changes });
    };

    const handleChangePartnerId = ({ partner_id, draft, updateDraft }) => {
        const changes = {
            ...draft.fta,
            partner_id,
        };
        updateDraft({ fta: changes });
    };

    const handleChangeFtaEnabled = ({ partnerId, ftaFee, draft, updateDraft }) => checked => {
        const fta_fee = checked ? ftaFee : 0;
        const ftaChanges = {
            ...draft.fta,
            enabled: checked,
            partner_id: checked ? partnerId : null,
            line_id: null,
        };
        updateDraft({ fta: ftaChanges, fta_fee });
    };

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Foot Traffic Attribution"
            fields={['fta_fee', 'fta']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onSave}
            columnName="Foot Traffic Attribution"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                return (
                    <FootTrafficAttribution
                        ftaEnabled={draft.fta.enabled}
                        onChangeEnabled={handleChangeFtaEnabled({
                            partnerId: organization.fta_partner_id,
                            ftaFee: campaign.fta_fee_standard,
                            draft,
                            updateDraft,
                        })}
                        errors={errors.fta}
                        showErrors={shouldShowErrors}
                        isInternalUser={isInternalUser()}
                        lineId={draft.fta.line_id || ''}
                        onChangeLineId={line_id =>
                            handleChangeLineId({ line_id, updateDraft, draft })
                        }
                        partnerId={draft.fta.partner_id || ''}
                        onChangePartnerId={partner_id =>
                            handleChangePartnerId({ partner_id, updateDraft, draft })
                        }
                    />
                );
            }}
        </BaseColumnForm>
    );
};

export const FootTrafficAttributionV1Form = connect(selector)(
    FootTrafficAttributionV1FormContainer
);

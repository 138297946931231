import _ from 'lodash';
import VError from 'verror';

import AudienceActions from 'states/resources/audiences/actions';
import notify from 'utils/notify';
import { createHttp } from 'utils/http';
import draftToPayload from './services/draft-to-payload';

const actions = {
    initialize(audienceId, target) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'AUDIENCE_EDITOR__INIT_START',
                payload: {
                    audienceId,
                    target,
                },
            });

            const orgId = _.get(getState(), 'profile.organizationId');

            let data = await fetchAudienceEditorData(orgId);

            if (!audienceId) {
                dispatch({
                    type: 'AUDIENCE_EDITOR__INIT_END',
                    payload: {
                        audienceId,
                        audience: null,
                        data,
                    },
                });
                return;
            }

            // fetch existing audience and load into draft
            dispatch(AudienceActions.getOne(audienceId)).then(audience => {
                dispatch({
                    type: 'AUDIENCE_EDITOR__INIT_END',
                    payload: {
                        audienceId,
                        audience,
                        data,
                    },
                });
            });
        };
    },
    save(audienceId) {
        return (dispatch, getState) => {
            dispatch({
                type: 'AUDIENCE_EDITOR__SAVE_START',
                payload: {},
            });

            const errors = _.get(getState(), 'audienceEditor.errors', {});

            if (_.keys(errors).length > 0) {
                dispatch({
                    type: 'AUDIENCE_EDITOR__SAVE_CLIENT_ERROR',
                    payload: {},
                    error: errors,
                });
                return Promise.reject(errors);
            }

            const draft = _.get(getState(), 'audienceEditor.draft');
            const isEditing = _.get(getState(), 'audienceEditor.isEditing');
            const payload = draftToPayload(draft, isEditing);

            let request;
            if (audienceId) {
                request = dispatch(
                    AudienceActions.update(
                        audienceId,
                        _.pick(payload, ['name', 'description', 'supports', 'third_party_fees'])
                    )
                );
            } else if (payload.mode === 'upload') {
                request = dispatch(AudienceActions.createAndUpload(payload));
            } else {
                request = dispatch(AudienceActions.create(payload));
            }

            return request.then(
                audience => {
                    dispatch({
                        type: 'AUDIENCE_EDITOR__SAVE_END',
                        payload: {},
                    });

                    return audience;
                },
                error => {
                    if (error.body && error.body[0] === 'SyntaxError') {
                        dispatch({
                            type: 'AUDIENCE_EDITOR__SAVE_SERVER_ERROR',
                            payload: {
                                error: error.body[1],
                                invalidLines: error.body.slice(2),
                            },
                        });
                    } else if (error.code && error.code === 413) {
                        dispatch({
                            type: 'AUDIENCE_EDITOR__SAVE_SERVER_ERROR',
                            payload: {
                                error: 'Uploaded file must be 100mb or less',
                                invalidLines: error.body.slice(2),
                            },
                        });
                    } else {
                        dispatch({
                            type: 'AUDIENCE_EDITOR__SAVE_SERVER_ERROR',
                            payload: {
                                error: 'Unknown error',
                            },
                        });
                    }
                    return Promise.reject(error);
                }
            );
        };
    },
    updateDraft(changes) {
        return {
            type: 'AUDIENCE_EDITOR__UPDATE_DRAFT',
            payload: {
                changes,
            },
        };
    },
    updateField(fieldName, changes) {
        return {
            type: 'AUDIENCE_EDITOR__UPDATE_FIELD',
            payload: {
                [fieldName]: changes,
            },
        };
    },
    updateMode(mode) {
        return {
            type: 'AUDIENCE_EDITOR__UPDATE_MODE',
            payload: {
                mode,
            },
        };
    },
    updateRetargetingType(type) {
        return {
            type: 'AUDIENCE_EDITOR__UPDATE_RETARGETING_TYPE',
            payload: {
                type,
            },
        };
    },
    openModal(audienceId) {
        return {
            type: 'AUDIENCE_EDITOR__UPLOADED_DASHBOARD_OPEN_MODAL',
            payload: { audienceId },
        };
    },
    closeModal() {
        return {
            type: 'AUDIENCE_EDITOR__UPLOADED_DASHBOARD_CLOSE_MODAL',
        };
    },
};

async function fetchAudienceEditorData(orgId) {
    try {
        const http = createHttp();
        const query = `
            query getAudienceEditorData ($relationship: String, $clientType: [String])  {
                campaigns {
                    id
                    name
                    start
                    end
                    iab_categories
                    ads {
                        id
                        name
                        start
                        end
                        platforms
                    }
                    beacons {
                        name
                        label
                    }
                    conversions {
                        reporting_name
                        event_name
                        use_click_through
                        use_view_through
                    }
                }
                organizations(filters: { relationship: $relationship, client_type: $clientType }) {
                    id
                    name
                    client_type
                }
                ownOrganization {
                    conversions {
                        id
                        event_name
                        reporting_name
                        use_click_through
                        click_through_attribution_window
                        use_view_through
                        view_through_attribution_window
                        use_dynamic_data_subevent
                        use_dynamic_data_value
                        dynamic_data_subevent_name
                        dynamic_data_value_name
                        should_optimize_for_budget_allocation
                        advertiser {
                            id
                            name
                        }
                    }
                }
            }
        `;

        const variables = {
            orgId,
            relationship: 'child',
            clientType: ['advertiser'],
        };

        const data = await http.graphql(query, variables);
        const advertisers = _.filter(data.organizations, org => org.client_type === 'advertiser');

        return {
            ...data,
            organizations: advertisers,
        };
    } catch (err) {
        notify({
            error: new VError(err, 'failed to fetch audience editor data'),
            metaData: {
                orgId,
            },
        });

        return {};
    }
}

export default actions;

import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.users';

export default function users(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__USERS__FETCH__SUCCESS:
            const { user, users } = action.payload;

            return mergeResource(
                state,
                []
                    .concat(user)
                    .concat(users)
                    .filter(x => x)
            );

        default:
            return state;
    }
}

import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MuiLink from '@mui/material/Link';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';

import { SimpleSelect } from 'widgets-v6/select';
import FlightIndicator from 'widgets-v5/flight-indicator';
import InlineTooltip from 'widgets-v5/inline-tooltip';
import ProgressBar from 'widgets-v5/progress-bar';
import { MobileHeader, CellBody } from 'widgets-v5/data-table';
import ColumnsDropdown from 'widgets-v6/columns-dropdown';
import { HealthChip } from 'widgets-v6/health-chip';
import { changeStatsView } from '../../actions';
import { STATS_VIEWS } from '../../constants';

import {
    formatNumber_whole,
    formatNumber_currency,
    formatNumber_percentage,
    formatDate_date,
} from 'utils/formatting';
import { can } from 'services/can';
import classnames from 'classnames';
import { NoResults } from 'widgets-v6/layout';
import { PermissionsMapping } from 'common/constants/role-intents';

const DATASOURCE_OPTIONS = [
    {
        value: STATS_VIEWS.CAMPAIGN,
        label: 'Entire Campaign View',
    },
    {
        value: STATS_VIEWS.FLIGHT,
        label: 'Current Flight View',
    },
];

const useStyles = makeStyles(() => ({
    chip: () => ({
        backgroundColor: 'orange',
        color: 'white',
    }),
    chipAvatar: () => ({
        backgroundColor: 'red',
    }),
    dotsbetweenItem: {
        '&:not(:first-child)::before': {
            content: '"\\2022"',
            padding: '0px 5px',
        },
    },
}));

const AdStatusIcon = styled(props => <FiberManualRecordIcon {...props} />)({
    color: props => props.customcolor,
    fontSize: '12',
    margin: '0px 5px',
});

const getAdHealthCat = ({ ads }) => {
    const adHealthChips = [
        {
            value: 'bad',
            label: 'Critical Ads',
            isChipVisible:
                _.filter(ads, ad => getAdHealth(ad) === 'bad' && !ad.isArchived).length > 0,
            avatar: (
                <Avatar className="healthAvatar">
                    {ads && _.filter(ads, ad => getAdHealth(ad) === 'bad' && !ad.isArchived).length}
                </Avatar>
            ),
            color: '#f36a7f',
        },
        {
            value: 'good',
            label: 'On Track Ads',
            isChipVisible:
                _.filter(ads, ad => getAdHealth(ad) === 'good' && !ad.isArchived).length > 0,
            avatar: (
                <Avatar className="healthAvatar">
                    {ads &&
                        _.filter(ads, ad => getAdHealth(ad) === 'good' && !ad.isArchived).length}
                </Avatar>
            ),
            color: '#13baaa',
        },
        {
            value: 'ok',
            label: 'Behind Ads',
            isChipVisible:
                _.filter(ads, ad => getAdHealth(ad) === 'ok' && !ad.isArchived).length > 0,
            avatar: (
                <Avatar className="healthAvatar">
                    {ads && _.filter(ads, ad => getAdHealth(ad) === 'ok' && !ad.isArchived).length}
                </Avatar>
            ),
            color: '#f8cf61',
        },
        {
            value: 'pending',
            label: 'Scheduled Ads',
            isChipVisible:
                _.filter(ads, ad => getAdHealth(ad) === 'pending' && !ad.isArchived).length > 0,
            avatar: (
                <Avatar className="healthAvatar">
                    {ads &&
                        _.filter(ads, ad => getAdHealth(ad) === 'pending' && !ad.isArchived).length}
                </Avatar>
            ),
            color: '#9fbfff',
        },
    ];

    return adHealthChips;
};

const getCampaignOptions = ({ campaignId, isClientOrgType, hasAds, ads }) => {
    const options = [
        {
            label: 'Progress',
            link: `/campaigns/${campaignId}/progress`,
            canView: can(PermissionsMapping.PROGRESS__VIEW),
        },
        {
            label: 'Report',
            link: `/campaigns/${campaignId}/${isClientOrgType ? 'client-report' : 'report'}`,
            canView: (hasAds || ads.length > 0) && can(PermissionsMapping.REPORT__VIEW),
        },
        {
            label: 'History',
            link: `/campaigns/${campaignId}/history`,
            canView: can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT),
        },
    ];

    return _.filter(options, option => option.canView);
};

const headers = [
    {
        label: 'Status',
        align: 'center',
        name: 'campaign_status',
        isPermitted: () => true,
        isToggleable: false,
        component: ({ status }) => (
            <FlightIndicator size="large" status={status} position={'right'} />
        ),
    },
    {
        label: 'Campaign',
        name: 'campaign_campaign',
        isPermitted: () => true,
        isToggleable: false,
        component: props => {
            const {
                campaign,
                classes,
                start,
                end,
                organizationTimezone,
                canViewSetup,
                hasAds,
                id,
                campaignNameURL,
                notes,
                clients,
                hasCpcInsConflict,
                budget_allocation_method,
                adHealthChipIsLoading,
                adHealthChips,
                currentCampaignId,
                activeChip,
                openAds,
                anchorElAds,
                handlePopoverClose,
                filteredAds,
                getDateFromLabel,
                getDateLabel,
                name,
                handlePopoverOpen,
                statsView,
            } = props;
            return (
                <React.Fragment>
                    <Typography color="textSecondary" variant="body2">
                        <span>{getDateFromLabel(campaign)}</span>
                        {start && end && (
                            <span className={classes.dotsbetweenItem}>
                                {getDateLabel(campaign, organizationTimezone, statsView)}
                            </span>
                        )}
                    </Typography>
                    <div className="ef6-dashboard-campaign-table__campaign-name">
                        <CellBody className="ef6-dashboard-campaign-table__mobile-campaign-name-column">
                            {// This handles a specific case where a client has been granted access to a
                            // campaign that has no ads. We want to block them from visiting the report page
                            // since it hangs without ads.
                            !canViewSetup && !hasAds ? (
                                <Typography>{`#${id} ${name}`}</Typography>
                            ) : (
                                <Link to={campaignNameURL} component={MuiLink}>
                                    <Typography variant="body1">{`#${id} ${name}`}</Typography>
                                </Link>
                            )}
                        </CellBody>
                    </div>
                    {notes && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {notes}
                        </Typography>
                    )}
                    <div className="ef6-dashboard-campaign-table__client-indicators">
                        <Typography color="textSecondary" variant="body2" gutterBottom>
                            {_.map(clients, client => (
                                <span className={classes.dotsbetweenItem} key={client.id}>
                                    {client.name}
                                </span>
                            ))}
                        </Typography>
                        {hasCpcInsConflict && <CPCConflictTooltip />}
                    </div>
                    {budget_allocation_method === 'manual' &&
                        (can(PermissionsMapping.PROGRESS__VIEW) && (
                            <Grid container spacing={1}>
                                {!adHealthChipIsLoading &&
                                    _.map(adHealthChips, category => (
                                        <React.Fragment key={category.value}>
                                            {category.isChipVisible && (
                                                <Grid item>
                                                    <HealthChip
                                                        label={category.label}
                                                        avatar={category.avatar}
                                                        onClick={e =>
                                                            handlePopoverOpen({
                                                                event: e,
                                                                campaignId: id,
                                                                catValue: category.value,
                                                            })
                                                        }
                                                        size="small"
                                                        customcolor={category.color}
                                                    />
                                                </Grid>
                                            )}
                                            {id === currentCampaignId &&
                                                category.value === activeChip &&
                                                can(PermissionsMapping.CAMPAIGN__VIEW) && (
                                                    <AdPopover
                                                        openAds={openAds}
                                                        anchorElAds={anchorElAds}
                                                        handlePopoverClose={handlePopoverClose}
                                                        filteredAds={filteredAds}
                                                        campaignId={id}
                                                        category={category}
                                                    />
                                                )}
                                        </React.Fragment>
                                    ))}
                            </Grid>
                        ))}
                </React.Fragment>
            );
        },
    },
    {
        label: 'Progress',
        name: 'campaign_progress',
        isPermitted: () => true,
        isToggleable: true,
        component: ({ status, start, end, organizationTimezone, default_timezone }) => {
            return (
                <ProgressBar
                    status={status}
                    startDate={start && new Date(start)}
                    endDate={end && new Date(end)}
                    primaryTimezone={organizationTimezone}
                    secondaryTimezone={default_timezone}
                />
            );
        },
    },
    {
        label: 'Impressions',
        align: 'right',
        name: 'campaign_impressions',
        isPermitted: () => true,
        isToggleable: true,
        component: ({ stats }) => (
            <React.Fragment>
                <MobileHeader>Impressions</MobileHeader>
                {_.isNumber(stats.impressions) ? (
                    <CellBody>{formatNumber_whole(stats.impressions)}</CellBody>
                ) : (
                    <CellBody>-</CellBody>
                )}
            </React.Fragment>
        ),
    },
    {
        label: 'Clicks',
        align: 'right',
        name: 'campaign_clicks',
        isPermitted: () => true,
        isToggleable: true,
        component: ({ stats }) => (
            <React.Fragment>
                <MobileHeader>Clicks</MobileHeader>
                {_.isNumber(stats.clicks) ? (
                    <CellBody>{formatNumber_whole(stats.clicks)}</CellBody>
                ) : (
                    <CellBody>-</CellBody>
                )}
            </React.Fragment>
        ),
    },
    {
        label: 'CTR',
        align: 'right',
        name: 'campaign_ctr',
        isPermitted: () => true,
        isToggleable: true,
        component: ({ stats }) => (
            <React.Fragment>
                <MobileHeader>CTR</MobileHeader>
                {_.isNumber(stats.ctr) ? (
                    <CellBody>{formatNumber_percentage(stats.ctr)}</CellBody>
                ) : (
                    <CellBody>-</CellBody>
                )}
            </React.Fragment>
        ),
    },
    {
        label: 'Total Cost',
        align: 'right',
        name: 'campaign_totalcost',
        isPermitted: ({ isClientOrgType }) => {
            if (!isClientOrgType) {
                return true;
            }
        },
        isToggleable: true,
        component: ({ currency, stats }) => (
            <React.Fragment>
                <MobileHeader>{'Total Cost'}</MobileHeader>
                {_.isNumber(stats.owner_total_media_cost_local) ? (
                    <CellBody>
                        {`${currency}${formatNumber_currency(stats.owner_total_media_cost_local)}`}
                    </CellBody>
                ) : (
                    <CellBody>-</CellBody>
                )}
            </React.Fragment>
        ),
    },
    {
        label: 'Total Cost eCPM',
        align: 'right',
        name: 'campaign_totalcost_epcm',
        isPermitted: ({ isClientOrgType }) => {
            if (!isClientOrgType) {
                return true;
            }
        },
        isToggleable: true,
        component: ({ stats }) => (
            <React.Fragment>
                <MobileHeader>Total Cost eCPM</MobileHeader>
                {_.isNumber(stats.owner_total_media_cost_local_ecpm) ? (
                    <CellBody>
                        {formatNumber_currency(stats.owner_total_media_cost_local_ecpm)}
                    </CellBody>
                ) : (
                    <CellBody>-</CellBody>
                )}
            </React.Fragment>
        ),
    },
    {
        label: 'Revenue',
        align: 'right',
        name: 'campaign_revenue',
        isPermitted: ({ isClientOrgType }) => {
            if (!isClientOrgType) {
                return true;
            }
        },
        isToggleable: true,
        component: ({ stats, campaign }) => (
            <React.Fragment>
                <MobileHeader>{'Revenue'}</MobileHeader>
                {campaign.billing_enabled && _.isNumber(stats.billings_local) ? (
                    <CellBody>{formatNumber_currency(stats.billings_local)}</CellBody>
                ) : (
                    <CellBody>-</CellBody>
                )}
            </React.Fragment>
        ),
    },
    {
        label: 'Actions',
        name: 'campaign_actions',
        isPermitted: () => true,
        isToggleable: false,
        component: ({ campaignOptions }) => <MoreOptionsMenu campaignOptions={campaignOptions} />,
    },
];

export default function DashboardCampaignList(props) {
    const dispatch = useDispatch();
    const statsView = useSelector(state => state.dashboard.statsView);
    const classes = useStyles(props);

    const [activeChip, setActiveChip] = React.useState('bad');

    const [currentCampaignId, setCurrentCampaignId] = React.useState(null);

    const [anchorElAds, setAnchorElAds] = React.useState(null);

    const handlePopoverOpen = ({ event, campaignId, catValue }) => {
        setCurrentCampaignId(campaignId);
        setActiveChip(catValue);
        setAnchorElAds(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorElAds(null);
    };

    const openAds = Boolean(anchorElAds);

    const {
        ownOrganizationType,
        campaigns,
        canViewSetup,
        organizationTimezone,
        isDashboardLoading,
        paginator,
        resetSearchValue,
        maxResults,
        currentPage,
        maxPage,
        hiddenColumns,
        toggleColumn,
        fetchingHiddenColumns,
        filterButton,
        filterChips,
    } = props;
    const isClientOrgType = ownOrganizationType === 'client';

    let text;

    if (maxPage > 0) {
        text = `Page ${currentPage} of ${maxPage}.`;
    }

    const getDateFromLabel = campaign => {
        const isEnded = campaign.status === 'ended';
        const prefix = isEnded ? 'Ended' : 'Ending';
        const daysBetween = moment(campaign.end).from();

        if (!campaign.end) {
            return 'Incomplete setup';
        } else {
            return `${prefix} ${daysBetween}`;
        }
    };

    const getDateLabel = (campaign, organizationTimezone, statsView) => {
        if (statsView === STATS_VIEWS.FLIGHT && campaign.flightPacingStrategy === 'campaign') {
            if (campaign.liveFlight) {
                const startDate = formatDate_date(
                    new Date(campaign.liveFlight.start),
                    organizationTimezone
                );
                const endDate = formatDate_date(
                    new Date(campaign.liveFlight.end),
                    organizationTimezone
                );

                return `${startDate} - ${endDate}`;
            } else {
                return 'No live flight';
            }
        }

        const startDate = formatDate_date(new Date(campaign.start), organizationTimezone);
        const endDate = formatDate_date(new Date(campaign.end), organizationTimezone);

        if (campaign.start && campaign.end) {
            return `${startDate} - ${endDate}`;
        }
    };

    let filteredHeaders = _(headers)
        .filter(header => header.isPermitted({ isClientOrgType }))
        .filter(header => !hiddenColumns[header.name])
        .value();

    let filteredColumns = _(headers)
        .filter(header => header.isPermitted({ isClientOrgType }))
        .filter(header => header.isToggleable)
        .value();

    return (
        <React.Fragment>
            {!isDashboardLoading && (
                <Typography color="textSecondary" variant="body2" gutterBottom>
                    {maxResults} Records found. {text}
                </Typography>
            )}
            <Paper>
                <Box display="flex" justifyContent="space-between" px={1} py={2}>
                    <Box display="flex" alignItems="center">
                        <Box mr={1}>{filterButton}</Box>
                        <div>{filterChips}</div>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <SimpleSelect
                            value={statsView}
                            onChange={e => dispatch(changeStatsView(e.target.value))}
                            options={DATASOURCE_OPTIONS}
                            placeholder="Select View"
                        />
                        {!fetchingHiddenColumns && (
                            <ColumnsDropdown
                                columns={filteredColumns}
                                onClick={toggleColumn}
                                hiddenColumns={hiddenColumns}
                            />
                        )}
                    </Box>
                </Box>
                {isDashboardLoading && <LinearProgress />}
                <Table stickyHeader aria-label="sticky table">
                    {!fetchingHiddenColumns && (
                        <TableHead>
                            <TableRow>
                                {_.map(filteredHeaders, (header, index) => (
                                    <TableCell
                                        key={`${header}${index}`}
                                        style={{ minWidth: header.minWidth }}
                                        align={header.align}
                                    >
                                        {header.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {campaigns.length === 0 && !isDashboardLoading ? (
                            <TableRow>
                                <TableCell colSpan={headers.length} align="center">
                                    <NoResults
                                        title="No campaigns found"
                                        buttonText="Reset Results"
                                        onClick={resetSearchValue}
                                    />
                                </TableCell>
                            </TableRow>
                        ) : (
                            _.map(campaigns, campaign => {
                                const {
                                    id,
                                    name,
                                    notes,
                                    status,
                                    ads,
                                    stats,
                                    start,
                                    currency,
                                    end,
                                    statsIsLoading,
                                    clients,
                                    hasCpcInsConflict,
                                    hasAds,
                                    default_timezone,
                                    budget_allocation_method,
                                    adHealthChipIsLoading,
                                } = campaign;

                                // ---- Sort out intent -----------
                                // Order are important
                                let campaignNameURL = `/campaigns/${id}/setup`;
                                if (can(PermissionsMapping.CAMPAIGN__VIEW)) {
                                    campaignNameURL = `/campaigns/${id}/setup`;
                                } else if (can(PermissionsMapping.REPORT__VIEW)) {
                                    if (isClientOrgType) {
                                        campaignNameURL = `/campaigns/${id}/client-report`;
                                    } else {
                                        campaignNameURL = `/campaigns/${id}/report`;
                                    }
                                }
                                // ---- Sort out intent (end)-------

                                const adHealthChips = getAdHealthCat({ ads });
                                const campaignOptions = getCampaignOptions({
                                    campaignId: id,
                                    isClientOrgType,
                                    hasAds,
                                    ads,
                                });

                                const filteredAds = _.filter(ads, ad => {
                                    const health = getAdHealth(ad);
                                    const activeAdPopup = activeChip;
                                    return _.includes(activeAdPopup, health);
                                });

                                return (
                                    <React.Fragment key={id}>
                                        <TableRow
                                            hover
                                            className={classnames(
                                                'ef6-dashboard-campaign-table__table-row',
                                                {
                                                    'ef6-dashboard-campaign-table__table-row--cpc-conflict': hasCpcInsConflict,
                                                }
                                            )}
                                        >
                                            {_.map(filteredHeaders, column => (
                                                <TableCell
                                                    key={column.name}
                                                    component="th"
                                                    scope="row"
                                                    align={column.align}
                                                    // style={{ minWidth: column.minWidth ? column.minWidth : 0 }}
                                                >
                                                    {column.component({
                                                        status,
                                                        campaign,
                                                        classes,
                                                        start,
                                                        end,
                                                        organizationTimezone,
                                                        canViewSetup,
                                                        hasAds,
                                                        id,
                                                        campaignNameURL,
                                                        notes,
                                                        clients,
                                                        hasCpcInsConflict,
                                                        budget_allocation_method,
                                                        adHealthChipIsLoading,
                                                        adHealthChips,
                                                        currentCampaignId,
                                                        activeChip,
                                                        openAds,
                                                        anchorElAds,
                                                        handlePopoverClose,
                                                        filteredAds,
                                                        getDateFromLabel,
                                                        getDateLabel,
                                                        name,
                                                        default_timezone,
                                                        statsIsLoading,
                                                        stats,
                                                        currency,
                                                        campaignOptions,
                                                        handlePopoverOpen,
                                                        statsView,
                                                    })}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </Paper>
            {!isDashboardLoading && paginator}
        </React.Fragment>
    );
}

function AdPopover({
    openAds,
    anchorElAds,
    handlePopoverClose,
    filteredAds,
    campaignId,
    category,
}) {
    return (
        <Popover
            id="mouse-over-popover"
            open={openAds}
            anchorEl={anchorElAds}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <List>
                {_.map(filteredAds, ad => (
                    <Link
                        key={ad.id}
                        color="initial"
                        to={`/campaigns/${campaignId}/progress/${ad.id}`}
                        component={MuiLink}
                    >
                        <ListItem button>
                            <AdStatusIcon customcolor={category.color} />
                            <Typography variant="body2">{ad.name}</Typography>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Popover>
    );
}

function MoreOptionsMenu({ campaignOptions }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} elevation={1}>
                {_.map(campaignOptions, option => (
                    <MenuItem key={option.label}>
                        <Link to={option.link} color="inherit" underline="none" component={MuiLink}>
                            {option.label}
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}

function CPCConflictTooltip() {
    return (
        <InlineTooltip
            className="ef6-dashboard-campaign-table__cpc-conflict-tooltip"
            position="right"
            tooltip={
                <div>
                    <div>Warning: CPC Ads are paced by clicks</div>
                    <div>and DoubleClick INS tags may not track clicks properly!</div>
                    <div>This Ad will require frequent monitoring to prevent overbuying</div>
                </div>
            }
        >
            <span className="ef6-dashboard-campaign-table__cpc-warning-icon">
                <i className="fa fa-lg fa-exclamation-triangle" />
            </span>
        </InlineTooltip>
    );
}

function getAdHealth(ad) {
    // return ad.health;
    return ad.metadata.progress && ad.metadata.progress.progressData.health;
}

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
    root: {
        textAlign: 'left',
    },
    divider: {
        margin: '10px 0px',
    },
    title: {
        textTransform: 'uppercase',
    },
});

export function SummaryLayout(props) {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                {props.hiddinHeaderText === true ? null : (
                    <Typography variant="body1" gutterBottom>
                        {props.title}
                    </Typography>
                )}
                {props.actions}
            </Box>
            <div className="ef3-adSummaryView_panelBody">
                <Typography variant="h2" gutterBottom>
                    {props.subTitle}
                </Typography>
                <div>{props.children}</div>
            </div>
        </div>
    );
}

export function SummaryGroup(props) {
    const classes = useStyles(props);

    return (
        <div>
            <Divider className={classes.divider} light />
            <Typography className={classes.title} variant="body2" gutterBottom>
                {props.groupName}
            </Typography>
            {props.children}
        </div>
    );
}

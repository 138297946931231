import _ from 'lodash';

export function serialize(draft) {
    const payload = {
        name: _.get(draft, 'name', void 0),
        bundle_ids: _.get(draft, 'bundle_ids', []),
    };

    const serializedDraft = {
        ...payload,
    };
    return serializedDraft;
}

export function serialize_EN_279({
    name,
    bundle_ids,
    domains,
    connected_tv_ids,
    dooh_board_ids,
    advertiserId,
}) {
    const payload = {
        name,
        bundle_ids,
        domains,
        connected_tv_ids,
        dooh_board_ids,
        advertiserId,
    };

    const serializedDraft = {
        ...payload,
    };
    return serializedDraft;
}

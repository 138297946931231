import _ from 'lodash';

import c from 'common/constants/flux-events';
import { getDefaults, validateAdPresetDraft } from 'services/resources/ad-preset';
import flags from 'containers/flags/service';

const initialState = {
    draft: _.pick(getDefaults(), ['ad_preset_properties']),
    defaultValues: getDefaults(),
    searchString: '',
    organization: null,
    isDraftValid: false,
    errors: [],
    poiViewer: null,
    showErrors: false,
    isLoading: false,
    isSubmiting: false,
    isDirty: false,
    isPresetsLoading: true,
};

function getInitialState() {
    const initialState = {
        draft: _.pick(getDefaults(), ['ad_preset_properties']),
        defaultValues: getDefaults(),
        searchString: '',
        organization: null,
        isDraftValid: false,
        errors: [],
        poiViewer: null,
        showErrors: false,
        isLoading: false,
        isSubmiting: false,
        isDirty: false,
        isPresetsLoading: true,
    };
    return initialState;
}

export const NAME = 'adPresetNew';

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case c.NEW_AD_PRESET__DRAFT__OPEN: {
            return {
                ...getInitialState(),
                isFormOpen: true,
                isLoading: true,
            };
        }

        case c.NEW_AD_PRESET__DRAFT__INIT: {
            const sessionDraft_current = _.get(state, `draft`, null);
            let sessionDraft_next = { ...sessionDraft_current, ad_preset_name: '' };

            if (sessionDraft_current === null) {
                // Only inititialize session's draft if there isn't already one.
                // This is to prevent router reruning when user navigates to edit creative form.
                sessionDraft_next = _.get(action, 'payload.draft');
            }

            const errors = validateAdPresetDraft({ ...sessionDraft_next });

            return {
                ...state,
                errors,
                organization: _.get(action, 'payload.organization'),
                draft: sessionDraft_next,
                isLoading: false,
                isFormOpen: true,
            };
        }

        case c.NEW_AD_PRESET__DRAFT__UPDATE: {
            const { formData } = action.payload;
            const nextDraft = {
                ...state.draft,
                ...formData,
            };
            const errors = validateAdPresetDraft(nextDraft);
            return {
                ...state,
                draft: nextDraft,
                isDirty: true,
                errors,
                isDraftValid: errors.length === 0,
            };
        }

        case c.NEW_AD_PRESET__DRAFT__SHOW_ERRORS: {
            return {
                ...state,
                showErrors: true,
            };
        }

        case c.NEW_AD_PRESET__DRAFT__SUBMIT: {
            return {
                ...state,
                isSubmitting: true,
            };
        }

        case c.NEW_AD_PRESET__DRAFT__SUBMIT_FAIL: {
            const httpErrors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));

            return {
                ...state,
                errors: httpErrors,
                showErrors: true,
                isSubmiting: false,
                isDraftValid: false,
            };
        }

        case c.NEW_AD_PRESET__DRAFT__SUBMIT_SUCCESS:
            return {
                ...state,
                isSubmiting: false,
            };

        case c.NEW_AD_PRESET__DRAFT__CLOSE: {
            return {
                ...getInitialState(),
                isFormOpen: false,
                isLoading: false,
            };
        }

        case c.SYSTEM__AD_PRESET__FETCH__SUCCESS: {
            return {
                ...getInitialState(),
                isPresetsLoading: false,
            };
        }

        default:
            return state;
    }
}

import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    currentSelectedMetricType: 'impressions',
    isVisibleBeaconsAtLimit: false,
    metricComponentsConfig: [
        {
            metricType: 'impressions',
            status: 'selected',
            style: 'impressions',
            category: 'basic',
            formatType: 'thousands',
            presentationName: 'Impressions',
            isCurrencyType: false,
        },
        {
            metricType: 'clicks',
            status: 'visible',
            style: 'clicks',
            category: 'basic',
            formatType: 'thousands',
            presentationName: 'Clicks',
            isCurrencyType: false,
        },
        {
            metricType: 'ctr',
            status: 'visible',
            style: 'ctr',
            category: 'basic',
            formatType: 'percentage',
            presentationName: 'CTR',
            isCurrencyType: false,
        },
        // {
        //     metricType: 'daily_uniq',
        //     status: 'hidden',
        //     style: 'daily_uniq',
        //     category: 'basic',
        //     formatType: 'thousands',
        //     presentationName : 'Average Daily Uniques',
        //     isCurrencyType: false,
        // },
        // {
        //     metricType: 'average_freq',
        //     status: 'hidden',
        //     style: 'average_freq',
        //     category: 'basic',
        //     formatType: 'whole-fixed',
        //     presentationName : 'Average Daily Frequency',
        //     isCurrencyType: false,
        // },
        {
            metricType: 'spend',
            status: 'hidden',
            style: 'spend',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Billable Cost',
            isCurrencyType: true,
        },
        {
            metricType: 'ecpc',
            status: 'hidden',
            style: 'ecpc',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Billable Cost eCPC',
            isCurrencyType: true,
        },
        {
            metricType: 'ecpm',
            status: 'hidden',
            style: 'ecpm',
            category: 'basic',
            formatType: 'dollar',
            presentationName: 'Billable Cost eCPM',
            isCurrencyType: true,
        },
    ],
};
export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        default:
            return state;
    }
}

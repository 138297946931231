import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';
import notify from 'utils/notify';
const Ads = {
    getAll(campaignId) {
        return (dispatch, getState) => {
            return http()
                .get(`campaigns/${campaignId}/ads`)
                .then(
                    ads => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                            payload: { campaignId, ads },
                        });

                        return ads;
                    },
                    err => {
                        console.error(`GET ALL ads error`, err);

                        throw err;
                    }
                );
        };
    },

    getByCampaigns(campaignIds) {
        return (dispatch, getState) => {
            const campaignIdsCSV = [].concat(campaignIds).join(',');
            return http()
                .get(`ads?campaigns=${campaignIdsCSV}`)
                .then(
                    ads => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                            payload: { ads },
                        });

                        return ads;
                    },
                    err => {
                        console.error(`GET BY CAMPAIGN ads error`, err);

                        throw err;
                    }
                );
        };
    },

    get(campaignId, adId) {
        return (dispatch, getState) => {
            return http()
                .get(`campaigns/${campaignId}/ads/${adId}`)
                .then(
                    ad => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                            payload: { campaignId, ad },
                        });

                        return ad;
                    },
                    err => {
                        console.error(`GET ONE ads error`, err);

                        throw err;
                    }
                );
        };
    },

    getHistory(campaignId, adId) {
        return (dispatch, getState) => {
            return http()
                .get(`campaigns/${campaignId}/ads/${adId}/history`)
                .then(
                    history => {
                        dispatch({
                            type: c.SYSTEM__AD_HISTORY__FETCH__SUCCESS,
                            payload: { campaignId, adId, history },
                        });

                        return history;
                    },
                    err => {
                        console.error(`GET HISTORY ads error`, err);

                        throw err;
                    }
                );
        };
    },

    create(campaignId, ad) {
        return (dispatch, getState) => {
            return http()
                .post(`campaigns/${campaignId}/ads`, ad)
                .then(
                    ad => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                            payload: { campaignId, ad },
                        });

                        return ad;
                    },
                    err => {
                        console.error(`CREATE ads error`, err);

                        throw err;
                    }
                );
        };
    },

    updateMany(campaignId, operations) {
        return (dispatch, getState) => {
            return http().patch(`campaigns/${campaignId}/ads/`, operations);
        };
    },
    update(campaignId, adId, ad) {
        return (dispatch, getState) => {
            return http()
                .patch(`campaigns/${campaignId}/ads/${adId}`, ad)
                .then(
                    ad => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                            payload: { campaignId, ad },
                        });

                        return ad;
                    },
                    err => {
                        console.error(`UPDATE ads error`, err);

                        throw err;
                    }
                );
        };
    },
    pause(campaignId, adId) {
        return (dispatch, getState) => {
            const payload = { paused: true };

            return http().patch(`campaigns/${campaignId}/ads/${adId}/pause`, payload);
        };
    },
    unpause(campaignId, adId) {
        return (dispatch, getState) => {
            const payload = { paused: false };

            return http().patch(`campaigns/${campaignId}/ads/${adId}/pause`, payload);
        };
    },

    delete(campaignId, adId) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.ads.${adId}.attr._etag`);

            return http()
                .setIfMatch(etag)
                .del(`campaigns/${campaignId}/ads/${adId}`)
                .then(
                    ad => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                            payload: { campaignId, ad },
                        });

                        return ad;
                    },
                    err => {
                        console.error(`DELETE ads error`, err);
                        notify({ error: err });
                        throw err;
                    }
                );
        };
    },
};

export default Ads;

import React from 'react';
import classnames from 'classnames';

import Card, { VerticalCardGroup } from 'widgets-v5/card';
import { BlockLoadGroup } from 'widgets-v5/load-group';

const ExporeLayout = (props) => {
    return (
        <BlockLoadGroup isLoading={!props.campaign}>
            <div
                className={classnames(
                    'ef5-explore-report',
                    {
                        'ef5-explore-report_mode-dimensions': props.mode === 'dimension',
                        'ef5-explore-report_mode-pivot-table': props.mode === 'pivot',
                    },
                    // Eventually remove below classes
                    'am-reports',
                )}
            >
                <VerticalCardGroup>
                    <Card>{props.scopes}</Card>
                    <Card>{props.chartExplorerSummary}</Card>
                    <Card>
                        {props.controls}
                        {props.mode === 'dimension' && <div className="ef5-explore-report__dimensions">
                            {props.heatMap}
                            {props.barGraphs}
                        </div>}
                        {props.mode === 'pivot' && <div className="ef5-explore-report__pivot-table">
                            {props.pivotTable}
                        </div>}
                    </Card>
                </VerticalCardGroup>
            </div>
        </BlockLoadGroup>
    );
}

export default ExporeLayout;

import React from 'react';
import uniqueId from 'lodash/uniqueId';
/** import third-party libraries in the section above, keep the ascending order */

import { highlight } from '../summary-conf';
import formatGeoCountry from 'services/gis/geo-country-format';
/** import classes and others in the section above, keep the ascending order */

export default highlight(
    class extends React.Component {
        render() {
            const style = this.props.strikethroughText
                ? {
                      textDecoration: 'line-through',
                      color: 'gray',
                  }
                : this.props.highlight
                ? { background: '#ffaaaa' }
                : null;

            const dataGeoTargets = this.props.value;
            const geoTargets = <ul key={uniqueId()}>{dataGeoTargets.map(formatGeoCountry)}</ul>;

            return (
                <dl className="ef3-adSummaryView_panelBlock">
                    <div className="ef3-adSummaryView__item-group">
                        <dt className="ef3-adSummaryView_term">{this.props.keyName}</dt>
                        <dd className="ef3-adSummaryView_description" style={style}>
                            {geoTargets}
                        </dd>
                        {this.props.tooltip}
                    </div>
                </dl>
            );
        }
    },
    (currentProps, nextProps) => {
        let hasChanged;
        const value_strgf_next = JSON.stringify(nextProps.value);
        const value_strgf_prev = JSON.stringify(currentProps.value);

        hasChanged = value_strgf_prev !== value_strgf_next;
        return hasChanged;
    }
);

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
    ROLE_INTENTS,
    ALL_INTENTS,
} from 'common/constants/role-intents';
import { can } from 'services/can';

/*

    <Can intent="campaign__view_and_edit">
        {(canDo) => canDo ? (

        ) : (

        )}
    </Can>

*/

export const Can = connect(storeState => {
    return {
        globalRole: _.get(storeState, 'profile.globalRole'),
    };
})(
    class extends React.Component {
        render() {
            const { children, intent, globalRole } = this.props;

            if (!globalRole) {
                return children(false);
            }

            if (ALL_INTENTS[intent] === undefined) {
                console.warn(`Invalid intent: ${intent}`);
            }

            const canDo = !!_.get(ROLE_INTENTS, `${globalRole}.${intent}`);

            return children(canDo) || null;
        }
    }
);

// DEPRECATED - Use Can instead
export class CanSwitch extends React.Component {
    render() {
        const allChildren = React.Children.toArray(this.props.children);
        const invalidChild = _.find(allChildren, c => !isValidChild(c));

        if (invalidChild) {
            console.warn(`Invalid Child of CanSwitch: ${invalidChild.type.displayName}`);
        }

        const children = _.filter(allChildren, isValidChild);

        // Check for intent
        var out = _.find(children, c => {
            if (c.props.intent) {
                return can(c.props.intent);
            }
        });

        // Check for CanDefault
        if (!out) {
            out = _.find(children, c => c.type.displayName === 'CanDefault');
        }

        // Default to span
        if (!out) {
            out = <span />;
        }

        return out;
    }
}

// DEPRECATED - Use Can instead
function isValidChild(child) {
    return child.type.displayName === 'CanDefault' || child.type.displayName === 'CanCase';
}

// DEPRECATED - Use Can instead
export class CanCase extends React.Component {
    static displayName = 'CanCase';

    static propTypes = {
        intent: PropTypes.string,
    };

    render() {
        return this.props.children;
    }
}

// DEPRECATED - Use Can instead
export class CanDefault extends React.Component {
    static displayName = 'CanDefault';

    render() {
        return this.props.children;
    }
}

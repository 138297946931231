import get from 'lodash/get';

import store from 'core/store';
import { ROLE_INTENTS, ALL_INTENTS } from 'common/constants/role-intents';

// can(PermissionsMapping.DO_SOMETHING);
export function can(intent, role) {
    const finalRole = role || get(store.getState(), 'profile.globalRole');
    // role = 'analyst';
    // role = 'mediaPlanner';
    // role = 'campaignManager';
    // role = 'admin';
    // console.log('ROLE', role)

    if (!finalRole) {
        return false;
    }

    // Check if the incoming intent exists
    if (ALL_INTENTS[intent] === undefined) {
        console.warn(`Invalid intent: ${intent}`);
    }

    return !!get(ROLE_INTENTS, `${finalRole}.${intent}`);
}

import config from '../../config';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/remote-config';
import 'firebase/analytics';

import store from 'core/store';

firebase.initializeApp(config.API_KEYS.FIREBASE);

const db = firebase.firestore();
const collection = db.collection('global-notifications');
const firebaseRemoteConfig = firebase.remoteConfig();

window.firebase = firebase;
window.firebaseRemoteConfig = firebaseRemoteConfig;

function setFireBaseUserProperties() {
    const organizationId = _.get(store.getState(), 'profile.organizationId');
    const organizationType = _.get(store.getState(), 'profile.ownOrganization.type');
    const globalRole = _.get(store.getState(), 'profile.globalRole');
    const realPersonOrganization = _.get(
        store.getState(),
        'profile.impersonatorsToken.v3_impersonator.organization',
        undefined
    );
    const isImpersonating = _.get(store.getState(), 'profile.isImpersonating', false);

    firebase.analytics().setUserProperties({
        organization_id: organizationId,
        organization_type: organizationType,
        user_role: globalRole,
        imp_organization: isImpersonating ? realPersonOrganization : null,
    });
}

export { firebase, collection, firebaseRemoteConfig, setFireBaseUserProperties };

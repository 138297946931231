import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

function getDateStamp() {
    const date = new Date();
    return date.toISOString();
}

const initialState = {
    isLoading: false,
    currentView: {
        entityType: 'implicitLayer',
        entityId: void 0,
    },
    eTag: getDateStamp(),
};

export default function reducer(state = {}, action) {
    switch (action.type) {
        case c.OVERVIEW_ADS__AD_DRAFT_EDIT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        // case '@@router/LOCATION_CHANGE': {
        //     const isOverviewAdsUrl = parseRoute(action.path);
        //     if (!isOverviewAdsUrl) {
        //         return state;
        //     }
        //     return {
        //         ...state,
        //         filterUrl: action.path
        //     };
        // }

        case c.OVERVIEW_ADS__AD_DRAFT_EDIT__SHOW_GEO_ENTITY_IN_MAP: {
            const currentView = {
                entityType: action.payload.entityType,
                entityId: action.payload.entityId,
            };

            const o = {
                ...state,
                currentView,
            };
            return o;
        }

        case c.OVERVIEW_ADS__AD_DRAFT_EDIT__REFRESH_GEO_ENTITY_IN_MAP: {
            const o = {
                ...state,
                eTag: getDateStamp(),
            };
            return o;
        }

        default:
            return state;
    }
}

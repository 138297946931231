
const fakeStorage = {
    getItem: () => null,
    setItem: () => null,
    removeItem: () => null,
};

export function storageSelector(isImpersonating) {
    // test environment does not have session or local storage
    if (typeof sessionStorage === 'undefined') {
        return fakeStorage;
    }
    if (isImpersonating) {
        return sessionStorage;
    }
    return localStorage;
}

import _ from 'lodash';

const domainSetting = {
    dataNestingOrder: ['day_of_week', 'hour'],
    hour: {
        displayOrder: { type: 'fixed', sortOrder: ['0', '1', '2', '3'] },
        bin: {
            '0': ['0', '1', '2', '3', '4', '5'],
            '1': ['6', '7', '8', '9', '10', '11'],
            '2': ['12', '13', '14', '15', '16', '17'],
            '3': ['18', '19', '20', '21', '22', '23'],
        },
        map: {
            '0-5': ['0', '1', '2', '3', '4', '5'],
            '6-11': ['6', '7', '8', '9', '10', '11'],
            '12-17': ['12', '13', '14', '15', '16', '17'],
            '18-24': ['18', '19', '20', '21', '22', '23'],
        },
        binNameToBinKey: {
            '0-5': '0',
            '6-11': '1',
            '12-17': '2',
            '18-24': '3',
        },
    },
    day_of_week: {
        displayOrder: { type: 'fixed', sortOrder: ['0', '1', '2', '3', '4', '5', '6'] },
        bin: {
            '0': '0',
            '1': '1',
            '2': '2',
            '3': '3',
            '4': '4',
            '5': '5',
            '6': '6',
        },
        map: {
            '0': '0',
            '1': '1',
            '2': '2',
            '3': '3',
            '4': '4',
            '5': '5',
            '6': '6',
        },
        // , dataMap: [        // to be build by buildingIndexForHeatmap() // length 27
        //     0: "0-5|0"
        //     1: "6-11|0"
        //     2: "12-17|0"
        //     3: "18-24|0"
        //     4: "0-5|1"
        //     5: "6-11|1"
        //     ...
        //
        // ]
    },
    convertSelection_hoursToInterval: function(selected_hours) {
        const selected_intervals = [];
        const binSpecification = domainSetting.hour.bin;
        _.each(binSpecification, (binSpecVal, binSpecKey) => {
            const intersection = _.intersection(binSpecVal, selected_hours);
            if (_.isEqual(intersection, binSpecVal)) {
                selected_intervals.push(binSpecKey);
            }
        });
        return selected_intervals;
    },

    convertSelection_intervalToHours: function(selected_intervals) {
        let selected_hours = [];
        const binSpecification = domainSetting.hour.bin;
        selected_intervals.forEach(interval => {
            const equivalentHours = binSpecification[interval];
            selected_hours = selected_hours.concat(equivalentHours);
        });
        return selected_hours;
    },
};

(function buildIndexForHeatmap() {
    (function() {
        // do hour|day_of_week
        const dimensionName = 'hour|day_of_week';
        const dataMap = {};
        const dataNestingOrder = domainSetting.dataNestingOrder;
        const item_0 = dataNestingOrder[0]; // 'day_of_week'
        const item_1 = dataNestingOrder[1]; // 'hour'
        const dim_0 = domainSetting.day_of_week;
        const dim_1 = domainSetting.hour;

        let count = 0;
        Object.keys(dim_0.map).forEach((el_0, index_0) => {
            Object.keys(dim_1.map).forEach((el_1, index_1) => {
                const name1 = el_1 + '|' + el_0;
                dataMap[count++] = name1;
            });
        });
        domainSetting.dataMap = dataMap;

        domainSetting.hour.binKeyToBinName = _.invert(domainSetting.hour.binNameToBinKey);
    })();
})();

export default domainSetting;

export function openCreativeWarningModal() {
    return dispatch => {
        dispatch({
            type: 'CREATIVE_BULK_EDIT_FORM__OPEN_WARNING_MODAL',
        });
    };
}

export function closeCreativeWarningModal() {
    return dispatch => {
        dispatch({
            type: 'CREATIVE_BULK_EDIT_FORM__CLOSE_WARNING_MODAL',
        });
    };
}

import _ from 'lodash';

export default function selector(storeState) {
    const isInitialized = _.get(storeState, 'conversionsDashboard.isInitialized');
    const conversions = _.get(storeState, 'conversionsDashboard.conversions', []);
    const advertiserIdSelected = _.get(storeState, 'conversionsDashboard.advertiserIdSelected', []);
    const isDeleting = _.get(storeState, 'conversionsDashboard.isDeleting', []);

    const conversionsFiltered = _.filter(conversions, conversion => {
        if (!advertiserIdSelected) {
            return true;
        }

        return conversion.advertiser.id === advertiserIdSelected;
    });

    return {
        conversions: conversionsFiltered,
        isDeleting,
        isInitialized,
    };
}

import _ from 'lodash';

export const NAME = 'ftaLocationLists';

const initialState = {
    loading: true,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;

        case 'FTA_LOCATION_LISTS__FETCH_DATA__END': {
            return {
                ...state,
                ftaLocationLists: action.payload.data,
                loading: false,
            };
        }

        case 'FTA_LOCATION_LISTS__FETCH_DATA__START': {
            return {
                ...state,
                loading: true,
            };
        }
    }
}

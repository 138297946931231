const urlValidationRegExp = /^(http:\/\/|https:\/\/|tel:)((www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}[a-z]{2,6}([-a-zA-Z0-9@:%_\+.;~#?&\/\/=]*)|[0-9]{10,11})/;

//http://stackoverflow.com/questions/46155/validate-email-address-in-javascripte
const email = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const soloHtmlTag = tag => new RegExp(`<\\s*${tag}[^>]*\\/>`, 'gm');

const letter = new RegExp(/[a-z]/i);
const digit = new RegExp(/[0-9]/i);

export const regEx = {
    url: urlValidationRegExp,
    soloHtmlTag,
    email,
    letter,
    digit,
};

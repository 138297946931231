import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';
import formatGeoCountry from 'services/gis/geo-country-format';
import Geotargets from 'forms/ad-form/modules/geotargets';
import shallowCompare from 'utils/shallow-compare';
import selector, { geoTargetingSummarySelector } from './selector';

export const GeoTargetingForm = connect(selector)(
    class GeoTargetingForm extends React.Component {
        state = { lastCopiedAd: null };

        render() {
            const { ad, ads, campaign, onSave } = this.props;

            return (
                <BaseColumnForm
                    ad={ad}
                    ads={ads}
                    campaign={campaign}
                    headline="Geo"
                    fields={['geo_targeting_settings', 'geotargets', 'geoboxes']}
                    shouldRefreshPredictionsOnSave={true}
                    onSave={onSave}
                    onCancel={onSave}
                    onCopyAd={ad => this.setState({ lastCopiedAd: ad.id })}
                    columnName="Geotargeting"
                >
                    {(updateDraft, shouldShowErrors, errors, draft) => (
                        <Geotargets
                            key={this.state.lastCopiedAd}
                            value={draft.geotargets}
                            errors={errors.geotargets}
                            showErrors={shouldShowErrors}
                            profileToken={{}}
                            onChange={val => updateDraft({ geotargets: val })}
                        />
                    )}
                </BaseColumnForm>
            );
        }
    }
);
class GeoTargetingSummaryComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, error } = this.props;

        return (
            <div>
                {error ? (
                    <div>{error}</div>
                ) : (
                    <div>
                        <div className="media-plan-form__overview-table-geo-group">
                            <div>Geotargets</div>
                            <ul>{_.map(ad.geotargets, formatGeoCountry)}</ul>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export const GeoTargetingSummary = connect(geoTargetingSummarySelector)(
    GeoTargetingSummaryComponent
);

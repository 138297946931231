import _ from 'lodash';
import moment from 'moment';

import { can } from 'services/can';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';
import flags from 'containers/flags/service';
import { PermissionsMapping } from 'common/constants/role-intents';

const PLUSCO_SHOWROOM_ID = '6487683d04a1620f4c1af7f6';
const ROOT_ORG_ID = '53271100d26f9a2f917ed289';
const ADDICTIVE_ORG_ID = '53271100d26f9a2f917ed290';

export function isBillingsManagedThroughEF(campaign) {
    return campaign.billing_enabled === true;
}
export function isUnlimitedBudget(campaign) {
    return campaign.billing_enabled && campaign.campaign_budget_enabled === false;
}
export function isLimitedBillings(campaign) {
    return (
        campaign.billing_enabled &&
        campaign.billing_term === 'billable_media_cost_margin' &&
        campaign.campaign_budget_enabled
    );
}
export function isLimitedMediaSpend(campaign) {
    return (
        campaign.billing_enabled &&
        campaign.billing_term === 'billable_media_cost_markup' &&
        campaign.campaign_budget_enabled
    );
}

export function calculateThirdPartyFees(campaign) {
    let total = 0;

    _.each(campaign.third_party_fees, fee => {
        total += fee.fee;
    });

    return total;
}

export function revenueModelToBillingTerm(revenueModel) {
    const mapping = {
        disabled: 'CPM',
        cpmcpc: 'CPM',
        cpm: 'CPM',
        agencyMargin: 'billable_media_cost_margin',
        totalSpendMarkup: 'billable_media_cost_markup',
    };

    // default to CPM
    return mapping[revenueModel] || 'CPM';
}

export const RevenueModelMapping = {
    Disabled: 'disabled',
    CPMCPC: 'cpmcpc',
    AgencyMargin: 'agencyMargin',
    TotalSpendMarkup: 'totalSpendMarkup',
    CPM: 'cpm',
};

export const CampaignTypeMapping = {
    Standard: 'standard',
    CTV: 'ctv',
    DOOH: 'dooh',
};

export const CampaignAutoBudgetAllocationOptions = {
    CPA: 'cpa',
    CPC: 'cpc',
    CPCV: 'cpcv',
};

export function getRevenueModelDefaults(revenueModel) {
    const mapping = {
        default: {
            revenueModel: null,
            billing_term: 'billable_media_cost_markup',
            billing_enabled: true,
            campaign_budget_enabled: true,
            flightPacingStrategy: 'ad',
            flights: [],
        },
        disabled: {
            revenueModel: RevenueModelMapping.Disabled,
            billing_term: 'billable_media_cost_markup',
            billing_enabled: false,
            campaign_budget_enabled: false,
        },
        cpmcpc: {
            revenueModel: RevenueModelMapping.CPMCPC,
            billing_term: 'billable_media_cost_markup',
            billing_enabled: true,
            campaign_budget_enabled: false,
        },
        cpm: {
            revenueModel: RevenueModelMapping.CPM,
            billing_term: 'billable_media_cost_markup',
            billing_enabled: true,
            campaign_budget_enabled: false,
        },
        totalSpendMarkup: {
            revenueModel: RevenueModelMapping.TotalSpendMarkup,
            billing_term: 'billable_media_cost_markup',
            billing_enabled: true,
            campaign_budget_enabled: true,
        },
        agencyMargin: {
            revenueModel: RevenueModelMapping.AgencyMargin,
            billing_term: 'billable_media_cost_margin',
            billing_enabled: true,
            campaign_budget_enabled: true,
            campaign_max_total_spend_local: 0,
        },
    };

    if (!revenueModel) {
        return mapping.default;
    }

    return {
        ...mapping[revenueModel],
        // common defaults
        billing_rate: 0,
        total_billings_local: 0,
        budget_allocation_method: 'manual',
        flightPacingStrategy: 'ad',
        flights: [],
        campaign_max_total_impressions: 0,
        campaign_primary_pacing: 'spend',
    };
}

export function getFtaDisabledDefaults() {
    return {
        fta_enabled: false,
        fta_lift_version: null,
        fta_location_list: null,
        fta_management_level: null,
    };
}

export function getRevenueModelOptions(campaignType) {
    const REVENUE_MODEL_OPTIONS = [
        {
            label: 'CPM / CPC',
            value: RevenueModelMapping.CPMCPC,
            blockForTypes: [CampaignTypeMapping.CTV, CampaignTypeMapping.DOOH],
        },
        {
            label: 'CPM',
            value: RevenueModelMapping.CPM,
            blockForTypes: [CampaignTypeMapping.Standard],
        },
        { label: 'Agency margin', value: RevenueModelMapping.AgencyMargin },
        // { label: 'Total cost markup', value: 'totalSpendMarkup' },
        { label: 'Disabled', value: RevenueModelMapping.Disabled },
    ];

    if (_.isEmpty(campaignType)) {
        return REVENUE_MODEL_OPTIONS;
    }

    return REVENUE_MODEL_OPTIONS.filter(n => !_.includes(n.blockForTypes, campaignType));
}

export function getCampaignTypeOptions(orgId) {
    const result = [
        {
            value: CampaignTypeMapping.Standard,
            label: 'Standard (Mobile & Desktop)',
        },
        {
            value: CampaignTypeMapping.CTV,
            label: 'Connected TV',
        },
    ];

    if (flags.isEnabled('en_4657_dooh_campaigns')) {
        result.push({
            value: CampaignTypeMapping.DOOH,
            label: 'Digital Out-of-Home',
        });
    }

    return result;
}

export function hasConversionsRequiredWarning(campaign) {
    const allConversions = _.concat(
        _.get(campaign, 'conversions', []),
        _.get(campaign, 'advertiserConversions', [])
    );

    const noConversions = allConversions.length === 0;

    return (
        (hasAnyAdsWithCreativeRotationByConversionOptimization(campaign) ||
            isCampaignCpaOptimized(campaign)) &&
        noConversions
    );
}

export function hasAnyAdsWithCreativeRotationByConversionOptimization(campaign) {
    return (
        _.filter(
            campaign.ads,
            ad => ad.rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForConversions
        ) > 0
    );
}

export const REVENUE_MODELS = Object.values(RevenueModelMapping);

export function isCampaignCpaOptimized(campaign) {
    return (
        campaign.automaticBudgetAllocationOptimizationStrategy ===
            CampaignAutoBudgetAllocationOptions.CPA && campaign.budget_allocation_method === 'auto'
    );
}

export function canToggleStatus(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canEditCampaign(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canDuplicateCampaign() {
    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canArchiveCampaign(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function canCreateAd(campaign) {
    if (campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

export function isArchivedCampaign(campaign) {
    return campaign.isArchived;
}

export function canViewCampaignCreator() {
    return true;
}

export function getCurrentFlight(campaign) {
    return campaign.currentFlight;
}

export function getMostRecentFlight(campaign) {
    const now = moment();

    return _.findLast(campaign.flights, flight => {
        return now.isAfter(flight.end);
    });
}

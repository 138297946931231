import _ from 'lodash';

export default function selector(storeState, props) {
    const { brands, loading } = _.get(storeState, 'flexBrandsDashboard');

    return {
        brands,
        loading,
    };
}

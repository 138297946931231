import React from 'react';
import _ from 'lodash';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import { SortableChipRow } from 'widgets-v5/chip';

function Splits(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expandedItem, setExpand] = React.useState('');

    const expandItem = item => {
        expandedItem === item ? setExpand('') : setExpand(item);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { onRemoveSplit, splits, dimensionsFiltered, onAddSplit, hasDimensionsAvailable } = props;

    const updateSplits = splits => {
        const { onUpdateSplits } = props;
        if (onUpdateSplits) {
            onUpdateSplits(splits);
        }
    };

    const availableSplits = _.map(splits, split => {
        return {
            label: split.label,
            value: split.name,
            deletable: true,
        };
    });

    const selectedSplits = _.map(splits, split => split.name);

    const addSplit = dimension => {
        onAddSplit(dimension);
        setAnchorEl(null);
        setExpand('');
    };

    return (
        <Grid container alignItems="center">
            {splits.length > 0 && <Box mr={1}>Split on:</Box>}
            <SortableChipRow
                items={availableSplits}
                value={selectedSplits}
                onChange={updateSplits}
            />
            {hasDimensionsAvailable && (
                <div>
                    <PopoverMenu
                        options={dimensionsFiltered}
                        onClick={option => addSplit(option)}
                    />
                </div>
            )}
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function PopoverMenu(props) {
    const { options, onClick } = props;

    const classes = useStyles();
    const [popoverAnchor, setAnchorEl] = React.useState(null);
    const [openItems, setOpen] = React.useState({});

    const toggleOption = name => {
        setOpen({
            ...openItems,
            [name]: !openItems[name],
        });
    };

    const handleClickAddSplit = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOption = option => {
        handleClose();
        onClick(option);
    };

    const open = Boolean(popoverAnchor);
    const id = open ? 'popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} onClick={handleClickAddSplit}>
                Add Split
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={popoverAnchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <List>
                    {_.map(options, option => (
                        <React.Fragment key={option.name}>
                            {option.children.length > 0 ? (
                                <ListItem disableGutters>
                                    <ListItemButton onClick={() => toggleOption(option.name)}>
                                        <ListItemText primary={option.label} />
                                        {openItems[option.name] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                </ListItem>
                            ) : (
                                <ListItem disableGutters>
                                    <ListItemButton onClick={() => handleClickOption(option)}>
                                        <ListItemText primary={option.label} />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            {option.children.length > 0 && (
                                <Collapse in={openItems[option.name]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {_.map(option.children, child => (
                                            <ListItem
                                                disableGutters
                                                key={child.name}
                                            >
                                                <ListItemButton className={classes.nested} onClick={() => handleClickOption(child)}>
                                                    <ListItemText primary={child.label} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </React.Fragment>
                    ))}
                </List>
            </Popover>
        </div>
    );
}

export default Splits;

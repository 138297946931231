import _ from 'lodash';

function match(string, partial) {
    if (!_.isString(string)) {
        return false;
    }

    return string.indexOf(partial) > -1;
}
// standard, mraid, video, third_party_vast, native
export const TAGS = [
    {
        value: 'engagefront__tag',
        label: 'Pelmorex - Engagefront Assets',
        formats: ['standard', 'mraid', 'third_party_vast', 'native', 'video'],
        test(content) {
            return (
                match(content, 'images.cdn.engagefront.com') ||
                match(content, 'videos.cdn.engagefront.com') ||
                match(content, 'videos.engagefront.eu') ||
                match(content, 'images.engagefront.eu') ||
                match(content, 'videos.engagefront.es') ||
                match(content, 'images.engagefront.es')
            );
        },
    },
    {
        value: 'engagefront__tag__js',
        label: 'Pelmorex - Custom Creative',
        formats: ['standard', 'video', 'native', 'mraid'],
        test(content) {
            return (
                match(content, 'id="amometa"') ||
                match(content, `id='amometa'`) ||
                match(content, '<ef-creative')
            );
        },
    },
    {
        value: 'dcm__tag__standard',
        label: 'Campaign Manager 360 (DCM) - Standard Tag',
        formats: ['standard', 'native'],
        test(content) {
            return match(content, 'ddm/ad/') || match(content, 'ddm/jump/');
        },
    },
    {
        value: 'dcm__tag__js',
        label: 'Campaign Manager 360 (DCM) - JavaScript (Legacy) Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'ddm/adj/');
        },
    },
    {
        value: 'dcm__tag__ins',
        label: 'Campaign Manager 360 (DCM) - INS Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, '<ins');
        },
    },
    {
        value: 'dcm__tag__trackingad',
        label: 'Campaign Manager 360 (DCM) - Tracking Ad',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return (
                match(content, 'ddm/trackimp') ||
                match(content, 'ddm/trackimpj') ||
                match(content, 'ddm/trackclk')
            );
        },
    },
    {
        value: 'dcm__tracking__clicktracker',
        label: 'Campaign Manager 360 (DCM) - Click Tracker',
        formats: ['mraid'],
        test(content) {
            return match(content, 'ddm/clk');
        },
    },
    {
        value: 'dcm__tag__prefetch',
        label: 'Campaign Manager 360 (DCM) - VAST Pre-fetch Tag',
        formats: ['third_party_vast'],
        test(content) {
            return match(content, 'ddm/pfadx');
        },
    },
    {
        value: 'TRUSTe__certification',
        label: 'TrustArc (TRUSTe) - Privacy Certification',
        formats: ['standard', 'mraid', 'third_party_vast'],
        test(content) {
            return match(content, 'choices.truste.com/ca');
        },
    },
    {
        value: 'sizmek__tag__js',
        label: 'Sizmek (MediaMind) - Standard JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'BurstingPipe/adServer.bs');
        },
    },
    {
        value: 'sizmek__tracking__impression',
        label: 'Sizmek (MediaMind) - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'serving/adServer.bs?cn=display&c=19&mc=imp');
        },
    },
    {
        value: 'sizmek__tracking__click',
        label: 'Sizmek (MediaMind) - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'serving/adServer.bs?cn=trd&mc=click');
        },
    },
    {
        value: 'ias__tracking__impression',
        label: 'Integral (IAS) - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'pixel.adsafeprotected.com/rfw');
        },
    },
    {
        value: 'ias__tag__js',
        label: 'Integral (IAS) - JavaScript Tag',
        formats: ['mraid', 'third_party_vast'],
        test(content) {
            return match(content, 'pixel.adsafeprotected.com/jload');
        },
    },
    {
        value: 'eyereturn__tracking__impression',
        label: 'Eyereturn - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'voken.eyereturn.com/j4/pix');
        },
    },
    {
        value: 'eyereturn__tracking__click',
        label: 'Eyereturn - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'voken.eyereturn.com/j4/c');
        },
    },
    {
        value: 'eyereturn__tag__js',
        label: 'Eyereturn - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            if (/voken\.eyereturn\.com\/\?\d+/.test(content)) {
                return true;
            }

            if (/voken\.eyereturn\.com\/j4\/js\?\d+/.test(content)) {
                return true;
            }

            return false;
        },
    },
    {
        value: 'eyereturn__tracking__js',
        label: 'Eyereturn - eyeBuild JavaScript Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'quickresource.eyereturn.com/eyebuild');
        },
    },
    {
        value: 'adgear__tag__js',
        label: 'AdGear - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, '/impressions/ext/p=');
        },
    },
    {
        value: 'adgear__tracking__impression',
        label: 'AdGear - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'dcs.adgear.com/impressions/witness');
        },
    },
    {
        value: 'adgear__tracking__click',
        label: 'AdGear - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return (
                match(content, 'dcs.adgear.com/impressions/witness') ||
                match(content, 'dcs.adgear.com/clicks/witness')
            );
        },
    },
    {
        value: 'pelmorex__attribution',
        label: 'Pelmorex - Attribution',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'pixel.pelmorex.com/event');
        },
    },
    {
        value: 'moat__tracking__js',
        label: 'Moat - JavaScript Tracker',
        formats: ['mraid'],
        test(content) {
            return match(content, 'moatad.js?');
        },
    },
    {
        value: 'moat__tag__js',
        label: 'Moat - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'moatApi');
        },
    },
    {
        value: 'atlas__tag__js',
        label: 'Atlas - JavaScript Tag',
        formats: ['mraid', 'video'],
        test(content) {
            return match(content, 'ad.atdmt.com/d/a.js');
        },
    },
    {
        value: 'atlas__tracking__general',
        label: 'Atlas - Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'ad.atdmt.com/i/img');
        },
    },
    {
        value: 'markmonitor__tag__banner',
        label: 'MarkMonitor - Standard',
        formats: ['standard', 'native'],
        test(content) {
            return match(content, 's1.2mdn.net');
        },
    },
    {
        value: 'markmonitor__tag__video',
        label: 'MarkMonitor - Video',
        formats: ['video'],
        test(content) {
            return match(content, 'gcdn.2mdn.net');
        },
    },
    {
        value: 'nielsen__tracking__general',
        label: 'Nielsen - DAR Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'imrworldwide.com/cgi-bin/m?');
        },
    },
    {
        value: 'innovid__tag__vast_vendor',
        label: 'Innovid - VAST Tag',
        formats: ['third_party_vast'],
        test(content) {
            return match(content, 'rtr.innovid.com/r1');
        },
    },
    {
        value: 'speedshift__tracking__impression',
        label: 'Speed Shift Media - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'adserving.speedshiftmedia.com/p/pixel.gif');
        },
    },
    {
        value: 'speedshift__tag__js',
        label: 'Speed Shift Media - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'scripts.speedshiftmedia.com');
        },
    },
    {
        value: 'kochava__tracking__click',
        label: 'Kochava - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'control.kochava.com/v1/cpi/click');
        },
    },
    {
        value: 'gwd__tag__js',
        label: 'Google Web Designer - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="Google Web Designer"\/>/gm.test(content);
        },
    },
    {
        value: 'conversio__tag__js',
        label: 'Conversio - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="Conversio"\/>/gm.test(content);
        },
    },
    {
        value: 'contobox__tag__js',
        label: 'Contobox - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'am.contobox.com/v3/');
        },
    },
    {
        value: 'adobe__tag__js',
        label: 'Adobe Creative - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="Adobe Animate"\/>/gm.test(content);
        },
    },
    {
        value: 'html5__tag__js',
        label: 'HTML5 - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="HTML5 Creative"\/>/gm.test(content);
        },
    },
];
export const CREATIVES_VENDOR_TAGS = [
    {
        value: 'engagefront__tag',
        label: 'Pelmorex - Engagefront Assets',
        formats: ['standard', 'mraid', 'third_party_vast', 'native', 'video'],
        test(content) {
            return (
                match(content, 'images.cdn.engagefront.com') ||
                match(content, 'videos.cdn.engagefront.com') ||
                match(content, 'videos.engagefront.eu') ||
                match(content, 'images.engagefront.eu') ||
                match(content, 'videos.engagefront.es') ||
                match(content, 'images.engagefront.es')
            );
        },
    },
    {
        value: 'dcm__tag__ins',
        label: 'Campaign Manager 360 (DCM) - INS Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, '<ins');
        },
    },
    {
        value: 'engagefront__tag__js',
        label: 'Pelmorex - Custom Creative',
        formats: ['standard', 'video', 'native', 'mraid'],
        test(content) {
            return (
                match(content, 'id="amometa"') ||
                match(content, `id='amometa'`) ||
                match(content, '<ef-creative')
            );
        },
    },
    {
        value: 'dcm__tag__js',
        label: 'Campaign Manager 360 (DCM) - JavaScript Tag (Legacy)',
        formats: ['mraid'],
        test(content) {
            return match(content, 'ddm/adj/');
        },
    },
    {
        value: 'eyereturn__tag__js',
        label: 'Eyereturn - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            if (/voken\.eyereturn\.com\/\?\d+/.test(content)) {
                return true;
            }

            if (/voken\.eyereturn\.com\/j4\/js\?\d+/.test(content)) {
                return true;
            }

            return false;
        },
    },
    {
        value: 'adgear__tag__js',
        label: 'AdGear - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, '/impressions/ext/p=');
        },
    },
    {
        value: 'sizmek__tag__js',
        label: 'Sizmek (MediaMind) - Standard JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'BurstingPipe/adServer.bs');
        },
    },
    {
        value: 'dcm__tag__prefetch',
        label: 'Campaign Manager 360 (DCM) - VAST Pre-fetch Tag',
        formats: ['third_party_vast'],
        test(content) {
            return match(content, 'ddm/pfadx');
        },
    },
    {
        value: 'dcm__tag__standard',
        label: 'Campaign Manager 360 (DCM) - Standard Tag',
        formats: ['standard', 'native'],
        test(content) {
            return match(content, 'ddm/ad/');
        },
    },
    {
        value: 'moat__tag__js',
        label: 'Moat - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'moatApi');
        },
    },
    {
        value: 'atlas__tag__js',
        label: 'Atlas - JavaScript Tag',
        formats: ['mraid', 'video'],
        test(content) {
            return match(content, 'ad.atdmt.com/d/a.js');
        },
    },
    {
        value: 'markmonitor__tag__banner',
        label: 'MarkMonitor - Standard',
        formats: ['standard', 'native'],
        test(content) {
            return match(content, 's1.2mdn.net');
        },
    },
    {
        value: 'markmonitor__tag__video',
        label: 'MarkMonitor - Video',
        formats: ['video'],
        test(content) {
            return match(content, 'gcdn.2mdn.net');
        },
    },
    {
        value: 'innovid__tag__vast_vendor',
        label: 'Innovid - VAST Tag',
        formats: ['third_party_vast'],
        test(content) {
            return match(content, 'rtr.innovid.com/r1');
        },
    },
    {
        value: 'speedshift__tag__js',
        label: 'Speed Shift Media - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'scripts.speedshiftmedia.com');
        },
    },
    {
        value: 'gwd__tag__js',
        label: 'Google Web Designer - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="Google Web Designer"\/>/gm.test(content);
        },
    },
    {
        value: 'conversio__tag__js',
        label: 'Conversio - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="Conversio"\/>/gm.test(content);
        },
    },
    {
        value: 'contobox__tag__js',
        label: 'Contobox - JavaScript Tag',
        formats: ['mraid'],
        test(content) {
            return match(content, 'am.contobox.com/v3/');
        },
    },
    {
        value: 'adobe__tag__js',
        label: 'Adobe Creative - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="Adobe Animate"\/>/gm.test(content);
        },
    },
    {
        value: 'html5__tag__js',
        label: 'HTML5 - Published Creative',
        formats: ['mraid'],
        test(content) {
            return /<meta generator="HTML5 Creative"\/>/gm.test(content);
        },
    },
];
export const TRACKING_VENDOR_TAGS = [
    {
        value: 'atlas__tracking__general',
        label: 'Atlas - Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'ad.atdmt.com/i/img');
        },
    },
    {
        value: 'dcm__tag__trackingad',
        label: 'Campaign Manager 360 (DCM) - Tracking Ad',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return (
                match(content, 'ddm/trackimp') ||
                match(content, 'ddm/trackimpj') ||
                match(content, 'ddm/trackclk')
            );
        },
    },
    {
        value: 'dcm__tracking__clicktracker',
        label: 'Campaign Manager 360 (DCM) - Click Tracker',
        formats: ['mraid'],
        test(content) {
            return match(content, 'ddm/clk');
        },
    },
    {
        value: 'dcm__tag__standard',
        label: 'Campaign Manager 360 (DCM) - Standard Tag',
        formats: ['standard', 'native'],
        test(content) {
            return match(content, 'ddm/jump/');
        },
    },
    {
        value: 'eyereturn__tracking__impression',
        label: 'Eyereturn - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'voken.eyereturn.com/j4/pix');
        },
    },
    {
        value: 'eyereturn__tracking__click',
        label: 'Eyereturn - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'voken.eyereturn.com/j4/c');
        },
    },
    {
        value: 'TRUSTe__certification',
        label: 'TrustArc (TRUSTe) - Privacy Certification',
        formats: ['standard', 'mraid', 'third_party_vast'],
        test(content) {
            return match(content, 'choices.truste.com/ca');
        },
    },
    {
        value: 'sizmek__tracking__impression',
        label: 'Sizmek (MediaMind) - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'serving/adServer.bs?cn=display&c=19&mc=imp');
        },
    },
    {
        value: 'sizmek__tracking__click',
        label: 'Sizmek (MediaMind) - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'serving/adServer.bs?cn=trd&mc=click');
        },
    },
    {
        value: 'ias__tracking__impression',
        label: 'Integral (IAS) - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'pixel.adsafeprotected.com/rfw');
        },
    },
    {
        value: 'ias__tag__js',
        label: 'Integral (IAS) - JavaScript Tag',
        formats: ['mraid', 'third_party_vast'],
        test(content) {
            return match(content, 'pixel.adsafeprotected.com/jload');
        },
    },
    {
        value: 'eyereturn__tracking__js',
        label: 'Eyereturn - eyeBuild JavaScript Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'quickresource.eyereturn.com/eyebuild');
        },
    },
    {
        value: 'adgear__tracking__impression',
        label: 'AdGear - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'dcs.adgear.com/impressions/witness');
        },
    },
    {
        value: 'adgear__tracking__click',
        label: 'AdGear - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return (
                match(content, 'dcs.adgear.com/impressions/witness') ||
                match(content, 'dcs.adgear.com/clicks/witness')
            );
        },
    },
    {
        value: 'pelmorex__attribution',
        label: 'Pelmorex - Attribution',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'pixel.pelmorex.com/event');
        },
    },
    {
        value: 'moat__tracking__js',
        label: 'Moat - JavaScript Tracker',
        formats: ['mraid'],
        test(content) {
            return match(content, 'moatad.js?');
        },
    },
    {
        value: 'nielsen__tracking__general',
        label: 'Nielsen - DAR Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'imrworldwide.com/cgi-bin/m?');
        },
    },
    {
        value: 'speedshift__tracking__impression',
        label: 'Speed Shift Media - Impression Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'adserving.speedshiftmedia.com/p/pixel.gif');
        },
    },
    {
        value: 'kochava__tracking__click',
        label: 'Kochava - Click Tracker',
        formats: ['standard', 'mraid', 'video', 'third_party_vast', 'native'],
        test(content) {
            return match(content, 'control.kochava.com/v1/cpi/click');
        },
    },
];
const vendorOptionsByCreativeType = {
    standard: [],
    mraid: [],
    video: [],
    third_party_vast: [],
    native: [],
};
_.each(TAGS, tag => {
    _.each(tag.formats, format => {
        vendorOptionsByCreativeType[format].push({
            value: tag.value,
            label: tag.label,
        });
    });
});

export const vendorToPresentationMapping = {};

_.each(TAGS, tag => {
    vendorToPresentationMapping[tag.value] = tag.label;
});

export const VENDOR_TO_PRESENTATION_MAPPING = vendorToPresentationMapping;

/*
export const VENDOR_TO_PRESENTATION_MAPPING = {
    // Assets
    'engagefront__tag':  'EngageFront Hosted Assets',
    'dcm__tag__prefetch':  'DCM - VAST Pre-fetch Tag',
    'dcm__tag__standard':  'DCM - Standard Tag',
    'dcm__tag__trackingad': 'DCM - Tracking Ad',
    'dcm__tag__ins':  'DCM - INS Tag',
    'dcm__tag__js':  'DCM - JavaScript (Legacy) Tag',
    'atlas__tag__js':  'Atlas - JavaScript Tag',
    'eyereturn__tag__js':  'Eyereturn - JavaScript Tag',
    'markmonitor__tag__banner':  'MarkMonitor - Standard',
    'markmonitor__tag__video':  'MarkMonitor - Video',
    'innovid__tag__vast_vendor':  'Innovid - VAST Tag',
    'adgear__tag__js':  'AdGear - JavaScript Tag',
    'speedshift__tag__js':  'Speed Shift Media - JavaScript Tag',
    'engagefront__tag__js':  'Addictive Mobility - Custom Creative',
    'sizmek__tag__js':  'Sizmek (MediaMind) - Standard JavaScript Tag',
    'moat__tag__js': 'Moat - JavaScript Tag',
    'ias__tag__js':  'Integral (IAS) - JavaScript Tag',

    // Attribution
    'TRUSTe__certification':  'TrustArc (TRUSTe) - Privacy Certification',
    'pelmorex__attribution':  'Pelmorex - Attribution',

    // Tracker
    'dcm__tracking__clicktracker':  'DCM - Click Tracker',
    'eyereturn__tracking__click':  'Eyereturn - Click Tracker',
    'eyereturn__tracking__impression':  'Eyereturn - Impression Tracker',
    'eyereturn__tracking__js':  'Eyereturn - eyeBuild JavaScript Tracker',
    'adgear__tracking__impression':  'AdGear - Impression Tracker',
    'adgear__tracking__click':  'AdGear - Click Tracker',
    'nielsen__tracking__general':  'Nielsen - DAR Tracker',
    'speedshift__tracking__impression':  'Speed Shift Media - Impression Tracker',
    'atlas__tracking__general':  'Atlas - Tracker',
    'kochava__tracking__click':  'Kochava - Click Tracker',
    'ias__tracking__impression':  'Integral (IAS) - Impression Tracker',
    'moat__tracking__js':  'Moat - JavaScript Tracker',
    'sizmek__tracking__click':  'Sizmek (MediaMind) - Click Tracker',
    'sizmek__tracking__impression':  'Sizmek (MediaMind) - Impression Tracker',
};
*/

export const VENDOR_OPTIONS_BY_CREATIVE_TYPE = vendorOptionsByCreativeType;

/*
export const VENDOR_OPTIONS_BY_CREATIVE_TYPE = {

    standard: [
        // Assets
        { value: 'engagefront__tag', label: 'EngageFront Hosted Assets' },
        { value: 'dcm__tag__standard', label: 'DCM - Standard Tag' },
        { value: 'dcm__tag__trackingad', label: 'DCM - Tracking Ad' },
        { value: 'dcm__tag__ins', label: 'DCM - INS Tag' },
        { value: 'markmonitor__tag__banner', label: 'MarkMonitor - Standard' },

        // Attribution
        { value: 'TRUSTe__certification', label: 'TrustArc (TRUSTe) - Privacy Certification', clearableValue: false },
        { value: 'pelmorex__attribution', label: 'Pelmorex - Attribution' },

        // Tracker
        { value: 'dcm__tracking__clicktracker', label: 'DCM - Click Tracker' },
        { value: 'eyereturn__tracking__click', label: 'Eyereturn - Click Tracker' },
        { value: 'eyereturn__tracking__impression', label: 'Eyereturn - Impression Tracker' },
        { value: 'eyereturn__tracking__js', label: 'Eyereturn - eyeBuild JavaScript Tracker' },
        { value: 'adgear__tracking__impression', label: 'AdGear - Impression Tracker' },
        { value: 'adgear__tracking__click', label: 'AdGear - Click Tracker' },
        { value: 'speedshift__tracking__impression', label: 'Speed Shift Media - Impression Tracker' },
        { value: 'nielsen__tracking__general', label: 'Nielsen - DAR Tracker' },
        { value: 'atlas__tracking__general', label: 'Atlas - Tracker' },
        { value: 'kochava__tracking__click', label: 'Kochava - Click Tracker' },
        { value: 'ias__tracking__impression', label: 'Integral (IAS) - Impression Tracker' },
        { value: 'sizmek__tracking__click', label: 'Sizmek (MediaMind) - Click Tracker' },
        { value: 'sizmek__tracking__impression', label: 'Sizmek (MediaMind) - Impression Tracker' },
    ],
    mraid: [
        // Assets
        { value: 'engagefront__tag', label: 'EngageFront Hosted Assets' },
        { value: 'dcm__tag__standard', label: 'DCM - Standard Tag' },
        { value: 'dcm__tag__trackingad', label: 'DCM - Tracking Ad' },
        { value: 'dcm__tag__ins', label: 'DCM - INS Tag' },
        { value: 'dcm__tag__js', label: 'DCM - JavaScript (Legacy) Tag' },
        { value: 'atlas__tag__js', label: 'Atlas - JavaScript Tag' },
        { value: 'eyereturn__tag__js', label: 'Eyereturn - JavaScript Tag' },
        { value: 'markmonitor__tag__banner', label: 'MarkMonitor - Standard' },
        { value: 'adgear__tag__js', label: 'AdGear - JavaScript Tag' },
        { value: 'speedshift__tag__js', label: 'Speed Shift Media - JavaScript Tag' },
        { value: 'engagefront__tag__js', label: 'Addictive Mobility - Custom Creative' },
        { value: 'sizmek__tag__js', label: 'Sizmek (MediaMind) - Standard JavaScript Tag' },
        { value: 'moat__tag__js', label: 'Moat - JavaScript Tag' },
        { value: 'ias__tag__js', label: 'Integral (IAS) - JavaScript Tag' },

        // Attribution
        { value: 'TRUSTe__certification', label: 'TrustArc (TRUSTe) - Privacy Certification' },
        { value: 'pelmorex__attribution', label: 'Pelmorex - Attribution' },

        // Tracker
        { value: 'dcm__tracking__clicktracker', label: 'DCM - Click Tracker' },
        { value: 'eyereturn__tracking__click', label: 'Eyereturn - Click Tracker' },
        { value: 'eyereturn__tracking__impression', label: 'Eyereturn - Impression Tracker' },
        { value: 'eyereturn__tracking__js', label: 'Eyereturn - eyeBuild JavaScript Tracker' },
        { value: 'adgear__tracking__impression', label: 'AdGear - Impression Tracker' },
        { value: 'adgear__tracking__click', label: 'AdGear - Click Tracker' },
        { value: 'speedshift__tracking__impression', label: 'Speed Shift Media - Impression Tracker' },
        { value: 'nielsen__tracking__general', label: 'Nielsen - DAR Tracker' },
        { value: 'atlas__tracking__general', label: 'Atlas - Tracker' },
        { value: 'kochava__tracking__click', label: 'Kochava - Click Tracker' },
        { value: 'ias__tracking__impression', label: 'Integral (IAS) - Impression Tracker' },
        { value: 'moat__tracking__js', label: 'Moat - JavaScript Tracker' },
        { value: 'sizmek__tracking__click', label: 'Sizmek (MediaMind) - Click Tracker' },
        { value: 'sizmek__tracking__impression', label: 'Sizmek (MediaMind) - Impression Tracker' },
    ],
    video: [
        // Assets
        { value: 'engagefront__tag', label: 'EngageFront Hosted Assets' },
        { value: 'dcm__tag__prefetch', label: 'DCM - VAST Pre-fetch Tag' }, // Video
        { value: 'dcm__tag__standard', label: 'DCM - Standard Tag' },
        { value: 'dcm__tag__trackingad', label: 'DCM - Tracking Ad' },
        { value: 'dcm__tag__ins', label: 'DCM - INS Tag' },
        { value: 'markmonitor__tag__banner', label: 'MarkMonitor - Standard' },
        { value: 'markmonitor__tag__video', label: 'MarkMonitor - Video' }, // Video
        { value: 'innovid__tag__vast_vendor', label: 'Innovid - VAST Tag' }, // Video

        // Attribution
        { value: 'TRUSTe__certification', label: 'TrustArc (TRUSTe) - Privacy Certification' },
        { value: 'pelmorex__attribution', label: 'Pelmorex - Attribution' },

        // Tracker
        { value: 'dcm__tracking__clicktracker', label: 'DCM - Click Tracker' },
        { value: 'eyereturn__tracking__click', label: 'Eyereturn - Click Tracker' },
        { value: 'eyereturn__tracking__impression', label: 'Eyereturn - Impression Tracker' },
        { value: 'eyereturn__tracking__js', label: 'Eyereturn - eyeBuild JavaScript Tracker' },
        { value: 'adgear__tracking__impression', label: 'AdGear - Impression Tracker' },
        { value: 'adgear__tracking__click', label: 'AdGear - Click Tracker' },
        { value: 'speedshift__tracking__impression', label: 'Speed Shift Media - Impression Tracker' },
        { value: 'nielsen__tracking__general', label: 'Nielsen - DAR Tracker' },
        { value: 'atlas__tracking__general', label: 'Atlas - Tracker' },
        { value: 'kochava__tracking__click', label: 'Kochava - Click Tracker' },
        { value: 'ias__tracking__impression', label: 'Integral (IAS) - Impression Tracker' },
        { value: 'sizmek__tracking__click', label: 'Sizmek (MediaMind) - Click Tracker' },
        { value: 'sizmek__tracking__impression', label: 'Sizmek (MediaMind) - Impression Tracker' },
    ],
    third_party_vast: [
        // Assets
        { value: 'engagefront__tag', label: 'EngageFront Hosted Assets' },
        { value: 'dcm__tag__prefetch', label: 'DCM - VAST Pre-fetch Tag' }, // Video
        { value: 'dcm__tag__standard', label: 'DCM - Standard Tag' },
        { value: 'dcm__tag__trackingad', label: 'DCM - Tracking Ad' },
        { value: 'dcm__tag__ins', label: 'DCM - INS Tag' },
        { value: 'dcm__tag__js', label: 'DCM - JavaScript (Legacy) Tag' },
        { value: 'markmonitor__tag__banner', label: 'MarkMonitor - Standard' },
        { value: 'markmonitor__tag__video', label: 'MarkMonitor - Video' }, // Video
        { value: 'innovid__tag__vast_vendor', label: 'Innovid - VAST Tag' }, // Video
        { value: 'adgear__tag__js', label: 'AdGear - JavaScript Tag' },
        { value: 'speedshift__tag__js', label: 'Speed Shift Media - JavaScript Tag' },
        { value: 'engagefront__tag__js', label: 'Addictive Mobility - Custom Creative' },
        { value: 'moat__tag__js', label: 'Moat - JavaScript Tag' },

        // Attribution
        { value: 'TRUSTe__certification', label: 'TrustArc (TRUSTe) - Privacy Certification' },
        { value: 'pelmorex__attribution', label: 'Pelmorex - Attribution' },

        // Tracker
        { value: 'dcm__tracking__clicktracker', label: 'DCM - Click Tracker' },
        { value: 'eyereturn__tracking__click', label: 'Eyereturn - Click Tracker' },
        { value: 'eyereturn__tracking__impression', label: 'Eyereturn - Impression Tracker' },
        { value: 'eyereturn__tracking__js', label: 'Eyereturn - eyeBuild JavaScript Tracker' },
        { value: 'adgear__tracking__impression', label: 'AdGear - Impression Tracker' },
        { value: 'adgear__tracking__click', label: 'AdGear - Click Tracker' },
        { value: 'speedshift__tracking__impression', label: 'Speed Shift Media - Impression Tracker' },
        { value: 'nielsen__tracking__general', label: 'Nielsen - DAR Tracker' },
        { value: 'atlas__tracking__general', label: 'Atlas - Tracker' },
        { value: 'kochava__tracking__click', label: 'Kochava - Click Tracker' },
        { value: 'ias__tracking__impression', label: 'Integral (IAS) - Impression Tracker' },
        { value: 'sizmek__tracking__click', label: 'Sizmek (MediaMind) - Click Tracker' },
        { value: 'sizmek__tracking__impression', label: 'Sizmek (MediaMind) - Impression Tracker' },
    ],
    native: [
        // Assets
        { value: 'engagefront__tag', label: 'EngageFront Hosted Assets' },
        { value: 'dcm__tag__standard', label: 'DCM - Standard Tag' },
        { value: 'dcm__tag__trackingad', label: 'DCM - Tracking Ad' },
        { value: 'dcm__tag__ins', label: 'DCM - INS Tag' },
        { value: 'markmonitor__tag__banner', label: 'MarkMonitor - Standard' },

        // Attribution
        { value: 'TRUSTe__certification', label: 'TrustArc (TRUSTe) - Privacy Certification' },
        { value: 'pelmorex__attribution', label: 'Pelmorex - Attribution' },

        // Tracker
        { value: 'dcm__tracking__clicktracker', label: 'DCM - Click Tracker' },
        { value: 'eyereturn__tracking__click', label: 'Eyereturn - Click Tracker' },
        { value: 'eyereturn__tracking__impression', label: 'Eyereturn - Impression Tracker' },
        { value: 'eyereturn__tracking__js', label: 'Eyereturn - eyeBuild JavaScript Tracker' },
        { value: 'adgear__tracking__impression', label: 'AdGear - Impression Tracker' },
        { value: 'adgear__tracking__click', label: 'AdGear - Click Tracker' },
        { value: 'speedshift__tracking__impression', label: 'Speed Shift Media - Impression Tracker' },
        { value: 'nielsen__tracking__general', label: 'Nielsen - DAR Tracker' },
        { value: 'atlas__tracking__general', label: 'Atlas - Tracker' },
        { value: 'kochava__tracking__click', label: 'Kochava - Click Tracker' },
        { value: 'ias__tracking__impression', label: 'Integral (IAS) - Impression Tracker' },
        { value: 'sizmek__tracking__click', label: 'Sizmek (MediaMind) - Click Tracker' },
        { value: 'sizmek__tracking__impression', label: 'Sizmek (MediaMind) - Impression Tracker' },
    ],
};
*/

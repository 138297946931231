import _ from 'lodash';
import React from 'react';


import FormField from 'widgets-v5/form-field';
import AudienceSegmentModal from 'containers/audience-segment-picker/modules/audience-segment-modal';
import Overlay from 'widgets-v5/overlay';

class Audience extends React.Component {
    static defaultProps = {
        adPresetKeyCounter: null,
    };

    render() {
        const {
            formData,
            errors,
            showErrors,
            hideAudienceSegments,
            tacticsEnabled,
            audienceRates,
            campaignId,
            shouldHideFlexSegments,
            isCTVCampaign,
            isDOOHCampaign,
        } = this.props;

        // const shouldShowAudienceOverlay = hasAnyPois(formData);
        // TODO: Temporary Change for Campaign: 7619
        const shouldShowAudienceOverlay = false;

        return (
            <div className="ef5-ad-setup-form-audience__wrapper">
                {!tacticsEnabled && !hideAudienceSegments && (
                    <Overlay
                        disabled
                        message="Audiences are not available when POI Segments are selected."
                        show={shouldShowAudienceOverlay}
                    >
                        <FormField
                            description=""
                            errors={errors.audiences}
                            showErrors={showErrors}
                            isRequired={false}
                        >
                            <div>
                                <AudienceSegmentModal
                                    platform={formData.platform}
                                    onChangeSegments={this.props.onChangeSegments}
                                    audiences={_.map(formData.audiences, String)}
                                    audienceExclude={_.map(formData.audience_exclude, String)}
                                    audienceRates={audienceRates}
                                    geo_targeting_settings={
                                        !shouldHideFlexSegments
                                            ? formData.geo_targeting_settings
                                            : null
                                    }
                                    campaignId={campaignId}
                                    isCTVCampaign={isCTVCampaign}
                                    isDOOHCampaign={isDOOHCampaign}
                                />
                            </div>
                        </FormField>
                    </Overlay>
                )}
            </div>
        );
    }
}

export default Audience;

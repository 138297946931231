const cities = [
    /* 1 */
    {
        _id: 'PE',
        cities: [
            'PRINCE EDWARD ISLAND',
            'HOPE RIVER',
            'BAY FORTUNE',
            'LOT 62',
            'MALPEQUE BAY',
            'EMERALD',
            'MAYFIELD',
            'HAMPTON',
            'CHARLOTTETOWN PEI',
            'ST PETERS',
            'KENNINGSTON',
            'FORT AUGUSTUS',
            'MILLVIEW',
            'PRINCETOWN',
            'PARKDALE',
            'CAPE TRAVERSE',
            'SUMMERSIDE PEI',
            'NORTH CAPE',
            'STANHOPE (PRINCE EDWARD ISLAND)',
            'NEW ANNAN',
            'ORWELL COVE',
            'GRANDVIEW',
            'DUNSTAFFNAGE',
            'MOUNT MELLICK',
            'CHARLOTETOWN',
            'BETHEL',
            "PROFIT'S CORNER",
            'MOUNT HERBERT',
            'LOT 63',
            'EBENEZER',
            'FRENCH RIVER',
            'ST. ANDREWS',
            'BAYVIEW',
            'MERMAID',
            'CHEPSTOW',
            'CHARLETTETOWN',
            'NORTH WILSHIRE',
            'DARNLEY',
            'WINSLOWE',
            'ST. PETERS BAY',
            'OLEARY',
            'JOHNSTONS RIVER',
            'HAZELBROOK',
            'GLASGOW RD',
            'SPRINGFIELD WEST',
            'BRACKLEY',
            'WOODSTOCK',
            'PARK CORNER',
            'CANOE COVE',
            'WINSLOW',
            'ST PETERS BAY',
            'LONG CREEK',
            'SCOTCHFORT',
            'WELLINGTON',
            'BLOOMFIELD',
            'SUMMERSIDE',
            'RUSTICOVILLE',
            'MEADOWBANK',
            'VILLAGE GREEN',
            'WARREN GROVE',
            'EAST ROYALTY',
            'MILTON',
            'RICHMOND',
            'WATERSIDE',
            'BRACKLEY BEACH',
            'EBENZER',
            'FAIRVIEW',
            'DONAGH',
            'BIDEFORD',
            'ST CATHERINES',
            'BRUDENELL',
            'NORTH MILTON',
            'NORTH WINSLOE',
            'ALEXANDRA',
            'MILTON STATION',
            'ARGYLE SHORE',
            'NEW DOMINION',
            'SAINT CATHERINES',
            'NORTH RUSTICO',
            'UNION ROAD',
            'SPRINGVALE',
            'GREEN GABLES',
            'ELDON',
            'MOUNT ALBION',
            'ST-LOUIS',
            'VICTORIA',
            'WHEATLEY RIVER',
            'CRAPAUD',
            'ST. PETERS',
            'BREADALBANE',
            'VERNON BRIDGE',
            'BONSHAW',
            'CHARLOTTEOTOWN',
            'WOOD ISLANDS',
            'KINGS',
            'WEST ROYALTY',
            'TOLL-FREE',
            'DONAGH RD',
            'NORTH WILTSHIRE',
            'MURRAY HARBOUR',
            'AUBURN',
            'VERNON RIVER',
            'POWNAL',
            'BUNBURY',
            'CENTRAL BEDEQUE',
            'TOLL FREE',
            'SOUTH WINSLOE',
            'BUNBURY RD',
            'STANLEY BRIDGE',
            'SCOTCHFORD',
            'COVEHEAD',
            'STRATFORD',
            'SHEDIAC',
            'WELLINGTON STATION',
            'PRINCE',
            'ELLERSLIE',
            'MIMINEGASH',
            'CORNWALL',
            'SOUTH LAKE',
            'CUMBERLAND',
            'ROLLO BAY',
            'BEDEQUE',
            'KILMUIR',
            'CLYDE RIVER',
            'CAVENDISH',
            'LAKE VERDE',
            'ALMA',
            'BELLE RIVER',
            'OYSTER BED BRIDGE',
            'PLEASANT VALLEY',
            'MISCOUCHE',
            'PICTOU',
            'WINSLOE',
            'ELMIRA',
            'KINKORA',
            '',
            'BORDEN-CARLETON',
            'NEW LONDON',
            'MT STEWART',
            'DEBLOIS',
            'SHERBROOKE',
            'RUSTICO',
            'ALBERTON',
            'MURRAY RIVER',
            'TIGNISH',
            'CARDIGAN',
            'WINSLOE SOUTH',
            'RICE POINT',
            'HUNTER RIVER',
            'UIGG',
            'LITTLE YORK',
            'BELFAST',
            'NINE MILE CREEK',
            'MARSHFIELD',
            'HARRINGTON',
            'NEW GLASGOW',
            'YORK',
            'TRAVELLERS REST',
            'ST-PETERS BAY',
            'SLEMON PARK',
            'SAINT PETERS BAY',
            'LENNOX ISLAND',
            'MALPEQUE',
            'KNUTSFORD',
            'LITTLE SANDS',
            'FREETOWN',
            'COLEMAN',
            'ELMWOOD',
            'TYNE VALLEY',
            'CHARLOTTETOWN',
            'WINDSOR',
            'BLOOMFIELD STATION',
            'ELMSDALE',
            'COVEHEAD HARBOUR',
            "O'LEARY",
            'CHARLOTTETOWN CHARLOTTETOWN',
            'MOUNT STEWART',
            'LOT 37',
            'CHARLOTTETOWN MALL',
            'ANGLO RUSTICO',
            'NEW HAVEN',
            'SOURIS',
            'FERNWOOD',
            'DOWN EAST MALL',
            'UNIONVALE',
            'MORELL',
            'MONTAGUE',
            'KENSINGTON',
            'PLESANT VALLEY',
            'ALBANY',
            'GEORGETOWN',
            'ST LOUIS',
        ],
    },

    /* 2 */
    {
        _id: 'NB',
        cities: [
            'SCOTCH SETTLEMENT',
            'CARLOW',
            'SOUTH BRANCH',
            'BLOOMFIELD',
            'WHITE HEAD ISLAND',
            'ST DAVID RIDGE',
            'CALDERS HEAD',
            'BLISSVILLE',
            'PRINCESS PARK',
            'DURHAM',
            'FIELDING',
            'CUMMINGS COVE',
            'CARLETON',
            'SUNNYSIDE BEACH',
            'BARTIBOG BRIDGE',
            'SUMMERFIELD',
            'HARVEY',
            'BLUE BELL',
            'COVERDALE',
            'LONG CREEK',
            'MISCOU ISLAND',
            'STYMIEST',
            'GREENHILL',
            'MIDDLE RIVER',
            'CENTRAL HAINESVILLE',
            'ROAD',
            'NOUVEAU-BRUNSWICK',
            'KINGSCLEAR',
            'KINGS LANDING HISTORICAL SETTL',
            'SAINT GEORGE',
            'ST STEPHENS',
            'SCOTCH SETTLEMENT YORK CO',
            'MOBILE',
            'RED BANK QUEENS CO',
            'RIVERBANK CARLETON CO',
            'SAINT-THOMAS-DE-KENT',
            "L'ETETE",
            'GLOUCESTER JUNCTION',
            'GOOD CORNER',
            'LOWER CAMBRIDGE',
            'WESTCOCK',
            'CENTRAL HAMPSTEAD',
            'MILL COVE',
            'LONG SETTLEMENT',
            'GLENWOOD KINGS CO',
            'MINISTERS ISLAND',
            'SAINT-JOSEPH-DE-MADAWASKA',
            "VAL D'AMOUR",
            'EDGETTS LANDING',
            'NB',
            'SAINT LEONARD',
            'LITTLE RIDGE',
            'DUNSINANE',
            'LITTLE BARTIBOG',
            'SAINT-JOSEPH-DE-KENT',
            'OXBOW',
            'ST. BASILE',
            'SAINT-FRANCOIS',
            'CENTRAL WATERVILLE',
            'MECHANIC SETTLEMENT',
            'TRACADIE-SHEILLA',
            'HAWKSHAW',
            "BURTT'S CORNER",
            'NEWCASTLE CREEK',
            'NORTH FORKS',
            'HACHEYVILLE',
            'GRAND-SAULT / GRAND-FALLS',
            'DUFFERIN',
            'MEADOW BROOK',
            'RICHMOND SETTLEMENT',
            'ASHLAND',
            'GARDNER CREEK',
            'ELMSVILLE',
            'UNIVERSITE DE MONCTON',
            'CONNELL',
            'BIG COVE',
            'DARMOUTH',
            'NEW BANDON NORTHUMB CO',
            'SORMANY',
            'NICTAU',
            'NEW BRUNSWICK (NB)',
            'PETICODIAC',
            'SNIDER MOUNTAIN',
            'CROSS CREEK',
            'CLARKS CORNER',
            'WOOD POINT',
            'BON ACCORD',
            'NASHWAAK BRIDGE',
            'MURRAY CORNER',
            'CAISSIE ROAD',
            'COLLETTE',
            'UPPER SACKVILLE',
            'BEERSVILLE',
            'FRENCH LAKE',
            'LEECH',
            'PRINCE OF WALES',
            'PAROISSE DE SAINTE-MARIE',
            'PONT-LAFRANCE',
            'CROCKER HILL',
            'CAISSIE CAPE',
            'SAINTE-MARIE SAINT RAPHAEL',
            'DUNDAS',
            'ESCUMINAC',
            'ST-FRANCOIS',
            'POINTE DIXON POINT',
            'SAINT-PONS',
            'CASSILIS',
            'VILLAGE-SAINT-LAURENT',
            'WILLIAMSTOWN',
            'GLEN LEVIT',
            'NEW BANDON',
            'HONEYDALE',
            'SKIFF LAKE',
            'FREDERICKTON',
            'FREDERICTON EXHIBITION GROUNDS',
            'LAVILLETTE',
            'BAY DU VIN',
            'OTTER CREEK',
            'MURRAY SETTLEMENT',
            'PORTER COVE',
            'TRACY',
            'WEST BRANCH',
            "ESTEY'S BRIDGE",
            'KINGSLEY',
            'UPPER SALMON CREEK',
            'WARDS CREEK',
            'QUISPAMSIE',
            'PORTAGE VALE',
            'EDMUNSTON',
            'BURNT CHURCH FIRST NATION',
            'LOWER MILLSTREAM',
            'BRONSON SETTLEMENT',
            'CARTERS POINT',
            'GRANDE DIGUE',
            'WIRRAL',
            'GREATER MONCTON - KENT COUNTY',
            'SAINT STEPHEN',
            'UPPER BRIGHTON',
            'SAINT ANDREWS',
            'GRAND-SAULT',
            'POINTE A BOULEAU',
            'LEPREAU',
            'DEERVILLE',
            'CAINS RIVER',
            'LITTLE RIVER ALBERT CO',
            'SHEFFIELD',
            'COTEAU ROAD',
            'SAINTE-ANNE GLOUCESTER CO',
            'CAMBRIDGE-NARROWS',
            'CLIFTON',
            'BASSWOOD RIDGE',
            'THREE TREE CREEK',
            'CAMBELLTON',
            'GROVE HILL',
            'LAKEVILLE CARLETON CO',
            'LAKE GEORGE',
            'SAINT BERESFORD',
            'THE GLADES',
            'BLACK RIVER',
            'BELLEFOND',
            'CAITHNESS',
            'CAMPBELLTON',
            'CHATHAM',
            'GALLAGHER RIDGE',
            'UPTOWN FREDERICTON',
            'JOHNSTON',
            'BOIS-BLANC',
            'MAPLE GLEN',
            'UPPER BLACKVILLE',
            'UPPER KINTORE',
            'RICHIBUCTO',
            'LOWER ST MARYS',
            'EAST COLDSTREAM',
            'LOWER COVE',
            'BARNESVILLE',
            'HILLANDALE',
            'PARKINDALE',
            'EEL RIVER BAR FIRST NATION',
            'MAYFIELD',
            'GRANDE ANSE',
            'WATERVILLE-SUNBURY',
            'KINGSTON NB',
            'STUART TOWN',
            'CORNHILL',
            'SPRINGFIELD KINGS CO',
            'VALLEY ROAD',
            'WOODMANS POINT',
            'FAIRFIELD',
            'LOWER COVERDALE',
            'RICHMOND CORNER',
            'BAXTERS CORNER',
            'RICHIBUCTO PARISH',
            'LOSIER SETTLEMENT',
            'FREDERICTON NO CHARGE-DIAL',
            'SHEDIAC RIVER',
            'BRISTOL',
            'BAIE DE PETIT-POKEMOUCHE',
            'MAZEROLLE SETTLEMENT',
            'NOONAN',
            'ROGERSVILLE-OUEST',
            'ST-ANDREE',
            'ST MARGARETS',
            'NOTRE-DAME-DES-ERABLES',
            'ACADIEVILLE',
            'TAY CREEK',
            'MCLAUGHLIN',
            'SAINT-LAURENT NORD',
            'SHEDIAC BRIDGE',
            'MOULIN MORNEAULT',
            'ANFIELD',
            'ST. JOHN',
            'POKEMOUCHE',
            'BARTER SETTLEMENT',
            'LEVERVILLE',
            'PRINCE WILLIAM',
            'ST-QUENTIN',
            'HOPEWELL CAPE',
            'WATERVILLE CARLETON',
            'HARVEY STATION',
            'SHEMOGUE',
            'APOHAQUI',
            'HAY SETTLEMENT',
            'BRIGHTON',
            'WICKLOW',
            'LONG REACH',
            'BLOOMFIELD STATION',
            'GAUVREAU',
            'BAIE VERTE',
            'LANDRY OFFICE',
            'PORC',
            'ST-JEAN-BAPTISTE',
            'FAIRHAVEN',
            'JEWETTS MILLS',
            'DUGAS',
            'BERWICK',
            'MOOSEHORN CREEK',
            'LAMBERTVILLE',
            'HARTFORD',
            'SPRINGFIELD',
            'MAIN RIVER',
            'SOUTH ESK',
            'SHEDIAC PARISH',
            'NEW LINE',
            'LITTLE SALMON RIVER WEST',
            'ST-STEPEN',
            'MELROSE',
            'ST MARTINS NORTH',
            'PABINEAU FIRST NATION',
            'FUNDY NATIONAL PARK',
            'ST. GEORGE',
            'POINTE-DES-ROBICHAUD',
            'LETANG',
            'MACDOUGALL SETTLEMENT',
            'MIDDLE HAINESVILLE',
            'CANAL',
            'SARAHS LANE',
            'TRACADIE SHEILA',
            'BEAUBASSIN EAST',
            'WAYERTON',
            'SOUTH TETAGOUCHE',
            'KEDGWICK NORD',
            'SIMONDS SAINT JOHN',
            'NACKAWIC',
            'RED BANK',
            'ROBICHAUD SETTLEMENT',
            'BARRYVILLE',
            'CUMBERLAND BAY',
            'NOTRE DAME DE LOURDES',
            'MILLVILLE',
            'MONCTON AREA',
            'GREATER MONCTON',
            'LAKEVILLE',
            'LYTTLETON',
            'LORNE',
            'CHAPLIN ISLAND ROAD',
            'RIVERBANK',
            'EEL GROUND',
            'WATERVILLE',
            'POINTE-ALEXANDRE',
            'ALDERWOOD',
            'SAINT-SIMON',
            'INKERMAN',
            'EEL RIVER LAKE',
            'BASS RIVER',
            'MALTEMPEC',
            'SIMONDS',
            'KEDGWICK SUD',
            'KEDGWICK RIVER',
            'CAP-PELE',
            'GALLOWAY',
            'FAIRVALE',
            'PEMBROKE',
            'DUNDEE',
            'PETITCODIAC EAST',
            'ROSEDALE',
            'EEL RIVER BAR',
            'MCINTOSH HILL',
            'WAASIS',
            'MISCOU',
            'WAKEFIELD',
            'POKESUDIE',
            'JOHNVILLE',
            'SAINT-LOUIS DE KENT',
            'CHARTERS SETTLEMENT',
            "LAMBERT'S COVE",
            'CLOVERDALE',
            'AVONDALE',
            'MOOSE MOUNTAIN',
            'GREENFIELD',
            'GRAND DIGUE',
            'LINTON CORNER',
            'SAINT-MARTIN-DE-RESTIGOUCHE',
            'ARTHURETTE',
            'BLACK RIVER BRIDGE',
            'RICHIBOUCTOU-VILLAGE',
            'MADAWASKA MALISEET FRST NATION',
            'LOWER KNOXFORD',
            'CROMBIE SETTLEMENT',
            'LOWER QUEENSBURY',
            'ALBERT',
            'PLYMOUTH',
            'NORTONDALE',
            'KESWICK',
            'ZEALAND',
            'DUMFRIES',
            'KARS',
            'GRAND-SAULT GRAND FALLS',
            'MIDGIC',
            'MAISONNETTE',
            'FLUME RIDGE',
            'BATH',
            'SUSSEX EAST',
            'CASSIDY LAKE',
            'COLLINA',
            'SOUTHAMPTON',
            'BELLEISLE CREEK',
            'BLACKVILLE',
            'BAS-PAQUETVILLE',
            'ISLAND VIEW',
            'DARLINGS ISLAND',
            'SERVING SOUTH EASTERN NB',
            'MORRISDALE',
            'HATFIELD POINT',
            'ST-ISIDORE',
            'WHEATON SETTLEMENT',
            'HARTLAND NB',
            'SAINTE-MARIE-DE-KENT',
            'BACK BAY',
            'BALMORAL PARISH',
            'HEAD OF MILLSTREAM',
            'ST-LOUIS',
            'NELSON-MIRAMICHI',
            'SOUTHESK',
            'INTERVALE',
            'HARCOURT',
            'PETIT-CAP',
            'MUNDLEVILLE',
            'MCLEOD HILL',
            'SAINT-PAUL',
            'FORD BANK',
            'SOMERVILLE',
            'UPPER QUEENSBURY',
            'SMITHTOWN',
            'PLACE CARTIER / CO-OP MALL',
            'JOLICURE',
            'SECOND NORTH RIVER',
            'SAINT-SAUVEUR',
            'TRACEY MILLS',
            'LOWER CAPE',
            'TROIS-RUISSEAUX',
            'HARDWOOD RIDGE',
            'WOODSTOCK FIRST NATION',
            'WHITES COVE',
            'YOUNGS COVE',
            'TOBIQUE FIRST NATION',
            'NEW HORTON',
            'GEARY',
            'LOWER BRIGHTON',
            'HOYT',
            'SIEGAS',
            'CELLULAR',
            'JEMSEG',
            'SAINT-ANDRE RD',
            'MCLEODS',
            'HEATHLAND',
            'MOUTH OF KESWICK',
            'JACKSONTOWN',
            'GLENCOE',
            'BEAVER DAM',
            'SAINT-JOHN',
            'BEAVER HARBOUR',
            'GARNETT SETTLEMENT',
            'CHAMBERLAIN SETTLEMENT',
            'BIG RIVER',
            'SAUMAREZ',
            'BEAVERBROOK',
            'LAGACEVILLE',
            'HAUTE-ABOUJAGANE',
            'SAINT-IRENEE',
            "MANN'S MOUNTAIN",
            'CARAQUET PARISH',
            'BANNON',
            'COLPITTS SETTLEMENT',
            'TRACADIE',
            'DIEPPE',
            'NEW SCOTLAND',
            'MCKEES MILLS',
            'GUELPH',
            'SMITH CROSSING',
            'UPPER DERBY',
            'PASSEKEAG',
            'MCNAMEE',
            'CANTONS DES BASQUES',
            'GLASSVILLE',
            'VICTORIA CORNER',
            'TETAGOUCHE FALLS',
            'GLENWOOD',
            'DOYLES BROOK',
            'RENOUS',
            'RED BANK RESERVE',
            'NASH CREEK',
            'LOWER KINGSCLEAR',
            'POINT DE BUTE',
            'VILLAGE-DES-POIRIER',
            'BAIE-SAINTE-ANNE',
            'CANOOSE',
            'SHEPODY ALBERT CO',
            'CAMPBELTON',
            'LOCKSTEAD',
            'LIMESTONE',
            "NB'",
            'LAKEVILLE CORNER',
            'FOSTERVILLE',
            'CHOCOLATE COVE',
            'INKERMAN FERRY',
            "ST JOHN'S",
            'VAL-DOUCET',
            'SAINT-LEOLIN',
            'DUNLOP',
            'BEARDSLEY',
            'BROWNS YARD',
            'POINTE-VERTE',
            'SAINT-FRANCOIS-DE-MADAWASKA',
            'SPEERVILLE',
            'PETIT-ROCHER-NORD',
            'WILMOT',
            'TREMBLAY',
            'PETITCODIAC',
            'KIRKLAND',
            'BEACONSFIELD',
            'GASPEREAU FORKS',
            'NASONWORTH',
            'DEBEC',
            'ACADIE SIDING',
            'AULAC',
            'LOWER NEWCASTLE',
            'BEDELL',
            'GLENVALE',
            'SAINT-GEORGES',
            'ST-LEONARD',
            'SMITHFIELD',
            'WELDON',
            'LAC BAKER',
            'OAK HAVEN',
            'MACTAQUAC',
            'VILLAGE BLANCHARD',
            'MIRAMICHI ROAD',
            'LOWER NORTON',
            'BEAR ISLAND',
            'PORTAGE ST-LOUIS',
            'TAYMOUTH',
            'WICKHAM',
            'BAY VIEW',
            'SERVING GREATER MONCTON',
            'CANTERBURY',
            'BELLEISLE BAY',
            'WILSONS BEACH',
            'CHAMCOOK',
            'HILLSDALE',
            'FOUR CORNERS',
            'SAINT-ANDRE',
            'WELSFORD',
            'POODIAC',
            'SCOTCH RIDGE',
            'PENNFIELD',
            'MAUGERVILLE',
            'ALDOUANE',
            'CAPE TORMENTINE',
            'ERBS COVE',
            'RATTER CORNER',
            'MUSQUASH',
            'BATHURST PARISH',
            'KOUCHIBOUGUAC NATIONAL PARK',
            'WELSHPOOL',
            'ABERDEEN',
            'ST ANDRE',
            'HARVEY YORK CO',
            'QUARRYVILLE',
            'REXTON',
            'UPPER CAPE',
            'UTOPIA',
            'LOWER GREENWICH',
            'POINT LA NIM',
            'TIDE HEAD',
            'ROSSVILLE',
            'FAWCETT HILL',
            'BLACKS HARBOUR',
            'FOUR FALLS',
            'FLORENCEVILLE BRISTOL',
            'ROGERSVILLE PARISH',
            'ROLLINGDAM',
            'SAINT JOHN AND KV AREA',
            'HAVELOCK',
            'RILEY BROOK',
            'STEEVES MOUNTAIN',
            'COLDSTREAM',
            'SILLIKERS',
            'JUNIPER',
            'FOX HILL',
            'BOUCTOUCHE RESERVE',
            'CORMIER-VILLAGE',
            'SIX ROADS',
            'HARVEY ALBERT CO',
            'NASHWAAK VILLAGE',
            'TEMPERANCE VALE',
            'BOUDREAU-OUEST',
            'TEMPLE',
            'GRAND BAY',
            'FLATLANDS',
            'POINTE-DU-CHENE',
            'PUBLIC LANDING',
            'GLENELG',
            'LOWER WOODSTOCK',
            'SAINT-EDOUARD-DE-KENT',
            'GREATER LAKEBURN',
            'TRANS CANADA HWY',
            'RIVER GLADE',
            'SUMMERVILLE',
            'SALISBURY WEST',
            'WATERSIDE',
            'MONCTON PARISH',
            'RICHMOND',
            'HOPEWELL HILL',
            'VERRET',
            'GRAND MANAN',
            'NEW ZION',
            'NEWTOWN',
            'LE GOULET',
            'BENTON',
            'WEAVER',
            'MONTEAGLE',
            'TOWN OF UPPER LETANG',
            'CALIFORNIA SETTLEMENT',
            'MINTO',
            'HANWELL RD',
            'BAYSIDE',
            'FLORENCEVILLE-BRISTOL',
            'RIVIERE-DU-PORTAGE',
            'RICHIBOUCTO',
            'DOUGLAS',
            'HAUT-SHEILA',
            'HAMPSTEAD',
            'BLOOMFIELD KINGS CO',
            'HAUT-SHIPPAGAN',
            'RANG-SAINT-GEORGES',
            'ATHOLVILLE',
            'SUSSEX',
            'SALMON BEACH',
            'OAK HILL',
            'BAS-CARAQUET',
            'SAINTE-ROSE',
            'HANEYTOWN',
            'LAKEVILLE CARLETON COUNTY',
            'DORCHESTER',
            'FREE GRANT',
            'CHANCE HARBOUR',
            'TRACYVILLE',
            'SAINT-ANDRE-LEBLANC',
            'LAKEVILLE-WESTMORLAND',
            'GRAND FALLS',
            'TURTLE CREEK',
            'HOLTVILLE',
            'TITUSVILLE',
            'NEREPIS',
            'HAUT-RIVIERE-DU-PORTAGE',
            'NAUWIGEWAUK',
            'ROGERSVILLE-EST',
            "ST JOHN'S NL",
            'DOAKTOWN',
            'GRAND FALLS / GRAND-SAULT',
            'SAINT-PHILIPPE',
            'WATERBOROUGH',
            'BOUNDARY CREEK',
            'ST GEORGE',
            'SCOUDOUC',
            'ST-HILAIRE',
            'NOTRE DAME',
            'WAWEIG',
            'ST JOHN',
            'ST ANDREWS',
            'WARWICK SETTLEMENT',
            'HAUT-PAQUETVILLE',
            'FREDERICTON JUNCTION',
            'BOIESTOWN',
            'GAGETOWN',
            'NEW RIVER BEACH',
            'SWAN CREEK',
            'ALLISON',
            'JANEVILLE',
            'SCOUDOUC ROAD',
            'PLASTER ROCK',
            'CANOBIE',
            'HAUT-LAMEQUE',
            'EEL RIVER CROSSING',
            'CLIFTON ROYAL',
            'ALBRIGHTS CORNER',
            'BURTTS CORNER',
            'BAS-CAP-PELE',
            'PABINEAU FALLS',
            "LORD'S COVE",
            'BELLEVILLE',
            'OLD RIDGE',
            'MAPLEDALE',
            'NORTHAMPTON',
            'PARKER ROAD',
            'ST. ANDREWS',
            'EVANDALE',
            'KEDGWICK OUEST',
            'NEWBURG',
            'FREDERICTON AREA',
            'LAMEQUE',
            'OAK POINT',
            'WEST FLORENCEVILLE',
            'HOWARD BROOK',
            'INDIAN MOUNTAIN',
            'MADRAN',
            'STILESVILLE',
            'STICKNEY',
            'SAINTE-LOUISE',
            'MANNHURST',
            'PENOBSQUIS',
            'ALLARDVILLE',
            'PETITE-LAMEQUE',
            'LUTES MOUNTAIN',
            'UPPER LETANG',
            'MUNIAC',
            'RICHIBUCTO ROAD',
            'STANLEY',
            'HANWELL',
            'LANSDOWNE',
            'SAINT-ARTHUR',
            'SAINT-LEONARD-PARENT',
            'VAL-COMEAU',
            'SAINT QUENTIN',
            'SAINT-CHARLES',
            'BAIE-STE-ANNE',
            'GRANDE-DIGUE',
            'VILLAGE-BLANCHARD',
            'CLEARVIEW',
            'CHIPMAN',
            'INDIAN ISLAND',
            'TROUT BROOK',
            'SAINT-BASILE',
            'SAINT-LOUIS-DE-KENT',
            'KILLAMS MILLS',
            'WATERFORD',
            'BLACK POINT',
            'HALIFAX',
            'BLOOMFIELD RIDGE',
            'CENTRAL GREENWICH',
            'SIMONDS ST JOHN',
            'RIVERVIEW',
            'MILL BROOK',
            'LITTLE SHEMOGUE',
            'UPPER HAINESVILLE',
            'MCADAM',
            'ROWLEY',
            'SAINT JOHN',
            'MISPEC',
            'FLORENCEVILLE',
            'BLISSFIELD',
            'NEW MARYLAND',
            'ROACHVILLE',
            'COLES ISLAND QUEENS CO',
            'DIPPER HARBOUR',
            'HAMPTON PARISH',
            'MOUNT HEBRON',
            'NOTRE-DAME',
            'SUSSEX CORNER',
            'UPPER LOCH LOMOND',
            'MACES BAY',
            'KNIGHTVILLE',
            'SUNNY CORNER',
            'DOUGLAS HARBOUR',
            'POCOLOGAN',
            'TILLEY ROAD',
            'WILLOW GROVE',
            'BENOIT',
            'ROOSEVELT CAMPOBELLO',
            'BOOM ROAD',
            'LONG POINT',
            'CURRYVILLE',
            'BIRDTON',
            'HAMPTON',
            'BRIDGETOWN',
            'MOORES MILLS',
            'UPHAM',
            'BOCABEC',
            "PETITE-RIVIERE-DE-L'ILE",
            'BURTON',
            'NEW BANDON NORTHUMB',
            'IRISHTOWN',
            'DSL DE GRAND-SAULT/FALLS',
            'ASTLE',
            'RUSAGONIS',
            'KIERSTEADVILLE',
            'PENNIAC',
            'SAINT-ANTOINE-DE-KENT',
            'SAINT-STEPHEN',
            'ELSIPOGTOG FIRST NATION',
            'ST MARTINS',
            'SAINT-FRANCOIS DE MADAWASKA',
            'DAWSON SETTLEMENT',
            'HANTSPORT',
            'ST-ANTOINE NORD',
            'PEARSONVILLE',
            'RIVERSIDE-ALBERT',
            'ST. MARTINS',
            'HAWKINS CORNER',
            'DIGDEGUASH',
            'JARDINEVILLE',
            'NORTON',
            'PEEL',
            'UPPER REXTON',
            'ROBERTVILLE',
            'POINTE-BRULEE',
            'MIDLAND KINGS CO',
            'BOUCTOUCHE SUD',
            'NAPAN',
            'LINDSAY',
            'RICHIBUCTO-VILLAGE',
            'CENTREVILLE',
            'CANTON DES BASQUES',
            'SAINTE-ANNE-DE-MADAWASKA',
            'BETHEL',
            'KINGSCLEAR FIRST NATION',
            'TEEDS MILLS',
            'SPRINGHILL',
            'SAINTE-MARIE-SAINT-RAPHAEL',
            'PLUMWESEEP',
            'CARLINGFORD',
            'MONCTON',
            'STONEY CREEK',
            'RIVIERE-A-LA-TRUITE',
            'DERBY',
            'MCKENNA',
            'LAPLANTE',
            'MALDEN',
            'OAKLAND',
            'WILLIAMSTOWN CARLETON CO',
            'QUIMPAMSIS',
            'LUDLOW',
            'ROGERSVILLE',
            'RICHARDSON',
            'HILLSBOROUGH',
            'MAPLE RIDGE',
            'KEDGWICK',
            'DURHAM BRIDGE',
            'UPPER KESWICK',
            'NORTH TETAGOUCHE',
            'KINGSTON',
            'PORTAGE',
            'FREDERICTON',
            'KILBURN',
            'SAINT-LEONARD',
            'DSL DE DRUMMOND',
            'FAIRISLE',
            'EDMUNDSTON',
            'UPPER WOODSTOCK',
            'SAINT-GREGOIRE',
            'DERBY JUNCTION',
            'BRANTVILLE',
            'PETIT-ROCHER-OUEST',
            'CODYS',
            'SAINT-WILFRED',
            'SAINTE-ANNE-DE-KENT',
            'GRANDE-ANSE',
            'SAINT JOHN NB',
            'PETIT-ROCHER-SUD',
            'ROTHESAY',
            'KILLARNEY ROAD',
            'ORANGE HILL',
            'MEMRAMCOOK',
            'BATHURST',
            'DOUGLAS RIVER PLAZA',
            'PETIT ROCHER',
            'TOBIQUE NARROWS',
            'MEDUCTIC',
            'PORT ELGIN',
            'QUEENSTOWN',
            'QUISPAMSIS',
            'GRAND-SAULT/GRAND FALLS',
            'OAK BAY',
            'ANSE-BLEUE',
            "BLACK'S HARBOUR",
            'HANWELL (NB)',
            'MIRAMICHI',
            'PARLEE BROOK',
            'UPPER TRACY',
            'CARAQUET',
            'MCALLISTER PL',
            'COOKVILLE',
            'NEW DENMARK',
            'LAKESIDE',
            'BARNABY',
            'DRUMMOND',
            'HOLMESVILLE',
            'GRAND-BARACHOIS',
            'DUGUAYVILLE',
            'SAINT-LOUIS',
            'HARTLAND',
            'BOUCTOUCHE COVE',
            'NORTHERN HARBOUR',
            'PONT-LANDRY',
            'OROMOCTO',
            'BAINS CORNER',
            'ST-JOSEPH',
            'BURNT CHURCH',
            'MAQUAPIT LAKE',
            'DALHOUSIE JUNCTION',
            'CLAIR',
            'ALMA',
            'PICADILLY',
            'GATEWAY MALL',
            'SALISBURY',
            '',
            'SAINT-ISIDORE',
            'PIGEON HILL',
            'ELGIN',
            'MOUNT PLEASANT',
            'NEW BRUNSWICK',
            'SACKVILLE',
            'UPPER COVERDALE',
            'ROYAL ROAD',
            'GRAND LAKE',
            'MAGNETIC HILL',
            'ST STEPHEN',
            'PINE GLEN',
            'BOUCTOUCHE',
            'EXMOOR',
            "VAL-D'AMOUR",
            'GRAY RAPIDS',
            'ST-JOSEPH-DE-MADAWASKA',
            'CLAIRVILLE',
            'SAINT-IGNACE',
            'GRAND BAY-WESTFIELD',
            'SAINT-CHARLES-DE-KENT',
            'AROOSTOOK',
            'SAINT ANDRE',
            'DAUVERSIERE',
            'JACKSONVILLE',
            'STRATHADAM',
            'RENAUDS MILLS',
            'NEGUAC',
            'BERTRAND',
            'RED RAPIDS',
            'CHARLO',
            'SAINT-QUENTIN',
            'EVANGELINE',
            'DUFFERIN CHARLOTTE CO',
            'POKIOK',
            'GLENLEVIT',
            'SAINT-ANTOINE SUD',
            'RIPPLES',
            'BAKER BROOK',
            'UPPER GOLDEN GROVE',
            'MIDDLETON',
            'BALMORAL',
            'NEWBRIDGE',
            'LINCOLN',
            'CHERRY BURTON',
            'NIGADOO',
            'ROSAIREVILLE',
            'BERESFORD',
            'KESWICK RIDGE',
            'SHEDIAC',
            'SMITHS CREEK',
            'BERRY MILLS',
            'STE ANNE DE MADAWASKA',
            'BROWNS FLAT',
            'WATERVILLE CARLETON CO',
            'NEWCASTLE',
            'PETIT-PAQUETVILLE',
            'SHIPPAGAN',
            'JEFFRIES CORNER',
            'BAIE DE BOUCTOUCHE',
            'ALCIDA',
            'SHEPODY KINGS CO',
            'SAINT-ANTOINE',
            'COLES ISLAND',
            'AMMON',
            'ROCKLAND',
            'OAK POINT KINGS CO',
            'RIVIERE-VERTE',
            'ROUGH WATERS',
            'TRACADIE-SHEILA',
            'BELLEDUNE',
            'LOWER DERBY',
            'SHEDIAC CAPE',
            'TIMBER RIVER',
            'PERTH-ANDOVER',
            'SEELEYS COVE',
            'GRAFTON',
            'SAINTE-ROSETTE',
            'YOHO',
            'WHITNEY',
            'TABUSINTAC',
            'COCAGNE',
            'BARNETTVILLE',
            'ST. STEPHEN',
            'TILLEY',
            'DALHOUSIE',
            'KOUCHIBOUGUAC',
            'CALHOUN',
            'ST CROIX',
            'UPPER KINGSCLEAR',
            'WOODSTOCK',
            'BAYSWATER',
            'POINTE-SAPIN',
            'DUTCH VALLEY',
            'SAINT-JACQUES',
            'UPPER KENT',
            'DEER ISLAND',
            'SAVOIE LANDING',
            'PAQUETVILLE',
            'BEECHWOOD',
            'INDUSTRIAL PK',
            'ST-QUENTIN PARISH',
            'UPPER GAGETOWN',
            'EDMONTON',
            'PETIT-ROCHER',
        ],
    },

    /* 3 */
    {
        _id: 'QC',
        cities: [
            'COTEAU DU LAC',
            "SAINTE-CHRISTINE-D'AUVERG",
            'VILLE MARIE',
            'CONTRECOUR',
            'DONACONNA',
            'SAINT-ANACLET-DE-LESSARD',
            'DELISLE',
            'SAINT-ANTOINE-DES-LAURENTIDES',
            'EASTMAIN (JAMES BAY)',
            "80 AVE. DE L' AEROPORT",
            'PIERREFONDS-ROXBORO',
            'SAINT-GREGOIRE',
            'DUDSWELL',
            'AV BROADWAY SHAWINIGAN',
            'QC CITY',
            'METABETCHOUAN-LAC-A -LA-CROIX',
            'COTE SAINT LUC',
            'ST-LAMBERT-DE-LAUZON',
            'QUEBEB',
            'DORION-VAUDREUIL',
            'MONTREAL (VERDUN)',
            'RUE DE BELLECHASSE',
            'VILLE ST LAURENT',
            'MONTREAL SUBERB.',
            'SHERBOOKE',
            'RUE DE VENISE',
            'ST. JEAN-SUR-RICHELIEU',
            'STATE / PROVINCE / REGION',
            'ST-LIN-LAURENTIDES',
            'TROIS-RIVIRES',
            'DISTRICT JUDICIAIRE DE MONTREAL',
            'POINT-CLAIRE',
            'MONT-BRUN',
            'MCMASERVILLE',
            'SIEGE SOCIAL',
            'QUEBEC)',
            'VARENNE',
            'BOULEVARD JEAN-LESAGE',
            'RIVIERE-DU-LOUP (QUEBEC)',
            'TRES-ST-REDEMPTEUR',
            'SAINT-ANTOINE',
            '(QC)',
            'CANADA.',
            'RUE FORESTER LONGUEUIL',
            'MONTREAL ET SES ENVIRONS.',
            'SHEF',
            'ST.LAURENT',
            'BDF',
            "GRAND'MERE",
            'ST FAUSTIN',
            'SAINT-E',
            'ST-ANNE-DES-PLANES',
            'PAVILLON ADRIEN-',
            'UNIVERSITE LAVAL',
            'ST-PROSPER DE DORCHESTER',
            'BOUL. RENE-LE',
            'CANADA',
            'QUEBEC (QC)',
            '(QUEBEC)',
            'ST-HONORE',
            'BRMT',
            'ST-AGATHE DES MONTS',
            'STE SOPHIE DE LEV',
            'ST MATHIEU-DE-BELOEIL',
            'SAINT-PAUL DE MONTMINY',
            'ST CASS CAPS',
            'ST-JEAN-DE-DIEU',
            'ST-ELZEAR',
            'BREAKEYVILLE',
            'CH BRIERE',
            "BAIE D'URFE",
            'MARIA (QUEBEC)',
            'NOTRE DAME DE LA MERCI',
            'KANGIRSUK',
            'VANCOUVER',
            'RUE BEAUCHAMPS',
            'QC -',
            'GREENVILLE-SUR-LA-ROUGE',
            'KANESATAKE',
            'ST-CANUT',
            'BEAUCE',
            'RICHELAIN',
            'QU/BEC',
            'LA PETITE-PATRIE',
            'RIVIERE DES PRAIRIES',
            "L'ASSM",
            'TEE LAKE',
            'QUEBEC -',
            'SAINT-CANUT',
            'STE AGA DES MONT',
            'STE-ANNE DES LACS',
            'STE GENEVIEVE DE BATISCAN',
            'MONTREAL-WEST',
            'ANCIENNE-LORETTE',
            "ST THOMAS D'AQUIN",
            'SAINT-BRUNO-DU-LAC-SAINT-JEAN',
            'SHAW-S',
            'SAINTE-THERESE-DE-BLAINVILLE',
            'MONTREAL NORTH',
            'NOYAN, MISSISQUOI',
            'MONTREAL/TORONTO/OTTAWA/NYC/BOSTON/MIAMI',
            'BISHOPTOWN',
            'MONT ROYAL',
            'SAINT-JOACHIM-DE-MONTMORENCY',
            'ST-JUSTIN',
            'ST-EUST',
            'SAINT-LIN - LAURENTIDES',
            'BAGOTVILLE',
            'PROMENADES ST-BRUNO',
            'MONTREAL ( SNOWDON )',
            'ST-JACQUES DE MONTCALM',
            'VILLE DEQC',
            'PLACE DU CANADA',
            'JOHNVL',
            'SAINT JEAN SUR RICHELIEU',
            'SAINT-JEAN CHRYSOSTOME',
            'CANTON-TREMBLAY',
            'PONTIAC',
            'OTTAWA-HULL',
            'SAINT-PIERRE-MONTMAGNY',
            'OTTAWA SAINT-ANDRE-AVELLIN',
            'POINTE-CALUMET QC',
            'SPHILE',
            'ST ARM O',
            'HOTEL',
            'CHENEV',
            'STE CATHERINE DE LA J CARTIER',
            'REPENTGY',
            'STE-BARBE',
            'STE ELISABETH DE PROULX',
            'BLAIVILLE',
            'ST-BASILE-LE GRAND',
            'PESSAMIT',
            'ACCESTOTAL',
            'CAP-DE-LA-MADELAINE',
            'ST PIERRE DE BROTN',
            'ST CLEMENT',
            'DOLLARD DES',
            'ST-ROMUALD',
            'ST CHARLES DE DRMD',
            'NOTRE-DAMES-DES-PRAIRIES',
            'VILLE DE QUEBEC',
            "ST-THOMAS-D'AQUIN",
            'NOTRE-DAME-DE-LORETTE',
            'ST-ALEXANDRE',
            'N/A',
            'STE-AGATHE-DE-LOTBINIERE',
            'ILE BIZARD',
            'VILLAGE POINTE-CLAIRE',
            'CHATEAUGUY',
            'ST-AGAPIT',
            'SAINT-GEORGES-DE-BAUCE',
            'BAIE SAINT-PAUL',
            'ST-SEVERIN-DE-PROULXVILLE',
            'STONEHAM',
            'VILLE LAURRAINE',
            'ST-FLAVIEN',
            'QC',
            'MONTBEILLARD',
            'STE-MARIE-SALOME',
            'PUVIRNITUQ',
            'SAINTE-CATHERINE-DE-LA-JACQUES-CART',
            'SAINT-JULIE',
            'SHERBROOK',
            'SAINT-PATRICE-DE-SHERRINGTON',
            'RSMR',
            'JACQUES-CARTIER',
            'LIMOILOU',
            'ST-GABRIEL DE RIMOUSKI',
            'SAINTE-MARGUERITE-DU-LAC-MASSON',
            'DAVELVL',
            'SAGUENAY-LAC-SAINT-JEAN',
            'ANGE GARDIEN',
            'SAINTE ANNE DE BELLEVUE',
            'SAINTE-ANNE-DU-SAULT',
            'SAINTE THERESE',
            'TETE-A-LA-BALEINE',
            'POINTE CALUMET',
            'ST-ROCH-DE-L',
            'STE RSE',
            'NEMASKA',
            'ST-JULIEN',
            'SAINT-DENIS DE BROMPTON',
            'EAST MAIN',
            "STE SOPHIE D'HALFX",
            'ST-LAMBERT',
            'KEGASKA',
            'ST-NARCISSE-DE-BEAURIVAGE',
            'PORTAGE DU FORT',
            'ROUYN',
            'ST-ROCH-OUEST',
            'SAINT RAYMOND',
            'ST-BERNARD-DE-LACOLLE',
            'ST-BASILE-LEGRAND',
            'FORT COULONGE',
            'VILLE MONT ROYAL',
            'ST-VALERIEN-DE-MILTON',
            'LAVAL QC',
            'ST-BERNARD',
            'ST-FERDINAND',
            "L'ILE BIZARD",
            'EATON',
            'GNBY',
            'BEAULAC GARTHBY',
            'SAINTE-HELENE DE BAGOT',
            'STE HELENE DE KAMOURASKA',
            'SAINTE-SOPHIE DE LEVRARD',
            'ST PAUL DE J',
            'LANAUDIERE',
            'STE-JUSTINE-DE-NEWTON',
            'ST THEOPHILE',
            'SAINTE FOY',
            'SAINT-LUDGER DE MILOT',
            'STE CECILE DE MILTON',
            'SAINT-BRUNO DE MONTARVILLE',
            'ROCK ISLAND',
            'PORT MENIER',
            'ST-MATHIEU-DE-BELOEIL',
            'INUKJUAK',
            'ST. LAURENT',
            'STE-JUSTINE DE NEWTON',
            'SAINT-BRUNO-MONTARVILLE',
            'SAINT-PAUL-DE-MONMAGNY',
            'ST-BRUNO-DE-MONTARVILLE',
            "L'EPIPH",
            'AUT DUPLESSIS',
            'ST-PAUL ILE AUX NOIX',
            'SAINT-MATHIEU-DE BELOEIL',
            'ILE DES SOEURS',
            'STE-LUCIE-DES-LAURENTIDES',
            'SAINT ESPRIT',
            'ANNONCIATION',
            'PINCRT',
            'ST. CONSTANT',
            'COOTICOOK',
            'SAINT-MARCEL',
            'STE-BRIGITTE',
            'STE-AURELIE',
            'BOISBRIANDS',
            'VL CARN',
            'ST-HYCINTHE',
            'CANTON-DE-SHEFFORD',
            'BROMPTON',
            "CENTRE D'ACHAT PLACE LIMBOUR",
            'VILLE ST-LAURENT',
            'RUE WILLIAM',
            'SALLABERRY-DE-VALLEYFIELD',
            'CHICOUTIMI-NORD',
            'BLANCHE',
            'SAINT UBALDE',
            'SAINT PHILEMON',
            'SAINT-DENIS',
            'ST-DENIS-SUR-RICHELIEU',
            'ST-COME-LINIERES',
            'STE PETRONILLE',
            'STE-CLOT',
            'MOONTREAL',
            'NOTRE-DAMES-DES-PINS',
            'SAGD',
            'COTE SAINT- LUC',
            'ST-CHARLES-DE-BELLECHASSE',
            'ST-ETIENNE-DES-GRES',
            'ALOUETTE',
            'STE-MONIQUE-LAC-ST-JEAN',
            'STE-MARIE DE BEAUCE',
            'SAINT-GILLES DE LOTBINIERE',
            "L'ALVERNE",
            'SAINT-GUY',
            'ST-DOMINIQUE-DE-BAGOT',
            'POINTE-AUX-LOUPS',
            'LA POCATIERE-STATION',
            'SAINT-MARCELLINE-DE-KILDA',
            'DAVIDSON',
            'CAPUCINS',
            'COLOMBOURG',
            'ST-VITAL-DE-CLERMONT',
            "ST-PAUL D'ABBOSTFORD",
            'PLATEAU-MONT-ROYAL',
            'ST-JACQUES-DE-LEEDS',
            'SAINT-GEORGES-DE-BEAUCE',
            'ST FRED',
            'SAINT-MAXIME-DU-MONT-LOUIS',
            'ST-MAURICE',
            'SAINT LEONARD',
            'ST-THEODORE',
            'SAINT HYACINTHE',
            'SAINT-LAZARE-DE-VAUDREUIL',
            'NOTRE-DAME DU NORD',
            'STN MAIN',
            'ST MARTINE',
            'BAIE SAINT PAUL',
            'TRIOLET',
            "ST P D'A",
            'SAINT-ALPHONSE-DE-GRANDBY',
            "L'ANNONCIATION",
            'ST-APOLLINAIRE',
            'BAIE COMEAU QC',
            'LAC BEAUPORT',
            'EGAN-SUD',
            'BONDVILLE L BROME',
            'RIV P',
            'ANGE-GUARDIEN',
            'STE MARTHE',
            'ST-MARC-SUR-RICHELIEU',
            'SAINT FELICIEN',
            'CAP-CHAT-EST',
            'VENOSTA',
            'SHAW CLARENDON',
            'MARIA-CHAPDELAINE',
            'SECTEUR POINTE-DU-LAC - TROIS-RIVIERES',
            'VILLE MONT-ROYAL',
            'SAINT CONSTANT',
            'MELOCHEVILLE (VI)',
            'STONEHAM-ET-TEWKBURY',
            'MRC LES MOULINS',
            'TERREBONE',
            'VARENNESS',
            'MONTR?AL',
            'AVENUE LAVAL',
            'STE-HELENE-DE-KAMOURASKA',
            'RIVIERE-TROIS-PISTOLES',
            'ST APOLLINAIRE',
            'ST GABRIEL DE VALCARTIER',
            'STE A DE BEL',
            'SAINTE-LUCIE',
            'ST-EMILE-DE-SUFFOLK',
            'SAINT ANDRE AVELLIN',
            'WALMART ST-LEONARD',
            "L'ILE PERROT",
            "SAINT-PHILIPPE D'ARGENTEUIL",
            'ST-GERMAIN DE GRANTHAM',
            'ILE-AUX-COUDRES',
            'STE MARGUERITE DU LAC MASSON',
            'ST-HONORE DE TEMISCOUATA',
            'ST-LOUIS-DE-FRANCE',
            'STE GENEVIEVE',
            'ARTHABASKA',
            'BEUPRE',
            'ST-JEAN-SUR',
            "CANTON D'ORFORD",
            'SAINT JULES',
            'ST LAURENT',
            'SHAWBRIDGRE',
            'ST-ISIDORE-DE-LA-PRAIRIE',
            'KUUJJUARAPIK',
            'ST-ISIDORE',
            'ST REDEM',
            'CHARLESBOURG-OUEST',
            'MONT-STE-ANNE',
            'MILLE ISLES',
            'TORONTO',
            'LAC BROME',
            'RIVIERE AU TONNERRE',
            'STE-ADELE',
            'SAINTE-MARCELINE-DE-KILDARE',
            'SAINT-SEVERIN-DE-BEAUCE',
            'SAINT-GEORGES-EST',
            'ST-LUC-DE-VINCENNES',
            'BRAMPTON',
            'ST CHARLES BORROMEE',
            'SAINTE-JULIE-DE-VERCHERES',
            'LACORNE',
            'ST-OURS',
            'ST ETIENNE DE LAUZON',
            'STE MAD',
            'CHTGY',
            'SAINTS-MARTYRS-CANADIENS',
            'LANOR',
            'FABRVLE',
            'ILE PERROT.',
            'MONTREAL ET LA REGION',
            'SHAWINIGAN SUD',
            'ST FULG',
            'ROUYN-NOR',
            'MAPLE GROVE',
            'ST JOS LEVIS',
            'PLESSISVILLE PAROISSE',
            'ST NICEPHORE',
            'VAL-RACINE',
            "L'ILE-MICHON",
            'ST BARNABE S',
            'ST DENIS SUR RICHELIEU',
            'SAINT-GERARD-MAJELLA',
            'ST HUB',
            'PAQUETTE',
            'ST JOS KAM',
            'SAINT-BARNABE S',
            'MARCHAND',
            'STE FOY',
            'LAC-SERGENT',
            'SAINT-DAVID-DE-FALARDEAU',
            'TIVERTON',
            'ST-AIME',
            'DOLLARD DES ORMEAUX',
            'RIVE NORD',
            'N D P',
            'BERHIERVILLE',
            'LAC BOUCHETTE',
            'ST-COLOMAN',
            'SAINTE-PIE',
            'ST LEONARD',
            'ST MATHIEU DU PARC',
            'ST-HONORE-DE-CHICOUTIMI',
            'SAINT-JEAN',
            'BLOCKCHAIN',
            'BARNSTON',
            'TRES-SAINT-REDEMPTEUR',
            'LEBEL-S-QUEV',
            'SAINT BRUNO',
            'POINTE CLAIRE',
            'SAINTE-ROSALIE',
            'STE ADELE',
            'ST-FAUSTIN LAC CARRE',
            'VAL MORIN',
            'ST RAPHAEL',
            'MONTEAL',
            'SAINT-HENRI',
            'CHARLESBOURG-EST',
            'ST-BONIFACE-DE-SHAWINIGAN',
            'PACKINTON',
            'KRKLK',
            'CLERVAL',
            'ST-ANDRE-A',
            'GRAENVILLE SUR LA ROUGE',
            'STE ANNE',
            'RDP',
            'LAPLAINE',
            "ST-JEAN-D'ORLEANS",
            'STE ANNE BEAUPRE',
            '780 AVENUE BREWSTER SUITE 03-300 MONTREA',
            'SAINTE-PRAXEDE',
            'ST-ZOTIQUE',
            'CANTON ORFORD',
            'SAINT-SAUVEUR-DES-MONTS',
            'BAIE-DE-MASKINONGE',
            'MAKINONGE',
            'ST-DENIS-DE-BROMPTON',
            'LES BUISSONS',
            'SY BAS L G',
            'STE-CAT.-DE-HATLY',
            'SAINT-METHODE-DE-FRONTENAC',
            'STE-CATHERINE-DE-HATLEY',
            'ST-NICEPH',
            'CANTON DE WIND',
            'FLEURIMONT',
            'LAKE KIPAWA',
            'ROLLET',
            'COAT',
            'KEBAOWEK INDIAN RESERVE',
            'EAGLE VILLAGE RESERVE',
            'SULLIVAN MINES',
            'CADILLAC',
            'VAL-VIGER',
            'ST ANDRE AVELLIN',
            'LAC SUPERIEUR',
            'MC MASTERVILLE',
            'SAINT-GERARD-DES-LAURENTIDES',
            'ST GERARD DES LAURENTIDES',
            'LAC RAPIDE',
            'STE MART LAC',
            'MISTISS',
            'DE L EGLISE',
            'STE ANG MON',
            'ST-REMI',
            'ST-LEON',
            'ST-ESPRIT',
            'ST-CUTHBERT',
            'ST BRIG IBERVILLE',
            "ST-ADOLPHE-D'HOWARD",
            "ST PAUL DE L'ILE AUX NOIX",
            'BETHANIE',
            'STE CHRSTN',
            'ST VALERIEN',
            'ST NAZAIRE',
            'NOTRE-DAME-AUXILIATRICE-DE-BUCKLAND',
            'STUKELY S',
            "ST-PAUL-D'ABBOTSFORD",
            'ST ET BOLTON',
            'STE-FLORE',
            'N-D-B-CONS',
            'JOLIETTE-JOL',
            'ROBVL',
            'N D B CONS',
            'ANSE-AU-GRIFFON',
            'ST GERM GR',
            'STGUILL',
            'STE EDWG DE CLIFTN',
            'BOIS DE FILION',
            'E ANG',
            'N D DES BOIS',
            'LAC KENOGAMI',
            'VALOIS',
            'D DES O',
            'STE D',
            'ST ROMAULD',
            'ST-ELIE-DE-CAXTON',
            "ST-CHRISTOPHE-D'ARTHAB",
            'ST CYP NPVL',
            'ETIENNE-DE-LAUZON',
            'ST-ETIENNE-DE-LAUZON',
            'ST-JEAN-DE-BREBEUF',
            'SRMT',
            'PONT-VIAU',
            'PT V',
            'MADDINGTON',
            'ST SYLVERE',
            'ST-SEBASTIEN',
            "KINNEAR'S MILL'S",
            'ST ROSAIRE',
            'RIVIERE A LA PECHE',
            'LEGARDEUR',
            'STE MONIQUE',
            'MILOT',
            'ST D DE FALARDEAU',
            'ST-DAMIEN-DE-BUCKLAND',
            'THET MNS',
            'DEWITTVILLE',
            'E BROTN',
            'SAINT-JUSTE-DU-LAC',
            'MAJOR GR',
            'COMMERCIALE',
            'ST-EUSEBE',
            'ST-EPIPHANE',
            'CHEVERY',
            'KAWAWACHIKAMACH',
            'RIMOUSKI-EST',
            'BAIE-JOHAN-BEETZ',
            'BARACHOIS',
            'SAINT HENRI',
            'MILL RD',
            "VAL-D'ESPOIR",
            'ST-STANISLAS-DE-CHAMPLAIN',
            'MTL O',
            'PORT-DANIEL-GASCONS',
            'ST SIMEON DE BONAVENTURE',
            'ST-LAURENT I O',
            'ST FERREOL LES NEIGES',
            'ST-JOSEPH DE LA-RIVE',
            'T RIV O',
            'ST-CHARLES DE DRUMMOND',
            'ST-FELIX DE VALOIS',
            'WENTWORTH',
            'MONT-TREMBANT',
            'HL',
            'STE-CATHERINE-DE-LA-J. CARTIER',
            'SAINT JEROME',
            'SAINT-FRANCOIS-DE-LA-RIVIERE-DU-SUD',
            'STE-SOPHIE',
            'AUTHIER-NORD',
            'THORNE',
            'SAINT-BEATRIX',
            'ST MICHEL DU SQUATEC',
            "NOTRE DAME DE L'ILE PERROT",
            'ST-CYR-W',
            "L'ANCIENNE LORETTE",
            'HAUTE-MAURICIE',
            'CAP-ROUGE',
            'MILLES-ISLES',
            'SHEENBORO',
            'N D ILE PER',
            'ST LAZARE',
            'ST MCHL',
            'SAINTE-THERESE DE GASPE',
            'ST MARCEL RICH',
            'LAC-DUFAULT',
            'COTE ST-LUC',
            'MONT LAURIER',
            'NOTRE-DAME-DU-BON-CONSEIL',
            'MASSON-ANGERS',
            'STE-AG-D-MTS',
            'VAUD',
            'MONTREAL INTERNATIONAL AIRPORT',
            'ST-EDMOND',
            'STE A BPRE',
            'VAUDREUIL',
            'VAUDREUIL DORION',
            "ST JOHN'S",
            'MONTREAL & BANLIEUES',
            'ST EUST',
            'ST-ANTONIN',
            'LOCHABER O',
            'LAVAL RIVE-NORD ET ENVIRONS',
            'STE-THERESE',
            'RIVE-NORD',
            'REPTGNY',
            'SANS FRAIS',
            'ST JER',
            'ST LIN LAURENTIDES',
            'SAINT JEAN-SUR-RICHELIEU',
            'ST-CONSTANT',
            'SAINT-BRUNO-DE-MONTARVILLE',
            'ST MATHIAS SUR RICHELIEU',
            'ST M BELO',
            'GEORGETOWN',
            'ST-MATHIEU DE BELOEIL',
            'ST M BEL',
            'STE CATH DE LA J CARTIER NC',
            'STE-BLAISE-SUR-RICHELIEU',
            'PLESSIVILLE',
            'STE-AGATHE-DES-MONTS',
            "CANTON D'ORFRD",
            "CARREFOUR DE L'ESTRIE",
            'ST-COLOMBAN',
            'MANSFIELD',
            'WALTHAM',
            'PORTAGE-DU-FORT',
            'STE CECILE DE LEV',
            'LADYSMITH',
            'ST J CHRYS',
            "ST ANDRE D'ARGENTEUIL",
            'SAINTE-VERONIQUE',
            "ST A D'HOW",
            "L'ANNON",
            'ST ETIENNE DE BEAUHARNOIS',
            'HINCHINBROOK',
            'STE ANNE DES LACS',
            'DALHOUSIE',
            'CHISAS',
            'P ST EDOUARD',
            'ST MARC',
            'CALIXA LAVALLEE',
            'ST ISIDORE (P)',
            'ST THOMAS',
            "ST ROCH DE L'ACHIGAN",
            'STE-URSULE',
            'INVNS',
            'ST GB DE B',
            'ST-CHARLES',
            'ST-EDOUARD-DE-MASKINONGE',
            "LA VISITATION-DE-L'ILE-DUPAS",
            'TROIS-RIVIERE',
            'STE-BEATRIX',
            'ST FLX DE VALOIS',
            'NOTRE-DAME-DE-LOURDES',
            'ST-JEAN-BAPTISTE',
            'ST FELIX DE VALOIS',
            'HNRYVL',
            'ST JEAN DE MATHA',
            'ACCES TOTAL',
            'CANADA -',
            'NPVL',
            'PHILIPSBURG',
            'ST SIM BAGOT',
            'ST CHARLES RICH',
            'SAINT-AIME',
            'MONT-ALBERT',
            'ST-PIE DE GUIRE',
            'ST FRANCOIS DU LAC',
            'CANTON DE SHEFFORD',
            'ABBOTSFORD',
            'POTTON',
            'GRAND MERE',
            "ST-LEONARD-D'ASTON",
            'ST-FRAN-X DE BROMP',
            'ST CHARLES DE BELLECHASSE',
            'SAINT SAUVEUR DES MONTS',
            'STE-MARGUERITE-DE-LINGWICK',
            'SAINT-REMI-DE-TINGWICK',
            'OTTAWA',
            'COMPLEXE DESJARDINS',
            'VAL D`OR',
            'STE-ANNE-DES-LACS',
            'ST FRS',
            'EM-1-A-SARCELLE-RUPERT',
            'COTE ST LUC SHOPPING CENTRE',
            'ST-LAURENT',
            'COTE-ST-LUC',
            'SAINTE-CLOTHILDE-DE-CHATEAUGUAY',
            'ST-LAZARE',
            'WINDSOR STATION',
            'STE-ANGELE-DE-MONNOIR',
            'MTL E',
            'WSMT',
            'SAINT GEORGES DE CHAMPLAIN',
            'SHAW NC',
            'SHAW S',
            'TROIS-RIVIERES-OUEST',
            'ST AMBROISE',
            'ST JEAN CHRYSOSTOME',
            'ST ROMULAD',
            'CHATEAUGAY',
            'STE-MARIE-DE-BEAUCE',
            'ASCOT CORN',
            'MALIO',
            'SAINT-MARTHE-SUR-LE-LAC',
            'B ST-PAUL',
            'ST-RAYMOND',
            'LORET',
            'MILLE ILES',
            'ST AUG DESMAURES',
            'QUEBEC CITY',
            'RBC ROYAL BANK MONTREAL SOUTH-WEST',
            'STE-FOY',
            'ANSE ST-JEAN',
            'ST-ANDRE-AVELLIN',
            'ASTON JUNCTION',
            'ST-SYLVERE',
            'SAINT FAUSTIN',
            'ST-PAUL',
            'ST NRBRT',
            'MARSTON',
            'ST-BARN',
            'ST DOMINIQUE',
            'SAINT-LUC-DE-VINCENNES',
            'CROISSANT DU SAULT',
            'STRATFORD CENTRE',
            'ST-JEAN-DES-PILES',
            'SAINT-ROCH-SUR-RICHELIEU',
            'ST ETIENNE DES GRES',
            'SAINT-CHARLES-DE-BOURGET',
            'DES RUISSEAUX',
            'SAINTE-FRANCOISE-DE-LOTBINIERE',
            'GRAND MONTREAL',
            'STE FRANCOISE',
            'WENDAKE RI',
            'STE HELENE DE BREAKEYVL',
            'LAMARTINE',
            'SAINT-CAMILLE-DE-BELLECHASSE',
            'STE- MARTHE SUR LE LAC',
            'SAINT-ADALBERT',
            'LAC-SAINT-JOSEPH',
            'SAINTE-HELENE-DE-CHESTER',
            'STE-MARTHE-SUR-LE-LAC',
            'ST JEAN DE LA LANDE',
            'STHONS',
            'BARKMERE',
            'ST HON',
            "D'ALAMBERT",
            'ST-LUDG DE FRONT',
            'ST-HONORE-DE-SHENLEY',
            'N D DU LAC',
            'STE-ANNE-DES-PLAINES',
            'ST EUSEBE',
            'SAINT-ALEXANDRE-DES-LACS',
            'RUE RIDEAU',
            'SDONA',
            'TROIS PISTOLES',
            'CALI LAVAL',
            'ST-PASCAL',
            'CLUB DE BOXE PERFORMANCE',
            'BERTHIER',
            'LAC-HUMQUI',
            'L BOUCHT',
            'LA BOSTONNAIS',
            'SAINT-AUGUSTIN-SAGUENAY',
            'BOUL BASTIEN',
            'LA TABATIERE',
            'CHESTVL',
            'RIVIERE-PASPEBIAC',
            'SAINT-JOGUES',
            'GRANDE-RIVIERE-OUEST',
            'BPRE',
            'ST THE G',
            "L'ANGE GAR",
            'STATION CENTRE-VILLE',
            'ROUYN NORANDA',
            'B-JAMES',
            'SAINTE STANILAS DE KOSTKA',
            'MTLAUR',
            'SEPT ILES',
            'OTTAWA-GATINEAU',
            'PARC-DE-LA VERENDRYE',
            'HULL',
            'ST-TITE-DES-CAPS',
            'RUE MONTCALM',
            'GAT NC',
            'DENHOLM',
            'VLCT',
            'HARRINGTON',
            'PINCOURT',
            'VAUD DOR',
            "SAINT-JEAN-D'ORLEANS",
            'ILE-PERROT',
            'ST JANV',
            'ODANAK',
            'ST-BARNABE NORD',
            'MONT-ST-GREGOIRE',
            'STE THER',
            'RIVIERE-AUX-OUTARDES',
            'BOIS DES FILLIONS',
            'QC (QUEBEC)',
            'LACHENAIE',
            'ST-PAUL DE JOLIETTE',
            'SAINT-PIERRE',
            'MTL',
            'MONTREAL METRO AREA',
            'CHUTE-ST-PHILIPPE',
            'STE ANNE DE SOREL',
            'ST HUBERT',
            'ST BASILE LE GRAND',
            'ST JEAN SUR RICHELIEU',
            'SAINT LIBOIRE',
            'ST HYACINTHE',
            'AMQUI',
            'FRANHAM',
            'CANTON-DE-GRANBY',
            'ST-CELESTIN',
            'MIRABEL',
            'LORRAINV',
            'SAINT-FORTUNAT',
            'WINNEWAY',
            'ST-HYACINTHE',
            'PABOS MILLS',
            'LANIEL',
            'ST-EDOUARD',
            'LATULIPE',
            'AUTHIER',
            'BELLETERRE',
            'STE JULIE',
            'MIQUELON',
            'LAUNAY',
            'SAINTE-ANNE-DU-LAC',
            'SENNET',
            'BERRY',
            'MALTON',
            'BELCOURT',
            'STE ANNE DE BEAUPRE',
            'SAINT-MARC-DE-FIGUERY',
            'LA MOTTE',
            'SAINTE-SOPHIE-LEVRARD',
            'MANSFIELD ET PONTEFRACT',
            'LEB-SUR-QUEV',
            'SAINT-ODILON-DE-CRANBOURNE',
            'STHYA',
            'BLUE SEA LAKE',
            'DUCLOS',
            'ST-JACQUES',
            'TEMISCAMINGUE',
            'SAINTE-THERESE-DE-LA-GATINEAU',
            'ST-CHARLES-DE-DRUMMOND',
            'RAPIDES-DES-JOACHIMS',
            'LONGUEIL',
            'ST JEAN BAPTISTE',
            'SAINTE-SOPHIE',
            'ALCOVE',
            'QUYON',
            'STE-THERESE DE GATINEAU',
            'UMIUJAQ',
            'BOWMAN',
            'SAINT HIPPOLYTE',
            'DESMELOIZES',
            "ST-MARCEL-DE-L'ISLET",
            'KAZABAZUA',
            'LOURDES-DE-BLANC-SABLON',
            'MANSEAU',
            'ROQUEMAURE',
            'WENWORTH-NORD',
            'WINDSOR',
            'GORE',
            'LABL',
            'ST-NICEPHORE',
            'ST DONAT',
            'BOUL FRONTENAC O',
            'ST ROM',
            "SAINT-REMI-D'AMHERST",
            'SAINT-ETIENNE',
            'RIVIERE ROUGE',
            'STE-MARTINE',
            'ST-SAUVEUR',
            'LADRIERE',
            'SHAWBRIDGE',
            'ST-FRANEOIS-XAVIER-DE-BROMPTON',
            'GRANDES-PILES',
            'STE-MARTHE',
            'ST-JOSEPH-DU-LAC',
            'SHAWINIGAN (LAC-A-LA-TORTUE)',
            'KNW',
            'ST-ALFRED DE BEAUCE',
            'SAINTE-ANGELE-DE-MONNOIR',
            'SAINT-HONORE DE SHENLEY',
            'SAINTE CLOTILDE DE CHATEAUGUAY',
            'MONT-TREMBLANT',
            'ST-ALEX',
            "ST ADOLPHE D'HOWARD",
            'ST ESPRIT',
            'SAINT-ROCH DE LACHIGAN',
            'ST-JEAN-DE-MATHA',
            'ST-HIPPOLYTE',
            "ST-PAUL-DE-L'ILE-AUX-NOIX",
            'ST ROCH DE L',
            'SAINT-DENIS-DE-BROMPTON',
            'CHARLESBOURG',
            'STE GENEVIEVE-DE-BERTHIERVILLE',
            'DESCHAMBAULT',
            'ST AMBR DE K',
            'RUE BEACON',
            'SAINTE-APOLINAIRE',
            'ST-BARNABE-NORD',
            'ST-FELIX-DE-VALOIS',
            'ST-CHARLES-SUR-RICHELIEU',
            'MONT- SAINT-GREGOIRE',
            'VICTORVL',
            'ST PIE',
            'BURRY',
            'ACT VL',
            "VALD'OR",
            'LA VISITATION-DE-YAMASKA',
            'SAINT-JULES',
            'ST JOACH SHEF',
            'SAINT-THERESE',
            'VAL-SENNEVILLE',
            'SAINT-ELPHEGE',
            'FULFORD',
            "L'ISLE AUX ALLUMETTES",
            'SAINT-CLAUDE',
            'BEAUX-RIVAGES',
            'ST ULRIC',
            'SAINT-FRANCOIS-XAVIER-DE-BROMPTON',
            'AYERS CLIFF',
            'YAMASKA',
            'CLEVELAND',
            'STE JULIENNE',
            'LA SARRE',
            'ST GEDN',
            'SAINTE-EDWIDGE',
            'SAINT-BENOIT-DU-LAC',
            'LAWRENCEVILLE',
            'RUE BEAUREVOIR',
            'VILLAGE SAINT-PIERRE',
            'VAL-JOLI',
            'NOTRE-DAME-DES-PINS',
            'STANSTEAD-EST',
            'ST-JOSEPH-DE-BEAUCE',
            'SAINT-VALERE',
            'CHARTIERVILLE',
            'NEW RICHMOND',
            'DIXVILLE',
            'RAGUENEAU',
            'CANTON DE KINGSY',
            'ST FLX DE KINGSY',
            'DEAUVILLE',
            'SAINTE-ELIZABETH-DE-WARWICK',
            'SAINT-ZOTI',
            'SAINT-ROCH-OUEST',
            'MONTREAL AREA',
            'SAINT-FELIX-DE-KINGSEY',
            'SAINT-FERDINAND',
            'TNO HAUT-ST-MAURICE',
            'LAC-SUPERIEUR',
            'SAINT-GEORGES-DE-WINDSOR',
            'LA MACAZA',
            'WOTTON',
            'ST-AMABLE',
            'SAINT-JOSEPH-DE-KAMOURASKA',
            'SAINTE-FRANCOISE',
            'BAIE-JAMES',
            'ST PHIL',
            'KINGSTON',
            'WEEDON',
            'AUTEUIL',
            'CHOEMDEY',
            'COTE-SAINT-LUC',
            'SAINT-SAUVEUR',
            'DRVL',
            'SAINTE-DOROTHEE',
            'LLST M',
            'ST ISIDORE',
            'LE GARDEUR',
            'ILE NOTRE DAME',
            'CHIBOUG',
            'ST-ROCH',
            'LATUQUE',
            'ST FELC',
            'ST REMI DE TINGWICK',
            'MILLE-ILES',
            'METABETCHOUAN LAC A LA CROIX',
            'MULGRAVE-ET-DERRY',
            'SAINT ROMUALD',
            'SAINT-FE?LICIEN',
            'ST-ROM',
            'PONT VIAU',
            'LAURIERVILLE',
            'ST REDEMPTEUR',
            'SAINT-CHARLES-DE-DRUMMOND',
            "ST-ADRIEN-D'IRLANDE",
            'ST-ETIENNE DES GRES',
            'PINTENDRE',
            'SAINT-BRUNO',
            'SOREL TRACY',
            'ST-HUGUES',
            'ST-ARMAND',
            'NDPIN',
            'SAINTE-AGELE-DE-MONNOIR',
            'BON-CONSEIL',
            'ST-GEORGES',
            'GENTILLY',
            'ST-LUDGER',
            'POINTE-AU-PERE',
            "L'ILE-D'ENTREE",
            'STE ADE',
            'GROSSE-ILE',
            'CAP SANTE',
            'FERME NEUVE',
            'STRY',
            'DUMMONDVILLE',
            'LORETTEVILLE',
            'LAC-DES-SEIZE-ILES',
            'SAINT-NOEL',
            'BURY',
            'SAINTE-HELENE-DE-BREAKEYVILLE',
            'CARREFOUR CHARLESBOURG',
            'ST LIN',
            'VANIER',
            'STE CECL DE WHITN',
            'AUDET',
            'BOIS FRANC',
            'ELGIN',
            'BEAULAC-GARTHBY',
            'SAINT-ROMAIN',
            'GRDIN',
            'WEMINDJI',
            'STUKELY-SUD',
            'NEWPORT',
            'SAINT-ROCH-DE-MEKINAC',
            'LECLERCVILLE',
            'ST MAURICE (P)',
            'FNEUV',
            'SAINT-LAZARE-DE-BELLECHASSE',
            'SAINT-BERNARD',
            'SAINT-BONIFACE-DE-SHAWINIGAN',
            'SAINT-JEAN SUR RICHELIEU',
            'LA PRAIRIE',
            'LEJEUNE',
            'WOLINAK',
            'VAUDREUIL-SUR-LE-LAC',
            'ST-ELIE',
            'SAINT-STANISLAS',
            'SAINT-ROCH-DES-AULNAIES',
            'LAC-A-LA-TORTUE',
            'ST-TITE',
            'ST-LIN',
            'SAINTE-SOPHIE-DE-LEVRARD',
            'SAINT-STANISLAS-DE-CHAMPLAIN',
            'FARRELLTON',
            'SAINT-FRANCOIS-DE-SALES',
            'STE-SERAPHINE',
            'ST DOM',
            "L'ASCENSION DE NOTRE SEIGNEUR",
            'OBEDJIWAN',
            'SAINTE-MONIQUE',
            'SAINT-BARNABE-SUD',
            'LABRECQUE',
            'ST-ROCH DE MEKINAC',
            'ST CONSTANT',
            'ST-HENRI-DE-LEVIS',
            'GRANDES-BERGERONNES',
            'ST-GERTRUDE',
            'FERLAND-ET-BOILLEAU',
            'SAINT-JOSEPH-DU-LAC',
            'DUVERNAY',
            'PETIT-SAGUENAY',
            'ST-CHARLES-DE-BOURGET',
            'STE-CHRSTN',
            'DELEAGE',
            'DOLBEAU',
            'SHAWLNIGAN',
            'ST-ANDRE DE KAMOURASKA',
            "SAINT-FELIX-D'OTIS",
            'ST NCLAS',
            'RADISS',
            'FORTIERVILLE',
            'LASALLE',
            'SAINTE-AGATHE-SUD',
            'SAINTE-MARGUERITE',
            'HUDSON HEIGHTS',
            'RUE DES MOULINS',
            'SAINT-THOMAS-DIDYME',
            'MARRIOTT CHATEAU CHAMPLAIN',
            'ROSEMERE',
            'DOSQUET',
            'LA SALLE',
            'SAINT-GABRIEL DE VALCARTIER',
            'ST JEAN SUR RICH',
            'SAINT-ELZEAR-DE-BONAVENTURE',
            'SAINT-CELESTIN',
            'RAVIGNAN',
            'STE-ETIENNE DE BEAUHARNOIS',
            'NOMIN',
            'NOTRE-DAME-DU-ROSAIRE',
            'ST-FRANCOIS-MONTMAGNY',
            'SAINT-NAZAIRE-DE-DORCHESTER',
            'ST FERDINAND',
            'SAINT-LEONARD-DE-PORTNEUF',
            'SAINTE-LOUISE',
            'ST GEORGES S',
            'SAINTE-APOLLINE-DE-PATTON',
            'SAINTE-SABINE-DE-BELLECHASSE',
            'ST GABRIEL',
            'TOURVILLE',
            'SAINTE-LUCIE-DE-BEAUREGARD',
            'ST-HENRI DE LEVIS',
            'SAINT-NEREE',
            'SAINT-ETIENNE-DE-LAUZON',
            'SAINTE-EUPHEMIE',
            'ST CHRYSOSTOME',
            "SAINTE-FELICITE-DE-L'ISLET",
            'SAINT-PHILEMON',
            'CANTON DE GRANBY',
            'ST-AMBROISE-DE-KILDARE',
            'SAINTE-MONIQUE-LAC-SAINT-JEAN',
            'SAINT-JULIEN',
            'NOTRE-DAME-DE-HAM',
            "SAINTE-SOPHIE-D'HALIFAX",
            'STE-MARCELLINE-DE-KILDARE',
            "SAINT-NORBERT-D'ARTHABASKA",
            'CHAMPLAIN',
            'HAM NORD',
            'SAINT-BASILE-DE-PORTNEUF',
            'RANG PETIT SHENLEY',
            'LA MORANDIERE',
            'ST-MALO',
            'ST-CESAIRE',
            'LAGUAD',
            'ST-JOGUES',
            'MONTEE LETANG',
            'JONQ',
            'SAINT-SIMON-LES-MINES',
            'SHERBROOKE',
            'SAINT-ALFRED',
            "PLACE D'YOUVILLE",
            'ST-JEAN-DE-LA-LANDE-DE-BEAUCE',
            'STE A DES PLAINES',
            'ST ARM',
            'VILLE SAINT-LAURENT',
            'SAINTE-RITA',
            'SAINT-LUC-DE-BELLECHASSE',
            'SAINT-GEDEON-DE-BEAUCE',
            'SAINTE-PERPETUE',
            'LA REINE',
            'WARDEN',
            'STE-DOROTHEE',
            'SOREL-TRACY',
            'SAINT-PIERRE-DE-LAMY',
            'SAINT-ADELME',
            'TEMISCOUATA SUR LE LAC',
            'SAINT-ETIENNE DES GRES',
            'SAINT-DENIS-DE-LA-BOUTEILLERIE',
            'ST-DIDACE',
            'SAINT-VALERIEN-DE-RIMOUSKI',
            'ST SAUV',
            'SAINT-MEDARD',
            'GASPESIE',
            'SAINT-MATHIEU-DE-RIOUX',
            "L'ISLE VERTE",
            'KNOWLTON (LAC BROME)',
            'MCMASTERVILLE',
            'SAINT-BRUNO-DE-KAMOURASKA',
            'DESMARAISVILLE',
            'ST-ROCH-DE-MEKINAC',
            'ST DENIS',
            'SAINT-ELIE-DE-CAXTON',
            'SAINT-ELZEAR-DE-TEMISCOUATA',
            'RIVIERE-OUELLE',
            'LA MALABIE',
            'LAC DES LOUPS',
            'MONT-SAINT-MICHEL',
            'PRINCIPALE',
            'OUJE-BOUG',
            "ST-EMILE-D'AUCLAIR",
            'LAC-DOUAIRE',
            'LAURIER',
            'ST C',
            'ST-PIERRE-DE-LA-RIVIERE-DU-SUD',
            'ST-ATHANASE',
            'NEW-RICHMOND',
            'TROIS-RIVES',
            'SAINT-EUSEBE',
            'SAINT-LUC',
            'ORFORD',
            'NOTRE-DAME-DES-SEPT-DOULEURS',
            "CAMPBELL'S BAY",
            'TROIS RIVIERES',
            'MANOUANE',
            'SAINT-CLEMENT',
            'SAINTE-ADELE',
            'STE HELENE',
            'ST-LEONARD',
            'COTE ST LUC',
            'SAINT-HYACINTHE',
            'LONGUE-RIVE',
            'MONT-CARMEL',
            'ST COLOMBAN',
            'ILE VERTE',
            'SAINT-POLYCARPE',
            'PADOUE',
            'ESPRIT-SAINT',
            'LAC-DES-AIGLES',
            'SAINT-CHARLES-GARNIER',
            'ST-FELIX DE KINGSEY',
            'SAINT-MARCELLIN',
            'ST-NORBERT BERTHIER',
            'ST-ALPHONSE-DE-RODRIGUEZ',
            'SAINTE-MARGUERITE-MARIE',
            'SAINTE-MARGUERITE-DU-LAC-',
            'BROWNSBURG',
            'SAINTE-PAULE',
            'DOLBEAU-MIST',
            'ST MICHEL',
            'SAINTE-LUCE',
            "L'ASCENSION-DE-PATAPEDIA",
            'RIVROUGE',
            'SAINT-THARCISIUS',
            'SAINT-FREDERIC',
            'STE-IRENE-DE-MATAPEDIA',
            'ILE PERROT',
            'SAINTE-FLORENCE',
            'ST CANUT',
            'LAC-DROLET',
            'SAINTE-ANGELIQUE',
            'ST-OCTAVE',
            'SAINT-MOISE',
            "SAINT-ADRIEN-D'IRLANDE",
            'OTTER LAKE',
            'SOREL',
            'LONGUE-POINTE-DE-MINGAN',
            'RIVIERE-AU-TONNERRE',
            "SAINT-LAURENT-ILE-D'ORLEANS",
            'BAIE-TRINITE',
            'SAINT-LEANDRE',
            'RIVIERE-PENTECOTE',
            'ST-BRUNO',
            'COLOMBIER',
            'CLARKE CITY',
            'SAINT-NICOLAS',
            'GODBOUT',
            'SAINT-APOLLINAIRE',
            'LA MINERVE',
            'CHUTE-AUX-OUTARDES',
            'SAINT-BARTHELEMY',
            "L'ISLETVILLE",
            'SAINT-SIMON',
            'PORT-MENIER',
            'MATIMEKOSH',
            'RIVIERE-SAINT-JEAN',
            'EAST BROUGHTON STATION',
            'CP DE TOURAINE',
            'MUTTON BAY',
            'SAINT-CYPRIEN-DE-NAPIERVILLE',
            'MADELEINE-CENTRE',
            'STHE G',
            'LA MARTRE',
            'MARICOURT',
            'MURDOCHVILLE',
            'ST ROMUALD',
            'FARN',
            'SHIGAWAKE',
            'MONT-ST-HILAIRE',
            'DESCH SUR ST LAUR',
            'STE-A-BPRE',
            'ST-CLET',
            'SAINTE-THERESE-DE-GASPE',
            'ST-OMER',
            'VL CAR',
            'BELO',
            'LAC-FRONTIERE',
            'MONTREAL EST',
            'RES FAUN DES LAURENTIDES',
            'STE-AGATHE',
            'LAC-SAGUAY',
            'VAL-BRILLANT',
            'ILE DU GRAND-CALUMET',
            'TEWKESBURY',
            'LEF',
            'ST-EUGENE',
            'SAINT-FABIEN',
            "SAINT-FRANCOIS-D'ORLEANS",
            'SAINTE-FAMILLE',
            'PORT-DANIEL',
            'BEAUPRE',
            'STE-GERMAINE-BOULE',
            'SAINT-BRUNO-LAC-SAINT-JEAN',
            'SAINTE-MARIE-DE-BLANDFORD',
            'LA TRINITE-DES-MONTS',
            'SALBA',
            'KINGSEY FALLS',
            'WEMOTACI',
            'BOISBRIAND',
            'ALBERTVILLE',
            'MAGOG',
            'GRONDINES',
            'ST-COME',
            "SAINT-PIERRE-DE-L'ILE-D'ORLEANS",
            'WENTWORTH-NORD',
            'ST-HILARION',
            'COTEAU-DU-LAC',
            'CASCAPEDIA-SAINT-JULES',
            'TERRASSE-VAUDREUIL',
            'SAINT-ADRIEN',
            'ST-ETIENNE',
            'LA CONCEPTION',
            'SENNEVILLE',
            'ST-WENCESLAS',
            'SHANNON',
            'ST-FELICIEN',
            'VAL-ALAIN',
            'ST-ARSENE',
            'SAYABEC',
            'SAINT ANDRE-AVELLIN',
            'SAINT-TITE-DES-CAPS',
            'NOUVELLE-OUEST',
            'SAINT-EDMOND-LES-PLAINES',
            "SAINTE-CHRISTINE-D'AUVERGNE",
            'LYSTER',
            'SAINT-MARC-SUR-LE-RICHELIEU',
            'OTTERBURN PARK',
            'SAINT-LAMBERT',
            'ST CES',
            'BONAVENTURE',
            'SAINT-MARCEL-SUR-RICHELIEU',
            'SAINTE-FLAVIE',
            'PARISVILLE',
            'SAINT-MICHEL-DE-BELLECHASSE',
            'SAINT-SAMUEL-DE-HORTON',
            'INVERNESS',
            "ST-ELIE D'ORFORD",
            'SAINT-PIERRE-BAPTISTE',
            'SAINT-ESPRIT',
            'CAPLAN',
            'C ST L',
            'REPENTIGNY',
            'MONT-SAINT-PIERRE',
            'BAIE-DES-SABLES',
            'BLANC-SABLON',
            'ST EPIPHANE',
            'SAINTE-VICTOIRE-DE-SOREL',
            'FERMONT',
            'DANVILLE',
            'CHATEAU RICHER',
            'SAINT-ALBAN',
            'SAINTE-AGATHE-DE-LOTBINIERE',
            "L' ISLET",
            'SAINT-PATRICE-DE-BEAURIVAGE',
            'CAUSAPSCAL',
            'SAINTE MARTHE',
            'SAINT-MODESTE',
            'BOIS DES FILION',
            'BOISCHATEL',
            'SAINT-ELOI',
            'GROS-MORNE',
            'PIERREFONDS',
            'SAINT-EVARISTE-DE-FORSYTH',
            'LAMBTON',
            'CHSBRG',
            'COURCELLES',
            'DOLLARD-DES ORMEAUX',
            'SAINT-LEON',
            'SAINT-RENE-DE-MATANE',
            'SAINTE-AURELIE',
            'CHAMBORD',
            'CHRISTIEVILLE',
            'SAINT-PAUL-DE-LA-CROIX',
            'SAINT-VINCENT-DE-PAUL',
            'SAINTE-CLOTILDE-DE-BEAUCE',
            'SAINTE-HELENE-DE-KAMOURASKA',
            "SAINTE-PERPETUE-DE-L'ISLET",
            'SAINT EUSTACHE',
            "STE-EMILLIE DE L'ENERGIE",
            'ISSDN',
            'BUCKINGHAM',
            'DESCHAILLONS-SUR-SAINT-LAURENT',
            'ST-JEAN',
            'TREMBLAY',
            'SAINT-IRENEE',
            'BAPTISTE DE LA SALLE ANN',
            'HEBERTVILLE-STATION',
            'LAC CARRE',
            'ST-DENIS DE BRAMPTON',
            'FRONTENAC',
            'VILLEROY',
            'SAINTE-CROIX',
            'LAC-ETCHEMIN',
            'BAIE-SAINTE-CATHERINE',
            'SAINTE-ROSE-DU-NORD',
            'MONT SAINT HILAIRE',
            'MISTISSINI',
            'LOURDES DE JOLIETTE',
            'DESBIENS',
            "SAINT-ALEXANDRE D'IBERVILLE",
            'SAINTE-ANNE-DE-LA-PERADE',
            'ST D B',
            'TAILLON',
            'ST MAURICE',
            'TERR',
            'NORTH HATLEY',
            'CLOVA',
            'SPROC',
            'STE-ANNE-DU-SAULT',
            'SCOTT',
            'STORNOWAY',
            'STE-MARTHE-DU-CAP',
            'SAINT-LOUIS-DE-BLANDFORD',
            'LAVERLOCHERE',
            'LES ILES-DE-LA-MADELEINE',
            'SAINT-JE?RO?ME',
            'KUUJJUAQ',
            'LAC-DELAGE',
            'ST-JEAN CHRYSOSTOME',
            'ST CUTH',
            'ESSIPIT',
            'ST-JOACHIM-DE-MONTMORENCY',
            'CANTON TREMBLAY',
            'SAINT-AUGUSTIN',
            'BEEBE',
            'STE BARBE',
            'PORT DE MONTREAL',
            'FOSSAMBAULT-SUR-LE-LAC',
            'CHOOSE ONE',
            'MONT JOLI',
            'SAINT GEORGES',
            'ST BASIL LE GRAND',
            'HAVRE AUBERT',
            'ST-AUGUSTIN',
            'STE MARIE',
            'SAINTE-ROSE',
            "ST-CHRISTOPHE D'ARTHABASKA",
            'SAINTE-HELENE-DE-MANCEBOURG',
            'BRYSON',
            'BOUL. BRIEN',
            'ST-GEDEON-DE-BEAUCE',
            'HEBERTVILLE',
            'ST JULIENNE',
            'ST FELICIEN',
            'SAINTE-MARCELLINE-DE-KILDARE',
            'SHIPSHAW',
            'SAINT-GEORGES-DE-CHAMPLAIN',
            'LAC SIMON',
            'ST-FRANCOIS-XAVIER-DE-BROMPTON',
            'NOTRE-DAME-DU-LAC',
            'GRAND-SAINT-ESPRIT',
            'BONSECOURS',
            'SCOTSTOWN',
            'TRAITEUR.EBENE@GMAIL.COM',
            'OGDEN',
            'AVENUE PAPINEAU',
            'SAINT-HUBERT-RIVIERE-DU-LOUP',
            'MARBLETON',
            'LA PRESENTATION',
            'BISHOPTON',
            'EAST',
            'ABERCORN',
            'VALLEYFIELD',
            "ILE D'ORLEANS",
            'SAINT-CAMILLE-DE-LELLIS',
            'SAINT-EUGENE-DE-GRANTHAM',
            'HOPE TOWN',
            'SAINT-GODEFROI',
            'SAINT-GERVAIS',
            'STANSTEAD',
            'MARSOUI',
            'ST RM',
            'RIV DU LOUP',
            'MONTREAL OUEST',
            'NOTRE-DAME-DES-MONTS',
            'ASCOT CORNER',
            'ST-PHILIPPE',
            'ST-GABRIEL-DE-KAMOURASKA',
            'SAINT-LUCIEN',
            'WESTBURY',
            'CANADA CEMENT BLDG',
            'LAC-DU-CERF',
            'SABREVOIS',
            'LAMARCHE',
            'SAINT-EDMOND-DE-GRANTHAM',
            'POINTE-AUX-OUTARDES',
            "CAP-D'ESPOIR",
            'MASSUEVILLE',
            'STE THERESE',
            "KINNEAR'S MILLS",
            'SAINT-JOACHIM-DE-SHEFFORD',
            'DEUX MTS',
            'SAINT-JOSEPH-DE-HAM-SUD',
            "SAINT-LEONARD-D'ASTON",
            'AUBERT-GALLION',
            "B D'URFE",
            'ST JACQ',
            'ST COME',
            'DALMAS',
            'SAINT-PHILIBERT',
            'STE-CLAIRE-DE-DORCHESTER',
            'RIVIERE-SAINT-PAUL',
            'SAINT-ROMUALD',
            '1 RIVER SIDE',
            'SAINT-PLACIDE',
            'DURHAM-SUD',
            'SAINT-LOUIS',
            'SAINT HUBERT',
            'SAINTE-HENEDINE',
            'ST BON',
            'SAINT-BERNARD-DE-MICHAUDVILLE',
            'SAINT-DENIS-SUR-RICHELIEU',
            'ST-COME LINIERES',
            'ST-PIERRE-LAMY',
            'SAINT-LIN',
            'LEFEBVRE',
            'MANCEBOURG',
            'ST DAM',
            'CENTRE ROCKLAND',
            'STE BRIG S',
            'MONTREAL WEST',
            'ST MARTHE SUR LE LAC',
            'ST-JEAN-CHRYSOSTOME',
            'NOYAN',
            'SAINT-BERNARD-DE-LACOLLE',
            "L'ISLE-VERTE",
            'SAINT-BLAISE-SUR-RICHELIEU',
            'FRELIGHSBURG',
            'PROULXVILLE',
            'CANTON BEDFORD',
            'ST JEROME',
            'STE-GERM-DE-BOUL',
            'WASKAGANISH',
            'ST-ROCH-DE-RICHELIEU',
            'NAMUR',
            'STE MARTINE',
            'LAC-MINOGAMI',
            'ST REMI',
            'STE-JULIENNE',
            'STE ROSE',
            'SAINT-GEORGES-DE-MALBAIE',
            'SAINT-FULGENCE',
            'MISTASSINI',
            "STE EMELIE DE L'ENERGIE",
            'MTL N',
            'ESTEREL',
            'MONT-ROYAL',
            'OUJE-BOUGOUMOU',
            'ASBESTOS',
            'PT-GAT',
            'SAINT-NARCISSE-DE-RIMOUSKI',
            'SAINT-CUTHBERT',
            "L'ANGE-GARDIEN",
            'SAINT-ELZEAR-DE-BEAUCE',
            'SAINT-CONSTANT',
            'SAINT-ZENON',
            'SAINT JULIE',
            'SAINT-JEAN-PORT-JOLI',
            "SAINTE-EMELIE-DE-L'ENERGIE",
            'SHAWINIGAN SECTEUR GRAND-MERE',
            'SAINT-JEAN-DES-PILES',
            'SAINT-DAMASE-DE-MATAPEDIA',
            'OKA',
            'ST FLX DE V',
            'NESMISCAU',
            'SAINT-ROBERT',
            'HINCHINBROOKE',
            'COMPTON',
            'SAINT-CALIXTE',
            'KINGSEY-FALLS',
            "SAINT-OMER-L'ISLET",
            'FRANKLIN',
            'SAINT-ALPHONSE-RODRIGUEZ',
            'SAINT-DONAT',
            'STE AGATHE DES MONTS',
            'SAINT-VIANNEY',
            'CHARLEMAGNE',
            'ARUNDEL',
            'CALIXA-LAVALLEE',
            'ST-ETIENNE-DE-BEAUHARNOIS',
            'RIVIERE-BLEUE',
            'ST-ONESIME',
            'HUBERDEAU',
            'BRADOR',
            'CHATEAU-RICHER',
            'SAINTE-MADELEINE',
            'LEMIEUX',
            'VAL DES LACS',
            'CAND',
            'SAINT-DAMASE-DES-AULNAIES',
            'ST-ANNE-DU-LAC',
            'SAINT-LOUIS-DU-HA-HA',
            'POINTE-CALUMET',
            'MASCOUCHE',
            'SAINT-BASILE-LE-GRAND',
            'HAVELOCK',
            'CAP DE LA MADELEINE',
            'SAINT-CLET',
            'ST URBAIN PREMIER',
            'SAINTE-ANNE-DES-LACS',
            'SAINTE-GERMAINE-BOULE',
            'ST-COME-LINIERE',
            'SAINT-LOUIS-DE-GONZAGUE',
            'SAINT-MALACHIE',
            'KAMOURASKA',
            'TROIS-PISTOLES',
            'FRONT',
            'POINTE-FORTUNE',
            'ST-POLYCARPE',
            'DONNACONA',
            'ST-NICOLAS',
            'SAINT-MALO',
            'LATERRIERE',
            'SAINT-ANICET',
            'RUE HERTEL SHERBROOKE (QUEBEC)',
            'CHENEVILLE',
            'MOUNT ROYAL',
            'ST-ANICET',
            'GRENVILLE-SUR-LA-ROUGE',
            'MONTREAL( SAINT-LAURENT)',
            'VAL-BARRETTE',
            'SAINT-BENOIT',
            'AUMOND',
            'NOTRE-DAME-DES-PRAIRIES',
            'FASSETT',
            'GRAND-MERE',
            'ST-CLEOPHAS-DE-BRANDON',
            'BOUCHETTE',
            'LA CROCHE',
            'VAL-DES-BOIS',
            'SAINTE-URSULE',
            "VAL-D'OR",
            'SAINT-DOMINIQUE-DU-ROSAIRE',
            'ILE ST IGNACE',
            'SAINTE-CECILE-DE-MASHAM',
            'ASTON-JONCTION',
            'NOMININGUE',
            'RIVIERE-HEVA',
            'SALLUIT',
            'LANTIER',
            'RADISSON',
            'SAINT-MARCEL-DE-RICHELIEU',
            'NOTRE-DAME-DE-LA-SALETTE',
            'SAINTE-CATHERINE-DE-LA-JACQUES-CARTIER',
            'STE-CATHERINE-DE-LA-JACQUES-CARTIER',
            'ST-PAUL-DE-MONTMINY',
            'SAINT-LIGUORI',
            'SAINT-LUDGER-DE-MILOT',
            'MARIEVILLE',
            'TRECESSON',
            'MANDEVILLE',
            "SAINT-CYRILLE-DE-L'ISLET",
            'SAINT-BRUNO-DE-GUIGUES',
            'FABRE',
            'SAINTE-CECILE-DE-WHITTON',
            'SANMAUR',
            'BEARN',
            'GUERIN',
            "SAINT-PAUL-DE-L'ILE-AUX-NOIX",
            'CANTON STANSTEAD',
            "SAINT-THEODORE-D'ACTON",
            'COWAN',
            'TRACY',
            'STE-HELENE',
            'RAWDON',
            "ST-A D'HOW",
            'RICHELIEU',
            'SAINT-PIE',
            "ST ANDRE D'ARGNTL",
            'MONT-LAURIER',
            'LERY',
            'GAT',
            'MALIOTENAM',
            'SAINT-BASILE',
            'SAINT-MARC-DES-CARRIERES',
            'MASKINONGE',
            'MONTMAGNY',
            'MONTREAL-EST',
            'HOWICK',
            'STE-VERONIQUE',
            'MADLE',
            'ISLE-AUX-COUDRES',
            'SAINTE-FOY',
            'SAINT-SEBASTIEN-DE-FRONTENAC',
            'STE-ANNE DE BEAUPRE',
            'PORTNEUF',
            'BELLEFEUILLE',
            'ST-ROBERT',
            'SAINT-URBAIN',
            'SAINT-CASIMIR',
            'PERIBONKA',
            'SAINT-GABRIEL-DE-VALCARTIER',
            'RIVIERE DU LOUP',
            'STE CATH DE LA J CARTIER',
            'SAINT-HILARION',
            'SAINTE-ROSE-DE-WATFORD',
            'SHERRINGTON',
            'STE-CATHERINE-DE-LA-J-CARTIER',
            'SAINT-JOSEPH-DE-LA-RIVE',
            'SAINTE-PETRONILLE',
            'SAINTE-ANNE-DE-BEAUPRE',
            'SAINT-BERNARD-SUR-MER',
            'ST-STANILAS DE KO',
            'LAC-DES-PLAGES',
            'RAPID LAKE',
            'PSPBC',
            'SAINT-NORBERT',
            'SAINT-ALPHONSE-DE-CAPLAN',
            'SAINT-LEONAD',
            'MONTREAL EAST',
            'RIVERE-BEAUDETTE',
            'CARLETON',
            'ST FAUST LAC CARRE',
            'CAP-ST-IGNACE',
            'ST-JOSEPH DE BEAUCE',
            'GRACEFIELD',
            'CARLETON-SUR-MER',
            'SAINTE-ANNE-DE-SOREL',
            'MONT SAINT-HILAIRE',
            'PLACE VENDOME',
            'SAINT-JEAN-DE-LA-LANDE',
            'PORT-CARTIER',
            'LA DURANTAYE',
            'S EUGN',
            'WOBURN',
            'BETSIAMITES',
            'ST-BONAVENTURE',
            'SAINT-PIE-DE-GUIRE',
            'LA REDEMPTION',
            'VMS',
            'SHAWINIGAN ST-TITE',
            'MONT ST HILAIRE',
            'ST-CYPRIEN',
            'RAPIDE-DANSEUR',
            'SAINT-DAMIEN-DE-BUCKLAND',
            'SOMER',
            "L'ILE-AUX-COUDRES",
            'SAINT-ULRIC',
            'LAC A LA CROIX',
            'N D DE LRDES',
            'OMERVILLE',
            'SAINT-GUILLAUME',
            'TEMISCAMING',
            'MONTPELLIER',
            'RIVE-SUD',
            'LONGUEUIL (ST-HUBERT)',
            'LAC-CHEMIN',
            'HEROUXVILLE',
            'SAINT-PACOME',
            'STE-ROSE-NORD',
            'SQUATEC',
            'SAINT-HONORE-DE-TEMISCOUATA',
            'ST-URBAIN',
            'ADSTOCK',
            'SAINT-PAMPHILE',
            "SAINT-CHRISTOPHE D'ARTHABASKA",
            'LA BALEINE',
            'DUBERGER-LES SAULES',
            'RIVIERE-AU-RENARD',
            'CHANDLER',
            'ST G CHAMP',
            'LAC-AUX-SABLES',
            'GAB',
            'SAINTE-JUSTINE',
            'AUPALUK',
            'SAINT-URBAIN-PREMIER',
            'BIENCOURT',
            'PIOPOLIS',
            'SAINT-GABRIEL-DE-RIMOUSKI',
            'PRICE',
            'GRANTHAM',
            'ST-J-CHRYS',
            'LONG',
            'PONT-ROUGE',
            'SAINT-MATHIEU-DE-LAPRAIRIE',
            'EASTMAN',
            'LOTS-RENVERSES',
            'SAINT-ARSENE',
            'SAINT-LEON-LE-GRAND',
            'POINTE-A-LA-CROIX',
            'HAVRE ST-PIERRE',
            'LA GUADELOUPE',
            'ST ANTONIN',
            'FORESTVILLE',
            'DUPUY',
            'TEMISCOUATA-SUR-LE-LAC',
            'SAWYERVILLE',
            'CAZAVILLE',
            'NOTRE-DAME-DE-PONTMAIN',
            'POHENEGAMOOK',
            'ST MAJOR GR',
            'ST-AMBLE',
            'SAINTE-CLAIRE',
            'SAINT-MARC-SUR-RICHELIEU',
            'NOTRE-DAME-DU-PORTAGE',
            'CARIGNAN',
            'SAINT-JEROME',
            'SAINT-DIDACE',
            'GRANDE-VALLEE',
            "SAINT-ELIE-D'ORFORD",
            'SAINT-PROSPER-DE-DORCHESTER',
            'KANAWAKE',
            'ST-PIERRE-DE-BROUGHTON',
            'BUCKLAND',
            'SCHEFFERVILLE',
            'SAINT-HONORE-DE-SHENLEY',
            'SAINT-MATHIEU-DU-PARC',
            'MILE END',
            'LES GALERIES DE LA CAPITALE',
            'BAIE-COMEAU',
            'SAINT-LUDGER',
            'SAINTE-BARBE',
            'RIVIERE-ROUGE',
            'SAINT-ZACHARIE',
            'STE-ANNE-DE-SOREL',
            'ST MATHIEU',
            'MT LAUR',
            'SAINT-ALEXIS-DE-MONTCALM',
            'ST-PRIME',
            'GIRARDVILLE',
            'BOLTON-EST',
            'MORIN-HEIGHTS',
            'SAINT-PHILIPPE-DE-NERI',
            'CH LAC DES RAPIDES',
            'ST-CYRILLE',
            'CARTIERVILLE',
            'ST-GERMAIN-DE-KAMOURASKA',
            'VALLEE-JONCTION',
            'WASWAN',
            'DRMVL',
            'ST-BLAISE',
            'SAINT-VALERIEN',
            'COTEAUX DU LAC',
            'DUHAMEL',
            'CHELSEA',
            'SAINT-GEORGES DE BEAUCE',
            'SAINT-EPIPHANE',
            'SAINT-ANTONIN',
            'SAINT-RENE',
            'SAINT-BONIFACE',
            'NOTRE-DAME-DE-MONTAUBAN',
            'SAINT-JOSEPH-DE-COLERAINE',
            'SAINTE-MELANIE',
            'MONT STE-ANNE',
            'ST-VALERE',
            'SAINT LAURENT',
            'SAINT-ALEXIS-DEMONTCALM',
            'MILAN',
            'SHERNGTN',
            "L'ANCIENNE-LORETTE",
            'TRING-JONCTION',
            'ST JEAN',
            'SAINT-BENOIT-LABRE',
            'ANGLIERS',
            'BECANCOUR',
            'SAINTE-ELISABETH',
            'SAINTE-AGATHE-DES-MONTS',
            'CACOUNA',
            'SAINT-LAMBERT QC',
            'LAFONTAINE',
            'BEACONSFIELD',
            'PT CAL',
            'ST LUC',
            'BEAUMONT',
            'SAINT-OMER',
            'JONQUIERE',
            'ST-JOSEPH-DE-LA-POINTE-DE-LEVY',
            'EAST BROUGHTON',
            'LAROUCHE',
            'BERTHIER-SUR-MER',
            'HOTEL REINE ELIZABETH',
            'YAMASKA-EST',
            'ST-GEORGES-DE-BEAUCE',
            'SAINT-EPHREM-DE-BEAUCE',
            'SAINTE-TH?R?SE',
            'ST LIGUORI',
            'ST-FRANCOIS-DE-LA-RIVIERE-DU-S',
            'SAINTE-MARIE-DE-BEAUCE',
            'ILE-DES-SOEURS',
            'SAINTE-SERAPHINE',
            'PABOS',
            'SAINT-ALPHONSE DE GRANBY',
            'MONTCERF-LYTTON',
            "D'ALEMB",
            'ST-SIMEON',
            'SAINT-EDOUARD-DE-NAPIERVILLE',
            'VICTORIAVILLE',
            'SAINT-CHARLES-DE-BELLECHASSE',
            'MOR HTS',
            'BROME',
            "L'ANGE-GARDIEN-DE-ROUVILL",
            'SEUGL',
            'POINTES-AUX-TREMBLES',
            'SAINT-HONORE-DE-CHICOUTIMI',
            'ANSE ST JN',
            'ABITIBI-TEMISCAMINGUE',
            'RIVIERE-ETERNITE',
            'BEDF',
            'SAINTE-BEATRIX',
            'SAINT-GEDEON',
            'LAVAL-STHER',
            'ST-FAUSTIN-LAC-CARRE',
            'STE-CECILE-DE-LEV',
            'KIPAWA',
            'SAINT-GEORGES-DE-CLARENCEVILLE',
            'DANFORD LAKE',
            'NDLOUR',
            'STE-MARIE',
            'ST-PLACIDE',
            'SAINT-ELZEAR',
            'NEW CARLISLE',
            'WESTMOUNT',
            'LAC-BOUCHETTE',
            'LA POC',
            'PARC INDUSTRIEL CENTRE',
            'KANGIQSUALUJJUAQ',
            'ST GREGOIRE',
            'LISTUGUJ',
            'SAINT-ISIDORE',
            'POINTE-A-LA-GARDE',
            'MT ST HILAIRE',
            'MIRABEL (DOMAINE VERT NORD)',
            'SAINT-LAMBERT-DE-LAUZON',
            'SAINTE MARTHE SUR LE LAC',
            'JOLIETTE',
            'LES HAUTEURS',
            'SAINT-FRANCOIS-MONTMAGNY',
            'COOKSHIRE-EATON',
            'MILLE-ISLES',
            'LAC-NOMININGUE',
            'ST-MAJORIQUE',
            'BASSIN',
            "L'ASCENSION-DE-NOTRE-SEIGNEUR",
            'SAINT-ODILON',
            "ST-FRANCOIS-ILE-D'ORLEANS",
            'GRANDE-RIVIERE',
            'AKULIVIK',
            'SAINT-SYLVESTRE',
            'SAINTE-MARCELLINE',
            'SAINT-JEAN-DE-MATHA',
            'ST-BRUNO DE MONTARVILLE',
            'SAINT-AGAPIT',
            'ST-EUSTACHE',
            'BLUE SEA',
            'QUABEC',
            'HATLEY',
            "SAINT-ALEXANDRE-D'IBERVILLE",
            'POINTE-DES-CASCADES',
            'ST-NAZAIRE-DU-LAC-ST-JEAN',
            'AYLMER WEST',
            'FALARDEAU',
            'AERO BAGVLE',
            'SAINT-COME-LINIERE',
            'TINGWICK',
            'SAINTS-ANGES',
            'SAINT-CLEOPHAS',
            'ST BRUNO DE MONTARVILLE',
            'POINTE-LEBEL',
            'DOLB MISTSN',
            'ST RAYMOND',
            'OUTREMONT',
            'CHAPAIS',
            'SGNVE',
            'MONTREAL',
            'IVRY-SUR-LE-LAC',
            '(TERREBONNE)',
            'NORMETAL',
            'CH DE LA MONTAGNE',
            'GASCONS',
            'SAINT-ETIENNE-DES-GRES',
            'IVUJIVIK',
            'GRANDE-ENTREE',
            'ST-GEORGES-DE-CHAMPLAIN',
            'BPRT',
            'ST-VINCENT-DE-PAUL',
            'ST-NAZAIRE',
            'CHERTSEY',
            'STE MARG LAC MASS',
            'ST-REDEMPTEUR',
            'SAINT-SYLVERE',
            'ST MEDARD',
            'LAMBERT',
            'CHAMBLY',
            'SAINTE-JUSTINE-DE-NEWTON',
            'RIVIERE-A-CLAUDE',
            'HEBERVL STN',
            'SAINT-REDEMPTEUR',
            'WATERVILLE',
            'HOCHELAGA',
            "L'ANSE-SAINT-JEAN",
            'CAP-SAINT-IGNACE',
            'SAINT-SEVERE',
            'LOTBINIERE',
            'P A T',
            'HUDSON',
            'LAC-SIMON',
            'SAINT-NARCISSE',
            'SAINTE-THECLE',
            'SAINT-ANTOINE-DE-TILLY',
            'SAINT-PLACIDE-DE-BEARN',
            'SAINT-ZEPHIRIN',
            'STE CATHERINE',
            'L HAUT',
            'LAC-FOUILLAC',
            'ST TELESPHORE',
            'AYLMER',
            'LAC-EDOUARD',
            'WASWANIPI',
            'RIVIERE BLEUE',
            'LORRAINVILLE',
            'STE ANNE SAB',
            'SAINT-ALBERT',
            'DAVELUYVILLE',
            'ST-GUILLAUME',
            'ST-PIE',
            'ST NICOLAS',
            'FRAMPTON',
            'BECAN',
            'MARIA',
            'ST ANICET',
            'LAURENTIDES',
            'SAINTE-EULALIE',
            'SAINT-SAMUEL',
            'N D DES NEIGES',
            'CHARNY',
            'SAINT-EUGENE-DE-GUIGUES',
            'NOTRE DAME DES NEIGES',
            'ST-FEL-DE-DALQ',
            "L'ACADIE",
            'BEAUPORT',
            'FATIMA',
            'YAMACHICHE',
            'SAINTE-HEDWIDGE-DE-ROBERVAL',
            'ST-ALPHONSE',
            'STE-CROIX',
            'SAINTE-ANNE-DES-MONTS',
            'ISSOUDUN',
            'SAINT-ADELPHE-DE-CHAMPLAIN',
            "MANCHE-D'EPEE",
            'SAINT-VENANT-DE-PAQUETTE',
            'ST MATHIEU DE BELOEIL',
            'ROBERVAL',
            'BREBEUF',
            'SAINT-MARC-DU-LAC-LONG',
            'HAVRE-AUX-MAISONS',
            'ST-ALBERT',
            'NEUVILLE',
            'ST BNO',
            'ST-GEORGES-DE-WINDSOR',
            'SAINTE-GERTRUDE-MANNEVILLE',
            'SAINT-JACQUES',
            'SAINTE-ANNE-DES-MONTS--TOURELLE',
            'LES COTEAUX',
            'GASPE',
            'SAINT-ANNE-DE-BEAUPRE',
            'LES MECHINS',
            'SAINT-FRANCOIS-XAVIER-DE-VIGER',
            'MONT-JOLI',
            'RIMOUSKI',
            "AYER'S CLIFF",
            'LAVALTRIE',
            'DEGELIS',
            'RIVIERE-DU-LOUP',
            'ST JOSEPH DE SOREL',
            'MOUNT-ROYAL',
            'ST M BELOEIL',
            'ST-MARC-DES-CARRIERES',
            'SAINT-PASCAL',
            'SACRE-COEUR',
            'SAINT-JEAN-DE-CHERBOURG',
            "ST-ANDRE D'ARGENTEUIL",
            "SAINT-CHRISTOPHE-D'ARTHABASKA",
            'STE-ANNE-DES-MONTS',
            'PAPVL',
            'QUEBEC',
            'SAINT-VITAL-DE-CLERMONT',
            'ST-CYRILLE DE WENDOVER',
            'CHAZEL',
            'CONTRECOEUR',
            'SAINT-REMY-DE-TINGWICK',
            'SAINT-SIMEON',
            'ST-EDOUARD-DE-NAPIERVILLE',
            'MARTINVILLE',
            'BEAUCEVILLE',
            'SAINT-GEORGES',
            'SAINT-LIN-LAURENTIDES',
            'NANTES',
            'ENTRELACS',
            'SAINT-RAYMOND',
            'SI OCCUPE COMPOSEZ',
            'HEMMINGFORD',
            'STE-MARCELLINE',
            'DELSON',
            'IRLANDE',
            'ST ETIENNE DE LZN',
            'ST-CHARLES-BORROMEE',
            'NOTRE-DAME-DE-LA-MERCI',
            'PRINCEVILLE',
            'SAINT-CYRILLE-DE-WENDOVER',
            'NORBERTVILLE',
            'SAINTE-BRIGITTE-DE-LAVAL',
            'COTE ST. LUC',
            'VAL-PARADIS',
            'SAINT-THEOPHILE',
            'SAINT-AUBERT',
            'METABETCHOUAN-LAC A LA CROIX',
            'QUAQTAQ',
            'STTHEODACT',
            "L'ASCENSION",
            "SAINT-MATHIEU-D'HARRICANA",
            'ST-DAMASE',
            'STE-BLANDINE',
            'GROCH',
            'ST-PIERRE-DE-VERONE',
            'THETFORD MINES',
            'ST CESAIRE',
            'PT CLR',
            'KNOWLTON',
            'CHTMI',
            'GRANDE RIVIERE',
            'SAINTE ANNE DE BEAUPRE',
            'ROCK FOREST',
            'STANBRIDGE EAST',
            'SAINT-ISIDORE-DE-CLIFTON',
            'SAINT-ALEXANDRE',
            'SAINT-AMBROISE',
            'SAINT-ANDRE-EST',
            'ST-VALERIEN DE MILTON',
            'LAC-KENOGAMI',
            'LA POCATIERE',
            'L RAP',
            'MACAMIC ( SECTEUR COLOMBOURG )',
            'S C B',
            'LACOLLE',
            'STE-APOLLINE-DE-PATTON',
            'LONDON',
            'ST ETIENNE',
            'DOLBEAU-MISTASSINI',
            'SAINT-PRIME',
            'DOLLARD-DES-ORMEAUX',
            'MONT TREMBLANT',
            'CHARETTE',
            'BLAINVILLE',
            'SEPT-ILES',
            'CTE SAINT-LUC',
            'CAP-CHAT',
            'ST-ANTOINE-SUR-RICHELIEU',
            'STE-MADELEINE',
            'MELOCHEVILLE',
            'METABETCHOUAN',
            "SAINT-ANDRE D'ARGENTEUIL",
            'SAINT-CHARLES-BORROMEE',
            'SACRE-COEUR-SAGUENAY',
            'CHIBOUGAMAU',
            'FUGEREVILLE',
            'LANOUETTE',
            "SAINTE CHRISTINE D'AUVERGNE",
            'SAINTE-SABINE',
            'UPTON',
            'SAINT-HYPOLITE',
            'AKWESASNE',
            'ST DAMASE',
            'ST DENIS SUR RICH',
            'SAINT-ETIENNE-DE-BEAUHARNOIS',
            'G-MERE',
            'SAINTE-AGNES-DE-DUNDEE',
            'ANJOU',
            'FABREVILLE',
            'CAP-AUX-MEULES',
            'MONTREAL-NORD',
            'PREISSAC',
            'SAINT-LEONARD',
            'CANTLEY',
            'ST-JOACHIM-DE-SHEFFORD',
            'SAINT-UBALDE',
            'RIVIERE-A-PIERRE',
            'SAINT-FLAVIEN',
            'GRENVILLE',
            'STE-ANNE-DE-SABREVOIS',
            'BEAUHARNOIS',
            'SAINT-EDOUARD-DE-LOTBINIERE',
            "L'ANGE GARDIEN",
            'COOKSHIRE',
            'MATANE E',
            'LAC-SAINTE-MARIE',
            'BROMONT',
            'GATINEAU',
            'ST-HENEDINE',
            'LAVAL-OUEST',
            'CABANO',
            'ST MATHIAS RICH',
            'ST-JEAN-SUR-RICHELIEU',
            "SAINTE-BRIGIDE-D'IBERVILLE",
            'ST JN DE M',
            'ST-JANVIER',
            'SAINT-CLEOPHAS-DE-BRANDON',
            'VAUDREUIL-DORION',
            'BARRAUTE',
            'ST-HILAIRE',
            'CHARLESBOURD',
            'ST-JEAN SUR LE RICHELIEU',
            "L'EPIPHANIE",
            'NOTRE DAME DU MONT CARMEL',
            'HENRYVILLE',
            'SAINTE-MARIE-SALOME',
            'SAINTE-MARTHE',
            '.',
            'MONTREAL,QUEBEC',
            'LOUVICOURT',
            'SAINT-FAUSTIN-LAC-CARRE',
            'RIVIERE-DES-PRAIRIES-POINTE-AUX-TREMBLES',
            'CHESTERVILLE',
            'SAINT-FELIX-DE-VALOIS',
            'ROXTON FALLS',
            'ST IGNACE DE STANBRIDGE',
            'SAINT-FELIX-DE-DALQUIER',
            'ST-VALLIER',
            'CRABTREE',
            'GESGAPEGIAG',
            'ETANG-DU-NORD',
            'GUYENNE',
            'BLACK CAPE',
            'CLORIDORME',
            'LEBEL-SUR-QUEVILLON',
            'SAINT-ALEXIS-DE-MATAPEDIA',
            'COURCELETTE',
            'ST ALEX',
            "L'ILE-CADIEUX",
            'LOURDES',
            'POINTE-AUX-TREMBLES',
            'SAINT-JUSTIN',
            'ST-JOSEPH-DE-COLERAINE',
            'WAKEFIELD',
            'VILLEBOIS',
            'RIGAUD',
            'COLERAINE STATION',
            'SAINT-FELICIEN',
            'L BROME',
            'PRINCVL',
            'SAINT-ETIENNE-DE-BOLTON',
            'SAINT-FRANCOIS-DU-LAC',
            'KIRKLAND WEST ISLAND',
            'LONGUEUIL',
            'FRANQUELIN',
            'STSIM BAGOT',
            'SAINT-CHRYSOSTOME',
            'SAINT-PAULIN',
            'VILLE-MARIE',
            'ST-JOVITE',
            'ATHELSTAN',
            'LAC-BEAUPORT',
            'LES BERGERONNES',
            "L'AVENIR",
            'NEMISCEAU',
            'ILE BIZ',
            'SAINT-ARMAND',
            'ROCKLAND',
            'LAC A LA TORTUE',
            'MANSONVILLE',
            'SAINT-ZEPHIRIN-DE-COURVAL',
            'SAINT-ANACLET',
            'NOTRE-DAME-DES-BOIS',
            'SAINT-GILBERT',
            'ST-BASILE-LE-GRAND',
            'ST-MATH-D-BEL',
            'ST-MICHEL-DES-SAINTS',
            'SAINTE-BRIGITTE-DES-SAULTS',
            'STE AURELIE',
            'SAINT-ALEXIS-DES-MONTS',
            'DRUMMONDVILLE',
            'SAINT LAMBERT',
            "L'ILE-DU-GRAND-CALUMET",
            'ANGE-GARDIEN',
            'G MERE',
            'ST GAB',
            'VERCHERES',
            'BOULANGER',
            'SAINTE-CECILE-DE-MILTON',
            'ROCHEBAUCOURT',
            'HAVRE-SAINT-PIERRE',
            'BOLTON-OUEST',
            'MATAPEDIA',
            'WATERLOO',
            'STE ANNE DE SABREVOIS',
            'DUNHAM',
            'SAINT-OURS',
            'CANTON-DE-HATLEY',
            'TADOUSSAC',
            'BOIS-FRANC',
            'BOUCHERVILLE',
            'LECORC',
            'ILE PER',
            'SAINTE-JULIENNE',
            'SAINT-NARCISSE-DE-BEAURIVAGE',
            'ACTON VALE',
            'PAPINEAUVILLE',
            'SAINT-LIBOIRE',
            'SAINT-THURIBE',
            'SAINT-DOMINIQUE',
            'SAINT-JEAN-DE-BREBEUF',
            'COTE SAINT-LUC',
            'SAINTE-HELENE-DE-BAGOT',
            'SAINTE-CATHERINE-DE-HATLEY',
            'L MEG',
            'CAMPBELLS BAY',
            'LA BAIE',
            'CHTEAUGUAY',
            'ST LAZ',
            'MONT-SAINT-GREGOIRE',
            'SHER',
            'ST JOS LAC',
            'SAINT-COME',
            'BOILEAU',
            'ST POLYCARPE',
            'OTTAWA-GATINEAU METRO AREA',
            'JOLY',
            'SAINT-SIMEON-DE-BONAVENTURE',
            'SAINT-PIERRE-LES-BECQUETS',
            'SAINT-DAMASE',
            'PORTNEUF-SUR-MER',
            'SAINT-GERMAIN-DE-GRANTHAM',
            'PARC-DE-LA-VERENDRYE',
            'PIERREVILLE',
            'SAINT-BONAVENTURE',
            'NOTRE-DAME-DE-PORTNEUF',
            'SAINT-IGNACE-DE-LOYOLA',
            'SAINTE-CLOTILDE-DE-HORTON',
            'ST-PAULIN',
            'GUELPH',
            "SAINTE-JEANNE-D'ARC",
            'GUIGUES',
            'COWANSVILLE',
            "SAINT-ROCH-DE-L'ACHIGAN",
            'SHAW',
            'GENEVIEVE',
            'SAINT-AMBROISE-DE-KILDARE',
            'STE-MELANIE',
            'SAINT-JEAN-BAPTISTE',
            'SAINT-HILAIRE-DE-DORSET',
            'NEMISCAU',
            'DISRAELI',
            'FARHNAM',
            'ST JOS DE COL',
            'SAINT-MICHEL',
            'ASSOMPTION',
            'SAINTE-ANNE-DE-LA-ROCHELLE',
            'SAINTE-ANGELE-DE-MERICI',
            'NOUVELLE',
            'FRANKLIN CENTRE',
            'STE-CLOTILDE',
            'MIDDLE BAY',
            'RTE DU LAC-POULIN',
            'PIEDMONT',
            "L'ISLE-AUX-COUDRES",
            'DNVL',
            'MT ROYAL',
            'LA PLAINE',
            'SAINT-MARTIN',
            'VIEUX LONGUEUIL',
            'RUISSEAU',
            'LANORAIE',
            'METABETCHOUAN--LAC-A-LA-CROIX',
            'SAINTE-CECILE-DE-LEVRARD',
            'LES-SAULES',
            'ALBANEL',
            'STE ANNE DES PLAINES',
            'SAINTE-THERESE',
            'CANDIAC',
            'SAINT-ANDRE-DE-KAMOURASKA',
            'LAC-DES-ECORCES',
            'HEROUVILLE',
            'SAINT-VICTOR',
            'SAINT-MICHEL-DES-SAINTS',
            'VRNS',
            'KAHNAWAKE',
            'SUTTON',
            'LOW',
            'HUNTINGDON',
            'LES EBOULEMENTS',
            'VAL-BELAIR',
            "ST-ADOLPHE D'HOWARD",
            'BERTHIERVILLE',
            'EGAN',
            'MARIE',
            'SOULANGE',
            'EVAIN',
            'CLERMONT',
            'STE ELIZABETH DE PROULX',
            'BOUCHRVLL',
            'SAINT-SEBASTIEN',
            'SHAW TOWNSHIP',
            'STE-JULIE-DE-VERCHERES',
            'STOKE',
            'SAINT-CESAIRE',
            'SAINT-EDOUARD-DE-MASKINONGE',
            'CAP-SANTE',
            'STE MARCLNE',
            'ST-AUGUSTIN-DE-DESMAURES',
            'VERCH',
            'SAINT-JEAN-SUR RICHELIEU',
            'SHAWINIGAN-SUD',
            'MONTEBELLO',
            "L'ETANG-DU-NORD",
            'SAINT-REMI',
            'PONT ROUGE',
            'SAINT-CHARLES-SUR-RICHELIEU',
            "L'ILE-BIZARD",
            'PALMAROLLE',
            'SAINT-CYPRIEN-DES-ETCHEMINS',
            'STANBRIDGE STATION',
            'BOULEVARD HOCHELAGA QUEBEC',
            'ST CLET',
            'BROWNSBURG CHATHAM',
            'SAINT-JACQUES-DE-LEEDS',
            'SAINT-PHILIPPE',
            'POINTE-CLAIRE',
            'SAINT-AMABLE',
            'SAINET-ADELE',
            'SAINTE-CLOTILDE-DE-CHATEAUGUAY',
            'N-D-DE-STANBRIDGE',
            'SGOD',
            'SAINT-MAGLOIRE',
            'CH ROCKLAND',
            'AUCLAIR',
            'RISTIGOUCHE-SUD-EST',
            'LA MALBAIE',
            'SHAWVILLE',
            "SAINT-NAZAIRE-D'ACTON",
            'SAINT-ZOTIQUE',
            'ST-LEON-DE-STANDON',
            'LAC-MEGANTIC',
            'BROSSARD',
            'NOTRE-DAME-DU-MONT-CARMEL',
            'STANSTD',
            'SAINT-RAPHAEL',
            'ST PLACIDE',
            'SAINT-GERMAIN',
            'SAINT-SULPICE',
            'ST-FELIX-DE-KINGSEY',
            'ST GUILL',
            'GROSSES-ROCHES',
            'GEORGEVILLE',
            'PREVOST',
            'GALLIX',
            "SAINT-ANDRE-D'ARGENTEUIL",
            'SAINTE-CHRISTINE',
            'ST-MARC-DU-LAC-LONG',
            'PARENT',
            'PASPEBIAC',
            'STE CLOT',
            'BCHVL',
            'ST-JOSEPH-DE-SOREL',
            'BEGIN',
            'LABELLE',
            'SAINT-FABIEN-DE-PANET',
            'CHUTE-SAINT-PHILIPPE',
            'SAINT-DONAT-DE-MONTCALM',
            'SAINT-FRANCOIS-XAVIER',
            'NOTRE-DAME-DU-NORD',
            "BAIE-D'URFE",
            'ST MICHEL DES SAINTS',
            'SAINT-DAVID',
            'SAINT-ISIDORE-DE-LAPRAIRIE',
            'CLARENCEVILLE',
            'LAC SAINTE-MARIE',
            'LA PATRIE',
            'ST-ALPHONSE-RODRIGUEZ',
            'ST BASILE LR GRAND',
            'HAVRE-AUBERT',
            'JOL',
            'SAINT-LAZARE',
            'SAINT-ROBERT-BELLARMIN',
            'MOFFET',
            'VAUDREYUIL DORION',
            'TASCHEREAU',
            'WICKHAM',
            'HARRINGTON HARBOUR',
            'ST-CES',
            'SAINTE-GENEVIEVE',
            'VAL-SAINT-GILLES',
            'MELBOURNE',
            'GALERIES DE HULL',
            'PERCE',
            'SAINT-JEAN-DE-DIEU',
            'SAINT-GABRIEL-DE-BRANDON',
            'SAINT-HUGUES',
            'SAINT-GILLES',
            'NOTRE-DAME-DE-STANBRIDGE',
            'MESSINES',
            'MERCIER',
            'ST-ADRIEN',
            'SAINT-NICEPHORE',
            'ST PIE DE BAGOT',
            'SAINT-URBAIN-DE-CHARLEVOIX',
            'MACAMIC',
            'RICHMOND',
            'WARWICK',
            'POULARIES',
            'ORMSTOWN',
            'SAINTE-MARTHE-SUR-LE-LAC',
            'ST-LIN - LAURENTIDES',
            'RIVIERE-BEAUDETTE',
            'WEST BROME',
            "L'ANSE ST JEAN",
            'NAPIERVILLE',
            'ST FAUSTIN LAC CARRE',
            'SAINT-JEAN-SUR-RICHELIEU',
            'ST-LAZARE-DE-VAUDREUIL',
            'MT ST HILR',
            'CHAMPNEUF',
            'SAINTE SOPHIE',
            'THURSO',
            'ST-FRANCOIS-XAVIER-DE-VIGER',
            'MALARTIC',
            'LINGWICK',
            'SAINT-AIME-DES-LACS',
            'SAINT-LOUIS-DU-HA! HA!',
            'MONTCALM',
            'SAINT-JOSEPH-DE-SOREL',
            'TERREBONNE',
            'PETITE-RIVIERE-SAINT-FRANCOIS',
            'LACHINE',
            'NOTRE-DAME-DE-LA-PAIX',
            'NEDELEC',
            'ST BAS L G',
            'SAINT-ALEXANDRE-DE-KAMOURASKA',
            'ST EDOUARD DE NAPIERVILLE',
            'PACKINGTON',
            'PIKE RIVER',
            'EAST HEREFORD',
            'ST ARSENE',
            'ST-ED-DE-FABRE',
            'STNHM',
            'BAIE-SAINT-PAUL',
            'FOSTER',
            'GRAND-REMOUS',
            'CAMPEMENT-SARCELLE',
            'SAINT-THOMAS',
            'CAP-DE-LA-MADELEINE',
            'ST-SAUVEUR-DES-MONTS',
            'LAC SAGUAY',
            'ST-URBAIN-DE-CHARLEVOIX',
            'SAINT-PROSPER',
            'LAC-AU-SAUMON',
            'SAINT-JUDE',
            'VAL-DES-MONTS',
            'TRING',
            'GETHSEMANI',
            'QUE CANADA',
            "ST-LAURENT-D'ORLEANS",
            'SAINT-ROSAIRE',
            'ST-BLAISE-SUR-RICHELIEU',
            'ARMAGH',
            'RIPON',
            'SAINT-ANDRE-DU-LAC-SAINT-JEAN',
            'SAINT-ANTOINE-ABBE',
            'MATANE',
            'MIRABELLE',
            "L'ISLE-AUX-GRUES",
            'RIVIERE OUELLE',
            'SAINTE-ANNE-DE-BELLEVUE',
            'BOIS-DES-FILION',
            'SILLERY',
            'GODMANCHESTER',
            'ROXBORO',
            'SAINT-WENCESLAS',
            'STE-CECILE DE MILTON',
            'ST-PAUL-DE-JOLIETTE',
            'PLAISANCE',
            'KIAMIKA',
            'ST BRUNO',
            'DUHAMEL-OUEST',
            'MONTCERF LYTTON',
            'VENDEE',
            'AUSTIN',
            'SAINT-ANSELME',
            'LES CEDRES',
            'BAIE-DU-FEBVRE',
            'EATN',
            'VIMONT',
            'ST-VALERIEN',
            'ST-LUCIEN',
            "L'ISLET",
            'SAINT-ONESIME',
            'SAINT-ANDRE-DE-RESTIGOUCHE',
            'N D PN',
            "VAL D'OR",
            'BEAUCANTON',
            'DUPARQUET',
            'SAINT-VALENTIN',
            'STE-JULIE',
            'LAC-CAYAMANT',
            'VAL-DES-LACS',
            'STE ANNE ROCH',
            'R POND',
            "STE-JEANNE-D'ARC-DE-MATANE",
            'SAINT-ODILON DE CRANBOURNE',
            "SAINT-PIERRE-ILE-D'ORLEANS",
            'SAINT-FERREOL-LES-NEIGES',
            'FERLAND ET BOILLEAU',
            'CANTON DE BARNSTN',
            'SAINT-MATHIEU-DE-BELOEIL',
            'VALCOURT',
            'BROWNSBURG-CHATHAM',
            'ST-HENRI-DE-TAILLON',
            'STE-GERM-BOUL',
            'ST HYA',
            'ST GAB DE B',
            'ROUGEMONT',
            'LA DORE',
            'DEUX-MONTAGNES',
            'KANGIQSUJUAQ',
            'SAINTE-JULIE',
            'SAINT-JEAN-CHRYSOSTOME',
            'ST-JEROME',
            'ST-JUDE',
            'CHAPEAU',
            'ST-LIN-LAUREN',
            'ST-JERMAIN',
            'SAINT-DAMIEN',
            'GESGA',
            'BATISCAN',
            'BRISTOL',
            'SAINT-COLOMBAN',
            'BROMPTONVILLE',
            'HAMPSTEAD',
            'MONTEREGIE',
            'ST-EPHREM-DE-BEAUCE',
            'LOURDES-DE-JOLIETTE',
            'SAINTE JULIE',
            'FERME-NEUVE',
            'WENDAKE',
            'RIVIERE-MADELEINE',
            'STONEHAM-ET-TEWKESBURY',
            'SAINT-HERMENEGILDE',
            'ST HERMNGLD',
            'GALLICHAN',
            'LUSKVILLE',
            'SAGUENAY',
            'RXBO',
            'RGMT',
            'SAINT-EUSTACHE',
            'NOTRE DAME DES PRAIRIES',
            'NORMANDIN',
            'SENNETERRE',
            'RIVIERE-DES-PRAIRIES',
            'NOTRE-DAME-DE-RIMOUSKI',
            'BRIGHAM',
            'SAINTE-ANNE-DES-PLAINES',
            'LAC MEGANTIC',
            'LA RONDE',
            'LENNOXVILLE',
            'ST-JER',
            'SAINT-JOSEPH-DE-LEPAGE',
            'BASE VLCR',
            'SAINTE-ANGELE-DE-PREMONT',
            'SAINTE-ELISABETH-DE-PROULX',
            'SAINT-HENRI-DE-LEVIS',
            'MTL NORD',
            'LAURIER-STATION',
            'LA CORNE',
            'ST HONORE DE CHICOUTIMI',
            'ST-REMI DE TINGWICK',
            'STE A DES P',
            'ST GUILLAUME',
            'MONT-LOUIS',
            'BROSS',
            'ST MCH DES S',
            'SHAWINIGAN',
            'BCHAT',
            'ST BONIFACE DE SHAWINIGAN',
            'BEDFORD',
            'SAINT-SIMON-DE-BAGOT',
            'LEMOYNE',
            "SAINT-ADOLPHE-D'HOWARD",
            'SAINT-ALPHONSE-DE-GRANBY',
            'ST DAVID DE FALARDEAU',
            'NICOLET',
            'LE BIC',
            'SAINT-VALERIEN-DE-MILTON',
            "SAINT-FRANCOIS-D'ASSISE",
            'GRANBY',
            'GREENFIELD PARK',
            'METABETCHOUAN-LAC-A-LA-CROIX',
            'FARNHAM',
            'STRATFORD',
            'SAINT-ALEXIS',
            "SAINT-EUGENE-D'ARGENTENAY",
            'SAINTE-MARIE',
            'SAINT-MATHIAS-SUR-RICHELIEU',
            'EAST ANGUS',
            'COATICOOK',
            'ST-STANISLAS DE KOSTKA',
            'STE-ANNE DE LA ROCHELLE',
            'SALABERRY DE VALLEYFIELD',
            'LAC-DES-ILES',
            'ST MICH DES S',
            'VENISE-EN-QUEBEC',
            'THUNDER BAY',
            'SAINT-CYPRIEN',
            'SAINT-ANDRE-AVELLIN',
            'HOPE',
            'N D PIN',
            'LES ESCOUMINS',
            'SAINTE-MARTINE',
            'PIERREFDS',
            'LAVLTR',
            'CHOMEDEY',
            'PETITE-VALLEE',
            'LANDRIENNE',
            'SAINT-JACQUES-LE-MINEUR',
            'ST SAUVEUR',
            'SHEFFORD',
            'SAINTE-CATHERINE-DE-LA-J-CARTIER',
            'SAINT-MAURICE',
            'RAINVILLE',
            'VERDUN',
            'GRANDE-ILES',
            'VAL-MORIN',
            'BELOEIL',
            'SAINT-TITE',
            'N D DU MONT CARMEL',
            'STE-FRANCOISE',
            'MONT-SAINT-HILAIRE',
            'BERTH',
            'SAINT-BARNABES-S',
            'METIS-SUR-MER',
            'SAINT-HONORE',
            'THETFORD-MINES',
            'SAINT-MAJORIQUE',
            'SAINT-JOACHIM',
            'ST-FAUSTIN',
            'LAVAL-DES-RAPIDES',
            'KIRKLAND',
            'SAINT-SIMON-DE-RIMOUSKI',
            'ST-LOUIS DU HA HA',
            'SAINT-ANTOINE-DE-KENT',
            'PLESSISVILLE',
            'SAINT-TELESPHORE',
            'LOUISEVILLE',
            'SAINT-AUGUSTIN-DE-DESMAURES',
            'KINGSBURY',
            'SAINTE-AGATHE',
            'SAINT-ALPHONSE-DE-RODRIGUEZ',
            'SALABERRY-DE-VALLEYFIELD',
            "RUE DE L'UNIVERSITE (QUEBEC)",
            'TROIS-RIVIERES',
            'SAINT-BENJAMIN',
            'MASHTEUIATSH',
            'SAINT-LAURENT',
            'STE-MARGUERITE-DE-DORCHESTER',
            'ST JANVIER',
            'ST-CHARL-BORR',
            'SUT',
            'RACINE',
            'SAINT-HUBERT',
            'ST-BRIGITTE DES SAULTS',
            'SAINT-ROCH-DE-RICHELIEU',
            'S C DE JESUS',
            'LAVAL',
            'STE-ANNE-DU-LAC',
            'STE-ANNE-DE-BEAUPRE',
            'FORT-COULONGE',
            'SULLIVAN',
            'AGUANISH',
            'SAINTE-CATHERINE',
            'VERDUM',
            'SAINT-JANVIER',
            'STE-VICTOIRE-DE-SOREL',
            'SAINTE-AGATHE-NORD',
            'ST LAMBERT',
            "ST-ROCH-DE-L'ACHIGAN",
            'SAINT-STANISLAS-DE-KOSTKA',
            'SAINT-IGNACE-DE-STANBRIDGE',
            "ST FRANCOIS L'ILE D'ORLEANS",
            "L'ASSOMPTION",
            'CAPAUX MEULES',
            'ST JEAN DE CHRYSOSTOME',
            'ALMA',
            'MATAGAMI',
            'SAINT-VALLIER',
            'SAINTE-GENEVIEVE-DE-BATISCAN',
            'ST-LIBOIRE',
            'ESCUMINAC',
            'DORVAL',
            'EASTMAIN',
            'BAIE-ST-PAUL',
            'CHICOUTIMI',
            "ST THEODORE D'ACTON",
            'SAINT-CAMILLE',
            'ULVERTON',
            'MAYO',
            'LAC-SAINT-PAUL',
            'ST-ALEXANDRE-DE-KAMOURASKA',
            'VARENNES',
            'SAINT AMABLE',
            'SAINTE-FELICITE',
            "ST-CHRISTOPHE-D'ARTHABASKA",
            'ROUYN-NORANDA',
            'MONT ST-HILAIRE',
            'STE-MARGUERITE-DU-LAC-MASSON',
            'ST EUSTACHE',
            'LORRAINE',
            'SAINTE-ANNE-DE-LA-POCATIERE',
            'ST FRANCOIS XAVIER DE BROMPTON',
            'MONTREAL-OUEST',
            'SAINT-HIPPOLYTE',
            'LACHUTE',
            'ST-GABRIEL-DE-BRANDON',
            'SAINTE-ANNE-DE-SABREVOIS',
            'CHATEAUGUAY',
            'DORION',
            'LAFORCE',
            'ST LNT',
            'AMHERST',
            'MANDVL',
            'EAST FARNHAM',
            'SAINT-JOSEPH-DE-BEAUCE',
            'SAINT-PAUL',
            'ST AIME DES LACS',
            'MOISIE',
            'CHISASIBI',
            'ST-JOSEPH-DE-KAMOURASKA',
            'GATINEAU-OTTAWA',
            'MANIWAKI',
            'ST JACQUES',
            'SAINT-ANTOINE-SUR-RICHELIEU',
            "L'ILE-PERROT",
            'ST-AMBROISE-DE-CHICOUTIMI',
            "NOTRE-DAME-DE-L'ILE-PERROT",
            "ST-ELIE-D'ORFORD",
            "ST-ALEXANDRE-D'IBERVILLE",
            'SHIPTN',
            'TOWN OF MOUNT ROYAL',
            'ST-GREGOIRE',
            "SAINT-PAUL-D'ABBOTSFORD",
            'LA PECHE',
            "SAINT-JEAN-DE-L'ILE-D'ORLEANS",
            'ROXTON POND',
            'ST ZOTIQUE',
            'AMOS',
            'SAINT-JUST-DE-BRETENIERES',
            'AVENUE ST-SACREMENT',
            'NOTRE-DAME-DU-LAUS',
            'ST-MICHEL-DE-BELLECHASSE',
            'ST. LEONARD',
            'REMIGNY',
            'STE AGA DES MONTS',
            'DOLBEAU MISTASSINI',
            'L SIMON',
            'SAINTE-LUCIE-DES-LAURENTIDES',
            'NATASHQUAN',
            'LA TUQUE',
            'SAINT-PAUL-DE-MONTMINY',
            '',
            'LATR',
            'RANG PORT-AUX-QUILLES',
            'STE SOPHIE',
            'ARNTFIELD',
            'UASHAT',
            'LAPRAIRIE',
            'STANDON',
            'LEVIS',
            'VAL-DAVID',
            'LSL',
            'ST-MATHIEU',
            'HONFLEUR',
            'ROUTHIERVILLE',
            'RUE DU QUAI',
            'ST-HUBERT',
            'SAINT-EMILE-DE-SUFFOLK',
            'HAM-NORD',
            'ST GEORGES',
            'SAINT-PIERRE-DE-BROUGHTON',
        ],
    },

    /* 4 */
    {
        _id: 'SK',
        cities: [
            'ROSENTHERN',
            'JAN LAKE',
            'SASKATCHEWAN(SK)',
            'ALBERT',
            'CORINNE',
            'PAKWAW LAKE',
            'RM OF VANSCOY #345',
            'ANGLIN LAKE',
            'PENNANT',
            'SHERWOOD',
            'LUMSDEN BEACH',
            'BUCKLAND',
            'MANITOU LAKE',
            'MOOSE RANGE',
            'FIR MOUNTAIN',
            'BIG QUILL',
            'EAGLE CREEK',
            'UNORGANIZED',
            "NORTH QU'APPELLE",
            'NEAUNLAGE',
            'BELLEVUE',
            'SAINT GREGOR',
            'MOZART',
            'ST WABURG',
            'SK',
            'DISTRICT OF KATEPWA',
            'LAC DES ILES',
            'SAINT BRIEUX',
            'ST. WALBURG',
            'YELLOW QUILL',
            'CROSSMOUNT',
            'VERIGIN',
            'SCOTT LAKE',
            'COLFAX',
            'KATEPWA BEACH',
            'CHAMBERLIN',
            'WALDRON',
            'WILLOWBROOKE',
            'LAPORTE',
            'LANGLEY',
            'PORTREEVE',
            'THODE',
            'MENDHAM',
            'ZEHNER',
            'BRIGHT SAND',
            'ROKEBY',
            'MCMAHON',
            'KENDAL',
            'JEDBURGH',
            'GOULDTOWN',
            'CARDROSS',
            'PRAIRIE VIEW',
            'COURVAL',
            'LOVERNA',
            'WOODROW',
            'PASQUA',
            'FLAXCOMBE',
            'GREENWATER LAKE',
            'MESKANAW',
            'GRASSWOOD',
            'ADMIRAL',
            'RICETON',
            'WHITECAP',
            'MANITOU BEACH',
            'RM OF SHERWOOD',
            'LAJORD',
            'STEELMAN',
            'EMMA LAKE',
            'LIVELONG',
            'KIPLING STATION',
            'LLOYDMINISTER',
            'WHITE CITY',
            'DUFFERIN',
            'DENARE BEACH',
            'PAMBRUN',
            'PRELATE',
            'MAPPLE CREEK',
            'LANCER',
            'VIDORA',
            'ROCKHAVEN',
            'ANEROID',
            'NEIDPATH',
            'COWESSESS',
            'RICHARD',
            'ONION LAKE',
            'WASECA',
            'SPEERS',
            'MORRIS',
            'WATERHEN LAKE',
            'HOOSIER',
            'LACADENA',
            'PENNANT STATION',
            'TESSIER',
            'YOUNG',
            'SONNINGDALE',
            'ARELEE',
            'CORMAN PARK',
            'KELFIELD',
            'SCOTT',
            'ENGLEFELD',
            'CARMEL',
            'LANDIS',
            'ST. BRIEUX',
            'WARMAN',
            'PADDOCKWOOD',
            'SPRUCE HOME',
            'TIMBER BAY',
            'LITTLE BEAR LAKE',
            'BLACK LAKE',
            'PARKBEG',
            'RUSH LAKE',
            'ORMISTON',
            'HAGEN',
            'DRINKWATER',
            'RIVERHURST',
            'MARQUIS',
            'STRONGFIELD',
            'CLAYBANK',
            'CARON',
            'STALWART',
            'FILMORE',
            'STEWART VALLEY',
            'MARENGO',
            'FINDLATER',
            'FLIN FLON',
            'MARKINCH',
            'EDENWOLD',
            'NORTH BATLEFORD',
            'BELLE PLAINE',
            'MONT NEBO',
            'SHAMROCK',
            'CYMRIC',
            'BROOKSBY',
            'MCTAGGART',
            'FOSSTON',
            'TRIBUNE',
            'LAKE ALMA',
            'CLEARWATER',
            'WOOD MOUNTAIN',
            'BEAUBIER',
            'WELWYN',
            'BURR',
            'TUFFNELL',
            'MIKADO',
            'YELLOW CREEK',
            'TANTALLON',
            'STENEN',
            'DEER VALLEY',
            'RAMA',
            'ALBERTA/',
            'EMERALD PARK',
            'STURGEON LANDING',
            'HAZENMORE',
            'HAZLET',
            'STORNOWAY',
            'TOMPKINS',
            'SANDY BAY',
            'DESCHAMBAULT LAKE',
            'PIAPOT',
            'VAL MARIE',
            'PELICAN NARROWS',
            'SAINT VICTOR',
            'FRONTIER',
            'VAWN',
            'ARDILL',
            'NEVILLE',
            'VANGUARD',
            'SPRUCE LAKE',
            'MAPLE CREEK',
            'GARDINER DAM',
            'GALLIVAN',
            'PATUANAK',
            'MAKWA',
            'LONE ROCK',
            'KHEDIVE',
            'LOON LAKE',
            'DELMAS',
            'SASKATCHEWAN',
            'MAYMONT',
            'ZEALANDIA',
            'DAVIN',
            'CACTUS LAKE',
            'MEDSTEAD',
            'COCHIN',
            'MARSHALL',
            'FRENCHMAN BUTTE',
            'GREEN LAKE',
            'DORINTOSH',
            'PIERCELAND',
            'MEOTA',
            'TURNOR LAKE',
            'MERVIN',
            'GLASLYN',
            'WISETON',
            'GLENBUSH',
            'BALDWINTON',
            'FISKE',
            'BUFFALO NARROWS',
            'MACRORIE',
            'CLEARWATER RIVER',
            'BEAUVAL',
            'GRONLID',
            'BIRSAY',
            'ARDATH',
            'GRAVELBOURG',
            'DEMAINE',
            'SCEPTRE',
            'HARRIS',
            'GOODWATER',
            'LASHBURN',
            'FIFE LAKE',
            'DENZIL',
            'TUXFORD',
            'GRENFELL',
            'DILLON',
            'RAPID VIEW',
            'BUSHELL PARK',
            'ST BENEDICT',
            'CONQUEST',
            'NEILBURG',
            'EATONIA',
            'COLEVILLE',
            'PLENTY',
            'LOCKWOOD',
            'MOOSEJAW',
            'KINLEY',
            'ROSE VALLEY',
            "PRUD'HOMME",
            'MACDOWALL',
            'BLADWORTH',
            'CHELAN',
            'PLUNKETT',
            'LAKESIDE',
            'RADISSON',
            'FENWOOD',
            'FULDA',
            'GUERNSEY',
            'OSLER',
            'CRYSTAL SPRINGS',
            'HEPBURN',
            'DUVAL',
            'BJORKDALE',
            'DALMENY',
            'SPRINGWATER',
            'DRAKE',
            'HERSCHEL',
            'BRACKEN',
            'WHITEWOOD',
            'WALDHEIM',
            'LAMPMAN',
            'COLONSAY',
            'CUDWORTH',
            'ANNAHEIM',
            'BEATTY',
            'WEBB',
            'OXBOW',
            'PARADISE HILL',
            'WEIRDALE',
            'SIMPSON',
            'KILLALY',
            'PAYNTON',
            'GRANDORA',
            'BRADWELL',
            'CANOE NARROWS',
            'ABERDEEN',
            'WEYAKWIN',
            'HOLBEIN',
            'SOVEREIGN',
            'GLENTWORTH',
            'HAGUE',
            'WELDON',
            'ASQUITH',
            'FOX VALLEY',
            'BUENA VISTA',
            'ST ISIDORE DE BELLEVUE',
            'HOEY',
            'CUMBERLAND HOUSE',
            'BORDEN',
            'BELLEGARDE',
            'PARKSIDE',
            'CLIMAX',
            'BROCK',
            'WAKAW',
            'MIDALE',
            'BANKEND',
            'WASKESIU LAKE',
            'BALGONIE',
            'WOLLASTON LAKE',
            'MILDEN',
            'ATWATER',
            'LIMERICK',
            'MEATH PARK',
            'MILDRED',
            'SOUTHEND',
            'ABBEY',
            'MAIDSTONE',
            'CANDLE LAKE',
            'LEASK',
            'URANIUM CITY',
            'ESTON',
            'STONY RAPIDS',
            'CANDIAC',
            'HAFFORD',
            'TYVAN',
            'CANWOOD',
            'LESLIE',
            'PREECEVILLE',
            'CUT KNIFE',
            'BIG RIVER',
            'WALDECK',
            'STANLEY MISSION',
            'SHAUNAVON. SASK',
            'MORSE',
            'LIPTON',
            'WILLOW BUNCH',
            'PELLY',
            'LAFLECHE',
            'ROCANVILLE',
            'HENRIBOURG',
            'MARSDEN',
            'LOREBURN',
            'ORKNEY',
            'HERBERT',
            'BRODERICK',
            'KINDERSLEY',
            'CARRAGANA',
            'KINCAID',
            'KINISTINO',
            'SENLAC',
            'DINSMORE',
            'CORNING',
            'ELBOW',
            'KISBEY',
            'MACNUTT',
            'DOMREMY',
            'DAVIDSON',
            'FOAM LAKE',
            'CENTRAL BUTTE',
            'SINTALUTA',
            'RM OF ST LOUIS',
            'LLOYDMINSTER',
            'MARCELIN',
            'BETHUNE',
            'WYNYARD',
            'SILTON',
            'TOGO',
            'ARCOLA',
            'MINTON',
            'VIBANK',
            'FLEMING',
            'NETHERHILL',
            'RUTHILDA',
            'FAIRLIGHT',
            'SASKATOON',
            'DYSART',
            'CUPAR',
            'RICHARDSON',
            'CADILLAC',
            'CRAIK',
            'CHAPLIN',
            'BENGOUGH',
            'THEODORE',
            'INVERMAY',
            'HOLDFAST',
            'YELLOW GRASS',
            'WAPELLA',
            'NORTH BATTLEFORD',
            'BALCARRES',
            'GOVAN',
            'MACKLIN',
            'ESTERHAZ',
            'EARL GREY',
            'WOLSELEY',
            'LEBRET',
            'FROBISHER',
            'ALSASK',
            'TRAMPING LAKE',
            'KRONAU',
            'KINDERSLY',
            'CREELMAN',
            'MAZENOD',
            'RIDGEDALE',
            'QUILL LAKE',
            'BUCHANAN',
            'PRAIRIE RIVER',
            'BLAINE LAKE',
            'REDWING',
            'PANGMAN',
            'MISTATIM',
            'GLIDDEN',
            'GRAY',
            'SEMANS',
            'SEDLEY',
            'WEEKES',
            'LIBERTY',
            'VICTOIRE',
            'GLENSIDE',
            'MONTMARTRE',
            'FAIRY GLEN',
            'TURTLEFORD',
            'BRIERCREST',
            'AYLESBURY',
            'BROADVIEW',
            'OGEMA',
            'ST WALBURG',
            'ARCHERWILL',
            'STAR CITY',
            'DUNDURN',
            'BURSTALL',
            'ESTERHAZY',
            'ZENON PARK',
            'GERALD',
            'GAINSBOROUGH',
            'DESLISLE',
            'BROMHEAD',
            'RADVILLE',
            'FORGET',
            'ASSINIBOIA',
            'NOKOMIS',
            'MANOR',
            'CARONPORT',
            'LANGENBURG',
            'BROWNLEE',
            'GLEN EWEN',
            'MARYFIELD',
            'STORTHOAKS',
            'GLADMAR',
            'TORQUAY',
            'SNOWDEN',
            'OUNGRE',
            'HODGEVILLE',
            'CORONACH',
            'VEREGIN',
            'JANSEN',
            'ALIDA',
            'WILLOWBROOK',
            'REDVERS',
            'ROSTHERN',
            'CARIEVALE',
            'DUBUC',
            'LEOVILLE',
            'STOCKHOLM',
            'ALAMEDA',
            'RABBIT LAKE',
            'PINEHOUSE LAKE',
            'LEROSS',
            'HUBBARD',
            "QU'APPELLE",
            'IMPERIAL',
            'FERLAND',
            'WHITE BEAR',
            'LAKE LENORE',
            'BULYEA',
            'VANSCOY',
            'MARGO',
            'WATSON',
            'ST LOUIS',
            'JEANETTE LAKE',
            'DENHOLM',
            'GOODEVE',
            'DODSLAND',
            'SPY HILL',
            'LANG',
            'ALVENA',
            'NORQUAY',
            'SPIRITWOOD',
            'KUROKI',
            'CODERRE',
            'ROSETOWN',
            'REGINA SK',
            'KIPLING',
            'RHEIN',
            'SUCCESS',
            'WINDTHORST',
            'WILKIE',
            'KYLEMORE',
            'SALTCOATS',
            'CHITEK LAKE',
            'PILOT BUTTE',
            'ROULEAU',
            'WYMARK',
            'KENOSEE LAKE',
            'SHELL LAKE',
            'CROOKED RIVER',
            'ARRAN',
            'PEEBLES',
            'SMEATON',
            'CALDER',
            'HYAS',
            'RAYMORE',
            'COLE BAY',
            'BREDENBURY',
            'MANKOTA',
            'SYLVANIA',
            'HANDEL',
            'MARTENSVILLE',
            'KENASTON',
            'SAINT BENEDICT',
            'SHELLBROOK',
            'ABERNETHY',
            'MUENSTER',
            'CODETTE',
            'LESTOCK',
            'DUCK LAKE',
            'CARNDUFF',
            'WROXTON',
            'QUINTON',
            'PLEASANTDALE',
            'WILCOX',
            'WHITE FOX',
            'MEADOW LAKE',
            'COLGATE',
            'CRAVEN',
            'MCLEAN',
            'AVONHURST',
            'GRAYSON',
            'WISHART',
            'SASKATOO',
            'LA RONGE',
            'CREIGHTON',
            'HALBRITE',
            "FORT QU'APPELLE",
            'LA LOCHE',
            'MUSKODAY',
            'ROCKGLEN',
            'HUMBOLDT',
            'KELVINGTON',
            'GOLDEN PRAIRIE',
            'DAFOE',
            'PENSE',
            'MAYFAIR',
            'AIR RONGE',
            'ALBERTVILLE',
            'MACOUN',
            'MIDDLE LAKE',
            'ESTEVAN',
            'GLENBAIN',
            'HANLEY',
            'CLAYDON',
            'PERDUE',
            'NAICAM',
            'CARROT RIVER',
            'SMILEY',
            'VANCOUVER',
            'MONTREAL LAKE',
            'BEECHY',
            'ST BRIEUX',
            'INSINGER',
            'GRIFFIN',
            'PILGER',
            'SHEHO',
            'WADENA',
            'LAIRD',
            'LINTLAW',
            'VONDA',
            'AYLSHAM',
            'DILKE',
            'FOND DU LAC',
            'MEACHAM',
            'WATROUS',
            'KAMSACK',
            'ELROSE',
            'SPALDING',
            'INDIAN HEAD',
            'CLAVET',
            'WEYBURN',
            'MAJOR',
            'HUDSON BAY',
            'LAKE',
            'REGINA BEACH',
            'BANGOR',
            'BATTLEFORD',
            'STRANRAER',
            'SPRINGSIDE',
            'SOUTHEY',
            'SPRING VALLEY',
            'PONTEIX',
            'LUMSDEN',
            'DELISLE',
            'UNITY',
            'ANTLER',
            'FILLMORE',
            'FRANCIS',
            'EYEBROW',
            'ILE-A-LA-CROSSE',
            'KELLIHER',
            'EBENEZER',
            'STRASBOURG',
            'CONSUL',
            'EDAM',
            'MEYRONNE',
            'PORCUPINE PLAIN',
            'OSAGE',
            'ODESSA',
            'ASSINIOBOIA',
            'GULL LAKE',
            'ALLAN',
            'ENDEAVOUR',
            'ISLAND LAKE',
            'ST DENIS',
            'ELFROS',
            'KEELER',
            'CANORA',
            'CHOICELAND',
            'DANBURY',
            'CHRISTOPHER LAKE',
            'OKLA',
            'LUCKY LAKE',
            'HAWARDEN',
            'OUTLOOK',
            'WAWOTA',
            'ARBORFIELD',
            'SWIFT CURRENT',
            'YORKTON',
            'MELFORT',
            'LOVE',
            'REGINA',
            'DEBDEN',
            'MOSSBANK',
            'ST GREGOR',
            'NORTH PORTAL',
            'CABRI',
            'HEWARD',
            'KYLE',
            'LEADER',
            'NEUDORF',
            'VISCOUNT',
            'BIENFAIT',
            'KENNEDY',
            'CHAMBERLAIN',
            'STOUGHTON',
            'LEROY',
            'HENDON',
            'TROSSACHS',
            'BRUNO',
            'WASKESIU',
            'CEYLON',
            'LEMBERG',
            'FURDALE',
            'AVONLEA',
            'LANGHAM',
            'SHAUNAVON',
            'RIVERSIDE ESTATES',
            '',
            'LANIGAN',
            'GLENAVON',
            'TUGASKE',
            'MORTLACH',
            'CANDO',
            'PUNNICHY',
            'WAUCHOPE',
            'PRINCE ALBERT',
            'BENSON',
            'CARLYLE',
            'PLATO',
            'BIRCH HILLS',
            'DENMAINE',
            'EVENWOLD',
            'NORTH WEYBURN',
            'GOODSOIL',
            'BIGGAR',
            'STONY BEACH',
            'MOOSOMIN',
            'NIPAWIN',
            'MEDICINE HAT',
            'MILESTONE',
            'ITUNA',
            'STONEBRIDGE',
            'FOXFORD',
            'LUSELAND',
            'MELVILLE',
            'KERROBERT',
            'STURGIS',
            'LANGBANK',
            'RICHMOUND',
            'DUPEROW',
            'TISDALE',
            'EASTEND',
            'MOOSE JAW',
            'CHURCHBRIDGE',
        ],
    },

    /* 5 */
    {
        _id: 'YT',
        cities: [
            'YUKON TERRITORY (YT)',
            'KENO',
            'DAWSON CITY',
            'TESLIN',
            'SWIFT RIVER',
            'PELLY CROSSING',
            'CARCROSS',
            'TAGISH',
            'OLD CROW',
            'WHITEHORSE',
            'DESTRUCTION BAY',
            'FARO',
            'FORT WARE',
            'BEAVER CREEK',
            'CARMACKS',
            'ELSA',
            'ROSS RIVER',
            'BURWASH LANDING',
            'MAYO',
            'WATSON LAKE',
            'HAINES JUNCTION',
            '',
            'YT -',
            'DAWSON',
            'MARSH LAKE',
        ],
    },

    /* 6 */
    {
        _id: 'ON',
        cities: [
            'MILLERS',
            'COATSWORTH',
            'MARKSTAY-WARREN',
            'LAWRENCE AND N',
            'MCKENZIE ISLAND',
            'GREATER MADAWASKA',
            'HORNBY STATION',
            'SOUTH HURON',
            'VICTORIA CORNERS',
            'OLIVER PAIPOONGE',
            'MIDDLESEX CENTRE',
            'CENTRAL MANITOULIN',
            'MUIRKIR',
            'NORTH YORK TORONTO ONTARIO',
            'WALLASEY',
            'ORE MEDONTE',
            'NORTH YORK ON',
            'ONTARO',
            'WANUP',
            'CEDARVILLE',
            'TORONTO/ EAST YORK',
            'OSHWA',
            'SAINT JOACHIM',
            'TORRONTO',
            'ONTARIIO',
            'SPRINGWATER TOWNSHIP',
            'TORONTP',
            'LAKE',
            'LAURENTIAN HILLS',
            'HALIBURTON COUNTY',
            'ONTAIO',
            'HILL',
            'N/A',
            'ST. CATHERAINES',
            'P.O BOX:',
            'HALTON REGION',
            'PARIS- BRANTFORD AREA',
            'ONTRARIO',
            'OKVILLE',
            'KITCHENER/WATERLOO AREA',
            'MISSISSUAGA',
            'PARIS ON',
            'KINGSWAY',
            'PICKERING VILLAGE',
            'NORTH WATERLOO',
            'THORNHILL (MARKHAM)',
            'ST. CATHERINES',
            'DORNOCH',
            'ONTATRIO',
            'RIVER',
            'ONTATIO',
            'ONTERIO',
            'ONTARION',
            'TORONTO/MISSISSAUGA GTA',
            'ONTARIA',
            'ONARIO',
            'OTTAWA (NEPEAN)',
            'PETERBOURGH',
            'MISSISSAGUA',
            'BRANTHAVEN ST',
            'BANTING DR',
            'LORMEL GATE',
            'CHUBB',
            'BELGRADE',
            'BARDEN ST',
            'DEVINE ST',
            'ILDERTON, ON',
            'MARIONVILLE',
            'STARR BAY',
            'MONTREAL RD',
            'BLIZZARD VALLEY',
            'GLEN MILLER',
            'ELORA ST',
            'NIPISSING VILLAGE',
            'ELDER STATION',
            'MANITOULIN ISLAND',
            'BLACK RIVER- MATHESON',
            'TORONTO AMF',
            'WEST PERTH',
            'GERMAN MILLS',
            'THORAH',
            'WFSH',
            'INNISFIL BEACH',
            'CALDEON',
            'KAWARTHAS',
            'DOWNTOWN MISSISSAUGA',
            'CABBAGETOWN',
            'FEDERAL NATIONAL DEFENCE DENISON FACILIT',
            'BRAMALEA',
            'OLD TORONTO',
            'NORTH MISSISSAUGA',
            'TILLBURY',
            'BRAMPTON EAST',
            'SIMCOE COUNTY',
            'LEACHCROFT',
            'NON',
            'THORNHIL',
            'BRAMPTON WEST CENTRAL',
            'SCARLETT & EAST DR',
            'SERVING OSHAWA & SURROUNDING AREA',
            'OAKRIDGES',
            'SCARBROUGH',
            'ADMASTON',
            'MOBERT',
            'ANACASTER',
            'HIAWATHA',
            'SHOMBERG',
            'AVONMORE, NORTH STORMONT',
            'SAINT-GEORGE',
            'LAC LA CROIX FIRST NATION',
            'BURNHAMTHORPE & SATURN',
            'YONGE & STEELES',
            'FIRST CANADIAN PLACE',
            'AGINCOURT MALL',
            'MAIN ST',
            'BRIDGE STREET',
            'SALUT STE MARIE',
            'OTTO TWP',
            'GUELPH SOUTHWEST',
            'SHERIN DR',
            'LESKARD',
            'SONYA',
            'VIAMEDE',
            'SNOW ROAD STATION, N FRONTENAC',
            'RICHMONDHILL',
            'KING TOWNSHIP',
            'MALVERN TOWN CENTRE',
            'SEAFORTH INDEPENDENT',
            'LEXINGTON CRT',
            'SHABAQUA',
            'CEYLON',
            'CARLETON PL',
            'ST.MARYS',
            'NORTH DUMFRIES',
            'KEADY',
            'AJAX/PICKERING',
            'PORT BURMELL',
            'MISSISSAUGA SQUARE ONE',
            'STRAIFORD ON',
            'KINGS',
            'ALFRED AND PLANTAGENET',
            'TORONTO & GTA',
            'THORNH',
            'ST LAURENT',
            'SCARBOROUGH ON',
            'THUNDERBAY',
            'KABABEKA FALLS',
            'URLINGTON',
            'STOCO LAKE',
            'TRAIN YARDS OTTAWA',
            'WARWICK',
            'KETTLE & STONY POINT FIRST NATION',
            'VILLAGE OF POINT EDWARD',
            'GERRARD E & SEATON',
            'MT. BRYDGES',
            'POINT CLARKE',
            'CAMBRIDGE EAST',
            'NORTH BAY & AREA',
            'BRUCE',
            'ONONDAGA',
            'CORNER SCOTT & GEORGE',
            'MOORCREST DR',
            'EILBER TWP',
            'ATORONTO',
            'CHATSWOTH',
            'BLUE MOUNTAIN MALL',
            'PICT',
            'AMH',
            'WINSDOR',
            'BLUEWATER BEACH',
            'MAITLD',
            'BEAM',
            'TIMMINSON',
            'STAINER',
            'MNBEM',
            'HAVELOCK ON',
            'W THRLD',
            'LORIGNAL',
            'ACTINOLITE',
            'CARLTON PLACE',
            'GEORGIAN BLUFF',
            'KAWARTHA LAKE',
            'LAKE TEMAGAMI',
            'NEW TECUMSEH',
            'WIKWEMIKONG UNCEDE',
            'ROSEMOUNT',
            'MISSSISSAUGA',
            'SAINT-THOMAS',
            'ORILIA',
            'WESTON',
            'RUSSELL LANDING',
            'DOKIS FIRST NATION',
            'SOUTHHAMPTON',
            'ANTEN MILLS',
            'BELLERIVER',
            'STOCK TWP',
            'SAULTE STE MARIE',
            'TALBOTVILLE',
            "AWERE'S TWP",
            'PUCE',
            'ONATRIO',
            'DESDEN',
            'HORSESHOE VALLEY',
            "ST-MARY'S",
            'BOWWANVILLE',
            'ST-CHARLES',
            "ST. ANN'S",
            'BLINDRIVER',
            'HARRIS',
            'THE BLUE MOUNTAINS',
            'FORRESTERS FALLS',
            'SOUTH DESBARATS',
            'GLADS HILL',
            'PERRY',
            'NORTH SHORE LANE',
            'POINT CLARK',
            'AVONTON',
            'MOUNT CARMEL',
            'PINE GROVE',
            'WEBER ST',
            'FERNDALE',
            'JACQUES',
            'LAKESHORE DR',
            'PORT BRUCE',
            'TORNOTO',
            'MOOSE CREEK VILLAGE',
            'IPPERWASH',
            'COLEMAN TWP',
            'ELDEE',
            'WAUBASHENE',
            'SPRINGVILLE',
            'PERTH EAST',
            'THETFORD',
            'ERIE S',
            'BRUCE CRES',
            'INNISVILLE',
            'QUINTE',
            'FOURTH AV',
            'PALMER DR',
            'TORONOT',
            'LAPPE',
            'PIORT SEVERN',
            'HAILYBURY',
            'SOPERTON',
            'LESTER B PEARSON INTERNATIONAL AIRPORT',
            'MKHM',
            'BLACK STREET',
            'BROSSARD',
            'LASALETTE',
            'TRONTO',
            'BLUEWATER',
            'PARRY SOUND DR',
            'PRETORIA AVENUE',
            'WILLOWDALE',
            'PIC MOBERT',
            'MAIDSONE',
            'ST-ISIDORE-DE-PRESCOTT',
            'DUNVILLE',
            'OSHAWA ON',
            'PICKERING ON',
            'AJAX ON',
            'YORK MILLS',
            'LAKE SAINT PETER',
            'ROSEVILLE',
            'CHAPLEAU OJIBWAY',
            'BROOKVILLE',
            'WHITIBY',
            'NIAGARA',
            'GRAND SUDBURY',
            'BROOKLYN AVE',
            'GLOVERTOWN',
            'ST CLAIR',
            'SCARBORUGH',
            'BALLANTRAE',
            'GOGAMA LAKE',
            'MISSISSAUGA ON',
            'PAINCOURT',
            'BRAMHURST AVE',
            'THORNHILL ON',
            'VAUGHAN ON',
            'GENEVA',
            'BONCAYGEON',
            'DUFFERIN AVE',
            'WHITE ELM',
            'SERVING AURORA AND AREA',
            'UNIONDALE',
            'BLYTHE',
            'HEMLO',
            'COATSWORTH STATION',
            'SOUTHGATE',
            'PRIVATE',
            'LIMERIDGE MALL',
            'HAWKESTON',
            'DYSART AND OTHERS',
            'RAPIDES-DES-JOACHIMS',
            'GRIFFITH',
            'HAMILTON TOWNSHIP',
            'GARDEN RIVER FIRST NATION',
            'MINNITAKI',
            'MONTEVILLE',
            'HYDE PARK',
            'LONG POINT BEACH',
            'WASAGA',
            'MOONLAND RD',
            'WALLWARK STREET',
            'WEST GUILDFORD',
            'BISHOPS MILLS',
            'SOUTH DUNDAS',
            'THORNBRY',
            'HURON SHORES',
            'CENTRAL KW',
            'BAYVIEW GLEN',
            'EMBRIN',
            'TONRONTO',
            'MISSISSAUAGA',
            'KUSHOG LAKE',
            'ST. MARY`S',
            'STAPLES',
            'COMMANDA',
            'HALLEBOURG',
            'OTHER...',
            'VAL COTE',
            'TEHKUMMAH',
            'QUADEVILLE',
            'POINTE AU BARIL',
            'WAIN',
            'BARRHAVEN',
            'MCLAUGHLING AVE',
            'THORNTON AV',
            'MARKETING SOLUTIONS',
            'KASHABOWIE',
            'STRICKLAND',
            'ROEHAMPTON & BAYVIEW',
            'VIRGINIA',
            'NORTH DORCHESTER',
            "ST CATHARINE'S",
            "PLACED'ORLEANS",
            'ETOBICOK',
            'ETOBICOKE ON',
            'BURNHAMTHORPE RD',
            'HILLSBURG',
            'BEAR ISLAND',
            'DINORWIC',
            'CROTON',
            'STITTSVL',
            'SABLES-SPANISH RIVERS',
            'COBURG',
            'GRIMBY',
            'CHAMPLAIN',
            'SOUTHERN ONTARIO',
            'ST-ISIDORE',
            'ROOSMORE',
            'ST CATHERINES',
            'PLYMPTON-WYOMING',
            'HIGHLANDS EAST',
            'WAIRTON',
            'ST-GEORGE BRANT',
            'ABERFOYLE',
            'KIRKLAND',
            'SPRUCE ST',
            'COPELAND',
            'TOWN OF PLYMPTON WYOMING',
            'VARIOUS',
            'ST. GEORGE',
            'CENTRE ST',
            'HNMR',
            'SAULT-SAINTE-MARIE',
            'PT EDWARD',
            'WWW.EXITREALTYHARE.COM',
            'HAIG AVE',
            'KATRINE',
            'GWILLIMBURY',
            'MADAWASKA VALLEY',
            'LAKE OF BAYS',
            'SUNNIDALE',
            'FRONT W',
            'NOVOCO DR',
            "YOUNG'S POINT",
            'MUSKOKA',
            'WENDOVER ONTARIO',
            'BELLFOUNTAIN',
            'SAINT MARYS',
            'POINTE-AU-BARIL',
            'MCARTHURS MILLS',
            'RIVER RD',
            'SAUGEEN SHORES',
            'FOWLER',
            'NO CHARGE-DIAL',
            'COE-HILL',
            'LAVAL',
            'LOYALIST & THE COLLEGEWAY',
            'SALABERRY-DE-VALLEYFIELD',
            'LYNDEN RD',
            "ST DAVID'S",
            'CANADA',
            'BEAVERTON ROAD SOUTH',
            'CORMAC',
            'LAURENTIAN VALLEY',
            'CLARENCE-ROCKLAND',
            'DEVIL LAKE',
            'MARTEN',
            'BELLS CORNERS',
            'OAKRG',
            'SOUTH GLENGARRY',
            "HORNING'S MILLS",
            'SAINT-CHARLES',
            'INISFIL',
            'SOUTH BAYMOUTH',
            'GUELPH/ERAMOSA',
            'MT BRYDGES',
            'RAITH',
            'LONG LAC',
            '(DURHAM & AREA)',
            'ELORA',
            'KETTLE AND STONY POINT FN',
            'WELLENSTEIN',
            'RENFREW',
            'HAMILON',
            'WOODSLEE',
            'ROBLING',
            'HILLSBURGH',
            'NIPISSING DISTRICT',
            'TOTTENHAM',
            'ADELAIDE METCALFE',
            'DON MILLS',
            'CAMBRIDGE ON',
            'WESTWOOD',
            'VILLE MARIE',
            'BATAWA',
            'DURHAM REGION',
            'NORTHEASTERN MANITOULIN AND ISLANDS',
            'SPRINGS LINE',
            'YORK ON',
            'PEN CENTRE',
            'BIRCH ISLAND',
            'REABORO',
            'STRATHROY-CARADOC',
            'HAILEBURY',
            'SPRING BAY',
            'PETROLIA LINE',
            'ARDEN',
            'SCARBOROUH',
            'BLMFLD',
            'WLOO',
            'N0B1E0',
            'SENELON FALLS',
            'KESWICK',
            'RAVENNA',
            'BILLINGS BRIDGE PLAZA',
            'BELLE VALLEE',
            'ROCKWOODON',
            'YORK',
            'HHS-MCMASTER UNIVERSITY MEDICAL CTR',
            'SHAWVILLE',
            'ST. ISIDORE',
            'ODO-MERONTE',
            'AURORA & RICHMOND HILL',
            'LOCALLY OPERATED',
            'BLACK RIVER-MATHESON',
            'MNTK',
            'CARLINGWOOD SHOPPING CENTRE',
            'SIOUX NARROWS-NESTOR FALLS',
            'SHINING TREE',
            'ORILLA',
            'WHITEDOG',
            'KILBRIDE',
            'CAMBRAY',
            'KEEWAYWIN',
            'EAST TORONTO',
            'NORTH PERTH',
            'MADSEN',
            'DON HEAD',
            'PORT STANLEY',
            'CONESTOGO',
            'POPLAR HILL',
            'OBA',
            'JOGUES',
            'COCHR',
            'RAMORE',
            "SQUIRE'S BEACH",
            'LAVIGNE',
            'CAVAN MILLBROOK N',
            'TALBOTVILLE ROYAL',
            'PALGRAVE',
            'BAYHAM',
            'BERKELEY',
            'SAINT-ISIDORE',
            'TYENDINAGA MOHAWK TERRITORY',
            'CONSTITUTION SQUARE',
            'PRINCETON',
            'HORNELL HEIGHTS',
            'WELLINGTON NORTH',
            'ALBERTON',
            'INGELSIDE',
            'NORTH DUNDAS TWP',
            'WARREN',
            'TOWNSHIP OF WILMOT',
            'SNOW ROAD STATION',
            'RAMARA',
            'TORANTO',
            'VAUGHAN MILLS CENTRE',
            'VANDORF',
            'JOHNSTOWN',
            'NICOL ISLAND',
            'DOBIE',
            'JORDAN',
            'SAGAMOK FIRST NATION',
            'BRIGHTS GROVE',
            'STAFFA',
            'EGMONDVILLE',
            'HEARST',
            'DERBY',
            'CHUTE A BLONDEAU',
            'ALLAN PARK',
            'BYNG INLET',
            'ROSSLYN',
            'TAY TOWNSHIP',
            'ZELLERS PLAZA',
            'MOUNT PLEASANT',
            'BATCHAWANA BAY',
            'ROSSPORT',
            'NORFOLK COUNTY',
            'SLEEMAN',
            'COLD SPRINGS',
            'GREENSTONE',
            'POTTAGEVILLE',
            'DUNNS VALLEY',
            'WAWA TOLL FREE',
            'SCHUM',
            'VAL RITA',
            'ORONO',
            'COE HILL',
            'KEARNS',
            'CONSTANCE LAKE',
            'CHUTE-A-BLONDEAU',
            'HOLTYRE',
            'SESEKINIKA',
            'MONTEITH',
            'GOLDEN VALLEY',
            'WHITE LAKE',
            'CRYSTAL FALLS',
            'AHMIC HARBOUR',
            'AWERES TWP',
            'ST. CLAIR BEACH',
            'WORTLEY VILLAGE',
            'WALKERTON',
            'ZORRA',
            'EASTWOOD',
            'CLIFFORD',
            'NO CHARGE DIAL KITCHENER',
            'FAIRVIEW MALL',
            'KITCHENER-WATERLOO',
            'BRUCE MINES',
            'HEAD OFFICE',
            'SAULT STE MARIE',
            'DUART',
            'HEATHCOTE',
            'CALEDONIA',
            'OLIPHNT',
            'MOUNT FOREST ONT',
            'TORY HILL',
            'SIOUX NARROWS',
            'MONTAGUE',
            'FORMOSA',
            'BURRITTS RAPIDS',
            'WINDHAM CENTRE',
            'PROTON STATION',
            'MCKENZIE PORTAGE',
            'DON MILLS & LAWRENCE',
            'WORTHINGTON',
            'REXDALE',
            'GANDER',
            'DRYDEN',
            'WALLENSTEIN',
            'EXHIBITION PLACE',
            'MT. FOREST',
            "HANLAN'S POINT",
            'TORONTO ONT',
            'ST. JACOBS',
            'OHSWEKEN',
            'BLUE MOUNTAIN',
            'ALVANLEY',
            'PICK',
            'AMARANTH',
            "BARRY'S BAY",
            'STNCK',
            'TORONTO (PETERBOROUGH)',
            'EASTGATE SQUARE SHOPPING CENTRE',
            'BRAMPTON (EAST)',
            'MINDEN HILLS',
            'CARDINAL TRAIL',
            'GREATER TORONTO AREA',
            'CLEARWATER BAY',
            'ROSEMONT',
            'EAST GWILLIMBURY',
            'LINCOLN',
            'ST. AGATHA',
            'TOONTO',
            'ST REGIS',
            'BLANDFORD-BLENHEIM',
            'CLEARVIEW',
            'KING CITY',
            'NIAGRA ON THE LAKE',
            'FERGUS',
            'STIRLING-RAWDON',
            'SMVL',
            'CAMPDEN',
            'ST.THOMAS',
            'SALFORD',
            'ORTON',
            'HENSALL',
            'NAVAN',
            'CEDAR POINT',
            'TARA',
            'BROUGHAM',
            'MONKLAND',
            'ALLENFORD',
            'WHITEVALE',
            'KITCHENER WATERLOO',
            'CENTREVILLE',
            'EAST HAWKESBURY',
            'GAUTHIER TWP',
            'MANILLA',
            'ST WILLIAM',
            'BOULTER',
            'DOURO',
            'BLEZARD VALLEY',
            'YOUNGS POINT',
            'HIGHLAND GROVE',
            'MARYSVILLE',
            'NORTHPORT',
            'FERGUSON',
            'ROYAL BANK PLAZA SOUTH TWR',
            'ROUND LAKE CENTRE',
            'DEMORESTVILLE',
            'CITY',
            'ETONIA',
            'HENRY STREET',
            'MINTO',
            'ROBLIN',
            'LYNDHURST',
            'LIMEHOUSE',
            "ST RAPHAEL'S",
            'DUNVEGAN',
            'ST BERNARDIN',
            'MISSISSIPPI MILLS',
            'SAINT-PASCAL-BAYLON',
            'GORHAM',
            'KENDAL',
            'JAFFRAY MELICK',
            'HAWKESTONE',
            'GARDEN RIVER',
            'NESTLETON',
            'LIVE',
            'CHUB LAKE',
            'OTTER LAKE',
            'PORT ROBINSON',
            'BLACKS HARBOUR',
            'SEARCHMONT',
            'ALMA',
            'MARYHILL',
            'CONNAUGHT',
            'WEBEQUIE',
            'WEST HILL',
            'LONGBOW LAKE',
            'BARWICK',
            'SACHIGO LAKE',
            'MUSKRAT DAM',
            'NAIRN CENTRE',
            'FORT SEVERN',
            'SANDY LAKE',
            'BAY',
            'ANGLING LAKE',
            'BROCKTON',
            'NORTH BUXTON',
            'NORTH GLENGARRY',
            'WUNNUMIN LAKE',
            'SELBY',
            'COCHENOUR',
            'CEDAR VALLEY',
            'SAVANT LAKE',
            'CARAMAT',
            'SHEBANDOWAN',
            'EABAMET LAKE',
            'MICHIPICOTEN RIVER',
            'KAMINISTIQUIA',
            'TROY',
            'VINELAND STATION',
            'OPASATIKA',
            'SPRAGGE',
            'BELFOUNTAIN',
            'IRON BRIDGE',
            'SILVER WATER',
            'HILTON BEACH',
            'LAC LACROIX',
            '(OTTAWA)',
            'CRENNA',
            'VERONICA AVE',
            'KAGAWONG',
            'PASS LAKE',
            'PORT EDWARD',
            'GUELPH',
            'SOUTH LANCASTER',
            'BIG TROUT LAKE',
            'GRASSY NARROWS',
            'WATER W',
            'STIRLING',
            'RAINY RIVER',
            'SHEGUIANDAH',
            'AYR',
            'SHALLOW LAKE HEPWORTH',
            'MANITOUWADGE',
            'RUSCOM STATION',
            'CONI',
            'SKEAD',
            'OTTAWA ON',
            'DOWLING',
            'DYMOND',
            'LANSDOWNE',
            'NAUGHTON',
            'HURONTARIO STREET',
            'ONAPING',
            'HILLCREST MALL RICHMOND HILL',
            'WHITEFISH',
            'BRITT',
            'BAILIEBORO',
            'MONETVILLE',
            'CUTLER',
            'CANINGTON',
            'ASTRA',
            'WENTWORTH',
            'PETAWAWA',
            'CALSTOCK',
            'PEAWANUCK',
            'FORT ALBANY',
            'POINT LEAMINGTON',
            'MOOSONEE',
            'VIRGINIATOWN',
            'FRENCH RIVER',
            'KING KIRKLAND',
            'HLYBY',
            'JAMES TWP',
            'ORO STATION',
            'DACK TWP',
            'ABERARDER',
            'MACDIARMID',
            'NORTH COBALT',
            'WOODBINE & LANARK RD',
            'JOCKVALE',
            'RUTHERGLEN',
            'MARTEN RIVER',
            'ASTORVILLE',
            'MONO MILLS',
            'KASHECHEWAN',
            'SEVERN BRIDGE',
            'RESTOULE',
            'ARVA',
            'NORMANDY',
            'CARLING',
            'MINETT',
            'CACHE BAY',
            'WALPOLE ISLAND',
            'HULL',
            'PT EDW',
            'BUSINESS OFFICE',
            'ELMWOOD',
            'NORFOLK',
            'GREEN RIVER',
            'WOOLWICH',
            'WESTBORO AREA',
            'CAESAREA',
            'KIT',
            'HORSECOLLAR JCT',
            'TERRASSE BAY',
            'GALTON',
            'CLARENCE ROCKLAND',
            'OLD CASTLE',
            'HIGHGATE',
            'BOGNOR',
            'NEWINGTON',
            'APPIN',
            'ERIEAU',
            'PORT BURWELL',
            'CEDAR SPRINGS',
            'BLENHEM',
            'TUPPERVILLE',
            'LAKEHURST',
            'SOUTH BRUCE PENNINSULA',
            'PLYMPTON WYOMING',
            'VARNA',
            'LAMBTON SHORES',
            'GULL BAY',
            'SOUFFVILLE',
            'TROUT CREEK',
            'BRUCEFIELD',
            'FONT HILL',
            'BOLSOVER',
            'KINTORE',
            'NEW LISKEARD EARLTON',
            'LIGHTHOUSE COVE',
            'KIPPEN',
            'DENFIELD',
            'LOWBANKS',
            'HARRIETSVILLE',
            'MARLBANK',
            'MUNCEY',
            'ARNSTEIN',
            'HANNON',
            'AKWESASNE',
            'SOUTHWOLD',
            'MOOSE FACTORY',
            'EAR FALLS',
            'FULLARTON',
            'WOODHAM',
            'DUNDALK',
            'GADS HILL STATION',
            'ETHEL',
            'ESSA',
            'BRUNNER',
            'STHPORC',
            'DANFORTH AVE',
            'GILFORD',
            'MOUNT ELGIN',
            'SPRINGFORD',
            'EDEN',
            'WARWICK TOWNSHIP',
            'BELLE RIVER',
            'STOKES BAY',
            'ETOBICOKE',
            'ALLANBURG',
            'LAS',
            'BELGRAVE',
            'CURVE LAKE',
            'TURKEY POINT',
            'BOTHWELL',
            'AUCUNS FRAIS - COMPOSEZ',
            'WALSINGHAM',
            'HARLEY',
            'ST ANDREWS WEST',
            'THAMES CENTRE',
            'VITTORIA',
            'WILMOT',
            'HARRISBURG',
            'HAWKESVILLE',
            'FRINGE RADIO',
            'GUELPH-ERAMOSA',
            'TEVIOTDALE',
            'JURAVINSKI CANCER CTR',
            'ONTARIO',
            'CARNARVON',
            'WELLELSEY',
            'IROQUOIS',
            'HALIFAX',
            'HURON EAST',
            'NORH YORK',
            'BRAMPTON ON',
            'EGLINTON & PHARMACY',
            'WALTERS FALLS',
            'EGBERT',
            'KING',
            'YORKDALE SHOPPING CENTRE',
            'STREETSVILLE',
            'JARVIS',
            'BALLINAFAD',
            'BLYTH',
            'WHITCHURCH-STOUFFVILE',
            'OAK RIDGES',
            'RHL',
            'SUDBURRY',
            'RAMA',
            'CHRISTIAN ISLAND',
            "HUDSON'S BAY CTR",
            'SEVERN',
            'MEAFORD',
            'EAGLE RIVER',
            'GRIM',
            'WALLACE TOWN',
            'SOUTH PICKERING',
            'CLARINGTON',
            'BOLAND AVE',
            'ST EUGENE',
            'HALTON HILLS',
            'COLCHESTER',
            "LION'S HEAD",
            'LAUREL',
            'ALBION',
            'REDDITT',
            'SAULT SAINT MARIE',
            'NEPEAN',
            'CORBEIL',
            'MANSFIELD',
            'ST JOSEPH ISLAND',
            'VIENNA',
            'WILDESPORT',
            'UNION',
            'BELLE EWART',
            'WARMINSTER',
            'SUNNIDALE TOWNSHIP',
            'KETTLEBY',
            'MORPETH',
            'ROLPHTON',
            'BOND HEAD',
            "JACKSON'S POINT",
            'LITTLE CURRENT',
            'SANDFORD',
            'UDORA',
            'ON',
            'GOODWOOD',
            'LIONS HEAD',
            'BETHANY',
            'PICKLE LAKE',
            'MCNAB BRAESIDE',
            'DENBIGH',
            "ST JOSEPH'S HOSPITAL",
            'WEST NIPISSING',
            'ENTERPRISE',
            'CHAPLEAU',
            'JOHN DEUTSCH UNIVERSITY CTR',
            'DOVER CENTRE',
            'NEPAN',
            'ENNISKILLEN',
            'PORT CREDIT',
            'GOODERHAM',
            'TRAIL OF THE WOODS',
            'LASALLE',
            'HOLLAND CENTRE',
            'CENTRAL HURON',
            'WEST GUILFORD',
            'FRASERVILLE',
            'SMITH ENNISMORE LAKEFIELD',
            'NORTHERN BRUCE PENINSULA',
            'LAKE ST PETER',
            'MOUNT ALBERT',
            'NORTH MIDDLESEX',
            'CARDIFF',
            'CARLETON PLACE',
            "L'AMABLE",
            'NEW MARKET',
            'BRODHAGEN',
            'BEWDLEY',
            'SULTAN',
            'CRAIGLEITH',
            'YONGE AND ELGIN MILLS',
            'HARCOURT',
            'WOOLER',
            'IRONDALE',
            'KERWOOD',
            'STONE MILLS',
            'CAMDEN EAST',
            'BURLINGHTON',
            'WILKESPORT',
            'HARTY',
            'CHART AVE',
            'EDEN MILLS',
            'CRYSLER',
            'CODRINGTON',
            'RGWY',
            'THOMASBURG',
            'RIDGETOWN',
            'ORO',
            'WARWORTH',
            'NORTH ALGONA',
            'MALTON',
            'AMELIASBURG',
            'MAPLE',
            'SUDBURY EAST',
            'ARKELL',
            'WALTON',
            'FORESTERS FALLS',
            'CHATHAM-KENT',
            'PATERBOROUGH',
            'MACKEY',
            'HUGEL & OLD PENETANGUISHENE RD',
            'WHITNEY',
            'HALEY STATION',
            'NEWBURY',
            'DUDALK',
            'MARKHAM NORTH',
            'ANDERDON',
            'PEMROKE',
            'CANNINGTON',
            'BURNSTOWN',
            'GRANDE POINTE',
            'WESTMEATH',
            'PLEVNA',
            'PARHAM',
            'SHUNIAH',
            'MOOSE CREEK',
            'INDIAN RIVER',
            'PIAZZA DEL SOL',
            'SUTTON W',
            'LANCASTER',
            'BATTERSEA',
            'PLATTSVILLE',
            'DEUX RIVIERES',
            'STELLA',
            'RIDEAU FERRY',
            'KIMBERLEY',
            'KEMPVILLE',
            'DORECHESTER',
            'NORTH DUNDAS',
            'SAINT ALBERT',
            'BISCOTASING',
            'ROSTOCK',
            'ST CATHERINE',
            'GLENCAIRN',
            'OXFORD MILLS',
            'LUCAN',
            'EDWARDSBURGH/CARDINAL',
            'FRANKVILLE',
            'HAILEYBURY',
            'RCHMND',
            'BERWICK',
            'HAGGERSVILLE',
            'CARLT PL',
            'BAYSHORE SHOPPING CENTRE',
            'AVONMORE',
            'FLINTON',
            'SIOUX LOOKOUT',
            'APPLE HILL',
            'CHERRY VALLEY',
            'WILLOW BEACH',
            'NIAGRA',
            'GLEN ROBERTSON',
            'CURRAN',
            'ORILLIA & SURROUNDING AREA',
            'MASSEY',
            'PORT LORING',
            'ST-EUGENE',
            'BALMERTOWN',
            'BROCK',
            'VERNON',
            'CALL US TOLL FREE',
            'LANDCASTER',
            'GAIL PARKS CRES',
            'WATERFORD',
            'RAMSAYVILLE',
            'GEORGINA',
            'UNIVERSITY OF LONDON FACULTY OF EDUCATIO',
            'SAULT STE. MARIE',
            'MUNSTER',
            'SALES CALL TOLL FREE',
            'MAD',
            'KARS',
            'WHITCHURCH-STOUFFVILLE',
            'MOREWOOD',
            'FORT FRANCES',
            'ST ALBERT',
            'WAUBAUSHENE',
            'WILBERFORCE',
            'HAMILTON ON',
            'BOURGET',
            'LEFAIVRE',
            'BETHESDA',
            'DALKEITH',
            'FOURNIER',
            'GARDEN VILLAGE',
            "SEELEY'S BAY",
            'SOUTHWEST MIDDLESEX',
            'DUNROBIN',
            'ARKONA',
            'ORLEAN',
            'STE ANNE DE PRESCOTT',
            'ONTARIO (ON)',
            'SUMMER HILL',
            'NAPEAN',
            'OSGOODE',
            'SOUTH GILLIES',
            'DOUGLAS',
            'STEWARTVILLE RD',
            'WILLIAMSTOWN',
            'BRINSTON',
            'ROSSEAU',
            'CHARING CROSS',
            'MAXVILLE',
            'HICKSON',
            'PAKENHAM',
            'NORTHYORK',
            'WHITEFISH FALLS',
            'TOLEDO',
            'HAMMOND',
            'CLOYNE',
            'SHESHEGWANING',
            'OAKVILLE ON',
            'DELTA',
            '(DOWNTOWN)',
            'MOFFAT',
            'NEWBURGH',
            'JOACHIM',
            'MALLORYTOWN',
            'PERTH ROAD',
            'SAINT JACOBS',
            'SOUTH MOUNTAIN',
            'DELAWARE',
            'LOMBARDY',
            'JONES RD',
            'ARDOCH',
            'DONWAY W & LAWRENCE',
            'AJAX',
            'SIMCOE',
            'CARRYING PLACE',
            'PROVIDENCE BAY',
            'SHARBOT LAKE',
            'STROUD',
            'WOLFE ISLAND',
            'CONSECON',
            'HILLIER',
            'JANETVILLE',
            'OLIPHANT',
            'TAMWORTH',
            'NORTH AUGUSTA',
            'CAMPBELLCROFT',
            'MARMORA & LAKE',
            'APSLEY',
            'WARSAW',
            'KASABONIKA',
            'MAXWELL',
            'DUNSFORD',
            "ST. DAVID'S",
            'GODERICH',
            'ALGONQUIN HIGHLANDS',
            'TRENT LAKES',
            'ELIZABETHTOWN',
            'HASTING',
            'AUGUSTA',
            'VIRGIL',
            'KANATA STITTSVILLE',
            'WESTBROOK',
            'SHEFFIELD',
            'WATERLOO TOWN CENTRE',
            'NASSAGAWEYA',
            'OTONABEE-SOUTH MONAGHAN',
            'GREENWOOD',
            'WHITCHURCH STOUFFVILLE',
            'NAKINA',
            'SEAGRAVE',
            'COBOCONK',
            'KEJICK',
            'ATIKOKAN',
            'BLACKSTOCK',
            'MILLGROVE',
            'DUNTROON',
            'SOUTH STORMONT',
            "ROCHE'S POINT",
            'EVERETT',
            'WHITBY',
            'LEFROY',
            'CALL TOLL FREE',
            'CHURCHILL',
            'STONEY POINT',
            'DUBLIN',
            'MUSKOKA LAKES',
            'PEFFERLAW',
            'PALMER RAPIDS',
            'NIALK',
            'DORSET',
            'LONG BEACH',
            'OH',
            'GRAND VALLEY',
            'THORNLOE',
            'TAVISTOCK',
            'CLAREMONT',
            'ST. CATHARINES',
            'WROXETER',
            'DOBBINTON',
            'COTTAM',
            'MIDDLETON',
            'SAINT GEORGE',
            'SELWYN',
            'ST GEORGE',
            'HONEYWOOD',
            'ATOMIC DR',
            'BRIGHT',
            'INNERKIP',
            'WETSPORT',
            'EVANTUREL',
            'LINDSAY',
            'BINBROOK',
            'GOULAIS RIVER',
            'THORNE',
            'WELLINGTON',
            "BRIGHT'S GROVE",
            'HASTINGS',
            'NEWTON',
            'PORT SEVERN',
            'NOVAR',
            'BEACHVILLE',
            'MAGNETAWAN',
            'KPSKS',
            'RIVER VALLEY',
            'ELMVALE',
            'KRKLK',
            'MUIRKIRK',
            'GLEN MORRIS',
            'BRUSSELS',
            'LUCKNOW',
            'ST ISIDORE',
            'TORRANCE',
            'NEW HAMBURG',
            'LAFONTAINE',
            'GUILDORD',
            'SMITHS FALLS',
            'SUTTON',
            'MILVERTON',
            'COURTRIGHT',
            'GREATER SUDBURY',
            'GOWANSTOWN',
            'LASALLE WINDSOR',
            'WILLIAMSFORD',
            'ALVINSTON',
            'SHALLOW LAKE',
            'SHANTY BAY',
            'RUTHVEN',
            'MELBOURNE',
            'BADJEROS',
            'NLSKD',
            'HURON PARK',
            'CREDITON',
            'LITTLE BRITAIN',
            'EGANVILLE',
            'DUNGANNON',
            'HEYDEN',
            'PERRAULT FALLS',
            'MARKDALE',
            'AUBURN',
            'AYLMER WEST',
            'POINTE AUX ROCHES',
            'CAMBRIDGE',
            'DRESDEN',
            'CAMLACHIE',
            'ST CATH',
            'MOBILE',
            "ST MARY'S",
            'CLARKSON',
            'ERLTN',
            'BRIGDEN',
            'BRAESIDE',
            "SAINT MARY'S",
            'HORNINGS MILLS',
            'PORT ROWAN',
            'ST. THOMAS',
            'SPRUCEDALE',
            'COLONEL BY DR',
            'ADJALA',
            'MCKELLAR',
            'UNIONVILLE',
            'BAYSVILLE',
            'LA SALLE',
            'KESWICK ST.',
            'BOLTON',
            'POINTE-AU-BARIL-STATION',
            'PRINCE EDWARD COUNTY',
            'HUDSON',
            'SCHREIBER',
            'WAVERLEY',
            'BAMPTON',
            'OAKWOOD',
            'VICKERS HEIGHTS',
            'MINE CENTRE',
            'ARMSTRONG',
            'CONN',
            'YARKER',
            'WABIGOON',
            'LANDSDOWNE HOUSE',
            'BALDWIN',
            'DEVLIN',
            'EDWARDS',
            'NEWBORO',
            'MINAKI',
            'KENABEEK',
            "M'CHIGEENG",
            'FALCONBRIDGE',
            'PAIN COURT',
            'MATTICE',
            'WEST TORONTO',
            'VERNER',
            'SPANISH',
            'QUEENSVILLE',
            'RAMSEY',
            'BAFIELD',
            'VAL THERESE',
            'MOONBEAM',
            'BOWMANVILLE',
            'CUMBERLAND',
            'FORT WILLIAM FIRST NATION',
            'ASHTON',
            'CASSELMAN',
            'TEETERVILLE',
            'CARTIER',
            'RICHMOND',
            'VANKLEEK HILL',
            'INDUSTRIAL',
            "B D'URFE",
            'BROOKLIN',
            'PLHM',
            'MARKHAM',
            'MISSANABIE',
            'GRAND BEND',
            'NOLALU',
            'CONCORD ON',
            'ALFRED',
            'KENDAL TOWNSHIP',
            'SAINT EUGENE',
            'ROSLIN',
            'EMBRUN',
            'SCHOMBERG',
            'SWASTIKA',
            'LONDESBOROUGH',
            "L'ORIGNAL",
            'ROCKCLIFFE',
            'ELGIN',
            'NEMI',
            'BELWOOD',
            'NORTH GOWER',
            'EAGANVILLE',
            'GRAFTON',
            'RIDEAU CENTRE',
            'KLEINBURG',
            'TOLL FREE',
            'CARLINGWOOD MALL',
            'BARRHAVEN NEPEAN KANATA',
            'VERONA',
            'VAL CARON',
            'KIRKLAND LANE',
            'PUTNAM',
            'WENDOVER',
            'DRESSEN',
            'COLBORNE',
            'WOODBRIDGE',
            'MAYFIELD',
            'BERKLEY',
            'LHSC-VICTORIA HOSPITAL',
            'PRESCOTT',
            'BLOOMINGDALE',
            'HAMILTON',
            'BALTIMORE',
            'EAST YORK',
            'BLOOMFIELD',
            'PLAINFIELD',
            'WALFORD STATION',
            'CORNWALL',
            'FOXBORO',
            'SARSFIELD',
            'DUBREUILVILLE',
            'PORT LAMBTON',
            'TEMISKAMING',
            'STONECLIFFE',
            'ROSENEATH',
            'NAPANEE',
            'NFLS',
            'SEBRIGHT',
            'CASTLETON',
            'SAULT STE-MARIE',
            'SEBRINGVILLE',
            'NORWOOD',
            'ELIZABETHTOWN-KITLEY',
            'MCDOUGALL',
            'AROLAND',
            'COBALT',
            'ST-BERNARDIN',
            'BARRIE',
            'MAYNOOTH',
            'FITZROY HARBOUR',
            'METCALFE',
            'GORMLEY',
            'WILLIAMSBURG',
            'PORTLAND',
            'GREENBANK',
            'ALEXANDRIA',
            'FOREST',
            'ROCKPORT',
            'CANANOQUE',
            'TRENTON',
            'EMSDALEON',
            'HMV HEAD OFFICE',
            'GREATER NAPANEE',
            'MORRISTON',
            'MCHIGEENG',
            'RICHMOND HILL',
            'ANCASTER',
            'ATHENS',
            'PICKERING',
            'ZEPHYR',
            'ODESSA',
            'ST ANDREWS',
            'ARTHUR',
            'NIPISSING',
            'MCDONALDS CORNERS',
            'WESTPORT',
            'ORO MEDONTE',
            'THUNDER BAY BEACH',
            'STITTSVILLE',
            'HARTINGTON',
            'NEW LISKEARD',
            'WOODSIDE SQ MALL',
            'THE ARCHIPELAGO',
            'FTERI',
            'FLETCHER LAKE',
            'CARDINAL',
            'INVERARY',
            'MILTON',
            'NEWCASTLE',
            'TICHBORNE',
            'LORING',
            'BALDERSON',
            'SITTSVILLE',
            'GLENBURNIE',
            'WYCHWOOD AV',
            'MADILL CHURCH RD',
            'OIL CITY',
            'UPSALA',
            'CENTENNIAL ST',
            'CALEDON',
            'WYOMING',
            'UNIVERSITY OF GUELPH',
            'VINELAND',
            'MADAWASKA',
            'CALABOGIE',
            'HARROWSMITH',
            'GODFREY',
            'BRANCHTON',
            'BATH',
            'CENTRALIA',
            'DEEP RIVER',
            'KILLALOE',
            'CHERRYWOOD',
            'SPARTA',
            'HARROW',
            'CLARENCE CREEK',
            'SAINT ISIDORE',
            'PICTON',
            'MINESING',
            'GEORGETOWN',
            'MARMORA',
            'MISSISAUGA',
            'SMOOTH ROCK FALLS',
            'DOBBINGTON',
            'FENWICK',
            'COMMERCIAL DEPT FAX',
            'VAUGHN',
            'ELK LAKE',
            'DESERONTO',
            'KIRKFIELD',
            'FORDWICH',
            'PLANTAGENET',
            'MIDTOWN',
            'WHITELAKE',
            'SUDBURY TOLL FREE',
            'DEER LAKE',
            'TOWNSEND',
            'MOUNTAIN GROVE',
            'BANCROFT',
            'CAP',
            'FIELD',
            'BRIDGENORTH',
            'BLUE MOUNTAINS',
            'ELMIRA',
            'ALBAN',
            'HAVELOCK',
            'HALDIMAND',
            'CHIBLOW LAKE RD',
            'DUNCHURCH',
            'PALMERSTON',
            'CARLISLE',
            'CAMPBELLFORD',
            'LEBEL TWP',
            'DUNNVILLE',
            'OMEMEE',
            'CHELM',
            'BLUEVALE',
            'PRINCE EDWARD',
            'NORTH YORK',
            'ALMONTE',
            'GRAHAM',
            'BRGHT',
            'MAR',
            'KANATA',
            'BOBCAYGEON',
            'WEBBWOOD',
            'NORLAND',
            'AURORA',
            'BRIGHTON',
            'ZURICH',
            'VARS',
            'BLIND RIVER',
            'GLOUCESTER',
            'CAMPBILLVILLE',
            'COURTICE',
            'GREELY',
            'SUNRIDGE',
            'WHIT',
            'ASHFIELD WAWANOSH COLBORNE TOWNSHIP',
            'HAWKESBURY',
            'NEW DUNDEE',
            'VAL GAGNE',
            'TERRACE BAY',
            'RUSSELL',
            'TRENT HILLS',
            'SOUTH BRUCE PENINSULA',
            'MANOTICK',
            'ORLEANS',
            'CHELTENHAM',
            'WEST MONTROSE',
            'BELL EWART',
            'RIDGEWAY',
            'ROCKLAND',
            'WEST LINCOLN',
            'EMO',
            'CHATHAM KENT',
            'SOUTH PORCUPINE',
            'BURLEIGH FALLS',
            'FRANKFORD',
            'LEAMINGTON',
            'ROSSMORE',
            'WARKWORTH',
            'COBEN',
            "RICHARD'S LANDING",
            'ARNPRIOR',
            'LA SALETTE',
            'MARHAM',
            'THORNTON',
            'QUINTE WEST',
            'SWSTK',
            'LAMPTON SHORES',
            'PONTYPOOL',
            'FONTHILL',
            'SOUTH GLENGARY',
            'HEIDELBERG',
            'MILLBROOK',
            'PORQUIS JUNCTION',
            'NANTICOKE',
            'CANADA/',
            'GANANOQUE',
            'NESTLETON STATION',
            'ROCKTON',
            'CAVAN',
            'BEETON',
            'BURNT RIVER',
            'COOKSVILLE',
            'PENETANGUISHENE',
            'NOBEL',
            'WESTMOUNT MALL',
            'CANNIFTON',
            'SOUTH BRUCE',
            'WAWA',
            'FINCH',
            'BROADWAY',
            'SUTTON WEST',
            'SANDWICH SOUTH',
            'JACKSONS POINT',
            'PRINCE ALBERT',
            'WINDSOR',
            'HAMPTON',
            'AMHERST ISLAND',
            'MOSSLEY',
            'ST DAVIDS',
            'WINONA',
            'OGOKI',
            'BROOKE-ALVINSTON',
            'NIAGARA-ON-THE-LAKE',
            'TILDEN LAKE',
            'SHERKSTON',
            'OTTAWA',
            'GRASSIE',
            'ALTON',
            'HOWE ISLAND',
            'JERSEYVILLE',
            'KINGSTON',
            'GRANTON',
            'HISLOP TWP',
            'AILSA CRAIG',
            'PETERBOROUGH',
            'CHELSEY',
            'MIDHURST',
            'KILWORTHY',
            'SHARON',
            'BRECHIN',
            'PRESTON',
            'BEARDMORE',
            'MAPLE LEAF',
            'PHELPSTON',
            'CASTLEMORE',
            'CLAYTON',
            'MARKSTAY',
            'BAYFIELD',
            'ARISS',
            'ENNISMORE',
            'BROMONT',
            'GATINEAU',
            'LIMOGES',
            'COURTLAND',
            'UXBRIDGE',
            'MULMUR',
            'THEDFORD',
            'BEAVERTON',
            'HOLSTEIN',
            'GRIMSBY',
            'MOORETOWN',
            'STEVENSVILLE',
            'KINGFISHER LAKE',
            'WYEBRIDGE',
            'OXDRIFT',
            'MCGREGOR',
            'TINY',
            'BORNHOLM',
            'PARIS',
            'LIVELY',
            'UTOPIA',
            '-- NON U.S. --',
            'OKAVILLE',
            'ERIN',
            'NESTOR FALLS',
            'BORDEN',
            'HUNTA',
            'WILNO',
            'LUNENBURG',
            'ANGUS',
            'GLEN HURON',
            'COOKSTOWN',
            'LYN',
            'UXBRIDGE RURAL',
            'CALDON',
            'DACRE',
            'NOTTAWA',
            'SCUGOG',
            'ST-ALBERT',
            'LEISMER',
            'DOWS LAKE',
            'NEW TECUMSETH',
            'CAMPBELLVILLE',
            'NORTH HURON',
            'LEASIDE',
            'THESSAL',
            'CALEDON VILLAGE',
            'BRADFORD WEST GWILLIMBURY',
            'STAYNER',
            'MOUNT HOPE',
            'ST PAULS',
            'BADEN TWP',
            'CRYSTAL BEACH',
            'CARLSBAD SPRINGS',
            'SUNDERLAND',
            'ADDISON',
            'ST CLEMENTS',
            'CAISTOR CENTRE',
            'FENELON FALLS',
            'PRICEVILLE',
            'PERTH',
            'BONFIELD',
            'MILFORD',
            'N WATERLOO',
            'WATERDOWN',
            'HALIBURTON',
            'KINMOUNT',
            'BRAMPTON ONTARIO / TORONTO ONTARIO',
            'COMBERMERE',
            'LONDON / STRATHROY',
            'WAINFLEET',
            'ACTON',
            'NIAGARA ON THE LAKE',
            'BARRIE ON',
            'SMITHVILLE',
            'JANE FINCH MALL',
            'HAMILTON AREA',
            'MATACHEWAN',
            'ATTAWAPISKAT',
            'PORT HOPE',
            'EAST ZORRA-TAVISTOCK',
            'KEENE',
            'MANITOWANING',
            'PORT DOVER',
            'OSHAWA',
            'BLACK RIVER',
            'HAGAR',
            'NORTH KAWARTHA',
            'MERRICKVILLE-WOLFORD',
            'THRLD',
            'OROMEDONTE',
            'PORT PERRY',
            'LANARK',
            'CARP',
            'SHEDDEN',
            'HAMILTON MOUNTAIN',
            'TILBURY',
            'ORILLIA',
            'SARNIA',
            'DUNCAN',
            'JOYCEVILLE',
            'BURGESSVILLE',
            'CARGILL',
            'MOONSTONE',
            'BRADFORD',
            'ONTRIO',
            'WOODLAWN',
            'DRAYTON',
            'BAINSVILLE',
            "ST JOSEPH'S HEALTH CARE",
            'CONCORD',
            'EAST OTTAWA',
            'SHELBOURNE',
            'OTONABEE',
            'AGINCOURT',
            'KAPUSKASING',
            'GREATER INNISFIL & BARRIE',
            'NEWMARKET',
            'RED ROCK',
            'KIRKLAND LAKE',
            'HEPWORTH',
            'GOLDEN LAKE',
            'YORK.',
            'EAGLE LAKE',
            'GRIMSBY TOLL FREE',
            'FITZROY HARBOR',
            'OSNABURGH HOUSE',
            'MONTREAL',
            'CHEPSTOW',
            'LAKESIDE',
            'ALGOMA MILLS',
            'MONKTON',
            'HILLSDALE',
            'KEMPTVILLE',
            'MT.VERNON',
            'MISSISSAUGA',
            'CLARENDON STATION',
            'AYTON',
            'POOLE',
            "ST.ANN'S",
            'ST GEORGE BRANT',
            'UTICA',
            'EATON CENTRE',
            'GTA',
            'LONGLAC',
            'GLEN WILLIAMS',
            'HOLLAND LANDING',
            'LOCUST HILL',
            'SCHUMACHER',
            'INNISFIL',
            'MADOC DR',
            'SUMMERSTOWN',
            'VENNACHAR',
            'CHAPPLE',
            'JORDAN STATION',
            'PETERSBURG',
            'ORANGEVILLE',
            'AMHERSTVIEW',
            'ST. WILLIAMS',
            'OAKVILLE',
            'KINGSVILLE',
            'LISLE',
            'BRAMPTON',
            'ERAMOSA',
            'SALTFORD',
            'HARWOOD',
            'PAISLEY',
            'BATCHEWANA FIRST NATION',
            'OLDCASTLE',
            'BURLINGTON',
            'KENILWORTH',
            'BRANT',
            'WOODVILLE',
            'GARSON',
            'ESSEX',
            'WEST GREY',
            'LARDER LAKE',
            'GORRIE',
            'BROWNSVILLE',
            'HARRISTON',
            'LORETTO',
            'PEMBROKE',
            'GEORGIAN BLUFFS',
            'TIVERTON',
            'SMITH FALLS',
            'DWIGHT',
            'SOUTHAMPTON',
            'BLENHEIM',
            'MOOREFIELD',
            'SUNDRIDGE',
            "BURK'S FALLS",
            'BEARSKIN LAKE',
            'PINEWOOD',
            'BEDFORD',
            'VANIER',
            'CALLANDER',
            'MINDEN',
            'VANESSA',
            'PORT ELGIN',
            'SELKIRK',
            'SAINT CATHARINES',
            'RIHCMOND HILL',
            'SOMBRA',
            'EXETER',
            'BOBCAYGEON KAWARTHA LAKES',
            'LANSDOWNE HOUSE',
            'CHALK RIVER',
            'POINTE-AUX-ROCHES',
            'LHSC VICTORIA HOSPITAL COMMISSIONERS RD',
            'BLEMHEIM',
            'COPPER CLIFF',
            'RESERVATIONS',
            'DESBORO',
            'BELMONT',
            'SAUBLE BEACH',
            'FORT ERIE',
            'KEMBLE',
            'WESPORT',
            'CLARKSBURG',
            'MARATHON',
            'MONO-AMARANTH TOWN LINE RD',
            'CANFIELD',
            'RED LAKE',
            'TRENT RIVER',
            'ROCKWOOD',
            'CLINTON',
            'MCKERROW',
            'PELEE ISLAND',
            'OMPAH',
            'SARNIA ON CANADA',
            'PORT COLBORNE',
            'MONAGHAN',
            'CORUNNA',
            'HAGERSVILLE',
            'ALLISTON',
            'LAC SEUL',
            'HUNSTVILLE',
            'BROCKVILLE',
            'DOWNSVIEW',
            'ASHFIELD-COLBORNE-WAWANOSH',
            'FLORENCE',
            'UPPSALA',
            'ST AGATHA',
            'SEELEYS BAY',
            'ANNAN',
            'ILDERTON',
            'EMERGENCY AFTER HOURS',
            'LEASKDALE',
            'CAYUGA',
            'NORTH LANCASTER',
            'VERMILION BAY',
            'KIRKTON',
            'SOUTH RIVER',
            'SOUTH WOODSLEE',
            'MATHESON',
            'EMBRO',
            'TECUMSEH',
            'SANS FRAIS',
            'MAC TIER',
            'WATERLOO',
            'FOLEYET',
            'STONEY CREEK',
            'ON -',
            'DESBARATS',
            'SCARBOROUGH',
            'KALADAR',
            'LILY OAK',
            'DURHAM',
            'ST WILLIAMS',
            'FEVERSHAM',
            'RIVER DRIVE PARK',
            'BECKWITH',
            'WILSONVILLE',
            'GADS HILL',
            'TEESWATER',
            'CALEDON EAST',
            'KAKABEKA FALLS',
            'CLEAR CREEK',
            'NO CHARGE DIAL',
            'HANMER',
            'ORO-MEDONTE',
            'SUDBURY',
            'BUCKHORN',
            'KILLARNEY',
            'ON //',
            'LONGFORD MILLS',
            'TEMISKAMING SHORES',
            'NEYAASHIINIGMIING',
            'ELDORADO',
            'DORCHESTER',
            'NORVAL',
            'PETROLIA',
            'WARDSVILLE',
            'ST. MARYS',
            'IROQUOIS FALLS NT',
            'TIMMINS',
            'COBDEN',
            'DASHWOOD',
            'TAY',
            'HORNEPAYNE',
            'WALDHOF',
            'NIAGARA AREA',
            'MILLER LAKE',
            'SERPENT RIVER',
            'KEARNEY',
            'SELWIN',
            'SNELGROVE',
            'OIL SPRINGS',
            'PAWITIK',
            'FLAMBOROUGH',
            'COLDWATER',
            'VANKLEY HILL',
            'GREY HIGHLANDS',
            'PORT FRANKS',
            'MSTN',
            'GORES LANDING',
            'TILLSONBURG',
            'KEEWATIN',
            'THOROLD',
            'TOBERMORY',
            'KITCHENER NO CHARGE DIAL',
            'ST JACOBS',
            'UXBRIDGE/PORT PERRY',
            'WIARTON',
            'LEVACK',
            'LANGTON',
            'CHATSWORTH',
            'WALLACEBURG',
            'CATHCART',
            'SCOTLAND',
            "ST. MARY'S",
            'EGLINTON W',
            'INGLEWOOD',
            'BEAUMARIS',
            'MITCHELL',
            'SAINT-ISIDORE-DE-PRESCOTT',
            'MARTINTOWN',
            'RODNEY',
            'MOUNT BRYDGES',
            'WALLACETOWN',
            'RIDGEVILLE',
            'CUMBERLAND BEACH',
            'HAWK JUNCTION',
            'TWEED',
            'SHELBURNE',
            'BURFORD',
            'UTTERSON',
            'HANOVER',
            'EMERYVILLE',
            'LATCHFORD',
            'PORT CARLING',
            'DOWNTOWN LONDON',
            'ST CLAIR BEACH',
            'ST ANNS',
            'LAMBETH',
            'GORE BAY',
            'WINDSOR HILL BLVD',
            'EMSDALE',
            'PERKINSFIELD',
            'CAVAN MONAGHAN',
            'BURKS FALLS',
            'THORNBURY',
            'ST CATHARINES',
            'CHAPUT HUGHES',
            'YOUNGS POINTS',
            'MARKAM',
            'CHESTERVILLE',
            'KINBURN',
            'GLENCOE',
            'HALDIMAND COUNTY',
            'RICHMOND H',
            'THESSALON',
            'MT FOREST',
            'TEC',
            'TERRA COTTA',
            'SPENCERVILLE',
            'PRIMELINE',
            'DORION',
            'MILDMAY',
            'FREELTON',
            'EARLTON',
            'WEAGAMOW LAKE',
            'WATFORD',
            'MCNAB/BRAESIDE',
            'UPPER CANADA VILLAGE',
            'KAWARTHA LAKES',
            'LINWOOD',
            'KINCARDINE',
            'PARKHILL',
            'ST THOMAS',
            'THORNDALE',
            'BEAMSVILLE',
            'QUEENSTON',
            'MIDLAND',
            'PORQUIS',
            'DUTTON',
            'HORNBY',
            'PREVOST S',
            'KOMOKA',
            'MELANCTHON',
            'WOODVIEW',
            'FINGAL',
            'HERON BAY',
            'WEST LORNE',
            'LONG SAULT',
            'HESPELER',
            'BARRYS BAY',
            'NOELVILLE',
            'KENT BRIDGE',
            'ATWOOD',
            'LYNDEN',
            'THAMESFORD',
            'WINDERMERE',
            'MABERLY',
            'PRINCE TOWNSHIP',
            'NEEBING',
            'WASHAGO',
            'HOLYROOD',
            '()',
            'STRATFORD',
            'LAKEFIELD',
            'MARKAHM',
            'KETTLE & STONY POINT FN',
            "GORE'S LANDING",
            'SHANNONVILLE',
            'SUMMER BEAVER',
            'BADEN',
            'EVANSVILLE',
            'ROCHES POINT',
            'ST. CHARLES',
            'WITHNEY',
            'MAIDSTONE',
            'ERINSVILLE',
            'SHAKESPEARE',
            'THORNHILL',
            'GREEN VALLEY',
            'NIPIGON',
            'HALIMAND COUNTY',
            'WELLANDPORT',
            'CONISTON',
            'DUNDAS',
            'DELHI',
            'MONO',
            'HACK CITY',
            'DRUMBO',
            'ST.CATHARINES',
            'SAULT SAINTE MARIE',
            'COPETOWN',
            'SYDENHAM',
            'ARMSTRONG STATION',
            'OWEN SOUND',
            'MINDEMOYA',
            'VAUGHAN',
            'WHEATLEY',
            'ST MARYS',
            'INWOOD',
            'ST. CAHARINES',
            'CHATHAM',
            'DOURO-DUMMER',
            'NEUSTADT',
            'ONTARTIO',
            'INGERSOLL',
            'HONEY HARBOUR',
            'CREEMORE',
            'TINY TOWNSHIP',
            'STRATHROY',
            'NORTH BAY',
            'SLATE RIVER',
            'SEAFORTH',
            'FOYMOUNT',
            'HUNTSVIILLE',
            'ALGONQUIN PARK',
            'UNIVERSITY OF TORONTO',
            'CENTRAL ELGIN',
            'LOUTH',
            'MAITLAND',
            'WASAGA BEACH',
            'POINT EDWARD',
            'LISTOWEL',
            'GLENRIDGE/GLENDALE AREA',
            'IROQUOIS FALLS',
            'WHITE RIVER',
            'ST. DAVIDS',
            'MURILLO',
            'COCHRANE',
            'ISABELLEREZAZADEH',
            'NESTLET',
            'BRESLAU',
            'NEW OSNABURGH',
            'COLDSTREAM',
            'MILFORD BAY',
            'GOWGANDA',
            'CULTUS',
            'WIKWEMIKONG',
            'ESPANOLA',
            'SPRINGFIELD',
            'AMHERSTBURG',
            'OTAONABEE',
            'DANFORTH & BROADVIEW',
            'MORSON',
            'JELLICOE',
            'PORT MCNICOLL',
            'BALA',
            'LAKESHORE',
            'TEMAGAMI',
            'ARNOLDS COVE',
            'BELLEVILLE',
            'IONA STATION',
            'HUNTSVILLE',
            'THAMESVILLE',
            'MACTIER',
            'NIAGARA REGION',
            'HORTON',
            'CENTRE WELLINGTON',
            'AYLMER',
            'GOGAMA',
            'SMITH-ENNISMORE-LAKEFIELD',
            'STRATTON',
            'ARRAN-ELDERSLIE',
            'ESSEXW',
            'PIKWAKANAGAN',
            'BRACEBRIDGE',
            'TORONO',
            'REDBRIDGE',
            'PORT SYDNEY',
            'MATTAWA',
            'STBN',
            'MCGARRY',
            'PRINCE TWP',
            'WELLESLEY',
            'CHESLEY',
            'STONEY CREEK',
            'OXFORD STATION',
            'INGLESIDE',
            'ECHO BAY',
            'SPRINGBROOK',
            'HAYDON',
            'PUSLINCH',
            'ST JOACHIM',
            'CONSTANCE BAY',
            'MOUNT FOREST',
            'SPRINGWATER',
            'EAST GARAFRAXA',
            'LANARK HIGHLANDS',
            'COMBER',
            'PORCUPINE',
            'NORWICH',
            'JASPER',
            'ST-CATHARINES',
            'TORONTO',
            'ENGLEHART',
            'STITTSVILLE MAIN ST',
            'MORRISBURG',
            'AZILDA',
            'NEWTONVILLE',
            'RIPLEY',
            'BEACHBURG',
            'BRANTFORD',
            'GALT',
            'NORTHBROOK',
            'WINDSOR O',
            'RICHARDS LANDING',
            'NOBLETON',
            'COBOURG',
            'WDBG',
            'LOYALIST',
            '',
            'CAT LAKE',
            'GILMOUR',
            'IGNACE',
            'MILLBANK',
            'PLAYFAIR TWP',
            'FAUQUIER',
            'MERRICKVILLE',
            'KENMORE',
            'ESSEXON',
            'WALLACE TWP',
            'PLACE',
            'GERALDTON',
            'MOUNTAIN',
            'TARZWELL',
            'MYRTLE STATION',
            'MERLIN',
            'NIAGARA FALLS',
            'WELLAND',
            'MADOC',
            'NEW LISKEAR',
            'FLORADALE',
            "L'ANNONCIATION",
            'PIKANGIKUM',
            'CAMERON',
            'ROCHWOOD',
            'MOHAWK TERRITORY',
            'GRAVENHURST',
            'VICTORIA HARBOUR',
            'SEGUIN',
            'POWASSAN',
            'WINGHAM',
            'THUNDER BAY',
            'ELGINBURG',
            'NEW LOWELL',
            'SUDBURY ONTARIO',
            'PELHAM',
            'MELDRUM BAY',
            'WAHNAPITAE',
            'HURON',
            'KITCHENER',
            'HAY',
            'CHELMSFORD',
            'CHARLTON',
            'ST CHARLES',
            'SINGHAMPTON',
            'STRAFFORDVILLE',
            'ASHBURN',
            'KELOWNA',
            'STURGEON FALLS',
            'STOUFFVILLE',
            'WINCHESTER',
            'WOODSTOCK',
            'CORBYVILLE',
            'FISHERVILLE',
            'KENORA',
            'FLESHERTON',
            'SAINT THOMAS',
            'LIMERIDGE RD E',
            'ELLIOT LAKE',
            'RAWDON',
            "FOOT'S BAY",
            'MACDONALDS CORNERS',
            'BEARS PASSAGE',
            'AMELIASBURGH',
            "ST JOSEPH'S HEALTHCARE",
            'PARRY SOUND',
            'OAKLAND',
            'COLLINGWOOD',
            'OTTERVILLE',
            'LONDON',
            'NORTH-YORK',
            'CAPREOL',
        ],
    },

    /* 7 */
    {
        _id: 'MB',
        cities: [
            'PLEASANT VALLEY',
            'GRAND VIEW',
            'EASTERN MANITOBA',
            'STE. RITA',
            'SAINT AMBROISE',
            'WEST ST. PAUL',
            'LITTLE BULLHEAD',
            'REYNOLDS',
            'GRAYSVILLE',
            'PORTAGE LA PRARIE',
            'HARDING',
            'DE SALABERRY',
            'STRATHCONA',
            'ST-GEORGES',
            'ROCKWOOD',
            'WHITEWATER',
            'DELROAINE',
            'BRADWARDINE',
            'UNORGANIZED',
            'WALLACE',
            'SAINT-PIERRE-JOLYS',
            'BLUFF',
            'MANITOBA',
            'ILES DES CHENES',
            'CAMP MORTON (GIMLI)',
            'WINNIEPEG',
            'WINNIPEGSQUARE',
            'LABROQUERIE',
            'GIMLI GLEN BAY',
            'SHELLMOUTH',
            'POPLAR BAY',
            'CLEARWATER LAKE',
            'LADYWOOD',
            'STE. AGATHE',
            'LA BROQUIRE',
            'SAINTE-AGATHE',
            'SILVERTON',
            'ST. MALO',
            'POLOPARKSHOPPINGCENTRE',
            'FISHER RIVER',
            'LAKE METIGOSHE',
            'THOMSPON',
            'OAKPOINT',
            'ST. ANNE',
            'ASHVILLE',
            'SIGLUNES',
            'SPRUCE SANDS',
            'HOCHFELD',
            'GRAND BEACH',
            'WEST PINE RIDGE',
            'ST. JEAN BAPTISTE',
            "O'HANLEY",
            'SOMEREST',
            'ELTON',
            'EAST SAINT PAUL',
            'OAKLAND',
            'GINEW',
            'NEWTON',
            'MERRITT',
            'KENVILLE',
            'ISABELLA',
            'MARQUETTE',
            'MAKINAK',
            'SHORTDALE',
            'CHANNING',
            'PANSY',
            'ST GEORGE',
            'SAINT LAURENT',
            'OAKBLUFF',
            'WAYWAYSEECAPPO',
            'SEDDONS CORNER',
            'HASKETT',
            'BIRDTAIL SIOUX RSVE',
            'APPLE LANE',
            'WHYTEWOLD',
            'GOODLANDS',
            'BLUMENFELD',
            'LAUDER',
            'VALLEY RIVER',
            'GLENORA',
            'WELLMAN LAKE',
            'HARGRAVE',
            'FISHER',
            'BRERETON LAKE',
            'SCHANZENFELD',
            'ST SIM BAGOT',
            'WALDERSEE',
            'THICKET PORTAGE',
            'WNNIPEG',
            'FAIRFAX',
            'TILSTON',
            'LYLETON',
            'DUFRESNE',
            'BUFFALO POINT',
            'CLIFF LAKE RD',
            'REINFELD',
            'DACOTAH',
            'CHARLESWOOD',
            'GRANT PARK SHOPPING CENTRE',
            'RIVERSIDE',
            'SAINT BONIFACE',
            'POLO PARK',
            'DAKOTA TIPI',
            'ARROW RIVER',
            'MAFEKING',
            'FRANKLIN',
            'MEADOW PORTAGE',
            'SIFTON',
            'WATSON',
            'DUCK BAY',
            'FORK RIVER',
            'ST THERESA POINT',
            'JUSTICE',
            'NINGA',
            'WESTERN MEDICAL CLINIC',
            'NESBITT',
            'UPTOWN MALL',
            'RIDING MOUNTAIN',
            'GRISWOLD',
            'SAINTE-ROSE-DU-LAC',
            'HIGH BLUFF',
            'BRUXELLES',
            'CARROLL',
            'VERMETTE',
            'NEEPAWA',
            'GROSSE ISLE',
            'ALTAMONT',
            'HECLA ISLAND',
            'WASKADA',
            'MILNER RIDGE',
            'POWERVIEW-PINE FALLS',
            'MANIGOTAGAN',
            'FALCON BEACH',
            'MATLOCK',
            'VOGAR',
            'SHERRIDON',
            'RUSSELL',
            'WINNIPEG BEACH',
            'EASTERVILLE',
            'CARDALE',
            'CORMORANT',
            'EAST BRAINTREE',
            'MACDONALD',
            'STEVENSON ISLAND',
            'ALBERT BEACH',
            'SPRINGSTEIN',
            'YORK LANDING',
            'SAINT-MALO',
            'MIDDLEBRO',
            'GARDENTON',
            'CHANNING AIRPORT',
            'LA BARRIERE',
            'ST VITAL',
            'TRANSCONA',
            'ST-CLAUDE',
            'CRANDALL',
            'CROMER',
            'SANFORD',
            'ELPHINSTONE',
            'BEULAH',
            'KENTON',
            'BIRNIE',
            'WATERHEN',
            'INTERLAKE',
            'CAMPERVILLE',
            'KOLA',
            'PIPESTONE',
            'BENITO',
            'SOUTH JUNCTION',
            'ELKHORN',
            'WINNIPEGOSIS',
            'WOODMORE',
            'FALCON LAKE',
            'BALDUR',
            'ST-LAZARE',
            'HANOVER',
            'LIZ LAKE',
            'EMERSON',
            'ELGIN',
            'CLEAR LAKE',
            'OAK RIVER',
            'HODGSON',
            'SPRUCEWOODS',
            'ST EUSTACHE',
            'WARREN',
            'MITCHELL',
            'ALEXANDER',
            'FANNYSTELLE',
            'ARDEN',
            'PELICAN RAPIDS',
            'JORDAN',
            'LAKE AUDY',
            'SNOWFLAKE',
            'MENISINO',
            'KELWOOD',
            'NEWDALE',
            'LORNE',
            'STEPHENFIELD',
            'LAURIER',
            'WINITOBA MALL',
            'BIRTLE',
            'OAKBURN',
            'NEWTON SIDING',
            'ST-LAURENT',
            'ROSEBANK',
            'PORTAGE LA PRAIRIE RM',
            'PLUMAS',
            'LEAF RAPIDS',
            'SOUTHPORT',
            'KINOSOTA',
            'HILBRE',
            'FOXWARREN',
            'BALMY BEACH',
            'CAYER',
            'SIDNEY',
            'ST-JEAN-BAPTISTE',
            'WEST ST PAUL',
            'DOMAIN',
            'FORT GARRY',
            'BAGOT',
            'BROCHET',
            'AUSTIN',
            'VASSAR',
            'ALONSA',
            'PINEY',
            'LANGRUTH',
            'MALLARD',
            'LA RIVIERE',
            'WATERFORD AVE',
            'WESTBOURNE',
            'ST LEON',
            'HAYWOOD',
            'MATHESON ISLAND',
            'ROSETOWN',
            'CHARLESSWOOD',
            'SKOWNAN',
            'HORNDEAN',
            'DECKER',
            'GREAT FALLS',
            'ST JOSEPH',
            'ST FRANCOIS XAVIER',
            'FORT ALEXANDER',
            'ST MALO',
            'GRETNA',
            'ROSEAU RIVER',
            'TRAVERSE BAY',
            'GLENLEA',
            'RANDOLPH',
            'GONOR',
            'GARLAND',
            'HOWDEN',
            'THORNHILL',
            'SAINT-LAURENT',
            'SOUTH CYPRESS',
            'NAROL',
            'POWERVIEW',
            'LANDMARK',
            'VICTORIA BEACH',
            'WHITE LAKE',
            'LAC BROCHET',
            'MCMUNN',
            'POINTE DU BOIS',
            'BISSETT',
            'ST LAURENT',
            'ANOLA',
            'FAIRFORD',
            'ZHODA',
            'STRATHCLAIR',
            'WOODLANDS',
            'ST. LAURENT',
            'PINE CREEK INDIAN RSVE',
            'SAINTE ROSE DU LAC',
            'OCHRE RIVER',
            'STE AGATHE',
            'SANDY HOOK',
            'AMARANTH',
            'LAKE FRANCIS',
            'ST. GEORGES',
            'ST ADOLPHE',
            'NORTH KILDONAN',
            'BELMONT',
            'SELKIRK',
            'ST BONIFACE',
            'MINITONAS',
            'TREHERNE',
            'ERIKSDALE',
            'OAKVIEW',
            'RED SUCKER LAKE',
            'CLANDEBOYE',
            'WASAGAMACK',
            'CRYSTAL CITY',
            'FLIN FLON',
            'MCMILLAN',
            'PAUINGASSI',
            'SAINT FRANCOIS XAVIER',
            'BERENS RIVER',
            'GLENELLA',
            'GRUNTHAL',
            'WABOWDEN',
            'PUKATAWAGAN',
            'AIRPORT',
            'ETHELBERT',
            'OXFORD HOUSE',
            'RAPID CITY',
            'SAINT MALO',
            'LIBAU',
            'EDDYSTONE',
            'BOGGY CREEK',
            'RED ROCK LAKE',
            'PORTAGE-LA-PRAIRIE',
            'LUNDAR',
            'SARTO',
            'GODS LAKE NARROWS',
            'RORKETON',
            'WINNIPEG',
            'HOMEWOOD',
            'STONY MOUNTAIN',
            'SUNDOWN',
            'FORT ALEXANDER INDIAN RESERVE',
            'ST JEAN',
            'TOUROND',
            'OAKVILLE',
            'ONANOLE',
            'VITA',
            'GRAND RAPIDS',
            'SAN CLARA',
            'ST PIERRE JOLYS',
            'CAMP MORTON',
            'ARNES',
            'MORRIS',
            'WELLWOOD',
            'COULTER',
            'DUFROST',
            'ST-PIERRE-JOLYS',
            'WHITESHELL',
            'EAST KILDONAN',
            'WEST HAWK LAKE',
            'SPRINGFIELD',
            'THOMPSON',
            'KOMARNO',
            'STEAD',
            'MIAMI',
            'RICHER',
            'STEINBACH',
            'BROAD VALLEY',
            'ST JEAN BAPTISTE',
            'OTTERBURNE',
            'STANLEY',
            'SOMERSET',
            'NOTRE DAME DE LOURDES',
            'SPERLING',
            'CROSS LAKE',
            'OPASKWAYAK',
            'CRANBERRY PORTAGE',
            'ROLAND',
            'WANLESS',
            'SNOW LAKE',
            'LA BROQUERIE',
            'ROSSER',
            'ST CLAUDE',
            'RATHWELL',
            'SPLIT LAKE',
            'GILBERT PLAINS',
            'EAST ST. PAUL',
            'PEGUIS',
            'ROSENORT',
            'BLUMENORT',
            'ST CLEMENTS',
            'MANITOU',
            'GLENBORO',
            'COOKS CREEK',
            'SWAN RIVER',
            'LETELLIER',
            'MACGREGOR',
            'TADOULE LAKE',
            'WAWANESA',
            'ST AMBROISE',
            'WANIPIGOW',
            'LOWE FARM',
            'HAMIOTA',
            'SAINT-JEAN-BAPTISTE',
            'EDEN',
            'DARLINGFORD',
            'WHITEMOUTH',
            'SINCLAIR',
            'MINIOTA',
            'ST NORBERT',
            'GREENLAND',
            'ST. CLEMENTS',
            'CARTWRIGHT',
            'ROSEAU RIVER INDIAN RSVE',
            'POPLAR POINT',
            'MARGARET',
            'VIDIR',
            'BOWSMAN',
            'MCKENZIE PORTAGE',
            'OAK POINT',
            'TUXEDO',
            'STARBUCK',
            'RESTON',
            'BOISSEVAIN',
            'CARMAN',
            'CARTIER',
            'MARIAPOLIS',
            'GODS RIVER',
            'MATHER',
            'DOUGLAS',
            'NEGGINAN',
            'STE. ANNE',
            'NEW BOTHWELL',
            'PINE FALLS',
            'LORETTE',
            'ROSEAU RIVER INDIAN RESERVE',
            'INGLIS',
            'BIRCH RIVER',
            'NELSON HOUSE',
            'ST MARKS',
            'LYNN LAKE',
            'ELIE',
            'CYPRESS RIVER',
            'PIKWITONEI',
            'ELMA',
            'BEAUSEJOUR',
            'RITCHOT',
            'DURBAN',
            'KILLARNEY',
            'DUGALD',
            'OTINEKA MALL',
            'MOOSE LAKE',
            'SAINT ANDREWS',
            'FORREST STATION',
            'KLEEFELD',
            'GRANDE POINTE',
            'SAINT LEON',
            'ILFORD',
            'WINNIPEG-KLEYSEN',
            'GILLAM',
            'CHURCHILL',
            'DALLAS',
            'KELSEY',
            'LA SALLE',
            'RIVERTON',
            'PEMBINA',
            'MINTO',
            'SANDY LAKE',
            'SOURIS',
            'NAPINKA',
            'ASSINIBOIA',
            'HAZELRIDGE',
            'WEST SAINT PAUL',
            'FISHER BRANCH',
            'SILVER CREEK',
            'OAK BLUFF',
            'NARCISSE',
            'MARCHAND',
            'ARBORG',
            'MCAULEY',
            'MOOSEHORN',
            'DUNREA',
            'TOLSTOI',
            'ROSSBURN',
            'WICKHAM',
            'RENWER',
            'LITTLE GRAND RAPIDS',
            'NOPIMING PROVINCIAL PARK (NEAR LAC DU BO',
            'CLANWILLIAM',
            'OAKBANK',
            'ASHERN',
            'TOUTES AIDES',
            'GUNTON',
            'MINNEDOSA',
            'BROOKDALE',
            'ST MARTIN',
            'BELAIR',
            'ILE DES CHENES',
            'CARBERRY',
            'PEGUIS INDIAN RESERVE',
            'ARGYLE',
            'WOODRIDGE',
            'ILE-DES-CHENES',
            'MARIUS',
            'SAINT CLAUDE',
            'MELITA',
            'ST. FRANCOIS XAVIER',
            'EAST ST PAUL',
            'WINKLER',
            'STUARTBURN',
            'SAINT JEAN BAPTISTE',
            'PINE RIVER',
            'SPRAGUE',
            'ROSEISLE',
            'WASAGAMING',
            'WEST STEINBACH',
            'PINAWA',
            'WINIPEG',
            'SANDILANDS',
            'BALMORAL',
            'PLUM COULEE',
            'TOOTINAOWAZIIBEENG',
            'LOCKPORT',
            'ROSSENDALE',
            'ST GEORGES',
            'BLOODVEIN',
            'ERICKSON',
            'GIROUX',
            'INWOOD',
            'ST. CLAUDE',
            'SOUTH INDIAN LAKE',
            'ELM CREEK',
            'ST JAMES',
            'PILOT MOUND',
            'NORTH CYPRESS',
            'STONEWALL',
            'EAST SELKIRK',
            'GARSON',
            'ST. GEORGE',
            'BRANDON',
            'ST ANDREWS',
            'STE ROSE DU LAC',
            'SHILO',
            'HARTNEY',
            'DELEAU',
            'SWAN LAKE',
            'RIVERS',
            'OAK LAKE',
            'WINNIPEG MB',
            'LAC DU BONNET',
            'ANGUSVILLE',
            'OSTERWICK',
            'WASAGAMACH',
            'HOLLAND',
            'TEULON',
            'MC CREARY',
            'PIERSON',
            'SHAMATTAWA',
            'NOTRE-DAME-DE-LOURDES',
            'RENNIE',
            'FORREST',
            'BRUNKILD',
            'CRANE RIVER',
            'ALTONA',
            'KOOSTATAK',
            'CLEARWATER',
            'SHOAL LAKE',
            'SOUTH BEACH',
            'SCANTERBURY',
            'LAKE OF THE PRAIRIES',
            'ISLAND LAKE',
            'MCCREARY',
            "O'HANLY",
            'TYNDALL',
            'GRANDVIEW',
            'SILVER RIDGE',
            'THE PAS',
            'MEDORA',
            'ARNAUD',
            'PORTAGE LA PRAIRIE',
            'BETHANY',
            'GYPSUMVILLE',
            'ST LAZARE',
            'AUBIGNY',
            'NORWAY HOUSE',
            'BIFROST',
            'BEACONIA',
            'BOX 1842',
            'EDWIN',
            'ILE DES CHENES EAST',
            'DELORAINE',
            'GYPUMSVILLE',
            'GRAND MARAIS',
            'RIVER HILLS',
            'REGINA',
            'FRASERWOOD',
            'ST. ANDREWS',
            'HEADINGLEY',
            'ROSA',
            'CFB SHILO',
            'LETTONIA',
            '',
            'VIRDEN',
            'PETERSFIELD',
            'COWAN',
            'SANDY BAY RSVE',
            'ROBLIN',
            'STEEP ROCK',
            'EBB AND FLOW',
            'BARROWS',
            'STE ANNE',
            'HADASHVILLE',
            'RIDGEVILLE',
            'BINSCARTH',
            'ROCK RIDGE',
            'SAINT ADOLPHE',
            'GIMLI',
            'POPLARFIELD',
            'MELEB',
            'SEVEN SISTERS FALLS',
            'NIVERVILLE',
            'DAUPHIN',
            'MORDEN',
            'ST GERMAIN SOUTH',
            'ROSENFELD',
            'NINETTE',
            'DOMINION CITY',
            'GLADSTONE',
        ],
    },

    /* 8 */
    {
        _id: 'NS',
        cities: [
            'OLDHAM',
            'NICTAUX',
            'GABARUS LAKE',
            'BIG POND',
            'BRIGHTON',
            'FRAMBOISE',
            'LUMSDEN DAM',
            'MCNABS ISLAND',
            'PORT PHILLIP',
            'LINWOOD',
            'CAPSTICK',
            'SHORTTS LAKE',
            'FOURCHU',
            'MUSQUODOBOIT HARBOR',
            'CEDAR LAKE',
            'LOWER EEL BROOK',
            'CLARE',
            'SHIP HARBOUR',
            'SAINT ANDREWS',
            'NICHOLSVILLE',
            'HORTONVILLE',
            'SUBD. B',
            'HAZELDALE',
            'DALEM LAKE',
            'DALHOUSIE UNIVERSITY',
            'TORONTO',
            'NOVA SCOTA',
            'WINDSOR NS',
            'HEAD OF ST. MARGARETS BAY',
            'SACKVILLE NS',
            'INGOMAR',
            'DIGBY MUNICIPAL DISTRICT',
            'BLACK ROCK',
            'NORTH BROOKFIELD',
            'FALKLAND RIDGE',
            'GLASS ST',
            'PLEASANT LAKE',
            'ECUM SECUM',
            'THREE MILE PLAINS',
            'NEW HARRIS',
            'CAPE GEORGE',
            'NORTHSIDE EAST BAY',
            'LAKE EGMONT',
            'BIG LAKE',
            'DARLINGS LAKE',
            'COMMERCIAL ST',
            'MIDDLE SACKVILE',
            'NORTH EAST POINT',
            'WOODSTOCK',
            'SPRING GARDEN RD',
            'ROCKY MOUNTAIN',
            'KELTIC DR',
            'COALBURN',
            'MIDDLE LAHAVE',
            'ASHDALE',
            'MCCALLUM SETTLEMENT',
            'GAETZ BROOK',
            'KINSAC',
            'LUNENBURG COUNTY',
            'SUBD. D',
            'HISTORIC PROPERTIES',
            'LUCASVILLE RD',
            'WAYCOBAH',
            'NEW RUSSEL',
            'FRENCH ROAD',
            'WEST CLIFFORD',
            'HAIFAX',
            'ISLANDVIEW',
            'EAST RIVER',
            'WOODS HBR',
            'SUMMERVILLE CENTRE',
            'FOREST HOME',
            'PEMBROKE',
            'WEDGEWOOD',
            'WEST GORE',
            'CAPE ST. MARYS',
            'ST JOSEPH',
            'GLACEBAY',
            'WEST RIVER',
            'BAKER SETTLEMENT',
            'BROADCOVE',
            'NORTH GRANT',
            'ST TRURO',
            'HALF ISLAND COVE',
            'COMEAUVILLE',
            'GROSSES COQUES',
            'CHERRY HILL',
            'SOUTHSIDE HARBOUR',
            'PINE GROVE.',
            'CREIGNISH',
            'CLYDESDALE',
            'SHIP HARBOR',
            'HILLSIDE BOULARDERIE',
            'MIDDLE PORTERS LAKE',
            'SCOTCH VILLAGE',
            'TOWER ROAD',
            'WENTZELLS LAKE',
            'DEERFIELD',
            'UPPER BRANCH',
            'PHINNEY COVE',
            'NOEL SHORE',
            'SOUTH CHEGOGGIN',
            'ARGYLE',
            'EAST TREMONT',
            'CLEMENTSPORT NS',
            'GREAT VILLAGE',
            'PORTER LAKE',
            'CAMBRIDGE STN',
            'RIVIERE-AUX-SAUMONS',
            'YELLOW MARSH (CANSO)',
            'SOUTH BAR',
            'BAY ST LAWRENCE',
            'LIVINGSTONE COVE',
            'AULDS COVE',
            'MACKINNONS HARBOUR',
            'UPPER BROOKSIDE',
            'BROOKLYN.',
            'LOWER EAST PUBNICO',
            'COXHEATH',
            'ALLAINS CREEK',
            "NEIL'S HARBOR",
            'DARTMOUTH NS',
            'VICTORIA GENERAL SITE',
            'COGMAGUN',
            'WEST ARICHAT',
            'CAPE NORTH',
            'PARRSBORO',
            'ST. PETERS',
            'HAMMOND PLAINS',
            'PROSPECT.',
            'CROUSETOWN',
            'SAND BEACH',
            'LOUISDALE',
            'ANTIGONISH COUNTY',
            'MT UNIACKE',
            "HEBB'S CROSS",
            'MOUNT UNIACKE',
            'YARTMOUTH',
            'WEST BROOKLYN',
            'SALTS SPRINGS',
            'KEMPTOWN',
            'CAPE DAUPHIN',
            'GRAND PRE',
            'BLOMIDON',
            'GOFFS',
            'LAWRENCETOWN.',
            'KAULBACK ST',
            'SOLDIERS COVE',
            'STE CROIX',
            'WALDECK EAST',
            'SALT SPRINGS',
            'PRINCE ALBERT',
            'CHURCH RD',
            'UPPER NORTH RIVER',
            'SOUTH WATERVILLE',
            'DARMOUTH',
            'CHEVERIE',
            'HAMPTON',
            'LITTLE RIVER',
            'NEW VICTORIA',
            'WESTCHESTER STATION',
            'PICTOU ISLAND',
            'FRANKVILLE',
            'OAKFIELD',
            'LISCOMB',
            'LAWRENCETOWN',
            'MOOSELAND',
            'POINT ACONI',
            'MCGRATHS COVE',
            'IONA',
            'UPPER LAHAVE',
            'THOMSON STATION',
            'SHUBENCADIE',
            'MURPHY LAKE',
            'FORT ELLIS',
            'HEAD OF JEDDORE',
            'CURRYS CORNER',
            'AFTON STATION',
            'BRAZIL LAKE',
            'LOCHABER LAKE',
            'NEWCOMBVILLE',
            'BEDFORD PLACE MALL',
            'PANUKE RD',
            'HEAD OF ST MARGARETS BAY',
            'GOODWOOD',
            'HALIFAX SEAPORT MARKET',
            'EAST PENNANT',
            'WEST AMHERST',
            'SOUTH RIVER ROAD',
            'MILL VILLAGE.',
            'ALTON',
            'EVANGELINE BEACH',
            'NUTTBY',
            'OAK HILL',
            'EAST BACCARO',
            'EAST KEMPTVILLE',
            'UPPER NORTHFIELD',
            'HARDWOOD LANDS',
            'RIVERSDALE',
            'TOR BAY',
            "ST. PETER'S",
            'WEST PENNANT',
            'PORTUGUESE COVE',
            'JUNIPER MOUNTAIN',
            'BELLE COTE',
            'IRISH COVE',
            'UPPER SACKVILLE',
            'MONKS HEAD',
            'CASEY CORNER',
            'MCLELLANS BROOK',
            'BADDECK',
            'MIDDLEWOOD',
            'STEAM MILL',
            'NEWPORT STATION',
            'BELNAN',
            'RICHMOND',
            'MIRA GUT',
            'NEW CANADA',
            'ROCKDALE',
            'RIVERSIDE',
            'EAST AMHERST',
            'MURRAY SIDING',
            'MONTAGUE GOLD MINES',
            'FORBES POINT',
            'SOUTHSIDE',
            'EAST JEDDORE',
            'SYDNEY',
            'CONQUERALL MILLS',
            'BOISDALE',
            'DARTHMOUTH',
            'BARSS CORNERS',
            'UPPER KENNETCOOK',
            'QUEENSPORT',
            'WAGMATCOOK',
            'SERVING CAPE BRETON',
            'LAPLAND',
            'SAMBRO CREEK',
            'TIDNISH BRIDGE',
            'MARTINS POINT',
            'PEGGYS COVE',
            'NORTH SIDNEY',
            'GASPEREAUX LAKE',
            'BURNSIDE INDUSTRIAL PARK',
            'ABERCROMBIE',
            'SOUTH ALTON',
            'WESTERN SHORE',
            'BEECHVILLE',
            'WINDSOR',
            'TROY',
            'VICTORIA MINES',
            'WESTMOUNT',
            'BARTON',
            'MEIKLEFIELD',
            'GARDNERS MILLS',
            'WELSFORD',
            'BEN EOIN',
            'FOREST GLADE',
            'LOWER SOUTH RIVER',
            'UPPER NINE MILE RIVER',
            'BEAR RIVER',
            'BRANCH LAHAVE',
            'GROVES POINT',
            'SOUTH MAITLAND',
            'MERIGOMISH',
            'CAMBRIDGE',
            'ST JOSEPH DU MOINE',
            'FLETCHERS LAKE',
            'CELLULAR',
            'WALLBROOK',
            'BEECH HILL',
            'MARGAREE VALLEY',
            'SOUTH GREENWOOD',
            'EAST UNIACKE',
            'CENTERVILLE',
            'BROOKLYN CORNER',
            'MAIN-A-DIEU',
            'BURNSIDE',
            'ROBINSON CORNER',
            'NORTH RIVER',
            'BROOKDALE',
            'BUNBURY',
            'YARMOUTH COUNTY',
            'WEST HAVRE BOUCHER',
            'ENFIELD',
            'BELLIVEAUS COVE',
            'PRIESTVILLE',
            'GREEN BAY',
            'EAST DALHOUSIE',
            'PORTAGE',
            'CHEZZETCOOK',
            'BAYSIDE',
            'GRAND MIRA NORTH',
            'HEBERT',
            "POINTE-DE-L'EGLISE",
            'LAKE FLETCHER',
            'FOREST HILLS',
            'SEAFORTH',
            'ANTIGONISH MALL',
            'HARBOUR CENTRE',
            'STEWIAKE',
            'LOWER ONSLOW',
            'NEWTOWN',
            'PORT HAWKESBURY OFFICE',
            'ONSLOW MOUNTAIN',
            'CLEVELAND',
            'UPPER HAMMONDS PLAINS',
            'JEDDORE',
            'WEST NORTHFIELD',
            'NEWTONVILLE',
            'ENON',
            'ARISAIG',
            'OXFORD JUNCTION',
            'NOVA SCOTIA',
            'PORT LA TOUR',
            'KETCH HARBOUR',
            'BEDFORD NS',
            'LAKEVIEW',
            'HAMMONDS PLAIN',
            'BEAR COVE',
            'HEARST',
            'ST PETERS',
            'KEMPT',
            'NOVA SCOTIA TOLL FREE',
            "PORTER'S LAKE",
            'SUMMER ST RD',
            'LWR ONSLOW',
            'FENWICK',
            'COW BAY',
            'HALIFAX / DARTMOUTH',
            'COLE HARBOUR HALIFAX',
            'RHODES CORNER',
            'BELNEN',
            'ORANGEDALE',
            'COLCHESTER',
            'ST-JOSEPH-DU-MOINE',
            'MILFORD STATION',
            'WESTVILLE ROAD',
            'LEQUILLE',
            'NS',
            'INDIAN HARBOUR',
            'WEST BLACK ROCK',
            'MIDDLETOWN',
            'SERVING HRM',
            'CLOVERVILLE',
            'ALDER POINT',
            'LINGAN',
            'INVERNESS',
            'BIRCH GROVE',
            'PLEASANT VALLEY',
            'WESTPORT',
            'SOUTH TREMONT',
            'QUEENS',
            'WEST ST ANDREWS',
            'ANTRIM',
            'UPPER CLYDE',
            'GUYSBOROUGH',
            'UPPER RAWDON',
            'MARSHY HOPE',
            'HANTS COUNTY',
            'DEEP COVE',
            'LANESVILLE',
            'MAITLAND',
            'CONWAY',
            'LOWER FIVE ISLANDS',
            'SHUBENACADIE EAST',
            'JOGGINS',
            'HANTSPORT',
            'UPPER VAUGHAN',
            'BARNEYS RIVER STATION',
            'DILIGENT RIVER',
            'EAST HANTS',
            'LOWER WEST PUBNICO',
            'WEST RIVER STATION',
            'GRANTON',
            'LOWER WOLFVILLE',
            'WESTVILLE',
            'COLLINGWOOD',
            'OAKLAND',
            'BOYLSTON',
            'SPRINGVILLE',
            'GOLDBORO',
            'MILL VILLAGE',
            'LITTLE DOVER',
            'SHELBURNE SHOPPING CENTRE SHELBURNE',
            'TANCOOK ISLAND',
            'NORTH PRESTON',
            'WEST BAY',
            "LOWER L'ARDOISE",
            'BARRINGTON',
            'PURLBROOK',
            'MARGAREE CENTRE',
            'CHAPEL ISLAND',
            'PINE GROVE',
            'KEMPT HEAD',
            'ST. ANDREWS',
            'CAMBRIDGE STATION',
            'COOKVILLE',
            'MONCTON',
            'GASPEREAU',
            'KINGS',
            'DARLING LAKE',
            'EUREKA',
            'BEAVERBANK',
            'UPPER NAPPAN',
            'TRUEMANVILLE',
            'BLANDFORD',
            'SPECTACLE LAKES',
            'INDIAN BROOK',
            'LUCASVILLE',
            'WEST PUBNICO',
            'TANTALLON',
            'JAMESVILLE',
            'PINE TREE',
            'LOWER PROSPECT',
            'AVONPORT',
            'CHRISTMAS ISLAND',
            'WELLINGTON',
            'PORT HOWE',
            'NORTH KENTVILLE',
            'KINGS HEAD',
            'FRENCH VILLAGE',
            'GLEN MARGARET',
            'HACKETTS COVE',
            'MABOU',
            'SPRYFIELD',
            'SAMBRO HEAD',
            'SAMBRO',
            'SHAD BAY',
            'HALIFAX NS',
            'WEST LA HAVE',
            'EAST LAWRENCETOWN',
            'BLOCK HOUSE',
            'ROCKVILLE',
            'CHERRY BROOK',
            'DICKSON BUILDING VGH SITE',
            'CARIBOU MARSH',
            'WINDSOR JUNCTION',
            'CLIFTON',
            'LEWIS LAKE',
            'EAST DOVER',
            'EAST LAHAVE',
            'LAKE CHARLOTTE',
            'MIDDLEBORO',
            'CROSS ROADS OHIO',
            'GRAND NARROWS',
            'BOULARDERIE EAST',
            'PLYMPTON',
            'OLD BARNS',
            'POINT EDWARD',
            'KENTVILLE',
            'SHEET HARBOUR',
            'KEDDYS CORNER',
            'SHUBENACADIE',
            'RINES CREEK',
            'PORT HASTINGS',
            'LUNEMBURG',
            'LUNENBURG MUNICIPAL DISTRICT',
            'RIVER RYAN',
            'HALIFAX TOLL FREE',
            'PROSPECT BAY',
            'BOULARDERIE',
            'BELMONT',
            'WENTWORTH',
            'HARRIETSFIELD',
            'POMQUET',
            'HOPEWELL',
            'HOWIE CENTER',
            'SCOTSVILLE',
            'CANADA',
            'LOWER SACKVILLE',
            'PRIME BROOK',
            'LOWER BRANCH',
            'LOCKEPORT',
            'PUGWASH',
            'DOMINION',
            'LAKE KILLARNEY',
            'SPANISH SHIP BAY',
            'SHELBURNE MUNICIPAL DISTRICT',
            'BACCARO',
            'MAVILLETTE',
            'HALF WAY COVE',
            'DONKIN',
            'COLLINGWOOD CORNER',
            'HABITANT',
            'PORT HASTINGS SITE',
            "SAINT PETER'S",
            'BRENTON',
            'CLYDE RIVER',
            'SANDY COVE',
            "BIG BRAS D'OR",
            'CARDIGAN',
            'CARLETON',
            'STONEY ISLAND',
            'SAINT JOHN',
            'METEGHAN RIVER',
            'DUNCANS COVE',
            'PORT CLYDE',
            'GRAND LAKE',
            'METEGHAN',
            'BRIDGEWATER',
            'BELLIVEAU COVE',
            'OHIO',
            'ARCADIA',
            "ST PETER'S",
            'PORT GEORGE',
            'SOUTH BROOKFIELD',
            'MILFORD',
            'CALEDONIA',
            "ST JOHN'S",
            'UPPER PORT LA TOUR',
            'MILTON',
            'GREENFIELD.',
            'EVANSTON',
            'SMITHS COVE',
            'KEMPTVILLE',
            'HILLSDALE',
            'MELVERN SQUARE',
            'TORBROOK MINES',
            'WOODVILLE',
            'QUEENSLAND',
            'SUMMERVILLE',
            'LWR VAUGHAN',
            'CROWELL',
            'NORTH SALEM',
            'PORT MOUTON',
            'CENTRE BURLINGTON',
            'MEAGHERS GRANT',
            'WILLIAMSWOOD',
            'PORTERS LAKE',
            'UPPER ONSLOW',
            'LORNEVILLE',
            'MIDDLE SACKVILLE',
            'RIVER HEBERT',
            'SAULNIERVILLE',
            'DARTMOURTH',
            'GULF SHORE',
            'NOVASCOTIA',
            'LONDONDERRY',
            "WENTZELL'S LAKE",
            'FRASERS MOUNTAIN',
            'PETIT DE GRAT',
            'TIVERTON',
            'GRAND LAKE ROAD',
            'RIVER JOHN',
            'GLACE BAY',
            'TANGIER',
            'DEEP BROOK',
            "BARNEY'S RIVER STATION",
            'MALAGASH',
            'DIGBY COUNTY',
            'QUEENSVILLE',
            'SALMON RIVER.',
            'BARNEYS RIVER',
            'CUMBERLAND',
            'POST ROAD',
            'WEST CHEZZETCOOK',
            'NEW MINAS',
            'AUBURN',
            'BICKERTON WEST',
            'HEBBS CROSS',
            'KENNETCOOK',
            'GUNNING COVE',
            'CORNWALLIS PARK',
            'CENTRAL ONSLOW',
            'BROOKSIDE',
            'MOSER RIVER',
            'CHESTER BASIN',
            'OVERTON',
            'PARADISE',
            'NORTHPORT',
            'SYDNEY FORKS',
            'JEDDORE OYSTER PONDS',
            'LUNENBERG',
            'MELANSON',
            'CROSS ROADS COUNTRY HARBOUR',
            'OXFORD',
            'WILLIAMS POINT',
            'GREENWOOD',
            'GOSHEN',
            'HUBBARDS',
            'MOUNT WILLIAM',
            'BIBLE HILL',
            'SOUTH OHIO',
            'CLARKS HARBOUR',
            'ASPEN',
            'WALTON',
            'PETITE RIVIERE',
            'RIVER BOURGEOIS',
            'WILMOT',
            'HEATHERTON',
            'CHETICAMP',
            'NORTH INGONISH',
            'HEBRON',
            'INGONISH',
            'WATERVILLE',
            'PORT DUFFERIN',
            'GRAND RIVER',
            'LAKE LOON',
            'PLEASANT BAY',
            'OAKHILL',
            'MEMBERTOU',
            'GRANVILLE FERRY',
            'SACKVILLE',
            'PETIT ETANG',
            'RIVERPORT',
            'JUDIQUE',
            'SOUTHHAMPTON',
            "AULD'S COVE",
            'HEAD OF CHEZZETCOOK',
            'MARGAREE HARBOUR',
            'NEWPORT',
            'DINGWALL',
            'KINGSTON',
            'SCOTCHTOWN',
            'GARLANDS CROSSING',
            'TRENTON',
            'PORTLAND',
            'MARYVALE',
            'ECONOMY',
            'BEAVER BANK',
            'INGONISH BEACH',
            'ST MARGARET VILLAGE',
            'BROOKLYN',
            'PROSPECT',
            'SHEARWATER',
            'SANDY POINT',
            'NOEL',
            'GRAND ETANG',
            'NEW ROSS',
            'AYLESFORD',
            'PLEASANTVILLE',
            'MULGRAVE',
            'URBANIA',
            'AMHERST CENTRE MALL',
            'HALIBUT BAY',
            'BROOKFIELDS',
            'SUBD. C',
            'HARMONY.',
            'PORT GREVILLE',
            'SABLE RIVER',
            'FLORENCE',
            'LEITCHES CREEK',
            'MIRA ROAD',
            'BROOKVILLE',
            'MARGAREE',
            'SALMON RIVER',
            'EAST GORE',
            'SOUTH PUGWASH',
            'UPPER POMQUET',
            'SUNKEN LAKE',
            'INGRAMPORT',
            'CARROLLS CORNER',
            'WILMOT STATION',
            'MIDDLE MUSQUODOBOIT',
            'TRURO NS',
            'ADDINGTON FORKS',
            'CHELSEA',
            'PORT WILLIAMS',
            'TOLL FREE',
            'BAYPORT',
            'ADVOCATE HARBOUR',
            'PROSPECT VILLAGE',
            'GREENWICH',
            'SPRINGFIELD',
            "NEIL'S HARBOUR",
            'MINEVILLE',
            'BEAVER BROOK',
            'BALLS CREEK',
            'MARTOCK',
            'NEW GERMANY',
            'HILDEN',
            'MCGRAY',
            'FALL RIVER',
            'NEW GLASGOW',
            'HAMMONDS PLAINS',
            'ANNAPOLIS ROYAL',
            'MCGEE LAKE',
            'MARGAREE FORKS',
            'MAITLAND BRIDGE',
            'BEAVER COVE',
            'FRENCHVALE',
            'BEAVER RIVER',
            'LOUISBOURG',
            'TRURO HEIGHTS',
            'EAST STEWIACKE',
            'CRAIGMORE',
            'MORRISTOWN',
            'LOWER WOODS HARBOUR',
            'GRAFTON',
            'SOUTH FARMINGTON',
            'SOUTH BERWICK',
            'MUMFORD PROFESSIONAL CENTRE',
            'ANTIGONISH LANDING',
            'GARDINER MINES',
            'PORT HOOD',
            'GLENHOLME',
            'VICTORIA',
            'CHIPMAN BROOK',
            'MID W PUBNICO',
            'NORTH ALTON',
            'MACCAN',
            'CANAAN.',
            'STELLARTON',
            'MIDVILLE BRANCH',
            "LYON'S BROOK",
            'HERRING COVE',
            'LITTLE BROOK',
            'WOLFVILLE RIDGE',
            'CHASWOOD',
            'ARDOISE',
            'GRAND MIRA SOUTH',
            'LOCHABER',
            'MIDDLE WEST PUBNICO',
            'MICMAC',
            'LAHAVE',
            'PUGWASH JUNCTION',
            'NEW WATERFORD',
            "D'ESCOUSSE",
            'PLYMOUTH',
            'SPRY BAY',
            'BOUTILIERS POINT',
            'MILL CREEK',
            'TRURO',
            'LOWER TRURO',
            'RESERVE MINES',
            'ST MARGARETS BAY',
            'MARION BRIDGE',
            'MUSQUODOBOIT HBR.',
            'ST ANDREWS CHANNEL',
            'SCOTSBURN',
            'LITTLE NARROWS',
            'LAKESIDE',
            'AUBURNDALE',
            'LINACY',
            'DEBERT',
            'SOUTH HARBOUR',
            'NEILS HARBOUR',
            'EASTERN PASSAGE',
            'STEWIACKE',
            'CANNING',
            'ITALY CROSS',
            'TOWN PLAZA',
            'THORBURN',
            'NORTH CHEGOGGIN',
            'EAST BAY',
            'UPPER MUSQUODOBOIT',
            'PLEASANT VALLEY.',
            'WEST BAY ROAD',
            'SYDNEY MINES',
            'OVERTON RD',
            'FLETCHER LAKE',
            "CLARK'S HARBOUR",
            'EAST MOUNTAIN',
            'NAPPAN',
            'ALMA',
            'ISAACS HARBOUR',
            'FERGUSONS COVE',
            'ELMSDALE',
            'SPRY HARBOUR',
            'LAKEVALE',
            'BASS RIVER',
            'DAYSPRING',
            'WHYCOCOMAGH',
            'CAMPERDOWN',
            'LANTZ',
            'DENSMORES MILLS',
            'GLEN HAVEN',
            'SPRINGHILL',
            'IRONVILLE',
            'WILEVILLE',
            'METEGHAN CENTRE',
            'MARTINS RIVER',
            'MUSQUODOBOIT HARBOUR',
            'NINE MILE RIVER',
            'COLDBROOK',
            'BLOCKHOUSE',
            'SCOTCH LAKE',
            'SUBD. A',
            'COOKS BROOK',
            'YARMOUTH',
            'BROOKFIELD',
            'CORNER BROOK',
            'ELLERSHOUSE',
            'TATAMAGOUCHE',
            'PETIT-DE-GRAT',
            'HRM',
            'HARMONY',
            'ENGLISHTOWN',
            'NEW GLASCOW',
            'MARGARETSVILLE',
            'ARICHAT',
            'WEST PORTERS LAKE',
            'SHERBROOKE',
            'ELDERBANK',
            'PORT MEDWAY',
            'PICTOU COUNTY',
            'LA HAVE',
            'LYONS BROOK',
            'BARSS CORNER',
            "BRAS D'OR",
            'RIVER DENYS',
            'THOMASVILLE',
            'LIVERPOOL',
            'WOODSIDE',
            'SANDFORD',
            'SYDNEY RIVER',
            'LITTLE HARBOUR',
            'LOWER WEDGEPORT',
            'VALLEY',
            'BLACK POINT',
            'NORTH SYDNEY',
            'CATALONE',
            'DARTMOUTH',
            'COLE HARBOUR',
            'WINDSOR JCT',
            'SAINT PETERS',
            'LAKE ECHO',
            'ROSE BAY',
            'SEABRIGHT',
            'CORNWALLIS',
            'GREAT TANCOOK ISLAND',
            'BLACK ROCK.',
            'PORT JOLI',
            'FALMOUTH',
            'SAINT MARGARET VILLAGE',
            'GAYS RIVER',
            'POINT TUPPER',
            '',
            'CENTRAL NORTH RIVER',
            'COQ',
            'SYDNEY-MINES',
            'MILLVILLE',
            'WEST HANTS',
            'ST ANDREWS',
            'WALLACE',
            'BROOKSIDE.',
            'BOYLESTON',
            'SAMPSON COVE',
            'CONQUERALL BANK',
            'ABERDEEN',
            'WOLFVILLE',
            'PUBNICO-OUEST',
            'BRIDGETOWN',
            'ESKASONI',
            'MILLBROOK',
            'UPPER STEWIACKE',
            'SILVER POINT RD',
            'LUNENBURG',
            'CAMBRIDGE 32',
            'BEAVERBROOK',
            'TERENCE BAY',
            'GREENFIELD',
            'BLACK RIVER LAKE',
            'BARRINGTON PASSAGE',
            'ANTIGONISH',
            'MIDDLETON',
            'FOREST HILL',
            'HEBBVILLE',
            'CANSO',
            'CROWES MILLS',
            'BERWICK',
            'CHURCH POINT',
            "HD OF ST MARGARET'S BAY",
            'ALBERT BRIDGE',
            'CLEMENTSVALE',
            'YAMOURTH',
            'CAPE BRETON',
            'BEDFORD',
            'LAKEVILLE',
            'MONASTERY',
            'PORT HAWKESBURY',
            'WESTPHAL',
            'STE-ANNE-DU-RUISSEAU',
            'DIGBY',
            'HALIFAX',
            'GLENWOOD',
            'SHELBURNE',
            'EAST MARGARETSVILLE',
            'HUBLEY',
            'PUBNICO',
            'COLDBROOK CENTRE',
            "LITTLE BRAS D'OR",
            'PORT MORIEN',
            'FREEPORT',
            'NORTH EAST MARGAREE',
            'EAST PRESTON',
            'GEORGES RIVER',
            'BLACKETTS LAKE',
            'SALT SPRINGS PICTOU',
            'EDWARDSVILLE',
            'STILLWATER LAKE',
            'SHAG HARBOUR',
            'WEST DOVER',
            'SYDEY MINES',
            'WOODS HARBOUR',
            'NORTH KINGSTON',
            'PORT MAITLAND',
            'HATCHET LAKE',
            'MAHONE BAY',
            'MEADOWVALE',
            'DUTCH SETTLEMENT',
            'WHITES LAKE',
            'RED ISLANDS',
            'METEGHAN CTR',
            'TUSKET',
            'SOUTHAMPTON',
            'IRISHVALE',
            'EAST KINGSTON',
            'LARRYS RIVER',
            'RIVER PHILIP',
            'CLEMENTSPORT',
            'WEDGEPORT',
            'TIMBERLEA',
            'WEST HEAD',
            'CHESTER',
            'SHEROSE ISLAND',
            'CENTREVILLE',
            'FIVE ISLANDS',
            'HAVRE BOUCHER',
            'WHITE ROCK',
            'AMHERST',
            'UPPER TANTALLON',
            "CURRY'S CORNER",
            'BRANCH LA HAVE',
            "L'ARDOISE",
            'CAMDEN',
            'DAYTON',
            'UPPER PORT LATOUR',
            'FORT LAWRENCE',
            "GARLAND'S CROSSING",
            'PICTOU',
            'WEST LEICESTER',
            'JORDAN FALLS',
            'HALIFAX REGIONAL MUNICIPALITY',
            'WAVERLEY',
            'HUNTS POINT',
            'BEDFORD SOUTH',
            'WEYMOUTH',
        ],
    },

    /* 9 */
    {
        _id: 'NU',
        cities: [
            'GJOA HAVEN',
            'TALOYOAK',
            'KUGLUKTUK',
            'RESOLUTE',
            'IQLOOLIK',
            '',
            'ARCTIC BAY',
            'ARVIAT',
            'WHALE COVE',
            'CAPE DORSET',
            'KIMMIRUT',
            'POND INLET',
            'CLYDE RIVER',
            'IQUALUIT',
            'NAUJAAT',
            'CORAL HARBOUR',
            'KUGAARUK',
            'PANGNIRTUNG',
            'CHESTERFIELD INLET',
            'HALL BEACH',
            'BAKER LAKE',
            'CAMBRIDGE BAY',
            'IQALUIT',
            'GRISE FIORD',
            'SANIKILUAQ',
            'QIKIQTARJUAQ',
            'IGLOOLIK',
            'RANKIN INLET',
        ],
    },

    /* 10 */
    {
        _id: 'NL',
        cities: [
            'PORT AUX CHOIX',
            'SNOOKS ARM',
            'WEST SAINT MODESTE',
            'NEW PERLICAN',
            'SUBD. H',
            "COOK'S HARBOUR",
            'BURNT ISLANDS',
            'SUBD. C',
            'CENTRAL FOGO ISLAND',
            'SOUTH BROOK',
            'SUBD. Y',
            'CAPE RAY',
            'SAINT PAULS',
            'SUBD. F',
            'SUBD. A',
            'CANADA',
            'ST. JOHN',
            'KNIGHTS COVE',
            'CARMENVILLE',
            'CROW HEAD',
            "COX'S COVE",
            'SALT POND',
            "JEFFREY'S",
            'HAPPY VALLEY- GOOSE BAY',
            'BROWNS ARM',
            'ST-JOHN',
            'BUCHANS JUNCTION',
            'ST BERNARDS',
            'HAPPY VALLEY - GOOSE BAY',
            'SHESHATSHIU',
            'HUMAN RESOURCES BLDG',
            'ENTON',
            'BISHOP FALLS',
            'ST JOSEPHS SAL',
            'TOPSAIL',
            "O'DONNELLS",
            'ST PHILLIPS',
            'MAIN',
            'FOX HARBOUR PB',
            "BURT'S LN",
            "EARLE'S LN",
            'SAINT-JEAN',
            'BRIDGEPORT',
            'MAJORS PATH',
            'GRENFELL DR',
            'KINGS COVE',
            "COLEY'S POINT",
            "GEORGE'S BROOK",
            'SOUTHERN HARBOUR',
            'THREE ROCK COVE',
            'RED HEAD COVE',
            'HUMBER VALLEY',
            'HILLGRADE',
            'PORT AU PORT EAST',
            'BAY ST. GEORGE SOUTH',
            'WEST LINK',
            'WEST BAY',
            'EASPORT',
            "ST. BERNARD'S-JACQUES FONTAINE",
            'ST. JOHNS',
            'GREAT CODROY',
            'GREEN ISLAND COVE',
            'LODGE BAY',
            'FORTUNE HARBOUR',
            'METEGHAN',
            "HEART'S DELIGHT-ISLINGTON",
            'ROCKEY HARBOUR',
            "ST ALBAN'S",
            'BEACHSIDE',
            "CARTER'S COVE",
            'PORT AU BRAS',
            "DANIEL'S HARBOUR",
            'BIDE ARM',
            'SAVAGE COVE',
            'BEAR COVE',
            'ATLANTIC PL',
            'CORNER BY CHANCE',
            "NORMAN'S COVE",
            "ST. MARY'S",
            'GREENSPOND ISLAND',
            'KEELS',
            'CENTREVILLE-WAREHAM-TRINITY',
            'NORTH VALLEY',
            'ARGENTIA',
            'LEWINS COVE',
            'ST LUNIARE-GRIQUET',
            'TRINITY BAY DILDO',
            "L'ANSE-AU-CLAIR",
            'PORTUGAL COVE - ST PHILIPS',
            'CAMPBELTON',
            "ST. BRENDAN'S",
            "NIPPARD'S HARBOUR",
            "JOHN'S",
            'ST. GEORGES',
            'GRANDER',
            'PLACENTIA BAY',
            'DONAVANS IND PARK MOUNT PEARL',
            'NORRIS ARM NORTHSIDE',
            'LABRADOR',
            'GRAND BAY WEST',
            'VALLEYFIELD',
            'CLARKS BEACH',
            'BLACK DUCK SIDING',
            'COLLIERS',
            "ST.JOHN'S",
            'ST. JOHN`S',
            'FOGO ISLAND',
            'LITTLE BAY',
            'GRAND FALLS?WINDSOR',
            "BURGOYNE'S COVE",
            "MING BIGHT'S ROAD",
            'WILTONDALE',
            'LAW OFC ATLANTIC PL',
            'FRESHWATER',
            'DONOVANS INDUSTRIAL PK',
            'MELROSE',
            'SAINT PHILIPS',
            'GEORGETOWN',
            "SAINT JOHN'S",
            "PORTUGAL COVE ST PHILLIP'S",
            'HUMBER ARM SOUTH',
            'BAINE HARBOUR',
            'SHEA HEIGHTS',
            'ST VINCENTS',
            'AGUATHUNA',
            'NIPPERS HARBOUR',
            "HANT'S HARBOUR",
            'HARBOUR ROUND',
            'SAINT LAWRENCE',
            'SOUTH BRANCH',
            'RIVERHEAD ROAD',
            'WEST BAY CENTRE',
            'HAPPY VALLEY',
            'CAPE ST. GEORGE',
            "JACKSON'S ARM",
            'SUBD. L',
            'NOELS POND',
            'BAYTONA',
            'BURNT ISLANDS BLP',
            'HOWLEY',
            'HARBOUR MILLE',
            'FORT AMHERST',
            "BADGER'S QUAY",
            'HIGHLANDS',
            'COMFORT COVE-NEWSTEAD',
            'NEW HARBOUR',
            'BRENTS COVE',
            'ROBINSONS',
            'BRIGHTON',
            'TEMPLEMAN',
            'BARACHOIS BROOK',
            'SEAL COVE WHITE BAY',
            'GLOVERTOWN SOUTH',
            'SOUTHERN HARBOUR PB',
            'MILES COVE',
            'RIVER OF PONDS',
            'SWEET BAY',
            'SHOE COVE',
            'CRESTON NORTH',
            "SAINT FINTAN'S",
            'COLLIERS RIVERHEAD',
            'PORT DE GRAVE',
            'HAPPY VALLEY-GOOSE-BAY',
            'BROAD COVE BDV',
            'LITTLE RAPIDS',
            'VICTORIA',
            'RIVERHEAD HARBOUR GRACE',
            'VICTORIA COVE',
            'COLINET',
            'GAMBO SOUTH',
            "FRENCHMAN'S COVE",
            'RENCONTRE EAST',
            'ST FINTANS',
            'NEWTOWN',
            "ST JOSEPH'S MOUNT CARMEL",
            'JOE BATTS ARM',
            'LAWN',
            'HERMITAGE',
            'COMFORT',
            "KING'S POINT",
            'SAINT ANTHONY',
            'VALLEY-GOOSE BAY',
            'SOUTH RIVER',
            'CAVENDISH',
            'HORWOOD',
            "COTTRELL'S COVE",
            'DONOVANS',
            'PLACENTIA',
            'ELLISTON',
            'JACKSONS COVE',
            'PILLEYS ISLAND',
            'COME BY CHANCE',
            'TOPSAIL RD MOUN',
            'SHALLOWAY COVE',
            "MORETON'S HARBOUR",
            'CHANNEL PORT AUX BASQUES',
            'SPRUCE',
            'STATION RD',
            'GREY RIVER',
            'ST PHILIPS',
            'CONCEPTION HARBOUR',
            'NORTHERN ARM',
            "ST-JOHN'S",
            "PORTUGAL COVE-ST PHILIP'S",
            'BONNE BAY POND',
            'PETERVIEW',
            "COLEY'S POINT S",
            'CRESTON SOUTH',
            'MAIN HWY BAY ROBERTS',
            'MILTON',
            'PORTLAND CREEK',
            'MILLBROOK MALL CORNER BROOK',
            'ST. LAWRENCE',
            'HALIFAX',
            "HEAD BAY D'ESPOIR",
            'ASPEN COVE',
            'BELL ISLAND FRONT',
            'CHARLOTTETOWN LAB',
            'TRINITY BB',
            'OUTER COVE',
            'MEMORIAL DR',
            'FRESHWATER PLACENTIA BAY',
            "BENOIT'S COVE",
            'COTTLESVILLE',
            'POINT LEAMINGTON',
            'BRANCH',
            'TROUT RIVER',
            "ROBERT'S ARM",
            'CONCEPTION BAY',
            'PARSONS POND',
            'LASCIE',
            'REIDVILLE',
            'PARADISE NL',
            'SEAL COVE FB',
            'CHANGE ISLANDS',
            'CRESTON',
            "NEWMAN'S COVE",
            'BLAKETOWN',
            'BAY DE VERDE',
            'GRATES COVE',
            'OPEN HALL',
            'TRINITY TB',
            'MAKINSONS',
            'PLATE COVE EAST',
            'RUSHOON',
            'RAMEA',
            'STEPHENVILLE',
            'BURGEO',
            'HAMPDEN',
            "HAWKE'S BAY",
            'YORK HARBOUR',
            'CENTREVILLE',
            'MOUNT MORIAH',
            'SUBD. O',
            'GRAND LE PIERRE',
            'HUGHES BROOK',
            'ST BERNARDS-JACQUES FONTAINE',
            'TOPSAIL RD',
            "MING'S BIGHT",
            'WHITEWAY',
            'PETTY HARBOUR',
            'EDDIES COVE WEST',
            'OCTAGON POND',
            'WESTERN BAY',
            'BURNSIDE',
            'PORT AUX BASQUES',
            'NORTHERN BAY',
            'NEW CHELSEA',
            'WHITBOURNE',
            'AQUAFORTE',
            'BURGOYNES COVE',
            'ISLINGTON',
            'BUNYANS COVE',
            'GRAND BAY EAST',
            "GREEN'S HARBOUR",
            'CHARLOTTETOWN',
            'MEADOWS',
            'HARE BAY',
            'DOVER',
            'PORTUGAL COVE-ST PHILIPS',
            'LONG HARBOUR',
            'NEWVILLE',
            'STAG HARBOUR',
            'WINGS POINT',
            "ST BERNARD'S",
            'ISLAND HARBOUR',
            'LITTLE BURNT BAY',
            'CALVERT',
            'LUMSDEN',
            'MIDDLE ARM',
            'GANDER BAY',
            'ST BRENDANS',
            'CHURCHILL FALLS',
            'COTTRELLS COVE',
            'POOLS COVE',
            'FLEUR DE LYS',
            'BENTON',
            'TREPASSEY',
            'BAULINE',
            'HILLVIEW',
            'CONCHE',
            'LITTLE BAY ISLANDS',
            'ST JOHNS',
            'ENGLEE',
            'MIDDLE ARM GB',
            'VIRGIN ARM',
            'POUND COVE',
            'MOBILE',
            'WITLESS BAY',
            'FLOWERS COVE',
            'BELLEORAM',
            "PARSON'S POND",
            'PORT HOPE SIMPSON',
            'PORT BLANDFORD',
            'TOWN CENTER',
            'LITTLE BAY EAST',
            'ST DAVIDS',
            'PORT AU PORT',
            'SHIP HARBOUR PB',
            'JEFFREYS',
            'FAIRBANKS',
            "BOYD'S COVE",
            'REGATTA DENTAL CENTRE',
            'INTERNAL MEDECINE',
            'AVONDALE',
            'PASADENA',
            'MASSEY DRIVE',
            'SOUTH DILDO',
            'BEAUMONT',
            'GRAND FALLS',
            'MCGETTIGAN BLVD',
            'SYDNEY',
            'BRIGUS',
            'MIDDLE COVE',
            'NEW GLASGOW',
            'CONCEPTION BAY S',
            'MONKSTOWN',
            'CAPE ST GEORGE',
            'TRINITY BAY',
            'CAMPBELLTON',
            'LETHBRIDGE',
            'BRIGS BAY',
            'BONAVISTA',
            'DANIELS HARBOUR',
            '306 BAY BULLS',
            'SEAL COVE WB',
            'NATUASHISH',
            'ARBOUR BRETON',
            "PIKE'S ARM",
            'CORNER BROOK',
            "CENTRAL ST. JOHN'S",
            'LOURDES',
            'CHANNEL-PORT AUX BASQUES',
            'WINTERLAND',
            'FRANCOIS',
            'BIRD COVE',
            'BURIN',
            "SPANIARD'S BAY",
            "SAINT GEORGE'S",
            'PLUM POINT',
            'PORT-AUX-BASQUES',
            'STONEVILLE',
            'REEFS HARBOUR',
            'LABRADOR CITY',
            'BLAKETOWN WHITBOURNE',
            'NEW-WES-VALLEY',
            'HOPEDALE',
            'CARTERS COVE',
            'GAMBO',
            'SAINT LUNAIRE-GRIQUET',
            'LADLE COVE',
            'ROSE BLANCHE',
            'TOLL FREE',
            'EASTPORT',
            'BENOITS COVE',
            "SAINT ALBAN'S",
            "PORTUGAL COVE-ST. PHILIP'S",
            'SUNNYSIDE',
            'POSTVILLE',
            'LA SCIE',
            'NORRIS POINT',
            'BARTLETTS HARBOUR',
            'CARTYVILLE',
            'RATTLING BROOK',
            'CAPPAHAYDEN',
            'GOOBIES',
            'LITTLE CATALINA',
            'MCLVERS',
            'PARADISE',
            "MARY'S HARBOUR",
            'PORT SAUNDERS',
            "CLARKE'S BEACH",
            'GARNISH',
            'CHANCE COVE',
            'GOULDS',
            'MILLERTOWN',
            'BURNS COVE',
            'LEADING TICKLES',
            'BUCANS JUNCTION',
            'LOON BAY',
            'FLATROCK',
            'BURLINGTON',
            'BRIGUS JUNCTION',
            '',
            'COLEYS POINT SOUTH',
            'SUMMERVILLE',
            'BELLBURNS',
            'VALLEY POND',
            'CITADEL',
            'BOAT HARBOUR WEST',
            "ST. ALBAN'S",
            'ST ANDREWS',
            'FREDERICKTON',
            'HARRYS HARBOUR',
            'VILLE MARIE DR',
            'NORRIS ARM',
            'BOTWOOD',
            'HERRING NECK',
            'BUCHANS',
            "L'ANSE AU LOUP",
            'PLACENCIA',
            'MILTOWN',
            'ST LUNAIRE-GRIQUET',
            'MARYSVALE',
            'RED BAY',
            'COW HEAD',
            'FLAT BAY',
            "ST JOSEPH'S",
            'DILDO',
            'BROOKFIELD',
            'CONNE RIVER',
            'LAMALINE',
            'GLOVERTOWN',
            'GANDER BAY SOUTH',
            "BAY L'ARGENT",
            "ST GEORGE'S",
            'ST LEWIS',
            'TERRENCEVILLE',
            'HARBOUR BRETON',
            'WAREHAM-CENTREVILLE',
            'HARE BAY BONAVISTA BAY',
            'BELLEVUE',
            'PRINCETON',
            "BISHOP'S FALLS",
            'SELDOM',
            'BELL ISLAND',
            'JERSEYSIDE',
            'PICCADILLY',
            'CARTWRIGHT',
            'GOOSEBERRY COVE',
            'TILTING',
            'COXS COVE',
            "SAINT-JOHN'S",
            'HOLYROOD',
            'HARE BAY BB',
            'DEER LAKE',
            'GLENWOOD',
            'CODROY',
            'LIVELY',
            'WOODY POINT',
            'MASSEY DR',
            'BOYDS COVE',
            'GRAND BEACH',
            'ENGLISH HARBOUR EAST',
            'ROTHEMERE ST',
            'CARBONEAR',
            "LITTLE HEART'S EASE",
            'OLD SHOP',
            'PLATE COVE WEST',
            'CLARKES BEACH',
            'CHARLESTON',
            'SALLYS COVE',
            'POLLARDS POINT',
            'BONNE BAY',
            'SWIFT CURRENT',
            'HAPPY-VALLEY-GOOSE-BAY',
            'HICKMANS HARBOUR',
            'SPRINGDALE',
            'ST-ANTHONY',
            'PORTUGAL COVE CONCEPTION BAY',
            'PORT UNION',
            'DURRELL',
            'HANTS HARBOUR',
            'RIGOLET',
            'OLD PERLICAN',
            'NORTH WEST RIVER',
            'ST PAULS',
            'ST GEORGES',
            'GILLAMS',
            'CATALINA',
            "BAY D'ESPOIR",
            'PACQUET',
            'MORRISVILLE',
            'LARK HARBOUR',
            'EPWORTH',
            'EMBREE',
            'DUNVILLE',
            'PORT ANSON',
            'GANDER',
            "PETER'S RIVER",
            'SUBD. D',
            "ST. JOHN'",
            'SALMON COVE',
            'MAKKOVIK',
            'WESTPORT',
            'STEPHENVILLE CROSSING',
            'LA POILE',
            "L'ANSE AU CLAIR",
            'BONIVISTA',
            'WINTERTON',
            'KIPPENS',
            'LAURENCETON',
            'POINT OF BAY',
            'NAIN',
            'ROBERTS ARM',
            'RALEIGH',
            'HODGES COVE',
            'ST MARYS',
            'SAINT JOHNS',
            "COLLIER'S RIVERHEAD",
            'MINGS BIGHT',
            'TILTON',
            'ISLE-AUX-MORTS',
            "SAINT MARY'S",
            'GREENSPOND',
            'MARYSTOWN',
            'WOODSTOCK',
            'HARBOUR GRACE',
            'POUCH COVE',
            'BROWNSDALE',
            'GAULTOIS',
            'BAY BULLS',
            'FOGO ISLAND CENTRAL',
            'SOUTH EAST BIGHT',
            'CUPIDS',
            'BLOOMFIELD',
            'BADGERS QUAY',
            'MCCALLUM',
            'SOUTHERN BAY',
            'CORNER BROOK PLAZA',
            'ST CHADS',
            'FERRYLAND',
            'WOODFORDS',
            'ST ANTHONY',
            'RODDICKTON',
            'SANDRINGHAM',
            'VICTORIA CB',
            "L'ANSE-AU-LOUP",
            'MAIN BROOK',
            'CORMACK',
            'GREENS HARBOUR',
            'STEADY BROOK',
            'FRESHWATER PB',
            'MUSGRAVE HARBOUR',
            'MONT PEARL',
            'RENEWS',
            'DOYLES',
            'UPPER ISLAND COVE',
            'ST JOHN',
            'SHIP COVE',
            "PADDY'S POND",
            'PORT BLANFORD',
            'ST LAWRENCE',
            'HAPPY VALLEY GOOSE BAY',
            'LOGY BAY',
            'GRANDFALLS-WINDSOR',
            'LONG POND',
            'INDIAN BAY BB',
            'ROCKY HARBOUR',
            'MOUNT PEARL',
            'NORRIS PT',
            'BAIE VERTE',
            'SUGARLOAF',
            'MILLTOWN',
            'HEARTS CONTENT',
            'CAPE BROYLE',
            'NEWMANS COVE',
            'FRENCHMANS COVE BOI',
            'CHANNEL-PORT-AUX-BASQUES',
            'BIRCHY HEAD',
            'BLACK DUCK COVE',
            "ELLIOTT'S COVE",
            'TRITON',
            'LEWISPORT',
            'BAY ROBERTS MALL NFLD CALL NO CHARGE',
            'ARNOLDS COVE',
            "HEART'S CONTENT",
            'TORBAY',
            'HAWKES BAY',
            'BINDON PLACE',
            "ST MARY'S",
            'CENTENNIAL SQ',
            'FOGO',
            'ST BRIDES',
            'OCHRE PIT COVE',
            "ST. GEORGE'S",
            "PILLEY'S ISLAND",
            'WESLEYVILLE',
            'NORMANS COVE',
            'WABUSH',
            'KILBRIDE',
            'MANUELS',
            'PORT REXTON',
            'WILLIAMS HARBOUR',
            'KELLIGREWS',
            'MOUNT CARMEL',
            'CLARENVILLE',
            'BADGER',
            'RIVERHEAD',
            'GALLANTS',
            'TWILLINGATE',
            "FLOWER'S COVE",
            'FORTEAU',
            'WINTERLAN',
            "ST PAUL'S",
            'BISHOPS FALLS',
            'HARBOUR MAIN-CHAPEL COVE-LAKEVIEW',
            'CHAPEL ARM',
            'TRINITY',
            'SOPS ARM',
            'MUSGRAVETOWN',
            'TRAYTOWN',
            'MARYS HARBOUR',
            'NEW HARBOUR TB',
            'PLEASANTVILLE',
            "SAINT ANDREW'S",
            'BAY ROBERTS',
            'BLACK TICKLE',
            'ST. ANTHONY',
            'BIRCHY BAY',
            'FERMEUSE',
            'CHAMBERLAINS',
            'IRISHTOWN-SUMMERSIDE',
            'SANDY COVE',
            'GARDEN COVE PB',
            "JOE BATT'S ARM",
            "MCKAY'S",
            'CARMANVILLE',
            'PORTUGAL COVE-ST PHILPS',
            'HARBOUR MAIN',
            'SUBD. E',
            'ST ALBANS',
            'SUMMERFORD',
            "ST JOHN'S",
            'LOWER ISLAND COVE',
            'LEWISPORTE',
            'GRND-FALLS-WI',
            'GOOSE BAY',
            "GLOVER'S HARBOUR",
            "ST ANDREW'S",
            "ST. JOHN'S",
            "COLEY'S POINT SOUTH",
            'TORS COVE',
            'SPANIARDS BAY',
            'PORT AU CHOIX',
            'HR G',
            'GRAND FALLS WINDSOR',
            'NEW HARBOUR TRINITY BAY',
            'MONROE',
            'NORTH RIVER',
            'MOUNT ARLINGTON HEIGHTS',
            'KINGS POINT',
            "ST JOHN'S TOLL FREE",
            "ARNOLD'S COVE",
            'HAPPY VALLEY-GOOSE BAY',
            'CONCEPTION BAY SOUTH',
            'APPLETON',
            "HEART'S DELIGHT",
            'GRAND FALLS-WINDSOR',
            'GRANDFALLS',
            'BURIN BAY ARM',
            'HOPEALL',
            'NORTH HARBOUR PB',
            'MT PEARL',
            'HEATHERTON',
            'ENGLISH HARBOUR WEST',
            'SHEARSTOWN',
            'FORTUNE',
            'SOUTH BROOK GB',
            'GRAND BANK',
        ],
    },

    /* 11 */
    {
        _id: 'NT',
        cities: [
            'NAHANNI BUTTE',
            'YELLWKNIFE',
            'GAMETI',
            '',
            'WHA TI',
            'BEHCHOKO',
            'TSIIGEHTCHIC',
            'FORT PROVIDENCE',
            'ULUKHAKTOK',
            'WEKWETI',
            'AKLAVIK',
            "LUTSELK'E",
            'TULITA',
            'FORT RESOLUTION',
            'SACHS HARBOUR',
            'WRIGLEY',
            'TUKTOYAKTUK',
            'DELINE',
            'TROUT LAKE',
            'NORMAN WELLS',
            'FORT GOOD HOPE',
            'FORT SMITH',
            'COLVILLE LAKE',
            'INVUIK',
            'WHATI',
            'FORT MCPHERSON',
            'PAULATUK',
            'FORT LIARD',
            'YELLOWKNIFE',
            'ST CONSTANT',
            'HAY RIVER',
            'ENTERPRISE',
            'INUVIK',
            'FORT SIMPSON',
        ],
    },

    /* 12 */
    {
        _id: 'AB',
        cities: [
            'PAKAN',
            'CLEARWATER COUNTY',
            'CARDSTON COUNTY',
            'RIVIERE QUI BARRE',
            'WHEATLAND COUNTY',
            'VERMILION RIVER COUNTY NO. 24',
            'CAMROSE COUNTY NO. 22',
            'ELK ISLAND NATIONAL PARK',
            'WINFIELD',
            'PINCHER CREEK NO. 9',
            'WHITELAW',
            '213 472 TWP',
            'BALZAC',
            'LAVOY',
            'WHITE SANDS',
            'ST. ALBERT',
            'KANANASKIS VILLAGE',
            'PARK LANE',
            'MEANDER RIVER',
            'EDMONTONTON',
            'INNISFAILL',
            'COUTTS',
            'CAGLARY',
            'CANADA',
            'HILLS',
            'NAMAO',
            'VALLEY',
            'MORNINVILLE',
            "DEAD MAN'S FLATS",
            'EDMOTON',
            'VILLENEUVE',
            'HERITAGE POINTE',
            'KINGMERE COVE SE',
            'SYLVAN',
            'MUNICIPAL DISTRICT OF TABER',
            'CAMROSE COUNTY',
            'IMPROVEMENT DISTRICT NO. 9',
            'BROWNFIELD',
            'BURRIS POINTE',
            'BARHEAD',
            'PEACE RIVER',
            'BASHAWA',
            'HERITAGE POINT',
            'BYEMOOR',
            'BOWNESS',
            'ROCKYWIEW',
            'WEASKIWIN',
            'VEGA',
            'WAINWRIGHT ALBERTA',
            'PARADISE VALLEY',
            'WETASKWIN',
            'RIVERSIDE',
            'SUMMERSIDE',
            'INGLEWOOD',
            'NORTHWEST CALGARY',
            'KIKINO',
            'BRAESIDE',
            'WESTLOCK COUNTY',
            'VIRGINIA PARK',
            '.',
            'NORTH COOKING LAKE',
            'SOUTHWEST EDMONTON',
            'NORTH EDMONTON',
            'WEST EDMONTON',
            'FOREST HEIGHTS',
            'SUDER GREENS',
            'LETBRIDGE',
            'SOUTHEAST EDMONTON',
            'LACOMBE PARK',
            'KIRRIEMUIR',
            'RIDEAU PARK',
            'CENTRAL EDMONTON',
            'ACHESN',
            'SADDLERIDGE',
            'DICKSON',
            'SPRINGBANK',
            'HAIRY HILL',
            'FORT MURRAY',
            'EDMONDTON',
            'STARTHMORE',
            'MEDECINE HAT',
            'ALLIANCE',
            'DERWENT',
            'RED EARTH',
            'CANYON CREEK',
            'MCCAULEY',
            'PARKLAND COUNTY',
            'HONDO',
            'FT SASKATCHEWAN',
            'AIRPORT DR. GRANDE PRAIRIE',
            'FOOTHILLS NO. 31',
            'MUNSON',
            'LAKE ISLE',
            'RED CLIFF',
            'REDTAIL',
            'CAROLINA',
            'MCLAUGHLIN',
            'HYLO',
            'CONDOR',
            'STATHMORE',
            'BIRCHCLIFF',
            'FAUST',
            'ISLAND LAKE',
            'SANDY LAKE',
            'AUBURN BAY AVE SE',
            'LNDL',
            'CALMAR',
            'FABYAN',
            'MCLENNAN',
            'ALBANY',
            'WILLINGTON',
            'GUNN',
            'VALHALLA CENTRE',
            'MD OF BONNYVILLE',
            'GRAN PRAIRIE',
            'BARRHEAD COUNTY',
            'STONY PLAIN',
            'BLACKFOOT',
            'NORTHWEST EDMONTON',
            'COUNTY OF BARRHEAD',
            'KATHYRN',
            'HARMATTAN',
            'NEW NORWAY',
            'ST BRIDES',
            'KEOMA',
            'COLD LAKE SOUTH',
            'BLOOMSBURY',
            'CADOGAN',
            'WHISPERING HILLS',
            'DEMMITT',
            'JOFFRE',
            'BEDARD EXCAVATING',
            'GRAND CENTRE',
            'STAUFFER',
            'ASHMONT',
            'IRVINE',
            'WASKATENEAU',
            'ST.ALBERT',
            'CALEY',
            'BLACKFALLS',
            'LACOMBE COUNTY',
            'LINDBERGH',
            'LAC STE ANNE COUNTY',
            'JAMES RIVER BRIDGE',
            'REDCLIFF',
            'NORGLENWOLD',
            'LOUSANA',
            'HAYTER',
            'MEETING CREEK',
            'WILD HORSE',
            'PEERLESS LAKE',
            'CLARESHOLM',
            'ALBERT',
            'GUY',
            'AB -',
            'ST MICHAEL',
            'GOODRIDGE',
            'ARROWWOOD',
            'SADDLE LAKE',
            'ST VINCENT',
            'BROSSEAU',
            'IRON SPRINGS',
            'LLOYDMISNETR',
            'COUNTY OF GRANDE PRAIRIE',
            'LLOYDMINISTER',
            'PERRYVALE',
            'HINTON',
            'BAY TREE',
            'CADOMIN',
            'NEERLANDIA',
            'CHISHOLM MILLS',
            'CARSTON',
            'GRANDE-PRAIRIE',
            'GRAND PRAIRE',
            'FT. SASKATCHEWAN',
            'ELLSCOTT',
            'VERMILLION',
            'MULHURST',
            'SPRING COULEE',
            'IRRICANA',
            'SHERWOOD',
            'CHEADLE',
            'ORION',
            'EDMONTON SOUTH',
            'BRETON',
            'GULL LAKE',
            'SPEDDEN',
            'WASKATENAU',
            'MOUNTAIN VIEW',
            'HILL SPRING',
            'YELLOWHEAD COUNTY',
            'FORT MACKAY',
            'STANFORD',
            'COALHURST',
            'DEL BONITA',
            'BRUCE',
            'ROCKY MOUNTAIN HOUSE',
            'VEINERVILLE',
            'EDGEMONT',
            'MARLBORO',
            'THORNHILD',
            'CRANBROOK',
            'LEDUC COUNTY',
            'NEW DAYTON',
            'SOUTHEAST CALGARY',
            'DIAMOND CITY',
            'SLACE LAKE',
            'AND AREA',
            'ROCKY VIEW NO. 44',
            'WOOD BUFFALO',
            'ROSEDALE',
            'AUBURN BAY',
            'CHARD',
            'WATERTON',
            'AB',
            'DENWOOD',
            'SAINT ALBERT',
            'BUCK LAKE',
            'COOKING LAKE',
            'SPRING LAKE',
            'LEGAL',
            'ROSEDALE VALLEY',
            'SMOKY LAKE',
            'LNDN',
            'HUXLEY',
            'MOSSLEIGH',
            'BRANT',
            'HEINSBURG',
            'LACRETE',
            'ALBERTA',
            'ST-PAUL',
            'CHAMPION',
            'BUFFALO HEAD PRAIRIE',
            'BARNWELL',
            'CESSFORD',
            'EDSON',
            'SOUTH FOOTHILLS',
            'WABASCA-DESMARAIS',
            'MILLICENT',
            'SEDALIA',
            'CARBON',
            'IDDESLEIGH',
            'CHATEH',
            'BUCK CREEK',
            'DEADWOOD',
            'BON ACCORD',
            'WOKING',
            'DONNELLY',
            'KEG RIVER',
            'CADOTTE LAKE',
            '11473 97 AVE GRANDE PRAIRIE',
            'SPRUCE GROVE',
            'ENILDA',
            'TAWATINAW',
            'MARWAYNE',
            'VERGREVILLE',
            'BONNYVILLE',
            'ABERDEEN',
            'DRIFTPILE',
            'DEBOLT',
            'GROUARD',
            'LONE PINE',
            'BASHAW',
            'CHERHILL',
            'HIGH PRAIRIE',
            'TOMAHAWK',
            'ROBB',
            'BRULE',
            'ACME',
            'HILDA',
            'FALUN',
            'CLEARDALE',
            'GOODFARE',
            'SUNNYBROOK',
            'RANFURLY',
            'METISKOW',
            'SMITH',
            'KINGMAN',
            'LYALTA',
            'IRON RIVER',
            'BVR LK',
            'DELACOUR',
            'ATMORE',
            'GOODFISH LAKE',
            'LAC LA BICHE COUNTY',
            'TROUT LAKE',
            'CASLAN',
            'STAVELY',
            'SAINT PAUL',
            'WIDEWATER',
            'KEHEWIN',
            'ST. PAUL',
            'PLAMONDON',
            'MALLAIG',
            'NEWBROOK',
            'LA COREY',
            'RIVERCOURSE',
            'GRASSLAND',
            'CHERRY GROVE',
            'KINSELLA',
            'GLENDON',
            'INNISFREE',
            'SAINT-ALBERT',
            'HARVIE HEIGHTS',
            'FERINTOSH',
            'LAMONT COUNTY',
            'NEW SAREPTA',
            'GRANDE PRAIRIE COUNTY',
            'FT VERMILION',
            'STROME',
            'MIRROR',
            'ISLAY',
            'CLYDE',
            'ALBERTA (AB)',
            'EDBERG',
            'LETHBRIDGE',
            'CLANDONALD',
            'FROG LAKE',
            'RED WILLOW',
            'RYLEY',
            'KITSCOTY',
            'ST LINA',
            'MILLARVILLE',
            'TEES',
            'EDGERTON',
            'LAC ST. ANNE COUNTY',
            'FOISY',
            'GRASSY LAKE',
            'BELLEVUE',
            'NISKU',
            'WILLINGDON',
            'NEVIS',
            'BROOKS',
            'BIG VALLEY',
            'BODO',
            'EGREMONT',
            'IRMA',
            'BONANZA',
            'WESTEROSE',
            'ATHABASCA',
            'PENHOLD',
            'BLUFFTON',
            'HOBBEMA',
            'SUNDRIE',
            'TORRINGTON',
            'ALTARIO',
            'DEWBERRY',
            'NEW BRIGDEN',
            'NOBLEFORD',
            'GWYNNE',
            'GLEICHEN',
            'TORONTO',
            'JASPER',
            'ROLLY VIEW',
            'MASKWACIS',
            'STREAMSTOWN',
            'PICKARDVILLE',
            'FAWCETT',
            'SIKSIKA',
            'WARSPRITE',
            'WEST BAPTISTE',
            'GLENEVIS',
            'CYNTHIA',
            'HILLCREST MINES',
            'DAPP',
            'ROCKY RAPIDS',
            'NITON JUNCTION',
            'THORHILD',
            'JARVIE',
            'SMOKY LANE',
            'AIRDRIE',
            'DONALDA',
            'RICH VALLEY',
            'SWAN HLLS',
            'BENTLEY',
            'KELSEY',
            'WABAMUN',
            'LUNDBRECK',
            'WIMBORNE',
            'HEISLER',
            'ROCKY MTN HOUSE',
            'WILDWOOD',
            'EAGLESHAM',
            'BLACK DIAMOND',
            'RAINIER',
            'TANGENT',
            'WANDERING RIVER',
            'FORT CHIPEWYAN',
            'LAC LA BICHE',
            'HALKIRK',
            'LACOMBE',
            'CANMORE',
            'FORT KENT',
            'ISLAND LAKE SOUTH',
            'DUFFIELD',
            'POLLOCKVILLE',
            'PATRICIA',
            'ROSEBUD',
            'YOUNGSTOWN',
            'BLACKIE',
            'GROVEDALE',
            'WAINWRIGHT',
            'WARBURG',
            'GIROUXVILLE',
            'CAMROSE',
            'CAYLEY',
            'COMPEER',
            'FT MCMURRAY',
            'OHATON',
            'HUSSAR',
            'LITTLE SMOKY',
            'ZAMA CITY',
            'BELLIS',
            'WATERTON PARK',
            'GIBBONS',
            'WATER VALLEY',
            'HYTHE',
            'TURIN',
            'MOUNTAIN VIEW COUNTY',
            'ARDMORE',
            'DELIA',
            'FRANK',
            'ENDIANG',
            'BINDLOSS',
            'JENNER',
            'MACKENZIE NO. 23',
            'BEAUMONT',
            'SPUTINOW',
            'BERWYN',
            'SEBA BEACH',
            'RADWAY',
            'ROLLING HILLS',
            'CLIVE',
            'EDMONTON & ST PAUL',
            'WALSH',
            'ETZIKOM',
            'WRENTHAM',
            'CROWSNEST PASS',
            'LOMOND',
            'SYLVAIN LAKE',
            'BARONS',
            'CARSTAIRS/CALGARY',
            'BUFFALO',
            'LAC DES ARCS',
            'ST. ALBERTA',
            'DUNMORE',
            'GRANUM',
            'ED MONTON',
            'SCHULER',
            'TWO HILLS',
            'FALLIS',
            'KANANASKIS',
            'NORDEGG',
            'OLDS',
            'PARKLAND',
            'MIDNAPORE',
            'GORDONDALE',
            'BOWDEN',
            'FOX LAKE',
            'FOOTHILLS',
            'LA GLACE',
            'CRANE LAKE WEST',
            'ROCKY VIEW',
            'MEDICINE HAT',
            "TSUU T'INA",
            'WHITECOURT',
            'JASPER EAST',
            'STAR',
            'LINDEN',
            'FLATBUSH',
            'PEERS',
            'LETHBRIDGE COUNTY',
            'BRAGG CREEK',
            'STURGEON COUNTY',
            'CAIRSTAIRS',
            'GEM',
            'SYLVAN LAKE',
            'TROCHU',
            'COLINTON',
            'CARSELAND',
            'FT MACLEOD',
            'CLAIRMONT',
            'RALSTON',
            'REDWATER',
            'FORT VERMILION',
            'ROSEDALE STATION',
            'BAWLF',
            'CEREAL',
            'THREE HILLS',
            'CARNWOOD',
            'BOYLE',
            'MUNDARE',
            'DUCHESS',
            'SEXSMITH',
            'CONSORT',
            'MELENNAN',
            'FONDA DR SE',
            'CHAUVIN',
            'LESLIEVILLE',
            'ROCKY VIEW COUNTY',
            'BEISEKER',
            'SOUTHWEST CALGARY',
            'RED DEER COUNTY',
            'CARROT CREEK',
            'MARIE REINE',
            'SEDGEWICK',
            'HAY LAKES',
            'GALAHAD',
            'CANADA AREA',
            'JENNIFER@FULLCIRCLEHYPNOSIS.COM',
            'MILLET',
            'WABASCA',
            'VETERAN',
            'BENALTO',
            'STIRLING',
            'TOFIELD',
            'MADDEN',
            'MILK RIVER',
            'SWAN HILLS',
            'PINE LAKE',
            'MUSIDORA',
            'DESERT BLUME',
            'CALL NO CHARGE',
            'CHESTERMERE',
            'RUMSEY',
            'GRANDE PRAIRIE',
            'GRIMSHAW',
            'ROSEMARY',
            'RIMBEY',
            'CASTOR',
            'FOREMOST',
            'CZAR',
            'LEDUC',
            'BROCKET',
            'HIGH LEVEL',
            'CROOKED CREEK',
            'SUNNYNOOK',
            'CORONATION',
            'ST PAUL',
            'HAYS',
            'TURNER VALLEY',
            'CALAHOO',
            'DARWELL',
            'PICTURE BUTTE',
            'GRANDE PRAIRE',
            'HIGH RIVER',
            'DELBURNE',
            'EDMONTON',
            'CLUNY',
            'TILLEY',
            'CALGARY',
            'MANNING',
            'ROCKYFORD',
            'CALAIS',
            'CARVEL',
            'FT CHIPEWYAN',
            'RED DEER',
            'VAUXHALL',
            'MORRIN',
            'STANDARD',
            'ROUND HILL',
            'MAGRATH',
            'GRANDE CACHE',
            'LONGVIEW',
            'SEVEN PERSONS',
            'CARDSTON',
            'HILLIARD',
            'INDUS',
            'COLDLAKE',
            'NANTON',
            'BURMIS',
            'JEAN COTE',
            'NESTOW',
            'LLOYDMINSTER',
            'CYPRESS COUNTY',
            'ONLINE SALES ONLY',
            'COWLEY',
            'ALIX',
            'LANGDON',
            'STRATHCONA COUNTY',
            'LINDALE',
            'SANGUDO',
            'STRATHMORE',
            'RIBSTONE (MAILING: CHAUVIN)',
            'CREMONA',
            'ONOWAY',
            'DAYSLAND',
            'JOUSSARD',
            'COALDALE',
            'EVANSBURG',
            'BEAUMMONT',
            'RAYMOND',
            'BASSANO',
            'STRATHCONA CITY',
            'BEZANSON',
            'SLAVE LAKE',
            'DE BOLT',
            'RED EARTH CREEK',
            'VERMILION',
            'CONKLIN',
            'SHAUGHNESSY',
            'REDDEER',
            'CARMANGAY',
            'VIMY',
            'ALDERSYDE',
            'FORT ASSINIBOINE',
            'BURDETT',
            'BEAVER COUNTY',
            'BARRHEAD',
            'WHITE GULL',
            'SWALWELL',
            'COUNTY OF GRANDE PRAIRIE NO 1',
            'DEWINTON',
            'MORLEY',
            'HARDISTY',
            'EMPRESS',
            'BLACKFALDS',
            'ALBERTA BEACH',
            'PONOKA COUNTY',
            'ST ISIDORE',
            'VULCAN',
            'TABER',
            'CAROLINE',
            'BEAUVALLON',
            'SUNDRE',
            'COLEMAN',
            'BLUE RIDGE',
            'HOLDEN',
            'WESTLOCK',
            'SPRUCE VIEW',
            'VIKING',
            'SOUTH BAPTISTE',
            'GADSBY',
            'SANDY BEACH',
            'FORT SASKATCHEWAN',
            'FAIRVIEW',
            'MYRNAM',
            'SILVER VALLEY',
            'LOUGHEED',
            'MANNVILLE',
            'BEAVERLODGE',
            'FALHER',
            'WESTHILLS TOWNE CENTRE SW',
            'ECKVILLE',
            'BRUDERHEIM',
            'HARDISTRY',
            'SCANDIA',
            'MONARCH',
            'MINBURN',
            'ST-ALBERT',
            'ZAMA',
            'RAINBOW LAKE',
            'LODGEPOLE',
            'ALSIKE',
            'ELK POINT',
            'ALDER FLATS',
            'STETTLER',
            'KILLAM',
            'CRAIGMYLE',
            'CHINOOK',
            'MILO',
            'PADDLE PRAIRIE',
            'COCHRANE',
            'LAMONT',
            'BANFF - LAKE LOUISE',
            'BROWNVALE',
            'OKOTOKS',
            'ENTWISTLE',
            'WELLING',
            'EDMONTON INTERNATIONAL AIRPORT',
            'LA CRETE',
            'SHERWOOD PARK',
            'STAND OFF',
            'SPIRIT RIVER',
            'ELNORA',
            'BLAIRMORE',
            'WHITE',
            'PROVOST',
            'BOW ISLAND',
            'COLD LAKE',
            'GIFT LAKE',
            'ERSKINE',
            'MORINVILLE',
            'OYEN',
            'KINUSO',
            'EAST COULEE',
            'HUGHENDEN',
            'HOTCHKISS',
            'ENCHANT',
            'MEDCINE HAT',
            'FORESTBURG',
            'GREEN COURT',
            'HINES CREEK',
            'DALEMEAD',
            'DRUMHELLER',
            'WARNER',
            'AMISK',
            'ELKWATER',
            'ACHESON',
            'BUSBY',
            'BOTHA',
            'DIXONVILLE',
            'ROCKYVIEW COUNTY',
            'ENOCH',
            'HANNA',
            'PRIDDIS',
            'ALCOMDALE',
            'CROSSFIELD',
            'ATIKAMEG',
            'DE WINTON',
            'DIDSBURY',
            'CHIPMAN',
            'FORT MACLEOD',
            'LAKE LOUISE',
            'ROCKYVIEW',
            'EXSHAW',
            'WARDLOW',
            'BREYNAT',
            '',
            'WEMBLEY',
            'BEAR CANYON',
            'ALHAMBRA',
            'BITTERN LAKE',
            'ANZAC',
            'CARRDSTON',
            'DEVON',
            'MARKERVILLE',
            'INNISFAIL',
            'GRAND PRAIRIE',
            'VALLEYVIEW',
            'LONE PINE CREEK PLANT CARSTAIRS',
            'LAKE NEWELL RESORT',
            'ARDROSSAN',
            'REDWOOD MEADOWS',
            'RYCROFT',
            'VILNA',
            'BAYTREE',
            'ABEE',
            'FOX CREEK',
            'DRAYTON VALLEY',
            'GLENWOOD',
            'PINCHER CREEK',
            'PONOKA',
            'THORSBY',
            'ROCHESTER',
            'MULHURST BAY',
            'ACADIA VALLEY',
            'OPAL',
            'ATHBASCA',
            'BANFF',
            'MA-ME-O BEACH',
            'XXXXXXXXXXXXX',
            'BONVISTA',
            'NAMPA',
            'MAYERTHORPE',
            'COUNTY OF GRANDE PRAIRIE NO. 1',
            'ROSALIND',
            'ROCKY MOUNTAIN',
            'CALLING LAKE',
            'GUNDY',
            'WORSLEY',
            'OLD STRATHCONA',
            'NORTHEAST CALGARY',
            'SPRINGBROOK',
            'CARSTAIRS',
            'DOWNTOWN EDMONTON',
            'CAMP CREEK',
            'ANDREW',
            'CHERRY POINT',
            'ARMENA',
            'WANHAM',
            'ST ALBERT',
            'FORT MCMURRAY',
            'VEGREVILLE',
            'AETNA',
            'MANYBERRIES',
            'MAGNOLIA',
            'LANCASTER PARK',
            'WETASKIWIN',
        ],
    },

    /* 13 */
    {
        _id: 'BC',
        cities: [
            'DOWNTOWN VANCOUVER',
            'KINGSWAY VANCOUVER',
            'DUBLIN STREET',
            'EDMONTON',
            'METRO-VANCOUVER',
            'MORGAN HEIGHTS',
            'COQUITALM',
            'B.C.',
            'NANIAMO',
            'BRITISH COLUMIA',
            'B',
            'BURNABY BC',
            'BRITISH COLOMBIA',
            'CANADA',
            'B.C CANADA',
            'VANCOVUER',
            'SALT SPRING ISLD',
            'BC ( BRITISH COLUMBIA )',
            'OKANAGAN-SIMILKAMEEN A',
            'COMOX-STRATHCONA J',
            'KITIMAT-STIKINE C (PART 1)',
            'SQUAMISH-LILLOOET B',
            'GREATER VANCOUVER',
            'THOMPSON-NICOLA M',
            'SUBD. D',
            'CENTRAL KOOTENAY E',
            'GREATER VANCOUVER A',
            'DARFIELD',
            'CARIBOO E',
            'VERON',
            'PEMBERTON',
            'TULAMEEN',
            'THOMPSON-NICOLA L',
            'WWW.FLEMINGCREATIVE.COM',
            'SQUAMISH-LILLOOET A',
            'STIKINE REGION',
            'COLUMBIA-SHUSWAP F',
            'HARRISON HOT SPRINGS',
            'THOMPSON-NICOLA O',
            'EAST KOOTENAY E',
            'KISPIOX',
            'COMOX-STRATHCONA D',
            'SUBD. A',
            'COMOX',
            'EAST KOOTENAY F',
            'THRUMS',
            'CENTRAL KOOTENAY A',
            'MESACHIE LAKE',
            'COWICHAN VALLEY E',
            'KOOTENAY BOUNDARY C',
            'CARIBOO D',
            'SUNSHINE COAST B',
            'PORT ALBERNI',
            'SUNSHINE COAST A',
            'TORONTO',
            'THOMPSON-NICOLA A',
            'FORT SAINT JAMES',
            'THOMPSON-NICOLA E',
            'K.L.O.',
            'PEACE RIVER B',
            'KELOWNA FARMERS MARKET',
            'ABBOSTFORD',
            'BC -',
            '4196 WEST 4TH AVE.',
            'ABBOSTSFORD',
            'CHUTE LK',
            'FLATROCK',
            'CAPILANO',
            'OYAMA',
            'VANCOUVER BC',
            'FT ST JAMES',
            'SECHEL',
            'MALAKWA',
            'LANGLEY-SURREY',
            'CENTRAL MAPLE RIDGE',
            'BUICK',
            'DUNDAS',
            'LOGAN LAKE',
            'OLALLA',
            'SULPHUROUS LAKE',
            'VANCOUVER WESTSIDE',
            'WEST BANK',
            'PT COQ',
            'PARKSVILLE',
            'PETAWAWA',
            'PEACE RIVER E',
            'GALAGHR LK',
            'DONALD',
            'KENT',
            'BRIDGE LAKE',
            'JACKFISH LAKE',
            'JOHNSONS LANDING',
            'NORTH NANAIMO',
            'MASSETT',
            'NANIMO',
            'BRITISH COLUMBIA',
            'FORT ST.JOHN',
            'GALIANO ISLAND',
            'NANAIMO A',
            'SPUZZUM',
            'SAINT IVES',
            'SILVERTON',
            'PEACE RIVER REGIONAL DISTRICT',
            'C SAAN',
            'ONE HUNDRED MILE HOUSE',
            'VERNON',
            'PORT ALICE',
            'ATHALMER',
            'LAD',
            'MT CUR',
            'YOUBOU',
            'HARRISON HOT SPRING',
            'HORNBY ISLD',
            'PENICTON',
            'WINFLD',
            'HUDSON HOPE',
            'LANGFD',
            'PROCTOR',
            'CASTELGAR',
            'N PENDER',
            'MORICETOWN',
            'KOOTENAYS',
            'GIBSON',
            'AGASZ',
            'WARFIELD',
            'BAMFIELD',
            'BUNABY',
            'MEADOWBROOK',
            'BIRKEN',
            'ANNACIS ISLD',
            'ERNGTON',
            'BAYBES LAKE',
            'KIMBERLY',
            'APEX MOUNTAIN RESORT',
            'BRIDAL FALLS',
            'SIRDAR',
            'MAYNE ISLD',
            'COALMONT',
            'HEFFLEY-CREEK',
            'ATLIN',
            'SATURNA ISLAND',
            'DAWSONS LANDING',
            'ANAHIM-NIMPO LAKE',
            'BUCKINGHORSE RIVER',
            'BIG WHITE MOUNTAIN',
            'ALERT BAY',
            'KUSKANOOK',
            'TEXADA ISLAND',
            'N PENDER ISLAND',
            'SOINTULA',
            'SUMMERLAND BC',
            'FRASER VALLEY',
            'FT.NELSON',
            'DEWDNEY',
            'QUALICUM BEACH',
            'FAIRVIEW',
            'TOAD RIVER',
            'CORNWALL',
            'VANCOUVER INTERNATIONAL AIRPORT',
            'CLOVERDALE',
            'RICHMOND CENTRE RMD',
            'FT. NELSON',
            'SHWANIGAN LAKE',
            'SHIRLEY',
            'APPLEDALE',
            'NITINAT LAKE',
            'PT ALBERNI',
            'PARKSVILLE-QUALICU',
            'RMD',
            'CORTES ISLD',
            'MILNES LANDING',
            'WHIT ROCK',
            'ABB',
            'STONEY CREEK',
            'WHITEROCK',
            'COQ',
            'MINSTREL ISLAND',
            'LASQUETI',
            'SQUIRREL COVE',
            'ASHCROFT',
            'NEMAIAH VALLEY',
            'FAIRMONT',
            'MONTE CREEK',
            'THOMPSON-NICOLA P',
            'PAVILION',
            'WILDW',
            'GROUNDBIRCH',
            'CRESCENT SPUR',
            'SUNSET PRAIRIE',
            'UPPER FRASER',
            'SECHELT',
            'SURGE NARROWS',
            'TAKLA LANDING',
            'PENNY',
            'BLUBBER BAY',
            'KITAMAAT VILLAGE',
            'GITWINKSIHLKW',
            'CLEARBROOK',
            'LAKE COWICHAN',
            'NORTH NELSON',
            'ROBERTS CREEK',
            '- QUESNEL',
            'SPILLIMACHEEN',
            'SOUTH SURREY',
            'WINNIPEG',
            'SANCA',
            'BEASLEY',
            'DESTINY BAY',
            "HUDSON'S HOPE",
            'NEWTON',
            'WELLINGTON',
            'GALLAGHER LAKE',
            'MT ROBSON',
            'SAAN',
            'GANGES',
            'HARTLEY BAY',
            'N PENDER ISLD',
            'GENELLE',
            'GALLOWAY',
            'KITCHENER',
            '.',
            'CARIBOO',
            'THETIS ISLD',
            'ELKO',
            'HAR H S',
            'PACHENA',
            'STUIE',
            'SKIDGT',
            'OAK BAY',
            'SUNSHINE COAST',
            'NIAGARA FALLS',
            'KINGSGATE',
            'TUPPER',
            'GOOD HOPE LAKE',
            'RADIUM',
            'FT. ST. JAMES',
            'REDSTONE',
            'PRESPATOU',
            'SAVARY ISLD',
            'GOWGANDA',
            'ESQMLT',
            'WILLOW RIVER',
            'SWANSEA POINT',
            'HYDER',
            'BALDONNEL',
            'MOUNT WADDINGTON D',
            'MAGNA BAY',
            'TWIN LAKE',
            'OLIV',
            "VAL-D'OR",
            'SRY',
            'MCBRIDE',
            'NORTH OKANAGAN',
            'PARKS',
            'VICORIA',
            'DOG CREEK',
            'KILDONAN',
            'WESTHOLME',
            'KEREMEOS',
            'BRITISH COLUMBIA(BC)',
            'BBY',
            'SID',
            'GAMBIER ISLAND',
            'CENTRAL OKANAGAN',
            'SETON PORTAGE',
            'SUBD. B',
            'BEAVER FALLS',
            'NORTH OKANAGAN F',
            'QUATSINO',
            'WHALETOWN',
            'STRATHCONA',
            'CALGARY',
            'COAL HARBOUR',
            'WOSS LAKE',
            'BRILLIANT',
            'MUNCHO LAKE',
            'FORT SAINT JOHN',
            'LASQUETI ISLAND',
            'SILVER STAR MOUNTAIN',
            'MAYNE',
            'LADYSMITH',
            'TLELL',
            'MANNING PARK',
            'SIMOOM SOUND',
            'QUADRA ISLD',
            'CENTRAL SAANICH',
            'QUADRA ISLAND',
            'BALFOUR',
            'WYNNDELL',
            'WILSON CK',
            'SECH',
            'ROYSTON',
            'CHILANKO FORKS',
            'BRENTWOOD BAY',
            '10 MILE HOUSE',
            'SUBD. C',
            'CANIM LAKE',
            'ONE HUNDRED FIFTY MILE HOUSE',
            'KITSEGUEKLA',
            'TSAY KEH DENE',
            'BEAR LAKE',
            'CAPITAL',
            'OK F',
            'CRESCENT VALLEY',
            'EDGEWOOD',
            'KLO RD',
            'SCOTCH CREEK',
            'MCLURE',
            'BOWEN ISLD',
            'CELISTA',
            'BIRCH ISLAND',
            'WESTWOLD',
            'CANOE',
            'GOODLOW',
            'PROGRESS',
            'TSAWWASEN',
            'JADE CITY',
            'SKEENA - QUEEN CHARLOTTE REGIONAL DISTRI',
            'CECIL LAKE',
            'MILL BAY',
            'ARROW CREEK',
            'BERYL PRAIRIE',
            'SARDIS',
            'CENTRAL KOOTENAY',
            'STAVE FALLS',
            'HERIOT BAY',
            'TA TA CREEK',
            'BOSTON BAR',
            'EAST KOOTENAY',
            'FAUQUIER',
            'KINGCOME INLET',
            'WINTER HARBOUR',
            'POWELL RIVER DISTRICT',
            'EDGEWATER',
            'BULL RIVER',
            'BOSWELL',
            'FORT STEELE',
            'RIONDEL',
            'PARSON',
            'WARDNER',
            'CRAWFORD BAY',
            'BRITISH COILUMBIA',
            'GRASMERE',
            'LIKELY',
            'SALMO',
            'WASA',
            'COLUMBIA-SHUSWAP',
            'OKANAGAN',
            'CLUCULZ LAKE',
            'ROBSON',
            'LUMBY',
            'QUEEN CHARLOTTE CITY',
            'KRESTOVA',
            'MASSET',
            'NEW HAZELTON',
            'WONOWON',
            'VANCONVER',
            'CHERRYVILLE',
            'JOHN',
            'LOUIS CREEK',
            'KITWANGA',
            'SOUTHBANK',
            'KNUTSFORD',
            'QUILCHENA',
            'DOUGLAS LAKE',
            'PRINCETON',
            'TAYLOR',
            'VAVENBY',
            'HOT SPRINGS',
            'AINSWORTH HOT SPRINGS',
            'SLOCAN',
            'MEADOW CREEK',
            'FORT ST JOHN',
            'VASEUX LAKE',
            'PEACHLAND',
            'CASTLEGEAR',
            'NARAMATA',
            'BRIDESVILLE',
            'BUFFALO CREEK',
            'OKANAGAN-SIMILKAMEEN H',
            'EAST KOOTENAY G',
            'TOPLEY',
            'BOWSER',
            'GREENWOOD',
            'AIYANSH',
            'BLACK CREEK',
            'ISKUT',
            'LINDELL BEACH',
            'TELEGRAPH CREEK',
            'GRANISLE',
            'GRINDROD',
            'KASLO',
            'BARKERVILLE',
            'VANCOUVER MAIN VANCOUVER',
            'KENORA',
            'LONE BUTTE',
            'CEDARVALE',
            'MCLEOD LAKE',
            'SAVONA',
            'NASS CAMP',
            'AINSWORTH',
            'FT SASKATCHEWAN',
            'BIG LAKE RANCH',
            'HORSEFLY',
            'GIBS',
            'W. VANCOUVER',
            'CLINTON',
            'WILLIAMS LAKE',
            'METRO VANCOUVER',
            'SICAMOUS',
            'NEW WESTMINSTER',
            'KITIMAT',
            'EAGLE BAY',
            'LAZO',
            'PORT MELLON',
            'MIDWAY',
            'DUNSTER',
            'PARSONS',
            'GALIANO',
            'CULTUS LAKE',
            'CANAL FLATS',
            'CASTLEGAR',
            'BLIND CHANNEL',
            'MADIERA PARK',
            "D'ARCY",
            'CHETWYND',
            'ROSEDALE',
            'FERNIE',
            'ATTACHIE',
            'CHEMAINUS',
            'CORTES ISLAND',
            'UNION BAY',
            'SKIDEGATE',
            'POUCE COUPE',
            'OONA RIVER',
            'SINCLAIR MILLS',
            'DOME CREEK',
            'OLD MASSETT',
            "LAX KW'ALAAMS",
            'ENDAKO',
            'YMIR',
            'DRAG',
            'KYUQUOT',
            'KLEMTU',
            'HARRISON MILLS',
            'RUTLAND',
            'BIG WHITE MTN',
            'FRANCOIS LAKE',
            'OCEAN FALLS',
            'NEW WESTMINSTER BC',
            'BIG WHITE',
            'WEST VANCOUVER',
            'PORT CLEMENTS',
            'NANOOSE BAY',
            'CAWSTON',
            'PROCTER',
            'NORTH VANCOUVER DISTRICT',
            'ROSSLAND',
            'NORTH VANCOVUER',
            'ENDERBY',
            'GILLIES BAY',
            'VANOUVER',
            'BRITANNIA BEACH',
            'LANGLEY TWP',
            'BAYNES LAKE',
            'FORT FRASER',
            'PORT SIMPSON',
            'COWICHAN VALLEY F',
            'TOBIANO',
            'NORTH SYDNEY',
            'EGMONT',
            'FAX LINE',
            'BELCARRA',
            'ALTONA',
            'FORT LANGLEY',
            'NORTH OKANAGAN D',
            'LADNER',
            'TSAWWASSEN',
            'BELLA BELLA',
            'WBANK',
            'STUART ISLAND',
            'VALEMOUNT',
            'WINFIELD',
            'TELKWA',
            'COMOX VALLEY',
            'WOODBRIDGE',
            'UNIVERSITY OF BRITISH COLUMBIA',
            'PORT EDWARD',
            'KITIMAT STIKINE',
            'MARA',
            'GREENVILLE',
            'COLWOOD',
            'SURREY',
            'HANOVER',
            'WINIFIELD',
            'COWICHAN VALLEY',
            'HOUSTON',
            'LOWER NICOLA',
            'W KEL',
            'KLEENA KLEENE',
            'VANANDA',
            'NITINAT LK',
            'NORTH COWICHAN',
            'VANDERHOFF',
            'MONTE LAKE',
            'NANAIMO DISTRICT',
            'NANOO B',
            'BURTON',
            'KINCOLITH',
            'LANGLEY',
            'NEW WESTMINISTER',
            'LEE CREEK',
            'SLOCAN PARK',
            'HAGWILGET',
            'WHITE ROCK',
            'TATLAYOKO LAKE',
            'LITTLE FORT',
            'NIMPO LAKE',
            'OSOY',
            'NORTHERN ROCKIES B',
            'FANNY BAY',
            'PRITCHARD',
            'BLUE RIVER',
            'TAPPEN',
            'PROPHET RIVER',
            'NEW AIYANSH',
            'THETIS ISLAND',
            'MOUNT CURRIE',
            'LIONS BAY',
            'ARRAS',
            'KIMBERLEY',
            'SKOOKUMCHUCK',
            'KELOWNA',
            'FORT ST JAMES',
            'CHILLIWACK',
            'HAISLA',
            'ERRINGTON',
            'ERICKSON',
            'HAZELTON',
            "MANSON'S LANDINF",
            'ALEXIS CREEK',
            'HORNBY ISLAND',
            'VAN ANDA',
            'TUMBLER RIDGE',
            'CLAYHURST',
            'MACKENZIE',
            'SAANICH',
            'TAHSIS',
            'ZEBALLOS',
            'DOWNTOWN OFFICE',
            'NO CHARGE - DIAL',
            'LUND',
            'AINSWORTH HOTSPRINGS',
            'FAIRMONT HOT SPRINGS',
            'TRAIL',
            'SOUTH SLOCAN',
            'ABBOTSFORD',
            'PITT MEADOWS',
            'AVOLA',
            'DENNY ISLAND',
            'KAMLOOPS',
            'ANGLEMONT',
            'NORTH OKANAGAN C',
            'MANSONS LANDING',
            'MILNER',
            'PRINCE GEROGE',
            'ANMORE',
            'LAC LA HACHE',
            'DEASE LAKE',
            'GRAY CREEK',
            'SUN PEAKS',
            'LYTTON',
            'EAST GATE',
            'CHARLIE LAKE',
            'DEROCHE',
            'BRISCO',
            'SHERIDAN LAKE',
            'PINANTAN LAKE',
            'NAZKO',
            'ROSE PRAIRIE',
            'PENDER ISLAND',
            'MOUNT WASHINGTON',
            'BLACK POINT',
            'WINDERMERE',
            'MOBERLY LAKE',
            'ERIE',
            'CRESTON',
            'REVELSTOKE',
            'GRAND FORKS',
            'SORRENTO',
            'SOUTH GRANVILLE',
            'LONGWORTH',
            'AHOUSAT',
            'FIELD',
            'TATLA LAKE',
            'MAPLE RIDGE',
            'CLEARWATER',
            'CENTRAL OKANAGAN H',
            'SULLIVAN BAY',
            'FOREST GROVE',
            'BARRIERE',
            'SPRINGFIELD/SPALL',
            'CUMB',
            'VANCOUVER ISLAND',
            'CAMPBELLRIVER',
            'QUESNEL',
            'TELEGRAPH COVE',
            'CASSIDY',
            'BEAVER VLY',
            'CHASE',
            'FAULDER',
            'SAANICHTON',
            'BLIND BAY',
            'ALD',
            'LISTER',
            'NEW DENVER',
            'NELSON',
            'SHALALTH',
            'VANDERHOOF',
            'VANCUVER',
            'NAKUSP',
            'SEYMOUR ARM',
            'WINLAW',
            'WYNNDEL',
            'BEAVERDELL',
            'OKANAGAN FALLS',
            'QUATHIASKI COVE',
            'QUEENS BAY',
            'CHRISTINA LAKE',
            'ESQUIMALT',
            'SHAWINIGAN LAKE',
            'KALEDEN',
            'KELOWA',
            'PENDER HARBOUR',
            'KITKATLA',
            'FRUITVALE',
            'KAMPLOOPS',
            'MALAHAT',
            'GOLD BRIDGE',
            'HIGHLANDS',
            'FURRY CREEK',
            'NEW WESMINSTER',
            'OLIVER',
            'FRASER LAKE',
            'MICA CREEK',
            'LANGFORD',
            'ARGENTA',
            'PORT MOODY',
            'W VAN',
            'HEFFLEY CREEK',
            'AGASSIZ',
            'HEDLEY',
            'GITANMAAX',
            'SHAWNIGAN LAKE',
            'GRANVILLE ST',
            'SMITHERS',
            'BOWEN ISLAND',
            '150 MILE HOUSE',
            'NORTH SAANICH',
            'WEST KELOWNA',
            'ABBOTSOFRD',
            'DELTA',
            'LANTZVILLE',
            'CROFTON',
            'BIG BEND',
            'PORT COQUITLAM',
            'VICTORIA',
            'SUNSHINE COAST E',
            'RADIUM HOT SPRINGS',
            'LILLOOET',
            'WOSS',
            'GARIBALDI HIGHLANDS',
            'PORT MCNEILL',
            'MANSON CREEK',
            'SALT SPRING ISLAND',
            'GARDEN BAY',
            'JAFFRAY',
            'GLADE',
            'MADEIRA PARK',
            'GIBSONS',
            'BRACKENDALE',
            'COWICHAN BAY',
            'GABRIOLA',
            'WHISTLER',
            'COOMBS',
            'ANNACIS ISLAND',
            'COBBLE HILL',
            'HANCEVILLE',
            'BELLA COOLA',
            'HOLBERG',
            'GALAGHR LAKE',
            'SOUTH HAZELTON',
            'OKANAGAN-SIMILKAMEEN',
            'RICHMOND',
            'NORTH BEND',
            'GOLDEN',
            'FORT ST. JOHN',
            'WELLS',
            'LANGELY',
            'YAHK',
            'UCLUELET',
            'KOKSILAH',
            'CUMBERLAND',
            'ROCK CREEK',
            'ELKFORD',
            'SANDSPIT',
            'LAKE ERROCK',
            'GARIBLD',
            'C.-B.',
            'MAYNE ISLAND',
            '70 MILE HOUSE',
            'TOFINO',
            'HONEYMOON BAY',
            'SURRET',
            'CHETYWND',
            'RISKE CREEK',
            'HOPE',
            'PINK MOUNTAIN',
            'SALMON ARM',
            'LAVINGTON',
            'COQUITLAM',
            'MCLEESE LAKE',
            'PANORAMA',
            'FARMINGTON',
            'STEWART',
            'FORT ST. JAMES',
            'EAGLE CREEK',
            'SPENCES BRIDGE',
            'PENTICTON',
            'MISSION',
            'DENMAN ISLAND',
            'CARIBOO G',
            'KITSEGUECLA',
            'QUAL BCH',
            'ROLLA',
            'ESOWISTA',
            'SPARWOOD',
            'WESTBRIDGE',
            'CENTRAL KOOTENAY B',
            'CAMPBELL RIVER',
            'GREEN BAY',
            'VANCOUVER',
            'YARROW STATION MAIN',
            'LAKE COUNTY',
            'REFUGE COVE',
            'YALE',
            'COLDSTREAM',
            'CACHE CREEK',
            'ALBERNI-CLAYOQUOT',
            'ST JOHN',
            'PRINCE GEORGE',
            'KOOTENAY BAY',
            '',
            'HALFMOON BAY',
            'ALDERGROVE',
            'WALHACHIN',
            'SATURNA',
            'MONTNEY',
            'GANG RANCH',
            'FORT NELSON',
            'SAYWARD',
            'PRINCE RUPERT',
            'LANGLEY TOWNSHIP',
            'DUNCAN',
            'ARMSTRONG',
            'BURNABY',
            'HAGENSBORG',
            '100 MILE HOUSE',
            'AHOUSAHT',
            'PORT RENFREW',
            'LOWER POST',
            'DAWSON CREEK',
            'THORNHILL',
            'ANAHIM LAKE',
            'FALKLAND',
            'PORT HARDY',
            'NORTH VANCOUVER',
            'CARIBOO A',
            'CENTRAL KELOWNA',
            'CANYON',
            'ALKALI LAKE',
            'LAKE COUNTRY',
            'MONTROSE',
            'FORT MCMURRAY',
            'SIDNEY',
            'TERRACE',
            'WESTBANK',
            'JORDAN RIVER',
            'GABRIOLA ISLAND',
            'POWELL RIVER',
            'HIXON',
            'TOMSLAKE',
            'EGMT',
            'OSOYOOS',
            'SOOKE',
            'SQUAMISH',
            'NORTH SHORE',
            'QUEEN CHARLOTTE',
            'MERVILLE',
            'INVERMERE',
            'COURTENAY',
            'MERRITT',
            'COURTNEY',
            'SUMMERLAND',
            'CRANBROOK',
            'GOLD RIVER',
            'MARYSVILLE',
            'MOYIE',
            'NANAIMO',
            'BONNINGTON',
            'BURNS LAKE',
            'BAKER CREEK',
            '108 MILE RANCH',
        ],
    },
];

export default cities;

import _ from 'lodash';
import { isString, isArray } from 'utils/validate';
import { DeviceOSMapping } from 'states/resources/ads/business-logic';

const geotargetsIncludeExcludeSchema = {
    city(value) {
        if (value === undefined) {
            return;
        }

        if (!isString().validate(value)) {
            return 'City is required';
        }
    },

    region(value) {
        if (value === undefined) {
            return;
        }

        if (!isString().validate(value)) {
            return 'Region is required';
        }
    },
};


const geoboxSchema = {
    E(value) {
        if (!_.isNumber(value)) {
            return 'E must be a number';
        }
    },
    N(value) {
        if (!_.isNumber(value)) {
            return 'N must be a number';
        }
    },
    S(value) {
        if (!_.isNumber(value)) {
            return 'S must be a number';
        }
    },
    W(value) {
        if (!_.isNumber(value)) {
            return 'W must be a number';
        }
    },
};

const geotargetsSchema = {
    country(value) {
        if (!isString().validate(value)) {
            return 'Country is required';
        }
    },

    exclude(value) {
        if (!_.isArray(value)) {
            return 'Exclude must be an array';
        }

        return geotargetsIncludeExcludeSchema;
    },

    include(value) {
        if (!_.isArray(value)) {
            return 'Include must be an array';
        }

        return geotargetsIncludeExcludeSchema;
    },
};

//-- validation rules --//
export function getDraftValidators_forPayload() {
    return {
        //==========
        // Basic
        //==========
        ad_preset_name(value) {
            if (!isString(1).validate(value)) {
                return 'Name is required';
            }
        },

        geotargets(value) {
            if (value === undefined) {
                return;
            }

            if (!isArray(1).validate(value)) {
                return 'Geotarget is required, select at least one country';
            }

            if (!_.every(value, _.isPlainObject)) {
                return 'Geotarget is an invalid type';
            }

            return geotargetsSchema;
        },

        target_age_groups(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Target Age Groups must be a list';
            }

            return ageGroupSchema;
        },

        target_carriers(value) {
            if (value === undefined) {
                return;
            }

            if (_.isArray(value) && value.length === 0) {
                return 'Target Carrier is required';
            }
        },

        target_genders(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Target Genders must be a list';
            }

            return gendersSchema;
        },

        max_cpm_rate_local(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isNumber(value) || !(value > 0)) {
                return 'Must be a number greater than 0';
            }
        },

        target_device_language(value) {
            if (value === undefined || value === null) {
                return;
            }

            if (_.isNumber(value)) {
                return 'Invalid type';
            }

            if (_.isArray(value) && value.length === 0) {
                return 'Target language is required';
            }
            return targetDeviceLanguageSchema;
        },

        target_device_language_exclude() {
            return undefined;
        },

        target_app_categories(value) {
            if (value === undefined) {
                return;
            }

            return appCategorySchema;
        },

        target_app_store_cat(value) {
            if (value === undefined) {
                return;
            }
            if (_.isArray(value) && value.length === 0) {
                return 'App category is required';
            }
        },

        audiences(value) {
            if (value === undefined) {
                return;
            }
            if (_.isArray(value) && value.length === 0) {
                return 'Audience segment is required';
            }
        },

        target_iab_categories(value) {
            if (value === undefined) {
                return;
            }

            return iabCategorySchema;
        },

        geoboxes(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Geoboxes must be a list';
            }

            if (_.isArray(value) && value.length === 0) {
                return 'Geo Fencing is required';
            }

            return geoboxSchema;
        },

        geo_targeting_settings(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isPlainObject(value)) {
                return 'Invalid geo targetting settings data type';
            }
        },

        target_device_os(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isArray(value)) {
                return 'Target Device OS must be a list';
            }

            return deviceOsSchema;
        },

        max_user_frequency(value) {
            if (value === undefined) {
                return;
            }

            if (!_.isNumber(value)) {
                return 'Max User Frequency must be a number';
            }

            if (value < 0) {
                return 'Minimum value is 0';
            }
        },

        ad_preset_properties(value) {
            if (!isArray(1).validate(value)) {
                return 'Invalid ad preset properties';
            }

            const anyWidgetSelected = _.some(value, val => {
                return _.some(val.properties, prop => {
                    return prop.checked;
                });
            });

            if (!anyWidgetSelected) {
                return 'Select at least one preset option';
            }
        },
    };
}

function ageGroupSchema(value) {
    if (!isOneOf([-1, 1, 2, 3, 4, 5, 6, 7, 8])(value)) {
        return 'Age must be one of: -1, 1, 2, 3, 4, 5, 6, 7, 8';
    }
}

function gendersSchema(value) {
    if (!isOneOf(['M', 'F', 'D'])(value)) {
        return 'Gender must be one of: M, F, D';
    }
}

function appCategorySchema(value) {
    if (!_.isNumber(value)) {
        return 'App Category must be a number';
    }
}

function iabCategorySchema(value) {
    if (value.indexOf('IAB') === -1) {
        return 'Invalid IAB Category';
    }
}

const DEVICE_OSes = Object.values(DeviceOSMapping);
function deviceOsSchema(value) {
    if (
        !isOneOf(DEVICE_OSes)(
            value
        )
    ) {
        return `Device OS must be one of: ${DEVICE_OSes.join(', ')}`;
    }
}

function targetDeviceLanguageSchema(value) {
    if (!isString(1).validate(value)) {
        return 'Target Device Language is required';
    }
}

//-- Custom validation helper --//
function isOneOf(list) {
    return function(value) {
        return _.includes(list, value);
    };
}

import c from 'common/constants/flux-events';

const initialState = {
    resetCounter: 0,
    isLoading: true,
};

export const NAME = 'creativesHistory';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case c.OVERVIEW_CREATIVES__HISTORY__OPEN:
            return {
                ...state,
                resetCounter: state.resetCounter + 1,
            };

        case c.SYSTEM__CREATIVE_HISTORY__FETCH__SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case c.OVERVIEW_CREATIVES__HISTORY__CLOSE:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}

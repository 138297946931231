import _ from 'lodash';

export function getValidatorsPOIv2() {
    return {
        name(value) {
            if (!value) {
                return 'POI segment name is required';
            }
        },
        radius(value) {
            if (!value) {
                throw new Error('Max radius should exist');
            }
        },
        category_code(value, source) {
            if (!value) {
                return 'POI Category is required';
            }
        },
        organization(value) {
            if (value === undefined) {
                throw new Error('organization should exist');
            }
        },
        time_constraint(value) {
            const range = value.date_range[0];

            // If the field is left blank it's OK
            if (range.start === null && range.end === null) {
                return;
            }

            // However, start and end must both be there otherwise
            if (range.start === null || range.end === null) {
                return 'Start and End should be specified';
            }
        },
    };
}

export function getCleanlistValidators() {
    return {
        cleanlist_entries(value) {
            if (value.length === 0) {
                return 'Must have at least 1 element';
            }
        },
    };
}

import _ from 'lodash';
import toastr from 'toastr';

export function createServerErrorAsToastr(error) {
    const errors = _.map(error.body, item => {
        toastr.error(item.message, `Server Error - ${error.message} - ${error.code}\n\n`, {
            closeButton: true,
            escapeHtml: true,
        });
    });
}

import React, { Fragment } from 'react';
import { Link } from 'react-router';

import {
    Layout,
    Image,
    Section,
    SubSection,
    Paragraph,
    BoldText,
    EmphasizedText,
    List,
    ListItem,
} from '../modules/layout.js';
import HelpTopic from '../help-topic';
import Spacer from 'widgets-v5/spacer';
import { BodyText, Subheading } from 'widgets-v5/typography';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';

export default {
    category: 'Reference',
    topic: 'Ad Setup',
    url: 'reference/ad-setup',
    published: false,
    Component: class Article extends React.Component {
        constructor(props) {
            super(props);

            this.basic = React.createRef();
            this.categorization = React.createRef();
            this.schedule = React.createRef();
            this.revenueBudgetCpm = React.createRef();
            this.revenueBudgetCpc = React.createRef();
            this.revenueBudgetAgencyMargin = React.createRef();
            this.revenueBudgetTotalSpendMarkup = React.createRef();
            this.budget = React.createRef();
            this.pacing = React.createRef();
            this.fta = React.createRef();
            this.bidOptimization = React.createRef();
            this.creative = React.createRef();
            this.thirdPartyVerification = React.createRef();
            this.targeting = React.createRef();
            this.audienceSegments = React.createRef();
        }

        render() {
            return (
                <Layout
                    title="Ad Setup"
                    sidebarContent={[
                        {
                            title: 'Basic',
                            link: this.basic,
                        },
                        {
                            title: 'Schedule',
                            link: this.schedule,
                        },
                        {
                            title: 'Revenue & Budget (CPM)',
                            link: this.revenueBudgetCpm,
                        },
                        {
                            title: 'Revenue & Budget (CPC)',
                            link: this.revenueBudgetCpc,
                        },
                        {
                            title: 'Revenue & Budget (Agency Margin)',
                            link: this.revenueBudgetAgencyMargin,
                        },
                        {
                            title: 'Revenue & Budget (Total Cost Markup)',
                            link: this.revenueBudgetTotalSpendMarkup,
                        },
                        {
                            title: 'Budget (Revenue Model Disabled)',
                            link: this.budget,
                        },
                        {
                            title: 'Pacing',
                            link: this.pacing,
                        },
                        {
                            title: 'Foot Traffic Attribution (FTA)',
                            link: this.fta,
                        },
                        {
                            title: 'Creative',
                            link: this.creative,
                        },
                        {
                            title: 'Third-Party Verification',
                            link: this.thirdPartyVerification,
                        },
                        {
                            title: 'Targeting',
                            link: this.targeting,
                        },
                        {
                            title: 'Audience Segments',
                            link: this.audienceSegments,
                        },
                        {
                            title: 'Bid Optimization',
                            link: this.bidOptimization,
                        },
                    ]}
                >
                    <Paragraph>Required fields are marked with an asterisk (*).</Paragraph>
                    <Section link={this.basic} title="Basic">
                        <SubSection title="Name*">
                            <Paragraph>
                                Used for identification. We recommend using a consistent format
                                where possible.
                            </Paragraph>
                            <Paragraph>
                                For example: [Brand] [YY]-[MM] [Campaign Name] (i.e. Unilever 18-10
                                Axe Body Spray Halloween Promotion)
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Notes">
                            <Paragraph>Attach free-form notes to this ad.</Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.schedule} title="Schedule">
                        <SubSection title="Timezone*">
                            <Paragraph>
                                Set the timezone for scheduling. <em>Start / End</em> and{' '}
                                <em>Dayparts</em> fields in this ad will use this timezone.
                            </Paragraph>
                            <Paragraph>
                                If multiple timezones are targeted, use the primary timezone or the
                                preferred timezone for reporting. By default, the campaign's Default
                                Timezone is used.
                            </Paragraph>
                            <Paragraph>
                                <strong>Note</strong>: <em>Weekparts</em> is not affected by
                                Timezone. UTC is always used instead.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Start / End*">
                            <Paragraph>
                                Set the flight dates for this ad. Select{' '}
                                <em>Start as soon as possible</em> to use the current date and time
                                as the start date.
                            </Paragraph>
                            <Paragraph>
                                The start date cannot be changed once the ad has started. The end
                                date can no longer be extended 21 days after the ad has ended.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Dayparts">
                            <Paragraph>
                                Set the time of day when this ad should run. Dayparts uses{' '}
                                <em>Timezone</em> set above.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Weekparts">
                            <Paragraph>Set the day of week when this ad should run.</Paragraph>
                            <Paragraph>
                                <strong>Note</strong>: Weekparts always uses the UTC timezone.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.revenueBudgetCpm} title="Revenue & Budget (CPM)">
                        <Paragraph>
                            The following fields are only visible when the campaign's{' '}
                            <em>Revenue Model</em> is set to "CPM / CPC", and the ad's Revenue Model
                            is set to "CPM".
                        </Paragraph>
                        <SubSection title="Revenue Model">
                            <Paragraph>
                                Set Revenue Model to "CPM" if your revenue is based on the number of
                                impressions delivered.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Bonus Ad">
                            <Paragraph>Enable Bonus Ad to set Revenue to 0 for this ad.</Paragraph>
                        </SubSection>
                        <SubSection title="Billing Rate">
                            <Paragraph>
                                Set the Billing Rate (CPM) for this ad. Revenue is a combination of
                                Billing Rate and Billable Impressions.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Billable Impressions">
                            <Paragraph>
                                Set the number of Billable Impressions for this ad. Revenue is a
                                combination of Billing Rate and Billable Impressions.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Non-billable Impressions">
                            <Paragraph>
                                Set Non-billable Impressions to adjust Total Impressions without
                                affecting Revenue.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Third-Party Fees">
                            <Paragraph>
                                Track overall spend more holistically, by adding Third-Party Fees
                                (CPM) that EngageFront would otherwise not know about. Third-party
                                Fees are automatically deducted from your Total Cost as impressions
                                are delivered, to ensure that you do not go overbudget.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.revenueBudgetCpc} title="Revenue & Budget (CPC)">
                        <Paragraph>
                            The following fields are only visible when the campaign's{' '}
                            <em>Revenue Model</em> is set to "CPM / CPC", and the ad's Revenue Model
                            is set to "CPM".
                        </Paragraph>
                        <SubSection title="Revenue Model">
                            <Paragraph>
                                Set Revenue Model to "CPC" if your revenue is based on the number of
                                clicks delivered.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Bonus Ad">
                            <Paragraph>
                                Enable Bonus Ad to set Billing Rate to $0 CPC and Billable Clicks to
                                0.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Billing Rate">
                            <Paragraph>
                                Set the Billing Rate (CPC) for this ad. Revenue is a combination of
                                Billing Rate and Billable Clicks.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Billable Clicks">
                            <Paragraph>
                                Set the number of Billable Clicks for this ad. Revenue is a
                                combination of Billing Rate and Billable Clicks.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Non-billable Clicks">
                            <Paragraph>
                                Set Non-billable Clicks to adjust Total Clicks without affecting
                                Revenue.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Third-Party Fees">
                            <Paragraph>
                                Track overall spend more holistically, by adding Third-Party Fees
                                (CPM) that EngageFront would otherwise not know about. Third-party
                                Fees are automatically deducted from your Total Cost as impressions
                                are delivered, to ensure that you do not go overbudget.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section
                        link={this.revenueBudgetAgencyMargin}
                        title="Budget & Billing (Agency Margin)"
                    >
                        <Paragraph>
                            The following fields are only visible when the campaign's{' '}
                            <em>Revenue Model</em> is set to "Agency Margin".
                        </Paragraph>
                        <SubSection title="Revenue Model">
                            <Paragraph>
                                Revenue Model is automatically set to Agency Margin.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Agency Margin">
                            <Paragraph>
                                Agency Margin is automatically set based on campaign settings.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Client Budget">
                            <Paragraph>
                                Enter the expected Revenue. Total Cost is automatically calculated
                                based on Client Budget and Agency Margin.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Third-Party Fees">
                            <Paragraph>
                                Track overall spend more holistically, by adding Third-Party Fees
                                (CPM) that EngageFront would otherwise not know about. Third-party
                                Fees are automatically deducted from your Total Cost as impressions
                                are delivered, to ensure that you do not go overbudget.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section
                        link={this.revenueBudgetTotalSpendMarkup}
                        title="Budget & Billing (Total Cost Markup)"
                    >
                        <Paragraph>
                            The following fields are only visible when the campaign's{' '}
                            <em>Revenue Model</em> is set to "Total Cost Markup".
                        </Paragraph>
                        <SubSection title="Revenue Model">
                            <Paragraph>
                                Revenue Model is automatically set to Total Cost Markup.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Markup Rate">
                            <Paragraph>Set the Markup Rate (%) for this ad.</Paragraph>
                        </SubSection>
                        <SubSection title="Client Budget">
                            <Paragraph>
                                Enter the expected Client Budget. Total Revenue is automatically
                                calculated by adding Markup Rate to Client Budget.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Third-Party Fees">
                            <Paragraph>
                                Track overall spend more holistically, by adding Third-Party Fees
                                (CPM) that EngageFront would otherwise not know about. Third-party
                                Fees are automatically deducted from your Total Cost as impressions
                                are delivered, to ensure that you do not go overbudget.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.budget} title="Budget (Revenue Model Disabled)">
                        <Paragraph>
                            The following fields are only visible when the campaign's{' '}
                            <em>Revenue Model</em> is set to Disabled.
                        </Paragraph>
                        <SubSection title="Goal Type">
                            <Paragraph>
                                Set the metric to limit media buying by: impressions, clicks, or
                                total spend. The ad will also automatically be paced evenly by this
                                metric.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Total Impressions">
                            <Paragraph>
                                The ad will automatically pause when this number of impressions has
                                been delivered.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Total Clicks">
                            <Paragraph>
                                The ad will automatically pause when this number of clicks has been
                                delivered.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Total Cost">
                            <Paragraph>
                                The ad will automatically pause when this amount of budget has been
                                spent. Total Cost includes media costs, data costs and any
                                third-party fees.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Third-Party Fees">
                            <Paragraph>
                                Track overall spend more holistically, by adding Third-Party Fees
                                (CPM) that EngageFront would otherwise not know about. Third-party
                                Fees are automatically deducted from your Total Cost as impressions
                                are delivered, to ensure that you do not go overbudget.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.pacing} title="Pacing">
                        <Paragraph>
                            By default, EngageFront automatically paces evenly based on the revenue
                            model set in the campaign or ad. If Revenue Model is disabled, the
                            selected "Goal Type" will be used.
                        </Paragraph>
                        <Spacer type="small" />
                        <ComposableDataTable>
                            <Row header>
                                <Cell>Revenue Model</Cell>
                                <Cell>Pacing Metric</Cell>
                            </Row>
                            <Row>
                                <Cell>CPM</Cell>
                                <Cell>Impressions</Cell>
                            </Row>
                            <Row>
                                <Cell>CPC</Cell>
                                <Cell>Clicks</Cell>
                            </Row>
                            <Row>
                                <Cell>Agency Margin</Cell>
                                <Cell>Revenue</Cell>
                            </Row>
                            <Row>
                                <Cell>Total Cost Markup</Cell>
                                <Cell>Total Cost</Cell>
                            </Row>
                            <Row>
                                <Cell>Disabled</Cell>
                                <Cell>
                                    Based on Goal Type field (Impressions, Clicks, or Total Cost)
                                </Cell>
                            </Row>
                        </ComposableDataTable>
                        <Spacer type="small" />
                        <Paragraph>
                            Pacing is based on UTC time. The basic formula is Daily Target = Total
                            Target ÷ Number of UTC Days, including incomplete days.
                        </Paragraph>
                        <Paragraph>
                            For example, an ad set to run 10,000 impressions from 00:00 EST to 23:59
                            EST of the same day will see approximately 5,000 impressions delivered
                            between 00:00–18:59 EST (05:00–23:59 UTC) and 5,000 impressions
                            delivered between 19:59–23:59 EST (00:00–04:59 UTC).
                        </Paragraph>
                        <Paragraph>
                            The default behaviour can be overriden to fill more quickly or more
                            slowly, by setting a new daily target higher or lower than the default
                            numbere given by the formula above. When set an Override field is set,
                            EngageFront will ignore the default pacing for that metric and target
                            for this many impressions, clicks or amount of total spend per day
                            instead. The override amount cannot exceed the total target set under
                            the Budget section.
                        </Paragraph>
                        <Paragraph>
                            If multiple goals are entered, the ad will stop serving when the first
                            of any of its daily goals have been met.
                        </Paragraph>
                    </Section>
                    <Section link={this.fta} title="Foot Traffic Attribution (FTA)">
                        <SubSection title="Insert FTA Pixel">
                            <Paragraph>
                                Select "Insert FTA Pixel" to automatically track store visits for
                                this ad. A data cost will be automatically added when this is
                                selected.
                            </Paragraph>
                            <Paragraph>
                                <BoldText>Important:</BoldText> "Insert FTA Pixel" only inserts the
                                required tracking pixel for this ad. Some parameters, including the
                                list of locations and days to track, must be set up separately
                                outside of EngageFront.
                            </Paragraph>
                            <Paragraph>
                                To learn more about Foot Traffic Attribution, speak to your Account
                                Manager or Client Partner.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.creative} title="Creative">
                        <SubSection title="">
                            <Paragraph>
                                Select one or more creatives to serve, and the method of rotation:
                                <ComposableDataTable>
                                    <Row header>
                                        <Cell>Rotation</Cell>
                                        <Cell>Description</Cell>
                                    </Row>
                                    <Row>
                                        <Cell>Single</Cell>
                                        <Cell>
                                            Choose a single creative to be delivered for the entire
                                            ad flight.
                                        </Cell>
                                    </Row>
                                    <Row>
                                        <Cell>Even Rotation</Cell>
                                        <Cell>
                                            <Paragraph>
                                                Choose multiple creatives to be delivered evenly at
                                                an equal ratio of impressions, clicks or spend.
                                            </Paragraph>
                                            <Paragraph>
                                                <BoldText>Note:</BoldText> Creatives are not rotated
                                                sequentially, so an equal ratio is not guaranteed
                                                when the ad is underdelivering. Each creative is
                                                capped at the specified ratio, but if only{' '}
                                                <EmphasizedText>some</EmphasizedText> creatives are
                                                struggling with inventory (due to a different
                                                format, size or other properties), EngageFront will
                                                still try to deliver other creatives in full.
                                            </Paragraph>
                                        </Cell>
                                    </Row>
                                    <Row>
                                        <Cell>Weighted Rotation</Cell>
                                        <Cell>
                                            Choose multiple creatives to be delivered and customize
                                            the ratio at which they appear.
                                        </Cell>
                                    </Row>
                                    <Row>
                                        <Cell>Scheduled Rotation</Cell>
                                        <Cell>
                                            Setup a schedule to automatically switch between
                                            creatives throughout the ad flight.
                                        </Cell>
                                    </Row>
                                </ComposableDataTable>
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.thirdPartyVerification} title="Third-Party Verification">
                        <SubSection title="Fraud Protection">
                            <Paragraph>
                                EngageFront has partnered with Forensiq by Impact to provide
                                real-time blacklisting of high-risk and suspect inventory. Fraud
                                Protection is enabled on all ads by default, at no added cost.
                            </Paragraph>
                            <Paragraph>
                                Fraud protection can be adjusted to maximize inventory.
                            </Paragraph>
                            <Spacer type="small" />
                            <ComposableDataTable>
                                <Row header>
                                    <Cell>Level</Cell>
                                    <Cell>Description</Cell>
                                </Row>
                                <Row>
                                    <Cell>Low Risk Apps Only (Default)</Cell>
                                    <Cell>
                                        Automatically blacklist medium-risk and high-risk inventory.
                                    </Cell>
                                </Row>
                                <Row>
                                    <Cell>Low and Medium Risk Apps Only</Cell>
                                    <Cell>Automatically blacklist high-risk inventory.</Cell>
                                </Row>
                                <Row>
                                    <Cell>Off</Cell>
                                    <Cell>Do not blacklist any at-risk inventory.</Cell>
                                </Row>
                            </ComposableDataTable>
                            <Spacer type="small" />
                            <Paragraph>
                                EngageFront is proudly certified against fraud by TAG.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.targeting} title="Targeting">
                        <SubSection title="Geotargeting">
                            <Paragraph>
                                Geotargeting uses a device's IP address to determine their
                                geolocation. Geotargeting provides maximum reach (all online devices
                                have an IP address) and can provide accurate targeting down to
                                city-level precision. For higher precision beyond city-level,
                                GPS-based geotargeting (Geofencing or POI Segments) should be used.
                            </Paragraph>
                            <Paragraph>
                                Select at least one country on the left. By default, all devices in
                                the country are targeted. To target or specific specific locations,
                                enable "Specify Locations" and use the "Include" and "Exclude"
                                fields. You can search by city or region name, or postal codes.
                                Postal code targeting is supported in Canada only and is based on
                                Forward Sortation Areas (FSAs) — the first three letters of your
                                postal code, for example "M5H".
                            </Paragraph>
                            <Paragraph>
                                You can add multiple cities or regions to expand the geotarget.
                                Devices in any of the included locations will be targeted. You can
                                also exclude subdivisions of regions targeted in "Include". For
                                example, to target all of Ontario except Toronto, add Ontario to
                                "Include" and Toronto, Ontario to "Exclude".
                            </Paragraph>
                            <Paragraph>
                                To add many locations at once, use "Bulk Import". Type or paste in a
                                list of locations to target or suppress, with one location per line.
                                Locations must be specified in these formats:
                                <List>
                                    <ListItem>
                                        <BoldText>
                                            Cities: [City name], [Region name or code]
                                        </BoldText>
                                        <br />
                                        Examples: "Toronto, ON"; "Montreal, Quebec"
                                    </ListItem>
                                    <ListItem>
                                        <BoldText>
                                            Postal Codes: [First 3 letters of postal code]
                                        </BoldText>
                                        <br />
                                        Examples: "M5H"; "V5Y"
                                    </ListItem>
                                </List>
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Geofencing">
                            <Paragraph>
                                Geofencing uses a device's GPS coordinates to determine location. In
                                contrast to IP-based geotargeting, GPS-based geotargeting provides
                                more accurate targeting, but reach is limited to users who have
                                shared their location via their device's location privacy settings.
                            </Paragraph>
                            <Paragraph>
                                To use geofencing, draw one or more fences on the provided map:
                                <List>
                                    <ListItem>
                                        Click the Fence Tool in the top toolbar.{' '}
                                        <Image url="/images/help/ad-setup-geofencing-fence-tool.png" />
                                    </ListItem>
                                    <ListItem>
                                        Click and drag on the map to draw to a fence.
                                    </ListItem>
                                </List>
                                To draw another fence, click on the Fence Tool again. Drawing
                                additional fences will increase the reach of the ad. Use the Hand
                                Tool to resize fences or select fences for deletion.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="POI Segments">
                            <Paragraph>
                                POI Segments are a special type of audience segment based on points
                                of interest (POIs). Users who were present within the specified
                                radius of a POI will be automatically added to the segment. It
                                combines the accuracy of GPS-based geotargeting and the reach of
                                audience segments, to give the best of both worlds.
                            </Paragraph>
                            <Paragraph>
                                Note: POI Segments cannot be used in conjunction with Audience
                                Segments.
                            </Paragraph>
                            <Paragraph>
                                You can select from pre-built Standard POI Segments, or create your
                                own Custom POI Segments in the{' '}
                                <Link href="/presets/pois">POI Segments</Link> section.
                            </Paragraph>
                            <BoldText>Standard POI Segments & POI Categories</BoldText>
                            <Paragraph>
                                Standard POI Segments are divided into POI Categories. When
                                targeting a POI Category, all POI Segments categorized under the
                                category will be targeted.
                            </Paragraph>
                            <BoldText>Custom POI Segments</BoldText>
                            <Paragraph>
                                POI Segments that you create will appear in this section.
                            </Paragraph>
                            <BoldText>Lookback Window</BoldText>
                            <Paragraph>
                                By default, only users who were present at the POI in the last 21
                                days are targeted. By adjusting the Lookback Window, you can target
                                users who had been in the vicinity up to 365 days ago.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Device OS">
                            <Paragraph>Target or suppress specific operating systems.</Paragraph>
                        </SubSection>
                        <SubSection title="Carrier Networks">
                            <Paragraph>Target or suppress specific carrier networks.</Paragraph>
                        </SubSection>
                        <SubSection title="User Language">
                            <Paragraph>
                                Target or suppress specific user languages. The user / device
                                language is used and may be different from the app language.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="App Category">
                            <Paragraph>
                                Target or suppress specific{' '}
                                <Link href="/help/reference/app-categories">
                                    app store categories
                                </Link>
                                .
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Gender">
                            <Paragraph>
                                Target or suppress specific genders. Demographic profiles are
                                created based on probabilistic analysis of sanitized bid-stream
                                data.
                            </Paragraph>
                            <Paragraph>
                                The "Unknown" option significantly expands reach to include users
                                for whom we have insufficient or inconsistent data. We recommend
                                including "Unknown" unless a narrower target is required.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Age">
                            <Paragraph>
                                Target or suppress specific age groups. Demographic profiles are
                                created based on probabilistic analysis of sanitized bid-stream
                                data.
                            </Paragraph>
                            <Paragraph>
                                The "Unknown" option significantly expands reach to include users
                                for whom we have insufficient or inconsistent data. We recommend
                                including "Unknown" unless a narrower target is required.
                            </Paragraph>
                        </SubSection>
                    </Section>
                    <Section link={this.audienceSegments} title="Audience Segments">
                        <Paragraph>
                            Target or suppress audience segments. Search from a database of over 500
                            built-in audience segments.
                        </Paragraph>
                        <Paragraph>
                            An "OR" relationship is always used between multiple segments. In other
                            words, the more audience segments you target, the more users you will
                            reach.
                        </Paragraph>
                        <Paragraph>
                            Using premium audience segments may incur additional data costs. The
                            cost of the most expensive segment will always be charged.
                        </Paragraph>
                        <Paragraph>
                            Note: Audience Segments cannot be used in conjunction with POI Segments.
                        </Paragraph>
                    </Section>
                    <Section link={this.bidOptimization} title="Bid Optimization">
                        <SubSection title="Total Cost">
                            <Paragraph>
                                Specify the maximum CPM amount you are willing spend to reach a user
                                with this ad. Total Cost should include any Data Costs and
                                Third-Party Fees. The final bid price will be automatically
                                calculated.
                            </Paragraph>
                        </SubSection>
                        <SubSection title="Frequency Cap">
                            <Paragraph>
                                Set the maximum number of impressions to display, per unique device,
                                per hour, day, week, or lifetime of ad. Use Frequency Cap to balance
                                between performance and reach. The default value of 5 impressions
                                per day is a good starting point for further optimization.
                            </Paragraph>
                            <Paragraph>
                                You can set a higher frequency (more impressions) to reach your goal
                                and fill your budget faster, but at the likely drawback of lower
                                performance. Users typically do not click, engage, or respond to ads
                                proportionately to the number of ad exposures.
                            </Paragraph>
                            <Paragraph>
                                Frequency cap is applied in UTC time, and frequency counts are reset
                                at 00:00 UTC.
                            </Paragraph>
                        </SubSection>
                    </Section>
                </Layout>
            );
        }
    },
};

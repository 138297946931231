import _ from 'lodash';

import allRegions from 'common/constants/regions';

// inconsistent region code
export const decodeRegion = function(regionCode, countryCode) {
    var countryRegions;
    if (!regionCode) {
        return '';
    }

    var splited = regionCode.split('-');

    if (splited.length === 0) {
        return '';
    }

    if (countryCode) {
        countryRegions = allRegions[countryCode];
    } else {
        countryRegions = allRegions[splited[0]];
    }

    if (_.isEmpty(countryRegions)) {
        return '';
    }

    if (splited.length === 2) {
        return countryRegions[regionCode];
    } else {
        return countryRegions[countryCode + '-' + splited[0]];
    }
};

export const formatGeoRegionCity = function(countryCode, obj) {
    var regionName = decodeRegion(obj.region, countryCode);
    if (obj.fsa) {
        return obj.fsa;
    }
    if (!_.isEmpty(obj.city)) {
        if (!regionName) {
            return obj.city;
        }

        return obj.city + ', ' + (obj.isRegionCode ? obj.region : regionName);
    }
    return regionName;
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import actions from './actions';
import selector from './selector';

import FlexSegmentsFormActions from 'forms/flex-segments-form/actions';

import Layout from './layout';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.fetchBrands(keywords));
};

const FlexBrandDashboard = ({ dispatch, brands, loading, params, organizationId }) => {
    useEffect(() => {
        dispatch(actions.fetchBrands());
    }, []);

    const onSearch = value => {
        handleSearch(value, dispatch);
    };

    const openFormModal = selectedFlexBrandId => {
        dispatch(FlexSegmentsFormActions.open(selectedFlexBrandId));
    };

    const onSave = () => {
        dispatch(actions.fetchBrands());
    };

    return (
        <Layout
            audienceSegments={brands}
            onSearch={onSearch}
            openFormModal={openFormModal}
            onSave={onSave}
            loading={loading}
            activeAudience={Number(params.audienceId)}
            organizationId={organizationId}
        />
    );
};

export default connect(selector)(FlexBrandDashboard);

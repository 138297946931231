import _ from 'lodash';

const customFieldsSchema = {
    key(value) {
        if (!value) {
            throw new Error('key is required');
        }
    },
    name(value) {
        if (!_.isString(value)) {
            return 'required field';
        }
    },
    required(value) {
        if (!_.isBoolean(value)) {
            return 'required field';
        }
    },
    value() {},
};

const validationRules = {
    name(value) {
        if (typeof value !== 'string' || (typeof value === 'string' && value === '')) {
            return 'Organization name is required';
        }
    },
    client_type(value, source) {
        if (source.type !== 'client') {
            return;
        }
        if (!value) {
            return 'required field';
        }
    },
    type(value) {
        if (
            typeof value !== 'string' ||
            (typeof value === 'string' && value === '') ||
            value === void 0
        ) {
            return 'Organization type is required';
        }
    },

    credit_limit(value) {
        if (typeof value !== 'number' || (typeof value === 'number' && value < -1)) {
            return 'Credit limit must a number greater than zero, or leave blank if unlimited';
        }
    },

    tech_fee(value) {
        if (typeof value !== 'number' || (typeof value === 'number' && (value > 1 || value < 0))) {
            return 'Platform Fee must a number between 0 and 100';
        }
    },

    auditors(value) {
        if (Object.prototype.toString.call(value) !== '[object Array]') {
            return 'internal error, autitor is not an array';
        } else {
            return _value => {
                if (typeof _value !== 'string') {
                    return 'Internal error, element of array not number: ';
                }
            };
        }
    },
    auditees(value) {
        if (Object.prototype.toString.call(value) !== '[object Array]') {
            return 'Internal error, auditeer is not an array';
        } else {
            return _value => {
                if (typeof _value !== 'string') {
                    return 'internal error, element of auditee array is not number';
                }
            };
        }
    },
    currency(value) {
        if (typeof value !== 'string' && _.includes(['USD', 'CAD'], value)) {
            return 'Currency must be either USD or CAD';
        }
    },
    custom_fields(value) {
        if (!_.isArray(value)) {
            return 'invalid type';
        }
        if (value.length === 0) {
            return;
        }

        return customFieldsSchema;
    },
    revenueModel() {
        // optional
    },
    agencyMarginRate() {
        // optional
    },
};

export default validationRules;

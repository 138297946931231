import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { browserHistory } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

import flags from 'containers/flags/service';
import PopUp from 'widgets-v5/pop-up';
import AdSummary from 'containers/ad-summary';
import CampaignSummaryView from './modules/campaign-summary-view';
import FillProgressBar from 'widgets-v5/fill-progress-bar';
import StatsSummaryTable from './modules/stats-summary-table';
import ElapsedTimeProgressBar from 'widgets-v5/elapsed-time-progress-bar';
import { TemporaryDrawer } from 'widgets-v6/modal';

import CampaignOverallProgress from './modules/campaign-overall-progress';

import {
    formatTimeRangeInDaysHours,
    formatNumber_percentage,
    formatNumber_whole,
    formatNumber_currency,
} from 'utils/formatting';
import { getDateProgressRelativeToRange } from 'common/date-calculator';
import {
    createGoalDeliveryString,
    createRemainingDeliveryString,
    createBookedDeliveryString,
} from './modules/campaign-overall-progress';

import Separator from 'widgets-v5/separator';
import { NeutralButton } from 'widgets-v5/buttons';
import TimezoneSelector from 'widgets-v5/timezone-selector';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    drawer: {
        width: '55%',
    },
    drawerContent: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 64px)',
        margin: theme.spacing(2),
    },
    drawerHeader: {
        flex: '0 0 auto',
    },
    drawerBody: {
        flex: '1 1 auto',
        overflowY: 'auto',
    },
    metrics: {
        padding: '0px 35px',
    },
    metric: {
        fontSize: 14,
    },
    infoIcon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingTop: '15px',
        paddingLeft: '40%',
    },
}));

export function ProgressLayout(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const {
        campaignId,
        campaign,
        statsTableData,
        summary,
        ads,
        workspace,
        flight,
        dataForOverallGraph,
        organizationTimezone,
        timezoneAtCampaignLevel,
        changeTimezoneAtCampaignLevel,
        isLoading,
        progressData,
    } = props;

    const { adId } = props.params;
    const currentAd = _.find(ads, ad => parseInt(adId) === ad.id);

    useEffect(() => {
        const { adId } = props.params;
        if (adId) {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        if (adId && currentAd) {
            changeTimezoneAtCampaignLevel(currentAd.timezone);
        }
    }, [adId]);

    const openDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
        browserHistory.push(`/campaigns/${campaignId}/progress/`);
    };

    let currentDelivered = 0;
    let idealDelivered = 0;
    let projectedDelivery = 0;
    let currentPacing = 0;
    let totalTarget = 0;
    let revenueAtRisk = 0;
    let goalAtRisk = 0;
    let projectedPacing = 0;
    let goal = '';
    let variance = 0;
    if (progressData) {
        currentDelivered = progressData.currentDelivered;
        currentPacing = progressData.currentPacing;
        goal = _.capitalize(progressData.goal === 'billings' ? 'revenue' : progressData.goal);
        idealDelivered = progressData.idealDelivered;
        projectedDelivery = progressData.projectedDelivery;
        totalTarget = progressData.totalTarget;
        currentPacing = progressData.currentPacing;
        revenueAtRisk = progressData.revenueAtRisk;
        goalAtRisk = progressData.goalAtRisk;
        projectedPacing = progressData.projectedPacing;
        variance = progressData.variance ? progressData.variance : 0;
    }

    const projectedPacingColor = getProjectedPacingColor(projectedPacing);
    const isEnded = currentAd && currentAd.status === 'ended';
    const totalPacingColor = getPacingColor(currentPacing, isEnded);
    const isAutoCampaign = campaign.budget_allocation_method === 'auto';
    const isCurrencyFormat = goal === 'Billings' || goal === 'Spend' || goal === 'Revenue';

    let metricsLeftColumn = [];
    let metricsMiddleColumn = [];
    let metricsRightColumn = [];

    if (isEnded) {
        metricsLeftColumn = [
            {
                title: `Delivered ${goal}`,
                value: getTargetFormat(isCurrencyFormat, currentDelivered),
                color: 'black',
            },
            {
                title: 'Target',
                value: getTargetFormat(isCurrencyFormat, totalTarget),
                color: 'black',
            },
        ];

        metricsMiddleColumn = [
            {
                title: 'Delivered %',
                value: formatNumber_percentage(currentPacing),
                color: totalPacingColor,
            },
            {
                title: 'Variance',
                value: getTargetFormat(isCurrencyFormat, variance),
                color: 'black',
            },
        ];
    } else {
        metricsLeftColumn = [
            {
                title: 'Current Pacing',
                value: formatNumber_percentage(currentPacing),
                color: totalPacingColor,
            },
            {
                title: `Delivered ${goal}`,
                value: getTargetFormat(isCurrencyFormat, currentDelivered),
                color: 'black',
            },
            {
                title: 'Ideal Delivery',
                value: getTargetFormat(isCurrencyFormat, idealDelivered),
                color: 'black',
            },
        ];

        metricsMiddleColumn = [
            {
                title: 'Projected Pacing',
                value: currentPacing === 0 ? 'N/A' : formatNumber_percentage(projectedPacing),
                color: projectedPacingColor,
            },
            {
                title: 'Projected Delivery',
                value:
                    currentPacing === 0
                        ? 'N/A'
                        : getTargetFormat(isCurrencyFormat, projectedDelivery),
                color: 'black',
            },
            {
                title: 'Target',
                value: getTargetFormat(isCurrencyFormat, totalTarget),
                color: 'black',
            },
        ];

        if (progressData && progressData.goal !== 'billings') {
            metricsRightColumn.push({
                title: `${goal} At Risk`,
                value:
                    progressData.goal === 'spend'
                        ? formatNumber_currency(goalAtRisk)
                        : formatNumber_whole(goalAtRisk),
                color: 'black',
            });
        }

        if (currentAd && campaign.revenueModel !== 'disabled') {
            metricsRightColumn.push({
                title: 'Revenue At Risk',
                value: formatNumber_currency(revenueAtRisk),
                color: 'black',
            });
        }
    }

    return (
        <div className="ef5-progress-page">
            <div
                className={classnames('ef5-progress-page__workspace', {
                    'ef5-progress-page__workspace_is-campaign-progress': !workspace,
                })}
            >
                <div className="ef5-progress-page__ad-list">
                    <CampaignOverallProgress
                        ads={ads}
                        currentAd={adId}
                        campaign={campaign}
                        dataForOverallGraph={dataForOverallGraph}
                        campaignId={campaignId}
                        flight={flight}
                        onClick={openDrawer}
                        isLoading={isLoading}
                    />
                </div>
                <TemporaryDrawer
                    side="right"
                    open={open}
                    onClose={closeDrawer}
                    classes={{ paper: classes.drawer }}
                >
                    <div className={classes.drawerContent}>
                        {currentAd && (
                            <div className={classes.drawerHeader}>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="center"
                                    wrap="nowrap"
                                >
                                    <Grid item>
                                        <h1 className="ef5-progress-page__ad-name">
                                            #{currentAd.id} {currentAd.name}
                                        </h1>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            aria-label="close"
                                            onClick={closeDrawer}
                                            size="large"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        <Box mb={2}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <Grid item sm={12} md={8} lg={5}>
                                    <Box mb={1}>
                                        <FormLabel>Ad Timezone</FormLabel>
                                    </Box>
                                    <TimezoneSelector
                                        timezone={timezoneAtCampaignLevel}
                                        existingTimezones={[
                                            organizationTimezone,
                                            campaign.default_timezone,
                                            ...campaign.adTimezones,
                                            'UTC',
                                        ]}
                                        onChange={changeTimezoneAtCampaignLevel}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <div className={classes.drawerBody}>
                            <Separator />
                            {currentAd ? (
                                <AdProgressRow
                                    campaignStart={flight.start}
                                    campaignEnd={flight.end}
                                    currentAd={currentAd}
                                    campaign={campaign}
                                    isLoading={isLoading}
                                />
                            ) : (
                                <LinearProgress />
                            )}
                            {workspace ? workspace : <CircularProgress />}
                            {!isAutoCampaign && (
                                <Grid container className={classes.metrics} spacing={4}>
                                    <Grid item xs={isEnded ? 6 : 4}>
                                        {_.map(metricsLeftColumn, metric => (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                key={metric.title}
                                                color={metric.color}
                                            >
                                                <Typography
                                                    className={classes.metric}
                                                    color="textSecondary"
                                                >
                                                    {`${metric.title}:`}
                                                </Typography>
                                                <Typography className={classes.metric}>
                                                    {metric.value}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Grid>
                                    <Grid item xs={isEnded ? 6 : 4}>
                                        {_.map(metricsMiddleColumn, metric => (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                key={metric.title}
                                                color={metric.color}
                                            >
                                                <Typography
                                                    className={classes.metric}
                                                    color="textSecondary"
                                                >{`${metric.title}:`}</Typography>
                                                <Typography className={classes.metric}>
                                                    {metric.value}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Grid>
                                    {!isEnded && (
                                        <Grid item xs={4}>
                                            {_.map(metricsRightColumn, metric => (
                                                <Box
                                                    display="flex"
                                                    key={metric.title}
                                                    justifyContent="space-between"
                                                    color={metric.color}
                                                >
                                                    <Typography
                                                        className={classes.metric}
                                                        color="textSecondary"
                                                    >{`${metric.title}:`}</Typography>
                                                    <Typography className={classes.metric}>
                                                        {metric.value}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                            {!isAutoCampaign && (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={12}>
                                        <Typography className={classes.infoIcon}>
                                            <InfoOutlinedIcon fontSize="small" />
                                            <Typography>
                                                Progress Graph updated every 1 hour
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {currentAd ? (
                                <StatsTable
                                    ad={currentAd}
                                    campaignId={campaignId}
                                    status={currentAd.status}
                                    summary={summary}
                                    statsTableData={statsTableData}
                                    isLoading={isLoading}
                                />
                            ) : (
                                <LinearProgress />
                            )}
                        </div>
                    </div>
                </TemporaryDrawer>
            </div>
        </div>
    );
}

function AdProgressRow(props) {
    const { campaignStart, campaignEnd, campaign } = props;
    const ad = props.currentAd;

    const { goal, totalTarget, totalDelivered, dailyDelivered, dailyCap, maxTotalDelivery } = ad;

    const elapsedTime = {
        start:
            getDateProgressRelativeToRange({ start: campaignStart, end: campaignEnd }, ad.start) *
            100,
        end:
            100 -
            getDateProgressRelativeToRange({ start: campaignStart, end: campaignEnd }, ad.end) *
                100,
    };

    if (campaign.budget_allocation_method === 'auto') {
        return null;
    }

    return (
        <div className="ef3-campaign-delivery__progress-section ef3-campaign-delivery__ad-progress-row">
            {campaign.budget_allocation_method === 'manual' && (
                <div className="ef3-campaign-delivery__elapsedBar">
                    <ElapsedTimeProgressBar
                        type="ad-row"
                        deliveryHealth={ad.health}
                        deliveryProgress={(totalDelivered / maxTotalDelivery) * 100}
                        status={ad.status}
                        campaignFlight={{ campaignStart, campaignEnd }}
                        startDate={new Date(ad.start)}
                        endDate={new Date(ad.end)}
                        offsetLeft={elapsedTime.start}
                        offsetRight={elapsedTime.end}
                        labelLeft={`${formatTimeRangeInDaysHours({
                            start: ad.start,
                            end: ad.end,
                        })} booked`}
                    />
                </div>
            )}
            <div className="ef3-campaign-delivery__stats">
                {totalTarget !== 0 ? (
                    <span>
                        <p>{createGoalDeliveryString(totalDelivered, goal)}</p>
                        {campaign.budget_allocation_method === 'manual' && (
                            <p>
                                {createRemainingDeliveryString(totalTarget, totalDelivered, goal)}{' '}
                                remaining / {createBookedDeliveryString(totalTarget, goal)}
                            </p>
                        )}
                    </span>
                ) : null}
                {dailyCap ? (
                    <span>
                        <p>{createBookedDeliveryString(dailyCap, goal)} today</p>
                        <p>
                            {createGoalDeliveryString(dailyDelivered, goal)} today /{' '}
                            {createRemainingDeliveryString(dailyCap, dailyDelivered, goal)}{' '}
                            remaining today
                        </p>
                    </span>
                ) : null}
            </div>
            <div className="ef3-campaign-delivery__fill-progress">
                {totalTarget ? (
                    <FillProgressBar type={goal} goal={totalTarget} current={totalDelivered} />
                ) : null}
            </div>
        </div>
    );
}

function StatsTable(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const { statsTableData, summary, ad, status, campaignId, isLoading } = props;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const popoverRef = useRef();

    return (
        <div className="ef3-campaign-delivery__stats-table">
            <div className={classes.toolbar}>
                {flags.isEnabled('en_2109_ad_summary_fix_height') ? (
                    <div>
                        <Button
                            aria-describedby={id}
                            variant="outlined"
                            color="primary"
                            onClick={handleClick}
                        >
                            Summary
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            action={popoverRef}
                        >
                            <Box m={2} minWidth={300} width={500}>
                                {ad ? (
                                    <AdSummary
                                        adId={ad.id}
                                        campaignId={campaignId}
                                        onDoneLoading={() => popoverRef.current.updatePosition()}
                                    />
                                ) : (
                                    <CampaignSummaryView
                                        title={summary.details.title}
                                        subtitle={summary.details.secondaryTitle}
                                        groups={summary.details.groups}
                                    />
                                )}
                            </Box>
                        </Popover>
                    </div>
                ) : (
                    <PopUp
                        className="ef3-campaign-delivery__summary-popup"
                        position="left"
                        contents={
                            ad ? (
                                <AdSummary adId={ad.id} campaignId={campaignId} />
                            ) : (
                                <CampaignSummaryView
                                    title={summary.details.title}
                                    subtitle={summary.details.secondaryTitle}
                                    groups={summary.details.groups}
                                />
                            )
                        }
                    >
                        <NeutralButton icon={<i className="fa fa-list" />} text="Summary" />
                    </PopUp>
                )}
            </div>
            <StatsSummaryTable
                className="ef3-campaign-delivery__table-body"
                headerItems={statsTableData.headerItems}
                bodyItems={statsTableData.bodyItems}
                isLiveStats={status === 'live'}
                isLoading={isLoading}
            />
        </div>
    );
}

export function getPacingColor(pacingNumber, isEnded) {
    const pacingPercentage = pacingNumber * 100;
    let pacingColor;
    if (isEnded) {
        if (pacingPercentage >= 100) {
            pacingColor = '#6BDF7E';
        } else {
            pacingColor = '#ffbfcc';
        }
    } else {
        if (pacingPercentage >= 90) {
            pacingColor = '#6BDF7E';
        } else if (pacingPercentage >= 80) {
            pacingColor = '#FFCE51';
        } else if (pacingPercentage < 80) {
            pacingColor = '#ffbfcc';
        }
    }

    return pacingColor;
}

export function getProjectedPacingColor(projectedPacingNumber) {
    const projectedPacingPercentage = projectedPacingNumber * 100;
    let projectedPacingColor;

    if (projectedPacingPercentage === 100) {
        projectedPacingColor = '#6BDF7E';
    } else {
        projectedPacingColor = '#ffbfcc';
    }

    return projectedPacingColor;
}

export function getTargetFormat(isCurrencyFormat, value) {
    const isNegative = value < 0;
    const targetFormat = isCurrencyFormat
        ? formatNumber_currency(value)
        : formatNumber_whole(value);
    return isNegative ? targetFormat.replace('$-', '-$') : targetFormat;
}

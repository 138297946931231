import _ from 'lodash';
export function getPOI(obj, poiCategories = [], poiSegments = [], isAllowed = false) {
    if (!obj) return [];

    const { categories, custom_layers, category_layers } = obj;

    // Explicitly handle the case where targeting is effectively disabled:
    if (categories.length === 0 && custom_layers.length === 0 && category_layers.length === 0) {
        return [];
    }
    const shouldShowPreview = isAllowed;
    const adGeoCategoryLayers =
        shouldShowPreview && poiCategories.length > 0
            ? createPOICategoriesPreview(poiCategories)
            : createSummaryString([].concat(categories), 'POI Category', 'POI Categories');
    const adGeoLayers =
        shouldShowPreview && poiSegments.length > 0
            ? createPOICategoriesPreview(poiSegments)
            : createSummaryString(
                  [].concat(category_layers),
                  'Standard POI Segment',
                  'Standard POI Segments'
              );

    const adCustomLayers =
        (!shouldShowPreview || poiSegments.length === 0) &&
        createSummaryString(custom_layers, 'Custom POI Segment', 'Custom POI Segments');

    return [].concat(
        adGeoCategoryLayers,
        adGeoLayers,
        adCustomLayers,
        // customLayerWithPointCount,
        `Retarget - ${obj.retarget}`,
        `Target - ${obj.target}`
    );

    function createSummaryString(list, name, pluralName) {
        if (!list.length) return [];
        return `${list.length} ${list.length > 1 ? pluralName : name}`;
    }

    function createPOICategoriesPreview(poiObj) {
        if (poiObj.length === 0) {
            return [];
        }
        const poiText = poiObj.reduce((accumatedString, currentObj) => {
            const formattedName = _.capitalize(currentObj.name);
            return `${accumatedString}${accumatedString.length > 0 ? ', ' : ''}${
                formattedName ? formattedName : ''
            }`;
        }, '');
        return poiText;
    }
}

import _ from 'lodash';

import validate from 'utils/validate';
import { getValidatorsPOIv2, getCleanlistValidators } from './services/validation-rules';
import { defaultValues } from './services/constants';
import { validatePois } from './services/csv-processing';

export const NAME = 'poisEditor';

export const initialState = {
    poiId: null,
    isEditing: false,
    isInitializing: false,
    isInterpolatingPoints: false,
    fileName: '',
    draft: defaultValues,
    poisProcessing: false,
    poiErrors: [],
    metadataErrors: [],
    showErrors: false,
    showFileErrors: false,
    failedAssets: [],
    csvErrors: {},
};

function initializeDraft2(state, action) {
    const { poi, points, organizationId } = action.payload;

    const nextState = {
        ...state,
        isInitializing: false,
        isInterpolatingPoints: false,
        draft: {
            ...state.draft,
            layerMetadata: {
                ...state.draft.layerMetadata,
                organization: organizationId,
            },
        },
    };

    if (!poi) {
        return {
            ...nextState,
        };
    }

    let timeConstraintForForm;
    if (poi.time_constraint === null) {
        timeConstraintForForm = {
            date_range: [{ start: null, end: null }],
        };
    } else {
        timeConstraintForForm = poi.time_constraint;
    }

    let pointsForForm;
    if (points) {
        pointsForForm = _.map(points, point => ({
            ...point,
            latitude: point.latitude,
            longitude: point.longitude,
        }));
    } else {
        pointsForForm = [];
    }

    return {
        ...nextState,
        draft: {
            layerMetadata: {
                name: poi.name,
                radius: poi.radius,
                time_constraint: timeConstraintForForm,
                organization: poi.organization,
                category_code: poi.category_code,
                status: poi.status,
                manual_activation_until: poi.manual_activation_until,
            },
            audience_type: poi.audience_type,
            points: pointsForForm,
            cleanlist_entries: poi.cleanlist_entries || initialState.draft.cleanlist_entries,
            source: poi.source || initialState.draft.source,
        },
    };
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'POIS_EDITOR__SET_POINTS_ERROR': {
            return {
                ...state,
                poiErrors: action.payload.poiErrors,
            };
        }
        case 'POIS_EDITOR__INIT_START': {
            return {
                ...initialState,
                isInitializing: true,
                isEditing: action.payload.poiId ? true : false,
                poiId: action.payload.poiId,
            };
        }
        case 'POIS_EDITOR__UPDATE_FILENAME': {
            return {
                ...state,
                fileName: action.payload.fileName,
            };
            break;
        }
        case 'POIS_EDITOR__UPDATE_LAYER': {
            const nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    layerMetadata: {
                        ...state.draft.layerMetadata,
                        ...action.payload,
                    },
                },
            };

            let metadataErrors = validate(nextState.draft.layerMetadata, getValidatorsPOIv2());
            let cleanlistErrors = validate(
                _.pick(nextState.draft, 'cleanlist_entries'),
                getCleanlistValidators()
            );

            return {
                ...nextState,
                metadataErrors,
                cleanlistErrors,
            };
        }
        case 'POIS_EDITOR__INIT_END': {
            return initializeDraft2(state, action);
        }
        case 'POIS_EDITOR__INIT_ERROR': {
            return {
                ...state,
                isInitializing: false,
                isInterpolatingPoints: false,
            };
        }
        case 'POIS_EDITOR__SAVE_START': {
            let metadataErrors = validate(state.draft.layerMetadata, getValidatorsPOIv2());
            let cleanlistErrors = validate(
                _.pick(state.draft, 'cleanlist_entries'),
                getCleanlistValidators()
            );

            return {
                ...state,
                isSaving: true,
                showErrors: true,
                metadataErrors,
                cleanlistErrors,
            };
        }
        case 'POIS_EDITOR__SAVE_END': {
            return initialState;
        }
        case 'POIS_EDITOR__SAVE_CLIENT_ERROR': {
            return {
                ...state,
                isSaving: false,
            };
        }
        case 'POIS_EDITOR__SAVE_SERVER_ERROR': {
            return {
                ...state,
                serverSaveErrors: action.payload.err,
            };
        }
        case 'POIS_EDITOR__UPDATE_DRAFT': {
            const nextState = {
                ...state,
                draft: {
                    ...state.draft,
                    ...action.payload,
                },
            };

            let metadataErrors = validate(nextState.draft.layerMetadata, getValidatorsPOIv2());
            let cleanlistErrors = validate(
                _.pick(nextState.draft, 'cleanlist_entries'),
                getCleanlistValidators()
            );

            return {
                ...nextState,
                metadataErrors,
                cleanlistErrors,
            };
        }
        case 'POIS_EDITOR__PROCESS_POINTS': {
            return {
                ...state,
                poisProcessing: true,
                fileName: action.payload.fileName,
            };
        }
        case 'POIS_EDITOR__PROCESS_POINTS__SUCCESS': {
            let points;
            if (action.payload.options.append) {
                points = state.draft.points.concat(action.payload.points);
            } else {
                points = action.payload.points;
            }
            const newCsvErrors = validatePois({ data: points, parseCsv: false });
            return {
                ...state,
                draft: {
                    ...state.draft,
                    points,
                },
                poisProcessing: false,
                poiErrors: [],
                csvErrors: newCsvErrors,
                hasDuplicates: action.payload.hasDuplicates,
                isInterpolatingPoints: false,
            };
        }
        case 'POIS_EDITOR__REMOVE_POINT': {
            const { pointToRemove } = action.payload;
            const poiKey =
                pointToRemove.poiKey ||
                `${pointToRemove.latitude}_${pointToRemove.longitude}_${pointToRemove.address}`;
            const newPoints = _.filter(
                state.draft.points,
                point =>
                    `${point.latitude}${point.longitude}${point.address}` !==
                    `${pointToRemove.latitude}${pointToRemove.longitude}${pointToRemove.address}`
            );
            const newCsvErrors = _.omit(state.csvErrors, poiKey);
            return {
                ...state,
                draft: {
                    ...state.draft,
                    points: newPoints,
                },
                csvErrors: newCsvErrors,
            };
        }
        case 'POIS_EDITOR__PROCESS_POINTS__FAILURE': {
            return {
                ...state,
                poisProcessing: false,
                csvErrors: action.payload.errors,
                showFileErrors: true,
                isInterpolatingPoints: false,
            };
        }
        case 'POIS_EDITOR__INTERPOLATE_POINTS__START': {
            return {
                ...state,
                isInterpolatingPoints: true,
            };
        }
        case 'POIS_EDITOR__INTERPOLATE_POINTS__END': {
            return {
                ...state,
                isInterpolatingPoints: false,
            };
        }
        case 'POIS_EDITOR__SET_FILE_TYPE_ERROR': {
            const payloadFiles = action.payload.files;
            const failedAssets = _.map(payloadFiles, file => {
                return {
                    name: file.name,
                    reason: 'Invalid File Type',
                };
            });
            return {
                ...state,
                failedAssets: failedAssets,
            };
        }
        default: {
            return state;
        }
    }
}

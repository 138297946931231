import _ from 'lodash';

export const NAME = 'reportCampaignsLoader';

const initialState = {
    isLoaded: false,
    isLoading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'REPORT_CAMPAIGNS_LOADER__FETCH_START': {
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            };
        }
        case 'REPORT_CAMPAIGNS_LOADER__FETCH_END': {
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
            };
        }
        case 'REPORT_CAMPAIGNS_LOADER__FETCH_ERROR': {
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
            };
        }
        default:
            return state;
    }
}

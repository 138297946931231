import VError from 'verror';
import _ from 'lodash';

import { createHttp } from 'utils/http';
import Conversions from 'states/resources/conversions/actions';
import notify from 'utils/notify';
import { fetchOverviewPageGraphqlData } from 'pages/campaigns/campaigns-overview/actions';
import campaignActions from 'states/resources/campaigns/actions';

const http = createHttp();

export function openManageConversionsView() {
    return {
        type: 'OVERVIEW_CONVERSIONS__OPEN_MANAGE_CONVERSIONS_VIEW',
        payload: {},
    };
}

export function cancelEdit(campaignId) {
    return initializeConversionsDraft(campaignId);
}

export function changeField({ id, fieldName, value }) {
    return {
        type: 'OVERVIEW_CONVERSIONS__HANDLE_FIELD_CHANGE',
        payload: {
            id,
            fieldName,
            value,
        },
    };
}

export function toggleOptimization(conversion, checked) {
    return {
        type: 'OVERVIEW_CONVERSIONS__TOGGLE_OPTIMIZATION',
        payload: { conversion, checked },
    };
}

export function saveConversions(campaignId) {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: 'OVERVIEW_CONVERSIONS__SAVE_CONVERSION_INIT' });

            const etag = _.get(getState(), `conversionsOverview.campaign._etag`, '');
            const advertiserConversionsDraft = _.get(
                getState(),
                `conversionsOverview.advertiserConversionsDraft`,
                []
            );
            const conversionsDraft = _.get(getState(), `conversionsOverview.draft`);
            const conversionErrors = _.get(getState(), `conversionsOverview.errors`, {});

            if (!conversionsDraft) {
                dispatch({ type: 'OVERVIEW_CONVERSIONS__SAVE_CONVERSION_DRAFT_MISSING' });
                return;
            }

            if (_.keys(conversionErrors).length > 0) {
                dispatch({ type: 'OVERVIEW_CONVERSIONS__SAVE_CONVERSION_VALIDATION_ERRORS' });
                return;
            }

            if (advertiserConversionsDraft.length > 0) {
                const payload = {
                    advertiserConversions: advertiserConversionsDraft,
                };

                await dispatch(campaignActions.update(campaignId, payload, { etag }));
            }

            const payload = {
                conversions: _.map(conversionsDraft, conversion => _.omit(conversion, 'id')),
            };

            await dispatch(Conversions.save(campaignId, payload));

            dispatch({
                type: 'OVERVIEW_CONVERSIONS__SAVE_CONVERSION_SUCCESS',
                payload: {},
            });

            await dispatch(initializeConversionsDraft(campaignId));
            await dispatch(fetchOverviewPageGraphqlData(campaignId));
        } catch (error) {
            dispatch({
                type: 'OVERVIEW_CONVERSIONS__SAVE_CONVERSION_FAIL',
                payload: {},
            });

            notify({
                error: new VError(
                    error,
                    `Failed to save conversions in campaign(id:${campaignId})`
                ),
            });
        }
    };
}

export function deleteConversion(id) {
    return {
        type: 'OVERVIEW_CONVERSIONS__HANDLE_DELETE_CONVERSION',
        payload: {
            id,
        },
    };
}

export function addNewConversion() {
    return {
        type: 'OVERVIEW_CONVERSIONS__HANDLE_ADD_NEW_CONVERSION',
        payload: {},
    };
}

export function initializeConversionsDraft(campaignId) {
    return async (dispatch) => {
        dispatch({
            type: 'OVERVIEW_CONVERSIONS__FETCHING_CONVERSIONS_INIT',
            payload: {},
        });

        try {
            const { campaign, ownOrganization } = await fetchPageData(campaignId);

            dispatch({
                type: 'OVERVIEW_CONVERSIONS__FETCHING_CONVERSIONS_END',
                payload: {
                    campaign,
                    ownOrganization,
                },
            });
        } catch (err) {
            notify({
                error: new VError(err, `Failed to fetch conversions in campaign(id:${campaignId})`),
            });
        }
    };
}

async function fetchPageData(campaignId) {
    const query = `
        query getConversions ($id: Int) {
            campaign(id: $id) {
                id
                budget_allocation_method
                automaticBudgetAllocationOptimizationStrategy
                _etag
                isArchived
                conversions {
                    reporting_name
                    event_name
                    use_view_through
                    use_click_through
                    click_through_attribution_window
                    view_through_attribution_window
                    use_dynamic_data_subevent
                    use_dynamic_data_value
                    dynamic_data_subevent_name
                    dynamic_data_value_name
                    should_optimize_for_budget_allocation
                }
                advertiserConversions {
                    id
                    reporting_name
                    event_name
                    use_view_through
                    use_click_through
                    click_through_attribution_window
                    view_through_attribution_window
                    use_dynamic_data_subevent
                    use_dynamic_data_value
                    dynamic_data_subevent_name
                    dynamic_data_value_name
                    should_optimize_for_budget_allocation
                    _created
                    advertiser {
                        id
                        name
                    }
                }
                ads {
                    rotation_rules {
                        mode
                    }
                    id
                    conversionsForCreativeOptimization
                }
                clients {
                    type
                    organization
                }
            }
            ownOrganization {
                id
            }
        }
    `;

    const variables = {
        id: Number(campaignId),
    };

    const data = await http.graphql(query, variables);

    return data;
}

export function toggleConversionBudgetOptimization(conversion) {
    return {
        type: 'OVERVIEW_CONVERSIONS__ADVERTISER_CONVERSION__TOGGLE_BUDGET_OPTIMIZATION',
        payload: {
            conversion,
        },
    };
}

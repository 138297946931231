import pick from 'lodash/pick';
import { can } from 'services/can';
import constants from '../../../../../config';
import { getEnvironmentSettings } from 'services/environment';
import { PermissionsMapping } from 'common/constants/role-intents';

function getConversionUrls() {
    return pick(getEnvironmentSettings(), ['conversionDomain', 'pixelDomain']);
}

export function getAdvertiserPixel(conversion) {
    const baseUri = constants.ENGAGEFRONT_ADSERVER_THIRDPARTY_PIXEL_BASEURI;

    const pixelData = {
        advertiser_org_id: conversion.advertiser.id,
        event_name: conversion.event_name,
    };

    return `${baseUri}${encodeURIComponent(JSON.stringify(pixelData))}`;
}

export function getJavaScriptTag({ conversion, campaignId, domains }) {
    let conversionIdAttribute;

    if (undefined === domains) {
        domains = getConversionUrls();
    }

    let type = conversion.advertiser ? 'advertiser' : 'campaign';
    if (type === 'advertiser') {
        conversionIdAttribute = conversion.advertiser.id;
    } else if (campaignId) {
        conversionIdAttribute = campaignId;
    } else {
        conversionIdAttribute = conversion.id;
    }
    let dynamic_data_string = '';
    if (conversion.use_dynamic_data_subevent && conversion.dynamic_data_subevent_name) {
        dynamic_data_string = `s.setAttribute("data-conversion-subevent",{{${
            conversion.dynamic_data_subevent_name
        }}});`;
    }
    if (conversion.use_dynamic_data_value && conversion.dynamic_data_value_name) {
        dynamic_data_string += `s.setAttribute("data-conversion-value",{{${
            conversion.dynamic_data_value_name
        }}});`;
    }

    return `<script>(function(){var s=document.createElement('script');s.setAttribute("type","text/javascript");s.setAttribute("data-pixeldomain-value","${
        domains.pixelDomain
    }");s.setAttribute("data-efdomain-value","${domains.conversionDomain}");s.setAttribute("src","${
        domains.conversionDomain
    }/objects/conversion.min.js");s.setAttribute("class","ENGAGE_FRONT");s.setAttribute("data-conversion-id","${conversionIdAttribute}");s.setAttribute("data-conversion-type","${type}");s.setAttribute("data-conversion-event-name","${
        conversion.event_name
    }");${dynamic_data_string}document.currentScript.appendChild(s);})();</script>`;
}

export function getHtmlTag({ conversion, campaignId }) {
    return `<img width="1" height="1" style="display:none" src="${getPixelUrl({
        conversion,
        campaignId,
    })}"/>`;
}

export function getPixelUrl({ conversion, campaignId, domains }) {
    if (domains === undefined) {
        domains = getConversionUrls();
    }

    const basePixelUrl = `${domains.pixelDomain}/x/b?data=`;
    let urlToEncode = {};
    let encodedUrl = '';

    let type = conversion.advertiser ? 'advertiser' : 'campaign';
    if (type === 'campaign' && conversion.event_name && campaignId) {
        urlToEncode['camp_id'] = campaignId;
    } else if (type === 'advertiser' && conversion.event_name && conversion.id) {
        urlToEncode['advertiser_org_id'] = conversion.advertiser.id;
    }
    urlToEncode['event_name'] = conversion.event_name;

    if (conversion.use_dynamic_data_subevent && conversion.dynamic_data_subevent_name) {
        urlToEncode['subevent'] = `{{${conversion.dynamic_data_subevent_name}}}`;
    }
    if (conversion.use_dynamic_data_value && conversion.dynamic_data_value_name) {
        urlToEncode['value'] = `{{${conversion.dynamic_data_value_name}}}`;
    }

    encodedUrl = `${basePixelUrl}${encodeURIComponent(JSON.stringify(urlToEncode))}`;

    encodedUrl = encodedUrl.split('%7B%7B').join('{{');
    encodedUrl = encodedUrl.split('%7D%7D').join('}}');
    encodedUrl = encodedUrl
        .split(`%22{{${conversion.dynamic_data_value_name}}}%22`)
        .join(`{{${conversion.dynamic_data_value_name}}}`);

    return encodedUrl;
}

export function canEditConversion(campaign) {
    if (campaign && campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

import { browserHistory } from 'react-router';
import VError from 'verror';

import c from 'common/constants/flux-events';
import qs from 'utils/querystring';
import notify from 'utils/notify';
import { actions } from './reducer';
import creativesActions from 'states/resources/creatives/actions';
import adActions from 'states/resources/ads/actions';

const initCreativesAdsMw = ({ fetchCreatives, fetchAds }) => ({
    dispatch,
}) => next => async action => {
    next(action);
    if (
        action.type === actions.initCreativesOverview.type ||
        action.type === actions.creativeBulkArchiveSuccess.type ||
        action.type === actions.creativeArchiveSuccess.type ||
        action.type === c.OVERVIEW__CREATIVE_DRAFT_EDIT__SUBMIT_SUCCESS ||
        action.type === c.OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_SUCCESS ||
        action.type === 'CREATIVE_BULK_EDIT_FORM__SUBMIT_SUCCESS' ||
        action.type === 'CREATIVE_BULK_UPLOAD_FORM__SUBMIT_SUCCESS'
    ) {
        const { campaignId } = action.payload;
        try {
            const creatives = await fetchCreatives(dispatch, campaignId);
            const ads = await fetchAds(dispatch, campaignId);

            dispatch(actions.initSuccess({ creatives, ads }));
        } catch (err) {
            notify({
                error: new VError(err, 'Failed to fetch creative/ad data for Creative Overview'),
                metaData: campaignId,
            });
        }
    }
};

const archiveCreativeMw = ({ updateCreative }) => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.confirmArchiveCreative.type) {
        const { campaignId } = action.payload;
        const creativeId = action.payload.creativeToArchive;
        const payload = { isArchived: true };
        try {
            // await dispatch(creativesActions.update(campaignId, creativeId, payload));
            await updateCreative(dispatch, campaignId, creativeId, payload);
        } catch (err) {
            notify({
                error: new VError(err, 'Failed to archive and update creative'),
                metaData: campaignId,
            });
        }
        dispatch(actions.creativeArchiveSuccess({ campaignId }));
    }
};

const bulkArchiveCreativesMw = () => ({ dispatch }) => next => async action => {
    next(action);
    if (action.type === actions.confirmBulkArchiveCreatives.type) {
        const { campaignId } = action.payload;
        const creativeIds = action.payload.selectedRows;
        const payload = _.map(creativeIds, id => {
            return { id, isArchived: true };
        });

        try {
            await dispatch(creativesActions.bulkEdit(campaignId, payload));
        } catch (err) {
            notify({
                error: new VError(err, 'Failed to bulk archive creatives'),
                metaData: campaignId,
            });
        }
        dispatch(actions.creativeBulkArchiveSuccess({ campaignId }));
    }
};
const filterCreativeListMW = () => ({ dispatch, getState }) => next => async action => {
    next(action);
    if (action.type === actions.filterCreativeList2.type) {
        const { campaignId } = action.payload;

        const query = getState().creativesOverviewV2.filterOptions;

        browserHistory.push(`/campaigns/${campaignId}/setup/creatives?${qs.serialize(query)}`);
    }
};

export const makeMiddlewares = deps => {
    return [
        archiveCreativeMw(deps),
        filterCreativeListMW(deps),
        initCreativesAdsMw(deps),
        bulkArchiveCreativesMw(deps),
    ];
};

export const middlewares = makeMiddlewares({
    updateCreative: (dispatch, campaignId, creativeId, payload) => {
        return dispatch(creativesActions.update(campaignId, creativeId, payload));
    },
    fetchCreatives: (dispatch, campaignId) => {
        return dispatch(creativesActions.getAll(campaignId));
    },
    fetchAds: (dispatch, campaignId) => {
        return dispatch(adActions.getAll(campaignId));
    },
});

import c from 'common/constants/flux-events';

import UserActions from 'states/resources/users/actions';
import { createHttp } from 'utils/http';

const useGraphQLToGetOrgAndUsers = orgId => {
    const http = createHttp();
    const query = `
        query getOrgAndUsers ($id: String) {
            organization(id: $id) {
                name
                id
                type
                relationshipToAuthOrganization {
                    _relationship
                }
                users {
                    id
                    first_name
                    last_name
                    email
                    global_role
                    suspended
                    third_party
                }
            }
        }
    `;

    const variables = {
        id: orgId,
    };

    return http.graphql(query, variables);
};

export const adminUsers = {
    init(orgId) {
        return async dispatch => {
            dispatch({
                type: c.ADMIN_USERS__INIT_START,
                payload: { orgId },
            });

            if (orgId) {
                try {
                    const data = await useGraphQLToGetOrgAndUsers(orgId);
                    const ownOrganization = {
                        ...data.organization,
                        _relationship:
                            data.organization.relationshipToAuthOrganization._relationship,
                    };

                    return dispatch({
                        type: 'ADMIN_USERS__INIT_SUCCESS',
                        payload: { orgId, users: data.organization.users, ownOrganization },
                    });
                } catch (err) {
                    dispatch({
                        type: 'ADMIN_USERS__INIT_FAIL',
                        payload: {
                            err: {
                                message: err,
                            },
                        },
                    });
                }
            }
            return Promise.resolve();
        };
    },

    delete(userId, orgId) {
        return dispatch => {
            dispatch({
                type: c.ADMIN_USERS__DELETE_START,
                payload: { userId },
            });

            if (userId) {
                dispatch(UserActions.delete(userId)).then(
                    users => {
                        dispatch({
                            type: c.ADMIN_USERS__DELETE_SUCCESS,
                            payload: { userId, users },
                        });
                        dispatch(adminUsers.init(orgId));
                    },
                    err => {
                        dispatch({
                            type: c.ADMIN_USERS__DELETE_FAIL,
                            payload: { err },
                        });
                    }
                );
            }
        };
    },
};

import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

import makeStyles from '@mui/styles/makeStyles';

import FormField from 'widgets-v5/form-field';
import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';

import { getEnvironmentSettings } from 'services/environment';

import flags from 'containers/flags/service';

import selector from './selector';

import BillingTiers from '../billing-tiers';

const useStyles = makeStyles(theme => ({
    red: {
        color: 'red',
    },
    feesTable: {
        '& > thead > tr > th': {
            border: 'none',
            verticalAlign: 'top',
        },
        '& > tbody > tr > td': {
            border: 'none',
            verticalAlign: 'top',
        },
    },
}));

const Fees = ({
    draft,
    onChange,
    errors,
    showErrors,
    canEditRestrictedFields,
    mode,
    relationship,
    onAddBillingTier,
    onDeleteBillingTier,
    onEditBillingTierMax,
    onEditBillingTierFee,
}) => {
    const environmentSettings = getEnvironmentSettings();
    const classes = useStyles();

    const showFeesField = draft.type === 'media_buyer';
    const isEditingSelf = mode === 'edit' && relationship === 'self';

    return (
        <div>
            <Box mb={3}>
                <Typography gutterBottom variant="h6">
                    Private Deals
                </Typography>
                {!isEditingSelf && (
                    <Box mb={3}>
                        <FormLabel
                            required
                            classes={{
                                asterisk: classes.red,
                            }}
                        >
                            Infrastructure Cost
                        </FormLabel>
                        <FormField isRequired={true}>
                            <TextFieldFormatter
                                initialValue={draft.privateDealInfraCost}
                                onChange={value => onChange('privateDealInfraCost', value)}
                                shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                formatIn={v => {
                                    return _.isNil(v) ? '' : numeral(v).format('0,0.00');
                                }}
                                formatOut={v => {
                                    return v ? numeral(v).value() : 0;
                                }}
                                renderInput={({ value, onChange }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        required
                                        disabled={!canEditRestrictedFields}
                                        helperText={showErrors && errors.privateDealInfraCost}
                                        error={showErrors && errors.privateDealInfraCost}
                                        endAdornment="CPM"
                                        startAdornment={environmentSettings.getCurrencySign()}
                                    />
                                )}
                            />
                        </FormField>
                    </Box>
                )}
                <Box>
                    <FormLabel
                        required
                        classes={{
                            asterisk: classes.red,
                        }}
                    >
                        Tiers
                    </FormLabel>
                    <BillingTiers
                        tiers={draft.privateInventorySpendTiers}
                        onAddBillingTier={() => onAddBillingTier('privateInventorySpendTiers')}
                        onDeleteBillingTier={tierId =>
                            onDeleteBillingTier({
                                type: 'privateInventorySpendTiers',
                                tierId,
                            })
                        }
                        onEditBillingTierMax={({ tierId, value }) => {
                            onEditBillingTierMax({
                                type: 'privateInventorySpendTiers',
                                tierId,
                                value,
                            });
                        }}
                        onEditBillingTierFee={({ tierId, value }) =>
                            onEditBillingTierFee({
                                type: 'privateInventorySpendTiers',
                                tierId,
                                value,
                            })
                        }
                        canEditRestrictedFields={canEditRestrictedFields}
                        showErrors={showErrors}
                        isEditingSelf={isEditingSelf}
                        type="privateInventorySpendTiers"
                        errors={errors}
                    />
                </Box>
            </Box>
            <Box>
                <Typography gutterBottom variant="h6">
                    Public Inventory
                </Typography>
                {!isEditingSelf && (
                    <React.Fragment>
                        <Box mb={2}>
                            <FormLabel
                                required
                                classes={{
                                    asterisk: classes.red,
                                }}
                            >
                                Infrastructure Cost
                            </FormLabel>
                            <FormField isRequired={true}>
                                <TextFieldFormatter
                                    initialValue={draft.publicInventoryInfraCost}
                                    onChange={value => onChange('publicInventoryInfraCost', value)}
                                    shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                    formatIn={v => {
                                        return _.isNil(v) ? '' : numeral(v).format('0,0.00');
                                    }}
                                    formatOut={v => {
                                        return v ? numeral(v).value() : 0;
                                    }}
                                    renderInput={({ value, onChange }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            required
                                            disabled={!canEditRestrictedFields}
                                            helperText={
                                                showErrors && errors.publicInventoryInfraCost
                                            }
                                            error={showErrors && errors.publicInventoryInfraCost}
                                            endAdornment="CPM"
                                            startAdornment={environmentSettings.getCurrencySign()}
                                        />
                                    )}
                                />
                            </FormField>
                        </Box>
                        <Box mb={2}>
                            <FormLabel
                                required
                                classes={{
                                    asterisk: classes.red,
                                }}
                            >
                                Infrastructure Cost Precentage
                            </FormLabel>
                            <FormField isRequired={true}>
                                <TextFieldFormatter
                                    initialValue={draft.infraCostPercentage}
                                    onChange={value => onChange('infraCostPercentage', value)}
                                    shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                    formatIn={v => {
                                        return _.isNil(v)
                                            ? '0'
                                            : numeral(v)
                                                  .multiply(100)
                                                  .format('0.00');
                                    }}
                                    formatOut={v => {
                                        return v ? numeral(v).value() / 100 : 0;
                                    }}
                                    renderInput={({ value, onChange }) => (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            required
                                            disabled={!canEditRestrictedFields}
                                            helperText={showErrors && errors.infraCostPercentage}
                                            error={showErrors && errors.infraCostPercentage}
                                            endAdornment="%"
                                        />
                                    )}
                                />
                            </FormField>
                        </Box>
                    </React.Fragment>
                )}
                <Box>
                    <FormLabel
                        required
                        classes={{
                            asterisk: classes.red,
                        }}
                    >
                        Tiers
                    </FormLabel>
                    <BillingTiers
                        tiers={draft.publicInventorySpendTiers}
                        onAddBillingTier={() => onAddBillingTier('publicInventorySpendTiers')}
                        onDeleteBillingTier={tierId =>
                            onDeleteBillingTier({
                                type: 'publicInventorySpendTiers',
                                tierId,
                            })
                        }
                        onEditBillingTierMax={({ tierId, value }) => {
                            onEditBillingTierMax({
                                type: 'publicInventorySpendTiers',
                                tierId,
                                value,
                            });
                        }}
                        onEditBillingTierFee={({ tierId, value }) =>
                            onEditBillingTierFee({
                                type: 'publicInventorySpendTiers',
                                tierId,
                                value,
                            })
                        }
                        canEditRestrictedFields={canEditRestrictedFields}
                        showErrors={showErrors}
                        isEditingSelf={isEditingSelf}
                        type="publicInventorySpendTiers"
                        errors={errors}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default connect(selector)(Fees);

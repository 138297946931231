import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import {
    getJavaScriptTag,
    getHtmlTag,
    getPixelUrl,
} from 'states/resources/conversions/business-logic';
import { can } from 'services/can';
import { SearchInput } from 'widgets-v6/standard-input';
import selector from './selector';
import actions from '../../actions';
import { PermissionsMapping } from 'common/constants/role-intents';

const useStyles = makeStyles({
    title: {},
    copyButton: {},
    html: {
        whiteSpace: 'pre-wrap',
        backgroundColor: '#FAFAFA',
        fontFamily: "Consolas, Monaco, 'Andale Mono', 'Lucida Console', monospace",
        lineHeight: '1.6em',
        fontSize: 12,
        padding: '0.3em 0.5em 0.3em 0.5em',
        border: '1px solid #E0E0E0',
        borderRadius: 4,
        overflow: 'auto',
        maxHeight: 250,
        position: 'relative',
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

export function IntegratePixelPopover(props) {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState('javascript');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [isCopied, setIsCopied] = useState({
        javascriptTag: false,
        htmlTag: false,
        pixelUrl: false,
    });

    const { textToCopy, reportingName } = props;

    const tabs = [
        {
            label: 'JavaScript Tag',
            value: 'javascript',
        },
        {
            label: 'Other Formats',
            value: 'other',
        },
    ];

    const onChangeTab = (event, value) => {
        setCurrentTab(value);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const destroyCopy = () => {
        setTimeout(() => {
            setIsCopied({
                javascriptTag: false,
                htmlTag: false,
                pixelUrl: false,
            });
        }, 2000);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Button variant="outlined" onClick={handleClick}>
                Integrate Pixel
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box m={2} width="400">
                    <Typography>{reportingName}</Typography>
                    <AppBar position="static" color="inherit" elevation={1}>
                        <Tabs
                            value={currentTab}
                            onChange={onChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            centered
                        >
                            {_.map(tabs, tab => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </Tabs>
                    </AppBar>
                    <div className={classes.pixelPopoverContainer}>
                        {currentTab === 'javascript' ? (
                            <React.Fragment>
                                <Typography>JavaScript Tag</Typography>
                                <pre className={classes.html}>
                                    <code>{textToCopy.javascriptTag}</code>
                                </pre>
                                <Box mt={1} mb={1} className={classes.button}>
                                    <CopyToClipboard
                                        text={textToCopy.javascriptTag}
                                        onCopy={() => {
                                            setIsCopied({
                                                javascriptTag: true,
                                            });
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                destroyCopy();
                                            }}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            {isCopied.javascriptTag
                                                ? 'Copied'
                                                : 'Copy JavaScript Tag'}
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    For Google Tag Manager, copy the Pixel URL code above and follow{' '}
                                    <a
                                        href="https://support.google.com/tagmanager/answer/6107167?hl=en"
                                        target="_blank"
                                    >
                                        Google's instructions
                                    </a>{' '}
                                    for creating new <b>Custom HTML Tags</b>
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography>HTML Tag</Typography>
                                <pre className={classes.html}>
                                    <code>{textToCopy.htmlTag}</code>
                                </pre>
                                <Box mt={1} mb={1} className={classes.button}>
                                    <CopyToClipboard
                                        text={textToCopy.htmlTag}
                                        onCopy={() => {
                                            setIsCopied({
                                                htmlTag: true,
                                            });
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                destroyCopy();
                                            }}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            {isCopied.htmlTag ? 'Copied' : 'Copy HTML Tag'}
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    For Google Tag Manager, copy the Pixel URL code above and follow{' '}
                                    <a
                                        href="https://support.google.com/tagmanager/answer/6107167?hl=en"
                                        target="_blank"
                                    >
                                        Google's instructions
                                    </a>{' '}
                                    for creating new <b>Custom HTML Tags</b>
                                </Typography>
                                <hr />
                                <br />
                                <Typography>Pixel URL</Typography>
                                <pre className={classes.html}>
                                    <code>{textToCopy.pixelUrl}</code>
                                </pre>
                                <Box mt={1} mb={1} className={classes.button}>
                                    <CopyToClipboard
                                        text={textToCopy.pixelUrl}
                                        onCopy={() => {
                                            setIsCopied({
                                                pixelUrl: true,
                                            });
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                destroyCopy();
                                            }}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            {isCopied.pixelUrl ? 'Copied' : 'Copy Pixel URL'}
                                        </Button>
                                    </CopyToClipboard>
                                </Box>
                                <Typography variant="body2" gutterBottom color="textSecondary">
                                    For Google Tag Manager, copy the Pixel URL code above and follow{' '}
                                    <a
                                        href="https://support.google.com/tagmanager/answer/6107167?hl=en"
                                        target="_blank"
                                    >
                                        Google's instructions
                                    </a>{' '}
                                    for creating new <b>Custom Image Tags</b>
                                </Typography>
                            </React.Fragment>
                        )}
                    </div>
                </Box>
            </Popover>
        </div>
    );
}

function ConversionsList(props) {
    const {
        onClickNewConversion,
        onClickEditConversion,
        onClickDeleteConversion,
        conversions,
        isDeleting,
        isInitialized,
    } = props;

    const [conversionToDelete, setConversionToDelete] = useState(null);
    const [searchText, setSearchText] = useState('');

    const handleSearch = _.debounce(text => {
        setSearchText(_.trim(text));
    }, 200);

    const conversionsFiltered = _.filter(conversions, conversion => {
        if (!searchText) {
            return true;
        }

        if (conversion.event_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
            return true;
        }

        if (conversion.reporting_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
            return true;
        }

        return false;
    });

    function handleClickDeleteConversion(conversion) {
        setConversionToDelete(conversion);
    }

    function handleClickDeleteCancel() {
        setConversionToDelete(null);
    }

    async function handleClickDeleteConfim() {
        await onClickDeleteConversion(conversionToDelete);

        setConversionToDelete(null);
    }

    const getColumns = () => {
        return [
            {
                key: 'advertiser',
                header: 'Advertiser',
                alignCell: 'left',
                getBodyContent: ({ conversion }) => conversion.advertiser.name,
            },
            {
                key: 'pixel_name',
                header: 'Pixel Name',
                alignCell: 'left',
                getBodyContent: ({ conversion }) => conversion.event_name,
            },
            {
                key: 'reporting_name',
                header: 'Name',
                alignCell: 'left',
                getBodyContent: ({ conversion }) => conversion.reporting_name,
            },
            {
                key: 'attribution_model',
                header: 'Attribution Model',
                alignCell: 'left',
                getBodyContent: ({ conversion }) => (
                    <React.Fragment>
                        {conversion.use_click_through && <div>Click-through attribution</div>}
                        {conversion.use_view_through && <div>View-through attribution</div>}
                    </React.Fragment>
                ),
            },
            {
                key: 'attribution_window',
                header: 'Attribution Window',
                alignCell: 'left',
                getBodyContent: ({ conversion }) => (
                    <React.Fragment>
                        {conversion.use_click_through && (
                            <div>{conversion.click_through_attribution_window} days</div>
                        )}
                        {conversion.use_view_through && (
                            <div>{conversion.view_through_attribution_window} days</div>
                        )}
                    </React.Fragment>
                ),
            },
            {
                key: 'dynamic_data',
                header: 'Event Parameters',
                alignCell: 'left',
                getBodyContent: ({ conversion }) => (
                    <React.Fragment>
                        <div>
                            {conversion.use_dynamic_data_subevent &&
                                `${conversion.dynamic_data_subevent_name} (parameter)`}
                        </div>
                        <div>
                            {conversion.use_dynamic_data_value &&
                                `${conversion.dynamic_data_value_name} (value)`}
                        </div>
                    </React.Fragment>
                ),
            },
            {
                key: 'actions',
                header: 'Actions',
                alignCell: 'center',
                getBodyContent: ({
                    conversion,
                    onClickEditConversion,
                    handleClickDeleteConversion,
                }) => (
                    <React.Fragment>
                        <IntegratePixelPopover
                            textToCopy={{
                                javascriptTag: getJavaScriptTag({ conversion }),
                                htmlTag: getHtmlTag({ conversion }),
                                pixelUrl: getPixelUrl({ conversion }),
                            }}
                            reportingName={conversion.reporting_name}
                        />
                        {can(PermissionsMapping.PIXEL__EDIT) && (
                            <div>
                                <IconButton
                                    size="small"
                                    onClick={() => onClickEditConversion(conversion)}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() => handleClickDeleteConversion(conversion)}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </div>
                        )}
                    </React.Fragment>
                ),
            },
        ];
    };

    const availableColumns = () => {
        return _.filter(getColumns(), column => column.key !== 'pixel_name');
    };

    return (
        <div>
            <Box mb={2}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <SearchInput
                            placeholder="Search Advertisers Pixels"
                            defaultValue={searchText}
                            onChange={event => handleSearch(event.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        {can(PermissionsMapping.PIXEL__EDIT) && (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={onClickNewConversion}
                            >
                                New Advertiser Pixel
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Paper>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {_.map(availableColumns(), column => (
                                <TableCell key={column.key} align={column.alignCell}>
                                    {column.header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(conversionsFiltered, conversion => (
                            <TableRow key={conversion.id}>
                                {_.map(availableColumns(), column => (
                                    <TableCell key={column.key} align={column.alignCell}>
                                        {column.getBodyContent({
                                            conversion,
                                            onClickEditConversion,
                                            handleClickDeleteConversion,
                                        })}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {!isInitialized && <LinearProgress />}
            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={!!conversionToDelete}
            >
                <DialogTitle>Delete Pixel?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Conversions for this pixel will no longer be tracked.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isDeleting}
                        onClick={handleClickDeleteCancel}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isDeleting}
                        onClick={handleClickDeleteConfim}
                        variant="contained"
                        color="secondary"
                    >
                        <span>Delete </span>
                        {isDeleting && <CircularProgress size={20} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function mapDispatch(dispatch) {
    return {
        onClickNewConversion: () => dispatch(actions.openForm()),
        onClickEditConversion: conversion => dispatch(actions.openForm(conversion)),
        onClickDeleteConversion: conversion => dispatch(actions.deleteConversion(conversion)),
    };
}

export default connect(
    selector,
    mapDispatch
)(ConversionsList);

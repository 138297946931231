import _ from 'lodash';
import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
import Bacon from 'baconjs';
import { fetchCampaign } from 'states/resources/campaigns/actions';
import { fetchReportStats } from 'services/resources/stats';
import { fetchOrganizationTimezone } from '../shared';
import { getEnvironmentSettings } from 'services/environment';

const http = createHttp();
/* Metric Selector */
export function metricSelector_toggleVisiblity(campaignId, metric) {
    return {
        type: c.CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_VISIBILITY,
        payload: { campaignId, metric },
    };
}
export function metricSelector_toggleSelection(campaignId, metric) {
    return {
        type: c.CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_SELECTION,
        payload: { campaignId, metric },
    };
}

/* Controls */
export const controls_setTab = (campaignId, tab) => dispatch => {
    dispatch({
        type: c.CAMPAIGN_REPORT__CONTROLS__SET_TAB,
        payload: { campaignId, tab },
    });
};

export const controls_setUnitType = (campaignId, unitType) => ({
    type: c.CAMPAIGN_REPORT__CONTROLS__SET_UNIT_TYPE,
    payload: { campaignId, unitType },
});

export const controls_setMetricType = (campaignId, metricType) => ({
    type: c.CAMPAIGN_REPORT__CONTROLS__SET_METRIC_TYPE,
    payload: { campaignId, metricType },
});

export const controls_setMetricVisibility = (campaignId, metricType) => ({
    type: c.CAMPAIGN_REPORT__CONTROLS__SET_METRIC_VISIBILITY,
    payload: { campaignId, metricType },
});

export const fetchReportData = campaignId => (dispatch, getState) => {
    fetchComponentData(campaignId, dispatch, getState);
};

/* Pivot Table */

export function pivot_sortColumn(campaignId, column) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__SORT,
        payload: {
            column,
            campaignId,
        },
    };
}
export function pivot_expandRow(campaignId, rowId) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__EXPAND_ROW,
        payload: {
            rowId,
            campaignId,
        },
    };
}
export function pivot_collapseRow(campaignId, rowId) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__COLLAPSE_ROW,
        payload: {
            rowId,
            campaignId,
        },
    };
}
export function pivot_addSplit(campaignId, dimension) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_REPORT__PIVOT_TABLE__ADD_SPLIT,
            payload: {
                dimension,
                campaignId,
            },
        });
    };
}
export function pivot_removeSplit(campaignId, dimension) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_REPORT__PIVOT_TABLE__REMOVE_SPLIT,
            payload: {
                dimension,
                campaignId,
            },
        });
    };
}
export function pivot_updateSplits(campaignId, splits) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_REPORT__PIVOT_TABLE__UPDATE_SPLITS,
            payload: {
                splits,
                campaignId,
            },
        });
    };
}
export function pivot_hideZerosToggle(campaignId) {
    return {
        type: c.CAMPAIGN_REPORT__PIVOT_TABLE__HIDE_ZEROS_TOGGLE,
        payload: {
            campaignId,
        },
    };
}

/* Ad Group */
export const adgroup_toggleGroup = (campaignId, component, grouping, group) => (
    dispatch,
    getState
) => {
    [].concat(group).forEach(group => {
        dispatch({
            type: c.CAMPAIGN_REPORT__ADGROUP__TOGGLE,
            payload: {
                campaignId,
                component,
                grouping,
                group,
            },
        });
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const adgroup_reset = (campaignId, component) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__ADGROUP__RESET,
        payload: {
            campaignId,
            component,
        },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

/* Filters */
export const dimensionFilter_groupingClear = (campaignId, grouping) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR,
        payload: { campaignId, grouping },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const dimensionFilter_groupingClearAll = campaignId => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR_ALL,
        payload: { campaignId },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const scopeFilter_groupingClear = (campaignId, grouping) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__SCOPE_FILTER__CLEAR,
        payload: { campaignId, grouping },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const scopeFilter_groupingClearAll = campaignId => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__SCOPE_FILTER__CLEAR_ALL,
        payload: { campaignId },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export const filterByDateRange = (campaignId, dateRange) => (dispatch, getState) => {
    dispatch({
        type: c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_FILTER,
        payload: { campaignId, dateRange },
    });

    fetchReportData(campaignId)(dispatch, getState);
};

export function updateTimezone(campaignId, timezone) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_TIMEZONE,
            payload: { campaignId, timezone },
        });

        dispatch(fetchReportData(campaignId));
    };
}

/* General */
export function inititalizeReport(campaignId, isClientReportPage) {
    return async (dispatch, getState) => {
        dispatch({
            type: c.CAMPAIGN_REPORT__INIT_STATE,
            payload: {
                campaignId,
            },
        });
        const campaign = await dispatch(fetchCampaign(campaignId));
        const userOrganization = _.get(getState(), 'profile.organizationId');
        const campaignOwner = campaign.owner;
        const isClient = userOrganization !== campaignOwner;
        const organizationTimezone = await fetchOrganizationTimezone(
            _.get(getState(), 'profile.userId')
        );

        dispatch({
            type: c.CAMPAIGN_REPORT__INITIALIZE,
            payload: {
                context: campaignId,
                campaignId,
                isClient,
                beacons: campaign.beacons,
                conversions: campaign.conversions,
                advertiserConversions: campaign.advertiserConversions,
                campaign,
                isClientReportPage,
                organizationTimezone,
            },
        });

        dispatch({
            type: c.CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE_START,
            payload: {
                campaignId,
                campaign,
                isClient,
                isClientReportPage,
            },
        });

        const response = await http.get(`campaigns/${campaignId}/report-scopes`);
        dispatch({
            type: c.CAMPAIGN_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS,
            payload: {
                campaignId,
                response,
                campaign,
            },
        });
        dispatch({
            type: 'REPORT_EXPLORE__SCHEDULED_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS',
            payload: {
                campaignId,
                response,
            },
        });
        fetchComponentData(campaignId, dispatch, getState);
    };
}

export function refreshReport(campaignId) {
    return (dispatch, getState) => {
        let campaign;
        dispatch(fetchCampaign(campaignId))
            .then(_campaign => {
                campaign = _campaign;

                return http.get(`campaigns/${campaignId}/report-scopes`);
            })
            .then(response => {
                dispatch({
                    type: c.CAMPAIGN_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS,
                    payload: {
                        campaignId,
                        response,
                        campaign,
                    },
                });
                dispatch({
                    type: 'REPORT_EXPLORE__SCHEDULED_REPORT__AVAILABLE_ADGROUPS__FETCH_SUCCESS',
                    payload: {
                        campaignId,
                        response,
                    },
                });
            })
            .then(() => {
                fetchComponentData(campaignId, dispatch, getState);
            });
    };
}

const StreamManager = (function() {
    var streams = {};

    return {
        getStream({ componentId, dispatch, timezone, getState }) {
            if (!streams[componentId]) {
                streams[componentId] = new Bacon.Bus();

                streams[componentId]
                    .debounce(100)
                    .flatMapLatest(({ request, component }) => {
                        dispatch({
                            type: c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH,
                            payload: {
                                campaignId: request.match.campaign_id,
                                component,
                            },
                        });
                        switch (component.type) {
                            case 'pivot':
                            case 'dimension':
                            case 'overview':
                            case 'heatmap':
                            case 'scope': {
                                const fetchData = () => {
                                    let match = {
                                        ..._.omit(request.match.dimensions, [
                                            'campaign_id',
                                            'cacheKey',
                                            'cache',
                                        ]),
                                        ..._.omit(request.match.scopes, ['cacheKey', 'cache']),
                                    };

                                    const { viewBy } = getState().campaignPageContainer;
                                    if (viewBy && viewBy !== 'campaign') {
                                        match.flight_id = [viewBy];
                                    }

                                    const promise = fetchReportStats({
                                        campaign_id: request.match.campaign_id,
                                        start: request.start,
                                        end: request.end,
                                        timeframe: request.timeframe,
                                        timezone: request.timezone,
                                        match,
                                        split: request.groups.scope
                                            ? [request.groups.scope]
                                            : request.groups.dimensions,
                                        datasource: getEnvironmentSettings().getReportingDatasource(),
                                        isReportPage: true,
                                        queryTag: 'ef_campaign_report',
                                    }).then(response => ({
                                        response,
                                        request,
                                        component,
                                        timezone,
                                    }));

                                    return Bacon.fromPromise(promise, true);
                                };

                                return Bacon.retry({
                                    source: fetchData,
                                    retries: 2,
                                    delay() {
                                        return 1000;
                                    },
                                });
                            }

                            default: {
                                console.warn('Unsupported component type');
                            }
                        }
                    })
                    .onValue(function(result) {
                        dispatch({
                            type: c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH__SUCCESS,
                            payload: {
                                campaignId: result.request.match.campaign_id,
                                response: result.response,
                                component: result.component,
                                timezone: result.timezone,
                            },
                        });
                    });
            }

            return streams[componentId];
        },
    };
})();

export function fetchComponentData(campaignId, dispatch, getState, filter = () => true) {
    const reportSession = _.get(getState(), `reportExplore`);
    const dateRange = _.get(getState(), `reportExplore.dateRange`);
    const timezone = _.get(getState(), `reportExplore.dateRange.timezone`, 'UTC');

    const components = _.get(reportSession, 'components.items', [])
        .filter(filter)
        .filter(c => c.type !== 'pivot');
    const dimensionMatch = _.get(reportSession, 'dimensionFilter', {});
    const scopeMatch = _.get(reportSession, 'scopeFilter', {});

    components.forEach(component => {
        const request = createRequest(campaignId, dimensionMatch, scopeMatch, component, dateRange);

        StreamManager.getStream({
            componentId: component.id,
            dispatch,
            timezone,
            getState,
        }).push({ request, component });
    });
}

function createRequest(campaignId, dimensionMatch, scopeMatch, component, dateRange) {
    const isScope = component.type === 'scope';
    const groupType = isScope ? 'scope' : 'dimensions';
    let groupValue = isScope ? _.first([].concat(component.groupings)) : component.groupings;

    const defaultGroups = {
        dimensions: [],
        scope: null,
    };

    let dateRangePayload = {
        ..._.omit(dateRange, ['cache', 'cacheKey', 'campaignStart', 'campaignEnd', 'selectedKey']),
    };

    return {
        ...dateRangePayload,
        match: {
            campaign_id: campaignId,
            dimensions: dimensionMatch,
            scopes: scopeMatch,
        },
        groups: {
            ...defaultGroups,
            [groupType]: groupValue,
        },
        type: component.type,
    };
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

export class PanelsLayout extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        direction: PropTypes.oneOf(['row', 'column']).isRequired,
        mobileDirection: PropTypes.oneOf(['row', 'column']),
    };

    static defaultProps = {
        className: null,
    };

    render() {
        return (
            <div
                className={classnames(
                    'ef5-panels-layout',
                    `ef5-panels-layout_direction-${this.props.direction}`,
                    `ef5-panels-layout_mobile-direction-${this.props.mobileDirection}`,
                    this.props.padding && 'ef5-panels-layout_padding',
                    this.props.className
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

export class WorkspacePanel extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        id: PropTypes.string,
    };

    static defaultProps = {
        className: null,
        style: null,
    };

    render() {
        return (
            <div
                id={this.props.id || undefined}
                style={this.props.style}
                className={classnames('ef5-workspace-panel', this.props.className)}
            >
                {this.props.children}
            </div>
        );
    }
}

export class ScrollingSidebarPanel extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        className: null,
        style: null,
    };

    render() {
        return (
            <div
                style={this.props.style}
                className={classnames(
                    'ef5-sidebar-panel',
                    'ef5-sidebar-panel_type-scrolling',
                    this.props.className
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

export class OverlaySidebarPanel extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        className: null,
        style: null,
    };

    render() {
        return (
            <div
                style={this.props.style}
                className={classnames(
                    'ef5-sidebar-panel',
                    'ef5-sidebar-panel_type-overlay',
                    this.props.className
                )}
            >
                {this.props.children}
            </div>
        );
    }
}

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { calculateAudienceFee } from 'states/resources/ads/business-logic';
import AudienceSegmentModal from 'containers/audience-segment-picker/modules/audience-segment-modal';

class AudiencesTarget extends React.Component {
    static propTypes = {
        targetDraft: PropTypes.object,
        onDraftChange: PropTypes.func,
        onUnmount: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    render() {
        const {
            targetDraft,
            onDraftChange,
            audienceRates,
            platform,
            campaignId,
            shouldHideFlexSegments,
            platforms,
            audienceWarning,
            isCTVCampaign,
            isDOOHCampaign,
        } = this.props;

        return (
            <AudienceSegmentModal
                platforms={platforms}
                onChangeSegments={({ includedItems, excludedItems, geo_targeting_settings }) => {
                    const audienceFee = calculateAudienceFee({
                        includedAudiences: includedItems,
                        excludedAudiences: excludedItems,
                        audienceRates: audienceRates,
                    });

                    const draft = !shouldHideFlexSegments
                        ? {
                              audiences: _.map(includedItems, item => item.value),
                              audience_exclude: _.map(excludedItems, item => item.value),
                              audience_fee: audienceFee,
                              geo_targeting_settings,
                          }
                        : {
                              audiences: _.map(includedItems, item => item.value),
                              audience_exclude: _.map(excludedItems, item => item.value),
                              audience_fee: audienceFee,
                          };
                    const errors = [];
                    if (_.concat(draft.audiences, draft.audience_exclude,
                        draft.geo_targeting_settings.categories,
                        draft.geo_targeting_settings.category_layers,
                        draft.geo_targeting_settings.custom_layers).length === 0) {
                        errors.push('At least one segment has to be selected');
                    }
                    onDraftChange({
                        draft,
                        errors: errors,
                    });
                }}
                geo_targeting_settings={targetDraft && targetDraft.geo_targeting_settings}
                audiences={targetDraft && targetDraft.audiences}
                audienceExclude={targetDraft && targetDraft.audience_exclude}
                audienceRates={audienceRates}
                platform={platform}
                campaignId={campaignId}
                shouldHideFlexSegments={shouldHideFlexSegments}
                audienceWarning={audienceWarning}
                isCTVCampaign={isCTVCampaign}
                isDOOHCampaign={isDOOHCampaign}
            />
        );
    }
}

function mapState(storeState, ownProps) {
    let platform = _.get(storeState, 'adForm.form.draft.platform');
    if (ownProps.platform) {
        platform = ownProps.platform;
    }

    return {
        platform,
    };
}

export default connect(mapState)(AudiencesTarget);

import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';

const AdGroupings = {
    getTemplateCatelog(campaignId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE_CATELOG__FETCH,
                payload: {
                    campaignId,
                },
            });

            return http()
                .get(`campaigns/${campaignId}/adgroupings/templates`)
                .then(
                    adGroupingsTemplateCatelog => {
                        dispatch({
                            type:
                                c.OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE_CATELOG__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                adGroupingsTemplateCatelog,
                            },
                        });
                        return adGroupingsTemplateCatelog;
                    },
                    err => {
                        console.error(`GET adgroup template catelog error`, err);

                        throw err;
                    }
                );
        };
    },

    getTemplate(campaignId, templateId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE__FETCH,
                payload: {
                    campaignId,
                    templateId,
                },
            });

            return http()
                .get(`campaigns/${campaignId}/adgroupings/templates/${templateId}`)
                .then(
                    adGroupingsTemplate => {
                        dispatch({
                            type:
                                c.OVERVIEW_ADS_GROUPINGS__DRAFT__GROUPING_TEMPLATE__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                templateId,
                            },
                        });
                        return adGroupingsTemplate;
                    },
                    err => {
                        console.error(`GET adgroup template error`, err);

                        throw err;
                    }
                );
        };
    },

    getAll(campaignId) {
        return (dispatch, getState) => {
            dispatch({
                type: c.SYSTEM__CAMPAIGN_ADS_GROUPINGS__FETCH,
                payload: {
                    campaignId,
                },
            });

            return http()
                .get(`campaigns/${campaignId}/adgroupings`)
                .then(
                    adGroupings => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_ADS_GROUPINGS__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                adGroupings,
                            },
                        });
                        return adGroupings;
                    },
                    err => {
                        console.error(`GET ALL adgroup error`, err);

                        throw err;
                    }
                );
        };
    },

    create(campaignId, adgroupings) {
        return (dispatch, getState) => {
            return http()
                .post(`campaigns/${campaignId}/adgroupings`, adgroupings)
                .then(
                    adGrouping => {
                        return adGrouping;
                    },
                    err => {
                        console.error(`CREATE adgroup error`, err);

                        throw err;
                    }
                );
        };
    },

    patch(campaignId, groupingId, adgroupings) {
        return (dispatch, getState) => {
            return http()
                .patch(`campaigns/${campaignId}/adgroupings/${groupingId}`, adgroupings)
                .then(
                    adGrouping => {
                        return dispatch(AdGroupings.getAll(campaignId));
                    },
                    err => {
                        console.error(`PATCH adgroup error`, err);

                        throw err;
                    }
                );
        };
    },

    delete(campaignId, groupingId) {
        return (dispatch, getState) => {
            return http()
                .del(`campaigns/${campaignId}/adgroupings/${groupingId}`)
                .then(
                    response => {
                        return dispatch(AdGroupings.getAll(campaignId));
                    },
                    err => {
                        console.error(`DELETE adgroup error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default AdGroupings;

import last from 'lodash/last';
import slice from 'lodash/slice';
import get from 'lodash/get';
import each from 'lodash/each';
import omit from 'lodash/omit';
import assign from 'lodash/assign';
import map from 'lodash/map';
import 'moment';

import c from 'common/constants/flux-events';

export const NAME = 'resources.stats.campaigns.report.explore';
export default function report(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH: {
            const { campaignId, component } = action.payload;
            const report = get(state, campaignId, {});

            return {
                ...state,
                [campaignId]: {
                    ...report,
                    [component.id]: {
                        ...report[component.id],
                        isLoading: true,
                    },
                },
            };
        }
        case c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH__SUCCESS: {
            const { campaignId, component, response, timezone } = action.payload;

            // HACK
            const _response =
                component.id.indexOf('hour|day_of_week') > -1
                    ? mapToHeatmap(response, campaignId, timezone)
                    : response;

            const report = get(state, campaignId, {});

            return {
                ...state,
                [campaignId]: {
                    ...report,
                    [component.id]: {
                        ..._response,
                        isLoading: false,
                    },
                },
            };
        }
        default:
            return state;
    }
}

function mapToHeatmap(response, campaignId) {
    function transformStat(stat) {
        const group = {};
        each(stat.split, split => {
            group[split.dimension] = split.group;
        });

        const statWithoutStats = omit(stat, ['stats', 'split']);

        return assign({}, statWithoutStats, {
            group,
        });
    }

    function flattenStats(stats) {
        if (!stats) {
            return [];
        }

        function reducer(acc, stat) {
            if (stat.stats.length > 0) {
                return acc.concat(flattenStats(stat.stats));
            } else {
                return acc.concat(transformStat(stat));
            }
        }

        return _(stats).reduce(reducer, []);
    }

    function getDistinctValues(stats) {
        if (!stats || stats.length === 0) {
            return;
        }

        return {
            [last(stats[0].split).dimension]: map(stats, stat => last(stat.split).group),
        };
    }

    const stats = flattenStats(response.stats);

    return {
        campaign_id: campaignId,
        distinctValues: {
            hour: slice(getDistinctValues(response.stats), 0, -1),
            day_of_week: slice(getDistinctValues(get(response, 'stats.0.stats')), 0, -1),
        },
        group: ['hour', 'day_of_week'],
        stats,
    };
}

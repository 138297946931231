import createHttp from 'utils/http';

export const fetchOrganizationTimezone = async userId => {
    const http = createHttp();
    const query = `
                    query getUser ($id: String) {
                        user (id: $id) {
                            org {
                                default_timezone
                            }
                        }
                    }
            `;

    const variables = {
        id: userId,
    };

    const data = await http.graphql(query, variables);

    return data.user.org.default_timezone;
};

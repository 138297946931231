import _ from 'lodash';
import c from 'common/constants/flux-events';
import qs from 'utils/querystring';

const parseRoute = path => /^\/dashboard/.test(path);

export const NAME = 'dashboard';

import { STATS_VIEWS } from './constants';

const getInitialState = () => {
    return {
        pageIndex: {},
        maxResults: 0,
        resultsPerPage: 10,
        isLoading: true,
        filterUrl: '/dashboard',
        adGroupingsExpanded: {},
        adsAndAdGroupingsLoaded: false,
        filters: {
            page: 1,
            search: null,
            status: [],
            sort: null,
            category: [],
            creative_type: [],
            client: [],
            campaignCreatorId: [],
            archived: '',
        },
        campaigns: [],
        childOrganizations: [],
        ownOrganization: {},
        hiddenColumns: {},
        fetchingHiddenColumns: false,
        userEtag: null,
        isFilterPopUpOpen: false,
        statsView: STATS_VIEWS.FLIGHT,
    };
};

export default function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case 'CAMPAIGN_DASHBOARD:SEARCH:SUCCESS': {
            const { campaigns, currentPage, maxResults } = action.payload;

            return {
                ...state,
                maxResults,
                isLoading: false,
                pageIndex: {
                    ...state.pageIndex,
                    [currentPage]: campaigns,
                },
                filters: {
                    ...state.filters,
                    page: currentPage,
                },
            };
        }
        case 'CAMPAIGN_DASHBOARD__USER_PREFERENCES_AND_ETAG': {
            return {
                ...state,
                fetchingHiddenColumns: true,
            };
        }
        case 'CAMPAIGN_DASHBOARD__USER_PREFERENCES_AND_ETAG_FETCH__SUCCESS': {
            const { hiddenColumns, etag } = action.payload;
            const newHiddenColumns = _.chain(hiddenColumns)
                .map(field => ({
                    field,
                    value: true,
                }))
                .keyBy('field')
                .mapValues('value')
                .value();

            return {
                ...state,
                hiddenColumns: newHiddenColumns,
                userEtag: etag,
                fetchingHiddenColumns: false,
            };
        }
        case 'CAMPAIGN_DASHBOARD__USER_PREFERENCES_AND_ETAG_FETCH__FAIL': {
            return {
                ...state,
                hiddenColumns: {
                    campaign_budget: true,
                },
                fetchingHiddenColumns: false,
            };
        }
        case '@@router/LOCATION_CHANGE': {
            const isDashboardUrl = parseRoute(action.payload.pathname);

            if (!isDashboardUrl) {
                return state;
            }

            return {
                ...state,
                filterUrl: `${action.payload.pathname}${action.payload.search}`,
            };
        }

        case 'CAMPAIGN_DASHBOARD__TOGGLE_COLUMN': {
            const { column } = action.payload;
            return {
                ...state,
                hiddenColumns: {
                    ...state.hiddenColumns,
                    [column]: !state.hiddenColumns[column],
                },
            };
        }

        case c.CAMPAIGN_DASHBOARD__FETCH: {
            return {
                ...state,
                adsAndAdGroupingsLoaded: false,
                isLoading: true,
            };
        }

        case c.CAMPAIGN_DASHBOARD__FETCH__SUCCESS: {
            const { campaigns, currentPage, maxResults } = action.payload;

            const currentCampaigns = _.map(campaigns, campaign => campaign.id);

            return {
                ...state,
                maxResults,
                isLoading: false,
                pageIndex: {
                    ...state.pageIndex,
                    [currentPage]: currentCampaigns,
                },
                filters: {
                    ...state.filters,
                    page: currentPage,
                },
            };
        }

        case 'CAMPAIGN_DASHBOARD__DELETE_FILTER': {
            const { field, value } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: 1,
                    [field]: _.filter(state.filters[field], stateValue => stateValue !== value),
                },
            };
        }

        case 'CAMPAIGN_DASHBOARD__ADD_FILTERS': {
            const filters = _.pick(action.payload, _.keys(state.filters));
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: 1,
                    ...filters,
                },
            };
        }

        case 'CAMPAIGN_DASHBOARD__APPLY_FILTERS': {
            const filters = _.omitBy(
                {
                    ...state.filters,
                    ...action.payload,
                },
                value => {
                    return value !== null && value !== undefined && value !== '';
                }
            );
            const nextFilters = _.omit(filters, 'page');

            const currentFilters = _.omit(state.filters, 'page');

            // if the filters change, should purge pageIndex
            const pageIndex = _.isEqual(nextFilters, currentFilters) ? state.pageIndex : {};

            return {
                ...state,
                pageIndex,
                filterUrl: `/dashboard?${qs.serialize(filters)}`,
                filters: {
                    ...state.filters,
                    page: 1,
                    ...action.payload,
                },
                isFilterPopUpOpen: false,
            };
        }

        case 'CAMPAIGN__DASHBOARD__SET__PAGINATION__FILTERS': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    page: 1,
                    ...action.payload,
                },
            };
        }

        case 'CAMPAIGN_DASHBOARD__CHANGE_PAGE': {
            return {
                ...state,
                isLoading: true,
            };
        }

        case c.CAMPAIGN_DASHBOARD__AD_GROUPING_EXPAND:
            return {
                ...state,
                adGroupingsExpanded: {
                    ...state.adGroupingsExpanded,
                    [action.payload.primaryAdGroupingId]: true,
                },
            };

        case c.CAMPAIGN_DASHBOARD__AD_GROUPING_COLLAPSE:
            return {
                ...state,
                adGroupingsExpanded: {
                    ...state.adGroupingsExpanded,
                    [action.payload.primaryAdGroupingId]: false,
                },
            };

        case c.CAMPAIGN_DASHBOARD__FETCH__ADGROUPINGS:
            return state;

        case c.CAMPAIGN_DASHBOARD__FETCH__ADGROUPINGS__SUCCESS:
            return {
                ...state,
                adsAndAdGroupingsLoaded: true,
            };

        case 'CAMPAIGN_DASHBOARD__SET_OWN_AND_CHILD_ORGANIZATION_DATA':
            const { childOrganizations, ownOrganization } = action.payload;
            const childOrganizationsLookup = _.reduce(
                childOrganizations,
                (lookupObj, organization) => {
                    lookupObj[organization.id] = organization;
                    return lookupObj;
                },
                {}
            );
            return {
                ...state,
                childOrganizations: childOrganizationsLookup,
                ownOrganization,
            };

        case 'CAMPAIGN_DASHBOARD__OPEN_FILTER_OPTIONS': {
            return {
                ...state,
                isFilterPopUpOpen: true,
            };
        }

        case 'CAMPAIGN_DASHBOARD__CLOSE_FILTER_OPTIONS': {
            return {
                ...state,
                isFilterPopUpOpen: false,
            };
        }
        case 'CAMPAIGN_DASHBOARD__CHANGE_STATS_VIEW': {
            let statsView = action.payload.view;
            return {
                ...state,
                statsView,
            };
        }

        default:
            return state;
    }
}

import $ from 'jquery';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class QuickDialog extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        button: PropTypes.func.isRequired,
        dialog: PropTypes.func.isRequired,
        verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
        horizontalAlign: PropTypes.oneOf(['left', 'center', 'right']),
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        mode: PropTypes.oneOf(['click', 'hover']),
        animate: PropTypes.bool,
        dropUp: PropTypes.bool,
        offsetTop: PropTypes.bool,
    };

    static defaultProps = {
        verticalAlign: 'top',
        horizontalAlign: 'left',
        mode: 'click',
        animate: true,
        dropUp: false,
        offsetTop: false,
    };

    state = {
        isOpen: false,
    };

    handleOutsideInteraction = event => {
        if (!$(event.target).closest(this.refs.root).length) {
            if (this.state.isOpen === true) {
                this.closeDialog();
            }
        }
    };

    componentDidMount() {
        switch (this.props.mode) {
            case 'click':
                $(document).on('click', this.handleOutsideInteraction);
                return;
            case 'hover':
                $(document).on('mouseover', this.handleOutsideInteraction);
                return;
        }
    }

    componentWillUnmount() {
        switch (this.props.mode) {
            case 'click':
                $(document).off('click', this.handleOutsideInteraction);
                return;
            case 'hover':
                $(document).off('mouseover', this.handleOutsideInteraction);
                return;
        }
    }

    /*
     componentWillUpdate (nextProps, nextState) {
         if (this.state.isOpen !== nextState.isOpen) {
             if (nextState.isOpen && this.props.onOpen) {
                 this.props.onOpen();
             } else if (!nextState.isOpen && this.props.onClose) {
                 this.props.onClose();
             }
         }
     },
     */

    openDialog = () => {
        this.setState({ isOpen: true });

        if (this.props.onOpen) {
            this.props.onOpen();
        }
    };

    closeDialog = () => {
        this.setState({ isOpen: false });

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        return (
            <div ref="root" className={classnames('ef4-quick-dialog', this.props.className)}>
                <div className="ef4-quick-dialog__click-area">
                    {this.props.button(this.openDialog, this.closeDialog, this.state.isOpen)}
                    <aside
                        style={{
                            top: `${this.props.top}`,
                            left: `${this.props.left}`,
                            right: `${this.props.right}`,
                            maxHeight: `${this.props.height}`,
                            width: `${this.props.width}`,
                        }}
                        className={classnames(
                            'ef4-quick-dialog__dialog',
                            `ef4-quick-dialog__dialog_${this.props.verticalAlign}-${
                                this.props.horizontalAlign
                            }`,
                            {
                                'ef4-quick-dialog__dialog_is-active': this.state.isOpen,
                                'ef4-quick-dialog__dialog--offset-top-full': this.props.offsetTop,
                                'ef4-quick-dialog__dialog--animate': this.props.animate,
                                'ef4-quick-dialog__dialog--dropup': this.props.dropUp,
                            }
                        )}
                    >
                        {this.props.dialog(this.closeDialog)}
                    </aside>
                </div>
            </div>
        );
    }
}

export default QuickDialog;

import _ from 'lodash';
import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    items: [
        {
            type: 'overview',
            id: _.uniqueId('date_'),
            groupings: ['date'],
            serverName: '',
        },
    ],
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }
        case c.CAMPAIGN_REPORT__INITIALIZE: {
            const { campaign, isClient, isClientReportPage } = action.payload;
            const sharingSettings = campaign.sharing_settings.dimensions;

            const items = initialState.items;

            const visibleComponents = items.filter(config => {
                if (_.includes(['pivot', 'overview', 'scope'], config.type)) {
                    // We always want these components
                    return true;
                }

                // This is where we enforce campaign sharing settings on dimensions.
                // We do the same for metrics in the metricSelector
                const sharingConfig = _.find(sharingSettings, setting => {
                    return setting.name === config.serverName;
                });

                return isClient || isClientReportPage
                    ? _.get(sharingConfig, 'shared', false)
                    : true;
            });

            return {
                ...state,
                items: visibleComponents,
            };
        }

        case c.REPORT_GEO__AVAILABLE_ADGROUPS__FETCH_SUCCESS: {
            const { campaign } = action.payload;

            const scopeComponents = campaign.customDimensions.map(scope => {
                return {
                    id: _.uniqueId(`${scope.id}_`),
                    title: scope.name,
                    type: 'scope',
                    groupings: [scope.id],
                };
            });

            const componentsWithoutScopes = state.items.filter(c => c.type !== 'scope');

            const nextItems = componentsWithoutScopes.concat(scopeComponents);

            return {
                ...state,
                items: nextItems,
            };
        }

        default:
            return state;
    }
}

import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import DialogContent from '@mui/material/DialogContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { BlockLoadGroup } from 'widgets-v5/load-group';
import { GlobalModal } from 'widgets-v5/modal';
import { MultiSelect, SingleSelect } from 'widgets-v6/select';
import { TextField } from 'widgets-v6/standard-input';

import actions from './actions';
import Editor from './modules/editor';
import UserInput from './modules/user-input';
import selector from './selector';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2),
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50,
    },
    closeIcon: {
        marginRight: 5,
    },
    cursorSelect: {
        cursor: 'pointer',
    },
}));

function AppsAndSites({
    dispatch,
    appLists,
    blackList,
    appListId,
    appList,
    loadingId,
    isBlacklisted,
    isLoading,
    isSyncing,
    syncError,
    showErrors,
    errors,
    draft,
    viewState,
    onCloseRedirectPath,
    isEditorOpen,
    isAppListsLoading,
    isBlackListLoading,
    isPaginationLoading,
    errorMapping,
    invalidBundleAndDomainIDs,
    advertiserOptions,
    filters,
    ownOrganization,
}) {
    const classes = useStyles();

    const handleToggleBlacklistIcon = rowId => {
        dispatch(actions.updateBlackList(rowId));
    };

    const handleClickOpen = (appListId, blackList) => {
        dispatch(actions.openAppListEditor(appListId, blackList));
        dispatch(actions.getApplist(appListId));
    };

    const handleCreateNewApplist = () => {
        dispatch(actions.openAppListEditorNew());
    };

    const handleClose = () => {
        dispatch(actions.closeAppListEditor());
    };

    useEffect(() => {
        dispatch(actions.getBlackList());
        dispatch(actions.getApplists());
    }, [1]);

    return (
        <Container maxWidth="lg">
            <Toolbar>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">Apps & Sites</Typography>
                    <Grid item xs={8}>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                            <Grid item xs={5}>
                                <Filters advertiserOptions={advertiserOptions} filters={filters} />
                            </Grid>
                            <Grid item>
                                <Button
                                    startIcon={<AddIcon />}
                                    variant="contained"
                                    onClick={handleCreateNewApplist}
                                    color="primary"
                                >
                                    New App & Site List
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <div className={classes.root}>
                <TableList
                    rows={appLists}
                    blackList={blackList}
                    handleClickOpen={handleClickOpen}
                    onToggleBlacklistIcon={handleToggleBlacklistIcon}
                    isLoading={isAppListsLoading}
                    isBlackListLoading={isBlackListLoading}
                    loadingId={loadingId}
                    advertiserOptions={advertiserOptions}
                />
                {isEditorOpen && (
                    <ApplistEdit
                        dispatch={dispatch}
                        appLists={appLists}
                        appList={appList}
                        appListId={appListId}
                        blackList={blackList}
                        isLoading={isLoading}
                        isBlacklisted={isBlacklisted}
                        isBlackListLoading={isBlackListLoading}
                        isPaginationLoading={isPaginationLoading}
                        isSyncing={isSyncing}
                        syncError={syncError}
                        showErrors={showErrors}
                        errors={errors}
                        draft={draft}
                        viewState={viewState}
                        onCloseRedirectPath={onCloseRedirectPath}
                        handleClose={handleClose}
                        errorMapping={errorMapping}
                        invalidBundleAndDomainIDs={invalidBundleAndDomainIDs}
                        advertiserOptions={advertiserOptions}
                        ownOrganization={ownOrganization}
                    />
                )}
            </div>
        </Container>
    );
}

const filterOptions = [
    {
        label: 'Advertiser',
        component: ({ filters, handleChange, advertiserOptions }) => (
            <MultiSelect
                placeholder="Filter by advertiser"
                value={filters.advertiserIds}
                onChange={value => handleChange({ advertiserIds: value })}
                options={advertiserOptions}
            />
        ),
    },
];

const Filters = ({ filters, advertiserOptions }) => {
    const dispatch = useDispatch();

    const handleChange = selectedFilters => {
        dispatch(actions.applyFilters(selectedFilters));
    };
    return (
        <React.Fragment>
            {_.map(filterOptions, (filter, index) => (
                <FormControl margin="none" key={index} fullWidth>
                    {filter.component({
                        filters,
                        handleChange,
                        advertiserOptions,
                    })}
                </FormControl>
            ))}
        </React.Fragment>
    );
};

const headers = [
    {
        label: 'Default Blacklist',
        align: 'left',
        width: '130px',
    },
    {
        label: 'Name',
        align: 'left',
    },
    {
        label: 'Advertiser',
        align: 'left',
    },
    {
        label: 'Actions',
        align: 'center',
    },
];

function TableList({
    rows,
    blackList,
    handleClickOpen,
    onToggleBlacklistIcon,
    isLoading,
    loadingId,
    isBlackListLoading,
    advertiserOptions,
}) {
    const classes = useStyles();

    const getAdvertiserName = advertiserId => {
        const matchedAdvertiser = _.find(
            advertiserOptions,
            option => option.value === advertiserId
        );
        if (matchedAdvertiser) {
            return matchedAdvertiser.label;
        }
        return null;
    };
    return (
        <Paper>
            <Table aria-label="simple table" size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        {_.map(headers, header => (
                            <TableCell
                                key={header.label}
                                align={header.align}
                                width={header.width}
                                size={'medium'}
                            >
                                {header.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {!isLoading && (
                    <TableBody>
                        {_.map(rows, row => (
                            <TableRow key={row.id}>
                                <TableCell align="center">
                                    <BlackListIcon
                                        id={row.id}
                                        loadingId={loadingId}
                                        blackList={blackList}
                                        isBlackListLoading={isBlackListLoading}
                                        onToggleBlacklistIcon={onToggleBlacklistIcon}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {getAdvertiserName(row.advertiserId)}
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => {
                                            handleClickOpen(row.id, blackList);
                                        }}
                                        size="large"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
            {isLoading && (
                <div className={classes.progress}>
                    <CircularProgress />
                </div>
            )}
        </Paper>
    );
}

function BlackListIcon({ id, loadingId, blackList, isBlackListLoading, onToggleBlacklistIcon }) {
    const classes = useStyles();

    if (id === loadingId && isBlackListLoading) {
        return <CircularProgress />;
    }

    const StarIconComponent = blackList && blackList.includes(id) ? StarIcon : StarBorderIcon;
    return (
        <StarIconComponent
            className={classes.cursorSelect}
            color="primary"
            onClick={() => onToggleBlacklistIcon(id)}
        />
    );
}

function ApplistEdit(props) {
    const {
        dispatch,
        isLoading,
        isBlacklisted,
        isSyncing,
        syncError,
        showErrors,
        errors,
        draft,
        viewState,
        onCloseRedirectPath,
        appListId,
        appList,
        handleClose,
        isPaginationLoading,
        errorMapping,
        invalidBundleAndDomainIDs,
        advertiserOptions,
        ownOrganization,
    } = props;

    const classes = useStyles();

    const onFieldChange = useMemo(() => {
        return _.debounce((fieldName, fieldValue) => {
            const { dispatch } = props;
            const appListId = _.get(props, 'appListId', void 0);

            const newFormData = { [fieldName]: fieldValue };
            dispatch(actions.updateDraft(newFormData, appListId));
        }, 500);
    }, []);

    if (isSyncing === false && isLoading === true) {
        return (
            <GlobalModal isOpen={true} onClose={handleClose}>
                <BlockLoadGroup isLoading={true} />
            </GlobalModal>
        );
    }

    let Contents;

    if (viewState === 'inputing') {
        Contents = UserInput;
    }
    if (viewState === 'editing') {
        Contents = Editor;
    }

    if (!Contents) {
        return (
            <GlobalModal isOpen={true} onClose={handleClose}>
                <div className="ef6-alignment__center" style={{ width: 500, height: 300 }}>
                    <BlockLoadGroup isLoading={true} />
                </div>
            </GlobalModal>
        );
    }

    const handleNext = () => {
        const { appListId } = props;
        const { bundleIdInput, domainInput, connectedTVIdsInput, doohBoardIdsInput } = draft;

        const data = {
            bundleIdInput,
            domainInput,
            connectedTVIdsInput,
            doohBoardIdsInput,
        };

        props.dispatch(actions.sync({ appListId, data }));
    };

    const handleBlacklistChange = () => {
        const { isBlacklisted } = props;
        const newFormData = { ['isBlacklisted']: isBlacklisted };
        props.dispatch(actions.updateDraft(newFormData, appListId));

        props.dispatch(actions.updateBlacklistStatus(isBlacklisted));
    };

    const handleBack = () => {
        handleNext();
    };

    const handleSubmit = () => {
        const appListId = _.get(props, 'appListId', void 0);
        const { isBlacklisted } = props;
        props.dispatch(actions.submit(appListId));
        if (isBlacklisted && !isLoading) {
            //dispatch(actions.updateBlackList(appListId))
        }
    };

    return (
        <Dialog open={true} onClose={handleClose} fullWidth maxWidth="lg">
            <Grid container justifyContent="space-between" alignItems="center">
                <DialogTitle>App & Site List</DialogTitle>
                <div className={classes.closeIcon}>
                    <IconButton onClick={handleClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </Grid>
            <DialogContent dividers>
                {isLoading ? (
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField
                                            autoFocus
                                            key={isLoading}
                                            label="List Name"
                                            error={showErrors && draft.name === ''}
                                            isRequired={true}
                                            defaultValue={draft.name}
                                            onChange={e => onFieldChange('name', e.target.value)}
                                            fullWidth
                                            disableMargin
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SingleSelect
                                            label="Advertiser"
                                            value={draft.advertiserId}
                                            options={advertiserOptions}
                                            onChange={value => onFieldChange('advertiserId', value)}
                                            placeholder="Filter by advertiser"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={isBlacklisted}
                                            onChange={handleBlacklistChange}
                                            name="blacklist_checkbox"
                                        />
                                    }
                                    label="Default Blacklist for new ads"
                                />
                            </Grid>
                        </Grid>
                        <Box my={3}>
                            <Stepper activeStep={viewState === 'inputing' ? 0 : 1}>
                                <Step>
                                    <StepLabel error={showErrors && errorMapping['__appsAndSites']}>
                                        {showErrors && errorMapping['__appsAndSites']
                                            ? 'Resolve errors'
                                            : 'Input Apps and Sites'}
                                    </StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>View Summary</StepLabel>
                                </Step>
                            </Stepper>
                        </Box>
                        {isSyncing ? (
                            <Grid container justifyContent="center">
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <div>
                                {viewState === 'inputing' && (
                                    <UserInput
                                        dispatch={dispatch}
                                        draft={draft}
                                        appListId={appListId}
                                        appList={appList}
                                        syncError={syncError}
                                        showErrors={showErrors}
                                        errors={errors}
                                        onCloseRedirectPath={onCloseRedirectPath}
                                        isSyncing={isSyncing}
                                        isPaginationLoading={isPaginationLoading}
                                        errorMapping={errorMapping}
                                        ownOrganization={ownOrganization}
                                    />
                                )}
                                {viewState === 'editing' && (
                                    <Editor
                                        dispatch={dispatch}
                                        draft={draft}
                                        appListId={appListId}
                                        appList={appList}
                                        syncError={syncError}
                                        showErrors={showErrors}
                                        invalidBundleAndDomainIDs={invalidBundleAndDomainIDs}
                                        errors={errors}
                                        onCloseRedirectPath={onCloseRedirectPath}
                                        isSyncing={isSyncing}
                                        isPaginationLoading={isPaginationLoading}
                                    />
                                )}
                            </div>
                        )}
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions>
                {showErrors && errorMapping['__appsAndSites'] && (
                    <FormHelperText error>{errorMapping['__appsAndSites']}</FormHelperText>
                )}
                {invalidBundleAndDomainIDs.length ?
                    viewState === 'editing' && (
                        <List
                            dense={true}
                            sx={{
                                width: '100%',
                                maxWidth: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                maxHeight: 75,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                        >
                            <li>
                                <ul>
                                    <ListSubheader>
                                        Resolve invalid Mobile Apps or Sites
                                    </ListSubheader>
                                    <ListItem
                                        secondaryAction={
                                            <FormHelperText error size="small">
                                                {invalidBundleAndDomainIDs.length} errors remaining
                                            </FormHelperText>
                                        }
                                    >
                                        <FormHelperText error>
                                            <ListItemText primary={invalidBundleAndDomainIDs[0]}/>
                                        </FormHelperText>
                                    </ListItem>
                                </ul>
                            </li>
                        </List>
                    ) : " "}
                <Button onClick={handleBack} disabled={viewState === 'inputing'}>
                    Back
                </Button>
                {viewState === 'inputing' ? (
                    <Button onClick={handleNext} color="primary" variant="contained">
                        Next
                    </Button>
                ) : (
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        disabled={errors.length}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default connect(selector)(AppsAndSites);

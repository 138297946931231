export const SOURCE_TO_DASHBOARD_LINKS = {
    pds_brand: '/audiences/brand',
    pds_standard: '/audiences/brand',
    pds_behaviour: '/audiences/brand',
    flex_standard_brand: '/audiences/flex-brand',
    flex_location_category: '/audiences/flex-brand',
    pds_intent: '/audiences/intent',
    pds_manifold: '/audiences/cana-code',
    pds_environics: '/audiences/environics',
    pds_mastercard: '/audiences/mastercard',
    pds_age: '/audiences/age',
    pds_custom: '/audiences/pds-custom',
    flex_custom_brand: '/audiences/flex-custom',
    ad: '/audiences/retargeting',
    campaign: '/audiences/retargeting',
    pixel: '/audiences/retargeting',
    upload: '/audiences/uploaded',
    adobe: '/audiences/adobe',
};

export const TORSTAR_ID = '60240676c48e547c2b3d114d';

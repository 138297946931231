import _ from 'lodash';
import moment from 'moment';
import toastr from 'toastr';
import c from 'common/constants/flux-events';

import { actions } from './redux';
import {
    selectPivotTableRequest,
    selectMetrics,
    selectStats,
    selectSelectedDimensionOptionsFlat,
    selectSelectedExpandedMetrics,
    selectCampaign,
    selectDateRange,
    selectShouldHideZeroRows,
    hideZeroRows,
    selectDimensionDataRequest,
} from './selectors';
import { fetchReportStats } from 'services/resources/stats';
import { graphqlRequest } from 'utils/http/redux';
import http from 'utils/http';
import makeFlatMapLatest from 'utils/flatmaplatest';
import { TIMEOUT_NOTIF_CONFIG, TIMEOUT_IDS } from '../../constants';

const initMw = () => ({ dispatch, getState }) => {
    const flatmaplatest = makeFlatMapLatest();

    return next => async action => {
        next(action);

        if (action.type === c.CAMPAIGN_REPORT__PIVOT_TABLE__INITIALIZE_START) {
            dispatch(actions.init(action.payload.campaignId));
        }
        if (action.type === actions.init.type) {
            const campaignId = action.payload;
            const request = selectPivotTableRequest(getState());
            const res = await dispatch(
                graphqlRequest({
                    query: `
                    query getReportExploreData($campaignId: Int) {
                        campaign(id: $campaignId) {
                            id
                            name
                            organization
                            billing_enabled
                            isUniqueUsersDisabled
                            status
                            start
                            end
                            currency
                            hasAudiences
                            isMediaCostMetricEnabled
                            flightPacingStrategy
                            default_timezone
                            revenueModel
                            customDimensions {
                                id
                                name
                            }
                            beacons {
                                name
                                label
                            }
                            conversions {
                                id
                                event_name
                                reporting_name
                                dynamic_data
                            }
                            advertiserConversions {
                                id
                                event_name
                                reporting_name
                                dynamic_data
                            }
                            sharing_settings {
                                report_tabs {
                                    explore {
                                        dimensions {
                                            name
                                            shared
                                        }
                                    }
                                }
                                metrics {
                                    name
                                    shared
                                }
                            }
                        }
                    }
                `,
                    variables: {
                        campaignId: Number(campaignId),
                    },
                })
            );

            dispatch(
                actions.initCampaign({
                    campaign: res.data.campaign,
                })
            );

            dispatch(actions.addTimeouts(TIMEOUT_NOTIF_CONFIG));
            flatmaplatest(
                fetchReportStats(request, {
                    mockResponseOnError: false,
                    displayWarnStatsProblemRetryLater: false,
                }),
                (err, stats) => {
                    if (err) {
                        console.error(err);
                    } else {
                        const { campaign } = res.data;

                        toastr.clear();
                        const isClientReportPage = checkIsClientReportPage();
                        dispatch(
                            actions.initSuccess({
                                stats,
                                campaign,
                                isClientReportPage,
                            })
                        );
                    }
                    dispatch(actions.removeTimeouts(TIMEOUT_IDS));
                }
            );
        }

        const re = /\/campaigns\/[0-9]*\/report/;
        const isReportPage = re.test(window.location.pathname);

        const reFTA = /foot-traffic-attribution/;
        const isFTAPage = reFTA.test(window.location.pathname);

        if (
            action.type === actions.addDimension.type ||
            action.type === actions.updateDimensions.type ||
            action.type === c.CAMPAIGN_REPORT__ADGROUP__TOGGLE ||
            action.type === c.CAMPAIGN_REPORT__CONTROLS__SET_ACTIVE_SCOPE_TAB ||
            action.type === c.CAMPAIGN_REPORT__DIMENSION_FILTER__CLEAR ||
            action.type === c.CAMPAIGN_REPORT__SCOPE_FILTER__CLEAR ||
            action.type === c.CAMPAIGN_REPORT__FILTER__DATE_RANGE_FILTER ||
            (action.type === 'campaignPageContainer:changeView' && isReportPage && !isFTAPage)
        ) {
            dispatch(actions.fetchStats(action.payload.campaignId));

            const request = selectPivotTableRequest(getState());
            if (
                action.type === actions.addDimension.type ||
                action.type === actions.updateDimensions.type
            ) {
                dispatch(actions.addTimeouts(TIMEOUT_NOTIF_CONFIG));
            }
            flatmaplatest(
                fetchReportStats(request, {
                    mockResponseOnError: false,
                    displayWarnStatsProblemRetryLater: false,
                }),
                (err, stats) => {
                    if (err) {
                        console.error(err);
                    } else {
                        toastr.clear();
                        dispatch(actions.fetchStatsSuccess(stats));
                    }

                    if (
                        action.type === actions.addDimension.type ||
                        action.type === actions.updateDimensions.type
                    ) {
                        dispatch(actions.removeTimeouts(TIMEOUT_IDS));
                    }
                }
            );
        }
    };
};

const dimensionMw = () => ({ dispatch, getState }) => {
    return next => async action => {
        next(action);

        if (action.type === actions.initializeDimensions.type) {
            const components = action.payload.components;

            // The purpose of this action is to initialize the state inside
            // resources.stats.campaigns.report.explore
            // for each of the dimension components. If we don't initialize it
            // here we will run into issues when fetching this data from the backend.
            for (const component of components) {
                dispatch({
                    type: c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH,
                    payload: {
                        campaignId: action.payload.campaignId,
                        component,
                    },
                });
            }
        }

        if (action.type === actions.fetchDimensionItemData.type) {
            dispatch({
                type: c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH,
                payload: {
                    campaignId: action.payload.campaignId,
                    component: action.payload.component,
                },
            });

            const request = selectDimensionDataRequest(action.payload.component)(getState());

            dispatch(actions.addTimeouts(TIMEOUT_NOTIF_CONFIG));
            const stats = await fetchReportStats(request, {
                    mockResponseOnError: false,
                    displayWarnStatsProblemRetryLater: false,
                });

            dispatch({
                type: c.CAMPAIGN_REPORT__ADGROUP_STATS__FETCH__SUCCESS,
                payload: {
                    campaignId: action.payload.campaignId,
                    response: stats,
                    component: action.payload.component,
                },
            });
            dispatch(actions.removeTimeouts(TIMEOUT_IDS));
        }
    }
}

const exportMw = () => ({ getState }) => next => action => {
    next(action);

    if (action.type === actions.exportTable.type) {
        const fileType = action.payload;
        if (fileType === 'csv') {
            generateCsvFile(getState());
        }
        if (fileType === 'json') {
            generateJsonFile(getState());
        }
        if (fileType === 'xls') {
            generateXlsFile(getState());
        }
    }
};

const metricInterceptorMw = () => ({ dispatch, getState }) => next => action => {
    next(action);
    if (action.type === c.CAMPAIGN_REPORT__METRIC_SELECTOR__TOGGLE_VISIBILITY) {
        const metrics = selectMetrics(getState());
        const metric = _.find(metrics, { name: action.payload.metric.metricType });
        dispatch(actions.toggleMetric(metric));
    }

    if (action.type === actions.toggleSubevent.type) {
        const { changedValue } = action.payload;
        const metrics = selectMetrics(getState());
        const metric = _.find(metrics, { name: changedValue });
        dispatch(actions.toggleMetric(metric));
    }
};

function generateXlsFile(state) {
    const statRoot = selectStats(state);
    const selectedDimensions = selectSelectedDimensionOptionsFlat(state);
    const selectedMetrics = selectSelectedExpandedMetrics(state);
    const campaign = selectCampaign(state);
    const dateRange = selectDateRange(state);
    const shouldHideZeroRows = selectShouldHideZeroRows(state);

    let rows = flattenLeaves(statRoot);
    rows = formatAsRow(rows);
    rows = hideZeroRows({
        rows,
        selectedMetrics,
        shouldHideZeroRows,
    });
    const request = generateXlsRequest({
        statRoot,
        rows,
        selectedMetrics,
        selectedDimensions,
        campaign,
        dateRange,
    });
    http()
        .post('report/excel', request)
        .then(response => {
            download(
                base64ToBlob(
                    response,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ),
                `${campaign.name}.xlsx`
            );
        });
}

function download(blob, name) {
    var link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

function base64ToBlob(base64, _mimetype) {
    const mimetype = _mimetype || '';
    var bytechars = atob(base64);
    var bytearrays = [];
    for (var offset = 0; offset < bytechars.length; offset += 512) {
        var slice = bytechars.slice(offset, offset + 512);
        var bytenums = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            bytenums[i] = slice.charCodeAt(i);
        }
        var bytearray = new Uint8Array(bytenums);
        bytearrays[bytearrays.length] = bytearray;
    }
    return new Blob(bytearrays, { type: mimetype });
}

function generateXlsRequest(args) {
    const { statRoot, rows, selectedMetrics, selectedDimensions, campaign, dateRange } = args;

    const progressNumber = Math.min(
        100,
        Math.round(
            ((moment().valueOf() - moment(campaign.start)) /
                (moment(campaign.end).valueOf() - moment(campaign.start))) *
                100
        )
    );

    const diff = Math.max(0, moment(campaign.end).diff(moment(), 'days'));
    const elapsedLabel = diff === 0 ? '' : `elapsed (${diff} days remaining)`;
    const campaignData = {
        name: campaign.name,
        status: _.capitalize(campaign.status),
        flight:
            moment(campaign.start).format('MMMM Do YYYY') +
            ' - ' +
            moment(campaign.end).format('MMMM Do YYYY'),
        progress: `${progressNumber}% ${elapsedLabel}`,
        exportDate: 'Retrieved ' + moment().format('MMMM Do YYYY'),
        retrievedDate:
            'From ' +
            moment(dateRange.start).format('MMMM Do YYYY') +
            ' to ' +
            moment(dateRange.end).format('MMMM Do YYYY'),
    };

    const columns = _.concat(
        _.map(selectedDimensions, dimension => ({
            name: dimension.value,
            exportLabel: dimension.label,
            formatType: '',
        })),
        _.map(selectedMetrics, metric => {
            return {
                name: metric.name,
                exportLabel: metric.meta && metric.meta.reporting_name ? `${metric.meta.reporting_name}: ${metric.label}` : metric.label,
                formatType: metric.format,
            }
        })
    );
    const stats = _.map(rows, row => {
        const stat = {};

        _.each(row.stat.split, split => {
            stat[split.dimension] = split.label;
        });
        _.each(selectedMetrics, metric => {
            stat[metric.name] = row.stat[metric.name];
        });

        return stat;
    });

    const total = {};
    _.each(selectedDimensions, (dimension, index) => {
        total[dimension.value] = index === 0 ? 'Total' : '';
    });
    _.each(selectedMetrics, metric => {
        total[metric.name] = statRoot[metric.name];
    });

    return {
        campaign: campaignData,
        columns,
        stats,
        totals: [total],
    };
}

function generateJsonFile(state) {
    const statRoot = selectStats(state);
    const selectedMetrics = selectSelectedExpandedMetrics(state);
    const campaign = selectCampaign(state);
    const shouldHideZeroRows = selectShouldHideZeroRows(state);

    let rows = flattenLeaves(statRoot);
    rows = formatAsRow(rows);
    rows = hideZeroRows({
        rows,
        selectedMetrics,
        shouldHideZeroRows,
    });
    const json = formatAsJson({
        rows,
        selectedMetrics,
        campaign,
    });

    generateFile({
        exportString: json,
        exportFormat: 'json',
        fileName: campaign.name,
    });
}

function formatAsJson({ rows, selectedMetrics, campaign }) {
    const records = _.map(rows, row => {
        const record = {
            campaign_id: campaign.id,
            campaign_name: campaign.name,
        };

        _.each(row.stat.split, split => {
            record[split.dimension] = split.label;
        });

        _.each(selectedMetrics, metric => {
            record[metric.name] = row.stat[metric.name] || 0;
        });

        return record;
    });

    return JSON.stringify(records);
}

function generateCsvFile(state) {
    const statRoot = selectStats(state);
    const selectedDimensions = selectSelectedDimensionOptionsFlat(state);
    const selectedMetrics = selectSelectedExpandedMetrics(state);

    let showTotals = false;
    if (selectedDimensions.length === 0) {
        // No selected dimensions so we should just show the total which is in the root of statRoot.
        showTotals = true;
    }

    const campaign = selectCampaign(state);
    const shouldHideZeroRows = selectShouldHideZeroRows(state);

    let rows = showTotals ? [statRoot] : flattenLeaves(statRoot);
    rows = formatAsRow(rows);
    rows = hideZeroRows({
        rows,
        selectedMetrics,
        shouldHideZeroRows,
    });

    rows = formatRowsForCsv({
        rows,
        selectedMetrics,
        campaign,
        selectedDimensions,
    });

    const report = generateCsvText(rows);
    generateFile({
        exportString: report,
        exportFormat: 'csv',
        fileName: campaign.name,
    });
}

function generateCsvText(rows) {
    let text = '';
    _.each(rows, columns => {
        const line = _.map(columns, column => {
            if (_.isString(column)) {
                return `"${column}"`;
            }
            return column;
        }).join(',');

        text += line + '\n';
    });

    return text;
}

function formatAsRow(rows) {
    return _.map(rows, row => ({
        id: row.id,
        stat: row,
        type: 'body',
    }));
}

function flattenLeaves(statRoot) {
    const rows = [];
    _.each(statRoot.stats, stat => {
        if (_.isEmpty(stat.stats)) {
            rows.push(stat);
        } else {
            rows.push(...flattenLeaves(stat));
        }
    });

    return rows;
}

function formatRowsForCsv({ rows, selectedMetrics, campaign, selectedDimensions }) {
    if (_.size(rows) === 0) {
        return [];
    }

    const header = _.concat(
        'Campaign ID',
        'Campaign Name',
        _.map(selectedDimensions, dimension => dimension.label),
        _.map(selectedMetrics, metric => {
            return metric.meta && metric.meta.reporting_name ? `${metric.meta.reporting_name}: ${metric.label}` : metric.label;
        }),
    );

    const body = _.map(rows, row => {
        const dimensions = _.map(row.stat.split, split => {
            return split.label;
        });

        const metrics = _.map(selectedMetrics, metric => {
            if (metric.format === 'percentage') {
                return row.stat[metric.name] ? row.stat[metric.name] * 100 : 0;
            }
            return row.stat[metric.name] || 0;
        });

        return _.concat(campaign.id, campaign.name, dimensions, metrics);
    });

    return [header, ...body];
}

function generateFile({ exportString, exportFormat, fileName }) {
    const mimeType = `text/${exportFormat};charset=utf-8`;
    const defaultFilename = `${fileName}.${exportFormat}`;
    const blob = new Blob([exportString], { type: mimeType });
    download(blob, defaultFilename, true);
}

function checkIsClientReportPage() {
    return _.includes(location.pathname, 'client-report');
}

export const makeMiddlewares = deps => {
    return [initMw(deps), metricInterceptorMw(deps), exportMw(deps), dimensionMw(deps)];
};
export const middlewares = makeMiddlewares({});

/**
 * Role mappings
 */

const rolePermissionsByOrg_incremental = [
    { client: ['analyst'] },
    { co_managed_client: ['media_planner'] },
    { media_buyer: ['campaign_manager'] },
    { admin: ['admin'] },
];

let acc_prev = [];
const rolePermissionsByOrganization = rolePermissionsByOrg_incremental.reduce((acc, value) => {
    const org = Object.keys(value);
    const roles = value[org[0]];
    acc[org] = roles.concat(acc_prev);
    acc_prev = acc[org];
    return acc;
}, {});

// Each values in item will added to values of preceeding item.
// so rolePermissionsByOrganization will contain:
// {
//      client: [analyst],
//      co_manager_client: [analyst, media_planer]
//      media_buyer: [analyst, media_planer, campaign_manager]
//      ... etc
// }
export default rolePermissionsByOrganization;

import _ from 'lodash';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import flags from 'containers/flags/service';
// import Box from '@mui/material/Box';
// import { VariableSizeList } from 'react-window';

import { useReportExplore } from '../../redux';

const useStyles = makeStyles(theme => ({
    headerRow: {
        backgroundColor: theme.palette.grey[50],
    },
    totalRow: {
        backgroundColor: theme.palette.grey[200],
    },
    colSpanBorderElem: {
        position: 'relative',
        borderBottom: 'unset',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '95%',
            left: 10,
            bottom: 0,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        }
    }
}));

function PivotTableContainer() {
    const {
        pivotTableData: { headerRows, totalRows, bodyRows },
        isLoading,
        sortColumn,
        toggleRow,
        togglePixelRow,
        selectedMetrics,
    } = useReportExplore();
    return (
        <PivotTable
            isLoading={isLoading}
            headerRows={headerRows}
            totalRows={totalRows}
            bodyRows={bodyRows}
            onSort={sortColumn}
            onToggleRow={toggleRow}
            onTogglePixelRow={togglePixelRow}
            selectedMetrics={selectedMetrics}
        />
    );
}

function PivotTable(props) {
    const { isLoading, onSort, onToggleRow, headerRows, totalRows, bodyRows, onTogglePixelRow } = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            {isLoading && <LinearProgress />}
            <Table size="small">
                <TableHead>
                    {_.map(headerRows, row => (
                        <TableRow key={row.key} className={classes.headerRow}>
                            {_.map(row.columns, column => (
                                <TableCell
                                    key={column.name}
                                    rowSpan={column.rowSpan}
                                    colSpan={column.colSpan}
                                    onClick={column.sortable ? () => onSort(column) : _.noop}
                                    align={column.align || 'inherit'}
                                    className={column.superHeader && flags.isEnabled('en_4598_new_report_conversion_columns') ? classes.colSpanBorderElem : undefined}
                                >
                                    {column.label}
                                    {column.sortDirection === 'desc' && (
                                        <i className="fa fa-fw fa-caret-down am-pivotTable__sort-icon" />
                                    )}
                                    {column.sortDirection === 'asc' && (
                                        <i className="fa fa-fw fa-caret-up am-pivotTable__sort-icon" />
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {_.map(totalRows, row => (
                        <TableRow key={row.id} className={classes.totalRow}>
                            {_.map(row.columns, column => (
                                <TableCell
                                    key={column.name}
                                    colSpan={column.colSpan}
                                    align={column.align || 'inherit'}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                    {_.map(bodyRows, (row, index) => (
                        <TableRow key={`${row.id}-${index}`}>
                            {_.map(row.columns, (column, colIndex) => (
                                <TableCell
                                    key={column.name}
                                    colSpan={column.colSpan}
                                    align={column.align || 'inherit'}
                                >
                                    <div
                                        style={{
                                            paddingLeft: column.depth ? column.depth * 30 : 0,
                                        }}
                                    >
                                        {colIndex === 0 && row.isTogglable && row.isExpanded && (
                                            <i
                                                className="fa fa-fw fa-minus-square-o"
                                                onClick={() => onToggleRow(row)}
                                            />
                                        )}
                                        {colIndex === 0 && row.isTogglable && !row.isExpanded && (
                                            <i
                                                className="fa fa-fw fa-plus-square-o"
                                                onClick={() => onToggleRow(row)}
                                            />
                                        )}
                                        {row.isRowTogglable &&
                                            column.isColumnTogglable &&
                                            row.isPixelRowExpanded && (
                                                <i
                                                    className="fa fa-fw fa-minus-square-o"
                                                    onClick={() => onTogglePixelRow(row)}
                                                />
                                            )}
                                        {row.isRowTogglable &&
                                            column.isColumnTogglable &&
                                            !row.isPixelRowExpanded && (
                                                <i
                                                    className="fa fa-fw fa-plus-square-o"
                                                    onClick={() => onTogglePixelRow(row)}
                                                />
                                            )}
                                        {!column.hideSubRows || colIndex === 0 ? column.label : ' '}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

// TODO: This is a tentative solution for virtualizing the pivot-table (rows only, not headers)
// It currently works but isn't responsive (i.e. The cell heights and widths are being hard-coded so not using it for now)
// function useResetCache(data) {
//     const ref = React.useRef(null);
//     React.useEffect(() => {
//         if (ref.current !== null && ref.current.resetAfterIndex) {
//             ref.current.resetAfterIndex(0, true);
//         }
//     }, [data]);
//     return ref;
// }

// function PivotTableVirtualized(props) {
//     const { isLoading, onSort, onToggleRow, headerRows, totalRows, bodyRows, selectedMetrics } = props;

//     const classes = useStyles();
//     const headerRefs = useRef({});

//     const tableRef = useResetCache(bodyRows);

//     return (
//         <React.Fragment>
//             {isLoading && <LinearProgress />}
//             <Table size="small">
//                 <TableHead>
//                     {_.map(headerRows, row => (
//                         <TableRow key={row.key} className={classes.headerRow}>
//                             {_.map(row.columns, column => (
//                                 <TableCell
//                                     key={column.name}
//                                     colSpan={column.colSpan}
//                                     onClick={column.sortable ? () => onSort(column) : _.noop}
//                                     align={column.align || 'inherit'}
//                                 >
//                                     {column.label}
//                                     {column.sortDirection === 'desc' && (
//                                         <i className="fa fa-fw fa-caret-down am-pivotTable__sort-icon" />
//                                     )}
//                                     {column.sortDirection === 'asc' && (
//                                         <i className="fa fa-fw fa-caret-up am-pivotTable__sort-icon" />
//                                     )}
//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     ))}
//                 </TableHead>
//                 <TableBody>
//                     {_.map(totalRows, row => (
//                         <TableRow key={row.id} className={classes.totalRow}>
//                             {_.map(row.columns, (column) => {
//                                 return (
//                                 <TableCell
//                                     key={column.name}
//                                     colSpan={column.colSpan}
//                                     align={column.align || 'inherit'}
//                                     ref={(element) => {
//                                         const parsedName = column.name === 'total' ? 'dimension' : column.name;
//                                         headerRefs.current[parsedName] = element;
//                                     }}
//                                     sx={{
//                                         minWidth: column.minWidth
//                                     }}
//                                 >
//                                     {column.label}
//                                 </TableCell>
//                             )})}
//                         </TableRow>
//                     ))}
//                 </TableBody>
//             </Table>
//             <Box
//                 className="table"
//                 minHeight={300}
//                 width="100%"
//             >
//                 <VariableSizeList
//                     height={300}
//                     itemCount={bodyRows.length}
//                     itemSize={() => 30}
//                     width="100%"
//                     itemData={{ bodyRows, headerRefs, onToggleRow, selectedMetrics }}
//                     ref={tableRef}
//                 >
//                     {RenderRow}
//                 </VariableSizeList>
//             </Box>
//         </React.Fragment>
//     )
// }

// const RenderRow = ({ index, style, data: { bodyRows, headerRefs, onToggleRow }}) => {
//     const row = bodyRows[index];

//     return (
//         <TableRow key={row.id} style={{ ...style, width: '101%', display: 'flex', alignItems: 'center' }}>
//             {_.map(row.columns, (column, colIndex) => {
//                 return (<Box
//                     key={column.name}
//                     colSpan={column.colSpan}
//                     align={column.align || 'inherit'}
//                     className="td"
//                     style={{
//                         display: 'inline-block',
//                         width: headerRefs.current[column.name] && headerRefs.current[column.name].getBoundingClientRect().width,
//                         padding: 10,
//                     }}
//                 >
//                     <div
//                         style={{
//                             paddingLeft: column.depth ? column.depth * 30 : 0,
//                         }}
//                     >
//                         {colIndex === 0 && row.isTogglable && row.isExpanded && (
//                             <i
//                                 className="fa fa-fw fa-minus-square-o"
//                                 onClick={() => onToggleRow(row)}
//                             />
//                         )}
//                         {colIndex === 0 && row.isTogglable && !row.isExpanded && (
//                             <i
//                                 className="fa fa-fw fa-plus-square-o"
//                                 onClick={() => onToggleRow(row)}
//                             />
//                         )}
//                         {row.isRowTogglable &&
//                             column.isColumnTogglable &&
//                             row.isExpanded && (
//                                 <i
//                                     className="fa fa-fw fa-minus-square-o"
//                                     onClick={() => onToggleRow(row)}
//                                 />
//                             )}
//                         {row.isRowTogglable &&
//                             column.isColumnTogglable &&
//                             !row.isExpanded && (
//                                 <i
//                                     className="fa fa-fw fa-plus-square-o"
//                                     onClick={() => onToggleRow(row)}
//                                 />
//                             )}
//                         {!column.hideSubRows || colIndex === 0 ? column.label : ' '}
//                     </div>
//                 </Box>)
//             })}
//         </TableRow>
//     );
// };

export default PivotTableContainer;

import _ from 'lodash';

import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.audiences';
export default function audiences(state = {}, action) {
    switch (action.type) {
        case 'SYSTEM__AUDIENCE__FETCH__SUCCESS':
            const { audiences, audience } = action.payload;
            return mergeResource(
                state,
                []
                    .concat(audience)
                    .concat(audiences)
                    .filter(x => x)
            );
        default:
            return state;
    }
}

import _ from 'lodash';
import VError from 'verror';
import createHttp from 'utils/http';
import notify from 'utils/notify';
import c from 'common/constants/flux-events';
import Ads from 'states/resources/ads/actions';
import Campaigns from 'states/resources/campaigns/actions';
import Creatives from 'states/resources/creatives/actions';
import { fetchReportStats } from 'services/resources/stats';
import { getEnvironmentSettings } from 'services/environment';

const http = createHttp();

const fetchAdMetaData = async campaignId => {
    try {
        const http = createHttp();
        const query = `
            query getAdMetaData ($campaignId: Int) {
                campaign(id: $campaignId) {
                    ads {
                        id
                        revenueModel
                        metadata {
                            progress {
                                progressData {
                                    health
                                }
                            }
                        }
                    }
                }
             }
        `;

        const variables = {
            keywords: null,
            source_type: null,
            campaignId: parseInt(campaignId),
        };

        const data = await http.graphql(query, variables);
        const res = _.chain(data.campaign.ads)
            .keyBy('id')
            .value();
        return res;
    } catch (err) {
        console.error(err);
        notify({
            error: new VError(err, 'failed to fetch ad metadata'),
            metaData: {
                campaignId,
                location: 'campaigns/progress/campaign-progress/actions: fetchData()',
                prevAction: 'SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS',
            },
        });

        return [];
    }
};

const fetchCampaign = async (campaignId, flightId) => {
    try {
        const http = createHttp();
        const query = `
            query getCampaignMetaData ($campaignId: Int, $flightId: String, $runTime: String) {
                campaign(id: $campaignId) {
                    id
                    flightPacingStrategy
                    flights {
                        id
                        name
                        start
                        end
                    }
                    liveFlight: flight (runTime: "live") {
                        id
                    }
                    currentFlight: flight (flightId: $flightId, runTime: $runTime) {
                        id
                        name
                        start
                        end
                        metadata {
                            metrics {
                                impressions
                                clicks
                                ctr
                                spend
                                billings_local
                                owner_total_media_cost_local
                            }
                            progressData{
                                idealDeliveryPlot
                                idealDelivered
                                currentDeliveryPlot
                                currentDelivered
                                currentPacing
                                projectedDeliveryPlot
                                projectedDelivery
                                projectedPacing
                                timeAxisData
                                totalTarget
                                goal
                                revenueAtRisk
                                spendAtRisk
                                idealDeliveryTitle
                                currentDeliveredTargetTitle
                                timezone
                                variance
                            }
                            progressDataDaily {
                                idealDeliveryPlot
                                idealDelivered
                                currentDeliveryPlot
                                currentDelivered
                                currentPacing
                                projectedDeliveryPlot
                                projectedDelivery
                                projectedPacing
                                health
                                timeAxisData
                                totalTarget
                                goal
                                revenueAtRisk
                                goalAtRisk
                                variance
                            }
                        }
                    }
                    metadata {
                        progressData{
                            idealDeliveryPlot
                            idealDelivered
                            currentDeliveryPlot
                            currentDelivered
                            currentPacing
                            projectedDeliveryPlot
                            projectedDelivery
                            projectedPacing
                            timeAxisData
                            totalTarget
                            goal
                            revenueAtRisk
                            spendAtRisk
                            idealDeliveryTitle
                            currentDeliveredTargetTitle
                            timezone
                            variance
                        }
                        progressDataDaily {
                            idealDeliveryPlot
                            idealDelivered
                            currentDeliveryPlot
                            currentDelivered
                            currentPacing
                            projectedDeliveryPlot
                            projectedDelivery
                            projectedPacing
                            health
                            timeAxisData
                            totalTarget
                            goal
                            revenueAtRisk
                            goalAtRisk
                            variance
                        }
                    }
                }
             }
        `;

        const variables = {
            keywords: null,
            source_type: null,
            campaignId: Number(campaignId),
            flightId,
            runTime: flightId ? null : 'live',
        };

        const data = await http.graphql(query, variables);

        return data.campaign;
    } catch (err) {
        console.error(err);
        notify({
            error: new VError(err, 'failed to fetch campaign metadata'),
            metaData: {
                campaignId,
                location: 'campaigns/progress/campaign-progress/actions: fetchData()',
                prevAction: 'SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS',
            },
        });

        return [];
    }
};

export const fetchData = (campaignId, id, timezone) => async (dispatch, getState) => {
    const options = {
        campaign_id: String(campaignId),
        match: {},
        split: ['ad_id'],
        include_beacons: false,
        timezone,
        datasource: getEnvironmentSettings().getReportingDatasource(),
    };

    await http.get(`campaigns/${campaignId}/livestats?keys=hour,date&sort=date`).then(response =>
        dispatch({
            type: c.SYSTEM__CAMPAIGN_STATS_LIVE_BY_HOUR__FETCH__SUCCESS,
            payload: {
                stats: response,
                campaignId,
            },
        })
    );

    await fetchReportStats(options).then(response => {
        const adStats = [];

        _.each(response.stats, stats => {
            adStats.push({
                id: _.get(stats, 'split.0.group'),
                stats,
            });
        });

        dispatch({
            type: 'SYSTEM__AD_STATS_TOTAL__FETCH_ALL__SUCCESS',
            payload: {
                adStats,
            },
        });
    });

    let flightId = null;
    const { viewBy } = getState().campaignPageContainer;
    if (viewBy !== 'campaign') {
        flightId = viewBy;
    }
    await dispatch(Campaigns.get(campaignId))
        .then(() => {
            return Promise.all([
                dispatch(Ads.getAll(campaignId)),
                fetchAdMetaData(campaignId),
                fetchCampaign(campaignId, flightId),
                dispatch(Creatives.getAll(campaignId)),
            ]);
        })
        .then(async ([ads, adMetaData, campaign]) => {
            const timezoneAtCampaignLevel = _.get(getState(), 'campaignProgress.timezone');

            const adsWithMetadata = _.map(ads, ad => {
                return {
                    ...ad,
                    revenueModel: adMetaData[ad.id].revenueModel,
                    metadata: {
                        progress: adMetaData[ad.id].metadata.progress,
                        tactics: ad.metadata.tactics,
                    },
                };
            });
            dispatch({
                type: c.SYSTEM__CAMPAIGN_ADS__FETCH__SUCCESS,
                payload: {
                    campaignId: parseInt(campaignId),
                    ads: adsWithMetadata,
                    campaignMetadata: campaign.metadata.progressData,
                },
            });

            let progressData = campaign.metadata.progressData;
            if (campaign.currentFlight) {
                progressData = campaign.currentFlight.metadata.progressData;
            }

            dispatch({
                type: c.CAMPAIGN_DELIVERY__INIT,
                payload: {
                    ads: adsWithMetadata,
                    campaignFromGraphql: campaign,
                    campaignData: progressData,
                    currentFlight: campaign.currentFlight,
                    liveFlight: campaign.liveFlight,
                    defaultTimezone:
                        timezoneAtCampaignLevel ||
                        _.get(campaign.metadata, 'progressData.timezone'),
                },
            });

            const adStats = [];

            await Promise.all(
                _.map(adsWithMetadata, async ad => {
                    try {
                        const stats = await http.get(
                            `campaigns/${campaignId}/ads/${
                                ad.id
                            }/livestats?keys=hour,date&sort=date`
                        );
                        adStats.push({
                            id: ad.id,
                            stats,
                        });
                    } catch (err) {
                        adStats.push({
                            id: ad.id,
                            stats: [],
                        });
                        notify({
                            error: new VError(
                                err,
                                `CAMPAIGN PROGRESS failed to fetch livestats for AD ID ${ad.id}`
                            ),
                            metaData: { adId: ad.id },
                        });
                    }
                })
            );
            dispatch({
                type: 'SYSTEM__AD_STATS_LIVE_BY_HOUR__FETCH_ALL__SUCCESS',
                payload: {
                    adStats,
                },
            });
        });
};



export const fetchCampaignDeliveryData = (campaignId, currentFlightId, timezone) => dispatch => {
    dispatch({
        type: c.CAMPAIGN_DELIVERY__LOADING,
        payload: {
            campaignId,
        },
    });

    return dispatch(fetchData(campaignId, currentFlightId, timezone));
};

export const updateTimezoneAtCampaignLevel = timezone => dispatch => {
    dispatch({
        type: 'CAMPAIGN_DELIVERY__TIMEZONE_CHANGE',
        payload: {
            timezone,
        },
    });
};

export const fetchAdDeliveryData = (campaignId, adId, timezone) => (dispatch, getState) => {
    dispatch({
        type: c.AD_DELIVERY__LOADING,
        payload: {
            adId,
        },
    });

    return fetchData(campaignId, adId, timezone)(dispatch, getState);
};

export const resetState = () => {
    return dispatch => {
        dispatch({
            type: 'CAMPAIGN_DELIVERY__RESET_STATE',
        });
    };
};

export const updateFlightId = flightId => dispatch => {
    dispatch({
        type: 'UPDATE_FLIGHT_ID',
        payload: {
            flightId,
        },
    });
};

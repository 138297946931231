import _ from 'lodash';
import { getAllViablePlatforms } from 'forms/creative-form/constants';

export default function selector(storeState, props) {
    const creativeDraft = _.get(props, 'creativeDraft');
    const selectablePlatforms = getAllViablePlatforms({
        type: creativeDraft.type,
        size: creativeDraft.size,
        format: creativeDraft.format,
        placements: [],
        currentPlatforms: creativeDraft.platforms,
        campaignType: props.campaignType,
    });
    const hasImageGenerationError = _.get(
        storeState,
        `bulkUploadCreativesForm.imageGenerationErrors.${props.creativeKey}`
    );
    const lineSizeError = _.get(
        storeState,
        `bulkUploadCreativesForm.lineSizeErrors.${props.creativeKey}`
    );
    return {
        selectablePlatforms,
        hasImageGenerationError,
        lineSizeError,
    };
}

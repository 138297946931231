import React from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';

import { Caption } from 'widgets-v5/typography';
import AppCategoriesTarget from './modules/app-categories/target';
import AudiencesTarget from './modules/audiences/target';
import PoisTarget from './modules/pois/target';
import IasContextualTargetingTarget from './modules/ias-contextual-targeting/target';
import Typography from '@mui/material/Typography';
import {
    getAppCategoryWarnings,
    getContextualTargetingWarnings,
} from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import Box from '@mui/material/Box';
import { getEnvironmentSettings } from 'services/environment';
import { PlatformMapping } from 'states/resources/ads/business-logic';

const TARGET_OPTIONS = [
    {
        key: 'pois',
        title: 'Flex Geolocation Audiences',
        Target: PoisTarget,
        shouldDisable: () => {
            return false;
        },
        shouldFilter: ({ shouldHideFlexSegments }) => {
            if (shouldHideFlexSegments) {
                return true;
            }
            return false;
        },
        platforms: [PlatformMapping.INAPP, PlatformMapping.CTV, PlatformMapping.DOOH],
    },
    {
        key: 'app_categories',
        title: 'App Categories',
        Target: AppCategoriesTarget,
        shouldDisable: () => false,
        shouldFilter: ({ isAppStoreCatEnabled, isCrossPlatformCampaign }) => {
            if (isCrossPlatformCampaign) {
                return false;
            }
            if (isAppStoreCatEnabled) {
                return true;
            } else {
                return false;
            }
        },
        platforms: [PlatformMapping.INAPP, PlatformMapping.CTV, PlatformMapping.DOOH],
    },
    {
        key: 'audience_segments',
        title: 'Audiences',
        Target: AudiencesTarget,
        shouldDisable: ({}) => {
            return false;
        },
        shouldFilter: () => true,
        platforms: [PlatformMapping.INAPP, PlatformMapping.MWEB, PlatformMapping.Desktop, PlatformMapping.CTV, PlatformMapping.DOOH],
    },
    {
        key: 'ias_contextual_targeting',
        title: 'Contextual Targeting',
        customSubheader: (
            <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                    <Caption type="info">
                        Powered by{' '}
                        <a href="https://integralads.com/" target="_blank">
                            Integral Ad Science (IAS)
                        </a>
                    </Caption>
                </Grid>
                <Grid item>
                    <img src={'/images/IAS-logo.png'} style={{ height: 20 }} />
                </Grid>
            </Grid>
        ),
        Target: IasContextualTargetingTarget,
        shouldDisable: () => false,
        shouldFilter: ({ isCrossPlatformCampaign, isCTVCampaign, isDOOHCampaign }) => {
            if (isCTVCampaign) {
                return false;
            }
            if (isDOOHCampaign) {
                return false;
            }
            if (isCrossPlatformCampaign) {
                return false;
            }
            return true;
        },
        platforms: [PlatformMapping.MWEB, PlatformMapping.Desktop],
    },
    {
        key: 'categories',
        title: 'Contextual Targeting',
        Target: props => {
            const handleDraftChange = ({ draft }) => {
                const existingDraft = props.targetDraft;
                const newDraft = {
                    ...existingDraft,
                    ...draft,
                };
                props.onDraftChange({ draft: newDraft, errors: {} });
            };

            let appCategoryWarning = {};
            let contextualTargetingWarning = {};

            if (!_.isEmpty(props.targetDraft)) {
                const includedAppCategories = _.get(props, 'targetDraft.target_app_store_cat');
                const excludedAppCategories = _.get(
                    props,
                    'targetDraft.target_app_store_cat_exclude'
                );
                const contextualTargetingValue = _.get(
                    props,
                    'targetDraft.ias.contextualTargeting.value',
                    []
                );

                appCategoryWarning = getAppCategoryWarnings({
                    includedAppCategories,
                    excludedAppCategories,
                    platforms: props.platforms,
                });
                contextualTargetingWarning = getContextualTargetingWarnings({
                    contextualTargetingValue,
                    platforms: props.platforms,
                });
            }

            return (
                <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary">App Store Categories</Typography>
                        <AppCategoriesTarget {...props} onDraftChange={handleDraftChange} />
                        {appCategoryWarning.hasWarning && (
                            <Box marginTop={1}>
                                <WarningText
                                    severity={appCategoryWarning.severity}
                                    message={appCategoryWarning.message}
                                />
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="textSecondary">
                            Contexual Targeting (Web environments only)
                        </Typography>
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Caption type="info">
                                    Powered by{' '}
                                    <a href="https://integralads.com/" target="_blank">
                                        Integral Ad Science (IAS)
                                    </a>
                                </Caption>
                            </Grid>
                            <Grid item>
                                <img src={'/images/IAS-logo.png'} style={{ height: 20 }} />
                            </Grid>
                        </Grid>
                        <IasContextualTargetingTarget
                            {...props}
                            onDraftChange={handleDraftChange}
                        />
                        {contextualTargetingWarning.hasWarning && (
                            <Box marginTop={1}>
                                <WarningText
                                    severity={contextualTargetingWarning.severity}
                                    message={contextualTargetingWarning.message}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            );
        },
        shouldDisable: () => false,
        shouldFilter: ({ isCrossPlatformCampaign, isCTVCampaign, isDOOHCampaign }) => {
            if (isCTVCampaign) {
                return false;
            }
            if (isDOOHCampaign) {
                return false;
            }
            if (!isCrossPlatformCampaign) {
                return false;
            }
            if (!getEnvironmentSettings().canUseTacticsCategories()) {
                return false;
            }

            return true;
        },
        platforms: [PlatformMapping.INAPP, PlatformMapping.MWEB, PlatformMapping.Desktop],
    },
];

export const TARGET_OPTIONS_MAPPING = {};
_.each(TARGET_OPTIONS, option => {
    TARGET_OPTIONS_MAPPING[option.key] = option;
});

export default TARGET_OPTIONS;

import _ from 'lodash';
import React, { useEffect } from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';

import ExporeLayout from './layout';
import { ReportLayout } from 'pages/campaigns/report/report-overview/layout';
import selector from './selector';

import { getUniqueBySplit } from './helpers/get-unique-by-split';

import { onRefresh, offRefresh } from 'containers/global-refresh';
import {
    inititalizeReport,
    refreshReport,
    metricSelector_toggleVisiblity,
    metricSelector_toggleSelection,
    controls_setTab,
    controls_setMetricType,
    controls_setUnitType,
    controls_setMetricVisibility,
    adgroup_toggleGroup,
    adgroup_reset,
    updateTimezone,
    pivot_sortColumn,
    pivot_expandRow,
    pivot_collapseRow,
    pivot_addSplit,
    pivot_removeSplit,
    pivot_updateSplits,
    pivot_hideZerosToggle,
    dimensionFilter_groupingClear,
    dimensionFilter_groupingClearAll,
    scopeFilter_groupingClear,
    scopeFilter_groupingClearAll,
    filterByDateRange,
} from 'pages/campaigns/report/report-explore/actions';
import { controls_setActiveScopeTab } from 'pages/campaigns/report/report-overview/actions';

import { can } from 'services/can';
import Controls from './modules/controls';
import ChartExplorerSummary from './modules/chart-explorer-summary';
import FilterSummary from './modules/filter-summary';

import ScheduledReport from 'containers/scheduled-report';
import { formatDimensions } from 'widgets/pivot-table/service';

import Tabs from 'widgets-v5/tabs';
import { BarGraphTile } from 'widgets-v5/bar-graph-tile';
import MetricSelector2 from 'widgets-v5/metric-selector-2';
import DimensionSelector from 'widgets-v5/dimension-selector';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import PivotTable3 from './v2/modules/pivot-table';
import DimensionSelector3 from './v2/modules/dimension-selector';
import HideZeroRows from './v2/modules/hide-zero-rows';
import ExportButton from './v2/modules/export-button';

import { formatNumber_percentage } from 'utils/formatting';
import { useReportExplore } from './v2/hook';
import { TIMEOUT_IDS } from '../constants';
import { isDimensionShared } from '../../../../containers/campaign-sharing/helper';
import { Dimensions } from './v2/modules/dimensions';
import { Heatmap } from './v2/modules/heatmap';
import { PermissionsMapping } from 'common/constants/role-intents';

function ExploreWrapper(props) {
    const { removeTimeouts, toggleSubevent, selectedSubevents } = useReportExplore();
    useEffect(() => () => removeTimeouts(TIMEOUT_IDS), []);
    return (
        <Explore {...props} toggleSubevent={toggleSubevent} selectedSubevents={selectedSubevents} />
    );
}

const Explore = createReactClass({
    displayName: 'Explore',

    getInitialState() {
        return { refreshId: Date.now() };
    },

    init() {
        this.setState({ refreshId: Date.now() }, () => {
            this.props.dispatch(
                inititalizeReport(this.props.params.campaignId, this.isClientReportPage())
            );
        });
    },

    isClientReportPage() {
        const { routes } = this.props;

        return !!_.find(routes, route => route.path === 'client-report');
    },

    componentDidMount() {
        this.init();
        onRefresh(this.init);
    },

    UNSAFE_componentWillUnmount() {
        offRefresh(this.init);
    },

    componentDidUpdate(prevProps) {
        if (this.props.params.campaignId !== prevProps.params.campaignId) {
            this.init();
        }
    },

    /* Metric Selector */
    metricSelector_toggleVisiblity(metric) {
        const { campaignId } = this.props.params;
        this.props.dispatch(metricSelector_toggleVisiblity(campaignId, metric));
    },

    metricSelector_toggleSelection(metric) {
        const { campaignId } = this.props.params;
        this.props.dispatch(metricSelector_toggleSelection(campaignId, metric));
    },

    /* Control handlers */
    controls_handleTabClick(tab) {
        const { campaignId } = this.props.params;
        this.props.dispatch(controls_setTab(campaignId, tab));
    },

    controls_handleMetricClick(selectedMetricType) {
        const { campaignId } = this.props.params;
        this.props.dispatch(controls_setMetricType(campaignId, selectedMetricType));
    },

    controls_handleUnitClick(selectedUnitType) {
        const { campaignId } = this.props.params;
        this.props.dispatch(controls_setUnitType(campaignId, selectedUnitType));
    },

    controls_handleSetActiveScopeTab(scopeId) {
        const { campaignId } = this.props.params;
        this.props.dispatch(controls_setActiveScopeTab(campaignId, scopeId));
    },

    //@TODO rename this with prefix "control"
    metricVisibilitySelection_haddleToggle(metricType) {
        const { campaignId } = this.props.params;
        this.props.dispatch(controls_setMetricVisibility(campaignId, metricType));
    },

    /* Bargraph handlers */
    adgroup_handleGroupToggle(component, grouping, group) {
        const { campaignId } = this.props.params;

        this.props.dispatch(adgroup_toggleGroup(campaignId, component, grouping, group));
    },

    adgroup_handleReset(component) {
        const { campaignId } = this.props.params;

        this.props.dispatch(adgroup_reset(campaignId, component));
    },

    /* Pivot Table Handlers */
    pivot_handleSort(column) {
        const { campaignId } = this.props.params;
        this.props.dispatch(pivot_sortColumn(campaignId, column));
    },

    pivot_handleExpand(rowId) {
        const { campaignId } = this.props.params;
        this.props.dispatch(pivot_expandRow(campaignId, rowId));
    },

    pivot_handleCollapse(rowId) {
        const { campaignId } = this.props.params;
        this.props.dispatch(pivot_collapseRow(campaignId, rowId));
    },

    pivot_handleAddSplit(split) {
        const { campaignId } = this.props.params;
        this.props.dispatch(pivot_addSplit(campaignId, split));
    },

    pivot_handleRemoveSplit(splits) {
        const { campaignId } = this.props.params;
        this.props.dispatch(pivot_removeSplit(campaignId, splits));
    },

    pivot_handleUpdateSplits(splits) {
        const { campaignId } = this.props.params;

        this.props.dispatch(pivot_updateSplits(campaignId, splits));
    },

    pivot_handleHideZerosToggle() {
        const { campaignId } = this.props.params;
        this.props.dispatch(pivot_hideZerosToggle(campaignId));
    },

    pivot_serializePivotTable() {
        return this.refs.pivotTable.serialize();
    },

    /* Heatmap handlers */
    heatmap_handleHoursClick(component, grouping, hours) {
        const { campaignId } = this.props.params;

        const groups = hours.map(hour => ({
            id: hour,
        }));

        this.props.dispatch(adgroup_toggleGroup(campaignId, component, grouping, groups));
    },

    heatmap_handleDayOfWeekClick(component, grouping, daysOfWeek) {
        const { campaignId } = this.props.params;

        const groups = daysOfWeek.map(dow => ({
            id: dow,
        }));

        this.props.dispatch(adgroup_toggleGroup(campaignId, component, grouping, groups));
    },

    heatmap_handleReset(component) {
        const { campaignId } = this.props.params;
        this.props.dispatch(adgroup_reset(campaignId, component));
    },

    /* Filters */
    dimensionFilter_handleGroupingClear(grouping) {
        const { campaignId } = this.props.params;

        this.props.dispatch(dimensionFilter_groupingClear(campaignId, grouping));
    },

    dimensionFilter_handleGroupingClearAll() {
        const { campaignId } = this.props.params;

        this.props.dispatch(dimensionFilter_groupingClearAll(campaignId));
    },

    scopeFilter_handleGroupingClear(grouping) {
        const { campaignId } = this.props.params;

        this.props.dispatch(scopeFilter_groupingClear(campaignId, grouping));
    },

    scopeFilter_handleGroupingClearAll() {
        const { campaignId } = this.props.params;

        this.props.dispatch(scopeFilter_groupingClearAll(campaignId));
    },

    filterByDateRange({ start, end, timeframe, selectedKey }) {
        const { campaignId } = this.props.params;

        this.props.dispatch(filterByDateRange(campaignId, { start, end, timeframe, selectedKey }));
    },

    updateTimezone(timezone) {
        const { campaignId } = this.props.params;

        this.props.dispatch(updateTimezone(campaignId, timezone));
    },

    getTab() {
        const { routes } = this.props;
        const lastRoute = _.last(routes);

        return lastRoute.path;
    },

    getTimezone() {
        const { selectedDateRange, organizationTimezone } = this.props;

        const defaultTimezone = organizationTimezone;

        return selectedDateRange.timezone || defaultTimezone;
    },

    formatRow(row) {
        const { pivotTableState, dictionary, campaign } = this.props;
        let nextRow = { ...row };

        nextRow = formatDimensions(
            nextRow,
            pivotTableState.pivotTable.splits,
            dictionary,
            campaign
        );

        let columnData = { ...nextRow.columnData };

        const uniqueUsers = getUniqueBySplit({
            splits: pivotTableState.pivotTable.splits,
            row: nextRow,
        });

        columnData = {
            ...columnData,
            unique_users: uniqueUsers,
        };

        const adsAttribute = _.get(nextRow, 'attributes.ads', []);
        const billingRateLabel = _(adsAttribute)
            .sortBy(['billing_enabled', 'billing_term', 'billing_rate'])
            .map(ad => {
                if (!ad.billing_enabled) {
                    return 'Bonus';
                }

                const isCpmCpc = _.includes(['CPM', 'CPC'], ad.billing_term);

                if (isCpmCpc) {
                    return `$${ad.billing_rate} ${ad.billing_term}`;
                } else {
                    return `${formatNumber_percentage(ad.billing_rate)}`;
                }
            })
            .uniq()
            .join(', ');

        columnData = {
            ...columnData,
            billing_rate: billingRateLabel,
        };

        return {
            ...nextRow,
            columnData,
        };
    },

    refreshPageData() {
        const { campaignId } = this.props.params;

        this.props.dispatch(refreshReport(campaignId));
    },

    getConversionSubheaders() {
        const { columns } = this.props;

        let output = [];

        const nonConversionColumns = _.map(
            _.filter(columns, ({ name }) => !_.includes(name, 'conv_')),
            column => {
                return {
                    ...column,
                    isEmpty: true,
                };
            }
        );

        const conversionColumns = _.filter(columns, ({ name }) => _.includes(name, 'conv_'));

        if (conversionColumns.length > 0) {
            _.each(conversionColumns, ({ name }) => {
                if (
                    _.includes(name, 'conv_total_cost_ecpa_') ||
                    _.includes(name, 'conv_overall_total_cost_ecpa')
                ) {
                    output.push({
                        name: 'conv_header_total_cost_ecpa',
                        label: 'Total Cost eCPA',
                        isConversionHeader: true,
                        eventPrefix: 'conv_total_cost_ecpa_',
                        overallMapping: 'conv_overall_total_cost_ecpa',
                    });
                } else if (
                    _.includes(name, 'conv_revenue_ecpa_') ||
                    _.includes(name, 'conv_overall_revenue_ecpa')
                ) {
                    output.push({
                        name: 'conv_header_revenue_ecpa',
                        label: 'Revenue eCPA',
                        isConversionHeader: true,
                        eventPrefix: 'conv_revenue_ecpa_',
                        overallMapping: 'conv_overall_revenue_ecpa',
                    });
                } else if (
                    _.includes(name, 'conv_total_click_') ||
                    _.includes(name, 'conv_overall_click')
                ) {
                    output.push({
                        name: 'conv_header_click',
                        label: 'Click-through',
                        isConversionHeader: true,
                        eventPrefix: 'conv_total_click_',
                        overallMapping: 'conv_overall_click',
                    });
                } else if (
                    _.includes(name, 'conv_total_imp_') ||
                    _.includes(name, 'conv_overall_imp')
                ) {
                    output.push({
                        name: 'conv_header_imp',
                        label: 'View-through',
                        isConversionHeader: true,
                        eventPrefix: 'conv_total_imp_',
                        overallMapping: 'conv_overall_imp',
                    });
                } else if (_.includes(name, 'conv_total_') || _.includes(name, 'conv_overall')) {
                    output.push({
                        name: 'conv_header_total',
                        label: 'Total',
                        isConversionHeader: true,
                        eventPrefix: 'conv_total_',
                        overallMapping: 'conv_overall',
                    });
                }
            });

            output = _.uniqBy(output, 'name');
            output = _.concat(
                [
                    {
                        name: 'conv_header_events',
                        label: 'Pixels',
                        isConversionHeader: true,
                    },
                ],
                output
            );
            output = _.concat(nonConversionColumns, output);

            return output;
        }

        return output;
    },

    getConversionNameMapping() {
        const { conversions, advertiserConversions } = this.props.campaign;

        const output = {
            conv_overall: 'Overall Conversions',
        };

        _.each(conversions, ({ event_name, reporting_name }) => {
            output[event_name] = reporting_name;
        });

        _.each(advertiserConversions, ({ event_name, reporting_name }) => {
            output[`adv_${event_name}`] = reporting_name;
        });

        return output;
    },

    getSelectedConversions() {
        const { metricComponentsConfig: metrics, selectedMetrics } = this.props.metricSelector;
        const { conversions, advertiserConversions } = this.props.campaign;

        const output = [];

        const allConversions = _.join(
            _(metrics)
                .filter(
                    ({ metricType }) =>
                        _.includes(metricType, 'conv_') && _.includes(selectedMetrics, metricType)
                )
                .map(({ metricType }) => metricType)
                .value()
        );

        if (_.includes(allConversions, 'conv_overall')) {
            output.push('conv_overall');
        }

        _.each(conversions, ({ event_name }) => {
            if (_.includes(allConversions, event_name)) {
                output.push(event_name);
            }
        });

        _.each(advertiserConversions, ({ event_name }) => {
            if (_.includes(allConversions, event_name)) {
                output.push(`adv_${event_name}`);
            }
        });

        return output;
    },

    getAllConversions() {
        const { metricComponentsConfig: metrics, selectedMetrics } = this.props.metricSelector;

        return _(metrics)
            .filter(
                ({ metricType }) =>
                    _.includes(metricType, 'conv_') && _.includes(selectedMetrics, metricType)
            )
            .map(({ metricType }) => metricType)
            .value();
    },

    render() {
        const { isInitialized } = this.props;

        if (!isInitialized) {
            return (
                <div className="ef6-alignment__center" style={{ width: '100%', height: '100%' }}>
                    <BlockLoadGroup isLoading={true} />
                </div>
            );
        }

        const {
            mode,
            controls,
            metricSelector,

            chartExplorerSummary,

            dimensionFilter,
            scopeFilter,

            scopeComponents,
            activeScopeTab,

            campaign,
            dropDownOptions,
            pivotTableState,
            selectedDateRange,

            selectedMetric,
            isClient,
            campaignRelationship,
            toggleSubevent,
            selectedSubevents,
            selectedMetrics,
            visibleMetrics,
        } = this.props;

        const timezone = this.getTimezone();
        const activeScopeComponent = _.filter(
            scopeComponents,
            component => component.grouping === activeScopeTab
        ).map(component => {
            return (
                <BarGraphTile
                    {...component}
                    key={component.id}
                    onReset={this.adgroup_handleReset}
                    onCheckboxSelected={this.adgroup_handleGroupToggle}
                />
            );
        })[0];

        const filterAdGroupsScopeComponents = _.filter(
            this.props.scopeComponents,
            scopeComponent => {
                const isAdGroupShared = isDimensionShared(this.props.campaign, 'adgroup');
                if (isAdGroupShared) {
                    return true;
                }
                return !_.includes(
                    ['Split by Ad ID', 'Split by Netsuite ID'],
                    scopeComponent.title
                );
            }
        );

        return (
            <ReportLayout
                refreshPageData={this.refreshPageData}
                campaignRelationship={campaignRelationship}
                selectedMetric={selectedMetric}
                campaign={campaign}
                dateRange={selectedDateRange}
                updateTimezone={this.updateTimezone}
                timezone={timezone}
                existingTimezones={[this.props.organizationTimezone, campaign.default_timezone]}
                metricSelector={
                    metricSelector && (
                        <React.Fragment>
                            <MetricSelector2
                                campaign={campaign}
                                statsMetricsType_visibility={
                                    metricSelector.statsMetricsType_visibility
                                }
                                metricType={metricSelector.metricType}
                                selectedMetric={metricSelector.selectedMetric}
                                totalFilteredStats={metricSelector.totalFilteredStats}
                                currency={metricSelector.currency}
                                campaignBillingEnabled={campaign.billing_enabled}
                                metricComponentsConfig={metricSelector.metricComponentsConfig}
                                metricSelector_toggleVisiblity={this.metricSelector_toggleVisiblity}
                                metricSelector_toggleSubevent={toggleSubevent}
                                selectedSubevents={selectedSubevents}
                                selectedMetrics={selectedMetrics}
                                visibleMetrics={visibleMetrics}
                            />
                        </React.Fragment>
                    )
                }
                dimensionSelector={
                    <React.Fragment>
                        <DimensionSelector
                            campaign={campaign}
                            statsMetricsType_visibility={metricSelector.statsMetricsType_visibility}
                            metricType={metricSelector.metricType}
                            selectedMetric={metricSelector.selectedMetric}
                            totalFilteredStats={metricSelector.totalFilteredStats}
                            currency={metricSelector.currency}
                            campaignBillingEnabled={campaign.billing_enabled}
                            metricComponentsConfig={metricSelector.metricComponentsConfig}
                            metricSelector_toggleVisiblity={this.metricSelector_toggleVisiblity}
                            metricSelector_toggleSelection={this.metricSelector_toggleSelection}
                        />
                    </React.Fragment>
                }
                workspace={
                    <ExporeLayout
                        campaign={campaign}
                        dropDownOptions={dropDownOptions}
                        filterByDateRange={this.filterByDateRange}
                        chartExplorerSummary={
                            chartExplorerSummary && (
                                <ChartExplorerSummary
                                    key={this.state.refreshId}
                                    isClient={this.isClientReportPage()}
                                    {...chartExplorerSummary}
                                />
                            )
                        }
                        mode={mode}
                        controls={
                            controls ? (
                                <Controls
                                    {...controls}
                                    dimensionFilter={
                                        <FilterSummary
                                            {...dimensionFilter}
                                            onClearItem={this.dimensionFilter_handleGroupingClear}
                                            onClearAllFilters={
                                                this.dimensionFilter_handleGroupingClearAll
                                            }
                                        />
                                    }
                                    scopeFilter={
                                        <FilterSummary
                                            {...scopeFilter}
                                            onClearItem={this.scopeFilter_handleGroupingClear}
                                            onClearAllFilters={
                                                this.scopeFilter_handleGroupingClearAll
                                            }
                                        />
                                    }
                                    onTabClick={this.controls_handleTabClick}
                                    onClickUnit={this.controls_handleUnitClick}
                                    onClickMetric={this.controls_handleMetricClick}
                                    onClick_metricVisibilitySelection_haddleToggle={
                                        this.metricVisibilitySelection_haddleToggle
                                    }
                                />
                            ) : null
                        }
                        barGraphs={
                            <Dimensions
                                isClient={isClient}
                                isClientReportPage={this.isClientReportPage()}
                                onReset={this.adgroup_handleReset}
                                onCheckboxSelected={this.adgroup_handleGroupToggle}
                            />
                        }
                        heatMap={
                            <Heatmap
                                isClient={isClient}
                                isClientReportPage={this.isClientReportPage()}
                                onReset={this.heatmap_handleReset}
                                onHoursClick={this.heatmap_handleHoursClick}
                                onDayOfWeekClick={this.heatmap_handleDayOfWeekClick}
                            />
                        }
                        pivotTable={
                            pivotTableState ? (
                                <div>
                                    <div>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            style={{ padding: 10 }}
                                        >
                                            <Grid item>
                                                <DimensionSelector3 />
                                            </Grid>
                                            <Grid item>
                                                <HideZeroRows />
                                                <ExportButton />
                                                {can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT) &&
                                                    !(isClient || this.isClientReportPage()) && (
                                                        <ScheduledReport campaign={campaign} />
                                                    )}
                                            </Grid>
                                        </Grid>
                                        <PivotTable3 />
                                    </div>
                                </div>
                            ) : null
                        }
                        scopes={
                            <div className="ef5-reports__scopes">
                                {filterAdGroupsScopeComponents &&
                                    filterAdGroupsScopeComponents.length > 1 && (
                                        <Tabs
                                            activeItem={activeScopeTab}
                                            onChange={value => {
                                                this.controls_handleSetActiveScopeTab(value);
                                            }}
                                            items={filterAdGroupsScopeComponents.map(scope => ({
                                                label: scope.title,
                                                value: scope.grouping,
                                            }))}
                                        />
                                    )}
                                <div
                                    className={classnames('ef5-reports__scope-dimension', {
                                        'ef5-reports__scope-dimension_multiple-scopes':
                                            filterAdGroupsScopeComponents &&
                                            filterAdGroupsScopeComponents.length > 1,
                                    })}
                                >
                                    {activeScopeComponent}
                                </div>
                            </div>
                        }
                    />
                }
                filterByDateRange={this.filterByDateRange}
                tab={this.getTab()}
            />
        );
    },
});

export default connect(selector)(ExploreWrapper);

import _ from 'lodash';
import c from 'common/constants/flux-events';
import http from 'utils/http';
import toastr from 'toastr';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const Files = {
    getAll(campaignId) {
        return (dispatch, getState) => {
            return http()
                .get(`campaigns/${campaignId}/files`)
                .then(
                    files => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_FILES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                files,
                            },
                        });

                        return files;
                    },
                    err => {
                        console.error(`GET ALL files error`, err);

                        throw err;
                    }
                );
        };
    },

    get(campaignId, fileId) {
        return (dispatch, getState) => {
            return http()
                .get(`campaigns/${campaignId}/files/${fileId}`)
                .then(
                    file => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_FILES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                file,
                            },
                        });

                        return file;
                    },
                    err => {
                        console.error(`GET ONE files error`, err);

                        throw err;
                    }
                );
        };
    },

    create(campaignId, fileData, onProgress, onComplete, payload = {}) {
        _.each(payload, (value, key) => {
            fileData.append(key, value);
        });

        return (dispatch, getState) => {
            return http()
                .post(`campaigns/${campaignId}/files`, null, {
                    contentType: false,
                    processData: false,
                    data: fileData,
                    xhr() {
                        const xhr = $.ajaxSettings.xhr();
                        xhr.upload.onprogress = e => {
                            if (onProgress) {
                                onProgress(Math.floor((e.loaded / e.total) * 100));
                            }
                        };

                        xhr.upload.success = e => {
                            if (onComplete) {
                                onComplete();
                            }
                        };
                        return xhr;
                    },
                })
                .catch(err => {
                    if (err.body === 'Corrupt file') {
                        toastr.warning(
                            'The file you are trying to upload is corrupted, please try another one.',
                            '',
                            TOASTR_OPTIONS
                        );
                        if (window.bugsnagClient) {
                            window.bugsnagClient.notify(`Failed to upload file`, {
                                metaData: {
                                    campaignId: `${campaignId}`,
                                },
                            });
                        }
                    } else {
                        throw err;
                    }
                });
        };
    },

    update(campaignId, fileId, file) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.files.${fileId}.attr._etag`);

            return http()
                .setIfMatch(etag)
                .patch(`campaigns/${campaignId}/files/${fileId}`, file)
                .then(
                    file => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_FILES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                file,
                            },
                        });

                        return file;
                    },
                    err => {
                        console.error(`UPDATE files error`, err);

                        throw err;
                    }
                );
        };
    },

    delete(campaignId, fileId) {
        return (dispatch, getState) => {
            const etag = _.get(getState(), `resources.files.${fileId}.attr._etag`);

            return http()
                .setIfMatch(etag)
                .del(`campaigns/${campaignId}/files/${fileId}`)
                .then(
                    file => {
                        dispatch({
                            type: c.SYSTEM__CAMPAIGN_FILES__FETCH__SUCCESS,
                            payload: {
                                campaignId,
                                file,
                            },
                        });

                        return file;
                    },
                    err => {
                        console.error(`DELETE files error`, err);

                        throw err;
                    }
                );
        };
    },
};

export default Files;

import _ from 'lodash';
import flags from 'containers/flags/service';

export default function selector(storeState, props) {
    const user = _.get(storeState, `profilePage.user`, undefined);

    const orgId = _.get(user, 'attr.organization', undefined);
    const adPresets = _.get(storeState, 'resources.adPresets', undefined);

    const adPresetsAttr = _.map(_.keys(adPresets), adPresetId => {
        return adPresets[adPresetId].attr;
    });

    const isUpdatingSubscriptionPreferences = _.get(
        storeState,
        `profilePage.isUpdatingSubscriptionPreferences`,
        false
    );

    const out = {
        user,
        orgId,
        adPresets: adPresetsAttr,
        ownOrganization: _.get(storeState, 'profilePage.organization'),
        isUpdatingSubscriptionPreferences,
    };

    return out;
}

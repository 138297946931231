import _ from 'lodash';
import { browserHistory } from 'react-router';
import createHttp from 'utils/http';
import c from 'common/constants/flux-events';
import Bacon from 'baconjs';
import qs from 'utils/querystring';
const http = createHttp();
const searchStream = new Bacon.Bus();

import UserActions from 'states/resources/users/actions';

const fetchDashboardData = async filters => {
    const http = createHttp();
    const query = `
        query getDashbordData($filters: CampaignFilters) {
            campaignsSearch(filters: $filters) {
                maxResults
                campaigns {
                    id
                    name
                    status
                    start
                    end
                    notes
                    budget_allocation_method
                    currency
                    billing_enabled
                    flightPacingStrategy
                    clients {
                        type
                        organization
                        name
                    }
                    ads {
                        id
                        name
                        metadata {
                            progress {
                                progressData {
                                    health
                                }
                            }
                        }
                    }
                    metadata {
                        metrics {
                            impressions
                            clicks
                            ctr
                            owner_total_media_cost_local_ecpm
                            owner_total_media_cost_local
                            billings_local
                        }
                    }
                    liveFlight: flight(runTime: "live") {
                        id
                        name
                        start
                        end
                        metadata {
                            metrics {
                                impressions
                                clicks
                                ctr
                                owner_total_media_cost_local_ecpm
                                owner_total_media_cost_local
                                billings_local
                            }
                        }
                    }
                }
            }
         }
    `;
    const variables = {
        filters,
    };

    const res = await http.graphql(query, variables);

    return res;
};


searchStream
    .debounce(500)
    .flatMapLatest(({ dispatch, query }) => {
        const {
            search,
            status,
            creative_type,
            category,
            sort,
            page,
            resultsPerPage,
            client,
            campaignCreatorId,
            archived,
        } = query;

        var params = {
            page: page - 1,
            limit: resultsPerPage,
        };

        if (search) {
            params.search = search;
        }

        if (campaignCreatorId) {
            params.campaignCreatorId = _.concat(campaignCreatorId).join(',');
        }

        if (status) {
            params.status = _.concat(status).join(',');
        }

        if (creative_type) {
            params.creative_type = _.concat(creative_type).join(',');
        }

        if (client) {
            params.client = _.concat(client).join(',');
        }

        if (category) {
            params.category = _.concat(category).join(',');
        }

        if (sort) {
            params.sort = sort;
        } else {
            params.sort = '-created';
        }

        if (archived) {
            params.archived = '1';
        }

        dispatch({
            type: c.CAMPAIGN_DASHBOARD__FETCH,
            payload: {},
        });

        const fetchData = async () => {
            const dashboardData = await fetchDashboardData(params);
            return {
                dashboardData,
                params,
                dispatch,
            };
        };

        return Bacon.fromPromise(fetchData(), true);
    })
    .onValue(async args => {
        const { dashboardData, dispatch, params } = args;
        const { campaigns, maxResults } = dashboardData.campaignsSearch;

        dispatch({
            type: 'CAMPAIGN_DASHBOARD:SEARCH:SUCCESS',
            payload: {
                campaigns,
                currentPage: params.page + 1,
                maxResults,
            },
        });
    });

export function fetchDashboardCampaigns(userOpts) {
    return (dispatch, getState) => {
        const pagination = _.get(getState(), 'dashboard', {});

        const query = {
            ...pagination.filters,
            resultsPerPage: pagination.resultsPerPage,
            ...userOpts,
        };

        let querystring = _(query);

        querystring = querystring
            .pick([
                'page',
                'search',
                'status',
                'creative_type',
                'category',
                'client',
                'campaignCreatorId',
                'archived',
            ])
            .omitBy(val => val === '' || val === null || val === undefined || _.isEmpty(val))
            .value();

        browserHistory.push(`/dashboard?${qs.serialize(querystring)}`);
        searchStream.push({ dispatch, getState, query });
    };
}

export function filterCampaigns(query) {
    return dispatch => {
        dispatch({
            type: 'CAMPAIGN_DASHBOARD__ADD_FILTERS',
            payload: query,
        });
    };
}

export function openFilters() {
    return {
        type: 'CAMPAIGN_DASHBOARD__OPEN_FILTER_OPTIONS',
    };
}

export function closeFilters() {
    return {
        type: 'CAMPAIGN_DASHBOARD__CLOSE_FILTER_OPTIONS',
    };
}

export function clearSearch() {
    return dispatch => {
        dispatch(filterCampaigns({ search: '' }));
        dispatch(fetchDashboardCampaigns({}));
    };
}

export function applyFilters(query) {
    return dispatch => {
        dispatch({
            type: 'CAMPAIGN_DASHBOARD__APPLY_FILTERS',
            payload: query,
        });

        dispatch(closeFilters());
        dispatch(fetchDashboardCampaigns({}));
    };
}

export function changePage(query) {
    return dispatch => {
        dispatch({
            type: 'CAMPAIGN_DASHBOARD__CHANGE_PAGE',
        });

        dispatch(filterCampaigns(query));
        dispatch(fetchDashboardCampaigns({}));
    };
}

export function searchCampaigns(query) {
    return dispatch => {
        dispatch(filterCampaigns(query));
        dispatch(fetchDashboardCampaigns({}));
    };
}

export function expandAdGrouping(primaryAdGroupingId, isExpanded) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_DASHBOARD__AD_GROUPING_EXPAND,
            payload: { primaryAdGroupingId, isExpanded },
        });
    };
}

export function collapseAdgrouping(primaryAdGroupingId, isExpanded) {
    return dispatch => {
        dispatch({
            type: c.CAMPAIGN_DASHBOARD__AD_GROUPING_COLLAPSE,
            payload: { primaryAdGroupingId, isExpanded },
        });
    };
}

export function fetchChildOrganizations() {
    return async dispatch => {
        const query = `
            query getChildOrganizationDataForDashboard ($filters: OrganizationFilters) {
                organizations(filters: $filters) {
                    name
                    id
                    type
                }
                ownOrganization {
                    name
                    id
                    type
                    users {
                        first_name
                        last_name
                        global_role
                        id
                        suspended
                    }
                }
            }
        `;
        const variables = {
            filters: {
                relationship: 'child',
                type: ['client', 'co_managed_client'],
            },
        };
        const { organizations, ownOrganization } = await http.graphql(query, variables);
        const childOrganizations = _.filter(
            organizations,
            organization => organization.id !== ownOrganization.id
        );

        dispatch({
            type: 'CAMPAIGN_DASHBOARD__SET_OWN_AND_CHILD_ORGANIZATION_DATA',
            payload: { childOrganizations, ownOrganization },
        });
    };
}

export const fetchUserPreferences = () => {
    return async (dispatch, getState) => {
        const id = _.get(getState(), `profile.userId`);

        const http = createHttp();
        const query = `
            query getUser ($id: String) {
                user(id: $id) {
                    _etag
                    preferences{
                        hiddenColumnsOnCampaignListPage
                    }
                }
             }
        `;
        const variables = {
            id,
        };

        try {
            dispatch({
                type: 'CAMPAIGN_DASHBOARD__USER_PREFERENCES_AND_ETAG',
                payload: {},
            });

            const fetchedUser = await http.graphql(query, variables);
            const { hiddenColumnsOnCampaignListPage } = fetchedUser.user.preferences;
            const etag = fetchedUser.user._etag;

            dispatch({
                type: 'CAMPAIGN_DASHBOARD__USER_PREFERENCES_AND_ETAG_FETCH__SUCCESS',
                payload: {
                    hiddenColumns: hiddenColumnsOnCampaignListPage,
                    etag,
                },
            });
        } catch (err) {
            if (window.bugsnagClient) {
                bugsnagClient.notify(
                    `Failed to fetch user preferences for campaign ads list column display`,
                    {
                        metaData: {
                            URI: window.location.href,
                            userId: id,
                        },
                    }
                );
            }
            dispatch({
                type: 'CAMPAIGN_DASHBOARD__USER_PREFERENCES_AND_ETAG_FETCH__FAIL',
            });
        }
    };
};

export function toggleColumn(column) {
    return (dispatch, getState) => {
        dispatch({
            type: 'CAMPAIGN_DASHBOARD__TOGGLE_COLUMN',
            payload: { column },
        });

        const hiddenColumnsOnCampaignListPage = _.get(getState(), `dashboard.hiddenColumns`);

        const userId = _.get(getState(), `profile.userId`);
        const etag = _.get(getState(), `dashboard.userEtag`);

        const hiddenColumns = _.pickBy(hiddenColumnsOnCampaignListPage, value => !!value);

        const payload = {
            preferences: {
                hiddenColumnsOnCampaignListPage: _.keys(hiddenColumns),
            },
        };
        const impersonatorId = _.get(getState(), `profile.impersonatorsToken.user.id`);

        if (impersonatorId === userId) {
            dispatch(UserActions.update(userId, payload, etag));
        }
    };
}

export function deleteFilter({ field, value }) {
    return dispatch => {
        dispatch({
            type: 'CAMPAIGN_DASHBOARD__DELETE_FILTER',
            payload: { field, value },
        });

        dispatch(fetchDashboardCampaigns({}));
    };
}

export function changeStatsView(view) {
    return {
        type: 'CAMPAIGN_DASHBOARD__CHANGE_STATS_VIEW',
        payload: {
            view,
        },
    };
}

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { REDUCER_KEY, actions } from './reducer';
import {
    getErrors,
    getUseDisabledRevenueModelAndAutoBudgetAllocation,
    getShowFta,
    getShowFtaFullyManagedFields,
    getShowFtaLimitedFields,
    getSchemaErrors,
    formatFlightMetrics,
    getThirdPartyFeeErrors,
    getSalesReps,
    getPartners,
    getAdvertisers,
    getClientMapping,
    getClientGrouping,
} from './selectors';

export function useCampaignForm() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);
    const errors = useSelector(getErrors);
    const useDisabledRevenueModelAndAutoBudgetAllocation = useSelector(
        getUseDisabledRevenueModelAndAutoBudgetAllocation
    );
    const showFta = useSelector(getShowFta);
    const showFtaFullyManagedFields = useSelector(getShowFtaFullyManagedFields);
    const showFtaLimitedFields = useSelector(getShowFtaLimitedFields);
    const schemaErrors = useSelector(getSchemaErrors);
    const flightMetrics = useSelector(formatFlightMetrics);
    const thirdPartyFeeErrors = useSelector(getThirdPartyFeeErrors);
    const salesReps = useSelector(getSalesReps);
    const partners = useSelector(getPartners);
    const advertisers = useSelector(getAdvertisers);
    const clientMapping = useSelector(getClientMapping);
    const clientGrouping = useSelector(getClientGrouping);

    return {
        ...state,
        ...boundActions,
        errors,
        useDisabledRevenueModelAndAutoBudgetAllocation,
        showFta,
        showFtaFullyManagedFields,
        showFtaLimitedFields,
        schemaErrors,
        flightMetrics,
        thirdPartyFeeErrors,
        salesReps,
        partners,
        advertisers,
        clientMapping,
        clientGrouping,
    };
}

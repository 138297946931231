import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import querystring from 'utils/querystring';

import { onRefresh, offRefresh } from 'containers/global-refresh';
import Layout from './layout';
import selector from './selector';
import actions from './actions';

class AudienceDashboard extends React.Component {
    getCurrentPage = () => {
        return parseInt(this.getFilters().page || 1, 10);
    };

    search = text => {
        const filters = this.getFilters();

        const query = {
            ...filters,
            search: text,
            page: 1,
        };

        browserHistory.push(`/audiences?${querystring.serialize(query)}`);
    };

    downloadAudience = id => {
        this.props.dispatch(actions.downloadAudience(id));
    };

    refreshPage = () => {
        const filters = this.getFilters();
        this.props.dispatch(actions.fetchPageData(filters));
    };

    componentDidMount() {
        const filters = this.getFilters();
        this.props.dispatch(actions.init(filters));

        onRefresh(this.refreshPage);
    }

    componentDidUpdate(prevProps) {
        const prevFilters = prevProps.location.query;
        const filters = this.getFilters();

        if (!_.isEqual(prevFilters, filters)) {
            this.props.dispatch(actions.fetchPageData(filters));
        }
    }

    componentWillUnmount() {
        offRefresh(this.refreshPage);
    }

    getFilters = () => {
        const filters = this.props.location.query;
        return filters || {};
    };

    goToNewAudience = () => {
        browserHistory.push('/audiences/new');
    };

    render() {
        const page = this.getCurrentPage();
        const filters = this.getFilters();

        return (
            <Layout
                filters={filters}
                currentPage={page}
                itemsPerPage={this.props.audienceDashboardState.itemsPerPage}
                maxResults={this.props.audienceDashboardState.maxResults}
                isLoading={this.props.audienceDashboardState.isLoading}
                audiences={this.props.audiences}
                downloadAudience={this.downloadAudience}
                audienceIdBeingDownloaded={
                    this.props.audienceDashboardState.audienceIdBeingDownloaded
                }
                goToNew={this.goToNewAudience}
                search={this.search}
            />
        );
    }
}

export default connect(selector)(AudienceDashboard);

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';

import CampaignDetail from '../../modules/campaign-detail';
import { CampaignMetrics } from '../../modules/campaign-metrics';
import CampaignProgress from '../../modules/campaign-progress';

const useStyles = makeStyles(() => ({
    sectionWrapper: {
        padding: 16,
    },
}));

const CampaignOverviewLayoutV2 = props => {
    const {
        campaign,
        campaignStats,
        campaignHealth,
        toggleCampaignStatus,
        duplicateCampaign,
        openCampaignEditForm,
        openCampaignHistory,
        campaignStatsIsLoading,
        organizationTimezone,
        campaignCreator,
        campaignDataShowing,
        currentFlight,
    } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.sectionWrapper}>
            <Grid item xs={12} sm={6} md={3}>
                <CampaignDetail
                    campaign={campaign}
                    openCampaignEditForm={openCampaignEditForm}
                    duplicateCampaign={duplicateCampaign}
                    openCampaignHistory={openCampaignHistory}
                    toggleCampaignStatus={toggleCampaignStatus}
                    campaignCreator={campaignCreator}
                    campaignHealth={campaignHealth}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <CampaignMetrics
                    campaignStats={campaignStats}
                    campaignStatsIsLoading={campaignStatsIsLoading}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <CampaignProgress
                    campaignDataShowing={campaignDataShowing}
                    currentFlight={currentFlight}
                    campaign={campaign}
                    campaignStats={campaignStats}
                    campaignStatsIsLoading={campaignStatsIsLoading}
                    organizationTimezone={organizationTimezone}
                />
            </Grid>
        </Grid>
    );
};

export default CampaignOverviewLayoutV2;

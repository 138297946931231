import _ from 'lodash';

import c from 'common/constants/flux-events';
import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.organizations';

export default function organizations(state = {}, action) {
    switch (action.type) {
        case c.SYSTEM__AUDIENCES__FETCH__SUCCESS: {
            const { organizationId, audiences } = action.payload;
            const organization = _.get(state, organizationId, {});

            return {
                ...state,
                [organizationId]: {
                    ...organization,
                    ubx_audiences: _.map(audiences, audience => audience.id),
                },
            };
        }
        case c.SYSTEM__ORGANIZATIONS__FETCH__SUCCESS: {
            const { organization, organizations } = action.payload;

            return mergeResource(
                state,
                []
                    .concat(organization)
                    .concat(organizations)
                    .filter(x => x)
            );
        }
        default:
            return state;
    }
}

import React from 'react';

import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

import AccordionItem from 'widgets-v5/expansion-panel-item';
import { isInternalUser } from 'states/profile/business-rules';
import { useBusinessReportEditor } from '../../v2/hook';

function ShowOverdeliveries(props) {
    const { expandPanel, expandedPanel, showErrors, showOverdeliveriesError, draft } = props;
    const { toggleShowSpendOverdeliveries } = useBusinessReportEditor({
        isInternalUser: isInternalUser(),
    });

    return (
        <AccordionItem
            onClick={expandPanel('overdeliveries')}
            expanded={expandedPanel === 'overdeliveries'}
            title="Overdelivery Behaviour"
            required
            error={showErrors && showOverdeliveriesError}
            details={
                <RadioGroup
                    value={draft.showSpendOverdeliveries ? 'show' : 'hide'}
                    onChange={() => {
                        toggleShowSpendOverdeliveries();
                    }}
                >
                    <div>
                        <FormControlLabel
                            value="hide"
                            control={<Radio color="primary" />}
                            label="Hide overdeliveries (default)"
                        />
                        <Box ml={3.3}>
                            <Typography variant={'body2'} gutterBottom>
                                Use this option to match standard Engagefront reports.
                            </Typography>
                            <Typography variant={'body2'}>
                                The following adjustment is made:
                            </Typography>
                            <Typography variant={'body2'} gutterBottom>
                                - Any cost and revenue metrics in excess of budget are discounted
                            </Typography>
                        </Box>
                    </div>
                    <div>
                        <FormControlLabel
                            value="show"
                            control={<Radio color="primary" />}
                            label="Show overdeliveries "
                        />
                        <Box ml={3.3}>
                            <Typography variant="body2" gutterBottom>
                                Displays raw numbers as recorded in our database
                            </Typography>
                        </Box>
                    </div>
                </RadioGroup>
            }
            summary={
                <div>
                    <Typography variant="body1" gutterBottom>
                        {draft.showSpendOverdeliveries === true
                            ? 'Show overdeliveries'
                            : 'Hide overdeliveries'}
                    </Typography>
                </div>
            }
        />
    );
}

export default ShowOverdeliveries;

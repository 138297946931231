import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import querystring from 'utils/querystring';

import { onRefresh, offRefresh } from 'containers/global-refresh';
import Layout from './layout';
import selector from './selector';
import actions from './actions';
import flags from 'containers/flags/service';

class PoiDashboard extends React.Component {
    getCurrentPage = () => {
        return parseInt(this.getFilters().page || 1, 10);
    };

    search = text => {
        const filters = this.getFilters();

        const query = {
            ...filters,
            search: text,
            page: 1,
        };

        browserHistory.push(`/presets/pois?${querystring.serialize(query)}`);
    };

    refreshPage = () => {
        const filters = this.getFilters();
        this.props.dispatch(actions.fetchPageData(filters));
    };

    componentDidMount() {
        const filters = this.getFilters();
        this.props.dispatch(actions.init(filters));

        onRefresh(this.refreshPage);
    }

    componentDidUpdate(prevProps) {
        const prevFilters = prevProps.location.query;
        const filters = this.getFilters();

        if (!_.isEqual(prevFilters, filters)) {
            this.props.dispatch(actions.fetchPageData(filters));
        }
    }

    componentWillUnmount() {
        offRefresh(this.refreshPage);
    }

    getFilters = () => {
        const filters = this.props.location.query;
        return filters || {};
    };

    filterByCategory = category => {
        const filters = this.getFilters();

        const query = {
            ...filters,
            category,
            page: 1,
        };

        browserHistory.push(`/presets/pois?${querystring.serialize(query)}`);
    };

    goToNewPoi = () => {
        browserHistory.push('/presets/pois/new');
    };

    render() {
        return null;
    }
}

export default connect(selector)(PoiDashboard);

import _ from 'lodash';
import env from '../../config';
import store from 'core/store';

const DIMENSION = {
    VERSION: 'dimension1',
    ENVIRONMENT: 'dimension2',
    ORGANIZATION: 'dimension3',
    ORGANIZATION_TYPE: 'dimension4',
    ROLE_TYPE: 'dimension5',
    USER_ID: '&uid',
    PAGE: 'page',
};

const Tracking = {
    init() {
        ga('create', env.API_KEYS.GOOGLE_ANALYTICS, 'auto');
        ga('set', DIMENSION.ENVIRONMENT, env.ENV_NAME);
    },

    // Runs on bootstrap
    setProfile() {
        const userId = _.get(store.getState(), 'profile.userId');
        const organizationId = _.get(store.getState(), 'profile.organizationId');
        const organizationType = _.get(store.getState(), 'profile.ownOrganization.type');
        const globalRole = _.get(store.getState(), 'profile.globalRole');
        const realPersonOrganization = _.get(
            store.getState(),
            'profile.impersonatorsToken.v3_impersonator.organization',
            undefined
        );
        const isImpersonating = _.get(store.getState(), 'profile.isImpersonating', false);

        ga('set', DIMENSION.USER_ID, userId);
        ga('set', DIMENSION.ORGANIZATION, organizationId);

        gtag('config', env.API_KEYS.GOOGLE_ANALYTICS_GA4, {
            user_id: userId,
        });

        gtag('set', 'user_properties', {
            organization_id: organizationId,
            organization_type: organizationType,
            user_role: globalRole,
            imp_organization: isImpersonating ? realPersonOrganization : null,
        });
    },

    // Runs on navigation
    logPageView(url) {
        ga('set', DIMENSION.PAGE, url);
        ga('send', 'pageview');
    },
};

export default Tracking;

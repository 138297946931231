import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import PrefixSuffixInput from 'widgets-v5/prefix-suffix-input';
import FormField from 'widgets-v5/form-field';
import Spacer from 'widgets-v5/spacer';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import { NeutralButton } from 'widgets-v5/buttons';

import { PaceMapping, PACING_STRATEGY_MAPPING } from 'common/constants/pacing';

class Pacing extends React.Component {
    getPlaceholder = metric => {
        const { formData } = this.props;

        if (formData.primary_pacing === metric) {
            return 'Even Pacing';
        }

        return 'Auto';
    };

    getDailyGoal = metric => {
        const { formData } = this.props;
        if (formData.primary_pacing === metric) {
            return 'Even Pacing';
        }

        return 'Auto';
    };

    resetToDefaultValues = field => {
        return () => {
            const { updateDraft } = this.props;
            updateDraft({
                [field]: 0,
            });
        };
    };

    render() {
        const { errors, showErrors, formData } = this.props;

        return (
            <div>
                <div className="ad-form-pacing">
                    <ComposableDataTable condensed>
                        <Row header>
                            <Cell />
                            <Cell>Default Behaviour</Cell>
                            <Cell>Override</Cell>
                            <Cell />
                        </Row>
                        <Row>
                            <Cell>Impressions</Cell>
                            <Cell
                                className={
                                    this.props.formData.max_daily_impressions === 0
                                        ? null
                                        : 'ad-form-pacing-muted'
                                }
                            >
                                {this.getDailyGoal('impressions')}
                            </Cell>
                            <Cell>
                                <FormField>
                                    <PrefixSuffixInput
                                        inputClassName="ad-form-pacing__prefix-input"
                                        suffix="per day"
                                        placeholder="e.g. 10,000"
                                        key={this.getDailyGoal('impressions')}
                                        formatIn={formatGoalValueToString}
                                        formatOut={formatGoalStringToValue}
                                        shouldAllowInput={shouldAllowInputForGoal}
                                        onChange={this.props.onFieldChange('max_daily_impressions')}
                                        value={this.props.formData.max_daily_impressions}
                                    />
                                </FormField>
                            </Cell>
                            <Cell>
                                <NeutralButton
                                    text="Reset"
                                    onClick={this.resetToDefaultValues('max_daily_impressions')}
                                />
                            </Cell>
                        </Row>
                        <Row>
                            <Cell>Clicks</Cell>
                            <Cell
                                className={
                                    this.props.formData.max_daily_clicks === 0
                                        ? null
                                        : 'ad-form-pacing-muted'
                                }
                            >
                                {this.getDailyGoal('clicks')}
                            </Cell>
                            <Cell>
                                <FormField
                                    className="ad-form-pacing__form-wrapper"
                                    classNameChildren="ad-form-pacing__form"
                                    errors={errors.max_daily_clicks}
                                    showErrors={showErrors}
                                    isRequired={false}
                                >
                                    <PrefixSuffixInput
                                        inputClassName="ad-form-pacing__prefix-input"
                                        suffix="per day"
                                        placeholder="e.g. 1000"
                                        key={this.getDailyGoal('clicks')}
                                        formatIn={formatGoalValueToString}
                                        formatOut={formatGoalStringToValue}
                                        shouldAllowInput={shouldAllowInputForGoal}
                                        onChange={this.props.onFieldChange('max_daily_clicks')}
                                        value={this.props.formData.max_daily_clicks}
                                    />
                                </FormField>
                            </Cell>
                            <Cell>
                                <NeutralButton
                                    text="Reset"
                                    onClick={this.resetToDefaultValues('max_daily_clicks')}
                                />
                            </Cell>
                        </Row>
                        {formData.primary_pacing === 'billings' && (
                            <Row>
                                <Cell>Revenue (Spend)</Cell>
                                <Cell
                                    className={
                                        this.props.formData.max_daily_billings === 0
                                            ? null
                                            : 'ad-form-pacing-muted'
                                    }
                                >
                                    {this.getDailyGoal('billings')}
                                </Cell>
                                <Cell>
                                    <div className="ad-form-pacing__form">
                                        <FormField>
                                            <PrefixSuffixInput
                                                inputClassName="ad-form-pacing__prefix-input"
                                                prefix="$"
                                                placeholder="e.g. 1000"
                                                suffix="per day"
                                                key={this.getDailyGoal('billings')}
                                                formatIn={formatSpendValueToString}
                                                formatOut={formatSpendStringToValue}
                                                shouldAllowInput={shouldAllowInputForSpend}
                                                onChange={this.props.onFieldChange(
                                                    'max_daily_billings'
                                                )}
                                                value={this.props.formData.max_daily_billings}
                                            />
                                        </FormField>
                                        <Spacer type="small" />
                                    </div>
                                </Cell>
                                <Cell>
                                    <NeutralButton
                                        text="Reset"
                                        onClick={this.resetToDefaultValues('max_daily_billings')}
                                    />
                                </Cell>
                            </Row>
                        )}
                        <Row>
                            <Cell>
                                {formData.primary_pacing === 'spend'
                                    ? 'Total Cost (Spend)'
                                    : 'Total Cost'}
                            </Cell>
                            <Cell
                                className={
                                    this.props.formData.max_daily_spend_local === 0
                                        ? null
                                        : 'ad-form-pacing-muted'
                                }
                            >
                                {this.getDailyGoal('spend')}
                            </Cell>
                            <Cell>
                                <FormField>
                                    <PrefixSuffixInput
                                        inputClassName="ad-form-pacing__prefix-input"
                                        prefix="$"
                                        suffix="per day"
                                        placeholder="e.g. 1000"
                                        key={this.getDailyGoal('spend')}
                                        disabled={formData.primary_pacing === 'billings'}
                                        formatIn={formatSpendValueToString}
                                        formatOut={formatSpendStringToValue}
                                        shouldAllowInput={shouldAllowInputForSpend}
                                        onChange={this.props.onFieldChange('max_daily_spend_local')}
                                        value={this.props.formData.max_daily_spend_local}
                                    />
                                </FormField>
                            </Cell>
                            <Cell>
                                {formData.primary_pacing !== 'billings' && (
                                    <NeutralButton
                                        text="Reset"
                                        onClick={this.resetToDefaultValues('max_daily_spend_local')}
                                    />
                                )}
                            </Cell>
                        </Row>
                    </ComposableDataTable>
                </div>
            </div>
        );
    }
}

function shouldAllowInputForGoal(input) {
    if (input === '0') {
        return false;
    }
    // Allow empty input
    if (!input) {
        return true;
    }
    // Check if all digits
    if (!/^[\d,]*$/g.test(input)) {
        return false;
    }
    // Check length of digits
    const numDigits = input.match(/\d/g).length;
    return numDigits <= 9;
}

function formatGoalValueToString(value) {
    if (!value) {
        return '';
    } else {
        return value.toString().replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
    }
}

function formatGoalStringToValue(string) {
    if (string === '') {
        return 0;
    } else {
        return parseInt(string.replace(/,/g, ''));
    }
}

function shouldAllowInputForSpend(input) {
    if (input === '0') {
        return false;
    }
    // Allow empty input
    if (!input) {
        return true;
    }
    // Check allowable characters
    if (!/^[\d,]*(\.\d{0,2})?$/g.test(input)) {
        return false;
    }
    // Check character length
    const [dollars, cents] = input.split('.');
    const numDollarsDigits = dollars.match(/\d/g).length;
    const numCentsDigits = cents ? cents.length : 0;
    return numDollarsDigits <= 9 && numCentsDigits <= 2;
}

function formatSpendValueToString(value) {
    if (!value) {
        return '';
    } else {
        const [dollars, cents] = value.toFixed(2).split('.');
        const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
        return `${formattedDollars}.${cents}`;
    }
}

function formatSpendStringToValue(string) {
    if (string === '') {
        return 0;
    } else {
        return parseFloat(string.replace(/,/g, ''));
    }
}

export function ImpressionsPacing(props) {
    const { updateDraft } = props;
    const [value, setValue] = React.useState(
        props.formData.max_daily_impressions > 0 ? PaceMapping.Override : PaceMapping.Default
    );

    const handleChange = event => {
        setValue(event.target.value);

        if (event.target.value === PaceMapping.Default) {
            updateDraft({
                ['max_daily_impressions']: 0,
            });
        }
    };

    return (
        <div>
            <RadioGroupOptions value={value} handleChange={handleChange} />
            {value === 'override' && (
                <PrefixSuffixInput
                    inputClassName="ad-form-pacing__prefix-input"
                    suffix="per day"
                    placeholder="e.g. 10,000"
                    key={'Auto'}
                    formatIn={formatGoalValueToString}
                    formatOut={formatGoalStringToValue}
                    shouldAllowInput={shouldAllowInputForGoal}
                    onChange={props.onFieldChange('max_daily_impressions')}
                    value={props.formData.max_daily_impressions}
                />
            )}
        </div>
    );
}

export function ClicksPacing(props) {
    const { updateDraft } = props;
    const [value, setValue] = React.useState(
        props.formData.max_daily_clicks > 0 ? PaceMapping.Override : PaceMapping.Default
    );

    const handleChange = event => {
        setValue(event.target.value);

        if (event.target.value === PaceMapping.Default) {
            updateDraft({
                ['max_daily_clicks']: 0,
            });
        }
    };

    return (
        <div>
            <RadioGroupOptions value={value} handleChange={handleChange} />
            {value === PaceMapping.Override && (
                <FormField
                    className="ad-form-pacing__form-wrapper"
                    classNameChildren="ad-form-pacing__form"
                    errors={props.errors.max_daily_clicks}
                    showErrors={props.showErrors}
                    isRequired={false}
                >
                    <PrefixSuffixInput
                        inputClassName="ad-form-pacing__prefix-input"
                        suffix="per day"
                        placeholder="e.g. 1000"
                        key={'Auto'}
                        formatIn={formatGoalValueToString}
                        formatOut={formatGoalStringToValue}
                        shouldAllowInput={shouldAllowInputForGoal}
                        onChange={props.onFieldChange('max_daily_clicks')}
                        value={props.formData.max_daily_clicks}
                    />
                </FormField>
            )}
        </div>
    );
}

export function RevenuePacing(props) {
    const { updateDraft } = props;
    const [value, setValue] = React.useState(
        props.formData.max_daily_billings > 0 ? PaceMapping.Override : PaceMapping.Default
    );

    const handleChange = event => {
        setValue(event.target.value);

        if (event.target.value === PaceMapping.Default) {
            updateDraft({
                ['max_daily_billings']: 0,
            });
        }
    };

    return (
        <div>
            <RadioGroupOptions value={value} handleChange={handleChange} />
            {value === PaceMapping.Override && (
                <PrefixSuffixInput
                    inputClassName="ad-form-pacing__prefix-input"
                    prefix="$"
                    placeholder="e.g. 1000"
                    suffix="per day"
                    key={'Auto'}
                    formatIn={formatSpendValueToString}
                    formatOut={formatSpendStringToValue}
                    shouldAllowInput={shouldAllowInputForSpend}
                    onChange={props.onFieldChange('max_daily_billings')}
                    value={props.formData.max_daily_billings}
                />
            )}
        </div>
    );
}

export function TotalMediaSpendPacing(props) {
    const { updateDraft } = props;
    const [value, setValue] = React.useState(
        props.formData.max_daily_spend_local > 0 ? PaceMapping.Override : PaceMapping.Default
    );

    const handleChange = event => {
        setValue(event.target.value);

        if (event.target.value === PaceMapping.Default) {
            updateDraft({
                ['max_daily_spend_local']: 0,
            });
        }
    };

    return (
        <div>
            <RadioGroupOptions value={value} handleChange={handleChange} />
            {value === PaceMapping.Override && (
                <PrefixSuffixInput
                    inputClassName="ad-form-pacing__prefix-input"
                    prefix="$"
                    suffix="per day"
                    placeholder="e.g. 1000"
                    key={'Auto'}
                    disabled={props.formData.primary_pacing === 'billings'}
                    formatIn={formatSpendValueToString}
                    formatOut={formatSpendStringToValue}
                    shouldAllowInput={shouldAllowInputForSpend}
                    onChange={props.onFieldChange('max_daily_spend_local')}
                    value={props.formData.max_daily_spend_local}
                />
            )}
        </div>
    );
}

function RadioGroupOptions(props) {
    return (
        <>
            <RadioGroup
                aria-label="position"
                name="position"
                value={props.value}
                onChange={props.handleChange}
            >
                <FormControlLabel
                    value={PaceMapping.Default}
                    control={<Radio color="primary" />}
                    label={PACING_STRATEGY_MAPPING[PaceMapping.Default].label}
                />
                <FormControlLabel
                    value={PaceMapping.Override}
                    control={<Radio color="primary" />}
                    label={PACING_STRATEGY_MAPPING[PaceMapping.Override].label}
                />
            </RadioGroup>
        </>
    );
}

export default Pacing;

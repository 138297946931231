import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class extends React.Component {
    static displayName = 'TopBottomBarLayout';

    static propTypes = {
        className: PropTypes.string,
        topBar: PropTypes.element,
        bottomBar: PropTypes.element,
        workspace: PropTypes.element.isRequired,
    };

    render() {
        return (
            <div className={classnames('ef4-top-bottom-bar', this.props.className)}>
                {this.props.topBar && (
                    <div className="ef4-top-bottom-bar__top-bar">{this.props.topBar}</div>
                )}
                <div
                    className={classnames('ef4-top-bottom-bar__workspace', {
                        'ef4-top-bottom-bar__overflow': this.props.overflow,
                    })}
                >
                    {this.props.workspace}
                </div>
                {this.props.bottomBar && (
                    <div className="ef4-top-bottom-bar__bottom-bar">{this.props.bottomBar}</div>
                )}
            </div>
        );
    }
}

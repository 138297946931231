import _ from 'lodash';

import mergeResource from 'utils/merge-resource';

export const NAME = 'resources.businessReports';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SYSTEM__BUSINESS_REPORT__FETCH__SUCCESS': {
            const { businessReport, businessReports } = action.payload;
            const incoming = []
                .concat(businessReport)
                .concat(businessReports)
                .filter(x => x);
            const adsResource = mergeResource(state, incoming);

            return adsResource;
        }
        default:
            return state;
    }
}

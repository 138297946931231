import React from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LinearProgressWithLabel } from 'widgets-v6/progress';
import { useBulkEditProgressBar } from './hook';

export const BulkEditProgressBar = ({ selectedCreatives }) => {
    const { processedCreatives } = useBulkEditProgressBar();

    return (
        <Box width="100%">
            <Typography>
                {`Processed ${processedCreatives} out of ${selectedCreatives.length}`}
            </Typography>
            <LinearProgressWithLabel
                value={(processedCreatives / selectedCreatives.length) * 100}
            />
        </Box>
    );
};

export default React.memo(connect()(BulkEditProgressBar));

import _ from 'lodash';
import { getDefaults } from 'services/resources/ad-preset';
import flags from 'containers/flags/service';

export default function selector(storeState, props) {
    const adPresets = _.get(storeState, 'resources.adPresets', undefined);
    const adPresetsAttr = _.map(_.keys(adPresets), adPresetId => {
        return adPresets[adPresetId].attr;
    });

    const adPreset = _.get(storeState, `adPresetNew`, {});

    const out = {
        isPresetsLoading: adPreset.isPresetsLoading && adPresetsAttr.length < 1,
        adPresets: adPresetsAttr,
        ownOrganization: _.get(storeState, 'profilePage.organization'),
    };
    return out;
}

import _ from 'lodash';

import { ROLE_INTENTS, ALL_INTENTS } from 'common/constants/role-intents';
import store from 'core/store';
const ROOT_ORG = '53271100d26f9a2f917ed289';
const ADDICTIVE_ORG = '53271100d26f9a2f917ed290';
const SELF_SERVE_ROOT_ORG = '57c5f37c3fa5a4b6820a48c3';
const SPAIN_ROOT_ORG = '53271100d26f9a2f917ed289';
const ELTIEMPO_ORG = '5fd7da80f30daf4460d6ef35';

// @object profileState
// @string || @string[] intents
export function userCan(profileState, intents) {
    const finalRole = _.get(profileState, 'globalRole');

    let finalIntents = typeof intents === 'string' ? [intents] : intents;

    return _.every(finalIntents, intent => {
        // Check if the incoming intent exists
        if (ALL_INTENTS[intent] === undefined) {
            throw new Error(`Invalid intent: ${intent}`);
        }

        return !!_.get(ROLE_INTENTS, `${finalRole}.${intent}`);
    });
}

// The big difference here is that it confirms that the underlying user
// has permission to do the action. For impersonated sessions, this means
// the original user. For regular user sessions, it means the regular user.
//
// @object profileState
// @string || @string[] intents
export function userTrulyCan(profileState, intents) {
    const finalRole = _.get(profileState, 'authToken.v3_impersonator.role');

    let finalIntents = typeof intents === 'string' ? [intents] : intents;

    return _.every(finalIntents, intent => {
        // Check if the incoming intent exists
        if (ALL_INTENTS[intent] === undefined) {
            throw new Error(`Invalid intent: ${intent}`);
        }
        return !!_.get(ROLE_INTENTS, `${finalRole}.${intent}`);
    });
}

// @object profileState
// @string || @string[] [orgNames]
export function userBelongsTo(profileState, orgNames) {
    const usersOrgName = _.get(profileState, 'authToken.organization.name').toLowerCase();

    let finalOrgNames =
        typeof orgNames === 'string'
            ? [orgNames.toLowerCase()]
            : _.map(orgNames, n => n.toLowerCase());

    return _.includes(finalOrgNames, usersOrgName);
}

// The big difference here is that it confirms that the underlying user
// has permission to do the action. For impersonated sessions, this means
// the original user. For regular user sessions, it means the regular user.
//
// @object profileState
// @string || @string[] intents
export function userTrulyBelongsTo(profileState, orgNames) {
    const usersOrgName = _.get(
        profileState,
        'authToken.v3_impersonator.organization_name'
    ).toLowerCase();

    let finalOrgNames =
        typeof orgNames === 'string'
            ? [orgNames.toLowerCase()]
            : _.map(orgNames, n => n.toLowerCase());

    return _.includes(finalOrgNames, usersOrgName);
}

export function isInternalUser() {
    const orgId = _.get(store.getState(), 'profile.organizationId');

    // Internal users are part of Root, Addictive Mobility org, Spain root, or elTiempo
    return _.includes(
        [ROOT_ORG, ADDICTIVE_ORG, SELF_SERVE_ROOT_ORG, SPAIN_ROOT_ORG, ELTIEMPO_ORG],
        orgId
    );
}

/**
 * Checks if the user is part of the Root or Spain root organization,
 * either directly or through impersonation.
 *
 * @returns {boolean} True if the user is part of the Root or Spain root organization, false otherwise.
 */
export function isRootOrImpersonatedByRoot() {
    const impersonatorOrUserOrganizationID = _.get(
        store.getState(),
        'profile.authToken.v3_impersonator.organization'
    );

    return [ROOT_ORG, SPAIN_ROOT_ORG].includes(impersonatorOrUserOrganizationID);
}

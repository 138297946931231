import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';

import Section from 'widgets-v5/section';
import { IncludeExcludeLayout } from 'widgets-v6/layout';

import { TargetDeviceLanguage } from 'forms/ad-form/modules/target-device-language';
import { TargetDeviceLanguageExclude } from 'forms/ad-form/modules/target-device-language-exclude';
import { getDeviceLanguage } from 'containers/ad-summary';
import shallowCompare from 'utils/shallow-compare';
import classnames from 'classnames';

export class LanguageForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="User Language"
                fields={['target_device_language', 'target_device_language_exclude']}
                shouldRefreshPredictionsOnSave={false}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <div className="media-plan-form__language-selector">
                        <IncludeExcludeLayout type="Include" marginBottom>
                            <TargetDeviceLanguage
                                value={draft.target_device_language}
                                errors={errors.target_device_language}
                                showErrors={shouldShowErrors}
                                onChange={val => updateDraft({ target_device_language: val })}
                            />
                        </IncludeExcludeLayout>
                        <IncludeExcludeLayout type="Exclude">
                            <TargetDeviceLanguageExclude
                                value={draft.target_device_language_exclude}
                                errors={errors.target_device_language_exclude}
                                showErrors={shouldShowErrors}
                                onChange={val =>
                                    updateDraft({ target_device_language_exclude: val })
                                }
                            />
                        </IncludeExcludeLayout>
                    </div>
                )}
            </BaseColumnForm>
        );
    }
}
export class LanguageSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error } = this.props;

        let label;
        if (ad.target_device_language.length > 0 || ad.target_device_language_exclude.length > 0) {
            label = (
                <div>
                    {ad.target_device_language.length > 0 && (
                        <IncludeExcludeLayout type="Include" marginBottom>
                            {_.map(
                                getDeviceLanguage(ad.target_device_language).split(','),
                                language => (
                                    <div key={language}>{language}</div>
                                )
                            )}
                        </IncludeExcludeLayout>
                    )}

                    {ad.target_device_language_exclude.length > 0 && (
                        <IncludeExcludeLayout type="Exclude">
                            {_.map(
                                getDeviceLanguage(ad.target_device_language_exclude).split(','),
                                language => (
                                    <div key={language}>{language}</div>
                                )
                            )}
                        </IncludeExcludeLayout>
                    )}
                </div>
            );
        } else {
            label = 'All Languages';
        }

        return <div>{error ? <div>{error}</div> : <div>{label}</div>}</div>;
    }
}

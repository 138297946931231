import get from 'lodash/get';

export default function selector(storeState) {
    const { ftaLocationLists, loading } = get(storeState, 'ftaLocationLists');

    return {
        ftaLocationLists,
        loading,
    };
}

import _ from 'lodash';
import { getSegments } from './constants';

export default function selector(storeState, props) {
    const audienceSegments = _.get(storeState, 'audienceSegmentPicker.audienceSegments', []);
    const flexSegments = _.get(storeState, 'audienceSegmentPicker.flexSegments', []);
    const filteredFlexSegments = _.filter(flexSegments, segment => segment.id);

    const allSegments = [...audienceSegments, ...filteredFlexSegments];

    const keywords = _.get(storeState, 'audienceSegmentPickerModal.keywords');
    const sourceType = _.get(storeState, 'audienceSegmentPickerModal.sourceType');
    const isTableLoading = _.get(storeState, 'audienceSegmentPickerModal.isLoading');
    const allTypesValue = _.get(storeState, 'audienceSegmentPickerModal.allTypesValue');
    const tab = _.get(storeState, 'audienceSegmentPickerModal.tab');
    const restrictedSegments = _.get(storeState, 'profile.restrictedSegments', []);
    const orgId = _.get(storeState, 'profile.organizationId', void 0);

    let audienceSegmentOptions = getSegments({
        allSegments,
        sourceType,
        tab,
        restrictedSegments,
        shouldHideFlexSegments: props.shouldHideFlexSegments,
        platforms: props.platforms,
        platform: props.platform,
        keywords,
        isCrossPlatformCampaign: props.isCrossPlatformCampaign,
        orgId,
    });

    if (props.isCrossPlatformCampaign) {
        audienceSegmentOptions = audienceSegmentOptions;
    } else {
        audienceSegmentOptions = _.filter(audienceSegmentOptions, option => {
            const platform = props.platform === 'desktop' ? 'mweb' : props.platform;

            return _.includes(option.supports, platform);
        });
    }

    if (restrictedSegments && restrictedSegments.length > 0) {
        audienceSegmentOptions = _.filter(
            audienceSegmentOptions,
            option => !_.includes(restrictedSegments, option.source_type)
        );
    }

    return {
        audienceSegmentOptions,
        keywords,
        sourceType,
        isTableLoading,
        allTypesValue,
        restrictedSegments,
        orgId,
    };
}

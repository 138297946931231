import _ from 'lodash';
import numeral from 'numeral';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';

import { getCurrencySignforCurrency } from 'utils/formatting';
import { REDUCER_KEY } from './redux';
import flags from 'containers/flags/service';
import { getEnvironmentSettings } from 'services/environment';
import { NEW_DIMENSIONS } from './constants';
import { adGroupSelector } from '../selector';
import heatmapSelector from '../selector/heatmap';

export const selectCampaignId = state => _.get(state, `${REDUCER_KEY}.campaignId`);
export const selectCampaign = state => _.get(state, `${REDUCER_KEY}.campaign`);
export const selectCurrency = state => _.get(state, `${REDUCER_KEY}.campaign.currency`, 'USD');
export const selectMetrics = state => _.get(state, `${REDUCER_KEY}.metrics`);
export const selectDimensions = state => _.get(state, `${REDUCER_KEY}.dimensions`);
export const selectStats = state => _.get(state, `${REDUCER_KEY}.pivotTableStats`);
export const selectSortColumn = state => _.get(state, `${REDUCER_KEY}.sortColumn`);
export const selectSortDirection = state => _.get(state, `${REDUCER_KEY}.sortDirection`);
export const selectExpandedRows = state => _.get(state, `${REDUCER_KEY}.expandedRows`);
export const selectExpandedPixelRows = state => _.get(state, `${REDUCER_KEY}.expandedPixelRows`);
export const selectSelectedDimensions = state => _.get(state, `${REDUCER_KEY}.selectedDimensions`);
export const selectSelectedSubevents = state => _.get(state, `${REDUCER_KEY}.selectedSubevents`);
export const selectShouldHideZeroRows = state => _.get(state, `${REDUCER_KEY}.shouldHideZeroRows`);
export const selectDimensionItems = state => _.get(state, `${REDUCER_KEY}.dimensionItems`);
export const selectHeatmapItems = state => _.get(state, `${REDUCER_KEY}.heatmapItems`);
export const selectDateRange = state => _.get(state, `reportExplore.dateRange`);
export const selectDimensionMatch = state => _.get(state, `reportExplore.dimensionFilter`, {});
export const selectScopeMatch = state => _.get(state, `reportExplore.scopeFilter`, {});
export const selectCampaignPageContainer = state => _.get(state, `campaignPageContainer`, {});
export const selectUserOrganization = state => _.get(state, 'profile.organizationId', '');

export const selectMetricsWithValue = createSelector(
    selectMetrics,
    selectStats,
    selectCurrency,
    (metrics, stats, currency) => {
        return _(metrics)
            .map(metric => ({
                ...metric,
                valueFormatted: formatMetric({
                    metric,
                    value: _.get(stats, metric.name, 0),
                    currency,
                }),
            }))
            .value();
    }
);

export const selectPerformanceMetricOptions = createSelector(
    selectMetricsWithValue,
    metrics => {
        return _.filter(metrics, metric => metric.category === 'Performance');
    }
);

export const selectBeaconMetricOptions = createSelector(
    selectMetricsWithValue,
    metrics => {
        return _.filter(metrics, metric => metric.category === 'Engagements');
    }
);

export const selectOverallConversionMetricOptions = createSelector(
    selectMetricsWithValue,
    metrics => {
        return _.filter(metrics, metric => metric.category === 'Overall Conversions');
    }
);

export const selectAdvertiserConversionMetricOptions = createSelector(
    selectMetricsWithValue,
    metrics => {
        return _.filter(metrics, metric => metric.category === 'Advertiser Pixels');
    }
);

export const selectCampaignConversionMetricOptions = createSelector(
    selectMetricsWithValue,
    metrics => {
        return _.filter(metrics, metric => metric.category === 'Campaign Pixels');
    }
);

export const selectSelectedMetrics = createSelector(
    selectMetrics,
    metrics => {
        return _.filter(metrics, metric => metric.selected);
    }
);

export const selectSelectedExpandedMetrics = createSelector(
    selectSelectedMetrics,
    selectedMetrics => {
        return _(selectedMetrics)
            .map(metric => {
                if (metric.name === 'unique_users') {
                    return getEnvironmentSettings().getReportingDatasource() === 'snowflake'
                        ? [
                              {
                                  ...metric,
                                  name: 'unique_campaign',
                                  label: 'Unique Users',
                              },
                          ]
                        : [
                              {
                                  ...metric,
                                  name: 'unique_campaign',
                                  label: 'Unique Users',
                              },
                              {
                                  ...metric,
                                  name: 'unique_ad',
                                  label: 'Uniques By Ad',
                              },
                              {
                                  ...metric,
                                  name: 'unique_ad_creative',
                                  label: 'Uniques By Ad-Creative',
                              },
                              {
                                  ...metric,
                                  name: 'unique_creative',
                                  label: 'Uniques By Creative',
                              },
                          ];
                }

                return metric;
            })
            .flatten()
            .value();
    }
);

export const selectDimensionOptions = createSelector(
    selectDimensions,
    selectCampaign,
    (dimensions, campaign) => {
        return _(dimensions)
            .map(dimension => {
                if (dimension.name === 'adgroup') {
                    return {
                        ...dimension,
                        children: _.map(_.get(campaign, 'customDimensions'), customDimension => ({
                            name: customDimension.id,
                            label: customDimension.name,
                        })),
                    };
                }
                return dimension;
            })
            .map(dimension => ({
                ...dimension,
                label: dimension.label,
                value: dimension.name,
                deletable: true,
                children: _.map(dimension.children, dimension => ({
                    ...dimension,
                    label: dimension.label,
                    value: dimension.name,
                    deletable: true,
                })),
            }))
            .value();
    }
);

export const selectSelectableDimensionOptions = createSelector(
    selectDimensionOptions,
    selectSelectedDimensions,
    selectCampaign,
    (dimensionOptions, selectedDimensions, campaign) => {
        return _(dimensionOptions)
            .filter(dimension => {
                return !_.includes(selectedDimensions, dimension.value);
            })
            .map(dimension => ({
                ...dimension,
                children: _.filter(dimension.children, dimension => {
                    return !_.includes(selectedDimensions, dimension.value);
                }),
            }))
            .map(dimension => {
                // If this dimension is new then we should block it if the campaign
                // starts before April 1st 2023. This is due to invalid data before that date.
                return {
                    ...dimension,
                    invalidBecauseOfDate:
                        campaign &&
                        campaign.start &&
                        moment(campaign.start)
                            .tz(campaign.default_timezone)
                            .isBefore(moment('2023-04-01').tz(campaign.default_timezone)) &&
                        NEW_DIMENSIONS.includes(dimension.value),
                };
            })
            .value();
    }
);

export const selectAllDimensionOptionsFlat = createSelector(
    selectDimensionOptions,
    dimensionOptions => {
        return _(dimensionOptions)
            .map(dimension => _.concat(dimension, dimension.children))
            .flatten()
            .filter(dimension => dimension)
            .value();
    }
);

export const selectSelectedDimensionOptionsFlat = createSelector(
    selectAllDimensionOptionsFlat,
    selectSelectedDimensions,
    (dimensionOptions, selectedDimensions) => {
        return _.map(selectedDimensions, dimensionName =>
            _.find(dimensionOptions, { name: dimensionName })
        );
    }
);

export const selectHasDimensionsAvailable = createSelector(
    selectSelectableDimensionOptions,
    selectableDimensions => {
        return _.size(selectableDimensions) > 0;
    }
);

export const selectPivotTableRequest = createSelector(
    selectSelectedDimensions,
    selectCampaignId,
    selectDateRange,
    selectDimensionMatch,
    selectScopeMatch,
    selectCampaignPageContainer,
    (
        selectedDimensions,
        campaignId,
        dateRange,
        dimensionMatch,
        scopeMatch,
        campaignPageContainer
    ) => {
        const split = selectedDimensions;

        const request = {
            ..._.omit(dateRange, ['cache', 'cacheKey', 'campaignStart', 'campaignEnd', 'selectedKey']),
            campaign_id: campaignId,
            split,
            match: {
                ..._.omit(dimensionMatch, ['campaign_id', 'cache', 'cacheKey']),
                ..._.omit(scopeMatch, ['cache', 'cacheKey']),
            },
            datasource: getEnvironmentSettings().getReportingDatasource(),
            isReportPage: true,
            queryTag: 'ef_campaign_report_v2',
        };

        const { viewBy } = campaignPageContainer;
        if (viewBy && viewBy !== 'campaign') {
            request.match.flight_id = [viewBy];
        }
        return request;
    }
);

export const selectDimensionDataRequest = (component) => createSelector(
    selectCampaignId,
    selectDateRange,
    selectDimensionMatch,
    selectScopeMatch,
    selectCampaignPageContainer,
    (
        campaignId,
        dateRange,
        dimensionMatch,
        scopeMatch,
        campaignPageContainer
    ) => {
        const request = {
            ..._.omit(dateRange, ['cache', 'cacheKey', 'campaignStart', 'campaignEnd', 'selectedKey']),
            campaign_id: campaignId,
            split: component.groupings || [component.grouping],
            match: {
                ..._.omit(dimensionMatch, ['campaign_id', 'cache', 'cacheKey']),
                ..._.omit(scopeMatch, ['cache', 'cacheKey']),
            },
            datasource: getEnvironmentSettings().getReportingDatasource(),
            isReportPage: true,
            queryTag: 'ef_campaign_report_v2',
        };

        const { viewBy } = campaignPageContainer;
        if (viewBy && viewBy !== 'campaign') {
            request.match.flight_id = [viewBy];
        }
        return request;
    }
);

export const selectDimensionsWithValues = createSelector(
    state => state,
    selectDimensionItems,
    selectCampaignId,
    (state, components, campaignId) => {
        return adGroupSelector(components, state, campaignId);
    }
);

export const selectHeatmapWithValues = createSelector(
    state => state,
    selectHeatmapItems,
    selectCampaignId,
    (state, components, campaignId) => {
        const heatmapItem = heatmapSelector(components, state, campaignId);
        return [heatmapItem];
    }
);

export const selectPivotTableData = createSelector(
    selectStats,
    selectSelectedMetrics,
    selectSortColumn,
    selectSortDirection,
    selectExpandedRows,
    selectExpandedPixelRows,
    selectSelectedDimensions,
    selectShouldHideZeroRows,
    selectCurrency,
    (...args) => {
        const [
            statsRoot,
            selectedMetrics,
            sortColumn,
            sortDirection,
            expandedRows,
            expandedPixelRows,
            selectedDimensions,
            shouldHideZeroRows,
            currency,
        ] = args;

        let stat = sortStats({
            statsRoot,
            sortColumn,
            sortDirection,
        });

        stat = expandCollapseStats({ stat, expandedRows });

        const stats = flattenStats(stat);

        let rows = createBodyRowsFromStats({
            stats,
            selectedMetrics,
            expandedRows,
            expandedPixelRows,
            selectedDimensions,
            currency,
        });

        rows = hideZeroRows({
            rows,
            selectedMetrics,
            shouldHideZeroRows,
        });

        rows = addTotalRow({
            rows,
            selectedMetrics,
            selectedDimensions,
            statsRoot,
            currency,
        });

        rows = addSubHeaderRow({
            rows,
            selectedMetrics,
            statsRoot,
        });

        rows = addHeaderRow({
            rows,
            selectedMetrics,
            sortColumn,
            sortDirection,
        });

        const headerRows = _.filter(rows, row => row.type === 'header');
        const totalRows = _.filter(rows, row => row.type === 'total');
        const bodyRows = _.filter(rows, row => row.type === 'body');
        return {
            headerRows,
            totalRows,
            bodyRows,
        };
    }
);

function sortStats({ statsRoot, sortDirection, sortColumn }) {
    if (!statsRoot) {
        return statsRoot;
    }

    if (_.size(statsRoot.stats) === 0) {
        return statsRoot;
    }

    const stats = _.map(statsRoot.stats, stat => {
        return sortStats({
            statsRoot: stat,
            sortDirection,
            sortColumn,
        });
    });

    let copy = { ...statsRoot };

    if (sortColumn === 'dimension') {
        copy.stats = _.orderBy(stats, stat => _.last(stat.split).order, sortDirection);
    } else {
        copy.stats = _.orderBy(stats, stat => stat[sortColumn], sortDirection);
    }

    return copy;
}

function expandCollapseStats({ stat, expandedRows }) {
    if (!stat) {
        return stat;
    }

    if (_.isEmpty(stat.stats)) {
        return stat;
    }

    const isExpanded = isRowExpanded({ ...stat, expandedRows });

    let copy = { ...stat };
    if (isExpanded) {
        copy.stats = _.map(stat.stats, stat => {
            return expandCollapseStats({ stat, expandedRows });
        });
    } else {
        copy.stats = [];
    }

    return copy;
}

function flattenStats(stat) {
    const stats = [];
    if (!stat) {
        return stats;
    }

    _.each(stat.stats, _stat => {
        stats.push(_stat);
        stats.push(...flattenStats(_stat));
    });

    return stats;
}

function addTotalRow({ rows, statsRoot, selectedMetrics, currency }) {
    if (!statsRoot) {
        return rows;
    }

    const nonConversionMetrics = _.filter(
        selectedMetrics,
        metric => metric.category !== 'Conversion'
    );
    const conversionMetrics = _.filter(selectedMetrics, metric => metric.category === 'Conversion');

    const conversionMetricsByColumn = _(selectedMetrics)
        .filter(metric => metric.category === 'Conversion')
        .groupBy(metric => _.get(metric, 'meta.column'))
        .value();

    const uniqueMetric = 'unique_campaign';

    const row = {
        ...statsRoot,
        type: 'total',
        columns: _.concat(
            {
                name: 'total',
                label: 'Total',
                colSpan: 1,
            },
            _.map(!flags.isEnabled('en_4598_new_report_conversion_columns') ? nonConversionMetrics : selectedMetrics, metric => ({
                name: metric.name,
                label: formatMetric({
                    metric,
                    value: statsRoot[metric.name === 'unique_users' ? uniqueMetric : metric.name],
                    currency,
                }),
                colSpan: 1,
            })),
            // We can remove these columns when removing the en_4598 flag.
            _.isEmpty(conversionMetrics) || flags.isEnabled('en_4598_new_report_conversion_columns')
                ? []
                : {
                      name: 'pixels',
                      label: '',
                      colSpan: 1,
                      minWidth: 200,
                  },
            // We can remove these columns when removing the en_4598 flag.
            _.isEmpty(conversionMetrics) || flags.isEnabled('en_4598_new_report_conversion_columns')
                ? []
                : _.map(conversionMetricsByColumn, (metrics, columnLabel) => {
                        return {
                            name: columnLabel,
                            label: formatMetric({
                                metric: _.first(metrics),
                                value: _.sumBy(metrics, metric => statsRoot[metric.name]),
                                currency,
                            }),
                            colSpan: 1,
                        };
                  })
        ),
    };

    return [row, ...rows];
}

function addHeaderRow(args) {
    const { rows, selectedMetrics, sortColumn, sortDirection } = args;

    const nonConversionMetrics = _.filter(
        selectedMetrics,
        metric => metric.category !== 'Conversion'
    );

    const conversionMetricsByColumn = _(selectedMetrics)
        .filter(metric => metric.category === 'Conversion')
        .groupBy(metric => _.get(metric, 'meta.column'))
        .map((metrics, columnLabel) => columnLabel)
        .value();

    const conversionMetricsByReportingName = _(selectedMetrics)
        .filter(metric => {
            return metric.category === 'Conversion'
        })
        .groupBy(metric => _.get(metric, 'meta.reporting_name'))
        .value();
    const conversionKeys = _.keys(conversionMetricsByReportingName);

    const columns = !flags.isEnabled('en_4598_new_report_conversion_columns') ? _.concat(
        {
            name: 'dimension',
            label: 'Dimensions',
            sortable: true,
            colSpan: 1,
            rowSpan: flags.isEnabled('en_4598_new_report_conversion_columns') && _.size(conversionKeys) > 0 ? 2 : 1,
        },
        _.map(nonConversionMetrics, metric => ({
            name: metric.name,
            label: metric.label,
            sortable: true,
            colSpan: 1,
        })),
        _.isEmpty(conversionMetricsByColumn)
            ? []
            : {
                  name: 'conversions',
                  label: 'Conversions',
                  colSpan: _.size(conversionMetricsByColumn) + 1,
                  align: 'center',
              },
    ) : _.concat(
        {
            name: 'dimension',
            label: 'Dimensions',
            sortable: true,
            colSpan: 1,
            rowSpan: _.size(conversionKeys) > 0 ? 2 : 1,
        },
        _.map(_.concat(nonConversionMetrics), metric => ({
            name: metric.name,
            label: metric.label,
            sortable: true,
            colSpan: 1,
            rowSpan: _.size(conversionKeys) > 0 ? 2 : 1,
        })),
        _.isEmpty(conversionKeys)
            ? []
            : _.map(conversionKeys, metric => {
                 return {
                    name: metric,
                    label: metric,
                    colSpan: _.size(conversionMetricsByReportingName[metric]),
                    align: 'center',
                    superHeader: true,
                }
              })
    )

    const row = {
        key: 'header',
        type: 'header',
        columns: columns.map(column => {
            return {
                ...column,
                sortDirection: column.name === sortColumn ? sortDirection : null,
            };
        }),
    };

    return [row, ...rows];
}

export function createBodyRowsFromStats(args) {
    const { stats, expandedRows, expandedPixelRows, selectedMetrics, selectedDimensions, currency } = args;
    const nonConversionMetrics = _.filter(
        selectedMetrics,
        metric => metric.category !== 'Conversion'
    );

    const conversionMetricsByReportingName = _(selectedMetrics)
        .filter(metric => metric.category === 'Conversion')
        .groupBy(metric => _.get(metric, 'meta.reporting_name'))
        .map((metrics, reportingName) => {
            let conversionMetrics = [];
            if (reportingName) {
                conversionMetrics.push(reportingName);
            }
            _.each(metrics, metric => {
                if (metric.dynamicData) {
                    conversionMetrics.push(
                        ..._(metric.dynamicData)
                            .filter(dynamicData => {
                                return (
                                    dynamicData.includes('|') && !dynamicData.includes('conv_value')
                                );
                            })
                            .flatten()
                            .value()
                    );
                }
            });
            return _.uniq(conversionMetrics);
        })
        .flatten()
        .value();

    const hasSubevents = conversionMetricsByReportingName.some(name => name.includes('|'));
    const conversionMetricsByColumn = _(selectedMetrics)
        .filter(metric => metric.category === 'Conversion')
        .groupBy(metric => _.get(metric, 'meta.column'))
        .value();

    const rows = _(stats)
        .map(stat => {
            const isTogglable = _.size(stat.split) < _.size(selectedDimensions);

            const isExpanded = isRowExpanded({ ...stat, expandedRows });

            const uniqueMetric = getUniqueMetricFromDimensions(
                _.map(stat.split, split => split.dimension)
            );

            const dimensionColumnLabelValue =
                _.size(stat.split) === 0 ? '' : _.last(stat.split).label;

            const row = {
                id: stat.id,
                type: 'body',
                stat,
                isTogglable: isTogglable,
                isExpanded,
                columns: _.concat(
                    {
                        name: 'dimension',
                        label: dimensionColumnLabelValue,
                        value: dimensionColumnLabelValue,
                        depth: Math.max(_.size(stat.split) - 1, 0),
                        colSpan: 1,
                    },
                    _.map(!flags.isEnabled('en_4598_new_report_conversion_columns') ? nonConversionMetrics : selectedMetrics, metric => ({
                        name: metric.name,
                        label: formatMetric({
                            metric,
                            value:
                                stat[metric.name === 'unique_users' ? uniqueMetric : metric.name],
                            currency,
                        }),
                        hideSubRows:
                        getEnvironmentSettings().getReportingDatasource() === 'snowflake' &&
                            metric.name === 'unique_users' &&
                            isTogglable,
                        value:
                            stat[metric.name === 'unique_users' ? uniqueMetric : metric.name] || 0,
                        colSpan: 1,
                    }))
                ),
            };

            if (_.isEmpty(conversionMetricsByReportingName) || flags.isEnabled('en_4598_new_report_conversion_columns')) {
                return row;
            }

            // All the below code can be removed when fully enabling the en_4598 flag.
            let isParentRowExpanded = false;
            const rowsWithConversions = _.reduce(
                conversionMetricsByReportingName,
                (acc, reportingName, index) => {
                    const result = acc.slice(0);
                    const isRowTogglable =
                       !reportingName.includes('|') && reportingName !== 'Overall Conversions'
                            ? true
                            : false;

                    const isConversionRowStatic = reportingName === 'Overall Conversions';
                    const isConversionExpanded = isRowExpanded({
                        split: stat.split,
                        expandedRows: expandedPixelRows,
                        isTopLevelConversionMetric: !reportingName.includes('|'),
                        reportingName,
                        id: row.id + reportingName
                    });

                    if (!isConversionRowStatic && !reportingName.includes('|')) {
                        // This is the beginning of a conversion pixel set of rows.
                        // We need to track if this set is expanded or not to show the rows or not.
                        isParentRowExpanded = isConversionExpanded;
                    }

                    if (reportingName.includes('|') && !isParentRowExpanded) {
                        // Don't add this row as the parent isn't expanded.
                        return result;
                    }

                    result.push({
                        ...row,
                        pixelRowID: row.id + reportingName,
                        isPixelRowExpanded: isConversionExpanded,
                        isTogglable: index === 0 ? row.isTogglable : false,
                        isRowTogglable,
                        isConversionRowStatic,
                        columns: _.concat(
                            _.map(row.columns, column => ({
                                ...column,
                                label: index === 0 ? column.label : '',
                                value: index === 0 ? column.label : '',
                            })),
                            {
                                name: 'pixels',
                                label: reportingName.includes('|')
                                    ? (reportingName.split('|')[1] || 'N/A')
                                    : reportingName,
                                colSpan: 1,
                                depth: reportingName.includes('|') ? 1 : 0,
                                isColumnTogglable:
                                    !reportingName.includes('|') &&
                                    reportingName !== 'Overall Conversions' &&
                                    hasSubevents
                                        ? true
                                        : false,
                            },
                            _.map(conversionMetricsByColumn, (metrics, columnLabel) => {
                                const metric = _.find(metrics, metric => {
                                    if (reportingName.includes('|')) {
                                        return metric.dynamicData && metric.dynamicData.includes(reportingName) && metric.meta.column === columnLabel;
                                    }
                                    return (
                                        metric.meta.reporting_name === reportingName.split('|')[0] &&
                                        metric.meta.column === columnLabel
                                    );
                                });

                                let metricName = metric ? metric.name : '';
                                let label = '';
                                let value = 0;
                                if (columnLabel === 'Count' && reportingName.includes('|')) {
                                    metricName = reportingName;
                                }
                                if (columnLabel === 'Event Value' && reportingName.includes('|')) {
                                    metricName = reportingName
                                        .toString()
                                        .replace('conv_', 'conv_value_');
                                }
                                label = metric
                                    ? formatMetric({ metric, value: stat[metricName], currency })
                                    : '';

                                value = metric ? _.get(stat, metricName, 0) : 0;

                                if (columnLabel === 'Count' && !metricName.includes('conv_') && metric) {
                                    const sumOnePixelStats = sumOnePixelCountAsTableStats(
                                        stat,
                                        metric,
                                        currency
                                    );

                                    label = sumOnePixelStats.label;
                                    value = sumOnePixelStats.value;
                                }
                                if (
                                    columnLabel === 'Event Value' &&
                                    !metricName.includes('conv_') &&
                                    metric
                                ) {
                                    const sumOnePixelStats = sumOnePixelCustomMetricAsTableStats(
                                        stat,
                                        metric,
                                        currency
                                    );

                                    label = sumOnePixelStats.label;
                                    value = sumOnePixelStats.value;
                                }
                                return {
                                    name: columnLabel,
                                    label,
                                    value,
                                    colSpan: 1,
                                };
                            })
                        ),
                    });
                    return result;
                },
                []
            );
            return rowsWithConversions;
        })
        .flatten()
        .value();
    return rows;
}

function sumOnePixelCountAsTableStats(stat, metric, currency) {
    let sumCount = 0;
    _.each(metric.dynamicData, dynamicData => {
        if (dynamicData.includes('conv_') && !dynamicData.includes('conv_value_')) {
            sumCount += stat[dynamicData];
        }
    });
    const label = formatMetric({ metric, value: sumCount, currency });
    const value = sumCount;
    return {
        label,
        value,
    };
}

function sumOnePixelCustomMetricAsTableStats(stat, metric, currency) {
    let sumCustomMetric = 0;
    _.each(metric.dynamicData, dynamicData => {
        if (dynamicData.includes('conv_value_')) {
            sumCustomMetric += _.get(stat, dynamicData, 0);
        }
    });
    const label = formatMetric({ metric, value: sumCustomMetric, currency });
    const value = sumCustomMetric;
    return {
        label,
        value,
    };
}

function getUniqueMetricFromDimensions(dimensions) {
    const mapping = {
        campaign_id: 'unique_campaign',
        ad_id: 'unique_ad',
        markup_id: 'unique_creative',
    };

    let lastUnique = 'unique_campaign';
    _.each(dimensions, dimension => {
        if (mapping[dimension]) {
            lastUnique = mapping[dimension];
        }
    });

    return lastUnique;
}

export function hideZeroRows({ rows, selectedMetrics, shouldHideZeroRows }) {
    if (!shouldHideZeroRows) {
        return rows;
    }

    return _.filter(rows, row => {
        if (row.type !== 'body') {
            return true;
        }

        const isZeroRow = _.every(selectedMetrics, metric => {
            const metricValue = row.stat[metric.name];

            return !!metricValue === false;
        });

        if (isZeroRow) {
            return false;
        } else {
            return true;
        }
    });
}

function addSubHeaderRow({ rows, statsRoot, selectedMetrics }) {
    if (!statsRoot) {
        return rows;
    }

    const conversionMetrics = _.filter(selectedMetrics, metric => metric.category === 'Conversion');
    if (_.isEmpty(conversionMetrics)) {
        return rows;
    }

    const nonConversionMetrics = _.filter(
        selectedMetrics,
        metric => metric.category !== 'Conversion'
    );

    const conversionMetricsByColumn = _(selectedMetrics)
        .filter(metric => metric.category === 'Conversion')
        .groupBy(metric => _.get(metric, 'meta.column'))
        .map((metrics, columnLabel) => columnLabel)
        .value();

    const row = {
        key: 'subheader',
        type: 'header',
        columns: !flags.isEnabled('en_4598_new_report_conversion_columns') ? _.concat(
            {
                name: 'dimensions',
                label: '',
                colSpan: 1,
            },
            _.map(nonConversionMetrics, metric => ({
                name: metric.name,
                label: '',
                colSpan: 1,
            })),
            _.isEmpty(conversionMetricsByColumn)
                ? []
                : {
                      name: 'pixels',
                      label: 'Pixels',
                      colSpan: 1,
                      minWidth: 200,
                  },
            _.map(conversionMetricsByColumn, columnLabel => ({
                name: columnLabel,
                label: columnLabel,
                colSpan: 1,
            }))
        ) : _.concat(
            _.map(conversionMetrics, metric => ({
                name: metric.name,
                label: metric.label,
                colSpan: 1,
            }))
        ),
    };

    return [row, ...rows];
}

function isRowExpanded({ split, id, expandedRows, isTopLevelConversionMetric = false }) {
    const isTopLevelSplit = _.size(split) <= 1;
    const isNotToggled = expandedRows === undefined || expandedRows[id] === undefined;
    if (isTopLevelSplit && isNotToggled && !isTopLevelConversionMetric) {
        return true;
    } else {
        return !!expandedRows[id];
    }
}

function formatMetric({ metric, value, currency }) {
    if (!metric.format) {
        return value;
    }

    const mapping = {
        dollar: `${getCurrencySignforCurrency(currency)}0,0.00`,
        thousands: '0,0',
        percentage: '0,0.00%',
    };
    return numeral(value).format(mapping[metric.format] || '0,0');
}

import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AppLists from 'states/resources/app-lists/actions';

const mapStateToProps = (storeState, ownProps) => {
    const orgId = _.get(storeState, `profile.organizationId`, void 0);
    const appListItem = _.get(storeState, `resources.appLists.${ownProps.appListId}.attr`, void 0);
    return {
        appListItem,
        orgId,
    };
};

class ModuleRoot extends React.Component {
    constructor(props) {
        super(props);
        this.handle_toggleChannelInAppList = this.handle_toggleChannelInAppList.bind(this);
        this.hasBeenAdded = this.hasBeenAdded.bind(this);
        this.patchDetails = this.patchDetails.bind(this);
        this.state = {
            isSaving: false,
            isLoading: false,
        };
    }

    handle_toggleChannelInAppList(appListItem, channel_id, channel_type) {
        const bundle_ids = appListItem.bundle_ids;
        const domain_ids = appListItem.domains;

        const bundle_ids_next = [].concat(bundle_ids);
        const domain_ids_next = [].concat(domain_ids);

        if (this.hasBeenAdded(bundle_ids, domain_ids, channel_id, channel_type)) {
            console.log('Removal not implimented'); // eslint-disable-line no-console
            // _.remove(bundle_ids_next,  (id)=>{
            //   return id === channel_id;
            // });
        } else {
            if (channel_type === 'APP') {
                bundle_ids_next.push(channel_id);
            } else {
                domain_ids_next.push(channel_id);
            }
        }

        return this.patchDetails(appListItem, bundle_ids_next, domain_ids_next).then(
            appList_updated => {
                this.setState({
                    appList: appList_updated,
                    isSaving: false,
                });
            }
        );
    }

    patchDetails(appListItem, bundle_ids, domain_ids) {
        const { dispatch } = this.props;
        const payload = {
            bundle_ids: bundle_ids,
            domains: domain_ids,
        };

        const appListId = appListItem.id;
        this.setState({ isSaving: true });
        return dispatch(AppLists.update(this.props.orgId, appListId, payload));
    }

    hasBeenAdded(bundle_ids, domain_ids, channel_id, channel_type) {
        return _.includes(channel_type === 'APP' ? bundle_ids : domain_ids, channel_id);
    }

    render() {
        const channel_id = this.props.channel_id;
        const channel_type = this.props.channel_type;
        const appListItem = this.props.appListItem;

        return (
            <div
                className="app-list-item"
                onClick={async e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    await this.handle_toggleChannelInAppList(appListItem, channel_id, channel_type);
                    this.props.fetchDetails();
                }}
            >
                <span className="name">{appListItem.name}</span>
                {this.hasBeenAdded(
                    appListItem.bundle_ids,
                    appListItem.domains,
                    channel_id,
                    channel_type
                ) && <span className="indicator">added</span>}
                <span className="indicator">&nbsp;</span>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ModuleRoot);

import _ from 'lodash';
import React from 'react';

import { LEVEL_BY_NAME } from 'common/constants/forensiq-risk-levels';

import StandardSelector from 'widgets-v5/standard-selector';
import FormField from 'widgets-v5/form-field';
import RadioSelector from 'widgets-v5/radio-selector';
import Toggle from 'widgets-v5/toggle';

import flags from 'containers/flags/service';

const OPTIONS = [LEVEL_BY_NAME['allow_low_risk'], LEVEL_BY_NAME['allow_low_med_risk']];

class BrandSafety extends React.Component {
    state = {
        enabled: this.props.formData.forensiq_risk_cap === -1 ? false : true,
    };

    updateRiskCap = newCap => {
        this.props.onFieldChange('forensiq_risk_cap')(newCap);
    };

    toggleBrandSafety = () => {
        this.setState(
            {
                enabled: !this.state.enabled,
            },
            () => {
                if (this.state.enabled === false) {
                    this.updateRiskCap(LEVEL_BY_NAME['disabled'].value);
                } else {
                    this.updateRiskCap(LEVEL_BY_NAME['allow_low_risk'].value);
                }
            }
        );
    };

    render() {
        const {
            formData,
            validate,
            errors,
            showErrors,
            onFieldChange,
            startNow,
            adId,
            currency,
        } = this.props;

        return (
            <div
                style={{ position: 'relative' }}
                className="ef3-adSetupForm_workspace_basics ef6-alignment__space-between"
            >
                <div className="ef3-adSetupForm_row">
                    <FormField
                        className="ef6-alignment__flex-center"
                        label="Viewability and Fraud"
                        errors={errors.forensiq_risk_level}
                        showErrors={showErrors}
                        isRequired={false}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Toggle
                                status="true"
                                width={56}
                                height={25}
                                position={!this.state.enabled ? 'left' : 'right'}
                                textLeft="no"
                                textRight="yes"
                                onClick={this.toggleBrandSafety}
                            />
                            {this.state.enabled && (
                                <RadioSelector
                                    onChange={this.updateRiskCap}
                                    activeItem={formData.forensiq_risk_cap}
                                    items={OPTIONS}
                                />
                            )}
                        </div>
                    </FormField>
                </div>
                <div className="ef6-alignment__flex-center">
                    <div style={{ marginRight: 10 }}>Verified by</div>
                    <img
                        style={{ maxWidth: '100%', height: 40, marginRight: 10 }}
                        src="/images/tag-logo.png"
                    />
                    <img
                        style={{ maxWidth: '100%', height: 40 }}
                        className=""
                        src="/images/forensiq-logo.png"
                    />
                </div>
            </div>
        );
    }
}

export default BrandSafety;

import _ from 'lodash';

import c from 'common/constants/flux-events';
import { storageSelector } from 'utils/browser-storage';
import { detectImpersonation } from 'services/impersonation';

var storage;

function getInitialState() {
    const impersonating = detectImpersonation();
    storage = storageSelector(impersonating);

    const sidebarTabs = storage.getItem('SIDEBAR_TABS') || '{}';
    const selectedCampaigns = JSON.parse(sidebarTabs);
    const mediaPlans = storage.getItem('SIDEBAR_MEDIA_PLAN_TABS') || '[]';
    const businessReports = storage.getItem('SIDEBAR_BUSINESS_REPORT_TABS') || '[]';

    _.each(selectedCampaigns, item => {
        if (!item.hasOwnProperty('isPinned')) {
            item.isPinned = false;
        }
    });

    return {
        selectedCampaigns,
        mediaPlans: JSON.parse(mediaPlans),
        isOnScreen: window.innerWidth >= 768,
        businessReports: JSON.parse(businessReports),
    };
}

// Given '/campaigns/4125/overview'
// return ["/campaigns/4125", "4125"]
const campaignsRegex = /^\/campaigns\/(\d+)/;
function matchCampaigns(path) {
    return campaignsRegex.exec(path);
}

export const NAME = 'sidebar';

export default function sidebar(state = getInitialState(), action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            const path = `${action.payload.pathname}${
                action.payload.search ? action.payload.search : ''
            }`;

            let nextState = { ...state };
            nextState = tryAddCampaignTab(nextState, path);
            nextState = tryAddMediaPlanTab(nextState, path);
            nextState = tryAddBusinessReportTab(nextState, path);

            return nextState;
        }
        case c.SIDEBAR__MEDIA_PLANS__ADD_TAB: {
            const { name } = action.payload;

            var mediaPlans = state.mediaPlans;
            if (!_.includes(state.mediaPlans, name)) {
                mediaPlans = _.uniq([].concat(name, state.mediaPlans));
            }

            return {
                ...state,
                mediaPlans,
            };
        }
        case 'SIDEBAR__BUSINESS_REPORT_TAB__REMOVE': {
            const nextState = removeBusinessReportTab(state, action.payload.businessReportId);
            return nextState;
        }
        case c.MEDIA_PLANS_DASHBOARD__TAB_REMOVE: {
            const { mediaPlanId } = action.payload;

            const mediaPlans = _.filter(state.mediaPlans, id => id !== mediaPlanId);

            return {
                ...state,
                mediaPlans,
            };
        }
        case c.SIDEBAR__CAMPAIGN_TAB__ADD: {
            const { campaignId } = action.payload;
            const timestamp = _.get(state, `selectedCampaigns.${campaignId}.timestamp`, Date.now());

            const selectedCampaigns = {
                ...state.selectedCampaigns,
                [campaignId]: {
                    campaignId,
                    timestamp,
                    path: action.payload.path,
                    isPinned: _.get(state, `selectedCampaigns.${campaignId}.isPinned`, false),
                },
            };

            return {
                ...state,
                selectedCampaigns,
            };
        }

        case c.SIDEBAR__CAMPAIGN_TAB__REMOVE: {
            const nextSelectedCampaigns = _.omit(
                state.selectedCampaigns,
                action.payload.campaignId
            );

            return {
                ...state,
                selectedCampaigns: nextSelectedCampaigns,
            };
        }

        case c.SIDEBAR__CAMPAIGN_TAB__REMOVE_All: {
            // only remove unpinped campaign
            const selectedCampaigns_next = _(state.selectedCampaigns).reduce((acc, val, key) => {
                if (val.isPinned === true) {
                    acc[key] = val;
                }
                return acc;
            }, {});

            return {
                ...state,
                selectedCampaigns: selectedCampaigns_next,
            };
        }

        case c.SIDEBAR__CAMPAIGN_TAB__PIN_TOGGLED: {
            const { campaignId } = action.payload;
            const selectedCampaigns_next = {
                ...state.selectedCampaigns,
                [campaignId]: {
                    ...state.selectedCampaigns[campaignId],
                    isPinned: !state.selectedCampaigns[campaignId].isPinned,
                },
            };

            return {
                ...state,
                selectedCampaigns: selectedCampaigns_next,
            };
        }

        case c.LOGOUT: {
            // This forces a refresh of the `storage` object
            return {
                ...getInitialState(),
            };
        }

        case c.SIDEBAR__SLIDE_TOGGLE: {
            return {
                ...state,
                isOnScreen: !state.isOnScreen,
            };
        }
        case 'SIDEBAR__SLIDE_CLOSE': {
            return {
                ...state,
                isOnScreen: false,
            };
        }
        case 'SIDEBAR__SLIDE_OPEN': {
            return {
                ...state,
                isOnScreen: true,
            };
        }

        case 'SIDEBAR__CAMPAIGN_FETCH_FAILURE': {
            return state;
        }

        default:
            return state;
    }
}

function tryAddCampaignTab(state, path) {
    const match = matchCampaigns(path);

    if (match) {
        const campaignId = match[1];
        const timestamp = _.get(state, `selectedCampaigns.${campaignId}.timestamp`, Date.now());

        const selectedCampaigns = {
            ...state.selectedCampaigns,
            [campaignId]: {
                campaignId,
                timestamp,
                path,
                isPinned: _.get(state, `selectedCampaigns.${campaignId}.isPinned`, false),
            },
        };

        return {
            ...state,
            selectedCampaigns,
        };
    }

    return state;
}

const mediaPlansRegex = /^\/media-plans\/(.+)/;
function tryAddMediaPlanTab(state, path) {
    const match = mediaPlansRegex.exec(path);

    let mediaPlans = state.mediaPlans;

    if (match) {
        const mediaPlanId = match[1].split('/')[0];

        if (!_.includes(mediaPlans, mediaPlanId)) {
            mediaPlans = _.uniq([].concat(mediaPlanId, mediaPlans));
        }
    }

    return {
        ...state,
        mediaPlans,
    };
}

const businessReportsRegex = /^\/reports\/(\d+)$/;
function tryAddBusinessReportTab(state, path) {
    const match = businessReportsRegex.exec(path);

    if (!match) {
        return {
            ...state,
            currentBusinessReportId: null,
        };
    }

    const businessReportId = match[1];

    // dont add duplicate tabs
    const existingTab = _.find(state.businessReports, { businessReportId });
    if (existingTab) {
        return {
            ...state,
            currentBusinessReportId: businessReportId,
        };
    }

    const tab = { businessReportId };

    return {
        ...state,
        businessReports: [tab].concat(state.businessReports),
        currentBusinessReportId: businessReportId,
    };
}

function removeBusinessReportTab(state, businessReportId) {
    const businessReports = _.filter(
        state.businessReports,
        tab => tab.businessReportId !== businessReportId
    );

    return {
        ...state,
        businessReports,
    };
}

import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';

export const TIMEOUT_NOTIF_CONFIG = [
    {
        id: 'pivotTableInfo',
        timeout: 10000,
        text: () => 'Your report is still loading! Thank you for your patience.',
        title: 'Loading',
        level: 'info',
    },
    {
        id: 'pivotTableWarning',
        timeout: 90000,
        text: () => {
            return can(PermissionsMapping.CAMPAIGN__VIEW)
                ? 'Your report has timed out. If the issue persists, please try generating it from the Reports section. We apologize for the inconvenience.'
                : 'Your report has timed out. Please contact your Account Manager for assistance. We apologize for the inconvenience.';
        },
        title: 'Timeout',
        level: 'warning',
    },
];

export const TIMEOUT_IDS = TIMEOUT_NOTIF_CONFIG.map(({ id }) => id);

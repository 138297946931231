import _ from 'lodash';

import flags from 'containers/flags/service';
import { payload_formData as defaultValues } from '../../services/default-values';

export function handleIASBrandSafetyChange() {
    return {
        description: 'Handle IAS Fraud Prevention Change',
        target(state, action) {
            return 'AD_FORM__IAS_BRAND_SAFETY__CHANGE' === action.type;
        },
        reduce(state, action) {
            const { code, category } = action.payload;
            return updateIASBrandSafety(state, category, code);
        },
    };
}

export function deriveIASBrandSafety() {
    return {
        description: 'Derive Brand Safety code when Creative Format or Platform changes',
        target(state, action, prevState) {
            return /^AD_FORM__/.test(action.type) && state.draft && prevState.draft;
        },
        reduce(state, action, prevState) {
            const creative = _.find(state.creatives, { id: state.draft.creative[0] });

            if (!creative) {
                return resetIASBrandSafety(state);
            }

            const isUsingUnsupportedFormat = _.includes(['mraid', 'native'], creative.format);
            const isUsingUnsupportedPlatform = state.platform === 'mweb';

            if (isUsingUnsupportedPlatform || isUsingUnsupportedFormat) {
                return resetIASBrandSafety(state);
            }

            const prevCreative = _.find(prevState.creatives, { id: prevState.draft.creative[0] });
            const creativeFormatChanged = hasCreativeFormatChanged({ creative, prevCreative });
            const platformChanged = state.draft.platform !== prevState.draft.platform;

            if (creative && (creativeFormatChanged || platformChanged)) {
                const creativeMapping = {};
                _.each(state.creatives, creative => (creativeMapping[creative.id] = creative));

                const creatives = _.map(
                    state.draft.creative,
                    creativeId => creativeMapping[creativeId]
                );
                const iasFormat = mapFormatToIasFormat(creatives[0].format);
                const channel = mapPlatformToIasChannel(state.draft.platform);

                return resetIASBrandSafety(state);
            }

            return state;
        },
    };
}
function resetIASBrandSafety(state) {
    return {
        ...state,
        draft: {
            ...state.draft,
            iasBrandSafety: defaultValues.iasBrandSafety,
        },
    };
}

function updateIASBrandSafety(state, category, code) {
    return {
        ...state,
        draft: {
            ...state.draft,
            ias: {
                ...state.draft.ias,
                brandSafety: {
                    ...state.draft.ias.brandSafety,
                    [category]: {
                        ...state.draft.ias.brandSafety[category],
                        value: code,
                    },
                },
            },
        },
    };
}

function hasCreativeFormatChanged({ creative, prevCreative }) {
    if (!creative) {
        return false;
    }

    if (creative && !prevCreative) {
        return true;
    }

    return creative.format !== prevCreative.format;
}

function mapFormatToIasFormat(format) {
    if (_.includes(['video', 'third_party_vast'], format)) {
        return 'video';
    } else {
        return 'display';
    }
}

function mapPlatformToIasChannel(platform) {
    return platform === 'inapp' ? 'inapp' : 'desktop';
}

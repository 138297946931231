import _ from 'lodash';
import React from 'react';

import { SingleSelect } from 'widgets-v6/select';

import dictTimezone from 'common/constants/timezones';

import { getEnvironmentSettings } from 'services/environment';

// only show campaign, org, and utc timezones if specified. Show everything as options if not
const getTimezoneOptions = (existingTimezones = []) => {
    const environmentSettings = getEnvironmentSettings();
    const countryCodes = environmentSettings.getTimezoneCountryCodes();

    const validTimezoneEntries = _.pickBy(dictTimezone, (value, countryCode) => {
        return _.includes(countryCodes, countryCode);
    });

    const availableTimezoneOptions = _(validTimezoneEntries)
        .map(v => v)
        .flatten()
        .map(v => ({
            label: `${v[1]}${v[3] ? ', ' + v[3] : ''} - ${v[2]}`,
            value: `${v[0]}`,
        }))
        .value();

    return existingTimezones.length > 0
        ? _.filter(
              availableTimezoneOptions,
              option => _.includes(existingTimezones, option.value) || option.value === 'UTC'
          )
        : availableTimezoneOptions;
};

class TimeZoneSelector extends React.Component {
    static defaultProps = {
        timezone: 'UTC',
        disabled: false,
        onChange: _.noop,
    };

    render() {
        const items = getTimezoneOptions(this.props.existingTimezones);

        return (
            <SingleSelect
                placeholder="Timezone"
                options={items}
                value={items.find(({ value }) => value === this.props.timezone)}
                onChange={this.props.onChange}
                label={this.props.label}
            />
        );
    }
}

export default TimeZoneSelector;

import _ from 'lodash';

import querystring from 'utils/querystring';

export default function selector(storeState, props) {
    const audienceDashboardState = _.get(storeState, 'audienceDashboard');
    const audienceResources = _.get(storeState, 'resources.audiences', {});

    const filters = querystring.parse(window.location.href.split('?')[1]);
    const { pagination } = audienceDashboardState;

    const audiences = _(pagination[filters.page || 1])
        .map(id => audienceResources[id])
        .filter(a => a)
        .map(a => ({
            ...a.attr,
        }))
        .value();

    return {
        audienceDashboardState,
        audiences,
    };
}

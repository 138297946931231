// Use reducers transformations to non-derived state
import { makeActions } from 'utils/redux-tools';

export const REDUCER_KEY = 'template';
export const initialState = {
    draft: {
        message: 'hello',
    },
};

export const { reducer, actions } = makeActions({
    reducerKey: REDUCER_KEY,
    initialState,
    reducers: {
        changeMessage: (state, message) => ({
            ...state,
            draft: {
                ...state.draft,
                message,
            },
        }),
    },
});

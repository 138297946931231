import _ from 'lodash';
import VError from 'verror';
import Joi from '@hapi/joi';
import { TAGS } from 'common/constants/vendors';
import {
    templateValidator,
    oldTemplateValidator,
    clickMacroValidator,
} from 'common/richmedia-validators';
import { getEnvironmentSettings } from 'services/environment';
const ENGAGEFRONT_JS_TAG_MATCHER = _.find(TAGS, { value: 'engagefront__tag__js' }).test;

import { regEx } from 'utils/regular-expression-library';
const urlValidationRegExp = regEx.url;

const customContentHtmlValidator = value => {
    if (!value) {
        throw new VError('HTML Markup is required');
    }

    const clickMacroErrorMessage = clickMacroValidator(value);

    if (clickMacroErrorMessage) {
        throw new VError(clickMacroErrorMessage);
    }

    const isNewCreativeTemplate = null !== value.match(/\<ef\-creative/gim);

    const hasMoreThanAscii = [...value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')].some(
        char => char.charCodeAt(0) > 127
    );

    if (hasMoreThanAscii) {
        throw new VError('HTML Markup has Invalid non-ASCII characters');
    }

    if (!ENGAGEFRONT_JS_TAG_MATCHER(value)) {
        return;
    }
    const templateErrorMessage = isNewCreativeTemplate
        ? templateValidator(value)
        : oldTemplateValidator(value);

    if (templateErrorMessage) {
        throw new VError(templateErrorMessage);
    }
};

const customReferenceImagePreviewUrlValidator = value => {
    const useJobForBulkCreativeScreenshots = getEnvironmentSettings().useJobForBulkCreativeScreenshots();

    if (!useJobForBulkCreativeScreenshots && _.isEmpty(value)) {
        throw new VError('Reference Image Preview Url is required');
    }

    if (!urlValidationRegExp.test(value)) {
        throw new VError(
            `Reference Image Preview should start with 'http://', 'https://', or 'tel:'`
        );
    }
};

export default Joi.object({
    name: Joi.string()
        .required()
        .messages({
            'string.empty': 'Name is required',
        }),
    format: Joi.string()
        .required()
        .messages({ 'string.empty': 'Format is required' }),
    size: Joi.string()
        .required()
        .messages({ 'string.empty': 'Size is required' }),
    platforms: Joi.array()
        .min(1)
        .required()
        .messages({ 'array.min': 'Platform is required' }),
    type: Joi.string()
        .required()
        .messages({ 'string.empty': 'Type is required' }),
    content_html: Joi.string()
        .trim()
        .required()
        .custom(customContentHtmlValidator, 'custom content_html validator')
        .messages({
            'any.required': 'HTML Markup is required',
            'string.empty': 'HTML Markup is required',
        }),
    reference_image_asset: Joi.string()
        .allow(null)
        .allow('')
        .default(''),
    reference_image_preview_url: Joi.string()
        .default('')
        .required()
        .custom(
            customReferenceImagePreviewUrlValidator,
            'custom reference_image_preview_url validator'
        ),
    third_party_clickthrough: Joi.boolean()
        .required()
        .messages({ 'any.empty': 'Third Party Clickthrough is required' }),
    clickthrough_url: Joi.when('third_party_clickthrough', {
        switch: [
            {
                is: true,
                then: Joi.string()
                    .trim()
                    .required()
                    .pattern(urlValidationRegExp, 'urlPatternTest'),
            },
        ],
    }).messages({
        'any.required': 'Clickthrough URL is required',
        'string.base': 'Clickthrough URL is required',
        'string.empty': 'Clickthrough URL is required',
        'string.pattern.name': `Clickthrough URL should start with 'http://', 'https://', or 'tel:`,
    }),
    landing_page: Joi.string()
        .trim()
        .pattern(urlValidationRegExp, 'urlPatternTest')
        .invalid(Joi.ref('clickthrough_url'))
        .required()
        .messages({
            'any.invalid': 'Landing Page cannot be the same as Clickthrough URL',
            'string.empty': 'Landing Page is required',
            'string.pattern.name': `Landing Page should start with 'http://', 'https://', or 'tel:`,
        }),
    third_party_pixels: Joi.array()
        .items(Joi.string().pattern(urlValidationRegExp, 'urlPatternTest'))
        .messages({
            'string.base': 'Third Party Pixels must be a string',
            'string.pattern.name': `Third Party Pixels should start with 'http://', 'https://', or 'tel:'`,
        }),
    third_party_javascript_urls: Joi.array()
        .items(Joi.string().pattern(urlValidationRegExp, 'urlPatternTest'))
        .messages({
            'string.empty': 'Third Party Javascript cannot be empty',
            'string.base': 'Third Party Javascript must be a string',
            'string.pattern.name': `Third Party Javascript should start with 'http://', 'https://', or 'tel:'`,
        }),
});

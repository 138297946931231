import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';

export function canManageBeacons(campaign) {
    if (!campaign) {
        return false;
    }

    if (campaign && campaign.isArchived) {
        return false;
    }

    return can(PermissionsMapping.CAMPAIGN__VIEW_AND_EDIT);
}

import _ from 'lodash';

export default function selector(state, props) {
    const activate = _.get(state, `activate`, void 0);
    const draft = activate.draft;
    const isLoading = activate.isLoading;
    const errors = reduceErrorList(activate.errors);
    const showErrors = activate.showErrors;

    const o = {
        draft,
        isLoading,
        errors,
        showErrors,
    };
    return o;
}

function reduceErrorList(errorList = []) {
    // Input:
    //
    // [{
    //     field: "name"
    //     message: "Name is required"
    //     value: ""
    // },{
    //     field: "iab_categories"
    //     message: "IAB Category must be provided"
    //     value: Array[0]
    // },{
    //     field: "advertiser_domain"
    //     message: "You must provide the advertiser's domain name, e.g. abc.com"
    //     value: ""
    // }]
    //
    // Output:
    //
    // {
    //     advertiser_domain: [ "You must provide the advertiser's domain name, e.g. abc.com" ],
    //     iab_categories: [ "IAB Category must be provided" ],
    //     name: ["Name is required"]
    // }
    return errorList.reduce((accumulator, error) => {
        accumulator[error.field] = accumulator[error.field] || [];
        accumulator[error.field].push(error.message);
        return accumulator;
    }, {});
}

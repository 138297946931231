import _ from 'lodash';

import { regEx } from 'utils/regular-expression-library';
import flags from 'containers/flags/service';
const urlValidationRegExp = regEx.url;

export const videoValidators = {
    video_asset() {
        // optional
    },
    video_url(value) {
        if (!urlValidationRegExp.test(value)) {
            return `Video URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    video_preview_url(value) {
        if (!value) {
            return;
        }

        if (!urlValidationRegExp.test(value)) {
            return `Video Preview URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    image_preview_url(value, source) {
        if (!value && !source.video_asset) {
            return 'Reference Screenshot is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Reference Screenshot should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    video_duration(value) {
        if (!value) {
            return 'Duration is required';
        }

        if (!_.isNumber(value)) {
            return 'Duration must be between 1 and 30';
        }

        // if (value <= 0 || value > 30) {
        //     return 'Duration must be between 1 and 30';
        // }
    },
    video_bit_rate_kbps(value) {
        if (!value) {
            return 'Bit Rate is required';
        }

        if (!_.isNumber(value)) {
            return 'Bit Rate must be a number';
        }
    },
    companion_asset() {
        // optional
    },
    companion_url(value, source) {
        if (!value) {
            return;
        }

        if (!value && !source.companion_asset) {
            return 'Companion Banner URL is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Companion Banner URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    companion_preview_url(value) {
        if (!value) {
            return;
        }

        if (!urlValidationRegExp.test(value)) {
            return `Companion Banner Preview URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_pixels(value) {
        if (!value) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_pixels to be a list');
        }

        const allValid = _.every(value, pixel => urlValidationRegExp.test(pixel));

        if (!allValid) {
            return `Third Party Pixels should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    third_party_quartile_pixels(value) {
        if (!value) {
            return;
        }

        if (!_.isArray(value)) {
            throw new Error('Expected third_party_quartile_pixels to be a list');
        }

        const allValid = _.every(
            value,
            pixel => pixel.event && urlValidationRegExp.test(pixel.url)
        );

        if (!allValid) {
            return 'Third Party Quartile Pixels are missing event(s) or invalid URL(s)';
        }
    },
    third_party_clickthrough(value) {
        if (!_.isBoolean(value)) {
            return 'Third Party Clickthrough is required';
        }
    },
    clickthrough_url(value, source) {
        // This field isn't required for DOOH creatives.
        if (source.platforms.includes('dooh')) {
            return;
        }

        if (!value && source.third_party_clickthrough === true) {
            return 'Clickthrough URL is required';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Clickthrough URL should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    landing_page(value, source) {
        // This field isn't required for DOOH creatives.
        if (source.platforms.includes('dooh')) {
            return;
        }

        if (!value) {
            return 'Landing Page is required';
        }

        if (value === source.clickthrough_url && source.third_party_clickthrough === true) {
            return 'Landing Page cannot be the same as Clickthrough URL';
        }

        if (!urlValidationRegExp.test(value)) {
            return `Landing Page should start with 'http://', 'https://', or 'tel:'`;
        }
    },
    creative_vendors(value, source) {
        if (source.assetSelectionMethod === 'inputByUrl' && !value.length) {
            return 'At least one creative vendor must be specified';
        }
    },
    placements(placements, source) {
        if (source.platforms.includes('dooh')) {
            return;
        }

        if (placements.length === 0) {
            return 'Placement is required';
        }
    },
    audio_required(value, source) {
        if (flags.isEnabled('ctef_178_add_new_audio_required_field') && source.platforms.includes('dooh') && value === null) {
            return 'Audio is required';
        }
    },
};

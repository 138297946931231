import React, { useState } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Spacer from 'widgets-v5/spacer';

const useStyles = makeStyles(theme => ({
    macros: {
        backgroundColor: 'yellow',
    },
    expand: {
        cursor: 'pointer',
    },
}));

export const htmlExamples = [
    {
        name: 'Campaign Manager 360 (DCM) - INS Tags',
        value: 'ins',
        instructions:
            'Macros are automatically inserted for Campaign Manager 360 INS Tags, and usually do not require manual editing.',
        example: `<ins class='dcmads' style='display:inline-block;width:728px;height:90px'
    data-dcm-placement='XXXXXXX.XXXXXXXXXXXXXXXXXXXXXXX/XXXXXXXXX.XXXXXXXXX'
    data-dcm-rendering-mode='script'
    data-dcm-https-only
    data-dcm-gdpr-applies='gdpr=0'
    data-dcm-gdpr-consent='gdpr_consent='
    data-dcm-addtl-consent='addtl_consent='
    data-dcm-resettable-device-id='%%DEVICE_ID%%'
    data-dcm-click-tracker='%%CLICKTHROUGH%%'
    data-dcm-app-id=''>
    <script src='https://www.googletagservices.com/dcm/dcmads.js'></script>
</ins>`,
    },
    {
        name: 'EyeReturn - JavaScript Tags',
        value: 'eyeReturn',
        instructions:
            'Macros are automatically inserted for EyeReturn JavaScript Tags, and usually do not require manual editing.',
        example: `<script type="text/javascript" src="https://voken.eyereturn.com/j4/js?XXXXXXX&click=%%CLICKTHROUGH_ESC%%&params=%%TIMESTAMP%%"></script>`,
    },
    {
        name: 'Sizmek - JavaScript Tags',
        value: 'sizmek',
        instructions:
            'Macros are automatically inserted for Sizmek JavaScript Tags, and usually do not require manual editing.',
        example: `<script src="https://bs.serving-sys.com/Serving/adServer.bs?c=28&cn=display&pli=XXXXXXXXXX&w=XXX&h=XXX&ord=%%TIMESTAMP%%&z=10000&ucm=true&ncu=%%CLICKTHROUGH_ESC%%"></script>
<noscript>
    <a href="http://bs.serving-sys.com/BurstingPipe/adServer.bs?cn=brd&amp;FlightID=XXXXXXXX&amp;Page=&amp;PluID=0&amp;Pos=XXXXXXXXX" target="_blank"><img src="http://bs.serving-sys.com/BurstingPipe/adServer.bs?cn=bsr&amp;FlightID=XXXXXXXX&amp;Page=&amp;PluID=0&amp;Pos=XXXXXXXXX" border=0 width=XXX height=XXX></a>
</noscript>`,
    },
    {
        name: 'AdGear - JavaScript Tags',
        value: 'adgear',
        instructions:
            'Macros are automatically inserted for Sizmek JavaScript Tags, and usually do not require manual editing.',
        example: `<script type="text/javascript" language="JavaScript">
(function() {
  ADGEAR_SOURCE_CLICKTRACKER = "%%CLICKTHROUGH%%";
  ADGEAR_SOURCE_CLICKTRACKER_EXPECTS_ENCODED = true;
  ADGEAR_SOURCE_CLICKTRACKER_IS_ENCODED = false;
  ADGEAR_SOURCE_CLICKTRACKER_IS_DOUBLE_ENCODED = false;
  var randomnum = "%%TIMESTAMP%%";
  var agref = "";
  var proto = "https:";
  if (!agref.match(/^https?/i)) agref = "";
  if (((typeof __ADGEAR_SSL != "undefined") && !__ADGEAR_SSL) || (window.location.protocol == "http:")) proto = "http:";
  if (randomnum.substring(0,2) == "__") randomnum = String(Math.random());
  document.writeln('<scr' + 'ipt type="text/ja' + 'vascr' + 'ipt" s' + 'rc="' +
      proto + '//d.adgear.com' + '/impressions/ext/p=' +
      'XXXXXX' + '.js?AG_R=' + randomnum +
      (agref === "" ? "" : ("&AG_REF=" + encodeURIComponent(agref))) +
      '"></scr' + 'ipt>');
})();
</script>`,
    },
];

const MACROS_TABLE = [
    {
        label: '%%CLICKTHROUGH%%.',
        description:
            'Provides an EngageFront clicktracker URL which redirects to your specified landing page when used in conjunction with the "Set or override Clickthrough URL option".',
    },
    {
        label: '%%CLICKTHROUGH_ESC%%',
        description: 'URL-escaped version of %%CLICKTHROUGH%%',
    },
    {
        label: '%%CLICKTRACKER%%',
        description: 'Provides a clicktracking URL, which does not redirect.',
    },
    {
        label: '%%TIMESTAMP%%',
        description: 'Cachebuster macro',
    },
    {
        label: '%%SITE_DOMAIN%%',
        description: 'Domain of the site (e.g. mygame.example.com)',
    },
    {
        label: '%%EXCHANGE_ID%%',
        description: 'Inserts the name of the exchange',
    },
    {
        label: '%%CAMPAIGN_ID%%',
        description: 'Inserts the Campaign ID',
    },
    {
        label: '%%AD_ID%%',
        description: 'Inserts the Ad ID',
    },
    {
        label: '%%CREATIVE_ID%%',
        description: 'Inserts the Creative ID',
    },
    {
        label: '%%CITY%%',
        description: 'City name',
    },
    {
        label: '%%REGION%%',
        description: 'Province or state name',
    },
    {
        label: '%%COUNTRY%%',
        description: 'Country name',
    },
    {
        label: '%%LATITUDE%%',
        description: 'Latitude (if available)',
    },
    {
        label: '%%LONGITUDE%%',
        description: 'Longitude (if available)',
    },
    {
        label: '%%CARRIER%%',
        description: 'Name of the wireless carrier',
    },
    {
        label: '%%DEVICE_OS%%',
        description: 'OS of device',
    },
    {
        label: '%%DEVICE_MODEL%%',
        description: 'Model of device',
    },
    {
        label: '%%DEVICE_ID%%',
        description: 'Inserts an advertising identifier based on the platform/OS',
    },
];

export const htmlGenericExample = {
    name: 'creative tags (generic)',
    value: 'generic',
    instructions: (
        <React.Fragment>
            <Box bgcolor="#e8f4fd" p={3} my={2}>
                <Typography variant="body1" gutterBottom>
                    Choose the creative vendor from the list above for improved compatibility and
                    tailored setup instructions. If the vendor is not in the list, click "New
                    Vendor" to add it.
                </Typography>
                <Spacer />
                <Typography variant="body1" gutterBottom>
                    Most commonly used macros
                </Typography>
                <Spacer />
                <Box mt={2}>
                    <Typography variant="body1" gutterBottom>
                        {' '}
                        <MacroHighlight>%%CLICKTHROUGH%%</MacroHighlight> (Clicktracking / click URL
                        macro)
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        This macro expands into a unique URL that allows EngageFront to track
                        clicks. It is commonly used in three ways:
                    </Typography>
                    <Box ml={1}>
                        <Typography variant="body2" gutterBottom>
                            1. Provided as an HTML or JavaScript parameter in the creative tag
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Example:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            data-dcm-click-tracker='%%CLICKTRACKER%%'
                        </Typography>
                        <Spacer />
                        <Typography variant="body2" gutterBottom>
                            2. Prefixed to a landing page URL in the HTML or JavaScript
                        </Typography>{' '}
                        <Typography variant="body2" gutterBottom>
                            HTML Example:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <pre>
                                <code>
                                    &lt;a
                                    href="%%CLICKTHROUGH%%https://www.theweathernetwork.com"&gt;Link&lt;/a&gt;
                                </code>
                            </pre>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            JavaScript example:{' '}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            var clickTag = '%%CLICKTRACKER%%https://www.theweathernetwork.com';{' '}
                        </Typography>
                        <Spacer />
                        <Typography variant="body2" gutterBottom>
                            3. When inserted into the middle of a URL, use{' '}
                            <MacroHighlight>%%CLICKTHROUGH_ESC%%</MacroHighlight> instead.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Example:
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <pre>
                                <code>
                                    &lt;script type="text/javascript"
                                    src="https://voken.eyereturn.com/j4/js?1292752&;click=%%CLICKTHROUGH_ESC%%&ms=%%TIMESTAMP%%"&gt;
                                </code>
                            </pre>
                        </Typography>
                    </Box>
                    <Spacer />
                    <Typography variant="body1" gutterBottom>
                        <MacroHighlight>%%TIMESTAMP%%</MacroHighlight> (Cachebuster macro)
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Prevents caching on the user's device. Often formatted as [timestamp] or
                        [random].
                    </Typography>
                </Box>
            </Box>
            <MacrosTable />
        </React.Fragment>
    ),
};

function MacrosTable(props) {
    const classes = useStyles();
    const [isExpanded, setExpand] = useState(false);

    const toggleExpandMacros = () => {
        setExpand(!isExpanded);
    };

    const expandIcon = isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;

    return (
        <Box>
            <Box
                onClick={toggleExpandMacros}
                display="flex"
                alignContent="center"
                className={classes.expand}
            >
                Available Macros {expandIcon}
            </Box>
            {isExpanded && (
                <Box bgcolor="#fafafa" mt={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Macro</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(MACROS_TABLE, macro => (
                                <TableRow key={macro.label}>
                                    <TableCell>{macro.label}</TableCell>
                                    <TableCell>{macro.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
        </Box>
    );
}

function MacroHighlight(props) {
    const classes = useStyles();
    return <span className={classes.macros}>{props.children}</span>;
}

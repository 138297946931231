import _ from 'lodash';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import AddIcon from '@mui/icons-material/Add';

import { TextField } from 'widgets-v6/standard-input';

const useStyles = makeStyles(theme => ({
    closeButton: {
        right: -theme.spacing(1),
        color: theme.palette.grey[500],
        height: 'fit-content',
    },
    newVendorButton: {
        height: 'fit-content',
        marginLeft: theme.spacing(0.5),
        whiteSpace: 'nowrap',
    },
}));

function isAscii(input) {
    if (!input) {
        return false;
    }
    return !input.match(/[^\x00-\x7F]/);
}

const validators = values => {
    let error;

    if (!values.length) {
        error = 'required field';
        return error;
    }
    _.forEach(values, value => {
        const isValidNewOption = isAscii(value);
        if (!isValidNewOption) {
            error = 'character not admitted';
        }
    });

    return error;
};

export default function NewVendorForm({ onFieldChange, vendorType, draft }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [error, setError] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [newVendor, setNewVendor] = useState([]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openForm = event => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const updateField = value => {
        const vendor = [value];
        const error = validators(vendor);

        if (error) {
            setShowErrors(true);
            setError(error);
            return;
        }
        setNewVendor(vendor);
    };

    const submit = () => {
        const error = validators(newVendor);
        if (error) {
            setShowErrors(true);
            setError(error);
            return;
        }
        if (vendorType === 'creative') {
            const newCustomisedOptions = [...draft.creativeVendorsOptions, ...newVendor];
            onFieldChange('isCreativeVendorAutoDetected')(false);
            onFieldChange('creative_vendors')(newVendor);
            onFieldChange('creativeVendorsOptions')(newCustomisedOptions);
        } else if (vendorType === 'tracking') {
            const newTrackingVendors = [...newVendor, ...draft.tracking_vendors];
            const newCustomisedOptions = [...draft.trackingVendorsOptions, ...newVendor];
            onFieldChange('isTrackingVendorsAutoDetected')(false);
            onFieldChange('tracking_vendors')(newTrackingVendors);
            onFieldChange('trackingVendorsOptions')(newCustomisedOptions);
        }
        setNewVendor([]);
        handleClose();
    };

    return (
        <React.Fragment>
            <Button className={classes.newVendorButton} onClick={openForm} startIcon={<AddIcon />}>
                New vendor
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box m={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={-1}>
                        <Typography variant="body1">New Vendor</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className={classes.closeButton}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <div>
                        <TextField
                            disableMargin
                            value={newVendor}
                            onChange={e => updateField(e.target.value)}
                            required={true}
                            error={showErrors}
                        />
                        {showErrors && error && <FormHelperText>{error}</FormHelperText>}
                    </div>
                    <Box mt={1}>
                        <Button onClick={submit} variant="contained" color="primary">
                            Save
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </React.Fragment>
    );
}

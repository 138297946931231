import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import flags from 'containers/flags/service';
import { Checkbox, FormGroup, FormControlLabel, FormLabel } from '@mui/material';

export default class extends React.Component {
    static propTypes = {
        values: PropTypes.array,
        onChange: PropTypes.func,
        onMenuCheckboxChange: PropTypes.func,
    };

    static defaultProps = {
        values: [],
    };

    onClickCB = (key, value) => {
        const newValues = _.map(this.props.values, input => {
            const newInput = {
                ...input,
                properties: _.map(input.properties, property => {
                    if (property.key === key) {
                        return {
                            ...property,
                            checked: !value,
                        };
                    }
                    return property;
                }),
            };

            return newInput;
        });
        this.props.onMenuCheckboxChange(key, value);
        this.props.onChange(newValues);
    };

    render() {
        const { values, readOnlyFields = [] } = this.props;

        return (
            <div className="ef3-checkboxMenuList">
                <div className="ef3-checkboxMenuList_body">
                    {_.map(values, (input, index) => {
                        const cbList = _.map(input.properties, property => {
                            return (
                                <FormGroup style={{ paddingLeft: 0 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color={'primary'}
                                                checked={property.checked}
                                                id={'checkboxVal-' + index + property.key}
                                                name={'checkboxVal-' + index + property.key}
                                                disabled={readOnlyFields.indexOf(property.key) > -1}
                                                onChange={() =>
                                                    this.onClickCB(property.key, property.checked)
                                                }
                                            />
                                        }
                                        label={property.name}
                                    />
                                </FormGroup>
                            );
                        });
                        return (
                            <div
                                key={'header-' + index}
                                className="ef3-checkboxMenuList_body_block"
                            >
                                <FormLabel
                                    style={{ fontWeight: 1000, lineHeight: 2 }}
                                    key={'group-' + index}
                                >
                                    {input.groupName}
                                </FormLabel>
                                {cbList}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

import _ from 'lodash';
import moment from 'moment';

const scheduleInfoSchema = {
    excludeTodaysStat(value) {
        if (!_.isBoolean(value)) {
            return 'Invalid type: excludeTodaysStat';
        }
    },

    repeat(value) {
        if (!_.isString(value)) {
            return 'Repeat is required';
        }
    },

    atDate(value) {
        if (value === undefined) {
            return;
        }

        if (!moment(value).isValid()) {
            return 'Invalid Date';
        }
    },

    atTime(value, source) {
        // None, Hourly are optional
        if (value === undefined && _.includes(['none', 'hourly'], source.repeat)) {
            return;
        }

        if (value === undefined) {
            return 'At Time is required';
        }

        if (!moment(value).isValid()) {
            return 'Invalid Time';
        }
    },

    weekDays(value) {
        if (value === undefined) {
            return;
        }

        if (!_.isArray(value)) {
            return 'Invalid type: weekdays';
        }
    },

    timezone(value) {
        if (value === undefined) {
            return;
        }

        if (!_.isString(value)) {
            return 'Invalid type: timezone';
        }
    },
};

const userSchema = {
    id(value) {
        if (!value) {
            return 'user id could not be found';
        }
    },

    email(value) {
        if (!_.isString(value)) {
            return 'User email could not be found';
        }
    },

    checked(value) {
        if (!_.isBoolean(value)) {
            return 'user checks is invalid type';
        }
    },
};

const recipientSchema = {
    clientName(value) {
        if (!_.isString(value)) {
            return 'clientName could not be found';
        }
    },
    organization(value) {
        if (!_.isString(value)) {
            return 'organization could not be found';
        }
    },
    users(value) {
        if (!_.isArray(value)) {
            return 'users has to be an array';
        }

        return userSchema;
    },
};

const metricPropSchema = {
    name(value) {
        if (!_.isString(value)) {
            return 'Invalid metric name';
        }
    },
    checked(value) {
        if (!_.isBoolean(value)) {
            return 'Invalid metric check';
        }
    },
};

const metricSchema = {
    enabledMetrics(value) {
        if (!_.isArray(value)) {
            return 'enabledMetrics is invalid';
        }

        const isMetricSelected = _.some(value, item => {
            return item.checked;
        });

        if (!isMetricSelected) {
            return 'At least one metric has to be selected';
        }

        return metricPropSchema;
    },
    disabledMetrics(value) {
        if (!_.isArray(value)) {
            return 'disabledMetrics is invalid';
        }

        return metricPropSchema;
    },
};

const splitSchema = {
    label(value) {
        if (!_.isString(value)) {
            return 'Invalid split: label';
        }
    },
    name(value) {
        if (!_.isString(value)) {
            return 'Invalid split: name';
        }
    },
};

//-- validation rules --//
export function getDraftValidators_forPayload() {
    return {
        scheduleInfo(value, source) {
            if (!_.isPlainObject(value)) {
                return 'Invalid type: scheduleInfo';
            }

            const repeat = _.get(source, 'scheduleInfo.repeat', 'none');
            if (repeat === 'none') {
                return;
            }

            return scheduleInfoSchema;
        },
        campaignId(value, source) {
            if (!value) {
                return 'Campaign Id is not found.';
            }

            const repeat = _.get(source, 'scheduleInfo.repeat', 'none');
            if (repeat === 'none') {
                return;
            }
        },
        organization(value, source) {
            if (!value) {
                return 'Organization is not found.';
            }

            const repeat = _.get(source, 'scheduleInfo.repeat', 'none');
            if (repeat === 'none') {
                return;
            }
        },
        recipients(value, source) {
            const repeat = _.get(source, 'scheduleInfo.repeat', 'none');
            if (repeat === 'none') {
                return;
            }

            if (value === undefined) {
                return 'recipients are required';
            }

            if (!_.isArray(value)) {
                return 'Recipients must be an array';
            }

            if (value.length === 0) {
                return 'At least one recipient has to be selected';
            }

            const isClientUserSelected = _.some(value, item => {
                return _.some(item.users, user => {
                    return user.checked;
                });
            });

            if (!isClientUserSelected) {
                return 'At least one client user has to be selected';
            }

            return recipientSchema;
        },
        metrics(value, source) {
            const repeat = _.get(source, 'scheduleInfo.repeat', 'none');
            if (repeat === 'none') {
                return;
            }

            if (value === undefined || !_.isPlainObject(value)) {
                return 'Metrics are invalid';
            }

            return metricSchema;
        },
        splits(value, source) {
            const repeat = _.get(source, 'scheduleInfo.repeat', 'none');
            if (repeat === 'none') {
                return;
            }

            if (value === undefined || !_.isArray(value)) {
                return 'Splits are invalid';
            }

            if (!value.length) {
                return 'At least one split must be selected';
            }

            return splitSchema;
        },
    };
}

import _ from 'lodash';
import c from 'common/constants/flux-events';

const initialState = {
    isProfileLoaded: false,
    isLoading: false,
    isModalOpen: false,

    users: [],
    organizations: [],
    selectedOrganization: undefined, // Declared for explicitness
    errors: [],
};

export const NAME = 'impersonate';

function merge(collection, item) {
    const newCollection = _.cloneDeep(collection);
    const existingIndex = _.findIndex(newCollection, i => {
        return i.id === item.id;
    });

    if (existingIndex === -1) {
        newCollection.push(item);
    } else {
        newCollection[existingIndex] = item;
    }

    return newCollection;
}

export default function(state = initialState, action) {
    switch (action.type) {
        case c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                organizations: merge(state.organizations, action.payload.organization),
            };
        }

        case c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case c.IMPERSONATION_MODAL__REFRESH_USER_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case c.IMPERSONATION_MODAL__REFRESH_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                users: merge(state.users, action.payload.user),
            };
        }

        case c.IMPERSONATION_MODAL__REFRESH_USER: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case c.IMPERSONATION_MODAL__CLOSE: {
            return {
                ...state,
                isModalOpen: false,
            };
        }

        case c.IMPERSONATION_MODAL__OPEN: {
            return {
                ...state,
                isModalOpen: true,
            };
        }

        // This isn't as bad as it looks. Since the profile's user data
        // cannot be changed we don't have to refetch it
        case c.PROFILE__USER__FETCH__SUCCESS: {
            return {
                ...state,
                isProfileLoaded: true,
            };
        }

        case c.IMPERSONATION_MODAL__INIT: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case c.IMPERSONATION_MODAL__INIT_SUCCESS: {
            const { organizations, users, ownOrgId } = action.payload;

            return {
                ...state,
                organizations,
                users,
                isLoading: false,
                selectedOrganization: ownOrgId,
            };
        }

        case c.IMPERSONATION_MODAL__INIT_FAILURE: {
            const { errors } = action.payload;

            return {
                ...state,
                errors,
                isLoading: false,
            };
        }

        case c.IMPERSONATION_MODAL__SET_ORG: {
            return {
                ...state,
                selectedOrganization: action.payload.id,
            };
        }

        case c.IMPERSONATION_MODAL__IMPERSONATION_START: {
            return {
                ...state,
                isLoading: true,
            };
        }

        default:
            return state;
    }
}

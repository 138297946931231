import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import actions from './actions';
import selector from './selector';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles(theme => ({
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
}));
const Loading = withStyles(theme => ({
    root: {
        width: '24px',
        height: '24px',
    },
}))(Grid);

const LoadingSpinner = withStyles(theme => ({
    root: {
        width: '24px !important',
        height: '24px !important',
    },
}))(CircularProgress);

const DeleteUserModal = ({ dispatch, isOpen, refreshUsers, isDeleting }) => {
    const classes = useStyles();
    const handleClose = () => dispatch(actions.close());
    const handleDelete = () => {
        dispatch(actions.delete(refreshUsers));
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
            <DialogTitle classes={{ root: classes.dialogTitleRoot }}>Delete Account</DialogTitle>
            <DialogContent>
                <Typography>
                    Are you sure you want to delete this account? This action is permanent and the
                    account will no longer be available.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                    {isDeleting ? (
                        <Loading>
                            <LoadingSpinner fontSize="small" color="inherit" />
                        </Loading>
                    ) : (
                        'Delete'
                    )}
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(selector)(DeleteUserModal);

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { tabLinksUriQuery } from 'pages/campaigns/campaigns-overview/overview-common';
import { getEnvironmentSettings } from 'services/environment';
import { isInternalUser } from 'states/profile/business-rules';
import { hasConversionsRequiredWarning } from 'states/resources/campaigns/business-logic';

import Tabs from 'widgets-v5/tabs';

function selector(storeState, props) {
    const campaign = _.get(storeState, 'campaignsOverview.campaign', {});

    const { campaignId } = props;
    const tabActiveGroupingId = _.get(
        storeState,
        `adGroupingsOverview.adGroupingsList.tabActiveGroupingId`,
        ''
    );
    // const tabActiveAppListId = _.get(storeState, `session.campaigns.overview.${campaignId}.adGroupings.adGroupingsList.tabActiveGroupingId`, '');
    const tabActiveAppListId = undefined;

    return {
        campaignId,
        tabLinksUriQuery: tabLinksUriQuery(storeState, campaignId),
        tabActiveGroupingId,
        tabActiveAppListId,
        hasConversionsRequiredWarning: hasConversionsRequiredWarning(campaign),
    };
}

// ========
//  Main
// ========
class Comp extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            tab,
            campaignId,
            tabLinksUriQuery,
            tabActiveGroupingId,
            hasConversionsRequiredWarning,
        } = this.props;

        const environmentSettings = getEnvironmentSettings();

        const targetUrl_customDimensions = tabActiveGroupingId
            ? `/campaigns/${campaignId}/setup/custom-dimensions/${tabActiveGroupingId}${
                  tabLinksUriQuery.customDimensions
              }`
            : `/campaigns/${campaignId}/setup/custom-dimensions${
                  tabLinksUriQuery.customDimensions
              }`;

        const potentialTabItems = [
            {
                label: 'Ads',
                value: 'ads',
                link: `/campaigns/${campaignId}/setup/ads${tabLinksUriQuery.ads}`,
            },
            {
                label: 'Overview',
                value: 'overview',
                link: `/campaigns/${campaignId}/setup/overview`,
            },
            {
                label: 'Creatives',
                value: 'creatives',
                link: `/campaigns/${campaignId}/setup/creatives${tabLinksUriQuery.creatives}`,
                className: 'creatives-tab',
            },
            {
                label: 'Engagements',
                value: 'beacons',
                link: `/campaigns/${campaignId}/setup/beacons`,
                hidden: !isInternalUser(),
            },
        ];

        if (environmentSettings.canUsePixels()) {
            potentialTabItems.push({
                label: (
                    <React.Fragment>
                        <span>Pixels</span>
                        {hasConversionsRequiredWarning && (
                            <PriorityHighIcon fontSize="inherit" color="error" />
                        )}
                    </React.Fragment>
                ),
                value: 'pixels',
                link: `/campaigns/${campaignId}/setup/pixels`,
            });
        }

        if (isInternalUser() && environmentSettings.canUseCustomDimensions()) {
            potentialTabItems.push({
                label: 'Custom Dimensions',
                value: 'custom-dimensions',
                link: targetUrl_customDimensions,
            });
        }

        potentialTabItems.push({
            label: 'Files',
            value: 'files',
            link: `/campaigns/${campaignId}/setup/files${tabLinksUriQuery.files}`,
        });

        return <Tabs activeItem={tab} align="left" items={potentialTabItems} />;
    }
}

export default connect(selector)(Comp);

import React, { useState, useRef, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    notes: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export const Notes = (props) => {
    const [isNotesOverflowed, setOverflow] = useState(false);
    const notesRef = useRef();
    const classes = useStyles();

    useEffect(() => {
        if (!notesRef.current) {
            return;
        }
        const resize = () => {
            setOverflow(notesRef.current.scrollWidth > notesRef.current.clientWidth);
        };
        setOverflow(notesRef.current.scrollWidth > notesRef.current.clientWidth);
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [notesRef.current]);

    return (
        <Tooltip
            title={<Typography variant="body2">{props.children}</Typography>}
            disableHoverListener={!isNotesOverflowed}
        >
            <div ref={notesRef} className={classes.notes}>
                {props.children}
            </div>
        </Tooltip>
    );
}

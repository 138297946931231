import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import Menu, { VirtualMenu, MenuItem, MenuSeparator } from 'widgets-v5/menu';
import Checkbox from 'widgets-v5/checkbox';

class CheckboxMenu extends React.Component {
    static displayName = 'CheckboxMenu';

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.any.isRequired,
                children: PropTypes.array,
            })
        ).isRequired,
        values: PropTypes.array.isRequired,
        onScroll: PropTypes.func,
        maxHeight: PropTypes.number,
    };

    // handleChange = (e, option) => {
    //     let newValues;
    //     if (e.target.checked) {
    //         newValues = this.props.values.concat(option.value);
    //     } else {
    //         newValues = this.props.values.filter(v => v !== option.value);
    //     }

    //     this.props.onChange(newValues, option);
    // }
    handleRowClick = (option, checked) => {
        if (option.unselectable) {
            return;
        }

        let newValues;
        if (checked) {
            newValues = this.props.values.concat(option.value);
        } else {
            newValues = this.props.values.filter(v => v !== option.value);
        }

        this.props.onChange(newValues, option);
    };
    flattenOptions = () => {
        const items = [];

        function walk(options, depth) {
            _.each(options, option => {
                items.push({
                    ...option,
                    depth,
                });

                walk(option.children, depth + 1);
            });
        }
        walk(this.props.options, 0);

        return items;
    };

    render() {
        const checkedValues = {};
        _.each(this.props.values, value => {
            checkedValues[value] = true;
        });

        const items = this.flattenOptions();

        return (
            <VirtualMenu
                items={items}
                onScroll={this.props.onScroll}
                maxHeight={this.props.maxHeight}
            >
                {(option, style) => (
                    <MenuItem
                        key={option.value}
                        style={style}
                        onClick={() => this.handleRowClick(option, !checkedValues[option.value])}
                    >
                        <div
                            className={cn({
                                'checkbox-menu__item': true,
                                [`checkbox-menu__item--depth-${option.depth || 0}`]: true,
                            })}
                        >
                            {option.unselectable ? (
                                <div>
                                    {option.label} {option.description}
                                </div>
                            ) : (
                                <Checkbox
                                    checked={!!checkedValues[option.value]}
                                    onChange={e => {}}
                                    label={option.label}
                                    description={option.description}
                                />
                            )}
                        </div>
                    </MenuItem>
                )}
            </VirtualMenu>
        );
    }
}

export default CheckboxMenu;

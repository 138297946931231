import React, { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import PinDrop from '@mui/icons-material/PinDrop';
import Tooltip from '@mui/material/Tooltip';

import { SearchInput } from 'widgets-v6/standard-input';
import { SimpleSelect } from 'widgets-v6/select';

import { AudienceCard } from '../modules/audience-card';
import { AudienceTable } from '../modules/audience-table';
import flags from 'containers/flags/service';

const categoryMap = {
    pds_brand: {
        label: 'Brand',
        description: 'Users who frequent locations from select brands',
        color: '#FFCE50',
    },
    pds_standard: {
        label: 'Location Categories',
        description: 'Users who frequent top locations from select categories',
        color: '#FFB0C1',
    },
    pds_behaviour: {
        label: 'Behavioural',
        description:
            'Group consumers based on specific pattern they display, paired with frequently visited locations and first-party SDK location data',
        color: '#6ADF7E',
    },
};
const subCategoryColor = {
    Brand: '#FFCE50',
    'Location Categories': '#FFB0C1',
    Behavioural: '#6ADF7E',
};
const tabValueMapping = {
    all: {
        value: ['pds_brand', 'pds_standard', 'pds_behaviour'],
        label: 'All',
    },
    location_categories: {
        value: ['pds_standard'],
        label: 'Location Categories',
    },
    behavioural: {
        value: ['pds_behaviour'],
        label: 'Behavioural',
    },
    brand: {
        value: ['pds_brand'],
        label: 'Brand',
    },
};

const tabValueMappingV2 = {
    all: {
        value: ['Brand', 'Location Categories', 'Behavioural'],
        label: 'All',
    },
    location_categories: {
        value: ['Location Categories'],
        label: 'Location Categories',
    },
    behavioural: {
        value: ['Behavioural'],
        label: 'Behavioural',
    },
    brand: {
        value: ['Brand'],
        label: 'Brand',
    },
};

const getTabs = ({ audiences }) => {
    const tabs = [
        {
            value: 'all',
            label: 'All',
            avatar: <Avatar>{audiences && audiences.length}</Avatar>,
        },
        {
            value: 'location_categories',
            label: 'Location Categories',
            tooltip:
                'Audiences composed of users that have been seen within mapped polygon locations, and are best used to target based on consumer interest, customer loyalty, or conquesting strategies.',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Location Categories';
                            } else {
                                return audience.source_type === 'pds_standard';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'behavioural',
            label: 'Behavioural',
            tooltip:
                'Audiences composed of users that have been seen within mapped polygon locations, and are best used to target based on consumer interest, customer loyalty, or conquesting strategies.',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Behavioural';
                            } else {
                                return audience.source_type === 'pds_behaviour';
                            }
                        }).length}
                </Avatar>
            ),
        },
        {
            value: 'brand',
            label: 'Brand',
            tooltip:
                'Audiences composed of users that exhibited habitual visitation patterns, and are best used to target based on offline interests and frequent visitors to locations',
            avatar: (
                <Avatar>
                    {audiences &&
                        _.filter(audiences, audience => {
                            if (
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                            ) {
                                return audience.subCategory === 'Brand';
                            } else {
                                return audience.source_type === 'pds_brand';
                            }
                        }).length}
                </Avatar>
            ),
        },
    ];

    return tabs;
};

const sortingOptions = [
    {
        label: 'Sort by: Name',
        value: 'name',
        direction: 'asc',
    },
    {
        label: 'Sort by: Newest',
        value: '_created',
        direction: 'desc',
    },
    {
        label: 'Sort by: Last Updated',
        value: 'lastRefreshed',
        direction: 'desc',
    },
];

const useStyles = makeStyles(theme => ({
    iconWrapper: {
        display: 'inline-flex',
        height: '112px',
        width: '112px',
        backgroundColor: 'rgba(12, 186, 170, 0.12)',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: '3.5em',
        height: '3.5em',
        color: '#098679',
    },
}));

const Layout = ({ audienceSegments, onSearch, ...props }) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('all');
    const [activeSort, setActiveSort] = useState('name');

    const handleChangeTab = newValue => {
        setActiveTab(newValue);
        onSearch(null);
        setActiveSort('name');
    };

    const debouncedSearch = useMemo(() => {
        return _.debounce(value => onSearch(value), 200);
    }, []);

    const handleSearch = e => {
        const { value } = e.target;
        debouncedSearch(value);
    };

    const handleSortChange = event => {
        setActiveSort(event.target.value);
    };

    const sortedAudiences = _.orderBy(
        audienceSegments,
        [activeSort],
        [_.find(sortingOptions, option => activeSort === option.value).direction]
    );

    const filteredAudiences = _.filter(sortedAudiences, audience => {
        if (flags.isEnabled('en_2987_use_audience_category_and_subCategory_code')) {
            const activeSubCategories = tabValueMappingV2[activeTab].value;
            return _.includes(activeSubCategories, audience.subCategory);
        }
        const activeSourceTypes = tabValueMapping[activeTab].value;
        return _.includes(activeSourceTypes, audience.source_type);
    });
    const tabs = getTabs({ audiences: audienceSegments });

    const linkToAudienceTab = {
        pathname: '/audiences/all',
        query: { audienceTab: 'pelmorex' },
    };

    return (
        <Box padding={4}>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink
                    color="inherit"
                    component={Link}
                    to={linkToAudienceTab}
                    underline="none"
                >
                    Pelmorex Audiences
                </RouterLink>
                <Typography color="textPrimary">Precise Geolocation</Typography>
            </Breadcrumbs>
            <Container>
                <Grid container>
                    <Grid item xs={2} alignItems="center" style={{ textAlign: 'center' }}>
                        <Box className={classes.iconWrapper}>
                            <PinDrop className={classes.icon} />
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h3" gutterBottom>
                            Precise Geolocation
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                            Segments created using first-party SDK location data, points of interest
                            database from Cleanlist, and hand-drawn building footprints -- for the
                            highest precision and accuracy
                        </Typography>
                        <Grid container>
                            <Grid item>
                                <Box mt={3}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Total
                                    </Typography>
                                    <Typography variant="h5">{sortedAudiences.length}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={5} mb={1.5}>
                    <Grid container spacing={1}>
                        {_.map(tabs, option => (
                            <Grid item key={option.value}>
                                <Tooltip
                                    title={
                                        <Typography variant="body2">{option.tooltip}</Typography>
                                    }
                                    disableHoverListener={!option.tooltip}
                                >
                                    <Chip
                                        label={option.label}
                                        avatar={option.avatar}
                                        color={activeTab === option.value ? 'primary' : 'default'}
                                        onClick={() => handleChangeTab(option.value)}
                                    />
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box mb={2.5}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <SearchInput
                                placeholder="Filter Audiences"
                                onChange={handleSearch}
                                key={`${activeTab}_search`}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleSelect
                                options={sortingOptions}
                                value={activeSort}
                                onChange={handleSortChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <AudienceTable
                    audiences={filteredAudiences}
                    activeAudience={props.activeAudience}
                    renderContent={segment => (
                        <AudienceCard
                            segment={segment}
                            active={props.activeAudience === segment.id}
                            categoryLabel={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? segment.subCategory
                                    : categoryMap[segment.source_type].label
                            }
                            categoryColor={
                                flags.isEnabled(
                                    'en_2987_use_audience_category_and_subCategory_code'
                                )
                                    ? subCategoryColor[segment.subCategory]
                                    : categoryMap[segment.source_type].color
                            }
                        />
                    )}
                />
            </Container>
        </Box>
    );
};

export default Layout;

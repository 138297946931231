import React, { useState, useEffect } from 'react';
import moment from 'moment';
import last from 'lodash/last';

import { useOverviewAdData } from './hook';
import useDrawerState from '../../../utils/hooks/useDrawerState';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogTitle } from 'widgets-v6/modal';
import { Tabs } from './modules/tabs';
import generateConfigs from './configs';

import { SidebarAlertItems } from './modules/alerts';

const useStyles = makeStyles(theme => ({
    warningIcon: {
        position: 'absolute',
        fontSize: 13,
        left: 35,
        top: 4,
    },
    mainContainer: {
        paddingBottom: 0,
    },
    drawer: {
        width: 694,
        padding: theme.spacing(2),
    },
}));

export const Overview = ({
    routes,
    children,
}) => {
    const {
        endingSoon,
        isLoading,
        upcoming,
        recentlyEnded,
        hiddenColumns,
        sortBy,
        updateHiddenColumns,
        updateSortBy,
        fetchSidebarAlerts,
        sidebarAlerts,
        sidebarIsLoading,
    } = useOverviewAdData();

    const [open, handleClick, handleClose] = useDrawerState();

    const isSelfServeRoute = last(routes).path === 'self-serve';

    const onAlertColumnClick = (event, original) => {
        if (original.type === 'ad') {
            fetchSidebarAlerts({
                issue: original.alerts.issue,
                notice: original.alerts.notice,
                id: original.id,
                name: original.name,
                type: original.type,
                resourceId: [original.id],
                showAllSS: isSelfServeRoute,
            });
        } else {
            fetchSidebarAlerts({
                issue: original.alerts.issue,
                notice: original.alerts.notice,
                id: original.id,
                name: original.name,
                type: original.type,
                campaignId: [original.id],
                showAllSS: isSelfServeRoute,
            });
        }
        handleClick(event);
    };

    const {
        liveAdsConfig,
        recentlyEndedAdsConfig,
        upcomingAdsConfig,
    } = generateConfigs({ onAlertColumnClick });

    const classes = useStyles();
    const [overviewState, setOverviewState] = useState({
        currentList: endingSoon,
        currentConfig: liveAdsConfig,
        activeFilter: 'live',
    });

    useEffect(() => {
        setOverviewState({ ...overviewState, currentList: endingSoon });
    }, [isLoading]);

    const handleChangeActiveFilter = value => {
        let overviewConfig = {};
        if (value === 'live') {
            overviewConfig = {
                currentList: endingSoon,
                currentConfig: liveAdsConfig,
                activeFilter: 'live',
            };
        } else if (value === 'ended') {
            overviewConfig = {
                currentList: recentlyEnded,
                currentConfig: recentlyEndedAdsConfig,
                activeFilter: 'ended',
            };
        } else if (value === 'upcoming') {
            overviewConfig = {
                currentList: upcoming,
                currentConfig: upcomingAdsConfig,
                activeFilter: 'upcoming',
            };
        }
        setOverviewState(overviewConfig);
    };

    const selectHiddenColumns = () => {
        const currentFilter = overviewState.activeFilter;
        if (hiddenColumns && hiddenColumns[currentFilter]) {
            return hiddenColumns[currentFilter];
        } else {
            // Select the initial values for the hidden columns from the config.
            if (currentFilter === 'live') {
                return liveAdsConfig.hiddenColumns;
            } else if (currentFilter === 'ended') {
                return recentlyEndedAdsConfig.hiddenColumns;
            } else if (currentFilter === 'upcoming') {
                return upcomingAdsConfig.hiddenColumns;
            }
        }
    };

    const toggleColumn = columnName => {
        let currentColumns = selectHiddenColumns().slice(0);
        if (currentColumns.includes(columnName)) {
            currentColumns.splice(currentColumns.indexOf(columnName), 1);
        } else {
            currentColumns.push(columnName);
        }

        updateHiddenColumns({
            section: overviewState.activeFilter,
            hiddenColumns: currentColumns,
        });
    };

    const selectSortBy = () => {
        const currentFilter = overviewState.activeFilter;
        if (sortBy && sortBy[currentFilter] && sortBy[currentFilter].length) {
            return sortBy[currentFilter];
        } else {
            // Select the initial values for the sort from the config.
            if (currentFilter === 'live') {
                return liveAdsConfig.sortBy;
            } else if (currentFilter === 'ended') {
                return recentlyEndedAdsConfig.sortBy;
            } else if (currentFilter === 'upcoming') {
                return upcomingAdsConfig.sortBy;
            }
        }
    };

    const onSortChange = currentSort => {
        updateSortBy({
            section: overviewState.activeFilter,
            sortBy: currentSort,
        });
    };

    return (
        <Container maxWidth={false} maxHeight="100%" className={classes.mainContainer}>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
                classes={{ paper: classes.drawer }}
            >
                <DialogTitle onClose={handleClose} textVariant="h4">
                    Alerts
                </DialogTitle>
                {sidebarIsLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress isloading="true" />
                    </Box>
                ) : (
                    <SidebarAlertItems {...sidebarAlerts} />
                )}
            </Drawer>
            <Grid container direction="column">
                <Grid>
                    <Box mb={2} mt={3}>
                        <Typography variant="h4">
                            {moment(new Date()).format('dddd, MMMM D, YYYY')}
                        </Typography>
                    </Box>
                </Grid>
                <Tabs handleChangeActiveFilter={handleChangeActiveFilter} />
                {React.cloneElement(children, {
                    onSortChange,
                    toggleColumn,
                    selectSortBy,
                    selectHiddenColumns,
                    activeFilter: overviewState.activeFilter,
                    currentList: overviewState.currentList,
                    currentConfig: overviewState.currentConfig,
                    isSelfServeRoute,
                })}
            </Grid>
        </Container>
    );
};

export default Overview;

import PropTypes from 'prop-types';
import React from 'react';
import StandardInput from 'widgets-v5/standard-input';
import _ from 'lodash';

import { processMacrosForCustomHtml } from '../../forms/creative-bulk-upload-form/services/process-macros';

export const securePartnerDomainsAndReplaceUrlMacros = url => {
    const partnerDomainRegexes = [
        /(http:\/\/)(locationserve\.com|www.locationserve\.com)/g,
        /(http:\/\/)(engagefront\.com|www.engagefront\.com)/g,
    ];

    let modifiedUrl = url;

    modifiedUrl = processMacrosForCustomHtml({ value: modifiedUrl });

    _.forEach(partnerDomainRegexes, pattern => {
        if (modifiedUrl.match(pattern) !== null) {
            modifiedUrl = modifiedUrl.replace(/http:\/\//g, 'https://');
            return false;
        }
    });

    return modifiedUrl;
};

class UrlStandardInput extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'Enter a URL',
    };

    render() {
        return (
            <StandardInput
                {...this.props}
                value={this.props.value}
                placeholder={this.props.placeholder}
                autocorrect={input => securePartnerDomainsAndReplaceUrlMacros(input)}
                onChange={this.props.onChange}
            />
        );
    }
}

export default UrlStandardInput;

import _ from 'lodash';
import c from 'common/constants/flux-events';

import { can } from 'services/can';

import { adminOrgs as Action_orgs } from 'containers/admin-orgs/actions';

import * as services from 'services/resources/organization';
import Organizations from 'states/resources/organizations/actions';
import { PermissionsMapping } from 'common/constants/role-intents';

import { createHttp } from 'utils/http';

const fetchOrganizationDataWithGraphql = async orgId => {
    const http = createHttp();
    const query = `
        query getOrganizationDetailsDataForAdminOrgItemForm ($id: String) {
            organization(id: $id) {
                auditors
                auditees
                type
                name
                tech_fee
                credit_limit
                currency
                revenueModel
                default_timezone
                relationshipToAuthOrganization {
                    _relationship
                }
                custom_fields {
                    value
                    required
                    name
                    key
                }
                creatives {
                    standard {
                        allow_create
                        auto_approve
                    }
                    video {
                        allow_create
                        auto_approve
                    }
                    mraid {
                        allow_create
                        auto_approve
                    }
                    custom_html {
                        allow_create
                        auto_approve
                    }
                    native {
                        allow_create
                        auto_approve
                    }
                    third_party_vast {
                        allow_create
                        auto_approve
                    }
                }
            }
        }
    `;
    const variables = {
        id: orgId,
    };
    const { organization } = await http.graphql(query, variables);
    return organization;
};

export const orgItemFormForm = {
    draftInitWithGraphql(orgId) {
        return async dispatch => {
            dispatch({
                type: c.ADMIN_ORG_ITEM_FORM__INIT_START,
                payload: { orgId },
            });

            // Figure out relationship of organization in draft
            let relationship_inDraft;
            if (orgId) {
                const orgDetails_inDraft = await fetchOrganizationDataWithGraphql(orgId);
                relationship_inDraft =
                    orgDetails_inDraft.relationshipToAuthOrganization._relationship;
                try {
                    const org = await fetchOrganizationDataWithGraphql(orgId);

                    // prepare default draft
                    const defaultDraft = {
                        ..._.cloneDeep(services.draftDefaultValues),
                        ...services.apiValueToDraft(org),
                        organization: orgId,
                        default_timezone: org.default_timezone,
                    };

                    dispatch({
                        type: c.ADMIN_ORG_ITEM_FORM__INIT_SUCCESS,
                        payload: {
                            draft: defaultDraft,
                            orgId,
                            relationship: relationship_inDraft,
                        },
                    });
                } catch (httpErrors) {
                    dispatch({
                        type: c.ADMIN_ORG_ITEM_FORM__INIT_FAIL,
                        payload: { httpErrors },
                    });
                }
            } else if (!orgId) {
                relationship_inDraft = 'child';
                // prepare default draft
                const defaultDraft = {
                    ..._.cloneDeep(services.draftDefaultValues),
                    organization: void 0,
                };

                dispatch({
                    type: c.ADMIN_ORG_ITEM_FORM__INIT_SUCCESS,
                    payload: {
                        draft: defaultDraft,
                        orgId: void 0,
                        relationship: relationship_inDraft,
                    },
                });
            }
        };
    },

    fieldChanged(orgId, data) {
        return dispatch => {
            dispatch({
                type: c.ADMIN_ORG_ITEM_FORM__FIELD_CHANGED,
                payload: { orgId, data },
            });
        };
    },

    draftSumbitWithGraphqlFetch(orgId) {
        return async (dispatch, getState) => {
            const draft = _.get(getState(), `adminOrgItemForm.draft`, void 0);

            dispatch({
                type: c.ADMIN_ORG_ITEM_FORM__USER_SUBMIT,
                payload: { orgId, draft },
            });

            const formErrors = _.get(getState(), 'adminOrgItemForm.formErrors', null);
            if (formErrors.length !== 0) {
                // can not send because there are erorrs in form
                return;
            }
            let relationship;
            if (orgId) {
                const orgDetails = await fetchOrganizationDataWithGraphql(orgId);
                relationship = _.get(orgDetails, 'relationshipToAuthOrganization._relationship');
            }
            const meta = { orgId, relationship };
            const payload = _.cloneDeep(services.serializedForApi(draft, meta));

            dispatch({
                type: c.ADMIN_ORG_ITEM_FORM__SUBMIT_START,
                payload: { orgId, draft, payload },
            });

            if (orgId) {
                dispatch(Organizations.update(orgId, payload)).then(
                    org => {
                        dispatch({
                            type: c.ADMIN_ORG_ITEM_FORM__SUBMIT_SUCESS,
                            payload: { orgId, org },
                        });

                        if (can(PermissionsMapping.IMPERSONATE)) {
                            dispatch({
                                type: c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_SUCCESS,
                                payload: {
                                    organization: org,
                                },
                            });
                        }

                        const profile = _.get(getState(), 'profile');
                        if (
                            orgId === profile.organizationId &&
                            org.default_timezone !== profile.organizationTimezone
                        ) {
                            dispatch({
                                type: 'PROFILE__TIMEZONE__CHANGE',
                                payload: {
                                    organizationTimezone: org.default_timezone,
                                },
                            });
                        }
                    },
                    httpErrors => {
                        dispatch({
                            type: c.ADMIN_ORG_ITEM_FORM__SUBMIT_FAIL,
                            payload: { httpErrors },
                        });
                    }
                );
            } else if (!orgId) {
                dispatch(Organizations.create(payload)).then(
                    newOrg => {
                        dispatch(Action_orgs.init(orgId));
                        dispatch({
                            type: c.ADMIN_ORG_ITEM_FORM__SUBMIT_SUCESS,
                            payload: { orgId, ord: newOrg },
                        });

                        if (can(PermissionsMapping.IMPERSONATE)) {
                            dispatch({
                                type: c.IMPERSONATION_MODAL__REFRESH_ORGANIZATION_SUCCESS,
                                payload: {
                                    organization: newOrg,
                                },
                            });
                        }
                    },
                    httpErrors => {
                        dispatch({
                            type: c.ADMIN_ORG_ITEM_FORM__SUBMIT_FAIL,
                            payload: { httpErrors },
                        });
                    }
                );
            }
        };
    },

    draftTearDown() {
        return dispatch => {
            dispatch({
                type: c.ADMIN_ORG_ITEM_FORM__TEARDOWN,
                payload: {},
            });
        };
    },
};

import _ from 'lodash';

export default function selector(storeState) {
    const ftaLocationLists = _.get(storeState, 'mediaPlanForm.ftaLocationLists');
    const locationListMapping = {};
    _.each(ftaLocationLists, locationList => {
        locationListMapping[locationList.id] = locationList.name;
    });
    const ftaLocationListOptions = _.map(ftaLocationLists, locationList => ({
        value: locationList.id,
        label: locationList.name,
    }));
    const partner_id = _.get(storeState, 'mediaPlanForm.organization.fta_partner_id');
    return {
        locationListMapping,
        ftaLocationListOptions,
        partner_id,
    };
}

import _ from 'lodash';

export const NAME = 'retargetingDashboard';

const initialState = {
    isModalOpen: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;

        case 'RETARGETING_DASHBOARD__FETCH_SEGMENTS_DATA__END': {
            return {
                ...state,
                audienceSegments: action.payload.data,
                isLoading: false,
            };
        }
        case 'RETARGETING_DASHBOARD__FETCH_SEGMENTS_DATA__START': {
            return {
                ...state,
                isLoading: true,
            };
        }

        case 'RETARGETING_DASHBOARD__INIT_END': {
            return {
                ...state,
                totalSegmentCount: action.payload.totalSegmentCount,
            };
        }
    }
}

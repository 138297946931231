import _ from 'lodash';
import moment from 'moment';
import ProgressCalculator from 'common/progress-calculator';
import { getProgressData } from 'common/progress-calculator-v2';
import flags from 'containers/flags/service';

export default function getData({ ad, stats, timezone }) {
    if (!ad.start || !ad.end) {
        return { dataSeries: null, meta: {} };
    }

    let progress =
        flags.isEnabled('en_2507_daily_rtp_graph') && moment().diff(moment(ad.start), 'days') > 13
            ? { ...ad.metadata.progress.progressDataDaily }
            : { ...ad.metadata.progress.progressData };

    const progressCalculator = new ProgressCalculator(ad, stats);
    if (_.isEmpty(progress)) {
        progress = getProgressData({ ad, stats, timezone });
    }

    return {
        dataSeries: {
            hourlyIntervals: progressCalculator.getHourlyIntervals(),
            hourlyFill: progressCalculator.getHourlyFillDataSeries(),
            hourlyIdeal_paced: progressCalculator.getHourlyIdealPacedFillDataSeries(),
            hourlyIdeal_unpaced: progressCalculator.getHourlyIdealUnpacedFillDataSeries(),
        },
        meta: {
            metricType: progressCalculator.getPacingMetric(),
            hasTotalTarget: progressCalculator.hasTotalTarget(),
            hasDailyCap: progressCalculator.hasDailyCap(),
            timezone,
        },
        progressData: progress,
    };
}

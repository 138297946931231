import _ from 'lodash';
import http from 'utils/http';

const Conversions = {
    save(campaignId, payload) {
        return (dispatch, getState) => {
            return http()
                .patch(`campaigns/${campaignId}/conversions`, payload)
                .catch(err => {
                    console.error(`CREATE conversions error`, err);
                    throw err;
                });
        };
    },

    createOrganizationConversion({ organizationId, payload }) {
        return (dispatch, getState) => {
            return http().post(`organizations/${organizationId}/conversions`, payload);
        };
    },

    updateOrganizationConversion({ organizationId, conversionId, payload }) {
        return (dispatch, getState) => {
            return http().patch(
                `organizations/${organizationId}/conversions/${conversionId}`,
                payload
            );
        };
    },

    deleteOrganizationConversion({ organizationId, conversionId }) {
        return (dispatch, getState) => {
            return http().del(`organizations/${organizationId}/conversions/${conversionId}`);
        };
    },
};

export default Conversions;

import React from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

import PLATFORM_OPTIONS from 'common/constants/platforms';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { getIASGamblingWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

import {
    IAS_BRAND_SAFETY_CATEGORIES,
    IAS_BRAND_SAFETY_OPTIONS,
    IAS_BRAND_SAFETY_UNRATEABLE_ON_LABEL,
} from 'common/constants/ias-brand-safety';

const PLATFORMS = {};
_.each(PLATFORM_OPTIONS, option => (PLATFORMS[option.value] = option));

const useStyles = makeStyles({
    form: {
        width: '30%',
        margin: '5px 0px',
    },
    label: {
        fontSize: 14,
        margin: '10px 0px',
    },
    labelSmall: {
        fontSize: 17,
    },
});

const IASBrandSafety = ({ draft, ad, ads, updateDraft, isCrossPlatformCampaign }) => {
    const classes = useStyles();
    let platform;
    if (ad) {
        platform = _.get(ad, 'platform');
    }
    const brandSafety = _.get(draft, 'ias.brandSafety');
    const categories = _.map(IAS_BRAND_SAFETY_CATEGORIES, category => {
        let disabled;
        let helpText;

        if (isCrossPlatformCampaign) {
            let adPlatforms;
            if (ad) {
                adPlatforms = _.get(ad, 'platforms');
            } else {
                adPlatforms = _(ads)
                    .map(ad => ad.platforms)
                    .flatten()
                    .uniq()
                    .value();
            }

            disabled = false;
            const adHasPlatformNotInCategory = _.some(
                adPlatforms,
                platform => !_.includes(category.platforms, platform)
            );

            if (adHasPlatformNotInCategory) {
                const categoryPlatforms = _.map(
                    category.platforms,
                    platform => PLATFORMS[platform].label
                ).join(' and ');

                helpText = `Supports ${categoryPlatforms} only`;
            }
        } else {
            if (ad) {
                disabled = !_.includes(category.platforms, platform);
            } else if (ads && ads.length > 0) {
                const platforms = _.map(ads, ad => ad.platform);
                disabled = _.some(platforms, platform => !_.includes(category.platforms, platform));
            }

            if (disabled) {
                const platforms = _.map(
                    category.platforms,
                    platform => PLATFORMS[platform].label
                ).join(' and ');

                helpText = `Supports ${platforms} only`;
            }
        }

        return {
            ...category,
            id: category.value,
            disabled,
            helpText,
            value: brandSafety[category.value].value,
        };
    });

    const isUnrateableChecked = brandSafety.unrateable.value !== 0;

    const handleChangeRateable = (category, e) => {
        const { value } = e.target;
        const changes = {
            ...draft.ias,
            brandSafety: {
                ...draft.ias.brandSafety,
                [category]: {
                    ...draft.ias.brandSafety[category],
                    value,
                },
            },
        };
        updateDraft({ ias: changes });
    };

    const handleChangeUnrateable = e => {
        const { checked } = e.target;
        const changes = {
            ...draft.ias,
            brandSafety: {
                ...draft.ias.brandSafety,
                unrateable: {
                    ...draft.ias.brandSafety.unrateable,
                    value: checked ? 1 : 0,
                },
            },
        };
        updateDraft({ ias: changes });
    };

    const gamblingCategory = _.find(categories, category => category.id === 'gambling');
    const gamblingValue = _.get(gamblingCategory, 'value');
    let warningData = {};

    if (isCrossPlatformCampaign) {
        const platforms = ad
            ? _.get(ad, 'platforms')
            : _(ads)
                  .map(ad => ad.platforms)
                  .flatten()
                  .uniq()
                  .value();
        warningData = getIASGamblingWarning({ platforms, gamblingValue });
    }

    return (
        <div>
            <Typography>Brand Safety</Typography>
            <div>
                {_.map(categories, categoryOption => (
                    <div key={categoryOption.id}>
                        <FormControl className={classes.form} disabled={categoryOption.disabled}>
                            <InputLabel className={classes.labelSmall}>
                                {categoryOption.label}
                            </InputLabel>
                            <Select
                                value={categoryOption.value}
                                onChange={e => handleChangeRateable(categoryOption.id, e)}
                            >
                                {_.map(IAS_BRAND_SAFETY_OPTIONS, option => (
                                    <MenuItem value={option.value} key={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {categoryOption.helpText && (
                                <FormHelperText>{categoryOption.helpText}</FormHelperText>
                            )}
                            {categoryOption.id === 'gambling' &&
                                isCrossPlatformCampaign &&
                                warningData.hasWarning && (
                                    <WarningText
                                        message={warningData.message}
                                        title={warningData.title}
                                        severity={warningData.severity}
                                    />
                                )}
                        </FormControl>
                    </div>
                ))}

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isUnrateableChecked}
                            onChange={e => handleChangeUnrateable(e)}
                        />
                    }
                    label={IAS_BRAND_SAFETY_UNRATEABLE_ON_LABEL}
                />
            </div>
        </div>
    );
};

export default IASBrandSafety;

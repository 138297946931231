import _ from 'lodash';
import React from 'react';
import flags from 'containers/flags/service';

import { formatGeoRegionCity } from 'services/gis/geo-region-format';
import COUNTRIES from 'common/constants/countries';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { IncludeExcludeLayout } from 'widgets-v6/layout';

// Reuse code from v2
function _getCountryName(code) {
    var country = _.find(COUNTRIES, function(c) {
        return c.id === code;
    });
    return country && country.text;
}

export default function formatGeoCountry(obj) {
    var html = '',
        content,
        countryName = _getCountryName(obj.country);
    var includes, excludes;
    if (!_.isEmpty(obj.include)) {
        content = _.compact(_.map(obj.include, _.partial(formatGeoRegionCity, obj.country)));
        includes = (
            <div className="regions" key={_.uniqueId()}>
                <IncludeExcludeLayout type="Include" marginBottom>
                    <div title={content.join(', ')}>
                        {_.map(content, (place, i) => (
                            <div key={place + i}>{place}</div>
                        ))}
                    </div>
                </IncludeExcludeLayout>
            </div>
        );
    }

    if (!_.isEmpty(obj.exclude)) {
        content = _.compact(_.map(obj.exclude, _.partial(formatGeoRegionCity, obj.country)));
        excludes = (
            <div className="regions">
                <IncludeExcludeLayout type="Exclude">
                    <div title={content.join(', ')}>
                        {_.map(content, (place, i) => (
                            <div key={place + i}>{place}</div>
                        ))}
                    </div>
                </IncludeExcludeLayout>
            </div>
        );
    }

    return (
        <div key={_.uniqueId()}>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography>{countryName}</Typography>
                </Box>
                {includes}
                {excludes}
            </Box>
        </div>
    );
}

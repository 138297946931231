import _ from 'lodash';

const LEVELS = [
    {
        name: 'allow_low_risk',
        value: 65,
        label: 'Low Risk Apps Only',
    },
    {
        name: 'allow_low_med_risk',
        value: 80,
        label: 'Low and Medium Risk Apps Only',
    },
    {
        name: 'allow_low_med_high_risk',
        value: 100,
        label: 'Allow All',
    },
    {
        name: 'disabled',
        value: -1,
        label: 'Off',
    },
];

const _LEVEL_BY_NAME = {};
const _LEVEL_BY_VALUE = {};

_.each(LEVELS, level => {
    _LEVEL_BY_NAME[level.name] = level;
    _LEVEL_BY_VALUE[level.value] = level;
});

// Export it after building the objects
export const LEVEL_BY_NAME = _LEVEL_BY_NAME;
export const LEVEL_BY_VALUE = _LEVEL_BY_VALUE;

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions, REDUCER_KEY } from './reducer';

export function useAdSummary() {
    const dispatch = useDispatch();
    const state = useSelector(state => state[REDUCER_KEY]);
    const boundActions = bindActionCreators(actions, dispatch);

    return {
        ...state,
        ...boundActions,
    };
}

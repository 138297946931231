import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import shallowCompare from 'utils/shallow-compare';
import BaseColumnForm from '../base-column-form';
import withStyles from '@mui/styles/withStyles';
import uuid from 'uuid';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import {
    changeWeight,
    handleCreativesModeSwitch,
    getAssignedPacing,
    getTotalRotationWeight,
    isPacingColumnEnabledForRotationSelector,
    handleSelectEvenCreative,
    handleSelectWeightedCreative,
    handleClearCreatives,
    handleUpdateCreativeScheduleRow,
    handleAddCreativeScheduleRow,
    handleRemoveCreativeScheduleRow,
    processCreative,
    changeScheduledWeightedCreativeSelection,
    changeScheduledWeightedCreativeWeight,
    addCreativeToScheduledWeightedCreative,
    removeScheduledWeightedCreative,
    changeScheduledWeightedCreativeEndDate,
    addScheduledWeightedCreativeDateRange,
    removeScheduledWeightedCreativeDateRange,
    changeScheduledEvenWeighted,
    handleMultiClearCreatives,
} from 'states/resources/creatives/business-logic';
import creativeActions from 'states/resources/creatives/actions';
import { checkIsNewScheduledWeightedRotationMode } from 'states/resources/ads/business-logic';
import {
    getScheduledWeightedRotationSummary,
    CreativesByScheduleWeight,
} from 'widgets/summary-view/summary-type-creative';

import { BlockLoadGroup } from 'widgets-v5/load-group';
import Creative from 'forms/ad-form/modules/creative';

import { processCreativeWarning } from 'forms/ad-form/reducer';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

import Box from '@mui/material/Box';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

const styles = theme => ({
    image: {
        width: '100%',
        paddingRight: 10,
        minWidth: 100,
        maxWidth: 100,
    },
    imageName: {
        whiteSpace: 'normal',
    },
    imageMeta: {
        marginTop: 5,
    },
    grid: {
        marginBottom: theme.spacing(2),
    },
    rotation: {
        marginBottom: theme.spacing(1),
    },
    previewImgColumn: {
        marginRight: 10,
    },
    creativeSummaryContainer: {
        marginBottom: 10,
    },
});

export const CreativeForm = connect()(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isLoading: false,
                creatives: [],
                creativeMapping: {},
            };
        }

        componentDidMount() {
            const { campaign, dispatch } = this.props;
            const campaignId = campaign.id;

            this.setState({ isLoading: true }, () => {
                dispatch(creativeActions.getAll(campaignId)).then(creatives => {
                    const creativeMapping = {};
                    _.forEach(creatives, creative => {
                        creativeMapping[creative.id] = creative;
                    });
                    this.setState({ creatives, isLoading: false, creativeMapping });
                });
            });
        }

        onFieldChange = updateDraft => fieldName => {
            return fieldValue => {
                const newFormData = {
                    [fieldName]: fieldValue,
                };

                updateDraft(newFormData);
            };
        };

        changeCreativeRotationWeight = (updateDraft, draft) => (creativeId, newWeight) => {
            const { weighted } = draft.rotation_rules;

            const nextWeighted = changeWeight(weighted, creativeId, newWeight);

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    mode: CreativeRotationMethodMapping.Weighted,
                    weighted: nextWeighted,
                },
            });
        };

        switchMode = (creatives, draft, updateDraft) => newMode => {
            let selectedCreatives = _(creatives)
                .filter(c => c.selected)
                .value();

            const {
                nextWeighted,
                nextCreatives,
                nextScheduled,
                nextWeather,
            } = handleCreativesModeSwitch(draft, newMode, selectedCreatives);

            updateDraft({
                creative: nextCreatives || draft.creative,
                rotation_rules: {
                    mode: newMode,
                    weighted: nextWeighted,
                    scheduled: nextScheduled || draft.rotation_rules.scheduled,
                    weather: nextWeather,
                },
            });
        };

        selectSingleCreative = (draft, updateDraft) => creativeId => {
            updateDraft({
                creative: [creativeId],
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                },
            });
        };

        selectEvenCreative = (draft, updateDraft) => creativeIds => {
            const { nextWeighted } = handleSelectEvenCreative(creativeIds);

            updateDraft({
                creative: creativeIds,
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: nextWeighted,
                },
            });
        };

        selectWeightedCreative = (draft, updateDraft) => nextCreatives => {
            const { weighted } = draft.rotation_rules;

            const { nextWeighted } = handleSelectWeightedCreative(nextCreatives, weighted);

            updateDraft({
                creative: nextCreatives,
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: nextWeighted,
                },
            });
        };

        clearCreatives = (draft, updateDraft) => () => {
            const { nextCreatives, nextRotationRules } = handleClearCreatives(draft);

            updateDraft({
                creative: nextCreatives,
                rotation_rules: nextRotationRules,
            });
        };

        updateCreativeScheduleRow = (draft, updateDraft) => (rowId, data) => {
            const { nextScheduled } = handleUpdateCreativeScheduleRow(draft, rowId, data);

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        addCreativeScheduleRow = (draft, updateDraft) => () => {
            const nextScheduled = handleAddCreativeScheduleRow(draft);

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        removeCreativeScheduleRow = (draft, updateDraft) => rowId => {
            const nextScheduled = handleRemoveCreativeScheduleRow(rowId, draft);

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        addDateRange = (draft, updateDraft) => () => {
            const { nextScheduled } = addScheduledWeightedCreativeDateRange({
                draft,
            });

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        removeScheduledWeightedCreative = (draft, updateDraft) => ({
            dateRangeIndex,
            creativeIndex,
        }) => {
            const { nextScheduled } = removeScheduledWeightedCreative({
                draft,
                dateRangeIndex,
                creativeIndex,
            });

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        removeWarningMessage = () => {};

        removeDateRange = (draft, updateDraft) => dateRangeIndex => {
            const { nextScheduled } = removeScheduledWeightedCreativeDateRange({
                draft,
                dateRangeIndex,
            });

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        changeScheduledWeightedCreativeEndDate = (draft, updateDraft) => ({
            dateRangeIndex,
            end,
        }) => {
            const { nextScheduled } = changeScheduledWeightedCreativeEndDate({
                draft,
                dateRangeIndex,
                end,
            });

            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        selectScheduledWeightedCreative = (draft, updateDraft) => ({
            dateRangeIndex,
            creativeIndex,
            creativeId,
        }) => {
            updateDraft(draft => {
                const { nextScheduled } = changeScheduledWeightedCreativeSelection({
                    draft,
                    dateRangeIndex,
                    creativeIndex,
                    creativeId,
                });
                return {
                    ...draft,
                    rotation_rules: {
                        ...draft.rotation_rules,
                        weighted: [],
                        scheduled: nextScheduled,
                    },
                };
            });
        };

        addScheduledWeightedCreative = (draft, updateDraft) => dateRangeIndex => {
            updateDraft(draft => {
                const { nextScheduled } = addCreativeToScheduledWeightedCreative({
                    draft,
                    dateRangeIndex,
                });
                return {
                    ...draft,
                    rotation_rules: {
                        ...draft.rotation_rules,
                        weighted: [],
                        scheduled: nextScheduled,
                    },
                };
            });
        };

        changeScheduledWeightedCreativeWeight = (draft, updateDraft) => ({
            dateRangeIndex,
            creativeIndex,
            weight,
        }) => {
            updateDraft(draft => {
                const { nextScheduled } = changeScheduledWeightedCreativeWeight({
                    draft,
                    dateRangeIndex,
                    creativeIndex,
                    weight,
                });
                return {
                    ...draft,
                    rotation_rules: {
                        ...draft.rotation_rules,
                        weighted: [],
                        scheduled: nextScheduled,
                    },
                };
            });
        };

        changeScheduledToEvenWeighted = (draft, updateDraft) => ({ dateRangeIndex }) => {
            updateDraft(draft => {
                const { nextScheduled } = changeScheduledEvenWeighted({
                    draft,
                    dateRangeIndex,
                });
                return {
                    ...draft,
                    rotation_rules: {
                        ...draft.rotation_rules,
                        weighted: [],
                        scheduled: nextScheduled,
                    },
                };
            });
        };

        removeMultiCreatives = (draft, updateDraft) => ({ dateRangeIndex }) => {
            const { nextScheduled } = handleMultiClearCreatives({
                draft,
                dateRangeIndex,
            });
            updateDraft({
                rotation_rules: {
                    ...draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            });
        };

        handleCreativeRotationByWeatherChange = (oldDraft, updateDraft) => ({ draft }) => {
            updateDraft({
                ...draft,
            });
        };

        render() {
            const { ad, ads, campaign, onSave } = this.props;
            let processedAd;
            if (ad && !ads && ad.rotation_rules) {
                processedAd = {
                    ...ad,
                    rotation_rules: {
                        ...ad.rotation_rules,
                        weighted: _.map(ad.rotation_rules.weighted, weightInfo => ({
                            ...weightInfo,
                            weight: weightInfo.weight * 100,
                        })),
                    },
                };
            }

            const adPlatforms = ad
                ? _.get(ad, 'platforms')
                : _(ads)
                      .map(ad => ad.platforms)
                      .flatten()
                      .uniq()
                      .value();

            const isAdStartAndAdEndEqual = () => {
                if (_.isNil(ads)) {
                    return true;
                }

                for (const ad of ads) {
                    if (ad.rotation_rules.mode !== CreativeRotationMethodMapping.Scheduled) {
                        return true;
                    }
                }

                //check if all ads have same start date/time
                let sameStartDateTime = false;
                let sameEndDateTime = false;
                const startDateTime = ads[0].start;
                sameStartDateTime = _.every(ads, ['start', startDateTime]);

                //check if all ads have same end date/time
                if (sameStartDateTime) {
                    const endDateTime = ads[0].end;
                    sameEndDateTime = _.every(ads, ['end', endDateTime]);
                }
                if (_.size(ads) < 2) {
                    return true;
                }
                return sameStartDateTime && sameEndDateTime;
            };

            return (
                <BaseColumnForm
                    ad={processedAd}
                    ads={ads}
                    campaign={campaign}
                    headline="Creative"
                    fields={['rotation_rules', 'creative']}
                    shouldRefreshPredictionsOnSave={true}
                    isCopyDraftEnabled={isAdStartAndAdEndEqual()}
                    onSave={onSave}
                    onCancel={onSave}
                >
                    {(updateDraft, shouldShowErrors, errors, draft) => {
                        if (this.state.isLoading) {
                            return (
                                <div
                                    className="ef6-alignment__center"
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <BlockLoadGroup isLoading={this.state.isLoading} />
                                </div>
                            );
                        }

                        let sameStartDateTime = false;
                        let sameEndDateTime = false;

                        if ((ad && !ads) || (!ad && ads && ads.length <= 1)) {
                            sameStartDateTime = true;
                            sameEndDateTime = true;
                        } else {
                            //check if all ads have same start date/time
                            const startDateTime = ads[0].start;
                            sameStartDateTime = _.every(ads, ['start', startDateTime]);

                            //check if all ads have same end date/time
                            if (sameStartDateTime) {
                                const endDateTime = ads[0].end;
                                sameEndDateTime = _.every(ads, ['end', endDateTime]);
                            }
                        }

                        const disableScheduledRotation = !sameStartDateTime || !sameEndDateTime;
                        const processedDraft = {
                            ...draft,
                        };

                        if (!disableScheduledRotation && _.size(ads) > 0) {
                            processedDraft.start = ads[0].start;
                            processedDraft.end = ads[0].end;
                        }

                        const draftLoaded = !!processedDraft;
                        const { campaign } = this.props;
                        let rotationMode = draftLoaded
                            ? processedDraft.rotation_rules.mode
                            : CreativeRotationMethodMapping.Single;
                        if (
                            campaign.isUsingConsolidateCreativeOptions &&
                            rotationMode === CreativeRotationMethodMapping.Single
                        ) {
                            rotationMode = CreativeRotationMethodMapping.Scheduled;
                        }
                        const campaignId = campaign.id;
                        const processedCreatives = _(this.state.creatives)
                            .map(creative => processCreative(creative, processedDraft, creative.id, campaign))
                            .filter(creative => {
                                if (creative.isArchived) {
                                    return false;
                                }
                                return true;
                            })
                            .filter(creative => {
                                return campaign.isCrossPlatformCampaign
                                    ? true
                                    : _.includes(creative.platforms, this.props.platformFilter);
                            })
                            .value();

                        const primaryPacing = draftLoaded && processedDraft.primary_pacing;
                        let totalRotationWeight = getTotalRotationWeight(
                            draftLoaded,
                            processedDraft
                        );
                        let assignedPacing = getAssignedPacing(
                            primaryPacing,
                            processedDraft,
                            totalRotationWeight
                        );
                        let pacingColumnEnabled = isPacingColumnEnabledForRotationSelector(
                            processedDraft
                        );
                        let conversionsInCampaign = _.concat(
                            campaign.conversions,
                            campaign.advertiserConversions
                        );

                        const selectedCreativeIds = processedDraft.creative;
                        const rotation_rules = processedDraft.rotation_rules;
                        const creativeMapping = this.state.creativeMapping;
                        let warningData = {};

                        if (!_.isEmpty(creativeMapping) && campaign.isCrossPlatformCampaign) {
                            warningData = processCreativeWarning({
                                creativeIds: selectedCreativeIds,
                                rotation_rules,
                                creativeMapping,
                                adPlatforms,
                            });
                        }

                        if (
                            campaign.isUsingConsolidateCreativeOptions &&
                            _.size(processedDraft.rotation_rules.scheduled) === 1 &&
                            processedDraft.rotation_rules.scheduled[0].start === ''
                        ) {
                            updateDraft(draft => {
                                return {
                                    ...draft,
                                    rotation_rules: {
                                        ...draft.rotation_rules,
                                        weighted: [],
                                        mode: CreativeRotationMethodMapping.Scheduled,
                                        scheduled: [
                                            {
                                                id: uuid.v4(),
                                                start: processedDraft.start,
                                                end: processedDraft.end,
                                                weighted: [],
                                                isManualWeight: false,
                                            },
                                        ],
                                    },
                                };
                            });
                        }

                        return (
                            <div>
                                <div>
                                    <Creative
                                        formData={processedDraft}
                                        onFieldChange={this.onFieldChange(updateDraft)}
                                        onCreativeChange={this.onFieldChange(updateDraft)(
                                            'creative'
                                        )}
                                        onRotationChange={this.onFieldChange(updateDraft)(
                                            'rotation_rules'
                                        )}
                                        switchMode={this.switchMode(
                                            processedCreatives,
                                            processedDraft,
                                            updateDraft
                                        )}
                                        hasConversions={conversionsInCampaign.length > 0}
                                        changeWeight={this.changeCreativeRotationWeight(
                                            updateDraft,
                                            processedDraft
                                        )}
                                        selectSingleCreative={this.selectSingleCreative(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        selectEvenCreative={this.selectEvenCreative(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        selectWeightedCreative={this.selectWeightedCreative(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        clearCreatives={this.clearCreatives(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        updateScheduleRow={this.updateCreativeScheduleRow(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        addScheduleRow={this.addCreativeScheduleRow(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        removeScheduleRow={this.removeCreativeScheduleRow(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        errors={errors}
                                        showErrors={shouldShowErrors}
                                        creatives={processedCreatives}
                                        rotationMode={rotationMode}
                                        assignedPacing={assignedPacing}
                                        pacingColumnEnabled={pacingColumnEnabled}
                                        totalWeight={totalRotationWeight}
                                        campaignId={campaignId}
                                        campaign={campaign}
                                        disableCreatingCreative={true}
                                        disableScheduledRotation={disableScheduledRotation}
                                        disableEditingCreative={true}
                                        disableDuplicatingCreative={true}
                                        mode={'edit'}
                                        addDateRange={this.addDateRange(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        removeScheduledWeightedCreative={this.removeScheduledWeightedCreative(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        onCreativeRotationByWeatherChange={this.handleCreativeRotationByWeatherChange(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        removeWarningMessage={this.removeWarningMessage(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        removeDateRange={this.removeDateRange(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        changeScheduledWeightedCreativeEndDate={this.changeScheduledWeightedCreativeEndDate(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        selectScheduledWeightedCreative={this.selectScheduledWeightedCreative(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        changeScheduledWeightedCreativeWeight={this.changeScheduledWeightedCreativeWeight(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        addScheduledWeightedCreative={this.addScheduledWeightedCreative(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        showWeightColumn={
                                            !_.includes(
                                                [
                                                    CreativeRotationMethodMapping.OptimizedForClicks,
                                                    CreativeRotationMethodMapping.OptimizedForConversions,
                                                    CreativeRotationMethodMapping.OptimizedForVideoCompletion,
                                                    CreativeRotationMethodMapping.Weather,
                                                ],
                                                rotationMode
                                            )
                                        }
                                        changeScheduledEvenWeighted={this.changeScheduledToEvenWeighted(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        removeMultiCreatives={this.removeMultiCreatives(
                                            processedDraft,
                                            updateDraft
                                        )}
                                        isCrossPlatformCampaign={campaign.isCrossPlatformCampaign}
                                        creativeWarning={warningData}
                                    />
                                </div>
                                {campaign.isCrossPlatformCampaign && warningData.hasWarning && (
                                    <Box marginTop={2}>
                                        <WarningText
                                            severity={warningData.severity}
                                            title={warningData.title}
                                            message={warningData.message}
                                        />
                                    </Box>
                                )}
                            </div>
                        );
                    }}
                </BaseColumnForm>
            );
        }
    }
);

const CreativeSummary = connect((storeState, props) => {
    const creativeIds = _.get(
        storeState,
        `resources.campaigns.${props.campaign.id}.attr.creatives`
    );
    const creativeLookup = {};
    _.each(creativeIds, id => {
        const creative = _.get(storeState, `resources.creatives.${id}`);
        if (creative) {
            creativeLookup[id] = creative;
        }
    });

    const isNewScheduledWeightedRotation =
        checkIsNewScheduledWeightedRotationMode(props.ad.rotation_rules) &&
        props.ad.rotation_rules.mode === CreativeRotationMethodMapping.Scheduled;

    return {
        creativeLookup,
        schedules: isNewScheduledWeightedRotation
            ? getScheduledWeightedRotationSummary({
                  rotationRules: props.ad.rotation_rules,
                  creativesMapping: creativeLookup,
              })
            : [],
    };
})(
    class extends React.Component {
        shouldComponentUpdate(nextProps, nextState) {
            const shouldUpdate =
                !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
            return shouldUpdate;
        }

        render() {
            const { ad, creativeLookup, classes, schedules } = this.props;
            let label;
            if (
                ad.creative.length === 0 &&
                schedules.length === 0 &&
                ad.rotation_rules.mode !== CreativeRotationMethodMapping.Weather
            ) {
                label = 'No Creative Assigned';
            } else if (schedules.length > 0) {
                return <CreativesByScheduleWeight schedules={schedules} classes={classes} />;
            } else if (ad.creative.length === 1) {
                label = _.map(ad.creative, creativeId => {
                    if (!creativeLookup[creativeId]) {
                        return <div key={creativeId} />;
                    }
                    return (
                        <Grid key={creativeId} container wrap="nowrap">
                            <Grid item>
                                <img
                                    className={classes.image}
                                    src={
                                        creativeLookup[creativeId].attr.image_preview_url ||
                                        creativeLookup[creativeId].attr.reference_image_preview_url
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <div># {creativeLookup[creativeId].attr.id}</div>
                                <div className={classes.imageName}>
                                    {creativeLookup[creativeId].attr.name}
                                </div>
                                <Grid container className={classes.imageMeta} spacing={1}>
                                    <Grid item>
                                        <Chip
                                            label={creativeLookup[creativeId].attr.size}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Chip
                                            label={creativeLookup[creativeId].attr.format}
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                });
            } else if (ad.creative.length > 1 || schedules.length > 1) {
                label = 'Multiple Creatives';
            } else if (ad.rotation_rules.mode === CreativeRotationMethodMapping.Weather) {
                const numOfWeatherRules = ad.rotation_rules.weather.length;
                label = `${numOfWeatherRules} weather condition rule`;
                if (numOfWeatherRules > 1) {
                    label += '(s)';
                }
            }

            return <div>{label}</div>;
        }
    }
);

export default withStyles(styles)(CreativeSummary);

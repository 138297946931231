import React from 'react';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { formatNumber_whole } from 'utils/formatting';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';

import { DialogTitle, DialogContent, DialogActions } from 'widgets-v6/modal';
import { getEnvironmentSettings } from 'services/environment';
import { PlatformMapping } from 'states/resources/ads/business-logic';

const PLATFORM_ICON_MAPPING = {
    inapp: {
        component: <PhoneIphoneIcon fontSize="small" />,
    },
    mweb: {
        component: <PhonelinkIcon fontSize="small" />,
    },
    ctv: {
        component: <ConnectedTvIcon fontSize="small" />,
    },
    dooh: {
        component: <HomeMaxIcon fontSize="small" />,
    },
};

export const AudienceDetails = ({
    segment,
    isOpen,
    onClose,
    onClickNextAudience,
    onClickPrevAudience,
    isFirstSegment,
    isLastSegment,
    segmentDetails,
    summaryPoints,
    ...props
}) => {
    return (
        <Dialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
            <DialogTitle onClose={onClose}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="h6">{segment.name}</Typography>
                    </Grid>
                    <Grid item>
                        {_.map(segment.supports, platform => {
                            if (
                                ((platform === PlatformMapping.CTV ||
                                    platform === PlatformMapping.DOOH) &&
                                    !getEnvironmentSettings().canUseCTVOrDOOH()) ||
                                platform === null
                            ) {
                                return null;
                            }

                            return (
                                <React.Fragment key={`${segment.id}_${platform}`}>
                                    {PLATFORM_ICON_MAPPING[platform].component}
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="h5" component="h2">
                        {!_.isNil(segment.uniques) ? (
                            <span>{formatNumber_whole(segment.uniques)}</span>
                        ) : (
                            <span>N/A</span>
                        )}
                    </Typography>
                    <Typography color="textSecondary" variant="body2" gutterBottom>
                        Uniques
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        Last updated: {moment(segment.lastRefreshed).format('MMM D, YYYY')}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Grid container spacing={1}>
                        {_.map(summaryPoints, summaryPoint => (
                            <Grid item key={`${segment.id}_${summaryPoint}`}>
                                <Chip label={summaryPoint} size="small" variant="outlined" />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {_.map(segmentDetails, segmentDetail => (
                            <Grid item xs={3} key={`${segment.id}_${segmentDetail.label}`}>
                                <Card elevation={0} variant="outlined">
                                    <CardContent>
                                        <Typography align="center" gutterBottom>
                                            {segmentDetail.getValue({
                                                segment,
                                            })}
                                        </Typography>
                                        <Typography align="center">
                                            {segmentDetail.label}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box marginTop={1}>{props.body}</Box>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={onClickPrevAudience}
                            disabled={isFirstSegment}
                        >
                            <Grid container spacing={1} justifyContent="center">
                                <Grid item>
                                    <ArrowBackIcon />
                                </Grid>
                                <Grid item>Previous Audience</Grid>
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onClickNextAudience}
                            disabled={isLastSegment}
                        >
                            <Grid container spacing={1} justifyContent="center">
                                <Grid item>Next Audience</Grid>
                                <Grid item>
                                    <ArrowForwardIcon />
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

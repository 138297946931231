import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { GlobalModal } from 'widgets-v5/modal';
import { PrimaryButton, SecondaryButton, NeutralButton } from 'widgets-v5/buttons';
import { EqualColumnsLayout, EqualColumn } from 'widgets-v5/equal-columns-layout';
import { PanelsLayout, WorkspacePanel, ScrollingSidebarPanel } from 'widgets-v5/panels-layout';
import Navigation from 'widgets-v5/navigation';
import { ComposableDataTable, Cell, Row } from 'widgets-v5/data-table';
import StandardInput from 'widgets-v5/standard-input';
import Spacer from 'widgets-v5/spacer';
import CircularProgress from '@mui/material/CircularProgress';

import {
    init,
    impersonate,
    unimpersonate,
    openModal,
    closeModal,
    setOrganization,
} from './actions';
import {
    rolePresentationDictionary,
} from 'common/constants/role-presentation-dict';
import select from './selector';

class Impersonate extends React.Component {
    UNSAFE_componentWillMount() {}

    impersonate = userId => {
        this.props.dispatch(impersonate(userId));
    };

    unimpersonate = () => {
        this.props.dispatch(unimpersonate());
    };

    handleClose = () => {
        this.props.dispatch(closeModal());
    };

    handleOpen = () => {
        this.props.dispatch(init());
        this.props.dispatch(openModal());
    };

    setOrganization = orgId => {
        this.props.dispatch(setOrganization(orgId));
    };

    render() {
        const {
            userFirstName,
            userOrganizationName,
            orgToUserMappings,

            isImpersonating,

            isModalOpen,
            isLoading,

            currentUserId,
            selectedOrganization,
        } = this.props;

        if (!userFirstName || !userOrganizationName) {
            return (
                <div className="ef5-impersonate">
                    <i className="fa fa-spinner fa-spin ef5-impersonate_loading" />
                </div>
            );
        }

        const modal = (
            <ImpersonationModal
                orgToUserMappings={orgToUserMappings}
                selectedOrganization={selectedOrganization}
                isImpersonating={isImpersonating}
                handleClose={this.handleClose}
                impersonate={this.impersonate}
                unimpersonate={this.unimpersonate}
                setOrganization={this.setOrganization}
                isModalOpen={isModalOpen}
                currentUserId={currentUserId}
                isLoading={isLoading}
            />
        );

        if (this.props.isImpersonating) {
            return (
                <div className="ef5-impersonate ef5-impersonate_is-impersonating">
                    <span className="ef5-impersonate__status ef5-impersonate__status_is-impersonating">
                        Viewing page as:
                        <br />
                        {this.props.userFirstName} ({this.props.userOrganizationName})
                    </span>
                    <EqualColumnsLayout>
                        <EqualColumn>
                            <SecondaryButton
                                className="ef5-impersonate__control"
                                text="Switch"
                                onClick={this.handleOpen}
                            />
                        </EqualColumn>
                        <EqualColumn>
                            <SecondaryButton
                                className="ef5-impersonate__control"
                                text="Stop"
                                onClick={this.unimpersonate}
                            />
                        </EqualColumn>
                    </EqualColumnsLayout>
                    {modal}
                </div>
            );
        } else {
            return (
                <div className="ef5-impersonate">
                    <span className="ef5-impersonate__status">
                        Logged in as:
                        <br />
                        {this.props.userFirstName} ({this.props.userOrganizationName})
                    </span>
                    <EqualColumnsLayout>
                        <EqualColumn>
                            <SecondaryButton
                                className="ef5-impersonate__control"
                                text="Impersonate"
                                onClick={this.handleOpen}
                            />
                        </EqualColumn>
                    </EqualColumnsLayout>
                    {modal}
                </div>
            );
        }
    }
}

class ImpersonationModal extends React.Component {
    state = {
        filter: '',
    };

    handleClose = e => {
        this.setState({ filter: '' }, () => {
            this.props.handleClose(e);
        });
    };

    onFilterInput = value => {
        this.setState({ filter: value });
    };

    render() {
        var self = this;

        var contents;

        if (this.props.isLoading) {
            contents = <CircularProgress />;
        } else if (this.props.orgToUserMappings.length === 0) {
            contents = (
                <h2 className="ef3-impersonationModal_table_noOrgs">
                    You are not permitted to impersonate any users.
                </h2>
            );
        } else {
            const selectedOrganizationIndex = _.findIndex(this.props.orgToUserMappings, mapping => {
                return mapping.id === self.props.selectedOrganization;
            });

            const selectedOrganization = this.props.orgToUserMappings[selectedOrganizationIndex];

            const currentUserOrgIndex = _.findIndex(this.props.orgToUserMappings, org => {
                return _.some(org.users, user => {
                    return !!user.isCurrentUser;
                });
            });

            const organizationList = this.props.orgToUserMappings.map(org => ({
                label: org.name,
                value: org.id,
            }));

            /* To make selected organization first item */
            const relistOrganizationList = move(organizationList, currentUserOrgIndex, 0);

            const filteredItems = relistOrganizationList.filter(
                option => option.label.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0
            );
            contents = (
                <div className="ef5-impersonate-modal__contents">
                    <p>
                        Impersonating a user lets you see and interact with the console exactly as
                        they do.
                    </p>
                    <Spacer />
                    <StandardInput
                        value={this.state.filter}
                        onChange={this.onFilterInput}
                        placeholder="Search Organizations"
                    />
                    <Spacer />
                    <PanelsLayout direction="row" className="ef5-impersonate-modal__table">
                        <ScrollingSidebarPanel className="ef5-impersonate-modal__organizations">
                            <Navigation
                                activeItem={this.props.selectedOrganization}
                                items={filteredItems}
                                onChange={value => this.props.setOrganization(value)}
                            />
                        </ScrollingSidebarPanel>
                        <WorkspacePanel>
                            <ComposableDataTable condensed>
                                <Row header>
                                    <Cell>Name</Cell>
                                    <Cell>E-mail</Cell>
                                    <Cell>Role</Cell>
                                    <Cell />
                                </Row>
                                {selectedOrganization.users.map(user => (
                                    <Row
                                        className="ef5-impersonate-modal__row"
                                        key={user.id}
                                        selectable={true}
                                        activeRow={
                                            user.id === this.props.currentUserId ? true : false
                                        }
                                        onClick={e => {
                                            if (this.props.isImpersonating && user.isCurrentUser) {
                                                this.props.unimpersonate();
                                            } else if (
                                                !(user.isCurrentUser || user.isImpersonator)
                                            ) {
                                                this.props.impersonate(user.id);
                                            } else {
                                                this.handleClose(e);
                                            }
                                        }}
                                    >
                                        <Cell>{user.name}</Cell>
                                        <Cell>{user.email}</Cell>
                                        <Cell>{rolePresentationDictionary[user.role]}</Cell>
                                        <Cell>
                                            {user.isCurrentUser || user.isImpersonator ? (
                                                <NeutralButton
                                                    text={
                                                        user.isCurrentUser ? 'Me' : 'Impersonating'
                                                    }
                                                    onClick={e => {
                                                        if (
                                                            this.props.isImpersonating &&
                                                            user.isCurrentUser
                                                        ) {
                                                            this.props.unimpersonate();
                                                        } else {
                                                            this.handleClose(e);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <div className="ef5-impersonate-modal__impersonate-button">
                                                    <PrimaryButton
                                                        text="Impersonate"
                                                        onClick={() => {
                                                            this.props.impersonate(user.id);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Cell>
                                    </Row>
                                ))}
                            </ComposableDataTable>
                        </WorkspacePanel>
                    </PanelsLayout>
                </div>
            );
        }

        return (
            <GlobalModal
                title="Impersonation"
                className="ef5-impersonate-modal"
                isOpen={this.props.isModalOpen}
                onClose={this.handleClose}
                bottomBar={<NeutralButton text="Close" onClick={this.handleClose} />}
            >
                {contents}
            </GlobalModal>
        );
    }
}

function move(array, old_index, new_index) {
    if (new_index >= array.length) {
        var k = new_index - array.length;
        while (k-- + 1) {
            array.push(undefined);
        }
    }
    array.splice(new_index, 0, array.splice(old_index, 1)[0]);
    return array;
}

export default connect(select)(Impersonate);

import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import selector from './selector';
import CheckboxMenuList from 'widgets/checkbox-menu-list';
import FormField from 'widgets-v5/form-field';
import Profile from 'states/profile';

import Overlay from 'widgets-v5/overlay';
import Geotargets from 'forms/ad-form/modules/geotargets';

import AudienceSegmentModal from 'containers/audience-segment-picker/modules/audience-segment-modal';
import { TargetGenders } from 'forms/ad-form/modules/checkbox-groups';
import { DeviceOS } from 'forms/ad-form/modules/targeting';
import TargetCarrieries from 'forms/ad-form/modules/target-carrieries';
import TargetAge from 'forms/ad-form/modules/target-age';
import { TargetDeviceLanguage } from 'forms/ad-form/modules/target-device-language';
import TargetAppStoreCategories from 'forms/ad-form/modules/target-app-store-categories';
import PreFixSuffixInput from 'widgets-v6/prefix-suffix-input';
import { TextField } from 'widgets-v6/standard-input';

import { Poi } from './modules/poi';
import Geoboxes from 'forms/ad-form/modules/geo-boxes';

import { newAdPresetForm } from 'pages/ad-preset/ad-preset-new/actions';
import { editAdPresetForm } from 'pages/ad-preset/ad-preset-edit/actions';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import {
    calculateAudienceFee,
    hasAnyAudiences,
} from 'states/resources/ads/business-logic';

const useStyles = makeStyles(() => ({
    grid_right: {
        paddingLeft: '10px',
    },
    paper_root: {
        maxHeight: '650px',
        overflow: 'auto',
    },
    loading_root: {
        width: '100%',
        height: '500px',
    },
    formField: {
        paddingBottom: '30px',
    },
    grid_foot: {
        paddingTop: '20px',
    },
    widget: {
        padding: 10,
    },
    closeIcon: {
        marginRight: 5,
    },
}));

function AdPresets({
    location,
    handleClose,
    dispatch,
    defaultValues,
    EN_1421_adPresetId,
    showErrors,
    errors,
    formData,
    profileToken,
    isLoading,
    geoResources,
    organizationId,
    isPoiRecategorized,
}) {
    const classes = useStyles();

    useEffect(() => {
        const campaignId =
            location && location.query && location.query.campaign ? location.query.campaign : '';
        if (EN_1421_adPresetId === 'new') {
            dispatch(newAdPresetForm.open(campaignId));
        } else {
            dispatch(editAdPresetForm.open(EN_1421_adPresetId));
        }
    }, [1]);

    const onFieldChange = fieldName => {
        return fieldValue => {
            if (fieldName === 'ad_preset_name') {
                fieldValue = fieldValue.target.value;
            }

            const newFormData = {
                [fieldName]: fieldValue,
            };
            if (EN_1421_adPresetId === 'new') {
                dispatch(newAdPresetForm.updateDraft(newFormData));
            } else {
                dispatch(editAdPresetForm.updateDraft(newFormData));
            }
        };
    };

    const cancelAdPreset = () => {
        if (EN_1421_adPresetId === 'new') {
            dispatch(newAdPresetForm.cancelDraft(location.query));
        } else {
            dispatch(editAdPresetForm.cancelDraft());
        }
        handleClose();
    };

    const submitAdPreset = async () => {
        if (EN_1421_adPresetId === 'new') {
            await dispatch(newAdPresetForm.submitDraft(formData, location.query));
        } else {
            await dispatch(editAdPresetForm.submitDraft(formData, EN_1421_adPresetId));
        }

        if (Object.keys(errors).length < 1) {
            handleClose();
        }
    };

    const onMenuCheckboxChange = (key, value) => {
        // if checkbox will be unchecked, assign draft default value.
        const newFormData = {
            [key]: value ? undefined : defaultValues[key],
        };

        if (EN_1421_adPresetId === 'new') {
            dispatch(newAdPresetForm.updateDraft(newFormData));
        } else {
            dispatch(editAdPresetForm.updateDraft(newFormData));
        }
    };

    const widgets = [
        {
            key: 'geotargets',
            value: (
                <Geotargets
                    value={formData.geotargets}
                    errors={errors.geotargets}
                    showErrors={showErrors}
                    profileToken={profileToken}
                    onChange={onFieldChange('geotargets')}
                />
            ),
        },
        {
            key: 'geoboxes',
            value: (
                <Geoboxes
                    ref={useRef('geoboxes')} //"geoboxes"
                    value={formData.geoboxes}
                    errors={errors.geoboxes}
                    showErrors={showErrors}
                    onChange={onFieldChange('geoboxes')}
                />
            ),
        },
        {
            key: 'target_device_os',
            value: (
                <DeviceOS
                    formData={formData}
                    onFieldChange={onFieldChange('target_device_os')}
                    isCrossPlatformCampaign={true}
                    deviceOsWarnings={{}}
                />
            ),
        },
        {
            key: 'target_carriers',
            value: (
                <TargetCarrieries
                    label={'Target Carrier'}
                    value={formData.target_carriers}
                    errors={errors.target_carriers}
                    showErrors={showErrors}
                    onChange={onFieldChange('target_carriers')}
                />
            ),
        },
        {
            key: 'target_device_language',
            value: (
                <TargetDeviceLanguage
                    label="User Language"
                    value={formData.target_device_language}
                    errors={errors.target_device_language}
                    showErrors={showErrors}
                    onChange={onFieldChange('target_device_language')}
                />
            ),
        },
        {
            key: 'target_genders',
            value: (
                <TargetGenders
                    value={formData.target_genders}
                    errors={errors.target_genders}
                    showErrors={showErrors}
                    onChange={onFieldChange('target_genders')}
                />
            ),
        },
        {
            key: 'target_age_groups',
            value: (
                <TargetAge
                    value={formData.target_age_groups}
                    errors={errors.targetAgeGroup}
                    showErrors={showErrors}
                    onChange={onFieldChange('target_age_groups')}
                />
            ),
        },
        {
            key: 'target_app_store_cat',
            value: (
                <FormField
                    label="App Categories"
                    required
                    errors={errors.target_app_store_cat}
                    showErrors={showErrors}
                >
                    <TargetAppStoreCategories
                        includeValues={formData.target_app_store_cat || []}
                        excludeValues={formData.target_app_store_cat_exclude || []}
                        onChange={({ values, exclude }) => {
                            if (exclude) {
                                onFieldChange('target_app_store_cat')([]);
                                onFieldChange('target_app_store_cat_exclude')(values);
                            } else {
                                onFieldChange('target_app_store_cat')(values);
                                onFieldChange('target_app_store_cat_exclude')([]);
                            }
                        }}
                    />
                </FormField>
            ),
        },
        {
            key: 'geo_targeting_settings',
            value: (
                <Overlay
                    disabled
                    message="POI Segments are not available when Audience Segments are selected."
                    show={hasAnyAudiences(formData)}
                >
                    <Poi
                        geo_targeting_settings={formData.geo_targeting_settings}
                        geoResources={geoResources}
                        organizationId={organizationId}
                        onChange={onFieldChange('geo_targeting_settings')}
                        isPoiRecategorized={isPoiRecategorized}
                    />
                </Overlay>
            ),
        },
        {
            key: 'audiences',
            value: (
                <Overlay
                    disabled
                    message="Audience Segments are not available when POI Segments are selected."
                    show={false}
                >
                    <div className="ef5-ad-setup__target-carriers ef5-ad-setup__form-field-flex">
                        <FormField
                            label={'Audience Segment'}
                            showErrors={showErrors}
                            errors={errors.audiences}
                        >
                            <Profile>
                                {({ ownOrganization }) => {
                                    return (
                                        <AudienceSegmentModal
                                            onChangeSegments={({
                                                includedItems,
                                                excludedItems,
                                                geo_targeting_settings,
                                            }) => {
                                                onFieldChange('audiences')(
                                                    _.map(includedItems, item => item.value)
                                                );
                                                onFieldChange('audience_exclude')(
                                                    _.map(excludedItems, item => item.value)
                                                );

                                                const audienceFee = calculateAudienceFee({
                                                    includedAudiences: includedItems,
                                                    excludedAudiences: excludedItems,
                                                    audienceRates: ownOrganization.audience_rates,
                                                });

                                                onFieldChange('audience_fee')(audienceFee);
                                                onFieldChange('geo_targeting_settings')(
                                                    geo_targeting_settings
                                                );
                                            }}
                                            audiences={_.map(formData.audiences, String)}
                                            geo_targeting_settings={_.get(
                                                formData,
                                                'geo_targeting_settings'
                                            )}
                                            audienceExclude={_.map(
                                                formData.audience_exclude,
                                                String
                                            )}
                                            audienceRates={ownOrganization.audience_rates}
                                        />
                                    );
                                }}
                            </Profile>
                        </FormField>
                    </div>
                </Overlay>
            ),
        },
        {
            key: 'max_cpm_rate_local',
            value: (
                <FormField
                    label={'Total Bid Price'}
                    description="Some fees may be deducted from your Total Bid Price"
                    errors={errors.max_cpm_rate_local}
                    showErrors={showErrors}
                >
                    <PreFixSuffixInput
                        prefix={`$`}
                        suffix="CPM"
                        formatIn={formatSpendValueToString}
                        formatOut={formatSpendStringToValue}
                        shouldAllowInput={shouldAllowInputForSpend}
                        onChange={onFieldChange('max_cpm_rate_local')}
                        value={formData.max_cpm_rate_local}
                    />
                </FormField>
            ),
        },
        {
            key: 'max_user_frequency',
            value: (
                <FormField
                    label="Daily Frequency"
                    description=""
                    errors={errors.max_user_frequency}
                    showErrors={showErrors}
                    tooltip={{
                        position: 'right',
                        tooltipTitle: 'Daily Frequency Cap',
                        tooltip: `Maximum number of times a specific user will be targeted by this ad within a 24-hour period. Frequency counts are reset daily at 00:00 UTC.`,
                    }}
                >
                    <PreFixSuffixInput
                        placeholder="Unlimited"
                        formatIn={valueToString__max_user_frequency}
                        formatOut={stringToValue__max_user_frequency}
                        shouldAllowInput={shouldAllowInput__max_user_frequency}
                        onChange={onFieldChange('max_user_frequency')}
                        value={formData.max_user_frequency}
                    />
                </FormField>
            ),
        },
    ];

    var isAnyWidgetSelected = false;
    const adPresetPropertiesVisibilities = {};
    _.forEach(formData.ad_preset_properties, group => {
        _.forEach(group.properties, property => {
            adPresetPropertiesVisibilities[property.key] = property.checked;
            isAnyWidgetSelected = isAnyWidgetSelected || property.checked;
        });
    });

    if (isLoading) {
        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                classes={{ root: classes.loading_root }}
            >
                <Grid item>
                    <CircularProgress isloading="true" />
                </Grid>
            </Grid>
        );
    }

    let availableWidgets = widgets;
    let availableProperties = formData.ad_preset_properties;

    availableWidgets = widgets.filter(widget => widget.key !== 'geo_targeting_settings');
    availableProperties = _.map(formData.ad_preset_properties, group => {
        return {
            ...group,
            properties: _.filter(
                group.properties,
                property => property.key !== 'geo_targeting_settings'
            ),
        };
    });

    return (
        <React.Fragment>
            <Grid container justifyContent="space-between" alignItems="center">
                <DialogTitle>Ad Presets</DialogTitle>
                <div className={classes.closeIcon}>
                    <IconButton onClick={cancelAdPreset} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </Grid>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={3}>
                        <TextField
                            label="Name"
                            onChange={onFieldChange('ad_preset_name')}
                            value={formData.ad_preset_name}
                            error={showErrors && errors.ad_preset_name}
                            fullWidth
                            required
                        />
                        <CheckboxMenuList
                            values={availableProperties}
                            onMenuCheckboxChange={onMenuCheckboxChange}
                            onChange={onFieldChange('ad_preset_properties')}
                        />
                    </Grid>
                    <Grid item xs={9} classes={{ root: classes.grid_right }}>
                        <div className={classes.paper_root}>
                            {isAnyWidgetSelected ? (
                                _(availableWidgets)
                                    .filter(widget => {
                                        return adPresetPropertiesVisibilities[widget.key];
                                    })
                                    .map((widget, ind) => {
                                        return (
                                            <div key={'widget-' + ind} className={classes.widget}>
                                                {widget.value}
                                            </div>
                                        );
                                    })
                                    .value()
                            ) : (
                                <FormField
                                    errors={errors.ad_preset_properties}
                                    showErrors={showErrors}
                                >
                                    <div>Select a preset option to begin.</div>
                                </FormField>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={submitAdPreset} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </React.Fragment>
    );

    // max_user_frequency
    function shouldAllowInput__max_user_frequency(input) {
        const isEmpty = input === '';
        const isUnsignNumber = /^\d+$/.test(input);
        const isNotUnsignInteger = !/(^\d+\.\d*$)/.test(input);
        const shouldAllow = isEmpty || (isUnsignNumber && isNotUnsignInteger);
        return shouldAllow;
    }

    function valueToString__max_user_frequency(value) {
        const exists = value !== undefined && value !== null;

        if (value === 0) {
            return '';
        } else if (exists) {
            return value.toString();
        } else {
            return '';
        }
    }

    function stringToValue__max_user_frequency(string) {
        const isDigit = /^\d+$/.test(string);

        if (isDigit) {
            return +string;
        } else if (string === '') {
            return 0;
        } else {
            return string;
        }
    }

    function shouldAllowInputForSpend(input) {
        // Allow empty input
        if (!input) {
            return true;
        }
        // Check allowable characters
        if (!/^[\d,]*(\.\d{0,2})?$/g.test(input)) {
            return false;
        }
        // Check character length
        const [dollars, cents] = input.split('.');
        const numDollarsDigits = dollars.match(/\d/g).length;
        const numCentsDigits = cents ? cents.length : 0;
        return numDollarsDigits <= 9 && numCentsDigits <= 2;
    }

    function formatSpendValueToString(value) {
        if (!value) {
            return '';
        } else {
            const [dollars, cents] = value.toFixed(2).split('.');
            const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
            return `${formattedDollars}.${cents}`;
        }
    }

    function formatSpendStringToValue(string) {
        if (string === '') {
            return 0;
        } else {
            return parseFloat(string.replace(/,/g, ''));
        }
    }
}

export default connect(selector)(AdPresets);

import _ from 'lodash';

const selector = (storeState, ownProps) => {
    const ad = _.get(storeState, `adSummary.ad`);

    const campaign = _.get(storeState, `resources.campaigns.${ownProps.campaignId}.attr`);
    const creativeIdsForCampaign = _.get(
        storeState,
        `resources.campaigns.${ownProps.campaignId}.attr.creatives`
    );
    const creativesByCampaign = _.pick(storeState.resources.creatives, creativeIdsForCampaign);

    const campaignBillingEnabled = _.get(
        storeState,
        `resources.campaigns.${ownProps.campaignId}.attr.billing_enabled`
    );
    const campaignBudgetEnabled = _.get(
        storeState,
        `resources.campaigns.${ownProps.campaignId}.attr.campaign_budget_enabled`
    );

    const organizationId = _.get(storeState, `profile.organizationId`);
    const ubxAudienceResources = _.get(storeState, `resources.ubxAudiences`, {});
    const audienceResources = _.get(storeState, `resources.audiences`, {});

    const allGeoCategories = _.get(storeState, 'resources.geoCategories');
    const geoTargetingSubCategories = _(allGeoCategories.geoCategories)
        .map(category => category.subcategories)
        .flatten()
        .value();

    const poiCategories = _.filter(
        geoTargetingSubCategories,
        subCategory =>
            _.get(ad, 'geo_targeting_settings.categories', '').indexOf(subCategory.id) !== -1
    );

    const poiSegments = _.filter(
        allGeoCategories.geoLayers,
        geoLayer =>
            _.get(ad, 'geo_targeting_settings.category_layers', '').indexOf(geoLayer.id) !== -1
    );

    return {
        adId: ownProps.adId,
        campaignId: ownProps.campaignId,
        formData: ad,
        creativesByCampaign,
        campaignBillingEnabled,
        campaignBudgetEnabled,
        ubxAudienceResources,
        audienceResources,
        campaign,
        poiCategories,
        poiSegments,
        geoTargetingSubCategories,
        geoLayers: allGeoCategories.geoLayers,
        organizationId,
    };
};

export default selector;

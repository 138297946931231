import _ from 'lodash';

import { getIASPublicisGroupMWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

export default function selector(storeState) {
    const iasViewability = _.get(storeState, 'adForm.form.draft.ias.viewability.value');
    const platforms = _.get(storeState, 'adForm.form.draft.platforms');
    const value = _.get(storeState, 'adForm.form.draft.ias.viewability.value');
    const selectedCreatives = _.get(storeState, 'adForm.form.draft.creative');
    const creatives = _.get(storeState, 'adForm.form.creatives');
    const rotationRules = _.get(storeState, 'adForm.form.draft.rotation_rules');
    const creativeFormats = getCreativeFormat(selectedCreatives, creatives, rotationRules);

    const warningData = getIASPublicisGroupMWarning({
        viewabilityValue: value,
        platforms,
        field: 'viewability',
        creativeFormats,
    });

    return {
        iasViewability,
        warningData,
    };
}

export function getCreativeFormat(creativeIds, creatives, rotationRules) {
    let ids = creativeIds;
    const creativeFormats = [];

    if (ids.length < 1) {
        if (rotationRules.mode === CreativeRotationMethodMapping.Scheduled) {
            const allScheduled = _.flatten(
                _.map(rotationRules.scheduled, setting => setting.weighted)
            );
            ids = _.map(allScheduled, setting => {
                return setting['markup_id'];
            });
        } else if (rotationRules.mode === CreativeRotationMethodMapping.Weather) {
            ids = _.map(rotationRules.weather, setting => setting.markupId);
        }
    }

    _.forEach(creatives, creative => {
        if (_.includes(ids, creative.id)) {
            creativeFormats.push(creative.format);
        }
    });

    return creativeFormats;
}

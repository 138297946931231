export const IAS_VIEWABILITY_OPTIONS = [
    {
        label: 'Off',
        value: 0,
    },
    {
        label: 'Target up to 40% viewability',
        value: 1,
    },
    {
        label: 'Target 40% viewability or higher',
        value: 2,
    },
    {
        label: 'Target 50% viewability or higher',
        value: 3,
    },
    {
        label: 'Target 60% viewability or higher',
        value: 4,
    },
    {
        label: 'Target 70% viewability or higher',
        value: 5,
    },
];

export const IAS_VIEWABILITY_MAPPING = {
    0: 'Off',
    1: 'Target up to 40% viewability',
    2: 'Target 40% viewability or higher',
    3: 'Target 50% viewability or higher',
    4: 'Target 60% viewability or higher',
    5: 'Target 70% viewability or higher',
};

export const IAS_VIEWABILITY_MAPPING_VALUES = {
    OFF: 0,
    'Target up to 40% viewability': 1,
    'Target 40% viewability or higher': 2,
    'Target 50% viewability or higher': 3,
    'Target 60% viewability or higher': 4,
    'Target 70% viewability or higher': 5,
};

import _ from 'lodash';
import React from 'react';
import moment from 'moment-timezone';

import HalfDateTimeRangePicker from 'widgets/half-date-time-range-picker';

export default class EndDateSelector extends React.Component {
    state = {
        showCalendar: false,
        id: _.uniqueId(),
    };

    componentDidMount() {
        document.body.addEventListener('click', this.hideCalendar);
        this.refs.root.addEventListener('click', this.cancelHideCalendar);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.hideCalendar);
        this.refs.root.removeEventListener('click', this.cancelHideCalendar);
    }

    hideCalendar = e => {
        // Exit when click originates from within the DateTimeRangePicker
        if (e.endDateSelectorClick && e.endDateId === this.state.id) {
            return;
        }

        // Exit when Calendar is already closed to prevent unecessary renders
        if (this.state.showCalendar === false) {
            return;
        }

        this.setState({ showCalendar: false });
    };

    cancelHideCalendar = e => {
        e.endDateSelectorClick = true;
        e.endDateId = this.state.id;
    };

    toggleCalendar = () => {
        this.setState({
            showCalendar: !this.state.showCalendar,
        });
    };

    render() {
        const {
            rowStart,
            rowEnd,
            lastSelectableDate,

            onChange,
            isFinalRow,

            timezone,
            isDisabled,
            error,
        } = this.props;

        const displayText = this.props.rowEnd
            ? moment(rowEnd)
                  .tz(timezone)
                  .format('MMM DD, YYYY HH:mm z')
            : 'Select end of date range';

        return (
            <div
                className="ef3-creativeRotationSelector_scheduledRowEndDisplayContainer"
                ref="root"
            >
                <HalfDateTimeRangePicker
                    className="ef3-creativeRotationSelector_scheduledRowEndPicker"
                    timezone={timezone}
                    onChange={onChange}
                    lastSelectableDate={lastSelectableDate}
                    value={{
                        start: rowStart || new Date(Date.UTC(2017, 0, 1)).toISOString(),
                        end: rowEnd,
                    }}
                    visibleHalf={'end'}
                    resetable={false}
                    disabled={isFinalRow || isDisabled}
                    error={error}
                    displayText={displayText}
                />
            </div>
        );
    }
}

import React from 'react';
import FiberNew from '@mui/icons-material/FiberNew';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

export default function Notifications() {
    const notificationContent = (
        <Tooltip title="Updates" placement="right">
            <FiberNew />
        </Tooltip>
    );

    return (
        <div>
            <ListItem button id="notification-button">
                <ListItemIcon>{notificationContent}</ListItemIcon>
                <ListItemText primary="Updates" />
            </ListItem>
        </div>
    );
}

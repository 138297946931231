import _ from 'lodash';
import http from 'utils/http';

const Campaigns = {
    getAll(params = {}) {
        return (dispatch, getState) => {
            let buffer = [];

            const include = [
                '_id',
                'campaign',
                'mayContainBadAudienceReportData',
                'default_timezone',
                'campaign_category',
                'clients',
                'beacons.name',
                'beacons.label',
                'custom_fields',
                'conversions.reporting_name',
                'conversions.event_name',
                'conversions.use_view_through',
                'conversions.use_click_through',
                'advertiserConversions.reporting_name',
                'advertiserConversions.event_name',
                'advertiserConversions.use_view_through',
                'advertiserConversions.use_click_through',
                'ads._id',
                'ads.desc',
                'ads._deleted',
                'ads.start',
                'ads.end',
                'ads.custom_fields',
            ].join(',');

            return http()
                .get('report-campaigns', { include })
                .then(
                    reportCampaigns => {
                        const campaigns = addDerivedFields(reportCampaigns);
                        dispatch({
                            type: 'SYSTEM__REPORT_CAMPAIGNS__FETCH__SUCCESS',
                            payload: {
                                campaigns,
                            },
                        });
                    },
                    err => {
                        console.error(`GET ALL report-campaigns error`, err);

                        throw err;
                    }
                );
        };
    },
};

function addDerivedFields(reportCampaigns) {
    return _.map(reportCampaigns, doc => {
        const minStart = _(doc.ads)
            .filter(ad => !ad._deleted)
            .map(ad => ad.start)
            .min();

        const maxEnd = _(doc.ads)
            .filter(ad => !ad._deleted)
            .map(ad => ad.end)
            .max();

        const { campaign, _id, ads, default_timezone, campaign_category, ...rest } = doc;

        // remap campaign
        return {
            ...rest,
            id: _id,
            name: campaign,
            timezone: default_timezone,
            iab_categories: campaign_category,
            start: minStart,
            end: maxEnd,
            // remap ads
            ads: _.map(ads, ad => {
                const { _id, desc, ...rest } = ad;

                return {
                    ...rest,
                    id: _id,
                    name: desc,
                };
            }),
        };
    });
}
export default Campaigns;

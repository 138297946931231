import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import { ButtonGroup } from 'widgets-v5/buttons';
import { Title } from 'widgets-v5/typography';

export default class extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        footer: PropTypes.node,
        padding: PropTypes.number,
        margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    static defaultProps = {
        onClick() {},
        margin: 16,
        autoHeight: false,
        expand: false,
    };

    state = {
        collapsed: false,
    };

    handleCollapseExpandButton = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <div
                style={{ margin: this.props.margin }}
                className={classnames('ef4-card', this.props.className, {
                    'ef4-card__auto-height': this.props.autoHeight,
                })}
                onClick={this.props.onClick}
            >
                {(this.props.title || this.props.subtitle || this.props.tabs) && (
                    <div className="ef4-card__subheader">
                        {this.props.title && (
                            <div className="ef4-card__title-wrapper">
                                <h1 className="ef4-card__title">{this.props.title}</h1>
                                {this.props.expand && (
                                    <i
                                        onClick={this.handleCollapseExpandButton}
                                        className={classnames(
                                            'fa ef4-card__collapse-expand-button fa-chevron-down',
                                            {
                                                'ef4-card__collapse-expand-button--is-active': !this
                                                    .state.collapsed,
                                            }
                                        )}
                                    />
                                )}
                            </div>
                        )}
                        {this.props.subtitle && (
                            <h2 className="ef4-card__subtitle">{this.props.subtitle}</h2>
                        )}
                    </div>
                )}

                <div
                    className={classnames('ef4-card__workspace', {
                        'ef4-card__workspace--is-collapsed': this.state.collapsed,
                    })}
                >
                    <div
                        className={classnames('ef4-card__body', {
                            'ef4-card__body--no-padding': this.props.padding === 0,
                        })}
                    >
                        {this.props.children}
                    </div>
                </div>
                {this.props.footer && <div className="ef4-card__footer">{this.props.footer}</div>}
            </div>
        );
    }
}

export function VerticalCardGroup(props) {
    return (
        <div className={classnames('ef4-vertical-card-group', props.className)}>
            {props.children}
        </div>
    );
}

export function HorizontalCardGroup(props) {
    return (
        <div className={classnames('ef4-horizontal-card-group', props.className)}>
            <div className="ef4-horizontal-card-group__wrapper">{props.children}</div>
        </div>
    );
}

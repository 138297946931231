import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import _ from 'lodash';
import classnames from 'classnames';

import CatchAll from 'error-boundaries/catch-all';

import Card from 'widgets-v5/card';
import CenterLayout from 'widgets-v5/center-layout';
import TopBottomBar from 'widgets-v5/top-bottom-bar-layout';

const modalPropTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    bottomBar: PropTypes.node,
    isFullscreen: PropTypes.bool,
};

export class LocalModal extends React.Component {
    static propTypes = modalPropTypes;

    stopPropagation = e => {
        e.stopPropagation();
    };

    handleBackgroundClick = () => {
        if (this.props.closeOnBackgroundClick === false) {
            return;
        }

        this.props.onClose();
    };

    static defaultProps = {
        overflow: false,
    };

    render() {
        if (!this.props.isOpen) {
            return null;
        }

        const children = _.isFunction(this.props.children)
            ? this.props.children()
            : this.props.children;

        return (
            <CenterLayout
                className={classnames(this.props.className, 'ef5-modal', {
                    'ef5-modal_is-closed': !this.props.isOpen,
                })}
                onClick={this.handleBackgroundClick}
            >
                <Card
                    margin={0}
                    padding={0}
                    className={classnames('ef5-modal__card', {
                        'ef5-modal__card--is-fullscreen': this.props.isFullscreen,
                    })}
                    onClick={this.stopPropagation}
                >
                    <TopBottomBar
                        overflow={this.props.overflow}
                        className={classnames('ef5-modal__contents', {
                            'ef5-modal__contents--is-fullscreen': this.props.isFullscreen,
                            'ef5-modal__contents-overflow': this.props.overflow,
                        })}
                        topBar={
                            this.props.title && (
                                <h2 className="ef5-modal__title">{this.props.title}</h2>
                            )
                        }
                        workspace={
                            this.props.children && (
                                <div
                                    className={classnames('ef5-modal__workspace', {
                                        'ef5-modal__workspace_has-title': !!this.props.title,
                                    })}
                                >
                                    {children}
                                </div>
                            )
                        }
                        bottomBar={
                            this.props.bottomBar ? (
                                <ModalFooter>{this.props.bottomBar}</ModalFooter>
                            ) : null
                        }
                    />
                </Card>
            </CenterLayout>
        );
    }
}

export function ModalFooter(props) {
    return <footer className="ef5-modal__bottom-bar">{props.children}</footer>;
}

function mockReducer(state = {}) {
    return state;
}

export const GlobalModal = createReactClass({
    displayName: 'GlobalModal',
    propTypes: modalPropTypes,

    componentDidMount() {
        // Always render modal in an appended container to prevent
        // overwriting other global modals that may have been mounted
        this.target = document.createElement('div');
        this.container = createRoot(this.target);
        document.getElementById('modal-root').appendChild(this.target);
        this.store = createStore(mockReducer);
        this.componentDidUpdate();
    },

    componentDidUpdate() {
        this.container.render(
            <CatchAll>
                <Provider store={this.context.store || this.store}>
                    <LocalModal {...this.props}>{this.props.children}</LocalModal>
                </Provider>
            </CatchAll>
        );
    },

    componentWillUnmount() {
        this.container.unmount();
        document.getElementById('modal-root').removeChild(this.target);
    },

    render() {
        return <div />;
    },
});

GlobalModal.contextTypes = {
    store: PropTypes.object,
};

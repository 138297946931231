import React from 'react';
import numeral from 'numeral';
import BaseColumnForm from '../base-column-form';

import { formatNumber_currency } from 'utils/formatting';

import shallowCompare from 'utils/shallow-compare';

import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';

import { TextField } from 'widgets-v6/standard-input';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import {
    processBidStrategyValue,
    processOptimizingStrategyMode,
    processOptimizingStrategyMaxEcpcLocal,
    processOptimizingStrategyMaxEcpcvLocal,
    processMaxEcpm,
} from 'forms/ad-form/modules/bid-strategy';

import { getEnvironmentSettings } from 'services/environment';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import { BiddingStrategyMode } from 'states/resources/ads/business-logic';

export class BidStrategyForm extends React.Component {
    updateBidStrategy = ({ value, updateDraft }) => {
        const newAutomaticBidPrice = processBidStrategyValue({ value });
        const updates = { bid_strategy_mode: value, automatic_bid_price: newAutomaticBidPrice };
        if (value === 'automatic_bid_price') {
            updates.max_cpm_rate_local = 0;
        }
        updateDraft(updates);
    };
    updateOptimizingStrategyMode = ({ draft, value, updateDraft }) => {
        const newAutomaticBidPrice = processOptimizingStrategyMode({ value, draft });
        updateDraft({ automatic_bid_price: newAutomaticBidPrice });
    };

    updateOptimizingStrategyMaxEcpcLocal = ({ value, updateDraft, draft }) => {
        const newAutomaticBidPrice = processOptimizingStrategyMaxEcpcLocal({ value, draft });
        updateDraft({ automatic_bid_price: newAutomaticBidPrice });
    };

    updateOptimizingStrategyMaxEcpcvLocal = ({ value, updateDraft, draft }) => {
        const newAutomaticBidPrice = processOptimizingStrategyMaxEcpcvLocal({ value, draft });
        updateDraft({ automatic_bid_price: newAutomaticBidPrice });
    };

    updateMaxCpmRateLocal = ({ value, updateDraft }) => {
        updateDraft({ max_cpm_rate_local: value });
    };
    updateMaxEcpm = ({ value, updateDraft, draft }) => {
        const newAutomaticBidPrice = processMaxEcpm({ value, draft });
        updateDraft({ automatic_bid_price: newAutomaticBidPrice });
    };
    render() {
        const { ad, ads, campaign, onSave } = this.props;
        const environmentSettings = getEnvironmentSettings();

        const currencyLabel = environmentSettings.showCurrencyLabel() ? campaign.currency : '';
        const isCTVCampaign = campaign.type === CampaignTypeMapping.CTV;
        const isDOOHCampaign = campaign.type === CampaignTypeMapping.DOOH;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Bid Strategy"
                fields={[
                    'bid_strategy_mode',
                    'automatic_bid_price',
                    'max_cpm_rate_local',
                    'fta_fee',
                    'audience_fee',
                ]}
                shouldRefreshPredictionsOnSave={true}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <React.Fragment>
                        {environmentSettings.canEditBidStrategy() && (
                            <Box mb={2}>
                                <RadioGroup
                                    aria-label="gender"
                                    name="bid_strategy_mode"
                                    value={draft.bid_strategy_mode}
                                    onChange={e =>
                                        this.updateBidStrategy({
                                            value: e.target.value,
                                            updateDraft,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value="automatic_bid_price"
                                        control={<Radio color="primary" />}
                                        label="Automated bid price"
                                        disabled={isDOOHCampaign}
                                    />
                                    <FormControlLabel
                                        value="fixed_bid_price"
                                        control={<Radio color="primary" />}
                                        label="Fixed bid price"
                                        disabled={isDOOHCampaign}
                                    />
                                </RadioGroup>
                                {isDOOHCampaign &&
                                    <FormHelperText>
                                        DOOH ads only support fixed bid price.
                                    </FormHelperText>
                                }
                            </Box>
                        )}
                        {draft.bid_strategy_mode === 'automatic_bid_price' && (
                            <Box mb={2}>
                                <Box mb={1}>
                                    <FormLabel component="legend">Bid Optimization </FormLabel>
                                </Box>
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={draft.automatic_bid_price.optimizing_strategy.mode}
                                    onChange={event =>
                                        this.updateOptimizingStrategyMode({
                                            value: event.target.value,
                                            draft,
                                            updateDraft,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        value={BiddingStrategyMode.SpendFullBudget}
                                        label="Optimize for spending full budget"
                                    />
                                    <Box ml={3.6}>
                                        <Typography variant="body2" gutterBottom>
                                            Spend full budget while maximizing {isCTVCampaign ? 'VCR' : 'CTR'}
                                        </Typography>
                                    </Box>
                                    {!isCTVCampaign && (
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <FormControlLabel
                                                    value={BiddingStrategyMode.Performance}
                                                    control={<Radio color="primary" />}
                                                    label="Optimize for performance"
                                                />
                                                <Box ml={3.6}>
                                                    <Typography variant="body2" gutterBottom>
                                                        Budget may not be fully spent if goal is too
                                                        aggressive
                                                    </Typography>
                                                    {draft.automatic_bid_price.optimizing_strategy
                                                        .mode === BiddingStrategyMode.Performance && (
                                                        <BidStrategyField
                                                            initialValue={isCTVCampaign ?
                                                                draft.automatic_bid_price.optimizing_strategy.max_ecpcv_local :
                                                                draft.automatic_bid_price.optimizing_strategy.max_ecpc_local}
                                                            onUpdate={value => {
                                                                if (!isCTVCampaign) {
                                                                    this.updateOptimizingStrategyMaxEcpcLocal(
                                                                        {
                                                                            value,
                                                                            updateDraft,
                                                                            draft,
                                                                        }
                                                                    )
                                                                } else {
                                                                    this.updateOptimizingStrategyMaxEcpcvLocal(
                                                                        {
                                                                            value,
                                                                            updateDraft,
                                                                            draft,
                                                                        }
                                                                    )
                                                                }
                                                            }}
                                                            shouldShowErrors={shouldShowErrors}
                                                            errors={errors}
                                                            fieldName={isCTVCampaign ? 'eCPCV' : 'eCPC'}
                                                            currency={campaign.currency}
                                                        />
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </RadioGroup>
                            </Box>
                        )}
                        <Box mb={1}>
                            <FormLabel
                                component="legend"
                                required
                                error={
                                    shouldShowErrors && errors['automatic_bid_price.max_ecpm_local']
                                }
                            >
                                {draft.bid_strategy_mode === 'fixed_bid_price'
                                    ? 'Total Bid Price'
                                    : 'Total Bid Price Limit'}
                            </FormLabel>
                        </Box>
                        {draft.bid_strategy_mode === 'automatic_bid_price' ? (
                            <React.Fragment>
                                <Typography gutterBottom>
                                    Do not exceed total bid price of
                                </Typography>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <TextFieldFormatter
                                                    key="automatic_bid_price.max_ecpm_local"
                                                    initialValue={
                                                        draft.automatic_bid_price.max_ecpm_local
                                                    }
                                                    onChange={value =>
                                                        this.updateMaxEcpm({
                                                            value,
                                                            updateDraft,
                                                            draft,
                                                        })
                                                    }
                                                    shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                                    formatIn={v => {
                                                        if (v === 0) {
                                                            return '';
                                                        } else {
                                                            return numeral(v).format('0,0.00');
                                                        }
                                                    }}
                                                    formatOut={v => numeral(v).value()}
                                                    renderInput={({ value, onChange, onBlur }) => (
                                                        <TextField
                                                            disableMargin
                                                            error={
                                                                shouldShowErrors &&
                                                                errors[
                                                                    'automatic_bid_price.max_ecpm_local'
                                                                ]
                                                            }
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            startAdornment={
                                                                <Box whiteSpace="nowrap">
                                                                    {currencyLabel}{' '}
                                                                    {environmentSettings.getCurrencySign()}{' '}
                                                                </Box>
                                                            }
                                                            required={
                                                                draft.bid_strategy_mode ===
                                                                'automatic_bid_price'
                                                            }
                                                            endAdornment="CPM"
                                                            placeholder="0.00"
                                                            helperText="Some fees may be deducted from your Total Bid Price."
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {shouldShowErrors &&
                                                errors['automatic_bid_price.max_ecpm_local'] && (
                                                    <Grid item>
                                                        <FormControl error>
                                                            <FormHelperText>
                                                                {
                                                                    errors[
                                                                        'automatic_bid_price.max_ecpm_local'
                                                                    ]
                                                                }
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid item>
                                    <TextFieldFormatter
                                        initialValue={draft.max_cpm_rate_local}
                                        onChange={value =>
                                            this.updateMaxCpmRateLocal({ updateDraft, value })
                                        }
                                        shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                        formatIn={v => {
                                            if (v === 0) {
                                                return '';
                                            } else {
                                                return numeral(v).format('0,0.00');
                                            }
                                        }}
                                        formatOut={v => numeral(v).value()}
                                        renderInput={({ value, onChange, onBlur }) => (
                                            <TextField
                                                disableMargin
                                                error={
                                                    shouldShowErrors &&
                                                    errors['fixed_bid_price.max_cpm_rate_local']
                                                }
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                startAdornment={
                                                    <Box whiteSpace="nowrap">
                                                        {currencyLabel}{' '}
                                                        {environmentSettings.getCurrencySign()}{' '}
                                                    </Box>
                                                }
                                                required
                                                endAdornment="CPM"
                                                placeholder="0.00"
                                                helperText="Some fees may be deducted from your Total Bid Price."
                                            />
                                        )}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </BaseColumnForm>
        );
    }
}

const BidStrategyField = ({
    initialValue,
    onUpdate,
    shouldShowErrors,
    errors,
    fieldName,
    currency,
}) => {
    const environmentSettings = getEnvironmentSettings();
    const currencyLabel = environmentSettings.showCurrencyLabel() ? currency : '';

    return (
        <Grid container direction="column">
            <Grid item>
                <TextFieldFormatter
                    initialValue={initialValue}
                    onChange={onUpdate}
                    shouldAllowChange={v =>
                        !/[a-zA-Z]/.test(v)
                    }
                    formatIn={v => {
                        if (v === 0) {
                            return '';
                        } else {
                            return numeral(v).format(
                                '0,0.00'
                            );
                        }
                    }}
                    formatOut={v => numeral(v).value()}
                    renderInput={({
                        value,
                        onChange,
                        onBlur,
                    }) => (
                        <TextField
                            error={
                                shouldShowErrors &&
                                errors[
                                    'automatic_bid_price.optimizing_strategy'
                                ]
                            }
                            startAdornment={
                                <Box whiteSpace="nowrap">
                                    {currencyLabel}{' '}
                                    {environmentSettings.getCurrencySign()}{' '}
                                </Box>
                            }
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            endAdornment={
                                <Box whiteSpace="nowrap">
                                    {fieldName} or less
                                </Box>
                            }
                            placeholder="0.00"
                            disableMargin
                        />
                    )}
                />
            </Grid>
            {shouldShowErrors &&
                errors[
                    'automatic_bid_price.optimizing_strategy'
                ] && (
                    <Grid item>
                        <FormControl error>
                            <FormHelperText>
                                {
                                    errors[
                                        'automatic_bid_price.optimizing_strategy'
                                    ]
                                }
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                )}
        </Grid>
    )
}

export class BidStrategySummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, error, campaign } = this.props;

        if (error) {
            return <div>{error}</div>;
        }
        return (
            <div>
                {ad.bid_strategy_mode === 'automatic_bid_price' ? (
                    <div>
                        <Typography>Automatic Bid Price</Typography>
                        <React.Fragment>
                            {ad.automatic_bid_price.apply_max_ecpm && (
                                <Typography>{`Do not Exceed ${formatNumber_currency(
                                    ad.automatic_bid_price.max_ecpm_local
                                )} eCPM`}</Typography>
                            )}
                            {ad.automatic_bid_price.optimizing_strategy.mode ===
                                BiddingStrategyMode.SpendFullBudget && (
                                <Typography>Optimize for spending full budget</Typography>
                            )}
                            {ad.automatic_bid_price.optimizing_strategy.mode === BiddingStrategyMode.Performance &&
                             campaign.type !== CampaignTypeMapping.CTV && (
                                <Typography>
                                    {`Optimize for performance: ${formatNumber_currency(
                                        ad.automatic_bid_price.optimizing_strategy.max_ecpc_local
                                    )} eCPC or less`}
                                </Typography>
                            )}
                            {ad.automatic_bid_price.optimizing_strategy.mode === BiddingStrategyMode.Performance &&
                             campaign.type === CampaignTypeMapping.CTV && (
                                <Typography>
                                    {`Optimize for performance: ${formatNumber_currency(
                                        ad.automatic_bid_price.optimizing_strategy.max_ecpcv_local
                                    )} eCPCV or less`}
                                </Typography>
                            )}
                        </React.Fragment>
                    </div>
                ) : (
                    <div>
                        <Typography gutterBottom>Fixed Bid Price</Typography>
                        <Typography>
                            Total Bid Price: {formatNumber_currency(ad.max_cpm_rate_local)}
                        </Typography>
                    </div>
                )}
            </div>
        );
    }
}

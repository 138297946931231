import _ from 'lodash';
import React from 'react';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import { TargetGenders } from 'forms/ad-form/modules/checkbox-groups';
import { getGenders } from 'containers/ad-summary';
import shallowCompare from 'utils/shallow-compare';
import classnames from 'classnames';

export class GenderForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Gender"
                fields={['target_genders']}
                shouldRefreshPredictionsOnSave={true}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <TargetGenders
                        label={ads ? 'Gender' : ''}
                        value={draft.target_genders}
                        errors={errors.target_genders}
                        showErrors={shouldShowErrors}
                        onChange={val => updateDraft({ target_genders: val })}
                    />
                )}
            </BaseColumnForm>
        );
    }
}

export class GenderSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, error } = this.props;

        return (
            <div>
                {error ? (
                    <div>{error}</div>
                ) : (
                    <div>
                        {_.map(getGenders(ad.target_genders).split(','), gender => (
                            <div key={gender}>{gender}</div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

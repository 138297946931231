import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

var MC_OPTIONS = {
    gridSize: 20,
    zoomOnClick: true,
    averageCenter: true,
    minimumClusterSize: 6,
    imagePath: '/images/marker-clusterer/m',
};

var MAP_OPTIONS = {
    center: { lat: 43.6525, lng: -79.381667 }, // Toronto,
    zoom: 12,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
    maxZoom: 18,
};

function clusterCalculator(markers) {
    var count = markers.length; // the markers length is double counted (waypoint + circle)
    var actualCount = count / 2; // so we divided by 2 to get the actual number of point
    var dv = count;
    while (dv !== 0) {
        dv = parseInt(dv / 10, 10);
    }

    return {
        text: Math.round(actualCount),
        index: 3, // fix the color for clustering market at red
    };
}

function getPoint(id, pois) {
    let point = void 0;
    const poi = _.find(pois, { _id: id });
    if (poi) {
        point = {
            id: id,
            latitude: poi.latitude,
            longitude: poi.longitude,
        };
    }
    return point;
}

export default class extends React.Component {
    static propTypes = {
        pois: PropTypes.array.isRequired,
    };

    UNSAFE_componentWillMount() {
        this.pointDataCache = {
            markers: [],
            points: [],
        };
    }

    componentDidMount() {
        this.map = new google.maps.Map(this.refs.map, MAP_OPTIONS);
        this.cluster = new MarkerClusterer(this.map, [], MC_OPTIONS);
        this.cluster.setCalculator(clusterCalculator);
    }

    componentWillUnmount() {
        google.maps.event.clearListeners(this.map);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.pois !== nextProps.pois) {
            const that = this;

            const nextIds = nextProps.pois
                ? nextProps.pois.map(poi => {
                      return poi._id;
                  })
                : [];
            const currIds = this.props.pois
                ? this.props.pois.map(poi => {
                      return poi._id;
                  })
                : [];

            this.cluster.clearMarkers();
            this.pointDataCache.markers = [];
            this.pointDataCache.points = [];

            nextIds.forEach(id => {
                const selectedPoint = getPoint(id, nextProps.pois);
                const { marker } = that.createGoogleMapMarker(selectedPoint);

                that.pointDataCache.points.push(selectedPoint);
                that.pointDataCache.markers.push(marker);
            });

            this.cluster.addMarkers(this.pointDataCache.markers);

            const symmetricDiff = _.xor(nextIds, currIds);
            if (symmetricDiff.length !== 0) {
                this.fitBoundsToPoints();
            }
        }
    }

    shouldComponentUpdate() {
        // component should never update because updating is delegate to goggle map
        return false;
    }

    createGoogleMapMarker = pointData => {
        const marker = new google.maps.Marker({
            position: {
                lat: pointData.latitude,
                lng: pointData.longitude,
            },
            map: this.map,
            pointId: pointData.id,
        });

        return { marker };
    };

    fitBoundsToPoints = () => {
        const pointsForBoundary = this.pointDataCache.points;

        // ignore if no points, otherwise you'll end up in the ocean
        if (!pointsForBoundary || !pointsForBoundary.length) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        pointsForBoundary.forEach(point => {
            bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));
        });

        this.map.fitBounds(bounds);

        // zoom out if there is only one point
        if (pointsForBoundary.length === 1) {
            this.map.setZoom(14);
        }
    };

    render() {
        return (
            <div ref="widget" className="ef3-geofencing_mapWrapper">
                <div ref="map" className="ef3-geofencing_mapWrapper_map" />
                <div className="ef3-geofencing_mapWrapper_buttonGroup">
                    <button
                        onClick={() => this.fitBoundsToPoints()}
                        className="btn btn-secondary btn-sm"
                    >
                        See All Points
                    </button>
                </div>
            </div>
        );
    }
}

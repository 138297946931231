import _ from 'lodash';
import ProgressCalculator from 'common/progress-calculator';

import { getDraftValidators_forPayload } from './validation-rules';
import validate from 'utils/validate';
import { payload_formData } from './default-values';
import { payload_nonFormData } from './default-values';
import { payload_updateOnly } from './default-values';

import { serialize as _serialize } from './serialize-for-api';

// -----------
// Reducer helpers
// -----------

export function updateBudgetFields(nextDraft, campaign, formData) {
    const {
        billing_enabled: campaignBillingEnabled,
        campaign_budget_enabled: campaignBudgetEnabled,
    } = campaign;

    let draft = {
        ...nextDraft,
    };

    // Disabling billing resets billing rate and billable volume to 0
    const billingEnabled = nextDraft.billing_enabled && campaignBillingEnabled;
    const billingDisabled = billingEnabled === false;

    if (billingDisabled === true) {
        draft.billing_rate = 0;
        draft.billable_volume = 0;
    }

    // Billable volume must be 0 if Campaign billing is off
    if (!campaignBillingEnabled) {
        draft.billable_volume = 0;
    }

    // Term was toggled. Reset total/daily values
    if (formData.ef_billing_terms !== undefined) {
        draft.max_total_impressions = 0;
        draft.max_total_clicks = 0;
        draft.max_total_spend_local = 0;
        draft.max_total_billings = 0;
    }

    if (billingDisabled === false && campaignBudgetEnabled && formData.billing_enabled === true) {
        draft.billing_rate = campaign.billing_rate;
    }

    const billableVolume = Math.max(0, draft.billable_volume);

    switch (draft.ef_billing_terms) {
        case 'CPM': {
            draft.max_total_billings = billingDisabled
                ? 0
                : (billableVolume / 1000) * draft.billing_rate;
            draft.max_total_impressions = billableVolume + draft.non_billable_volume;
            draft.primary_pacing = 'impressions';
            draft.max_daily_billings = 0;
            break;
        }
        case 'CPC': {
            draft.max_total_billings = billingDisabled ? 0 : billableVolume * draft.billing_rate;
            draft.max_total_clicks = billableVolume + draft.non_billable_volume;
            draft.primary_pacing = 'clicks';
            draft.max_daily_billings = 0;
            break;
        }
        case 'billable_media_cost_markup': {
            draft.max_total_billings = billingDisabled
                ? 0
                : billableVolume * (1 + draft.billing_rate);
            draft.max_total_spend_local = billableVolume + draft.non_billable_volume;
            draft.primary_pacing = 'spend';
            draft.max_daily_billings = 0;
            break;
        }
        case 'billable_media_cost_margin': {
            const billableMediaSpendTotal = billingDisabled
                ? 0
                : billableVolume * (1 - draft.billing_rate);
            draft.max_total_billings = billableVolume;
            draft.max_total_spend_local = billableMediaSpendTotal + draft.non_billable_volume;

            const billableMediaSpendDaily = billingDisabled
                ? 0
                : draft.max_daily_billings * (1 - draft.billing_rate);
            draft.max_daily_spend_local = billableMediaSpendDaily;

            draft.primary_pacing = 'billings';
            break;
        }
        default: {
            return nextDraft;
        }
    }

    return draft;
}

export function applyBillingTermFix(nextDraft, state, formData) {
    var modifiers = {};
    if (formData.ef_billing_terms) {
        const isToBillableMediaCost = _.includes(
            ['billable_media_cost_markup', 'billable_media_cost_margin'],
            formData.ef_billing_terms
        );
        const isFromCPMorCPC = _.includes(['CPC', 'CPM'], state.draft.ef_billing_terms);

        const isToCPMorCPC = _.includes(['CPC', 'CPM'], formData.ef_billing_terms);
        const isFromBillableMediaCost = _.includes(
            ['billable_media_cost_markup', 'billable_media_cost_margin'],
            state.draft.ef_billing_terms
        );

        let multipler;
        if (isToBillableMediaCost && isFromCPMorCPC) {
            // divide by 100
            multipler = 0.01;
        } else if (isToCPMorCPC && isFromBillableMediaCost) {
            // multiply by 100
            multipler = 100;
            modifiers.billable_volume = parseInt(state.draft.billable_volume, 10);
            modifiers.non_billable_volume = parseInt(state.draft.non_billable_volume, 10);
        } else {
            multipler = 1;
        }

        modifiers.billing_rate = state.draft.billing_rate * multipler;
    }

    return {
        ...nextDraft,
        ...modifiers,
    };
}

// -----------
// Ad's health
// -----------
export function getHealth(ad, stats) {
    // xxx start here. this calculates the health
    const calculator = new ProgressCalculator(ad);
    const pacingMetric = calculator.getPacingMetric();
    const now = new Date();
    const totalGoal = []
        .concat(stats)
        .reduce((sum, statItem) => sum + Number(statItem[pacingMetric]), 0);
    return calculator.getPacedFillHealthForEndOfSecond(now, totalGoal);
}

export function getHealthDisplayName(health) {
    switch (health) {
        case 'pending':
            return 'Scheduled';
        case 'good':
            return 'On Track';
        case 'ok':
            return 'Behind';
        case 'bad':
            return 'Critical';
        case null:
            return 'Daily Goal';
        default:
            return health;
    }
}

// --------------
// Default values
// --------------

export function getDefaults(type) {
    const defaults = {};
    defaults.payload_formData = { ...payload_formData };
    defaults.payload_nonFormData = { ...payload_nonFormData };
    defaults.updateOnly = { ...payload_updateOnly };
    return { ...defaults[type] };
}

// ----------
// Validation
// ----------

export function validateAdDraft({
    draft,
    originalAd,
    billingEnabled,
    thirdPartyFees,
    orgFtaPartnerId,
    validGeoLayers,
    revenueModel,
    stats,
    campaignBudgetAllocationMethod,
    techFee,
    campaignFtaVersion,
    campaignType,
}) {
    const draftValidators_forPayload = getDraftValidators_forPayload({
        campaignBillingEnabled: billingEnabled,
        originalAd,
        thirdPartyFees,
        orgFtaPartnerId,
        validGeoLayers,
        revenueModel,
        stats,
        campaignBudgetAllocationMethod,
        techFee,
        campaignFtaVersion,
        campaignType,
    });

    const validators = _.pick(draftValidators_forPayload, Object.keys(draft));

    return validate(draft, validators);
}

// -----------------------------------------
// Data transformation before sending to api
// -----------------------------------------
export const serializeForApi = _serialize;

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import selector from './selector';
import { GlobalModal } from 'widgets-v5/modal';
import Toggle from 'widgets-v5/toggle';
import MultipleSelector from 'widgets/multiple-selector';
import InlineTooltip from 'widgets-v5/inline-tooltip';

import METRICS from 'common/constants/metrics';

import { PrimaryButton, NeutralButton, ButtonGroup } from 'widgets-v5/buttons';
import { BlockLoadGroup, InlineBlockLoadGroup } from 'widgets-v5/load-group';
import Separator from 'widgets-v5/separator';
import DataTable from 'widgets-v5/data-table';
import { InfoBanner } from 'widgets-v5/banners';

const METRIC_LABELS = {};
const aggregatedMetricLabelsForSharedSetting = [
    {
        name: 'conversions_sharing_settings_flag',
        label: 'Conversions',
    },
    {
        name: 'engagements_sharing_settings_flag',
        label: 'Engagements',
    },
];
_.each([...METRICS, ...aggregatedMetricLabelsForSharedSetting], metric => {
    METRIC_LABELS[metric.name] = metric.label;
});

const SHARING_SETTINGS_PRESENTATION_MAPPING = {
    metrics: METRIC_LABELS,
    dimensions: {
        country: 'Country',
        age: 'Age',
        gender: 'Gender',
        region: 'Region',
        creative: 'Creative',
        time: 'Day of Week & Time of Day',
        os: 'Device OS',
        markup_id: 'Creative',
        city: 'City',
        poi: 'POI Layer',
        channel: 'Channel',
        date: 'Date',
        audience_segment: 'Audience Segment',
        ad_id: 'Ad',
        platform: 'Platform',
        hour: 'Hour',
        month: 'Month',
        quarter: 'Quarter',
        week_by_campaign_start_day: 'Week by campaign start',
        week_by_monday_to_sunday: 'Week by Monday to Sunday',
        tactic_id: 'Tactic',
        geo_city: 'City',
        fsa: 'Postal Code',
        device_type_name: 'Device Type',
        device_language: 'Device Language',
        app_store_cat: 'App / Site Category',
        creative_size: 'Creative Size',
        creative_channel: 'Creative Channel',
        video_length: 'Video Length',
        adgroup: 'Custom Dimensions',
    },
};

// These metrics must always be enabled for all campaign sharing
const ALWAYS_ENABLED_METRICS = ['impressions', 'clicks', 'ctr'];

import {
    openForm,
    closeForm,
    initialize,
    destroy,
    share,
    submitForm,
    toggleEmail,
    toggleShare,
    selectAllEmails,
    selectNoneEmails,
    expandCollapseToggle,
    updateMetric,
    updateDimension,
    updateReportTab,
} from 'containers/campaign-sharing/actions';

class ReportSharing extends React.Component {
    state = {
        isOpen: false,
    };

    openForm = () => {
        const { dispatch, campaignId, hasNoAds } = this.props;
        if (hasNoAds) {
            return;
        }

        dispatch(initialize(campaignId));
        dispatch(openForm(campaignId));
    };

    closeForm = () => {
        const { dispatch, campaignId } = this.props;
        dispatch(closeForm(campaignId));
        dispatch(destroy(campaignId));
    };

    handleEmailToggle = (user, organization) => {
        const { dispatch, campaignId } = this.props;
        dispatch(toggleEmail(campaignId, user, organization));
    };

    handleShareToggle = organization => {
        const { dispatch, campaignId } = this.props;
        dispatch(toggleShare(campaignId, organization));
    };

    selectAllEmails = organization => {
        const { dispatch, campaignId } = this.props;
        dispatch(selectAllEmails(campaignId, organization));
    };

    selectNoneEmails = organization => {
        const { dispatch, campaignId } = this.props;
        dispatch(selectNoneEmails(campaignId, organization));
    };

    expandCollapseToggle = organization => {
        const { dispatch, campaignId } = this.props;
        dispatch(expandCollapseToggle(campaignId, organization));
    };

    submitForm = () => {
        const { dispatch, campaignId, isSending, organizationIdList } = this.props;
        if (isSending) {
            return;
        }

        dispatch(submitForm(campaignId));
    };

    componentDidMount() {
        const { dispatch, campaignId } = this.props;
    }

    handleMetricChange = newCheckboxValues => {
        const { dispatch, campaignId } = this.props;
        dispatch(updateMetric(campaignId, newCheckboxValues));
    };

    handleDimensionChange = tab => {
        return newCheckboxValues => {
            const { dispatch, campaignId } = this.props;
            dispatch(updateDimension(campaignId, tab, newCheckboxValues));
        };
    };

    handleTabClick = tab => {
        return checked => {
            const { dispatch, campaignId } = this.props;
            dispatch(updateReportTab(campaignId, tab, checked));
        };
    };

    handleClientChange = clientIds => {};

    render() {
        const {
            isFormOpen,
            campaign,
            orgs,
            hasNoAds,
            metrics,
            graphqlFetchingOrgsAndUsersLoading,
        } = this.props;

        return (
            <div className="ef3-campaignSharing">
                <span className="ef3-campaignSharing_shareButtonContainer">
                    {hasNoAds ? (
                        <InlineTooltip tooltip="Campaign setup incomplete" position="left">
                            <i className="fa fa-exclamation-triangle" />
                        </InlineTooltip>
                    ) : (
                        <InlineTooltip tooltip="Share" position="bottom" top={30}>
                            <NeutralButton
                                icon={<i className="fa fa-share" />}
                                onClick={this.openForm}
                            />
                        </InlineTooltip>
                    )}
                </span>
                {isFormOpen && (
                    <CampaignSharingModal
                        campaign={this.props.campaign}
                        reportTabs={this.props.reportTabs}
                        isSending={this.props.isSending}
                        orgs={this.props.orgs}
                        isFormOpen={true}
                        metricValues={_(metrics)
                            .filter(
                                metric => SHARING_SETTINGS_PRESENTATION_MAPPING.metrics[metric.name]
                            )
                            .map(metric => {
                                let hidden = false;
                                const hiddenMetrics = [
                                    'spend',
                                    'ecpc',
                                    'ecpm',
                                    'owner_media_cost_2_local',
                                    'owner_total_media_cost_local_ecpm',
                                    'owner_total_media_cost_local_ecpc',
                                    'owner_total_media_cost_local_ecpcv',
                                    'win_rate',
                                    'data_cost',
                                    'third_party_fees',
                                ];

                                // if billings module is off, hide billing options
                                if (_.includes(hiddenMetrics, metric.name)) {
                                    hidden = true;
                                } else if (
                                    _.includes(['revenue', 'erpc', 'erpm'], metric.name) &&
                                    !campaign.billing_enabled
                                ) {
                                    hidden = true;
                                } else if (
                                    campaign.third_party_fees.length === 0 &&
                                    metric.name === 'owner_total_media_cost_local'
                                ) {
                                    hidden = true;
                                } else if (metric.name === 'unique_users') {
                                    const isUniqueUsersDisabled = campaign.isUniqueUsersDisabled;
                                    hidden = isUniqueUsersDisabled;
                                }

                                if (!campaign.isUniqueUsersDisabled) {
                                    if (_.includes(['daily_uniq', 'average_freq'], metric.name)) {
                                        hidden = true;
                                    }
                                }

                                return {
                                    value: metric.shared,
                                    text:
                                        SHARING_SETTINGS_PRESENTATION_MAPPING.metrics[metric.name],
                                    key: metric.name,
                                    disabled: _.includes(ALWAYS_ENABLED_METRICS, metric.name)
                                        ? true
                                        : false,
                                    hidden,
                                };
                            })
                            .value()}
                        mapToMultiSelector={(dimensions, defaultDimension) =>
                            _(dimensions)
                                .map(dimension => {
                                    const hiddenDimensions = ['currency'];
                                    const hidden = _.includes(hiddenDimensions, dimension.name);
                                    return {
                                        value: dimension.shared,
                                        disabled: dimension.name === defaultDimension,
                                        text:
                                            SHARING_SETTINGS_PRESENTATION_MAPPING.dimensions[
                                                dimension.name
                                            ],
                                        key: dimension.name,
                                        hidden,
                                    };
                                })
                                .value()
                        }
                        organizationIdList={this.props.organizationIdList}
                        handleClientChange={this.handleClientChange}
                        closeForm={this.closeForm}
                        submitForm={this.submitForm}
                        expandCollapseToggle={this.expandCollapseToggle}
                        handleShareToggle={this.handleShareToggle}
                        selectAllEmails={this.selectAllEmails}
                        selectNoneEmails={this.selectNoneEmails}
                        handleEmailToggle={this.handleEmailToggle}
                        handleMetricChange={this.handleMetricChange}
                        handleDimensionChange={this.handleDimensionChange}
                        handleTabClick={this.handleTabClick}
                        organizations={this.props.organizations}
                        graphqlFetchingOrgsAndUsersLoading={graphqlFetchingOrgsAndUsersLoading}
                    />
                )}
            </div>
        );
    }
}

const dataTable = ({ orgs, ...props }) => (
    <DataTable
        columnClassNames={[null, null, 'ef5-campaign-sharing__notify-column']}
        headers={['Client Organization', 'Share?', 'Notify User via E-mail']}
        rows={_.map(orgs, org => [
            org.name,
            <Toggle
                className="ef5-campaign-sharing__share-toggle"
                status={'live'}
                position={org.shared ? 'right' : 'left'}
                onClick={() => props.handleShareToggle(org)}
                textLeft="off"
                textRight="on"
                width={50}
                height={22}
            />,
            org.shared && (
                <div>
                    <div className="ef3-campaignSharingEmails_controls">
                        <NeutralButton
                            text="Select All"
                            onClick={() => props.selectAllEmails(org)}
                        />
                        <NeutralButton
                            text="Select None"
                            onClick={() => props.selectNoneEmails(org)}
                        />
                    </div>
                    <div className="ef3-campaignSharingEmails_scrollableContainer">
                        {_.map(org.users, user => (
                            <div className="ef3-campaignSharingEmails_row" key={user.id}>
                                <label>
                                    <input
                                        className="ef3-campaignSharingEmails_checkbox"
                                        type="checkbox"
                                        onChange={() => props.handleEmailToggle(user, org)}
                                        checked={user.checked}
                                    />
                                    {_.get(user, 'email', 'N/A')}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            ),
        ])}
    />
);

class CampaignSharingModal extends React.Component {
    render() {
        const {
            reportTabs,
            isSending,
            mapToMultiSelector,
            graphqlFetchingOrgsAndUsersLoading,
        } = this.props;

        return (
            <GlobalModal
                isOpen={true}
                onClose={this.props.closeForm}
                title="Share Campaign"
                className="ef5-campaign-sharing"
                bottomBar={
                    <div>
                        <div className="ef3-campaignSharing_unsharingWarning">
                            Unsharing a metric or dimension will automatically remove it from
                            scheduled reports.
                        </div>
                        <InlineBlockLoadGroup isLoading={isSending}>
                            <ButtonGroup>
                                <PrimaryButton text="Save" onClick={this.props.submitForm} />
                                <NeutralButton onClick={this.props.closeForm} text="Cancel" />
                            </ButtonGroup>
                        </InlineBlockLoadGroup>
                    </div>
                }
            >
                <div className="ef3-campaignSharing_modal">
                    <div className="ef3-campaignSharing_table">
                        <Separator className="ef5-campaign-sharing__separator" label="Sharing" />
                        <BlockLoadGroup isLoading={!!graphqlFetchingOrgsAndUsersLoading}>
                            {dataTable(this.props)}
                        </BlockLoadGroup>
                        <Separator
                            className="ef5-campaign-sharing__separator"
                            label="Permissions"
                        />
                        <InfoBanner content='Media Cost and Billable Cost can no longer be shared. "Revenue" will be renamed "Spend" on the Client Report.' />
                        <div className="ef3-campaignSharingTable_dimensionMetric">
                            <div className="ef3-campaignSharingTable_dimensionMetric_metrics ef3-campaignSharingTable_dimensionMetric_column">
                                <MultipleSelector
                                    headerText="Metrics"
                                    onChange={this.props.handleMetricChange}
                                    values={this.props.metricValues}
                                />
                            </div>
                            <div className="ef3-campaignSharingTable_dimensionMetric_reportTabs">
                                {reportTabs && reportTabs.explore && (
                                    <div className="ef3-campaignSharingTable_dimensionMetric_column">
                                        <MultipleSelector
                                            headerText="Explore"
                                            onChange={this.props.handleDimensionChange('explore')}
                                            values={mapToMultiSelector(
                                                reportTabs.explore.dimensions,
                                                'date'
                                            )}
                                            useLeftRightButtons={false}
                                            onHeaderClick={_.noop}
                                            isHeaderDisabled={true}
                                            isHeaderChecked={reportTabs.explore.shared}
                                        />
                                    </div>
                                )}
                                {reportTabs && reportTabs.geo && (
                                    <div className="ef3-campaignSharingTable_dimensionMetric_column">
                                        <MultipleSelector
                                            headerText="Geo"
                                            onChange={this.props.handleDimensionChange('geo')}
                                            values={mapToMultiSelector(
                                                reportTabs.geo.dimensions,
                                                'region'
                                            )}
                                            useLeftRightButtons={false}
                                            onHeaderClick={this.props.handleTabClick('geo')}
                                            isHeaderChecked={reportTabs.geo.shared}
                                        />
                                    </div>
                                )}
                                {reportTabs && reportTabs.channel && (
                                    <div className="ef3-campaignSharingTable_dimensionMetric_column">
                                        <MultipleSelector
                                            headerText="Channel"
                                            onChange={this.props.handleDimensionChange('channel')}
                                            values={mapToMultiSelector(
                                                reportTabs.channel.dimensions,
                                                'channel'
                                            )}
                                            useLeftRightButtons={false}
                                            onHeaderClick={this.props.handleTabClick('channel')}
                                            isHeaderChecked={reportTabs.channel.shared}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </GlobalModal>
        );
    }
}

export default connect(selector)(ReportSharing);

import _ from 'lodash';

import { creativeTypes, creativeSettings } from './setting';

const commonDefault = {
    name: '',
    type: void 0,
    credit_limit: -1, // minus one means unlimited
    tech_fee: void 0, // not optional for media buyer
    auditors: [], // optional
    auditees: [], // optional
    currency: 'USD',
    default_timezone: 'UTC',
    infraCostPercentage: 0,
    custom_fields: [],
    revenueModel: null,
    agencyMarginRate: null,
};

const draftDefaultValues = {
    ...commonDefault,
    ...generateCreativeDefaultSetting(),
};

const defaultForApi = {
    ...commonDefault,
    creatives: {},
};

export { draftDefaultValues, defaultForApi };

function generateCreativeDefaultSetting() {
    // -----------------------------------------------------------------------
    // This function generate the following
    //     'creatives-standard-auto_approve'   : false,         //optional
    //     'creatives-video-auto_approve'      : false,         //optional
    //     'creatives-mraid-auto_approve'      : false,         //optional
    //     'creatives-custom_html-auto_approve': false,         //optional
    //     'creatives-custom_html-auto_approve': false,         //optional
    //
    //     'creatives-custom_html-enable'      : true,         //optional
    //     'creatives-standard-enable'         : true,         //optional
    //     'creatives-video-enable'            : true,         //optional
    //     'creatives-mraid-enable'            : true,         //optional
    //     'creatives-custom_html-enable'      : true,         //optional
    // -----------------------------------------------------------------------

    const out = {};
    _.forEach(creativeTypes, v1 => {
        _.forEach(creativeSettings, v2 => {
            const key = 'creatives-' + v1 + '-' + v2;
            if (v2 === 'allow_create') out[key] = true;
            if (v2 === 'auto_approve') out[key] = false;
        });
    });
    return out;
}

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import toastr from 'toastr';

import SyncIcon from '@mui/icons-material/Sync';
import BackupIcon from '@mui/icons-material/Backup';
import Button from '@mui/material/Button';

class FileUpload extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        buttonText: PropTypes.string,
        disabled: PropTypes.bool,
        accept: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        accept: 'audio/*,video/*,image/*,application/pdf',
        buttonText: 'Upload Assets',
        maxSizeInMB: 20,
    };

    uploadFiles = e => {
        const { files } = e.target;

        const errors = this.validateFiles(files);

        if (_.keys(errors).length > 0) {
            e.target.value = '';
            return;
        }

        _.map(files, file => {
            const formData = new FormData();
            formData.append('asset', file);
            this.props.onChange(formData);
        });

        e.target.value = '';
    };

    openFileDialog = () => {
        this.refs.fileUploadInput.click();
    };

    validateFiles = files => {
        const { maxSizeInMB } = this.props;

        const errors = {};
        _.each(files, file => {
            if (bytesToMb(file.size) > maxSizeInMB) {
                errors.fileSize = `File size is too large. Max ${maxSizeInMB}MB`;
            }
        });

        if (errors.fileSize) {
            toastr.warning(errors.fileSize, 'File Upload Error');
        }

        return errors;
    };

    render() {
        return (
            <div className="ef5-fileUpload">
                <form>
                    <input
                        multiple
                        ref="fileUploadInput"
                        className="ef5-fileUpload__input"
                        onChange={e => this.uploadFiles(e)}
                        type="file"
                        id="file"
                        accept={this.props.accept}
                    />
                </form>
                <Button
                    variant={this.props.variant ? this.props.variant : 'contained'}
                    color={this.props.color ? this.props.color : 'inherit'}
                    disabled={this.props.disabled}
                    startIcon={this.props.isLoading ? <SyncIcon /> : <BackupIcon />}
                    onClick={this.openFileDialog}
                    disableElevation={this.props.disableElevation}
                >
                    {this.props.buttonText}
                </Button>
            </div>
        );
    }
}

function bytesToMb(bytes) {
    return bytes / 1000000;
}

export default FileUpload;

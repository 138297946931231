import _ from 'lodash';

export const configs = [
    {
        value: 'billing_rate_attr',
        label: 'Billing Rate',
        formatValue: stat => {
            return _.map(stat.attributes.billing_rate_attr, cat => cat.label).join(', ');
        },
        orderValue: stat => {
            return _.map(stat.attributes.billing_rate_attr, cat => cat.label).join(', ');
        },
    },

    {
        value: 'app_store_cat_attr',
        label: 'Category',
        getTotalFormatValue: () => '',
        formatValue: stat => {
            const labelObj = _.map(stat.attributes.app_store_cat_attr, cat => cat.label);
            return _.uniq(_.split(labelObj.join(','), ',')).join(', ');
        },
        orderValue: stat => {
            return _.map(stat.attributes.app_store_cat_attr, cat => cat.label).join(', ');
        },
    },

    {
        value: 'campaign_currency_attr',
        label: 'Campaign Currency',
        getTotalFormatValue: () => '',
        formatValue: stat => {
            return _.map(stat.attributes.campaign_currency_attr, cat => cat.label).join(', ');
        },
        orderValue: stat => {
            return _.map(stat.attributes.campaign_currency_attr, cat => cat.label).join(', ');
        },
    },
];

const attributeConfig = {};

_.each(configs, config => (attributeConfig[config.value] = config));

export default attributeConfig;

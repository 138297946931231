import classnames from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import { ButtonGroup, NeutralButton, PrimaryButton } from 'widgets-v5/buttons';
import { GlobalModal } from 'widgets-v5/modal';
import FormField from 'widgets-v5/form-field';
import StandardInput from 'widgets-v5/standard-input';
import StandardSelector from 'widgets-v5/standard-selector';
/** import widgets components in the section above, keep the ascending order */

import AdPresetSummary from 'containers/ad-preset/modules/ad-preset-summary';
/** import classes and others in the section above, keep the ascending order */

const ORDER_OPTIONS = [
    {
        label: 'Alphabetical',
        value: 'byName',
    },
    {
        label: 'Last Created',
        value: 'byDate',
    },
];

export default class extends React.Component {
    static propTypes = {
        adPresets: PropTypes.array,
        campaign: PropTypes.object,
        closeModal: PropTypes.func,
        isModalOpen: PropTypes.bool,
        openModal: PropTypes.func,
        setAdPreset: PropTypes.func,
    };

    state = {
        selectedAdPreset: null,
        searchString: '',
        selectedSort: 'byName',
    };

    onFieldChange = string => {
        this.setState({
            searchString: string,
        });
    };

    handleDropdownChange = value => {
        this.setState({
            selectedSort: value,
        });
    };

    handleAdPresetClick = adpreset => {
        this.setState({
            selectedAdPreset: adpreset,
        });
    };

    partialHighlight = (text, part) => {
        const splits = text.split(part);

        return map(splits, (split, i) => {
            return (
                <span key={split + i}>
                    {split}
                    {i < splits.length - 1 && (
                        <span className="ef5-ad-preset-modal__body-list-item-highlight">
                            <b>{part}</b>
                        </span>
                    )}
                </span>
            );
        });
    };

    render() {
        const { adPresets, campaign, adPlatforms } = this.props;
        const { searchString, selectedSort, selectedAdPreset } = this.state;

        if (!adPresets.length) {
            return <div />;
        }

        const filteredAdPresets = _(adPresets)
            .filter(adPreset => new RegExp(searchString, 'gi').test(adPreset.ad_preset_name))
            .filter(adPreset => {
                if (!campaign.isAppStoreCatEnabled) {
                    const appStoreCats = [].concat(
                        adPreset.target_app_store_cat,
                        adPreset.target_app_store_cat_exclude
                    );
                    const isUsingAppStoreCats = appStoreCats.length > 0;

                    // Do not show App Store Cat presets for legacy Campaigns
                    if (isUsingAppStoreCats) {
                        return false;
                    } else {
                        return true;
                    }
                }

                return true;
            })
            .sortBy(adPreset => {
                if (selectedSort === 'byName') {
                    return adPreset.ad_preset_name.toLowerCase();
                } else {
                    return -moment(adPreset._created).valueOf();
                }
            })
            .value();

        return (
            <div className="ef5-ad-preset-modal">
                <NeutralButton onClick={this.props.openModal} text="Apply Ad Preset" />
                <GlobalModal
                    title="Ad Preset"
                    isOpen={this.props.isModalOpen}
                    onClose={this.props.closeModal}
                    bottomBar={
                        <div className="ef5-ad-preset-modal__footer">
                            <ButtonGroup>
                                <PrimaryButton
                                    text="Apply"
                                    onClick={() =>
                                        this.props.setAdPreset(this.state.selectedAdPreset)
                                    }
                                />
                                <NeutralButton text="Cancel" onClick={this.props.closeModal} />
                            </ButtonGroup>
                        </div>
                    }
                >
                    <div className="ef5-ad-preset-modal__body">
                        <div className="ef5-ad-preset-modal__presets-form">
                            <FormField>
                                <div className="ef5-ad-preset-modal__form">
                                    <StandardInput
                                        className="ef5-ad-preset-modal__form-input"
                                        value={''}
                                        placeholder="Search"
                                        onChange={this.onFieldChange}
                                    />
                                    <StandardSelector
                                        clearable={false}
                                        searchable={false}
                                        items={ORDER_OPTIONS}
                                        value={this.state.selectedSort}
                                        onChange={value => this.handleDropdownChange(value)}
                                    />
                                </div>
                            </FormField>
                            <div className="ef5-ad-preset-modal__list">
                                <ul>
                                    {map(filteredAdPresets, (adPreset, index) => {
                                        const adPresetName = adPreset.ad_preset_name;
                                        return (
                                            <li
                                                key={'line-' + index}
                                                className={classnames(
                                                    'ef5-ad-preset-modal__list-item',
                                                    get(selectedAdPreset, 'id') === adPreset.id
                                                        ? 'ef5-ad-preset-modal__list-item--active'
                                                        : ''
                                                )}
                                                onClick={() => this.handleAdPresetClick(adPreset)}
                                            >
                                                <i className="fa fa-check" />
                                                <span className="ef5-ad-preset-modal__list-item-name">
                                                    {this.partialHighlight(
                                                        adPresetName,
                                                        searchString
                                                    )}
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="ef5-ad-preset-modal__summary">
                            {selectedAdPreset ? (
                                <AdPresetSummary
                                    hiddinHeaderText={true}
                                    formData={selectedAdPreset}
                                    shouldHideFlexSegments={campaign.shouldHideFlexSegments}
                                    adPlatforms={adPlatforms}
                                />
                            ) : null}
                        </div>
                    </div>
                </GlobalModal>
            </div>
        );
    }
}

import _ from 'lodash';

export default function selector(storeState, props) {
    const geoResources = _.get(storeState, 'resources.geoCategories');
    const organizationId = _.get(storeState, 'profile.organizationId');
    return {
        geoResources,
        organizationId,
    };
}

export function geoTargetingSummarySelector(storeState, ownProps) {
    const { ad } = ownProps;
    const allGeoCategories = _.get(storeState, 'resources.geoCategories');

    const geoTargetingSubCategories = _(allGeoCategories.geoCategories)
        .map(category => category.subcategories)
        .flatten()
        .value();

    const selectedPoiCategories = _.filter(
        geoTargetingSubCategories,
        subCategory => ad.geo_targeting_settings.categories.indexOf(subCategory.id) !== -1
    );

    const selectedPoiSegments = _.filter(
        allGeoCategories.geoLayers,
        geoLayer => ad.geo_targeting_settings.category_layers.indexOf(geoLayer.id) !== -1
    );

    return {
        selectedPoiCategories,
        selectedPoiSegments,
    };
}

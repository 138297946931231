import PropTypes from 'prop-types';
import React from 'react';
import DateTimeField from 'react-datetime';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import classnames from 'classnames';

// import styles from 'react-datetime/css/react-datetime.css';

class DateTimePicker extends React.Component {
    static propTypes = {
        defaultText: PropTypes.string,
        onChange: PropTypes.func,
        timezone: PropTypes.string,
        disabled: PropTypes.bool,
        daysOfWeekDisabled: PropTypes.array,
        date: PropTypes.string,
    };

    static defaultProps = {
        timezone: 'UTC',
    };

    getISOString = (date, timezone) => {
        const value = moment.tz(date, 'll', timezone);
        const ISOString = value.toISOString();
        return ISOString;
    };

    widgetUpdate = datetime => {
        const { onChange, timezone } = this.props;

        // const ISOString = this.getISOString(timezonelessValue, "UTC");
        onChange(
            moment(datetime, 'll')
                .tz(timezone)
                .toISOString()
        );
    };

    render() {
        const { disabled, date, timezone } = this.props;

        const dateTimeInput =
            date === ''
                ? moment.tz(new Date(), timezone).format('ll')
                : moment.tz(date, timezone).format('ll');

        return (
            <div className={classnames('ef3-dateTimePicker', { 'is-disabled': disabled })}>
                <DateTimeField
                    inputProps={{ disabled, readOnly: true }}
                    defaultValue={dateTimeInput}
                    dateFormat="ll"
                    timeFormat={false}
                    onChange={this.widgetUpdate}
                />
            </div>
        );
    }
}

export default DateTimePicker;

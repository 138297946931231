import moment from 'moment';
import React from 'react';

const DATE_FORMAT = 'MMMM Do, YYYY @ h:ma';

export const NOTIFICATIONS_LIVE_DATE = moment(
    'December 20th, 2021 @ 9:00am',
    DATE_FORMAT
).valueOf();

export const FEATURE_NOTIFICATIONS = [
    {
        id: 1,
        released: false,
        feature: 'user-preferences-columns',
        date: '1 Jan, 2020',
        title: 'User preferences',
        subtitle: 'Users preferences for columns',
    },
    {
        id: 2,
        released: false,
        feature: 'inventory-forecast-redesign',
        date: '10 Jan, 2020',
        title: 'Inventory Forecast page redesign',
        subtitle: 'Target users using the And/Or Logic',
        img: '/images/notifications/inventory-forecast-redesign.png',
    },
    {
        id: 3,
        released: true,
        feature: 'tactics',
        date: 'January 20th, 2020',
        title: 'Tactics',
        subtitle: 'Target users using the And/Or Logic',
        img: '/images/notifications/tactics/intro-tactics.png',
        steps: [
            {
                img: '/images/notifications/tactics/or-and-logic.png',
                title: 'Introducing Tactics',
                content: 'Target users using the And/Or logic.',
            },
            {
                img: '/images/notifications/tactics/or-logic.png',
                title: 'OR Logic',
                content:
                    'Target all audience profiles who may find your ad relevant. Improve the scale of users you are reaching.',
            },
            {
                img: '/images/notifications/tactics/tactics-or-targeting.gif',
                title: 'How to setup OR Logic',
                content: 'Add OR logic by creating multiple tactics within an ad.',
            },
            {
                img: '/images/notifications/tactics/and-logic.png',
                title: 'AND Logic',
                content:
                    'Create niche audience profiles by targeting users who fit multiple traits. However, scale will shrink.',
            },
            {
                img: '/images/notifications/tactics/tactics-and-targeting.gif',
                title: 'How to setup AND Logic',
                content: 'Add AND logic by creating multiple targeting conditions within a tactic.',
            },
            {
                img: '/images/notifications/tactics/tactic.gif',
                title: 'Congrats! Now you are ready to use the tactics feature',
                content:
                    'To use the tactics feature find it under the tactics section in the ad form.',
            },
        ],
    },
    {
        id: 4,
        released: false,
        feature: 'audience-segments-poi',
        date: '10 Jan, 2020',
        title: 'POI Segments in the Audience Segments Dashboard',
        subtitle: 'Target users using the And/Or Logic',
    },
    {
        id: 5,
        released: false,
        feature: 'scheduled-weighted-rotation',
        date: '10 Jan, 2020',
        title: 'Multiple Creatives under Scheduled Rotation',
        subtitle: 'Allow users to add multiple creatives under Scheduled Rotation',
    },
    {
        id: 6,
        released: false,
        feature: 'history-redesign',
        date: '10 Jan, 2020',
        title: 'History redesign',
        subtitle: 'Allow users to add multiple creatives under Scheduled Rotation',
    },
    {
        id: 7,
        released: false,
        feature: 'history-redesign',
        date: '10 Jan, 2020',
        title: 'Campaign Cost/Revenue Progress Graphs',
        subtitle: 'Allow users to add multiple creatives under Scheduled Rotation',
    },
    {
        id: 8,
        released: true,
        feature: 'cross-platform-ads',
        date: 'November 25th, 2020',
        title: 'Cross-Platform Ads',
        subtitle: '',
        image: '',
        steps: [
            {
                img: '/images/notifications/cross-platform-ads/new-platforms.png',
                title: 'Introducing Cross-Platform Ads',
                content: (
                    <React.Fragment>
                        <p> You can now target multiple platforms in the same ad.</p>
                        <p>
                            Mobile Web and Desktop platforms are now enabled by default, in addition
                            to Mobile In-App.
                        </p>
                    </React.Fragment>
                ),
                subSteps: [
                    {
                        content: (
                            <p>
                                To run a cross-platform ad, make sure to select one or more
                                creatives that are compatible with all the targeted platforms
                            </p>
                        ),
                        img: '/images/notifications/cross-platform-ads/creative-targeting.png',
                    },
                ],
            },
        ],
    },
    {
        id: 9,
        released: true,
        feature: 'camapign-graph-release',
        date: 'December 10th, 2021',
        title: 'Campaign Progress',
        subtitle: 'New feature! See at a glance if your campaign will deliver in full.',
        callToAction: {
            text: 'Learn More',
            link:
                'https://engagefront.notion.site/Campaign-Progress-is-now-available-47f3f4db1b3e4c5e9e6e69ff15e04ca7',
        },
    },
    {
        id: 10,
        released: true,
        feature: 'dashboard-ss-release',
        date: 'March 11th, 2022',
        title: 'A new way to monitor campaigns',
        subtitle:
            'The new dashboard provides an overview of your campaigns and ads, so that you can see and prioritize your attention holistically.',
        callToAction: {
            text: 'Learn More',
            link:
                'https://www.notion.so/engagefront/A-new-way-to-monitor-campaigns-9f27c9c8edfe4c2cbad34ac7c7bc584f',
        },
    },
];

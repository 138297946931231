import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';

import Sidebar from '../../../containers/audience-sidebar';
import Layout from '../layout';
import AudienceHeader from '../modules/audience-header';

import actions from './actions';
import selector from './selector';

import { formatNumber_whole, formatNumber_currency } from 'utils/formatting';
import { BodyText } from 'widgets-v5/typography';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import Spacer from 'widgets-v5/spacer';
import { platformMapping } from 'common/constants/audiences';
import { isInternalUser } from 'states/profile/business-rules';

import { SearchInput } from 'widgets-v6/standard-input';

const handleSearch = (keywords, dispatch) => {
    dispatch(actions.fetCategories(keywords));
};

const useStyles = makeStyles(theme => ({
    chip: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    platformCell: {
        display: 'flex',
    },
}));

const FlexCategoryDashboard = ({ dispatch, categories, loading }) => {
    const classes = useStyles();
    useEffect(() => {
        dispatch(actions.fetCategories());
    }, []);

    const onChange = e => {
        const { value } = e.target;
        handleSearch(value, dispatch);
    };

    return (
        <Layout
            sidebar={<Sidebar />}
            header={
                <React.Fragment>
                    <AudienceHeader
                        title="Flex Location Categories"
                        description="Target users who request top locations from select categories."
                        category="Flex Geolocation"
                        categoryIcon="fa fa-map-marker"
                        categoryDescription=""
                        imgUrl="/images/audience-segments/audience-segment__precise-ocation-cat.png"
                    />
                    <SearchInput
                        className="ef3-standardInput"
                        type="text"
                        placeholder="Search Categories"
                        onChange={onChange}
                    />
                    <Spacer />
                </React.Fragment>
            }
            body={
                <React.Fragment>
                    {loading && <LinearProgress />}
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Platform(s)</TableCell>
                                <TableCell align="right"> Number of POIs </TableCell>
                                <TableCell align="right"> Number of Devices </TableCell>
                                <TableCell align="right"> Data Cost </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories &&
                                categories.length > 0 &&
                                categories.map(category => (
                                    <TableRow key={category.id}>
                                        <TableCell>{category.name}</TableCell>
                                        <TableCell>
                                            <div className={classes.platformCell}>
                                                <Chip
                                                    label={platformMapping.inapp}
                                                    size="small"
                                                    className={classes.chip}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">
                                            {' '}
                                            {formatNumber_whole(_.get(category, 'points'))}{' '}
                                        </TableCell>
                                        <TableCell align="right">N/A</TableCell>
                                        <TableCell align="right">
                                            {' '}
                                            {formatNumber_currency(0)} CPM{' '}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    {categories && categories.length === 0 && (
                        <div className="audiences__error">No Audiences Found</div>
                    )}
                </React.Fragment>
            }
        />
    );
};

export default connect(selector)(FlexCategoryDashboard);

import _ from 'lodash';
import toastr from 'toastr';
import { browserHistory } from 'react-router';

import c from 'common/constants/flux-events';
import { defaultSetting } from './services';
import createHttp from 'utils/http';

import Files from 'states/resources/files/actions';
import { fetchCampaign } from 'states/resources/campaigns/actions';

import notify from 'utils/notify';
import VError from 'verror';

import flags from 'containers/flags/service';

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

const actions = {
    generateReferenceImage(html, campaignId, renderDelay) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'CREATIVE_FORM__GET_REFERENCE_IMAGE_START',
                payload: {},
            });
            const http = createHttp();
            const [width, height] = _.get(getState(), 'creativeForm.draft.size').split('x');
            try {
                const file = await http.post(`campaigns/${campaignId}/files`, {
                    html,
                    campaignId,
                    dimensions: { width: Number(width), height: Number(height) },
                    renderDelay,
                    shouldDeleteUnused: true,
                });
                await new Promise(resolve => setTimeout(resolve, 1500));

                dispatch({
                    type: 'CREATIVE_FORM__GET_REFERENCE_IMAGE_SUCCESS',
                    payload: {
                        file,
                    },
                });
                dispatch({
                    type: c.SYSTEM__CAMPAIGN_FILES__UPLOAD__SUCCESS,
                    payload: {
                        file,
                        campaignId,
                    },
                });
                fetchCampaign(campaignId)(dispatch, getState);
                const newFiles = await dispatch(Files.getAll(campaignId));
                const newFileIds = _.map(newFiles, file => file.id);
                const fileIdsFromResource = _.map(
                    Object.keys(_.get(getState(), 'resources.files')),
                    id => id
                );

                let filesToBeDeleted = [];

                _.forEach(fileIdsFromResource, resourceFileId => {
                    if (!_.includes(newFileIds, resourceFileId)) {
                        filesToBeDeleted = _.concat(filesToBeDeleted, resourceFileId);
                    }
                });

                _.forEach(filesToBeDeleted, fileToBeDeleted => {
                    dispatch({
                        type: 'CREATIVE_FORM__DELETE_UNUSED_IMAGE',
                        payload: {
                            fileToBeDeleted,
                        },
                    });
                });
            } catch (error) {
                dispatch({
                    type: 'CREATIVE_FORM__GET_REFERENCE_IMAGE_ERROR',
                    payload: {},
                });
                toastr.warning('Error generating reference image', '', TOASTR_OPTIONS);
                if (window.bugsnagClient) {
                    window.bugsnagClient.notify(
                        new VError(error, `Failed to generate reference image`),
                        {
                            metaData: {
                                htmlString: `${html}`,
                                campaignId: `${campaignId}`,
                                imageHeight: `${height}`,
                                width: `${width}`,
                            },
                        }
                    );
                }
            }
        };
    },
    processUploadedZip(formData, campaignId) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'CREATIVE_FORM__UPLOAD_CREATIVE_ZIP_START',
                payload: {},
            });
            const http = createHttp();
            const exporter = _.get(getState(), `creativeForm.draft.exporter`);
            const dimensions = _.get(getState(), 'creativeForm.draft.size');

            formData.append('exporter', exporter);
            formData.append('dimensions', dimensions);

            try {
                const { markup } = await http.post(
                    `campaigns/${campaignId}/creatives/rich-media-markup`,
                    {},
                    {
                        data: formData,
                        dataType: 'json',
                        mimeType: 'multipart/form-data',
                        contentType: false,
                        cache: false,
                        processData: false,
                    }
                );
                dispatch({
                    type: 'CREATIVE_FORM__CHANGE_CONTENT_HTML',
                    payload: {
                        markup,
                    },
                });
                dispatch({
                    type: 'CREATIVE_FORM__UPLOAD_CREATIVE_ZIP_SUCCESS',
                    payload: {},
                });
            } catch (error) {
                dispatch({
                    type: 'CREATIVE_FORM__UPLOAD_CREATIVE_ZIP_ERROR',
                    payload: {},
                });
                notify({
                    error,
                    metaData: {
                        campaignId: `${campaignId}`,
                        exporter: `${exporter}`,
                        dimensions: `${dimensions}`,
                    },
                });
            }
        };
    },
};

export default actions;

import _ from 'lodash';
import c from 'common/constants/flux-events';
import flags from 'containers/flags/service';
const initialState = {
    orgIds: [],
    userIds: [],
};

export const NAME = 'admin';

export default function admin(state = initialState, action) {
    switch (action.type) {
        case c.ADMIN_ORGS__INIT_START: {
            return {
                ...state,
            };
        }
        case c.ADMIN_ORGS__INIT_SUCCESS: {
            const payload = _.get(action, 'payload', void 0);
            return {
                ...state,
                orgIds: _.get(payload, 'orgIds', void 0),
            };
        }
        case c.ADMIN_ORGS__INIT_FAIL: {
            return {
                ...state,
            };
        }

        case c.ADMIN_USERS__INIT_START: {
            return {
                ...state,
            };
        }
        case 'ADMIN_USERS__INIT_SUCCESS': {
            return {
                ...state,
                users: _.filter(action.payload.users, user => !user.third_party),
                orgId: action.payload.orgId,
                ownOrganization: action.payload.ownOrganization,
            };
        }
        case c.ADMIN_USERS__INIT_SUCCESS: {
            const userIds = _(action.payload.users)
                .filter(u => !u.third_party)
                .map(u => u.id)
                .value();

            return {
                ...state,
                userIds,
            };
        }
        case 'ADMIN_USERS__INIT_FAIL':
        case c.ADMIN_USERS__INIT_FAIL: {
            const payload = _.get(action, 'payload', void 0);
            return {
                ...state,
                showUsersError: payload.err.message,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}

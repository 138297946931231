import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import moment from 'moment';

import numeral from 'numeral';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import { getEnvironmentSettings } from 'services/environment';
import { isInternalUser } from 'states/profile/business-rules';

import TextFieldFormatter from 'widgets-v6/text-field-formatter';
import FormField from 'widgets-v5/form-field';
import ThirdPartyFees from './modules/third-party-fees';
import PrefixSuffixInput from 'widgets-v5/prefix-suffix-input';
import AdvertiserForm from 'forms/advertiser-form';
import Spacer from 'widgets-v5/spacer';
import { Label } from 'widgets-v5/typography';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import Separator from 'widgets-v5/separator';
import { TextField } from 'widgets-v6/standard-input';
import { SimpleSelect, MultiSelect, SingleSelect } from 'widgets-v6/select';
import { MultiTreeView } from 'widgets-v6/treeview';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
import { FlightsTable } from './modules/flights-table';
import { TextFieldInStrOutInt } from 'widgets-v6/standard-input';

import FootTrafficAttribution from './modules/foot-traffic-attribution';

import { formatNumber_currency, formatNumber_percentage } from 'utils/formatting';

import {
    ConversionLookBackWindowForm,
    ConversionLookBackWindowSummary,
} from './modules/conversion-lookback-window';

import {
    canViewCampaignCreator,
    getRevenueModelOptions,
    getCampaignTypeOptions,
    RevenueModelMapping,
    CampaignTypeMapping,
    CampaignAutoBudgetAllocationOptions,
} from 'states/resources/campaigns/business-logic';
import { useCampaignForm } from './v2';

const styles = theme => ({
    automaticBudgetAllocationOptimizationStrategy: {
        marginLeft: 30,
    },
    budgetAllocationDescription: {
        marginLeft: 30,
        maxWidth: 500,
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    analysisWindowControl: {
        marginTop: theme.spacing(1),
    },
});

const AUTOMATIC_BUDGET_ALLOCATION_OPTIONS = {
    CPA: {
        value: 'cpa',
        label: 'Optimize for eCPA',
    },
    CPC: {
        value: 'cpc',
        label: 'Optimize for eCPC',
    },
    CPCV: {
        value: 'cpcv',
        label: 'Optimize for eCPCV',
    },
};

const RESTRICTED_CATEGORY_OPTIONS = [{ label: 'Cannabis', value: 'cannabis' }];

const RESTRICTED_CATEGORY_WARNINGS = {
    cannabis: {
        title: (
            <div>
                Please ensure that this campaign is in compliance with{' '}
                <a
                    style={{ color: '#00897B', textDecoration: 'underline' }}
                    target="_blank"
                    href="https://www.canada.ca/en/health-canada/services/drugs-medication/cannabis/laws-regulations/promotion-prohibitions.html"
                    rel="noreferrer"
                >
                    Health Canada’s guidelines on cannabis promotion
                </a>
                .
            </div>
        ),
        severity: 'soft',
        message:
            'We will review ads and creatives according to internal guidelines, to the best of our ability, but assume no responsibility for ensuring that all applicable legal requirements are met. Our publisher and exchange partners reserve the right to block or limit cannabis-related campaigns.',
    },
};

const BUDGET_ALLOCATION_OPTIONS = [
    { label: 'Auto', value: 'auto' },
    { label: 'Manual', value: 'manual' },
];

const NONE_ADVERTISER = {
    label: 'Select Advertiser',
    value: '',
    revenueModel: RevenueModelMapping.Disabled,
};

import IABCategories from 'common/constants/iab-categories';
import dict_timezone from 'common/constants/timezones';
import { CampaignOverall } from './modules/campaign-overall';
import CampaignInfoBanner from './modules/campaign-info-banner';
import { AutomaticBudgetAllocationMethodSummary } from '../../pages/campaigns/campaigns-overview/modules/campaign-summary/summary-content';

const _getTimezoneOptions = () => {
    const environmentSettings = getEnvironmentSettings();
    const countryCodes = environmentSettings.getTimezoneCountryCodes();

    const validTimezoneEntries = _.pickBy(dict_timezone, (value, countryCode) => {
        return _.includes(countryCodes, countryCode);
    });

    const timezones = _(validTimezoneEntries)
        .map(v => v)
        .flatten()
        .map(v => ({
            label: `${v[1]}${v[3] ? ', ' + v[3] : ''} - ${v[2]}`,
            value: `${v[0]}`,
        }))
        .value();

    return timezones;
};

const _getCampaignCreatorOptions = users => {
    const options = _(users)
        .filter(user => !user.suspended && user.global_role !== 'revoked' && !(_.isEmpty(user.first_name) && _.isEmpty(user.last_name)))
        .map(user => ({
            label: user.first_name + ' ' + user.last_name,
            value: user.id,
        }))
        .sortBy(user => user.label)
        .value();

    return options;
};

const getFields = () => {
    const fields = [
        'advertiser_domain',
        'billing_enabled',
        'billing_rate',
        'billing_term',
        'campaign_budget_enabled',
        'campaign_max_total_spend_local',
        'clients',
        'currency',
        'default_timezone',
        'iab_categories',
        'name',
        'notes',
        'owner',
        'third_party_fees',
        'total_billings_local',
        'custom_fields',
        'revenueModel',
        'agencyMarginRate',
        'isUsingConsolidateCreativeOptions',
        'use_front_load_pacing',

        // FTA-related fields.
        'fta_enabled',
        'fta_management_level',
        'fta_location_list',

        // FTA Fully Managed Fields.
        'fta_conversion_lookback_window',
        'fta_pre_campaign_interval_days',
        'fta_post_campaign_interval_days',
        'fta_lift_version',
    ];

    if (isInternalUser()) {
        fields.push('creatorId');
    }

    if (getEnvironmentSettings().canUseCTVOrDOOH()) {
        fields.push('type');
    }

    return fields;
};

const OptionCaption = withStyles({
    root: {
        marginLeft: '30px',
        width: '60%',
    },
})(Typography);

class CampaignForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedPanel: '',
        };
    }

    static defaultProps = {
        getFields,
    };

    componentDidMount() {
        const { campaignId, getFields, campaignForm } = this.props;
        campaignForm.init({
            campaignId,
            fields: getFields(),
            timezone: _.get(campaignForm, 'draft.default_timezone', 'America/Toronto'),
        });
    }

    handleClose = () => {
        const { campaignForm, onClose } = this.props;

        campaignForm.close();
        if (onClose) {
            onClose();
        }
    };

    handleClientChange = (selectedClients, clientType) => {
        const { campaignForm } = this.props;
        const validRevenueModels = getRevenueModelOptions(campaignForm?.draft?.type).map(
            option => option.value
        );

        if (clientType === 'advertiser') {
            _.each(campaignForm.advertisers, advertiser => {
                if (advertiser.value === _.head(selectedClients)) {
                    let newRevenueModel = null;
                    if (!advertiser.revenueModel) {
                        newRevenueModel = RevenueModelMapping.Disabled;
                    } else if (validRevenueModels.includes(advertiser.revenueModel)) {
                        newRevenueModel = advertiser.revenueModel;
                    }
                    this.handleRevenueModelChange(newRevenueModel);

                    if (
                        advertiser.revenueModel &&
                        advertiser.revenueModel === RevenueModelMapping.AgencyMargin
                    ) {
                        this.onBillingRateChange(advertiser.agencyMarginRate);
                    }
                }
            });

            if (_.head(selectedClients) === '') {
                this.handleRevenueModelChange(RevenueModelMapping.Disabled);
            }
        }

        const nextGrouping = {
            ...campaignForm.clientGrouping,
            [clientType]: _.map(selectedClients, organizationId => {
                return {
                    organization: organizationId,
                    shared: _.get(campaignForm.clientMapping, `${organizationId}.shared`, false),
                    type: clientType,
                };
            }),
        };

        const clients = _(nextGrouping)
            .map(clients => clients)
            .flatten()
            .value()
            .filter(c => c.organization !== '');

        this.onCampaignFieldChange('clients')(clients);
    };

    handleIabCategoryChange = selectedCategories => {
        this.onCampaignFieldChange('iab_categories')(selectedCategories);
    };

    handleLiftVersionChange = selectedVersion => {
        this.onCampaignFieldChange('fta_lift_version')(selectedVersion);
    };

    handlePreIntervalChange = selectedVersion => {
        this.onCampaignFieldChange('fta_pre_campaign_interval_days')(selectedVersion);
    };

    handlePostIntervalChange = selectedVersion => {
        this.onCampaignFieldChange('fta_post_campaign_interval_days')(selectedVersion);
    };

    onCampaignFieldChange = fieldName => {
        return fieldValue => {
            const newFormData = {
                [fieldName]: fieldValue,
            };
            this.updateDraft(newFormData);
        };
    };

    updateDraft = changes => {
        const { campaignId, campaignForm } = this.props;
        campaignForm.campaignFormUpdate(changes, campaignId);
    };

    handleFTAConversionLookBackWindowChange = ({ field, value }) => {
        const { campaignForm } = this.props;
        if (field === 'type' && value === 'unlimited') {
            this.updateDraft({
                fta_conversion_lookback_window: {
                    ...campaignForm.draft.fta_conversion_lookback_window,
                    type: 'unlimited',
                    lookback_window_days: null,
                },
            });
        } else {
            this.updateDraft({
                fta_conversion_lookback_window: {
                    ...campaignForm.draft.fta_conversion_lookback_window,
                    [field]: value,
                },
            });
        }
    };

    handlePartnerIdChange = id => {
        this.onCampaignFieldChange('fta_partner_id')(id);
    };

    onTotalBillingsChange = newTotalBillings => {
        const { billing_term, billing_rate } = this.props.campaignForm.draft;

        const newFormData = {
            total_billings_local: newTotalBillings,
        };

        newFormData.campaign_max_total_spend_local = recalculateMaxTotalSpendLocal(
            newTotalBillings,
            billing_term,
            billing_rate
        );

        this.updateDraft(newFormData);
    };

    onBillingRateChange = newBillingRate => {
        const {
            billing_term,
            total_billings_local,
            flights,
            flightPacingStrategy,
        } = this.props.campaignForm.draft;

        const newFormData = {
            billing_rate: newBillingRate,
        };

        newFormData.campaign_max_total_spend_local = recalculateMaxTotalSpendLocal(
            total_billings_local,
            billing_term,
            newBillingRate
        );

        // If this campaigns has flights then we need to update the total_cost_budget
        // fields for each flight as the billing rate has change.
        if (flightPacingStrategy === 'campaign' && flights && flights.length) {
            // Only update flights which haven't ended yet.
            let totalCostBudgetForWholeCampaign = 0;
            const now = moment();
            const newFlights = flights.map(flight => {
                const flightTotalCostBudget =
                    flight.end && now.isAfter(flight.end)
                        ? flight.total_cost_budget
                        : flight.billings_local - flight.billings_local * newBillingRate;
                totalCostBudgetForWholeCampaign += flightTotalCostBudget;
                return {
                    ...flight,
                    total_cost_budget: flightTotalCostBudget,
                };
            });
            newFormData.campaign_max_total_spend_local = totalCostBudgetForWholeCampaign;
            newFormData.flights = newFlights;
        }

        this.updateDraft(newFormData);
    };

    onMediaSpendChange = newMediaSpend => {
        const newFormData = {
            campaign_max_total_spend_local: newMediaSpend,
        };

        this.updateDraft(newFormData);
    };

    submitCampaign = () => {
        const { onSubmit, campaignId, campaignForm } = this.props;

        if (campaignForm.isSubmitting) {
            return;
        }

        campaignForm.submit(campaignId, onSubmit);
    };

    getFieldVisibilityMapping = () => {
        const { getFields } = this.props;
        const mapping = {};
        _.each(getFields(), field => {
            mapping[field] = true;
        });

        return mapping;
    };

    handleRevenueModelChange = value => {
        const { campaignForm } = this.props;
        campaignForm.changeRevenueModel(value);
    };

    handleBillingEnabledChage = () => {
        const toggledBillingEnabled = !this.props.campaignForm.draft.billing_enabled;

        const formData = {
            billing_enabled: toggledBillingEnabled,
            campaign_budget_enabled: false,
        };

        this.updateDraft(formData);
    };

    handleCustomFieldChange = (customField, value) => {
        const { draft } = this.props.campaignForm;
        const customFields = _.map(draft.custom_fields, c => {
            if (c.name !== customField.name) {
                return c;
            }

            return {
                ...customField,
                value,
            };
        });

        this.updateDraft({ custom_fields: customFields });
    };

    handleBudgetAllocationChange = value => {
        const {
            campaignForm: { draft },
        } = this.props;

        const newDraft = {
            budget_allocation_method: value,
            flightPacingStrategy: 'ad',
            // We only set campaign_primary_pacing to impressions if revenue model is CPM/CPC
            // AND we're using auto budget allocation method.
            campaign_primary_pacing:
                [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                    draft.revenueModel
                ) && value === 'auto'
                    ? 'impressions'
                    : 'spend',
            flights: [],
        };

        if (draft.type === CampaignTypeMapping.CTV && value === 'auto') {
            newDraft.automaticBudgetAllocationOptimizationStrategy =
                CampaignAutoBudgetAllocationOptions.CPCV;
        }

        this.updateDraft(newDraft);
    };

    handlePacingStrategy = value => {
        const isUsingFrontLoadPacing = value === 'true';

        this.updateDraft({ use_front_load_pacing: isUsingFrontLoadPacing });
    };

    expandPanel = panel => {
        return () => {
            this.state.expandedPanel === panel
                ? this.setState({
                      expandedPanel: '',
                  })
                : this.setState({
                      expandedPanel: panel,
                  });
        };
    };

    getUsersMapping = () => {
        const usersMapping = {};

        _.each(_.get(this.props, 'campaignForm.ownOrganization.users', []), user => {
            usersMapping[user.id] = {
                ...user,
                fullname: `${user.first_name} ${user.last_name}`,
            };
        });

        return usersMapping;
    };

    getPartnersMapping = () => {
        const partnersMapping = {};

        _.each(this.props.campaignForm.partners, partner => {
            partnersMapping[partner.value] = partner;
        });

        return partnersMapping;
    };

    getSalesRepsMapping = () => {
        const salesRepsMapping = {};

        _.each(this.props.campaignForm.salesReps, salesRep => {
            salesRepsMapping[salesRep.value] = salesRep;
        });

        return salesRepsMapping;
    };

    getAdvertisersMapping = () => {
        const advertisersMapping = {};

        _.each(this.props.campaignForm.advertisers, advertiser => {
            advertisersMapping[advertiser.value] = advertiser;
        });

        return advertisersMapping;
    };

    getRevenueModelMapping = campaignType => {
        const revenueModelMapping = {};

        _.each(getRevenueModelOptions(campaignType), option => {
            revenueModelMapping[option.value] = option;
        });

        return revenueModelMapping;
    };

    getRestrictedCategoryMapping = () => {
        return RESTRICTED_CATEGORY_OPTIONS.reduce((acc, option) => {
            return {
                ...acc,
                [option.value]: option,
            };
        }, {});
    };

    getBudgetAllocationMapping = () => {
        const budgetAllocationMapping = {};

        _.each(BUDGET_ALLOCATION_OPTIONS, option => {
            budgetAllocationMapping[option.value] = option;
        });

        return budgetAllocationMapping;
    };

    getLocationListsMapping = () => {
        const locationListsMapping = {};

        _.each(this.props.campaignForm.ftaLocationLists, option => {
            locationListsMapping[option.id] = option;
        });

        return locationListsMapping;
    };

    render() {
        const { type, classes, campaignForm } = this.props;

        const envSettings = getEnvironmentSettings();

        const { onCampaignFieldChange, onTotalBillingsChange, onBillingRateChange } = this;
        const fieldVisibility = this.getFieldVisibilityMapping();
        // when editing, only show third party fees if data fee is enabled
        const shouldShowThirdPartyFees = fieldVisibility.third_party_fees;

        if (window.DEBUG) {
            console.log('errors', campaignForm.errors);
        }

        const userMapping = this.getUsersMapping();
        const partnersMapping = this.getPartnersMapping();
        const salesRepsMappingMapping = this.getSalesRepsMapping();
        const advertisersMapping = this.getAdvertisersMapping();
        const revenueModelMapping = this.getRevenueModelMapping();
        const budgetAllocationMapping = this.getBudgetAllocationMapping();
        const locationListsMapping = this.getLocationListsMapping();
        const restrictedCategoryMapping = this.getRestrictedCategoryMapping();

        const environmentSettings = getEnvironmentSettings();

        const showBudgetOptimizationField = envSettings.canUseAutoBudgetAllocation();

        const hasRestrictedCategoryWarning =
            campaignForm.draft &&
            campaignForm.draft.restricted_category &&
            RESTRICTED_CATEGORY_WARNINGS[campaignForm.draft.restricted_category];

        return (
            <Dialog
                maxWidth="lg"
                open={campaignForm.isModalOpen}
                onClose={this.handleClose}
                fullWidth
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <DialogTitle id="customized-dialog-title">Campaign Setup</DialogTitle>
                    </Grid>
                    <Grid item>
                        <IconButton aria-label="close" onClick={this.handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <DialogContent dividers>
                    <React.Fragment>
                        {campaignForm.isLoading ? (
                            <Grid container justifyContent="center">
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <div>
                                <Typography>Basic</Typography>
                                <Spacer />
                                <AccordionItem
                                    required
                                    onClick={this.expandPanel('campaignName')}
                                    expanded={this.state.expandedPanel === 'campaignName'}
                                    title="Name"
                                    incomplete={
                                        !campaignForm.showErrors && !campaignForm.draft.name
                                    }
                                    error={campaignForm.showErrors && !!campaignForm.errors.name}
                                    details={
                                        fieldVisibility.name &&
                                        campaignForm.draft.name !== undefined && (
                                            <Grid item xs={8}>
                                                <CampaignName />
                                            </Grid>
                                        )
                                    }
                                    summary={
                                        campaignForm.draft.name ? (
                                            <Label>{campaignForm.draft.name}</Label>
                                        ) : (
                                            <Label placeholder>Add a Name</Label>
                                        )
                                    }
                                />
                                {fieldVisibility.type && (
                                    <AccordionItem
                                        readOnly={type === 'edit'}
                                        required
                                        onClick={this.expandPanel('type')}
                                        expanded={this.state.expandedPanel === 'type'}
                                        title="Type"
                                        incomplete={
                                            !campaignForm.showErrors && !campaignForm.draft.type
                                        }
                                        error={
                                            campaignForm.showErrors && !!campaignForm.errors.type
                                        }
                                        details={
                                            campaignForm.draft.type !== undefined && (
                                                <Grid item xs={4}>
                                                    <SimpleSelect
                                                        value={campaignForm.draft.type}
                                                        onChange={e => {
                                                            campaignForm.changeType(e.target.value);
                                                        }}
                                                        options={getCampaignTypeOptions(
                                                            campaignForm.ownOrganization.id
                                                        )}
                                                    />
                                                </Grid>
                                            )
                                        }
                                        summary={
                                            campaignForm.draft.type ? (
                                                <Label>
                                                    {_.find(
                                                        getCampaignTypeOptions(
                                                            campaignForm.ownOrganization.id
                                                        ),
                                                        ({ value }) =>
                                                            value === campaignForm.draft.type
                                                    )?.label || campaignForm.draft.type}
                                                </Label>
                                            ) : (
                                                <Label placeholder>Set a type</Label>
                                            )
                                        }
                                    />
                                )}
                                {_.map(campaignForm.draft.custom_fields, (customField, i) => (
                                    <AccordionItem
                                        required={customField.required}
                                        key={customField.name}
                                        onClick={this.expandPanel(customField.name)}
                                        expanded={this.state.expandedPanel === customField.name}
                                        title={customField.name}
                                        incomplete={!campaignForm.showErrors && !customField.value}
                                        error={
                                            _.get(campaignForm.errors, [
                                                'custom_fields',
                                                i,
                                                'value',
                                            ]) && campaignForm.showErrors
                                        }
                                        details={
                                            <CustomFieldTextField
                                                error={
                                                    campaignForm.showErrors &&
                                                    _.get(campaignForm.errors, [
                                                        'custom_fields',
                                                        i,
                                                        'value',
                                                    ])
                                                }
                                                value={customField.value}
                                                onChange={value => {
                                                    campaignForm.changeCustomField({
                                                        key: customField.key,
                                                        value,
                                                    });
                                                }}
                                                placeholder={customField.name}
                                            />
                                        }
                                        summary={
                                            customField.value ? (
                                                <Label>{customField.value}</Label>
                                            ) : (
                                                <Label placeholder>Add a {customField.name}</Label>
                                            )
                                        }
                                    />
                                ))}
                                {fieldVisibility.default_timezone && (
                                    <AccordionItem
                                        onClick={this.expandPanel('timezone')}
                                        expanded={this.state.expandedPanel === 'timezone'}
                                        title="Default Timezone"
                                        error={
                                            campaignForm.errors.default_timezone &&
                                            campaignForm.showErrors
                                        }
                                        details={
                                            <Grid item xs={4}>
                                                <SimpleSelect
                                                    value={campaignForm.draft.default_timezone}
                                                    onChange={e => {
                                                        campaignForm.changeDefaultTimezone(
                                                            e.target.value
                                                        );
                                                    }}
                                                    options={_getTimezoneOptions()}
                                                />
                                            </Grid>
                                        }
                                        summary={
                                            <Label>{campaignForm.draft.default_timezone}</Label>
                                        }
                                        helper="Note: you are always billed according to Eastern Time
                                            (Canada), regardless of the campaign’s timezone."
                                    />
                                )}
                                {canViewCampaignCreator() && campaignForm.ownOrganization && (
                                    <AccordionItem
                                        onClick={this.expandPanel('campaignCreator')}
                                        expanded={this.state.expandedPanel === 'campaignCreator'}
                                        title="Assignee"
                                        error={
                                            campaignForm.errors.creatorId && campaignForm.showErrors
                                        }
                                        details={
                                            <Grid item xs={3}>
                                                <SimpleSelect
                                                    value={campaignForm.draft.creatorId}
                                                    onChange={e =>
                                                        campaignForm.changeCreator(e.target.value)
                                                    }
                                                    options={_getCampaignCreatorOptions(
                                                        campaignForm.ownOrganization.users
                                                    )}
                                                />
                                            </Grid>
                                        }
                                        summary={
                                            campaignForm.draft.creatorId ? (
                                                <Label>
                                                    {_.get(
                                                        userMapping,
                                                        `${campaignForm.draft.creatorId}.fullname`
                                                    )}
                                                </Label>
                                            ) : (
                                                <Label placeholder>Select an assignee</Label>
                                            )
                                        }
                                    />
                                )}
                                {fieldVisibility.notes && (
                                    <AccordionItem
                                        onClick={this.expandPanel('notes')}
                                        expanded={this.state.expandedPanel === 'notes'}
                                        title="Notes"
                                        error={campaignForm.errors.notes && campaignForm.showErrors}
                                        details={
                                            <Grid item xs={6}>
                                                <Notes
                                                    error={
                                                        campaignForm.errors.notes &&
                                                        campaignForm.showErrors
                                                    }
                                                    value={campaignForm.draft.notes}
                                                    onChange={notes =>
                                                        campaignForm.changeNotes(notes)
                                                    }
                                                />
                                            </Grid>
                                        }
                                        summary={
                                            campaignForm.draft.notes ? (
                                                <div>
                                                    <Label>{campaignForm.draft.notes}</Label>
                                                </div>
                                            ) : (
                                                <Label placeholder>Add Notes</Label>
                                            )
                                        }
                                        helper="Attach additional notes to this campaign. Notes are
                                            searchable from the homepage."
                                    />
                                )}
                                <Spacer />
                                <Typography>Categorization</Typography>
                                <Spacer />
                                {_.includes(
                                    ['admin'],
                                    _.get(campaignForm.ownOrganization, 'type')
                                ) &&
                                    isInternalUser() && (
                                        <AccordionItem
                                            onClick={this.expandPanel('partners')}
                                            expanded={this.state.expandedPanel === 'partners'}
                                            title="Partners"
                                            error={
                                                campaignForm.errors.notes && campaignForm.showErrors
                                            }
                                            details={
                                                <MultiSelect
                                                    value={_.map(
                                                        campaignForm.clientGrouping.partner,
                                                        c => c.organization
                                                    )}
                                                    options={campaignForm.partners}
                                                    onChange={clients => {
                                                        this.handleClientChange(clients, 'partner');
                                                    }}
                                                    placeholder="Partners"
                                                />
                                            }
                                            summary={
                                                campaignForm.clientGrouping.partner ? (
                                                    _.map(campaignForm.draft.clients, client => (
                                                        <Label key={client.organization}>
                                                            {partnersMapping[client.organization] &&
                                                                partnersMapping[client.organization]
                                                                    .label}
                                                        </Label>
                                                    ))
                                                ) : (
                                                    <Label placeholder>Select Partners</Label>
                                                )
                                            }
                                        />
                                    )}
                                <AccordionItem
                                    readOnly={type === 'edit'}
                                    required
                                    onClick={this.expandPanel('advertisers')}
                                    expanded={this.state.expandedPanel === 'advertisers'}
                                    title="Advertisers"
                                    error={
                                        campaignForm.showErrors && campaignForm.errors.advertiser
                                    }
                                    incomplete={
                                        !campaignForm.showErrors &&
                                        campaignForm.draft.clients.length === 0
                                    }
                                    details={
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={7}>
                                                <SingleSelect
                                                    error={
                                                        campaignForm.showErrors &&
                                                        !!campaignForm.errors.advertiser
                                                    }
                                                    value={campaignForm.advertisers.find(
                                                        ({ value }) =>
                                                            value ===
                                                            (campaignForm.clientGrouping.advertiser
                                                                ? _.first(
                                                                      campaignForm.clientGrouping
                                                                          .advertiser
                                                                  ).organization
                                                                : '')
                                                    )}
                                                    options={[
                                                        NONE_ADVERTISER,
                                                        ...campaignForm.advertisers,
                                                    ]}
                                                    onChange={advertiser =>
                                                        this.handleClientChange(
                                                            [advertiser],
                                                            'advertiser'
                                                        )
                                                    }
                                                    placeholder="Advertisers"
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <AdvertiserForm
                                                    ownOrganization={
                                                        this.props.campaignForm.ownOrganization
                                                    }
                                                />
                                            </Grid>
                                            {campaignForm.draft.clients.length > 0 && (
                                                <Grid item xs={6}>
                                                    <Label placeholder>
                                                        Revenue Model and Agency margin have been
                                                        updated according to this Advertiser's
                                                        settings.
                                                    </Label>
                                                </Grid>
                                            )}
                                        </Grid>
                                    }
                                    summary={
                                        campaignForm.clientGrouping.advertiser ? (
                                            _.map(
                                                campaignForm.clientGrouping.advertiser,
                                                advertiser => (
                                                    <Label key={advertiser.organization}>
                                                        {advertisersMapping[
                                                            advertiser.organization
                                                        ] &&
                                                            advertisersMapping[
                                                                advertiser.organization
                                                            ].label}
                                                    </Label>
                                                )
                                            )
                                        ) : (
                                            <Label placeholder>Select Advertiser</Label>
                                        )
                                    }
                                    helper="Select an advertiser to track conversions associated with advertiser pixels."
                                />
                                {fieldVisibility.advertiser_domain &&
                                    campaignForm.draft.advertiser_domain !== undefined && (
                                        <AccordionItem
                                            required
                                            onClick={this.expandPanel('advertiserDomain')}
                                            expanded={
                                                this.state.expandedPanel === 'advertiserDomain'
                                            }
                                            title="Advertiser Domain"
                                            error={
                                                campaignForm.showErrors &&
                                                campaignForm.errors.advertiser_domain
                                            }
                                            incomplete={
                                                !campaignForm.showErrors &&
                                                !campaignForm.draft.advertiser_domain
                                            }
                                            details={
                                                <AdvertiserDomain
                                                    error={
                                                        campaignForm.errors.advertiser_domain &&
                                                        campaignForm.showErrors
                                                    }
                                                    errorText={
                                                        campaignForm.errors.advertiser_domain
                                                    }
                                                    value={campaignForm.draft.advertiser_domain}
                                                    onChange={advertiser_domain =>
                                                        this.updateDraft({ advertiser_domain })
                                                    }
                                                />
                                            }
                                            summary={
                                                campaignForm.draft.advertiser_domain ? (
                                                    <Label>
                                                        {campaignForm.draft.advertiser_domain}
                                                    </Label>
                                                ) : (
                                                    <Label placeholder>Add Advertiser Domain</Label>
                                                )
                                            }
                                            helper="Enter the domain of the advertiser. If you are unsure, use
                                            the URL of the landing page without “https://” or “www”,
                                            e.g. “brand.com”."
                                        />
                                    )}
                                <AccordionItem
                                    onClick={this.expandPanel('restrictedCategory')}
                                    expanded={this.state.expandedPanel === 'restrictedCategory'}
                                    error={
                                        campaignForm.errors.restricted_category &&
                                        campaignForm.showErrors
                                    }
                                    title="Restricted Category"
                                    details={
                                        <Grid item>
                                            <Label>
                                                You must declare if your campaign contains content
                                                from the following restricted categories.
                                            </Label>
                                            <Box width="50%" marginTop={2} marginBottom={2}>
                                                <SingleSelect
                                                    value={campaignForm.draft.restricted_category}
                                                    onChange={campaignForm.changeRestrictedCategory}
                                                    options={RESTRICTED_CATEGORY_OPTIONS}
                                                    disabled={type === 'edit'}
                                                    placeholder="Select a Category"
                                                    error={
                                                        campaignForm.showErrors &&
                                                        campaignForm.errors.restricted_category
                                                    }
                                                />
                                            </Box>
                                            {hasRestrictedCategoryWarning &&
                                                !campaignForm.errors.restricted_category && (
                                                    <Box>
                                                        <WarningText
                                                            title={
                                                                hasRestrictedCategoryWarning.title
                                                            }
                                                            severity={
                                                                hasRestrictedCategoryWarning.severity
                                                            }
                                                            message={
                                                                hasRestrictedCategoryWarning.message
                                                            }
                                                        />
                                                    </Box>
                                                )}
                                        </Grid>
                                    }
                                    summary={
                                        campaignForm.draft.restricted_category ? (
                                            <Label>
                                                {
                                                    restrictedCategoryMapping[
                                                        campaignForm.draft.restricted_category
                                                    ].label
                                                }
                                            </Label>
                                        ) : (
                                            <Label placeholder>Select Restricted Category</Label>
                                        )
                                    }
                                />
                                {fieldVisibility.iab_categories && (
                                    <AccordionItem
                                        required
                                        onClick={this.expandPanel('advertiserCategory')}
                                        expanded={this.state.expandedPanel === 'advertiserCategory'}
                                        title="Advertiser Category"
                                        error={
                                            campaignForm.showErrors &&
                                            campaignForm.errors.iab_categories
                                        }
                                        incomplete={
                                            !campaignForm.showErrors &&
                                            !campaignForm.draft.iab_categories.length > 0
                                        }
                                        details={
                                            <Grid item xs={6}>
                                                <MultiTreeView
                                                    error={
                                                        campaignForm.showErrors &&
                                                        campaignForm.errors.iab_categories
                                                    }
                                                    value={campaignForm.draft.iab_categories}
                                                    option={IABCategories}
                                                    onChange={this.handleIabCategoryChange}
                                                />
                                            </Grid>
                                        }
                                        summary={
                                            campaignForm.draft.iab_categories.length > 0 ? (
                                                _.map(
                                                    campaignForm.draft.iab_categories,
                                                    iabCategory => (
                                                        <Label key={iabCategory}>
                                                            {IABCategories[iabCategory]}
                                                        </Label>
                                                    )
                                                )
                                            ) : (
                                                <Label placeholder>
                                                    Select Advertiser Category
                                                </Label>
                                            )
                                        }
                                        helper={
                                            <React.Fragment>
                                                <span>
                                                    Select all IAB categories relevant to the
                                                    Advertiser’s products or services. Restricted
                                                    content MUST be tagged appropriately:
                                                </span>{' '}
                                                <Link
                                                    target="newtab"
                                                    href="https://engagefront.notion.site/Content-Restrictions-5894e4d82a254d6f9e610e58f82b1bf7"
                                                >
                                                    Content Restrictions
                                                </Link>
                                            </React.Fragment>
                                        }
                                    />
                                )}
                                {_.includes(
                                    ['admin'],
                                    _.get(campaignForm.ownOrganization, 'type')
                                ) &&
                                    isInternalUser() && (
                                        <AccordionItem
                                            onClick={this.expandPanel('salesReps')}
                                            expanded={this.state.expandedPanel === 'salesReps'}
                                            title="Sales Reps"
                                            details={
                                                <MultiSelect
                                                    value={_.map(
                                                        campaignForm.clientGrouping.sales_rep,
                                                        c => c.organization
                                                    )}
                                                    options={campaignForm.salesReps}
                                                    onChange={clients =>
                                                        this.handleClientChange(
                                                            clients,
                                                            'sales_rep'
                                                        )
                                                    }
                                                    placeholder="Sales Reps"
                                                />
                                            }
                                            summary={
                                                campaignForm.clientGrouping.sales_rep ? (
                                                    _.map(
                                                        campaignForm.clientGrouping.sales_rep,
                                                        salesRep => {
                                                            return (
                                                                <Label key={salesRep.organization}>
                                                                    {salesRepsMappingMapping[
                                                                        salesRep.organization
                                                                    ] &&
                                                                        salesRepsMappingMapping[
                                                                            salesRep.organization
                                                                        ].label}
                                                                </Label>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <Label placeholder>Select Sales Reps</Label>
                                                )
                                            }
                                        />
                                    )}
                                <Spacer />
                                <Typography>Budget & Billing</Typography>
                                <Spacer />
                                {fieldVisibility.currency && (
                                    <AccordionItem
                                        onClick={this.expandPanel('currency')}
                                        expanded={this.state.expandedPanel === 'currency'}
                                        title="Currency"
                                        details={
                                            <Grid item xs={1}>
                                                <SimpleSelect
                                                    value={campaignForm.draft.currency}
                                                    onChange={e => {
                                                        onCampaignFieldChange('currency')(
                                                            e.target.value
                                                        );
                                                    }}
                                                    options={environmentSettings.getCurrencyOptions()}
                                                    disabled={type === 'edit'}
                                                    placeholder="Select currency"
                                                />
                                            </Grid>
                                        }
                                        helper="This cannot be changed once saved"
                                        summary={<Label>{campaignForm.draft.currency}</Label>}
                                    />
                                )}
                                <AccordionItem
                                    required
                                    onClick={this.expandPanel('revenueModel')}
                                    expanded={this.state.expandedPanel === 'revenueModel'}
                                    error={
                                        campaignForm.errors.revenueModel && campaignForm.showErrors
                                    }
                                    title="Revenue Model"
                                    details={
                                        <Grid item xs={2}>
                                            <SingleSelect
                                                value={campaignForm.draft.revenueModel}
                                                onChange={this.handleRevenueModelChange}
                                                options={getRevenueModelOptions(
                                                    campaignForm.draft.type
                                                )}
                                                disabled={type === 'edit'}
                                                placeholder="Select revenue model"
                                                error={
                                                    campaignForm.showErrors &&
                                                    !!campaignForm.errors.revenueModel
                                                }
                                            />
                                        </Grid>
                                    }
                                    summary={
                                        campaignForm.draft.revenueModel ? (
                                            <Label>
                                                {
                                                    revenueModelMapping[
                                                        campaignForm.draft.revenueModel
                                                    ].label
                                                }
                                            </Label>
                                        ) : (
                                            <Label placeholder>Select Revenue Model</Label>
                                        )
                                    }
                                />
                                {campaignForm.draft.revenueModel ===
                                    RevenueModelMapping.AgencyMargin && (
                                    <AccordionItem
                                        required
                                        onClick={this.expandPanel(RevenueModelMapping.AgencyMargin)}
                                        expanded={
                                            this.state.expandedPanel ===
                                            RevenueModelMapping.AgencyMargin
                                        }
                                        title="Agency Margin"
                                        error={
                                            campaignForm.errors.billing_rate &&
                                            campaignForm.showErrors
                                        }
                                        incomplete={
                                            !campaignForm.showErrors &&
                                            !campaignForm.draft.billing_rate
                                        }
                                        details={
                                            <Grid item xs={1}>
                                                <PrefixSuffixInput
                                                    suffix="%"
                                                    formatIn={formatMarginValueToString}
                                                    formatOut={formatMarginStringToValue}
                                                    shouldAllowInput={
                                                        shouldAllowInputForBillingRate
                                                    }
                                                    onChange={onBillingRateChange}
                                                    value={campaignForm.draft.billing_rate}
                                                />
                                            </Grid>
                                        }
                                        summary={
                                            campaignForm.draft.billing_rate ? (
                                                <Label>
                                                    {formatNumber_percentage(
                                                        campaignForm.draft.billing_rate
                                                    )}
                                                </Label>
                                            ) : (
                                                <Label placeholder>
                                                    {formatNumber_percentage(0)}
                                                </Label>
                                            )
                                        }
                                    />
                                )}
                                {showBudgetOptimizationField && (
                                    <AccordionItem
                                        onClick={this.expandPanel('budgetAllocation')}
                                        expanded={this.state.expandedPanel === 'budgetAllocation'}
                                        readOnly={
                                            type === 'edit' &&
                                            campaignForm.draft.flightPacingStrategy === 'campaign'
                                        }
                                        title="Budget & Pacing"
                                        details={
                                            <FormControl
                                                component="fieldset"
                                                required
                                                className={classes.formControl}
                                            >
                                                <RadioGroup
                                                    name="budget_allocation"
                                                    value={
                                                        campaignForm.draft.budget_allocation_method
                                                    }
                                                    onChange={e =>
                                                        this.handleBudgetAllocationChange(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value="manual"
                                                        control={<Radio color="primary" />}
                                                        label="Manual - control budget and pacing at the ad level"
                                                        disabled={
                                                            campaignForm.draft
                                                                .flightPacingStrategy ===
                                                                'campaign' ||
                                                            campaignForm.draft.type ===
                                                                CampaignTypeMapping.DOOH
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        value="auto"
                                                        control={<Radio color="primary" />}
                                                        label="Automatic - set budget and pacing at the campaign level"
                                                        disabled={
                                                            campaignForm.draft
                                                                .flightPacingStrategy ===
                                                                'campaign' ||
                                                            campaignForm.draft.type ===
                                                                CampaignTypeMapping.DOOH
                                                        }
                                                    />
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                        gutterBottom
                                                        className={
                                                            classes.budgetAllocationDescription
                                                        }
                                                    >
                                                        {campaignForm.draft.type ===
                                                        CampaignTypeMapping.DOOH
                                                            ? 'Automatic budget allocation is not supported in Digital Out-Of-Home campaigns'
                                                            : 'EngageFront will automatically distribute the campaign budget towards the best performing ads, given the absence of inventory limitations.'}
                                                    </Typography>
                                                </RadioGroup>
                                                {campaignForm.draft.budget_allocation_method ===
                                                    'auto' && (
                                                    <AutomaticBudgetAllocationMethod
                                                        campaignForm={campaignForm}
                                                        updateDraft={this.updateDraft}
                                                        classes={classes}
                                                    />
                                                )}
                                                {campaignForm.draft.budget_allocation_method ===
                                                    'auto' &&
                                                    [
                                                        RevenueModelMapping.CPMCPC,
                                                        RevenueModelMapping.CPM,
                                                    ].includes(campaignForm.draft.revenueModel) && (
                                                        <CampaignInfoBanner content="When automatic budget allocation is used, all ads will use the CPM revenue model and will be paced by impressions" />
                                                    )}
                                            </FormControl>
                                        }
                                        summary={
                                            <AutomaticBudgetAllocationMethodSummary
                                                budgetAllocationMethod={
                                                    campaignForm.draft.budget_allocation_method
                                                }
                                                automaticBudgetAllocationOptimizationStrategy={
                                                    campaignForm.draft
                                                        .automaticBudgetAllocationOptimizationStrategy
                                                }
                                                budgetAllocationMapping={budgetAllocationMapping}
                                            />
                                        }
                                    />
                                )}
                                {campaignForm.draft.revenueModel ===
                                    RevenueModelMapping.AgencyMargin &&
                                    campaignForm.draft.flightPacingStrategy !== 'campaign' && (
                                        <AccordionItem
                                            required
                                            onClick={this.expandPanel('revenueBudget')}
                                            expanded={this.state.expandedPanel === 'revenueBudget'}
                                            title="Revenue Budget"
                                            error={
                                                campaignForm.errors.total_billings_local &&
                                                campaignForm.showErrors
                                            }
                                            incomplete={
                                                !campaignForm.showErrors &&
                                                !campaignForm.draft.total_billings_local
                                            }
                                            details={
                                                <Grid item xs={2}>
                                                    <RevenueBudget
                                                        disableMargin
                                                        error={
                                                            campaignForm.errors
                                                                .total_billings_local &&
                                                            campaignForm.showErrors
                                                        }
                                                        value={
                                                            campaignForm.draft.total_billings_local
                                                        }
                                                        onChange={onTotalBillingsChange}
                                                        startAdornment={`${
                                                            campaignForm.draft.currency
                                                        } $`}
                                                    />
                                                    <Spacer type="small" />
                                                    <Typography color="textSecondary">
                                                        Total Cost Budget
                                                    </Typography>
                                                    <Label>
                                                        {`${
                                                            campaignForm.draft.currency
                                                        } ${formatNumber_currency(
                                                            campaignForm.draft
                                                                .campaign_max_total_spend_local
                                                        )}`}
                                                    </Label>
                                                </Grid>
                                            }
                                            summary={
                                                campaignForm.draft.total_billings_local ? (
                                                    <div>
                                                        <Label>{`${
                                                            campaignForm.draft.currency
                                                        } ${formatNumber_currency(
                                                            campaignForm.draft.total_billings_local
                                                        )}`}</Label>
                                                        <Spacer type="small" />
                                                        <Typography color="textSecondary">
                                                            Total Cost Budget
                                                        </Typography>
                                                        <Label>
                                                            {`${
                                                                campaignForm.draft.currency
                                                            } ${formatNumber_currency(
                                                                campaignForm.draft
                                                                    .campaign_max_total_spend_local
                                                            )}`}
                                                        </Label>
                                                    </div>
                                                ) : (
                                                    <Label placeholder>{`${
                                                        campaignForm.draft.currency
                                                    } ${formatNumber_currency(0)}`}</Label>
                                                )
                                            }
                                            helper="Enter in your total client spend. EngageFront will deduct your Agency Margin to calculate the Total Cost Budget used for media costs, data costs and non-media fees."
                                        />
                                    )}
                                {campaignForm.draft.budget_allocation_method === 'auto' && (
                                    <AccordionItem
                                        onClick={this.expandPanel('flights')}
                                        expanded={this.state.expandedPanel === 'flights'}
                                        error={
                                            campaignForm.showErrors &&
                                            campaignForm.errors &&
                                            !!Object.keys(campaignForm.errors).find(
                                                error => error.indexOf('flight') !== -1
                                            )
                                        }
                                        title="Flights"
                                        helper={
                                            <React.Fragment>
                                                <Link
                                                    target="newtab"
                                                    href="https://engagefront.notion.site/Campaigns-now-support-multiple-flights-dd525a8ea35e41a996be00585de34456"
                                                >
                                                    Learn more
                                                </Link>
                                            </React.Fragment>
                                        }
                                        details={
                                            <React.Fragment>
                                                <FormControl component="fieldset" required>
                                                    <RadioGroup
                                                        name="flights"
                                                        value={
                                                            campaignForm.draft.flightPacingStrategy
                                                        }
                                                        onChange={e =>
                                                            campaignForm.changeFlightsSelection(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            value="ad"
                                                            control={<Radio color="primary" />}
                                                            label="Set ad flights individually"
                                                            disabled={type === 'edit'}
                                                        />
                                                        <FormControlLabel
                                                            value="campaign"
                                                            control={<Radio color="primary" />}
                                                            label="Set flights at the campaign level"
                                                            disabled={
                                                                type === 'edit' ||
                                                                campaignForm.draft.fta_enabled
                                                            }
                                                        />
                                                        {campaignForm.draft.flightPacingStrategy ===
                                                            'campaign' && (
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2"
                                                                gutterBottom
                                                                className={
                                                                    classes.budgetAllocationDescription
                                                                }
                                                            >
                                                                Ads will automatically use these
                                                                flight dates.
                                                            </Typography>
                                                        )}
                                                        {campaignForm.draft.fta_enabled && (
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2"
                                                                gutterBottom
                                                                className={
                                                                    classes.budgetAllocationDescription
                                                                }
                                                            >
                                                                Flights cannot be set at the
                                                                campaign level when Foot Traffic
                                                                Attribution is enabled.
                                                            </Typography>
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </React.Fragment>
                                        }
                                        extension={
                                            campaignForm.draft.flightPacingStrategy ===
                                            'campaign' ? (
                                                <React.Fragment>
                                                    <Separator />
                                                    <FlightsTable isEdit={type === 'edit'} />
                                                    {campaignForm.draft.flights &&
                                                        campaignForm.draft.flights.length > 1 && (
                                                            <CampaignOverall />
                                                        )}
                                                    {campaignForm.errors.flights && (
                                                        <Grid item>
                                                            <FormControl
                                                                error
                                                                className={
                                                                    classes.totalCostBudgetField
                                                                }
                                                            >
                                                                <FormHelperText>
                                                                    {campaignForm.errors.flights}
                                                                </FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    )}
                                                </React.Fragment>
                                            ) : null
                                        }
                                        summary={
                                            <div>
                                                <Label>
                                                    {campaignForm.draft.flightPacingStrategy ===
                                                    'campaign'
                                                        ? 'Flights set at campaign level'
                                                        : 'Flights set at ad level'}
                                                </Label>
                                            </div>
                                        }
                                    />
                                )}
                                {campaignForm.draft.revenueModel ===
                                    RevenueModelMapping.AgencyMargin &&
                                    campaignForm.draft.flightPacingStrategy === 'campaign' && (
                                        <AccordionItem
                                            onClick={this.expandPanel('revenueBudget')}
                                            expanded={this.state.expandedPanel === 'revenueBudget'}
                                            title="Revenue Budget"
                                            readOnly
                                            summary={
                                                campaignForm.draft.total_billings_local ? (
                                                    <div>
                                                        <Label>{`${
                                                            campaignForm.draft.currency
                                                        } ${formatNumber_currency(
                                                            campaignForm.draft.total_billings_local
                                                        )}`}</Label>
                                                        <Spacer type="small" />
                                                        <Typography color="textSecondary">
                                                            Total Cost Budget
                                                        </Typography>
                                                        <Label>
                                                            {`${
                                                                campaignForm.draft.currency
                                                            } ${formatNumber_currency(
                                                                campaignForm.draft
                                                                    .campaign_max_total_spend_local
                                                            )}`}
                                                        </Label>
                                                    </div>
                                                ) : (
                                                    <Label placeholder>{`${
                                                        campaignForm.draft.currency
                                                    } ${formatNumber_currency(0)}`}</Label>
                                                )
                                            }
                                        />
                                    )}
                                {[RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
                                    campaignForm.draft.revenueModel
                                ) &&
                                    campaignForm.draft.budget_allocation_method === 'auto' && (
                                        <AccordionItem
                                            required
                                            onClick={this.expandPanel('totalImpressions')}
                                            expanded={
                                                this.state.expandedPanel === 'totalImpressions'
                                            }
                                            title="Total Impressions"
                                            error={
                                                campaignForm.errors
                                                    .campaign_max_total_impressions &&
                                                campaignForm.showErrors
                                            }
                                            incomplete={
                                                !campaignForm.showErrors &&
                                                !campaignForm.draft.campaign_max_total_impressions
                                            }
                                            readOnly={
                                                campaignForm.draft.flightPacingStrategy ===
                                                'campaign'
                                            }
                                            details={
                                                <Grid item xs={2}>
                                                    <TotalImpressions
                                                        error={
                                                            campaignForm.errors
                                                                .campaign_max_total_impressions
                                                        }
                                                        value={
                                                            campaignForm.draft
                                                                .campaign_max_total_impressions
                                                        }
                                                        showErrors={campaignForm.showErrors}
                                                        onChange={value => {
                                                            this.updateDraft({
                                                                campaign_max_total_impressions: value,
                                                            });
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            summary={
                                                campaignForm.draft
                                                    .campaign_max_total_impressions ? (
                                                    <div>
                                                        <Label>
                                                            {
                                                                campaignForm.draft
                                                                    .campaign_max_total_impressions
                                                            }
                                                        </Label>
                                                    </div>
                                                ) : (
                                                    0
                                                )
                                            }
                                        />
                                    )}
                                {campaignForm.draft.revenueModel !== null &&
                                    campaignForm.draft.revenueModel !==
                                        RevenueModelMapping.AgencyMargin && (
                                        <AccordionItem
                                            onClick={this.expandPanel('totalCostBudget')}
                                            expanded={
                                                this.state.expandedPanel === 'totalCostBudget'
                                            }
                                            title="Total Cost Budget"
                                            readOnly={
                                                campaignForm.draft.flightPacingStrategy ===
                                                    'campaign' &&
                                                ![
                                                    RevenueModelMapping.CPMCPC,
                                                    RevenueModelMapping.CPM,
                                                ].includes(campaignForm.draft.revenueModel)
                                            }
                                            error={
                                                campaignForm.errors
                                                    .campaign_max_total_spend_local &&
                                                campaignForm.showErrors
                                            }
                                            required={
                                                campaignForm.useDisabledRevenueModelAndAutoBudgetAllocation
                                            }
                                            details={
                                                campaignForm.draft.revenueModel ===
                                                RevenueModelMapping.AgencyMargin ? (
                                                    <Label>
                                                        {
                                                            campaignForm.draft
                                                                .campaign_max_total_spend_local
                                                        }
                                                    </Label>
                                                ) : (
                                                    <Grid item xs={3}>
                                                        <TotalCostBudget
                                                            key={
                                                                campaignForm.draft
                                                                    .total_billings_local
                                                            }
                                                            useDisabledRevenueModelAndAutoBudgetAllocation={
                                                                campaignForm.useDisabledRevenueModelAndAutoBudgetAllocation
                                                            }
                                                            disableMargin
                                                            error={
                                                                campaignForm.errors
                                                                    .campaign_max_total_spend_local &&
                                                                campaignForm.showErrors
                                                            }
                                                            errorMessage={
                                                                campaignForm.errors
                                                                    .campaign_max_total_spend_local
                                                            }
                                                            value={
                                                                campaignForm.draft
                                                                    .campaign_max_total_spend_local
                                                            }
                                                            onChange={campaign_max_total_spend_local =>
                                                                this.updateDraft({
                                                                    campaign_max_total_spend_local,
                                                                })
                                                            }
                                                            startAdornment={`${
                                                                campaignForm.draft.currency
                                                            } $`}
                                                        />
                                                    </Grid>
                                                )
                                            }
                                            summary={
                                                campaignForm.draft
                                                    .campaign_max_total_spend_local === 0 ? (
                                                    <Label>Unlimited</Label>
                                                ) : (
                                                    <Label>
                                                        {`${
                                                            campaignForm.draft.currency
                                                        } ${formatNumber_currency(
                                                            campaignForm.draft
                                                                .campaign_max_total_spend_local
                                                        )}`}
                                                    </Label>
                                                )
                                            }
                                            helper="Total Cost comprises all campaign costs to you — including media costs, data costs, and third party fees."
                                        />
                                    )}
                                {campaignForm.draft.budget_allocation_method === 'auto' && (
                                    <AccordionItem
                                        onClick={this.expandPanel('use_front_load_pacing')}
                                        expanded={
                                            this.state.expandedPanel === 'use_front_load_pacing'
                                        }
                                        title="Pacing Strategy"
                                        details={
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="use_front_load_pacing"
                                                    name="use_front_load_pacing"
                                                    value={campaignForm.draft.use_front_load_pacing}
                                                    onChange={e =>
                                                        this.handlePacingStrategy(e.target.value)
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio color="primary" />}
                                                        label="Even Pacing"
                                                    />
                                                    <OptionCaption
                                                        variant="body2"
                                                        paragraph
                                                        color="textSecondary"
                                                    >
                                                        System will evenly pace the ad line to
                                                        deliver the same daily goal – either spend,
                                                        impressions or clicks – throughout the
                                                        duration of the ad line flight.
                                                    </OptionCaption>
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio color="primary" />}
                                                        label="Front-loaded pacing"
                                                    />
                                                    <OptionCaption
                                                        variant="body2"
                                                        paragraph
                                                        color="textSecondary"
                                                    >
                                                        System will frontload the ad line to deliver
                                                        50% of the total goal – either spend,
                                                        impression or clicks – within the first
                                                        third of the ad line flight. Use this method
                                                        to mitigate under delivery risk.
                                                    </OptionCaption>
                                                </RadioGroup>
                                            </FormControl>
                                        }
                                        summary={
                                            <Label>
                                                {campaignForm.draft.use_front_load_pacing
                                                    ? 'Front-loaded pacing'
                                                    : 'Even Pacing'}
                                            </Label>
                                        }
                                    />
                                )}
                                {shouldShowThirdPartyFees && campaignForm.draft.currency && (
                                    <AccordionItem
                                        onClick={this.expandPanel('thirdPartyFees')}
                                        expanded={this.state.expandedPanel === 'thirdPartyFees'}
                                        title="Default Third Party Fees"
                                        error={
                                            campaignForm.showErrors &&
                                            campaignForm.thirdPartyFeeErrors.length > 0
                                        }
                                        details={
                                            <FormField
                                                label="Default Third Party Fees"
                                                errors={campaignForm.thirdPartyFeeErrors}
                                                showErrors={campaignForm.showErrors}
                                                isRequired={false}
                                            >
                                                <ThirdPartyFees
                                                    currency={campaignForm.draft.currency}
                                                    value={campaignForm.draft.third_party_fees}
                                                    onChange={onCampaignFieldChange(
                                                        'third_party_fees'
                                                    )}
                                                />
                                            </FormField>
                                        }
                                        helper="Set the default value for Third Party Fees for new ads. To modify existing ads, use the Bulk Edit feature under Overview. Changes to this field are not applied retroactively and only take effect for new impressions moving forward."
                                        summary={
                                            campaignForm.draft.third_party_fees.length > 0 ? (
                                                _.map(
                                                    campaignForm.draft.third_party_fees,
                                                    (thirdPartyFee, index) => {
                                                        return thirdPartyFee.description &&
                                                            thirdPartyFee.fee ? (
                                                            <div
                                                                key={
                                                                    thirdPartyFee.description +
                                                                    index
                                                                }
                                                            >
                                                                <Label>
                                                                    {thirdPartyFee.description}
                                                                </Label>
                                                                <Label>{`${
                                                                    campaignForm.draft.currency
                                                                } ${formatNumber_currency(
                                                                    thirdPartyFee.fee
                                                                )} CPM`}</Label>
                                                                <Spacer type="small" />
                                                            </div>
                                                        ) : (
                                                            <Typography color="error">
                                                                Incomplete fields
                                                            </Typography>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <Label placeholder>Add Third Party Fees</Label>
                                            )
                                        }
                                    />
                                )}
                                {environmentSettings.canUseFta() && campaignForm.showFta && (
                                    <React.Fragment>
                                        <Spacer />
                                        <Typography>Foot Traffic Attribution</Typography>
                                        <Spacer />
                                        <AccordionItem
                                            onClick={this.expandPanel('footTrafficAttribution')}
                                            expanded={
                                                this.state.expandedPanel ===
                                                'footTrafficAttribution'
                                            }
                                            title="Foot Traffic Attribution"
                                            error={
                                                campaignForm.showErrors &&
                                                (campaignForm.errors.fta_location_list ||
                                                    campaignForm.errors.fta_management_level)
                                            }
                                            details={
                                                <FootTrafficAttribution
                                                    ftaEnabled={campaignForm.draft.fta_enabled}
                                                    ftaManagementLevel={
                                                        campaignForm.draft.fta_management_level
                                                    }
                                                    ftaLocationList={
                                                        campaignForm.draft.fta_location_list
                                                    }
                                                    onChange={this.updateDraft}
                                                    locationListOptions={_.map(
                                                        this.props.campaignForm.ftaLocationLists,
                                                        locationList => ({
                                                            value: locationList.id,
                                                            label: locationList.name,
                                                            status: locationList.status,
                                                        })
                                                    )}
                                                    showErrors={campaignForm.showErrors}
                                                    ftaLocationListError={
                                                        campaignForm.errors.fta_location_list
                                                    }
                                                    ftaManagementLevelError={
                                                        campaignForm.errors.fta_management_level
                                                    }
                                                    campaignType={campaignForm.draft.type}
                                                />
                                            }
                                            summary={
                                                <React.Fragment>
                                                    {campaignForm.draft.fta_enabled &&
                                                        !campaignForm.draft
                                                            .fta_management_level && (
                                                            <Typography color="error">
                                                                Incomplete fields
                                                            </Typography>
                                                        )}
                                                    {campaignForm.draft.fta_enabled &&
                                                        campaignForm.draft.fta_management_level ===
                                                            'campaign' &&
                                                        !campaignForm.draft.fta_location_list && (
                                                            <Typography color="error">
                                                                Incomplete fields
                                                            </Typography>
                                                        )}
                                                    {campaignForm.draft.fta_enabled &&
                                                        campaignForm.draft.fta_management_level ===
                                                            'ad' && <Label>Enabled per ad</Label>}
                                                    {campaignForm.draft.fta_enabled &&
                                                        campaignForm.draft.fta_management_level ===
                                                            'campaign' &&
                                                        campaignForm.draft.fta_location_list && (
                                                            <div>
                                                                <Label>Enabled for all ads</Label>
                                                                <Typography color="textSecondary">
                                                                    Location List
                                                                </Typography>
                                                                <Label>
                                                                    {_.get(
                                                                        locationListsMapping,
                                                                        `${
                                                                            campaignForm.draft
                                                                                .fta_location_list
                                                                        }.name`
                                                                    )}
                                                                </Label>
                                                            </div>
                                                        )}
                                                    {!campaignForm.draft.fta_enabled && (
                                                        <Label>Disabled</Label>
                                                    )}
                                                </React.Fragment>
                                            }
                                            helper="Use first-party SDK location data to measure foot traffic and store visits at real-world locations."
                                        />
                                        {campaignForm.showFtaFullyManagedFields && (
                                            <AccordionItem
                                                onClick={this.expandPanel('partnerId')}
                                                expanded={this.state.expandedPanel === 'partnerId'}
                                                title="Partner ID"
                                                details={
                                                    <Grid container spacing={0} direction="column">
                                                        <Grid item xs={4}>
                                                            <TextFieldFormatter
                                                                initialValue={
                                                                    campaignForm.draft
                                                                        .fta_partner_id
                                                                }
                                                                onChange={
                                                                    this.handlePartnerIdChange
                                                                }
                                                                shouldAllowChange={v =>
                                                                    !/[a-zA-Z]/.test(v)
                                                                }
                                                                renderInput={({
                                                                    value,
                                                                    onChange,
                                                                }) => (
                                                                    <div>
                                                                        <Label>Partner ID</Label>
                                                                        <TextField
                                                                            disableMargin
                                                                            required
                                                                            fullWidth
                                                                            value={value}
                                                                            onChange={onChange}
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                }
                                                summary={
                                                    <Label>
                                                        {campaignForm.draft.fta_partner_id}
                                                    </Label>
                                                }
                                            />
                                        )}
                                        {campaignForm.showFtaFullyManagedFields && (
                                            <AccordionItem
                                                onClick={this.expandPanel(
                                                    'conversionLookbackWindow'
                                                )}
                                                expanded={
                                                    this.state.expandedPanel ===
                                                    'conversionLookbackWindow'
                                                }
                                                title="Lookback Window"
                                                details={
                                                    <ConversionLookBackWindowForm
                                                        value={
                                                            campaignForm.draft
                                                                .fta_conversion_lookback_window
                                                        }
                                                        onChange={
                                                            this
                                                                .handleFTAConversionLookBackWindowChange
                                                        }
                                                        showErrors={campaignForm.showErrors}
                                                        lookBackWindowDaysError={
                                                            campaignForm.errors &&
                                                            campaignForm.errors
                                                                .fta_conversion_lookback_window
                                                        }
                                                    />
                                                }
                                                summary={
                                                    <ConversionLookBackWindowSummary
                                                        data={
                                                            campaignForm.draft
                                                                .fta_conversion_lookback_window
                                                        }
                                                    />
                                                }
                                                helper="Maximum number of days between an impression and a location visit. Users visiting a location after the lookback window is over will not be counted in the ad-exposed group."
                                            />
                                        )}
                                        {(campaignForm.showFtaFullyManagedFields ||
                                            campaignForm.showFtaLimitedFields) && (
                                            <AccordionItem
                                                onClick={this.expandPanel('campaignInterval')}
                                                expanded={
                                                    this.state.expandedPanel === 'campaignInterval'
                                                }
                                                title="Analysis Window"
                                                error={
                                                    (campaignForm.errors
                                                        .fta_pre_campaign_interval_days ||
                                                        campaignForm.errors
                                                            .fta_post_campaign_interval_days) &&
                                                    campaignForm.showErrors
                                                }
                                                details={
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                        direction="column"
                                                        className={classes.analysisWindowControl}
                                                    >
                                                        <Grid item xs={10}>
                                                            <Box>
                                                                <Alert severity="info">
                                                                    For best results, set the
                                                                    pre-campaign interval and
                                                                    post-campaign interval to the
                                                                    same number of days
                                                                </Alert>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextFieldFormatter
                                                                initialValue={
                                                                    campaignForm.draft
                                                                        .fta_pre_campaign_interval_days
                                                                }
                                                                onChange={
                                                                    this.handlePreIntervalChange
                                                                }
                                                                shouldAllowChange={v =>
                                                                    !/[a-zA-Z]/.test(v)
                                                                }
                                                                formatIn={v => {
                                                                    return numeral(v).format('0');
                                                                }}
                                                                formatOut={v => {
                                                                    return v
                                                                        ? numeral(v).value()
                                                                        : 0;
                                                                }}
                                                                renderInput={({
                                                                    value,
                                                                    onChange,
                                                                }) => (
                                                                    <div>
                                                                        <Label>
                                                                            Pre-Campaign Analysis
                                                                            Window
                                                                        </Label>
                                                                        <TextField
                                                                            disableMargin
                                                                            required
                                                                            fullWidth
                                                                            value={value}
                                                                            onChange={onChange}
                                                                            endAdornment={
                                                                                <div>days</div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2"
                                                            >
                                                                Maximum: 90 days
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Label>
                                                                Post-Campaign Analysis Window
                                                            </Label>
                                                            <TextFieldFormatter
                                                                initialValue={
                                                                    campaignForm.draft
                                                                        .fta_post_campaign_interval_days
                                                                }
                                                                onChange={
                                                                    this.handlePostIntervalChange
                                                                }
                                                                shouldAllowChange={v =>
                                                                    !/[a-zA-Z]/.test(v)
                                                                }
                                                                formatIn={v => {
                                                                    return numeral(v).format('0');
                                                                }}
                                                                formatOut={v => {
                                                                    return v
                                                                        ? numeral(v).value()
                                                                        : 0;
                                                                }}
                                                                error={
                                                                    campaignForm.errors
                                                                        .fta_post_campaign_interval_days
                                                                }
                                                                renderInput={({
                                                                    value,
                                                                    onChange,
                                                                }) => (
                                                                    <TextField
                                                                        disableMargin
                                                                        required
                                                                        fullWidth
                                                                        value={value}
                                                                        onChange={onChange}
                                                                        endAdornment={
                                                                            <div>days</div>
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2"
                                                            >
                                                                Maximum: 90 days
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                summary={
                                                    <Grid>
                                                        <Label>Pre-campaign Interval</Label>
                                                        <Label>
                                                            {
                                                                campaignForm.draft
                                                                    .fta_pre_campaign_interval_days
                                                            }{' '}
                                                            days
                                                        </Label>
                                                        <Label>Post-campaign Interval</Label>
                                                        <Label>
                                                            {
                                                                campaignForm.draft
                                                                    .fta_post_campaign_interval_days
                                                            }{' '}
                                                            days
                                                        </Label>
                                                    </Grid>
                                                }
                                                helper={
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                color="textPrimary"
                                                                variant="caption"
                                                            >
                                                                Pre-Campaign Analysis Window
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="caption"
                                                            >
                                                                Number of days before the campaign
                                                                begins to start foot traffic
                                                                analysis. Data collected in this
                                                                period will be used as a baseline to
                                                                calculate lift.
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                color="textPrimary"
                                                                variant="caption"
                                                            >
                                                                Post-Campaign Analysis Window
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="caption"
                                                            >
                                                                Number of days after the campaign
                                                                ends to continue foot traffic
                                                                analysis. Users visiting a location
                                                                after this date will not be counted.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        )}
                                        {campaignForm.showFtaFullyManagedFields &&
                                            campaignForm.draft.fta_lift_version && (
                                                <AccordionItem
                                                    onClick={this.expandPanel('liftVersion')}
                                                    expanded={
                                                        this.state.expandedPanel === 'liftVersion'
                                                    }
                                                    title="Lift Version"
                                                    error={
                                                        campaignForm.errors.fta_lift_version &&
                                                        campaignForm.showErrors
                                                    }
                                                    details={
                                                        <Grid item xs={3}>
                                                            <FormControl
                                                                component="fieldset"
                                                                required
                                                                onChange={e =>
                                                                    this.handleLiftVersionChange(
                                                                        e.target.value
                                                                    )
                                                                }
                                                            >
                                                                <RadioGroup
                                                                    name="total_cost_budget_options"
                                                                    value={
                                                                        campaignForm.draft
                                                                            .fta_lift_version
                                                                    }
                                                                >
                                                                    <FormControlLabel
                                                                        value="standard"
                                                                        control={
                                                                            <Radio color="primary" />
                                                                        }
                                                                        label="Standard"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="lookalike"
                                                                        control={
                                                                            <Radio color="primary" />
                                                                        }
                                                                        label="Lookalike"
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    summary={
                                                        <Label>
                                                            {campaignForm.draft.fta_lift_version ===
                                                            'standard'
                                                                ? 'Standard'
                                                                : 'Lookalike'}
                                                        </Label>
                                                    }
                                                />
                                            )}
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                </DialogContent>
                {!campaignForm.isLoading && (
                    <DialogActions>
                        <Button
                            disabled={campaignForm.isSubmitting}
                            variant="contained"
                            autoFocus
                            onClick={this.submitCampaign}
                            color="primary"
                        >
                            Save
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}

const useStyles = makeStyles({
    noWrapTextField: {
        whiteSpace: 'nowrap',
    },
    totalCostBudgetField: {
        marginLeft: 28,
    },
});

function CampaignName() {
    const { errors, draft, changeName, showErrors } = useCampaignForm();

    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            changeName(fieldValue);
        }, 500);
    }, []);

    return (
        <TextField
            disableMargin
            error={showErrors ? !!errors.name : null}
            defaultValue={draft.name}
            onChange={e => handleChange(e.target.value)}
            placeholder="Name"
            fullWidth
        />
    );
}

function CustomFieldTextField(props) {
    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            props.onChange(fieldValue);
        }, 500);
    }, []);

    return (
        <TextField
            disableMargin
            error={props.error}
            errorText={props.error}
            defaultValue={props.value}
            onChange={e => handleChange(e.target.value)}
            placeholder={props.placeholder}
        />
    );
}

function Notes(props) {
    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            props.onChange(fieldValue);
        }, 500);
    }, []);

    return (
        <TextField
            disableMargin
            error={props.error}
            defaultValue={props.value}
            onChange={e => handleChange(e.target.value)}
            multiline
            placeholder="Notes"
            fullWidth
        />
    );
}

function AdvertiserDomain(props) {
    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            props.onChange(fieldValue);
        }, 500);
    }, []);

    return (
        <TextField
            disableMargin
            error={props.error}
            errorText={props.errorText}
            defaultValue={props.value}
            onChange={e => handleChange(e.target.value)}
            placeholder="brand.com"
        />
    );
}

function TotalCostBudget(props) {
    const [option, setValue] = useState(
        props.value === 0 && !props.useDisabledRevenueModelAndAutoBudgetAllocation
            ? 'unlimited'
            : 'fixed'
    );
    const classes = useStyles();

    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            props.onChange(fieldValue);
        }, 500);
    }, []);

    const handleOption = event => {
        setValue(event.target.value);

        if (event.target.value === 'unlimited') {
            handleChange(0);
        }
    };

    return (
        <React.Fragment>
            <FormControl component="fieldset" required onChange={handleOption}>
                <RadioGroup name="total_cost_budget_options" value={option}>
                    <FormControlLabel
                        value="unlimited"
                        disabled={props.useDisabledRevenueModelAndAutoBudgetAllocation}
                        control={<Radio color="primary" />}
                        label="Unlimited"
                    />
                    <FormControlLabel
                        value="fixed"
                        control={<Radio color="primary" />}
                        label="Fixed Budget"
                    />
                </RadioGroup>
            </FormControl>
            {option === 'fixed' && (
                <Grid container>
                    <Grid item>
                        <TextFieldFormatter
                            initialValue={props.value}
                            onChange={value => handleChange(value)}
                            shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                            formatIn={v => {
                                if (v === 0) {
                                    return '';
                                }

                                return numeral(v).format('0,0.00');
                            }}
                            formatOut={v => {
                                return v ? numeral(v).value() : 0;
                            }}
                            renderInput={({ value, onChange, onBlur }) => (
                                <TextField
                                    disableMargin
                                    error={props.error}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    placeholder="0.00"
                                    startAdornment={
                                        <div className={classes.noWrapTextField}>
                                            {props.startAdornment}
                                        </div>
                                    }
                                    className={classes.totalCostBudgetField}
                                />
                            )}
                        />
                    </Grid>
                    {props.error && (
                        <Grid item>
                            <FormControl error className={classes.totalCostBudgetField}>
                                <FormHelperText>{props.errorMessage}</FormHelperText>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            )}
        </React.Fragment>
    );
}

function RevenueBudget(props) {
    const classes = useStyles();

    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            props.onChange(fieldValue);
        }, 500);
    }, []);

    return (
        <TextFieldFormatter
            initialValue={props.value}
            onChange={value => handleChange(value)}
            shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
            formatIn={v => numeral(v).format('0,0.00')}
            formatOut={v => numeral(v).value()}
            renderInput={({ value, onChange, onBlur }) => (
                <TextField
                    disableMargin
                    error={props.error}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="0.00"
                    startAdornment={
                        <div className={classes.noWrapTextField}>{props.startAdornment}</div>
                    }
                />
            )}
        />
    );
}

function TotalImpressions(props) {
    const handleChange = useMemo(() => {
        return _.debounce(fieldValue => {
            props.onChange(fieldValue);
        }, 500);
    }, []);

    return (
        <TextFieldInStrOutInt
            onChange={handleChange}
            value={props.value}
            error={props.showErrors && props.error}
            fullWidth
        />
    );
}

function recalculateMaxTotalSpendLocal(billings, term, rate) {
    if (term === 'billable_media_cost_markup') {
        return _.round(billings / (1 + rate), 2);
    } else {
        return _.round(billings * (1 - rate), 2);
    }
}

function shouldAllowInputForBillingRate(input) {
    // Allow empty input
    if (!input) {
        return true;
    }

    let parsedInput = parseInt(input);

    // Check allowable characters
    if (Number.isNaN(parsedInput)) {
        return false;
    }

    // Check character length
    return parsedInput < 100;
}

function formatMarginValueToString(value) {
    return value && value >= 0 ? `${(value * 100).toFixed(2)}` : '';
}

function formatMarginStringToValue(valueAsString) {
    return valueAsString === '' ? 0 : parseFloat(valueAsString) / 100;
}

function CampaignFormWrapper(props) {
    const campaignForm = useCampaignForm();

    if (!campaignForm.isModalOpen) {
        return null;
    }

    return <CampaignForm {...props} campaignForm={campaignForm} />;
}

const AutomaticBudgetAllocationMethod = ({ campaignForm, updateDraft, classes }) => {
    const optionsToOmit =
        campaignForm.draft?.type === CampaignTypeMapping.CTV ? ['CPA', 'CPC'] : ['CPCV'];
    const radioOptions = { ..._.omit(AUTOMATIC_BUDGET_ALLOCATION_OPTIONS, optionsToOmit) };
    return (
        <RadioGroup
            className={classes.automaticBudgetAllocationOptimizationStrategy}
            name="automatic_budget_allocation"
            value={campaignForm.draft.automaticBudgetAllocationOptimizationStrategy}
            onChange={e => {
                updateDraft({
                    automaticBudgetAllocationOptimizationStrategy: e.target.value,
                });
            }}
        >
            {Object.keys(radioOptions).map(optionKey => (
                <FormControlLabel
                    key={`${radioOptions[optionKey].value}`}
                    value={`${radioOptions[optionKey].value}`}
                    control={<Radio color="primary" />}
                    label={`${radioOptions[optionKey].label}`}
                />
            ))}
            {campaignForm.draft.type !== CampaignTypeMapping.CTV && (
                <Typography
                    color="textSecondary"
                    variant="body2"
                    gutterBottom
                    className={classes.budgetAllocationDescription}
                >
                    <React.Fragment>
                        <div>
                            Create pixels to track conversions under the Pixels tab. All campaign
                            pixels and associated advertiser pixels will be used for optimization by
                            default.
                        </div>
                    </React.Fragment>
                </Typography>
            )}
        </RadioGroup>
    );
};

export default withStyles(styles)(CampaignFormWrapper);

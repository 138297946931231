import _ from 'lodash';

import { draftDefaultValues } from './default-values';
import { creativeTypes, creativeSettings } from './setting';

// const creatives_test =  {
//     'standard': {
//         'margin_type': 'fixed',
//         'rates': [{
//             'size': '300x50',
//             'rate': 0
//         }, {
//             'size': '320x50',
//             'rate': 0
//         }, {
//             'size': '728x90',
//             'rate': 0
//         }, {
//             'size': '300x250',
//             'rate': 0
//         }, {
//             'size': '320x480',
//             'rate': 0
//         }, {
//             'size': '480x320',
//             'rate': 0
//         }, {
//             'size': '768x1024',
//             'rate': 0
//         }, {
//             'size': '1024x768',
//             'rate': 0
//         }],
//         'margin_rate': 0,
//         'allow_create': true,
//         'auto_approve': false
//     },
//     'video': {
//         'margin_type': 'fixed',
//         'rates': [{
//             'size': '320x480',
//             'rate': 0
//         }, {
//             'size': '480x320',
//             'rate': 0
//         }, {
//             'size': '768x1024',
//             'rate': 0
//         }, {
//             'size': '1024x768',
//             'rate': 0
//         }],
//         'margin_rate': 0,
//         'allow_create': true,
//         'auto_approve': false
//     },
//     'mraid': {
//         'margin_type': 'fixed',
//         'rates': [{
//             'size': '300x50',
//             'rate': 0
//         }, {
//             'size': '320x50',
//             'rate': 0
//         }, {
//             'size': '728x90',
//             'rate': 0
//         }, {
//             'size': '300x250',
//             'rate': 0
//         }, {
//             'size': '320x480',
//             'rate': 0
//         }, {
//             'size': '480x320',
//             'rate': 0
//         }, {
//             'size': '768x1024',
//             'rate': 0
//         }, {
//             'size': '1024x768',
//             'rate': 0
//         }],
//         'margin_rate': 0,
//         'allow_create': true,
//         'auto_approve': false
//     },
//     'custom_html': {
//         'margin_type': 'fixed',
//         'rates': [{
//             'size': '300x50',
//             'rate': 0
//         }, {
//             'size': '320x50',
//             'rate': 0
//         }, {
//             'size': '728x90',
//             'rate': 0
//         }, {
//             'size': '300x250',
//             'rate': 0
//         }, {
//             'size': '320x480',
//             'rate': 0
//         }, {
//             'size': '480x320',
//             'rate': 0
//         }, {
//             'size': '768x1024',
//             'rate': 0
//         }, {
//             'size': '1024x768',
//             'rate': 0
//         }],
//         'margin_rate': 0,
//         'allow_create': true,
//         'auto_approve': false
//     },
//     'native': {
//         'margin_type': 'fixed',
//         'rates': [{
//             'size': '0x0',
//             'rate': 0
//         }],
//         'margin_rate': 0,
//         'allow_create': true,
//         'auto_approve': false
//     },
// };

function getCreativeSetting(_creatives) {
    // creatives = creatives_test;
    const creatives = _creatives;

    const flatten = {};
    _.forEach(creatives, (v1, k1) => {
        if (_.includes(creativeTypes, k1)) {
            _.forEach(v1, (v2, k2) => {
                if (_.includes(creativeSettings, k2)) {
                    const key = 'creatives-' + k1 + '-' + k2;
                    flatten[key] = v2;
                }
            });
        }
    });
    return flatten;
}

function getDraft(org) {
    // ---------------------------------------------------------
    //
    // This module returns an object that will be use to populate
    // the fields in the draft of organization form
    //
    // Api values are transformed into the scheme required by draft.
    //
    // - key feature is: the nested creatives setting structure is
    //   flatten to looks like so:
    // {
    //    "name": "Root",
    //    "type": "admin",
    //    "credit_limit": -1,
    //    "auditors": [],
    //    "auditees": [],
    //    "creatives-standard-allow_create": true,
    //    "creatives-standard-auto_approve": true,
    //    "creatives-video-allow_create": true,
    //    "creatives-video-auto_approve": true,
    //    "creatives-mraid-allow_create": true,
    //    "creatives-mraid-auto_approve": true,
    //    "creatives-custom_html-allow_create": true,
    //    "creatives-custom_html-auto_approve": true,
    //    "creatives-native-allow_create": true,
    //    "creatives-native-auto_approve": true
    // }
    //
    // ---------------------------------------------------------
    const fields = [
        'name',
        'client_type',
        'type',
        'credit_limit',
        'tech_fee',
        'infraCostPercentage',
        'auditors',
        'auditees',
        'currency',
        'custom_fields',
        'revenueModel',
        'agencyMarginRate',
    ];

    const _draft = {
        ...draftDefaultValues,
        ..._.pick(org, fields),
        ...getCreativeSetting(_.pick(org, ['creatives']).creatives),
    };
    return _draft;
}

export default getDraft;

import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import flags from 'containers/flags/service';
import BaseColumnForm from '../base-column-form';

import Section from 'widgets-v5/section';

import formatGeoCountry from 'services/gis/geo-country-format';
import Geotargets from 'forms/ad-form/modules/geotargets';
import Geoboxes from 'forms/ad-form/modules/geo-boxes';
import { Poi } from 'containers/ad-preset/modules/poi';
import Overlay from 'widgets-v5/overlay';
import { getGeofencing } from 'containers/ad-summary';
import { getPOI } from 'containers/ad-summary/get-poi';
import shallowCompare from 'utils/shallow-compare';
import selector, { geoSummarySelector } from './selector';
import { hasAnyAudiences } from 'states/resources/ads/business-logic';

export const GeoForm = connect(selector)(
    class GeoForm extends React.Component {
        state = { lastCopiedAd: null };

        render() {
            const { ad, ads, campaign, onSave } = this.props;

            const shouldShowPoiOverlay = _.some([].concat(ad, ads), ad => hasAnyAudiences(ad));

            return (
                <BaseColumnForm
                    ad={ad}
                    ads={ads}
                    campaign={campaign}
                    headline="Geo"
                    fields={['geo_targeting_settings', 'geotargets', 'geoboxes']}
                    shouldRefreshPredictionsOnSave={true}
                    onSave={onSave}
                    onCancel={onSave}
                    onCopyAd={ad => this.setState({ lastCopiedAd: ad.id })}
                >
                    {(updateDraft, shouldShowErrors, errors, draft) => (
                        <div>
                            <Geotargets
                                key={this.state.lastCopiedAd}
                                value={draft.geotargets}
                                errors={errors.geotargets}
                                showErrors={shouldShowErrors}
                                profileToken={{}}
                                onChange={val => updateDraft({ geotargets: val })}
                            />
                            <Geoboxes
                                key={this.state.lastCopiedAd}
                                value={draft.geoboxes}
                                errors={errors.geoboxes}
                                showErrors={shouldShowErrors}
                                onChange={val => updateDraft({ geoboxes: val })}
                            />
                            <Overlay
                                disabled
                                message="POI Segments are not available when Audience Segments are selected."
                                show={shouldShowPoiOverlay}
                            >
                                <Poi
                                    key={this.state.lastCopiedAd}
                                    geo_targeting_settings={draft.geo_targeting_settings}
                                    geoResources={this.props.geoResources}
                                    organizationId={this.props.organizationId}
                                    onChange={val => updateDraft({ geo_targeting_settings: val })}
                                    isPoiRecategorized={false}
                                />
                            </Overlay>
                        </div>
                    )}
                </BaseColumnForm>
            );
        }
    }
);
class GeoSummaryComponent extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad, campaign, error, selectedPoiCategories, selectedPoiSegments } = this.props;

        return (
            <div>
                {error ? (
                    <div>{error}</div>
                ) : (
                    <div>
                        <div>
                            <div className="media-plan-form__overview-table-geo-group">
                                <div>Geotargets</div>
                                <ul>{_.map(ad.geotargets, formatGeoCountry)}</ul>
                            </div>
                            <div className="media-plan-form__overview-table-geo-group">
                                {ad.geoboxes.length > 0 && <div>Geofencing</div>}
                                {_.map(getGeofencing(ad.geoboxes), geobox => (
                                    <div key={geobox}>{geobox}</div>
                                ))}
                            </div>
                            <div className="media-plan-form__overview-table-geo-group">
                                <div>POI Segments</div>
                                {_.map(
                                    getPOI(
                                        ad.geo_targeting_settings,
                                        selectedPoiCategories,
                                        selectedPoiSegments
                                    ),
                                    (line, i) => (
                                        <div key={i}>{line}</div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export const GeoSummary = connect(geoSummarySelector)(GeoSummaryComponent);

import _ from 'lodash';
import schema from './schema';
import UUID from 'uuid';
import { CampaignAutoBudgetAllocationOptions } from '../../../states/resources/campaigns/business-logic';

const initialState = {
    campaign: undefined,
    mode: 'view',
    draft: [],
    errors: {},
    showErrors: false,
    isLoading: true,
    isSaving: false,
    isDeleteConversionWarningOpen: false,
    deletedConversionWithAffectedAdLines: {},
    advertiserConversionsDraft: [],
    ownOrganization: null,
};

const processErrors = errors => {
    if (!errors.error) {
        return {};
    }

    const result = {};

    _.each(errors.error.details, err => {
        const { path, message } = err;
        if (path.length > 0) {
            result[path.join('.')] = message;
        } else {
            result['conversions'] = message;
        }
    });
    return result;
};

const validateDraft = state => {
    const options = {
        allowUnknown: true,
        abortEarly: false,
        context: {
            draft: state.draft,
            advertiserConversionsDraft: state.advertiserConversionsDraft,
            campaign: state.campaign,
        },
    };

    const errors = schema.validate(state.draft, options);
    const processedErrors = processErrors(errors);

    return {
        ...state,
        errors: processedErrors,
    };
};

export const NAME = 'conversionsOverview';

const handlers = {
    OVERVIEW_CONVERSIONS__FETCHING_CONVERSIONS_INIT: (state) => {
        return {
            ...state,
            campaign: undefined,
            mode: 'view',
            isLoading: true,
            errors: {},
            showErrors: false,
            isDeleteConversionWarningOpen: false,
            deletedConversionWithAffectedAdLines: {},
        };
    },

    OVERVIEW_CONVERSIONS__FETCHING_CONVERSIONS_END: (state, action) => {
        const { campaign , ownOrganization} = action.payload;

        return {
            ...state,
            isLoading: false,
            campaign,
            draft: _.map(campaign.conversions, conversion => ({
                ...conversion,
                id: conversion.event_name,
            })),
            advertiserConversionsDraft: campaign.advertiserConversions,
            ownOrganization,
        };
    },

    OVERVIEW_CONVERSIONS__OPEN_MANAGE_CONVERSIONS_VIEW: (state) => {
        return {
            ...state,
            mode: 'edit',
        };
    },

    OVERVIEW_CONVERSIONS__TOGGLE_OPTIMIZATION: (state, action) => {
        const { conversion, checked } = action.payload;

        const conversions = updateConversion({
            targetConversionId: conversion.id,
            changes: {
                should_optimize_for_budget_allocation: checked,
            },
            conversions: state.draft,
        });

        const allConversionOptimizationOff = _.every(
            conversions,
            conversion => !conversion.should_optimize_for_budget_allocation
        );
        if (allConversionOptimizationOff) {
            return state;
        }

        return {
            ...state,
            draft: conversions,
        };
    },

    OVERVIEW_CONVERSIONS__HANDLE_FIELD_CHANGE: (state, action) => {
        const { id, fieldName, value } = action.payload;

        const newDraft = _.map(state.draft, conversion => {
            if (conversion.id !== id) {
                return conversion;
            }
            return {
                ...conversion,
                [fieldName]: value,
            };
        });

        return {
            ...state,
            draft: newDraft,
        };
    },

    OVERVIEW_CONVERSIONS__SAVE_CONVERSION_INIT: (state) => {
        return {
            ...state,
            showErrors: true,
            isDeleteConversionWarningOpen: false,
            isSaving: true,
        };
    },
    OVERVIEW_CONVERSIONS__SAVE_CONVERSION_DRAFT_MISSING: (state) => {
        return {
            ...state,
            isSaving: false,
        };
    },
    OVERVIEW_CONVERSIONS__SAVE_CONVERSION_VALIDATION_ERRORS: (state) => {
        return {
            ...state,
            isSaving: false,
        };
    },

    OVERVIEW_CONVERSIONS__HANDLE_DELETE_CONVERSION: (state, action) => {
        const { id } = action.payload;
        let newDraft = _.filter(state.draft, conversion => conversion.id !== id);

        return {
            ...state,
            draft: newDraft,
            deletedConversionWithAffectedAdLines: {},
            isDeleteConversionWarningOpen: false,
        };
    },

    OVERVIEW_CONVERSIONS__SAVE_CONVERSION_SUCCESS: (state) => {
        return {
            ...state,
            mode: 'view',
            isLoading: false,
            errors: {},
            showErrors: false,
            deletedConversionWithAffectedAdLines: {},
            isDeleteConversionWarningOpen: false,
            isSaving: false,
        };
    },

    OVERVIEW_CONVERSIONS__SAVE_CONVERSION_FAIL: (state) => {
        return {
            ...state,
            isSaving: false,
        };
    },

    OVERVIEW_CONVERSIONS__HANDLE_ADD_NEW_CONVERSION: (state) => {
        const { campaign } = state;

        const defaultEventName = UUID.v4().replaceAll('-', '_');

        const newDraft = _.concat(state.draft, {
            reporting_name: '',
            event_name: defaultEventName,
            use_view_through: true,
            use_click_through: true,
            click_through_attribution_window: 14,
            view_through_attribution_window: 14,
            use_dynamic_data_subevent: false,
            use_dynamic_data_value: false,
            dynamic_data_subevent_name: '',
            dynamic_data_value_name: '',
            id: _.uniqueId(),
            should_optimize_for_budget_allocation:
                campaign.automaticBudgetAllocationOptimizationStrategy === CampaignAutoBudgetAllocationOptions.CPA,
        });

        return {
            ...state,
            draft: newDraft,
        };
    },

    OVERVIEW_CONVERSIONS__SHOW_CONVERSIONS_DELETION_WARNING: (state, action) => {
        const { deletedConversionWithAffectedAdLines } = action.payload;
        return {
            ...state,
            deletedConversionWithAffectedAdLines,
            isDeleteConversionWarningOpen: true,
        };
    },
    OVERVIEW_CONVERSIONS__ADVERTISER_CONVERSION__UPDATE_BUDGET_OPTIMIZATION__START: (
        state,
    ) => {
        return {
            ...state,
            isUpdatingBudget: true,
        };
    },
    OVERVIEW_CONVERSIONS__ADVERTISER_CONVERSION__UPDATE_BUDGET_OPTIMIZATION__SUCCESS: (
        state,
        action
    ) => {
        return {
            ...state,
            campaign: {
                ...state.campaign,
                advertiserConversions: action.payload.campaign.advertiserConversions,
            },
            isUpdatingBudget: false,
        };
    },
    OVERVIEW_CONVERSIONS__ADVERTISER_CONVERSION__UPDATE_BUDGET_OPTIMIZATION__ERROR: (
        state,
    ) => {
        return {
            ...state,
            isUpdatingBudget: false,
        };
    },
    OVERVIEW_CONVERSIONS__ADVERTISER_CONVERSION__TOGGLE_BUDGET_OPTIMIZATION: (state, action) => {
        const advertiserConversionsDraft = _.map(state.advertiserConversionsDraft, conversion => {
            if (conversion.id === action.payload.conversion.id) {
                return {
                    ...conversion,
                    should_optimize_for_budget_allocation: !conversion.should_optimize_for_budget_allocation,
                };
            }

            return conversion;
        });

        return {
            ...state,
            advertiserConversionsDraft,
        };
    },
};

function updateConversion({ targetConversionId, changes, conversions }) {
    const conversionsCopy = [...conversions];

    const targetConversionIndex = _.findIndex(conversionsCopy, { id: targetConversionId });

    if (targetConversionIndex === -1) {
        return conversions;
    }

    conversionsCopy[targetConversionIndex] = {
        ...conversionsCopy[targetConversionIndex],
        ...changes,
    };

    return conversionsCopy;
}

export default function(state = initialState, action) {
    let nextState = state;

    if (handlers[action.type]) {
        nextState = handlers[action.type](state, action);
        nextState = validateDraft(nextState);
    }

    return nextState;
}

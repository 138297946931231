import _ from 'lodash';
import { getEnvironmentSettings } from '../../services/environment';

const QUALIFIER_OPTIONS = [
    { value: 'equal', label: 'equals to' },
    { value: 'greaterThan', label: 'greater than' },
    { value: 'lessThan', label: 'less than' },
    { value: 'greaterThanInclusive', label: 'equal to or greater than' },
    { value: 'lessThanInclusive', label: 'equal to or less than' },
];

const TIMEFRAME_OPTIONS = [
    { value: 'now', label: 'Now' },
    { value: 'next_36h', label: 'Next 36 hours' },
    { value: 'next_14d', label: 'Next 14 days' },
    { value: 'next_3d', label: 'Next 3 Days' },
    { value: 'next_6d', label: 'Next 6 Days' },
    { value: 'forecast', label: 'Forecast' },
];

const VALUE_OPTIONS = [
    {
        alias: 'no',
        value: 0,
        label: 'None',
        types: ['migraine', 'tick'],
    },
    {
        alias: 'low',
        value: 1,
        label: 'Low',
        types: ['migraine', 'tick'],
    },
    {
        alias: 'moderate',
        value: 2,
        label: 'Moderate',
        types: ['migraine', 'tick'],
    },
    {
        alias: 'high',
        value: 3,
        label: 'High',
        types: ['migraine', 'tick'],
    },
    {
        alias: 'veryHigh',
        value: 4,
        label: 'Very High',
        types: ['migraine'],
    },
    {
        alias: 'low',
        value: 0,
        label: 'Low',
        types: ['pollen', 'airquality', 'flu', 'mosquito', 'uv'],
    },
    {
        alias: 'moderate',
        value: 1,
        label: 'Moderate',
        types: ['pollen', 'airquality', 'flu', 'mosquito', 'uv'],
    },
    {
        alias: 'high',
        value: 2,
        label: 'High',
        types: ['pollen', 'airquality', 'flu', 'mosquito', 'uv'],
    },
    {
        alias: 'veryHigh',
        value: 3,
        label: 'Very High',
        types: ['airquality', 'flu', 'uv'],
    }, // should be excluded for Canada airquality/flu
    { alias: 'extreme', value: 4, label: 'Extreme', types: ['uv'] },
    {
        alias: 'true',
        value: 1,
        label: 'True',
        types: ['Rain', 'Sunny', 'Snow', 'heat', 'haze', 'fog', 'iceWarning', 'freezingRain'],
    },
    {
        alias: 'false',
        value: 0,
        label: 'False',
        types: ['Rain', 'Sunny', 'Snow', 'heat', 'haze', 'fog', 'iceWarning', 'freezingRain'],
    },
    {
        alias: 'no',
        value: 1,
        label: 'Not Recommended',
        types: ['outdoor', 'pool', 'beach', 'poolandbeach', 'ski'],
    },
    {
        alias: 'poor',
        value: 1,
        label: 'Poor',
        types: ['outdoor', 'pool', 'beach', 'poolandbeach', 'ski'],
    },
    {
        alias: 'fair',
        value: 2,
        label: 'Fair',
        types: ['outdoor', 'pool', 'beach', 'poolandbeach', 'ski'],
    },
    {
        alias: 'good',
        value: 3,
        label: 'Good',
        types: ['outdoor', 'pool', 'beach', 'poolandbeach', 'ski'],
    },
    {
        alias: 'veryGood',
        value: 4,
        label: 'Very Good',
        types: ['outdoor', 'pool', 'beach', 'poolandbeach', 'ski'],
    },
];

function getQualifiers(keys) {
    return _.filter(QUALIFIER_OPTIONS, setting => _.includes(keys, setting.value));
}

function geTimeframes(keys) {
    return _.filter(TIMEFRAME_OPTIONS, setting => _.includes(keys, setting.value));
}

function getValues(keys, type) {
    return _.filter(
        VALUE_OPTIONS,
        setting => _.includes(keys, setting.alias) && _.includes(setting.types, type)
    );
}

const WEATHER_CONDITIONS_MAPPING = [
    {
        value: 'Rain',
        label: 'Rain',
        env: ['ca', 'es'],
        group: 'Weather',
        qualifierOptions: getQualifiers(['equal']),
        timeframeOptions: geTimeframes(['now', 'next_36h', 'next_14d']),
        valueOptions: getValues(['true', 'false'], 'Rain'),
    },
    {
        value: 'Sunny',
        label: 'Sunny',
        env: ['ca', 'es'],
        group: 'Weather',
        qualifierOptions: getQualifiers(['equal']),
        timeframeOptions: geTimeframes(['now', 'next_36h', 'next_14d']),
        valueOptions: getValues(['true', 'false'], 'Sunny'),
    },
    {
        value: 'Temperature',
        label: 'Temperature',
        env: ['ca', 'es'],
        group: 'Weather',
        qualifierOptions: getQualifiers([
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'next_36h', 'next_14d']),
        valueOptions: getValues([]),
    },
    {
        value: 'FeelsLike',
        label: '"Feels Like" Temperature',
        env: ['ca', 'es'],
        group: 'Weather',
        qualifierOptions: getQualifiers([
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'next_36h', 'next_14d']),
        valueOptions: getValues([]),
    },
    {
        value: 'Snow',
        label: 'Snow',
        env: ['ca', 'es'],
        group: 'Weather',
        qualifierOptions: getQualifiers(['equal']),
        timeframeOptions: geTimeframes(['now', 'next_36h', 'next_14d']),
        valueOptions: getValues(['true', 'false'], 'Snow'),
    },
    {
        value: 'humidity',
        label: 'Humidity',
        env: ['ca', 'es'],
        group: 'Weather',
        qualifierOptions: getQualifiers([
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'next_36h', 'next_14d'], 'humidity'),
        valueOptions: getValues([]),
    },
    {
        value: 'windspeed',
        label: 'Wind Speed',
        env: ['ca', 'es'],
        group: 'Weather',
        qualifierOptions: getQualifiers([
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'next_36h', 'next_14d'], 'windspeed'),
        valueOptions: getValues([]),
    },
    {
        value: 'heat',
        label: 'Heat Warning',
        env: ['ca', 'es'],
        group: 'Warnings',
        qualifierOptions: getQualifiers(['equal']),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['true', 'false'], 'heat'),
    },
    {
        value: 'haze',
        label: 'Calima',
        env: ['ca', 'es'],
        group: 'Warnings',
        qualifierOptions: getQualifiers(['equal']),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['true', 'false'], 'haze'),
    },
    {
        value: 'fog',
        label: 'Fog',
        env: ['ca', 'es'],
        group: 'Warnings',
        qualifierOptions: getQualifiers(['equal']),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['true', 'false'], 'fog'),
    },
    // { we will release this trigger near winter season
    //     value: 'iceWarning',
    //     label: 'Ice Warning',
    //     env: ['ca', 'es'],
    //     group: 'Warnings',
    //     qualifierOptions: getQualifiers(['equal']),
    //     timeframeOptions: geTimeframes(['now']),
    //     valueOptions: getValues(['true', 'false']),
    // },
    // {  we will release this trigger near winter season
    //     value: 'freezingRain',
    //     label: 'Freezing Rain',
    //     env: ['ca', 'es'],
    //     group: 'Warnings',
    //     qualifierOptions: getQualifiers(['equal']),
    //     timeframeOptions: geTimeframes(['now']),
    //     valueOptions: getValues(['true', 'false']),
    // },
    {
        value: 'pollen',
        label: 'Pollen',
        env: ['ca'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['next_6d', 'now']),
        valueOptions: getValues(['low', 'moderate', 'high'], 'pollen'),
    },
    {
        value: 'pollen',
        label: 'Pollen',
        env: ['es'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'next_3d']),
        valueOptions: getValues(['low', 'moderate', 'high'], 'pollen'),
    },
    {
        value: 'airquality',
        label: 'Air Pollution',
        env: ['ca', 'es'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['low', 'moderate', 'high', 'veryHigh'], 'airquality'),
    },
    {
        value: 'flu', // changed type value to lowercase(camelCase). should be verified Spain CBAT re-set-rules
        label: 'Flu Activity',
        env: ['ca', 'es'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['low', 'moderate', 'high', 'veryHigh'], 'flu'),
    },
    {
        value: 'mosquito',
        label: 'Mosquito Activity',
        env: ['ca', 'es'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['low', 'moderate', 'high'], 'mosquito'),
    },
    {
        value: 'tick',
        label: 'Tick Activity',
        env: ['ca', 'es'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['no', 'low', 'moderate', 'high'], 'tick'),
    },
    {
        value: 'uv',
        label: 'UV',
        env: ['ca', 'es'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['low', 'moderate', 'high', 'veryHigh', 'extreme'], 'uv'),
    },
    {
        value: 'migraine',
        label: 'Migraine',
        env: ['ca', 'es'],
        group: 'Health',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now']),
        valueOptions: getValues(['no', 'low', 'moderate', 'high', 'veryHigh'], 'migraine'),
    },
    {
        value: 'outdoor',
        label: 'Outdoor Sports',
        env: ['ca', 'es'],
        group: 'Activities',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'forecast']),
        valueOptions: getValues(['no', 'poor', 'fair', 'good', 'veryGood'], 'outdoor'),
    },
    {
        value: 'beach',
        label: 'Beach',
        env: ['ca', 'es'],
        group: 'Activities',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'forecast']),
        valueOptions: getValues(['no', 'poor', 'fair', 'good', 'veryGood'], 'beach'),
    },
    {
        value: 'pool',
        label: 'Pool',
        env: ['ca', 'es'],
        group: 'Activities',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'forecast']),
        valueOptions: getValues(['no', 'poor', 'fair', 'good', 'veryGood'], 'pool'),
    },
    {
        value: 'poolandbeach',
        label: 'Pool and Beach',
        env: ['ca', 'es'],
        group: 'Activities',
        qualifierOptions: getQualifiers([
            'equal',
            'greaterThan',
            'lessThan',
            'greaterThanInclusive',
            'lessThanInclusive',
        ]),
        timeframeOptions: geTimeframes(['now', 'forecast']),
        valueOptions: getValues(['no', 'poor', 'fair', 'good', 'veryGood'], 'poolandbeach'),
    },
    // {  we will release this trigger near winter season
    //     value: 'ski',
    //     label: 'Ski Activity',
    //     env: ['ca', 'es'],
    //     group: 'Activities',
    //     qualifierOptions: getQualifiers([
    //         'equal',
    //         'greaterThan',
    //         'lessThan',
    //         'greaterThanInclusive',
    //         'lessThanInclusive',
    //     ]),
    //     timeframeOptions: geTimeframes(['now', 'forecast']),
    //     valueOptions: getValues(['poor', 'fair', 'good', 'veryGood']),
    // },
];

export function getConditionLabel(type) {
    const environmentSettings = getEnvironmentSettings();
    const environment = environmentSettings.env === 'spain' ? 'es' : 'ca';
    return _.find(WEATHER_CONDITIONS_MAPPING, { value: type, env: [environment] })?.label;
}

export function getQualifierLabel(qualifier) {
    return _.find(QUALIFIER_OPTIONS, { value: qualifier })?.label;
}

export function getValueLabel(value, type) {
    return _(VALUE_OPTIONS)
        .filter(option => _.includes(option.types, type))
        .find(option => option.value === value)?.label;
}

export const isWeatherTypeTemperature = type => {
    return ['Temperature', 'FeelsLike', 'humidity', 'windspeed'].includes(type);
};

export const hasWeatherConditions = weatherConditions => {
    return (
        weatherConditions && weatherConditions.conditions && weatherConditions.conditions.length > 0
    );
};

export const getWeatherConditionOptions = conditionsToUse => {
    const environmentSettings = getEnvironmentSettings();
    const environment = environmentSettings.env === 'spain' ? 'es' : 'ca';
    return _.filter(
        WEATHER_CONDITIONS_MAPPING,
        setting =>
            _.includes(conditionsToUse, setting.value) && _.includes(setting.env, environment)
    );
};

export const maxNumberOfConditions = 3;

export const VALID_WEATHER_CONDITION_MULTIPLE_OPTION_TYPE = [
    'Temperature',
    'FeelsLike',
    'humidity',
    'windspeed',
    'pollen',
    'airquality',
    'flu',
    'uv',
    'mosquito',
    'tick',
    'migraine',
    'outdoor',
    'pool',
    'beach',
    'poolandbeach',
    'ski',
];

export const VALID_WEATHER_CONDITION_QUALIFIER = [
    ..._.map([...QUALIFIER_OPTIONS], option => option.value),
];
export const VALID_WEATHER_CONDITION_TYPE = [
    'Rain',
    'Sunny',
    'Temperature',
    'FeelsLike',
    'Snow',
    'humidity',
    'windspeed',
    'heat',
    'haze',
    'fog',
    'iceWarning',
    'freezingRain',
    'pollen',
    'airquality',
    'flu',
    'uv',
    'mosquito',
    'tick',
    'migraine',
    'outdoor',
    'pool',
    'beach',
    'poolandbeach',
    'ski',
];

export const VALID_WEATHER_CONDITION_TIMEFRAME = [
    'now',
    'next_36h',
    'next_14d',
    'next_3d',
    'next_6d',
    'forecast',
];

export const inputAdornmentMap = {
    Temperature: '°C',
    FeelsLike: '°C',
    humidity: '%',
    windspeed: 'kmh',
};

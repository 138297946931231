import _ from 'lodash';
import c from 'common/constants/flux-events';
import toastr from 'toastr';

import { fetchCampaign } from 'states/resources/campaigns/actions';
import { serializeForApi } from 'forms/ad-form/services';
import { fetchReportStats } from 'services/resources/stats';
import Ads from 'states/resources/ads/actions';
import Campaigns from 'states/resources/campaigns/actions';
import Creatives from 'states/resources/creatives/actions';
import Organizations from 'states/resources/organizations/actions';
import { getDefaults } from 'forms/ad-form/services';
import UserActions from 'states/resources/users/actions';
const defaultsDraft = getDefaults('payload_formData');
const defaultsDraft_updateOnly = getDefaults('updateOnly'); // update only (not present in resources)
import adFormActions from 'forms/ad-form/actions';
import { createHttp } from 'utils/http';
import { getGeocategories, getAllLayers } from '../ad-new-form/actions';
import { getEnvironmentSettings } from 'services/environment';

const http = createHttp();

const TOASTR_OPTIONS = {
    tapToDismiss: true,
    showDuration: 1000,
    showMethod: 'slideDown',
    timeOut: 0,
    extendedTimeOut: 0,
    positionClass: 'toast-bottom-left',
};

async function fetchAdFormEditData(organizationId, adId) {
    const query = `
        query getDataForAdEditForm($id: String, $adIds: [Int]) {
            organization(id: $id) {
                name
                tech_fee
                blacklistedAppsAndSites
                whitelistedAppsAndSites
                fta_partner_id
                ftaEnabled
                audience_rates {
                    name
                    fee
                }
                isIasPostBidVerificationEnabled
            }
            deals(filter: { adIds: $adIds }) {
                id
            }
        }
    `;

    const variables = {
        id: organizationId,
        adIds: [Number(adId)],
    };

    try {
        const data = await http.graphql(query, variables);

        return {
            ...data,
            deals: _.map(data.deals, deal => deal.id),
        };
    } catch (error) {
        if (error.response.status === 401) {
            return;
        }
        toastr.warning(
            '',
            '<p>Something went wrong, please try again later. The EngageFront team has been notified</p>',
            TOASTR_OPTIONS
        );
        if (window.bugsnagClient) {
            window.bugsnagClient.notify(`Failed to fetch data for Ad Edit Form`, {
                metaData: {
                    organizationId,
                },
            });
        }
    }
}

const AdEdit = {
    open(campaignId, adId) {
        return (dispatch, getState) => {
            function getAdAndLayersAndImplicitLayerPoints() {
                return Promise.all([dispatch(Ads.get(campaignId, adId)), getAllLayers(dispatch)])
                    .then(
                        ([ad, layers]) => {
                            const { custom_layers } = ad.geo_targeting_settings;
                            const allImplicitLayers = _.filter(
                                layers,
                                layer => layer.name === '___implicit'
                            );
                            const implicitLayerForGeoMap = _.filter(allImplicitLayers, layer =>
                                _.includes(custom_layers, layer.id)
                            )[0];

                            return new Promise((resolve, reject) => {
                                if (!implicitLayerForGeoMap) {
                                    // This ad has no implicite layer so simply resolve silently
                                    resolve();
                                    return;
                                }
                                // resolve();
                                // This ad has implicit layer, fetch it and show it
                                getAllLayers(dispatch, implicitLayerForGeoMap.id).then(
                                    layer => {
                                        // load implicit layer in the map
                                        dispatch(
                                            adFormActions.showGeoEntityInMap(
                                                campaignId,
                                                adId,
                                                'implicitLayer',
                                                implicitLayerForGeoMap.id
                                            )
                                        );
                                        setTimeout(() => {
                                            // google map has latency, so refresh etag to force map to update
                                            dispatch(
                                                adFormActions.refreshGeoEntityInMap(
                                                    campaignId,
                                                    adId,
                                                    'implicitLayer',
                                                    implicitLayerForGeoMap.id
                                                )
                                            );
                                        }, 1000);

                                        resolve(layer);
                                    },
                                    error => {
                                        // fetching implicit error
                                        reject(JSON.stringify(error));
                                    }
                                );
                            });
                        },
                        error => {
                            throw new Error(JSON.stringify(error));
                        }
                    )
                    .catch(reason => {
                        throw new Error(JSON.stringify(reason));
                    });
            }

            const organizationId = _.get(getState(), `profile.organizationId`);
            const promise = Promise.all([
                dispatch(UserActions.get()),
                dispatch(Organizations.get(organizationId)),
                dispatch(Campaigns.get(campaignId)),
            ])
                .then(([user, organization, campaign]) => { // eslint-disable-line
                    const _organizationId = _.get(getState(), `profile.organizationId`);
                    return Promise.all([
                        campaign,
                        fetchReportStats({
                            campaign_id: campaignId,
                            match: {
                                ad_id: [adId],
                            },
                            split: [],
                            include_beacons: false,
                            timezone: campaign.default_timezone || 'America/Toronto',
                            datasource: getEnvironmentSettings().getReportingDatasource(),
                            queryTag: 'ef_ad_edit_form',
                        }),
                        fetchAdFormEditData(_organizationId, adId),
                        dispatch(Creatives.getAll(campaignId)),
                        dispatch(Ads.getAll(campaignId)),
                        getGeocategories(dispatch),
                        getAdAndLayersAndImplicitLayerPoints(),
                    ]);
                })
                .then(([campaign, stats, data]) => {
                    const { organization, deals } = data;
                    const ad = _.get(getState(), `resources.ads.${adId}`);
                    const adAttr = _.get(ad, 'attr');
                    // adAtrr has all the data relevent to an ad, but we only want data for the form.
                    const defaultsDraft_keys = Object.keys(defaultsDraft);
                    const adAttr_pickedForDraft = _.pick(adAttr, defaultsDraft_keys);

                    // We handle creative rotation weights as whole numbers on the front end in order to avoid
                    // float precision errors. We leave the store's ad resource untouched however. These weights
                    // are converted to decimals in the serialization step of the ad form submission
                    const adAttr_pickedForDraft_rotationRulesProcessed = _.cloneDeep(
                        adAttr_pickedForDraft
                    );
                    adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.weighted = _.map(
                        adAttr_pickedForDraft_rotationRulesProcessed.rotation_rules.weighted,
                        rotationSetting => ({
                            ...rotationSetting,
                            weight: _.floor(rotationSetting.weight * 100),
                        })
                    );

                    // defaults in the case that server sends back empty string
                    const defaultedCreativeValue = adAttr.creative === '' ? null : adAttr.creative;

                    const adAttr_form = {
                        ...defaultsDraft, // so that attr form does not has missing field
                        ...adAttr_pickedForDraft_rotationRulesProcessed,
                        ...defaultsDraft_updateOnly, // update only (not present in resources)
                        creative: defaultedCreativeValue,
                        deals,
                    };

                    // poi module
                    const geoResources = _.get(getState(), `resources.geoCategories`);
                    const organizationId = _.get(getState(), `profile.organizationId`);
                    const ownOrganization = organization;
                    const geoCategories = {
                        ...geoResources,
                        geoLayersForThisOrg: _.filter(
                            geoResources.geoLayers,
                            layer => layer.organization === organizationId
                        ),
                    };

                    return {
                        stats,
                        campaignId,
                        adId,
                        ad: adAttr_form,
                        rawAd: adAttr,
                        geoCategories: geoCategories,
                        campaign,
                        deliveredImpressions: stats.impressions,
                        ownOrganization,
                    };
                });

            return promise;
        };
    },
    submitDraft(draft, campaignId, adId) {
        // update ad
        if (!campaignId) {
            throw new Error('Edit Ad campaignId not found');
        }

        return (dispatch, getState) => {
            const draft = _.get(getState(), `adForm.form.draft`);

            const adForm = _.get(getState(), `adForm.form`);
            const isCrossPlatformCampaign = _.get(adForm, 'isCrossPlatformCampaign');

            const payloadSerialized = serializeForApi({ draft, isCrossPlatformCampaign });

            return dispatch(Ads.update(campaignId, adId, payloadSerialized)).then(
                response => {
                    dispatch({
                        type: c.OVERVIEW_ADS__AD_DRAFT_EDIT__SUBMIT_SUCCESS,
                        payload: { adId, campaignId, response },
                    });

                    fetchCampaign(campaignId)(dispatch, getState);
                },
                error => {
                    dispatch({
                        type: c.OVERVIEW_ADS__AD_DRAFT_EDIT__SUBMIT_FAIL,
                        payload: { campaignId, adId },
                        error: error.body,
                    });

                    return Promise.reject(error.body);
                }
            );
        };
    },
};

export default AdEdit;

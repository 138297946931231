import _ from 'lodash';
import flags from 'containers/flags/service';

export function handleChangeIasPublicisViewability() {
    return {
        description: 'Handle change of IAS Publicis Viewability',
        target(state, action) {
            return 'AD_FORM__IAS_PUBLICIS_VIEWABILITY__CHANGE' === action.type;
        },
        reduce(state, action) {
            return updateIasPublicisViewability(state, action.payload.value);
        },
    };
}

function updateIasPublicisViewability(state, value) {
    return {
        ...state,
        draft: {
            ...state.draft,
            ias: {
                ...state.draft.ias,
                publicisViewability: {
                    ...state.draft.ias.publicisViewability,
                    value,
                },
            },
        },
    };
}

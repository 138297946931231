import React from 'react';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import shallowCompare from 'utils/shallow-compare';
import { Cell } from 'widgets-v5/data-table';

import BaseColumnForm from '../base-column-form';
import Section from 'widgets-v5/section';

import { TextField } from 'widgets-v6/standard-input';
import PLATFORMS from 'common/constants/platforms';
import flags from 'containers/flags/service';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';

import selector from './selector';

import { indigo, blue, purple } from '@mui/material/colors';

const platformMap = {};
PLATFORMS.forEach(platform => {
    platformMap[platform.value] = platform;
});

const styles = makeStyles(theme => ({
    chip: ({ platform }) => {
        let color;
        switch (platform) {
            case 'mweb':
                color = indigo[500];
                break;
            case 'desktop':
                color = blue[500];
                break;
            case 'inapp':
                color = purple[500];
                break;
            default:
                color = 'primary';
        }
        return {
            borderColor: color,
            color: color,
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.25),
        };
    },
    text: {
        marginBottom: theme.spacing(1),
    },
    mweb: {
        borderColor: indigo[500],
        color: indigo[500],
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.25),
    },
    desktop: {
        borderColor: blue[500],
        color: blue[500],
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.25),
    },
    inapp: {
        borderColor: purple[500],
        color: purple[500],
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.25),
    },
}));

export class NameForm extends React.Component {
    render() {
        const { ad, ads, campaign, onSave } = this.props;

        return (
            <BaseColumnForm
                ad={ad}
                ads={ads}
                campaign={campaign}
                headline="Name"
                fields={['name']}
                onSave={onSave}
                onCancel={onSave}
            >
                {(updateDraft, shouldShowErrors, errors, draft) => (
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                value={draft.name}
                                onChange={e => updateDraft({ name: e.target.value })}
                                error={shouldShowErrors && errors.name}
                                helperText={shouldShowErrors && errors.name}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                )}
            </BaseColumnForm>
        );
    }
}

const NameSummaryComponent = props => {
    const { ad, campaign, hasAdSetupWarning } = props;
    const classes = styles({ platform: ad.platform });

    return (
        <div>
            <p className={classes.text}>
                #{ad.id} {ad.name}
            </p>
            {campaign.isCrossPlatformCampaign ? (
                <React.Fragment>
                    {_.map(ad.platforms, platform => (
                        <Chip
                            key={`${platform}-${ad.id}`}
                            size="small"
                            className={classes[platform]}
                            label={platformMap[platform].label}
                            variant="outlined"
                        />
                    ))}
                    {hasAdSetupWarning && (
                        <Box mt={1}>
                            <WarningText severity="hard" message="Low Inventory" />
                        </Box>
                    )}
                </React.Fragment>
            ) : (
                <Chip
                    size="small"
                    className={classes.chip}
                    label={platformMap[ad.platform].label}
                    variant="outlined"
                />
            )}
        </div>
    );
};

export const NameSummary = React.memo(connect(selector)(NameSummaryComponent));

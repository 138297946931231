import React, { Fragment } from 'react';

import { Section, List, Layout, HelpLink, Paragraph, ListItem } from '../modules/layout.js';
import { BodyText } from 'widgets-v5/typography';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import Spacer from 'widgets-v5/spacer';

export default {
    category: 'Reference',
    topic: 'App Categories',
    url: 'reference/app-categories',
    published: true,
    Component: class Article extends React.Component {
        constructor(props) {
            super(props);

            this.targeting = React.createRef();
            this.categories = React.createRef();
            this.equivalents = React.createRef();
        }

        render() {
            return (
                <Layout
                    title="App Categories"
                    sidebarContent={[
                        {
                            title: 'Targeting App Categories',
                            link: this.targeting,
                        },
                        {
                            title: 'List of App Categories',
                            link: this.categories,
                        },
                        {
                            title: 'App Store Equivalents',
                            link: this.equivalents,
                        },
                    ]}
                >
                    <Section link={this.targeting} title="Targeting App Categories">
                        <Paragraph>
                            When targeting apps by category, EngageFront no longer uses the
                            self-reported IAB categories due to frequent misattribution and abuse by
                            publishers.
                        </Paragraph>
                        <Paragraph>
                            To combat fraud, EngageFront uses a proprietary list of categories
                            amalagmated from the
                            <HelpLink
                                icon
                                text="Apple App Store"
                                url="https://developer.apple.com/app-store/categories/"
                                target="_blank"
                            />
                            and
                            <HelpLink
                                icon
                                text="Google Play Store."
                                url="https://support.google.com/googleplay/android-developer/answer/113475?hl=en"
                                target="_blank"
                            />
                            Each app's category is determined by the category as labelled on their
                            respective App Store and Play Store pages, where a stringent review
                            process and community reporting ensures clean and useful data.
                        </Paragraph>
                    </Section>
                    <Section link={this.categories} title="List of App Categories">
                        <Paragraph>
                            Categories marked with an asterisk (*) are recent additions to the
                            Google Play Store and have no equivalents available on the Apple App
                            Store. When targeting these categories, expect reduced inventory.
                        </Paragraph>
                        <List>
                            <ListItem>Auto & Vehicles*</ListItem>
                            <ListItem>Books & Reference</ListItem>
                            <ListItem>Business</ListItem>
                            <ListItem>Education</ListItem>
                            <ListItem>Entertainment</ListItem>
                            <ListItem>Finance</ListItem>
                            <ListItem>Food & Drink</ListItem>
                            <ListItem>Games</ListItem>
                            <ListItem>Health & Fitness</ListItem>
                            <ListItem>House & Home*</ListItem>
                            <ListItem>Lifestyle</ListItem>
                            <ListItem>Maps & Navigation</ListItem>
                            <ListItem>Medical</ListItem>
                            <ListItem>Music & Audio</ListItem>
                            <ListItem>News & Magazines</ListItem>
                            <ListItem>Parenting*</ListItem>
                            <ListItem>Photo & Video</ListItem>
                            <ListItem>Productivity</ListItem>
                            <ListItem>Shopping</ListItem>
                            <ListItem>Social</ListItem>
                            <ListItem>Sports</ListItem>
                            <ListItem>Tools & Utilities</ListItem>
                            <ListItem>Travel & Local</ListItem>
                            <ListItem>Weather</ListItem>
                            <ListItem>Other*</ListItem>
                        </List>
                    </Section>
                    <Section link={this.equivalents} title="App Store Equivalents">
                        <ComposableDataTable>
                            <Row header>
                                <Cell>EngageFront App Category</Cell>
                                <Cell>Google Play Store Equivalent(s)</Cell>
                                <Cell>Apple App Store Equivalent(s)</Cell>
                            </Row>
                            <Row>
                                <Cell>Auto & Vehicles*</Cell>
                                <Cell>Auto & Vehicles</Cell>
                                <Cell />
                            </Row>
                            <Row>
                                <Cell>Books & References</Cell>
                                <Cell>
                                    Books & Reference
                                    <br />
                                    Comics
                                </Cell>
                                <Cell>
                                    Books
                                    <br />
                                    Reference
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Business</Cell>
                                <Cell>Business</Cell>
                                <Cell>Business</Cell>
                            </Row>
                            <Row>
                                <Cell>Education</Cell>
                                <Cell>Education</Cell>
                                <Cell>Education</Cell>
                            </Row>
                            <Row>
                                <Cell>Entertainment</Cell>
                                <Cell>Entertainment</Cell>
                                <Cell>
                                    Entertainment
                                    <br />
                                    Events
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Finance</Cell>
                                <Cell>Finance</Cell>
                                <Cell>Finance</Cell>
                            </Row>
                            <Row>
                                <Cell>Food & Drink</Cell>
                                <Cell>Food & Drink</Cell>
                                <Cell>Food & Drink</Cell>
                            </Row>
                            <Row>
                                <Cell>Games</Cell>
                                <Cell>Games</Cell>
                                <Cell>Games</Cell>
                            </Row>
                            <Row>
                                <Cell>Health & Fitness</Cell>
                                <Cell>Health & Fitness</Cell>
                                <Cell>Health & Fitness</Cell>
                            </Row>
                            <Row>
                                <Cell>House & Home*</Cell>
                                <Cell>House & Home</Cell>
                                <Cell />
                            </Row>
                            <Row>
                                <Cell>Lifestyle</Cell>
                                <Cell>
                                    Lifestyle
                                    <br />
                                    Beauty
                                </Cell>
                                <Cell>Lifestyle</Cell>
                            </Row>
                            <Row>
                                <Cell>Maps & Navigation</Cell>
                                <Cell>Maps & Navigation</Cell>
                                <Cell>Navigation</Cell>
                            </Row>
                            <Row>
                                <Cell>Medical</Cell>
                                <Cell>Medical</Cell>
                                <Cell>Medical</Cell>
                            </Row>
                            <Row>
                                <Cell>Music & Audio</Cell>
                                <Cell>Music & Audio</Cell>
                                <Cell>Music</Cell>
                            </Row>
                            <Row>
                                <Cell>News & Magazines</Cell>
                                <Cell>News & Magazines</Cell>
                                <Cell>
                                    News
                                    <br />
                                    Magazines & Newspapers
                                </Cell>
                            </Row>
                            <Row>
                                <Cell>Parenting*</Cell>
                                <Cell>Parenting</Cell>
                                <Cell />
                            </Row>
                            <Row>
                                <Cell>Photo & Video</Cell>
                                <Cell>
                                    Photography
                                    <br />
                                    Video Players & Editors
                                </Cell>
                                <Cell>Photo & Video</Cell>
                            </Row>
                            <Row>
                                <Cell>Productivity</Cell>
                                <Cell>
                                    Productivity
                                    <br />
                                    Art & Design
                                </Cell>
                                <Cell>Productivity</Cell>
                            </Row>
                            <Row>
                                <Cell>Shopping</Cell>
                                <Cell>Shopping</Cell>
                                <Cell>Shopping</Cell>
                            </Row>
                            <Row>
                                <Cell>Social</Cell>
                                <Cell>
                                    Social
                                    <br />
                                    Dating
                                    <br />
                                    Communications
                                </Cell>
                                <Cell>Social Netowrking</Cell>
                            </Row>
                            <Row>
                                <Cell>Sports</Cell>
                                <Cell>Sports</Cell>
                                <Cell>Sports</Cell>
                            </Row>
                            <Row>
                                <Cell>Tools & Utilities</Cell>
                                <Cell>Tools</Cell>
                                <Cell>Utilities</Cell>
                            </Row>
                            <Row>
                                <Cell>Travel & Local</Cell>
                                <Cell>Travel & Local</Cell>
                                <Cell>Travel</Cell>
                            </Row>
                            <Row>
                                <Cell>Weather</Cell>
                                <Cell>Weather</Cell>
                                <Cell>Weather</Cell>
                            </Row>
                            <Row>
                                <Cell>Other*</Cell>
                                <Cell>
                                    Libraries & Demo
                                    <br />
                                    Personalization
                                </Cell>
                                <Cell />
                            </Row>
                        </ComposableDataTable>
                    </Section>
                </Layout>
            );
        }
    },
};

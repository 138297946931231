
import get from 'lodash/get';

import { actions } from './reducer';
import geoCategoriesActions from 'states/resources/geo-categories/actions';
import audienceActions from 'states/resources/audiences/actions';
import predictionActions from 'states/resources/inventory-predictions/actions';
import { graphqlRequest } from 'utils/http/redux';

const initMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.init.type) {
        const organizationId = get(getState(), `profile.organizationId`);

        const [audiences, organization, applists] = await Promise.all([
            dispatch(audienceActions.getAll()),
            fetchOrganization(organizationId, dispatch),
            fetchApplists(dispatch),
        ]);

        dispatch(actions.initSuccess({
            audiences,
            organization,
            applists,
        }));
        recalculateCosts(dispatch);
    }
};

const initGeoMw = () => ({ dispatch }) => next => async action => {
    next(action);

    if (action.type === actions.initGeo.type) {
        try {
            await Promise.all([
                dispatch(geoCategoriesActions.getCategories()),
                dispatch(geoCategoriesActions.getAllLayers()),
            ]);

            dispatch(actions.initGeoSuccess());
        } catch (e) {
        }
    }
};

const validateMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.validateDraft.type) {
        const {
            predictionEditor: { draft },
        } = getState();
        const result = await dispatch(
            predictionActions.validatePredictionForm(draft, Number(draft.daysRunning))
        );
        if (result) {
            dispatch(actions.generate());
        }
    }
};

const generateMw = () => ({ dispatch, getState }) => next => async action => {
    next(action);

    if (action.type === actions.generate.type) {
        const {
            predictionEditor: { draft },
        } = getState();
        await dispatch(predictionActions.updatePrediction(draft, Number(draft.daysRunning)));
        dispatch(actions.generateSuccess());
    }
}

async function recalculateCosts(dispatch) {
    await dispatch(actions.deriveDataCost());
    await dispatch(actions.calculateBidPrice());
}

async function fetchOrganization(id, dispatch) {
    try {
        const query = `
            query getOrganizations ($id: String) {
                organization(id: $id) {
                    id
                    name
                    fta_fee
                    tech_fee
                    audience_rates {
                        name
                        fee
                    }
                }
            }
        `;

        const variables = { id };

        const response = await dispatch(
            graphqlRequest({
                query,
                variables,
            })
        );

        const {
            data: {
                organization,
            },
        } = response;

        return organization;
    } catch (err) {
        if (window.bugsnagClient) {
            window.bugsnagClient.notify(`Failed to fetch organization data in prediction editor`, {
                metaData: {
                    id,
                    action: 'PREDICTION_EDITOR__INITIALIZE_DRAFT__START',
                    function: 'fetchOrganization()',
                },
            });
        }
        throw err;
    }
}

async function fetchApplists(dispatch) {
    try {
        const query = `
            query getApplists {
                applists(filters: {}) {
                    id
                    name
                    advertiser {
                        id
                        name
                    }
                }
            }
        `;

        const response = await dispatch(
            graphqlRequest({
                query,
            })
        );

        const {
            data: {
                applists,
            },
        } = response;

        return applists;
    } catch (err) {
        if (window.bugsnagClient) {
            window.bugsnagClient.notify(`Failed to fetch applist data in prediction editor`, {
                metaData: {
                    action: 'PREDICTION_EDITOR__INITIALIZE_DRAFT__START',
                    function: 'fetchApplists()',
                },
            });
        }
        throw err;
    }
}

export const makeMiddlewares = deps => {
    return [
        initMw(deps),
        initGeoMw(deps),
        validateMw(deps),
        generateMw(deps),
    ];
};

export const middlewares = makeMiddlewares({});

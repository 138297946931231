import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PoisForm from './form';

class PoisTarget extends React.Component {
    static propTypes = {
        targetDraft: PropTypes.object,
        showErrors: PropTypes.bool,
        onDraftChange: PropTypes.func,
        onUnmount: PropTypes.func,

        geoResources: PropTypes.object,
        organizationId: PropTypes.string,
    };

    componentWillUnmount() {
        this.props.onUnmount();
    }

    render() {
        const {
            targetDraft,
            showErrors,
            onDraftChange,

            geoResources,
            organizationId,
            isPoiRecategorized,
        } = this.props;

        return (
            <PoisForm
                geo_targeting_settings={targetDraft && targetDraft.geo_targeting_settings}
                geoResources={geoResources}
                organizationId={organizationId}
                showErrors={showErrors}
                onDraftChange={onDraftChange}
                isPoiRecategorized={isPoiRecategorized}
            />
        );
    }
}

function selector(storeState, props) {
    const geoResources = _.get(storeState, 'resources.geoCategories');
    const organizationId = _.get(storeState, 'profile.organizationId');
    const isPoiRecategorized = _.get(
        storeState,
        `resources.ads.${props.adId}.attr.poi_recategorized`
    );

    return {
        geoResources,
        organizationId,
        isPoiRecategorized,
    };
}

export default connect(selector)(PoisTarget);

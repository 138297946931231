import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { object, string, func, arrayOf } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TreeItem from '@mui/lab/TreeItem';
import ChipInput from 'material-ui-chip-input-v5';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';

const useStyles = props => {
    const styles = {
        paperContainer: {
            maxWidth: 445,
            maxHeight: 300,
            overflowY: 'scroll',
            padding: 16,
        },
        chipInput: {
            width: 450,
            marginBottom: 8,
        },
        treeView: {
            flexGrow: 1,
            maxWidth: 500,
            maxHeight: 400,
        },
        treeItemRoot: {
            flexGrow: 1,
            maxWidth: 500,
            '&$selected > $content $label': {
                backgroundColor: 'transparent',
            },
            '&$selected > $content $label:hover, &$selected:focus > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        content: {
            flexDirection: 'row-reverse',
            '&$selected > $content $label': {
                backgroundColor: 'transparent',
            },
        },
        selected: {
            backgroundColor: 'transparent',
        },
        label: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
            padding: 4,
        },
        group: {
            marginLeft: 36,
        },
        inputDropdown: {
            fontSize: 14,
            marginTop: -8,
        },
    };

    if (props?.fullWidth) {
        // remove width and maxWidth from each element
        Object.keys(styles).forEach(key => {
            if (styles[key].maxWidth) {
                delete styles[key].maxWidth;
            }
            if (styles[key].width) {
                delete styles[key].width;
            }
        });
    }

    return makeStyles(styles);
};

export function MultiTreeView({
    value,
    option,
    onChange,
    error,
    disabledOptions,
    fullWidth,
    placeholder,
}) {
    const classes = useStyles({ fullWidth })();
    const [toggle, setToggle] = useState([]);
    const [tree, setTree] = useState([]);
    const [categories, setCategories] = useState([]);
    const [treeVisible, setTreeVisible] = useState([]);
    const [select, setSelect] = useState(value);
    const [chipSelected, setChip] = useState([]);
    const [treeDropdown, setTreeDropdown] = useState(false);

    const _getCategoryTreeData = option => {
        let categoryTreeData = [];
        _(option).each((item, key) => {
            if (key.indexOf('-') === -1) {
                // parent
                categoryTreeData.push({
                    id: key,
                    name: item,
                    disabled: _.isArray(disabledOptions) && disabledOptions.indexOf(key) !== -1,
                    children: [],
                });
            } else {
                // child
                let parentKey = key.split('-')[0];
                _(categoryTreeData).each((data, index) => {
                    if (parentKey === data.id) {
                        let categoryObj = {
                            id: key,
                            name: item,
                            disabled:
                                _.isArray(disabledOptions) && disabledOptions.indexOf(key) !== -1,
                        };
                        categoryTreeData[index]['children'].push(categoryObj);
                    }
                });
            }
        });
        return categoryTreeData;
    };

    const _getCategoryOption = option => {
        let categoryOption = [];
        let groupStr = '';
        _(option).each((item, key) => {
            if (key.indexOf('-') === -1) {
                groupStr = item;
                categoryOption.push({
                    label: item,
                    value: key,
                    group: groupStr,
                    parent: true,
                });
            } else {
                categoryOption.push({
                    label: item,
                    value: key,
                    group: groupStr,
                    parent: false,
                });
            }
        });
        return categoryOption;
    };

    useEffect(() => {
        setTree(_getCategoryTreeData(option));
        setCategories(_getCategoryOption(option));

        let initialTreeArray = [];
        _(tree).each(item => {
            initialTreeArray.push(item.id);
            if (Array.isArray(item.children)) {
                _(item.children).each(child => {
                    initialTreeArray.push(child.id);
                });
            }
        });
        setTreeVisible(initialTreeArray);
    }, []);

    useEffect(() => {
        let initialChipArray = [];
        _(categories).each(item => {
            if (value && value.indexOf(item.value) !== -1) initialChipArray.push(item.label);
        });
        setChip(initialChipArray);
        setSelect(value);
    }, [categories, value]);

    const resetTree = () => {
        setTree(_getCategoryTreeData(option));
        let treeIdFlat = [];
        tree.map(function(item) {
            if (Array.isArray(item.children)) {
                item.children.map(function(node) {
                    treeIdFlat.push(node.id);
                });
            }
            treeIdFlat.push(item.id);
        });
        setTreeVisible(treeIdFlat);
    };

    // When chip text field is changed
    const handleUpdateInput = event => {
        let categoryVisibleArray = [];
        if (event.target.value !== '') {
            let categoryNodes = [];
            let isParentMatch = false;
            _(categories).each(item => {
                // Filter the string match, partial or exact
                if (
                    item.label.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1 &&
                    item.label.toLowerCase() !== event.target.value.toLowerCase()
                ) {
                    if (item.value.indexOf('-') !== -1) {
                        // add parent value
                        categoryVisibleArray.push(item.value.split('-')[0]);
                    }
                    categoryVisibleArray.push(item.value);
                } else if (item.label.toLowerCase() === event.target.value.toLowerCase()) {
                    isParentMatch = item;
                }
            });
            categoryVisibleArray = _.uniq(categoryVisibleArray);

            // When the parent is matched, add all subCategories
            if (isParentMatch) {
                _(categories).each(item => {
                    if (item.group === isParentMatch.group) {
                        categoryVisibleArray.push(item.value);
                    }
                });
            }

            setTreeVisible(categoryVisibleArray);

            _(tree).each(item => {
                if (categoryVisibleArray.indexOf(item.id) !== -1) {
                    categoryNodes.push(item);
                }
            });
            setTree(categoryNodes);

            // Toggle open when input is not a parent
            if (!isParentMatch) {
                let adjustedExpandArray = [];
                _(categoryVisibleArray).each(item => {
                    if (item.indexOf('-') !== -1) {
                        adjustedExpandArray.push(item.split('-')[0]);
                    }
                });
                setToggle(adjustedExpandArray);
            }
        } else {
            //Reset
            resetTree();
            setToggle([]);
        }
    };

    const handleDeleteChip = chipLabel => {
        let newSelect = [],
            chipNewArray = [];
        _(chipSelected).each(item => {
            if (item !== chipLabel) {
                chipNewArray.push(item);
            }
        });
        _(categories).each(item => {
            if (chipNewArray.indexOf(item.label) !== -1) {
                newSelect.push(item.value);
            }
        });
        setSelect(newSelect);
        onChange(newSelect);
        setChip(chipNewArray);
        setTree(_getCategoryTreeData(option));
        if (newSelect.length < 1) {
            resetTree();
            setToggle([]);
            setTreeDropdown(false);
        }
    };

    // When checkbox is changed
    const handleCheckboxChange = (checked, nodes) => {
        let newSelect = [];
        if (select.indexOf(nodes.id) !== -1) {
            newSelect = select.filter(function(item) {
                if (nodes.id !== item) {
                    return item;
                }
            });
        } else {
            newSelect = [...select, nodes.id];
        }
        setSelect(newSelect);
        onChange(newSelect);

        // Update Chip selected display
        let chipNewArray = [];
        _(categories).each(item => {
            if (newSelect.indexOf(item.value) !== -1) {
                chipNewArray.push(item.label);
            }
        });
        setChip(chipNewArray);

        //Reset Toggle categories
        resetTree();
    };

    const renderTreeChild = nodes => {
        if (treeVisible.indexOf(nodes.id) === -1) return null;
        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id}
                label={
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={select.some(item => item === nodes.id)}
                                    onChange={event =>
                                        handleCheckboxChange(event.currentTarget.checked, nodes)
                                    }
                                    onClick={e => e.stopPropagation()}
                                    color="primary"
                                    disabled={nodes.disabled}
                                />
                            }
                            label={<div>{nodes.name}</div>}
                            key={nodes.id}
                        />
                    </div>
                }
                classes={{
                    root: classes.treeItemRoot,
                    content: classes.content,
                    selected: classes.selected,
                    label: classes.label,
                    group: classes.group,
                }}
            >
                {Array.isArray(nodes.children)
                    ? nodes.children.map(node => renderTreeChild(node))
                    : null}
            </TreeItem>
        );
    };

    const renderTree = nodes => {
        let categoryNodes = nodes;
        return Array.isArray(categoryNodes)
            ? categoryNodes.map(node => renderTreeChild(node))
            : null;
    };

    const toggleNode = categoryValue => {
        setToggle(categoryValue);
    };

    const inputFocused = () => {
        resetTree();
        setTreeDropdown(true);
    };

    const inputBlur = event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setTreeDropdown(false);
        }
    };

    /**
     * Returns a placeholder string based on the state of chipSelected and placeholder.
     *
     * @returns {string} The placeholder text.
     */
    const getPlaceholder = () => {
        if (chipSelected.length > 0) {
            return '';
        }

        if (!_.isEmpty(placeholder)) {
            return placeholder;
        }

        return 'Add categories';
    };

    return (
        <div onBlur={event => inputBlur(event)}>
            <ChipInput
                error={error}
                value={chipSelected}
                onDelete={chipLabel => handleDeleteChip(chipLabel)}
                onUpdateInput={event => handleUpdateInput(event)}
                onFocus={() => inputFocused()}
                placeholder={getPlaceholder()}
                alwaysShowPlaceholder={true}
                className={classes.chipInput}
                fullWidth={fullWidth}
                variant="outlined"
                InputProps={{
                    endAdornment: <ExpandMoreIcon className={classes.inputDropdown} />,
                }}
            />
            {treeDropdown && (
                <Paper className={classes.paperContainer} square={true}>
                    <TreeView
                        className={classes.treeView}
                        defaultCollapseIcon={<KeyboardArrowUpIcon />}
                        defaultExpandIcon={<ExpandMoreIcon />}
                        multiSelect
                        expanded={toggle}
                        onNodeToggle={(event, selectedOptions) => {
                            toggleNode(selectedOptions);
                        }}
                    >
                        {renderTree(tree)}
                    </TreeView>
                </Paper>
            )}
        </div>
    );
}

MultiTreeView.propTypes = {
    value: arrayOf(string),
    option: object,
    onChange: func,
};

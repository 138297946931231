import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './reducer';
import {
    getErrors,
    getDealsWithConvertedPrice,
    getNetBidPriceAndPlatformTechFee,
    getOverrideDealPriceWarning,
    showCrossPlatformCampaignWarning,
    getAdOverBudgetWarnings,
    getCurrentUser,
} from './selectors';

export function useAdForm() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.adForm.form);
    const boundActions = bindActionCreators(actions, dispatch);
    const errors = useSelector(getErrors);
    const dealsWithConvertedPrice = useSelector(getDealsWithConvertedPrice);
    const { netBidPrice, platformTechFee } = useSelector(getNetBidPriceAndPlatformTechFee);
    const overrideDealPriceWarning = useSelector(getOverrideDealPriceWarning);
    const adOverBudgetWarning = useSelector(getAdOverBudgetWarnings);
    const hasCrossPlatformCampaignWarning = useSelector(showCrossPlatformCampaignWarning);
    const currentUser = useSelector(getCurrentUser);

    return {
        ...state,
        ...boundActions,
        errors,
        dealsWithConvertedPrice,
        netBidPrice,
        platformTechFee,
        overrideDealPriceWarning,
        hasCrossPlatformCampaignWarning,
        adOverBudgetWarning,
        currentUser,
    };
}

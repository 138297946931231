import c from 'common/constants/flux-events';
import findCacheState from 'utils/find-cache-state';

const initialState = {
    metricType: 'impressions',
    unitType: 'absolute',
    tab: 'pivot',
    statsMetricsType_visibility: {
        clicks: true,
        impressions: true,
        revenue: true,
        spend: true,
        ctr: true,
        vcr: false,
        daily_uniq: false,
        average_freq: false,
        ecpc: false,
        ecpm: false,
        erpc: false,
        erpm: false,
        owner_total_media_cost_local_ecpcv: false,
        revenue_ecpcv: false,
    },
};

export default function(state = {}, action) {
    switch (action.type) {
        case c.CAMPAIGN_REPORT__INIT_STATE: {
            const { campaignId } = action.payload;

            return findCacheState(campaignId, state, initialState);
        }

        case c.CAMPAIGN_REPORT__CONTROLS__SET_TAB: {
            const { tab } = action.payload;

            return {
                ...state,
                tab,
            };
        }

        default:
            return state;
    }
}

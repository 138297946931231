import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import includes from 'lodash/includes';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import VirtualizedTable from 'packages/widgets-v6/virtualized-table';

import { useOverviewAdData } from '../../hook';
import { MultiSelect } from '../../../../widgets-v6/select';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '25%',
    }
}));

export const SelfServeDashboard = ({
    toggleColumn,
    onSortChange,
    selectSortBy,
    selectHiddenColumns,
    currentList,
    currentConfig,
    activeFilter,
    isSelfServeRoute,
}) => {
    const classes = useStyles();

    const {
        init,
        fetchUserPreference,
        fetchSelfServeOrgList,
        isLoading,
        organizationFilter,
        updateOrganizationFilter,
        selfServeOrgList,
    } = useOverviewAdData();

    const generateOrgConfig = (orgList) => orgList.map(({ id, name }) => ({ value: id, label: name }));

    const tableRef = useRef();
    const [data, setData] = useState({
        listData: currentList,
        selfServeOptions: []
    });

    useEffect(() => {
        init({
            showAllSS: true,
        });
        fetchUserPreference();

        // Don't re-request if list is already fetched.
        if (!(selfServeOrgList && selfServeOrgList.length)) {
            fetchSelfServeOrgList();
        }
    }, []);

    useEffect(() => {
        if (organizationFilter && organizationFilter[activeFilter] && organizationFilter[activeFilter].length) {
            // Filter the list according to the selected assignee(s).
            setData({
                ...data,
                listData: currentList.filter(({ organization }) => includes(organizationFilter[activeFilter], organization)),
            });
        } else {
            setData({
                ...data,
                listData: currentList,
            });
        }
    }, [currentList, organizationFilter]);

    useEffect(() => {
        setData({
            ...data,
            selfServeOptions: generateOrgConfig(selfServeOrgList),
        });
    }, [selfServeOrgList])

    const calculateMinHeight = () => {
        if (isLoading || !tableRef.current) {
            return 0;
        }

        // We calculate the distance between the table container to the bottom of the window.
        // This is so we can show as much ads/campaigns as possible in the window.
        // If there are a small number of ads/campaigns then we'll just calculate
        // the height based on that.
        return currentList.length
            ? Math.min(
                  window.innerHeight - tableRef.current.getBoundingClientRect().top - 70,
                  currentList.length * 87 + 40
              )
            : 400;
    };

    return (
        <React.Fragment>
        {!isLoading ? (
                <Grid container flexDirection="column">
                    <Grid item xs>
                        <Box pt="7">
                            <Typography variant="subtitle2">Filter by Organization:</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box mb="10">
                            <MultiSelect
                                value={organizationFilter ? organizationFilter[activeFilter] : ''}
                                onChange={(value) => {
                                    updateOrganizationFilter({
                                        section: activeFilter,
                                        organizationFilter: value,
                                    });
                                }}
                                options={data.selfServeOptions}
                                placeholder="Select organizations to filter or leave empty for all"
                                formFullWidth={false}
                                formControlClass={classes.formControl}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ) : null}
            <Grid xs="12" ref={tableRef}>
                <VirtualizedTable
                    data={data.listData}
                    minHeight={calculateMinHeight()}
                    itemHeight={87}
                    isLoading={isLoading}
                    noResultsText="No Ads or Campaigns found."
                    canToggleColumns
                    toggleColumn={(column) => toggleColumn(column)}
                    onSortChange={onSortChange}
                    columns={currentConfig.columns}
                    hiddenColumns={selectHiddenColumns()}
                    sortBy={selectSortBy()}
                    canExport
                    exportFilename={`dashboard-${moment(new Date()).format('YYYY-MM-DD')}-${
                        activeFilter
                    }.csv`}
                    showConditions={{ isSelfServeRoute }}
                />
            </Grid>
        </React.Fragment>
    )
}

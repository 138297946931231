import _ from 'lodash';

const newBasicSectionFields = ['name', 'default_timezone', 'currency', 'type', 'revenueModel'];

const feesSectionFields = [
    'privateDealInfraCost',
    'publicInventoryInfraCost',
    'privateInventorySpendTiers',
    'publicInventorySpendTiers',
];

const featureSetsFields = ['fta_fee', 'fta_partner_id'];

const getSectionsWithErrors = errors => {
    const sectionsWithErrors = [];
    const errorFields = _.keys(errors);
    _.forEach(errorFields, field => {
        if (_.includes(newBasicSectionFields, field)) {
            sectionsWithErrors.push('basic');
        }
        if (_.includes(feesSectionFields, field)) {
            sectionsWithErrors.push('fees');
        }
        if (_.includes(featureSetsFields, field)) {
            sectionsWithErrors.push('feature_sets');
        }
    });
    return _.uniq(sectionsWithErrors);
};

export default function selector(storeState) {
    const isFormOpen = _.get(storeState, 'adminOrgForm.isFormOpen');
    const isLoading = _.get(storeState, 'adminOrgForm.isLoading');
    const draft = _.get(storeState, 'adminOrgForm.draft');
    const isSubmitting = _.get(storeState, 'adminOrgForm.isSubmitting');
    const mode = _.get(storeState, 'adminOrgForm.mode');
    const relationship = _.get(storeState, 'adminOrgForm.relationship');
    const errors = _.get(storeState, 'adminOrgForm.errors');
    const showErrors = _.get(storeState, 'adminOrgForm.showErrors');
    const activeFormSection = _.get(storeState, 'adminOrgForm.activeFormSection');
    const sectionsWithErrors = getSectionsWithErrors(errors);
    return {
        isFormOpen,
        isLoading,
        draft,
        isSubmitting,
        mode,
        relationship,
        sectionsWithErrors,
        showErrors,
        activeFormSection,
    };
}

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = withStyles({
    root: {
        '&$disabled': {
            backgroundColor: '#eaeaea',
            cursor: 'not-allowed',
        },
        '&$expanded': {
            '&:hover > div': {
                cursor: 'default',
            },
            margin: 0,
        },
    },
    disabled: {},
    expanded: {},
})(MuiAccordion);

const useStyles = makeStyles({
    header: {
        backgroundColor: ({ backgroundColor }) => backgroundColor || 'initial',
        color: ({ color }) => color || 'initial',
    },
    details: {
        padding: '20px 16px',
    },
});

export const AccordionComponent = ({
    id,
    ExpandIcon = ExpandMoreIcon,
    summary,
    children,
    headerBackgroundColor,
    headerColor,
    headerClassName,
    detailsClassName,
    disabled,
    defaultExpanded,
}) => {
    const classes = useStyles({ backgroundColor: headerBackgroundColor, color: headerColor });
    return (
        <Accordion disabled={disabled} defaultExpanded={defaultExpanded}>
            <AccordionSummary
                expandIcon={<ExpandIcon />}
                id={id}
                className={`${headerClassName || classes.header}`}
            >
                {summary}
            </AccordionSummary>
            <AccordionDetails className={`${classes.details} ${detailsClassName}`}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionComponent;

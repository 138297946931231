import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
    rolePresentationDictionary,
} from 'common/constants/role-presentation-dict';
import UserForm_Button from 'containers/admin-users-form';
import { NeutralButton } from 'widgets-v5/buttons';

import { adminUsers as Action_users } from 'containers/admin-users/actions';
import { ComposableDataTable, Row, Cell } from 'widgets-v5/data-table';
import { BlockLoadGroup } from 'widgets-v5/load-group';
import Card from 'widgets-v5/card';
import Spacer from 'widgets-v5/spacer';
import { onRefresh, offRefresh } from 'containers/global-refresh';

const mapStateToProps = (state, ownProps) => {
    const ownOrganization = _.get(state, 'admin.ownOrganization', null);
    const userIds = _.get(state, 'admin.userIds', []);
    const allUsers = _.get(state, 'resources.users', null);
    const users = {};

    _.forEach(allUsers, (u, id) => {
        if (!u.attr.third_party) {
            users[id] = u;
        }
    });

    const loggedInAsUserId = _.get(state, `profile.userId`, void 0);
    const loggedInAsOrgId = _.get(state, `profile.organizationId`, void 0);

    const o = {
        orgId: _.get(ownProps, 'orgId', void 0),
        showUsersError: _.get(state, 'admin.showUsersError', null),
        loggedInAsUserId,
        loggedInAsOrgId,
        users: _.get(state, 'admin.users', void 0),
        userIds,
        ownOrganization,
    };

    return o;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch__init(orgId) {
            return dispatch(Action_users.init(orgId));
        },
        dispatch__user_delete(userId, orgId) {
            dispatch(Action_users.delete(userId, orgId));
        },
    };
};

class Users extends React.Component {
    state = {
        isLoading: false,
    };

    init = () => {
        this.setState({ isLoading: true });

        this.props
            .dispatch__init(this.props.orgId)
            .then(() => {
                this.setState({ isLoading: false });
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });
    };

    componentDidMount() {
        this.init();

        onRefresh(this.init);
    }

    componentWillUnmount() {
        offRefresh(this.init);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.orgId !== nextProps.orgId) {
            // organization has changed, so need to initialize again
            this.setState({ isLoading: true });
            this.props.dispatch__init(nextProps.orgId).then(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    handleDelete() {
        const confirm = window.confirm("This users's account will be deleted in the EngageFront");
        if (confirm) {
            props.dispatch__user_delete(props.userId, props.orgId);
        }
    }

    rephraseErrorMessage = errorMessage => {
        let rephrasedErrorMessage = '';
        switch (errorMessage) {
            case 'Forbidden':
                rephrasedErrorMessage = 'You do not have permission to access this page';
                break;
            case 'TypeError: Failed to fetch':
                rephrasedErrorMessage = 'Failed to fetch users on this page';
                break;
        }
        return rephrasedErrorMessage;
    };

    render() {
        const { ownOrganization } = this.props;

        if (!ownOrganization) {
            return <div />;
        }
        return (
            <div className="admin-users">
                <div className="admin-users__head">
                    <UserForm_Button
                        userId={void 0}
                        orgId={this.props.orgId}
                        Button={
                            <NeutralButton icon={<i className="fa fa-plus" />} text="Add a User" />
                        }
                        orgType={ownOrganization.type}
                        retrieveUsers={this.init}
                    />
                </div>
                <Spacer />
                {this.props.showUsersError ? (
                    this.rephraseErrorMessage(this.props.showUsersError)
                ) : (
                    <div className="admin-users__body">
                        <Card padding={0} margin={0}>
                            <BlockLoadGroup isLoading={this.state.isLoading}>
                                <ComposableDataTable>
                                    <Row header>
                                        <Cell>Email</Cell>
                                        <Cell>First Name</Cell>
                                        <Cell>Last Name</Cell>
                                        <Cell>Suspended</Cell>
                                        <Cell>Role</Cell>
                                        <Cell />
                                    </Row>
                                    {_.map(this.props.users, user => {
                                        const userDetails = user;

                                        const orgDetails = ownOrganization;

                                        return (
                                            !this.state.isLoading &&
                                            userDetails &&
                                            orgDetails && (
                                                <Row key={userDetails.id}>
                                                    <Cell>{userDetails.email}</Cell>
                                                    <Cell>{userDetails.first_name}</Cell>
                                                    <Cell>{userDetails.last_name}</Cell>
                                                    <Cell>
                                                        {userDetails.suspended ? 'yes' : 'no'}
                                                    </Cell>
                                                    <Cell>
                                                        {_.get(
                                                            rolePresentationDictionary,
                                                            `${userDetails.global_role}`,
                                                            'Unknown'
                                                        )}
                                                    </Cell>
                                                    <Cell>
                                                        {/* Cannot edit or delete oneself */
                                                        _.includes(
                                                            ['self', 'child'],
                                                            orgDetails._relationship
                                                        ) ? (
                                                            <div>
                                                                <UserForm_Button
                                                                    userId={userDetails.id}
                                                                    orgId={this.props.orgId}
                                                                    Button={
                                                                        <div className="admin-users__edit-user-button">
                                                                            <i className="fa fa-pencil" />
                                                                        </div>
                                                                    }
                                                                    orgType={ownOrganization.type}
                                                                    retrieveUsers={this.init}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </Cell>
                                                </Row>
                                            )
                                        );
                                    })}
                                </ComposableDataTable>
                            </BlockLoadGroup>
                        </Card>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);

import _ from 'lodash';
import c from 'common/constants/flux-events';

export const NAME = 'presets-shell';

const initialState = {
    tab: 'pois',
};

export default function(state = initialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
    }
}

import React from 'react';

import Box from '@mui/material/Box';
import moment from 'moment';
import { useCampaignForm } from '../../v2';
import makeStyles from '@mui/styles/makeStyles';

import { formatNumber_currency, formatNumber_whole } from '../../../../../utils/formatting';
import CampaignInfoBanner from '../campaign-info-banner';
import { RevenueModelMapping } from '../../../../states/resources/campaigns/business-logic';

const useStyles = makeStyles({
    mainContainer: props => ({
        width: '100%',
        height: 54,
        backgroundColor: '#F5F5F5',
        padding: '0 20px',
        display: 'grid',
        gridTemplateColumns: props.isAgencyMargin
            ? '3.2fr 2fr 2fr 1.3fr 0.8fr 0.9fr 0.9fr'
            : '3.2fr 2fr 2fr 1fr 0.9fr 0.7fr',
        alignItems: 'center',
    }),
    campaignOverall: {
        fontWeight: 'bold',
    },
});

export const CampaignOverall = () => {
    const {
        draft: { default_timezone: timezone, flights, revenueModel },
        flightMetrics,
    } = useCampaignForm();
    const isAgencyMargin = revenueModel === RevenueModelMapping.AgencyMargin;
    const isCPMCPCOrCPM = [RevenueModelMapping.CPMCPC, RevenueModelMapping.CPM].includes(
        revenueModel
    );
    const styles = useStyles({ isAgencyMargin });
    const lastSelectedEndDate = flights.reduce((acc, flight) => flight.end || acc, null);
    const firstSelectedStartDate = flights && flights.length && flights[0].start;
    const totalBudget = flights.reduce((acc, flight) => {
        if (isCPMCPCOrCPM) {
            return acc + (flight.total_impressions || 0);
        }
        return acc + (flight.total_cost_budget || 0);
    }, 0);
    const totalSpend = flightMetrics.reduce((acc, metric) => {
        if (isAgencyMargin) {
            return acc + metric.billings_local;
        } else if (isCPMCPCOrCPM) {
            return acc + metric.impressions;
        }

        return acc + metric.owner_total_media_cost_local;
    }, 0);
    const totalRemaining = flightMetrics.reduce((acc, metric) => acc + metric.remaining, 0);
    const totalRevenueBudget = flights.reduce(
        (acc, flight) => acc + (flight.billings_local || 0),
        0
    );
    const numberFormatFn = isCPMCPCOrCPM ? formatNumber_whole : formatNumber_currency;

    return (
        <Box>
            <Box className={styles.mainContainer}>
                <Box className={styles.campaignOverall}>Campaign Overall</Box>
                <Box>
                    {firstSelectedStartDate &&
                        moment(firstSelectedStartDate)
                            .tz(timezone)
                            .format('MMMM DD, YYYY')}
                </Box>
                <Box>
                    {lastSelectedEndDate &&
                        moment(lastSelectedEndDate)
                            .tz(timezone)
                            .format('MMMM DD, YYYY')}
                </Box>
                {isAgencyMargin && <Box>{formatNumber_currency(totalRevenueBudget)}</Box>}
                <Box>{numberFormatFn(totalBudget)}</Box>
                <Box>{numberFormatFn(totalSpend)}</Box>
                <Box>{numberFormatFn(totalRemaining)}</Box>
            </Box>
            <CampaignInfoBanner content="Each flight will be paced independently." />
        </Box>
    );
};

import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class EqualColumnsLayout extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const columns = React.Children.toArray(this.props.children).filter(
            child => child.type === EqualColumn
        );
        const width = `${100 / columns.length}%`;
        return (
            <div className={classnames('ef5-equal-columns-layout', this.props.className)}>
                {columns.map((column, i) => (
                    <EqualColumn {...column.props} key={i} style={{ width }} />
                ))}
            </div>
        );
    }
}

export class EqualColumn extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
    };

    render() {
        return (
            <div
                className={classnames('ef5-equal-column', this.props.className)}
                style={this.props.style}
            >
                {this.props.children}
            </div>
        );
    }
}

import _ from 'lodash';

import validate from 'utils/validate';

// --------------
// Default values
// --------------
import _defaultSetting, { EN_279_defaultValues } from './default-values';
export const defaultSetting = _defaultSetting;
export const defaultSetting_EN_279 = EN_279_defaultValues;

// ----------
// Validation
// ----------
import { validators_rules } from './validation-rules';

export const draftValidators = {
    ...validators_rules,
};

export function validateAppsDraft(draft) {
    const _draft = {
        ...defaultSetting.draftDefaultValues,
        ...draft,
    };
    const validators = _.pick(draftValidators, ['name', '__appsAndSites', '__bundleId', '__domain'])
    const errors = validate(_draft, validators);
    return errors;
}

// -----------------------------------------
// Data transformation before sending to api
// -----------------------------------------
import {
    serialize as _serialize,
    serialize_EN_279 as _serialize_EN_279,
} from './serialize-for-api';

export const serializeForApi = _serialize;

export const serialize_EN_279 = _serialize_EN_279;

import get from 'lodash/get';

export default function selector(storeState) {
    const isLoggedIn = get(storeState, 'profile.isLoggedIn');
    const globalRole = get(storeState, 'profile.globalRole');
    const currentBranch = get(storeState, 'devSettings.currentBranch');
    const sidebarIsOnScreen = get(storeState, 'sidebar.isOnScreen');
    const profileState = get(storeState, 'profile');
    const dashboardUrl = get(storeState, 'dashboard.filterUrl', '/dashboard');
    const canImpersonate = get(storeState, 'profile.canImpersonate', false);
    const isLoadingUser = get(storeState, 'profile.isLoadingUser');
    return {
        isLoggedIn,
        currentBranch,
        globalRole,
        sidebarIsOnScreen,
        profileState,
        dashboardUrl,
        canImpersonate,
        isLoadingUser,
    };
}

export const IAS_FRAUD_PREVENTION_RISK_OPTIONS = [
    {
        label: 'Off',
        value: 0,
    },
    {
        label: 'Exclude HIGH risk content',
        value: 2,
    },
    {
        label: 'Exclude HIGH and MODERATE risk content',
        value: 1,
    },
];

export const IAS_FRAUD_PREVENTION_RISK_MAPPING = {
    2: 'Exclude HIGH risk content',
    1: 'Exclude HIGH and MODERATE risk content',
    0: 'Off',
};

export const IAS_IP_FRAUD_VALUES = {
    OFF: 0,
    ON: 1,
};

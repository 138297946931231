import _ from 'lodash';

import smartQuery from 'utils/smart-query';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

export default function selector(storeState, props) {
    const { campaignId } = props.params;

    const campaign = _.get(storeState, `resources.campaigns.${campaignId}.attr`);
    if (!campaign) {
        return {
            creatives: [],
        };
    }

    const ads = _.get(storeState, `resources.ads`, {});

    const creativesResource = _.get(storeState, `resources.creatives`, {});
    const pagination = _.get(storeState, `creativesOverview`);

    const creatives = _.map(campaign.creatives, _creativeId => creativesResource[_creativeId])
        .filter(x => x)
        .map(creative => {
            const connectedAds = _(ads)
                .filter(ad => {
                    const rotationCreativeIds =
                        ad.attr.rotation_rules.mode === CreativeRotationMethodMapping.Weather
                            ? _.map(ad.attr.rotation_rules.weather, setting => setting.markupId)
                            : _.map(
                                  ad.attr.rotation_rules.scheduled,
                                  schedule => schedule.markup_id
                              );
                    const creativeIds = [].concat(ad.attr.creative, rotationCreativeIds);

                    return _.includes(creativeIds, creative.attr.id);
                })
                .value();

            const isExpanded = _.get(
                storeState,
                `creativesOverview.creativesExpanded.${creative.attr.id}`,
                false
            );

            return {
                ...creative.attr,
                connectedAds: connectedAds || 'None',
                isExpanded,
            };
        });

    const filesResource = _.get(storeState, `resources.files`, {});

    const campaignFiles = _.reduce(
        campaign.files,
        (acc, fileId) => {
            const file = filesResource[fileId];

            if (!file) {
                return acc;
            }

            return {
                ...acc,
                [fileId]: file,
            };
        },
        {}
    );

    const filteredCampaignFiles = _.filter(campaignFiles, x => x);
    const assets = _.filter(filteredCampaignFiles, (value) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? value : null;
    });
    const documents = _.filter(filteredCampaignFiles, (value) => {
        return value.attr.type === 'image' || value.attr.type === 'video' ? null : value;
    });

    const searchQuery = _.get(pagination, 'filterOptions.search', '');
    const searchQueryClean = searchQuery.toLowerCase();

    const creativesLength_beforeFilter = creatives.length;

    const creatives_filtering = _creatives => {
        const _o = _(_creatives)
            .filter(ad => {
                const stringBeingSearch = _.values(_.pick(ad, ['id', 'name'])).join(' ');
                const stringBeingSearch_lowercase = stringBeingSearch.toLowerCase();
                if (searchQueryClean === '') return true;
                if (smartQuery(searchQueryClean).test(stringBeingSearch_lowercase)) return true;
                return false;
            })
            .orderBy('_created', 'desc')
            .value();

        return _o;
    };

    const organization = _.get(storeState, 'profile.organizationId');

    const isDeleteConfirmModalOpen = _.get(
        storeState,
        `creativesOverview.isDeleteConfirmModalOpen`
    );
    const deleteConfirmModalMessage = _.get(
        storeState,
        `creativesOverview.deleteConfirmModalMessage`
    );
    const canDeleteCreative = _.get(storeState, `creativesOverview.canDeleteCreative`);
    const creativeToDelete = _.get(storeState, `creativesOverview.creativeToDelete`);

    return {
        campaign,
        campaignId,
        creatives: creatives_filtering(creatives),
        files: {
            documents,
            assets,
        },
        searchQuery,
        creativesLength_beforeFilter,
        organization,
        isDeleteConfirmModalOpen,
        deleteConfirmModalMessage,
        canDeleteCreative,
        creativeToDelete,
    };
}
